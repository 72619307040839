import React from 'react';
import './AnalysisPageTitle.css';
import cx from "classnames";

const AnalysisPageTitle = ({title, subTitle, headIcon}) => {
  return (
    <div className="page-title-wrapper">
        <div className='page-title-block d-flex align-items-center flex-wrap'>
          <div className="page-title-icon rounded-circle">
            <img src={headIcon} alt="img" className='titleImg' />
          </div>
          <div className='pageTitleText'>
              <div className="page-title">{title}</div>
              <div className={cx("page-title-subheading")}>{subTitle}</div>
          </div>
        </div>
      </div>
  )
}

export default AnalysisPageTitle
