import React, { Component } from 'react';
import classnames from 'classnames';
import {
  Row, Col
} from 'reactstrap';
import AuthService from '../../services/AuthService';
import './ContactUs.css';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';
class ContactUs extends Component {
  isLogin = false;
  componentDidMount() {
    GoogleAnalticsService.pageView('Contact Us View');
    this.isLogin = AuthService.isAuthenticated();
  }
  render() {
    return (
      <div className={classnames({ 'padding-15px-15px-0': !this.isLogin })}>
        <div className="d-flex-column">
          <span className="Contact-Us mb-4">Contact Us</span>
          <span className="Contact-Person mb-2">Contact Person</span>
          <div className="support-detail mb-4" style={{ flexWrap: 'wrap', marginTop: '0' }}>
            <span className="Contact-Person-name" style={{ marginRight: '22px', marginTop: '5px' }}>Mr. Kaushal Kanani</span>
            <div className="d-flex-row" style={{ marginTop: '5px' }}>
              <img src="/images/Mail.svg" alt="" />&nbsp;
            <span className="Helpline-Number Locate-in-google-map" style={{ marginTop: 'auto', marginBottom: 'auto' }}><a href="mailto:kaushal.kanani@trackwalkins.com" style={{ color: '#545cd8' }}>kaushal.kanani@trackwalkins.com</a></span>

            </div>
          </div>
          <span className="Contact-Person mb-3">Support Team</span>
          <Row className="mb-3">
            <Col className="mb-3">
              <div className="support-detail mb-3" style={{ flexWrap: 'wrap' }}>
                <span className="Helpline-lbl" style={{ marginRight: '25px' }}>Helpline Number</span>
                <span className="Helpline-Number">+91 8282884545</span>
              </div>
              <div className="support-detail">
                <span className="Helpline-lbl" style={{ marginRight: '40px' }}>Email address:</span>
                <span className="Helpline-Number">newjkadvantage@trackwalkins.com</span>
              </div>
            </Col>

          </Row>
          <span className="Contact-Person mb-2">Office Address</span>
          <div className="d-flex-column Lomoso-Solutions-Pri mb-2">
            <span>Lomoso Solutions Private Limited</span>
            <span>716, Binori Bsquare 3</span>
            <span>Sindhubhavan Marg</span>
            <span>Bodakdev, Ahmedabad</span>
            <span>Gujarat 380054</span>
          </div>
          <div className="d-flex-row"><img src="/images/Location.svg" alt="" />&nbsp;<span className="Locate-in-google-map" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <a href='https://www.google.com/maps/place/Lomoso+Solutions+Pvt+Ltd/@23.0120798,72.5192033,17z/data=!3m1!4b1!4m5!3m4!1s0x395e850436bfd67d:0x9dc35a91a98a1341!8m2!3d23.0120749!4d72.5213974' target="_blank" rel="noopener noreferrer" style={{ color: '#545cd8' }}>
              Locate in google maps
            </a>
          </span></div>
          <div className="margin-top-10">For Privacy Policy of program please refer&nbsp;
            <a href='https://advantage.jktyrecrm.in/policy/PrivacyPolicy.html' target="_blank" rel="noopener noreferrer" style={{ color: '#545cd8', }}>
              here
            </a>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default ContactUs;