import React, { Component } from "react";

import {
  Row,
  Input,
  Col,
  Button,
  FormGroup,
  Label,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import DashboardService from "../../services/DashboardService";
import "./WarrantyFilter.css";

class WarrantyFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: this.props.opensidebar,
      dropdownOpenYtd: this.props.opensidebar,
      monthOptions: [],
      vehicleType: this.props.data.lcv_min
        ? "lcv"
        : this.props.data.scv_min
          ? "scv"
          : this.props.data.two_wheel_min
            ? "2 wheeler"
            : this.props.data.sensor_min
              ? "sensor"
              : this.props.data.pcr_min
                ? "car"
                : "",
      yearOptions: [],
      allQuarter: [],
      input_min:
        this.props.data.lcv_min ||
        this.props.data.scv_min ||
        this.props.data.two_wheel_min ||
        this.props.data.pcr_min ||
        this.props.data.sensor_min ||
        null,
      input_max:
        this.props.data.lcv_max ||
        this.props.data.scv_max ||
        this.props.data.two_wheel_max ||
        this.props.data.pcr_max ||
        this.props.data.sensor_max ||
        null,
      isLoading: false,
      dealerRole: null,
      Xerox: {},
      year_name_value: {},
      controls: {
        error: {
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        month: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        year: {
          value: "all",
          // new Date().getMonth() > 3
          //   ? new Date().getFullYear()
          //   : new Date().getFullYear() - 1,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        quarter: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        pcr_min: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        pcr_max: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        two_wheel_min: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        two_wheel_max: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        lcv_min: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        lcv_max: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        scv_min: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        scv_max: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        sensor_min: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        sensor_max: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },

      tooltipOpen: false,
      tooltip_name: "warranty_report",
      is_filter_apply: this.props.isfilterApplied,
    };
    this.onRadioButtonChange = this.onRadioButtonChange.bind(this);
    // this.state.controls.year.value = props.data.year;
    // this.state.controls.quarter.value = props.data.quarter;
    // this.state.controls.month.value = props.data.month;
    // this.state.controls.pcr_min.value = props.data.pcr_min;
    // this.state.controls.pcr_max.value = props.data.pcr_max;
    // this.state.controls.two_wheel_min.value = props.data.two_wheel_min;
    // this.state.controls.two_wheel_max.value = props.data.two_wheel_max;
  }

  componentDidMount() {
    // let Xerox = {
    //   month: this.state.controls.month.value,
    //   year: this.state.controls.year.value,
    //   quarter: this.state.controls.quarter.value,
    //   pcr_min: this.state.controls.pcr_min.value,
    //   pcr_max: this.state.controls.pcr_max.value,
    //   two_wheel_min: this.state.controls.two_wheel_min.value,
    //   two_wheel_max: this.state.controls.two_wheel_max.value,
    //   lcv_min: this.state.controls.lcv_min.value,
    //   lcv_max: this.state.controls.lcv_max.value,
    //   scv_min: this.state.controls.scv_min.value,
    //   scv_max: this.state.controls.scv_max.value,
    //   sensor_min: this.state.controls.sensor_min.value,
    //   sensor_max: this.state.controls.sensor_max.value,
    // };
    this.state.controls.year.value = this.props.data.year;
    this.state.controls.quarter.value = this.props.data.quarter;
    this.state.controls.month.value = this.props.data.month;
    this.state.controls.pcr_min.value = this.props.data.pcr_min;
    this.state.controls.pcr_max.value = this.props.data.pcr_max;
    this.state.controls.two_wheel_min.value = this.props.data.two_wheel_min;
    this.state.controls.two_wheel_max.value = this.props.data.two_wheel_max;
    this.state.controls.lcv_min.value = this.props.data.lcv_min;
    this.state.controls.lcv_max.value = this.props.data.lcv_max;
    this.state.controls.scv_min.value = this.props.data.scv_min;
    this.state.controls.scv_max.value = this.props.data.scv_max;
    this.state.controls.sensor_min.value = this.props.data.sensor_min;
    this.state.controls.sensor_max.value = this.props.data.sensor_max;
    this.state.is_filter_apply = this.props.isfilterApplied;
    this.setState({ controls: this.state.controls }, () => {
      this.filterAPI(false, false);
    });
    // this.setState((prevState) => ({
    //   controls: {
    //     ...prevState.controls,
    //     year: { value: this.props.data.year },
    //     quarter: { value: this.props.data.quarter },
    //     month: { value: this.props.data.month },
    //     pcr_min: { value: this.props.data.pcr_min },
    //     pcr_max: { value: this.props.data.pcr_max },
    //     two_wheel_min: { value: this.props.data.two_wheel_min },
    //     two_wheel_max: { value: this.props.data.two_wheel_max },
    //     lcv_min: { value: this.props.data.lcv_min },
    //     lcv_max: { value: this.props.data.lcv_max },
    //     scv_min: { value: this.props.data.scv_min },
    //     scv_max: { value: this.props.data.scv_max },
    //     sensor_min: { value: this.props.data.sensor_min },
    //     sensor_max: { value: this.props.data.sensor_max },
    //   },
    //   is_filter_apply: this.props.isfilterApplied,
    // }), () => {
    //   this.filterAPI(false, false);
    // });

    // this.setState({
    //   Xerox
    // });
  }

  getInputMinValue = async (e) => {
    const { controls } = this.state;
    const {
      pcr_min,
      two_wheel_min,
      scv_min,
      lcv_min,
      sensor_min,
    } = controls;
    if (this.state.vehicleType === "car") {
      pcr_min.value = e.target.value;
      // two_wheel_min.value = null;
      // scv_min.value = null;
      // sensor_min.value = null;
      // lcv_min.value = null;
      this.setState({
        controls,
        input_min: e.target.value,
      });
    }
    if (this.state.vehicleType === "2 wheeler") {
      two_wheel_min.value = e.target.value;
      // pcr_min.value = null;
      // scv_min.value = null;
      // sensor_min.value = null;
      // lcv_min.value = null;
      this.setState({
        controls,
        input_min: e.target.value,
      });
    }
    if (this.state.vehicleType === "lcv") {
      lcv_min.value = e.target.value;
      // pcr_min.value = null;
      // two_wheel_min.value = null;
      // scv_min.value = null;
      // sensor_min.value = null;
      this.setState({
        controls,
        input_min: e.target.value,
      });
    }
    if (this.state.vehicleType === "scv") {
      scv_min.value = e.target.value;
      // pcr_min.value = null;
      // two_wheel_min.value = null;
      // sensor_min.value = null;
      // lcv_min.value = null;
      this.setState({
        controls,
        input_min: e.target.value,
      });
    }
    if (this.state.vehicleType === "sensor") {
      sensor_min.value = e.target.value;
      // pcr_min.value = null;
      // two_wheel_min.value = null;
      // scv_min.value = null;
      // lcv_min.value = null;
      this.setState({
        controls,
        input_min: e.target.value,
      });
    }
  };

  getInputMaxValue = async (e) => {
    const { controls } = this.state;
    const {
      pcr_max,
      two_wheel_max,
      scv_max,
      lcv_max,
      sensor_max,
    } = controls;
    if (this.state.vehicleType === "car") {
      pcr_max.value = e.target.value;
      // two_wheel_max.value = null;
      // scv_max.value = null;
      // sensor_max.value = null;
      // lcv_max.value = null;
      this.setState({
        controls,
        input_max: e.target.value,
      });
    }
    if (this.state.vehicleType === "2 wheeler") {
      two_wheel_max.value = e.target.value;
      // pcr_max.value = null;
      // scv_max.value = null;
      // sensor_max.value = null;
      // lcv_max.value = null;
      this.setState({
        controls,
        input_max: e.target.value,
      });
    }
    if (this.state.vehicleType === "lcv") {
      lcv_max.value = e.target.value;
      // pcr_max.value = null;
      // two_wheel_max.value = null;
      // scv_max.value = null;
      // sensor_max.value = null;
      this.setState({
        controls,
        input_max: e.target.value,
      });
    }
    if (this.state.vehicleType === "scv") {
      scv_max.value = e.target.value;
      // pcr_max.value = null;
      // two_wheel_max.value = null;
      // sensor_max.value = null;
      // lcv_max.value = null;
      this.setState({
        controls,
        input_max: e.target.value,
      });
    }
    if (this.state.vehicleType === "sensor") {
      sensor_max.value = e.target.value;
      // pcr_max.value = null;
      // two_wheel_max.value = null;
      // scv_max.value = null;
      // lcv_max.value = null;
      this.setState({
        controls,
        input_max: e.target.value,
      });
    }
  };

  getHelpModuleScreen = () => {
    // this.setState({ isLoading: true });
    DashboardService.getHelpModuleScreen()
      .then((data) => {
        // this.setState({ isLoading: false });
        const { screen_name } = data.data.data;
        if (screen_name.includes(this.state.tooltip_name) === false) {
          this.setState({ tooltipOpen: true, tooltip_name: "warranty_report" });
        }
      })
      .catch((e) => {
        // this.setState({ isLoading: false });
      });
  };

  addHelpModuleScreen = (name) => {
    this.setState({ isLoading: true });
    this.setState({
      dropdownOpenYtd: true,
    });
    DashboardService.addHelpModuleScreen({ screen_name: name })
      .then((data) => {
        this.setState({ tooltipOpen: false, tooltip_name: "", isLoading: false, dropdownOpenYtd: true });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  toggleYtd = () => {
    if (this.state.dropdownOpenYtd === false) {
      this.getHelpModuleScreen();
    }

    this.state.controls.year.value = this.props.data.year;
    this.state.controls.quarter.value = this.props.data.quarter;
    this.state.controls.month.value = this.props.data.month;
    this.state.controls.pcr_min.value = this.props.data.pcr_min;
    this.state.controls.pcr_max.value = this.props.data.pcr_max;
    this.state.controls.two_wheel_min.value = this.props.data.two_wheel_min;
    this.state.controls.two_wheel_max.value = this.props.data.two_wheel_max;
    this.state.controls.lcv_min.value = this.props.data.lcv_min;
    this.state.controls.lcv_max.value = this.props.data.lcv_max;
    this.state.controls.scv_min.value = this.props.data.scv_min;
    this.state.controls.scv_max.value = this.props.data.scv_max;
    this.state.controls.sensor_min.value = this.props.data.sensor_min;
    this.state.controls.sensor_max.value = this.props.data.sensor_max;
    this.setState({ controls: this.state.controls });
    // this.setState((prevState) => ({
    //   controls: {
    //     ...prevState.controls,
    //     year: { ...prevState.controls.year, value: this.props.data.year },
    //     quarter: { ...prevState.controls.quarter, value: this.props.data.quarter },
    //     month: { ...prevState.controls.month, value: this.props.data.month },
    //     pcr_min: { ...prevState.controls.pcr_min, value: this.props.data.pcr_min },
    //     pcr_max: { ...prevState.controls.pcr_max, value: this.props.data.pcr_max },
    //     two_wheel_min: { ...prevState.controls.two_wheel_min, value: this.props.data.two_wheel_min },
    //     two_wheel_max: { ...prevState.controls.two_wheel_max, value: this.props.data.two_wheel_max },
    //     lcv_min: { ...prevState.controls.lcv_min, value: this.props.data.lcv_min },
    //     lcv_max: { ...prevState.controls.lcv_max, value: this.props.data.lcv_max },
    //     scv_min: { ...prevState.controls.scv_min, value: this.props.data.scv_min },
    //     scv_max: { ...prevState.controls.scv_max, value: this.props.data.scv_max },
    //     sensor_min: { ...prevState.controls.sensor_min, value: this.props.data.sensor_min },
    //     sensor_max: { ...prevState.controls.sensor_max, value: this.props.data.sensor_max }
    //   }
    // }));

    // let { dropdownOpenYtd } = this.state;
    // this.setState({
    //   dropdownOpenYtd: !dropdownOpenYtd
    // });
  };

  // toggleClose = () => {
  //   let { controls, Xerox } = this.state;
  //   controls["month"].value = Xerox.month;
  //   controls["year"].value = Xerox.year;
  //   controls["quarter"].value = Xerox.quarter;
  //   controls["pcr_min"].value = Xerox.pcr_min;
  //   controls["pcr_min"].value = Xerox.pcr_max;
  //   controls["two_wheel_min"].value = Xerox.two_wheel_min;
  //   controls["two_wheel_max"].value = Xerox.two_wheel_max;
  //   this.setState({
  //     controls
  //   });
  //   this.toggleYtd();
  // };

  onChangeYear = (e) => {
    let { controls, yearOptions, year_name_value } = this.state;
    let { year, quarter, month } = controls;
    if (e && e.target.value) {
      year_name_value = yearOptions.filter((x) => x.value == e.target.value);
    }
    year.value = e.target.value;
    quarter.value = null;
    month.value = null;

    this.setState(
      {
        controls,
        year_name_value,
      },
      () => {
        this.filterAPI(true, true);
      }
    );
  };

  componentWillUnmount = () => {
    if (this.state.is_filter_apply === true) {
      this.setState({
        applied: true,
      });
    }
  };

  onRadioButtonChange = async (e) => {
    const { controls } = this.state;
    const {
      pcr_max,
      pcr_min,
      lcv_max,
      lcv_min,
      scv_max,
      scv_min,
      sensor_max,
      sensor_min,
      two_wheel_min,
      two_wheel_max,
    } = controls;
    pcr_max.value = null;
    pcr_min.value = null;
    scv_min.value = null;
    scv_max.value = null;
    sensor_max.value = null;
    sensor_min.value = null;
    lcv_max.value = null;
    lcv_min.value = null;
    two_wheel_max.value = null;
    two_wheel_min.value = null;
    await this.setState({
      controls,
      vehicleType: e.target.value,
      input_max: "",
      input_min: "",
    });
  };

  addQuarter = (e) => {
    const { controls } = this.state;
    const { quarter, month } = controls;
    quarter.value = e.target.value;
    month.value = null;
    this.setState(
      {
        controls,
      },
      () => {
        this.filterAPI(false, true);
      }
    );
  };

  onChangeMonth = (e) => {
    const { controls } = this.state;
    const { month } = controls;
    month.value = e.target.value;
    this.setState({
      controls,
    });
  };

  AddYTDFilter = async (e) => {
    e.preventDefault();
    const { controls } = this.state;
    const {
      month,
      error,
      year,
      quarter,
      pcr_min,
      pcr_max,
      two_wheel_min,
      two_wheel_max,
      lcv_max,
      lcv_min,
      scv_max,
      scv_min,
      sensor_max,
      sensor_min,
    } = controls;
    let filter = {
      month: month.value,
      year: year.value ? year.value : "all",
      quarter: quarter.value,
      pcr_min: pcr_min.value,
      pcr_max: pcr_max.value,
      two_wheel_min: two_wheel_min.value,
      two_wheel_max: two_wheel_max.value,
      lcv_min: lcv_min.value,
      lcv_max: lcv_max.value,
      sensor_min: sensor_min.value,
      sensor_max: sensor_max.value,
      scv_min: scv_min.value,
      scv_max: scv_max.value,
    };
    if (this.state.input_min && this.state.input_max) {
      if (Number(this.state.input_min) > Number(this.state.input_max)) {
        error.valid = false;
        error.showErrorMsg = `Please Enter ${this.state.vehicleType === "car"
          ? "PCR"
          : this.state.vehicleType === "lcv"
            ? "LCV"
            : this.state.vehicleType === "scv"
              ? "SCV"
              : this.state.vehicleType === "sensor"
                ? "Sensor"
                : "2 Wheeler"
          } max value greater then ${this.state.vehicleType === "car"
            ? "PCR"
            : this.state.vehicleType === "lcv"
              ? "LCV"
              : this.state.vehicleType === "scv"
                ? "SCV"
                : this.state.vehicleType === "sensor"
                  ? "Sensor"
                  : "2 Wheeler"
          } min.`;
        this.setState({ controls });
        return;
      } else {
        error.valid = true;
        error.showErrorMsg = null;
        this.setState({ controls });
      }
      /* if (pcr_max.value < pcr_min.value) {
        pcr_min.valid = false;
        pcr_min.showErrorMsg = 'Please Enter pcr min value smaller then pcr max.';
        this.setState({ controls });
        return;
      } else {
        pcr_min.valid = true;
        pcr_min.showErrorMsg = null;
        this.setState({ controls });
      } */
    }
    // if (two_wheel_min.value && two_wheel_max.value) {
    //   if (Number(two_wheel_min.value) > Number(two_wheel_max.value)) {
    //     two_wheel_max.valid = false;
    //     two_wheel_max.showErrorMsg = 'Please Enter two wheeler min value greater then two wheeler max.';
    //     this.setState({ controls });
    //     return;
    //   } else {
    //     two_wheel_max.valid = true;
    //     two_wheel_max.showErrorMsg = null;
    //     this.setState({ controls });
    //   }
    /* if (two_wheel_max.value < two_wheel_min.value) {
      two_wheel_min.valid = false;
      two_wheel_min.showErrorMsg = 'Please Enter two wheeler max value smaller then two wheeler min.';
      this.setState({ controls });
      return;
    } else {
      two_wheel_min.valid = true;
      two_wheel_min.showErrorMsg = null;
      this.setState({ controls });
    } */
    //}
    this.toggleYtd();
    // await this.setState({ is_filter_apply: true });
    if (
      filter.year === "all" &&
      !filter.quarter &&
      !filter.month &&
      !filter.pcr_min &&
      !filter.pcr_max &&
      !filter.two_wheel_min &&
      !filter.two_wheel_max &&
      !filter.lcv_min &&
      !filter.lcv_max &&
      !filter.scv_min &&
      !filter.sensor_min &&
      !filter.sensor_max
    ) {
      this.props.isFilterApplied(false);
    } else if (
      filter.year &&
      (filter.quarter ||
        filter.month ||
        filter.pcr_min ||
        filter.pcr_max ||
        filter.two_wheel_min ||
        filter.two_wheel_max ||
        filter.lcv_min ||
        filter.lcv_max ||
        filter.scv_min ||
        filter.sensor_min ||
        filter.sensor_max)
    ) {
      this.props.getWarrantyData(filter);
      this.props.isFilterApplied(true);
    }
    //this.props.getWarrantyData(filter);

    this.props.onSideBarClose();
  };

  validateInputChange = (e) => {
    let controls = this.state.controls;
    if (
      ["pcr_min", "pcr_max", "two_wheel_min", "two_wheel_max"].includes(
        e.target.name
      ) === true
    ) {
      let controlName = e.target.name;
      if (isNaN(e.target.value) === true) {
        controls[controlName].showErrorMsg = "Please Enter valid value.";
        controls[controlName].valid = false;
        controls[controlName].value = null;
        this.setState({ controls });
      } else {
        controls[controlName].showErrorMsg = null;
        controls[controlName].valid = true;
        controls[controlName].value = e.target.value;
        this.setState({ controls });
      }
    }
  };

  filterAPI = (is_year_change, is_quarter_change) => {
    const { controls } = this.state;
    const {
      month,
      year,
      quarter,
      // pcr_min,
      // pcr_max,
      // two_wheel_min,
      // two_wheel_max,
    } = controls;

    let filter = {
      y: year.value ? year.value : null,
      m: month.value ? month.value : null,
      q:
        quarter.value && quarter.value.toLowerCase() !== "all"
          ? quarter.value
          : null,
    };

    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          this.setState(
            {
              yearOptions: res.data.data.year ? res.data.data.year : [],
              monthOptions: res.data.data.month ? res.data.data.month : [],
              allQuarter: res.data.data.quarter ? res.data.data.quarter : [],
            },
            () => {
              // if (res.data.data.month && res.data.data.month.length === 1) {
              //   month.value = res.data.data.month[0].value
              //   this.setState({ month })
              // } else {
              //   month.value = null;
              //   this.setState({ month })
              // }
              // this.state.year_name_value = this.state.yearOptions.filter(
              //   x => x.value === this.state.controls.year.value
              // );
              // if (this.state.allQuarter.length === 1) {

              // }
              // if (this.state.monthOptions.length === 1) {
              controls["month"].value = this.state.monthOptions[0].value;
              this.setState({
                controls,
              });
              // }
              // if (
              //   !this.state.controls.year.value &&
              //   this.state.yearOptions &&
              //   this.state.yearOptions[0]
              // ) {
              // controls["year"].value = this.state.yearOptions[0].value;
              // controls["quarter"].value = this.state.allQuarter[0].value;
              // controls["month"].value = this.state.monthOptions[0].value;
              //   let Xerox = {
              //     month: this.state.monthOptions[0].value,
              //     year: this.state.yearOptions[0].value,
              //     quarter: this.state.allQuarter[0].value,
              //   };
              //   this.setState(
              //     {
              //       controls,
              //       year_name: this.state.yearOptions[0].name,
              //       Xerox,
              //     },
              //     () => {
              //       this.state.year_name_value = this.state.yearOptions.filter(
              //         (x) => x.value === this.state.controls.year.value
              //       );
              //     }
              //   );
              // }
              // if (filter.q === null) {

              // }
              // this.props.updateZone(this.state);
              this.props.getWarrantyData();
            }
          );
          const { yearOptions } = this.state;
          yearOptions.unshift({ value: "all", name: "All", key: "All" });
          this.setState({ yearOptions, year: "all" });
          this.props.updateZone(this.state);
          quarter.value = is_year_change === true ? "all" : quarter.value;
          month.value =
            is_year_change === true || is_quarter_change === true
              ? "all"
              : month.value;
          this.setState({
            controls,
          });
        }
      })
      .catch((err) => { });
  };

  resetFilter = () => {
    this.props.isFilterApplied(false);
    const { controls } = this.state;
    const {
      month,
      year,
      quarter,
      pcr_min,
      pcr_max,
      two_wheel_min,
      two_wheel_max,
      lcv_max,
      lcv_min,
      scv_max,
      scv_min,
      sensor_max,
      sensor_min,
    } = controls;
    month.value = null;
    year.value = null;
    quarter.value = null;
    pcr_min.value = null;
    pcr_max.value = null;
    two_wheel_min.value = null;
    two_wheel_max.value = null;
    lcv_min.value = null;
    lcv_max.value = null;
    scv_max.value = null;
    scv_min.value = null;
    sensor_max.value = null;
    sensor_min.value = null;
    this.props.isFilterApplied(false);
    setTimeout(() => {
      this.props.onSideBarClose();
    }, 1000);
    this.setState({ controls }, () => {
      this.filterAPI();
    });
    this.toggleYtd();
    let filter = {
      month: null,
      year: "all",
      quarter: null,
      pcr_min: null,
      pcr_max: null,
      two_wheel_min: null,
      two_wheel_max: null,
      scv_min: null,
      scv_max: null,
      lcv_min: null,
      lcv_max: null,
      sensor_min: null,
      sensor_max: null,
      //is_filter_apply: false
    };

    this.props.getWarrantyData(filter);
  };

  render() {
    const { yearOptions, monthOptions, allQuarter } = this.state;
    const { controls } = this.state;
    const {
      month,
      year,
      quarter,
      // pcr_max,
      // pcr_min,
      // two_wheel_min,
      // two_wheel_max,
    } = controls;
    const yearOptionToDisplay = yearOptions.map((y, index) => (
      <option value={y.value} defaultValue={index === 0 ? true : false}>
        {" "}
        {y.name}
      </option>
    ));
    const allQuarterToDisplay = allQuarter.map((q) => (
      <option value={q.value}>{q.name}</option>
    ));
    const monthOptionToDisplay = monthOptions.map((m, index) => (
      <option value={m.value} selected={index === 0}>
        {m.name}
      </option>
    ));
    const allToDisplay = <option value={null}>All</option>;

    return (
      <>
        <div id="warranty_report" isOpen={this.state.dropdownOpenYtd}>
          <div
          // color="white"
          // tag="span"
          // className=""
          // data-toggle="dropdown"
          // aria-expanded={this.state.dropdownOpenYtd}
          >
            {/* {this.state.is_filter_apply === true
              && <img className="mt-auto mb-auto cursor-pointer marginLeft" src="/images/filter_applied.svg" alt="" style={{ height: '20px', marginLeft: '-0.7rem !important' }} />
            } */}
            {/* {this.state.is_filter_apply === false
              && <img className="mt-auto mb-auto cursor-pointer marginLeft" src="/images/filter.svg" alt="" style={{ height: '15px', marginLeft: '-0.7rem !important' }} />
            } */}
          </div>
          <div
            style={{
              transform: "none",
            }}
            className={`filterContainer ${this.state.tooltipOpen &&
              this.state.tooltip_name === "warranty_report"
              ? "dropdown-toggle-ho-all-india-filter-wrapper box-shadow-40px-0-5"
              : "dropdown-toggle-ho-all-india-filter-wrapper"
              } `}
          >
            <div className="custom-dropdown-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{ marginRight: "5px" }}
                  className="custom-dropdown-header-title cursor-pointer filterheading"
                >
                  Filter
                </span>
                {/* {this.state.is_filter_apply === true
                  && <img className="mt-auto mb-auto cursor-pointer" src="/images/filter_applied.svg" alt="" style={{ height: '20px', marginLeft: '-0.7rem !important' }} />
                } */}
              </div>
              <img
                className="cursor-pointer cursor-pointer-image"
                src="/images/close.svg"
                alt=""
                onClick={this.props.onSideBarClose}
              />
            </div>
            <Row style={{ margin: "0px", marginTop: "10px", height: "420px" }}>
              <Col xl="12" xs="12" sm="12" md="12">
                <FormGroup>
                  <Label className="lbl-14px">Year</Label>
                  <br />
                  <select
                    name="year"
                    className="select-input form-control cursor-pointer"
                    value={year.value ? year.value : "all"}
                    onChange={(e) => {
                      this.onChangeYear(e);
                    }}
                  >
                    {/* <option value='all' defaultValue>All</option> */}
                    {yearOptionToDisplay}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="12" xs="12" sm="12" md="12">
                <FormGroup>
                  <Label className="lbl-14px">Quarter</Label>
                  <br />
                  <select
                    name="quarter"
                    value={quarter.value}
                    className="select-input form-control cursor-pointer"
                    onChange={(e) => {
                      this.addQuarter(e);
                    }}
                    disabled={
                      year.value === "all" || year.value === null ? true : false
                    }
                  >
                    {allQuarterToDisplay}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="12" xs="12" sm="12" md="12">
                <FormGroup>
                  <Label className="lbl-14px">Month</Label>
                  <br />
                  <select
                    name="month"
                    className={
                      !monthOptions.length
                        ? "select-input form-control"
                        : "select-input form-control cursor-pointer"
                    }
                    value={month.value}
                    disabled={
                      !monthOptions.length ||
                      (year.value === "all" || year.value === null
                        ? true
                        : false)
                    }
                    onChange={(e) => {
                      this.onChangeMonth(e);
                    }}
                  >
                    {year.value === "all" || year.value === null
                      ? allToDisplay
                      : monthOptionToDisplay}
                  </select>
                </FormGroup>
              </Col>
              <div className="line_break"></div>
              <Col>
                <Row
                  style={{ marginTop: "15px", marginLeft: 0, marginRight: 0 }}
                >
                  <Col xl="12" xs="12" sm="12" style={{ padding: "0" }}>
                    <FormGroup>
                      <Label>
                        Shortlist dealers based on tyres registered with
                        warranty
                      </Label>
                      <div id="WarrantyFilter">
                        <label class="radion-container">
                          PCR
                          <input
                            type="radio"
                            name="dealerType"
                            value="car"
                            checked={this.state.vehicleType === "car"}
                            onChange={this.onRadioButtonChange}
                          // checked={zone.value === "nz"}
                          />
                          <span class="radio-checkmark"></span>
                        </label>

                        <label
                          style={{ marginLeft: "-15px" }}
                          class="radion-container"
                        >
                          2 Wheeler
                          <input
                            type="radio"
                            name="dealerType"
                            value="2 wheeler"
                            onChange={this.onRadioButtonChange}
                            // defaultChecked={this.state.vehicleType}
                            checked={this.state.vehicleType === "2 wheeler"}
                          />
                          <span class="radio-checkmark"></span>
                        </label>

                        <label
                          style={{ marginLeft: "5px" }}
                          class="radion-container"
                        >
                          LCV
                          <input
                            type="radio"
                            name="dealerType"
                            value="lcv"
                            onChange={this.onRadioButtonChange}
                            //defaultChecked={this.state.vehicleType}
                            checked={this.state.vehicleType === "lcv"}
                          />
                          <span class="radio-checkmark"></span>
                        </label>

                        <label
                          style={{ marginLeft: "-10px" }}
                          class="radion-container"
                        >
                          SCV
                          <input
                            type="radio"
                            name="dealerType"
                            value="scv"
                            //defaultChecked={this.state.vehicleType}
                            onChange={this.onRadioButtonChange}
                            checked={this.state.vehicleType === "scv"}
                          />
                          <span class="radio-checkmark"></span>
                        </label>
                        <label
                          style={{ marginLeft: "-25px" }}
                          class="radion-container"
                        >
                          Sensor
                          <input
                            type="radio"
                            name="dealerType"
                            value="sensor"
                            onChange={this.onRadioButtonChange}
                            //defaultChecked={this.state.vehicleType}
                            checked={this.state.vehicleType === "sensor"}
                          />
                          <span class="radio-checkmark"></span>
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              {this.state.vehicleType && (
                <Col xl="12" xs="12" sm="12" md="12">
                  <FormGroup>
                    <Label className="lbl-14px">{`Enter ${this.state.vehicleType === "car"
                      ? "PCR"
                      : this.state.vehicleType === "lcv"
                        ? "LCV"
                        : this.state.vehicleType === "scv"
                          ? "SCV"
                          : this.state.vehicleType === "sensor"
                            ? "Sensors"
                            : "2 Wheeler"
                      } Tyre Range`}</Label>
                    <br />
                    <Row style={{ margin: "0px" }}>
                      <div className="filter_minmax_input_container">
                        <Input
                          placeholder="Min Count"
                          className="login-input"
                          name="input_min"
                          onChange={(e) => this.getInputMinValue(e)}
                          value={this.state.input_min}
                        ></Input>
                        <span style={{ padding: "5px 10px" }}> - </span>
                        <Input
                          placeholder="Max Count"
                          className="login-input"
                          name="input_max"
                          onChange={(e) => this.getInputMaxValue(e)}
                          value={this.state.input_max}
                        ></Input>
                      </div>
                    </Row>
                    <Row style={{ margin: "0px" }}>
                      {this.state.controls.error.valid === false &&
                        this.state.controls.error.showErrorMsg !== null && (
                          <div
                            className="error"
                            style={{ margin: "15px 15px 0px 15px" }}
                          >
                            {this.state.controls.error.showErrorMsg}
                          </div>
                        )}
                    </Row>
                  </FormGroup>
                </Col>
              )}
              {/*  */}
            </Row>
            <div>
              <Row style={{ margin: 0 }}>
                {/* <Col
                xl="6"
                xs="6"
                md="6"
                className="text-align-right mt-3"
                style={{ padding: "0px" }}
              > */}
                <div className="filter_button_container">
                  <div>
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="dark"
                      onClick={() => {
                        this.resetFilter();
                      }}
                    >
                      Reset Filters
                    </Button>
                    {/* </Col>
              <Col xl="6" xs="6" md="6" className="text-align-right mt-3"> */}
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={(e) => {
                        this.AddYTDFilter(e);
                      }}
                    >
                      Apply
                    </Button>
                    {/* </Col> */}
                  </div>
                </div>
              </Row>
            </div>
            {/*<Row style={{ margin: 0 }}>
               <Col
                xl="6"
                xs="6"
                md="6"
                className="text-align-right mt-3"
                style={{ padding: "0px" }}
              > 
              <div className="filter_button_container">
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                  color="dark"
                  onClick={() => {
                    this.resetFilter();
                  }}
                >
                  Reset Filters
                </Button>
                {/* </Col>
              <Col xl="6" xs="6" md="6" className="text-align-right mt-3"> 
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-save-button"
                  color="warning"
                  onClick={e => {
                    this.AddYTDFilter(e);
                  }}
                >
                  Apply
                </Button>
               
              </div>
            </Row>*/}
          </div>
        </div>

        <UncontrolledPopover
          placement={"right-start"}
          hideArrow={false}
          isOpen={
            this.state.tooltipOpen &&
            this.state.tooltip_name === "warranty_report"
          }
          fade={true}
          target={"warranty_report"}
          trigger="focus"
          className="warranty-report"
          style={{ transform: "translate3d(940px, 159px, 0px) !important" }}
        >
          <div ref={(r) => (this.dealerPopOver = r)}>
            <PopoverHeader className="popover-title d-flex justify-content-between">
              <span>Help Guide Tour</span>
              <span>1/1</span>
            </PopoverHeader>
            <PopoverBody
              className="d-flex-column"
              style={{ transform: "translate3d(940px, 159px, 0px) !important" }}
            >
              <span className="popover-lbl mt-2">Introducing filters:</span>
              <span className="popover-lbl-value mt-2">
                Now warranty reports can be filter from Year to Month and that
                is also category wise
                <br />
                Also category can be further filter as minimum quantity to
                identify the secondary sales in a better way
              </span>
              <br />
              {
                <div className="d-flex-row justify-content-end mb-2">
                  <span
                    className="link-blue cursor-pointer font-size-14px font-weight-bold"
                    onClick={() =>
                      this.addHelpModuleScreen(this.state.tooltip_name)
                    }
                  >
                    Close
                  </span>
                </div>
              }
            </PopoverBody>
          </div>
        </UncontrolledPopover>
        {/* <Dropdown
          id='warranty_report'
          group
          isOpen={this.state.dropdownOpenYtd}
          toggle={this.toggleYtd}
          size="sm"
          className={
            this.state.tooltipOpen && this.state.tooltip_name === 'warranty_report'
              ? 'dropdown-toggle-ho-all-india-filter-wrapper box-shadow-40px-0-5' : 'dropdown-toggle-ho-all-india-filter-wrapper'}
        > */}
        {/* <DropdownToggle
            color="white"
            caret
            className="dropdown-toggle-ho-all-india-filter"
          >
            <img className="mt-auto mb-auto ml-2 cursor-pointer" src="/images/filter.svg" alt="" style={{ height: '16px' }} />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-ho-all-india-filter">
            <div className="custom-dropdown-header">
              <span className="custom-dropdown-header-title cursor-pointer">
                Filter
              </span>
              <img
                className="cursor-pointer"
                src="/images/close1.svg"
                 alt=""
                onClick={this.toggleClose}
              />
            </div>
            <Row className="p-2" style={{ margin: "-5px", marginTop: "10px" }}>
              <Col xl="12" xs="12" sm="12" md="12">
                <FormGroup>
                  <Label className="lbl-14px">Year</Label>
                  <br />
                  <select
                    name="year"
                    className="form-control cursor-pointer"
                    value={year.value}
                    onChange={e => {
                      this.onChangeYear(e);
                    }}
                  >
                    <option value='all' defaultValue>All</option>
                    {yearOptionToDisplay}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="12" xs="12" sm="12" md="12">
                <FormGroup>
                  <Label className="lbl-14px">Quarter</Label>
                  <br />
                  <select name="quarter" value={quarter.value} className="form-control cursor-pointer"
                    onChange={e => { this.addQuarter(e); }}
                  >
                    {allQuarterToDisplay}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="12" xs="12" sm="12" md="12">
                <FormGroup>
                  <Label className="lbl-14px">Month</Label>
                  <br />
                  <select
                    name="month"
                    className={
                      !monthOptions.length
                        ? "form-control"
                        : "form-control cursor-pointer"
                    }
                    value={month.value}
                    disabled={!monthOptions.length}
                    onChange={e => {
                      this.onChangeMonth(e);
                    }}
                  >
                    {monthOptionToDisplay}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="12" xs="12" sm="12" md="12">
                <FormGroup>
                  <Label className="lbl-14px">PCR Tyre</Label>
                  <br />
                  <Row>
                    <Input type='number' placeholder="Min Count" className="login-input" name="pcr_min" style={{ width: '40%', marginRight: '15px', marginLeft: '15px' }}
                      onChange={(e) => this.validateInputChange(e)} value={pcr_min.value} ></Input>
                    <Input type='number' placeholder="Max Count" className="login-input" name="pcr_max" style={{ width: '40%', marginRight: '15px', marginLeft: '15px' }}
                      onChange={(e) => this.validateInputChange(e)} value={pcr_max.value} ></Input>
                  </Row>
                  <Row>
                    {
                      pcr_min.valid === false && pcr_min.showErrorMsg !== null &&
                      <div className="error" style={{ margin: '15px 15px 0px 15px' }}>{pcr_min.showErrorMsg}</div>
                    }
                    {
                      pcr_max.valid === false && pcr_max.showErrorMsg !== null &&
                      <div className="error" style={{ margin: '15px 15px 0px 15px' }}>{pcr_max.showErrorMsg}</div>
                    }
                  </Row>
                </FormGroup>
              </Col>
              <Col xl="12" xs="12" sm="12" md="12">
                <FormGroup>
                  <Label className="lbl-14px">2 Wheeler Count</Label>
                  <br />
                  <Row>
                    <Input type='number' placeholder="Min Count" className="login-input" name="two_wheel_min" style={{ width: '40%', marginRight: '15px', marginLeft: '15px' }}
                      onChange={(e) => this.validateInputChange(e)} value={two_wheel_min.value} ></Input>
                    <Input type='number' placeholder="Max Count" className="login-input" name="two_wheel_max" style={{ width: '40%', marginRight: '15px', marginLeft: '15px' }}
                      onChange={(e) => this.validateInputChange(e)} value={two_wheel_max.value} ></Input>
                  </Row>
                  {
                    two_wheel_min.valid === false && two_wheel_min.showErrorMsg !== null &&
                    <div className="error" style={{ margin: '15px' }}>{two_wheel_min.showErrorMsg}</div>
                  }
                  {
                    two_wheel_max.valid === false && two_wheel_max.showErrorMsg !== null &&
                    <div className="error" style={{ margin: '15px' }}>{two_wheel_max.showErrorMsg}</div>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                xl="6"
                xs="6"
                md="6"
                className="text-align-right mt-3"
                style={{ padding: "0px" }}
              >
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                  color="dark"
                  onClick={() => {
                    this.resetFilter();
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col xl="6" xs="6" md="6" className="text-align-right mt-3">
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-save-button"
                  color="warning"
                  onClick={e => {
                    this.AddYTDFilter(e);
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </DropdownMenu>

          <UncontrolledPopover
            placement={'right-start'}
            trigger="legacy"
            hideArrow={false}
            isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'warranty_report'}
            fade={true}
            target={'warranty_report'}
            trigger="focus"
            className='warranty-report'
            style={{ transform: 'translate3d(940px, 159px, 0px) !important' }}
          >
            <div ref={r => this.dealerPopOver = r}>
              <PopoverHeader className="popover-title d-flex justify-content-between">
                <span>Help Guide Tour</span>
                <span>1/1</span>
              </PopoverHeader>
              <PopoverBody className="d-flex-column" style={{ transform: 'translate3d(940px, 159px, 0px) !important' }}>
                <span className="popover-lbl mt-2">Introducing filters:</span>
                <span className="popover-lbl-value mt-2">
                  Now warranty reports can be filter from Year to Month and that is also category wise
                  <br />
                  Also category can be further filter as minimum quantity to identify the secondary sales in a better way
                </span>
                <br />
                {<div className="d-flex-row justify-content-end mb-2">
                  <span className="link-blue cursor-pointer font-size-14px font-weight-bold" onClick={() => this.addHelpModuleScreen(this.state.tooltip_name)}>
                    Close
                    </span>
                </div>}
              </PopoverBody>
            </div>
          </UncontrolledPopover>
        </Dropdown> */}
      </>
    );
  }
}

export default WarrantyFilter;
