import {
  Button,
  Table,
  Input,
  Row,
  Card, CardBody,
} from 'reactstrap';
import React, { Component } from 'react';
import classnames from 'classnames';
import CustomerManagementService from '../../services/CustomerManagementService';
import Moment from 'react-moment';
import Pagination from '../Pagination/Pagination';
import swal from 'sweetalert';
import ModalService from '../../services/ModalService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import lodash from 'lodash'; // library added for debounce functionality
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

class BlockCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      selectAll: 0,
      customers: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      isSelectAllEntry: false,
      isLoading: false,
      search: ''
    };
    this.searchData = lodash.debounce(this.searchData, 500);
  }

  componentDidMount() {
    GoogleAnalticsService.pageView('Block List View');
    this.getBlockCustomer();
  }


  searchData = (search, page) => {
    this.getBlockCustomer()
  }

  handleSearchChange = (e) => {
    /**Method to handle the search bar change  */
    let searchVal = e.target.value;
    this.setState({ page: 1, search: searchVal });
    this.searchData(searchVal, 1);
  }

  resetState = () => {
    this.setState({
      selected: [],
      selectAll: 0,
      customers: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      isSelectAllEntry: false,
      isLoading: false,
    });
  }

  checkSelectAll = () => {
    let { selected, customers } = this.state;
    let selectAll = 0;
    let perPageCointSelection = 0;
    if (selected.length > 0) {
      selected.forEach(id => {
        let index = customers.findIndex(x => x.customer_id === id);
        if (index > -1) {
          perPageCointSelection++;
        }
      });
      if (perPageCointSelection === customers.length) {
        selectAll = 1;
      } else if (perPageCointSelection === 0) {
        selectAll = 0;
      } else {
        selectAll = 2;
      }
    } else {
      selectAll = 0;
    }
    return selectAll;
  }

  selectAllEntry = (isSelectAllEntry) => {
    let { selected, customers } = this.state;
    if (isSelectAllEntry) {
      this.state.customers.forEach(x => {
        x.active = true;
        if (selected.indexOf(x.customer_id) === -1) {
          selected.push(x.customer_id);
        }
      });
      this.setState((prevState) => {
        return {
          ...prevState,
          isSelectAllEntry: isSelectAllEntry,
          selectAll: 1
        }
      });
    } else {
      customers.forEach(x => {
        x.active = false;
      });
      this.setState((prevState) => {
        return {
          ...prevState,
          isSelectAllEntry: isSelectAllEntry,
          selected: [],
          selectAll: 0,
          customers: customers
        }
      });
    }

  }

  toggleRow = (id) => {
    let { selected, customers } = this.state;
    let index = selected.findIndex(x => x === id);
    let indexData = customers.findIndex(x => x.customer_id === id);
    if (index > -1) {
      selected.splice(index, 1);
      customers[indexData].active = false;
    } else {
      if (selected.indexOf(id) === -1) {
        selected.push(id);
      }
      customers[indexData].active = true;
    }
    let selectAll = this.checkSelectAll();
    this.setState({ selected, selectAll: selectAll, customers, isSelectAllEntry: false });
  }

  toggleSelectAll() {
    let { selected } = this.state;
    if (this.state.selectAll === 0 || this.state.selectAll === 2) {
      //selected = [];
      this.state.customers.forEach(x => {
        x.active = true;
        if (selected.indexOf(x.customer_id) === -1) {
          selected.push(x.customer_id);
        }
      });
      if (this.state.selectAll === 2) {
        this.state.selectAll = 0;
      }
    } else if (this.state.selectAll === 1) {
      if (this.state.isSelectAllEntry) {
        selected = [];
        this.state.customers.forEach(x => {
          x.active = false;
        });
      } else {
        this.state.customers.forEach((x) => {
          selected.forEach((element, i) => {
            if (element === x.customer_id) {
              x.active = false;
              selected.splice(i, 1)
            }
          });
        });
      }
    }

    this.setState({
      selected: selected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
      isSelectAllEntry: false
    });
  }

  isItemSelected = (id) => {
    let { selected } = this.state;
    let isChecked = false;
    selected.forEach(element => {
      if (id === element) {
        isChecked = true;
      }
    });
    return isChecked;
  }


  handleInputChange = (e) => {
    let { pageSize } = this.state;
    pageSize = e.target.value;
    this.setState((prevState) => {
      return {
        ...prevState,
        pageSize: pageSize,
        page: 1
      }
    });
    setTimeout(() => {
      this.getBlockCustomer();
    }, 1);
  }

  handlePageChange = (page) => {
    // const { currentUser } = this.state
    // const uuid = currentUser && currentUser.uuid ? currentUser.uuid : null;
    this.setState({ page: page });
    this.setState((prevState) => {
      return {
        ...prevState,
        page: page
      }
    });
    setTimeout(() => {
      this.getBlockCustomer();
    }, 1);

  }

  getBlockCustomer = () => {
    let reqData = {
      page: this.state.page,
      pageSize: this.state.pageSize
    };
    reqData.search_query = this.state.search ? this.state.search : '';
    this.setState({ isLoading: true });
    CustomerManagementService.getBlockCustomers(reqData)
      .then(res => {
        let { customers, count, selected, selectAll } = this.state;
        if (res.data.data && res.data.data.customers.length > 0) {
          customers = res.data.data.customers;
          count = res.data.data.count;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState((prevState) => {
            return {
              ...prevState,
              customers,
              count,
              totalPages,
              isLoading: false,
              selectAll: this.state.isSelectAllEntry ? 0 : selectAll
            }
          });
          if (this.state.isSelectAllEntry) {
            this.state.customers.forEach(x => {
              x.active = true;
              if (selected.indexOf(x.customer_id) === -1) {
                selected.push(x.customer_id);
              }
            });
            this.setState((prevState) => {
              return {
                ...prevState,
                selectAll: selectAll
              }
            });
          } else {
            setTimeout(() => {
              let selectAll = this.checkSelectAll();
              this.setState((prevState) => {
                return {
                  ...prevState,
                  selectAll: selectAll
                }
              });
            }, 1);
          }
        } else {
          customers = [];
          count = 0;
          this.setState((prevState) => {
            return {
              ...prevState,
              customers,
              count,
              selectAll: 0,
              isLoading: false
            }
          });
        }

      })
      .catch(e => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  }

  unBlockCustomers = (customer = null) => {
    let reqData = {};
    if (customer) {
      reqData.customers = [customer];
    } else {
      /**The value of the selected customer id from the block list is passed as an customers object which needs to be unblocked */
      let { selected } = this.state;
      reqData.customers = selected;
      if (this.state.isSelectAllEntry) {
        reqData.customers = [];
      }
    }
    this.setState({ isLoading: true });
    CustomerManagementService.unBlockCustomers(reqData)
      .then(res => {
        this.setState({ isLoading: false });
        this.resetState();
        if (res.data.message) {
          swal({
            title: '',
            text: res.data.message,
            icon: 'success',
            type: 'success',
          }).then(value => {
            this.getBlockCustomer();
          });
        }
      })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : '';
        ModalService.openAlert('', message, 'error');
        this.setState({ isLoading: false });
      });
  }




  render() {
    const { count, selected, totalPages, pageSize, search } = this.state;
    return (
      <>
        {/* <hr style={{ border: 'solid 1px #e0e2e4' }} />
        <br /> */}
        <span className="header-title">Block List</span>
        <br />
        <br />

        {this.state.isLoading && <CustomSpinner />}
        <Card>
          <CardBody>
            <Row style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '1%', justifyContent: 'space-between' }}>
              <div className="d-flex-row">
                <div style={{ width: '290px' }} className="d-flex-row search-input">
                  <Input
                    className="report-search-input"
                    name="search"
                    placeholder="Search by customer name or mobile no"
                    onChange={this.handleSearchChange}
                    value={search}
                  ></Input>
                  <img className="icon-width" src="images/search.svg" style={{ marginLeft: '-25px' }} alt='' />
                </div>
                {
                  selected.length > 0 ? <div className="entries-selected block-customer-entry">
                    {
                      totalPages > 1 ?
                        <div>
                          <span className="color-757575">{!this.state.isSelectAllEntry ? selected.length : count} entries selected.</span>
                          {
                            this.state.pageSize < count && this.state.selectAll === 1 && <>
                              {
                                !this.state.isSelectAllEntry ? <span onClick={() => this.selectAllEntry(true)}>Select all {count} entries.</span> :
                                  <span onClick={() => this.selectAllEntry(false)}>Clear Selection.</span>
                              }
                            </>
                          }
                        </div> :
                        <div><span className="color-757575">{!this.state.isSelectAllEntry ? selected.length : count} {selected.length > 1 ? 'entries' : 'entry'} selected.</span>
                          {
                            this.state.pageSize < count && this.state.selectAll === 1 && <>
                              {
                                !this.state.isSelectAllEntry ? <span onClick={() => this.selectAllEntry(true)}>Select all {count} entries.</span> :
                                  <span onClick={() => this.selectAllEntry(false)}>Clear Selection.</span>
                              }
                            </>
                          }
                        </div>
                    }
                  </div> : ''
                }
              </div>
              {
                selected && selected.length > 0 &&
                <div>
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    onClick={() => this.unBlockCustomers()}
                  >
                    Unblock
                  </Button>
                </div>
              }
            </Row>
            <Table className="mb-0 table-responsive tbl-about tbl-about text-align-left">
              <thead>
                <tr style={{ backgroundColor: '#f6f6f6' }} className="customer-list-table-header">
                  <th className="border-1-5">
                    {
                      count > 0 && <>
                        {
                          /**When complete list is not selected then show <img/> in the selectAll checkbox */
                          this.state.selectAll === 2 ? <img onClick={(e) => this.toggleSelectAll()} src="images/group-16.svg" style={{ height: '18px' }} alt='' /> :
                            /**When complete list is selected show change the checked value to true */
                            (this.state.selectAll === 1 ? <label className="custom-checkboax1--container">
                              <input type="checkbox" name="completed"
                                checked={true}
                                onChange={(e) => this.toggleSelectAll()}
                              />
                              <span className="checkmark1" />
                              {/**When nothing is selected show empty selectAll checkbox by changing checked value to false*/}
                            </label> : <label className="custom-checkboax1--container">
                              <input type="checkbox" name="completed"
                                checked={false}
                                onChange={(e) => this.toggleSelectAll()}
                              />
                              <span className="checkmark1" />
                            </label>)
                        }
                      </>
                    }
                  </th>
                  <th className="text-align-left border-1-5" style={{ width: '220px' }}>Customer Name</th>
                  <th className="text-align-left border-1-5">Mobile No.</th>
                  <th className="text-align-left border-1-5">Created On</th>
                  <th className="text-align-left border-1-5">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.customers.map((row, i) => (
                    <tr className={classnames({ 'active-tbl-row': row.active }, 'customer-list-row')}>
                      <td className="border-1-5">
                        <label className="custom-checkboax1--container">
                          <input type="checkbox" name="main_road" checked={this.isItemSelected(row.customer_id)}
                            onChange={() => this.toggleRow(row.customer_id)}
                          />
                          <span className="checkmark1" />
                        </label>
                      </td>
                      <td className="text-align-left border-1-5">
                        {row.customer_name !== ' ' && row.customer_name ? row.customer_name : 'No Name'}
                      </td>
                      <td className="text-align-left border-1-5">
                        {row.phone_number}
                      </td>
                      <td className="text-align-left">
                        <Moment format="DD MMM YYYY">
                          {row.created_at}
                        </Moment>
                      </td>

                      <td className="border-1-5 text-align-left">
                        <img className="cursor-pointer" style={{ height: '13px' }}
                          onClick={() => this.unBlockCustomers(row.customer_id)} src='images/delete.svg' alt='' />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            {
              count <= 0 && <div className="no-data tbl-offtake-summary" style={{ maxWidth: '100%' }}>
                Currently, there is no data to display
              </div>
            }
            {/**If the count value is greater than 0 , then show Rows per page option */}
            {count > 0 &&
              <div className="d-flex-row" style={{ marginTop: '10px', justifyContent: 'flex-end' }}>
                <span className="Rows-per-page">Rows per page:</span>
                <select className="form-control rows-per-page-select" name="pageSize" value={pageSize}
                  onChange={(e) => this.handleInputChange(e)} style={{ width: '65px' }}>
                  <option value="2">2</option>
                  <option value='10'>10</option>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                </select>
                &nbsp;&nbsp;

                <div style={{ marginTop: '5px' }}><Pagination
                  margin={2}
                  page={this.state.page}
                  count={this.state.totalPages}
                  pageSize={this.state.pageSize}
                  totalRecords={count}
                  onPageChange={this.handlePageChange}
                ></Pagination>
                </div>
              </div>
            }
          </CardBody>
        </Card>
      </>
    );
  }
}

export default BlockCustomer;