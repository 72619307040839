import React from "react";
import "./ComparisonYear.css";
import { FaChevronDown } from "react-icons/fa";
import { Row, Col } from "reactstrap";

const ComparisonYear = ({
  cmpYear,
  setCmpYear,
  handleChangeCmpYear,
  getCmpYearData,
}) => {
  return (
    <div className=" flex align-items-center comparisonYear">
      <div className="radioInnerTitle mb-3">
        Select Financial Year for comparison
      </div>
      <Row className="yearWrap">
        <Col className="mb-2 flex">
          <label>Select Year 1</label>
          <select
            className="form-control select"
            name="first_year"
            value={cmpYear.first_year || ""}
            onChange={handleChangeCmpYear}
          >
            <option value="">
              Select Year
            </option>
            <option value="2019">FY 19-20</option>
            <option value="2020">FY 20-21</option>
            <option value="2021">FY 21-22</option>
            <option value="2022">FY 22-23</option>
          </select>
          {/* <span className='select-dwn-icon'><FaChevronDown/></span> */}
        </Col>
        <Col className="mb-2 flex">
          <label>Select Year 2</label>
          <select
            className="form-control select"
            name="sec_year"
            value={cmpYear.sec_year || ""}
            onChange={handleChangeCmpYear}
          >
            <option value="">
              Select Year
            </option>
            <option value="2019">FY 19-20</option>
            <option value="2020">FY 20-21</option>
            <option value="2021">FY 21-22</option>
            <option value="2022">FY 22-23</option>
          </select>
          {/* <span className='select-dwn-icon'><FaChevronDown/></span> */}
        </Col>
        {/* <button
          type="button"
          className="btn btn-primary btn-sm mt-1"
          onClick={getCmpYearData}
        >
          Get Data
        </button> */}
      </Row>
    </div>
  );
};

export default ComparisonYear;
