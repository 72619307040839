import React, { Component } from 'react';
import {
  Button,
  Row, Col
} from 'reactstrap';

// css
import './OnBoardingComponent2.css';

// component
import VideoComponent from '../VideoComponent/VideoComponent';
import AboutJKAdvantage from '../AboutJKAdvantage/AboutJKAdvantage';
import HowItWorks from '../HowItWorks/HowItWorks';

// modal 

// const title = 'Logout';
// const modalButtons = null;

class OnBoardingComponent2 extends Component {

  goToNextPage = () => {
    this.props.handleGoToNextPage('reg');
  }

  render() {

    return <div>
      <div style={{ height: '55px', paddingTop: '5px' }}>
        <img src="images/logo-small.png" height="30px" width="auto" alt="" />
      </div>
      <br />
      <Row>
        <Col className="page-title text-align-center">
          Know your platform
        </Col>
      </Row>
      <br />
      <Row style={{ padding: '14px' }}>
        {/* <Col xl="1" md="12" xs="12">

        </Col> */}
        <Col xl="4" md="12" xs="12" className="margin-top-10" >
          {/* <Row className="height-100"> */}
          {/* <Col> */}
          <VideoComponent />
          {/* </Col> */}
          {/* </Row> */}
        </Col>
        <Col xl="4" md="12" xs="12" className="margin-top-10">
          <AboutJKAdvantage />
        </Col>
        <Col xl="4" md="12" xs="12" className="margin-top-10">
          <HowItWorks />
        </Col>
        {/* <Col xl="1" md="12" xs="12">

        </Col> */}

      </Row>
      <br />
      <Row>
        <Col xl="1" className="text-align-center" />
        <Col className="text-align-center">
          <Button className="mb-1 mr-1 btn-pill action-button" style={{ width: '140px', height: '44px', fontWeight: 'bold', fontSize: '21px', color: '#495057' }} color="warning" onClick={this.goToNextPage}>
            Next
          </Button>
          {/* <Button onClick={this.goToNextPage}>Next</Button> */}
        </Col>

        <Col xl="1" />
      </Row>
      <div className="logout" onClick={this.props.openLogoutModal}> <img src="images/logout.svg" alt="logout" /></div>
    </div >;
  }
}

export default OnBoardingComponent2;