import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./SalesDataUpload.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import SalesDataUploadService from "../../services/SalesDataUploadService";
import ModalService from "../../services/ModalService";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import Storage from "../../services/Storage";
import DownloadModal from "../../components/SalesDataUpload/DownloadModal/DownloadModal";

class SalesDataUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sapId: Storage.getUserDetail().sap_id,
      controls: {
        uploadDataType: {
          value: "sales_data",
          valid: null,
          touched: false,
          nullValue: null,
        },
        application: {
          value: { value: "All", label: "All" },
          valid: null,
          touched: false,
          nullValue: null,
        },
        transactionDate: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
        },
        sapId: {
          value: "0",
          valid: null,
          touched: false,
          nullValue: null,
        },
        selectedFile: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
        },
        tyreType: {
          value: "regular",
          valid: null,
          touched: false,
          nullValue: null,
        },
      },
      applicationData: [
        {
          value: "All",
          label: "All",
        },
        {
          value: "Advantage",
          label: "Advantage",
        },
        {
          value: "Baadshah",
          label: "Baadshah",
        },
      ],
      isLoading: false,
      isInvalid: true,
      isDownloadModal: false,
      nonExistmaterialKeyArr: [],
      nonExistSapIdArr: [],
      isModalLoading: false,
      uploadErrMsg: '',
    };
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  onSubmit = async (isForceUpdate) => {
    let { controls, isDownloadModal } = this.state;
    const {
      transactionDate,
      selectedFile,
      uploadDataType,
      tyreType,
    } = controls;
    try {
      this.setState({ isLoading: true });
      const tempTransactionDate = moment(transactionDate.value).format(
        "YYYY-MM-DD"
      );
      const formData = new FormData();
      formData.append("application", "All");
      formData.append("transactionDate", tempTransactionDate);
      formData.append("salesReport", selectedFile.value);
      formData.append("sapId", "0");

      let formDataMobility = new FormData();
      formDataMobility.append("transactionDate", tempTransactionDate);
      formDataMobility.append("mobilityFleetSalesReport", selectedFile.value);
      formDataMobility.append(
        "sapId",
        this.state.sapId ? this.state.sapId : "0"
      );

      if (isForceUpdate) {
        formData.append("isForceUpload", true);
        formDataMobility.append("isForceUpload", true);
      }

      let data = '';

      if (uploadDataType.value === "sales_data") {
        formData.append("isSensorData", tyreType.value === "smart");
        data = await SalesDataUploadService.SalesDataUpload(formData);
      } else {
        data = await SalesDataUploadService.MobilityGRNDataUpload(
          formDataMobility
        );
      }

      if (data.data.success) {
        ModalService.openAlert(
          "Congratulations!",
          data.data.message,
          "success"
        );
        if (isForceUpdate) {
          this.setState({ isDownloadModal: !isDownloadModal })
        }
        this.resetData();
      } else {
        ModalService.openAlert("Sorry!", data.data.message, "error");
      }
      this.setState({
        isLoading: false,
        isModalLoading: false
      });
    }
    catch (error) {
      console.log('error', error)
      if (error.response) {
        console.log('error response', error.response)
        let { data } = error.response;
        // let data = {
        //   "success": false,
        //   "error": [
        //     {
        //       "title": "Error",
        //       "message": "Material Keys and Sap Ids not found in the database. Please insert to process further",
        //       "data": {
        //         "nonExistingMaterialKeys": [
        //           "1D25118Z14008QXPC0",
        //           "1225170015012LSTL0",
        //           "1211182016018JJXL0",
        //           "1225170016012LSTL0",
        //           "1121111020016KDEP0",
        //           "1111110020016JVTL0"
        //         ],
        //         "nonExistingSapIds": [
        //           "1103246",
        //           "1121267",
        //           "1121298",
        //           "1121380",
        //           "1121396",
        //           "1404744",
        //           "1404813",
        //           "1906899"
        //         ]
        //       }
        //     }
        //   ]
        // }

        if (data.error && data.error.length > 0) {
          let errorMessage = data.error[0].message;
          if (data.error[0].hasOwnProperty("data")) {
            this.setState({
              nonExistmaterialKeyArr: data.error[0].data && data.error[0].data.nonExistingMaterialKeys ? data.error[0].data.nonExistingMaterialKeys : [],
              nonExistSapIdArr: data.error[0].data && data.error[0].data.nonExistingSapIds ? data.error[0].data.nonExistingSapIds : [],
              uploadErrMsg: errorMessage,
              isDownloadModal: !isDownloadModal
            })
          } else {
            ModalService.openAlert("", errorMessage, "error");
            this.setState({ isLoading: false });
          }
        }
      } else {
        this.setState({ isLoading: false });
      }
    }
  };

  genrateNonExistmaterialKeyCsv = async () => {
    let { nonExistmaterialKeyArr } = this.state;
    this.setState({ isModalLoading: true });

    const csvData = nonExistmaterialKeyArr && nonExistmaterialKeyArr.length > 0 ? nonExistmaterialKeyArr.map((mKey) => [mKey]).join('\n') : [];

    return new Promise((resolve) => {
      this.downloadCSV(csvData, 'non exist material key list.csv');
      resolve();
    }).then(() => {
      this.setState({ isModalLoading: false });
    });

  }


  genrateNonExistSapIdCsv = async () => {
    let { nonExistSapIdArr } = this.state;
    this.setState({ isModalLoading: true });

    const csvData = nonExistSapIdArr && nonExistSapIdArr.length > 0 ? nonExistSapIdArr.map((sapId) => [sapId]).join('\n') : [];

    return new Promise((resolve) => {
      this.downloadCSV(csvData, 'non exist sap id list.csv');
      resolve();
    }).then(() => {
      this.setState({ isModalLoading: false });
    });

  }

  // Trigger CSV download
  downloadCSV = (csv, filename) => {
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
  };

  handleDownloadModalNoClick = () => {
    let { isDownloadModal } = this.state;
    this.setState({ isDownloadModal: !isDownloadModal, isLoading: false })
  }

  handlDownloadModalYesClick = () => {
    this.setState({ isModalLoading: true });
    this.onSubmit(true);
  }

  resetData = () => {
    const { controls } = this.state;
    const { application, transactionDate, selectedFile, tyreType } = controls;
    application.value = { value: "All", label: "All" };
    transactionDate.value = "";
    tyreType.value = "regular";
    selectedFile.value = "";
    if (this.fileInput) {
      this.fileInput.value = "";
    }

    this.setState({ controls, isInvalid: true });
  };

  handleValidation() {
    const { controls } = this.state;
    let { isInvalid } = this.state;
    const { transactionDate, selectedFile } = controls;
    isInvalid = false;

    if (!transactionDate.value || !selectedFile.value) {
      isInvalid = true;
    }

    this.setState({ isInvalid });
  }

  render() {
    const { controls,
      // applicationData, 
      isInvalid, isLoading, isDownloadModal, isModalLoading, uploadErrMsg, nonExistmaterialKeyArr, nonExistSapIdArr } = this.state;
    const {
      // application, 
      transactionDate, uploadDataType, tyreType } = controls;
    return (
      <>
        <div>
          {isLoading && <CustomSpinner />}
          <div className="page-header">Sales Data Upload</div>

          <Row style={{ marginTop: "25px" }}>
            <Col xl="6" md="6" sm="6" xs="6">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="margin-15">
                        <div className="d-flex-row pcrtype-wrapp">
                          <label className="custom-checkboax-radio--container">
                            <input
                              type="radio"
                              name="uploadDataType"
                              onChange={this.handleInputChange}
                              value="sales_data"
                              checked={uploadDataType.value === "sales_data"}
                            />
                            <span className="checkmark-radio" />
                          </label>
                          <span style={{ marginLeft: "10px" }}>SAP Data</span>
                        </div>
                        <div className="d-flex-row pcrtype-wrapp">
                          <label className="custom-checkboax-radio--container">
                            <input
                              type="radio"
                              name="uploadDataType"
                              onChange={this.handleInputChange}
                              value="mobility_data"
                              checked={uploadDataType.value === "mobility_data"}
                            />
                            <span className="checkmark-radio" />
                          </label>
                          <span style={{ marginLeft: "10px" }}>
                            Import Mobility GRN Data Upload
                          </span>
                        </div>
                      </Row>

                      <FormGroup
                        className="margin-15"
                        style={{ marginBottom: "0" }}
                      >
                        <Label>Transaction Date</Label>
                        <br />

                        <Label
                          onClick={(e) =>
                            this.calendar1.state.open && e.preventDefault()
                          }
                          style={{ width: "100%" }}
                        >
                          <InputGroup>
                            <DatePicker
                              className="form-control"
                              onChange={(e) =>
                                this.handleInputChange({
                                  target: { name: "transactionDate", value: e },
                                })
                              }
                              name="transactionDate"
                              placeholderText="Select Date"
                              selected={transactionDate.value}
                              dateFormat="dd/MM/yyyy"
                              showMonthDropdown
                              ref={(r) => (this.calendar1 = r)}
                              shouldCloseOnSelect
                              showYearDropdown
                              maxDate={new Date()}
                              dropdownMode="select"
                            />
                            <InputGroupAddon addonType="append">
                              <div className="input-group-text">
                                <FontAwesomeIcon
                                  htmlFor="datepicker"
                                  icon={faCalendarAlt}
                                />
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                        </Label>
                      </FormGroup>
                      {uploadDataType.value === "sales_data" && (
                        <Row className="margin-15">
                          <div className="d-flex-row pcrtype-wrapp">
                            <label className="custom-checkboax-radio--container">
                              <input
                                type="radio"
                                name="tyreType"
                                onChange={this.handleInputChange}
                                value="regular"
                                checked={tyreType.value === "regular"}
                              />
                              <span className="checkmark-radio" />
                            </label>
                            <span style={{ marginLeft: "10px" }}>Regular</span>
                          </div>
                          <div className="d-flex-row pcrtype-wrapp">
                            <label className="custom-checkboax-radio--container">
                              <input
                                type="radio"
                                name="tyreType"
                                onChange={this.handleInputChange}
                                value="smart"
                                checked={tyreType.value === "smart"}
                              />
                              <span className="checkmark-radio" />
                            </label>
                            <span style={{ marginLeft: "10px" }}>Smart Tyre</span>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  {/* <Row style={{ marginTop: '10px' }}>
                  <Col>
                    <Label for="username">Application</Label><br />
                    <Select
                      value={application.value}
                      onChange={(e) => this.handleInputChange({ target: { name: 'application', value: e } })}
                      placeholder="Select Application"
                      options={applicationData}
                    />
                  </Col>
                </Row> */}

                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      {/* <Label for="username">Application</Label><br /> */}
                      <input
                        type="file"
                        accept=".xlsx"
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "selectedFile",
                              value: e.target.files[0],
                            },
                          })
                        }
                        className="width-100"
                        ref={(ref) => (this.fileInput = ref)}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "25px" }}>
                    <Col>
                      <Button
                        className="mb-1 mr-1 btn-pill action-button login-button"
                        color="warning"
                        disabled={isInvalid}
                        onClick={() => this.onSubmit(false)}
                        type="submit"
                        style={{ width: "139px" }}
                      >
                        <b>Submit</b>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <DownloadModal
            isOpen={isDownloadModal}
            toggle={() => this.setState({ isDownloadModal: !isDownloadModal })}
            isModalLoading={isModalLoading}
            uploadErrMsg={uploadErrMsg}
            nonExistmaterialKeyArr={nonExistmaterialKeyArr}
            nonExistSapIdArr={nonExistSapIdArr}
            genrateNonExistmaterialKeyCsv={this.genrateNonExistmaterialKeyCsv}
            genrateNonExistSapIdCsv={this.genrateNonExistSapIdCsv}
            handleDownloadModalNoClick={this.handleDownloadModalNoClick}
            handlDownloadModalYesClick={this.handlDownloadModalYesClick}
          />
        </div>
      </>
    );
  }
}

export default SalesDataUpload;
