import React, { Component } from "react";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, UncontrolledButtonDropdown
} from "reactstrap";
import './DealerDashboard.css';
import lodash from "lodash";

/**
  * @description 
  * Required Props
  * 1.option_lbl  => display label
    2.option_key  =>set key
    3.selected_dropdown_item  => set selected item
    4.dropdown_list  => dropdownlist
    5.setSelectedItem => set selected items
  */
class CustomDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  componentDidMount() {
  }



  toggle = () => {
    let { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen
    });
  }

  onClick = (item) => {
    let option_key = this.props.option_key ? this.props.option_key : 'id';
    this.props.setSelectedItem(item[option_key], item);
  }

  render() {
    let selected_dropdown = this.props.selected_dropdown_item ? this.props.selected_dropdown_item : '';
    let option_lbl = this.props.option_lbl ? this.props.option_lbl : 'name';
    let option_key = this.props.option_key ? this.props.option_key : 'id';
    let placeholder = this.props.placeholder ? this.props.placeholder : 'Select item';
    let dropdown_list = this.props.dropdown_list ? this.props.dropdown_list : [];
    let selected_dropdown_item = '';
    let index = -1;
    if (selected_dropdown && dropdown_list.length > 0) {
      index = dropdown_list.findIndex(x => lodash.isEqual(x[option_key], selected_dropdown))
      if (index > -1) {
        selected_dropdown_item = dropdown_list[index][option_lbl];

      }
    }

    return (
      <>
        <UncontrolledButtonDropdown>
          <Dropdown
            group
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
            size="sm"
            className="custom-dropdown"
          >
            <DropdownToggle
              color="white"
              caret
              className="custom-dropdown-selected-lbl"
            >
              {selected_dropdown_item
                ? selected_dropdown_item : placeholder}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-shadow"
              style={{ minWidth: this.props.minWidth ? this.props.minWidth : '15rem', maxHeight: this.props.maxHeight ? this.props.maxHeight : 'max-content', overflow: 'auto' }}>
              {
                dropdown_list.map((item, i) => (
                  <DropdownItem
                    active={i === index}
                    onClick={() => this.onClick(item)}
                  >{item[option_lbl]}
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          </Dropdown>
        </UncontrolledButtonDropdown>

      </>
    );
  }
}

export default CustomDropDown;
