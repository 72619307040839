import React, { Component } from 'react';
import {
  Button,
  Row, Col,
  Card, Input
} from 'reactstrap';
import _ from 'lodash';
// import { connect } from 'react-redux';
// import { setMerchandiseCart } from '../../actions/UserAction';
import Storage from '../../services/Storage';
import { formatNumber } from '../../utils/utils';
import ModalService from '../../services/ModalService';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

class MerchandiseCatalogue extends Component {
  constructor(props) {
    super(props);
    const placeOrderCartItems = Storage.getPlaceOrderCartItems();
    this.state = {
      balance_coins: props.balance_coins ? props.balance_coins : 0,
      redeemed_coins: props.redeemed_coins ? props.redeemed_coins : 0,
      total_coins: props.total_coins ? props.total_coins : 0,
      coinsOnCart: 0,
      cartItems: props.cartItems ? props.cartItems : [],
      itemCountCart: 0,
      placeOrderCartItems: placeOrderCartItems || []
    };
  }

  setCartItems = (cartItems) => {
    this.setState({
      cartItems,
      balance_coins: this.props.balance_coins ? this.props.balance_coins : 0,
      redeemed_coins: this.props.redeemed_coins ? this.props.redeemed_coins : 0,
      total_coins: this.props.total_coins ? this.props.total_coins : 0
    });
    setTimeout(() => {
      this.checkCartItems();
    }, 1);
  }

  checkCartItems() {
    let { cartItems, coinsOnCart } = this.state;
    if (cartItems && cartItems.length > 0) {
      coinsOnCart = 0;
      this.state.placeOrderCartItems.forEach(row => {
        const indexCartItem = cartItems.findIndex(x => x.item_id === row.item_id);
        if (indexCartItem > -1) {
          coinsOnCart = row.total_quantity * row.coins_per_piece + coinsOnCart;
          cartItems[indexCartItem] = row;
        }
      });
    }
    this.setState({
      cartItems,
      coinsOnCart
    });
  }

  componentDidMount() {
    this.checkCartItems();
  }

  checkTotalCoins = (item, isAddToCart) => {
    let { coinsOnCart, placeOrderCartItems } = this.state;
    const tempPlaceOrderCartItems = _.cloneDeep(placeOrderCartItems);
    const indexCartItem = tempPlaceOrderCartItems.findIndex(x => x.item_id === item.item_id);
    let tempCoinsOnCart = coinsOnCart;
    if (indexCartItem <= -1) {
      tempCoinsOnCart = 0;
      tempPlaceOrderCartItems.push(item);
      tempPlaceOrderCartItems.forEach(element => {
        tempCoinsOnCart = element.total_quantity * element.coins_per_piece + tempCoinsOnCart;
      });
      if (tempCoinsOnCart > this.state.balance_coins) {
        ModalService.openAlert('Sorry!', `Your available coins are ${this.state.balance_coins} which is less than items you are requesting.`, 'warning');
        return;
      }
      placeOrderCartItems = tempPlaceOrderCartItems;

      coinsOnCart = tempCoinsOnCart;
      item.isAddToCart = true;
      this.setState({ coinsOnCart, placeOrderCartItems });
      return;
    }
    if (isAddToCart) {
      GoogleAnalticsService.event('Trade Scheme', 'Merchandise Item Plus Click');
      tempCoinsOnCart = 0;
      placeOrderCartItems.forEach(element => {
        tempCoinsOnCart = element.total_quantity * element.coins_per_piece + tempCoinsOnCart;
      });
      if (tempCoinsOnCart > this.state.balance_coins) {
        placeOrderCartItems.splice(indexCartItem, 1);
        item.isInvalid = true;
        this.setState({});
        setTimeout(() => {
          item.isInvalid = false;
          this.setState({});
        }, 5000);
        return;
      }
      coinsOnCart = tempCoinsOnCart;
      item.isAddToCart = true;
      this.setState({ coinsOnCart, placeOrderCartItems });
    } else {
      placeOrderCartItems.splice(indexCartItem, 1);
      tempCoinsOnCart = 0;
      placeOrderCartItems.forEach(element => {
        tempCoinsOnCart = element.total_quantity * element.coins_per_piece + tempCoinsOnCart;
      });
      coinsOnCart = tempCoinsOnCart;
      item.isAddToCart = false;
      this.setState({ coinsOnCart });
    }
  }

  addSubCoins = (item, isAdd) => {
    const { coinsOnCart } = this.state;
    if (item.isAddToCart) {
      return false;
    }
    const total_quantity = isAdd ?
      item.total_quantity + item.minimum_quantity :
      item.total_quantity - item.minimum_quantity;
    if (total_quantity > item.current_quantity) {
      item.isInvalid = true;
      this.setState({});
      setTimeout(() => {
        item.isInvalid = false;
        this.setState({});
      }, 5000);
      return;
    }
    if (total_quantity < item.minimum_quantity) {
      return;
    }
    if (total_quantity > item.minimum_quantity * 5) {
      return;
    }
    item.total_quantity = total_quantity;
    this.setState({
      coinsOnCart
    });
  }

  backToMerchandiseWallet = () => {
    if (this.state.placeOrderCartItems.length > 0) {
      Storage.setPlaceOrderCartItems(this.state.placeOrderCartItems);
      this.props.backToMerchandiseWallet(false);
    }
  }

  render() {
    return (
      <>

        <Row className="merchandise-wallet-count-wrapp">
          <Col xl="2" md="12" xs="12" className="merchandise-wallet-col-count">
            <Card className="merchandise-wallet-count-card">
              <div className="merchandise-wallet-count-lbl-wrapp d-flex-column">
                <span className="merchandise-wallet-coins-lbl">Total Coins</span>
                <span className="merchandise-wallet-coins-count">{formatNumber(this.state.total_coins)}</span>
              </div>
            </Card>
          </Col>

          <Col xl="2" md="12" xs="12" className="merchandise-wallet-col-count">
            <Card className="merchandise-wallet-count-card">
              <div className="merchandise-wallet-count-lbl-wrapp d-flex-column">
                <span className="merchandise-wallet-coins-lbl">Redeemed Coins</span>
                <span className="merchandise-wallet-coins-count">{formatNumber(this.state.redeemed_coins)}</span>
              </div>
            </Card>
          </Col>

          <Col xl="2" md="12" xs="12" className="merchandise-wallet-col-count">
            <Card className="merchandise-wallet-count-card">
              <div className="merchandise-wallet-count-lbl-wrapp d-flex-column">
                <span className="merchandise-wallet-coins-lbl">Balance Coins</span>
                <span className="merchandise-wallet-coins-count">{formatNumber(this.state.balance_coins)}</span>
              </div>
            </Card>
          </Col>



          <Col xl="2" md="12" xs="12" className="merchandise-wallet-col-count">
            <Card className="merchandise-wallet-count-card">
              <div className="merchandise-wallet-count-lbl-wrapp d-flex-column">
                <span className="merchandise-wallet-coins-lbl">Coins on Cart</span>
                <span className="merchandise-wallet-coins-count">{formatNumber(this.state.coinsOnCart)}</span>
              </div>
            </Card>
          </Col>

          <Col xl="2" md="12" xs="12" className="merchandise-wallet-col-count cursor-pointer">
            <Card className="merchandise-wallet-count-card" style={{ background: '#fff' }} onClick={() => this.backToMerchandiseWallet()}>
              <div className="merchandise-wallet-count-lbl-wrapp d-flex-row cart-count-wrapp">
                <img src="images/cart.svg" alt='' /><div className="cart-count">
                  <span>Cart</span>&nbsp;&nbsp;<span>
                    {this.state.placeOrderCartItems && this.state.placeOrderCartItems.length}
                  </span>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <br />
        <div className="Add-items-to-your-ca" style={{ marginTop: '15px' }}>Add items to your cart</div>

        <Row>
          {
            this.state.cartItems.map((item) => (
              <Col xl="2" md="12" xs="12" className="Add-items-cart-col d-flex-column" style={{ marginRight: '15px' }}>
                <div className="d-flex-column Add-items-cart-wrapp">
                  <img src={item.image_url} className="Add-items-cart-img" alt='' />
                </div>
                <div style={{ marginTop: '0px' }} className="text-center d-flex-column">
                  <span className="Car-Charger">{item.name}</span>
                  <span className="coins-per-piece" style={{ marginTop: '10px' }}>{item.coins_per_piece} coins per piece</span>
                  <div className="d-flex-row" style={{ margin: 'auto', marginTop: '10px' }}>
                    <span className="Minimum-Qty">*Minimum Qty. = {item.minimum_quantity}</span>
                    <div className="d-flex-row" style={{ flexWrap: 'nowrap' }}>
                      <Input disabled={item.isAddToCart} value={item.total_quantity} type="text" className="min-input-qty" style={{ width: '65px' }} />&nbsp;&nbsp;
                      <div className="d-flex-column">
                        <img src="images/up.png" className="up-down-img cursor-pointer" onClick={() => this.addSubCoins(item, true)} alt='' />
                        <img src="images/down.png" style={{ marginTop: '8px' }} className="up-down-img cursor-pointer" onClick={() => this.addSubCoins(item, false)} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                {item.isInvalid && <div style={{ marginTop: '5px' }} className="error">Sorry! maximum available item count is {item.current_quantity}.</div>}

                <div style={{ marginTop: 'auto', marginBottom: '20px', justifyContent: 'center' }} className="d-flex-row">
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold add-to-cart-btn"
                    color="warning"
                    type="submit"
                    onClick={() => this.checkTotalCoins(item, !item.isAddToCart)}
                  >
                    {item.isAddToCart === undefined || item.isAddToCart === false ? 'Add To Cart' : 'Remove From Cart'}
                  </Button></div>
              </Col>
            ))
          }
        </Row>

      </>
    );
  }
}

export default MerchandiseCatalogue;

// const mapDispatchToProps = dispatch => ({
//   setMerchandiseCart: placeOrderCartItems => dispatch(setMerchandiseCart(placeOrderCartItems)),
// });


// const mapStateToProp = state => ({
//   placeOrderCartItems: state.User.placeOrderCartItems
// });

// export default connect(mapStateToProp, mapDispatchToProps)(MerchandiseCatalogue);