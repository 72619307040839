import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Ionicon from 'react-ionicons';
// modal
import SpeacialEngagement from '../../modal/SpeacialEngagement/SpeacialEngagement';

// css
import './ActionScreen.css';

class ActionScreen extends Component {
  state = {
    isSpecialEngagementModalOpen: false,
    actionId: null
  }

  openSpecialEngagementModal = (actionId) => {
    this.setState({ actionId, isSpecialEngagementModalOpen: true })
  }

  closeSpecialEngagementModal = () => {
    this.setState({ actionId: null, isSpecialEngagementModalOpen: false })
  }

  render() {
    const { titles = [] } = this.props;
    const { actionId, isSpecialEngagementModalOpen } = this.state;
    const prepareTitles = titles.map(t =>
      <Card className="margin-5 cursor-pointer">
        <CardBody>
          <Row onClick={this.openSpecialEngagementModal.bind(this, t.uuid)}>
            <Col>
              <span className=" action-button">{t.title}</span>
            </Col>
            <Col sm="1" className="text-align-right" style={{ padding: '5px' }}>
              <Ionicon icon="ios-arrow-forward" fontSize="16px" color="#fdbb1f" />
            </Col>
          </Row>
        </CardBody>
      </Card>)
    return (
      <div id="action-screen">
        {isSpecialEngagementModalOpen && <SpeacialEngagement
          show={isSpecialEngagementModalOpen}
          uuid={actionId}
          // popupDetail={popupDetail}
          closeModal={this.closeSpecialEngagementModal}
        ></SpeacialEngagement>}

        {prepareTitles}

      </div>
    );
  }
}

export default ActionScreen;