import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
  CardHeader,
  Card,
  CardBody
} from "reactstrap";
import classnames from "classnames";
class LcvPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      showMore: true,
      transform: true,
      showInkBar: true,
      selectedTabKey: 0,
      transformWidth: 400
    };
    //this.getAboutDetail();
  }
  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    let lcvPoints = this.props.lcvPoints;
    return (
      <>
        <Card
          tabs="true"
          className="mb-3 about-tab-header"
          style={{ boxShadow: "none" }}
        >
          <CardHeader className="card-header-tab">
            <Nav style={{ marginLeft: "0" }}>

              {lcvPoints.lcv.tabs.map((t, index) =>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={classnames({
                      active: this.state.activeTab === index
                    })}
                    onClick={() => {
                      this.toggleTab(index);
                    }}
                  >
                    {t}
                  </NavLink>
                </NavItem>
              )}
              {/* <NavLink
                  href="javascript:void(0);"
                  className={classnames({
                    active: this.state.activeTab === "1"
                  })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                >
                  Quarter 1
                </NavLink> */}
              {/* <NavItem>
                <NavLink
                  href="javascript:void(0);"
                  className={classnames({
                    active: this.state.activeTab === "2"
                  })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                >
                  Quarter 2 - 4
                </NavLink>
              </NavItem> */}
            </Nav>
          </CardHeader>
          <CardBody style={{ padding: "0" }}>

            <TabContent activeTab={this.state.activeTab}>
              {lcvPoints.lcv.tabs.map((t, index) => {
                return lcvPoints.lcv.year === '2019-20' && t === 'Quarter 1' ?
                  <TabPane tabId={index}>
                    <Row style={{ justifyContent: "space-between", margin: "0" }}>
                      {lcvPoints.lcv[t].basePoints.map((item, i) => (
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", width: "auto" }}
                        >
                          <div
                            className="truckbus-note two-three-wheeler-multilple-offer-note"
                            style={{ width: "auto" }}
                          >
                            <span className="Base-Points-80-Tyre-label">
                              {item}
                            </span>
                          </div>
                        </div>
                      ))}
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Table className="table-responsive tbl-tbb tbl-about">
                          <thead>
                            <tr style={{ ...this.getStripedStyle(0) }}>
                              <th
                                colspan={lcvPoints.lcv[t].headers.length - 1}
                                style={{ textAlign: "center" }}
                              >
                                LCV Bias
                              </th>
                              <th>LCV Radial</th>
                            </tr>
                            <tr>
                              {lcvPoints.lcv[t].headers.map((item, i) => (
                                <th>{item}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {lcvPoints.lcv[t].biasPoints.map((row, i) => (
                              <tr key={i} style={{ ...this.getStripedStyle(i) }}>
                                {row.map((item, j) =>
                                  j > 1 ? (
                                    j > 2 ? (
                                      <td
                                        rowspan={
                                          lcvPoints.lcv[t].biasPoints + 1
                                        }
                                        style={{
                                          backgroundColor: "#fff",
                                          borderLeft: "1px solid #e9ecef"
                                        }}
                                      >
                                        {item}
                                      </td>
                                    ) : (
                                      <td
                                        rowspan="6"
                                        style={{
                                          backgroundColor: "#fff",
                                          borderLeft: "1px solid #e9ecef"
                                        }}
                                      >
                                        {item}
                                      </td>
                                    )
                                  ) : (
                                    <td> {item}</td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          {lcvPoints.lcv[t].note &&
                            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                              <span className="Base-Points-80-Tyre-label">
                                {lcvPoints.lcv[t].note}
                              </span>
                            </div>
                          }
                        </div>
                      </Col>
                    </Row>
                  </TabPane> :
                  <TabPane tabId={index}>
                    <Row>
                      <Col sm="12">
                        <Table className="table-responsive tbl-tbb tbl-about">
                          <thead>
                            <tr style={{ ...this.getStripedStyle(0) }}>
                              <th
                                colspan={
                                  lcvPoints.lcv[t].lcvHeaders.length - 1
                                }
                                style={{ textAlign: "center" }}
                              >
                                LCV Bias
                              </th>
                              <th style={{ textAlign: "center" }}>LCV Radial</th>
                            </tr>
                            <tr>
                              {lcvPoints.lcv[t].lcvHeaders.map((item, i) => (
                                <th style={{ textAlign: i === 2 ? "center" : "" }}>
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {lcvPoints.lcv[t].lcvPoints.map((row, i) => (
                              <tr key={i} style={{ ...this.getStripedStyle(i) }}>
                                {row.length === 3 &&
                                  row.map((item, j) => (
                                    <td
                                      className={
                                        j === 2 && row.length === 3
                                          ? "tdd-size-td"
                                          : ""
                                      }
                                      rowspan={
                                        j === 2 && row.length === 3
                                          ? lcvPoints.lcv[t].lcvPoints.length
                                          : "1"
                                      }
                                    >
                                      {item}
                                    </td>
                                  ))}
                                {row.length === 2 &&
                                  row.map((item, j) => <td>{item}</td>)}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          {lcvPoints.lcv[t].multipleOffer &&
                            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                              <span className="Base-Points-80-Tyre-label">
                                {lcvPoints.lcv[t].multipleOffer}
                              </span>
                            </div>
                          }
                        </div>
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          {lcvPoints.lcv[t].note &&
                            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                              <span className="Base-Points-80-Tyre-label">
                                {lcvPoints.lcv[t].note}
                              </span>
                            </div>
                          }
                        </div>
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
              }
              )}
            </TabContent>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default LcvPoints;
