import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
} from "reactstrap";
import ReactDOM from 'react-dom';
import "../AddCustomer.css";
import _ from "lodash";
import swal from "sweetalert";
// import moment from "moment";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import CustomerManagementService from "../../../services/CustomerManagementService";
// services
import WarrantyRegistrationService from "./../../../services/WarrantyRegistrationService";
import CampaignService from "../../../services/CampaignService";
import AddCustomerService from "../../../services/AddCustomerService";
import ModalService from "../../../services/ModalService";
import { messages } from "../../../constants/constant.message";
import TextareaAutosize from "react-textarea-autosize";
import Validation from "../../../services/Validation";
import Select, { components } from "react-select";
class EditCustomerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPurpose: null,
      showPD: false,
      customgroupname: false,
      groupData: [],
      customer_services: [],
      selectedServices: [],
      vehicleMakeData: [],
      vehicleTypeData: [],
      vehicleModelData: [],
      customer_vehicle: [],
      customer_detail: {},
      controls: {
        first_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        last_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        phone_number: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicleCategory: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        group_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        group_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_brand_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_brand_model_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        birth_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        anniversary_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        remark: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        email_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      count_vehicle_detail: 0,
      serachValueGroup: '',
      isopenGroupMenu: false,
      isHideLbl: false
    };
    this.childVehicleRegistrationNumber = React.createRef();
  }

  componentDidMount = () => {
    this.getVehicleType();
    this.getGroups();

  };
  setProps = () => {
    const { customer_vehicle, customer_detail } = this.props;
    let { controls, count_vehicle_detail, isHideLbl } = this.state;
    if (this.props && this.props.customer_detail && Object.keys(this.props.customer_detail).length > 0) {
      controls["first_name"].value = customer_detail.first_name;
      controls["last_name"].value = customer_detail.last_name;
      controls["phone_number"].value = customer_detail.phone_number;
      controls["birth_date"].value = customer_detail.birth_date;
      controls["anniversary_date"].value = customer_detail.anniversary_date;
      controls.group_id.value =
        customer_detail.member_of_group && customer_detail.member_of_group.length > 0
          ? customer_detail.member_of_group
          : [];
      controls["remark"].value = customer_detail.remarks;
      controls["email_id"].value = customer_detail.email_id;

    } else {
      controls["first_name"].value = '';
      controls["last_name"].value = '';
      controls["phone_number"].value = '';
      controls["birth_date"].value = null;
      controls["anniversary_date"].value = null;
      controls.group_id.value = [];
      controls["remark"].value = '';
      controls["email_id"].value = '';
    }


    if (Array.isArray(this.props.customer_vehicle) && this.props.customer_vehicle.length > 0) {
      let count = this.props.customer_vehicle.filter(x => x.upcoming_due_date);
      count_vehicle_detail = count.length;
      isHideLbl = false;
    } else {
      isHideLbl = true;
    }
    this.setState({ controls, isHideLbl });
    if (this.props && this.props.customer_vehicle) {
      this.setState({ customer_vehicle, customer_detail, count_vehicle_detail });
    }
  }


  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let isFormValid = false;
    let {
      first_name, last_name, email_id
    } = controls;


    if ((first_name.touched === true || isSubmit)) {
      first_name = Validation.notNullValidator(first_name);
      first_name.valid = !first_name.nullValue;
      if (first_name.valid === false) {
        first_name.showErrorMsg = true;
      } else {
        first_name.showErrorMsg = false;
      }
    }

    if ((last_name.touched === true || isSubmit)) {
      last_name = Validation.notNullValidator(last_name);
      last_name.valid = !last_name.nullValue;
      if (last_name.valid === false) {
        last_name.showErrorMsg = true;
      } else {
        last_name.showErrorMsg = false;
      }
    }

    if (email_id.touched === true || isSubmit) {
      email_id = Validation.notNullValidator(email_id);
      if (!email_id.nullValue) {
        email_id = Validation.emailValidator(email_id);
        email_id.valid = !email_id.invalidEmail;
        if (email_id.valid) {
          email_id.showErrorMsg = false;
        } else {
          email_id.showErrorMsg = true;
        }
      } else {
        email_id.valid = true;
        email_id.showErrorMsg = false;
      }

      if (last_name.valid === false) {
        last_name.showErrorMsg = true;
      } else {
        last_name.showErrorMsg = false;
      }
    }

    if (
      last_name.valid === true &&
      first_name.valid === true &&
      email_id.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  }


  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (
      controlValue &&
      (controlName === "first_name" || controlName === "last_name")
    ) {
      controls[controlName].value = controlValue.replace(/\b\w/g, l =>
        l.toUpperCase()
      );
      controls[controlName].touched = true;
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }

    this.setState({ controls });
    this.handleValidation();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeBirthdayDatePicker = (date) => {
    const { controls } = this.state;
    controls["birth_date"].value = date;
    this.setState({ controls });
  };

  handleChangeAnniversaryDatePicker = (date) => {
    const { controls } = this.state;
    controls["anniversary_date"].value = date;
    this.setState({ controls });
  };

  updateState = (params) => {
    //let { controls } = this.state;
    this.setState({
      controls: params.vehicleDetails.controls,
      isValidForm: params.vehicleDetails.isValidForm,
    });
    this.handleValidation();
    //this.props.updateState({ visibleArea: 'vehicleRegistrationNumber', isSameRoute: true, vehicleDetails: { controls, isValidForm }, vehicleLocalRegistrationNumber: localControls });
  };

  getVehicleType = () => {
    const obj = {};
    CampaignService.getVehicleTypeCamp(obj)
      .then((res) => {
        let { vehicleTypeData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          vehicleTypeData = res.data.data;
        } else {
          vehicleTypeData = [];
        }
        this.setState({
          vehicleTypeData,
        });
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  };

  async getVehicleMake() {
    let { controls, vehicleMakeData, vehicleModelData } = this.state;
    try {
      if (!controls.custom_filter_vehicle_type_id.value) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      const { data } = await WarrantyRegistrationService.getVehicalMake({
        vehicle_type_id: controls.custom_filter_vehicle_type_id.value,
      });

      if (data && data.data.length > 0) {
        const model = _.filter(data.data, (element) => {
          return (
            element.brand_id ===
            controls.custom_filter_vehicle_type_brand_id.value.brand_id
          );
        });
        if (model.length > 0) {
          controls.custom_filter_vehicle_type_brand_id.value = model[0];
        }
        vehicleMakeData = data.data;
      } else {
        vehicleMakeData = [];
      }

      vehicleMakeData = [];
      if (data && data.data.length > 0) {
        vehicleMakeData = data.data;
      } else {
        vehicleMakeData = [];
      }

      this.setState((prevState) => {
        return {
          ...prevState,
          vehicleMakeData,
          controls,
          vehicleModelData,
          isLoading: false,
        };
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      return error;
    }
  }

  async getVehicleModel() {
    try {
      let { controls, vehicleModelData } = this.state;
      const { custom_filter_vehicle_type_brand_id } = controls;
      this.setState({
        isLoading: true,
      });
      const { data } = await WarrantyRegistrationService.getVehicalModel({
        vehicle_type_brand_id:
          custom_filter_vehicle_type_brand_id.value.brand_id,
      });
      if (data && data.data.length > 0) {
        const model = _.filter(data.data, (element) => {
          return (
            element.model_id ===
            controls.custom_filter_vehicle_type_brand_model_id.value.model_id
          );
        });
        if (model.length > 0) {
          controls.custom_filter_vehicle_type_brand_model_id.value = model[0];
        }
        vehicleModelData = data.data;
      } else {
        vehicleModelData = [];
      }
      this.setState((prevState) => {
        return { ...prevState, vehicleModelData, controls, isLoading: false };
      });
      this.handleValidation();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  }

  showPD = () => {
    this.setState({ showPD: !this.state.showPD });
  };

  getGroups = () => {
    this.setState({ isLoading: true });
    CustomerManagementService.getGroups({ page: 1, pageSize: 10000 })
      .then((res) => {
        let { groupData } = this.state;
        if (
          res.data.data &&
          res.data.data.groups &&
          res.data.data.groups.length > 0
        ) {
          if (res.data.data.count !== 0) {
            this.setState({
              customgroupname: false,
            });
          }
          groupData = res.data.data.groups;
          this.setState({ groupData });
        } else {
          groupData = [];
          this.setState({ groupData });
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        console.log(e);
      });
  };

  //  ========================AddGroup======================

  addGroup = async () => {
    let { controls } = this.state;
    if (!controls.group_name.value) {
      ModalService.openAlert('', 'Please enter group name.', 'error');
      return;
    }
    const reqData = {
      name: controls.group_name.value,
    };

    this.props.isLoading(true);
    let group_id = await CustomerManagementService.addGroup(reqData)
      .then(async (res) => {
        this.props.isLoading(false);
        this.getGroups();
        let { controls } = this.state;
        if (res.data.data.group_id) {
          controls.group_name.value = '';
          controls.group_id.value.push({
            group_id: res.data.data.group_id,
            created_on: new Date().toISOString(),
            group_name: controls.group_name.value,
            member_count: 0
          })
        }
        await this.setState({
          controls,
          customgroupname: false
        })
        return res.data.data.group_id;
      })
      .catch((e) => {
        this.props.isLoading(false);
        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error')
        console.log(e);
        return null;
      });
    return group_id;
  };

  addNewGroup = () => {
    let { controls } = this.state;
    if (this.state.customgroupname) {
      controls.group_name.value = '';
    }
    this.setState({
      customgroupname: !this.state.customgroupname,
      controls
    });
  };

  UpdateCustomer = async () => {
    let { controls } = this.state;
    let reqData = {
      phone_number: controls.phone_number.value,
      first_name: controls.first_name.value ? controls.first_name.value : null,
      last_name: controls.last_name.value ? controls.last_name.value : null,
      email_id: controls.email_id.value ? controls.email_id.value : null,
      birth_date: controls.birth_date.value ? controls.birth_date.value : null,
      anniversary_date: controls.anniversary_date.value
        ? controls.anniversary_date.value
        : null,
      remark: controls.remark.value ? controls.remark.value : null,
      country_code: "+91",
      group_id: []
    };
    if (this.state.customgroupname && controls.group_name.value) {
      let group_id = await this.addGroup();
      if (group_id) {
        reqData.group_id.push(group_id);
      } else {
        return;
      }
      if (controls.group_id.value && controls.group_id.value.length > 0) {
        controls.group_id.value.forEach(element => {
          reqData.group_id.push(element.group_id);
        });
      }
    } else {
      if (controls.group_id.value && controls.group_id.value.length > 0) {
        reqData.group_id = [];
        controls.group_id.value.forEach(element => {
          reqData.group_id.push(element.group_id);
        });
      }
    }
    if (this.state.customer_detail && this.state.customer_detail.customer_id) {
      reqData.customer_id = this.state.customer_detail.customer_id;
    }
    this.props.isLoading(true);
    let response = await CustomerManagementService.addCustomer(reqData)
      .then((res) => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((res) => {
          if (this.props.is_open_vehcile_detail_list) {
            this.props.backPage();
            return;
          } else if (this.props.is_edit_from_customer_list) {
            this.props.handleOnCancel({ is_customer_list: true });
          }

        });
        this.props.isLoading(false);
        return true;
      })
      .catch((e) => {
        console.log(e);
        this.props.isLoading(false);
        return false;
      });
    return response;
  };

  editVehicle = (vehicleData) => {
    this.props.editVehicle(vehicleData);
  };


  getCustomerVehicle = () => {
    this.setState({
      isLoading: true
    });
    AddCustomerService.getCustomerVehicle(this.state.customer_detail.customer_id)
      .then(res => {
        let { customer_vehicle, isHideLbl } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          customer_vehicle = res.data.data;
          isHideLbl = false;
        } else {
          customer_vehicle = [];
          isHideLbl = true;
        }
        this.setState({
          customer_vehicle,
          isLoading: false,
          isHideLbl
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  deleteCustomerConfirm = (vehicle_id = null) => {
    swal({
      title: "Delete Vehicle",
      text: "Are you sure, you want to delete vehicle ?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.deleteVehicle(vehicle_id);
      }
    });
  };

  deleteVehicle = (vehicle_id = null) => {
    let reqData = {
      customer_id: this.state.customer_detail.customer_id,
    };
    if (vehicle_id) {
      reqData.vehicle_id = vehicle_id;
    }
    this.props.isLoading(true);
    CustomerManagementService.deleteCustomerVehicle(reqData)
      .then((res) => {
        this.props.isLoading(false);
        this.props.getCustomerVehicle(this.state.customer_detail.customer_id);
        if (res.data.message) {
          swal({
            title: "Deleted",
            text: res.data.message,
            icon: "success",
            type: "success",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.props.isLoading(false);
      });
  };

  viewHistory = (history) => {
    this.setState({
      ViewHistory: history,
    });
    this.props.viewTradeHistory(history);
  };

  viewUpcomingAppointemnts = () => {
    this.props.viewUpcomingAppointemnts();
  }

  openCloseMenu = (open) => {
    this.setState({
      isopenGroupMenu: open
    })
  }

  ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        {/* <span>{`${this.state.groupData.length === this.state.controls.group_id.value.length ? nbValues - 1 : nbValues} selected`}</span> */}
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${nbValues} selected`}</span>
          {props.selectProps.menuIsOpen && <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }}
            name="serachValueGroup" value={this.state.serachValueGroup}
            onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };

  onChangeFilter = e => {
    let controlName = e.target.name;
    this.setState({
      [controlName]: e.target.value
    });
  };

  customFilter = (option, searchText) => {
    if (
      option.data.group_name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };



  handleClickOutside = (event) => {
    if (this.refGroupId) {
      const domNode = ReactDOM.findDOMNode(this.refGroupId);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refGroupId.state.menuIsOpen) {
          this.refGroupId.onMenuClose();
          this.setState({
            isopenGroupMenu: false
          })
        }
      }
    }
  }

  generateServiceToolTip = (services) => {
    let serviceString = '';
    if (services && Array.isArray(services) && services.length > 0) {
      for (let i = 0; i < services.length; i++) {
        if (i === 0) {
          serviceString += services[i];
        } else {
          serviceString += `\n${services[i]}`
        }
      }
    }
    return serviceString;
  }


  generateServiceText = (lastVisitService) => {
    let c = null;
    if (lastVisitService && Array.isArray(lastVisitService) && lastVisitService.length > 0) {
      if (lastVisitService.length === 1) {
        c = <span className="sub-value-table-service d-flex">({lastVisitService[0]})</span>
      } else {
        c = <span className="sub-value-table-service d-flex" id="customer-list-tooltip">({lastVisitService[0]})
          <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={this.generateServiceToolTip(lastVisitService)} >
            <span className="sub-value-more cursor-pointer">+{lastVisitService.length - 1}</span>
          </span>
          {/* <span className="sub-value-more cursor-pointer" title={this.generateServiceToolTip(lastVisitService)}>+{lastVisitService.length - 1}</span> */}
        </span>
      }
    }
    return c;
  }

  render() {
    let { controls, isHideLbl } = this.state;
    let { customer_vehicle } = this.props;
    if (!this.props.is_edit_from_customer_list) {
      isHideLbl = true;
    }
    const customStyles = {
      control: base => ({
        ...base,
        maxHeight: 70,
        overflow: "auto",
        theme: "primary",
        borderColor: "#fee19d",
        outline: "0",
        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
      }),
      input: styles => "text"
    };



    const CustomOption1 = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label className="custom-checkboax1--container"
              ref={innerRef} {...innerProps}>
              <input
                type="checkbox"
                name="service"
                checked={props.isSelected === true || props.isSelected === 'true'}
              />
              <span className="checkmark1" />
            </label>

            <span className="ml-2" ref={innerRef} {...innerProps} style={{ marginTop: '-2px' }}>{props.data.group_name}</span>
          </div>
        </>
      );
    };
    return (
      <>
        <Row className="add-customer-wrapper" onClick={this.handleClickOutside}>
          <Col xl="4" md="12" xs="12" className="p-10-px">
            <FormGroup>
              <Row>
                <Col xl="12" md="12" xs="12">
                  <Label for="common"
                    className="add-customer-lbl">
                    Mobile Number&nbsp;
                    <span className="required-field">*</span>
                  </Label>
                </Col>
                <Col xl="3" className="pr-0">
                  <div className="edit-Country-code diable-mo-number">+91</div>
                </Col>
                <Col xl="9">
                  <FormGroup>
                    <Input
                      type="number"
                      name="phone_number"
                      disabled={true}
                      id="phone_number"
                      className="diable-mo-number"
                      autoComplete="nofill"
                      maxLength="10"
                      value={controls.phone_number.value}
                      onChange={this.handleInputChange}
                      placeholder="Type here"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col xl="4" md="12" xs="12" className="p-10-px">
            <FormGroup>
              <Label for="common" className="add-customer-lbl">
                First Name &nbsp;
                <span className="required-field">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="nofill"
                name="first_name"
                id="first_name"
                value={controls.first_name.value}
                onChange={this.handleInputChange}
                placeholder="Type here"
              />
              {controls.first_name.showErrorMsg &&
                <div className="error show_error">
                  *Please enter first name
                </div>}
            </FormGroup>
          </Col>
          <Col xl="4" md="12" xs="12" className="p-10-px">
            <FormGroup>
              <Label for="common" className="add-customer-lbl">
                Last Name &nbsp;
                <span className="required-field">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="nofill"
                name="last_name"
                id="last_name"
                value={controls.last_name.value}
                onChange={this.handleInputChange}
                placeholder="Type here"
              />
              {controls.last_name.showErrorMsg &&
                <div className="error show_error">
                  *Please enter last name
                </div>}
            </FormGroup>
          </Col>
          {
            (!isHideLbl) &&
            <Col xl="12" className="p-10-px" style={{ marginBottom: !this.state.showPD ? '10px' : '0' }}>
              {!this.state.showPD ? (
                <div className="VPD cursor-pointer" onClick={this.showPD}>
                  View Personal Details
                </div>
              ) : (
                <div className="VPD cursor-pointer" onClick={this.showPD}>
                  Hide Personal Details
                </div>
              )}
            </Col>
          }
          {(this.state.showPD || isHideLbl) && (
            <>
              <Col xl="12" md="12" xs="12" className="p-10-px">
                <div className="PDetails" style={{ marginTop: isHideLbl ? '0px' : '10px' }}>PERSONAL DETAILS</div>
              </Col>
              <Col xl="4" md="12" xs="12" className="p-10-px">
                <FormGroup>
                  <Label for="password">Birthdate</Label>
                  <Label onClick={e => this.calendar1.state.open && e.preventDefault()} className="display-botttom-datepicker">
                    <InputGroup>
                      <DatePicker
                        className="form-control edit-name"
                        selected={
                          controls.birth_date.value
                            ? new Date(controls.birth_date.value)
                            : null
                        }
                        onChange={this.handleChangeBirthdayDatePicker}
                        showMonthDropdown
                        ref={(r) => (this.calendar1 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        maxDate={new Date()}
                        dropdownMode="select"
                        placeholderText="Select"
                        dateFormat="dd/MM/yyyy"
                      />
                      <InputGroupAddon addonType="append" >
                        <div className="input-group-text" style={{ paddingRight: '5px' }}>
                          {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                          <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>

                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12" className="p-10-px">
                <FormGroup>
                  <Label for="password">Anniversary Date</Label>
                  <Label className="width-100">
                    <Label
                      onClick={(e) =>
                        this.calendar.state.open && e.preventDefault()
                      }
                      className="display-botttom-datepicker"
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control edit-name display-botttom-datepicker"}
                          selected={
                            controls.anniversary_date.value
                              ? new Date(controls.anniversary_date.value)
                              : null
                          }
                          onChange={this.handleChangeAnniversaryDatePicker}
                          showMonthDropdown
                          ref={(r) => (this.calendar = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          maxDate={new Date()}
                          dropdownMode="select"
                          placeholderText="Select"
                          dateFormat="dd/MM/yyyy"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text" style={{ paddingRight: '5px' }}>
                            {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                            <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </Label>
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12" className="p-10-px">
                <FormGroup>
                  <Label for="common">Email Address</Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    className="edit-name"
                    name="email_id"
                    id="email_id"
                    value={controls.email_id.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.email_id.showErrorMsg &&
                    <div className="error show_error">
                      *Please enter valid email
                    </div>}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12" className="p-10-px">
                <FormGroup>
                  <div className="d-flex-row">
                    <Label for="common">Group</Label>
                    {
                      this.state.customgroupname ?
                        <>
                          <span className="ml-auto add-group text-align-right cursor-pointer" onClick={this.addNewGroup}
                            style={{ width: 'auto' }}>
                            Cancel
                          </span>&nbsp;&nbsp;
                          <span className="add-group text-align-right cursor-pointer" onClick={this.addGroup}
                            style={{ width: 'auto', paddingRight: '15px' }}>
                            Save
                          </span>
                        </>
                        : <span className="edit-group cursor-pointer" onClick={this.addNewGroup}>
                          Create Group
                        </span>
                    }
                  </div>
                  {(this.state.customgroupname === false || this.state.customgroupname === 'false') ? (
                    <Select
                      {...this.props.input}
                      ref={r => this.refGroupId = r}
                      name="group_id"
                      pageSize="1"
                      getOptionLabel={(option) => option.group_name}
                      getOptionValue={(option) => option.group_id}
                      options={this.state.groupData}
                      allowSelectAll
                      onMenuOpen={() => this.openCloseMenu(true)}
                      onMenuClose={() => this.openCloseMenu(false)}
                      closeMenuOnSelect={false}
                      value={controls.group_id.value}
                      tabSelectsValue={false}
                      isSearchable={true}
                      filterOption={this.customFilter}
                      onChange={(e) => this.handleInputChange({ target: { name: 'group_id', value: e } })}
                      onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueGroup', value: e } })}
                      placeholder="Search..."
                      styles={customStyles}
                      hideSelectedOptions={false}
                      inputValue={this.state.serachValueGroup}
                      components={{ Option: CustomOption1, ValueContainer: this.ValueContainer }}
                      backspaceRemoves={false}
                      // menuPlacement="top"
                      className={this.state.isopenGroupMenu ? 'z-index-102 add-customer-react-select' : 'add-customer-react-select'}
                      isMulti
                    />

                  ) : (
                    <Input
                      type="text"
                      name="group_name"
                      placeholder="Enter group name"
                      value={controls.group_name.value}
                      onChange={(e) => {
                        this.handleInputChange(e);
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col xl="8" md="12" xs="12" className="p-10-px">
                <FormGroup>
                  <Label for="common">Add Remark</Label>
                  <TextareaAutosize
                    name="comment"
                    autocomplete="nofill"
                    id="remark"
                    value={controls.remark.value}
                    style={{ minHeight: 35, maxHeight: 90, fontSize: '14px' }}
                    className="form-control"
                    onChange={(e) => this.handleInputChange({ target: { name: 'remark', value: e.target.value } })}
                    placeholder='Type here'
                  />
                </FormGroup>
              </Col>
            </>
          )}
          {
            (customer_vehicle && customer_vehicle.length > 0 &&
              this.props.is_edit_from_customer_list) &&
            <Col className="d-flex justify-content-between p-10-px mt-2 mb-3" xl="12">
              <div className="PDetails mt-0 mb-0">VEHICLE DETAILS</div>
              <div className="visit-history mt-0 mb-0 mr-0" onClick={() => this.viewHistory(null)}>
                <img src="/images/history_icon.png" class="Fill-1" alt='' />
                &nbsp;&nbsp;
                <span className="ANV cursor-pointer">Visit History</span>
              </div>
            </Col>
          }
          {
            (this.state.count_vehicle_detail > 0 && this.props.is_edit_from_customer_list) &&
            <Col xl="12" className="p-10-px">
              <Row className="Upcoming-up-card" style={{ margin: 0 }}>
                <Col xl="10" md="10" xs="10" className="edit-uA d-flex">
                  <div className="circle cursor-pointer">
                    <div className="circle-number" onClick={() => this.viewUpcomingAppointemnts()}>{this.state.count_vehicle_detail}</div>
                  </div>
                  <span className="ml-2 cursor-pointer" onClick={() => this.viewUpcomingAppointemnts()}>Upcoming Appointment</span>
                </Col>
                <Col xl="2" md="2" xs="2" className="d-flex">
                  <img
                    src="/images/right-arrow-blue-icon.svg"
                    alt=''
                    onError="images/rightA.png"
                    className="ml-auto mb-auto mt-auto mr-0"
                  />
                </Col>
              </Row>
            </Col>
          }
          <Col className="mt-3"></Col>
          {customer_vehicle && this.props.is_edit_from_customer_list &&
            customer_vehicle.map((item, i) => (
              <div className="edit-veh-det-view w-100 mb-3" style={{ paddingTop: '10px', marginLeft: '15px', marginRight: '15px' }}>
                <table className="w-100 tbl-vehicle-detail" cellspacing="0">
                  <tr>
                    <td style={{ width: '20px' }}></td>
                    <td className="borde-bottom-EFF1F3">
                      <div className="d-flex-column mb-2">
                        <div className="d-flex-row">
                          <span style={{ fontSize: "12px" }}>
                            Vehicle Type
                          </span>
                        </div>
                        <div>
                          <img
                            src={item.vehicle_type_image_url}
                            alt=''
                            className="vehicle-image"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="borde-bottom-EFF1F3">
                      <div className="d-flex-column pb-2" style={{ width: '128px', minWidth: '128px' }}>
                        <div className="V-t-card mb-1" style={{ width: 'auto' }}>Vehicle Detail</div>
                        <div className="V-t-model mb-1" style={{ width: 'auto' }}>
                          {
                            item.vehicle_type_brand_name || item.vehicle_type_brand_model_name ?
                              <b>
                                {item.vehicle_type_brand_name}&nbsp;&nbsp;
                                {item.vehicle_type_brand_model_name}
                              </b> : '--'
                          }
                        </div>
                        <div className="V-t-number mb-1" style={{ width: 'auto' }}>{item.registration_number}</div>
                      </div>
                    </td>
                    <td className="borde-bottom-EFF1F3">
                      <div className="V-t-card mb-1">Usage Type</div>
                      <div className="V-t-model mb-1">
                        <b>{item.vehicle_category ? (item.vehicle_category.toLowerCase() === 'taxi' ? 'Commercial' : item.vehicle_category) : ''}</b>
                      </div>
                    </td>
                    <td >
                      <div className="del-img">
                        <img
                          className="cursor-pointer"
                          src="images/edit.svg"
                          alt=''
                          style={{ height: "13px" }}
                          onClick={() => this.editVehicle(i)}
                        />
                        &nbsp;&nbsp;
                        <img
                          className="cursor-pointer"
                          alt=''
                          src="/images/delete.svg"
                          onClick={() =>
                            this.deleteCustomerConfirm(item.vehicle_id)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="vertical-align-middle">
                      <div style={{ fontSize: "12px" }}>
                        Last Visit Date
                      </div>
                    </td>
                    <td className="vertical-align-middle">
                      <div className="d-flex-row justify-content-between">
                        <div className="V-t-model">
                          {
                            item.last_visit_date ?
                              <b>
                                {" "}
                                { }
                                {item.last_visit_date &&
                                  moment.tz(item.last_visit_date, 'Asia/Kolakata').format("DD MMM,YYYY")}
                              </b> : <b>--</b>
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex-column mt-2 mb-2">
                        <div className="d-flex-row">
                          <span>Purpose</span>&nbsp;
                          <span style={{ fontSize: "14px" }}>
                            {
                              item.last_purpose_name ?
                                <b>{item.last_purpose_name}</b> :
                                <b>--</b>
                            }
                          </span>
                        </div>
                        {
                          item.last_purpose_service_name && item.last_purpose_service_name.length > 0 &&
                          <>
                            {this.generateServiceText(item.last_purpose_service_name)}
                          </>
                          // <span>
                          //   {item.last_purpose_service_name.map((last_purpose_service_name, i) => (
                          //     <>
                          //       {last_purpose_service_name}
                          //       {
                          //         (i !== (item.last_purpose_service_name.length - 1)) && ', '
                          //       }
                          //     </>
                          //   ))
                          //   }

                          // </span>
                        }
                        {
                          item.type_of_inquiry &&
                          <div className="V-t-card-2 d-flex-column">
                            Type of Inquiry &nbsp;&nbsp;&nbsp;{" "}
                            <span style={{ fontSize: "14px" }} className="mt-1">
                              <b>{item.type_of_inquiry}&nbsp;</b>
                            </span>
                          </div>
                        }
                      </div>
                    </td>
                    <td>
                      <div
                        className="del-img mt-2"
                        onClick={() => this.viewHistory(item)}
                      >
                        <img src="/images/history_icon.png" alt='' />
                      </div>
                    </td>
                  </tr>
                  <tr className="upcoming-aapoint">
                    <td></td>
                    <td className="vertical-align-middle" style={{ height: '40px' }}>
                      <div style={{ fontSize: '12px' }} className="d-flex">
                        <span style={{ width: '130px' }}>Upcoming Appointment</span>
                      </div>
                    </td>
                    <td className="vertical-align-middle">
                      {
                        item.upcoming_due_date ?
                          <div className="V-t-model d-flex" style={{ marginTop: '5px' }}>
                            <b>{moment.tz(item.upcoming_due_date, 'Asia/Kolakata').format("DD MMM,YYYY")}</b>
                          </div> :
                          <b>--</b>
                      }
                    </td>
                    <td className="vertical-align-middle">
                      <div className="V-t-card-2 d-flex-column flex-wrap pt-2 pb-2">
                        <div className="d-flex-row">
                          <span>Purpose &nbsp;&nbsp;&nbsp;{" "}</span>
                          <span style={{ fontSize: "14px" }}>
                            {
                              item.upcoming_purpose_name ?
                                <b>{item.upcoming_purpose_name}</b> :
                                <b>--</b>
                            }
                          </span>
                        </div>
                        {
                          item.upcoming_purpose_service_name && item.upcoming_purpose_service_name.length > 0 &&
                          <span>
                            {/* {item.upcoming_purpose_service_name.map((upcoming_purpose_service_name, i) => (
                              <>
                                {upcoming_purpose_service_name}
                                {
                                  (i !== (item.upcoming_purpose_service_name.length - 1)) && ', '
                                }
                              </>
                            ))
                            } */}
                            {this.generateServiceText(item.upcoming_purpose_service_name)}
                          </span>
                        }
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </table>
              </div>
            ))}

        </Row>
      </>
    );
  }
}

export default EditCustomerDetail;
