import React, { Component } from 'react';
import {
  Row, Col,
  Card, CardBody
} from 'reactstrap';
import './OnBoardingComponent1.css';

class OnBoardingComponent1 extends Component {

  render() {

    return <div id="ob1">
      <Row className="padding-left-13">
        <Col>
          <div className="vert-align">
            <div className="text-align-center">
              <span className="page-title">Welcome to the brand new</span>
              <br />
              <br />
              <Card style={{ padding: '12px 50px' }}>
                <CardBody>
                  <img src="images/logo.png" height="64px" alt="" />
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </div >;
  }
}

export default OnBoardingComponent1;