import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalBody,
  Row, Col
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

// css
import './ConfirmModal.css';

class ConfirmModal extends React.Component {


  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      data: props.data ? props.data : null
    }
  }

  setData = (data) => {
    this.setState({
      data: data ? data : null
    })
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    return (
      <div>
        <Modal
          id="confirm-modal"
          isOpen={this.props.show}
          toggle={this.closeModal}
          className={this.props.className}>
          <ModalBody>
            <br />
            <Row>
              <Col xs="2">
                <FontAwesomeIcon className="arrow-color" style={{ fontSize: '50px', color: '#ffb609' }} icon={faExclamationTriangle} />
              </Col>
              <Col xs="10">
                <div className="modal-title">{this.props.title}</div>
                <div className="modal-text margin-top-10">{this.props.message}</div>
                <br />
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="text-align-right">
                <Button
                  color="#e0e2e4"
                  style={{ backgroundColor: '#e0e2e4', borderRadius: '25px', fontSize: '16px', fontWeight: 'bold' }}
                  onClick={this.props.closeModal}
                >No
                </Button>{'   '}
                <Button
                  color="#fdbb1f"
                  style={{ backgroundColor: '#fdbb1f', borderRadius: '25px', fontSize: '16px', fontWeight: 'bold' }}
                  onClick={() => this.props.actionHandler(this.state.data)}
                >Yes
                </Button>{'   '}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.any,
};



export default ConfirmModal;
