import React, { Component } from 'react';
import {
  Button,
  FormGroup, Label,
  Row, Col,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';
// import _ from 'lodash';
import Ionicon from 'react-ionicons';
import DatePicker from 'react-datepicker';
import {
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarketingCouponService from '../../services/MarketingCouponService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";

const { ERROR_MESSAGE } = messages.COMMON;
class CouponFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        program_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        type: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        purpose: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        offer_status: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        start_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        end_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        paid_by_jk: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
      },
      isLoading: false,
      programData: [],
      type: [],
      purpose: [],
    }
    this.handleRadioChange = this.handleRadioChange.bind(this);
    if (props.getFilterControls && Object.keys(props.getFilterControls).length > 0) {
      this.state.controls = props.getFilterControls;
      if (this.state.controls && this.state.controls.program_name.value !== '') {
        this.getType(this.state.controls.program_name.value)
      }
    }
  }

  handleRadioChange(e) {
    let { controls } = this.state;
    controls.offer_status.value = e;
    this.setState({ controls });
  }

  handleRadioChangePaid(e) {
    let { controls } = this.state;
    controls.paid_by_jk.value = e;
    this.setState({ controls });
  };

  componentDidMount() {
    this.getProgram();
    this.getTypeAndData();
    // this.getTypeFromCampaign();
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });

    if (e.target.name === 'program_name') {
      this.getType(e.target.value)
    }

  }

  getType = (program_name) => {
    this.setState({ isLoading: true });
    MarketingCouponService.getType({ program_name: program_name })
      .then(res => {
        let { type } = this.state;

        if (res.data.data) {
          type = res.data.data;
        } else {
          type = [];
        }

        this.setState({
          type,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }


  getTypeFromCampaign = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getTypeFromCampaign()
      .then(res => {
        let { type } = this.state;

        if (res.data.data) {
          type = res.data.data;
        } else {
          type = [];
        }

        this.setState({
          type,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  getProgram = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getProgram({ search_query: '' })
      .then(res => {

        let { programData } = this.state;
        if (res.data.data && res.data.data && res.data.data.length > 0) {
          programData = res.data.data;
        } else {
          programData = [];
        }
        this.setState({
          programData,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  getTypeAndData = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getTypeAndData()
      .then(res => {

        let { purpose } = this.state;
        if (res.data.data) {
          purpose = res.data.data.purpose;
        } else {
          purpose = [];
        }

        this.setState({
          purpose,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  applyFilter = () => {
    this.props.applyFilter(this.state.controls);
  }

  resetFilter = () => {
    this.props.resetFilter();
  }

  onCloseSidebar = () => {
    this.props.onCloseSidebar();
  }

  render() {
    let { controls, programData, type, purpose } = this.state;
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <>
          <div className="drawer-header">
            <span className="drawer-title" style={{marginTop:"5px"}}>
              Filter
            </span>
            <Ionicon
              className="cursor-pointer"
              icon="md-close"
              fontSize="24px"
              color="#fdbb1f"
              onClick={() => this.onCloseSidebar()}
            />
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
          <div className="custom-drawer-content-wrapperr Fix-height" style={{paddingLeft:"10px", paddingRight:"10px"}}>

          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col xl="12" md="12" xs="12">
              <FormGroup>
                <Label className="label-text">
                  Select Program
                  </Label>
                <select className="form-control form-group-select select select-height-70rem"
                  name="program_name"
                  value={controls.program_name.value}
                  onChange={(e) => { this.handleInputChange(e) }}>
                  <option className="label-text" value="">Select</option>
                  {
                    programData.map((item) => (
                      <option className="label-text" value={item.program_name}>{item.program_name}</option>
                    ))
                  }
                </select>
              </FormGroup>
            </Col>
          </Row>

          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col xl="12" md="12" xs="12">
              <FormGroup>
                <Label className="label-text">
                  Type
                  </Label>
                <select className="form-control form-group-select select select-height-70rem"
                  name="type"
                  value={controls.type.value}
                  onChange={(e) => { this.handleInputChange(e) }}>
                  <option className="label-text" value="">Select</option>
                  {
                    type.map((item) => (
                      <option className="label-text" value={item.vehicle_type_id}>{item.name}</option>
                    ))
                  }
                </select>
              </FormGroup>
            </Col>
          </Row>

          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col xl="12" md="12" xs="12">
              <FormGroup>
                <Label className="label-text">
                  Purpose
                  </Label>
                <select className="form-control form-group-select select select-height-70rem"
                  name="purpose"
                  value={controls.purpose.value}
                  onChange={(e) => { this.handleInputChange(e) }}>
                  <option className="label-text" value="">Select</option>
                  {
                    purpose.map((item) => (
                      <option className="label-text" value={item.id}>{item.name}</option>
                    ))
                  }
                </select>
              </FormGroup>
            </Col>
          </Row>

          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col xl="12" md="12" xs="12">
              <Label className="label-text">
                Offer Status
                  </Label>
            </Col>
            <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
              <FormGroup>
                <div className="d-flex-row pcrtype-wrapp">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="offer_status"
                      value=""
                      onChange={() => {
                        this.handleRadioChange("");
                      }}
                      checked={controls.offer_status.value === ""} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">All</span>
                </div>
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
              <FormGroup>
                <div className="d-flex-row pcrtype-wrapp" style={{ paddingRight: "0" }} >
                  <label className="custom-checkboax-radio--container">
                    <input type="radio"
                      name="offer_status"
                      value="on_going"
                      onChange={() => {
                        this.handleRadioChange("on_going");
                      }}
                      checked={controls.offer_status.value === "on_going"} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">On going</span>
                </div>
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
              <FormGroup>
                <div className="d-flex-row pcrtype-wrapp">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="offer_status"
                      value="closed"
                      onChange={() => {
                        this.handleRadioChange("closed");
                      }}
                      checked={controls.offer_status.value === "closed"} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">Closed</span>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col xl="12" md="12" xs="12">
              <Label for="common">Coupon Amount Paid</Label>
            </Col>
            <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
              <FormGroup>
                <div className="d-flex-row pcrtype-wrapp">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="paid_by_jk"
                      value=""
                      onChange={() => {
                        this.handleRadioChangePaid("");
                      }}
                      checked={controls.paid_by_jk.value === ""} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">All</span>
                </div>
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
              <FormGroup>
                <div className="d-flex-row pcrtype-wrapp">
                  <label className="custom-checkboax-radio--container">
                    <input type="radio"
                      name="paid_by_jk"
                      value="JK Tyre"
                      onChange={() => {
                        this.handleRadioChangePaid("JK Tyre");
                      }}
                      checked={controls.paid_by_jk.value === "JK Tyre"} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">JK Tyre</span>
                </div>
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
              <FormGroup>
                <div className="d-flex-row pcrtype-wrapp">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="paid_by_jk"
                      value="Dealer"
                      onChange={() => {
                        this.handleRadioChangePaid("Dealer");
                      }}
                      checked={controls.paid_by_jk.value === "Dealer"} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">Dealer</span>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col xl="12" md="12" xs="12">
              <Label className="filter-bolde-label" for="common">
                Coupon Created Between
                </Label>
            </Col>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label className="label-text" for="common">
                  Start Date
                  </Label>

                <Label className="date-picker-left-plus-15" onClick={e => this.calendar1.state.open && e.preventDefault()} >
                  <InputGroup>
                    <DatePicker className={"form-control"}
                      onChange={(e) => this.handleInputChange({ target: { name: 'start_date', value: e } })}
                      selected={controls.start_date.value}
                      name="start_date"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      ref={r => this.calendar1 = r}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      popperPlacement='top'
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '100px, 0px'
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                          boundariesElement: 'viewport'
                        }
                      }}
                      autoComplete="off"
                      placeholderText="Enter"
                      maxDate={controls.end_date.value ? controls.end_date.value : new Date()}
                      dropdownMode="select"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text">
                        <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
                {controls.start_date.showErrorMsg && <div className="error">*Please select date.</div>}
              </FormGroup>
            </Col>

            <Col xl="6" md="12" xs="12" style={{ paddingLeft: "0" }}>
              <FormGroup>
                <Label className="label-text" for="common">
                  End Date
                  </Label>

                <Label className="date-picker-left-15" onClick={e => this.calendar2.state.open && e.preventDefault()} >
                  <InputGroup>
                    <DatePicker className={"form-control"}
                      onChange={(e) => this.handleInputChange({ target: { name: 'end_date', value: e } })}
                      name="end_date"
                      selected={controls.end_date.value}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      ref={r => this.calendar2 = r}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      autoComplete="off"
                      minDate={controls.start_date.value ? controls.start_date.value : null}
                      placeholderText="Enter"
                      maxDate={new Date()}
                      popperPlacement='top'
                      dropdownMode="select"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '100px, 0px'
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                          boundariesElement: 'viewport'
                        }
                      }}
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text">
                        <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>

              </FormGroup>
            </Col>
          </Row>

        </div>

          <Row
            style={{
              marginLeft: "0",
              marginRight: "0",
              height: "100%",
              maxHeight: "80px",
              position: "relative",
              bottom: "0",
              width: "100%"
            }}
          >
            <Col
              xl="12"
              xs="12"
              md="12"
              className="text-align-right footer-drawer"
            >
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter()} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
            <Button className="mb-1 mr-1 btn-pill action-button font-weight-bold" color="warning" onClick={() => { this.applyFilter(); }} style={{ width: '116px' }}>Apply</Button>
            </Col>
          </Row>
        </>
      </>
    );
  }
}

export default CouponFilter;