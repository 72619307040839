import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, FormGroup, Input, Button } from 'reactstrap';
import ReactDOM from 'react-dom';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import AddCustomerService from '../../services/AddCustomerService';
import CustomerManagementService from '../../services/CustomerManagementService';
import swal from 'sweetalert';
import ModalService from '../../services/ModalService';
import { messages } from '../../constants/constant.message';
import Storage from '../../services/Storage';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

class ModalServicePref extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      details: {},
      isLoading: false,
      customer_services: [],
      vehicleTypeData: [],
      is_set_active_service: true,
      is_add_customer: false
    }
    if (Storage.getAddCustomerData() && this.props.is_first_service_pref) {
      this.state.storage_add_customer_warranty_data = Storage.getAddCustomerData();
      if (this.state.storage_add_customer_warranty_data &&
        Object.keys(this.state.storage_add_customer_warranty_data).length > 0) {
        this.state.is_add_customer = true;
      }
    }
    //this.toggle = this.toggle.bind(this);
  }
  initApi = async () => {
    await this.getVehicleType();
    this.getServices();
  }
  toggle = async (data) => {
    let { controls, details } = this.state;
    if (data && Object.keys(data).length > 0) {
      details = data;
      this.initApi();
    } else {
      details = {};
      // this.resetControls();
    }
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      controls,
      details
    }));
  }

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal
    }));
  }

  getVehicleType = async () => {
    this.setState({ isLoading: true });
    return new Promise(async (resolve, reject) => {
      CustomerManagementService.getVehicleType()
        .then(res => {
          let { vehicleTypeData } = this.state;
          if (res.data.data && res.data.data.length > 0) {
            vehicleTypeData = res.data.data;
          } else {
            vehicleTypeData = [];
          }
          this.setState({
            vehicleTypeData,
            isLoading: false
          })
          return resolve(null);
        })
        .catch(e => {
          this.setState({ isLoading: false });
          return reject(e);
          // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
          // const message = err && err.message ? err.message : ERROR_MESSAGE;

        });
    })
  }

  saveService = () => {
    if (!this.handleValidation()) {
      return;
    }
    let { customer_services } = this.state;
    let service = [];
    let is_set_active_service = false;
    customer_services.forEach(element => {
      service.push({
        "service_id": element.service_id,
        "personal_vehicle_life_span": element.personal_vehicle_life_span_of_dealer ? Number(element.personal_vehicle_life_span_of_dealer * 30) : 0,
        "commercial_vehicle_life_span": element.commercial_vehicle_life_span_of_dealer ? Number(element.commercial_vehicle_life_span_of_dealer * 30) : 0,
        "is_active": element.is_active,
        service_wise_vehicle_type_ids: element.vehicleTypeCheckBox && element.vehicleTypeCheckBox.length > 0 ?
          element.vehicleTypeCheckBox.map(a => a.vehicle_type_id) : []
      })
      if (element.is_active && !is_set_active_service) {
        is_set_active_service = true;
      }
    });

    if (!is_set_active_service) {
      this.setState({
        is_set_active_service: false
      })
      return;
    }

    this.setState({
      isLoading: true
    })
    AddCustomerService.saveService(service)
      .then((response) => {
        this.setState({
          isLoading: false
        })
        swal({
          title: "Congratulations !",
          text: response.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.closeModal();
          if (this.props.from === 'dealer-dashboard') {
            this.props.getUserDetail(true);
          }
          if (this.state.details &&
            this.state.details.is_from_add_customer_visit) {
            this.props.openAddCustomer();
          }
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false
        });

        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  getServices = () => {
    this.setState({
      isLoading: true
    })
    AddCustomerService.getService()
      .then((result) => {
        let { customer_services } = this.state;
        if (result && result.data && result.data.data.length > 0) {
          customer_services = result && result.data && result.data.data;
          customer_services.forEach(element => {
            element.is_open = false;
            element.commercial_vehicle_life_span_of_dealer_toched = false;
            element.personal_vehicle_life_span_of_dealer_toched = false;
            element.personal_vehicle_life_span_of_dealer = element.personal_vehicle_life_span_of_dealer ? Number(element.personal_vehicle_life_span_of_dealer / 30) : 0;
            element.commercial_vehicle_life_span_of_dealer = element.commercial_vehicle_life_span_of_dealer ? Number(element.commercial_vehicle_life_span_of_dealer / 30) : 0
            element.commercial_vehicle_life_span_of_dealer_err = false;
            element.personal_vehicle_life_span_of_dealer_err = false;
            element.vehicle_type_checkBox_errr = false;
            element.vehicle_type_toched = false;
            element.is_active = element.service_active_by_dealer;
            element.vehicle_type_data = '';
            element.vehicleTypeCheckBox = [];
            element.isVehicleTypeDropDownOpen = false;
            element.service_wise_vehicle_type_ids &&
              element.service_wise_vehicle_type_ids.forEach(vehicle_type_id => {
                let filter = this.state.vehicleTypeData.filter(x => x.vehicle_type_id === vehicle_type_id);
                if (filter.length > 0)
                  element.vehicleTypeCheckBox.push(filter[0]);
              });
          });

        }

        this.setState({
          customer_services,
          isLoading: false
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          isLoading: false
        })
      });
  };
  handleVehicleTypeCheckBoxInput = (i, vehicle) => {
    let { customer_services } = this.state;
    const index = customer_services[i].vehicleTypeCheckBox.findIndex(x => x.vehicle_type_id === vehicle.vehicle_type_id);
    if (index > -1) {
      customer_services[i].vehicleTypeCheckBox.splice(index, 1);
    } else {
      customer_services[i].vehicleTypeCheckBox.push(vehicle);
    }
    customer_services[i].vehicle_type_toched = true;
    this.setState({
      customer_services
    });
    this.handleValidation(i);
  }
  handleValidation = (i = -1) => {
    let { customer_services } = this.state;
    let error_count = 0;
    if (i > -1) {
      if (customer_services[i].commercial_vehicle_life_span_of_dealer_toched) {
        if (customer_services[i].commercial_vehicle_life_span_of_dealer === '' || customer_services[i].commercial_vehicle_life_span_of_dealer === null || customer_services[i].commercial_vehicle_life_span_of_dealer === undefined) {
          customer_services[i].commercial_vehicle_life_span_of_dealer_err = true;
          error_count++;
        } else {
          customer_services[i].commercial_vehicle_life_span_of_dealer_err = false;
        }
      }

      if (customer_services[i].personal_vehicle_life_span_of_dealer_toched) {
        if (customer_services[i].personal_vehicle_life_span_of_dealer === '' || customer_services[i].personal_vehicle_life_span_of_dealer === null || customer_services[i].personal_vehicle_life_span_of_dealer === undefined) {
          customer_services[i].personal_vehicle_life_span_of_dealer_err = true;
          error_count++;
        } else {
          customer_services[i].personal_vehicle_life_span_of_dealer_err = false;
        }
      }
      if (customer_services[i].vehicle_type_toched) {
        if (customer_services[i].vehicleTypeCheckBox.length === 0 && customer_services[i].is_active) {
          customer_services[i].vehicle_type_checkBox_errr = true;
          error_count++;
        } else {
          customer_services[i].vehicle_type_checkBox_errr = false;
        }
      }
    } else {
      customer_services.forEach(element => {
        if (element.commercial_vehicle_life_span_of_dealer_toched) {
          if (element.commercial_vehicle_life_span_of_dealer === '' || element.commercial_vehicle_life_span_of_dealer === null || element.commercial_vehicle_life_span_of_dealer === undefined) {
            element.commercial_vehicle_life_span_of_dealer_err = true;
            error_count++;
          } else {
            element.commercial_vehicle_life_span_of_dealer_err = false;
          }
        }
        if (element.personal_vehicle_life_span_of_dealer_toched) {
          if (element.personal_vehicle_life_span_of_dealer === '' || element.personal_vehicle_life_span_of_dealer === null || element.personal_vehicle_life_span_of_dealer === undefined) {
            element.personal_vehicle_life_span_of_dealer_err = true;
            error_count++;
          } else {
            element.personal_vehicle_life_span_of_dealer_err = false;
          }
        }
        if (element.vehicleTypeCheckBox.length === 0 && element.is_active) {
          element.vehicle_type_checkBox_errr = true;
          error_count++;
        } else {
          element.vehicle_type_checkBox_errr = false;
        }
      });
    }
    this.setState({
      customer_services
    })

    return error_count === 0;
  }
  handleChangeSpan = (e, i) => {
    let { customer_services } = this.state;
    if (!isNaN(e.target.value)) {
      if (e.target.value !== 0 && e.target.value !== '0') {
        customer_services[i][e.target.name] = e.target.value;
      }
    }
    if (e.target.name === 'commercial_vehicle_life_span_of_dealer') {
      customer_services[i]['commercial_vehicle_life_span_of_dealer_toched'] = true;
    }
    if (e.target.name === 'personal_vehicle_life_span_of_dealer') {
      customer_services[i]['personal_vehicle_life_span_of_dealer_toched'] = true;
    }
    this.setState({
      customer_services
    });
    this.handleValidation(i);
  }

  openCloseVehicleTypeDropDown = (i) => {
    let { customer_services } = this.state;
    customer_services[i].isVehicleTypeDropDownOpen = !customer_services[i].isVehicleTypeDropDownOpen;
    this.setState({
      customer_services
    })
  }

  handleClickOutside = (event) => {
    if (this.dropDownMenuContainer) {
      const domNode = ReactDOM.findDOMNode(this.dropDownMenuContainer);
      if (!domNode || !domNode.contains(event.target)) {
        let { customer_services } = this.state;
        customer_services.filter(x => x.isVehicleTypeDropDownOpen = false);
        this.setState({ customer_services })
      }
    }
  }
  closeVehicleTypeDropDownWhenClickedOutsideDropDown = event => {
    let { customer_services } = this.state;
    if (this.dropDownMenuContainer.current && this.dropDownMenuContainer.current.contains(event.target)) {

    } else {
      customer_services.filter(x => x.isVehicleTypeDropDownOpen = false);
      this.setState({ customer_services })
    }
  };

  onChangeServiceActive = (i) => {
    let { customer_services } = this.state;
    customer_services[i].is_active = !customer_services[i].is_active;
    this.setState({
      customer_services,
      is_set_active_service: true
    })
  }

  resetDefault = () => {
    GoogleAnalticsService.event('Sercvice Preference', 'Reset Default Service Click');
    swal({
      title: "Reset Preference",
      text: "This will reset preference for vehicle type as well duration span for all services to system default value. Do you want to continue?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        let { customer_services } = this.state;
        customer_services.forEach(element => {
          element.personal_vehicle_life_span_of_dealer = element.personal_vehicle_life_span;
          element.commercial_vehicle_life_span_of_dealer = element.commercial_vehicle_life_span;
          element.personal_vehicle_life_span_of_dealer = element.personal_vehicle_life_span_of_dealer ? Number(element.personal_vehicle_life_span_of_dealer / 30) : 0;
          element.commercial_vehicle_life_span_of_dealer = element.commercial_vehicle_life_span_of_dealer ? Number(element.commercial_vehicle_life_span_of_dealer / 30) : 0
          //element.is_active = true;
          element.vehicleTypeCheckBox = [];
          element.main_vehicle_type_id &&
            element.main_vehicle_type_id.forEach(vehicle_type_id => {
              let filter = this.state.vehicleTypeData.filter(x => x.vehicle_type_id === vehicle_type_id);
              if (filter.length > -1)
                element.vehicleTypeCheckBox.push(filter[0]);
            });
          element.service_wise_vehicle_type_ids = element.main_vehicle_type_id;
        });

        this.setState({
          customer_services
        }, () => {
          this.saveService();
        });
        this.handleValidation(-1);
      }
    });
  }


  render() {
    let { vehicleTypeData } = this.state;
    let { is_first_service_pref } = this.props;
    return (
      <>
        <Modal
          centered={false}
          isOpen={this.state.modal} toggle={this.toggle} className="modal-md modal-lg custom-confirm-modal modal-sm"
          style={{ maxWidth: '910px' }} backdrop={this.backdrop ? 'static' : 'nostatic'}
          id="ModalServicePref"
          onClick={this.handleClickOutside}>
          {this.state.isLoading && <CustomSpinner />}
          <div className="d-flex-column">
            <div className="confirm-modal-title-wrapp d-flex-column">
              <span className="confirm-modal-title text-capitalize w-100" style={{
                fontSize: '16px',
                marginLeft: '10px'
              }}>
                {is_first_service_pref ? 'SETUP ' : ''}SERVICE PREFERENCES
              </span>
              <div className="mt-2 header-note ml-2">Please select services which you are providing to your customers. Services which you will select here will be displayed while adding or searching customer data.</div>
              {/* <img className="cursor-pointer float-right" src="images/close.svg" alt='cancel' onClick={() => this.toggle({})} /> */}
            </div>
          </div>
          <ModalBody
            className={'d-flex-column'}>
            <Row className="m-0">
              {!is_first_service_pref &&
                <Col xl="12" className="text-align-right mb-2">
                  <span className="Reset-to-Default cursor-pointer"
                    onClick={() => this.resetDefault()}>
                    Reset to Default
                  </span>
                </Col>
              }
              <Col>
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="text-center" rowSpan="2">Select Service</th>
                      <th style={{ paddingLeft: '20px', paddingRight: '18px', maxWidth: '426px' }} rowSpan="2">
                        <div className="d-flex-column">
                          <span>Vehicle Types</span>
                          <span className="vehicle-type-note">Please select vehicle types for which you are offering services. Selected vehicle types will be displayed while adding new customer.</span>
                        </div>
                      </th>
                      <th className="text-center" colSpan="2">
                        Service Span in Months
                      </th>
                      {/* <th className="text-center">Commercial</th>
                      <th className="text-center">Personal</th> */}
                    </tr>
                    <tr>
                      <th className="text-center commercial-personal">Commercial</th>
                      <th className="text-center commercial-personal">Personal</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      this.state.customer_services &&
                      this.state.customer_services.map((object, i) => (
                        <tr>
                          <td>
                            <div className="d-flex-row justify-content-between mt-auto mb-auto ml-2">
                              <div className="d-flex mt-auto mb-auto">
                                <label className="custom-checkboax1--container">
                                  <input
                                    type="checkbox"
                                    name="service"
                                    checked={object.is_active}
                                    onChange={(e) =>
                                      this.onChangeServiceActive(i)
                                    }
                                  />
                                  <span className="checkmark1" />
                                </label>
                                <span className="service-pref-detail-name" style={{ fontSize: '14px', marginLeft: "10px" }}>
                                  {object.name}
                                </span>
                              </div>

                              {/* <div className="d-flex mt-auto mb-auto">
                                <img
                                  className="cursor-pointer"
                                  src="images/edit.svg"
                                   alt=""
                                  style={{ height: "18px" }}
                                  onClick={() => this.openSpan(i)}
                                />
                              </div> */}

                            </div>
                          </td>
                          <td>
                            <div style={{ position: 'relative' }} className='m-1'>
                              <div
                                onClick={() => this.openCloseVehicleTypeDropDown(i)}
                                className="form-control option-text cursor-pointer drop-down-control"
                              // checked={usageTypeCheckBox.value.includes(v.value)}
                              // onChange={this.handleUsageTypeCheckBoxInput.bind(this, v.value)}
                              >
                                {
                                  object.vehicleTypeCheckBox.length > 0 ? object.vehicleTypeCheckBox.map((v, index) =>
                                    <span className="dropdown-vehicle-name">
                                      {
                                        index === 0 ?
                                          v.name :
                                          `, ${v.name}`
                                      }
                                    </span>
                                  ) : <span className="dropdown-vehicle-no-name">Select</span>
                                }
                                {object.vehicle_type_names}
                                <div className="custom-drop-down-arrow">
                                  <img src="/images/down-arrow.svg" alt="" />
                                </div>
                              </div>

                              {object.isVehicleTypeDropDownOpen && <div className="custom-drop-down"
                                ref={r => this.dropDownMenuContainer = r}>
                                {vehicleTypeData.map((v, index) =>
                                  <>
                                    {(object.main_vehicle_type_id).includes(v.vehicle_type_id) && <div style={v.name.toLowerCase() === '2 wheeler' || v.name.toLowerCase() === 'pickup' ? { width: '100px' } : {}}
                                      className="custom-drop-down-item">
                                      <label class="container-checkbox drop-down-control" style={{ marginBottom: '0' }}>
                                        <input type="checkbox"
                                          name="checkbox"
                                          id="checkbox"
                                          className="option-text"
                                          checked={object.vehicleTypeCheckBox.findIndex(x => x.vehicle_type_id === v.vehicle_type_id) > -1}
                                          onChange={() => this.handleVehicleTypeCheckBoxInput(i, v)}
                                        />
                                        {v.name}
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>}
                                  </>
                                )}
                              </div>}
                            </div>
                            {object.vehicle_type_checkBox_errr &&
                              <div className="error show_error">
                                *Please select at least one vehicle type for {object.name}
                              </div>
                            }
                          </td>
                          {
                            object.is_set_service_due_date ?
                              <>
                                <td>
                                  <div className="d-flex-column">
                                    <FormGroup className="d-flex-row mb-0">
                                      <Input
                                        type="text"
                                        autoComplete="nofill"
                                        name="commercial_vehicle_life_span_of_dealer"
                                        id="commercial_vehicle_life_span_of_dealer"
                                        value={object.commercial_vehicle_life_span_of_dealer}
                                        onChange={(e) => this.handleChangeSpan(e, i)}
                                        style={{ width: '100px' }}
                                        placeholder="Enter Span"
                                      />
                                    </FormGroup>
                                    {object.commercial_vehicle_life_span_of_dealer_err &&
                                      <div className="error show_error">
                                        *Please enter span
                                      </div>
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex-column">
                                    <FormGroup className="d-flex-row mb-0">
                                      <Input
                                        type="text"
                                        autoComplete="nofill"
                                        name="personal_vehicle_life_span_of_dealer"
                                        id="personal_vehicle_life_span_of_dealer"
                                        value={object.personal_vehicle_life_span_of_dealer}
                                        onChange={(e) => this.handleChangeSpan(e, i)}
                                        style={{ width: '100px' }}
                                        placeholder="Enter Span"
                                      />
                                    </FormGroup>
                                    {object.personal_vehicle_life_span_of_dealer_err &&
                                      <div className="error show_error">
                                        *Please enter span
                                      </div>
                                    }
                                  </div>
                                </td>
                              </> :
                              <td colSpan="2">
                                <div className="d-flex">
                                  <span className="no-span-req m-auto">No Span Required</span>
                                </div>
                              </td>
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                {!this.state.is_set_active_service &&
                  <div className="error show_error">*Please select at least one service</div>
                }
              </Col>
              <Col
                xl="12"
                xs="12"
                md="12"
                className="text-align-center mt-4"
              >
                {
                  (this.state.is_add_customer) ? "" :
                    <Button
                      className={"mb-1 mr-1 btn-pill action-button profile-cancel-button"}
                      color="dark"
                      onClick={() => this.closeModal()} style={{ width: '116px' }}>
                      Cancel
                    </Button>
                }&nbsp;&nbsp;
                <Button
                  disabled={false}
                  className="mb-1 mr-1 btn-pill action-button profile-save-button"
                  color="warning"
                  onClick={() => this.saveService()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      </>
    );
  }
}

export default ModalServicePref;