import React, { Component, Fragment } from 'react';
import {
  Row, Col,
  Card, CardBody
} from 'reactstrap';
import Ionicon from 'react-ionicons';
import { formatNumber } from '../../utils/utils';
import { config } from '../../constants/constant.config';

import './CountDetailMobile.css';

let dashBoardImages = config.DASHBOARD;

class CountDetailMobile extends Component {
  render() {
    const {
      period, count, pcr = null, truck = null, farm = null, wheeler = null, lcv = null, scv = null, displayLabels
    } = this.props;
    let duration = null;
    if (displayLabels && period) {
      if (period === 'Year' && displayLabels.year_till_previous_quarter) {
        duration = displayLabels.year_till_previous_quarter;
      }
      if (period === 'Quarter' && displayLabels.quarter) {
        duration = displayLabels.quarter;
      }
      if (period === 'Month' && displayLabels.month) {
        duration = displayLabels.month;
      }
    }

    return <div id="count-dashboard-mobile">
      <div className="page-title">
        <span>
          <Ionicon icon="ios-arrow-back" onClick={this.props.changeScreen.bind(this, 'dashboard')} fontSize="40px" color="#fdbb1f" style={{ marginLeft: '-15px' }} />
        </span>
        <span>{period} Till Date</span>
      </div>
      <br />
      <Row>
        <Col>
          <div className="dashboard-page-card-title-big">Points {period} Till Date </div>
          <div className="card-count-period text-align-left" >{duration}</div>
        </Col>
        <Col className="text-align-right count-big">
          {formatNumber(count)}
        </Col>
      </Row>
      <br />
      {pcr && pcr.offtake !== null && pcr.offtake > 0 &&
        <Fragment>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
                      <div className="category">
                        PCR
                  </div>
                      <div className="text-align-right">
                        <img src={dashBoardImages.PCR_IMAGE} alt="" className="image" width="88px" height="46px" />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="offtake-color">Offtake</Col>
                        <Col className="points">{formatNumber(pcr.offtake)}</Col>
                      </Row>
                      <Row>
                        <Col className="offtake-color">Points</Col>
                        <Col className="points">{formatNumber(pcr.points)}</Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Fragment>
      }

      {truck && truck.offtake !== null && truck.offtake > 0 &&
        <Fragment>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
                      <div className="category">
                        TRUCK
                  </div>
                      <div className="text-align-right">
                        <img src={dashBoardImages.TRUCK_IMAGE} alt="" className="image" width="88px" height="46px" />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="offtake-color">Offtake</Col>
                        <Col className="points">{formatNumber(truck.offtake)}</Col>
                      </Row>
                      <Row>
                        <Col className="offtake-color">Points</Col>
                        <Col className="points">{formatNumber(truck.points)}</Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Fragment>
      }
      {wheeler && wheeler.offtake !== null && wheeler.offtake > 0 &&
        <Fragment>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
                      <div className="category">
                        2 WHEELER
                  </div>
                      <div className="text-align-right">
                        <img src={dashBoardImages.WHEELER_IMAGE} alt="" className="image" width="88px" height="46px" />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="offtake-color">Offtake</Col>
                        <Col className="points">{formatNumber(wheeler.offtake)}</Col>
                      </Row>
                      <Row>
                        <Col className="offtake-color">Points</Col>
                        <Col className="points">{formatNumber(wheeler.points)}</Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Fragment>
      }


      {farm && farm.offtake !== null && farm.offtake > 0 &&
        <Fragment>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
                      <div className="category">
                        FARM
                      </div>
                      <div className="text-align-right">
                        <img src={dashBoardImages.FARM_IMAGE} alt="" className="image" width="88px" height="46px" />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="offtake-color">Offtake</Col>
                        <Col className="points">{formatNumber(farm.offtake)}</Col>
                      </Row>
                      <Row>
                        <Col className="offtake-color">Points</Col>
                        <Col className="points">{formatNumber(farm.points)}</Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Fragment>
      }


      {lcv && lcv.offtake !== null && lcv.offtake > 0 &&
        <Fragment>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
                      <div className="category">
                        LCV
                    </div>
                      <div className="text-align-right">
                        <img src={dashBoardImages.LCV_IMAGE} alt="" className="image" width="88px" height="46px" />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="offtake-color">Offtake</Col>
                        <Col className="points">{formatNumber(lcv.offtake)}</Col>
                      </Row>
                      <Row>
                        <Col className="offtake-color">Points</Col>
                        <Col className="points">{formatNumber(lcv.points)}</Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Fragment>
      }

      {scv && scv.offtake !== null && scv.offtake > 0 &&
        <Fragment>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
                      <div className="category">
                        SCV
                  </div>
                      <div className="text-align-right">
                        <img src={dashBoardImages.SCV_IMAGE} alt="" className="image" width="88px" height="46px" />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="offtake-color">Offtake</Col>
                        <Col className="points">{formatNumber(scv.offtake)}</Col>
                      </Row>
                      <Row>
                        <Col className="offtake-color">Points</Col>
                        <Col className="points">{formatNumber(scv.points)}</Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Fragment>}

    </div>;
  }
}

export default CountDetailMobile;