import React, { Component, Fragment } from 'react';
import {
  Row, Col,
  Card, CardBody,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';

import { formatNumber } from '../../utils/utils';
import { config } from '../../constants/constant.config';
let dashBoardImages = config.DASHBOARD;

class DashboardCard extends Component {
  state = {
    redirectToAbout: false
  }

  redirectToAboutScreen = () => {
    this.setState({ redirectToAbout: true });
  }

  render() {

    const { showCard, period = null, count, pcr = null, truck = null, farm = null, wheeler = null, lcv = null, scv = null, displayLabels } = this.props;
    let duration = null;
    if (period && period === 'Year' && displayLabels && displayLabels.year_till_previous_quarter) {
      duration = displayLabels.year_till_previous_quarter;
    }
    if (period && period === 'Quarter' && displayLabels && displayLabels.quarter) {
      duration = displayLabels.quarter;
    }
    if (period && period === 'Month' && displayLabels && displayLabels.month) {
      duration = displayLabels.month;
    }
    return <Fragment>
      {this.state.redirectToAbout && < Redirect to="/about"></Redirect>}
      <Card className="height-100 border-radius-5">
        <Row className="card-custom-header">
          <Col className="dashboard-page-card-title-big">
            <div>
              {period} Till Date
            </div>
            <div className="dashboard-page-card-title-small">

              {duration}
            </div>
            {/* {period} Till Date {duration} */}
          </Col>
          <Col className="count-small">{formatNumber(count)}</Col>
        </Row>
        {showCard && <CardBody>
          <Row>
            <Col className="dashboard-page-card-title-small-gray">Category</Col>
            <Col className="dashboard-page-card-title-small-gray text-align-center">Offtake</Col>
            <Col className="dashboard-page-card-title-small-gray text-align-right">Points</Col>
          </Row>
          <br />
          {pcr && pcr.offtake !== null && pcr.offtake > 0 &&
            <Fragment>
              <Row>
                <Col><img className="image" src={dashBoardImages.PCR_IMAGE} alt="" /></Col>
                <Col className="text-align-center card-count">{pcr.offtake}</Col>
                <Col className="text-align-right card-count">{formatNumber(pcr.points)}</Col>
              </Row>
              <br />
            </Fragment>
          }
          {truck && truck.offtake !== null && truck.offtake > 0 &&
            <Fragment>
              <Row>
                <Col><img className="image" src={dashBoardImages.TRUCK_IMAGE} alt="" /></Col>
                <Col className="text-align-center card-count">{truck.offtake}</Col>
                <Col className="text-align-right card-count">{formatNumber(truck.points)}</Col>
              </Row>
              <br />
            </Fragment>
          }

          {wheeler && wheeler.offtake !== null && wheeler.offtake > 0 &&
            <Fragment>
              <Row>
                <Col><img className="image" src={dashBoardImages.WHEELER_IMAGE} alt="" /></Col>
                <Col className="text-align-center card-count">{wheeler.offtake}</Col>
                <Col className="text-align-right card-count">{formatNumber(wheeler.points)}</Col>
              </Row>
              <br />
            </Fragment>
          }

          {farm && farm.offtake !== null && farm.offtake > 0 &&
            <Fragment>
              <Row>
                <Col><img className="image" src={dashBoardImages.FARM_IMAGE} alt="" /></Col>
                <Col className="text-align-center card-count">{farm.offtake}</Col>
                <Col className="text-align-right card-count">{formatNumber(farm.points)}</Col>
              </Row>
              <br />
            </Fragment>
          }


          {lcv && lcv.offtake !== null && lcv.offtake > 0 &&
            <Fragment>
              <Row>
                <Col><img className="image" src={dashBoardImages.LCV_IMAGE} alt="" /></Col>
                <Col className="text-align-center card-count">{lcv.offtake}</Col>
                <Col className="text-align-right card-count">{formatNumber(lcv.points)}</Col>
              </Row>
              <br />
            </Fragment>
          }

          {scv && scv.offtake !== null && scv.offtake > 0 &&
            <Fragment>
              <Row>
                <Col><img className="image" src={dashBoardImages.SCV_IMAGE} alt="" /></Col>
                <Col className="text-align-center card-count">{scv.offtake}</Col>
                <Col className="text-align-right card-count">{formatNumber(scv.points)}</Col>
              </Row>
              <br />
            </Fragment>
          }
        </CardBody>}
        {!showCard && <CardBody className="display-content-center">
          <div className="no-content link" onClick={this.redirectToAboutScreen}>
            Know how to earn points
        </div>
        </CardBody>}
      </Card>
    </Fragment >
      ;
  }
}

export default DashboardCard;