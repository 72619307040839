import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import ProposalStatus from "../../components/ProposalStatus/ProposalStatus";
import Storage from "../../services/Storage";
import ProposalSerivice from "../../services/ProposalSerivice";
import UploadMachineBill from "../../components/UploadMachineBill/UploadMachineBill";
import EstimateNeeded from "../../components/EstimateNeeded/EstimateNeeded";
import _ from "lodash";
import * as Scroll from "react-scroll";
import ProposalDetails from "../ProposalDetails/ProposalDetails";
class ProjectsStageAM extends Component {
  steelWheelState = [
    {
      id: "recci_in_progress",
      name: "Recci in progress",
      count: 1,
    },
    {
      id: "layout_agreement",
      name: "Layout & Agreement",
      count: 1,
    },
    {
      id: "estimate_needed",
      name: "Estimate Needed",
      count: 1,
    },
    {
      id: "Dealer_work_in_progress",
      name: "Dealer Work In Progress",
      count: 1,
    },
    {
      id: "Agency_work_in_progress",
      name: "Agency work in progress",
      count: 1,
    },
    {
      id: "Hand Over",
      name: "Hand Over",
      count: 1,
    },
    {
      id: "Pre inauguration",
      name: "Pre inauguration",
      count: 1,
    },
    {
      id: "Inaugurated",
      name: "Inaugurated",
      count: 1,
    },
    {
      id: "Project completed",
      name: "Project completed",
      count: 1,
    },
  ];
  xpressWheelState = [
    {
      id: "recci_in_progress",
      name: "Recci in progress",
      counts: 0,
    },
    {
      id: "layout_agreement",
      name: "Layout & Agreement",
      counts: 0,
    },
    {
      id: "Dealer_work_in_progress",
      name: "Dealer Work In Progress",
      counts: 0,
    },
    {
      id: "Agency_work_in_progress",
      name: "Agency work in progress",
      counts: 0,
    },
    {
      id: "Hand Over",
      name: "Hand Over",
      counts: 0,
    },
    {
      id: "Pre inauguration",
      name: "Pre inauguration",
      counts: 0,
    },
    {
      id: "Inaugurated",
      name: "Inaugurated",
      counts: 0,
    },
    {
      id: "Project completed",
      name: "Project completed",
      counts: 0,
    },
  ];
  state = {};
  constructor(props) {
    super(props);
    // const query = new URLSearchParams(this.props.location.search);
    let userDetail = Storage.getUserDetail();
    this.state = {
      userDetail: userDetail,
      proposalStageStatus: ProposalSerivice.getProposalStageStatus()
        ? ProposalSerivice.getProposalStageStatus()
        : {},
      isRecciInProgressReadOnly: true,
      proposalByIdData: {},
      currentPriority: 0,
      is_display_view_deatil: false,
      proposalStageDetail: {
        agency_completion_data: [],
        dealership_completion_data: [],
        estimation_data: [],
        intrim_visit_data: [],
        layout_data: [],
        proposal_status: [],
        recci_data: [],
        stages_details: [],
        status_history: [],
      },
      progressStates: [],
    };

    if (
      this.state.proposalStageStatus &&
      Object.keys(this.state.proposalStageStatus).length <= 0
    ) {
      this.goToPrevPage();
    }
    // if (
    //   this.state.proposalStageStatus &&
    //   Object.keys(this.state.proposalStageStatus).length > 0
    // ) {
    //   if (
    //     this.state.proposalStageStatus.project_status &&
    //     this.state.proposalStageStatus.project_status.toLowerCase() ===
    //       "fresh proposal" &&
    //     this.state.userDetail.access_level === "all"
    //   ) {
    //     this.state.isFreshProposal = true;
    //   }
    // }

    if (
      this.state.proposalStageStatus &&
      this.state.proposalStageStatus.outlet_type &&
      (this.state.proposalStageStatus.outlet_type.toLowerCase() ===
        "xpress wheel" ||
        this.state.proposalStageStatus.outlet_type.toLowerCase() ===
        "farm xpress wheel")
    ) {
      this.state.progressStates = this.xpressWheelState;
    } else {
      this.state.progressStates = this.steelWheelState;
    }
    this.state.progressStateCurrentIndex = 0;
    this.childHandOver = React.createRef();
    this.childProposalStatus = React.createRef();
    this.refProposalDetails = React.createRef();
  }

  componentDidMount() {
    this.initApi();
  }
  initApi = async () => {
    //await this.getAgencyList();
    await this.getPrposalById();
    this.getProposalStageDetail();
  };

  getProposalStageDetail = () => {
    if (
      (!this.state.proposalStageStatus &&
        !this.state.proposalStageStatus.uuid) ||
      this.state.proposalStageStatus.uuid === undefined
    ) {
      return;
    }
    this.setState({ isLoading: true });
    ProposalSerivice.getProposalStageDetail(this.state.proposalStageStatus.uuid)
      .then((res) => {
        let { currentPriority } = this.state;
        let currentIndex = -1;
        if (res.data.data && res.data.data.length > 0) {
          let len = res.data.data[0].stages_details.length;
          let stage = "";
          if (
            res.data.data[0].proposal_status[0].project_status ===
            "Proposal accepted"
          ) {
            stage = "Recci in progress";
          }
          let maxPriority = 0;
          if (res.data.data[0].stages_details.length > 0) {
            maxPriority = Math.max.apply(
              Math,
              res.data.data[0].stages_details.map(function (o) {
                return o.priority;
              })
            );
            currentPriority = maxPriority;
          }
          if (maxPriority && maxPriority > 0) {
            let stageObj = _.filter(
              res.data.data[0].stages_details,
              (element) => {
                return element.priority === maxPriority;
              }
            );
            if (stageObj && stageObj.length > 0) {
              stage = stageObj[0].stage;
            }
          }
          currentIndex = this.state.progressStates.findIndex(
            (x) => x.name.toLowerCase() === stage.toLowerCase()
          );
          if (len > 0) {
            currentIndex++;
          }
          if (
            this.state.proposalStageStatus.project_status.toLowerCase() ===
            "fresh proposal"
          ) {
            currentIndex = -1;
          }
          res.data.data[0].stages_details.forEach((element) => {
            if (element.completed_date) {
              let index = -1;
              if (element.stage) {
                index = this.state.progressStates.findIndex(
                  (x) => x.name.toLowerCase() === element.stage.toLowerCase()
                );
              }
              if (index > -1) {
                this.state.progressStates[index].completed_date =
                  element.completed_date;
              }
            }
          });

          if (
            currentIndex === 1 &&
            this.state.proposalByIdData.stage &&
            this.state.proposalByIdData.stage === "Recci in progress"
          ) {
            currentIndex = 0;
          }
          if (
            currentIndex === 2 &&
            this.state.proposalByIdData.stage &&
            this.state.proposalByIdData.stage === "Layout & Agreement"
          ) {
            currentIndex = 1;
          }

          if (this.state.proposalByIdData.outlet_type === "Steel Wheel") {
            if (
              currentIndex === 4 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Dealer work in progress"
            ) {
              currentIndex = 3;
            }
            if (
              currentIndex === 5 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Agency work in progress"
            ) {
              currentIndex = 4;
            }
            if (
              currentIndex === 6 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Hand Over"
            ) {
              currentIndex = 5;
            }
            if (
              currentIndex === 7 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Pre inauguration"
            ) {
              currentIndex = 6;
            }

            if (
              currentIndex === 8 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Inaugurated"
            ) {
              currentIndex = 7;
            }
          } else {
            if (
              currentIndex === 3 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Dealer work in progress"
            ) {
              currentIndex = 2;
            }
            if (
              currentIndex === 4 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Agency work in progress"
            ) {
              currentIndex = 3;
            }
            if (
              currentIndex === 5 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Hand Over"
            ) {
              currentIndex = 4;
            }
            if (
              currentIndex === 6 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Pre inauguration"
            ) {
              currentIndex = 5;
            }

            if (
              currentIndex === 7 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Inaugurated"
            ) {
              currentIndex = 6;
            }
          }

          this.setState(
            (prevState) => {
              return {
                ...prevState,
                proposalStageDetail: res.data.data[0],
                progressStateCurrentIndex: currentIndex,
                currentPriority: currentPriority,
              };
            },
            () => {
              this.childProposalStatus.current.setPreInauguration_date();
            }
          );
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };
  getPrposalById = async () => {
    if (
      (!this.state.proposalStageStatus &&
        !this.state.proposalStageStatus.uuid) ||
      this.state.proposalStageStatus.uuid === undefined
    ) {
      return;
    }
    try {
      this.setState({ isLoading: true });
      let res = await ProposalSerivice.getProposalById(
        this.state.proposalStageStatus.uuid
      );
      if (res.data.data && res.data.data.length > 0) {
        // let { controls } = this.state;
        // if (res.data.data[0].agency_id) {
        //   controls.agency.value = res.data.data[0].agency_id;
        //   let agency = this.state.agencyList.filter(x => x.id === res.data.data[0].agency_id);
        //   if (agency.length > 0) {
        //     controls.phoneNumber.value = agency[0].phonenumber;
        //   }
        // }
        this.setState((prevState) => {
          return {
            ...prevState,
            proposalByIdData: res.data.data[0],
            isLoading: false,
          };
        });
      }
      return true;
    } catch (error) {
      this.setState({ isLoading: false });
      return true;
    }
  };
  goToPrevPage = () => {
    this.props.history.goBack();
  };

  redirectViewProposal = (id) => {
    this.props.history.push(`proposal-details?id=${id}`);
  };

  openViewCloseViewDetail = () => {
    let { is_display_view_deatil, proposalByIdData } = this.state;
    is_display_view_deatil = !is_display_view_deatil;
    this.setState(
      {
        is_display_view_deatil,
      },
      () => {
        if (is_display_view_deatil) {
          this.refProposalDetails.current.getViewDetail(proposalByIdData.uuid);
          this.scrollTo("proposal-view-detail");
        }
      }
    );
  };

  scrollTo(id) {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80,
    });
  }

  render() {
    let { proposalStageDetail } = this.state;
    return (
      <>
        <div className="header-title">Project Stage</div>
        <br />
        {!this.state.isFreshProposal && (
          <>
            <Card>
              <CardBody>
                <Row className="dealership-details-title-wrapp">
                  <Col xl="6" md="6" xs="6">
                    <b>Project status</b>
                  </Col>
                  <div className="d-flex-row">
                    {/* <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: 'right' }}
                      onClick={(e) => this.redirectViewProposal(this.state.proposalStageStatus.uuid)}
                    >
                      View Detail
          </Button>&nbsp;&nbsp; */}
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="dark"
                      onClick={() => this.goToPrevPage()}
                    >
                      Back
                    </Button>
                  </div>
                </Row>
                <hr style={{ marginTop: "0.5rem" }} />
                <div>
                  <section>
                    <nav>
                      <ol className="cd-breadcrumb triangle custom-icons">
                        {this.state.progressStates.map((row, i) => (
                          <li
                            className={
                              this.state.progressStateCurrentIndex > i
                                ? "current-before"
                                : this.state.progressStateCurrentIndex === i
                                  ? "current"
                                  : ""
                            }
                          >
                            <span>
                              {row.name}
                              <br />
                              <span style={{ marginLeft: "15px" }}>
                                {row.completed_date}
                              </span>
                            </span>
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </section>
                </div>
                <br />
                {this.state.proposalStageDetail &&
                  this.state.proposalStageDetail.status_history &&
                  this.state.proposalStageDetail.status_history.length > 0 && (
                    <div className="project-stage-status-tbl">
                      <Table className="mb-0  tbl-about tbl-about table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Status</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Status Change Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.proposalStageDetail.status_history.map(
                            (item) => (
                              <tr>
                                <td>{item.status}</td>
                                <td>{item.remark ? item.remark : "--"}</td>
                                <td>{item.created_at}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}
              </CardBody>
            </Card>
            <br />
            <ProposalStatus
              userDetail={this.state.userDetail}
              proposalByIdData={this.state.proposalByIdData}
              ref={this.childProposalStatus}
              proposalStageStatus={this.state.proposalStageStatus}
              proposalStageDetail={this.state.proposalStageDetail}
              openViewCloseViewDetail={() => this.openViewCloseViewDetail()}
              goToPrevPage={() => this.goToPrevPage()}
            />
          </>
        )}
        {this.state.is_display_view_deatil && (
          <div className="proposal-view-detail">
            <br />
            <ProposalDetails
              goBack={this.openViewCloseViewDetail}
              is_display_view_deatil={this.state.is_display_view_deatil}
              ref={this.refProposalDetails}
            />
          </div>
        )}
        {proposalStageDetail &&
          proposalStageDetail.proposal_status &&
          proposalStageDetail.proposal_status.length > 0 &&
          proposalStageDetail.proposal_status[0].project_status ===
          "Proposal accepted" && (
            <UploadMachineBill
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalStageDetail={this.state.proposalStageDetail}
            />
          )}
        {/* {
          this.state.proposalStageDetail && this.state.proposalStageDetail.recci_data && this.state.proposalStageDetail.recci_data.length > 0 &&
          <RecciVisit
            status={this.state.id} proposalStageStatus={this.state.proposalStageStatus}
            proposalStageDetail={this.state.proposalStageDetail} />
        } */}

        {this.state.proposalStageDetail &&
          this.state.proposalStageDetail.estimation_data &&
          this.state.proposalStageDetail.estimation_data.length >= 0 &&
          this.state.currentPriority >= 2 &&
          this.state.proposalByIdData.outlet_type === "Steel Wheel" && (
            <EstimateNeeded
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalByIdData={this.state.proposalByIdData}
              proposalStageDetail={this.state.proposalStageDetail}
            />
          )}

        {/* {
          this.state.proposalStageDetail && this.state.proposalStageDetail.handover_data && this.state.proposalStageDetail.handover_data.length > 0 &&
          <HandOver proposalStageStatus={this.state.proposalStageStatus}
            proposalStageDetail={this.state.proposalStageDetail} ref={this.childHandOver} />
        } */}
      </>
    );
  }
}

export default ProjectsStageAM;
