import React, { Fragment, Component } from 'react';
import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import { formatDate } from '../../utils/utils';

class PerformaInvoice extends Component {
  constructor(props) {
    super(props);

    this.getItems = this.getItems.bind(this);
    this.printDocument = this.printDocument.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this.printDocument();
  }


  getItems() {
    const { location } = this.props;
    const { data = [] } = location;

    return data.map(({ id, item_name: itemName, item_quantity: quantity, amount_per_item: amount }) => {
      return (
        <tr key={id}>
          <td className="subcol-0">{itemName}</td>
          <td className="subcol-1">{quantity}</td>
          <td className="subcol-1">{quantity * amount}</td>
        </tr>
      );
    });
  };

  printDocument() {
    const input = document.getElementById('performa-invoice');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new JsPdf();
        pdf.addImage(imgData, 'JPEG', 10, 10);
        pdf.save('download.pdf');
      }).catch(() => {
        console.log('Not able to download file ');
      });
  }

  goBack() {
    const { history: { goBack } } = this.props;
    if (goBack && typeof goBack === 'function') {
      goBack();
    }
  }

  render() {

    const { location } = this.props;
    const { data = [] } = location;

    if (!data || data.length === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <p>Opps try this again!</p>
        </div>);
    }
    const {
      address,
      city,
      state,
      pin_code: pinCode,
      business_name: businessName,
      owner_name: ownerName,
      telephone_no_1: mobile
    } = data[0];

    return (
      <Fragment>
        <div style={{ left: '10%', position: 'absolute' }}>
          <Button type='button' style={{ zIndex: 1000 }} onClick={this.goBack}>Back</Button>&nbsp;&nbsp;&nbsp;&nbsp;
          <Button color='primary' style={{ zIndex: 1000 }} type='button' onClick={this.printDocument}>Print</Button>
        </div>
        <div style={{ left: '10%', position: 'absolute' }}>
          <div id="performa-invoice" style={{ marginTop: '50px', border: '1px solid #000' }}>
            {data &&
              <table cellSpacing="0" className="main" cellPadding="7">
                <tbody>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Performa Invoice Non-Commercial </th>
                  </tr>
                  <tr style={{ height: '50px' }}>
                    <td style={{ border: '0px' }} colSpan="3" />
                    <td style={{ float: 'right' }}>Date: {formatDate(new Date())}</td>
                  </tr>
                  <tr>
                    <td style={{ border: '0px' }}>Shipper:</td>
                    <td style={{ border: '0px' }} colSpan="3"> JK Advantage 2.0<br /> Support<br />
                      <br /><br /> Lomoso Solution Pvt Ltd<br /><br />
                      H-609, Titanium City Center, 100 Feet Rd, Satellite,<br /> Ahmedabad, Gujarat 380015
                      <br /> <br />
                      GSTN: 24AADCL1179K1ZF

                    </td>
                  </tr>

                  <tr style={{ height: '100px' }}>
                    <td style={{ border: '0px', borderTop: '1px solid black' }}>Consignee:</td>
                    <td colSpan="3" style={{ border: '0px', borderTop: '1px solid black' }}>
                      <p>{businessName}</p>
                      <p>{ownerName}, {mobile && `Mobile: ${mobile}`}</p>
                      <span>{address}</span><br />
                      <span>{city}, {state} - {pinCode}</span>
                    </td>
                  </tr>


                  <tr>
                    <td colSpan="4" style={{ border: '0px', padding: '20px' }}>
                      <table cellSpacing="0" style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <th style={{ width: '400px' }}>DESCRIPTION OF GOODS</th>
                            <th>QUANTITY</th>
                            <th>VALUE</th>
                          </tr>
                          {this.getItems()}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" style={{ padding: '20px' }}> JK Advantage merchandise redemption. Items not for sale.
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="details" style={{ padding: '20px' }}><strong>The above itemsare being
                      dispatched to the Promotions
                      Purpose and not for sale.

                    <br /> Also the above items do not have any commercial value.
                    </strong>
                    </td>
                  </tr>

                </tbody>
              </table >
            }
          </div>
        </div>
      </Fragment>
    );
  }
};

PerformaInvoice.propTypes = {
  location: PropTypes.shape({
    data: PropTypes.shape([])
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
};

export default PerformaInvoice;