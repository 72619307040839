import React, { Component } from 'react';
import {
  Button,
  FormGroup, Label,
  Input,
  Row, Col,
  Card, CardBody
} from 'reactstrap';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import { messages } from '../../constants/constant.message';
const { INVALID_PERSON_NAME, INVALID_ALTERNATE_NO, INVALID_DESIGNATION, INVALID_WHATSAPP_NO } = messages.PROFILE;

class EditPrimaryContactDetail extends Component {

  state = {
    isFirstTime: true
  }

  componentWillReceiveProps(props) {
    const { wa } = props;
    if (this.state.isFirstTime === true && wa.autoFocus === true) {
      this.wa.focus();
      this.props.activeFragment('contact');
      this.setState({ isFirstTime: false });
    }
  }

  handleOnCancel = () => {
    this.props.onCancel('isContactEditActive', false);
  }

  render() {
    const { alPerson, alDesignation, alNumber, wa, sameAsUser, setSameAsUserCheckBoxValue } = this.props;

    return <Card className={this.props.className}>
      <CardBody>
        {this.props.isLoading && <CustomSpinner></CustomSpinner>}
        <Row>
          <Col>
            <b className="margin-right-16">PRIMARY CONTACT DETAILS</b>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="checkbox"
                  id="checkbox"
                  checked={sameAsUser}
                  onChange={setSameAsUserCheckBoxValue}
                />{' '}
                Same as owner
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="alPerson">Contact Person</Label>
              <Input
                type="text"
                name="alPerson"
                id="alPerson"
                value={alPerson.value}
                // value={null}
                disabled={sameAsUser}
                onChange={this.props.handleInputChange}
                onFocus={this.props.activeFragment ? this.props.activeFragment.bind(this, 'contact') : null}
                placeholder="Type here" />
              {alPerson.showErrorMsg && <div className="error">* {INVALID_PERSON_NAME}</div>}
            </FormGroup>
          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="alDesignation">Designation</Label>
              <Input
                type="text"
                name="alDesignation"
                id="alDesignation"
                disabled={sameAsUser}
                value={alDesignation.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {alDesignation.showErrorMsg && <div className="error">* {INVALID_DESIGNATION}</div>}

            </FormGroup>
          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="alNumber">Contact Number</Label>
              <Input
                type="text"
                name="alNumber"
                id="alNumber"
                disabled={sameAsUser}
                value={alNumber.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {alNumber.showErrorMsg && alNumber.invalidPhone && <div className="error">* {INVALID_ALTERNATE_NO}</div>}
            </FormGroup>
          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="wa">Whatsapp Number <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="wa"
                id="wa"
                innerRef={e => this.wa = e}
                value={wa.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {wa.showErrorMsg && <div className="error">* {INVALID_WHATSAPP_NO}</div>}
            </FormGroup>
          </Col>
        </Row>
        {this.props.onSave && <Row>
          <Col xl="12" xs="12" md="12" className="text-align-right" >
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={this.handleOnCancel}>Cancel</Button>
            <Button type="submit" className="mb-1 mr-1 btn-pill action-button profile-save-button" color="warning" disabled={!this.props.isFormValid} onClick={this.props.onSave}>Save</Button>
          </Col>
        </Row>}
      </CardBody>
    </Card >;
  }
}

export default EditPrimaryContactDetail;
