import React, { Component } from "react";
import Ionicon from 'react-ionicons';
import classnames from "classnames";
import {
  Button,
  Table,
  Input,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import { connect } from 'react-redux';
import Sidebar from "react-sidebar";
import lodash from "lodash"; // library added for debounce functionality
import moment from 'moment-timezone';  // import 'moment';
import swal from "sweetalert";
import MappleToolTip from "reactjs-mappletooltip";

// services
import CustomerManagementService from "../../services/CustomerManagementService";
import ModalService from "../../services/ModalService";

// components
import Pagination from "../Pagination/Pagination";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import CustomerManagementFilterNew from "../CustomerManagementFilterNew/CustomerManagementFilterNew";
import AddNewCustomer from "../AddNewCustomer/AddNewCustomer";
import BulkUploadCustomer from "./../BulkUploadCustomers/BulkUploadCutomer";
import AddNewGroup from "../AddNewGroup/AddNewGroup";
import AddToGroup from "../AddToGroup/AddToGroup";

// modals
import SharedReferenceModal from "../../modal/SharedReferenceModal/SharedReferenceModal";
import VehicleDetailModal from "../../modal/VehicleDetailModal/VehicleDetailModal";
import ServiceDetailModal from "../../modal/ServiceDetailModal/ServiceDetailModal";

// utils
import { downlodFile } from "../../utils/utils";
import AddCustomer from "../AddCustomer/AddCustomer";
import OverdueServices from "../OverdueServices/OverdueServices";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import Storage from "../../services/Storage";
import { Permissions } from "../../constants/constant.permissions";

class ListOfCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /**Getting user data from the localstorage */
      userDetail: Storage.getUserDetail(),
      opensidebar: false,
      selected: [],
      selectAll: 0,
      customers: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      isSelectAllEntry: false,
      hideCreateCampaign: false,
      isLoading: false,
      sidebarOpen: false,
      sidebarOpenCustomerManagementFilter: false,
      filterControls: {},
      newGroupSidebarOpen: false,
      isCreateGroup: false,
      BulkUploadCustomer: false,
      search_query: "",
      SelectAll_customer: false,
      phone_numberList: [],
      customerManagementFilterStates: null,
      isSharedReferenceModalOpen: false,
      referenceListModal: null,
      vehicleDetailModalCustomerId: null,
      isVehicleDetailModalOpen: false,
      serviceDetailModalCustomer: null,
      isServiceDetailModalOpen: false,
      actionMenuIndex: -1,
      phone_number: '',
      is_open_visit_history: false,
      is_open_vehcile_detail_list: false,
      customer: null,
      is_permission_create_camp: false
    };
    this.childAddNewCustomer = React.createRef();
    this.childCustomerManagementFilter = React.createRef();
    this.childAddToGroup = React.createRef();
    this.actionContainer = React.createRef();
    this.searchData = lodash.debounce(this.searchData, 500);
    if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.CREATE_CAMPAIGN)) {
      this.state.is_permission_create_camp = true;
    }
  }

  resetState = () => {
    this.setState({
      selected: [],
      selectAll: 0,
      filterApplied: false,
      customers: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      isSelectAllEntry: false,
      isLoading: false,
      sidebarOpen: false,
      sidebarOpenCustomerManagementFilter: false,
      filterControls: {},
      customerManagementFilterStates: null,
    });
  };

  componentDidMount() {
    GoogleAnalticsService.pageView('Customer List View');
    // document.addEventListener("mousedown", this.closeActionMenuWhenClickedOutsideMenu);

    /** Opening the filter from the sidebar if there is redirectDetails and setting the value of the filter to get appropriate customer list*/
    if (this.props.redirectDetails) {
      this.openCustomerManagementFilter(true);
    } else {
      /**Else getting the list of every customer */
      this.getCustomers();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.redirectDetails !== this.props.redirectDetails) {
      if (this.props.redirectDetails) {
        this.setState({
          customerManagementFilterStates: null
        }, () => {
          this.openCustomerManagementFilter(true);
        })
      }
    }
  }

  componentWillUnmount() {
    // document.removeEventListener("mousedown", this.closeActionMenuWhenClickedOutsideMenu);
    document.body.style.overflow = "auto";
  }

  onCloseNewGroupSidebar = (flag = false) => {
    /**Closing the group side bar when clicked on close */
    document.body.style.overflow = "auto";
    if (flag) {
      this.resetState();
      this.getCustomers();
      this.setState({
        phone_numberList: []
      })
    }
    this.setState({ newGroupSidebarOpen: false, isCreateGroup: false });
  };

  openNewGroupSidebar = (flag = false) => {
    /**opening group side bar when clicked on add to group*/
    document.body.style.overflow = "hidden";
    this.setState({ newGroupSidebarOpen: true });
  };

  searchData = (search, page) => {
    /**Getting the list of customers with the user's input feed into search bar */
    this.setState({
      search_query: search
    });
    this.getCustomers();
  };

  handleSearchChange = e => {
    let searchVal = e.target.value;
    this.setState({ page: 1, search: searchVal });
    this.searchData(searchVal, 1);
  };

  onClickBulkUploadCustomer = () => {
    this.setState({
      BulkUploadCustomer: true
    });
  };

  getStripedStyle(index) {
    /**Method to set the alternative style of the list  */
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }

  checkSelectAll = () => {
    let { selected, customers } = this.state;
    let selectAll = 0;
    let perPageCountSelection = 0;
    if (selected.length > 0) {
      selected.forEach(id => {
        let index = customers.findIndex(x => x.customer_id === id);
        if (index > -1) {
          perPageCountSelection++;
        }
      });
      if (perPageCountSelection === customers.length) {
        selectAll = 1;
      } else if (perPageCountSelection === 0) {
        selectAll = 0;
      } else {
        selectAll = 2;
      }
    } else {
      selectAll = 0;
    }
    return selectAll;
  };

  selectAllEntry = isSelectAllEntry => {
    let { selected, customers, customerManagementFilterStates } = this.state;
    if (isSelectAllEntry) {
      if (!customerManagementFilterStates) {
        this.setState({ SelectAll_customer: true });
      }
      this.state.customers.forEach(x => {
        x.active = true;
        if (selected.indexOf(x.customer_id) === -1) {
          selected.push(x.customer_id);
        }
      });
      this.setState(prevState => {
        return {
          ...prevState,
          isSelectAllEntry: isSelectAllEntry,
          selectAll: 1
        };
      });
    } else {
      customers.forEach(x => {
        x.active = false;
      });
      this.setState(prevState => {
        return {
          ...prevState,
          isSelectAllEntry: isSelectAllEntry,
          SelectAll_customer: false,
          selected: [],
          phone_numberList: [],
          selectAll: 0,
          customers: customers
        };
      });
    }

    if (!this.state.isSelectAllEntry && this.state.search_query) {
      this.setState({
        hideCreateCampaign: true
      });
    } else {
      this.setState({
        hideCreateCampaign: false
      });
    }
  };

  toggleRow = (id, phone_number) => {
    /**This method is called when selecting any row from list */
    let { selected, customers, phone_numberList } = this.state;
    let index = selected.findIndex(x => x === id);
    let indexData = customers.findIndex(x => x.customer_id === id);
    if (index > -1) {
      /** If the row is already selected then we remove it from the selected array and change the background to inactive */
      selected.splice(index, 1);
      phone_numberList.splice(index, 1);
      customers[indexData].active = false;
    } else {
      /**the clicked row will get selected and the value will be pushed to selected and change the background color to active */
      if (selected.indexOf(id) === -1) {
        selected.push(id);
        phone_numberList.push(phone_number);
      }
      customers[indexData].active = true;
    }
    let selectAll = this.checkSelectAll();
    this.setState({
      selected,
      selectAll: selectAll,
      customers,
      isSelectAllEntry: false,
      phone_numberList
    });
  };

  toggleSelectAll() {
    /**This method is called when we click on checkbox which selects every row  */
    let { selected, phone_numberList } = this.state;
    if (this.state.selectAll === 0 || this.state.selectAll === 2) {
      //selected = [];

      /**If the values are not selected then we push every customer from list into selected array and change the background color by setting active state */
      this.state.customers.forEach(x => {
        x.active = true;
        if (selected.indexOf(x.customer_id) === -1) {
          selected.push(x.customer_id);
          phone_numberList.push(x.phone_number);
        }
      });
      if (this.state.selectAll === 2) {
        this.state.selectAll = 0;
      }
    } else if (this.state.selectAll === 1) {
      /** if all entries are selected and we empty the selected array and change the background color of every row  */
      if (this.state.isSelectAllEntry) {
        selected = [];
        this.state.customers.forEach(x => {
          x.active = false;
        });
      } else {
        this.state.customers.forEach(x => {
          selected.forEach((element, i) => {
            if (element === x.customer_id) {
              x.active = false;
              selected.splice(i, 1);
              phone_numberList.splice(i, 1);
            }
          });
        });
      }
    }

    this.setState({
      selected: selected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
      isSelectAllEntry: this.state.count <= 10 ? true : false
    });
  }

  isItemSelected = id => {
    /**Method to check or uncheck the row based , if id is present then checked or unchecked */
    let { selected } = this.state;
    let isChecked = false;
    selected.forEach(element => {
      if (id === element) {
        isChecked = true;
      }
    });
    return isChecked;
  };

  getFilterReqData = filter => {
    if (filter && Object.keys(filter).length <= 0) {
      return {};
    }
    // this.setState({
    //   hideCreateCampaign: false
    // });
    let reqData = {};
    /**Creating the required filtered data based on value present to pass as a body to get the appropriate list */
    if (filter && Object.keys(filter).length > 0) {
      if (filter.from_date && filter.from_date.value) {
        let from_date = new Date(filter.from_date.value);
        from_date.setHours(0);
        from_date.setMinutes(0);
        reqData.from_date = from_date;
      }
      if (filter.to_date && filter.to_date.value) {
        let to_date = new Date(filter.to_date.value);
        to_date.setHours(23);
        to_date.setMinutes(59);
        reqData.to_date = to_date;
      }
      if (filter.vehicle_type_id && filter.vehicle_type_id.value) {
        reqData.vehicle_type_id = filter.vehicle_type_id.value;
      }
      if (
        filter.vehicle_type_brand_id &&
        filter.vehicle_type_brand_id.value.brand_id
      ) {
        reqData.vehicle_type_brand_id =
          filter.vehicle_type_brand_id.value.brand_id;
      }
      if (
        filter.vehicle_type_brand_model_id &&
        filter.vehicle_type_brand_model_id.value
      ) {
        reqData.vehicle_type_brand_model_id =
          filter.vehicle_type_brand_model_id.value.model_id;
      }
      if (filter.occasion && filter.occasion.value) {
        reqData.occasion = filter.occasion.value;
      }

      if (filter.occasion_date_from && filter.occasion_date_from.value) {
        let occasion_date_from = new Date(filter.occasion_date_from.value);
        occasion_date_from.setHours(0);
        occasion_date_from.setMinutes(0);
        reqData.occasion_date_from = occasion_date_from;
      }

      if (filter.occasion_date_to && filter.occasion_date_to.value) {
        let date = new Date(filter.occasion_date_to.value);
        date.setHours(23);
        date.setMinutes(59);
        reqData.occasion_date_to = date;
      }
      if (filter.source_of_member && filter.source_of_member.value) {
        reqData.source_of_member = filter.source_of_member.value;
      }
      if (filter.group_id && filter.group_id.value) {
        reqData.group_id = filter.group_id.value;
      }
    }
    return reqData;
  };

  getCustomers = (download_list = false) => {
    //this.setState({ isLoading: true });
    // const reqData = this.getFilterReqData(this.state.filterControls);
    let reqData = {};
    const { customerManagementFilterStates } = this.state;
    /** CustomerManagementFilterStates is filled with the data which we select from the filter  */
    if (customerManagementFilterStates) {
      if (customerManagementFilterStates.filter) {
        /**If present we merge the filter value with reqData object which is passed to get the customers list accordingly */
        reqData = { ...reqData, ...customerManagementFilterStates.filter };
        // if (customerManagementFilterStates.filter.is_hide_upcoming_over_due_tab) {
        //   reqData.due_type = '';
        // }
      }
    }
    reqData.page = this.state.page;
    reqData.pageSize = this.state.pageSize;
    reqData.download_list = download_list;
    reqData.fetch_customer_list = false;
    reqData.search_query = this.state.search ? this.state.search : "";
    this.setState({
      isLoading: true
    });
    CustomerManagementService.getCustomers(reqData)
      .then(res => {
        if (download_list) {
          GoogleAnalticsService.event('Customer List', 'Customer List Download Report');
          downlodFile(res.data.data.file);
          this.setState(prevState => {
            return {
              ...prevState,
              isLoading: false
            };
          });
          return;
        }
        // if (
        //   Object.keys(this.getFilterReqData(this.state.filterControls))
        //     .length !== 0 ||
        //   this.state.search
        // ) {
        //   this.setState({
        //     SelectAll_customer: false
        //   });
        // } else {
        //   this.setState({
        //     SelectAll_customer: true
        //   });
        // }
        // if (
        //   (
        //     customerManagementFilterStates
        //     && customerManagementFilterStates.filter
        //     && Object.keys(customerManagementFilterStates.filter).length > 0
        //   )
        //   || this.state.search
        // ) {
        //   this.setState({
        //     SelectAll_customer: false
        //   });
        // } else {
        //   this.setState({
        //     SelectAll_customer: true
        //   });
        // }

        let { customers, count, selected, selectAll } = this.state;
        if (res.data.data && res.data.data.customers.length > 0) {
          customers = res.data.data.customers;
          count = res.data.data.count;
          const totalPages = Math.ceil(count / this.state.pageSize);

          // this.setState(prevState => {
          //   return {
          //     ...prevState,
          //     customers,
          //     count,
          //     totalPages,
          //     isLoading: false,
          //     selectAll: this.state.isSelectAllEntry ? 0 : selectAll
          //   };
          // });
          this.setState({
            customers,
            count,
            totalPages,
            isLoading: false,
            selectAll: this.state.isSelectAllEntry ? 0 : selectAll
          })
          if (this.state.isSelectAllEntry) {
            this.state.customers.forEach(x => {
              x.active = true;
              if (selected.indexOf(x.customer_id) === -1) {
                selected.push(x.customer_id);
              }
            });
            this.setState(prevState => {
              return {
                ...prevState,
                selectAll: selectAll
              };
            });
          } else {
            setTimeout(() => {
              let selectAll = this.checkSelectAll();
              this.setState(prevState => {
                return {
                  ...prevState,
                  selectAll: selectAll
                };
              });
            }, 1);
          }
        } else {
          customers = [];
          count = 0;
          this.setState(prevState => {
            return {
              ...prevState,
              customers,
              count,
              selectAll: 0,
              isLoading: false
            };
          });
        }
      })
      .catch(e => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  };

  blockCustomer = (customer_id) => {
    GoogleAnalticsService.event('Customer List', 'Block Communication Click');
    this.setState({ actionMenuIndex: -1 })
    let reqData = {};
    // reqData = this.getFilterReqData(this.state.filterControls);
    const { customerManagementFilterStates } = this.state;
    // if (customerManagementFilterStates) {
    //   if (customerManagementFilterStates.filter) {
    //     reqData = { ...reqData, ...customerManagementFilterStates.filter };
    //   }
    // }
    if (customer_id) {
      reqData.customers = [customer_id];
    } else {
      if (customerManagementFilterStates) {
        if (customerManagementFilterStates.filter) {
          reqData = { ...reqData, ...customerManagementFilterStates.filter };
        }
      }
      // reqData = this.getFilterReqData(this.state.filterControls);
      let { selected } = this.state;
      if (this.state.isSelectAllEntry) {
        reqData.customers = [];
      } else {
        reqData.customers = selected;
      }
      if (this.state.search_query) {
        reqData.search_query = this.state.search_query;
      }
    }

    // let { selected } = this.state;
    // if (this.state.isSelectAllEntry) {
    //   reqData.customers = [];
    // } else {
    //   reqData.customers = selected;
    // }
    // if (this.state.search_query) {
    //   reqData.search_query = this.state.search_query;
    // }
    this.setState({ isLoading: true });
    CustomerManagementService.blockCustomers(reqData)
      .then(res => {
        this.setState({ isLoading: false });
        this.resetState();
        if (res.data.message) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => { });
          /**On success reseting the state and filter */
          this.resetState();
          this.applyFilter(null);
          // this.getCustomers();
        }
      })
      .catch(e => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  deleteCustomerConfirm = (customer_id = null) => {
    /**Delete Pop up confirmation */
    GoogleAnalticsService.event('Customer List', 'Customer Delete Click');
    swal({
      title: "Delete Customer",
      text: "Are you sure, you want to delete customer ?",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.setState({ actionMenuIndex: -1 })
        this.deleteCustomer(customer_id);
      }
    });
  };

  deleteCustomer = (customer_id = null) => {
    let reqData = {};
    const { customerManagementFilterStates } = this.state;
    if (customer_id) {
      /**Adding Customer id to the reqData as an array to delete it. */
      reqData.customers = [customer_id];
    } else {
      if (customerManagementFilterStates) {
        if (customerManagementFilterStates.filter) {
          reqData = { ...reqData, ...customerManagementFilterStates.filter };
        }
      }
      // reqData = this.getFilterReqData(this.state.filterControls);
      let { selected } = this.state;
      if (this.state.isSelectAllEntry) {
        reqData.customers = [];
      } else {
        reqData.customers = selected;
      }
      if (this.state.search_query) {
        reqData.search_query = this.state.search_query;
      }
    }
    this.setState({ isLoading: true });
    CustomerManagementService.deleteCustomer(reqData)
      .then(res => {
        this.setState({ isLoading: false });
        this.resetState();
        if (res.data.message) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => { });
          /**On Successfully delete , reseting the state and filter and getting the updated customer list */
          this.resetState();
          this.resetFilter();
          this.getCustomers();
        }
      })
      .catch(e => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  handlePageChange = page => {
    /**Method called when we change the page from pagination */

    // const { currentUser } = this.state
    // const uuid = currentUser && currentUser.uuid ? currentUser.uuid : null;
    this.setState({ page: page });
    this.setState(prevState => {
      return {
        ...prevState,
        page: page
      };
    });
    setTimeout(() => {
      this.getCustomers();
    }, 1);
  };

  handleInputChange = e => {
    /**Method called to change the Rows per page value */
    let { pageSize } = this.state;
    pageSize = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        pageSize: pageSize,
        page: 1
      };
    });
    setTimeout(() => {
      this.getCustomers();
    }, 1);
  };

  openAddEditCustomer = (data = {}) => {
    document.body.style.overflow = "hidden";
    this.setState(prevState => {
      return {
        ...prevState,
        sidebarOpen: true
      };
    });

    if (data && Object.keys(data).length > 0) {
      setTimeout(() => {
        if (this.childAddNewCustomer.current) {
          this.childAddNewCustomer.current.setEditData(data);
        }
      }, 500);
    }
  };

  onCloseSidebar = (flag = false) => {
    document.body.style.overflow = "auto";
    if (flag) {
      this.setState({
        selected: [],
        selectAll: 0,
        customers: [],
        count: 0,
        page: 1,
        pageSize: 10,
        totalPages: 0,
        isSelectAllEntry: false
      });
    }
    this.getCustomers();
    this.setState(prevState => {
      return { ...prevState, sidebarOpen: false };
    });
  };

  openCustomerManagementFilter = (is_open_from_dashboard = false) => {
    document.body.style.overflow = "hidden";
    GoogleAnalticsService.event('Customer List', 'Customer Filter View');
    this.setState(prevState => {
      return {
        ...prevState,
        sidebarOpenCustomerManagementFilter: true
      };
    }, () => {
    });

    // if (
    //   this.state.filterControls &&
    //   Object.keys(this.state.filterControls).length > 0
    // ) {
    //   if (this.childCustomerManagementFilter.current) {
    //     //this.childCustomerManagementFilter.current.setFilterData(this.state.filterControls);
    //   }
    // }
  };
  onCloseSidebarCustomerManagementFilter = () => {
    document.body.style.overflow = "auto";
    this.setState(prevState => {
      return { ...prevState, sidebarOpenCustomerManagementFilter: false };
    });
  };

  onCloseSidebarBulkUpload = () => {
    this.setState(prevState => {
      return { ...prevState, BulkUploadCustomer: false };
    });
    this.getCustomers();
  };

  applyFilter = filterStates => {
    this.setState({
      filterApplied: filterStates ? true : false,
      selected: [],
      selectAll: 0,
      customers: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      isSelectAllEntry: false,
      customerManagementFilterStates: filterStates
    }, () => {
      this.getCustomers();
    });
    this.onCloseSidebarCustomerManagementFilter();
  };

  resetFilter = () => {
    this.setState({
      filterControls: {},
      filterApplied: false
    });
    setTimeout(() => {
      this.getCustomers();
    }, 1);
    this.onCloseSidebarCustomerManagementFilter();
  };

  createGroup = (flag, group_name = "") => {
    if (!flag && group_name) {
      this.childAddToGroup.current.addToGroup(group_name);
    } else {
      if (flag) {
        GoogleAnalticsService.event('Customer List', 'Customer List Add Group Click');
      }
      this.setState({
        isCreateGroup: flag
      });
    }
  };

  //  =====================Open Create Campaign =======================

  openCreateCampaign = () => {
    let filter = {};
    const { customerManagementFilterStates } = this.state;
    if (customerManagementFilterStates) {
      if (customerManagementFilterStates.filter) {
        /**Merging the filter states with customerManagementFilterStates and passing to createCampaign page  as 'CampaignDetailData'  */
        filter = { ...filter, ...customerManagementFilterStates.filter };
      }
    }
    // if (
    //   this.state.filterControls &&
    //   this.state.filterControls.filter_type &&
    //   this.state.filterControls.filter_type.value
    // ) {
    //   filter[
    //     "custom_filter_type"
    //   ] = this.state.filterControls.filter_type.value;
    // }
    if (filter.hasOwnProperty('filter_type')) {
      filter["custom_filter_type"] = filter['filter_type'];
    }
    this.props.history.push({
      pathname: "/campaign/create",
      filterStates: customerManagementFilterStates,
      DataComingFromLOC: true,
      selectedCustomers: this.state.phone_numberList,
      CampaignDetailData: filter ? filter : null,
      isSelectAllEntry: this.state.isSelectAllEntry,
      selectedAll_length: !this.state.isSelectAllEntry
        ? this.state.selected.length
        : this.state.count,
      SelectAll_customer: this.state.SelectAll_customer
    });
    //selectedCustomers: this.state.phone_numberList && this.state.phone_numberList.length > 0 ? lodash.uniq(this.state.phone_numberList) : [],
    GoogleAnalticsService.event('Customer List', 'Create Campaign Click');
  };

  OpenActionMenu = (index) => {
    this.setState({ actionMenuIndex: index })
  }
  generateServiceText = (lastVisitService) => {
    let c = null;
    if (lastVisitService && Array.isArray(lastVisitService) && lastVisitService.length > 0) {
      if (lastVisitService.length === 1) {
        c = <span className="sub-value-table-service d-flex">({lastVisitService[0]})</span>
      } else {
        c = <span className="sub-value-table-service d-flex" id="customer-list-tooltip">({lastVisitService[0]})
          <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={this.generateServiceToolTip(lastVisitService)} >
            <span className="sub-value-more cursor-pointer">+{lastVisitService.length - 1}</span>
          </span>
          {/* <span className="sub-value-more cursor-pointer" title={this.generateServiceToolTip(lastVisitService)}>+{lastVisitService.length - 1}</span> */}
        </span>
      }
    }
    return c;
  }

  generateGroupToolTip = (groups) => {
    let groupString = '';
    if (groups && Array.isArray(groups) && groups.length > 0) {
      for (let i = 0; i < groups.length; i++) {
        if (i === 0) {
          groupString += groups[i].group_name;
        } else {
          groupString += `\n${groups[i].group_name}`
        }
      }
    }
    return groupString;
  }

  generateServiceToolTip = (services) => {
    let serviceString = '';
    if (services && Array.isArray(services) && services.length > 0) {
      for (let i = 0; i < services.length; i++) {
        if (i === 0) {
          serviceString += services[i];
        } else {
          serviceString += `\n${services[i]}`
        }
      }
    }
    return serviceString;
  }


  closeActionMenuWhenClickedOutsideMenu = event => {

    if (this.actionContainer.current && this.actionContainer.current.contains(event.target)) {

    } else {
      this.setState({ actionMenuIndex: -1 })
    }
  };

  openSharedReferenceModal = (referenceListModal) => {
    this.setState({ isSharedReferenceModalOpen: true, referenceListModal });
  }

  closeSharedReferenceModal = () => {
    this.setState({ isSharedReferenceModalOpen: false, referenceListModal: null });
  }

  openVehicleDetailModal = (customerId, customer) => {
    this.setState({ isVehicleDetailModalOpen: true, vehicleDetailModalCustomerId: customerId, customer: customer });
  }

  closeVehicleDetailModal = () => {
    this.setState({ isVehicleDetailModalOpen: false, vehicleDetailModalCustomerId: null });
  }

  openServiceDetailModal = (row) => {
    this.setState({ isServiceDetailModalOpen: true, serviceDetailModalCustomer: row });
  }


  closeServiceDetailModal = () => {
    this.setState({ isServiceDetailModalOpen: false, serviceDetailModalCustomer: null, customer: null });
  }

  viewHistory = () => {
    this.setState({
      isServiceDetailModalOpen: false,
      phone_number: this.state.serviceDetailModalCustomer.phone_number,
      opensidebar: true,
      customer_id: this.state.serviceDetailModalCustomer.customer_id,
      is_open_visit_history: true
    });
  }
  viewVehicleDetail = () => {
    this.setState({
      isVehicleDetailModalOpen: false,
      phone_number: this.state.customer.phone_number,
      opensidebar: true,
      customer_id: this.state.customer.customer_id,
      is_open_vehcile_detail_list: true
    });
  }
  closeAddCustomer = (obj) => {
    if (obj && Object.keys(obj).length > 0 && obj.is_customer_list) {
      this.getCustomers(false);
    } else if (obj && Object.keys(obj).length > 0 && obj.is_close_visit_history) {
      this.openServiceDetailModal(this.state.serviceDetailModalCustomer)
    } else if (obj && Object.keys(obj).length > 0 && obj.is_close_vehcile_detail_list) {
      this.openVehicleDetailModal(this.state.vehicleDetailModalCustomerId, this.state.customer)
    }
    this.setState({
      opensidebar: false,
      is_open_visit_history: false,
      is_open_vehcile_detail_list: false
    });
  };

  openAddCustomer = (row) => {
    this.setState({
      opensidebar: true,
      phone_number: row.phone_number
    });
  };

  openOverSueService = (row) => {
    if (row.over_due_count && row.over_due_count > 0) {
      let reqData = {
        customer_id: row.customer_id,
        ...this.state.customerManagementFilterStates
      }
      this.refs.refOverdueServices.toggle(reqData);
    }
  }


  render() {
    const {
      count,
      selected,
      totalPages,
      // isSelectAllEntry,
      pageSize,
      search,
      customerManagementFilterStates,
      referenceListModal,
      vehicleDetailModalCustomerId,
      serviceDetailModalCustomer,
      actionMenuIndex,
      is_permission_create_camp
    } = this.state;
    const mappleConfig = {
      direction: "left",
      backgroundColor: "#f1f4f7",
      float: false
    };

    return (
      <div
        onMouseDown={this.closeActionMenuWhenClickedOutsideMenu}
      >
        <OverdueServices
          ref="refOverdueServices"
          handleOnCancel={this.handleOnCancel}
          from=""
        />

        {this.state.isSharedReferenceModalOpen && <SharedReferenceModal
          show={this.state.isSharedReferenceModalOpen}
          closeModal={this.closeSharedReferenceModal}
          data={referenceListModal}
        >
        </SharedReferenceModal>}

        {this.state.isVehicleDetailModalOpen && <VehicleDetailModal
          show={this.state.isVehicleDetailModalOpen}
          closeModal={this.closeVehicleDetailModal}
          data={vehicleDetailModalCustomerId}
          viewVehicleDetail={this.viewVehicleDetail}
        >
        </VehicleDetailModal>}


        {this.state.isServiceDetailModalOpen && <ServiceDetailModal
          show={this.state.isServiceDetailModalOpen}
          closeModal={this.closeServiceDetailModal}
          viewHistory={this.viewHistory}
          data={serviceDetailModalCustomer}
        >
        </ServiceDetailModal>}
        <>
          {this.state.opensidebar && (
            <Sidebar
              sidebar={
                <>
                  <AddCustomer
                    ref={this.childCustomiseDetail}
                    closeAddCustomer={this.closeAddCustomer}
                    phone_number={this.state.phone_number}
                    serviceDetailModalCustomer={this.state.serviceDetailModalCustomer}
                    customer={this.state.customer}
                    is_open_visit_history={this.state.is_open_visit_history}
                    is_open_vehcile_detail_list={this.state.is_open_vehcile_detail_list}
                  />

                </>
              }
              open={this.state.opensidebar}
              sidebarClassName="campaign-drawer"
              pullRight
              styles={{
                sidebar: {
                  background: "white",
                  height: this.state.height ? this.state.height : "90%",
                  top: "60px",
                  width: "47%",
                  borderRadius: "2px",
                },
              }}
            />
          )}
        </>


        {this.state.newGroupSidebarOpen && (
          <Sidebar
            sidebar={
              <>
                <div
                  className={!this.state.isCreateGroup ? "display-hidden" : ""}
                  style={{ height: "100%" }}
                >
                  <AddNewGroup
                    ref={this.childAddNewGroup}
                    filterControls={this.getFilterReqData(
                      this.state.filterControls
                    )}
                    isSelectAllEntry={this.state.isSelectAllEntry}
                    selectedCustomers={this.state.selected}
                    createGroup={this.createGroup}
                    from="add-to-group"
                    onCloseSidebar={this.onCloseNewGroupSidebar}
                  />
                </div>
                <div
                  className={this.state.isCreateGroup ? "display-hidden" : ""}
                  style={{ height: "100%" }}
                >
                  <AddToGroup
                    ref={this.childAddToGroup}
                    filterControls={
                      customerManagementFilterStates
                        && customerManagementFilterStates.filter
                        ? customerManagementFilterStates.filter
                        : null
                    }
                    selectedCustomers={this.state.selected}
                    isSelectAllEntry={this.state.isSelectAllEntry}
                    createGroup={this.createGroup}
                    search_query={this.state.search_query}
                    onCloseSidebar={this.onCloseNewGroupSidebar}
                  />
                </div>
              </>
            }
            open={this.state.newGroupSidebarOpen}
            sidebarClassName="add-to-group-modal"
            pullRight
            styles={{
              sidebar: { background: "white", height: "90%", top: "60px" }
            }}
            children={<span></span>}
          />
        )}

        {/* Sidebar Add Edit Customer */}
        {this.state.sidebarOpen && (
          <Sidebar
            sidebar={
              <>
                <AddNewCustomer
                  ref={this.childAddNewCustomer}
                  onCloseSidebar={this.onCloseSidebar}
                />
              </>
            }
            open={this.state.sidebarOpen}
            sidebarClassName="subsdiaries-sidebar"
            pullRight
            styles={{
              sidebar: {
                background: "white",
                height: "100%",
                top: "60px",
                overflowY: "auto"
              }
            }}
          />
        )}
        { /**Open Bulk Customer Upload in sidebar */}
        {this.state.BulkUploadCustomer && (
          <Sidebar
            sidebar={
              <>
                <BulkUploadCustomer
                  onCloseSidebar={this.onCloseSidebarBulkUpload}
                />
              </>
            }
            open={this.state.BulkUploadCustomer}
            sidebarClassName="customer-management-filter"
            pullRight
            styles={{
              sidebar: { background: "white", height: "90%", top: "60px", overflowY: 'hidden' }
            }}
          />
        )}

        {/* {this.state.sidebarOpenCustomerManagementFilter && (
          <Sidebar
            sidebar={
              <>
                <CustomerManagementFilter
                  ref={this.childCustomerManagementFilter}
                  applyFilter={this.applyFilter}
                  resetFilter={this.resetFilter}
                  onCloseSidebar={this.onCloseSidebarCustomerManagementFilter}
                  getFilterControls={this.state.filterControls}
                  onCloseSidebar={this.onCloseSidebarCustomerManagementFilter}
                />
              </>
            }
            open={this.state.sidebarOpenCustomerManagementFilter}
            sidebarClassName="customer-management-filter"
            pullRight
            styles={{
              sidebar: { background: "white", height: "90%", top: "60px" }
            }}
          />
        )} */}


        {this.state.sidebarOpenCustomerManagementFilter && (
          <Sidebar
            sidebar={
              <>
                <CustomerManagementFilterNew
                  ref={this.childCustomerManagementFilter}
                  applyFilter={this.applyFilter}
                  resetFilter={this.resetFilter}
                  onCloseSidebar={this.onCloseSidebarCustomerManagementFilter}
                  // getFilterControls={this.state.filterControls}
                  filter={lodash.cloneDeep(customerManagementFilterStates)}
                />
              </>
            }
            open={this.state.sidebarOpenCustomerManagementFilter}
            sidebarClassName="customer-management-filter"
            pullRight
            styles={{
              sidebar: { background: "white", height: "90%", top: "60px", overflowY: 'auto !important' }
            }}
          />
        )}

        {this.state.isLoading && <CustomSpinner />}
        {/* <hr style={{ border: "solid 1px #e0e2e4" }} /> */}
        {/* <Row style={{ justifyContent: 'flex-end', marginRight: '0', position: 'relative' }}>
          <div onClick={() => { this.openAddEditCustomer({}) }}>
            <span className="link-warm-blue">
              Add New Customer
          </span>
          </div>&nbsp;&nbsp;
          <div className="f-flex-row">
            <img src="images/download.svg" alt='' className="download-report-icon margin-left-10" />&nbsp;&nbsp;
            <span className="link-warm-blue">
              Download List
          </span>
          </div>
        </Row> */}
        <br />
        <Card className="customer-mg-card">
          <CardBody>
            <Row
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "1%",
                justifyContent: "space-between"
              }}
            >
              <div className="d-flex-row">
                <div
                  style={{ width: "301px" }}
                  className="d-flex-row search-input"
                >
                  <Input
                    placeholder="Search by Name, Mobile No, Vehicle type"
                    className="login-input"
                    name="search"
                    onChange={this.handleSearchChange}
                    value={search}
                  ></Input>
                  <img
                    src="images/search.svg"
                    alt=''
                    className="icon-width"
                    style={{ marginLeft: "-25px" }}
                  />
                </div>

                <div className="d-flex-row" style={{ marginLeft: "10px" }}>
                  {/* {this.state.filterApplied === true && (
                    <img
                      src="images/smaller-blue-dot-hi.png"
                       alt=''
                      className="apply-filter-dot"
                    />
                  )} */}
                  <img
                    src={this.state.filterApplied === true ? "images/smaller-blue-dot-hi.png" : "images/filter.svg"}
                    className={`cursor-pointer ${this.state.filterApplied === true
                      ? 'filter-icon-selected'
                      : 'filter-icon'}`
                    }
                    onClick={() => this.openCustomerManagementFilter()}
                    alt=''
                  />
                </div>
                {selected.length > 0 ? (
                  <div className="entries-selected">
                    {totalPages > 1 ? (
                      <div>
                        <span className="color-757575">
                          {!this.state.isSelectAllEntry
                            ? selected.length
                            : count}{" "}
                          {selected.length > 1 ? "Entries" : "Entry"} Selected.
                        </span>
                        {this.state.pageSize < count &&
                          this.state.selectAll === 1 && (
                            <>
                              {!this.state.isSelectAllEntry ? (
                                <span onClick={() => this.selectAllEntry(true)}>
                                  Select All {count} Entries.
                                </span>
                              ) : (
                                <span
                                  onClick={() => this.selectAllEntry(false)}
                                >
                                  Clear Selection.
                                </span>
                              )}
                            </>
                          )}
                      </div>
                    ) : (
                      <div>
                        <span className="color-757575">
                          {!this.state.isSelectAllEntry
                            ? selected.length
                            : count}{" "}
                          {selected.length > 1 ? "entries" : "entry"} selected.
                        </span>
                        {this.state.pageSize < count &&
                          this.state.selectAll === 1 && (
                            <>
                              {!this.state.isSelectAllEntry ? (
                                <span onClick={() => this.selectAllEntry(true)}>
                                  Select all {count} entries.
                                </span>
                              ) : (
                                <span
                                  onClick={() => this.selectAllEntry(false)}
                                >
                                  Clear Selection.
                                </span>
                              )}
                            </>
                          )}
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {selected && selected.length > 0 ? (
                <div>

                  {/* &nbsp;&nbsp;<Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark">Send SMS</Button> */}
                  <Button
                    onClick={() => this.deleteCustomerConfirm()}
                    className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                    color="dark"
                    style={{ width: "100px" }}
                  >
                    Delete
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => this.blockCustomer()}
                    className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                    color="dark"
                    style={{ width: "auto" }}
                  >
                    Block Communication
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => this.openNewGroupSidebar()}
                    className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                    color="dark"
                    style={{ width: "auto" }}
                  >
                    Add to Group
                  </Button>
                  {/* &nbsp;&nbsp;<Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark">Send SMS</Button> */}
                  {(!this.state.hideCreateCampaign && is_permission_create_camp) && (
                    <>
                      &nbsp;&nbsp;
                      <Button
                        className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                        color="warning"
                        onClick={this.openCreateCampaign}
                        type="submit"
                      >
                        Create Campaign
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <Row style={{ marginRight: 0 }}>
                  {/* <div>
                      <div className="f-flex-row">
                        <img
                          src="images/plus_blue.svg"
                           alt=''
                          onClick={() => {
                            this.openAddEditCustomer({});
                          }}
                          className="download-report-icon cursor-pointer margin-left-10"
                        />
                        &nbsp;&nbsp;
                      <span
                          className="link-warm-blue cursor-pointer"
                          onClick={() => {
                            this.openAddEditCustomer({});
                          }}
                        >
                          Add New Customer
                      </span>
                      </div>
                    </div>
                  &nbsp;&nbsp; */}
                  <div>
                    <div className="f-flex-row">
                      <img
                        src="images/download.svg"
                        alt=''
                        onClick={() => {
                          this.onClickBulkUploadCustomer({});
                        }}
                        className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                      />
                      &nbsp;&nbsp;
                      <span
                        className="link-warm-blue cursor-pointer "
                        onClick={() => {
                          this.onClickBulkUploadCustomer({});
                        }}
                      >
                        Bulk Upload Customer
                      </span>
                    </div>
                  </div>
                  &nbsp;&nbsp;
                  {this.state.count > 0 && (
                    <div className="f-flex-row">
                      <img
                        src="images/download.svg"
                        alt=''
                        onClick={() => this.getCustomers(true)}
                        className="download-report-icon cursor-pointer margin-left-10"
                      />
                      &nbsp;&nbsp;
                      <span
                        className="link-warm-blue cursor-pointer"
                        onClick={() => this.getCustomers(true)}
                      >
                        Download List
                      </span>
                    </div>
                  )}
                  &nbsp;&nbsp;
                  <MappleToolTip {...mappleConfig}>
                    {/* <div className="f-flex-row">
            <img src="images/info1.svg" alt='' onClick={this.getHelp} className="download-report-icon cursor-pointer margin-left-10" />&nbsp;&nbsp;
            <span className="link-warm-blue cursor-pointer" onClick={this.getHelp}>
              Help Guide
          </span> 
          </div> */}
                    <Row className="otherlangcontent-wrapps">
                      <Col lg="10" sm="12" md="10" xs="12">
                        <div
                          className="otherlangcontent"
                          style={{
                            width: "100%",
                            color: "black",
                            textAlign: "left",
                            fontSize: "12px"
                          }}
                        >
                          Currently there are no customers registered with your
                          store. Use ‘OPTIN’ facility, Bulk Upload features to
                          add customers to your account.
                        </div>
                      </Col>
                    </Row>
                  </MappleToolTip>
                </Row>
              )}
            </Row>
            <div>
              <Table className="mb-0 table-responsive tbl-about tbl-about text-align-left">
                <thead>
                  <tr
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="customer-list-table-header"
                  >
                    <th className="border-1-5">
                      {count > 0 && (
                        <>
                          {this.state.selectAll === 2 ? (
                            <img
                              onClick={e => this.toggleSelectAll()}
                              src="images/group-16.svg"
                              alt=''
                              style={{ height: "18px" }}
                            />
                          ) : this.state.selectAll === 1 ? (
                            <label className="custom-checkboax1--container">
                              <input
                                type="checkbox"
                                name="completed"
                                checked={true}
                                onChange={e => this.toggleSelectAll()}
                              />
                              <span className="checkmark1" />
                            </label>
                          ) : (
                            <label className="custom-checkboax1--container">
                              <input
                                type="checkbox"
                                name="completed"
                                checked={false}
                                onChange={e => this.toggleSelectAll()}
                              />
                              <span className="checkmark1" />
                            </label>
                          )}
                        </>
                      )}
                    </th>
                    <th
                      className="text-align-left border-1-5 customer-list-th"
                      style={{ width: "145px" }}
                    >
                      Customer Name
                    </th>
                    {/* <th className="text-align-left border-1-5 customer-list-th">Mobile No.</th> */}
                    <th className="text-align-left border-1-5 customer-list-th" style={{ width: '73px' }}>Source</th>
                    {/* <th className="text-align-left border-1-5">DND Status</th> */}
                    <th className="text-align-left border-1-5 customer-list-th" style={{ width: '85px' }}>Reg. From</th>
                    <th className="text-align-left border-1-5 customer-list-th" style={{ width: '109px' }}>Tyre Purchase</th>
                    <th className="text-align-left border-1-5 customer-list-th" style={{ width: '71px' }}>Vehicles</th>
                    <th className="text-align-left border-1-5 customer-list-th" style={{ width: '73px' }}
                      id="customer-list-tooltip">
                      <div className="d-flex-row">
                        <span>Services</span>
                        <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={"The list of services taken by \n customers during a few last visits."} >
                          <img className="mt-auto mb-auto ml-1 cursor-pointer" src="/images/info_white_background.svg"
                            style={{ height: '15px' }} alt='' />
                          {/* <span className="question-mark ml-1 mt-auto mb-auto cursor-pointer">	&#63;</span> */}
                        </span>
                      </div>
                    </th>
                    <th className="text-align-left border-1-5 customer-list-th" style={{ width: '150px' }}
                      id="customer-list-tooltip">
                      <div className="d-flex-row text-align-center">
                        <span>
                          Overdue Service /
                          <br />
                          Tyre purchase
                        </span>
                        <span className="cursor-pointer tool-tip mt-auto mb-auto" data-tooltip-position="top"
                          data-tooltip={"The list of services/tyre purchase \ndue and for which customer needed\n to visit your store in the past\n however they did not visit."} >
                          {/* <span className="question-mark ml-1 mt-auto mb-auto cursor-pointer">	&#63;</span> */}
                          <img className="mt-auto mb-auto ml-1 cursor-pointer" src="/images/info_white_background.svg"
                            style={{ height: '15px' }} alt='' />
                        </span>
                      </div>
                    </th>
                    <th className="text-align-left border-1-5 customer-list-th" style={{ width: '68px' }}>Groups</th>
                    <th className="text-align-left border-1-5 customer-list-th text-align-center" style={{ width: '86px' }}>Ref. Count</th>
                    <th className="text-align-left border-1-5 customer-list-th" style={{ width: '100px' }}
                      id="customer-list-tooltip">
                      <div className="d-flex-row">
                        <span>
                          Upcoming Appointment</span>
                        <span className="cursor-pointer tool-tip mt-auto mb-auto" data-tooltip-position="top" data-tooltip={"The date when the next visit is due \nfor this customer along with purpose \nsuch as service or tyre purchase."} >
                          {/* <span className="question-mark ml-1 mt-auto mb-auto cursor-pointer">	&#63;</span> */}
                          <img className="mt-auto mb-auto ml-1 cursor-pointer" src="/images/info_white_background.svg"
                            style={{ height: '15px' }} alt='' />
                        </span>

                      </div>
                    </th>
                    <th className="text-align-left border-1-5 customer-list-th" id="customer-list-tooltip">
                      <div className="d-flex-row">
                        <span>
                          Last Visit
                        </span>
                        <span className="cursor-pointer tool-tip mt-auto mb-auto" data-tooltip-position="top" data-tooltip={"The date on which the customer \nvisited you last. It also lists the \nservices customer availed during \nthat visit"} >
                          {/* <span className="question-mark ml-1 mt-auto mb-auto cursor-pointer">	&#63;</span> */}
                          <img className="mt-auto mb-auto ml-1 cursor-pointer" src="/images/info_white_background.svg"
                            style={{ height: '15px' }} alt='' />
                        </span>
                      </div>
                    </th>
                    <th className="text-align-left border-1-5 customer-list-th customer-list-action-th">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.customers.map((row, i) => (
                    <tr
                      className={classnames(
                        { "active-tbl-row": row.active },
                        "customer-list-row"
                      )}
                    >
                      <td className="border-1-5">
                        <label className="custom-checkboax1--container">
                          <input
                            type="checkbox"
                            name="main_road"
                            checked={this.isItemSelected(row.customer_id)}
                            onChange={() =>
                              this.toggleRow(row.customer_id, row.phone_number)
                            }
                          />
                          <span className="checkmark1" />
                        </label>
                      </td>
                      <td className="text-align-left border-1-5 customer-list-td">
                        <div className="d-flex-row">
                          <div className="d-flex-column">
                            <div><span className="custom-anchor-tag"
                              onClick={() => this.openAddCustomer(row)} title="Edit customer detail">{row.customer_name !== " " && row.customer_name
                                ? row.customer_name
                                : "No Name"}</span></div>
                            <div className="margin-top-5">
                              {row.phone_number}
                            </div>
                          </div>
                          {/**Adding the block image if the row has 'is_block' value as true */}
                          {
                            row.is_block &&
                            <img className="ml-2" src="/images/block-light-gray.svg" alt='' />
                          }
                        </div>
                      </td>
                      {/* <td className="text-align-left border-1-5 customer-list-td">
                        {row.phone_number}
                      </td> */}
                      <td className="text-align-left border-1-5 customer-list-td">
                        {row.added_type}
                      </td>
                      {/* <td className="text-align-left border-1-5">
                        {row.is_optin
                          ? "Optin"
                          : row.dnd_status
                            ? row.dnd_status
                            : "NO-STATUS"}
                      </td> */}
                      <td className="text-align-left customer-list-td">
                        {/* {formatDate(row.created_at)} */}
                        {moment.tz(row.created_at, 'Asia/Kolakata').format('DD MMM\'YY')}
                        {/* <Moment tz="Asia/Kolakata" format="DD MMM'YY">{row.created_at}</Moment> */}
                      </td>
                      <td className="border-1-5 text-align-center customer-list-td">
                        {row.is_tyre_purchase ? 'Yes' : 'No'}
                      </td>
                      <td className="border-1-5 text-align-center customer-list-td">
                        <span
                          className={
                            row.vehicle_detail &&
                              Array.isArray(row.vehicle_detail) &&
                              row.vehicle_detail.length > 0 ? "custom-anchor-tag" : ""
                          }
                          onClick={
                            row.vehicle_detail &&
                              Array.isArray(row.vehicle_detail) &&
                              row.vehicle_detail.length > 0 ?
                              this.openVehicleDetailModal.bind(this, row.customer_id, row) :
                              null
                          }
                        >
                          {
                            row.vehicle_detail &&
                              Array.isArray(row.vehicle_detail) &&
                              row.vehicle_detail.length > 0 ?
                              row.vehicle_detail.length : 0
                          }
                        </span>
                      </td>
                      <td className="border-1-5 text-align-center customer-list-td">
                        <span
                          className={
                            row.service_name &&
                              Array.isArray(row.service_name) &&
                              row.service_name.length > 0 ? "custom-anchor-tag" : ""
                          }
                          onClick={
                            row.service_name &&
                              Array.isArray(row.service_name) &&
                              row.service_name.length > 0 ?
                              this.openServiceDetailModal.bind(this, row) :
                              null
                          }
                        >
                          {
                            row.service_name &&
                              Array.isArray(row.service_name) &&
                              row.service_name.length > 0 ?
                              row.service_name.length : 0
                          }
                        </span>
                      </td>
                      <td className="border-1-5 text-align-center border-1-5 customer-list-td">
                        <span
                          className={
                            row.over_due_count ? "custom-anchor-tag" : ""
                          }
                          onClick={() => this.openOverSueService(row)}>
                          {row.over_due_count}
                        </span>
                      </td>
                      <td className="border-1-5 text-align-center border-1-5 customer-list-td"
                        id={row.member_of_group &&
                          Array.isArray(row.member_of_group) &&
                          row.member_of_group.length > 0 ? 'customer-list-tooltip' : ''}>
                        <span className={
                          row.member_of_group &&
                            Array.isArray(row.member_of_group) &&
                            row.member_of_group.length > 0 ? "group-count" : ""
                        }
                        >
                          <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={`${this.generateGroupToolTip(row.member_of_group)}`} >
                            <span className="sub-value-more cursor-pointer">
                              {
                                row.member_of_group &&
                                  Array.isArray(row.member_of_group) &&
                                  row.member_of_group.length > 0 ?
                                  row.member_of_group.length : 0
                              }
                            </span>
                          </span>
                        </span>
                        {/* {row.member_of_group.map(
                          (group, j) =>
                            j < 2 && (
                              <span className="group-chip">
                                {group.group_name}
                              </span>
                            )
                        )}
                        {row.member_of_group.length > 2 ? (
                          <span
                            className="group_count"
                            data-tip
                            data-for={"tooltip" + i}
                          >
                            +{row.member_of_group.length - 2}
                          </span>
                        ) : (
                            ""
                          )}
                        {row.member_of_group.length > 2 && (
                          <ReactTooltip
                            offset="left"
                            id={"tooltip" + i}
                            aria-haspopup="true"
                            role="example"
                          >
                            <ul
                              style={{
                                textAlign: "left",
                                listStyleType: "none",
                                marginLeft: "-53px"
                              }}
                            >
                              {row.member_of_group.map(
                                (group, j) =>
                                  j > 1 && <li>{group.group_name}</li>
                              )}
                            </ul>
                          </ReactTooltip>
                        )}
                        {!row.member_of_group ||
                          (row.member_of_group.length <= 0 && (
                            <span className="no-data-hypen"></span>
                          ))} */}
                      </td>
                      <td className="border-1-5 text-align-center customer-list-td">
                        <span
                          className={
                            row.refer_customer_detail &&
                              Array.isArray(row.refer_customer_detail) &&
                              row.refer_customer_detail.length > 0 ? "custom-anchor-tag" : ""
                          }
                          onClick={
                            row.refer_customer_detail &&
                              Array.isArray(row.refer_customer_detail) &&
                              row.refer_customer_detail.length > 0 ?
                              this.openSharedReferenceModal.bind(this, row.refer_customer_detail) :
                              null
                          }
                        >
                          {
                            row.refer_customer_detail &&
                              Array.isArray(row.refer_customer_detail) &&
                              row.refer_customer_detail.length > 0 ?
                              row.refer_customer_detail.length : 0
                          }
                        </span>
                      </td>
                      <td className="border-1-5 text-align-left customer-list-td">
                        {row.upcoming_due_date ? <>
                          <div>{row.upcoming_due_date && moment.tz(row.upcoming_due_date, 'Asia/Kolakata').format('DD MMM\'YY')}</div>
                          <div className="sub-value-table d-flex flex-wrap">
                            <span className="sub-value-table-purpose">{row.upcoming_purpose_name}</span>{" "}
                            <span className="sub-value-table-service">
                              {this.generateServiceText(row.upcoming_service_name)}

                              {/* ({row.upcoming_service_name}) */}
                            </span>
                          </div>
                        </> : '--'}
                      </td>
                      <td className="border-1-5 text-align-left customer-list-td">
                        {row.last_visit_date ? <>
                          <div>{row.last_visit_date && moment.tz(row.last_visit_date, 'Asia/Kolakata').format('DD MMM\'YY')}</div>
                          <div className="sub-value-table d-flex-row flex-wrap">
                            <span className="sub-value-table-purpose">{row.last_purpose_name}</span>{" "}
                            {this.generateServiceText(row.last_purpose_service_name)}
                          </div>
                        </> : '--'}
                      </td>
                      <td className="border-1-5 text-align-center customer-list-td" style={{ position: 'relative' }}>
                        {/* <img className="cursor-pointer" style={{ height: '13px', marginTop: '5px' }} alt=''
                            onClick={() => this.removeCartItem(row.customer_id)} src='images/message.svg' />&nbsp;&nbsp; */}
                        <Ionicon className="cursor-pointer" icon="md-more" fontSize="24px" color="#fdbb1f" onClick={this.OpenActionMenu.bind(this, i)} />
                        {actionMenuIndex === i && <div className="customer-list-action-menu" ref={this.actionContainer}>
                          <div>
                            <Row className="customer-list-action-menu-content cursor-pointer"
                              onClick={() =>
                                this.deleteCustomerConfirm(row.customer_id)
                              }
                            >
                              <Col sm="1">
                                <img
                                  className="cursor-pointer ml-1"
                                  style={{ height: "13px" }}

                                  src="images/delete.svg"
                                  alt=''
                                />
                              </Col>
                              <Col >Delete</Col>
                            </Row>
                          </div>
                          <div>
                            <Row className="customer-list-action-menu-content cursor-pointer"
                              onClick={() => this.blockCustomer(row.customer_id)}
                            >
                              <Col sm="1">
                                <img
                                  className=" ml-1"
                                  style={{ height: "13px" }}
                                  src="images/block.svg"
                                  alt=''
                                />
                              </Col>
                              <Col>Block</Col>
                            </Row>
                          </div>
                        </div>}


                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {count <= 0 && !this.state.filterApplied && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%" }}
                >
                  Currently there are no customers registered with your store.
                  Use Bulk Upload features to add customers to
                  your account.
                </div>
              )}
              {count <= 0 && this.state.filterApplied && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%" }}
                >
                  We can not find any records matching your search criteria.
                  Please change search criteria and try again.
                </div>
              )}
              {count > 0 && (
                <div
                  className="d-flex-row"
                  style={{ marginTop: "10px", justifyContent: "flex-end" }}
                >
                  <span className="Rows-per-page">Rows per page:</span>
                  <select
                    className="form-control rows-per-page-select"
                    name="pageSize"
                    value={pageSize}
                    onChange={e => this.handleInputChange(e)}
                    style={{ width: "65px" }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                  &nbsp;&nbsp;
                  <div style={{ marginTop: "5px" }}>
                    <Pagination
                      margin={2}
                      page={this.state.page}
                      count={this.state.totalPages}
                      pageSize={this.state.pageSize}
                      totalRecords={count}
                      onPageChange={this.handlePageChange}
                    ></Pagination>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

//export default withRouter(ListOfCustomers);


const mapStateToProp = state => (
  {
    redirectDetails: state.User.redirectDetails
  });

export default connect(mapStateToProp)(ListOfCustomers);