import React, { Component } from "react";
import ProposalSerivice from "../../services/ProposalSerivice";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DatePicker from "react-datepicker";
import swal from "sweetalert";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import Moment from "react-moment";
import TextareaAutosize from "react-textarea-autosize";
import Validation from "../../services/Validation";
import { messages } from "../../constants/constant.message";
class InterimVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      controls: {
        interim: {
          type: "interim",
          remark: {
            value: "",
            valid: "",
            touched: false,
            showErrorMsg: false,
          },
          document: {
            value: "",
            valid: "",
            touched: false,
            showErrorMsg: false,
          },
          visit_date: {
            value: null,
            valid: "",
            touched: false,
            showErrorMsg: false,
          },
        },
        weekly: {
          type: "weekly",
          remark: {
            value: "",
            valid: "",
            touched: false,
            showErrorMsg: false,
          },
          document: {
            value: "",
            valid: "",
            touched: false,
            showErrorMsg: false,
          },
          visit_date: {
            value: null,
            valid: "",
            touched: false,
            showErrorMsg: false,
          },
        },
        Hand_Over: {
          type: "Hand Over",
          remark: "",
          document: "",
          visit_date: null,
        },
      },
      isLoading: false,
      userDetail: Storage.getUserDetail(),
      isWeeklyData: false,
      isIntremData: false,
      isDisplayUploadPicInterim: false,
    };
  }

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };
  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { controls } = this.state;
      if (data && data.data) {
        controls[type].document.value = data.data;
        controls[type].document.touched = true;
      }
      this.setState({ controls, isLoading: false });

      if (type === "interim") {
        this.handleValidationInterim();
      } else {
        this.handleValidationWeekly();
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (!this.checkActiveStatus()) {
      return false;
    }
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        event.target.value = null;
        let { controls } = this.state;
        if (type === "interim") {
          controls.interim.document.value = "";
          controls.interim.document.touched = true;
        } else {
          controls.weekly.document.value = "";
          controls.weekly.document.touched = true;
        }
        this.setState({
          controls,
        });
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB.",
          "error"
        );
        return;
      } else {
        this.uploadFileFormData(file, type);
      }
    }
  };

  submitWeekly = () => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    let { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    let obj = {
      visits: [],
    };
    if (!this.handleValidationWeekly(true)) {
      return false;
    }
    if (controls.weekly.visit_date.value) {
      obj.visits.push({
        type: "weekly",
        remark: controls.weekly.remark.value,
        visit_date: controls.weekly.visit_date.value,
        document: controls.weekly.document.value,
      });
    }
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.saveInterimVisits(obj)
      .then((res) => {
        controls.weekly.remark.value = "";
        controls.weekly.document.value = null;
        controls.weekly.visit_date.value = null;

        if (this.fileInput3) {
          this.fileInput3.value = "";
        }
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message = "Weekly report details added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message = "Weekly report details updated successfully";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.props.getProposalStageDetail();
        this.setState({ isLoading: false, controls });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  handleValidationInterim = (isSubmit = false) => {
    let { controls } = this.state;
    let { visit_date, remark, document } = controls.interim;
    let isFormValid = false;

    if (visit_date.touched === true || isSubmit) {
      visit_date = Validation.notNullValidator(visit_date);
      visit_date.valid = !visit_date.nullValue;
      if (visit_date.valid === false) {
        visit_date.showErrorMsg = true;
      } else {
        visit_date.showErrorMsg = false;
      }
    }

    if (remark.touched === true || document.touched === true || isSubmit) {
      remark = Validation.notNullValidator(remark);
      remark.valid = !remark.nullValue;
      document = Validation.notNullValidator(document);
      document.valid = !document.nullValue;
      if (remark.valid === true || document.valid === true) {
        document.showErrorMsg = false;
      } else {
        document.showErrorMsg = true;
      }
    }

    if (visit_date.valid && (document.valid || remark.valid)) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls,
    });
    return isFormValid;
  };

  handleValidationWeekly = (isSubmit = false) => {
    let { controls } = this.state;
    let { visit_date, remark, document } = controls.weekly;
    let isFormValid = false;

    if (visit_date.touched === true || isSubmit) {
      visit_date = Validation.notNullValidator(visit_date);
      visit_date.valid = !visit_date.nullValue;
      if (visit_date.valid === false) {
        visit_date.showErrorMsg = true;
      } else {
        visit_date.showErrorMsg = false;
      }
    }

    if (remark.touched === true || document.touched === true || isSubmit) {
      remark = Validation.notNullValidator(remark);
      remark.valid = !remark.nullValue;
      document = Validation.notNullValidator(document);
      document.valid = !document.nullValue;
      if (remark.valid === true || document.valid === true) {
        document.showErrorMsg = false;
      } else {
        document.showErrorMsg = true;
      }
    }

    if (visit_date.valid && (document.valid || remark.valid)) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls,
    });
    return isFormValid;
  };

  submit = () => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    let { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    let obj = {
      visits: [],
    };
    if (!this.handleValidationInterim(true)) {
      return false;
    }
    // if (controls.Hand_Over.visit_date.value) {
    //   obj.visits.push(controls.Hand_Over);
    // }
    if (controls.interim.visit_date) {
      if (this.state.isDisplayUploadPicInterim) {
        obj.visits.push({
          type: "interim",
          remark: controls.interim.remark.value,
          visit_date: controls.interim.visit_date.value,
          document: controls.interim.document.value,
        });
      } else {
        obj.visits.push({
          type: "interim",
          remark: controls.interim.remark.value,
          visit_date: controls.interim.visit_date.value,
        });
      }
      //controls.interim.visit_date.value = new Date(controls.interim.visit_date);
    }
    // if (controls.weekly.visit_date) {
    //   obj.visits.push(controls.weekly);
    // }
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.saveInterimVisits(obj)
      .then((res) => {
        controls.interim.remark.value = "";
        controls.interim.document.value = null;
        controls.interim.visit_date.value = null;

        if (this.fileInput1) {
          this.fileInput1.value = "";
        }
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message = "Interim visits details added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message = "Interim visits details updated successfully";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.props.getProposalStageDetail();
        this.setState({ isLoading: false, controls });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  onChangeDate = (e, type) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    let { controls, isDisplayUploadPicInterim } = this.state;
    if (type === "interim") {
      let visit_date = new Date(e);
      visit_date.setHours(0);
      visit_date.setMinutes(0);
      visit_date.setMilliseconds(0);
      let todayDate = new Date();
      todayDate.setHours(0);
      todayDate.setMinutes(0);
      todayDate.setMilliseconds(0);
      isDisplayUploadPicInterim =
        Date.parse(visit_date) > Date.parse(todayDate) ? false : true;
    }
    controls[type].visit_date.value = e;
    controls[type].visit_date.touched = true;
    this.setState({ controls, isDisplayUploadPicInterim });
    if (type === "interim") {
      this.handleValidationInterim();
    } else {
      this.handleValidationWeekly();
    }
  };

  handleInputChange = (e, type) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[type][controlName].value = e.target.value;
    controls[type][controlName].touched = true;
    this.setState({ controls });
    if (type === "interim") {
      this.handleValidationInterim();
    } else {
      this.handleValidationWeekly();
    }
  };

  render() {
    const { controls, userDetail } = this.state;
    const { proposalStageDetail, isCompletedAllStages } = this.props;
    let isWeeklyData = false,
      isIntremData = false;
    if (
      proposalStageDetail &&
      proposalStageDetail.intrim_visit_data &&
      proposalStageDetail.intrim_visit_data.length > 0
    ) {
      let findIndexWeeklyType = proposalStageDetail.intrim_visit_data.findIndex(
        (x) => x.type === "weekly"
      );
      let findIndexInterimType = proposalStageDetail.intrim_visit_data.findIndex(
        (x) => x.type === "interim"
      );
      isWeeklyData = findIndexWeeklyType > -1 ? true : false;
      isIntremData = findIndexInterimType > -1 ? true : false;
    }
    let minDate = null;
    let recciData = proposalStageDetail.stages_details.filter(
      (x) => x.stage && x.stage === "Recci in progress"
    );
    if (recciData.length > 0) {
      let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
      minDate = new Date(
        recciData[0].completed_date
          ? recciData[0].completed_date.replace(pattern, "$3-$2-$1")
          : null
      );
    }
    let noDataFoundWeekly = false;
    if (
      proposalStageDetail.intrim_visit_data &&
      proposalStageDetail.intrim_visit_data.length > 0
    ) {
      const index = proposalStageDetail.intrim_visit_data.findIndex(
        (x) => x.type === "weekly"
      );
      if (index) {
        noDataFoundWeekly = true;
      }
    } else {
      noDataFoundWeekly = true;
    }

    return (
      (!isCompletedAllStages ||
        proposalStageDetail.intrim_visit_data.length > 0) && (
        <>
          <br />
          {this.state.isLoading && <CustomSpinner />}
          <Card>
            <CardBody>
              <Row>
                <Col xl="12" md="12" xs="12">
                  <b>Interim visit</b>
                </Col>
              </Row>
              <hr />
              {this.state.userDetail.access_level !== "area" &&
                this.state.userDetail.access_level !== "region" &&
                this.state.userDetail.access_level !== "zone" &&
                !isCompletedAllStages ? (
                <>
                  <Row>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label>
                          Interim Visit Date
                          <span className="required-field">*</span>
                        </Label>
                        <Label
                          onClick={(e) =>
                            this.calendar2.state.open && e.preventDefault()
                          }
                        >
                          <InputGroup>
                            <DatePicker
                              className={"form-control"}
                              onChange={(e) => this.onChangeDate(e, "interim")}
                              name="visit_date"
                              selected={controls.interim.visit_date.value}
                              dateFormat="dd/MM/yyyy"
                              showMonthDropdown
                              ref={(r) => (this.calendar2 = r)}
                              shouldCloseOnSelect={true}
                              showYearDropdown
                              minDate={minDate}
                              maxDate={new Date()}
                              dropdownMode="select"
                              disabled={
                                userDetail.access_level === "brand_store"
                              }
                            />
                            <InputGroupAddon addonType="append">
                              <div className="input-group-text">
                                <FontAwesomeIcon
                                  htmlFor="datepicker"
                                  icon={faCalendarAlt}
                                />
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                        </Label>
                        {controls.interim.visit_date.showErrorMsg && (
                          <div className="error">
                            * Please select interim visit date.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>Upload Pictures</Label>
                        <input
                          ref={(ref) => (this.fileInput1 = ref)}
                          type="file"
                          name="document"
                          onChange={(e) => this.uploadFile(e, "interim")}
                          disabled={userDetail.access_level === "brand_store"}
                        />
                        {controls.interim.document.showErrorMsg && (
                          <div className="error mt-2">
                            * Please upload document or enter remark.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label>Interim Remarks</Label>
                        <TextareaAutosize
                          name="remark"
                          autocomplete="nofill"
                          id="remark"
                          value={controls.interim.remark.value}
                          style={{
                            minHeight: 60,
                            maxHeight: 70,
                            fontSize: "14px",
                          }}
                          className="form-control"
                          onChange={(e) =>
                            this.handleInputChange(
                              {
                                target: {
                                  name: "remark",
                                  value: e.target.value,
                                },
                              },
                              "interim"
                            )
                          }
                          placeholder=""
                          disabled={userDetail.access_level === "brand_store"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {userDetail.access_level !== "brand_store" && (
                    <Row>
                      <Col
                        xl="3"
                        md="12"
                        xs="12"
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                          color="warning"
                          type="submit"
                          style={{ float: "right" }}
                          onClick={(e) => this.submit()}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <br />
                </>
              ) : (
                ""
              )}
              {proposalStageDetail &&
                proposalStageDetail.intrim_visit_data &&
                proposalStageDetail.intrim_visit_data.length > 0 &&
                isIntremData && (
                  <Table className="mb-0  tbl-about tbl-about table-responsive">
                    <thead>
                      <tr>
                        {/* <th scope="col">Type</th> */}
                        <th scope="col">Document</th>
                        <th scope="col">Visit Date</th>
                        {/* <th scope="col">Report Date</th> */}
                        <th scope="col">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {proposalStageDetail.intrim_visit_data.map(
                        (item) =>
                          item.type === "interim" && (
                            <tr>
                              {/* <td>{item && item.type}</td> */}
                              <td>
                                {item && item.document ? (
                                  <a
                                    className="aa d-flex-column"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.document}
                                  >
                                    <img
                                      src="images/sidebar-icons/secondary-report-selected.svg"
                                      alt=''
                                      height="40px"
                                      style={{ margin: "auto" }}
                                    />
                                    <span className="redirect-link">View</span>
                                  </a>
                                ) : (
                                  <span className="no-data-hypen  ml-auto mr-auto"></span>
                                )}
                              </td>
                              <td>
                                {item && item.visit_date ? (
                                  <Moment format="DD-MM-YYYY">
                                    {item.visit_date}
                                  </Moment>
                                ) : (
                                  ""
                                )}
                              </td>
                              {/* <td>{item && item.created_at ?
                          <Moment format="DD-MM-YYYY">
                            {item.created_at}
                          </Moment>
                          : ''}</td> */}
                              <td
                                style={{ maxWidth: "125px" }}
                                className="text-align-left"
                              >
                                {item && item.remark ? item.remark : ""}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                )}
            </CardBody>
          </Card>
          <br />
          <Card>
            <CardBody>
              <Row>
                <Col xl="12" md="12" xs="12">
                  <b>Weekly Report</b>
                </Col>
              </Row>
              <hr />
              {this.state.userDetail.access_level !== "area" &&
                this.state.userDetail.access_level !== "region" &&
                this.state.userDetail.access_level !== "zone" &&
                userDetail.access_level !== "brand_store" &&
                !isCompletedAllStages ? (
                <>
                  <Row>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label>
                          Weekly Report Date
                          <span className="required-field">*</span>
                        </Label>
                        <Label
                          onClick={(e) =>
                            this.calendar1.state.open && e.preventDefault()
                          }
                        >
                          <InputGroup>
                            <DatePicker
                              className={"form-control"}
                              onChange={(e) => this.onChangeDate(e, "weekly")}
                              name="visit_date"
                              selected={controls.weekly.visit_date.value}
                              dateFormat="dd/MM/yyyy"
                              showMonthDropdown
                              ref={(r) => (this.calendar1 = r)}
                              shouldCloseOnSelect={true}
                              showYearDropdown
                              minDate={minDate}
                              maxDate={new Date()}
                              dropdownMode="select"
                              disabled={
                                userDetail.access_level === "brand_store"
                              }
                            />
                            <InputGroupAddon addonType="append">
                              <div className="input-group-text">
                                <FontAwesomeIcon
                                  htmlFor="datepicker"
                                  icon={faCalendarAlt}
                                />
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                        </Label>
                        {controls.weekly.visit_date.showErrorMsg && (
                          <div className="error">
                            * Please select interim visit date.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>Upload document</Label>
                        <input
                          type="file"
                          name="document"
                          accept="image/*,application/pdf"
                          ref={(ref) => (this.fileInput3 = ref)}
                          onChange={(e) => this.uploadFile(e, "weekly")}
                          disabled={userDetail.access_level === "brand_store"}
                        />
                        {controls.weekly.document.showErrorMsg && (
                          <div className="error mt-2">
                            * Please upload document or enter remark.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label>Weekly Remarks</Label>
                        <TextareaAutosize
                          name="remark"
                          autocomplete="nofill"
                          id="remark"
                          value={controls.weekly.remark.value}
                          style={{
                            minHeight: 60,
                            maxHeight: 70,
                            fontSize: "14px",
                          }}
                          className="form-control"
                          onChange={(e) =>
                            this.handleInputChange(
                              {
                                target: {
                                  name: "remark",
                                  value: e.target.value,
                                },
                              },
                              "weekly"
                            )
                          }
                          placeholder=""
                          disabled={userDetail.access_level === "brand_store"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xl="3"
                      md="12"
                      xs="12"
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                        color="warning"
                        type="submit"
                        style={{ float: "right" }}
                        onClick={(e) => this.submitWeekly()}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                  <br />
                </>
              ) : (
                ""
              )}
              {proposalStageDetail &&
                proposalStageDetail.intrim_visit_data &&
                proposalStageDetail.intrim_visit_data.length > 0 &&
                isWeeklyData && (
                  <Table className="mb-0  tbl-about tbl-about table-responsive">
                    <thead>
                      <tr>
                        {/* <th scope="col">Type</th> */}
                        <th scope="col">Document</th>
                        <th scope="col">Visit Date</th>
                        {/* <th scope="col">Report Date</th> */}
                        <th scope="col">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {proposalStageDetail.intrim_visit_data.map(
                        (item) =>
                          item.type === "weekly" && (
                            <tr>
                              {/* <td>{item && item.type}</td> */}
                              <td>
                                {item && item.document ? (
                                  <a
                                    className="aa d-flex-column"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.document}
                                  >
                                    <img
                                      src="images/sidebar-icons/secondary-report-selected.svg"
                                      alt=''
                                      height="40px"
                                      style={{ margin: "auto" }}
                                    />
                                    <span className="redirect-link">View</span>
                                  </a>
                                ) : (
                                  <span className="no-data-hypen  ml-auto mr-auto"></span>
                                )}
                              </td>
                              <td>
                                {item && item.visit_date ? (
                                  <Moment format="DD-MM-YYYY">
                                    {item.visit_date}
                                  </Moment>
                                ) : (
                                  ""
                                )}
                              </td>
                              {/* <td>{item && item.created_at ?
                          <Moment format="DD-MM-YYYY">
                            {item.created_at}
                          </Moment>
                          : ''}</td> */}
                              <td
                                style={{ maxWidth: "125px" }}
                                className="text-align-left"
                              >
                                {item && item.remark ? item.remark : ""}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                )}

              {noDataFoundWeekly && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%", borderTop: "solid 0.9px #e9ecef" }}
                >
                  There is no data found.
                </div>
              )}
            </CardBody>
          </Card>
        </>
      )
    );
  }
}

export default InterimVisit;
