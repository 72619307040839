import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types"; // or PropTypes = require('prop-types');
import AuthService from "../../services/AuthService";
import StorageService from "../../services/Storage";
import ComponetWithHeader from "../CompentWithHeader/ComponetWithHeader";
import ComponentWithoutHeader from "../ComponentWithoutHeader/ComponentWithoutHeader";
import ComponentWithoutSideNav from "../ComponentWithoutSideNav/ComponentWithoutSideNav";
// import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { Roles } from "../../constants/constant.role";

/**
 * private route component to prevent unauthorize access
 */
const PrivateRoute = ({
  component: Component,
  sidenav,
  header,
  role,
  footer = false,
  isPrivateAndPublic,
  permission,
  ...rest
}) => {
  // uncomment  when authentication api implement

  const isAuthenticated = AuthService.isAuthenticated();
  useEffect(() => {
    if (window) {
      //GoogleAnalticsService.pageView(window.location.pathname + window.location.search);
    }
  })
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated === true || isPrivateAndPublic) {
          let userDetail = StorageService.getUserDetail();
          let userRole = userDetail && userDetail.type ? userDetail.type : null;
          let permissions = [];

          if (userDetail && userDetail.permissions) {
            permissions = userDetail.permissions;
          }

          if (!isPrivateAndPublic) {
            if (!userRole) {
              AuthService.logout();
              return (
                <Redirect
                  to={{
                    pathname: "/login"
                  }}
                />
              );
            }
            let redirectToPage = false;

            for (let i = 0; i < permissions.length; i++) {
              let currentPermission = permissions[i];
              if (permissions.includes(currentPermission)) {
                redirectToPage = true;
                break;
              }
            }

            // if (redirectToPage && role.includes(userRole.toLowerCase())) {
            //   redirectToPage = true;
            // }
            if (!redirectToPage) {
              if (
                userRole.toLowerCase() === Roles.REGION_MANAGER_ROLE ||
                userRole.toLowerCase() === Roles.ARCHITECT_ROLE ||
                userRole.toLowerCase() === Roles.AGENCY_ROLE ||
                // userRole.toLowerCase() === Roles.SALES_OFFICER_ROLE ||
                userRole.toLowerCase() === Roles.AREA_MANAGER_ROLE ||
                userRole.toLowerCase() === Roles.ZONE_MANAGER_ROLE ||
                userRole.toLowerCase() === Roles.ZTM_ROLE
              ) {
                if (
                  userDetail.access_level === 'architect' ||
                  userDetail.access_level === 'agency' ||
                  userDetail.access_level === 'ztm'
                ) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/proposal-dashboard"
                      }}
                    />
                  );
                }
                return (
                  <Redirect
                    to={{
                      pathname: "/dashboard"
                    }}
                  />
                );
              } else if (userRole.toLowerCase() === Roles.SALES_OFFICER_ROLE) {
                return (
                  <Redirect
                    to={{
                      pathname: "/point-dashboard"
                    }}
                  ></Redirect>
                );
              } else if (userRole.toLowerCase() === Roles.SALES_OFFICER_ROLE || userRole.toLowerCase() === Roles.PROJECT_MANAGER_ROLE) {
                return (
                  <Redirect
                    to={{
                      pathname: "/dashboard"
                    }}
                  ></Redirect>
                );
              } else if (userRole === Roles.SUB_DEALER_ROLE || userRole === Roles.BRANCH_ROLE) {
                if (userDetail && userDetail.is_login_first_time === true) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/on-boarding"
                      }}
                    />
                  );
                } else {
                  return (
                    <Redirect
                      to={{
                        pathname: "/warranty-score-card"
                      }}
                    />
                  );
                }
              } else if (userRole === Roles.SUPER_ADMIN_ROLE) {
                return (
                  <Redirect
                    to={{
                      pathname: "/set-notification"
                    }}
                  />
                );
              } else if (userRole && userRole.toLowerCase() === Roles.ACCOUNTANT_ROLE) {
                return (
                  <Redirect
                    to={{
                      pathname: "/order-history-ho"
                    }}
                  />
                );
              } else if (userRole && userRole.toLowerCase() === Roles.VENDOR_ROLE) {
                return (
                  <Redirect
                    to={{
                      pathname: "/retail-marketing-system"
                    }}
                  />
                )
              }
              else {
                if (userDetail && userDetail.is_login_first_time === true) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/on-boarding"
                      }}
                    />
                  );
                } else {
                  return (
                    <Redirect
                      to={{
                        pathname: "/"
                      }}
                    />
                  );
                }
              }
            } else {
              console.log("not valid route");
            }
          }

          if (
            userDetail &&
            (userDetail.type === Roles.DEALER_ROLE ||
              userDetail.type === Roles.SUB_DEALER_ROLE ||
              userDetail.type === Roles.BRANCH_ROLE) &&
            userDetail.is_login_first_time === true
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/on-boarding"
                }}
              />
            );
          }

          // if (
          //   props.history.location.pathname === "/campaign" &&
          //   userDetail.type === Roles.DEALER_ROLE && process.env.NODE_ENV === 'production'
          // ) {
          //   return (
          //     <Redirect
          //       to={{
          //         pathname: "/"
          //       }}
          //     />
          //   );
          // }
          if (isPrivateAndPublic && !isAuthenticated) {
            header = !isPrivateAndPublic;
            sidenav = !isPrivateAndPublic;
          }
          if (header === true && sidenav === true) {
            return (
              <ComponetWithHeader
                component={Component}
                {...props}
                footer={footer}
              />
            );
          } else if (header === true && sidenav === false) {
            return <ComponentWithoutSideNav component={Component} {...props} />;
          } else {
            return (
              <ComponentWithoutHeader
                component={Component}
                {...props}
                footer={footer}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login"
              }}
            />
          );
        }
      }}
    />

    // <Route
    //   {...rest}
    //   render={props =>
    //     // if user is authenticated successfully then redirect to component or
    //     // redirect to login page
    //     (
    //       isAuthenticated === true
    //         ? <ComponetWithHeader component={Component} {...props} />
    //         : <Redirect to={{
    //           pathname: '/login',
    //         }}
    //         />
    //     )
    //     // (
    //     //   isAuthenticated === true
    //     //     ? <Component {...props} />
    //     //     : <Redirect to={{
    //     //       pathname: '/login',
    //     //     }}
    //     //     />
    //     // )

    //   }
    // />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired
};

export default PrivateRoute;
