import React, { Fragment } from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import MerchandiseService from '../../services/MerchandiseService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import { formatDate } from '../../utils/utils';
import './OrderTrackModal.css';

const orderStatusMap = {
  'in_progress': 'In Progress',
  'dispatch': 'Dispatch',
  'delivered': 'Delivered',
  'ready_to_dispatch': 'Ready to Dispatch',
  'out_of_stock': 'Out of Stock'
}
class OrderTrackModal extends React.Component {

  state = {
    trackObj: null,
    isLoading: true
  }

  componentDidMount() {
    const { awb } = this.props;
    // const awb = '1375007777';
    this.tracKOrder(awb);
  }

  tracKOrder = (awb) => {
    this.setState({ isLoading: true });
    MerchandiseService.trackOrder(awb)
      .then(data => {
        const trackObj = data.data;
        this.setState({ isLoading: false });
        this.setState({ trackObj });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }

  render() {
    const { trackObj, isLoading } = this.state;

    return (
      <div>
        <Modal id="order-track-modal" isOpen={this.props.show} toggle={this.props.closeModal} centered>
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            {isLoading && <CustomSpinner></CustomSpinner>}
            <Row className="modal-title-border padding-bottom-10">
              <Col className="modal-title">
                Order Status
              </Col>
            </Row>
            <Row>
              <Col>
                {trackObj && <Fragment>
                  <Row className="margin-top-5">
                    <Col sm="6" className="bold-label">AWB No</Col>
                    <Col> {trackObj.awb}</Col>
                  </Row>
                  <Row className="margin-top-5">
                    <Col sm="6" className="bold-label">Status</Col>
                    <Col> {orderStatusMap[trackObj.status]}</Col>
                  </Row>
                  <Row className="margin-top-5">
                    <Col sm="6" className="bold-label">Current Status</Col>
                    <Col> {trackObj.currentStatus}</Col>
                  </Row>
                  <Row className="margin-top-5">
                    <Col sm="6" className="bold-label">Current City</Col>
                    <Col> {trackObj.currentCity}</Col>
                  </Row>
                  <Row className="margin-top-5">
                    <Col sm="6" className="bold-label">Order Date </Col>
                    <Col> {formatDate(trackObj.orderDate)}</Col>
                  </Row>
                  {trackObj.dispatchDate && <Row className="margin-top-5">
                    <Col sm="6" className="bold-label">Dispatch Date </Col>
                    <Col> {formatDate(trackObj.dispatchDate)}</Col>
                  </Row>}
                  <Row className="margin-top-5">
                    <Col sm="6" className="bold-label">Current Status Date </Col>
                    <Col> {formatDate(trackObj.currentStatusDate)}</Col>
                  </Row>
                </Fragment>}
                {!trackObj && <Fragment>
                  <div>Tracking detail not available</div>
                </Fragment>}
              </Col>
            </Row>
            <br />
          </ModalBody>
        </Modal >
      </div >
    );
  }
}

export default OrderTrackModal; 