import React, { Component } from 'react';
import { Col } from 'reactstrap';
class PointEarningProcess extends Component {
  render() {
    let pointsEarningProcess = this.props.pointsEarningProcess;
    return (
      <>
        <Col md="12" style={{ paddingLeft: '0' }}>
          <div className="About-the-Program mb-2">
            {pointsEarningProcess.title}
          </div>
          <div>
            <ul className="Enrollment-ul">
              {
                pointsEarningProcess.data && pointsEarningProcess.data.map((item) => (
                  <li className="enroll-li">
                    {item}
                  </li>
                ))
              }
            </ul>
          </div>
        </Col>
      </>
    );
  }
}

export default PointEarningProcess;