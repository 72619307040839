import React, { Component, Fragment } from 'react';
import SpecialEngagementModal from '../../modal/SpeacialEngagement/SpeacialEngagement';
import './SpecialEngagement.css';

class SpecialEngagement extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      token: query.get('token'),
      uuid: query.get('uuid'),
    };
  }

  render() {
    const { token, uuid } = this.state;
    return (
      <Fragment>
        <div className="spcl-eng-background" style={{ minHeight: '100vh' }}>
          <SpecialEngagementModal
            {...this.props}
            show={true}
            token={token}
            uuid={uuid}
          ></SpecialEngagementModal>
        </div>
      </Fragment >
    );
  }
}

export default SpecialEngagement;