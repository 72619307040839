import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import ProposalStatus from "../../components/ProposalStatus/ProposalStatus";
import Storage from "../../services/Storage";
import ProposalSatusUpdate from "../../components/ProposalSatusUpdate/ProposalSatusUpdate";
import ProposalSerivice from "../../services/ProposalSerivice";
import _ from "lodash";
import Validation from "../../services/Validation";
import swal from "sweetalert";
import AgencyBranding from "../../components/AgencyBranding/AgencyBranding";
import ProposalDetails from "../ProposalDetails/ProposalDetails";
import * as Scroll from "react-scroll";
import Ionicon from "react-ionicons";
import UploadAgreementPTP from "../../components/UploadAgreementPTP/UploadAgreementPTP";
import { messages } from "../../constants/constant.message";
import ModalService from "../../services/ModalService";
class ProjectStagePtp extends Component {
  steelWheelState = [
    {
      id: "recci_in_progress",
      name: "Recci in progress",
      count: 1,
      completed_date: null,
    },
    {
      id: "layout_agreement",
      name: "Layout & Agreement",
      count: 1,
      completed_date: null,
    },
    {
      id: "estimate_needed",
      name: "Estimate Needed",
      count: 1,
      completed_date: null,
    },
    {
      id: "Dealer_work_in_progress",
      name: "Dealer Work In Progress",
      count: 1,
      completed_date: null,
    },
    {
      id: "Agency_work_in_progress",
      name: "Agency work in progress",
      count: 1,
      completed_date: null,
    },
    {
      id: "Hand Over",
      name: "Hand Over",
      count: 1,
      completed_date: null,
    },
    {
      id: "Pre inauguration",
      name: "Pre inauguration",
      count: 1,
      completed_date: null,
    },
    {
      id: "Inaugurated",
      name: "Inaugurated",
      count: 1,
      completed_date: null,
    },
    {
      id: "Project completed",
      name: "Project completed",
      count: 1,
      completed_date: null,
    },
  ];
  state = {};
  xpressWheelState = [
    {
      id: "recci_in_progress",
      name: "Recci in progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "layout_agreement",
      name: "Layout & Agreement",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Dealer_work_in_progress",
      name: "Dealer Work In Progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Agency_work_in_progress",
      name: "Agency work in progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Hand Over",
      name: "Hand Over",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Pre inauguration",
      name: "Pre inauguration",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Inaugurated",
      name: "Inaugurated",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Project completed",
      name: "Project completed",
      counts: 0,
      completed_date: null,
    },
  ];
  ptpState = [
    {
      id: "Proposal Accepted",
      name: "Proposal Accepted",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Upload Agreement",
      name: "Upload Agreement",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Agency Allocation",
      name: "Agency Allocation",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Branding In Progress",
      name: "Branding In Progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Branding Completed",
      name: "Branding Completed",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Pre inauguration",
      name: "Pre inauguration",
      counts: 0,
    },
    {
      id: "Inaugurated",
      name: "Inaugurated",
      counts: 0,
    },
  ];
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    let userDetail = Storage.getUserDetail();
    this.state = {
      id: query.get("id"),
      type: query.get("type"),
      proposal_status: query.get("proposal-status"),
      userDetail: userDetail,
      proposalStageStatus: ProposalSerivice.getProposalStageStatus()
        ? ProposalSerivice.getProposalStageStatus()
        : {},
      isRecciInProgressReadOnly: true,
      proposalByIdData: {},
      is_display_view_deatil: false,
      proposalStageDetail: {
        agency_completion_data: [],
        dealership_completion_data: [],
        estimation_data: [],
        intrim_visit_data: [],
        layout_data: [],
        proposal_status: [],
        recci_data: [],
        stages_details: [],
      },
      currentPriority: 0,
      agencyObj: {
        controls: {
          agency: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
          },
          phoneNumber: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
          },
        },
      },
      agencyList: [],
    };

    if (
      this.state.proposalStageStatus &&
      Object.keys(this.state.proposalStageStatus).length <= 0
    ) {
      this.goToPrevPage();
    }
    if (
      this.state.proposalStageStatus &&
      Object.keys(this.state.proposalStageStatus).length > 0
    ) {
      if (
        this.state.proposalStageStatus.project_status &&
        this.state.proposalStageStatus.project_status.toLowerCase() ===
          "fresh proposal" &&
        this.state.userDetail.access_level === "all"
      ) {
        //this.state.isFreshProposal = true;
      }
    }
    this.state.progressStates = this.ptpState;
    this.state.progressStateCurrentIndex = 0;
    this.childHandOver = React.createRef();
    this.childProposalStatus = React.createRef();
    this.childRecciVisit = React.createRef();
    this.childProposalSatusUpdate = React.createRef();
    this.refProposalDetails = React.createRef();
  }

  componentDidMount() {
    // this.getAgencyList();
    // this.getPrposalById();
    // this.getProposalStageDetail();
    this.initApi();
  }

  scrollTo(id) {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80,
    });
  }

  initApi = async () => {
    await this.getAgencyList();
    await this.getPrposalById();
    this.getProposalStageDetail();
  };

  getProposalStageDetail = () => {
    if (
      (!this.state.proposalStageStatus &&
        !this.state.proposalStageStatus.uuid) ||
      this.state.proposalStageStatus.uuid === undefined
    ) {
      return;
    }
    this.setState({ isLoading: true });
    ProposalSerivice.getProposalStageDetail(
      this.state.proposalStageStatus.uuid,
      "PTP"
    )
      .then((res) => {
        let { currentPriority, agencyObj } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          let len = res.data.data[0].stages_details.length;
          let stage = "Proposal Accepted";
          let maxPriority = 0;
          if (res.data.data[0].stages_details.length > 0) {
            maxPriority = Math.max.apply(
              Math,
              res.data.data[0].stages_details.map(function(o) {
                return o.priority;
              })
            );
            currentPriority = maxPriority;
          }
          if (maxPriority && maxPriority > 0) {
            let stageObj = _.filter(
              res.data.data[0].stages_details,
              (element) => {
                return element.priority === maxPriority;
              }
            );
            if (stageObj && stageObj.length > 0) {
              stage = stageObj[0].stage;
            }
          }
          let currentIndex = this.state.progressStates.findIndex(
            (x) => x.name.toLowerCase() === stage.toLowerCase()
          );
          if (len > 0) {
            currentIndex++;
          }
          if (
            this.state.proposalStageStatus.project_status.toLowerCase() ===
            "fresh proposal"
          ) {
            currentIndex = -1;
          }
          this.state.progressStates.filter((x) => (x.completed_date = ""));
          res.data.data[0].stages_details.forEach((element) => {
            if (element.completed_date) {
              let index = -1;
              if (element.stage) {
                index = this.state.progressStates.findIndex(
                  (x) => x.name.toLowerCase() === element.stage.toLowerCase()
                );
              }
              if (index > -1) {
                this.state.progressStates[index].completed_date =
                  element.completed_date;
              }
            }
          });
          let { controls } = agencyObj;
          if (
            res.data.data[0].proposal_status &&
            res.data.data[0].proposal_status[0].agency_id
          ) {
            controls.agency.value =
              res.data.data[0].proposal_status[0].agency_id;
            controls.phoneNumber.value =
              res.data.data[0].proposal_status[0].agency_phone;
          }

          if (
            currentIndex === 6 &&
            this.state.proposalByIdData.stage &&
            this.state.proposalByIdData.stage === "Pre inauguration"
          ) {
            currentIndex = 5;
          }

          if (
            currentIndex === 7 &&
            this.state.proposalByIdData.stage &&
            this.state.proposalByIdData.stage === "Inaugurated"
          ) {
            let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
            let Inaugurated = res.data.data[0].stages_details.filter(
              (x) => x.stage && x.stage === "Inaugurated"
            );
            if (Inaugurated.length > 0) {
              if (
                new Date(
                  Inaugurated[0].completed_date.replace(pattern, "$3-$2-$1")
                ).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
              ) {
                currentIndex = 7;
              } else {
                currentIndex = 6;
              }
            } else {
              currentIndex = 5;
            }
            //let minDateInaugration = new Date(handover[0].completed_date.replace(pattern, '$3-$2-$1'));
          }

          this.setState(
            (prevState) => {
              return {
                ...prevState,
                proposalStageDetail: res.data.data[0],
                progressStateCurrentIndex: currentIndex,
                controls,
                currentPriority: currentPriority,
              };
            },
            () => {
              if (
                this.childProposalStatus &&
                this.childProposalStatus.current
              ) {
                this.childProposalStatus.current.setPreInauguration_date();
              }

              if (this.childRecciVisit && this.childRecciVisit.current) {
                this.childRecciVisit.current.setRecciData();
              }

              if (
                this.childProposalSatusUpdate &&
                this.childProposalSatusUpdate.current
              ) {
                this.childProposalSatusUpdate.current.setData();
              }
            }
          );
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };
  getPrposalById() {
    if (
      (!this.state.proposalStageStatus &&
        !this.state.proposalStageStatus.uuid) ||
      this.state.proposalStageStatus.uuid === undefined
    ) {
      return;
    }
    this.setState({ isLoading: true });
    ProposalSerivice.getProposalById(this.state.proposalStageStatus.uuid)
      .then((res) => {
        let { controls } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              proposalByIdData: res.data.data[0],
              controls,
            };
          });
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }
  goToPrevPage = (isSetproject_status = false) => {
    if (!isSetproject_status) {
      let projectStagetFilter = ProposalSerivice.getFilterProjectStage();
      if (projectStagetFilter && Object.keys(projectStagetFilter).length > 0) {
        projectStagetFilter.project_status = this.state.proposalByIdData.project_status;
        ProposalSerivice.setFilterInProjectStage(projectStagetFilter);
      } else {
        ProposalSerivice.setFilterInProjectStage({
          project_status: this.state.proposalByIdData.project_status,
        });
      }
    }
    this.props.history.goBack();
  };

  redirectViewProposal = (id) => {
    this.props.history.push(`proposal-details?id=${id}`);
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state.agencyObj;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state.agencyObj;
    let { agency } = controls;
    if (agency.touched === true || isSubmit) {
      agency = Validation.notNullValidator(agency);
      agency.valid = !agency.nullValue;
      if (agency.valid === false) {
        agency.showErrorMsg = true;
      } else {
        agency.showErrorMsg = false;
      }
    }

    if (agency.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  getAgencyList() {
    ProposalSerivice.getAgencyList()
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          this.setState((prevState) => {
            return { ...prevState, agencyList: res.data.data };
          });
        } else {
          this.setState((prevState) => {
            return { ...prevState, proposalsList: [] };
          });
        }
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  assignAgency = () => {
    const { proposalStageDetail } = this.state;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    let { controls } = this.state.agencyObj;
    if (!this.handleValidation(true)) {
      return;
    }

    let reqData = {
      agency_id: controls.agency.value,
      uuid: this.state.proposalStageStatus.uuid,
      isPtp: true,
    };
    ProposalSerivice.assignAgency(reqData)
      .then((res) => {
        controls.agency.value = "";
        controls.phoneNumber.value = "";
        swal({
          title: "Congratulations !",
          text: "Agency assigned successfully",
          icon: "success",
          type: "success",
        }).then((value) => {
          this.getProposalStageDetail();
        });
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  handlePersonVisiting = (e) => {
    const { controls } = this.state.agencyObj;
    const controlName = e.target.name;
    controls[controlName].value = e.target.value;
    let phoneNumber = _.filter(this.state.agencyList, (element) => {
      return element.id == e.target.value;
    });
    if (phoneNumber.length > 0) {
      controls["phoneNumber"].value = phoneNumber[0].phonenumber;
    }
    this.setState({ controls });
    this.handleValidation(false);
  };

  redirectDashboard = () => {
    this.props.history.push(`proposal-dashboard`);
  };

  openViewCloseViewDetail = () => {
    let { is_display_view_deatil } = this.state;
    is_display_view_deatil = !is_display_view_deatil;
    this.setState(
      {
        is_display_view_deatil,
      },
      () => {
        if (is_display_view_deatil) {
          this.refProposalDetails.current.getViewDetail(
            this.state.proposalByIdData.uuid
          );
          this.scrollTo("proposal-view-detail");
        }
      }
    );
  };

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };

  render() {
    let { controls } = this.state.agencyObj;
    return (
      <>
        {/* <div className="header-title">Project Stage</div> */}
        <div>
          <span
            className="prev-page-link cursor-pointer"
            onClick={this.redirectDashboard}
          >
            Proposal Dashboard
          </span>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span
            className="prev-page-link cursor-pointer"
            onClick={this.goToPrevPage}
          >
            Project Stage
          </span>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span
            className="prev-page-link cursor-pointer"
            style={{ color: "#2c3f4c" }}
          >
            {this.state.proposalByIdData && this.state.proposalByIdData.name
              ? this.state.proposalByIdData.name
              : ""}
          </span>
        </div>

        {!this.state.isFreshProposal && (
          <>
            <Card>
              <CardBody>
                <Row className="dealership-details-title-wrapp">
                  <Col xl="6" md="6" xs="6">
                    <b>Project status</b>
                  </Col>
                  <div className="d-flex-row">
                    {/* <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: 'right' }}
                      onClick={(e) => this.openViewCloseViewDetail()}
                    >
                      {this.state.is_display_view_deatil ? 'Hide Detail' : 'View Detail'}
                    </Button>&nbsp;&nbsp; */}
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="dark"
                      onClick={() => this.goToPrevPage()}
                    >
                      Back
                    </Button>
                  </div>
                </Row>
                <hr style={{ marginTop: "0.5rem" }} />
                <div>
                  <section>
                    <nav>
                      <ol className="cd-breadcrumb triangle custom-icons">
                        {this.state.progressStates.map((row, i) => (
                          <li
                            className={
                              this.state.progressStateCurrentIndex > i
                                ? "current-before"
                                : this.state.progressStateCurrentIndex === i
                                ? "current"
                                : ""
                            }
                          >
                            <span>
                              {row.name}
                              <br />
                              <span style={{ marginLeft: "15px" }}>
                                {row.completed_date}
                              </span>
                            </span>
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </section>
                </div>
                <br />
                {this.state.proposalStageDetail &&
                  this.state.proposalStageDetail.status_history &&
                  this.state.proposalStageDetail.status_history.length > 0 && (
                    <div className="project-stage-status-tbl">
                      <Table className="mb-0  tbl-about tbl-about table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Status</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Status Change Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.proposalStageDetail.status_history.map(
                            (item) => (
                              <tr>
                                <td>{item.status}</td>
                                <td>{item.remark ? item.remark : "--"}</td>
                                <td>{item.created_at}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}
              </CardBody>
            </Card>
            <br />
            <ProposalStatus
              userDetail={this.state.userDetail}
              proposalByIdData={this.state.proposalByIdData}
              ref={this.childProposalStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalStageStatus={this.state.proposalStageStatus}
              proposalStageDetail={this.state.proposalStageDetail}
              initApi={this.initApi}
              openViewCloseViewDetail={this.openViewCloseViewDetail}
              goToPrevPage={() => this.goToPrevPage()}
            />
          </>
        )}
        {this.state.userDetail.access_level === "all" &&
          this.state.currentPriority < 1 && (
            <ProposalSatusUpdate
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              redirectViewProposal={this.redirectViewProposal}
              goToPrevPage={this.goToPrevPage}
              initApi={this.initApi}
              is_display_view_deatil={this.state.is_display_view_deatil}
              openViewCloseViewDetail={this.openViewCloseViewDetail}
              proposalStageDetail={this.state.proposalStageDetail}
            />
          )}
        {this.state.is_display_view_deatil && (
          <div className="proposal-view-detail">
            <br />
            <ProposalDetails
              goBack={this.openViewCloseViewDetail}
              ref={this.refProposalDetails}
            />
            <br />
            {this.state.userDetail.access_level === "all" &&
              this.state.currentPriority < 1 && (
                <ProposalSatusUpdate
                  proposalStageStatus={this.state.proposalStageStatus}
                  getProposalStageDetail={this.getProposalStageDetail}
                  redirectViewProposal={this.redirectViewProposal}
                  goToPrevPage={this.goToPrevPage}
                  initApi={this.initApi}
                  is_display_view_deatil={this.state.is_display_view_deatil}
                  openViewCloseViewDetail={this.openViewCloseViewDetail}
                  proposalStageDetail={this.state.proposalStageDetail}
                />
              )}
          </div>
        )}
        {this.state.currentPriority >= 1 && (
          <UploadAgreementPTP
            initApi={this.initApi}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageStatus={this.state.proposalStageStatus}
            currentPriority={this.state.currentPriority}
            proposalStageDetail={this.state.proposalStageDetail}
          />
        )}
        {this.state.userDetail.access_level === "all" &&
        this.state.proposalStageDetail.stages_details &&
        this.state.proposalStageDetail.stages_details.length > 0 &&
        this.state.currentPriority >= 2 ? (
          <>
            <br />
            <Card>
              <CardBody>
                {/* Dealership Details */}
                <Row className="dealership-details-title-wrapp">
                  <Col xl="6" md="6" xs="6">
                    <b>Agency Allocation</b>
                  </Col>
                </Row>
                <hr style={{ marginTop: "0.5rem" }} />
                <Row>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>
                        Agency<span className="required-field">*</span>
                      </Label>
                      <select
                        className="form-control select-height-70rem"
                        name="agency"
                        value={controls.agency.value}
                        onChange={(e) => this.handlePersonVisiting(e)}
                      >
                        <option value="" selected disabled>
                          Select Agency
                        </option>
                        {this.state.agencyList &&
                          this.state.agencyList.map((item, i) => (
                            <option value={item.id}>
                              {item.business_name}
                            </option>
                          ))}
                      </select>
                      {controls.agency.showErrorMsg && (
                        <div className="error">* Please select agency.</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>Phone No</Label>
                      <Input
                        type="text"
                        name="phoneNumber"
                        disabled="true"
                        value={controls.phoneNumber.value}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="3"
                    md="12"
                    xs="12"
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: "right" }}
                      onClick={(e) => this.assignAgency()}
                    >
                      Submit
                    </Button>{" "}
                    &nbsp;&nbsp;
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        ) : (
          ""
        )}

        {(this.state.userDetail.access_level === "area" &&
          this.state.proposalStageDetail.agency_completion_data &&
          this.state.proposalStageDetail.agency_completion_data.length > 0) ||
        this.state.userDetail.access_level === "agency" ||
        (this.state.userDetail.access_level === "all" &&
          this.state.proposalStageDetail.agency_completion_data &&
          this.state.proposalStageDetail.agency_completion_data.length > 0) ||
        (this.state.userDetail.access_level === "ztm" &&
          this.state.proposalStageDetail.agency_completion_data &&
          this.state.proposalStageDetail.agency_completion_data.length > 0) ||
        (this.state.userDetail.access_level === "zone" &&
          this.state.proposalStageDetail.agency_completion_data &&
          this.state.proposalStageDetail.agency_completion_data.length > 0) ? (
          <AgencyBranding
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageStatus={this.state.proposalStageStatus}
            proposalStageDetail={this.state.proposalStageDetail}
          />
        ) : (
          ""
        )}
        {/* {
          this.proposalStageDetail && this.proposalStageDetail.estimation_data && this.proposalStageDetail.estimation_data.length > 0 &&
          <UploadMachineBill proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail} />
        }
        {
          this.state.currentPriority >= 0 && <RecciVisit
            status={this.state.id} proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail}
            ref={this.childRecciVisit} />
        }
        {
          this.state.currentPriority >= 1 &&
          <LayoutStage proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail} />
        }
        {
          this.state.proposalStageDetail && this.state.proposalStageDetail.estimation_data && this.state.proposalStageDetail.estimation_data.length >= 0 ?
            this.state.currentPriority >= 2 && this.state.proposalByIdData.outlet_type !== 'Xpress Wheel' &&
            <EstimateNeeded proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalStageDetail={this.state.proposalStageDetail} /> : ""
        }
        {
          (this.state.currentPriority >= 3) || (this.state.currentPriority >= 2 && this.state.proposalByIdData.outlet_type === 'Xpress Wheel') ?
            <DealerWorkInProgress
              getProposalStageDetail={this.getProposalStageDetail}
              proposalStageStatus={this.state.proposalStageStatus}
              proposalStageDetail={this.state.proposalStageDetail}
            /> : ""
        }
        {
          this.state.currentPriority >= 3 &&
          <InterimVisit proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail} />
        }
        {
          this.state.currentPriority >= 4 &&
          <AgencyWorkInProgress proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail} />
        }

        {
          this.state.currentPriority >= 5 && <HandOver proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail} ref={this.childHandOver} />
        }
        {this.state.currentPriority >= 4 && this.state.proposalByIdData.outlet_type !== 'Xpress Wheel' ?
          <UploadMachineBill proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail} /> : ""
        }
        {
          this.state.currentPriority >= 7 && <CompletionStage proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail} />
        } */}
      </>
    );
  }
}

export default ProjectStagePtp;
