import { request } from "./Request";
import { routes } from "../constants/constant.route";

const {
  GET_GIFT_FILTER_DETAILS,
  GET_GIFT_ITEMS,
  GET_GIFT_ITEM_DETAIL,
  GET_PRODUCT_LIST,
  UPLOAD_PRODUCT_IMAGE,
  UPDATE_DEFAULT_IMAGE,
  UPDATE_GIFT_ITEM,
  UPLOAD_GIFT_ITEMS,
  PLACE_ORDER_POINTS,
  GET_ORDERS,
  GET_DASHOBARD_COUNTS,
  GET_ADDRESS,
  ADD_UPDATE_ADDRESS,
  PLACE_ORDER,
  GET_ORDERS_BY_FILTER,
  USER_UPDATE_DETAIL,
} = routes.GIFT_CATALOG;
const API_URL = process.env.REACT_APP_API_URL;

export default class GiftCatalogService {
  static getGiftFilterDetails() {
    return request(
      "GET",
      `${API_URL}${GET_GIFT_FILTER_DETAILS}`,
      null,
      null,
      null
    );
  }

  static getGiftItems(page, pageSize, category, subCategory, min, max, search, sortByPoints) {
    console.log("sortByPoints", sortByPoints)
    let q = `?`;
    let obj = {};
    if (page) {
      q += `page=${page}&`;
      obj.page = page;
    }
    if (pageSize) {
      q += `pageSize=${pageSize}&`;
      obj.pageSize = pageSize;
    }
    if (category) {
      q += `category=${category}&`;
      obj.category = category;
    }
    if (subCategory) {
      q += `sub_category=${subCategory}&`;
      obj.sub_category = subCategory;
    }
    if (min) {
      q += `min=${min}&`;
      obj.min = min;
    }
    if (max) {
      q += `max=${max}&`;
      obj.max = max;
    }
    if (search) {
      q += `q=${search}&`;
      obj.q = search;
    }
    if (sortByPoints) {
      q += `sort_by_points=${sortByPoints}&`;
      obj.sort_by_points = sortByPoints;
    }
    const searchParams = new URLSearchParams(obj);
    return request(
      "GET",
      `${API_URL}${GET_GIFT_ITEMS}?${searchParams.toString()}`,
      null,
      null,
      null
    );
  }

  static getGiftItemDetail(uuid) {
    let q = `?`;
    if (uuid) {
      q += `u=${uuid}&`;
    }

    return request(
      "GET",
      `${API_URL}${GET_GIFT_ITEM_DETAIL}${q}`,
      null,
      null,
      null
    );
  }

  static getProductList(page, pageSize, search, status) {
    let q = `?`;
    if (page) {
      q += `page=${page}&`;
    }
    if (pageSize) {
      q += `pageSize=${pageSize}&`;
    }
    if (search) {
      q += `q=${search}&`;
    }
    if (status) {
      q += `status=${status}&`
    }
    return request(
      "GET",
      `${API_URL}${GET_PRODUCT_LIST}${q}`,
      null,
      null,
      null
    );
  }

  static uploadProductImage(id, body) {
    const headers = {
      "content-type": "multipart/form-data",
    };
    return request(
      "POST",
      `${API_URL}${UPLOAD_PRODUCT_IMAGE}/${id}`,
      null,
      body,
      headers
    );
  }
  static updateDefaultImage(id, body) {
    return request(
      "POST",
      `${API_URL}${UPDATE_DEFAULT_IMAGE}/${id}`,
      null,
      body
    );
  }

  static updateProduct(id, body) {
    return request(
      "POST",
      `${API_URL}${UPDATE_GIFT_ITEM}/${id}`,
      null,
      body
    );
  }
  static uploadGiftItems(body) {
    const headers = {
      "content-type": "multipart/form-data",
    };
    return request(
      "POST",
      `${API_URL}${UPLOAD_GIFT_ITEMS}`,
      null,
      body,
      headers
    );
  }

  static placeOrderPoints(body) {
    return request("POST", `${API_URL}${PLACE_ORDER_POINTS}`, null, body);
  }

  static getOrders(data) {
    let q = `?`;
    if (data.pageOrder) {
      q += `page=${data.pageOrder}&`;
    }
    if (data.pageSize) {
      q += `pageSize=${data.pageSize}&`;
    }
    if (data.q) {
      q += `q=${data.q}&`;
    }
    if (data.area) {
      q += `area=${data.area}&`;
    }
    if (data.region) {
      q += `region=${data.region}&`;
    }
    if (data.zone) {
      q += `zone=${data.zone}&`;
    }
    if (data.download_report) {
      q += `download_report=true&`
    }
    return request("GET", `${API_URL}${GET_ORDERS}${q}`, null, null, null);
  }

  static getDashboardCounts() {
    return request(
      "GET",
      `${API_URL}${GET_DASHOBARD_COUNTS}`,
      null,
      null,
      null
    );
  }

  static getAddresses() {
    return request("GET", `${API_URL}${GET_ADDRESS}`, null, null, null);
  }

  static addUpdateAddresses(data) {
    return request("POST", `${API_URL}${ADD_UPDATE_ADDRESS}`, null, data, null);
  }

  static updateUserDetail(data) {
    return request("POST", `${API_URL}${USER_UPDATE_DETAIL}`, null, data, null);
  }

  static placeOrder(data) {
    return request("POST", `${API_URL}${PLACE_ORDER}`, null, data, null);
  }

  static getOrdersByFilters(filters = null, body = {}) {
    return request(
      "GET",
      `${API_URL}${GET_ORDERS_BY_FILTER}`,
      filters,
      body,
      null
    );
  }
}
