/* eslint-disable react/sort-comp */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import {
  // Input,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody, Label, Button
} from 'reactstrap';
import lodash from 'lodash'; // library added for debounce functionality
import Ionicon from 'react-ionicons';
// components
import OutsideClickHandler from 'react-outside-click-handler';
import Sidebar from 'react-sidebar';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import Pagination from '../../components/Pagination/Pagination';
import WarrantyFilter from '../../components/YTDFilter/WarrantyFilter';
import AutoSuggestionDealer from '../../components/AutoSuggestionDealer/AutoSuggestionDealer';
// import CipSummaryListFilter from '../../components/CipSummaryList/CipSummaryListFilter';
// import XLSX from 'js-xlsx';
// services
import WarrantyScoreCardService from '../../services/WarrantyScoreCardService';
import StorageService from '../../services/Storage';
import DashboardService from '../../services/DashboardService';
import WarrantyRegistrationService from '../../services/WarrantyRegistrationService';
import {
  formatNumber,
  downlodFile,
  findCurrentQuarterMonths,
  getCurrentMonth,
  generateRandomString,
} from '../../utils/utils';
import WarrantyReportChart from '../../components/WarrantReportChart/WarrantyReportChart';
// import AddCustomer from '../../components/AddCustomer/AddCustomer';
// css
import './SecondaryPointAllDealerReport.css';
import { askForPermissioToReceiveNotifications } from '../../push-notification';
import { Roles } from '../../constants/constant.role';

const pageSize = 10;

class SecondaryPointAllDealerReport extends Component {
  state = {
    yearOptions: [],
    opensidebar: false,
    yearArray: [],
    year: null,
    quarter: null,
    month: null,
    pcr_min: null,
    pcr_max: null,
    sidebarOpen: false,
    two_wheel_min: null,
    two_wheel_max: null,
    lcv_min: null,
    lcv_max: null,
    scv_min: null,
    scv_max: null,
    sensor_min: null,
    sensor_max: null,
    sapId: null,
    maxDate: null,
    yeartoDownload: null,
    year_name: null,
    allMonths: [],
    openCalender: false,
    openYearlyCalender: false,
    height: window.innerHeight,
    width: window.innerWidth,
    isLoading: false,
    isHelpModuleLoading: false,
    page: 1,
    totalPages: 0,
    search: '',
    filterControls: {},
    isfilterApplied: false,
    totalRegCustomer: 0,
    totalPoints: 0,
    totalRegCusInCurrentQ: 0,
    totalEarnedPointsInCurrentQ: 0,
    totalRegCusInCurrentMonth: 0,
    totalEarnedPointsInCurrentMonth: 0,
    totalDealers: 0,
    dealers: [],
    currentUser: null,
    manageUserLevel: [],
    dealerRole: null,
    rolesToDrillDown: [],
    displayLabels: null,
    currentMonth: null,
    currentMonthValue: null,
    warranty_score_card_data: {
      total_card: {
        car_wheel_tyre: 0,
        warranty_customers: 0,
        two_three_wheel_tyre: 0,
        lcv_wheel_tyre: 0,
        scv_wheel_tyre: 0,
      },
      sensor_total_card: {
        sensor_car_wheel_tyre: 0,
        sensor_customers: 0,
        sensor_two_three_wheel_tyre: 0,
        sensor_lcv_wheel_tyre: 0,
        sensor_scv_wheel_tyre: 0,
      },
      current_month_card: {
        warranty_points: 0,
        warranty_customers: 0,
      },
      year_card: {
        redeem_point: 0,
        warranty_points: 0,
      },
      dealer_card: [],
      total_dealers: 0,
    },

    tooltipOpen: false,
    tooltip_name: 'warranty_report',
    filterMsg: "Showing Results of Apr'2019 to Till now",
  };

  constructor() {
    super();
    this.searchReport = lodash.debounce(this.searchReport, 500);
    this.WarrantyFilter = React.createRef();
    this.onSideBarOpen = this.onSideBarOpen.bind(this);
    this.onSideBarClose = this.onSideBarClose.bind(this);
    // this.childHoDashboardFilter = React.createRef();
  }

  componentDidMount() {
    this.getMaxDate();
    this.getHelpModuleScreen();
    askForPermissioToReceiveNotifications();
    window.addEventListener('resize', this.resizeWindow);
    this.getDisplayLabel();
    this.filterAPI();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  scrollDown() {
    window.scroll(0, 1000);
  }

  getMaxDate = () => {
    WarrantyScoreCardService.getMaxDate()
      .then(async (data) => {
        await this.setState({
          maxDate: data.data.data,
        });
        return Promise.resolve(null);
      })
      .catch((error) => {
        console.log('error', error);
        return Promise.reject(null);
      });
  };

  addHelpModuleScreen(name) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.setState({ isHelpModuleLoading: true });
    DashboardService.addHelpModuleScreen({ screen_name: name })
      .then((data) => {
        this.setState({ tooltipOpen: false, tooltip_name: '' });
        this.setState({ isHelpModuleLoading: false });
      })
      .catch((e) => {
        this.setState({ isHelpModuleLoading: false });
      });
  }

  onSideBarOpen = () => {
    this.setState((prevState) => {
      return { ...prevState, opensidebar: true };
    });
    //   // setTimeout(() => {
    //   //   if (Object.keys(this.refs).length > 0 && this.refs && this.refs.childAddEditSubsidiaries) {
    //   //     this.refs.childAddEditSubsidiaries.setValue();
    //   //   }
    //   // }, 1);
  };

  onSideBarClose = () => {
    this.setState({
      opensidebar: false,
    });
  };
  // onSetSidebarOpen(open) {
  //   document.body.style.overflow = "hidden";
  //   if (this.childHoDashboardFilter.current) {
  //     this.childHoDashboardFilter.current.setFilter(this.state.filterControls);
  //   }
  //   this.setState((prevState) => {
  //     return { ...prevState, sidebarOpen: open };
  //   });
  //   // setTimeout(() => {
  //   //   if (Object.keys(this.refs).length > 0 && this.refs && this.refs.childAddEditSubsidiaries) {
  //   //     this.refs.childAddEditSubsidiaries.setValue();
  //   //   }
  //   // }, 1);
  // }

  resizeWindow = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  generateRolesToDrillDown = (dealerRole) => {
    let rolesToDrillDown = [];
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      rolesToDrillDown = ['ZM', 'SM', 'AM', 'dealer'];
    } else if (dealerRole === Roles.PROJECT_MANAGER_ROLE) {
      rolesToDrillDown = ['ZM', 'SM', 'AM', 'dealer'];
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE || dealerRole === Roles.ZTM_ROLE) {
      rolesToDrillDown = ['SM', 'AM', 'dealer'];
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      rolesToDrillDown = ['AM', 'dealer'];
    } else if (dealerRole === Roles.AREA_MANAGER_ROLE) {
      rolesToDrillDown = ['dealer'];
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      rolesToDrillDown = ['dealer'];
    }
    this.setState({ rolesToDrillDown });
  };

  isFilterApplied = (value) => {
    this.setState({
      isfilterApplied: value,
    });
  };

  getAllDealerAndPoints = (
    page,
    search,
    downloadReport = null,
    sapId = null
  ) => {
    const func = downloadReport
      ? WarrantyScoreCardService.downloadWarrantyReport(
        page,
        pageSize,
        search,
        downloadReport,
        sapId,
        this.state.year || '',
        this.state.quarter || '',
        this.state.month || '',
        this.state.pcr_min,
        this.state.pcr_max,
        this.state.two_wheel_min,
        this.state.two_wheel_max
      )
      : WarrantyScoreCardService.getAllDealersAndPointsV2(
        page,
        pageSize,
        search,
        downloadReport,
        sapId,
        this.state.year || '',
        this.state.quarter || '',
        this.state.month || '',
        this.state.pcr_min,
        this.state.pcr_max,
        this.state.two_wheel_min,
        this.state.two_wheel_max,
        this.state.lcv_min,
        this.state.lcv_max,
        this.state.scv_min,
        this.state.scv_max,
        this.state.sensor_min,
        this.state.sensor_max
      );
    this.setState({ isLoading: true });
    func
      .then((data) => {
        this.setState({ isLoading: false });
        if (downloadReport) {
          const { file } = data.data.data;
          downlodFile(file);
          // data.download(data.headers['filePath']);
          return;
        }
        const {
          total_card,
          current_month_card,
          quartered_card,
          dealer_card,
          total_dealers,
          sensor_total_card,
          year_card,
        } = data.data.data;
        // const {
        //   total_card, current_month_card, quartered_card, dealer_card, total_dealers
        // } = warrantyAllDealerResponse.data;
        let { warranty_score_card_data } = this.state;
        warranty_score_card_data = data.data.data;
        // sensor_score_card_data = data.data.data;
        // const totalRegCustomer = total_card.warranty_customers;
        // const totalPoints = total_card.warranty_points;

        // const totalEarnedPointsInCurrentMonth =
        //   current_month_card.warranty_points;
        // const totalRegCusInCurrentMonth = current_month_card.warranty_customers;

        // const totalRegCusInCurrentQ = quartered_card.warranty_customers;
        // const totalEarnedPointsInCurrentQ = quartered_card.warranty_points;

        const totalPages = Math.ceil(
          warranty_score_card_data.total_dealers / pageSize
        );
        this.setState({
          dealers: warranty_score_card_data.dealer_card,
          totalDealers: warranty_score_card_data.total_dealers,
          warranty_score_card_data,
          totalPages,
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  openViewReportPage = (sapId, name) => {
    window.scroll(0, 0);
    const { manageUserLevel, randomKey } = this.state;
    const lastUser = manageUserLevel[manageUserLevel.length - 1];

    const obj = {
      sap_id: sapId,
      name,
      role: 'dealer',
      y: this.state.year,
    };
    if (lastUser && lastUser.role === 'dealer') {
      manageUserLevel[manageUserLevel.length - 1] = obj;
    } else {
      manageUserLevel.push(obj);
    }

    let randomId = null;
    if (randomKey) {
      randomId = randomKey;
    } else {
      randomId = generateRandomString();
    }
    this.setState({ sapId });
    StorageService.setNavigationLevelDetail(randomId, manageUserLevel);
    // this.props.history.push(`/primary-points-report?id=${sapId}&name=${name}`);
    // this.props.history.push(`/primary-points-report?k=${randomId}`);

    // this.props.history.push(`/warranty-score-card?id=${sapId}&name=${name}`);
    this.props.history.push(`/warranty-score-card?k=${randomId}`);
  };

  // addHelpModuleScreen = (name) => {
  //   // this.setState({ isHelpModuleLoading: true });
  //   DashboardService.addHelpModuleScreen({ screen_name: name })
  //     .then((data) => {
  //       this.setState({ tooltipOpen: false, tooltip_name: "" });
  //       // this.setState({ isHelpModuleLoading: false });
  //     })
  //     .catch((e) => {
  //       // this.setState({ isHelpModuleLoading: false });
  //     });
  // };

  handleSearchChange = (e) => {
    const searchVal = e.target.value;
    this.setState({ search: searchVal, page: 1 });
    this.searchReport(searchVal, 1);
  };

  searchReport = (search, page) => {
    this.getAllDealerAndPoints(page, search);
  };

  getSelectedDealerDetail = (sapId, name) => {
    window.scroll(0, 0);
    const { rolesToDrillDown, manageUserLevel, dealerRole } = this.state;
    const length = manageUserLevel.length;
    const role = rolesToDrillDown[length];
    const currentUser = {
      sap_id: sapId,
      name,
      role,
    };
    manageUserLevel.push(currentUser);
    this.setState({ page: 1, currentUser, manageUserLevel });
    this.getAllDealerAndPoints(1, null, false, sapId);
  };

  handlePageChange = (page) => {
    const { currentUser } = this.state;
    const sapId = currentUser && currentUser.sap_id ? currentUser.sap_id : null;
    this.setState({ page });
    this.getAllDealerAndPoints(page, this.state.search, false, sapId);
  };

  downloadReport = () => {
    const obj = {
      m: this.state.month,
      y: this.state.yeartoDownload,
      sap_id:
        this.state.currentUser && this.state.currentUser.sap_id
          ? this.state.currentUser.sap_id
          : null,
    };
    this.setState({
      isLoading: true,
    });
    WarrantyRegistrationService.warrantyReport(obj)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        downlodFile(res.data.data.file);
        this.setState({
          openCalender: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  downloadSummaryReport = () => {
    const obj = {
      y: this.state.yeartoDownload || 2019,
    };
    this.setState({
      isLoading: true,
    });
    WarrantyRegistrationService.warrantySummaryReport(obj)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        downlodFile(res.data.data.file);
        this.setState({
          openYearlyCalender: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  getDisplayLabel = () => {
    DashboardService.getDisplayLabel(this.state.year)
      .then((data) => {
        const labels = data.data && data.data.data ? data.data.data : null;
        this.setState({ displayLabels: labels });
      })
      .catch((e) => { });
  };

  gotoDashboard = () => {
    this.props.history.push('/point-dashboard');
  };

  getDataFromNavigation = (index, manageUserLevel = null) => {
    if (!manageUserLevel) {
      manageUserLevel = this.state.manageUserLevel;
    }
    manageUserLevel = manageUserLevel.slice(0, index);
    const userData = manageUserLevel[index - 1];
    if (userData && userData.sap_id) {
      const sapId = userData.sap_id;

      this.getAllDealerAndPoints(1, null, false, sapId);
    } else {
      this.getAllDealerAndPoints(1);
    }
    this.setState({
      page: 1,
      currentUser: userData,
      manageUserLevel,
    });
  };

  getPageTitle = () => {
    const { dealerRole } = this.state;
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      return ' of Head Office';
    } if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      return 'of Zone Manager';
    } if (dealerRole === Roles.ZTM_ROLE) {
      return 'of Zonal Trade Marketing';
    } if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      return 'of Region Manager';
    } if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      return 'of Sales Officer';
    } if (dealerRole === Roles.AREA_MANAGER_ROLE) {
      return 'of Area Manager';
    }
  };

  getTableNameTitle = (currentUserRole) => {
    if (currentUserRole === null) {
      currentUserRole = this.identifyCurrentRole();
    }
    if (currentUserRole === 'HO') {
      return 'Zone';
    } if (currentUserRole === 'AM') {
      return 'Dealer';
    } if (currentUserRole === 'SM') {
      return 'Area';
    } if (currentUserRole === 'ZM') {
      return 'Region';
    }
    return 'Dealer';
  };

  getTableSapIdTitle = (currentUserRole) => {
    if (currentUserRole === null) {
      currentUserRole = this.identifyCurrentRole();
    }
    if (
      currentUserRole === 'SM' ||
      currentUserRole === 'ZM' ||
      currentUserRole === 'HO'
    ) {
      return 'Email';
    }
    return 'SAP Code';
  };

  setToolTip = () => {
    this.setState({
      tooltip_name: 'active_dealer_report_table',
      tooltipOpen: true,
    });
    window.scrollTo({
      top: 1000,
      behavior: 'smooth',
    });
  };

  setToolTipForUpperScroll = () => {
    this.setState({
      tooltip_name: 'filter_target',
      tooltipOpen: true,
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  identifyCurrentRole = () => {
    const { dealerRole } = this.state;
    if (dealerRole === Roles.HEAD_OFFICE_ROLE || dealerRole === Roles.PROJECT_MANAGER_ROLE) {
      return 'HO';
    } if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      return 'ZM';
    } if (dealerRole === Roles.ZTM_ROLE) {
      return 'ZTM';
    } if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      return 'SM';
    } if (dealerRole === Roles.AREA_MANAGER_ROLE) {
      return 'AM';
    } if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      return 'SO';
    }
  };

  onChangeMonth = (e) => {
    this.setState({ month: e.target.value });
  };

  openCalender = () => {
    this.setState({
      openCalender: !this.state.openCalender,
    });
  };

  openYearlyCalender = () => {
    this.setState({
      openYearlyCalender: !this.state.openYearlyCalender,
    });
  };

  updateZone = (data) => {
    this.setState();
  };

  getHelpModuleScreen = () => {
    this.setState({ isHelpModuleLoading: true });
    DashboardService.getHelpModuleScreen()
      .then((data) => {
        this.setState({ isHelpModuleLoading: false });
        const { screen_name } = data.data.data;
        if (screen_name.includes(this.state.tooltip_name) === false) {
          this.setState({
            tooltipOpen: true,
            tooltip_name: 'warranty_total_card',
          });
        }
      })
      .catch((e) => {
        this.setState({ isHelpModuleLoading: false });
      });
  };

  reOpenToolTipPopOver = (name) => {
    this.setState({
      tooltipOpen: true,
      tooltip_name: name,
    });
  };

  filterAPI = () => {
    const { year } = this.state;

    const filter = {
      y: year || null,
    };
    this.setState({ isLoader: true });
    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          this.setState({ isLoader: false });

          this.setState(
            {
              yearArray: res.data.data.year ? res.data.data.year : [],
            },
            () => {
              if (
                this.state.year === 'all' &&
                this.state.yearArray &&
                this.state.yearArray[0]
              ) {
                this.setState(
                  {
                    yeartoDownload: this.state.yearArray[0].value,
                  },
                  () => {
                    const query = new URLSearchParams(
                      this.props.location.search
                    );
                    const id = query.get('id');
                    let index = query.get('i');
                    const headerYear = query.get('y');
                    let manageUserLevel = [];
                    const currentUser = null;
                    if (headerYear) {
                      this.setState({ year: headerYear }, () => {
                        if (id && index) {
                          index = isNaN(index) === false ? parseInt(index) : 0;
                          manageUserLevel = StorageService.getNavigationLevelDetail(
                            id
                          );

                          // this.setState({ manageUserLevel: manageUserLevel });
                          this.getDataFromNavigation(index, manageUserLevel);
                        } else {
                          this.getAllDealerAndPoints(this.state.page);
                        }
                      });
                    } else if (id && index) {
                      index = isNaN(index) === false ? parseInt(index) : 0;
                      manageUserLevel = StorageService.getNavigationLevelDetail(
                        id
                      );

                      // this.setState({ manageUserLevel: manageUserLevel });
                      this.getDataFromNavigation(index, manageUserLevel);
                    } else {
                      this.getAllDealerAndPoints(this.state.page);
                    }

                    const userDetail = StorageService.getUserDetail();
                    const { type } = userDetail;
                    this.setState({ dealerRole: type });
                    this.generateRolesToDrillDown(type);
                    // this.getAllDealerAndPoints(this.state.page, this.state.search);
                    const year = new Date().getFullYear();

                    const years = [];
                    for (let i = 2019; i <= year; i++) {
                      years.push(i);
                    }
                    this.setState({
                      yearOptions: years,
                    });

                    this.state.allMonths = [
                      { key: 'April', value: 4 },
                      { key: 'May', value: 5 },
                      { key: 'June', value: 6 },
                      { key: 'July', value: 7 },
                      { key: 'August', value: 8 },
                      { key: 'September', value: 9 },
                      { key: 'October', value: 10 },
                      { key: 'November', value: 11 },
                      { key: 'December', value: 12 },
                      { key: 'January', value: 1 },
                      { key: 'February', value: 2 },
                      { key: 'March', value: 3 },
                    ];

                    const d = new Date();
                    const currMonth = d.getMonth();
                    for (const key in this.state.allMonths) {
                      if (this.state.allMonths[key].value === currMonth + 1) {
                        this.setState(
                          {
                            currentMonth: this.state.allMonths[key],
                            currentMonthValue: this.state.allMonths[key].value,
                            month: this.state.allMonths[key].value,
                          },
                          () => {
                          }
                        );
                      }
                    }
                  }
                );
              } else {
                const query = new URLSearchParams(this.props.location.search);
                const id = query.get('id');
                let index = query.get('i');
                let manageUserLevel = [];
                const currentUser = null;
                if (id && index) {
                  index = isNaN(index) === false ? parseInt(index) : 0;
                  manageUserLevel = StorageService.getNavigationLevelDetail(id);
                  // this.setState({ manageUserLevel: manageUserLevel });
                  this.getDataFromNavigation(index, manageUserLevel);
                } else {
                  this.getAllDealerAndPoints(this.state.page);
                }
                const userDetail = StorageService.getUserDetail();
                const { type } = userDetail;
                this.setState({ dealerRole: type });
                this.generateRolesToDrillDown(type);
                // this.getAllDealerAndPoints(this.state.page, this.state.search);

                const year = new Date().getFullYear();
                const years = [];
                for (let i = 2019; i <= year; i++) {
                  years.push(i);
                }
                this.setState({
                  yearOptions: years,
                });
                this.state.allMonths = [
                  { key: 'April', value: 4 },
                  { key: 'May', value: 5 },
                  { key: 'June', value: 6 },
                  { key: 'July', value: 7 },
                  { key: 'August', value: 8 },
                  { key: 'September', value: 9 },
                  { key: 'October', value: 10 },
                  { key: 'November', value: 11 },
                  { key: 'December', value: 12 },
                  { key: 'January', value: 1 },
                  { key: 'February', value: 2 },
                  { key: 'March', value: 3 },
                ];
                const d = new Date();
                const currMonth = d.getMonth();
                for (const key in this.state.allMonths) {
                  if (this.state.allMonths[key].value === currMonth + 1) {
                    this.setState(
                      {
                        currentMonth: this.state.allMonths[key],
                        currentMonthValue: this.state.allMonths[key].value,
                        month: this.state.allMonths[key].value,
                      },
                      () => {
                      }
                    );
                  }
                }
              }
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        console.log('err', err);
      });
  };

  onChangeYear = (e) => {
    this.setState(
      {
        year: e && e.target && e.target.value,
      },
      () => {
        // this.filterAPI();
        this.getDisplayLabel();
        const sapId =
          this.state.currentUser && this.state.currentUser.sap_id
            ? this.state.currentUser.sap_id
            : null;
        this.getAllDealerAndPoints(
          this.state.page,
          this.state.search,
          false,
          sapId
        );
      }
    );
  };

  getWarrantyData = (data) => {
    if (data) {
      if (
        data.year === 'all' &&
        !data.quarter &&
        !data.month &&
        !data.pcr_min &&
        !data.pcr_max &&
        !data.two_wheel_min &&
        !data.two_wheel_max &&
        !data.lcv_min &&
        !data.lcv_max &&
        !data.scv_min &&
        !data.sensor_min &&
        !data.sensor_max
      ) {
        this.setState({
          isfilterApplied: false,
        });
      } else {
        this.setState({
          isfilterApplied: true,
        });
      }
      if (data.quarter && data.quarter.toString().toLowerCase() === 'all') {
        data.quarter = null;
      }
      if (data.month && data.month.toString().toLowerCase() === 'all') {
        data.month = null;
      }
      this.setState(
        {
          year: data && data.year ? data.year : 'all',
          quarter: data && data.quarter ? data.quarter : null,
          month: data && data.month ? data.month : null,
          pcr_min: data && data.pcr_min ? data.pcr_min : null,
          pcr_max: data && data.pcr_max ? data.pcr_max : null,
          two_wheel_min: data && data.two_wheel_min ? data.two_wheel_min : null,
          two_wheel_max: data && data.two_wheel_max ? data.two_wheel_max : null,
          lcv_min: data && data.lcv_min ? data.lcv_min : null,
          lcv_max: data && data.lcv_max ? data.lcv_max : null,
          scv_min: data && data.scv_min ? data.scv_min : null,
          scv_max: data && data.scv_max ? data.scv_max : null,
          sensor_min: data && data.sensor_min ? data.sensor_min : null,
          sensor_max: data && data.sensor_max ? data.sensor_max : null,
          page: 1,
        },
        () => {
          this.getDisplayLabel();
          const sapId =
            this.state.currentUser && this.state.currentUser.sap_id
              ? this.state.currentUser.sap_id
              : null;
          if (data) {
            this.getAllDealerAndPoints(1, this.state.search, false, sapId);
          }
          let { filterMsg } = this.state;

          let month = (data && data.month) || null;
          this.state.allMonths = [
            { key: 'Jan', value: 1 },
            { key: 'Feb', value: 2 },
            { key: 'Mar', value: 3 },
            { key: 'Apr', value: 4 },
            { key: 'May', value: 5 },
            { key: 'June', value: 6 },
            { key: 'July', value: 7 },
            { key: 'Aug', value: 8 },
            { key: 'Sep', value: 9 },
            { key: 'Oct', value: 10 },
            { key: 'Nov', value: 11 },
            { key: 'Dec', value: 12 },
          ];
          const d = new Date();
          const currMonth = d.getMonth();
          const currentYear =
            currMonth >= 0 || currMonth <= 2
              ? !!(data && data.year === new Date().getFullYear() + 1)
              : !!(data && data.year === new Date().getFullYear() + 1);
          for (const key in this.state.allMonths) {
            if (
              currentYear &&
              this.state.allMonths[key].value === currMonth + 1
            ) {
              month = this.state.allMonths[key].value;
            }
          }
          if (
            data &&
            data.year &&
            data.year !== '' &&
            data.year !== 'all' &&
            data.year !== null
          ) {
            // let lastYear =
            //   new Date().getMonth() + 1 > 3
            //     ? Number(data.year.slice(-2)) + 2
            //     : data.year.slice(-2);
            const lastYear =
              new Date().getMonth() + 1 > 3
                ? Number(data.year.slice(-2))
                : data.year.slice(-2);
            // let lastYear = data.year.slice() + 1;
            const LastYear = Number(lastYear) + 1;
            filterMsg = `Showing Results of ${data.year}-${LastYear}`;
          }
          const year = data.year
            ? data.year
            : new Date().getFullYear().toString();
          const quarter = [
            `Q1, ${year}-${Number(data.year.slice(-2)) + 1}`,
            `Q2, ${year}-${Number(data.year.slice(-2)) + 1}`,
            `Q3, ${year}-${Number(data.year.slice(-2)) + 1}`,
            `Q4, ${year}-${Number(data.year.slice(-2)) + 1}`,
          ];
          if (
            data &&
            data.quarter &&
            data.quarter !== '' &&
            data.quarter !== null
          ) {
            filterMsg = `Showing Results of ${quarter[data.quarter - 1]}`;
          }
          if (month && month !== '' && month !== null) {
            const lastYear =
              new Date().getMonth() + 1 > 3
                ? Number(data.year.slice(-2)) + 1
                : data.year.slice(-2);
            if (month !== 'all') {
              if (
                data &&
                data.quarter &&
                data.quarter !== '' &&
                data.quarter !== null
              ) {
                filterMsg = `Showing Results of ${this.state.allMonths[month - 1].key
                  }, ${quarter[data.quarter - 1]}`;
              } else {

              }
            } else if (
              data &&
              data.quarter &&
              data.quarter !== '' &&
              data.quarter !== null
            ) {
            } else {
              filterMsg = `Showing Results of ${data.year}-${Number(
                data.year.slice(-2)
              ) + 1}`;
            }
          }
          // if (data && data.year !== new Date().getFullYear() && month && month !== '' && month !== null) {
          //   let lastYear = new Date().getMonth() + 1 > 3 ? Number(data.year.slice(-2)) + 1 : data.year.slice(-2);
          //   if (month !== 'all' && data && data.quarter && data.quarter !== '' && data.quarter !== null) {
          //     // filterMsg = `Showing Results of ${this.state.allMonths[month - 1]["key"]}'${month > 3 ? data.year : Number(data.year + 1)}`;
          //     filterMsg = `Showing Results of ${this.state.allMonths[month - 1]["key"]}, ${data.year}-${Number(data.year.slice(-2)) + 1}`;
          //   } else {
          //     if (data && data.quarter && data.quarter !== '' && data.quarter !== null) {
          //     } else {
          //       filterMsg = `Showing Results of ${data.year}-${Number(data.year.slice(-2)) + 1}`;
          //     }
          //   }
          // }
          if (data && data.year === 'all') {
            filterMsg = "Showing Results of Apr'2019 to Till now";
          }
          this.setState({ filterMsg, allMonths: this.state.allMonths });
        }
      );
    } else {
      this.setState({
        year: this.state.year || 'all',
        quarter: this.state.quarter || null,
        month: this.state.month || null,
        pcr_min: this.state.pcr_min || null,
        pcr_max: this.state.pcr_max || null,
        two_wheel_min: this.state.two_wheel_min || null,
        two_wheel_max: this.state.two_wheel_max || null,
        lcv_min: this.state.lcv_min || null,
        lcv_max: this.state.lcv_max || null,
        scv_min: this.state.scv_min || null,
        scv_max: this.state.scv_max || null,
        sensor_min: this.state.sensor_min || null,
        sensor_max: this.state.sensor_max || null,
        page: 1,
      });
    }
  };

  onChangeYearOption = (e) => {
    this.setState({ yeartoDownload: e.target.value });
  };

  render() {
    const {
      totalRegCustomer,
      totalPoints,
      totalRegCusInCurrentQ,
      totalEarnedPointsInCurrentQ,
      totalEarnedPointsInCurrentMonth,
      totalRegCusInCurrentMonth,
      dealers,
      search,
      totalDealers,
      totalPages,
      dealerRole,
      manageUserLevel,
      currentUser,
      displayLabels,
      yearOptions,
      yearArray,
      warranty_score_card_data,
      filterMsg,
    } = this.state;
    const currentUserRole =
      currentUser && currentUser.role ? currentUser.role : null;
    const currentMonth = getCurrentMonth();
    const currentQMonths = findCurrentQuarterMonths();
    const pageTitle = this.getPageTitle();
    const tableNameTitle = this.getTableNameTitle(currentUserRole);
    const tableSapTitle = this.getTableSapIdTitle(currentUserRole);
    const manageUserLevelLength = manageUserLevel ? manageUserLevel.length : 0;
    const lastUser =
      manageUserLevel && manageUserLevel.length > 0
        ? manageUserLevel[manageUserLevel.length - 1]
        : null;
    const navigation = this.state.manageUserLevel.map((m, index) => (
      <Fragment>
        <span>
          <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
        </span>
        {index !== manageUserLevel.length - 1 && (
          <span
            className="prev-page-link cursor-pointer title-case"
            onClick={this.getDataFromNavigation.bind(this, index + 1, null)}
          >
            Warranty Report of {m.name} ({m.role})
          </span>
        )}
        {index === manageUserLevel.length - 1 && (
          <span className="current-page">
            Warranty Report of {m.name} ({m.role})
          </span>
        )}
        {index + 1 === this.state.manageUserLevel.length &&
          this.state.currentUser === null && (
            <img
              className="mt-auto mb-auto ml-2 cursor-pointer"
              src="/images/info_white_background.svg"
              alt=""
              style={{ height: '16px' }}
              onClick={() => this.reOpenToolTipPopOver('warranty_total_card')}
            />
          )}
      </Fragment>
    ));
    let role = null;
    if (dealerRole === Roles.AREA_MANAGER_ROLE || dealerRole === Roles.SALES_OFFICER_ROLE) {
      role = dealerRole;
    }
    if (lastUser && (lastUser.role === 'AM' || lastUser.role === 'SO')) {
      role = lastUser.role === 'AM' ? 'area_manager' : 'sales_officer';
    }
    const prepareDealerTableRow = dealers.map((d) => (
      <tr>
        <td className="title-case">{d.business_name}</td>
        {tableSapTitle !== 'Email' && <td>{d.sap_id}</td>}
        <td>{d.warranty_customers}</td>
        {/* {role === null && ( */}
        <td style={{ fontWeight: 'bold' }}>
          {d.total_active_dealers} /{' '}
          <span style={{ fontWeight: 'normal' }}>{d.total_dealers}</span>
        </td>
        {/* )} */}
        <td>{d.car_wheel_tyre}</td>
        <td>{d.two_three_wheel_tyre}</td>
        <td>{d.lcv_wheel_tyre}</td>
        <td>{d.scv_wheel_tyre}</td>
        <td>{d.sensor_count}</td>
        <td>{d.warranty_points}</td>
        <td
          onClick={
            (currentUser &&
              currentUser.role &&
              (currentUser.role === 'AM' || currentUser.role === 'SO')) ||
              dealerRole === Roles.AREA_MANAGER_ROLE ||
              dealerRole === Roles.SALES_OFFICER_ROLE
              ? this.openViewReportPage.bind(this, d.sap_id, d.business_name)
              : this.getSelectedDealerDetail.bind(
                this,
                d.sap_id,
                d.business_name
              )
          }
          // onClick={this.openViewReportPage.bind(this, d.sap_id, d.business_name)}
          className="cursor-pointer link"
        >
          View Report
        </td>
      </tr>
    ));
    const currmonth = new Date().getMonth() + 1;

    const monthOptionToDisplay = this.state.allMonths.map((m) => (
      <option value={m.value} selected={m.value === currmonth}>
        {m.key}
      </option>
    ));

    const yearOptionToDisplay = yearOptions.map((y) => (
      <option value={y}>{y}</option>
    ));

    const currYear = new Date().getFullYear();
    return (
      <div id="secondary-point-all-delear-report">
        {/* <Container> */}
        {(this.state.isHelpModuleLoading || this.state.isLoading) && (
          <CustomSpinner />
        )}
        {manageUserLevel.length > 0 && (
          <Row>
            <Col xl="10" md="8" xs="12">
              <span
                className="prev-page-link cursor-pointer"
                onClick={this.gotoDashboard}
              >
                Dashboard
              </span>
              <span>
                <Ionicon
                  icon="ios-arrow-forward"
                  fontSize="34px"
                  color="#fdbb1f"
                />
              </span>
              <span
                className="prev-page-link cursor-pointer"
                onClick={this.getDataFromNavigation.bind(this, 0, null)}
              >
                {`Warranty Report  ${pageTitle || ''}`}
              </span>
              {navigation}
            </Col>
            {/* <Col xl="2" md="4" xs="12" style={{ paddingLeft: "0px" }}>
              <Row>
                <div className="fin-label">Financial Year</div>
                &nbsp;
                <FormGroup>
                  <select
                    name="month"
                    className="form-control cursor-pointer"
                    value={this.state.year}
                    style={{
                      fontSize: "13px",
                      width: "110px",
                      color: "#6e777f",
                      marginLeft: "auto"
                    }}
                    onChange={e => {
                      this.onChangeYear(e);
                    }}
                  >
                    <option value="all" selected>
                      All
                    </option>
                    {this.state.yearArray.map(item => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </select>
                </FormGroup>
              </Row>
            </Col> */}
          </Row>
        )}
        {manageUserLevel.length === 0 && (
          <Row>
            <Col xl="10" md="8" xs="12">
              <span
                className="prev-page-link cursor-pointer"
                onClick={this.gotoDashboard}
              >
                Dashboard
              </span>
              <span>
                <Ionicon
                  icon="ios-arrow-forward"
                  fontSize="34px"
                  color="#fdbb1f"
                />
              </span>
              <span className="current-page">
                {this.state.dealerRole === Roles.PROJECT_MANAGER_ROLE
                  ? 'Warranty Report'
                  : ` Warranty Report  ${pageTitle || ''}`}
                <img
                  className="mt-auto mb-auto ml-2 cursor-pointer"
                  src="/images/info_white_background.svg"
                  alt=""
                  style={{ height: '16px' }}
                  onClick={() =>
                    this.reOpenToolTipPopOver('warranty_total_card')
                  }
                />
              </span>
            </Col>
            {/* {this.state.yearOptions.length > 1 && ( */}
            {/* <Col xl="2" md="4" xs="12" style={{ paddingLeft: "0px" }}>
              <Row>
                <div className="fin-label">Financial Year</div>
                &nbsp;
                <FormGroup>
                  <select
                    name="month"
                    className="form-control cursor-pointer"
                    value={this.state.year}
                    style={{
                      fontSize: "13px",
                      width: "110px",
                      color: "#6e777f",
                      marginLeft: "auto"
                    }}
                    onChange={e => {
                      this.onChangeYear(e);
                    }}
                  >
                    <option value="all" selected>
                      All
                    </option>
                    {this.state.yearArray.map(item => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </select>
                </FormGroup>
              </Row>
            </Col> */}
            {/* )} */}
          </Row>
        )}

        <Row className="margin-top-20 margin-bottom-5">
          <Col style={{ padding: 0 }} xl={this.state.width <= 1350 && this.state.width >= 1200 ? '5' : '4'}>
            <div style={{ display: 'flex', padding: '0 10px 0 0 ' }}>
              <div style={{ padding: 0 }} className="col-lg-10">
                <Col sm="12" xs="12" md="12" style={{ paddingRight: 0 }}>
                  <AutoSuggestionDealer
                    {...this.props}
                    isWarranty
                  />
                </Col>
              </div>
              <div style={{ padding: 0 }} className="col-xs-2">
                <Col style={{ padding: '5px 0 0 5px' }}>
                  {!this.state.isfilterApplied ? (
                    <img
                      id="filter_target"
                      className="cursor-pointer"
                      src="/images/filter.svg"
                      alt=""
                      onClick={this.onSideBarOpen}
                    />
                  ) : (
                    <img
                      id="filter_target"
                      className="mt-auto mb-auto cursor-pointer"
                      src="/images/filter_applied.svg"
                      alt=""
                      style={{
                        height: '20px',
                        marginLeft: '-0.7rem !important',
                      }}
                      onClick={this.onSideBarOpen}
                    />
                  )}
                  <>
                    {this.state.opensidebar && (
                      <Sidebar
                        sidebar={
                          <>
                            <WarrantyFilter
                              updateZone={this.updateZone}
                              getWarrantyData={this.getWarrantyData}
                              ref={this.WarrantyFilter}
                              data={this.state}
                              opensidebar={this.state.opensidebar}
                              isFilterApplied={this.isFilterApplied}
                              isfilterApplied={this.state.isfilterApplied}
                              onSideBarClose={this.onSideBarClose}
                            />
                            {/* <CipSummaryListFilter
                          applyFilter={this.applyFilter}
                          controls_filter={this.state.controls_filter}
                          is_warranty_points_redemption={this.state.is_warranty_points_redemption}
                          closeSidebar={this.closeSidebar}
                        /> */}
                          </>
                        }
                        open
                        transitions
                        sidebarClassName="campaign-drawer"
                        pullRight
                        styles={{
                          sidebar: {
                            background: 'white',
                            height: this.state.height
                              ? this.state.height
                              : '90%',
                            top: '60px',
                            width: this.state.width <= 1100 ? '100%' : '32%',
                            borderRadius: '2px',
                          },
                        }}
                      />
                    )}
                  </>
                </Col>
              </div>
            </div>
          </Col>
          <Col xl='8'>
            <div className="download_report" style={{ float: 'right' }}>
              <img src="images/download.svg" alt="" className="download-report-icon" />
              <span className="download_monthly_report cursor-pointer " onClick={this.openYearlyCalender}>
                Download yearly report
              </span>
              {this.state.openYearlyCalender && (
                <OutsideClickHandler onOutsideClick={this.openYearlyCalender}>
                  <Col xl-12>
                    <Card className="custom-datepicker">
                      <Row style={{ marginLeft: 0, marginRight: 0 }}>
                        <Col style={{ padding: '0' }}>
                          <FormGroup>
                            <Label style={{ float: 'left' }}>Year</Label>
                            <br />
                            <select name="year" className="form-control" value={this.state.yeartoDownload}
                              onChange={(e) => { this.onChangeYearOption(e); }} >
                              {yearOptionToDisplay}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Col xl="12" md="12" sm="12" style={{ padding: '-1px', textAlign: 'end' }} >
                        <Button style={{ width: '95px', backgroundColor: '#f7b924', border: 'none', }}
                          onClick={this.downloadSummaryReport} className="mb-1 mr-1 btn-pill action-button">
                          <b>Download</b>
                        </Button>
                      </Col>
                    </Card>
                  </Col>
                </OutsideClickHandler>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: '19px 15px 8px 0px' }}>
            <span> {filterMsg} </span>
          </Col>
        </Row>

        <Row style={{ marginTop: '5px', paddingRight: '15px' }}>
          <Col xl="4" lg="4" className="pr-0 warranty_registered_card">
            <Card
              id="warranty_total_card"
              className={
                this.state.tooltipOpen &&
                  this.state.tooltip_name === 'warranty_total_card'
                  ? 'card-bg-image box-shadow-40px-0-5'
                  : 'card-bg-image'
              }
            >
              {/* <CardBody className="padding-bottom-10px">
                <div className="col-md-12">
                  <div
                    className="warranty_card_heading"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: '5px'
                    }}
                  >
                    <span
                      className="card-point-lbl"
                      style={{ color: '#495057' }}
                    >
                      Customer Registered With Warranty
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.total_card.warranty_customers
                      )}
                    </span>
                  </div>
                </div>

                <div style={{ marginBottom: '5px' }} className="col-md-12">
                  <span
                    className="card-point-lbl"
                    style={{ marginBottom: "5px", color: '#495057' }}
                  >
                    Tyres Registered With Warranty
                      </span>
                </div>
                <div className="col-md-12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="col-md-3" style={{ padding: 0 }} className="d-flex-column">
                    <span className="card-point-label">PCR </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.total_card.car_wheel_tyre
                      )}
                    </span>
                  </div>
                  <div className="col-md-3" style={{ padding: 0 }} className="d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ width: "max-content" }}
                    >
                      2 Wheeler
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.total_card.two_three_wheel_tyre
                      )}
                    </span>
                  </div>
                  <div className="col-md-3" style={{ padding: 0 }} className="d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ width: "max-content" }}
                    >
                      LCV
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.total_card.lcv_wheel_tyre
                      )}
                    </span>
                  </div>
                  <div className="col-md-3" style={{ padding: 0 }} className="d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ width: "max-content" }}
                    >
                      SCV
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.total_card.scv_wheel_tyre
                      )}
                    </span>
                  </div>
                </div>

                {/* <Col xl="12" className="text-align-right mt-2">
                    {
                      this.state.year === "all" ?
                        <span className="Apr-Jun’20">Apr’19 to till now</span>
                        : <span className="Apr-Jun’20">{displayLabels && displayLabels.year}</span>
                    }
                  </Col>
              </CardBody>  */}
              <CardBody>
                <Row>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span className="card_main_heading">
                      Customers Registered with Warranty
                    </span>
                    <span className="card_main_value">
                      {' '}
                      {formatNumber(
                        warranty_score_card_data.total_card.warranty_customers
                      )}
                    </span>
                  </div>
                </Row>
                <Row>
                  <span className="card_main_subheading">
                    Tyres Registered with Warranty
                  </span>
                </Row>
                <Row>
                  <div className="row_container">
                    <div>
                      <div>
                        <span className="vehicle_type_heading">PCR</span>
                      </div>
                      <div>
                        <span className="vehicle_type_value">
                          {' '}
                          {formatNumber(
                            warranty_score_card_data.total_card.car_wheel_tyre
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="vehicle_type_heading">2 Wheeler</span>
                      </div>
                      <div>
                        <span className="vehicle_type_value">
                          {' '}
                          {formatNumber(
                            warranty_score_card_data.total_card
                              .two_three_wheel_tyre
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="vehicle_type_heading">LCV</span>
                      </div>
                      <div>
                        <span className="vehicle_type_value">
                          {' '}
                          {formatNumber(
                            warranty_score_card_data.total_card.lcv_wheel_tyre
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="vehicle_type_heading">SCV</span>
                      </div>
                      <div>
                        <span className="vehicle_type_value">
                          {' '}
                          {formatNumber(
                            warranty_score_card_data.total_card.scv_wheel_tyre
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <UncontrolledPopover
              placement="right-start"
              hideArrow={false}
              isOpen={
                this.state.tooltipOpen &&
                this.state.tooltip_name === 'warranty_total_card'
              }
              fade
              target="warranty_total_card"
              trigger="focus"
              className="warranty-report"
            >
              <div ref={(r) => (this.dealerPopOver = r)}>
                <PopoverHeader className="popover-title d-flex justify-content-between">
                  <span>Help Guide Tour</span>
                  <span>{role === null ? '1/6' : '1/6'}</span>
                </PopoverHeader>
                <PopoverBody className="d-flex-column">
                  <span className="popover-lbl mt-2">
                    Introducing Category Wise Count
                  </span>
                  <span className="popover-lbl-value mt-2">
                    Along with the warranty registered customers, now we are
                    displaying number of tyres registered (Category wise)
                  </span>
                  <br />
                  <div className="d-flex-row justify-content-end mb-2">
                    {role === null && (
                      <span
                        className="link-blue cursor-pointer font-size-14px font-weight-bold"
                        onClick={() =>
                          this.setState({
                            tooltipOpen: true,
                            tooltip_name: 'sensor_total_card',
                          })
                        }
                      >
                        Next
                      </span>
                    )}
                    {role !== null && (
                      <span
                        className="link-blue cursor-pointer font-size-14px font-weight-bold"
                        onClick={() =>
                          this.setState({
                            tooltipOpen: true,
                            tooltip_name: 'sensor_total_card',
                          })
                        }
                      >
                        Next
                      </span>
                    )}
                  </div>
                </PopoverBody>
              </div>
            </UncontrolledPopover>
          </Col>
          <Col xl="4" lg="4" className="pr-0 warranty_registered_card">
            <Card
              id="sensor_total_card"
              className={
                this.state.tooltipOpen &&
                  this.state.tooltip_name === 'sensor_total_card'
                  ? 'card-bg-image box-shadow-40px-0-5'
                  : 'card-bg-image'
              }
            >
              {/* <CardBody className="padding-bottom-10px">
                <div className="col-md-12">
                  <div
                    className="warranty_card_heading"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: '5px'
                    }}
                  >
                    <span
                      className="card-point-lbl"
                      style={{ marginBottom: "5px" }}
                    >
                      Customer Registered With Sensors
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.sensor_total_card.sensor_customer
                      )}
                    </span>
                  </div>
                </div>
                <div style={{ marginBottom: '5px' }} className="col-md-12">
                  <span
                    className="card-point-lbl"
                    style={{ marginBottom: "5px", color: '#495057' }}
                  >
                    Sensors Registered
                      </span>
                </div>
                <div className="col-md-12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="col-md-3" style={{ padding: 0 }} className="d-flex-column">
                    <span className="card-point-label">PCR </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.sensor_total_card.sensor_car_wheel_tyre
                      )}
                    </span>
                  </div>
                  <div className="col-md-3" style={{ padding: 0 }} className="d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ width: "max-content" }}
                    >
                      2 Wheeler
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.sensor_total_card
                          .sensor_two_three_wheel_tyre
                      )}
                    </span>
                  </div>
                  <div className="col-md-3" style={{ padding: 0 }} className="d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ width: "max-content" }}
                    >
                      LCV
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.sensor_total_card.sensor_lcv_wheel_tyre
                      )}
                    </span>
                  </div>
                  <div className="col-md-3" style={{ padding: 0 }} className="d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ width: "max-content" }}
                    >
                      SCV
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.sensor_total_card.sensor_scv_wheel_tyre
                      )}
                    </span>
                  </div>
                </div>
                {/* <Col xl="12" className="text-align-right mt-2">
                    {
                      this.state.year === "all" ?
                        <span className="Apr-Jun’20">Apr’19 to till now</span>
                        : <span className="Apr-Jun’20">{displayLabels && displayLabels.year}</span>
                    }
                  </Col> 
              </CardBody> */}
              <CardBody>
                <Row>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span className="card_main_heading">
                      Customers Registered with Sensors
                    </span>
                    <span className="card_main_value">
                      {' '}
                      {formatNumber(
                        warranty_score_card_data.sensor_total_card
                          .sensor_customer
                      )}
                    </span>
                  </div>
                </Row>
                <Row>
                  <span className="card_main_subheading">
                    Sensors Registered
                  </span>
                </Row>
                <Row>
                  <div className="row_container">
                    <div>
                      <div>
                        <span className="vehicle_type_heading">PCR</span>
                      </div>
                      <div>
                        <span className="vehicle_type_value">
                          {' '}
                          {formatNumber(
                            warranty_score_card_data.sensor_total_card
                              .sensor_car_wheel_tyre
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="vehicle_type_heading">2 Wheeler</span>
                      </div>
                      <div>
                        <span className="vehicle_type_value">
                          {' '}
                          {formatNumber(
                            warranty_score_card_data.sensor_total_card
                              .sensor_two_three_wheel_tyre
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="vehicle_type_heading">LCV</span>
                      </div>
                      <div>
                        <span className="vehicle_type_value">
                          {' '}
                          {formatNumber(
                            warranty_score_card_data.sensor_total_card
                              .sensor_lcv_wheel_tyre
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="vehicle_type_heading">SCV</span>
                      </div>
                      <div>
                        <span className="vehicle_type_value">
                          {' '}
                          {formatNumber(
                            warranty_score_card_data.sensor_total_card
                              .sensor_scv_wheel_tyre
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <UncontrolledPopover
              placement="right-start"
              hideArrow={false}
              isOpen={
                this.state.tooltipOpen &&
                this.state.tooltip_name === 'sensor_total_card'
              }
              fade
              target="sensor_total_card"
              trigger="focus"
              className="warranty-report"
            >
              <div ref={(r) => (this.dealerPopOver = r)}>
                <PopoverHeader className="popover-title d-flex justify-content-between">
                  <span>Help Guide Tour</span>
                  <span>{role === null ? '2/6' : '2/6'}</span>
                </PopoverHeader>
                <PopoverBody className="d-flex-column">
                  <span className="popover-lbl mt-2">
                    Introducing Sensors Registered Count
                  </span>
                  <span className="popover-lbl-value mt-2">
                    It display the number of customer has registered for sensor
                    and sensor registered for each category.
                  </span>
                  <br />
                  <div>
                    {role === null && (
                      <div className="d-flex-row justify-content-between mb-2">
                        <span
                          className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() =>
                            this.setState({
                              tooltipOpen: true,
                              tooltip_name:
                                role === null
                                  ? 'warranty_total_card'
                                  : 'warranty_total_card',
                            })
                          }
                        >
                          Previous
                        </span>
                        <span
                          className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() =>
                            this.setState({
                              tooltipOpen: true,
                              tooltip_name: 'filter_target',
                            })
                          }
                        >
                          Next
                        </span>
                      </div>
                    )}
                    {role !== null && (
                      <div className="d-flex-row justify-content-between mb-2">
                        <span
                          className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() =>
                            this.setState({
                              tooltipOpen: true,
                              tooltip_name: 'warranty_total_card',
                            })
                          }
                        >
                          Previous
                        </span>
                        <span
                          className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() =>
                            this.setState({
                              tooltipOpen: true,
                              tooltip_name: 'filter_target',
                            })
                          }
                        >
                          Next
                        </span>
                      </div>
                    )}
                  </div>
                </PopoverBody>
              </div>
            </UncontrolledPopover>
          </Col>

          <Col xl="4" lg="4" className="pr-0 points_card">
            <Card
              className="card-bg-image"
            // {
            //   // this.state.tooltipOpen &&
            //   // this.state.tooltip_name === "warranty_total_card"
            //     ? "card-bg-image box-shadow-40px-0-5"
            //     : "card-bg-image"
            // }
            >
              <CardBody className="padding-bottom-10px">
                <div style={{ padding: 0 }} className="col-md-12">
                  <span className="summary_card_main_heading">
                    Warranty Points Summary
                  </span>
                </div>
                <div
                  className="col-md-12"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 0,
                    marginTop: '15px',
                  }}
                >
                  <div
                    className="col-md-4 d-flex-column"
                    style={{ padding: 0 }}
                  >
                    <span className="points_card_label">Earned points </span>
                    <span className="points_card_value  mt-1">
                      {formatNumber(
                        warranty_score_card_data.year_card.total_points
                      )}
                    </span>
                  </div>
                  <div
                    className="col-md-4 d-flex-column"
                    style={{ padding: 0 }}
                  >
                    <span className="points_card_label">Redeemed Points</span>
                    <span className="points_card_value mt-1">
                      {formatNumber(
                        warranty_score_card_data.year_card.redeem_point
                      )}
                    </span>
                  </div>
                  <div
                    className="col-md-4 d-flex-column"
                    style={{ padding: 0 }}
                  >
                    <span className="points_card_label">Balance Points</span>
                    <span className="points_card_value mt-1">
                      {formatNumber(
                        warranty_score_card_data.year_card.total_points -
                        warranty_score_card_data.year_card.redeem_point
                      )}
                    </span>
                  </div>
                </div>
                {/* <Col xl="12" className="text-align-right mt-2">
                    {
                      this.state.year === "all" ?
                        <span className="Apr-Jun’20">Apr’19 to till now</span>
                        : <span className="Apr-Jun’20">{displayLabels && displayLabels.year}</span>
                    }
                  </Col> */}
              </CardBody>
            </Card>
            {/* <UncontrolledPopover
              placement={"right-start"}
              trigger="legacy"
              hideArrow={false}
              isOpen={
                this.state.tooltipOpen &&
                this.state.tooltip_name === "total_card"
              }
              fade={true}
              target={"total_card"}
              trigger="focus"
              className="warranty-report"
            >
              <div ref={(r) => (this.dealerPopOver = r)}>
                <PopoverHeader className="popover-title d-flex justify-content-between">
                  <span>Help Guide Tour</span>
                  <span>{role === null ? "1/4" : "1/3"}</span>
                </PopoverHeader>
                <PopoverBody className="d-flex-column">
                  <span className="popover-lbl mt-2">
                    Introducing Category Wise Count
                  </span>
                  <span className="popover-lbl-value mt-2">
                    It displays the number of customers registered and tyres
                    registered for each category.
                  </span>
                  <br />
                  {
                    <div className="d-flex-row justify-content-end mb-2">
                      {role === null && (
                        <span
                          className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() =>
                            this.setState({
                              tooltipOpen: true,
                              tooltip_name: "filter_target",
                            })
                          }
                        >
                          Next
                        </span>
                      )}
                      {role !== null && (
                        <span
                          className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() =>
                            this.setState({
                              tooltipOpen: true,
                              tooltip_name: "filter_target",
                            })
                          }
                        >
                          Next
                        </span>
                      )}
                    </div>
                  }
                </PopoverBody>
              </div>
            </UncontrolledPopover> */}
          </Col>

          {/* <Col xl="4" className="pr-0 warranty_registered_card">
            <Card
              // id="total_card"
              className={
                this.state.tooltipOpen &&
                  this.state.tooltip_name === "total_card"
                  ? "card-bg-image box-shadow-40px-0-5"
                  : "card-bg-image"
              }>
              <CardBody className="padding-bottom-10px">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div className="col-md-4 d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ marginTop: "0px" }}
                    >
                      Earned Points
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.year_card.total_points
                      )}
                    </span>
                  </div>
                  <div className="col-md-4 d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ marginTop: "0px" }}
                    >
                      Redeemed Points
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.year_card.redeem_point
                      )}
                    </span>
                  </div>
                  {/* <Col xl="12" className="text-align-right mt-2">
                    {
                      this.state.year === "all" ?
                        <span className="Apr-Jun’20">Apr’19 to till now</span>
                        : <span className="Apr-Jun’20">{displayLabels && displayLabels.year}</span>
                    }
                  </Col> 
                  <div className="col-md-4 d-flex-column">
                    <span
                      className="card-point-label"
                      style={{ marginTop: "0px" }}
                    >
                      Balance Points
                    </span>
                    <span className="card-point-value mt-1">
                      {formatNumber(
                        warranty_score_card_data.year_card.totalPoints -
                        warranty_score_card_data.year_card.redeem_point
                      )}
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* {
            (currYear === this.state.year) && (
              <Col xl="4" className="pr-0">
                <Card>
                  <CardBody className="padding-bottom-10px">
                    <Row>
                      <Col xs="6" className="d-flex-column">
                        <span className="card-point-lbl">
                          Total Customer
                            </span>
                        <span className="card-point-value mt-1">
                          {formatNumber(warranty_score_card_data.current_month_card.warranty_customers)}
                        </span>
                      </Col>
                      <Col xs="6" className="d-flex-column">
                        <span className="card-point-lbl">
                          Earned Points
                            </span>
                        <span className="card-point-value mt-1">
                          {formatNumber(warranty_score_card_data.current_month_card.warranty_points)}
                        </span>
                      </Col>
                      <Col xl="12" className="text-align-right mt-2">
                        {
                          this.state.year === "all" ?
                            <span className="Apr-Jun’20">June’19 to till now</span>
                            : <span className="Apr-Jun’20">{displayLabels && displayLabels.month}</span>
                        }
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )} */}
        </Row>
        <div
          className={this.state.isfilterApplied ? 'hide_chart' : 'show_chart'}
          style={{ marginBottom: '24px' }}
        >
          <p className="heading">3 months Offtake vs Warranty Trend</p>
          <div className="col-md-12">
            <Row style={{ display: 'flex' }}>
              <Col xl="3" sm="6" style={{ padding: '0' }}>
                <div style={{ margin: '3px' }}>
                  <WarrantyReportChart
                    vehicle_type="car"
                    maxDate={this.state.maxDate}
                    zoneid={
                      this.state.currentUser
                        ? this.state.currentUser.sap_id
                        : ''
                    }
                  />
                </div>
              </Col>
              <Col xl="3" sm="6" style={{ padding: '0' }}>
                <div style={{ margin: '3px' }}>
                  <WarrantyReportChart
                    vehicle_type="2 wheeler"
                    maxDate={this.state.maxDate}
                    zoneid={
                      this.state.currentUser
                        ? this.state.currentUser.sap_id
                        : ''
                    }
                  />
                </div>
              </Col>
              <Col xl="3" sm="6" style={{ padding: '0' }}>
                <div style={{ margin: '3px' }}>
                  <WarrantyReportChart
                    vehicle_type="lcv"
                    maxDate={this.state.maxDate}
                    zoneid={
                      this.state.currentUser
                        ? this.state.currentUser.sap_id
                        : ''
                    }
                  />
                </div>
              </Col>
              <Col xl="3" sm="6" style={{ padding: '0' }}>
                <div style={{ margin: '3px' }}>
                  <WarrantyReportChart
                    vehicle_type="scv"
                    maxDate={this.state.maxDate}
                    zoneid={
                      this.state.currentUser
                        ? this.state.currentUser.sap_id
                        : ''
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center' }}>
          <p className="heading">Zonal Report</p>
          <Col
            xl="4"
            className="pr-0 text-align-right"
          >
            <img
              src="images/download.svg"
               alt=""
              className="download-report-icon"
              style={{ position: "absolute", left: "61%", bottom: "-10px" }}
            />
            <span
              className="cursor-pointer content"
              onClick={this.openCalender}
              style={{
                position: "absolute",
                color: "#545cd8",
                left: "66%",
                bottom: "-10px",
                fontSize: '14px',
                lineHeight: '14px',
                fontWeight: 'bold',
                width: "fit-content !important",
              }}
            >
              Download Monthly Report
            </span>
            {this.state.openCalender && (
              <OutsideClickHandler onOutsideClick={this.openCalender}>
                <Col xl-12>
                  <Card className="custom-datepicker">
                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                      <Col>
                        <FormGroup>
                          <Label style={{ float: "left" }}>Month</Label>
                          <br />
                          <select
                            name="month"
                            className="form-control"
                            value={this.state.month}
                            onChange={(e) => {
                              this.onChangeMonth(e);
                            }}
                          >
                            {monthOptionToDisplay}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label style={{ float: "left" }}>Year</Label>
                          <br />
                          <select
                            name="year"
                            className="form-control"
                            value={this.state.yeartoDownload}
                            onChange={(e) => {
                              this.onChangeYearOption(e);
                            }}
                          >
                            {yearOptionToDisplay}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col
                      xl="12"
                      md="12"
                      sm="12"
                      style={{ padding: "-1px", textAlign: "end" }}
                    >
                      <Button
                        style={{
                          width: "95px",
                          backgroundColor: "#f7b924",
                          border: "none",
                        }}
                        onClick={this.downloadReport}
                        className="mb-1 mr-1 btn-pill action-button"
                      >
                        <b>Download</b>
                      </Button>
                    </Col>
                  </Card>
                </Col>
              </OutsideClickHandler>
            )}
          </Col>
        </div> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: this.state.isfilterApplied ? '20px' : '5px',
            marginBottom: '8px',
          }}
        >
          <div>
            <span className="zonal-report">
              {this.getTableNameTitle(currentUserRole)} Report
            </span>
          </div>
          <div
            className="download_report"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img
              src="images/download.svg"
              alt=""
              className="download-report-icon"
            />
            <span
              className="download_monthly_report cursor-pointer "
              onClick={this.openCalender}
            >
              Download monthly report
            </span>
            {this.state.openCalender && (
              <OutsideClickHandler onOutsideClick={this.openCalender}>
                <Col xl-12>
                  <Card className="custom-datepicker">
                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                      <Col xs="12" style={{ padding: 0 }}>
                        <FormGroup>
                          <Label style={{ float: 'left' }}>Month</Label>
                          <br />
                          <select
                            // style={{ width: "max-content" }}
                            name="month"
                            className="form-control"
                            value={this.state.month}
                            onChange={(e) => {
                              this.onChangeMonth(e);
                            }}
                          >
                            {monthOptionToDisplay}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col style={{ padding: '0' }}>
                        <FormGroup>
                          <Label style={{ float: 'left' }}>Year</Label>
                          <br />
                          <select
                            name="year"
                            className="form-control"
                            value={this.state.yeartoDownload}
                            onChange={(e) => {
                              this.onChangeYearOption(e);
                            }}
                          >
                            {yearOptionToDisplay}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col
                      xl="12"
                      md="12"
                      sm="12"
                      style={{ padding: '-1px', textAlign: 'end' }}
                    >
                      <Button
                        style={{
                          width: '95px',
                          backgroundColor: '#f7b924',
                          border: 'none',
                        }}
                        onClick={this.downloadReport}
                        className="mb-1 mr-1 btn-pill action-button"
                      >
                        <b>Download</b>
                      </Button>
                    </Col>
                  </Card>
                </Col>
              </OutsideClickHandler>
            )}
          </div>
        </div>
        <Row>
          <Col>
            <Card>
              <CardBody className="overflow-auto">
                <Table>
                  <thead>
                    <tr>
                      <th>{tableNameTitle}</th>
                      {tableSapTitle !== 'Email' && <th>{tableSapTitle}</th>}
                      <th>
                        <tr style={{ border: 'none' }}>Customers Registered</tr>
                        <tr style={{ border: 'none' }}>with Warranty</tr>
                      </th>
                      <th
                        id="active_dealer_report_table"
                        className={
                          this.state.tooltipOpen &&
                            this.state.tooltip_name ===
                            'active_dealer_report_table'
                            ? 'box-shadow-40px-0-5'
                            : ''
                        }
                      >
                        <tr
                          style={{
                            border: 'none',
                            width: '130px !important',
                          }}
                        >
                          Dealers started warranty
                        </tr>
                        <tr style={{ border: 'none' }}>
                          Active /{' '}
                          <span style={{ fontWeight: 'normal' }}>Total </span>
                        </tr>
                      </th>
                      <UncontrolledPopover
                        placement="right-start"
                        hideArrow={false}
                        isOpen={
                          this.state.tooltipOpen &&
                          this.state.tooltip_name ===
                          'active_dealer_report_table'
                        }
                        fade
                        target="active_dealer_report_table"
                        trigger="focus"
                        className="warranty-report"
                      >
                        <div ref={(r) => (this.dealerPopOver = r)}>
                          <PopoverHeader className="popover-title d-flex justify-content-between">
                            <span>Help Guide Tour</span>
                            <span>{role === null ? '4/6' : '4/6'}</span>
                          </PopoverHeader>
                          <PopoverBody className="d-flex-column">
                            <span className="popover-lbl mt-2">
                              Dealers Started Warranty
                            </span>
                            {/* <span className="popover-lbl mt-2">
                              Adding three fields for better understanding of the report</span> */}
                            <span className="popover-lbl-value mt-2">
                              Shows the warranty active dealers out of total
                              dealers. <br />
                            </span>
                            <br />
                            <div className="d-flex-row justify-content-between mb-2">
                              <span
                                className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                onClick={
                                  this.setToolTipForUpperScroll
                                  // () =>
                                  // this.setState({
                                  //   tooltipOpen: true,
                                  //   tooltip_name: "filter_target",
                                  //   // ? "filter_target"
                                  //   //  "warranty_total_card",
                                  // })
                                }
                              >
                                Previous
                              </span>
                              <span
                                className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                onClick={() =>
                                  this.setState({
                                    tooltipOpen: true,
                                    tooltip_name: 'pcr_tyre_report_table',
                                  })
                                }
                              >
                                Next
                              </span>
                            </div>
                          </PopoverBody>
                        </div>
                      </UncontrolledPopover>
                      {(role === null || role !== null) && (
                        <UncontrolledPopover
                          placement="right-start"
                          hideArrow={false}
                          isOpen={
                            this.state.tooltipOpen &&
                            this.state.tooltip_name === 'filter_target'
                          }
                          fade
                          target="filter_target"
                          trigger="focus"
                          className="warranty-report"
                        >
                          <div ref={(r) => (this.dealerPopOver = r)}>
                            <PopoverHeader className="popover-title d-flex justify-content-between">
                              <span>Help Guide Tour</span>
                              <span>{role === null ? '3/6' : '3/6'}</span>
                            </PopoverHeader>
                            <PopoverBody className="d-flex-column">
                              <span className="popover-lbl mt-2">
                                Introducing Filter
                              </span>
                              {/* <span className="popover-lbl mt-2">
                                Adding three fields for better understanding of the report</span> */}
                              <span className="popover-lbl-value mt-2">
                                Now warranty reports can be filtered from Year
                                to Month and that is also category wise
                                including sensor data.
                                <br /> <br />
                                Also category can be further filtered based on
                                minimum to maximum tyre count per warranty
                                registration. <br />
                              </span>
                              <br />
                              <div className="d-flex-row justify-content-between mb-2">
                                <span
                                  className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                  onClick={() =>
                                    this.setState({
                                      tooltipOpen: true,
                                      tooltip_name: 'sensor_total_card',
                                    })
                                  }
                                >
                                  Previous
                                </span>
                                <span
                                  className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                  onClick={this.setToolTip}
                                // () =>
                                // this.setState({
                                //   tooltipOpen: true,
                                //   tooltip_name:
                                //     "active_dealer_report_table",
                                // })
                                // }
                                >
                                  Next
                                </span>
                              </div>
                            </PopoverBody>
                          </div>
                        </UncontrolledPopover>
                      )}
                      <th
                        id="pcr_tyre_report_table"
                        className={
                          this.state.tooltipOpen &&
                            this.state.tooltip_name === 'pcr_tyre_report_table'
                            ? 'box-shadow-40px-0-5'
                            : ''
                        }
                      >
                        PCR Tyre
                      </th>
                      <UncontrolledPopover
                        placement="right-start"
                        hideArrow={false}
                        isOpen={
                          this.state.tooltipOpen &&
                          this.state.tooltip_name === 'pcr_tyre_report_table'
                        }
                        fade
                        target="pcr_tyre_report_table"
                        trigger="focus"
                        className="warranty-report"
                      >
                        <div ref={(r) => (this.dealerPopOver = r)}>
                          <PopoverHeader className="popover-title d-flex justify-content-between">
                            <span>Help Guide Tour</span>
                            <span>{role === null ? '5/6' : '5/6'}</span>
                          </PopoverHeader>
                          <PopoverBody className="d-flex-column">
                            <span className="popover-lbl mt-2">
                              Category Wise Tyres Count.
                            </span>
                            {/* <span className="popover-lbl mt-2">
                              Adding three fields for better understanding of the report</span> */}
                            <span className="popover-lbl-value mt-2">
                              Number of tyres sold under warranty for each
                              category <br />
                            </span>
                            <br />
                            <div className="d-flex-row justify-content-between mb-2">
                              <span
                                className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                onClick={() =>
                                  this.setState({
                                    tooltipOpen: true,
                                    tooltip_name:
                                      'active_dealer_report_table',
                                    // ? "filter_target"
                                    //  "warranty_total_card",
                                  })
                                }
                              >
                                Previous
                              </span>
                              <span
                                className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                onClick={() =>
                                  this.setState({
                                    tooltipOpen: true,
                                    tooltip_name:
                                      'two_wheeler_tyre_report_table',
                                  })
                                }
                              >
                                Next
                              </span>
                            </div>
                          </PopoverBody>
                        </div>
                      </UncontrolledPopover>
                      <th>2 Wheeler Tyre</th>
                      <UncontrolledPopover
                        placement="right-start"
                        hideArrow={false}
                        isOpen={
                          this.state.tooltipOpen &&
                          this.state.tooltip_name ===
                          'two_wheeler_tyre_report_table'
                        }
                        fade
                        target="two_wheeler_tyre_report_table"
                        trigger="focus"
                        className="warranty-report"
                      >
                        <div ref={(r) => (this.dealerPopOver = r)}>
                          <PopoverHeader className="popover-title d-flex justify-content-between">
                            <span>Help Guide Tour</span>
                            <span>{role === null ? '6/6' : '6/6'}</span>
                          </PopoverHeader>
                          <PopoverBody className="d-flex-column">
                            <span className="popover-lbl mt-2">
                              Sensors Registered.
                            </span>
                            {/* <span className="popover-lbl mt-2">
                              Adding three fields for better understanding of the report</span> */}
                            <span className="popover-lbl-value mt-2">
                              Number of sensors sold and registered under
                              warranty.
                            </span>
                            <br />
                            <div className="d-flex-row justify-content-between mb-2">
                              <span
                                className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                onClick={() =>
                                  this.setState({
                                    tooltipOpen: true,
                                    tooltip_name: 'pcr_tyre_report_table',
                                  })
                                }
                              >
                                Previous
                              </span>
                              <span
                                className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                onClick={() => {
                                  this.addHelpModuleScreen('warranty_report');
                                }}
                              >
                                Close
                              </span>
                            </div>
                          </PopoverBody>
                        </div>
                      </UncontrolledPopover>
                      <th>LCV</th>
                      <th>SCV</th>
                      <th
                        id="two_wheeler_tyre_report_table"
                        className={
                          this.state.tooltip_name ===
                            'two_wheeler_tyre_report_table'
                            ? 'box-shadow-40px-0-5'
                            : ''
                        }
                      >
                        Sensors Registered
                      </th>
                      <th>Warranty Points</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{prepareDealerTableRow}</tbody>
                </Table>
                {totalPages > 1 && (
                  <Pagination
                    margin={2}
                    page={this.state.page}
                    count={totalPages}
                    pageSize={pageSize}
                    totalRecords={totalDealers}
                    onPageChange={this.handlePageChange}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecondaryPointAllDealerReport;
