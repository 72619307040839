import React, { useState, useRef } from "react";
import { Col, FormGroup, Button } from "reactstrap";
import UserUpdateService from "../../services/UserUpdateService";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
const { ERROR_MESSAGE } = messages.COMMON;
const defaultControls = {
  upload_type: {
    value: "enrollVendor",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  selectedFile: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  fileName: "",
};
const downloadSampleFile = {
  enrollVendor:
    "https://s3.amazonaws.com/images.jktyrecrm.in/others/New+Vendor+Format.xlsx",
  vendorMaping:
    "https://s3.amazonaws.com/images.jktyrecrm.in/others/RMS+Vendor+Mapping+Format.xlsx",
};
function RMSVendorEnrollAndDealerMapping({ setLoading }) {
  const [controls, setControls] = useState({ ...defaultControls });
  const fileInputRef = useRef(null);

  const resetData = () => {
    const controlsModify = { ...controls };
    controlsModify.selectedFile.value = "";
    controlsModify.selectedFile.touched = false;
    controlsModify.selectedFile.showErrorMsg = false;
    controlsModify.fileName = "";
    fileInputRef.current.value = "";
    setControls(controlsModify);
  };

  const handleUploadTypeChange = (e) => {
    const controlsModify = { ...controls };
    let control = controlsModify[e.target.name];
    control.value = e.target.value;
    setControls(controlsModify);
    resetData();
  };

  const handleInputChange = (e) => {
    const controlsModify = { ...controls };
    const controlName = e.target.name;
    const controlValue = e.target.value;
    controlsModify[controlName].value = controlValue;
    controlsModify[controlName].touched = true;
    if (
      controlsModify[controlName].touched === true &&
      (controlsModify[controlName].value === "" ||
        controlsModify[controlName].value === null)
    ) {
      controlsModify[controlName].showErrorMsg = true;
    } else {
      controlsModify[controlName].showErrorMsg = false;
    }

    if (controlName === "selectedFile" && e.target.value) {
      controlsModify["fileName"] = e.target.value.name;
    }
    setControls(controlsModify);
  };

  const submit = () => {
    const controlsModify = { ...controls };
    if (!controls.selectedFile.value) {
      controlsModify.selectedFile.showErrorMsg = true;
      setControls(controlsModify);
      return;
    }
    const formData = new FormData();
    formData.append("file", controls.selectedFile.value);
    formData.append("uploadType", controls.upload_type.value);
    setLoading(true);
    UserUpdateService.uploadRMSVendorAndMapping(formData)
      .then((data) => {
        setLoading(false);
        if (data.data && data.data.message) {
          ModalService.openAlert("", data.data.message, "success");
        }
        resetData();
      })
      .catch((e) => {
        setLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  return (
    <>
      <Col>
        <div
          className="switch-container"
          style={{ width: "fit-content", marginLeft: "10px" }}
        >
          <div
            className={`switch-data ${controls.upload_type.value === "enrollVendor"
                ? "active-switch"
                : ""
              }`}
            onClick={() =>
              handleUploadTypeChange({
                target: {
                  name: "upload_type",
                  value: "enrollVendor",
                },
              })
            }
          >
            Enroll New Vendors
          </div>
          <div
            className={`switch-data ${controls.upload_type.value === "vendorMaping"
                ? "active-switch"
                : ""
              }`}
            onClick={() =>
              handleUploadTypeChange({
                target: {
                  name: "upload_type",
                  value: "vendorMaping",
                },
              })
            }
          >
            Update Dealer Mapping
          </div>
        </div>
      </Col>
      <Col xl="12" md="12" sm="12" style={{ display: "flex" }}>
        <FormGroup style={{ marginBottom: "0px", marginLeft: "12px" }}>
          <img
            src="images/download.svg"
            alt=""
            className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
          />
          <label
            style={{
              fontWeight: "bold",
              color: "#5665d8",
              "font-size": "12px",
              cursor: "pointer",
              marginBottom: "0.5rem",
            }}
            for="file"
          >
            {controls && controls.fileName ? (
              <span>{controls.fileName}</span>
            ) : (
              <span className="hover-download">Upload Excel</span>
            )}
          </label>
          <input
            style={{ display: "none", cursor: "pointer" }}
            id="file"
            type="file"
            accept=".xlsx"
            onChange={(e) =>
              handleInputChange({
                target: {
                  name: "selectedFile",
                  value: e.target.files[0],
                },
              })
            }
            className="width-100"
            ref={fileInputRef}
          />
          {controls && controls.selectedFile.showErrorMsg && (
            <div className="error">* Please choose file.</div>
          )}
        </FormGroup>
        <div
          className="link-warm-blue cursor-pointer"
          style={{ marginTop: "4px", marginLeft: "62px" }}
        >
          <a
            href={downloadSampleFile[controls.upload_type.value]}
            target="_blank"
            rel="noopener noreferrer"
            download
            style={{
              color: "#5665d8",
              "font-size": "12px",
              cursor: "pointer",
            }}
          >
            <img
              src="images/download.svg"
              alt=""
              className="download-report-icon cursor-pointer margin-left-10"
            />
            <label className="" style={{ cursor: "pointer" }}>
              Download Sample File
            </label>
          </a>
        </div>
      </Col>
      <Col xl="4" md="4" sm="12">
        <Button
          style={{
            width: "40%",
            marginLeft: "10px",
            marginTop: "10px",
          }}
          className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
          color="warning"
          onClick={submit}
        >
          Submit
        </Button>
      </Col>
    </>
  );
}

export default RMSVendorEnrollAndDealerMapping;
