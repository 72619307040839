import React, { Component, Fragment } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import * as Scroll from "react-scroll";
import "./AboutComponent.css";
import PcrPoints from "./PcrPoints";
import TruckBusPoints from "./TruckBusPoints";
import TwoThreeWheelerPoints from "./TwoThreeWheelerPoints";
import LcvScvPoints from "./LcvScvPoints";
import FarmPoints from "./FarmPoints";

//import Tabs, { TabPane } from 'rc-tabs';
//import TabContent from 'rc-tabs/lib/SwipeableTabContent';
//import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import AboutService from "../../services/AboutService";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import SecondaryAbout from "./SecondaryAbout";
import TermsAndConditions from "./TermsAndConditions";
import PointEarningProcess from "./PointEarningProcess";
import CustomSpinner from "../CustomSpinner/CustomSpinner";

export default class AboutComponent extends Component {
  aboutData = {
    enrollmentToTheProgram: [],
    pointStructure: [],
    allCategoryPoints: {},
    bonusPoints: [],
  };

  state = {
    activeTab: "1",
    showMore: true,
    transform: true,
    showInkBar: true,
    selectedTabKey: 0,
    transformWidth: 400,
    aboutData: null,
  };

  isMobile = false;

  isLogin = false;

  isShowMore = true;

  constructor(props) {
    super(props);
    this.state = {
      activeCategory: "PCR",
      activeTab: this.props.secondary ? "2" : "1",
      showMore: true,
      transform: true,
      showInkBar: true,
      selectedTabKey: 0,
      transformWidth: 400,
      pcrType: "multibrand",
      selectedYear: "",
      isLoading: false,
      customer_class_type: "multibrand",
      selectedYearData: null,
      dealer_type: '',
      NotByURL: false,
    };
    if (this.props.pcrType) {
      this.state.pcrType =
        this.props.pcrType === "other" || this.props.pcrType === 'am' || this.props.pcrType === 'employee'
          ? "multibrand"
          : this.props.pcrType;
      this.state.dealer_type = this.props.pcrType;
    }
    if (this.props.type) {
      this.state.dealer_type = this.props.type;
      this.state.NotByURL = true;
    }
    this.toggle = this.toggle.bind(this);
    if (AuthService.isAuthenticated()) {
      this.getUserDetail();
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getUserDetail() {
    UserService.getUserInfo()
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.customer_class_type
        ) {
          this.setState((prevState) => {
            return {
              ...prevState,
              pcrType:
                response.data.data.customer_class_type === "other"
                  ? "multibrand"
                  : response.data.data.customer_class_type,
              customer_class_type: response.data.data.customer_class_type,
              dealer_type: response.data.data.type,
              NotByURL: true
            };
          });
        }
        //let userData = response.data.data;
      })
      .catch((e) => {
        console.error("e", e);
      });
  }

  async getAboutDetail() {
    try {
      this.setState((prevState) => {
        return { ...prevState, isLoading: true };
      });
      const { data } = await AboutService.getAboutDetails();
      if (data) {
        this.allData = data.data;
        let index = this.allData.years.findIndex((y) => y.selected === true);
        let selectedYearData = this.allData.years[index];
        let aboutData = this.allData[selectedYearData.value];
        this.setState({ aboutData, selectedYearData });
      }

      this.setState((prevState) => {
        return { ...prevState, isLoading: false };
      });
    } catch (error) {
      this.setState((prevState) => {
        return { ...prevState, isLoading: false };
      });
      console.log(error);
    }
  }

  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }

  toggle = (tab) => {
    if (this.state.activeCategory !== tab) {
      this.setState({
        activeCategory: tab,
      });
      this.scrollTo();
    }
  };

  checkUserAgent() {
    this.isMobile = false;
    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this.isMobile = true;
      return "mobile";
    } else if (/Chrome/i.test(ua)) {
      return "chrome";
    } else {
      return "other";
    }
  }

  componentDidMount() {
    this.getAboutDetail();
    this.checkUserAgent();
    this.isLogin = AuthService.isAuthenticated();
  }

  showMore = (isShow) => {
    this.isShowMore = isShow;
    this.setState((prevState) => {
      return { ...prevState };
    });
  };

  scrollTo() {
    var scroller = Scroll.scroller;
    scroller.scrollTo("pasanger-car-redial-wrapp", {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80,
    });
  }

  changePcrType = (pcrType) => {
    this.setState({
      pcrType: pcrType,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    let years = this.allData.years;
    let index = years.findIndex((y) => y.value === e.target.value);
    let selectedYearData = years[index];
    const activeCategory = this.state.activeCategory;
    let aboutData = this.allData[selectedYearData.value];
    this.setState({ aboutData, selectedYearData, activeCategory: null }, () => {
      this.setState({ activeCategory });
    });
  };

  render() {
    let { aboutData = null, selectedYearData } = this.state;
    let arr = ["point-structure-card", "mr-2", "mb-3"];
    return (
      <Fragment>
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        {aboutData && (
          <div
            id="about-program"
            className={classnames({ "padding-15px-15px-0": !this.isLogin })}
          >
            <Col md="12" className="mb-3" style={{ paddingLeft: "0" }}>
              <div className="About-the-Program mb-3">About the Program</div>
              <div className="The-Advantage-progra">
                {/* {
                (this.isMobile && aboutData.aboutTheProgram && aboutData.aboutTheProgram.length >= 100) ?
                  <>
                    {
                      this.isShowMore ? <>{aboutData.aboutTheProgram.substring(0, 100)}...
                    <span className="show-more-copy" onClick={this.showMore.bind(this, false)}>show more</span></> : <>
                          {aboutData.aboutTheProgram}...<span className="show-more-copy" onClick={this.showMore.bind(this, true)}>show less</span>
                        </>
                    }
                  </> :
                  <>
                    {aboutData.aboutTheProgram}
                  </>
              } */}
                {aboutData.aboutTheProgram}
              </div>
            </Col>
            <hr style={{ border: "solid 1px #e0e2e4" }} />
            <Row>
              <Col sm="9"></Col>
              <Col sm="3" className="set-right-dropdown">
                <span className="fy-label">FY</span>
                <span>
                  {" "}
                  {selectedYearData && (
                    <select
                      onChange={this.handleInputChange}
                      value={selectedYearData.value}
                      className="form-control text-capitalize mb-1"
                    >
                      {this.allData &&
                        this.allData.years.map((y) => (
                          <option
                            value={y.value}
                            defaultChecked={y.selected}
                            onChange={() => this.handleInputChange(y)}
                          >
                            {y.key}
                          </option>
                        ))}
                    </select>
                  )}
                </span>
              </Col>
            </Row>
            <Card tabs="true" className="mb-3 about-tab-header">
              <CardHeader className="card-header-tab">
                <Nav style={{ marginLeft: "0" }} className="about-main-tab">
                  <NavItem>
                    <NavLink
                      href="javascript:void(0);"
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      Earn {this.isMobile ? "Pts" : "Points"} On Offtake
                    </NavLink>
                  </NavItem>
                  {
                    aboutData && aboutData.secondaryPointsAndOfftake
                    // selectedYearData.value !== '2023-24'
                    && <NavItem>
                      <NavLink
                        href="javascript:void(0);"
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Secondary {this.isMobile ? "Pts" : "Points"} On Warranty
                      </NavLink>
                    </NavItem>}
                  <NavItem>
                    <NavLink
                      href="javascript:void(0);"
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggleTab("3");
                      }}
                    >
                      Terms & Conditions
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Col md="12" style={{ paddingLeft: "0" }}>
                      <div className="About-the-Program mb-3">
                        {aboutData.headerTitle}
                      </div>
                      <div>
                        <ul className="Enrollment-ul">
                          {aboutData.enrollmentToTheProgram.map &&
                            aboutData.enrollmentToTheProgram.map((item) => (
                              <li className="enroll-li">{item}</li>
                            ))}
                        </ul>
                      </div>
                    </Col>
                    <Col md="12">
                      <div
                        className="About-the-Program mb-3"
                        style={{ marginLeft: "-10px" }}
                      >
                        Point Structure
                      </div>
                      <Row
                        className="point-structure-wrapp"
                        style={{ flexWrap: "nowrap" }}
                      >
                        {aboutData.pointStructure.map((item, i) => (
                          <Col
                            className={classnames(
                              {
                                activeCategory:
                                  this.state.activeCategory === item.type,
                              },
                              arr
                            )}
                            style={{ paddingRight: "0px" }}
                            onClick={() => {
                              this.toggle(item.type);
                            }}
                          >
                            <div className="point-structure-type-wrapp">
                              <span className="point-structure-type">
                                {item.type}
                              </span>
                              {item.image1 && (
                                <img
                                  alt=""
                                  src={item.image1}
                                  className="point-structure-img"
                                />
                              )}
                            </div>
                            <div className="point-structure-data">
                              <span className="Tyre">Points Range / Tyre</span>
                              <div className="point-structure-point-wrapp">
                                <div className="d-flex-row">
                                  <span className="point-structure-point">
                                    {item.pointsRange[0]}
                                  </span>
                                  &nbsp;&nbsp;
                                  <span className="point-structure-point">
                                    to
                                  </span>
                                  &nbsp;&nbsp;
                                  <span className="point-structure-point">
                                    {item.pointsRange[1]}
                                  </span>
                                </div>
                                {item.image2 && (
                                  <img
                                    alt=""
                                    src={item.image2}
                                    className="point-structure-img"
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col
                      lg="12"
                      className="pasanger-car-redial-col"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <div className="pasanger-car-redial-card">
                        <div
                          className="pasanger-car-redial-wrapp"
                          id="pasanger-car-redial-wrapp"
                        >
                          {this.state.activeCategory === "TRUCKS / BUS" &&
                            aboutData.allCategoryPoints &&
                            aboutData.allCategoryPoints
                              .truckBusPointsPoints && (
                              <>
                                {" "}
                                <TruckBusPoints
                                  truckBusPointsPoints={
                                    aboutData.allCategoryPoints
                                      .truckBusPointsPoints
                                  }
                                />
                                <PointEarningProcess
                                  pointsEarningProcess={
                                    aboutData.pointsEarningProcess
                                  }
                                />
                              </>
                            )}
                          {this.state.activeCategory === "SCV / LCV" && (
                            <>
                              <LcvScvPoints
                                lcvScvPoints={
                                  aboutData.allCategoryPoints.lcvScvPoints
                                }
                              />
                              <br />
                              <PointEarningProcess
                                pointsEarningProcess={
                                  aboutData.pointsEarningProcess
                                }
                              />
                            </>
                          )}
                          {this.state.activeCategory === "PCR" &&
                            aboutData.allCategoryPoints.pcrPoints && (
                              <>
                                <PcrPoints
                                  pcrPoints={
                                    aboutData.allCategoryPoints.pcrPoints
                                  }
                                  pcrType={this.state.pcrType}
                                  changePcrType={this.changePcrType}
                                  dealer_type={this.state.dealer_type}
                                  selectyear={selectedYearData.value}
                                  notByUrl={this.state.NotByURL}
                                />
                                <br />
                                <br />
                                <PointEarningProcess
                                  pointsEarningProcess={
                                    aboutData.pointsEarningProcess
                                  }
                                />
                              </>
                            )}
                          {this.state.activeCategory === "2 and 3 WHEELER" && (
                            <>
                              <TwoThreeWheelerPoints
                                twoThreeWheelerPonits={
                                  aboutData.allCategoryPoints
                                    .twoThreeWheelerPonits
                                }
                              />

                              <PointEarningProcess
                                pointsEarningProcess={
                                  aboutData.pointsEarningProcess
                                }
                              />
                            </>
                          )}
                          {this.state.activeCategory === "FARM" && (
                            <>
                              <FarmPoints
                                farmPoints={
                                  aboutData.allCategoryPoints.farmPoints
                                }
                              />

                              <PointEarningProcess
                                pointsEarningProcess={
                                  aboutData.pointsEarningProcess
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  </TabPane>
                  {
                    // selectedYearData.value !== '2023-24'
                    aboutData && aboutData.secondaryPointsAndOfftake
                    && <TabPane tabId="2">
                      {aboutData && aboutData.secondaryPointsAndOfftake && (
                        <SecondaryAbout
                          pointsEarningProcessSecondary={
                            aboutData.pointsEarningProcessSecondary
                          }
                          secondaryPointsAndOfftake={
                            aboutData.secondaryPointsAndOfftake || null
                          }
                        />
                      )}
                    </TabPane>}
                  <TabPane tabId="3">
                    {aboutData.termsAndCondtions && (
                      <TermsAndConditions
                        termsAndCondtions={aboutData.termsAndCondtions}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
            {/* <Tabs
          className="about-tab"
          defaultActiveKey="0"
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent />}
        >
          <TabPane tab='Primary Points and Offtake' key="0">
            <Col md="12" style={{ paddingLeft: '0' }}>
              <div className="About-the-Program mb-3">
                Enrollment to the Program
                    </div>
              <div>
                <ul className="Enrollment-ul">
                  {
                    aboutData.enrollmentToTheProgram.map && aboutData.enrollmentToTheProgram.map((item) => (
                      <li className="enroll-li">
                        {item}
                      </li>
                    ))
                  }
                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="About-the-Program mb-3" style={{ marginLeft: '-10px' }}>
                Point Structure
                    </div>
              <Row className="point-structure-wrapp" style={{ flexWrap: 'wrap' }}>
                {
                  aboutData.pointStructure.map((item, i) => (
                    <Col className={classnames({ activeCategory: this.state.activeCategory === item.type }, arr)} style={{ paddingRight: '0px' }} onClick={() => { this.toggle(item.type); }}>
                      <div className="point-structure-type-wrapp">
                        <span className="point-structure-type">
                          {item.type}
                        </span>
                        {
                          item.image1 && <img alt='' src={item.image1} className="point-structure-img" />
                        }
                      </div>
                      <div className="point-structure-data">
                        <span className="Tyre">Points Range / Tyre</span>
                        <div className="point-structure-point-wrapp">
                          <div className="d-flex-row">
                            <span className="point-structure-point">
                              {item.pointsRange[0]}
                            </span>
                            &nbsp;&nbsp;
                                            <span className="point-structure-point">to</span>&nbsp;&nbsp;
                                            <span className="point-structure-point">
                              {item.pointsRange[1]}
                            </span>
                          </div>
                          {
                            item.image2 && <img alt='' src={item.image2} className="point-structure-img" />
                          }
                        </div>
                      </div>
                    </Col>
                  ))
                }
              </Row>
             
            </Col>
            <Col lg="12" className="pasanger-car-redial-col" style={{ paddingLeft: '0' }}>
              <div className="pasanger-car-redial-card">
                <div className="pasanger-car-redial-wrapp">
                  {
                    this.state.activeCategory === 'TRUCKS / BUS' && aboutData.allCategoryPoints && aboutData.allCategoryPoints.truckBusPointsPoints && <TruckBusPoints truckBusPointsPoints={aboutData.allCategoryPoints.truckBusPointsPoints} />
                  }
                  {
                    this.state.activeCategory === 'SCV / LCV' && <LcvScvPoints lcvScvPoints={aboutData.allCategoryPoints.lcvScvPoints} />
                  }
                  {
                    this.state.activeCategory === 'PCR' && <PcrPoints pcrPoints={aboutData.allCategoryPoints.pcrPoints} />
                  }
                  {
                    this.state.activeCategory === '2 and 3 WHEELER' && <TwoThreeWheelerPoints twoThreeWheelerPonits={aboutData.allCategoryPoints.twoThreeWheelerPonits} />
                  }
                  {
                    this.state.activeCategory === 'FARM' && <FarmPoints farmPoints={aboutData.allCategoryPoints.farmPoints} />
                  }
                </div>
              </div>
            </Col>
          </TabPane>
          <TabPane tab='Secondary Points and Offtake' key="1">
            {aboutData.secondaryPointsAndOfftake && <SecondaryAbout secondaryPointsAndOfftake={aboutData.secondaryPointsAndOfftake} />}
          </TabPane>
        </Tabs> */}
          </div>
        )}
      </Fragment>
    );
  }
}
