import firebase from 'firebase';
import UserService from './services/UserService';
import Storage from './services/Storage';

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    UserService.saveToken({ token });
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const onReceiveNotifications = registration => {
  try {
    const messaging = firebase.messaging();
    messaging.onMessage(payload => {
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: payload.data.icon,
        data: payload.data.business,
        actions: [
          {
            action: payload.data.click_action,
            title: payload.data.title
          }
        ]
      };
      registration.showNotification(notificationTitle, notificationOptions);
    });
  } catch (error) {
    console.error(error);
  }
};

export const initializeFirebase = () => {
  if (!firebase.apps.length) {
    let fireBaseConfig = {};
    if (process.env.REACT_APP_ENV === 'prod') {
      fireBaseConfig = {
        apiKey: 'AIzaSyAbuj2Y8LUs4kw1pi-3yK9ZSXXmZo7joS0',
        authDomain: 'jkadvantage2.firebaseapp.com',
        databaseURL: 'https://jkadvantage2.firebaseio.com',
        projectId: 'jkadvantage2',
        storageBucket: 'jkadvantage2.appspot.com',
        messagingSenderId: '958370112480',
        appId: '1:958370112480:web:75306acea635e1f5aa0185',
        measurementId: 'G-8CTVRDY5TF'
      };
    } else {
      fireBaseConfig = {
        apiKey: 'AIzaSyAJFX7qHvvCMJaY_JT9v5t74X-4qpkVHII',
        authDomain: 'advantage-production.firebaseapp.com',
        databaseURL: 'https://advantage-production.firebaseio.com',
        projectId: 'advantage-production',
        storageBucket: 'advantage-production.appspot.com',
        messagingSenderId: '769998336485',
        appId: '1:769998336485:web:56a5546c1240aeeb5eff0c'
      };
    }
    firebase.initializeApp(fireBaseConfig);
  }

  if (firebase.messaging.isSupported()) {
    try {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("./firebase-messaging-sw.js", {
            updateViaCache: "none"
          })
          .then(registration => {
            firebase.messaging().useServiceWorker(registration);
            onReceiveNotifications(registration);
          });

        navigator.serviceWorker.addEventListener('message', function (event) {
          Storage.setNotificationDetail(event.data);
          // if (window.location.href && window.location.href.indexOf('dev.jkadvantage.co') > -1) {
          //   window.location.replace("https://dev.jkadvantage.co.in/#/notification-page");
          // } else {
          //   window.open("https://dev.jkadvantage.co.in/#/notification-page", "_blank");
          // }
          // debugger
          // try {
          //   if (window.location.href && window.location.href.indexOf('localhost') > -1) {
          //     window.location.replace("http://localhost:3000/#/notification-page");
          //   } else {
          //     window.open("http://localhost:3000/#/notification-page", "_blank");
          //   }
          // } catch (error) {
          //   window.open("http://localhost:3000/#/notification-page", "_blank");
          // }

        });
      }
    } catch (error) {
      console.error(error);
    }
  }



};
