import React, { Component } from 'react';
import {
  Button,
  FormGroup, Label,
  Row, Col,
  Card, CardBody,
} from 'reactstrap';
import ProposalSerivice from '../../services/ProposalSerivice';
import Validation from '../../services/Validation';
import swal from 'sweetalert';
import TextareaAutosize from 'react-textarea-autosize';

class ProposalSatusUpdate extends Component {

  constructor(props) {
    super(props)
    this.state = {
      controls: {
        "stage": {
          "value": "",
          "valid": null,
          "touched": false,
          "nullValue": null,
          "invalidEmail": null,
          "required": true,
          "showErrorMsg": false,
          "autoFocus": false
        },
        "remark": {
          "value": "",
          "valid": null,
          "touched": false,
          "nullValue": null,
          "invalidEmail": null,
          "required": true,
          "showErrorMsg": false,
          "autoFocus": false
        },
        "architect": {
          "value": "",
          "valid": null,
          "touched": false,
          "nullValue": null,
          "invalidEmail": null,
          "required": true,
          "showErrorMsg": false,
          "autoFocus": false
        },
        "agency_type": {
          "value": "all",
          "valid": null,
          "touched": false,
          "nullValue": null,
          "invalidEmail": null,
          "required": true,
          "showErrorMsg": false,
          "autoFocus": false
        },
        "proposal_status": {
          "value": "",
          "valid": null,
          "touched": false,
          "nullValue": null,
          "invalidEmail": null,
          "required": true,
          "showErrorMsg": false,
          "autoFocus": false
        },
        "outlet_type": 'PTP',
        isFreshProposal: false
      },
      isFormValid: false,
      architectData: []
    }
    this.state.proposalStageStatus = props.proposalStageStatus;
  }

  componentDidMount() {
    this.handleArchitectChange();
    if (this.props.proposalStageStatus && Object.keys(this.props.proposalStageStatus).length) {
      let { controls } = this.state;
      controls.stage.value = this.props.proposalStageStatus.project_status !== 'Fresh Proposal' ? this.props.proposalStageStatus.project_status : '';
      controls.isFreshProposal = this.props.proposalStageStatus.project_status === 'Fresh Proposal' ? true : false;
      controls.outlet_type = this.props.proposalStageStatus.outlet_type;
      this.setState((prevState) => {
        return {
          ...prevState, controls
        }
      });
    }
  }

  setData = () => {
    if (this.props.proposalStageDetail && this.props.proposalStageDetail.recci_data &&
      this.props.proposalStageDetail.proposal_status.length > 0) {
      let { controls } = this.state;
      controls.stage.value = this.props.proposalStageDetail.proposal_status[0].project_status !== 'Fresh Proposal' ? this.props.proposalStageDetail.proposal_status[0].project_status : '';
      controls.isFreshProposal = this.props.proposalStageDetail.proposal_status[0].project_status === 'Fresh Proposal' ? true : false;
      controls.outlet_type = this.props.proposalStageDetail.proposal_status[0].outlet_type;
      this.setState((prevState) => {
        return {
          ...prevState, controls
        }
      });
    }
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
    if (controlName === 'agency_type' && controlValue) {
      // this.handleArchitectChange();
    }
  }

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let {
      stage, remark
    } = controls;
    if (stage.touched === true || isSubmit) {
      stage = Validation.notNullValidator(stage);
      stage.valid = !stage.nullValue;
      if (stage.valid === false) {
        stage.showErrorMsg = true;
      } else {
        stage.showErrorMsg = false;
      }
    }

    if ((remark.touched === true || isSubmit) && controls.stage.value !== 'Proposal accepted') {
      remark = Validation.notNullValidator(remark);
      remark.valid = !remark.nullValue;
      if (remark.valid === false) {
        remark.showErrorMsg = true;
      } else {
        remark.showErrorMsg = false;
      }
    } else {
      remark.valid = true;
    }

    if (
      stage.valid === true &&
      remark.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  }

  handleArchitectChange = (e) => {
    if (e) {
      this.handleInputChange(e);
    }
    let { controls } = this.state;
    ProposalSerivice.getArchitect(controls.agency_type.value)
      .then(res => {
        if (res.data.data && res.data.data.length > 0) {
          let theFormation = res.data.data.filter(x => x.name && x.name.toLowerCase() === 'the formation');
          if (theFormation && theFormation.length > 0) {
            controls.architect.value = theFormation[0].id;
          }
          this.setState((prevState) => {
            return {
              ...prevState, architectData: res.data.data,
              controls
            }
          });
        } else {
          this.setState((prevState) => {
            return { ...prevState, architectData: [] }
          });
        }
      })
      .catch(e => {

        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  updateStageStatus = (e) => {
    let { controls } = this.state;
    if (this.handleValidation(true)) {
      const reqData = {};
      if (controls.stage.value) {
        reqData.stage = controls.stage.value;
      }
      if (controls.remark.value) {
        reqData.remark = controls.remark.value;
      }
      if (controls.architect.value) {
        reqData.architect = +controls.architect.value;
      }
      if (controls.proposal_status.value) {
        reqData.proposal_status = controls.proposal_status.value;
      }
      if (controls.outlet_type === 'PTP' && controls.stage.value === 'Proposal accepted') {
        reqData.currentStage = 'Proposal Accepted';
        reqData.newStage = 'Upload Agreement';
      } else if (controls.stage.value === 'Proposal accepted') {
        //reqData.currentStage= 'Proposal Accepted';
        reqData.newStage = 'Recci in progress';
      }
      reqData.uuid = this.state.proposalStageStatus.uuid;
      ProposalSerivice.updateProposalStage(reqData)
        .then(res => {
          this.setState({ isLoading: false });
          swal({
            title: '',
            text: res.data.message,
            icon: 'success',
            type: 'success',
          }).then(value => {
            let projectStagetFilter = ProposalSerivice.getFilterProjectStage();
            if (projectStagetFilter && Object.keys(projectStagetFilter).length > 0) {
              // projectStagetFilter.project_status=controls.stage.value;
              // if(controls.stage.value === 'Proposal accepted' && 
              // projectStagetFilter.stage === 'Recci in progress'){
              //   projectStagetFilter.stage = 'Layout & agreement';
              // }
              // ProposalSerivice.setFilterInProjectStage(projectStagetFilter)
              projectStagetFilter.project_status = controls.stage.value;
              ProposalSerivice.setFilterInProjectStage(projectStagetFilter);
            } else {
              ProposalSerivice.setFilterInProjectStage({
                project_status: controls.stage.value
              })
            }
            this.props.goToPrevPage(true);
          });
        })
        .catch(e => {

          // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
          // const message = err && err.message ? err.message : ERROR_MESSAGE;
          // this.setState({ isLoading: false });
        });
    }
  }

  viewDetail = (e) => {
    this.props.openViewCloseViewDetail();
    //this.props.redirectViewProposal(this.state.proposalStageStatus.uuid);
  }

  goToPrevPage() {
    this.props.goToPrevPage();
  }


  render() {
    const { proposalStageDetail } = this.props;

    const { proposal_status } = proposalStageDetail ? proposalStageDetail : {
      proposal_status: []
    };
    const { controls } = this.state;
    return (
      proposalStageDetail.recci_data.length <= 0 &&
      <>
        <Card >
          <CardBody>
            {/* Dealership Details */}
            <Row className="dealership-details-title-wrapp">
              <Col xl="6" md="6" xs="6">
                <b>Proposal Status</b>
              </Col>
              {/* <div>
                <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.goToPrevPage()}>Back</Button>
              </div> */}
            </Row>
            <hr />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Proposal Status<span className="required-field">*</span></Label>
                  <select className="form-control select-height-70rem" name="stage" value={controls.stage.value}
                    onChange={(e) => this.handleInputChange(e)}>
                    <option value="" selected disabled>Select proposal status</option>
                    <option value="Proposal accepted">Proposal accepted</option>
                    <option value="Modification needed">Modification needed</option>
                    <option value="Proposal rejected">Proposal rejected</option>
                  </select>
                  {controls.stage.showErrorMsg && <div className="error">* Please select proposal stage.</div>}
                </FormGroup>
              </Col>
              {
                proposal_status && proposal_status.length > 0 && proposal_status[0].outlet_type !== 'PTP' ?
                  <>
                    {/* {
                controls.stage.value !== 'Proposal accepted' && 
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Agency Type</Label>
                  <select className="form-control select-height-70rem" name="agency_type" value={controls.agency_type.value}
                    onChange={(e) => this.handleInputChange(e)}>
                    <option value="" selected disabled>Select agency type</option>
                    <option value="Interior design">Interior design</option>
                    <option value="Exterior design">Exterior design</option>
                  </select>
                </FormGroup>
              </Col>
              } */}
                    {
                      controls.stage.value === 'Proposal accepted' &&
                      <Col xl="3" md="12" xs="12">
                        <FormGroup>
                          <Label>Architect</Label>
                          <select className="form-control select-height-70rem" name="architect" value={controls.architect.value}
                            onChange={(e) => this.handleInputChange(e)} disabled={true}>
                            <option value="">Select agency type</option>
                            {
                              this.state.architectData && this.state.architectData.map((item, i) => (
                                <option value={item.id}>{item.name}</option>
                              ))
                            }
                          </select>
                        </FormGroup>
                      </Col>
                    }
                  </> : ""
              }
              {/* <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Project Status</Label>
                  <select className="form-control select-height-70rem" name="proposal_status"
                    value={controls.proposal_status.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option value="">Select project Status</option>
                    <option value="Active">Active</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Dropped">Dropped</option>
                  </select>
                </FormGroup>
              </Col> */}
              <Col xl="5" md="12" xs="12">
                <FormGroup>
                  <Label>Remark{controls.stage.value !== 'Proposal accepted' && <span className="required-field">*</span>}</Label>
                  <TextareaAutosize
                    name="remark"
                    autocomplete="nofill"
                    id="remark"
                    value={controls.remark.value}
                    style={{ minHeight: 60, maxHeight: 70, fontSize: '14px' }}
                    className="form-control"
                    onChange={(e) => this.handleInputChange({ target: { name: 'remark', value: e.target.value } })}
                    placeholder=''
                  />
                  {controls.stage.value !== 'Proposal accepted' && controls.remark.showErrorMsg && <div className="error">* Please enter remark.</div>}
                </FormGroup>
              </Col>
            </Row>
            <Row>

              <Col xl="3" md="12" xs="12" style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                {
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    style={{ float: 'right' }}
                    disabled={this.props.is_display_view_deatil}
                    onClick={(e) => this.updateStageStatus(e)}
                  >
                    Submit
                  </Button>
                }&nbsp;&nbsp;

                <Button
                  className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                  color="warning"
                  type="submit"
                  style={{ float: 'right' }}
                  onClick={(e) => this.viewDetail(e)}
                >
                  {this.props.is_display_view_deatil ? 'Hide Detail' : 'View Detail'}
                </Button>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </>
    );
  }
}

export default ProposalSatusUpdate;
