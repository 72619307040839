import React, { Component } from 'react';
import { Col } from 'reactstrap';
class TermsAndConditions extends Component {
  render() {
    let termsAndCondtions = this.props.termsAndCondtions;
    return (
      <>
        <Col md="12" style={{ paddingLeft: '0' }}>
        <div className="Point-Structure-for mb-2">
            {termsAndCondtions.title}
          </div>
          <div>
            <ul className="Enrollment-ul">
              {
                termsAndCondtions.data && termsAndCondtions.data.map((item) => (
                  <li className="enroll-li">
                    {item}
                  </li>
                ))
              }
            </ul>
          </div>
        </Col>
      </>
    );
  }
}

export default TermsAndConditions;