import React, { Component } from "react";
import { Button, Col, Table } from "reactstrap";
import "./ProposalDashboard.css";
import ProposalSerivice from "../../services/ProposalSerivice";
import Storage from "../../services/Storage";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { Roles } from "../../constants/constant.role";
class ProposalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steelWheelState: [
        {
          id: "recci_in_progress",
          name: "Recci in progress",
          counts: 0,
        },
        {
          id: "layout_agreement",
          name: "Layout & agreement",
          counts: 0,
        },
        {
          id: "estimate_needed",
          name: "Estimate needed",
          counts: 0,
        },
        {
          id: "Dealer_work_in_progress",
          name: "Dealer work in progress",
          counts: 0,
        },
        {
          id: "Agency_work_in_progress",
          name: "Agency work in progress",
          counts: 0,
        },
        {
          id: "Hand Over",
          name: "Hand Over",
          counts: 0,
        },
        {
          id: "Pre inauguration",
          name: "Pre inauguration",
          counts: 0,
        },
        {
          id: "Inaugurated",
          name: "Inaugurated",
          counts: 0,
        },
        {
          id: "Completed",
          name: "Project completed",
          counts: 0,
        },
      ],
      xpressWheelState: [
        {
          id: "recci_in_progress",
          name: "Recci in progress",
          counts: 0,
        },
        {
          id: "layout_agreement",
          name: "Layout & agreement",
          counts: 0,
        },
        {
          id: "Dealer_work_in_progress",
          name: "Dealer work in progress",
          counts: 0,
        },
        {
          id: "Agency_work_in_progress",
          name: "Agency work in progress",
          counts: 0,
        },
        {
          id: "Hand Over",
          name: "Hand Over",
          counts: 0,
        },
        {
          id: "Pre inauguration",
          name: "Pre inauguration",
          counts: 0,
        },
        {
          id: "Inaugurated",
          name: "Inaugurated",
          counts: 0,
        },
        {
          id: "Completed",
          name: "Project completed",
          counts: 0,
        },
      ],
      farmXpressWheelState: [
        {
          id: "recci_in_progress",
          name: "Recci in progress",
          counts: 0,
        },
        {
          id: "layout_agreement",
          name: "Layout & agreement",
          counts: 0,
        },
        {
          id: "Dealer_work_in_progress",
          name: "Dealer work in progress",
          counts: 0,
        },
        {
          id: "Agency_work_in_progress",
          name: "Agency work in progress",
          counts: 0,
        },
        {
          id: "Hand Over",
          name: "Hand Over",
          counts: 0,
        },
        {
          id: "Pre inauguration",
          name: "Pre inauguration",
          counts: 0,
        },
        {
          id: "Inaugurated",
          name: "Inaugurated",
          counts: 0,
        },
        {
          id: "Completed",
          name: "Project completed",
          counts: 0,
        },
      ],
      ptpState: [
        {
          id: "Proposal Accepted",
          name: "Proposal Accepted",
          counts: 0,
          completed_date: null,
        },
        {
          id: "Upload Agreement",
          name: "Upload Agreement",
          counts: 0,
          completed_date: null,
        },
        {
          id: "Agency Allocation",
          name: "Agency Allocation",
          counts: 0,
          completed_date: null,
        },
        {
          id: "Branding In Progress",
          name: "Branding In Progress",
          counts: 0,
          completed_date: null,
        },
        {
          id: "Branding Completed",
          name: "Branding Completed",
          counts: 0,
          completed_date: null,
        },
        {
          id: "Pre inauguration",
          name: "Pre inauguration",
          counts: 0,
        },
        {
          id: "Inaugurated",
          name: "Inaugurated",
          counts: 0,
        },
        {
          id: "Completed",
          name: "Project completed",
          counts: 0,
        },
      ],
      holdDropedState: [
        {
          id: "On Hold",
          name: "On Hold",
          count: 1,
        },
        {
          id: "Dropped",
          name: "Dropped",
          count: 1,
        },
      ],
      by_project_status: [],
      by_hold_dropped: [],
      isLoading: false,
      userDetail: Storage.getUserDetail(),
      isArchitect: false,
      isBrandStore: false,
    };
    if (
      this.state.userDetail.type.toLocaleLowerCase() === Roles.ARCHITECT_ROLE ||
      this.state.userDetail.type.toLocaleLowerCase() === Roles.AGENCY_ROLE
    ) {
      this.state.isArchitect = true;
    }
    if (this.state.userDetail.type.toLocaleLowerCase() === Roles.BRAND_STORE_ROLE) {
      this.state.isBrandStore = true;
    }
  }
  redirectNewProposal = () => {
    this.props.history.push("proposal-details");
  };

  redirectProjectStage = (reqData, count = 0) => {
    if (!count && count <= 0) {
      return;
    }
    if (reqData && Object.keys(reqData).length > 0) {
      ProposalSerivice.setFilterInProjectStage(reqData);
    }
    this.props.history.push(`projects-stage`);
  };

  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }

  getDashboradCount = () => {
    this.setState({ isLoading: true });
    ProposalSerivice.getDashboardCounts()
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          let steelWheelState = this.state.steelWheelState;
          let xpressWheelState = this.state.xpressWheelState;
          let farmXpressWheelState = this.state.farmXpressWheelState;
          let ptpState = this.state.ptpState;
          if (
            res.data.data[0].by_project_stage &&
            res.data.data[0].by_project_stage.length > 0
          ) {
            res.data.data[0].by_project_stage.forEach((element) => {
              if (element.outlet_type.toLowerCase() === "steel wheel") {
                element.stage.forEach((stageElement) => {
                  if (stageElement.stage) {
                    let index = this.state.steelWheelState.findIndex(
                      (x) =>
                        x.name.toLowerCase() ===
                        stageElement.stage.toLowerCase()
                    );
                    if (index >= 0) {
                      steelWheelState[index].counts = stageElement.counts;
                    }
                  }
                });
              }

              if (element.outlet_type === "Xpress Wheel") {
                element.stage.forEach((stageElement) => {
                  if (stageElement.stage) {
                    let index = this.state.xpressWheelState.findIndex(
                      (x) =>
                        x.name.toLowerCase() ===
                        stageElement.stage.toLocaleLowerCase()
                    );
                    if (index >= 0) {
                      xpressWheelState[index].counts = stageElement.counts;
                    }
                  }
                });
              }

              if (element.outlet_type === "Farm Xpress Wheel") {
                element.stage.forEach((stageElement) => {
                  if (stageElement.stage) {
                    let index = this.state.farmXpressWheelState.findIndex(
                      (x) =>
                        x.name.toLowerCase() ===
                        stageElement.stage.toLocaleLowerCase()
                    );
                    if (index >= 0) {
                      farmXpressWheelState[index].counts = stageElement.counts;
                    }
                  }
                });
              }

              if (element.outlet_type === "PTP") {
                element.stage.forEach((stageElement) => {
                  let index = -1;
                  if (stageElement.stage) {
                    index = ptpState.findIndex(
                      (x) =>
                        x.id.toLowerCase() ===
                        stageElement.stage.toLocaleLowerCase()
                    );
                  }
                  if (index > -1) {
                    ptpState[index].counts = stageElement.counts;
                  }
                });
              }
            });
          }
          this.setState((prevState) => {
            return {
              ...prevState,
              steelWheelState: steelWheelState,
              by_project_status: res.data.data[0].by_project_status
                ? res.data.data[0].by_project_status
                : [],
              by_hold_dropped: res.data.data[0].by_hold_dropped,
              isLoading: false,
            };
          });
        }
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  };
  componentDidMount() {
    this.getDashboradCount();
  }
  render() {
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <div className="header-title">Proposal Dashboard</div>
        <br />
        <Col
          lg="12"
          className="pasanger-car-redial-col"
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >
          <div className="pasanger-car-redial-card">
            <div
              className="pasanger-car-redial-wrapp"
              id="pasanger-car-redial-wrapp"
              style={{ flexDirection: "column" }}
            >
              {(this.state.userDetail.access_level.toLowerCase() === "area" ||
                this.state.userDetail.access_level.toLowerCase() === "all") && (
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button btn-new-proposal"
                    color="warning"
                    type="submit"
                    onClick={() => this.redirectNewProposal()}
                  >
                    New proposal
                  </Button>
                )}
              {this.state.by_project_status &&
                this.state.by_project_status.length > 0 && (
                  <>
                    <br />
                    <Table
                      id="table"
                      className="mb-0 table-responsive tbl-about tbl-about"
                    >
                      <thead>
                        <tr>
                          <th>Project Status</th>
                          <th>Total Count</th>
                          <th>Steel Wheels</th>
                          <th>Xpress Wheels</th>
                          <th>Farm Xpress Wheels</th>
                          {this.state.userDetail.access_level === "area" ||
                            this.state.userDetail.access_level === "agency" ||
                            this.state.userDetail.access_level === "all" ||
                            this.state.userDetail.access_level === "ztm" ||
                            this.state.userDetail.access_level === "zone" ? (
                            <th>PTP</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.by_project_status.map((item, i) =>
                          this.state.isArchitect ? (
                            item.project_status &&
                            item.project_status.toLocaleLowerCase() ===
                            "proposal accepted" && (
                              <tr>
                                <td>{item.project_status}</td>
                                <td
                                  className={
                                    item.total
                                      ? "redirect-link text-center cusror-pointer"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.redirectProjectStage(
                                      {
                                        project_status: item.project_status,
                                        proposal_status: "Active",
                                      },
                                      item.total
                                    )
                                  }
                                >
                                  {item.total}
                                </td>
                                <td
                                  className={
                                    item.steel_wheel
                                      ? "redirect-link text-center cusror-pointer"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.redirectProjectStage(
                                      {
                                        project_status: item.project_status,
                                        outlet: "Steel Wheel",
                                        proposal_status: "Active",
                                      },
                                      item.steel_wheel
                                    )
                                  }
                                >
                                  {item.steel_wheel}
                                </td>
                                <td
                                  className={
                                    item.xpress_wheel
                                      ? "redirect-link text-center cusror-pointer"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.redirectProjectStage(
                                      {
                                        project_status: item.project_status,
                                        outlet: "Xpress Wheel",
                                        proposal_status: "Active",
                                      },
                                      item.xpress_wheel
                                    )
                                  }
                                >
                                  {item.xpress_wheel}
                                </td>
                                <td
                                  className={
                                    item.farm_xpress_wheel
                                      ? "redirect-link text-center cusror-pointer"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.redirectProjectStage(
                                      {
                                        project_status: item.project_status,
                                        outlet: "Farm Xpress Wheel",
                                        proposal_status: "Active",
                                      },
                                      item.farm_xpress_wheel
                                    )
                                  }
                                >
                                  {item.farm_xpress_wheel}
                                </td>
                                {this.state.userDetail.access_level ===
                                  "area" ||
                                  this.state.userDetail.access_level ===
                                  "agency" ||
                                  this.state.userDetail.access_level === "all" ||
                                  this.state.userDetail.access_level === "zone" ||
                                  this.state.userDetail.access_level ===
                                  "zone" ? (
                                  <td
                                    className={
                                      item.ptp
                                        ? "redirect-link text-center cursor-pointer"
                                        : ""
                                    }
                                    onClick={() =>
                                      this.redirectProjectStage(
                                        {
                                          project_status: item.project_status,
                                          outlet: "PTP",
                                          proposal_status: "Active",
                                        },
                                        item.ptp
                                      )
                                    }
                                  >
                                    {item.ptp}
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td>{item.project_status}</td>
                              <td
                                className={
                                  item.total
                                    ? "redirect-link text-center cusror-pointer"
                                    : ""
                                }
                                onClick={() =>
                                  this.redirectProjectStage(
                                    {
                                      project_status: item.project_status,
                                      proposal_status: "Active",
                                    },
                                    item.total
                                  )
                                }
                              >
                                {item.total}
                              </td>
                              <td
                                className={
                                  item.steel_wheel
                                    ? "redirect-link text-center cusror-pointer"
                                    : ""
                                }
                                onClick={() =>
                                  this.redirectProjectStage(
                                    {
                                      project_status: item.project_status,
                                      outlet: "Steel Wheel",
                                      proposal_status: "Active",
                                    },
                                    item.steel_wheel
                                  )
                                }
                              >
                                {item.steel_wheel}
                              </td>
                              <td
                                className={
                                  item.xpress_wheel
                                    ? "redirect-link text-center cusror-pointer"
                                    : ""
                                }
                                onClick={() =>
                                  this.redirectProjectStage(
                                    {
                                      project_status: item.project_status,
                                      outlet: "Xpress Wheel",
                                      proposal_status: "Active",
                                    },
                                    item.xpress_wheel
                                  )
                                }
                              >
                                {item.xpress_wheel}
                              </td>
                              <td
                                className={
                                  item.farm_xpress_wheel
                                    ? "redirect-link text-center cusror-pointer"
                                    : ""
                                }
                                onClick={() =>
                                  this.redirectProjectStage(
                                    {
                                      project_status: item.project_status,
                                      outlet: "Farm Xpress Wheel",
                                      proposal_status: "Active",
                                    },
                                    item.farm_xpress_wheel
                                  )
                                }
                              >
                                {item.farm_xpress_wheel}
                              </td>
                              {this.state.userDetail.access_level === "area" ||
                                this.state.userDetail.access_level === "agency" ||
                                this.state.userDetail.access_level === "all" ||
                                this.state.userDetail.access_level === "ztm" ||
                                this.state.userDetail.access_level === "zone" ? (
                                <td
                                  className={
                                    item.ptp
                                      ? "redirect-link text-center cursor-pointer"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.redirectProjectStage(
                                      {
                                        project_status: item.project_status,
                                        outlet: "PTP",
                                        proposal_status: "Active",
                                      },
                                      item.ptp
                                    )
                                  }
                                >
                                  {item.ptp}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </>
                )}
            </div>
            <br />
            <div
              className="pasanger-car-redial-wrapp Projects-Details-process-points"
              id="pasanger-car-redial-wrapp"
              style={{ flexDirection: "row" }}
            >
              {/* Steel Wheels */}
              <div className="d-flex-column Projects-Details-process">
                <div className="Base-Points-80-Tyre Projects-Details-process-lbl">
                  <span className="Base-Points-80-Tyre-label">
                    Steel Wheels
                  </span>
                </div>
                <Table id="table" className="mb-0  tbl-about tbl-about">
                  <thead>
                    <tr style={{ ...this.getStripedStyle(0) }}>
                      <th style={{ textAlign: "left" }}>Project Stage</th>
                      <th>Steel Wheels</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.steelWheelState.map((row, i) => (
                      <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                        {row.counts > 0 && (
                          <td
                            onClick={() =>
                              this.redirectProjectStage(
                                {
                                  project_status: "Proposal accepted",
                                  stage: row.name,
                                  outlet: "Steel Wheel",
                                  proposal_status: "Active",
                                },
                                row.counts
                              )
                            }
                            className="redirect-link"
                          >
                            {row.name}
                          </td>
                        )}
                        {row.counts <= 0 && (
                          <td className="redirect-link">{row.name}</td>
                        )}
                        <td>{row.counts ? row.counts : 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {/* Xpress Wheels */}
              <div className="d-flex-column Projects-Details-process">
                <div className="Base-Points-80-Tyre Projects-Details-process-lbl">
                  <span className="Base-Points-80-Tyre-label">
                    Xpress Wheels
                  </span>
                </div>
                <Table id="table" className="mb-0 tbl-about tbl-about">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Project Stage</th>
                      <th>Xpress Wheels</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.xpressWheelState.map((row, i) => (
                      <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                        {row.counts > 0 && (
                          <td
                            onClick={() =>
                              this.redirectProjectStage(
                                {
                                  project_status: "Proposal accepted",
                                  stage: row.name,
                                  outlet: "Xpress Wheel",
                                  proposal_status: "Active",
                                },
                                row.counts
                              )
                            }
                            className="redirect-link"
                          >
                            {row.name}
                          </td>
                        )}
                        {row.counts <= 0 && (
                          <td className="redirect-link">{row.name}</td>
                        )}
                        <td>{row.counts ? row.counts : 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {/*Farm Xpress Wheels */}
              <div className="d-flex-column Projects-Details-process">
                <div className="Base-Points-80-Tyre Projects-Details-process-lbl">
                  <span className="Base-Points-80-Tyre-label">
                    Farm Xpress Wheels
                  </span>
                </div>
                <Table id="table" className="mb-0 tbl-about tbl-about">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Project Stage</th>
                      <th>Farm Xpress Wheels</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.farmXpressWheelState.map((row, i) => (
                      <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                        {row.counts > 0 && (
                          <td
                            onClick={() =>
                              this.redirectProjectStage(
                                {
                                  project_status: "Proposal accepted",
                                  stage: row.name,
                                  outlet: "Farm Xpress Wheel",
                                  proposal_status: "Active",
                                },
                                row.counts
                              )
                            }
                            className="redirect-link"
                          >
                            {row.name}
                          </td>
                        )}
                        {row.counts <= 0 && (
                          <td className="redirect-link">{row.name}</td>
                        )}
                        <td>{row.counts ? row.counts : 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {/* PTP */}
              {this.state.userDetail.access_level === "area" ||
                this.state.userDetail.access_level === "agency" ||
                this.state.userDetail.access_level === "all" ||
                this.state.userDetail.access_level === "ztm" ||
                this.state.userDetail.access_level === "zone" ? (
                <div className="d-flex-column Projects-Details-process">
                  <div className="Base-Points-80-Tyre Projects-Details-process-lbl">
                    <span className="Base-Points-80-Tyre-label">PTP</span>
                  </div>
                  <Table id="table" className="mb-0  tbl-about tbl-about">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>Project Stage</th>
                        <th>PTP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ptpState.map((row, i) => (
                        <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                          {row.counts > 0 && (
                            <td
                              onClick={() =>
                                this.redirectProjectStage(
                                  {
                                    project_status: "Proposal accepted",
                                    stage: row.name,
                                    outlet: "PTP",
                                    proposal_status: "Active",
                                  },
                                  row.counts
                                )
                              }
                              className="redirect-link"
                            >
                              {row.name}
                            </td>
                          )}
                          {row.counts <= 0 && (
                            <td className="redirect-link">{row.name}</td>
                          )}
                          <td>{row.counts ? row.counts : 0}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                ""
              )}
              {/* Hold/Dropped */}
              {this.state.by_hold_dropped &&
                (this.state.userDetail.type === Roles.AREA_MANAGER_ROLE ||
                  this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE ||
                  this.state.userDetail.type === Roles.BRAND_STORE_ROLE) &&
                this.state.by_hold_dropped.length > 0 && (
                  <div className="d-flex-column Projects-Details-process">
                    <div className="Base-Points-80-Tyre Projects-Details-process-lbl">
                      <span className="Base-Points-80-Tyre-label">
                        Active / On Hold / Dropped
                      </span>
                    </div>
                    <Table id="table" className="mb-0 tbl-about tbl-about">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>Status</th>
                          <th>Counts</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.by_hold_dropped.map((row, i) => (
                          <tr style={{ ...this.getStripedStyle(i + 1) }}>
                            {row.counts > 0 && (
                              <td
                                onClick={() =>
                                  this.redirectProjectStage(
                                    { proposal_status: row.status },
                                    row.counts
                                  )
                                }
                                className="redirect-link"
                              >
                                {row.status}
                              </td>
                            )}
                            {row.counts <= 0 && (
                              <td className="redirect-link">{row.status}</td>
                            )}
                            <td>{row.counts}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
            </div>
            <br />
            <br />
          </div>
        </Col>
      </>
    );
  }
}

export default ProposalDashboard;
