import React, { Component } from 'react';
import {
  Row, Col, Button, ModalBody, Label, Modal,
  FormGroup, Input
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ModalService from '../../services/ModalService';
import Validation from '../../services/Validation';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import swal from 'sweetalert';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

class AddCipCoAttendee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: 'slideDown',
      visible: false,
      controls: {
        mobile_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        whatsapp_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        same_as_mobile: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        email: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        designation: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
      },
      attendee_list: [],
      old_cip_co_attendee: {},
      is_edit: false
    }
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  toggle = (data = {}) => {
    let { controls, old_cip_co_attendee, is_edit } = this.state;
    if (!this.state.visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    if (data && Object.keys(data).length > 0) {
      controls.mobile_number.value = data.mobile_number;
      controls.designation.value = data.designation;
      controls.whatsapp_number.value = data.whatsapp_number;
      controls.email.value = data.email;
      controls.name.value = data.name;
      is_edit = true;
      old_cip_co_attendee = data;
    } else {
      old_cip_co_attendee = {};
      is_edit = false;
    }
    this.setState({
      visible: !this.state.visible,
      controls,
      old_cip_co_attendee,
      is_edit
    }, () => {
      // if (this.state.visible) {
      //   this.props.openCoAttendee();
      // }
    })
  }
  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let {
      mobile_number,
      email,
      name,
      designation,
      whatsapp_number
    } = controls;


    if (name.touched === true || isSubmit) {
      name = Validation.notNullValidator(name);
      name.valid = !name.nullValue;
      if (name.valid === false) {
        name.showErrorMsg = true;
      } else {
        name.showErrorMsg = false;
      }
    }

    if (designation.touched === true || isSubmit) {
      designation = Validation.notNullValidator(designation);
      designation.valid = !designation.nullValue;
      if (designation.valid === false) {
        designation.showErrorMsg = true;
      } else {
        designation.showErrorMsg = false;
      }
    }




    if (mobile_number.touched === true || isSubmit) {
      mobile_number = Validation.notNullValidator(mobile_number);
      if (!mobile_number.nullValue) {
        mobile_number = Validation.validatePhoneNumber(mobile_number);
      }
      mobile_number.valid = !(
        mobile_number.nullValue || mobile_number.invalidPhone
      );
      if (mobile_number.valid === false) {
        mobile_number.showErrorMsg = true;
      } else {
        mobile_number.showErrorMsg = false;
      }
    }

    if (whatsapp_number.touched === true || isSubmit) {
      whatsapp_number = Validation.validatePhoneNumber(whatsapp_number);
      whatsapp_number.valid = !whatsapp_number.invalidPhone;
      if (whatsapp_number.valid === false) {
        whatsapp_number.showErrorMsg = true;
      } else {
        whatsapp_number.showErrorMsg = false;
      }
    }

    email.valid = true;
    if (email.touched === true || isSubmit) {
      if (
        email.value === "" ||
        email.value === null ||
        email.value === undefined
      ) {
        email.valid = true;
        email.showErrorMsg = false;
      } else {
        email = Validation.emailValidator(email);
        email.valid = !email.invalidEmail;
        if (email.valid === false) {
          email.showErrorMsg = true;
        } else {
          email.showErrorMsg = false;
        }
      }
    }

    if (
      email.valid === true &&
      mobile_number.valid === true &&
      name.valid === true &&
      designation.valid === true &&
      whatsapp_number.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  updateCoAttendee = () => {
    let { controls } = this.state;
    let reqData = {
      "uuid": this.state.old_cip_co_attendee.uuid,
      "name": controls.name.value,
      "mobile_number": controls.mobile_number.value,
      "designation": controls.designation.value,
      "whatsapp_number": controls.whatsapp_number.value,
      "email": controls.email.value
    }

    this.setState({ isLoading: true });
    CustomerIntractionServie.updateCoAttendee(reqData)
      .then((res) => {
        this.setState({ isLoading: false });
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.toggle();
          this.props.getCoAttendeeList();
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  }

  addCOAttendee = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    if (this.state.old_cip_co_attendee && Object.keys(this.state.old_cip_co_attendee).length > 0) {
      this.updateCoAttendee();
      return;
    }
    let { controls } = this.state;
    let reqData = {
      "cip_detail_id": this.props.cip_detail.id,
      "attendee": [
        {
          "name": controls.name.value,
          "mobile_number": controls.mobile_number.value,
          "designation": controls.designation.value,
          "whatsapp_number": controls.whatsapp_number.value,
          "email": controls.email.value
        }
      ]
    }
    this.setState({ isLoading: true });
    CustomerIntractionServie.addCOAttendee(reqData)
      .then((res) => {
        controls['mobile_number'].value = '';
        controls['designation'].value = '';
        controls['whatsapp_number'].value = '';
        controls['email'].value = '';
        controls['name'].value = '';
        controls['same_as_mobile'].value = false;

        this.setState({ isLoading: false, controls });
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.toggle();
          this.props.getCoAttendeeList();
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  }





  handleInputChange = async (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (controlName === "same_as_mobile") {
      controls.same_as_mobile.value = !controls.same_as_mobile.value;
      if (
        !controls.mobile_number.showErrorMsg &&
        controls.mobile_number.value &&
        controls.same_as_mobile.value
      ) {
        controls.whatsapp_number.value = controls.mobile_number.value;
        controls.same_as_mobile.value = true;
      } else {
        controls.whatsapp_number.value = "";
        controls.same_as_mobile.value = false;
      }
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    await this.setState({ controls });
    this.handleValidation();
  };

  render() {
    let { controls } = this.state;
    return (
      // <Rodal visible={this.state.visible}
      //   animation={this.state.animation}
      //   showMask={true}
      //   width="680"
      //   className="rodal-center"
      // >
      <Modal
        isOpen={this.state.visible}
        toggle={this.toggle}
        className="modal-md modal-lg custom-confirm-modal modal-sm modal-dialog-centered"
        style={{ maxWidth: "680px" }}
        backdrop={this.backdrop ? "static" : "nostatic"}
      >
        {this.state.isLoading && <CustomSpinner />}
        <div className="d-flex-column">
          <div className="confirm-modal-title-wrapp">
            <span className="confirm-modal-title">{this.state.is_edit ? 'EDIT' : 'ADD'} CO-ATTENDEE</span>
            <FontAwesomeIcon className="pull-right cursor-pointer" onClick={() => this.toggle({})} icon={faTimes} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
          </div>
        </div>
        <ModalBody>
          <Row>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label for="mobile_number">
                  Mobile Number
                  <span className="required-field">*</span>
                </Label>
                <Input
                  autocomplete="nofill"
                  type="text"
                  maxLength="10"
                  onChange={(e) => this.handleInputChange(e)}
                  onBlur={this.handleOnBlurMobile}
                  name="mobile_number"
                  value={controls.mobile_number.value}
                  placeholder="Type here"
                />
                {controls.mobile_number.showErrorMsg && (
                  <div className="error">
                    * Please enter valid mobile number.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label for="owner_name">
                  Co-Attendee Name
                  <span className="required-field">*</span>
                </Label>
                <Input
                  type="text"
                  autocomplete="nofill"
                  name="name"
                  value={controls.name.value}
                  onChange={(e) => this.handleInputChange(e)}
                  placeholder="Type here"
                />
                {controls.name.showErrorMsg && (
                  <div className="error">
                    * Please enter co-attendee name.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label for="company_type">
                  Designation
                  <span className="required-field">*</span>
                </Label>
                <select
                  className="form-control font-size-14px"
                  value={
                    controls.designation.value
                      ? controls.designation.value.toLowerCase()
                      : ""
                  }
                  name="designation"
                  onChange={(e) => this.handleInputChange(e)}
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  <option value="owner">Owner</option>
                  <option value="manager">Manager</option>
                  <option value="others">Others</option>
                </select>
                {controls.designation.showErrorMsg && (
                  <div className="error">
                    * Please select designation.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label for="whatsapp_number">Whatsapp Number</Label>
                <Input
                  autocomplete="nofill"
                  type="text"
                  onChange={(e) => this.handleInputChange(e)}
                  name="whatsapp_number"
                  value={controls.whatsapp_number.value}
                  disabled={controls.same_as_mobile.value}
                  maxLength="10"
                  id="whatsapp_number"
                  placeholder="Type here"
                />
                {controls.whatsapp_number.showErrorMsg && (
                  <div className="error">
                    * Please enter valid whatsapp number.
                  </div>
                )}
                <div className="mt-1">
                  <label className="custom-checkboax1--container">
                    <input
                      type="checkbox"
                      name="same_as_mobile"
                      checked={controls.same_as_mobile.value}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    <span className="checkmark1" />
                  </label>
                  <span style={{ marginLeft: "10px" }}>
                    Same as mobile number
                  </span>
                </div>
              </FormGroup>
            </Col>
            <Col xl="6" md="12" xs="12" style={{ marginTop: '-15px' }}>
              <FormGroup>
                <Label for="primaryPerson">Email Address</Label>
                <Input
                  autocomplete="nofill"
                  type="text"
                  onChange={(e) => this.handleInputChange(e)}
                  value={controls.email.value}
                  name="email"
                  id="email"
                  placeholder="Type here"
                />
                {controls.email.showErrorMsg && (
                  <div className="error">
                    * Please enter email address.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>


        </ModalBody>
        {/* <ModalFooter>
          <Button color="link" onClick={() => { this.toggle() }}> Cancel</Button>
          <Button color="primary">Do Something</Button>
        </ModalFooter> */}
        <div className="d-flex justify-content-center mt-3 mb-2">
          <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
            onClick={() => this.toggle()}>Cancel</Button>
          &nbsp;&nbsp;
          <Button
            className="mb-1 mr-1 btn-pill action-button profile-save-button"
            color="warning"
            onClick={() => this.addCOAttendee()}
          >
            {this.state.is_edit > -1 ? 'Save' : 'Add'}
          </Button>
        </div>
        {/* </Rodal > */}
      </Modal>
    );
  }
}

export default AddCipCoAttendee;