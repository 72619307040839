import React, { Component } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import "../AddCustomer.css";
import moment from "moment";
// services
import AddCustomerService from "../../../services/AddCustomerService";
import ViewRemark from "../../../modal/ViewRemark/ViewRemark";

class VehicleTradeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPurpose: null,
      customer_services: [],
      selectedServices: [],
      vehicleMakeData: [],
      vehicleModelData: [],
      customer_vehicle: [],
      remark: null,
      birthdate: null,
      viewRemark: false,
      anniversary: null,
      customer_vehicle_id: null,
      controls: {
        first_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        last_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        group_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        group_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
    };
    this.childVehicleRegistrationNumber = React.createRef();
  }

  componentDidMount = () => {
    // this.getCustomerVehicleTradeHistory();
    // this.setState({
    //   customer_vehicle_id: this.props.customer_vehicle_id,
    // });
  };

  /**This function is executed from the Parent Component using ref */
  setProps = () => {
    this.getCustomerVehicleTradeHistory();
    this.setState({
      customer_vehicle_id: this.props.customer_vehicle_id,
    });
  }

  handleInputChange = (e) => {
    const { controls } = this.state;
    controls[e.target.name].value = e.target.value;
    this.setState({ controls });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDatePicker = (date) => {
    const { controls } = this.state;
    controls["started_at"].value = date;
    this.setState({ controls });
  };

  /**Method To get the vehicle trade history when we click on Visit History button from Customer Detail */
  getCustomerVehicleTradeHistory = () => {
    const obj = {
      customer_id:
        this.props.customer_detail && this.props.customer_detail.customer_id,
      customer_vehicle_id:
        this.props.customer_vehicle_id &&
        this.props.customer_vehicle_id.vehicle_id,
    };
    if (
      !this.props.customer_detail ||
      !this.props.customer_detail.customer_id
    ) {
      return;
    }
    this.props.isLoading(true);
    AddCustomerService.getCustomerTradeHistory(obj)
      .then((res) => {
        this.props.isLoading(false);
        let { customer_vehicle } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          customer_vehicle = res.data.data;
        } else {
          customer_vehicle = [];
        }
        this.setState({
          customer_vehicle
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        this.props.isLoading(false);
      });
  };

  viewRemark = (item) => {
    this.setState({
      viewRemark: true,
      remarkData: item
    });
  };

  closeviewRemarkModel = () => {
    this.setState({
      viewRemark: false,
      remarkData: {}
    });
  };

  generateServiceToolTip = (services) => {
    let serviceString = '';
    if (services && Array.isArray(services) && services.length > 0) {
      for (let i = 0; i < services.length; i++) {
        if (i === 0) {
          serviceString += services[i].service_name;
        } else {
          serviceString += `\n${services[i].service_name}`
        }
      }
    }
    return serviceString;
  }


  generateServiceText = (lastVisitService) => {
    let c = null;
    if (lastVisitService && Array.isArray(lastVisitService) && lastVisitService.length > 0) {
      if (lastVisitService.length === 1) {
        c = <span className="sub-value-table-service d-flex">({lastVisitService[0].service_name})</span>
      } else {
        c = <span className="sub-value-table-service d-flex" id="customer-list-tooltip">({lastVisitService[0].service_name})
          <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={this.generateServiceToolTip(lastVisitService)} >
            <span className="sub-value-more cursor-pointer">+{lastVisitService.length - 1}</span>
          </span>
          {/* <span className="sub-value-more cursor-pointer" title={this.generateServiceToolTip(lastVisitService)}>+{lastVisitService.length - 1}</span> */}
        </span>
      }
    }
    return c;
  }

  generateLastServiceText = (lastVisitService) => {
    let c = null;
    if (lastVisitService && Array.isArray(lastVisitService) && lastVisitService.length > 0) {
      if (lastVisitService.length === 1) {
        c = <span className="sub-value-table-service d-flex">({lastVisitService[0]})</span>
      } else {
        c = <span className="sub-value-table-service d-flex" id="customer-list-tooltip">({lastVisitService[0]})
          <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={this.generateServiceToolTip(lastVisitService)} >
            <span className="sub-value-more cursor-pointer">+{lastVisitService.length - 1}</span>
          </span>
          {/* <span className="sub-value-more cursor-pointer" title={this.generateServiceToolTip(lastVisitService)}>+{lastVisitService.length - 1}</span> */}
        </span>
      }
    }
    return c;
  }

  render() {
    const {
      // controls,
      // vehicleMakeData,
      customer_vehicle,
      customer_vehicle_id,
    } = this.state;
    // const mappleConfig = {
    //   direction: "bottom",
    //   backgroundColor: "red",
    //   float: false
    // };
    return (
      <>
        {this.state.viewRemark && (
          <ViewRemark
            viewRemark={this.state.viewRemark}
            closeModal={this.closeviewRemarkModel}
            openModel={true}
            remarkData={this.state.remarkData}
          />
        )}
        <div className="add-customer-wrapper visit-history">
          {customer_vehicle_id && customer_vehicle_id.vehicle_id ? (
            <>
              <Row className="veh-det-view-2" style={{ paddingTop: '5px', paddingBottom: '10px', marginRight: '15px', marginLeft: '10px' }}>
                <Col xl="12">
                  <Row>
                    <Col xl="4" xs="4" md="4" className="mt-2">
                      <div className="V-t-card">Vehicle Type</div>
                      <div>
                        <img
                          src={
                            customer_vehicle_id &&
                            customer_vehicle_id.vehicle_type_image_url
                          }
                          className="vehicle-image mt-2"
                          alt=''
                        />
                      </div>
                    </Col>
                    <Col xl="4" xs="4" md="4" className="mt-2">
                      <div className="V-t-card">Vehicle Detail</div>
                      <div className="V-t-model mt-2">
                        {(customer_vehicle_id &&
                          customer_vehicle_id.vehicle_type_brand_name) ||
                          (customer_vehicle_id &&
                            customer_vehicle_id.vehicle_type_brand_model_name)
                          ?
                          <b>
                            {" "}
                            {customer_vehicle_id &&
                              customer_vehicle_id.vehicle_type_brand_name ?
                              customer_vehicle_id.vehicle_type_brand_name :
                              '--'}
                            &nbsp;&nbsp;
                            {customer_vehicle_id &&
                              customer_vehicle_id.vehicle_type_brand_model_name ?
                              customer_vehicle_id.vehicle_type_brand_model_name : '--'}
                          </b> : '--'
                        }
                      </div>
                      <div className="V-t-number">
                        {" "}
                        {customer_vehicle_id &&
                          customer_vehicle_id.registration_number}
                      </div>


                      {this.generateLastServiceText(customer_vehicle_id.last_purpose_service_name)}
                    </Col>
                    <Col xl="4" xs="4" md="4" className="mt-2">
                      <div className="V-t-card">Usage Type</div>
                      <div className="V-t-model mt-2">
                        <b>
                          {customer_vehicle_id &&
                            (customer_vehicle_id.vehicle_category.toLowerCase() === 'taxi' ? 'Commerical' : customer_vehicle_id.vehicle_category)}
                        </b>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {
                customer_vehicle && customer_vehicle.length <= 0 &&
                <Row style={{ paddingTop: '5px', paddingBottom: '10px', marginRight: '-3px', marginLeft: '-3px' }}
                  className="mt-2">
                  <Col xl="12">
                    {
                      <div className="no-data" style={{ border: 'solid 0.9px #e9ecef' }}>
                        Currently, there is no any trade to display
                      </div>
                    }
                  </Col>
                </Row>
              }
              <br />
              <Row style={{ paddingTop: '5px', paddingBottom: '10px', marginRight: '15px', marginLeft: '10px' }}>
                <Col xl="12">
                  {customer_vehicle &&
                    customer_vehicle.map((item, i) => (
                      <Row className={i !== 0 ? 'veh-det-view pl-0 pr-0 mt-2' : 'veh-det-view pl-0 pr-0'} style={{ marginLeft: '8px !important', marginRight: '15px !important' }}>
                        <Col>
                          <Row>
                            <Col xl="4" className="mt-3">
                              <div className="d-flex-column">
                                <span className="white-space-nowrap">Visit Date</span>
                                <span className="V-t-model">
                                  <b>
                                    {item.last_visit_date &&
                                      moment(item.last_visit_date).format(
                                        "DD MMM,YYYY"
                                      )}
                                  </b>
                                </span>
                              </div>
                            </Col>
                            <Col xl={item.type_of_inquiry ? "4" : "5"} className="mt-3">
                              <div className="V-t-card-2 d-flex-column">
                                <span> Purpose &nbsp;&nbsp;&nbsp;{" "}</span>
                                <div className="d-flex mt-1 flex-wrap">
                                  <span style={{ fontSize: "14px" }}>
                                    <b>{item.purpose_name}&nbsp;</b>
                                  </span>
                                  {
                                    item.services && item.services.length > 0
                                      ?
                                      <>
                                        {this.generateServiceText(item.services)}
                                        {/* (
                              {
                                          item.services.map((service, i) => (
                                            <>
                                              <span>{service.service_name}
                                                {
                                                  (i !== (item.services.length - 1)) ? ',' : ''
                                                }
                                              </span>
                                            </>
                                          ))
                                        }
                                        ) */}
                                      </> :
                                      <>
                                      </>
                                  }
                                </div>
                              </div>
                            </Col>
                            {
                              item.type_of_inquiry &&
                              <Col xl="4" xs="4" md="4" className="mt-3">
                                <div className="V-t-card-2 d-flex-column">
                                  Type of Inquiry &nbsp;&nbsp;&nbsp;{" "}
                                  <span style={{ fontSize: "14px" }} className="mt-1">
                                    <b>{item.type_of_inquiry}&nbsp;</b>
                                  </span>
                                </div>
                              </Col>
                            }
                            {item.services && item.services.length > 0 && <Col xl="3" xs="1" md="1" className="mt-3">
                              {/* {
                                (item.rating && item.rating > 0) ?
                                  <div className="d-flex-column">
                                    <span className="font-size-14px">Rating</span>
                                    <span className={item.rating_comment ? 'rating-round cursor-pointer' : 'rating-round'} data-tip data-for={'tooltip-' + i}>{item.rating}</span>
                                    {
                                      item.rating_comment &&
                                      <ReactTooltip id={'tooltip-' + i} className="rating-tooltip" effect="solid">
                                        <span className="trade-history-tooltip-text font-weight-bold">Rating: {item.rating}</span><br />
                                        <span className="trade-history-tooltip-text">{item.rating_comment} </span>
                                      </ReactTooltip>
                                    }
                                  </div> : ''
                              } */}
                              {
                                item.services && item.services.length > 0 &&
                                <div
                                  className="del-img"
                                  onClick={() => this.viewRemark(item)}
                                >
                                  <img src="/images/sidebar-icons/remark_icon.svg" alt='' />
                                </div>
                              }
                            </Col>
                            }
                            <Col xl="12" xs="12" md="12">
                              <div className="border-card"></div>
                            </Col>
                            <Col xl="4" xs="6" md="6  " className="label-t mt-3">
                              <div className="">Vehicle Meter Reading</div>
                              <div className="V-t-model">
                                {
                                  item.vehical_meter_reading ?
                                    <b>{item.vehical_meter_reading}</b>
                                    : <b>--</b>}
                              </div>
                            </Col>
                            <Col xl="7" xs="6" md="6" className="label-t mt-3">
                              <div className="">Avg. Monthly Usage (kms)</div>

                              <div className="V-t-model">
                                {
                                  item.vehical_avg_monthly_running ?
                                    <b>{item.vehical_avg_monthly_running}</b>
                                    : <b>--</b>}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                </Col>
              </Row>
            </>
          ) : (
            <>

              {
                customer_vehicle && customer_vehicle.length <= 0 &&
                <Row style={{ paddingTop: '5px', paddingBottom: '10px', marginRight: '-3px', marginLeft: '-3px' }}>
                  <Col xl="12">
                    {
                      <div className="no-data" style={{ border: 'solid 0.9px #e9ecef' }}>
                        Currently, there is no any visit history to display
                      </div>
                    }
                  </Col>
                </Row>
              }
              {customer_vehicle.map((item, index_vehicle) => (
                <Row className="veh-det-view ml-2 mr-3">
                  <Col>
                    <Row>
                      <Col xl="4" className="mt-3">
                        <div className="d-flex">
                          <span className="white-space-nowrap">Visit Date</span>
                          <span className="V-t-model text-align-right">
                            <b>
                              {item.last_visit_date &&
                                moment(item.last_visit_date).format(
                                  "DD MMM,YYYY"
                                )}
                            </b>
                          </span>
                        </div>
                      </Col>
                      <Col xl={item.type_of_inquiry ? "4" : "5"} className="mt-3 d-flex">
                        <div className="V-t-card-2">
                          Purpose &nbsp;&nbsp;&nbsp;{" "}
                          <span style={{ fontSize: "14px" }}>
                            <b>{item.purpose_name}&nbsp;</b>
                          </span>
                          {
                            item.services && item.services.length > 0
                              ?
                              <div className="d-flex flex-wrap">
                                {this.generateServiceText(item.services)}
                                {/* (
                                {
                                    item.services.map((service, i) => (
                                      <>
                                        <span>{service.service_name}
                                          {
                                            (i !== (item.services.length - 1)) ? ',' : ''
                                          }
                                        </span>
                                      </>
                                    ))
                                  }
                                  ) */}
                              </div> :
                              <>
                              </>
                          }
                        </div>
                      </Col>
                      {
                        item.type_of_inquiry &&
                        <Col xl="4" xs="4" md="4" className="mt-3">
                          <div className="V-t-card-2 d-flex-column">
                            Type of Inquiry &nbsp;&nbsp;&nbsp;{" "}
                            <span style={{ fontSize: "14px" }} className="mt-1">
                              <b>{item.type_of_inquiry}&nbsp;</b>
                            </span>
                          </div>
                        </Col>
                      }
                      {item.services && item.services.length > 0 &&
                        <Col xl="3" xs="3" md="3" className="mt-3 d-flex-row justify-content-between">
                          {/* {
                            (item.rating && item.rating > 0) ?
                              <div className="d-flex-column">
                                <span className="font-size-14px">Rating</span>
                                <span className={item.rating_comment ? 'rating-round cursor-pointer' : 'rating-round'} data-tip data-for={'tooltip-' + index_vehicle}>{item.rating}</span>
                                {
                                  item.rating_comment &&
                                  <ReactTooltip id={'tooltip-' + index_vehicle} className="rating-tooltip" effect="solid">
                                    <span className="trade-history-tooltip-text font-weight-bold">Rating: {item.rating}</span><br />
                                    <span className="trade-history-tooltip-text">{item.rating_comment} </span>
                                  </ReactTooltip>
                                }
                              </div> : ''
                          } */}
                          {
                            (item.services && item.services.length > 0) ?
                              <div
                                className="del-img"
                                onClick={() => this.viewRemark(item)}
                              >
                                <img src="/images/sidebar-icons/remark_icon.svg" alt='' />
                              </div> : ''
                          }
                        </Col>
                      }
                      <Col xl="4" xs="4" md="4" className="mt-3">
                        <div className="d-flex-row">
                          <span style={{ fontSize: "12px" }}>
                            Vehicle Type
                          </span>
                        </div>
                        <div>
                          <img
                            src={item.vehicle_type_image_url}
                            className="vehicle-image"
                            alt=''
                          />
                        </div>
                      </Col>
                      <Col xl="4" xs="4" md="4" className="mt-3">
                        <div className="V-t-card">Vehicle Detail</div>
                        <div className="V-t-model mt-1">
                          {
                            item.vehicle_type_brand_name ||
                              item.vehicle_type_brand_model_name ?
                              <b>
                                {item.vehicle_type_brand_name}&nbsp;&nbsp;
                                {item.vehicle_type_brand_model_name}
                              </b> : '--'
                          }
                        </div>
                        <div className="V-t-number">
                          {item.registration_number}
                        </div>
                      </Col>
                      <Col xl="4" xs="4" md="4" className="mt-3">
                        <div className="V-t-card">Usage Type</div>
                        <div className="V-t-model mt-1">
                          <b>{item.vehicle_category ? (item.vehicle_category.toLowerCase() === 'taxi' ? 'Commercial' : item.vehicle_category) : ''}</b>
                        </div>
                      </Col>


                      <Col xl="12" xs="12" md="12">
                        <div className="border-card"></div>
                      </Col>
                      <Col xl="6" xs="6" md="6  " className="label-t mt-3">
                        <div className="">Vehicle Meter Reading</div>
                        <div className="V-t-model">
                          {
                            item.vehical_meter_reading ?
                              <b>{item.vehical_meter_reading}</b>
                              : <b>--</b>}
                        </div>
                      </Col>
                      <Col xl="6" xs="6" md="6" className="label-t mt-3">
                        <div className="">Avg. Monthly Usage (kms)</div>
                        <div className="V-t-model">
                          {
                            item.vehical_avg_monthly_running ?
                              <b>{item.vehical_avg_monthly_running}</b>
                              : <b>--</b>}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))
              }
            </>
          )}
        </div>
      </>
    );
  }
}

export default VehicleTradeHistory;
