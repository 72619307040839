import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  CardBody,
  Label,
  Button,
  Alert
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import classnames from "classnames";
import _ from "lodash";
import { debounce } from "throttle-debounce";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import swal from "sweetalert";

import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import WarrantyReplacementService from "../../services/WarrantyReplacementService";
import StorageService from "../../services/Storage";

import { messages } from "../../constants/constant.message";
import Storage from "../../services/Storage";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

class WarrantyCheck extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: {
        value: ""
      },
      registrationNumber: "",
      redirectToDashboard: false,
      redirectToSelectVehicle: false,
      warrantyListData: {},
      clonedWarrantyListData: {},
      warrantyTyreCheckbox: {},
      replacementDetails: [],
      isLoading: false,
      token: "",
      userRole: null,
      controls: {
        stencilNumber0: {
          value: {
            stencil_number: "",
            name: "stencilNumber0"
          },
          tempValue: "",
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: ""
        },
        stencilNumber1: {
          value: {
            stencil_number: "",
            name: "stencilNumber1"
          },
          tempValue: "",
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: ""
        },
        stencilNumber2: {
          value: {
            stencil_number: "",
            name: "stencilNumber2"
          },
          tempValue: "",
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: ""
        },
        stencilNumber3: {
          value: {
            stencil_number: "",
            name: "stencilNumber3"
          },
          tempValue: "",
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: ""
        },
        stencilNumber4: {
          value: {
            stencil_number: "",
            name: "stencilNumber4"
          },
          tempValue: "",
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: ""
        },
        stencilNumber5: {
          value: {
            stencil_number: "",
            name: "stencilNumber5"
          },
          tempValue: "",
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: ""
        },
        skuId1: {
          value: {
            sku_id: "",
            name: ""
          },
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: ""
        },
        skuIdBack: {
          value: {
            sku_id: "",
            name: ""
          },
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: ""
        },
        skuIdSpare: {
          value: {
            sku_id: "",
            name: ""
          },
          valid: false,
          touched: false,
          message: null,
          nullValue: null,
          isTyre: true,
          invalidFirstname: null,
          databaseFieldName: "",
          vehicle_id: null
        }
      },
      userDetailData: Storage.getUserDetail()
    };

    if (this.state.userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)) {
      this.state.dashborad_path = "/dealer-dashboard";
    }
  }

  componentWillMount() {
    const query = new URLSearchParams(this.props.location.search);

    const vehicle_id = query.get("q");
    if (vehicle_id) {
      this.setState({
        vehicle_id: window.atob(vehicle_id)
      });
      const token = query.get("t");
      if (!vehicle_id || !token) {
        this.redirectDashboard();
      }

      this.setState({
        vehicle_id: window.atob(vehicle_id),
        token
      });
    }
    this.getVehicleWarrantyList(window.atob(vehicle_id));
  }

  componentDidMount() {
    const userDetail = StorageService.getUserDetail();
    const { type: userRole } = userDetail;
    this.setState({ userRole });
  }

  onChangeWarrantyTyreCheckbox(e, item, index) {
    const {
      warrantyTyreCheckbox,
      replacementDetails,
      warrantyListData
    } = this.state;
    if (
      warrantyListData.customer_data &&
      (warrantyListData.customer_data.type || "").toLowerCase() === "car" &&
      replacementDetails &&
      replacementDetails.length > 4 &&
      e.target.checked
    ) {
      swal(
        "Sorry !",
        messages.WARRANTY_REPLACEMENT.WRCHECKLIMITEXCEEDCAR,
        "warning"
      );
      e.target.checked = false;
      return false;
    }

    if (
      warrantyListData.customer_data &&
      (warrantyListData.customer_data.type || "").toLowerCase() !== "car" &&
      replacementDetails &&
      replacementDetails.length > 2 &&
      e.target.checked
    ) {
      swal(
        "Sorry !",
        messages.WARRANTY_REPLACEMENT.WRCHECKLIMITEXCEEDTWOWHEELER,
        "warning"
      );
      e.target.checked = false;
      return false;
    }

    warrantyTyreCheckbox[index] = e.target.checked;

    if (e.target.checked) {
      replacementDetails.push(item);
    } else {
      _.remove(replacementDetails, {
        warranty_registration_number: item.warranty_registration_number
      });
    }

    this.setState({ replacementDetails, warrantyTyreCheckbox });
  }

  onSearchWarrantyNumber = e => {
    const controlValue = e.target.value;
    const { searchText, clonedWarrantyListData } = this.state;
    const { warrantyListData } = this.state;
    searchText.value = (controlValue || "").toUpperCase();
    warrantyListData.warranty_data = _.filter(
      clonedWarrantyListData.warranty_data,
      item => {
        return (
          item.warranty_registration_number &&
          item.warranty_registration_number.indexOf(searchText.value) !== -1
        );
      }
    );
    this.setState({ searchText, warrantyListData });
  };

  /**
   * @name getVehicleType
   * @desc The method is used to get vehicle types.. car, two wheeler
   */
  async getVehicleWarrantyList(vehicle_id) {
    try {
      let { warrantyListData, clonedWarrantyListData } = this.state;
      const { data } = await WarrantyReplacementService.getVehicleWarrantyList(
        vehicle_id
      );
      if (data && data.data) {
        warrantyListData = data.data;
        clonedWarrantyListData = _.cloneDeep(data.data);
      }
      this.setState({ warrantyListData, clonedWarrantyListData });
    } catch (error) {
      console.log(error);
    }
  }

  getCurrentWeek() {
    Date.prototype.getWeek = function () {
      const onejan = new Date(this.getFullYear(), 0, 1);
      return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7);
    };

    const today = new Date();
    const weekNumber = today.getWeek();
    return weekNumber;
  }

  handleInputStencilNumberChange = (e, index) => {
    const controlName = e.target.name;
    const controlValue = (e.target.value || "").toUpperCase();
    const { controls, warrantyListData, replacementDetails } = this.state;
    const {
      stencilNumber0,
      stencilNumber1,
      stencilNumber2,
      stencilNumber3,
      stencilNumber4,
      stencilNumber5
    } = controls;
    let { isValidStencil } = this.state;
    let regex = "";
    let enterdWeek = 0;
    let maxStencilChar = 0;

    controls[controlName].value.stencil_number = controlValue;
    controls[controlName].touched = true;
    controls[controlName].valid = false;

    if (controls[controlName].value.stencil_number) {
      if ((warrantyListData.customer_data.type || "").toLowerCase() === "car") {
        regex = /^[BCbc][0-9]{4}$/;
        enterdWeek = controls[controlName].value.stencil_number.substr(1, 2);
        maxStencilChar = 5;
      } else {
        regex = /^[LHlh]{1}[0-9]{10}$/;
        enterdWeek = controls[controlName].value.stencil_number.substr(7, 2);
        maxStencilChar = 11;
      }

      if (
        controlName !== "stencilNumber0" &&
        (stencilNumber0.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (warrantyListData.customer_data.type || "").toLowerCase()
        )
      ) {
        stencilNumber0.value.stencil_number = controlValue;
        stencilNumber0.valid = true;
      }

      if (
        controlName !== "stencilNumber1" &&
        (stencilNumber1.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (warrantyListData.customer_data.type || "").toLowerCase()
        )
      ) {
        stencilNumber1.value.stencil_number = controlValue;
        stencilNumber1.valid = true;
      }

      if (
        controlName !== "stencilNumber2" &&
        (stencilNumber2.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (warrantyListData.customer_data.type || "").toLowerCase()
        )
      ) {
        stencilNumber2.value.stencil_number = controlValue;
        stencilNumber2.valid = true;
      }

      if (
        controlName !== "stencilNumber3" &&
        (stencilNumber3.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (warrantyListData.customer_data.type || "").toLowerCase()
        )
      ) {
        stencilNumber3.value.stencil_number = controlValue;
        stencilNumber3.valid = true;
      }

      if (
        controlName !== "stencilNumber4" &&
        (stencilNumber4.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (warrantyListData.customer_data.type || "").toLowerCase()
        )
      ) {
        stencilNumber4.value.stencil_number = controlValue;
        stencilNumber4.valid = true;
      }

      if (
        controlName !== "stencilNumber5" &&
        (stencilNumber5.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (warrantyListData.customer_data.type || "").toLowerCase()
        )
      ) {
        stencilNumber5.value.stencil_number = controlValue;
        stencilNumber5.valid = true;
      }

      if (controls[controlName].value.stencil_number.match(regex)) {
        controls[controlName].valid = true;
      }

      if (controls[controlName].valid) {
        controls[controlName].value.stencil_number = controls[
          controlName
        ].value.stencil_number.toUpperCase();
        let weekToCompare = 52;
        const currentYear = new Date()
          .getFullYear()
          .toString()
          .substr(-2);

        const enterdYear = controls[controlName].value.stencil_number.substr(
          -2
        );

        if (enterdYear == currentYear) {
          // uses double equals to for a reason.. keep it
          weekToCompare = this.getCurrentWeek();
        }
        if (
          enterdWeek === "00" ||
          enterdYear === "00" ||
          enterdYear > currentYear ||
          +enterdWeek > weekToCompare
        ) {
          controls[controlName].valid = false;
        } else {
          controls[controlName].valid = true;
        }
      }
    }

    isValidStencil = false;

    for (let i = 0; i < replacementDetails.length; i++) {
      isValidStencil = true;
      if (
        controls[`stencilNumber${i}`] &&
        !controls[`stencilNumber${i}`].valid
      ) {
        isValidStencil = false;
        break;
      }
    }

    this.setState({ controls, isValidStencil });
    this.handleValidatidation();
  };

  /**
   * @name redirectDashboard
   * @desc The method is used to redirect on dashboard
   */
  redirectSelectVehicle = () => {
    let { redirectToSelectVehicle } = this.state;
    redirectToSelectVehicle = true;
    this.setState({ redirectToSelectVehicle });
  };

  skuIdSearch = async sku_id => {
    this.setState({ isLoading: true });
    try {
      const { warrantyListData } = this.state;
      const { data } = await WarrantyReplacementService.getSKUId({
        vehicle_type:
          warrantyListData.customer_data && warrantyListData.customer_data.type,
        searchquery: sku_id
      });

      if (data) {
        this.SKUId = data.data;
      }

      this.setState(prevState => {
        return { options: this.SKUId, ...prevState, isLoading: false };
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleValidatidation = () => {
    const { controls, warrantyListData, replacementDetails } = this.state;
    let { isValidForm } = this.state;
    const { skuId1, skuIdBack, skuIdSpare } = controls;

    skuId1.valid = skuId1.value && !!skuId1.value.sku_id;
    skuIdBack.valid = skuIdBack.value && !!skuIdBack.value.sku_id;
    skuIdSpare.valid = skuIdSpare.value && !!skuIdSpare.value.sku_id;

    if ((warrantyListData.customer_data.type || "").toLowerCase() === "car") {
      if (skuId1.valid) {
        isValidForm = true;
      } else {
        isValidForm = false;
      }
    } else {
      isValidForm = false;
      if (
        replacementDetails &&
        replacementDetails.length === 1 &&
        skuId1.valid
      ) {
        isValidForm = true;
      }

      if (
        replacementDetails &&
        replacementDetails.length === 2 &&
        skuId1.valid &&
        skuIdBack.valid
      ) {
        isValidForm = true;
      }

      if (
        replacementDetails &&
        replacementDetails.length === 3 &&
        skuId1.valid &&
        skuIdBack.valid &&
        skuIdSpare.valid
      ) {
        isValidForm = true;
      }
    }
    this.setState({ controls, isValidForm });
  };

  onSubmitClick = async () => {
    try {
      const obj = this.parseObj();
      this.setState({ redirectToDashboard: false });
      const {
        data
      } = await WarrantyReplacementService.claimWarrantyReplacement(obj);
      if (data && data.success) {
        swal("Congratulations !", data.message || "", "success");
        this.redirectDashboard();
      } else {
        swal("Sorry !", messages.WARRANTY_REGISTRATION.ERRORMESSAGE, "error");
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.error[0] &&
        error.response.data.error[0].message
      ) {
        return swal("", error.response.data.error[0].message, "warning");
      }
      swal("Sorry !", messages.WARRANTY_REGISTRATION.ERRORMESSAGE, "error");
    }
  };

  cancelClick = () => {
    swal({
      title: messages.WARRANTY_REPLACEMENT.ONCANCELCLICKTITLE,
      text: messages.WARRANTY_REPLACEMENT.ONCANCELCLICKMESSAGE,
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.redirectSelectVehicle();
      }
    });
  };

  changeSKUId(query, controlName) {
    const { controls } = this.state;
    controls[controlName].touched = true;
    controls[controlName].value = query && query[0];

    this.setState({ controls });
    this.handleValidatidation();
  }

  parseObj() {
    let obj = {};
    const {
      warrantyListData,
      replacementDetails,
      controls,
      vehicle_id,
      token
    } = this.state;
    const { customer_data } = warrantyListData;
    obj = {
      vehicle_id: vehicle_id ? vehicle_id : null,
      country_code: customer_data.country_code,
      phone_number: customer_data.phone_number,
      state_id: customer_data.state_id,
      vehicle_type_id: customer_data.vehicle_type_id,
      registration_number: customer_data.registration_number,
      sku_id_1: controls.skuId1.value.sku_id || null,
      sku_id_back: controls.skuIdBack.value.sku_id || null,
      sku_id_spare: controls.skuIdSpare.value.sku_id || null,
      first_name: customer_data.first_name,
      last_name: customer_data.last_name,
      tyre_description: [],
      claim_data: [],
      token
    };

    _.forEach(replacementDetails, (replacementDetail, index) => {
      if (
        (customer_data.type || "").toLowerCase() === "car" &&
        controls[`stencilNumber${index}`] &&
        controls[`stencilNumber${index}`].value
      ) {
        obj.tyre_description.push(controls[`stencilNumber${index}`].value);
      }
      const findIndex = _.findIndex(obj.claim_data, {
        warranty_registration_id: replacementDetail.warranty_registration_id
      });
      if (findIndex === -1) {
        obj.claim_data.push({
          warranty_registration_id: replacementDetail.warranty_registration_id,
          original_warranty_registration_number: [
            { ticket_number: replacementDetail.warranty_registration_number }
          ]
        });
      } else {
        obj.claim_data[findIndex].original_warranty_registration_number.push({
          ticket_number: replacementDetail.warranty_registration_number
        });
      }
    });
    return obj;
  }

  /**
   * @name redirectDashboard
   * @desc The method is used to redirect on dashboard
   */
  redirectDashboard() {
    let { redirectToDashboard } = this.state;
    redirectToDashboard = true;
    this.setState({ redirectToDashboard });
  }

  isInvalidStencil(controlValue, type) {
    const regex =
      (type || "").toLowerCase() === "car"
        ? /^[BCbc][0-9]{4}$/
        : /^[LHlh]{1}[0-9]{10}$/;
    if (!controlValue.match(regex)) {
      return false;
    }

    const currentYear = new Date()
      .getFullYear()
      .toString()
      .substr(-2);
    const enterdYear = controlValue.substr(-2);
    const enterdWeek = controlValue.substr(1, 2) || 0;
    let weekToCompare = 52;

    if (enterdYear == currentYear) {
      // uses double equals to for a reason.. keep it
      weekToCompare = this.getCurrentWeek();
    }

    if (
      enterdWeek === "00" ||
      enterdYear === "00" ||
      enterdYear > currentYear ||
      +enterdWeek > weekToCompare
    ) {
      return false;
    }

    return true;
  }

  render() {
    const {
      token,
      isValidStencil,
      isValidForm,
      replacementDetails,
      searchText,
      isLoading,
      warrantyTyreCheckbox,
      redirectToDashboard,
      redirectToSelectVehicle,
      // registrationNumber,
      warrantyListData,
      controls,
      userRole
    } = this.state;

    return (
      <div>
        {isLoading && <CustomSpinner />}
        {/* {redirectToDashboard && <Redirect to='/' />} */}
        {redirectToDashboard && userRole === Roles.DEALER_ROLE && <Redirect to={this.state.dashborad_path} />}
        {redirectToDashboard &&
          (userRole === Roles.SUB_DEALER_ROLE || userRole === Roles.BRANCH_ROLE) && (
            <Redirect to="/warranty-score-card" />
          )}
        {redirectToSelectVehicle && (
          <Redirect
            to={{
              pathname: "/warranty-replacement",
              search: `phoneNumber=${warrantyListData.customer_data &&
                warrantyListData.customer_data.phone_number}&t=${token}`
            }}
          />
        )}
        <Row>
          <Col>
            <div style={{ display: "flex" }}>
              <span
                onClick={this.redirectSelectVehicle}
                className="select-vehicle-label"
                style={{ color: "#545cd8", cursor: "pointer" }}
              >
                Select Vehicle asas
              </span>
              &nbsp;&nbsp;
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  fontSize: "26px",
                  cursor: "pointer",
                  marginTop: "-5px"
                }}
                color="#fdbb1f"
              />
              &nbsp;&nbsp;
              <span className="warranty-check-label">Warranty Claim</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl="7" md="7" ms="12" xs="12">
            <Row style={{ marginTop: "15px" }}>
              <Col xl="5" md="5" ms="12" sm="12" xs="12">
                <div className="d-flex-row vehical-sku-id-search">
                  <Input
                    type="text"
                    placeholder="Search..."
                    name="serachWarrantyNumber"
                    className="form-control"
                    id="serachWarrantyNumber"
                    value={searchText.value}
                    onChange={this.onSearchWarrantyNumber}
                  />
                  <FontAwesomeIcon
                    className="serach-icon"
                    icon={faSearch}
                    color="#6e777f"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {warrantyListData.warranty_data &&
                  warrantyListData.warranty_data.length > 0 && (
                    <div
                      className="table-responsive table-responsive-lg table-responsive-xl table-responsive-md table-responsive-sm"
                      style={{ marginTop: "15px" }}
                    >
                      <table className="table table-bordered warranty-check-table-head">
                        <thead id="wc-table-head">
                          <tr>
                            <th />
                            <th>Warranty Registration Number</th>
                            <th>Registration Date</th>
                            {(
                              (warrantyListData.customer_data &&
                                warrantyListData.customer_data.type) ||
                              ""
                            ).toLowerCase() === "car" && (
                                <th>Stencil Number</th>
                              )}
                            <th>Tyre Description</th>
                          </tr>
                        </thead>
                        <tbody id="wc-table-body">
                          {(warrantyListData.warranty_data || []).map(
                            (item, index) => {
                              return (
                                <tr
                                  key={item.warranty_registration_number}
                                  style={
                                    item.is_claimed
                                      ? { opacity: 0.5 }
                                      : { opacity: 1 }
                                  }
                                >
                                  <td>
                                    <label className="custom-checkboax1--container">
                                      <input
                                        disabled={item.is_claimed}
                                        type="checkbox"
                                        name={`warrantyTyreCheckbox${index}`}
                                        checked={warrantyTyreCheckbox[index]}
                                        onChange={e =>
                                          this.onChangeWarrantyTyreCheckbox(
                                            e,
                                            item,
                                            index
                                          )
                                        }
                                      />
                                      <span className="checkmark1" />
                                    </label>
                                  </td>
                                  <td>{item.warranty_registration_number}</td>
                                  <td>
                                    {
                                      <>
                                        <Moment format="DD">
                                          {item.created_at}
                                        </Moment>
                                        &nbsp;
                                        <Moment format="MMM">
                                          {item.created_at}
                                        </Moment>
                                        &nbsp;'
                                        <Moment format="YY">
                                          {item.created_at}
                                        </Moment>
                                      </>
                                    }
                                  </td>
                                  {(
                                    (warrantyListData.customer_data &&
                                      warrantyListData.customer_data.type) ||
                                    ""
                                  ).toLowerCase() === "car" && (
                                      <td>{item.stencil_number}</td>
                                    )}
                                  <td>
                                    {item.sku_id_1 && item.sku_id_1.name}
                                    {item.sku_id_1 &&
                                      !item.sku_id_1.name &&
                                      item.sku_id_back.name}
                                    {item.sku_id_1 &&
                                      !item.sku_id_1.name &&
                                      (item.sku_id_back &&
                                        !item.sku_id_back.name) &&
                                      item.sku_id_spare.name}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}

                {warrantyListData.warranty_data &&
                  !warrantyListData.warranty_data.length && (
                    <Alert color="warning" className="no-match-found">
                      No Match Found !
                    </Alert>
                  )}
              </Col>
            </Row>
          </Col>

          <Col xl="5" md="5" ms="12" xs="12">
            <Card>
              <CardBody>
                {replacementDetails && replacementDetails.length === 0 ? (
                  <>
                    <Row>
                      <Col className="product-detail-label">
                        PRODUCT DETAILS
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="product-detail-desc">
                        Please select the Warranty Numbers against which you
                        want to replace tyres.
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col
                        className="product-detail-label"
                        style={{ textTransform: "uppercase" }}
                      >
                        Details of Replacement Tyre
                      </Col>
                    </Row>
                    <hr />
                    <div>
                      <Label for="tyreDescription" className="input-label">
                        {warrantyListData.customer_data &&
                          (
                            warrantyListData.customer_data.type || ""
                          ).toLowerCase() !== "car" ? (
                          "Select Tyre Description 1"
                        ) : (
                          <span>
                            Select Tyre Description
                            <span className="required-star"> *</span>
                          </span>
                        )}
                      </Label>
                      <div className="d-flex-row vehical-sku-id-search">
                        <AsyncTypeahead
                          style={{ width: "100%" }}
                          id="skuId1"
                          isLoading={isLoading}
                          filterBy={["sku_id", "name"]}
                          labelKey={option => `${option.name}`}
                          options={this.SKUId}
                          minLength={2}
                          onChange={e => {
                            this.changeSKUId(e, "skuId1");
                          }}
                          bsSize="small"
                          name="skuId1"
                          onSearch={debounce(200, this.skuIdSearch)}
                          placeholder="Search..."
                          useCache={false}
                          renderMenuItemChildren={selectedItem => {
                            return (
                              <>
                                {selectedItem.name} - {selectedItem.sku_id}
                              </>
                            );
                          }}
                        />

                        <FontAwesomeIcon
                          className="serach-icon"
                          icon={faSearch}
                          color="#6e777f"
                        />
                      </div>

                      <div
                        className="skuid-example-text"
                        style={{ marginLeft: "3px", marginTop: "5px" }}
                      >
                        {warrantyListData.customer_data &&
                          (
                            warrantyListData.customer_data.type || ""
                          ).toLowerCase() !== "car"
                          ? "Example: 100/90 or Challenger/Blaze or 1B11110A17550PR45"
                          : "Example: 145/70 or Ultima/Blazze or 1312114012006PJDX0"}
                      </div>
                    </div>

                    {warrantyListData.customer_data &&
                      (
                        warrantyListData.customer_data.type || ""
                      ).toLowerCase() !== "car" && (
                        <>
                          {replacementDetails.length >= 2 && (
                            <div style={{ marginTop: "15px" }}>
                              <Label for="tyreQuantity" className="input-label">
                                Select Tyre Description 2
                              </Label>
                              <br />

                              <div className="d-flex-row vehical-sku-id-search">
                                <AsyncTypeahead
                                  id="skuIdBack"
                                  style={{ width: "100%" }}
                                  isLoading={isLoading}
                                  filterBy={["sku_id", "name"]}
                                  labelKey={option => `${option.name}`}
                                  options={this.SKUId}
                                  minLength={2}
                                  onChange={e => {
                                    this.changeSKUId(e, "skuIdBack");
                                  }}
                                  bsSize="small"
                                  name="skuIdBack"
                                  onSearch={debounce(200, this.skuIdSearch)}
                                  placeholder="Search..."
                                  useCache={false}
                                  renderMenuItemChildren={selectedItem => {
                                    return (
                                      <>
                                        {selectedItem.name} -{" "}
                                        {selectedItem.sku_id}
                                      </>
                                    );
                                  }}
                                />

                                <FontAwesomeIcon
                                  className="serach-icon"
                                  icon={faSearch}
                                  color="#6e777f"
                                />
                              </div>
                            </div>
                          )}

                          {replacementDetails.length > 2 && (
                            <div style={{ marginTop: "15px" }}>
                              <Label for="tyreQuantity" className="input-label">
                                Select Tyre Description 3
                              </Label>
                              <br />

                              <div className="d-flex-row vehical-sku-id-search">
                                <AsyncTypeahead
                                  id="skuIdSpare"
                                  style={{ width: "100%" }}
                                  isLoading={isLoading}
                                  filterBy={["sku_id", "name"]}
                                  labelKey={option => `${option.name}`}
                                  options={this.SKUId}
                                  minLength={2}
                                  onChange={e => {
                                    this.changeSKUId(e, "skuIdSpare");
                                  }}
                                  bsSize="small"
                                  name="skuIdSpare"
                                  onSearch={debounce(200, this.skuIdSearch)}
                                  placeholder="Search..."
                                  useCache={false}
                                  renderMenuItemChildren={selectedItem => {
                                    return (
                                      <>
                                        {selectedItem.name} -{" "}
                                        {selectedItem.sku_id}
                                      </>
                                    );
                                  }}
                                />

                                <FontAwesomeIcon
                                  className="serach-icon"
                                  icon={faSearch}
                                  color="#6e777f"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}

                    {(
                      warrantyListData.customer_data.type || ""
                    ).toLowerCase() === "car" && (
                        <>
                          <Row style={{ marginTop: "24px" }}>
                            <Col className="enter-stencil-number">
                              Enter Stencil Number{" "}
                              <span className="required-star"> *</span>
                            </Col>
                          </Row>

                          <Row>
                            {replacementDetails.map((item, index) => {
                              return (
                                <Col
                                  key={item.warranty_registration_number}
                                  xl="12"
                                  xs="12"
                                  sm="12"
                                  md="12"
                                  style={{ marginTop: "13px" }}
                                >
                                  <span className="input-label">
                                    For Tyre {index + 1}
                                  </span>
                                  <Input
                                    type="text"
                                    id={`stencilNumber${index}`}
                                    placeholder={
                                      warrantyListData.customer_data &&
                                        (
                                          warrantyListData.customer_data.type || ""
                                        ).toLowerCase() === "car"
                                        ? "Ex: B0219"
                                        : "Ex: H3012344419"
                                    }
                                    maxLength={
                                      warrantyListData.customer_data &&
                                        (
                                          warrantyListData.customer_data.type || ""
                                        ).toLowerCase() === "car"
                                        ? 5
                                        : 11
                                    }
                                    className="form-control stencil-number-textbox"
                                    name={`stencilNumber${index}`}
                                    value={
                                      controls[`stencilNumber${index}`].value
                                        .stencil_number
                                    }
                                    onChange={e =>
                                      this.handleInputStencilNumberChange(
                                        e,
                                        index
                                      )
                                    }
                                  />
                                  {controls[`stencilNumber${index}`].value
                                    .stencil_number &&
                                    controls[`stencilNumber${index}`].value
                                      .stencil_number.length ===
                                    ((
                                      warrantyListData.customer_data.type || ""
                                    ).toLowerCase() === "car"
                                      ? 5
                                      : 11) &&
                                    !controls[`stencilNumber${index}`].valid && (
                                      <span
                                        className="err-msg"
                                        style={{
                                          marginLeft: "auto",
                                          marginTop: "5px"
                                        }}
                                      >
                                        Invalid Stencil Number
                                      </span>
                                    )}
                                </Col>
                              );
                            })}
                          </Row>
                        </>
                      )}
                  </>
                )}
                <br />
                <br />
                {replacementDetails && replacementDetails.length > 0 && (
                  <Row>
                    <Col>
                      <div className="pull-right">
                        <Button
                          type="button"
                          onClick={this.cancelClick}
                          className={classnames(
                            { "w-100 mt-4": this.isMobile },
                            "mb-1",
                            "mr-1",
                            "btn-pill",
                            "next-btn",
                            "font-weight-bold"
                          )}
                          color="warning"
                          style={{
                            backgroundColor: "#e0e2e4",
                            borderColor: "#e0e2e4",
                            width: "150px"
                          }}
                        >
                          Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          type="button"
                          disabled={
                            (
                              (warrantyListData.customer_data &&
                                warrantyListData.customer_data.type) ||
                              ""
                            ).toLowerCase() === "car"
                              ? !(isValidStencil && isValidForm)
                              : !isValidForm
                          }
                          className={classnames(
                            { "w-100 mt-4": this.isMobile },
                            "mb-1",
                            "mr-1",
                            "btn-pill",
                            "next-btn",
                            "font-weight-bold"
                          )}
                          color="warning"
                          onClick={this.onSubmitClick}
                          style={{ width: "150px" }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default WarrantyCheck;
