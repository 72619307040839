import React, { Component } from "react";
import { Button, FormGroup, Label, Row, Col, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { debounce } from "throttle-debounce";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import classnames from "classnames";
import WarrantyRegistrationService from "../../../services/WarrantyRegistrationService";
import { messages } from "../../../constants/constant.message";
import StorageService from "../../../services/Storage";
import MarketingCouponService from "../../../services/MarketingCouponService";
import CustomerManagementService from "../../../services/CustomerManagementService";
import ModalService from "../../../services/ModalService";
import GoogleAnalticsService from "../../../services/GoogleAnalticsService";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { Permissions } from "../../../constants/constant.permissions";
import { Roles } from "../../../constants/constant.role";

const { ERROR_MESSAGE } = messages.COMMON;

class ProductDetailsEdit extends Component {
  SKUId = [];

  selectedSkuId1 = null;

  selectedSkuIdBack = null;

  selectedSkuIdSpare = null;

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      userDetailData: StorageService.getUserDetail(),
      multiple: false,
      customerDetails:
        (props.customerDetails && props.customerDetails.controls) || {},
      vehicleDetails:
        (props.vehicleDetails && props.vehicleDetails.controls) || {},
      productDetails:
        (props.productDetails && props.productDetails.controls) || {},
      controls: (props.productDetails && props.productDetails.controls) || {},
      isValidForm: props.productDetails && props.productDetails.isValidForm,
      showTopSku: true,
      showTopSkuBack: true,
      selectedTopSKU: "",
      selectedTopSKUBack: "",
      showTopSkuSpare: true,
      selectedTopSKUSpare: "",
      backOption: null,
      spareOption: null,
      // backOption: 'other',
      // spareOption: 'other',
      from: props.from,
      token: props.token,
      redirectToDashboard: false,
      redirectToWarrantyCheck: false,
      redirectionWarrantyCheckRegistrationNumber: "",
      isLoading: false,
      sameAsFrontTyreCheckbox: false,
      spareTyreSkuIdRadio: "",
      isValidStencil: false,
      isValidSensor: true,
      defaultValue: "",
      sensorNumberInputBoxArray: [],
      /**For LCV & SCV array number is 6 , for PCR its 5 and for 2 wheeler its 3 */
      stencilNumberArray:
        props.vehicleDetails.controls.vehicleTypeId.value &&
          (
            props.vehicleDetails.controls.vehicleTypeId.value.name || ""
          ).toLowerCase() !== "2 wheeler"
          ? ["lcv", "scv"].includes(
            (
              props.vehicleDetails.controls.vehicleTypeId.value.name || ""
            ).toLowerCase()
          ) === true
            ? [1, 2, 3, 4, 5, 6]
            : [1, 2, 3, 4, 5]
          : [1, 2, 3],
      stencilNumberInputBoxArray: [],
      selectedStencilNumberIndex: 0,
      userRole: null,
      redirectToAddCustomer: false,
      dashborad_path: "/",
      topTenSKUs: null,
      selectedTyrePosition: [],
      selectedTyrePositionErrorMsg: false,
      selectedTyrePositionTouched: false,
    };
    if (
      this.state.userDetailData &&
      this.state.userDetailData.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)
    ) {
      this.state.dashborad_path = "/dealer-dashboard";
    }

    if (
      this.state.userDetailData.permissions &&
      this.state.userDetailData.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)
    ) {
      this.state.dashborad_path = "/dealer-dashboard";
    }
    this.selectedSkuId1 =
      this.state.productDetails &&
      this.state.productDetails.skuId1 &&
      (this.state.productDetails.skuId1.value || "");

    this.selectedSkuIdBack =
      this.state.productDetails &&
      this.state.productDetails.skuIdBack &&
      (this.state.productDetails.skuIdBack.value || "");

    this.selectedSkuIdSpare =
      this.state.productDetails &&
      this.state.productDetails.skuIdSpare &&
      (this.state.productDetails.skuIdSpare.value || "");
    // setTimeout(() => {
    //   if (this.state.vehicleDetails.vehicleTypeId && this.state.vehicleDetails.vehicleTypeId.value && (this.state.vehicleDetails.vehicleTypeId.value.name || '').toLowerCase() === 'car') {
    //     if (this.state.productDetails.frontTyre1.value) {
    //       this.handleInputStencilNumberChange({ target: { name: 'frontLeftStencilNumber', value: this.state.productDetails.frontLeftStencilNumber && this.state.productDetails.frontLeftStencilNumber.value && (this.state.productDetails.frontLeftStencilNumber.value.stencil_number || '') } });
    //     }

    //     if (this.state.productDetails.frontTyre2.value) {
    //       this.handleInputStencilNumberChange({ target: { name: 'frontRightStencilNumber', value: this.state.productDetails.frontRightStencilNumber && this.state.productDetails.frontRightStencilNumber.value && (this.state.productDetails.frontRightStencilNumber.value.stencil_number || '') } });
    //     }

    //     if (this.state.productDetails.backTyre1.value) {
    //       this.handleInputStencilNumberChange({ target: { name: 'rearLeftStencilNumber', value: this.state.productDetails.rearLeftStencilNumber && this.state.productDetails.rearLeftStencilNumber.value && (this.state.productDetails.rearLeftStencilNumber.value.stencil_number || '') } });
    //     }

    //     if (this.state.productDetails.backTyre2.value) {
    //       this.handleInputStencilNumberChange({ target: { name: 'rearRightStencilNumber', value: this.state.productDetails.rearRightStencilNumber && this.state.productDetails.rearRightStencilNumber.value && (this.state.productDetails.rearRightStencilNumber.value.stencil_number || '') } });
    //     }

    //     if (this.state.productDetails.squareTyre.value) {
    //       this.handleInputStencilNumberChange({ target: { name: 'spareStencilNumberCar', value: this.state.productDetails.spareStencilNumberCar && this.state.productDetails.spareStencilNumberCar.value && (this.state.productDetails.spareStencilNumberCar.value.stencil_number || '') } });
    //     }
    //   } else {
    //     if (this.state.productDetails.frontTyre1.value) {
    //       this.handleInputStencilNumberChange({ target: { name: 'frontStencilNumber', value: this.state.productDetails.frontStencilNumber && this.state.productDetails.frontStencilNumber.value && (this.state.productDetails.frontStencilNumber.value.stencil_number || '') } });
    //     }

    //     if (this.state.productDetails.frontTyre2.value) {
    //       this.handleInputStencilNumberChange({ target: { name: 'rearStencilNumber', value: this.state.productDetails.rearStencilNumber && this.state.productDetails.rearStencilNumber.value && (this.state.productDetails.rearStencilNumber.value.stencil_number || '') } });
    //     }

    //     if (this.state.productDetails.squareTyre.value) {
    //       this.handleInputStencilNumberChange({ target: { name: 'spareStencilNumberBike', value: this.state.productDetails.spareStencilNumberBike && this.state.productDetails.spareStencilNumberBike.value && (this.state.productDetails.spareStencilNumberBike.value.stencil_number || '') } });
    //     }
    //   }
    // }, 300);
  }

  async componentDidMount() {
    this.props.updateStateSensorNumber({ sensorNumberAdded: 0 });
    GoogleAnalticsService.event("Add Warranty", "Tyre Details View");
    this.isMobile = WarrantyRegistrationService.checkUserAgent();
    let { controls, topTenSKUs, showTopSku } = this.state;
    controls["tyreQuantity"].value = null;
    this.setState({ controls, isLoading: true });
    /**Getting the top 10 sku on basis of vehicle_type_id & vehicle_type_brand_id & vehicle_type_brand_model_id & vehicle_category from props  */
    const topTen = await WarrantyRegistrationService.getTopTenSKUs({
      vehicle_type_id:
        (this.state.vehicleDetails &&
          this.state.vehicleDetails.vehicleTypeId &&
          this.state.vehicleDetails.vehicleTypeId.value &&
          this.state.vehicleDetails.vehicleTypeId.value.vehicle_type_id) ||
        null,
      vehicle_type_brand_id:
        (this.state.vehicleDetails &&
          this.state.vehicleDetails.vehicleTypeBrandId &&
          this.state.vehicleDetails.vehicleTypeBrandId.value &&
          this.state.vehicleDetails.vehicleTypeBrandId.value.brand_id) ||
        null,
      vehicle_type_brand_model_id:
        (this.state.vehicleDetails &&
          this.state.vehicleDetails.vehicleTypeBrandModelId &&
          this.state.vehicleDetails.vehicleTypeBrandModelId.value &&
          this.state.vehicleDetails.vehicleTypeBrandModelId.value.model_id) ||
        null,
      vehicle_category:
        (this.state.vehicleDetails &&
          this.state.vehicleDetails.vehicleCategory &&
          this.state.vehicleDetails.vehicleCategory.value) ||
        null,
    });
    topTenSKUs =
      topTen && topTen.data && topTen.data.data ? topTen.data.data : [];
    if (topTenSKUs.length > 0) {
      /* if(this.props.vehicleDetails.controls.vehicleTypeId.value.type !== 'scv' 
      && this.props.vehicleDetails.controls.vehicleTypeId.value.type !== 'lcv'
      && this.props.vehicleDetails.controls.vehicleTypeId.value.type !== '2 wheeler') {
        topTenSKUs.push({ group_sku: "other", name: "Other", sku_id: "other" })
      }*/
      showTopSku = true;
      this.setState({ isLoading: false });
    } else {
      showTopSku = false;
      this.setState({ isLoading: false });
    }
    this.setState((prevState) => {
      return { ...prevState };
    });
    const userDetail = StorageService.getUserDetail();
    const { type: userRole } = userDetail;
    this.setState({ userRole, topTenSKUs, showTopSku });
    this.handleValidatidation();
  }

  /**Getting the SKU id for vehicle_type */
  async getSKUId(sku_id) {
    this.setState({ isLoading: true });
    try {
      const { vehicleDetails } = this.state;
      const { data } = await WarrantyRegistrationService.getSKUId({
        vehicle_type: vehicleDetails && vehicleDetails.vehicleTypeId.value.name,
        searchquery: sku_id,
      });

      if (data) {
        this.SKUId = data.data;
      }

      this.setState((prevState) => {
        return { options: this.SKUId, ...prevState, isLoading: false };
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  /**Logic to get the current week number */
  getCurrentWeek() {
    Date.prototype.getWeek = function () {
      const onejan = new Date(this.getFullYear(), 0, 1);
      return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7);
    };

    const today = new Date();
    const weekNumber = today.getWeek();
    return weekNumber;
  }

  _skuIdSearch = (query) => {
    this.getSKUId(query);
  };

  skuIdSClick = (type) => {
    let {
      topTenSKUs,
      showTopSku,
      showTopSkuBack,
      showTopSkuSpare,
    } = this.state;

    if (type === "rear") {
      if (
        this.selectedSkuIdBack &&
        this.selectedSkuIdBack.sku_id !== "other" &&
        topTenSKUs.length > 0
      ) {
        showTopSkuBack = !showTopSkuBack;
        this.setState({ showTopSkuBack });
      }
    } else if (type === "spare") {
      if (
        this.selectedSkuIdSpare &&
        this.selectedSkuIdSpare.sku_id !== "other" &&
        topTenSKUs.length > 0
      ) {
        showTopSkuSpare = !showTopSkuSpare;
        this.setState({ showTopSkuSpare });
      }
    } else {
      if (
        this.selectedSkuId1 &&
        this.selectedSkuId1.sku_id !== "other" &&
        topTenSKUs.length > 0
      ) {
        showTopSku = !showTopSku;
        this.setState({ showTopSku });
      }
    }
  };

  /**Handling the back button and and removing the values if vehicle type is 2 wheeler */
  goBack = () => {
    let {
      controls,
      selectedTyrePosition,
      selectedStencilNumberIndex,
      selectedTopSKUSpare,
      showTopSkuSpare,
      selectedTopSKUBack,
      showTopSkuBack,
      selectedTopSKU,
      showTopSku,
    } = this.state;
    let visibleAreaLabel = "vehicleModel";
    if (
      this.props.vehicleDetails.controls.vehicleTypeId.value.type === "lcv" ||
      this.props.vehicleDetails.controls.vehicleTypeId.value.type === "scv"
    ) {
      visibleAreaLabel = "vehicleMake";
    }

    if (
      this.props.vehicleDetails.controls.vehicleTypeId.value.type === "2 wheeler"
    ) {
      selectedTyrePosition = [];
      selectedStencilNumberIndex = 0;
      controls["tyreQuantity"].value = 0;

      if (controls["skuIdSpare"].value) {
        controls["skuIdSpare"].touched = false;
        controls["skuIdSpare"].value = null;
        this.selectedSkuIdSpare = null;
        selectedTopSKUSpare = "other";
        showTopSkuSpare = true;
      } else if (controls["skuIdBack"].value) {
        controls["skuIdBack"].touched = false;
        controls["skuIdBack"].value = null;
        this.selectedSkuIdBack = null;
        selectedTopSKUBack = "other";
        showTopSkuBack = true;
      } else if (controls["skuId1"].value) {
        controls["skuId1"].touched = false;
        controls["skuId1"].value = null;
        this.selectedSkuIdBack = null;
        selectedTopSKU = "other";
        showTopSku = true;
      }
    }

    this.setState({
      controls,
      selectedTyrePosition,
      selectedStencilNumberIndex,
      selectedTopSKUSpare,
      showTopSkuSpare,
      selectedTopSKUBack,
      showTopSkuBack,
      selectedTopSKU,
      showTopSku,
    });

    this.props.updateState({
      visibleArea: visibleAreaLabel,
      productDetails: { controls, isValidForm: true },
    });
  };

  onNextClick = () => {
    this.props.updateState({ visibleArea: "mobileViewDetail" });
  };

  /**On Clicking the register button */
  onRegisterClick = () => {
    this.registerWarrantyCustomer();
  };

  /**Handles the validation */
  handleValidatidation = () => {
    const { controls, vehicleDetails, selectedStencilNumberIndex } = this.state;
    let { isValidForm } = this.state;
    const { skuId1, skuIdBack, skuIdSpare } = controls;

    let valid_count = 0;
    skuId1.valid = skuId1.value && !!skuId1.value.sku_id;
    skuIdBack.valid = skuIdBack.value && !!skuIdBack.value.sku_id;
    skuIdSpare.valid = skuIdSpare.value && !!skuIdSpare.value.sku_id;

    if (
      (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase() !==
      "2 wheeler"
    ) {
      if (skuId1.valid) {
        isValidForm = true;
      } else {
        isValidForm = false;
      }
    } else if (
      (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase() ===
      "2 wheeler"
    ) {
      if (skuId1.valid) {
        valid_count = valid_count + 1;
      }
      if (skuIdBack.valid) {
        valid_count = valid_count + 1;
      }
      if (skuIdSpare.valid) {
        valid_count = valid_count + 1;
      }

      if (selectedStencilNumberIndex == valid_count) {
        isValidForm = true;
      } else {
        isValidForm = false;
      }
    } else if (skuId1.valid || skuIdBack.valid || skuIdSpare.valid) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }

    this.setState({ controls, isValidForm });
  };

  /**Handles the Input change for the stencil Number  */
  handleInputStencilNumberChange = (e, index) => {
    const controlName = e.target.name;
    const controlValue = (e.target.value || "").toUpperCase();
    const { stencilNumberInputBoxArray, controls, vehicleDetails } = this.state;
    const {
      stencilNumber0,
      stencilNumber1,
      stencilNumber2,
      stencilNumber3,
      stencilNumber4,
      stencilNumber5,
    } = controls;
    let { isValidStencil, defaultValue } = this.state;
    let regex = "";
    let enterdWeek = 0;
    let maxStencilChar = 0;

    controls[controlName].value.stencil_number = controlValue;
    controls[controlName].touched = true;
    controls[controlName].valid = false;

    stencilNumberInputBoxArray[index].value = {
      // copy to local control
      name: `stencilNumber${index}`,
      stencil_number: controlValue,
    };

    if (controls[controlName].value.stencil_number) {
      if (
        (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase() !==
        "2 wheeler"
      ) {
        /**Allowing stencil number starting with B,C,H,L and remaining 4 character must be number */
        regex = /^[BCHLbchl][0-9]{4}$/;
        enterdWeek = controls[controlName].value.stencil_number.substr(1, 2);
        if (controls[controlName].value.stencil_number.length > 5) {
          maxStencilChar = 11;
        } else {
          maxStencilChar = 5;
        }
      } else {
        regex = /^[LHlh]{1}[0-9]{10}$/;
        enterdWeek = controls[controlName].value.stencil_number.substr(7, 2);
        maxStencilChar = 11;
      }

      if (
        controlName !== "stencilNumber0" &&
        (stencilNumber0.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase()
        )
      ) {
        stencilNumber0.value.stencil_number = controlValue;
        stencilNumber0.valid = true;
      }

      if (
        controlName !== "stencilNumber1" &&
        (stencilNumber1.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase()
        )
      ) {
        stencilNumber1.value.stencil_number = controlValue;
        stencilNumber1.valid = true;
      }

      if (
        controlName !== "stencilNumber2" &&
        (stencilNumber2.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase()
        )
      ) {
        stencilNumber2.value.stencil_number = controlValue;
        stencilNumber2.valid = true;
      }

      if (
        controlName !== "stencilNumber3" &&
        (stencilNumber3.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase()
        )
      ) {
        stencilNumber3.value.stencil_number = controlValue;
        stencilNumber3.valid = true;
      }

      if (
        controlName !== "stencilNumber4" &&
        (stencilNumber4.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase()
        )
      ) {
        stencilNumber4.value.stencil_number = controlValue;
        stencilNumber4.valid = true;
      }

      if (
        controlName !== "stencilNumber5" &&
        (stencilNumber5.value.stencil_number || "").length < maxStencilChar &&
        this.isInvalidStencil(
          controlValue,
          (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase()
        )
      ) {
        stencilNumber5.value.stencil_number = controlValue;
        stencilNumber5.valid = true;
      }

      if (
        controls[controlName].value.stencil_number.length === 5 ||
        controls[controlName].value.stencil_number.length === 11
      ) {
        if (controls[controlName].value.stencil_number.match(regex)) {
          controls[controlName].valid = true;
        } else if (
          controls[controlName].value.stencil_number.length === 11 &&
          controls[controlName].value.stencil_number.match(/^[a-zA-Z0-9]*$/)
        ) {
          controls[controlName].valid = true;
        }

        if (
          controls[controlName].value.stencil_number.length <= 5 &&
          controls[controlName].valid
        ) {
          controls[controlName].value.stencil_number = controls[
            controlName
          ].value.stencil_number.toUpperCase();
          let weekToCompare = 52;
          const currentYear = new Date()
            .getFullYear()
            .toString()
            .substr(-2);

          const enterdYear = controls[controlName].value.stencil_number.substr(
            -2
          );

          if (enterdYear == currentYear) {
            // uses double equals to for a reason.. keep it
            weekToCompare = this.getCurrentWeek();
          }
          if (
            enterdWeek === "00" ||
            enterdYear === "00" ||
            enterdYear > currentYear ||
            +enterdWeek > weekToCompare
          ) {
            controls[controlName].valid = false;
          } else {
            controls[controlName].valid = true;
          }
        }
      } else {
        controls[controlName].valid = false;
      }
      if (controls[controlName].valid) {
        defaultValue = controls[controlName].value.stencil_number;
      }
    }

    isValidStencil = false;
    isValidStencil = !_.some(stencilNumberInputBoxArray, { valid: false });

    this.setState({ controls, isValidStencil, defaultValue });
    this.handleValidatidation();
  };

  handleInputSerialSensorNumber = (e, index) => {
    let controlName = e.target.name;
    let controlValue = e.target.value || "";
    /**Regex pattern for Serial Sensor Number */
    let regex = /^[0-9]{4}-[0-9]{5}$/;
    // controlValue = controlValue.replace(/[^0-9]/g, '')

    const { sensorNumberInputBoxArray, controls } = this.state;
    let { isValidSensor, defaultValue } = this.state;

    controls[controlName].value.sensor_number = controlValue;
    controls[controlName].touched = true;
    controls[controlName].valid = false;

    sensorNumberInputBoxArray[index].value = {
      // copy to local control
      name: `sensorNumber${index}`,
      sensor_number: controlValue,
      valid: true,
    };

    if (controlValue.match(regex)) {
      controls[controlName].valid = true;
      sensorNumberInputBoxArray[index].valid = true;
    } else {
      controls[controlName].valid = false;
      sensorNumberInputBoxArray[index].valid = false;
    }
    let currentYear = new Date()
      .getFullYear()
      .toString()
      .substr(-2);
    let currentMonth = new Date()
      .getMonth()
      .toString()
      .substr(-2);
    currentMonth = currentMonth + 1;

    const month = controlValue.substring(0, 2);
    const year = controlValue.substring(2, 4);

    if (controls[controlName].valid && controlValue.length > 4) {
      if (!isNaN(year) && year <= currentYear && year > 0) {
        controls[controlName].valid = true;
        sensorNumberInputBoxArray[index].valid = true;

        if (year == currentYear) {
          if (!isNaN(month) && month <= currentMonth && month >= 1) {
            controls[controlName].valid = true;
            sensorNumberInputBoxArray[index].valid = true;
          } else {
            controls[controlName].valid = false;
            sensorNumberInputBoxArray[index].valid = false;
          }
        } else {
          if (!isNaN(month) && month <= 12 && month >= 1) {
            controls[controlName].valid = true;
            sensorNumberInputBoxArray[index].valid = true;
          } else {
            controls[controlName].valid = false;
            sensorNumberInputBoxArray[index].valid = false;
          }
        }
      } else {
        controls[controlName].valid = false;
        sensorNumberInputBoxArray[index].valid = false;
      }
    }

    let total = 0;
    isValidSensor = true;
    if (sensorNumberInputBoxArray.length > 0) {
      sensorNumberInputBoxArray.forEach(async (data) => {
        if (data.value.sensor_number !== "" && (data.valid === false || data.valid === 'false')) {
          isValidSensor = false;
        }
        if (data.value.sensor_number !== "") {
          total = total + 1;
        }
      });
    }
    this.setState({ controls, isValidSensor, defaultValue });
    this.props.updateStateSensorNumber({ sensorNumberAdded: total });
    this.handleValidatidation();
  };

  changeStencilNumberCount(count) {
    const { vehicleDetails, controls, isValidForm } = this.state;
    let {
      selectedStencilNumberIndex,
      stencilNumberInputBoxArray,
      sensorNumberInputBoxArray,
    } = this.state;

    if (
      vehicleDetails.vehicleTypeId.value &&
      (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase() ==
      "2 wheeler"
    ) {
      let skuIdCount = 0;
      if (controls.skuId1.value) {
        skuIdCount++;
      }
      if (controls.skuIdBack.value) {
        skuIdCount++;
      }
      if (controls.skuIdSpare.value) {
        skuIdCount++;
      }

      if (skuIdCount !== count) {
        return swal(
          "",
          messages.WARRANTY_REGISTRATION.SKUIDSANDTYRECOUNTNOTSAME,
          "warning"
        );
      }
    }

    stencilNumberInputBoxArray = [];
    sensorNumberInputBoxArray = [];

    if (
      vehicleDetails.vehicleTypeId.value &&
      (vehicleDetails.vehicleTypeId.value.name || "").toLowerCase() !==
      "2 wheeler"
    ) {
      for (let i = 0; i < count; i++) {
        stencilNumberInputBoxArray.push(controls[`stencilNumber${i}`]);
        sensorNumberInputBoxArray.push(controls[`sensorNumber${i}`]);
      }
    }

    controls.tyreQuantity.value = count;
    controls.tyreQuantity.touched = true;

    selectedStencilNumberIndex = count;

    this.setState({
      stencilNumberInputBoxArray,
      sensorNumberInputBoxArray,
      selectedStencilNumberIndex,
      controls,
    });
    this.props.updateState({
      visibleArea: "productDetailsEdit",
      isSameRoute: true,
      productDetails: { controls, isValidForm },
      stencilNumberInputBoxArray,
      sensorNumberInputBoxArray,
    });
  }

  isInvalidStencil(controlValue, type) {
    if (controlValue.length <= 5) {
      const regex =
        (type || "").toLowerCase() !== "2 wheeler"
          ? /^[BCHLbchl][0-9]{4}$/
          : /^[LHlh]{1}[0-9]{10}$/;
      if (!controlValue.match(regex)) {
        return false;
      }

      const currentYear = new Date()
        .getFullYear()
        .toString()
        .substr(-2);
      const enterdYear = controlValue.substr(-2);
      const enterdWeek = controlValue.substr(1, 2) || 0;
      let weekToCompare = 52;

      if (enterdYear == currentYear) {
        // uses double equals to for a reason.. keep it
        weekToCompare = this.getCurrentWeek();
      }

      if (
        enterdWeek === "00" ||
        enterdYear === "00" ||
        enterdYear > currentYear ||
        +enterdWeek > weekToCompare
      ) {
        return false;
      }

      return true;
    } else {
      const regex = /^[a-zA-Z0-9]*$/;
      if (controlValue.length === 11 && controlValue.match(regex)) {
        return true;
      } else {
        return false;
      }
    }
  }

  async registerWarrantyCustomer() {
    try {
      GoogleAnalticsService.event(
        "Add Warranty",
        "Warranty Registration Complete"
      );
      const { from, controls } = this.state;
      let { redirectionWarrantyCheckRegistrationNumber } = this.state;
      /**Method to build the Object for post method */
      const obj = WarrantyRegistrationService.buildRegistrationObject(
        this.state
      );
      this.setState({ redirectToDashboard: false });
      this.setState({ redirectToWarrantyCheck: false });
      this.props.isDataLoading(true);
      /**Register the customer */
      const {
        data,
      } = await WarrantyRegistrationService.postWarrantyRegistration(obj);
      this.props.isDataLoading(false);
      if (data && data.success) {
        const Vehicle_id = data.data && data.data.vehicle_id;
        /**If props.from is warrantyReplacement then redirect to /warranty-check */
        if (from === "warrantyReplacement") {
          swal({
            title: "Congratulations !",
            text:
              data.message ||
              messages.WARRANTY_REGISTRATION.SUCCESSREGISTRATION,
            icon: "success",
            type: "success",
            button: "Verify Warranty Check",
          }).then(() => {
            redirectionWarrantyCheckRegistrationNumber = window.btoa(
              Vehicle_id
            );
            /**For type distributor redirects to Dealer Dashbaord */
            if (this.state.userDetailData.type === Roles.DISTRIBUTOR_ROLE) {
              this.setState({ redirectToDashboard: true });
              return;
            }
            this.setState({
              redirectToWarrantyCheck: true,
              redirectionWarrantyCheckRegistrationNumber,
            });
          });
        } else {
          swal(
            "Congratulations !",
            data.message || messages.WARRANTY_REGISTRATION.SUCCESSREGISTRATION,
            "success"
          ).then(() => {
            if (this.state.userDetailData.type === Roles.DISTRIBUTOR_ROLE) {
              this.setState({ redirectToDashboard: true });
              return;
            }
            /* if (this.props.customerDetails.controls.couponCode !== null && this.props.customerDetails.controls.couponCode !== '' 
            && this.props.customerDetails.controls.couponCode && 
            ((this.props.customerDetails.controls.type === '2 wheeler' || this.props.customerDetails.controls.type === 'car'))) {
              this.checkCustomer();
            } */
          });
          /**If permission includes  "CUSTOMER_MANAGEMENT" or "VISIT_HISTORY"  then call redirectDetailsAddCustomer which calls the  "setAddCustomerRedirect" from userAction*/
          if (
            this.state.userDetailData.permissions &&
            (this.state.userDetailData.permissions.includes(
              Permissions.CUSTOMER_MANAGEMENT
            ) ||
              this.state.userDetailData.permissions.includes(Permissions.VISIT_HISTORY))
          ) {
            await this.props.redirectDetailsAddCustomer();
            localStorage.setItem(
              "add_customer",
              JSON.stringify({
                phone_number_coupon: this.props.customerDetails.controls
                  .phoneNumber.value,
                phone_number: this.props.customerDetails.controls
                  .ownerPhoneNumber.value
                  ? this.props.customerDetails.controls.ownerPhoneNumber.value
                  : this.props.customerDetails.controls.phoneNumber.value,
                qty: controls.tyreQuantity.value,
                vehicle_category: this.props.vehicleDetails.controls
                  .vehicleCategory.value,
                vehicle_id: data.data.vehicle_id,
                customer_vehicle_id: data.data.customer_vehicle_id,
                vehicle_type_id: obj.vehicle_type_id,
                customer_id: data.data.customer_id,
                token: this.props.customerDetails.controls.couponToken,
                coupon_code: this.props.customerDetails.controls.couponCode,
                vehicle_type_brand_id: obj.vehicle_type_brand_id,
                vehicle_type_brand_model_id: obj.vehicle_type_brand_model_id,
                sku_id_1: obj.sku_id_1,
                sku_id_back: obj.sku_id_back,
                sku_id_spare: obj.sku_id_spare,
                serial_number: [],
              })
            );
            this.setState({ redirectToDashboard: true });
          } else if (
            this.props.customerDetails.controls.couponCode !== null &&
            this.props.customerDetails.controls.couponCode !== "" &&
            this.props.customerDetails.controls.couponCode &&
            (this.props.customerDetails.controls.type === "2 wheeler" ||
              this.props.customerDetails.controls.type === "car" ||
              this.props.customerDetails.controls.type === "scv" ||
              this.props.customerDetails.controls.type === "lcv")
          ) {
            this.checkCustomer(obj);
          }

          if (
            this.props.customerDetails.controls.couponCode === null ||
            this.props.customerDetails.controls.couponCode === ""
          ) {
            this.setState({ redirectToDashboard: true });
          }
        }
      } else {
        swal("Sorry !", messages.WARRANTY_REGISTRATION.ERRORMESSAGE, "error");
      }
    } catch (error) {
      this.props.isDataLoading(false);
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.error[0] &&
        error.response.data.error[0].message
      ) {
        return swal("", error.response.data.error[0].message, "warning");
      }
      swal("Sorry !", messages.WARRANTY_REGISTRATION.ERRORMESSAGE, "error");
    }
  }

  checkCustomer = (obj) => {
    this.props.isDataLoading(true);

    let reqData = {
      phone_number: this.props.customerDetails.controls.ownerPhoneNumber.value
        ? this.props.customerDetails.controls.ownerPhoneNumber.value
        : this.props.customerDetails.controls.phoneNumber.value,
      // phone_number: this.props.customerDetails.controls.phoneNumber.value,
      first_name: this.props.customerDetails.controls.firstname.value,
      last_name: this.props.customerDetails.controls.lastname.value,
    };
    CustomerManagementService.addCustomer(reqData)
      .then((res) => {
        this.setState({
          customer_id: res.data.data.customer_id,
        });
        this.redeemCoupon(obj);
      })
      .catch((e) => {
        this.props.isDataLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
        this.setState({ redirectToDashboard: true });
      });
  };

  redeemCoupon = (obj) => {
    this.props.isDataLoading(true);
    let reqData = {
      phone_number: this.props.customerDetails.controls.phoneNumber.value,
      token: this.props.customerDetails.controls.couponToken,
      coupon_code: this.props.customerDetails.controls.couponCode,
      redeemed_from:
        this.props.customerDetails.controls.type === "2 wheeler" ||
          this.props.customerDetails.controls.type === "car"
          ? "warranty"
          : "customer_management",
      customer_id: this.state.customer_id,
      tyre_quantity: this.state.controls.tyreQuantity.value,
      vehicle_type_brand_id: obj.vehicle_type_brand_id,
      vehicle_type_brand_model_id: obj.vehicle_type_brand_model_id,
      sku_id_1: obj.sku_id_1,
      sku_id_back: obj.sku_id_back,
      sku_id_spare: obj.sku_id_spare,
      visit_history_id: null,
    };

    MarketingCouponService.redeemCoupon(reqData)
      .then((data) => {
        this.props.isDataLoading(false);

        if (data.data && data.data.success) {
          swal({
            title: "Congratulations!",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            this.setState({ redirectToDashboard: true });
          });
        }
      })
      .catch((e) => {
        this.props.isDataLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
        this.setState({ redirectToDashboard: true });
      });
  };

  changeSKUId(query, controlName) {
    const { controls, isValidForm } = this.state;
    controls[controlName].touched = true;
    controls[controlName].value = query && query[0];
    if (controlName === "skuId1") {
      this.selectedSkuId1 = query[0];
    }
    if (controlName === "skuIdBack") {
      this.selectedSkuIdBack = query[0];
    }
    if (controlName === "skuIdSpare") {
      this.selectedSkuIdSpare = query[0];
    }
    this.setState((prevState) => {
      return { controls, ...prevState };
    });
    this.handleValidatidation();
    this.props.updateState({
      visibleArea: "productDetailsEdit",
      isSameRoute: true,
      productDetails: { controls, isValidForm },
    });
  }

  changeSameAsFrontTyre(e) {
    const { controls, isValidForm } = this.state;
    let { sameAsFrontTyreCheckbox } = this.state;
    sameAsFrontTyreCheckbox = e.target.checked;
    if (e.target.checked) {
      controls.skuIdBack.touched = true;
      controls.skuIdBack.value = controls.skuId1.value;
    } else {
      controls.skuIdBack.value = "";
    }
    this.selectedSkuIdBack = controls.skuIdBack.value;
    this.setState({ controls, sameAsFrontTyreCheckbox });
    this.props.updateState({
      visibleArea: "productDetailsEdit",
      isSameRoute: true,
      productDetails: { controls, isValidForm },
    });
  }

  changeStencilNumberCountWheel(count) {
    let {
      sensorNumberInputBoxArray,
      selectedTyrePosition,
      controls,
      isValidForm,
      selectedStencilNumberIndex,
      backOption,
      spareOption,
      showTopSku,
    } = this.state;
    controls.tyreQuantity.value = count;
    selectedStencilNumberIndex = count;

    if (count === 3 || count === '3') {
      selectedTyrePosition = ["front", "rear", "spare"];
    } else {
      selectedTyrePosition = [];
    }

    sensorNumberInputBoxArray = [];
    for (let i = 0; i < 3; i++) {
      sensorNumberInputBoxArray.push(controls[`sensorNumber${i}`]);
    }

    sensorNumberInputBoxArray[0].value.sensor_number = "";
    sensorNumberInputBoxArray[1].value.sensor_number = "";
    sensorNumberInputBoxArray[2].value.sensor_number = "";

    controls.skuId1.value = "";
    controls.skuIdBack.value = "";
    controls.skuIdSpare.value = "";
    backOption = "other";
    spareOption = "other";
    this.selectedSkuId1 = null;
    this.selectedSkuIdBack = null;
    this.selectedSkuIdSpare = null;
    showTopSku = true;

    this.setState(
      {
        sensorNumberInputBoxArray,
        controls,
        selectedTyrePosition,
        selectedStencilNumberIndex,
        backOption,
        spareOption,
        showTopSku,
      },
      () => {
        this.handleValidatidation();
      }
    );

    this.props.updateState({
      visibleArea: "productDetailsEdit",
      isSameRoute: true,
      productDetails: { controls, isValidForm },
    });

    this.setState((prevState) => {
      return { controls, ...prevState };
    });
  }

  handleInputChangeVehicleCategory = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let {
      controls,
      backOption,
      spareOption,
      isValidForm,
      showTopSkuBack,
      showTopSkuSpare,
    } = this.state;

    if (controlName === "backOption") {
      backOption = controlValue;
      if (e.target.value === "front_tyre") {
        controls.skuIdBack.touched = true;
        controls.skuIdBack.value = controls.skuId1.value;
        if (spareOption === "rear_tyre") {
          spareOption = "other";
        }
      } else {
        controls.skuIdBack.value = "";
        showTopSkuBack = true;
      }
      this.selectedSkuIdBack = controls.skuIdBack.value;
      // this.skuIdSClick('back');
    }

    if (controlName === "spareOption") {
      spareOption = controlValue;
      if (e.target.value === "front_tyre") {
        controls.skuIdSpare.touched = true;
        controls.skuIdSpare.value = controls.skuId1.value;
      } else if (e.target.value === "rear_tyre") {
        controls.skuIdSpare.touched = true;
        controls.skuIdSpare.value = controls.skuIdBack.value;
      } else {
        controls.skuIdSpare.value = "";
        showTopSkuSpare = true;
      }
      this.selectedSkuIdSpare = controls.skuIdSpare.value;
      // this.skuIdSClick('spare');
    }

    this.setState({
      controls,
      backOption,
      spareOption,
      showTopSkuBack,
      showTopSkuSpare,
    });
    this.handleValidatidation();

    this.props.updateState({
      visibleArea: "productDetailsEdit",
      isSameRoute: true,
      productDetails: { controls, isValidForm },
    });

    this.setState((prevState) => {
      return { controls, ...prevState };
    });
  };

  onPositionSelect(type) {
    let {
      controls,
      backOption,
      spareOption,
      selectedTopSKUBack,
      selectedTopSKUSpare,
      selectedTopSKU,
      showTopSku,
      showTopSkuBack,
      showTopSkuSpare,
    } = this.state;

    if (type === "rear") {
      controls["skuIdBack"].value = null;
      backOption = "other";
      selectedTopSKUBack = "other";
      selectedTopSKUSpare = "other";
      showTopSkuBack = true;

      if (spareOption === "rear_tyre") {
        spareOption = "other";
      }
    } else if (type === "spare") {
      controls["skuIdSpare"].value = null;
      spareOption = "other";
      selectedTopSKUSpare = "other";
      showTopSkuSpare = true;
    } else {
      controls["skuId1"].value = null;
      showTopSku = true;
      backOption = "other";
      selectedTopSKUSpare = "other";

      if (spareOption === "front_tyre") {
        spareOption = "other";
      }
    }
    // this.skuIdSClick(type);
    this.setState({
      controls,
      backOption,
      spareOption,
      selectedTopSKUBack,
      selectedTopSKUSpare,
      selectedTopSKU,
      showTopSku,
      showTopSkuBack,
      showTopSkuSpare,
    });
    this.handleValidatidation();
  }

  tyrePosition2Wheel(e) {
    let {
      sensorNumberInputBoxArray,
      selectedTyrePosition,
      selectedTyrePositionTouched,
      controls,
      selectedTopSKUBack,
      selectedTopSKUSpare,
      selectedTopSKU,
      isValidForm,
      showTopSku,
      showTopSkuSpare,
      showTopSkuBack,
    } = this.state;
    let index = selectedTyrePosition.findIndex((x) => x === e.target.value);

    if (index > -1) {
      if (e.target.value === "spare") {
        controls["skuIdSpare"].touched = false;
        controls["skuIdSpare"].value = null;
        this.selectedSkuIdSpare = null;
        selectedTopSKUSpare = "other";
        showTopSkuSpare = true;
        sensorNumberInputBoxArray[2].value.sensor_number = "";
      } else if (e.target.value === "rear") {
        controls["skuIdBack"].touched = false;
        controls["skuIdBack"].value = null;
        this.selectedSkuIdBack = null;
        selectedTopSKUBack = "other";
        showTopSkuBack = true;
        sensorNumberInputBoxArray[1].value.sensor_number = "";
      } else if (e.target.value === "front") {
        controls["skuId1"].touched = false;
        controls["skuId1"].value = null;
        this.selectedSkuId1 = null;
        selectedTopSKU = "other";
        showTopSku = true;
        sensorNumberInputBoxArray[0].value.sensor_number = "";
      }

      selectedTyrePosition.splice(index, 1);
    } else {
      if (
        controls.tyreQuantity.value == selectedTyrePosition.length
      ) {
        ModalService.openAlert(
          "",
          `You have selected tyre quantity as ${controls.tyreQuantity.value}. To add more tyre update tyre quantity.`,
          "warning"
        );
        return;
      }

      selectedTyrePosition.push(e.target.value);
    }
    selectedTyrePositionTouched = true;

    this.setState({
      sensorNumberInputBoxArray,
      selectedTyrePosition,
      selectedTyrePositionTouched,
      selectedTopSKUBack,
      selectedTopSKUSpare,
      selectedTopSKU,
      showTopSku,
      showTopSkuSpare,
      showTopSkuBack,
    });
    this.handleValidatidation();

    this.props.updateState({
      visibleArea: "productDetailsEdit",
      isSameRoute: true,
      productDetails: { controls, isValidForm },
    });

    this.setState((prevState) => {
      return { controls, ...prevState };
    });
  }

  changeSpareTyre(e) {
    const { controls, isValidForm } = this.state;
    let { spareTyreSkuIdRadio } = this.state;

    // controls.spareTyreSkuIdRadio.selected = true;
    spareTyreSkuIdRadio = e.target.value;
    if (spareTyreSkuIdRadio === "sameAsFrontTyre") {
      controls.skuIdSpare.value = controls.skuId1.value;
    } else {
      controls.skuIdSpare.value = controls.skuIdBack.value;
    }
    this.setState({ controls, spareTyreSkuIdRadio });
    this.props.updateState({
      visibleArea: "productDetailsEdit",
      isSameRoute: true,
      productDetails: { controls, isValidForm },
    });

    this.handleValidatidation();
  }

  topTopSkuClick(type, sku) {
    const { controls, isValidForm } = this.state;
    let {
      showTopSku,
      selectedTopSKU,
      showTopSkuBack,
      selectedTopSKUBack,
      showTopSkuSpare,
      selectedTopSKUSpare,
    } = this.state;
    // showTopSku = !showTopSku;

    if (type === "rear") {
      showTopSkuBack = !showTopSkuBack;

      if (sku && sku.sku_id !== "other") {
        controls["skuIdBack"].touched = true;
        controls["skuIdBack"].value = sku;
        this.selectedSkuIdBack = sku;
        selectedTopSKUBack = "";
      } else {
        controls["skuIdBack"].touched = false;
        controls["skuIdBack"].value = null;
        this.selectedSkuIdBack = null;
        selectedTopSKUBack = "other";
      }
    } else if (type === "spare") {
      showTopSkuSpare = !showTopSkuSpare;
      if (sku && sku.sku_id !== "other") {
        controls["skuIdSpare"].touched = true;
        controls["skuIdSpare"].value = sku;
        this.selectedSkuIdSpare = sku;
        selectedTopSKUSpare = "";
      } else {
        controls["skuIdSpare"].touched = false;
        controls["skuIdSpare"].value = null;
        this.selectedSkuIdSpare = null;
        selectedTopSKUSpare = "other";
      }
    } else {
      showTopSku = !showTopSku;

      if (sku && sku.sku_id !== "other") {
        controls["skuId1"].touched = true;
        controls["skuId1"].value = sku;
        this.selectedSkuId1 = sku;
        selectedTopSKU = "";
      } else {
        controls["skuId1"].touched = false;
        controls["skuId1"].value = null;
        this.selectedSkuId1 = null;
        selectedTopSKU = "other";
      }
    }

    this.setState({
      controls,
      showTopSku,
      selectedTopSKU,
      showTopSkuBack,
      selectedTopSKUBack,
      showTopSkuSpare,
      selectedTopSKUSpare,
    });

    this.props.updateState({
      visibleArea: "productDetailsEdit",
      isSameRoute: true,
      productDetails: { controls, isValidForm },
    });

    this.setState((prevState) => {
      return { controls, ...prevState };
    });
    this.handleValidatidation();
  }

  render() {
    const {
      selectedTyrePosition,
      token,
      redirectToDashboard,
      redirectToWarrantyCheck,
      redirectionWarrantyCheckRegistrationNumber,
      selectedStencilNumberIndex,
      controls,
      vehicleDetails,
      isLoading,
      sameAsFrontTyreCheckbox,
      spareTyreSkuIdRadio,
      isValidForm,
      isValidStencil,
      isValidSensor,
      stencilNumberArray,
      stencilNumberInputBoxArray,
      // sensorNumberInputBoxArray,
      userRole,
      // redirectToAddCustomer,
      userDetailData,
      topTenSKUs,
      // showTopSku,
      // selectedTopSKU,
      // showTopSkuBack,
      // selectedTopSKUBack,
      backOption,
      spareOption,
      // showTopSkuSpare,
      // selectedTopSKUSpare,
    } = this.state;

    const { tyreQuantity, skuId1, skuIdBack } = controls;
    return (
      <>
        {/**REdirect to different routes when completed regsitration on basis of userRole and customer_class */}
        {redirectToDashboard &&
          (userRole === Roles.DEALER_ROLE ||
            (userDetailData && userDetailData.type === Roles.DISTRIBUTOR_ROLE)) && (
            <Redirect to={this.state.dashborad_path} />
          )}
        {redirectToDashboard &&
          (userRole === Roles.SUB_DEALER_ROLE ||
            userRole === Roles.BRANCH_ROLE ||
            userDetailData.customer_class === "na") && (
            <Redirect
              to={{
                pathname: "/warranty-score-card",
              }}
            />
          )}
        {redirectToWarrantyCheck && (
          <Redirect
            to={{
              pathname: "/warranty-check",
              search: `q=${redirectionWarrantyCheckRegistrationNumber}&t=${token}`,
            }}
          />
        )}
        <style jsx="true">
          {`
            @media screen and (min-width: 220px) and (max-width: 991px) {
              .popover,
              .dropdown-menu {
                left: -1% !important;
                top: 0px !important;
                width: 100% !important;
                position: absolute !important;
                transform: translate3d(0px, 31px, 0px) !important;
              }
            }
          `}
        </style>
        <Row className="warranty-customer-details-label">
          <Col xl="10" xs="10" ms="10" style={{ display: "flex" }}>
            <FontAwesomeIcon
              onClick={this.goBack}
              icon={faAngleLeft}
              style={{ fontSize: "20px", cursor: "pointer" }}
              color="#fdbb1f"
            />
            &nbsp;&nbsp;
            <span style={{ marginTop: "2px" }}>PRODUCT DETAILS</span>
          </Col>
        </Row>
        <div>
          <hr />
          {(vehicleDetails.vehicleTypeId.value.name || "").toLowerCase() !==
            "2 wheeler" && (
              <>
                <Row>
                  <Col>
                    <FormGroup>
                      <div>
                        <Label for="tyreQuantity" className="input-label">
                          {vehicleDetails &&
                            (
                              vehicleDetails.vehicleTypeId.value.name || ""
                            ).toLowerCase() === "2 wheeler" ? (
                            "Select Tyre Description for the front tyre"
                          ) : (
                            <span>
                              Select Tyre Description of the tyre
                              <span className="required-star"> *</span>
                            </span>
                          )}
                        </Label>
                        {controls["skuId1"] && controls["skuId1"].value && (
                          <Row>
                            <Col>
                              <button
                                type="button"
                                onClick={() => this.onPositionSelect("front")}
                                style={{ width: "50%" }}
                                className={classnames(
                                  { "w-100 mb-3": this.isMobile },
                                  "mb-2 mr-2 btn-icon btn-pill button-sku"
                                )}
                              >
                                <span
                                  style={{ width: "90%", marginTop: "5px" }}
                                  className="blue-label pull-left text-overflow-dots"
                                >
                                  {controls["skuId1"] && controls["skuId1"].value
                                    ? controls["skuId1"].value.name
                                    : "Select"}
                                </span>
                                <span className="blue-label pull-right mr-2">
                                  <img
                                    className="cursor-pointer ml-1"
                                    style={{ height: "13px", marginTop: "5px" }}
                                    src="images/right_1.png"
                                    alt=""
                                  />
                                </span>
                              </button>
                            </Col>
                          </Row>
                        )}
                        {this.state.isLoading && <CustomSpinner />}
                        {!controls["skuId1"].value && (
                          <Row style={{ marginLeft: "0", marginTop: "10px" }}>
                            {(topTenSKUs || []).map((value) => {
                              return (
                                <Col
                                  xl="4"
                                  xs="12"
                                  sm="12"
                                  md="4"
                                  style={{
                                    paddingRight: "10px",
                                    paddingLeft: "2px",
                                  }}
                                  key={value.name}
                                >
                                  <button
                                    title={value.name}
                                    type="button"
                                    onClick={() =>
                                      this.topTopSkuClick("front", value)
                                    }
                                    className={classnames(
                                      { "w-100 mb-4": this.isMobile },
                                      "mb-2 mr-2 btn-icon btn-pill btn btn-outline-secondary custom-secondary text-overflow-dots",
                                      this.selectedSkuId1 &&
                                        value.sku_id ===
                                        this.selectedSkuId1.sku_id
                                        ? "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column custom-secondary-selected"
                                        : // : this.selectedSkuId1 === null && value.sku_id === 'other'
                                        // ? "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column custom-secondary-selected"
                                        "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column"
                                    )}
                                    style={{ width: "100%" }}
                                  >
                                    <span
                                      className="input-label"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {value.name}
                                    </span>
                                  </button>
                                </Col>
                              );
                            })}
                          </Row>
                        )}
                        {!controls["skuId1"].value &&
                          vehicleDetails.vehicleTypeId.value.type &&
                          ["car", "2 wheeler"].includes(
                            vehicleDetails.vehicleTypeId.value.type.toLowerCase()
                          ) === true && (
                            <>
                              {topTenSKUs && topTenSKUs.length > 0 && (
                                <Label
                                  className={
                                    topTenSKUs.length > 0
                                      ? "number-of-tyres-label"
                                      : "number-of-tyres-label-without-bold"
                                  }
                                >
                                  Search here, if SKU is not available in the
                                  suggestions
                                </Label>
                              )}
                              <div
                                className="d-flex-row vehical-sku-id-search"
                                onClick={() => {
                                  this.skuIdSClick("front");
                                }}
                              >
                                <AsyncTypeahead
                                  style={{ width: "100%" }}
                                  id="skuId1"
                                  isLoading={isLoading}
                                  filterBy={["sku_id", "name"]}
                                  labelKey={(option) => `${option.name}`}
                                  options={this.SKUId}
                                  // labelKey="sku_id"
                                  minLength={2}
                                  onChange={(e) => {
                                    this.changeSKUId(e, "skuId1");
                                  }}
                                  bsSize="small"
                                  name="skuId1"
                                  onSearch={debounce(200, this._skuIdSearch)}
                                  placeholder="Search..."
                                  useCache={false}
                                  renderMenuItemChildren={(selectedItem) => {
                                    return (
                                      <>
                                        {selectedItem.name}
                                        {/* {selectedItem.name} - {selectedItem.sku_id} */}
                                      </>
                                    );
                                  }}
                                  selected={
                                    this.selectedSkuId1
                                      ? [this.selectedSkuId1]
                                      : []
                                  }
                                />

                                <FontAwesomeIcon
                                  className="serach-icon"
                                  icon={faSearch}
                                  color="#6e777f"
                                />
                              </div>
                            </>
                          )}
                        {!controls["skuId1"].value && (
                          <div
                            className="skuid-example-text"
                            style={{ marginLeft: "7px", marginTop: "8px" }}
                          >
                            {/* {vehicleDetails &&
                      vehicleDetails.vehicleTypeId.value &&
                      (
                        vehicleDetails.vehicleTypeId.value.name || ''
                      ).toLowerCase() !== 'car'
                      ? 'Example: 100/90 or Challenger/Blaze or 1B11110A17550PR45'
                      : 'Example: 145/70 or Ultima/Blazze or 1312114012006PJDX0'} */}
                            {/* {vehicleDetails &&
                      vehicleDetails.vehicleTypeId.value &&
                      (
                        vehicleDetails.vehicleTypeId.value.type || ''
                      ).toLowerCase() === '2 wheeler' ? 'Example: 100/90 or Challenger/Blaze or 1B11110A17550PR45'
                      : (
                        vehicleDetails.vehicleTypeId.value.type || ''
                      ).toLowerCase() === 'car' ? 'Example: 145/70 or Ultima/Blazze or 1312114012006PJDX0' :
                        (
                          vehicleDetails.vehicleTypeId.value.type || ''
                        ).toLowerCase() === 'lcv' ? 'Example: 7.00-15 or JET XTRA XLM or 1211170015012KJXX0' :
                          (
                            vehicleDetails.vehicleTypeId.value.type || ''
                          ).toLowerCase() === 'scv' ? 'Example: 155R13 or ULTIMA XPC or 1D25215Z13008QXPC0' :
                            ''} */}
                            {vehicleDetails &&
                              vehicleDetails.vehicleTypeId.value &&
                              (
                                vehicleDetails.vehicleTypeId.value.type || ""
                              ).toLowerCase() === "2 wheeler"
                              ? "Example: 90/100-10 BLAZE BA21 (TL)"
                              : (
                                vehicleDetails.vehicleTypeId.value.type || ""
                              ).toLowerCase() === "car"
                                ? "Example: 215/75 R15 Ranger H/T"
                                : /* (
                          vehicleDetails.vehicleTypeId.value.type || ''
                        ).toLowerCase() === 'lcv' ? 'Example: 215/75R15 STEEL KING' :
                          (
                            vehicleDetails.vehicleTypeId.value.type || ''
                          ).toLowerCase() === 'scv' ? 'Example: 145R12 Ultima XPC LT' : */
                                ""}
                          </div>
                        )}
                      </div>

                      {vehicleDetails &&
                        (
                          vehicleDetails.vehicleTypeId.value.name || ""
                        ).toLowerCase() === "2 wheeler" && (
                          <div style={{ marginTop: "15px" }}>
                            <Label for="tyreQuantity" className="input-label">
                              Select Tyre Description for the rear tyre
                            </Label>
                            <br />

                            <div className="d-flex-row vehical-sku-id-search">
                              <AsyncTypeahead
                                id="skuIdBack"
                                style={{ width: "100%" }}
                                isLoading={isLoading}
                                filterBy={["sku_id", "name"]}
                                labelKey={(option) => `${option.name}`}
                                options={this.SKUId}
                                minLength={2}
                                onChange={(e) => {
                                  this.changeSKUId(e, "skuIdBack");
                                }}
                                bsSize="small"
                                name="skuIdBack"
                                onSearch={debounce(200, this._skuIdSearch)}
                                placeholder="Search..."
                                useCache={false}
                                renderMenuItemChildren={(selectedItem) => {
                                  return (
                                    <>
                                      {selectedItem.name}
                                      {/* {selectedItem.name} - {selectedItem.sku_id} */}
                                    </>
                                  );
                                }}
                                selected={
                                  this.selectedSkuIdBack
                                    ? [this.selectedSkuIdBack]
                                    : []
                                }
                              />

                              <FontAwesomeIcon
                                className="serach-icon"
                                icon={faSearch}
                                color="#6e777f"
                              />
                            </div>

                            <Row style={{ marginTop: "10px" }}>
                              <Col>
                                <div style={{ marginLeft: "7px" }}>
                                  <label
                                    style={
                                      !skuId1.value ||
                                        (skuId1.value && !skuId1.value.sku_id)
                                        ? { opacity: "0.5" }
                                        : { opacity: "1" }
                                    }
                                    className="custom-checkboax1--container"
                                  >
                                    <input
                                      disabled={
                                        !skuId1.value ||
                                        (skuId1.value && !skuId1.value.sku_id)
                                      }
                                      type="checkbox"
                                      name="sameAsFrontTyreCheckbox"
                                      checked={sameAsFrontTyreCheckbox}
                                      onChange={(e) =>
                                        this.changeSameAsFrontTyre(e)
                                      }
                                    />
                                    <span className="checkmark1" />
                                  </label>
                                  <span style={{ marginLeft: "10px" }}>
                                    Same as front tyre
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}

                      {vehicleDetails &&
                        (
                          vehicleDetails.vehicleTypeId.value.name || ""
                        ).toLowerCase() === "2 wheeler" && (
                          <div style={{ marginTop: "15px" }}>
                            <Label for="tyreQuantity" className="input-label">
                              Select Tyre Description for the spare tyre
                            </Label>
                            <Row style={{ marginTop: "10px" }}>
                              <Col xl="4" md="4" sm="6" xs="6">
                                <div style={{ marginLeft: "7px" }}>
                                  <label
                                    style={
                                      !skuId1.value ||
                                        (skuId1.value && !skuId1.value.sku_id)
                                        ? { opacity: "0.5" }
                                        : { opacity: "1" }
                                    }
                                    className="custom-checkboax-radio--container"
                                  >
                                    <input
                                      type="radio"
                                      disabled={
                                        !skuId1.value ||
                                        (skuId1.value && !skuId1.value.sku_id)
                                      }
                                      value="sameAsFrontTyre"
                                      checked={
                                        spareTyreSkuIdRadio === "sameAsFrontTyre"
                                      }
                                      onChange={(e) => this.changeSpareTyre(e)}
                                    />
                                    <span className="checkmark-radio" />
                                  </label>
                                  <span style={{ marginLeft: "10px" }}>
                                    Same as front tyre
                                  </span>
                                </div>
                              </Col>
                              <Col xl="4" md="4" sm="6" xs="6">
                                <div style={{ marginLeft: "7px" }}>
                                  <label
                                    style={
                                      !skuIdBack.value ||
                                        (skuIdBack.value && !skuIdBack.value.sku_id)
                                        ? { opacity: "0.5" }
                                        : { opacity: "1" }
                                    }
                                    className="custom-checkboax-radio--container"
                                  >
                                    <input
                                      type="radio"
                                      disabled={
                                        !skuIdBack.value ||
                                        (skuIdBack.value &&
                                          !skuIdBack.value.sku_id)
                                      }
                                      value="sameAsRearTyre"
                                      checked={
                                        spareTyreSkuIdRadio === "sameAsRearTyre"
                                      }
                                      onChange={(e) => this.changeSpareTyre(e)}
                                    />
                                    <span className="checkmark-radio" />
                                  </label>
                                  <span style={{ marginLeft: "10px" }}>
                                    Same as rear tyre
                                  </span>
                                </div>
                              </Col>
                              <Col />
                            </Row>
                          </div>
                        )}

                      <Input
                        value={tyreQuantity.value}
                        type="text"
                        name="tyreQuantity"
                        id="tyreQuantity"
                        onChange={(e) => { }}
                        style={{ display: "none" }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div style={{ marginTop: "8px" }}>
                  <FormGroup>
                    <Label for="numberOfTyres" className="number-of-tyres-label">
                      Select the number of tyres your customer is buying
                    </Label>
                    <span className="required-star"> *</span>
                  </FormGroup>
                </div>

                <Row style={{ marginTop: "-9px" }}>
                  {stencilNumberArray.map((index) => {
                    return (
                      <Col
                        key={index}
                        xl="2"
                        xs="2"
                        sm="2"
                        md="2"
                        style={{ paddingRight: "0px" }}
                      >
                        <button
                          type="button"
                          onClick={() => this.changeStencilNumberCount(index)}
                          className={classnames(
                            { "w-100 mb-3": this.isMobile },
                            "mb-2 mr-2 btn-icon btn-pill btn btn-outline-secondary custom-secondary"
                          )}
                          style={
                            selectedStencilNumberIndex === index
                              ? {
                                width: "100%",
                                backgroundColor: "#f7b924",
                                borderColor: "#f7b924",
                              }
                              : { width: "100%" }
                          }
                        >
                          <span className="input-label">{index}</span>
                        </button>
                      </Col>
                    );
                  })}
                </Row>

                {vehicleDetails.vehicleTypeId.value &&
                  (
                    vehicleDetails.vehicleTypeId.value.name || ""
                  ).toLowerCase() !== "2 wheeler" && (
                    <>
                      {stencilNumberInputBoxArray.length > 0 && (
                        <Row style={{ marginTop: "-24px" }}>
                          <Col
                            xl="6"
                            xs="12"
                            sm="6"
                            md="6"
                            style={{ marginTop: "13px" }}
                          >
                            <FormGroup style={{ marginTop: "15px" }}>
                              <Label
                                for="stencilNumberTextboxLabel"
                                className="number-of-tyres-label"
                              >
                                Enter stencil number for each tyre
                              </Label>
                              <span className="required-star"> *</span>
                            </FormGroup>
                          </Col>

                          <Col
                            xl="6"
                            xs="12"
                            sm="6"
                            md="6"
                            style={{ marginTop: "13px", paddingLeft: "0px" }}
                          >
                            <FormGroup style={{ marginTop: "15px" }}>
                              <Label
                                for="serialnumbersensor"
                                className="number-of-tyres-label"
                              >
                                Enter serial number for each sensor
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}

                      <Row style={{ marginTop: "-24px" }}>
                        {stencilNumberInputBoxArray.map((item, index) => {
                          return (
                            <>
                              <Col
                                key={item.value.name}
                                xl="5"
                                xs="12"
                                sm="5"
                                md="5"
                                style={{ marginTop: "13px", paddingRight: "0px" }}
                              >
                                <Input
                                  type="text"
                                  id={item.value.name}
                                  placeholder={
                                    vehicleDetails.vehicleTypeId.value &&
                                      (
                                        vehicleDetails.vehicleTypeId.value.name ||
                                        ""
                                      ).toLowerCase() !== "2 wheeler"
                                      ? "Ex: B0219"
                                      : "Ex: H3012344419"
                                  }
                                  maxLength={
                                    vehicleDetails.vehicleTypeId.value &&
                                      (
                                        vehicleDetails.vehicleTypeId.value.name ||
                                        ""
                                      ).toLowerCase() !== "2 wheeler" &&
                                      (
                                        vehicleDetails.vehicleTypeId.value.name ||
                                        ""
                                      ).toLowerCase() !== "lcv" &&
                                      (
                                        vehicleDetails.vehicleTypeId.value.name ||
                                        ""
                                      ).toLowerCase() !== "scv"
                                      ? 5
                                      : 11
                                  }
                                  className="form-control stencil-number-textbox"
                                  name={item.value.name}
                                  value={item.value.stencil_number}
                                  onChange={(e) =>
                                    this.handleInputStencilNumberChange(e, index)
                                  }
                                />
                                {item.value.stencil_number &&
                                  (item.value.stencil_number.length !== 5 ||
                                    item.value.stencil_number.length !== 11) &&
                                  /* item.value.stencil_number.length ===
                              (vehicleDetails.vehicleTypeId.value &&
                                (
                                  vehicleDetails.vehicleTypeId.value.name || ''
                                ).toLowerCase() !== '2 wheeler'
                                ? 5
                                : 11) && */
                                  !item.valid && (
                                    <span
                                      className="err-msg"
                                      style={{
                                        marginLeft: "auto",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Invalid Stencil Number
                                    </span>
                                  )}
                              </Col>
                              <Col
                                xl="1"
                                xs="12"
                                sm="1"
                                md="1"
                                style={{ marginTop: "20px", textAlign: "center" }}
                              >
                                <span>-</span>
                              </Col>
                              <Col
                                xl="5"
                                xs="12"
                                sm="5"
                                md="5"
                                style={{ marginTop: "13px", paddingLeft: "0px" }}
                              >
                                <Input
                                  type="text"
                                  maxLength="10"
                                  placeholder="Ex: 0320-00050"
                                  className="form-control stencil-number-textbox"
                                  name={
                                    this.state.sensorNumberInputBoxArray[index]
                                      .value.name
                                  }
                                  value={
                                    this.state.sensorNumberInputBoxArray[index]
                                      .value.sensor_number
                                  }
                                  onChange={(e) =>
                                    this.handleInputSerialSensorNumber(e, index)
                                  }
                                />
                                {this.state.sensorNumberInputBoxArray[index].value
                                  .sensor_number &&
                                  !this.state.sensorNumberInputBoxArray[index]
                                    .valid && (
                                    <span
                                      className="err-msg"
                                      style={{
                                        marginLeft: "auto",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Please enter valid sensor serial number
                                    </span>
                                  )}
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    </>
                  )}
              </>
            )}

          {(vehicleDetails.vehicleTypeId.value.name || "").toLowerCase() ===
            "2 wheeler" && (
              <>
                <div style={{ marginTop: "8px" }}>
                  <FormGroup>
                    <Label for="numberOfTyres" className="number-of-tyres-label">
                      Select the number of tyres your customer is buying
                    </Label>
                    <span className="required-star"> *</span>
                  </FormGroup>
                </div>

                <Row style={{ marginTop: "-9px" }}>
                  {stencilNumberArray.map((index) => {
                    return (
                      <Col
                        key={index}
                        xl="2"
                        xs="2"
                        sm="2"
                        md="2"
                        style={{ paddingRight: "0px" }}
                      >
                        <button
                          type="button"
                          onClick={() =>
                            this.changeStencilNumberCountWheel(index)
                          }
                          className={classnames(
                            { "w-100 mb-3": this.isMobile },
                            "mb-2 mr-2 btn-icon btn-pill btn btn-outline-secondary custom-secondary"
                          )}
                          style={
                            tyreQuantity.value === index
                              ? {
                                width: "100%",
                                backgroundColor: "#f7b924",
                                borderColor: "#f7b924",
                              }
                              : { width: "100%" }
                          }
                        >
                          <span className="input-label">{index}</span>
                        </button>
                      </Col>
                    );
                  })}
                </Row>

                {tyreQuantity.value > 0 && (
                  <>
                    <div style={{ marginTop: "8px" }}>
                      <Label
                        for="numberOfTyres"
                        className="number-of-tyres-label"
                      >
                        Select tyre position
                      </Label>
                      <span className="required-star"> *</span>
                    </div>
                    <Row>
                      <Col>
                        <label className="custom-checkboax1--container">
                          <input
                            type="checkbox"
                            name="tyre_position"
                            onChange={(e) => this.tyrePosition2Wheel(e)}
                            checked={selectedTyrePosition.includes("front")}
                            value="front"
                          />
                          <span className="checkmark1" />
                        </label>
                        <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                          Front
                        </span>

                        <label className="custom-checkboax1--container">
                          <input
                            type="checkbox"
                            name="tyre_position"
                            onChange={(e) => this.tyrePosition2Wheel(e)}
                            checked={selectedTyrePosition.includes("rear")}
                            value="rear"
                          />
                          <span className="checkmark1" />
                        </label>
                        <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                          Rear
                        </span>

                        {(tyreQuantity.value === 2 || tyreQuantity.value === '2' || tyreQuantity.value === 3 || tyreQuantity.value === '3') && (
                          <>
                            {" "}
                            <label className="custom-checkboax1--container">
                              <input
                                type="checkbox"
                                name="tyre_position"
                                onChange={(e) => this.tyrePosition2Wheel(e)}
                                checked={selectedTyrePosition.includes("spare")}
                                value="spare"
                              />
                              <span className="checkmark1" />
                            </label>
                            <span
                              style={{ marginLeft: "10px", marginRight: "10px" }}
                            >
                              Spare
                            </span>{" "}
                          </>
                        )}
                      </Col>
                    </Row>{" "}
                  </>
                )}

                {selectedTyrePosition.includes("front") && (
                  <>
                    <div style={{ marginTop: "16px" }}>
                      <Label
                        for="numberOfTyres"
                        className="number-of-tyres-label"
                      >
                        Select tyre description of front tyre
                      </Label>
                      <span className="required-star"> *</span>
                    </div>

                    {controls["skuId1"] && controls["skuId1"].value && (
                      <Row>
                        <Col>
                          <button
                            type="button"
                            onClick={() => this.onPositionSelect("front")}
                            style={{ width: "100%" }}
                            className={classnames(
                              { "w-100 mb-3": this.isMobile },
                              "mb-2 mr-2 btn-icon btn-pill button-sku"
                            )}
                          >
                            <span
                              style={{ width: "90%", marginTop: "5px" }}
                              className="blue-label pull-left text-overflow-dots"
                            >
                              {controls["skuId1"] && controls["skuId1"].value
                                ? controls["skuId1"].value.name
                                : "Select"}
                            </span>
                            <span className="blue-label pull-right mr-2">
                              <img
                                className="cursor-pointer ml-1"
                                style={{ height: "13px", marginTop: "5px" }}
                                src="images/right_1.png"
                                alt=""
                              />
                            </span>
                          </button>
                        </Col>
                        <Col>
                          <Input
                            style={{ marginTop: "3px" }}
                            type="text"
                            maxLength="10"
                            placeholder="Ex: 0320-00050"
                            className="form-control stencil-number-textbox"
                            name={
                              this.state.sensorNumberInputBoxArray[0].value.name
                            }
                            value={
                              this.state.sensorNumberInputBoxArray[0].value
                                .sensor_number
                            }
                            onChange={(e) =>
                              this.handleInputSerialSensorNumber(e, 0)
                            }
                          />
                          {this.state.sensorNumberInputBoxArray[0].value
                            .sensor_number &&
                            !this.state.sensorNumberInputBoxArray[0].valid && (
                              <span
                                className="err-msg"
                                style={{ marginLeft: "auto", marginTop: "5px" }}
                              >
                                Please enter valid sensor serial number
                              </span>
                            )}
                        </Col>
                      </Row>
                    )}

                    {!controls["skuId1"].value && (
                      <div>
                        {
                          <Row style={{ marginLeft: "0", marginTop: "10px" }}>
                            {(topTenSKUs || []).map((value) => {
                              return (
                                <Col
                                  xl="4"
                                  xs="12"
                                  sm="12"
                                  md="4"
                                  style={{
                                    paddingRight: "10px",
                                    paddingLeft: "2px",
                                  }}
                                  key={value.name}
                                >
                                  <button
                                    title={value.name}
                                    type="button"
                                    onClick={() =>
                                      this.topTopSkuClick("front", value)
                                    }
                                    className={classnames(
                                      { "w-100 mb-4": this.isMobile },
                                      "mb-2 mr-2 btn-icon btn-pill btn btn-outline-secondary custom-secondary text-overflow-dots",
                                      this.selectedSkuId1 &&
                                        value.sku_id ===
                                        this.selectedSkuId1.sku_id
                                        ? "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column custom-secondary-selected"
                                        : "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column"
                                    )}
                                    style={{ width: "100%" }}
                                  >
                                    <span
                                      className="input-label"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {value.name}
                                    </span>
                                  </button>
                                </Col>
                              );
                            })}
                          </Row>
                        }
                        {
                          <>
                            {topTenSKUs && topTenSKUs.length > 0 && (
                              <Label
                                className={
                                  topTenSKUs.length > 0
                                    ? "number-of-tyres-label"
                                    : "number-of-tyres-label-without-bold"
                                }
                              >
                                Search here, if SKU is not available in the
                                suggestions
                              </Label>
                            )}
                            <div
                              className="d-flex-row vehical-sku-id-search"
                              onClick={() => {
                                this.skuIdSClick("front");
                              }}
                            >
                              <AsyncTypeahead
                                style={{ width: "100%" }}
                                id="skuId1"
                                isLoading={isLoading}
                                filterBy={["sku_id", "name"]}
                                labelKey={(option) => `${option.name}`}
                                options={this.SKUId}
                                // labelKey="sku_id"
                                minLength={2}
                                onChange={(e) => {
                                  this.changeSKUId(e, "skuId1");
                                }}
                                bsSize="small"
                                name="skuId1"
                                onSearch={debounce(200, this._skuIdSearch)}
                                placeholder="Search..."
                                useCache={false}
                                renderMenuItemChildren={(selectedItem) => {
                                  return (
                                    <>
                                      {selectedItem.name}
                                      {/* {selectedItem.name} - {selectedItem.sku_id} */}
                                    </>
                                  );
                                }}
                                selected={
                                  this.selectedSkuId1 ? [this.selectedSkuId1] : []
                                }
                              />

                              <FontAwesomeIcon
                                className="serach-icon"
                                icon={faSearch}
                                color="#6e777f"
                              />
                            </div>
                            <div
                              className="skuid-example-text"
                              style={{ marginLeft: "7px", marginTop: "8px" }}
                            >
                              Example: 90/100-10 BLAZE BA21 (TL)
                            </div>
                          </>
                        }
                      </div>
                    )}
                  </>
                )}

                {selectedTyrePosition.includes("rear") && (
                  <>
                    <div style={{ marginTop: "16px" }}>
                      <Label
                        for="numberOfTyres"
                        className="number-of-tyres-label"
                      >
                        Select tyre description of rear tyre
                      </Label>
                      <span className="required-star"> *</span>
                    </div>

                    {controls["skuId1"].value && (
                      <Row>
                        <Col xl="4" xs="12" ms="12" className="mt-1">
                          <div className="d-flex-row">
                            <label className="custom-checkboax-radio--container">
                              <input
                                type="radio"
                                value="passenger"
                                checked={backOption === "front_tyre"}
                                onChange={(e) =>
                                  this.handleInputChangeVehicleCategory({
                                    target: {
                                      name: "backOption",
                                      value: "front_tyre",
                                    },
                                  })
                                }
                              />
                              <span className="checkmark-radio" />
                            </label>
                            <span style={{ marginLeft: "10px" }}>
                              Same as front tyre
                            </span>
                          </div>
                        </Col>

                        <Col
                          xl="4"
                          xs="12"
                          ms="12"
                          className="mt-1"
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="d-flex-row">
                            <label className="custom-checkboax-radio--container">
                              <input
                                type="radio"
                                value="passenger"
                                checked={backOption === "other"}
                                onChange={(e) =>
                                  this.handleInputChangeVehicleCategory({
                                    target: {
                                      name: "backOption",
                                      value: "other",
                                    },
                                  })
                                }
                              />
                              <span className="checkmark-radio" />
                            </label>
                            <span style={{ marginLeft: "10px" }}>Other</span>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      {controls["skuIdBack"] &&
                        controls["skuIdBack"].value &&
                        backOption === "other" && (
                          <Col>
                            <button
                              type="button"
                              onClick={() => this.onPositionSelect("rear")}
                              style={{ width: "100%" }}
                              className={classnames(
                                { "w-100 mb-3": this.isMobile },
                                "mb-2 mr-2 btn-icon btn-pill button-sku"
                              )}
                            >
                              <span
                                style={{ width: "90%", marginTop: "5px" }}
                                className="blue-label text-overflow-dots"
                              >
                                {controls["skuIdBack"] &&
                                  controls["skuIdBack"].value
                                  ? controls["skuIdBack"].value.name
                                  : "Select"}
                              </span>
                              <span className="blue-label pull-right mr-2">
                                <img
                                  className="cursor-pointer ml-1"
                                  style={{ height: "13px", marginTop: "5px" }}
                                  src="images/right_1.png"
                                  alt=""
                                />
                              </span>
                            </button>
                          </Col>
                        )}
                      {controls["skuIdBack"] && controls["skuIdBack"].value && (
                        <Col>
                          <Input
                            style={
                              backOption === "other"
                                ? { marginTop: "3px" }
                                : { marginTop: "3px", width: "45%" }
                            }
                            type="text"
                            maxLength="10"
                            placeholder="Ex: 0320-00050"
                            className="form-control stencil-number-textbox"
                            name={
                              this.state.sensorNumberInputBoxArray[1].value.name
                            }
                            value={
                              this.state.sensorNumberInputBoxArray[1].value
                                .sensor_number
                            }
                            onChange={(e) =>
                              this.handleInputSerialSensorNumber(e, 1)
                            }
                          />
                          {this.state.sensorNumberInputBoxArray[1].value
                            .sensor_number &&
                            !this.state.sensorNumberInputBoxArray[1].valid && (
                              <span
                                className="err-msg"
                                style={{ marginLeft: "auto", marginTop: "5px" }}
                              >
                                Please enter valid sensor serial number
                              </span>
                            )}
                        </Col>
                      )}
                    </Row>

                    {backOption === "other" && !controls["skuIdBack"].value && (
                      <div>
                        {
                          <Row style={{ marginLeft: "0", marginTop: "10px" }}>
                            {(topTenSKUs || []).map((value) => {
                              return (
                                <Col
                                  xl="4"
                                  xs="12"
                                  sm="12"
                                  md="4"
                                  style={{
                                    paddingRight: "10px",
                                    paddingLeft: "2px",
                                  }}
                                  key={value.name}
                                >
                                  <button
                                    title={value.name}
                                    type="button"
                                    onClick={() =>
                                      this.topTopSkuClick("rear", value)
                                    }
                                    className={classnames(
                                      { "w-100 mb-4": this.isMobile },
                                      "mb-2 mr-2 btn-icon btn-pill btn btn-outline-secondary custom-secondary text-overflow-dots",
                                      this.selectedSkuIdBack &&
                                        value.sku_id ===
                                        this.selectedSkuIdBack.sku_id
                                        ? "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column custom-secondary-selected"
                                        : "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column"
                                    )}
                                    style={{ width: "100%" }}
                                  >
                                    <span
                                      className="input-label"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {value.name}
                                    </span>
                                  </button>
                                </Col>
                              );
                            })}
                          </Row>
                        }
                        {
                          <>
                            {topTenSKUs && topTenSKUs.length > 0 && (
                              <Label
                                className={
                                  topTenSKUs.length > 0
                                    ? "number-of-tyres-label"
                                    : "number-of-tyres-label-without-bold"
                                }
                              >
                                Search here, if SKU is not available in the
                                suggestions
                              </Label>
                            )}
                            <div
                              className="d-flex-row vehical-sku-id-search"
                              onClick={() => {
                                this.skuIdSClick("rear");
                              }}
                            >
                              <AsyncTypeahead
                                id="skuIdBack"
                                style={{ width: "100%" }}
                                isLoading={isLoading}
                                filterBy={["sku_id", "name"]}
                                labelKey={(option) => `${option.name}`}
                                options={this.SKUId}
                                minLength={2}
                                onChange={(e) => {
                                  this.changeSKUId(e, "skuIdBack");
                                }}
                                bsSize="small"
                                name="skuIdBack"
                                onSearch={debounce(200, this._skuIdSearch)}
                                placeholder="Search..."
                                useCache={false}
                                renderMenuItemChildren={(selectedItem) => {
                                  return (
                                    <>
                                      {selectedItem.name}
                                      {/* {selectedItem.name} - {selectedItem.sku_id} */}
                                    </>
                                  );
                                }}
                                selected={
                                  this.selectedSkuIdBack
                                    ? [this.selectedSkuIdBack]
                                    : []
                                }
                              />

                              <FontAwesomeIcon
                                className="serach-icon"
                                icon={faSearch}
                                color="#6e777f"
                              />
                            </div>
                            <div
                              className="skuid-example-text"
                              style={{ marginLeft: "7px", marginTop: "8px" }}
                            >
                              Example: 90/100-10 BLAZE BA21 (TL)
                            </div>
                          </>
                        }
                      </div>
                    )}
                  </>
                )}

                {selectedTyrePosition.includes("spare") && (
                  <>
                    <div style={{ marginTop: "16px" }}>
                      <Label
                        for="numberOfTyres"
                        className="number-of-tyres-label"
                      >
                        Select tyre description of spare tyre
                      </Label>
                      <span className="required-star"> *</span>
                    </div>

                    {(controls["skuId1"].value ||
                      controls["skuIdBack"].value) && (
                        <Row style={{ marginBottom: "10px" }}>
                          {controls["skuId1"].value && (
                            <Col xl="4" xs="12" ms="12" className="mt-1">
                              <div className="d-flex-row">
                                <label className="custom-checkboax-radio--container">
                                  <input
                                    type="radio"
                                    checked={spareOption === "front_tyre"}
                                    onChange={(e) =>
                                      this.handleInputChangeVehicleCategory({
                                        target: {
                                          name: "spareOption",
                                          value: "front_tyre",
                                        },
                                      })
                                    }
                                  />
                                  <span className="checkmark-radio" />
                                </label>
                                <span style={{ marginLeft: "10px" }}>
                                  Same as front tyre
                                </span>
                              </div>
                            </Col>
                          )}

                          {controls["skuIdBack"].value &&
                            controls["skuIdBack"].value !==
                            controls["skuId1"].value && (
                              <Col xl="4" xs="12" ms="12" className="mt-1">
                                <div className="d-flex-row">
                                  <label className="custom-checkboax-radio--container">
                                    <input
                                      type="radio"
                                      checked={spareOption === "rear_tyre"}
                                      onChange={(e) =>
                                        this.handleInputChangeVehicleCategory({
                                          target: {
                                            name: "spareOption",
                                            value: "rear_tyre",
                                          },
                                        })
                                      }
                                    />
                                    <span className="checkmark-radio" />
                                  </label>
                                  <span style={{ marginLeft: "10px" }}>
                                    Same as rear tyre
                                  </span>
                                </div>
                              </Col>
                            )}

                          <Col xl="4" xs="12" ms="12" className="mt-1">
                            <div className="d-flex-row">
                              <label className="custom-checkboax-radio--container">
                                <input
                                  type="radio"
                                  value="passenger"
                                  checked={spareOption === "other"}
                                  onChange={(e) =>
                                    this.handleInputChangeVehicleCategory({
                                      target: {
                                        name: "spareOption",
                                        value: "other",
                                      },
                                    })
                                  }
                                />
                                <span className="checkmark-radio" />
                              </label>
                              <span style={{ marginLeft: "10px" }}>Other</span>
                            </div>
                          </Col>
                        </Row>
                      )}
                    <Row>
                      {controls["skuIdSpare"] &&
                        controls["skuIdSpare"].value &&
                        spareOption === "other" && (
                          <Col>
                            <button
                              type="button"
                              onClick={() => this.onPositionSelect("spare")}
                              style={{ width: "100%" }}
                              className={classnames(
                                { "w-100 mb-3": this.isMobile },
                                "mb-2 mr-2 btn-icon btn-pill button-sku"
                              )}
                            >
                              <span
                                style={{ width: "90%", marginTop: "5px" }}
                                className="blue-label text-overflow-dots"
                              >
                                {controls["skuIdSpare"] &&
                                  controls["skuIdSpare"].value
                                  ? controls["skuIdSpare"].value.name
                                  : "Select"}
                              </span>
                              <span className="blue-label pull-right mr-2">
                                <img
                                  className="cursor-pointer ml-1"
                                  style={{ height: "13px", marginTop: "5px" }}
                                  src="images/right_1.png"
                                  alt=""
                                />
                              </span>
                            </button>
                          </Col>
                        )}
                      {controls["skuIdSpare"] && controls["skuIdSpare"].value && (
                        <Col>
                          <Input
                            style={
                              spareOption === "other"
                                ? { marginTop: "3px" }
                                : { marginTop: "3px", width: "45%" }
                            }
                            type="text"
                            maxLength="10"
                            placeholder="Ex: 0320-00050"
                            className="form-control stencil-number-textbox"
                            name={
                              this.state.sensorNumberInputBoxArray[2].value.name
                            }
                            value={
                              this.state.sensorNumberInputBoxArray[2].value
                                .sensor_number
                            }
                            onChange={(e) =>
                              this.handleInputSerialSensorNumber(e, 2)
                            }
                          />
                          {this.state.sensorNumberInputBoxArray[2].value
                            .sensor_number &&
                            !this.state.sensorNumberInputBoxArray[2].valid && (
                              <span
                                className="err-msg"
                                style={{ marginLeft: "auto", marginTop: "5px" }}
                              >
                                Please enter valid sensor serial number
                              </span>
                            )}
                        </Col>
                      )}
                    </Row>

                    {spareOption === "other" && !controls["skuIdSpare"].value && (
                      <div>
                        {
                          <Row style={{ marginLeft: "0", marginTop: "10px" }}>
                            {(topTenSKUs || []).map((value) => {
                              return (
                                <Col
                                  xl="4"
                                  xs="12"
                                  sm="12"
                                  md="4"
                                  style={{
                                    paddingRight: "10px",
                                    paddingLeft: "2px",
                                  }}
                                  key={value.name}
                                >
                                  <button
                                    title={value.name}
                                    type="button"
                                    onClick={() =>
                                      this.topTopSkuClick("spare", value)
                                    }
                                    className={classnames(
                                      { "w-100 mb-4": this.isMobile },
                                      "mb-2 mr-2 btn-icon btn-pill btn btn-outline-secondary custom-secondary text-overflow-dots",
                                      this.selectedSkuIdSpare &&
                                        value.sku_id ===
                                        this.selectedSkuIdSpare.sku_id
                                        ? "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column custom-secondary-selected"
                                        : "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column"
                                    )}
                                    style={{ width: "100%" }}
                                  >
                                    <span
                                      className="input-label"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {value.name}
                                    </span>
                                  </button>
                                </Col>
                              );
                            })}
                          </Row>
                        }
                        {
                          <>
                            {topTenSKUs && topTenSKUs.length > 0 && (
                              <Label
                                className={
                                  topTenSKUs.length > 0
                                    ? "number-of-tyres-label"
                                    : "number-of-tyres-label-without-bold"
                                }
                              >
                                Search here, if SKU is not available in the
                                suggestions
                              </Label>
                            )}
                            <div
                              className="d-flex-row vehical-sku-id-search"
                              onClick={() => {
                                this.skuIdSClick("spare");
                              }}
                            >
                              <AsyncTypeahead
                                id="skuIdSpare"
                                style={{ width: "100%" }}
                                isLoading={isLoading}
                                filterBy={["sku_id", "name"]}
                                labelKey={(option) => `${option.name}`}
                                options={this.SKUId}
                                minLength={2}
                                onChange={(e) => {
                                  this.changeSKUId(e, "skuIdSpare");
                                }}
                                bsSize="small"
                                name="skuIdSpare"
                                onSearch={debounce(200, this._skuIdSearch)}
                                placeholder="Search..."
                                useCache={false}
                                renderMenuItemChildren={(selectedItem) => {
                                  return (
                                    <>
                                      {selectedItem.name}
                                      {/* {selectedItem.name} - {selectedItem.sku_id} */}
                                    </>
                                  );
                                }}
                                selected={
                                  this.selectedSkuIdSpare
                                    ? [this.selectedSkuIdSpare]
                                    : []
                                }
                              />

                              <FontAwesomeIcon
                                className="serach-icon"
                                icon={faSearch}
                                color="#6e777f"
                              />
                            </div>
                            <div
                              className="skuid-example-text"
                              style={{ marginLeft: "7px", marginTop: "8px" }}
                            >
                              Example: 90/100-10 BLAZE BA21 (TL)
                            </div>
                          </>
                        }
                      </div>
                    )}
                  </>
                )}
              </>
            )}

          <br />
          <Row style={{ marginTop: "12px" }}>
            <Col>
              {WarrantyRegistrationService.checkUserAgent() ? (
                <Button
                  disabled={
                    (
                      (vehicleDetails.vehicleTypeId.value &&
                        vehicleDetails.vehicleTypeId.value.name) ||
                      ""
                    ).toLowerCase() !== "2 wheeler"
                      ? !(isValidStencil && isValidForm)
                      : !isValidForm || selectedStencilNumberIndex <= 0
                  }
                  className={classnames(
                    { "w-100 mt-4": this.isMobile },
                    "mb-1",
                    "mr-1",
                    "btn-pill",
                    "next-btn",
                    "font-weight-bold"
                  )}
                  color="warning"
                  onClick={this.onNextClick}
                >
                  Next
                </Button>
              ) : (
                <Button
                  disabled={
                    (
                      (vehicleDetails.vehicleTypeId.value &&
                        vehicleDetails.vehicleTypeId.value.name) ||
                      ""
                    ).toLowerCase() !== "2 wheeler"
                      ? !(isValidStencil && isValidForm && isValidSensor)
                      : !isValidForm ||
                      selectedStencilNumberIndex <= 0 ||
                      !isValidSensor
                  }
                  className={classnames(
                    { "w-100 mt-4": this.isMobile },
                    "mb-1",
                    "mr-1",
                    "btn-pill",
                    "next-btn",
                    "font-weight-bold"
                  )}
                  color="warning"
                  onClick={this.onRegisterClick}
                >
                  Register{" "}
                  {(!isValidForm || selectedStencilNumberIndex <= 0) &&
                    !isValidSensor}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ProductDetailsEdit;
