import React, { Component } from 'react';
import lodash from "lodash";
import { bindActionCreators } from 'redux';
import Ionicon from 'react-ionicons';
import { Row, Col, CardBody, Label, Input, FormGroup, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import CampaignService from '../../services/CampaignService';
import './RecceManagementFilter.css';
import { resetfilterData, setFilterAppliedStatus, setFilterData, setFilterOpenClose, setRecceStatus } from '../../actions/RetailManagementSystemAction';
import { getFilterAppliedStatus, getFilterData, getRecceStatus } from '../RetailMarketingSystem/selector';
import { Roles } from '../../constants/constant.role';

class RecceManagementFilter extends Component {
  constructor() {
    super();
    this.state = {
      status: 'Select',
      userType: false,
      openRegion: false,
      openState: false,
      openArea: false,
      openCity: false,
      services: [],
      isLoading: false,
      userTargetRadioOptions: [
        {
          key: "Chairman's Club",
          value: 'chairmans_club'
        },
        {
          key: 'Other Club',
          value: 'other_club'
        }
      ],
      controls: {
        zone_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        region_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        area_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        state_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        city_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        created_at_start_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        created_at_end_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        recce_start_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        recce_end_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        installation_start_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        installation_end_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        club_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        status: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        }
      },
      zoneData: [],
      regionData: [],
      areaData: [],
      stateData: [],
      cityData: [],
      jkUserFilterData: {},
      isSetDataFirstTime: true,
    }
    this.dropDownRegion = React.createRef();
    this.dropDownArea = React.createRef();
    this.dropDownState = React.createRef();
    this.dropDownCity = React.createRef();
  }
  statusData = [
    "Select",
    "Recce Pending",
    "Recce Modification",
    "Recce Rejected",
    "Installation Pending",
    "Installation Approved",
    "Installation Modification",
    "Installation Completed"
  ]

  componentDidMount() {
    this.getZone();
    this.initAPI();
  }

  initAPI = async () => {
    const user = JSON.parse(localStorage.getItem('user-detail'));
    if (user.type === Roles.HEAD_OFFICE_ROLE) {
      this.setState({ userType: true })
    }
    if (this.props.filterData) {
      this.setState({ controls: this.props.filterData });
    }
    if (this.props.filterData.status.value === 'instllation_approved') {
      this.setState({ status: 'Installation Approved' })
    }
    else if (this.props.filterData.status.value === 'recce_pending') {
      this.setState({ status: 'Recce Pending' })
    }
    else if (this.props.filterData.status.value === 'installation_completed') {
      this.setState({ status: 'Installation Completed' })
    }
    else if (this.props.filterData.status.value === 'installation_modified') {
      this.setState({ status: 'Installation Modification' })
    }
    else if (this.props.filterData.status.value === 'installation_pending') {
      this.setState({ status: 'Installation Pending' })
    }
    else if (this.props.filterData.status.value === 'recce_rejected') {
      this.setState({ status: 'Recce Rejected' })
    }
    else if (this.props.filterData.status.value === 'recce_modified') {
      this.setState({ status: 'Recce Modification' })
    }
  }


  // close Filter 
  onCloseSidebar = () => {
    this.props.onCloseSidebar();
  }

  // handle control values
  handleDateChange = (controlName, e) => {
    const { controls } = this.state;
    let dateControl = controls[controlName];
    if (e === null) {
      dateControl.value = ""
    }
    else {
      dateControl.value = e;
    }

    dateControl.touched = true;
    this.setState({ controls });
  }
  // handle Club Name
  handleRadioChange = (e) => {
    let { controls } = this.state;
    controls["club_name"].value = e.target.value;
    this.setState({ controls });
  };

  // handle Status
  handleStatus = (e) => {
    const { controls } = this.state;
    if (e.target.value === 'Installation Approved') {
      controls['status'].value = 'instllation_approved'
      this.setState({ status: 'Installation Approved' })
    }
    else if (e.target.value === 'Recce Pending') {
      controls['status'].value = 'recce_pending'
      this.setState({ status: 'Recce Pending' })
    }
    else if (e.target.value === 'Installation Completed') {
      controls['status'].value = 'installation_completed'
      this.setState({ status: 'Installation Completed' })
    }
    else if (e.target.value === 'Installation Modification') {
      controls['status'].value = 'installation_modified'
      this.setState({ status: 'Installation Modification' })
    }
    else if (e.target.value === 'Installation Pending') {
      controls['status'].value = 'installation_pending'
      this.setState({ status: 'Installation Pending' })
    }
    else if (e.target.value === 'Recce Rejected') {
      controls['status'].value = 'recce_rejected'
      this.setState({ status: 'Recce Rejected' })
    }
    else if (e.target.value === 'Recce Modification') {
      controls['status'].value = 'recce_modified'
      this.setState({ status: 'Recce Modification' })
    }
    else if (e.target.value === 'Select') {
      controls['status'].value = 'all'
      this.props.setRecceStatusAction("all");
    }
  }

  // handle Zone Change

  handleInputChange = (e, index = -1) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let {
      controls,
      zoneData,
      regionData,
      areaData,
      isSetDataFirstTime
    } = this.state;
    isSetDataFirstTime = false;
    if (controlName === "region_id") {
      this.refArea.onMenuClose();
      controls[controlName].value = controlValue;
      controls.area_id.value = [];
      if (controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(
          x => x.region_name === "All"
        );
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = regionData;
          } else if (controlValue.length === regionData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
          this.refRegion.onMenuClose();
        } else if (index_all > 0) {
          controls[controlName].value = regionData;
        } else if (controlValue.length === regionData.length - 1) {
          controls[controlName].value = [];
        }
      }

      let zone_all = lodash.filter(zoneData, element => {
        return element.zone_name === "All";
      });
      let zone_id = [];
      if (!zone_all[0].isChecked) {
        zoneData.forEach(element => {
          if (element.isChecked) {
            zone_id.push(element.zone_id);
          }
        });
      }

      let index_all = controls[controlName].value.findIndex(
        x => x.region_name === "All"
      );
      if (index_all > -1) {
        this.getArea({ region_ids: [], zone_id: zone_id });
      } else {
        let region_id = [];
        controls[controlName].value.forEach(element => {
          region_id.push(element.region_id);
        });
        this.getArea({ region_ids: region_id, zone_id: zone_id });
      }
    } else if (controlName === "area_id") {
      this.refRegion.onMenuClose();
      controls[controlName].value = controlValue;
      if (controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(
          x => x.area_name === "All"
        );
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = areaData;
            this.refArea.onMenuClose();
          } else if (controlValue.length === areaData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = areaData;
          this.refArea.onMenuClose();
        } else if (controlValue.length === areaData.length - 1) {
          controls[controlName].value = [];
          this.refArea.onMenuClose();
        }
      }
    } else if (controlName === "zone_id") {
      zoneData[index].isChecked = !zoneData[index].isChecked;
      controls.area_id.value = [];
      controls.region_id.value = [];
      regionData = [];
      areaData = [];
      if (index === 0) {
        zoneData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      } else if (index > 0) {
        zoneData[0].isChecked = false;
      }
      let zone_all = lodash.filter(zoneData, element => {
        return element.zone_name === "All";
      });
      let zone_id = [];
      if (!zone_all[0].isChecked) {
        zoneData.forEach(element => {
          if (element.isChecked) {
            zone_id.push(element.zone_id);
          }
        });
      }
      controls[controlName].value = zone_id;
      controls[controlName].touched = true;
      this.getRegion(zone_id);
    }
    else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    this.setState({
      controls,
      zoneData,
      regionData,
      areaData,
    });
  };

  // Apply Filter Store value in redux store

  applyFilter = () => {
    const { controls } = this.state;
    localStorage.setItem('filterData', JSON.stringify(controls));
    this.props.setFilterDataAction(controls);
    this.props.setFilterAppliedStatusAction(true);
    this.props.setFilterOpenCloseAction(false);
    this.onCloseSidebar()

  }

  // Reset Filter

  resetFilter = () => {
    localStorage.setItem('serearchQuery', '');
    this.props.resetfilterDataAction(this.props.filterData)
    this.props.setFilterOpenCloseAction(false);
    this.props.setFilterAppliedStatusAction(false);
    this.onCloseSidebar()
  }


  // Get Zone

  getZone = () => {
    CampaignService.getZone()
      .then(data => {
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            zone_id: [],
            zone_name: "All",
            zone_code: "all"
          });
        }
        if (this.state.isSetDataFirstTime) {
          if (
            this.props.filterAppliedStatus &&
            Object.keys(this.props.filterData).length > 0
          ) {
            if (this.props.filterData.zone_id.value.length > 0) {
              let zone_id = [];

              this.props.filterData.zone_id.value.forEach(
                filter_zone_ids => {
                  data.data.data.forEach(element => {
                    if (element.zone_id === filter_zone_ids) {
                      element.isChecked = true;
                      zone_id.push(element.zone_id);
                    }
                  });
                }
              );
              this.getRegion(zone_id);
            } else if (
              this.props.filterData.zone_id.value.length === 0
            ) {
              data.data.data[0].isChecked = true;
              this.getRegion([]);
            }
          } else {
            this.getRegion([]);
            data.data.data[0].isChecked = true;
          }
        } else {
          data.data.data[0].isChecked = true;
        }

        this.setState({
          zoneData: data.data.data
        });
      })
      .catch(err => {
        return err;
      });
  };

  // Get Regions

  getRegion = zone_ids => {
    let reqData = {
      zone_id: zone_ids
    };
    CampaignService.getRegion(reqData)
      .then(data => {
        let { controls } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            zone_id: "all",
            region_id: [],
            region_name: "All"
          });
          if (this.props.filterAppliedStatus &&
            Object.keys(this.props.filterData).length > 0) {
            if (
              this.props.filterData.region_id.value &&
              this.props.filterData.region_id.value.length > 0
            ) {
              let region_id = [];
              // debugger;
              this.props.filterData.region_id.value.forEach(filter_region_ids => {
                data.data.data.forEach(element => {
                  if (element.region_id === filter_region_ids) {
                    controls.region_id.value.push(element);
                    region_id.push(element.region_id);
                  }
                });
              });

              let zone_all = lodash.filter(this.state.zoneData, element => {
                return element.zone_name === "All";
              });
              let zone_id = [];
              if (!zone_all[0].isChecked) {
                this.state.zoneData.forEach(element => {
                  if (element.isChecked) {
                    zone_id.push(element.zone_id);
                  }
                });
              }
              this.getArea(region_id, zone_id);
            }
          }
          this.setState({
            regionData: data.data.data,
            controls
          });
        }
      })
      .catch(err => {
        return err;
      });
  };

  // Get Area
  getArea = (region_id = "", zone_id = "") => {
    const { controls } = this.state;
    let reqData = {
      region_id: region_id ? region_id : controls.region_id.value,
      zone_id: zone_id ? zone_id : controls.zone_id.value
    };
    this.setState({
      isLoading: true
    });
    CampaignService.getArea(reqData)
      .then(data => {
        let { controls } = this.state;

        if (data.data.data) {
          data.data.data.splice(0, 0, {
            region_id: "",
            area_id: [],
            area_name: "All"
          });
          if (
            this.state.isSetDataFirstTime &&
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (
              this.state.jkUserFilterData.filter_area_ids &&
              this.state.jkUserFilterData.filter_area_ids.length > 0
            ) {
              this.state.jkUserFilterData.filter_area_ids.forEach(
                filter_area_ids => {
                  data.data.data.forEach(element => {
                    if (element.area_id === filter_area_ids) {
                      controls.area_id.value.push(element);
                    }
                  });
                }
              );
            }
          } else {
            this.getState();
            data.data.data[0].isChecked = true;
          }
        }
        this.setState({
          areaData: data.data.data,
          controls,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  // Get State
  getState = () => {
    this.setState({
      isLoading: true
    });
    CampaignService.getState()
      .then(data => {

        let { controls } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            area_id: "",
            state_id: [],
            name: "All"
          });
          if (
            this.state.isSetDataFirstTime &&
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (
              this.state.jkUserFilterData.filter_state_ids &&
              this.state.jkUserFilterData.filter_state_ids.length > 0
            ) {
              this.state.jkUserFilterData.filter_state_ids.forEach(
                filter_state_ids => {
                  data.data.data.forEach(element => {
                    if (element.state_id === filter_state_ids) {
                      controls.state_id.value.push(element);
                    }
                  });
                }
              );
            }
          } else {
            this.getCity();
            data.data.data[0].isChecked = true;
          }
        }
        this.setState({
          stateData: data.data.data,
          controls,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  // Get City
  getCity = () => {
    const { controls } = this.state;
    let reqData = {
      state_id: controls.state_id.value,
    };
    this.setState({
      isLoading: true
    });
    CampaignService.getCity(reqData)
      .then(data => {
        let { controls } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            state_id: "",
            city_id: [],
            name: "All"
          });
          if (
            this.state.isSetDataFirstTime &&
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (
              this.state.jkUserFilterData.filter_city_ids &&
              this.state.jkUserFilterData.filter_city_ids.length > 0
            ) {
              this.state.jkUserFilterData.filter_city_ids.forEach(
                filter_city_ids => {
                  data.data.data.forEach(element => {
                    if (element.city_id === filter_city_ids) {
                      controls.city_id.value.push(element);
                    }
                  });
                }
              );
            }
          } else {
            // this.getState();
            data.data.data[0].isChecked = true;
          }
        }
        this.setState({
          cityData: data.data.data,
          controls,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };
  // handle Region Change
  handleRegionChange = (region) => {
    const { controls } = this.state;
    const index = controls['region_id'].value.findIndex(x => x === region.region_id);
    if (index > -1) {
      controls['region_id'].value.splice(index, 1);
    } else if (Array.isArray(region.region_id) && region.region_id.length <= 0) {
      controls['region_id'].value = [];
    } else {
      controls['region_id'].value.push(region.region_id);
    }
    controls.area_id.value = [];
    controls.state_id.value = [];
    controls.city_id.value = [];
    this.setState({
      controls
    }, () => {
      this.getArea();
    })
  }
  // Handle Area Changes
  handleAreaChange = (area) => {
    const { controls } = this.state;
    const index = controls['area_id'].value.findIndex(x => x === area.area_id);
    if (index > -1) {
      controls['area_id'].value.splice(index, 1);
    } else if (Array.isArray(area.area_id) && area.area_id.length <= 0) {
      controls['area_id'].value = [];
    } else {
      controls['area_id'].value.push(area.area_id);
    }
    controls.state_id.value = [];
    controls.city_id.value = [];
    this.setState({
      controls
    }, () => {
      this.getState();
    })
  }

  // Handle State Change
  handleStateChange = (state) => {
    const { controls } = this.state;
    const index = controls['state_id'].value.findIndex(x => x === state.state_id);
    if (index > -1) {
      controls['state_id'].value.splice(index, 1);
    } else if (Array.isArray(state.state_id) && state.state_id.length <= 0) {
      controls['state_id'].value = [];
    } else {
      controls['state_id'].value.push(state.state_id);
    }
    // controls.state_id.value = [];
    controls.city_id.value = [];
    this.setState({
      controls
    }, () => {
      this.getCity();
    })
  }
  // handle City Changes
  handleCityChange = (city) => {
    const { controls } = this.state;
    const index = controls['city_id'].value.findIndex(x => x === city.city_id);
    if (index > -1) {
      controls['city_id'].value.splice(index, 1);
    } else if (Array.isArray(city.city_id) && city.city_id.length <= 0) {
      controls['city_id'].value = [];
    } else {
      controls['city_id'].value.push(city.city_id);
    }
    // controls.state_id.value = [];
    // controls.city_id.value = [];
    this.setState({
      controls
    })
  }


  generateAreaSelectedText = () => {
    const { controls } = this.state;
    let text = 'All';
    if (controls.area_id.value.length > 0) {
      text = `${controls.area_id.value.length} selected`
    }
    return text;
  }
  generateStateSelectedText = () => {
    const { controls } = this.state;
    let text = 'All';
    if (controls.state_id.value.length > 0) {
      text = `${controls.state_id.value.length} selected`
    }
    return text;
  }
  generateCitySelectedText = () => {
    const { controls } = this.state;
    let text = 'All';
    if (controls.city_id.value.length > 0) {
      text = `${controls.city_id.value.length} selected`
    }
    return text;
  }
  generateRegionSelectedText = () => {
    const { controls } = this.state;
    let text = 'All';
    var count = 0;
    controls.region_id.value.map((item, index) => {
      if (typeof (item) !== "object") {
        count = count + 1;
      }
      if (typeof (item) === 'object') {
        controls.region_id.value.splice(index, 1);
      }
    })
    if (controls.region_id.value.length > 0) {
      text = `${count} selected`
    }
    return text;
  }
  // Close All Drop Down of region,area,state and city
  closeAllDropDown = event => {
    if (this.dropDownRegion.current && !this.dropDownRegion.current.contains(event.target) && this.state.openRegion) {
      this.setState({ openRegion: false })
    }
    if (this.dropDownArea.current && !this.dropDownArea.current.contains(event.target) && this.state.openArea) {
      this.setState({ openArea: false })
    }
    if (this.dropDownState.current && !this.dropDownState.current.contains(event.target) && this.state.openState) {
      this.setState({ openState: false })
    }
    if (this.dropDownCity.current && !this.dropDownCity.current.contains(event.target) && this.state.openCity) {
      this.setState({ openCity: false })
    }
  }
  render() {
    // const { from } = this.props;
    const { regionData, controls } = this.state;
    return <div id="customer-management-filter-neww" className="height-100"
      onMouseDown={this.closeAllDropDown}
    >
      <div className="drawer-header" style={{ paddingTop: '18px' }}>
        <span className="drawer-title">Filter</span>
        <Ionicon className="cursor-pointer" icon="md-close" fontSize="24px" color="#fdbb1f" onClick={() => this.onCloseSidebar()} />
      </div>
      <hr style={{ border: 'solid 1px #ced4da', marginTop: '8px' }} />
      {this.state.isLoading && <CustomSpinner />}

      {/* Part One */}
      <div className="data-container">
        <div className="filter-vehicle-detail">
          <CardBody className="padding-top-bottom-0" >
            {this.state.userType ? <>
              <div className="filter-section-title" style={{ marginBottom: '18px' }}>Clubwise</div>
              <Row>
                <Col sm="6">
                  <label className="container-rb">
                    Chairman Club
                    <input
                      type="radio"
                      name="club_name"
                      value="Chairman's Club"
                      onChange={this.handleRadioChange.bind(this)}
                      checked={controls.club_name.value === "Chairman's Club"}
                    />
                    <span className="checkmark-rb"></span>
                  </label>
                </Col>
                <Col>
                  <label className="container-rb">
                    Other
                    <input
                      type="radio"
                      name="club_name"
                      value="Other Club"
                      onChange={this.handleRadioChange.bind(this)}
                      checked={controls.club_name.value === 'Other Club'}
                    />
                    <span className="checkmark-rb"></span>
                  </label>&nbsp;&nbsp;&nbsp;&nbsp;
                </Col>
              </Row>
              {this.state.zoneData.length > 0 && (
                <>
                  <div className="mb-2">
                    <span className="filter-type-lbl ml-0">Select Zone</span>
                  </div>
                  <div>
                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                      {(this.state.zoneData || []).map((zone, index) => (
                        <div className="d-flex" style={{ paddingRight: "20px" }}>
                          <label className="container-checkbox">
                            <input
                              type="checkbox"
                              name="zone_id"
                              checked={zone.isChecked}
                              id="checkbox"
                              value={zone.zone_id}
                              onChange={e => this.handleInputChange(e, index)}
                            />
                            &nbsp;
                            <span className="checkmark" />
                          </label>
                          <div>{zone.zone_name}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              <Row>
                <Col sm="6">
                  <div className="field-label">Region</div>
                  <div style={{ position: 'relative', marginTop: '7px' }}
                    ref={this.dropDownRegion}
                  >
                    <div
                      onClick={() => {
                        this.setState({ openRegion: !this.state.openRegion })
                      }}
                      className="form-control option-text cursor-pointer drop-down-control"
                    >
                      {this.generateRegionSelectedText()}
                      <div className="custom-drop-down-arrow">
                        <img src="/images/down-arrow.svg" alt='' />
                      </div>
                    </div>

                    {this.state.openRegion && <div className="custom-drop-down" >
                      {regionData.map((region) =>
                        <>
                          <div>
                            <label class="container-checkbox drop-down-control">
                              <input type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                className="option-text"
                                checked={controls.region_id.value.includes(region.region_id)}
                                onChange={() => this.handleRegionChange(region)}
                              />
                              {region.region_name}
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </>
                      )}
                    </div>}
                  </div>
                </Col>
                <Col sm="6">
                  <div className="field-label">Area</div>
                  <div style={{ position: 'relative', marginTop: '7px' }}
                    ref={this.dropDownArea}
                  >
                    <div
                      onClick={() => {
                        this.setState({ openArea: !this.state.openArea })
                      }}
                      className="form-control option-text cursor-pointer drop-down-control"
                    >
                      {this.generateAreaSelectedText()}
                      <div className="custom-drop-down-arrow">
                        <img src="/images/down-arrow.svg" alt='' />
                      </div>
                    </div>

                    {this.state.openArea && <div className="custom-drop-down">
                      {this.state.areaData.map((area) =>
                        <>
                          <div>
                            <label class="container-checkbox drop-down-control">
                              <input type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                className="option-text"
                                checked={controls.area_id.value.includes(area.area_id)}
                                onChange={() => this.handleAreaChange(area)}
                              />
                              {area.area_name}
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </>
                      )}
                    </div>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <div className="field-label">State</div>
                  <div style={{ position: 'relative', marginTop: '7px' }}
                    ref={this.dropDownState}
                  >
                    <div
                      onClick={() => {
                        this.setState({ openState: !this.state.openState })
                      }}
                      className="form-control option-text cursor-pointer drop-down-control"
                    >
                      {this.generateStateSelectedText()}
                      <div className="custom-drop-down-arrow">
                        <img src="/images/down-arrow.svg" alt=''
                        />
                      </div>
                    </div>

                    {this.state.openState && <div className="custom-drop-down">
                      {this.state.stateData.map((state, index) =>
                        <>
                          <div>
                            <label class="container-checkbox drop-down-control">
                              <input type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                className="option-text"
                                checked={controls.state_id.value.includes(state.state_id)}
                                onChange={() => this.handleStateChange(state)}
                              />
                              <span>{state.name}</span>
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </>
                      )}
                    </div>}
                  </div>
                </Col>
                <Col sm="6">
                  <div className="field-label">City</div>
                  <div style={{ position: 'relative', marginTop: '7px' }}
                    ref={this.dropDownCity}
                  >
                    <div
                      onClick={() => {
                        this.setState({ openCity: !this.state.openCity })
                      }}
                      className="form-control option-text cursor-pointer drop-down-control"
                    >
                      {this.generateCitySelectedText()}
                      <div className="custom-drop-down-arrow">
                        <img src="/images/down-arrow.svg" alt=''
                        />
                      </div>
                    </div>

                    {this.state.openCity && <div className="custom-drop-down">
                      {this.state.cityData.map((city) =>
                        <>
                          <div>
                            <label class="container-checkbox drop-down-control">
                              <input type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                className="option-text"
                                checked={controls.city_id.value.includes(city.city_id)}
                                onChange={() => this.handleCityChange(city)}
                              />
                              {city.name}
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </>
                      )}
                    </div>}
                  </div>
                </Col>
              </Row>
            </> :
              <>
                <FormGroup>
                  <Label for="exampleSelect">Status</Label>
                  <Input type="select" value={this.state.status} name="select" id="exampleSelect" onChange={this.handleStatus.bind(this)}>
                    {this.statusData.map(item => {
                      return <option>{item}</option>
                    })}
                  </Input>
                </FormGroup>
              </>
            }
            <div className="filter-section-title" style={{ marginBottom: '6px', marginTop: '24px' }}>Added on</div>
            <Row className="margin-top-5">
              <Col sm="12">
                <div className="display-inline">
                  <FormGroup style={{ display: "table-caption" }}>
                    <Label for="password" className="field-label display-inline">Start Date</Label>&nbsp;
                    <div className="display-inline">
                      <Label
                        onClick={e => e.preventDefault()}
                      >
                        <InputGroup style={{ width: "" }}>
                          <DatePicker className={"form-control calendar-input date-pickerr"}
                            selected={controls.created_at_start_date.value}
                            onChange={this.handleDateChange.bind(this, 'created_at_start_date')}
                            showMonthDropdown
                            maxDate={controls.created_at_start_date.value ? controls.created_at_start_date.value : null}
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="Select"
                            shouldCloseOnSelect
                            dropdownMode="select"
                            popperPlacement="bottom-end"

                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </div>
                  </FormGroup>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="display-inline">
                  <FormGroup style={{ display: "table-caption" }}>
                    <Label for="password" className="field-label display-inline">End Date</Label>&nbsp;
                    <div className="display-inline">
                      <Label
                        onClick={e => e.preventDefault()}
                      >
                        <InputGroup>
                          <DatePicker style={{ width: "183.13px" }} className={"form-control calendar-input date-pickerr"}
                            selected={controls.created_at_end_date.value}
                            onChange={this.handleDateChange.bind(this, 'created_at_end_date')}
                            showMonthDropdown
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="Select"
                            shouldCloseOnSelect
                            dropdownMode="select"
                            minDate={controls.created_at_end_date.value}
                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </div>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <div className="filter-section-title" style={{ marginBottom: '6px', marginTop: '24px' }}>Recce Date</div>
            <Row className="margin-top-5">
              <Col>
                <div className="display-inline">
                  <FormGroup style={{ display: "table-caption" }}>
                    <Label for="password" className="field-label display-inline">Start Date</Label>&nbsp;
                    <div className="display-inline">
                      <Label
                        onClick={e => e.preventDefault()}
                      >
                        <InputGroup>
                          <DatePicker className={"form-control calendar-input date-pickerr"}
                            selected={controls.recce_start_date.value}
                            onChange={this.handleDateChange.bind(this, 'recce_start_date')}
                            showMonthDropdown
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="Select"
                            shouldCloseOnSelect
                            dropdownMode="select"
                            popperPlacement="bottom-end"
                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </div>
                  </FormGroup>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="display-inline">
                  <FormGroup style={{ display: "table-caption" }}>
                    <Label for="password" className="field-label display-inline">End Date</Label>&nbsp;
                    <div className="display-inline">
                      <Label
                        onClick={e => e.preventDefault()}
                      >
                        <InputGroup>
                          <DatePicker className={"form-control calendar-input date-pickerr"}
                            selected={controls.recce_end_date.value}
                            onChange={this.handleDateChange.bind(this, 'recce_end_date')}
                            showMonthDropdown
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="Select"
                            shouldCloseOnSelect
                            dropdownMode="select"
                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </div>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <div className="filter-section-title" style={{ marginBottom: '6px', marginTop: '24px' }}>Installation Date</div>
            <Row className="margin-top-5">
              <Col>
                <div className="display-inline">
                  <FormGroup style={{ display: "table-caption" }}>
                    <Label for="password" className="field-label display-inline">Start Date</Label>&nbsp;
                    <div className="display-inline">
                      <Label
                        onClick={e => e.preventDefault()}
                      >
                        <InputGroup>
                          <DatePicker className={"form-control calendar-input date-pickerr"}
                            selected={controls.installation_start_date.value}
                            onChange={this.handleDateChange.bind(this, 'installation_start_date')}
                            showMonthDropdown
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="Select"
                            shouldCloseOnSelect
                            popperPlacement="bottom-end"
                            dropdownMode="select"
                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </div>
                  </FormGroup>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="display-inline">
                  <FormGroup style={{ display: "table-caption" }}>
                    <Label for="password" className="field-label display-inline">End Date</Label>&nbsp;
                    <div className="display-inline">
                      <Label
                        onClick={e => e.preventDefault()}
                      >
                        <InputGroup>
                          <DatePicker className={"form-control calendar-input date-pickerr"}
                            selected={controls.installation_end_date.value}
                            onChange={this.handleDateChange.bind(this, 'installation_end_date')}
                            showMonthDropdown
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="Select"
                            shouldCloseOnSelect
                            dropdownMode="select"
                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </div>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            {/* </Row> */}
          </CardBody>
        </div >
        {/* <CardBody> */}
        <Row style={{ marginLeft: '0', marginRight: '0' }} className="freeze-footer">
          <Col xs="12" md="12" lg="12" className="text-align-right footer-drawer" style={{ marginTop: '0' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter()} style={{ width: '116px' }}>Reset Filter</Button>&nbsp;&nbsp;
            <Button className="mb-1 mr-1 btn-pill action-button font-weight-bold" color="warning" onClick={this.applyFilter} style={{ width: '116px' }}>Apply</Button>
          </Col>
        </Row>
        {/* </CardBody> */}
        {/* </div> */}
      </div>


      {/* </div> */}
    </div>

  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setFilterDataAction: setFilterData,
      setFilterOpenCloseAction: setFilterOpenClose,
      resetfilterDataAction: resetfilterData,
      setRecceStatusAction: setRecceStatus,
      setFilterAppliedStatusAction: setFilterAppliedStatus
    },
    dispatch,
  );


const mapStateToProp = state => (
  {
    recceStatus: getRecceStatus(state),
    filterData: getFilterData(state),
    filterAppliedStatus: getFilterAppliedStatus(state)
  });

export default connect(mapStateToProp, mapDispatchToProps)(RecceManagementFilter);
