import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup,
  Button,
  InputGroupAddon,
} from "reactstrap";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import Lightbox from "react-image-lightbox";
import {
  getRetailMarketingStoreInformationState,
  getVendorScreen,
} from "../selector";
import {
  handleValidationRetailMarketingStoreInformation,
  setRetailRecciControls,
} from "../../../actions/RetailManagementSystemAction";
import PropTypes from "prop-types";
import lodash from "lodash";
import { retailPhotoGraphDetail } from "../RetailMarktingControls";
import "./RetailMarktingUploadPhotograph.css";
import RetailMarketingSystemService from "../../../services/RetailMarketingSystemService";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import TextareaAutosize from "react-textarea-autosize";
import RetailMarktingViewUploadPhotographHO from "../RetailMarktingViewUploadPhotographHO/RetailMarktingViewUploadPhotographHO";
function RetailMarktingUploadPhotograph({
  storeDetailControls,
  installation,
  hoInstallation,
  vendorScreen,
  installationModification,
  isApproved,
  setRetailRecciControlsAction,
  handleValidationRetailMarketingStoreInformationAction,
}) {
  // const screenState = JSON.parse(localStorage.getItem("screenState"));
  const screenStatus = localStorage.getItem("screenStatus");
  let calendarRecce = null;
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewImageLightBoxOpen, setIsPreviewImageLightBoxOpen] = useState(
    false
  );
  const [
    isPreviewImageLightBoxOpenInstallation,
    setIsPreviewImageLightBoxOpenInstallation,
  ] = useState(false);
  const [imagesToPreview, setImagesToPreview] = useState();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [recceDetailToggle, setRecceDetailToggle] = useState(true);
  const [installationDetail, setInstallationDetail] = useState(true);
  const [recceDetailColor, setRecceDetailColor] = useState(false);
  const handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    storeDetailControls[controlName].value = controlValue;
    storeDetailControls[controlName].touched = true;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(
      false,
      storeDetailControls
    );
  };
  const handleInputChangePhotograph = (e, index) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { proposedSizeForNewSignature } = storeDetailControls;
    if (controlName === "height" || controlName === "width") {
      if (!isNaN(e.target.value)) {
        proposedSizeForNewSignature[index][controlName].value = controlValue;
        if (
          proposedSizeForNewSignature[index]["height"].value &&
          proposedSizeForNewSignature[index]["width"].value
        ) {
          let totalArea =
            Number(proposedSizeForNewSignature[index]["height"].value) *
            Number(proposedSizeForNewSignature[index]["width"].value);
          totalArea = totalArea / 144;
          if (Number(totalArea) === totalArea && totalArea % 1 !== 0) {
            // check is float
            proposedSizeForNewSignature[index]["totalArea"].value = Number(
              totalArea
            ).toFixed(2);
          } else {
            proposedSizeForNewSignature[index]["totalArea"].value = totalArea;
          }
        } else {
          proposedSizeForNewSignature[index]["totalArea"].value = "";
        }
      }
    } else {
      proposedSizeForNewSignature[index][controlName].value = controlValue;
    }
    proposedSizeForNewSignature[index][controlName].touched = true;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(
      false,
      storeDetailControls
    );
  };
  const fileInputRefsFrontView = useRef([]);
  const fileInputRefsLeftView = useRef([]);
  const fileInputRefsRightView = useRef([]);
  const fileInputRefsCloseupView = useRef([]);
  const fileInputRefsLongShot = useRef([]);
  const selectPhoto = (index, type) => {
    if (type === "fileInputRefsFrontView") {
      if (index > -1 && fileInputRefsFrontView.current[index]) {
        fileInputRefsFrontView.current[index].click();
      }
    }
    if (type === "fileInputRefsLeftView") {
      if (index > -1 && fileInputRefsLeftView.current[index]) {
        fileInputRefsLeftView.current[index].click();
      }
    }
    if (type === "fileInputRefsRightView") {
      if (index > -1 && fileInputRefsRightView.current[index]) {
        fileInputRefsRightView.current[index].click();
      }
    }
    if (type === "fileInputRefsCloseupView") {
      if (index > -1 && fileInputRefsCloseupView.current[index]) {
        fileInputRefsCloseupView.current[index].click();
      }
    }
    if (type === "fileInputRefsLongShot") {
      if (index > -1 && fileInputRefsLongShot.current[index]) {
        fileInputRefsLongShot.current[index].click();
      }
    }
  };
  let fileInputAcknowledement = null;
  const browseAcknowledement = () => {
    if (fileInputAcknowledement) {
      fileInputAcknowledement.click();
    }
  };
  const uploadImage = async (event, index, controlName) => {
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      const result = await uploadRetailFile(file);
      const {
        proposedSizeForNewSignature,
        proposedSizeForNewSignatureInstallation,
      } = storeDetailControls;
      if (result) {
        const { data } = result;
        proposedSizeForNewSignature[index][controlName].value =
          data.data.imageUrl;
        proposedSizeForNewSignature[index][controlName].touched = true;
        setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        handleValidationRetailMarketingStoreInformationAction(
          false,
          storeDetailControls
        );
      }
      if (installation && result) {
        const { data } = result;
        proposedSizeForNewSignatureInstallation[index][controlName].value =
          data.data.imageUrl;
        proposedSizeForNewSignatureInstallation[index][
          controlName
        ].touched = true;
        setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        handleValidationRetailMarketingStoreInformationAction(
          false,
          storeDetailControls
        );
      }
    }
  };
  const deletePhotoRow = (index) => {
    const { proposedSizeForNewSignature } = storeDetailControls;
    proposedSizeForNewSignature.splice(index, 1);
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(
      false,
      storeDetailControls
    );
  };
  const deletePhoto = (index, controlName) => {
    const {
      proposedSizeForNewSignature,
      proposedSizeForNewSignatureInstallation,
    } = storeDetailControls;
    if (installation) {
      proposedSizeForNewSignatureInstallation[index][controlName].value = "";
      proposedSizeForNewSignatureInstallation[index][
        controlName
      ].touched = true;
      setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
      handleValidationRetailMarketingStoreInformationAction(
        false,
        storeDetailControls
      );
    }
    if (!installation) {
      proposedSizeForNewSignature[index][controlName].value = "";
      proposedSizeForNewSignature[index][controlName].touched = true;
      setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
      handleValidationRetailMarketingStoreInformationAction(
        false,
        storeDetailControls
      );
    }
  };
  const browseAcknowledementUploadFile = async (event, controlName) => {
    if (event.target.files[0]) {
      var files = event.target.files;
      var fileName = files[0].name;
      const file = new FormData();
      file.append("file", event.target.files[0]);
      const result = await uploadRetailFile(file);
      if (result) {
        const { data } = result;
        storeDetailControls[controlName].value = data.data.imageUrl;
        storeDetailControls[controlName].localFileName = fileName;
        storeDetailControls[controlName].touched = true;
        setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        handleValidationRetailMarketingStoreInformationAction(
          false,
          storeDetailControls
        );
      }
    }
  };

  const removeAcknowledement = (controlName) => {
    storeDetailControls[controlName].value = "";
    storeDetailControls[controlName].localFileName = "";
    storeDetailControls[controlName].touched = true;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(
      false,
      storeDetailControls
    );
  };

  const addMoreProposedSize = () => {
    storeDetailControls.proposedSizeForNewSignature.push(
      lodash.cloneDeep(retailPhotoGraphDetail)
    );
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  };
  const uploadRetailFile = async (reqData) => {
    setIsLoading(true);
    const response = await RetailMarketingSystemService.uploadRetailFile(
      reqData
    )
      .then((data) => {
        setIsLoading(false);
        return data;
      })
      .catch((e) => {
        console.error("e", e);
        setIsLoading(false);
        return false;
      });
    return response;
  };
  // const imagee = [];
  const closePreviewImageLightBox = () => {
    setImagesToPreview([]);
    setIsPreviewImageLightBoxOpen(false);
  };
  const closePreviewImageLightBoxInstallation = () => {
    setImagesToPreview([]);
    setIsPreviewImageLightBoxOpenInstallation(false);
  };
  const addOneYear = (oldDate) => {
    const d = new Date(oldDate);
    const newDate = d.setFullYear(d.getFullYear() + 1);
    return newDate;
  };
  const getStyle = () => {
    const obj = {
      color: "white",
    };
    if (installationDetail) {
      obj.color = "rgb(253, 187, 31)";
    }

    return obj;
  };
  const getStylee = () => {
    const obj = {
      color: "white",
    };
    if (recceDetailColor) {
      obj.color = "rgb(253, 187, 31)";
    }
    return obj;
  };
  const openPreviewImageLightBox = (index, data) => {
    var imagee = [];
    setPhotoIndex(index);
    if (data.frontView.value) {
      imagee.push(data.frontView.value);
    }
    if (data.closeupView.value) {
      imagee.push(data.closeupView.value);
    }
    if (data.longShotView.value) {
      imagee.push(data.longShotView.value);
    }
    if (data.leftView.value) {
      imagee.push(data.leftView.value);
    }
    if (data.rightView.value) {
      imagee.push(data.rightView.value);
    }
    // })
    setImagesToPreview(imagee);
    setIsPreviewImageLightBoxOpen(true);
  };
  const openPreviewImageLightBoxInstallation = (index, data) => {
    setPhotoIndex(index);
    var imagee = [];
    // storeDetailControls.proposedSizeForNewSignatureInstallation.map((data)=>{
    if (data.frontView.value) {
      imagee.push(data.frontView.value);
    }
    if (data.closeupView.value) {
      imagee.push(data.closeupView.value);
    }
    if (data.longShotView.value) {
      imagee.push(data.longShotView.value);
    }
    if (data.leftView.value) {
      imagee.push(data.leftView.value);
    }
    if (data.rightView.value) {
      imagee.push(data.rightView.value);
    }
    // })
    setImagesToPreview(imagee);
    setIsPreviewImageLightBoxOpenInstallation(true);
  };

  return (
    <div id="RetailMarktingUploadPhotograph" style={{ position: "relative" }}>
      {isLoading && <CustomSpinner />}
      {isPreviewImageLightBoxOpen && (
        <Lightbox
          mainSrc={imagesToPreview[photoIndex]}
          nextSrc={imagesToPreview[(photoIndex + 1) % imagesToPreview.length]}
          prevSrc={
            imagesToPreview[
            (photoIndex + imagesToPreview.length - 1) % imagesToPreview.length
            ]
          }
          onCloseRequest={closePreviewImageLightBox}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesToPreview.length - 1) % imagesToPreview.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesToPreview.length)
          }
        />
      )}
      {isPreviewImageLightBoxOpenInstallation && (
        <Lightbox
          mainSrc={imagesToPreview[photoIndex]}
          nextSrc={imagesToPreview[(photoIndex + 1) % imagesToPreview.length]}
          prevSrc={
            imagesToPreview[
            (photoIndex + imagesToPreview.length - 1) % imagesToPreview.length
            ]
          }
          onCloseRequest={closePreviewImageLightBoxInstallation}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesToPreview.length - 1) % imagesToPreview.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesToPreview.length)
          }
        />
      )}
      <Card>
        <CardBody>
          {(installation || installationModification) && (
            <>
              {" "}
              <Row>
                <Col xl="2" md="2" xs="2" className="mb-2 mr-2">
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    style={{
                      float: "left",
                      background: getStyle().color,
                      borderColor: "#F1F4F7",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      setRecceDetailToggle(!recceDetailToggle);
                      setInstallationDetail(true);
                      setRecceDetailColor(false);
                    }}
                  >
                    Installation Completed Details
                  </Button>
                </Col>
                <Col xl="2" md="2" xs="2" className="mb-2 ml-2">
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    style={{
                      marginLeft: "22%",
                      float: "left",
                      background: getStylee().color,
                      borderColor: "#F1F4F7",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      setRecceDetailToggle(!recceDetailToggle);
                      setInstallationDetail(false);
                      setRecceDetailColor(true);
                    }}
                  >
                    Recce Details
                  </Button>
                </Col>
              </Row>
              {!recceDetailToggle && (
                <>
                  <div style={{ marginTop: "24px" }}>Name on the Signage</div>
                  <div style={{ marginTop: "8px", fontWeight: "bold" }}>
                    {storeDetailControls.nameOnTheSignature.value}
                  </div>
                  <div style={{ marginTop: "40px", display: "flex" }}>
                    <div style={{ fontWeight: "bold" }}>
                      Proposed Sizes for New Signage
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div
                      style={{
                        fontFamily: "Helvetica",
                        fontWeight: "normal",
                        fontStyle: "italic",
                      }}
                    >
                      Note : To preview the image, click on the image.
                    </div>
                  </div>
                  <RetailMarktingViewUploadPhotographHO isEdit="false" />
                </>
              )}
              {recceDetailToggle && (
                <>
                  <Row>
                    <Col xl="2" md="2" xs="2">
                      <FormGroup>
                        <Label>
                          Installation Date{" "}
                          {!hoInstallation &&
                            !isApproved &&
                            !installationModification && (
                              <span className="required-field">*</span>
                            )}
                        </Label>
                        {installation &&
                          !hoInstallation &&
                          !isApproved &&
                          !installationModification ? (
                          <div className="d-flex">
                            <Label
                              onClick={(e) =>
                                calendarRecce.state.open && e.preventDefault()
                              }
                              className="mb-0"
                            >
                              <InputGroup>
                                <DatePicker
                                  className={"form-control"}
                                  onChange={(value) =>
                                    handleInputChange({
                                      target: {
                                        name: "installation_date",
                                        value: value,
                                      },
                                    })
                                  }
                                  name="recce_date"
                                  selected={
                                    storeDetailControls.installation_date.value
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  showMonthDropdown
                                  ref={(r) => {
                                    calendarRecce = r;
                                  }}
                                  shouldCloseOnSelect={true}
                                  showYearDropdown
                                  maxDate={new Date()}
                                  dropdownMode="select"
                                  autoComplete="off"
                                  placeholderText="Select"
                                />
                                <InputGroupAddon addonType="append">
                                  <div
                                    className="input-group-text"
                                    style={{ paddingRight: "8px" }}
                                  >
                                    <img
                                      src="/images/calendar_icon.svg"
                                      className="calendar-icon"
                                      alt=''
                                    />
                                  </div>
                                </InputGroupAddon>
                              </InputGroup>
                            </Label>
                          </div>
                        ) : (
                          ((storeDetailControls.installation_date.value &&
                            hoInstallation) ||
                            isApproved ||
                            installationModification) && (
                            <div
                              className="font-weight-bold"
                              style={{ fontSize: "16px" }}
                            >
                              <Moment format="DD MMM, YYYY">
                                {storeDetailControls.installation_date.value}
                              </Moment>
                            </div>
                          )
                        )}
                        {storeDetailControls.installation_date.showErrorMsg && (
                          <div className="error">
                            * Please select installation date.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="2" md="2" xs="2" style={{ marginLeft: "5.8%" }}>
                      <Row>Warranty End Date</Row>
                      <Row
                        style={{
                          fontWeight: "bold",
                          marginTop: "8px",
                          fontSize: "16px",
                        }}
                      >
                        {storeDetailControls.installation_date.value ? (
                          <Moment format="DD MMM, YYYY">
                            {addOneYear(
                              storeDetailControls.installation_date.value
                            )}
                          </Moment>
                        ) : (
                          `--`
                        )}
                      </Row>
                    </Col>
                  </Row>
                  {storeDetailControls.proposedSizeForNewSignatureInstallation.map(
                    (data, index) => {
                      return (
                        <Row>
                          {index === 0 && (
                            <Col xl="12" md="12" xs="12" className="mb-3">
                              <b className="font-size-16px">
                                Installation Photographs
                                <span className="prview-photo-note">
                                  Note : To preview the image, click on the
                                  image.
                                </span>
                              </b>
                            </Col>
                          )}
                          <span
                            className="count font-weight-bold"
                            style={{ marginTop: index === 0 ? "5.6%" : "2.6%" }}
                          >
                            {index + 1}
                          </span>
                          <Col xl="8" md="8" xs="12" className="d-flex">
                            <div className="d-flex-column upload-photo-wrapper">
                              <div className="d-flex justify-content-between">
                                <Label className="photo-lbl">
                                  Front View{" "}
                                  {!hoInstallation &&
                                    !isApproved &&
                                    !installationModification && (
                                      <span className="required-field">*</span>
                                    )}
                                </Label>
                                {data.frontView.value &&
                                  !hoInstallation &&
                                  !isApproved &&
                                  !installationModification && (
                                    <div className="d-flex">
                                      <img
                                        src="/images/edit.svg"
                                        alt=''
                                        className="edit-photo"
                                        onClick={() =>
                                          selectPhoto(
                                            index,
                                            "fileInputRefsFrontView"
                                          )
                                        }
                                      />
                                      &nbsp;&nbsp;
                                      <img
                                        src="/images/delete.svg"
                                        alt=''
                                        className="cursor-pointer"
                                        onClick={() =>
                                          deletePhoto(index, "frontView")
                                        }
                                        style={{ height: "14px" }}
                                      />
                                      &nbsp;&nbsp;
                                    </div>
                                  )}
                              </div>
                              {data.frontView.value ? (
                                <div
                                  className="photo-view"
                                  style={{
                                    backgroundImage: `url(${data.frontView.value})`,
                                  }}
                                  onClick={() => {
                                    openPreviewImageLightBoxInstallation(
                                      0,
                                      data
                                    );
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className="upload-photo-div d-flex"
                                  onClick={() =>
                                    selectPhoto(index, "fileInputRefsFrontView")
                                  }
                                >
                                  <img
                                    src="images/photo_upload.png"
                                    alt=''
                                    style={{ height: "20px", margin: "auto" }}
                                  />
                                  &nbsp;
                                </div>
                              )}
                              <input
                                accept="image/*"
                                type="file"
                                onChange={(e) =>
                                  uploadImage(e, index, "frontView")
                                }
                                ref={(el) =>
                                  (fileInputRefsFrontView.current[index] = el)
                                }
                                style={{ display: "none" }}
                              />
                              {data.frontView.showErrorMsg && (
                                <div className="error">
                                  * Upload front view.
                                </div>
                              )}
                            </div>
                            <div className="d-flex-column upload-photo-wrapper">
                              <div className="d-flex justify-content-between">
                                <Label className="photo-lbl">
                                  Closeup View{" "}
                                  {!hoInstallation &&
                                    !isApproved &&
                                    !installationModification && (
                                      <span className="required-field">*</span>
                                    )}
                                </Label>
                                {data.closeupView.value &&
                                  !hoInstallation &&
                                  !isApproved &&
                                  !installationModification && (
                                    <div className="d-flex">
                                      <img
                                        src="/images/edit.svg"
                                        alt=''
                                        className="edit-photo"
                                        onClick={() =>
                                          selectPhoto(
                                            index,
                                            "fileInputRefsCloseupView"
                                          )
                                        }
                                      />
                                      &nbsp;&nbsp;
                                      <img
                                        src="/images/delete.svg"
                                        alt=''
                                        className="cursor-pointer"
                                        onClick={() =>
                                          deletePhoto(index, "closeupView")
                                        }
                                        style={{ height: "14px" }}
                                      />
                                      &nbsp;&nbsp;
                                    </div>
                                  )}
                              </div>
                              {data.closeupView.value ? (
                                <div
                                  className="photo-view"
                                  style={{
                                    backgroundImage: `url(${data.closeupView.value})`,
                                  }}
                                  onClick={() =>
                                    openPreviewImageLightBoxInstallation(
                                      1,
                                      data
                                    )
                                  }
                                ></div>
                              ) : (
                                <div
                                  className="upload-photo-div d-flex"
                                  onClick={() =>
                                    selectPhoto(
                                      index,
                                      "fileInputRefsCloseupView"
                                    )
                                  }
                                >
                                  <img
                                    src="images/photo_upload.png"
                                    alt=''
                                    style={{ height: "20px", margin: "auto" }}
                                  />
                                  &nbsp;
                                </div>
                              )}
                              <input
                                accept="image/*"
                                type="file"
                                onChange={(e) =>
                                  uploadImage(e, index, "closeupView")
                                }
                                ref={(el) =>
                                  (fileInputRefsCloseupView.current[index] = el)
                                }
                                style={{ display: "none" }}
                              />
                              {data.closeupView.showErrorMsg && (
                                <div className="error">
                                  * Upload closeup view.
                                </div>
                              )}
                            </div>
                            <div className="d-flex-column upload-photo-wrapper">
                              <div className="d-flex justify-content-between">
                                <Label className="photo-lbl">
                                  Long Shot{" "}
                                  {!hoInstallation &&
                                    !isApproved &&
                                    !installationModification && (
                                      <span className="required-field">*</span>
                                    )}
                                </Label>
                                {data.longShotView.value &&
                                  !hoInstallation &&
                                  !isApproved &&
                                  !installationModification && (
                                    <div className="d-flex">
                                      <img
                                        src="/images/edit.svg"
                                        alt=''
                                        className="edit-photo"
                                        onClick={() =>
                                          selectPhoto(
                                            index,
                                            "fileInputRefsLongShot"
                                          )
                                        }
                                      />
                                      &nbsp;&nbsp;
                                      <img
                                        src="/images/delete.svg"
                                        alt=''
                                        className="cursor-pointer"
                                        onClick={() =>
                                          deletePhoto(index, "longShotView")
                                        }
                                        style={{ height: "14px" }}
                                      />
                                      &nbsp;&nbsp;
                                    </div>
                                  )}
                              </div>
                              {data.longShotView.value ? (
                                <div
                                  className="photo-view"
                                  style={{
                                    backgroundImage: `url(${data.longShotView.value})`,
                                  }}
                                  onClick={() =>
                                    openPreviewImageLightBoxInstallation(
                                      2,
                                      data
                                    )
                                  }
                                ></div>
                              ) : (
                                <div
                                  className="upload-photo-div d-flex"
                                  onClick={() =>
                                    selectPhoto(index, "fileInputRefsLongShot")
                                  }
                                >
                                  <img
                                    src="images/photo_upload.png"
                                    alt=''
                                    style={{ height: "20px", margin: "auto" }}
                                  />
                                  &nbsp;
                                </div>
                              )}
                              <input
                                accept="image/*"
                                type="file"
                                onChange={(e) =>
                                  uploadImage(e, index, "longShotView")
                                }
                                ref={(el) =>
                                  (fileInputRefsLongShot.current[index] = el)
                                }
                                style={{ display: "none" }}
                              />
                              {data.longShotView.showErrorMsg && (
                                <div className="error">
                                  * Upload Longshot view.
                                </div>
                              )}
                            </div>
                            {(!hoInstallation || data.leftView.value) &&
                              !installationModification &&
                              !isApproved && (
                                <div className="d-flex-column upload-photo-wrapper">
                                  <div className="d-flex justify-content-between">
                                    <Label className="photo-lbl">
                                      Left View
                                    </Label>
                                    {data.leftView.value &&
                                      !hoInstallation &&
                                      !isApproved &&
                                      !installationModification && (
                                        <div className="d-flex">
                                          <img
                                            src="/images/edit.svg"
                                            alt=''
                                            className="edit-photo"
                                            onClick={() =>
                                              selectPhoto(
                                                index,
                                                "fileInputRefsLeftView"
                                              )
                                            }
                                          />
                                          &nbsp;&nbsp;
                                          <img
                                            src="/images/delete.svg"
                                            alt=''
                                            className="cursor-pointer"
                                            onClick={() =>
                                              deletePhoto(index, "leftView")
                                            }
                                            style={{ height: "14px" }}
                                          />
                                          &nbsp;&nbsp;
                                        </div>
                                      )}
                                  </div>
                                  {data.leftView.value ? (
                                    <div
                                      className="photo-view"
                                      style={{
                                        backgroundImage: `url(${data.leftView.value})`,
                                      }}
                                      onClick={() =>
                                        openPreviewImageLightBoxInstallation(
                                          3,
                                          data
                                        )
                                      }
                                    ></div>
                                  ) : (
                                    <div
                                      className="upload-photo-div d-flex"
                                      onClick={() =>
                                        selectPhoto(
                                          index,
                                          "fileInputRefsLeftView"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/photo_upload.png"
                                        alt=''
                                        style={{
                                          height: "20px",
                                          margin: "auto",
                                        }}
                                      />
                                      &nbsp;
                                    </div>
                                  )}
                                  <input
                                    accept="image/*"
                                    type="file"
                                    onChange={(e) =>
                                      uploadImage(e, index, "leftView")
                                    }
                                    ref={(el) =>
                                    (fileInputRefsLeftView.current[
                                      index
                                    ] = el)
                                    }
                                    style={{ display: "none" }}
                                  />
                                </div>
                              )}
                            {(!hoInstallation || data.rightView.value) &&
                              !installationModification &&
                              !isApproved && (
                                <div className="d-flex-column upload-photo-wrapper">
                                  <div className="d-flex justify-content-between">
                                    <Label className="photo-lbl">
                                      Right View
                                    </Label>
                                    {data.rightView.value &&
                                      !hoInstallation &&
                                      !isApproved &&
                                      !installationModification && (
                                        <div className="d-flex">
                                          <img
                                            src="/images/edit.svg"
                                            alt=''
                                            className="edit-photo"
                                            onClick={() =>
                                              selectPhoto(
                                                index,
                                                "fileInputRefsRightView"
                                              )
                                            }
                                          />
                                          &nbsp;&nbsp;
                                          <img
                                            src="/images/delete.svg"
                                            alt=''
                                            className="cursor-pointer"
                                            onClick={() =>
                                              deletePhoto(index, "rightView")
                                            }
                                            style={{ height: "14px" }}
                                          />
                                          &nbsp;&nbsp;
                                        </div>
                                      )}
                                  </div>
                                  {data.rightView.value ? (
                                    <div
                                      className="photo-view"
                                      style={{
                                        backgroundImage: `url(${data.rightView.value})`,
                                      }}
                                      onClick={() =>
                                        openPreviewImageLightBoxInstallation(
                                          4,
                                          data
                                        )
                                      }
                                    ></div>
                                  ) : (
                                    <div
                                      className="upload-photo-div d-flex"
                                      onClick={() =>
                                        selectPhoto(
                                          index,
                                          "fileInputRefsRightView"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/photo_upload.png"
                                        alt=''
                                        style={{
                                          height: "20px",
                                          margin: "auto",
                                        }}
                                      />
                                      &nbsp;
                                    </div>
                                  )}
                                  <input
                                    accept="image/*"
                                    type="file"
                                    onChange={(e) =>
                                      uploadImage(e, index, "rightView")
                                    }
                                    ref={(el) =>
                                    (fileInputRefsRightView.current[
                                      index
                                    ] = el)
                                    }
                                    style={{ display: "none" }}
                                  />
                                </div>
                              )}
                            {index > 0 &&
                              !installation &&
                              screenStatus !== "installation_pending" && (
                                <div
                                  className="delete-board mt-auto mb-auto ml-1"
                                  style={{
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() => deletePhotoRow(index)}
                                >
                                  <img
                                    src="/images/delete_blue.svg"
                                    alt=''
                                    className="cursor-pointer"
                                    style={{ height: "14px" }}
                                  />
                                  &nbsp; Delete Board
                                </div>
                              )}
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </>
              )}
            </>
          )}
          {!installation && (
            <Row>
              <Col xl="12" md="12" xs="12" className="mb-3">
                <b className="font-size-16px">
                  Recce Details - Upload Facade Photograph{" "}
                  <span className="note">
                    (minimum 3 photos to be uploaded)
                  </span>
                </b>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Name on the Signage{" "}
                    <span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="nameOnTheSignature"
                      value={storeDetailControls.nameOnTheSignature.value}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Type here"
                    />
                  </div>
                  {storeDetailControls.nameOnTheSignature.showErrorMsg && (
                    <div className="error">
                      * Please enter name on the signage.
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          {storeDetailControls.proposedSizeForNewSignature.map(
            (data, index) => {
              return (
                <>
                  {!installation && (
                    <Row>
                      {index === 0 && (
                        <Col xl="12" md="12" xs="12" className="mb-3">
                          <b className="font-size-16px">
                            Proposed Size for New Signage (in Inches)
                            <span className="prview-photo-note">
                              Note : To preview the image, click on the image.
                            </span>
                          </b>
                        </Col>
                      )}
                      <span
                        className="count font-weight-bold"
                        style={{ marginTop: index === 0 ? "5.6%" : "2.6%" }}
                      >
                        {index + 1}
                      </span>
                      <Col xl="3" md="3" xs="12" className="ml-3">
                        <Row>
                          <Col xl="6" md="12" xs="12">
                            <FormGroup>
                              <Label>
                                Width (inches)
                                <span className="required-field">*</span>
                              </Label>
                              <div className="d-flex">
                                <Input
                                  type="text"
                                  name="width"
                                  value={data.width.value}
                                  onChange={(e) =>
                                    handleInputChangePhotograph(e, index)
                                  }
                                  placeholder="Type here"
                                />
                              </div>
                              {data.width.showErrorMsg && (
                                <div className="error">
                                  * Please enter width.
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col xl="6" md="12" xs="12">
                            <FormGroup>
                              <Label>
                                Height (inches)
                                <span className="required-field">*</span>
                              </Label>
                              <div className="d-flex">
                                <Input
                                  type="text"
                                  name="height"
                                  value={data.height.value}
                                  onChange={(e) =>
                                    handleInputChangePhotograph(e, index)
                                  }
                                  placeholder="Type here"
                                />
                              </div>
                              {data.height.showErrorMsg && (
                                <div className="error">
                                  * Please enter height.
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col xl="12" md="12" xs="12">
                            <FormGroup>
                              <Label>
                                Total Area (sq.ft)
                                <span className="required-field">*</span>
                              </Label>
                              <div className="d-flex">
                                <Input
                                  type="text"
                                  name="totalArea"
                                  value={data.totalArea.value}
                                  placeholder="Autocalculated"
                                  disabled="true"
                                />
                              </div>
                              {data.totalArea.showErrorMsg && (
                                <div className="error">
                                  * Area should be {">"} 0.
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl="8" md="8" xs="12" className="d-flex">
                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">
                              Front View{" "}
                              <span className="required-field">*</span>
                            </Label>
                            {data.frontView.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(index, "fileInputRefsFrontView")
                                  }
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() =>
                                    deletePhoto(index, "frontView")
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.frontView.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.frontView.value})`,
                              }}
                              onClick={() => openPreviewImageLightBox(0, data)}
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsFrontView")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => uploadImage(e, index, "frontView")}
                            ref={(el) =>
                              (fileInputRefsFrontView.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                          {data.frontView.showErrorMsg && (
                            <div className="error">* Upload front view.</div>
                          )}
                        </div>
                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">
                              Closeup View{" "}
                              <span className="required-field">*</span>
                            </Label>
                            {data.closeupView.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(
                                      index,
                                      "fileInputRefsCloseupView"
                                    )
                                  }
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() =>
                                    deletePhoto(index, "closeupView")
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.closeupView.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.closeupView.value})`,
                              }}
                              onClick={(() => openPreviewImageLightBox(), data)}
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsCloseupView")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) =>
                              uploadImage(e, index, "closeupView")
                            }
                            ref={(el) =>
                              (fileInputRefsCloseupView.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                          {data.closeupView.showErrorMsg && (
                            <div className="error">* Upload closeup view.</div>
                          )}
                        </div>
                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">
                              Long Shot{" "}
                              <span className="required-field">*</span>
                            </Label>
                            {data.longShotView.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(index, "fileInputRefsLongShot")
                                  }
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() =>
                                    deletePhoto(index, "longShotView")
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.longShotView.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.longShotView.value})`,
                              }}
                              onClick={() => openPreviewImageLightBox(2, data)}
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsLongShot")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) =>
                              uploadImage(e, index, "longShotView")
                            }
                            ref={(el) =>
                              (fileInputRefsLongShot.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                          {data.longShotView.showErrorMsg && (
                            <div className="error">* Upload Longshot view.</div>
                          )}
                        </div>
                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">Left View</Label>
                            {data.leftView.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(index, "fileInputRefsLeftView")
                                  }
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() => deletePhoto(index, "leftView")}
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.leftView.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.leftView.value})`,
                              }}
                              onClick={() => openPreviewImageLightBox(3, data)}
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsLeftView")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => uploadImage(e, index, "leftView")}
                            ref={(el) =>
                              (fileInputRefsLeftView.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">Right View</Label>
                            {data.rightView.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(index, "fileInputRefsRightView")
                                  }
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() =>
                                    deletePhoto(index, "rightView")
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.rightView.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.rightView.value})`,
                              }}
                              onClick={() => openPreviewImageLightBox(4, data)}
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsRightView")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => uploadImage(e, index, "rightView")}
                            ref={(el) =>
                              (fileInputRefsRightView.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                        {index > 0 && (
                          <div
                            className="delete-board mt-auto mb-auto ml-1"
                            style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                            onClick={() => deletePhotoRow(index)}
                          >
                            <img
                              src="/images/delete_blue.svg"
                              alt=''
                              className="cursor-pointer"
                              style={{ height: "14px" }}
                            />
                            &nbsp; Delete Board
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              );
            }
          )}
          {!installation && (
            <Row>
              <Col
                xl="12"
                md="12"
                xs="12"
                className="mb-3 mt-4"
                style={{ marginLeft: "-5px" }}
              >
                <div className="d-flex-row">
                  <img
                    src="/images/add_blue.svg"
                    alt=''
                    className="mt-auto mb-auto cursor-pointer"
                    onClick={() => addMoreProposedSize()}
                    style={{ height: "16px" }}
                  />
                  <div
                    className="add-more ml-2 cursor-pointer"
                    onClick={() => addMoreProposedSize()}
                  >
                    Add More Proposed Size for New Signage
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      <Row className="m-top-24px">
        <Col xl="6" md="12" xs="12">
          <Card style={{ height: "100%" }}>
            <CardBody>
              <Row>
                <Col xl="12" md="12" xs="12">
                  <b className="font-size-16px">
                    {installation
                      ? "Dealer Acknowledgement on Installation"
                      : "Dealer Acknowledgement on Recce Size"}
                    {!hoInstallation && (
                      <span className="required-field">*</span>
                    )}
                  </b>
                </Col>
                <Col xl="12" md="12" xs="12" className="mb-3 mt-4">
                  {!installation &&
                    storeDetailControls.acknowledgementForm.value ? (
                    <div className="d-flex-row">
                      <img
                        src="/images/file-img.png"
                        alt=''
                        className="mt-auto mb-auto cursor-pointer"
                        style={{ height: "19px" }}
                      />
                      <div className="add-more ml-2 cursor-pointer">
                        <span
                          onClick={() =>
                            window.open(
                              storeDetailControls.acknowledgementForm.value,
                              "_blank"
                            )
                          }
                        >
                          View Document
                        </span>
                      </div>
                      {!hoInstallation && (
                        <img
                          className="cursor-pointer mt-auto mb-auto ml-3"
                          src="images/close.png"
                          style={{ height: "12px", width: "12px" }}
                          alt="cancel"
                          onClick={() =>
                            removeAcknowledement("acknowledgementForm")
                          }
                        />
                      )}
                    </div>
                  ) : (
                    !installation && (
                      <div className="d-flex-row">
                        <img
                          src="/images/add_blue.svg"
                          alt=''
                          className="mt-auto mb-auto cursor-pointer"
                          onClick={() => browseAcknowledement()}
                          style={{ height: "16px" }}
                        />
                        <div
                          className="add-more ml-2 cursor-pointer"
                          onClick={() => browseAcknowledement()}
                        >
                          Browse Acknowledgment Form
                        </div>
                      </div>
                    )
                  )}
                  {installation &&
                    storeDetailControls.acknowledgementFormInstallation.value ? (
                    <div className="d-flex-row">
                      <img
                        src="/images/file-img.png"
                        alt=''
                        className="mt-auto mb-auto cursor-pointer"
                        style={{ height: "19px" }}
                      />
                      <div className="add-more ml-2 cursor-pointer">
                        <span
                          onClick={() =>
                            window.open(
                              storeDetailControls
                                .acknowledgementFormInstallation.value,
                              "_blank"
                            )
                          }
                        >
                          View Document
                        </span>
                      </div>
                      {!hoInstallation && !isApproved && (
                        <img
                          className="cursor-pointer mt-auto mb-auto ml-3"
                          src="images/close.png"
                          style={{ height: "12px", width: "12px" }}
                          alt="cancel"
                          onClick={() =>
                            removeAcknowledement(
                              "acknowledgementFormInstallation"
                            )
                          }
                        />
                      )}
                    </div>
                  ) : (
                    installation && (
                      <div className="d-flex-row">
                        <img
                          src="/images/add_blue.svg"
                          alt=''
                          className="mt-auto mb-auto cursor-pointer"
                          onClick={() => browseAcknowledement()}
                          style={{ height: "16px" }}
                        />
                        <div
                          className="add-more ml-2 cursor-pointer"
                          onClick={() => browseAcknowledement()}
                        >
                          Browse Acknowledgment Form
                        </div>
                      </div>
                    )
                  )}
                  <input
                    accept="image/*,application/pdf"
                    type="file"
                    onChange={(e) =>
                      browseAcknowledementUploadFile(
                        e,
                        installation
                          ? "acknowledgementFormInstallation"
                          : "acknowledgementForm"
                      )
                    }
                    ref={(ref) => (fileInputAcknowledement = ref)}
                    style={{ display: "none" }}
                  />
                  {storeDetailControls.acknowledgementForm.showErrorMsg && (
                    <div className="error" style={{ marginLeft: "15px" }}>
                      * Please Browse Acknowledgment form.
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        {installation && (
          <Col xl="6" md="12" xs="12">
            <Card>
              <CardBody style={{ paddingBottom: "6px" }}>
                <Row>
                  <Col xl="12" md="12" xs="12">
                    <b className="font-size-16px">
                      Vendor Installation Comments
                    </b>
                  </Col>
                  <Col xl="12" md="12" xs="12" className="mt-3">
                    <FormGroup>
                      <div className="d-flex">
                        {!vendorScreen && !isApproved ? (
                          <TextareaAutosize
                            name="remarksInstallation"
                            autocomplete="nofill"
                            id="remarksInstallation"
                            value={
                              storeDetailControls.remarksInstallation.value
                            }
                            style={{
                              minHeight: 40,
                              maxHeight: 70,
                              fontSize: "14px",
                            }}
                            className="form-control"
                            onChange={(e) =>
                              handleInputChange({
                                target: {
                                  name: "remarksInstallation",
                                  value: e.target.value,
                                },
                              })
                            }
                            placeholder="Type your comment here..."
                          />
                        ) : (
                          <div
                            style={{ marginTop: "4px", fontStyle: "italic" }}
                          >
                            {storeDetailControls.remarksInstallation.value
                              ? storeDetailControls.remarksInstallation.value
                              : `" "`}
                          </div>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
        {!installation && (
          <Col xl="6" md="12" xs="12">
            <Card>
              <CardBody style={{ paddingBottom: "6px" }}>
                <Row>
                  <Col xl="12" md="12" xs="12">
                    <b className="font-size-16px">Vendor Recce Comments</b>
                  </Col>
                  <Col xl="12" md="12" xs="12" className="mt-3">
                    <FormGroup>
                      <div className="d-flex">
                        <TextareaAutosize
                          name="remarks"
                          autocomplete="nofill"
                          id="remarks"
                          value={storeDetailControls.remarks.value}
                          style={{
                            minHeight: 40,
                            maxHeight: 70,
                            fontSize: "14px",
                          }}
                          className="form-control"
                          onChange={(e) =>
                            handleInputChange({
                              target: {
                                name: "remarks",
                                value: e.target.value,
                              },
                            })
                          }
                          placeholder="Type your comment here..."
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}

RetailMarktingUploadPhotograph.propTypes = {
  storeDetailControls: PropTypes.instanceOf(Object).isRequired,
  setRetailRecciControlsAction: PropTypes.func.isRequired,
  installation: PropTypes.bool.isRequired,
  hoInstallation: PropTypes.bool.isRequired,
  installationModification: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  vendorScreen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => {
  return {
    storeDetailControls: getRetailMarketingStoreInformationState(state),
    vendorScreen: getVendorScreen(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRetailRecciControlsAction: setRetailRecciControls,
      handleValidationRetailMarketingStoreInformationAction: handleValidationRetailMarketingStoreInformation,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailMarktingUploadPhotograph);
