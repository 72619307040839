import { action } from '../constants/constant.action';
const {
  SET_USER_DETAIL, IS_PROFILE_UPDATE
} = action;

export const setUserDetail = user => {
  return ({
    type: SET_USER_DETAIL,
    user
  })
};

export const isProfileUpdate = isProfileUpdate => {
  return ({
    type: IS_PROFILE_UPDATE,
    isProfileUpdate
  })
};

export const setMerchandiseCart = placeOrderCartItems => {
  return ({
    type: action.SET_MERCHANDISE_CART,
    placeOrderCartItems
  })
};

export const setDealerDashboardRedirectDetail = redirectDetails => {
  return ({
    type: action.SET_DEALER_DASHBOARD_REDIRECT_DETAIL,
    redirectDetails
  })
};

export const setAddCustomerRedirect = redirectDetailsAddCustomer => {
  return ({
    type: action.SET_ADD_CUSTOMER_REDIRECT,
    redirectDetailsAddCustomer
  })
};

export const setRedirect = redirectDetails => {
  return ({
    type: action.SET_REDIRECT,
    redirectDetails
  })
};

export const setHelpGuide = helpGuide => {
  return ({
    type: action.SET_HELP_GUIDE_REDIRECT,
    helpGuide
  })
}
// export const setUserDetail = user => ({
//   type: SET_USER_DETAIL,
//   user
// });