import React, { Component } from "react";
import ProposalSerivice from "../../services/ProposalSerivice";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import Storage from "../../services/Storage";
import Moment from "react-moment";
import ModalService from "../../services/ModalService";
import TextareaAutosize from "react-textarea-autosize";
import Validation from "../../services/Validation";
import { messages } from "../../constants/constant.message";
class AgencyWorkInProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      controls: {
        remark: {
          value: "",
          showErrorMsg: false,
          valid: false,
          touched: false,
        },
        visit_date: {
          value: null,
          showErrorMsg: false,
          valid: false,
          touched: false,
        },
        document: {
          value: "",
          showErrorMsg: false,
          valid: false,
          touched: false,
        },
        stage: "Agency",
        completed: false,
      },
      isLoading: false,
      userDetail: Storage.getUserDetail(),
    };
  }
  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { controls } = this.state;
      if (data && data.data) {
        controls.document.value = data.data;
        controls.document.touched = true;
      }
      this.setState({ controls, isLoading: false });
      this.handleValidation();
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (!this.checkActiveStatus()) {
      return false;
    }
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        const { controls } = this.state;
        controls.document.value = "";
        event.target.value = null;
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB",
          "error"
        );
        return;
      }
      this.uploadFileFormData(file, type);
    } else {
      const { controls } = this.state;
      controls.document.value = "";
      this.setState({
        controls,
      });
    }
  };

  submit = () => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    let { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    if (!this.handleValidation(true)) {
      return;
    }

    let obj = {
      remark: controls.remark.value,
      stage: controls.stage,
      document: controls.document.value,
      completed: controls.completed,
      visit_date: controls.visit_date.value,
    };

    // if (controls.completed) {
    //   let visit_date = new Date();
    //   obj.visit_date = new Date(visit_date).toISOString();
    // }

    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.saveComplitionReport(obj)
      .then((res) => {
        controls.visit_date.value = null;
        controls.document.value = "";
        controls.remark.value = "";
        controls.completed = false;
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message =
            "Agency work in progress details added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message =
            "Agency work in progress details updated successfully";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          if (this.fileInput) {
            this.fileInput.value = "";
          }
          this.props.initApi();
        });
        this.setState({ isLoading: false, controls });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  onChangeDate = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const { controls } = this.state;
    controls["visit_date"].value = e;
    controls["visit_date"].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  handleInputCheckboxChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName] = !controls[controlName];
    this.setState({ controls });
    this.handleValidation();
  };

  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let { visit_date, remark, document } = controls;
    let isFormValid = false;

    visit_date.valid = true;
    visit_date.showErrorMsg = false;
    if (visit_date.touched === true || isSubmit) {
      visit_date = Validation.notNullValidator(visit_date);
      visit_date.valid = !visit_date.nullValue;
      if (visit_date.valid === false) {
        visit_date.showErrorMsg = true;
      } else {
        visit_date.showErrorMsg = false;
      }
    }
    remark.valid = true;
    remark.showErrorMsg = false;
    document.valid = true;
    document.showErrorMsg = false;
    if (remark.touched === true || document.touched === true || isSubmit) {
      remark = Validation.notNullValidator(remark);
      remark.valid = !remark.nullValue;
      document = Validation.notNullValidator(document);
      document.valid = !document.nullValue;
      if (remark.valid === true || document.valid === true) {
        document.showErrorMsg = false;
      } else {
        document.showErrorMsg = true;
      }
    }

    if (visit_date.valid && (document.valid || remark.valid)) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls,
    });
    return isFormValid;
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    if (controlName === "stage") {
      controls[controlName] = e.target.value;
    } else {
      controls[controlName].value = e.target.value;
      controls[controlName].touched = true;
    }
    this.setState({ controls });
    this.handleValidation();
  };

  render() {
    const { controls, userDetail } = this.state;
    const { proposalStageDetail, isCompletedAllStages } = this.props;
    let minDate = null;
    let estimateNeeded = proposalStageDetail.stages_details.filter(
      (x) => x.stage && x.stage === "Dealer work in progress"
    );
    if (estimateNeeded.length > 0) {
      let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
      minDate = new Date(
        estimateNeeded[0].completed_date.replace(pattern, "$3-$2-$1")
      );
    }
    let isReadOnly = isCompletedAllStages;
    // let indexHandover = proposalStageDetail.stages_details.findIndex(
    //   (x) => x.stage && x.stage.toLowerCase() === "hand over"
    // );
    // if (indexHandover > -1) {
    //   isReadOnly = true;
    // }
    return (
      <>
        <br />
        {this.state.isLoading && <CustomSpinner />}
        <Card>
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Agency work in progress</b>
              </Col>
            </Row>
            <hr />
            {this.state.userDetail.access_level !== "area" &&
              this.state.userDetail.access_level !== "region" &&
              this.state.userDetail.access_level !== "zone"
              ? !isReadOnly && (
                <>
                  <Row>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label>
                          Date of Work
                          {!controls.completed && (
                            <span className="required-field">*</span>
                          )}
                        </Label>
                        <Label
                          onClick={(e) =>
                            this.calendar1.state.open && e.preventDefault()
                          }
                        >
                          <InputGroup>
                            <DatePicker
                              className={"form-control"}
                              onChange={(e) => this.onChangeDate(e)}
                              name="visit_date"
                              selected={controls.visit_date.value}
                              dateFormat="dd/MM/yyyy"
                              showMonthDropdown
                              ref={(r) => (this.calendar1 = r)}
                              shouldCloseOnSelect={true}
                              showYearDropdown
                              maxDate={new Date()}
                              minDate={minDate}
                              dropdownMode="select"
                              disabled={
                                userDetail.access_level === "brand_store"
                              }
                            />
                            <InputGroupAddon addonType="append">
                              <div className="input-group-text">
                                <FontAwesomeIcon
                                  htmlFor="datepicker"
                                  icon={faCalendarAlt}
                                />
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                        </Label>
                        {controls.visit_date.showErrorMsg && (
                          <div className="error">
                            * Please select date of work.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>
                          Upload Document(jpeg, jpg, png and pdf file)
                        </Label>
                        <input
                          type="file"
                          name="document"
                          ref={(ref) => (this.fileInput = ref)}
                          onChange={(e) => this.uploadFile(e, "document")}
                          disabled={userDetail.access_level === "brand_store"}
                        />
                        {controls.document.showErrorMsg && (
                          <div className="error mt-2">
                            * Please upload document or enter remark.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="12" xs="12">
                      <FormGroup>
                        <Label>Remark</Label>
                        <TextareaAutosize
                          name="remark"
                          autocomplete="nofill"
                          id="remark"
                          value={controls.remark.value}
                          style={{
                            minHeight: 60,
                            maxHeight: 70,
                            fontSize: "14px",
                          }}
                          className="form-control"
                          onChange={(e) =>
                            this.handleInputChange({
                              target: {
                                name: "remark",
                                value: e.target.value,
                              },
                            })
                          }
                          placeholder=""
                          disabled={userDetail.access_level === "brand_store"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>Agency Work Completed</Label>
                        <div className="d-flex-row">
                          <div>
                            <label className="custom-checkboax1--container">
                              <input
                                type="checkbox"
                                name="completed"
                                checked={controls.completed}
                                onChange={(e) =>
                                  this.handleInputCheckboxChange(e)
                                }
                                disabled={
                                  userDetail.access_level === "brand_store"
                                }
                              />
                              <span className="checkmark1" />
                            </label>
                            <span style={{ marginLeft: "10px" }}>
                              WORK COMPLETED
                            </span>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {userDetail.access_level !== "brand_store" && (
                    <Row>
                      <Col
                        xl="3"
                        md="12"
                        xs="12"
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                          color="warning"
                          type="submit"
                          style={{ float: "right" }}
                          onClick={(e) => this.submit()}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <br />
                </>
              )
              : ""}
            {proposalStageDetail &&
              proposalStageDetail.agency_completion_data &&
              proposalStageDetail.agency_completion_data.length > 0 && (
                <Table className="mb-0  tbl-about tbl-about table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Work Completed</th>
                      <th scope="col">Remarks</th>
                      <th scope="col">Document</th>
                      <th scope="col">Date of Work</th>
                      <th scope="col">Report Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposalStageDetail.agency_completion_data.map((item) => (
                      <tr>
                        <td>{item && item.completed ? "Yes" : "No"}</td>
                        <td>
                          {item && item.document ? (
                            <a
                              className="aa d-flex-column"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.document}
                            >
                              <img
                                src="images/sidebar-icons/secondary-report-selected.svg"
                                height="40px"
                                style={{ margin: "auto" }}
                                alt=''
                              />
                              <span className="redirect-link">View</span>
                            </a>
                          ) : (
                            <span className="no-data-hypen  ml-auto mr-auto"></span>
                          )}
                        </td>
                        <td>
                          {item && item.remark ? (
                            item.remark
                          ) : (
                            <span className="no-data-hypen  ml-auto mr-auto"></span>
                          )}
                        </td>
                        <td>
                          {item && (
                            <Moment format="DD-MM-YYYY">
                              {item.visit_date}
                            </Moment>
                          )}
                        </td>
                        <td>
                          {item && item.created_at ? item.created_at : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default AgencyWorkInProgress;
