import React, { Component } from "react";
import { Row, Col, Card, CardBody, FormGroup } from "reactstrap";
import Chart from "react-apexcharts";

// components
import DashboardCardEmployee from "../DashboardCardEmployee/DashboardCardEmployee";
import VideoComponent from "../VideoComponent/VideoComponent";
import AboutJKAdvantage from "../AboutJKAdvantage/AboutJKAdvantage";
import HowItWorks from "../HowItWorks/HowItWorks";
import AutoSuggestionDealer from "../../components/AutoSuggestionDealer/AutoSuggestionDealer";
import DashboardService from "../../services/DashboardService";
// utils
import {
  formatNumber,
  // findPreviousQuarterMonths,
  // findFirstQuarterToCurrentMonths,
  // findMonthFromFirstQToPreviousQMonths,
  // getCurrentMonth,
  // getCurrentQuarterMonths,
} from "../../utils/utils";

// css
import "./DashboardEmployeeWeb.css";
import Storage from "../../services/Storage";
import { Permissions } from "../../constants/constant.permissions";

class DashboardEmployeeWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearOptions: [],
      year: null,
      year_name: null,
      userDetail: Storage.getUserDetail(),
      is_display_warranty: false,
    };
    if (
      this.state.userDetail.permissions &&
      (
        this.state.userDetail.permissions.includes(Permissions.WARRANTY_REPORT_EMPLOYEE)
        // || this.state.userDetail.permissions.includes(Permissions.WARRANTY_REPORT)
      )
    ) {
      this.state.is_display_warranty = true;
    }
  }

  openPrimaryReportScreen = () => {
    this.props.history.push("/offtake-score-card-report");
  };

  componentDidMount = () => {
    this.filterAPI();
  };

  filterAPI = () => {
    const { year } = this.state;

    let filter = {
      y: year ? year : null,
    };
    this.setState({ isLoader: true });
    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          this.setState({ isLoader: false });

          this.setState(
            {
              yearOptions: res.data.data.year ? res.data.data.year : [],
            },
            () => {
              this.setState(
                {
                  year: this.state.yearOptions[0].value,
                  year_name: this.state.yearOptions[0].name,
                },
                () => {
                  this.props.yearFilterApplied(
                    this.state.year,
                    this.state.year_name
                  );
                }
              );
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        console.log("err", err);
      });
  };

  onChangeYear = (e) => {
    this.setState(
      {
        year: e && e.target && e.target.value,
      },
      () => {
        if (this.state.year) {
          let year_name = this.state.yearOptions.filter(
            (x) => x.value == this.state.year
          );
          this.setState({
            year_name: year_name && year_name[0].name,
          });
        }
        this.props.yearFilterApplied(this.state.year, this.state.year_name);
      }
    );
  };

  render() {
    const {
      yearForWarrantyPoints,
      yearObj,
      quarterObj,
      monthObj,
      stackedChartWeb,
      stackedChart: sc,
      prepareStackedChart,
      // totalSeconadryPoints,
      totalPrimaryPoints,
      currentQMonths,
      displayLabels,
      totalPrivionalPoints,
      categoryImages,
      // warrantyTotalPoints,
      warrantyTotalRegisteredCustomers,
      warrantyTotalRegisteredCustomersCurrentMonth,
      warrantyTotalPointsCurrentMonth,
      // handleSearchChange,
      currentMonth,
      // firstQToCurrentMonth,
      monthFromFirstQtoPreviousQ,
      openPrimaryReportScreen,
      openSecondaryReportScreen,
      // search,
    } = this.props;
    let stackedChart = sc;
    if (!sc) {
      stackedChart = prepareStackedChart(
        stackedChartWeb.categories,
        stackedChartWeb.series
      );
    }
    return (
      <div id="dashboard-web">
        {/* {redirectToWarrantyRegistration && <Redirect to={{ pathname: '/warranty-registration', search: `phoneNumber=${warrantyPhoneNumber.value}` }} />} */}
        {/* <div className="page-title">Dashboard</div> */}
        <Row>
          <Col
            className="page-title"
            md={this.state.yearOptions.length > 1 ? "7" : "9"}
            xs="12"
            style={{ marginLeft: "auto" }}
          >
            Dashboard
          </Col>

          {this.state.yearOptions.length > 1 && (
            <Col xl="2" md="4" xs="12" style={{ paddingLeft: "15px" }}>
              <Row>
                <div className="fin-label">Financial Year</div>
                &nbsp;
                <FormGroup>
                  <select
                    name="month"
                    className="form-control cursor-pointer"
                    value={this.state.year}
                    style={{
                      fontSize: "13px",
                      width: "110px",
                      color: "#6e777f",
                      marginLeft: "auto",
                    }}
                    onChange={(e) => {
                      this.onChangeYear(e);
                    }}
                  >
                    {this.state.yearOptions.map((item) => (
                      <option value={item.value} selected>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Row>
            </Col>
          )}
          <Col md="3" xs="12">
            <AutoSuggestionDealer
              {...this.props}
              from="point-dashboard"
              year={this.state.year}
            ></AutoSuggestionDealer>

            {/* <Input
            placeholder="Search using dealer name or SAP code"
            className="report-search-input"
            name="search"
            value={search}
            onChange={handleSearchChange}
          ></Input>
          <img src="images/search.svg" className="report-search" alt='' /> */}
          </Col>
        </Row>

        <div
          className="dashboard-page-title"
          style={{ marginLeft: "-10px", marginTop: "-4px" }}
        >
          POINTS ON OFFTAKE&nbsp;
          <span className="year-label">
            {" "}
            {this.state.year_name
              ? "(" + this.state.year_name + ")"
              : null}{" "}
          </span>
        </div>

        <Row className="cursor-pointer" onClick={openPrimaryReportScreen}>
          <Col xl="3" className="margin-top-10 padding-left-5 padding-right-5">
            <Card
              className="height-100 border-radius-5"
              style={{ minHeight: "420px" }}
            >
              <Row
                className="card-custom-header"
              // style={{ padding: '23.5px 0px' }}
              >
                <Col xs="6" className="dashboard-page-card-title-big">
                  <div>Total Offtake Points</div>
                  <div className="dashboard-page-card-title-small">
                    {displayLabels && displayLabels.year}
                    {/* {findFirstQuarterToCurrentMonths()} */}
                  </div>
                </Col>
                <Col
                  xs="6"
                  className="count-small"
                  style={{ paddingLeft: "0px" }}
                >
                  {formatNumber(totalPrimaryPoints + totalPrivionalPoints)}
                </Col>
              </Row>
              <CardBody>
                <Row
                // style={{ padding: '23.5px 0px' }}
                >
                  <Col xs="6" className="dashboard-page-card-title-big">
                    <div>Points Eligible for Redemption</div>
                    <div className="dashboard-page-card-title-small">
                      {displayLabels &&
                        displayLabels.year_till_previous_quarter}
                      {/* {findMonthFromFirstQToPreviousQMonths()} */}
                    </div>
                  </Col>
                  <Col
                    xs="6"
                    className="count-small"
                    style={{ paddingLeft: "0px" }}
                  >
                    {formatNumber(totalPrimaryPoints)}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" className="dashboard-page-card-title-small-gray">
                    Provisional Points for the Quarter (
                    {displayLabels && displayLabels.quarter})
                  </Col>
                  <Col
                    xs="6"
                    className="count-small"
                    style={{ paddingLeft: "0px" }}
                  >
                    {formatNumber(totalPrivionalPoints)}
                  </Col>
                </Row>

                <Row
                  className="margin-top-10"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "95%",
                    textAlign: "justify",
                  }}
                >
                  <Col>
                    <span className="These-Points-are-pr">
                      *These Points are provisional. They will get added to your
                      total earned points at the end of Q2 if you are eligible.
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3" className="margin-top-10 padding-left-5 padding-right-5">
            <DashboardCardEmployee
              {...yearObj}
              images={categoryImages}
              period="Year"
              duration={monthFromFirstQtoPreviousQ}
              displayLabels={displayLabels}
            />
          </Col>
          <Col xl="3" className="margin-top-10 padding-left-5 padding-right-5">
            <DashboardCardEmployee
              {...quarterObj}
              images={categoryImages}
              period="Quarter"
              duration={currentQMonths}
              displayLabels={displayLabels}
            />
          </Col>
          <Col xl="3" className="margin-top-10 padding-left-5 padding-right-5">
            <DashboardCardEmployee
              {...monthObj}
              images={categoryImages}
              period="Month"
              duration={currentMonth}
              displayLabels={displayLabels}
            />
          </Col>
        </Row>

        {this.state.is_display_warranty && (
          <>
            <br />
            <div className="dashboard-page-title">
              POINTS ON WARRANTY &nbsp;
              <span className="year-label">
                {" "}
                {this.state.year_name
                  ? "(" + this.state.year_name + ")"
                  : null}{" "}
              </span>
            </div>
            <Row>
              <Col
                xl="3"
                className="margin-top-10 padding-left-5 padding-right-5 cursor-pointer"
                onClick={openSecondaryReportScreen}
              >
                <Card className="height-100">
                  <CardBody>
                    <Row>
                      <Col xs="6" className="dashboard-page-card-title-big">
                        Total Warranty Points
                      </Col>
                      <Col xs="6" className="count-small">
                        {formatNumber(yearForWarrantyPoints.total_points)}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        xs="7"
                        className="dashboard-page-card-title-small-gray text-align-left"
                      >
                        Points earned in this month
                      </Col>
                      <Col
                        xs="5"
                        className="dashboard-page-card-title-small-gray text-align-right"
                      >
                        {warrantyTotalPointsCurrentMonth}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col
                xl="3"
                className="margin-top-10 padding-left-5 padding-right-5  cursor-pointer"
                onClick={openSecondaryReportScreen}
              >
                <Card className="height-100">
                  <CardBody>
                    <Row>
                      <Col xs="7" className="dashboard-page-card-title-big">
                        <div>Warranty Registered Customers</div>
                      </Col>
                      <Col xs="5" className="count-small">
                        {formatNumber(warrantyTotalRegisteredCustomers)}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        xs="7"
                        className="dashboard-page-card-title-small-gray text-align-left"
                      >
                        Customer Reg this month
                      </Col>
                      <Col
                        xs="5"
                        className="dashboard-page-card-title-small-gray text-align-right"
                      >
                        {warrantyTotalRegisteredCustomersCurrentMonth}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {/* } */}
        <br />
        <div className="dashboard-page-title">
          YEARLY OFFTAKE&nbsp;
          <span className="year-label">
            {" "}
            {this.state.year_name
              ? "(" + this.state.year_name + ")"
              : null}{" "}
          </span>
        </div>
        {/* <br /> */}
        <Row className="margin-top-10">
          <Card style={{ width: "100%" }}>
            <CardBody>
              <Chart
                options={stackedChart.options}
                series={stackedChart.series}
                type="bar"
                height="220px"
              />
            </CardBody>
          </Card>
        </Row>
        <br />
        <Row>
          <Col xl="4" className="margin-top-10 padding-left-5 padding-right-5">
            <VideoComponent />
          </Col>
          <Col xl="4" className="margin-top-10 padding-left-5 padding-right-5">
            <AboutJKAdvantage />
          </Col>
          <Col xl="4" className="margin-top-10 padding-left-5 padding-right-5">
            <HowItWorks />
          </Col>
        </Row>
      </div>
    );
  }
}

export default DashboardEmployeeWeb;
