import React, { Component } from 'react';
import Ionicon from 'react-ionicons';
import { Row, Col, CardBody, Label, FormGroup, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import moment from "moment";
import MappleToolTip from "reactjs-mappletooltip";
import * as Scroll from "react-scroll";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import CustomerManagementService from '../../services/CustomerManagementService';
import CampaignService from '../../services/CampaignService';
import WarrantyRegistrationService from '../../services/WarrantyRegistrationService';
import { setDealerDashboardRedirectDetail } from '../../actions/UserAction';
import { messages } from '../../constants/constant.message.js';
import './CustomerManagementFilterNew.css';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

const { EMPTY_DATE } = messages.CUSTOMER_MANAGEMENT;
// const vehicleTypeCheckboxOptions = [
//   {
//     key: "All",
//     value: "all"
//   },
//   {
//     key: "Car",
//     value: "car"
//   },
//   {
//     key: "2 Wheeler",
//     value: "2 wheeler"
//   },
//   {
//     key: "Truck",
//     value: "truck"
//   },
//   {
//     key: "Bus",
//     value: "bus"
//   },

//   {
//     key: "SCV",
//     value: "scv"
//   },
//   {
//     key: "LCV",
//     value: "lcv"
//   }
// ];

const vehicleUsageCheckboxOption = [
  {
    key: "Personal",
    value: "personal"
  },
  {
    key: "Commercial",
    value: "taxi"
  }
]

const vehicleUsageCheckboxOptionLCVSCV = [
  {
    key: "Cargo",
    value: "cargo"
  },
  {
    key: "Passenger",
    value: "passenger"
  },
]

const customerBasedOnRadioOptions = [
  {
    key: "Registration Date",
    value: "registration"
  },
  {
    key: "Last Visit Date",
    value: "last_visit"
  }
]

// let mainRadioOptions = [
//   {
//     key: "View Prospective Customer",
//     value: "prospective_customer"
//   },
//   {
//     key: "Upcoming Visit Or Overdue Visit",
//     value: "due"
//   },
//   {
//     key: "Source",
//     value: "source"
//   },
//   // {
//   //   key: "Customer's Rating",
//   //   value: "customer_rating"
//   // },
//   {
//     key: "Visit Purpose",
//     value: "visit"
//   },
//   {
//     key: "Group",
//     value: "group"
//   },
//   {
//     key: "Occasion",
//     value: "occasion"
//   },
//   {
//     key: "Add Data Manually",
//     value: "manual"
//   }
// ]
class CustomerManagementFilterNew extends Component {
  constructor() {
    super();
    this.state = {
      groupData: [],
      services: [],
      visitPurposes: [],
      vehicleModelData: [],
      vehicleMakeData: [],
      vehicleTypeData: [],
      sourceMembers: [],
      isAddMoreFilterOpen: true,
      isAddMakeAndModelOpen: false,
      isLoading: false,
      isVehicleTypeDropDownOpen: false,
      serachValueVehicleType: '',
      upcomingDueOrOverDueSwitch: 'upcoming_due',
      controls: {
        customerRegistrationFrom: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        customerRegistrationTo: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        upcomingDueFrom: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        upcomingDueTo: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        occasionDueFrom: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        occasionDueTo: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        customerBasedOnRadio: {
          value: 'registration',
          type: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        upcomingDueRadio: {
          value: null,
          type: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        occasionDueRadio: {
          value: 'birthday',
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        mainFilterRadio: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        upcomingDueServiceDropDown: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        sourceDropDown: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        visitPurposeServiceDropDown: {
          value: null,
          type: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        visitPurposeVisitPurposeDropDown: {
          value: '',
          type: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        groupDropDown: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        vehicleTypeCheckBox: {
          value: ['all'],
          key: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        typetext: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        usageTypeCheckBox: {
          value: [],
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        vehicleMake: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        vehicleModel: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        manualNumbers: {
          value: "",
          numbers: [],
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        ratings: {
          value: 1,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
        is_hide_upcoming_over_due_tab: {
          value: false,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false
        },
      },
      mainRadioOptions: [
        {
          key: "View Prospective Customer",
          value: "prospective_customer"
        },
        {
          key: "Upcoming Visit Or Overdue Visit",
          value: "due"
        },
        {
          key: "Source",
          value: "source"
        },
        // {
        //   key: "Customer's Rating",
        //   value: "customer_rating"
        // },
        {
          key: "Visit Purpose",
          value: "visit"
        },
        {
          key: "Group",
          value: "group"
        },
        {
          key: "Occasion",
          value: "occasion"
        },
        {
          key: "Add Data Manually",
          value: "manual"
        }
      ]
    }
    this.dropDownMenuContainer = React.createRef();
  }


  componentDidMount() {
    this.initAPI();
  }

  initAPI = async () => {
    if (this.props.filter) {
      if (this.props.filter.state) {
        this.setState({ ...this.props.filter.state });
      }
    } else {
      this.setState({ isLoading: true });
      return Promise.all([
        this.getAllCustomerService(),
        this.getVisitPurpose(),
        this.getVehicleType(),
        this.getSourceMembers(),
        this.getGroups()
      ])
        .then(() => {
          this.setState({ isLoading: false });
          if (this.props.filteredData) {
            this.setFilterControlData();
          }
          if (this.props.redirectDetails) {
            this.setFilterFromDashboard();
          }

          // if (this.props.redirectDetails && this.props.redirectDetails.campaign) {
          //   this.setFilterCampData();
          // }
        })
        .catch(e => {
          this.setState({ isLoading: false });
        })
    }
  }

  setFilterCampData = () => {
    if (!this.props.redirectDetails.campaign) {
      return
    }

    let { campaign } = this.props.redirectDetails;
    let { controls, upcomingDueOrOverDueSwitch } = this.state;
    let { customerBasedOnRadio, customerRegistrationFrom, customerRegistrationTo,
      occasionDueRadio, occasionDueFrom, occasionDueTo, mainFilterRadio } = controls;
    if (campaign.filter_data && campaign.filter_data.filter_type && campaign.filter_data.filter_type) {
      customerBasedOnRadio.value = campaign.filter_data.filter_type;
      customerRegistrationFrom.value = campaign.filter_data.last_visit_from_date ? new Date(campaign.filter_data.last_visit_from_date) : null;
      customerRegistrationTo.value = campaign.filter_data.last_visit_to_date ? new Date(campaign.filter_data.last_visit_to_date) : null;
    } else if (campaign.custom_filter === 'anniversary_wish' ||
      campaign.custom_filter === 'birthday_wish') {
      if (campaign.custom_filter === 'anniversary_wish') {
        occasionDueRadio.value = "anniversary";
      } else {
        occasionDueRadio.value = "birthday";
      }
      mainFilterRadio.value = 'occasion';
      occasionDueFrom.value = new Date();
      occasionDueTo.value = new Date();
    }
    this.setState({
      controls,
      upcomingDueOrOverDueSwitch
    }, () => {
      this.props.setDealerDashboardRedirectDetail(null);
      this.applyFilter();
    })
  }

  setFilterFromDashboard = async () => {
    if (!this.props.redirectDetails.customer_mgt) {
      return
    }
    let customer_mgt = this.props.redirectDetails.customer_mgt;

    if (customer_mgt) {
      let { controls, upcomingDueOrOverDueSwitch, isAddMoreFilterOpen, mainRadioOptions, isAddMakeAndModelOpen, vehicleTypeData } = this.state;
      let { customerBasedOnRadio } = controls;
      if (customer_mgt.custom_filter) {//from header notifcation
        customer_mgt.upcomingDueRadio = customer_mgt.custom_filter;
        controls.upcomingDueFrom.value = new Date(customer_mgt.due_date_from);
        controls.upcomingDueTo.value = new Date(customer_mgt.due_date_to);
        if (customer_mgt.custom_filter_service_id && Array.isArray(customer_mgt.custom_filter_service_id) &&
          customer_mgt.custom_filter_service_id.length > 0) {
          if (customer_mgt.custom_filter_service_id.length === 1) {
            customer_mgt.visitPurposeServiceDropDown = customer_mgt.custom_filter_service_id[0];
          }
        }
        customer_mgt.upcomingDue = 'from_notification';
      }

      if (customer_mgt.date_of_customer_registration && customer_mgt.date_of_customer_registration.toLowerCase() === 'today') {
        controls.customerRegistrationFrom.value = new Date();
        controls.customerRegistrationTo.value = new Date();
        customerBasedOnRadio.value = 'registration';
      } else if (customer_mgt.date_of_customer_registration && customer_mgt.date_of_customer_registration.toLowerCase() === 'month') {
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        controls.customerRegistrationFrom.value = startOfMonth ? new Date(startOfMonth) : null;
        controls.customerRegistrationTo.value = endOfMonth ? new Date(endOfMonth) : null;
        customerBasedOnRadio.value = 'registration';
      }

      if (customer_mgt.visitPurposeVisitPurposeDropDown === "tyre_purchase" ||
        customer_mgt.visitPurposeVisitPurposeDropDown === "service") {
        if (customer_mgt.date_of_customer_registration === "today" ||
          customer_mgt.date_of_customer_registration === "month") {
          customerBasedOnRadio.value = 'last_visit';
          isAddMoreFilterOpen = false;
        }
      }

      if (customer_mgt.upcomingDueRadio) {
        if (customer_mgt.hasOwnProperty('mainFilterRadio') && customer_mgt.mainFilterRadio === 'all_due') {
          let index = this.state.mainRadioOptions.findIndex(x => x.key === 'Upcoming Visit Or Overdue Visit');
          if (index > -1) {
            mainRadioOptions[index].value = 'all_due';
          }
          controls.mainFilterRadio.value = 'all_due';
        } else {
          controls.mainFilterRadio.value = 'due';
        }
        if (customer_mgt.due_type) {
          upcomingDueOrOverDueSwitch = customer_mgt.due_type;
        } else {
          upcomingDueOrOverDueSwitch = 'upcoming_due';
        }
        if (customer_mgt.upcomingDueRadio.toLowerCase() === 'tyre_purchase') {
          controls.upcomingDueRadio.type = 'tyre_purchase';
        }
        else if (customer_mgt.upcomingDueRadio.toLowerCase() === 'service') {
          controls.upcomingDueRadio.type = 'service';
        }
        let filter = this.state.visitPurposes.filter(x => x.type.toLowerCase() === customer_mgt.upcomingDueRadio.toLowerCase());
        if (filter.length > 0) {
          controls.upcomingDueRadio.value = filter[0].purpose_id;
        }
        if (customer_mgt.visitPurposeServiceDropDown) {
          controls.upcomingDueServiceDropDown.value = customer_mgt.visitPurposeServiceDropDown;
        }
      }


      if (customer_mgt.visitPurposeVisitPurposeDropDown) {
        controls.mainFilterRadio.value = "last_visit_purpose";
        let filter = this.state.visitPurposes.filter(x => x.type.toLowerCase() === customer_mgt.visitPurposeVisitPurposeDropDown.toLowerCase());
        if (customer_mgt.visitPurposeVisitPurposeDropDown.toLowerCase() === 'tyre_purchase') {
          controls.visitPurposeVisitPurposeDropDown.type = 'tyre_purchase';
        } else if (customer_mgt.visitPurposeVisitPurposeDropDown.toLowerCase() === 'service') {
          controls.visitPurposeVisitPurposeDropDown.type = 'service';
          if (customer_mgt.visitPurposeServiceDropDown) {
            controls.visitPurposeServiceDropDown.value = customer_mgt.visitPurposeServiceDropDown;
          }
          // value={visitPurposeServiceDropDown.value}
          //             visitPurposeVisitPurposeDropDown
        }
        if (filter.length > 0) {
          controls.visitPurposeVisitPurposeDropDown.value = filter[0].purpose_id;
        }
      }

      if (customer_mgt.upcomingDue) {
        let selectedDate = customer_mgt.upcomingDue;
        if (selectedDate === 'Today') {
          controls.upcomingDueFrom.value = new Date();
          controls.upcomingDueTo.value = new Date();
        } else if (selectedDate === '90') {
          let date_from = moment().subtract(90, "days").format("YYYY-MM-DD");
          let date_to = moment().add(90, "days").format("YYYY-MM-DD");
          controls.upcomingDueFrom.value = new Date(date_from);
          controls.upcomingDueTo.value = new Date(date_to);
        } else if (selectedDate === '60') {
          let date_from = moment().subtract(60, "days").format("YYYY-MM-DD");
          let date_to = moment().add(60, "days").format("YYYY-MM-DD");
          controls.upcomingDueFrom.value = new Date(date_from);
          controls.upcomingDueTo.value = new Date(date_to);
        } else if (selectedDate === '30') {
          let date_from = moment().subtract(30, "days").format("YYYY-MM-DD");
          let date_to = moment().add(30, "days").format("YYYY-MM-DD");
          controls.upcomingDueFrom.value = new Date(date_from);
          controls.upcomingDueTo.value = new Date(date_to);
        } else if (selectedDate === '7') {
          let date_from = moment().subtract(7, "days").format("YYYY-MM-DD");
          let date_to = moment().add(7, "days").format("YYYY-MM-DD");
          controls.upcomingDueFrom.value = new Date(date_from);
          controls.upcomingDueTo.value = new Date(date_to);
        }
      } else {
        controls.upcomingDueFrom.value = null;
      }

      if (customer_mgt.vehicle_type_id) {
        controls.vehicleTypeCheckBox.value = [customer_mgt.vehicle_type_id];
        const vehicleDataIndex = vehicleTypeData.findIndex(v => v.vehicle_type_id === customer_mgt.vehicle_type_id);
        const text = vehicleTypeData[vehicleDataIndex].name;
        if (text === 'Car' || text === '2/3 Wheeler' || text.toLowerCase() === 'lcv' || text.toLowerCase() === 'scv') {
          isAddMakeAndModelOpen = true;
          await this.getVehicleMake(customer_mgt.vehicle_type_id)
        } else {
          isAddMakeAndModelOpen = false;
        }
      }

      if (customer_mgt.sourceDropDown) {
        controls.mainFilterRadio.value = "source";
        controls.sourceDropDown.value = customer_mgt.sourceDropDown;
      }
      this.setState({
        controls,
        upcomingDueOrOverDueSwitch,
        isAddMoreFilterOpen,
        isAddMakeAndModelOpen
      }, () => {
        this.props.setDealerDashboardRedirectDetail(null);
        this.applyFilter();
      })
    }
  }

  setFilterControlData = () => {
    let obj = this.props.filteredData;
    let { controls, upcomingDueOrOverDueSwitch, isAddMoreFilterOpen, mainRadioOptions } = this.state;
    const { customerRegistrationFrom, customerRegistrationTo, upcomingDueFrom, upcomingDueTo,
      occasionDueFrom, occasionDueTo, upcomingDueRadio, occasionDueRadio, sourceDropDown,
      groupDropDown, upcomingDueServiceDropDown, visitPurposeServiceDropDown, visitPurposeVisitPurposeDropDown,
      vehicleTypeCheckBox, usageTypeCheckBox, mainFilterRadio, vehicleMake, vehicleModel, manualNumbers, ratings,
      customerBasedOnRadio
    } = controls;

    const {
      vehicle_type_id = null, vehicle_type_brand_id = null, vehicle_type_brand_model_id = null,
      vehicle_category = null, from_date = null, to_date = null, filter_type = null,
      due_date_from = null, due_date_to = null, purpose_id = null, purpose_type = null,
      service_id = null, source_of_member = null, group_id = null, occasion = null,
      occasion_date_from = null, occasion_date_to = null, custom_filter_manual_customer = [], due_type, rating,
      last_visit_from_date = null, last_visit_to_date = null
    } = obj;
    // Vehicle Type

    if (vehicle_type_id) {
      if (vehicle_type_id.length <= 0) {
        vehicleTypeCheckBox.value = ['all'];
      } else {
        vehicleTypeCheckBox.value = vehicle_type_id;
      }
    }
    if (vehicle_type_brand_id) {
      let checkBoxValue = vehicleTypeCheckBox.value;
      if (checkBoxValue.length === 1) {
        let vehicleTypeId = checkBoxValue[0];
        this.getVehicleMake(vehicleTypeId)
          .then(vehicleMakeData => {
            this.setState({ isAddMakeAndModelOpen: true });
            let index = vehicleMakeData.findIndex(v => v.brand_id === vehicle_type_brand_id);
            let vehicleMakeObj = vehicleMakeData[index];
            vehicleMake.value = vehicleMakeObj;
            this.setState({ controls });
            if (vehicle_type_brand_model_id) {
              this.getVehicleModel(vehicleMakeObj)
                .then(vehicleModelData => {
                  let modelIndex = vehicleModelData.findIndex(v => v.model_id === vehicle_type_brand_model_id);
                  let vehicleModalObj = vehicleModelData[modelIndex];
                  vehicleModel.value = vehicleModalObj;
                  this.setState({ controls });
                })
            }
          })
      } else {
        controls.vehicleMake.value = null;
        controls.vehicleModel.value = null;
        this.setState({ vehicleMakeData: [], vehicleModelData: [] })
      }
      // @NOTE get data from brand id
    }
    // if (vehicleTypeCheckBox.value && Array.isArray(vehicleTypeCheckBox.value) && vehicleTypeCheckBox.value.length > 0) {
    //   if (!(vehicleTypeCheckBox.value.length === 1 && vehicleTypeCheckBox.value[0] === 'all')) {
    //     filterObj['vehicle_type_id'] = vehicleTypeCheckBox.value;
    //   }
    //   if (vehicleTypeCheckBox.value.length === 1 && vehicleTypeCheckBox.value[0] !== 'all') {
    //     if (vehicleMake.value && vehicleMake.value.brand_id) {
    //       filterObj['vehicle_type_brand_id'] = vehicleMake.value.brand_id;
    //     }
    //     if (vehicleModel.value) {
    //       filterObj['vehicle_type_brand_model_id'] = vehicleModel.value.model_id;
    //     }
    //   }
    // }

    // @NOTE: DOUBT what is filed for usage type
    if (vehicle_category) {
      usageTypeCheckBox.value = vehicle_category;
    }

    // Customer Registration Filter
    if (from_date) {
      customerRegistrationFrom.value = new Date(from_date);
    }

    if (to_date) {
      let toDate = this.getToDateWhileSettingToControls(to_date);
      customerRegistrationTo.value = toDate;
    }


    if (last_visit_from_date) {
      customerBasedOnRadio.value = 'last_visit';
      customerRegistrationFrom.value = new Date(last_visit_from_date);
    }

    if (last_visit_to_date) {
      customerBasedOnRadio.value = 'last_visit';
      let toDate = this.getToDateWhileSettingToControls(last_visit_to_date);
      customerRegistrationTo.value = toDate;
    }

    if (filter_type) {
      mainFilterRadio.value = filter_type;
    }

    if (filter_type === 'due' || filter_type === 'all_due') {
      if (filter_type === 'all_due') {
        let index = this.state.mainRadioOptions.findIndex(x => x.key === 'Upcoming Visit Or Overdue Visit');
        if (index > -1) {
          mainRadioOptions[index].value = 'all_due';
          controls.mainFilterRadio.value = 'all_due';
        }
      }
      if (due_date_from) {
        upcomingDueFrom.value = new Date(due_date_from);
      }
      if (due_date_to) {
        let toDate = this.getToDateWhileSettingToControls(due_date_to);
        upcomingDueTo.value = toDate;
      }
      if (purpose_id) {
        upcomingDueRadio.value = purpose_id;
      }
      if (purpose_type) {
        upcomingDueRadio.type = purpose_type;
        if (!purpose_id) {
          let filter = this.state.visitPurposes.filter(x => x.type.toLowerCase() === purpose_type.toLowerCase());
          if (filter.length > 0) {
            controls.upcomingDueRadio.value = filter[0].purpose_id;
            purpose_id = filter[0].purpose_id;
          }
        }
      }
      if (due_type) {
        upcomingDueOrOverDueSwitch = due_type;
      }
      if (purpose_type === 'service') {
        if (service_id && Array.isArray(service_id) && service_id.length > 0) {
          upcomingDueServiceDropDown.value = service_id[0]
        }
      }
    }

    // Source
    if (filter_type === 'source') {
      if (source_of_member) {
        sourceDropDown.value = source_of_member;
      }
    }

    // Visit
    if (filter_type === 'last_visit_purpose' || filter_type === 'visit') {
      if (purpose_id) {
        visitPurposeVisitPurposeDropDown.value = purpose_id;
      }
      if (purpose_type) {
        visitPurposeVisitPurposeDropDown.type = purpose_type;
      }
      if (purpose_type === 'service') {
        if (service_id && Array.isArray(service_id) && service_id.length > 0) {
          visitPurposeServiceDropDown.value = service_id[0]
        }
      }
    }

    if (filter_type === 'group') {
      if (group_id) {
        groupDropDown.value = group_id;
      }
    }

    // Occasion
    // @TODO: add defaut from and due date
    if (filter_type === 'occasion') {
      if (occasion) {
        occasionDueRadio.value = occasion;
        if (occasion_date_from) {
          occasionDueFrom.value = new Date(occasion_date_from)
        }
        if (occasion_date_to) {
          let toDate = this.getToDateWhileSettingToControls(occasion_date_to);
          occasionDueTo.value = toDate;
        }
      }
    }

    if (filter_type === 'manual') {
      if (
        custom_filter_manual_customer
        && Array.isArray(custom_filter_manual_customer)
        && custom_filter_manual_customer.length > 0
      ) {
        let numbers = custom_filter_manual_customer.join('\n');
        manualNumbers.value = numbers;
        manualNumbers.numbers = custom_filter_manual_customer;
        customerBasedOnRadio.value = null;
      }
    }

    if (rating > 0) {
      ratings.value = rating;
    }

    if (filter_type === 'last_visit_purpose' || filter_type === 'last_visit') {
      mainFilterRadio.value = "last_visit_purpose";
      isAddMoreFilterOpen = false
    }


    // if(mainFilterRadio.value !== 'prospective_customer'){
    //   isAddMoreFilterOpen=false
    // }
    // if(mainFilterRadio.value==='manual'){
    //   filterObj['filter_type'] = mainFilterRadio.value;
    //   if(manualNumbers.value){
    //     filterObj['manual_customer'] = manualNumbers.value;
    //   }
    // }
    this.setState({ controls, upcomingDueOrOverDueSwitch, isAddMoreFilterOpen, mainRadioOptions });
  }

  onCloseSidebar = () => {
    this.props.onCloseSidebar();
  }


  scrollTo(id) {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80
    });
  }
  // handle control values
  handleDateChange = (controlName, e) => {
    const { controls } = this.state;
    let dateControl = controls[controlName];
    dateControl.value = e;
    dateControl.touched = true;
    this.setState({ controls });
    if (controlName === 'customerRegistrationFrom' || controlName === 'customerRegistrationTo') {
      this.resetAddManualNumber();
    }
    // this.handleValidation();
  }

  handleRadioChange = (controlName, e) => {
    let { controls, visitPurposes, vehicleMakeData, vehicleModelData, isAddMakeAndModelOpen, isAddMoreFilterOpen, mainRadioOptions } = this.state;
    let {
      vehicleTypeCheckBox, customerRegistrationFrom, customerRegistrationTo,
      usageTypeCheckBox, vehicleMake, vehicleModel, mainFilterRadio
    } = controls;
    let radioControl = controls[controlName]
    radioControl.value = e.target.value;

    if (controlName === 'customerBasedOnRadio') {
      if (e.target.value === 'last_visit') {
        mainFilterRadio.value = 'last_visit_purpose';
        isAddMoreFilterOpen = false;
        if (this.props.from === 'campaign') {
          GoogleAnalticsService.event('Campaign', 'Campaign Select Customer Last Visit Filter');
        } else {
          GoogleAnalticsService.event('Customer List', 'Customer Select Customer Last Visit Filter');
        }
      } else {
        mainFilterRadio.value = null;
        isAddMoreFilterOpen = true;
        if (this.props.from === 'campaign') {
          GoogleAnalticsService.event('Campaign', 'Campaign Select Customer Registration Date Filter');
        } else {
          GoogleAnalticsService.event('Customer List', 'Customer Select Customer Registration Date Filter');
        }
      }
    }
    if (controlName === 'upcomingDueRadio') {
      let purposeIndex = visitPurposes.findIndex(v => v.purpose_id === e.target.value)
      let purpose = visitPurposes[purposeIndex];
      radioControl.type = purpose.type;
    }
    if (controlName === 'mainFilterRadio') {
      if (e.target.value === 'manual') {
        vehicleTypeCheckBox.value = ['all'];
        customerRegistrationFrom.value = null;
        customerRegistrationTo.value = null;
        usageTypeCheckBox.value = [];
        vehicleMake.value = null;
        vehicleModel.value = null;
        vehicleModelData = [];
        vehicleMakeData = [];
        isAddMakeAndModelOpen = false;
      }
      if (e.target.value !== 'prospective_customer') {
        isAddMoreFilterOpen = false;
      }
      if (e.target.value === 'prospective_customer') {
        GoogleAnalticsService.event('Campaign', 'Campaign View Prospective Customers Filter');
      }
      if (controls.mainFilterRadio.value === 'all_due') {
        GoogleAnalticsService.event('Campaign', 'Campaign Upcoming Visit Or Overdue Visit Filter');
        let index = this.state.mainRadioOptions.findIndex(x => x.key === 'Upcoming Visit Or Overdue Visit');
        if (index > -1) {
          mainRadioOptions[index].value = 'due';
          controls.mainFilterRadio.value = 'due';
        }
      }
    }
    this.setState({ controls, vehicleModelData, isAddMakeAndModelOpen, isAddMoreFilterOpen, mainRadioOptions });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls, visitPurposes } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    if (controlName === 'vehicleMake') {
      this.getVehicleModel(controlValue);
    }
    if (controlName === 'visitPurposeVisitPurposeDropDown') {
      let purposeIndex = visitPurposes.findIndex(v => v.purpose_id === controlValue)
      if (purposeIndex > -1) {
        let purpose = visitPurposes[purposeIndex];
        controls[controlName].type = purpose.type;
      } else {
        controls[controlName].type = '';
      }
    }
    if (controlName === 'sourceDropDown') {
      if (this.props.from === 'campaign') {
        GoogleAnalticsService.event('Campaign', 'Campaign Source Filter');
      } else {
        GoogleAnalticsService.event('Customer List', 'Customer Source Filter');
      }
    }
    if (controlName === 'visitPurposeVisitPurposeDropDown') {
      if (this.props.from === 'campaign') {
        GoogleAnalticsService.event('Campaign', 'Campaign Visit Purpose Filter');
      } else {
        GoogleAnalticsService.event('Customer List', 'Customer Visit Purpose Filter');
      }
    }
    this.setState({ controls });
  }

  handleVehicleTypeCheckBoxInput = (e, name) => {
    let { controls } = this.state;
    const { vehicleTypeCheckBox } = controls;
    let checkBoxValue = vehicleTypeCheckBox.value;
    let valueIndex = checkBoxValue.indexOf(e);
    if (valueIndex < 0) {
      if ((e !== 'all' && checkBoxValue && checkBoxValue[0] === 'all') || e === 'all') {
        checkBoxValue = [e];
      } else {
        checkBoxValue.push(e);
      }
    } else {
      checkBoxValue.splice(valueIndex, 1);
    }
    if (checkBoxValue.length > 1 || (checkBoxValue.length === 1 && checkBoxValue[0] !== 'all')) {
      this.resetAddManualNumber();
    }
    if (checkBoxValue.length === 0) {
      vehicleTypeCheckBox.value = ['all']
    } else {
      vehicleTypeCheckBox.value = checkBoxValue;
    }
    if (checkBoxValue.length === 1 && e !== 'all') {
      let vehicleMake = checkBoxValue[0];
      this.getVehicleMake(vehicleMake);
    } else {
      controls.vehicleMake.value = null;
      controls.vehicleModel.value = null;
      this.setState({ vehicleMakeData: [], vehicleModelData: [] })
    }
    this.handleUsageTypeCheckBoxInputSetTodefault();
    this.generateVehicleTypeText(true);
    this.setState({ controls })
  }

  handleUsageTypeCheckBoxInputSetTodefault = (e) => {
    const { controls } = this.state;
    const { usageTypeCheckBox } = controls;
    usageTypeCheckBox.value = [];
    this.setState({ controls })
  }

  handleUsageTypeCheckBoxInput = (e) => {
    const { controls } = this.state;
    const { usageTypeCheckBox } = controls;
    const checkBoxValue = usageTypeCheckBox.value;
    let valueIndex = checkBoxValue.indexOf(e);
    if (valueIndex < 0) {
      checkBoxValue.push(e);
    } else {
      checkBoxValue.splice(valueIndex, 1);
    }
    if (this.props.from === 'campaign') {
      GoogleAnalticsService.event('Campaign', 'Campaign Usage Type Filter');
    } else {
      GoogleAnalticsService.event('Customer List', 'Customer Usage Type Filter');
    }
    this.resetAddManualNumber();
    this.setState({ controls })
  }

  openCloseVehicleTypeDropDown = () => {
    if (this.props.from === 'campaign') {
      GoogleAnalticsService.event('Campaign', 'Campaign Vehicle Type Filter');
    } else {
      GoogleAnalticsService.event('Customer List', 'Customer Vehicle Type Filter');
    }
    this.setState({
      isVehicleTypeDropDownOpen: !this.state.isVehicleTypeDropDownOpen
    })
  }

  generateVehicleTypeText = (toDisplayMakeAndModel) => {
    let { controls, vehicleTypeData, isAddMakeAndModelOpen } = this.state;
    let { vehicleTypeCheckBox, typetext } = controls;
    let text = '';
    if (vehicleTypeCheckBox.value.length === 0) {
      isAddMakeAndModelOpen = false;
    } else if (vehicleTypeCheckBox.value.length === 1) {
      if (vehicleTypeCheckBox.value[0] === 'all') {
        text = 'All';
        isAddMakeAndModelOpen = false;
      } else {
        let vehicleDataIndex = vehicleTypeData.findIndex(v => v.vehicle_type_id === vehicleTypeCheckBox.value[0]);
        text = vehicleTypeData[vehicleDataIndex].name;
        typetext.value = vehicleTypeData[vehicleDataIndex].name;
        if (text === 'Car' || text === '2/3 Wheeler' || text.toLowerCase() === 'lcv' || text.toLowerCase() === 'scv') {
          isAddMakeAndModelOpen = true;
        } else {
          isAddMakeAndModelOpen = false;
        }
      }
    } else {
      text = `${vehicleTypeCheckBox.value.length} selected`
      isAddMakeAndModelOpen = false;
    }
    if (toDisplayMakeAndModel) {
      this.setState({ isAddMakeAndModelOpen, typetext });
    } else {
      return text;
    }
  }

  closeVehicleTypeDropDownWhenClickedOutsideDropDown = event => {
    if (this.dropDownMenuContainer.current && this.dropDownMenuContainer.current.contains(event.target)) {

    } else {
      this.setState({ isVehicleTypeDropDownOpen: false })
    }
  };

  resetAddManualNumber = () => {
    let { controls, isAddMoreFilterOpen } = this.state;
    const { mainFilterRadio, manualNumbers } = controls;
    if (mainFilterRadio.value === 'manual') {
      mainFilterRadio.value = null;
      manualNumbers.value = null;
      isAddMoreFilterOpen = true;
    }
    this.setState({ controls, isAddMoreFilterOpen })
  }

  handleRatingChange = (rating) => {
    let { controls } = this.state;
    let { ratings } = controls;
    ratings.value = rating;
    this.setState({ controls });
  }

  enableMakeAndModel = () => {
    this.setState({ isAddMakeAndModelOpen: true })

  }
  // apis start
  getGroups = () => {
    // this.setState({
    //   isLoading: true
    // })
    return new Promise((resolve, reject) => {
      CustomerManagementService.getGroups({ page: 1, pageSize: 10000 })
        .then(res => {
          let { groupData } = this.state;
          if (res.data.data && res.data.data.groups && res.data.data.groups.length > 0) {
            groupData = res.data.data.groups;
          } else {
            groupData = [];
          }
          this.setState({
            groupData,
            // isLoading: false
          })
          return resolve(null);
        })
        .catch(e => {
          return resolve(e);
          // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
          // const message = err && err.message ? err.message : ERROR_MESSAGE;
          // this.setState({ isLoading: false });
        });
    })
  }

  getAllCustomerService = () => {
    // this.setState({
    //   isLoading: true
    // })
    return new Promise((resolve, reject) => {
      CustomerManagementService.getAllCustomerService()
        .then(res => {
          let { services } = this.state;
          if (res.data.data && res.data.data.length > 0) {
            services = res.data.data;
          } else {
            services = [];
          }
          this.setState({
            services,
            // isLoading: false
          })
          return resolve();
        })
        .catch(e => {

          // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
          // const message = err && err.message ? err.message : ERROR_MESSAGE;
          // this.setState({ isLoading: false });
          return reject(e);
        });
    })
  }

  getVisitPurpose = async () => {
    const { controls } = this.state;
    const {
      // visitPurposeVisitPurposeDropDown, 
      upcomingDueRadio } = controls;
    // this.setState({
    //   isLoading: true
    // })
    return new Promise(async (resolve, reject) => {
      CustomerManagementService.getCustomerVisitPurpose()
        .then(res => {
          let { visitPurposes } = this.state;
          if (res.data.data && res.data.data.length > 0) {
            visitPurposes = res.data.data;

            // visitPurposeVisitPurposeDropDown.value = visitPurposes[0].purpose_id;
            // visitPurposeVisitPurposeDropDown.type = visitPurposes[0].type;

            let serviceIndex = visitPurposes.findIndex(v => v.type === 'service');
            let servicePurpose = visitPurposes[serviceIndex];
            upcomingDueRadio.value = servicePurpose.purpose_id;
            upcomingDueRadio.type = servicePurpose.type;
          } else {
            visitPurposes = [];
          }
          this.setState({
            visitPurposes,
            controls,
            // isLoading: false
          })
          return resolve(null);
        })
        .catch(e => {

          // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
          // const message = err && err.message ? err.message : ERROR_MESSAGE;
          // this.setState({ isLoading: false });
          // return false;
          return reject(e);
        });
    })
  }

  getVehicleType = async () => {
    // this.setState({ isLoading: true });
    return new Promise(async (resolve, reject) => {
      CustomerManagementService.getVehicleType()
        .then(res => {
          let vehicleTypeDataArr = [{
            name: 'All',
            vehicle_type_id: 'all'
          }]
          let { vehicleTypeData } = this.state;
          if (res.data.data && res.data.data.length > 0) {
            vehicleTypeData = res.data.data;
          } else {
            vehicleTypeData = [];
          }
          vehicleTypeDataArr = [...vehicleTypeDataArr, ...vehicleTypeData]
          // let obj={
          //   name:'All',
          //   vehicle_type_id:'all'
          // }
          // vehicleTypeData.push(obj);
          this.setState({
            vehicleTypeData: vehicleTypeDataArr,
            // isLoading: false
          })
          return resolve(null);
        })
        .catch(e => {
          return reject(e);
          // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
          // const message = err && err.message ? err.message : ERROR_MESSAGE;
          // this.setState({ isLoading: false });
        });
    })
  }

  async getVehicleMake(vehicleType) {
    let { controls, vehicleMakeData, vehicleModelData } = this.state;
    let { vehicleModel } = controls;
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({
          isLoading: true
        })

        const { data } = await WarrantyRegistrationService.getVehicalMake({ vehicle_type_id: vehicleType });
        vehicleModel.value = null;
        vehicleModelData = [];
        if (data && data.data.length > 0) {
          vehicleMakeData = data.data;
        } else {
          vehicleMakeData = [];
        }

        this.setState({
          vehicleMakeData,
          vehicleModelData,
          isLoading: false,
          controls
        });
        return resolve(vehicleMakeData);
        // this.handleValidation();

      } catch (error) {
        this.setState({
          vehicleMakeData: [],
          vehicleModelData: [],
          isLoading: false
        })
        return reject(error);
      }
    })
  }

  async getVehicleModel(vehicleMake) {
    return new Promise(async (resolve, reject) => {
      try {
        let { controls, vehicleModelData } = this.state;
        let { vehicleModel } = controls;
        vehicleModel.value = null;
        this.setState({
          isLoading: true, controls
        })
        const { data } = await WarrantyRegistrationService.getVehicalModel({ vehicle_type_brand_id: vehicleMake.brand_id });
        if (data && data.data && data.data.length > 0) {

          // let model = _.filter(data.data, (element) => {
          //   return element.model_id === controls.vehicle_type_brand_model_id.value.model_id;
          // });
          // if (model.length > 0) {
          //   controls.vehicle_type_brand_model_id.value = model[0]
          // }
          vehicleModelData = data.data;
        } else {
          vehicleModelData = [];
        }
        this.setState({
          vehicleModelData: vehicleModelData,
          isLoading: false,
          controls
        })
        return resolve(vehicleModelData);
        // this.handleValidation();
      } catch (error) {
        this.setState({
          isLoading: false
        })
        return reject(error);
      }
    })
  }

  getSourceMembers = () => {
    return new Promise((resolve, reject) => {
      CampaignService.getSourceofCustomer({ page: 1, pageSize: 10000 })
        .then(res => {
          let { sourceMembers } = this.state;
          if (res.data.data && res.data.data.length > 0) {
            sourceMembers = res.data.data;
          } else {
            sourceMembers = [];
          }
          this.setState({
            sourceMembers
          });
          return resolve(null);
        })
        .catch(e => {
          let err =
            e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.length > 0 &&
              e.response.data.error[0]
              ? e.response.data.error[0]
              : null;
          return reject(err);
          // this.setState({ isLoading: false });
        });
    })
  };

  // validation

  handleValidation = (firstTime = false) => {
    const { controls } = this.state;
    let { upcomingDueFrom,
      // upcomingDueTo,
      occasionDueFrom, occasionDueTo,
      mainFilterRadio, manualNumbers,
      groupDropDown
    } = controls;
    let isFormValid = false;
    // if (mainFilterRadio.value === 'upcoming_due') {
    //   if (firstTime === true || upcomingDueTo.touched === true) {
    //     if (
    //       upcomingDueTo.value === null ||
    //       upcomingDueTo.value === undefined ||
    //       upcomingDueTo.value === ""
    //     ) {
    //       upcomingDueTo.nullValue = true;
    //     } else {
    //       upcomingDueTo.nullValue = false;
    //     }
    //     // if (typeof upcomingDueTo.value !== 'object') {
    //     //   upcomingDueTo.invalidDate = true;
    //     // }

    //     upcomingDueTo.valid = !upcomingDueTo.nullValue;
    //   }

    if (firstTime === true || upcomingDueFrom.touched === true) {
      if (
        upcomingDueFrom.value === null ||
        upcomingDueFrom.value === undefined ||
        upcomingDueFrom.value === ""
      ) {
        upcomingDueFrom.nullValue = true;
      } else {
        upcomingDueFrom.nullValue = false;
      }
      // if (typeof upcomingDueTo.value !== 'object') {
      //   upcomingDueTo.invalidDate = true;
      // }

      upcomingDueFrom.valid = !upcomingDueFrom.nullValue;

    }
    if (mainFilterRadio.value === 'occasion') {
      if (firstTime === true || occasionDueTo.touched === true) {
        if (
          occasionDueTo.value === null ||
          occasionDueTo.value === undefined ||
          occasionDueTo.value === ""
        ) {
          occasionDueTo.nullValue = true;
        } else {
          occasionDueTo.nullValue = false;
        }
        // if (typeof occasionDueTo.value !== 'object') {
        //   occasionDueTo.invalidDate = true;
        // }

        occasionDueTo.valid = !occasionDueTo.nullValue;
      }

      if (firstTime === true || occasionDueFrom.touched === true) {
        if (
          occasionDueFrom.value === null ||
          occasionDueFrom.value === undefined ||
          occasionDueFrom.value === ""
        ) {
          occasionDueFrom.nullValue = true;
        } else {
          occasionDueFrom.nullValue = false;
        }
        // if (typeof occasionDueTo.value !== 'object') {
        //   occasionDueTo.invalidDate = true;
        // }

        occasionDueFrom.valid = !occasionDueFrom.nullValue;
      }
    }

    // Group
    if (mainFilterRadio.value === 'group') {
      if (groupDropDown.value && groupDropDown.value !== 'all') {
        groupDropDown.valid = true;
        groupDropDown.nullValue = false;
        groupDropDown.showErrorMsg = false;
      } else {
        groupDropDown.valid = false;
        groupDropDown.nullValue = true;
        groupDropDown.showErrorMsg = true;
      }
    }

    if (mainFilterRadio.value === 'manual') {
      manualNumbers = this.extractNumbersFromManualNumbers(manualNumbers);
    }

    if (mainFilterRadio.value === 'due' || mainFilterRadio.value === 'all_due') {
      if (upcomingDueFrom.valid) {
        isFormValid = true
      }
    } else
      if (mainFilterRadio.value === 'occasion') {
        if (occasionDueTo.valid || occasionDueFrom.valid) {
          isFormValid = true
        }
      } else if (mainFilterRadio.value === 'group') {
        if (groupDropDown.valid) {
          isFormValid = true
        }
      } else if (mainFilterRadio.value === 'manual') {
        if (manualNumbers.valid) {
          isFormValid = true
        }
      } else {
        isFormValid = true;
      }

    // if(!isFormValid && mainFilterRadio.value === 'manual'){
    //   this.scrollTo("manual_number_error")
    // }
    // if(!isFormValid && mainFilterRadio.value === 'occasion'){
    //   this.scrollTo("occasion_error")
    // }

    this.setState({ controls, isFormValid });
    return isFormValid;

  }

  extractNumbersFromManualNumbers = (manualNumbers) => {
    let array = manualNumbers.value.split("\n");
    for (
      var i = 0;
      i < array.length;
      i++
    ) {

      if (!(isNaN(parseInt(array[i])) === false && array[i].length === 10)) {
        var index = array.indexOf(
          array[i]
        );
        array.splice(index, 1);
      }

    }
    if (array.length === 0) {
      manualNumbers.valid = false;
      manualNumbers.showErrorMsg = true;
    } else {
      array = _.uniq(array);
      manualNumbers.numbers = array;

      manualNumbers.valid = true;
      manualNumbers.showErrorMsg = false;
    }
    return manualNumbers;
  }

  getToDateWhileSettingToControls = (date) => {
    let dateToProcess = Object.assign(date);
    dateToProcess = new Date(dateToProcess);
    let currentDate = dateToProcess.getDate();
    dateToProcess.setDate(currentDate - 1);
    dateToProcess.setHours(23, 59, 59, 59);
    return dateToProcess;
  }

  getToDateWhileSending = (date) => {
    let dateToProcesss = Object.assign(date);
    let currentDate = dateToProcesss.getDate();
    dateToProcesss.setDate(currentDate + 1);
    dateToProcesss.setHours(5, 29, 59, 59);
    return dateToProcesss.toISOString();
  }

  applyFilter = () => {
    if (this.props.from === 'campaign') {
      GoogleAnalticsService.event('Campaign', 'Campaign Filter Apply Click');
    }
    const { controls, upcomingDueOrOverDueSwitch } = this.state;
    const { customerRegistrationFrom, customerRegistrationTo, upcomingDueFrom, upcomingDueTo,
      occasionDueFrom, occasionDueTo, upcomingDueRadio, occasionDueRadio, sourceDropDown,
      groupDropDown, upcomingDueServiceDropDown, visitPurposeServiceDropDown, visitPurposeVisitPurposeDropDown,
      vehicleTypeCheckBox, usageTypeCheckBox, mainFilterRadio, vehicleMake, vehicleModel, manualNumbers, ratings, customerBasedOnRadio
    } = controls;
    let filterObj = {}
    let isFormValid = this.handleValidation(true);
    if (!isFormValid) {
      return;
    }

    // Vehicle Type
    if (vehicleTypeCheckBox.value && Array.isArray(vehicleTypeCheckBox.value) && vehicleTypeCheckBox.value.length > 0) {
      if (!(vehicleTypeCheckBox.value.length === 1 && vehicleTypeCheckBox.value[0] === 'all')) {
        filterObj['vehicle_type_id'] = vehicleTypeCheckBox.value;
      }
      if (vehicleTypeCheckBox.value.length === 1 && vehicleTypeCheckBox.value[0] !== 'all') {
        if (vehicleMake.value && vehicleMake.value.brand_id) {
          filterObj['vehicle_type_brand_id'] = vehicleMake.value.brand_id;
        }
        if (vehicleModel.value) {
          filterObj['vehicle_type_brand_model_id'] = vehicleModel.value.model_id;
        }
      }
    }

    // @NOTE: DOUBT what is filed for usage type
    if (usageTypeCheckBox.value && Array.isArray(usageTypeCheckBox.value) && usageTypeCheckBox.value.length > 0) {
      filterObj['vehicle_category'] = usageTypeCheckBox.value;
      GoogleAnalticsService.event(`${this.props.from === 'campaign' ? 'Campaign' : 'Customer List'} Filter`, 'Campaign Usage Type Filter');
    }

    // Customer Registration Filter
    if (customerBasedOnRadio.value === 'registration') {
      if (customerRegistrationFrom.value) {
        let customerRegistrationFromValue = Object.assign(customerRegistrationFrom.value);
        customerRegistrationFromValue.setHours(5, 30, 0, 0);
        filterObj['from_date'] = customerRegistrationFromValue.toISOString();
      }
      if (customerRegistrationTo.value) {
        // let customerRegistrationToValue = Object.assign(customerRegistrationTo.value);
        // customerRegistrationToValue=
        // let currentDate=customerRegistrationToValue.getDate();
        // customerRegistrationToValue.setDate(currentDate+1);
        // customerRegistrationToValue.setHours(5, 29, 59, 59);
        let dateToProcess = new Date(customerRegistrationTo.value.getTime());
        filterObj['to_date'] = this.getToDateWhileSending(dateToProcess);
      }
    }

    if (customerBasedOnRadio.value === 'last_visit') {
      if (customerRegistrationFrom.value) {
        let customerRegistrationFromValue = Object.assign(customerRegistrationFrom.value);
        customerRegistrationFromValue.setHours(5, 30, 0, 0);
        filterObj['last_visit_from_date'] = customerRegistrationFromValue.toISOString();
      }
      if (customerRegistrationTo.value) {
        // let customerRegistrationToValue = Object.assign(customerRegistrationTo.value);
        // customerRegistrationToValue=
        // let currentDate=customerRegistrationToValue.getDate();
        // customerRegistrationToValue.setDate(currentDate+1);
        // customerRegistrationToValue.setHours(5, 29, 59, 59);
        let dateToProcess = new Date(customerRegistrationTo.value.getTime());
        filterObj['last_visit_to_date'] = this.getToDateWhileSending(dateToProcess);
      }
    }

    // Prospective Customer
    if (mainFilterRadio.value === 'prospective_customer') {
      filterObj['filter_type'] = mainFilterRadio.value;
      GoogleAnalticsService.event(`${this.props.from === 'campaign' ? 'Campaign' : 'Customer List'} Filter`, 'View Prospective Filter ');
    }

    // upcoming Due
    // @NOTE : DOUBT check with kinjal for upcoming fields
    if (mainFilterRadio.value === 'due' || mainFilterRadio.value === 'all_due') {
      GoogleAnalticsService.event(`${this.props.from === 'campaign' ? 'Campaign' : 'Customer List'} Filter`, 'Upcoming Visit Or Overdue Visit Filter');
      filterObj['filter_type'] = mainFilterRadio.value;
      if (upcomingDueFrom.value) {
        let upcomingDueFromValue = Object.assign(upcomingDueFrom.value);
        upcomingDueFromValue.setHours(5, 30, 0, 0);
        filterObj['due_date_from'] = upcomingDueFromValue.toISOString();
      }
      if (upcomingDueTo.value) {
        // let upcomingDueToValue = Object.assign(upcomingDueTo.value);
        // upcomingDueToValue.setHours(23, 59, 59, 59);
        let dateToProcess = new Date(upcomingDueTo.value.getTime());

        filterObj['due_date_to'] = this.getToDateWhileSending(dateToProcess);
      }

      if (upcomingDueRadio.value) {
        filterObj['purpose_id'] = upcomingDueRadio.value;
      }
      if (upcomingDueRadio.type) {
        filterObj['purpose_type'] = upcomingDueRadio.type;
        if (upcomingDueRadio.type === 'service') {
          if (upcomingDueServiceDropDown.value && upcomingDueServiceDropDown.value !== 'all') {
            filterObj['service_id'] = [upcomingDueServiceDropDown.value];
          }
        }
      }
      if (upcomingDueRadio.value === 'tyre_purchase') {
        filterObj['purpose_type'] = 'tyre_purchase';
      }

      if (upcomingDueOrOverDueSwitch) {
        filterObj['due_type'] = upcomingDueOrOverDueSwitch;
      }
    }

    if (mainFilterRadio.value === 'customer_rating') {
      filterObj['filter_type'] = mainFilterRadio.value;
      filterObj['rating'] = ratings.value;
    }
    // Source
    if (mainFilterRadio.value === 'source') {
      filterObj['filter_type'] = mainFilterRadio.value;
      if (sourceDropDown.value && sourceDropDown.value !== 'all') {
        filterObj['source_of_member'] = sourceDropDown.value;
      }
    }

    // Visit
    if (mainFilterRadio.value === 'last_visit_purpose' ||
      mainFilterRadio.value === 'visit') {
      filterObj['filter_type'] = mainFilterRadio.value;
      if (visitPurposeVisitPurposeDropDown.value) {
        filterObj['purpose_id'] = visitPurposeVisitPurposeDropDown.value;
      }
      if (visitPurposeVisitPurposeDropDown.type) {
        filterObj['purpose_type'] = visitPurposeVisitPurposeDropDown.type;
        if (visitPurposeVisitPurposeDropDown.type === 'service') {
          if (visitPurposeServiceDropDown.value && visitPurposeServiceDropDown.value !== 'all') {
            filterObj['service_id'] = [visitPurposeServiceDropDown.value];
          }
        }
      }
    }

    // Group
    if (mainFilterRadio.value === 'group') {
      filterObj['filter_type'] = mainFilterRadio.value;
      if (groupDropDown.value && groupDropDown.value !== 'all') {
        filterObj['group_id'] = groupDropDown.value;
      }

      if (this.props.from === 'campaign') {
        GoogleAnalticsService.event('Campaign', 'Campaign Group Filter');
      } else {
        GoogleAnalticsService.event('Customer List', 'Customer Group Filter');
      }
      // GoogleAnalticsService.event(`${this.props.from === 'campaign' ? 'Campaign' : 'Customer List'} Filter`, 'Group Filter');
    }

    // Occasion
    // @TODO: add defaut from and due date
    if (mainFilterRadio.value === 'occasion') {
      filterObj['filter_type'] = mainFilterRadio.value;
      if (occasionDueRadio.value) {
        filterObj['occasion'] = occasionDueRadio.value;
        if (occasionDueFrom.value) {
          let occasionDueFromValue = Object.assign(occasionDueFrom.value);
          occasionDueFromValue.setHours(5, 30, 0, 0);
          filterObj['occasion_date_from'] = occasionDueFromValue.toISOString();
        }
        if (occasionDueTo.value) {
          // let occasionDueToValue = Object.assign(occasionDueTo.value);
          // occasionDueToValue.setHours(23, 59, 59, 59);
          let dateToProcess = new Date(occasionDueTo.value.getTime());

          filterObj['occasion_date_to'] = this.getToDateWhileSending(dateToProcess);
        }
      }
      // GoogleAnalticsService.event(`${this.props.from === 'campaign' ? 'Campaign' : 'Customer List'} Filter`, 'Occasion Filter');
      if (this.props.from === 'campaign') {
        GoogleAnalticsService.event('Campaign', 'Campaign Occasion Filter');
      } else {
        GoogleAnalticsService.event('Customer List', 'Customer Occasion Filter');
      }
    }

    if (mainFilterRadio.value === 'manual') {
      filterObj['filter_type'] = mainFilterRadio.value;
      if (manualNumbers.numbers && Array.isArray(manualNumbers.numbers)) {
        filterObj['custom_filter_manual_customer'] = manualNumbers.numbers;
      }
    }
    if (filterObj.filter_type && filterObj['filter_type'] === 'all_due') {
      filterObj['due_type'] = '';
    }
    // if (controls.is_hide_upcoming_over_due_tab.value && (filterObj['filter_type'] === 'due' ||
    //   filterObj['filter_type'] === 'all_due')) {
    //   filterObj['is_hide_upcoming_over_due_tab'] = controls.is_hide_upcoming_over_due_tab.value;
    // } else {
    //   filterObj['is_hide_upcoming_over_due_tab'] = false;
    //   controls.is_hide_upcoming_over_due_tab.value = false;
    // }
    const obj = {
      filter: filterObj,
      state: this.state
    }
    GoogleAnalticsService.event(`${this.props.from === 'campaign' ? 'Campaign' : 'Customer List'} Filter`, 'Customer Apply Filter');
    this.props.applyFilter(obj);
  }

  resetFilter = () => {
    this.props.applyFilter(null);
  }


  handleUpcomingDueOrOverdueSwitchChange = (switchValue) => {
    const { controls } = this.state;
    const { upcomingDueFrom, upcomingDueTo } = controls;
    if (this.state.upcomingDueOrOverDueSwitch !== switchValue) {
      upcomingDueTo.value = null;
      upcomingDueFrom.value = null;
    }
    this.setState({ upcomingDueOrOverDueSwitch: switchValue, controls })
  }

  hideShowAddMoreFilter = () => {
    this.setState({ isAddMoreFilterOpen: !this.state.isAddMoreFilterOpen })
  }

  render() {
    const { groupData, services, visitPurposes, vehicleTypeData,
      vehicleMakeData, vehicleModelData, controls, isAddMakeAndModelOpen,
      sourceMembers, upcomingDueOrOverDueSwitch, isAddMoreFilterOpen
    } = this.state;
    const { customerRegistrationFrom, customerRegistrationTo, upcomingDueFrom, upcomingDueTo,
      occasionDueFrom, occasionDueTo, upcomingDueRadio, occasionDueRadio, sourceDropDown,
      groupDropDown, upcomingDueServiceDropDown, visitPurposeServiceDropDown, visitPurposeVisitPurposeDropDown,
      // typetext,
      vehicleTypeCheckBox, usageTypeCheckBox, mainFilterRadio, vehicleMake, vehicleModel, manualNumbers, customerBasedOnRadio,
      ratings
    } = controls;
    const { from } = this.props;
    const LCVIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'lcv');
    const LCVId = LCVIndex && LCVIndex > -1 ? vehicleTypeData[LCVIndex].vehicle_type_id : null;
    const SCVIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'scv');
    const SCVId = SCVIndex && SCVIndex > -1 ? vehicleTypeData[SCVIndex].vehicle_type_id : null;
    const CarIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'car');
    const CarId = CarIndex && CarIndex > -1 ? vehicleTypeData[CarIndex].vehicle_type_id : null;
    const TwoWheelIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === '2 wheeler');
    const TwoWheelId = TwoWheelIndex && TwoWheelIndex > -1 ? vehicleTypeData[TwoWheelIndex].vehicle_type_id : null;
    const TruckIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'truck');
    const TruckId = TruckIndex && TruckIndex > -1 ? vehicleTypeData[TruckIndex].vehicle_type_id : null;
    const BusIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'bus');
    const BusId = BusIndex && BusIndex > -1 ? vehicleTypeData[BusIndex].vehicle_type_id : null;
    const FarmIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'farm');
    const FarmId = FarmIndex && FarmIndex > -1 ? vehicleTypeData[FarmIndex].vehicle_type_id : null;

    const mappleConfig = {
      direction: "top",
      backgroundColor: "white",
      float: false
    };
    return <div id="customer-management-filter-new" className="height-100"
      onMouseDown={this.closeVehicleTypeDropDownWhenClickedOutsideDropDown}
    >
      <div className="drawer-header" style={{ paddingTop: '18px' }}>
        <span className="drawer-title">Filter</span>
        <Ionicon className="cursor-pointer" icon="md-close" fontSize="24px" color="#fdbb1f" onClick={() => this.onCloseSidebar()} />
      </div>
      <hr style={{ border: 'solid 1px #ced4da', marginTop: '8px' }} />
      {this.state.isLoading && <CustomSpinner />}

      {/* Part One */}
      <div className="data-container">
        <div className="filter-vehicle-detail">
          <CardBody className="padding-top-bottom-0">
            <div className="filter-section-title">Select Vehicle Details</div>
            <Row>
              <Col sm="5">
                <div className="field-label">Vehicle Type</div>
                <div style={{ position: 'relative', marginTop: '7px' }} ref={this.dropDownMenuContainer}>
                  <div
                    onClick={this.openCloseVehicleTypeDropDown}
                    className="form-control option-text cursor-pointer drop-down-control"
                  // checked={usageTypeCheckBox.value.includes(v.value)}
                  // onChange={this.handleUsageTypeCheckBoxInput.bind(this, v.value)}
                  >{this.generateVehicleTypeText()}
                    <div className="custom-drop-down-arrow">
                      <img src="/images/down-arrow.svg" alt='' />
                    </div>
                  </div>

                  {this.state.isVehicleTypeDropDownOpen && <div className="custom-drop-down">
                    {vehicleTypeData.map((v, index) =>
                      <>
                        <div style={v.name.toLowerCase() === '2 wheeler' || v.name.toLowerCase() === 'pickup' ? { width: '100px' } : {}}>
                          <label class="container-checkbox drop-down-control">
                            <input type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              className="option-text"
                              checked={vehicleTypeCheckBox.value.includes(v.vehicle_type_id)}
                              onChange={() => this.handleVehicleTypeCheckBoxInput(v.vehicle_type_id, v.name)}
                            />
                            {v.name}
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </>
                    )}
                  </div>}
                </div>
              </Col>
              <Col>
                {(vehicleTypeCheckBox.value.length >= 1 && !vehicleTypeCheckBox.value.includes(LCVId) && !vehicleTypeCheckBox.value.includes(SCVId) && !vehicleTypeCheckBox.value.includes('all')) &&
                  <div className="field-label">Usage Type</div>}
                {(vehicleTypeCheckBox.value.length >= 1 && !vehicleTypeCheckBox.value.includes(LCVId) && !vehicleTypeCheckBox.value.includes(SCVId) && !vehicleTypeCheckBox.value.includes('all')) &&
                  <div style={{ marginTop: '8px' }}>
                    {vehicleUsageCheckboxOption.map(v =>
                      // <Col sm="3">
                      <div className="usage-type-checkbox-div">
                        <label class="container-checkbox">
                          <input type="checkbox"
                            name="checkbox"
                            id="checkbox"
                            className="option-text"
                            checked={usageTypeCheckBox.value.includes(v.value)}
                            onChange={this.handleUsageTypeCheckBoxInput.bind(this, v.value)}
                          />
                          {v.key}
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      // </Col>
                    )}
                  </div>
                }
                {(vehicleTypeCheckBox.value.length >= 1 && vehicleTypeCheckBox.value.length <= 2 && !vehicleTypeCheckBox.value.includes(CarId) && !vehicleTypeCheckBox.value.includes(TwoWheelId) && !vehicleTypeCheckBox.value.includes(TruckId)
                  && !vehicleTypeCheckBox.value.includes(BusId) && !vehicleTypeCheckBox.value.includes(FarmId) && !vehicleTypeCheckBox.value.includes('all')) &&
                  <div className="field-label">Usage Type</div>}
                {(vehicleTypeCheckBox.value.length >= 1 && vehicleTypeCheckBox.value.length <= 2 && !vehicleTypeCheckBox.value.includes(CarId) && !vehicleTypeCheckBox.value.includes(TwoWheelId) && !vehicleTypeCheckBox.value.includes(TruckId)
                  && !vehicleTypeCheckBox.value.includes(BusId) && !vehicleTypeCheckBox.value.includes(FarmId) && !vehicleTypeCheckBox.value.includes('all')) &&
                  <div style={{ marginTop: '8px' }}>
                    {vehicleUsageCheckboxOptionLCVSCV.map(v =>
                      // <Col sm="3">
                      <div className="usage-type-checkbox-div">
                        <label class="container-checkbox">
                          <input type="checkbox"
                            name="checkbox"
                            id="checkbox"
                            className="option-text"
                            checked={usageTypeCheckBox.value.includes(v.value)}
                            onChange={this.handleUsageTypeCheckBoxInput.bind(this, v.value)}
                          />
                          {v.key}
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      // </Col>
                    )}
                  </div>
                }
              </Col>
            </Row>
            {/* </Row> */}
            {isAddMakeAndModelOpen && <>
              {/* {!isAddMakeAndModelOpen && <div
                className="link-blue margin-top-5"
                onClick={this.enableMakeAndModel}
              >
                Add Make and Model
              </div>} */}
              {<>
                <Row>
                  <Col sm="5">
                    <Label className="width-100">
                      <div className="field-label margin-bottom-5">Vehicle Make</div>
                      <Typeahead
                        id="vehicleMake"
                        disabled={false}
                        selected={vehicleMake.value ? [vehicleMake.value] : []}
                        filterBy={(option, props) => {
                          if (props.selected.length) {
                            return true;
                          }
                          return option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || option.name.toLowerCase().indexOf('other') !== -1;
                        }}
                        labelKey="name"
                        options={vehicleMakeData}
                        placeholder="Search..."
                        onChange={(selectedModel) => this.handleInputChange({
                          target: { name: 'vehicleMake', value: selectedModel[0] }
                        })}
                      />
                      <img src="images/search.svg" className="report-search icon-width" alt='' />
                    </Label>
                  </Col>
                  {/* </Row>

                <Row> */}
                  <Col sm="6">
                    <Label className="width-100">
                      <div className="field-label margin-bottom-5">Vehicle Model</div>
                      <Typeahead
                        id="vehicleModel"
                        disabled={false}
                        selected={vehicleModel.value ? [vehicleModel.value] : []}
                        filterBy={(option, props) => {
                          if (props.selected.length) {
                            return true;
                          }
                          return option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || option.name.toLowerCase().indexOf('other') !== -1;
                        }}
                        labelKey="name"
                        options={vehicleModelData}
                        placeholder="Search..."
                        onChange={(selectedModel) => this.handleInputChange({
                          target: { name: 'vehicleModel', value: selectedModel[0] }
                        })}
                      />
                      <img src="images/search.svg" className="report-search icon-width" alt='' />
                    </Label>
                  </Col>
                </Row>
              </>}
            </>}
          </CardBody>
          <hr className="border-yellow"></hr>
        </div >

        {/* Part two */}
        <div className="filter-customer-registration" >
          <CardBody className="padding-top-bottom-0">
            <div className="filter-section-title">Select Customer Based On</div>
            <Row className="margin-top-15">
              <Col>
                {customerBasedOnRadioOptions.map((c, index) => {
                  return <>
                    <label className="container-rb">
                      {c.key}
                      <input
                        type="radio"
                        name="customerBasedOnRadio"
                        value={c.value}
                        onChange={this.handleRadioChange.bind(this, 'customerBasedOnRadio')}
                        checked={customerBasedOnRadio.value === c.value}
                      />
                      <span className="checkmark-rb"></span>
                    </label>&nbsp;&nbsp;&nbsp;&nbsp;
                  </>
                }
                )}
              </Col>
            </Row>
            <Row className="margin-top-5">
              <Col>
                <div className="display-inline">
                  <FormGroup>
                    <Label for="password" className="field-label display-inline">From</Label>&nbsp;
                    <div className="display-inline">
                      <Label
                        onClick={e => this.customerRegistrationFrom.state.open && e.preventDefault()}
                      >
                        <InputGroup>
                          <DatePicker className={"form-control calendar-input"}
                            selected={customerRegistrationFrom.value}
                            onChange={this.handleDateChange.bind(this, 'customerRegistrationFrom')}
                            showMonthDropdown
                            ref={r => this.customerRegistrationFrom = r}
                            // shouldCloseOnSelect={true}
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="Select"
                            shouldCloseOnSelect
                            maxDate={customerRegistrationTo.value ? customerRegistrationTo.value : null}
                            dropdownMode="select"
                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                        {/* <InputGroup>
                          <DatePicker className={"form-control calendar-input"}
                            selected={customerRegistrationFrom.value}
                            onChange={this.handleDateChange.bind(this, 'customerRegistrationFrom')}
                            ref={r => this.customerRegistrationFrom = r}
                            showMonthDropdown
                            // shouldCloseOnSelect={true}
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            shouldCloseOnSelect
                            maxDate={new Date()}
                            placeholderText="Select"
                            // maxDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                            // dropdownMode="select"
                          />
                          <InputGroupAddon addonType="append">
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup> */}
                      </Label>
                    </div>
                  </FormGroup>
                </div>&nbsp;&nbsp;
                <div className="display-inline">
                  <FormGroup>
                    <Label for="password" className="field-label display-inline">To</Label>&nbsp;
                    <div className="display-inline">
                      <Label
                        onClick={e => this.customerRegistrationTo.state.open && e.preventDefault()}
                      >
                        <InputGroup>
                          <DatePicker className={"form-control calendar-input"}
                            selected={customerRegistrationTo.value}
                            onChange={this.handleDateChange.bind(this, 'customerRegistrationTo')}
                            showMonthDropdown
                            ref={r => this.customerRegistrationTo = r}
                            // shouldCloseOnSelect={true}
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="Select"
                            shouldCloseOnSelect
                            minDate={customerRegistrationFrom.value}
                            // maxDate={new Date()}
                            dropdownMode="select"
                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text">
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </div>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </CardBody>
          <hr className="border-yellow"></hr>
        </div >

        {/* all filter  selection*/}
        {isAddMoreFilterOpen && <div className="filter-add-more margin-bottom-15" >
          <CardBody className="padding-top-bottom-0">
            {/* <div className="filter-section-title">Add More Filters</div> */}
            <Row>
              <Col sm="8" style={{ paddingRight: '0px' }}>
                <div className="filter-section-title">Add More Filters</div>
              </Col>
              {(mainFilterRadio.value && mainFilterRadio.value !== 'prospective_customer') && <Col>
                <div className="text-align-right">
                  <span className="link-blue" onClick={this.hideShowAddMoreFilter}>Hide Filters</span>
                </div>
              </Col>}
            </Row>
            <Row className="margin-top-15">
              <Col>
                {this.state.mainRadioOptions.map((m, index) => {
                  let displayDropDown = false;
                  if (m.value === 'manual') {
                    if (from === 'campaign') {
                      displayDropDown = true;
                    }
                  } else if (m.value === 'group') {
                    if (from !== 'manage-group') {
                      displayDropDown = true;
                    }
                  } else {
                    displayDropDown = true;
                  }
                  return displayDropDown && <>
                    <label className="container-rb">
                      {m.key}
                      <input
                        type="radio"
                        name="mainFilterRadio"
                        value={m.value}
                        onChange={this.handleRadioChange.bind(this, 'mainFilterRadio')}
                        checked={mainFilterRadio.value === m.value}
                      />
                      <span className="checkmark-rb"></span>
                    </label>&nbsp;&nbsp;&nbsp;&nbsp;
                  </>
                }
                )}
              </Col>
            </Row>

          </CardBody>
          {/* <hr className="border-yellow"></hr> */}
        </div >}

        {/* Part three */}
        {/* <div className="filter-prospective-customer" >
          <CardBody className="padding-top-bottom-0">
            <Row>
             
              <Col
                className="cursor-pointer padding-left-3"
              >
                <div className="filter-section-title">View Prospective Customers</div>
                <div className="filter-section-sub-title">Customers who have not trade anything so far</div>
              </Col>
            </Row>
          </CardBody>
          <hr className="border-yellow"></hr>
        </div > */}

        {/* Part Four */}
        {(mainFilterRadio.value === "due" || mainFilterRadio.value === "all_due") && <div className="filter-upcoming-due">
          <CardBody className="padding-top-bottom-0">
            <Row>
              <Col
                className="cursor-pointer"
              // onClick={() => { this.handleRadioChange('mainFilterRadio', { target: { value: 'upcoming_due' } }) }}
              >
                <Row>
                  <Col sm="8" style={{ paddingRight: '0px' }}>
                    <div className="filter-section-title">Based On Upcoming Visits Or Overdue Visits</div>
                  </Col>
                  {!isAddMoreFilterOpen && <Col>
                    <div className="text-align-right">
                      <span className="link-blue" onClick={this.hideShowAddMoreFilter}>Show More Filters</span>
                    </div>
                  </Col>}
                </Row>
                <div className="filter-section-sub-title">Service or Tyre Purchase Due</div>
              </Col>
            </Row>
            <Row className="margin-top-15">
              {/* <Col sm="1"></Col> */}
              <Col>
                {mainFilterRadio.value !== 'all_due' &&
                  <>
                    <Row>
                      <Col>
                        <div className="switch-container">
                          <div
                            className={`switch-data ${upcomingDueOrOverDueSwitch === 'upcoming_due' ? 'active-switch' : ''}`}
                            onClick={this.handleUpcomingDueOrOverdueSwitchChange.bind(this, 'upcoming_due')}>Upcoming</div>
                          <div
                            className={`switch-data ${upcomingDueOrOverDueSwitch === 'over_due' ? 'active-switch' : ''}`}
                            onClick={this.handleUpcomingDueOrOverdueSwitchChange.bind(this, 'over_due')}
                          >Overdue</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="overdue-note mb-2">
                        {
                          upcomingDueOrOverDueSwitch === 'upcoming_due' ? 'Find the customers who has visited you in the past and now whose visit is due in the future. Find based on date range for the upcoming appoints due in that period.'
                            : 'Find the customers whose visit was due based on their visit history but did not visit the store. Find based on the date range the customer whose visit is overdue in that period.'}
                      </Col>
                    </Row>
                  </>
                }
                <Row>
                  <Col>
                    {visitPurposes.map((v, index) => {
                      return ['service', 'tyre_purchase'].includes(v.type) && <>
                        <label className="container-rb">
                          {v.name} Due
                          <input
                            type="radio"
                            name="upcomingDueRadio"
                            value={v.purpose_id}
                            onChange={this.handleRadioChange.bind(this, 'upcomingDueRadio')}
                            checked={upcomingDueRadio.value === v.purpose_id}
                          />
                          <span className="checkmark-rb"></span>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    }
                    )}
                  </Col>
                </Row>

                <Row>
                  {(upcomingDueRadio.type === "service") &&
                    <Col sm="4">
                      <FormGroup>
                        <Label for="upcomingDueServiceDropDown"
                          className="field-label margin-top-5"
                          style={{ marginTop: '10px' }}
                        >Services</Label>
                        <select
                          className="form-control select drop-down-control"
                          name="upcomingDueServiceDropDown"
                          value={upcomingDueServiceDropDown.value}
                          onChange={this.handleInputChange}
                        >
                          <option value="all">All</option>
                          {services.map(s => {
                            return s.service_active_by_dealer &&
                              <option value={s.service_id}>{s.name}</option>
                          }
                          )}
                        </select>
                      </FormGroup>
                    </Col>
                  }
                  <Col md="12" sm="12" xl="8">
                    <Row className={upcomingDueRadio.type === 'service' ? "margin-top-29" : "margin-top-15"}>
                      <Col className="pl-0">
                        <div className="display-inline"
                          style={{ marginTop: upcomingDueRadio.value === 'service' ? 'auto' : '-5px' }}
                        >
                          <FormGroup>
                            <Label for="password" className="field-label display-inline">From</Label>&nbsp;
                            <div className="display-inline">
                              <Label
                                onClick={e => this.upcomingDueFrom.state.open && e.preventDefault()}
                              >
                                <InputGroup>
                                  <DatePicker className={"form-control calendar-input"}
                                    selected={upcomingDueFrom.value}
                                    onChange={this.handleDateChange.bind(this, 'upcomingDueFrom')}
                                    showMonthDropdown
                                    ref={r => this.upcomingDueFrom = r}
                                    // shouldCloseOnSelect={true}
                                    dateFormat="dd/MM/yyyy"
                                    showYearDropdown
                                    shouldCloseOnSelect
                                    placeholderText="Select"
                                    minDate={this.state.upcomingDueOrOverDueSwitch === 'upcoming_due' ? new Date() : null}
                                    maxDate={this.state.upcomingDueOrOverDueSwitch === 'over_due' ? new Date() : null}
                                    dropdownMode="select"
                                  />
                                  <InputGroupAddon addonType="append" >
                                    <div className="input-group-text">
                                      <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                                    </div>
                                  </InputGroupAddon>
                                </InputGroup>
                              </Label>
                            </div>
                          </FormGroup>
                        </div>&nbsp;
                        <div className="display-inline"
                          style={{ marginTop: upcomingDueRadio.value === 'service' ? 'auto' : '-5px' }}
                        >
                          <FormGroup>
                            <Label for="password" className="field-label display-inline">To</Label>&nbsp;
                            <div className="display-inline">
                              <Label
                                onClick={e => this.upcomingDueTo.state.open && e.preventDefault()}
                              >
                                <InputGroup>
                                  <DatePicker className={"form-control calendar-input"}
                                    selected={upcomingDueTo.value}
                                    onChange={this.handleDateChange.bind(this, 'upcomingDueTo')}
                                    showMonthDropdown
                                    ref={r => this.upcomingDueTo = r}
                                    // shouldCloseOnSelect={true}
                                    dateFormat="dd/MM/yyyy"
                                    showYearDropdown
                                    shouldCloseOnSelect
                                    placeholderText="Select"
                                    minDate={this.state.upcomingDueOrOverDueSwitch === 'upcoming_due' ? upcomingDueFrom.value || new Date() : this.state.upcomingDueOrOverDueSwitch === 'over_due' ? upcomingDueFrom.value : null}
                                    // maxDate={new Date()}
                                    // maxDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                                    maxDate={this.state.upcomingDueOrOverDueSwitch === 'over_due' ? new Date() : null}
                                    dropdownMode="select"
                                  />
                                  <InputGroupAddon addonType="append" >
                                    <div className="input-group-text">
                                      <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                                    </div>
                                  </InputGroupAddon>
                                </InputGroup>
                              </Label>
                            </div>
                          </FormGroup>
                        </div>
                        {upcomingDueFrom.valid === false &&
                          <div className="error padding-left-15">* Please Select From Date</div>}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          {/* <hr className="border-yellow"></hr> */}
        </div >}

        {/* Part 5 */}
        {mainFilterRadio.value === "source" && <div className="filter-source" >
          <CardBody className="padding-top-bottom-0">
            <Row>
              <Col
                className="cursor-pointer"
              // onClick={() => { this.handleRadioChange('mainFilterRadio', { target: { value: 'source' } }) }}
              >
                <Row>
                  <Col sm="8" style={{ paddingRight: '0px' }}>
                    <div className="filter-section-title">Based On Source of Customer</div>
                  </Col>
                  {!isAddMoreFilterOpen && <Col>
                    <div className="text-align-right">
                      <span className="link-blue" onClick={this.hideShowAddMoreFilter}>Show More Filters</span>
                    </div>
                  </Col>}
                </Row>
                {/* <div className="filter-section-title">Based On Source of Customer</div> */}
                <div className="filter-section-sub-title">Warranty,Reference,Visit</div>
              </Col>
            </Row>
            <Row className="margin-top-5">
              {/* <Col sm="1"></Col> */}
              <Col>

                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="visitPurposeVisitPurposeDropDown" className="field-label">Source</Label>
                      <select
                        className="form-control select drop-down-control"
                        name="sourceDropDown"
                        value={sourceDropDown.value}
                        onChange={this.handleInputChange}
                      >
                        <option value={'all'}>All</option>
                        {sourceMembers.map(item => (
                          <option value={item.toLowerCase()}>{item}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>

              </Col>
            </Row>
          </CardBody>
          {/* <hr className="border-yellow"></hr> */}
        </div >}

        {/* Part 6 */}
        {mainFilterRadio.value === "customer_rating" && <div className="filter-source" >
          <CardBody className="padding-top-bottom-0">
            <Row>
              <Col
                className="cursor-pointer"
              // onClick={() => { this.handleRadioChange('mainFilterRadio', { target: { value: 'source' } }) }}
              >
                <Row>
                  <Col sm="8" style={{ paddingRight: '0px' }}>
                    <div className="filter-section-title">Based On Customer's Rating</div>
                  </Col>
                  {!isAddMoreFilterOpen && <Col>
                    <div className="text-align-right">
                      <span className="link-blue" onClick={this.hideShowAddMoreFilter}>Show More Filters</span>
                    </div>
                  </Col>}
                </Row>
                {/* <div className="filter-section-title">Based On Source of Customer</div> */}
                <div className="filter-section-sub-title">Customer's Rating</div>
              </Col>
            </Row>
            <Row className="margin-top-5">
              {/* <Col sm="1"></Col> */}
              <Col>

                <Row>
                  <Col sm="6">
                    <FormGroup>
                      {/* <Label for="visitPurposeVisitPurposeDropDown" className="field-label">Rating</Label> */}
                      <div className="margin-top-15">
                        {[1, 2, 3, 4, 5].map(a =>
                          <span
                            className={ratings.value === a ? "ratings-selected" : 'ratings'}
                            onClick={this.handleRatingChange.bind(this, a)}>{a}</span>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

              </Col>
            </Row>
          </CardBody>
          {/* <hr className="border-yellow"></hr> */}
        </div >}

        {/* Part 6 */}
        {(mainFilterRadio.value === "last_visit_purpose" ||
          mainFilterRadio.value === "visit") && <div className="filter-visit-purpose" >
            <CardBody className="padding-top-bottom-0">
              <Row>
                <Col
                  className="cursor-pointer"
                // onClick={() => { this.handleRadioChange('mainFilterRadio', { target: { value: 'visit' } }) }}
                >
                  <Row>
                    <Col sm="8" style={{ paddingRight: '0px' }}>
                      <div className="filter-section-title">Based On Visit Purpose</div>
                    </Col>
                    {(!isAddMoreFilterOpen && customerBasedOnRadio.value !== 'last_visit') && <Col>
                      <div className="text-align-right">
                        <span className="link-blue" onClick={this.hideShowAddMoreFilter}>Show More Filters</span>
                      </div>
                    </Col>}
                  </Row>
                  {/* <div className="filter-section-title">Based On Visit Purpose</div> */}
                  <div className="filter-section-sub-title">Inquiry,Service,Tyre Purchase</div>
                </Col>
              </Row>
              <Row className="margin-top-5">
                {/* <Col sm="1"></Col> */}
                <Col>

                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label for="visitPurposeVisitPurposeDropDown" className="field-label">Visit Purpose</Label>
                        <select
                          className="form-control select drop-down-control"
                          name="visitPurposeVisitPurposeDropDown"
                          value={visitPurposeVisitPurposeDropDown.value}
                          onChange={this.handleInputChange}
                        >
                          <option value="">All</option>
                          {visitPurposes.map(v =>
                            <option value={v.purpose_id}>{v.name}</option>
                          )}
                        </select>
                      </FormGroup>
                    </Col>
                    {/* </Row> */}
                    {visitPurposeVisitPurposeDropDown.type === 'service' &&
                      // <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label for="visitPurposeServiceDropDown" className="field-label">Services</Label>
                          <select className="form-control select drop-down-control"
                            name="visitPurposeServiceDropDown"
                            value={visitPurposeServiceDropDown.value}
                            onChange={this.handleInputChange}
                          >
                            <option value="">All</option>
                            {services.map(s => {
                              return s.service_active_by_dealer &&
                                <option value={s.service_id}>{s.name}</option>
                            }
                            )}
                          </select>
                        </FormGroup>
                      </Col>
                      // </Row>
                    }
                  </Row>
                </Col>

              </Row>
            </CardBody>
            {/* <hr className="border-yellow"></hr> */}
          </div >}

        {/* Part 7 */}
        {from !== 'manage-group' && mainFilterRadio.value === "group" && <div className="filter-group">
          <CardBody className="padding-top-bottom-0">
            <Row>
              <Col
                className="cursor-pointer"
              // onClick={() => { this.handleRadioChange('mainFilterRadio', { target: { value: 'group' } }) }}
              >
                <Row>
                  <Col sm="8" style={{ paddingRight: '0px' }}>
                    <div className="filter-section-title">Based On Group</div>
                  </Col>
                  {!isAddMoreFilterOpen && <Col>
                    <div className="text-align-right">
                      <span className="link-blue" onClick={this.hideShowAddMoreFilter}>Show More Filters</span>
                    </div>
                  </Col>}
                </Row>
                {/* <div className="filter-section-title">Based On Group</div> */}
                <div className="filter-section-sub-title">Group</div>
              </Col>
            </Row>
            <Row>
              {/* <Col sm="1"></Col> */}
              <Col>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="common" className="field-label">Select Group</Label>
                      <select
                        className="form-control select drop-down-control"
                        name="groupDropDown"
                        value={groupDropDown.value}
                        onChange={this.handleInputChange}
                      >
                        <option value='all'>Select</option>
                        {groupData.map(g =>
                          <option value={g.group_id}>{g.group_name}</option>
                        )}
                      </select>
                    </FormGroup>
                    {groupDropDown.showErrorMsg && <div className="error">* Please select group</div>}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          {/* <hr className="border-yellow"></hr> */}
        </div >}


        {/* Part 8 */}
        {mainFilterRadio.value === "occasion" && <div className="filter-occasion" >
          <CardBody className="padding-top-bottom-0">
            <Row>
              <Col
                className="cursor-pointer"
              // onClick={() => { this.handleRadioChange('mainFilterRadio', { target: { value: 'occasion' } }) }}
              >
                <Row>
                  <Col sm="8" style={{ paddingRight: '0px' }}>
                    <div className="filter-section-title">Based On Occasion</div>
                  </Col>
                  {!isAddMoreFilterOpen && <Col>
                    <div className="text-align-right">
                      <span className="link-blue" onClick={this.hideShowAddMoreFilter}>Show More Filters</span>
                    </div>
                  </Col>}
                </Row>
                {/* <div className="filter-section-title">Based On Occasion</div> */}
                <div className="filter-section-sub-title">Birthdate and Anniversary</div>
              </Col>
            </Row>
            {mainFilterRadio.value !== "occasion" && <Row className="margin-top-15">
              <Col></Col>
            </Row>}
            <Row>
              {/* <Col sm="1"></Col> */}
              <Col>
                <Row>
                  <Col>
                    <div className="field-label margin-bottom-5">Occasion</div>
                    <label className="container-rb">
                      Birthday
                      <input
                        type="radio"
                        name="occasionDueRadio"
                        value="birthday"
                        onChange={this.handleRadioChange.bind(this, 'occasionDueRadio')}
                        checked={occasionDueRadio.value === "birthday"}
                      />
                      <span className="checkmark-rb"></span>
                    </label>&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="container-rb">
                      Anniversary
                      <input
                        type="radio"
                        name="occasionDueRadio"
                        value="anniversary"
                        onChange={this.handleRadioChange.bind(this, 'occasionDueRadio')}
                        checked={occasionDueRadio.value === "anniversary"}
                      />
                      <span className="checkmark-rb"></span>
                    </label>
                  </Col>

                </Row>
                <Row className="margin-top-10">
                  <Col>
                    <div className="display-inline">
                      <FormGroup>
                        <Label for="password" className="field-label margin-top-10 display-inline">From</Label>&nbsp;
                        <div className="width-75  display-inline">
                          <Label
                            onClick={e => this.occasionDueFrom.state.open && e.preventDefault()}
                          >
                            <InputGroup>
                              <DatePicker className={"form-control calendar-input"}
                                selected={occasionDueFrom.value}
                                onChange={this.handleDateChange.bind(this, 'occasionDueFrom')}
                                showMonthDropdown
                                ref={r => this.occasionDueFrom = r}
                                // shouldCloseOnSelect={true}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                shouldCloseOnSelect
                                placeholderText="Select"
                                maxDate={new Date()}
                                // maxDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                                dropdownMode="select"
                              />
                              <InputGroupAddon addonType="append" >
                                <div className="input-group-text">
                                  <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                                  {/*  <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' /> */}
                                </div>
                              </InputGroupAddon>
                            </InputGroup>
                          </Label>
                        </div>
                      </FormGroup>
                    </div>&nbsp;&nbsp;
                    <div className="display-inline">
                      <FormGroup>
                        <Label for="password" className="field-label margin-top-10 display-inline">To</Label>&nbsp;
                        <div className="display-inline">
                          <Label
                            onClick={e => this.occasionDueTo.state.open && e.preventDefault()}
                          >
                            <InputGroup>
                              <DatePicker className={"form-control calendar-input"}
                                selected={occasionDueTo.value}
                                onChange={this.handleDateChange.bind(this, 'occasionDueTo')}
                                showMonthDropdown
                                ref={r => this.occasionDueTo = r}
                                // shouldCloseOnSelect={true}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                placeholderText="Select"
                                shouldCloseOnSelect
                                minDate={occasionDueFrom.value}
                                maxDate={new Date()}
                                // maxDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                                dropdownMode="select"
                              />
                              <InputGroupAddon addonType="append" >
                                <div className="input-group-text">
                                  <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                                </div>
                              </InputGroupAddon>
                            </InputGroup>
                          </Label>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="error padding-left-15 error-height" id="occasion_error">
                      {occasionDueTo.valid === false && occasionDueFrom.valid === false && <span>
                        * {EMPTY_DATE}</span>}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          {/* {from !== 'campaign' && <hr style={{ margin: '0px' }}></hr>}
          {from === 'campaign' && <hr className="border-yellow" style={{ marginTop: '0px' }}></hr>} */}
        </div>}

        {/* Part 9 */}
        {from === 'campaign' && mainFilterRadio.value === "manual" && <div className="filter-campaign">
          <CardBody className="padding-top-bottom-0">
            <Row>
              {/* <Col sm="1" className="radio-center">
                <label className="container-rb">

                  <input
                    type="radio"
                    name="mainFilterRadio"
                    value="manual"
                    onChange={this.handleRadioChange.bind(this, 'mainFilterRadio')}
                    checked={mainFilterRadio.value === "manual"}
                  />
                  <span className="checkmark-rb"></span>
                </label>
              </Col> */}
              <Col
                className="cursor-pointer"
              // onClick={() => { this.handleRadioChange('mainFilterRadio', { target: { value: 'manual' } }) }}
              >
                <Row>
                  <Col sm="8" style={{ paddingRight: '0px' }}>
                    <div className="filter-section-title">Add Data Manually</div>
                  </Col>
                  {!isAddMoreFilterOpen && <Col>
                    <div className="text-align-right">
                      <span className="link-blue" onClick={this.hideShowAddMoreFilter}>Show More Filters</span>
                    </div>
                  </Col>}
                </Row>
                {/* <div className="filter-section-title">Add Data Manually</div> */}
                <div className="filter-section-sub-title">Add Multiple Mobile Numbers</div>
              </Col>
            </Row>
            {mainFilterRadio.value !== "manual" && <Row className="margin-top-15">
              <Col></Col>
            </Row>}
            <Row>
              {/* <Col sm="1"></Col> */}
              <Col>
                <Row>
                  <Col>
                    <Label for="manualNumbers" className="field-label margin-top-10">
                      <span>Add Mobile Numbers</span>
                      <span style={{ position: 'absolute' }}>
                        <MappleToolTip {...mappleConfig}>
                          {/* <div className="f-flex-row"> */}
                          <img
                            style={{ marginTop: "-4px" }}
                            src="images/sidebar-icons/about-selected.svg"
                            alt=''
                            id="editorimage"
                          />
                          {/* </div> */}
                          <Row className="otherlangcontent-wrapps">
                            <Col>
                              <div
                                className="otherlangcontent"
                                style={{
                                  width: "80%",
                                  color: "black",
                                  textAlign: "left",
                                  fontSize: "12px",
                                  marginLeft: "-800px",
                                  marginTop: "-200px",
                                  display: "block",
                                  padding: "10px",
                                  height: "auto"
                                }}
                              >
                                <ul className="Enrollment-ul text-none">
                                  <li className="enroll-li campaign-popup-notes">
                                    You can send SMS to 2000 contacts at once
                                    manually.
                                  </li>
                                  <li className="enroll-li campaign-popup-notes">
                                    Select the phone numbers you want to run a
                                    campaign with and paste them in the box below.
                                  </li>
                                  <li className="enroll-li campaign-popup-notes">
                                    Only 10 digit phone number are allowed.
                                  </li>
                                  <li className="enroll-li campaign-popup-notes">
                                    It is not necessary to have the phone numbers
                                    added in your customer list. You can send SMS to
                                    any phone number using this option.
                                  </li>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </MappleToolTip>
                      </span>
                    </Label>
                    <FormGroup>
                      <textarea
                        rows="4"
                        maxLength="2000"
                        className="manual-customer"
                        cols="35"
                        id="manualNumbers"
                        name="manualNumbers"
                        value={manualNumbers.value}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    {manualNumbers.showErrorMsg && <div className="error" id="manual_number_error">
                      There is no customer found with applied filter . Please change filter criteria
                    </div>}
                  </Col>

                </Row>
              </Col>
            </Row>
          </CardBody>
          {/* <hr style={{ margin: '0px' }}></hr> */}
        </div>}



        {/* <CardBody> */}
        <Row style={{ marginLeft: '0', marginRight: '0' }} className="freeze-footer">
          <Col xs="12" md="12" lg="12" className="text-align-right footer-drawer" style={{ marginTop: '0' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter()} style={{ width: '116px' }}>Reset Filter</Button>&nbsp;&nbsp;
            <Button className="mb-1 mr-1 btn-pill action-button font-weight-bold" color="warning" onClick={this.applyFilter} style={{ width: '116px' }}>Apply</Button>
          </Col>
        </Row>
        {/* </CardBody> */}
        {/* </div> */}
      </div>


      {/* </div> */}
    </div>

  }
}

const mapDispatchToProps = dispatch => ({
  setDealerDashboardRedirectDetail: redirectDetails => dispatch(setDealerDashboardRedirectDetail(redirectDetails)),
});


const mapStateToProp = state => (
  {
    redirectDetails: state.User.redirectDetails
  });

export default connect(mapStateToProp, mapDispatchToProps)(CustomerManagementFilterNew);
