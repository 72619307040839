import React, { Component } from 'react';
import { Row, Col, Card, CardBody, } from 'reactstrap';

export default class CustomerReferenceSuccess extends Component {
  render() {
    let { dealer_detail } = this.props;
    return (
      <Card>
        <CardBody className="padding-32px">
          <Row>
            <Col xl="12" md="12" xs="12" className="CustomerReferenceSucesssLblWrapper">
              <span className="CustomerReferenceSucesssLbl">
                {
                  dealer_detail.customerHistory &&
                    dealer_detail.customerHistory.is_offer_enable ?
                    'We thank you for providing reference. We have sent offer SMS on your mobile' :
                    'We thank you for providing reference.'
                }
              </span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}
