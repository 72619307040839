import React, { Component, Fragment } from 'react';
import { Card, CardBody, Col, Row, Table, Input } from 'reactstrap';
import Ionicon from 'react-ionicons';
import lodash from 'lodash'; // library added for debounce functionality

import Pagination from '../../components/Pagination/Pagination';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

import TradeSchemeService from '../../services/TradeSchemeService';
import StorageService from '../../services/Storage';

import './TradeSchemeDealerReport.css';
import { Roles } from '../../constants/constant.role';

const pageSize = 10;
let sapId = null;
let delearName = null;
let reviewAchievement = null;

class TradeSchemeDealerReport extends Component {

  state = {
    page: 1,
    search: '',
    min: null,
    totalRecords: 0,
    totalPages: 0,
    tradeSchemeDealerList: [],
    uuid: null,
    manageUserLevel: [],
    currentUser: null,
    randomKey: null,
    dealerRole: null,
    sapId: null,
    dealerName: null,
    isLoading: false,
    monthLabel: {}
  }

  constructor(props) {
    super(props);
    this.searchTradeSchemeDealer = lodash.debounce(this.searchTradeSchemeDealer, 500);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    sapId = query.get('id');
    delearName = query.get('name');
    reviewAchievement = query.get('review_achievement');

    const id = query.get('k');
    const uuid = query.get('uuid');
    const min = query.get('min');
    let manageUserLevel = [];
    let currentUser = null;
    if (id) {
      manageUserLevel = StorageService.getNavigationLevelDetail(id);
      if (manageUserLevel.length > 0) {
        currentUser = manageUserLevel[manageUserLevel.length - 1];
        if (currentUser && currentUser.uuid) {
          sapId = currentUser.uuid;
          delearName = currentUser.name;
        }
      }
      this.setState({ currentUser, manageUserLevel, randomKey: id });
    }

    this.setState({ min: min ? min : null, uuid: uuid });
    this.getTradeSchemeDealerData(1, null, min, uuid);

    const userDetail = StorageService.getUserDetail();
    const { type } = userDetail;
    this.setState({ dealerRole: type });
    // this.generateRolesToDrillDown(type);
  }

  getTradeSchemeDealerData = (page, search = null, min, uuid) => {
    this.setState({ isLoading: true });
    TradeSchemeService.getSchemeDealerList(page, pageSize, search, min, uuid, reviewAchievement)
      .then(data => {
        const tradeSchemeDealerRes = data.data && data.data.data ? data.data.data : null;
        this.setState({ isLoading: false });
        if (tradeSchemeDealerRes) {
          const tradeSchemeDealerList = tradeSchemeDealerRes && tradeSchemeDealerRes.data ? tradeSchemeDealerRes.data : [];
          const totalRecords = tradeSchemeDealerRes.count;
          const monthLabel = tradeSchemeDealerRes.label;
          this.setState({ tradeSchemeDealerList, totalRecords, monthLabel })
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });

      })
  }

  getDataFromNavigation = (index) => {
    let { randomKey,
      // manageUserLevel 
    } = this.state;
    // manageUserLevel = manageUserLevel.slice(0, index)
    // const userData = manageUserLevel[index - 1];
    // if (userData && userData.uuid) {
    let url = `/trade-scheme-report?id=${randomKey}&i=${index}`;
    if (reviewAchievement) {
      url = `${url}&review_achievement=${reviewAchievement}`
    }
    this.props.history.push(url);

    // let uuid = userData.uuid;
    // this.setState({ page: 1, currentUser: userData, manageUserLevel });
    // this.getAllDealerReport(1, null, null, uuid);
    // }
  }

  getPageTitle = () => {
    const { dealerRole } = this.state;
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      return 'All India'
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      return 'Zone Manager'
    } else if (dealerRole === Roles.ZTM_ROLE) {
      return "Zonal Trade Marketing";
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      return 'Region Manager'
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      return 'Sales Officer'
    } else {
      return 'Trade Scheme Report'
    }
  }


  handlePageChange = (page) => {
    const { uuid, min, search } = this.state
    // const uuid = currentUser && currentUser.uuid ? currentUser.uuid : null;
    this.setState({ page: page });
    this.getTradeSchemeDealerData(page, search, min, uuid);
  }


  handleSearchChange = (e) => {
    let searchVal = e.target.value;
    this.setState({ search: searchVal, page: 1 });
    this.searchTradeSchemeDealer(searchVal, 1);
  }

  searchTradeSchemeDealer = (search, page) => {
    const { min, uuid } = this.state;
    this.getTradeSchemeDealerData(page, search, min, uuid)
  }

  render() {
    const { tradeSchemeDealerList, totalRecords, page, search, isLoading, dealerRole, monthLabel } = this.state;
    let { min } = this.state;
    const pageTitle = this.getPageTitle();
    let range = null;
    if (min) {
      min = parseInt(min);
      if (min === 100) {
        range = '(>100%)'
      } else {
        range = `(${min}-${min + 9}%)`
      }
    }
    const mapToRoleLabel = {
      'SM': 'Region',
      'AM': 'Area'
    }
    const prepareRows = tradeSchemeDealerList.map(t => <tr>
      <td>{t.dealer_sap_id}</td>
      <td>{t.dealer_business_name}</td>
      <td>{t.actual_target}</td>
      <td>{t.month_1}</td>
      <td>{t.month_1_achieved_percentage}</td>
      <td>{t.month_2}</td>
      <td>{t.month_2_achieved_percentage}</td>
      <td>{t.month_3}</td>
      <td>{t.month_3_achieved_percentage}</td>
      <td>{t.quarter_target}</td>
      <td>{t.quarter_offtake}</td>
      <td>{t.achieved_percentage}</td>
    </tr>);

    const manageUserLevelLength = this.state.manageUserLevel.length;
    const navigation = this.state.manageUserLevel.length > 0 ? this.state.manageUserLevel.map((m, index) => <Fragment>
      <span>
        <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
      </span>
      {index !== manageUserLevelLength - 1 && <span className="prev-page-link cursor-pointer title-case" onClick={this.getDataFromNavigation.bind(this, index + 1)}>
        {m.name}{' '}{m.role !== 'ZM' ? `(${mapToRoleLabel[m.role]})` : null}
      </span>}
      {index === manageUserLevelLength - 1 && <span className="current-page title-case">
        {m.name}{' '}{m.role !== 'ZM' ? `(${mapToRoleLabel[m.role]})` : null}{range}
      </span>}
    </Fragment>)
      : <Fragment>
        <span>
          <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
        </span>
        <span className="current-page title-case">
          Report of{' '}{delearName}
        </span>
      </Fragment>

    return (
      <div id="trade-scheme-dealer-report">
        {this.state.manageUserLevel.length > 0 && <Row>
          <Col>
            <span className="prev-page-link cursor-pointer" onClick={this.getDataFromNavigation.bind(this, 0, null)}>{pageTitle}</span>
            {dealerRole !== Roles.AREA_MANAGER_ROLE && navigation}
            {dealerRole === Roles.AREA_MANAGER_ROLE ? range : null}
          </Col>
        </Row>}
        {/* <div className="page-title">Trade Scheme Report</div> */}
        <Row className="margin-top-20 margin-bottom-5">
          <Col xs="4">
            {/* <Label> */}
            <Input
              placeholder="Search using dealer name or SAP code"
              className="report-search-input"
              onChange={this.handleSearchChange}
              value={search}
            ></Input>
            <img src="images/search.svg" alt="" className="report-search icon-width" />
            {/* </Label> */}
          </Col>
          {/* {warrantyData.length !== 0 && <Col xs="8" className="text-align-right">
            {(dealerRole === Roles.SUB_DEALER_ROLE || dealerRole === Roles.BRANCH_ROLE) && <img src="images/help.png" alt="" className="download-report-icon" onClick={this.openHelpModal} />}
            <img src="images/download.svg" alt="" className="download-report-icon margin-left-10" />
            <span className="download-report cursor-pointer" onClick={this.downloadReport}>{' '} Download Report</span>
          </Col>}
          {warrantyData.length === 0 && (dealerRole === Roles.SUB_DEALER_ROLE || dealerRole === Roles.BRANCH_ROLE) && <Col xs="8" className="text-align-right">
            <img src="images/help.png" alt="" className="download-report-icon" onClick={this.openHelpModal} />
          </Col>} */}
        </Row>
        <Card className="margin-top-5">
          <CardBody>
            {isLoading && <CustomSpinner></CustomSpinner>}
            <Table>
              <tr>
                <th rowSpan="2">SAP Code</th>
                <th rowSpan="2">Dealer Name</th>
                <th rowSpan="2">Monthly Target</th>
                <th colSpan="6" className="text-align-center">Monthly Offtake</th>
                <th rowSpan="2">Quarterly Target</th>
                <th rowSpan="2">Quarterly Offtake</th>
                <th rowSpan="2">% Achievement</th>
              </tr>
              <tr>
                <th className="text-align-center">{monthLabel.month_1}</th>
                <th className="text-align-center">{monthLabel.month_1_percentage}</th>
                <th className="text-align-center">{monthLabel.month_2}</th>
                <th className="text-align-center">{monthLabel.month_2_percentage}</th>
                <th className="text-align-center">{monthLabel.month_3}</th>
                <th className="text-align-center">{monthLabel.month_3_percentage}</th>
              </tr>
              {prepareRows}
            </Table>
            {totalRecords > 0 && <Pagination
              margin={2}
              page={page}
              // count={total}
              pageSize={pageSize}
              totalRecords={totalRecords}
              onPageChange={this.handlePageChange}
            ></Pagination>}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default TradeSchemeDealerReport;