import React, { Component, Fragment } from "react";
import { Button, Row, Col, Form } from "reactstrap";
import Ionicon from "react-ionicons";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// components
import EditOtherBusinessDetail from "../EditOtherBusinessDetail/EditOtherBusinessDetail";
import EditPrimaryContactDetail from "../EditPrimaryContactDetail/EditPrimaryContactDetail";
import EditSocialDetails from "../EditSocialDetails/EditSocialDetails";
import EditSubDealerDetail from "../EditSubDealerDetail/EditSubDealerDetail";
import EditBranch from "../EditBranch/EditBranch";
import ProfileDetail from "../ProfileDetail/ProfileDetail";
import CustomSpinner from "../CustomSpinner/CustomSpinner";

// service imports
import UserService from "../../services/UserService";
import StorageService from "../../services/Storage";
import Validation from "../../services/Validation";
import ModalService from "../../services/ModalService";
import WarrantyRegistrationService from "../../services/WarrantyRegistrationService";

// actions
import { setUserDetail } from "../../actions/UserAction";

//constant
import { messages } from "../../constants/constant.message";

// css
import "./Registration.css";
import Storage from "../../services/Storage";
// import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

let { ERROR_MESSAGE } = messages.COMMON;

class Registration extends Component {
  constructor(props) {
    super(props);
    this.childBusinessDetail = React.createRef();
  }
  state = {
    userDetail: Storage.getUserDetail(),
    profileImage: null,
    sapId: null,
    businessName: null,
    ownerName: null,
    address: null,
    mobileNumber: null,
    isImageUpdating: false,
    controls: {
      state: {
        value: null,
        valid: true,
        required: false,
        touched: false,
        invalidLength: null,
        showErrorMsg: false,
      },
      city: {
        value: null,
        valid: true,
        required: false,
        touched: false,
        invalidLength: null,
        showErrorMsg: false,
      },
      pin_code: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      address: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        invalidLength: null,
        showErrorMsg: false,
      },
      senderId: {
        value: null,
        valid: true,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
      },
      email: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
      },
      phone: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidPhone: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
      },
      gst: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        invalidLength: null,
        showErrorMsg: false,
      },
      pan: {
        value: null,
        valid: true,
        required: false,
        touched: false,
        invalidLength: null,
        showErrorMsg: false,
      },
      alPerson: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      alDesignation: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      alNumber: {
        value: null,
        valid: true,
        invalidPhone: null,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      wa: {
        value: null,
        valid: false,
        touched: false,
        invalidPhone: null,
        required: false,
        showErrorMsg: false,
        autoFocus: false,
      },
      birthDate: {
        value: null,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false,
      },
      anniversaryDate: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      facebook: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      instagram: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      website: {
        value: null,
        valid: true,
        touched: false,
        required: true,
        showErrorMsg: false,
      },
      google_my_business_rating_link: {
        value: null,
        valid: true,
        touched: false,
        required: true,
        showErrorMsg: false,
      },
      google_my_business_link: {
        value: null,
        valid: true,
        touched: false,
        required: true,
        showErrorMsg: false,
      },
      agreeTermAndCondition: true,
      sameAsUser: false,
    },
    subDealerControls: {
      businessName: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
      },
      ownerName: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidPhone: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
      },
      gst: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        invalidLength: null,
        showErrorMsg: false,
      },
      pan: {
        value: null,
        valid: true,
        required: false,
        touched: false,
        invalidLength: null,
        showErrorMsg: false,
      },
      wa: {
        value: null,
        valid: false,
        touched: false,
        invalidPhone: null,
        required: false,
        showErrorMsg: false,
        autoFocus: false,
      },
      street: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      city: {
        value: [],
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      state: {
        value: [],
        valid: true,
        touched: false,
        required: true,
        showErrorMsg: false,
      },
      pincode: {
        value: null,
        valid: true,
        required: false,
        touched: false,
        invalidLength: null,
        showErrorMsg: false,
      },
    },
    branchControls: {
      alternateContactPerson: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
      },
      wa: {
        value: null,
        valid: false,
        touched: false,
        invalidPhone: null,
        required: false,
        showErrorMsg: false,
        autoFocus: false,
      },
      street: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      city: {
        value: [],
        valid: true,
        touched: false,
        required: false,
        showErrorMsg: false,
      },
      state: {
        value: [],
        valid: true,
        touched: false,
        required: true,
        showErrorMsg: false,
      },
      pincode: {
        value: null,
        valid: true,
        required: false,
        touched: false,
        invalidLength: null,
        showErrorMsg: false,
      },
    },
    activeFragment: null,
    isFormValid: false,
    redirectToDashBoard: false,
    redirectToWarrantyScoreCard: false,
    isLoading: false,
    userRole: null,
    states: [],
    cities: [],
  };

  componentWillReceiveProps(props) {
  }

  componentDidMount() {
    const userDetail = StorageService.getUserDetail();
    const { type: userRole } = userDetail;
    if (
      userRole === Roles.SUB_DEALER_ROLE ||
      userRole === Roles.BRANCH_ROLE ||
      userDetail.customer_class === "na" ||
      userDetail.customer_class === "hy"
    ) {
      this.getState();
    }

    this.setState({ userRole });
    UserService.getUserInfo()
      .then((data) => {
        const userData = data.data.data;
        this.props.setUserDetail(userData);
        this.setPageValues(userData);
      })
      .catch((e) => {
        console.error("e", e);
      });
  }

  setPageValues = async (uDetail) => {
    const {
      email_1,
      telephone_no_1,
      gst_number,
      pan_number,
      image_url,
      alternate_contact_person,
      alternate_contact_number,
      designation,
      whats_app_number,
      birth_date,
      anniversary_date,
      facebook_url,
      instagram_url,
      sap_id,
      business_name,
      owner_name,
      address_line_1,
      address_line_2,
      address_line_3,
      address_line_4,
      website: web_from_api,
      nick_name,
      state,
      city,
      pin_code = null,
    } = uDetail;

    let {
      controls,
      subDealerControls,
      branchControls,
      sapId,
      address,
      businessName,
      profileImage,
      ownerName,
      mobileNumber,
      userRole,
      states,
    } = this.state;

    mobileNumber = telephone_no_1;
    profileImage = image_url;
    sapId = sap_id;
    businessName = business_name;
    ownerName = userRole === Roles.DEALER_ROLE ? owner_name : nick_name;

    address = ``;
    if (address_line_1) {
      address = `${address_line_1}`;
    }
    if (address_line_2) {
      address = `${address}, ${address_line_2}`;
    }
    if (address_line_3) {
      address = `${address}, ${address_line_3}`;
    }
    if (address_line_4) {
      address = `${address}, ${address_line_4}`;
    }

    if (
      userRole === Roles.DEALER_ROLE &&
      this.state.userDetail.customer_class !== "na" &&
      this.state.userDetail.customer_class !== "hy"
    ) {
      const {
        email,
        phone,
        gst,
        pan,
        alPerson,
        alNumber,
        alDesignation,
        wa,
        birthDate,
        anniversaryDate,
        facebook,
        instagram,
        website,
        senderId,
      } = controls;
      senderId.value = uDetail.sender_id ? uDetail.sender_id : null;
      email.value = email_1;
      phone.value = telephone_no_1;
      // phone.value = null;
      gst.value = gst_number;
      pan.value = pan_number;

      alPerson.value = alternate_contact_person;
      alNumber.value = alternate_contact_number;
      alDesignation.value = designation;
      wa.value = whats_app_number;
      website.value = web_from_api;
      controls.google_my_business_link.value = uDetail.google_my_business_link;
      controls.google_my_business_rating_link.value =
        uDetail.google_my_business_rating_link;

      controls.state.value = state;
      controls.city.value = city;
      controls.pin_code.value = pin_code;
      controls.address.value = address_line_1;

      if (controls.state && controls.state.value) {
        this.childBusinessDetail.current.getCity(controls.state.value);
        this.handleValidation();
      }

      // birthDate.value = null;
      // anniversaryDate.value = null;

      birthDate.value = birth_date ? new Date(birth_date) : null;
      anniversaryDate.value = anniversary_date
        ? new Date(anniversary_date)
        : null;
      facebook.value = facebook_url;
      instagram.value = instagram_url;

      // address = `${address_line_1}, ${address_line_2}, ${address_line_3}, ${address_line_4}`;

      if (email.value === null || email.value === "") {
        email.autoFocus = true;
      } else if (phone.value === null || phone.value === "") {
        phone.autoFocus = true;
      } else if (wa.value === null || wa.value === "") {
        wa.autoFocus = true;
      } else if (birthDate.value === null || birthDate.value === "") {
        birthDate.autoFocus = true;
      }
      this.setState({ controls });
      this.handleValidation(true);
    } else if (userRole === Roles.SUB_DEALER_ROLE) {
      const {
        businessName: subDealerBusinessName,
        ownerName: subDealerOwnerName,
        wa: subDealerWa,
        street: subDealerStreet,
        state: subDealerState = null,
        city: subDealerCity = null,
        pan: subDealerPan,
        gst: subDealerGst,
        pincode: subDealerPincode,
      } = subDealerControls;

      const selectedStateIndex = state
        ? states.findIndex((s) => s.name.toLowerCase() === state.toLowerCase())
        : -1;
      const currentState = states[selectedStateIndex];
      let cities = [];
      if (currentState) {
        cities = await this.getCity(currentState.state_id);
      }

      const selectedCityIndex = city
        ? cities.findIndex((c) => c.name.toLowerCase() === city.toLowerCase())
        : -1;

      subDealerBusinessName.value = business_name;
      subDealerOwnerName.value = owner_name;
      subDealerWa.value = whats_app_number;
      subDealerStreet.value = address_line_1;
      subDealerState.value =
        selectedStateIndex !== -1 ? [states[selectedStateIndex]] : [];
      subDealerCity.value =
        selectedCityIndex !== -1 ? [cities[selectedCityIndex]] : [];
      subDealerPan.value = pan_number;
      subDealerGst.value = gst_number;
      subDealerPincode.value = pin_code;

      this.setState({ subDealerControls });

      this.handleSubDealerValidation(true);
    } else if (
      userRole === Roles.BRANCH_ROLE ||
      this.state.userDetail.customer_class === "na" ||
      this.state.userDetail.customer_class === "hy"
    ) {
      const {
        alternateContactPerson,
        wa: branchWaNumber,
        street: branchStreet,
        state: branchState = null,
        city: branchCity = null,
        pincode: branchPinCode,
      } = branchControls;
      const selectedStateIndex = state
        ? states.findIndex((s) => s.name.toLowerCase() === state.toLowerCase())
        : -1;
      const currentState = states[selectedStateIndex];

      let cities = [];
      if (currentState) {
        cities = await this.getCity(currentState.state_id);
      }

      const selectedCityIndex = city
        ? cities.findIndex((c) => c.name.toLowerCase() === city.toLowerCase())
        : -1;

      alternateContactPerson.value = alternate_contact_person;
      branchWaNumber.value = whats_app_number;
      branchStreet.value = address_line_1;
      branchState.value =
        selectedStateIndex !== -1 ? [states[selectedStateIndex]] : [];
      branchCity.value =
        selectedCityIndex !== -1 ? [cities[selectedCityIndex]] : [];
      branchPinCode.value = pin_code;

      this.setState({ branchControls });
      this.handleBranchValidation(true);
    }

    this.setState({
      profileImage,
      ownerName,
      mobileNumber,
      sapId,
      businessName,
      address,
      is_disable_sender_id: controls.senderId.value ? true : false,
    });
  };

  handleUploadImage = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const obj = {
      file,
    };
    this.setState({ isImageUpdating: true });
    UserService.uploadImage(obj)
      .then((data) => {
        this.setState({
          profileImage: URL.createObjectURL(file),
          isImageUpdating: false,
        });
      })
      .catch((e) => {
        this.setState({
          profileImage: null,
          isImageUpdating: false,
        });
      });
  };

  goToPreviousPage = () => {
    this.props.handleGoToNextPage("ob2");
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    let controlValue = e.target.value;
    const { controls } = this.state;
    if (controlValue && controlName === "senderId") {
      controlValue = controlValue.toUpperCase();
    }

    if (controlName === "state") {
      controls.city.value = "";
      controls.city.touched = true;
    }


    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });

    if (controlName === "state") {
      if (this.childBusinessDetail.current) {
        this.childBusinessDetail.current.getCity(controls.state.value);
        this.handleValidation();
      }
    }

    this.handleValidation();
  };

  handleSubDealerInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { subDealerControls } = this.state;
    subDealerControls[controlName].value = controlValue;
    subDealerControls[controlName].touched = true;
    this.setState({ subDealerControls });
    this.handleSubDealerValidation();
  };

  handleBranchInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { branchControls } = this.state;
    branchControls[controlName].value = controlValue;
    branchControls[controlName].touched = true;
    this.setState({ branchControls });
    this.handleBranchValidation();
  };

  handleBirthDateChange = (e) => {
    const { controls } = this.state;
    const { birthDate } = controls;
    birthDate.value = e;
    birthDate.touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  handleAnniversaryDateChange = (e) => {
    const { controls } = this.state;
    const { anniversaryDate } = controls;
    anniversaryDate.value = e;
    anniversaryDate.touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  setCheckBoxValue = () => {
    const { controls } = this.state;
    // const { agreeTermAndCondition } = controls;
    controls.agreeTermAndCondition = !controls.agreeTermAndCondition;
    // agreeTermAndCondition.value = !agreeTermAndCondition.value;
    this.setState({ controls });
    this.handleValidation();
  };

  setSameAsUserCheckBoxValue = () => {
    const { controls, ownerName } = this.state;
    const { phone, alPerson, alNumber, alDesignation } = controls;
    // const { agreeTermAndCondition } = controls;
    controls.sameAsUser = !controls.sameAsUser;
    if (controls.sameAsUser === true) {
      alPerson.value = ownerName;
      alPerson.touched = true;
      alNumber.value = phone.value;
      alNumber.touched = true;
      alDesignation.value = "owner";
      alDesignation.touched = true;
    }
    // else {
    //   alPerson.value = ;
    //   alNumber.value = phone.value;
    // }
    // agreeTermAndCondition.value = !agreeTermAndCondition.value;
    this.setState({ controls });
    this.handleValidation();
  };

  activeFragment = (fragment) => {
    this.setState({ activeFragment: fragment });
  };

  updateProfile = (e) => {
    e.preventDefault();
    const isFormValid = this.handleValidation(false, true);
    if (isFormValid === false) {
      return;
    }
    const { controls } = this.state;
    const {
      email,
      phone,
      gst,
      pan,
      alPerson,
      alDesignation,
      alNumber,
      wa,
      birthDate,
      anniversaryDate,
      facebook,
      instagram,
      website,
      agreeTermAndCondition,
      senderId,
      city,
      state
    } = controls;

    const obj = {
      email: email.value,
      phoneNumber: phone.value, // only numbers
      gstNumber: gst.value ? gst.value.toUpperCase() : null, // 15 digit
      panNumber: pan.value ? pan.value.toUpperCase() : null, // 10 digit

      alternateContactPerson: alPerson.value,
      alternateContactNumber: alNumber.value,
      designation: alDesignation.value,
      whatsappNumber: wa.value,

      birthDate: birthDate.value,
      anniversaryDate: anniversaryDate.value,
      facebookUrl: facebook.value,
      instagramUrl: instagram.value,

      isTermsAccepted: agreeTermAndCondition,

      isLoginFirstTime: false,
      website: website.value,
      senderId: senderId.value ? senderId.value : null,
      city: city.value ? city.value : null,
      state: state.value ? state.value : null,
    };

    this.setState({ isLoading: true });
    UserService.updateUserInfo(obj)
      .then((data) => {
        const userDetail = StorageService.getUserDetail();
        userDetail.is_login_first_time = false;
        const userData = data.data.data;
        this.props.setUserDetail(userData);
        StorageService.setUserDetail(userDetail);
        this.setState({ isLoading: false, redirectToDashBoard: true });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
        ModalService.openAlert("Profile", message, "error");
      });
  };

  updateProfileSubDealer = (e) => {
    e.preventDefault();
    const isFormValid = this.handleSubDealerValidation(false, true);
    if (isFormValid === false) {
      return;
    }
    const { subDealerControls } = this.state;
    const {
      businessName,
      ownerName,
      wa,
      street,
      gst,
      pan,
      state,
      city,
      pincode,
    } = subDealerControls;

    const obj = {
      businessName: businessName.value,
      ownerName: ownerName.value, // only numbers
      whatsappNumberUser: wa.value,

      address: street.value,
      state: state.value[0].name.toLowerCase(),
      city: city.value[0].name.toLowerCase(),

      gstNumber: gst.value ? gst.value.toUpperCase() : null, // 15 digit
      panNumber: pan.value ? pan.value.toUpperCase() : null, // 10 digit
      pinCode: pincode.value,
      isLoginFirstTime: false,
    };

    this.setState({ isLoading: true });
    UserService.updateUserInfo(obj)
      .then((data) => {
        const userDetail = StorageService.getUserDetail();
        userDetail.is_login_first_time = false;
        const userData = data.data.data;
        this.props.setUserDetail(userData);
        // userDetail.is_login_first_time = true;
        StorageService.setUserDetail(userDetail);
        localStorage.setItem("openSubDealerGuide", true);
        this.setState({ isLoading: false, redirectToWarrantyScoreCard: true });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
        ModalService.openAlert("Profile", message, "error");
      });
  };

  updateProfileBranch = (e) => {
    e.preventDefault();
    const isFormValid = this.handleBranchValidation(false, true);
    if (isFormValid === false) {
      return;
    }
    const { branchControls } = this.state;
    const {
      wa,
      alternateContactPerson,
      street,
      state,
      city,
      pincode,
    } = branchControls;

    const obj = {
      alternateContactPerson: alternateContactPerson.value,
      whatsappNumberUser: wa.value,

      address: street.value,
      state: state.value[0].name.toLowerCase(),
      city: city.value[0].name.toLowerCase(),
      pinCode: pincode.value,
      isLoginFirstTime: false,
    };

    this.setState({ isLoading: true });
    UserService.updateUserInfo(obj)
      .then((data) => {
        const userDetail = StorageService.getUserDetail();
        userDetail.is_login_first_time = false;
        const userData = data.data.data;
        this.props.setUserDetail(userData);
        StorageService.setUserDetail(userDetail);
        localStorage.setItem("openSubDealerGuide", true);
        this.setState({ isLoading: false, redirectToWarrantyScoreCard: true });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
        ModalService.openAlert("Profile", message, "error");
      });
  };

  /**
   * @param firstTime when form loads for first time directly error showing makes bad experience
   *            so do not show any message for null values but disable save button if
   *            there is something missing or some error.
   */
  handleValidation = (firstTime, isSubmit = false) => {
    // let firstTime = true;

    let { controls, isFormValid } = this.state;
    let {
      email,
      phone,
      birthDate,
      gst,
      pan,
      alPerson,
      alDesignation,
      alNumber,
      website,
      wa,
      instagram,
      facebook,
      anniversaryDate,
      agreeTermAndCondition,
      senderId,
      address,
      // city,
      // state,
      pin_code,
    } = controls;

    if (firstTime === true || email.touched === true || isSubmit) {
      email = Validation.notNullValidator(email);
      email = Validation.emailValidator(email);
      email.valid = !(email.nullValue || email.invalidEmail);
      if (
        (firstTime && email.invalidEmail) ||
        ((email.touched || isSubmit) && email.valid === false)
      ) {
        email.showErrorMsg = true;
      } else {
        email.showErrorMsg = false;
      }
    }

    if (firstTime === true || phone.touched === true || isSubmit) {
      phone = Validation.notNullValidator(phone);
      phone = Validation.validatePhoneNumber(phone);
      phone.valid = !(phone.nullValue || phone.invalidPhone);
      if (
        (firstTime && phone.invalidPhone) ||
        ((phone.touched || isSubmit) && phone.valid === false)
      ) {
        phone.showErrorMsg = true;
      } else {
        phone.showErrorMsg = false;
      }
    }

    /* if (state.touched === true || firstTime || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (city.touched === true || firstTime || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    } */

    if (address.touched === true || firstTime || isSubmit) {
      address = Validation.notNullValidator(address);
      address.valid = !address.nullValue;
      if (address.valid === false) {
        address.showErrorMsg = true;
      } else {
        address.showErrorMsg = false;
      }
    }

    if (pin_code.touched === true || firstTime || isSubmit) {
      pin_code = Validation.notNullValidator(pin_code);
      pin_code = Validation.validateLength(pin_code, 6);
      pin_code.valid = !(pin_code.invalidLength || pin_code.nullValue);
      if (pin_code.valid === false) {
        pin_code.showErrorMsg = true;
      } else {
        pin_code.showErrorMsg = false;
      }
    }

    if (firstTime === true || gst.touched === true || isSubmit) {
      gst = Validation.validateLength(gst, 15);
      gst.valid = !gst.invalidLength;
      if (
        (firstTime && gst.invalidLength) ||
        ((gst.touched || isSubmit) && gst.valid === false)
      ) {
        gst.showErrorMsg = true;
      } else {
        gst.showErrorMsg = false;
      }
    }

    if (firstTime === true || pan.touched === true || isSubmit) {
      pan = Validation.validateLength(pan, 10);
      pan.valid = !pan.invalidLength;
      if (
        (firstTime && pan.invalidLength) ||
        ((pan.touched || isSubmit) && pan.valid === false)
      ) {
        pan.showErrorMsg = true;
      } else {
        pan.showErrorMsg = false;
      }
    }

    if (firstTime === true || senderId.touched === true || isSubmit) {
      senderId = Validation.notNullValidator(senderId);
      if (senderId.nullValue) {
        senderId.showErrorMsg = false;
        senderId.valid = true;
      } else {
        senderId = Validation.validateAlphabetic(senderId);
        if (senderId.invalidAlphabetic === true) {
          senderId.invalidMinLength = false;
          senderId.showErrorMsg = true;
          senderId.valid = false;
        } else {
          senderId.invalidAlphabetic = false;
          senderId = Validation.minLengthValidator(senderId, 6);
          senderId = Validation.maxLengthValidator(senderId, 6);
          senderId.valid =
            !senderId.invalidMinLength && !senderId.invalidLength;
          if (
            (firstTime && senderId.invalidMinLength) ||
            (senderId.touched && senderId.valid === false)
          ) {
            senderId.showErrorMsg = true;
          } else {
            senderId.showErrorMsg = false;
          }
        }
      }
    }

    if (firstTime === true || alPerson.touched === true || isSubmit) {
      alPerson.valid = !alPerson.nullValue; // it will be removed
    }

    if (firstTime === true || alNumber.touched === true || isSubmit) {
      alNumber = Validation.validatePhoneNumber(alNumber);
      alNumber.valid = !alNumber.invalidPhone;
      if (
        (firstTime && alNumber.invalidPhone) ||
        ((alNumber.touched || isSubmit) && alNumber.valid === false)
      ) {
        alNumber.showErrorMsg = true;
      } else {
        alNumber.showErrorMsg = false;
      }
    }

    if (firstTime === true || alDesignation.touched === true) {
      alDesignation.valid = !alDesignation.nullValue;
    }

    if (firstTime === true || wa.touched === true || isSubmit) {
      wa = Validation.notNullValidator(wa);
      wa = Validation.validatePhoneNumber(wa);
      wa.valid = !(wa.invalidPhone || wa.nullValue);
      if (
        (firstTime && wa.invalidPhone) ||
        ((wa.touched || isSubmit) && wa.valid === false)
      ) {
        wa.showErrorMsg = true;
      } else {
        wa.showErrorMsg = false;
      }
    }

    if (firstTime === true || birthDate.touched === true || isSubmit) {
      if (
        birthDate.value === null ||
        birthDate.value === undefined ||
        birthDate.value === ""
      ) {
        birthDate.nullValue = true;
      } else {
        birthDate.nullValue = false;
      }
      birthDate.valid = !birthDate.nullValue;
      if ((birthDate.touched || isSubmit) && birthDate.valid === false) {
        birthDate.showErrorMsg = true;
      } else {
        birthDate.showErrorMsg = false;
      }
    }

    if (firstTime === true || anniversaryDate.touched === true || isSubmit) {
      anniversaryDate.valid = !anniversaryDate.nullValue;
    }

    if (firstTime === true || facebook.touched === true || isSubmit) {
      facebook.valid = !facebook.nullValue;
    }

    if (firstTime === true || instagram.touched === true || isSubmit) {
      instagram.valid = !instagram.nullValue;
    }

    if (firstTime === true || website.touched === true || isSubmit) {
      // website = Validation.notNullValidator(website);
      website.valid = !website.nullValue;
      if ((website.touched || isSubmit) && website.valid === false) {
        website.showErrorMsg = true;
      } else {
        website.showErrorMsg = false;
      }
    }

    if (
      email.valid === true &&
      phone.valid === true &&
      gst.valid === true &&
      pan.valid === true &&
      alPerson.valid === true &&
      alNumber.valid === true &&
      alDesignation.valid === true &&
      wa.valid === true &&
      birthDate.valid === true &&
      anniversaryDate.valid === true &&
      facebook.valid === true &&
      instagram.valid === true &&
      website.valid === true &&
      agreeTermAndCondition === true &&
      senderId.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  /**
   * @param firstTime when form loads for first time directly error showing makes bad experience
   *            so do not show any message for null values but disable save button if
   *            there is something missing or some error.
   */
  handleSubDealerValidation = (firstTime, isSubmit = false) => {
    // let firstTime = true;

    let { subDealerControls, isFormValid } = this.state;
    let {
      businessName,
      ownerName,
      wa,
      street,
      state,
      city,
      gst,
      pan,
      pincode,
    } = subDealerControls;

    if (firstTime === true || businessName.touched === true || isSubmit) {
      businessName = Validation.notNullValidator(businessName);
      // email = Validation.emailValidator(email);
      businessName.valid = !businessName.nullValue;
      if ((businessName.touched || isSubmit) && businessName.valid === false) {
        businessName.showErrorMsg = true;
      } else {
        businessName.showErrorMsg = false;
      }
    }

    if (firstTime === true || ownerName.touched === true || isSubmit) {
      ownerName = Validation.notNullValidator(ownerName);
      // email = Validation.emailValidator(email);
      ownerName.valid = !ownerName.nullValue;
      if ((ownerName.touched || isSubmit) && ownerName.valid === false) {
        ownerName.showErrorMsg = true;
      } else {
        ownerName.showErrorMsg = false;
      }
    }

    if (firstTime === true || wa.touched === true || isSubmit) {
      wa = Validation.notNullValidator(wa);
      wa = Validation.validatePhoneNumber(wa);
      wa.valid = !(wa.nullValue || wa.invalidPhone);
      if (
        (firstTime && wa.invalidPhone) ||
        ((wa.touched || isSubmit) && wa.valid === false)
      ) {
        wa.showErrorMsg = true;
      } else {
        wa.showErrorMsg = false;
      }
    }

    if (firstTime === true || street.touched === true || isSubmit) {
      street = Validation.notNullValidator(street);
      // email = Validation.emailValidator(email);
      street.valid = !street.nullValue;
      if ((street.touched || isSubmit) && street.valid === false) {
        street.showErrorMsg = true;
      } else {
        street.showErrorMsg = false;
      }
    }

    if (firstTime === true || state.touched === true || isSubmit) {
      if (state.value.length > 0) {
        state.valid = true;
      } else {
        state.valid = false;
      }
      if ((state.touched || isSubmit) && state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (firstTime === true || city.touched === true || isSubmit) {
      if (city.value.length > 0) {
        city.valid = true;
      } else {
        city.valid = false;
      }
      if ((city.touched || isSubmit) && city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (firstTime === true || pincode.touched === true || isSubmit) {
      pincode = Validation.notNullValidator(pincode);
      pincode = Validation.validateLength(pincode, 6);
      pincode = Validation.validateNumber(pincode);
      pincode.valid = !(
        pincode.nullValue ||
        pincode.invalidLength ||
        pincode.invalidNumber
      );
      if (
        (firstTime && (pincode.invalidLength || pincode.invalidNumber)) ||
        ((pincode.touched || isSubmit) && pincode.valid === false)
      ) {
        pincode.showErrorMsg = true;
      } else {
        pincode.showErrorMsg = false;
      }
    }

    if (firstTime === true || gst.touched === true || isSubmit) {
      gst = Validation.validateGST(gst);
      gst.valid = !gst.invalidGST;
      if (
        (firstTime && gst.invalidGST) ||
        ((gst.touched || isSubmit) && gst.valid === false)
      ) {
        gst.showErrorMsg = true;
      } else {
        gst.showErrorMsg = false;
      }
    }

    if (firstTime === true || pan.touched === true || isSubmit) {
      pan = Validation.validatePAN(pan);
      pan.valid = !pan.invalidPAN;

      if (
        (pan.value === null || pan.value === "") &&
        (gst.value === null || gst.value === "")
      ) {
        pan.nullValue = true;
        pan.valid = false;
      } else {
        pan.nullValue = null;
      }

      if (
        (firstTime && pan.invalidPAN) ||
        ((pan.touched || isSubmit) && pan.valid === false)
      ) {
        pan.showErrorMsg = true;
      } else {
        pan.showErrorMsg = false;
      }
    }

    if (
      businessName.valid === true &&
      ownerName.valid === true &&
      wa.valid === true &&
      street.valid === true &&
      state.valid === true &&
      city.valid === true &&
      gst.valid === true &&
      pan.valid === true &&
      pincode.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ subDealerControls, isFormValid });
    return isFormValid;
  };

  /**
   * @param firstTime when form loads for first time directly error showing makes bad experience
   *            so do not show any message for null values but disable save button if
   *            there is something missing or some error.
   */
  handleBranchValidation = (firstTime, isSubmit = false) => {
    // let firstTime = true;

    let { branchControls, isFormValid } = this.state;
    let {
      alternateContactPerson,
      wa,
      street,
      state,
      city,
      pincode,
    } = branchControls;

    if (
      firstTime === true ||
      alternateContactPerson.touched === true ||
      isSubmit
    ) {
      alternateContactPerson = Validation.notNullValidator(
        alternateContactPerson
      );
      // email = Validation.emailValidator(email);
      alternateContactPerson.valid = !alternateContactPerson.nullValue;
      if (
        (alternateContactPerson.touched || isSubmit) &&
        alternateContactPerson.valid === false
      ) {
        alternateContactPerson.showErrorMsg = true;
      } else {
        alternateContactPerson.showErrorMsg = false;
      }
    }

    if (firstTime === true || wa.touched === true || isSubmit) {
      wa = Validation.notNullValidator(wa);
      wa = Validation.validatePhoneNumber(wa);
      wa.valid = !(wa.nullValue || wa.invalidPhone);
      if (
        (firstTime && wa.invalidPhone) ||
        ((wa.touched || isSubmit) && wa.valid === false)
      ) {
        wa.showErrorMsg = true;
      } else {
        wa.showErrorMsg = false;
      }
    }

    if (firstTime === true || street.touched === true || isSubmit) {
      street = Validation.notNullValidator(street);
      // email = Validation.emailValidator(email);
      street.valid = !street.nullValue;
      if ((street.touched || isSubmit) && street.valid === false) {
        street.showErrorMsg = true;
      } else {
        street.showErrorMsg = false;
      }
    }

    if (firstTime === true || state.touched === true || isSubmit) {
      if (state.value.length > 0) {
        state.valid = true;
      } else {
        state.valid = false;
      }
      if ((state.touched || isSubmit) && state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (firstTime === true || city.touched === true || isSubmit) {
      if (city.value.length > 0) {
        city.valid = true;
      } else {
        city.valid = false;
      }
      if ((city.touched || isSubmit) && city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (firstTime === true || pincode.touched === true || isSubmit) {
      pincode = Validation.notNullValidator(pincode);
      pincode = Validation.validateLength(pincode, 6);
      pincode = Validation.validateNumber(pincode);
      pincode.valid = !(
        pincode.nullValue ||
        pincode.invalidLength ||
        pincode.invalidNumber
      );
      if (
        (firstTime && (pincode.invalidLength || pincode.invalidNumber)) ||
        ((pincode.touched || isSubmit) && pincode.valid === false)
      ) {
        pincode.showErrorMsg = true;
      } else {
        pincode.showErrorMsg = false;
      }
    }

    if (
      alternateContactPerson.valid === true &&
      wa.valid === true &&
      street.valid === true &&
      state.valid === true &&
      city.valid === true &&
      pincode.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ branchControls, isFormValid });
    return isFormValid;
  };

  handleSelectState = (e) => {
    const userRole = this.state.userRole;
    if (userRole === Roles.SUB_DEALER_ROLE) {
      const { subDealerControls } = this.state;
      const { state, city } = subDealerControls;
      state.touched = true;
      if (e.length > 0) {
        let stateId = e[0].state_id;
        state.value = e;
        city.value = [];
        this.setState({ subDealerControls });
        this.getCity(stateId);
      } else {
        state.value = [];
        city.value = [];
        this.setState({ subDealerControls, cities: [] });
      }
      this.handleSubDealerValidation(false);
    } else {
      const { branchControls } = this.state;
      const { state, city } = branchControls;
      state.touched = true;
      if (e.length > 0) {
        let stateId = e[0].state_id;
        state.value = e;
        city.value = [];
        this.setState({ branchControls });
        this.getCity(stateId);
      } else {
        state.value = [];
        city.value = [];
        this.setState({ branchControls, cities: [] });
      }
      this.handleBranchValidation(false);
    }
  };

  handleSelectCity = (e) => {
    const userRole = this.state.userRole;
    if (userRole === Roles.SUB_DEALER_ROLE) {
      const { subDealerControls } = this.state;
      const { city } = subDealerControls;
      city.touched = true;
      city.value = e;
      this.setState({ subDealerControls });
      this.handleSubDealerValidation(false);
    } else {
      const { branchControls } = this.state;
      const { city } = branchControls;
      city.touched = true;
      city.value = e;
      this.setState({ branchControls });
      this.handleBranchValidation(false);
    }
  };

  getState = () => {
    return WarrantyRegistrationService.getState()
      .then((data) => {
        const { data: states } = data.data;
        if (states.length > 0) {
          const currentState = states[0];
          this.getCity(currentState.state_id);
        }
        this.setState({ states });
        return Promise.resolve(states);
      })
      .catch((e) => {
        console.error(e);
        return Promise.reject([]);
      });
  };

  getCity = (stateId) => {
    return WarrantyRegistrationService.getCity(stateId)
      .then((data) => {
        const { data: cities } = data.data;
        this.setState({ cities });
        return Promise.resolve(cities);
      })
      .catch((e) => {
        console.error("e", e);
        return Promise.reject([]);
      });
  };

  // getState = () => {
  //   WarrantyRegistrationService.getState()
  //     .then(data => {
  //       const { data: states } = data.data;
  //       // if (states.length > 0) {
  //       //   const currentState = states[0];
  //       //   this.getCity(currentState.state_id);
  //       // }
  //       this.setState({ states });
  //     })
  //     .catch(e => {
  //       console.error(e);

  //     })
  // }

  // getCity = (stateId) => {
  //   WarrantyRegistrationService.getCity(stateId)
  //     .then(data => {
  //       const { data: cities } = data.data;
  //       this.setState({ cities });
  //     })
  //     .catch(e => {
  //       console.error("e", e);
  //     })
  // }

  render() {
    const {
      controls,
      subDealerControls,
      activeFragment,
      branchControls,
      redirectToDashBoard,
      userRole,
      userDetail,
      states,
      cities,
      // mobileNumber,
      redirectToWarrantyScoreCard,
    } = this.state;
    const {
      email,
      phone,
      gst,
      pan,
      alPerson,
      alDesignation,
      alNumber,
      wa,
      birthDate,
      anniversaryDate,
      facebook,
      instagram,
      website,
      sameAsUser,
      senderId,
      google_my_business_rating_link,
      google_my_business_link,
    } = controls;

    const {
      businessName,
      city,
      gst: subDealerGst,
      ownerName,
      pan: subDealerPan,
      state,
      street,
      wa: subDealerWa,
      pincode: subDealerPincode,
    } = subDealerControls;
    const {
      wa: branchWaNumber,
      alternateContactPerson,
      city: branchCity,
      state: branchState,
      street: branchStreet,
      pincode: branchPincode,
    } = branchControls;

    return (
      <div id="registration">
        <Form>
          {redirectToDashBoard && <Redirect to={"/"} />}

          {/* HODE Code */}
          {/* {redirectToDashBoard && (
            <Redirect
              to={
                userDetail &&
                  userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD) === true
                  ? "/dealer-dashboard"
                  : userRole === Roles.DISTRIBUTOR_ROLE ||
                    (userDetail &&
                      userDetail.customer_class &&
                      userDetail.customer_class.toLowerCase() === "na")
                    ? ""
                    : "?is_from_v2_dashbaord=true"
              }
            />
          )} */}
          {redirectToWarrantyScoreCard && (
            <Redirect
              to={{
                pathname: "/warranty-score-card",
                search:
                  userRole === Roles.SUB_DEALER_ROLE || userRole === Roles.BRANCH_ROLE
                    ? "q=t"
                    : "q=f",
              }}
            />
          )}
          <div style={{ height: "55px", paddingTop: "5px" }}>
            <img
              src="images/logo-small.png"
              height="30px"
              width="auto"
              alt=""
            />
          </div>
          <br />
          {!(userDetail && this.props.userDetail && userRole) && <CustomSpinner />}
          {userDetail &&
            this.props.userDetail &&
            userRole &&
            (userRole === Roles.DEALER_ROLE
              // || userRole === Roles.DISTRIBUTOR_ROLE
            ) &&
            userDetail.customer_class !== "na" &&
            userDetail.customer_class !== "hy" &&
            // ((!userDetail.permissions) || (userDetail.permissions &&
            // userDetail.permissions.includes(Permissions.PROFILE) === true)) &&
            // userDetail.permissions &&
            // userDetail.permissions.includes(Permissions.PROFILE) === true &&
            (userRole !== Roles.SUB_DEALER_ROLE || userRole !== Roles.BRANCH_ROLE) && (
              <Fragment>
                {userRole !== Roles.DISTRIBUTOR_ROLE &&
                  userRole === Roles.DEALER_ROLE &&
                  userDetail &&
                  userDetail.customer_class &&
                  userDetail.customer_class !== "na" && (
                    <Row>
                      <Col xl="1"></Col>
                      <Col xl="11">
                        <div className="page-title text-align-center gradient-title">
                          Complete your business profile and earn 500 points
                        </div>
                      </Col>
                    </Row>
                  )}
                <br />
                <Row>
                  <Col xl="1">
                    <div className="side-column-container">
                      <span
                        className="side-column-content"
                        onClick={this.goToPreviousPage}
                      >
                        <Ionicon
                          icon="ios-arrow-back"
                          fontSize="47px"
                          color="#b5babe"
                          width="32px"
                          height="47px"
                          className="cursor-pointer"
                        />
                      </span>
                    </div>
                  </Col>
                  <Col xl="3" className="margin-top-10">
                    <ProfileDetail
                      sapId={this.state.sapId}
                      businessName={this.state.businessName}
                      ownerName={this.state.ownerName}
                      address={this.state.address}
                      mobileNumber={this.state.mobileNumber}
                      profileImage={this.state.profileImage}
                      isImageUpdating={this.state.isImageUpdating}
                      userDetail={this.state.userDetail}
                      userInfo={this.props.userDetail}
                      handleUploadImage={this.handleUploadImage}
                      showRequestButton={false}
                    />
                  </Col>
                  <Col xl="8" className="margin-top-10">
                    {this.state.isLoading && <CustomSpinner></CustomSpinner>}
                    <Row onClick={this.activeFragment.bind(this, "business")}>
                      <Col>
                        <EditOtherBusinessDetail
                          className={
                            activeFragment === "business"
                              ? "card-yellow-border"
                              : ""
                          }
                          email={email}
                          phone={phone}
                          gst={gst}
                          pan={pan}
                          senderId={senderId}
                          city={this.state.controls.city}
                          state={this.state.controls.state}
                          pin_code={this.state.controls.pin_code}
                          address={this.state.controls.address}
                          handleInputChange={this.handleInputChange}
                          activeFragment={this.activeFragment}
                          userDetail={this.state.userDetail}
                          userInfo={this.props.userDetail}
                          ref={this.childBusinessDetail}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row onClick={this.activeFragment.bind(this, "contact")}>
                      <Col>
                        <EditPrimaryContactDetail
                          className={
                            activeFragment === "contact"
                              ? "card-yellow-border"
                              : ""
                          }
                          alPerson={alPerson}
                          alDesignation={alDesignation}
                          alNumber={alNumber}
                          wa={wa}
                          sameAsUser={sameAsUser}
                          handleInputChange={this.handleInputChange}
                          activeFragment={this.activeFragment}
                          setSameAsUserCheckBoxValue={
                            this.setSameAsUserCheckBoxValue
                          }
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row onClick={this.activeFragment.bind(this, "social")}>
                      <Col>
                        <EditSocialDetails
                          className={
                            activeFragment === "social"
                              ? "card-yellow-border"
                              : ""
                          }
                          birthDate={birthDate}
                          anniversaryDate={anniversaryDate}
                          facebook={facebook}
                          instagram={instagram}
                          website={website}
                          google_my_business_rating_link={
                            google_my_business_rating_link
                          }
                          google_my_business_link={google_my_business_link}
                          handleInputChange={this.handleInputChange}
                          handleBirthDateChange={this.handleBirthDateChange}
                          handleAnniversaryDateChange={
                            this.handleAnniversaryDateChange
                          }
                          activeFragment={this.activeFragment}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
              </Fragment>
            )}
          {this.props.userDetail &&
            userDetail &&
            userRole &&
            (userRole === Roles.SUB_DEALER_ROLE ||
              userRole === Roles.BRANCH_ROLE ||
              userDetail.customer_class === "na" ||
              userDetail.customer_class === "hy") && (
              <Fragment>
                <Row>
                  <Col xl="1"></Col>
                  <Col xl="11">
                    <div className="page-title">
                      Please fill up your profile details.
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xl="1">
                    {userRole === Roles.DEALER_ROLE && (
                      <div className="side-column-container">
                        <span
                          className="side-column-content"
                          onClick={this.goToPreviousPage}
                        >
                          <Ionicon
                            icon="ios-arrow-back"
                            fontSize="47px"
                            color="#b5babe"
                            width="32px"
                            height="47px"
                            className="cursor-pointer"
                          />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xl="3" className="margin-top-10">
                    <ProfileDetail
                      sapId={this.state.sapId}
                      businessName={this.state.businessName}
                      ownerName={this.state.ownerName}
                      address={this.state.address}
                      mobileNumber={this.state.mobileNumber}
                      profileImage={this.state.profileImage}
                      isImageUpdating={this.state.isImageUpdating}
                      handleUploadImage={this.handleUploadImage}
                      showRequestButton={false}
                      userDetail={this.state.userDetail}
                      userInfo={this.props.userDetail}
                    />
                  </Col>
                  <Col xl="8" className="margin-top-10">
                    {this.state.isLoading && <CustomSpinner></CustomSpinner>}
                    <Row onClick={this.activeFragment.bind(this, "business")}>
                      <Col>
                        {userRole === Roles.SUB_DEALER_ROLE && (
                          <EditSubDealerDetail
                            className={
                              activeFragment === "business"
                                ? "card-yellow-border"
                                : ""
                            }
                            businessName={businessName}
                            ownerName={ownerName}
                            wa={subDealerWa}
                            street={street}
                            state={state}
                            city={city}
                            gst={subDealerGst}
                            pan={subDealerPan}
                            states={states}
                            cities={cities}
                            pincode={subDealerPincode}
                            handleInputChange={this.handleSubDealerInputChange}
                            handleSelectCity={this.handleSelectCity}
                            handleSelectState={this.handleSelectState}
                            activeFragment={this.activeFragment}
                          />
                        )}
                        {(userRole === Roles.BRANCH_ROLE ||
                          this.state.userDetail.customer_class === "na" ||
                          this.state.userDetail.customer_class === "hy") && (
                            <EditBranch
                              className={
                                activeFragment === "business"
                                  ? "card-yellow-border"
                                  : ""
                              }
                              alternateContactPerson={alternateContactPerson}
                              wa={branchWaNumber}
                              street={branchStreet}
                              state={branchState}
                              city={branchCity}
                              states={states}
                              cities={cities}
                              pincode={branchPincode}
                              handleInputChange={this.handleBranchInputChange}
                              handleSelectCity={this.handleSelectCity}
                              handleSelectState={this.handleSelectState}
                              activeFragment={this.activeFragment}
                            />
                          )}
                      </Col>
                    </Row>
                    <br />
                  </Col>
                </Row>
                <br />
              </Fragment>
            )}
          <Row>
            <Col xl="1" />
            <Col xl="11" className="text-align-right">
              {userDetail &&
                this.props.userDetail &&
                userRole &&
                (userRole === Roles.DEALER_ROLE
                  // || userRole === Roles.DISTRIBUTOR_ROLE
                ) &&
                userDetail.customer_class !== "na" &&
                userDetail.customer_class !== "hy" &&
                // userDetail.permissions &&
                // userDetail.permissions.includes(Permissions.PROFILE) === true &&
                (userRole !== Roles.SUB_DEALER_ROLE ||
                  userRole !== Roles.BRANCH_ROLE ||
                  userDetail.customer_class !== "na" ||
                  userDetail.customer_class !== "hy") && (
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button"
                    color="warning"
                    onClick={this.updateProfile}
                    type="submit"
                    disabled={this.state.isLoading}
                  >
                    Save & Get Started
                  </Button>
                )}
              {userRole === Roles.SUB_DEALER_ROLE && (
                <Button
                  className="mb-1 mr-1 btn-pill action-button action-ob-button"
                  color="warning"
                  onClick={this.updateProfileSubDealer}
                  type="submit"
                  disabled={this.state.isLoading}
                >
                  Save & Get Started
                </Button>
              )}
              {(userRole === Roles.BRANCH_ROLE ||
                userDetail.customer_class === "na" ||
                userDetail.customer_class === "hy") && (
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button"
                    color="warning"
                    onClick={this.updateProfileBranch}
                    type="submit"
                    disabled={this.state.isLoading}
                  >
                    Save & Get Started
                  </Button>
                )}
            </Col>
          </Row>
          <div className="logout" onClick={this.props.openLogoutModal}>
            {" "}
            <img src="images/logout.svg" alt="" />
          </div>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserDetail: (user) => dispatch(setUserDetail(user)),
});

const mapStateToProp = (state) => ({
  userDetail: state.User.user,
});

export default connect(mapStateToProp, mapDispatchToProps)(Registration);

// export default Registration;
