/**
 * @name WarrantyReplacementService
 * @author Surjeet Bhadauriya and Rasul Diwan <surjeet.bhadauriya@techifysolutions.com, rasul.diwan@techifysolutions.com>
 * @description All APIs for warranty replacement
 * @version 0.0.0
 */
import { request } from "./Request";
import { routes } from "../constants/constant.route";

const {
  GET_DEALER_WARRANTY_DETAIL, GET_DEALER_WARRANTY_DETAIL_V2,
  GET_TOTAL_DEALERS_AND_POINTS, GET_TOTAL_DEALERS_AND_POINTS_V2,
  GET_MAX_DATE
} = routes.WARRANTY_SCORE_CARD;
const API_URL = process.env.REACT_APP_API_URL;

export default class WarrantyScoreCardService {
  /**
   * @name getDealerWarrantyDetail
   * @description get dealer warranty detail
   * @param  {page: number, limit: number, search:search,sapId:string}
   * @method POST
   * @returns promise
   */
  static getDealerWarrantyDetail(
    page,
    limit,
    search,
    sapId,
    downloadReport,
    year
  ) {
    let qp = `page=${page}&limit=${limit}&sap_id=${sapId}`;
    if (search) {
      qp += `&search_query=${search}`;
    }
    if (downloadReport) {
      qp += `&download_report=${downloadReport}`;
    }
    if (year) {
      qp += `&y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_DEALER_WARRANTY_DETAIL}?${qp}`,
      null,
      null,
      null
    );
  }

  static getMaxDate() {
    return request('GET', `${API_URL}${GET_MAX_DATE}`)
  }
  static getDealerWarrantyDetailV2(
    page,
    limit,
    search,
    sapId,
    downloadReport,
    year,
    quarter,
    month,
    pcr_min,
    pcr_max,
    two_wheel_min,
    two_wheel_max,
    isSensor,
    from_date,
    to_date,
    sub_dealer_branch,
    type
  ) {
    let qp = `page=${page}&limit=${limit}&sap_id=${sapId}`;
    if (search) {
      qp += `&search_query=${search}`;
    }
    if (downloadReport) {
      qp += `&download_report=${downloadReport}`;
    }
    if (year) {
      qp += `&y=${year}`;
    }
    if (quarter) {
      qp += `&q=${quarter}`;
    }
    if (month && year && year !== 'all') {
      qp += `&m=${month}`;
    }
    if (pcr_min) {
      qp += `&pcr_min=${pcr_min}`;
    }
    if (pcr_max) {
      qp += `&pcr_max=${pcr_max}`;
    }
    if (two_wheel_min) {
      qp += `&two_wheel_min=${two_wheel_min}`;
    }
    if (two_wheel_max) {
      qp += `&two_wheel_max=${two_wheel_max}`;
    }
    if (isSensor) {
      qp += `&is_sensor=${isSensor}`;
    }
    if (from_date) {
      qp += `&from_date=${from_date}`;
    }
    if (to_date) {
      qp += `&to_date=${to_date}`;
    }
    if (sub_dealer_branch) {
      qp += `&sub_dealer_branch=${sub_dealer_branch}`;
    }
    if (type) {
      qp += `&vehicle_type=${type}`;
    }

    return request(
      "GET",
      `${API_URL}${GET_DEALER_WARRANTY_DETAIL_V2}?${qp}`,
      null,
      null,
      null
    );
  }

  /**
   * @name getTotalDealersAndPoints
   * @description get all dealers and points
   * @param  {page: number, limit: number, search:search,sapId:string}
   * @method POST
   * @returns promise
   */
  static getAllDealersAndPoints(
    page,
    limit,
    search,
    downloadReport,
    sapId,
    year
  ) {
    let qp = `page=${page}&limit=${10}`;
    if (!!search) {
      qp += `&search_query=${search}`;
    }
    if (downloadReport) {
      qp += `&download_report=${downloadReport}`;
    }
    if (sapId) {
      qp += `&sap_id=${sapId}`;
    }
    if (year) {
      qp += `&y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_TOTAL_DEALERS_AND_POINTS_V2}?${qp}`,
      null,
      null,
      null
    );
  }

  static getAllDealersAndPointsV2(
    page,
    limit,
    search,
    downloadReport,
    sapId,
    year,
    quarter,
    month,
    pcr_min,
    pcr_max,
    two_wheel_min,
    two_wheel_max,
    lcv_min,
    lcv_max,
    scv_min,
    scv_max,
    sensor_min,
    sensor_max,
  ) {
    let qp = `page=${page}&limit=${10}`;
    if (!!search) {
      qp += `&search_query=${search}`;
    }
    if (downloadReport) {
      qp += `&download_report=${downloadReport}`;
    }
    if (sapId) {
      qp += `&sap_id=${sapId}`;
    }
    if (year) {
      qp += `&y=${year}`;
    }
    if (quarter) {
      qp += `&q=${quarter}`;
    }
    if (month && year && year !== 'all') {
      qp += `&m=${month}`;
    }
    if (pcr_min) {
      qp += `&pcr_min=${pcr_min}`;
    }
    if (pcr_max) {
      qp += `&pcr_max=${pcr_max}`;
    }
    if (two_wheel_min) {
      qp += `&two_wheel_min=${two_wheel_min}`;
    }
    if (two_wheel_max) {
      qp += `&two_wheel_max=${two_wheel_max}`;
    }
    if (lcv_min) {
      qp += `&lcv_min=${lcv_min}`;
    }
    if (lcv_max) {
      qp += `&lcv_max=${lcv_max}`;
    }
    if (scv_min) {
      qp += `&scv_min=${scv_min}`;
    }
    if (scv_max) {
      qp += `&scv_max=${scv_max}`;
    }
    if (sensor_min) {
      qp += `&sensor_min=${sensor_min}`;
    }
    if (sensor_max) {
      qp += `&sensor_max=${sensor_max}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_TOTAL_DEALERS_AND_POINTS_V2}?${qp}`,
      null,
      null,
      null
    );
  }

  /**
   * @name downloadWarrantyReport
   * @description download warranty scorecard report
   * @param  {page: number, limit: number, search:search,sapId:string}
   * @method POST
   * @returns promise
   */
  static downloadWarrantyReport(page, limit, search, downloadReport, sapId) {
    let qp = `page=${page}&limit=${limit}`;
    if (!!search) {
      qp += `&search_query=${search}`;
    }
    if (downloadReport) {
      qp += `&download_report=${downloadReport}`;
    }
    // if (sapId) {
    //   qp += `&sap_id=${sapId}`;
    // }
    return request(
      "GET",
      `${API_URL}${GET_TOTAL_DEALERS_AND_POINTS}?${qp}`,
      null,
      null,
      null
    );
  }
}
