import React, { Component } from 'react';
import {
  Button, FormGroup, Label, Row, Col, InputGroup,
  InputGroupAddon
} from 'reactstrap';
import DatePicker from "react-datepicker";
import DropDownMultiple from '../../CustomComponents/DropDownMultiple/DropDownMultiple';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
class CipSummaryListFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cipCategory: [],
      activity: [],
      cip_objetives: ['Awareness about Brand', 'Educate on JK TBB & TBR Product range',
        'New Product Launch', 'Customer engagement'],
      inviteeClassificationData: [],
      controls: {
        cip_objective: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        category: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        invitee_classification: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        type_of_activity: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        start_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        end_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        cip_start_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        cip_end_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        cip_ending_start_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        cip_ending_end_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
      },
      is_warranty_points_redemption: true
    }
  }

  componentDidMount() {
    if (this.props.controls_filter && Object.keys(this.props.controls_filter).length > 0) {
      this.setData();
    } else {
      this.getCipCategory();
    }

  }

  setData = async () => {
    await this.setState({
      controls: this.props.controls_filter
    });
    this.getCipCategory();
    if (this.props.controls_filter.category && this.props.controls_filter.category.value) {
      this.getInviteeClassification();
    }
    if (this.props.controls_filter.invitee_classification && this.props.controls_filter.invitee_classification.value) {
      this.getActivity();
    }
  }
  getCipCategory = () => {
    this.setState({
      isLoading: true
    })
    CustomerIntractionServie.getCipCategory()
      .then((data) => {
        let { cipCategory } = this.state;
        cipCategory = data.data.data;
        this.setState({ cipCategory, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };

  getInviteeClassification = () => {
    let { controls } = this.state;
    let reqData = {
      "category": controls.category.value
    }
    this.setState({
      isLoading: true
    })
    CustomerIntractionServie.getInviteeClassification(reqData)
      .then((data) => {
        let { inviteeClassificationData } = this.state;
        inviteeClassificationData = data.data.data;
        this.setState({ inviteeClassificationData, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };
  getActivity = () => {
    let { controls } = this.state;
    if (controls.invitee_classification.value && controls.invitee_classification.value.length <= 0) {
      return
    }
    let reqData = {
      "category": controls.category.value,
      invite_classification: []
    }
    controls.invitee_classification.value.forEach(element => {
      reqData.invite_classification.push(element.label);
    });
    this.setState({
      isLoading: true
    });
    CustomerIntractionServie.getActivity(reqData)
      .then((data) => {
        let { activity } = this.state;
        activity = data.data.data;
        this.setState({ activity, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };



  onChangeInviteClassfication = async (selctedItems) => {
    let { controls } = this.state;
    controls.invitee_classification.value = selctedItems;
    controls.invitee_classification.touched = true;
    await this.setState({
      controls
    })
    if (selctedItems.length > 0) {
      this.getActivity();
    } else {
      this.setState({
        activity: []
      })
    }
  }


  handleInputChange = async (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    await this.setState({ controls });
    if (controlName === 'category') {
      this.getInviteeClassification();
    }
  };

  applyFilter = () => {
    let { controls } = this.state;
    let filterApplied = true;
    // if (controls.orderStartDate.value ||
    //   controls.orderEndDate.value || controls.amountStart.value ||
    //   controls.amountEnd.value || controls.pointStart.value ||
    //   controls.pointEnd.value || controls.paymentStatus.value !== 'all' ||
    //   controls.amountStart.value || controls.paymentMode.value !== 'all') {
    //   filterApplied = true;
    // }
    this.props.applyFilter(filterApplied ? controls : {});
  }

  resetFilter = () => {
    this.props.applyFilter({});
  }

  closeSidebar = () => {
    this.props.closeSidebar();
  }


  render() {
    const { controls } = this.state;
    return (
      <div class="custom-drawer">
        {this.state.isLoading && <CustomSpinner />}
        <div className="drawer-header">
          <span className="drawer-title">Filter</span>
          <img className="cursor-pointer" src="images/close.svg" alt='cancel'
            onClick={() => this.closeSidebar()} />
        </div>
        <hr style={{ border: 'solid 1px #ced4da' }} className="w-100" />
        <div class="custom-drawer-content" style={{ marginLeft: '10px' }}>
          <Row className={'m-0'}>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label for="common">Category</Label>
                <select
                  className="form-control select-height-70rem font-size-14px"
                  name="category"
                  value={controls.category.value}
                  onChange={e => {
                    this.handleInputChange(e);
                  }}
                >
                  <option selected="selected" value="" disabled>
                    Select
                  </option>
                  {this.state.cipCategory.map(item => (
                    <option value={item.label}>{item.name}</option>
                  ))}
                </select>
                {controls.category.showErrorMsg && (
                  <div className="error">
                    * Please select category.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label for="common">Invitee Classification</Label>
                <DropDownMultiple
                  maxHeight="auto"
                  getOptionKey={'label'}
                  getOptionValue={'name'}
                  DropdownItems={this.state.inviteeClassificationData}
                  selectedItems={controls.invitee_classification.value}
                  setSelectedItems={(selectedItems) => this.onChangeInviteClassfication(selectedItems)}
                />
                {controls.invitee_classification.showErrorMsg && (
                  <div className="error">
                    * Please select invitee classification.
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col xl="12" md="12" xs="12">
              <FormGroup>
                <Label for="common">Type of Activity</Label>
                <select
                  className="form-control select-height-70rem font-size-14px"
                  name="type_of_activity"
                  value={controls.type_of_activity.value}
                  onChange={e => {
                    this.handleInputChange(e);
                  }}
                >
                  <option selected="selected" value="" disabled>
                    Select
                  </option>
                  {this.state.activity.map(item => (
                    <option value={item.name}>{item.name}</option>
                  ))}
                </select>
                {controls.type_of_activity.showErrorMsg && (
                  <div className="error">
                    * Please select type of activity.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="12" md="12" xs="12">
              <FormGroup>
                <Label for="common">CIP Objective</Label>
                <select
                  className="form-control select-height-70rem font-size-14px"
                  name="cip_objective"
                  value={controls.cip_objective.value}
                  onChange={e => {
                    this.handleInputChange(e);
                  }}
                >
                  <option selected="selected" value="" disabled>
                    Select Objective
                  </option>
                  {this.state.cip_objetives.map(item => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                {controls.cip_objective.showErrorMsg && (
                  <div className="error">
                    * Please select cip objective.
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col xl="12">
              <Label className="font-weight-bold">Created Date</Label>
            </Col>
            <Col xl="5">
              <FormGroup className="d-flex-row">
                <Label className="mt-2 mr-2">From</Label>
                <Label onClick={e => this.calendar1.state.open && e.preventDefault()}
                  className={!this.props.is_warranty_points_redemption ? 'display-botttom-datepicker' : ''}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.start_date.value
                          ? new Date(controls.start_date.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'start_date', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar1 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={controls.end_date.value ? controls.end_date.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>
            <Col xl="5">
              <FormGroup className="d-flex-row">
                <Label className="mt-2 mr-2">To</Label>
                <Label onClick={e => this.calendar2.state.open && e.preventDefault()}
                  className={!this.props.is_warranty_points_redemption ? 'display-botttom-datepicker date-picker-left-n-35px' : 'date-picker-left-n-35px'}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.end_date.value
                          ? new Date(controls.end_date.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'end_date', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar2 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      minDate={controls.start_date.value ? controls.start_date.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>


            <Col xl="12">
              <Label className="font-weight-bold">CIP Start Date</Label>
            </Col>
            <Col xl="5">
              <FormGroup className="d-flex-row">
                <Label className="mt-2 mr-2">From</Label>
                <Label onClick={e => this.calendar3.state.open && e.preventDefault()}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.cip_start_date.value
                          ? new Date(controls.cip_start_date.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'cip_start_date', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar3 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={controls.cip_end_date.value ? controls.cip_end_date.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>
            <Col xl="5">
              <FormGroup className="d-flex-row">
                <Label className="mt-2 mr-2">To</Label>
                <Label onClick={e => this.calendar4.state.open && e.preventDefault()} className={'display-margin-left-n-35'}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.cip_end_date.value
                          ? new Date(controls.cip_end_date.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'cip_end_date', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar4 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      minDate={controls.cip_start_date.value ? controls.cip_start_date.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>



            <Col xl="12">
              <Label className="font-weight-bold">CIP End Date</Label>
            </Col>
            <Col xl="5">
              <FormGroup className="d-flex-row">
                <Label className="mt-2 mr-2">From</Label>
                <Label onClick={e => this.calendar5.state.open && e.preventDefault()}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.cip_ending_start_date.value
                          ? new Date(controls.cip_ending_start_date.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'cip_ending_start_date', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar5 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={controls.cip_ending_end_date.value ? controls.cip_ending_end_date.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>
            <Col xl="5">
              <FormGroup className="d-flex-row">
                <Label className="mt-2 mr-2">To</Label>
                <Label onClick={e => this.calendar6.state.open && e.preventDefault()} className={'display-margin-left-n-35'}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.cip_ending_end_date.value
                          ? new Date(controls.cip_ending_end_date.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'cip_ending_end_date', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar6 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      minDate={controls.cip_ending_start_date.value ? controls.cip_ending_start_date.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter()} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
            <Button
              //  disabled={!isValidForm} 
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: 'pointer' }}
              onClick={() => this.applyFilter()}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div >
    );
  }
}

export default CipSummaryListFilter;