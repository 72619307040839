import React, { Component } from 'react';
import {
  Input,
  Row,
} from "reactstrap";
class CipVehicleRelatedDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleReleatedData: props.vehicleReleatedData ? props.vehicleReleatedData : {},
      load_type: [
        "2 Wheeler Carrier",
        "Agriculture (Food Grains)",
        "Aviation Fuel",
        "Bulker",
        "Car Carrier",
        "Cement",
        "Cement & Fly Ash Bulkers",
        "Construction",
        "Container",
        "Courier",
        "CV Carrier",
        "Tractor Carrier",
        "FMCG and Durables",
        "Industrial Gas",
        "Industrial Machiery",
        "Iron and Steel",
        "Light Goods",
        "LPG Bullet",
        "LPG Cylinder",
        "Market Load",
        "Mining",
        "Concrete Mixer",
        "Municipal",
        "Parcel & Logistics",
        "Perishable Goods",
        "Ports",
        "Refridgerated Vans",
        "Spl Chemical & Other Patroleum Product",
        "Steel & Iron Ore",
        "Tanker",
        "White Goods",
      ],
      MonthlyRunningData: [
        "< 5k",
        "5k - 6k",
        "6k - 7k",
        "7k - 8k",
        "8k - 9k",
        "9k - 10k",
        "> 10k ",
      ],
    }
  }


  onChangeVehicle = (e, type, i) => {
    this.props.onChangeVehicle(e, type, i);
  };



  render() {
    let { vehicleReleatedData } = this.state;
    return (
      <Row>
        <table border="0" className="vehicle-related-tbl">
          <thead>
            <tr>
              {
                Object.keys(vehicleReleatedData).length > 0 && vehicleReleatedData[this.props.activeTab ? this.props.activeTab : 'truck'].headers.map((item, i) => (
                  <th style={{ ...item.style }}>{item.label}
                    {item.required && <span className="required-field">*</span>}
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {Object.keys(vehicleReleatedData).length > 0 && vehicleReleatedData[this.props.activeTab ? this.props.activeTab : 'truck'].data.map((vehicle_type, i) => {
              return (
                <tr>
                  <td className="font-weight-bold" valign="middle"
                    style={{ minWidth: '100px' }}>
                    <div className="d-flex">
                      <span className="mt-auto mb-auto">
                        {vehicle_type.label}
                      </span>
                    </div>
                  </td>
                  <td valign="top">
                    <div className="d-flex">
                      <Input
                        type="text"
                        autocomplete="nofill"
                        name="count"
                        className="mt-2 mr-2"
                        value={vehicle_type.count.value}
                        style={{ ...vehicle_type.count.style }}
                        disabled={this.props.is_view_only_dealils}
                        onChange={(e) =>
                          this.onChangeVehicle(e, this.props.activeTab, i)
                        }
                        placeholder=""
                      />
                    </div>
                    {vehicle_type.count.showErrorMsg && <div className="error mt-1">Please enter count.</div>}
                  </td>
                  {
                    vehicle_type.hasOwnProperty('application') &&
                    <td valign="top">
                      <div className="d-flex">
                        <select
                          className="form-control font-size-14px mt-2 mr-2"
                          name="application"
                          value={vehicle_type.application.value}
                          disabled={this.props.is_view_only_dealils}
                          onChange={(e) =>
                            this.onChangeVehicle(e, this.props.activeTab, i)
                          }

                        >
                          <option value="" selected>
                            Select
                          </option>
                          {this.state.load_type.map((load_type, j) => (
                            <option
                              className="text-capitalize"
                              value={load_type}
                              key={load_type}
                            >
                              {load_type}
                            </option>
                          ))}
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </td>
                  }
                  {
                    vehicle_type.hasOwnProperty('payload') &&
                    <td valign="top">
                      <div className="d-flex">
                        <Input
                          type="text"
                          autocomplete="nofill"
                          name="payload"
                          className="mt-2 mr-2"
                          value={vehicle_type.payload.value}
                          disabled={this.props.is_view_only_dealils}
                          onChange={(e) =>
                            this.onChangeVehicle(e, this.props.activeTab, i)
                          }
                          placeholder=" "
                        />
                      </div>
                    </td>
                  }
                  {
                    vehicle_type.hasOwnProperty('average_monthly_usage') &&
                    <td valign="top">
                      <div className="d-flex">
                        <select
                          className="form-control mt-2 font-size-14px"
                          name="average_monthly_usage"
                          value={vehicle_type.average_monthly_usage.value}
                          disabled={this.props.is_view_only_dealils}
                          onChange={(e) =>
                            this.onChangeVehicle(e, this.props.activeTab, i)
                          }
                        >
                          <option value="" selected>
                            Select
                          </option>
                          {vehicle_type.avg_monthly_list.map(
                            (MonthlyRunning, j) => (
                              <option
                                className="text-capitalize"
                                value={MonthlyRunning}
                                key={MonthlyRunning}
                              >
                                {MonthlyRunning}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </td>
                  }
                </tr>
              )
            })
            }
          </tbody>
        </table>
        {/* <div className="atleast-one-Tyre-Details-note">
          Note : You have to enter atleast one Tyre Details
       </div> */}
        {
          !this.props.isAddedVehicleRelated[this.props.activeTab] &&
          <div className="error mt-2 ml-2" id="isAddedVehicleRelated">Please enter vehicle details.
          </div>
        }
      </Row>
    );
  }
}

export default CipVehicleRelatedDetails;