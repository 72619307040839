import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  Input,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import Pagination from "../../components/Pagination/Pagination";
import DatePicker from "react-datepicker";
import lodash from "lodash";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import "./UserUpdates.css";
import CampaignService from "../../services/CampaignService";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import UserService from "../../services/UserService";
import { downlodFile } from "../../utils/utils";
import _ from "lodash";
import Ionicon from "react-ionicons";

const WhatsappCampaignTable = React.forwardRef(
  ({ openSideBarCreateCampaign }, ref) => {
    const [count, setCount] = useState(10);
    const [totalCount, setTotalCount] = useState(10);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [endDate, setEndDate] = useState("");
    const [whatsappListData, seWhatsappListData] = useState([]);
    const [
      isFirsTimeLoadPageDataNotFound,
      setIsFirsTimeLoadPageDataNotFound,
    ] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const pageNumber = localStorage.getItem("pageNumber");
    const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

    useEffect(() => {
      getWhatsappReport(page, pageSize, startDate, endDate, searchValue);
    }, []);

    const onStartDateChange = (e) => {
      setStartDate(e.target.value);
      setPage(1);
      setPageSize(10);
      getWhatsappReport(1, 10, e.target.value, endDate, searchValue);
    };
    const onEndDateChange = (e) => {
      setEndDate(e.target.value);
      setPage(1);
      setPageSize(10);
      getWhatsappReport(1, 10, startDate, e.target.value, searchValue);
    };

    const onResetApplied = () => {
      setStartDate("");
      setEndDate("");
      setPage(1);
      setPageSize(10);
      setSearchValue("");
      getWhatsappReport(1, 10, "", "");
    };

    const getWhatsappReport = (
      page,
      pageSize,
      startDate,
      endDate,
      search = "",
      exportReport = false
    ) => {
      setIsLoading(true);
      console.log("datttta", { page, pageSize, startDate, endDate });
      let reqData = {
        page: page || 1,
        pageSize: pageSize || 10,
        startDate: startDate
          ? moment(startDate)
              .startOf("day")
              .utc()
          : null,
        endDate: endDate
          ? moment(endDate)
              .endOf("day")
              .utc()
          : null,
        export: exportReport,
        search: search,
      };
      console.log("reqData", reqData);

      UserService.getWhatsappReport(reqData)
        .then((data) => {
          if (data.data && data.data.data && data.data.data.file) {
            setIsLoading(false);
            downlodFile(data.data.data.file);
            return;
          }
          let { data: list } = data.data;
          if (list.data && Array.isArray(list.data) && list.data.length > 0) {
            seWhatsappListData(list.data);
            if (isFirsTimeLoadPageDataNotFound) {
              setIsFirsTimeLoadPageDataNotFound(false);
            }
          } else {
            seWhatsappListData([]);
          }
          const totalCountsTemp = list.total_sms;
          const totalPagesTemp = Math.ceil(totalCountsTemp / pageSize);
          setCount(totalCountsTemp);
          setTotalCount(totalPagesTemp);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    };

    const getSMSReportDetails = (uuid, exportReport) => {
      setIsLoading(true);
      let reqData = {
        uuid,
        export: exportReport,
      };
      UserService.getSMSReportDetails(reqData)
        .then((data) => {
          if (data.data && data.data.data && data.data.data.file) {
            setIsLoading(false);
            downlodFile(data.data.data.file);
            return;
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    };
    const handlePageChange = (page) => {
      setPage(page);
      getWhatsappReport(page, pageSize, startDate, endDate, searchValue);
    };
    const handleInputChange = (e) => {
      setPageSize(Number(e.target.value));
      setPage(1);
      getWhatsappReport(
        1,
        Number(e.target.value),
        startDate,
        endDate,
        searchValue
      );
    };
    const handleEmailSentToDownload = (data) => {
      window.open(data, "_blank");
    };

    const handleFullReportDownload = () => {
      // let reqData = {
      //   page: page || 1,
      //   pageSize: pageSize || 10,
      //   start_date: startDate ? moment(startDate).utc() : "",
      //   end_date: endDate ? moment(endDate).utc() : "",
      // };
      // console.log("download reqData", reqData);
      getWhatsappReport(1, 100000000, startDate, endDate, searchValue, true);
    };

    // Forward the ref
    React.useImperativeHandle(ref, () => ({
      getWhatsappReport: () =>
        getWhatsappReport(1, 10, startDate, endDate, searchValue, false), // pass arguments to the function
    }));
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    function handleDebounceFn(value) {
      getWhatsappReport(1, 10, startDate, endDate, value, false);
    }
    function onSearchChange(event) {
      setSearchValue(event.target.value);
      debounceFn(event.target.value);
    }
    return (
      <div style={{ width: "100%" }}>
        <div className="email_title_container" style={{ paddingTop: 0 }}>
          <span className="email_campaign_title">Whatsapp Campaign</span>
          <button
            onClick={() => openSideBarCreateCampaign(true)}
            className="create_campaign_button"
          >
            Create Whatsapp Campaign
          </button>
        </div>
        <div className="email_input_container" style={{ paddingRight: "18px" }}>
          <div style={{ alignItems: "center" }} className="d-flex-row">
            <div
              style={{ width: "365px", height: "35px" }}
              className="d-flex-row search-input mt-auto"
            >
              <Input
                placeholder="Search by template name"
                className="login-input"
                name="SearchValueWhatsappTemplates"
                onChange={onSearchChange}
                value={searchValue}
                autoComplete="off" // Add autoComplete attribute
              ></Input>
              <img
                src="images/search.svg"
                alt=""
                className="icon-width"
                style={{ marginLeft: "-25px", width: "16px" }}
              />
            </div>
            {/* <div
              data-tip="Refresh Whatsapp Templates"
              data-for={"refresh-whatsapp-templates"}
              style={{ cursor: "pointer" }}
              className="ml-4"
            >
              <Ionicon
                icon="ios-refresh"
                fontSize="34px"
                color="#000"
                onClick={() => this.resetWhatsappTemplate()}
              />
              <ReactTooltip id={"refresh-whatsapp-templates"} effect="solid">
               </ReactTooltip>
            </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
              className="ml-4"
            >
              <div style={{ paddingRight: "3px", maxWidth: "250px" }}>
                <div style={{ marginBottom: "8px" }}>
                  <span className="email_campaign_date_title">Start Date</span>
                </div>
                <Input
                  type="date"
                  name="startDate"
                  value={startDate || ""}
                  onChange={onStartDateChange}
                />
              </div>
              <div style={{ paddingLeft: "3px", maxWidth: "250px" }}>
                <div style={{ marginBottom: "8px" }}>
                  <span className="email_campaign_date_title">End Date</span>
                </div>
                <Input
                  type="date"
                  name="endDate"
                  value={endDate || ""}
                  onChange={onEndDateChange}
                />
              </div>
              <div style={{ marginLeft: "12px" }}>
                <button
                  style={{ color: "#6E777F", background: "#E0E2E4" }}
                  className="email_campaign_button"
                  onClick={onResetApplied}
                >
                  Reset
                </button>
              </div>
              {count > 0 ? (
                // <div style={{ marginLeft: "30px" }}>
                //   <FontAwesomeIcon
                //     color="#007bff"
                //     className="pull-right"
                //     icon={faFileExcel}
                //     onClick={handleFullReportDownload}
                //     style={{
                //       fontSize: "28px",
                //       cursor: "pointer",
                //     }}
                //   />
                // </div>

                <div
                  className="link-warm-blue cursor-pointer"
                  style={{
                    marginLeft: "20px",
                    cursor: "pointer",
                    marginTop: "4px",
                  }}
                  onClick={handleFullReportDownload}
                >
                  <img
                    src="images/download.svg"
                    alt=""
                    className="download-report-icon cursor-pointer margin-left-10"
                  />
                  <label style={{ cursor: "pointer" }} className="">
                    Download WhatsApp Report
                  </label>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <br />
        {/* <div className="email_input_container" style={{ paddingRight: "18px" }}>
          <div
            style={{ alignItems: "center", justifyContent: "space-between" }}
            className="d-flex-row"
          >
            {/* Filters */}

        {isLoading && <CustomSpinner />}
        <div style={{ padding: "0 18px" }}>
          <Table className="mb-0 table-responsive tbl-about tbl-about text-align-center">
            <thead>
              <tr
                style={{ backgroundColor: "#f6f6f6", height: "40px" }}
                className="coupon-list-table-header"
              >
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Date</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Type</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Template Name</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Message</span>{" "}
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Total</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Processing</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Delivered</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Failed</span>
                </td>
                {/* <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Credit Used</span></td> */}
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Action</span>
                </td>
              </tr>
            </thead>
            <tbody>
              {whatsappListData &&
                whatsappListData.map((item, index) => {
                  const msgVal = item.message;
                  const dotMsgVal =
                    msgVal && msgVal.length > 50
                      ? `${msgVal.slice(0, 50)}...`
                      : msgVal || "";

                  const msgType = capitalizeFirstLetter(item.msg_type);
                  return (
                    <tr key={index}>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {moment(item.created_at).format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {msgType}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {item.template_name}
                      </td>
                      <td
                        className="text-align-center border-1-5 email_campaign_table_data"
                        style={{ maxWidth: "200px" }}
                      >
                        <div style={{ cursor: "pointer" }} id={`id-${index}`}>
                          {dotMsgVal}
                        </div>
                        <UncontrolledTooltip
                          placement="bottom"
                          target={`id-${index}`}
                          style={{ minWidth: "350px" }}
                        >
                          {msgVal}
                        </UncontrolledTooltip>
                      </td>

                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {item.total_sms}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {item.processing_sms}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {item.delivered_sms}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {item.failed_sms}
                      </td>
                      {/* <td className="text-align-center border-1-5 email_campaign_table_data">123</td> */}
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        <div>
                          <img
                            src="images/download.svg"
                            alt=""
                            onClick={() => getSMSReportDetails(item.uuid, true)}
                            style={{ cursor: "pointer" }}
                            id={`download-id-${index}`}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={`download-id-${index}`}
                          >
                            Download Report
                          </UncontrolledTooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        {count > 0 && (
          <div
            className="d-flex-row"
            style={{ marginTop: "10px", justifyContent: "flex-end" }}
          >
            <span className="Rows-per-page">Rows per page:</span>
            <select
              className="form-control rows-per-page-select"
              name="pageSize"
              value={pageSize}
              onChange={(e) => handleInputChange(e)}
              style={{ width: "65px" }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
            &nbsp;&nbsp;
            <div style={{ marginTop: "5px" }}>
              <Pagination
                margin={2}
                page={page}
                count={totalCount}
                pageSize={pageSize}
                totalRecords={count}
                onPageChange={handlePageChange}
              ></Pagination>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default WhatsappCampaignTable;
