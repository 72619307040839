import React from "react";
import { Switch, Redirect } from "react-router-dom";
import {
  publicRouteObj,
  privateRouteObj,
  onBoardingRouteObj,
} from "./RouterConfig";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import OnBoardingRoute from "../components/OnBoardingRoute/OnBoardingRoute";
import PublicRoute from "../components/PublicRoute/PublicRoute";
import Storage from "../services/Storage";
import { Roles } from "../constants/constant.role";
let isFirstTimeLoad = true;
const Routes = () => {
  // const publicRoutesObj = publicRouteObj.map(route => (
  //   <Route key={route.key} exact={route.exact} path={route.path} component={route.component} />
  // ));
  const userDetail =
    Storage && Storage.getUserDetail() ? Storage.getUserDetail() : {};
  // Set Default Route
  if (userDetail && userDetail.type === Roles.BRAND_STORE_ROLE && isFirstTimeLoad) {
    let dashboardIndex = privateRouteObj.findIndex((x) => x.path === "/");
    privateRouteObj[dashboardIndex].path = "/dashboard";
    let proposalDashboard = privateRouteObj.find(
      (x) => x.path === "/proposal-dashboard"
    );
    if (proposalDashboard) {
      proposalDashboard.path = "/";
    }
    privateRouteObj.push(proposalDashboard);
    isFirstTimeLoad = false;
  }
  window.scroll(0, 0);
  const publicRoutesObj = publicRouteObj.map((route) => (
    <PublicRoute
      key={route.key}
      exact={route.exact}
      path={route.path}
      component={route.component}
      sidenav={route.sidenav}
      header={route.header}
    />
  ));

  const privateRoutesObj = privateRouteObj.map((route) => (
    <PrivateRoute
      key={route.key}
      exact={route.exact}
      path={route.path}
      component={route.component}
      sidenav={route.sidenav}
      header={route.header}
      permission={route.permission}
      footer={route.footer ? route.footer : false}
      isPrivateAndPublic={route.isPrivateAndPublic}
      role={route.role}
    />
  ));

  const onBoardingRoutesObj = onBoardingRouteObj.map((route) => (
    <OnBoardingRoute
      key={route.key}
      exact={route.exact}
      path={route.path}
      component={route.component}
      sidenav={route.sidenav}
      header={route.header}
      role={route.role}
    />
  ));

  return (
    <Switch>
      {publicRoutesObj}
      {privateRoutesObj}
      {onBoardingRoutesObj}
      {/* if user enter wrong path redirect to home page */}
      <Redirect from="*" to="/" />
    </Switch>
  );
};
export default Routes;
