import React, { Component } from 'react';
import {
  Button, Form,
  FormGroup, Label,
  Input,
  Row, Col,
} from 'reactstrap';
import Validation from '../../services/Validation';

class ChangePassword extends Component {

  state = {
    height: window.innerHeight,
    width: window.innerWidth,
    controls: {
      password: {
        value: '',
        valid: null,
        touched: false,
        message: null,
        nullPassword: null,
        invalidPassword: null
      },
      confirmPassword: {
        value: '',
        valid: null,
        touched: false,
        message: null,
        nullPassword: null,
        invalidPassword: null
      }
    },
    isFormValid: false
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  }

  handleValidation = () => {
    let { controls, isFormValid } = this.state;
    let { password, confirmPassword } = controls;

    if (password.touched === true) {
      password = Validation.passwordValidator(password);
      password.valid = !(password.nullPassword);
    }


    if (confirmPassword.touched === true) {
      confirmPassword = Validation.passwordValidator(confirmPassword);
      confirmPassword.valid = !(confirmPassword.nullPassword);
    }

    if (
      password.valid === true &&
      confirmPassword.valid === true &&
      password.value === confirmPassword.value
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
  }

  resizeWindow = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { controls, isFormValid } = this.state;
    const { password, confirmPassword } = controls;
    return (
      <div id="login">
        {/* <Container> */}
        <Row>
          <Col xl="3" xs="1" md="1" className={this.state.width < 1200 ? 'display-none' : ''}>
            <img className="login-left-img" src="images/login-img.jpg" alt="" />
          </Col>
          <Col xl="1" xs="1" md="1" />
          <Col xl="7" xs="10" md="10">
            <Form>
              <Row className={this.state.width < 1200 ? 'margin-top-20' : 'margin-top-150'} />
              <Row className="padding-left-13px" style={{ marginBottom: '20px' }}>
                <img src="images/login-logo.jpg" alt="" />
              </Row>
              <Row className="welcome-text">
                Welcome
              </Row>
              <Row className="sign-in-subtitle-text">
                <div>
                  Please create new password to login to dashboard
                </div>
              </Row>
              <hr />
              <Row>
                <Col xl="6" xs="12" md="12">
                  {/* <Row> */}
                  <FormGroup>
                    <Label for="password">Enter Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder=""
                      value={password.value}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  {password.nullPassword && <div>Password is invalid</div>}
                  {/* </Row> */}
                </Col>
              </Row>

              <Row>
                <Col xl="6" xs="12" md="12">
                  {/* <Row> */}
                  <FormGroup>
                    <Label for="confirmPassword">Confirm Password</Label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder=""
                      value={confirmPassword.value}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  {confirmPassword.nullPassword && <div>Password is invalid</div>}
                  {/* </Row> */}
                </Col>
              </Row>


              <Row>
                <Col xl="6" xs="12" md="12" className="text-align-right">
                  <Button className="mb-1 mr-1 btn-pill action-button" color="warning" disabled={!isFormValid}>Set Password</Button>
                </Col>
                <Col xl="6" className={this.state.width < 1200 ? 'display-none' : ''} />

              </Row>

            </Form>
          </Col>
          <Col xl="1" xs="1" sm="1" />

        </Row>
        {/* </Container> */}
      </div >
    );
  }
}

export default ChangePassword;