import React, { Component } from "react";
import ModalService from "../../services/ModalService";
import { Row, Col, Button, FormGroup } from "reactstrap";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import MappleToolTip from "reactjs-mappletooltip";
class ManualDataFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add_Data: "",
      custom_filter_manual_customer: []
    };
  }

  componentDidMount() {
    if (this.props.ManualDataFilter) {
      this.setState({
        add_Data: this.props.ManualDataFilter.join("\n")
      });
    }
  }

  handleInputChange = e => {
    const re = /^(\s*\d+)+\s*$/;
    if (e.target.value === "" || e.target.value.match(re)) {
      this.setState({
        add_Data: e.target.value
      });
    }
  };

  applyFilter = () => {
    this.getCustomerCount();
  };
  resetFilter = flag => {
    this.props.applyFilter({});
  };

  closeSideBar = () => {
    this.props.closeSideBar(false);
  };

  getCustomerCount = () => {
    let array = this.state.add_Data.split("\n");
    this.state.custom_filter_manual_customer.push(array);
    for (
      var i = 0;
      i < this.state.custom_filter_manual_customer[0].length;
      i++
    ) {
      if (
        this.state.custom_filter_manual_customer[0][i].trim().length > 10 ||
        this.state.custom_filter_manual_customer[0][i].trim().length < 10
      ) {
        var index = this.state.custom_filter_manual_customer[0].indexOf(
          this.state.custom_filter_manual_customer[0][i]
        );
        this.state.custom_filter_manual_customer[0].splice(index, 1);
      }
      this.props.applyFilter(this.state.custom_filter_manual_customer[0]);
    }

    if (this.state.custom_filter_manual_customer[0].length === 0) {
      const err =
        "There is no customer found with applied filter . Please change filter criteria";
      const message = err;
      ModalService.openAlert("", message, "error");
      this.setState({
        errorLength: false
      });
      return;
    }
  };

  render() {
    let { add_Data } = this.state;
    const mappleConfig = {
      direction: "bottom",
      backgroundColor: "white",
      float: false
    };
    return (
      <div class="custom-drawer" id="AddEditActivity">
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        <div style={{ marginTop: "60px" }}>
          <div className="drawer-header">
            <span className="drawer-title">Manual Data Filter</span>
            <img
              className="cursor-pointer"
              src="images/close.svg"
              alt="cancel"
              onClick={() => this.closeSideBar(false)}
            />
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
        </div>
        <div class="custom-drawer-content">
          <Row
            className="mb-2"
            id="add_data_manually"
            style={{ marginLeft: "-10px", marginRight: "0" }}
          >
            <Col xl="12" md="12" xs="12">
              <div className="d-flex-row pcrtype-wrapp">
                <span
                  className="selectgrplabel margin-left-11"
                  style={{ width: "128px" }}
                >
                  Add Data Manually
                </span>
                <MappleToolTip {...mappleConfig}>
                  <div className="f-flex-row">
                    <img
                      style={{ marginTop: "-4px" }}
                      src="images/sidebar-icons/about-selected.svg"
                      alt=''
                      id="editorimage"
                    />
                  </div>
                  <Row className="otherlangcontent-wrapps">
                    <Col>
                      <div
                        className="otherlangcontent"
                        style={{
                          width: "100%",
                          color: "black",
                          textAlign: "left",
                          fontSize: "12px",
                          marginLeft: "-370px",
                          marginTop: "-72px",
                          display: "block",
                          padding: "10px",
                          height: "auto"
                        }}
                      >
                        <ul className="Enrollment-ul text-none">
                          <li className="enroll-li campaign-popup-notes">
                            You can send SMS to 2000 contacts at once manually.
                          </li>
                          <li className="enroll-li campaign-popup-notes">
                            Select the phone numbers you want to run a campaign
                            with and paste them in the box below.
                          </li>
                          <li className="enroll-li campaign-popup-notes">
                            Only 10 digit phone number are allowed.
                          </li>
                          <li className="enroll-li campaign-popup-notes">
                            It is not necessary to have the phone numbers added
                            in your customer list. You can send SMS to any phone
                            number using this option.
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </MappleToolTip>
              </div>
            </Col>
          </Row>
          <Row
            className="c-top-15"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <textarea
                  rows="4"
                  maxLength="22000"
                  className="Rectangle-Copy-18"
                  cols="50"
                  id="add_Data"
                  name="add_Data"
                  style={{ border: "solid 2px #ced4da" }}
                  value={add_Data}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <Row
          className="c-top-15 custom-drawer-footer"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <Col className="d-flex-row" style={{ justifyContent: "flex-end" }}>
            <Button
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => this.applyFilter()}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ManualDataFilter;
