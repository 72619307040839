import React from "react";
import "./ComparisonQuarter.css";
import { Row, Col } from "reactstrap";

const ComparisonQuarter = ({
  cmpQuarter,
  setCmpQuarter,
  handleChangeCmpQuarter,
  getCmpQuarterData,
}) => {
  return (
    <div className=" flex align-items-center ComparisonQuarter">
      <div className="radioInnerTitle mb-3">Select Quarter for comparison</div>
      <Row className="yearWrap">
        <Col className="mb-2 position-relative">
          <label>Select Year 1</label>
          <select
            className="form-control select"
            name="first_year"
            value={cmpQuarter.first_year || ""}
            onChange={handleChangeCmpQuarter}
          >
            <option value="">
              Select Year
            </option>
            <option value="2019">FY 19-20</option>
            <option value="2020">FY 20-21</option>
            <option value="2021">FY 21-22</option>
            <option value="2022">FY 22-23</option>
          </select>
        </Col>
        <Col className="mb-2 position-relative">
          <label>Select Year 2</label>
          <select
            className="form-control select"
            name="sec_year"
            value={cmpQuarter.sec_year || ""}
            onChange={handleChangeCmpQuarter}
          >
            <option value="">
              Select Year
            </option>
            <option value="2019">FY 19-20</option>
            <option value="2020">FY 20-21</option>
            <option value="2021">FY 21-22</option>
            <option value="2022">FY 22-23</option>
          </select>
        </Col>
        <Col className="mb-2 position-relative">
          <label>Select Quarter 1</label>
          <select
            className="form-control select"
            name="first_quarter"
            value={cmpQuarter.first_quarter || ""}
            onChange={handleChangeCmpQuarter}
          >
            <option value="">
              Select Quarter
            </option>
            <option value="q1">Quarter 1</option>
            <option value="q2">Quarter 2</option>
            <option value="q3">Quarter 3</option>
            <option value="q4">Quarter 4</option>
          </select>
        </Col>
        <Col className="mb-2 position-relative">
          <label>Select Quarter 2</label>
          <select
            className="form-control select"
            name="sec_quarter"
            value={cmpQuarter.sec_quarter || ""}
            onChange={handleChangeCmpQuarter}
          >
            <option value="" >
              Select Quarter
            </option>
            <option value="q1">Quarter 1</option>
            <option value="q2">Quarter 2</option>
            <option value="q3">Quarter 3</option>
            <option value="q4">Quarter 4</option>
          </select>
        </Col>
        {/* <button
          type="button"
          className="btn btn-primary btn-sm mt-1"
          onClick={getCmpQuarterData}
        >
          Get Data
        </button> */}
      </Row>
    </div>
  );
};

export default ComparisonQuarter;
