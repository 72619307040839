import React, { Component } from "react";
import { Row, Col, Table, Card, CardBody } from "reactstrap";
import "./CampaignDetail.css";
import Ionicon from "react-ionicons";
import CampaignService from "../../services/CampaignService";
import swal from "sweetalert";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import Moment from "react-moment";
import { downlodFile } from "../../utils/utils";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import Lightbox from "react-image-lightbox";
const { ERROR_MESSAGE } = messages.COMMON;
class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigntransfrDatatodetail: this.props.campaigntransfrDatatodetail,
      isLoading: false,
      is_scheduled: props.is_scheduled,
      campaign_id: "",
      msg_count: 0,
      sms_data: [],
      festName: "",
      tableLoader: false,
      Notification_report: [],
      Notification_report_download: null,
      isPreviewImageLightBoxOpen: false,
      imagesToPreview: [],
    };
  }

  componentDidMount() {
    this.getCampaignDetail(this.props.campaigntransfrDatatodetail.campaign_id);
    if (
      this.props.campaigntransfrDatatodetail.purpose_name === "Festival Wish"
    ) {
      this.setState({
        festName: this.props.campaigntransfrDatatodetail.festival_name,
      });
    } else if (
      this.props.campaigntransfrDatatodetail.purpose_name === "Promote Service"
    ) {
      this.setState({
        festName: this.props.campaigntransfrDatatodetail.service_name,
      });
    } else {
      this.setState({
        festName: "",
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.campaigntransfrDatatodetail) {
      this.setState({
        campaign_id: props.campaigntransfrDatatodetail.campaign_id,
      });
      if (props.campaigntransfrDatatodetail.purpose_name === "Festival Wish") {
        this.setState({
          festName: props.campaigntransfrDatatodetail.festival_name,
        });
      } else if (
        props.campaigntransfrDatatodetail.purpose_name === "Promote Service"
      ) {
        this.setState({
          festName: props.campaigntransfrDatatodetail.service_name,
        });
      } else {
        this.setState({
          festName: "",
        });
      }
    }

    if (
      props.campaigntransfrDatatodetail.campaign_id !==
      this.props.campaigntransfrDatatodetail.campaign_id
    ) {
      this.getCampaignDetail(props.campaigntransfrDatatodetail.campaign_id);
    }
  }

  editCampaign = (e) => {
    this.state.custom_customer = {
      openCreateCampaignPage: true,
      isEdit: true,
      CampaignDetailData: this.state.campaigntransfrDatatodetail,
    };
    this.state.custom_customer.CampaignDetailData["isEdit"] = true;
    this.props.onSelectTargetGroup(this.state.custom_customer);
  };

  deleteCampaign = (e, id) => {
    e.preventDefault();
    swal({
      title: "Delete Campaign",
      text: "Are you sure, you want to delete this campaign",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.confirmDeleteCampaign(id);
      }
    });
  };

  // runCampaign = (e, id) => {
  //   e.preventDefault();
  //   swal({
  //     title: "Run Campaign",
  //     text: "Are you sure, you want to run this campaign",
  //     icon: "warning",
  //     buttons: ["No", "Yes"],
  //   }).then((isConfirm) => {
  //     if (isConfirm) {
  //       this.confirmDeleteCampaign(id);
  //     }
  //   });
  // };

  confirmDeleteCampaign = (id) => {
    this.setState({
      isLoading: true,
    });
    CampaignService.deleteCampaign(id)
      .then((res) => {
        this.props.resetData();
        this.setState({
          isLoading: false,
        });
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.state.custom_customer = {
          Deleted: true,
        };
        this.props.onSelectTargetGroup(this.state.custom_customer);
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  downloadReport = () => {
    this.setState({
      isLoading: true,
    });
    const { campaigntransfrDatatodetail } = this.state;
    let obj = {
      uuid: campaigntransfrDatatodetail.campaign_id,
      export: true,
    };
    CampaignService.downloadReport(obj)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        if (res.data.data.file) {
          downlodFile(res.data.data.file);
        }
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  duplicateCampaign = (e) => {
    // delete this.state.campaigntransfrDatatodetail["customers_count"];
    // this.state.campaigntransfrDatatodetail["customers_count"] = 0;
    this.state.campaigntransfrDatatodetail["isDuplicate"] = true;
    this.state.custom_customer = {
      openCreateCampaignPage: true,
      duplicateCampaign: true,
      CampaignDetailData: this.state.campaigntransfrDatatodetail,
    };
    this.props.onSelectTargetGroup(this.state.custom_customer);
  };

  runNowCampaignPopup = () => {
    // e.preventDefault();
    swal({
      title: "Run Now Campaign",
      text: "Are you sure, you want to run this campaign right now?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.campaignRunNow();
      }
    });
  };

  campaignRunNow = () => {
    this.setState({
      isLoading: true,
    });
    CampaignService.campaignRunNow(this.state.campaign_id)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.state.custom_customer = {
          runNowDone: true,
        };
        this.props.onSelectTargetGroup(this.state.custom_customer);
        this.props.getSMSCredits();
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  reRunNowCampaignPopup = () => {
    // e.preventDefault();
    swal({
      title: "Re-Run Campaign",
      text: "Are you sure, you want to re-run this campaign?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.campaignRerunNow();
      }
    });
  };


  campaignRerunNow = () => {
    let obj = {
      campaign_id: this.state.campaign_id,
    };
    this.setState({
      isLoading: true,
    });
    CampaignService.campaignRerunNow(obj)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.getCampaignDetail(this.state.campaign_id);
        this.props.getSMSCredits();
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  getCampaignDetail = (id) => {
    this.setState({
      isLoading: true,
    });
    if (!id) {
      return;
    }
    CampaignService.getCampaignDetail(id)
      .then((res) => {
        this.setState({
          campaigntransfrDatatodetail: res.data.data,
          campaign_id: res.data.data.campaign_id,
        });
        if (!this.state.is_scheduled) {
          this.getSMSDetailAPI(res.data.data.campaign_id);
        }
        this.state.custom_customer = {
          CampaignDetailData: res.data.data,
        };
        if (
          this.state.campaigntransfrDatatodetail.communication_type ===
          "notification"
        ) {
          this.getSMSDetailAPIForPushNotification(false);
        }
        this.props.onSelectTargetGroup(this.state.custom_customer);
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });

        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  getSMSDetailAPI = (id) => {
    let obj = {
      id: id,
    };
    this.setState({
      tableLoader: true,
    });
    CampaignService.getSMSDetail(obj)
      .then((res) => {
        this.setState({
          msg_count: res.data.data.counts,
          sms_data: res.data.data,
          tableLoader: false,
        });
      })
      .catch((e) => {
        this.setState({
          tableLoader: false,
        });
      });
  };

  getSMSDetailAPIForPushNotification = (bool) => {
    let obj = {
      id: this.state.campaign_id,
      export: bool,
      limit: 10,
      offset: 0,
    };
    this.setState({
      tableLoader: true,
    });
    CampaignService.getSMSDetailAPIForPushNotification(obj)
      .then((res) => {
        if (!bool) {
          this.setState({
            Notification_report: res.data.data.data,
            tableLoader: false,
          });
        } else {
          this.setState({
            Notification_report_download: res.data.data,
            tableLoader: false,
          });
          downlodFile(res.data.data.file);
        }
      })
      .catch((e) => {
        this.setState({
          tableLoader: false,
        });
      });
  };

  openPreviewImageLightBox = (image) => {
    const imagesToPreview = [image];
    this.setState({ isPreviewImageLightBoxOpen: true, imagesToPreview });
  };

  closePreviewImageLightBox = () => {
    const imagesToPreview = [];
    this.setState({ isPreviewImageLightBoxOpen: false, imagesToPreview });
  };

  render() {
    const {
      campaigntransfrDatatodetail,
      is_scheduled,
      isPreviewImageLightBoxOpen,
      imagesToPreview,
      sms_data,
      Notification_report,
    } = this.state;
    let photoIndex = 0;
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        {isPreviewImageLightBoxOpen && (
          <Lightbox
            mainSrc={imagesToPreview[photoIndex]}
            nextSrc={0}
            prevSrc={0}
            onCloseRequest={this.closePreviewImageLightBox}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + imagesToPreview.length - 1) %
                  imagesToPreview.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % imagesToPreview.length,
              })
            }
          />
        )}
        <Row id="campaignDetail">
          <Card className="w-100">
            <CardBody>
              <Row>
                <Col xl={is_scheduled ? "8" : "10"} md="12" xs="12">
                  <div className="CampaignLabel">
                    <span className="text-style-1 text-capitalize">
                      {campaigntransfrDatatodetail.campaign_name}
                    </span>
                  </div>
                </Col>
                <Col
                  xl={is_scheduled ? "4" : "2"}
                  md="12"
                  xs="12"
                  style={{
                    display: "flex",
                    marginTop: is_scheduled ? "-26px" : "-13px",
                    justifyContent: "flex-end",
                  }}
                >
                  {is_scheduled && (
                    <span className="option">
                      <Ionicon
                        icon="md-create"
                        fontSize="24px"
                        color="#fdbb1f"
                        onClick={this.editCampaign}
                      />
                      <p onClick={this.editCampaign}>Edit</p>
                    </span>
                  )}
                  {is_scheduled && (
                    <span className="option">
                      <img
                        src="images/sidebar-icons/group-5.png"
                        srcSet="images/sidebar-icons/group-5@2x.png 2x,
                        images/sidebar-icons/group-5@3x.png 3x"
                        onClick={(e) =>
                          this.deleteCampaign(
                            e,
                            campaigntransfrDatatodetail.campaign_id
                          )
                        }
                        className="DeleteIcon"
                        alt=''
                      />
                      <br />
                      <p className="margin-left--10">Delete</p>
                    </span>
                  )}
                  <span className="option1" onClick={this.duplicateCampaign}>
                    <img
                      src="images/sidebar-icons/fill1.png"
                      srcSet="images/sidebar-icons/fill1@2x.png 2x,
                      images/sidebar-icons/fill1@3x.png 3x"
                      className="DuplicateIcon"
                      alt=''
                    />
                    <br />
                    <p className="margin-left--10">Duplicate</p>
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                <Col xl="10" md="8" xs="6">
                  <div className="CampaignLabel">
                    {!this.props.isHoLogin && (
                      <>
                        <span className="Purpose text-capitalize">
                          purpose :
                        </span>
                        <span className="Birthday-Wish-Detail">
                          {campaigntransfrDatatodetail.purpose_name}
                          {this.state.festName !== "" && (
                            <span>({this.state.festName})</span>
                          )}
                        </span>
                      </>
                    )}
                    <span
                      className="CustomerGroup"
                      style={{ paddingLeft: this.props.isHoLogin && "0" }}
                    >
                      Customer Group:
                    </span>
                    <span className="Birthday-Wish-Detail text-capitalize">
                      {campaigntransfrDatatodetail.customer_target_type ===
                        "jk_user"
                        ? "JK User"
                        : campaigntransfrDatatodetail.customer_target_type ===
                          "jk_secondary_point"
                          ? "JK Secondary User"
                          : campaigntransfrDatatodetail.customer_target_type ===
                            "jk_promotion_and_services"
                            ? "Promotional & Events Users"
                            : campaigntransfrDatatodetail.customer_target_type}
                    </span>
                    {
                      campaigntransfrDatatodetail.sender_id &&
                      <span className="white-space-nowrap ml-2">
                        <span className="Purpose text-capitalize">
                          Sender ID :
                        </span>
                        <span className="Birthday-Wish-Detail text-capitalize">
                          {campaigntransfrDatatodetail.sender_id}
                        </span>
                      </span>
                    }
                  </div>
                </Col>
                {/* <Col xl="4" md="3" xs="6" style={{ marginTop: "5px" }}>
                  {is_scheduled &&
                    campaigntransfrDatatodetail.communication_type !=
                      "notification" && (
                      <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                        <span className="Purpose text-capitalize">
                          Message Credit Used :
                        </span>
                        <span className="Birthday-Wish-Detail text-capitalize">
                          {campaigntransfrDatatodetail.sms_credit_used}
                        </span>
                      </span>
                    )}
                </Col> */}
              </Row>
              <Row style={{ marginLeft: "0px", marginTop: "7px" }}>
                <Col xl="8" md="12" xs="12">
                  <div
                  // className="CampaignLabel"
                  >
                    <span className="Purpose text-capitalize">
                      Message Type :
                    </span>
                    <span className="Birthday-Wish-Detail text-capitalize">
                      {campaigntransfrDatatodetail.message_type}
                    </span>
                    <span style={{ marginLeft: "10px" }}></span>
                    {campaigntransfrDatatodetail.communication_type ===
                      "notification" ? (
                      <span>
                        <span className="Purpose text-capitalize">
                          Communication Type :
                        </span>
                        <span className="Birthday-Wish-Detail text-capitalize">
                          {campaigntransfrDatatodetail.communication_type}
                        </span>
                      </span>
                    ) : (
                      <span>
                        <span className="Purpose text-capitalize">
                          Language :
                        </span>
                        <span className="Birthday-Wish-Detail text-capitalize">
                          {campaigntransfrDatatodetail.language}
                        </span>
                      </span>
                    )}
                  </div>
                </Col>
                <Col xl="4" md="3" xs="6" style={{ textAlign: 'right', paddingRight: '25px' }}>
                  {is_scheduled &&
                    campaigntransfrDatatodetail.communication_type !==
                    "notification" && (
                      <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                        <span className="Purpose text-capitalize">
                          Message Credit Used :
                        </span>
                        <span className="Birthday-Wish-Detail text-capitalize">
                          {campaigntransfrDatatodetail.sms_credit_used}
                        </span>
                      </span>
                    )}
                </Col>
                {/* <Col
                  xl="3"
                  md="12"
                  xs="12"
                  style={{ marginTop: "15px", paddingRight: "24px" }}
                >
                  <div className="Details-options">
                    {is_scheduled ? (
                      <div
                        className="f-flex-row run-now-link"
                        onClick={() => {
                          this.runNowCampaignPopup();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="link-warm-blue">Run Now</span>
                      </div>
                    ) : (
                      <div
                        className="f-flex-row run-now-link"
                        onClick={() => {
                          this.reRunNowCampaignPopup();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="link-warm-blue">Re-run Now</span>
                      </div>
                    )}
                  </div>
                </Col> */}
              </Row>
              <Row style={{ marginLeft: "0px", marginTop: "7px" }}>
                <Col xl="9" md="12" xs="12">
                  <span className="Campaign1-Created-o">
                    {" "}
                    Created on :{" "}
                    <Moment format="DD/MM/YYYY">
                      {campaigntransfrDatatodetail.created_at}
                    </Moment>
                  </span>
                </Col>
                <Col
                  xl="3"
                  md="12"
                  xs="12"
                  style={{ paddingRight: "24px" }}
                >
                  <div className="Details-options">
                    {is_scheduled ? (
                      <div
                        className="f-flex-row run-now-link"
                        onClick={() => {
                          this.runNowCampaignPopup();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="link-warm-blue">Run Now</span>
                      </div>
                    ) : (
                      <div
                        className="f-flex-row run-now-link"
                        onClick={() => {
                          this.reRunNowCampaignPopup();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="link-warm-blue">Re-run Now</span>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <hr className="Line-17" />
              <Row>
                <Col sm="12" xl="4">
                  {campaigntransfrDatatodetail.communication_type ===
                    "notification" ? (
                    <div
                      className="d-flex-column"
                      style={{ marginTop: "15px" }}
                    >
                      <span className="Preview-Message">Campaign Message</span>

                      <div className="sms-previewNotification">
                        <div className="notification-content-preview ">
                          <div
                            className="notification_chatbox-wrapper"
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            <div
                              className="chatbox-inner"
                              style={{
                                width: campaigntransfrDatatodetail.image_url
                                  ? "140px"
                                  : "",
                                maxHeight: "100%",
                              }}
                            >
                              <div className="scroll-container" fxFlex="auto">
                                <b>
                                  {
                                    campaigntransfrDatatodetail.notification_title
                                  }
                                </b>
                                <br />
                                {campaigntransfrDatatodetail.sms_draft}
                                <br />
                              </div>
                              {campaigntransfrDatatodetail.message_type ===
                                "picture" && (
                                  <div>
                                    {campaigntransfrDatatodetail.image_url && (
                                      <img
                                        src={
                                          campaigntransfrDatatodetail.image_url
                                        }
                                        style={{
                                          height: "54px",
                                          paddingRight: "45px",
                                          position: "absolute",
                                          right: "0",
                                          top: "8px",
                                          maxWidth: "100px",
                                          marginRight: "-40px",
                                        }}
                                        onClick={() =>
                                          this.openPreviewImageLightBox(
                                            campaigntransfrDatatodetail.image_url
                                          )
                                        }
                                        alt=''
                                      />
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Group-4">
                      <span className="Preview-Message">Campaign Message</span>

                      <div className="sms-content-preview d-flex-column">
                        <span className="date-preview">
                          <b>
                            <Moment format="DD/MM/YYYY  hh:mm A">
                              {campaigntransfrDatatodetail.started_at}
                            </Moment>
                          </b>
                        </span>
                        <div className="chatbox-wrapper chatbox-dot">
                          <div className="chatbox-inner">
                            <perfect-scrollbar
                              className="scroll-container"
                              fxFlex="auto"
                            >
                              {campaigntransfrDatatodetail.sms_draft}
                              <br />
                              {campaigntransfrDatatodetail.shortner_url && (
                                <a
                                  href={
                                    campaigntransfrDatatodetail.shortner_url
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="sms_draft_image_url"
                                  style={{ color: "blue", cursor: "pointer" }}
                                >
                                  {campaigntransfrDatatodetail.shortner_url}
                                </a>
                              )}
                            </perfect-scrollbar>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
                {!is_scheduled ? (
                  <Col
                    sm="12"
                    xl="8"
                    style={{ marginTop: "20px", paddingRight: "0px" }}
                  >
                    <Col sm="12" xl="12" className="d-flex">
                      <span className="Download-icon">
                        <img
                          src="images/group-6.png"
                          srcSet="images/group-6@2x.png 2x,
                      images/group-6@3x.png 3x"
                          className="Group-6"
                          alt=''
                        />
                        <span
                          className="Download-Report"
                          onClick={
                            campaigntransfrDatatodetail.communication_type ===
                              "notification"
                              ? () =>
                                this.getSMSDetailAPIForPushNotification(true)
                              : this.downloadReport
                          }
                        >
                          Download Report
                        </span>
                      </span>
                    </Col>
                    {campaigntransfrDatatodetail.communication_type ===
                      "notification" ? (
                      <Col sm="12" xl="12">
                        {this.state.tableLoader && <CustomSpinner />}
                        <Table bordered className="margin-top-5">
                          <thead>
                            <tr>
                              <th className="Rectangle-Copy-7">Date</th>
                              <th className="Rectangle-Copy-7">Android</th>
                              <th className="Rectangle-Copy-7">IOS</th>
                              <th className="Rectangle-Copy-7">Web</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Notification_report &&
                              Notification_report.map((item) => (
                                <tr>
                                  <td className="Rectangle-Copy-6">
                                    <span className="TableContent">
                                      {item.started_date}
                                    </span>
                                  </td>
                                  <td className="Rectangle-Copy-6">
                                    <span className="TableContent">
                                      {item.android_count}
                                    </span>
                                  </td>
                                  <td className="Rectangle-Copy-6">
                                    <span className="TableContent">
                                      {item.ios_count}
                                    </span>
                                  </td>
                                  <td className="Rectangle-Copy-6">
                                    <span className="TableContent">
                                      {item.web_count}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      <Col sm="12" xl="12">
                        {this.state.tableLoader && <CustomSpinner />}
                        <Table bordered className="margin-top-5">
                          <thead>
                            <tr>
                              <th className="Rectangle-Copy-7">Date</th>
                              <th className="Rectangle-Copy-7">
                                Customer Count
                              </th>
                              <th className="Rectangle-Copy-7">Credit Used</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sms_data.map((item) => (
                              <tr>
                                <td className="Rectangle-Copy-6">
                                  <span className="TableContent">
                                    {item.started_date}
                                  </span>
                                </td>
                                <td className="Rectangle-Copy-6">
                                  <span className="TableContent">
                                    {item.total_customers}
                                  </span>
                                </td>
                                <td className="Rectangle-Copy-6">
                                  <span className="TableContent">
                                    {item.credit_used}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <Row style={{ marginLeft: "0", marginRight: "0" }}>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className="text-align-right"
                  style={{ marginTop: "5px" }}
                ></Col>
              </Row>
              <br />
            </CardBody>
          </Card>
        </Row>
      </>
    );
  }
}

export default CampaignDetail;
