import React, { Component } from 'react';
import './CipSummaryList.css';
import {
  Button,
  Input,
  Row, Col, Collapse,
  Card, CardBody, UncontrolledPopover, PopoverHeader, PopoverBody
} from 'reactstrap';
import Sidebar from "react-sidebar";
import lodash from "lodash"; // library added for debounce functionality
import './CipSummaryList.css';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import Moment from 'react-moment';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import Pagination from '../Pagination/Pagination';
import CipSummaryListFilter from './CipSummaryListFilter';
import { downlodFile } from '../../utils/utils';
import CancelCip from './CancelCip';
import swal from 'sweetalert';
import CipPictureUpload from '../CipPictureUpload/CipPictureUpload';
import CipExpensesIncurred from './CipExpensesIncurred';
import ModalService from '../../services/ModalService';
class CipSummaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      collapse: false,
      summaryPage: 1,
      totalSummaryPages: 0,
      totalSummary: 0,
      pageSize: 10,
      controls_filter: {},
      openCipSummaryListFilter: false,
      tooltipOpen: false,
      isDisplayUncontrolledPopover: false,
      tooltip_name: ''
    }
    this.searchData = lodash.debounce(this.searchData, 500);
    this.refAddParticpant = React.createRef();

  }

  componentDidMount() {
    this.getCip();
    if (this.props.isDisplayAddParticipantPopover) {
      this.setState({
        tooltipOpen: true,
        tooltip_name: 'Add-particpant'
      });
    }
  }

  componentDidUpdate() {
    if (!this.state.isDisplayUncontrolledPopover && this.state.tooltip_name) {
      this.setReady();
    }
  }

  setReady() {
    if (this.refAddParticpant.current) {
      setTimeout(() => {
        this.setState({
          isDisplayUncontrolledPopover: true,
        });
      }, 1000);
    }
  }

  getCip = (download_report = false) => {
    let { controls_filter } = this.state;
    let reqData = {
      page: this.state.summaryPage,
      pagesize: this.state.pageSize,
      q: this.state.search_query
    }
    if (controls_filter && Object.keys(controls_filter).length > 0) {
      if (controls_filter.category.value) {
        reqData.category = controls_filter.category.value;
      }
      if (controls_filter.cip_objective.value) {
        reqData.cip_objective = controls_filter.cip_objective.value;
      }
      if (controls_filter.invitee_classification.value && controls_filter.invitee_classification.value.length > 0) {
        reqData.invitee_classification = [];
        controls_filter.invitee_classification.value.forEach(element => {
          reqData.invitee_classification.push(element.label);
        });
      }
      if (controls_filter.type_of_activity.value) {
        reqData.type_of_activity = controls_filter.type_of_activity.value;
      }
      if (controls_filter.start_date.value) {
        let date = new Date(controls_filter.start_date.value);
        date.setHours(0);
        date.setMinutes(0);
        reqData.start_date = date;
      }
      if (controls_filter.end_date.value) {
        let date = new Date(controls_filter.end_date.value);
        date.setHours(23);
        date.setMinutes(59);
        reqData.end_date = date;
      }

      if (controls_filter.cip_start_date.value) {
        let date = new Date(controls_filter.cip_start_date.value);
        date.setHours(0);
        date.setMinutes(0);
        reqData.cip_start_date = date;
      }
      if (controls_filter.cip_end_date.value) {
        let date = new Date(controls_filter.cip_end_date.value);
        date.setHours(23);
        date.setMinutes(59);
        reqData.cip_end_date = date;
      }

      if (controls_filter.cip_ending_start_date.value) {
        let date = new Date(controls_filter.cip_ending_start_date.value);
        date.setHours(0);
        date.setMinutes(0);
        reqData.cip_ending_start_date = date;
      }
      if (controls_filter.cip_ending_end_date.value) {
        let date = new Date(controls_filter.cip_ending_end_date.value);
        date.setHours(23);
        date.setMinutes(59);
        reqData.cip_ending_end_date = date;
      }

    }
    reqData.download_report = download_report;
    // q: Hi - Tech Tyres
    this.setState({ isLoading: true });
    CustomerIntractionServie.getCipV2(reqData)
      .then(data => {
        if (data.data.data.file) {
          downlodFile(data.data.data.file);
          this.setState({
            isLoading: false,
          })
          return
        }
        let { cipSummary } = this.state;
        cipSummary = data.data.data.data;
        let totalSummary = data.data.data.total;
        const totalSummaryPages = Math.ceil(totalSummary / 10);
        this.setState({
          cipSummary,
          isLoading: false,
          isDisplayCIPSummary: true,
          totalSummaryPages,
          totalSummary
        }, () => {

        })
      })
      .catch(e => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  }

  applyFilter = async (controls) => {
    await this.setState({
      controls_filter: controls,
      search_query: '',
      summaryPage: 1,
      pageSize: 10
    });
    this.getCip();
    this.closeSidebar();
  }
  searchData = async (search, page) => {
    await this.setState({
      search_query: search,
      pageSize: 10,
      summaryPage: 1
    });
    this.getCip()
  }


  handleSearchChange = (e) => {
    let searchVal = e.target.value;
    this.setState({ page: 1, search: searchVal });
    this.searchData(searchVal, 1);
  }
  collapseOpen = (i) => {
    let { cipSummary } = this.state;
    cipSummary[i].collapse = !cipSummary[i].collapse;
    this.setState({
      cipSummary
    })
  };
  pageChange = async (e) => {
    await this.setState({
      pageSize: e.target.value
    })
    this.getCip();
  }
  handlePageChange = async (page) => {
    await this.setState({ summaryPage: page });
    this.getCip();
  }

  goToAddParticapant = (row) => {
    let state = {
      summaryPage: this.state.summaryPage,
      pageSize: this.state.pageSize,
      filter: this.state.filter
    }
    this.props.goToAddParticapant(row, state, 'CipSummaryList');
  }

  goToCipAttendeeList = (row) => {
    this.props.goToCipAttendeeList(row);
  }


  openCipSummaryListFilter = () => {
    this.setState({
      openCipSummaryListFilter: !this.state.openCipSummaryListFilter
    })
  }
  closeSidebar = () => {
    this.setState({
      openCipSummaryListFilter: false
    })
  }
  opencancelCip = (row) => {
    this.refs.refCancelCip.toggle(row);
  }
  cancelCip = (row) => {
    if (row && Object.keys(row).length > 0) {
      let reqData = {
        "uuid": row.uuid
      }
      this.setState({ isLoading: true })
      CustomerIntractionServie.canncelCIP(reqData)
        .then(res => {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success",
          }).then((value) => { });
          this.getCip();
          this.setState({ isLoading: false });
        })
        .catch(e => {
          this.setState({ isLoading: false });
        });
    }
  }
  endCIP = (row, is_view_picture = false) => {
    this.refs.refCipPictureUpload.toggle(row, is_view_picture);
  }

  submitExpense = (row) => {
    let reqData = {
      cip_id: row.id,
      expenses: row.expenses
    };
    this.setState({ isLoading: true });
    CustomerIntractionServie.sendBillSettlment(reqData)
      .then((res) => {
        if (this.props.getCip) {
          this.props.getCip();
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {

        });
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  }


  sendExpenses = (row) => {
    if (row.expenses) {
      swal({
        title: "",
        text: "Are you sure want to Re-send Summary For Bill Settlement",
        icon: "warning",
        buttons: ["No", "Yes"]
      }).then(isConfirm => {
        if (isConfirm) {
          this.submitExpense(row);
        }
      });
    } else {
      this.refs.refCipExpensesIncurred.toggle(row);
    }
  }

  openExpensesIncurred = (row) => {
    this.refs.refCipExpensesIncurred.toggle(row);
  }
  render() {
    let { search } = this.state;
    return (
      <div>
        {this.state.isLoading && <CustomSpinner />}
        <div className="page-header mb-4 mt-2">CIP Summary</div>
        {this.state.openCipSummaryListFilter && (
          <Sidebar
            sidebar={
              <>
                <CipSummaryListFilter
                  applyFilter={this.applyFilter}
                  controls_filter={this.state.controls_filter}
                  is_warranty_points_redemption={this.state.is_warranty_points_redemption}
                  closeSidebar={this.closeSidebar} />
              </>
            }
            open={true}
            sidebarClassName="campaign-drawer"
            pullRight
            styles={{
              sidebar: {
                background: "white",
                height: "90%",
                top: "60px",
                width: "500px",
                borderRadius: "2px",
              },
            }}
          />
        )}
        <Row>
          <Col xl="12" md="12" xs="12">
            <Card>
              <CardBody className="d-flex-column">
                <div className="d-flex justify-content-between">
                  <div className="d-flex-row">
                    <div style={{ width: '290px' }} className="d-flex-row search-input">
                      <Input
                        className="report-search-input"
                        name="search"
                        placeholder="Search by Dealer Name,SAP ID"
                        onChange={this.handleSearchChange}
                        value={search}
                      ></Input>
                      <img className="icon-width" src="images/search.svg" alt='' style={{ marginLeft: '-25px' }} />
                    </div>
                    <div className="d-flex-row" style={{ marginLeft: "10px" }}>
                      {this.state.controls_filter &&
                        Object.keys(this.state.controls_filter).length > 0 ? (
                        <img
                          src="images/smaller-blue-dot-hi.png"
                          alt=''
                          onClick={() => this.openCipSummaryListFilter()}
                          style={{ height: '24px' }}
                          className="mt-auto mb-auto cursor-pointer"
                        />
                      ) :
                        <img
                          src="images/filter.svg"
                          alt=''
                          className="cursor-pointer"
                          onClick={() => this.openCipSummaryListFilter()}
                        />}
                    </div>
                  </div>

                  <div className="d-flex">
                    {
                      (this.state.cipSummary || []).length > 0 &&
                      <div className="d-flex mt-auto mb-auto">
                        <img src="images/download.svg" alt='' className="download-report-icon" onClick={() => this.getCip(true)} />
                        <span className="download-report cursor-pointer mt-auto mb-auto m-2" onClick={() => this.getCip(true)}>{' '} Download Report</span>
                      </div>
                    }

                    <div className="d-flex ml-3">
                      <Button
                        style={{ width: '185px' }}
                        className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                        color="warning" onClick={() => { this.props.redirectToCreateCIP(); }}>
                        Create new CIP
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <table
                    className="mb-0 table-responsive mt-3 tbl-f6f6f6"
                    bordered
                  >
                    <thead>
                      <tr style={{ backgroundColor: "#f6f6f6" }}>
                        <th></th>
                        <th>Category</th>
                        <th>Invitee Classification</th>
                        <th style={{ minWidth: '130px' }}>Type of Activity</th>
                        <th>Dealer Name</th>
                        <th>CIP Date</th>
                        <th style={{ width: '220px' }}>CIP Objective</th>
                        <th className="text-align-center">No of<br /> Invitees</th>
                        <th className="text-align-center" style={{ minWidth: '130px' }}>No of Participant <br /> & Co-attendee</th>
                        <th className="text-align-center">Expenses<br />(INR)</th>
                        <th className="text-align-center">Status</th>
                        <th className="text-align-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (this.state.cipSummary || []).map((item, i) => (
                          <>
                            <tr key={i}>
                              <td>
                                <img src={item.collapse ? "images/collapse-down.png" : "images/collapse-right.png"} alt=''
                                  className="cursor-pointer" onClick={() => this.collapseOpen(i)} />
                              </td>
                              <td>{item.category ? item.category : '--'}</td>
                              <td>{item.invitee_classification ? item.invitee_classification : '--'}</td>
                              <td>{item.type_of_activity ? item.type_of_activity : '--'}</td>
                              <td>{item.dealer_name ?
                                <div className="d-flex-column">
                                  <span>{item.dealer_name}</span>
                                  <span>({item.dealer_sap_id})</span>
                                </div>
                                : '--'}</td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {item.cip_date}
                                </Moment></td>
                              <td>{item.cip_objective}</td>
                              <td className="text-center">{item.no_of_customer_invite}</td>
                              <td onClick={() => this.goToCipAttendeeList(item)} className='link-blue text-center'
                                style={{ fontSize: '13px' }}>
                                <div className="d-flex-row ml-auto justify-content-center" id="CustomToolTip">
                                  <span>{item.total_atendee ? item.total_atendee : 0}</span>
                                </div>
                                {/* <div className="d-flex-row ml-auto justify-content-between" style={{ width: '55%' }}
                                  id="CustomToolTip">
                                  <span>{item.total_atendee ? item.total_atendee : 0}</span>
                                  {
                                    (item.total_atendee === null ||
                                      item.total_atendee < item.no_of_customer_invite) &&
                                    <div data-tooltip-position="top"
                                      data-tooltip={`Need to add ${item.no_of_customer_invite - item.total_atendee} more attendee`}
                                      style={{ padding: '0' }}>
                                      <img src={"images/Exclaimation.svg"}
                                       alt=''
                                        style={{ height: '11px' }}
                                        className="cursor-pointer" />
                                    </div>
                                  }
                                </div> */}
                              </td>
                              <td className="text-center">
                                {
                                  item.expenses ? <span>{item.expenses}</span> :
                                    '--'
                                }
                              </td>
                              <td>
                                {item.status ?
                                  <div className="d-flex-column text-align-center">
                                    <span className="font-weight-bold">{item.status_label}</span>
                                    {
                                      item.status_change_at &&
                                      <div>
                                        (<Moment format="DD/MM/YYYY">
                                          {item.status_change_at}
                                        </Moment>)</div>
                                    }
                                  </div> : '--'}
                              </td>
                              <td>
                                {/* {
                                item.total_atendee && item.total_atendee > 0 ?
                                  <>
                                    {item.is_bill_settlement ?
                                      <span className="link-blue" onClick={() => this.toggleBillSettlement(item.id, item.is_bill_settlement)}>Re-Send Summary For Bill Settlement</span> :
                                      <span className="link-blue" onClick={() => this.toggleBillSettlement(item.id, item.is_bill_settlement)}>Send Summary For Bill Settlement</span>
                                    }
                                  </> : <span className="no-data-hypen ml-auto mr-auto"></span>
                              } */}
                                <div className="d-flex justify-content-center" id="CustomToolTip">
                                  {
                                    (item.status !== "Canceled" && item.status !== "Ended") &&
                                    <span className="cursor-pointer mt-auto mb-auto" data-tooltip-position="top"
                                      data-tooltip={'Add participants'}
                                      ref={i === 0 ? this.refAddParticpant : null}
                                      id="cip-add-participant">
                                      <img src="/images/Add-Participant.png" alt='' style={{ height: '16px', width: '16px' }} onClick={() => this.goToAddParticapant(item)}
                                        id={i === 0 ? 'Add-particpant' : ''} />
                                    </span>
                                  }
                                  {
                                    item.status === "Ended" &&
                                    <div className="d-flex mt-auto mb-auto" data-tooltip-position="top"
                                      data-tooltip={`${item.expenses ? 'Re- Send' : 'Send'} Summary For Bill Settlement`}>
                                      {/* &nbsp;&nbsp; */}
                                      <img src="/images/Send Summary.png" alt='' className="cursor-pointer mt-auto mb-auto" style={{ height: '15px' }}
                                        onClick={() => this.sendExpenses(item)} />
                                    </div>
                                  }

                                  {
                                    (item.total_atendee !== 0 && item.total_atendee !== null && item.status !== "Ended") &&
                                    <div data-tooltip-position="top"
                                      data-tooltip={'END CIP'} className="end-cip-lbl">
                                      {/* &nbsp;&nbsp; */}
                                      <span className="cip-end-icon cursor-pointer mt-auto mb-auto" onClick={() => this.endCIP(item)}>END</span>
                                    </div>
                                  }
                                  {
                                    (item.image_1 || item.image_2 || item.image_3) &&
                                    <div className="d-flex mt-auto mb-auto end-cip-lbl" data-tooltip-position="top"
                                      data-tooltip={'View Photo'} >
                                      {/* &nbsp;&nbsp; */}
                                      <img src="/images/View Photos.png" alt='' className="cursor-pointer mt-auto mb-auto" style={{ height: '17px' }}
                                        onClick={() => this.endCIP(item, true)} />
                                    </div>
                                  }
                                  {
                                    (item.status !== "Canceled" && item.status !== "Ended") &&
                                    <div className="d-flex mt-auto mb-auto end-cip-lbl" data-tooltip-position="top"
                                      data-tooltip={'Cancel CIP'} >
                                      <img src="/images/delete.svg" alt='' className="cursor-pointer mt-auto mb-auto" style={{ height: '14px' }}
                                        onClick={() => this.opencancelCip(item)} />                               </div>
                                  }


                                </div>
                              </td>
                            </tr>
                            {
                              item.collapse &&
                              <tr>
                                <td colSpan="12">
                                  <Collapse
                                    isOpen={item.collapse}
                                  >
                                    <Row className="cip-collapse-row">
                                      <Col xl="3" className="d-flex-column" style={{ maxWidth: '15%' }}>
                                        <span className="cip-summary-collpase-lbl">CIP Venue</span>
                                        <span className="cip-summary-collpase-value mt-1">{item.location ? item.location : '--'}</span>
                                      </Col>
                                      <Col xl="3" className="d-flex-column" style={{ maxWidth: '20%' }}>
                                        <span className="cip-summary-collpase-lbl">CIP Conducted For</span>
                                        <span className="cip-summary-collpase-value mt-1">{item.conduct_for === 'Particular Dealer' ? 'A Dealer' : item.conduct_for ? item.conduct_for : '--'}</span>
                                      </Col>
                                      <Col xl="4" className="d-flex-column">
                                        <span className="cip-summary-collpase-lbl">Addtional Details of CIP</span>
                                        <span className="cip-summary-collpase-value mt-1">{item.cip_detail ? item.cip_detail : '--'}</span>
                                      </Col>
                                    </Row>
                                  </Collapse>
                                </td>
                              </tr>
                            }
                          </>
                        ))
                      }
                    </tbody>
                  </table>
                  {
                    (this.state.isDisplayUncontrolledPopover &&
                      <UncontrolledPopover
                        placement={'right-start'}
                        hideArrow={false}
                        isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'Add-particpant' && (this.state.cipSummary[0].total_atendee === 0 || this.state.cipSummary[0].total_atendee === '0')}
                        fade={true}
                        target={'Add-particpant'}
                        trigger="focus"
                        className='common-popover'
                      >
                        <div ref={r => this.dealerPopOver = r}>
                          <PopoverHeader className="popover-title d-flex justify-content-between">
                            <span>Help Guide</span>
                            <span>1/1</span>
                          </PopoverHeader>
                          <PopoverBody className="d-flex-column">
                            <span className="popover-lbl mt-2">Add Participat</span>
                            <span className="popover-lbl-value mt-2">
                              Click here to add participant
                            </span>
                            <br />
                            <div className="d-flex-row justify-content-end mb-2">

                              <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                onClick={() => this.setState({
                                  tooltipOpen: false,
                                })}>Close</span>
                            </div>
                          </PopoverBody>
                        </div>
                      </UncontrolledPopover>)
                  }
                  {
                    (this.state.cipSummary || []).length <= 0 &&
                    <div className="no-data-without-border mt-3">
                      <span className="m-auto">
                        There are no CIP created new yet. To create a new CIP click on "Create New CIP" button
                      </span>
                    </div>

                  }
                  {(this.state.totalSummary > 1) &&
                    <>
                      <div
                        className="d-flex-row"
                        style={{ marginTop: "10px", justifyContent: "flex-end" }}
                      >
                        <span className="Rows-per-page">Rows per page:</span>
                        <select
                          className="form-control rows-per-page-select"
                          name="pageSize"
                          value={this.state.pageSize}
                          onChange={e => this.pageChange(e)}
                          style={{ width: "65px" }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="25">25</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                        </select>
                        &nbsp;&nbsp;
                        <Pagination
                          margin={2}
                          page={this.state.summaryPage}
                          count={this.state.totalSummaryPages}
                          pageSize={10}
                          totalRecords={this.state.totalSummary}
                          onPageChange={this.handlePageChange}
                        ></Pagination>
                      </div>
                    </>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CancelCip ref="refCancelCip"
          cancelCip={this.cancelCip} />

        <CipPictureUpload
          getCip={this.getCip}
          openExpensesIncurred={this.openExpensesIncurred}
          ref="refCipPictureUpload" />

        <CipExpensesIncurred
          getCip={this.getCip}
          ref="refCipExpensesIncurred" />
      </div>
    );
  }
}

export default CipSummaryList;