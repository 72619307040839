import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Validation from "../../services/Validation";
import { messages } from "../../constants/constant.message";
import Storage from "../../services/Storage";
import ProposalSerivice from "../../services/ProposalSerivice";
import { Roles } from "../../constants/constant.role";

class BusinessAnalysisForm extends Component {
  state = {};
  counterMonthlyIndexes = {
    TotalCounter: 0,
    PresentOfftake: 1,
    CounterShare: 2,
    ProposedBusiness: 3,
  };
  constructor(props) {
    super(props);
    this.state = {
      businessAnalysis: props.businessAnalysis,
      userDetail: Storage.getUserDetail(),
      isEdit: false,
      isEnableEdit: true,
      isDisplayEditBtn: false,
      outlet_type: "",
    };
    this.state.businessAnalysis.controls.counter_monthly.forEach(
      (element, i) => {
        if (element.type === "Total Counter") {
          this.counterMonthlyIndexes.TotalCounter = i;
        } else if (element.type === "Present Offtake") {
          this.counterMonthlyIndexes.PresentOfftake = i;
        } else if (element.type === "Counter Share") {
          this.counterMonthlyIndexes.CounterShare = i;
        } else if (element.type === "Proposed Business") {
          this.counterMonthlyIndexes.ProposedBusiness = i;
        }
      }
    );

    if (
      this.state.userDetail &&
      this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE &&
      window.location.hash !== "#/proposal-details"
    ) {
      this.state.isDisplayEditBtn = true;
      this.state.isEnableEdit = false;
    }
  }

  setProps = () => {
    let { businessAnalysis } = this.state;
    businessAnalysis = this.props.businessAnalysis;
    businessAnalysis.controls.counter_monthly.forEach((element, i) => {
      if (element.type === "Total Counter") {
        this.counterMonthlyIndexes.TotalCounter = i;
      } else if (element.type === "Present Offtake") {
        this.counterMonthlyIndexes.PresentOfftake = i;
      } else if (element.type === "Counter Share") {
        this.counterMonthlyIndexes.CounterShare = i;
      } else if (element.type === "Proposed Business") {
        this.counterMonthlyIndexes.ProposedBusiness = i;
      }
    });
    this.setState({
      businessAnalysis,
    });
  };
  handleValidation = (firstTime = true, isSubmit = false) => {
    const { outlet_type } = this.state;
    let { controls, isFormValid } = this.state.businessAnalysis;
    let {
      ownership_type,
      proprietor_name,
      counter_monthly,
      sap_id,
      lease_aggrement,
      site_picture,
      total_counter_total_year_potential,
      present_offtake_total_year_turnover,
      total_counter_total_h1_potential,
      present_offtake_total_h1_turnover,
      proposed_business_h1_turnover,
      proposed_business_total_year_turnover,
    } = controls;

    if (ownership_type.touched === true || isSubmit) {
      ownership_type = Validation.notNullValidator(ownership_type);
      ownership_type.valid = !ownership_type.nullValue;
      if (ownership_type.valid === false) {
        ownership_type.showErrorMsg = true;
      } else {
        ownership_type.showErrorMsg = false;
      }
    }

    if (proprietor_name.touched === true || isSubmit) {
      proprietor_name = Validation.notNullValidator(proprietor_name);
      proprietor_name.valid = !proprietor_name.nullValue;
      if (proprietor_name.valid === false) {
        proprietor_name.showErrorMsg = true;
      } else {
        proprietor_name.showErrorMsg = false;
      }
    }

    sap_id.showErrorMsg = false;
    sap_id.valid = true;
    if (
      (sap_id.touched === true || isSubmit) &&
      (controls.is_jk_authorized_dealer.value === "true" ||
        controls.is_jk_authorized_dealer.value === true)
    ) {
      sap_id = Validation.notNullValidator(sap_id);
      if (!sap_id.nullValue) {
        sap_id = Validation.maxLengthValidator(sap_id, 8);
        sap_id = Validation.minLengthValidator(sap_id, 6);
      }
      sap_id.valid =
        !sap_id.nullValue && !sap_id.invalidLength && !sap_id.invalidMinLength;
      if (sap_id.nullValue) {
        sap_id.showErrorMsg = true;
      } else {
        sap_id.showErrorMsg = false;
      }
    }
    lease_aggrement.valid = true;
    lease_aggrement.showErrorMsg = false;
    if (
      (lease_aggrement.touched === true || isSubmit) &&
      (controls.is_premised_owner.value === "false" ||
        controls.is_premised_owner.value === false)
    ) {
      lease_aggrement = Validation.notNullValidator(lease_aggrement);
      lease_aggrement.valid = !lease_aggrement.nullValue;
      if (lease_aggrement.valid === false) {
        lease_aggrement.showErrorMsg = true;
      } else {
        lease_aggrement.showErrorMsg = false;
      }
    }

    if (site_picture.touched === true || isSubmit) {
      site_picture = Validation.notNullValidator(site_picture);
      site_picture.valid = !site_picture.nullValue;
      if (site_picture.valid === false) {
        site_picture.showErrorMsg = true;
      } else {
        site_picture.showErrorMsg = false;
      }
    }

    let counter = ["car", "lcv", "scv", "two_wheel"];

    counter.forEach((element, i) => {
      if (element !== "lcv" && element !== "scv" && element !== "two_wheel") {
        if (
          counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][element]
            .touched === true ||
          isSubmit
        ) {
          counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
            element
          ] = Validation.notNullValidator(
            counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
            element
            ]
          );
          counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
            element
          ].valid = !counter_monthly[
            this.counterMonthlyIndexes.ProposedBusiness
          ][element].nullValue;

          if (
            counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
              element
            ].value === ""
          ) {
            counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
              element
            ].valid = false;
          }
          if (
            counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
              element
            ].valid === false
          ) {
            counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
              element
            ].showErrorMsg = true;
          } else {
            counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
              element
            ].showErrorMsg = false;
          }
        }
        if (outlet_type === "Farm Xpress Wheel") {
          counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
            element
          ].showErrorMsg = false;
          counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
            element
          ].valid = true;
        }
      } else {
        counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
          element
        ].valid = true;
        counter_monthly[this.counterMonthlyIndexes.ProposedBusiness][
          element
        ].showErrorMsg = false;
      }
    });

    counter.forEach((element, i) => {
      if (
        counter_monthly[this.counterMonthlyIndexes.TotalCounter][element]
          .touched === true ||
        isSubmit
      ) {
        counter_monthly[this.counterMonthlyIndexes.TotalCounter][
          element
        ] = Validation.notNullValidator(
          counter_monthly[this.counterMonthlyIndexes.TotalCounter][element]
        );
        counter_monthly[this.counterMonthlyIndexes.TotalCounter][
          element
        ].valid = !counter_monthly[this.counterMonthlyIndexes.TotalCounter][
          element
        ].nullValue;
        if (
          counter_monthly[this.counterMonthlyIndexes.TotalCounter][element]
            .value === ""
        ) {
          counter_monthly[this.counterMonthlyIndexes.TotalCounter][
            element
          ].valid = false;
        }
        if (
          counter_monthly[this.counterMonthlyIndexes.TotalCounter][element]
            .valid === false
        ) {
          counter_monthly[this.counterMonthlyIndexes.TotalCounter][
            element
          ].showErrorMsg = true;
        } else {
          counter_monthly[this.counterMonthlyIndexes.TotalCounter][
            element
          ].showErrorMsg = false;
        }

        if (outlet_type === "Farm Xpress Wheel") {
          counter_monthly[this.counterMonthlyIndexes.TotalCounter][
            element
          ].showErrorMsg = false;
          counter_monthly[this.counterMonthlyIndexes.TotalCounter][
            element
          ].valid = true;
        }
      }
    });

    counter.forEach((element, i) => {
      if (
        counter_monthly[this.counterMonthlyIndexes.PresentOfftake][element]
          .touched === true ||
        isSubmit
      ) {
        if (element !== "lcv" && element !== "scv" && element !== "two_wheel") {
          counter_monthly[this.counterMonthlyIndexes.PresentOfftake][
            element
          ] = Validation.notNullValidator(
            counter_monthly[this.counterMonthlyIndexes.PresentOfftake][element]
          );
          counter_monthly[this.counterMonthlyIndexes.PresentOfftake][
            element
          ].valid = !counter_monthly[this.counterMonthlyIndexes.PresentOfftake][
            element
          ].nullValue;
          if (
            counter_monthly[this.counterMonthlyIndexes.PresentOfftake][element]
              .value === ""
          ) {
            counter_monthly[this.counterMonthlyIndexes.PresentOfftake][
              element
            ].valid = false;
          }
          if (
            counter_monthly[this.counterMonthlyIndexes.PresentOfftake][element]
              .valid === false
          ) {
            counter_monthly[this.counterMonthlyIndexes.PresentOfftake][
              element
            ].showErrorMsg = true;
          } else {
            counter_monthly[this.counterMonthlyIndexes.PresentOfftake][
              element
            ].showErrorMsg = false;
          }
          if (outlet_type === "Farm Xpress Wheel") {
            counter_monthly[this.counterMonthlyIndexes.PresentOfftake][
              element
            ].showErrorMsg = false;
            counter_monthly[this.counterMonthlyIndexes.PresentOfftake][
              element
            ].valid = true;
          }
        }
      }
    });

    present_offtake_total_year_turnover.valid = true;
    if (
      (present_offtake_total_year_turnover.touched === true || isSubmit) &&
      outlet_type === "Farm Xpress Wheel"
    ) {
      present_offtake_total_year_turnover = Validation.notNullValidator(
        present_offtake_total_year_turnover
      );
      present_offtake_total_year_turnover.valid = !present_offtake_total_year_turnover.nullValue;
      if (present_offtake_total_year_turnover.valid === false) {
        present_offtake_total_year_turnover.showErrorMsg = true;
      } else {
        present_offtake_total_year_turnover.showErrorMsg = false;
      }
    }

    total_counter_total_year_potential.valid = true;
    if (
      (total_counter_total_year_potential.touched === true || isSubmit) &&
      outlet_type === "Farm Xpress Wheel"
    ) {
      total_counter_total_year_potential = Validation.notNullValidator(
        total_counter_total_year_potential
      );
      total_counter_total_year_potential.valid = !total_counter_total_year_potential.nullValue;
      if (total_counter_total_year_potential.valid === false) {
        total_counter_total_year_potential.showErrorMsg = true;
      } else {
        total_counter_total_year_potential.showErrorMsg = false;
      }
    }

    total_counter_total_h1_potential.valid = true;
    if (
      (total_counter_total_h1_potential.touched === true || isSubmit) &&
      outlet_type === "Farm Xpress Wheel"
    ) {
      total_counter_total_h1_potential = Validation.notNullValidator(
        total_counter_total_h1_potential
      );
      total_counter_total_h1_potential.valid = !total_counter_total_h1_potential.nullValue;
      if (total_counter_total_h1_potential.valid === false) {
        total_counter_total_h1_potential.showErrorMsg = true;
      } else {
        total_counter_total_h1_potential.showErrorMsg = false;
      }
    }

    present_offtake_total_h1_turnover.valid = true;
    if (
      (present_offtake_total_h1_turnover.touched === true || isSubmit) &&
      outlet_type === "Farm Xpress Wheel"
    ) {
      present_offtake_total_h1_turnover = Validation.notNullValidator(
        present_offtake_total_h1_turnover
      );
      present_offtake_total_h1_turnover.valid = !present_offtake_total_h1_turnover.nullValue;
      if (present_offtake_total_h1_turnover.valid === false) {
        present_offtake_total_h1_turnover.showErrorMsg = true;
      } else {
        present_offtake_total_h1_turnover.showErrorMsg = false;
      }
    }

    proposed_business_h1_turnover.valid = true;
    if (
      (proposed_business_h1_turnover.touched === true || isSubmit) &&
      outlet_type === "Farm Xpress Wheel"
    ) {
      proposed_business_h1_turnover = Validation.notNullValidator(
        proposed_business_h1_turnover
      );
      proposed_business_h1_turnover.valid = !proposed_business_h1_turnover.nullValue;
      if (proposed_business_h1_turnover.valid === false) {
        proposed_business_h1_turnover.showErrorMsg = true;
      } else {
        proposed_business_h1_turnover.showErrorMsg = false;
      }
    }

    proposed_business_total_year_turnover.valid = true;
    if (
      (proposed_business_total_year_turnover.touched === true || isSubmit) &&
      outlet_type === "Farm Xpress Wheel"
    ) {
      proposed_business_total_year_turnover = Validation.notNullValidator(
        proposed_business_total_year_turnover
      );
      proposed_business_total_year_turnover.valid = !proposed_business_total_year_turnover.nullValue;
      if (proposed_business_total_year_turnover.valid === false) {
        proposed_business_total_year_turnover.showErrorMsg = true;
      } else {
        proposed_business_total_year_turnover.showErrorMsg = false;
      }
    }

    if (
      ownership_type.valid === true &&
      proprietor_name.valid === true &&
      lease_aggrement.valid === true &&
      site_picture.valid === true &&
      counter_monthly[this.counterMonthlyIndexes.ProposedBusiness]["car"]
        .valid === true &&
      counter_monthly[this.counterMonthlyIndexes.ProposedBusiness]["lcv"]
        .valid === true &&
      counter_monthly[this.counterMonthlyIndexes.TotalCounter]["car"].valid ===
      true &&
      counter_monthly[this.counterMonthlyIndexes.TotalCounter]["lcv"].valid ===
      true &&
      counter_monthly[this.counterMonthlyIndexes.PresentOfftake]["car"]
        .valid === true &&
      present_offtake_total_h1_turnover.valid === true &&
      proposed_business_h1_turnover.valid === true &&
      proposed_business_total_year_turnover.valid === true &&
      present_offtake_total_year_turnover.valid === true &&
      total_counter_total_h1_potential.valid === true &&
      total_counter_total_year_potential.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ businessAnalysis: { controls, isFormValid } });
    return isFormValid;
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let { businessAnalysis } = this.state;
    let { controls } = businessAnalysis;
    if (
      controlName === "shutters_count" ||
      controlName === "counters" ||
      controlName === "sap_id"
    ) {
      if (!isNaN(controlValue)) {
        controls[controlName].value = controlValue;
        controls[controlName].touched = true;
      }
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }

    if (controlName === "is_jk_authorized_dealer") {
      if (
        controls.is_jk_authorized_dealer.value === "false" ||
        controls.is_jk_authorized_dealer.value === false
      ) {
        controls.sap_id.value = "";
        controls.sap_id.showErrorMsg = false;
        controls.sap_id.invalidLength = false;
        controls.sap_id.invalidMinLength = false;
        controls.sap_id.valid = true;
      }
    }

    if (controlName === "existing_shop_compitition") {
      if (
        controls.existing_shop_compitition.value === "false" ||
        controls.existing_shop_compitition.value === false
      ) {
        controls.existing_show_compitition_name.value = "";
      }
    }
    this.setState({ businessAnalysis });
    this.handleValidation();
  };

  handleInputChangePotential = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { businessAnalysis } = this.state;
    const { controls } = businessAnalysis;
    if (!isNaN(controlValue)) {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    if (
      controlName === "total_counter_total_year_potential" ||
      controlName === "present_offtake_total_year_turnover"
    ) {
      if (
        controls.total_counter_total_year_potential.value &&
        controls.present_offtake_total_year_turnover.value
      ) {
        controls.counter_share_total_year_turnover.value =
          (controls.present_offtake_total_year_turnover.value * 100) /
          controls.total_counter_total_year_potential.value;
        controls.counter_share_total_year_turnover.value = Math.ceil(
          controls.counter_share_total_year_turnover.value
        );
      } else {
        controls.counter_share_total_year_turnover.value = "";
      }
    }

    if (
      controlName === "total_counter_total_h1_potential" ||
      controlName === "present_offtake_total_h1_turnover"
    ) {
      if (
        controls.total_counter_total_h1_potential.value &&
        controls.present_offtake_total_h1_turnover.value
      ) {
        controls.counter_share_total_h1_turnover.value =
          (controls.present_offtake_total_h1_turnover.value * 100) /
          controls.total_counter_total_h1_potential.value;
        controls.counter_share_total_h1_turnover.value = Math.ceil(
          controls.counter_share_total_h1_turnover.value
        );
      } else {
        controls.counter_share_total_h1_turnover.value = "";
      }
    }
    this.setState({
      businessAnalysis,
    });
    this.handleValidation();
  };

  handleSASInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state.businessAnalysis;
    if (!isNaN(controlValue)) {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    this.setState({ controls });
    this.handleValidation();
  };

  handleInputChangeCounter = (e, index) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state.businessAnalysis;
    const { counter_monthly } = controls;

    if (
      !isNaN(controlValue) ||
      controlName === "remarks" ||
      controlName === "existing_shop_compitition" ||
      controlName === "existing_show_compitition_name" ||
      controlName === "is_agree_exclusive_jk"
    ) {
      counter_monthly[index][controlName].value = +controlValue;
      counter_monthly[index][controlName].touched = true;
      if (index === 0 || index === 1) {
        if (
          counter_monthly[0][controlName].value &&
          counter_monthly[1][controlName].value &&
          +counter_monthly[0][controlName].value !== 0 &&
          +counter_monthly[1][controlName].value !== 0
        ) {
          counter_monthly[2][controlName].value =
            +(counter_monthly[1][controlName].value * 100) /
            +counter_monthly[0][controlName].value;
        } else {
          counter_monthly[2][controlName].value = "";
        }
      }

      // if ((index === 0 || index === 1) && controlName === "lcv") {
      //   if (
      //     counter_monthly[0][controlName].value &&
      //     counter_monthly[1][controlName].value
      //   ) {
      //     counter_monthly[2][controlName].value =
      //       (counter_monthly[1][controlName].value * 100) /
      //       counter_monthly[0][controlName].value;
      //   } else {
      //     counter_monthly[2][controlName].value = "";
      //   }
      // }

      // if ((index === 0 || index === 1) && controlName === "scv") {
      //   if (
      //     counter_monthly[0][controlName].value &&
      //     counter_monthly[1][controlName].value
      //   ) {
      //     counter_monthly[2][controlName].value =
      //       (counter_monthly[1][controlName].value * 100) /
      //       counter_monthly[0][controlName].value;
      //   } else {
      //     counter_monthly[2][controlName].value = "";
      //   }
      // }
      this.setState({ controls });
      //if (controlName === 'car' && index === this.counterMonthlyIndexes.ProposedBusiness) {
      this.handleValidation();
      //}
    }
  };

  enableEdit = (e) => {
    let { isEnableEdit } = this.state;
    if (isEnableEdit) {
      this.props.editDeatil(e, "submit");
    }
    isEnableEdit = !isEnableEdit;
    this.setState({
      isEnableEdit,
    });
  };

  enableEditCounter = (controlName, index) => {
    const { controls } = this.state.businessAnalysis;
    const { counter_monthly } = controls;
    counter_monthly[index][controlName].isDisable = !counter_monthly[index][
      controlName
    ].isDisable;
    this.setState({
      controls,
    });
  };

  uploadFileFormData = async (file, type) => {
    try {
      this.props.isLoadingSet(true);
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      this.props.isLoadingSet(false);
      if (data && data.data) {
        const { controls } = this.state.businessAnalysis;
        controls[type].value = data.data;
        controls[type].touched = true;
        controls[type].showErrorMsg = false;
        controls[type].valid = true;
        this.handleValidation();
      }
      // let { isLoading } = this.state;
      // isLoading = false;
      // this.setState({ isLoading });
    } catch (error) {
      this.props.isLoadingSet(false);
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    const { controls } = this.state.businessAnalysis;
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      // if (event.target.files[0].size > 5000000) {
      //   controls[type].value = "";
      //   controls[type].touched = true;
      //   event.target.value = null;
      //   this.handleValidation();
      //   ModalService.openAlert('', 'Document size exceeds maximum limit is 5MB.', 'error');
      //   return
      // }
      this.uploadFileFormData(file, type);
    } else {
      controls[type].value = "";
    }
    this.setState({
      controls,
    });
  };

  closeEdit = () => {
    //let { isEnableEdit } = this.state;
    this.setState({
      isEnableEdit: false,
    });
    this.props.setDetail(true);
  };
  resetDetail = () => {
    this.setState({
      businessAnalysis: this.props.businessAnalysis,
    });
  };
  setOutletType = (outlet_type_name) => {
    let { outlet_type, businessAnalysis } = this.state;
    let { controls } = businessAnalysis;
    if (outlet_type_name === "Farm Xpress Wheel") {
      controls.counter_monthly.forEach((element) => {
        if (element.car) {
          element.car.value = "";
          element.car.touched = false;
        }
        if (element.lcv) {
          element.lcv.value = "";
          element.lcv.touched = false;
        }
        if (element.scv) {
          element.scv.value = "";
          element.scv.touched = false;
        }
      });
    } else {
      controls.proposed_business_h1_turnover.value = "";
      controls.proposed_business_h1_turnover.touched = false;
      controls.proposed_business_total_year_turnover.value = "";
      controls.proposed_business_total_year_turnover.touched = false;
      controls.present_offtake_total_h1_turnover.value = "";
      controls.present_offtake_total_h1_turnover.touched = false;
      controls.present_offtake_total_year_turnover.value = "";
      controls.present_offtake_total_year_turnover.touched = false;
      controls.total_counter_total_h1_potential.value = "";
      controls.total_counter_total_h1_potential.touched = false;
      controls.total_counter_total_year_potential.value = "";
      controls.total_counter_total_year_potential.touched = false;
    }
    outlet_type = outlet_type_name;
    this.setState(
      {
        outlet_type,
        businessAnalysis,
      },
      () => {
        //  this.calculateDealerShareAmmount();
      }
    );
  };
  render() {
    const { getProposalDetail } = this.props;
    const { controls } = this.state.businessAnalysis;

    const { isEdit, isEnableEdit, outlet_type } = this.state;
    const { counter_monthly } = controls;
    return (
      <>
        <Card id="form-business-detail">
          <CardBody>
            {/* Business Analysis */}
            <Row className="dealership-details-title-wrapp">
              <Col xl="6" md="6" xs="6">
                <b>Business Analysis</b>
              </Col>
              <div>
                {this.state.isDisplayEditBtn &&
                  (this.state.isEnableEdit ? (
                    <>
                      <span
                        className="hyper-link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.enableEdit(e)}
                      >
                        Save
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="hyper-link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.enableEdit(e)}
                      >
                        Edit
                      </span>
                    </>
                  ))}
                {this.state.isDisplayEditBtn && this.state.isEnableEdit && (
                  <>
                    &nbsp;&nbsp;
                    <span
                      className="hyper-link"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.closeEdit()}
                    >
                      Cancel
                    </span>
                  </>
                )}
                &nbsp;&nbsp;
                {/* <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.goToPrevPage()}>Back</Button> */}
              </div>
            </Row>
            <hr />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Ownership Type(Sole proprietorship/ Partnership/ Pvt. Ltd.)
                    <span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="ownership_type"
                      value={controls.ownership_type.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value="" selected disabled>
                        Select Ownership Type
                      </option>
                      <option value="Sole proprietorship">
                        Sole proprietorship
                      </option>
                      <option value="Partnership">Partnership</option>
                      <option value="Pvt. Ltd.">Pvt. Ltd.</option>
                      <option value="Other">Other</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("ownership_type")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.ownership_type.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.OWNSHIP_TYPE}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">
                    Name of Proprietor/ Managing Partner/ Managing Director{" "}
                    <span className="required-field">*</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="Type here"
                    name="proprietor_name"
                    value={controls.proprietor_name.value}
                    onChange={(e) => this.handleInputChange(e)}
                    disabled={!isEnableEdit}
                  />
                  {controls.proprietor_name.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.PROPRIETOR}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup className="form-group-space-between">
                  <Label for="gst">
                    Currently operating in tyre Business(Y/N)
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                      name="is_in_tyre_business"
                      value={controls.is_in_tyre_business.value}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("is_in_tyre_business")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup className="form-group-space-between">
                  <Label for="pan">No. of Counters</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      placeholder="Type here"
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                      name="counters"
                      value={controls.counters.value}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("counters")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="pan">Authorized dealer of JK tyre?(Y/N)</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="is_jk_authorized_dealer"
                      value={controls.is_jk_authorized_dealer.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="gst">SAP Code</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      readOnly={
                        controls.is_jk_authorized_dealer.value === "false" ||
                          controls.is_jk_authorized_dealer.value === false
                          ? true
                          : false
                      }
                      onChange={(e) => this.handleInputChange(e)}
                      name="sap_id"
                      value={controls.sap_id.value}
                      disabled={!isEnableEdit}
                      placeholder="Type here"
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEdit("is_jk_authorized_dealer")
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.sap_id.showErrorMsg && (
                    <div className="error">*Please enter sap code.</div>
                  )}
                  {!controls.sap_id.showErrorMsg &&
                    (controls.sap_id.invalidLength ||
                      controls.sap_id.invalidMinLength) && (
                      <div className="error">*Please enter valid sap code.</div>
                    )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>SAS Non-Truck (in Lac.)</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      onChange={(e) => this.handleSASInputChange(e)}
                      name="sas_non_truck"
                      value={controls.sas_non_truck.value}
                      disabled={!isEnableEdit}
                      placeholder="Type here"
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("sas_non_truck")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {/* {email.showErrorMsg && email.invalidEmail && <div className="error">* {INVALID_EMAIL}</div>}
                  {email.showErrorMsg && email.nullValue && <div className="error">* {EMPTY_EMAIL}</div>} */}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">SAS Truck (in Lac.)</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      onChange={(e) => this.handleSASInputChange(e)}
                      name="sas_truck"
                      value={controls.sas_truck.value}
                      disabled={!isEnableEdit}
                      placeholder="Type here"
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("sas_truck")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="pan">SAS Total (in Lac.)</Label>
                  <div className="d-flex">
                    <Input
                      readOnly="true"
                      type="text"
                      name="dealershipName"
                      onChange={(e) => this.handleInputChange(e)}
                      value={
                        +controls.sas_truck.value +
                        +controls.sas_non_truck.value
                      }
                      disabled={!isEnableEdit}
                      placeholder="Type here"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />

        {/* Total Counter Potential of PCR (Monthly) */}
        <Card>
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>
                  {outlet_type !== "Farm Xpress Wheel"
                    ? "Total Counter Potential of PCR (Monthly)"
                    : "Total Counter Potential of FXW"}
                </b>
              </Col>
            </Row>
            <hr />
            {outlet_type !== "Farm Xpress Wheel" ? (
              <>
                <Row>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>
                        CAR+UV Radial<span className="required-field">*</span>
                      </Label>
                      <div className="d-flex">
                        <Input
                          name="car"
                          type="text"
                          value={
                            counter_monthly[
                              this.counterMonthlyIndexes.TotalCounter
                            ].car.value
                          }
                          disabled={!isEnableEdit}
                          onChange={(e) =>
                            this.handleInputChangeCounter(
                              e,
                              this.counterMonthlyIndexes.TotalCounter
                            )
                          }
                        />
                        {isEdit && (
                          <img
                            onClick={() =>
                              this.enableEditCounter(
                                "car",
                                this.counterMonthlyIndexes.TotalCounter
                              )
                            }
                            style={{ height: "14px" }}
                            className="cursor-pointer ml-2 mt-2"
                            src="images/edit.svg"
                            alt=''
                          />
                        )}
                      </div>
                      {counter_monthly[this.counterMonthlyIndexes.TotalCounter]
                        .car.showErrorMsg && (
                          <div className="error">
                            *Please enter CAR+UV radial.
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>2 W</Label>
                      <div className="d-flex">
                        <Input
                          type="text"
                          name="two_wheel"
                          value={
                            counter_monthly[
                              this.counterMonthlyIndexes.TotalCounter
                            ].two_wheel.value
                          }
                          disabled={!isEnableEdit}
                          onChange={(e) =>
                            this.handleInputChangeCounter(
                              e,
                              this.counterMonthlyIndexes.TotalCounter
                            )
                          }
                        />
                        {isEdit && (
                          <img
                            onClick={() =>
                              this.enableEditCounter(
                                "two_wheel",
                                this.counterMonthlyIndexes.TotalCounter
                              )
                            }
                            style={{ height: "14px" }}
                            className="cursor-pointer ml-2 mt-2"
                            src="images/edit.svg"
                            alt=''
                          />
                        )}
                      </div>
                      {/* {counter_monthly[this.counterMonthlyIndexes.TotalCounter]
                        .two_wheel.showErrorMsg && (
                        <div className="error">*Please enter 2 W</div>
                      )} */}
                    </FormGroup>
                  </Col>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>
                        LCV+SCV (Bias)<span className="required-field">*</span>
                      </Label>
                      <div className="d-flex">
                        <Input
                          type="text"
                          name="lcv"
                          value={
                            counter_monthly[
                              this.counterMonthlyIndexes.TotalCounter
                            ].lcv.value
                          }
                          disabled={!isEnableEdit}
                          onChange={(e) =>
                            this.handleInputChangeCounter(
                              e,
                              this.counterMonthlyIndexes.TotalCounter
                            )
                          }
                        />
                        {isEdit && (
                          <img
                            onClick={() =>
                              this.enableEditCounter(
                                "lcv",
                                this.counterMonthlyIndexes.TotalCounter
                              )
                            }
                            style={{ height: "14px" }}
                            className="cursor-pointer ml-2 mt-2"
                            src="images/edit.svg"
                            alt=''
                          />
                        )}
                      </div>
                      {counter_monthly[this.counterMonthlyIndexes.TotalCounter]
                        .lcv.showErrorMsg && (
                          <div className="error">*Please enter lcv(b).</div>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Total Year Potential
                      <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        name="total_counter_total_year_potential"
                        type="text"
                        value={
                          controls.total_counter_total_year_potential.value
                        }
                        disabled={!isEnableEdit}
                        onChange={(e) => this.handleInputChangePotential(e)}
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEdit(
                              "total_counter_total_year_potential"
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {controls.total_counter_total_year_potential
                      .showErrorMsg && (
                        <div className="error">
                          *Please enter total year potentail.
                        </div>
                      )}
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Total H1 Potential
                      <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        name="total_counter_total_h1_potential"
                        type="text"
                        value={controls.total_counter_total_h1_potential.value}
                        disabled={!isEnableEdit}
                        onChange={(e) => this.handleInputChangePotential(e)}
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEdit("total_counter_total_h1_potential")
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {controls.total_counter_total_h1_potential.showErrorMsg && (
                      <div className="error">
                        *Please enter total h1 potentail.
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            )}
            <br />
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>
                  {outlet_type === "Farm Xpress Wheel"
                    ? "Present Offtake- JK Tyre-(Monthly)"
                    : "Present Offtake- JK Tyre"}
                </b>
              </Col>
            </Row>
            <hr />
            {outlet_type === "Farm Xpress Wheel" ? (
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Total Yearly Turnover
                      <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        name="present_offtake_total_year_turnover"
                        type="text"
                        value={
                          controls.present_offtake_total_year_turnover.value
                        }
                        disabled={!isEnableEdit}
                        onChange={(e) => this.handleInputChangePotential(e)}
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEdit(
                              "present_offtake_total_year_turnover"
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {controls.present_offtake_total_year_turnover
                      .showErrorMsg && (
                        <div className="error">
                          *Please enter total year turnover.
                        </div>
                      )}
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Total H1 Turnover
                      <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        name="present_offtake_total_h1_turnover"
                        type="text"
                        value={controls.present_offtake_total_h1_turnover.value}
                        disabled={!isEnableEdit}
                        onChange={(e) => this.handleInputChangePotential(e)}
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEdit("present_offtake_total_h1_turnover")
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {controls.present_offtake_total_h1_turnover
                      .showErrorMsg && (
                        <div className="error">
                          *Please enter total h1 turnover.
                        </div>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      CAR+UV Radial<span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="car"
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.PresentOfftake
                          ].car.value
                        }
                        disabled={!isEnableEdit}
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.PresentOfftake
                          )
                        }
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEditCounter(
                              "car",
                              this.counterMonthlyIndexes.PresentOfftake
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {counter_monthly[this.counterMonthlyIndexes.PresentOfftake]
                      .car.showErrorMsg && (
                        <div className="error">* Please enter car+uv radial.</div>
                      )}
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>2 W</Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="two_wheel"
                        disabled={!isEnableEdit}
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.PresentOfftake
                          ].two_wheel.value
                        }
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.PresentOfftake
                          )
                        }
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEditCounter(
                              "two_wheel",
                              this.counterMonthlyIndexes.PresentOfftake
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {counter_monthly[this.counterMonthlyIndexes.PresentOfftake]
                      .scv.showErrorMsg && (
                        <div className="error">* Please enter scv(b).</div>
                      )}
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>LCV/SCV (Bias)</Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="lcv"
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.PresentOfftake
                          ].lcv.value
                        }
                        disabled={!isEnableEdit}
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.PresentOfftake
                          )
                        }
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEditCounter(
                              "lcv",
                              this.counterMonthlyIndexes.PresentOfftake
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {counter_monthly[this.counterMonthlyIndexes.PresentOfftake]
                      .lcv.showErrorMsg && (
                        <div className="error">* Please enter lcv(b).</div>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            )}
            <br />
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Counter Share (%)</b>
              </Col>
            </Row>
            <hr />
            {outlet_type === "Farm Xpress Wheel" ? (
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Total Yearly Turnover
                      <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        name="counter_share_total_year_turnover"
                        type="text"
                        value={controls.counter_share_total_year_turnover.value}
                        // disabled={!isEnableEdit}
                        disabled={true}
                        onChange={(e) => this.handleInputChangePotential(e)}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Total H1 Turnover
                      <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        name="counter_share_total_h1_turnover"
                        type="text"
                        value={controls.counter_share_total_h1_turnover.value}
                        // disabled={!isEnableEdit}
                        disabled={true}
                        onChange={(e) => this.handleInputChangePotential(e)}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>CAR+UV Radial</Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="car"
                        disabled={true}
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.CounterShare
                          ].car.value
                        }
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.CounterShare
                          )
                        }
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEditCounter(
                              "car",
                              this.counterMonthlyIndexes.CounterShare
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>2 W </Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="two_wheel"
                        disabled="true"
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.CounterShare
                          ].two_wheel.value
                        }
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.CounterShare
                          )
                        }
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>LCV/SCV (Bias)</Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="lcv"
                        disabled="true"
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.CounterShare
                          ].lcv.value
                        }
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.CounterShare
                          )
                        }
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEditCounter(
                              "lcv",
                              this.counterMonthlyIndexes.CounterShare
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                  </FormGroup>
                </Col>

                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>Competition Brands Dealing with (PCR)</Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="compitition_brand_pcr"
                        value={controls.compitition_brand_pcr.value}
                        onChange={(e) => this.handleInputChange(e)}
                        disabled={!isEnableEdit}
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEdit("compitition_brand_pcr")
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Any existing Brand shop of Competition? (Y/N)</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="existing_shop_compitition"
                      value={controls.existing_shop_compitition.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEdit("existing_shop_compitition")
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup className="form-group-space-between">
                  <Label>If Yes, Please specify</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="existing_show_compitition_name"
                      value={controls.existing_show_compitition_name.value}
                      disabled={
                        controls.existing_shop_compitition.value === "false" ||
                        controls.existing_shop_compitition.value === false ||
                        !isEnableEdit
                      }
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEdit("existing_show_compitition_name")
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>
                  {outlet_type === "Farm Xpress Wheel"
                    ? "Proposed Business (Average per month)"
                    : "Proposed Business"}
                </b>
              </Col>
            </Row>
            <hr />
            {outlet_type === "Farm Xpress Wheel" ? (
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Total Yearly Turnover
                      <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        name="proposed_business_total_year_turnover"
                        type="text"
                        value={
                          controls.proposed_business_total_year_turnover.value
                        }
                        disabled={!isEnableEdit}
                        onChange={(e) => this.handleInputChangePotential(e)}
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEdit(
                              "proposed_business_total_year_turnover"
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {controls.proposed_business_total_year_turnover
                      .showErrorMsg && (
                        <div className="error">
                          *Please enter total year turnover.
                        </div>
                      )}
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Total H1 Potential
                      <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <Input
                        name="proposed_business_h1_turnover"
                        type="text"
                        value={controls.proposed_business_h1_turnover.value}
                        disabled={!isEnableEdit}
                        onChange={(e) => this.handleInputChangePotential(e)}
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEdit("proposed_business_h1_turnover")
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {controls.proposed_business_h1_turnover.showErrorMsg && (
                      <div className="error">
                        *Please enter total h1 turnover.
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      CAR+UV Radial<label className="required-star">*</label>
                    </Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="car"
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.ProposedBusiness
                          ].car.value
                        }
                        disabled={!isEnableEdit}
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.ProposedBusiness
                          )
                        }
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEditCounter(
                              "car",
                              this.counterMonthlyIndexes.ProposedBusiness
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {counter_monthly[
                      this.counterMonthlyIndexes.ProposedBusiness
                    ].car.showErrorMsg && (
                        <div className="error">*Please enter CAR+UV radial.</div>
                      )}
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>2 W</Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="two_wheel"
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.ProposedBusiness
                          ].two_wheel.value
                        }
                        disabled={!isEnableEdit}
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.ProposedBusiness
                          )
                        }
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEditCounter(
                              "two_wheel",
                              this.counterMonthlyIndexes.ProposedBusiness
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {counter_monthly[
                      this.counterMonthlyIndexes.ProposedBusiness
                    ].scv.showErrorMsg && (
                        <div className="error">*Please enter scv.</div>
                      )}
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>LCV/SCV (Bias)</Label>
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="lcv"
                        value={
                          counter_monthly[
                            this.counterMonthlyIndexes.ProposedBusiness
                          ].lcv.value
                        }
                        disabled={!isEnableEdit}
                        onChange={(e) =>
                          this.handleInputChangeCounter(
                            e,
                            this.counterMonthlyIndexes.ProposedBusiness
                          )
                        }
                      />
                      {isEdit && (
                        <img
                          onClick={() =>
                            this.enableEditCounter(
                              "lcv",
                              this.counterMonthlyIndexes.ProposedBusiness
                            )
                          }
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {counter_monthly[
                      this.counterMonthlyIndexes.ProposedBusiness
                    ].lcv.showErrorMsg && (
                        <div className="error">*Please enter lcv.</div>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label>
                    The Franchise agrees to maintain Exclusive sale & branding
                    of JK Tyre at the counter (Yes/No)
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="is_agree_exclusive_jk"
                      value={controls.is_agree_exclusive_jk.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("is_agree_exclusive_jk")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup className="form-group-space-between">
                  <Label>Remarks</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="remarks"
                      value={controls.remarks.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("remarks")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody>
            {/* Dealership Details */}
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Specifications of Proposed Showroom</b>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xl="5" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Premises Owned / Rented- If Rented please attach copy of
                    Rent agreement (Minimum 5 year agreement required)
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                      name="is_premised_owner"
                      value={controls.is_premised_owner.value}
                    >
                      <option value={true}>Owned</option>
                      <option value={false}>Rented</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("is_premised_owner")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {/* {email.showErrorMsg && email.invalidEmail && <div className="error">* {INVALID_EMAIL}</div>}
                  {email.showErrorMsg && email.nullValue && <div className="error">* {EMPTY_EMAIL}</div>} */}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup className="form-group-space-between">
                  <Label>No. of shutters</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                      name="shutters_count"
                      value={controls.shutters_count.value}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("shutters_count")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              {(this.state.userDetail.access_level === "area" ||
                this.state.userDetail.access_level === "all" ||
                isEnableEdit) &&
                (controls.is_premised_owner.value === "false" ||
                  controls.is_premised_owner.value === false) && (
                  <Col xl="3" md="12" xs="12">
                    <FormGroup className="form-group-space-between">
                      <Label>
                        Lease agreement/Property Documents
                        <span className="required-star">*</span>
                      </Label>
                      <input
                        accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        type="file"
                        name="lease_aggrement"
                        onChange={(e) => this.uploadFile(e, "lease_aggrement")}
                        style={{ marginTop: "18px" }}
                      />
                      {controls.lease_aggrement.showErrorMsg && (
                        <div className="error">
                          * {messages.DEALERSHIP_DETAILS.LESS_AGGREEMENT}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                )}
              {getProposalDetail.lease_aggrement && (
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>Lease agreement/Property Documents</Label>
                    <a
                      className="aa d-flex-column"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={getProposalDetail.lease_aggrement}
                      style={{ width: "65px" }}
                    >
                      <img
                        src="images/sidebar-icons/secondary-report-selected.svg"
                        height="40px"
                        alt=''
                      />
                      <span className="redirect-link text-align-center">
                        View
                      </span>
                    </a>
                  </FormGroup>
                </Col>
              )}
              <Col xl="3" md="12" xs="12">
                <FormGroup className="form-group-space-between">
                  <Label>
                    Upload Site Pictures<span className="required-star">*</span>
                  </Label>
                  <input
                    accept="image/*"
                    type="file"
                    name="site_picture"
                    onChange={(e) => this.uploadFile(e, "site_picture")}
                  />
                  {this.state.businessAnalysis.controls.site_picture
                    .showErrorMsg && (
                      <div className="error">
                        * {messages.DEALERSHIP_DETAILS.SITE_PICTURES}
                      </div>
                    )}
                </FormGroup>
              </Col>
              {getProposalDetail.site_picture && (
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>Site Pictures</Label>
                    <a
                      className="aa d-flex-column"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={getProposalDetail.site_picture}
                      style={{ width: "65px" }}
                    >
                      <img
                        src="images/sidebar-icons/secondary-report-selected.svg"
                        height="40px"
                        alt=''
                      />
                      <span className="redirect-link text-align-center">
                        View
                      </span>
                    </a>
                  </FormGroup>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default BusinessAnalysisForm;
