import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Storage from '../../services/Storage';
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { getAction } from '../../utils/utils';
import { setDealerDashboardRedirectDetail, setAddCustomerRedirect, setRedirect } from '../../actions/UserAction';

class NotificationRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notication_detail: Object.assign(Storage.getNotificationDetail() ? Storage.getNotificationDetail() : {}, {})
    }
    Storage.removeNotificationDetail();
    if (this.state.notication_detail &&
      Object.keys(this.state.notication_detail).length > 0) {
      GoogleAnalticsService.event('Push Notification', 'Push Notification Click');
      let notification = this.state.notication_detail;
      if (notification.notification_data) {
        notification.notification_data = JSON.parse(this.state.notication_detail.notification_data);
      }
      if (notification.action) {
        let path_data = getAction(notification.action);
        if (typeof path_data === 'string') {
          if (path_data === '/campaign/create') {
            let notificationData = notification.notification_data;
            this.setCampaignData(notificationData);
            this.setState({ redirectToCreateCampaign: true });
          } else if (path_data === '/customer-management') {
            let notificationDataCustomerMgt = notification.notification_data;
            localStorage.setItem("active_id", 1);
            localStorage.setItem("activeLink", 'customer-management');
            this.setCustomerData(notificationDataCustomerMgt);
          }
          this.props.history.push(path_data);
        } else {
          if (path_data.isRedeemCouponOpen) {
            localStorage.setItem('redirectDetails', JSON.stringify(path_data));
            this.props.history.push(null);
          } else {
            this.props.setRedirect(path_data);
            if (path_data.pathname) {
              this.props.history.push(path_data.pathname);
            }
          }
        }
      } else {
        this.props.history.push('/');
      }
    } else {
      this.props.history.push('/');
    }
  }

  setCampaignData = (data) => {
    let reqData = {
      campaign: data
    }
    this.props.setDealerDashboardRedirectDetail(
      reqData
    );
  }
  setCustomerData = (data) => {
    let reqData = {
      customer_mgt: data
    }
    this.props.setDealerDashboardRedirectDetail(
      reqData
    );
  }




  render() {
    return (
      <></>
    );
  }
}

//export default NotificationRedirect;
const mapDispatchToProps = dispatch => ({
  setDealerDashboardRedirectDetail: redirectDetails => dispatch(setDealerDashboardRedirectDetail(redirectDetails)),
  setAddCustomerRedirect: redirectDetailsAddCustomer => dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
  setRedirect: redirectDetails => dispatch(setRedirect(redirectDetails)),
});

const mapStateToProps = state => ({
  userDetail: state.User.userDetail
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationRedirect));