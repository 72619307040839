import React, { Component } from 'react';
import {
  Button, Card, CardBody, Label
} from 'reactstrap';
import Moment from 'react-moment';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import AddCipCoAttendee from '../../pages/CipDetail/AddCipCoAttendee';
import swal from 'sweetalert';
import { downlodFile } from '../../utils/utils';

class CipCoAttendeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cipDetail: [],
      customerPage: 1,
      totalCustomerPages: 0,
      totalCustomer: 0,
      cip_co_attendee_state: this.props.cip_co_attendee_state ? this.props.cip_co_attendee_state : {}
    }
    if (this.props.cip_co_attendee_state && Object.keys(this.props.cip_co_attendee_state).length === 0) {
      this.backToAttendee()
    }
  }

  componentDidMount() {
    this.getCipCOAttendeeSummary();
  }


  backToAttendee = () => {
    window.scroll(0, 0);
    if (this.props.backToAttendee) {
      this.props.backToAttendee()
    }
    // this.props.history.push(
    //   `cip-summary`
    // );
  }

  goToAddParticapant = (row = {}) => {
    this.props.goToAddParticapant(this.state.cip_co_attendee_state, null, 'CipAttendeeList', row.uuid ? row.uuid : null);
  }

  getCipCOAttendeeSummary = (download = false) => {
    let reqData = {
      cip_id: this.state.cip_co_attendee_state.id, page: this.state.customerPage, pagesize: 200,
      cip_detail_id: this.state.cip_co_attendee_state.id
    }
    reqData.download_report = download;
    this.setState({
      isLoading: true
    })
    CustomerIntractionServie.getCipCOAttendeeSummary(reqData)
      .then(data => {
        let { cipDetail } = this.state;
        if (data.data.data.file) {
          downlodFile(data.data.data.file);
          this.setState({
            isLoading: false,
          })
          return
        }
        cipDetail = data.data.data.data;
        let totalCustomer = data.data.data.total;
        const totalCustomerPages = Math.ceil(totalCustomer / 10);
        this.setState({
          cipDetail,
          isLoading: false,
          totalCustomerPages,
          totalCustomer
        })
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  openCoAttendee = (row = {}) => {
    this.refs.refAddCipCoAttendee.toggle(row);
  }

  deleteCIPCoAttendee = (data) => {
    swal({
      title: "",
      text: "Are you sure you want to delete this Co-Attendee ?",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.confirmDeleteCIPCoAttendee(data);
      }
    });

  }

  confirmDeleteCIPCoAttendee = (data) => {
    let reqData = {
      uuid: data.uuid
    }
    this.setState({ isLoading: true });
    CustomerIntractionServie.deleteCIPCoAttendee(reqData)
      .then(res => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.getCipCOAttendeeSummary();
        this.setState({ isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="d-flex-column">
        {this.state.isLoading && <CustomSpinner />}
        <div className="d-flex-row mb-4 mt-2">
          <span onClick={() => this.backToAttendee()} className="Merchandise-catalogu-link cursor-pointer" style={{ fontSize: '16px' }}>No of Participant</span>&nbsp;&nbsp;
          <img src="images/right.svg" alt='' />&nbsp;&nbsp;
          <span className="cart-lbl" style={{ fontSize: '24px', width: 'auto' }}>No of Co-attendees</span>
        </div>
        <Card className="mt-2">
          <CardBody className="d-flex-column">
            <div className="d-flex-row justify-content-between">
              <div className="d-flex-row mt-auto mb-auto">
                {
                  this.props.cip_attendee_state.cip_date &&
                  <div>
                    <Label className="font-weight-bold" for="owner_name">CIP Date :</Label>
                    &nbsp;
                    <span> <Moment format="DD MMM YYYY">
                      {this.props.cip_attendee_state.cip_date}
                    </Moment></span>
                  </div>
                }
                {
                  this.props.cip_attendee_state.cip_venue && <div className="mt-auto mb-auto">&nbsp;&nbsp;
                    <Label className="font-weight-bold" for="owner_name">CIP Venue :</Label>
                    &nbsp;
                    <span>{this.props.cip_attendee_state.cip_venue}</span>
                  </div>
                }
                {
                  this.props.cip_co_attendee_state.company_name && <div className="mt-auto mb-auto">&nbsp;&nbsp;
                    <Label className="font-weight-bold" for="owner_name">Business Name :</Label>
                    &nbsp;
                    <span className="text-capitalize">{this.props.cip_co_attendee_state.company_name}</span>
                  </div>
                }

              </div>

              <div className="d-flex">
                {
                  (this.state.cipDetail || []).length > 0 &&
                  <div className="d-flex mt-auto mb-auto">
                    <img src="images/download.svg" alt='' className="download-report-icon" onClick={() => this.getCipCOAttendeeSummary(true)} />
                    <span className="download-report cursor-pointer mt-auto mb-auto m-2" onClick={() => this.getCipCOAttendeeSummary(true)}>{' '} Download Report</span>
                  </div>
                }&nbsp;&nbsp;
                {
                  (this.props.cip_attendee_state.status !== "Canceled" && this.props.cip_attendee_state.status !== "Ended") &&
                  <Button
                    style={{ width: '185px' }}
                    className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                    color="warning" onClick={() => { this.openCoAttendee({}); }}>
                    Add Co-Attendee
                  </Button>
                }
              </div>
            </div>
            <table
              className="mb-0 table-responsive mt-3 tbl-f6f6f6"
              bordered
            >
              <thead>
                <tr style={{ backgroundColor: "#f6f6f6" }}>
                  <th>Mobile No.</th>
                  <th>Co-Attendee Name</th>
                  <th>Designation</th>
                  <th>Whatsapp No</th>
                  <th>Email Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  (this.state.cipDetail || []).map((item, i) => (
                    <tr>
                      <td>{item.mobile_number}</td>
                      <td className="text-capitalize">{item.name}</td>
                      <td className="text-capitalize">{item.designation ? item.designation : '--'}</td>
                      <td>{item.whatsapp_number ? item.whatsapp_number : '--'}</td>
                      <td>{item.email ? item.email : '--'}</td>
                      <td>
                        {
                          (this.props.cip_attendee_state.status !== "Canceled" && this.props.cip_attendee_state.status !== "Ended") ?
                            <div className="d-flex">
                              <img src="/images/edit.svg" alt='' style={{ height: '16px', width: '16px' }} onClick={() => this.openCoAttendee(item)} />&nbsp;&nbsp;
                              <img src="/images/delete.svg" alt='' className="cursor-pointer mt-auto mb-auto"
                                onClick={() => this.deleteCIPCoAttendee(item)}
                                style={{ height: '14px' }} />&nbsp;&nbsp;
                            </div> : '--'
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {
              (this.state.cipDetail || []).length <= 0 &&
              <div className="no-data-without-border mt-3">
                <span className="m-auto">
                  There are no co-attendee added yet, If any person from the same organization is attending the CIP then kindly click on add Co-Attendee button to add the co-attendant information
                </span>
              </div>

            }
          </CardBody>
        </Card>
        {
          <AddCipCoAttendee
            cip_detail={this.props.cip_co_attendee_state}
            openCoAttendee={this.openCoAttendee}
            getCoAttendeeList={this.getCipCOAttendeeSummary}
            ref="refAddCipCoAttendee" />
        }
      </div>
    );
  }
}

export default CipCoAttendeeList;