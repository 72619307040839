import React from 'react';
import {
  Row, Col,
  Card, CardBody
} from 'reactstrap';
import Ionicon from 'react-ionicons';

import './DetailCard.css';
import { useLocation } from 'react-router-dom';
// import { Roles } from '../../constants/constant.role';

const SocialDetail = (props) => {
  // const cols = props.cardObj.fields.map(f => <>
  //   {
  //     f.sender_id_note ?
  //       <Col xl="4">
  //         <div> {f.title}</ div >
  //         {f.value && <div className={f.class}><b>{f.value}</b></div>}
  //         {!f.value ? <div><b>--</b></div>
  //           : <div className="d-flex mt-1">
  //             <span className="profile-sender-id-note">
  //               {f.sender_id_note}
  //             </span>
  //           </div>}
  //         <br />
  //       </Col> :
  // <Col xl={props.userRole === Roles.DEALER_ROLE ? '3' : '3'} md="12" xs="12" >
  //         <div> {f.title}</ div >
  //         {f.value && <div className={f.class}><b>{f.value}</b></div>}
  //         {!f.value && <div><b>--</b></div>}
  //         <br />
  //       </Col>
  //   }
  // </>
  // );

  const location = useLocation();
  return <Card id="detail-card">
    <CardBody>
      <Row className="header-border">
        <Col xl="10" md="10" xs="10">
          <b>{props.cardObj.title}</b>
        </Col>
        {location && location.pathname === "/user-updates" ? '' :
          <Col xl="2" md="2" xs="2" className="text-align-right cursor-pointer" onClick={props.makeEditActive.bind(this, props.cardObj.edit, true)}>
            <Ionicon icon="md-create" fontSize="24px" color="#fdbb1f" />
          </Col>
        }
      </Row>
      {/* <hr /> */}
      <Row className="content-padding">
        <Col xl='3' md="12" xs="12" >
          <div>Birthdate</ div >
          {props.cardObj.fields[0].value && <div className={props.cardObj.fields[0].class}><b>{props.cardObj.fields[0].value}</b></div>}
          {!props.cardObj.fields[0].value && <div><b>--</b></div>}
          <br />
        </Col>
        <Col xl='3' md="12" xs="12" >
          <div>Anniversary Date</ div >
          {props.cardObj.fields[1].value && <div className={props.cardObj.fields[1].class}><b>{props.cardObj.fields[1].value}</b></div>}
          {!props.cardObj.fields[1].value && <div><b>--</b></div>}
          <br />
        </Col>
      </Row>
      <Row className="content-paddingss">
        <Col xl='3' md="12" xs="12" >
          <div>Facebook Page</ div >
          {/* {props.cardObj.fields[2].value && <div className={props.cardObj.fields[2].class}><b>{props.cardObj.fields[2].value}</b></div>}
          {!props.cardObj.fields[2].value && <div><b>--</b></div>} */}

          {props.cardObj.fields[2].value && <div
            onClick={() => props.openUrl(props.cardObj.fields[2].value)}
            className="custom-anchor-tag cursor-pointer" style={{ textDecoration: 'none' }}>Open Facebook Page</div>}
          {!props.cardObj.fields[2].value && <div><b>--</b></div>}
          <br />
        </Col>
        <Col xl='3' md="12" xs="12" >
          <div>Instagram Page</ div >
          {props.cardObj.fields[3].value && <div
            onClick={() => props.openUrl(props.cardObj.fields[3].value)}
            className="custom-anchor-tag cursor-pointer" style={{ textDecoration: 'none' }}>Open Instagram Page</div>}
          {!props.cardObj.fields[3].value && <div><b>--</b></div>}
          {/* 

          {props.cardObj.fields[3].value && <div className={props.cardObj.fields[3].class}><b>{props.cardObj.fields[3].value}</b></div>}
          {!props.cardObj.fields[3].value && <div><b>--</b></div>} */}
          <br />
        </Col>
        <Col xl='3' md="12" xs="12" >
          <div>Website</ div >
          {props.cardObj.fields[4].value && <div
            onClick={() => props.openUrl(props.cardObj.fields[4].value)}
            className="custom-anchor-tag cursor-pointer" style={{ textDecoration: 'none' }}>Open Website</div>}
          {!props.cardObj.fields[4].value && <div><b>--</b></div>}
          <br />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xl='4' md="12" xs="12" >
          <div>Store Google My Business Link</ div >
          {/* {props.cardObj.fields[5].value && <div className={props.cardObj.fields[4].class}><b>{props.cardObj.fields[5].value}</b></div>} */}
          {props.cardObj.fields[5].value && <div
            onClick={() => props.openUrl(props.cardObj.fields[5].value)}
            className="custom-anchor-tag cursor-pointer" style={{ textDecoration: 'none' }}>Open Store Google My Business</div>}
          {!props.cardObj.fields[5].value && <div><b>--</b></div>}
          <br />
        </Col>
        <Col xl='5' md="12" xs="12" >
          <div>Store Google My Business Rating Link</ div >
          {props.cardObj.fields[6].value && <div
            onClick={() => props.openUrl(props.cardObj.fields[6].value)}
            className="custom-anchor-tag cursor-pointer" style={{ textDecoration: 'none' }}>Open Store Google My Business Rating Link</div>}
          {/* {props.cardObj.fields[6].value && <div className={props.cardObj.fields[6].class}><b>{props.cardObj.fields[6].value}</b></div>} */}
          {!props.cardObj.fields[6].value && <div><b>--</b></div>}
          <br />
        </Col>
      </Row>
    </CardBody>
  </Card>;
};

export default SocialDetail;