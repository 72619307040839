import React, { useState, useEffect } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import { Row, Col, Input, FormGroup, Label, Button } from 'reactstrap';
import PropTypes from "prop-types";
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import CampaignService from '../../services/CampaignService';
import { connect } from "react-redux";
import {
  setEventRegistrationSystemControls,
  handleValidationEventRegistrationInformation
} from "../../actions/EventRegistrationSystemAction";
import { getEventRegistrationInfoState } from "./selector";
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { messages } from "../../constants/constant.message";
import WarrantyRegistrationService from '../../services/WarrantyRegistrationService';
import './EventRegistration.css'
const { ERROR_MESSAGE } = messages.COMMON;
const ref = React.createRef();
const defaultFilterControls = {};
const tyerSizesOptions = [
  {
    name: "265/70 R17",
    value: "265/70 R17"
  },
  {
    name: "31X10.5 R15",
    value: "31X10.5 R15"
  },
  {
    name: "245/75 R16",
    value: "245/75 R16"
  },
  {
    name: "Other",
    value: "Other"
  }
]
const EventRegistration = (
  { eventRegistrationDetailControls,
    setEventRegistrationSystemControlsAction,
    handleValidationEventRegistrationInformationAction, ...props }
) => {
  const [tyerSize, setTyerSize] = useState("");
  const [tyerSizeOther, setTyerSizeOther] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [vehicleMakeData, setVehicleMakeData] = useState([]);
  const [vehicleModelData, setVehicleModelData] = useState([]);
  // const [vehicleMake, setVehicleMake] = useState([]);
  const [vehicleModel, setVehicleModel] = useState([]);
  // const ref = useRef([]);
  const [isQrCode, setIsQrCode] = useState('');
  // window.location.reload(false);
  let history = useHistory();

  useEffect(() => {
    // window.location.reload(false);
    setDefaultsControls();
    topFunction();
    getCity("22c8f77c-feca-4706-b426-c583e96eedab");
    getVehicleMake();
    if (props && props.location && props.location.state) {
      setIsQrCode(props.location.state)
    }
    else {
      setIsQrCode(false)
    }

  }, [])

  const setDefaultsControls = () => {
    const { vehicleDetailInfo, prosonalDetailInfo } = eventRegistrationDetailControls;
    prosonalDetailInfo.firstName.value = '';
    prosonalDetailInfo.firstName.touched = false;
    prosonalDetailInfo.firstName.valid = null;
    prosonalDetailInfo.lastName.value = '';
    prosonalDetailInfo.lastName.touched = false;
    prosonalDetailInfo.lastName.valid = null;
    prosonalDetailInfo.mobileNo.value = '';
    prosonalDetailInfo.mobileNo.touched = false;
    prosonalDetailInfo.mobileNo.valid = null;
    prosonalDetailInfo.email.value = '';
    prosonalDetailInfo.email.touched = false;
    prosonalDetailInfo.email.valid = null;
    vehicleDetailInfo[0].city.value = '';
    vehicleDetailInfo[0].city.touched = false;
    vehicleDetailInfo[0].city.valid = null;
    vehicleDetailInfo[0].vehicleMake.value = '';
    vehicleDetailInfo[0].vehicleMake.touched = false;
    vehicleDetailInfo[0].vehicleMake.valid = null;
    vehicleDetailInfo[0].vehicleModel.value = '';
    vehicleDetailInfo[0].vehicleModel.touched = false;
    vehicleDetailInfo[0].vehicleModel.valid = null;
    setEventRegistrationSystemControlsAction(
      Object.assign({}, eventRegistrationDetailControls)
    );

  }
  const onCancel = () => {
    history.push('/ranger-event')
  }

  const getVehicleMake = async (vehicleType = "all") => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const { data } = await WarrantyRegistrationService.getVehicalMake({ vehicle_type_id: "6cdb5eb6-fd92-4bf9-bc09-cf28c11b550c" });
        if (data && data.data.length > 0) {
          setVehicleMakeData(data.data);
        } else {
          setVehicleMakeData([]);
        }
        setVehicleModelData([]);
        setIsLoading(false);
        return resolve(vehicleMakeData);
      } catch (error) {
        setVehicleMakeData([]);
        setVehicleModelData([]);
        setIsLoading(false);
        return reject(error);
      }
    })
  }
  const getVehicleModel = async (vehicleMake, index) => {

    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const { data } = await WarrantyRegistrationService.getVehicalModel({ vehicle_type_brand_id: vehicleMake.brand_id });
        if (data && data.data && data.data.length > 0) {
          setVehicleModelData(data.data);
        } else {
          setVehicleModelData([])
        }
        setIsLoading(false);
        return resolve(vehicleModelData);
      } catch (error) {
        setIsLoading(false);
        return reject(error);
      }
    })
  }
  const getCity = (state = null) => {
    setIsLoading(true);
    let reqData = {
      state_id: state,
    };
    CampaignService.getCity(reqData)
      .then((data) => {
        const { data: cities } = data.data;
        setCityData(cities);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  const handleInputChange = (e, index) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { vehicleDetailInfo, prosonalDetailInfo } = eventRegistrationDetailControls;
    if (
      controlName === "vehicleMake" ||
      controlName === "vehicleModel" || controlName === 'city'
    ) {
      if (controlName === "vehicleMake") {

        if (controlValue && controlValue.brand_id) {
          getVehicleModel(controlValue, index);
        }
        if (controlValue === undefined || controlValue === 'undefined') {
          vehicleDetailInfo[index][controlName].value = null;
          vehicleDetailInfo[index][controlName].touched = true;
        }
        if (controlValue && controlValue.name) {
          vehicleDetailInfo[index][controlName].value = controlValue.brand_id;
          vehicleDetailInfo[index][controlName].touched = true;
          setVehicleModel([]);
        }
        setVehicleModelData([]);
        ref.current.clear();


      } else if (controlName === "vehicleModel") {
        if (controlValue === undefined || controlValue === 'undefined') {
          vehicleDetailInfo[index][controlName].value = null;
          vehicleDetailInfo[index][controlName].touched = true;
        }
        if (controlValue && controlValue.name) {
          vehicleDetailInfo[index][controlName].value = controlValue.model_id;
          vehicleDetailInfo[index][controlName].touched = true;
        }
      }
      else if (controlName === 'city') {
        if (controlValue && controlValue.city_id) {
          vehicleDetailInfo[index][controlName].value = controlValue.city_id;
          vehicleDetailInfo[index][controlName].touched = true;
        }
      }
    }
    if (controlName === "firstName") {
      prosonalDetailInfo.firstName.value = controlValue;
      prosonalDetailInfo.firstName.touched = true;

    } else if (controlName === "lastName") {
      prosonalDetailInfo.lastName.value = controlValue;
      prosonalDetailInfo.lastName.touched = true;

    } else if (controlName === "mobileNo") {
      prosonalDetailInfo.mobileNo.value = controlValue;
      prosonalDetailInfo.mobileNo.touched = true;
      // prosonalDetailInfo.email.showErrorMsg = false;

    } else if (controlName === "email") {
      prosonalDetailInfo.email.value = controlValue;
      prosonalDetailInfo.email.touched = true;
      // prosonalDetailInfo.mobileNo.showErrorMsg = false;

    }
    handleValidationEventRegistrationInformationAction(
      false,
      eventRegistrationDetailControls
    );
    setEventRegistrationSystemControlsAction(
      Object.assign({}, eventRegistrationDetailControls)
    );
  };
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const submit = () => {
    topFunction();

    if (
      !handleValidationEventRegistrationInformationAction(
        true,
        eventRegistrationDetailControls
      )
    ) {
      return;
    }
    setIsLoading(true);
    const prepareObj = {};
    if (
      eventRegistrationDetailControls.vehicleDetailInfo &&
      eventRegistrationDetailControls.vehicleDetailInfo.length > 0
    ) {
      eventRegistrationDetailControls.vehicleDetailInfo.forEach((element) => {
        prepareObj.city = element.city.value;
        prepareObj.make = element.vehicleMake.value;
        prepareObj.model = element.vehicleModel.value
      });
    }
    prepareObj.first_name = eventRegistrationDetailControls.prosonalDetailInfo.firstName.value;
    prepareObj.last_name = eventRegistrationDetailControls.prosonalDetailInfo.lastName.value;
    prepareObj.contact_no = eventRegistrationDetailControls.prosonalDetailInfo.mobileNo.value;
    prepareObj.email = eventRegistrationDetailControls.prosonalDetailInfo.email.value;
    prepareObj.isFromQRCode = isQrCode;
    prepareObj.tyre_size = tyerSize !== 'Other' ? tyerSize : tyerSizeOther;

    CampaignService.registerForEvent(prepareObj)
      .then((data) => {
        if (data.data && data.data.message) {
          setIsLoading(false);
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success"
          }).then((isConfirm) => {
            if (isQrCode) {
              history.push('/ranger-event?q=true')
            } else {
              window.location.reload(false);
            }
          });
        }

      })
      .catch((e) => {
        setIsLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        const msg = <div style={{ fontWeight: 'bold' }}>{message}</div>
        swal({
          title: "",
          text: msg,
          icon: "error",
          type: "warning",
        }).then((isConfirm) => {
        });
      });


  }
  const handleInputTyerSizeChange = (e) => {
    if (e.target.name === 'tyerSizeDropDown') {
      setTyerSize(e.target.value);
    }

    if (e.target.name === 'otherTyerSize') {
      setTyerSizeOther(e.target.value);
    }

  }
  return (
    <div id="event-registration" style={{ background: '#FFFAED', vh: '100%', width: '100%', display: 'flex', padding: '18px', justifyContent: 'center' }}>
      {isLoading && <CustomSpinner />}
      <div style={{}} className="event-page">
        <Col>
          <Row>
            <Col style={{ textAlign: 'left', marginLeft: '' }}>
              <img src="/images/jktyer-logo1.png" alt="" />
            </Col>
            <Col style={{ textAlign: 'right', marginRight: '' }}>
              <img src="/images/Vector.png" alt="" />
            </Col>
          </Row>
          <Row>
            <Col className="customer-registration-tag">
              customer registration
            </Col>
          </Row>
          <Row>
            <Col className="offer-detail">
              Please provide below detail to register for Ranger M/T
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <div style={{ display: "flex" }}>
                <div className="title-heading">PERSONAL INFORMATION</div>
                <div
                  className="straight-line"
                >
                  <hr className="hrizontal-line"></hr>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '24px' }}>
            <Col>
              <FormGroup>
                <Label>
                  First Name *
                </Label>
                <div className="d-flex">
                  <Input
                    type="text"
                    name="firstName"
                    className="input-field"
                    style={{ textTransform: 'capitalize' }}
                    value={
                      eventRegistrationDetailControls.prosonalDetailInfo.firstName.value
                    }
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                {eventRegistrationDetailControls.prosonalDetailInfo.firstName.showErrorMsg && (
                  <div className="error">
                    * Please enter first Name.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>
                  Last Name *
                </Label>
                <div className="d-flex">
                  <Input
                    type="text"
                    name="lastName"
                    className="input-field"
                    style={{ textTransform: 'capitalize' }}
                    value={
                      eventRegistrationDetailControls.prosonalDetailInfo.lastName.value
                    }
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                {eventRegistrationDetailControls.prosonalDetailInfo.lastName.showErrorMsg && (
                  <div className="error">
                    * Please enter last Name.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  Mobile No.
                </Label>
                <div className="d-flex">
                  <Input
                    type="text"
                    name="mobileNo"
                    className="input-field"
                    value={
                      eventRegistrationDetailControls.prosonalDetailInfo.mobileNo.value
                    }
                    onChange={(e) => handleInputChange(e)}

                    maxLength="10"
                  />
                </div>
                {eventRegistrationDetailControls.prosonalDetailInfo.mobileNo.showErrorMsg && (
                  <div className="error">
                    {eventRegistrationDetailControls.prosonalDetailInfo.email.valid ? "*Please provide valid mobile number" : "* Please enter either mobile number or email id."}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  Email
                </Label>
                <div className="d-flex">
                  <Input
                    type="text"
                    name="email"
                    className="input-field"
                    value={
                      eventRegistrationDetailControls.prosonalDetailInfo.email.value
                    }
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                {eventRegistrationDetailControls.prosonalDetailInfo.email.showErrorMsg && (
                  <div className="error">
                    {eventRegistrationDetailControls.prosonalDetailInfo.mobileNo.valid ? "*Please provide valid email" : "* Please enter either mobile number or email id."}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          {eventRegistrationDetailControls.vehicleDetailInfo.map((vehicle, index) => {
            return (
              <>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        City Name *
                      </Label>
                      <div className="d-flex">
                        <Typeahead
                          id="city"
                          align="left"
                          className="city-type"
                          disabled={false}
                          selected={vehicle.value}
                          filterBy={(option, props) => {
                            if (props.selected.length) {
                              return true;
                            }
                            return (
                              option.name
                                .toLowerCase()
                                .indexOf(props.text.toLowerCase()) !== -1 ||
                              option.name.toLowerCase().indexOf("other") !== -1
                            );
                          }}
                          labelKey="name"
                          options={cityData}
                          onChange={(city) => {

                            handleInputChange(
                              {
                                target: {
                                  name: "city",
                                  value: city[0],
                                },
                              },
                              index
                            );
                          }}
                        />
                        <img
                          src="images/search.svg"
                          alt=""
                          style={{ marginLeft: "-23px", zIndex: "999", marginTop: '29px', color: '#FDBB1F' }}
                          className="report-search"
                        />
                      </div>
                      {vehicle.city.showErrorMsg && (
                        <div className="error">
                          * Please select city.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                  <Col>
                    <div style={{ display: "flex" }}>
                      <div className="title-heading">VEHICLE DETAILS</div>
                      <div
                        className="straight-line"
                      >
                        <hr className="hrizontal-line"></hr>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '24px' }}>
                  <Col>
                    <FormGroup>
                      <Label>
                        Vehicle Make *
                      </Label>
                      <div className="d-flex">
                        <Typeahead
                          id="vehicleMake"
                          disabled={false}
                          selected={vehicle.value}
                          filterBy={(option, props) => {
                            if (props.selected.length) {
                              return true;
                            }
                            return (
                              option.name
                                .toLowerCase()
                                .indexOf(props.text.toLowerCase()) !== -1 ||
                              option.name.toLowerCase().indexOf("other") !== -1
                            );
                          }}
                          labelKey="name"
                          options={vehicleMakeData}
                          onChange={(vehicleMake) => {

                            handleInputChange(
                              {
                                target: {
                                  name: "vehicleMake",
                                  value: vehicleMake[0],
                                },
                              },
                              index
                            );
                          }}
                        />
                        <img
                          src="images/search.svg"
                          alt=""
                          style={{ marginLeft: "-23px", zIndex: "999", marginTop: '29px', color: '#FDBB1F' }}
                          className="report-search"
                        />
                      </div>
                      {vehicle.vehicleMake.showErrorMsg && (
                        <div className="error">
                          * Please select vehcile brand.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>
                        Vehicle Model *
                      </Label>
                      <div className="d-flex">
                        <Typeahead
                          ref={ref}
                          style={{ height: "32px" }}
                          id="vehicleModel"
                          disabled={false}
                          selected={vehicle.value}
                          filterBy={(option, props) => {
                            if (props.selected.length) {
                              return true;
                            }
                            return (
                              option.name
                                .toLowerCase()
                                .indexOf(props.text.toLowerCase()) !== -1 ||
                              option.name.toLowerCase().indexOf("other") !== -1
                            );
                          }}
                          labelKey="name"
                          options={vehicleModelData}
                          // placeholder="Search..."
                          onChange={(vehicleModel) => {
                            handleInputChange(
                              {
                                target: {
                                  name: "vehicleModel",
                                  value: vehicleModel[0],
                                },
                              },
                              index
                            );
                          }}
                        />
                        <img
                          src="images/search.svg"
                          alt=""
                          style={{ marginLeft: "-23px", zIndex: "999", marginTop: '29px', color: '#FDBB1F' }}
                          className="report-search"
                        />
                      </div>
                      {vehicle.vehicleModel.showErrorMsg && (
                        <div className="error">
                          * Please select vehicle model.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            );
          })}
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  Interested For Tyre Size ?
                </Label>
                <select
                  className="form-control select drop-down-control"
                  name="tyerSizeDropDown"
                  value={tyerSize}
                  onChange={(e) => handleInputTyerSizeChange(e)}
                >
                  <option value={''}>Select</option>
                  {tyerSizesOptions.map(item => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </select>
              </FormGroup>
            </Col>
          </Row>
          {tyerSize === 'Other' && <>
            <Row style={{ marginTop: '0px' }}>
              <Col>
                <FormGroup>
                  <Label>
                    Please Mention
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="otherTyerSize"
                      className="input-field"
                      // style={{ textTransform: 'uppercase' }}
                      value={
                        tyerSizeOther
                      }
                      onChange={(e) => handleInputTyerSizeChange(e)}
                    />
                  </div>
                </FormGroup>
              </Col>

            </Row>
          </>}
          <hr />
          <Row style={{ marginTop: '10px', display: 'flex', flexDirection: 'row-reverse' }}>
            <Col style={{ display: 'contents' }}>
              <Button style={{ width: "25%", weight: '700', fontWeight: 'bold' }}
                className="mb-1 mr-1 btn-pill action-button"
                color="warning"
                onClick={submit}>
                Submit
              </Button>
            </Col>
            <Col style={{ display: 'contents' }}>
              <Button style={{ width: "25%", weight: '700', fontWeight: 'bold', background: '#e0e2e4', color: '#6e777f', borderColor: '#e0e2e4' }}
                className="mb-1 mr-1 btn-pill action-button"
                color="warning"
                onClick={onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>

      </div>
    </div>
  )
}

EventRegistration.propTypes = {
  eventRegistrationDetailControls: PropTypes.instanceOf(Object).isRequired,
  // props: PropTypes
};

const mapStateToProps = (state) => {
  return {
    eventRegistrationDetailControls: getEventRegistrationInfoState(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setEventRegistrationSystemControlsAction: setEventRegistrationSystemControls,
      handleValidationEventRegistrationInformationAction: handleValidationEventRegistrationInformation,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventRegistration);


