import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";
import "./proposal-details.css";
import swal from "sweetalert";
import DealershipDetailsForm from "../../components/DealershipDetailsForm/DealershipDetailsForm";
import BusinessAnalysisForm from "../../components/BusinessAnalysisForm/BusinessAnalysisForm";
import ShowroomSizeForm from "../../components/ShowroomSizeForm/ShowroomSizeForm";
import MachineryDetails from "../../components/MachineryDetailsForm/MachineryDetails";
import { ProposalDetailState } from "./ProposalDetailState";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import ProposalSerivice from "../../services/ProposalSerivice";
import CheckChangeObjProposalDetail from "./CheckChangeObjProposalDetail";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import _ from "lodash";
import Storage from "../../services/Storage";
import * as Scroll from "react-scroll";
import { Roles } from "../../constants/constant.role";
const { ERROR_MESSAGE } = messages.COMMON;
class ProposalDetails extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      dealership_Details: _.cloneDeep(ProposalDetailState.dealership_Details),
      businessAnalysis: _.cloneDeep(ProposalDetailState.businessAnalysis),
      showRoomSize: _.cloneDeep(ProposalDetailState.showRoomSize),
      machineryDetailsExisting: _.cloneDeep(
        ProposalDetailState.machineryDetailsExisting
      ),
      machineryDetailsRequired: _.cloneDeep(
        ProposalDetailState.machineryDetailsRequired
      ),
      annexures: _.cloneDeep(ProposalDetailState.annexures),
      isLoading: false,
      userDetail: Storage.getUserDetail(),
      getProposalDetail: {},
      preObj: {},
      isNotFullPageViewDetail: false,
    };
    this.childRefDealershipDetailsForm = React.createRef();
    this.childRefBusinessAnalysisForm = React.createRef();
    this.childRefShowroomSizeForm = React.createRef();
    this.childRefMachineryDetails = React.createRef();
    if (this.props.location && this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      const userDetail = Storage.getUserDetail();
      userDetail.access_level = "AM";
      this.state.uuid = query.get("id") ? query.get("id") : "";
    }
    if (this.state.userDetail && this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE) {
      this.state.isEdit = true;
    }
  }

  resetData = () => {
    if (this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE) {
      return;
    }
    let {
      dealership_Details,
      businessAnalysis,
      showRoomSize,
      machineryDetailsExisting,
      machineryDetailsRequired,
      annexures,
    } = this.state;
    let proposal_type = "";
    if (dealership_Details.controls.proposal_type.value) {
      proposal_type = dealership_Details.controls.proposal_type.value;
    }
    dealership_Details = _.cloneDeep(ProposalDetailState.dealership_Details);
    dealership_Details.controls.proposal_type.value = proposal_type;
    businessAnalysis = _.cloneDeep(ProposalDetailState.businessAnalysis);
    showRoomSize = _.cloneDeep(ProposalDetailState.showRoomSize);
    machineryDetailsExisting = _.cloneDeep(
      ProposalDetailState.machineryDetailsExisting
    );
    machineryDetailsRequired = _.cloneDeep(
      ProposalDetailState.machineryDetailsRequired
    );
    annexures = _.cloneDeep(ProposalDetailState.annexures);
    this.setState(
      {
        dealership_Details,
        businessAnalysis,
        showRoomSize,
        machineryDetailsExisting,
        annexures,
        uuid: "",
        id: "",
        getProposalDetail: {},
      },
      () => {
        this.childRefDealershipDetailsForm.current.resetDetail();
        this.childRefBusinessAnalysisForm.current.resetDetail();
        this.childRefShowroomSizeForm.current.resetDetail();
        this.childRefMachineryDetails.current.resetDetail();
        this.updateOutletType("");
      }
    );
  };

  getViewDetail = (uuid) => {
    this.setState(
      {
        uuid: uuid,
        isNotFullPageViewDetail: true,
      },
      () => {
        this.getPrposalById(null);
      }
    );
  };

  goToPrevPage() {
    if (this.state.isNotFullPageViewDetail) {
      this.props.goBack();
    } else {
      this.props.history.goBack();
    }
  }

  setDealership_Details = async (id) => {
    let { dealership_Details, getProposalDetail } = this.state;
    let { controls } = dealership_Details;
    let data = getProposalDetail;
    if (!id) {
      controls.proposal_type.isDisable = true;
      controls.outlet_type.isDisable = true;
      controls.category.isDisable = true;
      controls.name.isDisable = true;
      controls.pincode.isDisable = true;
      controls.address.isDisable = true;
      controls.city.isDisable = true;
      controls.state.isDisable = true;
      controls.landline.isDisable = true;
      controls.mobile.isDisable = true;
      controls.region.isDisable = true;
      controls.email_id.isDisable = true;
      controls.area.isDisable = true;
      controls.so.isDisable = true;
    }
    controls.proposal_type.value = data.proposal_type;

    controls.outlet_type.value = data.outlet_type;

    controls.category.value = data.category;

    controls.name.value = data.name;

    controls.pincode.value = data.pincode;

    controls.address.value = data.address;

    controls.city.value = data.city;

    controls.state.value = data.state;

    controls.landline.value = data.landline;

    controls.mobile.value = data.mobile;

    controls.email_id.value = data.email_id;

    controls.region.value = data.region;

    controls.area.value = data.area;

    controls.so.value = { id: data.so, name: "" };

    await this.setState(
      (prevState) => {
        return {
          ...prevState,
          dealership_Details,
        };
      },
      () => {
        this.childRefDealershipDetailsForm.current.getRegion();
        this.childRefDealershipDetailsForm.current.getArea(data.region);
        this.childRefDealershipDetailsForm.current.getSO();
        this.updateOutletType(controls.outlet_type.value);
      }
    );
  };

  async setBusinessAnalysisData(id) {
    let { businessAnalysis, getProposalDetail } = this.state;
    let { controls } = businessAnalysis;
    let data = getProposalDetail;
    if (!id) {
      controls.ownership_type.isDisable = true;
      controls.proprietor_name.isDisable = true;
      controls.is_in_tyre_business.isDisable = true;
      controls.counters.isDisable = true;
      controls.is_jk_authorized_dealer.isDisable = true;
      controls.sas_non_truck.isDisable = true;
      controls.sap_id.isDisable = true;
      controls.is_premised_owner.isDisable = true;
      controls.shutters_count.isDisable = true;
      controls.compitition_brand_pcr.isDisable = true;
      controls.sas_truck.isDisable = true;
      controls.is_agree_exclusive_jk.isDisable = true;
      controls.existing_shop_compitition.isDisable = true;
      controls.existing_show_compitition_name.isDisable = true;
      controls.remarks.isDisable = true;
      controls.lease_aggrement.isDisable = true;
      controls.total_counter_total_year_potential.isDisable = true;
      controls.total_counter_total_h1_potential.isDisable = true;
      controls.present_offtake_total_year_turnover.isDisable = true;
      controls.present_offtake_total_h1_turnover.isDisable = true;
      controls.counter_share_total_h1_turnover.isDisable = true;
      controls.counter_share_total_year_turnover.isDisable = true;
      controls.proposed_business_total_year_turnover.isDisable = true;
      controls.proposed_business_h1_turnover.isDisable = true;
      controls.counter_monthly.forEach((element) => {
        if (element["lcv"]) {
          element["lcv"].isDisable = true;
        }
        if (element["scv"]) {
          element["scv"].isDisable = true;
        }
        if (element["car"]) {
          element["car"].isDisable = true;
        }
        if (element["two_wheel"]) {
          element["two_wheel"].isDisable = true;
        }
      });
    }
    controls.ownership_type.value = data.ownership_type;

    controls.proprietor_name.value = data.proprietor_name;

    controls.is_in_tyre_business.value = data.is_in_tyre_business;

    controls.counters.value = data.counters;
    // if (data.is_jk_authorized_dealer=false) {
    //   controls.is_jk_authorized_dealer.value = 'N';
    // } else if (data.is_jk_authorized_dealer === true) {
    //   controls.is_jk_authorized_dealer.value = 'Y';
    // } else {
    //   controls.is_jk_authorized_dealer.value = 'Y';
    // }
    controls.is_jk_authorized_dealer.value = data.is_jk_authorized_dealer;

    controls.sas_non_truck.value = data.sas_non_truck;

    controls.sap_id.value = data.sap_id;

    controls.is_premised_owner.value = data.is_premised_owner;

    controls.shutters_count.value = data.shutters_count;

    controls.sas_truck.value = data.sas_truck;

    controls.compitition_brand_pcr.value = data.compitition_brand_pcr;

    // if (data.is_agree_exclusive_jk === false || data.is_agree_exclusive_jk === 'false') {
    //   controls.is_agree_exclusive_jk.value = "N";
    // }
    // else if (data.is_agree_exclusive_jk === true || data.is_agree_exclusive_jk === 'true') {
    //   controls.is_agree_exclusive_jk.value = "Y";
    // } else {
    //   controls.is_agree_exclusive_jk.value = "N";
    // }

    controls.is_agree_exclusive_jk.value = data.is_agree_exclusive_jk;

    controls.existing_shop_compitition.value = data.existing_shop_compitition;

    controls.existing_show_compitition_name.value =
      data.existing_show_compitition_name;

    controls.remarks.value = data.remarks;

    controls.lease_aggrement.value = data.lease_aggrement;
    controls.site_picture.value = data.site_picture;

    controls.total_counter_total_year_potential.value =
      data.total_counter_total_year_potential;
    controls.total_counter_total_h1_potential.value =
      data.total_counter_total_h1_potential;
    controls.present_offtake_total_year_turnover.value =
      data.present_offtake_total_year_turnover;
    controls.present_offtake_total_h1_turnover.value =
      data.present_offtake_total_h1_turnover;
    controls.counter_share_total_h1_turnover.value =
      data.counter_share_total_h1_turnover;
    controls.counter_share_total_year_turnover.value =
      data.counter_share_total_year_turnover;
    controls.proposed_business_total_year_turnover.value =
      data.proposed_business_total_year_turnover;
    controls.proposed_business_h1_turnover.value =
      data.proposed_business_h1_turnover;

    if (data.counter_monthly && data.counter_monthly.length > 0) {
      data.counter_monthly.forEach((element) => {
        const index = controls.counter_monthly.findIndex(
          (x) => x.type === element.type
        );
        if (index > -1) {
          controls.counter_monthly[index].lcv.value = element.lcv;

          controls.counter_monthly[index].scv.value = element.scv;

          controls.counter_monthly[index].car.value = element.car;
          controls.counter_monthly[index].two_wheel.value = element.two_wheel;
        } else {
        }
      });
    }
    await this.setState(
      (prevState) => {
        return { ...prevState, businessAnalysis };
      },
      () => {
        this.childRefBusinessAnalysisForm.current.setProps();
      }
    );
  }

  async setMachinaryData(id) {
    const {
      machineryDetailsExisting,
      machineryDetailsRequired,
      getProposalDetail,
    } = this.state;
    let data = getProposalDetail;
    if (!id) {
      machineryDetailsExisting.forEach((element) => {
        if (element["brand"]) {
          element.brand.isDisable = true;
        }
        if (element["model"]) {
          element.model.isDisable = true;
        }
        if (element["installation_date"]) {
          element.installation_date.isDisable = true;
        }
      });

      machineryDetailsRequired.forEach((element) => {
        if (element["brand"]) {
          element.brand.isDisable = true;
        }
        if (element["model"]) {
          element.model.isDisable = true;
        }
        if (element["installation_date"]) {
          element.installation_date.isDisable = true;
        }
      });
    }

    if (data.machinery_existing && data.machinery_existing.length > 0) {
      data.machinery_existing.forEach((element) => {
        const index = machineryDetailsExisting.findIndex(
          (x) => x.type === element.type
        );
        if (index > -1) {
          machineryDetailsExisting[index].brand.value = element.brand;
          machineryDetailsExisting[index].model.value = element.model;
          machineryDetailsExisting[
            index
          ].installation_date.value = element.installation_date
              ? new Date(element.installation_date.toString())
              : "";
        }
      });
    }

    if (data.machinery_required && data.machinery_required.length > 0) {
      data.machinery_required.forEach((element) => {
        const index = machineryDetailsRequired.findIndex(
          (x) => x.type === element.type
        );
        if (index > -1) {
          machineryDetailsRequired[index].brand.value = element.brand;
          machineryDetailsRequired[index].model.value = element.model;
          machineryDetailsRequired[
            index
          ].installation_date.value = element.installation_date
              ? new Date(element.installation_date.toString())
              : "";
        }
      });
    }
    await this.setState((prevState) => {
      return {
        ...prevState,
        machineryDetailsExisting,
        machineryDetailsRequired,
      };
    });
  }

  async setShowRoomSize(id) {
    const { showRoomSize, getProposalDetail } = this.state;
    const { controls } = showRoomSize;
    let data = getProposalDetail;
    if (!id) {
      controls.assets_value.isDisable = true;
      controls.having_separate_storage.isDisable = true;
      controls.other_business.isDisable = true;
      controls.showroom_area.isDisable = true;
      controls.showroom_celling_height.isDisable = true;
      controls.showroom_depth.isDisable = true;
      controls.showroom_frontage.isDisable = true;
      controls.storage_area.isDisable = true;
      controls.front_face_area.isDisable = true;
    }
    controls.assets_value.value = data.assets_value;

    controls.having_separate_storage.value = data.having_separate_storage;

    controls.other_business.value = data.other_business;

    controls.showroom_area.value = data.showroom_area;

    controls.showroom_celling_height.value = data.showroom_celling_height;

    controls.showroom_depth.value = data.showroom_depth;

    controls.showroom_frontage.value = data.showroom_frontage;

    controls.storage_area.value = data.storage_area;

    controls.dealer_share_amount.value = data.dealer_share_amount;

    controls.front_face_area.value = data.front_face_area;

    if (data.location && data.location.length > 0) {
      data.location.forEach((element) => {
        if (element === "main_road") {
          controls.location.main_road.value = true;
        }
        if (element === "commercial_area") {
          controls.location.commercial_area.value = true;
        }
        if (element === "residental_area") {
          controls.location.residental_area.value = true;
        }
        if (element === "tyre_auto_market") {
          controls.location.tyre_auto_market.value = true;
        }
      });
      await this.setState((prevState) => {
        return { ...prevState, showRoomSize };
      });
    }
  }

  async setAnnexures(id) {
    let { annexures, getProposalDetail } = this.state;
    let data = getProposalDetail;
    let { controls } = annexures;
    if (!id) {
      controls.willing_machinery_invest.isDisable = true;
      controls.willing_civil_invest.isDisable = true;
    }
    controls.willing_machinery_invest.value = data.willing_machinery_invest;

    controls.willing_civil_invest.value = data.willing_civil_invest;

    //controls.lease_aggrement.value = data.lease_aggrement;
    //controls.site_picture.value = data.site_picture;
    await this.setState((prevState) => {
      return { ...prevState, annexures };
    });
  }

  isLoadingSet = (isLoading) => {
    this.setState((prevState) => {
      return { ...prevState, isLoading };
    });
  };

  setAllDetails = async (id) => {
    await this.setDealership_Details(id);
    await this.setBusinessAnalysisData(id);
    await this.setAnnexures(id);
    await this.setMachinaryData(id);
    await this.setShowRoomSize(id);
    if (this.state.isNotFullPageViewDetail) {
      this.setPreObj();
    }
  };

  getPrposalById = (req_uuid = null) => {
    this.setState({ isLoading: true });
    ProposalSerivice.getProposalById(req_uuid ? req_uuid : this.state.uuid)
      .then((res) => {
        let { id, uuid } = this.state;

        if (res.data.data && res.data.data.length > 0) {
          if (req_uuid) {
            res.data.data[0].proposal_type = "renewal";
            id = res.data.data[0].id;
            uuid = req_uuid;
          } else {
            id = res.data.data[0].id;
            uuid = res.data.data[0].uuid;
          }
          this.setState(
            (prevState) => {
              return {
                ...prevState,
                getProposalDetail: res.data.data[0],
                id,
                uuid,
              };
            },
            () => {
              this.setAllDetails(req_uuid);
            }
          );
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  componentDidMount() {
    if (this.state.uuid) {
      this.getPrposalById();
    }
  }

  scrollTo(id) {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80,
    });
  }

  checkAllValidation(is_submitted) {
    let form1 = true;
    let form2 = true;
    let form3 = true,
      form4 = true;
    if (
      !this.childRefDealershipDetailsForm.current.handleValidation(false, true)
    ) {
      form1 = false;
    }
    if (is_submitted) {
      if (
        !this.childRefBusinessAnalysisForm.current.handleValidation(false, true)
      ) {
        form2 = false;
      }
      if (!this.childRefShowroomSizeForm.current.handleValidation(true)) {
        form4 = false;
      }
      if (
        !this.childRefMachineryDetails.current.handleValidation(false, true)
      ) {
        form3 = false;
      }
    }
    if (form1 === false) {
      this.scrollTo("form-dealership-detail");
    } else if (form2 === false) {
      this.scrollTo("form-business-detail");
    } else if (form4 === false) {
      this.scrollTo("form-showroomsize-detail");
    } else if (form3 === false) {
      this.scrollTo("form-machine-detail");
    }
    return !!(
      form1 === true &&
      form2 === true &&
      form3 === true &&
      form4 === true
    );
  }

  updateSaveProposalDetails = (e, type, isHo = false) => {
    e.preventDefault();
    if (!this.checkAllValidation(type === "submit")) {
      return;
    }
    const {
      dealership_Details,
    } = this.childRefDealershipDetailsForm.current.state;
    const {
      businessAnalysis,
    } = this.childRefBusinessAnalysisForm.current.state;
    const { showRoomSize } = this.childRefShowroomSizeForm.current.state;
    const {
      machineryDetailsExisting,
      machineryDetailsRequired,
      annexures,
    } = this.childRefMachineryDetails.current.state;

    let obj = {
      is_submitted: type === "submit",
      proposal_type: dealership_Details.controls.proposal_type.value,
      outlet_type: dealership_Details.controls.outlet_type.value,
      category: dealership_Details.controls.category.value,
      name: dealership_Details.controls.name.value,
      address: dealership_Details.controls.address.value,
      pincode: dealership_Details.controls.pincode.value,
      city: dealership_Details.controls.city.value,
      state: dealership_Details.controls.state.value,
      landline: dealership_Details.controls.landline.value,
      mobile: dealership_Details.controls.mobile.value,
      email_id: dealership_Details.controls.email_id.value,
      region: dealership_Details.controls.region.value,
      area: dealership_Details.controls.area.value,
      // so: dealership_Details.controls.so.value,
      location: [],
      so: dealership_Details.controls.so.value,
      ownership_type: businessAnalysis.controls.ownership_type.value,
      proprietor_name: businessAnalysis.controls.proprietor_name.value,
      is_in_tyre_business:
        businessAnalysis.controls.is_in_tyre_business.value === true ||
          businessAnalysis.controls.is_in_tyre_business.value === "true"
          ? true
          : false,
      counters: businessAnalysis.controls.counters.value,
      is_jk_authorized_dealer:
        businessAnalysis.controls.is_jk_authorized_dealer.value === true ||
          businessAnalysis.controls.is_jk_authorized_dealer.value === 'true'
          ? true
          : false,
      sap_id: businessAnalysis.controls.sap_id.value,
      sas_non_truck: +businessAnalysis.controls.sas_non_truck.value,
      sas_truck: +businessAnalysis.controls.sas_truck.value,
      is_premised_owner:
        businessAnalysis.controls.is_premised_owner.value === "true" ||
          businessAnalysis.controls.is_premised_owner.value === true
          ? true
          : false,
      shutters_count: +businessAnalysis.controls.shutters_count.value,
      counter_share_total_h1_turnover:
        businessAnalysis.controls.counter_share_total_h1_turnover.value,
      counter_share_total_year_turnover:
        businessAnalysis.controls.counter_share_total_year_turnover.value,
      proposed_business_h1_turnover:
        businessAnalysis.controls.proposed_business_h1_turnover.value,
      proposed_business_total_year_turnover:
        businessAnalysis.controls.proposed_business_total_year_turnover.value,
      present_offtake_total_h1_turnover:
        businessAnalysis.controls.present_offtake_total_h1_turnover.value,
      present_offtake_total_year_turnover:
        businessAnalysis.controls.present_offtake_total_year_turnover.value,
      total_counter_total_h1_potential:
        businessAnalysis.controls.total_counter_total_h1_potential.value,
      total_counter_total_year_potential:
        businessAnalysis.controls.total_counter_total_year_potential.value,
      showroom_frontage: showRoomSize.controls.showroom_frontage.value
        ? parseInt(showRoomSize.controls.showroom_frontage.value)
        : 0,
      showroom_depth: +showRoomSize.controls.showroom_depth.value,
      showroom_celling_height: +showRoomSize.controls.showroom_celling_height
        .value,
      showroom_area: +showRoomSize.controls.showroom_area.value,
      front_face_area: showRoomSize.controls.front_face_area.value
        ? +showRoomSize.controls.front_face_area.value
        : null,
      dealer_share_amount: showRoomSize.controls.dealer_share_amount.value
        ? +showRoomSize.controls.dealer_share_amount.value
        : null,
      having_separate_storage:
        showRoomSize.controls.having_separate_storage.value === true ||
          showRoomSize.controls.having_separate_storage.value === "true"
          ? true
          : false,
      storage_area: +showRoomSize.controls.storage_area.value,
      assets_value: +showRoomSize.controls.assets_value.value,
      other_business: showRoomSize.controls.other_business.value,
      willing_machinery_invest:
        annexures.controls.willing_machinery_invest.value === "true" ||
          annexures.controls.willing_machinery_invest.value === true
          ? true
          : false,
      willing_civil_invest:
        annexures.controls.willing_civil_invest.value === "true" ||
          annexures.controls.willing_civil_invest.value === true
          ? true
          : false,
      lease_aggrement: businessAnalysis.controls.lease_aggrement.value,
      site_picture: businessAnalysis.controls.site_picture.value,
      // lease_aggrement: 'lease-2482-42361568779401223.pdf',
      // site_picture: 'lease-2482-47051568779424995.png',
      is_agree_exclusive_jk:
        businessAnalysis.controls.is_agree_exclusive_jk.value === true ||
          businessAnalysis.controls.is_agree_exclusive_jk.value === "true"
          ? true
          : false,
      existing_shop_compitition:
        businessAnalysis.controls.existing_shop_compitition.value === "true" ||
          businessAnalysis.controls.existing_shop_compitition.value === true
          ? true
          : false,
      existing_show_compitition_name:
        businessAnalysis.controls.existing_show_compitition_name.value,
      compitition_brand_pcr:
        businessAnalysis.controls.compitition_brand_pcr.value,
      remarks: businessAnalysis.controls.remarks.value,
    };
    if (showRoomSize.controls.location.main_road.value) {
      obj.location.push("main_road");
    }
    if (showRoomSize.controls.location.commercial_area.value) {
      obj.location.push("commercial_area");
    }
    if (showRoomSize.controls.location.residental_area.value) {
      obj.location.push("residental_area");
    }
    if (showRoomSize.controls.location.tyre_auto_market.value) {
      obj.location.push("tyre_auto_market");
    }
    if (showRoomSize.controls.location.main_road.value) {
    }
    const machinery_existing = [];
    if (machineryDetailsExisting && machineryDetailsExisting.length > 0) {
      machineryDetailsExisting.forEach((element) => {
        machinery_existing.push({
          type: element.type,
          brand: element.brand.value,
          installation_date: element.installation_date.value
            ? element.installation_date.value.toISOString()
            : null,
          model: element.model.value,
        });
      });
      obj.machinery_existing = machinery_existing;
    }

    const machinery_required = [];
    if (machineryDetailsRequired && machineryDetailsRequired.length > 0) {
      machineryDetailsRequired.forEach((element) => {
        machinery_required.push({
          type: element.type,
          brand: element.brand.value,
          model: element.model.value,
        });
      });
      obj.machinery_required = machinery_required;
    }
    const counter_monthly = [];
    if (
      businessAnalysis.controls.counter_monthly &&
      businessAnalysis.controls.counter_monthly.length > 0
    ) {
      businessAnalysis.controls.counter_monthly.forEach((element, i) => {
        counter_monthly.push({
          type: element.type,
          car: +element.car.value,
          lcv: +element.lcv.value,
          scv: +element.scv.value,
          two_wheel: +element.two_wheel.value,
        });
        // if (element.compitition_brand_pcr) {
        //   counter_monthly[i].compitition_brand_pcr = +element.compitition_brand_pcr.value;
        // }
        // if (element.existing_shop_compitition) {
        //   counter_monthly[i].existing_shop_compitition = element.existing_shop_compitition.value;
        // }
        // if (element.existing_show_compitition_name) {
        //   counter_monthly[i].existing_show_compitition_name = element.existing_show_compitition_name.value;
        // }
        // if (element.is_agree_exclusive_jk) {
        //   counter_monthly[i].is_agree_exclusive_jk = element.is_agree_exclusive_jk.value;
        // }
        if (element.remarks) {
          counter_monthly[i].remarks = element.remarks.value;
        }
      });
      obj.counter_monthly = counter_monthly;
    }

    // if (isHo) {
    //   let changes_obj = this.difference(obj, this.state.preObj);
    //   if (changes_obj && Object.keys(changes_obj).length < 0) {
    //     return
    //   }
    //   obj.old_obj = {};
    //   let bodyRows='';
    //   Object.keys(changes_obj).forEach(key => {
    //     if(this.preObj[key] !== undefined){
    //       obj.old_obj[key] = null;
    //       obj.old_obj[key]=this.preObj[key];
    //       bodyRows += '<tr><td>'
    //       + this.preObj[key] + '<td>';
    //     }
    //     //use key and value here
    //   });
    //   obj.changes_obj = changes_obj;
    // }

    if (this.state.uuid) {
      obj.uuid = this.state.uuid;
    }
    if (this.state.id) {
      obj.id = this.state.id;
    }
    if (this.state.getProposalDetail && this.state.getProposalDetail.stage) {
      obj.stage = this.state.getProposalDetail.stage;
    }
    if (
      this.state.getProposalDetail &&
      this.state.getProposalDetail.project_status
    ) {
      obj.project_status = this.state.getProposalDetail.project_status;
    }

    let changes_obj = [];
    if (
      (this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE ||
        this.state.userDetail.type === Roles.AREA_MANAGER_ROLE) &&
      this.state.uuid
    ) {
      changes_obj = CheckChangeObjProposalDetail(obj, this.state.preObj);
    } else {
      if (!this.state.isNotFullPageViewDetail) {
        if (obj.proposal_type === "renewal") {
          obj["project_status"] = "Fresh Proposal";
          obj["stage"] = null;
        }
      }
    }

    obj.changes_obj = changes_obj;

    this.setState({ isLoading: true });
    ProposalSerivice.saveNewDealer(obj)
      .then((data) => {
        this.setState({ isLoading: false });
        swal({
          title: "Congratulations !",
          text: data.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          if (!this.state.isNotFullPageViewDetail) {
            this.props.history.push("proposal-dashboard");
          } else {
            this.goToPrevPage();
          }
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        try {
          const err =
            e.response && e.response.data && e.response.data.error[0]
              ? e.response.data.error[0]
              : null;
          const message = err && err.message ? err.message : ERROR_MESSAGE;
          ModalService.openAlert("", message, "error");
        } catch (error) {
          ModalService.openAlert(
            "",
            messages.WARRANTY_REGISTRATION.ERRORMESSAGE,
            "error"
          );
        }
      });
  };

  updateOutletType = (outlet_type) => {
    this.childRefShowroomSizeForm.current.setOutletType(outlet_type);
    this.childRefBusinessAnalysisForm.current.setOutletType(outlet_type);
    // this.setState({
    //   getProposalDetail: {}
    // })
  };

  setPreObj = () => {
    const {
      dealership_Details,
    } = this.childRefDealershipDetailsForm.current.state;
    const {
      businessAnalysis,
    } = this.childRefBusinessAnalysisForm.current.state;
    const { showRoomSize } = this.childRefShowroomSizeForm.current.state;
    const {
      machineryDetailsExisting,
      machineryDetailsRequired,
      annexures,
    } = this.childRefMachineryDetails.current.state;
    let obj = {
      is_submitted: true,
      proposal_type: dealership_Details.controls.proposal_type.value,
      outlet_type: dealership_Details.controls.outlet_type.value,
      category: dealership_Details.controls.category.value,
      name: dealership_Details.controls.name.value,
      address: dealership_Details.controls.address.value,
      pincode: dealership_Details.controls.pincode.value,
      city: dealership_Details.controls.city.value,
      state: dealership_Details.controls.state.value,
      landline: dealership_Details.controls.landline.value,
      mobile: dealership_Details.controls.mobile.value,
      email_id: dealership_Details.controls.email_id.value,
      region: dealership_Details.controls.region.value,
      area: dealership_Details.controls.area.value,
      // so: dealership_Details.controls.so.value,
      location: [],
      so: dealership_Details.controls.so.value,
      ownership_type: businessAnalysis.controls.ownership_type.value,
      proprietor_name: businessAnalysis.controls.proprietor_name.value,
      is_in_tyre_business:
        businessAnalysis.controls.is_in_tyre_business.value === "true" ||
          businessAnalysis.controls.is_in_tyre_business.value === true
          ? true
          : false,
      counters: businessAnalysis.controls.counters.value,
      is_jk_authorized_dealer:
        businessAnalysis.controls.is_jk_authorized_dealer.value === "true" ||
          businessAnalysis.controls.is_jk_authorized_dealer.value === true
          ? true
          : false,
      sap_id: businessAnalysis.controls.sap_id.value,
      sas_non_truck: +businessAnalysis.controls.sas_non_truck.value,
      sas_truck: +businessAnalysis.controls.sas_truck.value,
      counter_share_total_h1_turnover:
        businessAnalysis.controls.counter_share_total_h1_turnover.value,
      counter_share_total_year_turnover:
        businessAnalysis.controls.counter_share_total_year_turnover.value,
      proposed_business_h1_turnover:
        businessAnalysis.controls.proposed_business_h1_turnover.value,
      proposed_business_total_year_turnover:
        businessAnalysis.controls.proposed_business_total_year_turnover.value,
      present_offtake_total_h1_turnover:
        businessAnalysis.controls.present_offtake_total_h1_turnover.value,
      present_offtake_total_year_turnover:
        businessAnalysis.controls.present_offtake_total_year_turnover.value,
      total_counter_total_h1_potential:
        businessAnalysis.controls.total_counter_total_h1_potential.value,
      total_counter_total_year_potential:
        businessAnalysis.controls.total_counter_total_year_potential.value,
      is_premised_owner:
        businessAnalysis.controls.is_premised_owner.value === "true" ||
          businessAnalysis.controls.is_premised_owner.value === true
          ? true
          : false,
      shutters_count: +businessAnalysis.controls.shutters_count.value,
      showroom_frontage: showRoomSize.controls.showroom_frontage.value
        ? parseInt(showRoomSize.controls.showroom_frontage.value)
        : 0,
      showroom_depth: +showRoomSize.controls.showroom_depth.value,
      showroom_celling_height: +showRoomSize.controls.showroom_celling_height
        .value,
      showroom_area: +showRoomSize.controls.showroom_area.value,
      front_face_area: showRoomSize.controls.front_face_area.value
        ? +showRoomSize.controls.front_face_area.value
        : null,
      dealer_share_amount: showRoomSize.controls.dealer_share_amount.value
        ? +showRoomSize.controls.dealer_share_amount.value
        : null,
      having_separate_storage:
        showRoomSize.controls.having_separate_storage.value === true || showRoomSize.controls.having_separate_storage.value === 'true'
          ? true
          : false,
      storage_area: +showRoomSize.controls.storage_area.value,
      assets_value: +showRoomSize.controls.assets_value.value,
      other_business: showRoomSize.controls.other_business.value,
      willing_machinery_invest:
        annexures.controls.willing_machinery_invest.value === true ||
          annexures.controls.willing_machinery_invest.value === 'true'
          ? true
          : false,
      willing_civil_invest: annexures.controls.willing_civil_invest.value,
      lease_aggrement: businessAnalysis.controls.lease_aggrement.value,
      site_picture: businessAnalysis.controls.site_picture.value,
      // lease_aggrement: 'lease-2482-42361568779401223.pdf',
      // site_picture: 'lease-2482-47051568779424995.png',
      is_agree_exclusive_jk:
        businessAnalysis.controls.is_agree_exclusive_jk.value === "true" ||
          businessAnalysis.controls.is_agree_exclusive_jk.value === true
          ? true
          : false,
      existing_shop_compitition:
        businessAnalysis.controls.existing_shop_compitition.value,
      existing_show_compitition_name:
        businessAnalysis.controls.existing_show_compitition_name.value,
      compitition_brand_pcr:
        businessAnalysis.controls.compitition_brand_pcr.value,
      remarks: businessAnalysis.controls.remarks.value,
    };
    if (showRoomSize.controls.location.main_road.value) {
      obj.location.push("main_road");
    }
    if (showRoomSize.controls.location.commercial_area.value) {
      obj.location.push("commercial_area");
    }
    if (showRoomSize.controls.location.residental_area.value) {
      obj.location.push("residental_area");
    }
    if (showRoomSize.controls.location.tyre_auto_market.value) {
      obj.location.push("tyre_auto_market");
    }
    if (showRoomSize.controls.location.main_road.value) {
    }
    const machinery_existing = [];
    if (machineryDetailsExisting && machineryDetailsExisting.length > 0) {
      machineryDetailsExisting.forEach((element) => {
        machinery_existing.push({
          type: element.type,
          brand: element.brand.value,
          installation_date: element.installation_date.value
            ? element.installation_date.value.toISOString()
            : null,
          model: element.model.value,
        });
      });
      obj.machinery_existing = machinery_existing;
    }

    const machinery_required = [];
    if (machineryDetailsRequired && machineryDetailsRequired.length > 0) {
      machineryDetailsRequired.forEach((element) => {
        machinery_required.push({
          type: element.type,
          brand: element.brand.value,
          model: element.model.value,
        });
      });
      obj.machinery_required = machinery_required;
    }
    const counter_monthly = [];
    if (
      businessAnalysis.controls.counter_monthly &&
      businessAnalysis.controls.counter_monthly.length > 0
    ) {
      businessAnalysis.controls.counter_monthly.forEach((element, i) => {
        counter_monthly.push({
          type: element.type,
          car: +element.car.value,
          lcv: +element.lcv.value,
          scv: +element.scv.value,
          two_wheel: +element.two_wheel.value,
        });
        // if (element.compitition_brand_pcr) {
        //   counter_monthly[i].compitition_brand_pcr = +element.compitition_brand_pcr.value;
        // }
        // if (element.existing_shop_compitition) {
        //   counter_monthly[i].existing_shop_compitition = element.existing_shop_compitition.value;
        // }
        // if (element.existing_show_compitition_name) {
        //   counter_monthly[i].existing_show_compitition_name = element.existing_show_compitition_name.value;
        // }
        // if (element.is_agree_exclusive_jk) {
        //   counter_monthly[i].is_agree_exclusive_jk = element.is_agree_exclusive_jk.value;
        // }
        if (element.remarks) {
          counter_monthly[i].remarks = element.remarks.value;
        }
      });
      obj.counter_monthly = counter_monthly;
    }
    this.setState({
      preObj: obj,
    });
  };
  compareObject(object, base) {
    function changes(object, base) {
      return _.transform(object, function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
          if (base.type) {
            result["type"] = base.type;
          }
        }
      });
    }
    return changes(object, base);
  }

  render() {
    let { getProposalDetail } = this.state;
    let isDisplaySaveDraft = false,
      is_display_submit = true;
    if (
      getProposalDetail &&
      getProposalDetail.project_status === "Save Draft Proposal"
    ) {
      isDisplaySaveDraft = true;
    }
    if (
      getProposalDetail &&
      getProposalDetail.project_status === "Proposal rejected"
    ) {
      if (this.childRefDealershipDetailsForm.current) {
        const {
          dealership_Details,
        } = this.childRefDealershipDetailsForm.current.state;
        if (
          dealership_Details.controls.proposal_type.value &&
          dealership_Details.controls.proposal_type.value === "fresh"
        ) {
          is_display_submit = false;
        }
      } else {
        is_display_submit = false;
      }
    }
    return (
      <>
        {!this.state.isNotFullPageViewDetail && (
          <>
            <div className="header-title">Proposal Details</div>
            <br />
          </>
        )}
        <DealershipDetailsForm
          goToPrevPage={() => this.goToPrevPage()}
          dealership_Details={this.state.dealership_Details}
          getProposalDetail={this.state.getProposalDetail}
          isLoadingSet={this.isLoadingSet}
          ref={this.childRefDealershipDetailsForm}
          updateOutletType={this.updateOutletType}
          setPreObj={this.setPreObj}
          isNotFullPageViewDetail={this.state.isNotFullPageViewDetail}
          getPrposalById={this.getPrposalById}
          editDeatil={(e, type) =>
            this.updateSaveProposalDetails(e, type, true)
          }
          setDetail={this.setDealership_Details}
          resetData={this.resetData}
          id={this.state.id}
        />
        <br />
        <BusinessAnalysisForm
          businessAnalysis={this.state.businessAnalysis}
          getProposalDetail={this.state.getProposalDetail}
          isLoadingSet={this.isLoadingSet}
          editDeatil={(e, type) =>
            this.updateSaveProposalDetails(e, type, true)
          }
          setDetail={this.setBusinessAnalysisData}
          ref={this.childRefBusinessAnalysisForm}
        />
        <br />
        <ShowroomSizeForm
          showRoomSize={this.state.showRoomSize}
          editDeatil={(e, type) =>
            this.updateSaveProposalDetails(e, type, true)
          }
          setDetail={this.setShowRoomSize}
          ref={this.childRefShowroomSizeForm}
        />
        <br />
        <MachineryDetails
          machineryDetailsRequired={this.state.machineryDetailsRequired}
          machineryDetailsExisting={this.state.machineryDetailsExisting}
          annexures={this.state.annexures}
          editDeatil={(e, type) =>
            this.updateSaveProposalDetails(e, type, true)
          }
          isLoadingSet={this.isLoadingSet}
          getProposalDetail={this.state.getProposalDetail}
          setDetail={this.setMachinaryData}
          isNotFullPageViewDetail={this.state.isNotFullPageViewDetail}
          goToPrevPage={() => this.goToPrevPage()}
          ref={this.childRefMachineryDetails}
        />
        <br />
        {(this.state.userDetail.access_level === "area" ||
          this.state.userDetail.access_level === "all") && (
            <Row>
              <Col xl="12" className="text-align-right">
                {is_display_submit && (
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    onClick={(e) => this.updateSaveProposalDetails(e, "submit")}
                  >
                    Submit
                  </Button>
                )}
                {(isDisplaySaveDraft || !this.state.isNotFullPageViewDetail) && (
                  <>
                    &nbsp;&nbsp;
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      onClick={(e) =>
                        this.updateSaveProposalDetails(e, "SaveinDraft")
                      }
                    >
                      Save in Draft
                    </Button>
                  </>
                )}
                &nbsp;&nbsp;
                {!this.props.is_display_view_deatil && (
                  <Button
                    className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                    onClick={() => this.props.history.goBack()}
                    color="dark"
                  >
                    Back
                  </Button>
                )}
                {/* <Button className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" color="dark">Cancel</Button> */}
              </Col>
              {/* <Col xl="1"></Col> */}
            </Row>
          )}

        {this.state.isLoading && <CustomSpinner />}
      </>
    );
  }
}

export default ProposalDetails;
