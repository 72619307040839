import { request } from "./Request";
import { routes } from "../constants/constant.route";

const API_URL = process.env.REACT_APP_API_URL;

export default class GenerativeAIService {
  static getAnswer(req) {
    return request(
      "POST",
      `${API_URL}${routes.GENERATIVE_AI.POST_QUESTION}`,
      null,
      req,
      null
    );
  }
}
