import React, { Component } from "react";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import DashboardService from "../../services/DashboardService";
// utils
import { downlodFile } from "../../utils/utils";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import "./DownloadScoreCard.css";

class DownloadScorecard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthOptions: [],
      yearOptions: [],
      yeartoDownload: null,
      isLoader: false,
    };
  }

  componentDidMount() {
    this.filterAPI();
  }

  onChangeYear = (e) => {
    this.setState({
      yeartoDownload: e && e.target && e.target.value,
    });
  };

  filterAPI = () => {
    const { yeartoDownload } = this.state;
    let filter = {
      y: yeartoDownload ? yeartoDownload : null,
    };
    this.setState({ isLoader: true });
    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          this.setState({ isLoader: false });

          this.setState(
            {
              yearOptions: res.data.data.year ? res.data.data.year : [],
              monthOptions: res.data.data.month ? res.data.data.month : [],
            },
            () => {
              if (
                !this.state.yeartoDownload &&
                this.state.yearOptions &&
                this.state.yearOptions[0]
              ) {
                this.setState({
                  yeartoDownload: this.state.yearOptions[0].value,
                });
              }
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        console.log("err", err);
      });
  };

  downloadScorecard = () => {
    this.setState({
      isLoader: true,
    });
    let filter = {
      y: this.state.yeartoDownload ? this.state.yeartoDownload : null,
    };
    DashboardService.downloadScoreCard(filter)
      .then((data) => {
        this.setState({
          isLoader: false,
        });
        downlodFile(data.data.data.location);
        this.props.closeModal();
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        console.error("e", e);
      });
  };

  render() {
    const { yearOptions,
      // monthOptions
    } = this.state;

    const yearOptionToDisplay = yearOptions.map((y) => (
      <option value={y.value}>{y.name}</option>
    ));

    // const monthOptionToDisplay = monthOptions.map((m) => (
    //   <option value={m.value}>{m.name}</option>
    // ));

    return (
      <>
        <Modal
          id="download-scorecard"
          isOpen={this.props.ShowDownloadScorcard}
          toggle={this.props.openModel}
          className="modelCard"
          centered
        >
          {this.state.isLoader && <CustomSpinner />}
          <ModalBody>
            <div>
              <Col xl-12>
                <Row
                  style={{
                    marginTop: "5px",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Col className="label" xl="10" md="10" sm="10">
                    <b>Download Scorecard</b>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    style={{
                      marginTop: "15px",
                      cursor: "pointer",
                      paddingLeft: "40px",
                    }}
                  >
                    <img
                      src="./images/close1.svg"
                      alt=''
                      onClick={this.props.closeModal}
                    />
                  </Col>
                </Row>
                <div className="border" />
                <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                  <Col>
                    <FormGroup>
                      <Label className="label-select">Select FY Year</Label>
                      <br />
                      <select
                        name="year"
                        className="form-control"
                        value={this.state.year}
                        onChange={(e) => {
                          this.onChangeYear(e);
                        }}
                      >
                        {yearOptionToDisplay}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Col
                  xl="12"
                  md="12"
                  sm="12"
                  style={{
                    padding: "-1px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}
                >
                  {/* <Button
                    style={{
                      width: "95px",
                      backgroundColor: "#f7b924",
                      border: "none",
                    }}
                    onClick={this.downloadScorecard}
                    className="mb-1 mr-1 btn-pill action-button"
                  >
                    <b>Download</b>
                  </Button> */}
                  <Button
                    className="mb-1 mr-1 btn-pill action-button profile-save-button"
                    color="warning"
                    onClick={this.downloadScorecard}
                  >
                    Download
                  </Button>
                </Col>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default DownloadScorecard;
