import { request } from "./Request";
import { routes } from "../constants/constant.route";
const {
  GET_ALL_INVITEE,
  INVITE_AGAIN,
  INVITE_DEALER,
  DELETE_INVITEE,
  EDIT_INVITEE,
} = routes.SUB_DEALER;
const API_URL = process.env.REACT_APP_API_URL;

export default class SubDealerService {
  static inviteSubDealerOrBranch(obj) {
    return request("POST", `${API_URL}${INVITE_DEALER}`, null, obj, null);
  }

  static getAllInvitees() {
    return request("GET", `${API_URL}${GET_ALL_INVITEE}`, null, null, null);
  }

  static getAllInviteesAccepted(sapid) {
    return request(
      "GET",
      `${API_URL}${GET_ALL_INVITEE}?status=accepted&sap_id=${sapid}`,
      null,
      null,
      null
    );
  }

  static inviteAgain(obj) {
    return request("POST", `${API_URL}${INVITE_AGAIN}`, null, obj, null);
  }

  static editInvitee(obj) {
    return request("POST", `${API_URL}${EDIT_INVITEE}`, null, obj, null);
  }

  static deleteInvitee(inviteeId) {
    let q = "";
    if (inviteeId) {
      q = `invite_id=${inviteeId}`;
    }
    return request("GET", `${API_URL}${DELETE_INVITEE}?${q}`, null, null, null);
  }
}
