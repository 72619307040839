/**
 * @name WarrantyRegistration.js
 * @desc Warranty Registration
 * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
 * @version 0.0.0
 */

import React, { Component, Fragment } from "react";
import { Animated } from "react-animated-css";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import swal from "sweetalert";

import CustomerDetails from "../../components/WarrantyRegistration/CustomerDetails/CustomerDetails";
import CustomerDetailsEdit from "../../components/WarrantyRegistration/CustomerDetails/CustomerDetailsEdit";
import VehicleDetails from "../../components/WarrantyRegistration/VehicleDetails/VehicleDetails";
import VehicleType from "../../components/WarrantyRegistration/VehicleDetails/VehicleType";
//import SelectState from '../../components/WarrantyRegistration/VehicleDetails/SelectState';
//import SelectCity from '../../components/WarrantyRegistration/VehicleDetails/SelectCity';
import VehicleRegistrationNumber from "../../components/WarrantyRegistration/VehicleDetails/VehicleRegistrationNumber";
import ProductDetails from "../../components/WarrantyRegistration/ProductDetails/ProductDetails";
import ProductDetailsEdit from "../../components/WarrantyRegistration/ProductDetails/ProductDetailsEdit";
//import VehicalMeterReading from '../../components/WarrantyRegistration/VehicleDetails/VehicalMeterReading';
import VehicleMake from "../../components/WarrantyRegistration/VehicleDetails/VehicleMake";
import VehicalModel from "../../components/WarrantyRegistration/VehicleDetails/VehicalModel";
//mport VehicleVariant from '../../components/WarrantyRegistration/VehicleDetails/VehicleVariant';
//import VehicleRegistrationYear from '../../components/WarrantyRegistration/VehicleDetails/VehicleRegistrationYear';
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import WarrantyRegistrationService from "../../services/WarrantyRegistrationService";
import StorageService from "../../services/Storage";

import { messages } from "../../constants/constant.message";

import "./WarrantyRegistration.css";
import ModalService from "../../services/ModalService";
import { connect } from "react-redux";
import { setAddCustomerRedirect } from "../../actions/UserAction";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

class WarrantyRegistration extends Component {
  isMobile = false;
  constructor(props) {
    super(props);
    this.state = {
      sensorNumberAdded: 0,
      queryString: "",
      visibleArea: "customerDetails",
      animateDetails: true,
      /**Getting the user detail from the local storage */
      userDetail: StorageService.getUserDetail(),
      customerDetails: {
        controls: {
          firstname: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "first_name",
          },
          lastname: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "last_name",
          },
          email: {
            value: null,
            valid: true,
            touched: false,
            message: null,
            nullValue: null,
            invalidEmail: null,
            databaseFieldName: "email_id",
          },
          phoneNumber: {
            value: "",
            valid: true,
            touched: false,
            message: null,
            nullValue: null,
            invalidPhoneNumber: null,
            databaseFieldName: "phone_number",
          },
          ownerPhoneNumber: {
            value: "",
            valid: true,
            touched: false,
            message: null,
            nullValue: null,
            invalidPhoneNumber: null,
            sameAsDriverNumber: false,
            databaseFieldName: "owner_phone_number",
          },
          type: "",
          couponCode: "",
          couponToken: "",
        },
        isValidForm: false,
      },
      vehicleDetails: {
        controls: {
          vehicleId: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "vehicle_id",
          },
          state: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "state_id",
          },
          city: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "city_id",
          },
          vehicleTypeId: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "vehicle_type_id",
          },
          vehicleTypeBrandId: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "vehicle_type_brand_id",
          },
          vehicleTypeBrandModelId: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidEmail: null,
            databaseFieldName: "vehicle_type_brand_model_id",
          },
          vehicleTypeBrandModelVariationId: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "vehicle_type_brand_model_variation_id",
          },
          registrationNumber: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "registration_number",
          },
          registrationYear: {
            value: null,
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "registration_year",
          },
          vehicleCategory: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "vehicle_category",
          },
          vehicleAvgMonthlyRunning: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "vehical_avg_monthly_running",
          },
        },
        isValidForm: false,
      },
      productDetails: {
        controls: {
          tyreQuantity: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "tyre_qty",
          },
          skuId1: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "sku_id_1",
          },
          skuIdBack: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "sku_id_back",
          },
          skuIdSpare: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "sku_id_spare",
          },
          tyreDescription: {
            value: "",
            valid: null,
            touched: false,
            message: null,
            nullValue: null,
            invalidFirstname: null,
            databaseFieldName: "tyre_description",
          },
          stencilNumber0: {
            value: {
              stencil_number: "",
              name: "stencilNumber0",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          stencilNumber1: {
            value: {
              stencil_number: "",
              name: "stencilNumber1",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          stencilNumber2: {
            value: {
              stencil_number: "",
              name: "stencilNumber2",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          stencilNumber3: {
            value: {
              stencil_number: "",
              name: "stencilNumber3",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          stencilNumber4: {
            value: {
              stencil_number: "",
              name: "stencilNumber4",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          stencilNumber5: {
            value: {
              stencil_number: "",
              name: "stencilNumber5",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          sensorNumber0: {
            value: {
              sensor_number: "",
              name: "sensorNumber0",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          sensorNumber1: {
            value: {
              sensor_number: "",
              name: "sensorNumber1",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          sensorNumber2: {
            value: {
              sensor_number: "",
              name: "sensorNumber2",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          sensorNumber3: {
            value: {
              sensor_number: "",
              name: "sensorNumber3",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          sensorNumber4: {
            value: {
              sensor_number: "",
              name: "sensorNumber4",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
          sensorNumber5: {
            value: {
              sensor_number: "",
              name: "sensorNumber5",
            },
            tempValue: "",
            valid: false,
            touched: false,
            message: null,
            nullValue: null,
            isTyre: true,
            invalidFirstname: null,
            databaseFieldName: "",
          },
        },
      },
      vehicleLocalRegistrationNumber: {
        tempRegistrationNumber: 1,
      },
      stencilNumberInputBoxArray: [],
      sensorNumberInputBoxArray: [],
      registeredVehicleDetails: [],
      token: "",
      isEditMode: false,
      isExisting: false,
      isLoading: false,
      isDataLoading: false,
      redirectToDashboard: false,
      redirectToWarrantyReplacement: false,
      from: "",
      redirectionWarrantySelectVehicle: "",
      userRole: null,
      is_from_add_customer: false,
    };
    this.customerDetailsEditRef = React.createRef();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.location.search !== prevState.queryString &&
      prevState.queryString !== "" &&
      prevProps.location.search !== ""
    ) {
      window.location.reload();
    } else {
    }
  };

  componentWillMount() {
    /**Getting the Phone Number, token , coupon code, Coupon Token from the url if its redirect from Redeem coupon */
    const query = new URLSearchParams(this.props.location.search);
    const queryString = this.props.location.search;
    const phoneNumber = query.get("phoneNumber");
    const redirectionWarrantySelectVehicle = phoneNumber;
    const token = query.get("t");
    const couponCode = query.get("couponCode");
    const couponToken = query.get("couponToken");
    const type = query.get("type");
    if (!phoneNumber || (phoneNumber && phoneNumber.length !== 10) || !token) {
      this.redirectDashboard();
    }
    const from = query.get("from");
    if (from && from === "warrantyReplacement") {
      const { vehicleDetails } = this.state;
      const { controls } = vehicleDetails;
      controls.vehicleTypeId.value = {
        vehicle_type_id: query.get("typeId"),
        name: query.get("typeName"),
      };
      controls.vehicleCategory.value = query.get("vehicleCategory");
      this.setState({ from, controls, redirectionWarrantySelectVehicle });
    }

    const { customerDetails } = this.state;
    const { controls } = customerDetails;
    controls.couponCode = couponCode;
    controls.couponToken = couponToken;
    controls.type = type;
    this.setState({ customerDetails, token, queryString });
    /**Getting the detail of the customer from the mobile number */
    this.findCustomer(phoneNumber, "+91");
    this.checkUserAgent();
  }

  componentDidMount() {
    if (this.isMobile) {
      const appMain = document.getElementsByClassName("app-main");
      const appContainer = document.getElementsByClassName("app-container");
      appMain[0].style.backgroundColor = "#fff";
      appContainer[0].style.backgroundColor = "#fff";
    }
    const userDetail = StorageService.getUserDetail();
    const { type: userRole } = userDetail;
    let { is_from_add_customer } = this.state;
    if (localStorage.getItem("add_customer")) {
      let data = JSON.parse(localStorage.getItem("add_customer"));
      const query = new URLSearchParams(this.props.location.search);
      const phoneNumber = query.get("phoneNumber");
      if (data && data.phone_number === phoneNumber) {
        is_from_add_customer = true;
      }
    }
    this.setState({ userRole, is_from_add_customer });
  }

  componentWillUnmount() {
    const appMain = document.getElementsByClassName("app-main");
    const appContainer = document.getElementsByClassName("app-container");
    appMain[0].style.backgroundColor = "#f1f4f6";
    appContainer[0].style.backgroundColor = "#f1f4f6";
  }

  /**
   * @name updateState
   * @desc The method is used to update state from child components
   * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
   * @version 0.0.0
   */
  updateState = (params) => {
    if (!params.isSameRoute) {
      this.setState((prevState) => {
        return { ...prevState, animateDetails: false };
      });
    }

    setTimeout(() => {
      this.setState((prevState) => {
        return { ...prevState, ...params, animateDetails: true };
      });
    }, 300);
  };

  updateStateSensorNumber = (params) => {
    this.setState({ sensorNumberAdded: params.sensorNumberAdded }, () => {
    });
  };

  /**
   * @name onRegisterClick
   * @desc The method is called on Next click from Product Details screen
   * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
   * @version 0.0.0
   */
  onRegisterClick = async () => {
    try {
      const {
        customerDetails,
        vehicleDetails,
        productDetails,
        token,
        stencilNumberInputBoxArray,
        sensorNumberInputBoxArray,
      } = this.state;
      const obj = WarrantyRegistrationService.buildRegistrationObject({
        customerDetails: customerDetails.controls,
        vehicleDetails: vehicleDetails.controls,
        productDetails: productDetails.controls,
        token,
        stencilNumberInputBoxArray,
        sensorNumberInputBoxArray,
      });

      const {
        data,
      } = await WarrantyRegistrationService.postWarrantyRegistration(obj);
      if (data && data.success) {
        swal(
          "Congratulations !",
          data.message || messages.WARRANTY_REGISTRATION.SUCCESSREGISTRATION,
          "success"
        );
        if (this.state.is_from_add_customer) {
          this.props.history.push(`add-customer`);
        } else {
          this.redirectDashboard();
        }
      } else {
        swal("Sorry !", messages.WARRANTY_REGISTRATION.ERRORMESSAGE, "error");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error[0] &&
        error.response.data.error[0].message
      ) {
        return swal("", error.response.data.error[0].message, "warning");
      }
      swal("Sorry !", messages.WARRANTY_REGISTRATION.ERRORMESSAGE, "error");
    }
  };

  /**
   * @name cancelClick
   * @desc The method is called will redirect on dashboard on cancel click
   * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
   * @version 0.0.0
   */
  cancelClick = () => {
    const { from, redirectionWarrantySelectVehicle } = this.state;
    swal({
      title: messages.WARRANTY_REGISTRATION.ONCANCELCLICKTITLE,
      text: messages.WARRANTY_REGISTRATION.ONCANCELCLICKMESSAGE,
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        if (
          from &&
          from === "warrantyReplacement" &&
          redirectionWarrantySelectVehicle
        ) {
          this.redirectToWarrantyReplacement();
        } else {
          this.redirectDashboard();
        }
      }
    });
  };

  /**
   * @name redirectDashboard
   * @desc The method is used to redirect on dashboard
   * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
   * @version 0.0.0
   */
  redirectDashboard() {
    let { redirectToDashboard } = this.state;
    redirectToDashboard = true;
    this.setState({ redirectToDashboard });
  }

  /**
   * @name redirectToWarrantyReplacement
   * @desc The method is used to redirect on Warranty Check
   * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
   * @version 0.0.0
   */
  redirectToWarrantyReplacement() {
    let { redirectToWarrantyReplacement } = this.state;
    redirectToWarrantyReplacement = true;
    this.setState({ redirectToWarrantyReplacement });
  }

  /**
   * @name checkUserAgent
   * @desc The method will return whether the device is browser or mobile
   * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
   * @version 0.0.0
   * @returns string
   */
  checkUserAgent() {
    this.isMobile = false;
    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this.isMobile = true;
      return "mobile";
    }
    if (/Chrome/i.test(ua)) {
      return "chrome";
    }
    return "other";
  }

  /**
   * @name findCustomer
   * @desc The method will find the customer
   * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
   * @version 0.0.0
   * @params {number, string}
   */
  async findCustomer(phone_number, country_code) {
    const { customerDetails, vehicleDetails } = this.state;
    let { registeredVehicleDetails } = this.state;
    let { isEditMode } = this.state;
    const { controls } = customerDetails;
    const { firstname, lastname, email, phoneNumber } = controls;
    let { isValidForm } = controls;
    try {
      const obj = {
        phone_number,
        country_code,
      };
      this.setState({ isLoading: true });
      const qResponse = await WarrantyRegistrationService.getCustomer(obj);
      const { data } = qResponse;

      isEditMode = false;
      if (data.success) {
        // set customer details
        firstname.value = data.data.customerDetails.first_name;
        lastname.value = data.data.customerDetails.last_name;
        email.value = data.data.customerDetails.email_id;
        phoneNumber.value = data.data.customerDetails.phone_number;
        isValidForm = true;
        // set customer details

        isEditMode = true;
        registeredVehicleDetails = data.data.vehicleDetails; // setting registeredVehicleDetails
        vehicleDetails.isValidForm = true;
        customerDetails.isValidForm = true;
        this.setState(
          {
            isEditMode,
            vehicleDetails,
            registeredVehicleDetails,
            customerDetails,
            isLoading: false,
          },
          () => {
            if (
              this.customerDetailsEditRef.current &&
              this.customerDetailsEditRef.current.setDataFromProps
            ) {
              this.customerDetailsEditRef.current.setDataFromProps();
            }
          }
        );
        if (
          this.state.userDetail.permissions &&
          this.state.userDetail.permissions.includes(Permissions.CUSTOMER_MANAGEMENT)
        ) {
          this.getCustomerDetail(data.data.customerDetails.phone_number);
        }
      } else {
        phoneNumber.value = phone_number;
        this.setState({ customerDetails, isLoading: false });
        if (
          this.state.is_from_add_customer &&
          this.state.userDetail.permissions &&
          this.state.userDetail.permissions.includes(Permissions.CUSTOMER_MANAGEMENT)
        ) {
          this.getCustomerDetail(phone_number);
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  }

  getCustomerDetail = async (phone_number = null) => {
    let params = {
      country_code: "91",
      phone_number: phone_number ? phone_number : this.state.phone_number,
    };
    this.setState({
      isLoading: true,
    });
    let cusromer_detail_data = await WarrantyRegistrationService.getCustomerDetail(
      params
    )
      .then((res) => {
        let { customerDetails, isEditMode } = this.state;
        const { controls } = customerDetails;
        const { firstname, lastname, email } = controls;
        if (res.data.data && Object.keys(res.data.data).length > 0) {
          firstname.value = res.data.data.customer.first_name;
          lastname.value = res.data.data.customer.last_name;
          email.value = res.data.data.customer.email_id;
        }
        isEditMode = true;
        this.setState({
          customerDetails,
          isLoading: false,
          isEditMode,
        });
        return res.data.data;
      })
      .catch((e) => {
        console.log("error", e);
        this.setState({
          isLoading: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        return false;
      });

    return cusromer_detail_data;
  };

  isLoadingComponents = (isLoading) => {
    this.setState({
      isDataLoading: isLoading,
    });
  };

  redirectDetailsAddCustomer = () => {
    let that = this;
    that.props.setAddCustomerRedirect({ redirectToAddCustomer: true });
    return true;
  };

  render() {
    const {
      from,
      token,
      visibleArea,
      animateDetails,
      vehicleLocalRegistrationNumber,
      customerDetails,
      vehicleDetails,
      productDetails,
      registeredVehicleDetails,
      isEditMode,
      isLoading,
      redirectToDashboard,
      redirectToWarrantyReplacement,
      redirectionWarrantySelectVehicle,
      userRole,
      isExisting,
      userDetail,
    } = this.state;
    return (
      <>
        {true && (
          <Fragment>
            <div>{isLoading ? <CustomSpinner /> : ""}</div>
            {/** Redirecting to certain url based on the type or customer_class*/}
            {redirectToDashboard &&
              userRole === Roles.DEALER_ROLE &&
              ((this.state.userDetail.customer_class).toLowerCase() === "sw" ||
                (this.state.userDetail.customer_class).toLowerCase() === "xw" ? (
                <Redirect to="/dealer-dashboard" />
              ) : userRole === Roles.SUB_DEALER_ROLE ||
                userRole === Roles.BRANCH_ROLE ||
                userDetail.customer_class === "hy" ||
                userDetail.customer_class === "na" ? (
                <Redirect to="/warranty-score-card" />
              ) : (
                <Redirect to="/" />
              ))}
            {redirectToDashboard &&
              (userRole === Roles.SUB_DEALER_ROLE ||
                userRole === Roles.BRANCH_ROLE ||
                userDetail.customer_class === "hy" ||
                userDetail.customer_class === "na") && (
                <Redirect to="/warranty-score-card" />
              )}
            {redirectToDashboard && userRole === Roles.DISTRIBUTOR_ROLE && (
              <Redirect to="/warranty-score-card" />
            )}
            {redirectToWarrantyReplacement && (
              <Redirect
                to={{
                  pathname: "/warranty-replacement",
                  search: `phoneNumber=${redirectionWarrantySelectVehicle}&t=${token}`,
                }}
              />
            )}

            {!this.isMobile && (
              <div>
                <span className="Warranty-Registration">
                  Warranty Registration
                </span>
                &nbsp;&nbsp;
                <span
                  onClick={this.cancelClick}
                  style={{
                    color: " #0056b3",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  Cancel
                </span>
              </div>
            )}

            <div
              className={classnames(
                {
                  "disable-text-box-margin": !this.isMobile,
                  "enable-text-box-margin": this.isMobile,
                },
                "row"
              )}
            >
              {!this.isMobile && (
                <div
                  className="col-lg-5 col-md-5 col-sm-0 col-xs-0"
                  style={{ padding: "0" }}
                >
                  <CustomerDetails
                    customerDetails={customerDetails}
                    updateState={this.updateState}
                    isEditMode={isEditMode}
                  />
                  <br />
                  <VehicleDetails
                    vehicleDetails={vehicleDetails}
                    updateState={this.updateState}
                    isEditMode={isEditMode}
                    from={from}
                    isExisting={isExisting}
                  />
                  <br />
                  <ProductDetails
                    sensorNumberAdded={this.state.sensorNumberAdded}
                    productDetails={productDetails}
                    updateState={this.updateState}
                    isEditMode={isEditMode}
                  />
                </div>
              )}

              {!this.isMobile && (
                <div
                  className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
                  style={{ padding: "0" }}
                />
              )}
              <div
                className={classnames(
                  { "p-0": !this.isMobile, p: this.isMobile },
                  "col-lg-6 col-md-6 col-sm-12 col-xs-12"
                )}
                style={{ position: "relative" }}
              >
                <Card
                  className={classnames(
                    {
                      "box-shadow-apply": !this.isMobile,
                      "box-shadow-remove": this.isMobile,
                    },
                    "row"
                  )}
                >
                  {this.state.isDataLoading && <CustomSpinner marginTop="0%" />}
                  <CardBody>
                    <Animated
                      animationIn="none"
                      animationOut="none"
                      animationInDuration={1000}
                      animationOutDuration={1000}
                      isVisible={animateDetails}
                    >
                      {visibleArea === "customerDetails" ? (
                        <CustomerDetailsEdit
                          customerDetails={customerDetails}
                          updateState={this.updateState}
                          isEditMode={isEditMode}
                          from={from}
                          ref={this.customerDetailsEditRef}
                        />
                      ) : (
                        ""
                      )}

                      {visibleArea === "vehicleType" ? (
                        <VehicleType
                          vehicleDetails={vehicleDetails}
                          updateState={this.updateState}
                          isEditMode={isEditMode}
                          isExisting={isExisting}
                          type={customerDetails.controls.type}
                          registeredVehicleDetails={registeredVehicleDetails}
                          productDetails={productDetails}
                        />
                      ) : (
                        ""
                      )}

                      {/* {
                        visibleArea === 'vehicleSelectState' ?
                          <SelectState vehicleDetails={vehicleDetails} updateState={this.updateState} isEditMode={isEditMode} from={from} isDataLoading={(flag) => this.isLoadingComponents(flag)} /> : ''
                      }

                      {
                        visibleArea === 'vehicleSelectCity' ?
                          <SelectCity vehicleDetails={vehicleDetails} updateState={this.updateState} isEditMode={isEditMode} isDataLoading={(flag) => this.isLoadingComponents(flag)} /> : ''
                      } */}
                      {visibleArea === "vehicleRegistrationNumber" ? (
                        <VehicleRegistrationNumber
                          vehicleDetails={vehicleDetails}
                          updateState={this.updateState}
                          isEditMode={isEditMode}
                          registeredVehicleDetails={registeredVehicleDetails}
                          vehicleLocalRegistrationNumber={
                            vehicleLocalRegistrationNumber
                          }
                        />
                      ) : (
                        ""
                      )}
                      {/* {
                        visibleArea === 'vehicleRegistrationYear' ?
                          <VehicleRegistrationYear vehicleDetails={vehicleDetails} updateState={this.updateState} isEditMode={isEditMode} /> : ''
                      } */}

                      {visibleArea === "vehicleMake" ? (
                        <VehicleMake
                          vehicleDetails={vehicleDetails}
                          updateState={this.updateState}
                          isEditMode={isEditMode}
                          isDataLoading={(flag) =>
                            this.isLoadingComponents(flag)
                          }
                          from={from}
                        />
                      ) : (
                        ""
                      )}

                      {visibleArea === "vehicleModel" ? (
                        <VehicalModel
                          vehicleDetails={vehicleDetails}
                          updateState={this.updateState}
                          isEditMode={isEditMode}
                          isDataLoading={(flag) =>
                            this.isLoadingComponents(flag)
                          }
                        />
                      ) : (
                        ""
                      )}

                      {/* {
                        visibleArea === 'vehicleVariant' ?
                          <VehicleVariant vehicleDetails={vehicleDetails} updateState={this.updateState} isEditMode={isEditMode} isDataLoading={(flag) => this.isLoadingComponents(flag)} /> : ''
                      } */}

                      {/* {
                        visibleArea === 'vehicleMeterReading' ?
                          <VehicalMeterReading vehicleDetails={vehicleDetails} updateState={this.updateState} isEditMode={isEditMode} /> : ''
                      } */}

                      {visibleArea === "productDetailsEdit" ? (
                        <ProductDetailsEdit
                          customerDetails={customerDetails}
                          vehicleDetails={vehicleDetails}
                          productDetails={productDetails}
                          updateStateSensorNumber={this.updateStateSensorNumber}
                          updateState={this.updateState}
                          isEditMode={isEditMode}
                          from={from}
                          isDataLoading={(flag) =>
                            this.isLoadingComponents(flag)
                          }
                          token={token}
                          is_from_add_customer={this.state.is_from_add_customer}
                          redirectDetailsAddCustomer={
                            this.redirectDetailsAddCustomer
                          }
                        />
                      ) : (
                        ""
                      )}

                      {visibleArea === "mobileViewDetail" && (
                        <Fragment>
                          <span
                            className="Warranty-Registration"
                            style={{ fontSize: "16px" }}
                          >
                            Warranty Registration Summary
                          </span>
                          <br />
                          <CustomerDetails
                            customerDetails={customerDetails}
                            updateState={this.updateState}
                            isEditMode={isEditMode}
                          />
                          <br />
                          <VehicleDetails
                            vehicleDetails={vehicleDetails}
                            updateState={this.updateState}
                            isEditMode={isEditMode}
                            isExisting={true}
                          />
                          <br />
                          <ProductDetails
                            sensorNumberAdded={this.state.sensorNumberAdded}
                            productDetails={productDetails}
                            updateState={this.updateState}
                            isEditMode={isEditMode}
                          />
                          <br />
                          <Row>
                            <Col
                              xl="6"
                              md="6"
                              xs="6"
                              style={{ paddingRight: 0 }}
                            >
                              <Button
                                className={classnames(
                                  "mb-1",
                                  "mr-1",
                                  "btn-pill",
                                  "next-btn",
                                  "font-weight-bold"
                                )}
                                color="secondary"
                                onClick={this.cancelClick}
                                style={{
                                  backgroundColor: "#e0e2e4",
                                  borderColor: "#e0e2e4",
                                  color: "black",
                                }}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col
                              xl="6"
                              md="6"
                              xs="6"
                              style={{ paddingLeft: 0 }}
                            >
                              <Button
                                className={classnames(
                                  "mb-1",
                                  "mr-1",
                                  "btn-pill",
                                  "next-btn",
                                  "font-weight-bold"
                                )}
                                color="warning"
                                onClick={this.onRegisterClick}
                              >
                                Register
                              </Button>
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    </Animated>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Fragment>
        )}
      </>
    );
  }
}

//export default WarrantyRegistration;
const mapDispatchToProps = (dispatch) => ({
  setAddCustomerRedirect: (redirectDetailsAddCustomer) =>
    dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
});

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  userDetail: state.User.userDetail,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WarrantyRegistration)
);
