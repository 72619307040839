import React, { Component } from 'react';
import { Row, Col, Table, Card } from 'reactstrap';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import './merchandise-wallet.css';
import MerchandiseCatalogue from '../../components/MerchandiseCatalogue/MerchandiseCatalogue';
import MerchandiseCatalogueCart from '../../components/MerchandiseCatalogueCart/MerchandiseCatalogueCart';
import MerchandiseWalletService from '../../services/MerchandiseWalletService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

class MerchandiseWallet extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      isDisplayMerchandiseWallet: true,
      placeOrderCartItems: [],
      total_coins: 0,
      balance_coins: 0,
      redeemed_coins: 0,
      coinsOnCart: 0,
      cartItems: [],
      orderDetails: [],
      isLoading: false
    };
    this.childRefMerchandiseCatalogue = React.createRef();
  }

  componentDidMount() {
    GoogleAnalticsService.pageView('Merchandise Catalogue View');
    this.getDashBoardCounts();
    this.getOrders();
    this.getItems();
  }

  getDashBoardCounts = () => {
    this.setState({ isLoading: true });
    MerchandiseWalletService.getDashboardCounts()
      .then(res => {
        this.setState({ isLoading: false });
        if (res.data && res.data.data) {
          this.setState({
            total_coins: res.data.data.total_coins,
            balance_coins: res.data.data.balance_coins,
            redeemed_coins: res.data.data.redeemed_coins
          });
          // this.getItems();
        }
      })
      .catch(e => {
        this.setState({
          profileImage: null,
          isImageUpdating: false,
          isLoading: false
        });
      });
  }

  getItems = () => {
    this.setState({ isLoading: true });
    MerchandiseWalletService.getItems()
      .then(res => {
        this.setState({ isLoading: false });
        if (res && res.data && res.data.data && res.data.data.length > 0) {
          this.childRefMerchandiseCatalogue.current.setCartItems(res.data.data);
          this.setState({ cartItems: res.data.data });
        }
      })
      .catch(e => {
        this.setState({
          profileImage: null,
          isImageUpdating: false,
          isLoading: false
        });
      });
  }


  setCoins = (coins) => {
    this.setState({
      total_coins: coins.total_coins,
      coinsOnCart: coins.coinsOnCart
    });
  }

  backToMerchandiseWallet = (isDisplayMerchandiseWallet) => {
    if (isDisplayMerchandiseWallet) {
      this.getDashBoardCounts();
    }
    this.setState({ isDisplayMerchandiseWallet });
  }

  getOrders = async () => {
    try {
      let { orderDetails } = this.state;

      const { data } = await MerchandiseWalletService.getOrders();

      if (data && data.success) {
        orderDetails = data.data;
        this.setState({ orderDetails });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { orderDetails, isLoading } = this.state;
    return (
      <>
        {isLoading && <CustomSpinner />}
        {
          this.state.isDisplayMerchandiseWallet &&
          <>
            <span className="Warranty-Registration">Merchandise Wallet</span>
            <br />
            <Tabs

              defaultActiveKey="0"
              className="primary-report-tabs"
              renderTabBar={() => <ScrollableInkTabBar extraContent={
                <></>
              } />}
              renderTabContent={() => <TabContent />}
            >
              <TabPane tab='Merchandise Catalogue' key="0">
                <MerchandiseCatalogue
                  total_coins={this.state.total_coins}
                  balance_coins={this.state.balance_coins}
                  redeemed_coins={this.state.redeemed_coins}
                  ref={this.childRefMerchandiseCatalogue}
                  setCoins={this.setCoins}
                  cartItems={this.state.cartItems}
                  placeOrderCartItems={this.state.placeOrderCartItems}
                  backToMerchandiseWallet={this.backToMerchandiseWallet} />
              </TabPane>
              <TabPane tab='Order History' key="1">
                <Row>
                  <Col>
                    <Card>
                      <div style={{ padding: '15px' }}>
                        <Table>
                          <thead>
                            <tr>
                              <th className="cart-th">Product Image</th>
                              <th className="cart-th">Product Name</th>
                              <th className="cart-th">Product ID</th>
                              <th className="cart-th">Product Qty</th>
                              <th className="cart-th">Status</th>
                              <th className="cart-th">Order Date</th>
                              <th className="cart-th">Delivery Date</th>
                              <th className="cart-th" style={{ width: '200px' }}>Shipping Address</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (orderDetails || []).map((order) => (
                                <tr>
                                  <td>
                                    <img src={order.item_url} alt="" style={{ width: '60px', height: '59px', objectFit: 'contain' }} />
                                  </td>
                                  <td className="cart-td">
                                    {order.item_name}
                                  </td>

                                  <td className="cart-td">
                                    {order.order_item_id}
                                  </td>
                                  <td className="cart-td">{order.item_quantity}</td>
                                  <td className="cart-td">
                                    <div className="d-flex-row" style={{ flexWrap: 'nowrap' }}>
                                      {order.status === 'delivered' && <><img src="images/DeliveredDot.svg" alt="delivered dot" /> &nbsp;&nbsp;Delivered </>}
                                      {order.status === 'in_progress' && <><img src="images/InProcessDot.svg" alt="in progress dot" />&nbsp;&nbsp; In Progress</>}
                                      {order.status === 'out_of_stock' && <><img src="images/OutofStockDot.svg" alt="out of stock dot" /> &nbsp;&nbsp;Out of stock</>}


                                    </div>
                                  </td>
                                  <td className="cart-td">
                                    {order.created_at}
                                  </td>
                                  <td className="cart-td">
                                    {order.delivery_date}
                                  </td>
                                  <td className="cart-td">
                                    {order.address}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </>
        }
        {
          !this.state.isDisplayMerchandiseWallet &&
          <MerchandiseCatalogueCart
            total_coins={this.state.total_coins}
            balance_coins={this.state.balance_coins}
            redeemed_coins={this.state.redeemed_coins}
            coinsOnCart={this.state.coinsOnCart}
            backToMerchandiseWallet={this.backToMerchandiseWallet}
            placeOrderCartItems={this.state.placeOrderCartItems} />
        }
      </>
    );
  }
}

export default MerchandiseWallet;