import React, { Fragment, useEffect, useState } from 'react';
import RangerEventFooter from './RangerEventFooter';
import RangerEventHeader from './RangerEventHeader';
import RangerEventContent from './RangerEventContent';


const RangerEventHome = () => {
  const [queryParameter, setQueryParameter] = useState('')
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const locationquery = window.location.hash;
    const parameter = locationquery.split("?");
    if (parameter && parameter[1] && parameter.length === 2) {
      if (parameter[1] === "q=true") {
        setQueryParameter(true)
      }
    }
    else if (parameter && parameter.length === 1) {
      setQueryParameter(false)
    }
  }, [window.location.hash])

  return (
    <Fragment>
      <div>
        <RangerEventHeader queryParameter={queryParameter} />
        <div>
          <RangerEventContent queryParameter={queryParameter} />
        </div>
        <RangerEventFooter queryParameter={queryParameter} />
      </div>
    </Fragment>
  )
}
export default RangerEventHome