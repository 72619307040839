import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types'; // or PropTypes = require('prop-types');
import ComponetWithHeader from '../CompentWithHeader/ComponetWithHeader';
import ComponentWithoutHeader from '../ComponentWithoutHeader/ComponentWithoutHeader';
import ComponentWithoutSideNav from '../ComponentWithoutSideNav/ComponentWithoutSideNav';
import AuthService from '../../services/AuthService';


/**
 * private route component to prevent unauthorize access
 */
const PublicRoute = ({ component: Component, sidenav, header, ...rest }) => {
  // uncomment  when authentication api implement
  const isAuthenticated = AuthService.isAuthenticated();

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated === true) {
          return <Redirect to={{
            pathname: '/',
          }}
          />
        }
        if (header === true && sidenav === true) {
          return <ComponetWithHeader component={Component} {...props} />
        } else if (header === true && sidenav === false) {
          return <ComponentWithoutSideNav component={Component} {...props} />
        } else {
          return <ComponentWithoutHeader component={Component} {...props} />
        }
      }
        // <ComponentWithoutHeader component={Component} {...props} />
      }
    />
  );
};


PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PublicRoute;
