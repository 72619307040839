import React, { Fragment } from 'react';
import Footer from '../../components/Footer/Footer';

const ComponentWithoutHeader = ({ component: Component, footer, ...rest }) => {
  return (
    <Fragment>
      <div style={{ minHeight: '97vh' }} >
        <Component {...rest} />
      </div>
      {rest && rest.location && rest.location.pathname !== "/ranger-event" && footer !== false && <Footer></Footer>}
    </Fragment>
  )
}

export default ComponentWithoutHeader;