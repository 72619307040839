import React, { Fragment, Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import sweetAlert from 'sweetalert';
import DatePicker from 'react-datepicker';

import MerchandiseService from '../../services/MerchandiseService';

class OrderStatusUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateSelected: new Date()
    };

    this.confirmDialogAnswer = this.confirmDialogAnswer.bind(this);
  }

  async confirmDialogAnswer(answer) {
    const { data: { orderId, statusName, statusValue, index }, onExit } = this.props;
    const alert = {
      title: 'Opps !!!',
      text: 'Error while updating remarks',
      icon: 'error',
      type: 'error'
    };

    if (answer) {
      const { dateSelected } = this.state;
      const requestBody = { [statusName]: statusValue, date: new Date(dateSelected).toISOString() };
      const { success, data, message } = await MerchandiseService.updateMerchandiseOrder(orderId, requestBody);
      if (success) {

        alert.title = 'Congratulations !';
        alert.text = message;
        alert.icon = 'success';
        alert.type = 'success';
        sweetAlert(alert);
        return onExit(data, { orderId, statusName, statusValue, index });
      }

      sweetAlert(alert);
    }

    return onExit(false);
  }

  render() {
    const { isOpen, onExit, title, msg } = this.props;
    const { dateSelected } = this.state;
    return (<Fragment>
      <Modal id="confirm-order-status-update" isOpen={isOpen} toggle={() => onExit(false)} onExit={() => onExit(false)} size='lg' centered>
        <ModalHeader toggle={() => onExit(false)}>{title}</ModalHeader>
        <ModalBody>
          <p>{msg}</p>

          <DatePicker
            name="dateSelected"
            className='form-control'
            selected={dateSelected}
            onChange={(e) => this.setState({ dateSelected: e })}
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            shouldCloseOnSelect
            dropdownMode="select"
          />
        </ModalBody>
        <ModalFooter>
          <Button type='button' color='primary' onClick={() => this.confirmDialogAnswer(true)}>Yes</Button>
          <Button type='button' onClick={() => this.confirmDialogAnswer(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
    );
  }
}

OrderStatusUpdate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  data: PropTypes.shape({
    orderId: PropTypes.any,
    statusName: PropTypes.any,
    statusValue: PropTypes.any,
    index: PropTypes.any
  }).isRequired
};

export default OrderStatusUpdate; 