/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import axios from "axios";
import qs from "qs";
// import { history } from 'react-router-dom';
import { detect } from "detect-browser";
import Storage from "./Storage";
import { routes } from "../constants/constant.route";
// import AuthService from "./AuthService";
import { getScreenResolution } from "../utils/utils";

const API_URL = process.env.REACT_APP_API_URL;
// const REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV;
const { LOGIN } = routes.AUTH;

function refreshRequestToken(refreshToken) {
  const refreshTokenBody = {
    refresh_token: refreshToken,
    grant_type: "refresh_token"
  };
  return request("POST", `${API_URL}${LOGIN}`, null, refreshTokenBody, {
    "content-type": "application/x-www-form-urlencoded",
    app: "advantage"
  })
    .then(data => {
      const response = data.data;

      const obj = {
        accessToken: response.accessToken,
        accessTokenExpiresAt: response.accessTokenExpiresAt,
        refreshToken: response.refreshToken,
        refreshTokenExpiresAt: response.refreshTokenExpiresAt
      };

      const userDetail = response.user;
      Storage.setTokenDetail(obj);
      Storage.setUserDetail(userDetail);
      return Promise.resolve(data);
      // store token to localstorage and send seconde request
    })
    .catch(e => {
      // window.history.replaceState(null, null, '/#/login');
      const err =
        e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error[0]
          ? e.response.data.error[0]
          : null;
      const errorName = err ? err.name : "";
      if (errorName === "invalid_grant") {
        Storage.removeKeepMeLoggedInCookie();
        Storage.removeTokenDetail();
        Storage.removeUserDetail();
        window.history.go("/#/login");
        return Promise.reject(e);
      }
      return Promise.reject(e);
      // remove tokens and redirect to logout
    });
}

/**
 * request interceptors
 * @param {String} method GET,PUT,POST,DELETE
 * @param {String} url req url
 * @param {Object} params query parameters
 * @param {Object} body req body
 * @param {Object} headers req headers
 */
export const request = (
  method,
  url,
  params,
  body = {},
  headers = {},
  token = ''
) => {
  token = Storage.getTokenDetail() ? Storage.getTokenDetail() : token;

  const browser = detect();
  const browserName = browser.name;
  const { version } = browser;

  headers = headers || {};
  params = params || {};
  body = body || {};

  headers.device_type = "browser";
  headers.web_version = version;
  headers.browser_name = browserName;
  headers.app = "advantage";
  headers.resolution = getScreenResolution();

  if (!headers["content-type"]) {
    headers["content-type"] = "application/json";
  }

  if (
    !(
      url.includes("login") ||
      url.includes("forgot-password") ||
      url.includes("crm-points/about")
    )
  ) {
    if (token && typeof token === 'object' && Object.keys(token).length > 0) {
      headers.Authorization = `Bearer ${token.accessToken}`;
    } else if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  } else if (url.includes("login")) {
    headers.Authorization = "Basic amt0eXJlOjEyMw==";
  }
  if (window.location.href.includes('event-registration')) {
    headers.Authorization = `Bearer ${token}`;
  }
  const options = {
    method,
    headers,
    params,
    url
  };
  if (
    (method === "POST" || method === "PUT") &&
    headers["content-type"] === "application/x-www-form-urlencoded"
  ) {
    options.data = qs.stringify(body);
  } else if (
    (method === "POST" || method === "PUT") &&
    headers["content-type"] === "multipart/form-data"
  ) {
    headers["content-type"] = "multipart/form-data";

    // prepate multipart formdata body
    const formData = new FormData();
    const keys = Object.keys(body);

    for (let i = 0; i < keys.length; i++) {
      if (body[keys[i]] && typeof body[keys[i]] === 'object' && Object.keys(body[keys[i]]).length > 0) {
        for (const key of Object.keys((body[keys[i]]))) {
          formData.append(keys[i], (body[keys[i]][key]))
        }
      } else {
        formData.append(keys[i], body[keys[i]]);
      }
    }
    options.data = formData;
  } else if (method === "POST" || method === "PUT") {
    options.data = body;
  }
  return axios(options)
    .then(data => {
      return Promise.resolve(data);
    })
    .catch(e => {
      console.log("request error", e);
      const err =
        e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.length > 0 &&
          e.response.data.error[0]
          ? e.response.data.error[0]
          : null;
      const errorName = err ? err.name : "";
      console.log("errorName", errorName);
      const preserveRequest = options;
      if (errorName === "invalid_token") {
        // AuthService.logout()
        //   .then(() => {
        // window.history.replaceState(null, null, '/#/login');
        //   window.history.go('/#/login');
        // })
        return refreshRequestToken(token.refreshToken)
          .then(data => {
            const accessToken = data.data.accessToken;
            preserveRequest.headers.Authorization = `Bearer ${accessToken}`;
            console.log("new access token retrived successfully");
            console.log("new request created");
            return axios(preserveRequest);
          })
          .then(data => {
            return Promise.resolve(data);
          })
          .catch(e => {
            return Promise.reject(e);
          });
      }

      if (errorName !== "invalid_token") {
        return Promise.reject(e);
      }
    });
};

/**
 * new request interceptors
 * @param {String} method GET,PUT,POST,DELETE
 * @param {String} url req url
 * @param {Object} params query parameters
 * @param {Object} body req body
 * @param {Object} headers req headers
 */
export const newRequest = async (
  method,
  url,
  params,
  body = {},
  headers = {}
) => {
  const { data = {} } = await request(method, url, params, body, headers);
  return data;
};
