import React, { Component, Fragment } from 'react';
import {
  Row, Col,
  Card, CardBody,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';

import { formatNumber } from '../../utils/utils';

class DashboardCardEmployee extends Component {
  state = {
    redirectToAbout: false
  }

  redirectToAboutScreen = () => {
    this.setState({ redirectToAbout: true });
  }

  render() {

    const { data, period, total_points, images, displayLabels } = this.props;
    let dataArr = [];
    let obj = {
      pcr: null,
      truck: null,
      tw: null,
      farm: null,
      lcv: null,
      scv: null
    }
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let y = data[i];
        if (y) {
          if (y.category === 'PCR') {
            obj.pcr = data[i];
          } else if (y.category === 'Truck') {
            obj.truck = data[i];
          } else if (y.category === 'LCV') {
            obj.lcv = data[i];
          } else if (y.category === 'SCV') {
            obj.scv = data[i];
          } else if (y.category === 'TW') {
            obj.tw = data[i];
          } else if (y.category === 'Farm') {
            obj.farm = data[i];
          }
        }
      }
    }

    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      let d = obj[keys[i]];
      if (d) {
        dataArr.push(d);
      }
    }

    const prepareRows = dataArr.map(y => {
      let imageUrl = '';
      if (y.category === 'PCR') {
        imageUrl = images['pcr'];
      } else if (y.category === 'Truck') {
        imageUrl = images['truck'];
      } else if (y.category === 'LCV') {
        imageUrl = images['lcv'];
      } else if (y.category === 'SCV') {
        imageUrl = images['scv'];
      } else if (y.category === 'TW') {
        imageUrl = images['wheeler'];
      } else if (y.category === 'Farm') {
        imageUrl = images['farm'];
      }
      return <Fragment>
        <br />
        <Row>
          <Col className="dashboard-page-card-title-small-gray text-align-left">
            <img className="image" src={imageUrl} alt='' />
          </Col>
          <Col className="text-align-center card-count">{y.total_sales}</Col>
          <Col className="card-count text-align-right">{y.points}</Col>
        </Row>
      </Fragment>
    }
    )
    let duration = null;
    if (period && displayLabels) {
      if (period === 'Year' && displayLabels.year_till_previous_quarter) {
        duration = displayLabels.year_till_previous_quarter;
      }
      if (period === 'Quarter' && displayLabels.quarter) {
        duration = displayLabels.quarter;
      }
      if (period === 'Month' && displayLabels.month) {
        duration = displayLabels.month;
      }
    }
    return <Fragment>
      {this.state.redirectToAbout && < Redirect to="/about"></Redirect>}
      <Card className="height-100 border-radius-5">
        <Row className="card-custom-header">
          <Col className="dashboard-page-card-title-big">
            <div>
              {period} Till Date
            </div>
            <div className="dashboard-page-card-title-small">{duration}</div>
          </Col>
          <Col className="count-small">{formatNumber(total_points)}</Col>
        </Row>
        <CardBody>
          <Row className="margin-top-10">
            <Col className="dashboard-page-card-title-small-gray">Category</Col>
            <Col className="dashboard-page-card-title-small-gray text-align-center">Offtake</Col>
            <Col className="dashboard-page-card-title-small-gray text-align-right">Points</Col>
          </Row>
          {prepareRows}

        </CardBody>

        {/* {data.length === 0 && <CardBody className="display-content-center">
          <div className="no-content link" onClick={this.redirectToAboutScreen}>
            Know how to earn points
        </div>
        </CardBody>} */}

      </Card>
    </Fragment >
      ;
  }
}

export default DashboardCardEmployee;