import React, { Component } from "react";
import ProposalSerivice from "../../services/ProposalSerivice";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import swal from "sweetalert";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import async from "async";
import { messages } from "../../constants/constant.message";
import { Roles } from "../../constants/constant.role";
class LayoutStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      isCurrentDocUpload: "",
      documents: [
        {
          file: "",
          type: "Layout",
        },
        {
          file: "",
          type: "Detailed Drawings",
        },
        {
          file: "",
          type: "Site Picture",
        },
        {
          file: "",
          type: "3D Layout",
        },
        {
          file: "",
          type: "Aggrement",
        },
        {
          file: "",
          type: "Pert Chart",
        },
        {
          file: "",
          type: "Letter of Intent",
        },
        {
          file: "",
          type: "Scope of Work",
        },
        {
          file: "",
          type: "cheque",
        },
      ],
      isLoading: false,
      compilsarydoc: {
        layout: {
          isValid: false,
          count: 1,
          data: [],
        },
        detaileddrawings: {
          isValid: false,
          count: 1,
          data: [],
        },
        sitepicture: {
          isValid: false,
          count: 1,
          data: [],
        },
        letterofintent: {
          isValid: false,
          count: 1,
          data: [],
        },
        aggrement: {
          isValid: false,
          count: 1,
          data: [],
        },
        pertChart: {
          isValid: false,
          count: 1,
          data: [],
        },
        scopeofwork: {
          isValid: false,
          count: 1,
          data: [],
        },
        threeDLayout: {
          isValid: false,
          count: 1,
          data: [],
        },
        cheque: {
          isValid: false,
          count: 1,
          data: [],
        },
      },
      userDetail: Storage.getUserDetail(),
    };
  }

  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { documents } = this.state;
      if (data && data.data) {
        let index = documents.findIndex((x) => x.type === type);
        if (index > -1) {
          documents[index].file = data.data;
        }
        this.submitLayoutDocuments(type, data.data);
      }
      this.setState({ documents, isCurrentDocUpload: type });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (!this.checkActiveStatus()) {
      return false;
    }
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        const { documents } = this.state;
        let index = documents.findIndex((x) => x.type === type);
        if (index > -1) {
          documents[index].file = "";
        }
        event.target.value = null;
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB.",
          "error"
        );
        return;
      }
      this.uploadFileFormData(file, type);
    }
  };

  setLayoutData = async () => {
    let { compilsarydoc, isCurrentDocUpload } = this.state;
    // let { layout, detaileddrawings, scopeofwork, pertChart } = compilsarydoc;
    let { proposalStageDetail } = this.props;
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Layout") {
      compilsarydoc.layout.data = [];
      compilsarydoc.layout.count = 1;
    }
    if (
      isCurrentDocUpload === "" ||
      isCurrentDocUpload === "Detailed Drawings"
    ) {
      compilsarydoc.detaileddrawings.data = [];
      compilsarydoc.detaileddrawings.count = 1;
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Site Picture") {
      compilsarydoc.sitepicture.data = [];
      compilsarydoc.sitepicture.count = 1;
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "3D Layout") {
      compilsarydoc.threeDLayout.data = [];
      compilsarydoc.threeDLayout.count = 1;
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "cheque") {
      compilsarydoc.cheque.data = [];
      compilsarydoc.cheque.count = 1;
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Aggrement") {
      compilsarydoc.aggrement.data = [];
      compilsarydoc.aggrement.count = 1;
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "PERT Chart") {
      compilsarydoc.pertChart.data = [];
      compilsarydoc.pertChart.count = 1;
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Letter Of Intent") {
      compilsarydoc.letterofintent.data = [];
      compilsarydoc.letterofintent.count = 1;
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Scope Of Work") {
      compilsarydoc.scopeofwork.data = [];
      compilsarydoc.scopeofwork.count = 1;
    }

    async.forEach(proposalStageDetail.layout_data, (element, callback) => {
      if (
        element.type.toLowerCase() === "layout" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.layout.data.push(element);
        compilsarydoc.layout.isValid = true;
      } else if (
        element.type.toLowerCase() === "detailed drawings" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.detaileddrawings.isValid = true;
        compilsarydoc.detaileddrawings.data.push(element);
      } else if (
        element.type.toLowerCase() === "site picture" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.sitepicture.isValid = true;
        compilsarydoc.sitepicture.data.push(element);
      } else if (
        element.type.toLowerCase() === "letter of intent" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.letterofintent.isValid = true;
        compilsarydoc.letterofintent.data.push(element);
      } else if (
        element.type.toLowerCase() === "aggrement" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.aggrement.isValid = true;
        compilsarydoc.aggrement.data.push(element);
      } else if (
        element.type.toLowerCase() === "pert chart" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.pertChart.isValid = true;
        compilsarydoc.pertChart.data.push(element);
      } else if (
        element.type.toLowerCase() === "scope of work" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.scopeofwork.isValid = true;
        compilsarydoc.scopeofwork.data.push(element);
      } else if (
        element.type.toLowerCase() === "3d layout" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.threeDLayout.isValid = true;
        compilsarydoc.threeDLayout.data.push(element);
      } else if (
        element.type.toLowerCase() === "cheque" &&
        (element.file || element.document) &&
        (isCurrentDocUpload === "" || isCurrentDocUpload === element.type)
      ) {
        compilsarydoc.cheque.isValid = true;
        compilsarydoc.cheque.data.push(element);
      }
      // callback();
    });
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Layout") {
      compilsarydoc.layout.data.push({
        type: "Layout",
        isUpload: true,
      });
    }
    if (
      isCurrentDocUpload === "" ||
      isCurrentDocUpload === "Detailed Drawings"
    ) {
      compilsarydoc.detaileddrawings.data.push({
        type: "Detailed Drawings",
        isUpload: true,
      });
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Site Picture") {
      compilsarydoc.sitepicture.data.push({
        type: "Site Picture",
        isUpload: true,
      });
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "3D Layout") {
      compilsarydoc.threeDLayout.data.push({
        type: "3D Layout",
        isUpload: true,
      });
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "cheque") {
      compilsarydoc.cheque.data.push({
        type: "cheque",
        isUpload: true,
      });
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Aggrement") {
      compilsarydoc.aggrement.data.push({
        type: "Aggrement",
        isUpload: true,
      });
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "PERT Chart") {
      compilsarydoc.pertChart.data.push({
        type: "PERT Chart",
        isUpload: true,
      });
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Letter Of Intent") {
      compilsarydoc.letterofintent.data.push({
        type: "Letter Of Intent",
        isUpload: true,
      });
    }
    if (isCurrentDocUpload === "" || isCurrentDocUpload === "Scope Of Work") {
      compilsarydoc.scopeofwork.data.push({
        type: "Scope Of Work",
        isUpload: true,
      });
    }

    this.setState({
      compilsarydoc,
    });
  };

  submitLayoutDocuments = (type = null, file = null) => {
    const { documents } = this.state;
    if (!this.state.proposalStageStatus || !type) {
      return;
    }
    let obj = { documents: [] };
    obj.documents.push({
      type: type,
      file: file,
    });

    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.upoladLayoutDocuments(obj)
      .then((res) => {
        documents.forEach((element) => {
          element.file = "";
        });
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message = "Layout stage details added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message = "Layout stage details updated successfully";
        }
        if (type === "Layout") {
          if (this.fileInput1) {
            this.fileInput1.value = "";
          }
        }
        if (this.fileInput2) {
          this.fileInput2.value = "";
        }
        if (this.fileInput3) {
          this.fileInput3.value = "";
        }
        if (this.fileInput4) {
          this.fileInput4.value = "";
        }
        if (this.fileInput5) {
          this.fileInput5.value = "";
        }

        if (this.fileInput6) {
          this.fileInput6.value = "";
        }
        if (this.fileInput7) {
          this.fileInput7.value = "";
        }
        if (this.fileInput8) {
          this.fileInput8.value = "";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.initApi();
        });
        this.setState({
          isLoading: false,
          documents,
          isCurrentDocUpload: type,
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        try {
          const err =
            e.response && e.response.data && e.response.data.error[0]
              ? e.response.data.error[0]
              : null;
          const message =
            err && err.message
              ? err.message
              : messages.WARRANTY_REGISTRATION.ERRORMESSAGE;
          ModalService.openAlert("", message, "error");
        } catch (error) {
          ModalService.openAlert(
            "",
            messages.WARRANTY_REGISTRATION.ERRORMESSAGE,
            "error"
          );
        }
      });
  };

  deleteDocConfirm = (item = null) => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    swal({
      title: "Delete Document",
      text: "Are you sure, you want to delete document ?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.deleteDoc(item);
      }
    });
  };

  deleteDoc = (item) => {
    let { proposalByIdData } = this.props;
    ProposalSerivice.deleteLayoutDocuments({
      document_id: item.document_id,
      uuid: proposalByIdData.uuid,
    })
      .then((res) => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.initApi();
        });
        this.setState({ isLoading: false, isCurrentDocUpload: item.type });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        try {
          const err =
            e.response && e.response.data && e.response.data.error[0]
              ? e.response.data.error[0]
              : null;
          const message =
            err && err.message
              ? err.message
              : messages.WARRANTY_REGISTRATION.ERRORMESSAGE;
          ModalService.openAlert("", message, "error");
        } catch (error) {
          ModalService.openAlert(
            "",
            messages.WARRANTY_REGISTRATION.ERRORMESSAGE,
            "error"
          );
        }
      });
  };

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };

  render() {
    const {
      // proposalStageDetail,
      proposalByIdData,
      isCompletedAllStages,
    } = this.props;

    let { compilsarydoc, userDetail } = this.state;
    let islayoutReadOnly = isCompletedAllStages;
    // let indexLayoutStage = -1;
    let is_xpress_wheel = false;
    // if (proposalByIdData && proposalByIdData.outlet_type === "Steel Wheel") {
    //   indexLayoutStage = proposalStageDetail.stages_details.findIndex(
    //     (x) => x.stage && x.stage.toLowerCase() === "estimate needed"
    //   );
    // } else {
    //   indexLayoutStage = proposalStageDetail.stages_details.findIndex(
    //     (x) => x.stage && x.stage.toLowerCase() === "dealer work in progress"
    //   );
    // }
    if (
      proposalByIdData &&
      proposalByIdData.outlet_type &&
      proposalByIdData.outlet_type.toLowerCase() === "xpress wheel"
    ) {
      is_xpress_wheel = true;
    }
    // if (indexLayoutStage > -1) {
    //   islayoutReadOnly = true;
    // }
    compilsarydoc.letterofintent.count = 1;
    compilsarydoc.layout.count = 1;
    compilsarydoc.scopeofwork.count = 1;
    compilsarydoc.aggrement.count = 1;
    compilsarydoc.detaileddrawings.count = 1;
    compilsarydoc.pertChart.count = 1;
    compilsarydoc.sitepicture.count = 1;
    compilsarydoc.threeDLayout.count = 1;
    compilsarydoc.cheque.count = 1;
    return (
      <>
        <br />
        {this.state.isLoading && <CustomSpinner />}
        <Card className="layout-stage">
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Layout Stage</b>
              </Col>
            </Row>
            <hr />

            <Row>
              {((islayoutReadOnly && compilsarydoc.layout.data.length > 1) ||
                !islayoutReadOnly) && (
                  <Col xl="4" md="12" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="pt-2 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        Layout<span className="required-field">*</span>
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.layout.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "layout" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="ml-1 mt-1 mb-1 text-align-center">
                                    Layout{compilsarydoc.layout.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !== "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.layout.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      type="file"
                                      accept="image/*,.pdf"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="layout"
                                      onChange={(e) =>
                                        this.uploadFile(e, "Layout")
                                      }
                                      className="pl-1 pb-2"
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              {((islayoutReadOnly && compilsarydoc.aggrement.data.length > 1) ||
                !islayoutReadOnly) && (
                  <Col xl="4" md="12" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="pt-2 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        Aggreement<span className="required-field">*</span>
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.aggrement.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "aggrement" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="mt-1 mb-1 text-align-center">
                                    Agreement {compilsarydoc.aggrement.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !== "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.aggrement.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      accept="image/*,.pdf"
                                      type="file"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="layout"
                                      onChange={(e) =>
                                        this.uploadFile(e, "Aggrement")
                                      }
                                      className="pl-1 pb-2"
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              {((islayoutReadOnly &&
                compilsarydoc.scopeofwork.data.length > 1) ||
                !islayoutReadOnly) && (
                  <Col xl="4" md="4" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="pt-2 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        Scope of Work<span className="required-field">*</span>
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.scopeofwork.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "scope of work" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="mt-1 mb-1 text-align-center">
                                    Scope of Work{" "}
                                    {compilsarydoc.scopeofwork.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !== "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.scopeofwork.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      type="file"
                                      accept="image/*,.pdf"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="layout"
                                      className="pl-1 pb-2"
                                      onChange={(e) =>
                                        this.uploadFile(e, "Scope Of Work")
                                      }
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}

              {((islayoutReadOnly &&
                compilsarydoc.detaileddrawings.data.length > 1) ||
                !islayoutReadOnly) && (
                  <Col xl="4" md="4" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="pt-2 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        Detailed Drawings<span className="required-field">*</span>
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.detaileddrawings.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "detailed drawings" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="mt-1 mb-1 text-align-center">
                                    Detailed Drawings{" "}
                                    {compilsarydoc.detaileddrawings.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !== "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.detaileddrawings.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      type="file"
                                      accept="image/*,.pdf"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="layout"
                                      className="pl-1 pb-2"
                                      onChange={(e) =>
                                        this.uploadFile(e, "Detailed Drawings")
                                      }
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              {((islayoutReadOnly && compilsarydoc.pertChart.data.length > 1) ||
                !islayoutReadOnly) && (
                  <Col xl="4" md="4" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="pt-2 ml-1 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        PERT Chart<span className="required-field">*</span>
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.pertChart.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "pert chart" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="mt-1 mb-1 text-align-center">
                                    PERT Chart {compilsarydoc.pertChart.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !== "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.pertChart.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      type="file"
                                      accept="image/*,.pdf"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="layout"
                                      className="pl-1 pb-2"
                                      onChange={(e) =>
                                        this.uploadFile(e, "PERT Chart")
                                      }
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              {((islayoutReadOnly &&
                compilsarydoc.letterofintent.data.length > 1) ||
                !islayoutReadOnly) && (
                  <Col xl="4" md="4" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="pt-2 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        Letter of Intent<span className="required-field">*</span>
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.letterofintent.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "letter of intent" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="mt-1 mb-1 text-align-center">
                                    Letter of Intent{" "}
                                    {compilsarydoc.letterofintent.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !== "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.letterofintent.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      type="file"
                                      accept="image/*,.pdf"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="layout"
                                      className="pl-1 pb-2"
                                      onChange={(e) =>
                                        this.uploadFile(e, "Letter Of Intent")
                                      }
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              {((islayoutReadOnly &&
                compilsarydoc.sitepicture.data.length > 1) ||
                !islayoutReadOnly) && (
                  <Col xl="4" md="4" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="ml-1 pt-2 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        Site Picture<span className="required-field">*</span>
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.sitepicture.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "site picture" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center text-align-center">
                                  <span className="mt-1 mb-1">
                                    Site Picture{" "}
                                    {compilsarydoc.sitepicture.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !== "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.sitepicture.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      type="file"
                                      accept="image/*,.pdf"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="layout"
                                      className="pl-1 pb-2"
                                      onChange={(e) =>
                                        this.uploadFile(e, item.type)
                                      }
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              {((islayoutReadOnly &&
                compilsarydoc.threeDLayout.data.length > 1) ||
                !islayoutReadOnly) && (
                  <Col xl="4" md="4" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="ml-1 pt-2 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        3D Layout
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.threeDLayout.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "3d layout" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="mt-1 mb-1 text-align-center">
                                    3D Layout {compilsarydoc.threeDLayout.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !== "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.threeDLayout.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      type="file"
                                      accept="image/*,.pdf"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="layout"
                                      className="pl-1 pb-2"
                                      onChange={(e) =>
                                        this.uploadFile(e, item.type)
                                      }
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              {is_xpress_wheel &&
                ((islayoutReadOnly && compilsarydoc.cheque.data.length > 1) ||
                  !islayoutReadOnly) && (
                  <Col xl="4" md="4" xs="4" className="mt-2">
                    <div
                      className="d-flex-column"
                      style={{ border: "1px solid rgb(0,0,0,0.1)" }}
                    >
                      <span
                        className="ml-1 pt-2 pb-2 text-align-center font-weight-bold"
                        style={{ borderBottom: "2px solid #e9ecef" }}
                      >
                        Cheque
                      </span>
                      <div className="d-flex-row pt-2">
                        {compilsarydoc.cheque.data.map(
                          (item, i) =>
                            this.state.userDetail.type.toLowerCase() !==
                            Roles.AGENCY_ROLE &&
                            item.type &&
                            item.type.toLowerCase() === "cheque" &&
                            (!item.isUpload ? (
                              <div
                                className="d-flex-column"
                                style={{ width: "110px" }}
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="mt-1 mb-1 text-align-center">
                                    Cheque {compilsarydoc.cheque.count++}
                                  </span>
                                  {!islayoutReadOnly &&
                                    userDetail.access_level !==
                                    "brand_store" && (
                                      <>
                                        &nbsp;{" "}
                                        <img
                                          className="cursor-pointer mt-auto mb-auto"
                                          onClick={() =>
                                            this.deleteDocConfirm(item)
                                          }
                                          src="images/delete.svg"
                                          alt=''
                                          style={{ height: "13px" }}
                                        />
                                      </>
                                    )}
                                </div>
                                <a
                                  className="aa d-flex-column"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.document}
                                >
                                  <img
                                    src="images/sidebar-icons/secondary-report-selected.svg"
                                    alt=''
                                    height="40px"
                                    style={{ margin: "auto" }}
                                  />
                                  <span className="redirect-link text-align-center">
                                    View
                                  </span>
                                </a>
                                <span className="text-align-center">
                                  {item.created_at}
                                </span>
                              </div>
                            ) : (
                              !islayoutReadOnly &&
                              compilsarydoc.cheque.data.length < 4 && (
                                <div
                                  className="d-flex-column justify-content-center"
                                  style={{
                                    width:
                                      userDetail.access_level !== "brand_store"
                                        ? "190px"
                                        : "100%",
                                  }}
                                >
                                  {userDetail.access_level !== "brand_store" ? (
                                    <input
                                      type="file"
                                      accept="image/*,.pdf"
                                      ref={(ref) => (this.fileInput1 = ref)}
                                      name="cheque"
                                      className="pl-1 pb-2"
                                      onChange={(e) =>
                                        this.uploadFile(e, item.type)
                                      }
                                    />
                                  ) : (
                                    <div style={{ textAlign: "center" }}>-</div>
                                  )}
                                </div>
                              )
                            ))
                        )}
                      </div>
                    </div>
                  </Col>
                )}
            </Row>
            {/* {
              proposalStageDetail && proposalStageDetail.layout_data && proposalStageDetail.layout_data.length > 0 &&
              <Table className="mb-0  tbl-about tbl-about table-responsive">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    {
                      proposalStageDetail.layout_data.map((item) => (
                        this.state.userDetail.type.toLowerCase() === Roles.AGENCY_ROLE ?
                          item.type && item.type.toLowerCase() === 'detailed drawings' &&
                          <th>
                            {item.type}
                          </th> :
                          <th>
                            {item.type}
                          </th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Document</th>
                    {
                      proposalStageDetail.layout_data.map((item) => (
                        this.state.userDetail.type.toLowerCase() === Roles.AGENCY_ROLE ?
                          item.type && item.type.toLowerCase() === 'detailed drawings' &&
                          <td>
                            <a className='aa' target='_blank' rel="noopener noreferrer" href={item.document} className="d-flex-column">
                              <img src='images/sidebar-icons/secondary-report-selected.svg' height='40px' style={{ margin: 'auto' }} alt='' />
                              <span className="redirect-link">Click here</span>
                            </a>

                          </td> :
                          <td>
                            <a className='aa' target='_blank' rel="noopener noreferrer" href={item.document} className="d-flex-column">
                              <img src='images/sidebar-icons/secondary-report-selected.svg' height='40px' style={{ margin: 'auto' }} alt='' />
                              <span className="redirect-link">Click here</span>
                            </a>

                          </td>

                      ))
                    }
                  </tr>
                  <tr>
                    <th>Date</th>
                    {
                      proposalStageDetail.layout_data.map((item) => (
                        this.state.userDetail.type.toLowerCase() === Roles.AGENCY_ROLE ?
                          item.type && item.type.toLowerCase() === 'detailed drawings' &&

                          <td>
                            {item.created_at}
                          </td> :

                          <td>
                            {item.created_at}
                          </td>

                      ))
                    }
                  </tr>
                </tbody>
              </Table>
            } */}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default LayoutStage;
