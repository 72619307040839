import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';
import swal from 'sweetalert';

class ConfirmationNextAtendee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      oldDetail: {},
      controls: {},
    }
    this.toggle = this.toggle.bind(this);
  }
  toggle(data) {
    let { controls, oldDetail } = this.state;
    if (data && Object.keys(data).length > 0) {
      oldDetail = data;
    } else {
      oldDetail = {};
      // this.resetControls();
    }
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      controls,
      oldDetail
    }));
  }

  close = () => {
    this.props.submitDetail(true, false, false);
    this.props.closeConfirmationNextAtendee();
    this.toggle({});
  }

  submitDetail = (isNextAtendee) => {
    if (isNextAtendee) {
      this.props.submitDetail(true, false, false);
      this.toggle({});
    } else {
      swal({
        title: "",
        text: "Are you sure you want to end CIP?",
        icon: "warning",
        buttons: ["No", "Yes"]
      }).then(isConfirm => {
        if (isConfirm) {
          this.props.submitDetail(true, false, false);
          this.props.openPictureUpload();
          this.toggle({});
        }
      });
    }
  }
  render() {
    // let { oldDetail } = this.state;
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-md modal-lg custom-confirm-modal modal-sm modal-dialog-centered"
          style={{ maxWidth: "550px" }}
          backdrop={this.backdrop ? "static" : "nostatic"}
        >
          {/* <div className="d-flex-column">
            <div className="confirm-modal-title-wrapp">
              <span className="confirm-modal-title">{oldDetail.uuid ? 'Edit' : 'Add'} Tyres</span>
              <FontAwesomeIcon className="pull-right cursor-pointer" onClick={() => this.toggle({})} icon={faTimes} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
            </div>
          </div> */}
          <ModalBody classNam="d-flex-column">
            <Row>
              <Col xl="12" xs="12" md="12" >
                <div style={{ fontSize: '18px', width: '250px' }} className="mt-3 mb-4 ml-auto mr-auto text-align-center">
                  You have successfully added the participant.
                </div>
              </Col>
              <Col xl="12" xs="12" md="12" className="d-flex-row mb-1 mt-1">
                <Button className="mb-1 mr-1 btn-pill action-button ml-auto mr-3 mt-2 font-weight-bold" style={{ width: '200px' }} color="warning"
                  onClick={() => this.submitDetail(true)}>
                  Add More Participant
                </Button>

                {/* <span className='link-blue mr-auto mt-auto mb-auto font-weight-bold'
                  onClick={() => this.submitDetail(false)}>END CIP REGISTRATION</span> */}
                <Button className="mr-3 btn-pill action-button profile-cancel-button mt-auto mb-auto"
                  style={{ width: '220px' }}
                  onClick={() => this.submitDetail(false)}>End CIP Registration</Button>
                <span className='link-blue mr-auto mt-auto mb-auto font-weight-bold'
                  onClick={() => this.close()}>Add Later</span>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      </>
    );
  }
}

export default ConfirmationNextAtendee;