export const action = {
  SET_ENABLE_BACKGROUND_IMAGE: 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE',

  SET_ENABLE_MOBILE_MENU: 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU',
  SET_ENABLE_MOBILE_MENU_SMALL: 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL',

  SET_ENABLE_FIXED_HEADER: 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER',
  SET_ENABLE_HEADER_SHADOW: 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW',
  SET_ENABLE_SIDEBAR_SHADOW: 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW',
  SET_ENABLE_FIXED_SIDEBAR: 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR',
  SET_ENABLE_CLOSED_SIDEBAR: 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR',
  SET_ENABLE_FIXED_FOOTER: 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER',

  SET_ENABLE_PAGETITLE_ICON: 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON',
  SET_ENABLE_PAGETITLE_SUBHEADING: 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING',
  SET_ENABLE_PAGE_TABS_ALT: 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT',

  SET_BACKGROUND_IMAGE: 'THEME_OPTIONS/SET_BACKGROUND_IMAGE',
  SET_BACKGROUND_COLOR: 'THEME_OPTIONS/SET_BACKGROUND_COLOR',
  SET_COLOR_SCHEME: 'THEME_OPTIONS/SET_COLOR_SCHEME',
  SET_BACKGROUND_IMAGE_OPACITY: 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY',
  SET_HEADER_BACKGROUND_COLOR: 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR',

  SET_USER_DETAIL: 'SET_USER_DETAIL',
  UPDATE_SIDE_MENU: 'UPDATE_SIDE_MENU',
  IS_PROFILE_UPDATE: 'IS_PROFILE_UPDATE',

  SET_MERCHANDISE_CART: 'SET_MERCHANDISE_CART',
  SET_DEALER_DASHBOARD_REDIRECT_DETAIL: 'SET_DEALER_DASHBOARD_REDIRECT_DETAIL',
  SET_ADD_CUSTOMER_REDIRECT: 'SET_ADD_CUSTOMER_REDIRECT',
  SET_REDIRECT: 'SET_REDIRECT',
  SET_HELP_GUIDE_REDIRECT: 'SET_HELP_GUIDE_REDIRECT',
}