
import React from 'react';
import { Button, Modal, ModalBody, FormGroup, Input, Label, Row, Col, Form } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import Validation from '../../services/Validation';
import { messages } from '../../constants/constant.message';
import WarrantyRegistrationService from '../../services/WarrantyRegistrationService';


class AddEditAddress extends React.Component {

  state = {};

  stateData = [];

  cityData = [];

  constructor(props) {
    super(props);
    this.state = {
      controls: {
        address: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null
        },
        pin_code: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null
        },
        state: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          passwordNotMatch: false
        },
        city: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          passwordNotMatch: false
        },
        uuid: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          passwordNotMatch: false
        },
        email: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          passwordNotMatch: false
        },
        mobile: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          passwordNotMatch: false
        },
      },
      isSameProfileEmail: false,
      isSameProfilePhone: false,
      isFormValid: false,
      onlyOneAddEditIndex: props.onlyOneAddEditIndex,
      addressSelectedIndex: props.addressSelectedIndex,
      userData: props.userData,
      selectedState: '',
      selectedCity: ''
    };
    if (this.props.addressSelectedIndex !== -1) {

      this.state.controls.address.value = props.addressList[this.props.addressSelectedIndex].address;
      this.state.controls.mobile.value = props.addressList[this.props.addressSelectedIndex].mobile;
      this.state.controls.email.value = props.addressList[this.props.addressSelectedIndex].email;
      this.state.controls.state.value = { name: props.addressList[this.props.addressSelectedIndex].state, code: props.addressList[this.props.addressSelectedIndex].state_code };
      this.state.selectedState = this.state.controls.state.value;
      this.state.controls.city.value = { name: props.addressList[this.props.addressSelectedIndex].city };
      this.state.selectedCity = this.state.controls.city.value;
      this.state.controls.pin_code.value = props.addressList[this.props.addressSelectedIndex].pin_code;
      this.state.controls.uuid.value = props.addressList[this.props.addressSelectedIndex].uuid ? props.addressList[this.props.addressSelectedIndex].uuid : null;

    }
  }

  componentDidMount() {
    this.getState();
  }

  setLogoutFromOtherCheckboxValue = () => {
    const { controls } = this.state;
    // const { agreeTermAndCondition } = controls;
    controls.logoutFromOther = !controls.logoutFromOther;
    // agreeTermAndCondition.value = !agreeTermAndCondition.value;
    this.setState({ controls });
    // this.handleValidation();
  }

  openDetailRequestSubmitSuccessAlert() {

  }

  async getState() {
    try {
      const { controls } = this.state;
      const { data } = await WarrantyRegistrationService.getState();
      if (data) {
        this.stateData = data.data;
        const ownState = _.remove(this.stateData, { is_own_state: true });

        if (ownState.length) {
          this.stateData.unshift(ownState[0]);
        }

        if (this.props.addressSelectedIndex !== -1) {
          const tempSelectedState = _.find(this.stateData, (item) => {
            return (item.name ? item.name : '').toString() === ((controls.state && controls.state.value && controls.state.value.name) ? controls.state.value.name : '').toString();
          });
          this.getCity(tempSelectedState);
        }


        this.setState((prevState) => {
          return { ...prevState };
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getCity(state) {
    if (state && state.state_id) {
      try {
        const { data } = await WarrantyRegistrationService.getCity(state.state_id);
        if (data) {
          this.cityData = data.data;

          const ownCity = _.remove(this.cityData, { is_own_city: true });
          if (ownCity.length) {
            this.cityData.unshift(ownCity[0]);
          }


          this.setState((prevState) => {
            return { ...prevState };
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleInputChange = (e) => {
    let { selectedState, selectedCity } = this.state;
    const { controls } = this.state;

    if (e.target.name === 'state') {
      this.getCity(e.target.value);
      selectedState = e.target.value;
      selectedCity = '';
      controls.city.value = '';
    }

    if (e.target.name === 'city') {
      selectedCity = e.target.value;
    }
    const controlName = e.target.name;
    const controlValue = e.target.value;

    controls[controlName].value = controlValue;
    controls[controlName].touched = true;

    this.handleValidation();
    this.setState({ controls, selectedState, selectedCity });
  }

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { state, address, city, pin_code, email, mobile } = controls;
    if (email.touched === true || isSubmit) {
      email = Validation.notNullValidator(email);
      email = Validation.emailValidator(email);
      email.valid = !email.nullValue && email.invalidEmail;
      if (email.valid === false) {
        email.showErrorMsg = true;
      } else {
        email.showErrorMsg = false;
      }
    }
    if (mobile.touched === true || isSubmit) {
      mobile = Validation.notNullValidator(mobile);
      mobile = Validation.validatePhoneNumber(mobile);
      mobile.valid = !mobile.nullValue && !mobile.invalidPhone;
      if (mobile.valid === false) {
        mobile.showErrorMsg = true;
      } else {
        mobile.showErrorMsg = false;
      }
    }
    if (state.touched === true || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }
    if (address.touched === true || isSubmit) {
      address = Validation.notNullValidator(address);
      address.valid = !address.nullValue;
      if (address.valid === false) {
        address.showErrorMsg = true;
      } else {
        address.showErrorMsg = false;
      }
    }
    if (city.touched === true || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }
    if (pin_code.touched === true || isSubmit) {
      pin_code = Validation.notNullValidator(pin_code);
      pin_code = Validation.validateLength(pin_code, 6);
      pin_code = Validation.validateNumber(pin_code);
      // pin_code.valid = !pin_code.nullValue;
      pin_code.valid = !(
        pin_code.nullValue ||
        pin_code.invalidLength ||
        pin_code.invalidNumber
      );
      if (pin_code.valid === false) {
        pin_code.showErrorMsg = true;
      } else {
        pin_code.showErrorMsg = false;
      }
    }
    if (pin_code.valid === true && state.valid === true && city.valid === true && address.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    this.setState({ controls, isFormValid });

    return isFormValid;
  }

  addAddress = (e) => {
    e.preventDefault();
    const { controls } = this.state;

    const isFormValid = this.handleValidation(true);
    if (isFormValid === false) {
      return;
    }
    this.props.closeModal({
      is_parent: false,
      address: controls.address.value,
      email: controls.email.value,
      mobile: controls.mobile.value,
      state: controls.state.value.name,
      state_code: controls.state.value.code,
      city: controls.city.value.name,
      pin_code: controls.pin_code.value,
      uuid: controls.uuid.value,
    });
  }


  closeModal = () => {
    this.setState({ isRequestSubmitSuccessModalOpen: false });
    this.props.closeModal(true);
  }



  handleCheckEmail = (e) => {
    let { isSameProfileEmail, controls, userData } = this.state;
    this.setState({ isSameProfileEmail: !isSameProfileEmail })
    if (e.target.checked === true) {
      controls.email.value = userData.email_1;
      this.setState({ controls })
    } else {
      controls.email.value = '';
      this.setState({ controls })
    }
  }

  handleCheckPhone = (e) => {
    let { isSameProfilePhone, controls, userData } = this.state;
    this.setState({ isSameProfilePhone: !isSameProfilePhone })
    if (e.target.checked === true) {
      controls.mobile.value = userData.telephone_no_1;
      this.setState({ controls })
    } else {
      controls.mobile.value = '';
      this.setState({ controls })
    }
  }


  render() {
    const { controls, isSameProfileEmail, isSameProfilePhone, selectedState, selectedCity, addressSelectedIndex } = this.state;

    return (
      <div>
        <Modal id="change-password" isOpen={this.props.show} toggle={this.props.closeModal} centered>
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col className="modal-title">
                  {addressSelectedIndex < 0 ? 'Add New Address' : 'Edit New Address'}
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <FormGroup className="margin-top-10">
                    <Label for="currentPassword" >Address <span className="required-field">*</span></Label>
                    <Input onChange={this.handleInputChange} value={controls.address.value} type="textarea" className="custom-textarea" rows="3" name="address" id="exampleText" />
                    {controls.address.showErrorMsg && <div className="error">* {messages.ADD_EDIT_ADDRESS.ADDRESS}</div>}
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <div style={{ marginTop: '5px' }}>
                    <Label for="currentPassword" >Email <span className="required-field">*</span></Label>
                    <FormGroup className="d-flex-row vehical-sku-id-search" style={{ marginBottom: '0px' }}>
                      <Input
                        type="text"
                        name="email"
                        value={controls.email.value}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    {controls.email.showErrorMsg && controls.email.nullValue && <div className="error">* {messages.ADD_EDIT_ADDRESS.EMAIL}</div>}
                    {controls.email.showErrorMsg && controls.email.invalidEmail && <div className="error">* {messages.PROFILE.INVALID_EMAIL}</div>}
                    {addressSelectedIndex < 0 ?
                      <FormGroup className='mt-1'>
                        <label className="custom-checkboax1--container">
                          <input
                            type="checkbox"
                            name="checkProfileEmail"
                            checked={isSameProfileEmail}
                            onChange={this.handleCheckEmail}
                          />
                          <span className="checkmark1" />
                        </label>&nbsp;&nbsp;&nbsp;
                        <span className="dropdown-toggle-fleet-type-filter-sub-lbl">Same as per profile</span>
                      </FormGroup>
                      : ''}
                  </div>
                </Col>
                <Col xl="6">
                  <div style={{ marginTop: '5px' }}>
                    <Label for="currentPassword" >Mobile <span className="required-field">*</span></Label>
                    <FormGroup className="d-flex-row vehical-sku-id-search" style={{ marginBottom: '0px' }}>
                      <Input
                        type="text"
                        name="mobile"
                        value={controls.mobile.value}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    {controls.mobile.showErrorMsg && controls.mobile.nullValue && <div className="error">* {messages.ADD_EDIT_ADDRESS.MOBILE}</div>}
                    {controls.mobile.showErrorMsg && controls.mobile.invalidPhone && <div className="error">* {messages.PROFILE.INVALID_PHONE}</div>}
                    {addressSelectedIndex < 0 ?
                      <FormGroup className='mt-1'>
                        <label className="custom-checkboax1--container">
                          <input
                            type="checkbox"
                            name="checkProfileMobile"
                            checked={isSameProfilePhone}
                            onChange={this.handleCheckPhone}
                          />
                          <span className="checkmark1" />
                        </label>&nbsp;&nbsp;&nbsp;
                        <span className="dropdown-toggle-fleet-type-filter-sub-lbl">Same as per profile</span>
                      </FormGroup>
                      : ''}
                  </div>
                </Col>
                <Col xl="12" style={{ marginTop: '15px' }}>
                  <Label for="currentPassword" >State <span className="required-field">*</span></Label>
                  <FormGroup className="d-flex-row vehical-sku-id-search" style={{ marginBottom: '0px' }}>
                    <Typeahead
                      id="selectState"
                      filterBy={(option, props) => {
                        if (props.selected.length) {
                          return true;
                        }
                        return option && option.name && (option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || option.name.toLowerCase().indexOf('other') !== -1);
                      }}
                      bsSize="small"
                      labelKey="name"
                      options={this.stateData}
                      onChange={(selectedState) => this.handleInputChange({ target: { name: 'state', value: selectedState[0] } })}
                      placeholder="Search..."
                      selected={selectedState && selectedState.name ? [selectedState] : []}
                    />
                    <FontAwesomeIcon className="serach-icon" icon={faSearch} color="#6e777f" />
                  </FormGroup>
                  {controls.state.showErrorMsg && <div className="error">* {messages.ADD_EDIT_ADDRESS.STATE}</div>}
                </Col>
                <Col xl="6">
                  <div style={{ marginTop: '18px' }}>
                    <Label for="currentPassword" >City <span className="required-field">*</span></Label>
                    <FormGroup className="d-flex-row vehical-sku-id-search" style={{ marginBottom: '0px' }}>
                      <Typeahead
                        id="selectCity"
                        filterBy={(option, props) => {
                          if (props.selected.length) {
                            return true;
                          }
                          return option && option.name && (option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || option.name.toLowerCase().indexOf('other') !== -1);
                        }}
                        bsSize="small"
                        labelKey="name"
                        options={this.cityData}
                        onChange={(selectedCity) => this.handleInputChange({ target: { name: 'city', value: selectedCity[0] } })}
                        placeholder="Search..."
                        selected={selectedCity && selectedCity.name ? [selectedCity] : []}

                      />
                      <FontAwesomeIcon className="serach-icon" icon={faSearch} color="#6e777f" />
                    </FormGroup>
                    {controls.city.showErrorMsg && <div className="error">* {messages.ADD_EDIT_ADDRESS.CITY}</div>}
                  </div>

                </Col>
                <Col xl="6">
                  <div style={{ marginTop: '18px' }}>
                    <Label for="currentPassword" >Pincode <span className="required-field">*</span></Label>
                    <FormGroup className="d-flex-row vehical-sku-id-search" style={{ marginBottom: '0px' }}>
                      <Input
                        type="text"
                        name="pin_code"
                        value={controls.pin_code.value}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    {controls.pin_code.showErrorMsg && controls.pin_code.nullValue && <div className="error">* {messages.ADD_EDIT_ADDRESS.PINCODE}</div>}
                    {controls.pin_code.showErrorMsg && (controls.pin_code.invalidLength || controls.pin_code.invalidNumber) && <div className="error">* {messages.PROFILE.INVALID_PINCODE}</div>}
                  </div>
                </Col>
              </Row>

              <br />
              <div style={{ textAlign: 'right' }}>
                <Button className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" onClick={() => this.props.closeModal({})} color="dark">Cancel</Button>
                <Button type="submit" className="mb-1 mr-1 btn-pill Rectangle-button send-button" onClick={this.addAddress} color="warning">
                  {addressSelectedIndex < 0 ? 'Add' : 'Edit'}
                </Button>
              </div>
            </Form>
          </ModalBody>

        </Modal>
      </div>
    );
  }
}

export default AddEditAddress; 