import React, { Component } from "react";
import BlockCustomerComponent from '../../components/BlockCustomer/BlockCustomer';

class BlockCustomer extends Component {

  render() {
    return <BlockCustomerComponent
      {...this.props}
    >
    </BlockCustomerComponent>
  }
}

export default BlockCustomer;
