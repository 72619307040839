import React from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import _ from "lodash";
import CampaignService from "../../services/CampaignService";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import TextareaAutosize from "react-textarea-autosize";
import Validation from "../../services/Validation";
import "./BuyProduct.css";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import swal from "sweetalert";
import UpdateDetailProileModal from "./UpdateDetailProileModal";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import Storage from "../../services/Storage";
import { Permissions } from "../../constants/constant.permissions";
const { ERROR_MESSAGE } = messages.COMMON;

class BuyProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      isLoading: false,
      products: [],
      gstInfo: false,
      mode: "warranty",
      points: 0,
      quantity: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      product: {},
      is_disable_state: false,
      is_display_all: false,
      is_display_billing: false,
      is_disable_warranty: false,
      gst_error_msg: "",
      offer: "",
      address_id: null,
      controls: {
        product_qty: {
          value: 1,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        product: {
          value: {},
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        sms_qty: {
          value: 0,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        price: {
          value: 1,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        gst_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        state_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        gst_holder: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        address: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        state: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        pincode: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        mobile: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        email: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        business_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
      },
      next_page_config: {
        current_page: "select_product",
        next_btn_label: "Buy",
        is_display_back: false,
      },
      bread_crum_history: [],
      stateData: [],
      cityData: [],
      displayOfferPoints: 0,
      offerPoints: 0,
      is_first_time_business_name: false,
      isDisplayWarranty: false,
    };

    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.WARRANTY_POINTS_REDEMPTION)
    ) {
      this.state.isDisplayWarranty = true;
    }
  }

  getProducts = () => {
    this.setState({
      isLoading: true,
    });
    CampaignService.getProducts()
      .then((res) => {
        let {
          controls,
          offer,
          address_id,
          is_first_time_business_name,
        } = this.state;
        controls.product.value = res.data.data.products[0].uuid;
        controls.gst_number.value = res.data.data.gst_number
          ? res.data.data.gst_number
          : null;
        is_first_time_business_name = res.data.data.business_name
          ? true
          : false;
        controls.gst_holder.value = res.data.data.gst_holder
          ? res.data.data.gst_holder
          : res.data.data.business_name;
        controls.business_name.value = res.data.data.business_name
          ? res.data.data.business_name
          : res.data.data.gst_holder;
        offer = res.data.data.offer ? res.data.data.offer : "";
        if (res.data.data && res.data.data.address) {
          address_id = res.data.data.address.id;
          controls.state.value = res.data.data.address.state;
          controls.city.value = res.data.data.address.city;
          controls.pincode.value = res.data.data.address.pincode;
          controls.mobile.value = res.data.data.address.mobile;
          controls.email.value = res.data.data.address.email;
          controls.address.value = res.data.data.address.address;
          if (res.data.data.address.state) {
            this.getCity(res.data.data.address.state);
          }
        }
        this.setState(
          {
            controls,
            products: res.data.data.products,
            points: res.data.data.points,
            gstInfo:
              res.data.data.gst_number && res.data.data.gst_number !== null
                ? true
                : false,
            is_disable_state:
              res.data.data.gst_number && res.data.data.gst_number !== null
                ? true
                : false,
            offerPoints: res.data.data.products[0].points,
            offer,
            mode:
              res.data.data.products[0].points >
                (res.data.data.points ? res.data.data.points : 0)
                ? "online"
                : "warranty",
            address_id,
            is_disable_warranty:
              res.data.data.products[0].points >
              (res.data.data.points ? res.data.data.points : 0),
            is_first_time_business_name,
          },
          () => {
            this.getState();
            if (controls.gst_number.value && !controls.state.value) {
              this.getStateByGST();
            }
            this.checkAllField();
            this.displayPrice();
            if (
              res.data.data.products[0].points &&
              Number(res.data.data.products[0].points) > 0
            ) {
              this.calculateOfferPoints();
            }
          }
        );
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });

        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  changePaymentMode = (mode) => {
    if (mode === "warranty" && this.state.is_disable_warranty) {
      return;
    }
    let { next_page_config } = this.state;
    if (mode === "warranty") {
      next_page_config.next_btn_label = "Buy";
      GoogleAnalticsService.event(
        "Buy Product",
        "Buy SMS With Warranty Points Click"
      );
    } else {
      GoogleAnalticsService.event("Buy Product", "Buy SMS With Online Click");
      next_page_config.next_btn_label = "Next";
    }
    this.setState({ mode, next_page_config });
  };

  changeGSTDisplay = (gstInfo) => {
    let { is_disable_state } = this.state;
    is_disable_state = gstInfo;
    if (gstInfo) {
      GoogleAnalticsService.event("Buy Product", "GST Yes Click");
    } else {
      GoogleAnalticsService.event("Buy Product", "GST No Click");
    }
    this.setState({ gstInfo, is_disable_state });
  };

  componentDidMount() {
    GoogleAnalticsService.pageView("Buy SMS View");
    document.body.style.overflow = "hidden";
    this.init();
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  init = async () => {
    this.getProducts();
    this.getState();
  };

  displayPrice = (e) => {
    let {
      controls,
      product,
      points,
      displayOfferPoints,
      next_page_config,
      is_disable_warranty,
      mode,
    } = this.state;
    const index = _.findIndex(this.state.products, function (o) {
      return o.uuid.toString() === controls.product.value.toString();
    });
    product["sms_qty"] = Number(
      this.state.products[index].sms_qty * controls.product_qty.value
    );
    product["price"] = Number(
      this.state.products[index].price * controls.product_qty.value
    );
    displayOfferPoints = Number(
      this.state.products[index].points * controls.product_qty.value
    );
    if (
      Number(displayOfferPoints ? displayOfferPoints : 0) >
      Number(points ? points : 0)
    ) {
      next_page_config.next_btn_label = "Next";
      is_disable_warranty = true;
      mode = "online";
    } else {
      is_disable_warranty = false;
      if (mode === "warranty") {
        next_page_config.next_btn_label = "Buy";
      } else {
        next_page_config.next_btn_label = "Next";
      }
    }

    if (!this.state.isDisplayWarranty) {
      mode = "online";
      next_page_config.next_btn_label = "Next";
    }
    this.setState({
      product,
      points,
      is_disable_warranty,
      mode,
    });
  };

  calculateOfferPoints = () => {
    let { controls, offerPoints, displayOfferPoints } = this.state;
    displayOfferPoints = offerPoints
      ? Number(offerPoints * controls.product_qty.value)
      : 0;
    this.setState({
      displayOfferPoints,
    });
  };

  buySMS = async (isdefault = false) => {
    let { controls } = this.state;
    let req = {
      products: [],
      address: {},
      gst_number: this.state.gstInfo ? controls.gst_number.value : null,
      gst_holder: controls.gst_holder.value,
      business_name: controls.business_name.value,
      isGst: this.state.gstInfo,
    };
    if (controls.gst_number.value && this.state.mode !== "warranty") {
      let state = await this.getStateByGST();
      if (state) {
        return;
      }
      if (!controls.city.value) {
        this.handleValidation(true);
        this.setState({
          is_display_all: false,
          is_display_billing: false,
        });
        return;
      }
    }

    const product = {
      uuid: controls.product.value,
      qty: controls.product_qty.value ? Number(controls.product_qty.value) : 1,
    };
    req.products.push(product);
    req.address = {
      city: controls.city.value,
      state: controls.state.value,
      pincode: controls.pincode.value,
      isDefault: isdefault,
      mobile: controls.mobile.value,
      email: controls.email.value,
      address: controls.address.value,
    };

    if (
      this.state.address_id &&
      (this.state.is_display_all || this.state.is_display_billing)
    ) {
      req.address.id = this.state.address_id;
    }
    // if (this.state.mode === 'online' && this.state.gstInfo === true) {
    //   req.gst_holder = this.state.gst_holder;
    //   req.gst_number = this.state.gst_number
    // }
    this.setState({
      isLoading: true,
    });
    CampaignService.buyProduct(req, this.state.mode)
      .then((res) => {
        if (this.state.mode === "warranty") {
          swal({
            title: "SMS Purchased",
            text: res.data.message,
            icon: "success",
            type: "success",
          }).then((isConfirm) => {
            this.props.openCloseBuyProduct(true);
          });
        } else {
          if (this.props.from && this.props.from === "add-customer") {
            this.props.saveReffDetail();
          }

          if (res.data.data && res.data.data.order_id) {
            localStorage.setItem("order_id_to_cancel", res.data.data.order_id);
          }

          document.getElementById("payment").innerHTML = res.data.data.formBody;
          document.forms["redirect"].submit();
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });

        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  closeModal = () => {
    this.props.closeModal(true);
  };

  getState = () => {
    this.setState({ isLoading: true });
    CampaignService.getState()
      .then((data) => {
        let { stateData, controls } = this.state;
        let { data: states } = data.data;
        stateData = states;
        if (controls.state.value) {
          let filter = stateData.filter(
            (x) => x.state_id === controls.state.value
          );
          if (filter.length > 0) {
            controls.state_name.value = filter[0].name;
          }
        }
        //this.getCity(state_id);
        this.setState({ stateData, controls, isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  getCity = (stateId) => {
    let reqData = {
      state_id: stateId,
    };
    this.setState({ isLoading: true });
    CampaignService.getCity(reqData)
      .then((data) => {
        let { cityData, controls } = this.state;
        const { data: cities } = data.data;
        cityData = cities;
        if (controls.city.value) {
          let filter = cityData.filter(
            (x) => x.city_id === controls.city.value
          );
          if (filter.length > 0) {
            controls.city_name.value = filter[0].name;
          }
        }
        this.setState({ cityData, controls, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };

  handleInputChange = (e) => {
    let {
      product_qty,
      gst_number,
      gst_holder,
      offerPoints,
      is_first_time_business_name,
    } = this.state;
    let { controls } = this.state;
    let control_name = e.target.name;
    let control_value = e.target.value;
    if (control_name === "product") {
      let product = this.state.products.filter((x) => x.uuid === control_value);
      if (product.length > 0) {
        offerPoints = product[0].points;
        this.calculateOfferPoints();
      }
    }
    if (control_name === "gst_number") {
      if (e.target.value) {
        e.target.value = e.target.value.toUpperCase();
      }
      this.setState({
        gst_error_msg: "",
      });
      if (this.state.is_display_all || this.state.is_display_billing) {
        this.editDetail();
      }
    }
    if (control_name === "gst_holder") {
      if (!is_first_time_business_name) {
        controls.business_name.value = e.target.value;
      }
      if (this.state.is_display_all || this.state.is_display_billing) {
        this.editDetail();
      }
    }

    controls[e.target.name].value = e.target.value;
    controls[e.target.name].touched = true;
    // this.handleValidation();
    this.setState(
      {
        controls,
        product_qty,
        gst_number,
        gst_holder,
        offerPoints,
      },
      () => {
        if (control_name === "product_qty" || control_name === "product") {
          this.displayPrice();
        }
        if (control_name === "state") {
          this.getCity(control_value);
        }
        if (control_name === "product_qty") {
          this.calculateOfferPoints();
        }
      }
    );
    this.handleValidation();
  };

  handleValidation = (isSubmit = false) => {
    let { controls, gstInfo } = this.state;
    let isFormValid = false;
    let {
      address,
      state,
      city,
      pincode,
      mobile,
      email,
      gst_holder,
      gst_number,
      business_name,
    } = controls;
    if (address.touched === true || isSubmit) {
      address = Validation.notNullValidator(address);
      address.valid = !address.nullValue;
      if (address.valid === false) {
        address.showErrorMsg = true;
      } else {
        address.showErrorMsg = false;
      }
    }

    if (state.touched === true || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (city.touched === true || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (pincode.touched === true || isSubmit) {
      pincode = Validation.notNullValidator(pincode);
      pincode = Validation.validateLength(pincode, 6);
      pincode = Validation.validateNumber(pincode);
      pincode.valid = !(
        pincode.nullValue ||
        pincode.invalidLength ||
        pincode.invalidNumber
      );
      if (pincode.valid === false) {
        pincode.showErrorMsg = true;
      } else {
        pincode.showErrorMsg = false;
      }
    }

    if (mobile.touched === true || isSubmit) {
      mobile = Validation.notNullValidator(mobile);
      mobile = Validation.validatePhoneNumber(mobile);
      mobile.valid = !(mobile.nullValue || mobile.invalidPhone);
      if (mobile.valid === false) {
        mobile.showErrorMsg = true;
      } else {
        mobile.showErrorMsg = false;
      }
    }

    if (email.touched === true || isSubmit) {
      email = Validation.notNullValidator(email);
      email = Validation.emailValidator(email);
      email.valid = !email.invalidEmail && !email.nullValue;
      if (email.valid === false) {
        email.showErrorMsg = true;
      } else {
        email.showErrorMsg = false;
      }
    }

    if ((gst_number.touched === true || isSubmit) && gstInfo) {
      gst_number = Validation.notNullValidator(gst_number);
      gst_number.valid = !gst_number.nullValue;
      if (gst_number.valid === false) {
        gst_number.showErrorMsg = true;
      } else {
        gst_number.showErrorMsg = false;
      }
    } else {
      gst_number.showErrorMsg = false;
      gst_number.valid = true;
    }

    if ((gst_holder.touched === true || isSubmit) && gstInfo) {
      gst_holder = Validation.notNullValidator(gst_holder);
      gst_holder.valid = !gst_holder.nullValue;
      if (gst_holder.valid === false) {
        gst_holder.showErrorMsg = true;
      } else {
        gst_holder.showErrorMsg = false;
      }
    } else {
      gst_holder.showErrorMsg = false;
      gst_holder.valid = true;
    }

    if (business_name.touched === true || isSubmit) {
      business_name = Validation.notNullValidator(business_name);
      business_name.valid = !business_name.nullValue;
      if (business_name.valid === false) {
        business_name.showErrorMsg = true;
      } else {
        business_name.showErrorMsg = false;
      }
    }

    if (
      address.valid === true &&
      state.valid === true &&
      city.valid === true &&
      pincode.valid === true &&
      email.valid === true &&
      mobile.valid === true &&
      gst_number.valid === true &&
      gst_holder.valid === true &&
      business_name.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls,
    });
    return isFormValid;
  };

  checkAllField = () => {
    let { controls, gstInfo, is_display_all, is_display_billing } = this.state;
    let {
      address,
      state,
      city,
      pincode,
      mobile,
      email,
      gst_holder,
      gst_number,
    } = controls;

    if (
      address.value &&
      state.value &&
      city.value &&
      pincode.value &&
      email.value &&
      mobile.value &&
      gst_holder.value
    ) {
      if (gst_holder.value && gst_number.value) {
        is_display_billing = false;
      } else {
        is_display_billing = true;
        gstInfo = false;
      }
      if (is_display_billing) {
        is_display_all = false;
      } else {
        is_display_all = true;
      }
    } else {
      is_display_all = false;
    }
    if (is_display_all) {
      GoogleAnalticsService.event(
        "Buy Product",
        "Buy SMS Billing Details View"
      );
    }
    this.setState({
      gstInfo,
      is_display_all,
      is_display_billing,
    });
  };

  setNextPageData = async (config) => {
    let { next_page_config, bread_crum_history } = this.state;
    bread_crum_history.push(Object.assign({}, next_page_config));
    next_page_config.current_page = config.current_page;
    next_page_config.next_btn_label = config.next_btn_label
      ? config.next_btn_label
      : "Next";
    next_page_config.is_display_back = config.is_display_back;
    await this.setState({
      next_page_config,
      bread_crum_history,
    });
    return true;
  };

  renderNext = async () => {
    let { next_page_config } = this.state;
    if (next_page_config.current_page === "select_product") {
      if (this.state.mode === "warranty") {
        this.buySMS();
        GoogleAnalticsService.event(
          "Buy Product",
          "Buy SMS With Warranty Points Click"
        );
        GoogleAnalticsService.event("Buy Product", "Buy SMS Buy Click");
        return;
      }
      await this.setNextPageData({
        current_page: "gst_detail",
        next_btn_label: "Buy",
        is_display_back: true,
      });
    } else if (next_page_config.current_page === "gst_detail") {
      if (!this.handleValidation(true)) {
        return;
      }
      if (this.state.is_display_all || this.state.is_is_display_billing) {
        this.buySMS(false);
      } else {
        this.refs.UpdateDetailProileModal.toggle();
      }
    }
    GoogleAnalticsService.event("Buy Product", "Buy SMS With Online Click");
    GoogleAnalticsService.event("Buy Product", "Buy SMS Buy Click");
  };
  backPage = () => {
    let { bread_crum_history, next_page_config } = this.state;
    if (bread_crum_history.length > 0) {
      next_page_config = bread_crum_history[bread_crum_history.length - 1];
      bread_crum_history.pop();
    }
    this.setState({
      next_page_config,
      bread_crum_history,
    });
    return;
  };

  closeSideBar = () => {
    this.props.openCloseBuyProduct();
  };

  getStateByGST = async (gst_number = null) => {
    let { controls } = this.state;
    this.setState({ isLoading: true });
    await CampaignService.getStateByGST({
      gst: gst_number ? gst_number : controls.gst_number.value,
    })
      .then((res) => {
        let { controls,
          // stateData
        } = this.state;
        if (
          res.data &&
          res.data.data &&
          Object.keys(res.data.data).length > 0
        ) {
          // let state_index = stateData.findIndex(
          //   (x) => x.state_id === res.data.data.uuid
          // );
          if (res.data.data.uuid !== controls.state.value) {
            controls.state.value = res.data.data.uuid;
            controls.city.value = "";
            this.getCity(res.data.data.uuid);
          }
        }
        this.setState({ controls, isLoading: false });
        return true;
      })
      .catch((e) => {
        console.log("e", e);
        this.setState({ isLoading: false });
        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({
          gst_error_msg: message,
        });
        return false;
      });
  };

  onBlurGSTNumber = (e) => {
    if (e.target.value) {
      this.getStateByGST(e.target.value);
    }
  };

  submitUpdateDetailProileModal = (flag) => {
    if (flag) {
      GoogleAnalticsService.event("Buy Product", "Yes Click on Update Profile");
    } else {
      GoogleAnalticsService.event("Buy Product", "No Click on Update Profile");
    }
    this.buySMS(flag);
  };

  editDetail = () => {
    GoogleAnalticsService.event("Buy Product", "Edit Billing Details Click");
    this.setState({
      is_display_all: false,
      is_display_billing: false,
    });
  };
  render() {
    const {
      gstInfo,
      mode,
      // gst_number,
      products,
      points,
      product,
      // product_qty,
      // gst_holder,
      controls,
      next_page_config,
      stateData,
      // cityData,
      offer,
      is_disable_state,
      is_first_time_business_name,
      isDisplayWarranty,
    } = this.state;

    let details = (
      <Row className={"m-0"} style={{ paddingLeft: "6px" }}>
        <Col xl="12" className="d-flex-column">
          <span className="buy-prod-detail-span font-size-16px mb-3 font-weight-bold">
            Billing Details
          </span>
          <div className="d-flex-row justify-content-between mb-3">
            <div className="d-flex-column">
              <div className="d-flex-row font-weight-bold mb-1">
                <span className="buy-prod-detail-span font-size-15px">
                  {controls.gst_holder.value}.
                </span>
                <span className="buy-prod-detail-span font-size-13px">
                  ({controls.mobile.value})
                </span>
              </div>
              <span className="buy-prod-detail-span font-size-13px font-weight-bold">
                {controls.email.value}
              </span>
            </div>
            <img
              className="cursor-pointer mt-auto mb-auto"
              src="images/edit.svg"
              alt=''
              style={{ height: "16px" }}
              onClick={() => this.editDetail()}
            />
          </div>
          <span className="buy-prod-detail-span font-size-13px mb-3 width-300px">
            {controls.address.value}.
          </span>
          <span className="buy-prod-detail-span font-size-13px mb-1">
            {controls.city_name.value}
          </span>
          <div className="d-flex-row mb-3 font-size-13px">
            <span className="buy-prod-detail-span">
              {controls.state_name.value}
            </span>
            &nbsp;-&nbsp;
            <span className="buy-prod-detail-span font-size-13px">
              {controls.pincode.value}
            </span>
          </div>
          {controls.gst_number.value && (
            <span className="buy-prod-detail-span font-size-14px">
              GSTIN&nbsp;-&nbsp;{controls.gst_number.value}
            </span>
          )}
        </Col>
      </Row>
    );
    return (
      <div class="custom-drawer">
        <UpdateDetailProileModal
          ref="UpdateDetailProileModal"
          submit={this.submitUpdateDetailProileModal}
        />
        {this.state.isLoading && <CustomSpinner />}
        <div className="drawer-header">
          <span className="drawer-title" style={{ marginLeft: "10px" }}>
            Buy Product
          </span>
          <img
            className="cursor-pointer"
            src="images/close.svg"
            alt="cancel"
            onClick={() => this.closeSideBar(false)}
          />
        </div>
        <hr style={{ border: "solid 1px #ced4da" }} className="w-100" />
        <div class="custom-drawer-content" style={{ marginLeft: "10px" }}>
          <Row
            className={
              next_page_config.current_page !== "select_product"
                ? "m-0 hidden"
                : "m-0"
            }
          >
            {offer && (
              <Col xl="12">
                <div
                  className="service-pref-note"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    paddingRight: "25px",
                  }}
                >
                  {offer}
                </div>
              </Col>
            )}

            <Col xl="12">
              <FormGroup className="margin-top-10">
                <Label>
                  Select Product<span className="required-field">*</span>
                </Label>
                <select
                  name="product"
                  className="form-control font-size-14px"
                  value={controls.product.value}
                  onChange={(e) => this.handleInputChange(e)}
                >
                  {products.map((product, index) => (
                    <option value={product.uuid} key={index}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>

            <Col xl="12">
              <FormGroup>
                <Label>
                  Select Quantity<span className="required-field">*</span>
                </Label>
                <select
                  name="product_qty"
                  className="form-control font-size-14px"
                  value={controls.product_qty.value}
                  onChange={(e) => this.handleInputChange(e)}
                >
                  {this.state.quantity.map((qty, index) => (
                    <option value={qty} key={index}>
                      {qty}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col xl="12">
              <Label>
                Price INR: <b>{product && product.price}</b>
                <br />
              </Label>
              <Label style={{ marginLeft: "10px" }}>
                SMS Credit : <b>{product && product.sms_qty}</b>
                <br />{" "}
              </Label>
              {this.state.displayOfferPoints &&
                isDisplayWarranty &&
                this.state.displayOfferPoints > 0 && (
                  <Label>
                    To avail this services you are requested{" "}
                    <b>{this.state.displayOfferPoints}</b> points.
                  </Label>
                )}
            </Col>
            {isDisplayWarranty && (
              <Col xl="12">
                <FormGroup>
                  <Label>Select Payment Mode</Label>
                  <br />
                  <div
                    className={
                      this.state.is_disable_warranty
                        ? "opacity-0-5 d-flex-row"
                        : "d-flex-row"
                    }
                  >
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="warranty"
                        value="warranty"
                        checked={mode === "warranty"}
                        onChange={() => this.changePaymentMode("warranty")}
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: "10px" }}>
                      <b>Warranty Points</b> ({points ? points : 0})
                    </span>
                  </div>
                  <div className="d-flex-row" style={{ marginTop: "10px" }}>
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="online"
                        value="online"
                        checked={mode === "online"}
                        onChange={() => this.changePaymentMode("online")}
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: "10px" }}>
                      {" "}
                      <b>Online</b> (Credit Card, Debit Card, Net Banking)
                    </span>
                  </div>
                </FormGroup>
              </Col>
            )}
          </Row>
          <div
            className={
              next_page_config.current_page !== "gst_detail"
                ? "m-0 hidden"
                : "m-0"
            }
          >
            {!this.state.is_display_all && !this.state.is_display_billing && (
              <Row className={"m-0"} style={{ paddingLeft: "6px" }}>
                <Col xl="12" className="d-flex-column">
                  <Label className="font-size-14px font-weight-bold">
                    GST Detail
                  </Label>
                </Col>
                <Col xl="12">
                  <FormGroup>
                    <Label>
                      Please provide GST Details if you want tax invoice
                    </Label>
                    <div className="d-flex-row">
                      <label className="custom-checkboax-radio--container">
                        <input
                          type="radio"
                          name="yes"
                          value="yes"
                          checked={gstInfo === true}
                          onChange={() => this.changeGSTDisplay(true)}
                        />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: "10px" }}>Yes</span>

                      <label
                        className="custom-checkboax-radio--container"
                        style={{ marginLeft: "20px" }}
                      >
                        <input
                          type="radio"
                          name="no"
                          value="no"
                          checked={gstInfo === false}
                          onChange={() => this.changeGSTDisplay(false)}
                        />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: "10px" }}> No </span>
                    </div>
                  </FormGroup>
                </Col>
                {gstInfo && (
                  <>
                    <Col xl="6">
                      <FormGroup className="margin-top-10">
                        <Label>
                          GSTIN<span className="required-field">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="gst_number"
                          value={controls.gst_number.value}
                          placeholder="Type here"
                          onBlur={(e) => this.onBlurGSTNumber(e)}
                          onChange={(e) => this.handleInputChange(e)}
                        />
                        {controls.gst_number.showErrorMsg && (
                          <div className="error show_error">
                            *Please enter gstin.
                          </div>
                        )}
                        {this.state.gst_error_msg &&
                          !controls.gst_number.showErrorMsg && (
                            <div className="error show_error">
                              *{this.state.gst_error_msg}.
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup className="margin-top-10 d-flex-column">
                        <Label>
                          Legal Name<span className="required-field">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="gst_holder"
                          placeholder="Type here"
                          value={controls.gst_holder.value}
                          onChange={(e) => this.handleInputChange(e)}
                        />
                        <span className="Based-on-GST-Certificate-label">
                          Based on GST Certificate
                        </span>
                        {controls.gst_holder.showErrorMsg &&
                          is_disable_state && (
                            <div className="error">*Please legal name.</div>
                          )}
                      </FormGroup>
                    </Col>
                  </>
                )}
                <hr
                  style={{ border: "solid 1px #ced4da", marginLeft: "-10px" }}
                  className="w-100"
                />
                <Col xl="12" className="d-flex-column">
                  <Label className="font-size-14px font-weight-bold">
                    Billing Details
                  </Label>
                </Col>
                <Col xl="12" md="12" xs="12" className="p-10-px">
                  <FormGroup>
                    <Label for="common">
                      Address<span className="required-field">*</span>
                    </Label>
                    <TextareaAutosize
                      name="comment"
                      autocomplete="nofill"
                      id="remark"
                      value={controls.address.value}
                      style={{ minHeight: 55, maxHeight: 90, fontSize: "14px" }}
                      className="form-control"
                      onChange={(e) =>
                        this.handleInputChange({
                          target: { name: "address", value: e.target.value },
                        })
                      }
                      placeholder="Type here"
                    />
                    {controls.address.showErrorMsg && (
                      <div className="error show_error">
                        *Please enter address.
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <FormGroup>
                    <Label>
                      Select State<span className="required-field">*</span>
                    </Label>
                    <select
                      name="state"
                      className="form-control font-size-14px"
                      value={controls.state.value}
                      disabled={is_disable_state}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value={""} key={"-1"}>
                        Select
                      </option>
                      {stateData.map((state, index) => (
                        <option value={state.state_id} key={index}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {controls.state.showErrorMsg && !is_disable_state && (
                      <div className="error show_error">
                        *Please select state.
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <FormGroup>
                    <Label>
                      Select City<span className="required-field">*</span>
                    </Label>
                    <select
                      name="city"
                      className="form-control font-size-14px"
                      value={controls.city.value}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value={""} key={"-1"}>
                        Select
                      </option>
                      {this.state.cityData.map((city, index) => (
                        <option value={city.city_id} key={index}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                    {controls.city.showErrorMsg && (
                      <div className="error show_error">
                        *Please select city.
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <FormGroup>
                    <Label>
                      Pincode<span className="required-field">*</span>
                    </Label>
                    <Input
                      type="text"
                      autoComplete="nofill"
                      className="edit-name"
                      name="pincode"
                      id="pincode"
                      value={controls.pincode.value}
                      onChange={this.handleInputChange}
                      placeholder="Type here"
                    />
                    {controls.pincode.showErrorMsg && (
                      <div className="error">*Please enter pincode.</div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="6"></Col>
                <Col xl="6">
                  <FormGroup>
                    <Label>
                      Business Name<span className="required-field">*</span>
                    </Label>
                    <Input
                      type="text"
                      autoComplete="nofill"
                      name="business_name"
                      id="business_name"
                      value={controls.business_name.value}
                      disabled={
                        is_first_time_business_name ? true : is_disable_state
                      }
                      onChange={this.handleInputChange}
                      placeholder="Type here"
                    />
                    {controls.business_name.showErrorMsg &&
                      !is_disable_state && (
                        <div className="error">*Please business name.</div>
                      )}
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <Label>
                    Mobile<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <FormGroup className="mb-0">
                      <Input
                        type="text"
                        autoComplete="nofill"
                        className="nine-one-rect"
                        disabled={true}
                        value="+91"
                      />
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="mobile"
                        maxLength="10"
                        value={controls.mobile.value}
                        onChange={this.handleInputChange}
                        style={{ marginLeft: "8px" }}
                        placeholder="Type here"
                      />
                      {controls.mobile.showErrorMsg && (
                        <div className="error">*Please enter mobile.</div>
                      )}
                    </FormGroup>
                  </div>
                </Col>
                <Col xl="12">
                  <FormGroup>
                    <Label>
                      Email<span className="required-field">*</span>
                    </Label>
                    <Input
                      type="text"
                      autoComplete="nofill"
                      name="email"
                      id="email"
                      value={controls.email.value}
                      onChange={this.handleInputChange}
                      placeholder="Type here"
                    />
                    {controls.email.showErrorMsg && (
                      <div className="error">*Please enter email.</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            )}
            {!this.state.is_display_all && this.state.is_display_billing && (
              <>
                <Row className={"m-0"}>
                  <Col xl="12" className="d-flex-column">
                    <Label className="font-size-14px font-weight-bold">
                      GST Detail
                    </Label>
                  </Col>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Please provide GST Details if you want tax invoice
                      </Label>
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="yes"
                            value="yes"
                            checked={gstInfo === true}
                            onChange={() => this.changeGSTDisplay(true)}
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>Yes</span>

                        <label
                          className="custom-checkboax-radio--container"
                          style={{ marginLeft: "20px" }}
                        >
                          <input
                            type="radio"
                            name="no"
                            value="no"
                            checked={gstInfo === false}
                            onChange={() => this.changeGSTDisplay(false)}
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}> No </span>
                      </div>
                    </FormGroup>
                  </Col>
                  {gstInfo && (
                    <>
                      <Col xl="6">
                        <FormGroup className="margin-top-10">
                          <Label>
                            GSTIN<span className="required-field">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="gst_number"
                            value={controls.gst_number.value}
                            placeholder="Type here"
                            onBlur={(e) => this.onBlurGSTNumber(e)}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          {controls.gst_number.showErrorMsg && (
                            <div className="error show_error">
                              *Please enter gstin.
                            </div>
                          )}
                          {this.state.gst_error_msg &&
                            !controls.gst_number.showErrorMsg && (
                              <div className="error show_error">
                                *{this.state.gst_error_msg}.
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                      <Col xl="6">
                        <FormGroup className="margin-top-10 d-flex-column">
                          <Label>
                            Legal Name<span className="required-field">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="gst_holder"
                            placeholder="Type here"
                            value={controls.gst_holder.value}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          <span className="Based-on-GST-Certificate-label">
                            Based on GST Certificate
                          </span>
                          {controls.gst_holder.showErrorMsg &&
                            is_disable_state && (
                              <div className="error">*Please legal name.</div>
                            )}
                        </FormGroup>
                      </Col>
                    </>
                  )}
                  <hr
                    style={{ border: "solid 1px #ced4da", marginLeft: "-10px" }}
                    className="w-100"
                  />
                </Row>
                {details}
              </>
            )}
            {this.state.is_display_all && !this.state.is_display_billing && (
              <>{details}</>
            )}
          </div>
        </div>
        <Row
          className="c-top-15 custom-drawer-footer"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <Col className="d-flex-row" style={{ justifyContent: "flex-end" }}>
            {next_page_config.is_display_back && (
              <>
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                  color="dark"
                  onClick={() => this.backPage()}
                  style={{ width: "116px" }}
                >
                  Back
                </Button>{" "}
                &nbsp; &nbsp;
              </>
            )}
            <Button
              //  disabled={!isValidForm}
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => this.renderNext()}
            >
              {next_page_config.next_btn_label}
            </Button>
          </Col>
        </Row>
        <div id="payment"></div>
      </div>
    );
  }
}

export default BuyProduct;
