import React from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col,
} from "reactstrap";

import Ionicons from 'react-ionicons';

import CustomerManagementService from "../../services/CustomerManagementService";

import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import { formatDate } from "../../utils/utils";

import "./ServiceDetailModal.css";

class ServiceDetailModal extends React.Component {
  state = {
    services: [],
    isLoading: false
  };

  componentDidMount() {
    let data = this.props.data;
    this.getServiceList(data.customer_id);
  }

  getServiceList = (customerId) => {
    this.setState({
      isLoading: true
    })
    CustomerManagementService.getCustomerServiceTaken(customerId)
      .then(data => {
        if (data.data.data.length > 0) {
          this.setState({ services: data.data.data });
        }
        this.setState({ isLoading: false })
      })
      .catch(e => {
        this.setState({ isLoading: false })
      })
  }


  closeModal = () => {
    this.props.closeModal(true);
  };

  viewHistory = () => {
    this.props.viewHistory();
  }

  render() {
    const { services, isLoading } = this.state;
    // let obj = this.props.data ? this.props.data : [];
    // let obj = [
    //   {
    //     service_name: 'Wheel Alignment',
    //     last_taken: "15 Jan'20"
    //   },
    //   {
    //     service_name: 'Wheel Alignment',
    //     last_taken: "15 Jan'20"
    //   }
    // ]
    return (
      <div>
        <Modal
          id="service-detail-modal"
          isOpen={this.props.show}
          toggle={this.props.closeModal}
          centered
        >
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody style={{ minHeight: '100px' }}>
            {isLoading && <CustomSpinner></CustomSpinner>}
            <Row className="modal-title-border padding-bottom-10">
              <Col className="modal-title">SERVICES TAKEN</Col>
              <Col sm="2" className="text-align-right">
                <span className="cursor-pointer" onClick={this.closeModal}>
                  <Ionicons icon="md-close" color="#fdbb1f"></Ionicons>
                </span>
              </Col>
              <Col xl="10" className="pr-0">
                <span className="note">
                  The list of services taken by customers during a few last visits.
                </span>
              </Col>
            </Row>
            <div></div>
            {/* {obj.map(o =>  */}
            <ul className="list-dot-padding title-list-style margin-top-15">
              <li>
                <Row>
                  <Col className="section-field-title">Services</Col>
                  <Col className="text-align-right section-field-title ">Last Taken</Col>
                </Row>
              </li>
            </ul>
            {services.map(o =>
              <ul className="list-dot-padding">
                <li>
                  <Row>
                    <Col className="section-field-value">{o.service_name}</Col>
                    <Col className="text-align-right section-field-value">{o.last_taken && formatDate(o.last_taken)}</Col>
                  </Row>
                </li>
              </ul>
            )}
            <div className="visit-history mt-0 mb-0 mt-3 justify-content-end d-flex" onClick={() => this.viewHistory()}>
              <img src="/images/history_icon.png" alt="" class="Fill-1"
                style={{ height: '16px' }} />
              &nbsp;&nbsp;
              <span className="ANV cursor-pointer font-size-14px">Visit History</span>
            </div>
            {/* <Row>
                <Col sm="1" className="padding-left-0">
                  <ul>
                    <li>

                    </li>
                  </ul>
                </Col>
                <Col>
                  <div className="section-field-value">Wheel Alignment</div>
                </Col>
                <Col className="padding-right-0">
                  <div className="section-field-value">15 Jan'20</div>
                </Col>
              </Row> */}
            {/* )} */}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ServiceDetailModal;
