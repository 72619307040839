import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";

import DashboardWeb from "../../components/DashboardWeb/DashboardWeb";
import DashboardMobile from "../../components/DashboardMobile/DashboardMobile";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import SpeacialEngagement from "../../modal/SpeacialEngagement/SpeacialEngagement";
import WarrantyRegistrationService from "../../services/WarrantyRegistrationService";
import DashboardService from "../../services/DashboardService";
import StorageService from "../../services/Storage";
import SpeacialEngagementService from "../../services/SpecialEngagementService";

import { messages } from "../../constants/constant.message";
import { config } from "../../constants/constant.config";
import { connect } from "react-redux";
import Sidebar from "react-sidebar";
import { Card, CardBody } from "reactstrap";
// utils
import {
  checkUserAgent,
  findCurrentQuarterMonths,
  // findMonthFromFirstQToPreviousQMonths,
  // getCurrentMonth,
  getAction,
} from "../../utils/utils";

import "./Dashboard.css";
import { askForPermissioToReceiveNotifications } from "../../push-notification";
import { setAddCustomerRedirect, setRedirect } from "../../actions/UserAction";
import AddCustomer from "../../components/AddCustomer/AddCustomer";
import ModalService from "../../services/ModalService";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

const dashBoardImages = config.DASHBOARD;
const displayBuyProductBtn = config.DISPLAY_BUY_PRODUCT;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClamRegistrationOpen: false,
      warranty_data: {
        customer_count: 0,
        pcr_wheeler_tyre: 0,
        total_points: 0,
        two_three_wheeler_tyre: 0,
        scv_tyre: 0,
        lcv_tyre: 0,
        sensor_count: 0,
        pcr_wheeler_tyre_with_sensor: 0,
        two_three_wheeler_tyre_with_sensor: 0,
        scv_tyre_with_sensor: 0,
        lcv_tyre_with_sensor: 0,
      },
      showYTDCard: false,
      showMTDCard: false,
      year_name: null,
      year: 2019,
      showQTDCard: false,
      opensidebar: false,
      yearObj: {
        period: "Year",
        duration: null,
        count: 0,
        pcr: {
          offtake: 0,
          points: 0,
        },
        truck: {
          offtake: 0,
          points: 0,
        },
        farm: {
          offtake: 0,
          points: 0,
        },
        wheeler: {
          offtake: 0,
          points: 0,
        },
        scv: {
          offtake: 0,
          points: 0,
        },
        lcv: {
          offtake: 0,
          points: 0,
        },
      },
      quarterObj: {
        period: "Quarter",
        duration: null,
        count: 0,
        pcr: {
          offtake: 0,
          points: 0,
        },
        truck: {
          offtake: 0,
          points: 0,
        },
        farm: {
          offtake: 0,
          points: 0,
        },
        wheeler: {
          offtake: 0,
          points: 0,
        },
        scv: {
          offtake: 0,
          points: 0,
        },
        lcv: {
          offtake: 0,
          points: 0,
        },
      },
      monthObj: {
        period: "Month",
        duration: null,
        count: 0,
        pcr: {
          offtake: 0,
          points: 0,
        },
        truck: {
          offtake: 0,
          points: 0,
        },
        farm: {
          offtake: 0,
          points: 0,
        },
        wheeler: {
          offtake: 0,
          points: 0,
        },
        scv: {
          offtake: 0,
          points: 0,
        },
        lcv: {
          offtake: 0,
          points: 0,
        },
      },
      controls: {
        warrantyPhoneNumber: {
          value: "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
        },
        warrantyOTP: {
          value: "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
        },
      },
      isSentOTP: false,
      isLoader: false,
      isValidForm: false,
      invalidOTP: "",
      redeemEnable: false,
      redeemDays: 0,
      currentQMonths: "",
      totalPrimaryPoints: 0,
      totalPrivionalPoints: 0,
      totalSeconadryPoints: 0,
      totalYearlyOfftake: 0,
      noWarrantyCustomers: 0,
      redirectToWarrantyRegistration: false,
      token: "",
      sparkLineChartPrimary: {
        categories: ["Apr", "May", "Jun"],
        data: [0, 0, 0],
      },
      sparkLineChartSecondary: {
        categories: ["Apr", "May", "Jun"],
        data: [0, 0, 0],
      },
      stackedChartWeb: {
        categories: [
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
        ],
        series: [
          {
            name: "Pcr",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Truck",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Wheeler",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Farm",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Lcv",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Scv",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        stackedChartMobile: {
          categories: {
            q1: ["Apr", "May", "Jun"],
            q2: ["Apr", "May", "Jun"],
            q3: ["Apr", "May", "Jun"],
            q4: ["Apr", "May", "Jun"],
          },
          series: {
            q1: [
              {
                name: "Pcr",
                data: [0, 0, 0],
              },
              {
                name: "Truck",
                data: [0, 0, 0],
              },
              {
                name: "Wheeler",
                data: [0, 0, 0],
              },
              {
                name: "Farm",
                data: [0, 0, 0],
              },
              {
                name: "Lcv",
                data: [0, 0, 0],
              },
              {
                name: "Scv",
                data: [0, 0, 0],
              },
            ],
            q2: [
              {
                name: "Pcr",
                data: [0, 0, 0],
              },
              {
                name: "Truck",
                data: [0, 0, 0],
              },
              {
                name: "Wheeler",
                data: [0, 0, 0],
              },
              {
                name: "Farm",
                data: [0, 0, 0],
              },
              {
                name: "Lcv",
                data: [0, 0, 0],
              },
              {
                name: "Scv",
                data: [0, 0, 0],
              },
            ],
            q3: [
              {
                name: "Pcr",
                data: [0, 0, 0],
              },
              {
                name: "Truck",
                data: [0, 0, 0],
              },
              {
                name: "Wheeler",
                data: [0, 0, 0],
              },
              {
                name: "Farm",
                data: [0, 0, 0],
              },
              {
                name: "Lcv",
                data: [0, 0, 0],
              },
              {
                name: "Scv",
                data: [0, 0, 0],
              },
            ],
            q4: [
              {
                name: "Pcr",
                data: [0, 0, 0],
              },
              {
                name: "Truck",
                data: [0, 0, 0],
              },
              {
                name: "Wheeler",
                data: [0, 0, 0],
              },
              {
                name: "Farm",
                data: [0, 0, 0],
              },
              {
                name: "Lcv",
                data: [0, 0, 0],
              },
              {
                name: "Scv",
                data: [0, 0, 0],
              },
            ],
          },
        },
      },
      stackedChart: null,
      advantageMessage: null,
      memberRole: null,
      popupDetail: null,
      isSpecialEngagementModalOpen: false,
      redeemData: {},
      displayLabels: null,
      displayLabelsWarrantyTrends: null,
      bannerData: [],
      is_from_v2_dashbaord: false,
      is_display_new_warranty_reg_login: false,
      userDetail: StorageService.getUserDetail(),
    };
    const query = new URLSearchParams(this.props.location.search);
    if (query.get("is_from_v2_dashbaord")) {
      if (
        query.get("is_from_v2_dashbaord") === "true" ||
        query.get("is_from_v2_dashbaord") === true
      ) {
        this.state.is_from_v2_dashbaord = true;
        this.state.is_display_new_warranty_reg_login = false;
      }
    }
    // HIDE CODE
    // if (this.state.userDetail.type && this.state.userDetail.type === Roles.DEALER_ROLE) {
    //   if (
    //     this.state.userDetail.permissions &&
    // this.state.userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD) &&
    //     !this.state.is_from_v2_dashbaord
    //   ) {
    //     this.props.history.push(`dealer-dashboard`);
    //   }
    // }
    if (
      this.state.userDetail.type &&
      (this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE ||
        this.state.userDetail.type === Roles.PROJECT_MANAGER_ROLE)
    ) {
      this.props.history.push(`dashboard`);
    }
    if (this.state.userDetail.type && this.state.userDetail.type === Roles.VENDOR_ROLE) {
      this.props.history.push(`retail-marketing-system`);
    }
    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(
        Permissions.CHECK_ELIGIBILITY_FOR_WARRANTY
      )
    ) {
      this.state.isClamRegistrationOpen = true;
    }

    if (
      this.state.userDetail.type &&
      (this.state.userDetail.type === Roles.SUB_DEALER_ROLE ||
        this.state.userDetail.type === Roles.BRANCH_ROLE ||
        this.state.userDetail.customer_class === "hy")
    ) {
      this.props.history.push(`warranty-score-card`);
    }
  }

  redirectToSecondaryReport = () => {
    window.scroll(0, 0);
    this.props.history.push("/about?secondary=true");
  };

  redirectToWarrantyReplacement = (phoneNumber, token) => {
    window.scroll(0, 0);
    this.props.history.push(
      `warranty-replacement?phoneNumber=${phoneNumber}&t=${token}`
    );
  };

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      // routUrl =
      //   window.location.href &&
      //   window.location.href.split("/")[5] &&
      //   window.location.href.split("/")[5];
      // if (routUrl === "create") {
      //   this.openCreateCampaignPageNew(true);
      // } else if (routUrl === "schedule") {
      //   this.getCampaignDataScheduleAndCompleted(true);
      // } else {
      //   this.getCampaignDataScheduleAndCompleted(false);
      // }
    });
  }

  componentDidMount() {
    GoogleAnalticsService.pageView("Points Dashboard View");
    this.getBanner();
    window.scroll(0, 0);
    const userDetail = StorageService.getUserDetail();
    askForPermissioToReceiveNotifications();
    const role = userDetail.type;
    this.setState({ memberRole: role });
    const currentQMonths = findCurrentQuarterMonths();
    const stackedChart = this.prepareStackedChart(
      this.state.stackedChartWeb.categories,
      this.state.stackedChartWeb.series
    );
    this.setState({ stackedChart, currentQMonths });
    this.prepareDataQuarterWise(
      this.state.stackedChartWeb.categories,
      this.state.stackedChartWeb.series
    );

    // this.getSecondaryCount()
    //   .then(data => {
    //     // this.loadInitialData();
    //   })
    //   .catch(e => {
    //     // this.loadInitialData();
    //   });
    this.getPopupLink();
    if (
      this.props.redirectDetailsAddCustomer &&
      this.props.redirectDetailsAddCustomer.redirectToAddCustomer
    ) {
      this.props.setAddCustomerRedirect(null);
      this.setState({
        opensidebar: true,
      });
    }
  }

  loadInitialData = (year) => {
    this.getSecondaryCount(year ? year : null);
    this.getAllWarrantyCounts();
    this.getMtd(year ? year : null);
    this.getQtd(year ? year : null);
    this.getYtd(year ? year : null);
    this.getYearlyOfftake(year ? year : null);
    this.getPrimaryPoints(year ? year : null);
    this.getPrimaryGraph(year ? year : null);
    this.getRedeemData(year ? year : null);
    this.getDisplayLabel(year ? year : null);
  };

  getBanner = async () => {
    await DashboardService.getBanner()
      .then((res) => {
        let { bannerData, is_display_new_warranty_reg_login } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          bannerData = res.data.data;
          is_display_new_warranty_reg_login = true;
          this.bannerViewClick({
            uuid: res.data.data[0].uuid,
            is_viewed: true,
          });
        } else {
          bannerData = [];
          is_display_new_warranty_reg_login = false;
        }
        this.setState(
          {
            bannerData,
            is_display_new_warranty_reg_login,
          },
          () => {
            // if (this.sliderBanner.current) {
            //   this.sliderBanner.current.slickGoTo(0);
            // }
          }
        );
      })
      .catch((e) => {
        console.log("err", e);
      });
  };

  redirectFromBanner = (item) => {
    if (item.action) {
      let path_data = getAction(item.action);
      if (typeof path_data === "string") {
        this.props.history.push(path_data);
      } else {
        if (path_data.isRedeemCouponOpen) {
          localStorage.setItem("redirectDetails", JSON.stringify(path_data));
          this.props.history.push(null);
        } else {
          this.props.setRedirect(path_data);
          if (path_data.pathname) {
            this.props.history.push(path_data.pathname);
          }
        }
      }
      this.bannerViewClick({
        uuid: item.uuid,
        is_clicked: true,
      });
    }
  };

  afterChangeBanner = (index) => {
    let { bannerData } = this.state;
    if (index > -1) {
      if (bannerData[index] && bannerData[index].uuid) {
        this.bannerViewClick({
          uuid: bannerData[index].uuid,
          is_viewed: true,
        });
      }
    }
  };

  bannerViewClick = async (reqData) => {
    await DashboardService.bannerViewClick(reqData)
      .then((res) => { })
      .catch((e) => {
        console.log("err", e);
      });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (!isNaN(controlValue) && controlName === "warrantyPhoneNumber") {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    } else if (controlName !== "warrantyPhoneNumber") {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    this.setState({ controls });
    // this.handleValidation();
  };

  prepareStackedChart = (categories, series) => {
    const stackedChart = {
      options: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: "",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },

        // title: {
        //   text: 'Yearly Offtake'
        // },
        xaxis: {
          categories,
          labels: {
            formatter(val) {
              return val;
            },
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          tickAmount: 3,
          crosshairs: {
            show: false,
            position: "back",
            stroke: {
              color: "#b6b6b6",
              width: 1,
              dashArray: 0,
            },
          },
          axisBorder: {
            show: false,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
        },
        tooltip: {
          y: {
            formatter(val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "right",
          horizontalAlign: "left",
          offsetX: 0,
          offsetY: 0,
          formatter(val) {
            if (val === "Pcr") {
              return `<img src = "${dashBoardImages.PCR_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "Truck") {
              return `<img src = "${dashBoardImages.TRUCK_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "Farm") {
              return `<img src = "${dashBoardImages.FARM_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "Wheeler") {
              return `<img src = "${dashBoardImages.WHEELER_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "Lcv") {
              return `<img src = "${dashBoardImages.LCV_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "Scv") {
              return `<img src = "${dashBoardImages.SCV_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            // return val
          },
          markers: {
            width: 38,
            height: 18,
          },
        },
        grid: {
          show: true,
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: [
          "#c970ff",
          "#9ad6d0",
          "#30b1ff",
          "#9ad851",
          "#9e95e2",
          "#fac2c0",
        ],
      },
      // series: [
      //   {
      //     name: 'Pcr',
      //     data: [44, 55, 41, 37, 22, 43, 21, 0, 0, 0, 0, 0]
      //   },
      //   {
      //     name: 'Truck',
      //     data: [44, 55, 41, 37, 22, 43, 21, 0, 0, 0, 0, 0]
      //   },
      //   {
      //     name: 'Wheeler',
      //     data: [44, 55, 41, 37, 22, 43, 21, 0, 0, 0, 0, 0]
      //   },
      //   {
      //     name: 'Farm',
      //     data: [53, 32, 33, 52, 13, 43, 32, 0, 0, 0, 0, 0]
      //   }
      // ],
      series,
    };

    return stackedChart;
  };

  sendOTP = async (is_from_add_customer = false) => {
    const { controls } = this.state;
    let { isSentOTP } = this.state;
    try {
      const { warrantyPhoneNumber } = controls;
      if (warrantyPhoneNumber.value) {
        isSentOTP = false;
        this.setState({
          isLoader: true,
        });
        const { data } = await WarrantyRegistrationService.sendOTP({
          phone_number: warrantyPhoneNumber.value,
        });
        if (data && data.success) {
          isSentOTP = true;
          if (is_from_add_customer) {
            ModalService.openAlert(
              "Warranty Registration",
              "OTP Send Successfuly",
              "success"
            );
          }
        }
        this.setState({ isSentOTP, isLoader: false });
      }
    } catch (error) {
      let err =
        error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error[0]
          ? error.response.data.error[0]
          : null;
      const message = err && err.message ? err.message : "Something went wrong";
      ModalService.openAlert("", message, "error");
      this.setState({
        isLoader: false,
      });
    }
  };

  cancelOTP = () => {
    this.setState({ isSentOTP: false });
  };

  verifyOTP = async () => {
    const { controls } = this.state;
    let { redirectToWarrantyRegistration, token } = this.state;
    const { warrantyPhoneNumber, warrantyOTP } = controls;
    try {
      if (warrantyOTP.value) {
        this.setState({
          isLoader: true,
        });
        const { data } = await WarrantyRegistrationService.verifyOTP({
          phone_number: warrantyPhoneNumber.value,
          otp: warrantyOTP.value,
        });
        if (data && data.success) {
          redirectToWarrantyRegistration = true; // when this flag set to true, it will redirect to warranty-registration
          token = (data.data || {}).token;
        } else {
          swal("", messages.WARRANTY_REGISTRATION.INVALIDOTP, "warning");
        }

        this.setState({
          controls,
          redirectToWarrantyRegistration,
          token,
          isLoader: true,
        });
      }
    } catch (error) {
      this.setState({
        isLoader: false,
      });
      swal("", messages.WARRANTY_REGISTRATION.INVALIDOTP, "warning");
    }
  };

  prepareDataQuarterWise = (categories, series) => {
    // categories = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];
    const qCategories = creatCategoriesQuarterWise(categories);
    // const pcrSeries = series[0].data;
    // const truckSeries = series[1].data;
    // const farmSeries = series[2].data;
    // const wheelerSeries = series[3].data;
    // const lcvSeries = series[4].data;
    // const scvSeries = series[5].data;

    let pcrSeries = [];
    let truckSeries = [];
    let wheelerSeries = [];
    let farmSeries = [];
    let lcvSeries = [];
    let scvSeries = [];

    for (let i = 0; i < series.length; i++) {
      if (series[i].name === "Pcr") {
        pcrSeries = series[i].data;
      } else if (series[i].name === "Truck") {
        truckSeries = series[i].data;
      } else if (series[i].name === "Wheeler") {
        wheelerSeries = series[i].data;
      } else if (series[i].name === "Farm") {
        farmSeries = series[i].data;
      } else if (series[i].name === "Lcv") {
        lcvSeries = series[i].data;
      } else if (series[i].name === "Scv") {
        scvSeries = series[i].data;
      }
    }

    const qPcrSeries = createDataQuarterWise(pcrSeries);
    const qTruckSeries = createDataQuarterWise(truckSeries);
    const qFarmSeries = createDataQuarterWise(farmSeries);
    const qWheelerSeries = createDataQuarterWise(wheelerSeries);
    const qLcvSeries = createDataQuarterWise(lcvSeries);
    const qScvSeries = createDataQuarterWise(scvSeries);

    const q1 = prepareQObj("q1");
    const q2 = prepareQObj("q2");
    const q3 = prepareQObj("q3");
    const q4 = prepareQObj("q4");

    const q1Total = getQuarterTotal("q1");
    const q2Total = getQuarterTotal("q2");
    const q3Total = getQuarterTotal("q3");
    const q4Total = getQuarterTotal("q4");

    function creatCategoriesQuarterWise(series) {
      const q1 = series.slice(0, 3);
      const q2 = series.slice(3, 6);
      const q3 = series.slice(6, 9);
      const q4 = series.slice(9, 12);
      return { q1, q2, q3, q4 };
    }

    function createDataQuarterWise(series) {
      const q1 = series.slice(0, 3);
      const q2 = series.slice(3, 6);
      const q3 = series.slice(6, 9);
      const q4 = series.slice(9, 12);

      const q1Total = getQuarterCategoryWiseTotal(q1);
      const q2Total = getQuarterCategoryWiseTotal(q2);
      const q3Total = getQuarterCategoryWiseTotal(q3);
      const q4Total = getQuarterCategoryWiseTotal(q4);

      return { q1, q2, q3, q4, q1Total, q2Total, q3Total, q4Total };
    }

    function getQuarterCategoryWiseTotal(series) {
      series[0] = parseInt(series[0]);
      series[1] = parseInt(series[1]);
      series[2] = parseInt(series[2]);
      return series[0] + series[1] + series[2];
    }

    function getQuarterTotal(quarter) {
      let total = 0;
      total += qPcrSeries[`${quarter}Total`];
      total += qTruckSeries[`${quarter}Total`];
      total += qWheelerSeries[`${quarter}Total`];
      total += qFarmSeries[`${quarter}Total`];
      total += qLcvSeries[`${quarter}Total`];
      total += qScvSeries[`${quarter}Total`];
      return total;
    }

    function prepareQObj(quarter) {
      return {
        categories: qCategories[quarter],
        series: [
          {
            name: "Pcr",
            data: qPcrSeries[quarter],
          },
          {
            name: "Truck",
            data: qTruckSeries[quarter],
          },
          {
            name: "Wheeler",
            data: qWheelerSeries[quarter],
          },
          {
            name: "Farm",
            data: qFarmSeries[quarter],
          },
          {
            name: "Lcv",
            data: qLcvSeries[quarter],
          },
          {
            name: "Scv",
            data: qScvSeries[quarter],
          },
        ],
      };
    }

    const s = { q1, q2, q3, q4 };
    const c = qCategories;
    const total = {
      q1: q1Total,
      q2: q2Total,
      q3: q3Total,
      q4: q4Total,
    };

    const obj = {
      series: s,
      categories: c,
      total,
    };
    this.setState({ stackedChartMobile: obj });
    // return { q1, q2, q3, q4 }
  };

  getAllWarrantyCounts = async () => {
    await DashboardService.getSecondaryCountsV2("all")
      .then((res) => {
        let { warranty_data } = this.state;
        if (res.data.data && Object.keys(res.data.data).length > 0) {
          warranty_data = res.data.data.dashboardCount;
        } else {
          warranty_data = {
            customer_count: 0,
            pcr_wheeler_tyre: 0,
            total_points: 0,
            two_three_wheeler_tyre: 0,
            scv_tyre: 0,
            lcv_tyre: 0,
            sensor_count: 0,
            pcr_wheeler_tyre_with_sensor: 0,
            two_three_wheeler_tyre_with_sensor: 0,
            scv_tyre_with_sensor: 0,
            lcv_tyre_with_sensor: 0,
          };
        }
        this.setState({
          warranty_data,
        });
      })
      .catch((e) => { });
  };

  getSecondaryCount = (year) => {
    let {
      sparkLineChartSecondary,
      totalSeconadryPoints,
      noWarrantyCustomers,
    } = this.state;
    return DashboardService.getSecondaryCounts(
      year,
      StorageService.getUserDetail().sap_id
    )
      .then((data) => {
        data = data.data.data;
        sparkLineChartSecondary.categories = data.monthWiseCounts.months;
        sparkLineChartSecondary.data = data.monthWiseCounts.counts;
        totalSeconadryPoints = data.dashboardCount.total_points
          ? parseInt(data.dashboardCount.total_points)
          : 0;
        noWarrantyCustomers = data.dashboardCount.customer_count
          ? parseInt(data.dashboardCount.customer_count)
          : 0;
        // isClamRegistrationOpen = data.isClamRegistrationOpen;
        this.setState({
          sparkLineChartSecondary,
          totalSeconadryPoints,
          noWarrantyCustomers,
        });
        return Promise.resolve();
      })
      .catch((e) => {
        return Promise.resolve();
      });
  };

  getYtd = (year) => {
    DashboardService.getYtd(year)
      .then((data) => {
        const yearObj = data.data.data;
        // const duration = findMonthFromFirstQToPreviousQMonths();
        // yearObj.duration = duration;
        const showYTDCard = this.cardsToShowOrNot(yearObj);
        this.setState({ yearObj, showYTDCard });
      })
      .catch((e) => { });
  };

  getQtd = (year) => {
    DashboardService.getQtd(year)
      .then((data) => {
        const quarterObj = data.data.data;
        // const duration = findCurrentQuarterMonths();
        // quarterObj.duration = duration;
        const showQTDCard = this.cardsToShowOrNot(quarterObj);
        this.setState({ quarterObj, showQTDCard });
      })
      .catch((e) => { });
  };

  getMtd = (year) => {
    DashboardService.getMtd(year)
      .then((data) => {
        const monthObj = data.data.data;
        // const duration = getCurrentMonth();
        // monthObj.duration = duration;
        const showMTDCard = this.cardsToShowOrNot(monthObj);
        this.setState({ monthObj, showMTDCard });
      })
      .catch((e) => { });
  };

  getPrimaryGraph = (year) => {
    DashboardService.getPrimaryGraph(year)
      .then((data) => {
        const chartData = data.data.data;
        this.setState({ sparkLineChartPrimary: chartData });
      })
      .catch((e) => { });
  };

  getPrimaryPoints = (year) => {
    this.setState({ isLoader: true });
    DashboardService.getPrimaryPoints(null, year)
      .then((data) => {
        const res = data.data.data;
        const primaryPoints = res.primaryPoints;
        const provisionalPoints = res.provisionalPoints;
        this.setState({ isLoader: false });
        // const year = res.year;
        this.setState({
          totalPrimaryPoints: primaryPoints,
          totalPrivionalPoints: provisionalPoints,
        });
      })
      .catch((e) => {
        this.setState({ isLoader: false });
      });
  };

  getYearlyOfftake = (year) => {
    this.setState({ isLoader: true });
    DashboardService.getYearlyOfftake(year)
      .then((data) => {
        const obj = data.data.data;
        const categories = obj.months;
        const series = obj.series;
        const totalYearlyOfftake = obj.total;
        this.setState({ isLoader: false });
        const o = {
          categories,
          series,
        };
        const stackedChart = this.prepareStackedChart(o.categories, o.series);
        this.setState({ stackedChartWeb: o, stackedChart, totalYearlyOfftake });

        this.prepareDataQuarterWise(categories, series);
      })
      .catch((e) => {
        this.setState({ isLoader: false });
      });
  };

  getRedeemData = (year) => {
    DashboardService.getRedeemData(year)
      .then((data) => {
        const {
          advantageMessage = null,
          redeemEnable,
          redeemDays = 0,
        } = data.data.data;
        const redeemData = data.data.data;
        this.setState({
          advantageMessage,
          redeemEnable,
          redeemDays,
          redeemData,
        });
      })
      .catch((e) => { });
  };

  cardsToShowOrNot = (obj) => {
    let val = false;
    if (obj.pcr && obj.pcr.offtake > 0) {
      val = true;
      return val;
    }

    if (obj.truck && obj.truck.offtake > 0) {
      val = true;
      return val;
    }
    if (obj.farm && obj.farm.offtake > 0) {
      val = true;
      return val;
    }

    if (obj.wheeler && obj.wheeler.offtake > 0) {
      val = true;
      return val;
    }

    if (obj.scv && obj.scv.offtake > 0) {
      val = true;
      return val;
    }

    if (obj.lcv && obj.lcv.offtake > 0) {
      val = true;
      return val;
    }
    return val;
  };

  getPopupLink = (year) => {
    SpeacialEngagementService.getPopupLink(year)
      .then((data) => {
        if (data.data && data.data.data.link) {
          this.setState({ isSpecialEngagementModalOpen: true });
        }
      })
      .catch((e) => { });
  };

  closeSpecialEngagementModal = () => {
    this.setState({ popupData: null, isSpecialEngagementModalOpen: false });
  };

  redeemAction = (action) => {
    if (action === "pcr-scheme") {
      this.props.history.push("/trade-scheme");
    }
    if (action === "gift-catalogue") {
      this.props.history.push("/redemption-catalog");
    }
  };

  getDisplayLabel = (year) => {
    DashboardService.getDisplayLabel(year)
      .then((data) => {
        const labels = data.data && data.data.data ? data.data.data : null;
        let { displayLabelsWarrantyTrends,
          //  displayLabels
        } = this.state;
        // if (displayLabels === null) {
        displayLabelsWarrantyTrends = labels;
        // }
        this.setState({
          displayLabels: labels,
          displayLabelsWarrantyTrends,
          year,
        });
      })
      .catch((e) => { });
  };

  yearFilterApplied = (year, year_name) => {
    if (year_name) {
      this.setState({
        year_name,
        year,
      });
    }
    this.loadInitialData(year);
  };

  componentWillReceiveProps() { }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.redirectDetailsAddCustomer !==
      this.props.redirectDetailsAddCustomer
    ) {
      if (
        this.props.redirectDetailsAddCustomer &&
        this.props.redirectDetailsAddCustomer.redirectToAddCustomer
      ) {
        this.setState(
          {
            opensidebar: false,
          },
          () => {
            this.setState({
              opensidebar: true,
            });
          }
        );
      }
      this.props.setAddCustomerRedirect(null);
    }
  }
  closeAddCustomer = (obj) => {
    if (obj && Object.keys(obj).length > 0) {
      if (obj.is_warranty_redirect) {
        if (localStorage.getItem("add_customer")) {
          let data = JSON.parse(localStorage.getItem("add_customer"));
          let { controls } = this.state;
          if (data && data.phone_number) {
            controls.warrantyPhoneNumber.value = data.phone_number;
            this.setState(
              {
                controls,
              },
              () => {
                this.sendOTP(true);
              }
            );
          }
        }
      }
    }
    this.setState({
      opensidebar: false,
    });
  };

  render() {
    const isMobile = checkUserAgent();
    const {
      controls,
      redirectToWarrantyRegistration,
      token,
      // popupDetail,
      isSpecialEngagementModalOpen,
      bannerData,
    } = this.state;
    const { warrantyPhoneNumber } = controls;

    let banner_list_item = bannerData.map((item, i) => (
      <div className="div-banner">
        <Card className="card-box-shadow-10px he-100 border-radius-8px">
          <CardBody
            style={{
              backgroundColor: item.background_color,
              backgroundImage: item.is_gradient
                ? `linear-gradient(to right, ${item.background_color}, ${item.gradient_color})`
                : item.background_color,
              padding: "10px",
              minHeight: "100px",
            }}
            className={item.action ? "cursor-pointer d-flex" : "d-flex"}
            onClick={() => this.redirectFromBanner(item)}
          >
            {item.banner_type === "picture" ? (
              <img
                src={item.image_url}
                alt=""
                onClick={() => this.previewImage(item)}
                className="dealer-banner-img m-auto dashboard-banner-full-image cursor-pointer"
              />
            ) : (
              <>
                {item.image_url && (
                  <img
                    src={item.image_url}
                    alt=""
                    className="dealer-banner-img m-auto cursor-pointer"
                    onClick={() => this.previewImage(item)}
                    style={{
                      width: "71px",
                      height: "100%",
                      display: "inline-block",
                    }}
                  />
                )}
                <div className="d-flex-column mt-auto mb-auto ml-2 mr-2 justify-content-center">
                  {item.content_url ? (
                    <a
                      href={item.content_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex-column mt-auto mb-auto"
                    >
                      <span
                        className="dealer-banner-title"
                        style={{
                          fontWeight: item.is_title_bold ? "bold" : "normal",
                          color: item.title_color ? item.title_color : "#000",
                        }}
                      >
                        {item.title}
                      </span>

                      <span
                        className="dealer-banner-desc mt-2"
                        style={{
                          color: item.description_color
                            ? item.description_color
                            : "#000",
                          fontWeight: item.is_description_bold
                            ? "bold"
                            : "normal",
                        }}
                      >
                        {item.description}
                      </span>
                    </a>
                  ) : (
                    <>
                      <span
                        className="dealer-banner-title"
                        style={{
                          fontWeight: item.is_title_bold ? "bold" : "normal",
                          color: item.title_color ? item.title_color : "#000",
                        }}
                      >
                        {item.title}
                      </span>

                      <span
                        className="dealer-banner-desc mt-2"
                        style={{
                          color: item.description_color
                            ? item.description_color
                            : "#000",
                          fontWeight: item.is_description_bold
                            ? "bold"
                            : "normal",
                        }}
                      >
                        {item.description}
                      </span>
                    </>
                  )}
                  {/* </div> */}
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    ));
    return (
      <div id="dashboard">
        {this.state.isLoader && <CustomSpinner />}
        {isSpecialEngagementModalOpen && (
          <SpeacialEngagement
            show={isSpecialEngagementModalOpen}
            // popupDetail={popupDetail}
            closeModal={this.closeSpecialEngagementModal}
          ></SpeacialEngagement>
        )}
        {redirectToWarrantyRegistration && (
          <Redirect
            to={{
              pathname: "/warranty-registration",
              search: `phoneNumber=${warrantyPhoneNumber.value}&t=${token}`,
            }}
          />
        )}

        <>
          {this.state.opensidebar && (
            <Sidebar
              sidebar={
                <>
                  <AddCustomer
                    ref={this.childCustomiseDetail}
                    resetFilter={this.state.CampaignDetailData}
                    CampaignDetailData={this.state.CampaignDetailData}
                    onSelectTargetGroup={this.changeTargetGroup}
                    getFilterControls={this.state.filterControls}
                    closeAddCustomer={this.closeAddCustomer}
                  />
                </>
              }
              open={true}
              sidebarClassName="campaign-drawer"
              pullRight
              styles={{
                sidebar: {
                  background: "white",
                  height: this.state.height ? this.state.height : "90%",
                  top: "60px",
                  width: "47%",
                  borderRadius: "2px",
                },
              }}
            />
          )}
        </>

        {!isMobile && (
          <DashboardWeb
            {...this.state}
            handleInputChange={this.handleInputChange}
            handleValidation={this.handleValidation}
            sendOTP={this.sendOTP}
            totalSeconadryPoints={this.state.totalSeconadryPoints}
            cancelOTP={this.cancelOTP}
            verifyOTP={this.verifyOTP}
            yearFilterApplied={this.yearFilterApplied}
            prepareStackedChart={this.prepareStackedChart}
            redirectToWarrantyReplacement={this.redirectToWarrantyReplacement}
            redirectToSecondaryReport={this.redirectToSecondaryReport}
            redeemAction={this.redeemAction}
            displayBuyProductBtn={this.displayBuyProductBtn}
            banner_list_item={banner_list_item}
            bannerData={this.state.bannerData}
            is_from_v2_dashbaord={this.state.is_from_v2_dashbaord}
            is_display_new_warranty_reg_login={
              this.state.is_display_new_warranty_reg_login
            }
            afterChangeBanner={this.afterChangeBanner}
            warranty_data={this.state.warranty_data}
            year={this.state.year}
          />
        )}
        {isMobile && (
          <DashboardMobile
            {...this.state}
            handleInputChange={this.handleInputChange}
            handleValidation={this.handleValidation}
            sendOTP={this.sendOTP}
            cancelOTP={this.cancelOTP}
            verifyOTP={this.verifyOTP}
            redirectToWarrantyReplacement={this.redirectToWarrantyReplacement}
            redirectToSecondaryReport={this.redirectToSecondaryReport}
            redeemAction={this.redeemAction}
            displayBuyProductBtn={this.displayBuyProductBtn}
            banner_list_item={banner_list_item}
            bannerData={this.state.bannerData}
            afterChangeBanner={this.afterChangeBanner}
          />
        )}
      </div>
    );
  }
}

//export default Dashboard;

const mapDispatchToProps = (dispatch) => ({
  setAddCustomerRedirect: (redirectDetailsAddCustomer) =>
    dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
  setRedirect: (redirectDetails) => dispatch(setRedirect(redirectDetails)),
});

const mapStateToProps = (state) => ({
  redirectDetailsAddCustomer: state.User.redirectDetailsAddCustomer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
