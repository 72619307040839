import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, Form } from 'reactstrap';
import CustomerManagementService from '../../services/CustomerManagementService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import './OverdueServices.css';
import { formatDate } from '../../utils/utils';
import Ionicons from 'react-ionicons';

class OverdueServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      overduelist: [],
      oldDetail: {}
    }
    this.toggle = this.toggle.bind(this);
  }
  getOverDueServices = (reqData) => {
    this.setState({ isLoading: true });
    CustomerManagementService.getOverDueServices(reqData)
      .then(res => {
        this.setState(prevState => {
          return {
            ...prevState,
            overduelist: res.data.data,
            isLoading: false
          };
        });
      })
      .catch(e => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  }
  toggle(data) {
    let { oldDetail } = this.state;
    if (data && Object.keys(data).length > 0) {
      if (data.customer_id) {
        let reqData = {
          "customer_id": data.customer_id
        }
        if (data.filter && data.filter.due_date_from) {
          reqData.due_date_from = data.filter.due_date_from;
        }
        if (data.filter && data.filter.due_date_to) {
          reqData.due_date_to = data.filter.due_date_to;
        }
        this.getOverDueServices(reqData);
      }
      oldDetail = data;
    } else {
      oldDetail = {};
      // this.resetControls();
    }
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      oldDetail
    }));
  }

  render() {
    let { overduelist } = this.state;
    return (
      <>
        <Modal
          centered={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-md modal-lg custom-confirm-modal modal-sm
          over-due-services-modal"
          style={{ paddingLeft: '10px' }}
          backdrop={this.backdrop ? 'static' : 'nostatic'}>
          {this.state.isLoading && <CustomSpinner />}
          <div className="d-flex-column">
            <div className="confirm-modal-title-wrapp justify-content-between d-flex" style={{ paddingBottom: '35px' }}>
              <span className="confirm-modal-title text-capitalize d-flex-column" style={{
                fontSize: '16px',
                width: 'auto'
              }}>
                OVERDUE SERVICES
                <span className="overdue-service-note mt-1 mb-1 text-transform-normal">
                  The list of services/tyre purchase due and for which customer needed to visit your store in the past however they did not visit.
                </span>
              </span>
              <span className="cursor-pointer" onClick={() => this.toggle({})} >
                <Ionicons icon="md-close" color="#fdbb1f"></Ionicons>
              </span>
            </div>
          </div>

          <ModalBody
            className={'d-flex-column pt-0'}
            style={{ paddingLeft: '10px' }}>
            <Form>
              {overduelist.map(o =>
                <Row className="over-due-services-list ml-0 mr-0 mt-2">
                  <Col xl="3" className="overdue-servic-vehicle-type">
                    <div className="overdue-service-lbl">Vehicle Type</div>
                    <div className="overdue-service-lbl font-weight-bold mt-1">{o.vehicle_type_name}</div>
                  </Col>
                  <Col xl="3" className="overdue-servic-vehicle-detail">
                    <div className="overdue-service-lbl">Vehicle Detail</div>
                    <div className="overdue-service-lbl font-weight-bold">
                      {o.brand_name || o.model_name ?
                        <>
                          {o.brand_name} {o.model_name}
                        </>
                        : '--'}
                    </div>
                    <div className="overdue-service-lbl font-weight-bold mt-1" style={{ color: "#838C95" }}>{o.registration_number}</div>
                  </Col>
                  <Col xl="3" className="overdue-servic-vehicle-purpose">
                    <div className="overdue-service-lbl">Purpose</div>
                    <div className="overdue-service-lbl font-weight-bold mt-1">{o.purpose_name}</div>
                  </Col>
                  <Col xl="3" className="overdue-servic-vehicle-service">
                    <div className="overdue-service-lbl">Service Name</div>
                    <div className="overdue-service-lbl font-weight-bold mt-1">{o.service_name}</div>
                  </Col>
                  <Col xl="3" className="overdue-servic-vehicle-overdue-date">
                    <div className="overdue-service-lbl">Overdue Date</div>
                    <div className="overdue-service-lbl font-weight-bold mt-1">{o.due_date && formatDate(o.due_date)}</div>
                  </Col>
                </Row>)}
            </Form>
          </ModalBody>
        </Modal>

      </>
    );
  }
}

export default OverdueServices;