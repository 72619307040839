
import React from 'react';
import { Button, Modal, ModalBody, Row, Col, Form } from 'reactstrap';

import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import GiftCatalogService from '../../services/GiftCatalogService';
import ModalService from '../../services/ModalService';
import './UploadGiftItems.css';


class UploadGiftItems extends React.Component {

  state = {
    selectedFiles: {},
    isLoading: false
  }



  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    // this.handleValidation();
  }

  handleFileUpload = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
  }

  uploadGiftItems = (e) => {
    e.preventDefault();
    let body = {
      file: this.state.selectedFile
    }
    this.setState({ isLoading: true });
    GiftCatalogService.uploadGiftItems(body)
      .then(data => {
        this.setState({ isLoading: false });
        ModalService.openAlert('Upload Item', 'Items Uploaded Successfully', 'success');
        this.props.closeModal();
      })
      .catch(e => {
        this.setState({ isLoading: false });
        // this.props.closeModal();
        ModalService.openAlert('Upload File', 'File Upload Failed', 'error');
      })
  }

  closeModal = () => {
    this.props.closeModal(true);
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <Modal id="change-password" isOpen={this.props.show} toggle={this.props.closeModal} centered>
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            {isLoading && <CustomSpinner></CustomSpinner>}
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col className="modal-title">
                  Upload Items
                </Col>
              </Row>

              <Row className="margin-top-10">
                <input type="file"
                  name="file"
                  onChange={this.handleFileUpload}
                ></input>
              </Row>

              <div style={{ textAlign: 'right' }}>
                <Button className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" onClick={this.props.closeModal} color="dark">Cancel</Button>
                <Button type="submit" className="mb-1 mr-1 btn-pill Rectangle-button send-button" onClick={this.uploadGiftItems} color="warning">Save</Button>
              </div>
            </Form>
          </ModalBody>

        </Modal>
      </div>
    );
  }
}

export default UploadGiftItems; 