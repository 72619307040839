import { request } from './Request';
import { routes } from '../constants/constant.route';

const tradeSchemeRoutes = routes.TRADE_SCHEME;
const {
  DOWNLOAD_TRADE_SCHEME_EXCEL,
  GET_DEALER_SCHEME,
  GET_SCHEME_DEALER_LIST,
  GET_SCHEME_PERCENTAGE,
  GET_SCHEMES
} = tradeSchemeRoutes;

const API_URL = process.env.REACT_APP_API_URL;

export default class TradeSchemeService {

  static getDealerScheme(quarter, year,uuid) {
    let qString = `?`;
    if (quarter) {
      qString += `quarter=${quarter}&`
    }
    if (year) {
      qString += `year=${year}&`
    }
    if (uuid) {
      qString += `u=${uuid}`
    }

    return request('GET', `${API_URL}${GET_DEALER_SCHEME}${qString}`, null, null, null);
  }

  static getSchemePercentage(userId, reviewAchivement) {
    let qString = `?`;
    if (userId) {
      qString += `u=${userId}&`
    }

    if (reviewAchivement) {
      qString += `by=${reviewAchivement}`
    }

    return request('GET', `${API_URL}${GET_SCHEME_PERCENTAGE}${qString}`, null, null, null);
  }

  static getSchemeDealerList(page, limit, search, min, userId, reviewAchievement) {
    let qString = `?`;
    if (userId) {
      qString += `u=${userId}&`
    }
    if (page) {
      qString += `page=${page}&`
    }
    if (limit) {
      qString += `limit=${limit}&`
    }
    if (search) {
      qString += `search=${search}&`
    }
    if (min) {
      qString += `min=${min}&`
    }
    if (reviewAchievement) {
      qString += `by=${reviewAchievement}`
    }
    return request('GET', `${API_URL}${GET_SCHEME_DEALER_LIST}${qString}`, null, null, null);
  }

  static downloadTradeSchemeExcel(userId) {
    let qString = ``;
    if (userId) {
      qString = `?u=${userId}`
    }

    return request('GET', `${API_URL}${DOWNLOAD_TRADE_SCHEME_EXCEL}${qString}`, null, null, null);
  }

  static getSchemes(category) {
     let qString = `?`;
    if (category) {
      qString += `category=${category}`
    }

    return request('GET', `${API_URL}${GET_SCHEMES}${qString}`, null, null, null);
  }
}