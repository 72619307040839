import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import Ionicon from 'react-ionicons';
import Lightbox from 'react-image-lightbox';

import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
// import GoogleAnalticsService from '../../services/GoogleAnalticsService';
import StorageService from '../../services/Storage';
import { formatNumber } from '../../utils/utils';

import GiftCatalogService from '../../services/GiftCatalogService';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import './GiftItemDetail.css';
import { Permissions } from '../../constants/constant.permissions';

class GiftItemDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: false,
      qty: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      itemDetail: null,
      uuid: null,
      activeImage: null,
      activeLargeImage: null,
      isPreviewImageLightBoxOpen: false,
      imagesToPreview: [],
      isLoading: false,
      selectedItem: this.props.selectedItem
    };
  }

  componentDidMount() {
    const userDetail = StorageService.getUserDetail();
    const permission = userDetail.permissions.includes(Permissions.PRIMARY_POINTS_REDEMPTION);
    /* const query = new URLSearchParams(this.props.location.search);
    GoogleAnalticsService.event('Redemption Catalogue', 'Product Detail View');
    const uuid = query.get("u"); */
    const uuid = this.state.selectedItem.uuid;
    this.setState({ permission, uuid });
    this.getGiftItemDetail(uuid);
  }

  getGiftItemDetail = uuid => {
    this.setState({ isLoading: true });
    GiftCatalogService.getGiftItemDetail(uuid)
      .then(data => {
        this.setState({ isLoading: false });
        data.data.data.redeem_points = data.data.data.name === 'Full Credit Note' ? this.props.balancePoints : data.data.data.redeem_points;
        const itemDetail = data.data && data.data.data ? data.data.data : null;
        const activeImage =
          itemDetail &&
            itemDetail.images &&
            itemDetail.images[0] &&
            itemDetail.images[0].main
            ? itemDetail.images[0].main
            : null;
        const activeLargeImage =
          itemDetail &&
            itemDetail.images &&
            itemDetail.images[0] &&
            itemDetail.images[0].large
            ? itemDetail.images[0].large
            : null;
        this.setState({
          itemDetail,
          activeImage,
          activeLargeImage
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  };

  changeActiveImage = obj => {
    this.setState({ activeImage: obj.main, activeLargeImage: obj.large });
  };

  openCatalog = () => {
    // const query = new URLSearchParams(this.props.location.search);

    // this.props.history.push(`/redemption-catalog${this.props.location.search}`);

    this.props.backtoCatalogue();
  };

  openWallet = () => {
    this.props.backToWallet(true);
    this.props.backtoCatalogue();
  };

  openPreviewImageLightBox = image => {
    const imagesToPreview = [image];
    this.setState({ isPreviewImageLightBoxOpen: true, imagesToPreview });
  };

  closePreviewImageLightBox = () => {
    const imagesToPreview = [];
    this.setState({ isPreviewImageLightBoxOpen: false, imagesToPreview });
  };

  checkedExistingCartAdd(item_id) {
    const { placeOrderCartItems } = this.props;
    const indexCartItem = placeOrderCartItems && placeOrderCartItems.findIndex(x => x.uuid === item_id);
    return !(indexCartItem <= -1);
  }

  render() {
    const {
      itemDetail,
      activeImage,
      imagesToPreview,
      isPreviewImageLightBoxOpen,
      isLoading,
      selectedItem
    } = this.state;
    const detailList =
      itemDetail &&
      itemDetail.details.map(d => (
        <div>
          {d.title && (
            <span className="detail-list detail-list-title">{d.title}:</span>
          )}
          {d.description && (
            <span className="detail-list">{d.description}</span>
          )}
          {d.bullet && (
            <ul>
              {d.bullet &&
                d.bullet.map(b => (
                  <li>
                    {b.title && (
                      <span className="detail-list detail-list-title">
                        {b.title}:
                      </span>
                    )}
                    {b.description && (
                      // <span className="detail-list" style={{whiteSpace: 'pre'}}>{b.description}</span>
                      <span className="detail-list">{b.description}</span>
                    )}
                  </li>
                ))}
            </ul>
          )}
        </div>
      ));

    const featureList =
      itemDetail &&
      itemDetail.features.map(d => (
        <div>
          {d.title && (
            <span className="detail-list detail-list-title">{d.title}:</span>
          )}
          {d.description && (
            <span className="detail-list">{d.description}</span>
          )}
          {d.bullet && (
            <ul>
              {d.bullet &&
                d.bullet.map(b => (
                  <li>
                    {b.title && (
                      <span className="detail-list detail-list-title">
                        {b.title}:
                      </span>
                    )}
                    {b.description && (
                      <span className="detail-list">{b.description}</span>
                    )}
                  </li>
                ))}
            </ul>
          )}
        </div>
      ));

    // let featureList = itemDetail && itemDetail.features.map(f => <li>
    //   <span className="detail-list detail-list-title">{f.title}:</span>
    //   <span className="detail-list">{f.description}</span>
    // </li>);

    const imageList =
      itemDetail &&
      itemDetail.images &&
      itemDetail.images.map(i => (
        <div>
          <img
            src={i.thumb}
            alt=""
            className={`thumb-image cursor-pointer margin-top-5 ${activeImage === i.main ? 'thumb-image-selected' : ''
              }`}
            onClick={this.changeActiveImage.bind(this, i)}
          />
        </div>
      ));
    const photoIndex = 0;
    return (
      <div id="gift-item-detail">
        {isLoading && <CustomSpinner />}
        {isPreviewImageLightBoxOpen && (
          <Lightbox
            mainSrc={imagesToPreview[photoIndex]}
            nextSrc={0}
            prevSrc={0}
            onCloseRequest={this.closePreviewImageLightBox}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + imagesToPreview.length - 1) %
                  imagesToPreview.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % imagesToPreview.length
              })
            }
          />
        )}
        <Row className="margin-bottom-20" style={{ marginLeft: '-15px' }}>
          <Col className="title title-case">
            <span
              className="prev-page-link cursor-pointer title-case"
              onClick={this.openCatalog}
            >
              Catalogue
            </span>
            <span>
              <Ionicon
                icon="ios-arrow-forward"
                fontSize="34px"
                color="#fdbb1f"
              />
            </span>
            <span className="title-case title-css">
              Product Details ({itemDetail && itemDetail.category})
            </span>
          </Col>
          {this.state.permission &&
            (
              <>
                <Col xl="5" md="12" xs="12">
                  <Card className="merchandise-wallet-count-card-redemption">
                    <div className="merchandise-wallet-count-lbl-wrapp d-flex-column">
                      <span className="merchandise-wallet-coins-lbl">Points Eligible for Redemption&nbsp;
                        <img src="images/help.png" alt="" className="cursor-pointer" width='15' onClick={() => this.props.openPointsModel()} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                      <span className="merchandise-wallet-coins-count">
                        {formatNumber(this.props.balancePoints)}
                      </span>
                    </div>
                  </Card>
                </Col>

                <Col xl="3" md="12" xs="12" className="cursor-pointer">
                  <Card className="merchandise-wallet-count-card-redemption" style={{ background: '#fff' }} onClick={() => { this.openWallet(); }}>
                    <div className="merchandise-wallet-count-lbl-wrapp d-flex-column cart-count-wrapp">
                      <span style={this.props.coinsOnCart > 0 ? {} : { opacity: '0.4' }} className="cart-count-count">
                        <img src="images/cart.svg" alt="" />&nbsp;Cart&nbsp;
                        ({this.props.placeOrderCartItems && this.props.placeOrderCartItems.length})
                      </span>
                      <div>
                        <span style={this.props.coinsOnCart > 0 ? {} : { opacity: '0.4' }} className="cart-count-lable">
                          {this.props.coinsOnCart} points on cart
                        </span>
                        {this.props.coinsOnCart > 0 &&
                          <div style={{ fontSize: "11px", color: "red" }}>Click here to place your order</div>}
                      </div>
                    </div>
                  </Card>
                </Col>
              </>
            )}
        </Row>

        {itemDetail && (
          <Card>
            <CardBody>
              <Row>
                <Col sm="6">
                  <Row>
                    <Col sm="2">{imageList}</Col>
                    <Col
                      sm="10"
                      style={{ paddingLeft: '36px' }}
                      className="text-align-center main-image-container"
                    >
                      {/* <div style={{ maxHeight: '411px', maxWidth: '441px' }}>
                        <ReactImageMagnify {...{
                          smallImage: {
                            alt: '',
                            isFluidWidth: true,
                            src: activeImage,
                            height: '100%'
                          },
                          largeImage: {
                            src: activeImage,
                            width: 900,
                            height: 900
                          },
                          enlargedImageContainerStyle: {
                            zIndex: 1000
                          },
                          enlargedImageContainerClassName: 'large-image-container',
                          imageClassName: 'main-image'
                        }}

                        />
                      </div> */}
                      <img
                        src={activeImage}
                        alt=""
                        className="main-image cursor-pointer"
                        onClick={this.openPreviewImageLightBox.bind(
                          this,
                          activeImage
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="6" style={{ paddingBottom: '30px' }}>
                  <div className="product-title">{itemDetail.title}</div>
                  <div className="description">{itemDetail.description}</div>
                  {/* <div className="description">Product Code:{itemDetail.code}</div> */}
                  <div className="redeem-point">
                    {itemDetail.redeem_points} Points
                  </div>
                  <div className="margin-top-20">
                    {/* <ul> */}
                    {detailList}
                    {/* </ul> */}
                  </div>
                  {itemDetail &&
                    itemDetail.features &&
                    itemDetail.features.length > 0 && (
                      <div className="detail-list detail-list-title">
                        Product Description
                      </div>
                    )}
                  {/* <ul> */}
                  {featureList}
                  {/* </ul> */}
                  <div className="d-flex-row">
                    {itemDetail.code && (
                      <div className="gift-code">
                        GIFT CODE: {itemDetail.code}
                      </div>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.permission && <>
                      <div className="margin-top-5">
                        <span className="">Quantity</span>
                      </div>&nbsp;&nbsp;&nbsp;
                      <div className="d-flex-row" style={{ flexWrap: 'nowrap' }}>
                        <select
                          className="form-control select drop-down-control"
                          name="total_quantity"
                          // disabled={selectedItem.isAddToCart}
                          value={selectedItem.total_quantity}
                          style={this.checkedExistingCartAdd(selectedItem.uuid) ? { backgroundColor: '#ededed', color: '#495057', width: '65px' } : { color: '#495057', width: '65px' }}
                          disabled={this.checkedExistingCartAdd(selectedItem.uuid)}
                          onChange={(e) => {
                            this.props.handleInputChangeqty(selectedItem, e);
                          }}
                        >
                          {this.state.qty.map((item) => (
                            <option key={item}>
                              {item}
                            </option>
                          ))}
                        </select> &nbsp;&nbsp;&nbsp;
                      </div>
                      <div className="d-flex-row" style={{ flexWrap: 'nowrap' }}>
                        <Button
                          className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold add-to-cart-btn"
                          color="warning"
                          type="submit"
                          style={{ marginLeft: '10px' }}
                          onClick={() => this.props.checkTotalCoins(selectedItem, (!selectedItem.isAddToCart && !this.checkedExistingCartAdd(selectedItem.uuid)))}
                        >
                          {(selectedItem.isAddToCart === undefined || selectedItem.isAddToCart === false) && this.checkedExistingCartAdd(selectedItem.uuid) === false ? 'Add To Cart' : 'Remove'}
                        </Button>
                      </div>
                    </>}
                  </div>
                  {itemDetail.note && (
                    <Row style={{ position: 'absolute', bottom: '0px' }}>
                      <Col className="note-container">{itemDetail.note}</Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default GiftItemDetail;
