import React, { Component } from "react";
import { Button, FormGroup, Label, Row, Col, Input } from "reactstrap";
import classnames from "classnames";
import Validation from "../../../services/Validation";
import WarrantyRegistrationService from "../../../services/WarrantyRegistrationService";

class CustomerDetailsEdit extends Component {
  isMobile = false;

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      controls: (props.customerDetails && props.customerDetails.controls) || {},
      isValidForm: props.customerDetails && props.customerDetails.isValidForm,
      isEditMode: props.isEditMode,
      from: props.from,
    };
  }
  /**This method is called from parent component to set the props detail to state  */
  setDataFromProps = () => {
    let { controls } = this.state;
    let { customerDetails, isValidForm, isEditMode, from } = this.props;
    controls = (customerDetails && customerDetails.controls) || {};
    isValidForm = customerDetails && customerDetails.isValidForm;
    this.setState({
      controls,
      isValidForm,
      isEditMode,
      from,
    });
  };

  componentDidMount() {
    const { isEditMode } = this.state;
    if (isEditMode) {
      this.setState({ isValidForm: true });
    }
    /**Check the user Agent is it android, ios, webOS , etc  */
    this.isMobile = WarrantyRegistrationService.checkUserAgent();
    this.setState((prevState) => {
      return { ...prevState };
    });
  }

  /**Handle the input change for the textBox  */
  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls, isValidForm } = this.state;
    if (
      controlValue &&
      (controlName === "firstname" || controlName === "lastname")
    ) {
      controls[controlName].value = controlValue.replace(/\b\w/g, (l) =>
        l.toUpperCase()
      );
      controls[controlName].touched = true;
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }

    this.setState({ controls });
    this.props.updateState({
      visibleArea: "customerDetails",
      isSameRoute: true,
      customerDetails: { controls, isValidForm },
    });
    this.handleValidation();
  };

  /**Handles the validation and return isValidForm state */
  handleValidation = () => {
    const { controls } = this.state;
    let { isValidForm } = this.state;
    let {
      firstname,
      lastname,
      email,
      ownerPhoneNumber,
      phoneNumber,
    } = controls;

    if (ownerPhoneNumber.touched === true) {
      ownerPhoneNumber = Validation.validatePhoneNumber(ownerPhoneNumber);
      ownerPhoneNumber.valid = !ownerPhoneNumber.invalidPhone;
      if (ownerPhoneNumber.valid === false) {
        ownerPhoneNumber.showErrorMsg = true;
      } else {
        ownerPhoneNumber.showErrorMsg = false;
      }
    }

    if (firstname.touched === true) {
      firstname = Validation.notNullValidator(firstname);
    }
    firstname.valid = !!firstname.value;

    if (lastname.touched === true) {
      lastname = Validation.notNullValidator(lastname);
    }
    lastname.valid = !!lastname.value;

    if (email.touched === true) {
      email = Validation.emailValidator(email);
      // email = Validation.notNullValidator(email);
      email.valid = !email.invalidEmail;
      if (!email.value) {
        email.valid = true;
      }
    }

    if (
      ownerPhoneNumber.value &&
      phoneNumber.value &&
      ownerPhoneNumber.value === phoneNumber.value
    ) {
      ownerPhoneNumber.sameAsDriverNumber = true;
      ownerPhoneNumber.showErrorMsg = true;
      isValidForm = false;
    } else {
      ownerPhoneNumber.sameAsDriverNumber = false;
      ownerPhoneNumber.showErrorMsg = false;
      isValidForm = true;
    }
    if (
      firstname.valid &&
      lastname.valid &&
      email.valid &&
      ownerPhoneNumber.valid
    ) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({ controls, isValidForm });
  };

  onNextClick = () => {
    const { isValidForm, controls, from } = this.state;
    /**Condition check to redirect to appropriate page on "Next" Button click */
    if (isValidForm) {
      this.props.updateState({
        visibleArea:
          from === "warrantyReplacement" ? "vehicleMake" : "vehicleType",
        customerDetails: { controls, isValidForm },
      });
    }
  };

  render() {
    const { controls } = this.state;
    const { isValidForm } = this.state;
    const { firstname, lastname, ownerPhoneNumber } = controls;

    return (
      <div>
        <Row className="warranty-customer-details-label">
          <Col
            xl="10"
            xs="10"
            ms="10"
            className={classnames({
              "text-align-center mr-auto ml-auto": this.isMobile,
            })}
          >
            {/* CUSTOMER DETAILS */}
            VEHICLE OWNER'S DETAILS
          </Col>
        </Row>
        {
          <div>
            <hr />
            <Row>
              <Col xl="10" xs="12" ms="12">
                <FormGroup>
                  <Label for="firstname" className="input-label">
                    First Name
                  </Label>
                  <span className="required-star"> *</span>
                  <Input
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={firstname.value}
                    onChange={this.handleInputChange}
                    style={{ textTransform: "capitalize" }}
                  />
                </FormGroup>
              </Col>
              <Col xl="2" xs="0" ms="0" />
            </Row>
            <Row>
              <Col xl="10" xs="12" ms="12">
                <FormGroup>
                  <Label for="lastname" className="input-label">
                    Last Name
                  </Label>
                  <span className="required-star"> *</span>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname"
                    value={lastname.value}
                    onChange={this.handleInputChange}
                    style={{ textTransform: "capitalize" }}
                  />
                </FormGroup>
              </Col>
              <Col xl="2" xs="0" ms="0" />
            </Row>
            <Row>
              <Col xl="10" xs="12" ms="12">
                <FormGroup>
                  <Label for="ownerPhoneNumber" className="input-label">
                    {/* Mobile Number */}
                    Owner's Mobile if not verified through OTP
                  </Label>
                  <Input
                    type="text"
                    maxLength="10"
                    type="text"
                    name="ownerPhoneNumber"
                    id="ownerPhoneNumber"
                    value={ownerPhoneNumber.value}
                    onChange={this.handleInputChange}
                    style={{ textTransform: "capitalize" }}
                  />
                  {ownerPhoneNumber.showErrorMsg &&
                    !ownerPhoneNumber.sameAsDriverNumber && (
                      <div className="error">*Please valid mobile number.</div>
                    )}
                  {ownerPhoneNumber.showErrorMsg &&
                    ownerPhoneNumber.sameAsDriverNumber && (
                      <div className="error">
                        *You have entered same mobile number for Driver and
                        Vehicle Owner. Generally if driver is same as vehicle
                        owner, you can skip entering this number.
                      </div>
                    )}
                </FormGroup>
              </Col>
              {/* <Col xl="10" md="12" xs="12" className="note-warranty">
                <span>
                  <i>
                    Note : If the number verified through OTP is not the owner's mobile number, kindly add the mobile number here
                  </i>
                </span>
              </Col> */}
            </Row>
            {/* <Row>
            <Col xl="10" xs="12" ms="12">
              <FormGroup>
                <Label for="emailAddress" className="input-label">Email Address</Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={email.value}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col xl="2" xs="0" ms="0" />
          </Row> */}
            <Row>
              <Col>
                <Button
                  disabled={!isValidForm}
                  className={classnames(
                    { "w-100 mt-4": this.isMobile },
                    "mb-1",
                    "mr-1",
                    "btn-pill",
                    "next-btn",
                    "font-weight-bold"
                  )}
                  color="warning"
                  onClick={this.onNextClick}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        }
      </div>
    );
  }
}

export default CustomerDetailsEdit;
