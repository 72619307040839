import React from 'react';
import {
  Row, Col
} from 'reactstrap';
import './Footer.css';

const Footer = () => {
  return <div id="footer" >
    <Row>
      <Col className="footer">
        Powered by JK  Tyre & Industries Ltd. © 2019, All Rights Reserved.
        Design & Developed by LoMoSo Solutions Pvt Limited
      </Col>
    </Row>
  </div>;
};

export default Footer;