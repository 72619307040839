import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import "./AddNewCustomer.css";
import Ionicon from "react-ionicons";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextareaAutosize from "react-textarea-autosize";
import AddNewVehicle from "../AddNewVehicle/AddNewVehicle";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import Validation from "../../services/Validation";
import CustomerManagementService from "../../services/CustomerManagementService";
import swal from "sweetalert";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
const { ERROR_MESSAGE } = messages.COMMON;

class AddNewCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: null,
      controls: {
        first_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        last_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        phone_number: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        email_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        group_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        birth_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        anniversary_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        vehicle_type: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        remark: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        isAddNewVehicle: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      },
      checkCustomerService: false,
      newCustomer: false,
      customerVehicleData: [],
      groupData: [],
      switchHideShowPage: 0,
      isDeleteModalOpen: false
    };
    this.childAddNewVehicle = React.createRef();
  }

  componentDidMount() {
    this.getCustomerVehicle();
    this.getGroups();
  }

  setEditData = data => {
    let { controls } = this.state;
    controls.phone_number.value = data.phone_number;
    controls.first_name.value = data.first_name;
    controls.last_name.value = data.last_name;
    controls.email_id.value = data.email_id;
    controls.birth_date.value = data.birth_date
      ? new Date(data.birth_date)
      : null;
    controls.anniversary_date.value = data.anniversary_date
      ? new Date(data.anniversary_date)
      : null;
    controls.remark.value = data.remark;
    controls.group_id.value =
      data.member_of_group && data.member_of_group.length > 0
        ? data.member_of_group
        : [];
    this.setState({
      customer_id: data.customer_id,
      controls
    });
    this.getCustomerVehicle();
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { phone_number, email_id } = controls;
    if (phone_number.touched === true || isSubmit) {
      phone_number = Validation.notNullValidator(phone_number);
      phone_number = Validation.validatePhoneNumber(phone_number);
      phone_number.valid = !(
        phone_number.nullValue || phone_number.invalidPhone
      );
      if (phone_number.valid === false) {
        phone_number.showErrorMsg = true;
      } else {
        phone_number.showErrorMsg = false;
      }
    }
    email_id.valid = true;
    if (email_id.touched === true || isSubmit) {
      if (
        email_id.value === "" ||
        email_id.value === null ||
        email_id.value === undefined
      ) {
        email_id.valid = true;
        email_id.showErrorMsg = false;
      } else {
        email_id = Validation.emailValidator(email_id);
        email_id.valid = !email_id.invalidEmail;
        if (email_id.valid === false) {
          email_id.showErrorMsg = true;
        } else {
          email_id.showErrorMsg = false;
        }
      }
    }

    if (email_id.valid === true && phone_number.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    const re = /^[0-9\b]+$/;
    if (
      controlName === "phone_number" &&
      controlValue !== "" &&
      re.test(e.target.value)
    ) {
      this.setState(
        {
          checkCustomerService: true
        },
        () => {
          // let length = this.state.controls.phone_number;
          if (this.state.controls.phone_number.value.length === 10) {
            this.checkCustomer();
          }
        }
      );
    } else {
      this.setState({
        checkCustomerService: false
      });
    }
    if (controlName === "phone_number" || controlName === "email_id") {
      this.handleValidation();
    }
  };

  onChangeDate = (e, controlName) => {
    const { controls } = this.state;
    controls[controlName].value = e;
    controls[controlName].touched = true;
    this.setState({ controls });
    //this.handleValidation();
  };

  handleRemarks = (e, controlName) => {
    const { controls } = this.state;
    controls[controlName].value = e;
    controls[controlName].touched = true;
    this.setState({ controls });
    //this.handleValidation();
  };

  handleInputCheckboxChange = e => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName] = !controls[controlName];
    this.setState({ controls });
  };

  handleOnCancel = () => {
    this.props.onCloseSidebar(false);
  };

  switchOpenPage = (value, data = {}) => {
    //let { controls } = this.state;
    if (value === 0) {
      if (data && Object.keys(data).length > 0 && data.customer_id) {
        this.setState({
          switchHideShowPage: value,
          customer_id: data.customer_id
        });
        setTimeout(() => {
          this.getCustomerVehicle();
        }, 1);
      } else {
        this.setState({
          switchHideShowPage: value
        });
      }
    } else if (value === 1) {
      if (data && Object.keys(data).length > 0) {
        this.setState({
          switchHideShowPage: value
        });
        setTimeout(() => {
          this.childAddNewVehicle.current.setEditVehicleData(data);
        }, 500);
      } else {
        if (this.handleValidation(true)) {
          this.setState({
            switchHideShowPage: value
          });
        }
      }
    }
  };

  isDataLoading = flag => {

    // this.setState({
    //   isLoading: flag
    // });
  };

  addCustomer = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    let { controls } = this.state;
    let reqData = {
      phone_number: controls.phone_number.value,
      first_name: controls.first_name.value ? controls.first_name.value : null,
      last_name: controls.last_name.value ? controls.last_name.value : null,
      email_id: controls.email_id.value ? controls.email_id.value : null,
      birth_date: controls.birth_date.value ? controls.birth_date.value : null,
      anniversary_date: controls.anniversary_date.value
        ? controls.anniversary_date.value
        : null,
      remark: controls.remark.value ? controls.remark.value : null,
      country_code: "+91"
    };
    if (controls.group_id.value && controls.group_id.value.length > 0) {
      reqData.group_id = [];
      controls.group_id.value.forEach(element => {
        reqData.group_id.push(element.group_id);
      });
    }
    if (this.state.customer_id) {
      reqData.customer_id = this.state.customer_id;
    }
    this.isDataLoading(true);
    CustomerManagementService.addCustomer(reqData)
      .then(res => {
        if (!this.state.customer_id) {
          this.isDataLoading(false);
          this.setState({
            newCustomer: true
          });
          swal({
            title: res.data.message,
            icon: "success",
            type: "success",
            text: "Do you want to add vehicles for this customer?",
            buttons: ["No", "Yes"]
          }).then(isConfirm => {
            if (isConfirm) {
              this.switchOpenPage(1);
              this.setState({
                customer_id: res.data.data.customer_id
              });
            } else {
              this.onCloseSidebar(true);
            }
          });
        } else {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => {
            this.onCloseSidebar(true);
          });
        }
      })
      .catch(e => {
        this.isDataLoading(false);
        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.length > 0 &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message
            ? err.message
            : messages.WARRANTY_REGISTRATION.ERRORMESSAGE;
        ModalService.openAlert("", message, "error");
        //this.setState({ isLoading: false });
      });
  };

  getCustomerVehicle = () => {
    if (!this.state.customer_id) {
      return;
    }
    this.setState({
      isLoading: true
    });
    CustomerManagementService.getCustomerVehicle(this.state.customer_id)
      .then(res => {
        let { customerVehicleData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          customerVehicleData = res.data.data;
        } else {
          customerVehicleData = [];
        }
        this.setState({
          customerVehicleData,
          isLoading: false
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  removeVehicle = () => {
    CustomerManagementService.getCustomerVehicle(this.state.customer_id)
      .then(res => {
        let { customerVehicleData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          customerVehicleData = res.data.data;
        } else {
          customerVehicleData = [];
        }
        this.setState({
          customerVehicleData
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  getGroups = () => {
    CustomerManagementService.getGroups({ page: 1, pageSize: 10000 })
      .then(res => {
        let { groupData } = this.state;
        if (
          res.data.data &&
          res.data.data.groups &&
          res.data.data.groups.length > 0
        ) {
          groupData = res.data.data.groups;
        } else {
          groupData = [];
        }
        this.setState({
          groupData
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  handleCalendarOpen = e => {
    if (e) {
      e.preventDefault();
    } else {
      return;
    }
    if (e.target.name === "anniversary_date") {
      this.calendar2.state.open = true;
    } else if (e.target.name === "birth_date") {
      this.calendar1.state.open = true;
    }
  };

  onCloseSidebar = flag => {
    this.props.onCloseSidebar(flag);
  };

  deleteCustomerConfirm = (vehicle_id = null) => {
    swal({
      title: "Delete Vehicle",
      text: "Are you sure, you want to delete vehicle ?",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.deleteVehicle(vehicle_id);
      }
    });
  };

  deleteVehicle = (vehicle_id = null) => {
    let reqData = {
      customer_id: this.state.customer_id
    };
    if (vehicle_id) {
      reqData.vehicle_id = vehicle_id;
    }
    this.setState({ isLoading: true });
    CustomerManagementService.deleteCustomerVehicle(reqData)
      .then(res => {
        this.setState({ isLoading: false });
        if (res.data.message) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => { });
          this.getCustomerVehicle();
        }
      })
      .catch(e => {
        let err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  checkCustomer = () => {
    if (!this.state.checkCustomerService) {
      return;
    }
    var reqData = {
      phone_number: this.state.controls.phone_number.value,
      country_code: "91"
    };
    this.setState({ isLoading: true });
    CustomerManagementService.checkCustomer(reqData)
      .then(res => {
        this.setState({ isLoading: false, checkCustomerService: false });
      })
      .catch(e => {
        let err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false, checkCustomerService: false });
      });
  };

  render() {
    const {
      controls,
      switchHideShowPage,
      customerVehicleData,
      groupData
    } = this.state;
    const customStyles = {
      control: base => ({
        ...base,
        maxHeight: 70,
        overflow: "auto"
      })
    };
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        {switchHideShowPage === 0 && (
          <>
            <div className="drawer-header">
              <span className="drawer-title">
                {this.state.customer_id && !this.state.newCustomer
                  ? "EDIT CUSTOMER & VEHICLE DETAILS"
                  : "Add New Customer"}
              </span>
              <Ionicon
                className="cursor-pointer"
                icon="md-close"
                fontSize="24px"
                color="#fdbb1f"
                onClick={() => this.handleOnCancel()}
              />
            </div>
            <hr style={{ border: "solid 1px #ced4da" }} />
            <div className="custom-drawer-content-wrapperr Fsix-height">
              <Row style={{ marginLeft: "0", marginRight: "0" }}>
                <Col xl="4" md="12" xs="12">
                  <FormGroup>
                    <Label for="common">
                      Mobile No<span className="required-field">*</span>
                    </Label>
                    <Input
                      type="number"
                      name="phone_number"
                      id="phone_number"
                      autoComplete="nofill"
                      maxLength="10"
                      disabled={this.state.customer_id ? true : false}
                      value={controls.phone_number.value}
                      onChange={this.handleInputChange}
                      placeholder="Type here"
                    />
                    {controls.phone_number.showErrorMsg && (
                      <div className="error">
                        * Please enter valid mobile number.
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="4" md="12" xs="12">
                  <FormGroup>
                    <Label for="common">First Name</Label>
                    <Input
                      type="text"
                      autoComplete="nofill"
                      name="first_name"
                      id="first_name"
                      value={controls.first_name.value}
                      onChange={this.handleInputChange}
                      placeholder="Type here"
                    />
                  </FormGroup>
                </Col>
                <Col xl="4" md="12" xs="12">
                  <FormGroup>
                    <Label for="common">Last Name</Label>

                    <Input
                      type="text"
                      autoComplete="nofill"
                      name="last_name"
                      id="last_name"
                      value={controls.last_name.value}
                      onChange={this.handleInputChange}
                      placeholder="Type here"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginLeft: "0", marginRight: "0" }}>
                <Col xl="6" md="12" xs="12">
                  <FormGroup>
                    <Label for="common">Email Address</Label>
                    <Input
                      type="text"
                      autoComplete="nofill"
                      name="email_id"
                      id="email_id"
                      value={controls.email_id.value}
                      onChange={this.handleInputChange}
                      placeholder="Type here"
                      className="email-address"
                    />
                    {controls.email_id.showErrorMsg && (
                      <div className="error">* Please enter valid email.</div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="6" md="12" xs="6">
                  <FormGroup>
                    <Label for="common">Group</Label>
                    <Select
                      name="select-group"
                      id="group_id"
                      pageSize="1"
                      getOptionLabel={option => option.group_name}
                      getOptionValue={option => option.group_id}
                      options={groupData}
                      closeMenuOnSelect={false}
                      value={controls.group_id.value}
                      onChange={e =>
                        this.handleInputChange({
                          target: { name: "group_id", value: e }
                        })
                      }
                      placeholder="Search..."
                      styles={customStyles}
                      isMulti
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginLeft: "0", marginRight: "0" }}>
                <Col xl="4" md="12" xs="12" style={{ marginTop: "-15px" }}>
                  <FormGroup>
                    <Label for="common">Birthdate</Label>

                    <Label
                      onClick={e =>
                        this.calendar1.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={e => this.onChangeDate(e, "birth_date")}
                          selected={controls.birth_date.value}
                          maxDate={new Date()}
                          name="birth_date"
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={r => (this.calendar1 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          popperPlacement="bottom"
                          popperModifiers={{
                            flip: {
                              enabled: false
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false
                            }
                          }}
                          autoComplete="nofill"
                          dropdownMode="select"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl="4" md="12" xs="12" style={{ marginTop: "-15px" }}>
                  <FormGroup>
                    <Label for="common">Anniversary Date</Label>
                    <Label
                      className="add-customer-date"
                      onClick={e => {
                        if (this.calendar1.state.open) {
                          e.preventDefault()
                        } else {
                          this.handleCalendarOpen(e)
                        }
                      }}
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={e =>
                            this.onChangeDate(e, "anniversary_date")
                          }
                          name="anniversary_date"
                          maxDate={new Date()}
                          selected={controls.anniversary_date.value}
                          dateFormat="dd/MM/yyyy"
                          autoComplete="nofill"
                          showMonthDropdown
                          ref={r => (this.calendar2 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          dropdownMode="select"
                          popperPlacement="bottom"
                          popperModifiers={{
                            flip: {
                              enabled: false
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false
                            }
                          }}
                        // onCalendarOpen={() => this.handleCalendarOpen()}
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </FormGroup>
                </Col>

                <Col xl="12" md="12" xs="12" style={{ marginTop: "-15px" }}>
                  <FormGroup>
                    <Label for="common">Remark</Label>
                    <TextareaAutosize
                      name="remark"
                      autoComplete="nofill"
                      id="remark"
                      value={controls.remark.value}
                      style={{ maxHeight: 50, fontSize: "14px" }}
                      className="form-control"
                      onChange={e =>
                        this.handleRemarks(e.target.value, "remark")
                      }
                      placeholder="Type here"
                    />
                  </FormGroup>
                </Col>
                <Row
                  style={{
                    marginRight: "0",
                    height: "25px",
                    marginLeft: "73%"
                  }}
                >
                  <Col xl="12" md="12" xs="12" className="d-flex-row">
                    {this.state.customer_id ? (
                      <div className="f-flex-row">
                        <img
                          src="images/plus_blue.svg"
                          onClick={() => this.switchOpenPage(1)}
                          className="download-report-icon cursor-pointer margin-left-10"
                          alt=''
                        />
                        &nbsp;&nbsp;
                        <span
                          className="link-warm-blue cursor-pointer"
                          onClick={() => this.switchOpenPage(1)}
                        >
                          Add New Vehicle
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    xl="8"
                    xs="12"
                    md="12"
                    className="text-align-right"
                    id="btn-submit"
                    style={{ marginTop: "120px" }}
                  ></Col>
                </Row>
                {customerVehicleData && customerVehicleData.length > 0 && (
                  <span>
                    {customerVehicleData.map((item, i) => (
                      <Row
                        style={{
                          marginLeft: "15px",
                          marginRight: "0",
                          marginTop: "15px",
                          marginBottom: "20px",
                          height: "auto"
                        }}
                        className="view-vehicle"
                      >
                        <Col
                          xl="3"
                          md="3"
                          xs="3"
                          style={{
                            borderRight: "solid 1px #febb1e",
                            marginTop: "15px",
                            marginBottom: "15px"
                          }}
                        >
                          <div className="align-center">
                            <span>Vehicle Type</span>
                            <span>
                              <img
                                src={item.vehicle_type_image_url}
                                class="ADV0363"
                                alt=''
                              />
                            </span>
                          </div>
                        </Col>

                        <Col
                          xl="8"
                          md="8"
                          xs="8"
                          style={{
                            marginTop: "15px",
                            marginBottom: "15px"
                          }}
                        >
                          <Row
                            style={{
                              marginTop: "-20px",
                              fontSize: "12px"
                            }}
                          >
                            <Col
                              xl="7"
                              md="7"
                              xs="7"
                              className="line-height-2"
                              style={{
                                marginTop: "15px"
                              }}
                            >
                              Model
                            </Col>
                            <Col
                              style={{ marginTop: "20px" }}
                              xl="5"
                              md="5"
                              xs="5"
                            >
                              <b>{item.vehicle_type_brand_name}</b>{" "}
                            </Col>
                          </Row>

                          <Row
                            style={{
                              marginTop: "-20px",
                              fontSize: "12px"
                            }}
                          >
                            <Col
                              xl="7"
                              md="7"
                              xs="7"
                              className="line-height-2"
                              style={{
                                marginTop: "15px"
                              }}
                            >
                              Make
                            </Col>
                            <Col
                              style={{ marginTop: "20px" }}
                              xl="5"
                              md="5"
                              xs="5"
                            >
                              <b>{item.vehicle_type_brand_model_name}</b>{" "}
                            </Col>
                          </Row>

                          <Row
                            style={{
                              marginTop: "-20px",
                              fontSize: "12px"
                            }}
                          >
                            <Col
                              className="line-height-2"
                              xl="7"
                              md="7"
                              xs="7"
                              style={{
                                marginTop: "15px"
                              }}
                            >
                              Registration No.
                            </Col>
                            <Col
                              xl="5"
                              md="5"
                              xs="5"
                              style={{ marginTop: "20px" }}
                            >
                              <b>{item.registration_number}</b>{" "}
                            </Col>
                          </Row>

                          <Row
                            style={{
                              marginTop: "-20px",
                              fontSize: "12px"
                            }}
                          >
                            <Col
                              className="line-height-2"
                              xl="7"
                              md="7"
                              xs="7"
                              style={{
                                marginTop: "15px"
                              }}
                            >
                              Average monthly usage (KMs)
                            </Col>
                            <Col
                              xl="5"
                              md="5"
                              xs="5"
                              style={{ marginTop: "20px" }}
                            >
                              <b>{item.vehical_avg_monthly_running}</b>{" "}
                            </Col>
                          </Row>

                          <Row
                            style={{
                              marginTop: "-20px",
                              fontSize: "12px"
                            }}
                          >
                            <Col
                              className="line-height-2"
                              xl="7"
                              md="7"
                              xs="7"
                              style={{
                                marginTop: "15px"
                              }}
                            >
                              Type of ownership of vehicle
                            </Col>
                            <Col
                              xl="5"
                              md="5"
                              xs="5"
                              style={{ marginTop: "20px" }}
                            >
                              <b>{item.vehicle_category}</b>{" "}
                            </Col>
                          </Row>
                        </Col>

                        <div>
                          <img
                            className="cursor-pointer"
                            src="images/edit.svg"
                            style={{ height: "13px" }}
                            onClick={() => this.switchOpenPage(1, item)}
                            alt=''
                          />
                          &nbsp;&nbsp;
                          <img
                            className="cursor-pointer"
                            onClick={() =>
                              this.deleteCustomerConfirm(
                                item.customer_vehicle_id
                              )
                            }
                            src="images/delete.svg"
                            alt=''
                            style={{ height: "13px" }}
                          />
                        </div>
                      </Row>
                    ))}
                  </span>
                )}
              </Row>
            </div>

            <Row
              style={{
                marginLeft: "0",
                marginRight: "0",
                height: "100%",
                maxHeight: "80px",
                position: "relative",
                bottom: "0",
                width: "100%"
              }}
            >
              <Col
                xl="12"
                xs="12"
                md="12"
                className="text-align-right footer-drawer"
              >
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-save-button"
                  color="warning"
                  onClick={() => {
                    this.addCustomer();
                  }}
                >
                  {this.state.customer_id ? "Save" : "Add"}
                </Button>
              </Col>
            </Row>
          </>
        )}
        {switchHideShowPage === 1 && (
          <>
            <AddNewVehicle
              switchOpenPage={this.switchOpenPage}
              phone_number={controls.phone_number.value}
              isDataLoading={this.isDataLoading}
              registeredVehicleDetails={this.state.customerVehicleData}
              customer_id={this.state.customer_id}
              ref={this.childAddNewVehicle}
            />
          </>
        )}
        {/* <br /><br /><br /><br /><br /><br /> */}
      </>
    );
  }
}

export default AddNewCustomer;
