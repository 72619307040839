import React, { Component } from "react";

import {
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import MarketingCouponService from '../../services/MarketingCouponService';
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import "./RedeemCoupon.css";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import VerifyCoupon from "../../components/VerifyCoupon/VerifyCoupon";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";


const { ERROR_MESSAGE } = messages.COMMON;

class RedeemCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        code: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        }
      },
      isLoader: false,
      couponDetail: {},
      isVerifiedCouponCardOpen: false,
      isShowDownloadScorcard: this.props.ShowDownloadScorcard
    };
  }

  componentDidMount() {
    GoogleAnalticsService.event('Redeem Marketing Coupon', 'Enter Coupon Dialogue Verify View');
    GoogleAnalticsService.pageView('Redeem Discount Coupon View');
  }

  validateCoupon = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    GoogleAnalticsService.event('Redeem Marketing Coupon', 'Enter Coupon Dialogue Verify Click');
    this.setState({
      isLoader: true,
    });
    MarketingCouponService.validateCoupon({
      coupon_code: this.state.controls.code.value
    })
      .then((data) => {
        this.setState({
          isLoader: false,
          isShowDownloadScorcard: false,
          couponDetail: data.data.data
        });
        this.openRedeemCouponModel();
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        console.error("e", e);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { code } = controls;

    if ((code.touched === true || isSubmit) && code.value === '') {
      code.showErrorMsg = true;
      isFormValid = false;
    } else {
      code.showErrorMsg = false;
      isFormValid = true;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;

    if (controls[controlName].touched === true && (controls[controlName].value === '' || controls[controlName].value === null)) {
      controls[controlName].showErrorMsg = true;
    } else {
      controls[controlName].showErrorMsg = false;
    }
    this.setState({ controls });
  };

  openRedeemCouponModel = () => {
    this.setState({ isVerifiedCouponCardOpen: true });
  };

  closeRedeemCouponModel = () => {
    this.props.closeModal();
    this.setState({ isVerifiedCouponCardOpen: false });
  };

  render() {

    const { controls } = this.state;

    return (
      <div id="coupon">
        {this.state.isVerifiedCouponCardOpen && (
          <VerifyCoupon
            mainModel={this.state.isVerifiedCouponCardOpen}
            data={this.state.couponDetail}
            closeModal={this.closeRedeemCouponModel}
            openModel={true}
          />
        )}

        <Modal
          id="redeem-coupon"
          isOpen={this.state.isShowDownloadScorcard}
          toggle={this.props.openModel}
          className="modelCard"
          centered
        >
          {this.state.isLoader && <CustomSpinner />}
          <ModalBody>
            <div>
              <Col xl-12>
                <Row
                  style={{
                    marginTop: "5px",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Col className="label-redeem" style={{ marginTop: "15px" }} xl="9" md="9" sm="9">
                    <b>REDEEM COUPON</b>
                  </Col>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                      paddingLeft: "60px",
                    }}
                  >
                    <img
                      style={{
                        width: "10px"
                      }}
                      src="./images/close.svg"
                      alt=''
                      onClick={this.props.closeModal}
                    />
                  </Col>
                </Row>
                <div className="border" />
                <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                  <Col>
                    <FormGroup>
                      <Label className="label-select">Enter Coupon Code&nbsp;<span className="required-field">*</span></Label>
                      <br />
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="code"
                        id="code"
                        value={controls.code.value}
                        onChange={this.handleInputChange}
                        placeholder="Type here"
                      />
                      {controls.code.showErrorMsg && (
                        <div className="error">
                          * Please enter coupon code.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" md="12" sm="12" style={{
                    padding: "-1px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}>
                    <Button style={{ width: "43%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.props.closeModal}
                    >
                      Cancel
                  </Button> &nbsp; &nbsp;
                    <Button style={{ width: "43%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={this.validateCoupon}
                    >
                      Verify
                  </Button>
                  </Col>
                  {/*  <Col xl="6" md="6" sm="12" style={{
                    padding: "-1px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}>
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={this.validateCoupon}
                    >
                      Verify
                  </Button>
                  </Col> */}
                </Row>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default RedeemCoupon;
