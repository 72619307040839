import React, { useState } from "react";
import Chart from "react-apexcharts";
import { FaArrowCircleDown, FaDownload } from "react-icons/fa";
import "./GraphAnalysis.css";
import { Button, Col, Row, Spinner } from "reactstrap";
import { routes } from "../../../constants/constant.route";

// const options66 = {
//   chart: {
//     height: 350,
//     type: "line",
//     toolbar: {
//       show: false,
//     },
//   },
//   // plotOptions: {
//   //   bar: {
//   //     // borderRadius: 4,
//   //     horizontal: true,
//   //   },
//   // },
//   // colors:['#fdbb1f', '#E91E63'],
//   // colors:['#ff0000', '#329e15'],
//   stroke: {
//     width: [0, 4],
//   },
//   labels: [
//     "01 Jan 2001",
//     "02 Jan 2001",
//     "03 Jan 2001",
//     "04 Jan 2001",
//     "05 Jan 2001",
//     "06 Jan 2001",
//     "07 Jan 2001",
//     "08 Jan 2001",
//     "09 Jan 2001",
//     "10 Jan 2001",
//     "11 Jan 2001",
//     "12 Jan 2001",
//   ],
//   xaxis: {
//     type: "datetime",
//   },
//   yaxis: [
//     {
//       title: {
//         text: "Website Blog",
//       },
//     },
//     {
//       opposite: true,
//       title: {
//         text: "Social Media",
//       },
//     },
//   ],
// };
// const series66 = [
//   {
//     name: "Website Blog",
//     type: "column",
//     data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
//     // data: ["11 Jan 2001", "12 Jan 2001"],
//   },
//   {
//     name: "Social Media",
//     type: "line",
//     data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
//   },
// ];

const series1 = [
  {
    data: [270, 430],
  },
];

const GraphAnalysis = ({
  // downloadMaxDealerPurchase,
  chartSeries,
  categories,
  chartHeading,
  chartData,
  chartData2,
}) => {
  // const [chartOpt, setChartOpt] = useState(options1);
  // const [chartSeries, setChartSeries] = useState(series1);
  const [downloadSpinner, setDownloadSpinner] = useState({
    more: false,
    less: false,
  });
  const options1 = {
    chart: {
      type: "bar",
      height: 350,
      id: "basic-bar",
      animations: {
        speed: 200,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#DC4C64", "#14A44D"],
    plotOptions: {
      bar: {
        // borderRadius: 4,
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
  };

  const downloadDealerPurchase = async (q) => {
    console.log(chartData);
    setDownloadSpinner({ ...downloadSpinner, [q]: true });
    // http://192.168.1.86:8000/api/download_data/10/more/2022/2023/jan/jul
    // download_data/percent_filter/type/start_year/end_year/first_month/second_month
    // let url = `http://3.226.27.7:30000/api/download_data/${chartData.perc_filter}/${q}/${chartData.first_year}/${chartData.sec_year}/jan/jul`;
    // if (filterRadio === "qoq") {
    //   url = `http://192.168.1.86:8000/api/download_data/${percentage}/${q}/${cmpQuarter.first_year}/${cmpQuarter.sec_year}/jan/jul`;
    // }
    // if (chartData.filterRadio === "mom") {
    //   url = `http://192.168.1.86:8000/api/download_data/${chartData.perc_filter}/${q}/${chartData.first_year}/${chartData.sec_year}/${chartData.first_month}/${chartData.sec_month}`;
    // }
    try {
      const headers = {
        "content-type": "application/json",
      };
      let bodyObj = {
        ...chartData2,
        download: true,
        download_data_for: q,
      };
      console.log(bodyObj);
      const dataRes = await fetch(`${routes.ANALYSIS.API_URL}/api/compare`, {
        method: "POST",
        // body: obj1,
        body: JSON.stringify(bodyObj),
        headers: headers,
      });
      // const dataRes = await fetch(url, {
      //   method: "GET",
      // });
      if (dataRes) {
        const blobRes = await dataRes.blob();
        if (blobRes) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blobRes);
          link.download = `${chartData.filterRadio.toUpperCase()} ${q
            .charAt(0)
            .toUpperCase() + q.slice(1)} Than ${
            chartData.perc_filter
          }% Dealers_details.xlsx`;
          // ${Date.now()}
          link.click();
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }
      }
      setDownloadSpinner({ ...downloadSpinner, [q]: false });
    } catch (error) {
      console.log(error);
    }
    setDownloadSpinner({ ...downloadSpinner, [q]: false });
  };
  return (
    <>
      <div className="bar anlysisStyle card">
        <p className="m-0 p-2 font-bold text-align-center">{chartHeading}</p>
        <Chart
          options={options1}
          series={chartSeries}
          type="bar"
          width="100%"
          height="400px"
        />
      </div>
      <Row className="mx-0 mt-3">
        <Col xs={12} lg={6} className="p-0">
          <div
            className="card p-3 analysis-dwn-card1"
            style={{ height: "200px" }}
          >
            <p className="font-bold mb-0">Dealeres count</p>
            <div style={{ display: "flex" }}>
              <div
                className="pl-4 dealers-details"
                style={{
                  borderRight: "2px solid #ddd",
                  height: "130px",
                  width: "50%",
                }}
              >
                <p className="mb-1">
                  {categories[0]}:{`  `} <b> {chartSeries[0]["data"][0]}</b>
                </p>
                {chartData2.zone && (
                  <p className="mb-1">
                    Zone: <b>{chartData2.zone || `   - `}</b>
                  </p>
                )}
                {chartData2.tyre_category && (
                  <p className="mb-1">
                    Tyre Category: <b>{chartData2.tyre_category || `   - `}</b>
                  </p>
                )}
              </div>
              {/* <Col className="pt-4"> */}
              <div className="pl-4 dealers-details">
                <p className="mb-1">
                  {" "}
                  {categories[1]}: {`  `}
                  <b>{chartSeries[0]["data"][1]}</b>{" "}
                </p>
                {chartData2.region && (
                  <p className="mb-1">
                    Region: <b>{chartData2.region || `   - `}</b>
                  </p>
                )}
                {chartData2.classification && (
                  <p className="mb-1">
                    Classifications:{" "}
                    <b>{chartData2.classification || `   - `}</b>
                  </p>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6} className="p-0">
          <div
            className="card p-3 analysis-dwn-card2"
            style={{ height: "200px" }}
          >
            <p className="font-bold mb-1">Download dealers details </p>
            <Row className="text-align-center">
              <Col
                className="pt-2"
                style={{
                  borderRight: "2px solid #ddd",
                  height: "130px",
                  alignItems: "center",
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <p className="mb-0">{categories[0]} </p>
                <Button
                  color="warning"
                  disabled={!chartData.perc_filter}
                  outline
                  className="download-btn"
                  onClick={() => downloadDealerPurchase("less")}
                >
                  <span style={{ fontSize: "20px" }}>
                    <FaArrowCircleDown />
                  </span>
                  <span> Download {`  `}</span>
                  {downloadSpinner.less && (
                    <Spinner size="sm" color="secondary" />
                  )}
                </Button>
              </Col>
              <Col
                className="pt-2"
                style={{
                  height: "130px",
                  alignItems: "center",
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <p className="mb-0">{categories[1]}</p>
                <Button
                  onClick={() => downloadDealerPurchase("more")}
                  className="download-btn"
                  disabled={!chartData.perc_filter}
                  outline
                  color="warning"
                >
                  <span style={{ fontSize: "20px" }}>
                    <FaArrowCircleDown />
                  </span>
                  <span> Download{`  `} </span>
                  {downloadSpinner.more && (
                    <Spinner size="sm" color="secondary" />
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GraphAnalysis;
