import React, { Component } from 'react';
import {
  Row, Col
} from 'reactstrap';
import CipSummaryList from '../../components/CipSummaryList/CipSummaryList';
import CipDetail from '../CipDetail/CipDetail';
import CipAttendeeList from '../../components/CIpAttendeeList/CipAttendeeList';
import CipCoAttendeeList from '../../components/CipCoAttendeeList/CipCoAttendeeList';
class CipSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      displayCMP: 'CipSummaryList',
      cip_dealer_deatil: {},
      cip_detail: {},
      cip_summary_list_state: {},
      cip_attendee_state: {},
      cip_co_attendee_state: {},
      from: '',
      select_vehicle_tab: false,
      is_view_only_dealils: false,
      isDisplayAddParticipantPopover: false
    }
    if (this.props.location && this.props.location.isDisplayAddParticipantPopover) {
      this.state.isDisplayAddParticipantPopover = this.props.location.isDisplayAddParticipantPopover;
    }

  }


  redirectToCreateCIP = () => {
    window.scroll(0, 0);
    this.props.history.push(
      `cip-registration`
    );
  }

  goToAddParticapant = (row, state, from, cip_detail = null, select_vehicle_tab) => {
    window.scroll(0, 0);
    let { is_view_only_dealils, cip_attendee_state } = this.state;

    if (select_vehicle_tab && (Object.keys(cip_attendee_state).length > 0) &&
      (cip_attendee_state.status === "Canceled" || cip_attendee_state.status === "Ended")) {
      is_view_only_dealils = true;
    } else {
      is_view_only_dealils = false;
    }
    this.setState({
      displayCMP: 'CipDetail',
      cip_dealer_deatil: row,
      from: from,
      cip_summary_list_state: state ? state : this.state.cip_summary_list_state,
      cip_detail: cip_detail,
      select_vehicle_tab: select_vehicle_tab,
      is_view_only_dealils
    })
  }


  goToCipSummary = () => {
    window.scroll(0, 0);
    this.setState({
      displayCMP: this.state.from ? this.state.from : 'CipSummaryList',
      from: null
    })
  }

  goToCipAttendeeList = (row) => {
    window.scroll(0, 0);
    this.setState({
      displayCMP: 'CipAttendeeList',
      cip_attendee_state: row
    })
  }

  openCoAttendeeList = (row) => {
    window.scroll(0, 0);
    this.setState({
      displayCMP: 'CipCoAttendeeList',
      cip_co_attendee_state: row
    })
  }

  backToAttendee = () => {
    window.scroll(0, 0);
    this.setState({
      displayCMP: 'CipAttendeeList'
    })
  }


  redirectToCIPSummaryList = () => {
    window.scroll(0, 0);
    this.setState({
      displayCMP: 'CipSummaryList',
      from: null
    });
  }

  render() {
    return (
      <div>
        {/* {
          this.state.displayCMP !== 'CipDetail' &&
          <div className="page-header mb-3">CIP Summary</div>
        } */}
        {
          this.state.displayCMP === 'CipSummaryList' &&
          <CipSummaryList redirectToCreateCIP={() => this.redirectToCreateCIP()}
            goToAddParticapant={this.goToAddParticapant}
            goToCipAttendeeList={this.goToCipAttendeeList}
            redirectToCIPSummaryList={this.redirectToCIPSummaryList}
            isDisplayAddParticipantPopover={this.state.isDisplayAddParticipantPopover}
            cip_summary_list_state={this.state.cip_summary_list_state} />
        }
        {
          this.state.displayCMP === 'CipDetail' &&
          <CipDetail goToCipSummary={this.goToCipSummary}
            cip_detail={this.state.cip_detail}
            select_vehicle_tab={this.state.select_vehicle_tab}
            redirectToCIPSummaryList={this.redirectToCIPSummaryList}
            is_view_only_dealils={this.state.is_view_only_dealils}
            cip_dealer_deatil={this.state.cip_dealer_deatil} />
        }
        {
          this.state.displayCMP === 'CipAttendeeList' &&
          <CipAttendeeList cip_attendee_state={this.state.cip_attendee_state}
            goToAddParticapant={this.goToAddParticapant}
            openCoAttendeeList={this.openCoAttendeeList}
            goToCipSummary={this.goToCipSummary} />
        }
        {
          this.state.displayCMP === 'CipCoAttendeeList' &&
          <Row>
            <Col xl="9" md="12" xs="12">
              <CipCoAttendeeList
                cip_co_attendee_state={this.state.cip_co_attendee_state}
                goToAddParticapant={this.goToAddParticapant}
                cip_attendee_state={this.state.cip_attendee_state}
                backToAttendee={this.backToAttendee} />
            </Col>
          </Row>
        }
      </div>
    );
  }
}

export default CipSummary;