import { request } from "./Request";
import { routes } from "../constants/constant.route";
const { RETAIL_MARKETING_SYSTEM } = routes;
const {
  UPLOAD_RETAIL_PHOTOS,
  ADD_UPDATE_RETAIL,
  GET_STATE_DISTRICT,
  GET_ALL_RETAIL,
  GET_RETAIL_DETAIIL,
  GET_DEALER_LIST,
  ADD_UPDATE_RETAIL_STATUS,
  GET_CLUB_PRICE,
  ADD_UPDATE_RETAIL_INSTALLATION,
  GET_MINI_RETAIL_DETAIL,
  GET_MINI_RETAIL_HO_DETAIL,
  UPLOAD_RETAIL_IMAGES,
  ADD_MINI_RETAIL_DETAIL,
  GET_MINI_DEALER_LIST,
  GET_VENDOR_LIST
} = RETAIL_MARKETING_SYSTEM;
const API_URL = process.env.REACT_APP_API_URL;

export default class RetailMarketingSystemService {
  static uploadRetailFile(obj) {
    return request(
      "POST",
      `${API_URL}${UPLOAD_RETAIL_PHOTOS}`,
      null,
      obj,
      null
    );
  }

  static uploadRetailImages(obj) {
    return request(
      "POST",
      `${API_URL}${UPLOAD_RETAIL_IMAGES}`,
      null,
      obj,
      null
    );
  }

  static getStateDistrict(obj) {
    return request("GET", `${API_URL}${GET_STATE_DISTRICT}`, obj, null, null);
  }

  static addUpdateRetail(obj) {
    return request("POST", `${API_URL}${ADD_UPDATE_RETAIL}`, null, obj, null);
  }
  static addUpdateRetailInstallation(obj) {
    return request(
      "POST",
      `${API_URL}${ADD_UPDATE_RETAIL_INSTALLATION}`,
      null,
      obj,
      null
    );
  }
  static addUpdateRetailHOStatus(obj) {
    return request(
      "POST",
      `${API_URL}${ADD_UPDATE_RETAIL_STATUS}`,
      null,
      obj,
      null
    );
  }

  static getAllRetail(obj) {
    return request("POST", `${API_URL}${GET_ALL_RETAIL}`, null, obj, null);
  }
  static getClubPrice(obj) {
    return request("GET", `${API_URL}${GET_CLUB_PRICE}`, null, obj, null);
  }
  static getRetailDetail(obj) {
    return request("GET", `${API_URL}${GET_RETAIL_DETAIIL}`, obj, null, null);
  }
  static serachDealer(obj) {
    return request("GET", `${API_URL}${GET_DEALER_LIST}`, obj, null, null);
  }
  static getMiniRetailDetail(obj) {
    return request(
      "GET",
      `${API_URL}${GET_MINI_RETAIL_DETAIL}`,
      obj,
      null,
      null
    );
  }

  static getMiniRetailHODetail(obj) {
    return request(
      "POST",
      `${API_URL}${GET_MINI_RETAIL_HO_DETAIL}`,
      null,
      obj,
      null
    );
  }

  static addMiniRetailDetail(obj) {
    return request(
      "POST",
      `${API_URL}${ADD_MINI_RETAIL_DETAIL}`,
      null,
      obj,
      null
    );
  }
  static serachMiniDealer(obj) {
    return request("GET", `${API_URL}${GET_MINI_DEALER_LIST}`, obj, null, null);
  }

  static getVendorList(reqData) {
    return request("GET", `${API_URL}${GET_VENDOR_LIST}`, reqData, null, null);
  }
}
