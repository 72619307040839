import { request } from "./Request";
import { routes } from "../constants/constant.route";
import Storage from "./Storage";
import UserService from "./UserService";

const { LOGIN, FORGOT_PASSWORD, LOGOUT } = routes.AUTH;

const API_URL = process.env.REACT_APP_API_URL;

export default class AuthService {
  static isAuthenticated() {
    const tokenDetail = Storage.getTokenDetail();
    if (tokenDetail && tokenDetail.accessToken) {
      return true;
    }
    return false;
  }

  static login(loginData) {
    const headers = {
      "content-type": "application/x-www-form-urlencoded"
    };
    loginData = { ...loginData, grant_type: "password" };
    return request("POST", `${API_URL}${LOGIN}`, null, loginData, headers);
  }

  static forgotPassword(body) {
    return request("POST", `${API_URL}${FORGOT_PASSWORD}`, null, body, null);
  }

  static logoutFromAllDevice() {
    this.removeFirebaseToken();
    return request("GET", `${API_URL}${LOGOUT}`, null, null, null);
  }

  static logout() {
    this.removeFirebaseToken();
    Storage.removeKeepMeLoggedInCookie();
    Storage.removeTokenDetail();
    Storage.removeUserDetail();
    Storage.removeKeepMeLoggedInCookie();
    Storage.removePlaceOrderCartItems();
    Storage.removeHideNavBar();
    Storage.removeAddCustomerData();
    Storage.removePlaceOrderRedeemItems();
    localStorage.setItem("active_id", 0);
    localStorage.setItem("activeLink", null);
    // this.closeLogoutModal();
    // this.setState({ redirectToLoginPage: true });
  }

  static removeFirebaseToken() {
    try {
      UserService.saveToken({ token: "" });
    } catch (error) {
      console.log(error);
    }
  }
}
