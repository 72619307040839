import React, { Component } from 'react';
import {
  Button, FormGroup, Input, Label, Row, Col, InputGroup,
  InputGroupAddon
} from 'reactstrap';
import DatePicker from "react-datepicker";
import Validation from '../../services/Validation';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

class OrderHistoryFilterDealer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        orderStartDate: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        orderEndDate: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        amountStart: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        amountEnd: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        pointStart: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        pointEnd: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        paymentMode: {
          value: 'all',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        paymentStatus: {
          value: 'all',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
      },
      is_warranty_points_redemption: true
    }
  }

  componentDidMount() {
    GoogleAnalticsService.pageView('Order History Filter Screen');
    if (this.props.controls_filter && Object.keys(this.props.controls_filter).length > 0) {
      this.setState({
        controls: this.props.controls_filter
      });
    }
  }

  handleValidation = (isSubmit = false) => {
    return true;
    let { controls } = this.state;
    let isFormValid = false;
    let {
      amountStart, amountEnd, pointStart, pointEnd
    } = controls;

    if (amountStart.touched === true || isSubmit) {
      amountStart = Validation.notNullValidator(amountStart);
      amountEnd = Validation.notNullValidator(amountEnd);
      amountStart.isNotValidMinMax = false;
      amountEnd.isNotValidMinMax = false;
      if (!amountStart.nullValue && !amountEnd.nullValue) {
        if (amountStart.value === 0 || amountStart.value === '0') {
          amountStart.valid = true;
        } else if (parseInt(amountStart.value) > parseInt(amountEnd.value)) {
          amountStart.valid = false;
          amountStart.isNotValidMinMax = true;
        } else {
          amountStart.valid = true;
        }
      } else if (!amountStart.nullValue) {
        if (amountStart.value === 0 || amountStart.value === '0') {
          amountStart.valid = true;
        } else {
          amountStart.valid = true;
          // if (amountEnd.nullValue && (amountEnd.touched === true || isSubmit)) {
          //   amountEnd.showErrorMsg = true;
          //   amountEnd.valid = false;
          // }
        }
      } else if (amountStart.nullValue) {
        amountStart.valid = true;
        amountStart.showErrorMsg = false;
      }
      else {
        amountStart.valid = true;
      }
      if (!amountStart.isNotValidMinMax) {
        if (amountStart.valid === false) {
          amountStart.showErrorMsg = true;
        } else {
          amountStart.showErrorMsg = false;
        }
      }
    }


    if (amountEnd.touched === true || isSubmit) {
      amountStart = Validation.notNullValidator(amountStart);
      amountEnd = Validation.notNullValidator(amountEnd);
      if (!amountStart.nullValue && !amountEnd.nullValue) {
        if (amountEnd.value === 0 || amountEnd.value === '0') {
          amountEnd.valid = false;
        } else if (parseInt(amountEnd.value) < parseInt(amountStart.value)) {
          amountEnd.isNotValidMinMax = true;
          amountEnd.valid = false;
        } else {
          amountEnd.valid = true;
        }
      } else if (!amountEnd.nullValue) {
        if (amountEnd.value === 0 || amountEnd.value === '0') {
          amountEnd.valid = false;
        } else {
          amountEnd.valid = true;
          // if (amountStart.nullValue && amountStart.touched === true || isSubmit) {
          //   amountStart.showErrorMsg = true;
          //   amountStart.valid = false;
          // }
        }
      } else if (amountEnd.nullValue) {
        amountEnd.valid = true;
        amountEnd.showErrorMsg = false;
      } else {
        if (!amountStart.nullValue) {
          amountEnd.valid = false;
          amountEnd.showErrorMsg = true;
        } else {
          amountEnd.valid = true;
        }
      }
      if (!amountEnd.isNotValidMinMax) {
        if (amountEnd.valid === false) {
          amountEnd.showErrorMsg = true;
        } else {
          amountEnd.showErrorMsg = false;
        }
      } else {
        amountEnd.showErrorMsg = false;
      }
    }


    if (pointStart.touched === true || isSubmit) {
      pointStart = Validation.notNullValidator(pointStart);
      pointEnd = Validation.notNullValidator(pointEnd);
      pointStart.isNotValidMinMax = false;
      pointEnd.isNotValidMinMax = false;
      if (!pointStart.nullValue && !pointEnd.nullValue) {
        if (pointStart.value === 0 || pointStart.value === '0') {
          pointStart.valid = true;
        } else if (parseInt(pointStart.value) > parseInt(pointEnd.value)) {
          pointStart.valid = false;
          pointStart.isNotValidMinMax = true;
        } else {
          pointStart.valid = true;
        }
      } else if (!pointStart.nullValue) {
        if (pointStart.value === 0 || pointStart.value === '0') {
          pointStart.valid = true;
        } else {
          pointStart.valid = true;
          // if (pointEnd.nullValue && (pointEnd.touched === true || isSubmit)) {
          //   pointEnd.showErrorMsg = true;
          //   pointEnd.valid = false;
          // }
        }
      } else if (pointStart.nullValue) {
        pointStart.valid = true;
        pointStart.showErrorMsg = false;
      }
      else {
        pointStart.valid = true;
      }
      if (!pointStart.isNotValidMinMax) {
        if (pointStart.valid === false) {
          pointStart.showErrorMsg = true;
        } else {
          pointStart.showErrorMsg = false;
        }
      }
    }


    if (pointEnd.touched === true || isSubmit) {
      pointStart = Validation.notNullValidator(pointStart);
      pointEnd = Validation.notNullValidator(pointEnd);
      if (!pointStart.nullValue && !pointEnd.nullValue) {
        if (pointEnd.value === 0 || pointEnd.value === '0') {
          pointEnd.valid = false;
        } else if (parseInt(pointEnd.value) < parseInt(pointStart.value)) {
          pointEnd.isNotValidMinMax = true;
          pointEnd.valid = false;
        } else {
          pointEnd.valid = true;
        }
      } else if (!pointEnd.nullValue) {
        if (pointEnd.value === 0 || pointEnd.value === '0') {
          pointEnd.valid = false;
        } else {
          pointEnd.valid = true;
          // if (pointStart.nullValue && pointStart.touched === true || isSubmit) {
          //   pointStart.showErrorMsg = true;
          //   pointStart.valid = false;
          // }
        }
      } else if (pointEnd.nullValue) {
        pointEnd.valid = true;
        pointEnd.showErrorMsg = false;
      } else {
        if (!pointStart.nullValue) {
          pointEnd.valid = false;
          pointEnd.showErrorMsg = true;
        } else {
          pointEnd.valid = true;
        }
      }
      if (!pointEnd.isNotValidMinMax) {
        if (pointEnd.valid === false) {
          pointEnd.showErrorMsg = true;
        } else {
          pointEnd.showErrorMsg = false;
        }
      } else {
        pointEnd.showErrorMsg = false;
      }
    }

    if (amountStart.valid === true && amountEnd.valid === true &&
      pointStart.valid === true && pointEnd.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    this.setState({
      controls
    });

    return isFormValid;
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  applyFilter = () => {
    this.handleValidation(true);
    let { controls } = this.state;
    let filterApplied = false;
    if (controls.orderStartDate.value ||
      controls.orderEndDate.value || controls.amountStart.value ||
      controls.amountEnd.value || controls.pointStart.value ||
      controls.pointEnd.value || controls.paymentStatus.value !== 'all' ||
      controls.amountStart.value || controls.paymentMode.value !== 'all') {
      filterApplied = true;
    }
    this.props.applyDealerFilter(filterApplied ? controls : {});
  }

  resetFilter = () => {
    this.props.applyDealerFilter({});
  }

  closeOrderHistoryFilterDealer = () => {
    this.props.closeOrderHistoryFilterDealer();
  }


  render() {
    const { controls } = this.state;
    return (
      <div class="custom-drawer">
        <div className="drawer-header">
          <span className="drawer-title">Filter</span>
          <img className="cursor-pointer" src="images/close.svg" alt='cancel'
            onClick={() => this.closeOrderHistoryFilterDealer()} />
        </div>
        <hr style={{ border: 'solid 1px #ced4da' }} className="w-100" />
        <div class="custom-drawer-content" style={{ marginLeft: '10px' }}>
          <Row className={'m-0'}>
            {
              this.props.is_warranty_points_redemption &&
              <Col xl="12">
                <FormGroup>
                  <Label>Payment Mode</Label>
                  <Row>
                    <Col xl="2">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" name="paymentMode" value="all" checked={controls.paymentMode.value === 'all'}
                            onChange={(e) => this.handleInputChange({ target: { name: 'paymentMode', value: 'all' } })} />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: '10px' }} className={controls.paymentMode.value === 'all' ? 'font-weight-bold' : ''}> All</span>
                      </div>
                    </Col>
                    <Col xl="3">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" name="paymentMode" value="Online" checked={controls.paymentMode.value === 'Online'}
                            onChange={(e) => this.handleInputChange({ target: { name: 'paymentMode', value: 'Online' } })} />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: '10px' }}
                          className={controls.paymentMode.value === 'Online' ? 'font-weight-bold' : ''}> Online</span>
                      </div>
                    </Col>
                    {
                      this.props.is_warranty_points_redemption &&
                      <Col xl="5">
                        <div className="d-flex-row">
                          <label className="custom-checkboax-radio--container">
                            <input type="radio" name="paymentMode" value="Points Redeemption" checked={controls.paymentMode.value === 'Points Redeemption'}
                              onChange={(e) => this.handleInputChange({ target: { name: 'paymentMode', value: 'Points Redeemption' } })} />
                            <span className="checkmark-radio" />
                          </label>
                          <span style={{ marginLeft: '10px' }} className={controls.paymentMode.value === 'Points Redeemption' ? 'font-weight-bold' : ''}>Point Redemption</span>
                        </div>
                      </Col>
                    }
                  </Row>
                </FormGroup>
              </Col>
            }
            {/* <Col xl="12">
              <FormGroup>
                <Label>Payment Status</Label>
                <Row>
                  <Col xl="2">
                    <div className="d-flex-row">
                      <label className="custom-checkboax-radio--container">
                        <input type="radio" name="paymentStatus" value="all" checked={controls.paymentStatus.value === 'all'}
                          onChange={(e) => this.handleInputChange({ target: { name: 'paymentStatus', value: 'all' } })} />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: '10px' }}
                        className={controls.paymentStatus.value === 'all' ? 'font-weight-bold' : ''}> All</span>
                    </div>
                  </Col>
                  <Col xl="3">
                    <div className="d-flex-row">
                      <label className="custom-checkboax-radio--container">
                        <input type="radio" name="paymentStatus" value="Success" checked={controls.paymentStatus.value === 'Success'}
                          onChange={(e) => this.handleInputChange({ target: { name: 'paymentStatus', value: 'Success' } })} />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: '10px' }}
                        className={controls.paymentStatus.value === 'Success' ? 'font-weight-bold' : ''}> Success</span>
                    </div>
                  </Col>
                  <Col xl="2">
                    <div className="d-flex-row">
                      <label className="custom-checkboax-radio--container">
                        <input type="radio" name="paymentStatus" value="Failed" checked={controls.paymentStatus.value === 'Failed'}
                          onChange={(e) => this.handleInputChange({ target: { name: 'paymentStatus', value: 'Failed' } })} />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: '10px' }}
                        className={controls.paymentStatus.value === 'Failed' ? 'font-weight-bold' : ''}>Fail</span>
                    </div>
                  </Col>
                  <Col xl="5">
                    <div className="d-flex-row">
                      <label className="custom-checkboax-radio--container">
                        <input type="radio" name="paymentStatus" value="Under Approval" checked={controls.paymentStatus.value === 'Under Approval'}
                          onChange={(e) => this.handleInputChange({ target: { name: 'paymentStatus', value: 'Under Approval' } })} />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: '10px' }}
                        className={controls.paymentStatus.value === 'Under Approval' ? 'font-weight-bold' : ''}>Under Approval</span>
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </Col> */}
            <Col xl="12">
              <Label className="font-weight-bold">Amount</Label>
            </Col>
            <Col xl="6">
              <FormGroup className="d-flex-column">
                <Label>Minimum amount</Label>
                <Input
                  type="text"
                  name="amountStart"
                  placeholder="Type here"
                  value={controls.amountStart.value}
                  onChange={(e) => this.handleInputChange(e)}
                />
                {controls.amountStart.showErrorMsg && (
                  <div className="error">
                    *Please enter the valid value
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup className="d-flex-column">
                <Label>Maximum amount</Label>
                <Input
                  type="text"
                  name="amountEnd"
                  placeholder="Type here"
                  value={controls.amountEnd.value}
                  onChange={(e) => this.handleInputChange(e)}
                />
                {controls.amountEnd.showErrorMsg && (
                  <div className="error">
                    *Please enter the valid value
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="12" md="12" xs="12" className="mt-n3">
              {!controls.amountEnd.showErrorMsg && !controls.amountStart.showErrorMsg &&
                (controls.amountStart.isNotValidMinMax || controls.amountEnd.isNotValidMinMax) && (
                  <div className="error">
                    *Not valid minimum or maximum  value.
                  </div>
                )}
            </Col>
            {this.props.is_warranty_points_redemption &&
              <>
                <Col xl="12">
                  <Label className="font-weight-bold">Points Redeemed</Label>
                </Col>
                <Col xl="6">
                  <FormGroup className="d-flex-column">
                    <Label>Minimum amount</Label>
                    <Input
                      type="text"
                      name="pointStart"
                      placeholder="Type here"
                      value={controls.pointStart.value}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    {controls.pointStart.showErrorMsg && (
                      <div className="error">
                        *Please enter the valid value
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <FormGroup className="d-flex-column">
                    <Label>Maximum amount</Label>
                    <Input
                      type="text"
                      name="pointEnd"
                      placeholder="Type here"
                      value={controls.pointEnd.value}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    {controls.pointEnd.showErrorMsg && (
                      <div className="error">
                        *Please enter the valid value
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="12" md="12" xs="12" className="mt-n3">
                  {!controls.pointEnd.showErrorMsg && !controls.pointStart.showErrorMsg &&
                    (controls.pointStart.isNotValidMinMax || controls.pointEnd.isNotValidMinMax) && (
                      <div className="error">
                        *Not valid minimum or maximum  value.
                      </div>
                    )}
                </Col>
              </>
            }
            <Col xl="12">
              <Label className="font-weight-bold">Order Date</Label>
            </Col>
            <Col xl="6">
              <FormGroup className="d-flex-column">
                <Label>Start Date</Label>
                <Label onClick={e => this.calendar1.state.open && e.preventDefault()}
                  className={!this.props.is_warranty_points_redemption ? 'display-botttom-datepicker' : ''}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.orderStartDate.value
                          ? new Date(controls.orderStartDate.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'orderStartDate', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar1 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={controls.orderEndDate.value ? controls.orderEndDate.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup className="d-flex-column">
                <Label>End Date</Label>
                <Label onClick={e => this.calendar2.state.open && e.preventDefault()}
                  className={!this.props.is_warranty_points_redemption ? 'display-botttom-datepicker date-picker-left-n-35px' : 'date-picker-left-n-35px'}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.orderEndDate.value
                          ? new Date(controls.orderEndDate.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'orderEndDate', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar2 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      minDate={controls.orderStartDate.value ? controls.orderStartDate.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>

          </Row>
        </div>
        <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter()} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
            <Button
              //  disabled={!isValidForm} 
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: 'pointer' }}
              onClick={() => this.applyFilter()}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div >
    );
  }
}

export default OrderHistoryFilterDealer;