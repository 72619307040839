import React, { Component } from "react";
import { Table } from "reactstrap";
import { formatNumber } from "../../../utils/utils";
// import { messages } from "../../../constants/constant.message";
class PrimaryTruckBusPointsReports extends Component {
  state = {
    year: null
  };

  componentWillReceiveProps(props) {
    if (props.year) {
      this.setState({
        year: props.year
      });
    }
  }
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ];
  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }
  getQuarter = month => {
    var m = Math.floor(month / 3) + 2;
    return m > 4 ? m - 4 : m;
  };

  render() {
    const { totals, labels } = this.props;

    let truckBusPoints = this.props.truckBusPoints;
    let currnetMonth = this.months[new Date().getMonth()];
    return (
      <>
        {truckBusPoints.map((quarter, i) => (
          <div className="quarter-points-wrapp mb-4">
            <div className="quarter-points-inner">
              <span className="QUARTER-label">
                QUARTER {quarter.quarter} ({quarter.month[0]}-
                {quarter.month[quarter.month.length - 1]})&nbsp;
                {quarter.month.map((item, monthIndex) =>
                  currnetMonth === item &&
                    new Date().getFullYear() <= this.state.year
                    ? "Current Quarter"
                    : ""
                )}
              </span>
            </div>
            <div className="QUARTER-tables">
              <div className="QUARTER-table quarter-tbl-left">
                <div className="d-flex-row total-offtake-elgible-wrapp">
                  <div className="d-flex-column">
                    <span className="mb-1 Total-Offtake mb-2">
                      Total Offtake <b>{
                        totals && totals[`q${quarter.quarter}`] && totals[`q${quarter.quarter}`]['tbb'] && totals[`q${quarter.quarter}`]['tbb']['totalOfftake'] &&
                        formatNumber(totals[`q${quarter.quarter}`]['tbb']['totalOfftake'])}</b>
                    </span>
                    {i === 0 ? (
                      <span className="Till-Date mb-2">Till Date</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="mb-1 Total-Offtake mb-2">
                    Total Eligible Offtake{" "}
                    <b>{
                      totals && totals[`q${quarter.quarter}`] && totals[`q${quarter.quarter}`]['tbb'] && totals[`q${quarter.quarter}`]['tbb']['eligibleOfftake'] &&
                      formatNumber(totals[`q${quarter.quarter}`]['tbb']['eligibleOfftake'])}</b>
                  </span>
                </div>{" "}
                <Table className="mb-0 table-responsive" bordered>
                  <thead>
                    <tr style={{ ...this.getStripedStyle(0) }}>
                      {labels && labels.offtake && labels.offtake.map(l => <th>{l.key}</th>)}

                      {/* <th>Month</th>
                      <th>Total offtake</th>
                      <th style={{ width: "115px", maxWidth: "100%" }}>
                        Eligible Offtake
                      </th>
                      <th>JRM </th>
                      <th>JXM</th>
                      <th>JRX</th>
                      <th>JXL</th>
                      <th>XLM</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {quarter.data.report.tbb.map((item, i) => (
                      <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                        {labels && labels.offtake && labels.offtake.map(l =>
                          <td>{
                            l.value === 'month' ? this.months[item[l.value] - 1] : formatNumber(item[l.value])
                          }</td>)}
                        {/* <td>{this.months[item.month - 1]}</td>
                        <td>{formatNumber(item.totalWholeMonthSales)}</td>
                        <td>{formatNumber(item.eligibleSales)}</td>
                        <td>{formatNumber(item.jrm)}</td>
                        <td>{formatNumber(item.jxm)}</td>
                        <td>{formatNumber(item.jrx)}</td>
                        <td>{formatNumber(item.jxl)}</td>
                        <td>{formatNumber(item.xlm)}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="QUARTER-table tbl-tbb-tbr-earnpoints-quarter-table">
                <span className="mb-1 Total-Offtake mb-2">
                  Total Earned Points{" "}
                  <b>{totals && totals[`q${quarter.quarter}`] && totals[`q${quarter.quarter}`]['tbb'] && totals[`q${quarter.quarter}`]['tbb']['totalPoints'] &&
                    formatNumber(totals[`q${quarter.quarter}`]['tbb']['totalPoints'])}</b>
                </span>
                {i === 0 ? (
                  <span className="Till-Date mb-2">
                    *These Points are provisional. They will get added to your
                    total earned points at the end of Q{quarter.quarter} if you
                    are eligible
                  </span>
                ) : (
                  ""
                )}

                <Table
                  className="mb-0 table-responsive tbl-tbb-tbr-earnpoints"
                  bordered
                  style={{ display: "block" }}
                >
                  <thead>
                    <tr style={{ ...this.getStripedStyle(0) }}>
                      {labels && labels.points && labels.points.map(l => <th>{l.key}</th>)}

                      {/* <th>Total</th> */}
                      {/* <th>Total</th>
                      <th>JRM </th>
                      <th>JXM</th>
                      <th>JRX</th>
                      <th>JXL</th>
                      <th>XLM</th>
                      <th>Consistency</th>
                      <th>Slab jump</th>
                      <th>Scheme</th>
                      <th>Approval</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {quarter.data.report.tbb.map((item, i) => (
                      <tr style={{ ...this.getStripedStyle(i + 1) }}>
                        {labels && labels.points && labels.points.map(l =>
                          <td>{
                            formatNumber(item[l.value])
                          }</td>)}
                        {/* <td>{formatNumber(item.biasPoints + item.jrmPoints
                              + item.jxmPoints + item.jrxPoints +
                              item.jxlPoints + item.xlmPoints +
                              item.consistencyBonusPoints + item.slabJumpPoints +
                              item.schemePoints + item.approvalPoints)}</td> */}
                        {/* <td>{formatNumber(item.biasPointsTotal)}</td>
                        <td>{formatNumber(item.jrmPoints)}</td>
                        <td>{formatNumber(item.jxmPoints)}</td>
                        <td>{formatNumber(item.jrxPoints)}</td>
                        <td>{formatNumber(item.jxlPoints)}</td>
                        <td>{formatNumber(item.xlmPoints)}</td>
                        <td>{formatNumber(item.consistencyBonusPoints)}</td>
                        <td>{formatNumber(item.slabJumpPoints)}</td>
                        <td>{formatNumber(item.schemePoints)}</td>
                        <td>{formatNumber(item.approvalPoints)}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <br />
              </div>
            </div>
            {i === truckBusPoints.length - 1 && (
              <>
                <br />
                {/* <a href="https://www.jktyreadvantage.com" target="_blank" rel="noopener noreferrer">
                    <b className="Learn-more-about" style={{ color: '#495057' }}>
                      {messages.PRIMARY_REPORT.REDIRECT_JK_ADVANTAGE}
                    </b>
                  </a> */}
              </>
            )}
          </div>
        ))}
      </>
    );
  }
}

export default PrimaryTruckBusPointsReports;
