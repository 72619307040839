import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

class AlertModal extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // this.state.modal = nextProps.show;
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    let footerButton = null;
    if (Array.isArray(this.props.buttons)) {
      footerButton = this.props.buttons.map(button => (
        <Button
          key={button.title}
          color={button.color}
          onClick={button.onClick}
        >{button.title}
        </Button>
      ));
    }
    return (
      <div>
        {/* <Button color="danger" onClick={this.toggle}>{this.props.buttonLabel}</Button> */}
        <Modal isOpen={this.props.show} toggle={this.closeModal} className={this.props.className} centered>
          <ModalHeader toggle={this.closeModal}>{this.props.title}</ModalHeader>
          <ModalBody>
            <div style={{ textAlign: 'center' }}>
              {this.props.msg}
              {/* {this.props.child} */}
            </div>
            {/* <div style={{ textAlign: 'center' }}>
              <Button
              onClick={this.closeModal}
              color="primary"
               style={{ marginTop: 20 }}>OK</Button>
            </div> */}

          </ModalBody>
          {footerButton &&
            <ModalFooter>
              {footerButton}
              {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
              <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
            </ModalFooter>}
        </Modal>
      </div>
    );
  }
}

AlertModal.propTypes = {
  buttons: PropTypes.any,
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  msg: PropTypes.string.isRequired,
  className: PropTypes.any,
};

export default AlertModal;
