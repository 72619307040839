import React from "react";
import "./ComparisonMonth.css";
import { Row, Col } from "reactstrap";

const monthList = [
  { s: "Jan", l: "Janauary" },
  { s: "Feb", l: "February" },
  { s: "Mar", l: "March" },
  { s: "Apr", l: "April" },
  { s: "May", l: "May" },
  { s: "Jun", l: "June" },
  { s: "Jul", l: "July" },
  { s: "Aug", l: "August" },
  { s: "Sep", l: "Sepetember" },
  { s: "Oct", l: "October" },
  { s: "Nov", l: "November" },
  { s: "Dec", l: "December" },
];

const ComparisonMonth = ({
  cmpMonth,
  setCmpMonth,
  handleChangeCmpMonth,
  getCmpMonthData,
}) => {
  return (
    <div className=" flex align-items-center ComparisonMonth">
      <div className="radioInnerTitle mb-3">Select Month for comparison</div>
      <Row className="yearWrap">
        <Col className="mb-2 ">
          <label>Select Year 1</label>
          <select
            className="form-control select"
            name="first_year"
            value={cmpMonth.first_year || ""}
            onChange={handleChangeCmpMonth}
          >
            <option value="">
              Select Year
            </option>
            <option value="2019">FY 19-20</option>
            <option value="2020">FY 20-21</option>
            <option value="2021">FY 21-22</option>
            <option value="2022">FY 22-23</option>
          </select>
        </Col>

        <Col className="mb-2 ">
          <label>Select Year 2</label>
          <select
            className="form-control select"
            name="sec_year"
            value={cmpMonth.sec_year || ""}
            onChange={handleChangeCmpMonth}
          >
            <option value="">
              Select Year
            </option>
            <option value="2019">FY 19-20</option>
            <option value="2020">FY 20-21</option>
            <option value="2021">FY 21-22</option>
            <option value="2022">FY 22-23</option>
          </select>
        </Col>
        <Col className="mb-2 ">
          <label>Select Month1</label>
          <select
            className="form-control select"
            name="first_month"
            value={cmpMonth.first_month || ""}
            onChange={handleChangeCmpMonth}
          >
            <option value="">
              Select Month
            </option>
            {monthList.map((item, index) => (
              <option key={item.s} value={item.s}>
                {item.l}
              </option>
            ))}
          </select>
        </Col>
        <Col className="mb-2 ">
          <label>Select Month 2</label>

          <select
            className="form-control select"
            name="sec_month"
            value={cmpMonth.sec_month || ""}
            onChange={handleChangeCmpMonth}
          >
            <option value="">
              Select Month
            </option>
            {monthList.map((item, index) => (
              <option key={item.s} value={item.s}>
                {item.l}
              </option>
            ))}
          </select>
        </Col>
        {/* <button
          type="button"
          className="btn btn-primary btn-sm mt-1"
          onClick={getCmpMonthData}
        >
          Get Data
        </button> */}
      </Row>
    </div>
  );
};

export default ComparisonMonth;
