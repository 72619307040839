import React, { Component } from 'react';
import CipDealerDetail from '../../components/CipDealerDetail/CipDealerDetail';
import {
  Row, Col,
} from 'reactstrap';

class CipRegitration extends Component {
  backToCipSummary = () => {
    //this.props.history.push('/cip-summary');
    this.goToCipSummary()
  }

  goToCipSummary = () => {
    this.props.history.push({
      pathname: "/cip-summary",
      isDisplayAddParticipantPopover: true
    });
  }
  render() {
    return (
      <div>
        {/* <div className="page-header">CIP Registration</div> */}
        <div className="d-flex-row mt-2">
          <span onClick={() => this.backToCipSummary()} className="Merchandise-catalogu-link cursor-pointer" style={{ fontSize: '16px' }}>CIP Summary</span>&nbsp;&nbsp;
          <img src="images/right.svg" alt="" />&nbsp;&nbsp;
          <span className="cart-lbl" style={{ fontSize: '24px', width: 'auto' }}>CIP Registration</span>
        </div>
        <Row>
          <Col xl="9" md="12" xs="12">
            <CipDealerDetail
              goToCipSummary={this.goToCipSummary} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default CipRegitration;