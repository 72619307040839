import React, { Component, Fragment } from 'react';
import AboutComponent from '../../components/about/AboutComponent';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

class About extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      pcrType: query.get('customer_class_type') || query.get('dealer_type'),
      type: query.get('type')
    };
  }
  componentDidMount() {
    GoogleAnalticsService.pageView('About Programme View');
  }

  render() {
    return (
      <Fragment>
        <AboutComponent pcrType={this.state.pcrType} type={this.state.type} />
      </Fragment>
    );
  }
}

export default About;