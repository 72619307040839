import React, { Component, Fragment } from "react";
import {
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  CardBody
} from "reactstrap";
import lodash from "lodash"; // library added for debounce functionality
import swal from "sweetalert";
import Ionicon from "react-ionicons";
import "./EditGroupDetail.css";
import Pagination from "../Pagination/Pagination";
import CustomSpinner from "../CustomSpinner/CustomSpinner";

import Validation from "../../services/Validation";
import CustomerManagementService from "../../services/CustomerManagementService";
import ModalService from "../../services/ModalService";

import ConfirmModal from "../../modal/ConfirmModal/ConfirmModal";

import { messages } from "../../constants/constant.message";
const { ERROR_MESSAGE } = messages.COMMON;
const pageSize = 10;

class EditGroupDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: null,
      isSelectAllEntry: false,
      selectAll: 0,
      selectAllCustomer: 0,
      isSelectAllEntryCustomer: false,
      controls: {
        group_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        search_customer_from_group: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        search_customer_from_all: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      },
      GroupManagementFilterStates: props.filter ? props.filter : null,
      filterApplied: false,
      groupCustomerDataNotEmpty: false,
      isAddMember: false,
      groupCustomerPage: 1,
      groupCustomers: [],
      groupCustomersCount: 0,
      groupCustomersTotalPages: 0,
      allCustomerPage: 1,
      allCustomers: [],
      allCustomersTotalPages: 0,
      allCustomersCount: 0,
      isAddCustomerLoading: false,
      selectedCustomerToAdd: [],
      isGroupCustomerLoading: false,
      selectedCustomerToDelete: [],
      isSelectAllCustomerToAddChecked: false,
      isSelectAllCustomerToDeleteChecked: false,
      isDeleteCustomerModalOpen: false
    };
    this.searchAllCustomer = lodash.debounce(this.searchAllCustomer, 500);
    this.searchGroupCustomer = lodash.debounce(this.searchGroupCustomer, 500);
  }

  componentDidMount() {
    this.getGroupCustomers();
    const { groupData } = this.props;
    const { controls } = this.state;
    const { group_name } = controls;
    group_name.value = groupData.group_name;
    // this.setState({
    //   filterApplied: false,
    //   filterControls: {}
    // });
  }

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { group_name } = controls;
    if (group_name.touched === true || isSubmit) {
      group_name = Validation.notNullValidator(group_name);
      group_name.valid = !group_name.nullValue;
      if (group_name.valid === false) {
        group_name.showErrorMsg = true;
      } else {
        group_name.showErrorMsg = false;
      }
    }
    if (group_name.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleInputChange = e => {
    /**Common method for handleChange search bar */
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    if (controlName === "search_customer_from_all") {
      this.searchAllCustomer(controlValue);
    }
    if (controlName === "search_customer_from_group") {
      this.searchGroupCustomer(controlValue);
    }
    this.handleValidation();
  };

  handleOnCancel = () => {
    this.props.onCloseSidebar(false);
  };

  openAddMemberPage = () => {
    this.setState({
      isAddMember: true,
      groupCustomerPage: 1,
      groupCustomers: [],
      groupCustomersCount: 0,
      groupCustomersTotalPages: 0,
      allCustomerPage: 1,
      allCustomers: [],
      allCustomersTotalPages: 0,
      allCustomersCount: 0,
      isAddCustomerLoading: false,
      selectedCustomerToAdd: [],
      isGroupCustomerLoading: false,
      selectedCustomerToDelete: [],
      isSelectAllCustomerToAddChecked: false,
      isSelectAllCustomerToDeleteChecked: false
    });
    this.getCustomers();
  };

  closeAddMemberPage = () => {
    this.setState({ isAddMember: false, selectedCustomerToAdd: [] });
  };

  checkboxSelectCustomerToAdd = index => {
    let { allCustomers, selectedCustomerToAdd } = this.state;
    const customer = allCustomers[index];
    customer.isChecked = !customer.isChecked;
    const i = selectedCustomerToAdd.indexOf(customer.customer_id);
    if (customer.isChecked === true && i === -1) {
      selectedCustomerToAdd.push(customer.customer_id);
    } else if (customer.isChecked === false) {
      selectedCustomerToAdd.splice(i, 1);
    }
    if (selectedCustomerToAdd.length > 0) {
      let index = allCustomers.findIndex(ac =>
        selectedCustomerToAdd.includes(ac.customer_id)
      );
      if (index > -1) {
        this.setState({ isSelectAllCustomerToAddChecked: true });
      } else {
        this.setState({ isSelectAllCustomerToAddChecked: false });
      }
    } else {
      this.setState({ isSelectAllCustomerToAddChecked: false });
    }
    this.setState({ allCustomers, selectedCustomerToAdd });
  };

  checkboxSelectCustomerToDelete = index => {
    let { groupCustomers, selectedCustomerToDelete } = this.state;
    const customer = groupCustomers[index];
    customer.isChecked = !customer.isChecked;
    const i = selectedCustomerToDelete.indexOf(customer.customer_id);
    if (customer.isChecked === true && i === -1) {
      selectedCustomerToDelete.push(customer.customer_id);
    } else if (customer.isChecked === false) {
      selectedCustomerToDelete.splice(i, 1);
    }

    if (selectedCustomerToDelete.length > 0) {
      let index = groupCustomers.findIndex(ac =>
        selectedCustomerToDelete.includes(ac.customer_id)
      );
      if (index > -1) {
        this.setState({ isSelectAllCustomerToDeleteChecked: true });
      } else {
        this.setState({ isSelectAllCustomerToDeleteChecked: false });
      }
    } else {
      this.setState({ isSelectAllCustomerToDeleteChecked: false });
    }

    this.setState({ groupCustomers, selectedCustomerToDelete });
  };

  checkboxSelectAllCustomerToAdd = () => {
    let {
      allCustomers,
      selectedCustomerToAdd,
      isSelectAllCustomerToAddChecked
    } = this.state;
    isSelectAllCustomerToAddChecked = !isSelectAllCustomerToAddChecked;
    if (isSelectAllCustomerToAddChecked) {
      for (let i = 0; i < allCustomers.length; i++) {
        let customer = allCustomers[i];
        customer.isChecked = true;
        selectedCustomerToAdd.push(customer.customer_id);
      }
    } else {
      let allCustomerSet = new Set(selectedCustomerToAdd);
      for (let i = 0; i < allCustomers.length; i++) {
        let customer = allCustomers[i];
        customer.isChecked = false;
        allCustomerSet.delete(customer.customer_id);
      }
      selectedCustomerToAdd = Array.from(allCustomerSet);
    }
    this.setState({
      allCustomers,
      selectedCustomerToAdd,
      isSelectAllCustomerToAddChecked
    });
  };

  checkboxSelectAllCustomerToDelete = () => {
    let {
      groupCustomers,
      selectedCustomerToDelete,
      isSelectAllCustomerToDeleteChecked
    } = this.state;
    isSelectAllCustomerToDeleteChecked = !isSelectAllCustomerToDeleteChecked;
    if (isSelectAllCustomerToDeleteChecked) {
      for (let i = 0; i < groupCustomers.length; i++) {
        let customer = groupCustomers[i];
        customer.isChecked = true;
        selectedCustomerToDelete.push(customer.customer_id);
      }
    } else {
      let groupCustomerset = new Set(selectedCustomerToDelete);
      for (let i = 0; i < groupCustomers.length; i++) {
        let customer = groupCustomers[i];
        customer.isChecked = false;
        groupCustomerset.delete(customer.customer_id);
      }
      selectedCustomerToDelete = Array.from(groupCustomerset);
    }
    this.setState({
      groupCustomers,
      selectedCustomerToDelete,
      isSelectAllCustomerToDeleteChecked
    });
  };

  searchGroupCustomer = search => {
    this.setState({ groupCustomerPage: 1 });
    this.getGroupCustomers(1, search);
  };

  searchAllCustomer = search => {
    this.setState(
      { allCustomerPage: 1, allCustomers: [], search_query: search },
      () => {
        this.getAllCustomers(1, search);
      }
    );
  };

  handlePageChangeAllCustomer = async page => {
    await this.setState({
      allCustomerPage: page,
      isSelectAllCustomerToAddChecked: false
    });
    this.getAllCustomers();
  };

  handlePageChangeGroupCustomer = page => {
    const { search_customer_from_group } = this.state.controls;
    this.setState({
      groupCustomerPage: page,
      isSelectAllCustomerToAddChecked: false
    });
    this.getGroupCustomers(page, search_customer_from_group.value);
  };

  addCustomerToGroup = () => {
    const { controls } = this.state;
    const { search_customer_from_group, search_customer_from_all } = controls;
    let customerToAdd = this.state.selectedCustomerToAdd;
    const { groupData } = this.props;
    if (this.state.selectedCustomerToAdd.length > 0) {
      let obj = {
        vehicle_type: "",
        created_at: "",
        created_till: "",
        group_name: groupData.group_name,
        customers: customerToAdd
      };

      if (this.state.selectAllCustomer === 1 && this.state.isSelectAllEntryCustomer) {
        obj.customers = [];
      }
      this.setState({ isAddCustomerLoading: true });
      CustomerManagementService.addCustomerToGroup(obj)
        .then(data => {
          this.setState({ selectedCustomerToAdd: [] }, () => {
            this.getCustomers(1, null);
          });
          this.getGroupCustomers(1, null);
          this.props.reloadGroups();
          search_customer_from_all.value = "";
          search_customer_from_group.value = "";
          this.setState({
            groupCustomerPage: 1,
            controls,
            isAddMember: false,
            isAddCustomerLoading: false
          });
          if (data.data.message) {
            swal({
              title: "Congratulations !",
              text: data.data.message,
              icon: "success",
              type: "success"
            }).then(value => {
              this.props.onCloseSidebar(false);
            });
          }
        })
        .catch(e => {
          this.setState({ isAddCustomerLoading: false });
        });
    } else {
      this.editGroup();
    }
  };

  getGroupCustomers = (page = 1, search = null) => {
    const { groupData } = this.props;
    let {
      groupCustomers,
      groupCustomersCount,
      groupCustomersTotalPages,
      selectedCustomerToDelete,
      // isSelectAllCustomerToDeleteChecked
    } = this.state;
    this.setState({ isGroupCustomerLoading: true });
    CustomerManagementService.getGroupCustomers(
      page,
      pageSize,
      groupData.group_id,
      false,
      search
    )
      .then(res => {
        this.setState({ isGroupCustomerLoading: false });

        if (
          res.data.data &&
          res.data.data.customers &&
          res.data.data.customers.length > 0
        ) {
          groupCustomers = res.data.data.customers;
          groupCustomersCount = res.data.data.count;
          groupCustomersTotalPages = Math.ceil(groupCustomersCount / pageSize);

          if (selectedCustomerToDelete.length > 0) {
            let index = groupCustomers.findIndex(ac =>
              selectedCustomerToDelete.includes(ac.customer_id)
            );

            if (index > -1) {
              this.setState({ isSelectAllCustomerToDeleteChecked: true });
            } else {
              this.setState({ isSelectAllCustomerToDeleteChecked: false });
            }
            let selectedCutomerSet = new Set(selectedCustomerToDelete);
            for (let i = 0; i < groupCustomers.length; i++) {
              if (selectedCutomerSet.has(groupCustomers[i].customer_id)) {
                groupCustomers[i].isChecked = true;
              }
            }
          } else {
            this.setState({ isSelectAllCustomerToDeleteChecked: false });
          }
          this.setState({
            groupCustomerDataNotEmpty: true
          });
        } else {
          groupCustomers = [];
          groupCustomersCount = 0;
          groupCustomersTotalPages = 1;
          if (!search) {
            this.getAllCustomers();
            this.setState({
              groupCustomerDataNotEmpty: false
            });
          }
        }
        this.setState({
          groupCustomers,
          groupCustomersCount,
          groupCustomersTotalPages
        });
      })
      .catch(e => {
        this.setState({ isGroupCustomerLoading: false });
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        //this.setState({ isLoading: false });
      });
  };

  getCustomers = (page = 1, search = null) => {
    let {
      allCustomers,
      allCustomersCount,
      allCustomersTotalPages,
      selectedCustomerToAdd
    } = this.state;
    const { groupData } = this.props;
    this.setState({ isAddCustomerLoading: true });
    CustomerManagementService.getCustomers(
      page,
      pageSize,
      groupData.group_id,
      search
    )
      .then(data => {
        this.setState({ isAddCustomerLoading: false });
        if (
          data.data.data &&
          data.data.data.customers &&
          data.data.data.customers.length > 0
        ) {
          allCustomers = [];
          allCustomers = data.data.data.customers;
          allCustomersCount = data.data.data.count;
          allCustomersTotalPages = Math.ceil(allCustomersCount / pageSize);
          if (selectedCustomerToAdd.length > 0) {
            let index = allCustomers.findIndex(ac =>
              selectedCustomerToAdd.includes(ac.customer_id)
            );
            if (index > -1) {
              this.setState({ isSelectAllCustomerToAddChecked: true });
            } else {
              this.setState({ isSelectAllCustomerToAddChecked: false });
            }
            let selectedCutomerSet = new Set(selectedCustomerToAdd);
            for (let i = 0; i < allCustomers.length; i++) {
              if (selectedCutomerSet.has(allCustomers[i].customer_id)) {
                allCustomers[i].isChecked = true;
              }
            }
          } else {
            this.setState({ isSelectAllCustomerToAddChecked: false });
          }
        } else {
          allCustomers = [];
          allCustomersCount = 0;
          allCustomersTotalPages = 1;
        }

        this.setState({
          allCustomers,
          allCustomersCount,
          allCustomersTotalPages
        });
      })
      .catch(e => {
        this.setState({ isAddCustomerLoading: false });
      });
  };

  editGroup = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    let { controls } = this.state;
    let { groupData } = this.props;

    let reqData = {
      name: controls.group_name.value,
      group_id: groupData.group_id
    };
    this.setState({ isGroupCustomerLoading: true });

    CustomerManagementService.editGroup(reqData)
      .then(res => {
        // this.isDataLoading(false);
        this.props.reloadGroups();
        this.setState({ isGroupCustomerLoading: false });
        if (res.data.message) {
          swal({
            title: "Congratulations !",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => {
            this.props.onCloseSidebar(true);
          });
        }
      })
      .catch(e => {
        // this.isDataLoading(false);
        this.setState({ isGroupCustomerLoading: false });
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        //this.setState({ isLoading: false });
      });
  };

  deleteGroupCustomer = selectedCustomerToDelete => {
    let { groupData } = this.props;
    const { controls } = this.state;
    const { search_customer_from_group } = controls;
    let obj = {
      customers: selectedCustomerToDelete,
      group_id: groupData.group_id
    };

    if (this.state.selectAll === 1 && this.state.isSelectAllEntry) {
      obj.customers = [];
    }

    this.setState({ isGroupCustomerLoading: true });

    CustomerManagementService.deleteGroupCustomer(obj)
      .then(data => {
        this.setState({ selectedCustomerToDelete: [] }, () => {
          this.getGroupCustomers(1, search_customer_from_group.value);
        });
        this.props.reloadGroups();
        this.setState({
          groupCustomerPage: 1,
          isGroupCustomerLoading: false,
          isDeleteCustomerModalOpen: false,
          groupCustomerDataNotEmpty: true
        });
        if (data.data.message) {
          swal({
            title: "Congratulations !",
            text: data.data.message,
            icon: "success",
            type: "success"
          }).then(value => {
            this.props.onCloseSidebar(false);
          });
        }
      })
      .catch(e => {
        this.setState({ isGroupCustomerLoading: false });
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
      });
  };

  openDeleteCustomerModal = () => {
    //this.setState({ isDeleteCustomerModalOpen: true });
    swal({
      title: "Remove Group Customer",
      text: "Are you sure you want to remove customer from group?",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.deleteGroupCustomer(this.state.selectedCustomerToDelete);
      }
    });
  };
  closeDeleteCustomerModal = () => {
    this.setState({ isDeleteCustomerModalOpen: false });
  };

  // ================================================ START =========================================

  componentWillReceiveProps(props) {
    if (props.filter && !Object.is(props.filter, this.state.GroupManagementFilterStates)) {
      this.setState({
        GroupManagementFilterStates: props.filter,
        groupCustomerPage: 1,
        filterApplied: true,
        selected: [],
        selectAll: 0,
        allCustomers: [],
        selectedCustomerToAdd: [],
        groupCustomersCount: 0,
        pageSize: 10,
        groupCustomersTotalPages: 0,
        isSelectAllEntry: false,
        isSelectAllCustomerToAddChecked: false
      }, () => {
        this.getAllCustomers();
      });
    } else if (this.state.GroupManagementFilterStates && !props.filter) {
      // when filter is reset
      this.setState({
        GroupManagementFilterStates: null,
        groupCustomerPage: 1,
        filterApplied: false,
        selected: [],
        selectAll: 0,
        allCustomers: [],
        selectedCustomerToAdd: [],
        groupCustomersCount: 0,
        pageSize: 10,
        groupCustomersTotalPages: 0,
        isSelectAllEntry: false,
        isSelectAllCustomerToAddChecked: false
      }, () => {
        this.getAllCustomers();
      });
    }

    // if (props.filter) {
    //   this.setState({
    //     GroupManagementFilterStates:props.filter?props.filter:null,
    //     page: 1,
    //     filterApplied: true,
    //     selected: [],
    //     allCustomers: [],
    //     selectAll: 0,
    //     selectedCustomerToAdd: [],
    //     groupCustomersCount: 0,
    //     pageSize: 10,
    //     groupCustomersTotalPages: 0,
    //     isSelectAllEntry: false,
    //     isSelectAllCustomerToAddChecked: false
    //   });
    //   setTimeout(() => {
    //     this.getAllCustomers();
    //   }, 1);
    // }
  }

  getFilterReqData = filter => {
    if (filter && Object.keys(filter).length <= 0) {
      return {};
    }

    let reqData = {};
    if (filter && Object.keys(filter).length > 0) {
      if (filter.from_date && filter.from_date.value) {
        let from_date = new Date(filter.from_date.value);
        from_date.setHours(0);
        from_date.setMinutes(0);
        reqData.from_date = from_date;
      }
      if (filter.to_date && filter.to_date.value) {
        let to_date = new Date(filter.to_date.value);
        to_date.setHours(23);
        to_date.setMinutes(59);
        reqData.to_date = to_date;
      }
      if (filter.vehicle_type_id && filter.vehicle_type_id.value) {
        reqData.vehicle_type_id = filter.vehicle_type_id.value;
      }
      if (
        filter.vehicle_type_brand_id &&
        filter.vehicle_type_brand_id.value.brand_id
      ) {
        reqData.vehicle_type_brand_id =
          filter.vehicle_type_brand_id.value.brand_id;
      }
      if (
        filter.vehicle_type_brand_model_id &&
        filter.vehicle_type_brand_model_id.value
      ) {
        reqData.vehicle_type_brand_model_id =
          filter.vehicle_type_brand_model_id.value.model_id;
      }
      if (filter.occasion && filter.occasion.value) {
        reqData.occasion = filter.occasion.value;
      }

      if (filter.occasion_date_from && filter.occasion_date_from.value) {
        let occasion_date_from = new Date(filter.occasion_date_from.value);
        occasion_date_from.setHours(0);
        occasion_date_from.setMinutes(0);
        reqData.occasion_date_from = occasion_date_from;
      }

      if (filter.occasion_date_to && filter.occasion_date_to.value) {
        let date = new Date(filter.occasion_date_to.value);
        date.setHours(23);
        date.setMinutes(59);
        reqData.occasion_date_to = date;
      }
      if (filter.source_of_member && filter.source_of_member.value) {
        reqData.source_of_member = filter.source_of_member.value;
      }
      if (filter.group_id && filter.group_id.value) {
        reqData.group_id = filter.group_id.value;
      }
    }
    return reqData;
  };

  getAllCustomers = () => {
    let {
      allCustomers,
      allCustomersCount,
      allCustomersTotalPages,
      selectedCustomerToAdd,
      GroupManagementFilterStates
    } = this.state;
    this.setState({ isGroupCustomerLoading: true });
    // const reqData = this.getFilterReqData(this.state.filterControls);

    let reqData = {};

    if (GroupManagementFilterStates) {
      if (GroupManagementFilterStates.filter) {
        reqData = { ...reqData, ...GroupManagementFilterStates.filter };
      }
    }
    reqData.excluding_group_id = this.props.groupData.group_id;
    reqData.page = this.state.allCustomerPage;
    reqData.pageSize = this.state.pageSize;
    reqData.fetch_customer_list = false;
    reqData.excluding_group_id = this.props.groupData.group_id;
    reqData.search_query = this.state.search_query
      ? this.state.search_query
      : "";
    CustomerManagementService.getCustomers(reqData)
      .then(res => {
        this.setState({ isGroupCustomerLoading: false });

        if (
          res.data.data &&
          res.data.data.customers &&
          res.data.data.customers.length > 0
        ) {
          allCustomers = res.data.data.customers;
          allCustomersCount = res.data.data.count;
          allCustomersTotalPages = Math.ceil(allCustomersCount / pageSize);
          // this.setState({
          //   filterApplied: false
          // });
          if (selectedCustomerToAdd.length > 0) {
            let index = allCustomers.findIndex(ac =>
              selectedCustomerToAdd.includes(ac.customer_id)
            );
            if (index > -1) {
              this.setState({ isSelectAllCustomerToAddChecked: true });
            } else {
              this.setState({ isSelectAllCustomerToAddChecked: false });
            }
            let selectedCutomerSet = new Set(selectedCustomerToAdd);
            for (let i = 0; i < allCustomers.length; i++) {
              if (selectedCutomerSet.has(allCustomers[i].customer_id)) {
                allCustomers[i].isChecked = true;
              }
            }
          } else {
            this.setState({ isSelectAllCustomerToAddChecked: false });
          }
        } else {
          allCustomers = [];
          if (this.state.search_query !== "") {
            this.setState({
              filterApplied: true
            });
          }
          allCustomersCount = 0;
          allCustomersTotalPages = 1;
        }
        this.setState({
          allCustomers,
          allCustomersCount,
          allCustomersTotalPages
        });
      })
      .catch(e => {
        this.setState({ isGroupCustomerLoading: false });
        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        //this.setState({ isLoading: false });
      });
  };

  sidebarOpenGroupFilter = () => {
    this.props.sidebarOpenGroupFilter(true, this.state.GroupManagementFilterStates);
  };

  addNewCustomer = () => {
    this.setState(
      {
        groupCustomerDataNotEmpty: false
      },
      () => {
        this.getAllCustomers();
      }
    );
  };

  toggleSelectAllCustomer() {
    let { selectedCustomerToAdd, allCustomers } = this.state;
    if (this.state.selectAllCustomer === 0 || this.state.selectAllCustomer === 2) {
      //selected = [];
      allCustomers.forEach(x => {
        x.isChecked = true;
        if (selectedCustomerToAdd.indexOf(x.customer_id) === -1) {
          selectedCustomerToAdd.push(x.customer_id);
          // phone_numberList.push(x.phone_number);
        }
      });
      if (this.state.selectAllCustomer === 2) {
        this.state.selectAll = 0;
      }
    } else if (this.state.selectAllCustomer === 1) {
      if (this.state.isSelectAllEntryCustomer) {
        selectedCustomerToAdd = [];
        allCustomers.forEach(x => {
          x.isChecked = false;
        });
      } else {
        allCustomers.forEach(x => {
          selectedCustomerToAdd.forEach((element, i) => {
            if (element === x.customer_id) {
              x.isChecked = false;
              selectedCustomerToAdd.splice(i, 1);
            }
          });
        });
      }
    }

    this.setState({
      selectedCustomerToAdd,
      selectAllCustomer: this.state.selectAllCustomer === 0 ? 1 : 0,
      isSelectAllEntryCustomer: this.state.allCustomersCount <= 10 ? true : false
    });
  }

  toggleSelectAll() {
    // groupCustomers,
    //   selectedCustomerToDelete,
    //   isSelectAllCustomerToDeleteChecked
    let { groupCustomers, selectedCustomerToDelete } = this.state;
    /**Customers are selected or added  */
    if (this.state.selectAll === 0 || this.state.selectAll === 2) {
      //selectedCustomerToDelete = [];
      groupCustomers.forEach(x => {
        x.isChecked = true;
        if (selectedCustomerToDelete.indexOf(x.customer_id) === -1) {
          selectedCustomerToDelete.push(x.customer_id);
        }
      });
      if (this.state.selectAll === 2) {
        this.state.selectAll = 0;
      }
    } else if (this.state.selectAll === 1) {
      /**Customers are not selected */
      if (this.state.isSelectAllEntry) {
        selectedCustomerToDelete = [];
        groupCustomers.forEach(x => {
          x.isChecked = false;
        });
      } else {
        groupCustomers.forEach(x => {
          selectedCustomerToDelete.forEach((element, i) => {
            if (element === x.customer_id) {
              x.isChecked = false;
              selectedCustomerToDelete.splice(i, 1);
            }
          });
        });
      }
    }

    this.setState({
      selectedCustomerToDelete,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
      isSelectAllEntry: this.state.groupCustomersCount <= 10 ? true : false
    });
  }

  checkSelectAll = () => {
    let { selectedCustomerToDelete, groupCustomers } = this.state;
    let selectAll = 0;
    let perPageCountSelection = 0;
    if (selectedCustomerToDelete.length > 0) {
      selectedCustomerToDelete.forEach(id => {
        let index = groupCustomers.findIndex(x => x.customer_id === id);
        if (index > -1) {
          perPageCountSelection++;
        }
      });
      if (perPageCountSelection === groupCustomers.length) {
        selectAll = 1;
      } else if (perPageCountSelection === 0) {
        selectAll = 0;
      } else {
        selectAll = 2;
      }
    } else {
      selectAll = 0;
    }
    return selectAll;
  };

  toggleRow = (id) => {
    let { selectedCustomerToDelete, groupCustomers } = this.state;
    let index = selectedCustomerToDelete.findIndex(x => x === id);
    let indexData = groupCustomers.findIndex(x => x.customer_id === id);
    if (index > -1) {
      selectedCustomerToDelete.splice(index, 1);
      groupCustomers[indexData].isChecked = false;
    } else {
      if (selectedCustomerToDelete.indexOf(id) === -1) {
        selectedCustomerToDelete.push(id);
      }
      groupCustomers[indexData].isChecked = true;
    }
    let selectAll = this.checkSelectAll();
    this.setState({
      selectedCustomerToDelete,
      selectAll: selectAll,
      groupCustomers,
      isSelectAllEntry: false
    });
  };

  checkSelectAllAddCustomer = () => {
    let { selectedCustomerToAdd, allCustomers } = this.state;
    let selectAll = 0;
    let perPageCountSelection = 0;
    if (selectedCustomerToAdd.length > 0) {
      selectedCustomerToAdd.forEach(id => {
        let index = allCustomers.findIndex(x => x.customer_id === id);
        if (index > -1) {
          perPageCountSelection++;
        }
      });
      if (perPageCountSelection === allCustomers.length) {
        selectAll = 1;
      } else if (perPageCountSelection === 0) {
        selectAll = 0;
      } else {
        selectAll = 2;
      }
    } else {
      selectAll = 0;
    }
    return selectAll;
  };

  toggleRowAddCustomer = (id) => {
    let { selectedCustomerToAdd, allCustomers } = this.state;
    let index = selectedCustomerToAdd.findIndex(x => x === id);
    let indexData = allCustomers.findIndex(x => x.customer_id === id);
    if (index > -1) {
      selectedCustomerToAdd.splice(index, 1);
      allCustomers[indexData].isChecked = false;
    } else {
      if (selectedCustomerToAdd.indexOf(id) === -1) {
        selectedCustomerToAdd.push(id);
      }
      allCustomers[indexData].isChecked = true;
    }
    let selectAllCustomer = this.checkSelectAllAddCustomer();
    this.setState({
      selectedCustomerToAdd,
      selectAll: selectAllCustomer,
      allCustomers,
      isSelectAllEntryCustomer: false
    });
  };

  selectAllEntry = isSelectAllEntry => {
    let { selectedCustomerToDelete, groupCustomers } = this.state;
    if (isSelectAllEntry) {
      groupCustomers.forEach(x => {
        x.isChecked = true;
        if (selectedCustomerToDelete.indexOf(x.customer_id) === -1) {
          selectedCustomerToDelete.push(x.customer_id);
        }
      });
      this.setState(prevState => {
        return {
          ...prevState,
          isSelectAllEntry: isSelectAllEntry,
          selectAll: 1
        };
      });
    } else {
      groupCustomers.forEach(x => {
        x.isChecked = false;
      });
      this.setState(prevState => {
        return {
          ...prevState,
          isSelectAllEntry: isSelectAllEntry,
          SelectAll_customer: false,
          selectedCustomerToDelete: [],
          selectAll: 0,
          groupCustomers
        };
      });
    }
  };

  selectAllEntryCustomer = isSelectAllEntryCustomer => {
    let { selectedCustomerToAdd, allCustomers } = this.state;

    if (isSelectAllEntryCustomer) {
      allCustomers.forEach(x => {
        x.isChecked = true;
        if (selectedCustomerToAdd.indexOf(x.customer_id) === -1) {
          selectedCustomerToAdd.push(x.customer_id);
        }
      });
      this.setState(prevState => {
        return {
          ...prevState,
          isSelectAllEntryCustomer: isSelectAllEntryCustomer,
          selectAllCustomer: 1
        };
      });
    } else {
      allCustomers.forEach(x => {
        x.isChecked = false;
      });
      this.setState(prevState => {
        return {
          ...prevState,
          isSelectAllEntryCustomer: isSelectAllEntryCustomer,
          selectedCustomerToAdd: [],
          selectAllCustomer: 0,
          allCustomers
        };
      });
    }
  };

  // ================================================ END =========================================
  render() {
    const {
      controls,
      allCustomers,
      groupCustomers,
      selectedCustomerToAdd,
      selectedCustomerToDelete,
      groupCustomerPage,
      groupCustomersTotalPages,
      allCustomerPage,
      allCustomersTotalPages,
      groupCustomersCount,
      allCustomersCount,
      // isSelectAllCustomerToAddChecked,
      // isSelectAllCustomerToDeleteChecked,
      groupCustomerDataNotEmpty
    } = this.state;
    const { groupData } = this.props;
    const {
      group_name,
      search_customer_from_all,
      search_customer_from_group
    } = controls;

    let allCustomersRows = null;
    if (allCustomers.length > 0) {
      allCustomersRows = allCustomers.map((ac, index) => (
        <tr key={ac.customer_id}>
          <td>
            <label className="container-checkbox" style={{ float: "left" }}>
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                checked={ac.isChecked ? true : false}
                onChange={this.toggleRowAddCustomer.bind(this, ac.customer_id)}
              />{" "}
              <span
                className="checkmark"
                style={{ borderColor: "#fdbc39" }}
              ></span>
            </label>
            {ac.customer_name !== " " && ac.customer_name
              ? ac.customer_name
              : "No Name"}
          </td>
          <td>{ac.phone_number ? ac.phone_number : "--"}</td>
        </tr>
      ));
    } else {
      if (this.state.filterApplied) {
        allCustomersRows = (
          <tr>
            <td colSpan="4" className="text-align-center">
              We can not find any records matching your search criteria. Please
              change search criteria and try again.
            </td>
          </tr>
        );
      } else {
        allCustomersRows = (
          <tr>
            <td colSpan="4" className="text-align-center">
              There is no customer to add in group
            </td>
          </tr>
        );
      }
    }

    let customersRows = null;
    if (groupCustomers.length > 0) {
      customersRows = groupCustomers.map((c, index) => (
        <tr key={c.customer_id}>
          <td>
            <label className="container-checkbox" style={{ float: "left" }}>
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                checked={c.isChecked ? true : false}
                onChange={this.toggleRow.bind(this, c.customer_id)}
              />{" "}
              <span
                className="checkmark"
                style={{ borderColor: "#fdbc39" }}
              ></span>
            </label>

            {c.customer_name !== " " && c.customer_name
              ? c.customer_name
              : "No Name"}
          </td>
          <td>{c.phone_number ? c.phone_number : "--"}</td>
        </tr>
      ));
    } else {
      customersRows = (
        <tr>
          <td colSpan="4" className="text-align-center">
            There is no customer in group
          </td>
        </tr>
      );
    }
    return (
      <Fragment>
        {!groupCustomerDataNotEmpty ? (
          <Fragment>
            <ConfirmModal
              show={this.state.isDeleteCustomerModalOpen}
              closeModal={this.closeDeleteCustomerModal}
              title="Remove Customer"
              message="Are you sure you want to remove customer from group?"
              actionHandler={this.deleteGroupCustomer.bind(
                this,
                selectedCustomerToDelete
              )}
            ></ConfirmModal>
            <div>
              <CardBody>
                <Row>
                  <Col sm="10">
                    <span className="drawer-title">
                      EDIT {groupData.group_name}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </div>
            <hr style={{ border: "solid 1px #ced4da", marginTop: "0px" }} />
            <Fragment>
              {this.state.isGroupCustomerLoading && (
                <CustomSpinner></CustomSpinner>
              )}

              <Row
                style={{
                  marginLeft: "0",
                  marginRight: "0",
                  color: "#fdbb1f",
                  fontWeight: "bold"
                }}
              ></Row>
            </Fragment>
            {this.state.isAddMember && (
              <Fragment>
                {this.state.isAddCustomerLoading && (
                  <CustomSpinner></CustomSpinner>
                )}
                <Row
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                    color: "#fdbb1f",
                    fontWeight: "bold"
                  }}
                >
                  <Col>
                    <span
                      onClick={this.closeAddMemberPage}
                      className="cursor-pointer"
                    >
                      <Ionicon
                        icon="ios-arrow-back"
                        fontSize="19px"
                        color="#fdbb1f"
                        style={{ marginTop: "-2px" }}
                      />
                      Back
                    </span>
                  </Col>
                </Row>
              </Fragment>
            )}
            <Row style={{ fontSize: "12px" }} id="editGroupDetail">
              <Col style={{ marginTop: "-15px" }}>
                <CardBody
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    minHeight: "calc(86vh - 100px)",
                    maxHeight: "calc(86vh - 100px)"
                  }}
                >
                  <Row>
                    <Col xl="12" md="12" xs="12">
                      <FormGroup>
                        <Label for="common">
                          Group Name<span className="required-field">*</span>
                        </Label>

                        <Input
                          type="text"
                          className="group_name"
                          name="group_name"
                          autoComplete="nofill"
                          id="group_name"
                          value={controls.group_name.value}
                          onChange={this.handleInputChange}
                          placeholder="Type Here"
                        />
                        {group_name.showErrorMsg && (
                          <div className="error">*Please enter group name</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col
                      xl="12"
                      md="12"
                      xs="12"
                      style={{ marginBottom: "10px" }}
                    >
                      Please select customers which you want to add to this
                      group.
                    </Col>
                    <Col xl="12" md="12" xs="12">
                      <div
                        className="d-flex-row padding-bottom-25"
                      // style={{ paddingBottom: "8px" }}
                      >
                        <Input
                          type="text"
                          name="search_customer_from_all"
                          id="search_customer_from_all"
                          placeholder="Search by name or mobile"
                          className="login-input"
                          value={search_customer_from_all.value}
                          onChange={this.handleInputChange}
                        />
                        <img
                          src="images/search.svg"
                          alt=''
                          className="Group-3 icon-width"
                          style={{ cursor: "pointer" }}
                        />
                        <span
                          className="d-flex-row"
                          style={{ marginLeft: "15px" }}
                        >
                          {/* {
                            this.state.filterApplied === true
                             && (
                              <img
                                src="images/smaller-blue-dot-hi.png"
                                 alt=''
                                className="apply-filter-dot"
                                style={{ marginLeft: "32px" }}
                              />
                            )} */}
                          <img
                            src={this.state.filterApplied === true
                              ? "images/smaller-blue-dot-hi.png"
                              : "images/filter.svg"}
                            // srcSet="images/sidebar-icons/group-3@2x.png 2x,
                            //       images/sidebar-icons/group-3@3x.png 3x"
                            className={`cursor-pointer ${this.state.filterApplied === true
                              ? 'filter-icon-selected'
                              : 'filter-icon'}`
                            }
                            style={{ cursor: "pointer" }}
                            onClick={this.sidebarOpenGroupFilter}
                            alt=''
                          />
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {/* {selectedCustomerToAdd.length > 0 && (
                    <Col style={{ marginLeft: "-12px", marginTop: "-15px" }}>
                      <span
                        className="member-count"
                        onClick={this.GroupData_forEdit}
                        style={{
                          float: "left",
                          color: "black",
                          marginBottom: "8px"
                        }}
                      >
                        Selected Members:{" "}
                        <span>{selectedCustomerToAdd.length}</span>
                      </span>
                    </Col>
                  )} */}

                  {selectedCustomerToAdd.length > 0 ? (
                    <div className="entries-selected mb-4">
                      {allCustomerPage > 1 ? (
                        <div className="d-flex-row justify-content-between">
                          {pageSize < allCustomersCount &&
                            this.state.selectAllCustomer === 1 && (
                              <>
                                {!this.state.isSelectAllEntryCustomer ? (
                                  <span onClick={() => this.selectAllEntryCustomer(true)}>
                                    Select All {allCustomersCount} Entries.
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => this.selectAllEntryCustomer(false)}
                                  >
                                    Clear Selection.
                                  </span>
                                )}
                              </>
                            )}
                          <span className="color-757575 ml-auto">
                            {!this.state.isSelectAllEntryCustomer
                              ? selectedCustomerToAdd.length
                              : allCustomersCount}{" "}
                            {selectedCustomerToAdd.length > 1 ? "Entries" : "Entry"} Selected.
                          </span>
                        </div>
                      ) : (
                        <div className="d-flex-row justify-content-between">

                          {pageSize < allCustomersCount &&
                            this.state.selectAllCustomer === 1 && (
                              <>
                                {!this.state.isSelectAllEntryCustomer ? (
                                  <span onClick={() => this.selectAllEntryCustomer(true)}>
                                    Select all {allCustomersCount} entries.
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => this.selectAllEntryCustomer(false)}
                                  >
                                    Clear Selection.
                                  </span>
                                )}
                              </>
                            )}
                          <span className="color-757575 ml-auto">
                            {!this.state.isSelectAllEntryCustomer
                              ? selectedCustomerToAdd.length
                              : allCustomersCount}{" "}
                            {selectedCustomerToAdd.length > 1 ? "entries" : "entry"} selected.
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <table
                    style={{
                      width: "100%",
                      marginTop: "-8px"
                    }}
                  >
                    {this.state.isAddCustomerLoading && (
                      <CustomSpinner></CustomSpinner>
                    )}
                    <thead>
                      <tr>
                        <th>
                          {allCustomersCount > 0 && (
                            <>
                              {this.state.selectAllCustomer === 2 ? (
                                <img
                                  onClick={e => this.toggleSelectAllCustomer()}
                                  src="images/group-16.svg"
                                  alt=''
                                  style={{ height: "14px", paddingRight: '12px' }}
                                />
                              ) : this.state.selectAllCustomer === 1 ? (
                                <label
                                  className="container-checkbox"
                                  style={{ float: "left" }}
                                >
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox"
                                    checked={true}
                                    onChange={e => this.toggleSelectAllCustomer()}
                                  />{" "}
                                  <span
                                    className="checkmark"
                                    style={{ borderColor: "#fdbc39" }}
                                  ></span>
                                </label>
                              ) : (
                                <label
                                  className="container-checkbox"
                                  style={{ float: "left" }}
                                >
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox"
                                    checked={false}
                                    onChange={e => this.toggleSelectAllCustomer()}
                                  />{" "}
                                  <span
                                    className="checkmark"
                                    style={{ borderColor: "#fdbc39" }}
                                  ></span>
                                </label>
                              )}
                            </>
                          )}

                          Customer Name
                        </th>
                        <th>Mobile No.</th>
                      </tr>
                    </thead>
                    <tbody>{allCustomersRows}</tbody>
                  </table>
                  {allCustomersTotalPages > 1 && (
                    <div
                      style={{
                        float: "left",
                        marginTop: "12px"
                      }}
                    >
                      <Pagination
                        margin={2}
                        page={allCustomerPage}
                        count={allCustomersTotalPages}
                        pageSize={pageSize}
                        totalRecords={this.state.allCustomersCount}
                        onPageChange={this.handlePageChangeAllCustomer}
                      ></Pagination>
                    </div>
                  )}
                </CardBody>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Fragment>
            {/** When click on edit button by default this fragment opens*/}
            <ConfirmModal
              show={this.state.isDeleteCustomerModalOpen}
              closeModal={this.closeDeleteCustomerModal}
              title="Remove Customer"
              message="Are you sure you want to remove customer from group?"
              actionHandler={this.deleteGroupCustomer.bind(
                this,
                selectedCustomerToDelete
              )}
            ></ConfirmModal>
            <div>
              <CardBody>
                <Row>
                  <Col sm="10">
                    <span className="drawer-title">
                      EDIT {groupData.group_name}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </div>
            <hr style={{ border: "solid 1px #ced4da", marginTop: "0px" }} />
            {!this.state.isAddMember && (
              <Fragment>
                {this.state.isGroupCustomerLoading && (
                  <CustomSpinner></CustomSpinner>
                )}
                <Row
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                    color: "#fdbb1f",
                    fontWeight: "bold"
                  }}
                ></Row>
              </Fragment>
            )}
            <Fragment>
              {this.state.isAddCustomerLoading && (
                <CustomSpinner></CustomSpinner>
              )}
              <Row
                style={{
                  marginLeft: "0",
                  marginRight: "0",
                  color: "#fdbb1f",
                  fontWeight: "bold"
                }}
              ></Row>
            </Fragment>

            <Row style={{ fontSize: "12px" }} id="editGroupDetail">
              <Col style={{ marginTop: "-15px" }}>
                <CardBody
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    minHeight: "calc(86vh - 100px)",
                    maxHeight: "calc(86vh - 100px)"
                  }}
                >
                  <Row style={{ marginLeft: "-12px" }}>
                    <Col xl="12" md="12" xs="12">
                      <FormGroup>
                        <div className="f-flex-row">
                          <img
                            src="images/plus_blue.svg"
                            alt=''
                            //  onClick={this.addNewMember}
                            className="download-report-icon cursor-pointer margin-left-10"
                          />
                          &nbsp;&nbsp;
                          <span
                            className="link-warm-blue"
                            onClick={this.addNewCustomer}
                            style={{ cursor: "pointer" }}
                          >
                            Add New Customer
                          </span>
                          <span
                            className="member-count"
                            style={{ color: "black" }}
                          >
                            Total Members:{" "}
                            <span>{this.state.groupCustomersCount}</span>
                          </span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="12" md="12" xs="12">
                      <div
                        className="d-flex-row padding-bottom-25"
                      // style={{ paddingBottom: "8px" }}
                      >
                        <Input
                          type="text"
                          name="search_customer_from_group"
                          id="search_customer_from_group"
                          placeholder="Search by name or mobile"
                          className="login-input"
                          value={search_customer_from_group.value}
                          onChange={this.handleInputChange}
                        />
                        <img
                          src="images/search.svg"
                          alt=''
                          className="Group-3 icon-width"
                          style={{ cursor: "pointer" }}
                        />
                        {/** Button displayed only when group memeber is selected */}
                        {selectedCustomerToDelete.length > 0 && (
                          <span
                            className="delete"
                            onClick={this.openDeleteCustomerModal}
                          >
                            <img
                              src="images/delete.svg"
                              alt=''
                              className="Fill-3"
                            />
                            <span
                              style={{ marginLeft: "10px" }}
                              className="remove"
                            >
                              Remove
                            </span>
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {selectedCustomerToDelete.length > 0 ? (
                    <div className="entries-selected mb-4">
                      {groupCustomersTotalPages > 1 ? (
                        <div className="d-flex-row justify-content-between">

                          {pageSize < groupCustomersCount &&
                            this.state.selectAll === 1 && (
                              <>
                                {!this.state.isSelectAllEntry ? (
                                  <span onClick={() => this.selectAllEntry(true)}>
                                    Select All {groupCustomersCount} Entries.
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => this.selectAllEntry(false)}
                                  >
                                    Clear Selection.
                                  </span>
                                )}
                              </>
                            )}
                          <span className="color-757575 ml-auto">
                            {!this.state.isSelectAllEntry
                              ? selectedCustomerToDelete.length
                              : groupCustomersCount}{" "}
                            {selectedCustomerToDelete.length > 1 ? "Entries" : "Entry"} Selected.
                          </span>
                        </div>
                      ) : (
                        <div className="d-flex-row justify-content-between">

                          {pageSize < groupCustomersCount &&
                            this.state.selectAll === 1 && (
                              <>
                                {!this.state.isSelectAllEntry ? (
                                  <span onClick={() => this.selectAllEntry(true)}>
                                    Select all {groupCustomersCount} entries.
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => this.selectAllEntry(false)}
                                  >
                                    Clear Selection.
                                  </span>
                                )}
                              </>
                            )}
                          <span className="color-757575 ml-auto">
                            {!this.state.isSelectAllEntry
                              ? selectedCustomerToDelete.length
                              : groupCustomersCount}{" "}
                            {selectedCustomerToDelete.length > 1 ? "entries" : "entry"} selected.
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <table
                    style={{
                      width: "100%",
                      marginTop: "-8px"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>
                          {/* {groupCustomers.length > 0 && (
                            <label
                              className="container-checkbox"
                              style={{ float: "left" }}
                            >
                              <input
                                type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                checked={isSelectAllCustomerToDeleteChecked}
                                onChange={
                                  this.checkboxSelectAllCustomerToDelete
                                }
                              />{" "}
                              <span
                                className="checkmark"
                                style={{ borderColor: "#fdbc39" }}
                              ></span>
                            </label>
                          )} */}
                          {groupCustomersCount > 0 && (
                            <>
                              {this.state.selectAll === 2 ? (
                                <img
                                  onClick={e => this.toggleSelectAll()}
                                  src="images/group-16.svg"
                                  alt=''
                                  style={{ height: "14px", paddingRight: '12px' }}
                                />
                              ) : this.state.selectAll === 1 ? (
                                <label
                                  className="container-checkbox"
                                  style={{ float: "left" }}
                                >
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox"
                                    checked={true}
                                    onChange={e => this.toggleSelectAll()}
                                  />{" "}
                                  <span
                                    className="checkmark"
                                    style={{ borderColor: "#fdbc39" }}
                                  ></span>
                                </label>
                              ) : (
                                <label
                                  className="container-checkbox"
                                  style={{ float: "left" }}
                                >
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox"
                                    checked={false}
                                    onChange={e => this.toggleSelectAll()}
                                  />{" "}
                                  <span
                                    className="checkmark"
                                    style={{ borderColor: "#fdbc39" }}
                                  ></span>
                                </label>
                              )}
                            </>
                          )}
                          Customer Name
                        </th>
                        <th>Mobile No.</th>
                      </tr>
                    </thead>
                    <tbody>{customersRows}</tbody>
                  </table>
                  {groupCustomersTotalPages > 1 && (
                    <div
                      style={{
                        float: "left",
                        marginTop: "12px"
                      }}
                    >
                      <Pagination
                        margin={2}
                        page={groupCustomerPage}
                        count={groupCustomersTotalPages}
                        pageSize={pageSize}
                        totalRecords={groupCustomersCount}
                        onPageChange={this.handlePageChangeGroupCustomer}
                      ></Pagination>
                    </div>
                  )}
                </CardBody>
              </Col>
            </Row>
          </Fragment>
        )}
        {!groupCustomerDataNotEmpty ? (
          <Row className="add-member-sticky-button">
            <Col
              xl="12"
              xs="12"
              md="12"
              className="text-align-right footer-drawer"
            >
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                onClick={() => this.props.onCloseSidebar(false)}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-save-button"
                color="warning"
                onClick={this.addCustomerToGroup}
              >
                Save
              </Button>
              <br />
            </Col>
          </Row>
        ) : (
          <Row className="add-member-sticky-button">
            <Col
              xl="12"
              xs="12"
              md="12"
              className="text-align-right footer-drawer"
            >
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                onClick={() => this.props.onCloseSidebar(false)}
              >
                Back
              </Button>
              &nbsp;&nbsp;
              <br />
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default EditGroupDetail;
