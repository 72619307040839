import lodash from "lodash";
export const retailPhotoGraphDetail = {
  width: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  height: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  qty: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  totalArea: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  frontView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  leftView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  rightView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  closeupView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  longShotView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
};

export const retailPhotoGraphDetailInstallation = {
  frontView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  leftView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  rightView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  closeupView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  longShotView: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
};

export const retailPhotoGraphDetail_New = {
  image1: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  image2: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  image3: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
};

export const retailPhotoGraphDetailInstallation_New = {
  image1: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  image2: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  image3: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
};

export const retailPhotoGraphDetailSignage_New = {
  image1: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  image2: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  image3: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
};

export const retailPhotoGraphDetailInstallationSignage_New = {
  image1: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  image2: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  image3: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
};

export const filterData = {
  zone_id: {
    value: [],
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  region_id: {
    value: [],
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  area_id: {
    value: [],
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  state_id: {
    value: [],
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  city_id: {
    value: [],
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  created_at_start_date: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  created_at_end_date: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  recce_start_date: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  recce_end_date: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  installation_start_date: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  installation_end_date: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  club_name: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  status: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  vendor_id: {
    value: "",
    displayValue: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
};

export const RetailMarketingStoreInformationControls = {
  recce_date: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  installation_date: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  dealer: {
    value: "",
    displayValue: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  address: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  state: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  city: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  district: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  pincode: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  nameOnTheSignature: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  proposedSizeForNewSignature: [lodash.cloneDeep(retailPhotoGraphDetail)],
  proposedSizeForNewSignatureInstallation: [
    lodash.cloneDeep(retailPhotoGraphDetailInstallation),
  ],
  proposedSizeForNewSignature_New: [
    lodash.cloneDeep(retailPhotoGraphDetail_New),
  ],
  proposedSizeForNewSignatureInstallation_New: [
    lodash.cloneDeep(retailPhotoGraphDetailInstallation_New),
  ],

  proposedSizeForNewSignatureSignage_New: [
    lodash.cloneDeep(retailPhotoGraphDetailSignage_New),
  ],
  proposedSizeForNewSignatureInstallationSignage_New: [
    lodash.cloneDeep(retailPhotoGraphDetailInstallationSignage_New),
  ],

  acknowledgementForm: {
    value: "",
    localFileName: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  acknowledgementFormInstallation: {
    value: "",
    localFileName: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  remarks: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  remarksInstallation: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  horemarks: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  horemarkReject: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  horemarksApprove: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  sapid: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  imgwidth: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  imgheight: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
};

// export const RetailMarketingPhotographControls = {
//   nameOnTheSignature: {
//     value: "",
//     valid: null,
//     touched: false,
//     nullValue: null,
//     showErrorMsg: false,
//   },
//   proposedSizeForNewSignature: [photoGraphDetail],
// }
