import { request } from "./Request";
import { routes } from "../constants/constant.route";

const {
  GET_SERVICE,
  GET_PURPOSE,
  GET_CUSTOMER_DETAIL,
  ADD_CUSTOMER_WITH_VISIT_HISTORY,
  GET_CUSTOMER_WISE_TRADE_HISTORY,
  ADD_CUSTOMER_WITH_VISIT_HISTORY_INQUIRY,
  ADD_CUSTOMER_WITH_VISIT_HISTORY_SERVICE,
  ADD_CUSTOMER_WITH_VISIT_HISTORY_TYREPURCHASE,
  EXISTING_CUSTOMER_WITH_VISIT_HISTORY_INQUIRY,
  EXISITING_CUSTOMER_WITH_VISIT_HISTORY_SERVICE,
  EXISTING_CUSTOMER_WITH_VISIT_HISTORY_TYREPURCHASE,
  GET_BRANDS,
  GET_BRANDS_RADIAL,
  GET_DEALER_SERVICES,
  ADD_DEALER_RATING,
  ADD_REFF_CUSTOMER,
  SAVE_SERVICE,
  CLOSE_TYRE_PURCHASE_UPCOMING_DUE,
  GET_DEFAULT_DEALER_REFERAL_REWARD,
  SET_DEFAULT_DEALER_REFERAL_REWARD
} = routes.ADD_CUSTOMER_NEW;

const API_URL = process.env.REACT_APP_API_URL;

export default class AddCustomerService {
  static getService() {
    return request("GET", `${API_URL}${GET_SERVICE}`, null, null, null);
  }

  static getPurpose() {
    return request("GET", `${API_URL}${GET_PURPOSE}`, null, null, null);
  }

  static getCustomerDetail(params) {
    return request(
      "POST",
      `${API_URL}${GET_CUSTOMER_DETAIL}`,
      null,
      params,
      null
    );
  }

  static addCustomerWithVisitHistory() {
    return request(
      "POST",
      `${API_URL}${ADD_CUSTOMER_WITH_VISIT_HISTORY}`,
      null,
      null,
      null
    );
  }

  static getCustomerTradeHistory(prms) {
    return request(
      "POST",
      `${API_URL}${GET_CUSTOMER_WISE_TRADE_HISTORY}`,
      null,
      prms,
      null
    );
  }

  static SaveVehicleDetail(prms) {
    return request(
      "POST",
      `${API_URL}${ADD_CUSTOMER_WITH_VISIT_HISTORY_INQUIRY}`,
      null,
      prms,
      null
    );
  }

  static SaveVehicleDetailForService(prms) {
    return request(
      "POST",
      `${API_URL}${ADD_CUSTOMER_WITH_VISIT_HISTORY_SERVICE}`,
      null,
      prms,
      null
    );
  }

  static SaveVehicleDetailForTyrePurchase(prms) {
    return request(
      "POST",
      `${API_URL}${ADD_CUSTOMER_WITH_VISIT_HISTORY_TYREPURCHASE}`,
      null,
      prms,
      null
    );
  }

  static existingCustomerInquiryVisit(params) {
    return request(
      "POST",
      `${API_URL}${EXISTING_CUSTOMER_WITH_VISIT_HISTORY_INQUIRY}`,
      null,
      params,
      null
    );
  }

  static existingCustomerTyrePurchaseVisit(params) {
    return request(
      "POST",
      `${API_URL}${EXISTING_CUSTOMER_WITH_VISIT_HISTORY_TYREPURCHASE}`,
      null,
      params,
      null
    );
  }

  static existingCustomerServiceVisit(params) {
    return request(
      "POST",
      `${API_URL}${EXISITING_CUSTOMER_WITH_VISIT_HISTORY_SERVICE}`,
      null,
      params,
      null
    );
  }

  static getBrands() {
    return request("GET", `${API_URL}${GET_BRANDS}`, null, null, null);
  }

  static getBrandsRadial() {
    return request("GET", `${API_URL}${GET_BRANDS_RADIAL}`, null, null, null);
  }

  static getDealerServices(params) {
    return request(
      "POST",
      `${API_URL}${GET_DEALER_SERVICES}`,
      null,
      params,
      null
    );
  }

  static addDealerRating(params) {
    return request(
      "POST",
      `${API_URL}${ADD_DEALER_RATING}`,
      null,
      params,
      null
    );
  }


  static addReffCustomer(params) {
    return request(
      "POST",
      `${API_URL}${ADD_REFF_CUSTOMER}`,
      null,
      params,
      null
    );
  }


  static getCustomerVehicle(customer_id) {
    return request(
      "GET",
      `${API_URL}${routes.CUSTOMER_MGT.GET_CUSTOMER_VEHICLE}?customer_id=${customer_id}`,
      null,
      null,
      null
    );
  }

  static saveService(params) {
    return request(
      "POST",
      `${API_URL}${SAVE_SERVICE}`,
      null,
      params,
      null
    );
  }

  static closeTyrePurchase(params) {
    return request(
      "POST",
      `${API_URL}${CLOSE_TYRE_PURCHASE_UPCOMING_DUE}`,
      null,
      params,
      null
    );
  }

  static getDealerReferalReward() {
    return request("GET", `${API_URL}${GET_DEFAULT_DEALER_REFERAL_REWARD}`, null, null, null);
  }

  static setDealerReferalReward(data) {
    return request("POST", `${API_URL}${SET_DEFAULT_DEALER_REFERAL_REWARD}`, null, data, null);
  }

}
