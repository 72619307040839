import { request } from './Request';
import { routes } from '../constants/constant.route';
const { GET_SKU_LIST, ADD_SKU_DATA, UPDATE_PCR_SKU_STATUS, GET_PCR_SKU_HISTORY, DOWNLOAD_PCR_SKU_HISTORY } = routes.ADMINFUNCTIONALITY

const API_URL = process.env.REACT_APP_API_URL;

export default class AdminService {
  static getSkuListData(body) {
    return request("GET", `${API_URL}${GET_SKU_LIST}`, body, null, null);
  }
  static downloadSKUHistory () {
    return request("GET", `${API_URL}${DOWNLOAD_PCR_SKU_HISTORY}`, null, null, null);
  }
  static getSkuPcrHistoryData(body) {
    try {
      return request("GET", `${API_URL}${GET_PCR_SKU_HISTORY}`, body, null, null);
    } catch (error) {
      console.log(error, 'err')
    }
  }
  static saveSkuData(body) {
    return request("POST", `${API_URL}${ADD_SKU_DATA}`,null, body, null)
  }
  static updatePcrSkuStatus (body) {
    return request("POST", `${API_URL}${UPDATE_PCR_SKU_STATUS}`, null, body, null )
  }
}