import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import lodash from "lodash"; // library added for debounce functionality

// services
// import PrimaryPointsReportService from '../../services/PrimaryPointsReportService';
import UserService from "../../services/UserService";
import Storage from "../../services/Storage";

import "./AutoSuggestionDealer.css";
// import { Permissions } from "../../constants/constant.permissions";

class AutoSuggestionDealer extends Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      year: "",
      userRoleDetail: Storage.getUserDetail(),
    };
    this.searchReport = lodash.debounce(this.searchReport, 500);
  }

  componentWillReceiveProps(props) {
    if (props.year) {
      this.setState({
        year: props.year,
      });
    }
  }

  getAllDealerReport = (page, search) => {
    UserService.searchDealer(search)
      .then((data) => {
        const { data: dealers } = data.data;
        const suggestions = dealers;
        this.setState({ suggestions });
      })
      .catch((e) => { });
    //   PrimaryPointsReportService.getAllDealerReport(page, 10000000, search)
    //     .then(data => {
    //       const { dealer_card } = data.data.data;
    //       const suggestions = dealer_card;
    //       this.setState({ suggestions });
    //     })
    //     .catch(e => {
    //       console.error("e", e);
    //     })
  };

  openViewReportPage = (suggestion) => {
    const { sap_id, uuid, business_name, type } = suggestion;
    const { isWarranty = false, from } = this.props;
    const { userRoleDetail } = this.state;
    let permissions = [];
    if (userRoleDetail && userRoleDetail.permissions) {
      permissions = userRoleDetail.permissions;
    }
    if (isWarranty) {
      this.props.history.push(
        `/warranty-score-card?id=${sap_id}&name=${business_name}&y=${this.state.year}`
      );
    } else {
      let url = `/primary-points-report?id=${uuid}&name=${business_name}`;
      // if (permissions.includes(Permissions.SALES_TREND_REPORT)) {
      //   url = `/point-report?id=${uuid}&name=${business_name}`;
      // }
      if (this.state.year) {
        url += `&y=${this.state.year}`;
      }
      if (type) {
        url += `&type=${type}`;
      }
      if (from) {
        url += `&from=${from}`;
      }
      this.props.history.push(url);
    }
  };

  searchReport = (search) => {
    this.getAllDealerReport(1, search.value, true);
  };

  getSuggestionValue = (suggestion) => {
    this.openViewReportPage(suggestion);
    return suggestion.dealer;
  };

  renderSuggestion = (suggestion) => {
    return (
      <span>
        {suggestion.business_name} ({suggestion.sap_id})
      </span>
    );
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search using dealer name or SAP code",
      value,
      onChange: this.onChange,
    };

    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.searchReport}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
        <img src="images/search.svg" className="report-search icon-width" alt='' />
      </div>
    );
  }
}

export default AutoSuggestionDealer;
