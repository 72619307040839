import React, { Component, Fragment } from "react";
import { Col, Row, Card, CardBody, Input, Button } from "reactstrap";
import Sidebar from "react-sidebar";
import Moment from "react-moment";
import _ from "lodash";
import { connect } from "react-redux";
import lodash from "lodash"; // library added for debounce functionality;
import classnames from "classnames";
import {
  setDealerDashboardRedirectDetail,
  setRedirect,
  setHelpGuide,
} from "../../actions/UserAction";

// import CampaignCustomize from "../../components/CampaignCustomize/CampaignCustomize";
import CampaignDetail from "../../components/CampaignList/CampaignDetail";
import CreateCampaign from "../../components/CreateCampaign/CreateCampaign";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import FilterPopUp from "../../components/filterPopUp/filterPopUp";
import CustomerManagementFilterNew from "../../components/CustomerManagementFilterNew/CustomerManagementFilterNew";

import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import CampaignService from "./../../services/CampaignService";

import { messages } from "../../constants/constant.message";

import "./Campaign.css";
import BuyProduct from "../../components/BuyProduct/BuyProduct";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

const { ERROR_MESSAGE } = messages.COMMON;

var routUrl = null;

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      targetgroup: "all",
      opensidebar: false,
      show: true,
      openCompaignDetailSideBar: false,
      openCreateCampaignPage: false,
      createCampaign: true,
      campaignData: [],
      campaigntransfrDatatodetail: "",
      CampaignDetailData: {},
      search_query: "",
      campaignCount: {},
      isActiveScheduleTab: "",
      is_scheduled: true,
      is_open_buy_product: false,
      isLoading: false,
      openCreateCampaignPageNew: false,
      filterPopupOn: false,
      filterPopUp: false,
      scheduledCountForSearch: null,
      completedCountForSearch: null,
      filterStates: null,
      CustomerListFilteredObj: null,
      controls: {
        campaign_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
        },
        purpose_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
        },
        campaign_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
        },
        customer_target_type: {
          value: "all",
          valid: null,
          touched: false,
          nullValue: null,
        },
        service_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
        },
        festival_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
        },
        from_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
        },
        to_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
        },
        purpose_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
        },
      },
      isHoLogin: false,
      sms_credits: {
        credit: 0,
      },
      is_display_buy_sms: false,
      is_display_sms_report: false,
      is_first_time_reset_filter_from_customer_list: false,
    };

    this.props.history.push({
      targetgroup: this.state.targetgroup,
    });
    this.childCreateCampaign = React.createRef();
    this.childCampaignDetail = React.createRef();
    this.childCustomiseDetail = React.createRef();

    this.searchCampaign = lodash.debounce(this.searchCampaign, 500);

    if (
      this.state.userDetail.type &&
      (this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE ||
        this.state.userDetail.type === Roles.PROJECT_MANAGER_ROLE)
    ) {
      this.TagsWithoutOffer = ["#CustomerName"];
      this.state.isHoLogin = true;
    }
    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.SMS_REPORT)
    ) {
      this.state.is_display_sms_report = true;
    }
    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.BUY_SMS)
    ) {
      this.state.is_display_buy_sms = true;
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      routUrl =
        window.location.href &&
        window.location.href.split("/")[5] &&
        window.location.href.split("/")[5];
      if (routUrl === "create") {
        this.openCreateCampaignPageNew(true);
      } else if (routUrl === "schedule") {
        this.getCampaignDataScheduleAndCompleted(true);
      } else {
        this.getCampaignDataScheduleAndCompleted(false);
      }
    });
  }

  setCustomerListFilteredObj = async (obj) => {
    await this.setState({
      CustomerListFilteredObj: obj,
    });
    return true;
  };

  componentDidMount() {
    this.setState({ height: window.innerHeight - 60 + "px" });
    this.getCampaignCount();
    this.getSMSCredits();

    if (this.props && this.props.location && this.props.location.manageGroup) {
      this.setState({
        isLoading: true,
      });
      setTimeout(() => {
        let { CampaignDetailData } = this.state;
        CampaignDetailData.custom_filter_group_id = this.props.location.manageGroup.group_id;
        CampaignDetailData.customers_count = this.props.location.manageGroup.member_count;
        CampaignDetailData.custom_filter_group_name = this.props.location.manageGroup.group_name;
        CampaignDetailData.custom_filter_type = "group";
        CampaignDetailData.customer_target_type = "custom";
        CampaignDetailData.manageGroup = true;
        let obj = this.mapCampaignObjWithFilterObj(CampaignDetailData);
        this.setState(
          {
            CustomerListFilteredObj: obj,
          },
          () => {
            this.setState({
              openCreateCampaignPage: true,
              openCreateCampaignPageNew: true,
              createCampaign: false,
              isActiveScheduleTab: "",
              CampaignDetailData,
              openCompaignDetailSideBar: false,
              opensidebar: true,
              CustomerListFilteredObj: obj,
            });
          }
        );
        if (this.childCreateCampaign.current) {
          this.childCreateCampaign.current.setData();
        }
        this.setState({
          isLoading: false,
        });
      }, 500);
    } else if (
      this.props &&
      this.props.location &&
      this.props.location.DataComingFromLOC
    ) {
      this.setState({
        isLoading: true,
      });
      setTimeout(() => {
        let {
          CampaignDetailData,
          is_first_time_reset_filter_from_customer_list,
        } = this.state;
        let {
          SelectAll_customer,
          isSelectAllEntry,
          selectedAll_length,
          filterStates = null,
          selectedCustomers,
        } = this.props.location;
        CampaignDetailData = this.props.location.CampaignDetailData
          ? this.props.location.CampaignDetailData
          : null;
        CampaignDetailData["DataComingFromLOC"] = true;
        is_first_time_reset_filter_from_customer_list = true;
        // CampaignDetailData["customers_count"] = this.props.location
        //   .selectedAll_length
        //   ? this.props.location.selectedAll_length
        //   : 0;

        // if(isSelectAllEntry){

        CampaignDetailData["customers_count"] = selectedAll_length;
        if (!(SelectAll_customer === true || isSelectAllEntry === true)) {
          CampaignDetailData["custom_filter_type"] = "manual";
          CampaignDetailData[
            "custom_filter_manual_customer"
          ] = selectedCustomers ? selectedCustomers : null;
          let obj = this.mapCampaignObjWithFilterObj(CampaignDetailData);
          this.setState({ CustomerListFilteredObj: obj });
          filterStates = null;
        } else {
          if (filterStates && filterStates.filter) {
            CampaignDetailData = {
              ...CampaignDetailData,
              ...filterStates.filter,
            };
          }
        }
        // }

        if (SelectAll_customer && isSelectAllEntry) {
          CampaignDetailData["customer_target_type"] = "all";
        } else {
          CampaignDetailData["customer_target_type"] = "custom";
        }

        // if (
        //   this.props.location.CampaignDetailData.custom_filter_type ==
        //     "by_group" &&
        //   this.props.location.isSelectAllEntry
        // ) {
        //   CampaignDetailData["custom_filter_type"] = "group";
        // } else if (
        //   this.props.location.CampaignDetailData.custom_filter_type ==
        //     "by_vehicle_type" &&
        //   this.props.location.isSelectAllEntry
        // ) {
        //   CampaignDetailData["custom_filter_type"] = "vehicle";
        //   CampaignDetailData[
        //     "custom_filter_vehicle_type_id"
        //   ] = this.props.location.CampaignDetailData.vehicle_type_id;
        //   CampaignDetailData[
        //     "custom_filter_vehicle_type_brand_id"
        //   ] = this.props.location.CampaignDetailData.vehicle_type_brand_id;
        //   CampaignDetailData[
        //     "custom_filter_vehicle_type_brand_model_id"
        //   ] = this.props.location.CampaignDetailData.vehicle_type_brand_model_id;
        // } else if (
        //   this.props.location.CampaignDetailData.custom_filter_type ==
        //     "by_occasion" &&
        //   this.props.location.isSelectAllEntry
        // ) {
        //   CampaignDetailData["custom_filter_type"] = "occasion";
        //   CampaignDetailData[
        //     "custom_filter_occasion"
        //   ] = this.props.location.CampaignDetailData.occasion;
        //   CampaignDetailData[
        //     "custom_filter_occasion_date_from"
        //   ] = this.props.location.CampaignDetailData.occasion_date_from;
        //   CampaignDetailData[
        //     "custom_filter_occasion_date_to"
        //   ] = this.props.location.CampaignDetailData.occasion_date_to;
        // } else if (
        //   this.props.location.CampaignDetailData.custom_filter_type ==
        //     "by_source" &&
        //   this.props.location.isSelectAllEntry
        // ) {
        //   CampaignDetailData["custom_filter_type"] = "source";
        // } else if (
        //   this.props.location.CampaignDetailData.custom_filter_type ==
        //     "by_creation_date" &&
        //   this.props.location.isSelectAllEntry
        // ) {
        //   CampaignDetailData["custom_filter_type"] = "creation_date";
        // } else {
        //   CampaignDetailData["custom_filter_occasion"] = null;
        //   CampaignDetailData["custom_filter_occasion_date_from"] = null;
        //   CampaignDetailData["custom_filter_occasion_date_to"] = null;
        //   CampaignDetailData["custom_filter_vehicle_type_id"] = null;
        //   CampaignDetailData["custom_filter_vehicle_type_brand_id"] = null;
        //   CampaignDetailData[
        //     "custom_filter_vehicle_type_brand_model_id"
        //   ] = null;
        //   CampaignDetailData["group_id"] = null;
        //   CampaignDetailData["from_date"] = null;
        //   CampaignDetailData["to_date"] = null;
        //   CampaignDetailData["source_of_member"] = null;
        //   // CampaignDetailData["custom_filter_type"] = "manual";
        //   CampaignDetailData["custom_filter_manual_customer"] = this.props
        //     .location.selectedCustomers
        //     ? this.props.location.selectedCustomers
        //     : null;
        //   // CampaignDetailData["customers_count"] = this.props.location
        //   //   .selectedCustomers
        //   //   ? this.props.location.selectedCustomers.length
        //   //   : 0;
        // }

        this.setState(
          {
            openCreateCampaignPage: true,
            openCreateCampaignPageNew: false,
            createCampaign: false,
            isActiveScheduleTab: "",
            CampaignDetailData,
            openCompaignDetailSideBar: false,
            opensidebar: true,
            filterStates,
            is_first_time_reset_filter_from_customer_list,
          },
          () => {
            if (this.childCreateCampaign.current) {
              this.childCreateCampaign.current.setData();
            }
          }
        );
        this.setState({
          isLoading: false,
        });
      }, 500);
    } else {
      this.getCampaignData();
    }

    routUrl =
      window.location.href &&
      window.location.href.split("/")[5] &&
      window.location.href.split("/")[5];

    if (routUrl === "create") {
      this.openCreateCampaignPageNew(true);
    } else if (routUrl === "schedule") {
      this.getCampaignDataScheduleAndCompleted(true);
    } else {
      this.getCampaignDataScheduleAndCompleted(false);
    }

    // if (this.props.redirectDetails && this.props.redirectDetails.campaign) {
    //   this.setRedirectData(this.props);
    //   if (this.props.redirectDetails.campaign.filter_data &&
    //     this.props.redirectDetails.campaign.filter_data.filter_type) {
    //     this.setState({
    //       targetgroup: 'customise',
    //       opensidebar: true
    //     });
    //   }
    // } else
    if (this.props.redirectDetails && this.props.redirectDetails.buySMS) {
      this.setState({
        is_open_buy_product: true,
      });
      this.props.setRedirect(null);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.redirectDetails !== this.props.redirectDetails) {
      if (this.props.redirectDetails && this.props.redirectDetails.buySMS) {
        this.setState(
          {
            is_open_buy_product: false,
          },
          () => {
            this.setState({
              is_open_buy_product: true,
            });
          }
        );
      } else if (
        this.props.redirectDetails &&
        this.props.redirectDetails.campaign
      ) {
        if (this.childCreateCampaign.current) {
          this.childCreateCampaign.current.setRedirectData();
        }
      }
      this.props.setRedirect(null);
    }
  }

  removeDashboardRedirectData = () => {
    this.props.setDealerDashboardRedirectDetail(null);
  };

  openFilterFromSelectPurpose = async () => {
    if (this.childCreateCampaign.current) {
      let { CampaignDetailData } = this.state;
      let obj = {};
      let { controls } = this.childCreateCampaign.current.state;
      if (
        controls.campaign_name.value &&
        (controls.campaign_name.value.toLowerCase() === "tyre purchase due" ||
          controls.campaign_name.value.toLowerCase() === "service due")
      ) {
        this.applyFilter(null);
        let custom_customer = {
          opensidebar: "all",
          resetFilter: true,
        };
        await this.changeTargetGroup(custom_customer);
        obj.filter_type = "due";
        if (controls.campaign_name.value.toLowerCase() === "service due") {
          obj.purpose_type = "service";
        } else {
          obj.purpose_type = "tyre_purchase";
        }
      } else if (
        controls.campaign_name.value &&
        (controls.campaign_name.value.toLowerCase() === "birthday wish" ||
          controls.campaign_name.value.toLowerCase() === "anniversary wish")
      ) {
        obj.filter_type = "occasion";
        if (controls.campaign_name.value.toLowerCase() === "anniversary wish") {
          obj.occasion = "anniversary";
        } else {
          obj.occasion = "birthday";
        }
      }
      controls.customer_target_type.value = "custom";
      CampaignDetailData.customers_count = 0;

      this.setState(
        {
          CustomerListFilteredObj: obj,
          CampaignDetailData,
        },
        () => {
          this.setState({
            opensidebar: true,
            targetgroup: "customise",
          });
          this.childCreateCampaign.current.resteCount();
        }
      );
    }
  };

  componentWillReceiveProps(props) {
    if (
      !this.state.openCreateCampaignPage &&
      props &&
      props.redirectDetails &&
      props.redirectDetails.campaign
    ) {
      this.setRedirectData(props);
      this.setState({
        openCreateCampaignPage: true,
        openCreateCampaignPageNew: true,
        createCampaign: false,
        isActiveScheduleTab: "",
        CampaignDetailData: {},
        openCompaignDetailSideBar: false,
      });
    }
    // }
  }

  setRedirectData = (props) => {
    let redirectData = {};
    if (props.redirectDetails) {
      redirectData = Object.assign(props.redirectDetails);
    }
    if (
      redirectData &&
      redirectData.campaign &&
      (redirectData.campaign.type === "anniversary_wish" ||
        redirectData.campaign.type === "birthday_wish")
    ) {
      if (redirectData.campaign.count <= 0) {
        return;
      }
    }

    if (
      redirectData &&
      redirectData.campaign &&
      redirectData.campaign.filter_data
    ) {
      // this.setState({
      //   targetgroup : "customise",
      //   opensidebar:true
      // },()=>{
      let obj = {
        campaign: redirectData.campaign.filter_data,
      };
      this.setRedirectFilterDataFromDashboard(obj);
      // })
    } else if (redirectData && redirectData.campaign) {
      // this.setState({
      //   targetgroup : "customise",
      //   opensidebar:true
      // },()=>{
      this.setRedirectFilterDataFromDashboard(redirectData);
      // })
    }
    // let {
    //   is_first_time_reset_filter_from_customer_list,
    // } = this.state;
    // is_first_time_reset_filter_from_customer_list = true;
    // this.setState({ is_first_time_reset_filter_from_customer_list })
  };

  setRedirectFilterDataFromDashboard = (redirectData) => {
    let filterObj = {};
    let fromDate = new Date();
    let toDate = new Date();
    toDate.setDate(toDate.getDate() + 1);
    fromDate.setHours(5, 30, 0, 0);
    toDate.setHours(5, 29, 59, 59);

    if (redirectData.campaign.type === "anniversary_wish") {
      filterObj.filter_type = "occasion";
      filterObj.occasion = "anniversary";
      filterObj.occasion_date_from = fromDate.toISOString();
      filterObj.occasion_date_to = toDate.toISOString();
    }
    if (redirectData.campaign.type === "birthday_wish") {
      filterObj.filter_type = "occasion";
      filterObj.occasion = "birthday";
      filterObj.occasion_date_from = fromDate.toISOString();
      filterObj.occasion_date_to = toDate.toISOString();
    }

    if (redirectData.campaign && redirectData.campaign.filter_type) {
      filterObj.filter_type = redirectData.campaign.filter_type;
      if (redirectData.campaign.custom_filter) {
        filterObj.purpose_type = redirectData.campaign.custom_filter;
        if (redirectData.campaign.custom_filter === "birthday_wish") {
          filterObj.occasion = "birthday";
          filterObj.occasion_date_from = fromDate.toISOString();
          filterObj.occasion_date_to = toDate.toISOString();
        }
        if (redirectData.campaign.custom_filter === "anniversary_wish") {
          filterObj.occasion = "anniversary";
          filterObj.occasion_date_from = fromDate.toISOString();
          filterObj.occasion_date_to = toDate.toISOString();
        }
      }
      if (redirectData.campaign.due_date_from) {
        filterObj.due_date_from = redirectData.campaign.due_date_from;
      }
      if (redirectData.campaign.due_date_to) {
        filterObj.due_date_to = redirectData.campaign.due_date_to;
      }
      if (redirectData.campaign.custom_filter_purpose_id) {
        filterObj.purpose_id = redirectData.campaign.custom_filter_purpose_id;
      }
      if (redirectData.campaign.last_visit_from_date) {
        filterObj.last_visit_from_date =
          redirectData.campaign.last_visit_from_date;
      }
      if (redirectData.campaign.last_visit_to_date) {
        filterObj.last_visit_to_date = redirectData.campaign.last_visit_to_date;
      }
      if (
        redirectData.campaign.custom_filter_service_id &&
        Array.isArray(redirectData.campaign.custom_filter_service_id) &&
        redirectData.campaign.custom_filter_service_id.length > 0
      ) {
        if (redirectData.campaign.custom_filter_service_id.length === 1) {
          filterObj.service_id =
            redirectData.campaign.custom_filter_service_id[0];
        }
      }
    }
    // this.props.setDealerDashboardRedirectDetail({
    //   campaign:null
    // })
    // if (this.childCreateCampaign.current) {
    //   this.childCreateCampaign.current.setRedirectData();
    // }
    if (
      redirectData.campaign.filter_type !== "festival" ||
      redirectData.campaign.custom_filter !== "festival_wish"
    ) {
      if (
        redirectData.campaign.type === "anniversary_wish" ||
        redirectData.campaign.type === "birthday_wish" ||
        redirectData.campaign.filter_type
      ) {
        this.setState(
          {
            CustomerListFilteredObj: filterObj,
            targetgroup: "customise",
            // opensidebar:true
          },
          () => {
            this.getCustomizeCustomer("redirect");
          }
        );
      }
    }
  };
  handleSearchChange = (e) => {
    let searchVal = e.target.value;
    this.setState({ search_query: searchVal });
    this.searchCampaign();
  };

  searchCampaign = (e) => {
    this.getCampaignData();
    this.getCampaignCount();
  };

  resetData = async () => {
    if (routUrl === "create") {
      routUrl = "";
    }
    await this.setState({
      targetgroup: "all",
      opensidebar: false,
      openCompaignDetailSideBar: false,
      openCreateCampaignPage: false,
      createCampaign: true,
      campaignData: [],
      campaigntransfrDatatodetail: "",
      customisedata: "",
      CampaignDetailData: {},
      isActiveScheduleTab: "",
      search_query: "",
      campaignCount: {},
      filterStates: null,
      CustomerListFilteredObj: null,
    });
    this.props.setDealerDashboardRedirectDetail({
      campaign: null,
    });
    this.getCampaignData();
    this.getCampaignCount();
  };

  getCampaignDataScheduleAndCompleted = async (flag) => {
    if (routUrl === "create") {
      routUrl = "";
    }
    let { controls } = this.state;
    controls["purpose_id"].value = "";
    controls["service_id"].value = "";
    controls["festival_id"].value = "";
    controls["from_date"].value = "";
    controls["to_date"].value = "";
    await this.setState(
      {
        is_scheduled: flag,
        openCompaignDetailSideBar: false,
        openCreateCampaignPage: false,
        createCampaign: true,
        campaignCount: {},
        search_query: "",
        controls,
      },
      () => {
        this.getCampaignData();
        this.getCampaignCount();
      }
    );
  };

  customiseFunction = (e) => { };

  onCloseSidebarCustomerManagementFilter = () => {
    document.body.style.overflow = "auto";
    this.setState((prevState) => {
      return { ...prevState, opensidebar: false };
    });
    document.body.style.overflow = "auto";
  };

  openDetailsList = (item, i) => {
    this.props.setDealerDashboardRedirectDetail({
      campaign: null,
    });
    if (routUrl === "create") {
      routUrl = "";
    }
    this.setState({
      filterStates: null,
      filterObj: null,
      openCompaignDetailSideBar: true,
      openCreateCampaignPage: false,
      createCampaign: false,
      campaigntransfrDatatodetail: item,
    });
  };

  getCampaignCount = () => {
    CampaignService.getCampaignCount()
      .then((data) => {
        this.setState({
          campaignCount: data.data.data,
        });
      })
      .catch((e) => {
        this.setState({
          campaignData: [],
        });
      });
  };

  getCampaignData = () => {
    let { is_scheduled, search_query, controls } = this.state;
    const obj = {
      is_scheduled: is_scheduled ? is_scheduled : false,
      pageSize: 10000,
      page: 1,
    };
    if (controls.from_date && controls.from_date.value) {
      let from_date = new Date(controls.from_date.value);
      from_date.setHours(0);
      from_date.setMinutes(0);
      controls.from_date.value = from_date;
    }
    if (controls.to_date && controls.to_date.value) {
      let to_date = new Date(controls.to_date.value);
      to_date.setHours(23);
      to_date.setMinutes(59);
      controls.to_date.value = to_date;
    }
    this.setState({ controls });

    if (controls.purpose_id.value) {
      obj["purpose_id"] = controls.purpose_id.value;
    }
    if (controls.service_id.value) {
      obj["service_id"] = controls.service_id.value;
    }
    if (controls.from_date.value) {
      obj["from_date"] = controls.from_date.value;
    }
    if (controls.to_date.value) {
      obj["to_date"] = controls.to_date.value;
    }
    if (controls.festival_id.value) {
      obj["festival_id"] = controls.festival_id.value;
    }
    obj["search_query"] = search_query;
    this.setState({
      isLoading: true,
    });

    CampaignService.getCampaignData(obj)
      .then((data) => {
        if (is_scheduled) {
          this.setState(
            {
              campaignData: data.data.data.campaign,
              scheduledCountForSearch: data.data.data.count,
              isActiveScheduleTab: routUrl !== "create" ? 0 : "",
            },
            () => {
              // if (routUrl === "history") {
              //   this.setState({
              //     is_scheduled: false
              //   });
              // }
            }
          );
        } else {
          this.setState(
            {
              campaignData: data.data.data.campaign,
              completedCountForSearch: data.data.data.count,
              isActiveScheduleTab: 0,
            },
            () => {
              if (routUrl === "history") {
                this.setState({
                  openCompaignDetailSideBar: true,
                });
              }
            }
          );
        }

        if (this.state.campaignData.length >= 1 && routUrl !== "create") {
          this.openDetailsList(data.data.data.campaign[0], "");
        } else {
          this.setState({
            createCampaign: true,
          });
        }
        if (this.state.filterPopUp) {
          let { campaignCount } = this.state;
          if (this.state.is_scheduled) {
            campaignCount["filter_scheduled_count"] =
              data.data.data.campaign.length;
          } else {
            campaignCount["filter_completed_count"] =
              data.data.data.campaign.length;
          }
          this.setState({
            campaignCount,
          });
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          campaignData: [],
        });
        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  changeTargetGroup = (e) => {
    this.setState({
      filterPopUp: false,
    });
    if (e === "customise") {
      this.setState({
        targetgroup: e,
        opensidebar: true,
      });
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      this.setState({
        targetgroup: e,
        opensidebar: false,
      });
      if (this.childCreateCampaign.current) {
        this.childCreateCampaign.current.setFilterData(e);
      }
    }
    if (e.openCreateCampaignPage) {
      this.setState(
        {
          openCreateCampaignPage: true,
          createCampaign: false,
          openCompaignDetailSideBar: false,
          openCreateCampaignPageNew: false,
        },
        () => {
          if (
            e.CampaignDetailData.custom_filter_purpose_type &&
            (e.CampaignDetailData.custom_filter_purpose_type === "service" ||
              e.CampaignDetailData.custom_filter_purpose_type ===
              "tyre_purchase") &&
            e.CampaignDetailData.custom_filter_type &&
            e.CampaignDetailData.custom_filter_type === "due" &&
            this.childCreateCampaign.current
          ) {
            if (e.CampaignDetailData.custom_filter_purpose_type === "service") {
              this.childCreateCampaign.current.setVehicleTypeTag();
              this.childCreateCampaign.current.setServiceTag();
            } else if (
              e.CampaignDetailData.custom_filter_purpose_type ===
              "tyre_purchase"
            ) {
              this.childCreateCampaign.current.setVehicleTypeTag();
            }
          } else if (this.childCreateCampaign.current) {
            this.childCreateCampaign.current.removeVehicleTypeTag();
            this.childCreateCampaign.current.removeServiceTag();
          }
        }
      );
    }
    if (e.CampaignDetailData) {
      this.setState({
        CampaignDetailData: e.CampaignDetailData,
      });
    }
    if (e.obj) {
      e.obj = { ...this.state.CampaignDetailData, ...e.obj };
      this.setState({
        CampaignDetailData: e.obj,
        ...e.CampaignDetailData,
      });
    }
    if (e.openCustom) {
      this.setState({
        targetgroup: e.targetgroup,
        opensidebar: true,
      });
      document.body.style.overflow = "hidden";
    }
    if (e.isEdit) {
      if (this.childCreateCampaign.current) {
        this.childCreateCampaign.current.setFilterData(e);
      }
      if (e.CampaignDetailData) {
        let CustomerListFilteredObj = this.mapCampaignObjWithFilterObj(
          e.CampaignDetailData
        );
        this.setState({ CustomerListFilteredObj });
      }
    }

    if (e.duplicateCampaign) {
      if (this.childCreateCampaign.current) {
        this.childCreateCampaign.current.setFilterData(e);
      }
      if (e.CampaignDetailData) {
        let CustomerListFilteredObj = this.mapCampaignObjWithFilterObj(
          e.CampaignDetailData
        );
        this.setState({ CustomerListFilteredObj });
      }
    }

    if (e.filterPopupControls) {
      let { controls } = this.state;
      if (e.filterPopupControls.purpose_id) {
        controls["purpose_id"].value = e.filterPopupControls.purpose_id.value;
      }
      if (e.filterPopupControls.festival_id) {
        controls["festival_id"].value = e.filterPopupControls.festival_id.value;
      }
      if (e.filterPopupControls.service_id) {
        this.state.controls.service_id.value =
          e.filterPopupControls.service_id.value;
      }
      if (e.filterPopupControls.from_date) {
        controls["from_date"].value = e.filterPopupControls.from_date.value;
      }
      if (e.filterPopupControls.to_date) {
        controls["to_date"].value = e.filterPopupControls.to_date.value;
      }
      this.setState({
        filterPopupOn: false,
        filterPopUp: false,
        controls,
      });
      this.getCampaignData();
      this.getCampaignCount();
    }
    if (e.closepopup) {
      this.setState({
        filterPopupOn: false,
      });
    }
    if (e.runNowDone) {
      this.setState({
        is_scheduled: false,
      });
      this.state.campaignCount.scheduled_count--;
      this.getCampaignData();
      this.getCampaignCount();
    }
    if (e.Deleted) {
      this.setState({
        is_scheduled: true,
      });
      this.state.campaignCount.scheduled_count--;
      this.getCampaignData();
      this.getCampaignCount();
    }
    if (e.campaignCreated) {
      this.setState({
        openCreateCampaignPage: false,
        createCampaign: true,
        openCompaignDetailSideBar: false,
        is_scheduled: e.is_scheduled,
      });
      this.getCampaignDataScheduleAndCompleted(e.is_scheduled);
    }
    if (e.resetFilter) {
      this.setState({ filterStates: null, CustomerListFilteredObj: null });
    }
    if (e.campaign) {
      this.setRedirectFilterDataFromDashboard(e);
    }
    if (this.state.is_scheduled) {
      GoogleAnalticsService.pageView("Schedule Campaign View");
    } else {
      GoogleAnalticsService.pageView("Campaign History View");
    }
  };

  openCreateCampaignPage = async (e) => {
    GoogleAnalticsService.event("Create Campaign", "Add Campaign Click");
    await this.setState({
      openCreateCampaignPage: true,
      openCreateCampaignPageNew: true,
      createCampaign: false,
      isActiveScheduleTab: "",
      CampaignDetailData: {},
      openCompaignDetailSideBar: false,
      CustomerListFilteredObj: null,
      filterStates: null,
    });
    let obj = {
      resetFilter: true,
    };
    this.changeTargetGroup(obj);
    if (this.childCreateCampaign.current) {
      this.childCreateCampaign.current.setData();
    }
  };

  openCreateCampaignPageNew = (e) => {
    this.setState(
      {
        openCreateCampaignPage: true,
        createCampaign: false,
        isActiveScheduleTab: "",
        openCompaignDetailSideBar: false,
        openCreateCampaignPageNew: true,
      },
      () => { }
    );
  };

  filterPopupOn = () => {
    this.setState({
      filterPopupOn: true,
    });
  };

  applyFilter = (filterStates) => {
    this.setState(
      {
        filterApplied: filterStates ? true : false,
        filterStates: filterStates,
        // opensidebar:false
      },
      () => {
        if (filterStates) {
          if (filterStates.filter) {
            let filter = filterStates.filter;
            if (filter.filter_type === "manual") {
              let mappedObj = this.mapFilterObjWithCampaignObj();
              let obj = mappedObj.filter;
              let custom_customer = {
                custom_filter_manual_customer:
                  filter.custom_filter_manual_customer,
                obj: obj,
              };
              this.changeTargetGroup(custom_customer);
            } else {
              this.getCustomizeCustomer();
            }
          } else {
            this.getCustomizeCustomer();
          }
        } else {
          let custom_customer = {
            opensidebar: "all",
            resetFilter: true,
          };
          this.setState({ CustomerListFilteredObj: null });
          this.changeTargetGroup(custom_customer);
        }
      }
    );
    // this.onCloseSidebarFilter();
  };

  resetFilter = () => { };

  onCloseSidebarFilter = () => {
    document.body.style.overflow = "auto";
    this.setState({ opensidebar: false });
    if (!this.state.filterStates && !this.state.CustomerListFilteredObj) {
      let obj = {
        resetFilter: true,
      };
      this.changeTargetGroup(obj);
    }
    if (
      this.state.filterStates &&
      this.state.filterStates.state &&
      !this.state.filterStates.filter
    ) {
      let obj = {
        resetFilter: true,
      };
      this.changeTargetGroup(obj);
    }
  };

  getCustomizeCustomer = (from = null) => {
    let reqObj = {};
    const { filterStates, CustomerListFilteredObj } = this.state;
    if (filterStates) {
      if (filterStates.filter) {
        reqObj = { ...reqObj, ...filterStates.filter };
      }
      if (filterStates.filter.is_hide_upcoming_over_due_tab) {
        reqObj.due_type = "";
      }
    } else if (CustomerListFilteredObj) {
      reqObj = { ...reqObj, ...CustomerListFilteredObj };
    }
    if (!reqObj) {
      reqObj = {};
    }
    reqObj = {
      ...reqObj,
      ...{
        download_list: false,
        fetch_count: true,
        search_query: null,
      },
    };
    this.setState({ isLoading: true });

    CampaignService.getCustomiseCustomer(reqObj)

      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data.total_customer === 0) {
          const err =
            "There is no customer found with applied filter . Please change filter criteria";
          const message = err;
          ModalService.openAlert("", message, "error");
          if (filterStates && filterStates.filter) {
            filterStates.filter = null;
          }
          if (from === "redirect") {
            let obj = {
              resetFilter: true,
            };
            if (this.childCreateCampaign.current) {
              this.childCreateCampaign.current.setFilterData(obj);
            }
          }
          this.setState({
            errorLength: false,
            filterStates,
          });

          return;
        }

        let mappedObj = this.mapFilterObjWithCampaignObj();
        let obj = mappedObj.filter;
        let custom_customer = {
          total_customer: res.data.data.total_customer,
          obj: obj,
        };
        if (res.data.data.total_customer && res.data.data.total_customer > 0) {
          if (
            obj.purpose_type &&
            (obj.purpose_type === "service" ||
              obj.purpose_type === "tyre_purchase") &&
            reqObj.filter_type &&
            reqObj.filter_type === "due"
          ) {
            if (obj.purpose_type === "service") {
              this.childCreateCampaign.current.setVehicleTypeTag();
              this.childCreateCampaign.current.setServiceTag();
            } else if (obj.purpose_type === "tyre_purchase") {
              this.childCreateCampaign.current.setVehicleTypeTag();
            }
          } else {
            this.childCreateCampaign.current.removeVehicleTypeTag();
            this.childCreateCampaign.current.removeServiceTag();
          }
        }
        this.changeTargetGroup(custom_customer);
        if (res.data.data.total_customer) {
          localStorage.setItem(
            "total_customer",
            custom_customer.total_customer
          );
        } else {
          localStorage.setItem("total_customer", 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  mapFilterObjWithCampaignObj = () => {
    let filter = {};
    let campaignObj = {};
    const { filterStates, CustomerListFilteredObj } = this.state;
    if (filterStates) {
      if (filterStates.filter) {
        filter = { ...filter, ...filterStates.filter };
      }
    } else if (CustomerListFilteredObj) {
      filter = { ...filter, ...CustomerListFilteredObj };
    }
    if (!filter) {
      filter = {};
    }
    const {
      vehicle_type_id = null,
      vehicle_type_brand_id = null,
      vehicle_type_brand_model_id = null,
      vehicle_category = null,
      from_date = null,
      to_date = null,
      filter_type = null,
      due_date_from = null,
      due_date_to = null,
      purpose_id = null,
      purpose_type = null,
      service_id = null,
      source_of_member = null,
      group_id = null,
      occasion = null,
      occasion_date_from = null,
      occasion_date_to = null,
      // manual_customer = null,
      custom_filter_manual_customer = [],
      due_type = null,
      rating = null,
      last_visit_from_date = null,
      last_visit_to_date = null,
      is_hide_upcoming_over_due_tab = false,
    } = filter;

    // @TODO verify and update manual customer detail
    campaignObj[
      "custom_filter_manual_customer"
    ] = this.state.custom_filter_manual_customer;
    // @TODO verify values
    campaignObj.custom_filter_occasion = occasion;
    if (vehicle_category) {
      campaignObj.custom_filter_vehicle_category = vehicle_category;
    }
    if (vehicle_type_id) {
      campaignObj.custom_filter_vehicle_type_id = vehicle_type_id;
    }
    if (vehicle_type_brand_id) {
      campaignObj.custom_filter_vehicle_type_brand_id = vehicle_type_brand_id;
    }
    if (vehicle_type_brand_model_id) {
      campaignObj.custom_filter_vehicle_type_brand_model_id = vehicle_type_brand_model_id;
    }
    if (from_date) {
      campaignObj.custom_filter_created_from = from_date;
    }
    if (to_date) {
      campaignObj.custom_filter_created_to = to_date;
    }
    if (last_visit_from_date) {
      campaignObj.custom_filter_last_visit_from_date = last_visit_from_date;
    }
    if (last_visit_to_date) {
      campaignObj.custom_filter_last_visit_to_date = last_visit_to_date;
    }
    if (due_type) {
      if (is_hide_upcoming_over_due_tab) {
        campaignObj.custom_filter_due_type = "";
      } else {
        campaignObj.custom_filter_due_type = due_type;
      }
    }
    if (due_date_from) {
      campaignObj.custom_filter_due_date_from = due_date_from;
    }
    if (due_date_to) {
      campaignObj.custom_filter_due_date_to = due_date_to;
    }
    if (purpose_id) {
      campaignObj.custom_filter_purpose_id = purpose_id;
    }
    if (purpose_type) {
      campaignObj.custom_filter_purpose_type = purpose_type;
    }
    if (service_id) {
      campaignObj.custom_filter_service_id = service_id;
    }
    if (source_of_member) {
      campaignObj.custom_filter_source_of_member = source_of_member;
    }
    if (group_id) {
      campaignObj.custom_filter_group_id = group_id;
    }
    if (occasion_date_from) {
      campaignObj.custom_filter_occasion_date_from = occasion_date_from;
    }
    if (occasion_date_to) {
      campaignObj.custom_filter_occasion_date_to = occasion_date_to;
    }

    if (filter_type) {
      campaignObj.custom_filter_type = filter_type;
    }

    if (
      custom_filter_manual_customer &&
      Array.isArray(custom_filter_manual_customer)
    ) {
      campaignObj.custom_filter_manual_customer = custom_filter_manual_customer;
    }
    if (rating) {
      campaignObj.custom_filter_rating = rating;
    }

    return {
      campaign: campaignObj,
      filter: { ...filter, ...campaignObj },
    };
  };

  mapCampaignObjWithFilterObj = (obj) => {
    let campaignObj = {};

    const {
      custom_filter_vehicle_category = null,
      custom_filter_occasion = null,
      custom_filter_vehicle_type_id = null,
      custom_filter_vehicle_type_brand_id = null,
      custom_filter_vehicle_type_brand_model_id = null,
      custom_filter_created_from = null,
      custom_filter_created_to = null,
      custom_filter_due_date_from = null,
      custom_filter_due_date_to = null,
      custom_filter_purpose_id = null,
      custom_filter_purpose_type = null,
      custom_filter_service_id = null,
      custom_filter_source_of_member = null,
      custom_filter_group_id = null,
      custom_filter_occasion_date_from = null,
      custom_filter_occasion_date_to = null,
      custom_filter_type = null,
      custom_filter_manual_customer = [],
      source_of_member = null,
      group_id = null,
      custom_filter_due_type = null,
      custom_filter_rating = null,
      custom_filter_last_visit_from_date = null,
      custom_filter_last_visit_to_date = null,
      is_hide_upcoming_over_due_tab = false,
    } = obj;

    // @TODO verify and update manual customer detail
    campaignObj[
      "custom_filter_manual_customer"
    ] = custom_filter_manual_customer;
    // @TODO verify values
    if (custom_filter_occasion) {
      campaignObj.occasion = custom_filter_occasion;
    }
    if (custom_filter_vehicle_category) {
      campaignObj.vehicle_category = custom_filter_vehicle_category;
    }
    if (custom_filter_vehicle_type_id) {
      campaignObj.vehicle_type_id = custom_filter_vehicle_type_id;
    }
    if (custom_filter_vehicle_type_brand_id) {
      campaignObj.vehicle_type_brand_id = custom_filter_vehicle_type_brand_id;
    }
    if (custom_filter_vehicle_type_brand_model_id) {
      campaignObj.vehicle_type_brand_model_id = custom_filter_vehicle_type_brand_model_id;
    }
    if (custom_filter_created_from) {
      campaignObj.from_date = custom_filter_created_from;
    }
    if (custom_filter_last_visit_from_date) {
      campaignObj.last_visit_from_date = custom_filter_last_visit_from_date;
    }

    if (custom_filter_last_visit_to_date) {
      campaignObj.last_visit_to_date = custom_filter_last_visit_to_date;
    }

    if (custom_filter_created_to) {
      campaignObj.to_date = custom_filter_created_to;
    }

    if (custom_filter_due_date_from) {
      campaignObj.due_date_from = custom_filter_due_date_from;
    }
    if (custom_filter_due_date_to) {
      campaignObj.due_date_to = custom_filter_due_date_to;
    }
    if (custom_filter_purpose_id) {
      campaignObj.purpose_id = custom_filter_purpose_id;
    }
    if (custom_filter_purpose_type) {
      campaignObj.purpose_type = custom_filter_purpose_type;
    }
    if (custom_filter_service_id) {
      campaignObj.service_id = custom_filter_service_id;
    }
    if (source_of_member) {
      campaignObj.source_of_member = source_of_member;
    }
    if (custom_filter_source_of_member) {
      campaignObj.source_of_member = custom_filter_source_of_member;
    }
    if (group_id) {
      campaignObj.group_id = group_id;
    }
    if (custom_filter_group_id) {
      campaignObj.group_id = custom_filter_group_id;
    }
    if (custom_filter_occasion_date_from) {
      campaignObj.occasion_date_from = custom_filter_occasion_date_from;
    }
    if (custom_filter_occasion_date_to) {
      campaignObj.occasion_date_to = custom_filter_occasion_date_to;
    }
    if (custom_filter_type) {
      campaignObj.filter_type = custom_filter_type;
    }
    if (custom_filter_manual_customer) {
      campaignObj.custom_filter_manual_customer = custom_filter_manual_customer;
    }
    if (custom_filter_due_type) {
      if (is_hide_upcoming_over_due_tab) {
        campaignObj.due_type = "";
      } else {
        campaignObj.due_type = custom_filter_due_type;
      }
    }
    if (custom_filter_rating) {
      campaignObj.rating = custom_filter_rating;
    }
    return campaignObj;
  };

  openCloseBuyProduct = (is_api_call = false) => {
    GoogleAnalticsService.event("Create Campaign", "Buy SMS View");
    if (is_api_call) {
      this.getSMSCredits();
    }
    this.setState({
      is_open_buy_product: !this.state.is_open_buy_product,
    });
  };

  getSMSCredits = () => {
    this.setState({
      isLoading: true,
    });
    CampaignService.getSMSCredits()
      .then((data) => {
        this.setState({
          sms_credits: data.data.data,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  viewSMSReport = () => {
    this.setState({
      is_redirect_sms_report: true,
    });
  };

  confirmLostFilter = async () => {
    let result = await swal({
      title: "",
      text:
        "If you change the purpose the target consumers filter will be reset. Do you want to continue and change the purpose?",
      // icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        return true;
      }
      return false;
    });
    this.setState({
      is_first_time_reset_filter_from_customer_list: !result,
    });
    return result;
  };

  reOpenToolTipPopOver = (name) => {
    this.setState({
      tooltipOpen: true,
      tooltip_name: name,
    });
    localStorage.setItem("helperModule", name);
    this.props.setHelpGuide(name);
  };

  render() {
    let {
      createCampaign,
      campaignData,
      search_query,
      campaignCount,
      filterPopupOn,
      // filter_completed_count,
      CustomerListFilteredObj,
      filterStates,
    } = this.state;

    // this.props.history.listen((location, action) => {
    //   if (routUrl === "create") {
    //     this.openCreateCampaignPage();
    //   }
    // });

    return (
      <Fragment>
        {this.state.isLoading && !this.state.is_open_buy_product && (
          <CustomSpinner />
        )}
        {this.state.is_redirect_sms_report && <Redirect to="/sms-report" />}
        <Row id="campaign">
          {this.state.is_open_buy_product && (
            <Sidebar
              sidebar={
                <>
                  <BuyProduct openCloseBuyProduct={this.openCloseBuyProduct} />
                </>
              }
              open={true}
              sidebarClassName="campaign-drawer"
              pullRight
              styles={{
                sidebar: {
                  background: "white",
                  height: this.state.height ? this.state.height : "90%",
                  top: "60px",
                  width: "456px",
                  borderRadius: "2px",
                },
              }}
            />
          )}
          <Col sm="12">
            <Row className="mb-2">
              <Col xl="6" md="12" xs="12" className="Campaign-Management">
                Campaigns{" "}
                {this.state.userDetail.type !== Roles.PROJECT_MANAGER_ROLE && this.state.userDetail.type !== Roles.HEAD_OFFICE_ROLE && <img
                  src="/images/orange-info.png"
                  alt=""
                  className="ml-1 cursor-pointer"
                  data-tip
                  data-for={"tooltip-service"}
                  style={{ height: "15px" }}
                  onClick={() => this.reOpenToolTipPopOver("purpose_id")}
                />}
              </Col>

              <Col
                xl="6"
                md="12"
                xs="12"
                className="text-right d-flex justify-content-end"
                style={{ marginLeft: "-35px" }}
              >
                {!this.state.isHoLogin && (
                  <>
                    <span className="mr-3 mt-auto mb-auto Balance-SMS-Credits">
                      Balance SMS Credits :{" "}
                      <span style={{ color: "#495057" }}>
                        {this.state.sms_credits && this.state.sms_credits.credit
                          ? this.state.sms_credits.credit
                          : 0}
                      </span>
                    </span>
                    {this.state.is_display_sms_report && (
                      <span
                        className="Download-Full-Report ml-0 mr-3"
                        onClick={() => this.viewSMSReport()}
                        style={{ cursor: "pointer", fontSize: "12px" }}
                      >
                        View SMS Report
                      </span>
                    )}
                    {this.state.is_display_buy_sms && (
                      <Button
                        className="mb-1 mr-1 btn-pill action-button font-weight-bold mr-2"
                        color="warning"
                        onClick={this.openCloseBuyProduct}
                        style={{ width: "116px" }}
                      >
                        Buy SMS
                      </Button>
                    )}
                  </>
                )}
                {campaignData.length > 0 && (
                  <Button
                    className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                    color="warning"
                    onClick={this.openCreateCampaignPage}
                    style={{ width: "116px" }}
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm="4" style={{ height: "100%" }}>
            <Card className="w-100" style={{ height: "100%" }}>
              {filterPopupOn ? (
                <FilterPopUp
                  onSelectTargetGroup={this.changeTargetGroup}
                  setFilterDataForPopUp={this.state.controls}
                />
              ) : (
                ""
              )}
              <CardBody className="padding-top-0 padding-bottom-0">
                <Row>
                  <Col
                    xl="6"
                    md="12"
                    xs="12"
                    className={
                      this.state.is_scheduled
                        ? "RectangleYellow campaign-tab campaign-active-tab"
                        : "RectangleYellow campaign-tab"
                    }
                    onClick={() =>
                      this.getCampaignDataScheduleAndCompleted(true)
                    }
                  >
                    <b>
                      Scheduled
                      {this.state.scheduledCountForSearch >= 0 &&
                        this.state.is_scheduled ? (
                        <span>
                          {" "}
                          (
                          {this.state.scheduledCountForSearch
                            ? this.state.scheduledCountForSearch
                            : 0}
                          )
                        </span>
                      ) : (
                        <span>
                          {" "}
                          (
                          {campaignCount.scheduled_count
                            ? campaignCount.scheduled_count
                            : 0}
                          )
                        </span>
                      )}
                    </b>
                  </Col>
                  <Col
                    xl="6"
                    md="12"
                    xs="12"
                    className={
                      !this.state.is_scheduled
                        ? "campaign-tab campaign-active-tab"
                        : "campaign-tab"
                    }
                    onClick={() =>
                      this.getCampaignDataScheduleAndCompleted(false)
                    }
                  >
                    <b>
                      Completed{" "}
                      {this.state.completedCountForSearch >= 0 &&
                        !this.state.is_scheduled ? (
                        <span>
                          {" "}
                          (
                          {this.state.completedCountForSearch
                            ? this.state.completedCountForSearch
                            : 0}
                          )
                        </span>
                      ) : (
                        <span>
                          {" "}
                          (
                          {campaignCount.completed_count
                            ? campaignCount.completed_count
                            : 0}
                          )
                        </span>
                      )}
                    </b>
                  </Col>
                </Row>
                {this.state.is_scheduled ? (
                  <div>
                    <Row className="Scheduled-campaigns">
                      {campaignCount.scheduled_count !== 0 ? (
                        <Col xl="12" md="12" xs="12">
                          <div className="d-flex-row padding-bottom-25">
                            <Input
                              placeholder="Search by campaign name"
                              className="login-input"
                              name="search"
                              onChange={(e) => this.handleSearchChange(e)}
                              value={search_query}
                            ></Input>
                            <img
                              src="images/search.svg"
                              alt=""
                              className="Group-3 icon-width"
                              style={{ cursor: "pointer" }}
                            />
                            <img
                              src="images/sidebar-icons/group-3.png"
                              srcSet="images/sidebar-icons/group-3@2x.png 2x,
                                images/sidebar-icons/group-3@3x.png 3x"
                              className="Group-33"
                              style={{ cursor: "pointer" }}
                              onClick={this.filterPopupOn}
                              alt=""
                            />
                          </div>

                          <hr className="Showing-scheduled-campaigns" />
                        </Col>
                      ) : (
                        ""
                      )}
                      {/* <Row className="campaign-data">
                        Scheduled campaigns help set up customer engagement
                        campaign for future dates. They will be executed
                        automatically on the schedule date. Currently you have
                        not scheduled any campaigns.
                      </Row> */}
                      {/* ) : (
                        ""
                      )} */}
                      {campaignData.length === 0 ? (
                        <Row className="campaign-data">
                          Scheduled campaigns help set up customer engagement
                          campaign for future dates. They will be executed
                          automatically on the schedule date. Currently you have
                          not scheduled any campaigns.
                        </Row>
                      ) : (
                        <div className="campaign-list">
                          {campaignData.map((item, i) => (
                            <Col
                              xl="12"
                              md="12"
                              xs="12"
                              key={i}
                              onClick={() =>
                                this.setState({
                                  isActiveScheduleTab: i,
                                })
                              }
                            >
                              <div
                                className={classnames(
                                  {
                                    "background-color-yellow":
                                      this.state.isActiveScheduleTab === i,
                                    "mt-0": i === 0,
                                  },
                                  "Combined-Shape"
                                )}
                                onClick={this.openDetailsList.bind(this, item)}
                              >
                                <div className="Campaign1">
                                  {item.campaign_name}
                                </div>
                                <div className="Birthday-Wish">
                                  {item.purpose_name && (
                                    <span>{item.purpose_name}</span>
                                  )}
                                  <span
                                    className="All-500-Customers"
                                    style={{
                                      marginLeft: this.state.isHoLogin && "0",
                                    }}
                                  >
                                    {item.customer_target_type === "jk_user"
                                      ? "JK User"
                                      : item.customer_target_type ===
                                        "jk_secondary_point"
                                        ? "JK Secondary User"
                                        : item.customer_target_type ===
                                          "jk_promotion_and_services"
                                          ? "Promotional & Events Users"
                                          : _.upperFirst(item.customer_target_type)}
                                    ({item.customers_count})
                                  </span>
                                  <span className="Fill-arrow">
                                    <img src="images/right.svg" alt="" />
                                  </span>
                                </div>
                                <div className="datefor">
                                  <span className="scheduledfor">
                                    Scheduled for:
                                  </span>{" "}
                                  <Moment format="DD/MM/YYYY  hh:mm A">
                                    {item.started_at}
                                  </Moment>
                                </div>
                              </div>
                              <hr className="Showing-scheduled-campaigns" />
                            </Col>
                          ))}
                        </div>
                      )}
                    </Row>
                  </div>
                ) : (
                  <Row className="Scheduled-campaigns">
                    {campaignCount.completed_count !== 0 ? (
                      <Col xl="12" md="12" xs="12">
                        <div className="d-flex-row padding-bottom-25">
                          <Input
                            placeholder="Search by campaign name"
                            className="login-input"
                            name="search"
                            onChange={(e) => this.handleSearchChange(e)}
                            value={search_query}
                          ></Input>
                          <img
                            src="images/search.svg"
                            alt=""
                            className="Group-3 icon-width"
                            style={{ cursor: "pointer" }}
                          />
                          <img
                            src="images/sidebar-icons/group-3.png"
                            srcSet="images/sidebar-icons/group-3@2x.png 2x,
                            images/sidebar-icons/group-3@3x.png 3x"
                            className="Group-33"
                            style={{ cursor: "pointer" }}
                            onClick={this.filterPopupOn}
                            alt=""
                          />
                        </div>
                        <hr className="Showing-scheduled-campaigns" />
                      </Col>
                    ) : (
                      ""
                    )}{" "}
                    {campaignData.length === 0 ? (
                      <Row className="campaign-data">
                        The campaigns those are passed their end date are listed
                        here. You can create duplicate campaign from existing
                        one but can not edit existing campaign. Currently none
                        of your campaigns have gone passed end date.
                      </Row>
                    ) : (
                      <div className="campaign-list">
                        {campaignData.map((item, i) => (
                          <Col
                            xl="12"
                            md="12"
                            xs="12"
                            key={i}
                            onClick={() =>
                              this.setState({
                                isActiveScheduleTab: i,
                              })
                            }
                          >
                            <div
                              className={classnames(
                                {
                                  "background-color-yellow":
                                    this.state.isActiveScheduleTab === i,
                                  "mt-0": i === 0,
                                },
                                "Combined-Shape"
                              )}
                              onClick={this.openDetailsList.bind(this, item)}
                            >
                              <div className="Campaign1">
                                {item.campaign_name}
                              </div>
                              <div className="Birthday-Wish">
                                {item.purpose_name && (
                                  <span>{item.purpose_name}</span>
                                )}
                                <span
                                  className="All-500-Customers"
                                  style={{
                                    marginLeft: this.state.isHoLogin && "0",
                                  }}
                                >
                                  {item.customer_target_type === "jk_user"
                                    ? "JK User"
                                    : item.customer_target_type ===
                                      "jk_secondary_point"
                                      ? "JK Secondary User"
                                      : item.customer_target_type ===
                                        "jk_promotion_and_services"
                                        ? "Promotional & Events Users"
                                        : _.upperFirst(item.customer_target_type)}
                                  ({item.customers_count})
                                </span>
                                <span className="Fill-arrow">
                                  <img src="images/right.svg" alt="" />
                                </span>
                              </div>
                              <div className="datefor">
                                <span className="scheduledfor">
                                  Started at :
                                </span>{" "}
                                <Moment format="DD/MM/YYYY  hh:mm A">
                                  {item.started_at}
                                </Moment>
                              </div>
                            </div>
                            <hr className="Showing-scheduled-campaigns" />
                          </Col>
                        ))}
                      </div>
                    )}
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          <div style={{ marginLeft: "50px" }} />
          <Col sm="7" style={{ paddingTop: "0px" }}>
            {campaignCount.scheduled_count !== 0 &&
              this.state.scheduledCountForSearch === 0 &&
              this.state.is_scheduled ? (
              <Card className="w-100">
                <CardBody style={{ height: "600px" }}>
                  <Row>
                    <Col sm="8" className="Click-on-the-Create">
                      We can not find any records matching your search criteria.
                      Please change search criteria and try again.
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              ""
            )}

            {campaignCount.completed_count !== 0 &&
              this.state.completedCountForSearch === 0 &&
              !this.state.is_scheduled ? (
              <Card className="w-100">
                <CardBody style={{ height: "600px" }}>
                  <Row>
                    <Col sm="8" className="Click-on-the-Create">
                      We can not find any records matching your search criteria.
                      Please change search criteria and try again.
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              ""
            )}

            {createCampaign &&
              // (routUrl === "history" || routUrl === "schedule" || routUrl === "create") &&
              !this.state.openCreateCampaignPage &&
              !this.state.filterPopUp &&
              (campaignCount.scheduled_count === 0 || campaignCount.scheduled_count === '0') &&
              this.state.is_scheduled ? (
              <Card className="w-100">
                <CardBody style={{ height: "600px" }}>
                  <Row>
                    <Col sm="8" className="Click-on-the-Create">
                      Click on the ‘Create Campaign’ button to create a new
                      campaign.
                    </Col>
                    <Col xl="12" md="12" xs="12" className="margin-top-20" />
                    <Col sm="8" className="mt-3">
                      <Button
                        className="rectangleButton"
                        onClick={this.openCreateCampaignPage}
                      >
                        <b>Create Campaign</b>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              ""
            )}

            {createCampaign &&
              // (routUrl === "history" || routUrl === "schedule" || routUrl === "create") &&
              !this.state.filterPopUp &&
              !this.state.openCreateCampaignPage &&
              (campaignCount.completed_count === 0 || campaignCount.completed_count === '0') &&
              !this.state.is_scheduled ? (
              <Card className="w-100">
                <CardBody style={{ height: "600px" }}>
                  <Row>
                    <Col sm="8" className="Click-on-the-Create">
                      Click on the ‘Create Campaign’ button to create a new
                      campaign.
                    </Col>
                    <Col xl="12" md="12" xs="12" className="margin-top-20" />
                    <Col sm="8" className="mt-3">
                      <Button
                        className="rectangleButton"
                        onClick={this.openCreateCampaignPage}
                      >
                        <b>Create Campaign</b>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              ""
            )}

            {this.state.openCreateCampaignPage && (
              <CreateCampaign
                ref={this.childCreateCampaign}
                onSelectTargetGroup={this.changeTargetGroup}
                resetData={this.resetData}
                redirectDetails={this.props.redirectDetails}
                removeDashboardRedirectData={this.removeDashboardRedirectData}
                openCreateCampaignPageNew={this.state.openCreateCampaignPageNew}
                userDetail={this.state.userDetail}
                CampaignDetailData={this.state.CampaignDetailData}
                filterStates={this.state.filterStates}
                filteredObj={this.state.CustomerListFilteredObj}
                mapFilterObjWithCampaignObj={this.mapFilterObjWithCampaignObj}
                mapCampaignObjWithFilterObj={this.mapCampaignObjWithFilterObj}
                openFilterFromSelectPurpose={this.openFilterFromSelectPurpose}
                confirmLostFilter={this.confirmLostFilter}
                is_first_time_reset_filter_from_customer_list={
                  this.state.is_first_time_reset_filter_from_customer_list
                }
                resetFilter={this.applyFilter}
                setCustomerListFilteredObj={this.setCustomerListFilteredObj}
              />
            )}
            {this.state.targetgroup === "customise" && this.state.opensidebar && (
              <Sidebar
                sidebar={
                  <>
                    <CustomerManagementFilterNew
                      ref={this.childCustomerManagementFilter}
                      applyFilter={this.applyFilter}
                      resetFilter={this.applyFilter}
                      onCloseSidebar={this.onCloseSidebarFilter}
                      filteredData={CustomerListFilteredObj}
                      from={"campaign"}
                      // getFilterControls={this.state.filterControls}
                      filter={filterStates}
                    />
                    {/* <CampaignCustomize
                      ref={this.childCustomiseDetail}
                      resetFilter={this.state.CampaignDetailData}
                      CampaignDetailData={this.state.CampaignDetailData}
                      onSelectTargetGroup={this.changeTargetGroup}
                      getFilterControls={this.state.filterControls}
                    /> */}
                  </>
                }
                open={this.state.opensidebar}
                sidebarClassName="customer-management-filter"
                pullRight
                styles={{
                  sidebar: {
                    background: "white",
                    height: this.state.height ? this.state.height : "90%",
                    top: "62px",
                    // width: "60%",
                    borderRadius: "2px",
                  },
                }}
              />
            )}

            {this.state.openCompaignDetailSideBar &&
              campaignCount.scheduled_count !== 0 &&
              (this.state.scheduledCountForSearch !== 0 || this.state.scheduledCountForSearch !== '0') &&
              this.state.is_scheduled ? (
              <CampaignDetail
                campaigntransfrDatatodetail={
                  this.state.campaigntransfrDatatodetail
                }
                is_scheduled={this.state.is_scheduled}
                resetData={this.resetData}
                onSelectTargetGroup={this.changeTargetGroup}
                getSMSCredits={this.getSMSCredits}
                isHoLogin={this.state.isHoLogin}
              />
            ) : (
              ""
            )}
            {this.state.openCompaignDetailSideBar &&
              (campaignCount.completed_count !== 0 || campaignCount.completed_count !== '0') &&
              (this.state.completedCountForSearch !== 0 || this.state.completedCountForSearch !== '0') &&
              !this.state.is_scheduled ? (
              <CampaignDetail
                campaigntransfrDatatodetail={
                  this.state.campaigntransfrDatatodetail
                }
                is_scheduled={this.state.is_scheduled}
                resetData={this.resetData}
                onSelectTargetGroup={this.changeTargetGroup}
                getSMSCredits={this.getSMSCredits}
                isHoLogin={this.state.isHoLogin}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDealerDashboardRedirectDetail: (redirectDetails) =>
    dispatch(setDealerDashboardRedirectDetail(redirectDetails)),
  setRedirect: (redirectDetails) => dispatch(setRedirect(redirectDetails)),
  setHelpGuide: (helpGuide) => dispatch(setHelpGuide(helpGuide)),
});

//export default Campaign;
const mapStateToProp = (state) => ({
  redirectDetails: state.User.redirectDetails,
});

export default connect(mapStateToProp, mapDispatchToProps)(Campaign);
