import React, { Component } from "react";
import {
    Button,
    FormGroup,
    Label,
    Row,
    Col,
    Input,
} from "reactstrap";
import Ionicon from "react-ionicons";
import PosterView from "../../components/PosterPreview/PosterView";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import PosterService from "../../services/PosterService";
import { downlodFile } from "../../utils/utils";

const { ERROR_MESSAGE } = messages.COMMON;
class PosterPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            controls: {
                type: {
                    value: 'pdf',
                    valid: null,
                    touched: false,
                    nullValue: null,
                    invalidEmail: null,
                    required: true,
                    showErrorMsg: false,
                    autoFocus: false
                },
                address: {
                    value: '',
                    valid: null,
                    touched: false,
                    nullValue: null,
                    invalidEmail: null,
                    required: true,
                    showErrorMsg: false,
                    autoFocus: false
                },
                mobile: {
                    value: '',
                    valid: null,
                    touched: false,
                    nullValue: null,
                    invalidEmail: null,
                    required: true,
                    showErrorMsg: false,
                    autoFocus: false
                },
                name: {
                    value: '',
                    valid: null,
                    touched: false,
                    nullValue: null,
                    invalidEmail: null,
                    required: true,
                    showErrorMsg: false,
                    autoFocus: false
                }
            },
            customerVehicleData: [],
            programData: [],
            selectedPoster: null,
            posterDeatil: null,
            editDetail: false
        };
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.state.selectedPoster = this.props.selectedPoster;
        this.state.posterDeatil = this.props.posterDeatil;

        if (this.props && this.props.posterDeatil && this.props.posterDeatil.name && this.props.posterDeatil.name !== '--') {
            this.state.controls.name.value = this.props.posterDeatil.name;
        }
        if (this.props && this.props.posterDeatil && this.props.posterDeatil.address && this.props.posterDeatil.address !== '--') {
            this.state.controls.address.value = this.props.posterDeatil.address;
        }
        if (this.props && this.props.posterDeatil && this.props.posterDeatil.mobile && this.props.posterDeatil.mobile !== '--') {
            this.state.controls.mobile.value = this.props.posterDeatil.mobile;
        }
    }

    handleRadioChange(e) {
        let { controls } = this.state;
        controls.type.value = e;
        this.setState({ controls });
    };

    componentDidMount() {
    };

    handleInputChange = (e) => {
        let controlName = e.target.name;
        let controlValue = e.target.value;

        if (controlName === 'mobile') {
            controlValue = controlValue.replace(/[^0-9]/g, '')
        }

        const { controls } = this.state;
        controls[controlName].value = controlValue;
        controls[controlName].touched = true;
        if (controls[controlName].touched === true && (controls[controlName].value === '' || controls[controlName].value === null)) {
            controls[controlName].showErrorMsg = true;
        } else {
            controls[controlName].showErrorMsg = false;
        }
        if(e.target.name === 'mobile' && (controls.mobile.touched === true)) {
            controls.mobile.errorMsg = null;
        }
        if(e.target.name === 'address' && (controls.address.touched === true) && (controls.address.value).length <= 100) {
            controls.address.errorMsg = null;
        } else {
            controls.address.errorMsg = 'Only 100 characters are allowed';
            controls.address.showErrorMsg = false;
        }
        this.setState({ controls });
    };

    onChangeDate = (e, controlName) => {
        const { controls } = this.state;
        controls[controlName].value = e;
        controls[controlName].touched = true;
        this.setState({ controls });
    };

    handleOnCancel = () => {
        this.props.onCloseSidebar(false);
    };

    editCotactDetail = () => {
        this.setState({ editDetail: true });
    }

    handleOnCancelEdit = () => {
        this.setState({ editDetail: false });
    }

    addEditPosterDetail = () => {
        const { controls } = this.state;

        if (!this.handleValidation(true)) {
            return;
        }

        this.setState({ isLoading: true });

        let data = {
            "address": controls.address.value,
            "pincode": "",
            "mobile": controls.mobile.value,
            "email": "",
            "name": controls.name.value
        };

        PosterService.addEditPosterDetail(data)
            .then(res => {

                let { posterDeatil } = this.state;
                if (res.data.data && res.data.data && res.data.data) {
                    posterDeatil = res.data.data;
                }

                this.setState({
                    posterDeatil,
                    isLoading: false,
                    editDetail: false
                }, () => {
                    this.downloadPoster();
                })

            })
            .catch(e => {
                this.setState({ isLoading: false });
                const err =
                    e.response && e.response.data && e.response.data.error
                        ? e.response.data.error[0]
                        : null;
                const message = err && err.message ? err.message : ERROR_MESSAGE;
                ModalService.openAlert("", message, "error");
            });
    }

    handleValidation = (isSubmit = false) => {
        let { controls, isFormValid } = this.state;
        let { address, name, mobile } = controls;
        isFormValid = true;

        if ((address.touched === true || isSubmit) && address.value === '') {
            address.showErrorMsg = true;
            isFormValid = false;
        }
        if ((name.touched === true || isSubmit) && name.value === '') {
            name.showErrorMsg = true;
            isFormValid = false;
        }
        if ((mobile.touched === true || isSubmit) && mobile.value === '') {
            mobile.showErrorMsg = true;
            mobile.errorMsg = null;
            isFormValid = false;
        }
        if((mobile.touched === true || isSubmit) && (mobile.value).length !== 10 && (mobile.value).length > 0) {
            mobile.errorMsg = 'Please Add 10 digit valid number';
            mobile.showErrorMsg = false;
            isFormValid = false;
        }
        if((address.touched === true || isSubmit) && (address.value).length > 100) {
            address.errorMsg = 'Only 100 characters are allowed';
            address.showErrorMsg = false;
            isFormValid = false;
        }

        this.setState({ controls, isFormValid });
        return isFormValid;
    };

    downloadPoster = () => {
        const { controls } = this.state;

        this.setState({ isLoading: true });

        let data = {
            'name': controls.name.value,
            'address': controls.address.value,
            'mobile': controls.mobile.value,
            'image_backgroung': this.state.selectedPoster.poster_url,
            'category': this.state.selectedPoster.category,
            'template': this.state.selectedPoster.template,
            'type': controls.type.value
        };

        PosterService.downloadPoster(data)
            .then(res => {

                this.downloadPosterDetail({
                    "uuid": this.state.selectedPoster.uuid,
                    "type": controls.type.value === 'pdf' ? 'pdf' : 'jpeg' 
                });

                if (res.data.data && res.data.data && res.data.data) {
                    downlodFile(res.data.data.file);
                }

                this.setState({ isLoading: false });

            })
            .catch(e => {
                this.setState({ isLoading: false });
                const err =
                    e.response && e.response.data && e.response.data.error
                        ? e.response.data.error[0]
                        : null;
                const message = err && err.message ? err.message : ERROR_MESSAGE;
                ModalService.openAlert("", message, "error");
            });
    }

    downloadPosterDetail = (data) => {
        PosterService.downloadPosterDetail(data)
            .then(res => {
            })
            .catch(e => {
                // this.setState({ isLoading: false });
                const err =
                    e.response && e.response.data && e.response.data.error
                        ? e.response.data.error[0]
                        : null;
                const message = err && err.message ? err.message : ERROR_MESSAGE;
                ModalService.openAlert("", message, "error");
            });
    }
    

    openViewModal = (data) => {
        data = {
            ...data,
            ...this.state.posterDeatil
        };
        this.refs.openViewModal.toggle(data);
    }

    render() {
        const {
            controls
        } = this.state;
        return (
            <>
                {this.state.isLoading && <CustomSpinner />}
                <>
                    <div className="drawer-header">
                        <span className="drawer-title" style={{ marginTop: "5px" }}>
                            Download Poster
                        </span>
                        <Ionicon
                            className="cursor-pointer"
                            icon="md-close"
                            fontSize="24px"
                            color="#fdbb1f"
                            onClick={() => this.handleOnCancel()}
                        />
                    </div>
                    <hr style={{ border: "solid 1px #ced4da" }} />
                    <div className="custom-drawer-content-wrapperr Fix-height">

                        <Row style={{ marginLeft: "0", marginRight: "0" }}>
                            <Col xl="7" md="7" xs="12" style={{ textAlign: 'right' }}>
                                <img src={this.state.selectedPoster.reference_poster_thumbnail_url}
                                 alt=''
                                    onClick={() => this.openViewModal(this.state.selectedPoster)}
                                    className="cursor-pointer"
                                    style={{ width: '55%' }} />
                            </Col>
                            <Col xl="5" md="5" xs="12">
                                <div style={{ height: '30px', lineHeight: '200px', display: 'flex' }}
                                    onClick={() => this.openViewModal(this.state.selectedPoster)}
                                    className="cursor-pointer redeem-points">
                                    Preview
                                </div>
                            </Col>

                        </Row>
                        <hr style={{ border: "solid 1px #ced4da" }} />
                        <Row style={{ marginLeft: "0", marginRight: "0" }}>
                            <Col xl="12" md="12" xs="12" className="note">
                                <span>
                                    <i>
                                        Please verify the contact details!! If you want to download the poster with
                                        diffrent contact details please edit here and click on Download button
                                    </i>
                                </span>
                            </Col>
                        </Row>
                        {!this.state.editDetail && (
                            <Row className="margin-top-10" style={{ marginLeft: "0", marginRight: "0" }}>
                                <Col xl="12" md="12" xs="12" className="title">
                                    Contact Details
                                        <img
                                        className="cursor-pointer"
                                        src="images/edit.svg"
                                        alt=''
                                        style={{ marginLeft: "10px", marginBottom: "3px", height: "12px" }}
                                        onClick={() => this.editCotactDetail()}
                                    />
                                </Col>
                                <Col xl="6" md="6" xs="12" className="margin-top-10">
                                    <span className="title">
                                        Bussiness name
                                    </span>
                                    <br/>
                                    {this.state.posterDeatil.name}
                                </Col>
                                <Col xl="6" md="6" xs="12" className="margin-top-10">
                                    <span className="title">
                                        Mobile
                                    </span>
                                    <br/>
                                    {this.state.posterDeatil.mobile}
                                </Col>
                                <Col xl="12" md="12" xs="12" className="margin-top-10">
                                    <span className="title">
                                        Address
                                    </span>
                                    <br/>
                                    {this.state.posterDeatil.address}
                                </Col>
                            </Row>
                        )}
                        {this.state.editDetail && (
                            <Row className="margin-top-10" style={{ marginLeft: "0", marginRight: "0" }}>
                                <Col xl="12" md="12" xs="12" className="title">
                                    Contact Details
                                </Col>
                                <Col xl="6" md="6" xs="12" className="margin-top-10">
                                    <Label for="common" style={{ marginBottom: '0px' }}>Bussiness Name &nbsp;<span className="required-field">*</span></Label>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            autoComplete="nofill"
                                            name="name"
                                            id="name"
                                            value={controls.name.value}
                                            onChange={this.handleInputChange}
                                            placeholder="Type here"
                                        />
                                        {controls.name.showErrorMsg && (
                                            <div className="error">
                                                * Please add name.
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col xl="6" md="6" xs="12" className="margin-top-10">
                                    <Label for="common" style={{ marginBottom: '0px' }}>Mobile &nbsp;<span className="required-field">*</span></Label>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            value='+91'
                                            disabled
                                            style={{ float: 'left', width: '24%', marginRight: '10px' }}
                                        />
                                        <Input
                                            type="text"
                                            autoComplete="nofill"
                                            name="mobile"
                                            id="mobile"
                                            maxLength="10"
                                            value={controls.mobile.value}
                                            onChange={this.handleInputChange}
                                            placeholder="Type here"
                                            style={{ marginLeft: '5px', width: '70%' }}
                                        />
                                        {controls.mobile.showErrorMsg && (
                                            <div className="error">
                                                * Please add phone number.
                                            </div>
                                        )}
                                        {controls.mobile.errorMsg && (
                                            <div className="error">
                                                * {controls.mobile.errorMsg}
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col xl="12" md="12" xs="12" >
                                    <Label for="common" style={{ marginBottom: '0px' }}>Address &nbsp;<span className="required-field">*</span></Label>
                                    <FormGroup>
                                        <Input
                                            style={{'fontSize': '14px'}}
                                            type="textarea"
                                            rows="2"
                                            autoComplete="nofill"
                                            name="address"
                                            id="address"
                                            value={controls.address.value}
                                            onChange={this.handleInputChange}
                                            placeholder="Type here"
                                        />
                                        <Row>
                                            <Col xl="10" md="10" xs="12" >
                                                {controls.address.showErrorMsg && (
                                                    <div className="error">
                                                        * Please add address detail.
                                                    </div>
                                                )}
                                                {controls.address.errorMsg && (
                                                    <div className="error">
                                                        * {controls.address.errorMsg}
                                                    </div>
                                                )}
                                            </Col>
                                            <Col xl="2" md="2" xs="12" >
                                                <div className="">
                                                    {controls.address.value.length}&nbsp;/&nbsp;100
                                                </div>
                                            </Col>
                                        </Row>

                                        
                                    </FormGroup>
                                </Col>

                                {/* <Col
                                    xl="12"
                                    xs="12"
                                    md="12"
                                    className="footer-drawer"
                                >
                                    <Button
                                        className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                                        color="dark"
                                        onClick={() => this.handleOnCancelEdit()} style={{ width: '116px' }}>
                                        Cancel
                                </Button>
                                    <Button
                                        className="mb-1 mr-1 btn-pill action-button profile-save-button"
                                        color="warning"
                                        onClick={() => {
                                            this.addEditPosterDetail();
                                        }}
                                    >
                                        Save
                                </Button>
                                </Col> */}

                            </Row>
                        )}

                    </div>

                    <Row
                        style={{
                            marginLeft: "0",
                            marginRight: "0",
                            height: "100%",
                            maxHeight: "80px",
                            position: "relative",
                            bottom: "0",
                            width: "100%"
                        }}
                    >
                        <Col xl="3" md="12" xs="12" className="text-align-right footer-drawer" style={{ paddingTop: '20px' }}>
                            <FormGroup>
                                <div className="mr-10 d-flex-row pcrtype-wrapp">
                                    <label className="custom-checkboax-radio--container">
                                        <input
                                            type="radio"
                                            name="type"
                                            value="pdf"
                                            onChange={() => {
                                                this.handleRadioChange("pdf");
                                            }}
                                            checked={controls.type.value === "pdf"} />
                                        <span className="checkmark-radio" />
                                    </label>
                                    <span className="label-text marg-pad" style={{ paddingLeft: '15px' }}>PDF</span>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col xl="3" md="12" xs="12" className="text-align-right footer-drawer" style={{ paddingTop: '20px' }}>
                            <FormGroup>
                                <div className="mb-1 mr-1 d-flex-row pcrtype-wrapp">
                                    <label className="custom-checkboax-radio--container">
                                        <input type="radio"
                                            name="type"
                                            value="jpeg"
                                            onChange={() => {
                                                this.handleRadioChange("jpeg");
                                            }}
                                            checked={controls.type.value === "jpeg"} />
                                        <span className="checkmark-radio" />
                                    </label>
                                    <span className="label-text marg-pad" style={{ paddingLeft: '15px' }}>JPEG</span>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col
                            xl="6"
                            xs="6"
                            md="6"
                            className="text-align-right footer-drawer"
                        >
                            <Button
                                className="mb-1 mr-1 btn-pill action-button profile-save-button"
                                color="warning"
                                onClick={() => {
                                    this.state.editDetail ? this.addEditPosterDetail() : this.downloadPoster();
                                }}
                            >
                                Download
                                </Button>
                        </Col>
                    </Row>
                    <PosterView
                        title="Poster"
                        ref="openViewModal"
                    />
                </>
            </>
        );
    }
}

export default PosterPreview;
