// you can combine multiple reducers see the commented code in this file
import { combineReducers } from 'redux';

// import user from './user';
import ThemeOptions from './ThemeOption';
import User from './UserReducer';
import RetailManagementSystemReducer from './RetailManagementSystemReducer';
import EventRegistrationSystemReducer from './EventRegistrationSystemReducer';

export default combineReducers({
  ThemeOptions,
  User,
  RetailManagementSystemReducer,
  EventRegistrationSystemReducer
});