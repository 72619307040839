import React, { Component, Fragment } from 'react';
import { Button, Table, Modal, ModalBody, ModalFooter, ModalHeader, Input, Alert } from 'reactstrap';
import PropTypes from 'prop-types';

import MerchandiseService from '../../services/MerchandiseService';

class PerformaInvoiceUpdate extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      selectedOrders: data,
      orderAmount: '',
      orderAmountOfOrderId: '',
      error: ''
    };

    this.getOrderTable = this.getOrderTable.bind(this);
    this.saveAndPrint = this.saveAndPrint.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (data !== this.props.data) {
      this.setState({
        selectedOrders: data
      });
    }

    return null;
  }

  onAmountChange(e, orderId) {
    const { target: { name, value } } = e;
    const controlName = name.split(':')[0] || null;
    const controlValue = value;
    const { selectedOrders } = this.state;

    const updatedOrders = selectedOrders.map((order) => {
      if (order.id === orderId) {
        order.amount_per_item = controlValue || 0;
      }

      return order;
    });

    if (controlName) {
      this.setState({
        [controlName]: controlValue !== '' ? controlValue : null,
        orderAmountOfOrderId: orderId
      });
    }

    this.setState({
      selectedOrders: updatedOrders
    });
  }

  getOrderTable() {
    const { selectedOrders, orderAmount, orderAmountOfOrderId } = this.state;

    return (
      <Table>
        <thead>
          <tr>
            <th>Item name</th>
            <th>Quantity</th>
            <th>Amount per unit</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {selectedOrders.map(({ id, item_name: name, item_quantity: quantity, amount_per_item: amount = 0 }) => {
            const orderAmountPerItem = orderAmountOfOrderId === id && orderAmount ? orderAmount : (amount || '');
            return (
              <tr key={id}>
                <td>{name}</td>
                <td>{quantity}</td>
                <td>
                  <Input type="text" name={`orderAmount:${id}`} id={`orderAmount:${id}`} placeholder="Amount"
                    style={{ width: '120px', fontSize: '0.88rem' }}
                    value={orderAmountPerItem}
                    onChange={(e) => this.onAmountChange(e, id)}
                  />
                </td>
                <td>
                  {orderAmountPerItem * quantity}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  async saveAndPrint(awb = false, e) {
    e.preventDefault();
    const { selectedOrders } = this.state;
    const { history } = this.props;

    const orderWithoutAmount = selectedOrders.find(o => (!o.amount_per_item || o.amount_per_item < 0));

    if (orderWithoutAmount) {
      this.setState({ error: 'Amount per unit is required' });
      return false;
    }

    const requestBody = selectedOrders.map(o => ({ id: o.id, amount: o.amount_per_item }));
    await MerchandiseService.updateMerchandiseOrderAmuont({ orders: requestBody, awb });

    history.push({
      pathname: 'print-performa-invoice',
      data: selectedOrders,
    });
    return true;
  }

  render() {
    const { isOpen, onExit } = this.props;
    const { error } = this.state;
    return (
      <Fragment>
        <Modal id="performa-invoice-update" isOpen={isOpen} toggle={onExit} onExit={onExit} size='xl' centered scrollable>
          <ModalHeader toggle={onExit}>Enter Amount</ModalHeader>
          <ModalBody>
            {error && <Alert color="danger" toggle={() => this.setState({ error: '' })} fade={false}>
              {error}
            </Alert>}
            {this.getOrderTable()}
          </ModalBody>
          <ModalFooter>
            <Button color='primary' type='button' onClick={this.saveAndPrint.bind(this, true)}>Save & Awb</Button>
            <Button color='primary' type='button' onClick={this.saveAndPrint.bind(this, false)}>Save & Print</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

PerformaInvoiceUpdate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  data: PropTypes.shape([]).isRequired
};

export default PerformaInvoiceUpdate;