import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CustomSpinner from '../CustomSpinner/CustomSpinner';

class PosterView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      details: {},
      posterDeatil: {},
      isLoading: false,
      pageNumber: 1,
      scale: 1,
      imageWidth: 570,
      imageHeight: 590
    }
    this.toggle = this.toggle.bind(this);
  }

  toggle(data) {
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      details: data
    }));
    this.setState({
      scale: 1,
      imageWidth: 570,
      imageHeight: 590
    });
  }

  zoomInImage = () => {
    let { imageWidth, imageHeight } = this.state;

    if (imageWidth > 1300) {
      return;
    }

    imageWidth += 100;
    imageHeight += 100;
    this.setState({ imageWidth, imageHeight });
  }

  zoomOutImage = () => {
    let { imageWidth, imageHeight } = this.state;
    if (imageWidth <= 570) {
      return;
    }
    imageWidth -= 100;
    imageHeight -= 100;
    this.setState({ imageWidth, imageHeight });
  }

  render() {
    let { details } = this.state;
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <Modal isOpen={this.state.modal} toggle={this.toggle} external={externalCloseBtn}
          className="filer-popup"
        >
          <div className="d-flex-column">
            <div className="confirm-modal-title-wrapp">
              <span className="confirm-modal-title">{this.props.title}</span>
              <FontAwesomeIcon className="pull-right cursor-pointer" onClick={() => this.toggle({})} icon={faTimes} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
            </div>
          </div>
          <ModalBody classNam="d-flex-column">
            <div className="activity-detail">
              <>
                <>
                  {/* <Row style={{ marginTop: '15px' }}>
                                        <Col xl="10" md="10" sm="8" xs="8">
                                            <span className="invoice-1jpg">
                                            </span>
                                        </Col>
                                        <Col>
                                            <div className="pull-right">
                                                <FontAwesomeIcon onClick={this.zoomInImage} icon={faPlusCircle} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
                                                        &nbsp;&nbsp;
                                                        <FontAwesomeIcon onClick={this.zoomOutImage} icon={faMinusCircle} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
                                            </div>
                                        </Col>
                                    </Row> */}
                  <Row style={{}}>
                    <Col style={{ textAlign: 'center' }}>
                      <img src={this.props.from === 'list' ? details.poster_url : details.reference_poster_url} alt={details.name} width='420px' className="img-viewer" />
                    </Col>

                    {/* <Col style={{ textAlign: 'center', marginTop: '-22px' }}>
                                            <span style={{ color: 'black',fontFamily: 'Helvetica', fontSize: '12px', fontWeight: 'bold' }}>
                                                {details.name},{details.address}-PH:{details.mobile}
                                            </span>
                                        </Col> */}

                  </Row>
                </>
              </>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default PosterView;