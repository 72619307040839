import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DatePicker from "react-datepicker";
import ProposalSerivice from "../../services/ProposalSerivice";
import swal from "sweetalert";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import Validation from "../../services/Validation";
import { messages } from "../../constants/constant.message";
import ModalService from "../../services/ModalService";
class UploadAgreementPTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      proposalStageDetail: props.proposalStageDetail,
      controls: {
        document: {
          value: null,
          showErrorMsg: false,
          touched: false,
          valid: false,
        },
        aggrement_date: {
          value: null,
          showErrorMsg: false,
          touched: false,
          valid: false,
        },
      },
    };
  }

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };

  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { controls } = this.state;
      if (data && data.data) {
        controls[type].value = data.data;
        controls[type].showErrorMsg = false;
        controls[type].touched = true;
      }
      this.setState({ controls, isLoading: false }, () => {
        this.handleValidation();
      });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  submitAggrementDocuments = () => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    const { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    if (!this.handleValidation(true)) {
      return;
    }
    let documents = [];
    documents.push({
      type: "agreement",
      file: controls.document.value,
      aggrement_date: controls.aggrement_date.value,
    });
    const obj = { documents: documents };
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.uploadAgreement(obj)
      .then((res) => {
        if (this.fileInput3) {
          this.fileInput3.value = "";
        }
        swal({
          title: "",
          text: "Agreement document added successfully",
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.getProposalStageDetail();
        });
        controls.aggrement_date.value = null;
        controls.document.value = null;
        controls.aggrement_date.touched = false;
        controls.document.touched = false;
        this.setState({ isLoading: false, controls });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  uploadFile = (event, type) => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      this.uploadFileFormData(file, type);
    } else {
      const { controls } = this.state;
      controls[type].value = null;
      controls[type].touched = true;
      this.setState({ controls });
    }
  };

  onChangeDate = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const { controls } = this.state;
    controls["aggrement_date"].value = e;
    controls["aggrement_date"].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };
  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let { document, aggrement_date } = controls;
    let isFormValid = false;

    if (document.touched === true || isSubmit) {
      document = Validation.notNullValidator(document);
      document.valid = !document.nullValue;
      if (document.valid === false) {
        document.showErrorMsg = true;
      } else {
        document.showErrorMsg = false;
      }
    }
    if (aggrement_date.touched === true || isSubmit) {
      aggrement_date = Validation.notNullValidator(aggrement_date);
      aggrement_date.valid = !aggrement_date.nullValue;
      if (aggrement_date.valid === false) {
        aggrement_date.showErrorMsg = true;
      } else {
        aggrement_date.showErrorMsg = false;
      }
    }
    if (aggrement_date.valid && document.valid) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls,
    });
    return isFormValid;
  };

  render() {
    let { controls } = this.state;
    let { proposalStageDetail } = this.props;
    return (
      <div style={{ position: "relative" }}>
        {this.state.isLoading && <CustomSpinner />}
        <br />
        <Card>
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Upload Agreement</b>
              </Col>
            </Row>
            <hr />
            {this.props.currentPriority <= 2 && (
              <Row>
                <Col xl="4" md="12" xs="12">
                  <FormGroup>
                    <Label>
                      Agreement Document (jpeg, jpg, png and pdf file)
                      <span className="required-field">*</span>
                    </Label>
                    <input
                      type="file"
                      ref={(ref) => (this.fileInput3 = ref)}
                      accept="image/*,.pdf"
                      name="layout"
                      onChange={(e) => this.uploadFile(e, "document")}
                    />
                  </FormGroup>
                  {controls.document && controls.document.showErrorMsg && (
                    <div className="error">*Please upload document.</div>
                  )}
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup className="d-flex-column">
                    <Label>
                      Agreement Date<span className="required-field">*</span>
                    </Label>
                    <Label
                      onClick={(e) =>
                        this.calendar1.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={(e) => this.onChangeDate(e)}
                          name="aggrement_date"
                          selected={controls.aggrement_date.value}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={(r) => (this.calendar1 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          minDate={new Date("2022-01-01T00:00:00.024Z")}
                          maxDate={new Date()}
                          dropdownMode="select"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                    {controls.aggrement_date.showErrorMsg && (
                      <div className="error">
                        * Please select agreement date.
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl="5" md="12" xs="12" style={{ display: "flex" }}>
                  <Button
                    className="mr-1 ml-auto btn-pill action-button action-ob-button font-weight-bold mt-auto mb-auto"
                    color="warning"
                    type="submit"
                    onClick={(e) => this.submitAggrementDocuments()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            )}
            <br />
            {proposalStageDetail &&
              proposalStageDetail.upload_agreement_ptp &&
              proposalStageDetail.upload_agreement_ptp.length > 0 && (
                <Table className="mb-0  tbl-about tbl-about table-responsive">
                  <thead>
                    <tr>
                      <th>Document</th>
                      <th>Agreement Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposalStageDetail.upload_agreement_ptp.map((item) => (
                      <tr>
                        <td>
                          <a
                            className="d-flex-column aa"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={item.document}
                          >
                            <img
                              src="images/sidebar-icons/secondary-report-selected.svg"
                              alt=""
                              height="40px"
                              style={{ margin: "auto" }}
                            />
                            <span className="redirect-link">View</span>
                          </a>
                        </td>
                        <td>
                          {item.aggrement_date ? (
                            item.aggrement_date
                          ) : (
                            <span className="no-data-hypen  ml-auto mr-auto"></span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UploadAgreementPTP;
