import React, { Component, Fragment } from "react";
import Chart from "react-apexcharts";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
  CardHeader,
  Card,
  CardBody
} from "reactstrap";
import classnames from "classnames";

class TwoThreeWheelerPoints extends Component {
  quarter2 = {
    headers: [
      "Tyre Slab(June 19'Offtake)",
      "Points/Tyre",
      "Points/Tyre",
      "Points/Tyre"
    ],
    note:
      "For any DEALER to Qualify for CRM Points in 2/3 Wheeler category;the dealer has to do minimum 20 TYRES in a month.",
    basePoints: [
      "Base Points : Motorcycle 20/Tyre",
      "Base Points : Scooter 15/Tyre",
      "Base Points : 3 Wheeler 10/Tyre"
    ],
    points: [
      ["20-49", "20", "25", "10"],
      ["50-99", "2*20", "2*15", "2*10"],
      ["100-174", "3*20", "3*15", "3*10"],
      ["175-299", "4*20", "4*15", "4*10"],
      ["300+", "5*20", "5*15", "5*10"]
    ]
  };
  constructor(props) {
    super(props);
    let charts = [];

    this.state = {
      activeTab: 0,
      showMore: true,
      transform: true,
      showInkBar: true,
      selectedTabKey: 0,
      transformWidth: 400,
      charts: charts
    };
    //this.getAboutDetail();
  }

  componentDidMount() {
    let twoThreeWheelerPoints = this.props.twoThreeWheelerPonits;
    if (twoThreeWheelerPoints && !(twoThreeWheelerPoints.tabs[0] === 'Quarter 1' && twoThreeWheelerPoints.year === '2019-20')) {
      let currentTab = twoThreeWheelerPoints.tabs[0];
      this.generateChart(currentTab, twoThreeWheelerPoints[currentTab].basePoints);
    }
  }

  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }
  toggleTab = (tab, tabName) => {
    if (this.state.activeTab !== tab) {
      let twoThreeWheelerPoints = this.props.twoThreeWheelerPonits;
      if (!(tabName === 'Quarter 1' && twoThreeWheelerPoints.year === '2019-20')) {
        this.generateChart(tabName, twoThreeWheelerPoints[tabName].basePoints);
      }
      this.setState({
        activeTab: tab
      });

    }
  }

  generateChart = (tabName, basePoints) => {
    const charts = [];
    let twoThreeWheelerPoints = this.props.twoThreeWheelerPonits;
    basePoints.map((item, i) =>
      charts.push({
        options: {
          labels: [item],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "40%"
              },
              dataLabels: {
                showOn: "always",
                name: {
                  offsetY: 0,
                  show: true
                },
                value: {
                  color: "#111",
                  fontSize: "30px",
                  show: false
                }
              }
            }
          }
        },
        series: [twoThreeWheelerPoints[tabName].dummyChartPoints[i]]
      })
    );
    this.setState({ charts });
  }
  render() {
    // const { charts = [] } = this.state;
    let twoThreeWheelerPoints = this.props.twoThreeWheelerPonits;
    return (
      <Fragment>
        <Card
          tabs="true"
          className="mb-3 about-tab-header"
          style={{ boxShadow: "none" }}
        >
          <CardHeader className="card-header-tab">
            <Nav style={{ marginLeft: "0" }}>
              {twoThreeWheelerPoints.tabs.map((t, index) =>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={classnames({
                      active: this.state.activeTab === index
                    })}
                    onClick={() => {
                      this.toggleTab(index, t);
                    }}
                  >
                    {t}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </CardHeader>
          <br />
          <CardBody style={{ padding: "0" }}>
            <TabContent
              activeTab={this.state.activeTab}
              style={{ marginTop: "30px" }}
            >
              {twoThreeWheelerPoints.tabs.map((t, index) => {
                return t === 'Quarter 1' && twoThreeWheelerPoints.year === '2019-20' ?
                  <TabPane tabId={index}>
                    <Row style={{ justifyContent: "space-between", margin: "0" }}>
                      {twoThreeWheelerPoints[t].basePoints.map((item, i) => (
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", width: "auto" }}
                        >
                          <div
                            className="truckbus-note two-three-wheeler-multilple-offer-note"
                            style={{ width: "auto", marginTop: "0" }}
                          >
                            <span className="Base-Points-80-Tyre-label">
                              {item}
                            </span>
                          </div>
                        </div>
                      ))}
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Table className="table-responsive tbl-tbb tbl-about">
                          <thead>
                            <tr style={{ ...this.getStripedStyle(0) }}>
                              <th></th>
                              <th>MotorCycle</th>
                              <th>Scooter</th>
                              <th className="white-space-nowrap">3 Wheeler</th>
                            </tr>
                            <tr>
                              {twoThreeWheelerPoints[t].headers.map(
                                (item, i) => (
                                  <th>{item}</th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {twoThreeWheelerPoints[t].points.map((row, i) => (
                              <tr key={i} style={{ ...this.getStripedStyle(i) }}>
                                {row.map((item, j) => (
                                  <td> {item}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          {twoThreeWheelerPoints[t].note &&
                            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                              <span className="Base-Points-80-Tyre-label">
                                {twoThreeWheelerPoints[t].note}
                              </span>
                            </div>
                          }
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  :
                  <TabPane tabId={index}>
                    <div className="PASSENGER-CAR-RADIAL-label">
                      {twoThreeWheelerPoints[t].type}
                    </div>
                    <div
                      className="d-flex-row truck-bus-wrapper"
                      style={{ marginRight: "0px", marginLeft: "15px" }}
                    >
                      <div className="d-flex-row two-three-wheel-chart-wrapp">
                        {twoThreeWheelerPoints[t].labels.map((item, i) => (
                          <div className="d-flex-column two-three-wheel-chart">
                            {this.state.charts && this.state.charts.length !== 0 && <Chart
                              options={this.state.charts[i].options}
                              series={this.state.charts[i].series}
                              type="radialBar"
                              height="300"
                            />}
                            <div className="d-flex-column two-three-wheel-lable-wrapp">
                              <span className="Base-Points-80-Tyre-label two-three-wheel-lable">
                                {item}
                              </span>

                              <span className="Base-Points-80-Tyre-label two-three-wheel-lable">
                                Base Ponts:{" "}
                                {twoThreeWheelerPoints[t].basePoints[i]}{" "}
                                Points per Tyre
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <br />
                    <br />
                    <div
                      className="d-flex-row truck-bus-wrapper"
                      style={{ marginRight: "0px", marginLeft: "0px" }}
                    >
                      {twoThreeWheelerPoints[t].multipleOffer &&
                        <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                          <span className="Base-Points-80-Tyre-label">
                            {twoThreeWheelerPoints[t].multipleOffer}
                          </span>
                        </div>
                      }
                    </div>
                    <div
                      className="d-flex-row truck-bus-wrapper"
                      style={{ marginRight: "0px", marginLeft: "0px" }}
                    >
                      {twoThreeWheelerPoints[t].note2 &&
                        <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                          <span className="Base-Points-80-Tyre-label">
                            {twoThreeWheelerPoints[t].note2}
                          </span>
                        </div>
                      }
                    </div>
                  </TabPane>
              }
              )}
            </TabContent>
          </CardBody>
        </Card>
        <br />
      </Fragment>
    );
  }
}

export default TwoThreeWheelerPoints;
