import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Ionicon from "react-ionicons";
import DatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import SubDealerService from "../../../services/SubDealerService";
import "../SecondaryPointsReport.css";
import { Roles } from "../../../constants/constant.role";

class SecondaryPointsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        sub_dealer: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        type: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        start_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        end_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      isLoading: false,
      programData: [],
      type: [],
      invitees: [],
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    if (
      props.getFilterControls &&
      Object.keys(props.getFilterControls).length > 0
    ) {
      this.state.controls = props.getFilterControls;
    }
  }

  handleRadioChange(e) {
    let { controls } = this.state;
    controls.type.value = e;
    this.setState({ controls });
  }

  componentDidMount() {
    this.getInvitees(this.props.sapId ? this.props.sapId : "");
  }

  getInvitees = (sapId) => {
    this.setState({ isLoading: true });
    SubDealerService.getAllInviteesAccepted(sapId)
      .then((data) => {
        const { data: invitees } = data.data;
        this.setState({ invitees, isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
  };

  applyFilter = () => {
    this.props.applyFilter(this.state.controls);
  };

  resetFilter = () => {
    this.props.resetFilter();
  };

  onCloseSidebar = () => {
    this.props.onCloseSidebar();
  };

  render() {
    let { controls,
      //  invitees 
    } = this.state;
    const { userDetail } = this.props;
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <>
          <div className="drawer-header">
            <span className="drawer-title" style={{ marginTop: "5px" }}>
              Filter
            </span>
            <Ionicon
              className="cursor-pointer"
              icon="md-close"
              fontSize="24px"
              color="#fdbb1f"
              onClick={() => this.onCloseSidebar()}
            />
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
          <div
            className="custom-drawer-content-wrapperr Fix-height"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label className="label-text">Select type of tyre</Label>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <div className="d-flex-row pcrtype-wrapp">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="type"
                      value="car"
                      onChange={() => {
                        this.handleRadioChange("car");
                      }}
                      checked={controls.type.value === "car"}
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">PCR</span>
                </div>
              </Col>
              <Col
                xl="3"
                md="12"
                xs="12"
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div
                  className="d-flex-row pcrtype-wrapp"
                  style={{ paddingRight: "0" }}
                >
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="type"
                      value="2 wheeler"
                      onChange={() => {
                        this.handleRadioChange("2 wheeler");
                      }}
                      checked={controls.type.value === "2 wheeler"}
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">2 Wheeler</span>
                </div>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <div className="d-flex-row pcrtype-wrapp">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="type"
                      value="lcv"
                      onChange={() => {
                        this.handleRadioChange("lcv");
                      }}
                      checked={controls.type.value === "lcv"}
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">LCV</span>
                </div>
              </Col>
              <Col
                xl="3"
                md="12"
                xs="12"
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div className="d-flex-row pcrtype-wrapp">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="type"
                      value="scv"
                      onChange={() => {
                        this.handleRadioChange("scv");
                      }}
                      checked={controls.type.value === "scv"}
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad">SCV</span>
                </div>
              </Col>
            </Row>
            {userDetail.type === Roles.SUB_DEALER_ROLE ||
              userDetail.type === Roles.BRANCH_ROLE ||
              userDetail.customer_class === "na" ||
              userDetail.customer_class === "hy" ? (
              <div style={{ marginBottom: "20px" }}></div>
            ) : (
              <Row
                style={{ marginLeft: "0", marginRight: "0", marginTop: "20px" }}
              >
                <Col xl="12" md="12" xs="12">
                  <FormGroup>
                    {/* <Label className="label-text">
                      Sub Dealer or Branch Name
                    </Label> */}
                    {/* <select
                      className="form-control form-group-select select select-height-70rem"
                      name="sub_dealer"
                      value={controls.sub_dealer.value}
                      onChange={(e) => {
                        this.handleInputChange(e);
                      }}
                    >
                      <option className="label-text" value="">
                        Select
                      </option>
                      {invitees.map((item) => (
                        <option
                          className="label-text"
                          value={item.phone_number}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label className="filter-bolde-label" for="common">
                  Based on registration date range
                </Label>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label className="label-text" for="common">
                    From
                  </Label>

                  <Label
                    className="date-picker-left-plus-15"
                    onClick={(e) =>
                      this.calendar1.state.open && e.preventDefault()
                    }
                  >
                    <InputGroup>
                      <DatePicker
                        className={"form-control"}
                        onChange={(e) =>
                          this.handleInputChange({
                            target: { name: "start_date", value: e },
                          })
                        }
                        selected={controls.start_date.value}
                        name="start_date"
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        ref={(r) => (this.calendar1 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        popperPlacement="bottom"
                        popperModifiers={{
                          flip: {
                            behavior: ["bottom"], // don't allow it to flip to be above
                          },
                          offset: {
                            enabled: true,
                            offset: "100px, 0px",
                          },
                          preventOverflow: {
                            enabled: false,
                            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                            boundariesElement: "viewport",
                          },
                        }}
                        autoComplete="off"
                        placeholderText="Enter"
                        maxDate={
                          controls.end_date.value
                            ? controls.end_date.value
                            : new Date()
                        }
                        dropdownMode="select"
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            style={{ color: " #fdbb1f" }}
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                  {controls.start_date.showErrorMsg && (
                    <div className="error">*Please select date.</div>
                  )}
                </FormGroup>
              </Col>

              <Col xl="6" md="12" xs="12" style={{ paddingLeft: "0" }}>
                <FormGroup>
                  <Label className="label-text" for="common">
                    To
                  </Label>

                  <Label
                    className="date-picker-left-15"
                    onClick={(e) =>
                      this.calendar2.state.open && e.preventDefault()
                    }
                  >
                    <InputGroup>
                      <DatePicker
                        className={"form-control"}
                        onChange={(e) =>
                          this.handleInputChange({
                            target: { name: "end_date", value: e },
                          })
                        }
                        name="end_date"
                        selected={controls.end_date.value}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        ref={(r) => (this.calendar2 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        autoComplete="off"
                        minDate={
                          controls.start_date.value
                            ? controls.start_date.value
                            : null
                        }
                        placeholderText="Enter"
                        maxDate={new Date()}
                        popperPlacement="bottom"
                        dropdownMode="select"
                        popperModifiers={{
                          flip: {
                            behavior: ["bottom"], // don't allow it to flip to be above
                          },
                          offset: {
                            enabled: true,
                            offset: "-50px",
                            // offset: "100px, 0px",
                          },
                          preventOverflow: {
                            enabled: false,
                            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                            boundariesElement: "viewport",
                          },
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            style={{ color: " #fdbb1f" }}
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div style={{ position: "relative" }}>
            <Row
              className="dealer_filter_button_container"
              style={{
                marginLeft: "0",
                marginRight: "0",
                height: "100%",
                maxHeight: "80px",
                position: "absolute",
                // bottom: "15px",
                width: "100%",
              }}
            >
              <Col
                xl="12"
                xs="12"
                md="12"
                className="text-align-right footer-drawer"
              >
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                  color="dark"
                  onClick={() => this.resetFilter()}
                  style={{ width: "116px" }}
                >
                  Reset
                </Button>
                &nbsp;&nbsp;
                <Button
                  className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                  color="warning"
                  onClick={() => {
                    this.applyFilter();
                  }}
                  style={{ width: "116px" }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </div>
        </>
      </>
    );
  }
}

export default SecondaryPointsFilter;
