import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
// import {BsTerminalFill} from "react-icons/bs";
import askToAi from "../../assets/images/AskToAI.jpg";
import resetSearch from "../../assets/images/resetSearch.jpg";
import icon1 from "../../assets/images/generative ai/icon1.png";
import icon2 from "../../assets/images/generative ai/icon2.png";
import icon3 from "../../assets/images/generative ai/icon3.png";
import icon4 from "../../assets/images/generative ai/icon4.png";
import icon5 from "../../assets/images/generative ai/icon5.png";
import icon6 from "../../assets/images/generative ai/icon6.png";
import icon7 from "../../assets/images/generative ai/icon7.png";
import icon8 from "../../assets/images/generative ai/icon8.png";
import icon9 from "../../assets/images/generative ai/icon9.png";
import "./GenerativeAI.css";
import GenerativeAICard from "./GenerativeAICard";
import { routes } from "../../constants/constant.route";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import GenerativeAIService from "../../services/GenerativeAI";

function GenerativeAI() {
  const [askInput, setAskInput] = useState("");
  const [tyreCategory, setTyreCategory] = useState("PCR");
  const [submitSearch, setSubmitSearch] = useState(false);
  const [apiANS, setApiAns] = useState("");
  const [loading, setLoading] = useState(false);

  const [cardList, setCardList] = useState([
    {
      name: "Area-wise Active dealer count",
      iconIMG: icon1,
      discription:
        'Share the count of dealers from Ahmedabad Area, Whose Active Inactive status is "active" in dealer master in Q1, FY 2023-24',
      iconStyleObj: {
        color: "green",
        backgroundColor: "#E2F0D9",
      },
    },
    {
      name: "Area-wise Steel Wheels count",
      iconIMG: icon2,
      discription:
        "Share the count of dealer with Steel Wheels classification in Delhi Region in Q4, FY 2022-23",
      // "This prompt create a question + answer structure for answering  question based on existing...",
      iconStyleObj: {
        color: "orange",
        backgroundColor: "#FFD9D9",
      },
    },
    {
      name: "Q-o-Q Offtake drop comparison",
      iconIMG: icon3,
      discription:
        "Share the count of dealers with Steel Wheels classification, where quarterly offtake reduced by 30%, from Q1 FY 2022-23 to Q1 FY 2023-24",
      iconStyleObj: {
        color: "green",
        backgroundColor: "#DAE3F3",
      },
    },
    {
      name: "Q-o-Q Offtake surge comparison",
      iconIMG: icon4,
      discription:
        "Share the count of dealers with SW classification, where Quarterly offtake increased by 20%, from Q1 FY 2022-23 to Q1 FY 2023-24",
      iconStyleObj: {
        color: "#007eff",
        backgroundColor: "#DEEBF7",
      },
    },
    {
      name: "Region-wise Active dealer count",
      iconIMG: icon5,
      discription:
        'Share the count of dealers from Delhi Region, Whose Active Inactive status is "active" in dealer master in Q1, FY 2023-24',
      iconStyleObj: {
        color: "#7a00ff",
        backgroundColor: "#EADCF4",
      },
    },
    {
      name: "Zone-wise Active dealer count",
      iconIMG: icon6,
      discription:
        'Share the count of dealers from West Zone, Whose Active Inactive status is "active" in dealer master in Q1, FY 2023-24',
      iconStyleObj: {
        color: "#007eff",
        backgroundColor: "#E2F0D9",
      },
    },
    {
      name: "APM of Steel Wheels",
      iconIMG: icon7,
      discription:
        "Share the average monthly offtake and dealer count with Steel Wheels classification in Q1, FY 2023-24",
      iconStyleObj: {
        color: "rgb(229 11 11)",
        backgroundColor: "#FBE5D6",
      },
    },
    {
      name: "APM of Xpress Wheels",
      iconIMG: icon8,
      discription:
        "Share the average monthly offtake and dealer count with Xpress Wheels classification in FY 2022-23",
      iconStyleObj: {
        color: "#007eff",
        backgroundColor: "#FFF2CC",
      },
    },
    {
      name: "Dealer SAP wise quarterly business",
      iconIMG: icon9,
      discription:
        "Q1 FY 2023-24 total offtake of dealer code 1115816",
      iconStyleObj: {
        color: "rgb(229 11 11)",
        backgroundColor: "#A7E8FF",
      },
    },
  ]);
  const [tyreCategoryList, setTyreCategoryList] = useState([
    "PCR",
    "TRUCK",
    "TBB",
    "TBR",
    "SCV",
    "LCV",
    "2-3W",
    "OTR",
    "Retread",
  ]);

  const handleSubmitSearch = async (v) => {
    setSubmitSearch(v);
    if (v) {
      let body = {
        question: askInput,
      };
      const headers = {
        "content-type": "application/json",
      };
      try {
        setLoading(true);
        const dataRes = await GenerativeAIService.getAnswer(body);
        if (dataRes) {
          setLoading(false);
          console.log(dataRes);

          setApiAns(dataRes.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      // setAskInput("Number of active dealers in Ahmedabad region in June'23");
      // setAskInput("get all quantitiy of 2022 and 2023 and find diff between this two");
    } else {
      setAskInput("");
      // setAskInput("");
    }
  };

  return (
    <div className="generativeAIPage" id="generativeAIPage">
      {/* Page Heading */}
      <div className="page-title-wrapper">
        <div className="page-title-block d-flex align-items-center flex-wrap">
          <div>
            <img src={askToAi} alt="img" height="70px" width="70px" />
          </div>
          <div className="pageTitleText">
            <div className="page-title">
              Ask Vikash your query for JK Tyre PCR Business?
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <CustomSpinner />
      ) : (
        <div className="chatWrap card">
          <div className="searchInputWrap">
            <div className="searchInputWrap2">
              <input
                name="searchInput"
                className="searchInput"
                type="text"
                value={askInput}
                onChange={(e) => {
                  setAskInput(e.target.value);
                  setSubmitSearch(false);
                }}
                placeholder="Ask Vikash…………"
              />
              {submitSearch ? (
                <span
                  className="searchIcon"
                  onClick={() => handleSubmitSearch(false)}
                >
                  <img src={resetSearch} height="36px" />
                </span>
              ) : (
                <span
                  className="searchIcon"
                  onClick={() => handleSubmitSearch(true)}
                >
                  <FaRegArrowAltCircleRight />
                </span>
              )}
            </div>
            <div style={{ width: "180px" }}>
              <select
                className="select"
                name="tyreCategory"
                value={tyreCategory}
                onChange={(e) => {
                  setTyreCategory(e.target.value);
                }}
              >
                <option value="All" disabled>
                  All
                </option>
                {tyreCategoryList.length > 0 &&
                  tyreCategoryList.map((item) => (
                    <option disabled value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {submitSearch ? (
            <div className="ans-card">
              <p className="mb-0">
                <b>Vikash:</b>
              </p>
              {/* <p>There are 32 active dealers in Ahmedabad region in June'23</p> */}
              <p>{apiANS}</p>
            </div>
          ) : (
            <Row className="p-3">
              {cardList.map((item, index) => (
                <GenerativeAICard
                  name={item.name}
                  iconIMG={item.iconIMG}
                  discription={item.discription}
                  iconStyleObj={item.iconStyleObj}
                  setAskInput={setAskInput}
                />
              ))}
            </Row>
          )}
        </div>
      )}
    </div>
  );
}

export default GenerativeAI;
