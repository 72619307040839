import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import swal from "sweetalert";

import CustomerManagementService from "../../services/CustomerManagementService";
import ModalService from "../../services/ModalService";
import Validation from "../../services/Validation";

import CustomSpinner from "../CustomSpinner/CustomSpinner";

class AddToGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_id: null,
      search_query: props.search_query ? props.search_query : "",
      filterControls: props.filterControls ? props.filterControls : {},
      selectedCustomers: props.selectedCustomers ? props.selectedCustomers : [],
      isSelectAllEntry: props.isSelectAllEntry ? props.isSelectAllEntry : false,
      controls: {
        group_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      },
      isLoading: false,
      groupData: []
    };
  }
  componentDidMount() {
    this.getGroups();
  }
  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { group_name } = controls;

    if (group_name.touched === true || isSubmit) {
      group_name = Validation.notNullValidator(group_name);
      group_name.valid = !group_name.nullValue;
      if (group_name.valid === false) {
        group_name.showErrorMsg = true;
      } else {
        group_name.showErrorMsg = false;
      }
    }
    if (group_name.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };
  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };
  getGroups = () => {
    CustomerManagementService.getGroups({ page: 1, pageSize: 10000 })
      .then(res => {
        let { groupData } = this.state;
        if (
          res.data.data &&
          res.data.data.groups &&
          res.data.data.groups.length > 0
        ) {
          groupData = res.data.data.groups;
        } else {
          groupData = [];
        }
        this.setState({
          groupData
        });
      })
      .catch(e => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        //this.setState({ isLoading: false });
      });
  };

  createGroup = () => {
    this.props.createGroup(true);
  };

  addToGroup = (group_name = "") => {
    if (!group_name) {
      if (!this.handleValidation(true)) {
        return false;
      }
    }
    let reqData = this.state.filterControls ? this.state.filterControls : {};
    reqData.search_query = this.state.search_query;
    if (this.state.isSelectAllEntry) {
      reqData.customers = [];
    } else {
      if (
        this.state.selectedCustomers &&
        this.state.selectedCustomers.length > 0
      ) {
        reqData.customers = this.state.selectedCustomers;
      }
    }

    let { controls } = this.state;
    if (group_name) {
      reqData.group_name = group_name;
    } else {
      reqData.group_name = controls.group_name.value;
    }
    this.setState({ isLoading: true });
    CustomerManagementService.addToGroup(reqData)
      .then(res => {
        this.setState({ isLoading: false });
        if (res.data.message) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => {
            this.props.onCloseSidebar(true);
          });
        }
      })
      .catch(e => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };
  render() {
    const { controls, groupData } = this.state;
    // const customStyles = {
    //   control: base => ({
    //     ...base,
    //     maxHeight: 70,
    //     overflow: "auto"
    //   })
    // };
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <div className="drawer-header">
          <span className="drawer-title">Add To Group</span>
        </div>
        <hr style={{ border: "solid 1px #ced4da" }} />
        <div className="custom-drawer-content-wrapper">
          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col xl="12" md="12" xs="12" className="mb-2">
              <span
                className="link-blue cursor-pointer"
                onClick={() => this.createGroup()}
                style={{ fontSize: "16px" }}
              >
                Create Group
              </span>
            </Col>
            <Col xl="12" md="12" xs="12" className="mb-2">
              <span style={{ fontSize: "16px" }}>Or</span>
            </Col>
            <Col xl="12" md="12" xs="12">
              <FormGroup>
                <Label for="common">
                  Group<span className="required-field">*</span>
                </Label>
                <select
                  className="form-control select-height-70rem"
                  name="group_name"
                  value={controls.group_name.value}
                  onChange={e => {
                    this.handleInputChange(e);
                  }}
                >
                  <option selected="selected" value="">
                    Select
                  </option>
                  {groupData.map(item => (
                    <option value={item.group_name}>{item.group_name}</option>
                  ))}
                </select>
                {controls.group_name.showErrorMsg && (
                  <div className="error">*Please select group.</div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
        {/* <br /><br /> */}
        <Row style={{ marginLeft: "0", marginRight: "0" }}>
          <Col
            xl="12"
            xs="12"
            md="12"
            className="text-align-right footer-drawer"
          >
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
              onClick={() => this.props.onCloseSidebar()}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-save-button"
              color="warning"
              onClick={() => this.addToGroup(null)}
            >
              Add
            </Button>
          </Col>
        </Row>
        <br />
      </>
    );
  }
}

export default AddToGroup;
