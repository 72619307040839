import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalBody,
  Row, Col
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

// actions
import { setUserDetail } from '../../actions/UserAction';
import AuthService from '../../services/AuthService';

// constant
import { messages } from '../../constants/constant.message';

// css
import './LogoutModal.css';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

class LogoutModal extends React.Component {

  state = {
    logoutFromAll: false
  }

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }


  setLogoutFromAllCheckboxValue = () => {
    this.setState({ logoutFromAll: !this.state.logoutFromAll });
  }

  closeModal() {
    this.props.closeModal();
  }

  clearLocalData = () => {
    //GoogleAnalticsService.pageView('Logout Click');
    AuthService.logout();
    this.props.setUserDetail(null);
    // this.closeLogoutModal();
    this.props.history.replace("/login");
    // this.setState({ redirectToLoginPage: true });
  }

  logout = () => {
    if (this.state.logoutFromAll === true) {
      this.logoutFromAllDevice();
    } else {
      this.clearLocalData();
    }
  }

  logoutFromAllDevice = () => {
    AuthService.logoutFromAllDevice()
      .then(data => {
        this.clearLocalData();
      })
      .catch(e => {
        this.clearLocalData();
      })
  }

  render() {
    return (
      <div>
        <Modal
          id="logout-modal"
          isOpen={this.props.show}
          toggle={this.closeModal}
          className={this.props.className}
          centered
        >
          <ModalBody>
            <br />
            <Row>
              <Col xs="2">
                <FontAwesomeIcon className="arrow-color" style={{ fontSize: '50px', color: '#ffb609' }} icon={faExclamationTriangle} />
              </Col>
              <Col xs="10">
                <div className="modal-title">Logout</div>
                <div className="modal-text margin-top-10">{messages.LOGOUT}</div>
                <br />
                <div className='d-flex'>
                  <label className="container-cb">
                    <input
                      type="checkbox"
                      name="checkbox-logout"
                      id="checkbox-logout"
                      checked={this.state.logoutFromAll}
                      onChange={this.setLogoutFromAllCheckboxValue} />{' '}
                    Logout from all device
                    <span className="checkmark"></span>
                  </label>
                  {/* <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="checkbox"
                        id="checkbox"
                        checked={this.state.logoutFromAll}
                        onChange={this.setLogoutFromAllCheckboxValue}
                      />{' '}
                      Logout from all device
                    </Label>
                  </FormGroup> */}
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="text-align-right">
                <Button
                  color="#e0e2e4"
                  style={{ backgroundColor: '#e0e2e4', borderRadius: '25px', fontSize: '16px', fontWeight: 'bold' }}
                  onClick={this.props.closeModal}
                >Cancel
                </Button>{'   '}
                <Button
                  color="#fdbb1f"
                  style={{ backgroundColor: '#fdbb1f', borderRadius: '25px', fontSize: '16px', fontWeight: 'bold' }}
                  onClick={this.logout}
                >Logout
                </Button>{'   '}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

LogoutModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.any,
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
  setUserDetail: user => dispatch(setUserDetail(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutModal);
// export default LogoutModal;
