
import React from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import Ionicon from 'react-ionicons';

import PrimaryPointsReport from '../../pages/PrimaryPointsReport/components/PrimaryPointsReport';
import './PrimaryPointsReportModal.css';
import { withRouter } from 'react-router-dom';
class PrimaryPointsReportModal extends React.Component {

  state = {
    modalTitle: ''
  }

  setModalTitle = (title) => {
    this.setState({ modalTitle: title })
  }

  render() {

    const { history } = this.props;
    return <Modal
      id="primary-point-report-modal"
      isOpen={this.props.show}
      toggle={this.props.closeModal}
      centered
    >
      {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
      <ModalBody>
        <Row className="modal-title-border padding-bottom-10">
          <Col sm="11" className="modal-title capital-case">POINTS REPORT as ({this.state.modalTitle})</Col>
          <Col sm="1" className="text-align-right cursor-pointer" onClick={this.props.closeModal}>
            <Ionicon
              icon="md-close"
              fontSize="34px"
              color="#fdbb1f"
            ></Ionicon>
          </Col>
        </Row>
        <div></div>

        {this.props.currentClassification && history && history.location.pathname && history.location.pathname === "/user-updates" &&
          <div className="gradient-title gradientBgImg mb-2 mt-2">
            <Row>
              <Col>
                <div className="gradient-sub-title" style={{ padding: '15px' }}>
                  <b>Current Classification: </b> {" "}
                  {/* {userDetail.display_type} */}
                  {this.props.currentClassification}
                </div>
              </Col>
            </Row>
          </div>
        }

        <PrimaryPointsReport
          from="modal"
          sapId={this.props.sapId}
          setModalTitle={this.setModalTitle}
        ></PrimaryPointsReport>
      </ModalBody>
    </Modal>
  }
}

export default withRouter(PrimaryPointsReportModal); 