import { request } from "./Request";
import { routes } from "../constants/constant.route";

const posterRoutes = routes.POSTER;
const {
  GET_POSTER_CATEGORY,
  GET_POSTER_LIST,
  ADD_EDIT_POSTER,
  UPDATE_POSTER_IMAGE,
  ACTIVE_INACTIVE_POSTER,
  GET_POSTER_TARGET_USER,
  GET_POSTER_DOWNLOAD_USER,
  GET_POSTER,
  GET_POSTER_DETAIL,
  ADD_EDIT_POSTER_DETAIL,
  DOWNLOAD_POSTER,
  DOWNLOAD_POSTER_DETAIL,
  GET_USER_COUNT,
} = posterRoutes;

const API_URL = process.env.REACT_APP_API_URL;

export default class PosterService {
  static addEditPoster(obj) {
    const headers = {
      "content-type": "application/json",
    };
    return request(
      "POST",
      `${API_URL}${ADD_EDIT_POSTER}`,
      null,
      obj,
      headers
    );
  }

  static getPosterCategory() {
    return request(
      "GET",
      `${API_URL}${GET_POSTER_CATEGORY}`,
      null,
      null,
      null
    );
  }

  static getPosterTargetUser(page, pageSize, uuid, type, search, isExport) {
    let qp = `?`
    if (page) {
      qp += `page=${page}&`;
    }
    if (pageSize) {
      qp += `pageSize=${pageSize}&`;
    }
    if (uuid) {
      qp += `uuid=${uuid}&`;
    }
    if (search) {
      qp += `q=${search}&`;
    }
    if (isExport === true) {
      qp += `is_download=${isExport}&`
    }

    let api = type === 'total' ? GET_POSTER_TARGET_USER : GET_POSTER_DOWNLOAD_USER;
    return request(
      "GET",
      `${API_URL}${api}${qp}`,
      null,
      null,
      null
    );
  }

  static getPosterList(obj) {
    let qp = `?`
    if (obj.page) {
      qp += `page=${obj.page}&`;
    }
    if (obj.pageSize) {
      qp += `pageSize=${obj.pageSize}&`;
    }
    if (obj.q) {
      qp += `q=${obj.q}&`;
    }
    if (obj.end_date) {
      qp += `end_date=${obj.end_date}&`;
    }
    if (obj.start_date) {
      qp += `start_date=${obj.start_date}&`;
    }
    if (obj.is_active) {
      qp += `is_active=${obj.is_active}&`;
    }
    if (obj.category) {
      qp += `category=${obj.category}&`;
    }
    return request(
      "GET",
      `${API_URL}${GET_POSTER_LIST}${qp}`,
      null,
      null,
      null
    );
  }

  static UpdatePosterImage(obj) {
    const headers = {
      "content-type": "multipart/form-data",
    };
    return request(
      "POST",
      `${API_URL}${UPDATE_POSTER_IMAGE}`,
      null,
      obj,
      headers
    );
  }

  static activeInactivePoster(obj) {
    const headers = {
      "content-type": "application/json",
    };
    return request(
      "POST",
      `${API_URL}${ACTIVE_INACTIVE_POSTER}`,
      null,
      obj,
      headers
    );
  }

  static getPoster(obj) {
    return request('GET', `${API_URL}${GET_POSTER}`, obj, null, null);
  }

  static getPosterDetail() {
    return request('GET', `${API_URL}${GET_POSTER_DETAIL}`, null, null, null);
  }

  static addEditPosterDetail(obj) {
    return request('POST', `${API_URL}${ADD_EDIT_POSTER_DETAIL}`, null, obj, null);
  }

  static downloadPoster(obj) {
    return request('POST', `${API_URL}${DOWNLOAD_POSTER}`, null, obj, null);
  }

  static downloadPosterDetail(obj) {
    return request(
      "POST",
      `${API_URL}${DOWNLOAD_POSTER_DETAIL}`,
      null,
      obj,
      null
    );
  }

  static getUserCount(obj) {
    return request(
      "POST",
      `${API_URL}${GET_USER_COUNT}`,
      null,
      obj,
      null
    );
  }
}