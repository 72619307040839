import React, { Component } from 'react';
import AboutJKAdvantage from '../../components/AboutJKAdvantage/AboutJKAdvantage';
import {
  Row, Col
} from 'reactstrap';
import VideoComponent from '../../components/VideoComponent/VideoComponent';
import HowItWorks from '../../components/HowItWorks/HowItWorks';
import classnames from 'classnames';
class WhatsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    }
  }
  checkUserAgent() {
    this.state.isMobile = false;
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.state.isMobile = true;
      return 'mobile'
    } else if (/Chrome/i.test(ua)) {
      return 'chrome'
    } else {
      return 'other'
    }
  }
  componentWillMount() {
    this.checkUserAgent();
    this.setState((prevState) => {
      return { ...prevState };
    });
  }
  render() {
    return (
      <>
        <div id="ob1">
          <div className={classnames({ 'vert-align': !this.state.isMobile })}>
            <div>
              <Row>
                <Col xl="4" className="margin-top-10 padding-left-5 padding-right-5" >
                  <VideoComponent />
                </Col>
                <Col xl="4" className="margin-top-10 padding-left-5 padding-right-5">
                  <AboutJKAdvantage />
                </Col>
                <Col xl="4" className="margin-top-10 padding-left-5 padding-right-5">
                  <HowItWorks />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhatsNew;