import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Sidebar from "react-sidebar";
import "react-input-range/lib/css/index.css";

import PosterPreview from "../../components/PosterPreview/PosterPreview";
import PosterView from "../../components/PosterPreview/PosterView";

import PosterService from "../../services/PosterService";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { messages } from "../../constants/constant.message";
import { formatDate } from "../../utils/utils";

import "./Poster.css";

const NODATAMSG = messages.POSTER.NO_POSTER;

class Poster extends Component {
  requestNo = 0;

  constructor(props) {
    super(props);

    this.state = {
      poster: [],
      categories: [{ label: 'All', key: null },
      { label: 'PCR', key: 'pcr' },
      { label: '2/3 Wheeler', key: '2/3wheeler' },
      { label: 'TBB & TBR', key: 'tbb_tbr' },
      { label: 'LCV & SCV', key: 'lcv_scv' },
      { label: 'Farm', key: 'farm' },
      { label: 'Other', key: 'other' }],
      posterDeatil: {
        "address": "--",
        "pincode": "--",
        "mobile": "--",
        "email": "--",
        "name": "--"
      },
      selectedPoster: null,
      category: null,
      selectedCategoryIndex: 0,
      isLoading: false,
      sidebarOpen: false
    };
  }

  componentDidMount() {
    this.getPoster();
    this.getPosterDetail();
  }

  getPoster = () => {

    this.setState({ isLoading: true });

    PosterService.getPoster({ 'category': this.state.category })
      .then((data) => {

        this.setState({ isLoading: false, poster: data.data.data });

      }).catch((e) => {

        this.setState({ isLoading: false });

      });
  }

  getPosterDetail = () => {

    this.setState({ isLoading: true });

    PosterService.getPosterDetail()
      .then((data) => {

        if (data && data.data && data.data.data) {
          this.setState({ isLoading: false, posterDeatil: data.data.data });
        } else {
          this.setState({ isLoading: false });
        }

      }).catch((e) => {

        this.setState({ isLoading: false });

      });
  }

  handleCategoryChange = (categoryName, index) => {
    this.setState(
      {
        category: categoryName,
        selectedCategoryIndex: index
      }, () => {
        this.getPoster();
      });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  openPreview = (data) => {
    document.body.style.overflow = "hidden";
    this.setState(prevState => {
      return {
        ...prevState, selectedPoster: data, sidebarOpen: true
      };
    });
  };

  closePreview = () => {
    document.body.style.overflow = "auto";
    this.setState(prevState => {
      return { ...prevState, sidebarOpen: false };
    });
    this.getPosterDetail();
  };

  openViewModal = (data) => {
    data = {
      ...data,
      ...this.state.posterDeatil
    };
    this.refs.openViewModal.toggle(data);
  }

  render() {
    const {
      poster,
      categories,
      selectedCategoryIndex,
      isLoading
    } = this.state;
    const categoriesList = categories.map((c, i) => {
      return (
        <div className="margin-top-10">
          <div>
            <span
              onClick={() => this.handleCategoryChange(c.key, i)}
              className={
                i === selectedCategoryIndex
                  ? "category-selected cursor-pointer"
                  : "cursor-pointer"
              }>
              {c.label}
            </span>
          </div>
        </div>
      );
    });

    const posterList = poster.map((data, i) => {

      return (
        <Col sm="12" xl="4" className="margin-top-10 margin-bottom-10">
          <Card className="card-height card-shadow border-radius-10 cursor-pointer">
            <CardBody>
              <Row>
                <Col xs='12' xl='12' sm='12'
                  className="content-center">
                  {data.new && (
                    <div className="label-bg">New</div>
                  )}
                    <div className="label-date">{formatDate(data.created_at)}</div>
                  <img
                    onClick={() => this.openViewModal(data)}
                    src={data.poster_thumbnail_url}
                    alt=""
                    className="logo-thumb-img"
                    // height="70px"
                    width="100%"
                  />
                </Col>
                <Col xs='12' xl='12' sm='12'>
                  <div className="redeem-points margin-top-10"
                    onClick={() => this.openPreview(data)}>
                    Download
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      );
    });

    return (
      <>

        <div id="poster">
          {this.state.sidebarOpen && (
            <Sidebar
              sidebar={
                <>
                  <PosterPreview
                    selectedPoster={this.state.selectedPoster}
                    posterDeatil={this.state.posterDeatil}
                    onCloseSidebar={this.closePreview}
                  />
                </>
              }
              open={this.state.sidebarOpen}
              pullRight
              sidebarClassName="poster-preview"
              styles={{
                sidebar: { width: '38%', background: "white", height: "90%", top: "60px" }
              }}
            />
          )}
          {isLoading && <CustomSpinner></CustomSpinner>}
          <Row>
            <Col
              sm="12"
              xl="3"
              className="category-bg"
              style={{ maxWidth: "20%" }}
            >

              <div className="category-title">Categories</div>

              {categoriesList}

            </Col>
            <Col sm="12" className="gift-bg" style={{ 'maxWidth': "80%" }}>
              <Row sm="11" className="">
                <Col>
                  <div className="page-title">Download Poster</div>

                  <Row className="margin-top-10" style={{
                    height: '550px', overflow: 'auto'
                  }}>
                    {poster && poster.length > 0 ? (
                      posterList
                    ) : (
                        <div className="width-100 text-align-center">
                          {isLoading !== true && (
                            <CardBody>{NODATAMSG}</CardBody>
                          )}
                        </div>
                      )}
                  </Row>

                </Col>
              </Row>
            </Col>
          </Row>
          <PosterView
            from="list"
            title="Poster"
            ref="openViewModal"
          />
        </div>
      </>
    );
  }
}

export default Poster;
