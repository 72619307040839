import React, { Component } from 'react';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import { Row, Col, Label, Button, InputGroup, InputGroupAddon, FormGroup } from 'reactstrap';
import DatePicker from "react-datepicker";
import ReactDOM from 'react-dom';
import CampaignService from '../../services/CampaignService';
import Select, { components } from "react-select";
import lodash from 'lodash';
import { messages } from "../../constants/constant.message";
import ModalService from '../../services/ModalService';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
const { ERROR_MESSAGE } = messages.COMMON;

class CIPCampaignFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cipCategory: [],
      cip_objetives: ['Awareness about Brand', 'Educate on JK TBB & TBR Product range', 'New Product Launch', 'Customer engagement'],
      isLoading: false,
      controls: {
        cip_objective: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        filter_zone_ids: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        vehiclecategory: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        region_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        area_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        filter_cip_start_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        filter_cip_end_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        state_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        }
      },
      serachValueState: '',
      serachValueCity: '',
      dealerData: [],
      stateData: [],
      cityData: [],
      makeData: [],
      regionData: [],
      zoneData: [],
      areaData: [],
      zone: [],
      region: [],
      area: [],
      serachValueZone: '',
      serachValueRegion: '',
      serachValueArea: '',
      isDisplayDealer: true,
      counts: 0,
      isSetDataFirstTime: true,
      isFormValid: false,
      applyFilterCIPUserFilter: {}
    }

    if (props.applyFilterCIPUserFilter && Object.keys(props.applyFilterCIPUserFilter).length > 0) {
      this.state.applyFilterCIPUserFilter = props.applyFilterCIPUserFilter;
      if (props.applyFilterCIPUserFilter.counts) {
        this.state.counts = props.applyFilterCIPUserFilter.counts;
      }
      this.state.controls.filter_cip_start_date.value = props.applyFilterCIPUserFilter.filter_cip_start_date ? props.applyFilterCIPUserFilter.filter_cip_start_date : null;
      this.state.controls.filter_cip_end_date.value = props.applyFilterCIPUserFilter.filter_cip_end_date ? props.applyFilterCIPUserFilter.filter_cip_end_date : null;
      this.state.controls.cip_objective.value = props.applyFilterCIPUserFilter.filter_cip_objective ? props.applyFilterCIPUserFilter.filter_cip_objective : null;
    }
  }
  componentDidMount() {
    this.getZone()
    this.getState();
    this.getDealerClassication();
    this.getCipCategory();
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  handleInputChange = (e, index = -1) => {
    let controlName = e.target.name;
    let controlValue = e.target.value;
    let { stateData, cityData, controls, dealerData, cipCategory,
      isSetDataFirstTime, zoneData, regionData, areaData } = this.state;
    isSetDataFirstTime = false;
    if (controlName === 'state_id') {
      if (this.refCity) {
        this.refCity.onMenuClose();
      }
      controls.city_id.value = [];
      controls[controlName].value = controlValue;
      cityData = [];
      if (controlValue === null) {
        controlValue = [];
        controls[controlName].value = controlValue;
      }
      if (controlValue && controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = stateData;
            this.refState.onMenuClose();
          } else if (controlValue.length === stateData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = stateData;
          this.refState.onMenuClose();
        } else if (controlValue.length === stateData.length - 1) {
          controls[controlName].value = [];
          this.refState.onMenuClose();
        }
      }
      let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
      if (index_all > -1) {
        this.getCity([]);
      } else {
        let state_id = [];
        controls[controlName].value.forEach(element => {
          state_id.push(element.state_id);
        });
        this.getCity(state_id);
      }
      if (this.state.serachValueState) {
        this.onChangeFilter({ target: { name: 'serachValueState', value: '' } })
      }
    } else if (controlName === 'city_id') {
      this.refState.onMenuClose();
      controls[controlName].value = controlValue;
      if (controlValue && controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = cityData;
            this.refCity.onMenuClose();
          } else if (controlValue.length === cityData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = cityData;
          this.refCity.onMenuClose();
        } else if (controlValue.length === cityData.length - 1) {
          controls[controlName].value = [];
          this.refCity.onMenuClose();
        }
      }
      if (this.state.serachValueCity) {
        this.onChangeFilter({ target: { name: 'serachValueCity', value: '' } })
      }
    } else if (controlName === 'dealer') {
      dealerData[index].isChecked = !dealerData[index].isChecked;
      if (index === 0) {
        dealerData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      }
      else if (index > 0) {
        dealerData[0].isChecked = false;
      }
    } else if (controlName === 'vehiclecategory') {
      cipCategory[index].isChecked = !cipCategory[index].isChecked;
      if (index === 0) {
        cipCategory.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      }
      else if (index > 0) {
        cipCategory[0].isChecked = false;
      }
    } else if (controlName === 'filter_zone_ids') {
      zoneData[index].isChecked = !zoneData[index].isChecked;
      controls.area_id.value = [];
      controls.region_id.value = [];
      regionData = [];
      areaData = [];
      if (index === 0) {
        zoneData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      } else if (index > 0) {
        zoneData[0].isChecked = false;
      }
      let zone_all = lodash.filter(zoneData, element => {
        return element.zone_name === "All";
      });
      let zone_id = [];
      if (!zone_all[0].isChecked) {
        zoneData.forEach(element => {
          if (element.isChecked) {
            zone_id.push(element.zone_id);
          }
        });
      }
      controls[controlName].touched = true;
      this.getRegion(zone_id);

    } else if (controlName === 'region_id') {
      this.refArea.onMenuClose();
      controls[controlName].value = controlValue;
      controls.area_id.value = [];
      if (controlValue && controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(
          x => x.region_name === "All"
        );
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = regionData;
          } else if (controlValue.length === regionData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
          this.refRegion.onMenuClose();
        } else if (index_all > 0) {
          controls[controlName].value = regionData;
        } else if (controlValue.length === regionData.length - 1) {
          controls[controlName].value = [];
        }
      }

      let zone_all = lodash.filter(zoneData, element => {
        return element.zone_name === "All";
      });
      let zone_id = [];
      if (!zone_all[0].isChecked) {
        zoneData.forEach(element => {
          if (element.isChecked) {
            zone_id.push(element.zone_id);
          }
        });
      }

      let index_all = controls[controlName].value.findIndex(
        x => x.region_name === "All"
      );
      if (index_all > -1) {
        this.getArea({ region_ids: [], zone_id: zone_id });
      } else {
        let region_id = [];
        controls[controlName].value.forEach(element => {
          region_id.push(element.region_id);
        });
        this.getArea({ region_ids: region_id, zone_id: zone_id });
      }
    } else if (controlName === 'area_id') {
      this.refRegion.onMenuClose();
      controls[controlName].value = controlValue;
      if (controlValue && controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(
          x => x.area_name === "All"
        );
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = areaData;
            this.refArea.onMenuClose();
          } else if (controlValue.length === areaData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = areaData;
          this.refArea.onMenuClose();
        } else if (controlValue.length === areaData.length - 1) {
          controls[controlName].value = [];
          this.refArea.onMenuClose();
        }
      }
    } else {
      controls[controlName].value = controlValue;
    }
    this.setState({
      controls,
      cityData,
      isSetDataFirstTime
    });
  }

  getState = () => {
    this.setState({ isLoading: true });
    CampaignService.getState()
      .then(data => {
        let { stateData, applyFilterCIPUserFilter, controls } = this.state;
        let { data: states } = data.data;

        states.splice(0, 0, {
          "name": "All",
          "state_id": [],
          "code": "All",
          "is_own_state": false
        });

        stateData = states;

        if (applyFilterCIPUserFilter && Object.keys(applyFilterCIPUserFilter).length > 0) {
          if (applyFilterCIPUserFilter.filter_state_ids &&
            applyFilterCIPUserFilter.filter_state_ids.length > 0) {
            let state_id = [];
            applyFilterCIPUserFilter.filter_state_ids.forEach(filter_state_ids => {
              stateData.forEach(element => {
                if (element.state_id === filter_state_ids) {
                  controls.state_id.value.push(element);
                  state_id.push(element.state_id);
                }
              });
            });
            this.getCity(state_id);
          }
        }
        this.setState({ stateData, controls, isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  getCity = (stateId) => {
    let reqData = {
      state_id: stateId
    }
    this.setState({ isLoading: true });
    CampaignService.getCity(reqData)
      .then(data => {
        let { cityData, applyFilterCIPUserFilter, controls } = this.state;
        const { data: cities } = data.data;
        cityData = cities;

        cityData.splice(0, 0, {
          "name": "All",
          "city_id": [],
          "city_rank": 1,
          "is_own_city": false
        });

        if (this.state.isSetDataFirstTime && applyFilterCIPUserFilter && Object.keys(applyFilterCIPUserFilter).length > 0) {
          if (applyFilterCIPUserFilter.filter_city_ids &&
            applyFilterCIPUserFilter.filter_city_ids.length > 0) {
            applyFilterCIPUserFilter.filter_city_ids.forEach(filter_city_ids => {
              controls.city_id.value.push({
                "name": "",
                "city_id": filter_city_ids,
                "city_rank": 1,
                "is_own_city": false
              });
            });
          }
        }
        this.setState({ cityData, controls, isLoading: false });

      })
      .catch(e => {
        console.error('e', e);
        this.setState({ isLoading: false });
      });
  }

  getZone = () => {
    CampaignService.getZone()
      .then(data => {
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            zone_id: [],
            zone_name: "All",
            zone_code: "all"
          });
        }

        //Set Props Data
        if (this.state.isSetDataFirstTime) {
          if (this.state.applyFilterCIPUserFilter &&
            Object.keys(this.state.applyFilterCIPUserFilter).length > 0) {
            if (this.state.applyFilterCIPUserFilter.filter_zone_ids.length > 0) {
              let zone_id = [];
              this.state.applyFilterCIPUserFilter.filter_zone_ids.forEach(
                filter_zone_ids => {
                  data.data.data.forEach(element => {
                    if (element.zone_id === filter_zone_ids) {
                      element.isChecked = true;
                      zone_id.push(element.zone_id);
                    }
                  });
                }
              );
              this.getRegion(zone_id);
            } else if (this.state.applyFilterCIPUserFilter.filter_zone_ids.length === 0) {
              data.data.data[0].isChecked = true;
              this.getRegion([]);
            }
          } else {
            this.getRegion([]);
            data.data.data[0].isChecked = true;
          }
        } else {
          this.getRegion([]);
          data.data.data[0].isChecked = true;
        }

        this.setState({
          zoneData: data.data.data
        });
      })
      .catch(err => {
        return err;
      });
  };

  getArea = data => {
    let reqData = {
      region_id: [],
      zone_id: []
    };
    if (data && Object.keys(data).length > 0) {
      reqData = {
        region_id: data.region_ids,
        zone_id: data.zone_id
      };
    }
    this.setState({
      isLoading: true
    });
    CampaignService.getArea(reqData)
      .then(data => {
        let { controls } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            region_id: "",
            area_id: [],
            area_name: "All"
          });

          if (
            this.state.isSetDataFirstTime &&
            this.state.applyFilterCIPUserFilter &&
            Object.keys(this.state.applyFilterCIPUserFilter).length > 0
          ) {
            if (
              this.state.applyFilterCIPUserFilter.filter_area_ids &&
              this.state.applyFilterCIPUserFilter.filter_area_ids.length > 0
            ) {
              this.state.applyFilterCIPUserFilter.filter_area_ids.forEach(
                filter_area_ids => {
                  data.data.data.forEach(element => {
                    if (element.area_id === filter_area_ids) {
                      controls.area_id.value.push(element);
                    }
                  });
                }
              );
            }
          } else {
            data.data.data[0].isChecked = true;
          }
        }
        this.setState({
          areaData: data.data.data,
          controls,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  getRegion = zone_ids => {
    this.setState({
      isLoading: true
    });
    let reqData = {
      zone_id: zone_ids
    };
    CampaignService.getRegion(reqData)
      .then(data => {
        let { controls, applyFilterCIPUserFilter } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            zone_id: "all",
            region_id: [],
            region_name: "All"
          });

          if (applyFilterCIPUserFilter && Object.keys(applyFilterCIPUserFilter).length > 0) {
            if (
              applyFilterCIPUserFilter.filter_region_ids &&
              applyFilterCIPUserFilter.filter_region_ids.length > 0
            ) {
              let region_id = [];
              applyFilterCIPUserFilter.filter_region_ids.forEach(filter_region_ids => {
                data.data.data.forEach(element => {
                  if (element.region_id === filter_region_ids) {
                    controls.region_id.value.push(element);
                    region_id.push(element.region_id);
                  }
                });
              });
              let zone_all = lodash.filter(this.state.zoneData, element => {
                return element.zone_name === "All";
              });
              let zone_id = [];
              if (!zone_all[0].isChecked) {
                this.state.zoneData.forEach(element => {
                  if (element.isChecked) {
                    zone_id.push(element.zone_id);
                  }
                });
              }
              this.getArea({ region_ids: region_id, zone_id: zone_id });
            }
          };

          this.setState({
            regionData: data.data.data,
            controls,
            isLoading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  getDealerClassication = () => {
    this.setState({ isLoading: false });
    CampaignService.getDealerClassication()
      .then(data => {
        if (data.data.data.customer_class) {
          data.data.data.customer_class.splice(0, 0, {
            "customer_class": "All",
            "customer_class_code": "all"
          });

          //Set Props Data
          if (this.state.isSetDataFirstTime && this.state.applyFilterCIPUserFilter && Object.keys(this.state.applyFilterCIPUserFilter).length > 0) {
            if (this.state.applyFilterCIPUserFilter.filter_dealer_classification.length === 0) {
              data.data.data.customer_class[0].isChecked = true;
            } else {
              if (this.state.applyFilterCIPUserFilter.filter_dealer_classification) {
                this.state.applyFilterCIPUserFilter.filter_dealer_classification.forEach(filter_dealer_classification => {
                  data.data.data.customer_class.forEach(element => {
                    if (element.customer_class_code === filter_dealer_classification) {
                      element.isChecked = true;
                    }
                  });
                });
              }
            }
          } else {
            data.data.data.customer_class[0].isChecked = true;
          }
        }
        this.setState({
          dealerData: data.data.data.customer_class,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        return err;
      });
  }

  onChangeFilter = (e) => {
    let controlName = e.target.name;
    this.setState({
      [controlName]: e.target.value
    })
  }

  //change for 
  ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.stateData.length === this.state.controls.state_id.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen && <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }} name="serachValueState" value={this.state.serachValueState} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };
  ValueContainerCity = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.cityData.length === this.state.controls.city_id.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen && <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }}
            value={this.state.serachValueCity}
            name="serachValueCity" onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };
  ValueContainerRegion = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.regionData.length ===
            this.state.controls.region_id.value.length
            ? nbValues - 1
            : nbValues
            } selected`}</span>
          {props.selectProps.menuIsOpen && (
            <input
              type="text"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#fff",
                width: "100%"
              }}
              name="serachValueRegion"
              value={this.state.serachValueRegion}
              onChange={e => this.onChangeFilter(e)}
              placeholder="Serach..."
            />
          )}
        </components.ValueContainer>
      </>
    );
  };
  ValueContainerArea = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.areaData.length ===
            this.state.controls.area_id.value.length
            ? nbValues - 1
            : nbValues
            } selected`}</span>
          {props.selectProps.menuIsOpen && (
            <input
              type="text"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#fff",
                width: "100%"
              }}
              name="serachValueArea"
              value={this.state.serachValueArea}
              onChange={e => this.onChangeFilter(e)}
              placeholder="Serach..."
            />
          )}
        </components.ValueContainer>
      </>
    );
  };

  customFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  customFilterRegion = (option, searchText) => {
    if (
      option.data.region_name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  customFilterArea = (option, searchText) => {
    if (
      option.data.area_name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  getReqData = () => {
    let { dealerData, controls, zoneData, cipCategory } = this.state;
    let reqData = {
      "customer_target_type": "jk_cip_user",
      filter_zone_ids: [],
      filter_area_ids: [],
      filter_region_ids: [],
      filter_state_ids: [],
      filter_city_ids: [],
      filter_dealer_classification: [],
      filter_cip_start_date: null,
      filter_cip_start_date: null,
      filter_cip_category: [],
      filter_cip_objective: null
    };
    let all = '';
    // Zone Data
    if (zoneData.length > 0) {
      all = lodash.filter(zoneData, element => {
        return element.zone_name === "All";
      });
      if (all && !all[0].isChecked) {
        zoneData.forEach(element => {
          if (element.isChecked) {
            reqData.filter_zone_ids.push(element.zone_id);
          }
        });
      }
    }

    //Region Data
    if (controls.region_id.value.length > 0) {
      all = controls.region_id.value.findIndex(x => x.region_name === "All");
      if (all <= -1) {
        controls.region_id.value.forEach(element => {
          reqData.filter_region_ids.push(element.region_id);
        });
      }
    }

    // Area Data
    if (controls.area_id.value.length > 0) {
      all = controls.area_id.value.findIndex(x => x.area_name === "All");
      if (all <= -1) {
        controls.area_id.value.forEach(element => {
          reqData.filter_area_ids.push(element.area_id);
        });
      }
    }

    //state Data
    if (controls.state_id.value.length > 0) {
      all = controls.state_id.value.findIndex(x => x.name === 'All');
      if (all <= -1) {
        controls.state_id.value.forEach(element => {
          reqData.filter_state_ids.push(element.state_id);
        });

      }
    }

    // city data
    if (controls.city_id.value.length > 0) {
      all = controls.city_id.value.findIndex(x => x.name === 'All');
      if (all <= -1) {
        controls.city_id.value.forEach(element => {
          reqData.filter_city_ids.push(element.city_id);
        });

      }
    }

    //dealerData
    if (dealerData.length > 0 && this.state.isDisplayDealer) {
      all = lodash.filter(dealerData, (element) => {
        return element.customer_class === 'All';
      });
      if (!all[0].isChecked) {
        dealerData.forEach(element => {
          if (element.isChecked) {
            reqData.filter_dealer_classification.push(element.customer_class_code);
          }
        });
      } else {
        reqData.filter_dealer_classification = [];
      }
    }

    // cipCategory
    if (cipCategory.length > 0) {
      all = lodash.filter(cipCategory, element => {
        return element.name === "All";
      });
      if (all && !all[0].isChecked) {
        cipCategory.forEach(element => {
          if (element.isChecked) {
            reqData.filter_cip_category.push(element.label);
          }
        });
      }
    }

    // filter_cip_start_date
    if (controls.filter_cip_start_date.value) {
      // var date = new Date(controls.filter_cip_start_date.value).setHours(0, 0, 0, 0);
      var start_date = new Date(controls.filter_cip_start_date.value);
      start_date.setHours(0);
      start_date.setMinutes(0);
      reqData.filter_cip_start_date = new Date(start_date).toISOString();
    }

    // filter_cip_end_date
    if (controls.filter_cip_end_date.value) {
      // var date = new Date(controls.filter_cip_end_date.value).setHours(0, 0, 0, 0);
      var end_date = new Date(controls.filter_cip_end_date.value);
      end_date.setHours(23);
      end_date.setMinutes(59);
      reqData.filter_cip_end_date = new Date(end_date).toISOString();
    }

    // filter_cip_objective
    if (controls.cip_objective.value) {
      reqData.filter_cip_objective = controls.cip_objective.value;
    }
    return reqData;
  }

  getCustomerCount = () => {

    let reqData = this.getReqData();
    this.setState({ isLoading: true });
    CampaignService.getCIPCustomerCount(reqData)
      .then(data => {
        this.setState({
          counts: data.data.data.count,
          isLoading: false
        });
        if (data.data.data.count === 0) {
          ModalService.openAlert('', "There is no user found with applied filter. Please change filter criteria", 'error')
          return
        }
        let filterData = {
          counts: this.state.counts,
        };
        filterData = { ...reqData, ...filterData };
        this.props.applyFilter(filterData);
      })
      .catch(e => {
        this.setState({ isLoading: false });

        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error');
      });
  }

  applyFilter = () => {
    this.getCustomerCount();
  }
  resetFilter = (flag) => {
    this.props.applyFilter({});
  }

  closeSideBar = () => {
    this.props.closeSideBar(false);
  }

  getCipCategory = () => {
    CustomerIntractionServie.getCipCategory()
      .then((data) => {
        let { cipCategory } = this.state;
        cipCategory = data.data.data;
        cipCategory.unshift({ name: "All", label: 'all' });

        if (this.state.isSetDataFirstTime && this.state.applyFilterCIPUserFilter && Object.keys(this.state.applyFilterCIPUserFilter).length > 0) {
          if (this.state.applyFilterCIPUserFilter.filter_cip_category.length === 0) {
            data.data.data[0].isChecked = true;
          } else {
            if (this.state.applyFilterCIPUserFilter.filter_cip_category) {
              this.state.applyFilterCIPUserFilter.filter_cip_category.forEach(filter_cip_category => {
                data.data.data.forEach(element => {
                  if (element.label === filter_cip_category) {
                    element.isChecked = true;
                  }
                });
              });
            }
          }
        } else {
          data.data.data[0].isChecked = true;
        }

        this.setState({ cipCategory });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  handleClickOutside = (event) => {
    if (this.refState) {
      const domNode = ReactDOM.findDOMNode(this.refState);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refState.state.menuIsOpen) {
          this.refState.onMenuClose();
        }
      }
    }

    if (this.refCity) {
      const domNode = ReactDOM.findDOMNode(this.refCity);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refCity.state.menuIsOpen) {
          this.refCity.onMenuClose();
        }
      }
    }


    if (this.refRegion) {
      const domNode = ReactDOM.findDOMNode(this.refRegion);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refRegion.state.menuIsOpen) {
          this.refRegion.onMenuClose();
        }
      }
    }

    if (this.refArea) {
      const domNode = ReactDOM.findDOMNode(this.refArea);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refArea.state.menuIsOpen) {
          this.refArea.onMenuClose();
        }
      }
    }
  }

  render() {
    let { controls } = this.state;
    const customStyles = {
      control: base => ({
        ...base,
        maxHeight: 50,
        overflow: 'auto',
        theme: 'primary',
        borderColor: '#fee19d',
        outline: '0',
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
      }),
      input: styles => ('text'),
    };
    const CustomOption = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label
              className="container-cb pr-0" ref={innerRef} {...innerProps}
            >
              <input
                type="checkbox"
                name="cc_self"
                checked={props.isSelected === true || props.isSelected === 'true'}
                id="checkbox"
              />
              <span className="checkmarkrectangle" />
            </label>
            <span className="ml-2" ref={innerRef} {...innerProps} style={{ marginTop: '-2px' }}>{props.data.name}</span>
          </div>
        </>
      );
    };

    const CustomOptionNew = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label
              className="container-cb pr-0" ref={innerRef} {...innerProps}
            >
              <input
                type="checkbox"
                name="cc_self"
                checked={props.isSelected === true || props.isSelected === 'true'}
                id="checkbox"
              />
              <span className="checkmarkrectangle" />
            </label>
            <span
              ref={innerRef}
              {...innerProps}
              className="ml-2"
              style={{ marginTop: "-2px" }}>
              {props.data.region_name
                ? props.data.region_name
                : props.data.area_name
                  ? props.data.area_name
                  : ""}
            </span>
          </div>
        </>
      );
    };

    const CustomOptionCity = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label
              className="container-cb pr-0" ref={innerRef} {...innerProps}
            >
              <input
                type="checkbox"
                name="cc_self"
                checked={props.isSelected === true || props.isSelected === 'true'}
                id="checkbox"
              />
              <span className="checkmarkrectangle" />
            </label>
            <span className="ml-2" ref={innerRef} {...innerProps} style={{ marginTop: '-2px' }}>{props.data.name}</span>
          </div>
        </>
      );
    };
    return (
      <div class="custom-drawer" id="AddEditActivity">
        {this.state.isLoading && <CustomSpinner />}

        <div class="custom-drawer-content" onClick={this.handleClickOutside}>

          <Row className="c-top-15" style={{ marginLeft: '0px', marginRight: '0px' }}>

            <Col xl="12" className="mb-2">
              <span className="filter-type-lbl ml-0">Select Zone</span>
            </Col>
            <Col xl="12">
              <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                {
                  (this.state.zoneData || []).map((data, index) => (
                    <div className="d-flex" style={{ paddingRight: '20px' }}>
                      <label
                        className="container-cb pr-0"
                      >
                        <input
                          type="checkbox"
                          name="filter_zone_ids"
                          checked={data.isChecked}
                          id="checkbox"
                          value={data.zone_id}
                          onChange={(e) => this.handleInputChange(e, index)}
                        />
                        &nbsp;{data.zone_name}
                        <span className="checkmarkrectangle" />
                      </label>
                    </div>
                  ))
                }
              </div>
            </Col>

            <Col xl="4" md="12" xs="12">
              <Col xl="12" className="mb-2 pl-0">
                <span className="filter-type-lbl ml-0 pl-0">Region</span>
              </Col>
              <FormGroup>
                <Select
                  {...this.props.input}
                  ref={r => (this.refRegion = r)}
                  name="region"
                  id="region"
                  pageSize="1"
                  getOptionLabel={option => option.region_name}
                  getOptionValue={option => option.region_id}
                  options={this.state.regionData}
                  allowSelectAll
                  closeMenuOnSelect={false}
                  value={controls.region_id.value}
                  tabSelectsValue={false}
                  isSearchable={true}
                  filterOption={this.customFilterRegion}
                  onChange={e =>
                    this.handleInputChange({
                      target: { name: "region_id", value: e }
                    })
                  }
                  placeholder="Search..."
                  styles={customStyles}
                  hideSelectedOptions={false}
                  onInputChange={e =>
                    this.onChangeFilter({
                      target: { name: "serachValueRegion", value: e }
                    })
                  }
                  inputValue={this.state.serachValueRegion}
                  components={{
                    Option: CustomOptionNew,
                    ValueContainer: this.ValueContainerRegion
                  }}
                  backspaceRemoves={false}
                  isMulti
                  onChangeCallback={this.onBlurSelect}
                />
              </FormGroup>
            </Col>

            <Col xl="4" md="12" xs="12">
              <FormGroup>
                <Col xl="12" className="mb-2 pl-0">
                  <span className="filter-type-lbl ml-0">Area</span>
                </Col>
                <Select
                  {...this.props.input}
                  ref={r => (this.refArea = r)}
                  name="target"
                  pageSize="1"
                  getOptionLabel={option => option.area_name}
                  getOptionValue={option => option.area_id}
                  options={this.state.areaData}
                  allowSelectAll
                  closeMenuOnSelect={false}
                  value={controls.area_id.value}
                  tabSelectsValue={false}
                  isSearchable={true}
                  filterOption={this.customFilterArea}
                  onChange={e =>
                    this.handleInputChange({
                      target: { name: "area_id", value: e }
                    })
                  }
                  placeholder="Search..."
                  styles={customStyles}
                  hideSelectedOptions={false}
                  onInputChange={e =>
                    this.onChangeFilter({
                      target: { name: "serachValueArea", value: e }
                    })
                  }
                  inputValue={this.state.serachValueArea}
                  components={{
                    Option: CustomOptionNew,
                    ValueContainer: this.ValueContainerArea
                  }}
                  backspaceRemoves={false}
                  isMulti
                  onBlur={() => {
                    this.refArea.select.setState({ isOpen: false });
                  }}
                />
              </FormGroup>
            </Col>

            <Col xl="4" md="12" xs="12">
            </Col>

            <Col xl="4" md="12" xs="12">
              <Col xl="12" className="mb-2 pl-0">
                <span className="filter-type-lbl ml-0 pl-0">State</span>
              </Col>
              <FormGroup>
                <Select
                  {...this.props.input}
                  ref={r => this.refState = r}
                  name="state_id"
                  pageSize="1"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.state_id}
                  options={this.state.stateData}
                  allowSelectAll
                  closeMenuOnSelect={false}
                  value={controls.state_id.value}
                  tabSelectsValue={false}
                  isSearchable={true}
                  filterOption={this.customFilter}
                  onChange={(e) => this.handleInputChange({ target: { name: 'state_id', value: e } })}
                  onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueState', value: e } })}
                  placeholder="Search..."
                  styles={customStyles}
                  hideSelectedOptions={false}
                  inputValue={this.state.serachValueState}
                  components={{ Option: CustomOption, ValueContainer: this.ValueContainer }}
                  backspaceRemoves={false}
                  isMulti
                />
              </FormGroup>
            </Col>

            <Col xl="4" md="12" xs="12">
              <Col xl="12" className="mb-2 pl-0">
                <span className="filter-type-lbl ml-0 pl-0">City</span>
              </Col>
              <FormGroup>
                <Select
                  {...this.props.input}
                  ref={r => this.refCity = r}
                  name="city_id"
                  pageSize="1"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.city_id}
                  options={this.state.cityData}
                  loadOptions={this.state.cityData}
                  allowSelectAll
                  closeMenuOnSelect={false}
                  value={controls.city_id.value}
                  tabSelectsValue={false}
                  isSearchable={true}
                  filterOption={this.customFilter}
                  onChange={(e) => this.handleInputChange({ target: { name: 'city_id', value: e } })}
                  placeholder="Search..."
                  styles={customStyles}
                  hideSelectedOptions={false}
                  onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueCity', value: e } })}
                  inputValue={this.state.serachValueCity}
                  components={{ Option: CustomOptionCity, ValueContainer: this.ValueContainerCity }}
                  backspaceRemoves={false}
                  isMulti
                />
              </FormGroup>

            </Col>

            <Col xl="12" className="mb-2">
              <span className="filter-type-lbl ml-0">Select Dealer's classification</span>
            </Col>
            <Col xl="12">
              <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                {
                  (this.state.dealerData || []).map((dealer, index) => (
                    <div className="d-flex" style={{ paddingRight: '20px' }}>
                      <label
                        className="container-cb pr-0"
                      >
                        <input
                          type="checkbox"
                          name="dealer"
                          checked={dealer.isChecked}
                          id="checkbox"
                          value={dealer.customer_class_code}
                          onChange={(e) => this.handleInputChange(e, index)}
                        />
                        &nbsp;{dealer.customer_class}
                        <span className="checkmarkrectangle" />
                      </label>
                    </div>
                  ))
                }
              </div>
            </Col>

            <Col xl="12">
              <Label className="font-weight-bold">CIP Registration Date</Label>
            </Col>
            <Col xl="4">
              <FormGroup className="d-flex-column">
                <Label>Start Date</Label>
                <Label onClick={e => this.calendar1.state.open && e.preventDefault()}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.filter_cip_start_date.value
                          ? new Date(controls.filter_cip_start_date.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'filter_cip_start_date', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar1 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      // maxDate={controls.filter_cip_start_date.value ? controls.filter_cip_start_date.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>
            <Col xl="4">
              <FormGroup className="d-flex-column">
                <Label>End Date</Label>
                <Label onClick={e => this.calendar1.state.open && e.preventDefault()}>
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.filter_cip_end_date.value
                          ? new Date(controls.filter_cip_end_date.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'filter_cip_end_date', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar1 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      // maxDate={controls.filter_cip_end_date.value ? controls.filter_cip_end_date.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>

            <>
              <Col xl="12" className="mb-2">
                <span className="filter-type-lbl ml-0">Select Category</span>
              </Col>
              <Col xl="12">
                <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                  {
                    (this.state.cipCategory || []).map((category, index) => (
                      <div className="d-flex" style={{ paddingRight: '20px' }}>
                        <label className="container-cb pr-0" >
                          <input
                            type="checkbox"
                            name="vehiclecategory"
                            checked={category.isChecked}
                            id="checkbox"
                            value={category.label}
                            onChange={(e) => this.handleInputChange(e, index)}
                          />
                          &nbsp;{category.name}
                          <span className="checkmarkrectangle" />
                        </label>
                      </div>
                    ))
                  }
                </div>
              </Col>
            </>

          </Row>
          <Col xl="6" md="12" xs="12">
            <Col xl="12" className="mb-2 pl-0">
              <span className="filter-type-lbl ml-0 pl-0">CIP Objectives</span>
            </Col>
            <FormGroup>
              <select
                className="form-control select-height-70rem font-size-14px"
                name="cip_objective"
                value={controls.cip_objective.value}
                onChange={e => {
                  this.handleInputChange(e);
                }}
              >
                <option selected="selected" value="" disabled>
                  Select Objective
                </option>
                {this.state.cip_objetives.map(item => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </FormGroup>

          </Col>

          <br /><br />
        </div>
        <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter(true)} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
            <Button
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: 'pointer' }}
              onClick={() => this.applyFilter()}
            >Apply</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CIPCampaignFilter;