import React, { Component, Fragment } from 'react';
import {
  TabContent, TabPane,
  Table, Card, CardBody, CardHeader, Nav, NavLink, NavItem
} from 'reactstrap';
import classnames from 'classnames';

class TruckBusPoints extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    }
  }

  getStripedStyle(index) {
    return { background: index % 2 === 0 ? '#f6f6f6' : 'white' };
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let truckBusPointsPoints = this.props.truckBusPointsPoints;
    return (
      <Fragment>
        <Card tabs="true" className="about-tab-header" style={{ boxShadow: 'none' }}>
          <CardHeader className="card-header-tab">
            <Nav style={{ marginLeft: '0' }}>
              {truckBusPointsPoints.tabs.map((t, index) =>
                <NavItem>
                  <NavLink href="javascript:void(0);"
                    className={classnames({ active: this.state.activeTab === index })}
                    onClick={() => {
                      this.toggleTab(index);
                    }}
                  >
                    {t}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </CardHeader>
          <CardBody style={{ padding: '0' }}>

            <br />

            <div className="PASSENGER-CAR-RADIAL-label">
              {truckBusPointsPoints.type}
            </div>
            <TabContent activeTab={this.state.activeTab}>
              {truckBusPointsPoints.tabs.map((t, index) =>
                <TabPane tabId={index}>
                  <div className="d-flex-row truck-bus-wrapper" style={{ marginRight: '0px', marginLeft: '0px' }}>
                    <Table className="table-responsive tbl-tbb tbl-about">
                      <thead>
                        <tr style={{ ...this.getStripedStyle(0) }}>
                          <th colspan={truckBusPointsPoints[t].TBB.headers.length} style={{ textAlign: 'center' }}>
                            TBB
                          </th>
                        </tr>
                        <tr>
                          {
                            truckBusPointsPoints[t].TBB.headers.map((item) => (
                              <th>{item}</th>
                            ))
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          truckBusPointsPoints[t].TBB.ponits.map((row, i) => (
                            <tr key={i} style={{ ...this.getStripedStyle(i) }}>
                              {
                                (row.length === 3) &&
                                row.map((item, j) => (
                                  <td className={j === 0 && row.length === 3 ? 'tdd-size-td' : ''} rowspan={j === 0 && row.length === 3 ? truckBusPointsPoints[t].TBB.ponits.length : '1'}>{item}</td>
                                ))
                              }
                              {
                                (row.length === 2) &&
                                row.map((item, j) => (
                                  <td>{item}</td>
                                ))
                              }
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                    <Table className="mb-0 table-responsive tbl-tbr tbl-about">
                      <thead>
                        <tr style={{ ...this.getStripedStyle(0) }}>
                          <th colspan={truckBusPointsPoints[t].TBR.headers.length} style={{ textAlign: 'center' }}>
                            TBR
                          </th>
                        </tr>
                        <tr>
                          {
                            truckBusPointsPoints[t].TBR.headers.map((item) => (
                              <th>{item}</th>
                            ))
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          truckBusPointsPoints[t].TBR.ponits.map((row, i) => (
                            <tr key={i} style={{ ...this.getStripedStyle(i) }}>
                              {
                                (row.length === 3) &&
                                row.map((item, j) => (
                                  <td className={j === 0 && row.length === 3 ? 'tdd-size-td' : ''} rowspan={j === 0 && row.length === 3 ? truckBusPointsPoints[t].TBR.ponits.length : '1'}>{item}</td>
                                ))
                              }
                              {
                                (row.length === 2) &&
                                row.map((item, j) => (
                                  <td>{item}</td>
                                ))
                              }
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
                  <br />
                  {/* <div className="truckbus-note">
        <span className="Base-Points-80-Tyre-label">
        {truckBusPointsPoints.note}
        </span>
        </div> */}
                  <br />
                </TabPane>
              )}

            </TabContent>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default TruckBusPoints;