import lodash from "lodash";
export const personalDetail = {
  firstName: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  lastName: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  mobileNo: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  email: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
}

export const vehicleDetail = {
  city: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  vehicleMake: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  vehicleModel: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
}

export const EventRegistrationInformationControls = {
  
  prosonalDetailInfo: lodash.cloneDeep(personalDetail),
  vehicleDetailInfo: [lodash.cloneDeep(vehicleDetail)],  
}