import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "react-sidebar";
import lodash from "lodash";
import { bindActionCreators } from "redux";
import { setFilterOpenClose } from "../../actions/RetailManagementSystemAction";
import RecceManagementMiniFilter from "../../components/RecceManagementMiniFilter/RecceManagementMiniFilter";
import {
  getFilterOpenClose,
  getRecceStatusCount,
} from "../../components/RetailMarketingSystem/selector";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import RetailMarketingHOList from "../../components/RetailMarketingSystem/RetailMarketingHOList/RetailMarketingHOList";

class RetailMarketingHOSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      recceManagementFilterStates: {},
      recceListCount: 0,
      jkUserFilterData: {},
    };
    this.childRecceManagementFilter = React.createRef();
    // this.childRecceManagementFilter  = React.createRef();
  }

  addNewRecce = () => {
    this.props.history.push({
      pathname: "/retail-marketing-recce",
      editId: "",
    });
  };
  onCloseSidebarRecceListManagementFilter = () => {
    document.body.style.overflow = "auto";
    this.props.setFilterOpenCloseAction(false);
  };
  openRecceListManagementFilter = (is_open_from_dashboard = false) => {
    document.body.style.overflow = "hidden";
    GoogleAnalticsService.event("Customer List", "Customer Filter View");
    this.props.setFilterOpenCloseAction(true);
  };
  render() {
    return (
      <div>
        {this.props.filterOpen && (
          <Sidebar
            sidebar={
              <>
                <RecceManagementMiniFilter
                  ref={this.childRecceManagementFilter}
                  onCloseSidebar={this.onCloseSidebarRecceListManagementFilter}
                  jkUserFilterData={lodash.cloneDeep(
                    this.state.recceManagementFilterStates
                  )}
                />
              </>
            }
            open={this.props.filterOpen}
            sidebarClassName="customer-management-filter"
            pullRight
            styles={{
              sidebar: {
                background: "white",
                height: "93%",
                width: "550px",
                top: "60px",
                overflowY: "auto !important",
              },
            }}
          />
        )}
        <div className="d-flex justify-content-between align-items-center">
          <span className="header-title">Retail Marketing System</span>
          <p
            style={{
              fontWeight: "bold",
              marginBottom: 0,
              color: "#8a8a8a",
              fontStyle: "italic",
              fontSize: "18px",
            }}
          >
            Total Installation: {this.props.count}
          </p>
          {/* <Button
            className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
            color="warning"
            type="submit"
            style={{ float: "right", width: "160px" }}
            onClick={(e) => this.addNewRecce()}
          >
            Add a New Recce
          </Button> */}
        </div>
        <div className="mt-3">
          <RetailMarketingHOList
            openRecceFilter={this.openRecceListManagementFilter}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // recceStatus: getRecceStatus(state),
    count: getRecceStatusCount(state),
    // statusCount:getRecceStatusCountFilter(state),
    filterOpen: getFilterOpenClose(state),
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // setRecceStatusAction:setRecceStatus,
      // setRecceStatusCountFilterAction:setRecceStatusCountFilter,
      // setRecceStatusCountAction:setRecceStatusCount,
      setFilterOpenCloseAction: setFilterOpenClose,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailMarketingHOSystem);
