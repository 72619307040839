import React, { Component } from 'react';
import {
  Button,
  FormGroup, Label,
  Input,
  Row, Col,
  Card, CardBody,
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import { messages } from '../../constants/constant.message';

const {
  EMPTY_STREET_NAME,
  EMPTY_STATE,
  EMPTY_CITY,
  EMPTY_ALTERNATE_PERSON,
  EMPTY_PINCODE,
  INVALID_WHATSAPP_NO,
  EMPTY_WHATSAPP_NO,
  INVALID_PINCODE
} = messages.PROFILE;

class EditBranch extends Component {

  state = {
    isFirstTime: true
  }

  handleOnCancel = () => {
    this.props.onCancel('isBranchEditActive', false);
  }


  componentWillReceiveProps(props) {
    const { alternateContactPerson, wa } = props;
    if (this.state.isFirstTime === true) {
      if (alternateContactPerson.autoFocus === true) {
        this.alternateContactPerson.focus();
        this.setState({ isFirstTime: false });
        this.props.activeFragment('business');
      } else if (wa.autoFocus === true) {
        this.wa.focus();
        this.props.activeFragment('business');
        this.setState({ isFirstTime: false });
      }
    }
  }


  render() {

    const { alternateContactPerson, street,
      state, city, states, cities, pincode, wa
    } = this.props;

    return <Card className={this.props.className}>
      <CardBody>
        {this.props.isLoading && <CustomSpinner></CustomSpinner>}
        <Row>
          <Col xl="12" md="12" xs="12">
            <b>ENTER YOUR DETAILS</b>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="email">Contact Person's Name <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="alternateContactPerson"
                id="alternateContactPerson"
                innerRef={e => this.alternateContactPerson = e}
                autoFocus={alternateContactPerson.autoFocus}
                // className="required-input"
                // ref={(input) => { this.email = input }}
                value={alternateContactPerson.value}
                onChange={this.props.handleInputChange}
                onFocus={this.props.activeFragment ? this.props.activeFragment.bind(this, 'business') : null}
                placeholder="Type here" />
              {alternateContactPerson.showErrorMsg && < div className="error">* {EMPTY_ALTERNATE_PERSON}</div>}

              {/* {email.showErrorMsg && email.invalidEmail && <div className="error">* {INVALID_EMAIL}</div>}
              {email.showErrorMsg && email.nullValue && <div className="error">* {EMPTY_EMAIL}</div>} */}
            </FormGroup>
          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="phone">Whatsapp Number <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="wa"
                innerRef={e => this.wa = e}
                autoFocus={wa.autoFocus}
                // className="required-input"
                id="wa"
                value={wa.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {/* {wa.showErrorMsg && < div className="error">* {EMPTY_OWNER_NAME}</div>} */}
              {wa.showErrorMsg && wa.invalidPhone && <div className="error">* {INVALID_WHATSAPP_NO}</div>}
              {wa.showErrorMsg && wa.nullValue && <div className="error">* {EMPTY_WHATSAPP_NO}</div>}
            </FormGroup>

          </Col>
        </Row>
        <Row>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="email">Street Address <span className="required-field">*</span></Label>
              <Input

                type="textarea"
                name="street"
                id="street"
                innerRef={e => this.street = e}
                autoFocus={street.autoFocus}
                value={street.value}
                onChange={this.props.handleInputChange}
                onFocus={this.props.activeFragment ? this.props.activeFragment.bind(this, 'business') : null}
                placeholder="Type here" />
              {street.showErrorMsg && < div className="error">* {EMPTY_STREET_NAME}</div>}

              {/* {email.showErrorMsg && email.invalidEmail && <div className="error">* {INVALID_EMAIL}</div>}
              {email.showErrorMsg && email.nullValue && <div className="error">* {EMPTY_EMAIL}</div>} */}
            </FormGroup>
          </Col>


          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="gst">State <span className="required-field">*</span></Label>
              <Typeahead
                id="state"
                selected={state.value}
                labelKey="name"
                options={states}
                onChange={this.props.handleSelectState}
                placeholder="Choose a state..."
              />
              {state.showErrorMsg && < div className="error">* {EMPTY_STATE}</div>}
            </FormGroup>

          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="phone">City <span className="required-field">*</span></Label>
              <Typeahead
                id="city"
                selected={city.value}
                labelKey="name"
                options={cities}
                onChange={this.props.handleSelectCity}
                placeholder="Choose a City..."
              />
              {city.showErrorMsg && < div className="error">* {EMPTY_CITY}</div>}
            </FormGroup>

          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="pincode">Pincode <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="pincode"
                id="pincode"
                className="capital-case"
                value={pincode.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {/* change message */}
              {pincode.showErrorMsg && (pincode.invalidLength || pincode.invalidNumber) && <div className="error">* {INVALID_PINCODE}</div>}
              {pincode.showErrorMsg && pincode.nullValue && <div className="error">* {EMPTY_PINCODE}</div>}
            </FormGroup>

          </Col>
        </Row>
        {this.props.onSave && <Row>
          <Col xl="12" xs="12" md="12" className="text-align-right" >
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" onClick={this.handleOnCancel}>Cancel</Button>
            <Button
              type="submit"
              className="mb-1 mr-1 btn-pill action-button profile-save-button"
              color="warning"
              // disabled={!this.props.isFormValid}
              onClick={this.props.onSave}>Save</Button>
          </Col>
        </Row>}
      </CardBody>
    </Card >;
  }
}

export default EditBranch;
