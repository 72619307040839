import React, { Component } from "react";
import PrimaryPointsReportComponent from './components/PrimaryPointsReport';
import GoogleAnalticsService from "../../services/GoogleAnalticsService";

class PrimaryPointsReport extends Component {

  constructor(props) {
    super(props);
    GoogleAnalticsService.pageView('Point Earn Report View');
  }

  render() {
    return <PrimaryPointsReportComponent
      from="page"
      {...this.props}
    >
    </PrimaryPointsReportComponent>
  }
}

export default PrimaryPointsReport;
