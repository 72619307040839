import React, { Component } from 'react';
import {
  Row, Col,
  Card, CardBody
} from 'reactstrap';
import classnames from 'classnames';
import Ionicon from 'react-ionicons';
import { formatNumber } from '../../utils/utils';
import { config } from '../../constants/constant.config';

import './GraphMobile.css';
import Chart from 'react-apexcharts';

const dashBoardImages = config.DASHBOARD;

class GraphMobile extends Component {

  state = {
    activeTab: 'q1'
  };



  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  prepareStackedChart = (categories, series) => {
    const stackedChart = {
      options: {
        chart: {
          stacked: true,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: ''
          },

        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },

        // title: {
        //   text: 'Yearly Offtake'
        // },
        xaxis: {
          categories,
          labels: {
            formatter(val) {
              return val;
            }
          },
          axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            offsetX: 0,
            offsetY: 0
          },
        },
        yaxis: {
          title: {
            text: undefined
          },
          tickAmount: 3,
          crosshairs: {
            show: false,
            position: 'back',
            stroke: {
              color: '#b6b6b6',
              width: 1,
              dashArray: 0,
            },
          },
          axisBorder: {
            show: false,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0
          },
        },
        tooltip: {
          y: {
            formatter(val) {
              return val;
            }
          }
        },
        fill: {
          opacity: 1

        },
        legend: {
          show: false,
          position: 'right',
          horizontalAlign: 'left',
          offsetX: 0,
          offsetY: 30,
          formatter(val) {
            if (val === 'Pcr') {
              return `  <img src = "${dashBoardImages.PCR_IMAGE}" alt='' height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;" > `
            } if (val === 'Truck') {
              return `  <img src = "${dashBoardImages.TRUCK_IMAGE}" alt='' height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;" > `
            } if (val === 'Farm') {
              return `  <img src = "${dashBoardImages.FARM_IMAGE}" alt='' height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;" > `
            } else if (val === 'Wheeler') {
              return `  <img src = "${dashBoardImages.WHEELER_IMAGE}" alt='' height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;" > `
            } else if (val === 'Lcv') {
              return ` < img src = "${dashBoardImages.LCV_IMAGE}" alt='' height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;" > `;
            } else if (val === 'Scv') {
              return `<img src = "${dashBoardImages.SCV_IMAGE}" alt='' height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            // return val
          },
          markers: {
            width: 38,
            height: 18
          },
        },
        grid: {
          show: true,
          yaxis: {
            lines: {
              show: false
            }
          },
        },
        colors: ['#c970ff', '#9ad6d0', '#30b1ff', '#9ad851', '#9e95e2', '#fac2c0']
      },
      series: series.series,
    };

    return stackedChart;
  }

  componentDidMount() {
    const { stackedChartMobile } = this.props;
    const total = stackedChartMobile.total;
    if (total.q1 !== 0) {
      this.toggle('q1');
    } else if (total.q2 !== 0) {
      this.toggle('q2');
    } else if (total.q3 !== 0) {
      this.toggle('q3');
    } else if (total.q4 !== 0) {
      this.toggle('q4');
    }
  }

  render() {
    const { activeTab } = this.state;
    const { stackedChartMobile } = this.props;
    const total = stackedChartMobile.total;
    // if (total.q1 !== 0) {
    //   this.toggle('q1');
    // } else if (total.q2 !== 0) {
    //   this.toggle('q2');
    // } else if (total.q3 !== 0) {
    //   this.toggle('q3');
    // } else if (total.q4 !== 0) {
    //   this.toggle('q4');
    // }
    const stackedChart = this.prepareStackedChart(stackedChartMobile.categories[activeTab], stackedChartMobile.series[activeTab]);
    return <div id="graph-mobile">
      <div className="page-title">
        <span>
          <Ionicon icon="ios-arrow-back" onClick={this.props.changeScreen.bind(this, 'dashboard')} fontSize="40px" color="#fdbb1f" style={{ marginLeft: '-15px' }} />
        </span>
        <span>Yearly Offtake</span>
      </div>
      <Row className="tab-container margin-top-5">
        {total.q1 > 0 && <Col style={{ padding: '10px 0px', textAlign: 'center' }}
          className={classnames({ active: this.state.activeTab === 'q1' })}
          onClick={() => { this.toggle('q1'); }}
        >
          Q1
        </Col>}
        {total.q2 > 0 && <Col style={{ padding: '10px 0px', textAlign: 'center' }}
          className={classnames({ active: this.state.activeTab === 'q2' })}
          onClick={() => { this.toggle('q2'); }}
        >
          Q2
        </Col>}
        {total.q3 > 0 && <Col style={{ padding: '10px 0px', textAlign: 'center' }}
          className={classnames({ active: this.state.activeTab === 'q3' })}
          onClick={() => { this.toggle('q3'); }}
        >
          Q3
        </Col>}
        {total.q4 > 0 && <Col style={{ padding: '10px 0px', textAlign: 'center' }}
          className={classnames({ active: this.state.activeTab === 'q4' })}
          onClick={() => { this.toggle('q4'); }}
        >
          Q4
        </Col>}
      </Row>
      <br />
      <br />
      <Row>
        <Col sm="12">
          <Card style={{ width: '100%' }} >
            <CardBody>
              <Row>
                <Col className="total-offtake">TOTAL OFFTAKE</Col>

                <Col className="counts">{formatNumber(total[activeTab])}</Col>
              </Row>
              <Chart options={stackedChart.options} series={stackedChart.series} type="bar" height="200px" />
              <Row className="tab-container">
                <Col>
                  <div className="margin-bottom-5">
                    <span className="color-block car">{'  '}</span>
                  </div>
                  <div>
                    <img src={dashBoardImages.PCR_IMAGE} height="18px" width="43px" className="image" alt="" />
                  </div>
                </Col>
                <Col>
                  <div className="margin-bottom-5">
                    <span className="color-block truck">{'  '}</span>
                  </div>
                  <div>
                    <img src={dashBoardImages.TRUCK_IMAGE} height="18px" width="43px" className="image" alt="" />
                  </div>
                </Col>
                <Col>
                  <div className="margin-bottom-5">
                    <span className="color-block farm">{'  '}</span>
                  </div>
                  <div>
                    <img src={dashBoardImages.FARM_IMAGE} height="18px" width="43px" className="image" alt="" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="margin-bottom-5">
                    <span className="color-block wheeler" />
                  </div>
                  <div>
                    <img src={dashBoardImages.WHEELER_IMAGE} height="18px" width="43px" className="image" alt="" />
                  </div>
                </Col>
                <Col>
                  <div className="margin-bottom-5">
                    <span className="color-block lcv" />
                  </div>
                  <div>
                    <img src={dashBoardImages.LCV_IMAGE} height="18px" width="43px" className="image" alt="" />
                  </div>
                </Col>
                <Col>
                  <div className="margin-bottom-5">
                    <span className="color-block scv" />
                  </div>
                  <div>
                    <img src={dashBoardImages.SCV_IMAGE} height="18px" width="43px" className="image" alt="" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div >;
  }
}

export default GraphMobile;