import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
  CardHeader,
  Card,
  CardBody
} from "reactstrap";
import classnames from "classnames";
class ScvPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      showMore: true,
      transform: true,
      showInkBar: true,
      selectedTabKey: 0,
      transformWidth: 400
    };
    //this.getAboutDetail();
  }
  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    let scvPoints = this.props.scvPoints;
    return (
      <>
        <Card
          tabs="true"
          className="mb-3 about-tab-header"
          style={{ boxShadow: "none" }}
        >
          <CardHeader className="card-header-tab">
            <Nav style={{ marginLeft: "0" }}>
              {scvPoints.scv.tabs.map((t, index) =>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={classnames({
                      active: this.state.activeTab === index
                    })}
                    onClick={() => {
                      this.toggleTab(index);
                    }}
                  >
                    {t}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </CardHeader>
          <CardBody style={{ padding: "0" }}>
            <TabContent activeTab={this.state.activeTab}>
              {scvPoints.scv.tabs.map((t, index) => {

                return t === 'Quarter 1' && scvPoints.scv.year === '2019-20' ?
                  <TabPane tabId={index}>
                    <Row style={{ justifyContent: "space-between", margin: "0" }}>
                      {scvPoints.scv[t].basePoints.map((item, i) => (
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", width: "auto" }}
                        >
                          <div
                            className="truckbus-note two-three-wheeler-multilple-offer-note"
                            style={{ width: "auto" }}
                          >
                            <span className="Base-Points-80-Tyre-label">
                              {item}
                            </span>
                          </div>
                        </div>
                      ))}
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Table className="table-responsive tbl-tbb tbl-about">
                          <thead>
                            <tr style={{ ...this.getStripedStyle(0) }}>
                              <th
                                colspan={scvPoints.scv[t].headers.length - 1}
                                style={{ textAlign: "center" }}
                              >
                                SCV Bias
                              </th>
                              <th style={{ textAlign: "left" }}>SCV Radial</th>
                            </tr>
                            <tr>
                              {scvPoints.scv[t].headers.map((item, i) => (
                                <th>{item}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {scvPoints.scv[t].biasPoints.map((row, i) => (
                              <tr key={i} style={{ ...this.getStripedStyle(i) }}>
                                {row.map((item, j) =>
                                  j > 1 ? (
                                    j > 2 ? (
                                      <td
                                        rowspan={
                                          scvPoints.scv[t].biasPoints + 1
                                        }
                                        style={{
                                          backgroundColor: "#fff",
                                          borderLeft: "1px solid #e9ecef"
                                        }}
                                      >
                                        {item}
                                      </td>
                                    ) : (
                                      <td
                                        rowspan="6"
                                        style={{
                                          backgroundColor: "#fff",
                                          borderLeft: "1px solid #e9ecef"
                                        }}
                                      >
                                        {item}
                                      </td>
                                    )
                                  ) : (
                                    <td> {item}</td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          {scvPoints.scv[t].note &&
                            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                              <span className="Base-Points-80-Tyre-label">
                                {scvPoints.scv[t].note}
                              </span>
                            </div>
                          }
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  : <TabPane tabId={index}>
                    <Row>
                      <Col sm="12">
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          <Table className="table-responsive tbl-tbb tbl-about">
                            <thead>
                              <tr style={{ ...this.getStripedStyle(0) }}>
                                <th
                                  colspan={scvPoints.scv[t].headers.length}
                                  style={{ textAlign: "center" }}
                                >
                                  SCV Bias
                                </th>
                              </tr>
                              <tr>
                                {scvPoints.scv[t].headers.map((item, i) => (
                                  <th>{item}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {scvPoints.scv[t].scvBiasPoints.map(
                                (row, i) => (
                                  <tr
                                    key={i}
                                    style={{ ...this.getStripedStyle(i) }}
                                  >
                                    {row.map((item, j) => (
                                      <td>{item}</td>
                                    ))}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                          <Table className="table-responsive tbl-tbb tbl-about">
                            <thead>
                              <tr style={{ ...this.getStripedStyle(0) }}>
                                <th
                                  colspan={scvPoints.scv[t].headers.length}
                                  style={{ textAlign: "center" }}
                                >
                                  SCV Radial
                                </th>
                              </tr>
                              <tr>
                                {scvPoints.scv[t].headers.map((item, i) => (
                                  <th>{item}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {scvPoints.scv[t].scvRedialPoints.map(
                                (row, i) => (
                                  <tr
                                    key={i}
                                    style={{ ...this.getStripedStyle(i) }}
                                  >
                                    {row.map((item, j) => (
                                      <td>{item}</td>
                                    ))}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          {scvPoints.scv[t].multipleOffer &&
                            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                              <span className="Base-Points-80-Tyre-label">
                                {scvPoints.scv[t].multipleOffer}
                              </span>
                            </div>
                          }
                        </div>
                        <div
                          className="d-flex-row truck-bus-wrapper"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          {scvPoints.scv[t].note &&
                            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                              <span className="Base-Points-80-Tyre-label">
                                {scvPoints.scv[t].note}
                              </span>
                            </div>
                          }
                        </div>
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
              }
              )}
            </TabContent>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default ScvPoints;
