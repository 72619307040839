import React, { Component } from 'react';
import {
  Row, Col, Button, ModalBody, Modal, Input
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ModalService from '../../services/ModalService';
import swal from 'sweetalert';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
class CipExpensesIncurred extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        expenses: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        }
      },
      cip_dealer_detail: {}
    }
  }

  toggle = (data = {}) => {
    let { controls, cip_dealer_detail } = this.state;
    controls.expenses.value = null;
    cip_dealer_detail = data;
    this.setState({
      isOpenExpensesModal: !this.state.isOpenExpensesModal,
      controls,
      cip_dealer_detail
    })
  }

  cancel = () => {
    if (this.props.redirectSummary) {
      this.props.redirectSummary();
    }
    this.toggle({});
  }

  submitExpensincurred = () => {
    swal({
      title: "",
      text: "Are you sure want to send expense details?\n You can't change this amount later",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.submitExpense();
      }
    });
  }

  submitExpense = () => {
    let { controls, cip_dealer_detail } = this.state;
    if (!controls.expenses.value) {
      return
    }
    let reqData = {
      cip_id: cip_dealer_detail.id,
      expenses: controls.expenses.value
    };
    this.setState({ isLoading: true });
    CustomerIntractionServie.sendBillSettlment(reqData)
      .then((res) => {
        if (this.props.getCip) {
          this.props.getCip();
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          if (this.props.from === 'CipDetail') {
            this.props.redirectSummary();
          }
        });
        this.toggle();
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  }

  onChangeAmount = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;

    if (controlName === 'expenses') {
      if (isNaN(controlValue)) {
        return
      }
    }
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
  }

  render() {
    let { controls } = this.state;
    return (
      <Modal
        isOpen={this.state.isOpenExpensesModal}
        toggle={this.toggle}
        className="modal-md modal-lg custom-confirm-modal modal-sm modal-dialog-centered"
        style={{ maxWidth: '420px' }}
        backdrop={this.backdrop ? "static" : "nostatic"}
      >
        {this.state.isLoading && <CustomSpinner />}
        <div className="d-flex-column">
          <div className="confirm-modal-title-wrapp">
            <span className="confirm-modal-title">EXPENSES INCURRED</span>
            <FontAwesomeIcon className="pull-right cursor-pointer" onClick={() => this.cancel()} icon={faTimes} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
          </div>
        </div>
        <ModalBody classNam="d-flex-column">
          <Row>
            <Col xl="12" xs="12" md="12" className="mt-3 mb-3">
              <div style={{ fontSize: '16px' }}>Please enter the expenses incurred in the CIP.</div>
            </Col>
            <Col>
              <Input
                type="text"
                autocomplete="nofill"
                name="expenses"
                value={controls.expenses.value}
                onChange={(e) => this.onChangeAmount(e)}
                placeholder="Enter Expenses Incurred Amount" />
              {controls.expenses.showErrorMsg && (
                <div className="error">
                  * Please enter expenses incurred amount
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>
        <Col xl="12" xs="12" md="12" className="text-align-center mt-3 mb-4" >
          <Button className="mb-3 mr-1 btn-pill action-button profile-save-button" color="warning"
            disabled={!controls.expenses.value}
            onClick={() => this.submitExpensincurred()}>Submit</Button>
        </Col>
      </Modal>
    );
  }
}

export default CipExpensesIncurred;