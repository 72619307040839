//import ReactGA from 'react-ga';
//import TagManager from 'react-gtm-module';

//import ReactPixel from 'react-facebook-pixel';
import { Roles } from "../constants/constant.role";
import Storage from "./Storage";
let state = Storage.getUserDetail();
window.dataLayer = window.dataLayer || [];
// let FB = window.fbq;
// let fbq = window.fbq;
export default class GoogleAnalticsService {
  static gtag() {
    //window.dataLayer.push(arguments);
  }
  static reactGAInitialize() {
    try {
      state = Storage.getUserDetail();
      // FB = window.FB;
      const processEnv = process.env;
      const pixel_id =
        processEnv.NODE_ENV === "production"
          ? "1123801671354456"
          : "716261112440198";
      if (state && state.sap_id) {
        if (window.fbq) {
          window.fbq("init", pixel_id, { external_id: state.sap_id });
        }
        // FB = window.FB;
        // if (FB) {
        //   window.fbAsyncInit = function () {
        //     FB.init({
        //       appId: '266260921485072',
        //       xfbml: true,
        //       version: 'v7.0'
        //     });
        //     FB.AppEvents.logPageView();
        //     FB.AppEvents.setUserID(state.sap_id);
        //     FB.AppEvents.updateUserProperties({
        //       platform: 'web',
        //       userId: state.sap_id
        //     });
        //     FB.AppEvents.setAppVersion('JK Advatange Web 2.0');
        //   };
        // }
        //ReactPixel.init(266260921485072, {}, { debug: true, autoConfig: false });
        // this.gtag('js', new Date());
        // this.gtag('config', 'G-49QNNPDGK7');
        // this.gtag('config', 'GA_TRACKING_ID', {
        //   'user_id': state.sap_id
        // });

        // ReactGA.initialize('UA-49QNNPDGK7', {
        //   debug: true,
        //   gaOptions: {
        //     userId: state.sap_id,
        //     clientId: state.sap_id
        //   }
        // });

        // ReactGA.set({ userId: state.sap_id });

        // const tagManagerArgs = {
        //   gtmId: 'GTM-KNFL4CM',
        // }

        // TagManager.initialize(tagManagerArgs)
      }
    } catch (error) {
      console.log("err", error);
    }
  }
  static pageView(page_name) {
    try {
      if (state && state.sap_id) {
        if (window.fbq) {
          // FB.AppEvents.logEvent(page_name);
          window.fbq("trackCustom", page_name, {
            sapid:
              state.type === Roles.DEALER_ROLE ? "wlkn" + state.sap_id : state.sap_id,
          });
        }
        // ReactPixel.trackCustom(page_name, {
        //   page: page_name,
        //   user_id: state.sap_id
        // }) 	// For tracking custom events

        // const args = {
        //   gtmId: 'GTM-KNFL4CM',
        //   dataLayer: {
        //     event: page_name.replace(/ /g, "_").toLowerCase(),
        //     userId: state.sap_id,
        //     platform: 'web'
        //   },
        //   dataLayerName: "PageDataLayer"
        // };
        // TagManager.dataLayer(args);

        // this.gtag('event', 'screen_view', {
        //   'app_name': 'Jk Advatnage Web',
        //   'screen_name': page_name
        // });
      }
    } catch (error) {
      console.log("err", error);
      GoogleAnalticsService.reactGAInitialize();
    }
  }

  static event(category, action) {
    // ReactGA.event({
    //   category: category,
    //   action: action
    // });
    try {
      if (state && state.sap_id) {
        if (window.fbq) {
          window.fbq("trackCustom", action, {
            sapid: state.sap_id,
          });
        }
        // ReactPixel.trackCustom(action, {
        //   category: category,
        //   action: action,
        //   user_id: state.sap_id
        // }) 	// For tracking custom events

        // const args = {
        //   gtmId: 'GTM-KNFL4CM',
        //   dataLayer: {
        //     event: action.replace(/ /g, "_").toLowerCase(),
        //     userId: state.sap_id,
        //     platform: 'web',
        //     page: category
        //   },
        //   dataLayerName: "PageDataLayer"
        // };
        // TagManager.dataLayer(args);

        // this.gtag('event', action.replace(/ /g, "_").toLowerCase(), {
        //   'event_label': category,
        //   'event_category': action.replace(/ /g, "_").toLowerCase(),
        //   'non_interaction': true
        // });
      }
    } catch (error) {
      console.log("err", error);
      GoogleAnalticsService.reactGAInitialize();
    }
  }
}
