import { SET_EVENT_REGISTERATION_INFO } from '../constants/types';
import Validation from '../services/Validation';

// Set Value in store that user put in input
export const setEventRegistrationSystemControls = addEventRegistrationServiceControls => (
  {
    type: SET_EVENT_REGISTERATION_INFO,
    payload: addEventRegistrationServiceControls
  });

// Handle Validation for Inputs
export const handleValidationEventRegistrationInformation = (
  isSubmit = false, // When click on submit button , pass isSubmit=true 
  eventRegistrationDetailControls
) => {
  return (dispatch) => {
    let isFormValid = false;
    let { prosonalDetailInfo, vehicleDetailInfo } = eventRegistrationDetailControls
    if (prosonalDetailInfo.firstName.touched === true || isSubmit) {
      prosonalDetailInfo.firstName = Validation.notNullValidator(prosonalDetailInfo.firstName);
      prosonalDetailInfo.firstName.valid = !prosonalDetailInfo.firstName.nullValue;
      if (prosonalDetailInfo.firstName.valid === false) {
        prosonalDetailInfo.firstName.showErrorMsg = true;
      } else {
        prosonalDetailInfo.firstName.showErrorMsg = false;
      }
    }
    if (prosonalDetailInfo.lastName.touched === true || isSubmit) {
      prosonalDetailInfo.lastName = Validation.notNullValidator(prosonalDetailInfo.lastName);
      prosonalDetailInfo.lastName.valid = !prosonalDetailInfo.lastName.nullValue;
      if (prosonalDetailInfo.lastName.valid === false) {
        prosonalDetailInfo.lastName.showErrorMsg = true;
      } else {
        prosonalDetailInfo.lastName.showErrorMsg = false;
      }
    }
    prosonalDetailInfo.email = Validation.emailValidator(prosonalDetailInfo.email);
    prosonalDetailInfo.email.valid = !prosonalDetailInfo.email.invalidEmail && !prosonalDetailInfo.email.nullValue;

    if (prosonalDetailInfo.mobileNo.touched === true || isSubmit || prosonalDetailInfo.email.touched === true) {
      prosonalDetailInfo.mobileNo = Validation.notNullValidator(prosonalDetailInfo.mobileNo);
      prosonalDetailInfo.mobileNo.valid = !prosonalDetailInfo.mobileNo.nullValue;
      if (prosonalDetailInfo.mobileNo.valid === false) {
        if (prosonalDetailInfo.email.valid !== false && prosonalDetailInfo.mobileNo.value === '') {
          prosonalDetailInfo.mobileNo.showErrorMsg = false;
          prosonalDetailInfo.mobileNo.valid = true;
        }
        else {
          prosonalDetailInfo.mobileNo.showErrorMsg = true;
        }

      } else {
        prosonalDetailInfo.mobileNo.showErrorMsg = false;
      }
    }
    if ((prosonalDetailInfo.mobileNo.touched === true || isSubmit || prosonalDetailInfo.email.touched === true) && prosonalDetailInfo.mobileNo.valid !== false) {
      prosonalDetailInfo.mobileNo = Validation.validatePhoneNumber(prosonalDetailInfo.mobileNo);
      prosonalDetailInfo.mobileNo.valid = !prosonalDetailInfo.mobileNo.invalidPhone;
      prosonalDetailInfo.email = Validation.emailValidator(prosonalDetailInfo.email);
      prosonalDetailInfo.email.valid = !prosonalDetailInfo.email.invalidEmail && !prosonalDetailInfo.email.nullValue;
      if (prosonalDetailInfo.mobileNo.valid === false) {
        if (prosonalDetailInfo.email.valid !== false && prosonalDetailInfo.mobileNo.value === '') {
          prosonalDetailInfo.mobileNo.showErrorMsg = false;
          prosonalDetailInfo.mobileNo.valid = true;
        }
        else {
          prosonalDetailInfo.mobileNo.showErrorMsg = true;
        }
      } else {
        prosonalDetailInfo.mobileNo.showErrorMsg = false;
      }
    }
    if (prosonalDetailInfo.email.touched === true || isSubmit || prosonalDetailInfo.mobileNo.value !== '') {
      prosonalDetailInfo.email = Validation.emailValidator(prosonalDetailInfo.email);
      prosonalDetailInfo.email.valid = !prosonalDetailInfo.email.invalidEmail && !prosonalDetailInfo.email.nullValue;
      if (prosonalDetailInfo.email.valid === false) {
        if (prosonalDetailInfo.mobileNo.valid !== false && prosonalDetailInfo.email.value === '') {
          prosonalDetailInfo.email.showErrorMsg = false;
          prosonalDetailInfo.email.valid = true;
        }
        else {
          prosonalDetailInfo.email.showErrorMsg = true;
        }
      } else {
        prosonalDetailInfo.email.showErrorMsg = false;
      }
    }
    let isFormValidVehicleDetailInfo = true;

    vehicleDetailInfo.forEach(element => {
      for (const [key] of Object.entries(element)) {
        if ((element[key].touched === true || isSubmit) &&
          (key === 'vehicleMake')) {
          element[key] = Validation.notNullValidator(element[key]);
          element[key].valid = !element[key].nullValue;
          if (element[key].valid === false) {
            element[key].showErrorMsg = true;
            isFormValidVehicleDetailInfo = false;
          } else {
            element[key].showErrorMsg = false;
          }
        }
        if ((element[key].touched === true || isSubmit) &&
          (key === 'vehicleModel')) {
          element[key] = Validation.notNullValidator(element[key]);
          element[key].valid = !element[key].nullValue;
          if (element[key].valid === false) {
            element[key].showErrorMsg = true;
            isFormValidVehicleDetailInfo = false;
          } else {
            element[key].showErrorMsg = false;
          }
        }
        if ((element[key].touched === true || isSubmit) &&
          (key === 'city')) {
          element[key] = Validation.notNullValidator(element[key]);
          element[key].valid = !element[key].nullValue;
          if (element[key].valid === false) {
            element[key].showErrorMsg = true;
            isFormValidVehicleDetailInfo = false;
          } else {
            element[key].showErrorMsg = false;
          }
        }
      }
    });
    if (
      prosonalDetailInfo.firstName.valid === true &&
      prosonalDetailInfo.lastName.valid === true &&
      prosonalDetailInfo.mobileNo.valid === true &&
      prosonalDetailInfo.email.valid === true &&
      isFormValidVehicleDetailInfo === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    dispatch(setEventRegistrationSystemControls(eventRegistrationDetailControls));
    return isFormValid;
  }
}