import React from 'react';
import './DealerProfileModal.css';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import ProfileDetail from '../../components/ProfileDetail/ProfileDetail';
import DetailCard from '../../components/DetailCard/DetailCard';
import moment from 'moment';
import SocialDetail from '../../components/DetailCard/SocialDetail';

const DealerProfileModal = ({ isLoading, isOpen, toggle, data }) => {

  const getDealerAddress = (dealerData) => {
    let address = "";
    if (dealerData) {
      let { address_line_1, address_line_2, address_line_3, address_line_4 } = dealerData;
      if (address_line_1) {
        address = `${address_line_1}`;
      }
      if (address_line_2) {
        address = `${address}, ${address_line_2}`;
      }
      if (address_line_3) {
        address = `${address}, ${address_line_3}`;
      }
      if (address_line_4) {
        address = `${address}, ${address_line_4}`;
      }
    }
    return address;
  }

  let businessCardObj = {
    title: "OTHER BUSINESS DETAILS",
    edit: "isBusinessEditActive",
    fields: [
      {
        title: "Email Address",
        value: data.email_1 || '',
      },
      {
        title: "Owner’s Phone Number",
        value: data.telephone_no_1 || '',
      },
      {
        title: "GST Number",
        value: data.gst_number || '',
      },
      {
        title: "PAN Number",
        value: data.pan_number || '',
      },
      {
        title: "Address",
        value: getDealerAddress(data),
      },
      {
        title: "State",
        value: data.state || '',
      },
      {
        title: "City",
        value: data.city || '',
      },
      {
        title: "Pin Code",
        value: data.pin_code || '',
      },
    ],
  }

  let contactCardObj = {
    title: "PRIMARY CONTACT DETAILS",
    edit: "isContactEditActive",
    fields: [
      {
        title: "Contact Person",
        value: data.alternate_contact_person || "",
        class: "title-case",
      },
      {
        title: "Designation",
        value: data.designation || "",
      },
      {
        title: "Contact Number",
        value: data.alternate_contact_number || "",
      },
      {
        title: "Whatsapp Number",
        value: data.whats_app_number || "",
      },
    ],
  }

  let socialCardObj = {
    title: "SOCIAL DETAILS",
    edit: "isSocialEditActive",
    fields: [
      {
        title: "Birth Date",
        value: data && data.birth_date ? moment(data.birth_date).format('DD/MM/YYYY') : null,
      },
      {
        title: "Anniversary Date",
        value: data && data.anniversary_date ? moment(data.anniversary_date).format('DD/MM/YYYY') : null,
      },
      {
        title: "Facebook Page",
        value: data.facebook_url || '',
      },
      {
        title: "Instagram Page",
        value: data.instagram_url || '',
      },
      {
        title: "Website",
        value: data.website || '',
      },
      {
        title: "Store Google My Business Link",
        value: data.google_my_business_link || '',
      },
      {
        title: "Store Google My Business Rating Link",
        value: data.google_my_business_rating_link || '',
      },
    ],
  }

  const openUrl = (url) => {
    if (url.indexOf("http") > -1) {
      window.open(url, "_blank");
    } else {
      window.open("http://" + url, "_blank");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} id="dealerProfileModal" scrollable>
      {isLoading && <CustomSpinner />}
      <ModalHeader toggle={toggle} className='align-items-center'>
        Dealer Profile
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="3">
            <ProfileDetail
              sapId={data.sap_id || ''}
              businessName={data.business_name || ''}
              ownerName={data.owner_name || ''}
              address={getDealerAddress(data)}
              mobileNumber={data.sap_id || ''}
              profileImage={''}
              isImageUpdating={false}
              userDetail={data}
              handleUploadImage={() => { }}
              openRequestDetailModal={() => { }}
              updateReqButton={() => { }}
              isUpdateProfileRequestSend={false}
              showRequestButton={false}
              userInfo={data}
            />
          </Col>
          <Col xl="9">
            <Row>
              <Col xs={12} className='mb-3'>
                <DetailCard
                  makeEditActive={() => { }}
                  cardObj={businessCardObj}
                />
              </Col>
              <Col xs={12} className='mb-3'>
                <DetailCard
                  makeEditActive={() => { }}
                  cardObj={contactCardObj}
                />
              </Col>
              <Col xs={12}>
                <SocialDetail
                  makeEditActive={() => { }}
                  cardObj={socialCardObj}
                  openUrl={openUrl}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default DealerProfileModal
