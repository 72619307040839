import React, { Component } from 'react';
import {
  Input,
  Row,
  Col,
} from "reactstrap";
import lodash from "lodash";
class CipTyreRelatedDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tbrTyreSize: [],
      tbbTyreSize: []
    }
    this.state.tbrTyreSize = [
      "7.50R20",
      "8.25R20",
      "9.00R20",
      "10.00R20",
      "11.00R20",
      "12.00R20",
      "11R22.5",
      "275/80R22.5",
      "295/80R22.5",
      "315/80R22.5",
    ];
    this.state.tbbTyreSize = [
      "7.50-20",
      "8.25-20",
      "9.00-20",
      "10.00-20",
      "11.00-20",
      "12.00-20",
      "295/95D20",
    ];
  }

  onChangeFrontRearTyre = (e, index, key, brands = [], type = 'front_data') => {
    this.props.onChangeFrontRearTyre(e, index, key, brands, type);
  }

  removeFrontTyre = (index, key) => {
    this.props.removeFrontTyre(index, key);
  }

  removeRearTyre = (index, key) => {
    this.props.removeRearTyre(index, key);
  }


  render() {
    let { frontRearData } = this.props;
    return (
      <>
        <Row>
          <Col
            xl="6"
            md="12"
            xs="12"
            className="d-flex-column mb-1"
          >
            <b className="margin-right-16 text-uppercase">
              TYRE DETAILS
              <span className="required-field">*</span>
            </b>
            {/* <span className="note-rect" style={{ border: 'none', marginTop: '3px', padding: '0' }}>Other Truck Bus Radial/ Bias Tyres bought Last year</span> */}
          </Col>
          {/* <Col xl="6" md="12" xs="12" className="text-align-right" style={{ marginTop: '20px' }}>
<img src='images/add.svg' alt='' className="cursor-pointer" onClick={() => { this.openAddTyreModal({ uuid: 1 }); }} />&nbsp;<span onClick={() => { this.openAddTyreModal({}); }} className="cursor-pointer">Add Tyres</span>
</Col> */}
        </Row>
        {/* <hr /> */}
        <table
          className="mb-0 table-responsive mt-3 cip-tyre-releated-tbl d-block"
          bordered
        >
          <thead>
            <tr style={{ backgroundColor: "#f6f6f6" }}>
              <th style={{ border: "1px solid #e9ecef" }}>
                Tyre Position
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Construction
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Tyre Size
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Brand
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Pattern
              </th>
              <th style={{ border: "1px solid #e9ecef", textAlign: 'center' }}>
                Tyres Annum
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(frontRearData).length > 0 && frontRearData[this.props.activeTab].map((item, index) => {
              return (
                item.front_or_rear.value ===
                "front_data" && (
                  <tr>
                    <td className="font-size-14px" valign={item.construction.valid && item.brand.valid &&
                      item.pattern.valid && item.size.valid && item.tyres.valid ? 'middle' : 'top'}>
                      <div className="d-flex">
                        <span className={item.construction.valid && item.brand.valid &&
                          item.pattern.valid && item.size.valid && item.tyres.valid ? "mt-auto mb-auto" : "m-0"} style={{ minWidth: '80px' }}>Front</span>
                      </div>
                    </td>
                    <td
                      className="business-related-detail-td"
                      valign="top"
                    >
                      <select
                        className="form-control font-size-14px"
                        name="construction"
                        value={item.construction.value}
                        disabled={this.props.is_view_only_dealils}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab
                          )
                        }
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Select
                        </option>
                        <option value="bias">Bias</option>
                        <option value="radial">
                          Radial
                        </option>
                      </select>
                      {!item.construction.valid && <div className="error mt-1">Please select construction</div>}
                    </td>
                    <td
                      className="business-related-detail-td"
                      valign="top"
                    >
                      <select
                        className="form-control font-size-14px"
                        name="size"
                        value={item.size.value}
                        disabled={this.props.is_view_only_dealils}
                        style={{ minWidth: '93px' }}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab
                          )
                        }
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Select
                        </option>
                        {this.props.activeTab !== "lcv" &&
                          this.props.activeTab !== "scv" &&
                          item.construction.value && (
                            <>
                              {item.construction.value ===
                                "bias"
                                ? this.state.tbbTyreSize.map(
                                  (tbb, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={tbb}
                                    >
                                      {tbb}
                                    </option>
                                  )
                                )
                                : this.state.tbrTyreSize.map(
                                  (tbr, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={tbr}
                                    >
                                      {tbr}
                                    </option>
                                  )
                                )}
                            </>
                          )}
                        <option value="other">
                          Other
                        </option>
                      </select>
                      {!item.size.valid && <div className="error mt-1">Please select tyre size</div>}
                    </td>
                    <td className="business-related-detail-td">
                      <select
                        className="form-control font-size-14px"
                        name="brand"
                        value={item.brand.value}
                        style={{ minWidth: '90px' }}
                        disabled={this.props.is_view_only_dealils}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab,
                            item.brands
                          )
                        }
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Select
                        </option>
                        {item.brands.map((brand, j) => (
                          <option
                            className="text-capitalize"
                            value={brand.name}
                          >
                            {brand.name}
                          </option>
                        ))}
                        <option value="other">
                          Other
                        </option>
                      </select>
                      {!item.brand.valid && <div className="error mt-1">Please select brand</div>}
                    </td>
                    <td className="business-related-detail-td">
                      <select
                        className="form-control font-size-14px"
                        name="pattern"
                        value={item.pattern.value}
                        style={{ minWidth: '100px' }}
                        disabled={this.props.is_view_only_dealils}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab
                          )
                        }
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Select
                        </option>
                        {this.props.activeTab !== "lcv" && this.props.activeTab !== "scv" ? (
                          <>
                            {item.patterns.map(
                              (pattern, j) => (
                                <option
                                  className="text-capitalize"
                                  value={pattern.name}
                                >
                                  {pattern.name}
                                </option>
                              )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <option value="Other">
                          Other
                        </option>
                      </select>
                      {!item.pattern.valid && <div className="error mt-1">Please select pattern</div>}
                    </td>
                    <td className="business-related-detail-td">
                      <Input
                        type="text"
                        autocomplete="nofill"
                        name="tyres"
                        id="tyres"
                        value={item.tyres.value}
                        disabled={this.props.is_view_only_dealils}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab
                          )
                        }
                        style={{ minWidth: "77px" }}
                      // placeholder="Type here"
                      />
                      {!item.tyres.valid && <div className="error mt-1">Please enter tyres count.</div>}
                    </td>
                    <td>
                      {
                        this.props.is_view_only_dealils ? '--' :
                          <div className="d-flex">
                            <img
                              className="cursor-pointer ml-auto mr-auto"
                              onClick={() =>
                                this.removeFrontTyre(index, this.props.activeTab)
                              }
                              style={{ width: '13px' }}
                              src="images/delete.svg"
                              alt=''
                            />
                          </div>
                      }
                    </td>
                  </tr>
                )
              )
            })}
          </tbody>
        </table>

        {lodash.sumBy(frontRearData[this.props.activeTab], function (o) {
          return o.front_or_rear.value === "front_data"
            ? 1
            : 0;
        }) <= 0 && (
            <div className="no-data">
              {
                !this.props.is_view_only_dealils ?
                  <>
                    If you want to add {this.props.activeTab}  tyre details then
                    click on add more button.
                  </> : `There are no  ${this.props.activeTab} tyre details.`
              }
            </div>
          )}

        {
          !this.props.is_view_only_dealils &&
          <div className="d-flex mt-2">
            <img
              className="cursor-pointer"
              src="images/add_more_white_blue.png"
              alt=''
              style={{ height: "18px" }}
              onClick={() => this.props.addFrontTyreObject(this.props.activeTab)}
            />
            &nbsp;&nbsp;
            <span
              className="subsidiaries-name cursor-pointer add-more-tyre"
              onClick={() => this.props.addFrontTyreObject(this.props.activeTab)}
            >
              Add More
            </span>
          </div>
        }

        <br />
        <table
          className="mb-0 table-responsive mt-3 cip-tyre-releated-tbl d-block"
        >
          <thead>
            <tr style={{ backgroundColor: "#f6f6f6" }}>
              <th style={{ border: "1px solid #e9ecef", textAlign: 'center' }}>
                Tyre Position
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Construction
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Tyre Size
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Brand
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Pattern
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Tyres/Annum
              </th>
              <th style={{ border: "1px solid #e9ecef" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>

            {frontRearData[this.props.activeTab].map((item, index) => {
              return (
                item.front_or_rear.value ===
                "rear_data" && (
                  <tr>
                    <td className="font-size-14px">
                      <div className="d-flex">
                        <span className="mt-auto mb-auto" style={{ minWidth: '80px' }}>Rear</span>
                      </div>
                    </td>
                    <td className="business-related-detail-td">
                      <select
                        className="form-control font-size-14px"
                        name="construction"
                        disabled={this.props.is_view_only_dealils}
                        value={item.construction.value}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab,
                            [], 'rear_data'
                          )
                        }
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Select
                        </option>
                        <option value="bias">Bias</option>
                        <option value="radial">
                          Radial
                        </option>
                      </select>
                      {!item.construction.valid && <div className="error mt-1">Please select construction</div>}
                    </td>
                    <td className="business-related-detail-td">
                      <select
                        className="form-control font-size-14px"
                        name="size"
                        disabled={this.props.is_view_only_dealils}
                        value={item.size.value}
                        style={{ minWidth: '93px' }}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab,
                            [], 'rear_data'
                          )
                        }
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Select
                        </option>
                        {this.props.activeTab !== "lcv" &&
                          this.props.activeTab !== "scv" &&
                          item.construction.value && (
                            <>
                              {item.construction.value ===
                                "bias"
                                ? this.state.tbbTyreSize.map(
                                  (tbb, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={tbb}
                                    >
                                      {tbb}
                                    </option>
                                  )
                                )
                                : this.state.tbrTyreSize.map(
                                  (tbr, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={tbr}
                                    >
                                      {tbr}
                                    </option>
                                  )
                                )}
                            </>
                          )}
                        <option value="Other">
                          Other
                        </option>
                      </select>
                      {!item.size.valid && <div className="error mt-1">Please select tyre size</div>}
                    </td>
                    <td className="business-related-detail-td">
                      <select
                        className="form-control font-size-14px"
                        style={{ minWidth: '90px' }}
                        disabled={this.props.is_view_only_dealils}
                        name="brand"
                        value={item.brand.value}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab,
                            item.brands,
                            'rear_data'
                          )
                        }
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Select
                        </option>
                        {item.brands.map((brand, j) => (
                          <option
                            className="text-capitalize"
                            value={brand.name}
                          >
                            {brand.name}
                          </option>
                        ))}
                        <option value="other">
                          Other
                        </option>
                      </select>
                      {!item.brand.valid && <div className="error mt-1">Please select brand</div>}
                    </td>
                    <td className="business-related-detail-td">
                      <select
                        className="form-control font-size-14px"
                        name="pattern"
                        value={item.pattern.value}
                        style={{ minWidth: '100px' }}
                        disabled={this.props.is_view_only_dealils}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab,
                            [], 'rear_data'
                          )
                        }
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Select
                        </option>
                        {this.props.activeTab !== "lcv" && this.props.activeTab !== "scv" ? (
                          <>
                            {item.patterns.map(
                              (pattern, j) => (
                                <option
                                  className="text-capitalize"
                                  value={pattern.name}
                                >
                                  {pattern.name}
                                </option>
                              )
                            )}
                          </>
                        ) : (
                          <option value="Other">
                            Other
                          </option>
                        )}
                      </select>
                      {!item.pattern.valid && <div className="error mt-1">Please select pattren</div>}
                    </td>
                    <td className="business-related-detail-td">
                      <Input
                        type="text"
                        autocomplete="nofill"
                        name="tyres"
                        id="tyres"
                        disabled={this.props.is_view_only_dealils}
                        value={item.tyres.value}
                        onChange={(e) =>
                          this.onChangeFrontRearTyre(
                            e,
                            index,
                            this.props.activeTab,
                            [], 'rear_data'
                          )
                        }
                        style={{ minWidth: "77px" }}
                      // placeholder="Type here"
                      />
                      {!item.tyres.valid && <div className="error mt-1">Please enter tyres count.</div>}
                    </td>
                    <td>
                      {
                        this.props.is_view_only_dealils ? '--' :
                          <div className="d-flex">
                            <img
                              className="cursor-pointer ml-auto mr-auto"
                              onClick={() =>
                                this.props.removeRearTyre(index, this.props.activeTab)
                              }
                              style={{ width: '13px' }}
                              src="images/delete.svg"
                              alt=''
                            />
                          </div>
                      }
                    </td>
                  </tr>
                )
              )
            })}
          </tbody>
        </table>
        {lodash.sumBy(frontRearData[this.props.activeTab], function (o) {
          return o.front_or_rear.value === "rear_data"
            ? 1
            : 0;
        }) <= 0 && (
            <div className="no-data">
              {
                !this.props.is_view_only_dealils ?
                  <>
                    If you want to add {this.props.activeTab} tyre details then
                    click on add more button.
                  </> : `There are no  ${this.props.activeTab} tyre details.`
              }
            </div>
          )}

        {
          !this.props.is_view_only_dealils &&
          <div className="d-flex mt-2">
            <img
              className="cursor-pointer"
              src="images/add_more_white_blue.png"
              alt=''
              style={{ height: "18px" }}
              onClick={() => this.props.addRearTyreObject(this.props.activeTab)}
            />
            &nbsp;&nbsp;
            <span
              className="subsidiaries-name cursor-pointer add-more-tyre"
              onClick={() => this.props.addRearTyreObject(this.props.activeTab)}
            >
              Add More
            </span>
          </div>
        }
        {
          this.props.isNeedTyreRelated[this.props.activeTab] &&
          <div id="isNeedTyreRelated" className="error mt-2">Please enter front or rear tyre
          </div>
        }
      </>
    );
  }
}

export default CipTyreRelatedDetails;