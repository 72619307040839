import React, { Component } from 'react';
import {
  Button,
  FormGroup, Label,
  Input,
  Row, Col,
  Card, CardBody,
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { messages } from '../../constants/constant.message';

const {
  EMPTY_BUSINESS_NAME,
  EMPTY_OWNER_NAME,
  EMPTY_WHATSAPP_NO,
  INVALID_WHATSAPP_NO,
  EMPTY_STREET_NAME,
  EMPTY_STATE,
  EMPTY_CITY,
  INVALID_GST,
  INVALID_PAN,
  EMPTY_PAN_GST,
  EMPTY_PINCODE,
  INVALID_PINCODE
} = messages.PROFILE;

class EditSubDealerDetail extends Component {

  state = {
    isFirstTime: true
  }

  handleOnCancel = () => {
    this.props.onCancel('isSubDealerEditActive', false);
  }


  componentWillReceiveProps(props) {
    const { businessName, ownerName, wa } = props;
    if (this.state.isFirstTime === true) {
      if (businessName.autoFocus === true) {
        this.businessName.focus();
        this.setState({ isFirstTime: false });
        this.props.activeFragment('business');
      } else if (ownerName.autoFocus === true) {
        this.ownerName.focus();
        this.props.activeFragment('business');
        this.setState({ isFirstTime: false });
      } else if (wa.autoFocus === true) {
        this.wa.focus();
        this.props.activeFragment('business');
        this.setState({ isFirstTime: false });
      }

    }
  }


  render() {

    const { businessName, ownerName, wa, street,
      state, city, gst, pan, states, pincode,
      cities,
      // selectedCity, selectedState
    } = this.props;

    return <Card className={this.props.className}>
      <CardBody>
        {this.props.isLoading && <CustomSpinner />}
        <Row>
          <Col xl="12" md="12" xs="12">
            <b>ENTER YOUR DETAILS</b>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xl="4" md="12" xs="12">
            <FormGroup>
              <Label for="email">Business Name <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="businessName"
                id="businessName"
                innerRef={e => this.businessName = e}
                autoFocus={businessName.autoFocus}
                // className="required-input"
                // ref={(input) => { this.email = input }}
                value={businessName.value}
                onChange={this.props.handleInputChange}
                onFocus={this.props.activeFragment ? this.props.activeFragment.bind(this, 'business') : null}
                placeholder="Type here" />
              {businessName.showErrorMsg && < div className="error">* {EMPTY_BUSINESS_NAME}</div>}

              {/* {email.showErrorMsg && email.invalidEmail && <div className="error">* {INVALID_EMAIL}</div>}
              {email.showErrorMsg && email.nullValue && <div className="error">* {EMPTY_EMAIL}</div>} */}
            </FormGroup>
          </Col>
          <Col xl="4" md="12" xs="12">
            <FormGroup>
              <Label for="phone">Owner’s Name <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="ownerName"
                innerRef={e => this.ownerName = e}
                autoFocus={ownerName.autoFocus}
                // className="required-input"
                id="ownerName"
                value={ownerName.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {ownerName.showErrorMsg && < div className="error">* {EMPTY_OWNER_NAME}</div>}
              {/* {phone.showErrorMsg && phone.invalidPhone && <div className="error">* {INVALID_PHONE}</div>}
              {phone.showErrorMsg && phone.nullValue && <div className="error">* {EMPTY_PHONE}</div>} */}
            </FormGroup>

          </Col>
          <Col xl="4" md="12" xs="12">
            <FormGroup>
              <Label for="gst">Whatsapp Number <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="wa"
                id="wa"
                className="capital-case"
                value={wa.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {wa.showErrorMsg && wa.invalidPhone && <div className="error">* {INVALID_WHATSAPP_NO}</div>}
              {wa.showErrorMsg && wa.nullValue && <div className="error">* {EMPTY_WHATSAPP_NO}</div>}
            </FormGroup>

          </Col>
        </Row>
        <Row>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="email">Street Address <span className="required-field">*</span></Label>
              <Input
                type="textarea"
                name="street"
                id="street"
                innerRef={e => this.street = e}
                autoFocus={street.autoFocus}
                value={street.value}
                onChange={this.props.handleInputChange}
                onFocus={this.props.activeFragment ? this.props.activeFragment.bind(this, 'business') : null}
                placeholder="Type here" />
              {street.showErrorMsg && < div className="error">* {EMPTY_STREET_NAME}</div>}

              {/* {email.showErrorMsg && email.invalidEmail && <div className="error">* {INVALID_EMAIL}</div>}
              {email.showErrorMsg && email.nullValue && <div className="error">* {EMPTY_EMAIL}</div>} */}
            </FormGroup>
          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="gst">State  <span className="required-field">*</span></Label>
              <Typeahead
                id="state"
                selected={state.value}
                labelKey="name"
                options={states}
                onChange={this.props.handleSelectState}
                placeholder="Choose a state..."
              />
              {state.showErrorMsg && < div className="error">* {EMPTY_STATE}</div>}
              {/* <Input
                type="text"
                name="state"
                id="state"
                className="capital-case"
                value={state.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" /> */}
              {/* {gst.showErrorMsg && < div className="error">* {INVALID_GST}</div>} */}
            </FormGroup>

          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="phone">City <span className="required-field">*</span></Label>
              <Typeahead
                id="city"
                selected={city.value}
                labelKey="name"
                options={cities}
                onChange={this.props.handleSelectCity}
                placeholder="Choose a City..."
              />
              {city.showErrorMsg && < div className="error">* {EMPTY_CITY}</div>}
              {/* <Input
                type="text"
                name="city"
                innerRef={e => this.city = e}
                autoFocus={city.autoFocus}
                // className="required-input"
                id="city"
                value={city.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" /> */}
              {/* {phone.showErrorMsg && phone.invalidPhone && <div className="error">* {INVALID_PHONE}</div>}
              {phone.showErrorMsg && phone.nullValue && <div className="error">* {EMPTY_PHONE}</div>} */}
            </FormGroup>

          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="pincode">Pincode <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="pincode"
                id="pincode"
                className="capital-case"
                value={pincode.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {/* change message */}
              {pincode.showErrorMsg && (pincode.invalidLength || pincode.invalidNumber) && <div className="error">* {INVALID_PINCODE}</div>}
              {pincode.showErrorMsg && pincode.nullValue && <div className="error">* {EMPTY_PINCODE}</div>}
            </FormGroup>

          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>

          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="phone">PAN Number</Label>
              <Input
                type="text"
                name="pan"
                innerRef={e => this.pan = e}
                autoFocus={pan.autoFocus}
                className="capital-case"
                // className="required-input"
                id="pan"
                value={pan.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {pan.showErrorMsg && pan.invalidPAN && <div className="error">* {INVALID_PAN}</div>}
              {pan.showErrorMsg && pan.nullValue && <div className="error">* {EMPTY_PAN_GST}</div>}
            </FormGroup>
          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="gst">GST Number</Label>
              <Input
                type="text"
                name="gst"
                id="gst"
                className="capital-case"
                value={gst.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {gst.showErrorMsg && < div className="error">* {INVALID_GST}</div>}
            </FormGroup>

          </Col>
        </Row>
        {this.props.onSave && <Row>
          <Col xl="12" xs="12" md="12" className="text-align-right" >
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" onClick={this.handleOnCancel}>Cancel</Button>
            <Button
              type="submit"
              className="mb-1 mr-1 btn-pill action-button profile-save-button"
              color="warning"
              // disabled={!this.props.isFormValid}
              onClick={this.props.onSave}>Save</Button>
          </Col>
        </Row>}
      </CardBody>
    </Card >;
  }
}

export default EditSubDealerDetail;
