import React, { Component } from "react";
import {
  Button,
  Input,
  Row,
  Col,
} from "reactstrap";
import CustomerManagementService from "../../services/CustomerManagementService";
import "./BulkUploadCutomer.css";
import ModalService from "../../services/ModalService";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import swal from "sweetalert";
import { messages } from "../../constants/constant.message";
const { ERROR_MESSAGE } = messages.COMMON;

class BulkUploadCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileData: null,
      isLoading: false,
      groupData: [],
      controls: {
        group_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        group_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          customgroupname: true
        }
      },
      fileName: null,
      filEmpty: false,
      convertToTextBox: false,
      isValidForm: false,
      vehicleTypeData: [],
      vehicleMakeData: [],
      vehicleModelData: []
    };
  }

  componentDidMount() {
    this.getGroups();
  }

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    this.setState({
      controls
    });
  };

  selectfile = () => {
    if (this.fileInput) {
      this.fileInput.click();
    }
  };

  uploadFile = (event, type) => {
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      this.setState({
        fileName: event.target.files[0].name,
        fileData: event.target.files[0]
      });
      if (event.target.files[0].size > 6000000) {
        const { controls } = this.state;
        controls.document.value = "";
        event.target.value = null;
        ModalService.openAlert("", "Document size exceeds maximum limit is 5MB.", "error");
        return;
      }
    }
  };

  uploadFileFormData = async () => {
    let formData = new FormData(); //formdata object
    formData.append("file", this.state.fileData); //append the values with key, value pair
    formData.append("groupId", this.state.controls.group_id.value);
    if (!this.state.fileData) {
      const message = "Please select Bulk upload file .";
      ModalService.openAlert("", message, "error");
      return;
    }

    this.setState({ isLoading: true });
    await CustomerManagementService.uploadCustomerFile(formData)
      .then(res => {
        const { controls } = this.state;
        if (res && res.data) {
          this.setState({ controls, isLoading: false });
          swal({
            title: "Congratulations !",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => {
            this.props.onCloseSidebar();
          });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  getGroups = () => {
    this.setState({ isLoading: true });
    CustomerManagementService.getGroups({ page: 1, pageSize: 10000 })
      .then(res => {
        let { groupData } = this.state;
        if (
          res.data.data &&
          res.data.data.groups &&
          res.data.data.groups.length > 0
        ) {
          if (res.data.data.count !== 0) {
            this.setState({
              customgroupname: false
            });
          }
          groupData = res.data.data.groups;
        } else {
          groupData = [];
        }
        this.setState({
          groupData,
          isLoading: false
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  onCloseSidebar = () => {
    this.props.onCloseSidebar();
  };

  //  ========================AddGroup======================

  addGroup = () => {
    let { controls } = this.state;
    const reqData = {
      name: controls.group_name.value
    };

    if (!this.state.controls.group_name.value) {
      this.uploadFileFormData();
      return;
    }

    this.setState({ isLoading: true });
    CustomerManagementService.addGroup(reqData)
      .then(res => {
        CustomerManagementService.getGroups({ page: 1, pageSize: 10000 })
          .then(res => {
            let { groupData } = this.state;
            if (
              res.data.data &&
              res.data.data.groups &&
              res.data.data.groups.length > 0
            ) {
              controls["group_id"].value = res.data.data.groups[0].group_id;
              this.setState(
                {
                  controls,
                  isLoading: false
                },
                () => {
                  this.uploadFileFormData();
                }
              );
            } else {
              groupData = [];
            }
            this.setState({
              groupData,
              isLoading: false
            });
          })
          .catch(e => {
            this.setState({ isLoading: false });
            const err =
              e.response && e.response.data && e.response.data.error
                ? e.response.data.error[0]
                : null;
            const message = err && err.message ? err.message : ERROR_MESSAGE;
            ModalService.openAlert("", message, "error");
          });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  render() {
    let { controls, fileName, customgroupname } = this.state;

    return (
      <div className="height-100">
        {this.state.isLoading && <CustomSpinner />}
        <div className="drawer-header">
          <span className="drawer-title">BULK UPLOAD CUSTOMERS</span>
        </div>
        <hr style={{ border: "solid 1px #ced4da" }} />
        <div style={{ height: "100%" }} id="bulkUpload">
          <div className="fix-height">
            <Row style={{ marginLeft: "10px" }}>
              <span className="custom-dot"></span>{" "}
              <Col>
                To upload bulk contacts, create an Excel file (with .xlsx or
                .csv extension) as shown in the below preview.
              </Col>
            </Row>
            <div style={{ marginTop: "10px" }} />
            <Row style={{ marginLeft: "10px" }}>
              <span className="custom-dot"></span>{" "}
              <Col>
                Keep the column names in the same order as shown in below
                preview.
              </Col>
            </Row>
            <div style={{ marginTop: "10px" }} />
            <Row style={{ marginLeft: "10px" }}>
              <span className="custom-dot"></span>{" "}
              <Col>
                If you want to add customers only in your customer list then you
                do not need to select any group.
              </Col>
            </Row>
            <div style={{ marginTop: "10px" }} />
            <Row style={{ marginLeft: "10px" }}>
              <span className="custom-dot"></span>{" "}
              <Col>
                If you want to add the customers in a precise group, then select
                the group name. In case the group is not created, first create
                group from "Manage Group" option .
              </Col>
            </Row>
            <div style={{ marginTop: "10px" }} />
            <Row style={{ marginLeft: "10px" }}>
              <span className="custom-dot"></span>{" "}
              <Col>
                For Birthdays and Anniversaries, kindly follow the format of
                DD-MM-YYYY .
              </Col>
            </Row>
            <div style={{ marginTop: "10px" }} />
            <Row style={{ marginLeft: "10px" }}>
              <span className="custom-dot"></span>{" "}
              <Col>
                In case any data is missing, kindly leave the cell blank (Only
                Mobile Number is mandatory) .
              </Col>
            </Row>

            <Col style={{ marginTop: "10px", marginBottom: "40px" }}>
              {" "}
              <div className="f-flex-row" style={{ float: "right" }}>
                <a
                  href="https://images.jktyrecrm.in/others/bulk-upload.xlsx"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  style={{ color: "#5665d8" }}
                >
                  <img
                    src="images/download.svg"
                    alt=''
                    className="download-report-icon cursor-pointer margin-left-10"
                  />
                </a>
                &nbsp;&nbsp;
                <span className="link-warm-blue cursor-pointer">
                  <a
                    href="https://images.jktyrecrm.in/others/BulkUpload.xlsx"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    style={{ color: "#5665d8" }}
                  >
                    Download Sample Excel File
                  </a>
                </span>
              </div>
            </Col>

            <Col style={{}}>
              {" "}
              <div>
                <img
                  className="static-image"
                  src="https://images.jktyrecrm.in/others/bulk-snapshot.png"
                  alt=''
                />
              </div>
            </Col>
            <Col style={{ marginTop: "15px" }}>
              <Row>
                <Col xl="8" xs="8" md="8">
                  Select File
                </Col>
                <Col xl="4" xs="4" md="4">
                  {customgroupname === false
                    ? "Select Group"
                    : "Enter Group Name"}
                </Col>
              </Row>
              {this.state.filEmpty && (
                <div className="No-file-chosen">No file chosen</div>
              )}
              <Row>
                <Col xl="8" xs="8" md="8">
                  <Input
                    type="text"
                    className="textarea-of-upload-file"
                  ></Input>
                  <span className="background-image">
                    <img src="images/rectangle-copy-8.png" alt='' />
                  </span>
                  <Row onClick={() => this.selectfile()}>
                    <Row className="upload-image">
                      <div style={{ marginRight: "9px" }}>
                        <img src="images/group-7.png" alt='' />
                      </div>
                      <div className="font-style">Choose file</div>
                    </Row>
                    {fileName ? (
                      <div className="No-file-chosen">{fileName}</div>
                    ) : (
                      <div className="No-file-chosen">No file chosen</div>
                    )}
                  </Row>
                  <input
                    type="file"
                    name="document"
                    ref={ref => (this.fileInput = ref)}
                    onChange={e => this.uploadFile(e, "document")}
                    style={{ display: "none" }}
                  />
                </Col>
                {customgroupname === false ? (
                  <Col xl="4" xs="4" md="4">
                    <select
                      className="select-group"
                      name="group_id"
                      value={controls.group_id.value}
                      onChange={e => {
                        this.handleInputChange(e);
                      }}
                    >
                      <option value="" disabled>
                        All
                      </option>
                      {this.state.groupData.map(item => (
                        <option value={item.group_id} key={item.group_id}>
                          {item.group_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                ) : (
                  <Col xl="4" xs="4" md="4">
                    <Input
                      className="select-group"
                      type="text"
                      name="group_name"
                      placeholder="Enter group name"
                      value={controls.group_name.value}
                      onChange={e => {
                        this.handleInputChange(e);
                      }}
                    />
                  </Col>
                )}
              </Row>
              {customgroupname === false && (
                <div
                  className="f-flex-row"
                  style={{ float: "right", marginTop: "5px" }}
                >
                  <img
                    src="images/plus_blue.svg"
                    alt=''
                    onClick={() => this.setState({ customgroupname: true })}
                    className="download-report-icon cursor-pointer margin-left-10"
                  />
                  &nbsp;&nbsp;
                  <span
                    className="link-warm-blue cursor-pointer"
                    onClick={() => this.setState({ customgroupname: true })}
                  >
                    Add Group
                  </span>
                </div>
              )}
            </Col>
          </div>

          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col
              xl="12"
              xs="12"
              md="12"
              className="text-align-right footer-drawer"
            >
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                onClick={() => this.onCloseSidebar()}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-save-button"
                color="warning"
                onClick={
                  customgroupname === false
                    ? this.uploadFileFormData
                    : this.addGroup
                }
              >
                Upload
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default BulkUploadCustomer;
