import React, { Fragment } from 'react';
import MerchandiseReportComponent from '../../components/MerchandiseReport/MerchandiseReport';

const MerchandiseOrders = (props) => (
  <Fragment>
    <div className="page-header">Merchandise Order</div>
    <MerchandiseReportComponent {...props} />
  </Fragment>
);

export default MerchandiseOrders;