import React, { Component, Fragment } from "react";
import { Row, Col, Label, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import swal from "sweetalert";
import WarrantyRegistrationService from "../../../services/WarrantyRegistrationService";
import { config } from "../../../constants/constant.config";
import { messages } from "../../../constants/constant.message";

class VehicleType extends Component {
  vehicleTypeData = [];

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      controls: (props.vehicleDetails && props.vehicleDetails.controls) || {},
      productDetails: (props.productDetails && props.productDetails) || {},
      isValidForm: props.vehicleDetails && props.vehicleDetails.isValidForm,
      isEditMode: props.isEditMode,
      type:
        props.vehicleDetails &&
        props.vehicleDetails.controls &&
        props.vehicleDetails.controls.vehicleTypeId &&
        props.vehicleDetails.controls.vehicleTypeId.value &&
        props.vehicleDetails.controls.vehicleTypeId.value.name,
      registeredVehicleDetails: props.registeredVehicleDetails || [],
      type_coupon: props.type
    };
    this.getVehicleType(); // get vehicle type data
  }

  componentDidMount() {
    this.isMobile = WarrantyRegistrationService.checkUserAgent();
    this.setState(prevState => {
      return { ...prevState };
    });
  }

  async getVehicleType() {
    try {
      const { data } = await WarrantyRegistrationService.getVehicleType();

      if (data) {
        this.vehicleTypeData = data.data;
        let { controls } = this.state;
        if (this.vehicleTypeData && this.vehicleTypeData.length > 0) {
          this.vehicleTypeData.filter(x =>
            x.vehicle_type_id == controls.vehicleTypeId.value.vehicle_type_id
              ? (x.isActive = true)
              : (x.isActive = false)
          );
        }

        // when coupon type there and need to select vehicle type
        if (this.state.type_coupon) {
          let index = this.vehicleTypeData.findIndex(x => x.type === this.state.type_coupon);
          this.updateType(this.vehicleTypeData[index], index);
        }

      }

      this.setState(prevState => {
        return { ...prevState };
      });
    } catch (error) {
      console.log(error);
    }
  }

  setValues(element) {
    const { controls, productDetails } = this.state;
    const {
      state,
      city,
      vehicleId,
      vehicleTypeId,
      vehicleTypeBrandId,
      vehicleTypeBrandModelId,
      registrationNumber,
      registrationYear,
      vehicleAvgMonthlyRunning,
      vehicleCategory
    } = controls;
    state.value = {
      state_id: element.state_id || "",
      name: element.state_name || ""
    };
    city.value = {
      city_id: element.city_id || "",
      name: element.city_name || ""
    };
    vehicleId.value = element.vehicle_id;
    vehicleTypeId.value = {
      vehicle_type_id: element.vehicle_type_id || "",
      name: element.type || ""
    };
    vehicleTypeBrandId.value = {
      brand_id: element.vehicle_type_brand_id || "",
      name: element.brand_name || ""
    };
    vehicleTypeBrandModelId.value = {
      model_id: element.vehicle_type_brand_model_id || "",
      name: element.model_name || ""
    };
    // vehicleTypeBrandModelVariationId.value = {
    //   variation_id: element.vehicle_type_brand_model_variation_id || '',
    //   name: element.variation_name || ''
    // };
    registrationNumber.value = element.registration_number || "";
    registrationYear.value = element.registration_year || "";
    vehicleAvgMonthlyRunning.value = element.vehical_avg_monthly_running || "";
    vehicleCategory.value = element.vehicle_category || "";

    // reset product details
    productDetails.controls.tyreQuantity.value = "";
    productDetails.controls.skuId1.value = "";
    productDetails.controls.skuIdBack.value = "";
    productDetails.controls.skuIdSpare.value = "";
    productDetails.controls.tyreDescription.value = "";

    productDetails.controls.stencilNumber0.value = {
      stencil_number: "",
      name: "stencilNumber0"
    };
    productDetails.controls.stencilNumber1.value = {
      stencil_number: "",
      name: "stencilNumber1"
    };
    productDetails.controls.stencilNumber2.value = {
      stencil_number: "",
      name: "stencilNumber2"
    };
    productDetails.controls.stencilNumber3.value = {
      stencil_number: "",
      name: "stencilNumber3"
    };
    productDetails.controls.stencilNumber4.value = {
      stencil_number: "",
      name: "stencilNumber4"
    };
    productDetails.controls.stencilNumber5.value = {
      stencil_number: "",
      name: "stencilNumber5"
    };

    this.setState({ controls, productDetails });
    return controls;
  }

  goBack = () => {
    const { controls, isValidForm } = this.state;
    this.props.updateState({
      visibleArea: "customerDetails",
      vehicleDetails: { controls, isValidForm }
    });
  };

  registeredVehicleClick(element = {}) {
    if ((element.type || "").toLowerCase() === "car") {
      if (
        element.daily_tyre >= config.WARRANTY_REGISTRATION.CAR.DAILYTYRELIMIT
      ) {
        swal(
          "Sorry !",
          messages.WARRANTY_REGISTRATION.DAILYLIMITCAR,
          "warning"
        );
        return;
      }

      if (
        element.last_6_month_tyre >=
        config.WARRANTY_REGISTRATION.CAR.LAST6MONTHTYRELIMIT
      ) {
        swal(
          "Sorry !",
          messages.WARRANTY_REGISTRATION.LAST6MONTHLIMITCAR,
          "warning"
        );
        return;
      }
    } else if ((element.type || "").toLowerCase() !== "car") {
      if (
        element.daily_tyre >=
        config.WARRANTY_REGISTRATION.TWOWHEELER.DAILYTYRELIMIT
      ) {
        swal(
          "Sorry !",
          messages.WARRANTY_REGISTRATION.DAILYLIMITTWOWHEELER,
          "warning"
        );
        return;
      }

      if (
        element.last_6_month_tyre >=
        config.WARRANTY_REGISTRATION.TWOWHEELER.LAST6MONTHTYRELIMIT
      ) {
        swal(
          "Sorry !",
          messages.WARRANTY_REGISTRATION.LAST6MONTHLIMITTWOWHEELER,
          "warning"
        );
        return;
      }
    }
    const controls = this.setValues(element);
    this.props.updateState({
      visibleArea: "productDetailsEdit",
      vehicleDetails: { controls, isValidForm: true },
      isEditMode: true,
      isExisting: true
    });
  }

  handleInputChangeVehicleCategory = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (!isNaN(controlValue) || controlName === "vehicleCategory") {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }

    this.setState({ controls });
  };

  updateType = (type, i) => {
    this.vehicleTypeData.filter(x => (x.isActive = false));
    this.vehicleTypeData[i].isActive = true;
    this.setState({
      type
    });

    const controls = this.setValues({});
    controls.vehicleTypeId.value = type;

    if (type.type === 'lcv' || type.type === 'scv') {
      controls.vehicleCategory.value = "cargo";
      controls.vehicleCategory.touched = true;
    }
    this.setState({ controls });
  };

  vehicleTypeClick(type) {
    let { isValidForm, controls } = this.state;

    if (!this.state.type) {
      swal("Sorry !", "Please Select Vehicle Type.", "warning");
      return;
    }
    if (!this.state.controls.vehicleCategory.value) {
      swal("Sorry !", "Please Select Vehicle Category.", "warning");
      return;
    }

    this.props.updateState({
      visibleArea: "vehicleMake",
      vehicleDetails: { controls, isValidForm },
      isEditMode: false,
      isExisting: false
    });
    this.setState({ controls });
  }

  render() {
    const {
      // registeredVehicleDetails, 
      controls, type_coupon, type } = this.state;
    const { vehicleCategory, vehicleTypeId } = controls;
    return (
      <div>
        <Row className="warranty-customer-details-label">
          <Col
            xl="10"
            xs="10"
            ms="10"
            className={classnames({ "text-align-center": this.isMobile })}
            style={{ display: "flex" }}
          >
            <FontAwesomeIcon
              onClick={this.goBack}
              icon={faAngleLeft}
              style={{ fontSize: "20px", cursor: "pointer" }}
              color="#fdbb1f"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ marginTop: "2px" }}>TYPE OF VEHICLE</span>
          </Col>
        </Row>
        <div>
          <hr />
          <Row>
            <Col>
              {/* {registeredVehicleDetails && registeredVehicleDetails.length ? (
                <Fragment>
                  <span className="input-label">
                    Select from existing vehicle
                  </span>
                  <br />

                  <Row style={{ marginTop: "15px", marginLeft: "3px" }}>
                    {registeredVehicleDetails.map(element => {
                      return (
                        <Col
                          xl="3"
                          xs="12"
                          sm="12"
                          md="3"
                          style={{ paddingRight: "10px", paddingLeft: "2px" }}
                          key={element.registration_number}
                        >
                          <button
                            // onClick={() => this.registeredVehicleClick(element)}
                            type="button"
                            className={classnames(
                              { "w-100 mb-3": this.isMobile },
                              "mb-2 mr-2 btn-icon btn-pill btn btn-outline-secondary custom-secondary w-100"
                            )}
                          >
                            {element.registration_number}
                          </button>
                        </Col>
                      );
                    })}
                  </Row>

                  <div style={{ marginTop: "15px", display: "flex" }}>
                    <span className="input-label">OR</span>
                    <span className="horizontal-OR-line" />
                  </div>
                </Fragment>
              ) : (
                ""
              )} */}
              <div style={{ marginTop: "15px" }}>
                <span className="input-label">Select Vehicle Type <span className="required-star"> *</span></span>
                <div
                  className="d-flex-row"
                  style={{ flexWrap: "wrap", marginTop: "15px" }}
                >
                  {(this.vehicleTypeData || []).map((type, i) => {
                    return (
                      <button
                        onClick={() => this.updateType(type, i)}
                        type="button"
                        disabled={type_coupon && type_coupon !== null && type_coupon !== '' && type_coupon !== (type.name).toLowerCase() ? true : null}
                        key={type.name}
                        className={
                          type.isActive
                            ? "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column custom-secondary-selected"
                            : "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column"
                        }
                        style={
                          (type.name || "").toLowerCase() === "car"
                            ? {
                              width: "130px",
                              height: "130px",
                              borderRadius: "25px",
                              justifyContent: "space-between",
                              display: "flex",
                              paddingTop: "20px"
                            }
                            : {
                              width: "130px",
                              height: "130px",
                              borderRadius: "25px",
                              justifyContent: "space-between",
                              display: "flex",
                              paddingTop: "20px",
                              paddingBottom: "14px"
                            }
                        }
                      >
                        <span
                          className="input-label"
                          style={{
                            color: "#6e777f",
                            marginLeft: "auto",
                            marginRight: "auto"
                          }}
                        >
                          {type.name}
                        </span>
                        <br />
                        <img
                          src={type.image_url}
                          alt={type.name}
                          className={
                            (type.name || "").toLowerCase() === "car"
                              ? "vehicle-type-image-car"
                              : "vehicle-type-image-bike"
                          }
                        />
                      </button>
                    );
                  })}
                </div>
                <>
                  <br />
                  {vehicleTypeId.value.type && <div>
                    <Label for="vehicleCategory" className="input-label">
                      Please select your customer’s type of ownership of the
                      vehicle
                    </Label>
                  </div>}
                  {vehicleTypeId.value.type && (vehicleTypeId.value.type !== 'lcv' && vehicleTypeId.value.type !== 'scv') && (<Row style={{ marginTop: "2px" }}>
                    <Col xl="3" xs="12" ms="12" className="mt-1">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="personal"
                            value="personal"
                            checked={
                              controls.vehicleCategory.value === "personal"
                            }
                            onChange={e =>
                              this.handleInputChangeVehicleCategory({
                                target: {
                                  name: "vehicleCategory",
                                  value: "personal"
                                }
                              })
                            }
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>Personal</span>
                      </div>
                    </Col>

                    <Col xl="6" xs="12" ms="12" className="mt-1">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="taxi"
                            value="taxi"
                            checked={vehicleCategory.value === "taxi"}
                            onChange={e =>
                              this.handleInputChangeVehicleCategory({
                                target: {
                                  name: "vehicleCategory",
                                  value: "taxi"
                                }
                              })
                            }
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>
                          Taxi{" "}
                          {(vehicleTypeId.value.name || "").toLowerCase() ===
                            "car"
                            ? "(Uber, OLA, Private taxi, etc.)"
                            : "(Zomato, OLA, Uber, etc.)"}
                        </span>
                      </div>
                    </Col>
                    <Col xl="6" xs="2" ms="2" />
                  </Row>)}

                  {vehicleTypeId.value.type && (vehicleTypeId.value.type === 'lcv' || vehicleTypeId.value.type === 'scv') && (<Row style={{ marginTop: "2px" }}>
                    <Col xl="3" xs="12" ms="12" className="mt-1">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="cargo"
                            value="cargo"
                            checked={vehicleCategory.value === "cargo"}
                            onChange={e =>
                              this.handleInputChangeVehicleCategory({
                                target: {
                                  name: "vehicleCategory",
                                  value: "cargo"
                                }
                              })
                            }
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>
                          Cargo
                        </span>
                      </div>
                    </Col>

                    <Col xl="6" xs="12" ms="12" className="mt-1">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="passenger"
                            value="passenger"
                            checked={
                              controls.vehicleCategory.value === "passenger"
                            }
                            onChange={e =>
                              this.handleInputChangeVehicleCategory({
                                target: {
                                  name: "vehicleCategory",
                                  value: "passenger"
                                }
                              })
                            }
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>Passenger</span>
                      </div>
                    </Col>
                    <Col xl="6" xs="2" ms="2" />
                  </Row>)}
                  {/* {vehicleTypeId.value.type && vehicleTypeId.value.type === 'scv' && (<Row style={{ marginTop: "2px" }}>
                    <Col xl="3" xs="12" ms="12" className="mt-1">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="passenger"
                            value="passenger"
                            checked={
                              controls.vehicleCategory.value === "passenger"
                            }
                            onChange={e =>
                              this.handleInputChangeVehicleCategory({
                                target: {
                                  name: "vehicleCategory",
                                  value: "passenger"
                                }
                              })
                            }
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>Passenger</span>
                      </div>
                    </Col>

                    <Col xl="6" xs="12" ms="12" className="mt-1">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="cargo"
                            value="cargo"
                            checked={vehicleCategory.value === "cargo"}
                            onChange={e =>
                              this.handleInputChangeVehicleCategory({
                                target: {
                                  name: "vehicleCategory",
                                  value: "cargo"
                                }
                              })
                            }
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>
                          Cargo
                        </span>
                      </div>
                    </Col>
                    <Col xl="6" xs="2" ms="2" />
                  </Row>)} */}
                </>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <Button
          className="mb-1 mr-1 btn-pill action-button font-weight-bold"
          color="warning"
          onClick={() => this.vehicleTypeClick(type)}
          style={{ width: "116px" }}
        >
          Next
        </Button>
      </div>
    );
  }
}

export default VehicleType;
