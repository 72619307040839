import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import Ionicon from "react-ionicons";
import DatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import MarketingCouponService from '../../services/MarketingCouponService';
import { downlodFile } from "../../utils/utils";

const { ERROR_MESSAGE } = messages.COMMON;
class CNReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        program_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        offer_status: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        paid_by_jk: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        format: {
          value: 'dealer',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        start_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        end_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        phone_number: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
      },
      customerVehicleData: [],
      programData: []
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleRadioChangeReportFormat = this.handleRadioChangeReportFormat.bind(this);
  }

  handleRadioChange(e) {
    let { controls } = this.state;
    controls.offer_status.value = e;
    this.setState({ controls });
  };

  handleRadioChangePaid(e) {
    let { controls } = this.state;
    controls.paid_by_jk.value = e;
    this.setState({ controls });
  };

  handleRadioChangeReportFormat(e) {
    let { controls } = this.state;
    controls.format.value = e;
    this.setState({ controls });
  };

  componentDidMount() {
    this.getProgram();
  };

  getProgram = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getProgram({ search_query: '' })
      .then(res => {

        let { programData } = this.state;
        if (res.data.data && res.data.data && res.data.data.length > 0) {
          programData = res.data.data;
        } else {
          programData = [];
        }
        this.setState({
          programData,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  onChangeDate = (e, controlName) => {
    const { controls } = this.state;
    controls[controlName].value = e;
    controls[controlName].touched = true;
    this.setState({ controls });
  };

  handleOnCancel = () => {
    this.props.onCloseSidebar(false);
  };

  downloadReport = () => {
    if (!this.handleValidation(true)) {
      return;
    }

    let { controls } = this.state;
    let start_date, end_date;

    if (controls.start_date.value) {
      start_date = new Date(controls.start_date.value);
      start_date.setHours(0);
      start_date.setMinutes(0);
    }
    if (controls.end_date.value) {
      end_date = new Date(controls.end_date.value);
      end_date.setHours(23);
      end_date.setMinutes(59);
    }

    let reqData = {
      program_name: controls.program_name.value,
      offer_status: controls.offer_status.value ? controls.offer_status.value : null,
      format: controls.format.value ? controls.format.value : null,
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null,
      paid_by_jk: controls.paid_by_jk.value ? controls.paid_by_jk.value : null
    };

    this.setState({
      isLoading: true
    });
    MarketingCouponService.CNReport(reqData)
      .then(res => {
        downlodFile(res.data.data.file);
        this.setState({
          isLoading: false
        });
        this.handleOnCancel();
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        this.handleOnCancel();
      });
  }

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { program_name } = controls;

    if (program_name.value === '') {
      program_name.showErrorMsg = true;
      isFormValid = false;
    } else {
      program_name.showErrorMsg = false;
      isFormValid = true;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  render() {
    const {
      programData,
      controls
    } = this.state;
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <>
          <div className="drawer-header">
            <span className="drawer-title" style={{ marginTop: "5px" }}>
              Download Coupon Redemption Report
            </span>
            <Ionicon
              className="cursor-pointer"
              icon="md-close"
              fontSize="24px"
              color="#fdbb1f"
              onClick={() => this.handleOnCancel()}
            />
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
          <div className="custom-drawer-content-wrapperr Fix-height" style={{ paddingLeft: "10px" }}>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label for="common">Offer Status &nbsp;<span className="required-field">*</span></Label>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="offer_status"
                        value=""
                        onChange={() => {
                          this.handleRadioChange("");
                        }}
                        checked={controls.offer_status.value === ""} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">All</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp" style={{ paddingRight: "0" }}>
                    <label className="custom-checkboax-radio--container">
                      <input type="radio"
                        name="offer_status"
                        value="on_going"
                        onChange={() => {
                          this.handleRadioChange("on_going");
                        }}
                        checked={controls.offer_status.value === "on_going"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">On going</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="offer_status"
                        value="closed"
                        onChange={() => {
                          this.handleRadioChange("closed");
                        }}
                        checked={controls.offer_status.value === "closed"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">Closed</span>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label for="common">Coupon Amount Paid</Label>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="paid_by_jk"
                        value=""
                        onChange={() => {
                          this.handleRadioChangePaid("");
                        }}
                        checked={controls.paid_by_jk.value === ""} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">All</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input type="radio"
                        name="paid_by_jk"
                        value="JK Tyre"
                        onChange={() => {
                          this.handleRadioChangePaid("JK Tyre");
                        }}
                        checked={controls.paid_by_jk.value === "JK Tyre"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">JK Tyre</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="paid_by_jk"
                        value="Dealer"
                        onChange={() => {
                          this.handleRadioChangePaid("Dealer");
                        }}
                        checked={controls.paid_by_jk.value === "Dealer"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">Dealer</span>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label for="common">Select Report Format</Label>
              </Col>
              <Col xl="4" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="format"
                        value="dealer"
                        onChange={() => {
                          this.handleRadioChangeReportFormat("dealer");
                        }}
                        checked={controls.format.value === "dealer"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">Dealer Wise</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input type="radio"
                        name="format"
                        value="coupon"
                        onChange={() => {
                          this.handleRadioChangeReportFormat("coupon");
                        }}
                        checked={controls.format.value === "coupon"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">Coupon Code Wise</span>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="11" md="12" xs="12" style={{ paddingRight: "0px" }}>
                <FormGroup>
                  <Label for="common">Select Program &nbsp;<span className="required-field">*</span></Label>
                  <select className="form-control form-group-select select select-height-70rem"
                    name="program_name"
                    value={controls.program_name.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option className="label-text" value="">Select</option>
                    {
                      programData.map((item) => (
                        <option className="label-text" value={item.program_name}>{item.program_name}</option>
                      ))
                    }
                  </select>
                  {controls.program_name.showErrorMsg && (
                    <div className="error">
                      * Please choose program name.
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label for="common" className='cn-title'>Select Redeem Date range</Label>
              </Col>
              <Col xl="6 " md="12" xs="12">
                <FormGroup>
                  <Label for="common">Start Date</Label>

                  <Label
                    onClick={e =>
                      this.calendar1.state.open && e.preventDefault()
                    }
                  >
                    <InputGroup>
                      <DatePicker
                        placeholderText="Select"
                        className={"form-control"}
                        onChange={e => this.onChangeDate(e, "start_date")}
                        selected={controls.start_date.value}
                        name="start_date"
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        ref={r => (this.calendar1 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        maxDate={controls.end_date.value ? controls.end_date.value : new Date()}
                        popperPlacement="botom-start"
                        popperModifiers={{
                          flip: {
                            enabled: false,
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false
                          }
                        }}
                        autoComplete="nofill"
                        dropdownMode="select"
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12" style={{ paddingLeft: "0px" }}>
                <FormGroup>
                  <Label for="common">End Date</Label>
                  <Label
                    className="add-customer-date"
                    onClick={e =>
                      this.calendar2.state.open && e.preventDefault()
                    }
                  >
                    <InputGroup>
                      <DatePicker
                        placeholderText="Select"
                        className={"form-control"}
                        onChange={e =>
                          this.onChangeDate(e, "end_date")
                        }
                        name="end_date"
                        maxDate={new Date()}
                        selected={controls.end_date.value}
                        dateFormat="dd/MM/yyyy"
                        autoComplete="nofill"
                        showMonthDropdown
                        ref={r => (this.calendar2 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        minDate={controls.start_date.value ? controls.start_date.value : null}
                        dropdownMode="select"
                        popperPlacement="botom-start"
                        popperModifiers={{
                          flip: {
                            enabled: false
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false
                          }
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                </FormGroup>
              </Col>

            </Row>
          </div>

          <Row
            style={{
              marginLeft: "0",
              marginRight: "0",
              height: "100%",
              maxHeight: "80px",
              position: "relative",
              bottom: "0",
              width: "100%"
            }}
          >
            <Col
              xl="12"
              xs="12"
              md="12"
              className="text-align-right footer-drawer"
            >
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                color="dark"
                onClick={() => this.handleOnCancel()} style={{ width: '116px' }}>
                Cancel
              </Button>&nbsp;&nbsp;

              <Button
                className="mb-1 mr-1 btn-pill action-button profile-save-button"
                color="warning"
                onClick={() => {
                  this.downloadReport();
                }}
              >
                Download
              </Button>
            </Col>
          </Row>
        </>
      </>
    );
  }
}

export default CNReport;
