import React, { Component } from "react";
import ProposalSerivice from "../../services/ProposalSerivice";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DatePicker from "react-datepicker";
import swal from "sweetalert";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import Moment from "react-moment";
import TextareaAutosize from "react-textarea-autosize";
import { messages } from "../../constants/constant.message";
class HandOver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      proposalStageDetail: props.proposalStageDetail,
      controls: {
        remark: "",
        visit_date: null,
        document: "",
      },
      controls_inauguration: {
        inauguration_date: null,
        isUpdate: false,
      },
      isLoading: false,
      userDetail: Storage.getUserDetail(),
    };
  }

  setPreInauguration_date = () => {
    let { controls_inauguration } = this.state;
    if (
      this.props.proposalStageDetail &&
      this.props.proposalStageDetail.proposal_status &&
      this.props.proposalStageDetail.proposal_status.length > 0
    ) {
      controls_inauguration.inauguration_date = this.props.proposalStageDetail
        .proposal_status[0].inauguration_date
        ? new Date(
          this.props.proposalStageDetail.proposal_status[0].inauguration_date
        )
        : null;
      if (controls_inauguration.inauguration_date) {
        controls_inauguration.isUpdate = true;
      }
      this.setState({ controls_inauguration });
    }
  };
  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { controls } = this.state;
      if (data && data.data) {
        controls.document = data.data;
      }
      this.setState({ controls, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (!this.checkActiveStatus()) {
      return;
    }
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        const { controls } = this.state;
        controls.document = "";
        event.target.value = null;
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB.",
          "error"
        );
        return;
      }
      this.uploadFileFormData(file, type);
    }
  };
  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };
  submitInaugration = () => {
    if (!this.checkActiveStatus()) {
      return;
    }
    let { controls_inauguration } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }

    const obj = controls_inauguration;
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.saveInaugration(obj, controls_inauguration.isUpdate)
      .then((res) => {
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message = "Hand over added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message = "Hand over updated successfully";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.getProposalStageDetail();
        });
        this.setState({ isLoading: false, controls_inauguration });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };
  submit = () => {
    if (!this.checkActiveStatus()) {
      return;
    }
    let { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    if (!controls.visit_date) {
      return;
    }
    let obj = {
      remark: controls.remark,
      visit_date: controls.visit_date,
      document: "",
    };
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.saveHandOver(obj)
      .then((res) => {
        controls = {
          remark: "",
          visit_date: null,
          document: "",
        };
        if (this.fileInput1) {
          this.fileInput1.value = "";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.initApi();
        });
        this.setState({ isLoading: false, controls });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  onChangeDate = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const { controls } = this.state;
    controls["visit_date"] = e;
    this.setState({ controls });
  };
  onChangeInauguration_date = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const { controls_inauguration } = this.state;
    controls_inauguration["inauguration_date"] = e;
    this.setState({ controls_inauguration });
  };

  handleInputCheckboxChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName] = !controls[controlName];
    this.setState({ controls });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName] = e.target.value;
    this.setState({ controls });
  };

  render() {
    const { controls, userDetail } = this.state;
    let { proposalStageDetail } = this.props;
    let minDate = null;
    let agencywork = proposalStageDetail.stages_details.filter(
      (x) => x.stage && x.stage === "Agency work in progress"
    );
    if (agencywork.length > 0) {
      let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
      minDate = new Date(
        agencywork[0].completed_date.replace(pattern, "$3-$2-$1")
      );
    }
    return (
      <>
        <br />
        {this.state.isLoading && <CustomSpinner />}
        <Card>
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Hand Over</b>
              </Col>
            </Row>
            <hr />
            {this.state.userDetail.access_level !== "area" &&
              this.state.userDetail.access_level !== "region" &&
              this.state.userDetail.access_level !== "zone" ? (
              <>
                <Row>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label>
                        Hand Over Date
                        <span className="required-field">*</span>
                      </Label>
                      <Label
                        onClick={(e) =>
                          this.calendar1.state.open && e.preventDefault()
                        }
                      >
                        <InputGroup>
                          <DatePicker
                            className={"form-control"}
                            onChange={(e) => this.onChangeDate(e)}
                            name="visit_date"
                            selected={controls.visit_date}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            ref={(r) => (this.calendar1 = r)}
                            shouldCloseOnSelect={true}
                            showYearDropdown
                            minDate={minDate ? minDate : new Date()}
                            dropdownMode="select"
                            disabled={userDetail.access_level === "brand_store"}
                          />
                          <InputGroupAddon addonType="append">
                            <div className="input-group-text">
                              <FontAwesomeIcon
                                htmlFor="datepicker"
                                icon={faCalendarAlt}
                              />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </FormGroup>
                  </Col>
                  {/* <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>Upload Document</Label>
                        <input type="file" ref={ref => this.fileInput1 = ref} name="document" onChange={(e) => this.uploadFile(e, 'document')} />
                      </FormGroup>
                    </Col> */}
                  <Col xl="5" md="12" xs="12">
                    <FormGroup>
                      <Label>Remark</Label>
                      <TextareaAutosize
                        name="remark"
                        autocomplete="nofill"
                        id="remark"
                        value={controls.remark}
                        style={{
                          minHeight: 60,
                          maxHeight: 70,
                          fontSize: "14px",
                        }}
                        className="form-control"
                        disabled={userDetail.access_level === "brand_store"}
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "remark",
                              value: e.target.value,
                            },
                          })
                        }
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {userDetail.access_level !== "brand_store" && (
                  <Row>
                    <Col
                      xl="3"
                      md="12"
                      xs="12"
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                        color="warning"
                        type="submit"
                        style={{ float: "right" }}
                        onClick={(e) => this.submit()}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                )}
                <br />
              </>
            ) : (
              ""
            )}
            {proposalStageDetail &&
              proposalStageDetail.handover_data &&
              proposalStageDetail.handover_data.length > 0 && (
                <Table className="mb-0  tbl-about tbl-about table-responsive">
                  <thead>
                    <tr>
                      {/* <th scope="col">Document</th> */}
                      <th scope="col">Hand Over Date</th>
                      <th scope="col">Report Date</th>
                      <th scope="col">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposalStageDetail.handover_data.map((item) => (
                      <tr>
                        {/* <td>
                          {
                            item && item.document &&
                            <a className='aa' target='_blank' rel="noopener noreferrer" href={item.document} className="d-flex-column">
                              <img src='images/sidebar-icons/secondary-report-selected.svg' alt=''  height='40px' style={{ margin: 'auto' }} />
                              <span className="redirect-link">Click here</span>
                            </a>
                          }
                        </td> */}
                        <td>
                          {item && (
                            <Moment format="DD-MM-YYYY">
                              {item.visit_date}
                            </Moment>
                          )}
                        </td>
                        <td>{item && item.created_at}</td>
                        <td>{item && item.remark}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default HandOver;
