import React, { Component } from 'react';
import {
  Row, Col, Button, ModalBody, Modal,
} from 'reactstrap';

import './SuccessModal.css';

class SuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      message: '',
      maxWidth: '680px',
      okButtonLabel: 'OK'
    }
  }

  toggle = (obj = {}) => {
    if (obj && Object.keys(obj).length > 0) {
      this.setState({
        ...obj
      })
    }
    if (this.state.visible) {
      if (this.props.suceessOK) {
        this.props.suceessOK(true);
      }
    }
    this.setState({
      visible: !this.state.visible
    })
  }

  render() {
    return (
      <Modal
        isOpen={this.state.visible}
        toggle={this.toggle}
        className="modal-md modal-lg custom-confirm-modal modal-sm modal-dialog-centered"
        style={{ maxWidth: this.state.maxWidth ? this.state.maxWidth : "680px" }}
        backdrop={this.backdrop ? "static" : "nostatic"}
      >
        <ModalBody>
          <Row>
            <Col xl="12" xs="12" md="12" className="mt-3">
              <div className="text-align-center success-title">
                {this.state.title}
              </div>
            </Col>
            <Col xl="12" xs="12" md="12" className="mt-4">
              <div className="text-align-center success-message">
                {this.state.message}
              </div>
            </Col>
            <Col xl="12" xs="12" md="12" className="text-align-center mb-2" style={{ marginTop: '9%' }}>
              <Button
                style={{ width: '133px' }}
                className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                color="warning" onClick={() => { this.toggle({}); }}>
                {this.state.okButtonLabel ? this.state.okButtonLabel : 'OK'}
              </Button></Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default SuccessModal;