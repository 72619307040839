import React, { Component } from 'react';
import {
  Button,
  FormGroup, Label,
  Row, Col,
} from 'reactstrap';
import Ionicon from 'react-ionicons';
import CampaignService from '../../services/CampaignService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";

const { ERROR_MESSAGE } = messages.COMMON;
class BrandstoreLocationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        zone: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        zone_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        region: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        region_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        area: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        area_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        state: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        state_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        // checkApollo: false,
        // checkBridgestone: false,
        // checkCEAT: false,
        // checkGoodyear: false,
        // checkJK: false,
        // checkYokohama: false,
        // checkMRF: false,
        brand: []
      },
      isLoading: false,
      zoneData: [],
      regionData: [],
      areaData: [],
      stateData: [],
    }
    if (props.getFilterControls && Object.keys(props.getFilterControls).length > 0) {
      this.state.controls = props.getFilterControls;
      if (this.state.controls && this.state.controls.zone.value !== '') {
        this.getRegion(this.state.controls.zone.value)
      }
      if (this.state.controls && this.state.controls.zone.value !== '') {
        this.getArea({ zone_id: this.state.controls.zone.value, region_id: this.state.controls.region.value })
      }
    }
  }

  componentDidMount() {
    this.getZone();
    this.getState();
  }

  handleInputChangeCheck = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    var { brand } = controls;

    const controlValue = !controls[controlName];
    let index;

    if (controlName === 'checkJK') {
      index = brand.indexOf('JK Tyre')
      if (index === -1) {
        brand.push('JK Tyre')
      } else {
        brand.splice(index, 1);
      }
    }
    if (controlName === 'checkApollo') {
      index = brand.indexOf('Apollo Tyres')
      if (index === -1) {
        brand.push('Apollo Tyres')
      } else {
        brand.splice(index, 1);
      }
    }
    if (controlName === 'checkCEAT') {
      index = brand.indexOf('CEAT')
      if (index === -1) {
        brand.push('CEAT')
      } else {
        brand.splice(index, 1);
      }
    }
    if (controlName === 'checkMRF') {
      index = brand.indexOf('MRF')
      if (index === -1) {
        brand.push('MRF')
      } else {
        brand.splice(index, 1);
      }
    }
    if (controlName === 'checkBridgestone') {
      index = brand.indexOf('Bridgestone')
      if (index === -1) {
        brand.push('Bridgestone')
      } else {
        brand.splice(index, 1);
      }
    }
    if (controlName === 'checkGoodyear') {
      index = brand.indexOf('Goodyear')
      if (index === -1) {
        brand.push('Goodyear')
      } else {
        brand.splice(index, 1);
      }
    }
    if (controlName === 'checkYokohama') {
      index = brand.indexOf('Yokohama')
      if (index === -1) {
        brand.push('Yokohama')
      } else {
        brand.splice(index, 1);
      }
    }
    controls[controlName] = controlValue;
    controls[brand] = brand;
    this.setState({ controls }, () => {
    });
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls, zoneData, areaData, stateData, regionData } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });

    if (e.target.name === 'zone') {
      this.getRegion(e.target.value)
      let index = zoneData.map(i => i.zone_id).indexOf(e.target.value);
      controls['zone_name'].value = zoneData[index].zone_name;
    }

    if (e.target.name === 'region') {
      this.getArea({
        region_ids: e.target.value,
        zone_id: controls.zone.value
      })
      let index = regionData.map(i => i.region_id).indexOf(e.target.value);
      controls['region_name'].value = regionData[index].region_name;
    }

    if (e.target.name === 'state') {
      let index = stateData.map(i => i.state_id).indexOf(e.target.value);
      controls['state_name'].value = stateData[index].name;
    }

    if (e.target.name === 'area') {
      let index = areaData.map(i => i.area_id).indexOf(e.target.value);
      controls['area_name'].value = areaData[index].area_name;
    }

  }

  getState = () => {
    this.setState({ isLoading: true });
    CampaignService.getState()
      .then(data => {
        let { stateData, controls } = this.state;
        let { data: states } = data.data;

        stateData = states;

        this.setState({ stateData, controls, isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  getZone = () => {
    this.setState({ isLoading: true });
    CampaignService.getZone()
      .then(res => {

        let { zoneData } = this.state;
        if (res.data.data && res.data.data && res.data.data.length > 0) {
          zoneData = res.data.data;
        } else {
          zoneData = [];
        }
        this.setState({
          zoneData,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  getRegion = zone_ids => {
    this.setState({
      isLoading: true
    });
    let reqData = {
      zone_id: zone_ids
    };
    CampaignService.getRegion(reqData)
      .then(data => {
        let { controls } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            zone_id: "all",
            region_id: [],
            region_name: "All"
          });

          this.setState({
            regionData: data.data.data,
            controls,
            isLoading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  getArea = data => {
    let reqData = {
      region_id: [],
      zone_id: []
    };
    if (data && Object.keys(data).length > 0) {
      reqData = {
        region_id: data.region_ids,
        zone_id: data.zone_id
      };
    }
    this.setState({
      isLoading: true
    });
    CampaignService.getArea(reqData)
      .then(data => {
        let { controls } = this.state;

        this.setState({
          areaData: data.data.data,
          controls,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  applyFilter = () => {
    this.props.applyFilter(this.state.controls);
  }

  resetFilter = () => {
    this.props.resetFilter();
  }

  onCloseSidebar = () => {
    this.props.onCloseSidebar();
  }

  render() {
    let { controls, zoneData, regionData, areaData, stateData } = this.state;
    let { brand } = controls;
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <>
          <div className="drawer-header">
            <span className="drawer-title" style={{ marginTop: "5px" }}>
              Filter
            </span>
            <Ionicon
              className="cursor-pointer"
              icon="md-close"
              fontSize="24px"
              color="#fdbb1f"
              onClick={() => this.onCloseSidebar()}
            />
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
          <div className="custom-drawer-content-wrapperr Fix-height" style={{ paddingLeft: "10px", paddingRight: "10px" }}>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <FormGroup>
                  <Label className="label-text">
                    Zone
                  </Label>
                  <select className="form-control form-group-select select select-height-70rem"
                    name="zone"
                    value={controls.zone.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option className="label-text" value="">Select</option>
                    {
                      zoneData.map((item) => (
                        <option className="label-text" value={item.zone_id}>{item.zone_name}</option>
                      ))
                    }
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <FormGroup>
                  <Label className="label-text">
                    Region
                  </Label>
                  <select className="form-control form-group-select select select-height-70rem"
                    name="region"
                    value={controls.region.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option className="label-text" value="">Select</option>
                    {
                      regionData.map((item) => (
                        <option className="label-text" value={item.region_id}>{item.region_name}</option>
                      ))
                    }
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <FormGroup>
                  <Label className="label-text">
                    Area
                  </Label>
                  <select className="form-control form-group-select select select-height-70rem"
                    name="area"
                    value={controls.area.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option className="label-text" value="">Select</option>
                    {
                      areaData.map((item) => (
                        <option className="label-text" value={item.area_id}>{item.area_name}</option>
                      ))
                    }
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <FormGroup>
                  <Label className="label-text">
                    State
                  </Label>
                  <select className="form-control form-group-select select select-height-70rem"
                    name="state"
                    value={controls.state.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option className="label-text" value="">Select</option>
                    {
                      stateData.map((item) => (
                        <option className="label-text" value={item.state_id}>{item.name}</option>
                      ))
                    }
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginTop: "5px", marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label className="label-text">
                  Brand
                </Label>
              </Col>
              <Col xl="4" md="6" xs="12">
                <FormGroup>
                  <label className="custom-checkboax1--container">
                    <input type="checkbox" name="checkJK" checked={brand.indexOf('JK Tyre') > -1}
                      onChange={(e) => this.handleInputChangeCheck(e)}
                    />
                    <span className="checkmark1" />
                  </label>&nbsp;
                  <span className="dropdown-toggle-fleet-type-filter-sub-lbl">JK Tyre</span>
                </FormGroup>
              </Col>

              <Col xl="4" md="6" xs="12">
                <FormGroup>
                  <label className="custom-checkboax1--container">
                    <input type="checkbox" name="checkApollo" checked={brand.indexOf('Apollo Tyres') > -1}
                      onChange={(e) => this.handleInputChangeCheck(e)}
                    />
                    <span className="checkmark1" />
                  </label>&nbsp;
                  <span className="dropdown-toggle-fleet-type-filter-sub-lbl">Apollo Tyres</span>
                </FormGroup>
              </Col>

              <Col xl="4" md="6" xs="12">
                <FormGroup>
                  <label className="custom-checkboax1--container">
                    <input type="checkbox" name="checkCEAT" checked={controls.checkCEAT}
                      onChange={(e) => this.handleInputChangeCheck(e)}
                    />
                    <span className="checkmark1" />
                  </label>&nbsp;
                  <span className="dropdown-toggle-fleet-type-filter-sub-lbl">CEAT</span>
                </FormGroup>
              </Col>

              <Col xl="4" md="6" xs="12">
                <FormGroup>
                  <label className="custom-checkboax1--container">
                    <input type="checkbox" name="checkMRF" checked={controls.checkMRF}
                      onChange={(e) => this.handleInputChangeCheck(e)}
                    />
                    <span className="checkmark1" />
                  </label>&nbsp;
                  <span className="dropdown-toggle-fleet-type-filter-sub-lbl">MRF</span>
                </FormGroup>
              </Col>

              <Col xl="4" md="6" xs="12">
                <FormGroup>
                  <label className="custom-checkboax1--container">
                    <input type="checkbox" name="checkBridgestone" checked={controls.checkBridgestone}
                      onChange={(e) => this.handleInputChangeCheck(e)}
                    />
                    <span className="checkmark1" />
                  </label>&nbsp;
                  <span className="dropdown-toggle-fleet-type-filter-sub-lbl">Bridgestone</span>
                </FormGroup>
              </Col>

              <Col xl="4" md="6" xs="12">
                <FormGroup>
                  <label className="custom-checkboax1--container">
                    <input type="checkbox" name="checkGoodyear" checked={controls.checkGoodyear}
                      onChange={(e) => this.handleInputChangeCheck(e)}
                    />
                    <span className="checkmark1" />
                  </label>&nbsp;
                  <span className="dropdown-toggle-fleet-type-filter-sub-lbl">Goodyear</span>
                </FormGroup>
              </Col>

              <Col xl="4" md="6" xs="12">
                <FormGroup>
                  <label className="custom-checkboax1--container">
                    <input type="checkbox" name="checkYokohama" checked={controls.checkYokohama}
                      onChange={(e) => this.handleInputChangeCheck(e)}
                    />
                    <span className="checkmark1" />
                  </label>&nbsp;
                  <span className="dropdown-toggle-fleet-type-filter-sub-lbl">Yokohama</span>
                </FormGroup>
              </Col>

            </Row>

            {/* <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label className="label-text">
                  Brand
                </Label>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrregion-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="brand"
                        value="JK Tyre"
                        onChange={() => {
                          let { controls } = this.state;
                          controls.brand.value = 'JK Tyre';
                          this.setState({ controls });
                        }}
                        checked={controls.brand.value === "JK Tyre"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">JK Tyre</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrregion-wrapp" style={{ paddingRight: "0" }} >
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="brand"
                        value="Apollo Tyres"
                        onChange={() => {
                          let { controls } = this.state;
                          controls.brand.value = 'Apollo Tyres';
                          this.setState({ controls });
                        }}
                        checked={controls.brand.value === "Apollo Tyres"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">Apollo Tyres</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrregion-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="brand"
                        value="CEAT"
                        onChange={() => {
                          let { controls } = this.state;
                          controls.brand.value = 'CEAT';
                          this.setState({ controls });
                        }}
                        checked={controls.brand.value === "CEAT"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">CEAT</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrregion-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="brand"
                        value="MRF"
                        onChange={() => {
                          let { controls } = this.state;
                          controls.brand.value = 'MRF';
                          this.setState({ controls });
                        }}
                        checked={controls.brand.value === "MRF"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">MRF</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrregion-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="brand"
                        value="Bridgestone"
                        onChange={() => {
                          let { controls } = this.state;
                          controls.brand.value = 'Bridgestone';
                          this.setState({ controls });
                        }}
                        checked={controls.brand.value === "Bridgestone"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">Bridgestone</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrregion-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="brand"
                        value="Goodyear"
                        onChange={() => {
                          let { controls } = this.state;
                          controls.brand.value = 'Goodyear';
                          this.setState({ controls });
                        }}
                        checked={controls.brand.value === "Goodyear"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">Goodyear</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrregion-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="brand"
                        value="Yokohama"
                        onChange={() => {
                          let { controls } = this.state;
                          controls.brand.value = 'Yokohama';
                          this.setState({ controls });
                        }}
                        checked={controls.brand.value === "Yokohama"} />
                      <span className="checkmark-radio" />
                    </label>
                    <span className="label-text marg-pad">Yokohama</span>
                  </div>
                </FormGroup>
              </Col>
            </Row> */}

          </div>

          <Row
            style={{
              marginLeft: "0",
              marginRight: "0",
              height: "100%",
              maxHeight: "80px",
              position: "relative",
              bottom: "0",
              width: "100%"
            }}
          >
            <Col
              xl="12"
              xs="12"
              md="12"
              className="text-align-right footer-drawer"
            >
              <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter()} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
              <Button className="mb-1 mr-1 btn-pill action-button font-weight-bold" color="warning" onClick={() => { this.applyFilter(); }} style={{ width: '116px' }}>Apply</Button>
            </Col>
          </Row>
        </>
      </>
    );
  }
}

export default BrandstoreLocationFilter;