import React, { Component } from "react";
import { FormGroup, Label, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import classnames from "classnames";
import WarrantyRegistrationService from "../../../services/WarrantyRegistrationService";
import GoogleAnalticsService from "../../../services/GoogleAnalticsService";

class VehicleMake extends Component {
  vehicleMake = [];

  displayVehicleDetail = [];

  brand_id = "";

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      controls: (props.vehicleDetails && props.vehicleDetails.controls) || {},
      isValidForm: props.vehicleDetails && props.vehicleDetails.isValidForm,
      from: props.from
    };
    const { controls } = this.state;
    const { vehicleTypeId } = controls;
    this.brand_id = vehicleTypeId.value.vehicle_type_id;
    this.getVehicleMake();
  }
  componentDidMount() {
    GoogleAnalticsService.event('Add Warranty', 'Vehicle Make View');
  }

  async getVehicleMake() {
    try {
      // const { controls } = this.state;
      // const { vehicleTypeId } = controls;
      this.props.isDataLoading(true);
      const { data } = await WarrantyRegistrationService.getVehicalMake({
        vehicle_type_id: this.brand_id
      });
      this.props.isDataLoading(false);
      if (data) {
        this.vehicleMake = JSON.parse(JSON.stringify(data.data));
        this.displayVehicleDetail = JSON.parse(JSON.stringify(data.data));
        // if (vehicleTypeId.value.type && (vehicleTypeId.value.type.toLowerCase() === 'lcv' || vehicleTypeId.value.type.toLowerCase() === 'scv')) {
        //   this.vehicleMake = this.vehicleMake.filter(value => value.name.toLowerCase() !== 'other');
        //   this.displayVehicleDetail = this.vehicleMake.filter(value => value.name.toLowerCase() !== 'other');
        // }
        if (this.displayVehicleDetail.length === 1 && this.displayVehicleDetail[0].name.toLowerCase() === ('other').toLowerCase()) {

        } else {
          this.displayVehicleDetail = JSON.parse(JSON.stringify(this.displayVehicleDetail.filter((value) => value.name.toLowerCase() !== ('other').toLowerCase())))
        }
        // this.displayVehicleDetail.forEach((element, index) => {
        //   if ((element.name).toLowerCase() === ('other').toLowerCase()) {
        //     cityData = this.displayVehicleDetail.splice(20, 0, this.displayVehicleDetail.splice(index, 1)[0]);
        //   }
        //   // if (index + 1 === this.displayVehicleDetail.length) {

        //   // }
        // });
        this.vehicleMake.forEach((element, index) => {
          if ((element.name).toLowerCase() === ('other').toLowerCase()) {
            element.name = 'Other (Vehicle make is not available)'
          }
        });
      }

      this.setState(prevState => {
        return { ...prevState };
      });
    } catch (error) {
      this.props.isDataLoading(false);
      console.log(error);
    }
  }

  goBack = () => {
    const { controls, isValidForm } = this.state;
    this.props.updateState({
      visibleArea: "vehicleType",
      vehicleDetails: { controls, isValidForm }
    });
  };

  vechileMake(item) {
    const { controls, isValidForm } = this.state;
    let visibleAreaLabel = "vehicleModel";
    if (controls.vehicleTypeId.value.type === 'lcv' || controls.vehicleTypeId.value.type === 'scv') {
      visibleAreaLabel = "productDetailsEdit";
    }
    controls.vehicleTypeBrandId.value = item;
    controls.vehicleTypeBrandModelId.value = "";
    this.setState({ controls });
    this.props.updateState({
      visibleArea: visibleAreaLabel,
      vehicleDetails: { controls, isValidForm }
    });
  }

  render() {
    return (
      <>
        <style jsx="true">
          {`
            @media screen and (min-width: 220px) and (max-width: 991px) {
              .popover,
              .dropdown-menu {
                left: -1% !important;
                top: 0px !important;
                width: 100% !important;
                position: absolute !important;
                transform: translate3d(0px, 31px, 0px) !important;
              }
            }
          `}
        </style>
        <Row className="warranty-customer-details-label">
          <Col xl="10" xs="10" ms="10" style={{ display: "flex" }}>
            <FontAwesomeIcon
              onClick={this.goBack}
              icon={faAngleLeft}
              style={{ fontSize: "20px", cursor: "pointer" }}
              color="#fdbb1f"
            />
            &nbsp;&nbsp;
            <span style={{ marginTop: "2px" }}>VEHICLE MAKE</span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <FormGroup>
              <Label for="tyreQuantity" className="input-label" style={{ fontWeight: 'bold' }}>
                Select the company of your customer's vehicle
                <span className="required-star"> *</span>
              </Label>
              <div className="d-flex-row vehical-sku-id-search service-pref-note-warranty">
                <Row>
                  <Label for="tyreQuantity" className="input-label">
                    Note - If you can not find the company of your customer’s vehicle please use the search bar to search for it.
                  </Label>
                  <Typeahead
                    id="vehicleMake"
                    filterBy={(option, props) => {
                      if (props.selected.length) {
                        return true;
                      }
                      return (
                        option.name
                          .toLowerCase()
                          .indexOf(props.text.toLowerCase()) !== -1 ||
                        option.name.toLowerCase().indexOf("other") !== -1
                      );
                    }}
                    labelKey="name"
                    options={this.vehicleMake}
                    placeholder="Search..."
                    onChange={selectedMake => this.vechileMake(selectedMake[0])}
                  />
                  <FontAwesomeIcon
                    className="serach-icon"
                    icon={faSearch}
                    color="#6e777f"
                  />
                </Row>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginLeft: "0" }}>
          {this.displayVehicleDetail.map(
            (item, index) =>
              index < 15 && (
                <Col
                  xl="4"
                  md="6"
                  ms="6"
                  xs="6"
                  sm="6"
                  className={classnames(
                    {
                      "active-vehical-make-wrapp":
                        this.brand_id === item.brand_id
                    },
                    "vehical-make-wrapp"
                  )}
                  style={{ display: "flex", maxWidth: "120px" }}
                  key={item.brand_id}
                >
                  <div
                    title={item.name}
                    onClick={() => this.vechileMake(item)}
                    className="d-flex-column"
                    style={{ margin: "auto" }}
                  >
                    <img
                      src={item.image_url}
                      alt={item.name}
                      className="vehical-make-img"
                    />
                    <span className="vehical-make-label">{item.name}</span>
                  </div>
                </Col>
              )
          )}
        </Row>
        <br />

        {/* <span className="Note---If-you-can-no">
          Note - If you can not find the make of your customer's vehicle please
          use the search bar to search for it.
        </span> */}
      </>
    );
  }
}

export default VehicleMake;
