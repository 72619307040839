import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import { renderToString } from "react-dom/server";
import {
  BtnBold,
  BtnItalic,
  BtnStrikeThrough,
  Toolbar,
} from "react-simple-wysiwyg";
import Ionicon from "react-ionicons";
import ReactTooltip from "react-tooltip";
import { Editor, EditorProvider } from "react-simple-wysiwyg";
import swal from "sweetalert";
import ModalService from "../../../services/ModalService";
import UserUpdateService from "../../../services/UserUpdateService";
import { messages } from "../../../constants/constant.message";
import constants from "../../../utils/constants";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
const { WHATSAPP_LANGUAGES = [] } = constants;
const { ERROR_MESSAGE } = messages.COMMON;
function AddWhatsappTemplates({
  toogleSideBar,
  getWhatsappTemplate,
  back = "",
}) {
  const [formData, setFormData] = useState({
    msgHtml: { __html: "" },
    body: "",
    headerSampleFile: "",
    templateName: "",
    bodySample: "",
    msgType: "text",
    mediaType: "Image",
    language: "en",
    category: "MARKETING",
  });
  const [errors, setErrors] = useState({});

  const [content, setContent] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const convertToHtml = (text) => {
    // Replace '\n' with '<br>'
    let htmlText = text ? text.replace(/\n/g, "<br>") : "";
    // Replace '\t' with '&nbsp;&nbsp;&nbsp;&nbsp;' (four non-breaking spaces)
    htmlText = htmlText
      ? htmlText.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
      : htmlText;
    let headerElement = null;
    if (formData.msgType === "media") {
      let headerUrl = "";
      if (formData.mediaType === "Image") {
        headerUrl = "/images/white_image.png";
      } else if (formData.mediaType === "Document") {
        headerUrl = "/images/white_document.png";
      } else if (formData.mediaType === "Video") {
        headerUrl = "/images/white_video.png";
      }
      headerElement = (
        <div
          className="msg-header"
          style={{
            backgroundImage: `url('${headerUrl}')`,
            display: "block",
            backgroundSize: "80px 80px",
          }}
        ></div>
      );
    }

    htmlText = htmlText.replace(/(?<![{[?}\]])\*(?!\s)(.+?)\*/g, "<b>$1</b>");
    htmlText = htmlText.replace(/(?<![{[?}\]])_(?!\s)(.+?)_/g, "<i>$1</i>");
    htmlText = htmlText.replace(/(?<![{[?}\]])~(?!\s)(.+?)~/g, "<s>$1</s>");

    const headerHtml = headerElement ? renderToString(headerElement) : "";
    if (!headerHtml && !htmlText) {
      htmlText = "&nbsp;&nbsp;&nbsp;&nbsp;";
    }
    return { __html: headerHtml + htmlText };
  };

  useEffect(() => {
    const html = convertToHtml(formData.body);
    let newFormData = { ...formData };
    newFormData.msgHtml = html;
    setFormData(newFormData);
  }, [formData.msgType, formData.mediaType]);

  const htmlToString = (htmlText) => {
    // // Replace <b> tags with asterisks
    // let text = html.replace(/<b>(.*?)<\/b>/g, "*$1*");
    // // Replace <i> tags with underscores
    // text = text.replace(/<i>(.*?)<\/i>/g, "_$1_");
    // // Replace <strike> tags with tildes
    // text = text.replace(/<strike>(.*?)<\/strike>/g, "~$1~");
    // // Replace <br> tags with newline characters
    // text = text.replace(/<br\s*\/?>/g, "\n");
    // // Remove <div> tags
    // text = text.replace(/<div[^>]*>/g, "");
    // // Remove closing </div> tags
    // text = text.replace(/<\/div>/g, "");
    // // Remove inline styles
    // text = text.replace(/<[^>]+>/g, "");

    // // Replace nested formatting characters
    // text = text.replace(/\*\*(.*?)\*\*/g, "*$1*"); // Remove extra asterisks
    // text = text.replace(/__([^_]+)__/g, "_$1_"); // Remove extra underscores
    // text = text.replace(/~~([^~]+)~~/g, "~$1~"); // Remove extra tildes
    // text = html.replace(/&nbsp;/g, " ");

    // // Add any other replacements if needed
    // return text;

    htmlText = htmlText.replace(/&nbsp;/g, " ");
    htmlText = htmlText.replace(/<br\s*\/?>/g, "\n");
    htmlText = htmlText.replace(/<div[^>]*>/g, "\n").replace(/<\/div>/g, "");
    const codes = { B: "*", I: "_", STRIKE: "~" };
    const { body } = new DOMParser().parseFromString(htmlText, "text/html");
    const dfs = ({ childNodes }) =>
      Array.from(childNodes, (node) => {
        if (node.nodeType == 1) {
          const s = dfs(node);
          const code = codes[node.tagName];
          return code
            ? s.replace(/^(\s*)(?=\S)|(?<=\S)(\s*)$/g, `$1${code}$2`)
            : s;
        } else {
          return node.textContent;
        }
      }).join("");

    return dfs(body);
  };

  // Validation function for form inputs
  const validateForm = (newFormData, controlName = "", isSubmit = false) => {
    const newErrors = {};
    let isValid = true;
    if (
      !newFormData.templateName.trim() &&
      (controlName === "templateName" || isSubmit || errors.templateName)
    ) {
      newErrors.templateName = "Template name is required";
      isValid = false;
    } else if (newFormData.templateName) {
      newErrors.templateName = "";
    }

    if (!newFormData.body.trim() && (controlName === "body" || isSubmit)) {
      newErrors.body = "Body is required";
      isValid = false;
    } else if (newFormData.body) {
      newErrors.body = "";
    }

    // if (
    //   !newFormData.bodySample.trim() &&
    //   (controlName === "bodySample" || isSubmit)
    // ) {
    //   newErrors.bodySample = "Sample body is required";
    //   isValid = false;
    // } else if (newFormData.bodySample) {
    //   newErrors.bodySample = "";
    // }

    // if (
    //   !newFormData.headerSampleFile.trim() &&
    //   (controlName === "headerSampleFile" || isSubmit) &&
    //   newFormData.msgType === "media"
    // ) {
    //   newErrors.headerSampleFile = "Sample File URL is required";
    //   isValid = false;
    // } else if (newFormData.headerSampleFile) {
    //   newErrors.headerSampleFile = "";
    // }

    // Add validations for other fields if needed...

    // setErrors({ ...errors, ...newErrors });
    setErrors(newErrors);
    return isValid;

    // Return true if there are no errors, otherwise false
    // return Object.keys(newErrors).length === 0;
  };
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = { ...formData };
    if (name) {
      newFormData[name] = value;
    }
    if (name === "body") {
      const html = convertToHtml(e.target.value);
      newFormData.msgHtml = html;
    }
    setFormData(newFormData);
    validateForm(newFormData, name, false);
  };

  const resetControls = () => {
    setFormData({
      msgHtml: { __html: "" },
      body: "",
      headerSampleFile: "",
      templateName: "",
      bodySample: "",
      msgType: "text",
      mediaType: "Image",
      language: "en",
      category: "MARKETING",
    });
  };

  const createTemplate = () => {
    if (!validateForm(formData, "", true)) {
      // If validation fails, return
      return;
    }
    const formDataRequest = new FormData();
    formDataRequest.append("msgType", formData.msgType.toLowerCase());
    let body = htmlToString(formData.body);
    body = body.replace(/\n{4,}/g, "\n\n");
    body = body.replace(/\n{4,}/g, "\n\n");
    body = body.replace(/\n{3,}/g, "\n\n");
    body = body.replace(/\n{3,}/g, "\n\n");

    formDataRequest.append("body", body);
    // formDataRequest.append("bodySample", htmlToString(formData.bodySample));
    formDataRequest.append("bodySample", body);
    formDataRequest.append("mediaType", formData.mediaType.toLowerCase());
    formDataRequest.append("templateName", formData.templateName);
    formDataRequest.append("headerSampleFile", formData.headerSampleFile);
    formDataRequest.append("language", formData.language);
    formDataRequest.append("category", formData.category || "MARKETING");
    // Display the key/value pairs
    for (var pair of formDataRequest.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    setIsLoading(true);
    UserUpdateService.addWhatsappTemplate(formDataRequest)
      .then((data) => {
        setIsLoading(false);
        if (data.data && data.data.message) {
          toogleSideBar(false);
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            resetControls();
            getWhatsappTemplate(1, 10, "");
          });
        }
      })
      .catch((e) => {
        debugger;
        setIsLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  const infoIcon = (message, id) => {
    return (
      <>
        {/* <button type="button" id="ScheduleUpdateTooltip" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title={message}> */}
        <img
          className="mt-auto ml-2 cursor-pointer"
          src="/images/info_white_background.svg"
          alt=""
          id={id} // title={message}
          style={{ marginBottom: 2, height: "16px" }}
          // onClick={listenerFunc}
        />
        {/* </button>; */}
        <UncontrolledTooltip
          placement="top"
          target={id}
          trigger="hover"
          style={{ maxWidth: "600px" }}
        >
          {message}
        </UncontrolledTooltip>
      </>
    );
  };
  return (
    <div
      className="AddWhatsappTemplates"
      style={{ position: "relative", height: "100vh" }}
    >
      {isLoading && <CustomSpinner />}
      <div style={{ height: "60px" }} className="custom-dropdown-header">
        <div className="d-flex w-100">
          {back && (
            <div className="d-flex-row">
              <img
                src="images/right.svg"
                alt=""
                style={{ transform: "rotate(180deg)" }}
                onClick={() => toogleSideBar(false, back)}
              />
              &nbsp;&nbsp;
              <span
                onClick={() => toogleSideBar(false, back)}
                className="link-blue cursor-pointer"
                style={{ fontSize: "16px" }}
              >
                Back
              </span>
              &nbsp;&nbsp;&nbsp;
            </div>
          )}
          <div className="d-flex justify-content-betweeen w-100">
            <span className="custom-dropdown-header-title cursor-pointer">
              Create Whatsapp Template
            </span>
            <img
              className="cursor-pointer ml-auto"
              src="/images/close.svg"
              alt=""
              onClick={() => toogleSideBar(false)}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "15px" }}>
        <Row>
          <Col xl="7" xs="7" md="7" className="left-panel">
            <Row>
              <Col xl="12" xs="12" md="12">
                <div>
                  <label className="wtemplate_msg d-flex flex-column">
                    <span> Templates Name *</span>
                    <div className="grayNote">
                      Name can only contain lowercase letters,
                      numbers,underscores and without any spaces.
                    </div>
                  </label>

                  <Input
                    name="templateName"
                    value={formData.templateName}
                    onChange={handleChange}
                    type="text"
                  />
                  {errors.templateName && (
                    <div className="text-danger">{errors.templateName}</div>
                  )}
                </div>
              </Col>
              <Col xl="6" xs="6" md="6" className="mt-3">
                <div>
                  <label className="wtemplate_msg d-flex">
                    <span>Category *</span>{" "}
                    <div style={{ cursor: "pointer" }}>
                      {infoIcon(
                        "Choose what type of message template you want to create.",
                        "template_category_info"
                      )}
                    </div>
                  </label>
                  <div className="d-flex">
                    <select
                      style={{ cursor: "pointer" }}
                      className="form-control select-height-70rem font-size-14px"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                    >
                      <option value="MARKETING" selected>
                        MARKETING
                      </option>
                      <option value="UTILITY">UTILITY</option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col xl="6" xs="6" md="6" className="mt-3">
                <div>
                  <label className="wtemplate_msg d-flex">
                    <span>Message Type *</span>{" "}
                    <div style={{ cursor: "pointer" }}>
                      {infoIcon(
                        "Choose a template message type you want to create.",
                        "template_msg_type_info"
                      )}
                    </div>
                  </label>
                  <div className="d-flex">
                    <select
                      style={{ cursor: "pointer" }}
                      className="form-control select-height-70rem font-size-14px"
                      name="msgType"
                      value={formData.msgType}
                      onChange={handleChange}
                    >
                      <option value="text" selected>
                        Text
                      </option>
                      <option value="media">Media</option>
                    </select>
                  </div>
                </div>
              </Col>
              {formData.msgType === "media" && (
                <Col xl="6" xs="6" md="6" className="mt-3">
                  <div>
                    <label className="wtemplate_msg d-flex">
                      <span>Media Type *</span>{" "}
                      <div style={{ cursor: "pointer" }}>
                        {infoIcon(
                          "Choose a template media type you want to create.",
                          "template_media_type_info"
                        )}
                      </div>
                    </label>
                    <div className="d-flex">
                      <select
                        style={{ cursor: "pointer" }}
                        className="form-control select-height-70rem font-size-14px"
                        name="mediaType"
                        value={formData.mediaType}
                        onChange={handleChange}
                      >
                        <option value="Image" selected>
                          Image
                        </option>
                        <option value="Document">Document</option>
                        <option value="Video">Video</option>
                      </select>
                    </div>
                  </div>
                </Col>
              )}
              <Col xl="6" xs="6" md="6" className="mt-3">
                <div>
                  <label className="wtemplate_msg d-flex">
                    <span>Language *</span>{" "}
                    <div style={{ cursor: "pointer" }}>
                      {infoIcon(
                        "Choose which languages your message template will be sent in.",
                        "template_language_info"
                      )}
                    </div>
                  </label>
                  <div className="d-flex">
                    <select
                      style={{ cursor: "pointer" }}
                      className="form-control select-height-70rem font-size-14px"
                      name="language"
                      value={formData.language}
                      onChange={handleChange}
                    >
                      {WHATSAPP_LANGUAGES &&
                        WHATSAPP_LANGUAGES.map((item, i) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </Col>
              {/* 
              {formData.msgType === "media" && (
                <Col xl="12" xs="12" md="12" className="mt-3">
                  <div>
                    <label
                      className="create_email_campaign_label"
                      style={{ marginBottom: "10px" }}
                    >
                      {formData.mediaType} Sample File URL *
                    </label>
                    <FormGroup
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      <Input
                        name="headerSampleFile"
                        onChange={handleChange}
                        type="text"
                        value={formData.headerSampleFile}
                      />
                    </FormGroup>
                    {errors.headerSampleFile && (
                      <div className="text-danger">
                        {errors.headerSampleFile}
                      </div>
                    )}
                  </div>
                </Col>
              )} */}

              <Col xl="12" xs="12" md="12" className="mt-3">
                <div>
                  <label className="wtemplate_msg d-flex flex-column">
                    <span> Body *</span>
                    <div className="grayNote">
                      Enter the text for your message in the language you have
                      selected.
                    </div>
                  </label>

                  <EditorProvider>
                    <Editor
                      style={{ maxHeight: "300px", overflow: "auto" }}
                      value={formData.body}
                      name="body"
                      onChange={handleChange}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnStrikeThrough />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                  {errors.body && (
                    <div className="text-danger">{errors.body}</div>
                  )}
                </div>
              </Col>
              {/* <Col xl="12" xs="12" md="12" className="mt-3">
                <div>
                  <label className="wtemplate_msg">Sample Body*</label>
                  <EditorProvider>
                    <Editor
                      style={{ maxHeight: "220px", overflow: "auto" }}
                      value={formData.bodySample}
                      name="bodySample"
                      onChange={handleChange}
                      onBlur={() => validateForm(formData, "bodySample")}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnStrikeThrough />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                  {errors.bodySample && (
                    <div className="text-danger">{errors.bodySample}</div>
                  )}
                </div>
              </Col> */}
            </Row>
          </Col>
          <Col xl="5" xs="5" md="5" className="mt-3 d-flex justify-content-end">
            <div className="d-flex">
              <div className="whatsapp-message">
                <WhatsAppWidget
                  phoneNo="919884098840"
                  position="right"
                  widgetWidth="320px"
                  widgetWidthMobile="160px"
                  autoOpen={true}
                  autoOpenTimer={1}
                  messageBox={true}
                  messageBoxTxt=""
                  iconSize="100%"
                  iconColor="#fee800"
                  iconBgColor="#fee800"
                  headerIcon="/images/jktyre-chat-profile-icon-circle.png"
                  headerIconColor="#fff"
                  headerTxtColor="#fff"
                  headerBgColor="#005e54"
                  headerTitle="JKTyre"
                  headerCaption=" "
                  bodyBgColor="#bbb"
                  chatPersonName=" "
                  chatMessage={
                    <div
                      style={{ width: "221px" }}
                      dangerouslySetInnerHTML={formData.msgHtml}
                    />
                  }
                  footerBgColor="#999"
                  placeholder="Type a message.."
                  btnBgColor="yellow"
                  btnTxt="Start Chat"
                  btnTxtColor="black"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          marginLeft: "0",
          marginRight: "0",
          maxHeight: "75px",
          position: "absolute",
          bottom: "60px",
          width: "100%",
        }}
      >
        <Row
          style={{ marginLeft: "0", marginRight: "0" }}
          className="dealer_filter_button_container"
        >
          <Col
            xl="12"
            xs="12"
            md="12"
            className="text-align-right footer-drawer"
          >
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
              color="dark"
              onClick={() => toogleSideBar(false)}
              style={{ width: "116px" }}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              style={{ width: "116px" }}
              className="mb-1 mr-1 btn-pill action-button  font-weight-bold"
              color="primary"
              onClick={() => createTemplate()}
            >
              Create
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddWhatsappTemplates;
