import React, { Component } from 'react';
import {
  Button,
  Input,
  Row, Col,
  FormGroup,
  Card, CardBody,
  UncontrolledPopover, PopoverHeader, PopoverBody
} from 'reactstrap';
import ReactDOM from "react-dom";
// import Carousel from 'react-multi-carousel';
import moment from "moment";
import Lightbox from "react-image-lightbox";
import 'react-multi-carousel/lib/styles.css';
import './DealerDashboard.css';
import '../../components/CampaignCustomize/CampaignCustomize.css';
import DashboardService from '../../services/DashboardService';
import { formatNumber, getAction, getCurrentQuarter, getCurrentYear } from '../../utils/utils';
import Validation from '../../services/Validation';
import WarrantyRegistrationService from '../../services/WarrantyRegistrationService';
import { messages } from '../../constants/constant.message';
import TradeSchemeService from '../../services/TradeSchemeService';
import CustomDropDown from './CustomDropDown';
import { setDealerDashboardRedirectDetail, setAddCustomerRedirect, setRedirect, setHelpGuide } from '../../actions/UserAction';
import { connect } from 'react-redux';
import CustomerManagementService from '../../services/CustomerManagementService';
import ModalService from '../../services/ModalService';
import Slider from "react-slick";
import Sidebar from "react-sidebar";
import swal from 'sweetalert';
import Storage from '../../services/Storage';
import CampaignService from '../../services/CampaignService';
import { askForPermissioToReceiveNotifications } from '../../push-notification';
import AddCustomer from '../../components/AddCustomer/AddCustomer';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import RedeemCoupon from '../../components/RedeemCoupon/RedeemCoupon';
import SendVerifyOtp from '../../components/SendVerifyOtp/SendVerifyOtp';
import ReactTooltip from 'react-tooltip';
import ModalServicePref from './ModalServicePref';
import UserService from '../../services/UserService';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';
import { Permissions } from '../../constants/constant.permissions';
import SpeacialEngagementService from "../../services/SpecialEngagementService";
import SpeacialEngagement from "../../modal/SpeacialEngagement/SpeacialEngagement";
//import ModalServicePref from './ModalServicePref';

const settings = {
  className: "slider-grow-business",
  centerMode: false,
  infinite: false,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  speed: 500,
  dots: true,
  initialSlide: 0
};
const settings_banner = {
  className: "slider-banner",
  centerMode: false,
  infinite: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  dots: true,
  initialSlide: 1,
};

// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//     slidesToSlide: 1 // optional, default to 1.
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1,
//     slidesToSlide: 1 // optional, default to 1.
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//     slidesToSlide: 1 // optional, default to 1.
//   }
// };

// const responsive_grow_business = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//     slidesToSlide: 1 // optional, default to 1.
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 3,
//     slidesToSlide: 1 // optional, default to 1.
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 3,
//     slidesToSlide: 1 // optional, default to 1.
//   }
// };

class DealerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      couponData: {},
      finatial_year: '2020-21',
      engage_customers: [],
      grow_business_list_data: [],
      warranty_data: {
        customer_count: 0,
        pcr_wheeler_tyre: 0,
        total_points: 0,
        two_three_wheeler_tyre: 0,
        scv_tyre: 0,
        lcv_tyre: 0,
        sensor_count: 0,
        pcr_wheeler_tyre_with_sensor: 0,
        two_three_wheeler_tyre_with_sensor: 0,
        scv_tyre_with_sensor: 0,
        lcv_tyre_with_sensor: 0
      },
      warranty_catalogue: {
        customer_count: 0,
        pcr_wheeler_tyre: 0,
        total_points: 0,
        two_three_wheeler_tyre: 0
      },
      bannerData: [],
      isPreviewImageLightBoxOpen: false,
      imagesToPreview: [],
      currentSlideBanner: 0,
      currentSlideGrowBusiness: 0,
      currentSlideEngageCustomer: 0,
      is_display_sms_credits: false,
      is_service_preference_set: true,
      sms_credits: {
        credit: 0
      },
      offtak_points: {
        eligible_offtake: 0,
        total_offtake: 0,
        quarter_eligible_offtake: 0,
        quarter_total_offtake: 0,
        month_eligible_offtake: null,
        month_total_offtake: null,
        points: null
      },
      merchandiseData: {
        total_coins: 0,
        balance_coins: 0,
        redeemed_coins: 0
      },
      redeemptionData: {
        "available_points": 0,
        "redeem_points": 0,
        "lower_month": 0,
        "upper_month": 0
      },
      dashboardCount: {
        "customer_card": {
          "today_customer": 0,
          "total_customer": 0,
          "current_month_customer": 0
        },
        "reference_card": {
          "today_customer": 0,
          "total_customer": 0,
          "current_month_customer": 0
        },
        "inquiry_card": {
          "today_customer": 0,
          "total_customer": 0,
          "current_month_customer": 0
        },
        "tyre_purchase_card": {
          "due_customer": 0,
          "today_customer": 0,
          "total_customer": 0,
          "current_month_customer": 0
        },
        "service_card": {
          "due_customer": 0,
          "today_customer": 0,
          "total_customer": 0,
          "current_month_customer": 0
        }
      },
      errorMessageOtp: '',
      isSendOtp: false,
      controls: {
        phoneNumber: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidPhone: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        otp: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidPhone: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      },
      schemeReport: {
        "mtd": {
          "target": 0,
          "achievement": 0,
          "achieved_percentage": 0
        },
        "qtd": {
          "target": 0,
          "achievement": 0,
          "achieved_percentage": 0,
          "data": []
        },
      },
      selectedVehicle: [],
      selectedService: [],
      services: [],
      vehicleTypeData: [],
      navBarToHide: [],
      selectedDate: '30',
      selectedDateService: '30',
      schemes_year: {},
      opensidebar: false,
      isClamRegistrationOpen: false,
      yearOption: {
        year: '',
        year_name: ''
      },
      displayLabels: '',
      hide_card: {
        merchandise_wallet: true,
        trade_scheme_report: true,
        redemption_catalogue: true,
        warranty_report: true,
        warranty_register: true,
      },
      days_weeks_months_years_data: [
        {
          id: '90',
          name: '+ / - 90 Days'
        },
        {
          id: '60',
          name: '+ / - 60 Days'
        },
        {
          id: '30',
          name: '+ / - 30 Days'
        },
        {
          id: '7',
          name: '+ / - 7 Days'
        }
      ],
      tooltipOpen: false,
      tooltip_name: 'customer_popover',
      isSpecialEngagementModalOpen: false,
    }

    this.sliderBanner = React.createRef();
    this.sliderGrowBuiness = React.createRef();
    this.sliderEngageCustomer = React.createRef();
    this.props.setDealerDashboardRedirectDetail(null);
    if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)) {
      if (this.state.userDetail.permissions.includes(Permissions.BUY_SMS)) {
        this.state.is_display_sms_credits = true;
      }
    } else {
      this.props.history.push(
        `/`
      );
    }

    if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.MERCHANDISE_CATALOGUE)) {
      this.state.hide_card.merchandise_wallet = false;
    } if ((this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.TRADE_SCHEME_REPORT)) || (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.TRADE_SCHEME))) {
      this.state.hide_card.trade_scheme_report = false;
    } if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.REDEMPTION_CATALOGUE)) {
      this.state.hide_card.redemption_catalogue = false;
    } if ((this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.WARRANTY_REPORT))) {
      // (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.DASHBOARD_WARRANTY_CATALOGUE))
      this.state.hide_card.warranty_report = false;
    }

    if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.WARRANTY_REGISTRATION)) {
      this.state.hide_card.warranty_register = false;
    }

    if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.CHECK_ELIGIBILITY_FOR_WARRANTY)) {
      this.state.isClamRegistrationOpen = true;
    }

    GoogleAnalticsService.pageView('New Dashboard View');
  }

  componentDidMount() {
    askForPermissioToReceiveNotifications();
    this.getUserDetail();
    this.initAPi();
    if ((this.props.redirectDetailsAddCustomer && this.props.redirectDetailsAddCustomer.redirectToAddCustomer) ||
      Storage.getAddCustomerSuccessDetail()) {
      this.props.setAddCustomerRedirect(null);
      if (this.state.userDetail.is_service_preference_set) {
        this.setState({
          opensidebar: true,
          tooltipOpen: false
        });
      } else {
        this.openSerivcePrefFromAddCustomer()
      }
    }
    this.getPopupLink();
  }

  getPopupLink = (year) => {
    SpeacialEngagementService.getPopupLink(year)
      .then((data) => {
        if (data.data && data.data.data.link) {
          this.setState({ isSpecialEngagementModalOpen: true });
        }
      })
      .catch((e) => { });
  };

  closeSpecialEngagementModal = () => {
    this.setState({ popupData: null, isSpecialEngagementModalOpen: false });
  };

  getHelpModuleScreen = () => {
    this.setState({ isLoading: true });
    DashboardService.getHelpModuleScreen().then((data) => {
      this.setState({ isLoading: false });
      const { screen_name } = data.data.data;
      if (screen_name.includes('dealer_dashboard') === false) {
        this.setState({ tooltipOpen: true, tooltip_name: 'add_visit_history_popover' });
      }
    }).catch((e) => {
      this.setState({ isLoading: false });
    });
  }

  reOpenToolTipPopOver = (name) => {
    this.setState({
      tooltipOpen: true,
      tooltip_name: name
    });
    localStorage.setItem('helperModule', name)
    this.props.setHelpGuide(name);
  }

  addHelpModuleScreen = (name) => {
    this.setState({ isLoading: true });
    this.setState({
      dropdownOpenYtd: true
    });
    DashboardService.addHelpModuleScreen({ screen_name: name }).then((data) => {
      this.setState({ tooltipOpen: false, tooltip_name: '' })
      this.setState({ isLoading: false });
      this.setState({
        dropdownOpenYtd: true
      });
    }).catch((e) => {
      this.setState({ isLoading: false });
    });
  }

  openAddCustomer = () => {
    this.setState({
      opensidebar: true,
      tooltipOpen: false
    });
  }

  openSerivcePrefFromAddCustomer = async () => {

    let response_data = await this.getUserDetail(true);
    if (!response_data.is_service_preference_set) {
      this.refs.modalServicePref.toggle({ is_from_add_customer_visit: true });
    } else {
      this.setState({
        opensidebar: true,
        tooltipOpen: false
      });
    }
  }

  updateSerivicePrefSet = () => {
    const obj = {
      is_service_preference_set: true
    };
    this.setState({
      isLoading: true
    })
    UserService.updateUserInfo(obj)
      .then((data) => {
        this.getUserDetail();
        this.setState({
          isLoading: false
        })
      })
      .catch((e) => {
        this.setState({
          isLoading: false
        })
        // const err =
        //   e.response && e.response.data && e.response.data.error[0]
        //     ? e.response.data.error[0]
        //     : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isContatFormLoading: false });
        // ModalService.openAlert("", message, "error");
      });
  };

  getUserDetail = async (is_loading = false) => {
    if (is_loading) {
      this.setState({
        isLoading: true
      })
    }
    let response_data = await UserService.getUserInfo()
      .then(response => {
        let { userDetail, hide_card, is_service_preference_set } = this.state;
        if (response.data && response.data.data && response.data.data.dealer_type) {
          Storage.setUserDetail(response.data.data);
          userDetail = response.data.data;
          if (userDetail.permissions && userDetail.permissions.includes(Permissions.MERCHANDISE_CATALOGUE)) {
            hide_card.merchandise_wallet = false;
          } if ((userDetail.permissions && userDetail.permissions.includes(Permissions.TRADE_SCHEME_REPORT)) || (userDetail.permissions && userDetail.permissions.includes(Permissions.TRADE_SCHEME))) {
            hide_card.trade_scheme_report = false;
          } if (userDetail.permissions && userDetail.permissions.includes(Permissions.REDEMPTION_CATALOGUE)) {
            hide_card.redemption_catalogue = false;
          } if ((userDetail.permissions && userDetail.permissions.includes(Permissions.WARRANTY_REPORT))) {
            // (userDetail.permissions && userDetail.permissions.includes(Permissions.DASHBOARD_WARRANTY_CATALOGUE))
            hide_card.warranty_report = false;
          }

          if (userDetail.permissions && userDetail.permissions.includes(Permissions.WARRANTY_REGISTRATION)) {
            hide_card.warranty_register = false;
          }

          is_service_preference_set = userDetail.is_service_preference_set;
        }
        this.setState({
          hide_card,
          userDetail,
          is_service_preference_set
        })
        if (is_loading) {
          this.setState({
            isLoading: false
          })
        }
        return userDetail;
      }).catch(e => {
        console.error("e", e);
        if (is_loading) {
          this.setState({
            isLoading: false
          })
        }
        return {};
      })
    return response_data;
  }

  initAPi = async () => {
    this.getDisplayLabel();
    this.setState({ isLoading: true });
    await this.filterAPI();
    await this.getNewDashboardOfftake();
    this.getSchemes();
    await this.getWarrantyCounts();
    await this.getDashboardCounts('', true);
    await this.getEngageCustomer();
    await this.getGrowBusiness();
    await this.getRedeemptionDashbaord();
    await this.getDashBoardCountsMerchandise();
    await this.getBanner();
    await this.getVehicleType();
    await this.getAllCustomerService();
    await this.getSMSCredits();
    this.setState({ isLoading: false }, () => {
      // setTimeout(() => {
      //   this.setState({
      //     tooltipOpen: true
      //   })
      // }, 3000);
    });
    // if(localStorage.getItem('add_customer')){
    //   let data=JSON.parse(localStorage.getItem('add_customer'));
    //   let {controls}=this.state;
    //   if(data && data.phone_number){
    //   controls.phoneNumber.value=data.phone_number;
    //   this.setState({
    //     controls
    //   },()=>{
    //     this.sendVerifyOtp(true,true);
    //   }) 
    // }
    // }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.redirectDetailsAddCustomer !== this.props.redirectDetailsAddCustomer) {
      if (this.props.redirectDetailsAddCustomer && this.props.redirectDetailsAddCustomer.redirectToAddCustomer) {
        this.setState({
          couponData: this.props.redirectDetailsAddCustomer
        }, () => {
        });

        this.setState({
          opensidebar: false,
        }, () => {
          if (this.state.is_service_preference_set) {
            this.setState({
              opensidebar: true,
              tooltipOpen: false
            });
          } else {
            this.openSerivcePrefFromAddCustomer()
          }
        });
      }
      this.props.setAddCustomerRedirect(null);
    }
    if (prevProps.helpGuide !== this.props.helpGuide && !!this.props.helpGuide === true && this.props.helpGuide === 'customer_popover') {
      this.setState({
        tooltip_name: 'customer_popover', tooltipOpen: true
      })
    }
  }

  getSMSCredits = () => {

    CampaignService.getSMSCredits()
      .then((data) => {
        this.setState({
          sms_credits: data.data.data
        });
      })
      .catch((e) => {
      });
  };

  getDisplayLabel = () => {
    DashboardService.getDisplayLabel(new Date().getFullYear())
      .then(data => {
        const labels = data.data && data.data.data ? data.data.data : null;
        this.setState({ displayLabels: labels });
      })
      .catch(e => { });
  };

  getSchemes = () => {
    TradeSchemeService.getSchemes()
      .then(data => {
        const schemes = data.data && data.data.data ? data.data.data : [];
        let { schemes_year, hide_card } = this.state;
        if (schemes.length > 0) {
          schemes_year = schemes[0];
          const current_quarter = getCurrentQuarter(new Date());
          const current_year = getCurrentYear(new Date());
          if (schemes_year.quarter !== current_quarter && schemes.year !== current_year) {
            hide_card.trade_scheme_report = true;
          }
        } else {
          hide_card.trade_scheme_report = true;
        }
        this.setState({ schemes_year, hide_card }, () => {
          if (schemes && schemes.length > 0 && !hide_card.trade_scheme_report) {
            this.getDealerScheme();
          }
        });
      })
      .catch(e => {
        // const err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // ModalService.openAlert('', message, 'error');
        let { hide_card } = this.state;
        hide_card.trade_scheme_report = true;
        this.setState({
          hide_card
        })
      })
  }

  filterAPI = async () => {
    let filter = {
      y: null
    };
    await DashboardService.filterAPI(filter)
      .then(res => {
        if (res.data.data) {
          let { yearOption } = this.state;
          let yearOptions = res.data.data.year ? res.data.data.year : [];
          yearOption.year = yearOptions[0].value;
          yearOption.year_name = yearOptions[0].name;
          this.setState({
            yearOption
          })
        }
      })
      .catch(e => {
        console.log("err", e);
      });
  };

  getNewDashboardOfftake = async () => {
    let date = new Date()
    await DashboardService.getNewDashboardOfftake({ y: this.state.yearOption.year ? this.state.yearOption.year : date.getFullYear() })
      .then(res => {
        let { offtak_points } = this.state;
        if (res.data.data) {
          offtak_points = res.data.data;
        }
        this.setState({
          offtak_points
        });
        return true
      })
      .catch(e => {
        return false;
      });
  }



  getAllCustomerService = () => {
    CustomerManagementService.getAllCustomerService()
      .then(res => {
        let { services } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          let services_data = [];
          services.push({
            service_id: [],
            name: "All",
            service_active_by_dealer: true
          });
          res.data.data.forEach(element => {
            if (element.service_active_by_dealer) {
              services_data.push(element);
            }
          });
          services = [...services, ...services_data];
        } else {
          services = [];
        }
        this.setState({
          services
        })

      })
      .catch(e => {

        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;


      });
  }



  getVehicleType = () => {
    DashboardService.getVehicleType()
      .then(res => {
        let { vehicleTypeData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          vehicleTypeData = [];
          vehicleTypeData.push({
            vehicle_type_id: [],
            name: "All"
          })
          vehicleTypeData = [...vehicleTypeData, ...res.data.data];
        } else {
          vehicleTypeData = [];
        }
        this.setState({
          vehicleTypeData,
          selectedVehicle: []
        });
      })
      .catch(e => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;

      });
  };


  // sendOTP = async (is_from_customer_mgt) => {
  //   const { controls } = this.state;
  //   let { isSendOtp } = this.state;
  //   try {
  //     const { phoneNumber } = controls;
  //     if (phoneNumber.value) {
  //       isSendOtp = false;
  //       this.setState({ isLoading:true});
  //       const { data } = await DashboardService.sendOTP({ phone_number: phoneNumber.value });
  //       if (data && data.success) {
  //         isSendOtp = true;
  //         debugger

  //       }
  //       this.setState({ isSendOtp,isLoading:false});
  //     }
  //   } catch (error) {
  //     this.setState({ isLoading:false});
  //   }
  // }

  getDashBoardCountsMerchandise = () => {

    DashboardService.getDashBoardCountsMerchandise()
      .then(res => {
        let { merchandiseData } = this.state;
        if (res.data && res.data.data) {
          merchandiseData = res.data.data;
        }
        this.setState({
          merchandiseData
        });
      })
      .catch(e => {

      });
  }

  getWarrantyCounts = async () => {
    await DashboardService.getSecondaryCountsV2('all')
      .then(res => {
        let { warranty_data, hide_card, isClamRegistrationOpen, warranty_catalogue } = this.state;
        if (res.data.data && Object.keys(res.data.data).length > 0) {
          warranty_data = res.data.data.dashboardCount;
          warranty_catalogue = res.data.data.dashboardCount;
          isClamRegistrationOpen = res.data.data.isClamRegistrationOpen;
        } else {
          warranty_data = {
            customer_count: 0,
            pcr_wheeler_tyre: 0,
            total_points: 0,
            two_three_wheeler_tyre: 0,
            scv_tyre: 0,
            lcv_tyre: 0,
            sensor_count: 0,
            pcr_wheeler_tyre_with_sensor: 0,
            two_three_wheeler_tyre_with_sensor: 0,
            scv_tyre_with_sensor: 0,
            lcv_tyre_with_sensor: 0
          };
          warranty_catalogue = {
            customer_count: 0,
            pcr_wheeler_tyre: 0,
            total_points: 0,
            two_three_wheeler_tyre: 0
          };
        }


        if (warranty_catalogue.total_points === 0 || warranty_catalogue.total_points === '0') {
          hide_card.warranty_report = true;
        }
        this.setState({
          warranty_data,
          hide_card,
          warranty_catalogue
        })
      })
      .catch(e => {

      });
  }

  getBanner = async () => {

    await DashboardService.getBanner()
      .then(res => {
        let { bannerData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          bannerData = res.data.data;
          this.bannerViewClick({
            "uuid": res.data.data[0].uuid,
            "is_viewed": true
          });
        } else {
          bannerData = [];
        }
        this.setState({
          bannerData
        }, () => {
          if (this.sliderBanner.current) {
            this.sliderBanner.current.slickGoTo(0);
          }
        });
      })
      .catch(e => {
        console.log("err", e);
      });
  }

  afterChangeBanner = (index) => {
    let { bannerData } = this.state;
    if (index > -1) {
      if (bannerData[index] && bannerData[index].uuid) {
        this.bannerViewClick({
          "uuid": bannerData[index].uuid,
          "is_viewed": true,
        });
      }
    }
  }

  bannerViewClick = async (reqData) => {
    await DashboardService.bannerViewClick(reqData)
      .then(res => {

      })
      .catch(e => {
        console.log("err", e);
      });
  }


  getEngageCustomer = async () => {

    await DashboardService.getEngageCustomer()
      .then(res => {
        let { engage_customers } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          engage_customers = res.data.data;
        } else {
          engage_customers = [];
        }
        this.setState({
          engage_customers
        }, () => {
          if (this.sliderEngageCustomer.current) {
            this.sliderBanner.current.slickGoTo(0);
          }
        });
      })
      .catch(e => {
        console.log("err", e);
      });
  }

  getToDateWhileSending = (date) => {
    let dateToProcesss = Object.assign(date);
    let currentDate = dateToProcesss.getDate();
    dateToProcesss.setDate(currentDate + 1);
    dateToProcesss.setHours(5, 29, 59, 59);
    return dateToProcesss.toISOString();
  }


  getDashboardCounts = async (type = '', default_date = false) => {
    let reqData = {
      "vehicle_type_id": [],
      "purpose_type": "",
      "due_date_from": "",
      "due_date_to": "",
      "service_id": []
    }


    if (type === 'tyre_purchase') {
      if (this.state.selectedVehicle && type === 'tyre_purchase') {
        if (!Array.isArray(this.state.selectedVehicle)) {
          reqData.vehicle_type_id.push(this.state.selectedVehicle);
        }
      }
    }
    if (type === 'service') {
      if (this.state.selectedService && type === 'service') {
        if (!Array.isArray(this.state.selectedService)) {
          reqData.service_id.push(this.state.selectedService);
        }
      }
    }

    if (type) {
      reqData.purpose_type = type;
      let selectedDate = type === 'service' ? this.state.selectedDateService : this.state.selectedDate;
      if (selectedDate === 'Today') {
        reqData.due_date_from = new Date();
        reqData.due_date_to = new Date();
      } else if (selectedDate === '90') {
        let date_from = moment().subtract(90, "days").format("YYYY-MM-DD");
        let date_to = moment().add(90, "days").format("YYYY-MM-DD");
        reqData.due_date_from = new Date(date_from);
        reqData.due_date_to = new Date(date_to);
      } else if (selectedDate === '60') {
        let date_from = moment().subtract(60, "days").format("YYYY-MM-DD");
        let date_to = moment().add(60, "days").format("YYYY-MM-DD");
        reqData.due_date_from = new Date(date_from);
        reqData.due_date_to = new Date(date_to);
      } else if (selectedDate === '30') {
        let date_from = moment().subtract(30, "days").format("YYYY-MM-DD");
        let date_to = moment().add(30, "days").format("YYYY-MM-DD");
        reqData.due_date_from = new Date(date_from);
        reqData.due_date_to = new Date(date_to);
      } else if (selectedDate === '7') {
        let date_from = moment().subtract(7, "days").format("YYYY-MM-DD");
        let date_to = moment().add(7, "days").format("YYYY-MM-DD");
        reqData.due_date_from = new Date(date_from);
        reqData.due_date_to = new Date(date_to);
      }
      this.setState({ isLoading: true });
    } else {
      // reqData.due_date_from = new Date();
      // reqData.due_date_to = new Date();
    }

    if (default_date) {
      let date_from = moment().subtract(30, "days").format("YYYY-MM-DD");
      let date_to = moment().add(30, "days").format("YYYY-MM-DD");
      reqData.due_date_from = new Date(date_from);
      reqData.due_date_to = new Date(date_to);
    }
    if (reqData.due_date_from) {
      let date = new Date(reqData.due_date_from);
      date.setHours(5, 30, 0, 0);
      reqData.due_date_from = date.toISOString();
    }
    if (reqData.due_date_to) {
      let date = new Date(reqData.due_date_to);
      date.setHours(23, 59, 59, 59);
      reqData.due_date_to = this.getToDateWhileSending(reqData.due_date_to);
    }


    await DashboardService.getDashboardCounts(reqData)
      .then(res => {
        let { dashboardCount } = this.state;
        if (res.data.data && Object.keys(res.data.data).length > 0) {
          if (type === 'tyre_purchase') {
            dashboardCount.tyre_purchase_card = res.data.data.tyre_purchase_card;
          } else if (type === 'service') {
            dashboardCount.service_card = res.data.data.service_card;
          } else {
            dashboardCount = res.data.data;
          }
        }
        this.setState({
          dashboardCount
        });
        if (type) {
          this.setState({ isLoading: false });
        }
      })
      .catch(e => {
        console.log("err", e);
        if (type) {
          this.setState({ isLoading: false });
        }
      });
  }

  getRedeemptionDashbaord = async () => {


    await DashboardService.getRedeemptionDashbaord()
      .then(res => {
        let { redeemptionData, hide_card } = this.state;
        if (res.data.data && Object.keys(res.data.data).length > 0) {
          redeemptionData = res.data.data;
        } else {
          hide_card.redemption_catalogue = true;
        }
        this.setState({
          redeemptionData,
          hide_card
        });
      })
      .catch(e => {
        console.log("err", e);
        let { hide_card } = this.state;
        hide_card.redemption_catalogue = true;
        this.setState({
          hide_card
        })
      });
  }

  getGrowBusiness = async () => {
    await DashboardService.getGrowBusiness()
      .then(res => {
        let { grow_business_list_data } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          grow_business_list_data = res.data.data;
        } else {
          grow_business_list_data = [];
        }
        this.setState({
          grow_business_list_data
        }, () => {
          if (this.sliderGrowBuiness.current) {
            this.sliderGrowBuiness.current.slickGoTo(0);
          }
        });
      })
      .catch(e => {
        console.log("err", e);
      });
  }


  handleValidation = (firstTime = true, isSubmit = false) => {
    const { controls } = this.state;
    let isFormValid = false;
    let {
      phoneNumber,
      otp
    } = controls;
    if (!this.state.isSendOtp && !this.isFirstTimeValidate) {
      phoneNumber = Validation.notNullValidator(phoneNumber);
      phoneNumber = Validation.validatePhoneNumber(phoneNumber);
      phoneNumber.valid = !(phoneNumber.nullValue || phoneNumber.invalidPhone);
      if (!phoneNumber.valid === false && phoneNumber.value && phoneNumber.value.length === 10) {
        phoneNumber.showErrorMsg = false;
      } else {
        phoneNumber.showErrorMsg = true;
      }
    }
    if (this.state.isSendOtp && !this.isFirstTimeValidate) {
      otp = Validation.notNullValidator(otp);
      otp.valid = !otp.nullValue;
      otp.showErrorMsg = !otp.valid;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  }



  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (controlName === "phoneNumber") {
      if (!isNaN(e.target.value)) {
        controls[controlName].value = controlValue;
      }
    } else {
      controls[controlName].value = controlValue;
    }
    controls[controlName].touched = true;
    this.setState({ controls, errorMessageOtp: '' });
    this.handleValidation();
  }

  sendVerifyOtp = (isSendOtp, is_from_customer_mgt = false) => {
    this.isFirstTimeValidate = false;
    this.handleValidation();
    const { controls } = this.state;
    const {
      phoneNumber,
      otp
    } = controls;
    if (!phoneNumber.showErrorMsg && isSendOtp) {
      this.sendOTP(is_from_customer_mgt);
    }
    if (!phoneNumber.showErrorMsg && !otp.showErrorMsg && !isSendOtp) {
      this.verifyOTP();
    }
  }

  sendOTP = async (is_from_customer_mgt) => {
    const { controls } = this.state;
    let { isSendOtp } = this.state;
    GoogleAnalticsService.event('Add Warranty', 'Send OTP View');
    try {
      const { phoneNumber } = controls;
      if (phoneNumber.value) {
        isSendOtp = false;
        this.setState({ isLoading: true });
        const { data } = await WarrantyRegistrationService.sendOTP({ phone_number: phoneNumber.value });
        if (data && data.success) {
          if (is_from_customer_mgt) {
            ModalService.openAlert("Warranty Registration", 'OTP Send Successfuly', "success");
          }
          isSendOtp = true;
        }
        this.setState({ isSendOtp, isLoading: false });
      } else {
        ModalService.openAlert('', 'message', 'error');
      }

    } catch (error) {
      let err = error.response && error.response.data && error.response.data.error && error.response.data.error[0] ? error.response.data.error[0] : null;
      const message = err && err.message ? err.message : 'Something went wrong';
      ModalService.openAlert('', message, 'error');
      this.setState({ isLoading: false });
    }
  }





  redirectToWarrantyRegistantion = (phoneNumber, token) => {
    window.scroll(0, 0);
    if (this.state.couponData && this.state.couponData.phone_number && this.state.couponData.type) {
      this.props.history.push(
        `warranty-registration?phoneNumber=${phoneNumber}&t=${token}&couponCode=${this.state.couponData.coupon_code}&couponToken=${this.state.couponData.coupon_token}`
      );
    } else {
      this.props.history.push(
        `warranty-registration?phoneNumber=${phoneNumber}&t=${token}`
      );
    }
  };


  verifyOTP = async () => {
    let { controls } = this.state;
    const { phoneNumber, otp } = controls;
    GoogleAnalticsService.event('Add Warranty', 'Verify OTP View');
    try {
      if (otp.value) {
        this.setState({ isLoading: true });
        const { data } = await WarrantyRegistrationService.verifyOTP({ phone_number: phoneNumber.value, otp: otp.value });
        if (data && data.success) {
          this.setState({ isOtpVerified: true, isLoading: false });
          this.redirectToWarrantyRegistantion(phoneNumber.value, (data.data || {}).token);
        } else {
          this.setState({ isOtpVerified: false, errorMessageOtp: messages.WARRANTY_REGISTRATION.INVALIDOTP, isLoading: false });
        }

      }
    } catch (error) {
      this.setState({ isOtpVerified: false, errorMessageOtp: messages.WARRANTY_REGISTRATION.INVALIDOTP, isLoading: false });
    }

  }


  cancelOTP = () => {
    this.setState({ isSendOtp: false, errorMessageOtp: '' });
  }

  getDealerScheme = () => {
    TradeSchemeService.getDealerScheme(this.state.schemes_year.quarter, this.state.schemes_year.year)
      .then(data => {
        let { schemeReport, hide_card } = this.state;
        if (data.data.data) {
          schemeReport = data.data.data;
          if ((!schemeReport.qtd.target && !schemeReport.qtd.achievement &&
            !schemeReport.qtd.achievement)) {
            hide_card.trade_scheme_report = true;
          }
        }
        this.setState({ schemeReport });
      })
      .catch(e => {

      })
  }
  getSelectedVehicle = (id, item) => {
    this.setState({
      selectedVehicle: id
    }, () => {
      this.getDashboardCounts('tyre_purchase')
    });
  }

  getSelectedDate = (id, item) => {
    this.setState({
      selectedDateService: id
    }, () => {
      this.getDashboardCounts('service');
    });
  }


  getSelectedDateTyrePurchase = (id, item) => {
    this.setState({
      selectedDate: id
    }, () => {
      this.getDashboardCounts('tyre_purchase');
    });
  }

  getSelectedService = (id, item) => {
    this.setState({
      selectedService: id
    }, () => {
      this.getDashboardCounts('service')
    });
  }
  afterchange = (currentSlide) => {
    this.setState({
      currentSlideBanner: currentSlide
    })
  }

  afterchangeGrowBusiness = (currentSlide) => {
    this.setState({
      currentSlideGrowBusiness: currentSlide
    })
  }

  afterchangeEngageCustomerList = (currentSlide) => {
    this.setState({
      currentSlideEngageCustomer: currentSlide
    })
  }

  redirectCustomerMGT = (e, customer_mgt, type = '') => {
    e.preventDefault();
    if (Object.keys(customer_mgt).length > 0) {
      if (customer_mgt.vehicle_type_id && Array.isArray(customer_mgt.vehicle_type_id)) {
        customer_mgt.vehicle_type_id = 'all';
      }
      if (Array.isArray(customer_mgt.visitPurposeServiceDropDown)) {
        customer_mgt.visitPurposeServiceDropDown = null;
      }
      this.props.setDealerDashboardRedirectDetail(
        {
          customer_mgt
        }
      );
    } else {
      this.props.setDealerDashboardRedirectDetail(null);
    }
    if (type) {
      GoogleAnalticsService.event('New Dashboard', type + ' Click');
    }
    this.props.history.push(
      `customer-management`
    );
  }

  redirectToCampaign = (data, card_name) => {
    if (card_name === 'grow_business') {
      GoogleAnalticsService.event('New Dashboard', ' Purpose Name + Click');
    }
    let reqData = {
      campaign: data
    }
    this.props.setDealerDashboardRedirectDetail(
      reqData
    );
    this.props.history.push(
      `/campaign/create`
    );
  }

  redirectToRedemptionCatalog = () => {
    GoogleAnalticsService.event('New Dashboard', 'Redemption Catalogue Click');
    this.props.history.push(
      `redemption-catalog`
    );
  }


  redirectToMerchandiseWallet = () => {
    GoogleAnalticsService.event('New Dashboard', 'Merchandise Catalogue Click');
    this.props.history.push(
      `merchandise-wallet`
    );
  }


  redirectToWarrantyScoreCard = (year) => {
    GoogleAnalticsService.event('New Dashboard', 'Warranty Card Click');
    this.props.history.push(
      // `warranty-score-card?y=${year}`
      `warranty-score-card?y=all`
    );
  }


  redirectToTradeScheme = () => {
    GoogleAnalticsService.event('New Dashboard', 'PCR Scheme Click');
    this.props.history.push(
      `trade-scheme?q=${this.state.schemes_year.quarter}&y=${this.state.schemes_year.year}`
    );
  }

  redirectToDashboard = () => {
    GoogleAnalticsService.event('New Dealer Dashboard', 'Offtake Card Click');
    GoogleAnalticsService.event('New Dashboard', 'Warranty Card Click');
    this.props.history.push(
      `/?is_from_v2_dashbaord=true`
    );
  }

  openRedeemCouponModel = () => {
    this.setState({ isRedeemCouponOpen: true });
  }

  redirectFromBanner = (item) => {
    if (item.action) {
      let path_data = getAction(item.action);
      if (typeof path_data === 'string') {
        this.props.history.push(path_data);
      } else {
        if (path_data.isRedeemCouponOpen) {
          localStorage.setItem('redirectDetails', JSON.stringify(path_data));
          this.props.history.push(null);
        } else {
          this.props.setRedirect(path_data);
          if (path_data.pathname) {
            this.props.history.push(path_data.pathname);
          }
        }
      }
      GoogleAnalticsService.event('New Dashboard', 'Banner Click');
      this.bannerViewClick({
        "uuid": item.uuid,
        "is_clicked": true
      })
    }
  }

  closeAddCustomer = (obj = {}) => {
    if (obj && Object.keys(obj).length > 0) {
      if (obj.is_warranty_redirect) {
        if (localStorage.getItem('add_customer')) {
          let data = JSON.parse(localStorage.getItem('add_customer'));
          let { controls } = this.state;
          if (data && data.phone_number) {
            controls.phoneNumber.value = data.phone_number;
            this.setState({
              controls
            }, () => {
              this.sendVerifyOtp(true, true);
            })
          }
        }
      } else if (obj.refresh_list) {
        this.getDashboardCounts('');
      }
    }
    this.setState({
      opensidebar: false,
    });
  };




  redirectToWarrantyReplacement = (phoneNumber, token) => {
    window.scroll(0, 0);
    this.props.history.push(
      `warranty-replacement?phoneNumber=${phoneNumber}&t=${token}`
    );
  };

  closeSendVerifyOtp = (phoneNumber, token) => {
    if (phoneNumber) {
      this.redirectToWarrantyReplacement(phoneNumber, token);
    }
  };

  openSendVerifyOtp = () => {
    const wrapper = document.createElement("div");
    ReactDOM.render(
      <SendVerifyOtp closeSendVerifyOtp={this.closeSendVerifyOtp} />,
      wrapper
    );
    const el = wrapper.firstChild;
    swal({
      text: "",
      content: el,
      buttons: false,
      className: "send-verify-otp-modal",
      closeOnClickOutside: false,
      closeOEscapeKey: false,
      closeOnConfirm: false,
    }).then((value) => {
    });
  };

  closeRedeemCouponModel = () => {
    this.setState({ isRedeemCouponOpen: false });
  };

  previewImage = (item) => {
    this.setState({
      imagesToPreview: [item.image_url],
      isPreviewImageLightBoxOpen: true
    })
  }
  closePreviewImageLightBox = () => {
    this.setState({
      isPreviewImageLightBoxOpen: false
    });
  }
  handleClickOutside = (event) => {
    // if (this.dealerPopOver) {
    //   const domNode = ReactDOM.findDOMNode(this.dealerPopOver);
    //   if (!domNode || !domNode.contains(event.target)) {
    //     if (this.state.tooltipOpen) {
    //       this.setState({
    //         tooltipOpen: false
    //       })
    //     }
    //   }
    // }
  }
  openSetPref = () => {
    this.refs.modalServicePref.toggle({ is_from_add_customer_visit: false });
  }
  render() {
    let { engage_customers, grow_business_list_data,
      warranty_data, dashboardCount, bannerData, merchandiseData, controls,
      offtak_points, imagesToPreview, userDetail,
      is_service_preference_set,
      schemeReport, isPreviewImageLightBoxOpen, warranty_catalogue, isSpecialEngagementModalOpen } = this.state;
    // let bannerRefArrow = {};
    // const CustomRightArrow = ({ onClick, ...rest }) => {
    //   if (rest.type && rest.type === 'banner_carousel') {
    //     bannerRefArrow = rest.carouselState;
    //   }
    //   const {
    //     onMove,
    //     carouselState: { currentSlide, deviceType }
    //   } = rest;
    //   // onMove means if dragging or swiping in progress.
    //   return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={() => onClick()} />;
    // };
    // const CustomLeftArrow = ({ onClick, ...rest }) => {
    //   const {
    //     onMove,
    //     carouselState: { currentSlide, deviceType }
    //   } = rest;
    //   // onMove means if dragging or swiping in progress.
    //   return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" onClick={() => onClick()} />;
    // };



    let banner_list_item =
      bannerData.map((item, i) => (
        <div className="div-banner">
          <Card className="card-box-shadow-10px he-100 border-radius-8px">
            <CardBody style={{
              backgroundColor: item.background_color,
              backgroundImage: (item.is_gradient ? `linear-gradient(to right, ${item.background_color}, ${item.gradient_color})` : item.background_color),
              padding: '10px',
              minHeight: '100px'
            }}
              className={item.action ? 'cursor-pointer d-flex' : 'd-flex'}
              onClick={() => this.redirectFromBanner(item)}
            >
              {
                item.banner_type === 'picture' ?
                  <img src={item.image_url}
                    alt=""
                    onClick={() => this.previewImage(item)}
                    className="dealer-banner-img m-auto dashboard-banner-full-image cursor-pointer" />
                  :
                  <>
                    {item.image_url && <img src={item.image_url}
                      alt=""
                      className={item.title || item.description ? 'dealer-banner-img cursor-pointer' : 'dealer-banner-img m-auto cursor-pointer'}
                      onClick={() => this.previewImage(item)}
                      style={{ height: '100%', display: 'inline-block' }} />}
                    <div className="d-flex-column mt-auto mb-auto ml-2 mr-2 justify-content-center">
                      {
                        item.content_url ?
                          <a href={item.content_url} target="_blank" rel="noopener noreferrer" className="d-flex-column mt-auto mb-auto">
                            <span className="dealer-banner-title"
                              style={{
                                fontWeight: item.is_title_bold ? 'bold' : 'normal',
                                color: item.title_color ? item.title_color : '#000',
                              }}>
                              {item.title}
                            </span>

                            <span className="dealer-banner-desc mt-2"
                              style={{
                                color: item.description_color ? item.description_color : '#000',
                                fontWeight: item.is_description_bold ? 'bold' : 'normal'
                              }}
                            >
                              {item.description}
                            </span>
                          </a> :
                          <>
                            <span className="dealer-banner-title"
                              style={{
                                fontWeight: item.is_title_bold ? 'bold' : 'normal',
                                color: item.title_color ? item.title_color : '#000',
                              }}>
                              {item.title}
                            </span>

                            <span className="dealer-banner-desc mt-2"
                              style={{
                                color: item.description_color ? item.description_color : '#000',
                                fontWeight: item.is_description_bold ? 'bold' : 'normal'
                              }}
                            >
                              {item.description}
                            </span>
                          </>
                      }
                      {/* </div> */}
                    </div>
                  </>

              }
            </CardBody>
          </Card>
        </div>
      ));

    let grow_business_list = '';
    if (grow_business_list_data.length > 0) {
      grow_business_list = grow_business_list_data.map((item, i) => (
        <div className="d-flex-column grow_business_list_data" onClick={() => this.redirectToCampaign(item, 'grow_business')}>
          <div className="grow-business-div">
            <div className="d-flex-row">
              <img src={item.image_url} alt="" className="grow-business-img ml-auto mr-auto mt-2" />
              {
                item.count > 0 && <span className="notification-oval"></span>
              }
            </div>
            <span className="mt-2 grow-business-desc">
              {item.name}
            </span>
          </div>
        </div>
      ));
    }

    let engage_customers_list = '';
    if (engage_customers.length > 0) {
      engage_customers_list = engage_customers.map((item, i) => (
        <div className="d-flex-column grow_business_list_data" onClick={() => this.redirectToCampaign(item, 'engage_customers')}>
          <div className="grow-business-div">
            <div className="d-flex-row">
              <img src={item.image_url} alt="" className="grow-business-img ml-auto mr-auto mt-2" />
              {
                item.count > 0 && <span className="notification-oval"></span>
              }
            </div>
            <span className="mt-2 grow-business-desc">
              {item.name}
            </span>
          </div>
        </div>
      ));
    }
    let photoIndex = 0;
    return (
      <div id="dealer-dashboard" style={{ position: 'relative' }} onClick={this.handleClickOutside}>
        {(this.state.isLoading && !this.state.opensidebar) && <CustomSpinner />}
        {isSpecialEngagementModalOpen && (
          <SpeacialEngagement
            show={isSpecialEngagementModalOpen}
            // popupDetail={popupDetail}
            closeModal={this.closeSpecialEngagementModal}
          />
        )}
        <div className="d-flex-column">
          <div className="page-header" style={{ marginLeft: '0px' }}>Dashboard <img className="mt-auto mb-auto ml-2 cursor-pointer" src="/images/info_white_background.svg" alt=""
            style={{ height: '16px' }} onClick={() => this.reOpenToolTipPopOver('add_visit_history_popover')} /></div>
          {/* <span className="mt-2 Showing-Counts-for-F" style={{ marginLeft: '0px' }}>Showing Counts for FY {this.state.yearOption.year_name}</span> */}
        </div>
        <ModalServicePref
          from="dealer-dashboard"
          getUserDetail={this.getUserDetail}
          openAddCustomer={this.openAddCustomer}
          is_first_service_pref={true}
          ref="modalServicePref" />
        <>
          {isPreviewImageLightBoxOpen && (
            <Lightbox
              mainSrc={imagesToPreview[photoIndex]}
              nextSrc={0}
              prevSrc={0}
              onCloseRequest={this.closePreviewImageLightBox}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (photoIndex + imagesToPreview.length - 1) %
                    imagesToPreview.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % imagesToPreview.length
                })
              }
            />
          )}
          {this.state.isRedeemCouponOpen && (
            <RedeemCoupon
              ShowDownloadScorcard={this.state.isRedeemCouponOpen}
              closeModal={this.closeRedeemCouponModel}
              openModel={true}
            />
          )}
          {this.state.opensidebar && (
            <div style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              right: "-30px"
            }}>
              <Sidebar
                sidebar={
                  <>
                    <AddCustomer
                      ref={this.childCustomiseDetail}
                      resetFilter={this.state.CampaignDetailData}
                      CampaignDetailData={this.state.CampaignDetailData}
                      onSelectTargetGroup={this.changeTargetGroup}
                      getFilterControls={this.state.filterControls}
                      closeAddCustomer={this.closeAddCustomer}
                      couponData={this.state.couponData}
                    />
                  </>
                }
                open={true}
                sidebarClassName="campaign-drawer"
                pullRight
                styles={{
                  sidebar: {
                    background: "white",
                    height: this.state.height ? this.state.height : "90%",
                    top: "60px",
                    width: "47%",
                    borderRadius: "2px",
                  },
                }}
              />
            </div>
          )}
        </>
        <Row className="ml-0 mr-0">
          {
            !is_service_preference_set &&
            <Col xl="12" md="12" xs="12" id="not-set-pref" className="d-flex mt-2">
              <div className="setting-icon-wrapp d-flex">
                <img src="/images/setting_white.png" alt="" className="m-auto" />
              </div>
              <div className="d-flex justify-content-between w-100 pr-2">
                <div className="mt-auto mb-auto ml-2">
                  <span className="owner-name">
                    Welcome,{userDetail.owner_name ? userDetail.owner_name : 'User'}
                  </span>
                  <span className="to-enable ml-2">
                    To enable the “Add Customer Visit” Feature, Please set your preferences.
                  </span>
                </div>
                <div className="click-here mt-auto mb-auto cursor-pointer"
                  onClick={() => this.openSetPref()}>
                  Click Here to Setup your preferences
                </div>
              </div>
            </Col>
          }
          <Col xl="9" md="12" xs="12" className="pl-7px" style={{ maxWidth: '73%' }}>
            <Row>
              <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                <Card id="offtake-popover"
                  className={this.state.tooltipOpen && this.state.tooltip_name === 'offtake-popover' ? 'backgrond-image-f8e184 he-100 box-shadow-40px-0-5' : 'card-box-shadow-10px backgrond-image-f8e184 he-100'}
                  onClick={() => this.redirectToDashboard()}>
                  <CardBody className="d-flex-column padding-13px">
                    <div className="d-flex-row justify-content-between">
                      <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>Offtake Points</span>
                      {this.state.yearOption.year_name && <span className="Apr---Jun209">(FY {this.state.yearOption.year_name})</span>}
                    </div>
                    <table style={{ border: 'none' }} className="mt-auto mb-auto">
                      <tr style={{ border: 'none' }}>
                        <td className="border-right-E1CD7B">
                          <div className="d-flex-column pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>Total points</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{
                              offtak_points.points ? formatNumber(offtak_points.points) : 0
                            }</b></span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex-column pl-3 pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>Total Offtake</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>
                              {offtak_points.total_offtake ? formatNumber(offtak_points.total_offtake) : 0}
                            </b></span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex-column pl-2 pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>Current Quarter</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>
                              {offtak_points.quarter_total_offtake ? formatNumber(offtak_points.quarter_total_offtake) : 0}
                            </b></span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex-column pl-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>Month</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>
                              {offtak_points.month_total_offtake ? formatNumber(offtak_points.month_total_offtake) : 0}
                            </b></span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </CardBody>
                </Card>
                <UncontrolledPopover
                  placement={'right-start'}
                  hideArrow={false}
                  isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'offtake-popover'}
                  fade={true}
                  target={'offtake-popover'}
                  trigger="focus"
                  className='dealer-popover'
                >
                  <div ref={r => this.dealerPopOver = r}>
                    <PopoverHeader className="popover-title d-flex justify-content-between">
                      <span>Help Guide Tour</span>
                      <span>5/7</span>
                    </PopoverHeader>
                    <PopoverBody className="d-flex-column">
                      <span className="popover-lbl mt-2">Offtake and Points Dashboard</span>
                      <span className="popover-lbl mt-2">Total Points:</span>
                      <span className="popover-lbl-value mt-2">
                        Total points earned as per JK Advantage Policy for current financial year, across products
                      </span>
                      <span className="popover-lbl mt-2">Offtake:</span>
                      <span className="popover-lbl-value mt-2">
                        Offtake figures are available for entire year, current quarter and current month for current financial year
                      </span>
                      <br />
                      <div className="d-flex-row justify-content-between mb-2">
                        <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'campagin-popover' })}>Previous</span>
                        <span className="link-blue cursor-pointer font-size-14px font-weight-bold" onClick={() => this.setState({
                          tooltipOpen: true,
                          tooltip_name: 'warranty-popover'
                        })}>Next</span>
                      </div>
                    </PopoverBody>
                  </div>
                </UncontrolledPopover>
              </Col>
              <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                <Card id="customer_popover"
                  className={this.state.tooltipOpen && this.state.tooltip_name === 'customer_popover' ? 'backgrond-image-4270f3 he-100 box-shadow-40px-0-5' : 'card-box-shadow-10px backgrond-image-4270f3 he-100'}
                  style={{ cursor: 'default' }}>
                  <CardBody className="d-flex-column padding-13px">
                    <span className='margin-bottom-13px dealer-dashobard-card-title mb-2'>Total Customers</span>
                    <div className="d-flex">
                      <div className="total-customer-small-lbl cursor-pointer" style={{ marginLeft: '0' }} onClick={(e) => this.redirectCustomerMGT(e, {}, 'Total Customer')}>
                        <span className="mt-auto mb-auto font-weight-normal" style={{ marginLeft: '10px' }}>Total <b className="font-size-14px color-ffffff">
                          {dashboardCount.customer_card.total_customer ? dashboardCount.customer_card.total_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl cursor-pointer" onClick={(e) => this.redirectCustomerMGT(e, { date_of_customer_registration: 'today' }, 'Today Customer')}>
                        <span className="mt-auto mb-auto font-weight-normal">Today <b className="font-size-14px color-ffffff">
                          {dashboardCount.customer_card.today_customer ? dashboardCount.customer_card.today_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl cursor-pointer" onClick={(e) => this.redirectCustomerMGT(e, { date_of_customer_registration: 'month' }, 'Month Customer')}>
                        <span className="mt-auto mb-auto font-weight-normal">Month <b className="font-size-14px color-ffffff">
                          {dashboardCount.customer_card.current_month_customer ? dashboardCount.customer_card.current_month_customer : 0}
                        </b></span>
                      </div>
                    </div>
                    <span className='margin-bottom-13px dealer-dashobard-card-title mt-2 mb-2'
                      style={{ fontSize: '13px' }}>Referral Customers</span>
                    <div className="d-flex">
                      <div className="total-customer-small-lbl cursor-pointer" style={{ marginLeft: '0' }} onClick={(e) => this.redirectCustomerMGT(e, { sourceDropDown: 'reference', }, 'Total Referance')}>
                        <span className="mt-auto mb-auto font-weight-normal" style={{ marginLeft: '10px' }}>Total <b className="font-size-14px color-ffffff">
                          {dashboardCount.reference_card.total_customer ? dashboardCount.reference_card.total_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl cursor-pointer" onClick={(e) => this.redirectCustomerMGT(e, { date_of_customer_registration: 'today', sourceDropDown: 'reference' }, 'Today Referance')}>
                        <span className="mt-auto mb-auto font-weight-normal">Today <b className="font-size-14px color-ffffff">
                          {dashboardCount.reference_card.today_customer ? dashboardCount.reference_card.today_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl cursor-pointer" onClick={(e) => this.redirectCustomerMGT(e, { date_of_customer_registration: 'month', sourceDropDown: 'reference' }, 'Month Referance')}>
                        <span className="mt-auto mb-auto font-weight-normal">Month <b className="font-size-14px color-ffffff">
                          {dashboardCount.reference_card.current_month_customer ? dashboardCount.reference_card.current_month_customer : 0}
                        </b></span>
                      </div>
                    </div>
                    {/* <div className="total-customer-small-lbl ml-0 w-100 cursor-pointer" style={{ marginTop: '12px', cursor: 'default' }}
                      onClick={(e) => this.redirectCustomerMGT(e, { sourceDropDown: 'reference' })}>
                      <span className="mt-auto mb-auto font-weight-normal" style={{ marginLeft: '10px' }}>Referral Customer <b className="font-size-14px color-ffffff">
                        {dashboardCount.reference_card.total_customer ? dashboardCount.reference_card.total_customer : 0}
                      </b></span>
                    </div> */}
                  </CardBody>
                </Card>
                <UncontrolledPopover
                  placement={'right-start'}
                  hideArrow={false}
                  isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'customer_popover'}
                  fade={true} target={'customer_popover'}
                  trigger="focus"
                  className="dealer-popover"
                >
                  <div ref={r => this.dealerPopOver = r}>
                    <PopoverHeader className="popover-title d-flex justify-content-between">
                      <span>Help Guide Tour</span>
                      <span>2/7</span>
                    </PopoverHeader>
                    <PopoverBody className="d-flex-column">
                      <span className='popover-lbl mt-2'>Dashboard of customers and Leads</span>
                      <span className="popover-lbl mt-2">Total Customers:</span>
                      <span className="popover-lbl-value mt-2">
                        Displays count of total customer, today's visitors and visitors for this month
                      </span>
                      <span className="popover-lbl mt-3">Referral Customers:</span>
                      <span className="mt-2 popover-lbl-value">
                        Total references received by the customers, references for the day and for the month
                        <br />
                        Higher the reference count means more happy customers
                      </span>
                      <br />
                      <div className="d-flex-row justify-content-between mb-2">
                        <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() => this.reOpenToolTipPopOver('add_visit_history_popover')}>Previous</span>
                        <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'tyre-purchase-popover' })}>Next</span>
                      </div>
                    </PopoverBody>
                  </div>
                </UncontrolledPopover>
              </Col>

              <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                <Card id="warranty-popover"
                  className={this.state.tooltipOpen && this.state.tooltip_name === 'warranty-popover' ? 'card-box-shadow-10px he-100 border-radius-8px box-shadow-40px-0-5' : 'card-box-shadow-10px he-100 border-radius-8px card-box-shadow-hover-30px'}
                  onClick={() => this.redirectToWarrantyScoreCard(this.state.yearOption.year)}>
                  <CardBody className="d-flex-column padding-13px">
                    <div className="d-flex-row justify-content-lg-between">
                      <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>Warranty Points - {warranty_data.total_points}</span>
                      {this.state.yearOption.year_name && <span className="Apr---Jun209">Apr’19 to Till Now</span>}
                    </div>
                    <Row>
                      <Col xl="4" md="4" xs="12">
                        <div className="d-flex-row justify-content-lg-between">
                          <span className='dealer-dashobard-card-sbtitle' style={{ color: '#495057' }}>Total Registrations</span>
                        </div>
                        <Row style={{ marginTop: "10px" }}>
                          <Col xl="6" md="6" xs="12">
                            <div className="d-flex-row justify-content-lg-between">
                              <Row>
                                <Col xl="12" md="12" xs="12">
                                  <span className='dealer-dashobard-card-text' style={{ color: '#495057' }}>Customers</span>
                                </Col>
                                <Col xl="12" md="12" xs="12" style={{ marginTop: "10px" }}>
                                  <span className="dealer-card-point mt-2" style={{ fontSize: '16px' }}><b>{warranty_data.customer_count || 0}</b></span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col xl="6" md="6" xs="12">
                            <div className="d-flex-row justify-content-lg-between">
                              <Row>
                                <Col xl="12" md="12" xs="12">
                                  <span className='dealer-dashobard-card-text' style={{ color: '#495057' }}>Sensors</span>
                                </Col>
                                <Col xl="12" md="12" xs="12" style={{ marginTop: "10px" }}>
                                  <span className="dealer-card-point mt-2" style={{ fontSize: '16px' }}><b>{warranty_data.sensor_count || 0}</b></span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl="8" md="8" xs="12" className='border-left-ECEDEE'>
                        <div className="d-flex-row justify-content-lg-between">
                          <span className='dealer-dashobard-card-sbtitle' style={{ color: '#495057' }}>Tyres</span>
                        </div>
                        <Row style={{ marginTop: "10px" }}>
                          <Col xl="3" md="3" xs="12">
                            <div className="d-flex-row justify-content-lg-between">
                              <Row>
                                <Col xl="12" md="12" xs="12">
                                  <span className='dealer-dashobard-card-text' style={{ color: '#495057' }}>PCR</span>
                                </Col>
                                <Col xl="12" md="12" xs="12" style={{ marginTop: "10px" }}>
                                  <span className="dealer-card-point mt-2" style={{ fontSize: '16px' }}><b>{warranty_data.pcr_wheeler_tyre || 0}</b></span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col xl="3" md="3" xs="12">
                            <div className="d-flex-row justify-content-lg-between">
                              <Row>
                                <Col xl="12" md="12" xs="12" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                                  <span className='dealer-dashobard-card-text' style={{ color: '#495057' }}>2 Wheeler</span>
                                </Col>
                                <Col xl="12" md="12" xs="12" style={{ paddingRight: "5px", paddingLeft: "5px", marginTop: "10px" }}>
                                  <span className="dealer-card-point mt-2" style={{ fontSize: '16px' }}><b>{warranty_data.two_three_wheeler_tyre || 0}</b></span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col xl="3" md="3" xs="12">
                            <div className="d-flex-row justify-content-lg-between">
                              <Row>
                                <Col xl="12" md="12" xs="12">
                                  <span className='dealer-dashobard-card-text' style={{ color: '#495057' }}>LCV</span>
                                </Col>
                                <Col xl="12" md="12" xs="12" style={{ marginTop: "10px" }}>
                                  <span className="dealer-card-point mt-2" style={{ fontSize: '16px' }}><b>{warranty_data.lcv_tyre || 0}</b></span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col xl="3" md="3" xs="12">
                            <div className="d-flex-row justify-content-lg-between">
                              <Row>
                                <Col xl="12" md="12" xs="12">
                                  <span className='dealer-dashobard-card-text' style={{ color: '#495057' }}>SCV</span>
                                </Col>
                                <Col xl="12" md="12" xs="12" style={{ marginTop: "10px" }}>
                                  <span className="dealer-card-point mt-2" style={{ fontSize: '16px' }}><b>{warranty_data.scv_tyre || 0}</b></span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* <table style={{ border: 'none' }} className="mt-auto mb-auto">
                      <tr style={{ border: 'none' }}>
                        <td>
                          <div className="d-flex-column pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>Total points</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{warranty_data.total_points || 0}</b></span>
                          </div>
                        </td>
                        <td className="border-right-ECEDEE">
                          <div className="d-flex-column pl-3 pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>Total Customer</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{warranty_data.customer_count || 0}</b></span>

                          </div>
                        </td>
                        <td>
                          <div className="d-flex-column pl-3 pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>PCR Tyre</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{warranty_data.pcr_wheeler_tyre || 0}</b></span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex-column pl-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>2 Wheeler </span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{warranty_data.two_three_wheeler_tyre || 0}</b></span>
                          </div>
                        </td>
                      </tr>
                    </table> */}
                  </CardBody>
                </Card>
                <UncontrolledPopover
                  placement={'right-start'}
                  hideArrow={false}
                  isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'warranty-popover'}
                  fade={true}
                  target={'warranty-popover'}
                  trigger="focus"
                  className='dealer-popover'
                >
                  <PopoverHeader className="popover-title d-flex justify-content-between">
                    <span>Help Guide Tour</span>
                    <span>6/7</span>
                  </PopoverHeader>
                  <PopoverBody className="d-flex-column">
                    <span className="popover-lbl mt-2">Warranty Dashboard</span>
                    <span className="popover-lbl mt-2">Total Points:</span>
                    <span className="popover-lbl-value mt-2">
                      Total points earned on registering PCR and 2W Warranty Registrations through JK Advantage App from April'19
                    </span>
                    <span className="popover-lbl mt-2">Total Customer:</span>
                    <span className="popover-lbl-value mt-2">
                      Number of customer registered warranty on tyre purchase
                    </span>
                    <span className="popover-lbl mt-2">PCR Tyre:</span>
                    <span className="popover-lbl-value mt-2">
                      Total PCR tyre registered with warranty till date
                    </span>
                    <span className="popover-lbl mt-2">2 Wheeler:</span>
                    <span className="popover-lbl-value mt-2">
                      Total 2 wheeler tyre registered with warranty till date
                    </span>
                    <br />
                    <div className="d-flex-row justify-content-between mb-2">

                      <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                        onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'offtake-popover' })}>Previous</span>
                      <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                        onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'dealer-dashboard' })}>Next</span>
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>

              {/* <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                <Card id="warranty-popover"
                  className={this.state.tooltipOpen && this.state.tooltip_name === 'warranty-popover' ? 'card-box-shadow-10px he-100 border-radius-8px box-shadow-40px-0-5' : 'card-box-shadow-10px he-100 border-radius-8px card-box-shadow-hover-30px'}
                  onClick={() => this.redirectToWarrantyScoreCard(this.state.yearOption.year)}>
                  <CardBody className="d-flex-column padding-13px">
                    <div className="d-flex-row justify-content-lg-between">
                      <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>Warranty</span>
                      {this.state.yearOption.year_name && <span className="Apr---Jun209">Apr’19 to Till Now</span>}
                    </div>
                    <table style={{ border: 'none' }} className="mt-auto mb-auto">
                      <tr style={{ border: 'none' }}>
                        <td>
                          <div className="d-flex-column pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>Total points</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{warranty_data.total_points || 0}</b></span>
                          </div>
                        </td>
                        <td className="border-right-ECEDEE">
                          <div className="d-flex-column pl-3 pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>Total Customer</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{warranty_data.customer_count || 0}</b></span>

                          </div>
                        </td>
                        <td>
                          <div className="d-flex-column pl-3 pr-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>PCR Tyre</span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{warranty_data.pcr_wheeler_tyre || 0}</b></span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex-column pl-2">
                            <span className="dealer-card-point" style={{ fontSize: '13px' }}>2 Wheeler </span>
                            <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>{warranty_data.two_three_wheeler_tyre || 0}</b></span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </CardBody>
                </Card>
                <UncontrolledPopover
                  placement={'right-start'}
                  trigger="legacy"
                  hideArrow={false}
                  isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'warranty-popover'}
                  fade={true}
                  target={'warranty-popover'}
                  trigger="focus"
                  className='dealer-popover'
                >
                  <PopoverHeader className="popover-title d-flex justify-content-between">
                    <span>Help Guide Tour</span>
                    <span>6/7</span>
                  </PopoverHeader>
                  <PopoverBody className="d-flex-column">
                    <span className="popover-lbl mt-2">Warranty Dashboard</span>
                    <span className="popover-lbl mt-2">Total Points:</span>
                    <span className="popover-lbl-value mt-2">
                      Total points earned on registering PCR and 2W Warranty Registrations through JK Advantage App from April'19
                    </span>
                    <span className="popover-lbl mt-2">Total Customer:</span>
                    <span className="popover-lbl-value mt-2">
                      Number of customer registered warranty on tyre purchase
                    </span>
                    <span className="popover-lbl mt-2">PCR Tyre:</span>
                    <span className="popover-lbl-value mt-2">
                      Total PCR tyre registered with warranty till date
                    </span>
                    <span className="popover-lbl mt-2">2 Wheeler:</span>
                    <span className="popover-lbl-value mt-2">
                      Total 2 wheeler tyre registered with warranty till date
                    </span>
                    <br />
                    <div className="d-flex-row justify-content-between mb-2">

                      <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                        onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'offtake-popover' })}>Previous</span>
                      <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                        onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'dealer-dashboard' })}>Next</span>
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </Col> */}

              <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                <Card id='tyre-purchase-popover' className={this.state.tooltipOpen && this.state.tooltip_name === 'tyre-purchase-popover' ? 'he-100 border-radius-8px box-shadow-40px-0-4' : 'card-box-shadow-10px he-100 border-radius-8px'}>
                  <CardBody className="d-flex-column padding-13px">
                    <div className="d-flex-row margin-bottom-5px justify-content-between">
                      <span className='dealer-dashobard-card-title color-495057'>Tyre Purchase</span>
                      <CustomDropDown
                        option_lbl="name"
                        option_key="vehicle_type_id"
                        selected_dropdown_item={this.state.selectedVehicle}
                        dropdown_list={this.state.vehicleTypeData}
                        placeholder="Select Vehicle Type"
                        minWidth="150px"
                        setSelectedItem={this.getSelectedVehicle}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer" style={{ marginLeft: '0', backgroundColor: '#ffffff' }}
                        onClick={(e) => this.redirectCustomerMGT(e, {
                          visitPurposeVisitPurposeDropDown: 'tyre_purchase',
                          vehicle_type_id: this.state.selectedVehicle,
                          date_of_customer_registration: 'total'
                        }, 'Total Tyre Purchase')}>
                        <span className="mt-auto mb-auto font-weight-normal" style={{ marginLeft: '10px', cursor: 'pointer' }}>Total <b className="font-size-14px">
                          {dashboardCount.tyre_purchase_card.total_customer ? dashboardCount.tyre_purchase_card.total_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer" style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
                        onClick={(e) => this.redirectCustomerMGT(e, {
                          date_of_customer_registration: 'today',
                          vehicle_type_id: this.state.selectedVehicle,
                          visitPurposeVisitPurposeDropDown: 'tyre_purchase'
                        }, 'Today Tyre Purchase')}>
                        <span className="mt-auto mb-auto font-weight-normal">Today <b className="font-size-14px">
                          {dashboardCount.tyre_purchase_card.today_customer ? dashboardCount.tyre_purchase_card.today_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer" style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
                        onClick={(e) => this.redirectCustomerMGT(e, {
                          date_of_customer_registration: 'month',
                          vehicle_type_id: this.state.selectedVehicle, visitPurposeVisitPurposeDropDown: 'tyre_purchase'
                        }, 'Month Tyre Purchase')}>
                        <span className="mt-auto mb-auto font-weight-normal">Month <b className="font-size-14px">
                          {dashboardCount.tyre_purchase_card.current_month_customer ? dashboardCount.tyre_purchase_card.current_month_customer : 0}
                        </b></span>
                      </div>
                    </div>
                    <div className="d-flex mt-2 mb-2">
                      <span className='dealer-dashobard-card-title color-495057 font-size-13px'>
                        Prospect For Tyre Replacement
                        <img src="/images/orange-info.png" alt="" className="ml-1 cursor-pointer" data-tip data-for={'tooltip-tyre-replacement'} />
                        <ReactTooltip id={'tooltip-tyre-replacement'} className="customer-list-tooltip" effect="solid">
                          Based on Data Analysis. View the Tyre Change
                          prospects for a period of time.
                        </ReactTooltip>
                      </span>
                    </div>
                    <div className="d-flex">
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer
                      pr-2" style={{ marginLeft: '0', width: 'auto', backgroundColor: '#ffffff' }}
                        onClick={(e) => this.redirectCustomerMGT(e, {
                          upcomingDue: this.state.selectedDate,
                          mainFilterRadio: 'all_due',
                          vehicle_type_id: this.state.selectedVehicle, upcomingDueRadio: 'tyre_purchase'
                        }, 'Tyre Purchase Due')}>
                        <span className="mt-auto mb-auto font-weight-normal" style={{ marginLeft: '0px', cursor: 'pointer' }}>Number of Customer <b className="font-size-14px">
                          {dashboardCount.tyre_purchase_card.due_customer ? dashboardCount.tyre_purchase_card.due_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer ml-auto"
                        style={{ backgroundColor: '#ffffff', cursor: 'pointer', width: '57%' }}>
                        <span className="mt-auto mb-auto font-weight-normal">Due Period Range </span>
                        <div className="ml-auto">
                          <CustomDropDown
                            option_lbl="name"
                            option_key="id"
                            selected_dropdown_item={this.state.selectedDate}
                            dropdown_list={this.state.days_weeks_months_years_data}
                            placeholder="Select Type"
                            minWidth="150px"
                            setSelectedItem={this.getSelectedDateTyrePurchase}
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <UncontrolledPopover
                  placement={'right-start'}
                  hideArrow={false}
                  isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'tyre-purchase-popover'}
                  fade={true} target={'tyre-purchase-popover'}
                  trigger="focus"
                  className="dealer-popover"
                >
                  <div ref={r => this.dealerPopOver = r}>
                    <PopoverHeader className="popover-title d-flex justify-content-between">
                      <span>Help Guide Tour</span>
                      <span>3/7</span>
                    </PopoverHeader>
                    <PopoverBody className="d-flex-column">
                      <span className="popover-lbl mt-2">Display Tyre Purchase Customer Count and Current Leads</span>
                      <span className="popover-lbl mt-2">Tyre Purchase:</span>
                      <span className="popover-lbl-value mt-2">
                        Displays total tyre purchase customers, today's customer and this month customers
                      </span>
                      <span className="popover-lbl mt-3">Prospect for Tyre Replacement:</span>
                      <span className="mt-2 popover-lbl-value">
                        These are the leads or prospects for tyre purchase based on referrals, tyre replacement due etc.
                        <br />
                        Due Period Range, suggests that tyre purchase is due for the span of two months (+/- 30 days). One can select different range as well through a filter
                      </span>
                      <br />
                      <div className="d-flex-row justify-content-between mb-2">
                        <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'customer_popover' })}>Previous</span>
                        <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'campagin-popover' })}>Next</span>
                      </div>
                    </PopoverBody>
                  </div>
                </UncontrolledPopover>
              </Col>
              <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                {!this.state.hide_card.warranty_register ?
                  <Card className="card-box-shadow-10px he-100 border-radius-8px">
                    <CardBody className="d-flex-column padding-13px">
                      <div className="d-flex-row justify-content-between">
                        <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>Warranty Registration</span>
                      </div>
                      <div className="d-flex-column mt-auto mb-auto">
                        {
                          !this.state.isSendOtp ?
                            <span className="Please-provide-custo">Please provide customer’s mobile number to register.</span>
                            :
                            <>
                              <div className="d-flex">
                                <span className="Please-provide-custo" style={{ fontSize: '13px', width: '78%' }}>Enter the OTP Sent to {controls.phoneNumber.value}.  Did not get code?</span>
                                &nbsp;&nbsp;
                                <span
                                  onClick={this.sendOTP}
                                  className="custom-anchor-tag"
                                >Send Again</span>
                              </div>
                            </>
                        }
                        <table style={{ border: 'none' }} className="mt-2">
                          <tr style={{ border: 'none' }}>
                            {
                              !this.state.isSendOtp &&
                              <td>
                                <div className="d-flex-column">
                                  <FormGroup className="mb-0">
                                    <Input
                                      type="text"
                                      autoComplete="nofill"
                                      className="nine-one-rect"
                                      disabled={true}
                                      value="+91"
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                            }
                            <td>
                              <div className="d-flex-column">
                                {
                                  !this.state.isSendOtp ?
                                    <FormGroup className="mb-0">
                                      <Input
                                        type="text"
                                        autoComplete="nofill"
                                        className="enter-number-otp"
                                        name='phoneNumber'
                                        maxLength="10"
                                        value={controls.phoneNumber.value}
                                        onChange={(ev) => this.handleInputChange(ev)}
                                        placeholder="Enter Customer Mobile Number"
                                      />
                                    </FormGroup>
                                    :
                                    <FormGroup className="mb-0">
                                      <Input
                                        type="text"
                                        autoComplete="nofill"
                                        className="enter-number-otp"
                                        name='otp'
                                        value={controls.otp.value}
                                        onChange={(ev) => this.handleInputChange(ev)}
                                        placeholder="Enter OTP"
                                      />
                                    </FormGroup>
                                }
                              </div>
                            </td>
                            <td>
                              <Button
                                className="btn-pill action-button profile-cancel-button"
                                style={{ marginLeft: '8px', width: '100px' }}
                                onClick={() => this.sendVerifyOtp(!this.state.isSendOtp)}>{this.state.isSendOtp ? 'Submit' : 'Send OTP'}
                              </Button>
                              {
                                this.state.isSendOtp &&
                                <>
                                  &nbsp;&nbsp;
                                  <span
                                    onClick={this.cancelOTP}
                                    className="custom-anchor-tag"
                                  >
                                    Cancel
                                  </span>
                                </>
                              }
                            </td>
                          </tr>
                          {
                            (this.state.errorMessageOtp || this.state.isSendOtp) &&
                            <tr style={{ border: 'none' }}>
                              <td>
                                <div className="d-flex-column">
                                  {
                                    this.state.errorMessageOtp &&
                                    <div className="error" style={{ marginBottom: '5px' }}>* Please enter valid OTP.</div>
                                  }
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          }

                          {this.state.isClamRegistrationOpen && (
                            <tr style={{ border: 'none' }}>
                              <td colspan="3">
                                <div className="d-flex">
                                  <span onClick={() => this.openSendVerifyOtp()}
                                    className="custom-anchor-tag existing-customer-eligibility mt-2">
                                    Check existing customer’s eligibility for warranty
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </CardBody>
                  </Card> :

                  <Slider {...settings_banner}
                    ref={this.sliderBanner}
                    initialSlide={0}
                    afterChange={this.afterChangeBanner}>
                    {banner_list_item}
                  </Slider>
                }
              </Col>
              <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                <Card className='card-box-shadow-10px he-100 border-radius-8px'>
                  <CardBody className="d-flex-column padding-13px">
                    <div className="d-flex margin-bottom-5px justify-content-between">
                      <span className='dealer-dashobard-card-title color-495057'>Service</span>
                      <CustomDropDown
                        option_lbl="name"
                        option_key="service_id"
                        selected_dropdown_item={this.state.selectedService}
                        dropdown_list={this.state.services}
                        placeholder="Select Service"
                        minWidth="150px"
                        maxHeight="185px"
                        setSelectedItem={this.getSelectedService}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer" style={{ marginLeft: '0', backgroundColor: '#ffffff' }}
                        onClick={(e) => this.redirectCustomerMGT(e, {
                          visitPurposeVisitPurposeDropDown: 'service',
                          date_of_customer_registration: 'total',
                          visitPurposeServiceDropDown: this.state.selectedService
                        }, 'Total Service')}>
                        <span className="mt-auto mb-auto font-weight-normal" style={{ marginLeft: '10px', cursor: 'pointer' }}>Total <b className="font-size-14px">
                          {dashboardCount.service_card.total_customer ? dashboardCount.service_card.total_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer" style={{ backgroundColor: '#ffffff' }}
                        onClick={(e) => this.redirectCustomerMGT(e, {
                          date_of_customer_registration: 'today',
                          visitPurposeVisitPurposeDropDown: 'service',
                          visitPurposeServiceDropDown: this.state.selectedService
                        }, 'Today Service')}>
                        <span className="mt-auto mb-auto font-weight-normal">Today <b className="font-size-14px">
                          {dashboardCount.service_card.today_customer ? dashboardCount.service_card.today_customer : 0}
                        </b></span>
                      </div>
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer" style={{ backgroundColor: '#ffffff' }}
                        onClick={(e) => this.redirectCustomerMGT(e, {
                          date_of_customer_registration: 'month',
                          visitPurposeVisitPurposeDropDown: 'service',
                          visitPurposeServiceDropDown: this.state.selectedService
                        }, 'Month Service')}>
                        <span className="mt-auto mb-auto font-weight-normal">Month <b className="font-size-14px">
                          {dashboardCount.service_card.current_month_customer ? dashboardCount.service_card.current_month_customer : 0}</b></span>
                      </div>
                    </div>
                    <div className="d-flex mt-2 mb-2">
                      <span className='dealer-dashobard-card-title color-495057 font-size-13px'>
                        Prospect for Service Due
                        <img src="/images/orange-info.png" alt="" className="ml-1 cursor-pointer" data-tip data-for={'tooltip-service'} />
                        <ReactTooltip id={'tooltip-service'} className="customer-list-tooltip" effect="solid">
                          Based on Data Analysis. View the Service prospects for a period of time.
                        </ReactTooltip>
                      </span>
                    </div>
                    <div className="d-flex">
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer
                      pr-2" style={{ marginLeft: '0', width: 'auto', backgroundColor: '#ffffff' }}
                        onClick={(e) => this.redirectCustomerMGT(e, {
                          upcomingDue: this.state.selectedDateService,
                          upcomingDueRadio: 'service',
                          mainFilterRadio: 'all_due',
                          visitPurposeServiceDropDown: this.state.selectedService
                        }, 'Service Purchase Due')}>
                        <span className="mt-auto mb-auto font-weight-normal" style={{ marginLeft: '0px', cursor: 'pointer' }}>
                          Number of Customers <b className="font-size-14px">
                            {dashboardCount.service_card.due_customer ? dashboardCount.service_card.due_customer : 0}
                          </b></span>
                      </div>
                      <div className="total-customer-small-lbl color-495057 background-color-495057 cursor-pointer ml-auto"
                        style={{ backgroundColor: '#ffffff', cursor: 'pointer', width: '57%' }}>
                        <span className="mt-auto mb-auto font-weight-normal">Due Period Range </span>
                        <div className="ml-auto">
                          <CustomDropDown
                            option_lbl="name"
                            option_key="id"
                            selected_dropdown_item={this.state.selectedDateService}
                            dropdown_list={this.state.days_weeks_months_years_data}
                            placeholder="Select Type"
                            minWidth="150px"
                            setSelectedItem={this.getSelectedDate}
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                {
                  !this.state.hide_card.trade_scheme_report &&
                  <Card className="card-box-shadow-10px  card-box-shadow-10px border-radius-8px card-box-shadow-hover-30px" onClick={() => this.redirectToTradeScheme()}
                    style={{ height: '80%', minHeight: '105px' }} alt=" ">
                    <CardBody className="d-flex-column padding-13px">
                      <div className="d-flex-row justify-content-between">
                        <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>PCR Scheme</span>
                        {this.state.schemes_year && this.state.schemes_year.yearLabel && <span className="Apr---Jun209">Q{this.state.schemes_year.quarter}&nbsp;{this.state.schemes_year.yearLabel}</span>}
                      </div>
                      <table style={{ border: 'none' }} className="mt-auto mb-auto">
                        <tr style={{ border: 'none' }}>
                          <td>
                            <div className="d-flex-column pr-2">
                              <span className="dealer-card-point" style={{ fontSize: '13px' }}>Target</span>
                              <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>
                                {schemeReport.qtd.target}
                              </b></span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex-column pl-3 pr-2">
                              <span className="dealer-card-point" style={{ fontSize: '13px' }}>Achievement</span>
                              <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>
                                {schemeReport.qtd.achievement}
                              </b></span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex-column pl-2">
                              <span className="dealer-card-point" style={{ fontSize: '13px' }}>Percentage</span>
                              <span className="dealer-card-point mt-2" style={{ fontSize: '18px' }}><b>
                                {schemeReport.qtd.achieved_percentage ? schemeReport.qtd.achieved_percentage + '%' : 0}
                              </b></span>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                }
              </Col>
              <Col xl="6" md="12" xs="12" className="pr-7px pl-7px mt-3">
                {!this.state.hide_card.warranty_register ?
                  <Slider {...settings_banner}
                    ref={this.sliderBanner}
                    initialSlide={0}
                    afterChange={this.afterChangeBanner}>
                    {banner_list_item}
                  </Slider>
                  : ""}
              </Col>
            </Row>
          </Col>
          <Col xl="3" md="12" xs="12" className="pl-7px mt-3" style={{ maxWidth: '27%', paddingRight: '0' }}>
            <Row>
              {userDetail.permissions && userDetail.permissions.includes(Permissions.CAMPAIGN) ?
                <Col xl="12" md="12" xs="12" className="mb-3">
                  <Card
                    id="campagin-popover"
                    className={this.state.tooltipOpen && this.state.tooltip_name === 'campagin-popover' ? 'border-radius-8px box-shadow-40px-0-5' : 'card-box-shadow-10px border-radius-8px'}>
                    <CardBody className="d-flex-column padding-13px padding-left-7px">
                      <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>
                        Grow Your Business
                      </span>
                      {/* <Carousel
                      swipeable={false}
                      draggable={false}
                      showDots={true}
                      responsive={responsive_grow_business}
                      ssr={true} // means to render carousel on server-side.
                      infinite={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="grow-business-carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      dotListClass="custom-dot-list-style"
                      // itemClass="carousel-item-padding-40-px"
                      customRightArrow={<CustomRightArrow 
                        type="grow_business_list"/>}
                      customLeftArrow={<CustomLeftArrow 
                        type="grow_business_list"/>}
                      arrows={false}
                      itemClass="grow-carousel-item"
                      showDots={grow_business_list_data.length > 3}
                    >
                      {grow_business_list}
                    </Carousel> */}
                      <Slider {...settings}
                        ref={this.sliderGrowBuiness}
                        dots={grow_business_list_data.length > 4}
                      >
                        {grow_business_list}
                      </Slider>

                      <span className='dealer-dashobard-card-title margin-bottom-13px mt-3' style={{ color: '#495057' }}>
                        Engage Your Customers
                      </span>
                      <Slider {...settings}
                        ref={this.sliderEngageCustomer}
                      >
                        {engage_customers_list}
                      </Slider>

                      <span className="mr-3 mt-3 Balance-SMS-Credits">
                        Balance SMS Credits : <span style={{ color: '#495057' }}>
                          {this.state.sms_credits && this.state.sms_credits.credit ? this.state.sms_credits.credit : 0}</span></span>
                    </CardBody>
                  </Card>
                  <UncontrolledPopover
                    placement={'left-start'}
                    hideArrow={false}
                    isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'campagin-popover'}
                    fade={true}
                    target={'campagin-popover'}
                    trigger="focus"
                    className='dealer-popover'
                  >
                    <PopoverHeader className="popover-title d-flex justify-content-between">
                      <span>Help Guide Tour</span>
                      <span>4/7</span>
                    </PopoverHeader>
                    <PopoverBody className="d-flex-column">
                      <span className="popover-lbl mt-2">Campaign Management</span>
                      <span className="popover-lbl-value mt-2">
                        Once the customer data is captured, it's important to communicate with them and keep them updated and engaged with your store.
                        Click on appropriate icon to run the SMS campaign for that purpose
                      </span>
                      <br />
                      <div className="d-flex-row justify-content-between mb-2">
                        <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'tyre-purchase-popover' })}>Previous</span>
                        <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                          onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'offtake-popover' })}>Next</span>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </Col>
                : ""}
              {
                !this.state.hide_card.redemption_catalogue &&
                <Col xl="12" md="12" xs="12" className="mb-3">
                  <Card className="card-box-shadow-10px border-radius-8px card-box-shadow-hover-30px" onClick={() => this.redirectToRedemptionCatalog()}>
                    <CardBody className="d-flex-column padding-13px">
                      <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>
                        Redemption Catalogue
                      </span>
                      <span className="mt-1 You-have-earned-134">
                        You have earned <b>
                          {formatNumber(this.state.redeemptionData.available_points)}</b> points in<br /> <b>{this.state.redeemptionData.lower_month} to {this.state.redeemptionData.upper_month}.</b>
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              }
              {
                !this.state.hide_card.warranty_report &&
                <Col xl="12" md="12" xs="12" className="mb-3" onClick={() => this.redirectToWarrantyScoreCard(this.state.yearOption.year)}>
                  <Card className="border-radius-8px card-box-shadow-hover-30px">
                    <CardBody className="d-flex-column padding-13px">
                      <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>
                        Warranty Catalogue
                      </span>
                      <span className="mt-1 You-have-earned-134">
                        You have <b>{formatNumber(warranty_catalogue.total_points)}</b> warranty points.
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              }
              {
                (!this.state.hide_card.merchandise_wallet && (merchandiseData.balance_coins !== 0 || merchandiseData.balance_coins !== '0')) &&
                <Col xl="12" md="12" xs="12" className="mb-3">
                  <Card className="border-radius-8px card-box-shadow-hover-30px" onClick={() => this.redirectToMerchandiseWallet()}>
                    <CardBody className="d-flex-column padding-13px">
                      <span className='dealer-dashobard-card-title margin-bottom-13px' style={{ color: '#495057' }}>
                        Merchandise Catalogue
                      </span>
                      <span className="mt-1 You-have-earned-134">
                        You have <b>{formatNumber(merchandiseData.balance_coins)}</b> balance coins for quarter <b>{this.state.displayLabels.quarter ? this.state.displayLabels.quarter : ''}</b>.
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              }
            </Row>
          </Col>
        </Row >

        <UncontrolledPopover placement={'center-start'} hideArrow={false}
          isOpen={this.state.tooltipOpen && this.state.tooltip_name === 'dealer-dashboard'} fade={true} target={'dealer-dashboard'}
          trigger="focus" className='dealer-popover'
          style={{ top: '50% !important' }}
        >
          <PopoverHeader className="popover-title d-flex justify-content-between">
            <span>Help Guide Tour</span>
            <span>7/7</span>
          </PopoverHeader>
          <PopoverBody className="d-flex-column" style={{ top: '50%' }}>

            <span className="popover-lbl mt-2">JK Tyre wishing you all the best to take maximum benefit of this CRM</span>
            <span className="popover-lbl-value mt-2">
              You can re-run this help tour by clicking on "i icon" next to Dashboard on top left corner
            </span>
            <br />
            <div className="d-flex-row justify-content-between mb-2">
              <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                onClick={() => this.setState({ tooltipOpen: true, tooltip_name: 'warranty-popover' })}>Previous</span>
              <span className="link-blue cursor-pointer font-size-14px font-weight-bold"
                onClick={() => this.addHelpModuleScreen('dealer_dashboard')}>Close</span>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      </div >
    );
  }
}
//export default DealerDashboard;
const mapDispatchToProps = dispatch => ({
  setDealerDashboardRedirectDetail: redirectDetails => dispatch(setDealerDashboardRedirectDetail(redirectDetails)),
  setAddCustomerRedirect: redirectDetailsAddCustomer => dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
  setRedirect: redirectDetails => dispatch(setRedirect(redirectDetails)),
  setHelpGuide: helpGuide => dispatch(setHelpGuide(helpGuide))
});


const mapStateToProp = state => (
  {
    redirectDetails: state.User.redirectDetails,
    redirectDetailsAddCustomer: state.User.redirectDetailsAddCustomer,
    helpGuide: state.User.helpGuide
  });

export default connect(mapStateToProp, mapDispatchToProps)(DealerDashboard);