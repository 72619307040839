/**
 * @name marketingCoupon.js
 * @desc marketing coupon
 * @author Foram Sojitra <foram.sojitra@techifysolutions.com>
 * @version 0.0.0
 */
import classnames from "classnames";
import React, { Component } from 'react';
import {
  Button,
  Table,
  Input,
  Row,
  Card,
  CardBody
} from "reactstrap";
import { connect } from 'react-redux';
import { setRedirect } from '../../actions/UserAction';
import Sidebar from "react-sidebar";
import { downlodFile, formatDate } from "../../utils/utils";
import lodash from "lodash"; // library added for debounce functionality
import MarketingCouponService from '../../services/MarketingCouponService';
import Pagination from '../../components/Pagination/Pagination';
import './MarketingCoupon.css';
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import CouponFilter from "../../components/CouponFilter/CouponFilter";
import CNReport from "../../components/CNReport/CNReport";
import GenerateCoupon from "../../components/GenerateCoupon/GenerateCoupon";
import swal from 'sweetalert';
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";

const { ERROR_MESSAGE } = messages.COMMON;

class MarketingCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      search_query: "",
      isLoading: false,
      sidebarOpenCouponsFilter: false,
      sidebarOpenCNReport: false,
      generateCouponSlider: false,
      duplicateCouponControles: {},
      filterControls: {},
    };
    this.searchData = lodash.debounce(this.searchData, 1000);
  };


  componentDidMount() {
    if (this.props.redirectDetails && this.props.redirectDetails.generateCoupon) {
      this.setState({ generateCouponSlider: true });
      // this.props.setRedirect(null);
    } else {
      this.setState({ generateCouponSlider: false });
    }
    this.getCoupon();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.redirectDetails !== this.props.redirectDetails) {
      if (this.props.redirectDetails && this.props.redirectDetails.generateCoupon) {
        this.setState({
          generateCouponSlider: false,
        }, () => {
          this.setState({
            generateCouponSlider: true
          });
        });
      } else {
        this.setState({
          generateCouponSlider: false,
        })
      }
      this.props.setRedirect(null);
    }
  }


  handlePageChange = page => {
    this.setState({ page: page });
    this.setState(prevState => {
      return {
        ...prevState,
        page: page
      };
    });
    setTimeout(() => {
      this.getCoupon();
    }, 1);
  };

  handleInputChange = e => {
    let { pageSize } = this.state;
    pageSize = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        pageSize: pageSize,
        page: 1
      };
    });
    setTimeout(() => {
      this.getCoupon();
    }, 1);
  };

  searchData = (search) => {
    this.setState(prevState => {
      return {
        ...prevState,
        filterApplied: true,
        search_query: search,
        page: 1
      };
    }, () => {
      this.getCoupon();
    });
  };

  handleSearchChange = e => {
    let q = e.target.value;
    this.searchData(q);
  };

  openCouponsFilter = () => {
    document.body.style.overflow = "hidden";
    this.setState(prevState => {
      return {
        ...prevState, sidebarOpenCouponsFilter: true
      };
    });
  };

  onCloseCouponsFilter = () => {
    document.body.style.overflow = "auto";
    this.setState(prevState => {
      return { ...prevState, sidebarOpenCouponsFilter: false };
    });
  };

  openCnReport = () => {
    document.body.style.overflow = "hidden";
    this.setState(prevState => {
      return {
        ...prevState, sidebarOpenCNReport: true
      };
    });
  };

  closeCnReport = () => {
    document.body.style.overflow = "auto";
    this.setState(prevState => {
      return { ...prevState, sidebarOpenCNReport: false };
    });
  };

  openGenerateCoupon = () => {
    document.body.style.overflow = "hidden";
    this.setState(prevState => {
      return {
        ...prevState, duplicateCouponControles: {}, generateCouponSlider: true
      };
    });
  };

  onCloseGenerateCoupon = () => {
    document.body.style.overflow = "auto";
    this.setState(prevState => {
      return { ...prevState, generateCouponSlider: false, page: 1 };
    });
    this.getCoupon();
  };

  applyFilter = controls => {
    this.setState({
      filterControls: controls,
      filterApplied: true,
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0
    });
    setTimeout(() => {
      this.getCoupon();
    }, 1);
    this.onCloseCouponsFilter();
  };

  resetFilter = () => {
    this.setState({
      filterControls: {},
      filterApplied: false
    });
    setTimeout(() => {
      this.getCoupon();
    }, 1);
    this.onCloseCouponsFilter();
  };

  getCoupon = () => {
    const reqData = {};
    reqData.page = this.state.page;
    reqData.pagesize = this.state.pageSize;

    if (this.state.search_query && this.state.search_query !== "") {
      reqData.q = this.state.search_query;
    }

    if (this.state.filterControls.program_name && this.state.filterControls.program_name.value !== "") {
      reqData.program_name = this.state.filterControls.program_name.value;
    }

    if (this.state.filterControls.purpose && this.state.filterControls.purpose.value !== "") {
      reqData.purpose = this.state.filterControls.purpose.value;
    }

    if (this.state.filterControls.type && this.state.filterControls.type.value !== "") {
      reqData.type = this.state.filterControls.type.value;
    }

    if (this.state.filterControls.start_date && this.state.filterControls.start_date.value !== "") {
      let start_date = new Date(this.state.filterControls.start_date.value);
      start_date.setHours(0);
      start_date.setMinutes(0);
      reqData.start_date = start_date;
      // reqData.start_date = this.state.filterControls.start_date.value;
    }

    if (this.state.filterControls.end_date && this.state.filterControls.end_date.value !== "") {
      let end_date = new Date(this.state.filterControls.end_date.value);
      end_date.setHours(23);
      end_date.setMinutes(59);
      reqData.end_date = end_date;
      // reqData.end_date = this.state.filterControls.end_date.value;
    }

    if (this.state.filterControls.offer_status && this.state.filterControls.offer_status.value !== "") {
      reqData.offer_status = this.state.filterControls.offer_status.value;
    }

    if (this.state.filterControls.paid_by_jk && this.state.filterControls.paid_by_jk.value !== "") {
      reqData.paid_by_jk = this.state.filterControls.paid_by_jk.value;
    }

    this.setState({
      isLoading: true
    });

    MarketingCouponService.getCoupon(reqData)
      .then(res => {

        let { coupon, count } = this.state;

        if (res.data.data && res.data.data.data) {
          coupon = res.data.data.data;
          count = res.data.data.total_count;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState(prevState => {
            return {
              ...prevState,
              coupon,
              count,
              totalPages,
              isLoading: false
            };
          });

        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  handleClick = (data) => {
    this.setState({
      isLoading: true
    });
    MarketingCouponService.getSubReports(data)
      .then(res => {
        downlodFile(res.data.data.file);
        this.setState({
          isLoading: false
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  deleteCoupon = (data) => {
    if (data.redeemed_coupon > 0) {
      ModalService.openAlert("Error", "You can not delete this Program as a few of the coupons are already redeemed.", "error");
    } else {
      swal({
        title: "Delete Coupon",
        text: "Are you sure, you want to delete Coupon?",
        icon: "warning",
        buttons: ["No", "Yes"]
      }).then(isConfirm => {
        if (isConfirm) {
          this.deleteCouponConfirm(data);
        }
      });
    }
  }

  getCouponDate = (data) => {
    this.setState({
      isLoading: true
    });
    MarketingCouponService.getCouponById({ u: data.uuid })
      .then(res => {
        this.setState({
          isLoading: false
        });
        if (res.data && res.data.data) {
          res.data.data.coupon.action = data.action;
          this.setState({
            duplicateCouponControles: res.data.data.coupon,
            generateCouponSlider: true
          });
        };
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  deleteCouponConfirm(data) {
    this.setState({
      isLoading: true
    });
    MarketingCouponService.deleteCoupon(data)
      .then(res => {
        this.setState({
          page: 1,
          isLoading: false
        });
        if (res.data && res.data.message) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => { });
          this.getCoupon();
        };
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  render() {
    const {
      count,
      pageSize,
      search
    } = this.state;

    return (
      <div id="marketing-coupon">
        <span className="header-title">Marketing Coupon</span>
        <br />
        <br />
        <Card className="coupon-mg-card">
          <CardBody>
            <Row
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "1%",
                justifyContent: "space-between"
              }}
            >

              <div className="d-flex-row">
                <div
                  style={{ width: "290px" }}
                  className="d-flex-row search-input"
                >
                  <Input
                    placeholder="Search by Coupon Code or Offer Code"
                    className="login-input"
                    name="search"
                    onChange={this.handleSearchChange}
                    value={search}
                  ></Input>
                  <img
                    src="images/search.svg"
                    alt=""
                    className="icon-width"
                    style={{ marginLeft: "-25px" }}
                  />
                </div>

                <div className="d-flex-row" style={{ marginLeft: "10px" }}>
                  {this.state.filterControls &&
                    Object.keys(this.state.filterControls).length > 0 && (
                      <img
                        src="images/filter_applied.svg"
                        alt=""
                        className="cursor-pointer"
                        onClick={() => this.openCouponsFilter()}
                      />
                    )}
                  {this.state.filterControls &&
                    Object.keys(this.state.filterControls).length === 0 && (
                      <img
                        src="images/filter.svg"
                        alt=""
                        className="cursor-pointer"
                        onClick={() => this.openCouponsFilter()}
                      />
                    )}
                </div>

              </div>

              <div className="mr-1 d-flex" style={{ justifyContent: 'flex-end', margin: 'auto' }}>
                <img src="images/download.svg" alt="" className="download-report-icon margin-left-10" onClick={this.downloadExcel} />&nbsp;&nbsp;
                <span className="Download-Full-Report pull-right mr-2" onClick={this.openCnReport} style={{ cursor: 'pointer' }}>
                  Download Coupon Redemption Report
                </span>
              </div>

              <div>
                <Button
                  className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                  color="warning"
                  onClick={this.openGenerateCoupon}
                  type="submit"
                >
                  Generate Coupon
                </Button>
              </div>
            </Row>

            {this.state.sidebarOpenCNReport && (
              <Sidebar
                sidebar={
                  <>
                    <CNReport
                      onCloseSidebar={this.closeCnReport}
                    />
                  </>
                }
                open={this.state.sidebarOpenCNReport}
                sidebarClassName="coupon-filter"
                pullRight
                styles={{
                  sidebar: { background: "white", height: "90%", top: "60px" }
                }}
              />
            )}

            {this.state.sidebarOpenCouponsFilter && (
              <Sidebar
                sidebar={
                  <>
                    <CouponFilter
                      applyFilter={this.applyFilter}
                      resetFilter={this.resetFilter}
                      onCloseSidebar={this.onCloseCouponsFilter}
                      getFilterControls={this.state.filterControls}
                    />
                  </>
                }
                open={this.state.sidebarOpenCouponsFilter}
                sidebarClassName="coupon-filter"
                pullRight
                styles={{
                  sidebar: { background: "white", height: "90%", top: "60px" }
                }}
              />
            )}

            {this.state.generateCouponSlider && (
              <Sidebar
                sidebar={
                  <>
                    <GenerateCoupon
                      onCloseSidebar={this.onCloseGenerateCoupon}
                      getDuplicateCouponData={this.state.duplicateCouponControles}
                    />
                  </>
                }
                open={this.state.generateCouponSlider}
                sidebarClassName="coupon-slider"
                pullRight
                styles={{
                  sidebar: { background: "white", height: "90%", top: "60px" }
                }}
              />
            )}
            {this.state.isLoading && <CustomSpinner />}
            <div>
              <Table className="mb-0 table-responsive tbl-about tbl-about text-align-center">
                <thead>
                  <tr
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="coupon-list-table-header"
                  ></tr>
                </thead>
                <tbody>
                  <tr
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="coupon-list-table-header"
                  >
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "80px" }} >
                      Offer Code
                    </td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "90px" }}>Created Date</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "80px" }}>Program Name</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "190px" }}>Offer</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "60px" }}>Quantity</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "50px" }}>Coupon Amount</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "50px" }}>Tyre/ Coupon</td>
                    <td colspan="2" className="text-align-center border-1-5" style={{ width: "70px" }}>Validity</td>
                    <td colspan="2" className="text-align-center border-1-5" style={{ width: "40px" }}>Coupon Status</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "50px" }}>Type</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "97px" }}>Purpose</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "70px" }}>Eligible Dealers</td>
                    <td rowSpan="2" className="text-align-center border-1-5" style={{ width: "70px" }}>Action</td>
                  </tr>
                  <tr
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="coupon-list-table-header"
                  >
                    <td className="text-align-center border-1-5" style={{ width: "85px" }}>Start Date</td>
                    <td className="text-align-center border-1-5" style={{ width: "85px" }}>End Date</td>
                    <td className="text-align-center border-1-5" style={{ width: "40px" }}>Redeemed</td>
                    <td className="text-align-center border-1-5" style={{ width: "40px" }}>Pending</td>
                  </tr>
                  {this.state.coupon.map((row, i) => (
                    <tr
                      className={classnames(
                        { "active-row": row.active },
                        "coupon-list-row"
                      )}
                    >
                      <td className="text-align-center border-1-5">
                        {row.offer_code}
                      </td>
                      <td className="text-align-center">
                        {formatDate(row.created_at)}
                      </td>
                      <td className="text-align-center border-1-5">
                        {row.program_name}
                      </td>
                      <td className="text-align-center border-1-5">
                        <label data-tooltip={row.offer_details} style={{ "cursor": "pointer" }}
                          data-tooltip-position="top" className="tool-tip">
                          <span className="textwrap">{row.offer_details}</span>
                        </label>
                      </td>
                      {
                        (row.coupon_quantity === 0 || row.coupon_quantity === '0') ?
                          <td className="text-align-center border-1-5">
                            {row.coupon_quantity}
                          </td> :
                          <td onClick={() => this.handleClick({ type: 'coupons', u: row.uuid })} className="text-align-center border-1-5 redirect-link">
                            {row.coupon_quantity}
                          </td>
                      }
                      <td className="text-align-center border-1-5">
                        <span>{row.discount_per_coupon}</span>
                        <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={(row.paid_by_jk === true || row.paid_by_jk === 'true') ? "Coupon amount paid by JK Tyre" : "Coupon amount paid by Dealer"} >
                          <img style={{ height: '11px' }}
                            src='images/info.svg'
                            alt=""
                          /></span>
                      </td>
                      <td className="text-align-center border-1-5">
                        {row.maximum_tyres_per_coupon}
                      </td>
                      <td className="text-align-center border-1-5">
                        {formatDate(row.coupon_validity_start_date)}
                      </td>
                      <td className="text-align-center border-1-5">
                        {formatDate(row.coupon_validity_end_date)}
                        {row.validity_end_today && (<span data-tooltip="Coupon Validity Ended" style={{ "cursor": "pointer" }}
                          data-tooltip-position="top" className="tool-tip error">!</span>)}
                      </td>
                      {
                        (row.redeemed_coupon === 0 || row.redeemed_coupon === '0') ?
                          <td className="text-align-center border-1-5">
                            {row.redeemed_coupon}
                          </td> :
                          <td onClick={() => this.handleClick({ type: 'redeem_coupons', u: row.uuid })} className="text-align-center border-1-5 redirect-link">
                            {row.redeemed_coupon}
                          </td>
                      }
                      {
                        (row.pending_coupon === 0 || row.pending_coupon === '0') ?
                          <td className="text-align-center border-1-5">
                            {row.pending_coupon}
                          </td> :
                          <td onClick={() => this.handleClick({ type: 'pending_coupons', u: row.uuid })} className="text-align-center border-1-5 redirect-link">
                            {row.pending_coupon}
                          </td>
                      }
                      <td className="text-align-center border-1-5">
                        {row.type}
                      </td>
                      <td className="text-align-center border-1-5">
                        {row.purpose}
                      </td>
                      {
                        (row.dealer === 0 || row.dealer === '0') ?
                          <td className="text-align-center border-1-5">
                            {row.dealer}
                          </td> :
                          <td onClick={() => this.handleClick({ type: 'dealer', u: row.uuid })} className="text-align-center border-1-5 redirect-link">
                            {row.dealer}
                          </td>
                      }

                      <td className="border-1-5 text-align-center" style={{ paddingLeft: "0", paddingRight: "0" }}>
                        <img
                          className="cursor-pointer"
                          style={{ height: "13px" }}
                          onClick={() => this.getCouponDate({ uuid: row.uuid, action: 'edit' })}
                          src="images/edit.svg"
                          alt=""
                        />
                        &nbsp;
                        <img
                          className="cursor-pointer"
                          style={{ height: '13px' }}
                          onClick={() => this.getCouponDate({ uuid: row.uuid, action: 'duplicate' })}
                          src='images/duplicate.svg'
                          alt=""
                        />
                        &nbsp;
                        <img
                          className="cursor-pointer ml-1"
                          style={{ height: "13px" }}
                          onClick={() => this.deleteCoupon({ uuid: row.uuid, redeemed_coupon: row.redeemed_coupon })}
                          src="images/delete.svg"
                          alt=""
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* {count <= 0 && !this.state.filterApplied && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%" }}
                >
                  Currently there are no customers registered with your store.
                  Use ‘OPTIN’ facility, Bulk Upload features to add customers to
                  your account.
                </div>
              )} */}
              {count <= 0 && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%" }}
                >
                  We can not find any records matching your search criteria.
                  Please change search criteria and try again.
                </div>
              )}
              {count > 0 && (
                <div
                  className="d-flex-row"
                  style={{ marginTop: "10px", justifyContent: "flex-end" }}
                >
                  <span className="Rows-per-page">Rows per page:</span>
                  <select
                    className="form-control rows-per-page-select"
                    name="pageSize"
                    value={pageSize}
                    onChange={e => this.handleInputChange(e)}
                    style={{ width: "65px" }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                  &nbsp;&nbsp;
                  <div style={{ marginTop: "5px" }}>
                    <Pagination
                      margin={2}
                      page={this.state.page}
                      count={this.state.totalPages}
                      pageSize={this.state.pageSize}
                      totalRecords={count}
                      onPageChange={this.handlePageChange}
                    ></Pagination>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => ({
  setRedirect: redirectDetails => dispatch(setRedirect(redirectDetails)),
});

const mapStateToProp = state => ({
  redirectDetails: state.User.redirectDetails
});

export default connect(mapStateToProp, mapDispatchToProps)(MarketingCoupon);
