import React, { Component } from 'react';
import {
  Button,
  FormGroup, Label,
  Input,
  Row, Col,
  Card, CardBody, InputGroup, InputGroupAddon
} from 'reactstrap';

import {
  faCalendarAlt,

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DatePicker from 'react-datepicker';

import { messages } from '../../constants/constant.message';

import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

const { INVALID_FACEBOOK, INVALID_WEBSITE, INVALID_INSTAGRAM, EMPTY_BIRTHDATE } = messages.PROFILE;

class EditSocialDetails extends Component {

  state = {
    isFirstTime: true
  }

  componentWillReceiveProps(props) {
    const { birthDate } = props;
    if (this.state.isFirstTime === true && birthDate.autoFocus === true) {
      this.bday.setOpen(true);
      this.props.activeFragment('social');
      this.setState({ isFirstTime: false });
    }
  }

  handleOnCancel = () => {
    this.props.onCancel('isSocialEditActive', false);
  }

  render() {
    const { birthDate, anniversaryDate, facebook, instagram, website,
      google_my_business_rating_link, google_my_business_link } = this.props;

    return <Card className={this.props.className}>
      <CardBody>
        {this.props.isLoading && <CustomSpinner></CustomSpinner>}
        <Row>
          <Col>
            <b>SOCIAL DETAILS</b>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xl="4" md="12" xs="12">
            <FormGroup>
              <Label for="password">Birth Date <span className="required-field">*</span></Label>
              <div className="width-100">
                <Label onClick={e => this.bday.state.open && e.preventDefault()} className="width-100">
                  <InputGroup>
                    <DatePicker className={`form-control ${this.props.inputClassName}`}
                      selected={birthDate.value}
                      onChange={this.props.handleBirthDateChange}
                      showMonthDropdown
                      ref={r => this.bday = r}
                      // shouldCloseOnSelect={true}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      shouldCloseOnSelect
                      maxDate={new Date()}
                      // maxDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                      dropdownMode="select"
                      onFocus={this.props.activeFragment ? this.props.activeFragment.bind(this, 'social') : null}
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text">
                        <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>

                {/* <AppDatePicker
                  onChange={this.props.handleBirthDateChange}
                  selected={birthDate.value}
                  activeFragment={this.props.activeFragment}
                  placeholder="Select"
                  ref={this.bday}
                // inputClassName="required-input"
                /> */}
                {birthDate.showErrorMsg && <div className="error">* {EMPTY_BIRTHDATE}</div>}
              </div>
              {/* <Label for="birthDate">Birthdate</Label> */}
              {/* <Input
                type="birthDate"
                name="birthDate"
                id="birthDate"
                value={birthDate.value}
                onChange={this.props.handleInputChange}
                placeholder="Enter here" /> */}
            </FormGroup>
          </Col>
          <Col xl="4" md="12" xs="12">
            <Label for="password">Anniversary Date</Label>
            <div className="width-100">

              <Label onClick={e => this.anny.state.open && e.preventDefault()} className="width-100">
                <InputGroup>
                  <DatePicker className={`form-control ${this.props.inputClassName}`}
                    selected={anniversaryDate.value}
                    onChange={this.props.handleAnniversaryDateChange}
                    showMonthDropdown
                    ref={r => this.anny = r}
                    // shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    shouldCloseOnSelect
                    maxDate={new Date()}
                    dropdownMode="select"
                    onFocus={this.props.activeFragment ? this.props.activeFragment.bind(this, 'social') : null}
                  />
                  <InputGroupAddon addonType="append" >
                    <div className="input-group-text">
                      <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} />
                    </div>
                  </InputGroupAddon>
                </InputGroup>
              </Label>

              {/* <AppDatePicker
                onChange={this.props.handleAnniversaryDateChange}
                selected={anniversaryDate.value}
                placeholder="Select"
                ref={this.an}
              /> */}
            </div>
            {/* <FormGroup>
              <Label for="anniversaryDate">Anniversary Date</Label>
              <Input
                type="anniversaryDate"
                name="anniversaryDate"
                id="anniversaryDate"
                value={anniversaryDate.value}
                onChange={this.props.handleInputChange}
                placeholder="Enter here" />
            </FormGroup> */}
          </Col>
        </Row>
        <Row>
          <Col xl="4" md="12" xs="12">
            <FormGroup>
              <Label for="facebook">Facebook Page</Label>
              <Input
                type="text"
                name="facebook"
                id="facebook"
                value={facebook.value}
                onChange={this.props.handleInputChange}
                placeholder="Paste here" />
              {facebook.showErrorMsg && <div className="error">* {INVALID_FACEBOOK}</div>}
              <div className="d-flex mt-2">
                <span className="profile-sender-id-note">
                  Please add your facebook page url as https://www.facebook.com/JKTyre/
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col xl="4" md="12" xs="12">
            <FormGroup>
              <Label for="instagram">Instagram Page</Label>
              <Input
                type="text"
                name="instagram"
                id="instagram"
                value={instagram.value}
                onChange={this.props.handleInputChange}
                placeholder="Paste here" />
              {instagram.showErrorMsg && <div className="error">* {INVALID_INSTAGRAM}</div>}
              <div className="d-flex mt-2">
                <span className="profile-sender-id-note">
                  Please add your Instagram page url as https://www.instagram.com/jktyrecorporate
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col xl="4" md="12" xs="12">
            <FormGroup>
              <Label for="website">Website</Label>
              <Input
                type="text"
                name="website"
                id="website"
                value={website.value}
                onChange={this.props.handleInputChange}
                placeholder="Paste here" />
              {website.showErrorMsg && <div className="error">* {INVALID_WEBSITE}</div>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="6" md="12" xs="12">
            <FormGroup>
              <Label for="website">Store Google My Business Link</Label>
              <Input
                type="text"
                name="google_my_business_link"
                id="google_my_business_link"
                value={google_my_business_link.value}
                onChange={this.props.handleInputChange}
                placeholder="Paste here" />
              {/* {website.showErrorMsg && <div className="error">* {INVALID_WEBSITE}</div>} */}
              <div className="d-flex mt-2">
                <span className="profile-sender-id-note">
                  Provide your Google My Business link. This link will be shared with your prospective customers so they can find more information about store.
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col xl="6" md="12" xs="12">
            <FormGroup>
              <Label for="website">
                Store Google My Business Rating Link
              </Label>
              <Input
                type="text"
                name="google_my_business_rating_link"
                id="website"
                value={google_my_business_rating_link.value}
                onChange={this.props.handleInputChange}
                placeholder="Paste here" />
              {/* {website.showErrorMsg && <div className="error">* {INVALID_WEBSITE}</div>} */}
              <div className="d-flex mt-2">
                <span className="profile-sender-id-note">
                  Provide your Google My Business Rating link. This link will be shared with your customers to get their feedback. The better rating help improve your rating in Google search too.
                </span>
              </div>
            </FormGroup>
          </Col>
        </Row>

        {this.props.onSave && <Row>
          <Col xl="12" xs="12" md="12" className="text-align-right" >
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={this.handleOnCancel}>Cancel</Button>
            <Button type="submit" className="mb-1 mr-1 btn-pill action-button profile-save-button" color="warning" disabled={!this.props.isFormValid} onClick={this.props.onSave}>Save</Button>
          </Col>
        </Row>}
      </CardBody>
    </Card>;
  }
}

export default EditSocialDetails;
