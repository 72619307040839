import React from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Form
} from "reactstrap";

import Ionicons from 'react-ionicons';
import { formatDate } from "../../utils/utils";
import "./SharedReferenceModal.css";

class SharedReferenceModal extends React.Component {
  state = {

  };

  componentDidMount() {

  }


  closeModal = () => {
    this.props.closeModal(true);
  };

  render() {

    let obj = this.props.data ? this.props.data : [];
    return (
      <div>
        <Modal
          id="shared-reference"
          isOpen={this.props.show}
          toggle={this.props.closeModal}
          centered
        >
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col className="modal-title">REFERENCES SHARED</Col>
                <Col sm="2" className="text-align-right">
                  <span className="cursor-pointer" onClick={this.closeModal}>
                    <Ionicons icon="md-close" color="#fdbb1f"></Ionicons>
                  </span>
                </Col>
              </Row>
              <div></div>
              {obj.map(o => <Row className="section-border">
                <Col className="padding-left-0">
                  <div className="section-field-title">Name</div>
                  <div className="section-field-value">{o.customer_name}</div>
                </Col>
                <Col>
                  <div className="section-field-title">Mobile</div>
                  <div className="section-field-value">{o.phone_number}</div>
                </Col>
                <Col className="padding-right-0">
                  <div className="section-field-title">Reference Date</div>
                  <div className="section-field-value">{formatDate(o.refer_date)}</div>
                </Col>
              </Row>)}
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default SharedReferenceModal;
