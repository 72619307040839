import React, { Component, Fragment } from 'react';
import {
  Card, CardBody, Table, Row, Col, Input, FormGroup, InputGroup,
  InputGroupAddon, Label, Button
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Ionicon from 'react-ionicons';
import lodash from "lodash"; // library added for debounce functionality

import Pagination from "../../components/Pagination/Pagination";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import BannerService from '../../services/BannerService';

import TargetUsersModal from '../../modal/TargetUsersModal/TargetUsersModal';

import { formatDate } from "../../utils/utils";

import './BannerList.css';
import ReactTooltip from 'react-tooltip';

const defaultFilterControls = {
  bannerTypeRadio: {
    value: '',
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false
  },
  bannerStatusRadio: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false
  },
  bannerStartDate: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false
  },
  bannerEndDate: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false
  }
}

class BannerList extends Component {
  state = {
    banners: [],
    page: 1,
    pageSize: 10,
    isLoading: false,
    totalPages: 0,
    count: 0,
    search: '',
    isTargetUserModalOpen: false,
    targetUserModalBannerUuid: null,
    targetUserModalType: null,
    targetUserModalCount: 0,
    isBannerFilterOpen: false,
    filterApplied: false,
    actionMenuIndex: -1,
    bannerTypes: [
      {
        key: 'All',
        value: ''
      },
      {
        key: 'Text',
        value: 'text'
      },
      {
        key: 'Text and Picture',
        value: 'text_picture'
      },
      {
        key: 'Picture',
        value: 'picture'
      }
    ],
    bannerStatuses: [
      {
        key: 'Active',
        value: 'true'
      },
      {
        key: 'Inactive',
        value: 'false'
      },
    ],
    controls: JSON.parse(JSON.stringify(defaultFilterControls))
  }

  constructor() {
    super();
    this.actionContainer = React.createRef();
    this.searchData = lodash.debounce(this.searchData, 500);
  }

  componentDidMount() {
    this.getBanners();
  }

  closeActionMenuWhenClickedOutsideMenu = event => {
    if (this.actionContainer.current && this.actionContainer.current.contains(event.target)) {

    } else {
      this.setState({ actionMenuIndex: -1 })
    }
  };

  activeInactiveBanner = (uuid, isActive) => {
    let obj = {
      "uuid": uuid,
      "is_active": isActive
    }
    this.setState({ isLoading: true, actionMenuIndex: -1 });
    BannerService.activeInactiveBanner(obj)
      .then(data => {
        this.setState({ isLoading: false });
        this.getBanners();
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }

  getBanners = (download_list = false) => {
    const { page, pageSize, search } = this.state;
    this.setState({
      isLoading: true
    });
    const filterObj = this.getReqData();
    let body = {
      page,
      pageSize: pageSize,
      q: search
    }
    body = { ...body, ...filterObj };
    BannerService.getAllBanner(body)
      .then(res => {
        if (res.data.data && res.data.data.elements.length > 0) {
          let banners = res.data.data.elements;
          let count = res.data.data.total_count;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState({
            banners,
            count,
            totalPages,
            isLoading: false
          });
        } else {
          let banners = [];
          let count = 0;
          this.setState({
            banners,
            count,
            isLoading: false
          });
        }
      })
      .catch(e => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  };

  searchData = (search, page) => {
    this.getBanners();
  };

  handleSearchChange = e => {
    let searchVal = e.target.value;
    this.setState({ page: 1, search: searchVal }, () => {
      this.searchData(searchVal, 1);
    });
  };

  handlePageChange = page => {
    this.setState({ page: page }, () => {
      this.getBanners();
    });
  };

  openTargetUserModal = (uuid, type, count) => {
    this.setState({
      targetUserModalBannerUuid: uuid,
      targetUserModalType: type,
      isTargetUserModalOpen: true,
      targetUserModalCount: count
    })
  }

  closeTargetUserModal = () => {
    this.setState({
      targetUserModalBannerUuid: null,
      targetUserModalType: null,
      isTargetUserModalOpen: false,
      targetUserModalCount: 0
    })
  }

  handleRadioChange = (controlName, e) => {
    let { controls } = this.state;
    let radioControl = controls[controlName]
    radioControl.value = e.target.value;
    this.setState({ controls });
  }

  handleDateChange = (controlName, e) => {
    const { controls } = this.state;
    let dateControl = controls[controlName];
    dateControl.value = e;
    dateControl.touched = true;
    this.setState({ controls });
    // this.handleValidation();
  }

  openCloseBannerFilter = () => {
    this.setState({ isBannerFilterOpen: !this.state.isBannerFilterOpen });
  }

  applyFilter = () => {
    this.setState({ page: 1, isBannerFilterOpen: false, filterApplied: true }, () => {
      this.getBanners();
    });
  }

  resetFilter = () => {
    this.setState({ isBannerFilterOpen: false, controls: JSON.parse(JSON.stringify(defaultFilterControls)), filterApplied: false }, () => {
      this.getBanners();
    });
  }

  getReqData = () => {
    const { controls } = this.state;
    const { bannerTypeRadio, bannerStartDate, bannerEndDate, bannerStatusRadio } = controls;
    let filterObj = {};
    if (bannerTypeRadio.value) {
      filterObj.type = bannerTypeRadio.value;
    }
    if (bannerStartDate.value) {
      bannerStartDate.value.setHours(0, 0, 0, 0);
      filterObj.start_date = bannerStartDate.value.toISOString();
    }
    if (bannerEndDate.value) {
      bannerEndDate.value.setHours(23, 59, 59, 59);
      filterObj.end_date = bannerEndDate.value.toISOString();
    }
    if (bannerStatusRadio.value) {
      filterObj.is_active = bannerStatusRadio.value;
    }
    return filterObj;
  }
  OpenActionMenu = (index) => {
    this.setState({ actionMenuIndex: index })
  }

  openCreateUpdateBannerPage = (action, b) => {
    this.props.history.push({
      pathname: "/create-banner",
      bannerData: b,
      action: action
    });
  }

  sliceText = (text, length) => {
    if (text && text.length > length) {
      return text.slice(0, length) + '...';
    } else {
      return text;
    }
  }
  // UI Components 
  prepareRows = () => {
    const { banners } = this.state;
    return banners.map((b, i) =>
      <tr>
        <td >{formatDate(b.created_at)}</td>
        <td style={{ maxWidth: '193px', overflow: 'hidden', whiteSpace: 'unset' }}>
          {
            b.title ?
              <>
                {
                  b.title.length > 20 ?
                    <>
                      <span className="cursor-pointer" data-tip data-for={`tooltip-title-${i}`}>
                        {this.sliceText(b.title, 20)}
                      </span>
                      <ReactTooltip id={`tooltip-title-${i}`} className="banner-list-tooltip" effect="solid">
                        {b.title}
                      </ReactTooltip>
                    </> :
                    b.title
                }
              </>
              : '--'
          }
        </td>
        <td style={{ maxWidth: '230px', overflow: 'hidden', whiteSpace: 'unset' }}>
          {
            b.description ?
              <>
                {
                  b.description.length > 25 ?
                    <>
                      <span className="cursor-pointer" data-tip data-for={`tooltip-description-${i}`}>
                        {this.sliceText(b.description, 25)}
                      </span>
                      <ReactTooltip id={`tooltip-description-${i}`}
                        className="banner-list-tooltip" effect="solid">
                        {b.description}
                      </ReactTooltip>
                    </> :
                    b.description
                }
              </>
              : '--'
          }
        </td>
        <td>{b.banner_action}</td>
        <td className={b.target_users > 0 ? "count" : ""}
          onClick={() => b.target_users > 0 ? this.openTargetUserModal(b.uuid, null, b.target_users) : null}
        >{b.target_users}</td>
        <td className={b.banner_view > 0 ? "count" : ""}
          onClick={() => b.banner_view > 0 ? this.openTargetUserModal(b.uuid, 'view', b.banner_view) : null}
        >{b.banner_view}</td>
        <td className={b.banner_click > 0 ? "count" : ""}
          onClick={() => b.banner_click > 0 ? this.openTargetUserModal(b.uuid, 'click', b.banner_click) : null}
        >{b.banner_click}</td>
        <td>{b.type}</td>
        <td>{formatDate(b.start_date)}</td>
        <td>{formatDate(b.end_date)}</td>
        <td>{b.is_active === true ? 'Active' : 'Inactive'}</td>
        <td style={{ position: 'relative', textAlign: 'center' }}>{this.renderActionMenu(b, i)}</td>
      </tr>
    )
  }

  renderFilterDesign = () => {
    const {
      // banners, count, isTargetUserModalOpen, 
      // targetUserModalBannerUuid, targetUserModalType, search,
      //  isBannerFilterOpen, targetUserModalCount,
      controls, bannerTypes, bannerStatuses,
    } = this.state;

    const { bannerTypeRadio, bannerStatusRadio, bannerStartDate, bannerEndDate } = controls;
    return <div className="filter-container">
      <div className="field-title">Banner Type</div>
      <Row className="margin-top-12">
        <Col>
          {bannerTypes.map((b, index) => {
            return <>
              <label className="container-rb">
                {b.key}
                <input
                  type="radio"
                  name="bannerTypeRadio"
                  value={b.value}
                  onChange={this.handleRadioChange.bind(this, 'bannerTypeRadio')}
                  checked={bannerTypeRadio.value === b.value}
                />
                <span className="checkmark-rb"></span>
              </label>&nbsp;&nbsp;{"   "}
            </>
          }
          )}
        </Col>
      </Row>
      {/* </div> */}
      <div className="field-title margin-top-20">Banner Status</div>
      <Row className="margin-top-12">
        <Col>
          {bannerStatuses.map((b, index) => {
            return <>
              <label className="container-rb">
                {b.key}
                <input
                  type="radio"
                  name="bannerStatusRadio"
                  value={b.value}
                  onChange={this.handleRadioChange.bind(this, 'bannerStatusRadio')}
                  checked={bannerStatusRadio.value === b.value}
                />
                <span className="checkmark-rb"></span>
              </label>&nbsp;&nbsp;&nbsp;&nbsp;
            </>
          }
          )}
        </Col>
      </Row>

      <div className="field-title margin-top-20 font-bold">Created on</div>
      <Row className="margin-top-12">
        <Col>
          <FormGroup>
            <Label for="password" className="field-title">Start Date</Label>
            <div>
              <Label className="width-100"
                onClick={e => this.bannerStartDate.state.open && e.preventDefault()}
              >
                <InputGroup>
                  <DatePicker className={"form-control calendar-input"}
                    selected={bannerStartDate.value}
                    onChange={this.handleDateChange.bind(this, 'bannerStartDate')}
                    showMonthDropdown
                    ref={r => this.bannerStartDate = r}
                    // shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    placeholderText="Select"
                    shouldCloseOnSelect
                    dropdownMode="select"
                  />
                  <InputGroupAddon addonType="append" >
                    <div className="input-group-text">
                      <img src="/images/calendar_icon.svg" className="calendar-icon" alt="" />
                    </div>
                  </InputGroupAddon>
                </InputGroup>
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="password" className="field-title">End Date</Label>
            <div>
              <Label className="width-100"
                onClick={e => this.bannerEndDate.state.open && e.preventDefault()}
              >
                <InputGroup>
                  <DatePicker className={"form-control calendar-input"}
                    selected={bannerEndDate.value}
                    onChange={this.handleDateChange.bind(this, 'bannerEndDate')}
                    showMonthDropdown
                    ref={r => this.bannerEndDate = r}
                    // shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    placeholderText="Select"
                    shouldCloseOnSelect
                    dropdownMode="select"
                    minDate={bannerStartDate.value}
                  />
                  <InputGroupAddon addonType="append" >
                    <div className="input-group-text">
                      <img src="/images/calendar_icon.svg" className="calendar-icon" alt="" />
                    </div>
                  </InputGroupAddon>
                </InputGroup>
              </Label>

            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginLeft: '0', marginRight: '0' }}>
        <Col xs="12" md="12" lg="12" className="text-align-right" style={{ marginTop: '0', paddingRight: '0px', paddingLeft: '0px' }}>
          <Button className="button cancel-button" onClick={this.resetFilter}
          >Reset</Button>&nbsp;&nbsp;
          <Button className="button create-button" onClick={this.applyFilter}>Apply</Button>
        </Col>
      </Row>
    </div>
  }

  renderActionMenu = (row, i) => {
    const { actionMenuIndex } = this.state;
    return <>
      <Ionicon className="cursor-pointer" icon="md-more" fontSize="24px" color="#fdbb1f" onClick={this.OpenActionMenu.bind(this, i)} />
      {actionMenuIndex === i && <div className="action-menu" ref={this.actionContainer}>
        <div>
          <Row className="action-menu-content cursor-pointer"
            onClick={() =>
              this.openCreateUpdateBannerPage('edit', row)
            }
          >
            <Col sm="1">
              <img
                className="cursor-pointer ml-1"
                style={{ height: "13px" }}
                alt=""
                src="images/edit.svg"
              />
            </Col>
            <Col >Edit</Col>
          </Row>
        </div>
        <div>
          <Row className="customer-list-action-menu-content cursor-pointer"
            onClick={() => this.activeInactiveBanner(row.uuid, !row.is_active)}
          >
            <Col sm="1">
              <img
                className=" ml-1"
                style={{ height: "17px", width: "21px" }}
                src="images/activate_inactive_icon.svg"
                alt=""
              />
            </Col>
            <Col>{row.is_active === true ? 'Inactive' : 'Active'}</Col>
          </Row>
        </div>
        <div>
          <Row className="customer-list-action-menu-content cursor-pointer"
            onClick={() =>
              this.openCreateUpdateBannerPage('duplicate', row)
            }
          >
            <Col sm="1">
              <img
                className=" ml-1"
                style={{ height: "17px", width: "22px" }}
                src="images/duplicate.svg"
                alt=""
              />
            </Col>
            <Col>Duplicate</Col>
          </Row>
        </div>
      </div>}
    </>
  }

  render() {
    const {
      // banners, bannerTypes,bannerStatuses,controls,
      count, isTargetUserModalOpen, targetUserModalBannerUuid, targetUserModalType, search, isBannerFilterOpen, filterApplied, isLoading, targetUserModalCount
    } = this.state;

    // const { bannerTypeRadio, bannerStatusRadio, bannerStartDate, bannerEndDate } = controls;
    return <div id="banner-list"
      onMouseDown={this.closeActionMenuWhenClickedOutsideMenu}
    >
      {isTargetUserModalOpen && <TargetUsersModal
        show={isTargetUserModalOpen}
        closeModal={this.closeTargetUserModal}
        uuid={targetUserModalBannerUuid}
        type={targetUserModalType}
        count={targetUserModalCount}
      ></TargetUsersModal>}
      <div className="page-title">
        Banner List
      </div>
      <br />
      <Card>
        <CardBody>
          {isLoading && <CustomSpinner />}
          <Row className="margin-bottom-10 margin-top-10">
            <Col xs="4" className="padding-right-0">
              <Input
                placeholder="Search by Title and Description"
                className="report-search-input"
                name="search"
                value={search}
                onChange={this.handleSearchChange}
              ></Input>
              <img src="images/search.svg" className="report-search icon-width" alt="" />
            </Col>
            <Col xs="2" className="padding-left-0">
              <div>
                <div>
                  <img
                    src={this.state.filterApplied === true ? "images/smaller-blue-dot-hi.png" : "images/filter.svg"}
                    className={`cursor-pointer ${filterApplied === true
                      ? 'filter-icon-selected'
                      : 'filter-icon'}`
                    }
                    onClick={this.openCloseBannerFilter}
                    alt=""
                  />
                </div>
                {isBannerFilterOpen && this.renderFilterDesign()}
              </div>
            </Col>
            <Col className="text-align-right">
              <button
                className="button create-button"
                style={{ width: '158px' }}
                onClick={() => this.openCreateUpdateBannerPage('add', null)}
              >Create Banner
              </button>
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th rowSpan="2" style={{ width: '99px' }}>Created Date</th>
                <th rowSpan="2" style={{ width: '193px' }}>Title</th>
                <th rowSpan="2" style={{ width: '250x' }}>Description</th>
                <th rowSpan="2" style={{ width: '66px' }}>Action</th>
                <th rowSpan="2" style={{ width: '58px' }}>Target Users</th>
                <th rowSpan="2" style={{ width: '58px' }}>View Count</th>
                <th rowSpan="2" style={{ width: '58px' }}>Click Count</th>
                <th rowSpan="2" style={{ width: '97px' }}>Banner Type</th>
                <th colSpan="2" className="text-align-center">Banner Date</th>
                <th rowSpan="2" style={{ width: '75px' }} className="text-align-center">Status</th>
                <th rowSpan="2" style={{ width: '65px' }}>Actions</th>
              </tr>
              <tr>
                <th style={{ width: '80px', fontWeight: 'normal' }}>Start Date</th>
                <th style={{ width: '80px', fontWeight: 'normal' }}>End Date</th>
              </tr>
            </thead>
            <tbody>
              {this.prepareRows()}
            </tbody>
          </Table>
          <div style={{ marginTop: "5px" }}>
            <Pagination
              margin={2}
              page={this.state.page}
              count={this.state.totalPages}
              pageSize={this.state.pageSize}
              totalRecords={count}
              onPageChange={this.handlePageChange}
            ></Pagination>
          </div>
        </CardBody>
      </Card>
    </div>
  }
}

export default BannerList;
