
import React from 'react';
import { Button, Modal, ModalBody, Row, Col, Form } from 'reactstrap';

import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import GiftCatalogService from '../../services/GiftCatalogService';
import ModalService from '../../services/ModalService';
import './UploadGiftProductImage.css';


class UploadGiftProductImage extends React.Component {

  state = {
    selectedFiles: {},
    isLoading: false
  }



  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    // this.handleValidation();
  }

  handleFileUpload = (e) => {
    this.setState({ selectedFiles: e.target.files });
  }

  uploadImage = (e) => {
    e.preventDefault();
    const { id } = this.props;
    let body = {
      photos: this.state.selectedFiles
    }
    this.setState({ isLoading: true });
    GiftCatalogService.uploadProductImage(id, body)
      .then(data => {
        this.setState({ isLoading: false });
        ModalService.openAlert('Upload Image', 'Image Uploaded Successfully', 'success');
        this.props.closeModal();
      })
      .catch(e => {
        this.setState({ isLoading: false });
        // this.props.closeModal();
        ModalService.openAlert('Upload Image', 'Image Upload Failed', 'error');
      })
  }

  closeModal = () => {
    this.props.closeModal(true);
  }

  render() {
    const { selectedFiles, isLoading } = this.state;
    const imageKeys = Object.keys(selectedFiles);

    const prepareSelectedImageView = imageKeys && imageKeys.length > 0 && imageKeys.map(i =>
      <img src={URL.createObjectURL(selectedFiles[i])} height="100px" width="100px" alt="" />
    )
    return (
      <div>
        <Modal id="change-password" isOpen={this.props.show} toggle={this.props.closeModal} centered>
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            {isLoading && <CustomSpinner></CustomSpinner>}
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col className="modal-title">
                  Upload Image
                </Col>
              </Row>

              <Row className="margin-top-10">
                <input type="file"
                  // name="photos" 
                  multiple={true}
                  onChange={this.handleFileUpload}
                ></input>
              </Row>
              <div className="margin-top-10">
                {prepareSelectedImageView}
              </div>
              <br />
              <div style={{ textAlign: 'right' }}>
                <Button className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" onClick={this.props.closeModal} color="dark">Cancel</Button>
                <Button type="submit" className="mb-1 mr-1 btn-pill Rectangle-button send-button" onClick={this.uploadImage} color="warning">Save</Button>
              </div>
            </Form>
          </ModalBody>

        </Modal>
      </div>
    );
  }
}

export default UploadGiftProductImage; 