import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalBody,
  Row, Col
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

// actions
import { setUserDetail } from '../../actions/UserAction';

// css
import './DeleteInviteeModal.css';

class DeleteInviteeModal extends React.Component {

  state = {
    logoutFromAll: false
  }

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }


  setLogoutFromAllCheckboxValue = () => {
    this.setState({ logoutFromAll: !this.state.logoutFromAll });
  }

  closeModal() {
    this.props.closeModal();
  }

  deleteInvitee = () => {
    this.props.delete(this.props.inviteeId);
  }


  render() {
    return (
      <div>
        <Modal
          id="logout-modal"
          isOpen={this.props.show}
          toggle={this.closeModal}
          className={this.props.className}
          centered
        >
          <ModalBody>
            <br />
            <Row>
              <Col xs="2">
                <FontAwesomeIcon className="arrow-color" style={{ fontSize: '50px', color: '#ffb609' }} icon={faExclamationTriangle} />
              </Col>
              <Col xs="10">
                <div className="modal-title">Delete Invitee</div>
                <div className="modal-text margin-top-10">Are you sure you want to delete this invitee?</div>
                <br />

              </Col>
            </Row>
            <br />
            <Row>
              <Col className="text-align-right">
                <Button
                  color="#e0e2e4"
                  style={{ backgroundColor: '#e0e2e4', borderRadius: '25px', fontSize: '16px', fontWeight: 'bold' }}
                  onClick={this.props.closeModal}
                >No
                </Button>{'   '}
                <Button
                  color="#fdbb1f"
                  style={{ backgroundColor: '#fdbb1f', borderRadius: '25px', fontSize: '16px', fontWeight: 'bold' }}
                  onClick={this.deleteInvitee}
                >Yes
                </Button>{'   '}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

DeleteInviteeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.any,
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
  setUserDetail: user => dispatch(setUserDetail(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInviteeModal);
// export default LogoutModal;
