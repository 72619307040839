import React, { Component } from "react";
import ProposalSerivice from "../../services/ProposalSerivice";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import swal from "sweetalert";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import Validation from "../../services/Validation";
import TextareaAutosize from "react-textarea-autosize";
import { messages } from "../../constants/constant.message";
class AgencyBranding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      proposalStageDetail: props.proposalStageDetail,
      controls: {
        remark: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        document: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        stage: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        branding_stage: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        completed: false,
      },
      isFormValid: false,
      isLoading: false,
      userDetail: Storage.getUserDetail(),
    };
  }

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };
  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { controls } = this.state;
      if (data && data.data) {
        controls.document.value = data.data;
      }
      this.setState({ controls, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (!this.checkActiveStatus()) {
      return false;
    }
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        const { controls } = this.state;
        controls.document.value = "";
        event.target.value = null;
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB.",
          "error"
        );
        return;
      }
      this.uploadFileFormData(file, type);
    }
  };

  submit = () => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    let { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    if (!this.handleValidation(true)) {
      return;
    }
    const obj = {
      remark: controls.remark.value,
      document: controls.document.value,
      stage: controls.stage.value,
      completed:
        controls.stage.value === "Recci Report" ? true : controls.completed,
    };
    // debugger
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.agencyBranding(obj)
      .then((res) => {
        if (res.data.message && res.data.message.search("add") > -1) {
          if (controls.stage.value === "Recci Report") {
            res.data.message = "Recci report added successfully";
          } else {
            res.data.message = "Branding report added successfully";
          }
        } else if (res.data.message && res.data.message.search("update") > -1) {
          if (controls.stage.value === "Recci Report") {
            res.data.message = "Recci report added successfully";
          } else {
            res.data.message = "Branding report added successfully";
          }
        }
        controls = {
          remark: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
          },
          document: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
          },
          stage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
          },
          completed: false,
        };
        swal({
          title: "Congratulations !",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          if (this.fileInput) {
            this.fileInput.value = "";
          }
          this.props.getProposalStageDetail();
        });
        this.setState({ isLoading: false, controls });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  onChangeDate = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const { controls } = this.state;
    controls["visit_date"] = e;
    this.setState({ controls });
  };

  handleInputCheckboxChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName] = !controls[controlName];
    this.setState({ controls });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName].value = e.target.value;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation(false);
    this.handleValidationBrandingStatus();
  };

  displayDate = (date) => {
    return new Date(date);
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { stage } = controls;
    if (stage.touched === true || isSubmit) {
      stage = Validation.notNullValidator(stage);
      stage.valid = !stage.nullValue;
      if (stage.valid === false) {
        stage.showErrorMsg = true;
      } else {
        stage.showErrorMsg = false;
      }
    }
    if (stage.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleValidationBrandingStatus = (isSubmit = false) => {
    let { controls } = this.state;
    let { branding_stage, remark } = controls;
    let isFormValid = false;

    if (branding_stage.touched === true || isSubmit) {
      branding_stage = Validation.notNullValidator(branding_stage);
      branding_stage.valid = !branding_stage.nullValue;
      if (branding_stage.valid === false) {
        branding_stage.showErrorMsg = true;
      } else {
        branding_stage.showErrorMsg = false;
      }
    }

    if (remark.touched === true || isSubmit) {
      remark = Validation.notNullValidator(remark);
      remark.valid = !remark.nullValue;
      if (remark.valid === false) {
        remark.showErrorMsg = true;
      } else {
        remark.showErrorMsg = false;
      }
    }
    if (branding_stage.value === "Branding accepted") {
      remark.valid = true;
      remark.showErrorMsg = false;
    }

    if (remark.valid && branding_stage.valid) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls,
    });
    return isFormValid;
  };

  updateStageStatus = (e) => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    if (!this.handleValidationBrandingStatus(true)) {
      return;
    }
    let { controls } = this.state;
    let reqData = {};
    reqData = {
      uuid: this.state.proposalStageStatus.uuid,
      branding_stage: controls.branding_stage.value,
      remark: controls.remark.value,
    };
    ProposalSerivice.updateBrandingStatus(reqData)
      .then((res) => {
        controls.branding_stage.value = "";
        controls.remark.value = "";
        this.setState({ controls, isLoading: false });
        swal({
          title: "",
          text: "Branding status updated successfully.",
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.getProposalStageDetail();
        });
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  render() {
    const { controls } = this.state;

    let { proposalStageDetail } = this.props;
    return (
      <>
        <br />
        {this.state.isLoading && <CustomSpinner />}
        <Card>
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Branding in progress</b>
              </Col>
            </Row>
            <hr />
            {this.state.userDetail.access_level === "agency" ? (
              <>
                <Row>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>
                        Report Type<span className="required-field">*</span>
                      </Label>
                      <select
                        className="form-control select-height-70rem"
                        name="stage"
                        value={controls.stage.value}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="" selected disabled>
                          Select Report Type
                        </option>
                        <option value="Recci Report">Recci Report</option>
                        <option value="Branding Report">Branding Report</option>
                      </select>
                      {controls.stage.showErrorMsg && (
                        <div className="error">
                          * Please select report type.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>
                        Upload Document (jpeg, jpg, png and pdf file)
                      </Label>
                      <input
                        type="file"
                        name="document"
                        ref={(ref) => (this.fileInput = ref)}
                        accept="image/*,.pdf"
                        onChange={(e) => this.uploadFile(e, "document")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>Remark</Label>
                      <Input
                        type="text"
                        value={controls.remark.value}
                        name="remark"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  {controls.stage.value === "Branding Report" && (
                    <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>Branding Completed</Label>
                        <div className="d-flex-row">
                          <div>
                            <label className="custom-checkboax1--container">
                              <input
                                type="checkbox"
                                name="completed"
                                checked={controls.completed}
                                onChange={(e) =>
                                  this.handleInputCheckboxChange(e)
                                }
                              />
                              <span className="checkmark1" />
                            </label>
                            <span style={{ marginLeft: "10px" }}>
                              WORK COMPLETED
                            </span>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col
                    xl="3"
                    md="12"
                    xs="12"
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: "right" }}
                      onClick={(e) => this.submit()}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
                <br />
              </>
            ) : (
              ""
            )}
            {this.state.userDetail.access_level === "all" &&
              proposalStageDetail.agency_completion_data.length > 0 &&
              proposalStageDetail.agency_completion_data.findIndex(
                (x) => x.step === "Branding Report" && x.completed
              ) > -1 ? (
              <>
                <Row>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>
                        Branding Status<span className="required-field">*</span>
                      </Label>
                      <select
                        className="form-control select-height-70rem"
                        name="branding_stage"
                        value={controls.branding_stage.value}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="" selected disabled>
                          Select Branding Status
                        </option>
                        <option value="Branding accepted">
                          Branding accepted
                        </option>
                        <option value="Modification needed">
                          Modification needed
                        </option>
                        <option value="Branding rejected">
                          Branding rejected
                        </option>
                      </select>
                      {controls.branding_stage.showErrorMsg && (
                        <div className="error">
                          * Please select branding status.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="5" md="12" xs="12">
                    <FormGroup>
                      <Label>
                        Remark
                        {controls.branding_stage.value !==
                          "Branding accepted" && (
                            <span className="required-field">*</span>
                          )}
                      </Label>
                      <TextareaAutosize
                        name="remark"
                        autocomplete="nofill"
                        id="remark"
                        value={controls.remark.value}
                        style={{
                          minHeight: 60,
                          maxHeight: 70,
                          fontSize: "14px",
                        }}
                        className="form-control"
                        onChange={(e) =>
                          this.handleInputChange({
                            target: { name: "remark", value: e.target.value },
                          })
                        }
                        placeholder=""
                      />
                      {controls.remark.showErrorMsg && (
                        <div className="error">* Please enter remark.</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="3"
                    md="12"
                    xs="12"
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: "right" }}
                      onClick={(e) => this.updateStageStatus()}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
                <br />
              </>
            ) : (
              ""
            )}
            {proposalStageDetail &&
              proposalStageDetail.agency_completion_data &&
              proposalStageDetail.agency_completion_data.length > 0 && (
                <Table className="mb-0  tbl-about tbl-about table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Report Type</th>
                      <th scope="col">Document</th>
                      <th scope="col">Remarks</th>
                      <th scope="col">Work Completed</th>
                      <th scope="col">Report Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposalStageDetail.agency_completion_data.map((item) => (
                      <tr>
                        <td>{item && item.step}</td>
                        <td>
                          {item && item.document && (
                            <a
                              className="aa d-flex-column"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.document}
                            >
                              <img
                                src="images/sidebar-icons/secondary-report-selected.svg"
                                height="40px"
                                style={{ margin: "auto" }}
                                alt=''
                              />
                              <span className="redirect-link">View</span>
                            </a>
                          )}
                        </td>
                        <td
                          style={{ wordBreak: "break-word", maxWidth: "100px" }}
                        >
                          {item && item.remark}
                        </td>
                        <td>{item && item.completed ? "Yes" : "No"}</td>
                        <td>{item && item.created_at}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default AgencyBranding;
