import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
} from "reactstrap";
import ReactDOM from 'react-dom';
import "../AddCustomer.css";
// import moment from "moment";
import moment from "moment-timezone";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
// services
import Select, { components } from "react-select";
import AddCustomerService from "../../../services/AddCustomerService";
import CustomerManagementService from "../../../services/CustomerManagementService";
import Validation from "../../../services/Validation";
import TextareaAutosize from "react-textarea-autosize";
import ModalService from "../../../services/ModalService";
import { messages } from "../../../constants/constant.message";
import GoogleAnalticsService from "../../../services/GoogleAnalticsService";

class CustomerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPurpose: null,
      customer_services: [],
      selectedServices: [],
      phone_number: null,
      remark: null,
      birthdate: null,
      anniversary: null,
      Existing: false,
      ViewHistory: null,
      customer_vehicle: [],
      customer_detail: {},
      customgroupname: false,
      Editing: false,
      isLoading: false,
      groupData: [],
      header_title: "ADD CUSTOMER DETAILS",
      controls: {
        birth_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        anniversary_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        first_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        last_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        group_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        group_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_brand_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_brand_model_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        email_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        remark: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      refer_note: '',
      serachValueGroup: '',
      isopenGroupMenu: false,
      remarks: '',
      couponData: null
    };
    if (props.couponData && Object.keys(props.couponData).length > 0 && props.couponData.phone_number) {
      this.state.couponData = props.couponData;
    } else {
      this.state.couponData = null;
    }
  }

  componentDidMount = () => {
    // this.setState(
    //   {
    //     phone_number: this.props && this.props.phone_number,
    //   },
    //   () => {
    //     this.getCustomerDetail();
    //     this.getGroups();
    //   }
    // );
    //this.getGroups();
  };

  /**This function customInit is getting called from parent Component AddCustomer using ref */
  customInit = async () => {
    if (this.props.couponData && Object.keys(this.props.couponData).length > 0 && this.props.couponData.phone_number) {
      await this.setState({
        couponData: this.props.couponData
      })
    } else {
      await this.setState({
        couponData: null
      })
    }
    this.getGroups();
    let { customer_detail } = this.props;
    let { controls, refer_note, remarks } = this.state;
    /**Setting the appropriate value of controls from this.props.customer_detail */
    if (customer_detail && Object.keys(customer_detail).length > 0) {
      controls["first_name"].value = customer_detail.first_name;
      controls["last_name"].value = customer_detail.last_name;
      controls["birth_date"].value = customer_detail.birth_date ? new Date(customer_detail.birth_date) : null;
      controls["anniversary_date"].value = customer_detail.anniversary_date ? new Date(customer_detail.anniversary_date) : null;
      controls.group_id.value =
        customer_detail.member_of_group && customer_detail.member_of_group.length > 0
          ? customer_detail.member_of_group
          : [];
      controls["remark"].value = customer_detail.remark;
      controls["email_id"].value = customer_detail.email_id;
      refer_note = customer_detail.refer_note;
      remarks = customer_detail.remarks;
    } else {
      controls["first_name"].value = '';
      controls["last_name"].value = '';
      controls["birth_date"].value = '';
      controls["anniversary_date"].value = null;
      controls.group_id.value = [];
      controls["remark"].value = '';
      controls["email_id"].value = '';
      refer_note = '';
      remarks = customer_detail.remarks;
    }
    this.setState({
      controls,
      Existing: this.props.Existing,
      customer_vehicle: this.props.customer_vehicle,
      customer_detail: this.props.customer_detail,
      refer_note,
      remarks
    });
  };

  updateCustomer = (e) => {
    let obj = {
      customer_id: this.state.customer_detail.customer_id,
    };
    CustomerManagementService.addCustomer(obj)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleInputChange = (e) => {
    /**Method to set the value of the input box on basis of name attribute */
    const { controls } = this.state;
    const controlName = e.target.name;
    const controlValue = e.target.value;
    if (
      controlValue &&
      (controlName === "first_name" || controlName === "last_name")
    ) {
      controls[controlName].value = controlValue.replace(/\b\w/g, l =>
        l.toUpperCase()
      );
      controls[controlName].touched = true;
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    this.setState({ controls });
    this.handleValidation();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDatePicker = (date) => {
    const { controls } = this.state;
    controls["started_at"].value = date;
    this.setState({ controls });
  };

  viewHistory = (history) => {
    this.setState({
      ViewHistory: history,
    });
    this.props.viewHistory(history);
  };

  getCustomerDetail = () => {
    let params = {
      country_code: "91",
      phone_number: this.state.phone_number,
    };
    this.setState({
      isLoading: true,
    });
    AddCustomerService.getCustomerDetail(params)
      .then((res) => {
        if (
          res.data.data.customer_vehicle &&
          res.data.data.customer_vehicle.length > 0
        ) {
          this.setState({
            Existing: true,
          });
          this.props.editLabel();
        }
        this.setState(
          {
            customer_vehicle: res.data.data && res.data.data.customer_vehicle,
            customer_detail: res.data.data && res.data.data.customer,
          },
          () => {
            this.setState({
              isLoading: false,
            });
          }
        );
      })
      .catch((err) => {
        console.log("error", err);
        this.setState({
          isLoading: false,
        });
      });
  };

  /**Method to get all groups which is getting called from custom init */
  getGroups = () => {
    this.props.isLoading(true);
    CustomerManagementService.getGroups({ page: 1, pageSize: 1000 })
      .then((res) => {
        this.props.isLoading(false);
        let { groupData } = this.state;
        if (
          res.data.data &&
          res.data.data.groups &&
          res.data.data.groups.length > 0
        ) {
          if (res.data.data.count !== 0) {
            this.setState({
              customgroupname: false,
            });
          }
          groupData = res.data.data.groups;
          // this.state.controls.group_id.value.push(
          //   res.data.data.groups[0].group_id
          // );
        } else {
          groupData = [];
        }
        this.setState({
          groupData,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.props.isLoading(false);
        console.log(e);
      });
  };

  //  ========================AddGroup======================

  /**Method to add the value of newGroup which is created */
  addGroup = async () => {
    let { controls } = this.state;
    if (!controls.group_name.value) {
      ModalService.openAlert('', 'Please enter group name.', 'error');
      return;
    }
    const reqData = {
      name: controls.group_name.value,
    };

    this.props.isLoading(true);
    let group_id = await CustomerManagementService.addGroup(reqData)
      .then((res) => {
        this.props.isLoading(false);
        this.getGroups();
        let { controls } = this.state;
        if (res.data.data.group_id) {
          controls.group_name.value = '';
          controls.group_id.value.push({
            group_id: res.data.data.group_id,
            created_on: new Date().toISOString(),
            group_name: controls.group_name.value,
            member_count: 0
          })
        }
        this.setState({
          controls
        })
        return res.data.data.group_id;
      })
      .catch((e) => {
        this.props.isLoading(false);
        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error')
        console.log(e);
        return null;
      });
    return group_id;
  };

  /**Function getting called when we click on Create Group or Cancel while creating new group */
  addNewGroup = () => {
    let { controls } = this.state;
    if (!this.state.customgroupname) {
      controls.group_name.value = '';
    }
    this.setState({
      customgroupname: !this.state.customgroupname,
      controls
    });
  };

  editCustomer = () => {
    GoogleAnalticsService.event('Customer Detail', 'Add Personal Details Click');
    this.props.editCustomer();
  };

  addnewVehicle = () => {
    GoogleAnalticsService.event('Customer Detail', 'Add Vehcile Click');
    this.props.addnewVehicle();
  };

  editVehicle = (index, vehicle_type) => {

    if (this.state.couponData && this.state.couponData.type) {
      if (vehicle_type.toLowerCase() !== this.state.couponData.type) {
        ModalService.openAlert("", `Coupon code ${this.state.couponData.coupon_code} is only valid for the vehicle type is ${this.props.couponData.type_name}`, "warning");
        return;
      }
    }
    this.props.editVehicle(index);
  };

  deleteCustomerConfirm = (index = null) => {
    swal({
      title: "Delete Vehicle",
      text: "Are you sure, you want to delete vehicle ?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.deleteVehicle(this.props.customer_vehicle[index].vehicle_id ?
          this.props.customer_vehicle[index].vehicle_id : this.props.customer_vehicle[index].customer_vehicle_id);
      }
    });
  };

  /**To delete the vehicle on basis of id and getting the updated list */
  deleteVehicle = (vehicle_id = null) => {
    let reqData = {
      customer_id: this.state.customer_detail.customer_id,
    };
    if (vehicle_id) {
      reqData.vehicle_id = vehicle_id;
    }
    this.props.isLoading(true);
    CustomerManagementService.deleteCustomerVehicle(reqData)
      .then((res) => {
        this.props.isLoading(false);
        this.props.getCustomerVehicle(this.state.customer_detail.customer_id);
        if (res.data.message) {
          swal({
            title: "Deleted",
            text: res.data.message,
            icon: "success",
            type: "success",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.props.isLoading(false);
      });
  };

  onChangebirthday = (date) => {
    let { controls } = this.state;
    controls.birth_date.value = date;
    this.setState(
      controls
    )
  };

  onChangeAnniversary = (date) => {
    let { controls } = this.state;
    controls.anniversary_date.value = date;
    this.setState(
      controls
    )
  };

  /**Validation Method to check the required value is present or not */
  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let isFormValid = false;
    let {
      first_name, last_name, email_id
    } = controls;


    if ((first_name.touched === true || isSubmit)) {
      first_name = Validation.notNullValidator(first_name);
      first_name.valid = !first_name.nullValue;
      if (first_name.valid === false) {
        first_name.showErrorMsg = true;
      } else {
        first_name.showErrorMsg = false;
      }
    }

    if ((last_name.touched === true || isSubmit)) {
      last_name = Validation.notNullValidator(last_name);
      last_name.valid = !last_name.nullValue;
      if (last_name.valid === false) {
        last_name.showErrorMsg = true;
      } else {
        last_name.showErrorMsg = false;
      }
    }

    if (email_id.touched === true || isSubmit) {
      email_id = Validation.notNullValidator(email_id);
      if (!email_id.nullValue) {
        email_id = Validation.emailValidator(email_id);
        email_id.valid = !email_id.invalidEmail;
        if (email_id.valid) {
          email_id.showErrorMsg = false;
        } else {
          email_id.showErrorMsg = true;
        }
      } else {
        email_id.valid = true;
        email_id.showErrorMsg = false;
      }

      if (last_name.valid === false) {
        last_name.showErrorMsg = true;
      } else {
        last_name.showErrorMsg = false;
      }
    }

    if (
      last_name.valid === true &&
      first_name.valid === true &&
      email_id.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  }

  /**Customzied select dropdown which returns component */
  ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${nbValues} selected`}</span>
          {props.selectProps.menuIsOpen && <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }}
            name="serachValueGroup" value={this.state.serachValueGroup}
            onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };

  onChangeFilter = e => {
    let controlName = e.target.name;
    this.setState({
      [controlName]: e.target.value
    });
  };

  customFilter = (option, searchText) => {
    if (
      option.data.group_name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };


  /**To close the customized dropdown by clicking outside the modal */
  handleClickOutside = (event) => {
    if (this.refGroupId) {
      const domNode = ReactDOM.findDOMNode(this.refGroupId);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refGroupId.state.menuIsOpen) {
          this.refGroupId.onMenuClose();
          this.setState({
            isopenGroupMenu: false
          })
        }
      }
    }
  }

  openCloseMenu = (open) => {
    this.setState({
      isopenGroupMenu: open
    })
  }

  onInputClickDate = () => {
    this.props.scrollTo('customer-detail-botttom');
  }

  generateServiceToolTip = (services) => {
    let serviceString = '';
    if (services && Array.isArray(services) && services.length > 0) {
      for (let i = 0; i < services.length; i++) {
        if (i === 0) {
          serviceString += services[i];
        } else {
          serviceString += `\n${services[i]}`
        }
      }
    }
    return serviceString;
  }


  generateServiceText = (lastVisitService) => {
    let c = null;
    if (lastVisitService && Array.isArray(lastVisitService) && lastVisitService.length > 0) {
      if (lastVisitService.length === 1) {
        c = <span className="sub-value-table-service d-flex">({lastVisitService[0]})</span>
      } else {
        c = <span className="sub-value-table-service d-flex" id="customer-list-tooltip">({lastVisitService[0]})
          <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={this.generateServiceToolTip(lastVisitService)} >
            <span className="sub-value-more cursor-pointer">+{lastVisitService.length - 1}</span>
          </span>
          {/* <span className="sub-value-more cursor-pointer" title={this.generateServiceToolTip(lastVisitService)}>+{lastVisitService.length - 1}</span> */}
        </span>
      }
    }
    return c;
  }

  render() {
    const { controls, customer_detail } = this.state;
    let { customer_vehicle } = this.props;

    /**Custom option to create checkbox in select Box */
    const CustomOption = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label className="custom-checkboax1--container"
              ref={innerRef} {...innerProps}>
              <input
                type="checkbox"
                name="service"
                checked={props.isSelected === true || props.isSelected === 'true'}
              />
              <span className="checkmark1" />
            </label>

            <span className="ml-2" ref={innerRef} {...innerProps} style={{ marginTop: '-2px' }}>{props.data.group_name}</span>
          </div>
        </>
      );
    };


    const customStyles = {
      control: base => ({
        ...base,
        maxHeight: 70,
        overflow: "auto",
        theme: "primary",
        borderColor: "#fee19d",
        outline: "0",
        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
      }),
      input: styles => "text"
    };

    return (
      <div className="m-0 p-0" onClick={this.handleClickOutside}>
        {/** View loaded when their is no customer detail */}
        {!this.state.Existing || (customer_vehicle.length <= 0) ? (
          <>
            <Row className="ml-0 mt-2">
              <Col xl="12" md="12" xs="12" className="mb-2">
                <span className="Please-provide-owner">
                  Please provide owner details.
                </span>
                {
                  this.state.refer_note &&
                  <div className="service-pref-note">
                    Note - {this.state.refer_note}.
                  </div>
                }
                {/* <div className="half-yellow"></div> */}
              </Col>

              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label for="common">
                    First Name &nbsp;
                    <span className="required-field">*</span>
                  </Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="first_name"
                    id="first_name"
                    value={controls.first_name.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.first_name.showErrorMsg && <div className="error show_error">
                    *Please enter first name
                  </div>}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label for="common">
                    Last Name &nbsp;
                    <span className="required-field">*</span>
                  </Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="last_name"
                    id="last_name"
                    value={controls.last_name.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.last_name.showErrorMsg &&
                    <div className="error show_error">
                      *Please enter last name
                    </div>}
                </FormGroup>
              </Col>

              <Col xl="12" md="12" xs="12">
                <FormGroup className="margin-bottom-0-4rem">
                  <Label for="common">Add Remark</Label>
                  <TextareaAutosize
                    name="comment"
                    autocomplete="nofill"
                    id="remark"
                    value={controls.remark.value}
                    style={{ minHeight: 40, maxHeight: 90, fontSize: '14px' }}
                    className="form-control"
                    onChange={(e) => this.handleInputChange({ target: { name: 'remark', value: e.target.value } })}
                    placeholder='Type here'
                  />
                </FormGroup>
              </Col>

              <Col xl="12" md="12" xs="12">
                <div className="PDetails" style={{ marginTop: '10px' }}>PERSONAL DETAILS</div>
              </Col>


              <Col xl="6" md="12" xs="12">
                <FormGroup className="margin-bottom-0-4rem">
                  <Label for="password" className="mb-0">Birthdate</Label>
                  <Label className="width-100">
                    <Label
                      onClick={(e) =>
                        this.calendar.state.open && e.preventDefault()
                      }
                      className="display-botttom-datepicker"
                    >
                      <InputGroup>
                        <DatePicker
                          placeholderText="select"
                          className={"form-control"}
                          selected={controls.birth_date.value}
                          onChange={this.onChangebirthday}
                          showMonthDropdown
                          ref={(r) => (this.calendar = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          maxDate={new Date()}
                          dropdownMode="select"
                          onInputClick={this.onInputClickDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </Label>
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup className="margin-bottom-0-4rem">
                  <Label for="password" className="mb-0">Anniversary Date</Label>
                  <Label className="width-100">
                    <Label
                      onClick={(e) =>
                        this.calendar1.state.open && e.preventDefault()
                      }
                      className="display-botttom-datepicker"
                    >
                      <InputGroup>
                        <DatePicker
                          placeholderText="select"
                          className={"form-control"}
                          selected={controls.anniversary_date.value}
                          onChange={this.onChangeAnniversary}
                          showMonthDropdown
                          ref={(r) => (this.calendar1 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          maxDate={new Date()}
                          dropdownMode="select"
                          onInputClick={this.onInputClickDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </Label>
                </FormGroup>
              </Col>


              <Col xl="6" md="12" xs="12">
                <FormGroup className="margin-bottom-0-4rem">
                  <Label for="common">Email Address</Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="email_id"
                    id="first_name"
                    value={controls.email_id.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.email_id.showErrorMsg &&
                    <div className="error show_error">
                      *Please enter valid email
                    </div>}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup className="margin-bottom-0-4rem">
                  <Label for="common">Group</Label>
                  {
                    this.state.customgroupname ?
                      <>
                        <span className="add-group text-align-right cursor-pointer" onClick={this.addNewGroup}
                          style={{ width: 'auto' }}>
                          Cancel
                        </span>&nbsp;&nbsp;
                        <span className="add-group text-align-right cursor-pointer" onClick={this.addGroup}
                          style={{ width: 'auto', paddingRight: '15px' }}>
                          Save
                        </span>
                      </>
                      : <span className="add-group text-align-right cursor-pointer" onClick={this.addNewGroup}>
                        Create Group</span>
                  }
                  {(this.state.customgroupname === false || this.state.customgroupname === 'false') ? (
                    <Select
                      {...this.props.input}
                      ref={r => this.refGroupId = r}
                      name="group_id"
                      pageSize="1"
                      getOptionLabel={(option) => option.group_name}
                      getOptionValue={(option) => option.group_id}
                      options={this.state.groupData}
                      allowSelectAll
                      onMenuOpen={() => this.openCloseMenu(true)}
                      onMenuClose={() => this.openCloseMenu(false)}
                      closeMenuOnSelect={false}
                      value={controls.group_id.value}
                      tabSelectsValue={false}
                      isSearchable={true}
                      filterOption={this.customFilter}
                      onChange={(e) => this.handleInputChange({ target: { name: 'group_id', value: e } })}
                      onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueGroup', value: e } })}
                      placeholder="Search..."
                      styles={customStyles}
                      hideSelectedOptions={false}
                      inputValue={this.state.serachValueGroup}
                      components={{ Option: CustomOption, ValueContainer: this.ValueContainer }}
                      backspaceRemoves={false}
                      menuPlacement="top"
                      className={this.state.isopenGroupMenu ? 'z-index-102 add-customer-react-select' : 'add-customer-react-select'}
                      isMulti
                    />

                  ) : (
                    <Input
                      type="text"
                      name="group_name"
                      placeholder="Type here"
                      value={controls.group_name.value}
                      onChange={(e) => {
                        this.handleInputChange(e);
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {/**Fragment which gets load when their is Customer Details  */}
            <Row className="ml-0">
              <Col xl="12" xs="12" md="12">
                <div className="user-det T-10 pb-3">
                  <Row>
                    <Col xl="4" xs="4" md="4">
                      <span className="user-name">
                        {customer_detail.first_name}&nbsp;&nbsp;
                        {customer_detail.last_name}
                      </span>
                    </Col>
                    <Col xl="7" xs="7" md="7" className="display-F flex-wrap pr-0">
                      {customer_detail &&
                        customer_detail.services_name &&
                        customer_detail.services_name.map((item) => (
                          <div className="service-tag mb-2 mr-2 pl-2 pr-2">
                            <span>{item}</span>
                          </div>
                        ))}
                    </Col>
                    <Col
                      xl="1"
                      xs="1"
                      md="1"
                      onClick={this.editCustomer}
                      style={{ cursor: "pointer" }}
                    >
                      <img src="/images/edit.svg" alt='' />
                    </Col>
                  </Row>
                  <Col className="remark-text">
                    <span>{customer_detail.remarks}</span>
                  </Col>
                </div>
              </Col>
              <Col
                xl="6"
                xs="6"
                md="6"
                className="mt-3"
              >
                <div className="add-newVehivle-btn display-F box-shadow-10px cursor-pointer" onClick={this.addnewVehicle}>
                  <div className="mt-auto mb-auto">
                    <img src="/images/plus_blue.svg" className="Fill-1 mt-auto mb-auto ml-2" alt='' />
                    &nbsp;&nbsp;
                    <span className="ANV mt-auto mb-auto">Add New Vehicle</span>
                  </div>
                </div>
              </Col>

              <Col
                xl="6"
                xs="6"
                md="6"
                className="mt-3"
              >
                <div className="add-newVehivle-btn display-F box-shadow-10px cursor-pointer cursor-pointer"
                  // onClick={this.addnewVehicle}
                  onClick={() => this.viewHistory(null)}>
                  <img src="/images/history_icon.png" className="Fill-1 mt-auto mb-auto ml-2" alt='' />
                  &nbsp;&nbsp;
                  <span className="ANV mt-auto mb-auto">Visit History</span>
                </div>
              </Col>
              <Col xl="12" className="mt-3"></Col>
              {customer_vehicle &&
                customer_vehicle.map((item, i) => (
                  <div className="edit-veh-det-view w-100 mb-3" style={{ paddingTop: '10px', marginLeft: '15px', marginRight: '15px' }}>
                    <table className="w-100 tbl-vehicle-detail" cellspacing="0">
                      <tr>
                        <td>
                          <label className="custom-checkboax-radio--container"
                            style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <input
                              type="radio"
                              name="personal"
                              value="personal"
                              checked={false}
                              onChange={e =>
                                this.editVehicle(i, item.vehicle_type)}
                            />
                            <span className="checkmark-radio" />
                          </label>
                        </td>
                        <td className="borde-bottom-EFF1F3">
                          <div className="d-flex-column mb-2">
                            <div className="d-flex-row">
                              <span style={{ fontSize: "12px" }}>
                                Vehicle Type
                              </span>
                            </div>
                            <div>
                              <img
                                src={item.vehicle_type_image_url}
                                className="vehicle-image"
                                alt=''
                              />
                            </div>
                          </div>
                        </td>
                        <td className="borde-bottom-EFF1F3">
                          <div className="d-flex-column pb-2" style={{ width: '128px', minWidth: '128px' }}>
                            <div className="V-t-card mb-1" style={{ width: 'auto' }}>Vehicle Detail</div>
                            <div className="V-t-model mb-1" style={{ width: 'auto' }}>
                              {
                                item.vehicle_type_brand_name || item.vehicle_type_brand_model_name ?
                                  <b>
                                    {item.vehicle_type_brand_name}&nbsp;&nbsp;
                                    {item.vehicle_type_brand_model_name}
                                  </b> : '--'
                              }
                            </div>
                            <div className="V-t-number mb-1" style={{ width: 'auto' }}>{item.registration_number}</div>
                          </div>
                        </td>
                        <td className="borde-bottom-EFF1F3">
                          <div className="V-t-card mb-1">Usage Type</div>
                          <div className="V-t-model mb-1">
                            <b>{item.vehicle_category ? (item.vehicle_category.toLowerCase() === 'taxi' ? 'Commercial' : item.vehicle_category) : ''}</b>
                          </div>
                        </td>
                        <td >
                          <div className="del-img">
                            <img
                              className="cursor-pointer"
                              src="/images/delete.svg"
                              alt=''
                              onClick={() =>
                                this.deleteCustomerConfirm(i)
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="vertical-align-middle">
                          <div style={{ fontSize: "12px" }} className="mb-2 mt-2">
                            Last Visit Date
                          </div>
                        </td>
                        <td className="vertical-align-middle">
                          <div className="d-flex-row justify-content-between">
                            <div className="V-t-model">
                              {
                                item.last_visit_date ?
                                  <b>
                                    {" "}
                                    { }
                                    {item.last_visit_date &&
                                      moment.tz(item.last_visit_date, 'Asia/Kolakata').format("DD MMM,YYYY")}
                                  </b> : <b>--</b>
                              }
                            </div>
                          </div>
                        </td>
                        <td className="vertical-align-middle">
                          <div className="d-flex-column mt-2 mb-2">
                            <div className="d-flex-row">
                              <span>Purpose</span>&nbsp;
                              <span style={{ fontSize: "14px" }}>
                                {
                                  item.last_purpose_name ?
                                    <b>{item.last_purpose_name}</b> :
                                    <b>--</b>
                                }
                              </span>
                            </div>
                            {
                              item.last_purpose_service_name && item.last_purpose_service_name.length > 0 &&
                              <span>
                                {/* {item.last_purpose_service_name.map((last_purpose_service_name, i) => (
                                    <>
                                      {last_purpose_service_name}
                                      {
                                        (i !== (item.last_purpose_service_name.length - 1)) && ', '
                                      }
                                    </>
                                  ))
                                  } */}
                                {this.generateServiceText(item.last_purpose_service_name)}
                              </span>
                            }
                          </div>
                        </td>
                        <td>
                          <div
                            className="del-img"
                            onClick={() => this.viewHistory(item)}
                          >
                            <img src="/images/history_icon.png" alt='' />
                          </div>
                        </td>
                      </tr>
                      <tr className="upcoming-aapoint">
                        <td></td>
                        <td className="vertical-align-middle" style={{ height: '40px' }}>
                          <div style={{ fontSize: '12px' }} className="d-flex">
                            <span style={{ width: '130px' }}>Upcoming Appointment</span>
                          </div>
                        </td>
                        <td className="vertical-align-middle">
                          {
                            item.upcoming_due_date ?
                              <div className="V-t-model d-flex" style={{ marginTop: '5px' }}>
                                <b>{moment(item.upcoming_due_date).format("DD MMM,YYYY")}</b>
                              </div> :
                              <b>--</b>
                          }
                        </td>
                        <td className="vertical-align-middle">
                          <div className="V-t-card-2 d-flex-column flex-wrap pt-2 pb-2">
                            <div className="d-flex-row">
                              <span>Purpose &nbsp;&nbsp;&nbsp;{" "}</span>
                              <span style={{ fontSize: "14px" }}>
                                {
                                  item.upcoming_purpose_name ?
                                    <b>{item.upcoming_purpose_name}</b> :
                                    <b>--</b>
                                }
                              </span>
                            </div>
                            {/* {item.upcoming_purpose_service_name && <span> {item.upcoming_purpose_service_name}</span>} */}
                            {
                              item.upcoming_purpose_service_name && item.upcoming_purpose_service_name.length > 0 &&
                              <span>
                                {/* {item.upcoming_purpose_service_name.map((upcoming_purpose_service_name, i) => (
                                    <>
                                      {upcoming_purpose_service_name}
                                      {
                                        (i !== (item.upcoming_purpose_service_name.length - 1)) && ', '
                                      }
                                    </>
                                  ))
                                  } */}
                                {this.generateServiceText(item.upcoming_purpose_service_name)}
                              </span>
                            }
                            {
                              item.type_of_inquiry &&
                              <div className="V-t-card-2 d-flex-column">
                                Type of Inquiry &nbsp;&nbsp;&nbsp;{" "}
                                <span style={{ fontSize: "14px" }} className="mt-1">
                                  <b>{item.type_of_inquiry}&nbsp;</b>
                                </span>
                              </div>
                            }
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                ))}
            </Row>
          </>
        )}
        <div id="customer-detail-botttom"></div>
      </div>
    );
  }
}

export default CustomerDetail;
