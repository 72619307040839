import React, { Component } from "react";
import "./CustomerManagement.css";
import ListOfCustomers from "../../components/ListOfCustomers/ListOfCustomers";
// import BlockCustomer from "../../components/BlockCustomer/BlockCustomer";
// import ManageGroup from "../../components/ManageGroup/ManageGroup";

class CustomerManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
    this.childListOfCustomers = React.createRef();
  }

  componentDidMount = () => {
    if (window.location.hash.split("/")[2]) {
      if (window.location.hash.split("/")[2] === "block-list") {
        this.changeTab(2);
      }
    }

  };

  changeTab = (activeTab) => {
    this.setState((prevState) => {
      return { ...prevState, activeTab: activeTab };
    });
  };

  render() {
    return (
      <>
        <span className="header-title">Customer List</span>
        {/* <br />
        <br />
        <br /> */}

        <div>
          {/* <div className="d-flex-row">
            <span
              onClick={() => this.changeTab(0)}
              className={
                this.state.activeTab === 0
                  ? "custom-rc-tab-active"
                  : "custom-rc-tab-deactive"
              }
            >
              List of Customers
            </span>
            <span
              onClick={() => {
                this.changeTab(1);
              }}
              className={
                this.state.activeTab === 1
                  ? "custom-rc-tab-active ml-4"
                  : "custom-rc-tab-deactive ml-4"
              }
            >
              Manage Groups
            </span>
            <span
              onClick={() => this.changeTab(2)}
              className={
                this.state.activeTab === 2
                  ? "custom-rc-tab-active ml-4"
                  : "custom-rc-tab-deactive ml-4"
              }
            >
              Block List
            </span>
          </div> */}
          <div>
            {/* {this.state.activeTab === 0 && ( */}
            <ListOfCustomers
              ref={this.childListOfCustomers}
              editCustomer={this.openAddEditCustomer}
              openAddEditCustomer={this.openAddEditCustomer}
              history={this.props.history}
            />
            {/* )} */}
          </div>
          {/* <div>
            {this.state.activeTab === 2 && <BlockCustomer />}
            {this.state.activeTab === 1 && <ManageGroup fromComponent={true} />}
          </div> */}
        </div>
      </>
    );
  }
}

export default CustomerManagement;
