import React, { Component, Fragment } from 'react';
import { Row, Col, Card, CardBody, Table, Form, FormGroup, Label, Input, Button, InputGroup, InputGroupAddon } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Ionicon from 'react-ionicons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faDownload, faSave } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import sweetAlert from 'sweetalert';
import PropTypes from 'prop-types';
import _ from 'lodash';

// components
import Pagination from '../Pagination/Pagination';
import CustomSpinner from '../CustomSpinner/CustomSpinner';

// modals
import MerchandiseOrderDetails from '../../modal/MerchandiseOrderDetails/MerchandiseOrderDetails';
import OrderStatusUpdate from '../../modal/OrderStatusUpdate/OrderStatusUpdate';
import PerformaInvoiceUpdate from '../../modal/PerformaInvoiceUpdate/PerformaInvoiceUpdate';
import OrderTrackModal from '../../modal/OrderTrackModal/OrderTrackModal';

// services
import MerchandiseService from '../../services/MerchandiseService';
import ModalService from '../../services/ModalService';

// utils
import { formatDate, downlodFile } from '../../utils/utils';

const initialPagination = {
  pageCount: 0,
  page: 1,
  limit: 10,
  counts: 0
};
const initialSearch = {
  sapIdOrGroupId: '',
  status: 'all',
  fromDate: null,
  toDate: null,
};
const orderStatus = [
  { value: 'in_progress', text: 'In Progress' },
  { value: 'ready_to_dispatch', text: 'Ready to dispatch' },
  { value: 'dispatch', text: 'Dispatch' },
  { value: 'delivered', text: 'Delivered' },
  { value: 'fail_to_deliver', text: 'Delivery Failed' },
];

const statusList = [
  { value: 'out_of_stock', text: 'Out of stock' },
  ...orderStatus
];

class MerchandiseReportComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      selectedOrder: {},
      showOrderDetailsModal: false,
      pagination: initialPagination,
      search: initialSearch,
      trackingId: '',
      trackingIdOfOrderId: '',
      isLoading: false,
      showConfirDialog: false,
      confirmDialogTitle: 'Update order status',
      confirmDialogMsg: '',
      confirmDialogData: {},
      selectedOrders: [],
      showPerformaInvoiceupdateModal: false,
      selectedAwbForTrack: null,
      isOrderTrackModalOpen: false
    };

    this.getMerchandiseOrders = this.getMerchandiseOrders.bind(this);
    this.getOrderRows = this.getOrderRows.bind(this);
    this.getSearchBar = this.getSearchBar.bind(this);
    this.onSearchFormChange = this.onSearchFormChange.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.hanldeStatusChange = this.hanldeStatusChange.bind(this);
    this.showOrderDetails = this.showOrderDetails.bind(this);
    this.hideOrderDetails = this.hideOrderDetails.bind(this);
    this.updateOrderInList = this.updateOrderInList.bind(this);
    this.onTrackingIdChange = this.onTrackingIdChange.bind(this);
    this.updateTrackingId = this.updateTrackingId.bind(this);
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
    this.selectOrder = this.selectOrder.bind(this);
    this.printPerformaInvoice = this.printPerformaInvoice.bind(this);
    this.closePerformaInvoiceUpdateModal = this.closePerformaInvoiceUpdateModal.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  componentDidMount() {
    this.getMerchandiseOrders();
  }


  onSearchFormChange(e) {
    const { target: { name, value } } = e;
    const controlName = name;
    const controlValue = value;
    const updateSearchState = {
      [controlName]: controlValue
    };
    if (controlName === 'status' && controlValue === 'all') {
      updateSearchState.fromDate = '';
      updateSearchState.toDate = '';
    }

    this.setState((prevState) => {
      return {
        pagination: initialPagination,
        search: {
          ...prevState.search,
          ...updateSearchState
        }
      };
    }, this.getMerchandiseOrders);
  }

  onTrackingIdChange(e, orderId) {
    const { target: { name, value } } = e;
    const controlName = name.split(':')[0] || null;
    const controlValue = value;

    if (controlName) {
      this.setState({
        [controlName]: controlValue !== '' ? controlValue : null,
        trackingIdOfOrderId: orderId
      });
    }
  }

  async getMerchandiseOrders(download = false) {
    const {
      pagination: { limit, page },
      search: { sapIdOrGroupId = null, status, fromDate = null, toDate = null }
    } = this.state;
    const offset = (page - 1) * limit;
    const filters = {
      offset,
      limit,
      status
    };

    this.setState({ isLoading: true, orders: [] });

    if (sapIdOrGroupId) {
      filters.sapIdOrGroupId = sapIdOrGroupId;
    }

    if (fromDate) {
      filters.fromDate = moment(new Date(fromDate)).format('MM/DD/YYYY');
    }

    if (toDate) {
      filters.toDate = moment(new Date(toDate)).add(1, 'days').format('MM/DD/YYYY');
    }


    if (download) {
      const { data: { file } } = await MerchandiseService.getMerchandiseOrders({ ...filters, download: true });
      this.setState({ isLoading: false });
      return downlodFile(file);
    }

    const { data: { orders, totalCount }, success } = await MerchandiseService.getMerchandiseOrders(filters);
    this.setState({ isLoading: false });
    if (success) {
      this.setState(prevState => {
        const totalPages = Math.ceil(totalCount / limit);
        return {
          orders,
          pagination: {
            ...prevState.pagination,
            counts: totalCount,
            pageCount: totalPages
          }
        };
      });
      return true;
    }

    return false;
  }

  getOrderRows() {
    const { orders, trackingId = '', trackingIdOfOrderId, isLoading } = this.state;
    return orders.map((order, index) => {
      const orderDate = formatDate(order.date);
      const deliveryDate = order.delivery_date ? formatDate(order.delivery_date) : '-';
      const dispatchDate = order.dispatch_date ? formatDate(order.dispatch_date) : '-';
      const readyToDispatchDate = order.ready_to_dispatch_date ? formatDate(order.ready_to_dispatch_date) : '-';

      return (
        <Fragment key={order.id}>
          {!isLoading &&
            <tr>
              <td>{order.status && (<input type="checkbox" onChange={(e) => this.selectOrder(e, order)} />)}</td>
              <td><Button style={{ padding: 0, color: '#007bff' }} color="link" onClick={(e) => this.showOrderDetails(e, order, index)}>{order.sap_id}</Button></td>
              <td>{order.business_name}</td>
              <td>{order.item_name}</td>
              <td>{order.item_quantity}</td>
              <td>{orderDate}</td>
              <td>{readyToDispatchDate}</td>
              <td>{dispatchDate}</td>
              <td>{deliveryDate}</td>
              <td>
                {order.status && order.status !== 'out_of_stock' && (<Input type="select" name="status" id="status"
                  style={{ width: '135px', padding: 0, fontSize: '0.88rem' }}
                  value={order.status}
                  onChange={(e) => this.hanldeStatusChange(e, order.id, index)} >
                  {
                    orderStatus.map(({ value, text }) => (<option value={value} key={value}>{text}</option>))
                  }
                </Input>)}
                {order.status && order.status === 'out_of_stock' && order.status}
              </td>
              <td>
                <Form inline>
                  <FormGroup>
                    <Input type="text" name={`trackingId:${order.id}`} id={`trackingId:${order.id}`} placeholder="Tracking Id"
                      style={{ width: '120px', fontSize: '0.88rem' }}
                      value={trackingIdOfOrderId === order.id ? trackingId : (order.tracking_id || '')}
                      onChange={(e) => this.onTrackingIdChange(e, order.id)}
                    />
                  </FormGroup>
                  &nbsp;&nbsp;
                  <Button type="button" id="saveTrackingId" size='sm' color="link"
                    style={{ padding: 0 }}
                    onClick={(e) => this.updateTrackingId(e, order.id, index)}>
                    <FontAwesomeIcon htmlFor="saveTrackingId" icon={faSave} />
                  </Button>
                  &nbsp;
                  {order.tracking_id && <span onClick={this.openOrderTrackModal.bind(this, order.tracking_id)} title="Track Order">
                    <Ionicon icon="md-pin" fontSize="16px" color="#fdbb1f" className="cursor-pointer" ></Ionicon>
                  </span>}
                </Form>
              </td>
            </tr>
          }
        </Fragment>
      );
    });
  }

  getSearchBar() {
    const { search: { sapIdOrGroupId, status, fromDate, toDate } } = this.state;
    const fromToDateText = (orderStatus.filter(s => status === s.value)[0] || [{ text: '' }]).text;

    return (
      <Row>
        <Col xl="8">
          <Form className="width-100" style={{}}>
            <Row>
              <Col xl="3" xs="6" md="6">
                <FormGroup>
                  <Label for="sapIdOrGroupId">SAP Id</Label>
                  <Input type="text" name="sapIdOrGroupId" id="sapIdOrGroupId" placeholder="SAP Id or Group Id"
                    style={{ fontSize: '0.88rem' }}
                    value={sapIdOrGroupId}
                    onChange={this.onSearchFormChange}
                  />
                </FormGroup>
              </Col>
              <Col xl="3" xs="6" md="6">
                <FormGroup>
                  <Label for="status"> Status </Label> &nbsp;
                  <Input type="select" name="status" id="status"
                    value={status}
                    style={{ fontSize: '0.88rem' }}
                    onChange={this.onSearchFormChange} >
                    {
                      [{ value: 'all', text: 'All' }, ...statusList].map(({ value, text }) => (<option value={value} key={value}>{text}</option>))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col xl="3" xs="6" md="6">
                <Label for="status"> {fromToDateText} From Date </Label> &nbsp;
                <InputGroup>
                  <DatePicker
                    name="fromDate"
                    className='form-control'
                    selected={fromDate}
                    onChange={(e) => this.onSearchFormChange({ target: { name: 'fromDate', value: e } })}
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    shouldCloseOnSelect
                    dropdownMode="select"
                  />
                  <InputGroupAddon addonType="append" >
                    <div className="input-group-text">
                      <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} />
                    </div>
                  </InputGroupAddon>
                </InputGroup>
                &nbsp;&nbsp;&nbsp;
              </Col>
              <Col xl="3" xs="6" md="6">
                <Label for="status"> {fromToDateText} To Date </Label> &nbsp;
                <InputGroup>
                  <DatePicker
                    name="toDate"
                    className='form-control'
                    selected={toDate}
                    onChange={(e) => this.onSearchFormChange({ target: { name: 'toDate', value: e } })}
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    shouldCloseOnSelect
                    dropdownMode="select"
                  />
                  <InputGroupAddon addonType="append" >
                    <div className="input-group-text">
                      <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} />
                    </div>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xl="1" style={{ padding: '30px' }}>
          <Button type="button" color="primary" onClick={() => this.getMerchandiseOrders(true)} ><FontAwesomeIcon htmlFor="download" icon={faDownload} /></Button>
        </Col>
      </Row>
    );
  }

  openOrderTrackModal = (awb) => {
    this.setState({ isOrderTrackModalOpen: true, selectedAwbForTrack: awb });
  }

  closeOrderTrackModal = () => {
    this.setState({ isOrderTrackModalOpen: false, selectedAwbForTrack: null });
  }

  selectOrder({ target: { checked = false } }, order) {
    const { selectedOrders } = this.state;
    if (checked === true) {
      selectedOrders.push(order);
    } else {
      const index = selectedOrders.indexOf(order);
      if (index !== -1) {
        selectedOrders.splice(index, 1);
      }
    }

    this.setState({
      selectedOrders
    });
  }

  showOrderDetails(e, order, index) {
    e.preventDefault();

    this.setState({
      selectedOrder: { ...order, index },
      showOrderDetailsModal: true
    });
  }

  hideOrderDetails() {
    this.setState({
      selectedOrder: {},
      showOrderDetailsModal: false
    });
  }

  async hanldeStatusChange(e, orderId, index) {
    const { orders } = this.state;
    const { target: { name, value } } = e;
    const controlName = name;
    const controlValue = value;
    const requestBody = {
      statusName: controlName,
      statusValue: controlValue,
      index,
      orderId
    };
    const oldStatus = orders[index] ? orders[index].status : null;

    this.setState({
      showConfirDialog: true,
      confirmDialogTitle: 'Update order status',
      confirmDialogMsg: `Do you realy want to change status of OrderId: ${orderId} ${oldStatus ? `from "${oldStatus}" ` : ''} to "${controlValue}"?`,
      confirmDialogData: requestBody,
    });

    return true;
  }

  doSearch(e) {
    e.preventDefault();
    this.setState({ pagination: initialPagination }, () => this.getMerchandiseOrders());
  }

  handlePageChange(page = 0) {
    this.setState(prevState => {
      return {
        pagination: {
          ...prevState.pagination,
          page
        }
      };
    }, this.getMerchandiseOrders);
  }

  updateOrderInList(updatedOrder, index) {
    // eslint-disable-next-line no-param-reassign
    delete updatedOrder.index;
    const { orders } = this.state;
    orders[index] = updatedOrder;
    this.setState({
      orders
    });
  }

  async updateTrackingId(e, orderId, index) {
    e.preventDefault();
    const { trackingId, orders } = this.state;
    const alert = {
      title: 'Opps !!!',
      text: 'Error while updating trackingId',
      icon: 'error',
      type: 'error'
    };

    const { success, data, message } = await MerchandiseService.updateMerchandiseOrder(orderId, { trackingId });

    if (success) {
      orders[index] = {
        ...orders[index],
        ...data,
      };

      this.setState({
        orders,
        trackingId: ''
      });
      alert.title = 'Congratulations !';
      alert.text = message;
      alert.icon = 'success';
      alert.type = 'success';
      sweetAlert(alert);

    }

    sweetAlert(alert);
  }

  closeConfirmDialog(data, orderValue) {
    if (data) {
      const { statusValue, index } = orderValue;
      const { orders } = this.state;
      const updatedOrder = {
        ...orders[index],
        ...data,
        status: statusValue
      };
      orders[index] = updatedOrder;
      this.setState({
        orders,
        showConfirDialog: false
      });

      return true;
    }


    this.setState({
      showConfirDialog: false
    });
    return false;
  }

  printPerformaInvoice() {
    const { selectedOrders } = this.state;
    let sapKey = {};
    for (let i = 0; i < selectedOrders.length; i++) {
      let order = selectedOrders[i];
      sapKey[order.sap_id] = null
    }
    const sapLength = Object.keys(sapKey).length;
    if (sapLength > 1) {
      ModalService.openAlert('Print', 'Please select only one sap id', 'error');
      return;
    }
    this.setState({
      showPerformaInvoiceupdateModal: true
    });
  }

  closePerformaInvoiceUpdateModal() {
    this.setState({
      showPerformaInvoiceupdateModal: false
    });
  }

  async sendEmail() {
    this.setState({ isLoading: true });

    const { selectedOrders } = this.state;
    const smEmail = _.chain(selectedOrders).map(order => order.sm_email).uniq().value();
    const amEmail = _.chain(selectedOrders).map(order => order.am_email).uniq().value();
    const dealerEmail = _.chain(selectedOrders).map(order => order.email_1).uniq().value();
    const emails = { smEmail, amEmail, dealerEmail };
    const orders = _.chain(selectedOrders).map(order => ({
      itemName: order.item_name,
      orderId: order.order_id,
      quantity: order.item_quantity,
      remarks: order.remarks,
      trackingId: order.tracking_id,
      orderDate: formatDate(order.date),
      ownerName: order.owner_name
    })).value();

    await MerchandiseService.notifiyMerchandiseDispatch({ emails, orders });
    this.setState({ isLoading: false });

  }

  render() {
    const { pagination, selectedOrder, showOrderDetailsModal, showConfirDialog, confirmDialogTitle,
      confirmDialogMsg, confirmDialogData, isLoading, selectedOrders, showPerformaInvoiceupdateModal,
      isOrderTrackModalOpen, selectedAwbForTrack
    } = this.state;
    const { pageCount, page, limit, counts } = pagination;
    const { history } = this.props;

    return (
      <Fragment>
        {/* Modal */}
        <MerchandiseOrderDetails orderDetails={selectedOrder} isOpen={showOrderDetailsModal} onExit={this.hideOrderDetails} onUpdateOrder={this.updateOrderInList} />
        <OrderStatusUpdate
          title={confirmDialogTitle}
          msg={confirmDialogMsg}
          data={confirmDialogData}
          isOpen={showConfirDialog}
          onExit={this.closeConfirmDialog}
        />
        <PerformaInvoiceUpdate
          isOpen={showPerformaInvoiceupdateModal}
          data={selectedOrders}
          onExit={this.closePerformaInvoiceUpdateModal}
          history={history}
        />
        {isOrderTrackModalOpen && <OrderTrackModal
          show={isOrderTrackModalOpen}
          awb={selectedAwbForTrack}
          closeModal={this.closeOrderTrackModal}
        />}
        {/* Modal end */}
        {isLoading && <CustomSpinner />}

        <Row style={{ marginTop: '25px' }}>
          <Col>
            <Card>
              <CardBody className="overflow-auto" style={{ minHeight: '500px' }}>
                {this.getSearchBar()}
                {
                  counts &&
                  <span className="text-muted"> {`About ${counts} results`} </span>
                }

                {
                  selectedOrders.length > 0 && (<>
                    <Button type="button" color='primary' id="btn-performa-invoice" style={{ marginLeft: '30px' }} onClick={this.printPerformaInvoice}> Print </Button>
                    <Button type="button" color='primary' id="btn-performa-invoice" style={{ marginLeft: '30px' }} onClick={this.sendEmail}> Email </Button>
                  </>)
                }
                <br />
                <br />
                <Table className="table-responsive">
                  <thead>
                    <tr>
                      <th />
                      <th>SAP Id</th>
                      <th>Business name </th>
                      <th>Item name</th>
                      <th>Qty.</th>
                      <th>Order date</th>
                      <th>Ready to dispatch date</th>
                      <th>Order dispatch date</th>
                      <th>Order delivered date</th>
                      <th>Status</th>
                      <th>Tracking Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.getOrderRows()}
                  </tbody>
                </Table>
                {pageCount > 1 && <Pagination
                  margin={2}
                  page={page}
                  count={pageCount}
                  pageSize={limit}
                  totalRecords={counts}
                  onPageChange={this.handlePageChange}
                />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

MerchandiseReportComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default MerchandiseReportComponent;