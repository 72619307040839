import React, { Fragment } from 'react';
import Header from '../../theme-components/Header/Header';
import AppSidebar from '../../theme-components/AppSidebar/AppSidebar';
import Footer from '../../components/Footer/Footer';

const ComponetWithHeader = ({ component: Component, ...rest }) => {
  return (
    <Fragment>
      <Header></Header>
      <div className="app-main" style={{ minHeight: '95.5vh' }}>
        <AppSidebar {...rest} />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Component {...rest} />
          </div>
        </div>

      </div>
      <Footer />

      {/* {rest.footer && <Footer />} */}
    </Fragment >
  )
}

export default ComponetWithHeader;