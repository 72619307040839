/**
 * @name WarrantyReplacementService
 * @author Surjeet Bhadauriya and Rasul Diwan <surjeet.bhadauriya@techifysolutions.com, rasul.diwan@techifysolutions.com>
 * @description All APIs for warranty replacement
 * @version 0.0.0
 */
import { request } from "./Request";
import { routes } from "../constants/constant.route";

const {
  GENERATE_OTP,
  VERIFY_OTP,
  GET_VEHICLE_TYPE,
  GET_CUSTOMER,
  GET_VEHICLE_WARRANTY_LIST_V3,
  CLAIM_REGISTRATION_v3,
  GETSKUID
} = routes.WARRANTY_REPLACEMENT;
const API_URL = process.env.REACT_APP_API_URL;

export default class WarrantyReplacementService {
  /**
   * @name sendOTP
   * @description Sends the OTP to specific Mobile No.
   * @param {data: Object}
   * @method POST
   * @returns promise
   */
  static sendOTP(data) {
    return request("POST", `${API_URL}${GENERATE_OTP}`, null, data, null);
  }

  /**
   * @name verifyOTP
   * @description Verfies the passed OTP
   * @param {data: Object}
   * @method POST
   * @returns promise
   */
  static verifyOTP(data) {
    return request("POST", `${API_URL}${VERIFY_OTP}`, null, data, null);
  }

  /**
   * @name getVehicleType
   * @description Get Vehicle Type : Car, Bike
   * @param {}
   * @method GET
   * @returns promise
   */
  static getVehicleType() {
    return request("GET", `${API_URL}${GET_VEHICLE_TYPE}`, null, null, null);
  }

  /**
   * @name getVehicleWarrantyList
   * @description Get getVehicleWarrantyList
   * @param {}
   * @method GET
   * @returns promise
   */
  static getVehicleWarrantyList(vehicle_id) {
    return request(
      "GET",
      `${API_URL}${GET_VEHICLE_WARRANTY_LIST_V3}?vehicle_id=${vehicle_id}`,
      null,
      null,
      null
    );
  }

  /**
   * @name getCustomer
   * @description Saves the data of customer warranty registration
   * @param {data: Object}
   * @method POST
   * @returns promise
   */
  static getCustomer(data) {
    return request("POST", `${API_URL}${GET_CUSTOMER}`, null, data, null);
  }

  /**
   * @name postWarrantyRegistration
   * @description Saves the data of customer warranty registration
   * @param {data: Object}
   * @method POST
   * @returns promise
   */
  static claimWarrantyReplacement(data) {
    return request(
      "POST",
      `${API_URL}${CLAIM_REGISTRATION_v3}`,
      null,
      data,
      null
    );
  }

  /**
   * @name getSKUId
   * @description Get SKU ID
   * @param {params: Object}
   * @method GET
   * @returns promise
   */
  static getSKUId(params) {
    return request("GET", `${API_URL}${GETSKUID}`, params, null, null);
  }
}
