export const Permissions = {
  PRINT_PERFOMA_INVOICE: "PRINT_PERFOMA_INVOICE",
  MERCHANDISE_ORDER: "MERCHANDISE_ORDER",
  ABOUT_PROGRAMME: "ABOUT_PROGRAMME",
  SPECIAL_ENGAGEMENT: "SPECIAL_ENGAGEMENT",
  MANAGE_GROUP: "MANAGE_GROUP",
  WARRANTY_REGISTRATION: "WARRANTY_REGISTRATION",
  CHECK_ELIGIBILITY_FOR_WARRANTY: "CHECK_ELIGIBILITY_FOR_WARRANTY",
  WARRANTY_CHECK: "WARRANTY_CHECK",
  POINTS_DASHBOARD: "POINTS_DASHBOARD",
  ADD_CUSTOMER: "ADD_CUSTOMER",
  PROFILE: "PROFILE",
  POINT_EARN_REPORT: "POINT_EARN_REPORT",
  WARRANTY_REPORT: "WARRANTY_REPORT",
  OFFTAKE_REPORT: "OFFTAKE_REPORT",
  WARRANTY_REPORT_EMPLOYEE: "WARRANTY_REPORT_EMPLOYEE",
  WHATS_NEW: "WHATS_NEW",
  MARKETING_COUPON: "MARKETING_COUPON",
  MARKETING_COUPON_REPORT: "MARKETING_COUPON_REPORT",
  REDEEM_MARKETING_COUPON: "REDEEM_MARKETING_COUPON",
  GENERATE_MARKETING_COUPON: "GENERATE_MARKETING_COUPON",
  DOWNLOAD_POSTER: "DOWNLOAD_POSTER",
  CONTACT_US: "CONTACT_US",
  PROPOSAL_DASHBOARD: "PROPOSAL_DASHBOARD",
  PROPOSAL_DETAIL: "PROPOSAL_DETAIL",
  PROJECT_STAGE: "PROJECT_STAGE",
  PROPOSAL_ASSIGN: "PROPOSAL_ASSIGN",
  PROJECT_STAGE_STATUS: "PROJECT_STAGE_STATUS",
  PROPOSAL_ARCHITECTURE: "PROPOSAL_ARCHITECTURE",
  MERCHANDISE_CATALOGUE: "MERCHANDISE_CATALOGUE",
  SET_NOTIFICATION: "SET_NOTIFICATION",
  SALES_DATA_UPLOAD: "SALES_DATA_UPLOAD",
  MIGRATE_MONTHLY_POINTS: "MIGRATE_MONTHLY_POINTS",
  INVITE_BRANCH_SUB_DEALER: "INVITE_BRANCH/SUB_DEALER", // slight key difference
  CUSTOMER_LIST: "CUSTOMER_LIST",
  BLOCK_LIST: "BLOCK_LIST",
  CAMPAIGN: "CAMPAIGN",
  CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
  SCHEDULE_CAMPAIGN: "SCHEDULE_CAMPAIGN",
  CAMPAIGN_HISTORY: "CAMPAIGN_HISTORY",
  OFFTAKE_DASHBOARD: "OFFTAKE_DASHBOARD",
  MIGRATE_QUARTERLY_POINTS: "MIGRATE_QUARTERLY_POINTS",
  MIGRATE_YEARLY_POINTS: "MIGRATE_YEARLY_POINTS",
  CUSTOMER_INTERACTION: "CUSTOMER_INTERACTION",
  TRADE_SCHEME: "TRADE_SCHEME",
  TRADE_SCHEME_REPORT: "TRADE_SCHEME_REPORT",
  REDEMPTION_CATALOGUE: "REDEMPTION_CATALOGUE",
  GIFT_ITEM: "GIFT_ITEM",
  EXCEPTIONAL_POINT_UPLOAD: "EXCEPTIONAL_POINT_UPLOAD",
  DEALER_NEW_DASHBOARD: "DEALER_NEW_DASHBOARD",
  NOTIFICATION_PREFERENCE: "NOTIFICATION_PREFERENCE",
  BUY_SMS: "BUY_SMS",
  ORDER_HISTORY: "ORDER_HISTORY",
  EMAIL_PREFERENCE: "EMAIL_PREFERENCE",
  CREATE_BANNER: "CREATE_BANNER",
  BANNER_LIST: "BANNER_LIST",
  USER_STATUS_CHANGE: "USER_STATUS_CHANGE",
  MAP_LOCATION: "MAP_LOCATION",
  SMS_REPORT: "SMS_REPORT",
  CREATE_POSTER: "CREATE_POSTER",
  SALES_TREND_REPORT: "SALES_TREND_REPORT",
  RETAIL_BRANDING_LIST: "RETAIL_BRANDING_LIST",
  CREATE_RETAIL_BRANDING: "CREATE_RETAIL_BRANDING",
  UPDATE_RETAIL_BRANDING: "UPDATE_RETAIL_BRANDING",
  ON_BOARDING_PAGE: "ON_BOARDING_PAGE",
  OFFTAKE_AND_POINTS: "OFFTAKE_AND_POINTS",
  DOWNLOAD_SCORECARD: "DOWNLOAD_SCORECARD",
  SPECIAL_POINTS_UPLOAD: "SPECIAL_POINTS_UPLOAD",
  ACCOUNT: "ACCOUNT",
  REDEEM_MARKETING_DISCOUNT_COUPON: "REDEEM_MARKETING_DISCOUNT_COUPON",
  MARKETING_COUPON_REDEEM_REPORT: "MARKETING_COUPON_REDEEM_REPORT",
  CUSTOMER_MANAGEMENT: "CUSTOMER_MANAGEMENT",
  ADD_CUSTOMER_VISIT: "ADD_CUSTOMER_VISIT",
  CATALOGUE: "CATALOGUE",
  SETTINGS: "SETTINGS",
  WARRANTY_POINTS_REDEMPTION: "WARRANTY_POINTS_REDEMPTION",
  VIEW_SENDER_ID: "VIEW_SENDER_ID",
  VISIT_HISTORY: "VISIT_HISTORY",
  DASHBOARD_WARRANTY_CATALOGUE: "DASHBOARD_WARRANTY_CATALOGUE",
  PRIMARY_POINTS_REDEMPTION: "PRIMARY_POINTS_REDEMPTION",
  DATA_UPDATE_UTILITY: "DATA_UPDATE_UTILITY",
  GENERATIVE_AI: "GENERATIVE_AI",
  JK_ANALYSIS: "JK_ANALYSIS",
};
