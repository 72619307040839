import React, { Component, Fragment } from "react";
import { Row, Col, Table, Card, CardBody } from "reactstrap";
import { Roles } from "../../constants/constant.role";

class SingleDealerPrimaryReportTable extends Component {
  render() {
    const { dealer, currentUser, dealerRole } = this.props;
    const prepareHeader = dealer.salesData.map((sd) => (
      <th key={dealer.uuid + sd.category}>{sd.category}</th>
    ));
    const prepareOfftake = dealer.salesData.map((sd, index) => (
      <td key={dealer.uuid + "sales" + index}>{sd.total_sales}</td>
    ));
    const preparePoints = dealer.salesData.map((sd, index) => (
      <td key={dealer.uuid + "point" + index}>{sd.points}</td>
    ));

    return (
      <Fragment>
        <Row className="margin-top-10">
          <Col>
            <Card>
              <CardBody className="overflow-auto">
                <Row className="margin-bottom-20">
                  <Col className="text-align-left">
                    <div className="single-dealer-table-card-header">
                      <span className="title-case">
                        {dealer.name ? `${dealer.name} / ` : ""}
                      </span>
                      {dealer.dealer}
                    </div>
                  </Col>
                  <Col className="text-align-right download-report-text cursor-pointer">
                    {/* <div onClick={this.props.openViewReportPage.bind(this, dealer.uuid, dealer.name)}>View detailed report </div> */}
                    <div
                      onClick={
                        (currentUser &&
                          currentUser.role &&
                          currentUser.role === "AM") ||
                          dealerRole === Roles.AREA_MANAGER_ROLE ||
                          dealerRole === Roles.SALES_OFFICER_ROLE
                          ? this.props.openViewReportPage.bind(
                            this,
                            dealer.uuid,
                            dealer.name,
                            dealer.type
                          )
                          : this.props.getSelectedDealerDetail.bind(
                            this,
                            dealer.uuid,
                            dealer.name,
                            dealer.type
                          )
                      }
                    >
                      View detailed report{" "}
                    </div>
                  </Col>
                </Row>

                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      {prepareHeader}
                      {/* <th>PCR</th>
                    <th>TBB</th>
                    <th>TBR</th>
                    <th>LCV</th>
                    <th>SCV</th>
                    <th>2/3 Wheeler</th>
                    <th>Farm</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="table-header">
                        Offtake
                      </td>
                      {prepareOfftake}
                      {/* <td>0</td>
                    <td>0</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td> */}
                    </tr>
                    <tr>
                      <td className="table-header">
                        Points
                      </td>
                      {preparePoints}
                      {/* <td>0</td>
                    <td>0</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td> */}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default SingleDealerPrimaryReportTable;
