import React, { Component } from 'react';
import {
  Row,
  Col,
} from "reactstrap";
import Switch from "react-switch";
import swal from 'sweetalert';
import ModalService from '../../services/ModalService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import { messages } from '../../constants/constant.message';
import UserService from '../../services/UserService';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';
class EmailPreference extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked_email: false,
      user_detail: {}
    }
  }

  componentDidMount() {
    GoogleAnalticsService.pageView('Email Preference View');
    this.getUserInfo();
  }

  handleChange = (e) => {
    this.setState({
      checked_email: e
    });
    GoogleAnalticsService.event('Email Preference', 'Overdue Service Customer List Click');
    this.saveDealerEmailPref(e);
  }


  getUserInfo() {
    this.setState({
      isLoading: true
    });
    UserService.getUserInfo()
      .then(response => {
        if (response.data && response.data.data && response.data.data) {
          this.setState((prevState) => {
            return {
              ...prevState,
              user_detail: response.data.data,
              checked_email: response.data.data.email_preference_for_customer_service ? true : false,
              isLoading: false
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              ...prevState,
              isLoading: false
            };
          });
        }
        //let userData = response.data.data;
      })
      .catch(e => {
        console.error("e", e);
        this.setState({
          isLoading: false
        });
      })
  }

  saveDealerEmailPref = (is_active) => {
    this.setState({
      isLoading: true
    });
    UserService.updateUserInfo({
      email_preference_for_customer_service: is_active
    })
      .then((response) => {
        this.setState({
          isLoading: false
        })
        swal({
          title: "",
          text: 'Your Email Preference saved successfully.',
          icon: "success",
          type: "success",
        }).then((value) => {
          this.setState({
            isLoading: false
          });
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          checked_email: !is_active
        });
        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  render() {
    return (
      <div className="d-flex-column" id="NotificationPreference">
        {this.state.isLoading && <CustomSpinner />}
        <span className="header-title">Email Preference</span>
        <Row className="mt-4 ml-0">
          <Col xs="6" className="servicespreference-wrapper cursor-pointer">
            <Row className="pr-3" style={{ height: '100%' }}>
              <Col xs="12" className="d-flex justify-content-between mt-auto mb-auto">
                <div className="d-flex-column">
                  <span className="servicespreference mb-2">Service / Tyre Purchase Due Reminder</span>
                  <span className="servicespreference-note" style={{ width: 'auto', height: 'auto' }}>
                    When this switch is on then you will daily receive customer visit tyre purchase or services due list over email.
                  </span>
                </div>
                <div className="mt-auto mb-auto">
                  <Switch
                    checked={this.state.checked_email}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={this.handleChange}
                    onColor="#fdbb1f"
                    handleDiameter={25}
                    height={35}
                    width={65}
                    className="react-switch"
                    id="small-radius-switch"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EmailPreference;