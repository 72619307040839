import React, { Component } from 'react';
// import cookie from 'react-cookies';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  FormGroup, Input, Label
} from 'reactstrap';
// components
import OnBoardingComponent1 from '../../components/OnBoardingComponent1/OnBoardingComponent1';
import OnBoardingComponent2 from '../../components/OnBoardingComponent2/OnBoardingComponent2';
import Registration from '../../components/Registration/Registration';

// modal
// import ConfirmAlert from '../../modal/ConfirmAlert/ConfirmAlert';
import LogoutModal from '../../modal/LogoutModal/LogoutModal';

// services
import Storage from '../../services/Storage';
import AuthService from '../../services/AuthService';

// actions
import { setUserDetail } from '../../actions/UserAction';

// constant
import { messages } from '../../constants/constant.message';

// css
import './OnBoardingPage.css';
import { Roles } from '../../constants/constant.role';


// const title = 'Logout';
let modalButtons = null;
let logoutChild = null;

class OnBoardingPage extends Component {

  pages = ['ob1', 'ob2', 'reg'];

  state = {
    currentPage: 'ob1',
    isLogoutModalOpen: false,
    message: messages.LOGOUT,
    redirectToLoginPage: false,
    redirectToDashboard: false
  }

  openLogoutModal = () => {
    logoutChild = <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          name="checkbox"
          id="checkbox"
        // checked={logoutFromOther}
        // onChange={this.setLogoutFromOtherCheckboxValue}
        />{' '}
        Logout from all device
      </Label>
    </FormGroup>;

    modalButtons = [
      {
        color: '#fdbb1f',
        title: 'Cancel',
        style: { backgroundColor: '#fdbb1f' },
        onClick: this.closeLogoutModal,
      },
      {
        color: '#e0e2e4',
        title: 'Logout',
        style: { backgroundColor: '#e0e2e4' },
        onClick: this.logout,
      },
      // {
      //   color: '#e0e2e4',
      //   title: 'Logout All',
      //   style: { backgroundColor: '#e0e2e4' },
      //   onClick: this.logoutFromAllDevice,
      // },
    ];
    // child = <Input placeholder="Reason" onChange={this.changeReasonToDelete} />;
    this.setState({ isLogoutModalOpen: true });
  }

  closeLogoutModal = () => {
    this.setState({ isLogoutModalOpen: false });
  }

  logoutFromAllDevice = () => {
    AuthService.logoutFromAllDevice()
      .then(data => {
        this.logout();
      })
      .catch(e => {
        this.logout();
      })
  }

  logout = () => {
    AuthService.logout();
    this.props.setUserDetail(null);
    this.closeLogoutModal();
    this.setState({ redirectToLoginPage: true });
  }

  componentDidMount() {
    const userDetail = Storage.getUserDetail();
    if (userDetail.is_login_first_time === true) {
      this.setState({ redirectToDashboard: true });
    }
    if (userDetail.type === Roles.SUB_DEALER_ROLE || userDetail.type === Roles.BRANCH_ROLE || userDetail.customer_class === "na" || userDetail.customer_class === "hy") {
      this.setState({ currentPage: 'reg' });
    } else {
      setTimeout(() => {
        this.setState({ currentPage: 'ob2' });
      }, 5000);
    }
  }

  handleGoToNextPage = (page) => {
    window.scroll(0, 0);
    this.setState({ currentPage: page });
  }

  render() {
    const { currentPage } = this.state;

    return (
      <div id="ob" style={{ padding: '10px' }}>
        {this.state.redirectToLoginPage && <Redirect to="/login" />}
        <LogoutModal
          show={this.state.isLogoutModalOpen}
          closeModal={this.closeLogoutModal}
          history={this.props.history}
        >
        </LogoutModal>
        {/* <ConfirmAlert
          show={this.state.isLogoutModalOpen}
          closeModal={this.closeLogoutModal}
          title={title}
          warning={true}
          msg={this.state.message}
          buttons={modalButtons}
          child={logoutChild}
        /> */}
        {currentPage === 'ob1' && <OnBoardingComponent1 />}
        {currentPage === 'ob2' && <OnBoardingComponent2 handleGoToNextPage={this.handleGoToNextPage} openLogoutModal={this.openLogoutModal} />}
        {currentPage === 'reg' && <Registration handleGoToNextPage={this.handleGoToNextPage} openLogoutModal={this.openLogoutModal} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
  setUserDetail: user => dispatch(setUserDetail(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingPage);

// export default OnBoardingPage;