import moment from "moment";
import React, { Component } from "react";
import Sidebar from "react-sidebar";
import { Table, UncontrolledTooltip, Input } from "reactstrap";
import Ionicon from "react-ionicons";
import ReactTooltip from "react-tooltip";
import Pagination from "../../../components/Pagination/Pagination";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import UserService from "../../../services/UserService";
import AddWhatsappTemplates from "./AddWhatsappTemplates";
import "./WhatsappTemplates.scss";
import _ from "lodash";

class WhatsappTemplates extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      openAddWhatsappTemplatesSideBar: false,
      whatsappTemplateListData: [],
      total_templates: 0,
      page: 1,
      pageSize: 10,
      isLoading: false,
      searchValue: "",
      back: "",
    };
    // Debounce the handleChange function
    this.debouncedSearch = _.debounce(this.performSearch, 300);
  }
  // Update searchTerm when input changes
  onSearchChange = (event) => {
    this.setState({ searchValue: event.target.value });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchValue !== this.state.searchValue) {
      this.debouncedSearch(this.state.searchValue);
    }
  }
  // Perform search
  performSearch = (searchValue) => {
    this.getWhatsappTemplate(this.state.page, this.state.pageSize, searchValue);
  };

  handleChange = (event) => {
    event.preventDefault();
    if (event && event.target) {
      this.setState({ searchValue: event.target.value });
    }
  };
  openAddWhatsappTemplatesSideBar = (value, back = "") => {
    this.setState({
      openAddWhatsappTemplatesSideBar: value,
      back: back,
    });
    if (!value && back === "whatsapp_campaign") {
      this.props.openWhatsappSideBarCreateCampaignFromTemplate();
    }
  };
  componentDidMount() {
    this.setState({
      searchValue: "",
    });
    this.getWhatsappTemplate(this.state.page, this.state.pageSize, "");
  }
  resetWhatsappTemplate() {
    this.setState({
      searchValue: "",
      page: 1,
      pageSize: 10,
    });
    this.getWhatsappTemplate(1, 10, "");
  }
  handleInputChange = (e) => {
    const pageSize = Number(e.target.value);
    this.setState({
      page: 1,
      pageSize: pageSize,
    });
    this.getWhatsappTemplate(1, pageSize);
  };
  handlePageChange = (page) => {
    this.setState({
      page: page,
    });
    this.getWhatsappTemplate(page, this.state.pageSize);
  };
  getWhatsappTemplate = (
    page = 1,
    pageSize = 10,
    // startDate = null,
    // endDate = null,
    search = "",
    exportReport = false
  ) => {
    console.log("datttta", { page, pageSize });
    let reqData = {
      page: page || 1,
      pageSize: pageSize || 10,
      // startDate: startDate ? moment(startDate).utc() : null,
      // endDate: endDate ? moment(endDate).utc() : null,
      export: exportReport,
      search: search,
    };
    console.log("reqData", reqData);
    this.setState({
      isLoading: true,
    });
    UserService.getWhatsappAllTemplates(reqData)
      .then((data) => {
        // if (data.data && data.data.data && data.data.data.file) {
        //   setIsLoading(false);
        //   downlodFile(data.data.data.file);
        //   return;
        // }
        let { data: list } = data.data;
        if (list.data && Array.isArray(list.data) && list.data.length > 0) {
          const whatsappTemplateListData = list.data || [];
          const totalCountsTemp = list.total_templates;
          const totalPagesTemp = Math.ceil(totalCountsTemp / pageSize);
          this.setState({
            whatsappTemplateListData: whatsappTemplateListData,
            total_templates: totalCountsTemp,
            totalPages: totalPagesTemp,
            isLoading: false,
          });
        } else {
          this.setState({
            whatsappTemplateListData: [],
            total_templates: 0,
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoading: true,
        });
      });
  };

  convertToHtml = (text) => {
    // Replace '\n' with '<br>'
    let htmlText = text ? text.replace(/\n/g, "<br>") : "";
    // Replace '\t' with '&nbsp;&nbsp;&nbsp;&nbsp;' (four non-breaking spaces)
    htmlText = htmlText
      ? htmlText.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
      : htmlText;
    let headerElement = null;

    htmlText = htmlText.replace(/(?<![{[?}\]])\*(?!\s)(.+?)\*/g, "<b>$1</b>");
    htmlText = htmlText.replace(/(?<![{[?}\]])_(?!\s)(.+?)_/g, "<i>$1</i>");
    htmlText = htmlText.replace(/(?<![{[?}\]])~(?!\s)(.+?)~/g, "<s>$1</s>");
    return htmlText;
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    const {
      total_templates = 0,
      whatsappTemplateListData = [],
      page,
      pageSize,
      isLoading = false,
      totalPages = 0,
      searchValue = "",
    } = this.state;
    return (
      <div
        style={{
          marginBottom: 15,
        }}
      >
        <div className="email_title_container" style={{ paddingTop: 0 }}>
          <span className="email_campaign_title">Whatsapp Templates</span>
          <button
            onClick={() => this.openAddWhatsappTemplatesSideBar(true)}
            className="create_campaign_button"
          >
            Create Whatsapp Template
          </button>
        </div>
        {/* <AddWhatsappTemplates></AddWhatsappTemplates> */}
        {/* Whatsapp create campaign sidebar */}
        {this.state.openAddWhatsappTemplatesSideBar && (
          <Sidebar
            sidebar={
              <>
                <AddWhatsappTemplates
                  toogleSideBar={this.openAddWhatsappTemplatesSideBar}
                  getWhatsappTemplate={this.getWhatsappTemplate}
                  back={this.state.back}
                />
              </>
            }
            open
            sidebarClassName="campaign-drawer"
            pullRight
            styles={{
              sidebar: {
                background: "white",
                height: "90%",
                top: "60px",
                width: "55%",
                borderRadius: "2px",
              },
            }}
          />
        )}

        {isLoading && <CustomSpinner />}
        <div className="email_input_container" style={{ paddingRight: "18px" }}>
          <div style={{ alignItems: "center" }} className="d-flex-row">
            <div
              style={{ width: "365px", height: "35px" }}
              className="d-flex-row search-input"
            >
              <Input
                placeholder="Search by template Name,Type,Media Type,Status"
                className="login-input"
                name="SearchValueWhatsappTemplates"
                onChange={this.onSearchChange}
                value={searchValue}
                autoComplete="off" // Add autoComplete attribute
              ></Input>
              <img
                src="images/search.svg"
                alt=""
                className="icon-width"
                style={{ marginLeft: "-25px", width: "16px" }}
              />
            </div>
            <div
              data-tip="Refresh Whatsapp Templates"
              data-for={"refresh-whatsapp-templates"}
              style={{ cursor: "pointer" }}
              className="ml-4"
            >
              <Ionicon
                icon="ios-refresh"
                fontSize="34px"
                color="#000"
                onClick={() => this.resetWhatsappTemplate()}
              />
              <ReactTooltip id={"refresh-whatsapp-templates"} effect="solid">
                {/* {row.vendor_comment} */}
              </ReactTooltip>
            </div>
          </div>
        </div>
        <div style={{ padding: "0 18px" }}>
          <Table className="mb-0 table-responsive tbl-about tbl-about text-align-center">
            <thead>
              <tr
                style={{ backgroundColor: "#f6f6f6", height: "40px" }}
                className="coupon-list-table-header"
              >
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Date</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Template Name</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Message Type</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Media Type</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Category</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Body</span>{" "}
                </td>
                {/* <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Sample Body</span>{" "}
                </td> */}
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Status</span>
                </td>
                <td rowSpan="2" className="text-align-center border-1-5">
                  <span className="table_header_text">Reason</span>
                </td>
              </tr>
            </thead>
            <tbody>
              {whatsappTemplateListData &&
                whatsappTemplateListData.map((item, index) => {
                  let msgVal = item.template.body || "";
                  //  msgVal = msgVal ? this.convertToHtml(msgVal) : "";
                  const media_type = item.template.header || "-";
                  const dotMsgVal =
                    msgVal && msgVal.length > 50
                      ? `${msgVal.slice(0, 50)}...`
                      : msgVal || "";
                  const mediaType = item.template.header
                    ? this.capitalizeFirstLetter(item.template.header)
                    : "-";
                  const msgType = this.capitalizeFirstLetter(item.msg_type);
                  const status = this.capitalizeFirstLetter(item.status);

                  return (
                    <tr key={index}>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {moment(item.created_at).format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {item.template_name}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {msgType}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {mediaType}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {item.category}
                      </td>
                      <td
                        className="text-align-center border-1-5 email_campaign_table_data"
                        style={{ maxWidth: "200px" }}
                      >
                        <div style={{ cursor: "pointer" }} id={`id-${index}`}>
                          {dotMsgVal}
                        </div>
                        <UncontrolledTooltip
                          placement="bottom"
                          target={`id-${index}`}
                          style={{ minWidth: "350px" }}
                        >
                          {msgVal}
                        </UncontrolledTooltip>
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {status}
                      </td>
                      <td className="text-align-center border-1-5 email_campaign_table_data">
                        {item.reason}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        {totalPages > 0 && (
          <div
            className="d-flex-row"
            style={{ marginTop: "10px", justifyContent: "flex-end" }}
          >
            <span className="Rows-per-page">Rows per page:</span>
            <select
              className="form-control rows-per-page-select"
              name="pageSize"
              value={pageSize}
              onChange={(e) => this.handleInputChange(e)}
              style={{ width: "65px" }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
            &nbsp;&nbsp;
            <div style={{ marginTop: "5px" }}>
              <Pagination
                margin={2}
                page={page}
                count={totalPages}
                pageSize={pageSize}
                totalRecords={total_templates}
                onPageChange={this.handlePageChange}
              ></Pagination>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WhatsappTemplates;
