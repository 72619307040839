import { request } from './Request';
import { routes } from '../constants/constant.route';

const API_URL = process.env.REACT_APP_API_URL;

export default class FleetCommunicationService {

  static generateAllFleetScorecard() {
    return request('GET', `${API_URL}${routes.FLEET_COMMUNICATION.GENERATE_ALL_FLEET_SCORECARD}`, null, null, null);
  }
}