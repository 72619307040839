import React, { Component } from 'react';
import {
  Row, Col,
  Card, CardBody
} from 'reactstrap';
import './OfftakeSummaryMobile.css';

class OfftakeSummaryMobile extends Component {
  render() {
    return <div id="offtake-summary">
      <br />
      <br />
      <div className="page-title">Offtake Summary</div>
      <br />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col className="offtake-card-title padding-bottom-10" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  OPENING BALANCE
                </Col>
              </Row>
              <Row className="padding-top-10">
                <Col>
                  <div className="point-title">Points</div>
                  <div className="points">50,000</div>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col className="offtake-card-title padding-bottom-10" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  QUARTER 1 (Apr - Jun)
                </Col>
              </Row>
              <Row className="padding-top-10">
                <Col>
                  <div className="point-title">Points</div>
                  <div className="points">50,000</div>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col className="offtake-card-title padding-bottom-10" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  SPECIAL SCHEME (ID #123456)
                </Col>
              </Row>
              <Row className="padding-top-10">
                <Col>
                  <div className="point-title">Points</div>
                  <div className="points">50,000</div>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col className="offtake-card-title padding-bottom-10" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  APPROVALS
                </Col>
              </Row>
              <Row className="padding-top-10">
                <Col>
                  <div className="point-title">Points</div>
                  <div className="points">50,000</div>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col className="offtake-card-title padding-bottom-10" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', backgroundColor: '#f4dfad' }}>
                  TOTAL
                </Col>
              </Row>
              <Row className="padding-top-10">
                <Col>
                  <div className="point-title">Points</div>
                  <div className="points">50,000</div>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>;
  }
}

export default OfftakeSummaryMobile;