import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Input,
  Row, Col,
  Label, FormGroup,
  InputGroup,
  InputGroupAddon,
  Card, CardBody,
} from 'reactstrap';
import lodash from 'lodash'; // library added for debounce functionality
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import Autosuggest from 'react-autosuggest';
import Validation from '../../services/Validation';
import DropDownMultiple from '../../CustomComponents/DropDownMultiple/DropDownMultiple';
import MerchandiseWalletService from '../../services/MerchandiseWalletService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import UserService from '../../services/UserService';
import SuccessModal from '../../modal/SuccessModal/SuccessModal';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import ModalService from '../../services/ModalService';
import Storage from '../../services/Storage';
import TextareaAutosize from 'react-textarea-autosize';
import { Roles } from '../../constants/constant.role';

class CipDealerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      selectedItems: [],
      cipDetail: [],
      value: '',
      stateData: [],
      userDetail: Storage.getUserDetail(),
      cityData: [],
      cipCategory: [],
      inviteeClassificationData: [],
      activity: [],
      isRedirectSummary: false,
      cip_objetives: ['Awareness about Brand', 'Educate on JK TBB & TBR Product range',
        'New Product Launch', 'Customer engagement'],
      conduct_for_list: ['Particular Dealer', 'Multiple Dealers', 'For the Area', 'For the Region'],
      controls: {
        dealer: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        region: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        category: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        invitee_classification: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        type_of_activity: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        conduct_for: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        cip_detail: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        area: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        dealer_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        dealer_sap_id: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        location: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        cip_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        no_of_customer_invite: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        cip_objective: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        expenses: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        state: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
      },
    }
    this.searchReport = lodash.debounce(this.searchReport, 500);
  }


  componentDidMount() {
    this.getCipCategory();
    this.getState();
    this.getUserInfo();
  }

  getUserInfo = () => {
    UserService.getUserInfo()
      .then(data => {
        let userData = data.data.data;
        let { controls } = this.state;
        if (userData && Object.keys(userData).length > 0) {
          if (userData.state_uuid) {
            controls.state.value = userData.state_uuid;
            this.getCity(userData.state_uuid);
          }
          if (userData.city_uuid) {
            controls.city.value = userData.city_uuid;
          }
        }
      })
      .catch(e => {
        console.error("e", e);
      })
  }

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { location, cip_date, no_of_customer_invite, cip_objective, dealer,
      state, city, invitee_classification, conduct_for, type_of_activity, category } = controls;

    if ((dealer.touched === true || isSubmit) && controls.conduct_for.value === 'Particular Dealer') {
      if (dealer.value && dealer.value.length > 0) {
        dealer.valid = true;
        dealer.showErrorMsg = false;
      } else {
        dealer.showErrorMsg = true;
        dealer.valid = false;
      }
    } else {
      dealer.valid = true;
      dealer.showErrorMsg = false;
    }

    if (city.touched === true || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !(city.nullValue);
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }


    if (category.touched === true || isSubmit) {
      category = Validation.notNullValidator(category);
      category.valid = !(category.nullValue);
      if (category.valid === true) {
        category.showErrorMsg = false;
      } else {
        category.showErrorMsg = true;
        category.valid = false;
      }
    }

    if (state.touched === true || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !(state.nullValue);
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (type_of_activity.touched === true || isSubmit) {
      type_of_activity = Validation.notNullValidator(type_of_activity);
      type_of_activity.valid = !(type_of_activity.nullValue);
      if (type_of_activity.valid === false) {
        type_of_activity.showErrorMsg = true;
      } else {
        type_of_activity.showErrorMsg = false;
      }
    }


    if (conduct_for.touched === true || isSubmit) {
      conduct_for = Validation.notNullValidator(conduct_for);
      conduct_for.valid = !(conduct_for.nullValue);
      if (conduct_for.valid === false) {
        conduct_for.showErrorMsg = true;
      } else {
        conduct_for.showErrorMsg = false;
      }
    }
    if (invitee_classification.touched === true || isSubmit) {
      if (invitee_classification.value && invitee_classification.value.length > 0) {
        invitee_classification.valid = true;
        invitee_classification.showErrorMsg = false;
      } else {
        invitee_classification.showErrorMsg = true;
        invitee_classification.valid = false;
      }
    }



    if ((location.touched === true || isSubmit) && (controls.type_of_activity.value && controls.type_of_activity.value.toLowerCase() !== 'digital cip')) {
      location = Validation.notNullValidator(location);
      location.valid = !(location.nullValue);
      if (location.valid === false) {
        location.showErrorMsg = true;
        location.valid = false;
      } else {
        location.showErrorMsg = false;
        location.valid = true;
      }
    } else {
      location.showErrorMsg = false;
      location.valid = true;
    }

    if (cip_date.touched === true || isSubmit) {
      cip_date = Validation.notNullValidator(cip_date);
      cip_date.valid = !(cip_date.nullValue);
      if (cip_date.valid === false) {
        cip_date.showErrorMsg = true;
      } else {
        cip_date.showErrorMsg = false;
      }
    }

    //region.valid = true;
    // region.showErrorMsg = false;
    // if ((region.touched === true || isSubmit) && this.state.userDetail.type === Roles.SALES_OFFICER_ROLE) {
    //   region = Validation.notNullValidator(region);
    //   region.valid = !(region.nullValue);
    //   if (region.valid === false) {
    //     region.showErrorMsg = true;
    //   } else {
    //     region.showErrorMsg = false;
    //   }
    // }

    // area.valid = true;
    // area.showErrorMsg = false;
    // if ((area.touched === true || isSubmit) && this.state.userDetail.type === Roles.SALES_OFFICER_ROLE) {
    //   area = Validation.notNullValidator(area);
    //   area.valid = !(area.nullValue);
    //   if (area.valid === false) {
    //     area.showErrorMsg = true;
    //   } else {
    //     area.showErrorMsg = false;
    //   }
    // }

    if (no_of_customer_invite.touched === true || isSubmit) {
      no_of_customer_invite = Validation.notNullValidator(no_of_customer_invite);
      no_of_customer_invite.valid = !(no_of_customer_invite.nullValue);
      if (no_of_customer_invite.valid === false) {
        no_of_customer_invite.showErrorMsg = true;
      } else {
        no_of_customer_invite.showErrorMsg = false;
      }
    }

    if (cip_objective.touched === true || isSubmit) {
      cip_objective = Validation.notNullValidator(cip_objective);
      cip_objective.valid = !(cip_objective.nullValue);
      if (cip_objective.valid === false) {
        cip_objective.showErrorMsg = true;
      } else {
        cip_objective.showErrorMsg = false;
      }
    }
    if (location.valid === true &&
      dealer.valid === true &&
      cip_objective.valid === true &&
      cip_date.valid === true &&
      no_of_customer_invite.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  }

  handleInputChange = async (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let { controls, value } = this.state;

    if (controlName === 'no_of_customer_invite') {
      if (isNaN(controlValue)) {
        return
      }
    }
    if (controlName === 'category') {
      controls.invitee_classification.value = [];
      controls.type_of_activity.value = '';
    }

    controls[controlName].value = controlValue;
    controls[controlName].touched = true;

    if (controlName === 'conduct_for') {
      if (controls.conduct_for.value !== 'Particular Dealer') {
        controls.dealer_name.value = null;
        controls.dealer_sap_id.value = null;
        value = '';
        controls.dealer.value = [];
        this.onSuggestionsClearRequested();
      }
    }
    if (controls.type_of_activity.value && controls.type_of_activity.value.toLowerCase() === 'digital cip') {
      controls.location.value = '';
    }
    await this.setState({ controls, value });
    if (controlName === 'state') {
      this.getCity();
    }
    if (controlName === 'category') {
      this.getInviteeClassification();
    }
    this.handleValidation();
  }

  searchReport = (search) => {
    this.getAllDealerReport(1, search.value, true)
  }


  getAllDealerReport = (page, search) => {
    this.setState({
      isLoading: true
    });
    UserService.searchDealer(search)
      .then(data => {
        const { data: dealers } = data.data;
        let { suggestions } = this.state;
        if (dealers) {
          suggestions = dealers;
        } else {
          suggestions = [];
        }
        this.setState({ suggestions, isLoading: false });
      })
      .catch(e => {
        this.setState({
          isLoading: false
        });
      })
  }
  getSuggestionValue = (suggestion) => {
    let { controls } = this.state;
    controls.dealer.value = [suggestion];
    controls.dealer.touched = true;
    this.setState({
      controls
    })
    this.handleValidation();
    return suggestion.business_name;
  }

  renderSuggestion = (suggestion) => {
    return (
      <span>{suggestion.business_name}{' '}({suggestion.sap_id})</span>
    );
  }

  onChange = (event, { newValue, method }) => {
    let { controls } = this.state;
    if (!newValue) {
      controls.dealer.value = [];
    }
    this.setState({
      value: newValue,
      controls
    });
  };

  onSuggestionsClearRequested = () => {
    let { controls } = this.state;
    this.setState({
      suggestions: [],
      controls
    });
  };


  getState = () => {
    this.setState({ isLoading: true });
    MerchandiseWalletService.getState()
      .then((data) => {
        let { stateData, controls } = this.state;
        let { data: states } = data.data;
        stateData = states;
        this.setState({ stateData, controls, isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  getCity = (state_uuid = null) => {
    let { controls } = this.state;
    this.setState({ isLoading: true });
    MerchandiseWalletService.getCity(state_uuid ? state_uuid : controls.state.value)
      .then((data) => {
        let { cityData, controls } = this.state;
        const { data: cities } = data.data;
        cityData = cities;
        this.setState({ cityData, controls, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };

  getCipCategory = () => {
    CustomerIntractionServie.getCipCategory()
      .then((data) => {
        let { cipCategory } = this.state;
        cipCategory = data.data.data;
        this.setState({ cipCategory });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };

  getInviteeClassification = () => {
    let { controls } = this.state;
    let reqData = {
      "category": controls.category.value
    }
    this.setState({
      isLoading: true
    })
    CustomerIntractionServie.getInviteeClassification(reqData)
      .then((data) => {
        let { inviteeClassificationData } = this.state;
        inviteeClassificationData = data.data.data;
        this.setState({ inviteeClassificationData, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };
  getActivity = () => {
    let { controls } = this.state;
    let reqData = {
      "category": controls.category.value,
      invite_classification: []
    }
    controls.invitee_classification.value.forEach(element => {
      reqData.invite_classification.push(element.label);
    });
    this.setState({
      isLoading: true
    });
    CustomerIntractionServie.getActivity(reqData)
      .then((data) => {
        let { activity } = this.state;
        activity = data.data.data;
        this.setState({ activity, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };
  createCIP = (isSubmit) => {
    if (!this.handleValidation(isSubmit)) {
      return
    }
    let { controls } = this.state;
    let reqData = {
      "dealer_name": controls.dealer.value && controls.dealer.value.length > 0 ? controls.dealer.value[0].business_name : '',
      "dealer_sap_id": controls.dealer.value && controls.dealer.value.length > 0 ? controls.dealer.value[0].sap_id : '',
      "cip_date": controls.cip_date.value,
      "cip_objective": controls.cip_objective.value,
      "location": controls.location.value,
      "no_of_customer_invite": controls.no_of_customer_invite.value,
      "category": controls.category.value,
      "invitee_classification": [],
      "type_of_activity": controls.type_of_activity.value,
      "conduct_for": controls.conduct_for.value,
      "city": controls.city.value,
      "state": controls.state.value,
      "cip_detail": controls.cip_detail.value
    }
    controls.invitee_classification.value.forEach(element => {
      reqData.invitee_classification.push(element.label);
    });
    if (this.state.userDetail.type === Roles.SALES_OFFICER_ROLE) {
      reqData.area_id = +controls.area.value;
    } else {
      reqData.area_id = this.state.userDetail.id;
    }

    this.setState({ isLoading: true });
    CustomerIntractionServie.addCipV2(reqData)
      .then(data => {
        this.setState({
          isLoading: false
        })
        this.refs.refSuccessModal.toggle({
          maxWidth: '408px',
          title: 'CIP Created Successfully!',
          message: 'During CIP, We request you to click couple of photographs and at the end of the CIP, we request you to upload 2/3 photographs.'
        });
        // if (data.data.data && Object.keys(data.data.data).length > 0) {
        //   this.setState({
        //     isDisplayCustomerIntractionDetail: true,
        //     cip_id: data.data.data.id,
        //     cip_uuid: null,
        //     isLoading: false
        //   })
        // } else {
        //   this.setState({
        //     isDisplayCustomerIntractionDetail: false,
        //     isLoading: false
        //   })
        // }
      })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : '';
        ModalService.openAlert('', message, 'error');
        this.setState({ isLoading: false });
      });
  }

  onChangeInviteClassfication = async (selctedItems) => {
    let { controls } = this.state;
    if (selctedItems && selctedItems.length > 0) {
      let filter = selctedItems.filter(x => x.label === "sub_dealers");
      if (filter.length > 0) {
        let index = selctedItems.findIndex(x => x.label === "sub_dealers");
        if (index === 0 && selctedItems.length === 1) {
          this.closeDropDownMultiple.click();
        } else if (index === (selctedItems.length - 1)) {
          selctedItems = filter;
          this.closeDropDownMultiple.click();
        } else {
          selctedItems.splice(index, 1);
        }
        // this.refs.refDropDownMultiple.toggle();
      }
    }
    controls.invitee_classification.value = selctedItems;
    controls.invitee_classification.touched = true;
    controls.type_of_activity.value = '';
    // refDropDownMultiple
    await this.setState({
      controls
    })
    this.handleValidation();
    if (selctedItems.length > 0) {
      this.getActivity();
    } else {
      this.setState({
        activity: []
      })
    }
  }

  handleCipDetail = (e, controlName) => {
    const { controls } = this.state;
    controls[controlName].value = e;
    controls[controlName].touched = true;
    this.setState({ controls });
    //this.handleValidation();
  };

  suceessOK = (ok) => {
    if (this.props.goToCipSummary) {
      this.props.goToCipSummary()
    } else {
      this.setState({
        isRedirectSummary: true
      })
    }
  }

  render() {
    let { controls, suggestions, value, inviteeClassificationData } = this.state;
    const inputProps = {
      placeholder: "Search by dealer name SAP ID",
      value,
      onChange: this.onChange,
      disabled: controls.conduct_for.value !== 'Particular Dealer' ? true : false
    };
    return (
      <>
        {
          this.state.isRedirectSummary &&
          <Redirect to={{
            pathname: '/cip-summary',
          }} />
        }
        {this.state.isLoading && < CustomSpinner />}
        <Card style={{ marginTop: '25px' }}>
          <CardBody>
            <Row>

              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">Category<span className="required-field">*</span></Label>
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="category"
                    value={controls.category.value}
                    onChange={e => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="" disabled>
                      Select
                    </option>
                    {this.state.cipCategory.map(item => (
                      <option value={item.label}>{item.name}</option>
                    ))}
                  </select>
                  {controls.category.showErrorMsg && (
                    <div className="error">
                      * Please select category.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">Invitee Classification<span className="required-field">*</span></Label>
                  <DropDownMultiple
                    ref='refDropDownMultiple'
                    maxHeight="auto"
                    getOptionKey={'label'}
                    getOptionValue={'name'}
                    DropdownItems={inviteeClassificationData}
                    selectedItems={controls.invitee_classification.value}
                    setSelectedItems={(selectedItems) => this.onChangeInviteClassfication(selectedItems)}
                  />
                  {controls.invitee_classification.showErrorMsg && (
                    <div className="error">
                      * Please select invitee classification.
                    </div>
                  )}
                  <input ref={input => this.closeDropDownMultiple = input} style={{ display: 'none' }} />
                </FormGroup>
              </Col>

              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">Type of Activity<span className="required-field">*</span></Label>
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="type_of_activity"
                    value={controls.type_of_activity.value}
                    onChange={e => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="" disabled>
                      Select
                    </option>
                    {this.state.activity.map(item => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                  <Label for="common" className="font-italic" style={{ fontSize: '11px' }}>Based on invitee classification.</Label>
                  {/* , type of activity will be added. */}
                  {controls.type_of_activity.showErrorMsg && (
                    <div className="error">
                      * Please select type of activity.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">CIP Objective<span className="required-field">*</span></Label>
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="cip_objective"
                    value={controls.cip_objective.value}
                    onChange={e => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="" disabled>
                      Select Objective
                    </option>
                    {this.state.cip_objetives.map(item => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  {controls.cip_objective.showErrorMsg && (
                    <div className="error">
                      * Please select CIP objective.
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">CIP Conducted For<span className="required-field">*</span></Label>
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="conduct_for"
                    value={controls.conduct_for.value}
                    onChange={e => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="" disabled>
                      Select
                    </option>
                    {this.state.conduct_for_list.map(item => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  {controls.conduct_for.showErrorMsg && (
                    <div className="error">
                      * Please select CIP conducted for.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup className={controls.conduct_for.value !== 'Particular Dealer' ? 'opacity-0-5' : ''}>
                  <Label for="owner_name">Dealer Name<span className="required-field">*</span></Label>
                  <div>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.searchReport}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      inputProps={inputProps}
                    />
                    {/* <img src="images/search.svg" alt='' className="report-search" style={{ marginTop: '31px', right: '18px' }} /> */}
                  </div>
                  <Label for="common" className="font-italic" style={{ fontSize: '11px' }}>Based on CIP Conduct For, dealer will be added.</Label>
                  {controls.dealer.showErrorMsg && (
                    <div className="error">
                      * Please select dealer.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup className={controls.type_of_activity.value && controls.type_of_activity.value.toLowerCase() === 'digital cip' ? 'opacity-0-5' : ''}>
                  <Label for="location">CIP Venue<span className="required-field">*</span></Label>
                  <Input
                    type="text"
                    name="location"
                    id="location"
                    value={controls.location.value}
                    onChange={(e) => this.handleInputChange(e)}
                    autocomplete="nofill"
                    placeholder="Enter Location" />
                  {controls.location.showErrorMsg && (
                    <div className="error">
                      * Please enter location.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">State<span className="required-field">*</span></Label>
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="state"
                    value={controls.state.value}
                    onChange={e => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="" disabled>Select</option>
                    {this.state.stateData.map((state, j) => (
                      <option
                        className="text-capitalize"
                        value={state.state_id}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {controls.state.showErrorMsg && (
                    <div className="error">
                      * Please select state.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">City<span className="required-field">*</span></Label>
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="city"
                    value={controls.city.value}
                    onChange={e => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="" disabled>Select</option>
                    {this.state.cityData.map((city, j) => (
                      <option
                        className="text-capitalize"
                        value={city.city_id}
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {controls.city.showErrorMsg && (
                    <div className="error">
                      * Please select city.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup className="d-flex-column">
                  <Label for="common">CIP Date<span className="required-field">*</span></Label>
                  <Label
                    onClick={e =>
                      this.calendar1.state.open && e.preventDefault()
                    }
                  >
                    <InputGroup>
                      <DatePicker
                        className={"form-control"}
                        onChange={e => this.handleInputChange({
                          target: {
                            name: 'cip_date',
                            value: e
                          }
                        })}
                        selected={controls.cip_date.value}
                        name="birth_date"
                        dateFormat="dd/MM/yyyy"
                        autoComplete="nofill"
                        showMonthDropdown
                        ref={r => (this.calendar1 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        //  shouldCloseOnSelect
                        // maxDate={new Date()}
                        popperPlacement="bottom"
                        placeholderText='Select'
                        popperModifiers={{
                          flip: {
                            enabled: false
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false
                          }
                        }}
                        dropdownMode="select"
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                  {controls.cip_date.showErrorMsg && (
                    <div className="error">
                      * Please select CIP date.
                    </div>
                  )}
                </FormGroup>
              </Col>



              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">No Of Customer Invited<span className="required-field">*</span></Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="no_of_customer_invite"
                    id="no_of_invite"
                    value={controls.no_of_customer_invite.value}
                    onChange={(e) => this.handleInputChange(e)}
                    placeholder="Type Here"
                  />
                  {controls.no_of_customer_invite.showErrorMsg && (
                    <div className="error">
                      * Please enter no of customer invited.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">Additional Details of CIP</Label>
                  <TextareaAutosize
                    name="cip_detail"
                    autoComplete="nofill"
                    id="cip_detail"
                    value={controls.cip_detail.value}
                    style={{ minHeight: 70, maxHeight: 80, fontSize: "14px" }}
                    className="form-control"
                    onChange={e =>
                      this.handleCipDetail(e.target.value, "cip_detail")
                    }
                    placeholder="Type Here"
                  />
                </FormGroup>
              </Col>
              {
                <Col className="text-align-right" style={{ marginTop: '20px' }}> <Button
                  style={{ width: '200px' }}
                  className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                  color="warning" onClick={() => { this.createCIP(true); }}>
                  Create new CIP
                </Button></Col>
              }
            </Row>
          </CardBody>
        </Card>

        <SuccessModal ref={"refSuccessModal"} suceessOK={this.suceessOK} />
      </>
    );
  }
}

export default CipDealerDetail;