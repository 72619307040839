import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import ProposalStatus from "../../components/ProposalStatus/ProposalStatus";
import Storage from "../../services/Storage";
import ProposalSatusUpdate from "../../components/ProposalSatusUpdate/ProposalSatusUpdate";
import ProposalSerivice from "../../services/ProposalSerivice";
import RecciVisit from "../../components/RecciVisit/RecciVisit";
import LayoutStage from "../../components/LayoutStage/LayoutStage";
import DealerWorkInProgress from "../../components/DealerWorkInProgress/DealerWorkInProgress";
import AgencyWorkInProgress from "../../components/AgencyWorkInProgress/AgencyWorkInProgress";
import InterimVisit from "../../components/InterimVisit/InterimVisit";
import HandOver from "../../components/HandOver/HandOver";
import CompletionStage from "../../components/CompletionStage/CompletionStage";
import EstimateNeeded from "../../components/EstimateNeeded/EstimateNeeded";
import UploadMachineBill from "../../components/UploadMachineBill/UploadMachineBill";
import _ from "lodash";
import swal from "sweetalert";
import Validation from "../../services/Validation";
import ProposalDetails from "../ProposalDetails/ProposalDetails";
import * as Scroll from "react-scroll";
import Ionicon from "react-ionicons";
import moment from "moment-timezone";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { Roles } from "../../constants/constant.role";
class ProposalStages extends Component {
  steelWheelState = [
    {
      id: "recci_in_progress",
      name: "Recci in progress",
      count: 1,
      completed_date: null,
    },
    {
      id: "layout_agreement",
      name: "Layout & Agreement",
      count: 1,
      completed_date: null,
    },
    {
      id: "estimate_needed",
      name: "Estimate Needed",
      count: 1,
      completed_date: null,
    },
    {
      id: "Dealer_work_in_progress",
      name: "Dealer Work In Progress",
      count: 1,
      completed_date: null,
    },
    {
      id: "Agency_work_in_progress",
      name: "Agency work in progress",
      count: 1,
      completed_date: null,
    },
    {
      id: "Hand Over",
      name: "Hand Over",
      count: 1,
      completed_date: null,
    },
    {
      id: "Pre inauguration",
      name: "Pre inauguration",
      count: 1,
      completed_date: null,
    },
    {
      id: "Inaugurated",
      name: "Inaugurated",
      count: 1,
      completed_date: null,
    },
    {
      id: "Project completed",
      name: "Project completed",
      count: 1,
      completed_date: null,
    },
  ];
  state = {};
  xpressWheelState = [
    {
      id: "recci_in_progress",
      name: "Recci in progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "layout_agreement",
      name: "Layout & Agreement",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Dealer_work_in_progress",
      name: "Dealer Work In Progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Agency_work_in_progress",
      name: "Agency work in progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Hand Over",
      name: "Hand Over",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Pre inauguration",
      name: "Pre inauguration",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Inaugurated",
      name: "Inaugurated",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Project completed",
      name: "Project completed",
      counts: 0,
      completed_date: null,
    },
  ];

  xpressWheelState = [
    {
      id: "recci_in_progress",
      name: "Recci in progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "layout_agreement",
      name: "Layout & Agreement",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Dealer_work_in_progress",
      name: "Dealer Work In Progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Agency_work_in_progress",
      name: "Agency work in progress",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Hand Over",
      name: "Hand Over",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Pre inauguration",
      name: "Pre inauguration",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Inaugurated",
      name: "Inaugurated",
      counts: 0,
      completed_date: null,
    },
    {
      id: "Project completed",
      name: "Project completed",
      counts: 0,
      completed_date: null,
    },
  ];
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    let userDetail = Storage.getUserDetail();
    this.state = {
      id: query.get("id"),
      type: query.get("type"),
      proposal_status: query.get("proposal-status"),
      userDetail: userDetail,
      proposalStageStatus: ProposalSerivice.getProposalStageStatus()
        ? ProposalSerivice.getProposalStageStatus()
        : {},
      isRecciInProgressReadOnly: true,
      proposalByIdData: {},
      proposalStageDetail: {
        agency_completion_data: [],
        dealership_completion_data: [],
        estimation_data: [],
        intrim_visit_data: [],
        layout_data: [],
        proposal_status: [],
        recci_data: [],
        stages_details: [],
      },
      currentPriority: 0,
      agencyList: [],
      is_display_view_deatil: false,
      controls: {
        agency: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        phoneNumber: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      isFormValid: false,
      isCompletedAllStages: false,
    };

    if (
      this.state.proposalStageStatus &&
      Object.keys(this.state.proposalStageStatus).length <= 0
    ) {
      this.goToPrevPage();
    }
    if (
      this.state.proposalStageStatus &&
      Object.keys(this.state.proposalStageStatus).length > 0
    ) {
      if (
        this.state.proposalStageStatus.project_status &&
        this.state.proposalStageStatus.project_status.toLowerCase() ===
        "fresh proposal" &&
        this.state.userDetail.access_level === "all"
      ) {
        //this.state.isFreshProposal = true;
      }
    }
    if (
      this.state.proposalStageStatus &&
      this.state.proposalStageStatus.outlet_type &&
      (this.state.proposalStageStatus.outlet_type.toLowerCase() ===
        "xpress wheel" ||
        this.state.proposalStageStatus.outlet_type.toLowerCase() ===
        "farm xpress wheel")
    ) {
      this.state.progressStates = this.xpressWheelState;
    } else {
      this.state.progressStates = this.steelWheelState;
    }
    this.state.progressStateCurrentIndex = 0;
    this.childHandOver = React.createRef();
    this.childProposalStatus = React.createRef();
    this.childRecciVisit = React.createRef();
    this.refProposalDetails = React.createRef();
    this.refLayout = React.createRef();
  }

  componentDidMount() {
    this.initApi();
  }

  initApi = async () => {
    await this.getAgencyList();
    await this.getPrposalById();
    this.getProposalStageDetail();
  };

  getAgencyList() {
    ProposalSerivice.getAgencyList()
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          this.setState((prevState) => {
            return { ...prevState, agencyList: res.data.data };
          });
        } else {
          this.setState((prevState) => {
            return { ...prevState, agencyList: [] };
          });
        }
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { agency } = controls;
    if (agency.touched === true || isSubmit) {
      agency = Validation.notNullValidator(agency);
      agency.valid = !agency.nullValue;
      if (agency.valid === false) {
        agency.showErrorMsg = true;
      } else {
        agency.showErrorMsg = false;
      }
    }

    if (agency.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  assignAgency = () => {
    let { controls } = this.state;
    if (!this.handleValidation(true)) {
      return;
    }

    let reqData = {
      agency_id: controls.agency.value,
      uuid: this.state.proposalStageStatus.uuid,
    };
    ProposalSerivice.assignAgency(reqData)
      .then((res) => {
        controls.agency.value = "";
        controls.phoneNumber.value = "";
        swal({
          title: "",
          text: "Agency assigned successfully",
          icon: "success",
          type: "success",
        }).then((value) => {
          this.getProposalStageDetail();
        });
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  getProposalStageDetail = () => {
    if (
      (!this.state.proposalStageStatus &&
        !this.state.proposalStageStatus.uuid) ||
      this.state.proposalStageStatus.uuid === undefined
    ) {
      return;
    }
    // this.setState({ isLoading: true });
    ProposalSerivice.getProposalStageDetail(this.state.proposalStageStatus.uuid)
      .then((res) => {
        let { currentPriority, isCompletedAllStages } = this.state;
        let currentIndex = -1;
        if (res.data.data && res.data.data.length > 0) {
          let len = res.data.data[0].stages_details.length;
          let stage = "";
          if (
            res.data.data[0].proposal_status[0].project_status ===
            "Proposal accepted"
          ) {
            stage = "Recci in progress";
          }
          let maxPriority = 0;
          if (res.data.data[0].stages_details.length > 0) {
            maxPriority = Math.max.apply(
              Math,
              res.data.data[0].stages_details.map(function (o) {
                return o.priority;
              })
            );
            currentPriority = maxPriority;
          }
          let stageObj = {};
          if (maxPriority && maxPriority > 0) {
            stageObj = _.filter(res.data.data[0].stages_details, (element) => {
              return element.priority === maxPriority;
            });
            if (stageObj && stageObj.length > 0) {
              stage = stageObj[0].stage;
            }
          }
          currentIndex = this.state.progressStates.findIndex(
            (x) => x.name.toLowerCase() === stage.toLowerCase()
          );
          if (len > 0) {
            currentIndex++;
          }
          if (
            this.state.proposalStageStatus.project_status.toLowerCase() ===
            "fresh proposal"
          ) {
            currentIndex = -1;
          }
          this.state.progressStates.filter((x) => (x.completed_date = ""));
          res.data.data[0].stages_details.forEach((element) => {
            if (element.completed_date) {
              let index = -1;
              if (element.stage) {
                index = this.state.progressStates.findIndex(
                  (x) => x.name.toLowerCase() === element.stage.toLowerCase()
                );
              }
              if (index > -1) {
                this.state.progressStates[index].completed_date =
                  element.completed_date;
              }
            }
          });

          if (
            currentIndex === 1 &&
            this.state.proposalByIdData.stage &&
            this.state.proposalByIdData.stage === "Recci in progress"
          ) {
            currentIndex = 0;
          }
          if (
            currentIndex === 2 &&
            this.state.proposalByIdData.stage &&
            this.state.proposalByIdData.stage === "Layout & Agreement"
          ) {
            currentIndex = 1;
          }

          if (this.state.proposalByIdData.outlet_type === "Steel Wheel") {
            if (
              currentIndex === 4 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Dealer work in progress"
            ) {
              currentIndex = 3;
            }
            if (
              currentIndex === 5 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Agency work in progress"
            ) {
              currentIndex = 4;
            }
            if (
              currentIndex === 6 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Hand Over"
            ) {
              currentIndex = 5;
            }
            if (
              currentIndex === 7 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Pre inauguration"
            ) {
              currentIndex = 6;
            }

            if (
              currentIndex === 8 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Inaugurated"
            ) {
              currentIndex = 7;
            }
          } else {
            if (
              currentIndex === 3 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Dealer work in progress"
            ) {
              currentIndex = 2;
            }
            if (
              currentIndex === 4 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Agency work in progress"
            ) {
              currentIndex = 3;
            }
            if (
              currentIndex === 5 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Hand Over"
            ) {
              currentIndex = 4;
            }
            if (
              currentIndex === 6 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Pre inauguration"
            ) {
              currentIndex = 5;
            }

            if (
              currentIndex === 7 &&
              this.state.proposalByIdData.stage &&
              this.state.proposalByIdData.stage === "Inaugurated"
            ) {
              currentIndex = 6;
            }
          }

          if (
            stage &&
            stage === "Inaugurated" &&
            (currentIndex === 7 || currentIndex === 8)
          ) {
            if (
              stageObj[0].completed_date &&
              new Date(
                new Date(
                  moment(stageObj[0].completed_date, "DD-MM-YYYY").format(
                    "DD-MMM-YYYY"
                  )
                )
              ).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
            ) {
              currentIndex -= 1;
            }
          }

          if (
            res.data.data[0] &&
            Object.keys(res.data.data[0]).length > 0 &&
            res.data.data[0].intrim_visit_data.length > 0
          ) {
            res.data.data[0].intrim_visit_data = res.data.data[0].intrim_visit_data.reverse();
          }

          let totalStages = this.steelWheelState.length;
          let countStages = 0;
          (res.data.data[0].stages_details
            ? res.data.data[0].stages_details
            : []
          ).forEach((element) => {
            if (element.stage) {
              if (
                this.state.proposalByIdData.outlet_type === "Steel Wheel" &&
                this.steelWheelState.findIndex(
                  (x) =>
                    x.name &&
                    x.name.toLowerCase() === element.stage.toLowerCase()
                ) > -1
              ) {
                countStages += 1;
              } else if (
                this.state.proposalByIdData.outlet_type === "Xpress Wheel" &&
                this.steelWheelState.findIndex(
                  (x) =>
                    x.name &&
                    x.name.toLowerCase() === element.stage.toLowerCase()
                ) > -1
              ) {
                countStages += 1;
              }
            }
          });
          isCompletedAllStages = countStages === totalStages ? true : false;
          this.setState(
            (prevState) => {
              return {
                ...prevState,
                proposalStageDetail: res.data.data[0],
                progressStateCurrentIndex: currentIndex,
                currentPriority: currentPriority,
                isLoading: false,
                isCompletedAllStages,
              };
            },
            () => {
              if (
                this.childProposalStatus &&
                this.childProposalStatus.current
              ) {
                this.childProposalStatus.current.setPreInauguration_date();
              }

              if (this.childRecciVisit && this.childRecciVisit.current) {
                this.childRecciVisit.current.setRecciData();
              }
              if (this.refLayout && this.refLayout.current) {
                this.refLayout.current.setLayoutData();
              }
            }
          );
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };
  getPrposalById = async () => {
    if (
      (!this.state.proposalStageStatus &&
        !this.state.proposalStageStatus.uuid) ||
      this.state.proposalStageStatus.uuid === undefined
    ) {
      return;
    }
    try {
      this.setState({ isLoading: true });
      let res = await ProposalSerivice.getProposalById(
        this.state.proposalStageStatus.uuid
      );
      if (res.data.data && res.data.data.length > 0) {
        let { controls } = this.state;
        if (res.data.data[0].agency_id) {
          controls.agency.value = res.data.data[0].agency_id;
          let agency = this.state.agencyList.filter(
            (x) => x.id === res.data.data[0].agency_id
          );
          if (agency.length > 0) {
            controls.phoneNumber.value = agency[0].phonenumber;
          }
        }
        this.setState((prevState) => {
          return {
            ...prevState,
            proposalByIdData: res.data.data[0],
            isLoading: false,
            controls,
          };
        });
      }
      return true;
    } catch (error) {
      this.setState({ isLoading: false });
      return true;
    }
  };
  goToPrevPage = (isSetproject_status = false) => {
    if (!isSetproject_status) {
      let projectStagetFilter = ProposalSerivice.getFilterProjectStage();
      if (projectStagetFilter && Object.keys(projectStagetFilter).length > 0) {
        projectStagetFilter.project_status = this.state.proposalByIdData.project_status;
        ProposalSerivice.setFilterInProjectStage(projectStagetFilter);
      } else {
        ProposalSerivice.setFilterInProjectStage({
          project_status: this.state.proposalByIdData.project_status,
        });
      }
    }
    this.props.history.goBack();
  };

  redirectViewProposal = (id) => {
    this.props.history.push(`proposal-details?id=${id}`);
  };

  handlePersonVisiting = (e) => {
    const { controls } = this.state;
    const controlName = e.target.name;
    controls[controlName].value = e.target.value;
    let phoneNumber = _.filter(this.state.agencyList, (element) => {
      return element.id == e.target.value;
    });
    if (phoneNumber.length > 0) {
      controls["phoneNumber"].value = phoneNumber[0].phonenumber;
    }
    this.setState({ controls });
    this.handleValidation(false);
  };

  openViewCloseViewDetail = () => {
    let { is_display_view_deatil, proposalByIdData } = this.state;
    is_display_view_deatil = !is_display_view_deatil;
    this.setState(
      {
        is_display_view_deatil,
      },
      () => {
        if (is_display_view_deatil) {
          this.refProposalDetails.current.getViewDetail(proposalByIdData.uuid);
          this.scrollTo("proposal-view-detail");
        }
      }
    );
  };

  scrollTo(id) {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80,
    });
  }

  redirectDashboard = () => {
    this.props.history.push(`proposal-dashboard`);
  };

  render() {
    let { proposalStageDetail, userDetail, isCompletedAllStages } = this.state;

    return (
      <>
        {/* <div className="header-title">Project Stage</div> */}
        {this.state.isLoading && <CustomSpinner />}
        <>
          <span
            className="prev-page-link cursor-pointer"
            onClick={this.redirectDashboard}
          >
            Proposal Dashboard
          </span>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span
            className="prev-page-link cursor-pointer"
            onClick={this.goToPrevPage}
          >
            Project Stage
          </span>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span
            className="prev-page-link cursor-pointer"
            style={{ color: "#2c3f4c" }}
          >
            {this.state.proposalByIdData && this.state.proposalByIdData.name
              ? this.state.proposalByIdData.name
              : ""}
          </span>
        </>
        <br />
        {!this.state.isFreshProposal && (
          <>
            <Card>
              <CardBody>
                <Row className="dealership-details-title-wrapp">
                  <Col xl="6" md="6" xs="6">
                    <b>Project status</b>
                  </Col>
                  <div className="d-flex-row">
                    {/* <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: 'right' }}
                      onClick={(e) => this.openViewCloseViewDetail()}
                    >
                      {this.state.is_display_view_deatil ? 'Hide Detail' : 'View Detail'}
                    </Button>&nbsp;&nbsp; */}
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="dark"
                      onClick={() => this.goToPrevPage()}
                    >
                      Back
                    </Button>
                  </div>
                </Row>
                <hr style={{ marginTop: "0.5rem" }} />
                <div>
                  <section>
                    <nav>
                      <ol className="cd-breadcrumb triangle custom-icons">
                        {this.state.progressStates.map((row, i) => (
                          <li
                            className={
                              this.state.progressStateCurrentIndex > i
                                ? "current-before"
                                : this.state.progressStateCurrentIndex === i
                                  ? "current"
                                  : ""
                            }
                          >
                            <span>
                              {row.name}
                              <br />
                              <span style={{ marginLeft: "15px" }}>
                                {row.completed_date}
                              </span>
                            </span>
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </section>
                </div>
                <br />
                {this.state.proposalStageDetail &&
                  this.state.proposalStageDetail.status_history &&
                  this.state.proposalStageDetail.status_history.length > 0 && (
                    <div className="project-stage-status-tbl">
                      <Table className="mb-0  tbl-about tbl-about table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Status</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Status Change Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.proposalStageDetail.status_history.map(
                            (item) => (
                              <tr>
                                <td>{item.status}</td>
                                <td>{item.remark ? item.remark : "--"}</td>
                                <td>{item.created_at}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}
              </CardBody>
            </Card>
            <br />
            <ProposalStatus
              userDetail={this.state.userDetail}
              proposalByIdData={this.state.proposalByIdData}
              initApi={this.initApi}
              ref={this.childProposalStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              is_display_view_deatil={this.state.is_display_view_deatil}
              proposalStageStatus={this.state.proposalStageStatus}
              proposalStageDetail={this.state.proposalStageDetail}
              openViewCloseViewDetail={this.openViewCloseViewDetail}
              isCompletedAllStages={isCompletedAllStages}
              goToPrevPage={() => this.goToPrevPage()}
            />
          </>
        )}
        {this.state.userDetail.access_level === "all" && (
          <ProposalSatusUpdate
            proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            redirectViewProposal={this.redirectViewProposal}
            goToPrevPage={this.goToPrevPage}
            initApi={this.initApi}
            is_display_view_deatil={this.state.is_display_view_deatil}
            openViewCloseViewDetail={this.openViewCloseViewDetail}
            isCompletedAllStages={isCompletedAllStages}
            proposalStageDetail={this.state.proposalStageDetail}
          />
        )}
        {this.state.is_display_view_deatil && (
          <div className="proposal-view-detail">
            <br />
            <ProposalDetails
              goBack={this.openViewCloseViewDetail}
              ref={this.refProposalDetails}
              isCompletedAllStages={isCompletedAllStages}
            />
            <br />
            {this.state.userDetail.access_level === "all" && (
              <ProposalSatusUpdate
                proposalStageStatus={this.state.proposalStageStatus}
                getProposalStageDetail={this.getProposalStageDetail}
                redirectViewProposal={this.redirectViewProposal}
                goToPrevPage={this.goToPrevPage}
                initApi={this.initApi}
                is_display_view_deatil={this.state.is_display_view_deatil}
                openViewCloseViewDetail={this.openViewCloseViewDetail}
                proposalStageDetail={this.state.proposalStageDetail}
                isCompletedAllStages={isCompletedAllStages}
              />
            )}
          </div>
        )}

        {this.state.currentPriority >= 0 &&
          proposalStageDetail &&
          proposalStageDetail.proposal_status.length > 0 &&
          proposalStageDetail.proposal_status[0].project_status &&
          proposalStageDetail.proposal_status[0].project_status ===
          "Proposal accepted" &&
          userDetail.type &&
          userDetail.type === Roles.HEAD_OFFICE_ROLE ? (
          <>
            {/* {this.state.proposalStageDetail.recci_data.length > 0 && ( */}
            <RecciVisit
              status={this.state.id}
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalStageDetail={this.state.proposalStageDetail}
              initApi={this.initApi}
              isCompletedAllStages={isCompletedAllStages}
              ref={this.childRecciVisit}
            />
            {/* )} */}
          </>
        ) : (
          <>
            {this.state.currentPriority >= 0 &&
              proposalStageDetail &&
              proposalStageDetail.proposal_status.length > 0 &&
              proposalStageDetail.proposal_status[0].project_status &&
              proposalStageDetail.proposal_status[0].project_status ===
              "Proposal accepted" &&
              userDetail.type &&
              userDetail.type.toLowerCase() !== Roles.AGENCY_ROLE ? (
              <RecciVisit
                status={this.state.id}
                proposalStageStatus={this.state.proposalStageStatus}
                getProposalStageDetail={this.getProposalStageDetail}
                proposalStageDetail={this.state.proposalStageDetail}
                initApi={this.initApi}
                ref={this.childRecciVisit}
                isCompletedAllStages={isCompletedAllStages}
              />
            ) : (
              ""
            )}
          </>
        )}
        {/* {
          ((this.state.currentPriority >= 0) &&
            this.state.userDetail.type && this.state.userDetail.type.toLowercase() !== 'agnecy' &&
            proposalStageDetail && proposalStageDetail.proposal_status.length > 0 && (proposalStageDetail.proposal_status[0].project_status && proposalStageDetail.proposal_status[0].project_status === 'Proposal accepted')) && <RecciVisit
            status={this.state.id} proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail}
            initApi={this.initApi}
            ref={this.childRecciVisit} />
        } */}

        {this.state.currentPriority >= 1 &&
          this.state.proposalByIdData &&
          this.state.proposalByIdData.stage !== "Recci in progress" &&
          userDetail.type &&
          userDetail.type.toLowerCase() !== Roles.AGENCY_ROLE && (
            <InterimVisit
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalStageDetail={this.state.proposalStageDetail}
              isCompletedAllStages={isCompletedAllStages}
            />
          )}
        {userDetail.type &&
          userDetail.type.toLowerCase() !== Roles.AGENCY_ROLE &&
          proposalStageDetail.proposal_status.length > 0 &&
          proposalStageDetail.proposal_status[0].project_status ===
          "Proposal accepted" &&
          this.state.proposalByIdData.outlet_type !== "Xpress Wheel" &&
          this.state.proposalByIdData.outlet_type !== "Farm Xpress Wheel" && (
            <UploadMachineBill
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalStageDetail={this.state.proposalStageDetail}
              isCompletedAllStages={isCompletedAllStages}
            />
          )}

        {this.state.currentPriority >= 1 &&
          this.state.proposalByIdData &&
          userDetail.type &&
          userDetail.type.toLowerCase() !== Roles.AGENCY_ROLE && (
            <LayoutStage
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalByIdData={this.state.proposalByIdData}
              initApi={this.initApi}
              ref={this.refLayout}
              proposalStageDetail={this.state.proposalStageDetail}
              isCompletedAllStages={isCompletedAllStages}
            />
          )}
        {this.state.currentPriority >= 2 &&
          this.state.proposalByIdData.outlet_type !== "Xpress Wheel" &&
          this.state.proposalByIdData.outlet_type !== "Farm Xpress Wheel" && (
            <EstimateNeeded
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              proposalByIdData={this.state.proposalByIdData}
              proposalStageDetail={this.state.proposalStageDetail}
              isCompletedAllStages={isCompletedAllStages}
            />
          )}
        {((this.state.currentPriority >= 3 &&
          this.state.proposalByIdData.outlet_type === "Steel Wheel") ||
          (this.state.currentPriority >= 2 &&
            this.state.proposalByIdData.outlet_type !== "Steel Wheel")) &&
          this.state.proposalByIdData.stage !== "Layout & Agreement" &&
          userDetail.type &&
          userDetail.type.toLowerCase() !== Roles.AGENCY_ROLE && (
            <DealerWorkInProgress
              getProposalStageDetail={this.getProposalStageDetail}
              proposalStageStatus={this.state.proposalStageStatus}
              initApi={this.initApi}
              agencyList={this.state.agencyList ? this.state.agencyList : []}
              proposalByIdData={this.state.proposalByIdData}
              proposalStageDetail={this.state.proposalStageDetail}
              isCompletedAllStages={isCompletedAllStages}
            />
          )}
        {/* {
          (this.state.userDetail.access_level === 'all' && this.state.currentPriority >= 3) &&
          <>
            <br />
            <Card>
              <CardBody>
                <Row className="dealership-details-title-wrapp">
                  <Col xl="6" md="6" xs="6">
                    <b>Agency Allocation</b>
                  </Col>
                </Row>
                <hr style={{ marginTop: '0.5rem' }} />
                <Row>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>Agency<span className="required-field">*</span></Label>
                      <select className="form-control select-height-70rem" name="agency" value={controls.agency.value}
                        onChange={(e) => this.handlePersonVisiting(e)}>
                        <option value="" selected disabled>Select Agency</option>
                        {
                          this.state.agencyList && this.state.agencyList.map((item, i) => (
                            <option value={item.id}>{item.name}</option>
                          ))
                        }
                      </select>
                      {controls.agency.showErrorMsg && <div className="error">* Please select agency.</div>}
                    </FormGroup>
                  </Col>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>Phone No</Label>
                      <Input type="text" name="phoneNumber"
                        disabled="true"
                        value={controls.phoneNumber.value}
                        onChange={(e) => this.handleInputChange(e)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xl="3" md="12" xs="12" style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: 'right' }}
                      onClick={(e) => this.assignAgency()}
                    >
                      Submit
          </Button> &nbsp;&nbsp;
          </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        } */}
        {this.state.currentPriority >= 4 &&
          this.state.proposalByIdData.stage !== "Dealer work in progress" && (
            <AgencyWorkInProgress
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              initApi={this.initApi}
              proposalStageDetail={this.state.proposalStageDetail}
              isCompletedAllStages={isCompletedAllStages}
            />
          )}

        {this.state.currentPriority >= 5 &&
          this.state.proposalByIdData.stage !== "Agency work in progress" &&
          userDetail.type &&
          userDetail.type.toLowerCase() !== Roles.AGENCY_ROLE && (
            <HandOver
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              initApi={this.initApi}
              proposalByIdData={this.state.proposalByIdData}
              proposalStageDetail={this.state.proposalStageDetail}
              ref={this.childHandOver}
            />
          )}
        {/* {userDetail.type && userDetail.type !== Roles.AGENCY_ROLE && this.state.currentPriority >= 4 && this.state.proposalByIdData.outlet_type !== 'Xpress Wheel' && this.state.proposalByIdData.outlet_type !== 'Farm Xpress Wheel' ?
          <UploadMachineBill proposalStageStatus={this.state.proposalStageStatus}
            getProposalStageDetail={this.getProposalStageDetail}
            proposalStageDetail={this.state.proposalStageDetail} /> : ""
        } */}
        {this.state.currentPriority >= 7 &&
          this.state.proposalByIdData.stage === "Project completed" &&
          userDetail.type &&
          userDetail.type.toLowerCase() !== Roles.AGENCY_ROLE && (
            <CompletionStage
              proposalStageStatus={this.state.proposalStageStatus}
              getProposalStageDetail={this.getProposalStageDetail}
              initApi={this.initApi}
              proposalStageDetail={this.state.proposalStageDetail}
              isCompletedAllStages={isCompletedAllStages}
              userDetail={this.state.userDetail}
            />
          )}
      </>
    );
  }
}

export default ProposalStages;
