import React, { Component } from 'react';
import {
  Button,
  Input,
  Row, Col,
  Label, FormGroup, Table,
  InputGroup,
  InputGroupAddon,
  Card, CardBody, Modal, ModalBody
} from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { AsyncTypeahead } from 'react-bootstrap-typeahead';
// import { debounce } from 'throttle-debounce';
import Ionicon from "react-ionicons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import Autosuggest from 'react-autosuggest';
import lodash from 'lodash'; // library added for debounce functionality
import './CustomerIntraction.css';
import Validation from '../../services/Validation';
import CustomerIntractionDetail from '../../components/CustomerIntractionDetail/CustomerIntractionDetail';
import UserService from '../../services/UserService';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import Moment from 'react-moment';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import Pagination from '../../components/Pagination/Pagination';
import Storage from '../../services/Storage';
import ProposalSerivice from '../../services/ProposalSerivice';
import swal from 'sweetalert';
import { downlodFile } from '../../utils/utils';
import ModalService from '../../services/ModalService';
import { Roles } from '../../constants/constant.role';


class CustomerIntraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      isDisplayCustomerIntractionDetail: false,
      suggestions: [],
      cipDetail: [],
      value: '',
      controls: {
        dealer: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        region: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        area: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        dealer_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        dealer_sap_id: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        location: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        cip_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        no_of_custpmer_invite: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        cip_objective: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        expenses: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
      },
      regionData: [],
      areaData: [],
      cipSummary: [],
      isFormValid: false,
      isLoading: false,
      isDisplayCIPSummary: true,
      cip_id: null,
      cip_uuid: null,
      cip_name: null,
      cip_venue: null,
      cip_date: null,
      summaryPage: 1,
      totalSummaryPages: 0,
      totalSummary: 0,
      customerPage: 1,
      totalCustomerPages: 0,
      totalCustomer: 0,
      isOpenExpensesModal: false,
      expensesDeatil: {}
    }
    this.searchReport = lodash.debounce(this.searchReport, 500);
  }

  componentDidMount() {
    this.getCip();
    this.getRegion();
  }
  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { location, cip_date, no_of_custpmer_invite, cip_objective, dealer,
      region, area } = controls;

    if (dealer.touched === true || isSubmit) {
      if (dealer.value && dealer.value.length > 0) {
        dealer.valid = true;
        dealer.showErrorMsg = false;
      } else {
        dealer.showErrorMsg = true;
        dealer.valid = false;
      }
    }

    if (location.touched === true || isSubmit) {
      location = Validation.notNullValidator(location);
      location.valid = !(location.nullValue);
      if (location.valid === false) {
        location.showErrorMsg = true;
      } else {
        location.showErrorMsg = false;
      }
    }

    if (cip_date.touched === true || isSubmit) {
      cip_date = Validation.notNullValidator(cip_date);
      cip_date.valid = !(cip_date.nullValue);
      if (cip_date.valid === false) {
        cip_date.showErrorMsg = true;
      } else {
        cip_date.showErrorMsg = false;
      }
    }

    region.valid = true;
    // region.showErrorMsg = false;
    // if ((region.touched === true || isSubmit) && this.state.userDetail.type === Roles.SALES_OFFICER_ROLE) {
    //   region = Validation.notNullValidator(region);
    //   region.valid = !(region.nullValue);
    //   if (region.valid === false) {
    //     region.showErrorMsg = true;
    //   } else {
    //     region.showErrorMsg = false;
    //   }
    // }

    area.valid = true;
    // area.showErrorMsg = false;
    // if ((area.touched === true || isSubmit) && this.state.userDetail.type === Roles.SALES_OFFICER_ROLE) {
    //   area = Validation.notNullValidator(area);
    //   area.valid = !(area.nullValue);
    //   if (area.valid === false) {
    //     area.showErrorMsg = true;
    //   } else {
    //     area.showErrorMsg = false;
    //   }
    // }

    if (no_of_custpmer_invite.touched === true || isSubmit) {
      no_of_custpmer_invite = Validation.notNullValidator(no_of_custpmer_invite);
      no_of_custpmer_invite.valid = !(no_of_custpmer_invite.nullValue);
      if (no_of_custpmer_invite.valid === false) {
        no_of_custpmer_invite.showErrorMsg = true;
      } else {
        no_of_custpmer_invite.showErrorMsg = false;
      }
    }

    if (cip_objective.touched === true || isSubmit) {
      cip_objective = Validation.notNullValidator(cip_objective);
      cip_objective.valid = !(cip_objective.nullValue);
      if (cip_objective.valid === false) {
        cip_objective.showErrorMsg = true;
      } else {
        cip_objective.showErrorMsg = false;
      }
    }
    if (location.valid === true &&
      dealer.valid === true &&
      cip_objective.valid === true &&
      cip_date.valid === true &&
      region.valid === true &&
      area.valid === true &&
      no_of_custpmer_invite.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  }

  handleInputChange = async (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;

    if (controlName === 'no_of_custpmer_invite') {
      if (isNaN(controlValue)) {
        return
      }
    }
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    await this.setState({ controls });
    if (controlName === 'region') {
      this.getArea();
    }
    this.handleValidation();
  }

  getAllDealerReport = (page, search) => {
    // this.setState({
    //   isLoading: true
    // });
    UserService.searchDealer(search)
      .then(data => {
        const { data: dealers } = data.data;
        let { suggestions } = this.state;
        if (dealers) {
          suggestions = dealers;
        } else {
          suggestions = [];
        }
        this.setState({ suggestions, isLoading: false });
      })
      .catch(e => {
        this.setState({
          isLoading: false
        });
      })
  }


  searchReport = (search) => {
    this.getAllDealerReport(1, search.value, true)
  }

  getSuggestionValue = (suggestion) => {
    let { controls } = this.state;
    controls.dealer.value = [suggestion];
    controls.dealer.touched = true;
    this.setState({
      controls
    })
    this.handleValidation();
    return suggestion.business_name;
  }

  renderSuggestion = (suggestion) => {
    return (
      <span>{suggestion.business_name}{' '}({suggestion.sap_id})</span>
    );
  }

  onChange = (event, { newValue, method }) => {
    let { controls
      // , value
     } = this.state;
    if (!newValue) {
      controls.dealer.value = [];
    }
    this.setState({
      value: newValue,
      controls
    });
  };

  onSuggestionsClearRequested = () => {
    let { controls } = this.state;
    this.setState({
      suggestions: [],
      controls
    });
  };

  getCip = () => {
    this.setState({ isLoading: true });
    CustomerIntractionServie.getCip({ page: this.state.summaryPage, pagesize: 10 })
      .then(data => {
        let { cipSummary } = this.state;
        cipSummary = data.data.data.data;
        let totalSummary = data.data.data.total;
        const totalSummaryPages = Math.ceil(totalSummary / 10);
        this.setState({
          cipSummary,
          isLoading: false,
          isDisplayCIPSummary: true,
          totalSummaryPages,
          totalSummary
        })
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  cipDetailById = async (uuid = null) => {
    await this.setState({
      cip_uuid: uuid,
      isDisplayCustomerIntractionDetail: true
    })
  }

  getCipDetail = (cip_id, cip_name, cip_venue, cip_date) => {
    this.setState({ isLoading: true, cip_id: cip_id, cip_name: cip_name, cip_venue: cip_venue, cip_date: cip_date });
    if (!cip_id) {
      return
    }
    CustomerIntractionServie.getCipDetail({ cip_id: cip_id, page: this.state.customerPage, pagesize: 10 })
      .then(data => {
        let { cipDetail } = this.state;
        cipDetail = data.data.data.data;
        let totalCustomer = data.data.data.total;
        const totalCustomerPages = Math.ceil(totalCustomer / 10);
        this.setState({
          cipDetail,
          isLoading: false,
          isDisplayCIPSummary: false,
          totalCustomerPages,
          totalCustomer
        })
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  addCIP = (flag = false) => {
    if (flag) {
      this.setState({
        isDisplayCustomerIntractionDetail: true,
        cip_uuid: null,
        isLoading: false
      })
      return
    }
    if (!this.handleValidation(true)) {
      return true;
    }
    let { controls } = this.state;
    let reqData = {
      dealer_name: controls.dealer.value[0].business_name,
      dealer_sap_id: controls.dealer.value[0].sap_id,
      cip_date: controls.cip_date.value,
      cip_objective: controls.cip_objective.value,
      location: controls.location.value,
      no_of_customer_invite: controls.no_of_custpmer_invite.value,
    }
    if (this.state.userDetail.type === Roles.SALES_OFFICER_ROLE) {
      reqData.area_id = +controls.area.value;
    } else {
      reqData.area_id = this.state.userDetail.id;
    }
    this.setState({ isLoading: true });
    CustomerIntractionServie.addCip(reqData)
      .then(data => {
        if (data.data.data && Object.keys(data.data.data).length > 0) {
          this.setState({
            isDisplayCustomerIntractionDetail: true,
            cip_id: data.data.data.id,
            cip_uuid: null,
            isLoading: false
          })
        } else {
          this.setState({
            isDisplayCustomerIntractionDetail: false,
            isLoading: false
          })
        }
      })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : '';
        ModalService.openAlert('', message, 'error');
        this.setState({ isLoading: false });
      });
  }


  getStripedStyle(index) {
    return { background: index % 2 === 0 ? '#f6f6f6' : 'white' };
  }

  endUpRegistration = (isBack = false) => {
    let { controls, isDisplayCIPSummary, cip_uuid, cip_id, cip_name, cip_venue, cip_date } = this.state;
    controls.cip_date.value = null;
    controls.cip_date.showErrorMsg = false;
    controls.dealer.value = [];
    controls.dealer.showErrorMsg = false;
    controls.dealer_name.value = '';
    controls.dealer_name.showErrorMsg = false;
    controls.dealer_sap_id.value = '';
    controls.dealer_sap_id.showErrorMsg = false;
    controls.location.value = '';
    controls.location.showErrorMsg = false;
    controls.cip_objective.value = '';
    controls.cip_objective.showErrorMsg = false;
    controls.no_of_custpmer_invite.value = '';
    controls.no_of_custpmer_invite.showErrorMsg = false;
    controls.region.value = '';
    controls.region.showErrorMsg = false;
    controls.area.value = '';
    controls.area.showErrorMsg = false;
    if (isBack) {
      isDisplayCIPSummary = false;
    } else {
      cip_uuid = null;
      cip_id = null;
    }
    this.setState({
      isDisplayCustomerIntractionDetail: false,
      isDisplayCIPSummary,
      cip_uuid,
      cip_id,
      controls,
      value: ''
    })
    if (isBack) {
      this.getCipDetail(cip_id, cip_name, cip_venue, cip_date);
    } else {
      this.getCip();
    }
  }

  backToCipSummary = () => {
    this.setState({
      isDisplayCIPSummary: true,
      cip_id: null,
      cip_uuid: null
    })
  }

  handlePageChange = async (page) => {
    await this.setState({ summaryPage: page });
    this.getCip();
  }

  handlePageChangeCustomer = async (page, cip_id, cip_name, cip_venue, cip_date) => {
    await this.setState({ customerPage: page });
    this.getCipDetail(cip_id, cip_name, cip_venue, cip_date);
  }

  getRegion() {
    ProposalSerivice.getRegion()
      .then(res => {
        if (res && res.data && res.data.data && res.data.data.length >= 0) {
          this.setState({
            regionData: res.data.data,
          })
        }
      })
      .catch(e => {

        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  handleValidationExpenses = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { expenses } = controls;
    if (expenses.touched === true || isSubmit) {
      expenses = Validation.notNullValidator(expenses);
      expenses.valid = !(expenses.nullValue);
      if (expenses.valid === false) {
        expenses.showErrorMsg = true;
      } else {
        expenses.showErrorMsg = false;
      }
    }

    if (expenses.valid) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls });
    return isFormValid;
  }

  submitExpensincurred = () => {
    if (!this.handleValidationExpenses(true)) {
      return
    }
    swal({
      title: "",
      text: "Are you sure,Expenses incurred amount is right ?",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.sendBillSettlement(this.state.expensesDeatil.cip_id, this.state.controls.expenses.value);
        this.toggleBillSettlement('');
      }
    });
  }

  getArea() {
    let { controls } = this.state;
    this.setState({ isLoading: true });
    ProposalSerivice.getArea(controls.region.value)
      .then(res => {
        let { areaData } = this.state;
        if (res && res.data && res.data.data && res.data.data.length >= 0) {
          areaData = res.data.data;
        } else {
          areaData = [];
        }
        controls.area.value = '';
        this.setState((prevState) => {
          return { ...prevState, areaData, isLoading: false, controls };
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  sendBillSettlement = (id, expenses = '') => {
    this.setState({ isLoading: true });
    let reqData = { cip_id: id };
    if (expenses) {
      reqData.expenses = expenses;
    }
    CustomerIntractionServie.sendBillSettlment(reqData)
      .then(res => {
        swal({
          title: '',
          text: res.data.message,
          icon: 'success',
          type: 'success',
        }).then(value => {
        });
        this.setState({ isLoading: false });
        this.getCip();
      })
      .catch(e => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  downloadSummary = (id) => {
    this.setState({ isLoading: true });
    CustomerIntractionServie.downloadSummary({ cip_id: id })
      .then(res => {
        const { file } = res.data.data;
        downlodFile(file);
        this.setState({ isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  onChangeAmount = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;

    if (controlName === 'expenses') {
      if (isNaN(controlValue)) {
        return
      }
    }
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidationExpenses(false);
  }

  toggleBillSettlement = (cip_id, is_bill_settlement) => {
    if (is_bill_settlement) {
      this.sendBillSettlement(cip_id, null);
      return;
    }
    let { expensesDeatil, isOpenExpensesModal, controls } = this.state;
    if (cip_id) {
      expensesDeatil = {
        cip_id: cip_id
      }
    } else {
      expensesDeatil = {};
      controls.expenses.value = '';
      controls.expenses.showErrorMsg = false;
    }
    this.setState({
      expensesDeatil,
      controls,
      isOpenExpensesModal: !isOpenExpensesModal
    })
  }

  render() {
    let { isDisplayCustomerIntractionDetail, controls, suggestions, value,
      totalSummaryPages, totalSummary, totalCustomer, totalCustomerPages } = this.state;
    const inputProps = {
      placeholder: "Search using dealer name or SAP code",
      value,
      onChange: this.onChange
    };

    return (
      <div id="customer-intraction">
        {this.state.isLoading && <CustomSpinner />}
        <div className="page-header">Customer Interaction Program</div>
        {
          !isDisplayCustomerIntractionDetail &&
          <Row>
            <Col xl="9" md="12" xs="12">
              <Card style={{ marginTop: '25px' }}>
                <CardBody>
                  <Row>
                    <Col>
                      <b className="margin-right-16">CIP DETAIL</b>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="owner_name">Dealer Name<span className="required-field">*</span></Label>
                        <div>
                          <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.searchReport}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={inputProps}
                          />
                          <img src="images/search.svg" className="report-search icon-width" style={{ marginTop: '31px', right: '18px' }} alt="" />
                        </div>
                        {/* <div className="d-flex-row vehical-sku-id-search">
                          <AsyncTypeahead
                            style={{ width: '100%' }}
                            id="sap_id"
                            isLoading={this.state.isLoading}
                            filterBy={['sap_id', 'business_name']}
                            labelKey={(option) => `${option.business_name}`}
                            options={suggestions}
                            isLoading={this.state.isLoading}
                            // labelKey="sku_id"
                            minLength={0}
                            onChange={(e) => { this.getSuggestionValue(e); }}
                            bsSize="small"
                            name="skuId1"
                            onSearch={debounce(200, this.searchReport)}
                            placeholder="Search..."
                            useCache={false}
                            emptyLabel="No Dealer Found"
                            renderMenuItemChildren={(selectedItem) => {
                              return <>{selectedItem.business_name} - {selectedItem.sap_id}</>;
                            }}
                            selected={controls.dealer.value ? controls.dealer.value : []}
                          />

                          <FontAwesomeIcon className="serach-icon" icon={faSearch} color="#6e777f" />
                        </div> */}
                        {controls.dealer.showErrorMsg && (
                          <div className="error">
                            * Please select dealer.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label for="common">Date<span className="required-field">*</span></Label>
                        <Label
                          onClick={e =>
                            this.calendar1.state.open && e.preventDefault()
                          }
                        >
                          <InputGroup>
                            <DatePicker
                              className={"form-control"}
                              onChange={e => this.handleInputChange({
                                target: {
                                  name: 'cip_date',
                                  value: e
                                }
                              })}
                              selected={controls.cip_date.value}
                              name="birth_date"
                              dateFormat="dd/MM/yyyy"
                              showMonthDropdown
                              ref={r => (this.calendar1 = r)}
                              shouldCloseOnSelect={true}
                              showYearDropdown
                              //  shouldCloseOnSelect
                              maxDate={new Date()}
                              popperPlacement="bottom"
                              placeholderText='Select Date'
                              popperModifiers={{
                                flip: {
                                  enabled: false
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false
                                }
                              }}
                              autoComplete="nofill"
                              dropdownMode="select"
                            />
                            <InputGroupAddon addonType="append">
                              <div className="input-group-text">
                                <FontAwesomeIcon
                                  htmlFor="datepicker"
                                  icon={faCalendarAlt}
                                />
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                        </Label>
                        {controls.cip_date.showErrorMsg && (
                          <div className="error">
                            * Please select date.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="location">CIP Venue<span className="required-field">*</span></Label>
                        <Input
                          type="text"
                          name="location"
                          id="location"
                          value={controls.location.value}
                          onChange={(e) => this.handleInputChange(e)}
                          autocomplete="nofill"
                          placeholder="Enter Location" />
                        {controls.location.showErrorMsg && (
                          <div className="error">
                            * Please enter location.
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col xl="8" md="12" xs="12">
                      <FormGroup>
                        <Label for="common">CIP Objective<span className="required-field">*</span></Label>
                        <textarea
                          value={controls.cip_objective.value}
                          onChange={(e) => this.handleInputChange(e)}
                          name="cip_objective"
                          placeholder="Enter CIP Objective"
                          className="form-control"></textarea>
                        {controls.cip_objective.showErrorMsg && (
                          <div className="error">
                            * Please enter cip objective.
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="common">No Of Customer Invited<span className="required-field">*</span></Label>
                        <Input
                          type="text"
                          autoComplete="nofill"
                          name="no_of_custpmer_invite"
                          id="no_of_invite"
                          value={controls.no_of_custpmer_invite.value}
                          onChange={(e) => this.handleInputChange(e)}
                          placeholder="Enter No Of Customer Invited"
                        />
                        {controls.no_of_custpmer_invite.showErrorMsg && (
                          <div className="error">
                            * Please enter no of customer invited.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    {
                      <Col className="text-align-right" style={{ marginTop: '20px' }}> <Button
                        style={{ width: '200px' }}
                        className="mb-1 mr-1 btn-pill action-button font-weight-bold" color="warning" onClick={() => { this.addCIP(); }}>START CIP REGISTRATION</Button></Col>
                    }
                  </Row>



                </CardBody>
              </Card>
            </Col>
            {
              this.state.isDisplayCIPSummary ?
                <Col xl="9" md="12" xs="12" className="mt-4">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <b className="margin-right-16">CIP SUMMARY</b>
                        </Col>
                      </Row>
                      <hr />
                      <Table className="mb-0 table-responsive" bordered style={{ marginTop: 0 }}>
                        <thead>
                          <tr style={{ ...this.getStripedStyle(0) }}>
                            <th>Dealer Name</th>
                            <th>Date</th>
                            <th>CIP Venue</th>
                            <th className="min-width-245px">CIP Objective</th>
                            <th>No Of Invite</th>
                            <th>No Of Attendee</th>
                            <th>Expenses</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (this.state.cipSummary || []).map((item, i) => (
                              <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                                <td>{item.dealer_name}</td>
                                <td>
                                  <Moment format="DD MMM YYYY">
                                    {item.cip_date}
                                  </Moment></td>
                                <td>{item.location}</td>
                                <td>{item.cip_objective}</td>
                                <td className="text-center">{item.no_of_customer_invite}</td>
                                <td onClick={() => this.getCipDetail(item.id, item.dealer_name, item.location, item.cip_date)} className='link-blue text-center'
                                  style={{ fontSize: '13px' }}>{item.total_atendee ? item.total_atendee : 0}</td>
                                <td className="text-center">
                                  {
                                    item.expenses ? <span>{item.expenses}</span> :
                                      <span className="no-data-hypen ml-auto mr-auto"></span>
                                  }
                                </td>
                                <td>
                                  {
                                    item.total_atendee && item.total_atendee > 0 ?
                                      <>
                                        {item.is_bill_settlement ?
                                          <span className="link-blue" onClick={() => this.toggleBillSettlement(item.id, item.is_bill_settlement)}>Re-Send Summary For Bill Settlement</span> :
                                          <span className="link-blue" onClick={() => this.toggleBillSettlement(item.id, item.is_bill_settlement)}>Send Summary For Bill Settlement</span>
                                        }
                                      </> : <span className="no-data-hypen ml-auto mr-auto"></span>
                                  }
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                      {
                        (this.state.cipSummary || []).length <= 0 &&
                        <div className="no-data">
                          Currently, there is no data to display
                        </div>
                      }
                      {totalSummary > 10 && <Pagination
                        margin={2}
                        page={this.state.summaryPage}
                        count={totalSummaryPages}
                        pageSize={10}
                        totalRecords={totalSummary}
                        onPageChange={this.handlePageChange}
                      ></Pagination>}
                    </CardBody>
                  </Card>
                </Col> :
                <Col xl="9" md="12" xs="12" className="mt-4">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <Row>
                            <Col xl="6" md="12" xs="12" className="d-flex">
                              {/* <b className="margin-right-16 mt-auto mb-auto">CIP Registration List</b> */}
                              <span className="prev-page-link cursor-pointer mt-auto mb-auto back-cip-summary-link" onClick={() => this.backToCipSummary()}>CIP SUMMARY</span>
                              <span className="mt-auto mb-auto">
                                <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
                              </span>
                              <span className="prev-page-link mt-auto mb-auto cip-summary-dealer-name" style={{ color: '#000' }}>{this.state.cip_name ? this.state.cip_name : ''}</span>
                            </Col>
                            <Col xl="6" md="12" xs="12" className="text-align-right back-wrapp-cip-inst">
                              <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark"
                                onClick={() => this.backToCipSummary()}
                                style={{ width: '100px' }}>Back</Button>
                              &nbsp;&nbsp;&nbsp;
                              <Button
                                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                                color="warning"
                                type="submit"
                                style={{ width: '100px' }}
                                onClick={(e) => this.addCIP(true)}
                              >ADD</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col className="d-flex-row" style={{ justifyContent: 'space-between' }}>
                          <div className="d-flex-row">
                            {
                              this.state.cip_date &&
                              <div>
                                <Label className="font-weight-bold" for="owner_name">CIP Date :</Label>
                                &nbsp;
                                <span> <Moment format="DD MMM YYYY">
                                  {this.state.cip_date}
                                </Moment></span>
                              </div>
                            }
                            {
                              this.state.cip_venue && <div>&nbsp;&nbsp;
                                <Label className="font-weight-bold" for="owner_name">CIP Venue :</Label>
                                &nbsp;
                                <span>{this.state.cip_venue}</span>
                              </div>
                            }

                          </div>
                          {
                            this.state.cipDetail && this.state.cipDetail.length > 0 &&
                            <div>
                              <img
                                src="images/download.svg"
                                alt=""
                                className="download-report-icon cursor-pointer margin-left-10"
                                onClick={() => this.downloadSummary(this.state.cip_id)}
                              />&nbsp;
                              <span className="link-blue" onClick={() => this.downloadSummary(this.state.cip_id)}>Download Summary</span>
                            </div>
                          }
                        </Col>
                      </Row>
                      <br />
                      <Table className="mb-0 table-responsive" bordered style={{ marginTop: 0 }}>
                        <thead>
                          <tr style={{ ...this.getStripedStyle(0) }}>
                            <th>Company Name</th>
                            <th>Attendee Name</th>
                            <th>Designation</th>
                            <th>Mobile Number</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (this.state.cipDetail || []).map((item, i) => (
                              <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                                <td onClick={() => this.cipDetailById(item.uuid)}
                                  className='link-blue'>{item.company_name ? item.company_name : ''}</td>
                                <td>
                                  {item.attendee_name}
                                </td>
                                <td>{item.designation}</td>
                                <td>{item.mobile_number}</td>
                                <td>
                                  <img onClick={() => this.cipDetailById(item.uuid)} style={{ height: '15px', marginTop: '-10px' }} className="cursor-pointer ml-auto" src="images/edit.svg" alt="" />
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                      {
                        (this.state.cipDetail || []).length <= 0 &&
                        <div className="no-data">
                          Currently, there is no data to display
                        </div>
                      }
                      {totalCustomer > 10 && <Pagination
                        margin={2}
                        page={this.state.customerPage}
                        count={totalCustomerPages}
                        pageSize={10}
                        totalRecords={totalCustomer}
                        onPageChange={(page) => this.handlePageChangeCustomer(page, this.state.cip_id, this.state.cip_name, this.state.cip_venue, this, this.state.cip_date)}
                      ></Pagination>}

                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>
        }


        {
          isDisplayCustomerIntractionDetail &&
          <>
            <div style={{ marginTop: '25px' }}>
              <CustomerIntractionDetail
                cip_uuid={this.state.cip_uuid}
                cip_id={this.state.cip_id}
                endUpRegistration={this.endUpRegistration} />
            </div>

          </>
        }
        <Modal isOpen={this.state.isOpenExpensesModal} className="modal-md modal-lg custom-confirm-modal modal-sm" style={{ maxWidth: '370px' }} backdrop={this.backdrop ? 'static' : 'nostatic'}>
          <div className="d-flex-column">
            <div className="confirm-modal-title-wrapp">
              <span className="confirm-modal-title">Expenses Incurred</span>
              <FontAwesomeIcon className="pull-right cursor-pointer" onClick={() => this.toggleBillSettlement('')} icon={faTimes} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
            </div>
          </div>
          <ModalBody classNam="d-flex-column">
            <Row>
              <Col>
                <Input
                  type="text"
                  autocomplete="nofill"
                  name="expenses"
                  value={controls.expenses.value}
                  onChange={(e) => this.onChangeAmount(e)}
                  placeholder="Enter expenses incurred amount" />
                {controls.expenses.showErrorMsg && (
                  <div className="error">
                    * Please enter expenses incurred amount
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
          <Col xl="12" xs="12" md="12" className="text-align-right mt-3 mb-3" >
            <Button className="mb-1 mr-1 btn-pill action-button profile-save-button" color="warning"
              onClick={() => this.submitExpensincurred()}>Submit</Button>
          </Col>
        </Modal>
      </div >
    );
  }
}

export default CustomerIntraction;