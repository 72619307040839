import { request } from './Request';
import { routes } from '../constants/constant.route';

const API_URL = process.env.REACT_APP_API_URL;

export default class MerchandiseWalletService {

  merchandiseWalletState = {};

  static getItems() {
    return request('GET', `${API_URL}${routes.MERCHANDISE_WALLET.GET_ITEMS}`, null, null, null);
  }

  static getDashboardCounts() {
    return request('GET', `${API_URL}${routes.MERCHANDISE_WALLET.GET_DASHOBARD_COUNTS}`, null, null, null);
  }

  static getAddresses() {
    return request('GET', `${API_URL}${routes.MERCHANDISE_WALLET.GET_ADDRESS}`, null, null, null);
  }

  static getState() {
    return request('GET', `${API_URL}${routes.WARRANTY_REGISTRATION.GETSTATE}`, null, null, null);
  }

  static getCity(statId) {
    return request('GET', `${API_URL}${routes.WARRANTY_REGISTRATION.GETCITY}?state_id=${statId}`, null, null, null);
  }

  static getOrders() {
    return request('GET', `${API_URL}${routes.MERCHANDISE_WALLET.GET_ORDERS}`, null, null, null);
  }

  static placeOrder(data) {
    return request('POST', `${API_URL}${routes.MERCHANDISE_WALLET.PLACE_ORDER}`, null, data, null);
  }

  static sendOTP(data) {
    return request('POST', `${API_URL}${routes.MERCHANDISE_WALLET.SEND_OTP_REDEMPTION}`, null, data, null);
  }

  static verifyOtp(data) {
    return request('POST', `${API_URL}${routes.MERCHANDISE_WALLET.VERIFY_OTP_REDEMPTION}`, null, data, null);
  }

  static setMerchandiseWalletState(state) {
    if (state && Object.keys(state).length > 0) {
      this.merchandiseWalletState = state;
    }
  }

  static getMerchandiseWalletState() {
    if (this.merchandiseWalletState && Object.keys(this.merchandiseWalletState).length > 0) {
      return this.merchandiseWalletState;
    }
    return {};
  }
}