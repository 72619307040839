import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './CustomerRating.css';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';
import CustomerRatingService from '../../services/CustomerRatingService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
export default class CustomerRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display_page: 'CustomerRating',
      dealer_detail: {
        dealer: {},
        customer: {},
        customerHistory: {},
        code: ''
      },
      is_valid_dealer_code: true
    };
    // const query = new URLSearchParams(this.props.location.search);
    if (this.props.match.params.token) {
      this.state.code = this.props.match.params.token;
    }
  }


  componentDidMount() {
    GoogleAnalticsService.pageView('GMB Ratting Link View');
    if (this.props.match.params.token) {
      this.getDealerInfo();
    }
  }

  getDealerInfo = async () => {
    this.setState({
      isLoading: true
    });
    await CustomerRatingService.getDealerInfo({
      "code": this.props.match.params.token
    }).then(res => {
      let { dealer_detail } = this.state;
      if (res.data.data) {
        let address_data = "";
        if (res.data.data.dealer.address_line_1) {
          address_data = `${res.data.data.dealer.address_line_1}`;
        }
        if (res.data.data.dealer.address_line_2) {
          address_data = `${address_data}, ${res.data.data.dealer.address_line_2}`;
        }
        if (res.data.data.dealer.address_line_3) {
          address_data = `${address_data}, ${res.data.data.dealer.address_line_3}`;
        }
        if (res.data.data.dealer.address_line_4) {
          address_data = `${address_data}, ${res.data.data.dealer.address_line_4}`;
        }
        address_data = `${address_data}, ${res.data.data.dealer.city} | ${res.data.data.dealer.telephone_no_1}`;
        res.data.data.dealer.address_data = address_data;
        dealer_detail = res.data.data;
        if (res.data.data.dealer.google_my_business_rating_link && res.data.data.dealer.google_my_business_rating_link.length > 0) {
          window.location = res.data.data.dealer.google_my_business_rating_link;
        }
      }
      this.setState({
        dealer_detail,
        isLoading: false,
        is_valid_dealer_code: true
      });
    })
      .catch(e => {
        console.log("err", e);
        this.setState({
          isLoading: false,
          is_valid_dealer_code: false
        });
      });
  }

  isLoading = (flag) => {
    this.setState({
      isLoading: flag
    })
  }

  render() {
    let { dealer_detail } = this.state;
    return (
      <div id="CustomerRating">
        {this.state.isLoading && <CustomSpinner />}
        <Row className="m-0">
          <Col xl="12" xs="12" md="12" className="d-flex-column mt-5 text-align-center">
            <img src="images/logo.png" alt="" className="logo ml-auto mr-auto" />
          </Col>
          {
            dealer_detail.dealer && Object.keys(dealer_detail.dealer).length > 0 && dealer_detail.dealer.google_my_business_rating_link.length === 0 &&
            <Col xl="12" md="12" xs="12" className="d-flex-column ml-auto mr-auto justify-content-center mt-4 mb-4">
              <Row>
                <Col xl="5" md="12" xs="12" className="customer-refence-detail-col ml-auto mr-auto justify-content-center d-flex-column">
                  <span className="store-name" style={{ color: 'red', opacity: '1', textAlign: 'center' }}>Rating link not found</span>
                </Col>
              </Row>
            </Col>
          }
          {
            dealer_detail.dealer && Object.keys(dealer_detail.dealer).length > 0 &&
            <Col xl="12" md="12" xs="12" className="d-flex-column ml-auto mr-auto justify-content-center mt-4 mb-4">
              <Row>
                <Col xl="12" md="12" xs="12" className="customer-refence-detail-col ml-auto mr-auto justify-content-center d-flex-column"
                  style={{ textAlign: 'center' }}>
                  <span className="store-name">{dealer_detail.dealer.business_name}</span>
                  <span className="mt-1 store-address">
                    {dealer_detail.dealer.address_data}</span>
                </Col>
              </Row>
            </Col>
          }
        </Row>
      </div>
    )
  }
}
