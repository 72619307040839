import React, { Component } from "react";
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap";
import swal from "sweetalert";
import lodash from "lodash";

import Pagination from "../Pagination/Pagination";
import Validation from "../../services/Validation";
import CustomSpinner from "../CustomSpinner/CustomSpinner";

import CustomerManagementService from "../../services/CustomerManagementService";
import ModalService from "../../services/ModalService";

import "./AddNewGroup.css";

import { messages } from "../../constants/constant.message";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";

const { ERROR_MESSAGE } = messages.COMMON;

class AddNewGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: null,
      GroupManagementFilterStates: props.filter ? props.filter : null,
      filterControls: props.groupData ? props.groupData : {},
      from: props.from ? props.from : "add-to-group",
      groupCustomerPage: 1,
      pageSize: 10,
      controls: {
        group_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        search_customer_from_all: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      },
      sidebarOpenGroupFilter: false,
      selected: [],
      selectAll: 0,
      search_query: "",
      page: 1,
      allCustomers: [],
      isLoading: false,
      groupCustomersCount: 0,
      groupCustomersTotalPages: 0,
      allCustomerPage: 1,
      filterApplied: false,
      isSelectAllEntry: false,
      customerManagementFilterStates: null,
    };
    this.getCustomers = lodash.debounce(this.getCustomers, 500);
    // this.childAddNewVehicle = React.createRef();
  }

  componentDidMount() {
    GoogleAnalticsService.event('Manage Group', 'Select Customer Click');
    // const { isEdit = false } = this.props;
    if (this.props.filter) {
      const { filter } = this.props;
      // const { selectedCustomers } = this.props;
      const { controls } = this.state;
      const { group_name } = controls;
      group_name.value = filter.group_name;
      this.setState({ controls });
    }
    this.getCustomers();
  }

  componentWillReceiveProps(props) {
    // when filter is applied. -> apply again -> close filter
    // Nothing to be change    
    if (props.filter && !Object.is(props.filter, this.state.GroupManagementFilterStates)) {
      this.setState({
        GroupManagementFilterStates: props.filter,
        groupCustomerPage: 1,
        filterApplied: true,
        selected: [],
        selectAll: 0,
        allCustomers: [],
        groupCustomersCount: 0,
        pageSize: 10,
        groupCustomersTotalPages: 0,
        isSelectAllEntry: false,
        isSelectAllCustomerToAddChecked: false
      }, () => {
        this.getCustomers();
      });
    } else if (this.state.GroupManagementFilterStates && !props.filter) {
      // when filter is reset
      this.setState({
        GroupManagementFilterStates: null,
        groupCustomerPage: 1,
        filterApplied: false,
        selected: [],
        selectAll: 0,
        allCustomers: [],
        groupCustomersCount: 0,
        pageSize: 10,
        groupCustomersTotalPages: 0,
        isSelectAllEntry: false,
        isSelectAllCustomerToAddChecked: false
      }, () => {
        this.getCustomers();
      });
    }
  }


  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { group_name } = controls;
    if (group_name.touched === true || isSubmit) {
      group_name = Validation.notNullValidator(group_name);
      group_name.valid = !group_name.nullValue;
      if (group_name.valid === false) {
        group_name.showErrorMsg = true;
      } else {
        group_name.showErrorMsg = false;
      }
    }
    if (group_name.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  handleOnCancel = () => {
    this.props.onCloseSidebar(false);
  };

  addNewGroup = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    GoogleAnalticsService.event('Create Group', 'Add Member in Customer View');
    const { controls } = this.state;
    this.setState({ isLoading: true });
    // return;
    if (this.state.selected.length !== 0) {
      let reqData = {};
      if (this.state.GroupManagementFilterStates && this.state.GroupManagementFilterStates.filter) {
        /**GroupManagementFilterStates is data from the filter which is set to reqData with the help of below function*/
        reqData = this.getFilterReqData(this.state.GroupManagementFilterStates.filter);
      }
      reqData.group_name = controls.group_name.value
      if (this.state.selected && this.state.selected.length > 0) {
        reqData.customers = this.state.selected;
      }
      if (this.state.selectAll === 1 && this.state.isSelectAllEntry) {
        reqData.customers = [];
      }
      reqData.search_query = this.state.search_query;
      CustomerManagementService.addToGroup(reqData)
        .then(res => {
          // this.isDataLoading(false);
          this.setState({ isLoading: false });
          if (this.state.from === "add-to-group") {
            this.props.createGroup(false, controls.group_name.value);
          } else if (res.data.message) {
            swal({
              title: "Congratulations !",
              text: "Group added successfully.",
              icon: "success",
              type: "success"
            }).then(value => {
              this.props.onCloseSidebar(true);
            });
          }
        })
        .catch(e => {
          this.setState({ isLoading: false });
          const err =
            e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error[0]
              ? e.response.data.error[0]
              : null;
          const message = err && err.message ? err.message : ERROR_MESSAGE;
          ModalService.openAlert("", message, "error");
          // this.setState({ isLoading: false });
        });
    } else {
      const reqData = {
        name: controls.group_name.value,
        search_query: this.state.search_query
      };
      CustomerManagementService.addGroup(reqData)
        .then(res => {
          // this.isDataLoading(false);
          this.setState({ isLoading: false });
          if (this.state.from === "add-to-group") {
            this.props.createGroup(false, controls.group_name.value);
          } else if (res.data.message) {
            swal({
              title: "Congratulations !",
              text: res.data.message,
              icon: "success",
              type: "success"
            }).then(value => {
              this.props.onCloseSidebar(true);
            });
          }
        })
        .catch(e => {
          this.setState({ isLoading: false });
          const err =
            e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error[0]
              ? e.response.data.error[0]
              : null;
          const message = err && err.message ? err.message : ERROR_MESSAGE;
          ModalService.openAlert("", message, "error");
          // this.setState({ isLoading: false });
        });
    }
  };

  editGroup = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    GoogleAnalticsService.event('Create Group', 'Edit Member in Customer View');
    const { controls } = this.state;
    const { filter } = this.props;

    const reqData = {
      name: controls.group_name.value,
      group_id: filter.group_id,
      search_query: this.state.search_query
    };
    this.setState({ isLoading: true });

    CustomerManagementService.editGroup(reqData)
      .then(res => {
        // this.isDataLoading(false);
        // this.props.reloadGroups();
        this.setState({ isLoading: false });
        if (res.data.message) {
          swal({
            title: "Congratulations !",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => {
            this.props.onCloseSidebar(true);
          });
        }
      })
      .catch(e => {
        // this.isDataLoading(false);
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        // this.setState({ isLoading: false });
      });
  };

  goBack = () => {
    this.props.createGroup(false);
  };

  // ============================================ New Task Begain ====================================

  getFilterReqData = filter => {
    /**If their is not filter value then return empty object */
    if (filter && Object.keys(filter).length <= 0) {
      return {};
    }
    /**Creating the object with specific key/value pair to send as body in request to get specific group */
    let reqData = {};
    if (filter && Object.keys(filter).length > 0) {
      if (filter.from_date && filter.from_date) {
        /**Converting the fromdate to the required format for eg 2021-02-02T00:00:00.000Z */
        let from_date = new Date(filter.from_date);
        from_date.setHours(0);
        from_date.setMinutes(0);
        reqData.from_date = from_date;
      }
      if (filter.to_date && filter.to_date) {
        /**Converting the todate to the required format for eg 2021-02-12T23:59:59.059Z */
        let to_date = new Date(filter.to_date);
        to_date.setHours(23);
        to_date.setMinutes(59);
        reqData.to_date = to_date;
      }
      if (filter.vehicle_type_id && filter.vehicle_type_id.length > 0) {
        reqData.vehicle_type_id = filter.vehicle_type_id;
      }
      if (filter.vehicle_type_brand_id) {
        reqData.vehicle_type_brand_id = filter.vehicle_type_brand_id;
      }
      if (filter.vehicle_type_brand_model_id) {
        reqData.vehicle_type_brand_model_id = filter.vehicle_type_brand_model_id;
      }
      if (filter.occasion) {
        reqData.occasion = filter.occasion;
      }

      if (filter.occasion_date_from) {
        let occasion_date_from = new Date(filter.occasion_date_from);
        occasion_date_from.setHours(0);
        occasion_date_from.setMinutes(0);
        reqData.occasion_date_from = occasion_date_from;
      }

      if (filter.occasion_date_to) {
        let date = new Date(filter.occasion_date_to);
        date.setHours(23);
        date.setMinutes(59);
        reqData.occasion_date_to = date;
      }
      if (filter.source_of_member) {
        reqData.source_of_member = filter.source_of_member;
      }
      if (filter.group_id && filter.group_id) {
        reqData.group_id = filter.group_id;
      }
      if (filter.filter_type) {
        reqData.filter_type = filter.filter_type;
      }
      if (filter.purpose_id) {
        reqData.purpose_id = filter.purpose_id;
      }
      if (filter.purpose_type) {
        reqData.purpose_type = filter.purpose_type;
      }
      if (filter.vehicle_category) {
        reqData.vehicle_category = filter.vehicle_category;
      }
      if (filter.last_visit_from_date) {
        reqData.last_visit_from_date = filter.last_visit_from_date;
      }
      if (filter.last_visit_to_date) {
        reqData.last_visit_to_date = filter.last_visit_to_date;
      }
      if (filter.due_date_from) {
        reqData.due_date_from = filter.due_date_from;
      }
      if (filter.due_date_to) {
        reqData.due_date_to = filter.due_date_to;
      }
      if (filter.service_id) {
        reqData.service_id = filter.service_id;
      }
      if (filter.due_type) {
        reqData.due_type = filter.due_type;
      }
      if (filter.rating) {
        reqData.rating = filter.rating;
      }
      if (filter.occasion_date_from) {
        reqData.occasion_date_from = filter.occasion_date_from;
      }
      if (filter.occasion_date_to) {
        reqData.occasion_date_to = filter.occasion_date_to;
      }
      if (filter.custom_filter_manual_customer) {
        reqData.custom_filter_manual_customer = filter.custom_filter_manual_customer;
      }
    }
    return reqData;
  };

  getCustomers = () => {
    this.setState({ isLoading: true });
    // const reqData = this.getFilterReqData(this.state.filterControls);
    let reqData = {};
    const { GroupManagementFilterStates } = this.state;
    if (GroupManagementFilterStates) {
      if (GroupManagementFilterStates.filter) {
        reqData = { ...reqData, ...GroupManagementFilterStates.filter };
      }
    }

    reqData.page = this.state.groupCustomerPage;
    reqData.pageSize = this.state.pageSize;
    reqData.fetch_customer_list = false;
    reqData.search_query = this.state.search_query
      ? this.state.search_query
      : "";
    this.setState({
      isLoading: true
    });
    CustomerManagementService.getCustomers(reqData)
      .then(res => {
        let {
          allCustomers,
          groupCustomersTotalPages,
          selected,
          selectAll,
          groupCustomersCount
        } = this.state;
        if (res.data.data && res.data.data.customers.length > 0) {
          allCustomers = res.data.data.customers;
          groupCustomersCount = res.data.data.count;
          groupCustomersTotalPages = Math.ceil(
            res.data.data.count / this.state.pageSize
          );
          this.setState(prevState => {
            return {
              ...prevState,
              allCustomers,
              groupCustomersTotalPages,
              groupCustomersCount,
              isLoading: false,
              // filterApplied: false,
              selectAll: this.state.isSelectAllEntry ? 0 : selectAll
            };
          });

          if (this.state.isSelectAllEntry) {
            this.state.allCustomers.forEach(x => {
              x.isChecked = true;
              if (selected.indexOf(x.customer_id) === -1) {
                selected.push(x.customer_id);
              }
            });
            this.setState(prevState => {
              return {
                ...prevState,
                selectAll
              };
            });
          } else {
            setTimeout(() => {
              const selectAll = this.checkSelectAllAddCustomer();
              this.setState(prevState => {
                return {
                  ...prevState,
                  selectAll
                };
              });
            }, 1);
          }
        } else {
          if (this.state.search_query !== "") {
            this.setState({
              filterApplied: true
            });
          }
          allCustomers = [];
          groupCustomersCount = 0;
          groupCustomersTotalPages = 0;
          this.setState(prevState => {
            return {
              ...prevState,
              allCustomers,
              groupCustomersTotalPages,
              groupCustomersCount,
              selectAll: 0,
              isLoading: false
            };
          });
        }
      })
      .catch(e => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  };

  // checkSelectAll = () => {
  //   const { selected, allCustomers } = this.state;
  //   let selectAll = 0;
  //   let perPageCountSelection = 0;
  //   if (selected.length > 0) {
  //     selected.forEach(id => {
  //       const index = allCustomers.findIndex(x => x.customer_id === id);
  //       if (index > -1) {
  //         perPageCountSelection++;
  //       }
  //     });
  //     if (perPageCountSelection === allCustomers.length) {
  //       selectAll = 1;
  //     } else if (perPageCountSelection === 0) {
  //       selectAll = 0;
  //     } else {
  //       selectAll = 2;
  //     }
  //   } else {
  //     selectAll = 0;
  //   }
  //   return selectAll;
  // };

  handlePageChangeCustomer = page => {
    this.setState({ groupCustomerPage: page }, () => {
      this.getCustomers();
    });
  };

  handleSearchChange = e => {
    this.setState(
      {
        search_query: e.target.value,
        groupCustomerPage: 1
      },
      () => {
        this.getCustomers();
      }
    );
  };

  toggleSelectAll = e => {
    GoogleAnalticsService.event('Create Group', 'Select Customer All');
    let { selected, allCustomers } = this.state;
    if (this.state.selectAll === 0 || this.state.selectAll === 2) {
      allCustomers.forEach(x => {
        x.isChecked = true;
        if (selected.indexOf(x.customer_id) === -1) {
          selected.push(x.customer_id);
        }
      });

      if (this.state.selectAll === 2) {
        this.state.selectAll = 0;
      }
    } else if (this.state.selectAll === 1) {
      if (this.state.isSelectAllEntry) {
        selected = [];
        allCustomers.forEach(x => {
          x.isChecked = false;
        });
      } else {
        allCustomers.forEach(x => {
          selected.forEach((element, i) => {
            if (element === x.customer_id) {
              x.isChecked = false;
              selected.splice(i, 1);
            }
          });
        });
      }
    }

    this.setState({
      selected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
      isSelectAllEntry: false,
      allCustomers
    });
  };

  // isItemSelected = id => {
  //   const { selected } = this.state;
  //   let isChecked = false;
  //   selected.forEach(element => {
  //     if (id === element) {
  //       isChecked = true;
  //     }
  //   });
  //   return isChecked;
  // };

  // toggleRow = id => {
  //   GoogleAnalticsService.event('Create Group', 'Select Customer');
  //   let { selected, allCustomers } = this.state;
  //   let index = selected.findIndex(x => x === id);
  //   let indexData = allCustomers.findIndex(x => x.customer_id === id);
  //   if (index > -1) {
  //     selected.splice(index, 1);
  //     allCustomers[indexData].isChecked = false;
  //   } else {
  //     if (selected.indexOf(id) === -1) {
  //       selected.push(id);
  //     }
  //     allCustomers[indexData].isChecked = true;
  //   }
  //   let selectAll = this.checkSelectAll();
  //   this.setState({
  //     selected,
  //     selectAll: selectAll,
  //     allCustomers,
  //     isSelectAllEntry: false
  //   });
  // };

  checkSelectAllAddCustomer = () => {
    let { selected, allCustomers } = this.state;
    let selectAll = 0;
    let perPageCountSelection = 0;
    if (selected.length > 0) {
      selected.forEach(id => {
        let index = allCustomers.findIndex(x => x.customer_id === id);
        if (index > -1) {
          allCustomers[index].isChecked = true;
          perPageCountSelection++;
        }
      });
      if (perPageCountSelection === allCustomers.length) {
        /**if we select all row one by one then selectAll is 1  */
        selectAll = 1;
      } else if (perPageCountSelection === 0) {
        /**if nothing is select then 0 */
        selectAll = 0;
      } else {
        /**on each row click the value of selectAll is 2 */
        selectAll = 2;
      }
    } else {
      selectAll = 0;
    }
    return selectAll;
  };

  sidebarOpenGroupFilter = () => {
    this.props.sidebarOpenGroupFilter(true, this.state.GroupManagementFilterStates);
  };

  toggleRowAddCustomer = (id) => {
    let { selected, allCustomers } = this.state;
    let index = selected.findIndex(x => x === id);
    let indexData = allCustomers.findIndex(x => x.customer_id === id);
    if (index > -1) {
      /**If it is already checked then we remove it from selected array  */
      selected.splice(index, 1);
      allCustomers[indexData].isChecked = false;
    } else {
      if (selected.indexOf(id) === -1) {
        /**If it is not checked then we select it and push the id to selected array  */
        selected.push(id);
      }
      allCustomers[indexData].isChecked = true;
    }
    let selectAll = this.checkSelectAllAddCustomer();
    this.setState({
      selected,
      selectAll: selectAll,
      allCustomers,
      isSelectAllEntry: false
    });
  };

  selectAllEntry = isSelectAllEntry => {
    let { selected, allCustomers } = this.state;

    if (isSelectAllEntry) {
      allCustomers.forEach(x => {
        x.isChecked = true;
        if (selected.indexOf(x.customer_id) === -1) {
          selected.push(x.customer_id);
        }
      });
      this.setState(prevState => {
        return {
          ...prevState,
          isSelectAllEntry: isSelectAllEntry,
          selectAll: 1
        };
      });
    } else {
      allCustomers.forEach(x => {
        x.isChecked = false;
      });
      this.setState(prevState => {
        return {
          ...prevState,
          isSelectAllEntry: isSelectAllEntry,
          selected: [],
          selectAll: 0,
          allCustomers
        };
      });
    }
  };

  // ============================================ New Task End ====================================

  render() {
    const {
      controls,
      groupCustomerPage,
      groupCustomersTotalPages,
      groupCustomersCount,
      pageSize,
      allCustomers,
      filterApplied,
      selected
    } = this.state;

    const { group_name } = controls;
    const { isEdit = false } = this.props;
    let customersRows = null;
    if (allCustomers.length > 0) {
      customersRows = allCustomers.map((ac, index) => (
        <tr key={ac.customer_id}>
          <td>
            <label className="container-checkbox" style={{ float: "left" }}>
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                checked={ac.isChecked ? true : false}
                onChange={this.toggleRowAddCustomer.bind(this, ac.customer_id)}
              />{" "}
              <span
                className="checkmark"
                style={{ borderColor: "#fdbc39" }}
              ></span>
            </label>

            {ac.customer_name !== " " && ac.customer_name
              ? ac.customer_name
              : "No Name"}
          </td>
          <td>{ac.phone_number ? ac.phone_number : "--"}</td>
        </tr>
      ));
    } else {
      if (filterApplied) {
        customersRows = (
          /**If filter applied and their is no customer */
          <tr>
            <td colSpan="4" className="text-align-center">
              We can not find any records matching your search criteria. Please
              change search criteria and try again.
            </td>
          </tr>
        );
      } else {
        /** If get customer Api return 0 customers */
        customersRows = (
          <tr>
            <td colSpan="4" className="text-align-center">
              There is no customer to add in group
            </td>
          </tr>
        );
      }
    }

    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <>
          <div className="drawer-header">
            <span className="drawer-title">
              {isEdit ? "Edit" : "Create"} Group
            </span>
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
          <div className="custom-drawer-content-wrapper" id="addNewGroup">
            <Row
              style={{ marginLeft: "0", marginRight: "0", fontSize: "12px" }}
            >
              {/**When creating a new group from the customer list , it shows the below section in sidebar*/}
              {this.state.from && this.state.from === "add-to-group" && (
                <Col xl="12" md="12" xs="12" className="mb-2">
                  <img src="images/left.svg" className="cursor-pointer" alt='' />
                  &nbsp;&nbsp;
                  <Label
                    className="link-warm-blue cursor-pointer"
                    style={{
                      fontSize: "18px",
                      fontWeight: "normal",
                      marginTop: "5px"
                    }}
                    onClick={() => this.goBack()}
                  >
                    Back
                  </Label>
                </Col>
              )}
              <Col xl="12" md="12" xs="12">
                <FormGroup>
                  <Label for="common">
                    Group Name<span className="required-field">*</span>
                  </Label>
                  {/* {this.state.selected.length > 0 && (
                    <span className="member-count">
                      Selected Members:{" "}
                      <span>
                        {!this.state.isSelectAllEntry
                          ? this.state.selected.length
                          : groupCustomersCount}
                      </span>
                    </span>
                  )} */}

                  <Input
                    type="text"
                    className="group_name"
                    name="group_name"
                    autoComplete="nofill"
                    id="group_name"
                    value={controls.group_name.value}
                    onChange={this.handleInputChange}
                    placeholder="Type Here"
                  />
                  {group_name.showErrorMsg && (
                    <div className="error">*Please enter group name</div>
                  )}
                </FormGroup>
              </Col>

              {!this.props.selectedCustomers && (
                <span>
                  <Col xl="12" md="12" xs="12" style={{ marginBottom: "10px" }}>
                    Please select customers which you want to add to this group.
                  </Col>

                  <Col xl="12" md="12" xs="12">
                    <div className="d-flex-row padding-bottom-25">
                      <Input
                        placeholder="Search by customer name and mobile number"
                        className="login-input"
                        name="search"
                        onChange={this.handleSearchChange}
                      // value={search_query}
                      />
                      <img
                        src="images/search.svg"
                        alt=''
                        className="Group-3 icon-width"
                        style={{ cursor: "pointer" }}
                      />
                      <span
                        className="d-flex-row"
                        style={{ marginLeft: "15px" }}
                      >
                        {/* {
                          this.state.filterApplied===true && (
                            <img
                              src="images/smaller-blue-dot-hi.png"
                               alt=''
                              className="apply-filter-dot"
                              style={{ marginLeft: "32px" }}
                            />
                          )} */}
                        <img
                          src={this.state.filterApplied === true ? "images/smaller-blue-dot-hi.png" : "images/filter.svg"}
                          // srcSet="images/sidebar-icons/group-3@2x.png 2x,
                          //       images/sidebar-icons/group-3@3x.png 3x"
                          className={`cursor-pointer ${this.state.filterApplied === true
                            ? 'filter-icon-selected'
                            : 'filter-icon'}`
                          }
                          style={{ cursor: "pointer" }}
                          onClick={this.sidebarOpenGroupFilter}
                          alt=''
                        />
                      </span>
                    </div>
                  </Col>
                </span>
              )}
            </Row>
            {/* {this.state.selected.length > 0 && (
              <Col style={{ marginTop: "-15px" }}>
                <span
                  className="member-count"
                  style={{
                    float: "left",
                    color: "black",
                    marginBottom: "8px"
                  }}
                >
                  Selected Members:{" "}
                  <span>
                    {" "}
                    {!this.state.isSelectAllEntry
                      ? this.state.selected.length
                      : groupCustomersCount}
                  </span>
                </span>
              </Col>
            )} */}
            {selected.length > 0 ? (
              <div className="entries-selected mb-4" style={{
                marginLeft: '18px', marginRight: '18px'
              }}>
                {groupCustomersTotalPages > 1 ? (
                  <div className="d-flex-row justify-content-between">

                    {pageSize < groupCustomersCount &&
                      this.state.selectAll === 1 && (
                        <>
                          {!this.state.isSelectAllEntry ? (
                            <span onClick={() => this.selectAllEntry(true)}>
                              Select All {groupCustomersCount} Entries.
                            </span>
                          ) : (
                            <span
                              onClick={() => this.selectAllEntry(false)}
                            >
                              Clear Selection.
                            </span>
                          )}
                        </>
                      )}
                    <span className="color-757575 ml-auto">
                      {!this.state.isSelectAllEntry
                        ? selected.length
                        : groupCustomersCount}{" "}
                      {selected.length > 1 ? "Entries" : "Entry"} Selected.
                    </span>
                  </div>
                ) : (
                  <div className="d-flex-row justify-content-between">

                    {pageSize < groupCustomersCount &&
                      this.state.selectAll === 1 && (
                        <>
                          {!this.state.isSelectAllEntry ? (
                            <span onClick={() => this.selectAllEntry(true)}>
                              Select all {groupCustomersCount} entries.
                            </span>
                          ) : (
                            <span
                              onClick={() => this.selectAllEntry(false)}
                            >
                              Clear Selection.
                            </span>
                          )}
                        </>
                      )}
                    <span className="color-757575 ml-auto">
                      {!this.state.isSelectAllEntry
                        ? selected.length
                        : groupCustomersCount}{" "}
                      {selected.length > 1 ? "entries" : "entry"} selected.
                    </span>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {!this.props.selectedCustomers && (
              <table
                style={{ width: "93%", marginLeft: "18px", marginTop: "-8px" }}
              >
                {/**Displays the table to select customer when we create group frm manage group page */}
                <thead>
                  <tr>
                    <th>
                      {groupCustomersCount > 0 && (
                        <>
                          {this.state.selectAll === 2 ? (
                            <img
                              onClick={e => this.toggleSelectAll()}
                              src="images/group-16.svg"
                              style={{ height: "14px", paddingRight: '12px' }}
                              alt=''
                            />
                          ) : this.state.selectAll === 1 ? (
                            <label
                              className="container-checkbox"
                              style={{ float: "left" }}
                            >
                              <input
                                type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                checked={true}
                                onChange={e => this.toggleSelectAll()}
                              />{" "}
                              <span
                                className="checkmark"
                                style={{ borderColor: "#fdbc39" }}
                              ></span>
                            </label>
                          ) : (
                            <label
                              className="container-checkbox"
                              style={{ float: "left" }}
                            >
                              <input
                                type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                checked={false}
                                onChange={e => this.toggleSelectAll()}
                              />{" "}
                              <span
                                className="checkmark"
                                style={{ borderColor: "#fdbc39" }}
                              ></span>
                            </label>
                          )}
                        </>
                      )}
                      Customer Name
                    </th>
                    <th>Mobile No.</th>
                  </tr>
                </thead>
                <tbody>{customersRows}</tbody>
              </table>
            )}
            {groupCustomersTotalPages > 1 && !this.props.selectedCustomers && (
              <div
                style={{ float: "left", marginLeft: "18px", marginTop: "12px" }}
              >
                <Pagination
                  margin={2}
                  page={groupCustomerPage}
                  count={groupCustomersTotalPages}
                  pageSize={pageSize}
                  totalRecords={groupCustomersCount}
                  onPageChange={this.handlePageChangeCustomer}
                />
              </div>
            )}
          </div>
          <Row style={{ marginLeft: "0", marginRight: "0" }}>
            <Col
              xl="12"
              xs="12"
              md="12"
              className="text-align-right footer-drawer"
            >
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                onClick={() => this.props.onCloseSidebar()}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-save-button"
                color="warning"
                onClick={isEdit ? this.editGroup : this.addNewGroup}
              >
                {isEdit ? "Save" : "Add"}
              </Button>
            </Col>
          </Row>
        </>

        <br />
        <br />
        <br />
      </>
    );
  }
}

export default AddNewGroup;
