// getting role from type key from Login and get User info API
export const Roles = {
  SUPPORT_ROLE: 'support',
  DEALER_ROLE: 'dealer',
  AREA_ROLE: 'area',
  AREA_MANAGER_ROLE: 'area_manager',
  SALES_OFFICER_ROLE: 'sales_officer',
  REGION_MANAGER_ROLE: 'region_manager',
  ZONE_MANAGER_ROLE: 'zone_manager',
  ZTM_ROLE: 'ztm',
  HEAD_OFFICE_ROLE: 'head_office',
  PROJECT_MANAGER_ROLE: 'project_manager',
  SUB_DEALER_ROLE: 'sub_dealer',
  BRANCH_ROLE: 'branch',
  ARCHITECT_ROLE: 'architect',
  AGENCY_ROLE: 'agency',
  EMPLOYEE_ROLE: 'employee',
  ZONE_ROLE: 'zone',
  SUPER_ADMIN_ROLE: 'super_admin',
  DISTRIBUTOR_ROLE: 'distributor',
  MARKETING_ROLE: 'marketing',
  ACCOUNTANT_ROLE: 'accountant',
  VENDOR_ROLE: 'vendor',
  FINANCE_ROLE: 'finance',
  BRAND_STORE_ROLE: 'brand_store',

}