import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { Button, Input, Row, Col, Card, CardBody, FormGroup } from "reactstrap";
import Chart from "react-apexcharts";
import Slider from "react-slick";
import swal from "sweetalert";
// components
import DashboardCard from "../DashboardCard/DashboardCard";
import VideoComponent from "../VideoComponent/VideoComponent";
import AboutJKAdvantage from "../AboutJKAdvantage/AboutJKAdvantage";
import HowItWorks from "../HowItWorks/HowItWorks";
import SendVerifyOtp from "../SendVerifyOtp/SendVerifyOtp";
import DashboardService from "../../services/DashboardService";
import ModalService from "../../services/ModalService";
//models
//import BuyProduct from '../../modal/BuyProduct/BuyProduct';
// utils
import {
  formatNumber,
} from "../../utils/utils";

// css
import "./DashboardWeb.css";
import Storage from "../../services/Storage";
import UserService from "../../services/UserService";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

class DashboardWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      yearOptions: [],
      year: null,
      year_name: null,
      isModalOpen: false,
      current_year: new Date().getFullYear(),
      hide_card: {
        warranty_register: true,
      },
      is_display_warranty: false,
    };

    if (
      this.state.userDetail.permissions &&
      (
        // this.state.userDetail.permissions.includes(Permissions.WARRANTY_REPORT_EMPLOYEE) ||
        this.state.userDetail.permissions.includes(Permissions.WARRANTY_REPORT))
    ) {
      this.state.is_display_warranty = true;
    }

    if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.WARRANTY_REGISTRATION)) {
      this.state.hide_card.warranty_register = false;
    }
  }

  prepareSparkLineChart = (categories, series) => {
    const sparkLineChart = {
      options: {
        stroke: {
          curve: "smooth",
          width: 2,
        },
        markers: {
          size: 4,
        },
        xaxis: {
          categories,
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        tooltip: {
          label: "",
        },
        colors: ["#545cd8"],
      },

      series: [
        {
          name: "",
          data: series,
        },
      ],
    };

    return sparkLineChart;
  };

  redirectToSecondaryAbout = () => {
    this.props.redirectToSecondaryReport();
  };

  closeSendVerifyOtp = (phoneNumber, token) => {
    if (phoneNumber) {
      this.props.redirectToWarrantyReplacement(phoneNumber, token);
    }
  };

  openSendVerifyOtp = () => {
    const wrapper = document.createElement("div");
    ReactDOM.render(
      <SendVerifyOtp closeSendVerifyOtp={this.closeSendVerifyOtp} />,
      wrapper
    );
    const el = wrapper.firstChild;
    swal({
      text: "",
      content: el,
      buttons: false,
      className: "send-verify-otp-modal",
      closeOnClickOutside: false,
      closeOEscapeKey: false,
      closeOnConfirm: false,
    }).then((value) => {
    });
  };

  redirectToWarrantyScoreCard = () => {
    this.props.history.push(`warranty-score-card?y=all`);
  };

  componentDidMount = () => {
    this.filterAPI();
    this.getUserDetail();
  };

  getUserDetail = async (is_loading = false) => {
    if (is_loading) {
      this.setState({
        isLoading: true
      })
    }
    let response_data = await UserService.getUserInfo()
      .then(response => {
        let { userDetail, hide_card } = this.state;
        if (response.data && response.data.data) {
          Storage.setUserDetail(response.data.data);
          userDetail = response.data.data;
          if (userDetail.permissions && userDetail.permissions.includes(Permissions.WARRANTY_REGISTRATION)) {
            hide_card.warranty_register = false;
          }
        }
        this.setState({
          hide_card,
          userDetail,
        })
        if (is_loading) {
          this.setState({
            isLoading: false
          })
        }
        return userDetail;
      }).catch(e => {
        if (is_loading) {
          this.setState({
            isLoading: false
          })
        }
        return {};
        console.error("e", e);
      })
    return response_data;
  }

  filterAPI = () => {
    const { year } = this.state;

    let filter = {
      y: year ? year : null,
    };
    this.setState({ isLoader: true });
    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          this.setState({ isLoader: false });

          this.setState(
            {
              yearOptions: res.data.data.year ? res.data.data.year : [],
            },
            () => {
              if (
                !this.state.year &&
                this.state.yearOptions &&
                this.state.yearOptions[0]
              ) {
                this.setState(
                  {
                    year: this.state.yearOptions[0].value,
                    year_name: this.state.yearOptions[0].name,
                  },
                  () => {
                    this.props.yearFilterApplied(
                      this.state.year,
                      this.state.year_name
                    );
                  }
                );
              } else {
                this.props.yearFilterApplied(
                  this.state.year,
                  this.state.year_name
                );
              }
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        console.log("err", err);
      });
  };

  openBuyProductModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeBuyProductModal = (update, message) => {
    this.setState({ isModalOpen: false });
    if (update === true) {
      ModalService.openAlert("Buy SMS", message, "success");
    }
  };

  onChangeYear = (e) => {
    this.setState(
      {
        year: e && e.target && e.target.value,
      },
      () => {
        if (this.state.year) {
          let year_name = this.state.yearOptions.filter(
            (x) => x.value == this.state.year
          );
          this.setState({
            year_name: year_name && year_name[0].name,
          });
        }
        this.props.yearFilterApplied(this.state.year, this.state.year_name);
      }
    );
  };

  afterChangeBanner = (index) => {
    this.props.afterChangeBanner(index);
  };

  render() {
    const {
      controls,
      isSentOTP,
      yearObj,
      quarterObj,
      monthObj,
      showYTDCard,
      showQTDCard,
      showMTDCard,
      stackedChartWeb,
      stackedChart: sc,
      prepareStackedChart,
      redeemData,
      // totalSeconadryPoints,
      totalPrimaryPoints,
      advantageMessage,
      displayLabels,
      totalPrivionalPoints,
      // redeemEnable,
      // redeemDays,
      sparkLineChartPrimary,
      sparkLineChartSecondary,
      memberRole,
      warranty_data,
      // displayLabelsWarrantyTrends,
    } = this.props;
    const { warrantyPhoneNumber, warrantyOTP } = controls;
    let stackedChart = sc;
    if (!sc) {
      stackedChart = prepareStackedChart(
        stackedChartWeb.categories,
        stackedChartWeb.series
      );
    }
    const sparklinePrimary = this.prepareSparkLineChart(
      sparkLineChartPrimary.categories,
      sparkLineChartPrimary.data
    );
    // const sparklineSecondary = this.prepareSparkLineChart(
    //   sparkLineChartSecondary.categories,
    //   sparkLineChartSecondary.data
    // );

    const settings_banner = {
      className: "slider-banner",
      centerMode: false,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      dots: true,
      initialSlide: 1,
    };
    return (
      <>
        {/* {this.state.isModalOpen && <BuyProduct
          show={this.state.isModalOpen}
          closeModal={this.closeBuyProductModal}
        />} */}
        <div id="dashboard-web">
          {/* {redirectToWarrantyRegistration && <Redirect to={{ pathname: '/warranty-registration', search: `phoneNumber=${warrantyPhoneNumber.value}` }} />} */}
          <Row style={{ marginBottom: "-18px", marginTop: "10px" }}>
            {/* <Col
              xl="10"
              md="8"
              xs="12"
              className="page-title pb-2"
              style={{ marginLeft: "0px !important" }}
            >
              Dashboard
            </Col> */}

            {this.state.yearOptions.length > 1 && (
              <Col xs="12" style={{ marginLeft: "auto" }}>
                <div className="d-flex flex-column justify-content-end align-items-end">
                  <div>
                    <div className="fin-label mb-1">Financial Year</div>
                    <FormGroup>
                      <select
                        name="month"
                        className="form-control cursor-pointer"
                        value={this.state.year}
                        style={{
                          fontSize: "13px",
                          width: "110px",
                          color: "#6e777f",
                          // marginLeft: "auto",
                        }}
                        onChange={(e) => {
                          this.onChangeYear(e);
                        }}
                      >
                        {this.state.yearOptions.map((item) => (
                          <option value={item.value} selected>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </div>
                </div>
              </Col>
            )}
          </Row>

          {memberRole === Roles.DEALER_ROLE && advantageMessage && <br />}
          {memberRole === Roles.DEALER_ROLE && advantageMessage && (
            <Row>
              <CardBody
                className={`gradient-title ${redeemData.action ? "cursor-pointer" : ""
                  }`}
                onClick={
                  redeemData.action
                    ? this.props.redeemAction.bind(this, redeemData.action)
                    : null
                }
              >
                <Row>
                  <Col>
                    <span className="gradient-sub-title">
                      {advantageMessage}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Row>
          )}

          <br />
          {
            this.props.is_display_new_warranty_reg_login
              &&
              !this.props.is_from_v2_dashbaord
              ? (
                <Row className="ml-0" style={{ paddingBottom: "10px" }}>
                  {!this.state.hide_card.warranty_register ?
                    <Col
                      xl="8"
                      xs="12"
                      md="12"
                      className='card-warranty-reg-otp justify-content-between d-flex-column'
                    >
                      <div className="d-flex justify-content-between mt-1">
                        <span className="dashboard-warranty-reg-title mt-auto mb-auto">
                          Warranty Registration
                        </span>
                        {this.props.isClamRegistrationOpen && (
                          <span
                            onClick={() => this.openSendVerifyOtp()}
                            className="custom-anchor-tag custom-pull-right existing-customer-eligibility mt-auto mb-auto"
                          >
                            Check existing customer’s eligibility for warranty
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        {!isSentOTP ? (
                          <span
                            style={{ fontSize: "14px" }}
                            className="mt-auto mb-auto"
                          >
                            Please provide customer's mobile number to register
                          </span>
                        ) : (
                          <div className="d-flex">
                            <span
                              style={{ fontSize: "14px" }}
                              className="mt-auto mb-auto"
                            >
                              Enter the OTP Sent to {warrantyPhoneNumber.value}. Did
                              not get code?
                            </span>

                            <span
                              onClick={this.props.sendOTP}
                              className="custom-anchor-tag mt-auto mb-auto white-space-nowrap
                        ml-1"
                            >
                              Send Again
                            </span>
                          </div>
                        )}
                        <div className="d-flex-row">
                          {!isSentOTP ? (
                            <>
                              <FormGroup className="mb-0">
                                <Input
                                  type="text"
                                  autoComplete="nofill"
                                  className="nine-one-rect"
                                  disabled={true}
                                  value="+91"
                                />
                              </FormGroup>
                              <FormGroup className="mb-0">
                                <Input
                                  type="text"
                                  autoComplete="nofill"
                                  className="enter-number-otp"
                                  name="warrantyPhoneNumber"
                                  maxLength="10"
                                  value={warrantyPhoneNumber.value}
                                  onChange={this.props.handleInputChange}
                                  style={{ marginLeft: "10px", width: "230px" }}
                                  placeholder="Enter Customer Mobile Number"
                                />
                              </FormGroup>
                            </>
                          ) : (
                            <FormGroup className="mb-0">
                              <Input
                                type="text"
                                autoComplete="nofill"
                                className="enter-number-otp"
                                name="warrantyOTP"
                                value={warrantyOTP.value}
                                onChange={this.props.handleInputChange}
                                style={{ marginLeft: "10px", width: "230px" }}
                                placeholder="Enter OTP"
                              />
                            </FormGroup>
                          )}
                          {!isSentOTP ? (
                            <Button
                              className="btn-pill action-button profile-cancel-button"
                              style={{ marginLeft: "8px", width: "100px" }}
                              onClick={this.props.sendOTP}
                            >
                              Send OTP
                            </Button>
                          ) : (
                            <Button
                              className="btn-pill action-button profile-cancel-button"
                              style={{ marginLeft: "8px", width: "100px" }}
                              onClick={this.props.verifyOTP}
                            >
                              Submit
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                    : ""}
                  {this.props.bannerData.length > 0 && (
                    <Col xl="4" md="12" xs="12" className={`${!this.state.hide_card.warranty_register ? "" : "px-0"}`}>
                      <Slider
                        {...settings_banner}
                        ref={this.sliderBanner}
                        afterChange={this.afterChangeBanner}
                        initialSlide={0}
                      >
                        {this.props.banner_list_item}
                      </Slider>
                    </Col>
                  )}
                </Row>
              ) : (
                (!this.props.is_from_v2_dashbaord ||
                  this.props.is_from_v2_dashbaord) && (
                  !this.state.hide_card.warranty_register ?
                    <>
                      <Row className="warranty-block padding-15">
                        <Col>
                          {isSentOTP ? (
                            <Row>
                              <Col
                                xl="8"
                                md="8"
                                sm="8"
                                className="warranty-text-bold"
                                style={{ marginTop: "9px", paddingLeft: "0" }}
                              >
                                <span
                                  className="warranty-text"
                                  style={{ color: "#545cd8", fontSize: "16px" }}
                                >
                                  WARRANTY REGISTRATION
                                </span>
                                <span
                                  className="warranty-text"
                                  style={{ marginLeft: "15px" }}
                                >
                                  Enter the OTP code send to{" "}
                                  {warrantyPhoneNumber.value}. Didn't get the code?
                                  &nbsp;
                                  <span
                                    onClick={this.props.sendOTP}
                                    className="custom-anchor-tag"
                                  >
                                    Send Again
                                  </span>
                                  &nbsp;&nbsp;
                                  <span
                                    onClick={this.props.cancelOTP}
                                    className="custom-anchor-tag"
                                  >
                                    Cancel
                                  </span>
                                </span>
                              </Col>
                              <Col xl="3" md="3" sm="3" style={{ paddingLeft: "0" }}>
                                <Input
                                  type="text"
                                  name="warrantyOTP"
                                  id="warrantyOTP"
                                  className="input-focus-white"
                                  value={warrantyOTP.value}
                                  onChange={this.props.handleInputChange}
                                  placeholder="Enter OTP"
                                />
                              </Col>
                              <Col xl="1" md="1" sm="1" style={{ padding: "0" }}>
                                <Button
                                  disabled={!warrantyOTP.value}
                                  style={{ width: "95px" }}
                                  onClick={this.props.verifyOTP}
                                  className="mb-1 mr-1 btn-pill action-button send-verify-otp"
                                  color="warning"
                                >
                                  <b>Submit</b>
                                </Button>
                              </Col>
                            </Row>
                          ) : (

                            <Row>
                              <Col
                                xl="8"
                                md="8"
                                sm="8"
                                className="warranty-text-bold"
                                style={{
                                  marginTop: "9px",
                                  paddingLeft: "0",
                                  paddingRight: "0",
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="warranty-text"
                                  style={{
                                    color: "#545cd8",
                                    fontSize: "16px",
                                    width: "100%",
                                    maxWidth: "222px",
                                  }}
                                >
                                  WARRANTY REGISTRATION
                                </div>
                                <span
                                  className="warranty-text"
                                  style={{ marginLeft: "15px" }}
                                >
                                  Enter the customer's phone number and we will send
                                  you a verification code
                                </span>
                              </Col>
                              <Col xl="3" md="3" sm="3" style={{ paddingLeft: "0" }}>
                                <div className="input-group prefix">
                                  <span className="input-group-addon1">+91</span>
                                  <Input
                                    type="text"
                                    name="warrantyPhoneNumber"
                                    id="warrantyPhoneNumber"
                                    maxLength="10"
                                    className="input-focus-white"
                                    style={{ paddingLeft: "43px" }}
                                    value={warrantyPhoneNumber.value}
                                    onChange={this.props.handleInputChange}
                                    placeholder="Enter customer's phone number"
                                  />
                                </div>
                              </Col>
                              <Col xl="1" md="1" sm="1" style={{ padding: "0" }}>
                                <Button
                                  onClick={this.props.sendOTP}
                                  disabled={
                                    !warrantyPhoneNumber.value ||
                                    (warrantyPhoneNumber.value &&
                                      warrantyPhoneNumber.value.length !== 10)
                                  }
                                  className="mb-1 mr-1 btn-pill action-button send-verify-otp"
                                  color="warning"
                                >
                                  <b>Send OTP</b>
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ paddingRight: "5px", marginTop: "10px" }}>
                          {this.props.isClamRegistrationOpen && (
                            <span
                              onClick={() => this.openSendVerifyOtp()}
                              className="custom-anchor-tag custom-pull-right existing-customer-eligibility"
                            >
                              Check existing customer’s eligibility for warranty
                            </span>
                          )}
                        </Col>
                      </Row>
                    </>
                    : ""
                )
              )}

          {/* {memberRole === Roles.DEALER_ROLE && advantageMessage && <br />}
           {memberRole === Roles.DEALER_ROLE && advantageMessage && (
             <Row>
               <CardBody
                 className={`gradient-title ${
                   redeemData.action ? "cursor-pointer" : ""
                   }`}
                 onClick={
                   redeemData.action
                     ? this.props.redeemAction.bind(this, redeemData.action)
                     : null
                 }
               >
                <Row>
                  <Col style={{ paddingRight: "5px", marginTop: "10px" }}>
                    {this.props.isClamRegistrationOpen && (
                      <span
                        onClick={() => this.openSendVerifyOtp()}
                        className="custom-anchor-tag custom-pull-right existing-customer-eligibility"
                      >
                        Check existing customer’s eligibility for warranty
                      </span>
                    )}
                  </Col>
                </Row>
              </>
            )
          )}

          {/* {redeemEnable && <Row>

        <CardBody className="gradient-title">
          YOUR REWARDS ARE READY!!
          <span className="gradient-sub-title">{' '}Your opportunity to Redeem Points just opened up. You have {redeemDays} Days remaining to redeem</span>
        </CardBody>
      </Row>} */}
          {this.props.bannerData.length <= 0 && <br />}
          <div className="dashboard-page-title">
            POINTS ON OFFTAKE &nbsp;
            <span className="year-label">
              {" "}
              {this.state.year_name
                ? "(" + this.state.year_name + ")"
                : null}{" "}
            </span>
          </div>
          {/* <br /> */}
          <Row>
            <Col
              xl="3"
              className="margin-top-10 padding-left-5 padding-right-5"
            >
              <Card
                className="height-100 border-radius-5"
                style={{ minHeight: "420px" }}
              >
                <Row className="card-custom-header">
                  <Col xs="6" className="dashboard-page-card-title-big">
                    <div>Total Offtake Points</div>
                    <div className="dashboard-page-card-title-small">
                      {displayLabels && displayLabels.year}
                    </div>
                  </Col>
                  <Col xs="6" className="count-small">
                    {formatNumber(totalPrimaryPoints + totalPrivionalPoints)}
                  </Col>
                </Row>
                <CardBody>
                  <Row>
                    <Col xs="6" className="dashboard-page-card-title-big">
                      <div>Points Eligible for Redemption</div>
                      <div className="dashboard-page-card-title-small">
                        {displayLabels &&
                          displayLabels.year_till_previous_quarter}
                      </div>
                    </Col>
                    <Col xs="6" className="count-small">
                      {formatNumber(totalPrimaryPoints)}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Chart
                        options={sparklinePrimary.options}
                        series={sparklinePrimary.series}
                        type="line"
                        width="100%"
                        height="100px"
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="6"
                      className="dashboard-page-card-title-small-gray"
                    >
                      Provisional Points for the Quarter (
                      {displayLabels && displayLabels.quarter})
                    </Col>
                    <Col xs="6" className="count-small">
                      {formatNumber(totalPrivionalPoints)}
                    </Col>
                  </Row>

                  <Row
                    className="margin-top-10"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      width: "95%",
                      textAlign: "justify",
                    }}
                  >
                    <Col>
                      <span className="These-Points-are-pr">
                        *These Points are provisional. They will get added to
                        your total earned points at the end of Q2 if you are
                        eligible.
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col
              xl="3"
              className="margin-top-10 padding-left-5 padding-right-5"
            >
              <DashboardCard
                {...yearObj}
                showCard={showYTDCard}
                displayLabels={displayLabels}
              />
            </Col>
            <Col
              xl="3"
              className="margin-top-10 padding-left-5 padding-right-5"
            >
              <DashboardCard
                {...quarterObj}
                showCard={showQTDCard}
                displayLabels={displayLabels}
              />
            </Col>
            <Col
              xl="3"
              className="margin-top-10 padding-left-5 padding-right-5"
            >
              <DashboardCard
                {...monthObj}
                showCard={showMTDCard}
                displayLabels={displayLabels}
              />
            </Col>
          </Row>
          <br />
          {this.state.is_display_warranty && (
            <>
              <div className="dashboard-page-title">
                POINTS ON WARRANTY &nbsp;
                {/* <span className="year-label text-uppercase">(apr’19 to till now)
            </span> */}
                {/* <span style={{ float: 'right' }}>
              <Button type="submit" className="mb-1 mr-1 btn-pill Rectangle-button send-button" onClick={this.openBuyProductModal} color="warning">
                Buy Product
                </Button>
            </span> */}
              </div>
              {/* <br /> */}
              {/* {!totalSeconadryPoints > 0 && <Row>
        <Col className="margin-top-10 padding-left-5 padding-right-5">
          <Card>
            <CardBody className="no-warranty-points">
              Start registering your customer for warranty and earn points on each registartion.
            </CardBody>
          </Card>
        </Col>
      </Row>} */}
              {/* {totalSeconadryPoints > 0 && */}
              <Row>
                <Col
                  xl="5"
                  className="margin-top-10 padding-left-5 padding-right-5"
                >
                  <Card
                    id="warranty-popover"
                    className={
                      this.state.tooltipOpen &&
                        this.state.tooltip_name === "warranty-popover"
                        ? "card-box-shadow-10px he-100 border-radius-8px box-shadow-40px-0-5"
                        : "card-box-shadow-10px he-100 border-radius-8px card-box-shadow-hover-30px"
                    }
                    onClick={() => this.redirectToWarrantyScoreCard()}
                  >
                    <CardBody className="d-flex-column padding-13px">
                      <div className="d-flex-row justify-content-lg-between">
                        <span
                          className="dealer-dashobard-card-title margin-bottom-13px"
                          style={{ color: "#495057" }}
                        >
                          Warranty Points - {warranty_data.total_points}
                        </span>
                        {/* {this.state.yearOption.year_name && 
                      <span className="Apr---Jun209">Apr’19 to Till Now</span>
                      } */}
                        <span className="Apr---Jun209">Apr’19 to Till Now</span>
                      </div>
                      <Row>
                        <Col xl="4" md="4" xs="12">
                          <div className="d-flex-row justify-content-lg-between">
                            <span
                              className="dealer-dashobard-card-sbtitle"
                              style={{ color: "#495057" }}
                            >
                              Total Registrations
                            </span>
                          </div>
                          <Row style={{ marginTop: "10px" }}>
                            <Col xl="6" md="6" xs="12">
                              <div className="d-flex-row justify-content-lg-between">
                                <Row>
                                  <Col xl="12" md="12" xs="12">
                                    <span
                                      className="dealer-dashobard-card-text"
                                      style={{ color: "#495057" }}
                                    >
                                      Customers
                                    </span>
                                  </Col>
                                  <Col
                                    xl="12"
                                    md="12"
                                    xs="12"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="dealer-card-point mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <b>{warranty_data.customer_count || 0}</b>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col xl="6" md="6" xs="12">
                              <div className="d-flex-row justify-content-lg-between">
                                <Row>
                                  <Col xl="12" md="12" xs="12">
                                    <span
                                      className="dealer-dashobard-card-text"
                                      style={{ color: "#495057" }}
                                    >
                                      Sensors
                                    </span>
                                  </Col>
                                  <Col
                                    xl="12"
                                    md="12"
                                    xs="12"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="dealer-card-point mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <b>{warranty_data.sensor_count || 0}</b>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col xl="8" md="8" xs="12" className="border-left-ECEDEE">
                          <div className="d-flex-row justify-content-lg-between">
                            <span
                              className="dealer-dashobard-card-sbtitle"
                              style={{ color: "#495057" }}
                            >
                              Tyres
                            </span>
                          </div>
                          <Row style={{ marginTop: "10px" }}>
                            <Col xl="3" md="3" xs="12">
                              <div className="d-flex-row justify-content-lg-between">
                                <Row>
                                  <Col xl="12" md="12" xs="12">
                                    <span
                                      className="dealer-dashobard-card-text"
                                      style={{ color: "#495057" }}
                                    >
                                      PCR
                                    </span>
                                  </Col>
                                  <Col
                                    xl="12"
                                    md="12"
                                    xs="12"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="dealer-card-point mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <b>{warranty_data.pcr_wheeler_tyre || 0}</b>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col xl="3" md="3" xs="12">
                              <div className="d-flex-row justify-content-lg-between">
                                <Row>
                                  <Col
                                    xl="12"
                                    md="12"
                                    xs="12"
                                    style={{
                                      paddingRight: "5px",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    <span
                                      className="dealer-dashobard-card-text"
                                      style={{ color: "#495057" }}
                                    >
                                      2 Wheeler
                                    </span>
                                  </Col>
                                  <Col
                                    xl="12"
                                    md="12"
                                    xs="12"
                                    style={{
                                      paddingRight: "5px",
                                      paddingLeft: "5px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <span
                                      className="dealer-card-point mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <b>
                                        {warranty_data.two_three_wheeler_tyre || 0}
                                      </b>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col xl="3" md="3" xs="12">
                              <div className="d-flex-row justify-content-lg-between">
                                <Row>
                                  <Col xl="12" md="12" xs="12">
                                    <span
                                      className="dealer-dashobard-card-text"
                                      style={{ color: "#495057" }}
                                    >
                                      LCV
                                    </span>
                                  </Col>
                                  <Col
                                    xl="12"
                                    md="12"
                                    xs="12"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="dealer-card-point mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <b>{warranty_data.lcv_tyre || 0}</b>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col xl="3" md="3" xs="12">
                              <div className="d-flex-row justify-content-lg-between">
                                <Row>
                                  <Col xl="12" md="12" xs="12">
                                    <span
                                      className="dealer-dashobard-card-text"
                                      style={{ color: "#495057" }}
                                    >
                                      SCV
                                    </span>
                                  </Col>
                                  <Col
                                    xl="12"
                                    md="12"
                                    xs="12"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="dealer-card-point mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <b>{warranty_data.scv_tyre || 0}</b>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Fragment>
              {
                totalSeconadryPoints > 0 &&
                <Col
                  xl="3"
                  className="margin-top-10 padding-left-5 padding-right-5"
                >
                  <Card className="height-100">
                    <CardBody className="d-flex-column">
                      <Row>
                        <Col xs="8" className="dashboard-page-card-title-big mb-2">
                          Warranty Trends
                      </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Chart
                            options={sparklineSecondary.options}
                            series={sparklineSecondary.series}
                            type="line"
                            width="100%"
                            height="100px"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-auto">
                        <Col xl="12" className="text-align-right mt-3">
                          <span className="Apr---Jun209">
                            {displayLabelsWarrantyTrends && displayLabelsWarrantyTrends.year}
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              }
              <Col
                xl="3"
                className="margin-top-10 padding-left-5 padding-right-5"
              >
                <Card className="height-100">
                  <CardBody className="d-flex-column">
                    <Row>
                      <Col xs="8" className="dashboard-page-card-title-big">
                        <div>Total Warrant Registered Customers</div>
                      </Col>
                      <Col xs="4" className="count-small">
                        {formatNumber(warranty_data.customer_count)}
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col xs="8" className="dashboard-page-card-title-big">
                        <div>Total Warranty Points</div>
                      </Col>
                      <Col xs="4" className="count-small">
                        {formatNumber(warranty_data.total_points)}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        xs="5"
                        className="text-align-left d-flex-column"
                      >
                        <span className="dashboard-page-card-title-small-gray ">
                          PCR Tyre
                          </span>
                        <span className="text-align-left card-count mt-2">
                          {formatNumber(warranty_data.pcr_wheeler_tyre)}
                        </span>
                      </Col>
                      <Col
                        xs="7"
                        className="text-align-left d-flex-column"
                      >
                        <span className="dashboard-page-card-title-small-gray ">
                          2 Wheeler Tyre</span>
                        <span className="text-align-left card-count mt-2">
                          {formatNumber(warranty_data.two_three_wheeler_tyre)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-auto">
                      <Col xl="12" className="text-align-right mt-3">
                        <span className="Apr---Jun209">Apr' 19 to till now</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Fragment> */}

                {/* {!totalSeconadryPoints > 0 && ( */}
                {(warranty_data.total_points === 0 || warranty_data.total_points === '0') && (
                  <Col
                    xl="6"
                    className="margin-top-10 padding-left-5 padding-right-5"
                  >
                    <Card className="height-100">
                      <CardBody className="no-warranty-points content-center">
                        <div style={{ margin: "auto" }}>
                          Start registering your customer for warranty and earn
                          points on each registartion.
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                <Col xl="7" className="margin-top-10">
                  {/* <div className="height-100 warranty-background"> */}
                  {/* <img src="images/group-26.svg" alt='' /> */}
                  <Card className="height-100 warranty-background">
                    <CardBody>
                      <Row className="height-100">
                        <Col xs="2" className="content-center">
                          <img src="images/group-22.svg" alt="" />
                        </Col>
                        <Col
                          xs="8"
                          className="content-center seconadary-warranty "
                          style={{
                            margin: "auto",
                            cursor: "pointer",
                            paddingLeft: "30px",
                          }}
                          onClick={this.redirectToSecondaryAbout.bind(this)}
                        >
                          Know how to earn points on warranty
                          {/* {warranty_data.total_points} */}
                        </Col>
                        <Col
                          xs="2"
                          className="content-center "
                          style={{ margin: "auto" }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src="images/group-18.svg"
                            alt=''
                            height="18px"
                            width="26px"
                            onClick={this.redirectToSecondaryAbout.bind(this)}
                          />
                        </Col>
                      </Row>
                      <div />
                    </CardBody>
                  </Card>
                  {/* </div> */}
                </Col>
              </Row>
              {/* } */}
            </>
          )}
          <br />
          <div className="dashboard-page-title">
            YEARLY OFFTAKE &nbsp;
            <span className="year-label">
              {" "}
              {this.state.year_name
                ? "(" + this.state.year_name + ")"
                : null}{" "}
            </span>
          </div>
          {/* <br /> */}
          <Row className="margin-top-10">
            <Card style={{ width: "100%" }}>
              <CardBody>
                <Chart
                  options={stackedChart.options}
                  series={stackedChart.series}
                  type="bar"
                  height="220px"
                />
              </CardBody>
            </Card>
          </Row>
          <br />
          <Row>
            <Col
              xl="4"
              className="margin-top-10 padding-left-5 padding-right-5"
            >
              <VideoComponent />
            </Col>
            <Col
              xl="4"
              className="margin-top-10 padding-left-5 padding-right-5"
            >
              <AboutJKAdvantage />
            </Col>
            <Col
              xl="4"
              className="margin-top-10 padding-left-5 padding-right-5"
            >
              <HowItWorks />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(DashboardWeb);
