import React from "react";
import { Col } from "reactstrap";

function GenerativeAICard({
  name,
  iconIMG,
  discription,
  iconStyleObj,
  setAskInput,
}) {
  return (
    <Col sm={12} md={9} lg={6} xl={4} className="p-0">
      <div className="aiCard" onClick={() => setAskInput(discription)}>
        <div className="AI-icon" style={iconStyleObj}>
          <img src={iconIMG} alt="meter" width="35px" />
        </div>
        <div className="AI-text">
          <p style={{ fontSize: "18px" }}>
            <b>{name}</b>
          </p>
          <p>{discription}</p>
        </div>
      </div>
    </Col>
  );
}

export default GenerativeAICard;
