
import React, { Fragment } from 'react';
import { Button, Modal, ModalBody, Input, Row, Col, Form } from 'reactstrap';
import Ionicons from 'react-ionicons';
import swal from 'sweetalert';

// services
import SpecialEngagementService from '../../services/SpecialEngagementService';
import ModalService from '../../services/ModalService';

// constants
import { messages } from '../../constants/constant.message';

// component
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

// css
import './SpeacialEngagement.css';

const { ERROR_MESSAGE } = messages.COMMON;

class SpeacialEngagement extends React.Component {

  state = {
    popupDetail: {},
    popupData: {},
    answers: [],
    error: null,
    isLoading: false
  }

  componentDidMount() {
    const { uuid } = this.props;

    if (uuid) {
      this.getPopupDetailFromId(uuid);
    } else {
      this.getPopupDetail();
    }
  }

  closeModal = () => {
    const { token } = this.props;
    if (this.props.history) {
      this.props.history.push(`/special-engagement?token=${token}&action=close`);
    } else {
      this.props.closeModal(true);
    }
  }

  getPopupDetail = () => {
    const { token } = this.props;
    this.setState({ isLoading: true });
    SpecialEngagementService.getPopupDetail(token)
      .then(data => {
        this.setState({ isLoading: false });
        if (data.data && data.data.data && data.data.data.popupData) {
          const popupData = data.data.data.popupData;
          if ((popupData.questions && Array.isArray(popupData.questions) && popupData.questions.length === 0) || (!popupData.questions)) {
            this.submitAnswer();
          }
          this.setState({ popupDetail: data.data.data, popupData });

        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        if (this.props.history) {
          this.props.history.push(`/special-engagement?token=${token}&action=error&msg=${message}`);
        } else {
          ModalService.openAlert("", message, 'error');
        }
      })
  }

  getPopupDetailFromId = (uuid) => {
    const { token } = this.props;
    this.setState({ isLoading: true });
    SpecialEngagementService.getPopupDetailFromId(token, uuid)
      .then(data => {
        this.setState({ isLoading: false });
        const detail = data.data && data.data.data[0] ? data.data.data[0] : {};
        if (detail && detail.answers && Array.isArray(detail.answers) && detail.answers.length > 0) {
          detail.popup_data.questions = detail.answers;
        }
        this.setState({ popupDetail: detail, popupData: detail.popup_data, answers: detail.answers })
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        if (this.props.history) {
          this.props.history.push(`/special-engagement?token=${token}&action=error&msg=${message}`);
        } else {
          ModalService.openAlert("", message, 'error');
        }
      })
  }


  submitAnswer = () => {
    const { token,
      // uuid: propsUuid
    } = this.props;
    const { popupDetail, popupData } = this.state;
    const { type, uuid } = popupDetail;
    let answers = null;
    if (popupData.questions && Array.isArray(popupData.questions) && popupData.questions.length > 0) {
      answers = popupData.questions;
      for (let i = 0; i < answers.length; i++) {
        let currentData = answers[i];
        if (currentData.type === 'checkbox') {
          if (currentData.answer && Array.isArray(currentData.answer) && currentData.answer.length === 0) {
            this.setState({ error: 'Request to answer all questions before you submit' });
            return;
          }
        }
        if (!currentData.answer) {
          this.setState({ error: 'Request to answer all questions before you submit' });
          return;
        }
      }
      const reqObj = {
        type,
        id: uuid,
        answers
      }

      SpecialEngagementService.submitPopupDetail(reqObj, token)
        .then(data => {
          let msg = null;
          this.setState({ error: null });
          // if (!propsUuid) {
          //   if (type === 'quiz') {
          //     msg = data.data && data.data.data && data.data.data.msg ? `${data.data.data.msg}\nTo view your score and questions again go to profile section and click "My Actions" section.` : msg;
          //   } else if (type === 'survey') {
          //     msg = `Thank your for participating in survey. We have noted your feedback. Incase you want to see your preferences, go to profile section and click "My Actions" section`;
          //   } else if (type === 'confirmation') {
          //     msg = `Thank you for selecting choice for the scheme. We have received your preference. Incase you want to change your preference, go to profile section and click "My Actions" section`;
          //   }
          // } else {
          if (type === 'quiz') {
            msg = data.data && data.data.data && data.data.data.msg ? `${data.data.data.msg}` : msg;
          } else if (type === 'survey') {
            msg = `Thank your for your participation. We have noted your valuable feedback.`;
          } else if (type === 'confirmation') {
            msg = data.data && data.data.data && data.data.data.msg ? `${data.data.data.msg}` : msg;
          }
          // }
          if (type === 'confirmation' || type === 'quiz' || type === 'survey') {
            swal(msg, {
              className: "sp-en-swal"
            })
              .then(() => {
                this.closeWebviewOrModal();
              })
              .catch(e => {
              })
          }
        })
        .catch(e => {
          const err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
          const message = err && err.message ? err.message : ERROR_MESSAGE;
          if (this.props.history) {
            this.props.history.push(`/special-engagement?token=${token}&action=error&msg=${message}`);
          } else {
            ModalService.openAlert("", message, 'error');
          }
        })
    }
  }

  closeWebviewOrModal = () => {
    if (this.props.history) {
      this.props.history.push('/special-engagement?action=close')
    } else {
      this.props.closeModal();
    }
  }

  // handle input change
  handleInputChange = (answer, question, index) => {
    const { popupData } = this.state;
    const { questions } = popupData;
    question.answer = answer;
    questions[index] = question;
    this.setState({ popupData });
    // this.handleValidation();
  }

  // handle radio button/mcq questions
  handleQuestionAnswers = (answer, question, index) => {
    const { popupData } = this.state;
    const { questions } = popupData;
    question.answer = answer;
    questions[index] = question;
    this.setState({ popupData });
  }

  // handle checkbox button/multiple selection questions
  handleCheckboxAnswers = (answer, question, index) => {
    const { popupData } = this.state;
    const { questions } = popupData;
    if (question.answer) {
      if (question.answer.includes(answer)) {
        const oIndex = question.answer.indexOf(answer);
        question.answer.splice(oIndex, 1);
      } else {
        question.answer.push(answer);
      }
    } else {
      question.answer = [answer];
    }
    questions[index] = question;
    this.setState({ popupData });
  }

  // start question methods
  prepareStars = (question, index, stars, answer) => {
    const ui = [];
    for (let i = 0; i < stars; i++) {
      if (answer && answer > i) {
        ui.push(<span className="cursor-pointer" onClick={() => { this.setStarAnswer(question, index, i + 1) }}>
          <Ionicons icon="md-star"></Ionicons>
        </span>);
      } else {
        ui.push(<span className="cursor-pointer" onClick={() => { this.setStarAnswer(question, index, i + 1) }}>
          <Ionicons icon="md-star-outline"></Ionicons>
        </span>);
      }
    }
    return ui;
  }

  setStarAnswer = (question, index, answer) => {
    const { popupData } = this.state;
    const { questions } = popupData;
    question.answer = answer;
    questions[index] = question;
    this.setState({ popupData });
  }
  // start question methods end

  prepareQuestions = (arr, type, isViewed) => {
    // const { uuid } = this.props;

    return arr && arr.map((q, index) => <Row
      className={type === 'confirmation' ? 'margin-top-20 question text-align-center' : 'margin-top-20 question'}>
      < Col >
        {type === 'confirmation' && <div>{(type === 'quiz' || type === 'survey') ? `${index + 1})` : null} {q.q}</div>}
        {type !== 'confirmation' && <div className="question-font">{(type === 'quiz' || type === 'survey') ? `${index + 1})` : null} {q.q}</div>}
        <div className="margin-top-5">
          {q.type === 'radio' && type === 'confirmation' && <Row className={type === 'confirmation' ? '' : null}>
            <Col>
              {q.options.map(o =>
                // <Col sm="4">
                <Fragment>
                  <div>
                    <label class="container-rb option-css">
                      {/* {o} */}
                      {/* <Row style={{ marginLeft: '5px' }}> */}
                      {/* <Col sm="1"> */}
                      <div>
                        <input
                          type="radio"
                          name={q.id}
                          value={o}
                          disabled={(isViewed === true) && (type === 'quiz') ? true : false}
                          onChange={() => this.handleQuestionAnswers(o, q, index)}
                          checked={q.answer === o}
                        />
                        <span class="checkmark-rb" style={{ borderWidth: '2px' }}></span>
                      </div>
                      {/* </Col> */}
                      {/* <Col style={{ paddingLeft: '0px', paddingRight: '16px' }}> */}
                      <div>
                        {o}
                        {/* </Col> */}
                        {/* </Row> */}
                      </div>
                    </label>

                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                  </div>
                </Fragment>
                // </Col>
              )}
            </Col>
          </Row>}

          {q.type === 'radio' && type !== 'confirmation' && <Row>
            {q.options.map(o => <Col sm={o.length > 10 ? "12" : "5"}>
              <label class="container-rb-quiz container-quiz-font">
                <input
                  type="radio"
                  name={q.id}
                  value={o}
                  disabled={(isViewed === true) && (type === 'quiz') ? true : false}
                  onChange={() => this.handleQuestionAnswers(o, q, index)}
                  checked={q.answer === o}
                />{' '}
                {o}
                <span class="checkmark-rb-quiz"></span>
              </label>
            </Col>)}
          </Row>}

          {(q.type === 'checkbox') && <Row>
            {q.options.map(o => <Col sm="5">
              <label className="container-checkbox">
                <input
                  type="checkbox"
                  name={q.id}
                  id="checkbox"
                  disabled={(isViewed === true) && (type === 'quiz') ? true : false}
                  checked={q.answer && q.answer.includes(o)}
                  onChange={() => this.handleCheckboxAnswers(o, q, index)}
                />{' '}
                {o}
                <span className="checkmark" />
              </label>
            </Col>)}
          </Row>}
          {(q.type === 'input') && <Row>
            <Col>
              <Input
                type="text"
                name={q.id}
                id={q.id}
                className="login-input"
                disabled={(isViewed === true) && (type === 'quiz') ? true : false}
                value={q.answer}
                onChange={(e) => this.handleInputChange(e.target.value, q, index)}
              />
            </Col>
          </Row>}
          {(q.type === 'star') && <Row>
            < Col >
              {this.prepareStars(q, index, q.stars, q.answer)}
            </Col>
          </Row>}
          {(isViewed === true) && q.correct_answer && <div>Answer: {q.correct_answer}</div>}
        </div>
      </Col >
    </Row >)
  }

  render() {
    const { uuid } = this.props;

    const { popupData, popupDetail, error, isLoading } = this.state;

    const { title, description, questions, image, note } = popupData;

    const { type,
      // answers = null,
      is_viewed } = popupDetail;
    // const { currentPassword, newPassword, confirmPassword, logoutFromOther } = controls;
    let prepareQuestions = null;
    // if (answers) {
    // prepareQuestions = this.prepareQuestions(answers, type);
    // } else {
    prepareQuestions = this.prepareQuestions(questions, type, is_viewed);
    // }

    return (
      <div>
        <Modal
          id="special-engagement"
          isOpen={this.props.show}
          toggle={uuid ? this.props.closeModal : null}
          centered
        >
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          {(isLoading === true) && <ModalBody style={{ minHeight: '300px' }}>
            <CustomSpinner></CustomSpinner>
          </ModalBody>}
          {isLoading === false && <ModalBody>
            <Form>
              {/* <Row className="modal-title-border padding-bottom-10">
                <Col className="modal-title">
                 
                </Col>
              </Row> */}
              {/* <div className="text-align-right" onClick={this.closeModal}>
                <span className="cursor-pointer">
                  <Ionicons icon="md-close" color="#ababab"></Ionicons>
                </span>
              </div>

              {title && <Row>
                <Col className="text-align-center margin-top-20 title">
                  <div>{title}</div>
                </Col>
              </Row>} */}
              <Row>
                <Col lg={12} className="d-flex flex-wrap justify-content-between">
                  {title && <div className="text-align-center title">{title}</div>}
                  <Ionicons icon="md-close" className="cursor-pointer" color="#ababab" onClick={this.closeModal} />
                </Col>
              </Row>
              {description && <Row>
                <Col className="text-align-center margin-top-20 description">
                  <div>{description}</div>
                </Col>
              </Row>}
              {image && <Row>
                <Col className="text-align-center margin-top-20 description">
                  <div><img className="img" src={image} alt="" /></div>
                </Col>
              </Row>}
              {note && <Row>
                <Col className="text-align-center margin-top-20 note">
                  <div>{note}</div>
                </Col>
              </Row>}

              {prepareQuestions}
              {error && <div className="error text-align-center margin-top-20">{error}</div>}
              {questions && Array.isArray(questions) && questions.length > 0 && (!(is_viewed === true && type === 'quiz')) && <div style={{ textAlign: 'center' }} className={error ? null : "margin-top-20"}>
                <Button
                  // type="submit"
                  className="mb-1 mr-1 btn-pill Rectangle-button send-button"
                  onClick={this.submitAnswer}
                  color="warning"
                >
                  Submit
                </Button>
              </div>}
            </Form>
          </ModalBody>}

        </Modal>
      </div>
    );
  }
}


export default SpeacialEngagement; 