import { newRequest } from './Request';
import { routes } from '../constants/constant.route';

const { GEL_ALL_ORDER, NOTIFY_MERCHANDISE_DISPATCH, UPDATE_ORDER, TRACK_ORDER } = routes.MERCHANDISE_REPORT;

const API_URL = process.env.REACT_APP_API_URL;

export default class MerchandiseService {

  static notifiyMerchandiseDispatch(body) {
    return newRequest('POST', `${API_URL}${NOTIFY_MERCHANDISE_DISPATCH}`, null, body, null);
  }

  static getMerchandiseOrders(filters = null, body = {}) {
    return newRequest('GET', `${API_URL}${GEL_ALL_ORDER}`, filters, body, null);
  }

  static updateMerchandiseOrder(orderId, body = {}) {
    return newRequest('PUT', `${API_URL}${UPDATE_ORDER}/${orderId}`, null, body, null);
  }

  static updateMerchandiseOrderAmuont(body = {}) {
    return newRequest('PUT', `${API_URL}${UPDATE_ORDER}/update-order-amount`, null, body, null);
  }

  static trackOrder(awbNo) {
    let qp = `?`;
    if (awbNo) {
      qp += `awb=${awbNo}`
    }
    return newRequest('GET', `${API_URL}${TRACK_ORDER}${qp}`, null, null, null);
  }
}