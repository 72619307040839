import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Input } from "reactstrap";
import Sidebar from "react-sidebar";
import Ionicon from "react-ionicons";
import swal from "sweetalert";
import lodash from "lodash"; // library added for debounce functionality
import CustomerManagementFilterNew from "../CustomerManagementFilterNew/CustomerManagementFilterNew";

import Pagination from "../../components/Pagination/Pagination";
import AddNewGroup from "../../components/AddNewGroup/AddNewGroup";
import EditGroupDetail from "../../components/EditGroupDetail/EditGroupDetail";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { withRouter } from "react-router";
import CustomerManagementService from "../../services/CustomerManagementService";
import ModalService from "../../services/ModalService";
// import MappleToolTip from "reactjs-mappletooltip";
import ConfirmModal from "../../modal/ConfirmModal/ConfirmModal";
import Moment from 'react-moment';

import { downlodFile } from "../../utils/utils";

import { messages } from "../../constants/constant.message";

import "./ManageGroup.css";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import Storage from "../../services/Storage";
import { Permissions } from "../../constants/constant.permissions";
const { ERROR_MESSAGE } = messages.COMMON;

const pageSize = 10;

class ManageGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      customerManagementFilterStates: null,
      sidebarOpenGroupFilter: false,
      newGroupSidebarOpen: false,
      editGroupSidebarOpen: false,
      groupData: [],
      currentEditGroup: null,
      groupCount: 0,
      groupPage: 1,
      groupTotalPages: 1,
      isDeleteGroupModalOpen: false,
      isLoading: false,
      isEdit: false,
      noGroupsFound: false,
      controls: {
        search: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          filter_data: {},
          filterControls: {},
        },
      },
    };
    this.childAddNewGroup = React.createRef();
    this.childEditNewGroup = React.createRef();
    this.searchGroup = lodash.debounce(this.searchGroup, 500);
  }

  componentDidMount() {
    GoogleAnalticsService.pageView('Manage Group View');
    GoogleAnalticsService.event('Manage Group', 'Group List Create Group');
    this.getGroups(1);
  }

  handleInputChange = (e) => {
    /**Common method for the input change which sets the value based on "name" attribute */
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.searchGroup(controlValue);
  };

  onCloseNewGroupSidebar = (flag = false) => {
    document.body.style.overflow = "auto";
    let { controls } = this.state;
    if (flag) {
      this.setState({ groupPage: 1 });
      this.getGroups(1);
      controls.search.value = "";
    }
    this.setState({
      newGroupSidebarOpen: false,
      isEdit: false,
      currentEditGroup: null,
      controls,
    });
  };

  reloadGroups = () => {
    this.getGroups(this.state.groupPage);
  };

  openNewGroupSidebar = (isEdit, groupData) => {
    /**Open group side bar to create the new group */
    GoogleAnalticsService.event('Manage Group', 'Manage Group Add Group Click');
    document.body.style.overflow = "hidden";
    this.setState({
      newGroupSidebarOpen: true,
      isEdit,
      currentEditGroup: groupData,
      filter_data: {},
      customerManagementFilterStates: null
    });
  };

  onCloseEditGroupSidebar = (flag = false) => {
    document.body.style.overflow = "auto";
    let { controls } = this.state;
    controls.search.value = "";
    this.setState({ editGroupSidebarOpen: false, controls });
  };

  openEditGroupSidebar = (groupData) => {
    document.body.style.overflow = "hidden";
    this.setState({
      currentEditGroup: groupData,
      editGroupSidebarOpen: true,
      filter_data: {},
      customerManagementFilterStates: null
    });
  };

  getGroups = (page = 1, search = null) => {
    this.setState({ isLoading: true });
    /**Service which gets the group data when comoponent is getting mount with query parameters */
    CustomerManagementService.getGroups({ page, pageSize, search })
      .then((res) => {
        let { groupData, groupCount, groupTotalPages } = this.state;
        this.setState({ isLoading: false });
        if (
          res.data.data &&
          res.data.data.groups &&
          res.data.data.groups.length > 0
        ) {
          groupData = res.data.data.groups;
          groupCount = res.data.data.count;
          groupTotalPages = Math.ceil(groupCount / pageSize);
          this.setState({
            noGroupsFound: false,
          });
        } else {
          groupData = [];
          this.setState({
            noGroupsFound: true,
          });
        }
        this.setState({ groupData, groupCount, groupTotalPages });
      })
      .catch((e) => {
        this.setState({ isLoading: false });

        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        //this.setState({ isLoading: false });
      });
  };

  handlePageChangeGroup = (page) => {
    // const { search_customer_from_all } = this.state.controls;
    /**Method called when changing page */
    this.setState({ groupPage: page });
    this.getGroups(page, this.state.controls.search.value);
  };

  deleteGroup = (groupId) => {
    this.setState({ isLoading: true });
    CustomerManagementService.deleteGroup(groupId)
      .then((data) => {
        this.setState({ isLoading: false });
        this.setState({
          groupPage: 1,
          isDeleteGroupModalOpen: false,
          selectedGroupToDelete: null,
        });
        this.getGroups(1);
        if (data.data.message) {
          swal({
            title: "Delete Group",
            text: data.data.message,
            icon: "success",
            type: "success",
          });
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  openDeleteGroupModal = (groupId) => {
    /**Delete Group modal open when clicked delete icon from manage group table*/
    swal({
      title: "Delete Group",
      text: "Are you sure, you want to delete group ?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.deleteGroup(groupId);
      }
    });
  };
  closeDeleteGroupModal = () => {
    this.setState({
      isDeleteGroupModalOpen: false,
      selectedGroupToDelete: null,
    });
  };

  downloadGroupCustomers = (groupId) => {
    // this.setState({ : true });
    this.setState({ isLoading: true });
    /** Service calling to get the data for the particular group */
    CustomerManagementService.getGroupCustomers(null, null, groupId, true, null)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res && res.data && res.data.data && res.data.data.file) {
          /**And passing the file from the response to the function to dwnload it.*/
          downlodFile(res.data.data.file);
        }
      })
      .catch((e) => {
        // this.setState({ isGroupCustomerLoading: false })
        this.setState({ isLoading: false });
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        //this.setState({ isLoading: false });
      });
  };

  searchGroup = (search) => {
    this.setState({ groupPage: 1 });
    this.getGroups(1, search);
  };

  sidebarOpenGroupFilter = (e, filterStates) => {
    this.setState({
      sidebarOpenGroupFilter: true,
      customerManagementFilterStates: filterStates,
    });
  };

  onCloseSidebarGroupFilter = (e) => {
    this.setState({
      sidebarOpenGroupFilter: false,
    });
  };

  applyFilter = (filterStates) => {
    this.setState({
      customerManagementFilterStates: filterStates,
      sidebarOpenGroupFilter: false,
    });
  };

  resetFilter = () => {
    this.setState({
      filter_data: {},
      customerManagementFilterStates: null,
      sidebarOpenGroupFilter: false,
    });
  };

  goToCampaign = (a, b) => {
    this.props.history.push({
      pathname: "/campaign/create",
      manageGroup: a,
    });
  };

  render() {
    console.log("dfgddfd", this.state.userDetail)
    const {
      groupData,
      currentEditGroup,
      groupTotalPages,
      groupCount,
      groupPage,
      selectedGroupToDelete,
      controls,
      customerManagementFilterStates,
      userDetail
    } = this.state;
    const { search } = controls;
    let prepareGroupRaws = <tr></tr>;
    {/**Displays the table When group Data is present */ }
    if (groupData.length > 0) {
      // this.setState({
      //   noGroupsFound: false
      // });
      prepareGroupRaws = groupData.map((g) => (
        <tr key={g.group_id}>
          <td>{g.group_name}</td>
          <td>{g.member_count}</td>
          <td>
            <Moment format="DD MMM'YY">
              {g.created_at}
            </Moment>
          </td>
          <td>
            {userDetail && userDetail.permissions &&
              userDetail.permissions.includes(
                Permissions.CAMPAIGN
              ) ?
              <>
                <span
                  onClick={this.goToCampaign.bind(this, g)}
                  title="Create Campaign"
                >
                  <Ionicon
                    icon="md-text"
                    fontSize="18px"
                    color="#fdbb1f"
                    className="cursor-pointer"
                  />
                </span>{" "}
              </>
              : ""}
            {!['inquiry customers', 'service customers', 'tyre purchase customers'].includes(g.group_name.toLowerCase()) &&
              <>
                &nbsp;&nbsp;

                <span
                  onClick={this.openEditGroupSidebar.bind(this, g)}
                  title="Add or remove group members"
                >
                  <Ionicon
                    icon="md-create"
                    fontSize="18px"
                    color="#fdbb1f"
                    className="cursor-pointer"
                  />
                </span>{" "}
                &nbsp;&nbsp;
                <span
                  onClick={this.openDeleteGroupModal.bind(this, g.group_id)}
                  title="Remove group"
                >
                  <Ionicon
                    icon="md-trash"
                    fontSize="18px"
                    color="#fdbb1f"
                    className="cursor-pointer"
                  />
                </span>
                &nbsp;&nbsp;
              </>
            }
            {/* {g.member_count > 0 && (
              <span
                onClick={this.downloadGroupCustomers.bind(this, g.group_id)}
                title="Export group member"
              >
                <Ionicon
                  icon="md-download"
                  fontSize="18px"
                  color="#fdbb1f"
                  className="cursor-pointer"
                />
              </span>
            )} */}
          </td>
        </tr>
      ));
    } else {
      {/**If their is no group present */ }
      if (this.state.controls.search.value === "") {
        prepareGroupRaws = (
          <tr>
            <td colSpan="4" className="text-align-center">
              Currently, there is no group to display
            </td>
          </tr>
        );
      } else {
        {/**If their is no result according to the search bar  */ }
        prepareGroupRaws = (
          <tr>
            <td colSpan="4" className="text-align-center">
              We can not find any records matching your search criteria. Please
              change search criteria and try again.
            </td>
          </tr>
        );
      }
    }

    // const mappleConfig = {
    //   direction: "left",
    //   backgroundColor: "#f1f4f7",
    //   float: false,
    //   fontFamily: "Helvetica",
    // };

    return (
      <div id="manage-group" style={{ width: "85%" }}>
        {!this.props.fromComponent && (
          <Row>
            <Col>
              <div className="page-title">Manage Groups</div>
            </Col>
            {/* <Col className="text-align-right">
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-save-button"
                color="warning"
                onClick={this.openNewGroupSidebar.bind(this, false)}
              >
                Add Group
              </Button>
            </Col> */}
          </Row>
        )}
        {this.props.fromComponent && (
          <Row>
            <hr
              style={{
                border: "solid 1px #e0e2e4",
                width: "100%",
                margin: "13px 1rem",
              }}
            />
            {/* <Col className="text-align-right">
            <span className="link-warm-blue" onClick={this.openNewGroupSidebar.bind(this, false)}>
              Add New Customer
          </span>
          </Col> */}
          </Row>
        )}
        <br />
        {/* <br /><br /> */}
        {/**Opens a sidebar when create Group is clicked */}
        {this.state.newGroupSidebarOpen && (
          <Sidebar
            sidebar={
              <>
                <AddNewGroup
                  ref={this.childAddNewGroup}
                  onCloseSidebar={this.onCloseNewGroupSidebar}
                  sidebarOpenGroupFilter={this.sidebarOpenGroupFilter}
                  from="manage-group"
                  filter={customerManagementFilterStates}
                  groupData={this.state.filter_data}
                />
              </>
            }
            open={this.state.newGroupSidebarOpen}
            sidebarClassName="new-group-modal"
            pullRight
            styles={{
              sidebar: {
                background: "white",
                height: "90%",
                top: "60px",
                overflowX: "hidden",
              },
            }}
            children={<span></span>}
          />
        )}
        {/**Opens a edit group in sidebar */}
        {this.state.editGroupSidebarOpen && (
          <Sidebar
            sidebar={
              <>
                <EditGroupDetail
                  ref={this.childEditNewGroup}
                  groupData={currentEditGroup}
                  onCloseSidebar={this.onCloseEditGroupSidebar}
                  reloadGroups={this.reloadGroups}
                  sidebarOpenGroupFilter={this.sidebarOpenGroupFilter}
                  filter={customerManagementFilterStates}
                // filter_data={this.state.filter_data}
                />
              </>
            }
            open={this.state.editGroupSidebarOpen}
            sidebarClassName="edit-group-modal"
            pullRight
            styles={{
              sidebar: { background: "white", height: "90%", top: "60px" },
            }}
            children={<span></span>}
          />
        )}
        {/**Opens the side bar with filter option */}
        {this.state.sidebarOpenGroupFilter && (
          <Sidebar
            sidebar={
              <>
                <CustomerManagementFilterNew
                  ref={this.childCustomerManagementFilter}
                  applyFilter={this.applyFilter}
                  resetFilter={this.resetFilter}
                  onCloseSidebar={this.onCloseSidebarGroupFilter}
                  from='manage-group'
                  // getFilterControls={this.state.filterControls}
                  filter={customerManagementFilterStates}
                />
              </>
            }
            open={this.state.sidebarOpenGroupFilter}
            sidebarClassName="customer-management-filter"
            pullRight
            styles={{
              sidebar: { background: "white", height: "90%", top: "60px" },
            }}
          />
        )}
        <ConfirmModal
          show={this.state.isDeleteGroupModalOpen}
          closeModal={this.closeDeleteGroupModal}
          title="Remove Group"
          message="Are you sure you want to remove group?"
          actionHandler={this.deleteGroup.bind(this, selectedGroupToDelete)}
        ></ConfirmModal>
        {/* <Row>
          <Col sm="6" className="text-align-right" onClick={this.openNewGroupSidebar}>
            <Ionicon icon="md-add" fontSize="18px" color="#fdbb1f" />
          </Col>
          <Col sm="6" className="text-align-right" onClick={this.openEditGroupSidebar}>
          </Col>
        </Row> */}

        <Row>
          <Col sm={this.props.fromComponent ? "12" : "6"}>
            {this.state.isLoading && <CustomSpinner></CustomSpinner>}
            {/**View loaded when their is no group present */}
            {this.state.noGroupsFound &&
              this.state.controls.search.value === "" ? (
              <Card>
                <CardBody>
                  <Col
                    style={{ fontSize: "18px", color: "rgb(140, 136, 136)" }}
                  >
                    Currently there are no groups created. You can create new
                    group by following the instructions given below.{" "}
                  </Col>
                  <br />
                  <Col>
                    <b>How to create a group?</b>
                  </Col>
                  <div style={{ marginTop: "10px" }} />
                  <Row style={{ marginLeft: "10px" }}>
                    <span class="custom-dot"></span>{" "}
                    <Col>
                      To add New Group from mobile app, select 'create new
                      group'.
                    </Col>
                  </Row>
                  <div style={{ marginTop: "10px" }} />
                  <Row style={{ marginLeft: "10px" }}>
                    <span class="custom-dot"></span>{" "}
                    <Col>
                      Select group name of your choice and add customers by
                      clicking on 'Select Customers'.
                    </Col>
                  </Row>
                  <div style={{ marginTop: "10px" }} />
                  <Row style={{ marginLeft: "10px" }}>
                    <span class="custom-dot"></span>{" "}
                    <Col>
                      Select 'Add To Group' to add those customers to the group.
                    </Col>
                  </Row>
                  <div style={{ marginTop: "10px" }} />
                  <div style={{ marginTop: "10px" }} />
                  <Col sm="8" className="mt-3">
                    <Button
                      className="rectangleButton"
                      onClick={this.openNewGroupSidebar.bind(this, false)}
                    >
                      <b>Create New Group</b>
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            ) : (
              <Card>
                {/** Default View loaded when customers from the group are loaded*/}
                <CardBody>
                  <Row>
                    <Col sm={this.props.fromComponent ? "3" : "6"}>
                      <Input
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Search by group name"
                        className="login-input"
                        value={search.value}
                        onChange={this.handleInputChange}
                      />
                      <div>
                        <img
                          src="images/search.svg"
                          className="report-search icon-width"
                          alt=''
                        />
                      </div>
                    </Col>
                    <Col
                      sm={this.props.fromComponent ? "9" : "6"}
                      className="mt-3"
                      style={{
                        float: "right",
                      }}
                    >
                      <Button
                        className="rectangleButton"
                        onClick={this.openNewGroupSidebar.bind(this, false)}
                        style={{
                          float: "right",
                          marginTop: "-13px",
                        }}
                      >
                        <b>Create New Group</b>
                      </Button>
                    </Col>
                    {/* <div
                      className="text-align-right"
                      // style={{ marginLeft: "130px", marginTop: "-3px" }}
                    >
                      {this.props.fromComponent && (
                        <div
                          className="f-flex-row"
                          style={{
                            marginLeft: "131px",
                            marginTop: "7px"
                          }}
                        >
                          <MappleToolTip {...mappleConfig}>
                            <div className="f-flex-row">
                              <img
                                src="images/info1.svg"
                                 alt=''
                                onClick={this.getHelp}
                                className="download-report-icon cursor-pointer margin-left-10"
                              />
                              &nbsp;&nbsp;
                              <span
                                className="link-warm-blue cursor-pointer"
                                onClick={this.getHelp}
                              >
                                Help Guide
                              </span>
                            </div>
                            <Row className="otherlangcontent-wrapps">
                              <Col lg="10" sm="12" md="10" xs="12">
                                <div
                                  className="otherlangcontent"
                                  style={{
                                    width: "100%",
                                    color: "black",
                                    textAlign: "left",
                                    fontSize: "12px"
                                  }}
                                >
                                  Currently there are no customers registered
                                  with your store. Use ‘OPTIN’ facility, Bulk
                                  Upload features to add customers to your
                                  account.
                                </div>
                              </Col>
                            </Row>
                          </MappleToolTip>

                          {/* <img
                            src="images/plus_blue.svg"
                             alt=''
                            onClick={this.openNewGroupSidebar.bind(this, false)}
                            className="download-report-icon cursor-pointer margin-left-10"
                          />
                          &nbsp;&nbsp;
                          <span
                            className="link-warm-blue cursor-pointer"
                            onClick={this.openNewGroupSidebar.bind(this, false)}
                          >
                            Add New Group1
                          </span> */}
                    {/* </div>
                      )}
                    </div>  */}
                  </Row>
                  <table className="width-100 margin-top-10">
                    <thead>
                      <tr>
                        <th>Group Name</th>
                        <th>Members</th>
                        <th>Created On</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>{prepareGroupRaws}</tbody>
                  </table>
                  {groupTotalPages > 1 && (
                    <Pagination
                      margin={2}
                      page={groupPage}
                      count={groupTotalPages}
                      pageSize={pageSize}
                      totalRecords={groupCount}
                      onPageChange={this.handlePageChangeGroup}
                    ></Pagination>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(ManageGroup);
