import React, { Component } from 'react';
import {
  Row, Col
} from 'reactstrap';
import classnames from 'classnames';
class SecondaryAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategory: ''
    };
    this.toggle = this.toggle.bind(this);
    // this.getAboutDetail();
  }
  toggle(tab) {
    if (this.state.activeCategory !== tab) {
      this.setState({
        activeCategory: tab
      });
    }
  }
  render() {
    let secondaryPointsAndOfftake = this.props.secondaryPointsAndOfftake || null;
    // let pointsEarningProcess = this.props.pointsEarningProcessSecondary;
    let arr = ['point-structure-card', 'mr-2', 'mb-3'];
    return (
      <>
        <Col md="12" className="secondary-point-structure mt-2">
          <div className="Point-Structure-for mb-2">
            Warranty Registration Process
          </div>
          <Col md="12" style={{ paddingLeft: '0' }}>

            <div>
              <ul className="Enrollment-ul">
                {
                  secondaryPointsAndOfftake.notes && secondaryPointsAndOfftake.notes.map((item) => (
                    <li className="enroll-li">
                      {item}
                    </li>
                  ))
                }
              </ul>
            </div>
          </Col>
          <br />
          <Row className="point-structure-wrapp" style={{ flexWrap: 'wrap' }}>
            {secondaryPointsAndOfftake && secondaryPointsAndOfftake.pointStructure && secondaryPointsAndOfftake.pointStructure.length > 0 ?
              secondaryPointsAndOfftake.pointStructure.map((item, i) => (
                <Col className="secondary-point-struct-wrapp">
                  <span className="Point-Structure-for">Point Structure for {item.type}</span>
                  <Col className={classnames(arr)} style={{ paddingRight: '0px', marginTop: '10px' }} onClick={() => { this.toggle(item.type); }}>
                    <div className="point-structure-type-wrapp">
                      <span className="point-structure-type">
                        {item.type}
                      </span>
                      {
                        item.image1 && <img alt='' src={item.image1} className="point-structure-img" />
                      }
                    </div>
                    <div className="point-structure-data">
                      <span className="Tyre">Points / Registration</span>
                      <div className="point-structure-point-wrapp">
                        <div className="d-flex-row">
                          <span className="point-structure-point">
                            {item.pointsRange[0]}
                          </span>

                        </div>
                        {
                          item.image2 && <img alt='' src={item.image2} className="point-structure-img" />
                        }
                      </div>
                    </div>
                  </Col>
                  {/* {
                    item.notes && <ul className="Enrollment-ul" style={{ maxWidth: '250px' }}>
                      {
                        item.notes.map((note) => (
                          <li className="enroll-li">
                            {note}
                          </li>
                        ))
                      }
                    </ul>
                  } */}
                </Col>
              ))
              : ""}
          </Row>
          <br />

          {/* <Col md="12" style={{ paddingLeft: '0' }}>
            <div className="Point-Structure-for mb-2">
              {pointsEarningProcess.title}
            </div>
            <div>
              <ul className="Enrollment-ul">
                {
                  pointsEarningProcess.data && pointsEarningProcess.data.map((item) => (
                    <li className="enroll-li">
                      {item}
                    </li>
                  ))
                }
              </ul>
            </div>
          </Col> */}
          {/* <Row className="point-structure-wrapp">
                        {
                           
                        }
                    </Row> */}
        </Col>
      </>
    );
  }
}

export default SecondaryAbout;