import { request } from './Request';
import { routes } from '../constants/constant.route';

const API_URL = process.env.REACT_APP_API_URL;

export default class HeadOfficeService {

  static getHeadOfficeReport(type, ref, month, year) {
    let qp = ``;

    if (type) {
      qp += `type=${type}&`
    }
    if (ref) {
      qp += `ref=${ref}&`
    }
    if (month) {
      qp += `m=${month}&`
    }
    if (year) {
      qp += `y=${year}&`
    }
    return request('GET', `${API_URL}${routes.DASHBOARD.HEAD_OFFICE_REPORT}?${qp}`, null, null, null);
  }

  static geImmediateDealer(type, ref) {
    let qp = ``;

    if (type) {
      qp += `type=${type}`
    }
    if (ref) {
      qp += `&ref=${ref}`
    }
    return request('GET', `${API_URL}${routes.USER.GET_IMMEDIATE_DEALER}?${qp}`, null, null, null);
  }


  static downloadExcelHo(type, ref, className, month, year) {
    let qp = ``;

    if (type) {
      qp += `type=${type}&`
    }
    if (ref) {
      qp += `ref=${ref}&`
    }
    if (className) {
      qp += `class=${className}&`
    }
    if (month) {
      qp += `m=${month}&`
    }
    if (year) {
      qp += `y=${year}&`
    }
    return request('GET', `${API_URL}${routes.DASHBOARD.DOWNLOAD_EXCEL_HO}?${qp}`, null, null, null);
  }
}