import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Ionicon from "react-ionicons";

import "./DetailCard.css";
import { useLocation } from "react-router-dom";
import { Roles } from "../../constants/constant.role";

const DetailCard = (props) => {
  const location = useLocation();
  const cols = props.cardObj.fields.map((f) => (
    <>
      {f.sender_id_note ? (
        <Col xl="4">
          <div> {f.title}</div>
          {f.value && (
            <div className={f.class}>
              <b>{f.value}</b>
            </div>
          )}
          {!f.value ? (
            <div>
              <b>--</b>
            </div>
          ) : (
            <div className="d-flex mt-1">
              <span className="profile-sender-id-note">{f.sender_id_note}</span>
            </div>
          )}
          <br />
        </Col>
      ) : (
        <Col xl={props.userRole === Roles.DEALER_ROLE ? "3" : "3"} md="12" xs="12">
          <div> {f.title}</div>
          {f.value && (
            <div className={f.class}>
              <b>{f.value}</b>
            </div>
          )}
          {!f.value && (
            <div>
              <b>--</b>
            </div>
          )}
          <br />
        </Col>
      )}
    </>
  ));
  return (
    <Card id="detail-card">
      <CardBody>
        <Row className="header-border">
          <Col xl="10" md="10" xs="10">
            <b>{props.cardObj.title}</b>
          </Col>
          {location && location.pathname === "/user-updates" ? '' :
            <Col
              xl="2"
              md="2"
              xs="2"
              className="text-align-right cursor-pointer"
              onClick={props.makeEditActive.bind(this, props.cardObj.edit, true)}
            >
              <Ionicon icon="md-create" fontSize="24px" color="#fdbb1f" />
            </Col>
          }
        </Row>
        {/* <hr /> */}
        <Row className="content-padding">{cols}</Row>
      </CardBody>
    </Card>
  );
};

export default DetailCard;
