import React from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import Ionicons from 'react-ionicons';
import "./PosterPreviewModal.css";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

class PosterPreviewModal extends React.Component {
  state = {
    imagesToPreview: null,
    imageTitle: null,
    isLoading: false,
  };

  componentDidMount() {
    let imagesToPreview = this.props.data.imagesToPreview;
    let imageTitle = this.props.data.imageTitle
    this.setState({ imagesToPreview, imageTitle });
  }

  closeModal = () => {
    this.props.closeModal(true);
  };

  render() {
    const { imagesToPreview, imageTitle } = this.state;
    return (
      <div>
        <Modal id="poster-preview-modal" isOpen={this.props.show} toggle={this.props.closeModal} centered>
          {this.state.isLoading && <CustomSpinner />}
          <ModalBody>
            <Row className="modal-title-border padding-bottom-10">
              <Col className="modal-title">{imageTitle}</Col>
              <Col sm="2" className="text-align-right">
                <span className="cursor-pointer" onClick={this.closeModal}>
                  <Ionicons icon="md-close" color="#fdbb1f"></Ionicons>
                </span>
              </Col>
            </Row>
            <div></div>
            {
              <Row style={{ paddingTop: '16px' }}>
                <Col>
                  <img src={imagesToPreview} className="poster-img mt-auto mb-auto" alt="" />
                </Col>
              </Row>
            }
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default PosterPreviewModal;
