import { request } from "./Request";
import { routes } from "../constants/constant.route";

const {
  SALES_DATA_UPLOAD,
  MIGRATE_DATA,
  MIGRATE_QUARTER_DATA,
  MOBILITY_GRN_DATA_UPLOAD,
  MIGRATE_YEAR_DATA
} = routes.SALES_DATA_REPORT;
const API_URL = process.env.REACT_APP_API_URL;

export default class SalesDataUploadService {
  static SalesDataUpload(obj) {
    return request("POST", `${API_URL}${SALES_DATA_UPLOAD}`, null, obj, null);
  }

  static MobilityGRNDataUpload(obj) {
    return request(
      "POST",
      // "http://192.168.4.113:5000/api/v1/mobility-fleet-sales/upload-mobility-fleet-sales-data",
      `${API_URL}${MOBILITY_GRN_DATA_UPLOAD}`,
      null,
      obj,
      null
    );
  }

  static migrateDataUpload(obj) {
    return request("POST", `${API_URL}${MIGRATE_DATA}`, null, obj, null);
  }

  static migrateQuarterData(obj) {
    return request(
      "POST",
      `${API_URL}${MIGRATE_QUARTER_DATA}`,
      null,
      obj,
      null
    );
  }

  static migrateYearData(obj) {
    return request(
      "POST",
      `${API_URL}${MIGRATE_YEAR_DATA}`,
      null,
      obj,
      null
    );
  }
}
