import React, { Component, Fragment } from "react";
import { Row, Col, Card, CardBody, FormGroup } from "reactstrap";
import lodash from "lodash"; // library added for debounce functionality
import Ionicon from "react-ionicons";

//components
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import SingleDealerPrimaryReportTable from "../../components/SingleDealerPrimaryReportTable/SingleDealerPrimaryReportTable";
import Pagination from "../../components/Pagination/Pagination";
import AutoSuggestionDealer from "../../components/AutoSuggestionDealer/AutoSuggestionDealer";

// services
import DashboardService from "../../services/DashboardService";
import PrimaryPointsReportService from "../../services/PrimaryPointsReportService";
import StorageService from "../../services/Storage";

// css
import "./PrimaryPointAllDealerReport.css";

import {
  formatNumber,
  generateRandomString
} from "../../utils/utils";
import { Roles } from "../../constants/constant.role";

const pageSize = 10;

class PrimaryPointAllDealerReport extends Component {
  state = {
    height: window.innerHeight,
    width: window.innerWidth,
    isLoading: false,
    page: 1,
    totalPages: 0,
    pageSize: 10,
    totalPoints: 0,
    totalOfftake: 0,
    earnedPointQ1: 0,
    totalOfftakeQ1: 0,
    earnedPointQ2: 0,
    totalOfftakeQ2: 0,
    dealers: [],
    totalDealer: 0,
    search: "",
    currentUser: null,
    manageUserLevel: [],
    dealerRole: null,
    rolesToDrillDown: [],
    randomKey: null,
    displayLabels: null,
    yearOptions: [],
    year: null,
    year_name: null,
  };

  constructor() {
    super();
    this.searchReport = lodash.debounce(this.searchReport, 500);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeWindow);
    this.filterAPI();
    // const { page } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    // const id = query.get("id");
    // let index = query.get("i");
    let year = query.get("y");
    // let manageUserLevel = [];
    // let currentUser = null;
    // this.getDisplayLabel();
    // if (id && index) {
    //   index = isNaN(index) === false ? parseInt(index) : 0;
    //   manageUserLevel = StorageService.getNavigationLevelDetail(id);
    //   // this.setState({ manageUserLevel: manageUserLevel });
    //   this.getDataFromNavigation(index, manageUserLevel);
    // } else {
    //   this.getAllDealerReport(page);
    // }
    // if (id) {
    //   this.setState({ randomKey: id });
    //   manageUserLevel = StorageService.getNavigationLevelDetail(id);
    //   const length = manageUserLevel.length;
    //   if (length > 0) {
    //     currentUser = manageUserLevel[length - 2];
    //     const uuid = currentUser && currentUser.uuid ? currentUser.uuid : null
    //     this.getAllDealerReport(page, null, false, uuid);
    //   } else {
    //     this.getAllDealerReport(page);
    //   }
    // } else {
    //   this.getAllDealerReport(page);
    // }
    const userDetail = StorageService.getUserDetail();
    const { type } = userDetail;
    this.setState({ dealerRole: type, year });
    this.generateRolesToDrillDown(type);
    // const { total_card, quarter_card, provosional_card, dealer_card, total_dealers } = reportRespone.message;
    // const totalPoints = total_card[0].total_points;
    // const totalOfftake = total_card[0].total_offtake;
    // const earnedPointQ1 = quarter_card[0].points;
    // const totalOfftakeQ1 = quarter_card[0].total_sales;
    // const earnedPointQ2 = provosional_card[0].points;
    // const totalOfftakeQ2 = provosional_card[0].total_offtake;
    // const totalDealer = total_dealers;
    // const dealers = dealer_card;
    // const totalPages = Math.ceil(totalDealer / pageSize);
    // this.setState({ totalPoints, totalDealer, totalOfftake, earnedPointQ1, earnedPointQ2, totalOfftakeQ1, totalOfftakeQ2, dealers, totalPages });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  generateRolesToDrillDown = (dealerRole) => {
    let rolesToDrillDown = [];
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      rolesToDrillDown = ["ZM", "SM", "AM", "dealer"];
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE || dealerRole === Roles.ZTM_ROLE) {
      rolesToDrillDown = ["SM", "AM", "dealer"];
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      rolesToDrillDown = ["AM", "dealer"];
    } else if (dealerRole === Roles.AREA_MANAGER_ROLE) {
      rolesToDrillDown = ["dealer"];
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      rolesToDrillDown = ["dealer"];
    }
    this.setState({ rolesToDrillDown });
  };

  resizeWindow = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  getAllDealerReport = (page, search, isSearch, uuid = null) => {
    this.setState({ isLoading: true });
    PrimaryPointsReportService.getAllDealerReport(
      page,
      this.state.pageSize,
      search,
      uuid,
      this.state.year
    )
      .then((data) => {
        this.setState({ isLoading: false });
        const {
          total_card,
          quarter_card,
          provosional_card,
          dealer_card,
          total_dealers,
        } = data.data.data;
        if (!isSearch) {
          const totalPoints = total_card[0].total_points;
          const totalOfftake = total_card[0].total_offtake;
          let earnedPointQ1 = 0;
          let totalOfftakeQ1 = 0;
          for (let i = 0; i < quarter_card.length; i++) {
            if (quarter_card[i]) {
              earnedPointQ1 += quarter_card[i].points;
              totalOfftakeQ1 += quarter_card[i].total_sales;
            }
          }
          const earnedPointQ2 = provosional_card[0].points;
          const totalOfftakeQ2 = provosional_card[0].total_offtake;
          this.setState({
            totalPoints,
            totalOfftake,
            earnedPointQ1,
            earnedPointQ2,
            totalOfftakeQ1,
            totalOfftakeQ2,
          });
        }

        const totalDealer = total_dealers;
        const dealers = dealer_card;
        const totalPages = Math.ceil(totalDealer / pageSize);
        this.setState({ totalDealer, dealers, totalPages });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        console.error("e", e);
      });
  };

  getDisplayLabel = () => {
    DashboardService.getDisplayLabel(this.state.year)
      .then((data) => {
        const labels = data.data && data.data.data ? data.data.data : null;

        this.setState({ displayLabels: labels });
      })
      .catch((e) => { });
  };

  handlePageChange = (page) => {
    const { currentUser } = this.state;
    const uuid = currentUser && currentUser.uuid ? currentUser.uuid : null;
    this.setState({ page: page });
    this.getAllDealerReport(page, null, false, uuid);
  };

  openViewReportPage = (sapId, name, type) => {
    const { manageUserLevel, randomKey } = this.state;
    const lastUser = manageUserLevel[manageUserLevel.length - 1];

    let obj = {
      uuid: sapId,
      name,
      role: "dealer",
      type,
    };
    if (lastUser && lastUser.role === Roles.DEALER_ROLE) {
      manageUserLevel[manageUserLevel.length - 1] = obj;
    } else {
      manageUserLevel.push(obj);
    }

    let randomId = null;
    if (randomKey) {
      randomId = randomKey;
    } else {
      randomId = generateRandomString();
    }

    StorageService.setNavigationLevelDetail(randomId, manageUserLevel);
    // this.props.history.push(`/primary-points-report?id=${sapId}&name=${name}`);
    // this.props.history.push(`/point-report?k=${randomId}&y=${this.state.year}`);
    //ROUTING LINK CAHNGED OFFTAKE REPORT
    this.props.history.push(
      `primary-points-report?id=${sapId}&name=${name}&y=${this.state.year}&type=${type}&from=offtake-scorecard`
    );
  };

  searchReport = (search, page) => {
    this.getAllDealerReport(page, search, true);
  };

  handleSearchChange = (e) => {
    let searchVal = e.target.value;
    this.setState({ page: 1, search: searchVal });
    this.searchReport(searchVal, 1);
  };

  getSelectedDealerDetail = (uuid, name, type) => {
    let { rolesToDrillDown, manageUserLevel } = this.state;
    let length = manageUserLevel.length;
    let role = rolesToDrillDown[length];
    const currentUser = {
      uuid,
      name,
      role,
      type,
    };
    manageUserLevel.push(currentUser);
    this.setState({ page: 1, currentUser, manageUserLevel });
    this.getAllDealerReport(1, null, null, uuid);
  };

  getDataFromNavigation = (index, manageUserLevel = null) => {
    if (!manageUserLevel) {
      manageUserLevel = this.state.manageUserLevel;
    }
    manageUserLevel = manageUserLevel.slice(0, index);
    const userData = manageUserLevel[index - 1];
    if (userData && userData.uuid) {
      let uuid = userData.uuid;

      this.getAllDealerReport(1, null, null, uuid);
    } else {
      this.getAllDealerReport(1);
    }
    this.setState({
      page: 1,
      currentUser: userData,
      manageUserLevel: manageUserLevel,
    });
  };

  getPageTitle = () => {
    const { dealerRole } = this.state;
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      return "Head Office";
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      return "Zone Manager";
    } else if (dealerRole === Roles.ZTM_ROLE) {
      return "Zonal Trade Marketing";
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      return "Region Manager";
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      return "Sales Officer";
    } else {
      return "Area Manager";
    }
  };

  filterAPI = () => {
    const { year } = this.state;

    let filter = {
      y: year ? year : null,
    };
    this.setState({ isLoader: true });
    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          this.setState({ isLoader: false });

          this.setState(
            {
              yearOptions: res.data.data.year ? res.data.data.year : [],
            },
            () => {
              if (
                !this.state.year &&
                this.state.yearOptions &&
                this.state.yearOptions[0]
              ) {
                this.setState(
                  {
                    year: this.state.yearOptions[0].value,
                    year_name: this.state.yearOptions[0].name,
                  },
                  () => {
                    const { page } = this.state;
                    const query = new URLSearchParams(
                      this.props.location.search
                    );
                    const id = query.get("id");
                    let index = query.get("i");
                    let manageUserLevel = [];
                    // let currentUser = null;
                    this.getDisplayLabel();
                    if (id && index) {
                      index = isNaN(index) === false ? parseInt(index) : 0;
                      manageUserLevel = StorageService.getNavigationLevelDetail(
                        id
                      );
                      // this.setState({ manageUserLevel: manageUserLevel });
                      this.getDataFromNavigation(index, manageUserLevel);
                    } else {
                      this.getAllDealerReport(page);
                    }
                  }
                );
              } else {
                const { page } = this.state;
                const query = new URLSearchParams(this.props.location.search);
                const id = query.get("id");
                let index = query.get("i");
                let manageUserLevel = [];
                // let currentUser = null;
                this.getDisplayLabel();
                index = isNaN(index) === false ? parseInt(index) : 0;
                manageUserLevel = StorageService.getNavigationLevelDetail(id);
                if (id && index) {
                  // this.setState({ manageUserLevel: manageUserLevel });
                  this.getDataFromNavigation(index, manageUserLevel);
                } else {
                  this.getAllDealerReport(page);
                }
              }
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        console.log("err", err);
      });
  };

  onChangeYear = (e) => {
    this.setState(
      {
        year: e && e.target && e.target.value,
      },
      () => {
        // this.filterAPI();

        const { page } = this.state;
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get("id");
        let index = query.get("i");
        let manageUserLevel = [];
        // let currentUser = null;
        this.getDisplayLabel();
        if (id && index) {
          index = isNaN(index) === false ? parseInt(index) : 0;
          manageUserLevel = StorageService.getNavigationLevelDetail(id);
          // this.setState({ manageUserLevel: manageUserLevel });
          this.getDataFromNavigation(index, manageUserLevel);
        } else {
          this.getAllDealerReport(
            page,
            null,
            null,
            this.state.currentUser && this.state.currentUser.uuid
              ? this.state.currentUser.uuid
              : null
          );
        }
      }
    );
  };

  render() {
    const {
      totalOfftake,
      totalPoints,
      totalOfftakeQ1,
      totalOfftakeQ2,
      earnedPointQ1,
      earnedPointQ2,
      manageUserLevel,
      dealers,
      totalPages,
      totalDealer,
      // search,
      currentUser,
      dealerRole,
      displayLabels,
    } = this.state;
    const pageTitle = this.getPageTitle();

    const manageUserLevelLength = manageUserLevel ? manageUserLevel.length : 0;

    const navigation = this.state.manageUserLevel.map((m, index) => (
      <Fragment>
        <span>
          <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
        </span>
        {index !== manageUserLevelLength - 1 && (
          <span
            className="prev-page-link cursor-pointer title-case"
            onClick={this.getDataFromNavigation.bind(this, index + 1, null)}
          >
            Offtake scorecard of {m.name} ({m.role})
          </span>
        )}
        {index === manageUserLevelLength - 1 && (
          <span className="current-page title-case">
            Offtake scorecard of {m.name} ({m.role})
          </span>
        )}
      </Fragment>
    ));
    const prepareDealerCard = dealers.map((d) => {
      return (
        <SingleDealerPrimaryReportTable
          dealer={d}
          openViewReportPage={this.openViewReportPage}
          getSelectedDealerDetail={this.getSelectedDealerDetail}
          currentUser={currentUser}
          dealerRole={dealerRole}
          key={d.uuid}
        ></SingleDealerPrimaryReportTable>
      );
    });
    return (
      <div id="primary-point-all-delear-report">
        {/* <Container> */}
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        {manageUserLevel.length > 0 && (
          <Row>
            <Col>
              <span
                className="prev-page-link cursor-pointer"
                onClick={this.getDataFromNavigation.bind(this, 0, null)}
              >
                Offtake Scorecard
              </span>
              {navigation}
              {/* <span>
              <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
            </span>
            <span className="current-page">Report of {delearName}</span> */}
            </Col>
          </Row>
        )}
        <Row style={{ marginBottom: "-18px", marginTop: "-4px" }}>
          {navigation.length < 1 ? (
            <Col
              className="current-page"
              md={this.state.yearOptions.length > 1 ? "7" : "9"}
              xs="12"
              style={{ top: "7px" }}
            >
              Offtake Scorecard Of {pageTitle}
            </Col>
          ) : (
            <Col
              md={this.state.yearOptions.length > 1 ? "7" : "9"}
              xs="12"
            ></Col>
          )}
          {this.state.yearOptions.length > 1 && (
            <Col xl="2" md="4" xs="12" style={{ paddingLeft: "0px" }}>
              <Row>
                <div className="fin-label">Financial Year</div>
                &nbsp;
                <FormGroup>
                  <select
                    name="month"
                    className="form-control cursor-pointer"
                    value={this.state.year}
                    style={{
                      fontSize: "13px",
                      width: "110px",
                      color: "#6e777f",
                      marginLeft: "auto",
                    }}
                    onChange={(e) => {
                      this.onChangeYear(e);
                    }}
                  >
                    {this.state.yearOptions.map((item) => (
                      <option value={item.value} selected>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Row>
            </Col>
          )}
          <Col md="3" xs="12">
            <AutoSuggestionDealer
              {...this.props}
              from="offtake-scorecard"
              year={this.state.year}
            ></AutoSuggestionDealer>
            {/* <Input
              placeholder="Search using dealer name or SAP code"
              className="report-search-input"
              name="search"
              value={search}
              onChange={this.handleSearchChange}
            ></Input>
            <img src="images/search.svg" alt="" className="report-search" /> */}
          </Col>
        </Row>
        <Row className="margin-top-30">
          <Col xl="4">
            <Card className="card-bg-image">
              <CardBody>
                <Row className="card-margin">
                  <Col xs="3">
                    <img src="images/wallet.svg" alt="" className="card-icon" />
                  </Col>
                  <Col xs="9" className="card-content-center">
                    <Row className="card-point-header">
                      <Col xs="6" className="padding-2">
                        Total Offtake
                      </Col>
                      <Col xs="6" className="padding-2">
                        Total Points
                      </Col>
                    </Row>
                    <Row className="card-point-value">
                      <Col xs="6" className="padding-2">
                        {formatNumber(totalOfftake)}
                      </Col>
                      <Col xs="6" className="padding-2">
                        {formatNumber(totalPoints)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardBody>
                <Row className="card-margin">
                  <Col xs="3">
                    <img src="images/calendar.svg" alt="" className="card-icon" />
                  </Col>
                  <Col xs="9" className="card-content-center">
                    <Row className="card-point-header">
                      <Col xs="6" className="padding-2">
                        Total Offtake
                      </Col>
                      <Col xs="6" className="padding-2">
                        Earned Points (
                        {displayLabels &&
                          displayLabels.year_till_previous_quarter}
                        )
                      </Col>
                    </Row>
                    <Row className="card-point-value">
                      <Col xs="6" className="padding-2">
                        {formatNumber(totalOfftakeQ1)}
                      </Col>
                      <Col xs="6" className="padding-2">
                        {formatNumber(earnedPointQ1)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardBody>
                <Row className="card-margin">
                  <Col xs="3">
                    <img src="images/calendar.svg" alt="" className="card-icon" />
                  </Col>
                  <Col xs="9" className="card-content-center">
                    <Row className="card-point-header">
                      <Col xs="6" className="padding-2">
                        Total Offtake
                      </Col>
                      <Col xs="6" className="padding-2">
                        Provisional Earned Points (
                        {displayLabels && displayLabels.quarter})
                      </Col>
                    </Row>
                    <Row className="card-point-value">
                      <Col xs="6" className="padding-2">
                        {formatNumber(totalOfftakeQ2)}
                      </Col>
                      <Col xs="6" className="padding-2">
                        {formatNumber(earnedPointQ2)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <br />
        {/* {totalDealer > 0 && <Row className="margin-top-20 margin-bottom-5">
          <Col xs="12" className="text-align-right">
            <img src="images/download.svg" alt="" className="download-report-icon" />
            <span className="download-report">{' '}Download Full Report</span>
          </Col>
        </Row>} */}

        {prepareDealerCard}
        {dealers.length === 0 && (
          <Card>
            <CardBody className="text-align-center card-point-header">
              We could not find any dealer with matching search criteria.
            </CardBody>
          </Card>
        )}
        {/* <SingleDealerPrimaryReportTable></SingleDealerPrimaryReportTable> */}
        {totalDealer > 0 && (
          <Pagination
            margin={2}
            page={this.state.page}
            count={totalPages}
            pageSize={pageSize}
            totalRecords={totalDealer}
            onPageChange={this.handlePageChange}
          ></Pagination>
        )}
      </div>
    );
  }
}

export default PrimaryPointAllDealerReport;
