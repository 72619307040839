import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import WarrantyRegistrationService from "../../../services/WarrantyRegistrationService";
import GoogleAnalticsService from "../../../services/GoogleAnalticsService";

class VehicleDetails extends Component {

  componentDidMount() {
    GoogleAnalticsService.event('Add Warranty', 'Vehicle Type View');
  }
  goTo = to => {
    // if (this.props.from === "warrantyReplacement") {
    //   return;
    // }
    if (!this.props.isExisting || to === "vehicleType") {
      const { controls } = this.props.vehicleDetails;
      WarrantyRegistrationService.goTo(
        controls.vehicleTypeId.value.name,
        this.props.updateState,
        to
      );
    }
  };

  goToWarrntyReg = () => {
    const { controls } = this.props.vehicleDetails;
    if (!this.props.isExisting) {
      WarrantyRegistrationService.goTo(
        controls.vehicleCategory.value,
        this.props.updateState,
        "vehicleType"
      );
    }
  };

  goToVehicleModel = () => {
    const { controls } = this.props.vehicleDetails;
    if (!this.props.isExisting) {
      WarrantyRegistrationService.goTo(
        controls.vehicleTypeBrandModelId.value.name,
        this.props.updateState,
        "vehicleModel"
      );
    }
  };

  goToVehicleMake = () => {
    if (!this.props.isExisting) {
      const { controls } = this.props.vehicleDetails;
      WarrantyRegistrationService.goTo(
        controls.vehicleTypeBrandId.value.name,
        this.props.updateState,
        "vehicleMake"
      );
    }
  };

  render() {
    const { isExisting } = this.props;
    const { controls } = this.props.vehicleDetails;

    return (
      <Card>
        <CardBody>
          <Row className="warranty-customer-details-label">
            <Col xl="10" xs="10" ms="10">
              VEHICLE DETAILS
            </Col>
          </Row>
          {
            <div>
              <Row
                onClick={() => this.goTo("vehicleType")}
                className="disable-text-box-margin"
                style={{ cursor: "pointer" }}
              >
                <Col>
                  <div
                    className={classnames(
                      { "light-gray-backss": isExisting },
                      "filled-data-field disabled-text-box "
                    )}
                  >
                    <Row>
                      <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                        <span className="disabled-input-label">Type</span>
                      </Col>
                      <Col className="text-overflow-dots">
                        <span
                          title={controls.vehicleTypeId.value.name}
                          className="disabled-input-value"
                          style={{ textTransform: "capitalize" }}
                        >
                          {controls.vehicleTypeId.value.name}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {/* <Row onClick={() => WarrantyRegistrationService.goTo(controls.state.value.name, this.props.updateState, 'vehicleSelectState')} className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={`disabled-text-box ${controls.state.value.name ? 'filled-data-field' : ''}`}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">State</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.state.value.name} className="disabled-input-value" style={{ textTransform: 'capitalize' }}>
                        {controls.state.value.name}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row onClick={() => WarrantyRegistrationService.goTo(controls.city.value.name, this.props.updateState, 'vehicleSelectCity')} className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={`disabled-text-box ${controls.city.value.name ? 'filled-data-field' : ''}`}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">City</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.city.value.name} className="disabled-input-value" style={{ textTransform: 'capitalize' }}>
                        {controls.city.value.name}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
              {/* <Row onClick={() => this.goToWarrntyReg()}
              className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={classnames({ 'light-gray-back': isExisting }, 'filled-data-field disabled-text-box ')}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">Registration No.</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.registrationNumber.value} className="disabled-input-value">
                        {controls.registrationNumber.value}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
              <Row
                onClick={() => this.goToWarrntyReg()}
                className="disable-text-box-margin"
                style={{ cursor: "pointer" }}
              >
                <Col>
                  <div
                    className={classnames(
                      { "light-gray-back": isExisting },
                      "filled-data-field disabled-text-box "
                    )}
                  >
                    <Row>
                      <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                        <span className="disabled-input-label">
                          Vehicle Category
                        </span>
                      </Col>
                      <Col className="text-overflow-dots">
                        <span
                          title={controls.vehicleCategory.value}
                          className="disabled-input-value"
                          style={{ textTransform: "capitalize" }}
                        >
                          {controls.vehicleCategory.value}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {/* <Row onClick={() => WarrantyRegistrationService.goTo(controls.registrationYear.value, this.props.updateState, 'vehicleRegistrationYear')} className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={`disabled-text-box ${controls.registrationYear.value ? 'filled-data-field' : ''}`}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">Registration Year</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.registrationYear.value !== 'null' ? controls.registrationYear.value : ''} className="disabled-input-value">
                        {controls.registrationYear.value !== 'null' ? controls.registrationYear.value : ''}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
              <Row
                onClick={() => this.goToVehicleMake()}
                className="disable-text-box-margin"
                style={{ cursor: "pointer" }}
              >
                <Col>
                  <div
                    className={classnames(
                      { "light-gray-back": isExisting },
                      "filled-data-field disabled-text-box "
                    )}
                  >
                    <Row>
                      <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                        <span className="disabled-input-label">Make</span>
                      </Col>
                      <Col className="text-overflow-dots">
                        <span
                          title={(controls.vehicleTypeBrandId.value.name && controls.vehicleTypeBrandId.value.name.toLowerCase() === 'other (vehicle make is not available)' ? 'other' : controls.vehicleTypeBrandId.value.name)}
                          className="disabled-input-value"
                          style={{ textTransform: "capitalize" }}
                        >
                          {(controls.vehicleTypeBrandId.value.name && controls.vehicleTypeBrandId.value.name.toLowerCase() === 'other (vehicle make is not available)' ? 'other' : controls.vehicleTypeBrandId.value.name)}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row
                onClick={() => this.goToVehicleModel()}
                className="disable-text-box-margin"
                style={{ cursor: "pointer" }}
              >
                <Col>
                  <div
                    className={classnames(
                      { "light-gray-back": isExisting },
                      "filled-data-field disabled-text-box "
                    )}
                  >
                    <Row>
                      <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                        <span className="disabled-input-label">Model</span>
                      </Col>
                      <Col className="text-overflow-dots">
                        <span
                          title={(controls.vehicleTypeId.value.type === 'scv' || controls.vehicleTypeId.value.type === 'lcv') ? '--' : (controls.vehicleTypeBrandModelId.value.name && controls.vehicleTypeBrandModelId.value.name.toLowerCase() === 'other (vehicle model is not available)' ? 'other' : controls.vehicleTypeBrandModelId.value.name)}
                          className="disabled-input-value"
                          style={{ textTransform: "capitalize" }}
                        >
                          {(controls.vehicleTypeId.value.type === 'scv' || controls.vehicleTypeId.value.type === 'lcv') ? '--' : (controls.vehicleTypeBrandModelId.value.name && controls.vehicleTypeBrandModelId.value.name.toLowerCase() === 'other (vehicle model is not available)' ? 'other' : controls.vehicleTypeBrandModelId.value.name)}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {/* <Row onClick={() => WarrantyRegistrationService.goTo(controls.vehicleAvgMonthlyRunning.value, this.props.updateState, 'vehicleMeterReading')} className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={`disabled-text-box ${controls.vehicleAvgMonthlyRunning.value ? 'filled-data-field' : ''}`}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">Average Monthly Usage in KMs</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.vehicleAvgMonthlyRunning.value} className="disabled-input-value">
                        {controls.vehicleAvgMonthlyRunning.value}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
            </div>
          }
        </CardBody>
      </Card>
    );
  }
}

export default VehicleDetails;
