import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import RootReducer from './reducers/RootReducer';
import App from './App';
import { initializeFirebase } from './push-notification';
import './index.css';
import './assets/base.scss';
import '../node_modules/video-react/dist/video-react.css';
// import * as serviceWorker from './serviceWorker';

const store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);


const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
);

initializeFirebase();
ReactDOM.render(jsx, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
