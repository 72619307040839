import React from 'react';
import './DownloadModal.css';
import { Modal, ModalBody } from 'reactstrap';
import CustomSpinner from '../../CustomSpinner/CustomSpinner';

const DownloadModal = ({ isOpen, toggle, uploadErrMsg, nonExistmaterialKeyArr, nonExistSapIdArr, genrateNonExistmaterialKeyCsv, genrateNonExistSapIdCsv, isModalLoading, handleDownloadModalNoClick, handlDownloadModalYesClick }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="downloadModal" id="downloadModal" scrollable={true} backdrop={false}>
      {isModalLoading && <CustomSpinner />}
      <ModalBody>
        <div className='downloadArea text-center'>
          <img src="images/errorIcon.png" alt='errorIcon' className='img-fluid errorIcon' />
          <div className='errorText'>{uploadErrMsg}</div>
          <div className="d-flex flex-wrap flex-column justify-content-center align-items-center mb-3">
            {nonExistmaterialKeyArr && nonExistmaterialKeyArr.length > 0 ? <div onClick={genrateNonExistmaterialKeyCsv}>
              <span className="Download-icon" style={{ display: 'inline-block' }}>
                <img
                  src="images/group-6.png"
                  srcSet="images/group-6@2x.png 2x,
                        images/group-6@3x.png 3x"
                  className="Group-6"
                  alt=''
                />
                <span
                  className="Download-Report"
                >
                  Download SKU Not Found List
                </span>
              </span>
            </div> : ''}
            {nonExistSapIdArr && nonExistSapIdArr.length > 0 ? <div onClick={genrateNonExistSapIdCsv}>
              <span className="Download-icon" style={{ display: 'inline-block' }}>
                <img
                  src="images/group-6.png"
                  srcSet="images/group-6@2x.png 2x,
                        images/group-6@3x.png 3x"
                  className="Group-6"
                  alt=''
                />
                <span
                  className="Download-Report"
                >
                  Download Sap Id's Not Found List
                </span>
              </span>
            </div> : ''}
          </div>
          <div className='errorText'>Are you sure you want to continue to process this file?</div>
          <div className='btnGroup'>
            <button className="downloadModalBtn noBtn" type='button' onClick={handleDownloadModalNoClick}>No</button>
            <button className="downloadModalBtn" type='button' onClick={handlDownloadModalYesClick}>Yes</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DownloadModal
