export const getRetailMarketingStoreInformationState = state => {
  return state.RetailManagementSystemReducer.addReccicontrols
};
export const getRetailMarketingUploadPhotographState = state => {
  return state.RetailManagementSystemReducer.uploadPhotgraphControls
};
export const getRecceStatus = state =>{
  return state.RetailManagementSystemReducer.status
}
export const getRecceStatusCount = state =>{
  return state.RetailManagementSystemReducer.count
}
export const getVendorScreen = state =>{
  return state.RetailManagementSystemReducer.vendorScreen
}
export const getRecceStatusCountFilter = state =>{
  return state.RetailManagementSystemReducer.statusCount;
}
export const getHoRecceModification = state =>{
  return state.RetailManagementSystemReducer.hoRecceControls
}
export const getFilterData = state =>{
  return state.RetailManagementSystemReducer.filterData;
}

export const getFilterOpenClose = state =>{
  return state.RetailManagementSystemReducer.filterOpen
}

export const getFilterAppliedStatus = state =>{
  return state.RetailManagementSystemReducer.filterAppliedStatus
}

export const getDuplicateRecce = state =>{
  return state.RetailManagementSystemReducer.duplicateRecce
}