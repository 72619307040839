import { request } from "./Request";
import { routes } from "../constants/constant.route";

const bannerRoutes = routes.USER_UPDATE;
const {
  UPDATE_PASSWORD_TO_DEFAULT,
  UPDATE_CLASS,
  UPDATE_STATUS,
  UPDATE_DATA,
  UPLOAD_FLEET,
  MAP_SAP,
  EMAIL_SEND,
  UPDATE_FM,
  ADD_CITY,
  UPLOAD_RMS_DEALER_MAPPING,
  UPLOAD_POINTS,
  REDEMPTION_FILE,
  WHATSAPP_UTILITY,
  ADD_WHATSAPP_TEMPLATE,
} = bannerRoutes;

const API_URL = process.env.REACT_APP_API_URL;

export default class BannerService {
  static dataModificationandUpload(data) {
    return request("POST", `${API_URL}${UPDATE_DATA}`, null, data, null);
  }

  static fleetDataUpload(data) {
    return request("POST", `${API_URL}${UPLOAD_FLEET}`, null, data, null);
  }

  static dataModificationandUploadFM(data) {
    return request("POST", `${API_URL}${UPDATE_FM}`, null, data, null);
  }

  static updatePasswordToDefault(obj) {
    return request(
      "POST",
      `${API_URL}${UPDATE_PASSWORD_TO_DEFAULT}`,
      null,
      obj,
      null
    );
  }

  static addCity(obj) {
    return request("POST", `${API_URL}${ADD_CITY}`, null, obj, null);
  }

  /**
   * @description update class
   * @method POST
   * @returns promise
   */
  static updateClass(data) {
    return request("POST", `${API_URL}${UPDATE_CLASS}`, null, data, null);
  }

  static uploadRedemptionPoints(data) {
    return request("POST", `${API_URL}${UPLOAD_POINTS}`, null, data, null);
  }

  /**
   * @description update status
   * @method POST
   * @returns promise
   */
  static updateStatus(data) {
    return request("POST", `${API_URL}${UPDATE_STATUS}`, null, data, null);
  }

  static emailSend(data) {
    return request("POST", `${API_URL}${EMAIL_SEND}`, null, data, null);
  }

  static mapSap(obj) {
    return request("POST", `${API_URL}${MAP_SAP}`, null, obj, null);
  }

  static uploadRMSVendorAndMapping(data) {
    return request(
      "POST",
      `${API_URL}${UPLOAD_RMS_DEALER_MAPPING}`,
      null,
      data,
      null
    );
  }

  /*   static mapSap(obj) {
    return request(
      'POST',
      `${API_URL}${MAP_SAP}`,
      null,
      obj,
      null
    );
  } */

  static getRedemptionFileData() {
    return request("GET", `${API_URL}${REDEMPTION_FILE}`, null, null, null);
  }
  static whatsappUtility(obj) {
    return request("POST", `${API_URL}${WHATSAPP_UTILITY}`, null, obj, null);
  }

  static addWhatsappTemplate(obj) {
    return request(
      "POST",
      `${API_URL}${ADD_WHATSAPP_TEMPLATE}`,
      null,
      obj,
      null
    );
  }
}
