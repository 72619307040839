import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import swal from "sweetalert";
import Validation from "../../../services/Validation";
import AddCustomerService from "../../../services/AddCustomerService";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import { messages } from "../../../constants/constant.message";
import ModalService from "../../../services/ModalService";
import "./AddCustomerSuccess.css";
import GoogleAnalticsService from "../../../services/GoogleAnalticsService";
import { Roles } from "../../../constants/constant.role";

class AddCustomerSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      success_detail: this.props.success_detail,
      /**Getting the data from local Storage */
      userDetail: JSON.parse(localStorage.getItem("user-detail")),
      is_display_add_ref: false,
      controls: {
        is_rating_enable: {
          value: true,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        is_offer_enable: {
          value: true,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        first_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        last_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        phone_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        comment: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        rating: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
    };
  }
  // toggle(data) {
  //   let { controls, success_detail } = this.state;
  //   if (data && Object.keys(data).length > 0) {
  //     success_detail = data;
  //   } else {
  //     success_detail = {};
  //     this.props.handleOnCancel({ refresh_list: true, couponData: this.props.couponData, user: this.state.controls });
  //     // this.resetControls();
  //   }
  //   this.setState(prevState => ({
  //     ...prevState,
  //     modal: !prevState.modal,
  //     controls,
  //     success_detail
  //   }));
  // }

  handleOnCancelAddRef = () => {
    this.resetAddRef();
    this.setState({
      is_display_add_ref: false,
    });
  };

  askReff = () => {
    this.setState({
      is_display_add_ref: true,
    });
  };

  /**Handle the validation for the reference Inputs */
  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let isFormValid = false;
    let { first_name, last_name, phone_number } = controls;

    first_name = Validation.notNullValidator(first_name);
    last_name = Validation.notNullValidator(last_name);
    phone_number = Validation.notNullValidator(phone_number);
    if (first_name.nullValue && last_name.nullValue && phone_number.nullValue) {
      first_name.showErrorMsg = false;
      last_name.showErrorMsg = false;
      phone_number.showErrorMsg = false;
      return true;
    }

    if (first_name.touched === true || isSubmit) {
      first_name = Validation.notNullValidator(first_name);
      first_name.valid = !first_name.nullValue;
      if (first_name.valid === false) {
        first_name.showErrorMsg = true;
      } else {
        first_name.showErrorMsg = false;
      }
    }

    if (last_name.touched === true || isSubmit) {
      last_name = Validation.notNullValidator(last_name);
      last_name.valid = !last_name.nullValue;
      if (last_name.valid === false) {
        last_name.showErrorMsg = true;
      } else {
        last_name.showErrorMsg = false;
      }
    }

    if (phone_number.touched === true || isSubmit) {
      phone_number = Validation.notNullValidator(phone_number);
      phone_number = Validation.validatePhoneNumber(phone_number);
      phone_number.valid = !(
        phone_number.nullValue || phone_number.invalidPhone
      );
      if (
        !phone_number.valid === false &&
        phone_number.value &&
        phone_number.value.length === 10
      ) {
        phone_number.showErrorMsg = false;
      } else {
        phone_number.showErrorMsg = true;
      }
    }

    if (
      first_name.valid === true &&
      last_name.valid === true &&
      phone_number.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls,
    });
    return isFormValid;
  };

  /**Resets the value  */
  resetAddRef = () => {
    let { controls } = this.state;
    controls.first_name.value = "";
    controls.last_name.value = "";
    controls.phone_number.value = "";
    controls.first_name.touched = false;
    controls.last_name.touched = false;
    controls.phone_number.touched = false;
    this.setState({
      controls,
    });
  };

  /**Submit the refernce data if form is valid */
  submitRef = async () => {
    if (!this.handleValidation(true)) {
      return;
    }
    let submitRef = await this.submitRefData();
    if (submitRef) {
      this.setState({
        is_display_add_ref: false,
      });
    }
  };

  submitRating = () => {
    let { controls } = this.state;
    let obj = {
      customer_id: this.state.success_detail.customer_id,
      customer_history_id: this.state.success_detail.customer_history_id,
      rating: controls.rating.value,
      comment: controls.comment.value,
    };
    this.setState({
      isLoading: true,
    });
    AddCustomerService.addDealerRating(obj)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        swal({
          title: "Congratulations !",
          text: response.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.toggle({});
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  componentDidMount() {
    GoogleAnalticsService.event("Add Customer Visit", "Reference Screen View");
  }

  /**Submits the referernce data */
  submitRefData = async (is_add_more = false) => {
    let { controls } = this.state;
    if (!this.handleValidation(true)) {
      return false;
    }
    // if (controls.first_name.nullValue && controls.last_name.nullValue &&
    //   controls.phone_number.nullValue) {
    //   this.props.handleOnCancel({ refresh_list: true, couponData: this.props.couponData, user: this.state.controls });
    //   return;
    // }

    /**Creating the obj to post with appropriate key/value pair */
    let obj = {
      customer_id: this.state.success_detail.customer_id,
      customer_history_id: this.state.success_detail.customer_history_id,
      is_offer_enable:
        this.props.offer_data &&
          this.props.offer_data.dealerData &&
          (this.props.offer_data.dealerData.offerMessageForExistingCustomer ||
            this.props.offer_data.dealerData.offerMessageForProspectCustomer)
          ? controls.is_offer_enable.value
          : false,
      is_rating_enable:
        this.props.get_user_info.google_my_business_rating_link &&
          this.props.get_user_info.google_my_business_rating_link
          ? controls.is_rating_enable.value
          : false,
    };

    if (this.props.sms_credits && this.props.sms_credits.credit <= 0) {
      obj.is_offer_enable = false;
      obj.is_rating_enable = false;
    }

    if (controls.phone_number.value) {
      obj.phone_number = controls.phone_number.value;
      obj.country_code = "91";
    }
    if (controls.first_name.value) {
      obj.first_name = controls.first_name.value;
    }
    if (controls.last_name.value) {
      obj.last_name = controls.last_name.value;
    }

    this.setState({
      isLoading: true,
    });
    let submitReff = await AddCustomerService.addReffCustomer(obj)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        if (controls.phone_number.value) {
          GoogleAnalticsService.event("Add Customer Visit", "Reference Added");
        }
        if (
          this.props.get_user_info &&
          this.props.get_user_info.google_my_business_rating_link &&
          controls.is_rating_enable.value
        ) {
          GoogleAnalticsService.event(
            "Add Customer Visit",
            "Add Customer Ratting Checkbox Checked"
          );
        }
        if (controls.is_offer_enable.value) {
          GoogleAnalticsService.event(
            "Add Customer Visit",
            "Add Customer Offer Chekcbox Checked"
          );
        }
        swal({
          title: "Congratulations !",
          text: response.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.resetAddRef();
          if (!is_add_more) {
            this.props.handleOnCancel({
              refresh_list: true,
              couponData: this.props.couponData,
              user: this.state.controls,
            });
          } else {
            // GoogleAnalticsService.event('Add Customer Visit', 'Ask For Reference Click');
          }
        });

        return true;
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        return false;
      });

    return submitReff;
  };

  /**If the user wants to add more reference */
  addMoreReff = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    this.submitRefData(true);
  };

  /**Handles the input change for input box on basis of name attribute*/
  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let { controls } = this.state;
    if (controlName === "phone_number") {
      if (!isNaN(e.target.value)) {
        if (e.target.value.length <= 10) {
          controls[controlName].value = e.target.value;
        }
      }
    } else {
      controls[controlName].value = controlValue;
    }
    controls[controlName].touched = true;
    this.setState({
      controls,
    });
    this.handleValidation();
  };

  onChangeRating = (rating) => {
    let { controls } = this.state;
    controls.rating.value = rating;
    this.setState({
      controls,
    });
  };

  addRating = () => {
    let { controls } = this.state;
    if (controls.rating.value || controls.comment.value) {
      this.submitRating();
    } else {
      this.toggle({});
    }
  };

  openRewardReff = () => {
    if (this.props.sms_credits && this.props.sms_credits.credit <= 0) {
      return;
    }
    this.props.openRewardReff();
  };

  handleChangeCheckbox = (name) => {
    if (this.props.sms_credits && this.props.sms_credits.credit <= 0) {
      return;
    }
    let { controls } = this.state;
    controls[name].value = !controls[name].value;
    this.setState({
      controls,
    });
  };

  goToBuySMS = () => {
    this.props.goToBuySMS();
  };

  render() {
    let { controls, userDetail } = this.state;
    if (this.props.sms_credits && this.props.sms_credits.credit <= 0) {
      controls.is_offer_enable.value = false;
      controls.is_rating_enable.value = false;
    }
    return (
      <>
        <div class="custom-drawer" id="add-customer">
          {this.state.isLoading && <CustomSpinner />}
          <div
            class="custom-drawer-content"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <Row className="m-0">
              <Col
                className="border-bottom-2-2px-F1F2F3 mt-3"
                xl="12"
                md="12"
                xs="12"
              >
                <div className="d-flex-column">
                  <span className="Success-tyre-purchase">Success!</span>
                  {this.state.success_detail &&
                    this.state.success_detail.message && (
                      <span className="Tyre-Purchase-visit">
                        {this.state.success_detail.message}
                      </span>
                    )}
                </div>
              </Col>
              {/* {
                this.props.get_user_info && this.props.get_user_info.google_my_business_link && */}
              <Col xl="12" md="12" xs="12" className="mt-3 text-align-center">
                <span className="Success-tyre-purchase">
                  Request to Take References and Add More Customers
                </span>
              </Col>
              {/* } */}
              <Col className="d-flex-column mt-3 border-bottom-2-2px-F1F2F3 pb-3">
                {this.props.get_user_info &&
                  this.props.get_user_info.google_my_business_rating_link && (
                    <div
                      className="d-flex-row"
                      style={{
                        opacity:
                          this.props.sms_credits &&
                            this.props.sms_credits.credit <= 0
                            ? "0.5"
                            : "1",
                      }}
                    >
                      <label className="custom-checkboax1--container">
                        <input
                          type="checkbox"
                          name="service"
                          checked={controls.is_rating_enable.value}
                          onChange={(e) =>
                            this.handleChangeCheckbox("is_rating_enable")
                          }
                        />
                        <span className="checkmark1" />
                      </label>
                      <span
                        style={{ marginLeft: "10px" }}
                        className="same-as-existing-customer font-size-15px"
                      >
                        {/* Would you like to send message to get customer rating? */}
                        Would you like to send a message to the customer for
                        Rating and Feedback?
                      </span>
                    </div>
                  )}
                {this.props.offer_data &&
                  this.props.offer_data.dealerData &&
                  (this.props.offer_data.dealerData
                    .offerMessageForExistingCustomer ||
                    this.props.offer_data.dealerData
                      .offerMessageForProspectCustomer) && (
                    <div
                      className="d-flex-row mt-2"
                      style={{
                        opacity:
                          this.props.sms_credits &&
                            this.props.sms_credits.credit <= 0
                            ? "0.5"
                            : "1",
                      }}
                    >
                      <label className="custom-checkboax1--container">
                        <input
                          type="checkbox"
                          name="service"
                          checked={controls.is_offer_enable.value}
                          onChange={(e) =>
                            this.handleChangeCheckbox("is_offer_enable")
                          }
                        />
                        <span className="checkmark1" />
                      </label>
                      <span
                        style={{ marginLeft: "10px" }}
                        className="same-as-existing-customer font-size-15px"
                      >
                        {/* Do you want to provide your configured offer to the customer if they refer you new prospect? */}
                        Would you like to provide any discount/offer to the
                        customer for giving references?
                      </span>
                    </div>
                  )}
                {userDetail.type === Roles.SUB_DEALER_ROLE ||
                  userDetail.type === Roles.BRANCH_ROLE ||
                  userDetail.customer_class === "na" ||
                  userDetail.customer_class === "hy" ? (
                  ""
                ) : (
                  <span
                    onClick={() => this.openRewardReff()}
                    className="Ask-for-Reference-lbl cursor-pointer mt-2"
                    style={{
                      marginLeft: "30px",
                      opacity:
                        this.props.sms_credits &&
                          this.props.sms_credits.credit <= 0
                          ? "0.5"
                          : "1",
                    }}
                  >
                    {this.props.offer_data &&
                      this.props.offer_data.dealerData &&
                      (this.props.offer_data.dealerData
                        .offerMessageForExistingCustomer ||
                        this.props.offer_data.dealerData
                          .offerMessageForProspectCustomer)
                      ? "Click here to change offer"
                      : "Click here to set offer"}
                  </span>
                )}
                {/* {
                  this.props.sms_credits && this.props.sms_credits.credit > 0 && */}
                {/**Not showing Balance SMS credits for below type and customer_class */}
                {userDetail.type === Roles.SUB_DEALER_ROLE ||
                  userDetail.type === Roles.BRANCH_ROLE ||
                  userDetail.customer_class === "na" ||
                  userDetail.customer_class === "hy" ? (
                  ""
                ) : (
                  <span className="mr-3 mt-3 Balance-SMS-Credits">
                    Balance SMS Credits :{" "}
                    <span style={{ color: "#495057" }}>
                      {this.props.sms_credits && this.props.sms_credits.credit
                        ? this.props.sms_credits.credit
                        : 0}
                    </span>
                  </span>
                )}
                {/* // }
                // {this.props.sms_credits && this.props.sms_credits.credit > 0 && */}
                {/**Not showing following "Note" for below type and customer_class */}
                {userDetail.type === Roles.SUB_DEALER_ROLE ||
                  userDetail.type === Roles.BRANCH_ROLE ||
                  userDetail.customer_class === "na" ||
                  userDetail.customer_class === "hy" ? (
                  ""
                ) : (
                  <span>
                    Note : Customer will not receive Ratting and Reference SMS
                    if you have low sms credit.
                  </span>
                )}
                {/* // } */}
                {this.props.sms_credits && this.props.sms_credits.credit <= 0 && (
                  <div className="service-pref-note d-flex-column ReferralRewardsPreferenceNote mt-3">
                    <span>
                      You do not have sufficient SMS balance.
                      <span
                        className="custom-anchor-tag ml-3"
                        style={{ textDecoration: "none" }}
                        onClick={() => this.goToBuySMS()}
                      >
                        Click here to buy SMS
                      </span>
                    </span>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="m-0">
              <Col xl="12" md="12" xs="12" className="mt-3 pb-3">
                <span className="Success-tyre-purchase">Add Reference</span>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label for="common">
                    First Name<span className="required-field">*</span>
                  </Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="first_name"
                    value={controls.first_name.value}
                    id="first_name"
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.first_name.showErrorMsg && (
                    <div className="error show_error">
                      *Please enter first name.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label for="common">
                    Last Name<span className="required-field">*</span>
                  </Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="last_name"
                    id="first_name"
                    value={controls.last_name.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.last_name.showErrorMsg && (
                    <div className="error show_error">
                      *Please enter last name.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label for="common">
                    Mobile Number<span className="required-field">*</span>
                  </Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="phone_number"
                    id="first_name"
                    value={controls.phone_number.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.phone_number.showErrorMsg && (
                    <div className="error show_error">
                      *Please enter mobile number
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="12" md="12" xs="12">
                {
                  <div className="d-flex-row">
                    <img
                      src="/images/add_blue.svg"
                      alt=''
                      onClick={() => this.submitRefData(true)}
                      className="mt-auto mb-auto cursor-pointer"
                    />
                    <span
                      onClick={() => this.submitRefData(true)}
                      className="Ask-for-Reference-lbl ml-2 mt-auto mb-auto cursor-pointer"
                    >
                      Add more Reference
                    </span>
                  </div>
                }
              </Col>
            </Row>
          </div>
          <Row
            className="c-top-15 custom-drawer-footer m-0"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <Col className="d-flex-row" style={{ justifyContent: "flex-end" }}>
              <Button
                className="btn-pill action-button profile-save-button"
                style={{
                  height: "38px",
                  fontSize: "18px !important",
                }}
                color="warning"
                onClick={() => this.submitRefData(false)}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
        {/* <Modal isOpen={this.state.modal} toggle={this.toggle} external={externalCloseBtn} className="modal-md modal-lg custom-confirm-modal modal-sm"
        id="add-customer" style={{ maxWidth: '350px' }} backdrop={this.backdrop ? 'static' : 'nostatic'}>
          {this.state.isLoading && <CustomSpinner/>}
          <ModalBody 
          className={this.state.is_display_add_ref?'hidden':'d-flex-column'} >
            <Row>
              <Col>
                <div className="d-flex-column">
                  <span className="Success-tyre-purchase">Success!</span>
                  {
                    (this.state.success_detail && this.state.success_detail.message) &&
                  <span className="Tyre-Purchase-visit">
                    {this.state.success_detail.message}
                  </span>
                  }
                  <span style={{ border: '1px solid #EFEFEF' }}></span>
                  <span className="How-would-you-like-t font-weight-bold">
                  Kindly share feedback based on customer experience
                  </span>
                  <span className="How-would-you-like-t font-weight-normal">
                    How would you like to rate<br />
                    overall experience?
                  </span>
                  <div className="star-rating-wrapper">
                    <Rating
                      className="star-rating-data"
                      initialRating={controls.rating.value}
                      emptySymbol={<img src="/images/start_empty.svg" alt='' />}
                      fullSymbol={<img src="/images/start_fill.svg" alt='' />}
                      onChange={this.onChangeRating}
                    // fractions={2}
                    />
                  </div>
                  <div className="mt-4 ml-auto mr-auto" style={{ width: '90%' }}>
                    <FormGroup>
                      <Label>Comments</Label>
                      <TextareaAutosize
                        name="comment"
                        autocomplete="nofill"
                        id="remark"
                        // value={controls.remark.value}
                        style={{ minHeight: 80, maxHeight: 90, fontSize: '14px' }}
                        className="form-control"
                        onChange={(e) => this.handleInputChange({ target: { name: 'comment', value: e.target.value } })}
                        placeholder=''
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="d-flex-column mt-4">
                  {
                    !this.state.success_detail.is_hide_add_reff &&
                  <div className="ml-auto mr-auto d-flex-row">
                    <img src="/images/ask-for-reference-icon.svg" 
                    alt='' 
                    onClick={()=>this.askReff()}
                    className="mt-auto mb-auto cursor-pointer"/>
                    <span 
                    onClick={()=>this.askReff()}
                    className="Ask-for-Reference-lbl ml-2 mt-auto mb-auto cursor-pointer">Ask for Reference</span>
                  </div>
                  }
                  <Button
                    className="mt-3 mb-3 btn-pill action-button profile-save-button
                    ml-auto mr-auto"
                    style={{width:'90%',
                    height: '38px',
                    fontSize: '18px !important'}}
                    color="warning"
                    onClick={() => this.addRating()}>
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        
          <ModalBody 
          className={!this.state.is_display_add_ref?'hidden':'d-flex-column'}>
            <Row>
              <Col xl="12" md="12" xs="12" 
              className="d-flex justify-content-between" 
              style={{borderBottom: 'solid 1.2px rgba(0, 0, 0, 0.1)',paddingBottom:'10px'}}>
                <span className="ADD-REFERENCE-Title">ADD REFERENCE</span>
                <Ionicon
                className="cursor-pointer"
                icon="md-close"
                fontSize="24px"
                color="#fdbb1f"
                onClick={() => this.handleOnCancelAddRef()}
                />
              </Col>
              <Col xl="12" md="12" xs="12" className="mt-2">
                <FormGroup>
                  <Label for="common">First Name<span className="required-field">*</span></Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="first_name"
                    value={controls.first_name.value}
                    id="first_name"
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                    {controls.first_name.showErrorMsg && <div className="error show_error">
                    *Please enter first name.
                      </div>}
                </FormGroup>
              
              </Col>
              <Col xl="12" md="12" xs="12">
                <FormGroup>
                  <Label for="common">Last Name<span className="required-field">*</span></Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="last_name"
                    id="first_name"
                    value={controls.last_name.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.last_name.showErrorMsg && <div className="error show_error">
                    *Please enter last name.
                      </div>}
                </FormGroup>
                
              </Col>
              <Col xl="12" md="12" xs="12">
                <FormGroup>
                  <Label for="common">Mobile Number<span className="required-field">*</span></Label>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="phone_number"
                    id="first_name"
                    value={controls.phone_number.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                     {controls.phone_number.showErrorMsg && <div className="error show_error">
                    *Please enter mobile number
                      </div>}
                </FormGroup>
              </Col>
              <div className="d-flex-column mt-4 w-100">
                {
                  !this.state.success_detail.is_hide_add_reff &&
                  <div className="ml-auto mr-auto d-flex-row">
                    <img src="/images/add_blue.svg" alt='' 
                    onClick={()=>this.addMoreReff()} className="mt-auto mb-auto cursor-pointer"/>
                    <span 
                    onClick={()=>this.addMoreReff()} className="Ask-for-Reference-lbl ml-2 mt-auto mb-auto cursor-pointer">Add more Reference</span>
                  </div>
                }
                  <Button
                    className="mt-3 mb-3 btn-pill action-button profile-save-button
                    ml-auto mr-auto"
                    style={{width:'90%',
                    height: '38px',
                    fontSize: '18px !important'}}
                    color="warning"
                    onClick={() => this.submitRef()}>
                    Submit
                  </Button>
                </div>
            </Row>
          </ModalBody>
        </Modal> */}
      </>
    );
  }
}

export default AddCustomerSuccess;
