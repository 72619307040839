import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Row,
  Col,
  Input,
} from "reactstrap";
import "../AddCustomer.css";
import _ from "lodash";
import swal from "sweetalert";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaskedInput from "react-maskedinput";
// services
import CustomerManagementService from "../../../services/CustomerManagementService";
import WarrantyRegistrationService from "./../../../services/WarrantyRegistrationService";
import CampaignService from "../../../services/CampaignService";
import ModalService from "../../../services/ModalService";
import Validation from "../../../services/Validation";
import { messages } from "../../../constants/constant.message";

class VehicleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lcv_scv_usage_type: false,
      selectedPurpose: null,
      vehicle_meter_reading: '',
      AVG_monthly_usage: '',
      customer_services: [],
      selectedServices: [],
      vehicleMakeData: [],
      vehicleTypeData: [],
      vehicleModelData: [],
      remark: null,
      birthdate: null,
      anniversary: null,
      registration: null,
      isEnterVehicleMakeModal: false,
      controls: {
        registrationNumber: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        first_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        last_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicleCategory: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        group_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        group_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_brand_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        custom_filter_vehicle_type_brand_model_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicle_id: {
          value: null,
          is_disable: false
        }
      },
      is_hide_avg_meter_reading: false,
      couponData: null
    };
    if (props.couponData && Object.keys(props.couponData).length > 0 && props.couponData.phone_number) {
      this.state.couponData = props.couponData;
    } else {
      this.state.couponData = null;
    }
  }

  componentDidMount = () => {
    // this.getVehicleType();
  };

  /* init = async (index) => {
    await this.getServiceWiseVehicleTypeId(index);
    this.updateStatetoEdit(index);
  }; */

  /**From Customer detail list getting the index of the clicked vehicle */
  init = async (index) => {
    await this.getServiceWiseVehicleTypeId(index);
    if (this.props.couponData && Object.keys(this.props.couponData).length > 0 && this.props.couponData.phone_number) {
      await this.setState({
        couponData: this.props.couponData
      })
    } else {
      await this.setState({
        couponData: null
      })
    }
    this.updateStatetoEdit(index);
  };

  getServiceWiseVehicleTypeId = async (index = -1) => {
    let { selectPurposeref } = this.props;
    let is_selected_service = false;
    if (selectPurposeref && selectPurposeref.current &&
      selectPurposeref.current.state &&
      selectPurposeref.current.state.controls &&
      selectPurposeref.current.state.controls.selectedPurpose.value &&
      selectPurposeref.current.state.controls.selectedPurpose.value.type &&
      selectPurposeref.current.state.controls.selectedPurpose.value.type === 'service') {
      is_selected_service = true;
    }
    /**If selectedPurpose.value.type === service then service_id otherwise [] */
    const obj = {
      "service_id": is_selected_service ? (selectPurposeref.current &&
        selectPurposeref.current.state &&
        selectPurposeref.current.state.selectedServices ? selectPurposeref.current.state.selectedServices : []) :
        []
    };
    const { controls } = this.state;
    this.props.isLoading(true);
    let response = await CustomerManagementService.getServiceWiseVehicleTypeId(obj)
      .then((res) => {

        /* if (this.state.couponData && this.state.couponData.type && (res.data.data).findIndex(x => x.type === this.state.couponData.type) === -1) {
          let services = [];
        
          selectPurposeref.current.state.customer_services.forEach((element, i) => {
            if (element.active) {
              services.push(element.name);
            }
          });
          ModalService.openAlert("", `You have not configured for ${this.state.couponData.type_name} for ${services} service. Please update settings so you can add ${this.state.couponData.type_name} for ${services}.`, "error");
          return false;
        } */

        this.props.isLoading(false);
        let { vehicleTypeData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          vehicleTypeData = res.data.data;

          // controls["custom_filter_vehicle_type_id"].value =
          //   res.data.data[0] && res.data.data[0].vehicle_type_id;
        } else {
          vehicleTypeData = [];
        }
        /**Based on index value we set the vehicle_type_name  */
        if (index > -1 || (this.state.couponData && this.state.couponData !== null)) {
          let index_vehicle = -1;
          let customer_vehicle = {
            vehicle_type_name: ''
          }
          if (this.state.couponData && this.state.couponData !== null) {
            index_vehicle = vehicleTypeData.findIndex(x => x.type === this.state.couponData.type);
            customer_vehicle.vehicle_type_name = this.state.couponData.type;
          } else {
            customer_vehicle = this.props.customer_vehicle[index];
            index_vehicle = vehicleTypeData.findIndex(x => x.vehicle_type_id === customer_vehicle.vehicle_type_id);
          }
          if (index_vehicle <= -1) {
            let services = [];
            if (selectPurposeref && selectPurposeref.current &&
              selectPurposeref.current.state &&
              selectPurposeref.current.state.customer_services) {
              selectPurposeref.current.state.customer_services.forEach((element, i) => {
                if (element.active) {
                  services.push(element.name);
                }
              });
              if (customer_vehicle.vehicle_type_name && customer_vehicle.vehicle_type_name.toLowerCase() === 'scv') {
                customer_vehicle.vehicle_type_name = 'SCV';
              } else if (customer_vehicle.vehicle_type_name && customer_vehicle.vehicle_type_name.toLowerCase() === 'lcv') {
                customer_vehicle.vehicle_type_name = 'LCV';
              } else if (customer_vehicle.vehicle_type_name && customer_vehicle.vehicle_type_name.toLowerCase() === 'icv') {
                customer_vehicle.vehicle_type_name = 'ICV';
              }
            }
            ModalService.openAlert("", `You have not configured ${customer_vehicle.vehicle_type_name} for ${services} service. Please update settings so you can add ${customer_vehicle.vehicle_type_name} for ${services}.`, "error");
            this.props.backPage();
            return;
          }
        }
        this.setState(
          {
            vehicleTypeData,
            controls,
          },
          () => {
            if (this.state.controls.custom_filter_vehicle_type_brand_id.value) {
              this.getVehicleMake();
            }

          }
        );
        return true;
      })
      .catch((e) => {
        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        this.props.isLoading(false);
        return false;
      });
    return response;
  }

  updateStatetoEdit = (index) => {

    let { controls, registration, vehicle_meter_reading, AVG_monthly_usage, is_hide_avg_meter_reading, is_disable_personal, lcv_scv_usage_type } = this.state;
    let { selectPurposeref } = this.props;
    let is_call_api = false, vehicleData = {};
    /**For inquiry we hide the avg_meter_reading */
    if (selectPurposeref && selectPurposeref.current &&
      selectPurposeref.current.state &&
      selectPurposeref.current.state.controls &&
      selectPurposeref.current.state.controls.selectedPurpose.value &&
      selectPurposeref.current.state.controls.selectedPurpose.value.type &&
      selectPurposeref.current.state.controls.selectedPurpose.value.type === "inquiry") {
      is_hide_avg_meter_reading = true;
    } else {
      is_hide_avg_meter_reading = false;
    }

    /**Setting the data from the props */
    if (index > -1) {
      vehicleData = this.props.customer_vehicle[index];
      controls.vehicleCategory.value = vehicleData.vehicle_category;
      controls.registrationNumber.value = vehicleData.registration_number;
      controls.custom_filter_vehicle_type_id.value = vehicleData.vehicle_type_id;
      registration = vehicleData.registration_number;
      vehicle_meter_reading = vehicleData.vehicle_meter_reading;
      AVG_monthly_usage = vehicleData.vehical_avg_monthly_running === 0 ? '' : vehicleData.vehical_avg_monthly_running;
      controls.vehicle_id.value = vehicleData.vehicle_id;
      controls.vehicle_id.is_disable = true;
      is_call_api = true;
      let filter = this.state.vehicleTypeData.filter(x => x.vehicle_type_id.toLowerCase() === vehicleData.vehicle_type_id);
      /**On basis of Filter we set the usage_type value */
      if (filter.length > 0) {
        if (filter[0].type.toLowerCase() === 'car' || filter[0].type.toLowerCase() === '2 wheeler') {
          is_disable_personal = false;
        } else {
          is_disable_personal = true;

          if (filter[0].type.toLowerCase() === 'lcv' || filter[0].type.toLowerCase() === 'scv') {
            lcv_scv_usage_type = true;
            is_disable_personal = false;
          } else {
            lcv_scv_usage_type = false;
            is_disable_personal = false;
          }

        }
      } else {
        is_disable_personal = false;
      }
    } else {
      controls.vehicleCategory.value = '';
      controls.custom_filter_vehicle_type_brand_id.value = '';
      controls.custom_filter_vehicle_type_brand_model_id.value = '';
      controls.registrationNumber.value = '';
      controls.custom_filter_vehicle_type_id.value = '';
      registration = '';
      vehicle_meter_reading = '';
      AVG_monthly_usage = '';
      controls.vehicle_id.value = '';
      controls.vehicle_id.is_disable = false;
      is_disable_personal = false;
      lcv_scv_usage_type = false;

      if (this.state.couponData && this.state.couponData !== null) {
        let index_vehicle = (this.state.vehicleTypeData).findIndex(x => x.type.toLowerCase() === (this.state.couponData.type).toLowerCase());

        if (index_vehicle > -1) {
          controls["custom_filter_vehicle_type_id"].value = this.state.vehicleTypeData[0] && this.state.vehicleTypeData[index_vehicle].vehicle_type_id;

          this.handleInputChange({
            target: {
              name: "custom_filter_vehicle_type_id",
              value: controls["custom_filter_vehicle_type_id"].value,
            }
          });
          let filter = this.state.vehicleTypeData.filter(x => x.vehicle_type_id.toLowerCase() === controls["custom_filter_vehicle_type_id"].value);
          if (filter.length > 0) {
            if (filter[0].type.toLowerCase() === 'car' || filter[0].type.toLowerCase() === '2 wheeler') {
              is_disable_personal = false;
            } else {
              is_disable_personal = true;
              if (filter[0].type.toLowerCase() === 'lcv' || filter[0].type.toLowerCase() === 'scv') {
                lcv_scv_usage_type = true;
                is_disable_personal = false;
              } else {
                lcv_scv_usage_type = false;
                is_disable_personal = false;
              }
            }
          } else {
            is_disable_personal = false;
          }
          controls.vehicle_id.is_disable = true;
        }

      }

    }
    this.setState({
      controls,
      registration,
      AVG_monthly_usage,
      vehicle_meter_reading,
      is_hide_avg_meter_reading,
      is_disable_personal,
      lcv_scv_usage_type
    }, () => {
      if (is_call_api) {
        this.setVehicleBrand(vehicleData);
      }
    })

  };

  setVehicleBrand = (vehicleData) => {
    let { controls, isEnterVehicleMakeModal } = this.state;
    if (controls.custom_filter_vehicle_type_id.value) {
      let filter = this.state.vehicleTypeData && this.state.vehicleTypeData.filter(x => x.vehicle_type_id.toLowerCase() === controls.custom_filter_vehicle_type_id.value);
      if (filter.length > 0) {
        // if (filter[0].name.toLowerCase() === 'car' || filter[0].name.toLowerCase() === '2 wheeler') {
        //   controls.vehicleCategory.value = 'personal';
        // } else {
        //   controls.vehicleCategory.value = 'taxi';
        // }
        controls.vehicleCategory.touched = true;
        if (filter[0].type.toLowerCase() !== 'bus' &&
          filter[0].type.toLowerCase() !== 'truck' &&
          filter[0].type.toLowerCase() !== 'car' &&
          filter[0].type.toLowerCase() !== '2 wheeler' &&
          filter[0].type.toLowerCase() !== 'lcv' &&
          filter[0].type.toLowerCase() !== 'scv') {
          isEnterVehicleMakeModal = true;
          if (vehicleData.vehicle_type_brand_name) {
            controls.custom_filter_vehicle_type_brand_id.value = vehicleData.vehicle_type_brand_name;
          }

          if (vehicleData.vehicle_type_brand_model_name) {
            controls.custom_filter_vehicle_type_brand_model_id.value = vehicleData.vehicle_type_brand_model_name;
          }
        } else {
          isEnterVehicleMakeModal = false;
        }
      } else {
        isEnterVehicleMakeModal = false;
      }

      this.setState({
        isEnterVehicleMakeModal,
        controls
      })
    }
    if (isEnterVehicleMakeModal) {
      return
    }
    if (vehicleData.vehicle_type_id) {
      this.getVehicleMake(vehicleData.vehicle_type_id);
      controls["custom_filter_vehicle_type_brand_id"].value = {
        brand_id: vehicleData.vehicle_type_brand_id,
        name: vehicleData.vehicle_type_brand_name,
        short_number: 1,
        quality: "",
        image_url: "",
        vehicle_type: "",
      };

    }
    this.setState({ controls, isEnterVehicleMakeModal }, () => {
      if (vehicleData.vehicle_type_brand_id) {
        this.getVehicleModel(vehicleData.vehicle_type_brand_id);
      }
      if (vehicleData.vehicle_type_brand_model_id) {
        controls["custom_filter_vehicle_type_brand_model_id"].value = {
          model_id: vehicleData.vehicle_type_brand_model_id,
          name: vehicleData.vehicle_type_brand_model_name,
          short_number: 0,
          quality: "",
        };
        this.setState({ controls }, () => { });
      }
    });
  };

  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let isFormValid = false;
    let {
      vehicleCategory, custom_filter_vehicle_type_id,
    } = controls;


    if ((custom_filter_vehicle_type_id.touched === true || isSubmit)) {
      custom_filter_vehicle_type_id = Validation.notNullValidator(custom_filter_vehicle_type_id);
      custom_filter_vehicle_type_id.valid = !custom_filter_vehicle_type_id.nullValue;
      if (custom_filter_vehicle_type_id.valid === false) {
        custom_filter_vehicle_type_id.showErrorMsg = true;
      } else {
        custom_filter_vehicle_type_id.showErrorMsg = false;
      }
    }
    if ((vehicleCategory.touched === true || isSubmit)) {
      vehicleCategory = Validation.notNullValidator(vehicleCategory);
      vehicleCategory.valid = !vehicleCategory.nullValue;
      if (vehicleCategory.valid === false) {
        vehicleCategory.showErrorMsg = true;
      } else {
        vehicleCategory.showErrorMsg = false;
      }
    }

    if (vehicleCategory.valid === true &&
      custom_filter_vehicle_type_id.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls
    })
    return isFormValid;
  }

  /**Method to handle the Input change  */
  handleInputChange = (e) => {
    const controlName = e.target.name;
    // const controlValue = e.target.value;
    let { controls, isEnterVehicleMakeModal, is_disable_personal, lcv_scv_usage_type } = this.state;
    controls[e.target.name].touched = true;
    if (controlName === "custom_filter_vehicle_type_id") {
      if (controls.vehicle_id.is_disable) {
        return
      }
      let filter = this.state.vehicleTypeData.filter(x => x.vehicle_type_id.toLowerCase() === e.target.value.toLowerCase());
      if (filter.length > 0) {
        if (filter[0].type.toLowerCase() === 'car' || filter[0].type.toLowerCase() === '2 wheeler') {
          controls.vehicleCategory.value = 'personal';
        } else if (filter[0].type.toLowerCase() === 'lcv' || filter[0].type.toLowerCase() === 'scv') {
          controls.vehicleCategory.value = 'cargo';
        } else {
          controls.vehicleCategory.value = 'taxi';
        }
        controls.vehicleCategory.touched = true;
        if (filter[0].type.toLowerCase() !== 'bus' &&
          filter[0].type.toLowerCase() !== 'truck' &&
          filter[0].type.toLowerCase() !== 'car' &&
          filter[0].type.toLowerCase() !== '2 wheeler' &&
          filter[0].type.toLowerCase() !== 'lcv' &&
          filter[0].type.toLowerCase() !== 'scv') {
          isEnterVehicleMakeModal = true;
        } else {
          isEnterVehicleMakeModal = false;
        }
      } else {
        isEnterVehicleMakeModal = false;
      }
      controls["custom_filter_vehicle_type_brand_id"].value = "";
      controls["custom_filter_vehicle_type_brand_model_id"].value = "";
      if (!isEnterVehicleMakeModal) {
        this.getVehicleMake(e.target.value);
      }
      controls[e.target.name].value = e.target.value;
    } else if (controlName === "custom_filter_vehicle_type_brand_id") {
      controls["custom_filter_vehicle_type_brand_model_id"].value = "";
      if (!isEnterVehicleMakeModal && e.target.value) {
        this.getVehicleModel(e.target.value.brand_id);
      } else {
        this.getVehicleModel(null);
      }
      controls[e.target.name].value = e.target.value;
    } else if (controlName === "vehicleCategory") {
      if (!is_disable_personal) {
        controls[e.target.name].value = e.target.value;
      } else {
        return;
      }
    } else {
      controls[e.target.name].value = e.target.value;
    }

    if (controlName === "custom_filter_vehicle_type_id" ||
      controlName === "vehicleCategory") {
      let filter = this.state.vehicleTypeData.filter(x => x.vehicle_type_id.toLowerCase() === e.target.value.toLowerCase());
      if (filter.length > 0) {
        if (filter[0].type.toLowerCase() === 'car' || filter[0].type.toLowerCase() === '2 wheeler') {
          is_disable_personal = false;
        } else {
          is_disable_personal = true;

          if (filter[0].type.toLowerCase() === 'lcv' || filter[0].type.toLowerCase() === 'scv') {
            lcv_scv_usage_type = true;
            is_disable_personal = false;
          } else {
            lcv_scv_usage_type = false;
            is_disable_personal = false;
          }

        }
      } else {
        is_disable_personal = false;
      }
    }
    this.setState({
      controls, isEnterVehicleMakeModal,
      is_disable_personal,
      lcv_scv_usage_type
    });
    this.handleValidation();
  };


  onChange = (e) => {
    if (e.target.name === 'AVG_monthly_usage' ||
      e.target.name === 'vehicle_meter_reading') {
      if (!isNaN(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleChangeDatePicker = (date) => {
    const { controls } = this.state;
    controls["started_at"].value = date;
    this.setState({ controls });
  };

  /**Method to get the different vehicle type */
  getVehicleType = () => {
    const obj = {};
    const { controls } = this.state;
    CampaignService.getVehicleTypeCamp(obj)
      .then((res) => {
        let { vehicleTypeData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          vehicleTypeData = res.data.data;

          // controls["custom_filter_vehicle_type_id"].value =
          //   res.data.data[0] && res.data.data[0].vehicle_type_id;
        } else {
          vehicleTypeData = [];
        }
        this.setState(
          {
            vehicleTypeData,
            controls,
          },
          () => {
            if (this.state.controls.custom_filter_vehicle_type_brand_id.value) {
              this.getVehicleMake();
            }

          }
        );
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  };

  /**Getting the brand of the vehicle */
  async getVehicleMake(custom_filter_vehicle_type_id = null) {
    let { controls, vehicleMakeData, vehicleModelData } = this.state;
    try {
      if (!controls.custom_filter_vehicle_type_id.value && !custom_filter_vehicle_type_id) {
        return;
      }
      this.props.isLoading(true);
      let vehicle_type_id = controls.custom_filter_vehicle_type_id.value ? controls.custom_filter_vehicle_type_id.value : null;
      if (custom_filter_vehicle_type_id) {
        vehicle_type_id = custom_filter_vehicle_type_id;
      }
      const { data } = await WarrantyRegistrationService.getVehicalMake({
        vehicle_type_id: vehicle_type_id,
      });

      if (data && data.data.length > 0) {
        const model = _.filter(data.data, (element) => {
          return (
            element.brand_id ===
            controls.custom_filter_vehicle_type_brand_id.value.brand_id
          );
        });
        if (model.length > 0) {
          controls.custom_filter_vehicle_type_brand_id.value = model[0];
        }
        vehicleMakeData = data.data;
      } else {
        vehicleMakeData = [];
      }

      vehicleMakeData = [];
      if (data && data.data.length > 0) {
        vehicleMakeData = data.data;
      } else {
        vehicleMakeData = [];
      }
      this.props.isLoading(false);
      this.setState((prevState) => {
        return {
          ...prevState,
          vehicleMakeData,
          controls,
          vehicleModelData,
          isLoading: false,
        };
      });
    } catch (error) {
      this.props.isLoading(false);
      return error;
    }
  }

  /**Method to get the vehicleModel */
  async getVehicleModel(vehicle_brand_id = null) {
    try {
      let { controls, vehicleModelData } = this.state;
      const { custom_filter_vehicle_type_brand_id } = controls;
      this.props.isLoading(true);
      if (!custom_filter_vehicle_type_brand_id.value.brand_id && !vehicle_brand_id) {
        // vehicleModelData = this.state.vehicleModelData = [];
        // this.props.isLoading(false);
        // this.setState((prevState) => {
        //   return { ...prevState, vehicleModelData, controls, isLoading: false };
        // });
        return;
      }
      let brand_id = custom_filter_vehicle_type_brand_id.value.brand_id ? custom_filter_vehicle_type_brand_id.value.brand_id : null;
      if (vehicle_brand_id) {
        brand_id = vehicle_brand_id;
      }
      const { data } = await WarrantyRegistrationService.getVehicalModel({
        vehicle_type_brand_id:
          brand_id,
      });

      if (data && data.data.length > 0) {
        if (controls.custom_filter_vehicle_type_brand_model_id.value && controls.custom_filter_vehicle_type_brand_model_id.value.model_id) {
          const model = _.filter(data.data, (element) => {
            return (
              element.model_id ===
              controls.custom_filter_vehicle_type_brand_model_id.value.model_id
            );
          });
          if (model.length > 0) {
            controls.custom_filter_vehicle_type_brand_model_id.value = model[0];
          }
        }


        if (data.data && data.data.length > 0) {
          let other_findIndex = data.data.findIndex(x => x.name && x.name.toLowerCase() === 'other');
          if (other_findIndex > -1) {
            let other = data.data[other_findIndex];
            data.data.splice(other_findIndex, 1);
            data.data.push(other);
          }
        }

        vehicleModelData = data.data;
      } else {
        vehicleModelData = [];
      }
      this.props.isLoading(false);
      this.setState((prevState) => {
        return { ...prevState, vehicleModelData, controls, isLoading: false };
      });
      this.handleValidation();
    } catch (error) {
      this.props.isLoading(false);
    }
  }


  /**Method to add the new Vehicle */
  addVehicle = async (customer_id = null) => {
    const { controls } = this.state;
    let reqData = {
      phone_number: this.props.phone_number,
      vehicle_type_id: controls.custom_filter_vehicle_type_id.value,
      vehicle_category: controls.vehicleCategory.value,
      registration_number: this.state.registration,
      vehical_avg_monthly_running: this.state.AVG_monthly_usage,
      vehicle_meter_reading: this.state.vehicle_meter_reading,
    };

    if (!this.state.isEnterVehicleMakeModal) {
      reqData.vehicle_type_brand_model_id =
        controls.custom_filter_vehicle_type_brand_model_id.value &&
          Object.keys(controls.custom_filter_vehicle_type_brand_model_id.value)
            .length > 0
          ? controls.custom_filter_vehicle_type_brand_model_id.value.model_id
          : "";
      reqData.vehicle_type_brand_id =
        controls.custom_filter_vehicle_type_brand_id.value && controls.custom_filter_vehicle_type_brand_id.value.brand_id ?
          controls.custom_filter_vehicle_type_brand_id.value.brand_id : null;
    } else {
      reqData.vehicle_type_brand_model_name = controls.custom_filter_vehicle_type_brand_model_id.value;
      reqData.vehicle_type_brand_name = controls.custom_filter_vehicle_type_brand_id.value;
    }
    if (controls.vehicle_id.value) {
      reqData.vehicle_id = controls.vehicle_id.value;
    }
    if (customer_id) {
      reqData.customer_id = customer_id;
    } else if (this.props.customer_detail.customer_id) {
      reqData.customer_id = this.props.customer_detail.customer_id;
    }
    this.props.isLoading(true);
    let response = CustomerManagementService.addVehicle(reqData)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data && res.data.data.customer_vehicle_id) {
          let { controls } = this.state;
          controls.vehicle_id.value = res.data.data.customer_vehicle_id;
          this.setState({
            controls
          })
        }
        if (this.props.is_edit_from_customer_list) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success",
          }).then((isConfirm) => {
          });
        }
        this.props.isLoading(false);
        this.props.getCustomerVehicle();
        return res.data.data;
      })
      .catch((e) => {
        this.props.isLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        return false;
      });
    return response;
  };

  render() {
    const { controls, vehicleMakeData, vehicleModelData } = this.state;

    return (
      <>
        <Row className="ML-30 T-20">
          <Col xl="6" md="12" xs="12">
            <FormGroup>
              <Label for="common">
                Vehicle Type &nbsp;
                <span className="required-field">*</span>
              </Label>
              <select
                placeholder="Select"
                className={
                  controls.vehicle_id.is_disable
                    ? "select-group name is-disabled"
                    : "select-group name"
                }
                name="custom_filter_vehicle_type_id"
                value={
                  controls.custom_filter_vehicle_type_id &&
                  controls.custom_filter_vehicle_type_id.value
                }
                disabled={controls.vehicle_id.is_disable}
                onChange={(e) => {
                  this.handleInputChange(e);
                }}
              >
                <option value="">Select vehicle type</option>
                {this.state.vehicleTypeData.map((item) => (
                  <option
                    className="text-capitalize"
                    value={item.vehicle_type_id}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
              {controls.custom_filter_vehicle_type_id.showErrorMsg && <div className="error show_error">
                *Please select vehicle type.
              </div>}
            </FormGroup>
          </Col>
          <Col xl="6" md="12" xs="12">
            <Label for="common">
              <span className="Usage-Type">Usage Type</span> &nbsp;
              <span className="required-field">*</span>
            </Label>
            {this.state.lcv_scv_usage_type ? (<Row className=" margin-top-5">
              <Col xl="6" xs="12" ms="12" className="mt-1">
                <div>
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="vehicleCategory"
                      value="cargo"
                      checked={
                        controls.vehicleCategory.value &&
                        controls.vehicleCategory.value.toLowerCase() ===
                        "cargo"
                      }
                      onChange={(e) =>
                        this.handleInputChange({
                          target: {
                            name: "vehicleCategory",
                            value: "cargo",
                          },
                        })
                      }
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span style={{ marginLeft: "10px" }}>Cargo</span>
                </div>
              </Col>

              <Col xl="4" xs="12" ms="12" className="mt-1 margin-right-30">
                <div className="d-flex-row">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="vehicleCategory"
                      value="passenger"
                      checked={
                        controls.vehicleCategory.value &&
                        controls.vehicleCategory.value.toLowerCase() === "passenger"
                      }
                      onChange={(e) =>
                        this.handleInputChange({
                          target: {
                            name: "vehicleCategory",
                            value: "passenger",
                          },
                        })
                      }
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span style={{ marginLeft: "10px" }}>Passenger</span>
                </div>
              </Col>
              {controls.vehicleCategory.showErrorMsg && <div
                className="error show_error"
                style={{ marginLeft: '21px', marginTop: '2px' }}>
                *Please select usage type.
              </div>}
              <Col xl="6" xs="2" ms="2" />
            </Row>)
              :
              (<Row className=" margin-top-5">
                <Col xl="6" xs="12" ms="12" className="mt-1">
                  <div className={this.state.is_disable_personal ? 'opacity-0-5 d-flex-row' : 'd-flex-row'}>
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="vehicleCategory"
                        value="personal"
                        checked={
                          controls.vehicleCategory.value &&
                          controls.vehicleCategory.value.toLowerCase() ===
                          "personal"
                        }
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "vehicleCategory",
                              value: "personal",
                            },
                          })
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: "10px" }}>Personal</span>
                  </div>
                </Col>

                <Col xl="4" xs="12" ms="12" className="mt-1 margin-right-30">
                  <div className="d-flex-row">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="vehicleCategory"
                        value="taxi"
                        checked={
                          controls.vehicleCategory.value &&
                          controls.vehicleCategory.value.toLowerCase() === "taxi"
                        }
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "vehicleCategory",
                              value: "taxi",
                            },
                          })
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: "10px" }}>Commercial </span>
                  </div>
                </Col>
                {controls.vehicleCategory.showErrorMsg && <div
                  className="error show_error"
                  style={{ marginLeft: '21px', marginTop: '2px' }}>
                  *Please select usage type.
                </div>}
                <Col xl="6" xs="2" ms="2" />
              </Row>)}
          </Col>
        </Row>

        <Row className="ML-30 T-10" id="veh-details">
          <Col lg="6" sm="6" md="6" xs="12">
            <FormGroup>
              <Label for="common">Vehicle Make</Label>
              {
                this.state.isEnterVehicleMakeModal ?
                  <Input
                    type="text"
                    autoComplete="nofill"
                    className="name"
                    name="custom_filter_vehicle_type_brand_id"
                    id="custom_filter_vehicle_type_brand_id"
                    value={controls.custom_filter_vehicle_type_brand_id.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  /> :
                  <div className="d-flex-row vehical-sku-id-search add-new-vehicle-model">
                    <Typeahead
                      className="name"
                      id="custom_filter_vehicle_type_brand_id"
                      name="custom_filter_vehicle_type_brand_id"
                      selected={
                        controls.custom_filter_vehicle_type_brand_id &&
                          controls.custom_filter_vehicle_type_brand_id.value &&
                          controls.custom_filter_vehicle_type_brand_id.value.brand_id
                          ? [controls.custom_filter_vehicle_type_brand_id.value]
                          : []
                      }
                      filterBy={(option, props) => {
                        if (props.selected.length) {
                          return true;
                        }
                        return (
                          option.name
                            .toLowerCase()
                            .indexOf(props.text.toLowerCase()) !== -1 ||
                          option.name.toLowerCase().indexOf("other") !== -1
                        );
                      }}
                      labelKey="name"
                      value={
                        controls.custom_filter_vehicle_type_brand_id &&
                          controls.custom_filter_vehicle_type_brand_id.value &&
                          controls.custom_filter_vehicle_type_brand_id.value.brand_id
                          ? [controls.custom_filter_vehicle_type_brand_id.value]
                          : []
                      }
                      options={vehicleMakeData}
                      placeholder="Search..."
                      onChange={(selectedModel) =>
                        this.handleInputChange({
                          target: {
                            name: "custom_filter_vehicle_type_brand_id",
                            value: selectedModel[0],
                          },
                        })
                      }
                    />
                    <FontAwesomeIcon
                      className="serach-icon"
                      icon={faSearch}
                      color="black"
                    />
                  </div>
              }
            </FormGroup>
          </Col>
          <Col lg="6" sm="6" md="6" xs="12">
            <FormGroup>
              <Label for="common">Vehicle Model</Label>
              {
                this.state.isEnterVehicleMakeModal ?
                  <Input
                    type="text"
                    autoComplete="nofill"
                    className="name"
                    name="custom_filter_vehicle_type_brand_model_id"
                    id="custom_filter_vehicle_type_brand_model_id"
                    value={controls.custom_filter_vehicle_type_brand_model_id.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  /> :
                  <div className="d-flex-row vehical-sku-id-search add-new-vehicle-model">
                    <Typeahead
                      className="name"
                      id="custom_filter_vehicle_type_brand_model_id"
                      name="custom_filter_vehicle_type_brand_model_id"
                      selected={
                        controls.custom_filter_vehicle_type_brand_model_id.value
                          ? [
                            controls.custom_filter_vehicle_type_brand_model_id
                              .value,
                          ]
                          : []
                      }
                      filterBy={(option, props) => {
                        if (props.selected.length) {
                          return true;
                        }
                        return (
                          option.name
                            .toLowerCase()
                            .indexOf(props.text.toLowerCase()) !== -1 ||
                          option.name.toLowerCase().indexOf("other") !== -1
                        );
                      }}
                      labelKey="name"
                      value={
                        controls.custom_filter_vehicle_type_brand_model_id.value
                      }
                      options={vehicleModelData}
                      placeholder="Search..."
                      onChange={(selectedModel) =>
                        this.handleInputChange({
                          target: {
                            name: "custom_filter_vehicle_type_brand_model_id",
                            value: selectedModel[0],
                          },
                        })
                      }
                    />
                    <FontAwesomeIcon
                      className="serach-icon"
                      icon={faSearch}
                      color="black"
                    />
                  </div>
              }
            </FormGroup>
          </Col>
        </Row>

        <Row className="ML-30 T-10">
          <Col style={{ width: "88%" }} xl="6" md="12" xs="12">
            <FormGroup className="name">
              <Label for="common">Vehicle Registration Number</Label>
              {/* <MaskedInput
                mask="1111-WW-11"
                mask="WW-WW-WW-WWWW"
                placeholder="_ _  _ _  _ _  _ _ _ _"
                size="11"
                formatCharacters={{
                  'W': {
                    validate(char) { return /\w/.test(char) },
                    transform(char) { return char.toUpperCase() }
                  }
                }}
              /> */}
              <MaskedInput
                mask="WW-WW-WWW-WWWW"
                formatCharacters={{
                  W: {
                    validate(char) {
                      return /^[\w\s]+$/.test(char);
                    },
                    transform(char) {
                      return char.toUpperCase();
                    },
                  },
                }}
                type="text"
                size="10"
                placeholder="_ _  _ _  _ _ _  _ _ _ _"
                className="form-control"
                name="registration"
                id="registration"
                value={this.state.registration}
                onChange={this.onChange}
                style={{ textAlign: "left" }}
              />
            </FormGroup>
            <div className="under_reg T-35">DL - 01- XY -1234</div>
          </Col>
          {
            !this.state.is_hide_avg_meter_reading &&
            <Col xl="6" md="12" xs="12">
              <FormGroup className="name">
                <Label for="common">Vehicle Meter Reading</Label>
                <Input
                  type="text"
                  autoComplete="nofill"
                  className="name"
                  name="vehicle_meter_reading"
                  id="vehicle_meter_reading"
                  value={this.state.vehicle_meter_reading}
                  onChange={this.onChange}
                  placeholder="Type here"
                />
              </FormGroup>
            </Col>
          }
        </Row>
        {
          !this.state.is_hide_avg_meter_reading &&
          <Col className="ML-30 T-10" xl="6" md="12" xs="12">
            <FormGroup className="name">
              <Label for="common">Avg. Monthly Usage (Kms)</Label>
              <Input
                type="text"
                autoComplete="nofill"
                className="name"
                name="AVG_monthly_usage"
                id="AVG_monthly_usage"
                value={this.state.AVG_monthly_usage}
                onChange={this.onChange}
                placeholder="Type here"
              />
            </FormGroup>
          </Col>
        }
      </>
    );
  }
}

export default VehicleDetail;
