import React, { Component } from "react";
import {
  Modal,
  Table,
  ModalBody,
} from "reactstrap";
// import Tooltip from "rc-tooltip";
// import Slider from "rc-slider";
import "react-input-range/lib/css/index.css";
import lodash from "lodash"; // library added for debounce functionality

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import GiftCatalogService from "../../services/GiftCatalogService";
import StorageService from "../../services/Storage";
import UserService from "../../services/UserService";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
// import { messages } from "../../constants/constant.message";
import EntryScreenCatelog from "../../components/CatelogEntryPage/EntryScreenCatelog";
import { formatNumber } from "../../utils/utils";
// import RedeemCatelogCart from "../../components/RedeemCatelogCart/RedeemCatelogCart";
import PrimaryPointsReportService from "../../services/PrimaryPointsReportService";
import PrimaryOfftakeSummary from "../PrimaryPointsReport/components/PrimaryOfftakeSummary";
// import Pagination from "../../components/Pagination/Pagination";
// import HoDashboardFilter from "../../modal/HoDashboardFilter/HoDashboardFilter";

import "./GiftCatalog.css";
import DashboardService from "../../services/DashboardService";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import Storage from "../../services/Storage";
import _ from "lodash";
import ModalService from "../../services/ModalService";
import GiftItemDetail from "../GiftItem/GiftItemDetail";
import RedemptionCatalogV2 from "../RedemptionCatalogV2/RedemptionCatalogV2";
import { Permissions } from "../../constants/constant.permissions";
import { POINT_MAX_RANGE_LIMIT } from "../../utils/constants";
// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);
// const Handle = Slider.Handle;
const pageSize = 15;
// const NODATAMSG = messages.COMMON.NO_DATA_FOUND;
// const handle = (props) => {
//   const { value, dragging, index, ...restProps } = props;
//   return (
//     <Tooltip
//       prefixCls="rc-slider-tooltip"
//       overlay={value}
//       visible={value}
//       placement="top"
//       key={index}
//     >
//       <Handle value={value} {...restProps} />
//     </Tooltip>
//   );
// };
const maxValue = 50000000;
let sapId = null;
class GiftCatalogV2 extends Component {
  requestNo = 0;

  offTakeSummary = {
    totalEarnedPoints: 0,
    pointsSummary: [],
  };

  constructor(props) {
    super(props);
    const placeOrderCartItems = Storage.getRedeemCartItems();
    this.state = {
      sidebarOpen: false,
      count: 0,
      pageOrder: 1,
      pageSizeOrder: 10,
      totalPages: 0,
      searchOrder: "",
      filterControls: {},
      type: "",
      navigation: [],
      totalPrimaryPoints: 0,
      totalPrivionalPoints: 0,
      totalPoints: 0,
      permission: false,
      summary: [],
      totalPoint: 0,
      selectedItem: null,
      qty: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      isDisplayCart: false,
      orderDetails: [],
      items: [],
      categories: [],
      totalItems: 0,
      min: 0,
      max: maxValue,
      selectedMinValue: 0,
      selectedMaxValue: maxValue,
      category: null,
      subCategory: null,
      selectedCategoryIndex: -1,
      selectedSubCategoryIndex: -1,
      subCategoryOpenIndex: -1,
      search: "",
      page: 1,
      balancePoints: 0,
      isLoading: false,
      isLoadingOrder: false,
      hasMore: true,
      scrollYValue: 0,
      rangeType: "SelectRange",
      isMoreCategorySelected: false,
      isshowGiftCat: !this.props.location.search,
      minValue: null,
      maxValue: null,
      errorMsg: false,
      MaxCount: null,
      redeemptionData: {
        available_points: "",
        lower_month: "",
        redeem_points: "",
        upper_month: "",
      },
      activeTab: 0,
      activeTabReport: 0,
      placeOrderCartItems: placeOrderCartItems || [],
      isOpenPointsModel: false,
      yearOptions: [],
      year: null,
      year_name: null,
      headerYear: null,
    };
    this.getGiftItemOnRangeChange = lodash.debounce(
      this.getGiftItemOnRangeChange,
      1000
    );
    this.handleNextVal = lodash.debounce(this.handleNextVal, 100);
    window.onscroll = (e) => {
      this.setState({ scrollYValue: window.scrollY });
      this.handleNextVal();
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentDidMount() {
    GoogleAnalticsService.pageView("Redemption Catalogue View");

    this.getGiftItemCategories();
    this.getRedeemptionDashbaord();
    this.coinsOnCart();
    this.filterAPI();

    this.backToTop();
    this.getOrders();
    this.getDashBoardCounts();
    const query = new URLSearchParams(this.props.location.search);
    const history = query.get("history");

    if (history === true || history === "true") {
      this.changeTab(1);
    }

    const category = query.get("category");
    const subCategory = query.get("sub_category");
    let index = query.get("index");
    let subCategoryIndex = query.get("sub_index");
    let selectedMinValue = query.get("min");
    let selectedMaxValue = query.get("max");
    let rangeType;
    if (query.get("rangeType")) {
      rangeType = query.get("rangeType");
    }
    const minValue = query.get("min") ? query.get("min") : 0;
    const maxValue = query.get("max") ? query.get("min") : POINT_MAX_RANGE_LIMIT;

    if (index) {
      index = parseInt(index);
      if (subCategoryIndex) {
        subCategoryIndex = parseInt(subCategoryIndex);
      } else {
        subCategoryIndex = -1;
      }
    } else {
      index = -1;
    }

    if (selectedMinValue) {
      selectedMinValue = parseInt(selectedMinValue);
    }

    if (selectedMaxValue) {
      selectedMaxValue = parseInt(selectedMaxValue);
    }

    const userDetail = StorageService.getUserDetail();
    const { type: dealerRole } = userDetail;
    const permission = userDetail.permissions.includes(Permissions.PRIMARY_POINTS_REDEMPTION);
    this.setState(
      {
        type: userDetail.type,
        permission,
        category: category || this.state.category,
        selectedCategoryIndex: index,
        selectedSubCategoryIndex: subCategoryIndex,
        subCategory: subCategory || this.state.subCategory,
        subCategoryOpenIndex: index,
        selectedMaxValue,
        selectedMinValue,
        isMoreCategorySelected: index > 4,
        dealerRole,
        rangeType: rangeType || "SelectRange",
        maxValue,
        minValue,
      },
      () => {
        this.getGiftCatalogItems();
      }
    );

    const uDetail = StorageService.getUserDetail();
    sapId = uDetail.uuid;
  }

  filterAPI = () => {
    const { year } = this.state;

    const filter = {
      y: year || null,
    };
    this.setState({ isLoader: true });
    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          this.setState({ isLoader: false });

          this.setState(
            {
              yearOptions: res.data.data.year ? res.data.data.year : [],
            },
            () => {
              if (
                !this.state.year &&
                !this.state.headerYear &&
                this.state.yearOptions &&
                this.state.yearOptions[0]
              ) {
                this.setState(
                  {
                    year: this.state.yearOptions[0].value,
                    year_name: this.state.yearOptions[0].name,
                  },
                  () => {
                    this.getOfftakeSummary(this.state.year);
                    this.getPrimaryPoints(this.state.year);
                  }
                );
              } else {
                this.getOfftakeSummary(this.state.year);
                this.getPrimaryPoints(this.state.year);
              }
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        console.log("err", err);
      });
  };

  onChangeYear = (e) => {
    this.setState(
      {
        year: e && e.target && e.target.value,
      },
      () => {
        this.getOfftakeSummary(this.state.year);
        this.getPrimaryPoints(this.state.year);
      }
    );
  };

  getPrimaryPoints = (year) => {
    this.setState({ isLoader: true });
    DashboardService.getPrimaryPoints(sapId, year)
      .then((data) => {
        const res = data.data.data;

        const primaryPoints = res.primaryPoints;
        const provisionalPoints = res.provisionalPoints;
        this.setState({ isLoader: false });
        // const year = res.year;
        this.setState((prevState) => {
          return {
            ...prevState,
            totalPrimaryPoints: primaryPoints,
            totalPrivionalPoints: provisionalPoints,
            totalPoints: primaryPoints + provisionalPoints,
          };
        });
      })
      .catch((e) => {
        this.setState({ isLoader: false }, () => { });
      });
  };

  getOfftakeSummary = (year) => {
    this.setState((prevState) => {
      return { ...prevState, isLoading: true };
    });
    this.setState({ isLoader: true });
    PrimaryPointsReportService.getOfftakeSummary(sapId, year)
      .then((data) => {
        this.offTakeSummary = {
          totalEarnedPoints: 0,
          pointsSummary: data.data.data,
        };
        this.setState({ isLoader: false });
        this.offTakeSummary.pointsSummary = _.filter(
          this.offTakeSummary.pointsSummary,
          (element) => {
            return (
              element.label.indexOf("uarter") !== -1 || element.points !== 0
            );
          }
        );
        this.setState((prevState) => {
          return { ...prevState, isLoading: false };
        });
      })
      .catch((e) => {
        this.setState({ isLoader: false });
        this.setState((prevState) => {
          return { ...prevState, isLoading: false };
        });
      });
  };

  coinsOnCart = async () => {
    let { coinsOnCart, placeOrderCartItems } = this.state;
    let tempCoinsOnCart = 0;
    placeOrderCartItems.forEach((element) => {
      tempCoinsOnCart =
        element.redeem_points * element.total_quantity + tempCoinsOnCart;
    });
    coinsOnCart = tempCoinsOnCart;
    this.setState({
      coinsOnCart,
    });
  };

  applyFilter = (controls) => {
    let isFilterApply = null;
    if (controls && controls.isFilterReset === true) {
      isFilterApply = false;
    } else {
      isFilterApply = true;
    }
    const mapZoneToZoneName = {
      nz: "North Zone",
      sz1: "South Zone 1",
      sz2: "South Zone 2",
      wz: "West Zone",
      ez: "East Zone",
    };
    let obj = {
      type: null,
      refName: null,
    };
    let type = null;
    let refName = null;
    let selectedMonth = this.state.selectedMonth;
    let selectedYear = this.state.selectedYear;
    this.onCloseSidebar();
    if (controls.area.value) {
      type = "area";
      refName = controls.area.value;
    } else if (controls.region.value) {
      type = "region";
      refName = controls.region.value;
    } else if (controls.zone.value) {
      type = "zone";
      refName = controls.zone.value;
    }

    if (type && refName) {
      obj.type = type;
      obj.refName = refName;
    } else {
      obj = {};
    }

    if (controls.month.value) {
      selectedMonth = controls.month.value;
    }

    if (controls.year.value) {
      selectedYear = controls.year.value;
    }
    const navigation = [];
    if (controls.zone.value) {
      const navObj = {
        name: mapZoneToZoneName[controls.zone.value],
        type: "zone",
        ref: controls.zone.value,
      };
      navigation.push(navObj);
    }

    if (controls.region.value) {
      const navObj = {
        name: controls.region.value,
        type: "region",
        ref: controls.region.value,
      };
      navigation.push(navObj);
    }

    if (controls.area.value) {
      const navObj = {
        name: controls.area.value,
        type: "area",
        ref: controls.area.value,
      };
      navigation.push(navObj);
    }
    this.setState(
      {
        pageOrder: 1,
        filterControls: controls,
        navigation,
        selectedMonth,
        selectedYear,
        isFilterApply,
      },
      () => {
        this.getOrders();
      }
    );
  };

  onSetSidebarOpen(open) {
    document.body.style.overflow = "hidden";
    this.setState((prevState) => {
      return { ...prevState, sidebarOpen: open };
    });
  }

  onCloseSidebar = () => {
    document.body.style.overflow = "auto";
    this.setState((prevState) => {
      return { ...prevState, sidebarOpen: false };
    });
  };

  handleSearchChangeOrder = (e) => {
    const q = e.target.value;
    this.searchData(q);
  };

  searchData = (search) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          filterApplied: true,
          searchOrder: search,
          pageOrder: 1,
        };
      },
      () => {
        this.getOrders();
      }
    );
  };

  handlePageChange = (pageOrder) => {
    this.setState({ pageOrder });
    this.setState((prevState) => {
      return {
        ...prevState,
        pageOrder,
      };
    });
    setTimeout(() => {
      this.getOrders();
    }, 1);
  };

  handleInputChangePageSize = (e) => {
    let { pageSizeOrder } = this.state;
    pageSizeOrder = e.target.value;
    this.setState((prevState) => {
      return {
        ...prevState,
        pageSizeOrder,
        pageOrder: 1,
      };
    });
    setTimeout(() => {
      this.getOrders();
    }, 1);
  };

  getOrders = async () => {
    try {
      this.setState({
        isLoadingOrder: true,
      });
      let { orderDetails, count, totalPages, filterControls } = this.state;

      const reqData = {};
      reqData.pageOrder = this.state.pageOrder;
      reqData.pageSize = this.state.pageSizeOrder;

      if (this.state.searchOrder && this.state.searchOrder !== "") {
        reqData.q = this.state.searchOrder;
      }

      if (filterControls && filterControls.area && filterControls.area.value) {
        reqData.area = filterControls.area.value;
      } else {
        reqData.area = null;
      }

      if (
        filterControls &&
        filterControls.region &&
        filterControls.region.value
      ) {
        reqData.region = filterControls.region.value;
      } else {
        reqData.region = null;
      }

      if (filterControls && filterControls.zone && filterControls.zone.value) {
        reqData.zone = filterControls.zone.value;
      } else {
        reqData.zone = null;
      }

      const { data } = await GiftCatalogService.getOrders(reqData);

      if (data && data.success) {
        orderDetails = data.data.orderHistory;
        count = data.data.total;
        totalPages = Math.ceil(count / this.state.pageSizeOrder);
        this.setState({ orderDetails, count, totalPages });
        this.setState({
          isLoadingOrder: false,
        });
      } else {
        this.setState({
          isLoadingOrder: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoadingOrder: false,
      });
      console.log(error);
    }
  };

  checkedExistingCartAdd(item_id) {
    const { placeOrderCartItems } = this.state;
    const indexCartItem =
      placeOrderCartItems &&
      placeOrderCartItems.findIndex((x) => x.uuid === item_id);
    return !(indexCartItem <= -1);
  }

  getRedeemptionDashbaord = () => {
    this.setState({
      isLoading: true,
    });
    DashboardService.getRedeemptionDashbaord()
      .then((res) => {
        let { redeemptionData, balancePoints } = this.state;
        if (res.data.data && Object.keys(res.data.data).length > 0) {
          redeemptionData = res.data.data;
          balancePoints = res.data.data.available_points;
        }
        this.setState({
          redeemptionData,
          balancePoints,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.setFullVoucherPoint();
          }
        );
      });
  };

  getGiftCatalogItems = () => {
    // if (this.state.page <= 1) {
    this.requestNo++;
    const reqNo = this.requestNo;
    this.setState({ isLoading: true });
    // }
    const {
      category = null,
      subCategory = null,
      selectedMinValue = null,
      selectedMaxValue = null,
      search = null,
      // balancePoints,
    } = this.state;
    GiftCatalogService.getGiftItems(
      this.state.page,
      pageSize,
      category,
      subCategory,
      selectedMinValue,
      selectedMaxValue,
      search
    )
      .then((data) => {
        // this.setState({ isLoading: false });
        const itemsData = data.data && data.data.data ? data.data.data : null;
        if (reqNo === this.requestNo) {
          if (itemsData) {
            const items = itemsData.data ? itemsData.data : [];
            const count = itemsData.count ? parseInt(itemsData.count) : 0;
            // const balancePoints = itemsData.balance_points
            //   ? itemsData.balance_points
            //   : 0;
            let finalItems = [];
            if (this.state.page === 1) {
              finalItems = items;
            } else {
              finalItems = this.state.items.concat(items);
            }
            if (finalItems.length >= count) {
              this.setState({ hasMore: false });
            }

            // let index = finalItems.findIndex(x => x.name ==="Full Credit Note");
            // if(index > -1) {
            //   finalItems[index].redeem_points = balancePoints
            // }

            this.setState(
              {
                items: finalItems,
                totalItems: count,
                isLoading: false,
              },
              () => {
                this.highlightSearchKeyWords(search);
                this.setQuantity();
                setTimeout(() => {
                  this.setFullVoucherPoint();
                }, 2000);
              }
            );
          }
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  setFullVoucherPoint = () => {
    const { items, balancePoints } = this.state;

    const index = items.findIndex((x) => x.name === "Full Credit Note");
    if (index > -1) {
      items[index].redeem_points = balancePoints;
    }

    this.setState({
      items,
    });
  };

  setQuantity = () => {
    const { placeOrderCartItems, items } = this.state;

    if (placeOrderCartItems && placeOrderCartItems.length > 0) {
      placeOrderCartItems.forEach((element) => {
        const i = items.findIndex((x) => x.uuid === element.uuid);
        if (i > -1) {
          items[i].total_quantity = element.total_quantity;
        }
      });
    }
  };

  highlightSearchKeyWords = () => {
    const { search, items } = this.state;
    if (search) {
      const search_text = new RegExp(`${search}`, "igm");

      for (let i = 0; i < items.length; i++) {
        if (items[i].title) {
          items[i].title = String(items[i].title).replace(
            search_text,
            `<span style='color: #fdbb1f'>${search}</span>`
          );
        }

        items[i].description = String(items[i].description).replace(
          search_text,
          (searcha) => {
            return `<span style='color: #fdbb1f'>${searcha}</span>`;
          }
        );
      }
    }

    this.setState({
      items,
    });
  };

  getGiftItemCategories = () => {
    GiftCatalogService.getGiftFilterDetails()
      .then((data) => {
        const query = new URLSearchParams(this.props.location.search);
        let selectedMaxValue = query.get("max");
        let selectedMinValue = query.get("min");

        if (selectedMaxValue) {
          selectedMaxValue = parseInt(selectedMaxValue);
        } else {
          selectedMaxValue = null;
        }
        if (selectedMinValue) {
          selectedMinValue = parseInt(selectedMinValue);
        } else {
          selectedMinValue = null;
        }
        const filterDetails =
          data.data && data.data.data ? data.data.data : null;
        if (filterDetails) {
          const categories = filterDetails.categories
            ? filterDetails.categories
            : [];
          const min = filterDetails.min;
          const max = filterDetails.max;

          for (let i = 0; i < categories.length; i++) {
            const currentData = categories[i];
            currentData.isSubCategoryOpen = false;
          }

          this.setState({
            categories,
            min,
            max,
            MaxCount: max,
            // selectedMinValue: min,
            // selectedMaxValue: max
          });
          if (selectedMaxValue) {
            this.setState({ selectedMaxValue });
          } else {
            this.setState({ selectedMaxValue: max });
          }
          if (selectedMinValue) {
            this.setState({ selectedMinValue });
          } else {
            this.setState({ selectedMinValue: min });
          }
        }
      })
      .catch((e) => { });
  };

  openProductDetail = (item) => {
    GoogleAnalticsService.event("Redemption Catalogue", "Catalogue List Click");
    /* const obj = {
      u: uuid,
    };
     if (this.state.category) {
      obj.category = this.state.category;
    }
    if (this.state.subCategory) {
      obj.sub_category = this.state.subCategory;
    }
    if (this.state.selectedCategoryIndex !== -1) {
      obj.index = this.state.selectedCategoryIndex;
    }
    if (this.state.selectedSubCategoryIndex !== -1) {
      obj.sub_index = this.state.selectedSubCategoryIndex;
    }
    if (this.state.selectedMinValue) {
      obj.min = this.state.selectedMinValue;
    }
    if (this.state.selectedMaxValue) {
      obj.max = this.state.selectedMaxValue;
    }
    if (this.state.rangeType) {
      obj.rangeType = this.state.rangeType;
    } 

    const searchParams = new URLSearchParams(obj);

    this.props.history.push(`/product-detail?${searchParams.toString()}`); */

    this.setState({
      openDetail: true,
      selectedItem: item,
    });
  };

  backtoCatalogue = () => {
    this.setState({
      openDetail: false,
      selectedItem: null,
    });
  };

  handleRangeChange = (e) => {
    // const min = e[0];
    // const max = e[1];
    // this.setState({ selectedMinValue: min, selectedMaxValue: max }, () => {
    //   this.getGiftCatalogItems();
    // });
    const min = e.min;
    const max = e.max > POINT_MAX_RANGE_LIMIT ? this.state.MaxCount : e.max;
    if (min <= 0) {
      // min = 0;
    }

    this.setState(
      {
        selectedMinValue: min,
        selectedMaxValue: max,
        minValue: min,
        maxValue: max,
        page: 1,
        items: [],
        hasMore: true,
        isLoading: true,
      },
      () => {
        this.getGiftItemOnRangeChange();
      }
    );
  };

  getGiftItemOnRangeChange = () => {
    this.backToTop();
    this.getGiftCatalogItems();
  };

  handleCategoryChange = (categoryName, index) => {
    this.setState(
      {
        category: categoryName,
        subCategory: null,
        selectedCategoryIndex: index,
        selectedSubCategoryIndex: -1,
        subCategoryOpenIndex: -1,
        page: 1,
        items: [],
        search: "",
        hasMore: true,
        isLoading: true,
      },
      () => {
        this.backToTop();
        this.openSubCategory(index);
        this.getGiftCatalogItems();
      }
    );
  };

  handleSubCategoryChange = (categoryName, index, categoryIndex) => {
    this.setState(
      {
        subCategory: categoryName,
        selectedCategoryIndex: categoryIndex,
        selectedSubCategoryIndex: index,
        page: 1,
        items: [],
        search: "",
        hasMore: true,
        isLoading: true,
      },
      () => {
        this.backToTop();
        this.getGiftCatalogItems();
      }
    );
  };

  handleSearchChange = (e) => {
    const searchVal = e.target.value;
    this.setState(
      { search: searchVal, page: 1, hasMore: true, items: [], isLoading: true },
      () => {
        this.backToTop();
        this.searchItems();
      }
    );
  };

  searchItems = () => {
    this.getGiftCatalogItems();
  };

  displayMoreCategory = (value) => {
    this.setState({ isMoreCategorySelected: value });
  };

  handleNextVal = () => {
    const { hasMore, isLoading } = this.state;
    if (isLoading || !hasMore) return;
    // Checks that the page has scrolled to the bottom
    if (
      window.innerHeight + document.documentElement.scrollTop <=
      document.documentElement.offsetHeight + 20 &&
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 200
    ) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.getGiftCatalogItems();
      });
    }
  };

  handleRefresh = () => {
    this.setState({ page: 1 }, () => {
      this.getGiftCatalogItems();
    });
  };

  backToTop = () => {
    window.scrollTo(0, 0);
  };

  showGiftCat = () => {
    GoogleAnalticsService.event(
      "Redemption Catalogue",
      "Welcome To JKTyre Advantage Rewards Click"
    );
    GoogleAnalticsService.pageView("Redemption Catalogue View");
    this.updateOpenGiftCatalogStatus();
    this.setState({
      isshowGiftCat: false,
    });
  };

  openSubCategory = (index) => {
    if (index === this.state.subCategoryOpenIndex) {
      index = -1;
    }
    this.setState({
      subCategoryOpenIndex: index,
    });
  };

  updateOpenGiftCatalogStatus = () => {
    const obj = {
      giftCatalogueViewDate: new Date().toISOString(),
    };
    UserService.updateUserInfo(obj)
      .then((data) => { })
      .catch((e) => { });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleInputChangeqty = (item, e) => {
    item.total_quantity = Number(e.target.value);
  };

  getInputValues = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (name === "minValue") {
          this.handleRangeChange({ min: value, max: this.state.maxValue });
        } else if (name === "maxValue") {
          this.handleRangeChange({ min: this.state.minValue, max: value });
        }
        if (
          this.state.maxValue &&
          this.state.minValue &&
          Number(this.state.maxValue) < Number(this.state.minValue)
        ) {
          this.setState({
            errorMsg: true,
          });
        } else {
          this.setState({
            errorMsg: false,
          });
        }
      }
    );
  };

  backToWallet = (isDisplayCart) => {
    this.getRedeemptionDashbaord();

    if (!isDisplayCart) {
      this.getGiftCatalogItems();
    }

    if (this.state.placeOrderCartItems.length > 0) {
      Storage.setPlaceOrderRedeemItems(this.state.placeOrderCartItems);
    } else {
      // this.getDashBoardCounts();
    }
    this.setState({ isDisplayCart });
  };

  openPointsModel = () => {
    this.setState({ isOpenPointsModel: true });
  };

  closePointsModel = () => {
    this.setState({ isOpenPointsModel: false });
  };

  getDashBoardCounts = () => {
    this.setState({ isLoading: true });
    GiftCatalogService.getDashboardCounts()
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data && res.data.data) {
          this.setState({
            total_coins:
              parseInt(res.data.data.available_points) +
              parseInt(res.data.data.redeem_points),
            balance_coins: res.data.data.available_points,
            redeemed_coins: res.data.data.redeem_points,
            summary: res.data.data.summary,
            totalPoint: _.sumBy(res.data.data.summary, function (item) {
              return item.points;
            }),
          });
          this.getItems();
          this.setFullVoucherPoint();
        }
      })
      .catch((e) => {
        this.setState({
          profileImage: null,
          isImageUpdating: false,
          isLoading: false,
        });
      });
  };

  Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  addSubCoins = (item, isAdd) => {
    const { coinsOnCart } = this.state;
    if (item.isAddToCart) {
      return false;
    }
    const total_quantity = isAdd
      ? item.total_quantity + item.minimum_quantity
      : item.total_quantity - item.minimum_quantity;
    if (total_quantity > item.current_quantity) {
      item.isInvalid = true;
      this.setState({});
      setTimeout(() => {
        item.isInvalid = false;
        this.setState({});
      }, 5000);
      return;
    }
    if (total_quantity < item.minimum_quantity) {
      return;
    }
    if (total_quantity > item.minimum_quantity * 5) {
      return;
    }
    item.total_quantity = total_quantity;
    this.setState({
      coinsOnCart,
    });
  };

  checkTotalCoins = (item, isAddToCart) => {
    item.total_quantity = item.total_quantity ? item.total_quantity : 1;
    let { coinsOnCart, placeOrderCartItems } = this.state;
    const tempPlaceOrderCartItems = _.cloneDeep(placeOrderCartItems);
    const indexCartItem = tempPlaceOrderCartItems.findIndex(
      (x) => x.uuid === item.uuid
    );
    let tempCoinsOnCart = coinsOnCart;
    if (indexCartItem <= -1) {
      tempCoinsOnCart = 0;
      tempPlaceOrderCartItems.push(item);
      tempPlaceOrderCartItems.forEach((element) => {
        tempCoinsOnCart =
          element.redeem_points * element.total_quantity + tempCoinsOnCart;
      });
      if (tempCoinsOnCart > this.state.balance_coins) {
        ModalService.openAlert(
          "Sorry!",
          `Your available points are ${this.state.balance_coins} which is less than items you are requesting.`,
          "warning"
        );
        return;
      }
      placeOrderCartItems = tempPlaceOrderCartItems;

      coinsOnCart = tempCoinsOnCart;
      item.isAddToCart = true;
      this.setState({ coinsOnCart, placeOrderCartItems });
      return;
    }
    if (isAddToCart) {
      tempCoinsOnCart = 0;
      placeOrderCartItems.forEach((element) => {
        tempCoinsOnCart =
          element.redeem_points * element.total_quantity + tempCoinsOnCart;
      });
      if (tempCoinsOnCart > this.state.balance_coins) {
        placeOrderCartItems.splice(indexCartItem, 1);
        item.isInvalid = true;
        this.setState({});
        setTimeout(() => {
          item.isInvalid = false;
          this.setState({});
        }, 5000);
        return;
      }
      coinsOnCart = tempCoinsOnCart;
      item.isAddToCart = true;
      this.setState({ coinsOnCart, placeOrderCartItems });
    } else {
      placeOrderCartItems.splice(indexCartItem, 1);
      tempCoinsOnCart = 0;
      placeOrderCartItems.forEach((element) => {
        tempCoinsOnCart =
          element.redeem_points * element.total_quantity + tempCoinsOnCart;
      });
      coinsOnCart = tempCoinsOnCart;
      item.isAddToCart = false;
      this.setState({ coinsOnCart });
    }
  };

  forceResetWallet = () => {
    this.componentDidMount();
    this.setState({
      placeOrderCartItems: Storage.getRedeemCartItems() || [],
      coinsOnCart: 0,
    });
  };

  changeTab = (activeTab) => {
    this.setState((prevState) => {
      return { ...prevState, activeTab };
    });
  };

  changeTabReport = (activeTab) => {
    if (activeTab === 1) {
    }

    this.setState((prevState) => {
      return { ...prevState, activeTabReport: activeTab };
    });
  };

  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }

  render() {
    const {
      openDetail,
      // items,
      // MaxCount,
      // categories,
      // totalItems,
      // orderDetails,
      // min,
      // rangeType,
      // max,
      // selectedCategoryIndex,
      // selectedSubCategoryIndex,
      // subCategoryOpenIndex,
      // search,
      // balancePoints,
      // redeemptionData,
      // selectedMaxValue,
      // selectedMinValue,
      isLoading,
      // scrollYValue,
      // isMoreCategorySelected,
      // minValue,
      // maxValue,
      summary,
      totalPoint,
    } = this.state;
    // const maxlength = max;
    // const categoriesList = categories.map((c, i) => {
    //   return (
    //     <div className="margin-top-10" key={c.category}>
    //       <div>
    //         <Row>
    //           <Col sm="10">
    //             <span
    //               onClick={this.handleCategoryChange.bind(this, c.category, i)}
    //               className={
    //                 i === selectedCategoryIndex
    //                   ? "category category-selected cursor-pointer"
    //                   : "category cursor-pointer"
    //               }
    //             >
    //               {c.category}
    //             </span>
    //           </Col>
    //           <Col sm="2">
    //             {subCategoryOpenIndex === i && (
    //               <span
    //                 onClick={this.openSubCategory.bind(this, i)}
    //                 className="cursor-pointer"
    //               >
    //                 <Ionicon
    //                   icon="ios-arrow-down"
    //                   fontSize="20px"
    //                   color="#fdbb1f"
    //                 />
    //               </span>
    //             )}
    //             {subCategoryOpenIndex !== i && (
    //               <span
    //                 onClick={this.openSubCategory.bind(this, i)}
    //                 className="cursor-pointer"
    //               >
    //                 <Ionicon
    //                   icon="ios-arrow-forward"
    //                   fontSize="20px"
    //                   color="#fdbb1f"
    //                 />
    //               </span>
    //             )}
    //           </Col>
    //         </Row>
    //       </div>
    //       {subCategoryOpenIndex === i &&
    //         c.sub_category.map((sc, subCIndex) => (
    //           <div>
    //             <span
    //               onClick={this.handleSubCategoryChange.bind(
    //                 this,
    //                 sc,
    //                 subCIndex,
    //                 i
    //               )}
    //               className={
    //                 subCIndex === selectedSubCategoryIndex &&
    //                   i === selectedCategoryIndex
    //                   ? "sub-category category-selected cursor-pointer"
    //                   : "sub-category cursor-pointer"
    //               }
    //             >
    //               {sc}
    //             </span>
    //           </div>
    //         ))}
    //     </div>
    //   );
    // });

    // const itemCardList = items.map((i) => {
    //   let defaultImage = null;
    //   const data_default = this.checkedExistingCartAdd(i.uuid);
    //   if (i.images) {
    //     for (let j = 0; j < i.images.length; j++) {
    //       const image = i.images[j];
    //       if (image.is_master) {
    //         defaultImage = image.main;
    //         break;
    //       }
    //     }
    //     if (!defaultImage) {
    //       defaultImage = i.images[0].main;
    //     }
    //   }
    //   return (
    //     <Col sm="12" xl="4" className="margin-top-10" key={i.uuid}>
    //       <Card className="card-height card-shadow cursor-pointer height-100">
    //         <CardBody>
    //           <Row onClick={this.openProductDetail.bind(this, i)}>
    //             <Col className="content-center">
    //               {i.special_product && (
    //                 <div className="label-bg">Experience</div>
    //               )}
    //               <img
    //                 src={defaultImage}
    //                 alt=""
    //                 className="logo-thumb-img"
    //                 height="70px"
    //                 width="100%"
    //               />
    //             </Col>
    //           </Row>
    //           <Row>
    //             <Col>
    //               <div
    //                 style={{ marginTop: "10px" }}
    //                 className="margin-bottom-0 card-title"
    //                 onClick={this.openProductDetail.bind(this, i.uuid)}
    //               >
    //                 <div dangerouslySetInnerHTML={{ __html: i.title }} />
    //               </div>
    //               {i.description && (
    //                 <div className="description margin-top-5">
    //                   <div
    //                     onClick={this.openProductDetail.bind(this, i)}
    //                     dangerouslySetInnerHTML={{ __html: i.description }}
    //                   />
    //                 </div>
    //               )}
    //               <div
    //                 className="redeem-points margin-top-5"
    //                 onClick={this.openProductDetail.bind(this, i)}
    //               >
    //                 {i.redeem_points} Points
    //               </div>

    //               {this.state.permission && (
    //                 <>
    //                   <hr style={{ marginTop: "10px", marginBottom: "0px" }} />

    //                   <div className="redeem-points margin-top-5">
    //                     <span className="Minimum-Qty">Quantity</span>
    //                   </div>

    //                   <div
    //                     className="d-flex-row"
    //                     style={{ margin: "auto", marginTop: "10px" }}
    //                   >
    //                     <div
    //                       className="d-flex-row"
    //                       style={{ flexWrap: "nowrap" }}
    //                     >
    //                       <select
    //                         className="form-control select drop-down-control"
    //                         name="total_quantity"
    //                         disabled={data_default}
    //                         // value={i.total_quantity}
    //                         style={
    //                           data_default
    //                             ? {
    //                               backgroundColor: "#ededed",
    //                               color: "#495057",
    //                               width: "65px",
    //                             }
    //                             : { color: "#495057", width: "65px" }
    //                         }
    //                         onChange={(e) => {
    //                           this.handleInputChangeqty(i, e);
    //                         }}
    //                       >
    //                         {this.state.qty.map((item) => (
    //                           <option
    //                             key={item}
    //                             selected={item === i.total_quantity}
    //                           >
    //                             {item}
    //                           </option>
    //                         ))}
    //                       </select>{" "}
    //                       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //                     </div>
    //                     <div
    //                       className="d-flex-row"
    //                       style={{ flexWrap: "nowrap" }}
    //                     >
    //                       <Button
    //                         className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold add-to-cart-btn"
    //                         color="warning"
    //                         type="submit"
    //                         style={{ marginLeft: "10px" }}
    //                         onClick={() =>
    //                           this.checkTotalCoins(
    //                             i,
    //                             !i.isAddToCart &&
    //                             !this.checkedExistingCartAdd(i.uuid)
    //                           )
    //                         }
    //                       >
    //                         {(i.isAddToCart === undefined ||
    //                           i.isAddToCart === false) &&
    //                           this.checkedExistingCartAdd(i.uuid) === false
    //                           ? "Add To Cart"
    //                           : "Remove"}
    //                       </Button>
    //                     </div>
    //                   </div>
    //                 </>
    //               )}
    //               {i.isInvalid && (
    //                 <div style={{ marginTop: "5px" }} className="error">
    //                   Sorry! maximum available item count is{" "}
    //                   {i.current_quantity}.
    //                 </div>
    //               )}
    //             </Col>
    //           </Row>
    //         </CardBody>
    //       </Card>
    //     </Col>
    //   );
    // });
    return (
      <>
        <div id="EntryScreenCatelog">
          {openDetail && (
            <GiftItemDetail
              openPointsModel={this.openPointsModel}
              backToWallet={this.backToWallet}
              balancePoints={this.state.balancePoints}
              coinsOnCart={this.state.coinsOnCart}
              selectedItem={this.state.selectedItem}
              backtoCatalogue={this.backtoCatalogue}
              checkedExistingCartAdd={this.checkedExistingCartAdd}
              checkTotalCoins={this.checkTotalCoins}
              handleInputChangeqty={this.handleInputChangeqty}
              placeOrderCartItems={this.state.placeOrderCartItems}
            />
          )}

          <Modal
            isOpen={this.state.isOpenPointsModel}
            className="modal-md modal-lg entry-screen-catalog-modal width-40-pecentage"
            backdrop={this.backdrop ? "static" : "nostatic"}
            centered
          >
            <div className="d-flex-column">
              <div className="confirm-modal-title-wrapp">
                <span className="confirm-modal-title text-capitalize">
                  POINTS ELIGIBLE FOR REDEMPTION
                </span>
                <FontAwesomeIcon
                  className="pull-right cursor-pointer"
                  onClick={() => this.closePointsModel()}
                  icon={faTimes}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  color="#fdbb1f"
                />
              </div>
            </div>
            <ModalBody
              classNam="d-flex-column"
              style={{ maxHeight: this.state.height, overflow: "auto" }}
            >
              <div className="d-flex-row" style={{ marginBottom: "20px" }}>
                <span
                  onClick={() => this.changeTabReport(0)}
                  className={
                    this.state.activeTabReport === 0
                      ? "custom-rc-tab-active"
                      : "custom-rc-tab-deactive"
                  }
                >
                  Points Eligible For Redemption
                </span>
                <span
                  onClick={() => {
                    this.changeTabReport(1);
                  }}
                  className={
                    this.state.activeTabReport === 1
                      ? "custom-rc-tab-active ml-4"
                      : "custom-rc-tab-deactive ml-4"
                  }
                >
                  Points Summary
                </span>
              </div>

              {this.state.activeTabReport === 1 && (
                <>
                  <PrimaryOfftakeSummary
                    year={this.state.year}
                    yearOptions={this.state.yearOptions}
                    onChangeYear={this.onChangeYear}
                    offTakeSummary={this.offTakeSummary}
                    changeTab={this.changeTab}
                    closePointsModel={() => this.closePointsModel()}
                    totalEarnedPoints={
                      this.state.totalPrimaryPoints +
                      this.state.totalPrivionalPoints
                    }
                  />
                </>
              )}

              {this.state.activeTabReport === 0 && (
                <Table className="mb-0 table-responsive" bordered>
                  <thead>
                    <tr style={{ ...this.getStripedStyle(0) }}>
                      <th>Description</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summary.map((item, i) => (
                      <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                        <td>{item.description}</td>
                        <td>{formatNumber(item.points)}</td>
                      </tr>
                    ))}

                    <tr style={{ backgroundColor: "#efefef" }}>
                      <th>Total</th>
                      <td>{formatNumber(totalPoint)}</td>
                    </tr>
                  </tbody>
                </Table>
              )}

              {this.state.activeTabReport === 1 && <></>}
            </ModalBody>
          </Modal>

          {/* <Modal
            isOpen={this.state.isshowGiftCat}
            toggle={this.toggle}
            className="modal-md modal-lg entry-screen-catalog-modal width-40-pecentage"
            style={{ maxWidth: "1250px", width: 'auto !important' }}
            backdrop={this.backdrop ? "static" : "nostatic"}
            centered
          > */}
          {this.state.isLoadingOrder && <CustomSpinner />}
          {this.state.isshowGiftCat && (
            <EntryScreenCatelog
              redeemptionData={this.state.redeemptionData}
              showGiftCat={() => this.showGiftCat}
            />
          )}
          {/* </Modal> */}
        </div>
        {!this.state.isshowGiftCat && (
          <div id="gift-catalog">
            {isLoading && <CustomSpinner />}
            <RedemptionCatalogV2 data={this.state.redeemptionData} />
          </div>
        )}
      </>
    );
    // return (

    // );
  }
}


/* {!this.state.isshowGiftCat && (
        <div id="gift-catalog">
          {isLoading && <CustomSpinner></CustomSpinner>}
          <Row>
            <Col
              sm="12"
              xl="3"
              className="category-bg"
              style={{ maxWidth: '20%', marginTop: '-15px' }}
            >
              <div className="menu-title">MENU</div>
              <div className="category-title margin-top-5">Points Range</div>

              <Col className="points-range">
                <Col xl="12" md="12" xs="12" style={{ marginLeft: '10px' }}>
                  <FormGroup className="d-block">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="checkbox"
                        name="rangeType"
                        value="SelectRange"
                        checked={rangeType === 'SelectRange'}
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: 'rangeType',
                              value: 'SelectRange',
                            },
                          })
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '6px' }}>Select Range</span>
                    &nbsp;&nbsp;&nbsp;
                  </FormGroup>
                </Col>

                <Col xl="12" md="12" xs="12" style={{ marginLeft: '10px' }}>
                  <FormGroup className="d-block">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="checkbox"
                        name="rangeType"
                        value="inputRange"
                        checked={rangeType === 'inputRange'}
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: 'rangeType',
                              value: 'inputRange',
                            },
                          })
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '6px' }}>Enter Range</span>
                  </FormGroup>
                </Col>
              </Col>
              {rangeType === 'SelectRange' && (
                <div id="inputRange" style={{ marginLeft: '20px' }}>
                  <div className="d-flex justify-content-between">
                    <span className="minValue">{selectedMinValue}</span>
                    {selectedMaxValue >= POINT_MAX_RANGE_LIMIT ? (
                      <span className="maxValue">{POINT_MAX_RANGE_LIMIT}+</span>
                    ) : (
                      <span className="maxValue">{selectedMaxValue}</span>
                    )}
                  </div>
                  <InputRange
                    maxValue={
                      Number(max) >= Number(POINT_MAX_RANGE_LIMIT) ? Number(POINT_MAX_RANGE_LIMIT) : max
                    }
                    minValue={min}
                    allowSameValues={false}
                    // classNames={{
                    //   minLabel: 'input-range__label input-range__label--min min-input-range',
                    //   maxLabel: 'max-input-range'
                    // }}
                    value={{
                      max:
                        Number(selectedMaxValue) >= Number(POINT_MAX_RANGE_LIMIT)
                          ? MaxCount
                          : selectedMaxValue,
                      min: selectedMinValue,
                    }}
                    onChange={(value) => this.handleRangeChange(value)}
                  />
                </div>
              )}
              {rangeType === 'inputRange' && (
                <Row >
                  <Col xl="6" md="12" xs="12">
                    <FormGroup>
                      <Label for="min">Min</Label>
                      <Input
                        type="text"
                        name="minValue"
                        value={minValue}
                        onChange={this.getInputValues}
                        placeholder="min"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6" md="12" xs="12">
                    <FormGroup>
                      <Label for="min">Max</Label>
                      <Input
                        type="text"
                        name="maxValue"
                        value={maxValue}
                        onChange={this.getInputValues}
                        placeholder="max"
                      />
                    </FormGroup>
                  </Col>
                  {this.state.errorMsg && (
                    <span className="error-msg">
                      Max Value should be greater then min value
                    </span>
                  )}
                </Row>
              )}

              <div className="category-title margin-top-20">Categories</div>

              <div
                className="category margin-top-10"
                onClick={this.handleCategoryChange.bind(this, null, -1)}
              >
                <span
                  className={
                    selectedCategoryIndex === -1
                      ? 'category-selected cursor-pointer'
                      : 'cursor-pointer'
                  }
                >
                  All
                </span>
              </div>
              {categoriesList}
              {/* <div
                className="margin-top-10 link-blue more-css width-100"
                onClick={this.displayMoreCategory.bind(
                  this,
                  !isMoreCategorySelected
                )}
              >
                {isMoreCategorySelected ? "Less..." : "More..."}
              </div> */

//           </Col>
//           <Col sm="12" className="gift-bg" style={{ maxWidth: '80%' }}>
//             <Row sm="11" className="height-100">
//               <Col>
//                 {/* <div className="page-title">Redemption Catalogue</div> */}
//                 {this.state.permission && <Row className="">
//                   <Col xl="7" md="12" xs="12">
//                     <Card className="merchandise-wallet-count-card-redemption">
//                       <div className="merchandise-wallet-count-lbl-wrapp d-flex-column">
//                         <span className="merchandise-wallet-coins-lbl">Points Eligible for Redemption&nbsp;
//                           <img src="images/help.png" alt="" className="cursor-pointer" width='15' onClick={() => this.openPointsModel()} />
//                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                           {/* <label style={{fontWeight:'100'}}>{redeemptionData.lower_month}-{redeemptionData.upper_month}</label> */}
//                         </span>
//                         <span className="merchandise-wallet-coins-count">{formatNumber(balancePoints)}</span>
//                       </div>
//                     </Card>
//                   </Col>

//                   <Col xl="3" md="12" xs="12" className="cursor-pointer">
//                     <Card className="merchandise-wallet-count-card-redemption" style={{ background: '#fff' }} onClick={() => this.backToWallet(true)}>
//                       <div className="merchandise-wallet-count-lbl-wrapp d-flex-column cart-count-wrapp">
//                         <span style={this.state.coinsOnCart > 0 ? {} : {opacity: '0.4'}} className="cart-count-count">
//                           <img src="images/cart.svg" alt="" />&nbsp;Cart&nbsp;
//                             ({this.state.placeOrderCartItems && this.state.placeOrderCartItems.length})
//                         </span>
//                         <div>
//                           <span style={this.state.coinsOnCart > 0 ? {} : {opacity: '0.4'}} className="cart-count-lable">
//                             {this.state.coinsOnCart} points on cart
//                           </span>
//                         </div>
//                       </div>
//                     </Card>
//                   </Col>
//                 </Row>}
//                 <Row className="margin-top-10">
//                   <Col xl="6" sm="12">
//                     {/* <Label> */}
//                     <Input
//                       placeholder="Search by product name or category"
//                       className="report-search-input"
//                       onChange={this.handleSearchChange}
//                       value={search}
//                     />
//                     <img
//                       src="images/search.svg"
// alt=""
//                       className="report-search icon-width"
//                     />
//                   </Col>
//                   {!this.state.permission && <Col className="text-align-right">
//                     <span className="text-bold">Balance Points: </span>
//                     <span>{balancePoints}</span>
//                   </Col>}
//                 </Row>
//                 <Row className="margin-top-10">
//                   {items && items.length > 0 ? (
//                     itemCardList
//                   ) : (
//                     <div className="width-100 text-align-center">
//                       {/* <Card> */}
//                       {isLoading !== true && (
//                         <CardBody>{NODATAMSG}</CardBody>
//                       )}
//                       {/* </Card> */}
//                     </div>
//                   )}
//                   {/* {isLoading && (
//                     <div className="infinite-loader-css">
//                       <Spinner />
//                     </div>
//                   )} */}
//                 </Row>
//               </Col>
//               <Col sm="1">
//                 {scrollYValue >= 700 && (
//                   <div
//                     onClick={this.backToTop}
//                     className="back-to-top-button cursor-pointer"
//                   >
//                     <Ionicon
//                       icon="ios-arrow-dropup"
//                       fontSize="50px"
//                       color="#fdbb1f"
//                     />
//                     {/* Back To Top */}
//                   </div>
//                 )}
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </div>)}
//            {this.state.activeTab === 1 && (<Row>

//              {this.state.sidebarOpen && (
//                <Sidebar
//                  sidebar={
//                   <>
//                     <div className="drawer-header">
//                       <div>
//                         <span className="drawer-title">Filters</span>&nbsp;&nbsp;
//                         <img src="/images/filter.svg" />
//                       </div>
//                       <img
//                         className="cursor-pointer"
//                         src="images/close.svg"
//  alt=""
//                         onClick={() => {
//                           this.onCloseSidebar();
//                         }}
//                       />
//                     </div>
//                     <hr style={{ border: 'solid 1px #ced4da' }} />
//                     <HoDashboardFilter
//                       applyFilter={this.applyFilter}
//                       navigation={this.state.navigation}
//                       fromGiftCatalog
//                       filterControls={this.state.filterControls}
//                       ref={this.childHoDashboardFilter}
//                     />
//                   </>
//                  }
//                  open={this.state.sidebarOpen}
//                  sidebarClassName="ho-filter-sidebar"
//                  pullRight
//                  styles={{ sidebar: { background: 'white', height: '100%' } }}
//                />
//              )}

//              <Col>
//                <Card>
//                  <div
//                    style={{ width: '380px', marginTop: '15px', marginLeft: '15px' }}
//                    className="d-flex-row search-input"
//                  >
//                    <Input
//                      placeholder={this.state.type === 'dealer' ? 'Search by Order ID, Product Name' : 'Search by SAP code, Order Id or Product Name'}
//                      className="login-input"
//                      name="searchOrder"
//                      onChange={this.handleSearchChangeOrder}
//                      value={this.state.searchOrder}
//                    />
//                    <img
//                      src="images/search.svg"
//                      style={{ marginLeft: '-25px' }}
//                    />

//                    {(this.state.type !== 'dealer' && this.state.type !== 'area_manager' && this.state.type !== 'sales_officer') && <img
//                      className="cursor-pointer"  alt=""
//                      onClick={() => {
//                        this.onSetSidebarOpen(true);
//                      }}
//                      src={this.state.isFilterApply ? '/images/filter_applied.svg' : '/images/filter.svg'}
//                      style={{ cursor: 'pointer', marginLeft: '25px' }}
//                    />}
//                  </div>

//                  <div style={{ padding: '15px' }}>
//                    <Table>
//                      <thead>
//                        <tr>
//                          {this.state.type !== 'dealer' && <th className='cart-th'>
//                             Dealer Name
// 		                    </th>}
//                          <th className='cart-th'>
//                             Product Image
// 		                    </th>
//                          <th style={{ width: '80px' }} className='cart-th'>
//                             Product Name
// 		                    </th>
//                          <th className='cart-th'>
//                             Product ID
// 		                    </th>
//                          <th style={{ width: '40px' }} className='cart-th'>
//                             Product Qty
// 		                    </th>
//                          <th style={{ width: '40px' }} className='cart-th'>
//                             Redeemed Points
// 		                    </th>
//                          <th className='cart-th'>
//                             Order ID
// 		                    </th>
//                          <th className='cart-th'>
//                             Status
// 		                    </th>
//                          <th className='cart-th' style={{width: '120px'}}>
//                             Order Date
// 		                    </th>
//                          <th className='cart-th' style={{width: '120px'}}>
//                             Delivery Date
// 		                    </th>
//                          <th style={{ width: '200px' }} className='cart-th'>
//                             Shipping Address
// 		                    </th>
//                        </tr>
//                      </thead>
//                      <tbody>
//                        {(orderDetails || []).map(
//                          (order) => (
//                            <tr key={order.id}>
//                              {this.state.type !== 'dealer' &&
//                               <td className='cart-td'>
//                                 <b>{order.owner_name}</b>
//                                 <br />
//                                 {order.code}
//                               </td>}
//                              <td>
//                                <img
//                                  src={
//                                    order.image_url
//                                  }  alt=""
//                                  style={{
//                                    width:
//                                       '60px',
//                                    height:
//                                       '59px',
//                                    objectFit:
//                                       'contain',
//                                  }}
//                                />
//                              </td>
//                              <td className='cart-td'>
//                                {
//                                  order.item_name
//                                }
//                              </td>
//                              <td className='cart-td'>
//                                {
//                                  order.order_item_id
//                                }
//                              </td>
//                              <td className='cart-td'>
//                                {
//                                  order.item_quantity
//                                }
//                              </td>
//                              <td className='cart-td'>
//                                {
//                                  order.redeemed_points
//                                }
//                              </td>
//                              <td className='cart-td'>
//                                {
//                                  order.order_id
//                                }
//                              </td>
//                              <td className='cart-td'>
//                                <div
//                                  className='d-flex-row'
//                                  style={{
//                                    flexWrap:
//                                       'nowrap',
//                                  }}
//                                >
//                                  {order.status ===
//                                     'delivered' && (
//                                       <>
//                                         <img
//                                           src='images/DeliveredDot.svg'
//                                           alt='delivered dot'
//                                         />{' '}
// 																		&nbsp;&nbsp;Delivered{' '}
//                                       </>
//                                  )}
//                                  {order.status ===
//                                     'in_progress' && (
//                                       <>
//                                         <img
//                                           src='images/InProcessDot.svg'
//                                           alt='in progress dot'
//                                         />
// 																		&nbsp;&nbsp;
// 																		In
// 																		Progress
// 																	</>
//                                  )}
//                                  {order.status ===
//                                     'out_of_stock' && (
//                                       <>
//                                         <img
//                                           src='images/OutofStockDot.svg'
//                                           alt='out of stock dot'
//                                         />{' '}
// 																		&nbsp;&nbsp;Out
// 																		of
// 																		stock
// 																	</>
//                                  )}
//                                </div>
//                              </td>
//                              <td className='cart-td'>
//                                {
//                                  order.created_at
//                                }
//                              </td>
//                              <td className='cart-td'>
//                                {
//                                  order.delivery_date
//                                }
//                              </td>
//                              <td className='cart-td'>
//                                {
//                                  order.address
//                                }
//                              </td>
//                            </tr>
//                          )
//                        )}
//                      </tbody>
//                    </Table>
//                  </div>
//                  {this.state.count>0 && <div
//                    className="d-flex-row"
//                    style={{ marginTop: '10px', marginRight:'15px', justifyContent: 'flex-end' }}
//                  >
//                    <span className="Rows-per-page">Rows per page:</span>
//                    <select
//                      className="form-control rows-per-page-select"
//                      name="pageSize"
//                      value={this.state.pageSizeOrder}
//                      onChange={e => this.handleInputChangePageSize(e)}
//                      style={{ width: '65px' }}
//                    >
//                      <option value="5">5</option>
//                      <option value="10">10</option>
//                      <option value="15">15</option>
//                      <option value="25">25</option>
//                      <option value="30">30</option>
//                      <option value="50">50</option>
//                    </select>
//                 &nbsp;&nbsp;
//                    <div style={{ marginTop: '5px' }}>
//                      <Pagination
//                        margin={2}
//                        page={this.state.pageOrder}
//                        count={this.state.totalPages}
//                        pageSize={this.state.pageSizeOrder}
//                        totalRecords={this.state.count}
//                        onPageChange={this.handlePageChange}
//                      />
//                    </div>
//                  </div>}
//                </Card>
//              </Col>
//            </Row>)}
//       </>
//     )
//     }
//     {
//       this.state.isDisplayCart && !openDetail &&
//       <RedeemCatelogCart
//         total_coins={this.state.total_coins}
//         balance_coins={this.state.balance_coins}
//         balancePoints={this.state.balancePoints}
//         redeemed_coins={this.state.redeemed_coins}
//         coinsOnCart={this.state.coinsOnCart}
//         backToWallet={this.backToWallet}
//         forceResetWallet={this.forceResetWallet}
//         placeOrderCartItems={this.state.placeOrderCartItems}
//         checkTotalCoins={this.checkTotalCoins} />
//     }
//   </>
// );
// return (

// );
//   }
// }
//     <Input
//       placeholder="Search by product, category or name"
//       className="report-search-input"
//       onChange={this.handleSearchChange}
//       value={search}
//     />
//     <img
//       src="images/search.svg"
//  alt=""
//       className="report-search icon-width"
//     />
//   </Col>
//   <Col className="text-align-right">
//     <span className="text-bold">Balance Points: </span>
//     <span>{balancePoints}</span>
//   </Col>
// </Row>
// <Row className="margin-top-10">
//   {items && items.length > 0 ? (
//     itemCardList
//   ) : (
//     <div className="width-100 text-align-center">
//       {/* <Card> */}
//       {isLoading !== true && (
//         <CardBody>{NODATAMSG}</CardBody>
//       )}
//       {/* </Card> */}
//     </div>
//   )}
//               {/* {isLoading && (
//                 <div className="infinite-loader-css">
//                   <Spinner />
//                 </div>
//               )} */}
//             </Row>
//           </Col>
//           <Col sm="1">
//             {scrollYValue >= 700 && (
//               <div
//                 onClick={this.backToTop}
//                 className="back-to-top-button cursor-pointer"
//               >
//                 <Ionicon
//                   icon="ios-arrow-dropup"
//                   fontSize="50px"
//                   color="#fdbb1f"
//                 />
//                 {/* Back To Top */}
//               </div>
//             )}
//           </Col>
//         </Row>
//       </Col>
//     </Row>
//   </div>
// )} */}

export default GiftCatalogV2;
