import React from 'react';
import {
  Row, Col,
  Card, CardBody,
} from 'reactstrap';
import './AboutJKAdvantage.css';

const AboutJKAdvantage = () => {
  return <Card id="about-jk-advantage" className="height-100 border-radius-10">
    <CardBody>
      <Row>
        <Col className="about-jkadvantage-title">
          ABOUT JKAdvantage
        </Col>
      </Row>
      <br />
      {/* <Row>
        <Col>
          <img src="images/logo-small.png" width="137px" height="27" alt="" />
        </Col>
      </Row> */}
      {/* <br /> */}
      <Row>
        <Col className="about-jkadvantage-text">
          The Advantage program is a centralized Dealer Relationship Program that simplifies the
          communication exchange between the channel partners and JK Tyre.
          It rewards dealers who are high performer and acknowledge their effort.
          It aims in building long term partnership relationship by enhancing the low potential dealers and enabling them to grow.
          JK Advantage is the only loyalty program which is offered to the dealers across the industry, and today is considered as one of the biggest program.
        </Col>
      </Row>
    </CardBody>
  </Card>;
};

export default AboutJKAdvantage;