import React from "react";
import {
  Modal,
  ModalBody,
  Input,
  Row,
  Col,
  Form,
  Table
} from "reactstrap";

import Pagination from "../../components/Pagination/Pagination";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { downlodFile } from "../../utils/utils";
import lodash from "lodash"; // library added for debounce functionality
import PosterService from '../../services/PosterService';
import { formatDate } from "../../utils/utils";
import "./PosterDealersModel.css";

class PosterDealersModel extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    users: [],
    count: 0,
    totalPages: 0,
    search: '',
    isLoading: false
  };

  constructor() {
    super();
    this.searchData = lodash.debounce(this.searchData, 500);
  }
  componentDidMount() {
    this.loadUserData();
  }

  loadUserData = () => {
    const { type } = this.props;
    if (type === null) {
    } else {
      this.getTargetUsers();
    }
  }

  searchData = (search, page) => {
    this.loadUserData();
  };

  handleSearchChange = e => {
    let searchVal = e.target.value;
    this.setState({ page: 1, search: searchVal }, () => {
      this.searchData(searchVal, 1);
    });
  };

  handlePageChange = page => {
    this.setState({ page: page }, () => {
      this.loadUserData();
    });
  };

  getTargetUsers = () => {
    const { page, pageSize, search } = this.state;
    const { uuid, type } = this.props;
    this.setState({ isLoading: true });
    PosterService.getPosterTargetUser(page, pageSize, uuid, type, search, false)
      .then(res => {
        if (res.data.data && res.data.data.elements) {
          let users = res.data.data.elements;
          let count = res.data.data.total_count;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState({
            users,
            count,
            totalPages,
            isLoading: false
          });
        } else {
          this.setState({
            users: [],
            count: 0,
            totalPages: 0,
            isLoading: false
          });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }

  closeModal = () => {
    this.props.closeModal(true);
  };

  downloadReport = () => {
    const { page, pageSize, search } = this.state;
    const { uuid, type } = this.props;
    this.setState({
      isLoading: true,
    });
    PosterService.getPosterTargetUser(page, pageSize, uuid, type, search, true).then((res) => {
      this.setState({
        isLoading: false,
      });
      if (res.data.data.file) {
        downlodFile(res.data.data.file);
      }
    }).catch((e) => {
      this.setState({
        isLoading: false,
      });
    });
  };

  prepareRows = () => {
    const { users } = this.state;
    return users.map(u =>
      <tr>
        <td>{u.user_id}</td>
        <td >{u.owner_name}</td>
        <td className="title-case">{u.type}</td>
        <td>{u.pdf_download_at ? formatDate(u.pdf_download_at) : '-'}</td>
        <td>{u.jpg_download_at ? formatDate(u.jpg_download_at) : '-'}</td>
      </tr>
    )
  }

  getModalTitle = () => {
    const { type } = this.props;
    if (type === 'total') {
      return `TOTAL DEALERS`
    } else if (type === 'download') {
      return `POSTER DOWNLOAD DEALER COUNT`
    } else {
      return `TOTAL DEALERS`
    }
  }

  render() {
    const { count, search, isLoading } = this.state;
    const { count: totalCounts, type } = this.props;
    return (
      <div>
        <Modal
          id="target-user-modal"
          isOpen={this.props.show}
          toggle={this.props.closeModal}
          centered
        >
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            {isLoading && <CustomSpinner />}
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col sm="8" className="modal-title">{this.getModalTitle()} : {totalCounts}</Col>
                <Col className="text-align-right">
                  <img
                    className="cursor-pointer"
                    src="images/close.svg"
                    alt="cancel"
                    width="15px"
                    onClick={() => this.props.closeModal()}
                  />
                </Col>
              </Row>
              <div></div>

              {/* <br /> */}
              <Row className="margin-bottom-10 margin-top-10">
                <Col xs="7">
                  <Input
                    placeholder="Search using SAP ID and Dealer name"
                    className="report-search-input"
                    name="search"
                    value={search}
                    onChange={this.handleSearchChange}
                  ></Input>
                  <img src="images/search.svg" alt="" className="report-search icon-width" />
                </Col>
                <Col>
                  <span className="Download-icon">
                    <img
                      src="images/group-6.png"
                      srcSet="images/group-6@2x.png 2x,
                      images/group-6@3x.png 3x"
                      className="Group-6"
                      alt=""
                    />
                    <span className="Download-Report" onClick={this.downloadReport}> Download Report </span>
                  </span>
                </Col>
              </Row>
              <Table>
                <thead>
                  <tr>
                    <th>SAP ID</th>
                    <th>Dealer Name</th>
                    <th>Dealer Classification</th>
                    <th>PDF</th>
                    {type === 'view' && <th>(Downloaded Date)</th>}
                    {type === 'click' && <th>(Downloaded Date)</th>}
                    <th>JPEG</th>
                    {type === 'view' && <th>(Downloaded Date)</th>}
                    {type === 'click' && <th>(Downloaded Date)</th>}
                  </tr>
                </thead>
                <tbody>
                  {this.prepareRows()}
                </tbody>
              </Table>
              {count <= 0 && (
                <div className="no-data tbl-offtake-summary" style={{ maxWidth: "100%" }}>
                  Dealer not found.
                </div>
              )}
              <div style={{ marginTop: "5px" }}>
                <Pagination
                  margin={2}
                  page={this.state.page}
                  count={this.state.totalPages}
                  pageSize={this.state.pageSize}
                  totalRecords={count}
                  onPageChange={this.handlePageChange}
                ></Pagination>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default PosterDealersModel;
