import React, { Component } from 'react';
import {
  Button,
  FormGroup, Label,
  Input,
  Row, Col,
  Card, CardBody,
} from 'reactstrap';

import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import WarrantyRegistrationService from "./../../services/WarrantyRegistrationService";
import { messages } from '../../constants/constant.message';
import './EditOtherBusinessDetail.css';
import { Permissions } from '../../constants/constant.permissions';
const { INVALID_EMAIL, EMPTY_EMAIL, EMPTY_PHONE, INVALID_PHONE, INVALID_GST, INVALID_PAN,
  SENDER_ID_SIX_DIGIT, SENDER_ID_ALPHABETIC } = messages.PROFILE;

class EditOtherBusinessDetail extends Component {


  constructor(props) {
    super(props);
    this.state = {
      stateData: [],
      cityData: [],
      state_name: '',
      city_name: '',
      isFirstTime: true,
      is_display_sender_id: false
    }
    if (this.props.userDetail.permissions && this.props.userDetail.permissions.includes(Permissions.VIEW_SENDER_ID)) {
      this.state.is_display_sender_id = true;
    }
  }
  handleOnCancel = () => {
    this.props.onCancel('isBusinessEditActive', false);
  }

  componentDidMount() {
    this.getState();
  }

  async getState() {
    try {
      const { data } = await WarrantyRegistrationService.getState();
      let { stateData, state_name } = this.state;
      let { state } = this.props;
      if (data) {

        stateData = [];
        if (data.data && data.data.length > 0) {
          stateData = data.data;
        }
        this.setState((prevState) => {
          return { ...prevState, stateData, isLoading: false, state_name };
        }, () => {
          this.getCity(state.value);
        });
      }
    } catch (error) {
      this.setState((prevState) => {
        return { ...prevState, isLoading: false };
      });
      console.log(error);
    }
  }

  async getCity(controlValue = null) {
    try {
      let { city_name } = this.state;
      const { state, city } = this.props;
      if (!controlValue || !state.value) {
        return
      }

      let state_id = controlValue;
      if (state.value && this.state.stateData.length > 0) {
        let state_data = this.state.stateData.filter(x => x.name.toLowerCase() === state.value.toLowerCase());

        if (state_data.length > 0) {
          state_id = state_data[0].state_id;
        } else {
          return
        }
      }
      this.setState((prevState) => {
        return {
          ...prevState,
          isLoading: true
        };
      });
      const { data } = await WarrantyRegistrationService.getCity(state_id);
      let { cityData } = this.state;
      cityData = [];
      if (data) {
        if (data.data && data.data.length > 0) {
          cityData = data.data;
          if (city.value && cityData.length > 0) {
            let city_data = cityData.filter(x => x.name.toLowerCase() === city.value.toLowerCase());
            if (city_data.length <= 0) {
              city.value = '';
            }
          }
        }
      }
      this.setState((prevState) => {
        return {
          ...prevState,
          cityData,
          state,
          city,
          city_name,
          isLoading: false
        };
      });
    } catch (error) {
      this.setState((prevState) => {
        return {
          ...prevState,
          isLoading: false
        };
      });
      console.log(error);
    }
  }

  componentWillReceiveProps(props) {
    const { email, phone } = props;
    if (this.state.isFirstTime === true) {
      if (email.autoFocus === true) {
        this.email.focus();
        this.setState({ isFirstTime: false });
        this.props.activeFragment('business');
      } else if (phone.autoFocus === true) {
        this.phone.focus();
        this.props.activeFragment('business');
        this.setState({ isFirstTime: false });
      }

    }
  }

  setFocusOnEmail = () => {
    this.email.focus();
  }

  render() {

    const { email, phone, gst, pan, senderId, state, city, pin_code, address } = this.props;
    return <Card className={this.props.className}>
      <CardBody>
        {this.props.isLoading && <CustomSpinner></CustomSpinner>}
        <Row>
          <Col xl="12" md="12" xs="12">
            <b>OTHER BUSINESS DETAILS</b>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="email">Email Address <span className="required-field">*</span></Label>
              <Input
                type="email"
                name="email"
                id="email"
                innerRef={e => this.email = e}
                autoFocus={email.autoFocus}
                // className="required-input"
                // ref={(input) => { this.email = input }}
                value={email.value}
                onChange={this.props.handleInputChange}
                onFocus={this.props.activeFragment ? this.props.activeFragment.bind(this, 'business') : null}
                placeholder="Type here" />

              {email.showErrorMsg && email.invalidEmail && !email.nullValue && <div className="error">* {INVALID_EMAIL}</div>}
              {email.showErrorMsg && email.nullValue && <div className="error">* {EMPTY_EMAIL}</div>}
            </FormGroup>
          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="phone">Owner’s Phone Number <span className="required-field">*</span></Label>
              <Input
                type="text"
                name="phone"
                innerRef={e => this.phone = e}
                autoFocus={phone.autoFocus}
                // className="required-input"
                id="phone"
                value={phone.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {phone.showErrorMsg && phone.invalidPhone && <div className="error">* {INVALID_PHONE}</div>}
              {phone.showErrorMsg && phone.nullValue && <div className="error">* {EMPTY_PHONE}</div>}
            </FormGroup>

          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="gst">GST Number</Label>
              <Input
                type="text"
                name="gst"
                id="gst"
                className="capital-case"
                value={gst.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {gst.showErrorMsg && < div className="error">* {INVALID_GST}</div>}
            </FormGroup>

          </Col>
          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="pan">PAN Number</Label>
              <Input
                type="text"
                name="pan"
                id="pan"
                className="capital-case"
                value={pan.value}
                onChange={this.props.handleInputChange}
                placeholder="Type here" />
              {pan.showErrorMsg && < div className="error">* {INVALID_PAN}</div>}
            </FormGroup>
          </Col>
          {
            (this.state.is_display_sender_id) &&
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label for="pan">Sender ID</Label>
                <Input
                  type="text"
                  name="senderId"
                  id="senderId"
                  value={senderId.value}
                  disabled={this.props.is_disable_sender_id}
                  onChange={this.props.handleInputChange}
                  placeholder="Type here" />
                {(senderId.showErrorMsg && (senderId.invalidMinLength)) && <div className="error">* {SENDER_ID_SIX_DIGIT}</div>}
                {(senderId.showErrorMsg && (senderId.invalidLength)) && <div className="error">* Please enter maximum six digit sender id.</div>}
                {(senderId.showErrorMsg && senderId.invalidAlphabetic) && <div className="error">* {SENDER_ID_ALPHABETIC}</div>}
              </FormGroup>
              {
                (this.state.is_display_sender_id) &&
                <Col xl="5" className="margin-top-n10px d-flex" style={{ maxWidth: '300px' }}>
                  <span className="profile-sender-id-note">
                    Sender ID approval will take 2 to 3 days, Once it's approved then you will receive notification for the same
                  </span>
                </Col>
              }
            </Col>
          }

          <Col xl="3" md="12" xs="12">
            <FormGroup>
              <Label for="address">Address<span className="required-field"> *</span></Label>
              <textarea
                name="address"
                autocomplete="nofill"
                id="address"
                value={address.value}
                style={{ minHeight: 117, maxHeight: 70, fontSize: '14px' }}
                className="form-control"
                onChange={(e) => this.props.handleInputChange({ target: { name: 'address', value: e.target.value } })}
                placeholder='Enter Address'
              />
              {address.showErrorMsg && <div className="error">*Please enter address.</div>}
            </FormGroup>
          </Col>

          <Col xl="6" md="12" xs="12">
            <Row>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label for="state">State</Label>
                  <select className="form-control" name="state"
                    disabled={this.props.userInfo && this.props.userInfo.state ? true : false}
                    onChange={this.props.handleInputChange} value={state.value}
                  >
                    <option value="" selected="true" disabled>Select state</option>
                    {
                      this.state.stateData && this.state.stateData.map((state) => (
                        <option key={state.name} value={state.name}>{state.name}</option>
                      ))
                    }
                  </select>
                  {state.showErrorMsg && <div className="error">*Please select state.</div>}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label for="city">City</Label>
                  <select className="form-control" name="city"
                    // disabled={this.props.userInfo && ((!this.props.userInfo.state) || (!this.props.state.value) || this.props.userInfo.city) ? true : false}
                    disabled={this.props.userInfo && this.props.userInfo.city ? true : false}
                    onChange={this.props.handleInputChange} value={city.value ? city.value : ''}
                  >
                    <option value="" selected="true" disabled>Select city</option>
                    {
                      this.state.cityData && this.state.cityData.map((city) => (
                        <option key={city.city_id} value={city.name ? city.name : ''}>{city.name}</option>
                      ))
                    }
                  </select>
                  {city.showErrorMsg && <div className="error">*Please select city.</div>}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <FormGroup>
                  <Label for="pin">Pincode<span className="required-field"> *</span></Label>
                  <Input
                    autocomplete="nofill"
                    type="text"
                    value={pin_code.value}
                    name="pin_code"
                    maxLength="6"
                    onChange={this.props.handleInputChange}
                    id="pin_code"
                    placeholder="Enter Pincode" />
                  {pin_code.showErrorMsg && <div className="error">*Please enter pincode.</div>}
                </FormGroup>
              </Col>
            </Row>
          </Col>

          {this.props.onSave &&
            // <Col xl={this.state.is_display_sender_id ? "9" : "12"} xs="12" md="12" className="text-align-right mt-auto">
            <Col xl="12" xs="12" md="12" className="text-align-right" >
              <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" onClick={this.handleOnCancel}>Cancel</Button>
              <Button
                type="submit"
                className="mb-1 mr-1 btn-pill action-button profile-save-button"
                color="warning"
                disabled={!this.props.isFormValid}
                onClick={this.props.onSave}>Save</Button>
            </Col>}
          {/* {
            (this.props.onSave && this.state.is_display_sender_id) &&
            <Col xl="5" className="margin-top-n10px d-flex" style={{ maxWidth: '300px' }}>
              <span className="profile-sender-id-note">
                Sender ID approval will take 2 to 3 days, Once it's approved then you will receive notification for the same
              </span>
            </Col>
          } */}
        </Row>
        {/* {
          (!this.props.onSave && this.state.is_display_sender_id) &&
          <Row>
            <Col xl="5" style={{ maxWidth: '300px' }} className="margin-top-n10px d-flex">
              <span className="profile-sender-id-note">
                Sender ID approval will take 2 to 3 days, Once it's approved then you will receive notification for the same
              </span>
            </Col>
          </Row>
        } */}

      </CardBody>
    </Card >;
  }
}

export default EditOtherBusinessDetail;
