import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FormGroup, Label,
  Row, Col,
  CardBody,
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import { getRetailMarketingStoreInformationState } from '../selector';
import { handleValidationRetailMarketingStoreInformation, setRetailRecciControls } from '../../../actions/RetailManagementSystemAction';
import PropTypes from 'prop-types';
import './RetailMarktingViewUploadPhotographHO.css'
import RetailMarketingSystemService from '../../../services/RetailMarketingSystemService';
import CustomSpinner from '../../CustomSpinner/CustomSpinner';
function RetailMarktingViewUploadPhotographHO({
  storeDetailControls,
  setRetailRecciControlsAction,
  handleValidationRetailMarketingStoreInformationAction
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isPreviewImageLightBoxOpen, setIsPreviewImageLightBoxOpen] = useState(false);
  const [imagesToPreview, setImagesToPreview] = useState([]);
  const screenStatus = localStorage.getItem('screenStatus');

  // const handleInputChange = (e) => {
  //   const controlName = e.target.name;
  //   const controlValue = e.target.value;
  //   storeDetailControls[controlName].value = controlValue;
  //   storeDetailControls[controlName].touched = true;
  //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  //   handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
  // }

  // const handleInputChangePhotograph = (e, index) => {
  //   const controlName = e.target.name;
  //   const controlValue = e.target.value;
  //   const { proposedSizeForNewSignature } = storeDetailControls;
  //   if (controlName === 'height' || controlName === 'width') {
  //     if (!isNaN(e.target.value)) {
  //       proposedSizeForNewSignature[index][controlName].value = controlValue;
  //       if (proposedSizeForNewSignature[index]['height'].value &&
  //         proposedSizeForNewSignature[index]['width'].value) {
  //         let totalArea = Number(proposedSizeForNewSignature[index]['height'].value)
  //           * Number(proposedSizeForNewSignature[index]['width'].value);
  //         if (Number(totalArea) === totalArea && totalArea % 1 !== 0) {// check is float
  //           proposedSizeForNewSignature[index]['totalArea'].value = Number(proposedSizeForNewSignature[index]['totalArea'].value).toFixed(2);
  //         } else {
  //           proposedSizeForNewSignature[index]['totalArea'].value = totalArea;
  //         }
  //       } else {
  //         proposedSizeForNewSignature[index]['totalArea'].value = '';
  //       }
  //     }
  //   } else {
  //     proposedSizeForNewSignature[index][controlName].value = controlValue;
  //   }
  //   proposedSizeForNewSignature[index][controlName].touched = true;
  //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  //   handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
  // }

  const fileInputRefsFrontView = useRef([]);
  const fileInputRefsLeftView = useRef([]);
  const fileInputRefsRightView = useRef([]);
  const fileInputRefsCloseupView = useRef([]);
  const fileInputRefsLongShot = useRef([]);
  const selectPhoto = (index, type) => {
    if (type === 'fileInputRefsFrontView') {
      if (index > -1 && fileInputRefsFrontView.current[index]) {
        fileInputRefsFrontView.current[index].click();
      }
    }
    if (type === 'fileInputRefsLeftView') {
      if (index > -1 && fileInputRefsLeftView.current[index]) {
        fileInputRefsLeftView.current[index].click();
      }
    }
    if (type === 'fileInputRefsRightView') {
      if (index > -1 && fileInputRefsRightView.current[index]) {
        fileInputRefsRightView.current[index].click();
      }
    }
    if (type === 'fileInputRefsCloseupView') {
      if (index > -1 && fileInputRefsCloseupView.current[index]) {
        fileInputRefsCloseupView.current[index].click();
      }
    }
    if (type === 'fileInputRefsLongShot') {
      if (index > -1 && fileInputRefsLongShot.current[index]) {
        fileInputRefsLongShot.current[index].click();
      }
    }
  };
  // let fileInputAcknowledement = null;
  // const browseAcknowledement = () => {
  //   if (fileInputAcknowledement) {
  //     fileInputAcknowledement.click();
  //   }
  // }
  const uploadImage = async (event, index, controlName) => {
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      const result = await uploadRetailFile(file);
      const { proposedSizeForNewSignature } = storeDetailControls;
      if (result) {
        const { data } = result;
        proposedSizeForNewSignature[index][controlName].value = data.data.imageUrl;
        proposedSizeForNewSignature[index][controlName].touched = true;
        setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
      }
    }
  };
  const deletePhotoRow = (index) => {
    const { proposedSizeForNewSignature } = storeDetailControls;
    proposedSizeForNewSignature.splice(index, 1);
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
  }
  // const deletePhoto = (index, controlName) => {
  //   const { proposedSizeForNewSignature } = storeDetailControls;
  //   proposedSizeForNewSignature[index][controlName].value = '';
  //   proposedSizeForNewSignature[index][controlName].touched = true;
  //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  //   handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
  // }

  // const browseAcknowledementUploadFile = async (event, controlName) => {
  //   if (event.target.files[0]) {
  //     var files = event.target.files;
  //     var fileName = files[0].name;
  //     const file = new FormData();
  //     file.append("file", event.target.files[0]);
  //     const result = await uploadRetailFile(file);
  //     if (result) {
  //       const { data } = result;
  //       storeDetailControls[controlName].value = data.data.imageUrl;
  //       storeDetailControls[controlName].localFileName = fileName;
  //       storeDetailControls[controlName].touched = true;
  //       setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  //       handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
  //     }
  //   }
  // };

  // const removeAcknowledement = (controlName) => {
  //   storeDetailControls[controlName].value = '';
  //   storeDetailControls[controlName].localFileName = '';
  //   storeDetailControls[controlName].touched = true;
  //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  //   handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
  // }

  // const addMoreProposedSize = () => {
  //   storeDetailControls.proposedSizeForNewSignature.push(lodash.cloneDeep(retailPhotoGraphDetail));
  //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  // }

  const uploadRetailFile = async (reqData) => {
    setIsLoading(true);
    const response = await RetailMarketingSystemService.uploadRetailFile(reqData)
      .then((data) => {
        setIsLoading(false);
        return data;
      })
      .catch((e) => {
        console.error("e", e);
        setIsLoading(false);
        return false;
      });
    return response;
  };
  // const imagee = [];
  const closePreviewImageLightBox = () => {
    setImagesToPreview([]);
    setIsPreviewImageLightBoxOpen(false);
  }
  const openPreviewImageLightBox = (index, data) => {
    setPhotoIndex(index);
    var imagee = [];
    // storeDetailControls.proposedSizeForNewSignature.map((data)=>{
    if (data.frontView.value) {
      imagee.push(data.frontView.value);
    }
    if (data.closeupView.value) {
      imagee.push(data.closeupView.value);
    }
    if (data.longShotView.value) {
      imagee.push(data.longShotView.value);
    }
    if (data.leftView.value) {
      imagee.push(data.leftView.value);
    }
    if (data.rightView.value) {
      imagee.push(data.rightView.value);
    }
    // })
    setImagesToPreview(imagee);
    setIsPreviewImageLightBoxOpen(true);
  }
  return (
    <div id="RetailMarktingUploadPhotograph" style={{ position: 'relative' }}>
      {isLoading && <CustomSpinner />}
      {isPreviewImageLightBoxOpen && (
        <Lightbox
          mainSrc={imagesToPreview[photoIndex]}
          nextSrc={imagesToPreview[(photoIndex + 1) % imagesToPreview.length]}
          prevSrc={imagesToPreview[(photoIndex + imagesToPreview.length - 1) % imagesToPreview.length]}
          onCloseRequest={closePreviewImageLightBox}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesToPreview.length - 1) %
              imagesToPreview.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesToPreview.length
            )
          }
        />
      )}
      <div>
        <CardBody>
          <Row>

            <Col xl="6" md="12" xs="12">
            </Col>
          </Row>

          {storeDetailControls.proposedSizeForNewSignature.map((data, index) => {
            return (
              <>
                <Row>
                  {/* {
                    index === 0 &&
                    <Col xl="12" md="12" xs="12" className="mb-3">
                      <b>Prposed size for new signature (In Intches)</b>
                    </Col>
                  } */}
                  <span className="count font-weight-bold"
                    style={{ marginTop: index === 0 ? '0' : '0', marginLeft: '-6px' }}>
                    {index + 1}
                  </span>
                  <Col xl="3" md="3" xs="12" className="ml-3">
                    <Row>
                      <Col xl="6" md="12" xs="12">
                        <FormGroup>
                          <Label>
                            Width
                          </Label>
                          <div className="d-flex" style={{ fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: '14px' }}>
                            {data.width.value} inches
                            {/* <Input
                              type="text"
                              name="width"
                              value={data.width.value}
                              onChange={(e) => handleInputChangePhotograph(e, index)}
                              placeholder="Type here"
                            /> */}
                          </div>
                          {data.width.showErrorMsg && (
                            <div className="error">
                              * Please enter width.
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xl="6" md="12" xs="12">
                        <FormGroup>
                          <Label>
                            Height
                          </Label>
                          <div className="d-flex" style={{ fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: '14px' }}>
                            {data.height.value} inches
                            {/* <Input
                              type="text"
                              name="height"
                              value={data.height.value}
                              onChange={(e) => handleInputChangePhotograph(e, index)}
                              placeholder="Type here"
                            /> */}
                          </div>
                          {data.height.showErrorMsg && (
                            <div className="error">
                              * Please enter height.
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      {/* <Col xl="6" md="12" xs="12">
                        <FormGroup>
                          <Label>
                            Quantity<span className="required-field">*</span>
                          </Label>
                          <div className="d-flex">
                            <Input
                              type="text"
                              name="qty"
                              value={data.qty.value}
                              maxLength="6"
                              onChange={(e) => handleInputChange(e)}
                              placeholder="Type here"
                            />
                          </div>
                          {data.qty.showErrorMsg && (
                            <div className="error">
                              * Please enter quantity.
                            </div>
                          )}
                        </FormGroup>
                      </Col> */}
                      <Col xl="12" md="12" xs="12">
                        <FormGroup>
                          <Label>
                            Total Area (sq.ft)
                          </Label>
                          <div className="d-flex" style={{ fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: '14px' }}>
                            {data.totalArea.value} sq ft
                            {/* <Input
                              type="text"
                              name="totalArea"
                              value={data.totalArea.value}
                              placeholder="Type here"
                            /> */}
                          </div>
                          {data.width.showErrorMsg && (
                            <div className="error">
                              * Please enter total area (sq.ft).
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="8" md="8" xs="12" className="d-flex">
                    <div className="d-flex-column upload-photo-wrapper">
                      {data.frontView.value && <div className="d-flex justify-content-between">
                        <Label
                          className="photo-lbl"
                        >Front View </Label>

                      </div>}
                      {
                        data.frontView.value ?
                          <div className="photo-view" style={{
                            backgroundImage: `url(${data.frontView.value})`
                          }}
                            onClick={() => openPreviewImageLightBox(0, data)}           >
                          </div>
                          :
                          data.frontView.value && <div className="upload-photo-div d-flex"
                            onClick={() => selectPhoto(index,
                              "fileInputRefsFrontView"
                            )}>
                            <img src="images/photo_upload.png" alt='' style={{ height: '20px', margin: 'auto' }} />&nbsp;
                          </div>
                      }
                      <input
                        accept="image/*"
                        type="file"
                        onChange={(e) => uploadImage(e, index,
                          "frontView")}
                        ref={(el) => (fileInputRefsFrontView.current[index] = el)}
                        style={{ display: "none" }}
                      />
                      {data.frontView.showErrorMsg && (<div className="error">
                        * Upload front view.
                      </div>
                      )}
                    </div>
                    <div className="d-flex-column upload-photo-wrapper">
                      {data.closeupView.value && <div className="d-flex justify-content-between">
                        <Label
                          className="photo-lbl"
                        >Closeup View</Label>
                      </div>}
                      {
                        data.closeupView.value ?
                          <div className="photo-view" style={{
                            backgroundImage: `url(${data.closeupView.value})`
                          }}
                            onClick={() => openPreviewImageLightBox(1, data)}
                          >
                          </div>
                          :
                          data.closeupView.value && <div className="upload-photo-div d-flex"
                            onClick={() => selectPhoto(index,
                              "fileInputRefsCloseupView"
                            )}>
                            <img src="images/photo_upload.png" alt='' style={{ height: '20px', margin: 'auto' }} />&nbsp;
                          </div>
                      }
                      <input
                        accept="image/*"
                        type="file"
                        onChange={(e) => uploadImage(e, index,
                          "closeupView")}
                        ref={(el) => (fileInputRefsCloseupView.current[index] = el)}
                        style={{ display: "none" }}
                      />
                      {data.closeupView.showErrorMsg && (<div className="error">
                        * Upload closeup view.
                      </div>
                      )}
                    </div>
                    <div className="d-flex-column upload-photo-wrapper">
                      {data.longShotView.value && <div className="d-flex justify-content-between">
                        <Label
                          className="photo-lbl"
                        >Long Shot</Label>
                      </div>}
                      {
                        data.longShotView.value ?
                          <div className="photo-view" style={{
                            backgroundImage: `url(${data.longShotView.value})`
                          }}
                            onClick={() => openPreviewImageLightBox(2, data)}
                          >
                          </div>
                          :
                          data.longShotView.value && <div className="upload-photo-div d-flex"
                            onClick={() => selectPhoto(index,
                              "fileInputRefsLongShot"
                            )}>
                            <img src="images/photo_upload.png" alt='' style={{ height: '20px', margin: 'auto' }} />&nbsp;
                          </div>
                      }
                      <input
                        accept="image/*"
                        type="file"
                        onChange={(e) => uploadImage(e, index, 'longShotView')}
                        ref={(el) => (fileInputRefsLongShot.current[index] = el)}
                        style={{ display: "none" }}
                      />
                      {data.longShotView.showErrorMsg && (<div className="error">
                        * Upload Longshot view.
                      </div>
                      )}
                    </div>
                    <div className="d-flex-column upload-photo-wrapper">
                      {data.leftView.value && <div className="d-flex justify-content-between">
                        <Label
                          className="photo-lbl"
                        >Left View</Label>
                      </div>}
                      {
                        data.leftView.value ?
                          <div className="photo-view" style={{
                            backgroundImage: `url(${data.leftView.value})`
                          }}
                            onClick={() => openPreviewImageLightBox(3, data)}
                          >
                          </div>
                          :
                          data.leftView.value && <div className="upload-photo-div d-flex"
                            onClick={() => selectPhoto(index,
                              "fileInputRefsLeftView"
                            )}>
                            <img src="images/photo_upload.png" alt='' style={{ height: '20px', margin: 'auto' }} />&nbsp;
                          </div>
                      }
                      <input
                        accept="image/*"
                        type="file"
                        // onChange={(e) => uploadImage(e, index, 'leftView')}
                        ref={(el) => (fileInputRefsLeftView.current[index] = el)}
                        style={{ display: "none", }}
                      />
                    </div>
                    <div className="d-flex-column upload-photo-wrapper">
                      {data.rightView.value && <div className="d-flex justify-content-between">
                        <Label
                          className="photo-lbl"
                        >Right View</Label>
                      </div>}
                      {
                        data.rightView.value ?
                          <div className="photo-view" style={{
                            backgroundImage: `url(${data.rightView.value})`
                          }}
                            onClick={() => openPreviewImageLightBox(4, data)}
                          >
                          </div>
                          :
                          data.rightView.value && <div className="upload-photo-div d-flex"
                            onClick={() => selectPhoto(index,
                              "fileInputRefsRightView"
                            )}>
                            <img src="images/photo_upload.png" alt='' style={{ height: '20px', margin: 'auto' }} />&nbsp;
                          </div>
                      }
                      <input
                        accept="image/*"
                        type="file"
                        // onChange={(e) => uploadImage(e, index, 'rightView')}
                        ref={(el) => (fileInputRefsRightView.current[index] = el)}
                        style={{ display: "none" }}
                      />
                    </div>
                    {screenStatus === 'recce_pending' && storeDetailControls.proposedSizeForNewSignature.length > 1 && screenStatus !== 'installation_pending' && <div className="delete-board mt-auto mb-auto ml-1"
                      style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                      onClick={() => deletePhotoRow(index)}>
                      <img src="/images/delete_blue.svg" alt='' className="cursor-pointer"
                        style={{ height: '14px' }} />&nbsp;
                      Delete Board
                    </div>}
                  </Col>
                </Row>
              </>
            )
          }
          )}
          <Row>
            {/* <Col xl="12" md="12" xs="12" className="mb-3 mt-4" style={{ marginLeft: '-5px' }}>
              <div className="d-flex-row">
                <img src="/images/add_blue.svg" alt='' className="mt-auto mb-auto cursor-pointer"
                  onClick={() => addMoreProposedSize()}
                  style={{ height: '16px' }} />
                <div className="add-more ml-2 cursor-pointer"
                  onClick={() => addMoreProposedSize()}
                >Add More Proposed Size for New Signage</div>
              </div>
            </Col> */}
          </Row>
        </CardBody>
      </div>
    </div>
  );
}

RetailMarktingViewUploadPhotographHO.propTypes = {
  storeDetailControls: PropTypes.instanceOf(Object).isRequired,
  setRetailRecciControlsAction: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    storeDetailControls: getRetailMarketingStoreInformationState(state),
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setRetailRecciControlsAction: setRetailRecciControls,
      handleValidationRetailMarketingStoreInformationAction: handleValidationRetailMarketingStoreInformation,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetailMarktingViewUploadPhotographHO);