import lodash from "lodash";
import {
  retailPhotoGraphDetail,
  retailPhotoGraphDetailInstallation,
  retailPhotoGraphDetailInstallationSignage_New,
  retailPhotoGraphDetailInstallation_New,
  retailPhotoGraphDetailSignage_New,
  retailPhotoGraphDetail_New,
} from "../components/RetailMarketingSystem/RetailMarktingControls";
import {
  SET_RETAIL_RECCI_STORE_INFORMATION,
  SET_RECCE_STATUS,
  SET_HO_RECCE_MODIFICATION,
  SET_RECCE_STATUS_COUNT,
  SET_RECCE_STATUS_COUNT_FILTER,
  SET_VENDOR_SCREEN,
  SET_FILTER_DATA,
  SET_FILTER_OPEN_CLOSE,
  SET_FILTER_APPLIED_STATUS,
  SET_DUPLICATE_RECCE,
} from "../constants/types";
import Validation from "../services/Validation";
export const setRetailRecciControls = (addReccicontrols) => ({
  type: SET_RETAIL_RECCI_STORE_INFORMATION,
  addReccicontrols,
});

export const setFilterOpenClose = (filterOpen) => ({
  type: SET_FILTER_OPEN_CLOSE,
  filterOpen,
});

export const setDuplicateRecce = (duplicateRecce) => ({
  type: SET_DUPLICATE_RECCE,
  duplicateRecce,
});

export const setFilterAppliedStatus = (filterAppliedStatus) => ({
  type: SET_FILTER_APPLIED_STATUS,
  filterAppliedStatus,
});

export const setFilterData = (filterData) => ({
  type: SET_FILTER_DATA,
  filterData,
});
export const setHoRecceControls = (hoRecceControl) => ({
  type: SET_HO_RECCE_MODIFICATION,
  hoRecceControl,
});
export const setRecceStatus = (status) => ({
  type: SET_RECCE_STATUS,
  status,
});

export const setVendorScreen = (vendorScreen) => ({
  type: SET_VENDOR_SCREEN,
  vendorScreen,
});

export const setRecceStatusCount = (count) => ({
  type: SET_RECCE_STATUS_COUNT,
  count,
});

export const setRecceStatusCountFilter = (statusCount) => ({
  type: SET_RECCE_STATUS_COUNT_FILTER,
  statusCount,
});

export const handleValidationRetailMarketingStoreInformation = (
  isSubmit = false, // When click on submit button , pass isSubmit=true
  storeDetailControls
) => {
  return (dispatch) => {
    let isFormValid = false;
    let {
      address,
      nameOnTheSignature,
      proposedSizeForNewSignature,
      state,
      city,
      pincode,
      district,
      dealer,
      recce_date,
      acknowledgementForm,
      horemarks,
      horemarkReject,
      installation_date,
      proposedSizeForNewSignatureInstallation,
      acknowledgementFormInstallation,
      sapid,
      imgwidth,
      imgheight,
    } = storeDetailControls;

    if (horemarks.touched === true || isSubmit) {
      horemarks = Validation.notNullValidator(horemarks);
      horemarks.valid = !horemarks.nullValue;
      if (horemarks.valid === false) {
        horemarks.showErrorMsg = true;
      } else {
        horemarks.showErrorMsg = false;
      }
    }
    if (horemarkReject.touched === true || isSubmit) {
      horemarkReject = Validation.notNullValidator(horemarkReject);
      horemarkReject.valid = !horemarkReject.nullValue;
      if (horemarkReject.valid === false) {
        horemarkReject.showErrorMsg = true;
      } else {
        horemarkReject.showErrorMsg = false;
      }
    }
    if (recce_date.touched === true || isSubmit) {
      recce_date = Validation.notNullValidator(recce_date);
      recce_date.valid = !recce_date.nullValue;
      if (recce_date.valid === false) {
        recce_date.showErrorMsg = true;
      } else {
        recce_date.showErrorMsg = false;
      }
    }

    if (installation_date.touched === true || isSubmit) {
      installation_date = Validation.notNullValidator(installation_date);
      installation_date.valid = !installation_date.nullValue;
      if (installation_date.valid === false) {
        installation_date.showErrorMsg = true;
      } else {
        installation_date.showErrorMsg = false;
      }
    }

    if (address.touched === true || isSubmit) {
      address = Validation.notNullValidator(address);
      address.valid = !address.nullValue;
      if (address.valid === false) {
        address.showErrorMsg = true;
      } else {
        address.showErrorMsg = false;
      }
    }
    if (dealer.touched === true || isSubmit) {
      dealer = Validation.notNullValidator(dealer);
      dealer.valid = !dealer.nullValue;
      if (dealer.valid === false) {
        dealer.showErrorMsg = true;
      } else {
        dealer.showErrorMsg = false;
      }
    }
    if (nameOnTheSignature.touched === true || isSubmit) {
      nameOnTheSignature = Validation.notNullValidator(nameOnTheSignature);
      nameOnTheSignature.valid = !nameOnTheSignature.nullValue;
      if (nameOnTheSignature.valid === false) {
        nameOnTheSignature.showErrorMsg = true;
      } else {
        nameOnTheSignature.showErrorMsg = false;
      }
    }

    if (state.touched === true || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (city.touched === true || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (district.touched === true || isSubmit) {
      district = Validation.notNullValidator(district);
      district.valid = !district.nullValue;
      if (district.valid === false) {
        district.showErrorMsg = true;
      } else {
        district.showErrorMsg = false;
      }
    }

    if (acknowledgementForm.touched === true || isSubmit) {
      acknowledgementForm = Validation.notNullValidator(acknowledgementForm);
      acknowledgementForm.valid = !acknowledgementForm.nullValue;
      if (acknowledgementForm.valid === false) {
        acknowledgementForm.showErrorMsg = true;
      } else {
        acknowledgementForm.showErrorMsg = false;
      }
    }
    if (acknowledgementFormInstallation.touched === true || isSubmit) {
      acknowledgementFormInstallation = Validation.notNullValidator(
        acknowledgementFormInstallation
      );
      acknowledgementFormInstallation.valid = !acknowledgementFormInstallation.nullValue;
      if (acknowledgementFormInstallation.valid === false) {
        acknowledgementFormInstallation.showErrorMsg = true;
      } else {
        acknowledgementFormInstallation.showErrorMsg = false;
      }
    }

    if (pincode.touched === true || isSubmit) {
      pincode = Validation.notNullValidator(pincode);
      pincode = Validation.validateLength(pincode, 6);
      pincode = Validation.validateNumber(pincode);
      pincode.valid = !(
        pincode.nullValue ||
        pincode.invalidLength ||
        pincode.invalidNumber
      );
      if (pincode.valid === false) {
        pincode.showErrorMsg = true;
      } else {
        pincode.showErrorMsg = false;
      }
    }

    if (sapid.touched === true || isSubmit) {
      sapid = Validation.notNullValidator(sapid);
      sapid.valid = !sapid.nullValue;
      if (sapid.valid === false) {
        sapid.showErrorMsg = true;
      } else {
        sapid.showErrorMsg = false;
      }
    }

    if (imgwidth.touched === true || isSubmit) {
      imgwidth = Validation.notNullValidator(imgwidth);
      imgwidth.valid = !imgwidth.nullValue;
      if (imgwidth.valid === false) {
        imgwidth.showErrorMsg = true;
      } else {
        imgwidth.showErrorMsg = false;
      }
    }

    if (imgheight.touched === true || isSubmit) {
      imgheight = Validation.notNullValidator(imgheight);
      imgheight.valid = !imgheight.nullValue;
      if (imgheight.valid === false) {
        imgheight.showErrorMsg = true;
      } else {
        imgheight.showErrorMsg = false;
      }
    }

    let isFormValidproposedSizeForNewSignature = true;

    proposedSizeForNewSignature.forEach((element) => {
      for (const [key] of Object.entries(element)) {
        if (
          (element[key].touched === true || isSubmit) &&
          (key === "width" ||
            key === "height" ||
            key === "closeupView" ||
            key === "longShotView" ||
            key === "frontView")
        ) {
          element[key] = Validation.notNullValidator(element[key]);
          element[key].valid = !element[key].nullValue;
          if (element.totalArea.value <= 0) {
            element.totalArea.valid = false;
            element.totalArea.showErrorMsg = true;
            isFormValidproposedSizeForNewSignature = false;
          }
          if (element.totalArea.value > 0) {
            element.totalArea.valid = true;
            element.totalArea.showErrorMsg = false;
            isFormValidproposedSizeForNewSignature = true;
          }
          if (element[key].valid === false) {
            element[key].showErrorMsg = true;
            isFormValidproposedSizeForNewSignature = false;
          } else {
            element[key].showErrorMsg = false;
          }
        }
      }
    });
    let isFormValidproposedSizeForNewSignatureInstallation = true;
    proposedSizeForNewSignatureInstallation.forEach((element) => {
      for (const [key] of Object.entries(element)) {
        if (
          (element[key].touched === true || isSubmit) &&
          (key === "closeupView" ||
            key === "longShotView" ||
            key === "frontView")
        ) {
          element[key] = Validation.notNullValidator(element[key]);
          element[key].valid = !element[key].nullValue;
          if (element[key].valid === false) {
            element[key].showErrorMsg = true;
            isFormValidproposedSizeForNewSignatureInstallation = false;
          } else {
            element[key].showErrorMsg = false;
          }
        }
      }
    });

    if (
      dealer.valid === true &&
      recce_date.valid === true &&
      address.valid === true &&
      state.valid === true &&
      city.valid === true &&
      pincode.valid === true &&
      district.valid === true &&
      sapid.valid === true &&
      imgwidth.valid === true &&
      imgheight.valid === true &&
      acknowledgementForm.valid === true &&
      isFormValidproposedSizeForNewSignature === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    dispatch(setRetailRecciControls(storeDetailControls));
    return isFormValid;
  };
};

export const handleValidationMiniRetailMarketingStoreInformation = (
  isSubmit = false, // When click on submit button , pass isSubmit=true
  storeDetailControls
) => {
  return (dispatch) => {
    let isFormValid = false;
    let {
      recce_date,
      imgwidth,
      imgheight,
      dealer,
      proposedSizeForNewSignatureSignage_New,
      proposedSizeForNewSignatureInstallationSignage_New,
    } = storeDetailControls;

    if (recce_date.touched === true || isSubmit) {
      recce_date = Validation.notNullValidator(recce_date);
      recce_date.valid = !recce_date.nullValue;
      if (recce_date.valid === false) {
        recce_date.showErrorMsg = true;
      } else {
        recce_date.showErrorMsg = false;
      }
    }

    if (imgwidth.touched === true || isSubmit) {
      imgwidth = Validation.notNullValidator(imgwidth);
      imgwidth.valid = !imgwidth.nullValue;
      if (imgwidth.valid === false) {
        imgwidth.showErrorMsg = true;
      } else {
        imgwidth.showErrorMsg = false;
      }
    }

    if (imgheight.touched === true || isSubmit) {
      imgheight = Validation.notNullValidator(imgheight);
      imgheight.valid = !imgheight.nullValue;
      if (imgheight.valid === false) {
        imgheight.showErrorMsg = true;
      } else {
        imgheight.showErrorMsg = false;
      }
    }

    if (dealer.touched === true || isSubmit) {
      dealer = Validation.notNullValidator(dealer);
      dealer.valid = !dealer.nullValue;
      if (dealer.valid === false) {
        dealer.showErrorMsg = true;
      } else {
        dealer.showErrorMsg = false;
      }
    }

    let isFormValidproposedSizeForNewSignature = true;
    proposedSizeForNewSignatureSignage_New.forEach((element) => {
      for (const [key] of Object.entries(element)) {
        if ((element[key].touched === true || isSubmit) && key === "image1") {
          element[key] = Validation.notNullValidator(element[key]);
          element[key].valid = !element[key].nullValue;
          if (element[key].valid === false) {
            element[key].showErrorMsg = true;
            isFormValidproposedSizeForNewSignature = false;
          } else {
            element[key].showErrorMsg = false;
          }
        }
      }
    });
    let isFormValidproposedSizeForNewSignatureInstallation = true;
    proposedSizeForNewSignatureInstallationSignage_New.forEach((element) => {
      for (const [key] of Object.entries(element)) {
        if ((element[key].touched === true || isSubmit) && key === "image1") {
          element[key] = Validation.notNullValidator(element[key]);
          element[key].valid = !element[key].nullValue;
          if (element[key].valid === false) {
            element[key].showErrorMsg = true;
            isFormValidproposedSizeForNewSignatureInstallation = false;
          } else {
            element[key].showErrorMsg = false;
          }
        }
      }
    });

    if (
      recce_date.valid === true &&
      imgwidth.valid === true &&
      dealer.valid === true &&
      imgheight.valid === true &&
      isFormValidproposedSizeForNewSignatureInstallation === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    dispatch(setRetailRecciControls(storeDetailControls));
    return isFormValid;
  };
};

export const setRetailRecciDetail = (storeDetailControls, detail) => {
  return (dispatch) => {
    const { dealer_detail, boards_detail } = detail;
    storeDetailControls.recce_date.value = dealer_detail.recce_date
      ? new Date(dealer_detail.recce_date)
      : "";
    storeDetailControls.installation_date.value = dealer_detail.installation_date
      ? new Date(dealer_detail.installation_date)
      : "";
    storeDetailControls.dealer.value = dealer_detail.dealer_id;
    storeDetailControls.address.value = dealer_detail.address;
    storeDetailControls.state.value = dealer_detail.state_id;
    storeDetailControls.city.value = dealer_detail.city_id;
    storeDetailControls.district.value = dealer_detail.district_id;
    storeDetailControls.pincode.value = dealer_detail.pin_code;
    storeDetailControls.nameOnTheSignature.value = detail.signage_name;
    storeDetailControls.acknowledgementForm.value = detail.acknowledgement_form;
    storeDetailControls.acknowledgementFormInstallation.value =
      detail.installation_acknowledgement_form;
    storeDetailControls.remarks.value = detail.vendor_comment;
    storeDetailControls.remarksInstallation.value =
      detail.vendor_comment_installation;
    if (detail.acknowledgement_form) {
      storeDetailControls.acknowledgementForm.localFileName = detail.acknowledgement_form
        .split("/")
        .pop();
    }
    if (detail.installation_acknowledgement_form) {
      storeDetailControls.acknowledgementFormInstallation.localFileName = detail.installation_acknowledgement_form
        .split("/")
        .pop();
    }
    if (boards_detail && boards_detail.length > 0) {
      storeDetailControls.proposedSizeForNewSignature = [];
      storeDetailControls.proposedSizeForNewSignatureInstallation = [];
      const {
        proposedSizeForNewSignature,
        proposedSizeForNewSignatureInstallation,
      } = storeDetailControls;
      boards_detail.forEach((element, i) => {
        proposedSizeForNewSignatureInstallation.push(
          lodash.cloneDeep(retailPhotoGraphDetailInstallation)
        );
        proposedSizeForNewSignature.push(
          lodash.cloneDeep(retailPhotoGraphDetail)
        );
        storeDetailControls.installation_date.value = element.installtion_date
          ? new Date(element.installtion_date)
          : "";
        proposedSizeForNewSignatureInstallation[i].frontView.value =
          element.post_facade_front_view;
        proposedSizeForNewSignatureInstallation[i].leftView.value =
          element.post_facade_left_view;
        proposedSizeForNewSignatureInstallation[i].rightView.value =
          element.post_facade_right_view;
        proposedSizeForNewSignatureInstallation[i].closeupView.value =
          element.post_facade_closeup_shot;
        proposedSizeForNewSignatureInstallation[i].longShotView.value =
          element.post_facade_long_shot;
        proposedSizeForNewSignature[i].height.value = element.signage_height;
        proposedSizeForNewSignature[i].width.value = element.signage_width;
        proposedSizeForNewSignature[i].totalArea.value =
          element.square_feet_area;
        proposedSizeForNewSignature[i].qty.value = element.quantity;
        proposedSizeForNewSignature[i].frontView.value =
          element.pre_facade_front_view;
        proposedSizeForNewSignature[i].leftView.value =
          element.pre_facade_left_view;
        proposedSizeForNewSignature[i].rightView.value =
          element.pre_facade_right_view;
        proposedSizeForNewSignature[i].closeupView.value =
          element.pre_facade_closeup_shot;
        proposedSizeForNewSignature[i].longShotView.value =
          element.pre_facade_long_shot;
      });
    }
    dispatch(setRetailRecciControls(storeDetailControls));
  };
};

export const resetStoreDetailControls = (storeDetailControls) => {
  return (dispatch) => {
    storeDetailControls.recce_date.value = "";
    storeDetailControls.recce_date.showErrorMsg = false;
    storeDetailControls.recce_date.touched = false;
    storeDetailControls.sapid.value = "";
    storeDetailControls.sapid.showErrorMsg = false;
    storeDetailControls.sapid.touched = false;
    storeDetailControls.imgwidth.value = "";
    storeDetailControls.imgwidth.showErrorMsg = false;
    storeDetailControls.imgwidth.touched = false;
    storeDetailControls.imgheight.value = "";
    storeDetailControls.imgheight.showErrorMsg = false;
    storeDetailControls.imgheight.touched = false;
    storeDetailControls.installation_date.value = "";
    storeDetailControls.installation_date.showErrorMsg = false;
    storeDetailControls.installation_date.touched = false;
    storeDetailControls.dealer.value = "";
    storeDetailControls.dealer.showErrorMsg = false;
    storeDetailControls.dealer.touched = false;
    storeDetailControls.dealer.displayValue = "";
    storeDetailControls.address.value = "";
    storeDetailControls.address.showErrorMsg = false;
    storeDetailControls.address.touched = false;
    storeDetailControls.state.value = "";
    storeDetailControls.state.showErrorMsg = false;
    storeDetailControls.state.touched = false;
    storeDetailControls.city.value = "";
    storeDetailControls.city.showErrorMsg = false;
    storeDetailControls.city.touched = false;
    storeDetailControls.district.value = "";
    storeDetailControls.district.showErrorMsg = false;
    storeDetailControls.district.touched = false;
    storeDetailControls.pincode.value = "";
    storeDetailControls.pincode.showErrorMsg = false;
    storeDetailControls.pincode.touched = false;
    storeDetailControls.nameOnTheSignature.value = "";
    storeDetailControls.nameOnTheSignature.showErrorMsg = false;
    storeDetailControls.nameOnTheSignature.touched = false;
    storeDetailControls.remarks.value = "";
    storeDetailControls.remarks.showErrorMsg = false;
    storeDetailControls.remarks.touched = false;
    storeDetailControls.acknowledgementForm.value = "";
    storeDetailControls.acknowledgementForm.showErrorMsg = false;
    storeDetailControls.acknowledgementForm.touched = false;
    storeDetailControls.acknowledgementForm.localFileName = "";
    storeDetailControls.acknowledgementFormInstallation.value = "";
    storeDetailControls.acknowledgementFormInstallation.showErrorMsg = false;
    storeDetailControls.acknowledgementFormInstallation.touched = false;
    storeDetailControls.acknowledgementFormInstallation.localFileName = "";
    storeDetailControls.proposedSizeForNewSignature = [];
    storeDetailControls.proposedSizeForNewSignatureInstallation = [];
    storeDetailControls.proposedSizeForNewSignature_New = [];
    storeDetailControls.proposedSizeForNewSignatureInstallation_New = [];
    storeDetailControls.proposedSizeForNewSignatureSignage_New = [];
    storeDetailControls.proposedSizeForNewSignatureInstallationSignage_New = [];
    storeDetailControls.proposedSizeForNewSignatureInstallation.push(
      lodash.cloneDeep(retailPhotoGraphDetailInstallation)
    );
    storeDetailControls.proposedSizeForNewSignature.push(
      lodash.cloneDeep(retailPhotoGraphDetail)
    );
    storeDetailControls.proposedSizeForNewSignature_New.push(
      lodash.cloneDeep(retailPhotoGraphDetail_New)
    );
    storeDetailControls.proposedSizeForNewSignatureInstallation_New.push(
      lodash.cloneDeep(retailPhotoGraphDetailInstallation_New)
    );
    storeDetailControls.proposedSizeForNewSignatureSignage_New.push(
      lodash.cloneDeep(retailPhotoGraphDetailSignage_New)
    );
    storeDetailControls.proposedSizeForNewSignatureInstallationSignage_New.push(
      lodash.cloneDeep(retailPhotoGraphDetailInstallationSignage_New)
    );
    dispatch(setRetailRecciControls(storeDetailControls));
  };
};

export const resetfilterData = (filterData) => {
  return (dispatch) => {
    filterData.zone_id.value = [];
    filterData.zone_id.showErrorMsg = false;
    filterData.zone_id.touched = false;
    filterData.region_id.value = [];
    filterData.region_id.showErrorMsg = false;
    filterData.region_id.touched = false;
    filterData.area_id.value = [];
    filterData.area_id.showErrorMsg = false;
    filterData.area_id.touched = false;
    filterData.state_id.value = [];
    filterData.state_id.showErrorMsg = false;
    filterData.state_id.touched = false;
    filterData.city_id.value = [];
    filterData.city_id.showErrorMsg = false;
    filterData.city_id.touched = false;
    filterData.vendor_id.value = '';
    filterData.vendor_id.displayValue = '';
    filterData.vendor_id.showErrorMsg = false;
    filterData.vendor_id.touched = false;
    filterData.created_at_start_date.value = "";
    filterData.created_at_start_date.showErrorMsg = false;
    filterData.created_at_start_date.touched = false;
    filterData.created_at_end_date.value = "";
    filterData.created_at_end_date.showErrorMsg = false;
    filterData.created_at_end_date.touched = false;
    filterData.recce_start_date.value = "";
    filterData.recce_start_date.showErrorMsg = false;
    filterData.recce_start_date.touched = false;
    filterData.recce_end_date.value = "";
    filterData.recce_end_date.showErrorMsg = false;
    filterData.recce_end_date.touched = false;
    filterData.installation_start_date.value = "";
    filterData.installation_start_date.showErrorMsg = false;
    filterData.installation_start_date.touched = false;
    filterData.installation_end_date.value = "";
    filterData.installation_end_date.showErrorMsg = false;
    filterData.installation_end_date.touched = false;
    filterData.club_name.value = "";
    filterData.club_name.showErrorMsg = false;
    filterData.club_name.touched = false;
    filterData.status.value = "";
    filterData.status.showErrorMsg = false;
    filterData.status.touched = false;
    dispatch(setFilterData(filterData));
  };
};
