import { request } from './Request';
import { routes } from '../constants/constant.route';

const API_URL = process.env.REACT_APP_API_URL;

export default class CustomerRatingService {

  static getDealerInfo(req) {
    req.source = 'feedback';
    return request('POST', `${API_URL}${routes.CUSTOMER_REFERNCE.GET_DEALER_INFO}`, null, req, null);
  }
}