import React, { Component, Fragment } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import Ionicon from "react-ionicons";
import cx from "classnames";
import moment from "moment";

// css
import "./ProfileDetail.css";

// modal
import RequestDetailUpdateModal from "../../modal/RequestDetailUpdateModal/RequestDetailUpdateModal";
import ChangePassword from "../../modal/ChangePassword/ChangePassword";

// service
import ModalService from "../../services/ModalService";
import StorageService from "../../services/Storage";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { withRouter } from "react-router-dom";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

class ProfileDetail extends Component {
  state = {
    height: window.innerHeight,
    width: window.innerWidth,
    isRequestDetailModalOpen: false,
    isChangePasswordModalOpen: false,
    userRole: null,
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeWindow);
    const userDetail = StorageService.getUserDetail();
    const { type: userRole } = userDetail;
    this.setState({ userRole });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  openRequestDetailModal = () => {
    GoogleAnalticsService.pageView("Request Profile Edit View");
    this.setState({ isRequestDetailModalOpen: true });
  };

  closeRequestDetailModal = (update) => {
    this.setState({ isRequestDetailModalOpen: false });
    if (update === true) {
      this.props.updateReqButton(update);
    }
  };

  openChangePasswordModal = () => {
    this.setState({ isChangePasswordModalOpen: true });
  };

  closeChangePasswordModal = (update, message) => {
    this.setState({ isChangePasswordModalOpen: false });
    if (update === true) {
      ModalService.openAlert("Update Password", message, "success");
    }
  };

  render() {
    const { sapId, address, isImageUpdating, userDetail, userInfo } = this.props;
    const { userRole } = this.state;
    const { history } = this.props;
    return (
      <div
        id="profile-detail"
      // className="height-100"
      >
        {this.state.isRequestDetailModalOpen && (
          <RequestDetailUpdateModal
            show={this.state.isRequestDetailModalOpen}
            closeModal={this.closeRequestDetailModal}
          />
        )}

        {this.state.isChangePasswordModalOpen && (
          <ChangePassword
            show={this.state.isChangePasswordModalOpen}
            closeModal={this.closeChangePasswordModal}
          ></ChangePassword>
        )}

        <Card
          id="profile-detail"
        // className="height-100"
        >
          <CardBody
            style={
              userDetail &&
                userDetail.permissions &&
                userDetail.permissions.includes(Permissions.PROFILE) === true &&
                (userRole !== Roles.SUB_DEALER_ROLE || userRole !== Roles.BRANCH_ROLE)
                ? { paddingBottom: "0px" }
                : {}
            }
          >
            {history && history.location.pathname && history.location.pathname === "/user-updates" ? "" :
              <Row>
                <Col className="text-align-right">
                  <div
                    className="link cursor-pointer"
                    onClick={this.openChangePasswordModal}
                  >
                    Change Password
                  </div>
                  <br />
                </Col>
              </Row>
            }
            <Row>
              <Col className="text-align-center">
                {!this.props.profileImage && (
                  <img
                    src="images/Web_Avatar.svg"
                    height="50px"
                    width="auto"
                    className="profile-image"
                    alt=""
                  />
                )}
                {this.props.profileImage && (
                  <img
                    src={this.props.profileImage}
                    height="50px"
                    width="auto"
                    className="profile-image"
                    alt=""
                  />
                )}
                {isImageUpdating && (
                  <Spinner
                    size="sm"
                    color="primary"
                    className="profile-spinner"
                  />
                )}
                {history && history.location.pathname && history.location.pathname === "/user-updates" ? "" :
                  <div className="create-icon-con">
                    <label className="profile-image-camera-side">
                      <div className="edit-icon">
                        <Ionicon icon="md-create" fontSize="24px" color="#fff" />
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        className="visibility-hidden width-0"
                        onChange={this.props.handleUploadImage}
                      />
                    </label>
                  </div>
                }
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="text-align-center user-name">
                <b className="title-case">{this.props.ownerName}</b>
              </Col>
            </Row>
            <hr />
            {((userDetail && !userDetail.permissions) ||
              (userDetail && userDetail.permissions &&
                userDetail.permissions.length > 0 &&
                userDetail.permissions.includes(Permissions.PROFILE) === true &&
                (userRole !== Roles.SUB_DEALER_ROLE || userRole !== Roles.BRANCH_ROLE)))
              && (
                <Fragment>
                  <Row>
                    <Col>
                      <div>Business Name</div>
                      <div className="title-case">
                        <b>{this.props.businessName}</b>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div>Member Code</div>
                      <div>
                        <b>{sapId}</b>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div>Date of Joining</div>
                      <div className="title-case">
                        <b>
                          {(userInfo.date_of_joining &&
                            moment(userInfo.date_of_joining).format(
                              "DD/MM/YYYY"
                            )) ||
                            "--"}
                        </b>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  {userDetail.customer_class === "na" || userDetail.customer_class === "hy" ? (
                    ""
                  ) : (
                    <Row>
                      <Col>
                        <div>Business Address</div>
                        <div className="title-case">
                          <b>{address}</b>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <br />
                  {userRole === Roles.SUB_DEALER_ROLE || userRole === Roles.BRANCH_ROLE || userDetail.customer_class === "na" || userDetail.customer_class === "hy" ? ("") :
                    (this.props.showRequestButton && (
                      <Fragment>
                        {this.props.isUpdateProfileRequestSend && (
                          <Row
                            className={cx(
                              "request-send-text-container",
                              "text-align-left",
                              "padding-bottom-10",
                              {
                                "req-button-container": this.state.width >= 1200,
                              },
                              { "padding-bottom-10": this.state.width < 1200 }
                            )}
                          >
                            <span>
                              <Ionicon
                                icon="ios-alert"
                                fontSize="24px"
                                color="#6e777f"
                              />
                            </span>
                            <span className="request-send-text">
                              Your request to change details has been received. We
                              will review shortly.
                            </span>
                          </Row>
                        )}
                        {/* {!this.props.isUpdateProfileRequestSend && (
                      <Row
                        className={cx(
                          "text-align-left",
                          "padding-bottom-10",
                          { "req-button-container": this.state.width >= 1200 },
                          { "padding-bottom-10": this.state.width < 1200 }
                        )}
                      >
                        <Col
                          className="request-send-text req-button"
                          onClick={this.openRequestDetailModal}
                        >
                          REQUEST TO EDIT
                        </Col>
                      </Row>
                    )} */}
                      </Fragment>
                    ))}
                </Fragment>
              )}
            {(userRole === Roles.SUB_DEALER_ROLE || userRole === Roles.BRANCH_ROLE) && (
              <Fragment>
                <Row>
                  <Col>
                    <div>Mobile Number</div>
                    <div className="title-case">
                      <b>{this.props.mobileNumber}</b>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(ProfileDetail);
