import {EventRegistrationInformationControls} from '../pages/EventRegistration/EventRegistrationControl';
import {SET_EVENT_REGISTERATION_INFO} from '../constants/types';

const initialState = {
    addEventRegistrationControls:EventRegistrationInformationControls
};
const EventRegistrationSystemReducer=(state = initialState,action)=> {
    switch(action.type){
        case SET_EVENT_REGISTERATION_INFO:
            return{
                ...state,
                addEventRegistrationControls:Object.assign({},action.payload)
            };
        default:
            return state;
    }
};

export default EventRegistrationSystemReducer;