import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import "./AddTyrePurchaseDetail.css";
import "../AddCustomer.css";
// services
import DatePicker from "react-datepicker";
import AddCustomerService from "../../../services/AddCustomerService";
import Validation from "../../../services/Validation";
import GoogleAnalticsService from '../../../services/GoogleAnalticsService';

class AddTyrePurchaseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddAlignment: false,
      remarks: null,
      quantity: null,
      selected_service: [],
      getBrand: [],
      controls: {
        brand_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        quantity: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        select_brand_radio: {
          value: "jk_tyre",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        set_service_reminder: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        tyre_purchase_due_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        }
      },
      is_disabel_brand_qty: false,
      is_add_service_touched: false,
      is_add_service_showErrorMsg: false,
      customer_services: [],
      add_customer: {},
      is_display_brand_dropdown: false,
      tyre_purchase_type: null,
      vehicleType: null,
      showTyrePurchaseReminderDetail: false,
    };
    this.childVehicleRegistrationNumber = React.createRef();
    /**Setting the data from the add_customer if present in local storage */
    if (localStorage.getItem('add_customer')) {
      let data = JSON.parse(localStorage.getItem('add_customer'));
      if (data && data.phone_number) {
        this.state.is_disabel_brand_qty = true;
        this.state.controls.quantity.value = data.qty ? data.qty : null;
        this.state.add_customer = data;
        this.state.is_display_brand_dropdown = false;
        this.state.controls.select_brand_radio.value = 'jk_tyre';
      }
    }
  }

  componentDidMount = () => {
    GoogleAnalticsService.event('Add Customer Visit', 'View Tyre Purchase Details View');
    this.getBrand();
    if (this.props.showTyrePurchaseReminderDetail) {
      this.setState({ showTyrePurchaseReminderDetail: this.props.showTyrePurchaseReminderDetail });
    } else {
      this.setState({ showTyrePurchaseReminderDetail: false });
    }
    if (localStorage.getItem('add_customer')) {
      let data = JSON.parse(localStorage.getItem('add_customer'));
      if (data && data.phone_number) {
        this.customInit();
      }
    };
  }

  customInit = async () => {
    /**Get the value of the vehicle from the vehicleDetailref and set the value accordingly */
    if (this !== undefined && this.props !== undefined && this.props.vehicleDetailref) {
      let tyre_purchase_type = this.props.vehicleDetailref.props.selectPurposeref.current.state.controls.tyre_purchase_type.value;
      let vehicle_type_id = this.props.vehicleDetailref.state.controls.custom_filter_vehicle_type_id.value;
      let vehicleType = (this.props.vehicleDetailref.state.vehicleTypeData).find((x) => x.vehicle_type_id === vehicle_type_id)
      let { controls, is_display_brand_dropdown } = this.state;
      if (tyre_purchase_type === 'other') {
        controls.select_brand_radio.value = 'other';
        controls.brand_id.value = null;
        is_display_brand_dropdown = true;
        controls.set_service_reminder.value = true;
      }
      this.setState({
        controls, tyre_purchase_type, vehicleType, is_display_brand_dropdown
      })
    } else {
      let { controls } = this.state;
      controls.select_brand_radio.value = 'jk_tyre';
      controls.set_service_reminder.value = true;
      this.setState({
        controls
      })
    }
    this.getServices();
  }

  /**Getting the list of the service */
  getServices = () => {
    this.props.isLoading(true);
    AddCustomerService.getService()
      .then((result) => {
        this.props.isLoading(false);
        this.setState({
          customer_services: result && result.data && result.data.data,
        }, () => {
          this.getDealerServices();
        });
      })
      .catch((err) => {
        this.props.isLoading(false);
        console.log("err", err);
      });
  };

  /**Getting the dealer services provided by the dealer */
  getDealerServices = () => {
    let service_id = [];
    if (this.state.customer_services.length <= 0) {
      return;
    }
    this.state.customer_services.forEach(element => {
      if (element.service_active_by_dealer) {
        service_id.push(element.service_id);
      }
    });

    let vehicle_type_id = this.props.vehicleDetailref.state.controls.custom_filter_vehicle_type_id &&
      this.props.vehicleDetailref.state.controls.custom_filter_vehicle_type_id.value ? this.props.vehicleDetailref.state.controls.custom_filter_vehicle_type_id.value : null;
    if (this.props.storage_add_customer_warranty_data && Object.keys(this.props.storage_add_customer_warranty_data).length > 0) {
      vehicle_type_id = this.props.storage_add_customer_warranty_data.vehicle_type_id;
    }
    let reqData = {
      "service_id": service_id,
      "vehicle_category": this.state.add_customer.vehicle_category ? this.state.add_customer.vehicle_category : 'personal',
      "vehicle_type_id": vehicle_type_id
    }
    this.props.isLoading(true);
    AddCustomerService.getDealerServices(reqData)
      .then((res) => {
        this.props.isLoading(false);
        let { selected_service } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          selected_service = res.data.data;
          selected_service.forEach(element => {
            element.remarks = '';
            element.upcoming_due_date = element.upcoming_due_date ? new Date(element.upcoming_due_date) : new Date();
            element.add_remark = false;
            element.is_add_service = true;
          });
          selected_service[0].add_remark = true;
          let other_findIndex = selected_service.findIndex(x => x.name && x.name.toLowerCase() === 'other');
          if (other_findIndex > -1) {
            let other = selected_service[other_findIndex];
            selected_service.splice(other_findIndex, 1);
            selected_service.push(other);
          }
        }
        this.setState({
          selected_service
        })
      })
      .catch((err) => {
        this.props.isLoading(false);
        console.log("err", err);
      });
  };

  /**Handles the Validation part for the form */
  handleValidation = (isSubmit = false) => {
    let { controls, is_add_service_touched, is_add_service_showErrorMsg } = this.state;
    let isFormValid = false;
    let {
      brand_id, quantity, set_service_reminder
    } = controls;



    if ((brand_id.touched === true || isSubmit)) {
      brand_id = Validation.notNullValidator(brand_id);
      brand_id.valid = !brand_id.nullValue;
      if (brand_id.valid === false) {
        brand_id.showErrorMsg = true;
      } else {
        brand_id.showErrorMsg = false;
      }
    }

    if ((quantity.touched === true || isSubmit)) {
      quantity = Validation.notNullValidator(quantity);
      quantity.valid = !quantity.nullValue;
      if (quantity.valid === false) {
        quantity.showErrorMsg = true;
      } else {
        quantity.showErrorMsg = false;
      }
    }
    is_add_service_showErrorMsg = false;
    if ((is_add_service_touched.touched === true || isSubmit) &&
      this.state.selected_service.length > 0 && set_service_reminder.value) {
      let findIndex = this.state.selected_service.findIndex(x => x.is_add_service);
      if (findIndex > -1) {
        is_add_service_showErrorMsg = false;
      } else {
        is_add_service_showErrorMsg = true;
      }
    }


    if (
      quantity.valid === true &&
      brand_id.valid === true &&
      is_add_service_showErrorMsg === false
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls, isFormValid,
      is_add_service_showErrorMsg
    });
    return isFormValid;
  }

  /**Handles the input change and set the value according to name attribute */
  handleInputChange = (e) => {
    let { controls, is_display_brand_dropdown } = this.state;

    if (e.target.name === 'quantity' || e.target.name === 'brand') {
      if (this.state.is_disabel_brand_qty) {
        return;
      }
    }
    if (e.target.name === 'quantity') {
      if (!isNaN(e.target.value)) {
        controls[e.target.name].value = e.target.value;
      }
    } else if (e.target.name === 'select_brand_radio') {
      if (this.state.is_disabel_brand_qty) {
        return;
      }
      if (e.target.name === 'select_brand_radio') {
        if (e.target.value === 'jk_tyre') {
          is_display_brand_dropdown = false;
          let filter = this.state.getBrand ? this.state.getBrand.filter(x => x.type.toLowerCase() === 'jk_tyre') : [];
          if (filter.length > 0) {
            controls.brand_id.value = filter[0].id;
          }
        } else {
          controls.brand_id.value = null;
          is_display_brand_dropdown = true;
        }
      }
      controls[e.target.name].value = e.target.value;
    } else {
      controls[e.target.name].value = e.target.value;
    }
    controls[e.target.name].touched = true;
    this.setState({ controls, is_display_brand_dropdown });
    if (this.props.setTyrePurchaseDetail) {
      this.props.setTyrePurchaseDetail(controls);
    }
    this.handleValidation();
  };

  onChange = (e, i) => {
    let { controls, selected_service } = this.state;
    selected_service[i].remarks = e.target.value;
    this.setState({ controls, selected_service });
  };

  /**Handles the changes for the date */
  handleChangeDatePicker = (e, i) => {
    let { controls, selected_service } = this.state;
    selected_service[i].upcoming_due_date = e.target.value;
    this.setState({ controls, selected_service });
  };

  handleChangeTyrePurchaseDatePicker = (e, i) => {
    let { controls } = this.state;
    controls.tyre_purchase_due_date.value = e.target.value;
    controls.tyre_purchase_due_date.touched = true;
    this.setState({ controls });
    let result = this.handleTyrePurchaseValidation(false, this.state.showTyrePurchaseReminderDetail);
    controls.tyre_purchase_due_date.value = e.target.value;
    controls.tyre_purchase_due_date.touched = true;
    this.setState({ controls });
    if (result) {
      this.props.setTyrePurchaseDueDate(e.target.value);
    }
  };

  handleTyrePurchaseValidation = (isSubmit = false, showTyrePurchaseReminderDetail, date) => {
    let { controls } = this.state;
    let isFormValid = false;
    let {
      tyre_purchase_due_date
    } = controls;

    if (!tyre_purchase_due_date.value && date) {
      tyre_purchase_due_date.value = date;
      tyre_purchase_due_date.touched = true;
    }
    if (showTyrePurchaseReminderDetail && ((tyre_purchase_due_date.touched === true) || isSubmit)) {
      tyre_purchase_due_date = Validation.notNullValidator(tyre_purchase_due_date);
      tyre_purchase_due_date.valid = !tyre_purchase_due_date.nullValue;
      if (tyre_purchase_due_date.valid === false) {
        tyre_purchase_due_date.showErrorMsg = true;
        tyre_purchase_due_date.touched = true;
      } else {
        tyre_purchase_due_date.showErrorMsg = false;
      }
    } else {
      tyre_purchase_due_date.valid = true;
    }

    if (
      tyre_purchase_due_date.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    if (!showTyrePurchaseReminderDetail) {
      isFormValid = true;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  }

  AddAlignment = (i) => {
    let { selected_service } = this.state;
    selected_service[i].add_remark = true;
    this.setState({
      selected_service
    });
  };

  isAddService = (i) => {
    let { selected_service, is_add_service_touched } = this.state;
    selected_service[i].is_add_service = !selected_service[i].is_add_service;
    is_add_service_touched = true;
    this.setState({
      selected_service,
      is_add_service_touched
    });
    this.handleValidation();
  }

  getBrand = () => {
    AddCustomerService.getBrands()
      .then((response) => {
        let { controls } = this.state;
        //if (this.state.is_disabel_brand_qty) {
        if (response.data.data && response.data.data.brand && response.data.data.brand.length > 0) {
          let filter = response.data.data.brand.filter(x => x.type.toLowerCase() === 'jk_tyre');
          if (filter.length > 0) {
            controls.brand_id.value = filter[0].id;
          }
        }
        //  }
        this.setState({
          getBrand: response && response.data.data && response.data.data.brand,
          controls
        });
      })
      .catch((err) => console.log(err));
  };

  setServiceReminder = () => {
    let { controls, is_add_service_touched } = this.state;
    controls.set_service_reminder.value = !controls.set_service_reminder.value;
    if (controls.set_service_reminder.value && this.state.selected_service.length <= 0) {
      //  this.getServices();
    }
    if (!controls.set_service_reminder.value) {
      is_add_service_touched = false;
    }
    this.setState({
      controls,
      is_add_service_touched
    });
    this.handleValidation();
  }

  render() {
    const { controls, selected_service, is_display_brand_dropdown, tyre_purchase_type, vehicleType, showTyrePurchaseReminderDetail } = this.state;
    return (
      <div className="add-customer-wrapper">
        <Row className="mr-0 p-0" style={{ marginLeft: '-5px' }}>
          {/* <Col xl="6" md="12" xs="12">
            <FormGroup className="d-flex-column">
              <Label for="common">
                Select Brand &nbsp;
                <span className="required-field">*</span>
              </Label>
              <select
                className={this.state.is_disabel_brand_qty ?
                  'diable-mo-number form-control select-height-70rem' : 'form-control select-height-70rem'}
                name="brand_id"
                style={{ color: "#495057" }}
                value={controls.brand_id.value}
                disabled={this.state.is_disabel_brand_qty}
                onChange={(e) => {
                  this.handleInputChange(e);
                }}
              >
                <option value="" key={-1}>Select brand</option>
                {this.state.getBrand.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {controls.brand_id.showErrorMsg && <div className="error show_error">
                *Please select brand.
                      </div>}
            </FormGroup>
          </Col> */}
          <Col xl={is_display_brand_dropdown ? '8' : '6'} md="12" xs="12" className="pr-0">
            <Row>
              <Col xl="12" md="12" xs="12">
                <Label for="common">
                  Select Brand &nbsp;
                  <span className="required-field">*</span>
                </Label>
              </Col>
              <Col xl={is_display_brand_dropdown ? '4' : '6'} xs="12" ms="12" className={tyre_purchase_type === 'other' && ['car', '2 wheeler', 'scv', 'lcv'].includes(vehicleType.type) === true ? 'opacity-0-5 mt-1' : "mt-1"}>
                <div className="d-flex-row">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="select_brand_radio"
                      value="jk_tyre"
                      checked={
                        controls.select_brand_radio.value &&
                        controls.select_brand_radio.value.toLowerCase() ===
                        "jk_tyre"
                      }
                      disabled={tyre_purchase_type === 'other' && ['car', '2 wheeler', 'scv', 'lcv'].includes(vehicleType.type) === true ? true : false}
                      onChange={(e) =>
                        this.handleInputChange({
                          target: {
                            name: "select_brand_radio",
                            value: "jk_tyre",
                          },
                        })
                      }
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span style={{ marginLeft: "10px" }}>JK Tyre</span>
                </div>
              </Col>
              <Col xl={is_display_brand_dropdown ? '3' : '5'} xs="12" ms="12" className={this.state.is_disabel_brand_qty ? 'opacity-0-5 p-0 mt-1' : "p-0 mt-1"}>
                <div className="d-flex-row">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="select_brand_radio"
                      value="other"
                      checked={
                        controls.select_brand_radio.value &&
                        controls.select_brand_radio.value.toLowerCase() === "other"
                      }
                      onChange={(e) =>
                        this.handleInputChange({
                          target: {
                            name: "select_brand_radio",
                            value: "other",
                          },
                        })
                      }
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span style={{ marginLeft: "10px" }}>Other</span>
                </div>
              </Col>
              {
                (is_display_brand_dropdown) &&
                <Col xl="4" md="12" xs="12" className="p-0">
                  <FormGroup className="d-flex-column">
                    <select
                      className={this.state.is_disabel_brand_qty ?
                        'diable-mo-number form-control select-height-70rem' : 'form-control select-height-70rem'}
                      name="brand_id"
                      style={{ color: "#495057" }}
                      value={controls.brand_id.value}
                      disabled={this.state.is_disabel_brand_qty}
                      onChange={(e) => {
                        this.handleInputChange(e);
                      }}
                    >
                      <option value="" key={-1}>Select</option>
                      {this.state.getBrand.map((item) => (
                        item.type && item.type !== 'jk_tyre' &&
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {controls.brand_id.showErrorMsg && <div className="error show_error">
                      *Please select brand.
                    </div>}
                  </FormGroup>
                </Col>
              }
            </Row>
          </Col>
          {
            showTyrePurchaseReminderDetail &&
            <Col xl={is_display_brand_dropdown ? '4' : '6'} md="12" xs="12">
              <FormGroup>
                <Label for="password">Next Replacement Date<span className="required-field">*</span></Label>
                <Label className="date-picker-left-60 width-100">
                  <Label onClick={(e) => this[`calendar${0}`].state.open && e.preventDefault()}
                    className="display-botttom-datepicker">
                    <InputGroup>
                      <DatePicker
                        placeholderText="select"
                        className={"form-control"}
                        name="tyre_purchase_due_date"
                        selected={controls.tyre_purchase_due_date.value ? controls.tyre_purchase_due_date.value : null}
                        onChange={(e) =>
                          this.handleChangeTyrePurchaseDatePicker({
                            target: { name: "tyre_purchase_due_date", value: e }
                          })
                        }
                        showMonthDropdown
                        ref={(r) => (this[`calendar${0}`] = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        minDate={new Date()}
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                        autoComplete="off"
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                </Label>
                {controls.tyre_purchase_due_date.showErrorMsg && <div className="error show_error">*Please enter valid date.</div>}
              </FormGroup>
            </Col>
          }
          {showTyrePurchaseReminderDetail === false &&
            <Col xl={is_display_brand_dropdown ? '4' : '6'} md="12" xs="12">
              <FormGroup className="d-flex-column">
                <Label for="common">
                  Quantity &nbsp;
                  <span className="required-field">*</span>
                </Label>
                <Input
                  type="text"
                  autoComplete="nofill"
                  className={this.state.is_disabel_brand_qty ? 'diable-mo-number form-control' : 'form-control'}
                  disabled={this.state.is_disabel_brand_qty}
                  name="quantity"
                  id="quantity"
                  value={controls.quantity.value}
                  onChange={(e) => {
                    this.handleInputChange(e);
                  }}
                  placeholder="Type here"
                />
                {controls.quantity.showErrorMsg && <div className="error show_error">
                  *Please enter quantity.
                </div>}
              </FormGroup>
            </Col>
          }

          {tyre_purchase_type === 'other' && ['car', '2 wheeler', 'scv', 'lcv'].includes(vehicleType.type) === true &&
            <Col xl="12" md="12" xs="12" className="service-note" style={{ marginBottom: "20px", marginLeft: '0px', height: '30px' }}>
              <span>
                Note : If you want to select JK Tyre brand for PCR, LCV, SCV & 2 Wheeler's tyre, please select 'JK PCR, LCV, SCV & 2 Wheeler's Tyre' in the Tyre purchase option.
                {/* If you want to select JK Tyre brand for Car & 2 Wheeler, please select 'JK PCR & 2 Wheeler's Tyre' in the Tyre purchase option. */}
              </span>
            </Col>
          }
          {
            (selected_service.length > 0) &&
            <Col xl="12" md="12" xs="12">
              <div className="d-flex">
                <label className="custom-checkboax1--container">
                  <input
                    type="checkbox"
                    name="service"
                    checked={controls.set_service_reminder.value}
                    onChange={(e) =>
                      this.setServiceReminder()
                    }
                  />
                  <span className="checkmark1" />
                </label>
                <span style={{ marginLeft: "10px" }} className="Set-Service-Reminder">
                  Set Service Reminder
                </span>
              </div>
            </Col>
          }
          {
            (controls.set_service_reminder.value && selected_service.length > 0) &&
            <>
              <Col xl="12" md="12" xs="12" className="mt-4">
                <div className="add-tyre-purchase-Services-List">SERVICES LIST</div>
              </Col>
              <Col xl="12" md="12" xs="12" className="mb-2">
                <div className="service-pref-note" style={{ marginTop: '10px', marginBottom: '0px' }}>
                  Customer will be reminded for this service, prior to due date set.
                </div>
                {this.state.is_add_service_showErrorMsg && <div className="error show_error">
                  *Please select at least one service.
                </div>}
              </Col>
              {selected_service.map((item, j) => (
                item.is_set_service_due_date &&
                <>
                  <Col xl="12" md="10" xs="10">
                    <Row className="veh-det-view p-10  ml-0 mr-0">
                      <Col xl="1" md="2" xs="2" style={{ paddingLeft: '5px' }} className="mt-2">
                        <label className="custom-checkboax1--container">
                          <input
                            type="checkbox"
                            name="service"
                            checked={item.is_add_service}
                            onChange={(e) =>
                              this.isAddService(j)
                            }
                          />
                          <span className="checkmark1" />
                        </label>
                      </Col>
                      <Col xl="11" md="10" xs="10" className="pl-0" style={{ marginLeft: '-20px', marginTop: '5px' }}>
                        <Row className="ml-0 mr-0 p-0">
                          <Col xl={item.is_set_service_due_date ? '6' : '12'} md="12" xs="12">
                            <FormGroup>
                              <Label for="common">
                                Service &nbsp;
                                <span className="required-field">*</span>
                              </Label>
                              <Input
                                type="text"
                                disabled={true}
                                autoComplete="nofill"
                                name="service"
                                id="service"
                                value={item.name}
                                placeholder="Type here"
                              />
                            </FormGroup>
                          </Col>
                          {
                            item.is_set_service_due_date &&
                            <Col xl="6" md="12" xs="12">
                              <FormGroup>
                                <Label for="password">Next Due Date</Label>
                                <Label className="width-100">
                                  <Label
                                    onClick={(e) =>
                                      this[`calendar${j}`].state.open && e.preventDefault()
                                    }
                                    className="display-botttom-datepicker"
                                  >
                                    <InputGroup>
                                      <DatePicker
                                        placeholderText="select"
                                        className={"form-control"}
                                        name="upcoming_due_date"
                                        selected={item.upcoming_due_date ? item.upcoming_due_date : null}
                                        onChange={(e) =>
                                          this.handleChangeDatePicker(
                                            { target: { name: "upcoming_due_date", value: e } },
                                            j
                                          )
                                        }
                                        showMonthDropdown
                                        ref={(r) => (this[`calendar${j}`] = r)}
                                        shouldCloseOnSelect={true}
                                        showYearDropdown
                                        minDate={new Date()}
                                        dropdownMode="select"
                                        dateFormat="dd/MM/yyyy"
                                        autoComplete="off"
                                      />
                                      <InputGroupAddon addonType="append">
                                        <div className="input-group-text">
                                          <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                                        </div>
                                      </InputGroupAddon>
                                    </InputGroup>
                                  </Label>
                                </Label>
                              </FormGroup>
                            </Col>
                          }
                          {!item.add_remark ? (
                            <Col xl="12" md="12" xs="12">
                              <div className="add-alignment" onClick={() => this.AddAlignment(j)}
                                style={{ marginTop: '-1rem' }}>
                                Add {item.name} Remark
                              </div>
                            </Col>
                          ) : (
                            <Col xl="12" md="12" xs="12" style={{ marginTop: '-1rem' }}>
                              <FormGroup>
                                <Label for="common">
                                  Remark &nbsp;
                                </Label>
                                <Input
                                  type="text"
                                  autoComplete="nofill"
                                  name="remarks"
                                  id="remarks"
                                  value={item.remarks}
                                  onChange={(e) => this.onChange(e, j)}
                                  placeholder="Type here"
                                />
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </>
              ))}
            </>
          }
        </Row>
      </div >
    );
  }
}

export default AddTyrePurchaseDetail;
