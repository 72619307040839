import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import "../AddCustomer.css";
import moment from "moment";
// services
import DatePicker from "react-datepicker";
import AddCustomerService from "../../../services/AddCustomerService";
import AddTyrePurchaseDetail from "../AddTyrePurchaseDetail/AddTyrePurchaseDetail";

class SetReminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddAlignment: false,
      remarks: null,
      selectedServices: [],
      selected_service: [],
      controls: {
        upcoming_due_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      is_puncture_service: false,
      showTyrePurchaseReminderDetail: false,
      tyre_purchase_due_date: null,
      tyrePurchaseDetail: {}
    };
    this.childVehicleRegistrationNumber = React.createRef();
  }

  init = async () => {
    let { selected_service } = this.state;
    if (this.props.select_purpose_detail.current.state.selectedPurpose.type === 'service') {
      if (this.props.vehicleDetailref.props.customer_vehicle && this.props.vehicleDetailref.state.controls.vehicle_id.value) {
        var selectedVehicleIndex = this.props.vehicleDetailref.props.customer_vehicle.findIndex(
          (x) => x.vehicle_id === this.props.vehicleDetailref.state.controls.vehicle_id.value
        )
        if (selectedVehicleIndex > -1) {
          var selectedVehicle = this.props.vehicleDetailref.props.customer_vehicle[selectedVehicleIndex];
          if (selectedVehicle && selectedVehicle.tyre_purchase_due_date && moment(selectedVehicle.tyre_purchase_due_date).valueOf() > moment().valueOf()) {
          } else {
            this.setState({
              showTyrePurchaseReminderDetail: true
            });
          }
        } else {
          this.setState({
            showTyrePurchaseReminderDetail: true,
          });
        }
      } else {
        this.setState({
          showTyrePurchaseReminderDetail: true,
        });
      }
    }
    await this.getDealerServices();
    // selected_service = [];
    // if (this.props.select_purpose_detail.current) {
    //   let customer_services = this.props.select_purpose_detail.current.state
    //     .customer_services;
    //   if (
    //     customer_services.length > 0 &&
    //     this.props.select_purpose_detail.current.state.selectedServices.length >
    //     0
    //   ) {
    //     this.props.select_purpose_detail.current.state.selectedServices.forEach(
    //       (element, i) => {
    //         if (element) {
    //           let findindex = customer_services.findIndex(
    //             (x) => x.service_id === element
    //           );
    //           if (findindex > -1) {
    //             selected_service.push(customer_services[findindex]);
    //             selected_service[i].upcoming_due_date = null;
    //             selected_service[i].remarks = "";
    //           }
    //         }
    //       }
    //     );
    //   }
    // }

    this.setState({
      selected_service,
      customer_vehicle_id: this.props.customer_vehicle_id
    });
  }

  componentDidMount = () => {

  };

  handleInputChange = (e) => {
    const { controls } = this.state;
    controls[e.target.name].value = e.target.value;
    this.setState({ controls });
  };

  onChange = (e, i) => {
    let { controls, selected_service } = this.state;
    selected_service[i].remarks = e.target.value;
    this.setState({ controls, selected_service });
  };

  handleChangeDatePicker = (e, i) => {
    let { controls, selected_service } = this.state;
    selected_service[i].upcoming_due_date = e.target.value;
    this.setState({ controls, selected_service });
  };

  setTyrePurchaseDueDate = (value) => {
    let { tyre_purchase_due_date } = this.state;
    tyre_purchase_due_date = value;
    this.setState({ tyre_purchase_due_date });
  };

  setTyrePurchaseDetail = controls => {
    let { tyrePurchaseDetail } = this.state;
    tyrePurchaseDetail = controls;
    this.setState({ tyrePurchaseDetail });
  }

  AddAlignment = (i) => {
    let { selected_service } = this.state;
    selected_service[i].add_remark = true;
    this.setState({
      selected_service
    });
  };

  getDealerServices = () => {
    let reqData = {
      "service_id": this.props.select_purpose_detail.current.state.selectedServices,
      "vehicle_category": this.props.vehicleDetailref.state.controls.vehicleCategory.value,
      "vehicle_type_id": this.props.vehicleDetailref.state.controls.custom_filter_vehicle_type_id.value
    }
    this.props.isLoading(true);
    AddCustomerService.getDealerServices(reqData)
      .then((res) => {
        this.props.isLoading(false);
        let { selected_service } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          selected_service = res.data.data;
          selected_service.filter(x => x.remarks = '');
          selected_service.filter(x => x.upcoming_due_date = x.upcoming_due_date ? new Date(x.upcoming_due_date) : new Date());
          selected_service.filter(x => x.add_remark = false);
          selected_service[0].add_remark = true;
          let other_findIndex = selected_service.findIndex(x => x.name && x.name.toLowerCase() === 'other');
          if (other_findIndex > -1) {
            let other = selected_service[other_findIndex];
            selected_service.splice(other_findIndex, 1);
            selected_service.push(other);
          }
        }
        this.setState({
          selected_service
        }, () => {
          let puncture_index = selected_service.findIndex(x => x.name && x.name.toLowerCase() === 'puncture');
          if (puncture_index > -1 && selected_service.length === 1) {
            this.props.renderNextPage();
          }
        })
      })
      .catch((err) => {
        this.props.isLoading(false);
        console.log("err", err);
      });
  };

  render() {
    const { selected_service, showTyrePurchaseReminderDetail } = this.state;
    return (
      <>
        {showTyrePurchaseReminderDetail &&
          <Col xl="12" style={{ paddingBottom: '5px' }}>
            <div className="upcoming-tyre-replacement">UPCOMING TYRE REPLACEMENT DETAILS</div>
            <div className="upcoming-tyre-replacement-note">
              Based of Tyre's wear and tear, Please enter the tentative upcoming replacement details for the existing tyres of the vehicle.
              Accordingly customer will be reminded for the tyre replacement
            </div>
          </Col>
        }
        {showTyrePurchaseReminderDetail &&
          <AddTyrePurchaseDetail
            isLoading={this.isLoading}
            storage_add_customer_warranty_data={this.state.storage_add_customer_warranty_data}
            vehicleDetailref={this.props.vehicleDetailref.current}
            showTyrePurchaseReminderDetail={showTyrePurchaseReminderDetail}
            setTyrePurchaseDueDate={this.setTyrePurchaseDueDate}
            setTyrePurchaseDetail={this.setTyrePurchaseDetail}
          />
        }
        <Col xl="12">
          <div className="service-pref-note">
            Customer will be reminded for this service, prior to due date set.
          </div>
          {/* <div className="half-yellow"></div> */}
        </Col>
        {selected_service.map((item, j) => (
          <Col xl="12">
            <Row className="veh-det-view ml-0 mr-0 p-10">
              <Col xl={item.is_set_service_due_date ? '6' : '12'} md="12" xs="12">
                <FormGroup className="margin-bottom-0-4rem">
                  <Label for="common">
                    Service &nbsp;
                    <span className="required-field">*</span>
                  </Label>
                  <Input
                    type="text"
                    disabled={true}
                    autoComplete="nofill"
                    name="service"
                    id="service"
                    value={item.name}
                    placeholder="Type here"
                  />
                </FormGroup>
              </Col>
              {
                item.is_set_service_due_date &&
                <Col xl="6" md="12" xs="12">
                  <FormGroup className="margin-bottom-0-4rem">
                    <Label for="password">Next Due Date</Label>
                    <Label className="width-100">
                      <Label
                        onClick={(e) =>
                          this[`calendar${j}`].state.open && e.preventDefault()
                        }
                        className="display-botttom-datepicker"
                      >
                        <InputGroup>
                          <DatePicker
                            placeholderText="select"
                            className={"form-control"}
                            name="upcoming_due_date"
                            selected={item.upcoming_due_date}
                            onChange={(e) =>
                              this.handleChangeDatePicker(
                                { target: { name: "upcoming_due_date", value: e } },
                                j
                              )
                            }
                            showMonthDropdown
                            ref={(r) => (this[`calendar${j}`] = r)}
                            shouldCloseOnSelect={true}
                            showYearDropdown
                            minDate={new Date()}
                            dropdownMode="select"
                            autoComplete="off"
                            dateFormat="dd/MM/yyyy"
                          />
                          <InputGroupAddon addonType="append">
                            <div className="input-group-text">
                              <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </Label>
                  </FormGroup>
                </Col>
              }
              {!item.add_remark ? (
                <Col xl="12" md="12" xs="12" style={{ marginTop: item.is_set_service_due_date ? '0px' : '15px' }}>
                  <div className="add-alignment" onClick={() => this.AddAlignment(j)}>
                    Add {item.name} Remark
                  </div>
                </Col>
              ) : (
                <Col xl="12" md="12" xs="12">
                  <FormGroup>
                    <Label for="common">
                      Remark &nbsp;
                    </Label>
                    <Input
                      type="text"
                      autoComplete="nofill"
                      name="remarks"
                      id="remarks"
                      value={item.remarks}
                      onChange={(e) => this.onChange(e, j)}
                      placeholder="Type here"
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </Col>
        ))}
      </>
    );
  }
}

export default SetReminder;
