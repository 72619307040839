import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';

class UpdateDetailProileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      details: {}
    }
    this.toggle = this.toggle.bind(this);
  }
  toggle(data) {
    let { details } = this.state;
    if (data && Object.keys(data).length > 0) {
      details = data;
    } else {
      details = {};
      // this.resetControls();
    }
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
    }));
  }
  submit = (flag) => {
    this.props.submit(flag);
    this.toggle({});
  }
  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <>
        <Modal
          centered={true}
          isOpen={this.state.modal} toggle={this.toggle} external={externalCloseBtn} className="modal-md modal-lg custom-confirm-modal modal-sm"
          style={{ maxWidth: '420px' }} backdrop={this.backdrop ? 'static' : 'nostatic'}>

          <ModalBody
            className={'d-flex-column'}>
            <Row className="m-0 text-align-center justify-content-center">
              <Col xl="12" className="d-flex-column">
                <img src="/images/proifile_complete.png" alt='' className="ml-auto mr-auto mt-2" style={{ height: '50px', width: '46px' }} />
                <span className="mt-3 title-confirm title-confirm font-weight-bold">Update Details In Profile</span>
                <span className="mt-3 title-confirm title-confirm">Do you want to update address, mobile, email in profile as well?</span>
              </Col>
            </Row>
            <Row className="m-0">
              <Col className="text-align-right mt-4">
                <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.submit(false)} style={{ width: '86px' }}>
                  NO</Button> &nbsp;
                <Button
                  //  disabled={!isValidForm} 
                  type="button"
                  className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
                  color="warning"
                  style={{ cursor: 'pointer', width: '86px' }}
                  onClick={() => this.submit(true)}
                >
                  Yes
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      </>
    );
  }
}

export default UpdateDetailProileModal;