import React, { Component } from 'react';
import {
  Button,
  Card, CardBody, Label
} from 'reactstrap';
import Moment from 'react-moment';
import './CipAttendeeList.css';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import swal from 'sweetalert';
import { downlodFile } from '../../utils/utils';

class CipAttendeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cipAttendees: [],
      customerPage: 1,
      totalCustomerPages: 0,
      totalCustomer: 0,
      cip_attendee_state: this.props.cip_attendee_state ? this.props.cip_attendee_state : {}
    }
    if (this.props.cip_attendee_state && Object.keys(this.props.cip_attendee_state).length === 0) {
      this.backToCipSummary()
    }
  }

  componentDidMount() {
    this.getCipDetail();
  }


  backToCipSummary = () => {
    window.scroll(0, 0);
    if (this.props.goToCipSummary) {
      this.props.goToCipSummary()
    }
    // this.props.history.push(
    //   `cip-summary`
    // );
  }

  goToAddParticapant = (row = {}, select_vehicle_tab = false) => {
    this.props.goToAddParticapant(this.state.cip_attendee_state, null, 'CipAttendeeList', row.uuid ? row : null, select_vehicle_tab);
  }

  getCipDetail = (download = false) => {
    let reqData = { cip_id: this.state.cip_attendee_state.id, page: this.state.customerPage, pagesize: 200 }
    reqData.download_report = download;
    this.setState({
      isLoading: true
    })
    CustomerIntractionServie.getCipSummary(reqData)
      .then(data => {
        let { cipAttendees } = this.state;
        if (data.data.data.file) {
          downlodFile(data.data.data.file);
          this.setState({
            isLoading: false,
          })
          return
        }
        cipAttendees = data.data.data.data;
        let totalCustomer = data.data.data.total;
        const totalCustomerPages = Math.ceil(totalCustomer / 10);
        this.setState({
          cipAttendees,
          isLoading: false,
          totalCustomerPages,
          totalCustomer
        })
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  openCoAttendeeList = (row) => {
    this.props.openCoAttendeeList(row);
  }

  deleteCipDetail = (data) => {
    swal({
      title: "",
      text: "Are you sure you want to delete this participant ?",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.confirmDeleteCipDetail(data);
      }
    });

  }

  confirmDeleteCipDetail = (data) => {
    let reqData = {
      uuid: data.uuid
    }
    this.setState({ isLoading: true });
    CustomerIntractionServie.deleteCIPDetail(reqData)
      .then(res => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.getCipDetail();
        this.setState({ isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="d-flex-column">
        {this.state.isLoading && <CustomSpinner />}
        <div className="d-flex-row mb-4 mt-2">
          <span onClick={() => this.backToCipSummary()} className="Merchandise-catalogu-link cursor-pointer" style={{ fontSize: '16px' }}>CIP Summary</span>&nbsp;&nbsp;
          <img src="images/right.svg" alt='' />&nbsp;&nbsp;
          <span className="cart-lbl" style={{ fontSize: '24px', width: 'auto' }}>No of Participant</span>
        </div>
        <Card className="mt-2">
          <CardBody className="d-flex-column">
            <div className="d-flex-row justify-content-between">
              <div className="d-flex-row mt-auto mb-auto">
                {
                  this.state.cip_attendee_state.cip_date &&
                  <div>
                    <Label className="font-weight-bold" for="owner_name">CIP Date :</Label>
                    &nbsp;
                    <span> <Moment format="DD MMM YYYY">
                      {this.state.cip_attendee_state.cip_date}
                    </Moment></span>
                  </div>
                }
                {
                  this.state.cip_attendee_state.cip_venue && <div className="mt-auto mb-auto">&nbsp;&nbsp;
                    <Label className="font-weight-bold" for="owner_name">CIP Venue :</Label>
                    &nbsp;
                    <span>{this.state.cip_attendee_state.cip_venue}</span>
                  </div>
                }

              </div>

              <div className="d-flex">
                {
                  (this.state.cipAttendees || []).length > 0 &&
                  <div className="d-flex mt-auto mb-auto">
                    <img src="images/download.svg" alt='' className="download-report-icon" onClick={() => this.getCipDetail(true)} />
                    <span className="download-report cursor-pointer mt-auto mb-auto m-2" onClick={() => this.getCipDetail(true)}>{' '} Download Report</span>
                  </div>
                }&nbsp;&nbsp;
                {
                  (this.state.cip_attendee_state.status !== "Canceled" && this.state.cip_attendee_state.status !== "Ended") &&
                  <Button
                    style={{ width: '185px' }}
                    className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                    color="warning" onClick={() => { this.goToAddParticapant(); }}>
                    Add Participant
                  </Button>
                }
              </div>
            </div>
            <table
              className="mb-0 table-responsive mt-3 tbl-f6f6f6"
              bordered
            >
              <thead>
                <tr style={{ backgroundColor: "#f6f6f6" }}>
                  <th>Mobile No.</th>
                  <th className="text-align-center line-height-18px">Business<br /> Name</th>
                  <th className="text-align-center line-height-18px">Participant<br></br> Name</th>
                  <th>Designation</th>
                  <th className="text-align-center line-height-18px">Whatsapp<br /> No</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>State</th>
                  <th>City</th>
                  <th>Pincode</th>
                  <th className="text-align-center line-height-18px">No.of<br /> Co-attendee</th>
                  <th className="text-align-center line-height-18px">Vehicle/Counter<br />Potential Detail</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  (this.state.cipAttendees || []).map((item, i) => (
                    <tr>
                      <td>{item.mobile_number}</td>
                      <td className="text-align-center text-capitalize">{item.company_name}</td>
                      <td className="text-align-center text-capitalize">{item.attendee_name ? item.attendee_name : '--'}</td>
                      <td className="text-capitalize">{item.designation ? item.designation : '--'}</td>
                      <td className="text-align-center">{item.whatsapp_number ? item.whatsapp_number : '--'}</td>
                      <td>{item.email ? item.email : '--'}</td>
                      <td className="text-capitalize">{item.address ? item.address : '--'}</td>
                      <td>{item.state ? item.state : '--'}</td>
                      <td>{item.city ? item.city : '--'}</td>
                      <td>{item.pin_code ? item.pin_code : '--'}</td>
                      <td className="text-align-center">
                        <span className="link-blue" onClick={() => this.openCoAttendeeList(item)}>{item.attendee ? item.attendee : '0'}</span>
                      </td>
                      <td className="text-align-center">
                        <span className='link-blue' onClick={() => this.goToAddParticapant(item, true)} >View</span>
                      </td>
                      <td>
                        {
                          (this.state.cip_attendee_state.status !== "Canceled" && this.state.cip_attendee_state.status !== "Ended") ?
                            <div className="d-flex">
                              <img src="/images/edit.svg" alt='' style={{ height: '16px', width: '16px' }} onClick={() => this.goToAddParticapant(item)} />&nbsp;&nbsp;
                              <img src="/images/delete.svg" alt='' className="cursor-pointer mt-auto mb-auto" style={{ height: '14px' }}
                                onClick={() => this.deleteCipDetail(item)} />&nbsp;&nbsp;
                            </div> : '--'
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {
              (this.state.cipAttendees || []).length <= 0 &&
              <div className="no-data-without-border mt-3">
                <span className="m-auto">
                  There are no CIP Attendees yet. Click on "Add Participant" button
                </span>
              </div>

            }
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default CipAttendeeList;