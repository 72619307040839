import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import ProposalSerivice from "../../services/ProposalSerivice";
// import Validation from "../../services/Validation";
// import { messages } from "../../constants/constant.message";
import DatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import { Roles } from "../../constants/constant.role";
class MachineryDetails extends Component {
  machineryDetailsExistingIndexes = {
    WheelAligner: 0,
    TyreChanger: 1,
    WheelBalancer: 2,
    NitrogenInflator: 3,
    Compressor: 4,
  };
  machineryDetailsRequiredIndexes = {
    WheelAligner: 0,
    TyreChanger: 1,
    WheelBalancer: 2,
    NitrogenInflator: 3,
    Compressor: 4,
  };
  constructor(props) {
    super(props);
    this.state = {
      machineryDetailsExisting: props.machineryDetailsExisting,
      machineryDetailsRequired: props.machineryDetailsRequired,
      willingInvest: props.willingInvest,
      annexures: props.annexures,
      userDetail: Storage.getUserDetail(),
      isEdit: false,
      isDisplayEditBtn: false,
      isEnableEdit: true,
    };
    this.state.machineryDetailsExisting.forEach((element, i) => {
      if (element.type === "Wheel Aligner") {
        this.machineryDetailsExistingIndexes.WheelAligner = i;
      } else if (element.type === "Tyre Changer") {
        this.machineryDetailsExistingIndexes.TyreChanger = i;
      } else if (element.type === "Wheel Balancer") {
        this.machineryDetailsExistingIndexes.WheelBalancer = i;
      } else if (element.type === "Nitrogen Inflator") {
        this.machineryDetailsExistingIndexes.NitrogenInflator = i;
      } else if (element.type === "Compressor") {
        this.machineryDetailsExistingIndexes.Compressor = i;
      }
    });

    this.state.machineryDetailsRequired.forEach((element, i) => {
      if (element.type === "Wheel Aligner") {
        this.machineryDetailsRequiredIndexes.WheelAligner = i;
      } else if (element.type === "Tyre Changer") {
        this.machineryDetailsRequiredIndexes.TyreChanger = i;
      } else if (element.type === "Wheel Balancer") {
        this.machineryDetailsRequiredIndexes.WheelBalancer = i;
      } else if (element.type === "Nitrogen Inflator") {
        this.machineryDetailsRequiredIndexes.NitrogenInflator = i;
      } else if (element.type === "Compressor") {
        this.machineryDetailsRequiredIndexes.Compressor = i;
      }
    });

    if (
      this.state.userDetail &&
      this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE &&
      window.location.hash !== "#/proposal-details"
    ) {
      this.state.isDisplayEditBtn = true;
      this.state.isEnableEdit = false;
    }
  }

  handleValidation = (firstTime = true, isSubmit = false) => {
    return true;
    let { controls, isFormValid } = this.state.annexures;
    let {
      // lease_aggrement, 
      site_picture } = controls;

    // if (lease_aggrement.touched === true || isSubmit) {
    //   lease_aggrement = Validation.notNullValidator(lease_aggrement);
    //   lease_aggrement.valid = !lease_aggrement.nullValue;
    //   if (lease_aggrement.valid === false) {
    //     lease_aggrement.showErrorMsg = true;
    //   } else {
    //     lease_aggrement.showErrorMsg = false;
    //   }
    // }

    // if (site_picture.touched === true || isSubmit) {
    //   site_picture = Validation.notNullValidator(site_picture);
    //   site_picture.valid = !site_picture.nullValue;
    //   if (site_picture.valid === false) {
    //     site_picture.showErrorMsg = true;
    //   } else {
    //     site_picture.showErrorMsg = false;
    //   }
    // }

    if (site_picture.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    //this.setState({ annexures: { controls, isFormValid } });
    this.setState((prevState) => {
      return { ...prevState, annexures: { controls, isFormValid } };
    });
    return isFormValid;
  };

  handleInputChangeExisting = (e, index) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { machineryDetailsExisting } = this.state;
    machineryDetailsExisting[index][controlName].value = controlValue;
    // controls[controlName].touched = true;
    this.setState({ machineryDetailsExisting });
  };

  onChangeDateMachineryDetailsExisting = (e, index) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const { machineryDetailsExisting } = this.state;
    machineryDetailsExisting[index]["installation_date"].value = e;
    this.setState({ machineryDetailsExisting });
  };

  handleInputChangeRequired = (e, index) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { machineryDetailsRequired } = this.state;
    machineryDetailsRequired[index][controlName].value = controlValue;
    // controls[controlName].touched = true;
    this.setState({ machineryDetailsRequired });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state.annexures;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  uploadFileFormData = async (file, type) => {
    try {
      this.props.isLoadingSet(true);
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      this.props.isLoadingSet(false);
      if (data && data.data) {
        const { controls } = this.state.annexures;
        controls[type].value = data.data;
        controls[type].touched = true;
        this.handleValidation();
      }
      // let { isLoading } = this.state;
      // isLoading = false;
      // this.setState({ isLoading });
    } catch (error) {
      this.props.isLoadingSet(false);
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        const { controls } = this.state.annexures;
        controls[type].value = "";
        controls[type].touched = true;
        event.target.value = null;
        this.handleValidation();
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB.",
          "error"
        );
        return;
      }
      this.uploadFileFormData(file, type);
    }
  };

  enableEditExisting = (controlName, index) => {
    const { machineryDetailsExisting } = this.state;
    machineryDetailsExisting[index][
      controlName
    ].isDisable = !machineryDetailsExisting[index][controlName].isDisable;
    // controls[controlName].touched = true;
    this.setState({ machineryDetailsExisting });
  };

  enableEditRequired = (controlName, index) => {
    const { machineryDetailsRequired } = this.state;
    machineryDetailsRequired[index][
      controlName
    ].isDisable = !machineryDetailsRequired[index][controlName].isDisable;
    // controls[controlName].touched = true;
    this.setState({ machineryDetailsRequired });
  };

  enableEdit = (e) => {
    let { isEnableEdit } = this.state;
    if (isEnableEdit) {
      this.props.editDeatil(e, "submit");
    }
    isEnableEdit = !isEnableEdit;
    this.setState({
      isEnableEdit,
    });
  };

  closeEdit = () => {
    //let { isEnableEdit } = this.state;
    this.setState({
      isEnableEdit: false,
    });
    this.props.setDetail(true);
  };

  goToPrevPage = () => {
    this.props.goToPrevPage();
  };

  resetDetail = () => {
    this.setState({
      machineryDetailsExisting: this.props.machineryDetailsExisting,
      machineryDetailsRequired: this.props.machineryDetailsRequired,
    });
  };
  render() {
    const {
      machineryDetailsExisting,
      machineryDetailsRequired,
      annexures,
    } = this.state;
    // const { getProposalDetail } = this.props;
    const { isEdit, isEnableEdit } = this.state;
    return (
      <>
        <Card id="form-machine-detail">
          <CardBody>
            {/* Machinery Details (Existing Equipment) */}
            <Row className="dealership-details-title-wrapp">
              <Col xl="6" md="6" xs="6">
                <b>Machinery Details (Existing Equipment)</b>
              </Col>
              <div>
                {this.state.isDisplayEditBtn &&
                  (this.state.isEnableEdit ? (
                    <>
                      <span
                        className="hyper-link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.enableEdit(e)}
                      >
                        Save
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="hyper-link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.enableEdit(e)}
                      >
                        Edit
                      </span>
                    </>
                  ))}
                {this.state.isDisplayEditBtn && this.state.isEnableEdit && (
                  <>
                    &nbsp;&nbsp;
                    <span
                      className="hyper-link"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.closeEdit()}
                    >
                      Cancel
                    </span>
                  </>
                )}
                &nbsp;&nbsp;
                {/* <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.goToPrevPage()}>Back</Button> */}
              </div>
            </Row>
            <hr />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Aligner - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.WheelAligner
                        ].brand.value
                      }
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.WheelAligner
                        )
                      }
                      disabled={!isEnableEdit}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "brand",
                            this.machineryDetailsExistingIndexes.WheelAligner
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Aligner - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.WheelAligner
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.WheelAligner
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "model",
                            this.machineryDetailsExistingIndexes.WheelAligner
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Aligner-Date of Installation</Label>
                  {/* <Input type="text" name="installation_date"
                    value={machineryDetailsExisting[this.machineryDetailsExistingIndexes.WheelAligner].installation_date.value}
                    onChange={(e) => this.handleInputChangeExisting(e, this.machineryDetailsExistingIndexes.WheelAligner)} /> */}
                  <div className="d-flex">
                    <Label
                      onClick={(e) =>
                        this.calendar6.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={(e) =>
                            this.onChangeDateMachineryDetailsExisting(
                              e,
                              this.machineryDetailsExistingIndexes.WheelAligner
                            )
                          }
                          name="installation_date"
                          selected={
                            machineryDetailsExisting[
                              this.machineryDetailsExistingIndexes.WheelAligner
                            ].installation_date.value
                          }
                          disabled={!isEnableEdit}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={(r) => (this.calendar6 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          //  shouldCloseOnSelect
                          maxDate={new Date()}
                          dropdownMode="select"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "installation_date",
                            this.machineryDetailsExistingIndexes.WheelAligner
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Tyre Changer - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.TyreChanger
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.TyreChanger
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "brand",
                            this.machineryDetailsExistingIndexes.TyreChanger
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Tyre Changer - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.TyreChanger
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.TyreChanger
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "model",
                            this.machineryDetailsExistingIndexes.TyreChanger
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Tyre Changer-Date of Installation</Label>
                  {/* <Input type="text" name="installation_date"
                    value={machineryDetailsExisting[this.machineryDetailsExistingIndexes.TyreChanger].installation_date.value}
                    onChange={(e) => this.handleInputChangeExisting(e, this.machineryDetailsExistingIndexes.TyreChanger)} /> */}
                  <div className="d-flex">
                    <Label
                      onClick={(e) =>
                        this.calendar2.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={(e) =>
                            this.onChangeDateMachineryDetailsExisting(
                              e,
                              this.machineryDetailsExistingIndexes.TyreChanger
                            )
                          }
                          name="installation_date"
                          selected={
                            machineryDetailsExisting[
                              this.machineryDetailsExistingIndexes.TyreChanger
                            ].installation_date.value
                          }
                          disabled={!isEnableEdit}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={(r) => (this.calendar2 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          maxDate={new Date()}
                          dropdownMode="select"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "installation_date",
                            this.machineryDetailsExistingIndexes.TyreChanger
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Balancer - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.WheelBalancer
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.WheelBalancer
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "brand",
                            this.machineryDetailsExistingIndexes.WheelBalancer
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Balancer - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.WheelBalancer
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.WheelBalancer
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "model",
                            this.machineryDetailsExistingIndexes.WheelBalancer
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup autocomplete="off">
                  <Label>Wheel Balancer-Date of Installation</Label>
                  {/*<Input type="text" name="installation_date"
                    value={machineryDetailsExisting[this.machineryDetailsExistingIndexes.WheelBalancer].installation_date.value}
                    onChange={(e) => this.handleInputChangeExisting(e, this.machineryDetailsExistingIndexes.WheelBalancer)} /> */}
                  <div className="d-flex">
                    <Label
                      onClick={(e) =>
                        this.calendar3.state.open && e.preventDefault()
                      }
                      autocomplete="off"
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={(e) =>
                            this.onChangeDateMachineryDetailsExisting(
                              e,
                              this.machineryDetailsExistingIndexes.WheelBalancer
                            )
                          }
                          name="installation_date"
                          selected={
                            machineryDetailsExisting[
                              this.machineryDetailsExistingIndexes.WheelBalancer
                            ].installation_date.value
                          }
                          disabled={!isEnableEdit}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={(r) => (this.calendar3 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          autocomplete="off"
                          maxDate={new Date()}
                          dropdownMode="select"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "installation_date",
                            this.machineryDetailsExistingIndexes.WheelBalancer
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Nitrogen Inflator - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.NitrogenInflator
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.NitrogenInflator
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "brand",
                            this.machineryDetailsExistingIndexes
                              .NitrogenInflator
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Nitrogen Inflator - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.NitrogenInflator
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.NitrogenInflator
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "model",
                            this.machineryDetailsExistingIndexes
                              .NitrogenInflator
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Nitrogen Inflator-Date of Installation</Label>
                  {/* <Input type="text" name="installation_date"
                    value={machineryDetailsExisting[this.machineryDetailsExistingIndexes.NitrogenInflator].installation_date.value}
                    onChange={(e) => this.handleInputChangeExisting(e, this.machineryDetailsExistingIndexes.NitrogenInflator)} /> */}
                  <div className="d-flex">
                    <Label
                      onClick={(e) =>
                        this.calendar4.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={(e) =>
                            this.onChangeDateMachineryDetailsExisting(
                              e,
                              this.machineryDetailsExistingIndexes
                                .NitrogenInflator
                            )
                          }
                          name="installation_date"
                          selected={
                            machineryDetailsExisting[
                              this.machineryDetailsExistingIndexes
                                .NitrogenInflator
                            ].installation_date.value
                          }
                          disabled={!isEnableEdit}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={(r) => (this.calendar4 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          maxDate={new Date()}
                          dropdownMode="select"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "installation_date",
                            this.machineryDetailsExistingIndexes
                              .NitrogenInflator
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Compressor - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.Compressor
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.Compressor
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "brand",
                            this.machineryDetailsExistingIndexes.Compressor
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Compressor - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsExisting[
                          this.machineryDetailsExistingIndexes.Compressor
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeExisting(
                          e,
                          this.machineryDetailsExistingIndexes.Compressor
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "model",
                            this.machineryDetailsExistingIndexes.Compressor
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Compressor-Date of Installation</Label>
                  {/* <Input type="text" name="installation_date"
                    value={machineryDetailsExisting[this.machineryDetailsExistingIndexes.Compressor].installation_date.value}
                    onChange={(e) => this.handleInputChangeExisting(e, this.machineryDetailsExistingIndexes.Compressor)} /> */}
                  <div className="d-flex">
                    <Label
                      onClick={(e) =>
                        this.calendar5.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={(e) =>
                            this.onChangeDateMachineryDetailsExisting(
                              e,
                              this.machineryDetailsExistingIndexes.Compressor
                            )
                          }
                          name="installation_date"
                          selected={
                            machineryDetailsExisting[
                              this.machineryDetailsExistingIndexes.Compressor
                            ].installation_date.value
                          }
                          disabled={!isEnableEdit}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={(r) => (this.calendar5 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          maxDate={new Date()}
                          dropdownMode="select"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditExisting(
                            "installation_date",
                            this.machineryDetailsExistingIndexes.Compressor
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody>
            {/* Machinery Details (Existing Equipment) */}
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Machinery Details (Required Equipment)</b>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Aligner - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.WheelAligner
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.WheelAligner
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "brand",
                            this.machineryDetailsRequiredIndexes.WheelAligner
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Aligner - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.WheelAligner
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.WheelAligner
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "model",
                            this.machineryDetailsRequiredIndexes.WheelAligner
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Tyre Changer - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.TyreChanger
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.TyreChanger
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "brand",
                            this.machineryDetailsRequiredIndexes.TyreChanger
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Tyre Changer - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.TyreChanger
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.TyreChanger
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "model",
                            this.machineryDetailsRequiredIndexes.TyreChanger
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Balancer - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.WheelBalancer
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.WheelBalancer
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "brand",
                            this.machineryDetailsRequiredIndexes.WheelBalancer
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Wheel Balancer - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.WheelBalancer
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.WheelBalancer
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "model",
                            this.machineryDetailsRequiredIndexes.WheelBalancer
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Nitrogen Inflator - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.NitrogenInflator
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.NitrogenInflator
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "brand",
                            this.machineryDetailsRequiredIndexes
                              .NitrogenInflator
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Nitrogen Inflator - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.NitrogenInflator
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.NitrogenInflator
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "model",
                            this.machineryDetailsRequiredIndexes
                              .NitrogenInflator
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup className="form-group-space-between">
                  <Label>Compressor - Brand</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="brand"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.Compressor
                        ].brand.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.Compressor
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ATS-ELGI"> ATS-ELGI</option>
                      <option value="Precision">Precision</option>
                      <option value="Manatec">Manatec</option>
                      <option value="Hoffmann">Hoffmann</option>
                      <option value="Johnbean">Johnbean</option>
                      <option value="Corghi">Corghi</option>
                      <option value="Hunter">Hunter</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "brand",
                            this.machineryDetailsRequiredIndexes.Compressor
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup className="form-group-space-between">
                  <Label>Compressor - Model</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="model"
                      value={
                        machineryDetailsRequired[
                          this.machineryDetailsRequiredIndexes.Compressor
                        ].model.value
                      }
                      disabled={!isEnableEdit}
                      onChange={(e) =>
                        this.handleInputChangeRequired(
                          e,
                          this.machineryDetailsRequiredIndexes.Compressor
                        )
                      }
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEditRequired(
                            "model",
                            this.machineryDetailsRequiredIndexes.Compressor
                          )
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Willingness to invest in Machinery (Yes/No)</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="willing_machinery_invest"
                      value={annexures.controls.willing_machinery_invest.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEdit("willing_machinery_invest")
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Willingness to invest in Civil Work (Yes/No)</Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="willing_civil_invest"
                      value={annexures.controls.willing_civil_invest.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("willing_civil_invest")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/* <br />
        <Card >
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12" className="d-flex justify-content-between">
                <b>Annexures (please attach relevant documents as jpeg, jpg, png and pdf file upto 5MB.)</b>
                {
                  this.props.isNotFullPageViewDetail &&
                  <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.goToPrevPage()}>Back</Button>
                }
              </Col>
            </Row>
            <hr />

            <Row>
              {(this.state.userDetail.access_level === 'area' || !isEnableEdit) &&
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>Upload Site Pictures<span className="required-star">*</span></Label>
                    <input accept="image/*" type="file" name="site_picture" onChange={(e) => this.uploadFile(e, 'site_picture')} />
                    {this.state.annexures.controls.site_picture.showErrorMsg && <div className="error">* {messages.DEALERSHIP_DETAILS.SITE_PICTURES}</div>}
                  </FormGroup>
                </Col>
              }
              {
                getProposalDetail.site_picture && <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>Site Pictures</Label>
                    <a className='aa' target='_blank' rel="noopener noreferrer" href={getProposalDetail.lease_aggrement} className="d-flex-column" style={{ width: '65px' }}>
                      <img src='images/sidebar-icons/secondary-report-selected.svg' alt='' height='40px' />
                      <span className="redirect-link">Click here</span>
                    </a>
                  </FormGroup>
                </Col>
              }
            </Row>




          </CardBody>
        </Card>
 */}
      </>
    );
  }
}

export default MachineryDetails;
