import React, { Fragment } from 'react';
import {
  Button, Modal, ModalBody,
  Row, Col
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faExclamationTriangle, faExclamationCircle, faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

import PropTypes from 'prop-types';
import './ConfirmAlert.css';

class ConfirmAlert extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // this.state.modal = nextProps.show;
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    let { error = false, warning = false, success = false } = this.props;
    let footerButton = null;
    if (Array.isArray(this.props.buttons)) {
      footerButton = this.props.buttons.map(button => (
        <Fragment>
          <Button
            key={button.title}
            color={button.color}
            style={{ ...button.style, borderRadius: '25px', fontSize: '16px', fontWeight: 'bold' }}
            onClick={button.onClick}
          >{button.title}
          </Button>{'   '}
        </Fragment>
      ));
    }
    return (
      <div id="confirm-alert">
        {/* <Button color="danger" onClick={this.toggle}>{this.props.buttonLabel}</Button> */}
        <Modal
          id="confirm-alert"
          isOpen={this.props.show}
          toggle={this.closeModal}
          className={this.props.className}
          centered
        >
          {/* <ModalHeader toggle={this.closeModal}>{this.props.title}</ModalHeader> */}
          <ModalBody>
            <br />
            <Row>
              {warning && <Col xs="2">
                <FontAwesomeIcon className="arrow-color" style={{ fontSize: '50px', color: '#ffb609' }} icon={faExclamationTriangle} />
              </Col>}
              {error && <Col xs="2">
                <FontAwesomeIcon className="arrow-color" style={{ fontSize: '50px', color: '#ffb609' }} icon={faExclamationCircle} />
              </Col>}
              {success && <Col xs="2">
                <FontAwesomeIcon className="arrow-color" style={{ fontSize: '50px', color: '#ffb609' }} icon={faCheckCircle} />
              </Col>}
              <Col xs="10">
                <div className="modal-title">{this.props.title}</div>
                <div className="modal-text margin-top-10">{this.props.msg}</div>
                {/* <br /> */}
                {/* <div>{this.props.child}</div> */}
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="text-align-right">
                {footerButton}
              </Col>
            </Row>
            {/* <div style={{ textAlign: 'center' }}>
              {this.props.msg}
            </div> */}

          </ModalBody>
          {/* {footerButton &&
            <ModalFooter>
              {footerButton}
            </ModalFooter>} */}
        </Modal>
      </div>
    );
  }
}

ConfirmAlert.propTypes = {
  buttons: PropTypes.any,
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  msg: PropTypes.string.isRequired,
  className: PropTypes.any,
};

export default ConfirmAlert;
