import React from 'react'
import { useState } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import { bindActionCreators } from 'redux';
import { setDuplicateRecce, setVendorScreen } from '../../../actions/RetailManagementSystemAction';
// import CustomSpinner from '../../CustomSpinner/CustomSpinner';
import Moment from 'react-moment';
import { getDuplicateRecce } from '../selector';

const RetailOtherRecceInformation = ({ otherRecceInfo, setVendorScreenAction, setDuplicateRecceAction, duplicateRecce, status }) => {

  const [redirectToViewRecce, setRedirectToViewRecce] = useState(false);
  const [vendorViewOnly, setVendorViewOnly] = useState(false);
  const [ho, setHo] = useState(false);
  const [otherRetailId, setOtherRetailId] = useState();
  const [hoInstallation, setHoInstallation] = useState(false);
  const [vendorInstallationModification, setVendorInstallationModification] = useState(false);
  const [vendor, setVendor] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const screenStatus = localStorage.getItem('screenStatus');

  const handleRecceStatus = (row) => {
    if (status === row.status) {
      setDuplicateRecceAction(!duplicateRecce);
    }
    // setDuplicateRecceAction(!duplicateRecce);
    setOtherRetailId(row.other_retail_id)
    if (row.status === "recce_pending") {

      localStorage.setItem('screenTitle', "Recce Approval");
      localStorage.setItem('screenStatus', "recce_pending");
      setHoInstallation(false);
      // setDuplicateRecceAction(!duplicateRecce);
    }
    if (row.status === 'recce_modified') {
      localStorage.setItem('screenTitle', "Recce Modification");
      localStorage.setItem('screenStatus', "recce_modified");
    }
    if (row.status === 'recce_rejected') {
      localStorage.setItem('screenTitle', "Recce Rejected");
      localStorage.setItem('screenStatus', "recce_rejected");
    }
    if (row.status === 'installation_pending') {
      localStorage.setItem('screenTitle', "Installation Pending");
      localStorage.setItem('screenStatus', "installation_pending");
      setHoInstallation(false);
      setVendorInstallationModification(true);
    }
    if (row.status === 'installation_completed') {
      localStorage.setItem('screenTitle', "Installation Approval");
      localStorage.setItem('screenStatus', "installation_completed");
      setHoInstallation(true);
    }
    if (row.status === 'installation_modified') {
      localStorage.setItem('screenTitle', "Installation Modification Required");
      localStorage.setItem('screenStatus', "installation_modified");
      setHoInstallation(true);
      setVendorInstallationModification(true);
    }
    if (row.status === 'instllation_approved') {
      localStorage.setItem('screenTitle', "Installation Approved");
      localStorage.setItem('screenStatus', "instllation_approved");
      setVendorInstallationModification(true);
      setHoInstallation(true);

    }
    // setRetailDetail(row);
    setRedirectToViewRecce(true);
    setVendorViewOnly(false);
    setHo(true);
    setVendorScreenAction(true);
    localStorage.setItem('screenState', true);
    // setDuplicateRecceAction(!duplicateRecce)
  }

  return (
    <>
      {redirectToViewRecce && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${otherRetailId}`,
            vendorViewOnly,
            ho,
            vendor,
            vendorInstallationModification,
            hoInstallation
          }}
        />
      )}
      {/* {redirectToCompleteInstallation && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${otherRetailId}`,
            vendorViewOnly,
            isEdit,
            hoInstallation,
            isInstallationApproved,
            ho,
            vendor,
          }}
        />
      )} */}
      {hoInstallation && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${otherRetailId}`,
            vendorViewOnly,
            isEdit,
            hoInstallation,
            ho,
            vendor,
          }}
        />
      )}
      {/* {isLoading && <CustomSpinner />} */}
      <Row className={screenStatus === "recce_pending" ? "ho-remark" : ""}>
        <Col>
          <div className="pasanger-car-redial-card" style={{ padding: "24px", height: '100%' }}>
            <div className="dealer-info">
              This vendor has already submitted Recce(s) during last one year with below recce details
            </div>
            {otherRecceInfo.map(recce => {
              return (
                <div style={{ marginTop: "24px", display: 'flex' }}>
                  <div style={{ fontFamily: 'Helvetica', fontStyle: 'normal', fontSize: '14px', lineHeight: '16px', color: '#495057', fontWeight: 'normal' }}>
                    Recce Date -
                  </div> &nbsp;&nbsp;
                  <div style={{ fontFamily: 'Helvetica', fontStyle: 'normal', fontSize: '14px', lineHeight: '16px', color: '#495057', fontWeight: 'bold' }}>
                    <Moment format="DD-MM-YYYY">
                      {recce.recce_date}
                    </Moment>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div style={{ fontFamily: 'Helvetica', fontStyle: 'normal', fontSize: '14px', lineHeight: '16px', color: '#495057', fontWeight: 'normal' }}>
                    Status -
                  </div>
                  &nbsp;&nbsp;
                  <div style={{ fontFamily: 'Helvetica', fontStyle: 'normal', fontSize: '14px', lineHeight: '16px', color: '#495057', fontWeight: 'bold' }}>
                    {/* {recce.status === 'instllation_approved'?"installation_approved":recce.status} */}
                    {recce.status === 'installation_completed' && "Installation Completed"}
                    {recce.status === 'installation_modified' && "Installation Modified"}
                    {recce.status === 'installation_pending' && "Installation Pending"}
                    {recce.status === 'instllation_approved' && "Installation Approved"}
                    {recce.status === 'recce_modified' && "Recce Modified"}
                    {recce.status === 'recce_pending' && "Recce Pending"}
                    {recce.status === 'recce_rejected' && "Recce Rejected"}

                  </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div style={{ cursor: 'pointer', fontFamily: 'Helvetica', fontStyle: 'normal', fontSize: '14px', lineHeight: '16px', color: '#545CD8', fontWeight: 'bold' }}
                    onClick={() => handleRecceStatus(recce)}
                  >
                    Click here to view recce
                  </div>
                </div>
              )
            })}
          </div>
        </Col>
      </Row>
    </>
  )
}

RetailOtherRecceInformation.propTypes = {
  storeDetailControls: PropTypes.instanceOf(Object).isRequired,
  otherRecceInfo: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  duplicateRecce: getDuplicateRecce(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setVendorScreenAction: setVendorScreen,
      setDuplicateRecceAction: setDuplicateRecce,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RetailOtherRecceInformation);