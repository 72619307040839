/**
 * @name BrandStoreLocation.js
 * @desc marketing coupon
 * @author Foram Sojitra <foram.sojitra@techifysolutions.com>
 * @version 0.0.0
 */
import React, { Component } from 'react';
import Sidebar from "react-sidebar";
import { downlodFile } from "../../utils/utils";
import MarketingCouponService from '../../services/MarketingCouponService';
import './BrandStoreLocation.css';
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import BrandstoreLocationFilter from "../../components/BrandstoreLocationFilter/BrandstoreLocationFilter";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

const { ERROR_MESSAGE } = messages.COMMON;
const mapStyles = {
  width: '90%',
  height: '80%',
};

class BrandStoreLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      summary: [],
      isLoading: false,
      sidebarOpenCouponsFilter: false,
      duplicateCouponControles: {},
      filterControls: {},
      selectedElement: null,
      selectedPlace: null,
      activeMarke: null,
      showInfoWindow: true
    };
  };

  componentDidMount() {
    this.getLocation();
  }

  handleInputChange = e => {
    let { pageSize } = this.state;
    pageSize = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        pageSize: pageSize,
        page: 1
      };
    });
    setTimeout(() => {
      this.getLocation();
    }, 1);
  };

  openCouponsFilter = () => {
    document.body.style.overflow = "hidden";
    this.setState(prevState => {
      return {
        ...prevState, sidebarOpenCouponsFilter: true
      };
    });
  };

  onCloseCouponsFilter = () => {
    document.body.style.overflow = "auto";
    this.setState(prevState => {
      return { ...prevState, sidebarOpenCouponsFilter: false };
    });
  };

  applyFilter = controls => {
    this.setState({
      filterControls: controls,
      filterApplied: true,
    });
    setTimeout(() => {
      this.getLocation();
    }, 1);
    this.onCloseCouponsFilter();
  };

  resetFilter = () => {
    this.setState({
      filterControls: {},
      filterApplied: false
    });
    setTimeout(() => {
      this.getLocation();
    }, 1);
    this.onCloseCouponsFilter();
  };

  getLocation = () => {
    let reqData = `?`;

    if (this.state.filterControls.zone_name && this.state.filterControls.zone_name.value !== "") {
      reqData += `zone=${this.state.filterControls.zone_name.value}&`
    }

    if (this.state.filterControls.region_name && this.state.filterControls.region_name.value !== "") {
      reqData += `region=${this.state.filterControls.region_name.value}&`
    }

    if (this.state.filterControls.area_name && this.state.filterControls.area_name.value !== "") {
      reqData += `area=${this.state.filterControls.area_name.value}&`
    }

    if (this.state.filterControls.state_name && this.state.filterControls.state_name.value !== "") {
      reqData += `state=${this.state.filterControls.state_name.value}&`
    }

    if (this.state.filterControls.brand && this.state.filterControls.brand.value !== "") {
      reqData += `brand=${this.state.filterControls.brand}`
    }

    this.setState({
      isLoading: true
    });

    MarketingCouponService.getLocation(reqData)
      .then(res => {

        let { data, summary } = this.state;

        if (res.data && res.data.data) {

          data = res.data.data;
          summary = res.data.summary;

          this.setState(prevState => {
            return {
              ...prevState,
              data,
              summary,
              isLoading: false
            };
          });

        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  handleClick = (data) => {
    this.setState({
      isLoading: true
    });
    MarketingCouponService.getSubReports(data)
      .then(res => {
        downlodFile(res.data.data.file);
        this.setState({
          isLoading: false
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  render() {
    const {
      summary, data, selectedElement, showInfoWindow,
      // selectedPlace
    } = this.state;

    return (
      <div id="brand-store-location">
        <div>
          <span className="header-title">
            Brandstore Location
            &nbsp;
            {this.state.filterControls &&
              Object.keys(this.state.filterControls).length > 0 && (
                <img
                  src="images/filter_applied.svg"
                  alt=""
                  className="cursor-pointer"
                  onClick={() => this.openCouponsFilter()}
                />
              )}
            {this.state.filterControls &&
              Object.keys(this.state.filterControls).length === 0 && (
                <img
                  src="images/filter.svg"
                  alt=""
                  className="cursor-pointer"
                  onClick={() => this.openCouponsFilter()}
                />
              )}
          </span>
        </div>

        <br />
        {summary && summary.length > 0 && <div style={{ 'display': 'flex' }} >

          {(summary.length > 0 && summary.map(i => i.brand).indexOf("JK Tyre") > -1 &&
            summary[summary.map(i => i.brand).indexOf("JK Tyre")].counts &&
            summary[summary.map(i => i.brand).indexOf("JK Tyre")].counts > 0
          ) &&
            <div>
              <span class='box orange'></span> &nbsp;
              <b> JK Tyre</b> : {summary[summary.map(i => i.brand).indexOf("JK Tyre")].counts} &nbsp;
            </div>
          }

          {(summary.length > 0 && summary.map(i => i.brand).indexOf("Apollo Tyres") > -1 &&
            summary[summary.map(i => i.brand).indexOf("Apollo Tyres")].counts &&
            summary[summary.map(i => i.brand).indexOf("Apollo Tyres")].counts > 0
          ) &&
            <div>
              <span class='box purple'></span> &nbsp;
              <b> Apollo Tyres</b> : {summary[summary.map(i => i.brand).indexOf("Apollo Tyres")].counts} &nbsp;
            </div>
          }

          {(summary.length > 0 && summary.map(i => i.brand).indexOf("MRF") > -1 &&
            summary[summary.map(i => i.brand).indexOf("MRF")].counts &&
            summary[summary.map(i => i.brand).indexOf("MRF")].counts > 0
          ) &&
            <div>
              <span class='box red'></span> &nbsp;
              <b> MRF</b> : {summary[summary.map(i => i.brand).indexOf("MRF")].counts} &nbsp;
            </div>
          }

          {(summary.length > 0 && summary.map(i => i.brand).indexOf("CEAT") > -1 &&
            summary[summary.map(i => i.brand).indexOf("CEAT")].counts &&
            summary[summary.map(i => i.brand).indexOf("CEAT")].counts > 0
          ) &&
            <div>
              <span class='box blue'></span> &nbsp;
              <b> CEAT</b> : {summary[summary.map(i => i.brand).indexOf("CEAT")].counts} &nbsp;
            </div>
          }

          {(summary.length > 0 && summary.map(i => i.brand).indexOf("Bridgestone") > -1 &&
            summary[summary.map(i => i.brand).indexOf("Bridgestone")].counts &&
            summary[summary.map(i => i.brand).indexOf("Bridgestone")].counts > 0
          ) &&
            <div>
              <span class='box black'></span> &nbsp;
              <b> Bridgestone</b> : {summary[summary.map(i => i.brand).indexOf("Bridgestone")].counts} &nbsp;
            </div>
          }

          {(summary.length > 0 && summary.map(i => i.brand).indexOf("Goodyear") > -1 &&
            summary[summary.map(i => i.brand).indexOf("Goodyear")].counts &&
            summary[summary.map(i => i.brand).indexOf("Goodyear")].counts > 0
          ) &&
            <div>
              <span class='box green'></span> &nbsp;
              <b> Goodyear</b> : {summary[summary.map(i => i.brand).indexOf("Goodyear")].counts} &nbsp;
            </div>
          }

          {(summary.length > 0 && summary.map(i => i.brand).indexOf("Yokohama") > -1 &&
            summary[summary.map(i => i.brand).indexOf("Yokohama")].counts &&
            summary[summary.map(i => i.brand).indexOf("Yokohama")].counts > 0
          ) &&
            <div>
              <span class='box grey'></span> &nbsp;
              <b> Yokohama</b> : {summary[summary.map(i => i.brand).indexOf("Yokohama")].counts} &nbsp;
            </div>
          }

        </div>}
        <br />

        {this.state.isLoading && <CustomSpinner />}

        <Map
          google={this.props.google}
          zoom={5}
          style={mapStyles}
          initialCenter={{ lat: 23.2599, lng: 77.4126 }}
        >
          {data.map((row, i) => (
            <Marker
              icon={row.category.includes("Yokohama") ? `images/marker_grey.png` :
                row.category.includes("Goodyear") ? `images/marker_green.png` :
                  row.category.includes("Bridgestone") ? `images/marker_black.png` :
                    row.category.includes("MRF") ? `images/marker_red.png` :
                      row.category.includes("CEAT") ? `images/marker_blue.png` :
                        row.category.includes("Apollo") ? `images/marker_purple.png` :
                          `images/marker_orange.png`
              }
              key={i}
              color="blue"
              position={{ lat: row.latitude, lng: row.longitude }}
              onMouseover={(props, marker) => {
                this.setState({
                  selectedElement: row,
                  activeMarker: marker,
                  selectedPlace: props.position,
                  // showInfoWindow: true
                });
              }}
              onMouseEnter={() => {
                // this.setState({
                //   selectedElement: null,
                //   showInfoWindow: false
                // }, () => {
                // });
              }}
            >
            </Marker>
          ))}

          {selectedElement ? (
            <InfoWindow
              // position={{ lat: selectedElement.latitude, lng: selectedElement.longitude }}
              // position={selectedPlace}
              marker={this.state.activeMarker}
              visible={showInfoWindow}
            // onCloseClick={() => {
            //   this.setState({
            //     selectedElement: null
            //   });
            // }}
            >
              <div>
                Store Category : {selectedElement.category}<br />
                Dealership : {selectedElement.dealer}<br />
                Address : {selectedElement.address}<br />
                Phone : {selectedElement.phone}<br />
                Email : {selectedElement.email}<br />
                City : {selectedElement.city}<br />
                State : {selectedElement.state}<br />
              </div>
            </InfoWindow>
          ) : null}
        </Map>

        {
          this.state.sidebarOpenCouponsFilter && (
            <Sidebar
              sidebar={
                <>
                  <BrandstoreLocationFilter
                    applyFilter={this.applyFilter}
                    resetFilter={this.resetFilter}
                    onCloseSidebar={this.onCloseCouponsFilter}
                    getFilterControls={this.state.filterControls}
                  />
                </>
              }
              open={this.state.sidebarOpenCouponsFilter}
              sidebarClassName="coupon-filter"
              pullRight
              styles={{
                sidebar: { background: "white", height: "90%", top: "60px" }
              }}
            />
          )
        }

      </div >
    );
  };
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCJelTTyuaM_uZfG6O1MCunTCqU0YTvuAs'
})(BrandStoreLocation);
