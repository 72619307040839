/* eslint-disable no-console */
/* eslint-disable camelcase */
/**
 * @name WarrantyRegistrationService
 * @author Surjeet Bhadauriya <surjeet.bhadauriya@techifysolutions.com>
 * @description All APIs for warranty registration
 * @version 0.0.1
 */
import _ from 'lodash';
import { request } from './Request';
import { routes } from '../constants/constant.route';

const {
  GENERATE_OTP,
  GETCITY,
  GETSKUID,
  GET_TOP_SKU,
  GETSTATE,
  GETVEHICLETYPE,
  GET_CUSTOMER,
  GET_VEHICALE_MAKE,
  GET_VEHICALE_MODEL,
  VERIFY_OTP,
  GET_VEHICALE_VARIATION,
  // POST_WARRANTY_REGISTRATION,
  POST_WARRANTY_REGISTRATION_V3,
  WARRANTY_REPORT,
  WARRANTY_SUMMARY_REPORT,
} = routes.WARRANTY_REGISTRATION;
const API_URL = process.env.REACT_APP_API_URL;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

export default class WarrantyRegistrationService {
  /**
   * @name getVehicleType
   * @description Get Vehicle Type : Car, Bike
   * @param {}
   * @method GET
   * @returns promise
   */
  static getVehicleType() {
    return request('GET', `${API_URL}${GETVEHICLETYPE}`, null, null, null);
  }

  /**
   * @name getState
   * @description Get State
   * @param {}
   * @method GET
   * @returns promise
   */
  static getState() {
    return request('GET', `${API_URL}${GETSTATE}`, null, null, null);
  }

  /**
   * @name getCity
   * @description Get City based on State
   * @param {statId: String}
   * @method GET
   * @returns promise
   */
  static getCity(statId) {
    return request(
      'GET',
      `${API_URL}${GETCITY}?state_id=${statId}`,
      null,
      null,
      null
    );
  }

  /**
   * @name getSKUId
   * @description Get SKU ID
   * @param {params: Object}
   * @method GET
   * @returns promise
   */
  static getSKUId(params) {
    return request('GET', `${API_URL}${GETSKUID}`, params, null, null);
  }

  /**
    * @name getTopTenSKUs
    * @description Get TOP SKU
    * @param {params: Object}
    * @method GET
    * @returns promise
    */
  static getTopTenSKUs(params) {
    return request('GET', `${API_URL}${GET_TOP_SKU}`, params, null, null);
  }

  /**
   * @name getVehicalMake
   * @description Get Vehicle Brand : Suzuki, Honda..
   * @param {params: Object}
   * @method GET
   * @returns promise
   */
  static getVehicalMake(params) {
    let condition = '';
    if (NODE_ENV === 'staging') {
      condition = 'aa129ca92680ef3519f09d3e3725366b94d1b66a';
    } else if (NODE_ENV === 'development') {
      condition = '9641375153b2407a4f568d3ab44b37425625b626';
    } else if (NODE_ENV === 'production') {
      condition = '62b91e1cc94e0ef87a622acb2dfaa036034b85df';
    }
    return request('GET', `${API_URL}${GET_VEHICALE_MAKE}`, params, null, null, condition);
  }

  /**
   * @name getVehicalModel
   * @description Get Vehicle Model based on Brand
   * @param {params: Object}
   * @method GET
   * @returns promise
   */
  static getVehicalModel(params) {
    let condition = '';
    if (NODE_ENV === 'staging') {
      condition = 'aa129ca92680ef3519f09d3e3725366b94d1b66a';
    } else if (NODE_ENV === 'development') {
      condition = '9641375153b2407a4f568d3ab44b37425625b626';
    } else if (NODE_ENV === 'production') {
      condition = '62b91e1cc94e0ef87a622acb2dfaa036034b85df';
    }
    return request(
      'GET',
      `${API_URL}${GET_VEHICALE_MODEL}`,
      params,
      null,
      null,
      condition
    );
  }

  /**
   * @name getVehicalVariation
   * @description Get Vehicle Variations based on Model
   * @param {params: Object}
   * @method GET
   * @returns promise
   */
  static getVehicalVariation(params) {
    return request(
      'GET',
      `${API_URL}${GET_VEHICALE_VARIATION}`,
      params,
      null,
      null
    );
  }

  /**
   * @name getCustomer
   * @description Saves the data of customer warranty registration
   * @param {data: Object}
   * @method POST
   * @returns promise
   */
  static getCustomer(data) {
    return request('POST', `${API_URL}${GET_CUSTOMER}`, null, data, null);
  }

  /**
   * @name postWarrantyRegistration
   * @description Saves the data of customer warranty registration
   * @param {data: Object}
   * @method POST
   * @returns promise
   */
  static postWarrantyRegistration(data) {
    return request(
      'POST',
      `${API_URL}${POST_WARRANTY_REGISTRATION_V3}`,
      null,
      data,
      null
    );
  }

  /**
   * @name sendOTP
   * @description Sends the OTP to specific Mobile No.
   * @param {data: Object}
   * @method POST
   * @returns promise
   */
  static sendOTP(data) {
    return request('POST', `${API_URL}${GENERATE_OTP}`, null, data, null);
  }

  /**
   * @name verifyOTP
   * @description Verfies the passed OTP
   * @param {data: Object}
   * @method POST
   * @returns promise
   */
  static verifyOTP(data) {
    return request('POST', `${API_URL}${VERIFY_OTP}`, null, data, null);
  }

  /**
   * @name warrantyReport
   * @description Verfies the passed OTP
   * @param {data: Object}
   * @method GET
   * @returns promise
   */
  static warrantyReport(data) {
    let qp = '?';
    if (data.m) {
      qp += `m=${data.m}`;
    }
    if (data.y && data.m) {
      qp += `&y=${data.y}`;
    }
    if ((data.y || data.m) && data.sap_id) {
      qp += `&sap_id=${data.sap_id}`;
    } else if (data.y && !data.m) {
      qp += `y=${data.y}`;
    }

    return request(
      'GET',
      `${API_URL}${WARRANTY_REPORT}${qp}`,
      null,
      null,
      null
    );
  }

  static warrantySummaryReport(data) {
    let qp = '?';
    if (data.y) {
      qp += `y=${data.y}`;
    }

    return request(
      'GET',
      `${API_URL}${WARRANTY_SUMMARY_REPORT}${qp}`,
      null,
      null,
      null
    );
  }



  static getCustomerDetail(params) {
    return request(
      'POST',
      `${API_URL}${routes.ADD_CUSTOMER_NEW.GET_CUSTOMER_DETAIL}`,
      null,
      params,
      null
    );
  }

  /**
   * @name checkUserAgent
   * @description detect browser or mobile
   * @param {}
   * @returns Boolean
   */
  static checkUserAgent() {
    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      return true;
    }
    if (/Chrome/i.test(ua)) {
      return false;
    }
    return false;
  }

  static goTo(value, updateState, route) {
    if (value) {
      updateState({ visibleArea: route });
    }
  }

  static buildRegistrationObject(state) {
    let obj = {};

    if (state) {
      const {
        customerDetails,
        vehicleDetails,
        productDetails,
        token,
        from,
        sensorNumberInputBoxArray
      } = state;

      const serial_number = [];
      if (sensorNumberInputBoxArray.length > 0) {
        sensorNumberInputBoxArray.forEach(async (data) => {
          if (data.value.sensor_number !== '' && data.value.sensor_number !== null) {
            serial_number.push(data.value.sensor_number);
          }
        });
      };

      obj = {
        first_name: customerDetails.firstname.value,
        last_name: customerDetails.lastname.value,
        email_id: customerDetails.email.value,
        country_code: '+91',
        phone_number: customerDetails.phoneNumber.value,
        state_id: vehicleDetails.state.value.state_id,
        city_id: vehicleDetails.city.value.city_id,
        vehicle_type_id: vehicleDetails.vehicleTypeId.value.vehicle_type_id,
        vehicle_type_name:
          vehicleDetails.vehicleTypeId.value &&
          (vehicleDetails.vehicleTypeId.value.name || '').toLowerCase(),
        vehicle_type_brand_id: vehicleDetails.vehicleTypeBrandId.value.brand_id,
        vehicle_type_brand_model_id:
          vehicleDetails.vehicleTypeBrandModelId.value.model_id,
        vehicle_type_brand_model_variation_id: null,
        registration_number: vehicleDetails.registrationNumber.value,
        registration_year: vehicleDetails.registrationYear.value,
        vehical_meter_reading: null,
        vehical_avg_monthly_running:
          vehicleDetails.vehicleAvgMonthlyRunning.value,
        vehicle_category: vehicleDetails.vehicleCategory.value,
        tyre_qty: productDetails.tyreQuantity.value,
        tyre_description: [],
        serial_number,
        sku_id_1:
          (productDetails.skuId1.value && productDetails.skuId1.value.sku_id) ||
          '',
        sku_id_back:
          (productDetails.skuIdBack.value &&
            productDetails.skuIdBack.value.sku_id) ||
          '',
        sku_id_spare:
          (productDetails.skuIdSpare.value &&
            productDetails.skuIdSpare.value.sku_id) ||
          '',
        token,
        is_edit: false,
        from_claim: from === 'warrantyReplacement',
      };

      // change for driver registration
      if (customerDetails && customerDetails.ownerPhoneNumber && customerDetails.ownerPhoneNumber.value) {
        obj.phone_number = customerDetails.ownerPhoneNumber.value;
        obj.driver_phone_number = customerDetails.phoneNumber.value;
      }

      _.forEach(
        state.stencilNumberInputBoxArray || [],
        (stencilNumberInputBox) => {
          obj.tyre_description.push(stencilNumberInputBox.value);
        }
      );
    }
    return obj;
  }
}
