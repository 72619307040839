// Retail Management System
export const SET_RETAIL_RECCI_STORE_INFORMATION = 'SET_RETAIL_RECCI';
export const SET_RECCE_STATUS = 'SET_RECCE_STATUS';
export const SET_RECCE_STATUS_COUNT = 'SET_RECCE_STATUS_COUNT';
export const SET_RECCE_STATUS_COUNT_FILTER = 'SET_RECCE_STATUS_COUNT_FILTER';
export const SET_HO_RECCE_MODIFICATION = 'SET_HO_RECCE_MODIFICATION';
export const SET_VENDOR_SCREEN = 'SET_VENDOR_SCREEN';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_FILTER_OPEN_CLOSE = 'SET_FILTER_OPEN_CLOSE';
export const SET_FILTER_APPLIED_STATUS = 'SET_FILTER_APPLIED_STATUS';
export const SET_DUPLICATE_RECCE = 'SET_DUPLICATE_RECCE';
export const SET_EVENT_REGISTERATION_INFO = 'SET_EVENT_REGISTERATION_INFO';
