import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Sidebar from "react-sidebar";
import lodash from "lodash";
import "./RetailMarketingRecceListHO.css";
import RetailMarketingSystemService from '../../../services/RetailMarketingSystemService';
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import GoogleAnalticsService from "../../../services/GoogleAnalticsService";
import RecceManagementFilter from "../../../components/RecceManagementFilter/RecceManagementFilter";
import RecceProgressStatus from "../RecceProgressStatus/RecceProgressStatus";
import RetailMarketingList from "../../../components/RetailMarketingSystem/RetailMarketingList/RetailMarketingList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFilterOpenClose, getRecceStatus, getRecceStatusCount, getRecceStatusCountFilter } from "../../../components/RetailMarketingSystem/selector";
import { setFilterOpenClose, setRecceStatus, setRecceStatusCount, setRecceStatusCountFilter } from "../../../actions/RetailManagementSystemAction";
class RetailMarketingRecceListHO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      sidebarOpenRecceListManagementFilter: false,
      recceManagementFilterStates: {},
      filterControls: {},
      recceProgessStatus: false,
      recceDataListStatus: {},
      isLoading: false,
      recceListCount: 0,
      jkUserFilterData: {},

    };
    this.childRecceManagementFilter = React.createRef();
    // this.childRecceManagementFilter  = React.createRef();
  }
  getAllDetails() {
    // this.setState({isLoading:true});
    let reqData = {
      page: 1,
      pageSize: 10,
      q: ""
    };

    RetailMarketingSystemService.getAllRetail(reqData)
      .then((data) => {
        let { data: list } = data.data;
        this.props.setRecceStatusCountFilterAction(list.status);
        // this.props.setRecceStatusCountAction(list.total);
        let { recceListCount } = this.state;

        let { recceDataListStatus } = this.state;
        recceListCount = list.total;
        this.setState({
          recceListCount
        });
        if (list.status) {
          recceDataListStatus = list.status;
          this.setState({
            recceDataListStatus
          })
        }
        // this.setState({recceDataListStatus:list.status})
        // this.setState({isLoading:false});
      })
      .catch((e) => {
        console.error("e", e);
        // this.setState({isLoading:true});
      });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.redirectDetails !== this.props.redirectDetails) {
      if (this.props.redirectDetails) {
        this.setState({
          recceManagementFilterStates: {}
        }, () => {
          this.openRecceListManagementFilter(true);
        })
      }
    }
  }
  componentDidMount() {
    this.getAllDetails();
    // this.props.filterOpen;
  }


  resetState = () => {
    this.setState({
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      sidebarOpenRecceListManagementFilter: false,
      recceManagementFilterStates: {},
      filterControls: {},
      filterApplied: false,
    });
  };
  openRecceListManagementFilter = (is_open_from_dashboard = false) => {
    document.body.style.overflow = "hidden";
    GoogleAnalticsService.event('Customer List', 'Customer Filter View');
    this.props.setFilterOpenCloseAction(true);
  };
  onCloseSidebarRecceListManagementFilter = () => {
    document.body.style.overflow = "auto";
    this.props.setFilterOpenCloseAction(false);
  };
  applyFilter = filterStates => {
    this.setState({
      filterApplied: filterStates ? true : false,
      selected: [],
      selectAll: 0,
      customers: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      isSelectAllEntry: false,
      recceManagementFilterStates: filterStates
    }, () => {
      this.getCustomers();
    });
    this.onCloseSidebarRecceListManagementFilter();
  };
  resetFilter = () => {
    this.setState({
      filterControls: {},
      filterApplied: false
    });
    setTimeout(() => {
      this.getCustomers();
    }, 1);
    this.onCloseSidebarRecceListManagementFilter();
  };
  data = [
    {
      name: "Recce Approval Pending",
      count: "recce_pending"
    },
    {
      name: "Recce Modification Required",
      count: "recce_modified"
    },
    {
      name: "Recce Rejected",
      count: "recce_rejected"
    },
    {
      name: "Installation Pending",
      count: "installation_pending"
    },
    {
      name: "Installation Completed",
      count: "installation_completed"
    },
    {
      name: "Installation Modification",
      count: "installation_modified"
    },
    {
      name: "Installation Approved",
      count: "instllation_approved"
    }

  ]
  handleRecceStatus = () => {
    this.setState({
      recceProgessStatus: true
    });
  }
  handleStatusList = (status = "") => {
    let { recceStatus, setRecceStatusAction } = this.props;
    recceStatus = status;
    setRecceStatusAction(recceStatus);
  }
  render() {
    const {
      recceManagementFilterStates
    } = this.state;
    let countS = 0;
    {
      this.data.map(item => {
        countS = Number(countS) + Number(this.props.statusCount[item.count])
      })
    }
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        {this.state.recceProgessStatus ? <RecceProgressStatus /> : <>
          {this.props.filterOpen && (
            <Sidebar
              sidebar={
                <>
                  <RecceManagementFilter
                    ref={this.childRecceManagementFilter}
                    // applyFilter={this.applyFilter}
                    // resetFilter={this.resetFilter}
                    // getFilterControls={this.state.filterControls}
                    onCloseSidebar={this.onCloseSidebarRecceListManagementFilter}
                    jkUserFilterData={lodash.cloneDeep(recceManagementFilterStates)}
                  />
                </>
              }
              open={this.props.filterOpen}
              sidebarClassName="customer-management-filter"
              pullRight
              styles={{
                sidebar: { background: "white", height: "94%", width: '550px', top: "60px", overflowY: 'auto !important' }
              }}
            />
          )}
          <div className="header-title">Retail Marketing System</div>
          <br />
          <Row>
            <Col
              lg="3"
              className="pasanger-car-redial-col"
              style={{ paddingLeft: "15px", paddingRight: "0" }}
            >
              <div className="recce-status-list">
                <Row onClick={() => this.handleStatusList("all")} className={this.props.recceStatus === "all" ? "status-color" : "status-list"}>All ({countS >= 0 ? countS : this.state.recceListCount})</Row>
                {this.data.map(item => {
                  return <Row onClick={() => this.handleStatusList(item.count)} className={this.props.recceStatus === item.count ? "status-color" : "status-list"}>{item.name} ({this.props.statusCount ? this.props.statusCount[item.count] : this.state.recceDataListStatus[item.count]})</Row>
                })}
              </div>
            </Col>
            <Col
              lg="9"
              className="pasanger-car-redial-col"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              <div>
                <RetailMarketingList
                  role="HO"
                  openRecceFilter={this.openRecceListManagementFilter}
                />
              </div>
            </Col>
          </Row>
        </>}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recceStatus: getRecceStatus(state),
    count: getRecceStatusCount(state),
    statusCount: getRecceStatusCountFilter(state),
    filterOpen: getFilterOpenClose(state)
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setRecceStatusAction: setRecceStatus,
      setRecceStatusCountFilterAction: setRecceStatusCountFilter,
      setRecceStatusCountAction: setRecceStatusCount,
      setFilterOpenCloseAction: setFilterOpenClose
    },
    dispatch,
  );

export default connect(mapStateToProps,
  mapDispatchToProps,)(RetailMarketingRecceListHO);
