import React, { Component } from "react";
import {
  FormGroup,
  Row,
  Col,
  Input,
} from "reactstrap";
import "../AddCustomer.css";
// services
import AddCustomerService from "../../../services/AddCustomerService";
import Validation from "../../../services/Validation";
import ModalService from "../../../services/ModalService";
import { messages } from "../../../constants/constant.message";
import GoogleAnalticsService from "../../../services/GoogleAnalticsService";

class SelectPurpose extends Component {
  /**By default Select Purpose gets loaded when we clicked on Add Customer Visit */
  constructor(props) {
    super(props);
    this.state = {
      selectedPurpose: null,
      customer_services: [],
      customer_purposes: [],
      selectedServices: [],
      selectedClass: "",
      phone_number: "",
      type_of_inquiry: null,
      tyre_purchase_type: null,
      controls: {
        type_of_inquiry: {
          value: 'Tyre Purchase',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        tyre_purchase_type: {
          value: 'jk pcr and 2-3 wheeler',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        phone_number: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        selectedPurpose: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
      },
      selectedServicesErrorMsg: false,
      selectedServicesTouched: false,
      errorMsg: null,
      is_added_from_warranty: false,
      coupon_purpose: '',
      disable_radio: false,
      phone_number_disable: false
    };

    if (props.couponData && Object.keys(props.couponData).length > 0 && props.couponData.phone_number) {
      let { controls } = this.state;

      if (props.couponData.phone_number) {
        controls.phone_number.value = props.couponData.phone_number;
        this.state.coupon_purpose = props.couponData.purpose;
        this.state.disable_radio = true;
        this.state.controls.tyre_purchase_type.value = 'other';
        this.state.phone_number_disable = true;
      }

    }
  }

  componentDidMount = () => {
    GoogleAnalticsService.event('Add Customer Visit', 'Select Purpose View');
    GoogleAnalticsService.event('Add Customer Visit', 'Inquiry Click');
  };

  customInit = async () => {
    await this.getPurpose();
    //await this.getServices();
  }

  /**Handles the validation part for the mobile number, select purpose and for selected services */
  handleValidation = (isSubmit = false) => {
    let { controls, selectedServicesErrorMsg, selectedServicesTouched } = this.state;
    let isFormValid = false;
    let {
      phone_number,
      // type_of_inquiry, tyre_purchase_type,
      selectedPurpose
    } = controls;
    // if (type_of_inquiry.touched === true || isSubmit) {
    //   type_of_inquiry = Validation.notNullValidator(type_of_inquiry);
    //   type_of_inquiry.valid = !type_of_inquiry.nullValue;
    //   if (type_of_inquiry.valid === false) {
    //     type_of_inquiry.showErrorMsg = true;
    //   } else {
    //     type_of_inquiry.showErrorMsg = false;
    //   }
    // }


    if (phone_number.touched === true || isSubmit) {
      phone_number = Validation.notNullValidator(phone_number);
      phone_number = Validation.validatePhoneNumber(phone_number);
      phone_number.valid = !(phone_number.nullValue || phone_number.invalidPhone);
      if (!phone_number.valid === false && phone_number.value && phone_number.value.length === 10) {
        phone_number.showErrorMsg = false;
      } else {
        phone_number.showErrorMsg = true;
      }
    }

    if ((selectedPurpose.touched === true || isSubmit)) {
      selectedPurpose = Validation.notNullValidator(selectedPurpose);
      selectedPurpose.valid = !selectedPurpose.nullValue;
      if (selectedPurpose.valid === false) {
        selectedPurpose.showErrorMsg = true;
      } else {
        selectedPurpose.showErrorMsg = false;
      }
    }

    selectedServicesErrorMsg = false;
    if ((selectedPurpose.value && (selectedServicesTouched === true || isSubmit)) && selectedPurpose.value.type === 'service') {
      if (this.state.selectedServices.length <= 0) {
        selectedServicesErrorMsg = true;
      }
    }

    if (

      phone_number.valid === true &&
      selectedPurpose.valid === true &&
      selectedServicesErrorMsg === false
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid, selectedServicesErrorMsg });
    return isFormValid;
  }

  /**Select the Purpose for the customer */
  selectPurpose = (purpose) => {
    let prpose = null;
    if (purpose.type === "inquiry") {
      prpose = `selected-inquiry`;
      GoogleAnalticsService.event('Add Customer Visit', 'Inquiry Click');
    } else if (purpose.type === "service") {
      prpose = `selected-servicing`;
      GoogleAnalticsService.event('Add Customer Visit', 'Service Click');
    } else if (purpose.type === "tyre_purchase") {
      prpose = ` selected-tyre_purchase`;
      GoogleAnalticsService.event('Add Customer Visit', 'Tyre Purchase Click');
    }

    let { controls } = this.state;
    controls.selectedPurpose.value = purpose;
    controls.selectedPurpose.touched = true;
    /**If selected Purpose is service then get the list of service */
    if (controls.selectedPurpose.value && controls.selectedPurpose.value.type &&
      controls.selectedPurpose.value.type === 'service' && this.state.customer_services.length === 0) {
      this.getServices();
    }
    this.setState({
      controls,
      selectedPurpose: purpose,
      selectedClass: prpose,
    });
    this.handleValidation();
  };

  couponPurposeValidate = () => {
    ModalService.openAlert("", `Coupon code ${this.props.couponData.coupon_code} is only valid for the ${this.props.couponData.purpose === 'service' ? 'Service' : 'Tyre Purchase'}`, "warning");
  }

  /**Method to get the list of services */
  getServices = async () => {
    this.props.isLoading(true);
    await AddCustomerService.getService()
      .then((result) => {
        this.props.isLoading(false);
        this.setState({
          customer_services: result && result.data && result.data.data,
        });
      })
      .catch((err) => {
        this.props.isLoading(false);
        console.log("err", err);
      });
  };

  /**Get the customer Purpose which are Inquiry, Service, Tyre Purchase */
  getPurpose = async () => {
    this.props.isLoading(true);
    await AddCustomerService.getPurpose()
      .then((result) => {
        this.props.isLoading(false);
        this.setState({
          customer_purposes: result && result.data && result.data.data,
        }, () => {
          // controls.selectedPurpose.value.type === "inquiry"
          let filter = result.data.data.filter(x => x.type === 'inquiry');
          /**By defaulting selecting the Inquiry as it is present in filter */
          if (filter.length > 0) {
            this.selectPurpose(filter[0]);
          }
          /**Selecting Tyre Purchase by default if props.is_added_from_warranty is present */
          if (this.props.is_added_from_warranty) {
            filter = result.data.data.filter(x => x.type === 'tyre_purchase');
            if (filter.length > 0) {
              this.selectPurpose(filter[0]);
            }
          }
          /**If coupon is for service, then select service as default */
          if (this.state.coupon_purpose && this.state.coupon_purpose === 'service') {
            filter = result.data.data.filter(x => x.type === 'service');
            if (filter.length > 0) {
              this.selectPurpose(filter[0]);
            }
          }
          /**If coupon is for tyre_purchase, then select service as default */
          else if (this.state.coupon_purpose && this.state.coupon_purpose === 'tyre_purchase') {
            filter = result.data.data.filter(x => x.type === 'tyre_purchase');
            if (filter.length > 0) {
              this.selectPurpose(filter[0]);
            }
          }

        });
      })
      .catch((e) => {
        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        this.props.isLoading(false);
        console.log("err", e);
      });
  };

  redirectToSetting = () => {
    this.props.redirectToSetting();
  };

  /**Method to select the services when selected purpose is Service */
  selectServices = (e, id) => {
    let { selectedServices, customer_services, selectedServicesTouched } = this.state;
    let index = selectedServices.findIndex((x) => x === id);
    let indexData = customer_services.findIndex((x) => x.service_id === id);
    if (index > -1) {
      selectedServices.splice(index, 1);
      customer_services[indexData].active = false;
    } else {
      if (selectedServices.indexOf(id) === -1) {
        selectedServices.push(id);
      }
      customer_services[indexData].active = true;
    }
    selectedServicesTouched = true;
    this.setState({ selectedServices, customer_services, selectedServicesTouched });
    this.handleValidation();
  };

  /**Handle Input Change Method For phone Number and Radio button for Tyre Purchase   */
  handleInputChange = (e) => {
    const { controls } = this.state;
    let controlName = e.target.name;
    if (controlName === "phone_number") {
      if (!isNaN(e.target.value)) {
        if (e.target.value.length <= 10) {
          controls[controlName].value = e.target.value;
        }
      }
    } else {
      controls[controlName].value = e.target.value;
    }

    if (controlName === 'tyre_purchase_type') {
      if (e.target.value === 'JK PCR & 2 Wheeler Tyre Purchase') {
        GoogleAnalticsService.event('Add Customer Visit', 'JK PCR & 2 Wheeler Tyre Purchase');
      } else {
        GoogleAnalticsService.event('Add Customer Visit', 'Other Tyre Purchase');
      }
    }

    // if (controlName === 'selectedPurpose') {
    //   if (controls.selectedPurpose.value && controls.selectedPurpose.value.type &&
    //     controls.selectedPurpose.value.type === 'service' && this.state.customer_services.length === 0) {
    //     this.getServices();
    //   }
    // }
    controls[controlName].touched = true;
    this.setState({
      controls
    });
    this.handleValidation();
  };

  add_customer_with_visit_history = () => {
    AddCustomerService.addCustomerWithVisitHistory()
      .then((res) => {
        if (res.data.data.customer_vehicle.length > 0) {
          this.setState({
            Existing: true,
            customer_vehicle: res.data.data && res.data.data.customer_vehicle,
            customer_detail: res.data.data && res.data.data.customer,
          });
        }
        console.log("response", res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  resetData = () => {
    let { controls, customer_services } = this.state;
    this.getPurpose();
    controls.phone_number.value = '';
    controls.phone_number.touched = false;
    controls.type_of_inquiry.value = 'Tyre Purchase';
    controls.tyre_purchase_type.value = 'jk pcr and 2-3 wheeler';
    controls.selectedPurpose.value = null;
    if (this.state.customer_services.length <= 0) {
      this.getServices();
    } else {
      customer_services.forEach(element => {
        element.active = false;
      });
    }
    this.setState({
      controls,
      selectedServices: [],
      selectedServicesTouched: false,
      coupon_purpose: '',
      disable_radio: false,
      phone_number_disable: false
    })
  }

  render() {
    const { controls, coupon_purpose, disable_radio } = this.state;
    return (
      <>
        <Col>
          <span className="select-prpse">
            Select Purpose&nbsp;<span className="required-field">*</span>
          </span>
        </Col>
        <Row className="purpose-section">
          {this.state.customer_purposes.map((item, i) => (
            <Col xl="2" onClick={() => coupon_purpose && coupon_purpose !== '' && coupon_purpose !== null ? (coupon_purpose !== item.type ? this.couponPurposeValidate(coupon_purpose) : null) : this.selectPurpose(item)}>
              <div
                style={coupon_purpose && coupon_purpose !== '' && coupon_purpose !== null && coupon_purpose !== item.type ? { backgroundColor: '#e9ecef' } : {}}
                className={
                  controls.selectedPurpose.value &&
                    controls.selectedPurpose.value.type === item.type
                    ? `rectangle-purpose ${this.state.selectedClass}`
                    : `rectangle-purpose `
                }
              >
                <img className="purpose-image" alt='' src={item.image_url} />
              </div>
              <div className="title-purpose">{item.name}</div>
            </Col>
          ))}
          <Col xl="4"></Col>
          {controls.selectedPurpose.showErrorMsg && (
            <div className="error show_error" style={{ marginLeft: '20px', marginTop: '5px' }}>*Please select purpose</div>
          )}
        </Row>
        {/**UI when select purpose is inquiry */}
        {controls.selectedPurpose.value &&
          controls.selectedPurpose.value.type === "inquiry" && (
            <div className="margin-top-23" style={{ marginBottom: '23px' }}>
              <Col>
                <span className="select-prpse">
                  Types of Inquiry
                  <span className="required-field" style={{ marginLeft: '3px' }}>*</span>
                </span>
              </Col>
              <Row className="purpose-section margin-top-5">
                <Col xl="4" xs="12" ms="12" className="mt-1">
                  <div className="d-flex-row">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="type_of_inquiry"
                        value="tyre purchase"
                        checked={controls.type_of_inquiry.value === "tyre purchase"}
                        onChange={this.handleInputChange}
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: "10px" }}>Tyre Purchase</span>
                  </div>
                </Col>

                <Col xl="4" xs="12" ms="12" className="mt-1 margin-right-30">
                  <div className="d-flex-row">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="type_of_inquiry"
                        value="service"
                        checked={controls.type_of_inquiry.value === "service"}
                        onChange={this.handleInputChange}
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: "10px" }}>Service </span>
                  </div>
                </Col>
                <Col xl="6" xs="2" ms="2" />
              </Row>
              {this.props.error &&
                this.props.error.errorCode === "type_of_inquiry" && (
                  <div className="error l-35 T-10">
                    *{this.props.error.errorMsg}
                  </div>
                )}
            </div>
          )}

        {/**UI when select purpose is service */}
        {controls.selectedPurpose.value &&
          controls.selectedPurpose.value.type === "service" && (
            <div className="margin-top-23">
              <Col>
                <span className="select-prpse">
                  Select type of service/s&nbsp;
                  <span className="required-field">*</span>
                  <Row>
                    <Col className="service-note" xl="9" xs="9" ms="9">
                      Go to settings to modify the service list.
                    </Col>
                    <Col
                      xl="2"
                      xs="2"
                      ms="2"
                      className="L-19 text-align-right pr-0 cursor-pointer"
                      onClick={this.redirectToSetting}
                    >
                      <img
                        className="setting-icon"
                        src="/images/sidebar-icons/setting_icon.png"
                        alt=''
                      />
                    </Col>
                  </Row>
                </span>
              </Col>
              <Row className="purpose-section margin-top-5">
                <Col xl="12" xs="12" ms="12" className="mt-1">
                  <div className="d-flex-row flex-wrap">
                    {
                      this.state.customer_services &&
                      this.state.customer_services.map((object) => (
                        object.service_active_by_dealer &&
                        <Col
                          xl="4"
                          xs="4"
                          ms="2"
                          className="margin-top-5 PL-0 d-flex-row text-align-right mb-3"
                        >
                          <label className="custom-checkboax1--container">
                            <input
                              type="checkbox"
                              name="service"
                              checked={object.active}
                              onChange={(e) =>
                                this.selectServices(e, object.service_id)
                              }
                            />
                            <span className="checkmark1" />
                          </label>
                          <span style={{ marginLeft: "10px" }}
                            className="text-align-left">
                            {object.name}
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </Col>
                      ))
                    }
                  </div>
                </Col>
              </Row>
              {this.state.selectedServicesErrorMsg && (
                <div className="error" style={{ marginLeft: '27px' }}>
                  *Please select service
                </div>
              )}
            </div>
          )}

        {/**UI when select purpose is tyre purchase */}
        {controls.selectedPurpose.value &&
          controls.selectedPurpose.value.type === "tyre_purchase" && (
            <div className="margin-top-23 ML-30">
              <Col className="mt-1 mb-2" style={{ paddingLeft: '10px' }}>
                <div className="d-flex-row">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="tyre_purchase_type"
                      value="jk pcr and 2-3 wheeler"
                      disabled={disable_radio ? true : null}
                      checked={
                        controls.tyre_purchase_type.value ===
                        "jk pcr and 2-3 wheeler"
                      }
                      onChange={this.handleInputChange}
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span style={{ marginLeft: "10px" }}>
                    JK PCR, LCV, SCV & 2 Wheeler's tyre
                  </span>
                </div>
              </Col>
              <Col className="mt-1 mb-3" style={{ paddingLeft: '10px' }}>
                <div className="d-flex-row">
                  <label className="custom-checkboax-radio--container">
                    <input
                      type="radio"
                      name="tyre_purchase_type"
                      value="other"
                      disabled={disable_radio ? true : null}
                      checked={controls.tyre_purchase_type.value === "other"}
                      onChange={this.handleInputChange}
                    />
                    <span className="checkmark-radio" />
                  </label>
                  <span style={{ marginLeft: "10px" }}>
                    Other Tyre Purchase
                  </span>
                </div>
                {this.props.error &&
                  this.props.error.errorCode === "tyrepurchase" && (
                    <div className="error T-10">
                      *{this.props.error.errorMsg}
                    </div>
                  )}
              </Col>
            </div>
          )}

        <Col>
          <span className="select-prpse">
            Vehicle Owner's Mobile Number &nbsp;
            <span className="required-field">*</span>
          </span>
        </Col>
        <Row className="purpose-section">
          <Col xl="1">
            <div className="country-code">+91</div>
          </Col>
          <Col xl="4">
            <FormGroup>
              <Input
                disabled={this.state.phone_number_disable ? true : null}
                type="text"
                name="phone_number"
                id="phone_number"
                className="number"
                autoComplete="nofill"
                maxLength="10"
                value={controls.phone_number.value}
                onChange={this.handleInputChange}
                placeholder="Type here"
              />
            </FormGroup>
            {controls.phone_number.showErrorMsg && <div className="error" style={{ marginTop: '-15px' }}>* Please enter mobile number</div>}
          </Col>
          <Col xl="7"></Col>
        </Row>
      </>
    );
  }
}

export default SelectPurpose;
