import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import swal from "sweetalert";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import ProposalSerivice from "../../services/ProposalSerivice";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import { Roles } from "../../constants/constant.role";

class UploadMachineBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      controls: {
        approved: "",
        machine_bill: {
          type: "machine bill",
          document: "",
        },
        machine_image: {
          type: "machine image",
          document: "",
        },
        estimate: {
          type: "estimate",
          file: "",
        },
        agreement: {
          type: "agreement",
          file: "",
          aggrementDate: null,
        },
      },
      isLoading: false,
      userDetail: Storage.getUserDetail(),
    };
  }

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };

  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { controls } = this.state;
      if (data && data.data) {
        controls[type].file = data.data;
      }
      this.setState({ controls, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (!this.checkActiveStatus()) {
      return false;
    }
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        const { controls } = this.state;
        controls[type].file = "";
        event.target.value = null;
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB.",
          "error"
        );
        return;
      }
      this.uploadFileFormData(file, type);
    }
  };

  submitMachineDocuments = () => {
    // const { proposalStageDetail } = this.props;
    // const { proposal_status } = proposalStageDetail;
    if (!this.checkActiveStatus()) {
      return false;
    }
    const { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    const documents = [];
    if (!controls.machine_bill.file && !controls.machine_image.file) {
      return;
    }
    documents.push(controls.machine_bill);
    documents.push(controls.machine_image);
    const obj = { documents };
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });
    ProposalSerivice.uploadEstimate(obj)
      .then((res) => {
        documents.forEach((element) => {
          element.file = "";
        });
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message = "Machine Bill/Images details added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message = "Machine Bill/Images details updated successfully";
        }
        if (this.fileInput1) {
          this.fileInput1.value = "";
        }
        if (this.fileInput2) {
          this.fileInput2.value = "";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.getProposalStageDetail();
        });
        this.setState({ isLoading: false, documents });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  render() {
    const { proposalStageDetail, isCompletedAllStages } = this.props;
    let machine_image = 1;

    let machine_bill = 1;
    const indexMachine = -1;

    // if (
    // (this.state.userDetail.type !== Roles.AREA_MANAGER_ROLE ||
    //     this.state.userDetail.type !== Roles.HEAD_OFFICE_ROLE) &&
    //   proposalStageDetail &&
    //   proposalStageDetail.estimation_data &&
    //   proposalStageDetail.estimation_data
    // ) {
    //   indexMachine = proposalStageDetail.estimation_data.findIndex(
    //     (item) =>
    //       item.type === 'machine bill' ||
    //       item.type === 'machine_bill' ||
    //       item.type === 'machine image' ||
    //       item.type === 'machine_image'
    //   );
    // } else {
    //   indexMachine = 1;
    // }
    // let project_completed = [];

    let is_project_completed = isCompletedAllStages;
    // project_completed = proposalStageDetail.stages_details.filter(
    //   (x) => x.stage && x.stage === 'Inaugurated'
    // );
    // if (project_completed.length > 0 && project_completed[0].completed_date) {
    //   is_project_completed = true;
    // }
    let estimation_data = Object.assign(
      [],
      proposalStageDetail.estimation_data
    );
    if (this.state.userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE) {
      if (indexMachine === -1) {
        // is_project_completed = true;
        estimation_data = [];
      }
    }
    let noDataFoundMachine = false;
    if (
      proposalStageDetail.estimation_data &&
      proposalStageDetail.estimation_data > 0
    ) {
      const index = proposalStageDetail.estimation_data.findIndex(
        (x) =>
          x.type === "machine bill" ||
          x.type === "machine_bill" ||
          x.type === "machine image" ||
          x.type === "machine_image"
      );
      if (index) {
        noDataFoundMachine = true;
      }
    } else {
      noDataFoundMachine = true;
    }
    return (
      (!is_project_completed || estimation_data.length > 0) && (
        <>
          {this.state.isLoading && <CustomSpinner />}
          {indexMachine && (
            <>
              <br />
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12" md="12" xs="12">
                      <b>Machine Bill / Images</b>
                    </Col>
                  </Row>
                  <hr />
                  {(this.state.userDetail.access_level === "area" ||
                    this.state.userDetail.access_level === "all" ||
                    this.state.userDetail.access_level === "architect") &&
                    !is_project_completed && (
                      <>
                        <Row>
                          <Col xl="3" md="12" xs="12">
                            <FormGroup>
                              <Label>
                                Upload Machine Bill (documents as jpeg, jpg, png
                                and pdf file upto 5MB)
                              </Label>
                              <input
                                type="file"
                                ref={(ref) => (this.fileInput2 = ref)}
                                name="layout"
                                onChange={(e) =>
                                  this.uploadFile(e, "machine_bill")
                                }
                                accept="application/pdf,image/*"
                              />
                            </FormGroup>
                          </Col>
                          <Col xl="3" md="12" xs="12">
                            <FormGroup>
                              <Label>
                                Upload Machine Images (documents as jpeg, jpg,
                                png and pdf file upto 5MB)
                              </Label>
                              <input
                                type="file"
                                ref={(ref) => (this.fileInput1 = ref)}
                                name="layout"
                                onChange={(e) =>
                                  this.uploadFile(e, "machine_image")
                                }
                                accept="application/pdf,image/*"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            xl="3"
                            md="12"
                            xs="12"
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                              color="warning"
                              type="submit"
                              style={{ float: "right" }}
                              onClick={(e) => this.submitMachineDocuments()}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  <br />
                  {proposalStageDetail &&
                    proposalStageDetail.estimation_data &&
                    proposalStageDetail.estimation_data.length > 0 &&
                    proposalStageDetail.estimation_data.filter(
                      (e) =>
                        e.type === "machine bill" ||
                        e.type === "machine_bill" ||
                        e.type === "machine image" ||
                        e.type === "machine_image"
                    ).length > 0 && (
                      <Table className="mb-0  tbl-about tbl-about table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Type</th>
                            <th>Document</th>
                          </tr>
                        </thead>
                        <tbody>
                          {proposalStageDetail.estimation_data.map((item) =>
                            item.type === "machine bill" ||
                              item.type === "machine_bill" ||
                              item.type === "machine image" ||
                              item.type === "machine_image" ? (
                              <tr>
                                <td className="text-capitalize">
                                  {item.type}&nbsp;
                                  {item.type === "machine image"
                                    ? machine_image++
                                    : machine_bill++}
                                </td>
                                <td>
                                  <a
                                    className="aa d-flex-column"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.document}
                                  >
                                    <img
                                      src="images/sidebar-icons/secondary-report-selected.svg"
                                      alt=""
                                      height="40px"
                                      style={{ margin: "auto" }}
                                    />
                                    <span className="redirect-link">View</span>
                                  </a>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )
                          )}
                          {/* <tr>
                  <td>Layout</td><td> <a className='aa' target='_blank' rel="noopener noreferrer" href='SocialPhoto/ProposalFile/Arch/SitePicture_SW49ccce03_2669_PURBANCHALTYRES,GUWAHATI(wecompress.com)PPT.pdf'><img src='images/pdf_format.png' alt="" height='60px' width='60px' /></a></td><td> <a className='aa' target='_blank' rel="noopener noreferrer" href='SocialPhoto/ProposalFile/Arch/layout4e37f7cb_2669_PURBANCHALTYRES,GUWAHATILayoutPlan.pdf'><img src='images/pdf_format.png' height='60px' width='60px' /></a></td><td> <a className='aa' target='_blank' rel="noopener noreferrer" href='SocialPhoto/ProposalFile/Arch/drawings_5fa8c853_2669_DETAILDRAWING.pdf'><img src='images/pdf_format.png' alt="" height='60px' width='60px' /></a></td><td> <a className='aa' target='_blank' rel="noopener noreferrer" href='SocialPhoto/ProposalFile/Arch/pert_chart_a025339c_2669_PertChart.pdf'><img src='images/pdf_format.png' alt="" height='60px' width='60px' /></a></td>
                </tr><tr>
                  <td>Date</td><td>Jun 20 2019  4:31:32:050PM</td><td>Jun 20 2019  3:45:33:280PM</td><td>Jun 25 2019  3:08:33:193PM</td><td>Sep 12 2019  3:03:28:973PM</td>
                </tr> */}
                        </tbody>
                      </Table>
                    )}
                  {noDataFoundMachine && (
                    <div
                      className="no-data tbl-offtake-summary"
                      style={{
                        maxWidth: "100%",
                        borderTop: "solid 0.9px #e9ecef",
                      }}
                    >
                      There is no data found.
                    </div>
                  )}
                </CardBody>
              </Card>
              <br />
            </>
          )}
        </>
      )
    );
  }
}

export default UploadMachineBill;
