import React, { Component } from 'react';
import {
  Row,
  Card,
  CardBody,
  Table,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Label,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { downlodFile } from "../../utils/utils";
import lodash from "lodash"; // library added for debounce functionality
import MarketingCouponService from '../../services/MarketingCouponService';
import Pagination from '../../components/Pagination/Pagination';
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import SMSReportService from "../../services/SMSReportService";
import './SMSReport.css';

const { ERROR_MESSAGE } = messages.COMMON;

class SMSReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      search_query: "",
      isLoading: false,
      filterControls: {},
      download_report: false,
      SMSReportData: [],
      copy_lbl: 'Copy Message',
      controls: {
        startDate: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        endDate: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      }
    };
    this.searchData = lodash.debounce(this.searchData, 1000);
  };

  componentDidMount() {
    GoogleAnalticsService.pageView('SMS Report View');
    this.getSMSReport();
  }

  handlePageChange = page => {
    this.setState({ page: page });
    this.setState(prevState => {
      return {
        ...prevState,
        page: page
      };
    });
    setTimeout(() => {
      this.getSMSReport();
    }, 1);
  };

  handleInputChange = e => {
    let { pageSize } = this.state;
    pageSize = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        pageSize: pageSize,
        page: 1
      };
    });
    setTimeout(() => {
      this.getSMSReport();
    }, 1);
  };

  searchData = (search) => {
    this.setState(prevState => {
      return {
        ...prevState,
        filterApplied: true,
        search_query: search,
        page: 1
      };
    }, () => {
      this.getSMSReport();
    });
  };

  handleSearchChange = e => {
    let q = e.target.value;
    this.searchData(q);
  };

  downloadReport = () => {
    this.setState(prevState => {
      return {
        ...prevState, download_report: true
      };
    }, () => {
      this.getSMSReport(true);
    });
  }

  applyFilter = controls => {
    this.setState({
      filterControls: controls,
      filterApplied: true,
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0
    });
    setTimeout(() => {
      this.getSMSReport();
    }, 1);
  };


  getSingleSMSReport = (row = {}, is_download = false) => {

    const reqData = {};
    if (is_download) {
      reqData.export = true;
    }
    if (row && row.uuid) {
      reqData.uuid = row.uuid;
    }
    if (row && row.source) {
      reqData.source = row.source;
    }
    this.setState({
      isLoading: true
    });

    SMSReportService.getSMSReportDetail(reqData)
      .then(res => {

        if (res.data.data && res.data.data.file) {
          downlodFile(res.data.data.file);
        }
        this.setState({
          isLoading: false
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  getSMSReport = (is_download = false) => {
    let { controls } = this.state;
    const reqData = {};
    if (is_download) {
      reqData.export = true;
    } else {
      reqData.page = this.state.page;
      reqData.pageSize = this.state.pageSize;
    }
    if (controls.startDate.value) {
      let start_date = new Date(controls.startDate.value);
      start_date.setHours(5, 30, 0, 0);
      reqData.startDate = start_date.toISOString();
    }
    if (controls.endDate.value) {
      let end_date = new Date(controls.endDate.value);
      let dateToProcess = new Date(end_date.getTime());
      reqData.endDate = this.getToDateWhileSending(dateToProcess);
    }
    this.setState({
      isLoading: true
    });

    SMSReportService.getSMSReport(reqData)
      .then(res => {

        let { SMSReportData, count } = this.state;

        if (res.data.data && res.data.data.file) {

          downlodFile(res.data.data.file);
          this.setState({
            isLoading: false,
            download_report: false
          });

        } else if (res.data.data && res.data.data.data) {
          SMSReportData = res.data.data.data;
          (SMSReportData || []).forEach(element => {
            if (element.sms && element.sms.length > 55) {
              element.sms_all_content = element.sms;
              element.sms = element.sms.substring(0, 55) + '..';
            }
          });
          count = res.data.data.total_sms;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState(prevState => {
            return {
              ...prevState,
              SMSReportData,
              count,
              totalPages,
              isLoading: false
            };
          });

        } else {
          this.setState(prevState => {
            return {
              ...prevState,
              SMSReportData: [],
              count: 0,
              isLoading: false
            };
          });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  getCoupon = () => {
    const reqData = {};
    reqData.page = this.state.page;
    reqData.pagesize = this.state.pageSize;
    if (this.state.download_report) {
      reqData.download_report = this.state.download_report;
      GoogleAnalticsService.event('View Report', 'SMS Report Download Report');
    }

    if (this.state.search_query && this.state.search_query !== "") {
      reqData.q = this.state.search_query;
    }

    if (this.state.filterControls.type && this.state.filterControls.type !== "") {
      reqData.type = this.state.filterControls.type;
    }

    if (this.state.filterControls.purpose && this.state.filterControls.purpose !== "") {
      reqData.purpose = this.state.filterControls.purpose;
    }

    if (this.state.filterControls.paid_by_jk && this.state.filterControls.paid_by_jk.value !== "") {
      reqData.paid_by = this.state.filterControls.paid_by_jk.value;
    }

    if (this.state.filterControls.startDate && this.state.filterControls.startDate.value !== "") {
      let startDate = new Date(this.state.filterControls.startDate.value);
      startDate.setHours(0);
      startDate.setMinutes(0);
      reqData.startDate = startDate;
    }

    if (this.state.filterControls.endDate && this.state.filterControls.endDate.value !== "") {
      let endDate = new Date(this.state.filterControls.endDate.value);
      endDate.setHours(23);
      endDate.setMinutes(59);
      reqData.endDate = endDate;
    }

    this.setState({
      isLoading: true
    });

    MarketingCouponService.getRedeemedDealerCoupon(reqData)
      .then(res => {

        let { coupon, count } = this.state;

        if (res.data.data && res.data.data.file) {

          downlodFile(res.data.data.file);
          this.setState({
            isLoading: false,
            download_report: false
          });

        } else if (res.data.data && res.data.data.data) {
          coupon = res.data.data.data;
          count = res.data.data.total_element;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState(prevState => {
            return {
              ...prevState,
              coupon,
              count,
              totalPages,
              isLoading: false
            };
          });

        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  copyText = (row) => {

    // navigator.clipboard.writeText(row.sms_all_content ? row.sms_all_content : row.sms).then(function () {
    // }, function (err) {
    //   console.error('Async: Could not copy text: ', err);
    // });

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = row.sms_all_content ? row.sms_all_content : row.sms;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.setState({
      copy_lbl: 'Message Copied'
    })


    // setTimeout(() => {
    //   this.setState({
    //     copy_lbl: 'Copy Message'
    //   })
    // }, 500);
  }
  onChangeDate = (e, controlName) => {
    let { controls, page, pageSize } = this.state;
    controls[controlName].value = e;
    if (controls.startDate.value && controls.endDate.value) {
      page = 1;
      pageSize = 10;
    }
    this.setState({ controls, page, pageSize }, () => {
      if (controls.startDate.value && controls.endDate.value) {
        this.getSMSReport();
      }
    });
  };
  /* handleInputChange = (e) => {
     let { controls } = this.state;
     let controlName = e.target.name;
 
     const controlValue = e.target.value;
     controls[controlName] = controlValue;
 
     this.setState({
       controls
     })
   } */
  resetFilter = () => {
    let { controls, page, pageSize } = this.state;
    if (!controls.startDate.value ||
      !controls.endDate.value) {
      return
    }
    controls.startDate.value = null;
    controls.endDate.value = null;
    page = 1;
    pageSize = 10;
    this.setState({
      controls,
      page,
      pageSize
    }, () => {
      this.getSMSReport();
    })
  }

  normalText = () => {
    this.setState({
      copy_lbl: 'Copy Message'
    })
  }

  getToDateWhileSending = (date) => {
    let dateToProcesss = Object.assign(date);
    let currentDate = dateToProcesss.getDate();
    dateToProcesss.setDate(currentDate + 1);
    dateToProcesss.setHours(5, 29, 59, 59);
    return dateToProcesss.toISOString();
  }
  render() {
    const {
      count,
      pageSize,
      // search,
      controls
    } = this.state;

    return (
      <div id='redeem-report'>
        <span className="header-title">SMS Report</span>
        <br />
        <br />
        <Card className="coupon-mg-card">
          <CardBody>
            <Row
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "1%",
                marginTop: '10px',
                justifyContent: "space-between"
              }}
            >

              <div className="d-flex-row">
                <FormGroup className="d-flex-row">
                  <Label for="common" className="filter-lbl mt-auto mb-auto">Start Date</Label>
                  <Label
                    onClick={e =>
                      this.calendar1.state.open && e.preventDefault()
                    }
                    className="mt-auto mb-auto ml-2"
                  >
                    <InputGroup>
                      <DatePicker className="m_title form-control"
                        placeholderText="Select"
                        onChange={e => this.onChangeDate(e, "startDate")}
                        selected={controls.startDate.value}
                        name="startDate"
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        ref={r => (this.calendar1 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        // minDate={new Date()}
                        maxDate={controls.endDate.value ? controls.endDate.value : null}
                        autoComplete="nofill"
                        dropdownMode="select"
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                </FormGroup>
                <FormGroup className="d-flex-row ml-3">
                  <Label for="common" className="filter-lbl mt-auto mb-auto">End Date</Label>
                  <Label
                    className="add-customer-date mt-auto mb-auto ml-2"
                    onClick={e =>
                      this.calendar2.state.open && e.preventDefault()
                    }
                  >
                    <InputGroup>
                      <DatePicker
                        placeholderText="Select"
                        className={"form-control"}
                        onChange={e =>
                          this.onChangeDate(e, "endDate")
                        }
                        name="endDate"
                        // maxDate={new Date()}
                        selected={controls.endDate.value}
                        dateFormat="dd/MM/yyyy"
                        autoComplete="nofill"
                        showMonthDropdown
                        ref={r => (this.calendar2 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        minDate={controls.startDate.value ? controls.startDate.value : null}
                        dropdownMode="select"
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                </FormGroup>
                <div className="d-flex-row ml-3">
                  <span className={'Download-Full-Report mt-2 cursor-pointer'}
                    style={{ opacity: controls.startDate.value && controls.endDate.value ? '1' : '0.6' }}
                    onClick={() => this.resetFilter()}>Reset</span>
                </div>
              </div>

              {
                this.state.SMSReportData && this.state.SMSReportData.length > 0 &&
                <div className="mr-1 d-flex" style={{ justifyContent: 'flex-end', margin: 'auto' }}>
                  <img src="images/download.svg" alt="" className="download-report-icon margin-left-10" onClick={this.downloadExcel} />&nbsp;&nbsp;
                  <span className="Download-Full-Report pull-right mr-2" onClick={() => this.getSMSReport(true)} style={{ cursor: 'pointer' }}>
                    Download Report
                  </span>
                </div>
              }

            </Row>
            {this.state.isLoading && <CustomSpinner />}
            <div>
              <Table className="mb-0 table-responsive tbl-about tbl-about text-align-center">
                <thead>
                  <tr
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="coupon-list-table-header"
                  ></tr>
                </thead>
                <tbody>
                  <tr
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="coupon-list-table-header"
                  >
                    <td className="text-align-center border-1-5" style={{ width: '135px' }}>Date and Time</td>
                    <td className="text-align-center border-1-5">Message Type</td>
                    <td className="border-1-5">Sender ID</td>
                    <td className="border-1-5">Sent By</td>
                    <td className="border-1-5" style={{ width: '400px' }}>Message</td>
                    <td className="text-align-center border-1-5">Total Number</td>
                    <td className="text-align-center border-1-5">Delivered SMS</td>
                    <td className="text-align-center border-1-5">SMS Credit Used</td>
                    <td className="border-1-5" style={{ width: '120px' }}>Action</td>
                  </tr>
                  {this.state.SMSReportData.map((row, i) => (
                    <tr>
                      <td className="text-align-center border-1-5 customer-list-td">
                        {row.created_at ?
                          row.created_at :
                          '--'}
                      </td>
                      <td className="text-align-center border-1-5 customer-list-td">
                        {
                          (row.type ? row.type : 'Normal') + " SMS"
                        }
                      </td>
                      <td className="text-align-center border-1-5 customer-list-td">
                        {row.sender_id ? row.sender_id : '--'}
                      </td>
                      <td className="text-align-center border-1-5 customer-list-td">
                        {row.sms_sent_by && row.sms_sent_by !== null ? row.sms_sent_by : '--'}
                      </td>
                      <td className="border-1-5 customer-list-td text-align-left">
                        {
                          row.sms_all_content ?
                            <div className="d-flex-row justify-content-between" id="sms-report-tooltip" >

                              <span className='mt-auto mb-auto'>{row.sms}</span>
                              <div>
                                <span className="cursor-pointer tool-tip mt-auto mb-auto sms-report-tooltip-text-align-left" data-tooltip-position="top"
                                  data-tooltip={row.sms_all_content} >
                                  <img src="/images/info_white_background.svg" alt="" />
                                </span>

                                <span data-tooltip={this.state.copy_lbl}
                                  data-tooltip-position="bottom" className="ml-1">
                                  <img src="/images/duplicate.svg" alt=""
                                    onClick={() => this.copyText(row)}
                                    onMouseEnter={() => this.normalText()}
                                    className="cursor-pointer"
                                  />
                                </span>
                              </div>
                            </div> :
                            <div className="d-flex-row justify-content-between" id="sms-report-tooltip" >
                              <span className="mt-auto mb-auto">{row.sms}</span>
                              <span data-tooltip={this.state.copy_lbl}
                                data-tooltip-position="bottom" className="ml-1">
                                <img src="/images/duplicate.svg" alt=""
                                  onClick={() => this.copyText(row)}
                                  onMouseEnter={() => this.normalText()}
                                  className="cursor-pointer"
                                />
                              </span>
                            </div>
                        }
                      </td>
                      <td className="text-align-center border-1-5 customer-list-td">
                        {row.total_sms}
                      </td>
                      <td className="text-align-center border-1-5 customer-list-td">
                        {row.delivered_sms}
                      </td>
                      <td className="text-align-center border-1-5 customer-list-td">
                        {row.sms_credit_used}
                      </td>
                      <td className="border-1-5 customer-list-td">
                        {/* {
                          row.delivered_sms && row.delivered_sms > 0 ?
                            <span className="Download-Full-Report pull-right" onClick={() => this.getSingleSMSReport(row, true)} style={{ cursor: 'pointer', fontSize: '12px' }}>
                              Download Report
                </span> : ''} */}
                        <span className="Download-Full-Report pull-right" onClick={() => this.getSingleSMSReport(row, true)} style={{ cursor: 'pointer', fontSize: '12px' }}>
                          Download Report
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {count <= 0 && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%" }}
                >
                  There is no sms found.
                </div>
              )}
              {count > 0 && (
                <div
                  className="d-flex-row"
                  style={{ marginTop: "10px", justifyContent: "flex-end" }}
                >
                  <span className="Rows-per-page">Rows per page:</span>
                  <select
                    className="form-control rows-per-page-select"
                    name="pageSize"
                    value={pageSize}
                    onChange={e => this.handleInputChange(e)}
                    style={{ width: "65px" }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                  &nbsp;&nbsp;
                  <div style={{ marginTop: "5px" }}>
                    <Pagination
                      margin={2}
                      page={this.state.page}
                      count={this.state.totalPages}
                      pageSize={this.state.pageSize}
                      totalRecords={count}
                      onPageChange={this.handlePageChange}
                    ></Pagination>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };
}

export default SMSReport;
