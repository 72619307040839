import { request } from "./Request";
import { routes } from "../constants/constant.route";

const PROPOSAL_DETAILS = routes.PROPOSAL_DETAILS;
const API_URL = process.env.REACT_APP_API_URL;

export default class ProposalSerivice {
  proposalStageStatus = {};
  projectStageFilter = {};
  static getRegion() {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.GET_REGION}`,
      null,
      null,
      null
    );
  }

  static getArea(uuid) {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.GET_AREA}${uuid}`,
      null,
      null,
      null
    );
  }

  static getSO() {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.GET_SO}`,
      null,
      null,
      null
    );
  }

  static uploadNewDealerDocument(file) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.UPLOAD_DOCUMENT_NEW_DEALER}`,
      null,
      file,
      null
    );
  }

  static saveNewDealer(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.FRESH_PROPOSAL}`,
      null,
      obj,
      null
    );
  }

  static getDashboardCounts() {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.GET_DASHBOARD_COUNTS}`,
      null,
      null,
      null
    );
  }

  static getProposalList(reqData) {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.GET_PROPOSAL_LIST}`,
      reqData,
      null,
      null
    );
  }

  static getArchitect(type) {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.GET_ARCHITECT}${type}`,
      null,
      null,
      null
    );
  }

  static getProposalById(id) {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.GET_PROPOSAL_BY_ID}${id}`,
      null,
      null,
      null
    );
  }

  static getVisitByData(id) {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.VISIT_BY_DATA}${id}`,
      null,
      null,
      null
    );
  }

  static updateProposalStage(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.UPDATE_PROPOSAL_STAGE}`,
      null,
      obj,
      null
    );
  }

  static updateBrandingStatus(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.UPDATE_BRANDING_STATUS}`,
      null,
      obj,
      null
    );
  }

  static submitRecciVisit(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.RECCI_VISIT}`,
      null,
      obj,
      null
    );
  }

  static getProposalStageDetail(id, outlet_type) {
    return request(
      "GET",
      `${API_URL}${PROPOSAL_DETAILS.GET_PRPOSAL_STAGE_DETAIL}${id}&outlet_type=${outlet_type}`,
      null,
      null,
      null
    );
  }

  static setProposalStageStatus(obj) {
    this.proposalStageStatus = obj;
  }

  static upoladLayoutDocuments(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.UPLOAD_LAYOUT_DOCUMENT}`,
      null,
      obj,
      null
    );
  }

  static deleteLayoutDocuments(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.DELETE_LAYOUT_DOCUMENT}`,
      null,
      obj,
      null
    );
  }

  static saveComplitionReport(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.SAVE_COMPLTETION_REPORT}`,
      null,
      obj,
      null
    );
  }
  static saveInterimVisits(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.SAVE_INTERIM_VISIT}`,
      null,
      obj,
      null
    );
  }
  static saveHandOver(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.HAND_OVER}`,
      null,
      obj,
      null
    );
  }
  static saveEstimate(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.APPROVED_ESTIMATE}`,
      null,
      obj,
      null
    );
  }
  static uploadEstimate(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.UPLOAF_ESTIMATE}`,
      null,
      obj,
      null
    );
  }
  static assignAgency(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.ASSIGN_AGENCY}`,
      null,
      obj,
      null
    );
  }

  static agencyBranding(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.AGENCY_BRANDING}`,
      null,
      obj,
      null
    );
  }

  static serachDealer(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.SEARCH_DEALER}`,
      null,
      obj,
      null
    );
  }

  static getAgencyList(obj = {}) {
    if (obj.isPtp) {
      return request(
        "GET",
        `${API_URL}${PROPOSAL_DETAILS.AGENCY_LIST}?isPtp=${obj.isPtp}`,
        null,
        null,
        null
      );
    } else {
      return request(
        "GET",
        `${API_URL}${PROPOSAL_DETAILS.AGENCY_LIST}`,
        null,
        null,
        null
      );
    }
  }

  static uploadAgreement(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.UPLOAD_AGREEMENT}`,
      null,
      obj,
      null
    );
  }
  static saveInaugration(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.PRE_INAUGRATION_DATE_UPDATE}`,
      null,
      obj,
      null
    );
  }

  static getProposalStageStatus() {
    if (
      this.proposalStageStatus &&
      Object.keys(this.proposalStageStatus).length > 0
    ) {
      return this.proposalStageStatus;
    }
    return {};
  }

  static setFilterInProjectStage(reqData) {
    if (reqData && Object.keys(reqData).length > 0) {
      this.projectStageFilter = reqData;
    }
  }

  static getFilterProjectStage() {
    if (
      this.projectStageFilter &&
      Object.keys(this.projectStageFilter).length > 0
    ) {
      return this.projectStageFilter;
    }
    return {};
  }

  static updateProposalStatus(obj) {
    return request(
      "POST",
      `${API_URL}${PROPOSAL_DETAILS.UPDATE_PROPOSAL_STATUS}`,
      null,
      obj,
      null
    );
  }
}
