import { config } from "./constant.config";

export const messages = {
  COMMON: {
    ERROR_MESSAGE:
      "Oops! Something went wrong, Please try again. If issue persists please email us on newjkadvantage@trackwalkins.com.",
    NO_DATA_FOUND:
      "We can not find any records matching your search criteria. Please change search criteria and try again.",
  },
  WARRANTY_REGISTRATION: {
    DAILYLIMITCAR: `Sorry! The limit for maximum tyres in a single day for a vehicle is ${config.WARRANTY_REGISTRATION.CAR.DAILYTYRELIMIT}. It seems you are regstering more than ${config.WARRANTY_REGISTRATION.CAR.DAILYTYRELIMIT} tyres in a single day for the same vehicle. `,
    DAILYLIMITTWOWHEELER: `Sorry! The limit for maximum tyres in a single day for a vehicle is ${config.WARRANTY_REGISTRATION.TWOWHEELER.DAILYTYRELIMIT}. It seems you are regstering more than ${config.WARRANTY_REGISTRATION.TWOWHEELER.DAILYTYRELIMIT} tyres in a single day for the same vehicle. `,
    LAST6MONTHLIMITCAR: `Sorry you have already registered ${config.WARRANTY_REGISTRATION.CAR.LAST6MONTHTYRELIMIT} tyres in last 6 months for same vehicle. You can not register more than ${config.WARRANTY_REGISTRATION.CAR.LAST6MONTHTYRELIMIT} tyres for same vehicle in six month.`,
    LAST6MONTHLIMITTWOWHEELER: `Sorry you have already registered ${config.WARRANTY_REGISTRATION.TWOWHEELER.LAST6MONTHTYRELIMIT} tyres in last 6 months for same vehicle. You can not register more than ${config.WARRANTY_REGISTRATION.TWOWHEELER.LAST6MONTHTYRELIMIT} tyres for same vehicle in six months.`,
    INVALIDOTP:
      "Sorry the OTP you have entered is not correct. Please try again.",
    INVALIDNUMBER:
      "Sorry the NUMBER you have entered is not correct. Please try again.",
    ALREADYREGISTEREDVEHICLENUMBER:
      "You have already registered same vehicle before. Why don't you select the vehicle again and we will bring its information for you.",
    SUCCESSREGISTRATION:
      "Congratulations! You've successfully registered a customer for the warranty.",
    ERRORMESSAGE:
      "Oops! Something went wrong, Please try again. If issue persists please email us on newjkadvantage@trackwalkins.com.",
    ONCANCELCLICKTITLE:
      "Are you sure you want to quit the registration process?",
    ONCANCELCLICKMESSAGE:
      "Please note that you'll lose all the progress if you quit the process now.",
    SKUIDSANDTYRECOUNTNOTSAME:
      "The number of Tyre Description you have entered do not match with the number of Tyre(s) you have selected!",
  },
  WARRANTY_REPLACEMENT: {
    ONCANCELCLICKTITLE:
      "Are you sure you want to quit the replacement process?",
    ONCANCELCLICKMESSAGE:
      "Please note that you'll lose all the progress if you quit the process now.",
    WRCHECKLIMITEXCEEDCAR:
      "You can not select more than 5 warranty numbers for replacement of the same car.",
    WRCHECKLIMITEXCEEDTWOWHEELER:
      "You can not select more than 3 warranty numbers for replacement of the same two wheeler.",
  },
  MERCHANDISE: {
    PLACE_ORDER_SUCCESS:
      "Your order has been placed successfully. You can see the status of your orders from order history.",
  },
  LOGIN: {
    INVALID_USER_NAME: "User ID should not be empty",
    EMPTY_USER_NAME: "User ID should not be empty",
    EMPTY_PASSWORD: "Password should not be empty",
  },
  PROFILE: {
    EMPTY_EMAIL: "Please enter Email Address.",
    INVALID_EMAIL: "Please enter valid Email Address.",
    EMPTY_PHONE: "Please enter Owner's Phone Number.",
    INVALID_PHONE: "Please enter valid Phone Number.",
    INVALID_GST: "Please enter valid GST Number.",
    INVALID_PAN: "Please enter valid PAN Number.",
    INVALID_PERSON_NAME: "Please enter valid Contact Person Name",
    INVALID_DESIGNATION: "Please enter valid Designation",
    INVALID_ALTERNATE_NO: "Please enter valid Alternate Number",
    EMPTY_WHATSAPP_NO: "Please enter Whatsapp Number.",
    INVALID_WHATSAPP_NO: "Please enter valid WhatsApp Number.",
    EMPTY_BIRTHDATE: "Please enter Birth Date.",
    INVALID_FACEBOOK: "Please enter valid Facebook Page.",
    INVALID_INSTAGRAM: "Please enter valid Instagram Page.",
    INVALID_WEBSITE: "Please enter valid Website.",
    EMPTY_BUSINESS_NAME: "Please enter Business Name.",
    EMPTY_OWNER_NAME: "Please enter Owner Name.",
    EMPTY_STREET_NAME: "Please enter Street Address.",
    EMPTY_STATE: "Please enter State Name.",
    EMPTY_CITY: "Please enter City Name.",
    EMPTY_PAN_GST: "Please enter GST number or PAN number.",
    EMPTY_ALTERNATE_PERSON: "Please enter Contact Person Name.",
    EMPTY_PINCODE: "Please enter pincode.",
    INVALID_PINCODE: "Please enter valid pincode.",
    SENDER_ID_SIX_DIGIT: "Please enter minimum six digit sender id.",
    SENDER_ID_ALPHABETIC: "Please enter only alphabets.",
  },
  UPDATE_PASSWORD: {
    EMPTY_CURRENT_PASSWORD: "Current password should not be empty",
    INVALID_NEW_PASSWORD: "Please enter valid password",
    EMPTY_NEW_PASSWORD: "New password should not be empty",
    PASSWORD_NOT_MATCH: "New password and confirm password should be same",
  },
  PRIMARY_REPORT: {
    REDIRECT_JK_ADVANTAGE:
      "Please click here to know about Last year (up to March’19) Advantage Program Points.",
  },
  SEND_VERIFY_OTP: {
    INVALID_MOBILE_NUMBER: "Please enter valid mobile number.",
    INVALID_MOBILE_OTP: "Please enter valid otp.",
  },
  LOGOUT: "Do you want to Logout?",
  DEALERSHIP_DETAILS: {
    TYPE_OF_PROPOSAL: "Please select type of proposal.",
    TYPE_OF_OUTLET: "Please select type of outlet.",
    CATEGORY: "Please select category.",
    NAME: "Please enter name.",
    ADDRESS: "Please enter address.",
    PINCODE: "Please enter pincode.",
    CITY: "Please enter city.",
    STATE: "Please enter state.",
    MOBILE: "Please enter mobile.",
    EMAIL_ID: "Please enter valid email id.",
    REGION: "Please select region.",
    AREA: "Please enter area.",
    SO: "Please enter or select so.",
    OWNSHIP_TYPE: "Please select ownership type",
    PROPRIETOR: "Please enter name of proprietor",
    LESS_AGGREEMENT:
      "Please upload a lease agreement/property valid documents.",
    SITE_PICTURES: "Please upload a site valid pictures.",
  },
  ADD_EDIT_ADDRESS: {
    STATE: "Please enter state.",
    CITY: "Please enter city.",
    PINCODE: "Please enter pincode.",
    ADDRESS: "Please enter address.",
    EMAIL: "Please enter email.",
    MOBILE: "Please enter mobile number.",
  },
  NOTIFICATION: {
    EMPTY_TITLE: "Please enter title.",
    EMPTY_MESSAGE: "Please enter message.",
    EMPTY_IMAGE: "Please upload image.",
    SELECT_ROLE: "Please select role.",
    SELECT_ACTION: "Please select action.",
  },
  SUB_DEALER: {
    EMPTY_NICKNAME: "Please enter Sub-dealer/Branch name.",
    EMPTY_MOBILE_NUMBER: "Please enter mobile number.",
    INVALID_PHONE: "Please enter valid Mobile Number.",
  },
  REPORT: {
    NO_OFFTAKE_SUMMARY:
      "The offtake points summary is displayed for the completed quarter of the finacial year.",
  },
  CUSTOMER_MANAGEMENT: {
    EMPTY_DATE: "Please select from date or to date",
  },
  BANNER: {
    EMPTY_TITLE: "Please enter title",
    NO_IMAGE: "Please upload image",
    EMPTY_BANNER_START_DATE: "Please enter start date",
    EMPTY_BANNER_END_DATE: "Please enter end date",
    SELECT_USERS: "Please select users",
    ENTER_WEB_URL: "Please enter url",
  },
  POSTER: {
    NO_POSTER:
      "Currently no posters are available for this category. When new poster will be available, we will update you by sending in app notification",
    NO_IMAGE: "Please upload image",
    SELECT_USERS: "Please select users",
  },
  PROSSAL_DASHBOARDRD: {
    ON_HOLD: "Sorry! The proposal is on hold so you can not modify details.",
    ON_DROPPED: "Sorry! The proposal is dropped so you can not modify details.",
  },
};
