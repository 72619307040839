import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Moment from "react-moment";

const MultistepForRMS = ({status,updateAt}) => {

const getStyle = ()=>{
    const obj = {
      opacity:1,
      color:'#FDBB1F'
    }
    if(status === 'recce_rejected'){
      obj.opacity = 0.2;
    }
    if(status === 'installation_pending' || status === 'recce_rejected'|| status === 'installation_completed'|| status === 'instllation_approved'){
      obj.color='#A3ABB3'
    }
    
    return obj;
  }

  return (
    <div className="recce-status">
        <div style={{display:'flex'}}>
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              {(status === "recce_pending" || status === "recce_modified" || status === "recce_rejected") &&<div style={{fontFamily:' Helvetica',fontSize:'12px',fontWeight:'normal',fontStyle:'normal',color:'#495057'}}>{<Moment format="DD-MM-YYYY">
                        {updateAt}
            </Moment>}</div>}
            <div style={{background:status === "installation_modified"?"#A3ABB3":getStyle().color}} className={(status === "recce_pending"  || status === "recce_modified" )?"circle-status": status === "recce_rejected"?"circle-rejected":"circle"}><span>1</span>
            </div>
            <div className={status === "recce_pending" || status === "recce_rejected" || status === "recce_modified"?"approval-status":"status-font"}>{status === "recce_modified"?"Recce Modifications Required": status === "recce_rejected"?"Recce Rejected":(status === "installation_pending" || status === "installation_modified" || status === "instllation_approved" || status === "installation_completed") ?"Recce Approved":"Recce Approval Pending"}</div>
              </div>
              <div style={{opacity:getStyle().opacity,width:'71%',marginLeft:'4px',marginRight:'4px',paddingLeft:'8px',marginTop:'3px'}}>
            <hr style={{border:"1px solid #838C95",marginTop:'2.3rem',opacity:getStyle().opacity}}></hr>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            {status === "installation_pending" &&<div style={{fontFamily:' Helvetica',fontSize:'12px',fontWeight:'normal',fontStyle:'normal',color:'#495057'}}>{<Moment format="DD-MM-YYYY">
                        {updateAt}
            </Moment>}</div>}
            <div style={{opacity:getStyle().opacity,background:status==='installation_completed' || status==='instllation_approved' || status === "installation_modified" ?'#A3ABB3':status==='installation_pending'?'#FDBB1F':''}} className={status === "installation_pending"?"circle-status": status==="recce_rejected" || "recce_pending" || "installation_completed" || "installation_modified" || "recce_modified" || "instllation_approved" ? "circle":"circle-statuss"}><span>2</span></div>
            <div className={status === "installation_pending" ?"approval-status":"status-font"} style={{opacity:getStyle().opacity}}>Installation Pending</div>
            </div>
            <div style={{width:'71%',marginLeft:'4px',marginRight:'4px',paddingLeft:'8px',marginTop:'3px',opacity:getStyle().opacity}}>
            <hr style={{border:"1px solid #838C95",marginTop:'2.3rem',opacity:getStyle().opacity}}></hr>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            {(status === "installation_completed" || status ==="installation_modified") &&<div style={{fontFamily:' Helvetica',fontSize:'12px',fontWeight:'normal',fontStyle:'normal',color:'#495057'}}>{<Moment format="DD-MM-YYYY">
                        {updateAt}
            </Moment>}</div>}
            <div style={{opacity:getStyle().opacity,background:status==='instllation_approved' || status==='recce_rejected' ?'#A3ABB3':status==='installation_completed' || status==='installation_modified'?'#FDBB1F':'', marginTop:status ==="installation_modified"?'0px':'22'}} className={status === "installation_completed"?"circle-status": status==="recce_rejected" || "recce_pending" || "installation_pending" || "installation_modified" || "recce_modified" || "instllation_approved" ? "circle":"circle-statuss"}><span>3</span></div>
            <div style={{opacity:getStyle().opacity}} className={(status === "installation_completed" || status === "installation_modified") ?"approval-status":"status-font"}>{status ==="installation_modified"?"Installation Modification Required":"Installation Completed"}</div>
            </div>
            <div style={{opacity:getStyle().opacity,width:'71%',marginLeft:'4px',marginRight:'4px',paddingLeft:'8px',marginTop:'3px'}}>
            <hr style={{opacity:getStyle().opacity,border:"1px solid #838C95",marginTop:'2.3rem'}}></hr>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            {(status === "instllation_approved") &&<div style={{fontFamily:' Helvetica',fontSize:'12px',fontWeight:'normal',fontStyle:'normal',color:'#495057'}}>{<Moment format="DD-MM-YYYY">
                        {updateAt}
            </Moment>}</div>}
            <div style={{opacity:getStyle().opacity}} className={status === "instllation_approved"?"circle-status": status==="recce_rejected" || "recce_pending" || "installation_pending" || "installation_modified" || "recce_modified" || "installation_completed" ? "circle":"circle-statuss"}><span>4</span></div>
            <div style={{opacity:getStyle().opacity}} className={status === "instllation_approved" ?"approval-status":"status-font"}>Installation Approved</div>
            </div>
        </div>
      </div>
  )
}

MultistepForRMS.propTypes = {
  // props: PropTypes,
  status:PropTypes.string.isRequired,
  updateAt:PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(MultistepForRMS)
