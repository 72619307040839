import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import lodash from "lodash";
import './DropDownMultiple.css';
const DropDownMultiple = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(props.selectedItems && Array.isArray(props.selectedItems) ? props.selectedItems : []);
  useEffect(() => {
    setSelectedItems(props.selectedItems && Array.isArray(props.selectedItems) ? props.selectedItems : []);
  }, [props]);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const dropdownItems = props.DropdownItems && Array.isArray(props.DropdownItems) ? props.DropdownItems : [];

  let getOptionKey = props.getOptionKey ? props.getOptionKey : 'id';
  let getOptionValue = props.getOptionValue ? props.getOptionValue : 'value';

  const selectItem = (items) => {
    let indexItem = lodash.findIndex(selectedItems, { [getOptionKey]: items[getOptionKey] });
    if (indexItem > -1) {
      selectedItems.splice(indexItem, 1);
      setSelectedItems([...selectedItems])
      props.setSelectedItems([...selectedItems]);
    } else {
      setSelectedItems([...selectedItems, items]);
      props.setSelectedItems([...selectedItems, items]);
    }
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}
      id="DropDownMultiple">
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        className="d-flex-row justify-content-between dropdown-title-wrapp"
      >
        <span className="dropdown-title">
          {
            selectedItems.length > 0 ? selectedItems.map((v, index) =>
              <span className="dropdown-vehicle-name">
                {
                  index === 0 ?
                    v[getOptionValue] :
                    ` / ${v[getOptionValue]}`
                }
              </span>
            ) : 'Select'
          }
        </span>
        <img className="down-img mt-auto mb-auto" src="images/down_black.png" alt="" />
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: (data) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: 'auto',
                  maxHeight: props.maxHeight ? props.maxHeight : '',
                },
              };
            },
          },
        }}
      >

        {dropdownItems.map((item, i) => {
          return (
            <div className="drop-down-item-wrapp"
              style={{ borderBottom: i !== (dropdownItems.lenght - 1) ? '1px solid #CED4DA' : '' }}>
              <label className="custom-checkboax1--container">
                <input
                  type="checkbox"
                  name="service"
                  // checked={lodash.findIndex(selectedItems, { [getOptionKey]: item[getOptionKey] }) > -1}
                  checked={selectedItems.findIndex(data => data[getOptionKey] === item[getOptionKey]) > -1}
                  onChange={(e) =>
                    selectItem(item)
                  }
                />
                <span className="checkmark1" />
              </label>
              <span className="drop-down-item-value" onClick={() => selectItem(item)}>{item[getOptionValue]}</span>
            </div>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  );
}


export default DropDownMultiple;
