import React, { Component } from 'react';
import {
  Button, FormGroup,
  Row, Col, Table, Label,
  Card, Input, Modal, ModalBody
} from 'reactstrap';
import _ from 'lodash';
import swal from 'sweetalert';
import AddEditAddress from '../../modal/AddEditAddress/AddEditAddress';
import GiftCatalogService from '../../services/GiftCatalogService';
import MerchandiseWalletService from '../../services/MerchandiseWalletService';
import Storage from '../../services/Storage';
import ModalService from '../../services/ModalService';
import { formatNumber } from '../../utils/utils';
import { messages } from '../../constants/constant.message';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import Validation from '../../services/Validation';
import UserService from '../../services/UserService';

class RedeemCatelogCart extends Component {
  state = {
    isOtpMobile: false,
    isOtpEmail: false,
    isRequestDetailModalOpen: false,
    isModalOpen: false,
    placeOrderCartItems: [],
    total_coins: 0,
    coinsOnCart: 0,
    redeemed_coins: 0,
    selectedAddress: {},
    addressSelectedIndex: -1,
    isLoading: false,
    isSetDeafultAddress: false,
    isFormValid: false,
  }

  constructor(props) {
    super(props);

    const placeOrderCartItems = Storage.getRedeemCartItems();
    if (placeOrderCartItems && placeOrderCartItems.length <= 0) {
      this.props.backToWallet(false);
    }
    this.state = {
      isOtpMobile: false,
      isOtpEmail: false,
      isRequestDetailModalOpen: false,
      isModalOpen: false,
      placeOrderCartItems: placeOrderCartItems || [],
      coinsOnCart: props.coinsOnCart,
      balance_coins: props.balance_coins ? props.balance_coins : 0,
      redeemed_coins: props.redeemed_coins ? props.redeemed_coins : 0,
      total_coins: props.total_coins ? props.total_coins : 0,
      addressList: [],
      checkedAddress: 'address0',
      onlyOneAddEditIndex: 1,
      showAddNewAddress: true,
      isFormValid: false,
      controls: {
        otp_phone: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        otp_email: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
      },
      userData: {}
    };
  }

  componentDidMount() {
    this.getUserDetail();
    // this.getAddresses();
  }

  openMobileOTP = () => {
    const { selectedAddress } = this.state;
    if (selectedAddress && selectedAddress.selected === true) {
      this.setState({ isOtpMobile: true });
      this.sendOtp('mobile');
      this.sendOtp('email');
    } else {
      ModalService.openAlert('', 'Please select shipping address.', 'error');
      return;
    }

    // this.setState({ isOtpMobile: true });
    // this.sendOtp('mobile');
    // this.sendOtp('email');
  }

  // openEmailOTP = () => {
  //   this.setState({ isOtpEmail: true });
  //   this.sendOtp('email')
  // }

  closeModalOtp = () => {
    const { controls } = this.state;
    controls.otp_email.value = '';
    controls.otp_phone.value = '';
    this.setState({ isOtpEmail: false, isOtpMobile: false, controls });
  }

  getUserDetail = () => {
    UserService.getUserInfo()
      .then((data) => {
        this.setState({ userData: data.data.data || {} });
        if (data.data.data) {
          this.getAddresses(data.data.data);
        }
      })
      .catch((e) => {
        console.error("e", e);
      });
  };



  getAddresses = (userDetail) => {
    this.setState({ isLoading: true });
    GiftCatalogService.getAddresses()
      .then(res => {

        if (res && res.data && res.data.data && res.data.data.length > 0) {
          let userObj = userDetail;
          let userAddressArr = res.data.data;

          let sortAddressArr = userAddressArr.map(v => {
            v.defaultAddress = (v.address.toLowerCase().trim() === userObj.address_line_1.toLowerCase().trim() && v.city === userObj.city && v.email === userObj.email_1 && v.mobile === userObj.telephone_no_1 && v.pin_code === userObj.pin_code && v.state === userObj.state) ? true : false;
            v.selected = v.defaultAddress;
            return v;
          })

          sortAddressArr = _.orderBy(sortAddressArr, ['defaultAddress'], ['desc']);

          let selectedAddress = sortAddressArr.find(x => x.selected);
          if (!selectedAddress) {
            // if (sortAddressArr && sortAddressArr.length > 0) {
            //   sortAddressArr[0].selected = true
            // }
            selectedAddress = sortAddressArr && sortAddressArr.length > 0 ? sortAddressArr[0] : {};
          }

          this.setState((prevState) => {
            return {
              ...prevState,
              // addressList: res.data.data || [],
              addressList: sortAddressArr || [],
              onlyOneAddEditIndex: res.data.data.length,
              selectedAddress
            };
          });
          this.setState({ isLoading: false });
        } else {
          this.setState({ onlyOneAddEditIndex: 0, isLoading: false });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        console.log(e);
      });
  }



  async getState() {
    try {
      this.props.isDataLoading(true);
      const { data } = await MerchandiseWalletService.getState();
      if (data) {
        const stateData = data.data;
        const ownState = _.remove(stateData, { is_own_state: true });
        if (ownState.length) {
          stateData.unshift(ownState[0]);
        }

        this.setState((prevState) => {
          return { ...prevState, stateData };
        });
      }
    } catch (error) {
      console.log(error);
    }
  }


  openModal = (index) => {
    if (index === -1) {
      this.setState({ showAddNewAddress: false });
    }
    this.setState({ addressSelectedIndex: index, isModalOpen: true });
  }

  closeAddressModal = (obj) => {
    this.setState({ showAddNewAddress: true });
    if (obj && obj.address) {
      this.setState({ isModalOpen: false, isLoading: true });
      GiftCatalogService.addUpdateAddresses(obj)
        .then(res => {
          if (res && res.data && res.data.success) {
            ModalService.openAlert('Congratulations!', res.data.message, 'success');
            this.getAddresses(this.state.userData);
          }
          this.setState({ isModalOpen: false, isLoading: false });
        })
        .catch(e => {
          this.setState({ isLoading: false });
        });

    } else {
      this.setState({ isModalOpen: false });
    }
  }

  backToWallet = () => {
    if (this.state.placeOrderCartItems && this.state.placeOrderCartItems.length > 0) {
      Storage.setPlaceOrderRedeemItems(this.state.placeOrderCartItems);
    } else {
      Storage.removePlaceOrderRedeemItems();
    }
    this.props.backToWallet(false);
  }

  addSubCoins = (item, isAddToCart) => {
    let { balance_coins, coinsOnCart, placeOrderCartItems } = this.state;
    const tempPlaceOrderCartItems = _.cloneDeep(placeOrderCartItems);
    const total_quantity = isAddToCart ?
      item.total_quantity + item.minimum_quantity :
      item.total_quantity - item.minimum_quantity;

    if (total_quantity > item.current_quantity) {
      item.isInvalid = true;
      this.setState({});
      setTimeout(() => {
        item.isInvalid = false;
        this.setState({});
      }, 5000);
      return;
    }


    const indexCartItem = tempPlaceOrderCartItems.findIndex(x => x.uuid === item.uuid);
    tempPlaceOrderCartItems[indexCartItem].total_quantity = total_quantity;
    let tempCoinsOnCart = 0;
    tempPlaceOrderCartItems.forEach(element => {
      tempCoinsOnCart = element.redeemed_points + tempCoinsOnCart;
    });
    if (tempCoinsOnCart > balance_coins) {
      placeOrderCartItems[indexCartItem].total_quantity = item.total_quantity;
      item.isAddToCart = true;
      this.setState({ coinsOnCart, placeOrderCartItems });
      // item.isInvalid = true;
      // this.setState({});
      // setTimeout(() => {
      //   item.isInvalid = false;
      //   this.setState({});
      // }, 5000);
      ModalService.openAlert('Sorry!', `Your available points are ${balance_coins} which is less than items you are requesting.`, 'warning');
      return;
    }
    placeOrderCartItems = tempPlaceOrderCartItems;
    coinsOnCart = tempCoinsOnCart;
    item.isAddToCart = true;
    this.setState({ coinsOnCart, placeOrderCartItems });
  }

  removeCartItem = (index) => {
    let { placeOrderCartItems, coinsOnCart } = this.state;
    const balance_coins =
      placeOrderCartItems[index].redeemed_points;
    this.props.checkTotalCoins(placeOrderCartItems[index], false);
    placeOrderCartItems.splice(index, 1);
    coinsOnCart -= balance_coins;
    this.setState((prevState) => {
      return {
        ...prevState,
        placeOrderCartItems,
        coinsOnCart
      };
    });
    if (placeOrderCartItems.length <= 0) {
      this.state.coinsOnCart = 0;
      this.backToWallet(false);
    }
  }


  changeAddress = (e, index) => {
    let { addressList } = this.state;
    let { selectedAddress } = this.state;
    selectedAddress = addressList[index];

    addressList = addressList.map((element, i) => {
      element.selected = false;
      if (i === index) {
        element.selected = true;
      }
      return element;
    });

    let defaultAddressIndex = addressList.findIndex(x => x.defaultAddress);
    selectedAddress.defaultAddress = defaultAddressIndex > -1 && defaultAddressIndex === index;

    const controlName = e.target.name;
    this.setState((prevState) => {
      return {
        ...prevState,
        checkedAddress: controlName,
        selectedAddress,
        addressList
      };
    });
  }

  placeOrder = async () => {
    try {
      let { selectedAddress, placeOrderCartItems, isSetDeafultAddress } = this.state;

      if (selectedAddress && Object.keys(selectedAddress).length > 0) {
      } else {
        ModalService.openAlert('', 'Please select shipping address.', 'error');
        return;
      }

      swal({
        title: 'Place Order',
        text: 'Are you sure you want to place the order?',
        icon: 'warning',
        buttons: ['No', 'Yes'],
      }).then(async (isConfirm) => {

        if (isConfirm) {

          try {
            this.setState({ isLoading: true });

            if (isSetDeafultAddress) {
              await GiftCatalogService.updateUserDetail(selectedAddress);
            }

            const obj = this.buildObject();
            const { data } = await GiftCatalogService.placeOrder(obj);

            if (data && data.success) {
              placeOrderCartItems = [];
              this.setState((prevState) => {
                return {
                  ...prevState,
                  placeOrderCartItems
                };
              });
              this.backToWallet(false);
              Storage.removePlaceOrderRedeemItems();
              this.props.forceResetWallet();
              ModalService.openAlert('Congratulations!', data.message, 'success');
              this.setState({ isLoading: false });
            } else {
              ModalService.openAlert('Sorry!', data.error.message, 'error');
              this.setState({ isLoading: false });
            }

          } catch (error) {
            console.log(error);
            ModalService.openAlert('Sorry!', messages.WARRANTY_REGISTRATION.ERRORMESSAGE, 'error');
          }

        }

      });

    } catch (error) {
      console.log(error);
      ModalService.openAlert('Sorry!', messages.WARRANTY_REGISTRATION.ERRORMESSAGE, 'error');
    }
  }

  buildObject() {
    const { selectedAddress, placeOrderCartItems } = this.state;
    const obj = {
      // ...selectedAddress,
      balance: this.props.balance_coins,
      address_id: selectedAddress.uuid,
      item_data: _.map((placeOrderCartItems || []), (item) => {
        return { item_id: item.uuid, total_quantity: item.total_quantity };
      })
    };

    return obj;
  }

  handleInputChangeCheck = (isSetDeafultAddress) => {
    this.setState({ isSetDeafultAddress }, () => { });
  }

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;

    if (controls[controlName].touched === true && (controls[controlName].value === '' || controls[controlName].value === null)) {
      controls[controlName].showErrorMsg = true;
    } else {
      controls[controlName].showErrorMsg = false;
    }
    this.setState({ controls });
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { otp_email, otp_phone } = controls;

    if (otp_email.touched === true || isSubmit) {
      otp_email = Validation.notNullValidator(otp_email);

      otp_email.valid = !otp_email.nullValue;
      if (otp_email.valid === false) {
        otp_email.showErrorMsg = true;
        isFormValid = false;
      } else {
        otp_email.showErrorMsg = false;
        isFormValid = true;
      }
    }

    if (otp_phone.touched === true || isSubmit) {
      otp_phone = Validation.notNullValidator(otp_phone);
      otp_phone.valid = !otp_phone.nullValue;
      if (otp_phone.valid === false) {
        otp_phone.showErrorMsg = true;
        isFormValid = false;
      } else {
        otp_phone.showErrorMsg = false;
        isFormValid = true;
      }
    }

    this.setState({ controls, isFormValid });
    if (isFormValid) {
      this.verifyOtp();
    } else {
      return isFormValid;
    }
  };


  verifyOtp = () => {
    const { controls } = this.state;
    const { otp_phone, otp_email } = controls;
    MerchandiseWalletService.verifyOtp({
      phone_number: this.state.selectedAddress.mobile,
      email: this.state.selectedAddress.email,
      otp_phone: this.state.controls.otp_phone.value,
      otp_email: this.state.controls.otp_email.value
    })
      .then((data) => {

        otp_email.value = '';
        otp_phone.value = '';

        this.setState({
          controls,
          isLoader: false,
          isOtpMobile: false
        });

        // if (type === 'email') {
        this.closeModalOtp();
        this.placeOrder();
        // } else {
        //   this.openEmailOTP()
        // }

      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.WARRANTY_REGISTRATION.ERRORMESSAGE;
        ModalService.openAlert('', message, 'warning');
      });
  }

  sendOtp = (type) => {
    this.setState({
      isLoader: true
    });
    MerchandiseWalletService.sendOTP({
      phone_number: type === 'mobile' ? this.state.selectedAddress.mobile : null,
      email: type === 'email' ? this.state.selectedAddress.email : null, type: 'redemption'
    })
      .then((data) => {

        this.setState({
          isLoader: false,
        });

      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        console.error('e', e);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.WARRANTY_REGISTRATION.ERRORMESSAGE;
        ModalService.openAlert('', message, 'warning');
      });
  }



  render() {
    const { placeOrderCartItems, addressList, addressSelectedIndex, selectedAddress, controls, isLoading } = this.state;
    // const isAddAddress = addressList.length === this.state.onlyOneAddEditIndex;

    return (
      <>
        {isLoading && <CustomSpinner />}

        {this.state.isModalOpen && <AddEditAddress
          isAddAddress={addressList.length === 1}
          addressSelectedIndex={addressSelectedIndex}
          addressList={this.state.addressList}
          show={this.state.isModalOpen}
          userData={this.state.userData}
          closeModal={this.closeAddressModal}
          onlyOneAddEditIndex={this.state.onlyOneAddEditIndex}
        />}
        <div className="d-flex-row">
          <span onClick={() => this.backToWallet()} className="Merchandise-catalogu-link cursor-pointer">Redeem Catalogue</span>&nbsp;&nbsp;
          <img src="images/right.svg" alt='' />&nbsp;&nbsp;
          <span className="cart-lbl" style={{ marginTop: '3px' }}>Cart</span>
        </div>
        <br />
        <Row>
          <Col xl="7" md="12" xs="12">
            <div style={{ marginBottom: '5px' }}>
              <span className="cart-grey-title" style={{ fontWeight: 'bold' }}>You still have {formatNumber(this.props.balancePoints - this.props.coinsOnCart)} points available for redemption.</span>&nbsp;
              <span style={{ fontWeight: 'bold' }} onClick={() => this.backToWallet()} className="Merchandise-catalogu-link cursor-pointer">
                Click here to redeem
              </span>
            </div>
          </Col>
          <Col xl="5" md="12" xs="12">
            <div style={{ marginBottom: '5px' }}>
              <span className="cart-grey-title">Need Help? Call Our Helpline : +91 8282884545</span>
              {/* &nbsp;
              <span style={{ fontWeight: 'bold' }} onClick={() => this.backToWallet()} className="Merchandise-catalogu-link cursor-pointer">
                Click here to redeem
              </span> */}
            </div>
          </Col>
          <Col xl="7" md="12" xs="12">
            <Card>
              <div style={{ padding: '15px' }}>
                <Table className="mb-0 table-responsive table">
                  <thead>
                    <tr>
                      <th className="cart-th">Product Image</th>
                      <th className="cart-th">Product Name</th>
                      <th className="cart-th">Points Redeemed</th>
                      <th className="cart-th" style={{ width: '150px' }}>Qty.</th>
                      <th className="cart-th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      placeOrderCartItems.map((item, index) => (
                        <tr key={item.name + index}>
                          <td>
                            <img src={item.images[0].main} alt='' style={{ width: '60px', height: '59px', objectFit: 'contain' }} />
                          </td>
                          <td className="cart-td">
                            {item.name}
                          </td>
                          <td>
                            {Number(item.redeem_points) * Number(item.total_quantity)}
                          </td>
                          <td className="cart-td">
                            {item.total_quantity}
                          </td>
                          <td>
                            <img className="cursor-pointer" onClick={() => this.removeCartItem(index)} src='images/delete.svg' alt='' style={{ marginTop: '-10px', marginLeft: '15px' }} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <div className="table-total">
                  <span className="cart-grey-title font-bold">Total Points in Cart :</span><span style={{ marginLeft: '5px' }} className="ShippingAddress">{formatNumber(this.props.coinsOnCart)}</span>
                </div>
              </div>
            </Card>
          </Col>
          <Col xl="5" md="12" xs="12">
            <Card>
              <div className="Select-Shipping-Addr-header">
                <span className="Select-Shipping-Addr">
                  Verify Details to Place Order
                  {/* Select Shipping Address */}
                </span>
                <div>
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    onClick={this.openMobileOTP}
                  // onClick={this.placeOrder}
                  >
                    Place Order
                  </Button>
                </div>
              </div>
              <div className="Select-Shipping-Addr-header-add-address">
                <label className="Select-Shipping-Addr-text">
                  Select Delivery and Contact Details
                </label>
                <br />
                <label className="ShippingAddressTitleNote">
                  (Below information is as per your profile)
                </label>
                <div className="d-flex-column">
                  <div className="Add-New-Address cursor-pointer" onClick={() => { this.openModal(-1); }}>+ Add New Address</div>
                </div>
              </div>
              <div style={{ padding: '15px' }}>
                {
                  addressList.map((item, index) => (
                    <Row key={index} style={{ marginTop: '10px' }}>
                      {/* <Col xl="1" md="1">
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" value={`address${index}`} name={`address${index}`} checked={this.state.checkedAddress === `address${index}`} onChange={(e) => this.changeAddress(e, index)} />
                          <span className="checkmark-radio" />
                        </label>
                      </Col>
                      <Col xl="11" md="11" sm="11" xs="11">
                        <span className="addressTitle"> Address - </span>
                        <span className="dropdown-toggle-fleet-type-filter-sub-lbl">
                          {`${item.address}${item.city ? ', ' : ''}
                          ${item.city}${item.state ? ', ' : ''}
                          ${item.state}${item.pin_code ? '- ' : ''}
                          ${item.pin_code}${item.pin_code ? '.' : ''}`}
                        </span>
                      </Col>
                      <Col xl="1" md="1" />
                      <Col xl="11" md="11" sm="11" xs="11">
                        <span className="addressTitle">Phone Number - </span>
                        <span className="dropdown-toggle-fleet-type-filter-sub-lbl">{`${item.mobile}`}</span>
                      </Col>
                      <Col xl="1" md="1" /> */}
                      {/* <Col xs={1}>
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" value={`address${index}`} name={`address${index}`} checked={this.state.checkedAddress === `address${index}` && item.defaultAddress === true} onChange={(e) => this.changeAddress(e, index)} />
                          <span className="checkmark-radio" />
                        </label>
                      </Col> */}
                      <Col xs={1}>
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" value={`address${index}`} name={`address`} checked={item.selected} onChange={(e) => this.changeAddress(e, index)} />
                          <span className="checkmark-radio" />
                        </label>
                      </Col>
                      <Col xs={11}>
                        <div>
                          <div>
                            <span className="addressTitle"> Address - </span>
                            <span className="dropdown-toggle-fleet-type-filter-sub-lbl">
                              {`${item.address}${item.city ? ', ' : ''}
                          ${item.city}${item.state ? ', ' : ''}
                          ${item.state}${item.pin_code ? '- ' : ''}
                          ${item.pin_code}${item.pin_code ? '.' : ''}`}
                            </span>
                          </div>
                          <div>
                            <span className="addressTitle">Phone Number - </span>
                            <span className="dropdown-toggle-fleet-type-filter-sub-lbl">{`${item.mobile}`}</span>
                          </div>
                          <div>
                            <span className="addressTitle">Email - </span>
                            <span className="dropdown-toggle-fleet-type-filter-sub-lbl">{`${item.email}`}</span>
                          </div>
                        </div>
                      </Col>
                      {/* <Col xs={1}>&nbsp;</Col>
                          <Col xs={11} className='text-center'>
                            {!item.is_parent &&
                              <Button
                                className="mr-1 mt-1 mb-2 btn-pill action-button action-ob-button font-weight-bold"
                                color="warning"
                                type="submit"
                                onClick={() => { this.openModal(index); }}
                              >
                                EDIT DETAILS
                              </Button>
                            }
                          </Col> */}
                      {item.selected ?
                        <>
                          <Col xs={1}>&nbsp;</Col>
                          <Col xs={11} className='text-center'>
                            {!item.is_parent &&
                              <Button
                                className="mr-1 mt-1 mb-2 btn-pill action-button action-ob-button font-weight-bold"
                                color="warning"
                                type="submit"
                                onClick={() => { this.openModal(index); }}
                              >
                                EDIT DETAILS
                              </Button>
                            }
                          </Col>
                        </>
                        : ''}

                      {/* <Col xs={12}>
                        {!item.is_parent && <FontAwesomeIcon onClick={() => { this.openModal(index); }} style={{ cursor: 'pointer' }} color="#fdbb1f" icon={faPen} />}
                      </Col> */}
                    </Row>


                  ))
                }
              </div>
              {/* {selectedAddress && !selectedAddress.defaultAddress && selectedAddress.mobile ?
                <div className="Select-Shipping-Addr-header-add-address">
                  <FormGroup>
                    <label className="custom-checkboax1--container">
                      <input type="checkbox" name="checkSW" checked={this.state.isSetDeafultAddress}
                        onChange={(e) => this.handleInputChangeCheck(!this.state.isSetDeafultAddress)}
                      />
                      <span className="checkmark1" />
                    </label>&nbsp;&nbsp;&nbsp;
                    <span className="dropdown-toggle-fleet-type-filter-sub-lbl">Select to update the above address, phone number and email ID in your jk Advantage Profile</span>
                  </FormGroup>
                </div>

                : ''} */}
              <div className="Select-Shipping-Addr-header-add-address">
                <label className="ShippingAddressTitleNote shippingNoteTextColor">
                  The gifts will be delivered on above mentioned address after verifying mobile and email through 0TP. If you want to update any of the above mentioned details like phone number, email ID or delivery address, click on edit button
                </label>
              </div>
            </Card>
          </Col>
        </Row>

        <Modal
          id="opt-model"
          isOpen={this.state.isOtpMobile}
          toggle={this.props.openModel}
          className="modelCard"
          centered
        >
          {this.state.isLoader && <CustomSpinner />}
          <ModalBody>
            <div>
              <Col xl-12>
                <Row
                  style={{
                    marginTop: '5px',
                    marginLeft: '0px',
                    marginRight: '0px',
                  }}
                >
                  <Col className="label-redeem" style={{ marginTop: '15px' }} xl="9" md="9" sm="9">
                    <b>VERIFY MOBILE & EMAIL</b>
                  </Col>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    style={{
                      marginTop: '10px',
                      cursor: 'pointer',
                      paddingLeft: '75px',
                    }}
                  >
                    <img
                      style={{
                        width: '10px'
                      }}
                      src="./images/close.svg"
                      alt=''
                      onClick={e => this.closeModalOtp()}
                    />
                  </Col>
                </Row>
                <div className="border" />
                <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                  <Col xl="9" md="12" sm="12" >
                    <Label className="label-select">Enter the OTP code send to {selectedAddress && selectedAddress.mobile}. Did not got code?
                      <span onClick={e => this.sendOtp('mobile')} className='label-send-again'> Send again</span></Label>
                  </Col>
                  <Col xl="3" md="12" sm="12" >
                    <FormGroup>
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="otp_phone"
                        id="otp_phone"
                        value={controls.otp_phone.value}
                        onChange={e => this.handleInputChange(e)}
                        placeholder="Type here"
                      />
                      {controls.otp_phone.showErrorMsg && (
                        <div className="error">
                          * Please enter phone otp.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                  <Col xl="9" md="12" sm="12" >
                    <Label className="label-select">Enter the OTP code send to {selectedAddress && selectedAddress.email}. Did not got code?
                      <span onClick={e => this.sendOtp('email')} className='label-send-again'> Send again</span></Label>
                  </Col>
                  <Col xl="3" md="12" sm="12" >
                    <FormGroup>
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="otp_email"
                        id="otp_email"
                        value={controls.otp_email.value}
                        onChange={e => this.handleInputChange(e)}
                        placeholder="Type here"
                      />
                      {controls.otp_email.showErrorMsg && (
                        <div className="error">
                          * Please enter email otp.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12" md="12" sm="12" style={{
                    padding: '-1px',
                    textAlign: 'center',
                    paddingBottom: '20px',
                    paddingTop: '10px',
                  }}>
                    <Button style={{ width: '43%' }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={e => this.closeModalOtp()}
                    >
                      Cancel
                    </Button> &nbsp; &nbsp;
                    <Button style={{ width: '43%' }}
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={e => this.handleValidation(true)}
                    >
                      Verify
                    </Button>
                  </Col>
                </Row>
              </Col>
            </div>
          </ModalBody>
        </Modal>

        {/* <Modal
          id="redeem-coupon"
          isOpen={this.state.isOtpEmail}
          toggle={this.props.openModel}
          className="modelCard"
          centered
        >
          {this.state.isLoader && <CustomSpinner />}
          <ModalBody>
            <div>
              <Col xl-12>
                <Row
                  style={{
                    marginTop: "5px",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Col className="label-redeem" style={{ marginTop: "15px" }} xl="9" md="9" sm="9">
                    <b>VERIFY EMAIL ID</b>
                  </Col>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                      paddingLeft: "60px",
                    }}
                  >
                    <img
                      style={{
                        width: "10px"
                      }}
                      src="./images/close.svg"
                       alt=''
                      onClick={e=>this.closeModalOtp()}
                    />
                  </Col>
                </Row>
                <div className="border" />
                <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                  <Col>
                    <FormGroup>
                      <Label className="label-select">Enter the OTP code send to {selectedAddress && selectedAddress.email}
                        <br />Did not got code? <span
                          onClick={e => this.sendOtp('email')}
                          className='label-send-again'>Send again</span></Label>
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="code"
                        id="code"
                        value={controls.code.value}
                        onChange={e => this.handleInputChange(e)}
                        placeholder="Type here"
                      />
                      {controls.code.showErrorMsg && (
                        <div className="error">
                          * Please enter coupon code.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" md="12" sm="12" style={{
                    padding: "-1px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}>
                    <Button style={{ width: "43%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={e => this.closeModalOtp()}
                    >
                      Cancel
                    </Button> &nbsp; &nbsp;
                    <Button style={{ width: "43%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={e => this.verifyOtp('email')}
                    >
                      Verify
                    </Button>
                  </Col>
                </Row>
              </Col>
            </div>
          </ModalBody>
        </Modal> */}
      </>
    );
  }
}
export default RedeemCatelogCart;