import React, { Component } from 'react';
import swal from 'sweetalert';
import CampaignService from "../../services/CampaignService";
class paymentSuccess extends Component {
  componentDidMount() {
    const params = this.props.match.params;
    const query = new URLSearchParams(this.props.location.search);
    if (params) {
      if (params.mode === 'success') {
        swal("SMS Purchased", `Congratulations! You have successfully purchased ${query.get('sms')} SMS worth \nRs. ${query.get('amount')} today. Your order ID is ${query.get('orderId')}.You can check all your purchase history from Order History in My Account section.`, 'success').then((e) => {
        });
        localStorage.setItem("order_id_to_cancel", null);
        this.props.history.push(`/dealer-dashboard`);
      } else if (params.mode === 'cancel') {
        swal("SMS Purchase", 'Your transaction has been cancelled.', "error").then((e) => {
        });
        this.cancelTransaction();
        // this.props.history.push(`/dealer-dashboard`);
      } else {
        swal("SMS Purchase", 'Sorry! We encountered some error while doing transaction. We will investigate from our end and will refund the amount in a week if transaction did not succeed.', "error").then((e) => {
        });
        this.cancelTransaction();
        // this.props.history.push(`/dealer-dashboard`);
      }

    }
  }

  cancelTransaction = () => {
    if (localStorage.getItem("order_id_to_cancel") && localStorage.getItem("order_id_to_cancel") !== '') {
      CampaignService.cancelTransaction(localStorage.getItem("order_id_to_cancel"))
        .then((res) => {
          localStorage.setItem("order_id_to_cancel", null);
          this.props.history.push(`/dealer-dashboard`);
        })
        .catch((e) => {
          localStorage.setItem("order_id_to_cancel", null);
          this.props.history.push(`/dealer-dashboard`);
        })
    } else {
      this.props.history.push(`/dealer-dashboard`);
    }
  }

  render() {
    return (
      <div>
      </div>
    );
  }
}
export default paymentSuccess;