import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Validation from "../../services/Validation";
import { messages } from "../../constants/constant.message";
import ProposalSerivice from "../../services/ProposalSerivice";
import { Typeahead } from "react-bootstrap-typeahead";
import _ from "lodash";
import TextareaAutosize from "react-textarea-autosize";
import Storage from "../../services/Storage";
import Autosuggest from "react-autosuggest";
import UserService from "../../services/UserService";
import { Roles } from "../../constants/constant.role";
class DealershipDetailsForm extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      dealership_Details: props.dealership_Details,
      regionData: [],
      areaData: [],
      soData: [],
      tempSo: "",
      id: props.id,
      dealerList: [],
      value: "",
      isEdit: false,
      isEnableEdit: true,
    };
    if (
      this.state.userDetail &&
      this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE &&
      window.location.hash !== "#/proposal-details"
    ) {
      this.state.isDisplayEditBtn = true;
      this.state.isEnableEdit = false;
    }
    this.searchDealer = _.debounce(this.searchDealer, 500);
  }

  resetDetail = () => {
    this.setState(
      {
        dealership_Details: this.props.dealership_Details,
      },
      () => {
        this.getRegion();
        this.getArea("");
        this.getSO();
      }
    );
  };

  componentDidMount() {
    if (!this.state.id) {
      this.initAPi();
    }
  }

  initAPi = async () => {
    await this.getUserDetail();
    this.getSO();
    this.getRegion();
  };

  getRegion() {
    this.props.isLoadingSet(true);
    ProposalSerivice.getRegion()
      .then((res) => {
        this.props.isLoadingSet(false);
        let { get_user_info, userDetail } = this.state;
        let { controls } = this.state.dealership_Details;
        if (res && res.data && res.data.data && res.data.data.length >= 0) {
          if (get_user_info.region_name && userDetail.type === Roles.AREA_MANAGER_ROLE) {
            let region = res.data.data.filter(
              (x) => x.name.toLowerCase() === get_user_info.region_name
            );
            if (region && region.length > 0) {
              controls.region.value = region[0].uuid;
              this.getArea(region[0].uuid);
            }
          }
          this.setState({
            regionData: res.data.data,
            controls,
          });
        }
      })
      .catch((e) => {
        this.props.isLoadingSet(false);
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }
  async getUserDetail() {
    this.props.isLoadingSet(true);
    await UserService.getUserInfo()
      .then((response) => {
        this.props.isLoadingSet(false);
        if (response.data && response.data.data) {
          this.setState((prevState) => {
            return {
              ...prevState,
              get_user_info: response.data.data,
            };
          });
        }
        //let userData = response.data.data;
      })
      .catch((e) => {
        this.props.isLoadingSet(false);
        console.error("e", e);
      });
  }
  getArea(uuid) {
    if (!uuid) {
      return;
    }
    this.props.isLoadingSet(true);
    ProposalSerivice.getArea(uuid)
      .then((res) => {
        this.props.isLoadingSet(false);
        let { controls } = this.state.dealership_Details;
        if (res && res.data && res.data.data && res.data.data.length >= 0) {
          let { get_user_info } = this.state;
          if (
            get_user_info.area_name &&
            this.state.userDetail.type === Roles.AREA_MANAGER_ROLE
          ) {
            let area = res.data.data.filter(
              (x) => x.name.toLowerCase() === get_user_info.area_name
            );
            if (area && area.length > 0) {
              controls.area.value = area[0].uuid;
            }
          }
          this.setState((prevState) => {
            return { ...prevState, areaData: res.data.data, controls };
          });
        }
      })
      .catch((e) => {
        this.props.isLoadingSet(false);
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  searchDealer = (e, type) => {
    let reqData = {
      q: e.value,
    };
    ProposalSerivice.serachDealer(reqData)
      .then((res) => {
        let { dealerList } = this.state;
        if (res && res.data && res.data.data && res.data.data.length >= 0) {
          dealerList = res.data.data;
        } else {
          dealerList = [];
        }
        this.setState(
          (prevState) => {
            return { ...prevState, dealerList };
          },
          () => { }
        );
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  getSO() {
    ProposalSerivice.getSO()
      .then((res) => {
        let { controls } = this.state.dealership_Details;
        if (res && res.data && res.data.data && res.data.data.length >= 0) {
          if (controls.so.value && controls.so.value.id) {
            let soValue = _.filter(res.data.data, (element) => {
              return element.id === controls.so.value.id;
            });
            if (soValue.length > 0) {
              controls.so.value = soValue[0];
            }
          }
          this.setState(
            (prevState) => {
              return { ...prevState, soData: res.data.data };
            },
            () => {
              if (this.state.isEdit) {
                this.props.setPreObj();
              }
            }
          );
        }
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  handleValidation = (firstTime = true, isSubmit = false) => {
    let { controls, isFormValid } = this.state.dealership_Details;
    let {
      outlet_type,
      category,
      name,
      address,
      pincode,
      city,
      state,
      mobile,
      email_id,
      region,
      area,
      so,
      proposal_type,
    } = controls;
    if (proposal_type.touched === true || isSubmit) {
      proposal_type = Validation.notNullValidator(proposal_type);
      proposal_type.valid = !proposal_type.nullValue;
      if (proposal_type.valid === false) {
        proposal_type.showErrorMsg = true;
      } else {
        proposal_type.showErrorMsg = false;
      }
    }
    if (outlet_type.touched === true || isSubmit) {
      outlet_type = Validation.notNullValidator(outlet_type);
      outlet_type.valid = !outlet_type.nullValue;
      if (outlet_type.valid === false) {
        outlet_type.showErrorMsg = true;
      } else {
        outlet_type.showErrorMsg = false;
      }
    }
    category.valid = true;
    category.showErrorMsg = false;
    if (
      (category.touched === true || isSubmit) &&
      outlet_type.value !== "Xpress Wheel"
    ) {
      category = Validation.notNullValidator(category);
      category.valid = !category.nullValue;
      if (category.valid === false) {
        category.showErrorMsg = true;
      } else {
        category.showErrorMsg = false;
      }
    }
    if (name.touched === true || isSubmit) {
      name = Validation.notNullValidator(name);
      name.valid = !name.nullValue;
      if (name.valid === false) {
        name.showErrorMsg = true;
      } else {
        name.showErrorMsg = false;
      }
    }
    if (address.touched === true || isSubmit) {
      address = Validation.notNullValidator(address);
      address.valid = !address.nullValue;
      if (address.valid === false) {
        address.showErrorMsg = true;
      } else {
        address.showErrorMsg = false;
      }
    }
    if (state.touched === true || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }
    if (city.touched === true || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }
    if (pincode.touched === true || isSubmit) {
      pincode = Validation.notNullValidator(pincode);
      pincode = Validation.minLengthValidator(pincode, 6);
      pincode.valid =
        !pincode.nullValue &&
        !pincode.invalidPhone &&
        !pincode.invalidMinLength;
      if (pincode.valid === false) {
        pincode.showErrorMsg = true;
      } else {
        pincode.showErrorMsg = false;
      }
    }
    if (mobile.touched === true || isSubmit) {
      mobile = Validation.notNullValidator(mobile);
      mobile = Validation.validatePhoneNumber(mobile);
      mobile.valid = !(mobile.nullValue || mobile.invalidPhone);
      if (
        !mobile.valid === false &&
        mobile.value &&
        mobile.value.length === 10
      ) {
        mobile.showErrorMsg = false;
      } else {
        mobile.showErrorMsg = true;
      }
    }
    if (email_id.touched === true || isSubmit) {
      email_id = Validation.emailValidator(email_id);
      // email = Validation.emailValidator(email);
      email_id.valid = !(email_id.nullValue || email_id.invalidEmail);
      if (
        (firstTime && email_id.invalidEmail) ||
        ((email_id.touched || isSubmit) && email_id.valid === false)
      ) {
        email_id.showErrorMsg = true;
      } else {
        email_id.showErrorMsg = false;
      }
    }
    if (region.touched === true || isSubmit) {
      region = Validation.notNullValidator(region);
      region.valid = !region.nullValue;
      if (region.valid === false) {
        region.showErrorMsg = true;
      } else {
        region.showErrorMsg = false;
      }
    }
    if (area.touched === true || isSubmit) {
      area = Validation.notNullValidator(area);
      area.valid = !area.nullValue;
      if (area.valid === false) {
        area.showErrorMsg = true;
      } else {
        area.showErrorMsg = false;
      }
    }

    if (so.touched === true || isSubmit) {
      if (so && Object.keys(so.value).length > 0) {
        mobile = Validation.notNullValidator(mobile);
        mobile = Validation.validatePhoneNumber(mobile);
        const regex = /^[0-9]*$/;
        if (!(so.value.name === null || so.value.name === "")) {
          if (so.value.name.length !== 10) {
            so.valid = false;
          } else {
            if (!so.value.name.match(regex)) {
              so.valid = false;
            } else {
              so.valid = true;
            }
          }
        } else {
          so.nullValue = true;
          so.valid = !so.nullValue;
        }
      } else {
        so.valid = false;
      }
      if (so.valid === false) {
        so.showErrorMsg = true;
      } else {
        so.showErrorMsg = false;
      }
    }
    if (
      proposal_type.valid === true &&
      outlet_type.valid === true &&
      category.valid === true &&
      name.valid === true &&
      address.valid === true &&
      pincode.valid === true &&
      city.valid === true &&
      state.valid === true &&
      mobile.valid === true &&
      email_id.valid === true &&
      region.valid === true &&
      area.valid === true &&
      so.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ dealership_Details: { controls, isFormValid } });
    return isFormValid;
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state.dealership_Details;
    if (controlName === "pincode") {
      if (!isNaN(e.target.value)) {
        controls[controlName].value = controlValue;
      }
    } else {
      controls[controlName].value = controlValue;
    }

    controls[controlName].touched = true;
    if (controlName === "outlet_type" && controlValue === "Xpress Wheel") {
      controls.category.value = "";
    }

    if (controlName === "outlet_type") {
      this.props.updateOutletType(controlValue);
    }
    this.setState({ controls }, () => {
      if (controlName === "proposal_type") {
        this.props.resetData();
      }
    });
    this.handleValidation();
  };

  handleRegionChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state.dealership_Details;
    controls[controlName].value = controlValue;
    controls["area"].value = "";
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
    this.getArea(controlValue);
  };
  goToPrevPage = () => {
    this.props.goToPrevPage();
  };

  selectDealer = (suggestion) => {
    let { controls } = this.state.dealership_Details;
    controls.name.value = [suggestion];
    controls.name.touched = true;
    this.setState(
      {
        controls,
      },
      () => {
        this.props.getPrposalById(suggestion.uuid);
      }
    );
    this.handleValidation();
    return suggestion.name;
  };

  soChange = (e, type) => {
    let controlValue = {};
    if (type === "change") {
      if (e && e.length > 0) {
        controlValue = e[0];
      } else {
        return;
      }
    } else {
      controlValue = {
        id: 0,
        name: e,
      };
    }
    const { controls } = this.state.dealership_Details;
    // if (controlValue && controlValue.name && controlValue.name.length > 10) {
    //   return;
    // }
    controls["so"].value = controlValue;
    controls["so"].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  enableEdit = (e) => {
    let { isEnableEdit } = this.state;
    if (isEnableEdit) {
      this.props.editDeatil(e, "submit");
    }
    isEnableEdit = !isEnableEdit;
    this.setState({
      isEnableEdit,
    });
    // let { controls } = this.state.dealership_Details;
    // controls[control_name].isDisable = !controls[control_name].isDisable;
    // this.setState({
    //   controls
    // })
  };
  onSuggestionsClearRequested = () => {
    let { controls } = this.state.dealership_Details;
    this.setState({
      dealerList: [],
      controls,
    });
  };

  renderSuggestion = (suggestion) => {
    return <span>{suggestion.name}</span>;
  };

  onChange = (event, { newValue, method }) => {
    let { controls } = this.state.dealership_Details;
    if (!newValue) {
      controls.name.value = [];
    }
    this.setState({
      value: newValue,
      controls,
    });
  };

  closeEdit = () => {
    //let { isEnableEdit } = this.state;
    this.setState({
      isEnableEdit: false,
    });
    this.props.setDetail(true);
  };
  render() {
    const { controls } = this.state.dealership_Details;
    const { value, isEnableEdit } = this.state;
    const { isEdit } = this.state;
    const inputProps = {
      placeholder: "Search using dealer name",
      value,
      onChange: this.onChange,
    };
    return (
      <>
        <Card id="form-dealership-detail">
          <CardBody>
            {/* Dealership Details */}
            {this.props.isNotFullPageViewDetail && (
              <>
                <div className="header-title">Proposal Details</div>
                <br />
              </>
            )}
            <Row className="dealership-details-title-wrapp">
              <Col xl="6" md="6" xs="6">
                <b>Dealership Details</b>
              </Col>
              <Col xl="6" md="6" xs="6" className="d-flex justify-content-end">
                {this.state.isDisplayEditBtn &&
                  (this.state.isEnableEdit ? (
                    <>
                      <span
                        className="hyper-link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.enableEdit(e)}
                      >
                        Save
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="hyper-link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.enableEdit(e)}
                      >
                        Edit
                      </span>
                    </>
                  ))}
                {this.state.isDisplayEditBtn && this.state.isEnableEdit && (
                  <>
                    &nbsp;&nbsp;
                    <span
                      className="hyper-link"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.closeEdit()}
                    >
                      Cancel
                    </span>
                  </>
                )}
                {/* &nbsp;&nbsp;
                <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.goToPrevPage()}>Back</Button> */}
              </Col>
            </Row>
            <hr style={{ marginTop: "0.5rem" }} />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Proposal Type<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="proposal_type"
                      value={controls.proposal_type.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={this.state.id}
                    >
                      <option value="" selected disabled>
                        Select Proposal Type
                      </option>
                      <option value="fresh">Fresh</option>
                      <option value="renewal">Renewal</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("proposal_type")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.proposal_type.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.TYPE_OF_PROPOSAL}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Type of Outlet<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="outlet_type"
                      value={controls.outlet_type.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                    >
                      <option value="" selected disabled>
                        Select Outlet Type
                      </option>
                      <option value="Steel Wheel">Steel Wheel</option>
                      <option value="Xpress Wheel">Xpress Wheel</option>
                      <option value="Farm Xpress Wheel">
                        Farm Xpress Wheel
                      </option>
                      <option value="PTP">PTP</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("outlet_type")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.outlet_type.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.TYPE_OF_OUTLET}
                    </div>
                  )}
                </FormGroup>
              </Col>
              {controls.outlet_type.value !== "Xpress Wheel" && (
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label for="phone">
                      Type of Category <span className="required-field">*</span>
                    </Label>
                    <div className="d-flex">
                      <select
                        className="form-control select-height-70rem"
                        name="category"
                        value={controls.category.value}
                        disabled={!isEnableEdit}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="" selected disabled>
                          Select Category
                        </option>
                        <option value="Standard">Standard</option>
                        <option value="Premium">Premium</option>
                        <option value="Super Premium">Super Premium</option>
                      </select>
                      {isEdit && (
                        <img
                          onClick={() => this.enableEdit("category")}
                          style={{ height: "14px" }}
                          className="cursor-pointer ml-2 mt-2"
                          src="images/edit.svg"
                          alt=''
                        />
                      )}
                    </div>
                    {controls.category.showErrorMsg && (
                      <div className="error">
                        * {messages.DEALERSHIP_DETAILS.CATEGORY}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              )}
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="gst">
                    Dealership Name<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex-row">
                    {controls.proposal_type.value === "renewal" ? (
                      <div className="auto-suggest">
                        <Autosuggest
                          suggestions={this.state.dealerList}
                          onSuggestionsFetchRequested={this.searchDealer}
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={this.selectDealer}
                          renderSuggestion={this.renderSuggestion}
                          inputProps={inputProps}
                        />
                        <img
                          src="images/search.svg"
                          alt=''
                          className="report-search icon-width"
                          style={{ top: "40px" }}
                        />
                      </div>
                    ) : (
                      // <Typeahead
                      //   id="selectState"
                      //   bsSize="small"
                      //   emptyLabel={''}
                      //   // filterBy={['name']}
                      //   labelKey="name"
                      //   minLength={2}
                      //   selected={controls.name.value ? [controls.name.value] : []}
                      //   disabled={controls.name.isDisable}
                      //   options={this.state.dealerList}
                      //   onChange={(e) => this.selectDealer(e, 'change')}
                      //   onInputChange={(e) => this.searchDealer(e, 'blur')}
                      //   placeholder=""
                      //   max
                      //   renderMenuItemChildren={(selectedItem) => {
                      //     return <>{selectedItem.name}</>;
                      //   }}
                      // />
                      <Input
                        type="text"
                        name="name"
                        value={controls.name.value}
                        disabled={!isEnableEdit}
                        onChange={(e) => this.handleInputChange(e)}
                        placeholder="Type here"
                      />
                    )}
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("name")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.name.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.NAME}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="pan">
                    Address<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <TextareaAutosize
                      name="address"
                      autocomplete="nofill"
                      id="address"
                      value={controls.address.value}
                      style={{ minHeight: 60, maxHeight: 70, fontSize: "14px" }}
                      disabled={!isEnableEdit}
                      className="form-control"
                      onChange={(e) =>
                        this.handleInputChange({
                          target: { name: "address", value: e.target.value },
                        })
                      }
                      placeholder=""
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("address")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.address.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.ADDRESS}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="pan">
                    State<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="state"
                      value={controls.state.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                      placeholder="Type here"
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("state")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.state.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.STATE}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="gst">
                    City/Town<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="city"
                      disabled={!isEnableEdit}
                      value={controls.city.value}
                      onChange={(e) => this.handleInputChange(e)}
                      placeholder="Type here"
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("city")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.city.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.CITY}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Pincode<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="pincode"
                      value={controls.pincode.value}
                      disabled={!isEnableEdit}
                      maxLength="6"
                      onChange={(e) => this.handleInputChange(e)}
                      placeholder="Type here"
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("pincode")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.pincode.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.PINCODE}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="pan">
                    Mobile No<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="mobile"
                      value={controls.mobile.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                      placeholder="Type here"
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("mobile")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.mobile.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.MOBILE}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Landline no</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="landline"
                      value={controls.landline.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                      placeholder="Enter No With STD Code"
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("landline")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>

              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="gst">
                    Email Id<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="email_id"
                      value={controls.email_id.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                      placeholder="Type here"
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("email_id")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.email_id.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.EMAIL_ID}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Region<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="region"
                      value={controls.region.value}
                      onChange={(e) => this.handleRegionChange(e)}
                      disabled={!isEnableEdit}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      {this.state.regionData &&
                        this.state.regionData.map((item, i) => (
                          <option value={item.uuid}>{item.name}</option>
                        ))}
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("region")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.region.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.REGION}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">
                    Area <span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      name="area"
                      value={controls.area.value}
                      disabled={!isEnableEdit}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      {this.state.areaData &&
                        this.state.areaData.map((item, i) => (
                          <option value={item.uuid}>{item.name}</option>
                        ))}
                    </select>
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("area")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {/* <Input
                    type="text"
                    name="area"
                    value={controls.area.value}
                    onChange={(e) => this.handleInputChange(e)}
                    placeholder="Type here" /> */}
                  {controls.area.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.AREA}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="pan">
                    SO Mobile Number<span className="required-field">*</span>
                  </Label>
                  {/* <select className="form-control select-height-70rem" name="so" value={controls.so.value}
                    onChange={(e) => this.handleInputChange(e)}>
                    <option value="" selected disabled>Select</option>
                    {
                      this.state.soData && this.state.soData.map((item, i) => (
                        <option value={item.uuid}>{item.name}</option>
                      ))
                    }
                  </select> */}
                  <div className="d-flex-row vehical-sku-id-search">
                    <Typeahead
                      id="selectState"
                      bsSize="small"
                      emptyLabel={""}
                      // filterBy={['name']}
                      labelKey="name"
                      minLength={2}
                      selected={controls.so.value ? [controls.so.value] : []}
                      disabled={!isEnableEdit}
                      options={this.state.soData}
                      onChange={(e) => this.soChange(e, "change")}
                      onInputChange={(e) => this.soChange(e, "blur")}
                      placeholder=""
                      max
                      renderMenuItemChildren={(selectedItem) => {
                        return <>{selectedItem.name}</>;
                      }}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("so")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                    {/* <FontAwesomeIcon className="serach-icon" icon={faSearch} color="#6e777f" /> */}
                  </div>
                  {controls.so.showErrorMsg && (
                    <div className="error">
                      * {messages.DEALERSHIP_DETAILS.SO}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {/* <Row>
             
            </Row> */}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default DealershipDetailsForm;
