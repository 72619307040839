import React, { Component } from 'react';
import CustomerManagementService from '../../services/CustomerManagementService';
import ModalService from '../../services/ModalService';
import { Row, Col, Label, Button, FormGroup } from 'reactstrap';
import { messages } from '../../constants/constant.message';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
const { ERROR_MESSAGE } = messages.COMMON;

class WhatsappEventsUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      broadcaster: [],
      individuals: [],
      groups: [],
      isLoading: false,
      selected: [],
      selectAll: 0,
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      isSelectAllEntry: false,
      counts: 0,
      display: 'All'
    }
    if (props.whatsAppFilterData && Object.keys(props.whatsAppFilterData).length > 0) {
      if (props.whatsAppFilterData.ids) {
        this.state.selected = props.whatsAppFilterData.ids;
        this.state.selectAll = this.checkSelectAll();
      }
      if (props.whatsAppFilterData.counts) {
        this.state.counts = props.whatsAppFilterData.counts;
      }
    }
  }

  componentDidMount() {
    this.getWhatsAppContacts();
  }


  getWhatsAppContacts = () => {
    this.setState({ isLoading: true });
    CustomerManagementService.getWhatsAppContacts({})
      .then(res => {
        let { broadcaster, groups, individuals } = this.state;
        broadcaster = res.data.data.broadcaster;
        groups = res.data.data.groups;
        individuals = res.data.data.individuals;
        this.setState({ isLoading: false });
        this.setState({ broadcaster, groups, individuals });
        let selectAll = this.checkSelectAll();
        this.setState({ selectAll });
      })
      .catch(e => {
        this.setState({ isLoading: false });

        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error')
        //this.setState({ isLoading: false });
      });
  }

  toggleRow = (id, target) => {
    let { selected, broadcaster, individuals, groups } = this.state;
    let index = selected.findIndex(x => x === id);
    if (target === 'broadcast') {
      let indexData = broadcaster.findIndex(x => x.id === id);
      if (index > -1) {
        selected.splice(index, 1);
        broadcaster[indexData].active = false;
      } else {
        if (selected.indexOf(id) === -1) {
          selected.push(id);
        }
        broadcaster[indexData].active = true;
      }
    } else if (target === 'groups') {
      let indexData = groups.findIndex(x => x.id === id);
      if (index > -1) {
        selected.splice(index, 1);
        groups[indexData].active = false;
      } else {
        if (selected.indexOf(id) === -1) {
          selected.push(id);
        }
        groups[indexData].active = true;
      }
    } else {
      let indexData = individuals.findIndex(x => x.id === id);
      if (index > -1) {
        selected.splice(index, 1);
        individuals[indexData].active = false;
      } else {
        if (selected.indexOf(id) === -1) {
          selected.push(id);
        }
        individuals[indexData].active = true;
      }
    }
    // let index = selected.findIndex(x => x === id);
    // let indexData = broadcaster.findIndex(x => x.id === id);
    // if (index > -1) {
    //   selected.splice(index, 1);
    //   broadcaster[indexData].active = false;
    // }
    // if (indexData === -1) {
    //   indexData = individuals.findIndex(x => x.id === id);
    //   if (index > -1) {
    //     selected.splice(index, 1);
    //     individuals[indexData].active = false;
    //   }
    // }
    // if (indexData === -1) {
    //   indexData = groups.findIndex(x => x.id === id);
    //   if (index > -1) {
    //     selected.splice(index, 1);
    //     groups[indexData].active = false;
    //   }
    // }
    let selectAll = this.checkSelectAll();
    this.setState({ selected, selectAll: selectAll, broadcaster, individuals, groups, isSelectAllEntry: false });
  }

  toggleSelectAll = () => {
    let { selected, display } = this.state;
    if (this.state.selectAll === 0 || this.state.selectAll === 2) {
      //selected = [];
      if (display === 'All' || display === 'Broadcast') {
        this.state.broadcaster.forEach(x => {
          x.active = true;
          if (selected.indexOf(x.id) === -1) {
            selected.push(x.id);
          }
        });
      }
      if (display === 'All' || display === 'Individuals') {
        this.state.individuals.forEach(x => {
          x.active = true;
          if (selected.indexOf(x.id) === -1) {
            selected.push(x.id);
          }
        });
      }

      if (display === 'All' || display === 'Groups') {
        this.state.groups.forEach(x => {
          x.active = true;
          if (selected.indexOf(x.id) === -1) {
            selected.push(x.id);
          }
        });
      }
      if (this.state.selectAll === 2) {
        this.state.selectAll = 0;
      }
    } else if (this.state.selectAll === 1) {
      if (this.state.isSelectAllEntry) {
        selected = [];
        if (display === 'All' || display === 'Broadcast') {
          this.state.broadcaster.forEach(x => {
            x.active = false;
          });
        }
        if (display === 'All' || display === 'Individuals') {
          this.state.individuals.forEach(x => {
            x.active = false;
          });
        }
        if (display === 'All' || display === 'Groups') {
          this.state.groups.forEach(x => {
            x.active = false;
          });
        }
      } else {
        if (display === 'All' || display === 'Broadcast') {
          this.state.broadcaster.forEach((x) => {
            selected.forEach((element, i) => {
              if (element === x.id) {
                x.active = false;
                selected.splice(i, 1)
              }
            });
          });
        }
        if (display === 'All' || display === 'Individuals') {
          this.state.individuals.forEach((x) => {
            selected.forEach((element, i) => {
              if (element === x.id) {
                x.active = false;
                selected.splice(i, 1)
              }
            });
          });
        }
        if (display === 'All' || display === 'Groups') {
          this.state.groups.forEach((x) => {
            selected.forEach((element, i) => {
              if (element === x.id) {
                x.active = false;
                selected.splice(i, 1)
              }
            });
          });
        }
      }


    }

    this.setState({
      selected: selected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
      isSelectAllEntry: false
    });
  }

  isItemSelected = (id) => {
    let { selected } = this.state;
    let isChecked = false;
    selected.forEach(element => {
      if (id === element) {
        isChecked = true;
      }
    });
    return isChecked;
  }

  checkSelectAll = () => {
    let { selected, broadcaster, individuals, groups } = this.state;
    let selectAll = 0;
    let perPageCountSelection = 0;
    if (selected.length > 0) {
      selected.forEach(id => {
        let index = broadcaster.findIndex(x => x.id === id);
        if (index > -1) {
          perPageCountSelection++;
        }

        index = groups.findIndex(x => x.id === id);
        if (index > -1) {
          perPageCountSelection++;
        }

        index = individuals.findIndex(x => x.id === id);
        if (index > -1) {
          perPageCountSelection++;
        }


      });
      if (perPageCountSelection === (broadcaster.length + individuals.length + groups.length)) {
        selectAll = 1;
      } else if (perPageCountSelection === 0) {
        selectAll = 0;
      } else {
        selectAll = 2;
      }
    } else {
      selectAll = 0;
    }
    return selectAll;
  }

  applyFilter = () => {
    let filterData = {
      ids: this.state.selected,
      counts: this.state.selected.length
    }
    this.props.applyFilter(filterData);
    this.props.closeSideBar(false);
  }
  resetFilter = (flag) => {
    this.props.applyFilter({});
  }


  closeSideBar = () => {
    this.props.closeSideBar(false);
  }

  handleChangeInput = async e => {
    this.setState({
      display: e.target.value
    })
  }

  render() {
    let { broadcaster, individuals, groups, display } = this.state;
    return (
      <div class="custom-drawer" id="AddEditActivity">
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        <div style={{ marginTop: '60px' }}>
          <div className="drawer-header">
            <span className="drawer-title">WhatsApp Contacts</span>
            <img className="cursor-pointer" src="images/close.svg" alt='cancel' onClick={() => this.closeSideBar(false)} />
          </div>
          <hr style={{ border: 'solid 1px #ced4da' }} />
        </div>
        <div style={{ marginTop: '5px' }}>
          <FormGroup>
            <Label for="username" style={{ marginLeft: '15px' }}>
              Filter<span className="required-field">*</span>
            </Label>
            <select
              htmlFor="username"
              className="dropbox w-50"
              onChange={this.handleChangeInput}
              name="display"
              id="display_id"
              value={this.state.display}
            >
              <option value="All" defaultChecked>
                All
              </option>
              <option value="Groups" >
                Groups
              </option>
              <option value="Broadcast" >
                Broadcast
              </option>
              <option value="Individuals" >
                Individuals
              </option>
            </select>
          </FormGroup>

        </div>

        <div class="custom-drawer-content">
          <Row className="c-top-15" style={{ marginLeft: '0px', marginRight: '0px' }}>
            <Col xl="8">
              <table className="mb-0 table-responsive tbl-about tbl-about text-align-left">
                <thead>
                  <tr style={{ backgroundColor: '#f6f6f6' }} className="customer-list-table-header">
                    <th className="border-1-5">
                      {
                        <>
                          {
                            this.state.selectAll === 2 ? <img onClick={(e) => this.toggleSelectAll()} src="images/group-16.svg" alt="" style={{ height: '18px' }} /> :
                              (this.state.selectAll === 1 ? <label className="custom-checkboax1--container">
                                <input type="checkbox" name="completed"
                                  checked={true}
                                  onChange={(e) => this.toggleSelectAll()}
                                />
                                <span className="checkmark1" />
                              </label> : <label className="custom-checkboax1--container">
                                <input type="checkbox" name="completed"
                                  checked={false}
                                  onChange={(e) => this.toggleSelectAll()}
                                />
                                <span className="checkmark1" />
                              </label>)
                          }
                        </>
                      }
                    </th>
                    <th className="text-align-left border-1-5">&nbsp;Name</th>
                    <th className="text-align-left border-1-5" style={{ padding: '7px' }}>Members</th>
                    <th className="text-align-left border-1-5" style={{ padding: '7px' }}>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (display === 'All' || display === 'Broadcast') && broadcaster.map((g, i) => (
                      <tr className={'customer-list-row'} key={i}>
                        <td className="border-1-5">
                          <label className="custom-checkboax1--container">
                            <input type="checkbox" name="main_road" checked={this.isItemSelected(g.id)}
                              onChange={() => this.toggleRow(g.id, 'broadcast')}
                            />
                            <span className="checkmark1" />
                          </label>
                        </td>
                        <td className="text-align-left border-1-5">&nbsp;&nbsp;{g.name}</td>
                        <td style={{ padding: '7px' }} className="text-align-left border-1-5">N/A</td>
                        <td style={{ padding: '7px' }} className="text-align-left border-1-5">Broadcast</td>
                      </tr>
                    ))
                  }

                  {
                    (display === 'All' || display === 'Groups') && groups.map((g, i) => (
                      <tr className={'customer-list-row'} key={i}>
                        <td className="border-1-5">
                          <label className="custom-checkboax1--container">
                            <input type="checkbox" name="main_road" checked={this.isItemSelected(g.id)}
                              onChange={() => this.toggleRow(g.id, 'groups')}
                            />
                            <span className="checkmark1" />
                          </label>
                        </td>
                        <td className="text-align-left border-1-5">&nbsp;&nbsp;{g.name}</td>
                        <td style={{ padding: '7px' }} className="text-align-left border-1-5">{g.metadata.participants.length}</td>
                        <td style={{ padding: '7px' }} className="text-align-left border-1-5">Group</td>
                      </tr>
                    ))
                  }


                  {
                    (display === 'All' || display === 'Individuals') && individuals.map((g, i) => (
                      <tr className={'customer-list-row'} key={i}>
                        <td className="border-1-5">
                          <label className="custom-checkboax1--container">
                            <input type="checkbox" name="main_road" checked={this.isItemSelected(g.id)}
                              onChange={() => this.toggleRow(g.id, 'individuals')}
                            />
                            <span className="checkmark1" />
                          </label>
                        </td>
                        <td className="text-align-left border-1-5">&nbsp;&nbsp;{g.name}</td>
                        <td style={{ padding: '7px' }} className="text-align-left border-1-5">1</td>
                        <td style={{ padding: '7px' }} className="text-align-left border-1-5">Individual</td>
                      </tr>
                    ))
                  }

                </tbody>
              </table>
              {
                broadcaster.length <= 0 && groups.length < 0 && individuals.length < 0 && <div className="no-data tbl-offtake-summary" style={{ maxWidth: '100%' }}>
                  Currently, there is no data to display
                </div>
              }
            </Col>
          </Row>
        </div>
        <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter(true)} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
            <Button
              //  disabled={!isValidForm} 
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: 'pointer' }}
              onClick={() => this.applyFilter()}
            >Apply
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default WhatsappEventsUsers;