import React, { Component } from "react";
import { Button, Row, Col, Card, CardBody } from "reactstrap";
import lodash from "lodash";
import Validation from "../../services/Validation";
import MerchandiseWalletService from "../../services/MerchandiseWalletService";
import CustomerIntractionServie from "../../services/CustomerIntractionService";
import * as Scroll from "react-scroll";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import ModalService from "../../services/ModalService";
import AddCounterPotential from "./AddCounterPotential";
import CipVehicleRelatedDetails from "./CipVehicleRelatedDetails";
import CipTyreRelatedDetails from "./CipTyreRelatedDetails";
import "./CipVehicleDetails.css";
const defaultTyreObj = {
  construction: {
    value: "",
    valid: true,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  front_or_rear: {
    value: "",
    valid: true,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  size: {
    value: "",
    valid: true,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brand: {
    value: "",
    valid: true,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brand_id: {
    value: "",
    valid: true,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  pattern: {
    value: "",
    valid: true,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  tyres: {
    value: "",
    valid: true,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brands: [],
  patterns: [],
};
class CipVehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "truck",
      tabData: [
        {
          name: "Truck",
          id: "truck",
        },
        {
          name: "Bus",
          id: "bus",
        },
        {
          name: "LCV",
          id: "lcv",
        },
        {
          name: "SCV",
          id: "scv",
        },
      ],
      vehicleReleatedData: {},
      isJKuserDeatil: false,
      isCounterPotentialError: false,
      isMinimumOneVehicleError: false,
      controls: {
        frontRearData: {
          truck: [],
          bus: [],
          lcv: [],
          scv: [],
        },
        company_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        designation: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        mobile_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        attendee_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        whatsapp_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        email: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        address: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        same_as_mobile: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        state: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        pin_code: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
      },
      vehicleData: {},
      stateData: [],
      cityData: [],
      brands: [],
      isFormValid: false,
      isLoading: false,
      isNeedTyreRelated: {
        truck: false,
        bus: false,
        lcv: false,
        icv: false,
      },
      isAddedVehicleRelated: {
        truck: true,
        bus: true,
        lcv: true,
        scv: true,
        counter_potential_detail: true,
      },
    };

    // this.state.tbrPattren = this.state.tbrPattren.jk_tyre;
    // this.state.tbbPattren = this.state.tbbPattren.jk_tyre;
    this.resetVehicleReleatedData();
  }

  resetVehicleReleatedData = () => {
    let { vehicleReleatedData } = this.state;
    vehicleReleatedData.truck = {
      headers: [
        { label: "Type", style: null },
        { label: "Count", style: null, required: true },
        { label: "Application", style: null },
        {
          label: "Payload\n(Tons)",
          style: {
            textAlign: "center",
          },
        },
        {
          label: "Avg \nMonthly Usage",
          style: {
            textAlign: "center",
            width: "20%",
          },
        },
      ],
      data: [
        {
          type: "6_wheeler",
          label: "6 wheeler",
          avg_monthly_list: [],
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          application: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          payload: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "12_wheeler",
          label: "12 wheeler",
          avg_monthly_list: [],
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          application: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          payload: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "14_wheeler",
          label: "14 wheeler",
          avg_monthly_list: [],
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          application: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          payload: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "16_wheeler",
          label: "16 wheeler",
          avg_monthly_list: [],
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          application: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          payload: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "18_wheeler",
          label: "18 wheeler",
          avg_monthly_list: [],
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          application: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          payload: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "22_wheeler",
          label: "22 wheeler",
          avg_monthly_list: [],
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          application: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          payload: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
      ],
    };
    vehicleReleatedData.bus = {
      headers: [
        { label: "Type", style: "" },
        { label: "Count", style: "" },
        { label: "Avg Monthly Usage", style: "" },
      ],
      data: [
        {
          type: "two_axles",
          label: "Two axles",
          avg_monthly_list: [
            "< 5k",
            "5k - 6k",
            "6k - 7k",
            "7k - 8k",
            "8k - 9k",
            "9k - 10k",
            "> 10k ",
          ],
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            style: { maxWidth: "100px" },
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "multi_axles",
          label: "Multi axles",
          avg_monthly_list: [
            "< 5k",
            "5k - 6k",
            "6k - 7k",
            "7k - 8k",
            "8k - 9k",
            "9k - 10k",
            "> 10k ",
          ],
          count: {
            value: "",
            valid: null,
            touched: false,
            style: { maxWidth: "100px" },
            nullValue: null,
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
      ],
    };
    vehicleReleatedData.lcv = {
      headers: [
        { label: "Type", style: "" },
        { label: "Count", style: "", required: true },
        { label: "Avg.Monthly Usage", style: "" },
      ],
      data: [
        {
          type: "4_wheeler",
          label: "4 wheeler",
          avg_monthly_list: [
            "< 5k",
            "5k - 6k",
            "6k - 7k",
            "7k - 8k",
            "8k - 9k",
            "9k - 10k",
            "> 10k ",
          ],
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            style: { maxWidth: "100px" },
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
      ],
    };
    vehicleReleatedData.scv = {
      headers: [
        { label: "Type", style: "" },
        { label: "Count", style: "", required: true },
        { label: "Avg.Monthly Usage", style: "" },
      ],
      data: [
        {
          type: "4_wheeler",
          label: "4 wheeler",
          avg_monthly_list: [
            "< 5k",
            "5k - 6k",
            "6k - 7k",
            "7k - 8k",
            "8k - 9k",
            "9k - 10k",
            "> 10k ",
          ],
          count: {
            value: "",
            style: { maxWidth: "100px" },
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
          average_monthly_usage: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
      ],
    };
    vehicleReleatedData.counter_potential_detail = {
      headers: [
        { label: "Type", style: null },
        {
          label: "Tyre Offtake per Month",
          style: {
            textAlign: "center",
          },
          required: true,
        },
      ],
      data: [
        {
          type: "2/3 wheeler",
          label: "2/3 Wheeler",
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "pcr",
          label: "PCR",
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "scv",
          label: "SCV",
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "lcv",
          label: "LCV",
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
        {
          type: "farm",
          label: "Farm",
          count: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            showErrorMsg: false,
          },
        },
      ],
    };
    this.setState({
      vehicleReleatedData,
    });
  };

  handleValidationVehicleRelatedDetails = (
    e,
    key = "",
    i,
    isSubmit = false
  ) => {
    let {
      vehicleReleatedData,
      isNeedTyreRelated,
      controls,
      isAddedVehicleRelated,
      isMinimumOneVehicleError,
    } = this.state;
    const { frontRearData } = controls;
    let count_error = 0;
    if (key !== "" && !isSubmit) {
      //isMinimumOneVehicleError = false;
      if (key === "Truck") {
        if (
          ((vehicleReleatedData[key].data[i].hasOwnProperty("application") &&
            !Validation.notNullValidator(
              vehicleReleatedData[key].data[i].application
            ).nullValue) ||
            (vehicleReleatedData[key].data[i].hasOwnProperty("payload") &&
              !Validation.notNullValidator(
                vehicleReleatedData[key].data[i].payload
              ).nullValue) ||
            (vehicleReleatedData[key].data[i].hasOwnProperty(
              "average_monthly_usage"
            ) &&
              !Validation.notNullValidator(
                vehicleReleatedData[key].data[i].average_monthly_usage
              ).nullValue)) &&
          Validation.notNullValidator(vehicleReleatedData[key].data[i].count)
            .nullValue &&
          vehicleReleatedData[key].data[i].count.touched === true
        ) {
          vehicleReleatedData[key].data[i].count = Validation.notNullValidator(
            vehicleReleatedData[key].data[i].count
          );
          if (vehicleReleatedData[key].data[i].count.nullValue) {
            vehicleReleatedData[key].data[i].count.showErrorMsg = true;
            count_error++;
          } else {
            vehicleReleatedData[key].data[i].count.showErrorMsg = false;
          }
        } else {
          vehicleReleatedData[key].data[i].count.showErrorMsg = false;
        }
      } else {
        if (
          vehicleReleatedData[key].data[i].hasOwnProperty(
            "average_monthly_usage"
          ) &&
          !Validation.notNullValidator(
            vehicleReleatedData[key].data[i].average_monthly_usage
          ).nullValue &&
          Validation.notNullValidator(vehicleReleatedData[key].data[i].count)
            .nullValue
        ) {
          vehicleReleatedData[key].data[i].count = Validation.notNullValidator(
            vehicleReleatedData[key].data[i].count
          );
          if (!vehicleReleatedData[key].data[i].count.nullValue) {
            vehicleReleatedData[key].data[i].count.showErrorMsg = true;
            count_error++;
          } else {
            vehicleReleatedData[key].data[i].count.showErrorMsg = false;
          }
        } else {
          vehicleReleatedData[key].data[i].count.showErrorMsg = false;
        }
      }
      //  isAddedVehicleRelated[key] = true;
    } else {
      Object.keys(vehicleReleatedData).forEach((key) => {
        if (key) {
          let count_wheel = 0;
          vehicleReleatedData[key].data.forEach((element) => {
            if (key === "Truck") {
              if (
                ((element.hasOwnProperty("application") &&
                  !Validation.notNullValidator(element.application)
                    .nullValue) ||
                  (element.hasOwnProperty("payload") &&
                    !Validation.notNullValidator(element.payload).nullValue) ||
                  (element.hasOwnProperty("average_monthly_usage") &&
                    !Validation.notNullValidator(element.average_monthly_usage)
                      .nullValue)) &&
                Validation.notNullValidator(element.count).nullValue
              ) {
                element.count = Validation.notNullValidator(element.count);
                if (element.count.nullValue) {
                  element.count.showErrorMsg = true;
                  count_error++;
                } else {
                  element.count.showErrorMsg = false;
                }
              } else {
                count_wheel++;
                element.count.showErrorMsg = false;
              }

              if (element.count.value) {
                count_wheel--;
                if (frontRearData[key] && frontRearData[key].length === 0) {
                  isNeedTyreRelated[key] = true;
                  this.scrollTo("isNeedTyreRelated");
                  this.scrollTo("isNeedTyreRelated");
                } else {
                  isNeedTyreRelated[key] = false;
                }
                //  isAddedVehicleRelated[key] = true;
              }

              if (count_wheel === vehicleReleatedData[key].data.length) {
                isNeedTyreRelated[key] = false;
                if (frontRearData[key] && frontRearData[key].length > 0) {
                  //isAddedVehicleRelated[key] = false;
                  this.scrollTo("isAddedVehicleRelated");
                  this.scrollTo("isAddedVehicleRelated");
                }
              }
            } else {
              if (
                element.hasOwnProperty("average_monthly_usage") &&
                !Validation.notNullValidator(element.average_monthly_usage)
                  .nullValue &&
                Validation.notNullValidator(element.count).nullValue
              ) {
                element.count = Validation.notNullValidator(element.count);
                if (!element.count.nullValue) {
                  element.count.showErrorMsg = true;
                  count_error++;
                } else {
                  element.count.showErrorMsg = false;
                }
              } else {
                count_wheel++;
                element.count.showErrorMsg = false;
              }

              if (element.count.value) {
                count_wheel--;
                if (frontRearData[key] && frontRearData[key].length === 0) {
                  isNeedTyreRelated[key] = true;
                  this.scrollTo("isNeedTyreRelated");
                  this.scrollTo("isNeedTyreRelated");
                } else {
                  isNeedTyreRelated[key] = false;
                  //  isAddedVehicleRelated[key] = true;
                }
              }
              if (count_wheel === vehicleReleatedData[key].data.length) {
                isNeedTyreRelated[key] = false;
                if (frontRearData[key] && frontRearData[key].length > 0) {
                  //   isAddedVehicleRelated[key] = false;
                  this.scrollTo("isAddedVehicleRelated");
                  this.scrollTo("isAddedVehicleRelated");
                }
              }
            }
          });
        }
      });
    }

    if (isSubmit) {
      // isMinimumOneVehicleError = true;
      Object.keys(vehicleReleatedData).forEach((key) => {
        let filter = vehicleReleatedData[key].data.filter(
          (x) => !Validation.notNullValidator(x.count).nullValue
        );
        if (filter && filter.length > 0) {
          isMinimumOneVehicleError = false;
        }
      });

      if (isMinimumOneVehicleError) {
        this.scrollTo("isMinimumOneVehicleError");
      }
    }

    this.setState({
      vehicleReleatedData,
      isNeedTyreRelated,
      isAddedVehicleRelated,
      isMinimumOneVehicleError,
    });

    let isChangedTab = false;
    if (
      isAddedVehicleRelated[this.state.activeTab] ||
      !isNeedTyreRelated[this.state.activeTab]
    ) {
      if (!isAddedVehicleRelated.truck || isNeedTyreRelated.truck) {
        isChangedTab = true;
        this.onChangeCipTAB("truck");
      }
      if (
        (!isAddedVehicleRelated.bus || isNeedTyreRelated.bus) &&
        !isChangedTab
      ) {
        isChangedTab = true;
        this.onChangeCipTAB("bus");
      }
      if (
        (!isAddedVehicleRelated.lcv || isNeedTyreRelated.lcv) &&
        !isChangedTab
      ) {
        isChangedTab = true;
        this.onChangeCipTAB("lcv");
      }
      if (
        (!isAddedVehicleRelated.scv || isNeedTyreRelated.scv) &&
        !isChangedTab
      ) {
        isChangedTab = true;
        this.onChangeCipTAB("scv");
      }
    }

    return (
      count_error === 0 &&
      !isNeedTyreRelated.truck &&
      !isNeedTyreRelated.bus &&
      !isNeedTyreRelated.lcv &&
      !isNeedTyreRelated.scv &&
      isAddedVehicleRelated.truck &&
      isAddedVehicleRelated.bus &&
      isAddedVehicleRelated.lcv &&
      isAddedVehicleRelated.scv &&
      !isMinimumOneVehicleError
    );
  };

  setDefaultOneTyres = () => {
    const { controls } = this.state;
    if (controls.frontRearData.truck.length <= 0) {
      this.addFrontTyreObject("truck");
      this.addRearTyreObject("truck");
    } else {
      if (
        controls.frontRearData.truck.findIndex(
          (x) => x.front_or_rear.value === "front_data"
        ) <= 0
      ) {
        this.addFrontTyreObject("truck");
      }
      if (
        controls.frontRearData.truck.findIndex(
          (x) => x.front_or_rear.value === "rear_data"
        ) <= 0
      ) {
        this.addRearTyreObject("truck");
      }
    }
    if (controls.frontRearData.bus.length <= 0) {
      this.addFrontTyreObject("bus");
      this.addRearTyreObject("bus");
    } else {
      if (
        controls.frontRearData.bus.findIndex(
          (x) => x.front_or_rear.value === "front_data"
        ) <= 0
      ) {
        this.addFrontTyreObject("bus");
      }
      if (
        controls.frontRearData.bus.findIndex(
          (x) => x.front_or_rear.value === "rear_data"
        ) <= 0
      ) {
        this.addRearTyreObject("bus");
      }
    }
    if (controls.frontRearData.lcv.length <= 0) {
      this.addFrontTyreObject("lcv");
      this.addRearTyreObject("lcv");
    } else {
      if (
        controls.frontRearData.lcv.findIndex(
          (x) => x.front_or_rear.value === "front_data"
        ) <= 0
      ) {
        this.addFrontTyreObject("lcv");
      }
      if (
        controls.frontRearData.lcv.findIndex(
          (x) => x.front_or_rear.value === "rear_data"
        ) <= 0
      ) {
        this.addRearTyreObject("lcv");
      }
    }
    if (controls.frontRearData.scv.length <= 0) {
      this.addFrontTyreObject("scv");
      this.addRearTyreObject("scv");
    } else {
      if (
        controls.frontRearData.scv.findIndex(
          (x) => x.front_or_rear.value === "front_data"
        ) <= 0
      ) {
        this.addFrontTyreObject("scv");
      }
      if (
        controls.frontRearData.scv.findIndex(
          (x) => x.front_or_rear.value === "rear_data"
        ) <= 0
      ) {
        this.addRearTyreObject("scv");
      }
    }
    this.setState({
      controls,
    });
  };

  componentDidMount() {
    if (this.props.is_sub_dealer) {
      this.setState({
        activeTab: "counter_potential_detail",
      });
    }
    this.getTruckApm();
    if (
      this.props.cip_detail &&
      Object.keys(this.props.cip_detail).length === 0
    ) {
      this.setDefaultOneTyres();
      // const { controls } = this.state;
      // if (controls.frontRearData.truck.length <= 0) {
      //   this.addFrontTyreObject('truck');
      //   this.addRearTyreObject('truck');
      // }
    }
  }

  setVehicleReleatedData = (data) => {
    let { vehicleReleatedData } = this.state;

    Object.keys(data.vehicle_detail).forEach((key) => {
      data.vehicle_detail[key].forEach((element) => {
        let index = vehicleReleatedData[key].data.findIndex(
          (x) => x.type === element.type
        );
        if (element.count) {
          if (index > -1) {
            vehicleReleatedData[key].data[index].count.value = element.count;
          }
        }
        if (element.payload) {
          if (index > -1) {
            vehicleReleatedData[key].data[index].payload.value =
              element.payload;
          }
        }
        if (element.application) {
          if (index > -1) {
            vehicleReleatedData[key].data[index].application.value =
              element.application;
          }
        }
        if (element.average_monthly_usage) {
          if (index > -1) {
            vehicleReleatedData[key].data[index].average_monthly_usage.value =
              element.average_monthly_usage;
          }
        }
      });
    });

    if (this.props.is_sub_dealer) {
      if (
        data.counter_potential_detail &&
        Array.isArray(data.counter_potential_detail) &&
        data.counter_potential_detail.length > 0
      ) {
        data.counter_potential_detail.forEach((element) => {
          let index = vehicleReleatedData[
            "counter_potential_detail"
          ].data.findIndex(
            (x) => x.type === (element.type && element.type.toLowerCase())
          );
          if (element.count && index > -1) {
            vehicleReleatedData["counter_potential_detail"].data[
              index
            ].count.value = element.count;
          }
        });
      }
    }

    this.setState({
      vehicleReleatedData,
    });
  };

  setData = (data) => {
    this.setVehicleReleatedData(data);
    if (
      data.business_related_detail &&
      Object.keys(data.business_related_detail).length > 0
    ) {
      if (
        data.business_related_detail &&
        Object.keys(data.business_related_detail).length > 0
      ) {
        if (
          data.business_related_detail.truck &&
          data.business_related_detail.truck.length > 0
        ) {
          this.setFrontRearData("truck", data.business_related_detail);
        }
        if (
          data.business_related_detail.bus &&
          data.business_related_detail.bus.length > 0
        ) {
          this.setFrontRearData("bus", data.business_related_detail);
        }
        if (
          data.business_related_detail.lcv &&
          data.business_related_detail.lcv.length > 0
        ) {
          this.setFrontRearData("lcv", data.business_related_detail);
        }
        if (
          data.business_related_detail.scv &&
          data.business_related_detail.scv.length > 0
        ) {
          this.setFrontRearData("scv", data.business_related_detail);
        }
      }
    }
    this.setDefaultOneTyres();
  };

  getCIPDetailById = () => {
    this.setState({ isLoading: true });
    let { controls } = this.state;
    let reqData = { uuid: this.props.cip_uuid };
    if (controls.mobile_number.value) {
      reqData = { mobile_number: controls.mobile_number.value };
    }
    CustomerIntractionServie.getCIPDetailById(reqData)
      .then((data) => {
        if (data.data.data && Object.keys(data.data.data).length > 0) {
          let { controls, vehicleData } = this.state;
          controls.mobile_number.value = data.data.data.mobile_number;
          controls.email.value = data.data.data.email;
          controls.company_name.value = data.data.data.company_name;
          controls.attendee_name.value = data.data.data.attendee_name;
          controls.designation.value = data.data.data.designation;
          controls.whatsapp_number.value = data.data.data.whatsapp_number;
          controls.state.value = data.data.data.state_id;
          controls.city.value = data.data.data.city_id;
          controls.pin_code.value = data.data.data.pin_code;
          controls.address.value = data.data.data.address;
          if (
            data.data.data.vehicle_detail &&
            Object.keys(data.data.data.vehicle_detail).length > 0
          ) {
            let totalCountBus = lodash.sumBy(
              data.data.data.vehicle_detail.bus,
              function (o) {
                return o.count ? +o.count : 0;
              }
            );
            let totalCountTruck = lodash.sumBy(
              data.data.data.vehicle_detail.truck,
              function (o) {
                return o.count ? +o.count : 0;
              }
            );
            let totalCountScv = lodash.sumBy(
              data.data.data.vehicle_detail.scv,
              function (o) {
                return o.count ? +o.count : 0;
              }
            );
            let totalCountLcv = lodash.sumBy(
              data.data.data.vehicle_detail.lcv,
              function (o) {
                return o.count ? +o.count : 0;
              }
            );
            vehicleData = {
              totalCountBus: totalCountBus,
              totalCountTruck: totalCountTruck,
              totalCountLcv: totalCountLcv,
              totalCountScv: totalCountScv,
              vehicles: data.data.data.vehicle_detail,
            };
          }
          if (
            data.data.data.business_related_detail &&
            Object.keys(data.data.data.business_related_detail).length > 0
          ) {
            if (
              data.data.data.business_related_detail &&
              Object.keys(data.data.data.business_related_detail).length > 0
            ) {
              if (
                data.data.data.business_related_detail.truck &&
                data.data.data.business_related_detail.truck.length > 0
              ) {
                this.setFrontRearData(
                  "truck",
                  data.data.data.business_related_detail
                );
              }
              if (
                data.data.data.business_related_detail.bus &&
                data.data.data.business_related_detail.bus.length > 0
              ) {
                this.setFrontRearData(
                  "bus",
                  data.data.data.business_related_detail
                );
              }
              if (
                data.data.data.business_related_detail.lcv &&
                data.data.data.business_related_detail.lcv.length > 0
              ) {
                this.setFrontRearData(
                  "lcv",
                  data.data.data.business_related_detail
                );
              }
              if (
                data.data.data.business_related_detail.scv &&
                data.data.data.business_related_detail.scv.length > 0
              ) {
                this.setFrontRearData(
                  "scv",
                  data.data.data.business_related_detail
                );
              }
            }
          }
          this.setState({
            controls,
            vehicleData,
          });
          this.getCity();
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  setFrontRearData = (key, data) => {
    let { controls } = this.state;
    let { frontRearData } = controls;
    data[key].forEach((element, i) => {
      frontRearData[key].push(JSON.parse(JSON.stringify(defaultTyreObj)));

      frontRearData[key][i].front_or_rear.value = element.front_or_rear
        ? element.front_or_rear
        : "front_data";
      frontRearData[key][i].construction.value = element.construction
        ? element.construction
        : "";
      frontRearData[key][i].size.value = element.size ? element.size : "";
      frontRearData[key][i].brand.value = element.brand ? element.brand : "";
      if (element.brand_id) {
        this.changeFrontBrand(element.brand_id, i, key);
        frontRearData[key][i].brand_id.value = element.brand_id
          ? element.brand_id
          : null;
      }
      if (element.construction) {
        this.getBrand(element.construction, i, key, true);
      }
      frontRearData[key][i].pattern.value = element.pattern
        ? element.pattern
        : "";
      frontRearData[key][i].tyres.value = element.tyres ? element.tyres : "";
    });
    this.setState({
      controls,
    });
  };

  // setRearData = (key, data) => {
  //   let { controls } = this.state;
  //   let { rearData } = controls;
  //   data[key].forEach((element, i) => {
  //     rearData[key].push(JSON.parse(JSON.stringify(defaultTyreObj)));
  //     rearData[key][i].construction.value = element.construction
  //       ? element.construction
  //       : "";
  //     rearData[key][i].size.value = element.size ? element.size : "";
  //     rearData[key][i].brand.value = element.brand ? element.brand : "";
  //     if (element.brand) {
  //       this.changeRearBrand(element.brand, i, key);
  //     }
  //     if (element.construction) {
  //       this.getBrand(element.construction, i, key, false);
  //     }
  //     rearData[key][i].pattern.value = element.pattern ? element.pattern : "";
  //     rearData[key][i].tyres.value = element.tyres ? element.tyres : "";
  //   });
  //   this.setState({
  //     controls,
  //   });
  // };

  openAddTyreModal = (data) => {
    // this.refs.refConfirmationNextAtendee.toggle(data);
  };
  openAddEditVehicleModal = (type) => {
    this.refs.refCustomerIntractionAddEditVehicle.toggle(
      this.state.vehicleData,
      type
    );
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let {
      mobile_number,
      email,
      company_name,
      attendee_name,
      designation,
      whatsapp_number,
      state,
      city,
      pin_code,
    } = controls;

    if (company_name.touched === true || isSubmit) {
      company_name = Validation.notNullValidator(company_name);
      company_name.valid = !company_name.nullValue;
      if (company_name.valid === false) {
        company_name.showErrorMsg = true;
      } else {
        company_name.showErrorMsg = false;
      }
    }

    if (attendee_name.touched === true || isSubmit) {
      attendee_name = Validation.notNullValidator(attendee_name);
      attendee_name.valid = !attendee_name.nullValue;
      if (attendee_name.valid === false) {
        attendee_name.showErrorMsg = true;
      } else {
        attendee_name.showErrorMsg = false;
      }
    }

    if (designation.touched === true || isSubmit) {
      designation = Validation.notNullValidator(designation);
      designation.valid = !designation.nullValue;
      if (designation.valid === false) {
        designation.showErrorMsg = true;
      } else {
        designation.showErrorMsg = false;
      }
    }

    if (state.touched === true || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (city.touched === true || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (pin_code.touched === true || isSubmit) {
      pin_code = Validation.notNullValidator(pin_code);
      pin_code = Validation.validateLength(pin_code, 6);
      pin_code = Validation.validateNumber(pin_code);
      pin_code.valid = !(
        pin_code.nullValue ||
        pin_code.invalidLength ||
        pin_code.invalidNumber
      );
      if (!pin_code.valid) {
        pin_code.showErrorMsg = true;
      } else {
        pin_code.showErrorMsg = false;
      }
    }

    if (mobile_number.touched === true || isSubmit) {
      mobile_number = Validation.notNullValidator(mobile_number);
      if (!mobile_number.nullValue) {
        mobile_number = Validation.validatePhoneNumber(mobile_number);
      }
      mobile_number.valid = !(
        mobile_number.nullValue || mobile_number.invalidPhone
      );
      if (mobile_number.valid === false) {
        mobile_number.showErrorMsg = true;
      } else {
        mobile_number.showErrorMsg = false;
      }
    }

    if (whatsapp_number.touched === true || isSubmit) {
      whatsapp_number = Validation.validatePhoneNumber(whatsapp_number);
      whatsapp_number.valid = !whatsapp_number.invalidPhone;
      if (whatsapp_number.valid === false) {
        whatsapp_number.showErrorMsg = true;
      } else {
        whatsapp_number.showErrorMsg = false;
      }
    }

    email.valid = true;
    if (email.touched === true || isSubmit) {
      if (
        email.value === "" ||
        email.value === null ||
        email.value === undefined
      ) {
        email.valid = true;
        email.showErrorMsg = false;
      } else {
        email = Validation.emailValidator(email);
        email.valid = !email.invalidEmail;
        if (email.valid === false) {
          email.showErrorMsg = true;
        } else {
          email.showErrorMsg = false;
        }
      }
    }

    if (
      email.valid === true &&
      mobile_number.valid === true &&
      attendee_name.valid === true &&
      designation.valid === true &&
      whatsapp_number.valid === true &&
      state.valid === true &&
      city.valid === true &&
      pin_code.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleValidationFrontData = (key = "", isSubmit = false) => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    for (
      let i = 0;
      i < (frontRearData[key] ? frontRearData[key].length : [].length);
      i++
    ) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "front_data") {
        if (
          currentData.construction.value ||
          currentData.brand.value ||
          currentData.pattern.value ||
          currentData.tyres.value ||
          currentData.size.value
        ) {
          if (currentData.construction.touched === true || isSubmit) {
            currentData.construction = Validation.notNullValidator(
              currentData.construction
            );
            currentData.construction.valid = !!currentData.construction.value;
          }

          if (currentData.size.touched === true || isSubmit) {
            currentData.size = Validation.notNullValidator(currentData.size);
            currentData.size.valid = !!currentData.size.value;
          }

          if (currentData.brand.touched === true || isSubmit) {
            currentData.brand = Validation.notNullValidator(currentData.brand);
            currentData.brand.valid = !!currentData.brand.value;
          }

          if (currentData.pattern.touched === true || isSubmit) {
            currentData.pattern = Validation.notNullValidator(
              currentData.pattern
            );
            currentData.pattern.valid = !!currentData.pattern.value;
          }

          if (currentData.tyres.touched === true || isSubmit) {
            currentData.tyres = Validation.notNullValidator(currentData.tyres);
            currentData.tyres.valid = !!currentData.tyres.value;
          }
        } else {
          currentData.brand.valid = true;
          currentData.pattern.valid = true;
          currentData.size.valid = true;
          currentData.tyres.valid = true;
        }
      }
    }
    let isTyreDetailValid = true;
    for (
      let i = 0;
      i < (frontRearData[key] ? frontRearData[key].length : [].length);
      i++
    ) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "front_data") {
        isTyreDetailValid =
          isTyreDetailValid &&
          currentData.construction.valid &&
          currentData.size.valid &&
          currentData.brand.valid &&
          currentData.pattern.valid &&
          currentData.tyres.valid;
      }
    }
    let isValidForm = false;
    if (isTyreDetailValid) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({ controls });
    return isValidForm;
  };

  handleValidationRearData = (key = "", isSubmit = false) => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    for (
      let i = 0;
      i < (frontRearData[key] ? frontRearData[key].length : [].length);
      i++
    ) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "rear_data") {
        if (
          currentData.construction.value ||
          (currentData.brand.value && currentData.pattern.value) ||
          currentData.tyres.value ||
          currentData.size.value
        ) {
          if (currentData.construction.touched === true || isSubmit) {
            currentData.construction = Validation.notNullValidator(
              currentData.construction
            );
            currentData.construction.valid = !!currentData.construction.value;
          }

          if (currentData.size.touched === true || isSubmit) {
            currentData.size = Validation.notNullValidator(currentData.size);
            currentData.size.valid = !!currentData.size.value;
          }

          if (currentData.brand.touched === true || isSubmit) {
            currentData.brand = Validation.notNullValidator(currentData.brand);
            currentData.brand.valid = !!currentData.brand.value;
          }

          if (currentData.pattern.touched === true || isSubmit) {
            currentData.pattern = Validation.notNullValidator(
              currentData.pattern
            );
            currentData.pattern.valid = !!currentData.pattern.value;
          }

          if (currentData.tyres.touched === true || isSubmit) {
            currentData.tyres = Validation.notNullValidator(currentData.tyres);
            currentData.tyres.valid = !!currentData.tyres.value;
          }
        } else {
          currentData.brand.valid = true;
          currentData.pattern.valid = true;
          currentData.size.valid = true;
          currentData.tyres.valid = true;
        }
      }
    }
    let isTyreDetailValid = true;
    for (
      let i = 0;
      i < (frontRearData[key] ? frontRearData[key].length : [].length);
      i++
    ) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "rear_data") {
        isTyreDetailValid =
          isTyreDetailValid &&
          currentData.construction.valid &&
          currentData.size.valid &&
          currentData.brand.valid &&
          currentData.pattern.valid &&
          currentData.tyres.valid;
      }
    }
    let isValidForm = false;
    if (isTyreDetailValid) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({ controls });
    return isValidForm;
  };

  handleValidationVehicleRelatedDetailsCounterPotential = (
    e,
    key = "",
    i,
    isSubmit = false
  ) => {
    let { vehicleReleatedData } = this.state;
    let is_added_any_one_count = false;
    if (key !== "" && !isSubmit) {
      if (
        Validation.notNullValidator(
          vehicleReleatedData.counter_potential_detail.data[i].count
        ).nullValue &&
        vehicleReleatedData[key].data[i].count.touched === true
      ) {
        vehicleReleatedData[key].data[i].count = Validation.notNullValidator(
          vehicleReleatedData[key].data[i].count
        );
        if (vehicleReleatedData[key].data[i].count.nullValue) {
          vehicleReleatedData[key].data[i].count.showErrorMsg = true;
        } else {
          is_added_any_one_count = true;
          vehicleReleatedData[key].data[i].count.showErrorMsg = false;
        }
      } else {
        vehicleReleatedData[key].data[i].count.showErrorMsg = false;
        is_added_any_one_count = true;
      }
    } else {
      vehicleReleatedData.counter_potential_detail.data.forEach((element) => {
        if (Validation.notNullValidator(element.count).nullValue) {
          element.count = Validation.notNullValidator(element.count);
          if (element.count.nullValue) {
            element.count.showErrorMsg = true;
          } else {
            element.count.showErrorMsg = false;
            is_added_any_one_count = true;
          }
        } else {
          element.count.showErrorMsg = false;
          is_added_any_one_count = true;
        }
      });
    }

    this.setState({
      vehicleReleatedData,
      isCounterPotentialError: !is_added_any_one_count,
    });
    return is_added_any_one_count;
  };

  handleInputChange = async (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (controlName === "same_as_mobile") {
      controls.same_as_mobile.value = !controls.same_as_mobile.value;
      if (
        !controls.mobile_number.showErrorMsg &&
        controls.mobile_number.value &&
        controls.same_as_mobile.value
      ) {
        controls.whatsapp_number.value = controls.mobile_number.value;
        controls.same_as_mobile.value = true;
      } else {
        controls.whatsapp_number.value = "";
        controls.same_as_mobile.value = false;
      }
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    await this.setState({ controls });
    this.handleValidation();
    if (controlName === "state") {
      this.getCity();
    }
  };

  handleOnBlurMobile = () => {
    let { controls } = this.state;
    if (controls.mobile_number.valid) {
      if (controls.same_as_mobile.value) {
        controls.whatsapp_number.value = controls.mobile_number.value;
      }
      this.getCIPDetailById();
    }
  };

  resetData = () => {
    this.resetVehicleReleatedData();
    let { controls } = this.state;
    controls.frontRearData = {
      truck: [],
      bus: [],
      lcv: [],
      scv: [],
    };
    this.setState({
      controls,
    });
  };

  // checkAllDataValidationFront = () => {
  //   let frontTruck = true;
  //   let frontBus = true;
  //   let frontLCV = true,
  //     frontSCV = true;

  //   if (!this.handleValidationFrontData("truck", true)) {
  //     frontTruck = false;
  //   }
  //   if (!this.handleValidationFrontData("bus", true)) {
  //     frontBus = false;
  //   }
  //   if (!this.handleValidationFrontData("lcv", true)) {
  //     frontLCV = false;
  //   }
  //   if (!this.handleValidationFrontData("scv", true)) {
  //     frontSCV = false;
  //   }

  //   return !!(
  //     frontTruck === true &&
  //     frontBus === true &&
  //     frontSCV === true &&
  //     frontLCV === true
  //   );
  // };

  // checkAllDataValidationRear = () => {
  //   let frontTruck = true;
  //   let frontBus = true;
  //   let frontLCV = true,
  //     frontSCV = true;
  //   if (!this.handleValidationRearData("truck", true)) {
  //     frontTruck = false;
  //   }
  //   if (!this.handleValidationRearData("bus", true)) {
  //     frontBus = false;
  //   }
  //   if (!this.handleValidationRearData("lcv", true)) {
  //     frontLCV = false;
  //   }
  //   if (!this.handleValidationRearData("scv", true)) {
  //     frontSCV = false;
  //   }
  //   return !!(
  //     frontTruck === true &&
  //     frontBus === true &&
  //     frontSCV === true &&
  //     frontLCV === true
  //   );
  // };

  getReqFrontData = () => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    let resFrontData = {
      bus: [],
      truck: [],
      lcv: [],
      scv: [],
    };
    Object.keys(frontRearData).forEach((key, i) => {
      frontRearData[key].forEach((element) => {
        resFrontData[key].push({
          construction: element.construction.value,
          front_or_rear: element.front_or_rear.value,
          size: element.size.value,
          brand: element.brand.value,
          pattern: element.pattern.value,
          tyres: element.tyres.value ? element.tyres.value : "",
          brand_id: element.brand_id.value ? element.brand_id.value : "",
        });
      });
    });
    return resFrontData;
  };

  submitDetail = () => {
    this.props.submitDetail();
  };
  changeRearBrand = (brand, index, key) => {
    const { controls } = this.state;
    const { rearData } = controls;
    const tyreObj = rearData[key][index];
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrandPattern({ brand_id: brand })
      .then((res) => {
        tyreObj.pattern = res.data.data.pattern;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  changeFrontBrand = (brand, index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    const tyreObj = frontRearData[key][index];
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrandPattern({ brand_id: brand })
      .then((res) => {
        tyreObj.patterns = res.data.data.pattern;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  onChangeVehicle = (e, type, i) => {
    let controlName = e.target.name;
    let controlValue = e.target.value;
    let { vehicleReleatedData } = this.state;

    if (controlName === "count" || controlName === "payload") {
      if (isNaN(controlValue)) {
        return;
      }
    }
    vehicleReleatedData[type].data[i][controlName].value = controlValue;
    vehicleReleatedData[type].data[i][controlName].touched = true;
    // if (type === "Truck") {
    //   vehicleReleatedData.Truck.data[i][controlName].value = controlValue;
    // } else if (type === "Bus") {
    //   vehicleReleatedData.Bus.data[i][controlName].value = controlValue;
    // } else if (type === "LCV") {
    //   vehicleReleatedData.LCV.data[i][controlName].value = controlValue;
    // } else if (type === "SCV") {
    //   vehicleReleatedData.SCV.data[i][controlName].value = controlValue;
    // }
    this.setState({
      vehicleReleatedData,
    });
    if (this.props.is_sub_dealer) {
      this.handleValidationVehicleRelatedDetailsCounterPotential(
        e,
        type,
        i,
        false
      );
    } else {
      this.handleValidationVehicleRelatedDetails(e, type, i, false);
    }
  };

  onChangeFrontRearTyre = (
    e,
    index,
    key,
    brands = [],
    front_rear = "front_data"
  ) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    const { frontRearData } = controls;
    if (controlName === "tyres") {
      if (isNaN(controlValue)) {
        return;
      }
    }
    const tyreObj = frontRearData[key][index];
    tyreObj[controlName].value = controlValue;
    tyreObj[controlName].touched = true;
    if (controlName === "construction") {
      tyreObj.size.value = "";
      tyreObj.brand.value = "";
      tyreObj.brand_id.value = "";
      tyreObj.pattern.value = "";
    } else if (controlName === "brand") {
      tyreObj.pattern.value = "";
    }
    if (controlName === "brand") {
      let brand = lodash.filter(brands, (element) => {
        return element.name === controlValue;
      });
      if (brand.length > 0) {
        tyreObj.brand_id.value = brand[0].id;
        this.changeFrontBrand(brand[0].id, index, key);
      } else {
        tyreObj.brand_id.value = "other";
        this.changeFrontBrand("other", index, key);
      }
    }
    if (controlName === "construction") {
      this.getBrand(controlValue, index, key, true);
    }
    this.setState({
      controls,
    });

    if (front_rear === "front_data") {
      this.handleValidationFrontData(key);
    } else {
      this.handleValidationRearData(key);
    }
  };

  onChangeRearTyre = (e, index, key) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    const { rearData } = controls;
    const tyreObj = rearData[key][index];
    if (controlName === "tyres") {
      if (isNaN(controlValue)) {
        return;
      }
    }
    tyreObj[controlName].value = controlValue;
    tyreObj[controlName].touched = true;
    if (controlName === "construction") {
      tyreObj.size.value = "";
      tyreObj.brand.value = "";
      tyreObj.pattern.value = "";
    } else if (controlName === "brand") {
      tyreObj.pattern.value = "";
    }
    if (controlName === "brand") {
      this.changeRearBrand(controlValue, index, key);
    }

    if (controlName === "construction") {
      this.getBrand(controlValue, index, key, false);
    }

    this.setState({
      controls,
    });
    this.handleValidationRearData(key);
  };

  addFrontTyreObject = (key) => {
    const { controls, isNeedTyreRelated } = this.state;
    const { frontRearData } = controls;
    let setdefaultTyreObj = defaultTyreObj;
    setdefaultTyreObj.front_or_rear.value = "front_data";
    frontRearData[key].push(JSON.parse(JSON.stringify(setdefaultTyreObj)));
    isNeedTyreRelated[key] = false;
    this.setState({ controls, isNeedTyreRelated });
  };

  removeFrontTyre = (index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    frontRearData[key].splice(index, 1);
    this.setState({ controls });
  };

  addRearTyreObject = (key) => {
    const { controls, isNeedTyreRelated } = this.state;
    const { frontRearData } = controls;
    let setdefaultTyreObj = defaultTyreObj;
    setdefaultTyreObj.front_or_rear.value = "rear_data";
    frontRearData[key].push(JSON.parse(JSON.stringify(setdefaultTyreObj)));
    isNeedTyreRelated[key] = false;
    this.setState({ controls, isNeedTyreRelated });
  };

  removeRearTyre = (index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    frontRearData[key].splice(index, 1);
    this.setState({ controls });
  };

  submitVehclies = (data) => {
    let vehicleData = lodash.cloneDeep(data);
    if (Object.keys(vehicleData).length <= 0) {
      this.setState({
        vehicleData: {},
      });
      return;
    }
    this.setState({ isLoading: true });
    let { controls } = this.state;
    let { frontRearData, rearData } = controls;
    if (vehicleData.totalCountTruck > 0) {
      if (frontRearData.truck.length === 0) {
        frontRearData.truck.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.truck[0].front_or_rear.value = "front_data";
        frontRearData.truck.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.truck[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["truck"] = [];
    }
    if (vehicleData.totalCountBus > 0) {
      if (frontRearData.bus.length === 0) {
        frontRearData.bus.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.bus[0].front_or_rear.value = "front_data";
        frontRearData.bus.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.bus[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["bus"] = [];
    }
    if (vehicleData.totalCountLcv > 0) {
      if (frontRearData.lcv.length === 0) {
        frontRearData.lcv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.lcv[0].front_or_rear.value = "front_data";
        frontRearData.lcv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.lcv[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["lcv"] = [];
    }
    if (vehicleData.totalCountScv > 0) {
      if (frontRearData.scv.length === 0) {
        frontRearData.scv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.scv[0].front_or_rear.value = "front_data";
        frontRearData.scv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.scv[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["scv"] = [];
    }
    this.setState({ isLoading: false });
    this.setState({
      vehicleData: vehicleData,
      frontRearData,
      rearData,
    });
  };

  getState = () => {
    this.setState({ isLoading: true });
    MerchandiseWalletService.getState()
      .then((data) => {
        let { stateData, controls } = this.state;
        let { data: states } = data.data;
        stateData = states;
        this.setState({ stateData, controls, isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  getCity = () => {
    let { controls } = this.state;
    this.setState({ isLoading: true });
    MerchandiseWalletService.getCity(controls.state.value)
      .then((data) => {
        let { cityData, controls } = this.state;
        const { data: cities } = data.data;
        cityData = cities;
        this.setState({ cityData, controls, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };

  scrollTo = (id) => {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80,
    });
  };

  confirmationNextAtendee = () => {
    if (!this.handleValidation(true)) {
      this.scrollTo("business-detail");
      return true;
    }

    // if (!this.checkAllDataValidationFront()) {
    //   return false;
    // }
    // if (!this.checkAllDataValidationRear()) {
    //   return false;
    // }
    this.refs.refConfirmationNextAtendee.toggle();
  };

  getTruckApm = () => {
    //this.setState({ isLoading: true });
    CustomerIntractionServie.getTruckApm()
      .then((res) => {
        let { vehicleReleatedData } = this.state;
        // MonthlyRunningData: [
        //   "< 5k",
        //   "5k - 6k",
        //   "6k - 7k",
        //   "7k - 8k",
        //   "8k - 9k",
        //   "9k - 10k",
        //   "> 10k ",
        // ],
        if (res.data.data && res.data.data.length > 0) {
          // Object.keys(vehicleReleatedData).forEach(key => {

          // });
          res.data.data.forEach((element) => {
            let index = vehicleReleatedData["truck"].data.findIndex(
              (x) => x.label === element.name
            );
            if (index > -1) {
              vehicleReleatedData["truck"].data[index].avg_monthly_list =
                element.options;
            } else {
            }
          });
        }
        // tyreObj.brands = res.data.data.brand;
        this.setState({
          vehicleReleatedData,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  getBrand = (construction, index, key, isFront) => {
    const { controls } = this.state;
    const { frontRearData, rearData } = controls;
    let tyreObj = frontRearData[key][index];
    if (!isFront) {
      tyreObj = rearData[key][index];
    }
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrand({ construction: construction })
      .then((res) => {
        tyreObj.brands = res.data.data.brand;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  onChangeCipTAB = (activeTab) => {
    this.setState({
      activeTab: activeTab,
    });
  };

  checkAllDataValidationFront = () => {
    let frontTruck = true;
    let frontBus = true;
    let frontLCV = true,
      frontSCV = true;
    if (!this.handleValidationFrontData("truck", true)) {
      frontTruck = false;
    }
    if (!this.handleValidationFrontData("bus", true)) {
      frontBus = false;
    }
    if (!this.handleValidationFrontData("lcv", true)) {
      frontLCV = false;
    }
    if (!this.handleValidationFrontData("scv", true)) {
      frontSCV = false;
    }
    return !!(
      frontTruck === true &&
      frontBus === true &&
      frontSCV === true &&
      frontLCV === true
    );
  };

  checkAllDataValidationRear = () => {
    let frontTruck = true;
    let frontBus = true;
    let frontLCV = true,
      frontSCV = true;
    if (!this.handleValidationRearData("truck", true)) {
      frontTruck = false;
    }
    if (!this.handleValidationRearData("bus", true)) {
      frontBus = false;
    }
    if (!this.handleValidationRearData("lcv", true)) {
      frontLCV = false;
    }
    if (!this.handleValidationRearData("scv", true)) {
      frontSCV = false;
    }
    return !!(
      frontTruck === true &&
      frontBus === true &&
      frontSCV === true &&
      frontLCV === true
    );
  };
  render() {
    // let { controls } = this.state;
    // let { frontRearData, rearData } = controls;
    return (
      <div>
        {this.state.isLoading && <CustomSpinner />}
        <Row>
          <Col xl="12" md="12" xs="12" className="mb-4 mt-3">
            <div className="d-flex justify-content-between">
              {!this.props.is_sub_dealer && (
                <div className="d-flex">
                  {(this.state.tabData || []).map((item, i) => (
                    <>
                      {this.state.activeTab === item.id ? (
                        <div
                          className="cip-vehicle-tab-active"
                          onClick={() => this.onChangeCipTAB(item.id)}
                        >
                          <span className="m-auto">{item.name}</span>
                        </div>
                      ) : (
                        <div
                          className="cip-vehicle-tab-in-active"
                          onClick={() => this.onChangeCipTAB(item.id)}
                        >
                          <span className="m-auto">{item.name}</span>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}
              {!this.props.is_view_only_dealils && (
                <div className="d-flex ml-auto">
                  <Button
                    className="ml-auto mt-auto btn-pill action-button font-weight-bold mb-auto"
                    color="warning"
                    style={{ width: "130px" }}
                    onClick={() => this.submitDetail()}
                  >
                    {this.props.is_new_business ? "Submit" : "Save"}
                  </Button>
                </div>
              )}
            </div>
          </Col>

          {this.props.is_sub_dealer ? (
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12" md="12" xs="12">
                      <AddCounterPotential
                        vehicleReleatedData={this.state.vehicleReleatedData}
                        onChangeVehicle={this.onChangeVehicle}
                        isAddedVehicleRelated={this.state.isAddedVehicleRelated}
                        isCounterPotentialError={
                          this.state.isCounterPotentialError
                        }
                        is_view_only_dealils={this.props.is_view_only_dealils}
                        activeTab={this.state.activeTab}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <>
              <Col xl="5" md="12" xs="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12" md="12" xs="12">
                        <CipVehicleRelatedDetails
                          vehicleReleatedData={this.state.vehicleReleatedData}
                          onChangeVehicle={this.onChangeVehicle}
                          isAddedVehicleRelated={
                            this.state.isAddedVehicleRelated
                          }
                          is_view_only_dealils={this.props.is_view_only_dealils}
                          activeTab={this.state.activeTab}
                        />
                      </Col>
                      {this.state.isMinimumOneVehicleError &&
                        this.state.isAddedVehicleRelated[
                        this.state.activeTab
                        ] && (
                          <Col xl="12" md="12" xs="12">
                            <div
                              className="error mt-3"
                              style={{ marginLeft: "21%" }}
                              id="isMinimumOneVehicleError"
                            >
                              Please enter minimum one vehicle count.
                            </div>
                          </Col>
                        )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="7" md="12" xs="12" className="pl-0">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12" md="12" xs="12" id="CipTyreRelatedDetails">
                        <CipTyreRelatedDetails
                          activeTab={this.state.activeTab}
                          onChangeFrontRearTyre={this.onChangeFrontRearTyre}
                          addFrontTyreObject={this.addFrontTyreObject}
                          removeFrontTyre={this.removeFrontTyre}
                          addRearTyreObject={this.addRearTyreObject}
                          removeRearTyre={this.removeRearTyre}
                          is_view_only_dealils={this.props.is_view_only_dealils}
                          isNeedTyreRelated={this.state.isNeedTyreRelated}
                          frontRearData={this.state.controls.frontRearData}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  }
}

export default CipVehicleDetails;
