import React, { Component, Fragment } from 'react';
import { Card, CardBody, Row, Col, Label, FormGroup, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import Switch from "react-switch";
import { SketchPicker } from 'react-color'
import DatePicker from 'react-datepicker';
import Sidebar from "react-sidebar";
import Ionicon from 'react-ionicons';

import UserService from '../../services/UserService';
import Validation from '../../services/Validation';
import BannerService from '../../services/BannerService';
import ModalService from '../../services/ModalService';

import CampaignHoUserFilter from "../../components/CampaignHoUserFilter/CampaignHoUserFilter";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import { messages } from '../../constants/constant.message';

import './CreateBanner.css';

let {
  EMPTY_TITLE,
  NO_IMAGE,
  EMPTY_BANNER_START_DATE,
  EMPTY_BANNER_END_DATE,
  SELECT_USERS,
  ENTER_WEB_URL
} = messages.BANNER;

class CreateBanner extends Component {

  state = {
    isLoading: false,
    bannerUuid: null,
    banners: [
      {
        key: 'Text',
        value: 'text'
      },
      {
        key: 'Text and Picture',
        value: 'text_picture'
      },
      {
        key: 'Picture',
        value: 'picture'
      }
    ],
    actionsRadioOption: [
      {
        key: 'In App',
        value: 'in_app'
      },
      {
        key: 'Web Link',
        value: 'web_link'
      },
    ],
    screenDropDownOption: [
      {
        action_name: 'No Action',
        action_value: ''
      }
    ],
    isTitleBold: false,
    isTitleColorPickerOpen: false,
    titleColor: '#000000',
    isDescriptionBold: false,
    isDescriptionColorPickerOpen: false,
    descriptionColor: '#000000',
    isBannerBackgroundBold: false,
    isBannerBackgroundColorPickerOpen: false,
    bannerBackgroundColor: '#ffffff',
    bannerImage: null,
    bannerImageUrl: null,
    isAddDescriptionOpen: false,
    isUserSelectFilterOpen: false,
    filterData: null,
    action: 'add',
    controls: {
      bannerTypeRadio: {
        value: 'text',
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      actionTypeRadio: {
        value: 'in_app',
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      selectScreenDropDown: {
        value: null,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      title: {
        value: null,
        count: 0,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      description: {
        value: null,
        count: 0,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      bannerStartDate: {
        value: null,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      bannerEndDate: {
        value: null,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      webUrl: {
        value: null,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      bannerImage: {
        value: null,
        link: null,
        file: null,
        count: 0,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false,
        isValidSize: false
      },
      selectTargetUsers: {
        fileName: null,
        file: null,
        count: null,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      }
    }
  }

  constructor(props) {
    super(props);
    this.bannerImageRef = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;
    const { action, bannerData = null } = location;

    if ((action === 'edit' || action === 'duplicate') && bannerData) {
      const {
        uuid, title, description, target_users, start_date, end_date, action: actionScreen, is_title_bold, title_color,
        is_description_bold, description_color, content_url, background_color, image_url, banner_type
      } = bannerData;

      let {
        controls,
        // banners, isDescriptionColorPickerOpen, isTitleColorPickerOpen, isBannerBackgroundColorPickerOpen, actionsRadioOption, screenDropDownOption,filterData,
        isTitleBold,
        isDescriptionBold, titleColor, descriptionColor, bannerBackgroundColor,
        isAddDescriptionOpen, bannerUuid
      } = this.state;
      // const { controls } = this.state;
      const {
        description: descriptionControl, title: titleControl, bannerTypeRadio, actionTypeRadio,
        selectScreenDropDown, webUrl, bannerStartDate, bannerEndDate,
        bannerImage, selectTargetUsers
      } = controls;
      if (uuid) {
        bannerUuid = uuid;
      }
      if (title) {
        titleControl.value = title;
        titleControl.count = title.length > 36 ? 36 : title.length;
        isTitleBold = is_title_bold;
        titleColor = title_color;
      }

      if (description) {
        descriptionControl.value = description;
        descriptionControl.count = description.length > 150 ? 150 : description.length;
        isDescriptionBold = is_description_bold;
        descriptionColor = description_color;
        isAddDescriptionOpen = true;
      }

      if (actionScreen) {
        selectScreenDropDown.value = actionScreen;
      }

      if (content_url) {
        webUrl.value = content_url;
        actionTypeRadio.value = 'web_link';
      }

      if (background_color) {
        bannerBackgroundColor = background_color;
      }

      if (image_url) {
        bannerImage.link = image_url;
      }

      if (banner_type) {
        bannerTypeRadio.value = banner_type;
      }

      if (target_users && action !== 'duplicate') {
        selectTargetUsers.count = target_users;
      }

      if (start_date && action !== 'duplicate') {
        bannerStartDate.value = new Date(start_date);
      }
      if (end_date && action !== 'duplicate') {
        bannerEndDate.value = new Date(end_date);
      }
      this.setState({
        controls, action, isTitleBold, titleColor, isAddDescriptionOpen,
        isDescriptionBold, descriptionColor, bannerBackgroundColor, bannerUuid
      });
    }
    this.getBannerActions();
  }
  // title color and bold methods 
  handleTitleBoldChange = (checked) => {
    this.setState({ isTitleBold: checked });
  }

  openCloseTitleColorPicker = () => {
    this.setState({ isTitleColorPickerOpen: !this.state.isTitleColorPickerOpen })
  };

  closeTitleColorPicker = () => {
    this.setState({ isTitleColorPickerOpen: false })
  };

  handleTitleColorChange = (color) => {
    this.setState({ titleColor: color.hex })
  };

  // title color and bold methods 
  handleDescriptionBoldChange = (checked) => {
    this.setState({ isDescriptionBold: checked });
  }

  openCloseDescriptionColorPicker = () => {
    this.setState({ isDescriptionColorPickerOpen: !this.state.isDescriptionColorPickerOpen })
  };

  closeDescriptionColorPicker = () => {
    this.setState({ isDescriptionColorPickerOpen: false })
  };

  handleDescriptionColorChange = (color) => {
    this.setState({ descriptionColor: color.hex })
  };

  // banner background
  openCloseBannerBackgroundColorPicker = () => {
    this.setState({ isBannerBackgroundColorPickerOpen: !this.state.isBannerBackgroundColorPickerOpen })
  };

  closeBannerBackgroundColorPicker = () => {
    this.setState({ isBannerBackgroundColorPickerOpen: false })
  };

  handleBannerBackgroundColorChange = (color) => {
    this.setState({ bannerBackgroundColor: color.hex })
  };

  handleDateChange = (controlName, e) => {
    const { controls } = this.state;
    let dateControl = controls[controlName];
    dateControl.value = e;
    dateControl.touched = true;
    this.setState({ controls });
    this.handleValidation();
  }

  handleRadioChange = (controlName, e) => {
    let { controls } = this.state;
    let radioControl = controls[controlName]
    radioControl.value = e.target.value;
    this.setState({ controls });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    let controlValue = e.target.value;
    const { controls } = this.state;
    if (controlName === 'title' && controlValue.length > 36) {
      if (!controls[controlName].value) {
        controlValue = controlValue.substring(0, 36);
      } else {
        return;
      }
    }
    if (controlName === 'description' && controlValue.length > 150) {
      if (!controls[controlName].value) {
        controlValue = controlValue.substring(0, 150);
      } else {
        return;
      }
    }
    if (controlName === 'title' || controlName === 'description') {
      controls[controlName].count = controlValue.length;
    }
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls }, () => {
      this.handleValidation();
    });
  }

  handleUploadImage = (event) => {
    const { controls } = this.state;
    const { bannerImage } = controls;

    event.preventDefault();
    const file = event.target.files[0];
    const obj = {
      file
    };
    this.setState({ isLoading: true });
    UserService.uploadImage(obj, 'banner_image')
      .then(data => {
        this.setState({ isLoading: false });
        if (data.data && data.data.data && data.data.data.imageUrl) {
          bannerImage.value = URL.createObjectURL(file);
          bannerImage.link = data.data.data.imageUrl;
          bannerImage.file = file;
          bannerImage.touched = true;
          this.setState({ controls }, () => {
            // this.handleValidation();
          });
        }
      })
      .catch(e => {
        bannerImage.value = null;
        bannerImage.link = null;
        bannerImage.file = null;
        this.setState({ controls, isLoading: false }, () => {
          this.handleValidation();
        });
      });
  }

  openDesriptionInput = () => {
    this.setState({ isAddDescriptionOpen: true });
  }

  resetBannerImage = () => {
    const { controls } = this.state;
    const { bannerImage } = controls;

    bannerImage.value = null;
    bannerImage.link = null;
    bannerImage.file = null;

    this.setState({ controls });
  }

  handleValidation = (firstTime, isSubmit = false) => {
    const { controls, action } = this.state;
    let {
      // description, selectScreenDropDown,
      title, bannerTypeRadio, actionTypeRadio,
      webUrl, bannerStartDate, bannerEndDate, bannerImage,
      selectTargetUsers
    } = controls;
    if (bannerTypeRadio.value === 'text' || bannerTypeRadio.value === 'text_picture') {
      if (firstTime === true || title.touched === true || isSubmit) {
        title = Validation.notNullValidator(title);
        // email = Validation.emailValidator(email);
        title.valid = !(title.nullValue);
        if (((title.touched || isSubmit) && title.valid === false)) {
          title.showErrorMsg = true;
        } else {
          title.showErrorMsg = false;
        }
      }
    }

    if ((bannerTypeRadio.value === 'text_picture' || bannerTypeRadio.value === 'picture')) {
      if (firstTime === true || bannerImage.touched === true || isSubmit) {
        let imageHeight = 0;
        let imageWidth = 0;
        if (this.bannerImageRef && (this.bannerImageRef.naturalHeight || this.bannerImageRef.naturalWidth)) {
          imageHeight = this.bannerImageRef.naturalHeight;
          imageWidth = this.bannerImageRef.naturalWidth;
        }
        let ratio = imageWidth / imageHeight;

        if (!bannerImage.link) {
          bannerImage.valid = false;
        } else {
          // bannerImage.valid = true;
          if (bannerTypeRadio.value === 'picture') {
            if (ratio <= 3.5 && ratio >= 2.2) {
              bannerImage.isValidSize = true;
              bannerImage.valid = true;
            } else {
              bannerImage.isValidSize = false;
              bannerImage.valid = false;
            }
          }

          if (bannerTypeRadio.value === 'text_picture') {
            if (ratio <= 1.2 && ratio >= 0.8) {
              bannerImage.isValidSize = true;
              bannerImage.valid = true;
            } else {
              bannerImage.isValidSize = false;
              bannerImage.valid = false;
            }
          }
        }
        if (((bannerImage.touched || isSubmit) && bannerImage.valid === false)) {
          bannerImage.showErrorMsg = true;
        } else {
          bannerImage.showErrorMsg = false;
        }
      }
    }

    if (actionTypeRadio.value === 'web_link') {
      if (firstTime === true || webUrl.touched === true || isSubmit) {
        webUrl = Validation.notNullValidator(webUrl);
        // email = Validation.emailValidator(email);
        webUrl.valid = !(webUrl.nullValue);
        if (((webUrl.touched || isSubmit) && webUrl.valid === false)) {
          webUrl.showErrorMsg = true;
        } else {
          webUrl.showErrorMsg = false;
        }
      }
    }

    if (firstTime === true || bannerStartDate.touched === true || isSubmit) {
      if (
        bannerStartDate.value === null ||
        bannerStartDate.value === undefined ||
        bannerStartDate.value === ""
      ) {
        bannerStartDate.nullValue = true;
      } else {
        bannerStartDate.nullValue = false;
      }
      bannerStartDate.valid = !bannerStartDate.nullValue;
      if ((isSubmit && bannerStartDate.valid === false)) {
        bannerStartDate.showErrorMsg = true;
      } else {
        bannerStartDate.showErrorMsg = false;
      }
    }

    if (firstTime === true || bannerEndDate.touched === true || isSubmit) {
      if (
        bannerEndDate.value === null ||
        bannerEndDate.value === undefined ||
        bannerEndDate.value === ""
      ) {
        bannerEndDate.nullValue = true;
      } else {
        bannerEndDate.nullValue = false;
      }
      bannerEndDate.valid = !bannerEndDate.nullValue;
      if ((isSubmit && bannerEndDate.valid === false)) {
        bannerEndDate.showErrorMsg = true;
      } else {
        bannerEndDate.showErrorMsg = false;
      }
    }

    if ((firstTime === true || selectTargetUsers.touched === true || isSubmit) && action !== 'edit') {
      if (
        selectTargetUsers.count === null &&
        selectTargetUsers.file === null
      ) {
        selectTargetUsers.valid = false;
      } else {
        selectTargetUsers.valid = true;
      }
      if ((isSubmit && selectTargetUsers.valid === false)) {
        selectTargetUsers.showErrorMsg = true;
      } else {
        selectTargetUsers.showErrorMsg = false;
      }
    }

    let isFormValid = bannerStartDate.valid && bannerEndDate.valid;
    if (action !== 'edit') {
      isFormValid = isFormValid && selectTargetUsers.valid;
    }
    if (actionTypeRadio.value === 'web_link') {
      isFormValid = isFormValid && webUrl.valid;
    }
    if ((bannerTypeRadio.value === 'text' || bannerTypeRadio.value === 'text_picture')) {
      isFormValid = isFormValid && title.valid
    } else if (bannerTypeRadio.value === 'text_picture' || bannerTypeRadio.value === 'picture') {
      isFormValid = isFormValid && bannerImage.valid
    }
    this.setState({ controls });
    return isFormValid;
  }

  previewContent = (isMobile) => {
    const { controls, titleColor, descriptionColor, isTitleBold, isDescriptionBold, isAddDescriptionOpen } = this.state;
    const {
      description, title, bannerTypeRadio, bannerImage,
      // actionTypeRadio, selectScreenDropDown, webUrl, bannerStartDate, bannerEndDate
    } = controls;
    return <CardBody className={`height-100 ${isMobile ? "mobile-preview-card" : "web-preview-card"}`}>
      {bannerTypeRadio.value === 'text' && <Row className="height-100" style={{ alignItems: 'center' }}>
        <Col>
          <div
            style={{ color: titleColor, fontWeight: isTitleBold ? 'bold' : '' }}
            className={isMobile ? "banner-preview-title-mobile" : "banner-preview-title-web"}
          >
            {title.value}
          </div>
          <div
            style={{ color: descriptionColor, fontWeight: isDescriptionBold ? 'bold' : '' }}
            className={isMobile ? "banner-preview-description-mobile" : "banner-preview-description-web"}
          >
            {description.value}
          </div>
        </Col>
      </Row>}

      {bannerTypeRadio.value === 'text_picture' && <>
        <Row className="height-100" style={{ alignItems: 'center' }}>
          <Col sm={isMobile ? "3" : "3"} className={isMobile ? "preview-image-container-mobile" : "preview-image-container-web"}>
            {!bannerImage.link && <div className="preview-image" style={{ minHeight: isMobile ? null : '75px' }} />}
            {bannerImage.link && <img src={bannerImage.link}
              alt=""
              className="preview-image"
              style={bannerImage.link ? { backgroundColor: 'transparent' } : null}
              ref={el => this.bannerImageRef = el}
              onLoad={() => this.handleValidation()}
            />}
          </Col>
          <Col className="padding-left-0 padding-right-0">
            <div
              style={{ color: titleColor, fontWeight: isTitleBold ? 'bold' : '' }}
              className={isMobile ? "banner-preview-title-mobile" : "banner-preview-title-web"}
            >
              {title.value}
            </div>
            <div
              style={{ color: descriptionColor, fontWeight: isDescriptionBold ? 'bold' : '' }}
              className={isMobile ? "banner-preview-description-mobile" : "banner-preview-description-web"}
            >
              {description.value}
            </div>
          </Col>
        </Row>
      </>}


      {bannerTypeRadio.value === 'picture' && <>
        {/* <Row className="height-100">
          <Col className="className="height-100""> */}
        <div className="height-100">
          {!bannerImage.link && <div className="preview-only-image" />}
          {bannerImage.link && <img
            src={bannerImage.link}
            alt=""
            className="preview-only-image"
            ref={el => this.bannerImageRef = el}
            onLoad={() => this.handleValidation()}
          />}

        </div>
        {/* </Col> */}
        {/* </Row> */}
      </>}
    </CardBody>
  }

  openFilter = () => {
    this.setState({ isUserSelectFilterOpen: true })
  }

  closeFilter = () => {
    this.setState({ isUserSelectFilterOpen: false })
  }
  applyFilter = (filterData) => {
    let defaultSelectTargetUsers = {
      file: null,
      count: null,
      valid: null,
      touched: true,
      required: true,
      showErrorMsg: false
    }
    const { controls } = this.state;
    let { selectTargetUsers } = controls;
    if (filterData.counts) {
      const { counts } = filterData;
      defaultSelectTargetUsers.count = counts;
      this.setState({ controls });
    }
    if (filterData.file) {
      const { file } = filterData;
      defaultSelectTargetUsers.file = file;
    }
    controls.selectTargetUsers = defaultSelectTargetUsers;
    this.setState({ controls, filterData });
  }

  getBannerActions = () => {
    let screenDropDownOption = [{
      action_name: 'No Action',
      action_value: ''
    }]
    this.setState({ isLoading: true });
    BannerService.getBannerActions()
      .then(data => {
        if (data.data.data) {
          screenDropDownOption = [...screenDropDownOption, ...data.data.data];
        }
        this.setState({ screenDropDownOption, isLoading: false })
      })
      .catch(e => {
        this.setState({ screenDropDownOption, isLoading: false })
        console.error("e", e);
      })
  }

  createBanner = () => {
    const { controls, isTitleBold,
      isDescriptionBold, titleColor, descriptionColor, bannerBackgroundColor, filterData, bannerUuid, action,
      // banners, isDescriptionColorPickerOpen, isTitleColorPickerOpen, isBannerBackgroundColorPickerOpen,
      // actionsRadioOption, screenDropDownOption, isAddDescriptionOpen,
    } = this.state;

    const {
      description, title, bannerTypeRadio, actionTypeRadio,
      selectScreenDropDown, webUrl, bannerStartDate, bannerEndDate,
      bannerImage, selectTargetUsers
    } = controls;

    let isFormValid = this.handleValidation(false, true);
    if (!isFormValid) {
      return;
    }
    let obj = {};
    obj['banner_type'] = bannerTypeRadio.value;
    obj['background_color'] = bannerBackgroundColor;
    if (bannerStartDate.value) {
      bannerStartDate.value.setHours(0, 0, 0, 0);
      obj['start_date'] = bannerStartDate.value.toISOString();
    }
    if (bannerEndDate.value) {
      bannerEndDate.value.setHours(23, 59, 59, 59);
      obj['end_date'] = bannerEndDate.value.toISOString();
    }

    if ((bannerTypeRadio.value === 'text') || (bannerTypeRadio.value === 'text_picture')) {
      obj['title'] = title.value;
      obj['is_title_bold'] = isTitleBold;
      obj['title_color'] = titleColor;
    }
    if ((bannerTypeRadio.value === 'picture') || (bannerTypeRadio.value === 'text_picture')) {
      obj['image_url'] = bannerImage.link;
    }
    if (description.value && bannerTypeRadio.value !== 'picture') {
      obj['description'] = description.value;
      obj['is_description_bold'] = isDescriptionBold;
      obj['description_color'] = descriptionColor;
    }
    if (actionTypeRadio.value === 'in_app') {
      if (selectScreenDropDown.value) {
        obj['action'] = selectScreenDropDown.value;
      }
    }
    if (actionTypeRadio.value === 'web_link') {
      obj['content_url'] = webUrl.value;
    }
    if (selectTargetUsers.file) {
      obj['file'] = selectTargetUsers.file;
    }
    if (filterData) {
      let {
        filter_area_ids, filter_dealer_classification, filter_employee_ids,
        filter_fleet_member_type, filter_region_ids, filter_target_ids, filter_zone_ids
      } = filterData;
      if (filter_area_ids && Array.isArray(filter_area_ids) && filter_area_ids.length > 0) {
        obj['area'] = filter_area_ids.join(',');
      }
      if (filter_region_ids && Array.isArray(filter_region_ids) && filter_region_ids.length > 0) {
        obj['region'] = filter_region_ids.join(',');
      }
      if (filter_zone_ids && Array.isArray(filter_zone_ids) && filter_zone_ids.length > 0) {
        obj['zone'] = filter_zone_ids.join(',');
      }
      if (filter_fleet_member_type && Array.isArray(filter_fleet_member_type) && filter_fleet_member_type.length > 0) {
        obj['fleet_type'] = filter_fleet_member_type.join(',');
      }
      if (filter_dealer_classification && Array.isArray(filter_dealer_classification) && filter_dealer_classification.length > 0) {
        obj['dealer_type'] = filter_dealer_classification.join(',');
      }
      if (filter_target_ids && Array.isArray(filter_target_ids) && filter_target_ids.length > 0) {
        obj['user_type'] = filter_target_ids.join(',');
      }
      if (filter_employee_ids && Array.isArray(filter_employee_ids) && filter_employee_ids.length > 0) {
        obj['employee_type'] = filter_employee_ids.join(',');
      }
    }

    if (action === 'edit') {
      obj['uuid'] = bannerUuid;
    }
    this.setState({ isLoading: true });
    BannerService.addEditBanner(obj)
      .then(data => {
        if (data.data && data.data.message) {
          ModalService.openAlert('', data.data.message, 'success');
        }
        this.setState({ isLoading: false });
        this.openBannerList();
      })
      .catch(e => {
        console.error("e", e);
        let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : 'Something went wrong';
        ModalService.openAlert('', message, 'error');
        this.setState({ isLoading: false });
      })
  }

  openBannerList = () => {
    this.props.history.push('/banner-list');
  }

  generateTitle = () => {
    const { action } = this.state;
    if (action === 'duplicate') {
      return 'Duplicate Banner'
    } else if (action === 'edit') {
      return 'Edit Banner'
    } else {
      return 'Create Banner'
    }
  }

  render() {
    const { controls, banners, isDescriptionColorPickerOpen, isTitleBold,
      isDescriptionBold, titleColor, descriptionColor, bannerBackgroundColor,
      actionsRadioOption, screenDropDownOption, isAddDescriptionOpen, action, isLoading,
      // isTitleColorPickerOpen, isBannerBackgroundColorPickerOpen
    } = this.state;

    const {
      description, title, bannerTypeRadio, actionTypeRadio,
      selectScreenDropDown, webUrl, bannerStartDate, bannerEndDate,
      bannerImage, selectTargetUsers
    } = controls;
    return (
      <div id="create-banner">
        {this.state.isUserSelectFilterOpen && (
          <Sidebar
            sidebar={
              <CampaignHoUserFilter
                closeSideBar={this.closeFilter}
                jkUserFilterData={
                  this.state.filterData
                    ? this.state.filterData
                    : {}
                }
                from="create-banner"
                applyFilter={this.applyFilter}
              // ref={this.childCampaignHoUserFilter}
              // communication_type={
              //   this.state.controls.communication_type.value
              // }
              />
            }
            open={this.state.isUserSelectFilterOpen}
            sidebarClassName="subsdiaries-sidebar"
            pullRight
            styles={{
              sidebar: { background: "white", height: "100%", width: "40%" }
            }}
          />
        )}
        <div className="page-title">
          <span
            className="prev-page-link cursor-pointer title-case"
            onClick={this.openBannerList}
          >
            Banner List
          </span>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span className="current-page title-case">
            {this.generateTitle()}
          </span>
        </div>
        <br />
        <Card style={{ width: '84%' }}>
          <CardBody>
            {isLoading && <CustomSpinner />}
            <Row>
              <Col sm="6" style={{ paddingRight: '50px' }}>
                <div className="field-title">Banner Type</div>
                <Row className="margin-top-10">
                  <Col>
                    {banners.map((b, index) => {
                      return <>
                        <label className="container-rb">
                          {b.key}
                          <input
                            type="radio"
                            name="bannerTypeRadio"
                            value={b.value}
                            onChange={this.handleRadioChange.bind(this, 'bannerTypeRadio')}
                            checked={bannerTypeRadio.value === b.value}
                          />
                          <span className="checkmark-rb"></span>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    }
                    )}

                  </Col>
                </Row>

                {/* Title */}
                {bannerTypeRadio.value !== 'picture' && <>
                  <div className="field-title margin-top-30">
                    <Row>
                      <Col sm="8">
                        Title <span className="compulsory">*</span>
                      </Col>
                      <Col sm="4">
                        <div className="field-title-other-field-container">
                          <span className="field-title-other-fields">Color</span>&nbsp;
                          <span>
                            <div className="color-picker-selected-color-container" onClick={this.openCloseTitleColorPicker}>
                              <div className="color-picker-selected-color" style={{ backgroundColor: titleColor }} />
                            </div>
                            {this.state.isTitleColorPickerOpen ?
                              <div className="color-picker-popover">
                                <div className="color-picker-cover" onClick={this.closeTitleColorPicker} />
                                <SketchPicker color={titleColor} onChange={this.handleTitleColorChange} />
                              </div> :
                              null
                            }
                          </span>&nbsp;&nbsp;
                          <span className="field-title-other-fields">Bold</span>&nbsp;
                          <Switch
                            onChange={this.handleTitleBoldChange}
                            checked={isTitleBold}
                            height={15}
                            width={30}
                            onColor='#f7b924'
                            uncheckedIcon={false}
                            checkedIcon={false}
                            activeBoxShadow=''
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col>
                      <input
                        type="text"
                        name="title"
                        className="form-control margin-top-8"
                        placeholder="Type Here"
                        value={title.value}
                        onChange={this.handleInputChange}
                      />
                      <div className="no-of-input-text">{title.count}/36</div>
                      {title.showErrorMsg && <div className="error" style={{ marginTop: '-15px' }}>
                        {EMPTY_TITLE}
                      </div>}
                    </Col>
                  </Row>
                </>}

                {/* Description */}
                {bannerTypeRadio.value !== 'picture' && <>

                  <div className="field-title margin-top-10">
                    {!isAddDescriptionOpen &&
                      <div onClick={this.openDesriptionInput}
                        className="add-description-button"
                      >Add Description</div>}
                    {isAddDescriptionOpen &&

                      <Row className="margin-top-30">
                        <Col sm="8">
                          Description
                        </Col>
                        <Col sm="4">
                          <div className="field-title-other-field-container">
                            <span className="field-title-other-fields">Color</span>&nbsp;
                            <span>
                              <div className="color-picker-selected-color-container" onClick={this.openCloseDescriptionColorPicker}>
                                <div className="color-picker-selected-color" style={{ backgroundColor: descriptionColor }} />
                              </div>
                              {isDescriptionColorPickerOpen ?
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeDescriptionColorPicker} />
                                  <SketchPicker color={titleColor} onChange={this.handleDescriptionColorChange} />
                                </div> :
                                null
                              }
                            </span>&nbsp;&nbsp;
                            <span className="field-title-other-fields">Bold</span>&nbsp;
                            <Switch
                              onChange={this.handleDescriptionBoldChange}
                              checked={isDescriptionBold}
                              height={15}
                              width={30}
                              onColor='#f7b924'
                              uncheckedIcon={false}
                              checkedIcon={false}
                              activeBoxShadow=''
                            />
                          </div>
                        </Col>
                      </Row>}
                  </div>
                  {isAddDescriptionOpen && <Row>
                    <Col>
                      <textarea
                        // type="text"
                        rows="3"
                        name="description"
                        className="form-control margin-top-8 description-text"
                        placeholder="Type Here"
                        value={description.value}
                        onChange={this.handleInputChange}
                      />
                      <div className="no-of-input-text">{description.count}/150</div>
                    </Col>
                  </Row>}
                </>}

                {/* Banner Background */}
                {bannerTypeRadio.value !== 'picture' && <div className="margin-top-30">
                  <span className="field-title">Banner Background <span className="compulsory">*</span></span>
                  <span>
                    <div className="banner-background-color-container display-inline">
                      <span className="field-title-other-fields">Color</span>&nbsp;
                      {/* <span style={{position:'relative'}}> */}
                      <span style={{ backgroundColor: bannerBackgroundColor }} className="preview-color"
                        onClick={this.openCloseBannerBackgroundColorPicker}
                      ></span>
                      {this.state.isBannerBackgroundColorPickerOpen ?
                        <div className="color-picker-popover">
                          <div className="color-picker-cover" onClick={this.closeBannerBackgroundColorPicker} />
                          <SketchPicker color={bannerBackgroundColor} onChange={this.handleBannerBackgroundColorChange} />
                        </div> :
                        null
                      }
                    </div>
                  </span>
                </div>
                }

                {/* upload Image */}
                {bannerTypeRadio.value !== 'text' && <>
                  <Row className="margin-top-30">
                    <Col className="field-title">
                      Upload Image<span className="compulsory">*</span>
                    </Col>
                  </Row>
                  <Row className="margin-top-10">
                    <Col>
                      <div style={{ backgroundColor: '#f8f8f8' }}>
                        <div className="create-icon-con">
                          <div className="upload-image-container">
                            <label className="upload-image-label">
                              <div className="edit-icon">
                                Choose File
                                {/* <Ionicon icon="md-create" fontSize="24px" color="#fff" /> */}
                              </div>
                              <input
                                type="file"
                                accept="image/*"
                                className="visibility-hidden width-0"
                                onChange={this.handleUploadImage}
                              />
                            </label>
                          </div>
                          <div className="file-upload-image-name">
                            {bannerImage.file && bannerImage.file.name && <Row>
                              <Col sm="10">
                                {bannerImage.file && bannerImage.file.name}
                              </Col>
                              <Col sm="2" className="text-align-right cursor-pointer" onClick={this.resetBannerImage}>
                                <img src="images/close.svg" alt="" height="12px" width="12px" />
                              </Col>
                            </Row>}
                          </div>
                        </div>
                        {bannerTypeRadio.value === 'picture' && <div className="image-note-text">(For better preview upload banner image in 1000 x 300 resolutions)</div>}
                        {bannerTypeRadio.value === 'text_picture' && <div className="image-note-text">(For better preview upload banner image in 75 x 75 resolutions)</div>}
                      </div >
                      {bannerImage.showErrorMsg && !bannerImage.isValidSize ? <div className="error">
                        Please upload {bannerTypeRadio.value === 'picture' ? '1000x300' : '75x75'} image
                      </div> : bannerImage.showErrorMsg && <div className="error">
                        {NO_IMAGE}
                      </div>}

                    </Col>
                  </Row>
                </>}
                {/* choose action */}
                <Row className="margin-top-30">
                  <Col sm="6">
                    <div className="field-title">Choose Action<span className="compulsory">*</span></div>
                    <Row className="margin-top-12">
                      <Col>
                        {actionsRadioOption.map((a, index) => {
                          return <>
                            <label className="container-rb">
                              {a.key}
                              <input
                                type="radio"
                                name="actionTypeRadio"
                                value={a.value}
                                onChange={this.handleRadioChange.bind(this, 'actionTypeRadio')}
                                checked={actionTypeRadio.value === a.value}
                              />
                              <span className="checkmark-rb"></span>
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;
                          </>
                        }
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="6">
                    {actionTypeRadio.value === 'in_app' && <>
                      <Label for="selectScreenDropDown" className="field-label">Select Screen<span className="compulsory">*</span></Label>
                      <select
                        className="form-control select drop-down-control"
                        name="selectScreenDropDown"
                        value={selectScreenDropDown.value}
                        onChange={this.handleInputChange}
                      >
                        {/* <option value={'all'}>All</option> */}
                        {screenDropDownOption.map(s => (
                          <option value={s.action_value.toLowerCase()}>{s.action_name}</option>
                        ))}
                      </select>
                    </>}
                    {actionTypeRadio.value === 'web_link' && <>

                      <div>Add URL <span className="compulsory">*</span></div>
                      <Row>
                        <Col>
                          <input
                            type="text"
                            name="webUrl"
                            className="form-control margin-top-5"
                            placeholder="Paste Website URL"
                            value={webUrl.value}
                            onChange={this.handleInputChange}
                          />
                          {webUrl.showErrorMsg && <div className="error">
                            {ENTER_WEB_URL}
                          </div>}
                        </Col>
                      </Row>
                    </>
                    }
                  </Col>
                </Row>

                {/* Select target users */}
                <Row className="margin-top-30">
                  <Col>
                    <div className="field-title">Select Target Users<span className="compulsory">*</span></div>
                    <button className="select-user-input" onClick={this.openFilter} disabled={action === 'edit' ? true : false}>
                      <Row>
                        <Col className="select-user-input-select" sm="2">Select</Col>
                        <Col className="select-user-input-user-count">
                          {selectTargetUsers.count && selectTargetUsers.count > 0 && <span> Selected User : {selectTargetUsers.count}</span>}
                          {selectTargetUsers.file && selectTargetUsers.file.name && <span>{selectTargetUsers.file.name}</span>}
                          &nbsp;<span>
                            <img src="images/right.svg" alt="" height="12px" width="12px" />
                          </span>
                        </Col>
                      </Row>
                    </button>
                    {selectTargetUsers.showErrorMsg && <div className="error">
                      {SELECT_USERS}
                    </div>}
                  </Col>
                </Row>

                {/* Start and End Date */}
                <Row className="margin-top-30">
                  <Col>
                    <FormGroup>
                      <Label for="password" className="field-title">Banner Start Date<span className="compulsory">*</span></Label>
                      <div>
                        <Label className="width-100"
                          onClick={e => this.bannerStartDate.state.open && e.preventDefault()}
                        >
                          <InputGroup>
                            <DatePicker className={"form-control calendar-input"}
                              selected={bannerStartDate.value}
                              onChange={this.handleDateChange.bind(this, 'bannerStartDate')}
                              showMonthDropdown
                              ref={r => this.bannerStartDate = r}
                              // shouldCloseOnSelect={true}
                              dateFormat="dd/MM/yyyy"
                              showYearDropdown
                              placeholderText="Select"
                              shouldCloseOnSelect
                              dropdownMode="select"
                            />
                            <InputGroupAddon addonType="append" >
                              <div className="input-group-text">
                                <img src="/images/calendar_icon.svg" className="calendar-icon" alt="" />
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                        </Label>
                        {bannerStartDate.showErrorMsg && <div className="error">
                          {EMPTY_BANNER_START_DATE}
                        </div>}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="password" className="field-title">Banner End Date<span className="compulsory">*</span></Label>
                      <div>
                        <Label className="width-100"
                          onClick={e => this.bannerEndDate.state.open && e.preventDefault()}
                        >
                          <InputGroup>
                            <DatePicker className={"form-control calendar-input"}
                              selected={bannerEndDate.value}
                              onChange={this.handleDateChange.bind(this, 'bannerEndDate')}
                              showMonthDropdown
                              ref={r => this.bannerEndDate = r}
                              // shouldCloseOnSelect={true}
                              dateFormat="dd/MM/yyyy"
                              showYearDropdown
                              placeholderText="Select"
                              shouldCloseOnSelect
                              dropdownMode="select"
                            />
                            <InputGroupAddon addonType="append" >
                              <div className="input-group-text">
                                <img src="/images/calendar_icon.svg" className="calendar-icon" alt="" />
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                        </Label>
                        {bannerEndDate.showErrorMsg && <div className="error">
                          {EMPTY_BANNER_END_DATE}
                        </div>}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

              </Col>

              {/* Preview */}
              {/* <Col sm="1"></Col> */}
              <Col sm="6" style={{ paddingLeft: '26px' }}>
                <div>
                  <CardBody className="preview-main-container">
                    <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '5px' }}>
                      <div className="preview-description-title preview-description">Device Preview</div>
                      <div className="preview-description margin-top-10">
                        This preview provides a general idea of how your message will appear on a mobile device.
                        Actually message rendering will vary depending on the device.
                        Test with a real device for actual results.
                      </div>
                    </div>
                    <hr className="margin-top-0" />
                    <div style={{ padding: '16px' }}>
                      <div className="preview-web-title preview-description">Web</div>
                      <div className="preview-web-container margin-top-10" style={{ backgroundColor: bannerBackgroundColor }}>
                        {this.previewContent()}
                      </div>
                    </div>

                    <div style={{ padding: '16px' }}>
                      <div className="preview-web-title preview-description">In Mobile</div>
                      <Row>
                        <Col sm="2"></Col>
                        <Col sm="9" style={{ padding: '0px' }}>
                          <div>
                            <div className="preview-mobile">
                              <div className="preview-mobile-container" style={{ backgroundColor: bannerBackgroundColor }}>
                                {this.previewContent(true)}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                      {/* <div style={{padding:'10px 105px'}}>
                        <div className="preview-mobile">
                          <div className="preview-mobile-container">
                            {this.previewContent(true)}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </CardBody>
                </div>
              </Col>
            </Row>
            <hr />
            <Row style={{ marginLeft: '0', marginRight: '0' }}>
              <Col xs="12" md="12" lg="12" className="text-align-right" style={{ marginTop: '0' }}>
                <Button className="button cancel-button"
                  onClick={() => this.openBannerList()}
                >Cancel</Button>&nbsp;&nbsp;
                <Button className="button create-button" onClick={this.createBanner}>{action === 'edit' ? 'Edit' : 'Create'}</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default CreateBanner;