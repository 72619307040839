import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import Sidebar from "react-sidebar";
import Ionicon from 'react-ionicons';

import PosterService from '../../services/PosterService';
import ModalService from '../../services/ModalService';

import CampaignHoUserFilter from "../../components/CampaignHoUserFilter/CampaignHoUserFilter";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import PosterPreviewModal from '../../modal/PosterPreviewModal/PosterPreviewModal';

import { messages } from '../../constants/constant.message';

import './CreatePoster.css';

let {
  NO_IMAGE,
} = messages.POSTER;

class CreatePoster extends Component {

  state = {
    isLoading: false,
    posterUuid: null,
    screenCategory: [
      {
        key: '',
        label: 'Select'
      }
    ],
    isPosterBackgroundColorPickerOpen: false,
    posterBackgroundColor: '#ffffff',
    posterImage: null,
    posterImageUrl: null,
    isUserSelectFilterOpen: false,
    filterData: null,
    action: 'add',

    isPreviewImageLightBoxOpen: false,
    imagesToPreview: [],
    imageTitle: null,

    controls: {
      selectScreenCategory: {
        value: null,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      },
      posterImage: {
        value: null,
        link: null,
        thumbnailLink: null,
        file: null,
        count: 0,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false,
        isValidSize: false
      },
      previewImage: {
        value: null,
        link: null,
        thumbnailLink: null,
        file: null,
        count: 0,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false,
        isValidSize: false
      },
      selectTargetUsers: {
        fileName: null,
        file: null,
        count: null,
        valid: null,
        touched: false,
        required: true,
        showErrorMsg: false
      }
    }
  }

  constructor(props) {
    super(props);
    this.posterImageRef = React.createRef();
  }

  async componentDidMount() {
    const { location } = this.props;
    const { action, posterData = null } = location;

    if ((action === 'edit' || action === 'duplicate') && posterData) {
      const { uuid, poster_url, poster_thumbnail_url, reference_poster_url, reference_poster_thumbnail_url, target_users,
        zone, region, area, category } = posterData;

      let { controls, posterUuid } = this.state;
      const { posterImage, previewImage, selectTargetUsers } = controls;
      if (uuid) {
        posterUuid = uuid;
      }

      if (poster_url) {
        posterImage.link = poster_url;
        posterImage.thumbnailLink = poster_thumbnail_url;
      }

      if (reference_poster_url) {
        previewImage.link = reference_poster_url;
        previewImage.thumbnailLink = reference_poster_thumbnail_url;
      }

      if (target_users) {
        selectTargetUsers.count = target_users;
      }

      let filterData = {
        filter_area_ids: area && Array.isArray(area) && area.length > 0 ? area : [],
        filter_dealer_classification: category && Array.isArray(category) && category.length > 0 ? category : [],
        filter_region_ids: region && Array.isArray(region) && region.length > 0 ? region : [],
        filter_zone_ids: zone && Array.isArray(zone) && zone.length > 0 ? zone : [],
        filter_target_ids: [2]
      };

      this.setState({
        controls, action, posterUuid, filterData
      });
    }
    this.getPosterCategory();
  }

  openPosterList = () => {
    this.props.history.push('/poster-list');
  }

  generateTitle = () => {
    const { action } = this.state;
    if (action === 'duplicate') {
      return 'Duplicate Poster'
    } else if (action === 'edit') {
      return 'Edit Poster'
    } else {
      return 'Create Poster'
    }
  }

  getPosterCategory = () => {
    let screenCategory = [{
      key: '',
      label: 'Select'
    }]
    this.setState({ isLoading: true });
    PosterService.getPosterCategory()
      .then(data => {

        if (data.data.data) {
          screenCategory = [...screenCategory, ...data.data.data];
        }
        this.setState({ screenCategory, isLoading: false });
        let { category } = this.props.location.posterData;
        if (category) {
          let index = screenCategory.map(s => { return s.label }).indexOf(category);
          let e = { target: { name: 'selectScreenCategory', value: index > -1 ? screenCategory[index].key : screenCategory[0].key } }
          this.handleInputChange(e);
        }
      })
      .catch(e => {
        this.setState({ screenCategory, isLoading: false })
        console.error("e", e);
      })
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    let controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls }, () => {
      this.handleValidation();
    });
  }

  handleUploadImage = (event) => {
    const { controls } = this.state;
    const { posterImage } = controls;

    event.preventDefault();
    const file = event.target.files[0];
    const obj = {
      file
    };
    this.setState({ isLoading: true });
    PosterService.UpdatePosterImage(obj, 'poster_image').then(data => {
      this.setState({ isLoading: false });
      if (data.data && data.data.data && data.data.data.imageUrl) {
        posterImage.value = URL.createObjectURL(file);
        posterImage.link = data.data.data.imageUrl;
        posterImage.thumbnailLink = data.data.data.thumbnailImageUrl;
        posterImage.file = file;
        posterImage.touched = true;
        this.setState({ controls }, () => {
        });
      }
    }).catch(e => {
      posterImage.value = null;
      posterImage.link = null;
      posterImage.file = null;
      this.setState({ controls, isLoading: false }, () => {
        this.handleValidation();
      });
    });
  }

  handlePreviewUploadImage = (event) => {
    const { controls } = this.state;
    const { previewImage } = controls;

    event.preventDefault();
    const file = event.target.files[0];
    const obj = {
      file
    };
    this.setState({ isLoading: true });
    PosterService.UpdatePosterImage(obj, 'poster_image')
      .then(data => {
        this.setState({ isLoading: false });
        if (data.data && data.data.data && data.data.data.imageUrl) {
          previewImage.value = URL.createObjectURL(file);
          previewImage.link = data.data.data.imageUrl;
          previewImage.thumbnailLink = data.data.data.thumbnailImageUrl;
          previewImage.file = file;
          previewImage.touched = true;
          this.setState({ controls }, () => {
          });
        }
      })
      .catch(e => {
        previewImage.value = null;
        previewImage.link = null;
        previewImage.file = null;
        this.setState({ controls, isLoading: false }, () => {
          this.handleValidation();
        });
      });
  }

  handleValidation = (firstTime, isSubmit = false) => {
    const { controls, action } = this.state;
    let { posterImage, previewImage, selectTargetUsers, selectScreenCategory } = controls;
    if (firstTime === true || posterImage.touched === true || isSubmit) {
      // let imageHeight = this.posterImageRef.naturalHeight;
      // let imageWidth = this.posterImageRef.naturalWidth;
      // let ratio = imageWidth / imageHeight;
      if (!posterImage.link) {
        posterImage.valid = false;
      } else {
        // if (ratio <= 3.5 && ratio >= 2.7) {
        posterImage.isValidSize = true;
        posterImage.valid = true;
        // } else {
        // posterImage.isValidSize = false;
        // posterImage.valid = false;
        // }
      }
      if (((posterImage.touched || isSubmit) && posterImage.valid === false)) {
        posterImage.showErrorMsg = true;
      } else {
        posterImage.showErrorMsg = false;
      }
    }

    if (firstTime === true || previewImage.touched === true || isSubmit) {
      // let imageHeight = this.posterImageRef.naturalHeight;
      // let imageWidth = this.posterImageRef.naturalWidth;
      // let ratio = imageWidth / imageHeight;
      if (!previewImage.link) {
        previewImage.valid = false;
      } else {
        // if (ratio <= 3.5 && ratio >= 2.7) {
        previewImage.isValidSize = true;
        previewImage.valid = true;
        // } else {
        // previewImage.isValidSize = false;
        // previewImage.valid = false;
        // }
      }
      if (((previewImage.touched || isSubmit) && previewImage.valid === false)) {
        previewImage.showErrorMsg = true;
      } else {
        previewImage.showErrorMsg = false;
      }
    }

    if ((firstTime === true || selectTargetUsers.touched === true || isSubmit) && action !== 'edit') {
      if (selectTargetUsers.count === null) {
        selectTargetUsers.valid = false;
      } else {
        selectTargetUsers.valid = true;
      }
      if ((isSubmit && selectTargetUsers.valid === false)) {
        selectTargetUsers.showErrorMsg = true;
      } else {
        selectTargetUsers.showErrorMsg = false;
      }
    }

    if ((firstTime === true || selectScreenCategory.touched === true || isSubmit) && action !== 'edit') {
      if (selectScreenCategory.value === null || selectScreenCategory.value === '') {
        selectScreenCategory.valid = false;
      } else {
        selectScreenCategory.valid = true;
      }
      if ((isSubmit && selectScreenCategory.valid === false)) {
        selectScreenCategory.showErrorMsg = true;
      } else {
        selectScreenCategory.showErrorMsg = false;
      }
    }

    let isFormValid = selectScreenCategory.valid === true && (selectScreenCategory.value !== null || selectScreenCategory.value !== '') ? true : false;
    isFormValid = isFormValid === true && posterImage.valid === true ? true : false;
    isFormValid = isFormValid === true && previewImage.valid === true ? true : false;
    if (action !== 'edit') {
      isFormValid = isFormValid === true && selectTargetUsers.valid === true ? true : false;
    }
    this.setState({ controls });
    return isFormValid;
  }

  openPreviewImage = (image_url, imageTitle) => {
    this.setState({
      imagesToPreview: [image_url],
      imageTitle,
      isPreviewImageLightBoxOpen: true
    })
  }

  closePreviewImageLightBox = () => {
    this.setState({
      isPreviewImageLightBoxOpen: false
    });
  }

  posterContent = () => {
    const { controls } = this.state;
    const { posterImage } = controls;
    return <CardBody className='height-100 web-preview-card'>
      <div className="height-100">
        {!posterImage.link && <div className="preview-only-image" />}
        {posterImage.link && <img
          src={posterImage.link}
          alt=""
          className="preview-only-image"
          ref={el => this.posterImageRef = el}
          onLoad={() => this.handleValidation()}
          onClick={() => this.openPreviewImage(posterImage.link, 'POSTER IMAGE')}
        />}
      </div>
    </CardBody>
  }

  previewContent = () => {
    const { controls } = this.state;
    const { previewImage } = controls;
    return <CardBody className='height-100 web-preview-card'>
      <div className="height-100">
        {!previewImage.link && <div className="preview-only-image" />}
        {previewImage.link && <img
          src={previewImage.link}
          alt=""
          className="preview-only-image"
          ref={el => this.posterImageRef = el}
          onLoad={() => this.handleValidation()}
          onClick={() => this.openPreviewImage(previewImage.link, 'PREVIEW IMAGE')}
        />}
      </div>
    </CardBody>
  }

  openFilter = () => {
    this.setState({ isUserSelectFilterOpen: true })
  }

  nothing = () => {
  }

  closeFilter = () => {
    this.setState({ isUserSelectFilterOpen: false })
  }

  applyFilter = (filterData) => {
    let defaultSelectTargetUsers = {
      file: null,
      count: null,
      valid: null,
      touched: true,
      required: true,
      showErrorMsg: false
    }
    const { controls } = this.state;
    if (filterData.counts) {
      const { counts } = filterData;
      defaultSelectTargetUsers.count = counts;
      this.setState({ controls });
    }
    if (filterData.file) {
      const { file } = filterData;
      defaultSelectTargetUsers.file = file;
    }
    controls.selectTargetUsers = defaultSelectTargetUsers;
    this.setState({ controls, filterData });
  }

  createPoster = () => {
    const { controls, filterData, posterUuid, action } = this.state;

    const { posterImage, previewImage, selectScreenCategory } = controls;

    let isFormValid = this.handleValidation(false, true);
    if (!isFormValid) {
      return;
    }
    let obj = {};
    obj['poster_url'] = posterImage.link;
    obj['poster_thumbnail_url'] = posterImage.thumbnailLink;
    obj['reference_poster_url'] = previewImage.link;
    obj['reference_poster_thumbnail_url'] = previewImage.thumbnailLink;
    obj['category'] = selectScreenCategory.value;
    if (filterData) {
      let {
        filter_area_ids, filter_dealer_classification, filter_region_ids, filter_zone_ids,
        filter_target_ids
      } = filterData;
      if (filter_area_ids && Array.isArray(filter_area_ids) && filter_area_ids.length > 0) {
        obj['area'] = filter_area_ids;
      }
      if (filter_region_ids && Array.isArray(filter_region_ids) && filter_region_ids.length > 0) {
        obj['region'] = filter_region_ids;
      }
      if (filter_zone_ids && Array.isArray(filter_zone_ids) && filter_zone_ids.length > 0) {
        obj['zone'] = filter_zone_ids;
      }
      if (filter_dealer_classification && Array.isArray(filter_dealer_classification) && filter_dealer_classification.length > 0) {
        obj['classification'] = filter_dealer_classification;
      }
      if (filter_target_ids && Array.isArray(filter_target_ids) && filter_target_ids.length > 0) {
        obj['filter_target_ids'] = filter_target_ids;
      }

    }

    if (action === 'edit') {
      obj['uuid'] = posterUuid;
    }
    this.setState({ isLoading: true });
    PosterService.addEditPoster(obj)
      .then(data => {
        if (data.data && data.data.message) {
          ModalService.openAlert('', data.data.message, 'success');
        }
        this.setState({ isLoading: false });
        this.openPosterList();
      })
      .catch(e => {
        console.error("e", e);
        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : 'Something went wrong';
        ModalService.openAlert('', message, 'error');
        this.setState({ isLoading: false });
      })
  }

  render() {
    const { controls, posterBackgroundColor, screenCategory, action, isLoading,
      isPreviewImageLightBoxOpen, imagesToPreview, imageTitle } = this.state;
    const { posterImage, previewImage, selectTargetUsers, selectScreenCategory } = controls;
    let photoIndex = 0;
    return (
      <div id="create-poster">
        {this.state.isUserSelectFilterOpen && (
          <Sidebar
            sidebar={
              <CampaignHoUserFilter closeSideBar={this.closeFilter}
                jkUserFilterData={this.state.filterData ? this.state.filterData : {}}
                from="create-poster" applyFilter={this.applyFilter}
              />
            }
            open={this.state.isUserSelectFilterOpen}
            sidebarClassName="subsdiaries-sidebar"
            pullRight
            styles={{
              sidebar: { background: "white", height: "100%", width: "40%" }
            }}
          />
        )}
        <div className="page-title">
          <span className="prev-page-link cursor-pointer title-case"
            onClick={this.openPosterList}>Poster List</span>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span className="current-page title-case">
            {this.generateTitle()}
          </span>
        </div>
        {isPreviewImageLightBoxOpen &&
          <PosterPreviewModal show={isPreviewImageLightBoxOpen} closeModal={this.closePreviewImageLightBox}
            data={{ imagesToPreview: imagesToPreview[photoIndex], imageTitle }}>
          </PosterPreviewModal>
        }
        <br />
        <Card style={{ width: '84%' }}>
          <CardBody>
            {isLoading && <CustomSpinner />}
            <Row>
              <Col sm="4" style={{ paddingRight: '50px' }}>
                <div className="field-title">Category <span className="compulsory">*</span></div>
                <Row className="margin-top-10">
                  <Col>
                    <select className="form-control select drop-down-control"
                      name="selectScreenCategory" value={selectScreenCategory.value}
                      onChange={this.handleInputChange}>
                      {screenCategory.map(s => (
                        <option value={s.key.toLowerCase()}>{s.label}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row>
                  {selectScreenCategory.showErrorMsg && !selectScreenCategory.valid &&
                    (selectScreenCategory.value === null || selectScreenCategory.value === '') && <Col>
                      <div className="error">Please select category.</div>
                    </Col>}
                </Row>
                <br />
                <div className="field-title">Select Dealers <span className="compulsory">*</span></div>
                <Row className="margin-top-10">
                  <Col>
                    <label className="form-control select-category drop-down-control" name="selectTargetUsers"
                      onClick={action === 'edit' ? this.nothing : this.openFilter}>
                      <Col style={{ paddingLeft: '0px', color: action === 'edit' ? '#939ba3' : '#495057' }}>
                        Select{(selectTargetUsers.touched === true || action === 'edit') && (
                          <span className="target-users" style={{ color: action === 'edit' ? '#939ba3' : '#495057' }}>Total Dealers : {selectTargetUsers.count}</span>
                        )}</Col>
                    </label>
                  </Col>
                </Row>
                <Row>
                  {selectTargetUsers.showErrorMsg && !selectTargetUsers.valid && selectTargetUsers.count === null && <Col>
                    <div className="error">Please select dealer.</div>
                  </Col>}
                </Row>
              </Col>
              <Col sm="8" style={{ paddingRight: '50px' }}>
                <div>
                  <CardBody className="preview-main-container">
                    <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '5px' }}>
                      <div className="preview-description-title preview-description">Image Preview</div>
                    </div>
                    <hr className="margin-top-0" />
                    <Row>
                      <Col>
                        <div className="preview-web-title preview-description" style={{ padding: '0px 16px', height: '30px' }}>
                          <Row>
                            <Col>Poster Image <span className="compulsory">*</span></Col>
                            <Col>
                              {/* <button className="button upload-button upload-button-title" onClick={() => this.openPosterList()}>Upload</button> */}
                              <div>
                                <div className="upload-image-container">
                                  <label className="upload-image-label">
                                    <div className="edit-icon"> Choose File </div>
                                    <input type="file" accept="image/*" className="visibility-hidden width-0" onChange={this.handleUploadImage} />
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {posterImage.showErrorMsg && posterImage.valid && !posterImage.isValidSize && <Col>
                              <div className="error">Please upload 1000x300 image </div>
                            </Col>}
                            {posterImage.showErrorMsg && !posterImage.valid && <Col>
                              <div className="error">{NO_IMAGE}</div>
                            </Col>}
                          </Row>
                        </div>
                        <div style={{ padding: '16px' }}>
                          <div className="preview-web-container margin-top-10" style={{ backgroundColor: posterBackgroundColor }}>
                            {/* <label className="upload-image-label">Your poster image will come here.</label> */}
                            {this.posterContent()}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="preview-web-title preview-description" style={{ padding: '0px 16px', height: '30px' }}>
                          <Row>
                            <Col>Preview Image <span className="compulsory">*</span></Col>
                            <Col>
                              {/* <button className="button upload-button upload-button-title" onClick={() => this.openPosterList()}>Upload</button> */}
                              <div>
                                <div className="upload-image-container">
                                  <label className="upload-image-label">
                                    <div className="edit-icon"> Choose File </div>
                                    <input type="file" accept="image/*" className="visibility-hidden width-0" onChange={this.handlePreviewUploadImage} />
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {previewImage.showErrorMsg && previewImage.valid && !previewImage.isValidSize && <Col>
                              <div className="error">Please upload 1000x300 image </div>
                            </Col>}
                            {previewImage.showErrorMsg && !previewImage.valid && <Col>
                              <div className="error">{NO_IMAGE}</div>
                            </Col>}
                          </Row>
                        </div>
                        <div style={{ padding: '16px' }}>
                          <div className="preview-web-container margin-top-10" style={{ backgroundColor: posterBackgroundColor }}>
                            {/* <label className="upload-image-label">Your poster image will come here.</label> */}
                            {this.previewContent()}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </div>
              </Col>
            </Row>
            <hr />
            <Row style={{ marginLeft: '0', marginRight: '0' }}>
              <Col xs="12" md="12" lg="12" className="text-align-right" style={{ marginTop: '0', paddingRight: '30px' }}>
                <Button className="button cancel-button" onClick={() => this.openPosterList()}>Cancel</Button>&nbsp;&nbsp;
                <Button className="button create-button" onClick={this.createPoster}>{action === 'edit' ? 'Save' : 'Create'}</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div >
    );
  }
}

export default CreatePoster;