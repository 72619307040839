/**
 * @name marketingCoupon.js
 * @desc redeem marketing coupon
 * @author Foram Sojitra <foram.sojitra@techifysolutions.com>
 * @version 0.0.0
 */
import classnames from "classnames";
import React, { Component } from 'react';
import {
  Table,
  Input,
  Row,
  Card,
  CardBody
} from "reactstrap";
import Filter from './Filter';
import { downlodFile, formatDate } from "../../utils/utils";
import lodash from "lodash"; // library added for debounce functionality
import MarketingCouponService from '../../services/MarketingCouponService';
import Pagination from '../../components/Pagination/Pagination';
import './RedeemCouponReport.css';
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";

const { ERROR_MESSAGE } = messages.COMMON;

class redeemCouponReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      search_query: "",
      isLoading: false,
      filterControls: {},
      download_report: false
    };
    this.searchData = lodash.debounce(this.searchData, 1000);
  };

  componentDidMount() {
    GoogleAnalticsService.pageView('Redeem Coupon Report View');
    this.getCoupon();
  }

  handlePageChange = page => {
    this.setState({ page: page });
    this.setState(prevState => {
      return {
        ...prevState,
        page: page
      };
    });
    setTimeout(() => {
      this.getCoupon();
    }, 1);
  };

  handleInputChange = e => {
    let { pageSize } = this.state;
    pageSize = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        pageSize: pageSize,
        page: 1
      };
    });
    setTimeout(() => {
      this.getCoupon();
    }, 1);
  };

  searchData = (search) => {
    this.setState(prevState => {
      return {
        ...prevState,
        filterApplied: true,
        search_query: search,
        page: 1
      };
    }, () => {
      this.getCoupon();
    });
  };

  handleSearchChange = e => {
    let q = e.target.value;
    this.searchData(q);
  };

  downloadReport = () => {
    this.setState(prevState => {
      return {
        ...prevState, download_report: true
      };
    }, () => {
      this.getCoupon();
    });
  }

  applyFilter = controls => {
    this.setState({
      filterControls: controls,
      filterApplied: true,
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0
    });
    setTimeout(() => {
      this.getCoupon();
    }, 1);
  };

  resetFilter = () => {
    this.setState({
      filterControls: {},
      filterApplied: false
    });
    setTimeout(() => {
      this.getCoupon();
    }, 1);
  };

  getCoupon = () => {
    const reqData = {};
    reqData.page = this.state.page;
    reqData.pagesize = this.state.pageSize;
    if (this.state.download_report) {
      reqData.download_report = this.state.download_report;
      GoogleAnalticsService.event('View Report', 'Redeem Coupon Download Report ');
    }

    if (this.state.search_query && this.state.search_query !== "") {
      reqData.q = this.state.search_query;
    }

    if (this.state.filterControls.type && this.state.filterControls.type !== "") {
      reqData.type = this.state.filterControls.type;
    }

    if (this.state.filterControls.purpose && this.state.filterControls.purpose !== "") {
      reqData.purpose = this.state.filterControls.purpose;
    }

    if (this.state.filterControls.paid_by_jk && this.state.filterControls.paid_by_jk.value !== "") {
      reqData.paid_by = this.state.filterControls.paid_by_jk.value;
    }

    if (this.state.filterControls.start_date && this.state.filterControls.start_date.value !== "") {
      let start_date = new Date(this.state.filterControls.start_date.value);
      start_date.setHours(0);
      start_date.setMinutes(0);
      reqData.start_date = start_date;
    }

    if (this.state.filterControls.end_date && this.state.filterControls.end_date.value !== "") {
      let end_date = new Date(this.state.filterControls.end_date.value);
      end_date.setHours(23);
      end_date.setMinutes(59);
      reqData.end_date = end_date;
    }

    this.setState({
      isLoading: true
    });

    MarketingCouponService.getRedeemedDealerCoupon(reqData)
      .then(res => {

        let { coupon, count } = this.state;

        if (res.data.data && res.data.data.file) {

          downlodFile(res.data.data.file);
          this.setState({
            isLoading: false,
            download_report: false
          });

        } else if (res.data.data && res.data.data.data) {
          coupon = res.data.data.data;
          count = res.data.data.total_element;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState(prevState => {
            return {
              ...prevState,
              coupon,
              count,
              totalPages,
              isLoading: false
            };
          });

        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };


  render() {
    const {
      count,
      pageSize,
      search
    } = this.state;

    return (
      <div id='redeem-report'>
        <span className="header-title">Redeemed Coupon Report</span>
        <br />
        <br />
        <br />
        <Card className="coupon-mg-card">
          <CardBody>
            <Row
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "1%",
                justifyContent: "space-between"
              }}
            >

              <div className="d-flex-row">
                <div
                  style={{ width: "380px" }}
                  className="d-flex-row search-input"
                >
                  <Input
                    placeholder="Search by Customer Number, Name, Coupon Code"
                    className="login-input"
                    name="search"
                    onChange={this.handleSearchChange}
                    value={search}
                  ></Input>
                  <img
                    src="images/search.svg"
                    alt=""
                    className="icon-width"
                    style={{ marginLeft: "-25px" }}
                  />
                </div>

                <div className="d-flex-row" style={{ marginLeft: "10px" }}>
                  <Filter
                    Filter={this.state.filterControls}
                    applyFilter={this.applyFilter} />
                </div>

              </div>

              {this.state.coupon.length > 0 && <div className="mr-1 d-flex" style={{ justifyContent: 'flex-end', margin: 'auto' }}>
                <img src="images/download.svg" alt="" className="download-report-icon margin-left-10" onClick={this.downloadExcel} />&nbsp;&nbsp;
                <span className="Download-Full-Report pull-right mr-2" onClick={this.downloadReport} style={{ cursor: 'pointer' }}>
                  Download Report
                </span>
              </div>}

            </Row>


            {this.state.isLoading && <CustomSpinner />}
            <div>
              <Table className="mb-0 table-responsive tbl-about tbl-about text-align-center">
                <thead>
                  <tr
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="coupon-list-table-header"
                  ></tr>
                </thead>
                <tbody>
                  <tr
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="coupon-list-table-header"
                  >
                    <td className="text-align-center border-1-5">Redemption Date</td>
                    <td className="text-align-center border-1-5">Coupon Code</td>
                    <td className="text-align-center border-1-5">Type</td>
                    <td className="text-align-center border-1-5">Offer</td>
                    <td className="text-align-center border-1-5">Customer Number</td>
                    <td className="text-align-center border-1-5">Customer Name</td>
                    <td className="text-align-center border-1-5">Purpose</td>
                    <td className="text-align-center border-1-5">No. of Tyres</td>
                    <td className="text-align-center border-1-5">Coupon Amount</td>
                    <td className="text-align-center border-1-5">Coupon Amount Paid</td>
                  </tr>
                  {this.state.coupon.map((row, i) => (
                    <tr
                      className={classnames(
                        { "active-row": row.active },
                        "coupon-list-row"
                      )}
                    >
                      <td className="text-align-center border-1-5">
                        {formatDate(row.redeemed_at)}
                      </td>
                      <td className="text-align-center border-1-5">
                        {row.coupon_code}
                      </td>
                      <td className="text-align-center">
                        {row.type}
                      </td>

                      <td className="text-align-center border-1-5">
                        <label data-tooltip={row.offer_details} style={{ "cursor": "pointer" }}
                          data-tooltip-position="top" className="tool-tip">
                          <span className="textwrap">{row.offer_details}</span>
                        </label>
                      </td>
                      <td className="text-align-center border-1-5">
                        {row.phone_number}
                      </td>
                      <td className="text-align-center border-1-5">
                        {row.customer_name}
                      </td>
                      <td className="text-align-center border-1-5">
                        {row.purpose}
                      </td>
                      <td className="text-align-center border-1-5">
                        {/* {row.maximum_tyres_per_coupon} */}
                        {row.tyre_quantity}
                      </td>
                      <td className="text-align-center border-1-5">
                        {/* {row.discount_per_coupon} */}
                        {row.discount_amount}
                      </td>
                      <td className="text-align-center border-1-5">
                        {row.paid_by}
                      </td>

                    </tr>
                  ))}
                </tbody>
              </Table>
              {count <= 0 && this.state.filterApplied && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%" }}
                >
                  There is no customer found with applied filter. Please change filter criteria.
                </div>
              )}
              {count > 0 && (
                <div
                  className="d-flex-row"
                  style={{ marginTop: "10px", justifyContent: "flex-end" }}
                >
                  <span className="Rows-per-page">Rows per page:</span>
                  <select
                    className="form-control rows-per-page-select"
                    name="pageSize"
                    value={pageSize}
                    onChange={e => this.handleInputChange(e)}
                    style={{ width: "65px" }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                  &nbsp;&nbsp;
                  <div style={{ marginTop: "5px" }}>
                    <Pagination
                      margin={2}
                      page={this.state.page}
                      count={this.state.totalPages}
                      pageSize={this.state.pageSize}
                      totalRecords={count}
                      onPageChange={this.handlePageChange}
                    ></Pagination>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };
}

export default redeemCouponReport;
