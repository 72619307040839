import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import _ from "lodash";
import "./AddNewVehicle.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import CustomerManagementService from "../../services/CustomerManagementService";
import WarrantyRegistrationService from "../../services/WarrantyRegistrationService";
import swal from "sweetalert";
import Validation from "../../services/Validation";
import VehicleRegistrationNumber from "../WarrantyRegistration/VehicleDetails/VehicleRegistrationNumber";
import ModalService from "../../services/ModalService";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { messages } from "../../constants/constant.message";
const { ERROR_MESSAGE } = messages.COMMON;

class AddNewVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registeredVehicleDetails: props.registeredVehicleDetails
        ? props.registeredVehicleDetails
        : [],
      customer_vehicle_id: null,
      customer_id: null,
      isLoading: false,
      controls: {
        registrationNumber: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicle_category: {
          value: "personal",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicle_type_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicle_type_brand_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicle_type_brand_model_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehical_avg_monthly_running: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicle_type_brand_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        vehicle_type_brand_model_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      convertToTextBox: false,
      isValidForm: false,
      vehicleTypeData: [],
      vehicleMakeData: [],
      vehicleModelData: [],
    };
    this.childVehicleRegistrationNumber = React.createRef();
  }

  setEditVehicleData = (data) => {
    let { controls } = this.state;
    controls.vehicle_category.value = data.vehicle_category;
    controls.registrationNumber.value = data.registration_number;
    controls.vehicle_type_id.value = data.vehicle_type_id;
    controls.vehicle_type_brand_id.value = data.vehicle_type_brand_id;
    controls.vehicle_type_brand_name.value = data.vehicle_type_brand_name;
    controls.vehicle_type_brand_model_name.value =
      data.vehicle_type_brand_model_name;
    controls.vehical_avg_monthly_running.value =
      data.vehical_avg_monthly_running;
    controls.vehicle_type_brand_model_id.value = {
      model_id: data.vehicle_type_brand_model_id,
      name: "",
    };
    if (
      data.vehicle_type_id === "e018ede2-a9a6-4cc0-bc0d-8cc931bb70c9" ||
      data.vehicle_type_id === "4d4c8085-66c0-4e02-80f4-374fceaa154d"
    ) {
      this.setState({
        convertToTextBox: true,
      });
    } else {
      this.setState({
        convertToTextBox: false,
      });
    }
    if (controls.registrationNumber.value) {
      let regNum = controls.registrationNumber.value.split(/[.\-]/);
      this.childVehicleRegistrationNumber.current.state.localControls.registration1.value =
        regNum.length > 0 ? regNum[0] : "";
      this.childVehicleRegistrationNumber.current.state.localControls.registration1.touched = true;
      this.childVehicleRegistrationNumber.current.state.localControls.registration2.value =
        regNum.length > 1 ? regNum[1] : "";
      this.childVehicleRegistrationNumber.current.state.localControls.registration2.touched = true;
      this.childVehicleRegistrationNumber.current.state.localControls.registration3.value =
        regNum.length > 2 ? regNum[2] : "";
      this.childVehicleRegistrationNumber.current.state.localControls.registration3.touched = true;
      this.childVehicleRegistrationNumber.current.state.localControls.registration4.value =
        regNum.length > 3 ? regNum[3] : "";
      this.childVehicleRegistrationNumber.current.state.localControls.registration4.touched = true;
      this.childVehicleRegistrationNumber.current.state.isDisabled = true;
      this.childVehicleRegistrationNumber.current.handleValidation();
    }
    this.setState({
      customer_vehicle_id: data.customer_vehicle_id,
      controls,
    });
    this.getVehicleMake();
    this.getVehicleModel();
    this.handleValidation(true);
  };

  componentDidMount() {
    this.getVehicleType();
    this.setState({
      customer_id: this.props.customer_id,
    });
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (controlName === "vehical_avg_monthly_running") {
      if (!isNaN(e.target.value)) {
        controls[controlName].value = controlValue;
      }
    } else {
      controls[controlName].value = controlValue;
    }
    controls[controlName].touched = true;
    this.setState({ controls });
    if (controlName === "vehicle_type_id") {
      controls.vehicle_type_brand_id.value = "";
      controls.vehicle_type_brand_model_id.value = "";
      if (
        controlValue === "e018ede2-a9a6-4cc0-bc0d-8cc931bb70c9" ||
        controlValue === "4d4c8085-66c0-4e02-80f4-374fceaa154d"
      ) {
        this.setState({
          convertToTextBox: true,
        });
      } else {
        this.setState({
          convertToTextBox: false,
        });
      }
      this.setState({
        controls,
      });

      this.getVehicleMake();
    } else if (controlName === "vehicle_type_brand_id") {
      controls.vehicle_type_brand_model_id.value = "";
      this.setState({
        controls,
      });
      this.getVehicleModel();
    }
    this.handleValidation();
  };

  getVehicleType = () => {
    CustomerManagementService.getVehicleType()
      .then((res) => {
        let { vehicleTypeData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          vehicleTypeData = res.data.data;
        } else {
          vehicleTypeData = [];
        }
        this.setState({
          vehicleTypeData,
        });
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        //this.setState({ isLoading: false });
      });
  };

  async getVehicleMake() {
    let { controls, vehicleMakeData, vehicleModelData } = this.state;
    try {
      if (!controls.vehicle_type_id.value) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      this.props.isDataLoading(true);
      const { data } = await WarrantyRegistrationService.getVehicalMake({
        vehicle_type_id: controls.vehicle_type_id.value,
      });
      this.props.isDataLoading(false);
      vehicleModelData = [];
      if (data && data.data.length > 0) {
        vehicleMakeData = data.data;
      } else {
        vehicleMakeData = [];
      }
      this.setState({
        isLoading: false,
      });
      this.setState((prevState) => {
        return { ...prevState, vehicleMakeData, controls, vehicleModelData };
      });
      this.handleValidation();
    } catch (error) {
      this.props.isDataLoading(false);
      this.setState({
        isLoading: false,
      });
      this.setState((prevState) => {
        return {
          ...prevState,
          vehicleMakeData: [],
          vehicleModelData: [],
          controls,
        };
      });
      console.log(error);
    }
  }

  async getVehicleModel() {
    try {
      const { controls } = this.state;
      let { vehicle_type_brand_id, vehicleModelData } = controls;
      this.setState({
        isLoading: true,
      });
      this.props.isDataLoading(true);
      const { data } = await WarrantyRegistrationService.getVehicalModel({
        vehicle_type_brand_id: vehicle_type_brand_id.value,
      });
      this.props.isDataLoading(false);
      if (data && data.data.length > 0) {
        let model = _.filter(data.data, (element) => {
          return (
            element.model_id ===
            controls.vehicle_type_brand_model_id.value.model_id
          );
        });
        if (model.length > 0) {
          controls.vehicle_type_brand_model_id.value = model[0];
        }
        vehicleModelData = data.data;
      } else {
        vehicleModelData = [];
      }
      this.setState({
        isLoading: false,
      });
      this.setState((prevState) => {
        return { ...prevState, vehicleModelData, controls };
      });
      this.handleValidation();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      this.props.isDataLoading(false);
      console.log(error);
    }
  }

  vechileModelClick = (data) => {
    const { controls } = this.state;
    controls["vehicle_type_brand_model_id"].value = data;
    controls["vehicle_type_brand_model_id"].touched = true;
    this.setState({ controls });
    this.handleValidation();
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isValidForm } = this.state;
    let {
      vehicle_type_id,
      // vehicle_type_brand_model_id,
      // vehicle_type_brand_id
    } = controls;
    if (vehicle_type_id.touched === true || isSubmit) {
      vehicle_type_id = Validation.notNullValidator(vehicle_type_id);
      vehicle_type_id.valid = !vehicle_type_id.nullValue;
      if (vehicle_type_id.valid === false) {
        vehicle_type_id.showErrorMsg = true;
      } else {
        vehicle_type_id.showErrorMsg = false;
      }
    }

    // if (vehicle_type_brand_model_id.touched === true || isSubmit) {
    //   vehicle_type_brand_model_id = Validation.notNullValidator(
    //     vehicle_type_brand_model_id
    //   );
    //   vehicle_type_brand_model_id.valid = !vehicle_type_brand_model_id.nullValue;
    //   if (vehicle_type_brand_model_id.valid === false) {
    //     vehicle_type_brand_model_id.showErrorMsg = true;
    //   } else {
    //     vehicle_type_brand_model_id.showErrorMsg = false;
    //   }
    // }

    // if (vehicle_type_brand_id.touched === true || isSubmit) {
    //   vehicle_type_brand_id = Validation.notNullValidator(
    //     vehicle_type_brand_id
    //   );
    //   vehicle_type_brand_id.valid = !vehicle_type_brand_id.nullValue;
    //   if (vehicle_type_brand_id.valid === false) {
    //     vehicle_type_brand_id.showErrorMsg = true;
    //   } else {
    //     vehicle_type_brand_id.showErrorMsg = false;
    //   }
    // }

    // // let isValidRegNumber = true;
    // // if (isSubmit) {
    // //   isValidRegNumber = false;
    // // }
    // let isValidRegNumber = true;
    // if (this.childVehicleRegistrationNumber.current) {
    //   if (
    //     this.childVehicleRegistrationNumber.current.state.localControls
    //       .registration1.value ||
    //     this.childVehicleRegistrationNumber.current.state.localControls
    //       .registration2.value ||
    //     this.childVehicleRegistrationNumber.current.state.localControls
    //       .registration4.value
    //   ) {
    //     if (this.childVehicleRegistrationNumber.current.handleValidation()) {
    //       isValidRegNumber = true;
    //     } else {
    //       isValidRegNumber = false;
    //     }
    //   } else {
    //     isValidRegNumber = false;
    //   }
    // }

    if (
      // vehicle_type_brand_id.valid === true &&
      // vehicle_type_brand_model_id.valid === true &&
      vehicle_type_id.valid === true
      // &&
      // isValidRegNumber
    ) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({ controls, isValidForm });
    return isValidForm;
  };

  addVehicle = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    const { controls } = this.state;
    let reqData = {
      phone_number: this.props.phone_number,
      vehicle_type_id: controls.vehicle_type_id.value,
      vehicle_category: controls.vehicle_category.value,
      registration_number: controls.registrationNumber.value,
      vehical_avg_monthly_running: controls.vehical_avg_monthly_running.value,
    };
    if (this.state.convertToTextBox) {
      reqData.vehicle_type_brand_name = controls.vehicle_type_brand_name.value;
      reqData.vehicle_type_brand_model_name =
        controls.vehicle_type_brand_model_name.value;
    } else {
      reqData.vehicle_type_brand_model_id =
        controls.vehicle_type_brand_model_id.value &&
        Object.keys(controls.vehicle_type_brand_model_id.value).length > 0
          ? controls.vehicle_type_brand_model_id.value.model_id
          : "";
      reqData.vehicle_type_brand_id = controls.vehicle_type_brand_id.value;
    }

    if (this.state.customer_vehicle_id) {
      reqData.vehicle_id = this.state.customer_vehicle_id;
      reqData.customer_id = this.props.customer_id;
    }
    // let {
    //   registration1,
    //   registration2,
    //   registration3,
    //   registration4,
    // } = this.childVehicleRegistrationNumber.current.state.localControls;
    this.setState({
      isLoading: true,
    });
    this.props.isDataLoading(true);
    CustomerManagementService.addVehicle(reqData)
      .then((res) => {
        this.setState({ isLoading: false });
        if (!this.state.customer_vehicle_id) {
          swal({
            title: res.data.message,
            icon: "success",
            type: "success",
            text: "Do you want to add more vehicles ?",
            buttons: ["No", "Yes"],
          }).then((isConfirm) => {
            if (isConfirm) {
              this.addNewVehicle();
              this.setState({ isLoading: false });
            } else {
              this.props.switchOpenPage(0, {
                customer_id: res.data.data.customer_id,
              });
              this.setState({ isLoading: false });
            }
          });
        } else {
          this.setState({ isLoading: false });
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success",
          }).then((isConfirm) => {
            if (isConfirm) {
              this.props.switchOpenPage(0, {
                customer_id: res.data.data.customer_id,
              });
            }
          });
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  addNewVehicle = () => {
    let { controls } = this.state;

    controls["registrationNumber"].value = "";
    controls["vehicle_category"].value = "personal";
    controls["vehicle_type_id"].value = "";
    controls["vehicle_type_brand_id"].value = "";
    controls["vehicle_type_brand_model_id"].value = "";
    controls["vehical_avg_monthly_running"].value = null;
    this.childVehicleRegistrationNumber.current.state.localControls.registration1.value =
      "";
    this.childVehicleRegistrationNumber.current.state.localControls.registration1.touched = false;
    this.childVehicleRegistrationNumber.current.state.localControls.registration2.value =
      "";
    this.childVehicleRegistrationNumber.current.state.localControls.registration2.touched = false;
    this.childVehicleRegistrationNumber.current.state.localControls.registration3.value =
      "";
    this.childVehicleRegistrationNumber.current.state.localControls.registration3.touched = false;
    this.childVehicleRegistrationNumber.current.state.localControls.registration4.value =
      "";
    this.childVehicleRegistrationNumber.current.state.localControls.registration4.touched = false;

    this.setState({ controls, registeredVehicleDetails: [] });
  };

  goBack = () => {
    this.props.switchOpenPage(0, {
      customer_id: this.state.customer_id,
    });
  };

  updateState = (params) => {
    //let { controls } = this.state;
    this.setState({
      controls: params.vehicleDetails.controls,
      isValidForm: params.vehicleDetails.isValidForm,
    });
    this.handleValidation();
    //this.props.updateState({ visibleArea: 'vehicleRegistrationNumber', isSameRoute: true, vehicleDetails: { controls, isValidForm }, vehicleLocalRegistrationNumber: localControls });
  };

  render() {
    let {
      vehicleTypeData,
      controls,
      vehicleMakeData,
      vehicleModelData,
      isValidForm,
    } = this.state;
    let vehicleDetails = {
      controls: controls,
    };
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <div className="drawer-header">
          <span className="drawer-title">
            {this.state.customer_vehicle_id
              ? "Edit Vehicle"
              : "Add new vehicle"}
          </span>
        </div>
        <hr style={{ border: "solid 1px #ced4da" }} />
        <div className="Fix-height" id="addVehicle">
          <div className="custom-drawer-content-wrapper">
            <Row style={{ marginLeft: "15px", marginRight: 0 }}>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label for="common">
                    Vehicle Type<span className="required-field">*</span>
                  </Label>
                  <select
                    className="form-control select-height-70rem text-capitalize"
                    name="vehicle_type_id"
                    value={controls.vehicle_type_id.value}
                    disabled={this.state.customer_vehicle_id ? true : false}
                    onChange={(e) => this.handleInputChange(e)}
                  >
                    <option
                      className="text-capitalize"
                      selected="selected"
                      value=""
                      disabled
                    >
                      Select
                    </option>
                    {vehicleTypeData.map((item) => (
                      <option value={item.vehicle_type_id}>{item.name}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                {!this.state.convertToTextBox ? (
                  <FormGroup>
                    <Label for="common">Vehicle Make</Label>
                    <select
                      className="form-control select-height-70rem"
                      name="vehicle_type_brand_id"
                      // disabled={this.state.customer_vehicle_id ? true : false}
                      value={controls.vehicle_type_brand_id.value}
                      onChange={(e) => {
                        this.handleInputChange(e);
                      }}
                    >
                      <option selected="selected" value="" disabled>
                        Select
                      </option>
                      {vehicleMakeData.map((item) => (
                        <option value={item.brand_id}>{item.name}</option>
                      ))}
                    </select>
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label for="common">Vehicle Make</Label>
                    <Input
                      type="text"
                      placeholder="Vehicle brand name"
                      className="form-control select-height-70rem"
                      name="vehicle_type_brand_name"
                      value={controls.vehicle_type_brand_name.value}
                      onChange={(e) => {
                        this.handleInputChange(e);
                      }}
                    ></Input>
                  </FormGroup>
                )}
              </Col>
              <Col xl="8" md="12" xs="12">
                {!this.state.convertToTextBox ? (
                  <FormGroup>
                    <Label for="common">Vehicle Model</Label>
                    <div className="d-flex-row vehical-sku-id-search add-new-vehicle-model">
                      <Typeahead
                        // disabled={this.state.customer_vehicle_id ? true : false}
                        id="vehicle_type_brand_model_id"
                        selected={
                          controls.vehicle_type_brand_model_id.value
                            ? [controls.vehicle_type_brand_model_id.value]
                            : []
                        }
                        filterBy={(option, props) => {
                          if (props.selected.length) {
                            return true;
                          }
                          return (
                            option.name
                              .toLowerCase()
                              .indexOf(props.text.toLowerCase()) !== -1 ||
                            option.name.toLowerCase().indexOf("other") !== -1
                          );
                        }}
                        labelKey="name"
                        options={vehicleModelData}
                        placeholder="Search..."
                        onChange={(selectedModel) =>
                          this.vechileModelClick(selectedModel[0])
                        }
                      />
                      <FontAwesomeIcon
                        className="serach-icon"
                        icon={faSearch}
                        color="#6e777f"
                      />
                    </div>
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label for="common">Vehicle Model</Label>
                    <div className="d-flex-row vehical-sku-id-search add-new-vehicle-model">
                      <Input
                        type="text"
                        name="vehicle_type_brand_model_name"
                        placeholder="Vehicle name"
                        value={controls.vehicle_type_brand_model_name.value}
                        onChange={(e) => {
                          this.handleInputChange(e);
                        }}
                      />
                    </div>
                  </FormGroup>
                )}
              </Col>

              <Col xl="12" md="12" xs="12">
                <div className="margin-bottom-10 radio-main-lbl">
                  Types of ownership of the vehicle
                  {/* <span className="required-field">*</span> */}
                </div>
                <div className="d-flex-row">
                  <label
                    className="container"
                    style={{ marginRight: "-360px" }}
                  >
                    <span className="margin-left-10">Personal</span>
                    <input
                      type="radio"
                      name="vehicle_category"
                      value="personal"
                      checked={controls.vehicle_category.value === "personal"}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="container">
                    <span className="margin-left-10">
                      Taxi (Uber,OLA,Private Taxi etc.)
                    </span>
                    <input
                      type="radio"
                      name="vehicle_category"
                      value="taxi"
                      checked={controls.vehicle_category.value === "taxi"}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </Col>

              <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                <VehicleRegistrationNumber
                  vehicleDetails={vehicleDetails}
                  from="AddNewCustomer"
                  ref={this.childVehicleRegistrationNumber}
                  // registeredVehicleDetails={this.state.registeredVehicleDetails}
                  updateState={this.updateState}
                />
              </div>
              <br />
              <Col xl="6" md="12" xs="12" style={{ marginTop: "15px" }}>
                <FormGroup>
                  <Label for="common">Average Monthly Usage(KMs)</Label>
                  <Input
                    autoComplete="nofill"
                    type="text"
                    name="vehical_avg_monthly_running"
                    id="vehical_avg_monthly_running"
                    value={controls.vehical_avg_monthly_running.value}
                    onChange={(e) => this.handleInputChange(e)}
                    placeholder="Type here"
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
        <Row style={{ marginLeft: "0", marginRight: "0" }}>
          <Col
            xl="12"
            xs="12"
            md="12"
            className="text-align-right footer-drawer"
          >
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
              onClick={() => this.goBack()}
            >
              Back
            </Button>
            &nbsp;&nbsp;
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-save-button"
              disabled={!isValidForm}
              color="warning"
              onClick={() => {
                this.addVehicle();
              }}
            >
              {this.state.customer_vehicle_id ? "Save" : "Add"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default AddNewVehicle;
