import { request } from "./Request";
import { routes } from "../constants/constant.route";

const API_URL = process.env.REACT_APP_API_URL;

export default class PrimaryPointsReportService {
  static getDelearReport(userId, year) {
    let qString = ``;

    if (userId) {
      qString = `?u=${userId}`;
    }
    if (userId && year) {
      qString += `&y=${year}`;
    } else if (!userId && year) {
      qString = `?y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${routes.PRIMARY_REPORT.GET_DELEAR_REPORT_QUARTERLY}${qString}`,
      null,
      null,
      null
    );
  }

  static getOfftakeSummary(userId, year) {
    let qString = ``;

    if (userId) {
      qString = `?u=${userId}`;
    }
    if (userId && year) {
      qString += `&y=${year}`;
    } else if (!userId && year) {
      qString = `?y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${routes.PRIMARY_REPORT.OFFTAKE_SUMMARY}${qString}`,
      null,
      null,
      null
    );
  }

  static getAllDealerReport(page = 1, limit = 10, search, uuid, year) {
    let qp = `page=${page}&limit=${limit}`;
    if (!!search) {
      qp += `&q=${search}`;
    }
    if (uuid) {
      qp += `&u=${uuid}`;
    }
    if (year) {
      qp += `&y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${routes.PRIMARY_REPORT.GET_ALL_DEALER_REPORT}?${qp}`,
      null,
      null,
      null
    );
  }

  static getTopSkuOfftakeTrend(uuid, year,category) {
    let qp = ``;
    if (uuid) {
      qp += `u=${uuid}&`;
    }
    if (year) {
      qp += `y=${year}&`;
    }
    if (category) {
      qp += `category=${category}&`;
    }

    return request(
      "GET",
      `${API_URL}${routes.PRIMARY_REPORT.GET_TOP_SKU_OFFTAKE_TREND}?${qp}`,
      null,
      null,
      null
    );
  }

  static getTopSkuAreaTrend( uuid, year,category) {
    let qp = ``;
    if (uuid) {
      qp += `u=${uuid}&`;
    }
    if (year) {
      qp += `y=${year}&`;
    }
    if (category) {
      qp += `category=${category}&`;
    }
    
    return request(
      "GET",
      `${API_URL}${routes.PRIMARY_REPORT.GET_TOP_SKU_AREA_TREND}?${qp}`,
      null,
      null,
      null
    );
  }

  static getTopSkuWarrantyTrend( uuid, year,category) {
    let qp = ``;
    if (uuid) {
      qp += `u=${uuid}&`;
    }
    if (year) {
      qp += `y=${year}&`;
    }
    if (category) {
      qp += `category=${category}&`;
    }
    
    return request(
      "GET",
      `${API_URL}${routes.PRIMARY_REPORT.GET_TOP_SKU_WARRANTY_TREND}?${qp}`,
      null,
      null,
      null
    );
  }

  static getAllMonthOfftakeTrend( uuid, year,category) {
    let qp = ``;
    if (uuid) {
      qp += `u=${uuid}&`;
    }
    if (year) {
      qp += `y=${year}&`;
    }
    if (category) {
      qp += `category=${category}&`;
    }
    
    return request(
      "GET",
      `${API_URL}${routes.PRIMARY_REPORT.GET_ALL_MONTH_OFFTAKE_TREND}?${qp}`,
      null,
      null,
      null
    );
  }

   static getDealerReportTargetAchievement(uuid, month,year,category) {
    let qp = ``;
    if (uuid) {
      qp += `u=${uuid}&`;
    }
     if (month) {
      qp += `m=${month}&`;
    }
    if (year) {
      qp += `y=${year}&`;
    }
    if (category) {
      qp += `category=${category}&`;
    }
    
    return request(
      "GET",
      `${API_URL}${routes.PRIMARY_REPORT.GET_DEALER_REPORT_TARGET_ACHIEVEMENT}?${qp}`,
      null,
      null,
      null
    );
  }
}
