import React, { Component, Fragment } from 'react';
import {
  Row, Col,
} from 'reactstrap';
import HODashboardCategoryWiseCard from '../HODashboardCategoryWiseCard/HODashboardCategoryWiseCard';
import HODashboardClassWiseCard from '../HODashboardClassWiseCard/HODashboardClassWiseCard';
import StorageService from '../../services/Storage';
import { Roles } from '../../constants/constant.role';

class HODashboardProductWiseCard extends Component {


  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      selectedCategory: 'PCR',
      offtakeAndCustomerCountByDealer: [],
      totalOfftakeByCustomeClass: [],
      totalOfftakeDealerWise: [],
      totalOfftakeCategoryWise: [],
      category: [{ 'label': 'PCR', value: 'PCR' },
        // { 'label': 'TBB', value: 'TBB' },
        // { 'label': 'TBR', value: 'TBR' },
        // { 'label': 'SCV', value: 'SCV' },
        // { 'label': 'LCV', value: 'LCV' },
        // { 'label': '2/3 Wheeler', value: '2/3 W' },
        // { 'label': 'FARM', value: 'Farm' },
        // { 'label': 'OTR', value: 'OTR' }
      ],
      dealerRole: null
    }
  }

  changeTab = (activeTab, selectedCategory) => {
    this.setState((prevState) => {
      return { ...prevState, activeTab: activeTab, selectedCategory: selectedCategory };
    });
    if (this.props.offtakeAndCustomerCountByCategory.length > 0) {
      setTimeout(() => {
        const index = this.props.offtakeAndCustomerCountByCategory.findIndex(o => o.category === this.state.selectedCategory);
        const selectedItem = this.props.offtakeAndCustomerCountByCategory[index];
        const {
          totalOfftake: totalOfftakeCategoryWise,
          customerClass: totalOfftakeByCustomeClass,
          offtake: totalOfftakeDealerWise
        } = selectedItem;
        this.setState({ totalOfftakeCategoryWise, totalOfftakeByCustomeClass, totalOfftakeDealerWise })
      }, 500);
    }
  }

  componentDidMount() {
    const userDetail = StorageService.getUserDetail();
    const { type } = userDetail;
    this.setState({ dealerRole: type });
  }

  componentWillReceiveProps(props) {
    const { offtakeAndCustomerCountByCategory } = props;
    if (offtakeAndCustomerCountByCategory.length > 0) {
      const index = offtakeAndCustomerCountByCategory.findIndex(o => o.category === this.state.selectedCategory);
      const selectedItem = offtakeAndCustomerCountByCategory[index];
      const {
        totalOfftake: totalOfftakeCategoryWise,
        customerClass: totalOfftakeByCustomeClass,
        offtake: totalOfftakeDealerWise
      } = selectedItem;
      this.setState({ totalOfftakeCategoryWise, totalOfftakeByCustomeClass, totalOfftakeDealerWise, totalDealers: props.totalDealers })
    }
  }

  render() {
    const { totalOfftakeCategoryWise, totalOfftakeByCustomeClass, totalOfftakeDealerWise, category, totalDealers, dealerRole } = this.state;
    const { findFirstQuarterMonthsToCurrentMonths, currentQuarterMonths, currentMonth, isArea, currentNav, isClassWiseCardLoading, isCategoryWiseCardLoading, label } = this.props;

    return <Fragment>
      <Row id="ho-dashboard">
        <Col xs="12" style={{ padding: '4px' }}>
          <div className="d-flex-row">
            {/* <span className="Product-wise">PRODUCT WISE</span> */}
            <div className='d-flex-row'>
              {
                category.map((item, index) => (
                  <span onClick={() => this.changeTab(index, item.value)} className={this.state.activeTab === index ? 'custom-rc-tab-active custom-rc-tab ml-0' : 'custom-rc-tab-deactive custom-rc-tab ml-0'}>{item.label}</span>))
              }
            </div>
          </div>
        </Col>
        {!(isArea || dealerRole === Roles.AREA_MANAGER_ROLE) && <Col sm="6" style={{ padding: '4px' }}>
          <HODashboardCategoryWiseCard
            offtake={totalOfftakeDealerWise}
            totalOfftake={totalOfftakeCategoryWise}
            getHeadOfficeReport={this.props.getHeadOfficeReport}
            findFirstQuarterMonthsToCurrentMonths={findFirstQuarterMonthsToCurrentMonths}
            currentQuarterMonths={currentQuarterMonths}
            currentMonth={currentMonth}
            isLoading={isCategoryWiseCardLoading}
            label={label}
          ></HODashboardCategoryWiseCard>
        </Col>}
        <Col sm="6" style={{ padding: '4px' }}>
          <HODashboardClassWiseCard
            offtake={totalOfftakeByCustomeClass}
            totalDealers={totalDealers}
            findFirstQuarterMonthsToCurrentMonths={findFirstQuarterMonthsToCurrentMonths}
            currentQuarterMonths={currentQuarterMonths}
            currentMonth={currentMonth}
            currentNav={currentNav}
            selectedMonth={this.props.selectedMonth}
            selectedYear={this.props.selectedYear}
            label={label}
            isLoading={isClassWiseCardLoading}
          ></HODashboardClassWiseCard>
        </Col>
      </Row>
    </Fragment>
  }
}

export default HODashboardProductWiseCard;