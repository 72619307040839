import React, { Component } from 'react';
import {
  Row,
  Col,
} from "reactstrap";
import Sidebar from "react-sidebar";
import { connect } from "react-redux";
import './NotificationPreference.css'
// import ServicesPreference from '../../components/ServicesPreference/ServicesPreference';
import { setAddCustomerRedirect } from '../../actions/UserAction';
import Storage from '../../services/Storage';
import { withRouter } from "react-router-dom";
import ReferralRewardsPreference from '../../components/ReferralRewardsPreference/ReferralRewardsPreference';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';
import ModalServicePref from '../DealerDashboard/ModalServicePref';
import { Permissions } from '../../constants/constant.permissions';
class NotificationPreference extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openServicePref: false,
      is_from_add_customer: false,
      userDetailData: Storage.getUserDetail(),
      dashborad_path: '/',
      openRewardReff: false
    }
    const query = new URLSearchParams(this.props.location.search);
    this.state.is_from_add_customer = query.get('is_from_add_customer');
    if (this.state.userDetailData.permissions && this.state.userDetailData.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)) {
      this.state.dashborad_path = "/dealer-dashboard";
    }
  }
  componentDidMount() {
    GoogleAnalticsService.pageView('Notification Preference View');
    if (this.state.is_from_add_customer === 'true' || this.state.is_from_add_customer === 'false') {
      this.setState({
        openServicePref: true
      })
    }
  }
  openServicePref = () => {

    // if (this.state.openServicePref) {
    //   if (this.state.is_from_add_customer === 'true' || this.state.is_from_add_customer === 'false') {
    //     this.props.setAddCustomerRedirect({ redirectToAddCustomer: true })
    //     this.props.history.push(this.state.dashborad_path);
    //   }
    // }
    this.refs.modalServicePref.toggle({ is_from_add_customer_visit: false });
    // this.setState({
    //   openServicePref: !this.state.openServicePref
    // })
  }
  openRewardReff = () => {
    this.setState({
      openRewardReff: !this.state.openRewardReff
    })
  }
  render() {
    return (
      <div className="d-flex-column" id="NotificationPreference">
        <span className="header-title">Your Preferences</span>

        {/* 
        {this.state.openServicePref && (
          <Sidebar
            sidebar={
              <ServicesPreference
                closeSideBar={this.openServicePref}
              />
            }
            open={this.state.openServicePref}
            sidebarClassName="subsdiaries-sidebar"
            pullRight
            styles={{
              sidebar: { background: "white", height: "100%", width: "35%" }
            }}
          />
        )} */}
        <ModalServicePref
          from="notification-pref"
          is_first_service_pref={false}
          ref="modalServicePref" />

        {this.state.openRewardReff && (
          <Sidebar
            sidebar={
              <ReferralRewardsPreference
                closeSideBar={this.openRewardReff}
              />
            }
            open={this.state.openRewardReff}
            sidebarClassName="subsdiaries-sidebar"
            pullRight
            styles={{
              sidebar: { background: "white", height: "100%", width: "40%" }
            }}
          />
        )}


        <Row className="mt-4 ml-0">
          <Col xs="4" className="servicespreference-wrapper cursor-pointer"
            onClick={() => this.openServicePref()}>
            <Row className="pr-3" style={{ height: '100%' }}>
              <Col xs="12" className="d-flex justify-content-between mt-auto mb-auto">
                <div className="d-flex-column">
                  <span className="servicespreference mb-2">Services Preference</span>
                  <span className="servicespreference-note">Modify Services and Set Service Span</span>
                </div>
                <div className="mt-auto mb-auto">
                  <img src="/images/right.svg" alt="" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3 ml-0">
          <Col xs="4" className="servicespreference-wrapper cursor-pointer"
            onClick={() => this.openRewardReff()}>
            <Row className="pr-3" style={{ height: '100%' }}>
              <Col xs="12" className="d-flex justify-content-between mt-auto mb-auto">
                <div className="d-flex-column">
                  <span className="servicespreference mb-2">Referral Rewards Preference</span>
                  <span className="servicespreference-note w-100">Set offers for Referrer and Referee customers</span>
                </div>
                <div className="mt-auto mb-auto">
                  <img src="/images/right.svg" alt="" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

//export default NotificationPreference;
const mapDispatchToProps = dispatch => ({
  setAddCustomerRedirect: (redirectDetailsAddCustomer) => dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
});

export default withRouter(connect(
  null,
  mapDispatchToProps
)(NotificationPreference));