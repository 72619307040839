import React, { Component, Fragment } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import LcvPoints from './LcvPoints';
import ScvPoints from './ScvPoints';

class LcvScvPoints extends Component {
  lcvPoints = {
    "headers": [
      "Tyre Offtake Quarterly",
      "LCV Bias Points/Tyre",
      "Consistency Bonus"
    ],
    "biasPoints": [
      [
        "20-29",
        "50",
        "10% Additional points"
      ],
      [
        "30-49",
        "2*50"
      ],
      [
        "30-49",
        "2*50"
      ],
      [
        "50-79",
        "2.5*50"
      ],
      [
        "80-109",
        "3*50"
      ],
      [
        "110-149",
        "4.5*50"
      ],
      [
        "150-224",
        "5*50"
      ],
      [
        "225-274",
        "5.5*50",
        "20% Additional points"
      ],
      [
        "275-324",
        "6*50"
      ],
      [
        "325-339",
        "6.5*50"
      ],
      [
        "400-499",
        "7*50"
      ],
      [
        "500 & Above",
        "8*50"
      ]
    ],
    "radialPoints": [
      "75% Points/Tyre"
    ],
    "note": "For nay dealer tp Qualify for CRM Points in any category; the dealer has to do minimum 20 Tyres in a quarter in that respective category."
  }
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  getStripedStyle(index) {
    return { background: index % 2 === 0 ? '#f6f6f6' : 'white' };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    let lcvScvPoints = this.props.lcvScvPoints;
    return (
      <Fragment>
        <div className="PASSENGER-CAR-RADIAL-label">
          {lcvScvPoints.type}
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              LCV
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              SCV
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <LcvPoints lcvPoints={lcvScvPoints} />
          </TabPane>
          <TabPane tabId="2">
            <ScvPoints scvPoints={lcvScvPoints} />
          </TabPane>
        </TabContent>
      </Fragment>
    );
  }
}

export default LcvScvPoints;