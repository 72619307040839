import { action } from '../constants/constant.action';
const {
  SET_USER_DETAIL, IS_PROFILE_UPDATE, SET_MERCHANDISE_CART,
  SET_DEALER_DASHBOARD_REDIRECT_DETAIL,
  SET_ADD_CUSTOMER_REDIRECT, SET_REDIRECT, SET_HELP_GUIDE_REDIRECT
} = action;

export default function reducer(state = {
  user: null
}, action) {
  switch (action.type) {

    case SET_USER_DETAIL:
      return {
        ...state,
        user: action.user
      };

    case IS_PROFILE_UPDATE:
      return {
        ...state,
        isProfileUpdate: action.isProfileUpdate
      };

    case SET_MERCHANDISE_CART:
      return {
        ...state,
        placeOrderCartItems: action.placeOrderCartItems
      };

    case SET_DEALER_DASHBOARD_REDIRECT_DETAIL:
      return {
        ...state,
        redirectDetails: action.redirectDetails
      };

    case SET_ADD_CUSTOMER_REDIRECT:
      return {
        ...state,
        redirectDetailsAddCustomer: action.redirectDetailsAddCustomer
      };
    case SET_REDIRECT:
      return {
        ...state,
        redirectDetails: action.redirectDetails
      };
    case SET_HELP_GUIDE_REDIRECT:
      return {
        ...state,
        helpGuide: action.helpGuide
      };
    default:
      return state;
  }
}