import React, { Component, Fragment } from "react";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Form,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import "./filterPopUp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
// services
import CampaignService from "../../services/CampaignService";
import "rc-time-picker/assets/index.css";
import { messages } from "../../constants/constant.message";
import Storage from "../../services/Storage";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { Roles } from "../../constants/constant.role";
// import Moment from "react-moment";

class FilterPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      purpose: [],
      service: [],
      festival: [],
      cc_self: false,
      time: "",
      total_customer: "",
      total_custom_customer_count: "",
      smsConfig: {
        isNotValidMinCount: false,
        length: 0,
        smsContentCredit: 0
      },
      maxCount: 459,
      tags: [],
      selectedTags: [],
      highLightTag: [],
      isLoading: false,
      tooltipText: "",
      obj: {},
      controls: {
        campaign_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        purpose_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        campaign_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        festival_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        service_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        customer_target_type: {
          value: "all",
          valid: null,
          touched: false,
          nullValue: null
        },
        service_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        festival_id: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        from_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        to_date: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        },
        purpose_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null
        }
      },
      showError: false,
      errorMsg: "",
      festivalTabClicked: false,
      selectServiceClicked: false,
      isFormValid: false,
      isHoLogin: false
    };
    this.getservice();
    this.getpurpose();
    this.getFestival();

    if (
      this.state.userDetail.type &&
      (this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE ||
        this.state.userDetail.type === Roles.PROJECT_MANAGER_ROLE)
    ) {
      this.TagsWithoutOffer = ["#CustomerName"];
      this.state.isHoLogin = true;
    }
  }

  componentDidMount() {
    GoogleAnalticsService.event('Campaign', 'Campaign Filter View');
    if (this.props.setFilterDataForPopUp) {
      let { controls } = this.state;
      if (this.props.setFilterDataForPopUp.purpose_id) {
        controls[
          "purpose_id"
        ].value = this.props.setFilterDataForPopUp.purpose_id.value;
      }
      if (this.props.setFilterDataForPopUp.festival_id) {
        controls[
          "festival_id"
        ].value = this.props.setFilterDataForPopUp.festival_id.value;
      }
      if (this.props.setFilterDataForPopUp.service_id) {
        controls[
          "service_id"
        ].value = this.props.setFilterDataForPopUp.service_id.value;
      }
      if (this.props.setFilterDataForPopUp.from_date) {
        controls[
          "from_date"
        ].value = this.props.setFilterDataForPopUp.from_date.value;
      }
      if (this.props.setFilterDataForPopUp.to_date) {
        controls[
          "to_date"
        ].value = this.props.setFilterDataForPopUp.to_date.value;
      }
      this.setState({
        controls
      });
    }
  }

  handleChangeDatePicker = (e, date) => {
    const { controls } = this.state;
    controls["from_date"].value = date;
    this.setState({ controls });
  };

  handleChangeDatePickerTo_date = date => {
    const { controls } = this.state;
    controls["to_date"].value = date;
    this.setState({ controls });
  };

  getpurpose = () => {
    const obj = {};
    CampaignService.getPurpose(obj)
      .then(data => {
        this.setState({
          purpose: data.data.data
        });
      })
      .catch(err => {
        return err;
      });
  };

  getservice = () => {
    const obj = {};
    CampaignService.getService(obj).then(data => {
      this.setState({
        service: data.data.data
      });
    });
  };

  getFestival = () => {
    const obj = {};
    CampaignService.getFestival(obj).then(data => {
      this.setState({
        festival: data.data.data
      });
    });
  };

  handleChangeInput = e => {
    let { controls } = this.state;
    const controlName = e.target.name;
    const controlValue = e.target.value;
    if (controlName === "purpose_id") {
      var index = e.nativeEvent.target.selectedIndex;
      const d = e.nativeEvent.target[index].text;
      controls.campaign_name.value = d;
      if (controls.campaign_name.value === "Festival Wish") {
        controls["purpose_id"].value = "";
        this.props.setFilterDataForPopUp.festival_id.value = "";
        this.props.setFilterDataForPopUp.service_id.value = "";
        this.setState({
          festivalTabClicked: true,
          selectServiceClicked: false,
          controls
        });
      } else if (controls.campaign_name.value === "Promote Service") {
        controls["festival_id"].value = "";
        this.props.setFilterDataForPopUp.festival_id.value = "";
        this.props.setFilterDataForPopUp.service_id.value = "";
        this.setState({
          festivalTabClicked: false,
          selectServiceClicked: true,
          controls
        });
      } else {
        controls["festival_id"].value = "";
        controls["service_id"].value = "";
        this.props.setFilterDataForPopUp.festival_id.value = "";
        this.props.setFilterDataForPopUp.service_id.value = "";
        if (
          this.props.setFilterDataForPopUp &&
          this.props.setFilterDataForPopUp.service_id
        ) {
          this.props.setFilterDataForPopUp.festival_id.value = "";
          this.props.setFilterDataForPopUp.service_id.value = "";
        } else if (
          this.props.setFilterDataForPopUp &&
          this.props.setFilterDataForPopUp.festival_id
        ) {
          this.props.setFilterDataForPopUp.festival_id.value = "";
          this.props.setFilterDataForPopUp.service_id.value = "";
        }
        this.setState({
          festivalTabClicked: false,
          selectServiceClicked: false
        });
      }
    }
    controls[controlName].value = controlValue;
    this.setState({
      controls
    });
  };

  back = e => {
    this.state.custom_customer = {
      createCampaign: true,
      CampaignDetailData: this.props.CampaignDetailData
    };
    this.props.onSelectTargetGroup(this.state.custom_customer);
  };

  apply = () => {
    let { controls } = this.state;
    if (
      controls.purpose_id.value ||
      controls.festival_id.value ||
      controls.service_id.value ||
      controls.from_date.value ||
      controls.to_date.value
    ) {
      if (this.state.festivalTabClicked && !controls.festival_id.value) {
        this.setState({
          showError: true,
          errorMsg: "Please select festival"
        });
        return;
      }
      if (this.state.selectServiceClicked && !controls.service_id.value) {
        this.setState({
          showError: true,
          errorMsg: "Please select Service"
        });
        return;
      }
    } else {
      this.setState({
        errorMsg: "Please select Purpose",
        showError: true
      });
      return;
    }
    this.state.custom_customer = {
      filterPopupControls: this.state.controls
    };
    this.props.onSelectTargetGroup(this.state.custom_customer);
  };

  closePopup = () => {
    this.state.custom_customer = {
      closepopup: true
    };
    this.props.onSelectTargetGroup(this.state.custom_customer);
  };

  reset = () => {
    let { controls } = this.state;
    controls["purpose_id"].value = "";
    controls["festival_id"].value = "";
    controls["service_id"].value = "";
    controls["from_date"].value = "";
    controls["to_date"].value = "";
    this.setState({
      controls
    });
    this.state.custom_customer = {
      filterPopupControls: this.state.controls,
      closepopup: true
    };
    this.props.onSelectTargetGroup(this.state.custom_customer);
  };

  render() {
    const {
      controls,
      purpose,
      service,
      festival,
      festivalTabClicked,
      selectServiceClicked
    } = this.state;
    return (
      <Fragment>
        <Modal
          className="filer-popup"
          isOpen={true}
          toggle={true}
          style={{ marginLeft: "250px", marginTop: "10px" }}
          id="filterpopup"
        >
          <ModalBody style={{ width: "475px", marginTop: "-30px" }}>
            <Form>
              <p className="filter"> &nbsp;&nbsp;&nbsp;Filter</p>
              {
                (!this.state.isHoLogin && (
                  <Col xl="4" md="12" xs="12" style={{ marginTop: "-15px" }}>
                    <FormGroup>
                      <Label for="username">
                        Select purpose<span className="required-field"></span>
                      </Label>
                      <select
                        htmlFor="username"
                        className="dropbox-purpose"
                        onChange={this.handleChangeInput}
                        name="purpose_id"
                        id="purpose_id"
                        value={this.state.controls.purpose_id.value}
                      >
                        <option value="" defaultChecked>
                          Select
                        </option>
                        {Object.keys(purpose).map(i => (
                          <option
                            value={purpose[i].purpose_id}
                            key={purpose[i].name}
                          >
                            {purpose[i].name}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                ))
              }

              {festivalTabClicked ||
                this.props.setFilterDataForPopUp.festival_id.value ? (
                <Col xl="4" md="12" xs="12">
                  <FormGroup>
                    <Label for="username">
                      Select festival<span className="required-field"></span>
                    </Label>
                    <select
                      htmlFor="username"
                      className="dropbox-purpose"
                      onChange={this.handleChangeInput}
                      name="festival_id"
                      value={this.state.controls.festival_id.value}
                    >
                      <option value="" defaultChecked>
                        Select
                      </option>
                      {Object.keys(festival).map((item, i) => (
                        <option
                          value={festival[i].festival_id}
                          key={festival[i].festival_name}
                        >
                          {festival[i].festival_name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              ) : (
                ""
              )}
              {selectServiceClicked ||
                this.props.setFilterDataForPopUp.service_id.value ? (
                <Col xl="4" md="12" xs="12">
                  <FormGroup>
                    <Label for="username">
                      Select service<span className="required-field"></span>
                    </Label>
                    <select
                      htmlFor="username"
                      className="dropbox-purpose"
                      onChange={this.handleChangeInput}
                      name="service_id"
                      value={this.state.controls.service_id.value}
                    >
                      <option value="" defaultChecked>
                        Select
                      </option>
                      {Object.keys(service).map(i => (
                        <option
                          value={service[i].service_id}
                          key={service[i].service_name}
                        >
                          {service[i].service_name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              ) : (
                ""
              )}

              <Row className="ml-1">
                <Col lg="3" sm="4" md="4" xs="12" xl="3">
                  <FormGroup>
                    <Label
                      for="common"
                      className="Select-Group"
                      style={{ width: "170px", opacity: "1" }}
                    >
                      Starting From<span className="required-field"></span>
                    </Label>

                    <Label
                      className="date-picker-left-plus-15 w-100"
                      onClick={e =>
                        this.calendar4.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup style={{ width: "170px", opacity: "1" }}>
                        <DatePicker
                          className="form-control"
                          onChange={date =>
                            this.handleChangeDatePicker(this, date)
                          }
                          value={controls.from_date.value}
                          selected={controls.from_date.value}
                          maxDate={controls.to_date.value}
                          name="from_date"
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={r => (this.calendar4 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          popperPlacement="bottom-end"
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              style={{ opacity: "0.2" }}
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </FormGroup>
                </Col>
                <Col
                  lg="3"
                  sm="4"
                  md="4"
                  xs="12"
                  style={{
                    opacity: "1",
                    marginLeft: "80px"
                  }}
                >
                  <FormGroup>
                    <Label
                      for="common"
                      className="Select-Group"
                      style={{ opacity: "1" }}
                    >
                      To
                    </Label>
                    <br />
                    <Label
                      className="date-picker-left-15 w-100"
                      onClick={e =>
                        this.calendar3.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup style={{ width: "170px", opacity: "1" }}>
                        <DatePicker
                          className="form-control"
                          onChange={this.handleChangeDatePickerTo_date}
                          name="to_date"
                          value={controls.to_date.value}
                          selected={controls.to_date.value}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={r => (this.calendar3 = r)}
                          shouldCloseOnSelect={true}
                          minDate={controls.from_date.value}
                          showYearDropdown
                          dropdownMode="select"
                          popperPlacement="'top'"
                          autoComplete="nofill"
                          disabled={!controls.from_date.value}
                        />

                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              style={{ opacity: "0.2" }}
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {this.state.showError && (
              <div className="error">{this.state.errorMsg}.</div>
            )}
            <Col xl="3" md="12" xs="12" style={{ marginTop: "-40px" }}>
              <FormGroup>
                <div className="filter-option">
                  <div
                    style={{
                      display: "flex",
                      marginRight: "15px",
                      cursor: "pointer"
                    }}
                    onClick={this.closePopup}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <img src="images/close.svg" style={{ height: "15px" }} alt='' />
                      &nbsp; &nbsp; &nbsp;
                    </span>
                    <p>Cancel</p>
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      marginRight: "15px",
                      cursor: "pointer"
                    }}
                    onClick={this.reset}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <img src="images/reset.svg" style={{ height: "18px" }} alt='' />
                      &nbsp; &nbsp; &nbsp;
                    </span>
                    <p>Reset</p>
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      marginRight: "15px",
                      cursor: "pointer"
                    }}
                    onClick={this.apply}
                  >
                    <span>
                      <img src="images/accepted.svg" alt='' />
                      &nbsp;
                    </span>
                    <p>Apply</p>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}
export default FilterPopUp;
