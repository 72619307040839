import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import lodash from "lodash";
import "./CustomerIntractionAddEditVehicle.css";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
class CustomerIntractionAddEditVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isMobile: false,
      isLoader: false,
      oldDetail: {},
      MonthlyRunningData: [
        "<5k",
        "5k - 6k",
        "6k - 7K",
        "7k - 8K",
        "8k - 9K",
        "9k - 10K",
        "> 10k ",
      ],
      height: "500px",
      controls: {
        vehicle_type: {
          value: "truck",
        },
      },
      load_type: [
        "2 Wheeler Carrier",
        "Agriculture (Food Grains)",
        "Aviation Fuel",
        "Bulker",
        "Car Carrier",
        "Cement",
        "Cement & Fly Ash Bulkers",
        "Construction",
        "Container",
        "Courier",
        "CV Carrier",
        "Tractor Carrier",
        "FMCG and Durables",
        "Industrial Gas",
        "Industrial Machiery",
        "Iron and Steel",
        "Light Goods",
        "LPG Bullet",
        "LPG Cylinder",
        "Market Load",
        "Mining",
        "Concrete Mixer",
        "Municipal",
        "Parcel & Logistics",
        "Perishable Goods",
        "Ports",
        "Refridgerated Vans",
        "Spl Chemical & Other Patroleum Product",
        "Steel & Iron Ore",
        "Tanker",
        "White Goods",
      ],
      vehicles: {},
    };
    this.state.vehicles.truck = [
      {
        type: "6_wheeler",
        label: "6 wheeler",
        count: "",
        application: "",
        payload: "",
      },
      {
        type: "12_wheeler",
        label: "12 wheeler",
        count: "",
        application: "",
        payload: "",
      },
      {
        type: "14_wheeler",
        label: "14 wheeler",
        count: "",
        application: "",
        payload: "",
      },
      {
        type: "16_wheeler",
        label: "16 wheeler",
        count: "",
        application: "",
        payload: "",
      },
      {
        type: "18_wheeler",
        label: "18 wheeler",
        count: "",
        application: "",
        payload: "",
      },
      {
        type: "22_wheeler",
        label: "22 wheeler",
        count: "",
        application: "",
        payload: "",
      },
      {
        type: "average_mothly_usage",
        label: "Average Monthly Usage",
        average_mothly_usage: "",
      },
    ];
    this.state.vehicles.bus = [
      {
        type: "two_axles",
        label: "Two axles",
        count: "",
      },
      {
        type: "multi_axles",
        label: "Multi axles",
        count: "",
      },
      {
        type: "average_mothly_usage",
        label: "Average Monthly Usage",
        average_mothly_usage: "",
      },
    ];
    this.state.vehicles.scv = [
      {
        type: "4_wheeler",
        label: "4 wheeler",
        count: "",
        payload: "",
      },
      {
        type: "average_mothly_usage",
        label: "Average Monthly Usage",
        average_mothly_usage: "",
      },
    ];
    this.state.vehicles.lcv = [
      {
        type: "4_wheeler",
        label: "4 wheeler",
        count: "",
        payload: "",
      },
      {
        type: "average_mothly_usage",
        label: "Average Monthly Usage",
        average_mothly_usage: "",
      },
    ];
    this.toggle = this.toggle.bind(this);
  }

  resetVehicle = (key) => {
    let { vehicles } = this.state;
    vehicles[key].forEach((element) => {
      if (element.count) {
        element.count = "";
      }
      if (element.application) {
        element.application = "";
      }
      if (element.payload) {
        element.payload = "";
      }
      if (element.average_mothly_usage) {
        element.average_mothly_usage = "";
      }
    });

    this.setState({
      vehicles,
    });
  };

  resetControls = () => {
    this.resetVehicle("bus");
    this.resetVehicle("truck");
    this.resetVehicle("lcv");
    this.resetVehicle("scv");
  };

  toggle(data, type = "") {
    let { controls, oldDetail, vehicles } = this.state;
    if (data && Object.keys(data).length > 0) {
      Object.keys(data.vehicles).forEach((key) => {
        data.vehicles[key].forEach((element) => {
          let indexWheel = vehicles[key].findIndex(
            (x) => x.type === element.type
          );
          if (indexWheel > -1) {
            vehicles[key][indexWheel] = element;
          }
          if (element.average_mothly_usage) {
            let average_mothly_usage_index = vehicles[key].findIndex(
              (x) => x.average_mothly_usage
            );
            if (average_mothly_usage_index > -1) {
              vehicles[key][average_mothly_usage_index] = element;
            }
          }
        });
      });

      //vehicles = lodash.cloneDeep(data.vehicles);
    } else {
      oldDetail = {};
    }
    controls.vehicle_type.value = type;
    this.setState((prevState) => ({
      ...prevState,
      modal: !prevState.modal,
      controls,
      oldDetail,
      vehicles,
    }));
    this.checkUserAgent();
  }

  componentWillMount() {
    let height = window.innerHeight - 194;
    this.setState({ height: height + "px" });
  }

  onChangeVehicle = (e, type, i) => {
    let controlName = e.target.name;
    let controlValue = e.target.value;
    let { vehicles } = this.state;
    if (controlName === "count" || controlName === "payload") {
      if (isNaN(controlValue)) {
        return;
      }
    }
    if (type === "truck") {
      vehicles.truck[i][controlName] = controlValue;
    } else if (type === "bus") {
      vehicles.bus[i][controlName] = controlValue;
    } else if (type === "lcv") {
      vehicles.lcv[i][controlName] = controlValue;
    } else if (type === "scv") {
      vehicles.scv[i][controlName] = controlValue;
    }
    this.setState({
      vehicles,
    });
  };

  handleInputChange = (e) => {
    let { controls } = this.state;
    if (e.target.name === "vehicle_type") {
      controls.vehicle_type.value = e.target.value;
    }
    this.setState({
      controls,
    });
  };
  checkUserAgent = () => {
    let { isMobile } = this.state;
    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      isMobile = true;
    } else if (/Chrome/i.test(ua)) {
      isMobile = false;
    } else {
      isMobile = false;
    }
    this.setState({
      isMobile,
    });
  };

  submit = () => {
    this.setState({ isLoader: true });
    let { vehicles } = this.state;
    let totalCountBus = lodash.sumBy(vehicles.bus, function (o) {
      return o.count ? +o.count : 0;
    });
    let totalCountTruck = lodash.sumBy(vehicles.truck, function (o) {
      return o.count ? +o.count : 0;
    });
    let totalCountScv = lodash.sumBy(vehicles.scv, function (o) {
      return o.count ? +o.count : 0;
    });
    let totalCountLcv = lodash.sumBy(vehicles.lcv, function (o) {
      return o.count ? +o.count : 0;
    });
    this.props.submitVehclies({
      totalCountBus: totalCountBus,
      totalCountTruck: totalCountTruck,
      totalCountLcv: totalCountLcv,
      totalCountScv: totalCountScv,
      vehicles: lodash.cloneDeep(vehicles),
    });
    this.setState({ isLoader: false });
    this.close();
  };

  close = () => {
    this.setState({
      modal: false,
    });
    this.resetControls();
  };
  render() {
    let { controls, isMobile, vehicles } = this.state;
    const externalCloseBtn = (
      <button
        className="close"
        style={{ position: "absolute", top: "15px", right: "15px" }}
        onClick={this.toggle}
      >
        &times;
      </button>
    );
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          external={externalCloseBtn}
          className="modal-md modal-lg custom-confirm-modal modal-sm"
          style={{ maxWidth: "495px" }}
          backdrop={this.backdrop ? "static" : "nostatic"}
        >
          {this.state.isLoader && <CustomSpinner />}
          <div className="d-flex-column">
            <div className="confirm-modal-title-wrapp">
              <span className="confirm-modal-title text-capitalize">
                {controls.vehicle_type.value === "lcv"
                  ? "LCV"
                  : controls.vehicle_type.value === "scv"
                    ? "SCV"
                    : controls.vehicle_type.value}{" "}
                Vehicle Detail
              </span>
              <FontAwesomeIcon
                className="pull-right cursor-pointer"
                onClick={() => this.close()}
                icon={faTimes}
                style={{ fontSize: "20px", cursor: "pointer" }}
                color="#fdbb1f"
              />
            </div>
          </div>
          <ModalBody
            classNam="d-flex-column"
            style={{ maxHeight: this.state.height, overflow: "auto" }}
          >
            <div>
              <Row>
                {/* <Col xl="12.custom-confirm-modal" md="12" xs="12">
                  <FormGroup>
                    <Label for="company_name">Vehicle<span className="required-field">*</span></Label>
                    <select className="form-control" name="vehicle_type" value={controls.vehicle_type.value}
                      onChange={(e) => this.handleInputChange(e)}>
                      <option value="" selected disabled>Select Vehicle</option>
                      <option value="truck">Truck</option>
                      <option value="bus">Bus</option>
                      <option value="lcv">LCV</option>
                      <option value="scv">SCV</option>
                    </select>
                  </FormGroup>
                </Col> */}
                {controls.vehicle_type.value === "truck" && (
                  <>
                    {!isMobile ? (
                      <Col
                        md="12"
                        xs="12"
                        className="d-flex"
                        style={{ flexWrap: "wrap" }}
                      >
                        <div className=" min-width-100px font-weight-bold">
                          <Label for="owner_name">Type</Label>
                        </div>
                        <div className="min-width-100px">
                          <Label for="owner_name" className="font-weight-bold">
                            Count
                          </Label>
                        </div>
                        <div className="load-type">
                          <Label for="owner_name" className="font-weight-bold">
                            Application
                          </Label>
                        </div>
                        <div className="min-width-100px">
                          <Label for="owner_name" className="font-weight-bold">
                            Payload(Tons)
                          </Label>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {vehicles.truck.map((vehicle_type, i) => (
                      <>
                        {vehicle_type.type !== "average_mothly_usage" ? (
                          <Col
                            md="12"
                            xs="12"
                            className="d-flex"
                            style={{ flexWrap: "wrap" }}
                          >
                            <div
                              className="vehicle-feild font-weight-bold"
                              style={isMobile ? { paddingBottom: 0 } : {}}
                            >
                              <Label for="owner_name">
                                {vehicle_type.label}
                              </Label>
                            </div>
                            <div className="vehicle-feild">
                              {isMobile && (
                                <Label for="owner_name">Count</Label>
                              )}
                              <Input
                                type="text"
                                autocomplete="nofill"
                                name="count"
                                value={vehicle_type.count}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "truck", i)
                                }
                                placeholder=" "
                              />
                            </div>
                            <div className="vehicle-feild load-type">
                              {isMobile ? (
                                <Label for="owner_name">Load Type</Label>
                              ) : (
                                ""
                              )}
                              <select
                                className="form-control"
                                name="application"
                                value={vehicle_type.application}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "truck", i)
                                }
                              >
                                <option value="" selected disabled>
                                  Select
                                </option>
                                {this.state.load_type.map((load_type, j) => (
                                  <option
                                    className="text-capitalize"
                                    value={load_type}
                                    key={load_type}
                                  >
                                    {load_type}
                                  </option>
                                ))}
                                <option value="Other">Other</option>
                              </select>
                            </div>
                            <div className="vehicle-feild">
                              {isMobile ? (
                                <Label for="owner_name">Payload(Tons)</Label>
                              ) : (
                                ""
                              )}
                              <Input
                                type="text"
                                autocomplete="nofill"
                                name="payload"
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "truck", i)
                                }
                                value={vehicle_type.payload}
                                placeholder=" "
                              />
                            </div>
                          </Col>
                        ) : (
                          <Col
                            xl="12"
                            md="12"
                            xs="12"
                            style={{ bordeTop: "solid 1px #e0e2e4" }}
                          >
                            <FormGroup>
                              <Label for="company_name">
                                Average Monthly Usage
                              </Label>
                              <select
                                className="form-control"
                                name="average_mothly_usage"
                                value={vehicle_type.average_mothly_usage}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "truck", i)
                                }
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {this.state.MonthlyRunningData.map(
                                  (MonthlyRunning, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={MonthlyRunning}
                                      key={MonthlyRunning}
                                    >
                                      {MonthlyRunning}
                                    </option>
                                  )
                                )}
                              </select>
                            </FormGroup>
                          </Col>
                        )}
                      </>
                    ))}
                  </>
                )}

                {controls.vehicle_type.value === "bus" && (
                  <>
                    {!isMobile ? (
                      <Col
                        md="12"
                        xs="12"
                        className="d-flex"
                        style={{ flexWrap: "wrap" }}
                      >
                        <div className=" min-width-100px font-weight-bold">
                          <Label for="owner_name">Type</Label>
                        </div>
                        <div className="min-width-100px">
                          <Label for="owner_name" className="font-weight-bold">
                            Count
                          </Label>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {vehicles.bus.map((vehicle_type, i) => (
                      <>
                        {vehicle_type.type !== "average_mothly_usage" ? (
                          <Col
                            md="12"
                            xs="12"
                            className="d-flex"
                            style={{ flexWrap: "wrap" }}
                          >
                            <div
                              className="vehicle-feild font-weight-bold"
                              style={isMobile ? { paddingBottom: 0 } : {}}
                            >
                              <Label for="owner_name">
                                {vehicle_type.label}
                              </Label>
                            </div>
                            <div className="vehicle-feild">
                              {isMobile && (
                                <Label for="owner_name">Count</Label>
                              )}
                              <Input
                                type="text"
                                autocomplete="nofill"
                                name="count"
                                value={vehicle_type.count}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "bus", i)
                                }
                                placeholder=" "
                              />
                            </div>
                          </Col>
                        ) : (
                          <Col
                            xl="12"
                            md="12"
                            xs="12"
                            style={{ bordeTop: "solid 1px #e0e2e4" }}
                          >
                            <FormGroup>
                              <Label for="company_name">
                                Average Monthly Usage
                              </Label>
                              <select
                                className="form-control"
                                name="average_mothly_usage"
                                value={vehicle_type.average_mothly_usage}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "bus", i)
                                }
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {this.state.MonthlyRunningData.map(
                                  (MonthlyRunning, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={MonthlyRunning}
                                      key={MonthlyRunning}
                                    >
                                      {MonthlyRunning}
                                    </option>
                                  )
                                )}
                              </select>
                            </FormGroup>
                          </Col>
                        )}
                      </>
                    ))}
                  </>
                )}
                {controls.vehicle_type.value === "lcv" && (
                  <>
                    {!isMobile ? (
                      <Col
                        md="12"
                        xs="12"
                        className="d-flex"
                        style={{ flexWrap: "wrap" }}
                      >
                        <div className=" min-width-100px font-weight-bold">
                          <Label for="owner_name">Type</Label>
                        </div>
                        <div className="min-width-100px">
                          <Label for="owner_name" className="font-weight-bold">
                            Count
                          </Label>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {vehicles.lcv.map((vehicle_type, i) => (
                      <>
                        {vehicle_type.type !== "average_mothly_usage" ? (
                          <Col
                            md="12"
                            xs="12"
                            className="d-flex"
                            style={{ flexWrap: "wrap" }}
                          >
                            <div
                              className="vehicle-feild font-weight-bold"
                              style={isMobile ? { paddingBottom: 0 } : {}}
                            >
                              <Label for="owner_name">
                                {vehicle_type.label}
                              </Label>
                            </div>
                            <div className="vehicle-feild">
                              {isMobile && (
                                <Label for="owner_name">Count</Label>
                              )}
                              <Input
                                type="text"
                                autocomplete="nofill"
                                name="count"
                                value={vehicle_type.count}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "lcv", i)
                                }
                                placeholder=" "
                              />
                            </div>
                          </Col>
                        ) : (
                          <Col
                            xl="12"
                            md="12"
                            xs="12"
                            style={{ bordeTop: "solid 1px #e0e2e4" }}
                          >
                            <FormGroup>
                              <Label for="company_name">
                                Average Monthly Usage
                              </Label>
                              <select
                                className="form-control"
                                name="average_mothly_usage"
                                value={vehicle_type.average_mothly_usage}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "lcv", i)
                                }
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {this.state.MonthlyRunningData.map(
                                  (MonthlyRunning, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={MonthlyRunning}
                                      key={MonthlyRunning}
                                    >
                                      {MonthlyRunning}
                                    </option>
                                  )
                                )}
                              </select>
                            </FormGroup>
                          </Col>
                        )}
                      </>
                    ))}
                  </>
                )}

                {controls.vehicle_type.value === "scv" && (
                  <>
                    {!isMobile ? (
                      <Col
                        md="12"
                        xs="12"
                        className="d-flex"
                        style={{ flexWrap: "wrap" }}
                      >
                        <div className=" min-width-100px font-weight-bold">
                          <Label for="owner_name">Type</Label>
                        </div>
                        <div className="min-width-100px">
                          <Label for="owner_name" className="font-weight-bold">
                            Count
                          </Label>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {vehicles.scv.map((vehicle_type, i) => (
                      <>
                        {vehicle_type.type !== "average_mothly_usage" ? (
                          <Col
                            md="12"
                            xs="12"
                            className="d-flex"
                            style={{ flexWrap: "wrap" }}
                          >
                            <div
                              className="vehicle-feild font-weight-bold"
                              style={isMobile ? { paddingBottom: 0 } : {}}
                            >
                              <Label for="owner_name">
                                {vehicle_type.label}
                              </Label>
                            </div>
                            <div className="vehicle-feild">
                              {isMobile && (
                                <Label for="owner_name">Count</Label>
                              )}
                              <Input
                                type="text"
                                autocomplete="nofill"
                                name="count"
                                value={vehicle_type.count}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "scv", i)
                                }
                                placeholder=" "
                              />
                            </div>
                          </Col>
                        ) : (
                          <Col
                            xl="12"
                            md="12"
                            xs="12"
                            style={{ bordeTop: "solid 1px #e0e2e4" }}
                          >
                            <FormGroup>
                              <Label for="company_name">
                                Average Monthly Usage
                              </Label>
                              <select
                                className="form-control"
                                name="average_mothly_usage"
                                value={vehicle_type.average_mothly_usage}
                                onChange={(e) =>
                                  this.onChangeVehicle(e, "scv", i)
                                }
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {this.state.MonthlyRunningData.map(
                                  (MonthlyRunning, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={MonthlyRunning}
                                      key={MonthlyRunning}
                                    >
                                      {MonthlyRunning}
                                    </option>
                                  )
                                )}
                              </select>
                            </FormGroup>
                          </Col>
                        )}
                      </>
                    ))}
                  </>
                )}
                {/* <hr style={{ border: 'solid 1px #e0e2e4' }} /> */}
              </Row>
            </div>
          </ModalBody>
          <Col xl="12" xs="12" md="12" className="text-align-right mt-3">
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-save-button"
              color="warning"
              onClick={() => this.submit()}
            >
              Submit
            </Button>
          </Col>
        </Modal>
      </>
    );
  }
}

export default CustomerIntractionAddEditVehicle;
