import React, { Component } from 'react';
import { Row, Table, Col, Button, Card, CardBody } from 'reactstrap';

// services
import SubDealerService from '../../services/SubDealerService';

// component
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

// modal
import InviteSubDealerOrBranch from '../../modal/InviteSubDealerOrBranch/InviteSubDealerOrBranch';
import DeleteInviteeModal from '../../modal/DeleteInviteeModal/DeleteInviteeModal';

// constant
import { messages } from '../../constants/constant.message';

// css
import './SubDealerOrBranch.css';
import ModalService from '../../services/ModalService';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';
import { Roles } from '../../constants/constant.role';
let { ERROR_MESSAGE } = messages.COMMON;


class SubDealerOrBranch extends Component {
  state = {
    controls: {

    },
    actions: [],
    selectedRole: null,
    isRoleValid: true,
    selectedAction: null,
    isActionValid: true,
    imageUrl: null,
    selectedOption: null,
    rolesOption: [],
    invitees: [],
    isInviteDealerModalOpen: false,
    isDeleteInviteeModalOpen: false,
    isLoading: true,
    invite_data: {}
  }

  componentDidMount() {
    GoogleAnalticsService.pageView('Invite Branch/Sub Dealer View');
    this.getInvitees();

  }

  openInviteDealerModal = (obj = {}) => {
    if (obj && Object.keys(obj).length > 0) {
      if (obj.status === 'accepted') {
        GoogleAnalticsService.event('Invite Branch Or Sub Dealer / Staff', 'Accepted Invite Branch Edit Click');
      } else {
        GoogleAnalticsService.event('Invite Branch Or Sub Dealer / Staff', 'Pending Invite Branch Edit Click');
      }
    } else {
      GoogleAnalticsService.event('Invite Branch Or Sub Dealer / Staff', 'Add Member in Staff Click');
    }
    this.setState({
      isInviteDealerModalOpen: true,
      invite_data: obj
    });
  }

  closeInviteDealerModal = (update, message) => {
    this.setState({ isInviteDealerModalOpen: false });
    if (update === true) {
      this.getInvitees();
    }
  }

  openDeleteInviteeModal = (inviteeId) => {
    GoogleAnalticsService.event('Invite Branch Or Sub Dealer / Staff', 'Invite Branch Delete Click');
    this.setState({ isDeleteInviteeModalOpen: true, inviteeId: inviteeId });
  }

  closeDeleteInviteeModal = () => {
    this.setState({ isDeleteInviteeModalOpen: false });
  }


  getInvitees = () => {
    this.setState({ isLoading: true });
    SubDealerService.getAllInvitees()
      .then(data => {
        const { data: invitees } = data.data;
        this.setState({ invitees, isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }

  inviteAgain = (uuid) => {
    this.setState({ isLoading: true });
    let obj = {
      invite_id: uuid
    }
    SubDealerService.inviteAgain(obj)
      .then(data => {
        const { data: res } = data;
        const { message } = res;
        ModalService.openAlert("Invite Again", message, 'success');
        this.setState({ isLoading: false });
      })
      .catch(e => {
        console.error("e", e);
        this.setState({ isLoading: false });
      })
  }

  deleteInvitee = (uuid) => {
    this.setState({ isLoading: true });
    SubDealerService.deleteInvitee(uuid)
      .then(data => {
        this.getInvitees();
        const { data: res } = data;
        const { message } = res;
        ModalService.openAlert("Delete Invite", message, 'success');
        this.setState({ isLoading: false, isDeleteInviteeModalOpen: false });
      })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;

        ModalService.openAlert("Delete Invite", message, 'failure');
        this.setState({ isLoading: false, isDeleteInviteeModalOpen: false });
      })
  }

  render() {
    let { invitees } = this.state;
    const prepareList = invitees.map(i =>
      <tr>
        <td className="title-case">{i.name}</td>
        <td>
          {i.type === Roles.SUB_DEALER_ROLE && <span>Sub Dealer</span>}
          {i.type === Roles.BRANCH_ROLE && <span>Staff / Branch</span>}
        </td>
        <td>{i.phone_number}</td>
        <td>
          <Row>
            <Col sm="2" style={{ display: 'flex', paddingRight: '0px' }}>
              <img src={i.status === 'accepted' ? '/images/accepted.svg' : '/images/pending.svg'} alt="" />&nbsp;
            </Col>
            <Col sm="10" style={{ paddingLeft: '6px' }}>
              <span className="title-case">{i.status}</span>
            </Col>
          </Row>
        </td>
        <td
        // className={i.status !== 'accepted' ? "cursor-pointer link" : ""}
        // onClick={i.status !== 'accepted' ? this.inviteAgain.bind(this, i.invite_id) : null}
        >
          <img
            className="cursor-pointer"
            src="images/edit.svg"
            alt=""
            style={{ height: "13px" }}
            onClick={() => this.openInviteDealerModal(i)}
          /> &nbsp;&nbsp;
          {i.status !== 'accepted' && <span>

            <span onClick={this.inviteAgain.bind(this, i.invite_id)} className="cursor-pointer link">
              Invite Again
            </span>
            <span onClick={this.openDeleteInviteeModal.bind(this, i.invite_id)} className="margin-left-10 cursor-pointer link">
              Delete
            </span>

          </span>}
          {/* {i.status === 'accepted' && <span>
            --
          </span>} */}
          {/* <span >
            {i.status !== 'accepted' ? 'Invite Again' : '--'}
          </span> */}
        </td>
      </tr>
    );

    return (
      <div id="invite-dealer">
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}

        {this.state.isInviteDealerModalOpen && <InviteSubDealerOrBranch
          show={this.state.isInviteDealerModalOpen}
          closeModal={this.closeInviteDealerModal}
          invite_data={this.state.invite_data}
        >
        </InviteSubDealerOrBranch>}
        {this.state.isDeleteInviteeModalOpen && <DeleteInviteeModal
          show={this.state.isDeleteInviteeModalOpen}
          closeModal={this.closeDeleteInviteeModal}
          delete={this.deleteInvitee}
          inviteeId={this.state.inviteeId}
        >
        </DeleteInviteeModal>}

        <Row>
          <Col sm="8">
            <div className="page-title">Invite Staff / Branch / Sub Dealer</div>
          </Col>
          {invitees.length > 0 && <Col sm="4" className="text-align-right">
            <Button
              className="mb-1 mr-1 btn-pill action-button invite-button"
              color="warning"
              onClick={() => this.openInviteDealerModal({})}
            >
              Invite New Members
            </Button>
          </Col>}
        </Row>
        <br />

        <Row>
          <Col>
            <Card>
              <CardBody>
                {invitees.length > 0 && <Table>
                  <thead>
                    <tr>
                      <th>Invite Staff / Branch / Sub Dealer</th>
                      <th>Type</th>
                      <th>Contact Number</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prepareList}
                  </tbody>
                </Table>}
                {invitees.length === 0 && <div>
                  <div className="no-invitee">
                    You have not invited any sub dealers or branches for warranty registration yet.
                  </div>
                  <div className="no-invitee">
                    Please add them for warranty registration
                  </div>
                  <div className="margin-top-40">
                    <Button
                      className="mb-1 mr-1 btn-pill action-button invite-button"
                      color="warning"
                      onClick={() => this.openInviteDealerModal({})}
                    >
                      Invite
                    </Button>
                  </div>
                </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div >
    );
  }
}

export default SubDealerOrBranch;