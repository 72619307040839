import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label, Button } from 'reactstrap';
import Select from 'react-select';
import ModalService from '../../services/ModalService';
import SalesDataUploadService from '../../services/SalesDataUploadService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import { messages } from '../../constants/constant.message';
const { ERROR_MESSAGE } = messages.COMMON;
class MigrateYearlyPoints extends Component {

  constructor(props) {
    super(props);
    let date = new Date();
    let currentYear = date.getFullYear();
    // let currentMonth = date.getMonth() + 1;
    this.state = {
      currentYear: currentYear,
      controls: {
        year: {
          value: currentYear,
          valid: null,
          touched: false,
          nullValue: null,
        },
        quarter: {
          value: 1,
          valid: null,
          touched: false,
          nullValue: null,
        },
        application: {
          value: 'advantage',
          valid: null,
          touched: false,
          nullValue: null,
        },
        isAddvantage: true
      },
      applicationData: [{
        value: 'advantage',
        label: 'Advantage'
      }, {
        value: 'baadshah',
        label: 'Baadshah'
      }],
      isInvalid: false,
      years: []
    }
    let startYear = 2019;
    let count = currentYear - startYear;
    for (var i = 0; i <= count; i++) {
      this.state.years.push(startYear);
      startYear++;
    }
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  }

  handleValidation() {
    const { controls } = this.state;
    let { isInvalid } = this.state;
    const { quarter, year } = controls;
    isInvalid = false;

    if (!quarter.value || !year.value) {
      isInvalid = true;
    }

    this.setState({ isInvalid });
  }



  onSubmit = async (action) => {
    const { controls } = this.state;
    let { isLoading } = this.state;
    const { year, application } = controls;
    try {
      isLoading = true;
      this.setState({ isLoading });
      // const formData = new FormData();
      // formData.append('application', (application.value || {}).value || '');
      // formData.append('transactionDate', tempTransactionDate);
      // formData.append('salesReport', selectedFile.value);
      // formData.append('sapId', '0');

      let reqData = {
        action: action,
        year: year.value ? parseInt(year.value) : '',
        application: application.value
      }
      const { data } = await SalesDataUploadService.migrateYearData(reqData);

      if (data.success) {
        ModalService.openAlert('Congratulations!', data.message, 'info');
      } else {
        ModalService.openAlert('Sorry!', data.message, 'error');
      }
      //this.resetData();
      isLoading = false;
      this.setState({ isLoading });

    } catch (e) {
      const err = e.response && e.response.data && e.response.data.error && e.response.data.error.length > 0 && e.response.data.error[0] ? e.response.data.error[0] : null;
      const message = err && err.message ? err.message : ERROR_MESSAGE;
      ModalService.openAlert("", message, 'error');
      console.error('error', e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { controls, applicationData, isInvalid, isLoading } = this.state;
    return (
      <>
        <div>
          {isLoading && <CustomSpinner />}
          <div className="page-header">Migrate Yearly Points</div>
          <Row style={{ marginTop: '25px' }}>
            <Col xl="6" md="6" sm="6" xs="6">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="username">Year</Label><br />
                      <select className="form-control select-height-70rem" name="year" value={controls.year.value}
                        onChange={(e) => this.handleInputChange(e)}>
                        <option value="" selected disabled>Select Year</option>
                        {
                          this.state.years.map((item, i) => (
                            <option value={item} selected={this.state.currentYear === item}>{item}</option>
                          ))
                        }
                      </select>
                    </Col>

                  </Row>
                  <Row style={{ marginTop: '10px' }}>
                    <Col>
                      <Label for="username">Application</Label><br />
                      <Select
                        value={applicationData.filter(option => option.value === controls.application.value)}
                        onChange={(e) => this.handleInputChange({ target: { name: 'application', value: e.value } })}
                        placeholder="Select Application"
                        options={applicationData}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '25px' }}>
                    <Col className="text-right">
                      <Button
                        className="mb-1 mr-1 btn-pill action-button login-button"
                        color="warning"
                        disabled={isInvalid}
                        onClick={() => this.onSubmit('move_quarterly_points_to_yearly_points')}
                        type="submit"
                      >
                        <b>Move Quarterly Points To Yearly Points</b>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </div>
      </>
    );
  }
}

export default MigrateYearlyPoints;