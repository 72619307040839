import { request } from './Request';
import { routes } from '../constants/constant.route';

const API_URL = process.env.REACT_APP_API_URL;

export default class SMSReportService {

  static getSMSReport(req) {
    return request('GET', `${API_URL}${routes.SMS_REPORT.GET_SMS_REPORT}`, req, null, null);
  }

  static getSMSReportDetail(req) {
    return request('GET', `${API_URL}${routes.SMS_REPORT.GET_SMS_REPORT_DETAIL}`, req, null, null);
  }
}