import { request } from './Request';
import { routes } from '../constants/constant.route';

const {
	GET_DOCUMENT_TYPES
} = routes.USER_DOCUMENTS;


const API_URL = process.env.REACT_APP_API_URL;

export default class UserDocumentsService {
	static getDocumentTypes() {
		return request('GET', `${API_URL}${GET_DOCUMENT_TYPES}`, null, null, null);
	}
}