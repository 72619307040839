import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  CardBody,
  FormGroup,
  InputGroup
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CustomTimePicker.css";
import moment from "moment";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import OutsideClickHandler from "react-outside-click-handler";
class CustomTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenTimePicker: false,
      controls: {
        time: "",
        hour: "",
        mintue: "",
        ampm: "AM",
        displayTime: ""
      }
    };
    let date = new Date();
    if (props.time) {
      date.setDate(props.time);
      this.state.controls.time = props.time;
    } else {
      this.state.controls.time = new Date();
    }
    this.didTime(this.state.controls.time);
  }

  didTime = time => {
    let { controls } = this.state;
    let formatTime = moment(new Date(time), "h:mm:ss A").format("LT");
    if (this.props.time) {
      controls.displayTime = formatTime;
    }
    let splitTime = formatTime.split(/[:\s]/);
    controls.hour = splitTime[0];
    controls.mintue = parseInt(splitTime[1]);
    controls.ampm = splitTime[2];
    this.setState({
      controls
    });
  };

  is_date = input => {
    if (Object.prototype.toString.call(input) === "[object Date]") return true;
    return false;
  };

  openCloseTimePicker = () => {
    let { isOpenTimePicker } = this.state;
    let isOpenTime = !isOpenTimePicker;
    if (isOpenTime) {
      this.didTime(this.props.time ? this.props.time : new Date());
    }
    this.setState({
      isOpenTimePicker: !isOpenTimePicker
    });
  };

  addHour = isAdd => {
    let { controls } = this.state;
    if (isAdd) {
      let hourValue = controls.hour ? parseInt(controls.hour) : 0;
      hourValue++;
      if (hourValue === 13 || hourValue === '13') {
        controls.hour = 1;
      }
      if (hourValue < 13) {
        controls.hour = hourValue;
      }
    } else {
      let hourValue = controls.hour ? parseInt(controls.hour) : 0;
      hourValue--;
      if (hourValue > 0) {
        controls.hour = hourValue;
      }
    }
    this.setState({
      controls
    });
  };

  leadingZero = (num, places) => {
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    return zeroPad;
  };

  addMinute = isAdd => {
    let { controls } = this.state;
    if (isAdd) {
      let mintueValue = controls.mintue ? parseInt(controls.mintue) : 0;
      mintueValue++;
      if (mintueValue === 60 || mintueValue === '60') {
        controls.mintue = 0;
      } else if (mintueValue < 60) {
        controls.mintue = mintueValue;
      }
    } else {
      let mintueValue = controls.mintue ? parseInt(controls.mintue) : 0;
      mintueValue--;
      if (mintueValue > -1) {
        controls.mintue = mintueValue;
      }
    }
    this.setState({
      controls
    });
  };

  chnageHourMinute = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (!isNaN(controlValue)) {
      if (controlName === "hour") {
        let hourValue = controlValue ? parseInt(controlValue) : 0;
        if (hourValue < 13 && hourValue > 0) {
          controls.hour = hourValue;
        } else {
          controls.hour = "";
        }
      } else if (controlName === "minute") {
        let mintueValue = controlValue ? parseInt(controlValue) : 0;
        if (mintueValue < 61 && mintueValue > -1) {
          controls.mintue = mintueValue;
        } else {
          controls.mintue = "";
        }
      }
    }
    this.setState({
      controls
    });
  };

  setAMPM = () => {
    const { controls } = this.state;
    controls.ampm = controls.ampm === "AM" ? "PM" : "AM";
    this.setState({
      controls
    });
  };

  setTime = () => {
    const { controls } = this.state;
    let hour = 0;
    if (controls.ampm === "PM") {
      if (parseInt(controls.hour) !== 12) {
        hour = controls.hour ? parseInt(controls.hour) + 12 : 0;
      } else {
        hour = controls.hour ? parseInt(controls.hour) : 0;
      }
    } else {
      if (parseInt(controls.hour) !== 12) {
        hour = controls.hour;
      } else {
        hour = 0;
      }
    }
    controls.displayTime = `${controls.hour < 10 ? "0" + controls.hour : controls.hour
      }:${controls.mintue < 10 ? "0" + controls.mintue : controls.mintue} ${controls.ampm
      }`;
    let date = new Date();
    date.setHours(hour);
    date.setMinutes(controls.mintue);
    controls.time = date;
    this.props.setTime(date);
    this.setState({
      controls,
      isOpenTimePicker: false
    });
  };

  cancel = () => {
    this.setState({
      isOpenTimePicker: false
    });
  };

  blur = () => {
    this.setState({
      isOpenTimePicker: false
    });
  };

  render() {
    let { isOpenTimePicker, controls } = this.state;
    return (
      <>
        <Row>
          <Col>
            <InputGroup>
              <Input
                type="text"
                autoComplete="nofill"
                placeholder="HH:MM"
                name="hour"
                id="hour"
                className="text-align-left"
                value={controls.displayTime}
                onClick={() => this.openCloseTimePicker()}
                style={{ marginTop: "2px", fontSize: "16px !important" }}
              />
              <FontAwesomeIcon
                htmlFor="datepicker"
                className="clock-icon"
                onClick={() => this.openCloseTimePicker()}
                icon={faClock}
              />
            </InputGroup>
          </Col>
        </Row>
        {isOpenTimePicker && (
          <Row
            id="custom-time-picker"
            style={{ position: "absolute", zIndex: "200", width: "100%" }}
          >
            <OutsideClickHandler onOutsideClick={this.blur}>
              <Col>
                <Card style={{ width: "300px" }}>
                  <CardBody className="pb-0">
                    <Row style={{ justifyContent: "center" }}>
                      <div className="mr-2">
                        <FormGroup className="form-group-input-h-m">
                          <img
                            src="images/up.png"
                            alt=''
                            className="up-down-img cursor-pointer ml-13px"
                            onClick={() => this.addHour(true)}
                          />
                          <Input
                            type="text"
                            autoComplete="nofill"
                            name="hour"
                            id="hour"
                            className="text-align-center"
                            value={
                              controls.hour < 10
                                ? `0${controls.hour}`
                                : controls.hour
                            }
                            onChange={e => this.chnageHourMinute(e)}
                            style={{
                              marginTop: "0px",
                              fontSize: "16px !important"
                            }}
                          />
                          <img
                            src="images/down.png"
                            alt=''
                            className="up-down-img cursor-pointer ml-13px"
                            onClick={() => this.addHour(false)}
                          />
                        </FormGroup>
                      </div>
                      <div className="ml-2">
                        <span className="time-colon">&#x3a;</span>
                      </div>
                      <div className="ml-2">
                        <FormGroup className="form-group-input-h-m">
                          <img
                            src="images/up.png"
                            alt=''
                            className="up-down-img cursor-pointer ml-13px"
                            onClick={() => this.addMinute(true)}
                          />
                          <Input
                            type="text"
                            autoComplete="nofill"
                            name="minute"
                            className="text-align-center"
                            id="minute"
                            onChange={e => this.chnageHourMinute(e)}
                            value={
                              controls.mintue > 0 && controls.mintue < 10
                                ? `0${controls.mintue}`
                                : controls.mintue
                            }
                            style={{
                              marginTop: "2px",
                              fontSize: "16px !important"
                            }}
                          />
                          <img
                            src="images/down.png"
                            alt=''
                            className="up-down-img cursor-pointer ml-13px"
                            onClick={() => this.addMinute(false)}
                          />
                        </FormGroup>
                      </div>
                      <div className="d-flex">
                        <span
                          className="am-pm-lbl"
                          onClick={() => this.setAMPM()}
                        >
                          {this.state.controls.ampm}
                        </span>
                      </div>
                    </Row>
                    <Row style={{ borderTop: "1px solid rgba(0,0,0,.12)" }}>
                      <span
                        className="btn-set-cancel"
                        onClick={() => this.cancel()}
                      >
                        CANCEL
                      </span>
                      <span
                        className="btn-set-cancel"
                        onClick={() => this.setTime()}
                      >
                        SET
                      </span>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </OutsideClickHandler>
          </Row>
        )}
      </>
    );
  }
}

export default CustomTimePicker;
