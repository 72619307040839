
import React from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import Ionicon from 'react-ionicons';

import PcrSchemeDealer from '../../components/PcrSchemeDealer/PcrSchemeDealer';
import './PcrSchemeDealerModal.css';
class PcrSchemeDealerModal extends React.Component {


  render() {
    return <Modal
      id="pcr-scheme-dealer-modal"
      isOpen={this.props.show}
      toggle={this.props.closeModal}
      centered
    >
      {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
      <ModalBody>
        <Row className="modal-title-border padding-bottom-10">
          <Col sm="11" className="modal-title capital-case">PCR Scheme</Col>
          <Col sm="1" className="text-align-right cursor-pointer" onClick={this.props.closeModal}>
            <Ionicon
              icon="md-close"
              fontSize="34px"
              color="#fdbb1f"
            ></Ionicon>
          </Col>
        </Row>
        <div></div>
        <PcrSchemeDealer
          from="modal"
          sapId={this.props.sapId}
        ></PcrSchemeDealer>
      </ModalBody>
    </Modal>
  }
}

export default PcrSchemeDealerModal; 