import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap";
import "./exceptionalPointsUpload.css";
import exceptionalPointsUploadService from "../../services/exceptionalPointsUploadService";
import ModalService from "../../services/ModalService";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import Storage from "../../services/Storage";
import { messages } from "../../constants/constant.message";
const { ERROR_MESSAGE } = messages.COMMON;

class exceptionalPointsUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sapId: Storage.getUserDetail().sap_id,
      controls: {
        userType: {
          value: "dealer",
          valid: null,
          touched: false,
          nullValue: null
        },
        sapId: {
          value: "0",
          valid: null,
          touched: false,
          nullValue: null
        },
        selectedFile: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null
        }
      },
      closingBalanceTrack: null,
      isLoading: false,
      isInvalid: true
    };
    this.getClosingBalanceTrack({ userType: 'dealer' });
  }

  getClosingBalanceTrack = obj => {
    const data = { type: obj.userType }
    exceptionalPointsUploadService.getClosingBalanceTrack(data).then(res => {
      this.setState({ closingBalanceTrack: res.data.data[0] });
    }).catch(e => {
    });
  }

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
    if (e.target.name === 'userType') {
      this.getClosingBalanceTrack({ userType: e.target.value });
    }
  };

  onSubmit = async () => {
    const { controls } = this.state;
    let { isLoading } = this.state;
    const {
      selectedFile,
      userType
    } = controls;
    try {
      isLoading = true;
      this.setState({ isLoading });
      const formData = new FormData();
      formData.append("exceptionalPointsReport", selectedFile.value);
      formData.append("sapId", this.state.sapId ? this.state.sapId : "0");
      formData.append("type", userType.value);

      // let data = null;
      // let isClosingBalanceUploaded = Storage.getUserDetail().is_closing_balance_uploaded && Storage.getUserDetail().is_closing_balance_uploaded === true ? true : false;
      if (!!userType.value) {
        if (this.state.closingBalanceTrack && this.state.closingBalanceTrack.is_point_tranfered === true) {
          exceptionalPointsUploadService.exceptionalPointsUploadV2(formData).then(res => {
            this.setState({ isLoading: false });
            ModalService.openAlert(
              "Congratulations!",
              res.data.message,
              "success"
            );
          }).catch(e => {
            this.setState({ isLoading: false });
            const err =
              e.response &&
                e.response.data &&
                e.response.data.error &&
                e.response.data.error[0]
                ? e.response.data.error[0]
                : null;
            const message = err && err.message ? err.message : ERROR_MESSAGE;
            ModalService.openAlert("", message, "error");
          });
        } else {
          exceptionalPointsUploadService.exceptionalPointsUpload(formData).then(res => {
            this.setState({ isLoading: false });
            ModalService.openAlert(
              "Congratulations!",
              res.data.message,
              "success"
            );
          }).catch(e => {
            this.setState({ isLoading: false });
            const err =
              e.response &&
                e.response.data &&
                e.response.data.error &&
                e.response.data.error[0]
                ? e.response.data.error[0]
                : null;
            const message = err && err.message ? err.message : ERROR_MESSAGE;
            ModalService.openAlert("", message, "error");
          });
        }
      } else {
      }
      this.resetData();
      isLoading = false;
      this.setState({ isLoading });
    } catch (error) {
      console.log('err', error);
      this.resetData();
      isLoading = false;
      this.setState({ isLoading });
    }
  };

  resetData = () => {
    const { controls } = this.state;
    const { selectedFile } = controls;
    selectedFile.value = "";
    if (this.fileInput) {
      this.fileInput.value = "";
    }

    this.setState({ controls });
  };

  handleValidation() {
    const { controls } = this.state;
    let { isInvalid } = this.state;
    const { selectedFile } = controls;
    isInvalid = false;

    if (!selectedFile.value) {
      isInvalid = true;
    }
    this.setState({ isInvalid });
  }

  render() {
    const { controls, isInvalid, isLoading } = this.state;
    const { userType } = controls;
    return (
      <div>
        {isLoading && <CustomSpinner />}
        <div className="page-header">Exceptional Points Upload</div>

        <Row style={{ marginTop: "25px" }}>
          <Col xl="6" md="6" sm="6" xs="6">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <Row className="margin-15">
                      <div className="d-flex-row pcrtype-wrapp">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="userType"
                            onChange={this.handleInputChange}
                            value="dealer"
                            checked={userType.value === "dealer"}
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>Dealer</span>
                      </div>
                      <div className="d-flex-row pcrtype-wrapp">
                        <label className="custom-checkboax-radio--container">
                          <input
                            type="radio"
                            name="userType"
                            onChange={this.handleInputChange}
                            value="fleet"
                            checked={userType.value === "fleet"}
                          />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: "10px" }}>
                          Fleet
                        </span>
                      </div>
                    </Row>
                  </Col>
                  <Col style={{ marginTop: "10px" }}>
                    {" "}
                    <div className="f-flex-row" style={{ float: "right" }}>
                      <a
                        href="https://images.jktyrecrm.in/others/EXCEPTIONAL_POINTS_FORMAT.xlsx"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{ color: "#5665d8" }}
                      >
                        <img
                          src="images/download.svg"
                          alt=""
                          className="dow  nload-report-icon cursor-pointer margin-left-10"
                        />
                      </a>
                      &nbsp;&nbsp;
                      <span className="link-warm-blue cursor-pointer">
                        <a
                          href="https://images.jktyrecrm.in/others/EXCEPTIONAL_POINTS_FORMAT.xlsx"
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                          style={{ color: "#5665d8" }}
                        >
                          Download Sample Excel File
                        </a>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col>
                    <input
                      type="file"
                      accept=".xlsx"
                      onChange={e =>
                        this.handleInputChange({
                          target: {
                            name: "selectedFile",
                            value: e.target.files[0]
                          }
                        })
                      }
                      className="width-100"
                      ref={ref => (this.fileInput = ref)}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "25px" }}>
                  <Col>
                    <Button
                      className="mb-1 mr-1 btn-pill action-button login-button"
                      color="warning"
                      disabled={isInvalid}
                      onClick={this.onSubmit}
                      type="submit"
                      style={{ width: "139px" }}
                    >
                      <b>Submit</b>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default exceptionalPointsUpload;
