import React, { Component, Fragment } from "react";
import { Table } from "reactstrap";
import { formatNumber } from "../../../utils/utils";
// import { messages } from "../../../constants/constant.message";
class PrimaryPcrPointsReport extends Component {
  state = {
    year: null
  };

  componentWillReceiveProps(props) {
    if (props.year) {
      this.setState({
        year: props.year
      });
    }
  }
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }
  getQuarter = month => {
    var m = Math.floor(month / 3) + 2;
    return m > 4 ? m - 4 : m;
  };
  render() {
    const { totals, labels } = this.props;
    let primaryPointsReports = [];
    if (this.props.primaryPointsReports) {
      primaryPointsReports = this.props.primaryPointsReports;
    }
    let currnetMonth = this.months[new Date().getMonth()];
    return (
      <Fragment>
        {primaryPointsReports.map((quarter, i) => (
          <div className="quarter-points-wrapp mb-4">
            <div className="quarter-points-inner">
              <span className="QUARTER-label">
                QUARTER {quarter.quarter} ({quarter.month[0]}-
                {quarter.month[quarter.month.length - 1]})&nbsp;
                {quarter.month.map((item, monthIndex) =>
                  currnetMonth === item &&
                    new Date().getFullYear() <= this.state.year
                    ? "Current Quarter"
                    : ""
                )}
              </span>
            </div>

            <div className="QUARTER-tables">
              <div className="QUARTER-table quarter-tbl-left">
                <div className="d-flex-row total-offtake-elgible-wrapp">
                  <div className="d-flex-column">
                    <span className="mb-1 Total-Offtake mb-2">
                      Total Offtake <b>{
                        totals && totals[`q${quarter.quarter}`] && totals[`q${quarter.quarter}`]['pcr'] && totals[`q${quarter.quarter}`]['pcr']['totalOfftake'] &&
                        formatNumber(totals[`q${quarter.quarter}`]['pcr']['totalOfftake'])}</b>
                    </span>
                    {i === 0 ? (
                      <span className="Till-Date mb-2">Till Date</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="mb-1 Total-Offtake mb-2">
                    Total Eligible Offtake{" "}
                    <b>{
                      totals && totals[`q${quarter.quarter}`] && totals[`q${quarter.quarter}`]['pcr'] && totals[`q${quarter.quarter}`]['pcr']['eligibleOfftake'] &&
                      formatNumber(totals[`q${quarter.quarter}`]['pcr']['eligibleOfftake'])}</b>
                  </span>
                </div>
                {quarter.data.report && quarter.data.report.pcr && (
                  <div>
                    <Table className="mb-0 table-responsive" bordered>
                      <thead>
                        <tr style={{ ...this.getStripedStyle(0) }}>
                          {labels && labels.offtake && labels.offtake.map(l => <th>{l.key}</th>)}
                          {/* <th>Month</th>
                        <th>Total Offtake</th>
                        <th>Eligible Offtake</th>
                        <th>12” - 13"</th>
                        <th>14” - 16"</th>
                        <th>17 & Premium</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {quarter.data.report.pcr.map((item, i) => (
                          <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                            {labels && labels.offtake && labels.offtake.map(l =>
                              <td>{
                                l.value === 'month' ? this.months[item[l.value] - 1] : formatNumber(item[l.value])
                              }</td>
                            )}
                            {/* <td>{this.months[item.month - 1]}</td>
                          <td>{formatNumber(item.totalWholeMonthSales)}</td>
                          <td>{formatNumber(item.eligibleSales)}</td>
                          <td>{formatNumber(item.pcr1213)}</td>
                          <td>{formatNumber(item.pcr1416)}</td>
                          <td>{formatNumber(item.pcrPremium)}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {(quarter && quarter.data && (quarter.data['totalPremiumOfftake'] === 0 || quarter.data['totalPremiumOfftake'] === null || quarter.data['totalPremiumOfftake'])) ? (
                      <div style={{ marginTop: '5px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span className="total-premium-offtake">Total Premium Offtake</span>
                          <span className="total-premium-offtake-value">
                            {quarter.data && quarter.data.totalPremiumOfftake ? quarter.data.totalPremiumOfftake : quarter.data.totalPremiumOfftake === null ? 0 : 0}
                          </span>
                        </div>
                      </div>
                    ) : ''}
                  </div>
                )}
              </div>

              <div className="QUARTER-table">
                <span className="mb-1 Total-Offtake mb-2">
                  Total Earned Points{" "}
                  <b>{totals && totals[`q${quarter.quarter}`] && totals[`q${quarter.quarter}`]['pcr'] && totals[`q${quarter.quarter}`]['pcr']['totalPoints'] &&
                    formatNumber(totals[`q${quarter.quarter}`]['pcr']['totalPoints'])}</b>
                </span>
                {i === 0 ? (
                  <span className="Till-Date mb-2">
                    *These Points are provisional. They will get added to your
                    total earned points at the end of Q{quarter.quarter} if you
                    are eligible
                  </span>
                ) : (
                  ""
                )}
                <Table className="mb-0 table-responsive" bordered>
                  <thead>
                    <tr style={{ ...this.getStripedStyle(0) }}>
                      {labels && labels.points && labels.points.map(l => <th>{l.key}</th>)}

                      {/* <th>Total</th>
                      <th>12” - 13"</th>
                      <th>14” - 16"</th>
                      <th>17 & Premium</th>
                      <th>Consistency</th>
                      <th>Slab jump</th>
                      <th>Scheme</th>
                      <th>Approval</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {quarter.data.report.pcr.map((item, i) => (
                      <tr style={{ ...this.getStripedStyle(i + 1) }}>
                        {labels && labels.points && labels.points.map(l =>
                          <td>{
                            l.value === 'classification' ? item[l.value] : formatNumber(item[l.value])
                          }</td>)}
                        {/* <td>
                          {formatNumber(
                            item.pcr1213Points +
                              item.pcr1416Points +
                              item.pcrPremiumPoints +
                              item.consistencyBonusPoints +
                              item.slabJumpPoints +
                              item.schemePoints +
                              item.approvalPoints
                          )}
                        </td>
                        <td>{formatNumber(item.pcr1213Points)}</td>
                        <td>{formatNumber(item.pcr1416Points)}</td>
                        <td>{formatNumber(item.pcrPremiumPoints)}</td>
                        <td>{formatNumber(item.consistencyBonusPoints)}</td>
                        <td>{formatNumber(item.slabJumpPoints)}</td>
                        <td>{formatNumber(item.schemePoints)}</td>
                        <td>{formatNumber(item.approvalPoints)}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            {i === primaryPointsReports.length - 1 && (
              <>
                <br />
                {/* <a href="https://www.jktyreadvantage.com" target="_blank" rel="noopener noreferrer">
                    <b className="Learn-more-about" style={{ color: '#495057' }}>
                      {messages.PRIMARY_REPORT.REDIRECT_JK_ADVANTAGE}
                    </b>
                  </a> */}
              </>
            )}
          </div>
        ))}
      </Fragment>
    );
  }
}

export default PrimaryPcrPointsReport;
