import React, { Component } from 'react';
import {
  Button,
  Row, Col, Table,
  Card, Input
} from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import AddEditAddress from '../../modal/AddEditAddress/AddEditAddress';
import MerchandiseWalletService from '../../services/MerchandiseWalletService';
import Storage from '../../services/Storage';
import ModalService from '../../services/ModalService';
import { messages } from '../../constants/constant.message';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

class MerchandiseCatalogueCart extends Component {
  state = {
    isRequestDetailModalOpen: false,
    isModalOpen: false,
    placeOrderCartItems: [],
    total_coins: 0,
    coinsOnCart: 0,
    redeemed_coins: 0,
    selectedAddress: {},
    addressSelectedIndex: -1
  }

  constructor(props) {
    super(props);
    const placeOrderCartItems = Storage.getPlaceOrderCartItems();
    if (placeOrderCartItems && placeOrderCartItems.length <= 0) {
      this.props.backToMerchandiseWallet(true);
    }
    this.state = {
      isRequestDetailModalOpen: false,
      isModalOpen: false,
      placeOrderCartItems: placeOrderCartItems || [],
      coinsOnCart: props.coinsOnCart,
      balance_coins: props.balance_coins ? props.balance_coins : 0,
      redeemed_coins: props.redeemed_coins ? props.redeemed_coins : 0,
      total_coins: props.total_coins ? props.total_coins : 0,
      addressList: [],
      checkedAddress: 'address0',
      onlyOneAddEditIndex: 1,
      showAddNewAddress: true
    };
  }

  componentDidMount() {
    this.getAddresses();
  }

  getAddresses = () => {
    MerchandiseWalletService.getAddresses()
      .then(res => {
        if (res && res.data && res.data.data && res.data.data.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              addressList: res.data.data,
              onlyOneAddEditIndex: res.data.data.length,
              selectedAddress: (res.data && res.data.data && res.data.data[0]) || []
            };
          });
        } else {
          this.setState({ onlyOneAddEditIndex: 0 });
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  async getState() {
    try {
      this.props.isDataLoading(true);
      const { data } = await MerchandiseWalletService.getState();
      if (data) {
        const stateData = data.data;
        const ownState = _.remove(stateData, { is_own_state: true });
        if (ownState.length) {
          stateData.unshift(ownState[0]);
        }

        this.setState((prevState) => {
          return { ...prevState, stateData };
        });
      }
    } catch (error) {
      console.log(error);
    }
  }


  openModal = (index) => {
    if (index === 0) {
      return;
    }
    if (index === -1) {
      this.setState({ showAddNewAddress: false });
    }
    this.setState({ addressSelectedIndex: index, isModalOpen: true });
  }

  closeChangePasswordModal = (obj) => {
    this.setState({ showAddNewAddress: true });
    let { selectedAddress } = this.state;
    if (obj && obj.address) {
      this.setState({ showAddNewAddress: false });
      const { addressList } = this.state;
      if (this.state.addressList.length === this.state.onlyOneAddEditIndex) {
        addressList.push(obj);
      } else {
        addressList[this.state.addressList.length - 1] = obj;
      }

      if (addressList && addressList.length === 1) {
        [selectedAddress] = addressList;
      }


      this.setState({ isModalOpen: false, selectedAddress });
      this.setState((prevState) => {
        return {
          ...prevState,
          addressList,
          isModalOpen: false
        };
      });
      return;
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        isModalOpen: false
      };
    });
  }

  backToMerchandiseWallet = () => {
    if (this.state.placeOrderCartItems && this.state.placeOrderCartItems.length > 0) {
      Storage.setPlaceOrderCartItems(this.state.placeOrderCartItems);
    } else {
      Storage.removePlaceOrderCartItems();
    }
    this.props.backToMerchandiseWallet(true);
  }

  addSubCoins = (item, isAddToCart) => {
    let { balance_coins, coinsOnCart, placeOrderCartItems } = this.state;
    const tempPlaceOrderCartItems = _.cloneDeep(placeOrderCartItems);
    const total_quantity = isAddToCart ?
      item.total_quantity + item.minimum_quantity :
      item.total_quantity - item.minimum_quantity;

    if (total_quantity > item.current_quantity) {
      item.isInvalid = true;
      this.setState({});
      setTimeout(() => {
        item.isInvalid = false;
        this.setState({});
      }, 5000);
      return;
    }

    if (total_quantity < item.minimum_quantity) {
      return;
    }

    if (total_quantity > item.minimum_quantity * 5) {
      return;
    }
    const indexCartItem = tempPlaceOrderCartItems.findIndex(x => x.item_id === item.item_id);
    tempPlaceOrderCartItems[indexCartItem].total_quantity = total_quantity;
    let tempCoinsOnCart = 0;
    tempPlaceOrderCartItems.forEach(element => {
      tempCoinsOnCart = element.total_quantity * element.coins_per_piece + tempCoinsOnCart;
    });
    if (tempCoinsOnCart > balance_coins) {
      placeOrderCartItems[indexCartItem].total_quantity = item.total_quantity;
      item.isAddToCart = true;
      this.setState({ coinsOnCart, placeOrderCartItems });
      // item.isInvalid = true;
      // this.setState({});
      // setTimeout(() => {
      //   item.isInvalid = false;
      //   this.setState({});
      // }, 5000);
      ModalService.openAlert('Sorry!', `Your available coins are ${balance_coins} which is less than items you are requesting.`, 'warning');
      return;
    }
    GoogleAnalticsService.event('Merchandise Catalogue', 'Merchandise Product Dialogue Add Click');
    placeOrderCartItems = tempPlaceOrderCartItems;
    coinsOnCart = tempCoinsOnCart;
    item.isAddToCart = true;
    this.setState({ coinsOnCart, placeOrderCartItems });
  }

  removeCartItem = (index) => {
    let { placeOrderCartItems, coinsOnCart } = this.state;
    const balance_coins = placeOrderCartItems[index].total_quantity *
      placeOrderCartItems[index].coins_per_piece;
    placeOrderCartItems.splice(index, 1);
    coinsOnCart -= balance_coins;
    this.setState((prevState) => {
      return {
        ...prevState,
        placeOrderCartItems,
        coinsOnCart
      };
    });
    if (placeOrderCartItems.length <= 0) {
      this.state.coinsOnCart = 0;
      this.backToMerchandiseWallet();
    }
  }


  changeAddress = (e, index) => {
    const { addressList } = this.state;
    let { selectedAddress } = this.state;
    selectedAddress = addressList[index];
    const controlName = e.target.name;
    this.setState((prevState) => {
      return {
        ...prevState,
        checkedAddress: controlName,
        selectedAddress
      };
    });
  }

  placeOrder = async () => {
    try {
      let { placeOrderCartItems } = this.state;
      const obj = this.buildObject();
      const { data } = await MerchandiseWalletService.placeOrder(obj);
      if (data && data.success) {
        placeOrderCartItems = [];
        this.setState((prevState) => {
          return {
            ...prevState,
            placeOrderCartItems
          };
        });
        this.backToMerchandiseWallet();
        GoogleAnalticsService.event('Merchandise Catalogue', 'Place Order');
        ModalService.openAlert('Congratulations!', data.message, 'success');
      } else {
        ModalService.openAlert('Sorry!', messages.WARRANTY_REGISTRATION.ERRORMESSAGE, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  }

  buildObject() {
    const { selectedAddress, placeOrderCartItems } = this.state;

    const obj = {
      ...selectedAddress,
      item_data: _.map((placeOrderCartItems || []), (item) => {
        return { item_id: item.item_id, total_quantity: item.total_quantity, redeemed_coins: item.total_quantity * item.coins_per_piece };
      })
    };

    return obj;
  }

  render() {
    const { placeOrderCartItems, addressList, addressSelectedIndex, showAddNewAddress } = this.state;
    const isAddAddress = addressList.length === this.state.onlyOneAddEditIndex;
    return (
      <>

        {this.state.isModalOpen && <AddEditAddress
          isAddAddress={addressList.length === 1}
          addressSelectedIndex={addressSelectedIndex}
          addressList={this.state.addressList}
          show={this.state.isModalOpen}
          closeModal={this.closeChangePasswordModal}
          onlyOneAddEditIndex={this.state.onlyOneAddEditIndex}
        />}
        <div className="d-flex-row">
          <span onClick={() => this.backToMerchandiseWallet()} className="Merchandise-catalogu-link cursor-pointer">Merchandise Catalogue</span>&nbsp;&nbsp;
          <img src="images/right.svg" alt='' />&nbsp;&nbsp;
          <span className="cart-lbl" style={{ marginTop: '3px' }}>Cart</span>
        </div>
        <br />
        <Row>
          <Col xl="7" md="12" xs="12">
            <Card>
              <div style={{ padding: '15px' }}>
                <Table className="mb-0 table-responsive table">
                  <thead>
                    <tr>
                      <th className="cart-th">Product Image</th>
                      <th className="cart-th">Product Name</th>
                      <th className="cart-th">Gift Coins Redeemed</th>
                      <th className="cart-th" style={{ width: '150px' }}>Qty.</th>
                      <th className="cart-th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      placeOrderCartItems.map((item, index) => (
                        <tr key={item.name + index}>
                          <td>
                            <img src={item.image_url} alt='' style={{ width: '60px', height: '59px', objectFit: 'contain' }} />
                          </td>
                          <td className="cart-td">
                            {item.name}
                          </td>
                          <td>
                            {item.total_quantity * item.coins_per_piece}
                          </td>
                          <td className="cart-td">
                            <div className="d-flex-column" style={{ margin: 'auto' }}>
                              <div className="d-flex-row" style={{ flexWrap: 'nowrap' }}>
                                <Input value={item.total_quantity} type="text" className="min-input-qty" style={{ width: '65px' }} />&nbsp;&nbsp;
                                <div className="d-flex-column">
                                  <img src="images/up.png" alt='' onClick={() => this.addSubCoins(item, true)} className="up-down-img cursor-pointer" />
                                  <img src="images/down.png" alt='' onClick={() => this.addSubCoins(item, false)} style={{ marginTop: '8px' }} className="up-down-img cursor-pointer" />
                                </div>
                              </div>
                              <span className="Minimum-Qty">*Minimum Qty. = {item.minimum_quantity}</span>
                            </div>
                            {item.isInvalid && <div style={{ marginTop: '5px' }} className="error">Sorry! maximum available item count is {item.current_quantity}.</div>}
                          </td>
                          <td>
                            <img className="cursor-pointer" onClick={() => this.removeCartItem(index)} src='images/delete.svg' alt='' style={{ marginTop: '-25px' }} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
          <Col xl="5" md="12" xs="12">
            <Card>
              <div className="Select-Shipping-Addr-header">
                <span className="Select-Shipping-Addr">Select Shipping Address</span>
                <div>
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    onClick={this.placeOrder}
                  >
                    Place Order
                  </Button>
                </div>
              </div>
              <div style={{ padding: '15px' }}>
                <span className="ShippingAddressTitle">Shipping Address</span>
                <br />
                {
                  addressList.map((item, index) => (
                    <Row key={item.address} style={{ marginTop: '10px' }}>
                      <Col xl="1" md="1">
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" value={`address${index}`} name={`address${index}`} checked={this.state.checkedAddress === `address${index}`} onChange={(e) => this.changeAddress(e, index)} />
                          <span className="checkmark-radio" />
                        </label>
                      </Col>
                      <Col xl="9" md="9" sm="10" xs="10" className="ShippingAddress">
                        {`${item.address}${item.city ? ', ' : ''}
                          ${item.city}${item.state ? ', ' : ''}
                          ${item.state}${item.pin_code ? '- ' : ''}
                          ${item.pin_code}${item.pin_code ? '.' : ''}
                          `}
                      </Col>
                      <Col>
                        {!item.is_parent && <FontAwesomeIcon onClick={() => { this.openModal(index); }} style={{ cursor: 'pointer' }} color="#fdbb1f" icon={faPen} />}
                      </Col>
                    </Row>


                  ))
                }

                {showAddNewAddress && <div className="d-flex-column" style={{ marginTop: '20px' }}>
                  <div className="Add-New-Address cursor-pointer" onClick={() => { this.openModal(-1); }}>+ Add New Address</div>
                </div>}
              </div>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default MerchandiseCatalogueCart;