import React, { Component, Fragment } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
  CardHeader,
  Card,
  CardBody
} from "reactstrap";
import classnames from "classnames";

class FarmPoints extends Component {
  quarter2 = {
    type: "Tractor Rear",
    headers: ["Category", "Brand", "Sizes", "Points/Per Tyre"],
    note: [
      "For Tractor. Front & Tractor.Trailer Category, only Base Points would be applicable.",
      "For Tractor. Rear Category, Base points would be applicable only on mentioned brands i.e.Shresth, AgriGold, Sona-1 & Haul Grip."
    ],
    points: [
      ["Tractor Rear", "Shresth/AgriGold", "All", "250"],
      ["Tractor Rear", "Sona-1/Haul Grip", "All", "175"]
    ]
  };
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      showMore: true,
      transform: true,
      showInkBar: true,
      selectedTabKey: 0,
      transformWidth: 400
    };
    //this.getAboutDetail();
  }
  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let farmPoints = this.props.farmPoints;
    return (
      <Fragment>
        <Card
          tabs="true"
          className="mb-3 about-tab-header"
          style={{ boxShadow: "none" }}
        >
          <CardHeader className="card-header-tab">
            <Nav style={{ marginLeft: "0" }}>
              {farmPoints.tabs.map((t, index) =>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={classnames({
                      active: this.state.activeTab === index
                    })}
                    onClick={() => {
                      this.toggleTab(index);
                    }}
                  >
                    {t}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </CardHeader>
          <br />
          <CardBody style={{ padding: "0" }}>
            <TabContent activeTab={this.state.activeTab}>
              {farmPoints.tabs.map((t, index) => {
                return t === 'Quarter 1' && farmPoints.year === '2019-20' ?

                  <TabPane tabId={index}>
                    <Row>
                      <Col sm="12">
                        <div className="PASSENGER-CAR-RADIAL-label">
                          {farmPoints[t].type}
                        </div>
                        <Table className="table-responsive tbl-tbb tbl-about">
                          <thead>
                            <tr style={{ ...this.getStripedStyle(0) }}>
                              {farmPoints[t].headers.map((item, i) => (
                                <th>{item}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {farmPoints[t].points.map((row, i) => (
                              <tr
                                key={i}
                                style={{ ...this.getStripedStyle(i + 1) }}
                              >
                                {row.map((item, j) => (
                                  <td> {item}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {farmPoints[t].note.map((note, i) => (
                          <div
                            className="d-flex-row truck-bus-wrapper"
                            style={{ marginRight: "0px", marginLeft: "0px" }}
                          >
                            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                              <span className="Base-Points-80-Tyre-label">
                                {note}
                              </span>
                            </div>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </TabPane> :
                  <TabPane tabId={index}>
                    <div className="PASSENGER-CAR-RADIAL-label">
                      {farmPoints.type}
                    </div>
                    <Table className="mb-0 table-responsive tbl-about">
                      <thead>
                        {/* <tr>
              <th colspan={farmPoints.headers.length} style={{ textAlign: 'center' }}>
                  Multi Brand Dealers
      </th>
          </tr> */}
                        <tr style={{ ...this.getStripedStyle(0) }}>
                          {farmPoints[t].headers.map(item => (
                            <th>{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {farmPoints[t].points.map((row, i) => (
                          <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                            {row.map((item, j) => (
                              <td>{item}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className="truckbus-note two-three-wheeler-multilple-offer-note">
                      <span className="Base-Points-80-Tyre-label">
                        {farmPoints[t].note}
                      </span>
                    </div>
                    <br />
                    <Table className="mb-0 table-responsive tbl-about">
                      <thead>
                        {/* <tr>
              <th colspan={farmPoints.headers.length} style={{ textAlign: 'center' }}>
                  Multi Brand Dealers
      </th>
          </tr> */}
                        <tr style={{ ...this.getStripedStyle(0) }}>
                          {farmPoints[t].sizePointsHeader.map(item => (
                            <th>{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {farmPoints[t].sizePoints.map((row, i) => (
                          <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                            {row.map((item, j) => (
                              <td>{item}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TabPane>

              })}
            </TabContent>
          </CardBody>
        </Card>
        <br />
      </Fragment>
    );
  }
}

export default FarmPoints;
