import React from 'react';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import JkTyreLogo from '../../assets/images/JK-Tyre-logo.png'
import './RangerEvent.css';

const RangerEventFooter = ({ queryParameter }) => {
  let history = useHistory();

  const gotoContact = () => {
    history.push({
      pathname: '/event-registration',
      state: queryParameter
    })
  }
  return (
    <Fragment>
      <div className="footerContainer">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img className="jktyre-event-logo" src={JkTyreLogo} alt="Jk Tyres" />
          <p onClick={gotoContact} className="event-contact-us">Register Here</p>
        </div>
        <div style={{ marginTop: '20px' }}>
          <p className="ranger-footer-text">
            © JK Tyre & Industries Ltd. 2020 | All Rights Reserved
          </p>

        </div>
      </div>
    </Fragment>
  )
}
export default RangerEventFooter