import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Table,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import "./ProposalList.css";
import Ionicon from "react-ionicons";
import ProposalSerivice from "../../services/ProposalSerivice";
import Moment from "react-moment";
import Storage from "../../services/Storage";
import swal from "sweetalert";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import Pagination from "../../components/Pagination/Pagination";
import { downlodFile } from "../../utils/utils";
import { Roles } from "../../constants/constant.role";
class ProposalList extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    let userDetail = Storage.getUserDetail();
    this.state = {
      assign: query.get("assign"),
      type: query.get("type"),
      userDetail: Storage.getUserDetail(),
      proposalsList: [],
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      controls: {
        agency_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        proposal_type: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        month: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        year: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        outlet: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        stage: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        project_status: {
          value:
            userDetail.type && userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE
              ? "Proposal accepted"
              : "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        proposal_status: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        q: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      isAssginAgency: false,
      agencyList: [],
      years: [],
    };
    let projectStagetFilter = ProposalSerivice.getFilterProjectStage();

    if (projectStagetFilter && Object.keys(projectStagetFilter).length > 0) {
      if (projectStagetFilter.stage) {
        this.state.controls.stage.value = projectStagetFilter.stage;
      }
      if (projectStagetFilter.proposal_status) {
        if (projectStagetFilter.proposal_status === "Fresh") {
          this.state.controls.proposal_status.value = "";
        } else {
          this.state.controls.proposal_status.value =
            projectStagetFilter.proposal_status;
        }
      }
      if (projectStagetFilter.outlet) {
        this.state.controls.outlet.value = projectStagetFilter.outlet;
      }
      if (projectStagetFilter.project_status) {
        this.state.controls.project_status.value =
          projectStagetFilter.project_status;
      }
      if (projectStagetFilter.month) {
        this.state.controls.month.value = projectStagetFilter.month;
      }
      if (projectStagetFilter.year) {
        this.state.controls.year.value = projectStagetFilter.year;
      }
      if (projectStagetFilter.stage) {
        this.state.controls.stage.value = projectStagetFilter.stage;
      }

      if (projectStagetFilter.q) {
        this.state.controls.q.value = projectStagetFilter.q;
      }
      if (projectStagetFilter.page) {
        this.state.page = this.state.page;
      }
      if (projectStagetFilter.pageSize) {
        this.state.pageSize = projectStagetFilter.limit;
      }
    }
    let currentYear = new Date().getFullYear();
    let startYear = 2017;
    let count = currentYear - startYear;
    for (var i = 0; i <= count; i++) {
      this.state.years.push(startYear);
      startYear++;
    }
  }

  componentDidMount() {
    this.getProposalList();
    if (this.state.userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE) {
      let { controls } = this.state;
      controls.project_status.value = "Proposal accepted";
      this.getAgencyList();
    }
  }

  handlePageChange = async (page) => {
    await this.setState((prevState) => {
      return {
        ...prevState,
        page: page,
      };
    });

    this.getProposalList();
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    if (controlName === "outlet") {
      controls["stage"].value = "";
    }

    if (controls.project_status.value === "Proposal rejected") {
      controls.stage.value = "";
    }
    this.setState({ controls });
  };

  search = async () => {
    await this.setState({
      page: 1,
    });
    this.getProposalList();
  };
  getAgencyList() {
    ProposalSerivice.getAgencyList()
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          this.setState((prevState) => {
            return { ...prevState, agencyList: res.data.data };
          });
        } else {
          this.setState((prevState) => {
            return { ...prevState, proposalsList: [] };
          });
        }
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }
  assignAgency = () => {
    const { controls } = this.state;
    let proposalStageStatus = ProposalSerivice.getProposalStageStatus();
    if (!controls.agency_id.value && proposalStageStatus.uuid) {
      return;
    }
    let reqData = {
      agency_id: controls.agency_id.value,
      uuid: proposalStageStatus.uuid,
    };
    ProposalSerivice.assignAgency(reqData)
      .then((res) => {
        controls.agency_id.value = "";
        swal({
          title: "Congratulations !",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.getProposalList();
        this.setState({ isLoading: false, controls, isAssginAgency: false });
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };
  getProposalList(isExport = false) {
    const reqData = {};
    const { controls } = this.state;
    if (controls.month.value) {
      reqData.month = controls.month.value;
    }
    if (controls.year.value) {
      reqData.year = controls.year.value;
    }
    if (controls.outlet.value) {
      reqData.outlet = controls.outlet.value;
    }
    if (controls.stage.value) {
      reqData.stage = controls.stage.value;
    }
    if (controls.project_status.value) {
      reqData.project_status = controls.project_status.value;
    }
    if (controls.proposal_status.value) {
      reqData.proposal_status = controls.proposal_status.value;
    }
    if (controls.proposal_type.value) {
      reqData.proposal_type = controls.proposal_type.value;
    }
    if (controls.q.value) {
      reqData.q = controls.q.value;
    }
    reqData.page = this.state.page;
    reqData.limit = this.state.pageSize;
    ProposalSerivice.setFilterInProjectStage(reqData);
    if (isExport) {
      reqData.export = true;
    }
    this.setState((prevState) => {
      return { ...prevState, isLoading: true };
    });

    ProposalSerivice.getProposalList(reqData)
      .then((res) => {
        let { proposalsList, count, totalPages } = this.state;
        if (isExport) {
          const { file } = res.data.data;
          downlodFile(file);
          this.setState({ isLoading: false });
        } else {
          if (
            res.data &&
            res.data.data &&
            Array.isArray(res.data.data) &&
            !res.data.data.proposals
          ) {
            proposalsList = [];
            count = 0;
            totalPages = 0;
          } else {
            if (res.data.data && res.data.data.proposals.length > 0) {
              proposalsList = res.data.data.proposals;
              count = res.data.data.total;
              totalPages = Math.ceil(count / this.state.pageSize);
            } else {
              proposalsList = [];
              count = 0;
              totalPages = 0;
            }
          }
          this.setState((prevState) => {
            return {
              ...prevState,
              proposalsList,
              count,
              totalPages,
              isLoading: false,
            };
          });
        }
      })
      .catch((e) => {
        this.setState((prevState) => {
          return { ...prevState, isLoading: false };
        });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  backToMain = () => {
    this.getProposalList();
    this.setState((prevState) => {
      return { ...prevState, isAssginAgency: false };
    });
  };
  redirectProjectStageStatus = (obj) => {
    // const { controls } = this.state;
    // if (controls.project_status.value === 'Modification needed') {
    //   return
    // }

    ProposalSerivice.setProposalStageStatus(obj);

    let userDetail = Storage.getUserDetail();
    if (obj.outlet_type === "PTP") {
      this.props.history.push(
        `proposal-ptp?proposal-status=${obj.project_status}`
      );
      return;
    }
    if (userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE) {
      if (this.state.assign === "true") {
        let { proposalsList } = this.state;
        proposalsList = [];
        proposalsList.push(obj);
        this.setState((prevState) => {
          return { ...prevState, proposalsList, isAssginAgency: true };
        });
      } else {
        this.props.history.push(
          `proposal-architect?proposal-status=${obj.project_status}`
        );
      }
    } else if (
      userDetail.access_level.toLowerCase() === "ztm" ||
      userDetail.access_level.toLowerCase() === "area" ||
      userDetail.access_level.toLowerCase() === "zone" ||
      userDetail.access_level.toLowerCase() === "region" ||
      userDetail.access_level.toLowerCase() === "zone"
    ) {
      this.props.history.push(`proposal-architect-am`);
    } else {
      this.props.history.push(
        `proposal-architect?proposal-status=${obj.project_status}`
      );
    }
  };
  redirectNewProposal = () => {
    this.props.history.push("proposal-details");
  };
  redirectProposalDashboard = () => {
    this.props.history.push("proposal-dashboard");
  };
  resetFilter = () => {
    let { controls } = this.state;
    controls.month.value = "";
    controls.year.value = "";
    controls.outlet.value = "";
    controls.project_status.value = "";
    controls.proposal_status.value = "";
    controls.q.value = "";
    controls.stage.value = "";
    if (this.state.userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE) {
      controls.project_status.value = "Proposal accepted";
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        controls,
        page: 1,
      };
    });
    this.getProposalList();
  };
  redirectDashboard = () => {
    this.props.history.push(`proposal-dashboard`);
  };
  render() {
    let { controls } = this.state;
    return (
      <div style={{ position: "relative" }}>
        {this.state.isLoading && <CustomSpinner marginTop="0" />}
        {/* <div className="header-title">Project Stage</div> */}
        <>
          <span
            className="prev-page-link cursor-pointer"
            onClick={this.redirectDashboard}
          >
            Proposal Dashboard
          </span>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span
            className="prev-page-link cursor-pointer"
            style={{ color: "#2c3f4c" }}
          >
            Project Stage
          </span>
        </>
        <br />
        <Card>
          <CardBody>
            {/* Dealership Details */}
            <Row>
              <Col xl="6" md="12" xs="12">
                <b>
                  {/* <FontAwesomeIcon
                    className="ml-2 opacity-8 arrow-color cursor-pointer"
                    icon={faFilter}
                  /> */}
                  Filter
                </b>
              </Col>

              <Col xl="6" className="text-align-right">
                {(this.state.userDetail.access_level.toLowerCase() === "area" ||
                  this.state.userDetail.access_level.toLowerCase() ===
                  "all") && (
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      onClick={() => this.redirectNewProposal()}
                    >
                      New Proposal
                    </Button>
                  )}
                &nbsp;&nbsp;
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                  color="dark"
                  onClick={() => this.redirectProposalDashboard()}
                >
                  Back
                </Button>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Month</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="month"
                    value={controls.month.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="">
                      Select Month
                    </option>
                    <option value="1">Jan</option>
                    <option value="2">Feb</option>
                    <option value="3">Mar</option>
                    <option value="4">Apr</option>
                    <option value="5">May</option>
                    <option value="6">Jun</option>
                    <option value="7">Jul</option>
                    <option value="8">Aug</option>
                    <option value="9">Sep</option>
                    <option value="10">Oct</option>
                    <option value="11">Nov</option>
                    <option value="12">Dec</option>
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Year</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="year"
                    value={controls.year.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="">
                      All year
                    </option>
                    {this.state.years.map((item, i) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Proposal Type</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="proposal_type"
                    value={controls.proposal_type.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="">
                      Select proposal type
                    </option>
                    <option value="fresh">Fresh</option>
                    <option value="renewal">Renewal</option>
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Proposal Status</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="project_status"
                    value={controls.project_status.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="">
                      Select proposal status
                    </option>
                    {this.state.userDetail.type === Roles.AREA_MANAGER_ROLE && (
                      <option value="Save Draft Proposal">
                        Save Draft Proposal
                      </option>
                    )}
                    {this.state.userDetail &&
                      this.state.userDetail.type &&
                      this.state.userDetail.type.toLowerCase() !==
                      Roles.ARCHITECT_ROLE && (
                        <option value="Fresh Proposal">Fresh Proposal</option>
                      )}
                    <option value="Proposal accepted">Proposal accepted</option>
                    <option value="Modification needed">
                      Modification needed
                    </option>
                    <option value="Proposal rejected">Proposal rejected</option>
                    {this.state.userDetail.access_level === "architect" &&
                      controls.outlet.value === "PTP" ? (
                      ""
                    ) : (
                      <option value="Branding rejected">
                        Branding Rejected
                      </option>
                    )}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Outlet Type</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="outlet"
                    value={controls.outlet.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option value="">Select outlet type </option>
                    <option selected="selected" value="Steel Wheel">
                      Steel Wheel
                    </option>
                    <option value="Xpress Wheel">Xpress Wheel</option>
                    <option value="Farm Xpress Wheel">Farm Xpress Wheel</option>
                    {this.state.userDetail.access_level === "area" ||
                      this.state.userDetail.access_level === "agency" ||
                      this.state.userDetail.access_level === "all" ||
                      this.state.userDetail.access_level === "ztm" ||
                      this.state.userDetail.access_level === "zone" ? (
                      <option value="PTP">PTP</option>
                    ) : (
                      ""
                    )}
                  </select>
                </FormGroup>
              </Col>
              {controls.outlet.value === "" ||
                controls.outlet.value === "Xpress Wheel" ||
                controls.outlet.value === "Farm Xpress Wheel" ||
                controls.outlet.value === "Steel Wheel" ? (
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label for="phone">Project Stage</Label>
                    <select
                      className="form-control select-height-70rem"
                      name="stage"
                      value={controls.stage.value}
                      onChange={(e) => {
                        this.handleInputChange(e);
                      }}
                    >
                      <option value="" selected>
                        Select project stage
                      </option>
                      <option value="Recci in progress">
                        Recci in progress
                      </option>
                      <option value="Layout & agreement">
                        Layout &amp; Agreement
                      </option>
                      {(!controls.outlet.value ||
                        controls.outlet.value === "Steel Wheel") && (
                          <option value="Estimate needed">Estimate needed</option>
                        )}
                      <option value="Dealer work in progress">
                        Dealer work in progress
                      </option>
                      <option value="Agency work in progress">
                        Agency work in progress
                      </option>
                      <option value="Hand Over">Hand Over</option>
                      <option value="Pre inauguration">Pre inauguration</option>
                      <option value="Inaugurated">Inaugurated</option>
                      <option value="Project completed">
                        Project completed
                      </option>
                    </select>
                  </FormGroup>
                </Col>
              ) : (
                ""
              )}
              {controls.outlet.value === "PTP" ? (
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label for="phone">Project Stage</Label>
                    <select
                      className="form-control select-height-70rem"
                      name="stage"
                      value={controls.stage.value}
                      onChange={(e) => {
                        this.handleInputChange(e);
                      }}
                    >
                      <option value="" selected>
                        Select project stage
                      </option>
                      <option value="Proposal Accepted">
                        Proposal Accepted
                      </option>
                      <option value="Upload Agreement">Upload Agreement</option>
                      <option value="Agency Allocation">
                        Agency Allocation
                      </option>
                      <option value="Branding In Progress">
                        Branding In Progress
                      </option>
                      <option value="Branding Completed">
                        Branding Completed
                      </option>
                      <option value="Pre inauguration">Pre inauguration</option>
                      <option value="Inaugurated">Inaugurated</option>
                      {/* <option value="Completed">Project completed</option> */}
                    </select>
                  </FormGroup>
                </Col>
              ) : (
                ""
              )}

              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Active / On Hold / Dropped</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="proposal_status"
                    value={controls.proposal_status.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="">
                      Select Status
                    </option>
                    <option value="Active">Active</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Dropped">Dropped</option>
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Keyword</Label>
                  <Input
                    type="text"
                    placeholder="Search keyword (Dealer/Region/Mobile)"
                    name="q"
                    value={controls.q.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                xl="6"
                md="12"
                xs="12"
                className="d-flex-column ml-auto"
                style={{ marginBottom: "1%" }}
              >
                <div
                  style={{ marginTop: "auto", justifyContent: "flex-end" }}
                  className="d-flex-row"
                >
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    onClick={() => this.search()}
                    style={{ paddingLeft: "25px", paddingRight: "25px" }}
                  >
                    Search
                  </Button>
                  <div
                    className="d-flex-row reset-filter-wrapp"
                    style={{ marginLeft: "10px" }}
                    onClick={() => this.resetFilter()}
                  >
                    <img
                      src="images/reset.svg"
                      alt=""
                      style={{ height: "20px", width: "20px", margin: "auto" }}
                    />
                    &nbsp;&nbsp;
                    <span style={{ marginTop: "auto", marginBottom: "auto" }}>
                      Reset
                    </span>
                  </div>
                  {(this.state.userDetail.access_level === "architect" ||
                    this.state.userDetail.access_level === "all" ||
                    this.state.userDetail.access_level === "brand_store") && (
                      <div className="mt-auto mb-auto ml-1">
                        <img
                          src="images/download.svg"
                          alt=""
                          className="download-report-icon cursor-pointer margin-left-10"
                          onClick={() => this.getProposalList(true)}
                        />
                        &nbsp;
                        <span
                          className="link-blue"
                          style={{ fontSize: "15px" }}
                          onClick={() => this.getProposalList(true)}
                        >
                          Download Report
                        </span>
                      </div>
                    )}
                </div>
              </Col>
              {/* <Col xl="9" md="12" xs="12">
              </Col> */}
              {/* <Col xl="3" md="12" xs="12">
              
              </Col> */}
              {/* <Col xl="1"></Col> */}
            </Row>
            <br />
            <div className="tbl-project-stage">
              <Table
                id="table"
                className="mb-0 table-responsive tbl-about tbl-about"
              >
                <thead>
                  <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Dealership Name</th>
                    <th scope="col">Region</th>
                    <th scope="col">Proposal Type</th>
                    {this.state.userDetail.access_level !== "architect" && (
                      <th scope="col">Proposal Status</th>
                    )}
                    {this.state.userDetail.access_level === "architect" && (
                      <th scope="col">Agency</th>
                    )}
                    <th scope="col">Project Stage</th>
                    <th scope="col">Outlet Type</th>
                    <th scope="col">Status</th>
                    {this.state.userDetail.access_level !== "architect" && (
                      <th scope="col">Report Date</th>
                    )}
                    <th scope="col">Approve Date</th>
                  </tr>
                </thead>
                <tbody className="project-stage-filter-tbody">
                  {this.state.proposalsList.map((row, i) =>
                    this.state.userDetail.access_level === "architect" &&
                      row.outlet_type === "PTP" ? (
                      ""
                    ) : (
                      <tr key={i}>
                        <td>
                          <a
                            onClick={() => this.redirectProjectStageStatus(row)}
                            id="ContentPlaceHolder1_grdproposal_lnkbtn_0"
                            href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$grdproposal$ctl02$lnkbtn&#39;,&#39;&#39;)"
                          >
                            Select
                          </a>
                        </td>
                        <td>{row.name}</td>
                        <td>{row.region}</td>
                        {/* <td>{row.project_status}</td> */}
                        <td className="text-capitalize">
                          {row.proposal_type ? row.proposal_type : null}
                        </td>
                        {this.state.userDetail.access_level === "architect" && (
                          <td>
                            {row.agency_name ? (
                              <span>{row.agency_name}</span>
                            ) : (
                              <span className="no-data-hypen  ml-auto mr-auto"></span>
                            )}
                          </td>
                        )}
                        {this.state.userDetail.access_level !== "architect" && (
                          <td>{row.project_status}</td>
                        )}
                        <td>
                          {row.stage ? (
                            <sapn>{row.stage}</sapn>
                          ) : (
                            <span className="no-data-hypen  ml-auto mr-auto"></span>
                          )}
                        </td>
                        <td>{row.outlet_type}</td>
                        <td>{row.status}</td>
                        {/* <td>{row.proposal_status}</td> */}
                        {this.state.userDetail.access_level !== "architect" && (
                          <td>
                            {row.report_date && (
                              <Moment format="DD/MM/YYYY">
                                {row.report_date}
                              </Moment>
                            )}
                          </td>
                        )}
                        <td>
                          {row.approval_date ? (
                            <Moment format="DD/MM/YYYY">
                              {row.approval_date}
                            </Moment>
                          ) : (
                            <span className="no-data-hypen ml-auto mr-auto"></span>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                  {/* <tr>
                    <td>
                      <a onClick={() => this.redirectProjectStageStatus()} id="ContentPlaceHolder1_grdproposal_lnkbtn_0" href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$grdproposal$ctl02$lnkbtn&#39;,&#39;&#39;)"   >Select</a>
                    </td><td>Advance Tyres Sales and Services</td><td>Ahmedabad</td><td>Proposal accepted</td><td>Layout &amp; Agreement</td><td>SW</td><td>Active</td><td>Fresh</td><td>7/16/2019 5:19:43 PM</td>
                  </tr> */}
                </tbody>
              </Table>
              {this.state.proposalsList.length <= 0 && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%" }}
                >
                  Currently, there is no proposals.
                </div>
              )}
              {this.state.count > 0 && (
                <div style={{ marginTop: "15px" }}>
                  <Pagination
                    margin={2}
                    page={this.state.page}
                    count={this.state.totalPages}
                    pageSize={this.state.pageSize}
                    totalRecords={this.state.count}
                    onPageChange={this.handlePageChange}
                  ></Pagination>
                </div>
              )}
              <br />
              {this.state.isAssginAgency && (
                <>
                  <Row>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>Agency</Label>
                        <select
                          className="form-control select-height-70rem"
                          name="agency_id"
                          value={controls.agency_id.value}
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value="" selected disabled>
                            Select agency
                          </option>
                          {this.state.agencyList &&
                            this.state.agencyList.map((item, i) => (
                              <option value={item.id}>
                                {item.business_name}
                              </option>
                            ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col xl="3" md="12" xs="12">
                      <Button
                        className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                        color="warning"
                        type="submit"
                        style={{ marginTop: "30px" }}
                        onClick={(e) => this.assignAgency()}
                      >
                        Submit
                      </Button>{" "}
                      &nbsp;&nbsp;
                      <Button
                        className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                        color="dark"
                        style={{ marginTop: "30px" }}
                        onClick={() => this.backToMain()}
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default ProposalList;
