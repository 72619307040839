import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, Label } from 'reactstrap';
import CustomSpinner from '../CustomSpinner/CustomSpinner';

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      details: {}
    }
    this.toggle = this.toggle.bind(this);
  }
  toggle(data) {
    let { controls, details } = this.state;
    if (data && Object.keys(data).length > 0) {
      details = data;
    } else {
      details = {};
      // this.resetControls();
    }
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      controls,
      details
    }));
  }
  render() {
    let { details } = this.state;
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <>
        <Modal
          centered={true}
          isOpen={this.state.modal} toggle={this.toggle} external={externalCloseBtn} className="modal-md modal-lg custom-confirm-modal modal-sm"
          style={{ maxWidth: '820px' }} backdrop={this.backdrop ? 'static' : 'nostatic'}>
          {this.state.isLoading && <CustomSpinner />}
          <div className="d-flex-column">
            <div className="confirm-modal-title-wrapp justify-content-between">
              <span className="confirm-modal-title text-capitalize" style={{
                fontSize: '16px',
                marginLeft: '10px'
              }}>
                Order ID : 435353535353
              </span>
              <img className="cursor-pointer float-right" src="images/close.svg" alt='cancel' onClick={() => this.toggle({})} />
            </div>
          </div>
          <ModalBody
            className={'d-flex-column'}>
            <Row className="m-0">
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">GST Number</Label>
                <Label className="font-weight-bold text-uppercase">{details['GST No'] ? details['GST No'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">Legal Name</Label>
                <Label className="font-weight-bold">{details['Dealer Name'] ? details['Dealer Name'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">HSN/SAC</Label>
                <Label className="font-weight-bold">{details['HSN/SAC'] ? details['HSN/SAC'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">Product Category</Label>
                <Label className="font-weight-bold">{details['Product Category'] ? details['Product Category'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">Value per Point</Label>
                <Label className="font-weight-bold">{details['Value per point'] ? details['Value per point'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">Total SMS Credits</Label>
                <Label className="font-weight-bold">{details['Actual Count of Product'] ? details['Actual Count of Product'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">Redemption Point Price</Label>
                <Label className="font-weight-bold">{details['Total Value of redemption'] ? details['Total Value of redemption'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">Base Price</Label>
                <Label className="font-weight-bold">{details['Base Price'] ? details['Base Price'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">Discount</Label>
                <Label className="font-weight-bold">{details['Discount'] ? details['Discount'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">Taxable</Label>
                <Label className="font-weight-bold">{details['Taxable'] ? details['Taxable'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">CGST</Label>
                <Label className="font-weight-bold">{details['CGST'] ? details['CGST'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">SGST</Label>
                <Label className="font-weight-bold">{details['SGST'] ? details['SGST'] : '--'}</Label>
              </Col>
              <Col xl='3' className="d-flex-column">
                <Label className="mb-1">IGST</Label>
                <Label className="font-weight-bold">{details['IGST'] ? details['IGST'] : '--'}</Label>
              </Col>
              <Col xl='6' className="d-flex-column">
                <Label className="mb-1">Address</Label>
                <Label className="font-weight-bold">{details['Store Address'] ? details['Store Address'] : '--'}</Label>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      </>
    );
  }
}

export default OrderDetail;