import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table } from "reactstrap";
import _ from "lodash";
import { formatNumber } from "../../../utils/utils";
// import { messages } from "../../../constants/constant.message";
import StorageService from "../../../services/Storage";
import { Roles } from "../../../constants/constant.role";

class PrimaryOfftakeSummary extends Component {
  offTakeSummary = {};
  constructor(props) {
    super(props);
    this.state = {
      redirectHistory: false,
      type: null
    }
  }

  componentDidMount() {
    const userDetail = StorageService.getUserDetail();
    this.setState({
      type: userDetail.type
    })
  }

  getStripedStyle(index) {
    return { background: index % 2 === 0 ? "#f6f6f6" : "white" };
  }

  viewOrederHistory = () => {
    if (this.props && this.props.year) {
      this.props.closePointsModel();
      this.props.changeTab(1);
    } else {
      this.setState({
        redirectHistory: true
      })
    }
  }
  sumCol(col) { }
  render() {
    this.offTakeSummary = this.props.offTakeSummary;
    let totalPoints = 0;
    let totalBalance = 0;
    if (this.offTakeSummary && this.offTakeSummary.pointsSummary) {
      totalPoints = _.sumBy(this.offTakeSummary.pointsSummary, function (item) {
        return item.points;
      });
    }
    if (this.offTakeSummary && this.offTakeSummary.pointsSummary) {
      totalBalance = _.sumBy(this.offTakeSummary.pointsSummary, function (item) {
        return item.balance;
      });
    }
    return (
      <>
        {this.state.redirectHistory && <Redirect to={{
          pathname: "/redemption-catalog",
          search: `history=true`
        }} />}
        <div className="quarter-points-wrapp">
          <div className="quarter-points-inner">
            <div className="d-flex-column">
              <div>
                <span className="Total-Earned-Points">
                  Total Earned Points{" "}
                  <b>{formatNumber(totalPoints)}</b>
                </span>
                {(this.props.year && this.props.yearOptions && this.state.type === Roles.DEALER_ROLE) && (
                  <div style={{ float: 'right' }}>
                    <span className="fin-label">Financial Year</span>
                    <select
                      name="month"
                      className="form-control cursor-pointer"
                      value={this.props.year}
                      style={{
                        fontSize: "13px",
                        width: "110px",
                        color: "#6e777f",
                      }}
                      onChange={(e) => {
                        this.props.onChangeYear(e);
                      }}
                    >
                      {this.props.yearOptions.map((item) => (
                        <option value={item.value} selected>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>)}
                <br />
                <span className="Till-Date mb-2 mt-2">Till Date</span>
              </div>

              <div className="QUARTER-table quarter-tbl-left">
                <Table
                  className={this.props.year ? "mb-0 table-responsive" : "mb-0 table-responsive tbl-offtake-summary"}
                  bordered
                  style={this.props.year ? { border: "none", marginTop: "10px" } : { border: "none" }}
                >
                  <thead>
                    <tr style={{ ...this.getStripedStyle(0) }}>
                      <th className="offtake-tbl-th">Description</th>
                      <th className="offtake-tbl-th text-align-center">
                        Points
                      </th>
                    </tr>
                  </thead>
                  {
                    <tbody>
                      {this.offTakeSummary.pointsSummary.map((item, i) =>
                        item.label === "Opening balance" &&
                          item.points === 0 ? (
                          ""
                        ) : (
                          <tr style={{ ...this.getStripedStyle(i + 1) }}>
                            <td>{item.label} <span className='prev-page-link cursor-pointer' style={{ textDecoration: 'underline' }} onClick={(e) => { this.viewOrederHistory(e) }}>
                              {item.type === 'redeem_points' ? 'View Order' : ''}</span></td>
                            <td className="text-align-center">
                              {formatNumber(item.points)}
                            </td>
                          </tr>
                        )
                      )}
                      {this.offTakeSummary.pointsSummary &&
                        this.offTakeSummary.pointsSummary.length > 0 && (
                          <tr style={{ backgroundColor: "#efefef" }}>
                            <th>Total</th>
                            <td className="text-align-center">
                              {formatNumber(totalPoints)}
                            </td>
                          </tr>
                        )}
                    </tbody>
                  }
                </Table>
                {this.offTakeSummary.isNoDataFound && (
                  <div className="no-data tbl-offtake-summary">
                    Currently, there is no points.
                  </div>
                )}
              </div>
              <br />
              {/* <a href="https://www.jktyreadvantage.com" target="_blank" rel="noopener noreferrer">
                <b className="Learn-more-about" style={{ color: '#495057' }}>
                  {messages.PRIMARY_REPORT.REDIRECT_JK_ADVANTAGE}
                </b>
              </a> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PrimaryOfftakeSummary;
