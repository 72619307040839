export const PROJECT_NAME = "Techify react starter project";
export const TAG_LINE = "Speed up your work";
const WHATSAPP_LANGUAGES = [
  { value: "af", label: "Afrikaans (af)" },
  { value: "sq", label: "Albanian (sq)" },
  { value: "ar", label: "Arabic (ar)" },
  { value: "az", label: "Azerbaijani (az)" },
  { value: "bn", label: "Bengali (bn)" },
  { value: "bg", label: "Bulgarian (bg)" },
  { value: "ca", label: "Catalan (ca)" },
  { value: "zh_CN", label: "Chinese (CHN) (zh_CN)" },
  { value: "zh_HK", label: "Chinese (HKG) (zh_HK)" },
  { value: "zh_TW", label: "Chinese (TAI) (zh_TW)" },
  { value: "hr", label: "Croatian (hr)" },
  { value: "cs", label: "Czech (cs)" },
  { value: "da", label: "Danish (da)" },
  { value: "nl", label: "Dutch (nl)" },
  { value: "en", label: "English (en)" },
  { value: "en_GB", label: "English (UK) (en_GB)" },
  { value: "en_US", label: "English (US) (en_US)" },
  { value: "et", label: "Estonian (et)" },
  { value: "fil", label: "Filipino (fil)" },
  { value: "fi", label: "Finnish (fi)" },
  { value: "fr", label: "French (fr)" },
  { value: "de", label: "German (de)" },
  { value: "el", label: "Greek (el)" },
  { value: "gu", label: "Gujarati (gu)" },
  { value: "ha", label: "Hausa (ha)" },
  { value: "he", label: "Hebrew (he)" },
  { value: "hi", label: "Hindi (hi)" },
  { value: "hu", label: "Hungarian (hu)" },
  { value: "id", label: "Indonesian (id)" },
  { value: "ga", label: "Irish (ga)" },
  { value: "it", label: "Italian (it)" },
  { value: "ja", label: "Japanese (ja)" },
  { value: "kn", label: "Kannada (kn)" },
  { value: "kk", label: "Kazakh (kk)" },
  { value: "ko", label: "Korean (ko)" },
  { value: "lo", label: "Lao (lo)" },
  { value: "lv", label: "Latvian (lv)" },
  { value: "lt", label: "Lithuanian (lt)" },
  { value: "mk", label: "Macedonian (mk)" },
  { value: "ms", label: "Malay (ms)" },
  { value: "ml", label: "Malayalam (ml)" },
  { value: "mr", label: "Marathi (mr)" },
  { value: "nb", label: "Norwegian (nb)" },
  { value: "fa", label: "Persian (fa)" },
  { value: "pl", label: "Polish (pl)" },
  { value: "pt_BR", label: "Portuguese (BR) (pt_BR)" },
  { value: "pt_PT", label: "Portuguese (POR) (pt_PT)" },
  { value: "pa", label: "Punjabi (pa)" },
  { value: "ro", label: "Romanian (ro)" },
  { value: "ru", label: "Russian (ru)" },
  { value: "sr", label: "Serbian (sr)" },
  { value: "sk", label: "Slovak (sk)" },
  { value: "sl", label: "Slovenian (sl)" },
  { value: "es", label: "Spanish (es)" },
  { value: "es_AR", label: "Spanish (ARG) (es_AR)" },
  { value: "es_ES", label: "Spanish (SPA) (es_ES)" },
  { value: "es_MX", label: "Spanish (MEX) (es_MX)" },
  { value: "sw", label: "Swahili (sw)" },
  { value: "sv", label: "Swedish (sv)" },
  { value: "ta", label: "Tamil (ta)" },
  { value: "te", label: "Telugu (te)" },
  { value: "th", label: "Thai (th)" },
  { value: "tr", label: "Turkish (tr)" },
  { value: "uk", label: "Ukrainian (uk)" },
  { value: "ur", label: "Urdu (ur)" },
  { value: "uz", label: "Uzbek (uz)" },
  { value: "vi", label: "Vietnamese (vi)" },
  { value: "zu", label: "Zulu (zu)" },
];
export const POINT_MAX_RANGE_LIMIT = 95000000;

const constants = {
  PROJECT_NAME,
  TAG_LINE,
  WHATSAPP_LANGUAGES,
  POINT_MAX_RANGE_LIMIT
};

export default constants;
