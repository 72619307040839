import { request } from "./Request";
import { routes } from "../constants/constant.route";

const { WARRANTY_REPORT_CHART } = routes.WARRANTY_REGISTRATION;

const API_URL = process.env.REACT_APP_API_URL;

export default class WarrantyReportChartService {
  static getChartData(sap_id, maxDate, vehicle_type) {
    let qp = `sap_id=${sap_id}&vehicle_type=${vehicle_type}&max_date=${maxDate}`;
    return request(
      "GET",
      `${API_URL}${WARRANTY_REPORT_CHART}?${qp}`,
      null,
      null,
      null
    );
  }
}
