import { request } from './Request';
import { routes } from '../constants/constant.route';

const API_URL = process.env.REACT_APP_API_URL;

export default class CustomerReferenceService {

  static getDealerInfo(req) {
    req.source = 'reference';
    return request('POST', `${API_URL}${routes.CUSTOMER_REFERNCE.GET_DEALER_INFO}`, null, req, null);
  }

  static addReffCustomer(params) {
    params.source = 'reference';
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_REFERNCE.ADD_REFF_CUSTOMER}`,
      null,
      params,
      null
    );
  }
}