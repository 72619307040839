import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
} from "reactstrap";
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import './ReferralRewardsPreference.css';
import { TextAreaHighlight } from '../../utils/TextAreaHighlight';
// import TextareaAutosize from 'react-textarea-autosize';
import { changeSMSContent } from '../../utils/utils';
import lodash from 'lodash';
import AddCustomerService from '../../services/AddCustomerService';
import swal from 'sweetalert';
import ModalService from '../../services/ModalService';
import { messages } from '../../constants/constant.message';
import Validation from '../../services/Validation';
import OutsideClickHandler from "react-outside-click-handler";
import GoogleAnalticsService from '../../services/GoogleAnalticsService';

export default class ReferralRewardsPreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedTags: [],
      offer_data: {},
      selectedTagsReferrer: [],
      highLightTag: [],
      smsConfig: {
        isNotValidMinCount: false,
        length: 0,
        smsContentCredit: 0
      },
      smsConfigReferrer: {
        isNotValidMinCount: false,
        length: 0,
        smsContentCredit: 0
      },
      maxCount: 459,
      controls: {
        language: {
          value: "english",
          valid: null,
          touched: false,
          nullValue: null
        },
        offerMessageForProspectCustomer: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null
        },
        offerMessageForExistingCustomer: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null
        },
        is_same_as_existing: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null
        }
      },
      Tags: ["#StoreName", "#CustomerName", '#ReferenceName', '#GMBStoreLink', '#StoreNumber']
    }
  }

  componentDidMount() {
    GoogleAnalticsService.event('Referral Rewards Preference', 'Referral Rewards Preference View');
    this.getDealerReferalReward();
    document.body.style.overflow = "hidden";
  }

  getDealerReferalReward = () => {
    this.setState({
      isLoading: true
    });

    AddCustomerService.getDealerReferalReward().then(res => {
      let { controls } = this.state;
      if (res.data.data.dealerData && Object.keys(res.data.data.dealerData).length > 0) {
        controls.offerMessageForProspectCustomer.value = res.data.data.dealerData.offerMessageForProspectCustomer ?
          res.data.data.dealerData.offerMessageForProspectCustomer : res.data.data.defaultData.offerMessageForProspectCustomer;

        controls.offerMessageForExistingCustomer.value = res.data.data.dealerData.offerMessageForExistingCustomer ?
          res.data.data.dealerData.offerMessageForExistingCustomer : res.data.data.defaultData.offerMessageForExistingCustomer;
      } else if (res.data.data.defaultData && Object.keys(res.data.data.defaultData).length > 0) {
        controls.offerMessageForProspectCustomer.value = res.data.data.defaultData.offerMessageForProspectCustomer;
        controls.offerMessageForExistingCustomer.value = res.data.data.defaultData.offerMessageForExistingCustomer;
      }
      this.setState({
        controls,
        offer_data: res.data.data,
        isLoading: false
      }, () => {
        this.onChangeSMSContent({
          target: {
            value: controls.offerMessageForProspectCustomer.value,
            name: 'offerMessageForProspectCustomer'
          }
        });
        this.onChangeSMSContentReferrer({
          target: {
            value: controls.offerMessageForExistingCustomer.value,
            name: 'offerMessageForExistingCustomer'
          }
        });
      });
    })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error')
        this.setState({
          isLoading: false
        });
      });
  }

  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let isFormValid = false;
    let {
      offerMessageForProspectCustomer, offerMessageForExistingCustomer
    } = controls;


    if ((offerMessageForProspectCustomer.touched === true || isSubmit)) {
      offerMessageForProspectCustomer = Validation.notNullValidator(offerMessageForProspectCustomer);
      offerMessageForProspectCustomer.valid = !offerMessageForProspectCustomer.nullValue;
      if (offerMessageForProspectCustomer.valid === false) {
        offerMessageForProspectCustomer.showErrorMsg = true;
      } else {
        offerMessageForProspectCustomer.showErrorMsg = false;
      }
    }
    if ((offerMessageForExistingCustomer.touched === true || isSubmit)) {
      offerMessageForExistingCustomer = Validation.notNullValidator(offerMessageForExistingCustomer);
      offerMessageForExistingCustomer.valid = !offerMessageForExistingCustomer.nullValue;
      if (offerMessageForExistingCustomer.valid === false) {
        offerMessageForExistingCustomer.showErrorMsg = true;
      } else {
        offerMessageForExistingCustomer.showErrorMsg = false;
      }
    }
    if (offerMessageForExistingCustomer.valid === true &&
      offerMessageForProspectCustomer.valid === true) {
      isFormValid = true;
    }
    this.setState({
      controls
    })
    return isFormValid;
  }

  setDealerReferalReward = async () => {
    let { controls } = this.state;
    if (!this.handleValidation(true)) {
      return;
    }
    if (this.state.smsConfig.isNotValidMinCount || this.state.smsConfigReferrer.isNotValidMinCount) {
      return
    }
    let reqData = {
      "offerMessageForExistingCustomer": controls.offerMessageForExistingCustomer.value,
      "offerMessageForProspectCustomer": controls.offerMessageForProspectCustomer.value
    }
    this.setState({
      isLoading: true
    }, () => {
    });
    GoogleAnalticsService.event('Referral Rewards Preference', 'Submit Referral Rewards Preference');
    await AddCustomerService.setDealerReferalReward(reqData)
      .then(res => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((isConfirm) => {
          this.props.closeSideBar(true);
        });
        this.setState({
          isLoading: false
        }, () => {
        });
      })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error')
        this.setState({
          isLoading: false
        });
      });
  }

  componentWillUnmount() {
    // document.removeEventListener("mousedown", this.closeActionMenuWhenClickedOutsideMenu);
    document.body.style.overflow = "auto";
  }

  isCharOfAnyTags = (text, position, code, name) => {

    const tagfinal = lodash.find(name === 'offerMessageForProspectCustomer' ? this.state.selectedTags : this.state.selectedTagsReferrer, tag => {

      if (code === "Backspace" || code === 8) {
        return (
          tag &&
          tag.indices &&
          position > tag.indices.start &&
          position <= tag.indices.end
        );
      }
      return (
        tag &&
        tag.indices &&
        position >= tag.indices.start &&
        position < tag.indices.end
      );
    });
    return { tagfinal };
  };

  getIndicesOf = (searchStr, str, caseSensitive) => {
    let searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
      return [];
    }
    let startIndex = 0, index, indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  }

  onChangeSMSContent = e => {
    let { controls, highLightTag, selectedTags, Tags } = this.state;
    controls["offerMessageForProspectCustomer"].showErrorMsg = false;
    if (!e) {
      e = { target: { value: controls["offerMessageForProspectCustomer"].value } }
    }
    let smsConfig = changeSMSContent(
      e.target.value,
      this.state.maxCount,
      true,
      false
    );
    controls.offerMessageForProspectCustomer.value = e.target.value;
    controls.offerMessageForProspectCustomer.touched = true;
    this.setState({
      controls,
      smsConfig: smsConfig
    });
    // this.replaceWords(controls.offerMessageForProspectCustomer.value);

    //copy paste tags
    if (
      highLightTag.length !== Tags.length &&
      (controls.offerMessageForProspectCustomer.value !== "" ||
        controls.offerMessageForProspectCustomer.value !== null ||
        controls.offerMessageForProspectCustomer.value !== undefined)
    ) {
      Tags.forEach(element => {
        const regex = new RegExp(element, "g");
        if (
          highLightTag.indexOf(element) <= -1 &&
          String(controls.offerMessageForProspectCustomer.value).search(regex) > -1
        ) {
          highLightTag.push(element);
          let indexes = this.getIndicesOf(element, controls.offerMessageForProspectCustomer.value);
          if (indexes && indexes > 0) {
            indexes.forEach(index => {
              selectedTags.push({
                indices: {
                  start: index,
                  end: index + element.length
                },
                cssClass: "bg-gray",
                data: element
              });
            });
          }
        }
      });

      this.setState({
        highLightTag,
        selectedTags
      });
    }

    this.handleValidation();
  };

  onChangeSMSContentReferrer = e => {
    let { controls, highLightTag, selectedTagsReferrer, Tags } = this.state;
    controls["offerMessageForExistingCustomer"].showErrorMsg = false;
    if (!e) {
      e = { target: { value: controls["offerMessageForExistingCustomer"].value } }
    }
    let smsConfigReferrer = changeSMSContent(
      e.target.value,
      this.state.maxCount,
      true,
      false
    );
    controls.offerMessageForExistingCustomer.value = e.target.value;
    controls.offerMessageForExistingCustomer.touched = true;
    this.setState({
      controls,
      smsConfigReferrer: smsConfigReferrer
    });
    // this.replaceWords(controls.offerMessageForExistingCustomer.value);

    //copy paste tags
    if (
      highLightTag.length !== Tags.length &&
      (controls.offerMessageForExistingCustomer.value !== "" ||
        controls.offerMessageForExistingCustomer.value !== null ||
        controls.offerMessageForExistingCustomer.value !== undefined)
    ) {
      Tags.forEach(element => {
        const regex = new RegExp(element, "g");
        if (
          highLightTag.indexOf(element) <= -1 &&
          String(controls.offerMessageForExistingCustomer.value).search(regex) > -1
        ) {
          highLightTag.push(element);
          let indexes = this.getIndicesOf(element, controls.offerMessageForExistingCustomer.value);
          if (indexes && indexes > 0) {
            indexes.forEach(index => {
              selectedTagsReferrer.push({
                indices: {
                  start: index,
                  end: index + element.length
                },
                cssClass: "bg-gray",
                data: element
              });
            });
          }
        }
      });

      this.setState({
        highLightTag,
        selectedTagsReferrer
      });
    }

    this.handleValidation();
  };


  onSmsDraftKeyDownReferrer = event => {
    if (event.keyCode === 8 || event.code === "Delete") {
      const tag = this.isCharOfAnyTags(
        event.target.value,
        event.target.selectionEnd - 1,
        event.keyCode,
        event.target.name
      );

      if (tag.tagfinal) {
        event.preventDefault();
        let { selectedTagsReferrer, highLightTag, controls } = this.state;
        let tmpVal =
          event.target.value.slice(0, tag.tagfinal.indices.start) +
          event.target.value.slice(tag.tagfinal.indices.end);
        let index = selectedTagsReferrer.findIndex(
          x => x.indices.end === tag.tagfinal.indices.end
        );
        highLightTag.splice(index, 1);
        selectedTagsReferrer.splice(index, 1);
        if (!tmpVal) {
          highLightTag = [];
          selectedTagsReferrer = [];
          tmpVal = "";
        }
        controls.offerMessageForExistingCustomer.value = tmpVal;
        let smsConfigReferrer = changeSMSContent(
          tmpVal,
          this.state.maxCount,
          true,
          false
        );
        this.setState({
          controls,
          highLightTag,
          selectedTagsReferrer,
          smsConfigReferrer: smsConfigReferrer
        });
      }
    }
  };

  onSmsDraftKeyDown = event => {
    if (event.keyCode === 8 || event.code === "Delete") {
      const tag = this.isCharOfAnyTags(
        event.target.value,
        event.target.selectionEnd - 1,
        event.keyCode,
        event.target.name
      );

      if (tag.tagfinal) {
        event.preventDefault();
        let { selectedTags, highLightTag, controls } = this.state;
        let tmpVal =
          event.target.value.slice(0, tag.tagfinal.indices.start) +
          event.target.value.slice(tag.tagfinal.indices.end);
        let index = selectedTags.findIndex(
          x => x.indices.end === tag.tagfinal.indices.end
        );
        highLightTag.splice(index, 1);
        selectedTags.splice(index, 1);
        if (!tmpVal) {
          highLightTag = [];
          selectedTags = [];
          tmpVal = "";
        }
        controls.offerMessageForProspectCustomer.value = tmpVal;
        let smsConfig = changeSMSContent(
          tmpVal,
          this.state.maxCount,
          true,
          false
        );
        this.setState({
          controls,
          highLightTag,
          selectedTags,
          smsConfig: smsConfig
        });
      }
    }
  };

  addTags = (tag, name) => {
    let { selectedTags, selectedTagsReferrer, controls, highLightTag } = this.state;
    const startIndex = controls[name].value
      ? controls[name].value.length
      : 0;
    if (name === 'offerMessageForProspectCustomer') {
      selectedTags.push({
        indices: {
          start: startIndex,
          end: startIndex + tag.length
        },
        cssClass: "bg-gray",
        data: tag
      });
    } else {
      selectedTagsReferrer.push({
        indices: {
          start: startIndex,
          end: startIndex + tag.length
        },
        cssClass: "bg-gray",
        data: tag
      });
    }
    highLightTag.push(tag);
    let cursorPosition = name === 'offerMessageForProspectCustomer' ? this.refTextAreaHighlight.textarea.selectionStart : this.refTextAreaHighlightOfferMessageForExistingCustomer.textarea.selectionStart;
    let textBeforeCursorPosition = String(controls[name].value).substring(
      0,
      cursorPosition
    );
    let textAfterCursorPosition = String(controls[name].value).substring(
      cursorPosition,
      String(controls[name].value).length
    );
    controls[name].value =
      textBeforeCursorPosition + tag + textAfterCursorPosition;
    let smsConfig = changeSMSContent(
      controls[name].value,
      this.state.maxCount,
      true,
      false
    );
    this.setState({
      selectedTags,
      selectedTagsReferrer,
      controls,
      highLightTag,
      smsConfig
    });
    //this.replaceWords(controls.offerMessageForProspectCustomer.value);
  };



  handleChangeIsExisting = (e) => {
    let { controls } = this.state;
    controls.is_same_as_existing.value = !controls.is_same_as_existing.value;
    controls.offerMessageForProspectCustomer.value = controls.is_same_as_existing.value ? controls.offerMessageForExistingCustomer.value : '';
    this.setState({
      controls
    })
  }
  resetDefault = () => {

    swal({
      title: "Reset Default Offer",
      text: "Are you sure want to reset offer messages ?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        let { controls, offer_data } = this.state;
        controls.offerMessageForProspectCustomer.value = offer_data.defaultData.offerMessageForProspectCustomer;
        controls.offerMessageForExistingCustomer.value = offer_data.defaultData.offerMessageForExistingCustomer;
        this.setState({
          controls
        }, () => {
          this.setDealerReferalReward();
        });
      }
    });
  }


  openmsg_detail = val => {
    this.setState({
      msg_detail: val
    });
  };

  openmsg_detailReferrer = val => {
    this.setState({
      msg_detail_refferer: val
    });
  };

  render() {
    let { controls, Tags } = this.state;
    return (
      <div class="custom-drawer" id="ReferralRewardsPreference">
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        <div style={{ marginTop: this.props.from && this.props.from === 'add-customer' ? '0px' : '60px' }}>
          <div className="drawer-header">
            <span className="drawer-title p-r-30px">Referral Rewards Preference </span>
            <img
              className="cursor-pointer"
              src="images/close.svg"
              alt="cancel"
              onClick={this.props.closeSideBar}
            />
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
        </div>
        <div
          class="custom-drawer-content" style={{ paddingLeft: '10px' }}>
          <Row className="m-0">
            <Col xl="12" className="text-align-right mb-2">
              <span className="Reset-to-Default cursor-pointer"
                onClick={() => this.resetDefault()}>
                Reset Default Offer
              </span>
            </Col>
            <Col xl="12">
              <div className="service-pref-note d-flex-column ReferralRewardsPreferenceNote
              mt-0">
                <span>Rewarding customers on sharing references is the opportunity to incentivised customers for sharing their positive experience with others.</span>
                <span className="mt-2">
                  83% of satisfied customers are willing to make a referral
                  92% of consumers trust recommendations from people they know
                </span>
                <span className="mt-2">
                  A referral is not only a lead, but a qualified lead for any business.
                  From a business aspect, acquiring new customers and growing sales is easy when leveraging the voice of established customers. So give the best offers to referrers and referees.
                </span>
              </div>
            </Col>
            <Col xl="12">
              <div className="d-flex-column">
                <span className="offer-lbl">Give this Offer to the Referrer <span className="font-weight-normal">(Your customer, who gives references)</span><span className="required-field">*</span></span>
                <span className="sent-to-referrer-note">Note : "Please provide the SMS content with offer to be sent to referrer"</span>
              </div>
              {/* <TextareaAutosize
                name="comment"
                autocomplete="nofill"
                id="remark"
                value={controls.offerMessageForProspectCustomer.value}
                style={{ minHeight: 80, maxHeight: 90, fontSize: '14px' }}
                className="form-control textarea-autosize"
                // onChange={(e) => this.handleInputChange({ target: { name: 'comment', value: e.target.value } })}
                placeholder=''
              /> */}
              <div className="text-area-chip">
                <TextAreaHighlight
                  className="inputbox-textarea"
                  value={controls.offerMessageForExistingCustomer.value}
                  onKeyDown={this.onSmsDraftKeyDownReferrer}
                  name="offerMessageForExistingCustomer"
                  onChange={this.onChangeSMSContentReferrer}
                  ref={r => (this.refTextAreaHighlightOfferMessageForExistingCustomer = r)}
                  highlight={() => this.state.highLightTag}
                />
              </div>
              {controls.offerMessageForExistingCustomer.showErrorMsg && <div className="error show_error">
                *Please enter offer to the referrer.
              </div>}

              {!controls.offerMessageForExistingCustomer.showErrorMsg &&
                this.state.smsConfigReferrer.isNotValidMinCount && (
                  <div className="error text-none">
                    *Maximum {this.state.maxCount} characters are allowed.
                  </div>
                )}
              <div
                className="d-flex-row justify-content-between mt-2">
                <span className="Message-Credits-Used">
                  Message Credits Used:
                  {this.state.smsConfig.smsContentCredit}
                  <img
                    src="images/sidebar-icons/about-selected.svg"
                    alt=''
                    id="editorimage"
                    className="ml-1 cursor-pointer"
                    onClick={() => this.openmsg_detailReferrer(true)}
                  />
                </span>
                <div
                  className="d-flex-row ml-auto"
                  style={{ justifyContent: "flex-end" }}
                >
                  <span className="noofwordss">
                    {this.state.smsConfigReferrer.length}/{this.state.maxCount}
                  </span>
                </div>
              </div>
              {this.state.msg_detail_refferer ? (
                <OutsideClickHandler
                  onOutsideClick={() => this.openmsg_detailReferrer(false)}
                >
                  <Row>
                    <Col lg="10" sm="12" md="10" xs="12">

                      <div className="entermsgcontent">
                        <div className="d-flex-row justify-content-end">
                          <span
                            className="closebtn ml-auto"
                            onClick={() => this.openmsg_detailReferrer(false)}
                          >
                            X
                          </span>
                        </div>
                        <span className="One-uni-code-charact">
                          <b className="Message-Details">
                            Message Details
                          </b>
                          <br />
                          <ul
                            className="Enrollment-ul text-none"
                            style={{ marginBottom: "0" }}
                          >
                            <li className="enroll-li campaign-popup-notes">
                              A message with upto{" "}
                              {controls.language.value === "english"
                                ? 160
                                : 70}{" "}
                              characters (including space and
                              special characters) uses 1 SMS
                              credit(balance).
                            </li>
                            <li className="enroll-li campaign-popup-notes">
                              If the message is more than{" "}
                              {controls.language.value === "english"
                                ? 160
                                : 70}{" "}
                              characters, 2 SMS credits are used for
                              that message. Meaning the account
                              balance is debited by 2 SMS.
                            </li>
                            <li className="enroll-li campaign-popup-notes">
                              Maximum eligible limit per message is{" "}
                              {this.state.maxCount} characters.
                            </li>
                            <li className="enroll-li campaign-popup-notes">
                              Only English language messages are
                              eligible as of now. JKTYRE does not
                              support unicode messages (Indian
                              language messages).
                            </li>
                          </ul>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </OutsideClickHandler>
              ) : (
                ""
              )}
              {/* <div
                className="d-flex-row mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <div className="tag-wrapper">
                  {Tags.map((item, i) => (
                    <span
                      className="Rectangletag cursor-pointer"
                      style={{ marginLeft: i === 0 ? 0 : '10px' }}
                      onClick={() => this.addTags(item, 'offerMessageForExistingCustomer')}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </div> */}
            </Col>
            <Col xl="12" className="mt-3">
              <div className="d-flex-column">
                <span className="offer-lbl">Give this Offer to the Referee <span className="font-weight-normal">(Your prospect for a new sale)</span><span className="required-field">*</span></span>
                <span className="sent-to-referrer-note">
                  Note : "Please provide the SMS content with offer to be sent to referee"
                </span>
              </div>
              <div className="text-area-chip">
                <TextAreaHighlight
                  className="inputbox-textarea"
                  value={controls.offerMessageForProspectCustomer.value}
                  onKeyDown={this.onSmsDraftKeyDown}
                  onChange={this.onChangeSMSContent}
                  ref={r => (this.refTextAreaHighlight = r)}
                  name="offerMessageForProspectCustomer"
                  highlight={() => this.state.highLightTag}
                />
              </div>
              {controls.offerMessageForProspectCustomer.showErrorMsg && <div className="error show_error">
                *Please enter offer to the referee.
              </div>}
              {!controls.offerMessageForProspectCustomer.showErrorMsg &&
                this.state.smsConfig.isNotValidMinCount && (
                  <div className="error text-none">
                    *Maximum {this.state.maxCount} characters are allowed.
                  </div>
                )}
              <div
                className="d-flex-row justify-content-between mt-2">
                <span className="Message-Credits-Used">
                  Message Credits Used:
                  {this.state.smsConfig.smsContentCredit}
                  <img
                    src="images/sidebar-icons/about-selected.svg"
                    alt=''
                    id="editorimage"
                    className="ml-1 cursor-pointer"
                    onClick={() => this.openmsg_detail(true)}
                  />
                </span>
                <div
                  className="d-flex-row ml-auto"
                  style={{ justifyContent: "flex-end" }}
                >
                  <span className="noofwordss">
                    {this.state.smsConfig.length}/{this.state.maxCount}
                  </span>
                </div>
              </div>
              {this.state.msg_detail ? (
                <OutsideClickHandler
                  onOutsideClick={() => this.openmsg_detail(false)}
                >
                  <Row>
                    <Col lg="10" sm="12" md="10" xs="12">

                      <div className="entermsgcontent">
                        <div className="d-flex-row justify-content-end">
                          <span
                            className="closebtn ml-auto"
                            onClick={() => this.openmsg_detail(false)}
                          >
                            X
                          </span>
                        </div>
                        <span className="One-uni-code-charact">
                          <b className="Message-Details">
                            Message Details
                          </b>
                          <br />
                          <ul
                            className="Enrollment-ul text-none"
                            style={{ marginBottom: "0" }}
                          >
                            <li className="enroll-li campaign-popup-notes">
                              A message with upto{" "}
                              {controls.language.value === "english"
                                ? 160
                                : 70}{" "}
                              characters (including space and
                              special characters) uses 1 SMS
                              credit(balance).
                            </li>
                            <li className="enroll-li campaign-popup-notes">
                              If the message is more than{" "}
                              {controls.language.value === "english"
                                ? 160
                                : 70}{" "}
                              characters, 2 SMS credits are used for
                              that message. Meaning the account
                              balance is debited by 2 SMS.
                            </li>
                            <li className="enroll-li campaign-popup-notes">
                              Maximum eligible limit per message is{" "}
                              {this.state.maxCount} characters.
                            </li>
                            <li className="enroll-li campaign-popup-notes">
                              Only English language messages are
                              eligible as of now. JKTYRE does not
                              support unicode messages (Indian
                              language messages).
                            </li>
                          </ul>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </OutsideClickHandler>
              ) : (
                ""
              )}

              <div
                className="d-flex-row mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <div className="tag-wrapper">
                  {Tags.map((item, i) => (
                    <span
                      className="Rectangletag cursor-pointer"
                      style={{ marginLeft: i === 0 ? 0 : '10px' }}
                      onClick={() => this.addTags(item, 'offerMessageForProspectCustomer')}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            </Col>
            <Col xl="12" className="mt-3 mb-3">
              <label className="custom-checkboax1--container">
                <input
                  type="checkbox"
                  name="service"
                  checked={controls.is_same_as_existing.value}
                  onChange={(e) =>
                    this.handleChangeIsExisting(e)}
                />
                <span className="checkmark1" />
              </label>
              <span style={{ marginLeft: "10px" }} className="same-as-existing-customer">
                Same as existing Customer offer
              </span>
            </Col>
          </Row>
          <br />
        </div>
        <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
            <Button
              //  disabled={!isValidForm} 
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: 'pointer' }}
              onClick={() => this.setDealerReferalReward()}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>

    )
  }
}
