import React, { Component } from 'react';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import { Row, Col } from 'reactstrap';
import CampaignSecondaryUserFilter from "../CampaignSecondaryUserFilter/CampaignSecondaryUserFilter";
import CIPCampaignFilter from "../CIPCampaignFilter/CIPCampaignFilter";
import PromotionalEventsUsers from "../PromotionalEventsUsers/PromotionalEventsUsers";

class CampaignFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      upcomingDueOrOverDueSwitch: 'warranty_users'
    }
    if (props) {
      this.state.upcomingDueOrOverDueSwitch = props.filterType === 'warranty_users' ? 'warranty_users' :
        props.filterType === 'promotional' ? 'promotional' : props.filterType === 'cip_user' ?
          'cip_user' : 'warranty_users';
    }
  }
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }
  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  closeSideBar = () => {
    this.props.closeSideBar(false);
  }

  closeSideBarPromotional = () => {
    this.props.closeSideBarPromotional(false);
  }

  closeSideBarCIP = () => {
    this.props.closeSideBarCIP(false);
  }

  handleUpcomingDueOrOverdueSwitchChange = (switchValue) => {
    const { controls } = this.state;
    this.setState({ upcomingDueOrOverDueSwitch: switchValue, controls })
  }

  applyFilterjkSecondaryUserFilter = (filterData) => {
    this.props.applyFilter(filterData);
    this.props.closeSideBar(false);
  }

  applyFilterCIPUserFilter = (filterData) => {
    this.props.applyFilterCIP(filterData);
    this.props.closeSideBarCIP(false);
  }

  applyFilterjkPromotionalEventsUsersData = (filterData) => {
    this.props.applyFilterPromotional(filterData);
    this.props.closeSideBarPromotional(false);
  }

  render() {
    let { upcomingDueOrOverDueSwitch } = this.state;

    return (
      <div class="custom-drawer" id="AddEditActivity">
        {this.state.isLoading && <CustomSpinner />}
        <div style={{ marginTop: '60px' }}>
          <div className="drawer-header">
            <span className="drawer-title">JK Secondary User</span>
            <img className="cursor-pointer" src="images/close.svg" alt='cancel'
              onClick={() => this.state.upcomingDueOrOverDueSwitch === 'warranty_users' ? this.closeSideBar(false) :
                this.state.upcomingDueOrOverDueSwitch === 'promotional' ? this.closeSideBarPromotional(false) :
                  this.state.upcomingDueOrOverDueSwitch === 'cip_user' ? this.closeSideBarCIP(false) : this.closeSideBar(false)} />
          </div>
          <hr style={{ border: 'solid 1px #ced4da' }} />
          <Col xl="12" className="mb-2">
            <span className="filter-type-lbl ml-0">Select Target Group</span>
          </Col>
          <Row className="c-top-15" style={{ marginLeft: '0px', marginRight: '0px' }}>
            <Col>
              <div className="switch-container">
                <div
                  className={`switch-data ${upcomingDueOrOverDueSwitch === 'warranty_users' ? 'active-switch' : ''}`}
                  onClick={this.handleUpcomingDueOrOverdueSwitchChange.bind(this, 'warranty_users')}
                >Warranty Users</div>
                <div
                  className={`switch-data ${upcomingDueOrOverDueSwitch === 'cip_user' ? 'active-switch' : ''}`}
                  onClick={this.handleUpcomingDueOrOverdueSwitchChange.bind(this, 'cip_user')}
                >CIP User</div>
                <div
                  className={`switch-data ${upcomingDueOrOverDueSwitch === 'promotional' ? 'active-switch' : ''}`}
                  onClick={this.handleUpcomingDueOrOverdueSwitchChange.bind(this, 'promotional')}
                >Promotional and event user</div>
              </div>
            </Col>
          </Row>
        </div>

        <div class="custom-drawer-content" onClick={this.handleClickOutside}>
          {upcomingDueOrOverDueSwitch === 'warranty_users' &&
            (<CampaignSecondaryUserFilter
              jkSecondaryUserFilterData={
                this.props.jkSecondaryUserFilterData
                  ? this.props.jkSecondaryUserFilterData
                  : {}
              }
              key={'childCampaignSecondaryUserFilter'}
              applyFilter={this.applyFilterjkSecondaryUserFilter}
              ref={this.childCampaignSecondaryUserFilter}
            />)}

          {upcomingDueOrOverDueSwitch === 'cip_user' &&
            (<CIPCampaignFilter
              applyFilterCIPUserFilter={
                this.props.applyFilterCIPUserFilter
                  ? this.props.applyFilterCIPUserFilter
                  : {}
              }
              key={'CIPUserFilter'}
              applyFilter={this.applyFilterCIPUserFilter}
              ref={this.CIPUserFilter}
            />)}

          {upcomingDueOrOverDueSwitch === 'promotional' &&
            (<PromotionalEventsUsers
              jkPromotionalEventsUsersData={
                this.props.jkPromotionalEventsUsersData
                  ? this.props.jkPromotionalEventsUsersData
                  : {}
              }
              key={'childPromotionalEventsUsers'}
              applyFilterPromotional={this.applyFilterjkPromotionalEventsUsersData}
              ref={this.childPromotionalEventsUsers}
            />)}
        </div>

      </div>
    );
  }
}

export default CampaignFilter;