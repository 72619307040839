import Select, { components } from "react-select";
import lodash from "lodash";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Row,
  Col,
  Button,
  FormGroup
} from "reactstrap";
import PropTypes from "prop-types"; // or PropTypes = require('prop-types');

import CustomSpinner from "../CustomSpinner/CustomSpinner";

import ModalService from "../../services/ModalService";
import UserService from "../../services/UserService";
import CampaignService from "../../services/CampaignService";

import { messages } from "../../constants/constant.message";

import './CampaignHoUserFilter.css';
import PosterService from "../../services/PosterService";

const { ERROR_MESSAGE } = messages.COMMON;
class CampaignHoUserFilter extends Component {
  static propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userTargetRadioOptions: [
        {
          key: 'Target Users',
          value: 'target_users'
        },
        {
          key: 'Upload Excel',
          value: 'upload_excel'
        }
      ],
      controls: {
        target: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        dealer: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        employee: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },

        zone_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        region_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        area_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        user_type_radio: {
          value: "target_users",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        selected_file: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        }
      },
      targetData: [],
      dealerData: [],
      areaData: [],
      fleetTypeData: [
        { label: "All", value: "all", clearableValue: false },
        { label: "Platinum", value: "Platinum", clearableValue: false },
        { label: "Gold", value: "Gold" },
        { label: "Silver", value: "Silver" }
      ],
      employeeData: [],
      regionData: [],
      zoneData: [],
      uniqueKey: "value",
      selected: [],
      isDisplayDealer: false,
      isDisplayEmployee: false,
      isDisplayFleets: false,
      counts: 0,
      jkUserFilterData: {},
      isSetDataFirstTime: true,
      serachValueArea: "",
      serachValueRegion: "",
      isUploadExcelOpen: false
    };
    if (
      props.jkUserFilterData &&
      Object.keys(props.jkUserFilterData).length > 0
    ) {
      if (props.jkUserFilterData.user_target === 'upload_excel') {
        let { controls, isUploadExcelOpen } = this.state;
        const { user_type_radio, selected_file } = controls;
        user_type_radio.value = 'upload_excel';
        selected_file.value = props.jkUserFilterData.file;
        isUploadExcelOpen = true;
        this.state.isUploadExcelOpen = true;
        this.state.controls = controls;
        // this.setState({ controls });
        return;
      }
      if (props.from === 'create-poster') {
        this.state.isDisplayEmployee = false;
        this.state.isDisplayDealer = true;
        // let obj = { target: { name: 'dealer', value: 'Dealer' } };
        this.getTargets();
        // this.handleInputChange(obj);
      }
      this.state.jkUserFilterData = props.jkUserFilterData;
      if (props.jkUserFilterData.counts) {
        this.state.counts = props.jkUserFilterData.counts;
      }

      //Set Props FLeet Data
      if (
        this.state.jkUserFilterData &&
        Object.keys(this.state.jkUserFilterData).length > 0
      ) {
        if (this.state.jkUserFilterData.filter_fleet_member_type.length === 0) {
          this.state.fleetTypeData[0].checked = true;
        } else {
          if (this.state.jkUserFilterData.filter_fleet_member_type) {
            this.state.jkUserFilterData.filter_fleet_member_type.forEach(
              filter_fleet_member_type => {
                this.state.fleetTypeData.forEach(element => {
                  if (element.value === filter_fleet_member_type) {
                    element.isChecked = true;
                  }
                });
              }
            );
          }
        }
      }
    }
  }

  componentDidMount() {
    this.getZone();
    this.getTargets();
    if (Object.keys(this.props.jkUserFilterData).length <= 0) {
      this.getRegion([]);
    }
  }

  handleClickOutside = event => {
    if (this.refRegion) {
      const domNodeRegion = ReactDOM.findDOMNode(this.refRegion);
      if (!domNodeRegion || !domNodeRegion.contains(event.target)) {
        if (this.refRegion.state.menuIsOpen) {
          this.refRegion.setState({
            menuIsOpen: false
          });
        }
      }
    }

    if (this.refArea) {
      const domNodeArea = ReactDOM.findDOMNode(this.refArea);
      if (!domNodeArea || !domNodeArea.contains(event.target)) {
        if (this.refArea.state.menuIsOpen) {
          this.refArea.setState({
            menuIsOpen: false
          });
        }
      }
    }
  };
  closeDropDowns = () => { };
  componentWillMount() {
    document.body.style.overflow = "auto";
  }

  customFilter = (option, searchText) => {
    if (
      option.data.region_name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  customFilterArea = (option, searchText) => {
    if (
      option.data.area_name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.regionData.length ===
            this.state.controls.region_id.value.length
            ? nbValues - 1
            : nbValues
            } selected`}</span>
          {props.selectProps.menuIsOpen && (
            <input
              type="text"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#fff",
                width: "100%"
              }}
              name="serachValueRegion"
              value={this.state.serachValueRegion}
              onChange={e => this.onChangeFilter(e)}
              placeholder="Serach..."
            />
          )}
        </components.ValueContainer>
      </>
    );
  };

  ValueContainerArea = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.areaData.length ===
            this.state.controls.area_id.value.length
            ? nbValues - 1
            : nbValues
            } selected`}</span>
          {props.selectProps.menuIsOpen && (
            <input
              type="text"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#fff",
                width: "100%"
              }}
              name="serachValueArea"
              value={this.state.serachValueArea}
              onChange={e => this.onChangeFilter(e)}
              placeholder="Serach..."
            />
          )}
        </components.ValueContainer>
      </>
    );
  };

  onChangeFilter = e => {
    let controlName = e.target.name;
    this.setState({
      [controlName]: e.target.value
    });
  };

  handleInputChange = (e, index = -1) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let {
      controls,
      targetData,
      employeeData,
      isDisplayEmployee,
      isDisplayDealer,
      isDisplayFleets,
      zoneData,
      dealerData,
      regionData,
      fleetTypeData,
      areaData,
      isSetDataFirstTime
    } = this.state;
    isSetDataFirstTime = false;
    if (controlName === "target") {
      targetData[index].isChecked = !targetData[index].isChecked;
      if (index === 0) {
        targetData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      } else if (index > 0) {
        targetData[0].isChecked = false;
      }
      let count = lodash.filter(targetData, function (o) {
        if (o.isChecked) return o;
      }).length;
      if (controlValue === "Employee") {
        if (targetData[index].isChecked) {
          this.getTargetEmpolyees();
        }
      } else if (controlValue === "Dealer") {
        if (targetData[index].isChecked) {
          this.getDealerClassication();
        }
      }
      if (count === 1) {
        let delaers = lodash.filter(targetData, element => {
          return element.role_name === "Dealer";
        });
        let employees = lodash.filter(targetData, element => {
          return element.role_name === "Employee";
        });
        let fleets = lodash.filter(targetData, element => {
          return element.role_name === "Fleet";
        });
        isDisplayDealer = delaers[0].isChecked ? delaers[0].isChecked : false;
        isDisplayEmployee = employees[0].isChecked && count === 1;
        isDisplayFleets = fleets[0].isChecked && count === 1;
        if (fleetTypeData.length > 0 && isDisplayFleets) {
          fleetTypeData.forEach(element => {
            element.isChecked = false;
          });
        }
      } else {
        let employees = lodash.filter(targetData, element => {
          return element.role_name === "Employee";
        });
        if (!employees[0].isChecked) {
          isDisplayEmployee = false;
        } else {
          isDisplayEmployee = true;
        }
        isDisplayDealer = false;
        isDisplayFleets = false;
      }
    } else if (controlName === "dealer") {
      dealerData[index].isChecked = !dealerData[index].isChecked;
      if (index === 0) {
        dealerData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      } else if (index > 0) {
        dealerData[0].isChecked = false;
      }
    } else if (controlName === "employee") {
      employeeData[index].isChecked = !employeeData[index].isChecked;
      if (index === 0) {
        employeeData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      } else if (index > 0) {
        employeeData[0].isChecked = false;
      }
    } else if (controlName === "region_id") {
      this.refArea.onMenuClose();
      controls[controlName].value = controlValue;
      controls.area_id.value = [];
      if (controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(
          x => x.region_name === "All"
        );
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = regionData;
          } else if (controlValue.length === regionData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
          this.refRegion.onMenuClose();
        } else if (index_all > 0) {
          controls[controlName].value = regionData;
        } else if (controlValue.length === regionData.length - 1) {
          controls[controlName].value = [];
        }
      }

      let zone_all = lodash.filter(zoneData, element => {
        return element.zone_name === "All";
      });
      let zone_id = [];
      if (!zone_all[0].isChecked) {
        zoneData.forEach(element => {
          if (element.isChecked) {
            zone_id.push(element.zone_id);
          }
        });
      }

      let index_all = controls[controlName].value.findIndex(
        x => x.region_name === "All"
      );
      if (index_all > -1) {
        this.getArea({ region_ids: [], zone_id: zone_id });
      } else {
        let region_id = [];
        controls[controlName].value.forEach(element => {
          region_id.push(element.region_id);
        });
        this.getArea({ region_ids: region_id, zone_id: zone_id });
      }
    } else if (controlName === "area_id") {
      this.refRegion.onMenuClose();
      controls[controlName].value = controlValue;
      if (controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(
          x => x.area_name === "All"
        );
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = areaData;
            this.refArea.onMenuClose();
          } else if (controlValue.length === areaData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = areaData;
          this.refArea.onMenuClose();
        } else if (controlValue.length === areaData.length - 1) {
          controls[controlName].value = [];
          this.refArea.onMenuClose();
        }
      }
    } else if (controlName === "zone_id") {
      zoneData[index].isChecked = !zoneData[index].isChecked;
      controls.area_id.value = [];
      controls.region_id.value = [];
      regionData = [];
      areaData = [];
      if (index === 0) {
        zoneData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      } else if (index > 0) {
        zoneData[0].isChecked = false;
      }
      let zone_all = lodash.filter(zoneData, element => {
        return element.zone_name === "All";
      });
      let zone_id = [];
      if (!zone_all[0].isChecked) {
        zoneData.forEach(element => {
          if (element.isChecked) {
            zone_id.push(element.zone_id);
          }
        });
      }
      controls[controlName].touched = true;
      this.getRegion(zone_id);
    } else if (controlName === "fleets") {
      controls.region_id.value = [];
      controls.area_id.value = [];
      fleetTypeData[index].isChecked = !fleetTypeData[index].isChecked;
      if (index === 0) {
        fleetTypeData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      } else if (index > 0) {
        fleetTypeData[0].isChecked = false;
      }
    } else if (controlName === 'selected_file') {
      controls[controlName].value = controlValue;
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    this.setState({
      controls,
      targetData,
      employeeData,
      isDisplayEmployee,
      regionData,
      areaData,
      isDisplayDealer,
      isDisplayFleets,
      isSetDataFirstTime
    });
  };

  handleValidation = () => {
    const { controls } = this.state;
    const { selected_file } = controls;
    if (!selected_file.value) {
      selected_file.valid = false;
      selected_file.showErrorMsg = true;
    } else {
      selected_file.valid = true;
      selected_file.showErrorMsg = false;
    }
    this.setState({ controls });
    return selected_file.valid;
  }
  getTargetEmpolyees = () => {
    const obj = {};
    this.setState({
      isLoading: true
    });
    CampaignService.getTargetedEmployee(obj)
      .then(data => {
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            role_id: -1,
            role_name: "All"
          });
          //Set Props Data
          if (
            this.state.isSetDataFirstTime &&
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (this.state.jkUserFilterData.filter_employee_ids.length === 0) {
              data.data.data[0].checked = true;
            } else {
              if (this.state.jkUserFilterData.filter_employee_ids) {
                this.state.jkUserFilterData.filter_employee_ids.forEach(
                  filter_employee_ids => {
                    data.data.data.forEach(element => {
                      if (element.role_id === filter_employee_ids) {
                        element.isChecked = true;
                      }
                    });
                  }
                );
              }
            }
          }
        }
        this.setState({
          employeeData: data.data.data,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  getDealerClassication = () => {
    this.setState({
      isLoading: true
    });
    CampaignService.getDealerClassication()
      .then(data => {
        if (data.data.data.customer_class) {
          data.data.data.customer_class.splice(0, 0, {
            customer_class: "All",
            customer_class_code: "all"
          });
          //Set Props Data
          if (this.state.isSetDataFirstTime) {
            if (
              this.state.jkUserFilterData &&
              Object.keys(this.state.jkUserFilterData).length > 0
            ) {
              if (
                this.state.jkUserFilterData.filter_dealer_classification
                  .length === 0
              ) {
                data.data.data.customer_class[0].checked = true;
              } else {
                if (this.state.jkUserFilterData.filter_dealer_classification) {
                  this.state.jkUserFilterData.filter_dealer_classification.forEach(
                    filter_dealer_classification => {
                      data.data.data.customer_class.forEach(element => {
                        if (
                          element.customer_class_code ===
                          filter_dealer_classification
                        ) {
                          element.isChecked = true;
                        }
                      });
                    }
                  );
                }
              }
            }
          } else {
            data.data.data.customer_class[0].checked = true;
          }
        }
        this.setState({
          dealerData: data.data.data.customer_class,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  getZone = () => {
    CampaignService.getZone()
      .then(data => {
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            zone_id: [],
            zone_name: "All",
            zone_code: "all"
          });
        }

        //Set Props Data
        if (this.state.isSetDataFirstTime) {
          if (
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (this.state.jkUserFilterData.filter_zone_ids.length > 0) {
              let zone_id = [];
              this.state.jkUserFilterData.filter_zone_ids.forEach(
                filter_zone_ids => {
                  data.data.data.forEach(element => {
                    if (element.zone_id === filter_zone_ids) {
                      element.isChecked = true;
                      zone_id.push(element.zone_id);
                    }
                  });
                }
              );
              this.getRegion(zone_id);
            } else if (
              this.state.jkUserFilterData.filter_zone_ids.length === 0
            ) {
              data.data.data[0].isChecked = true;
              this.getRegion([]);
            }
          } else {
            data.data.data[0].isChecked = true;
          }
        } else {
          data.data.data[0].isChecked = true;
        }

        this.setState({
          zoneData: data.data.data
        });
      })
      .catch(err => {
        return err;
      });
  };
  getArea = data => {
    let reqData = {
      region_id: [],
      zone_id: []
    };
    if (data && Object.keys(data).length > 0) {
      reqData = {
        region_id: data.region_ids,
        zone_id: data.zone_id
      };
    }
    this.setState({
      isLoading: true
    });
    CampaignService.getArea(reqData)
      .then(data => {
        let { controls } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            region_id: "",
            area_id: [],
            area_name: "All"
          });

          if (
            this.state.isSetDataFirstTime &&
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (
              this.state.jkUserFilterData.filter_area_ids &&
              this.state.jkUserFilterData.filter_area_ids.length > 0
            ) {
              this.state.jkUserFilterData.filter_area_ids.forEach(
                filter_area_ids => {
                  data.data.data.forEach(element => {
                    if (element.area_id === filter_area_ids) {
                      controls.area_id.value.push(element);
                    }
                  });
                }
              );
            }
          } else {
            data.data.data[0].isChecked = true;
          }
        }
        this.setState({
          areaData: data.data.data,
          controls,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  getRegion = zone_ids => {
    let reqData = {
      zone_id: zone_ids
    };
    CampaignService.getRegion(reqData)
      .then(data => {
        let { controls, jkUserFilterData } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            zone_id: "all",
            region_id: [],
            region_name: "All"
          });
          if (jkUserFilterData && Object.keys(jkUserFilterData).length > 0) {
            if (
              jkUserFilterData.filter_region_ids &&
              jkUserFilterData.filter_region_ids.length > 0
            ) {
              let region_id = [];
              jkUserFilterData.filter_region_ids.forEach(filter_region_ids => {
                data.data.data.forEach(element => {
                  if (element.region_id === filter_region_ids) {
                    controls.region_id.value.push(element);
                    region_id.push(element.region_id);
                  }
                });
              });
              let zone_all = lodash.filter(this.state.zoneData, element => {
                return element.zone_name === "All";
              });
              let zone_id = [];
              if (!zone_all[0].isChecked) {
                this.state.zoneData.forEach(element => {
                  if (element.isChecked) {
                    zone_id.push(element.zone_id);
                  }
                });
              }
              this.getArea({ region_ids: region_id, zone_id: zone_id });
            }
          }
          this.setState({
            regionData: data.data.data,
            controls
          });
        }
      })
      .catch(err => {
        return err;
      });
  };
  getTargets = () => {
    this.setState({
      isLoading: true
    });
    CampaignService.getTargets()
      .then(data => {
        let {
          isDisplayDealer,
          isDisplayEmployee,
          isDisplayFleets
        } = this.state;
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            role_id: -1,
            role_name: "All"
          });
        }

        if (this.props.from === 'create-poster') {
          data.data.data.forEach(element => {
            if (element.role_id === 2) {
              element.isChecked = true;
              if (element.role_name === "Dealer") {
                isDisplayDealer = true;
                this.getDealerClassication();
              }
            }
          });
          // let index = data.data.data.map(value => { return value.role_name }).indexOf('Dealer')
          // let dealerTargetId = data.data.data[index].role_id;
          this.state.filter_target_ids = [2];
        }

        //Set Props Data
        if (
          this.state.isSetDataFirstTime &&
          this.state.jkUserFilterData &&
          Object.keys(this.state.jkUserFilterData).length > 0
        ) {
          if (this.state.jkUserFilterData.filter_target_ids.length === 0) {
            data.data.data[0].isChecked = true;
          } else {
            if (this.state.jkUserFilterData.filter_target_ids) {
              this.state.jkUserFilterData.filter_target_ids.forEach(
                filter_target_ids => {
                  data.data.data.forEach(element => {
                    if (element.role_id === filter_target_ids) {
                      element.isChecked = true;
                      if (element.role_name === "Dealer") {
                        isDisplayDealer = true;
                        this.getDealerClassication();
                      } else if (element.role_name === "Employee") {
                        isDisplayEmployee = true;
                        this.getTargetEmpolyees();
                      } else if (element.role_name === "Fleet") {
                        isDisplayFleets = true;
                      }
                    }
                  });
                }
              );

              let count = lodash.filter(data.data.data, function (o) {
                if (o.isChecked) return o;
              }).length;
              if (count === 1) {
                let delaers = lodash.filter(data.data.data, element => {
                  return element.role_name === "Dealer";
                });
                let employees = lodash.filter(data.data.data, element => {
                  return element.role_name === "Employee";
                });
                let fleets = lodash.filter(data.data.data, element => {
                  return element.role_name === "Fleet";
                });
                isDisplayDealer = delaers[0].isChecked
                  ? delaers[0].isChecked
                  : false;
                isDisplayEmployee = employees[0].isChecked && count === 1;
                isDisplayFleets = fleets[0].isChecked && count === 1;
              } else {
                let employees = lodash.filter(data.data.data, element => {
                  return element.role_name === "Employee";
                });
                if (!employees[0].isChecked) {
                  isDisplayEmployee = false;
                } else {
                  isDisplayEmployee = true;
                }
                isDisplayDealer = false;
                // isDisplayEmployee = false;
                isDisplayFleets = false;
              }
            }
          }
        } else {
          data.data.data[0].isChecked = true;
        }

        this.setState({
          targetData: data.data.data,
          isDisplayDealer,
          isDisplayEmployee,
          isDisplayFleets,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  getReqData = () => {
    const { from } = this.props;
    let {
      controls,
      targetData,
      employeeData,
      // isDisplayEmployee,
      // isDisplayDealer,
      isDisplayFleets,
      zoneData,
      dealerData,
      // regionData,
      // areaData,
      fleetTypeData
    } = this.state;
    let reqData = {
      customer_target_type: "jk_user",
      filter_target_ids: [],
      filter_zone_ids: [],
      filter_region_ids: [],
      filter_area_ids: [],
      filter_employee_ids: [],
      filter_dealer_classification: [],
      filter_fleet_member_type: [],
      communication_type: this.props.communication_type,
      notification_title: ""
    };

    let all = "";
    //targetData
    if (targetData.length > 0) {
      all = lodash.filter(targetData, element => {
        return element.role_name === "All";
      });
      if (all && !all[0].isChecked) {
        targetData.forEach(element => {
          if (element.isChecked) {
            reqData.filter_target_ids.push(element.role_id);
          }
        });
      } else {
        reqData.filter_target_ids = [];
      }
    }

    //employeeData
    if (employeeData.length > 0 && this.state.isDisplayEmployee) {
      all = lodash.filter(employeeData, element => {
        return element.role_name === "All";
      });
      if (all && !all[0].isChecked) {
        employeeData.forEach(element => {
          if (element.isChecked) {
            reqData.filter_employee_ids.push(element.role_id);
          }
        });
      } else {
        reqData.filter_employee_ids = [];
      }
    }

    //dealerData
    if (dealerData.length > 0 && this.state.isDisplayDealer) {
      all = lodash.filter(dealerData, element => {
        return element.customer_class === "All";
      });
      if (all && !all[0].isChecked) {
        dealerData.forEach(element => {
          if (element.isChecked) {
            reqData.filter_dealer_classification.push(
              element.customer_class_code
            );
          }
        });
      } else {
        reqData.filter_dealer_classification = [];
      }
    }

    // Zone Data
    if (zoneData.length > 0) {
      all = lodash.filter(zoneData, element => {
        return element.zone_name === "All";
      });
      if (all && !all[0].isChecked) {
        zoneData.forEach(element => {
          if (element.isChecked) {
            reqData.filter_zone_ids.push(element.zone_id);
          }
        });
      }
    }

    //Region Data
    if (controls.region_id.value.length > 0) {
      all = controls.region_id.value.findIndex(x => x.region_name === "All");
      if (all <= -1) {
        controls.region_id.value.forEach(element => {
          reqData.filter_region_ids.push(element.region_id);
        });
      }
    }

    if (isDisplayFleets) {
      // Zone Data
      if (fleetTypeData.length > 0) {
        all = lodash.filter(fleetTypeData, element => {
          return element.label === "All";
        });
        if (all && !all[0].isChecked) {
          fleetTypeData.forEach(element => {
            if (element.isChecked) {
              reqData.filter_fleet_member_type.push(element.value);
            }
          });
        }
      }
    }

    // Area Data
    if (controls.area_id.value.length > 0) {
      all = controls.area_id.value.findIndex(x => x.area_name === "All");
      if (all <= -1) {
        controls.area_id.value.forEach(element => {
          reqData.filter_area_ids.push(element.area_id);
        });
      }
    }

    if (controls.user_type_radio.value === 'upload_excel') {
      if (controls.selected_file.value) {
        reqData = {
          file: controls.selected_file.value,
          user_target: controls.user_type_radio.value
        }
      }
    }
    if (reqData && from === 'create-banner') {
      let {
        filter_area_ids, filter_dealer_classification, filter_employee_ids,
        filter_fleet_member_type, filter_region_ids, filter_target_ids, filter_zone_ids
      } = reqData;
      if (filter_area_ids && Array.isArray(filter_area_ids) && filter_area_ids.length > 0) {
        reqData['area'] = filter_area_ids;
      }
      if (filter_region_ids && Array.isArray(filter_region_ids) && filter_region_ids.length > 0) {
        reqData['region'] = filter_region_ids;
      }
      if (filter_zone_ids && Array.isArray(filter_zone_ids) && filter_zone_ids.length > 0) {
        reqData['zone'] = filter_zone_ids;
      }
      if (filter_fleet_member_type && Array.isArray(filter_fleet_member_type) && filter_fleet_member_type.length > 0) {
        reqData['fleet_type'] = filter_fleet_member_type;
      }
      if (filter_dealer_classification && Array.isArray(filter_dealer_classification) && filter_dealer_classification.length > 0) {
        reqData['dealer_type'] = filter_dealer_classification;
      }
      if (filter_target_ids && Array.isArray(filter_target_ids) && filter_target_ids.length > 0) {
        reqData['user_type'] = filter_target_ids;
      }
      if (filter_employee_ids && Array.isArray(filter_employee_ids) && filter_employee_ids.length > 0) {
        reqData['employee_type'] = filter_employee_ids;
      }
      // reqData=obj;
    }

    if (reqData && from === 'create-poster') {
      let {
        filter_area_ids, filter_dealer_classification, filter_region_ids, filter_zone_ids
      } = reqData;
      if (filter_area_ids && Array.isArray(filter_area_ids) && filter_area_ids.length > 0) {
        reqData['area'] = filter_area_ids;
      }
      if (filter_region_ids && Array.isArray(filter_region_ids) && filter_region_ids.length > 0) {
        reqData['region'] = filter_region_ids;
      }
      if (filter_zone_ids && Array.isArray(filter_zone_ids) && filter_zone_ids.length > 0) {
        reqData['zone'] = filter_zone_ids;
      }
      if (filter_dealer_classification && Array.isArray(filter_dealer_classification) && filter_dealer_classification.length > 0) {
        reqData['classification'] = filter_dealer_classification;
      }
      reqData['filter_target_ids'] = [2];
      // reqData=obj;
    }
    return reqData;
  };

  onBlurSelect = e => {
    //   this.refRegion.select.setState({ isOpen: false });
  };

  getCustomerCount = () => {
    const { from } = this.props;
    let reqData = this.getReqData();
    this.setState({ isLoading: true });
    let functionServiceToCall = null;
    if (from === 'create-banner') {
      functionServiceToCall = UserService.getUserCount(reqData);
    } else if (from === 'create-poster') {
      functionServiceToCall = PosterService.getUserCount(reqData);
    } else {
      functionServiceToCall = CampaignService.getCustomerCount(reqData);
    }

    // CampaignService.getCustomerCount(reqData)
    functionServiceToCall
      .then(data => {
        this.setState({
          counts: data.data.data.count,
          isLoading: false
        });
        if (data.data.data.count === 0) {
          ModalService.openAlert(
            "",
            "There is no user found with applied filter . Please change filter criteria",
            "error"
          );
          return;
        }
        let filterData = {
          counts: this.state.counts
        };
        filterData = { ...reqData, ...filterData };
        this.props.applyFilter(filterData);
        this.props.closeSideBar(false);
      })
      .catch(e => {
        console.error("e", e);
        this.setState({ isLoading: false });

        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  applyFilter = () => {
    const { controls } = this.state;
    const { user_type_radio } = controls;
    if (user_type_radio.value === 'upload_excel') {
      let isFormValid = this.handleValidation();
      if (!isFormValid) {
        return;
      }
      let reqData = this.getReqData();
      this.props.applyFilter(reqData);
      this.props.closeSideBar(false);
    } else {
      this.getCustomerCount();
    }
  };
  resetFilter = flag => {
    if (flag) {
      this.props.applyFilter({});
      this.props.closeSideBar(false);
    }
  };

  closeSideBar = () => {
    this.props.closeSideBar(false);
  };

  handleBlurSelect(inputValue, action) {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({ inputValue });
    }
  }

  handleRadioChange = (controlName, e) => {
    let { controls } = this.state;
    let radioControl = controls[controlName]
    radioControl.value = e.target.value;
    this.setState({ controls });
  };

  openUploadExcel = () => {
    this.setState({ isUploadExcelOpen: true })
  }

  resetUploadExcel = () => {
    const { controls } = this.state;
    const { selected_file } = controls;

    selected_file.value = null;

    this.setState({ controls });
  }

  render() {
    let { controls, userTargetRadioOptions, isUploadExcelOpen } = this.state;
    const { user_type_radio, selected_file } = controls;
    const CustomOption = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div
            className="d-flex custom-option"
            style={{ padding: "5px" }}
            ref={innerRef}
            {...innerProps}
          >
            <label ref={innerRef} {...innerProps} className="container-checkbox">
              <input
                type="checkbox"
                name="cc_self"
                checked={props.isSelected === true}
                id="checkbox"
              />
              <span className="checkmark" />
            </label>
            <span
              ref={innerRef}
              {...innerProps}
              className="ml-2"
              style={{ marginTop: "-2px" }}
            >
              {props.data.region_name
                ? props.data.region_name
                : props.data.area_name
                  ? props.data.area_name
                  : ""}
            </span>
          </div>
        </>
      );
    };

    const customStyles = {
      control: base => ({
        ...base,
        maxHeight: 70,
        overflow: "auto",
        theme: "primary",
        borderColor: "#fee19d",
        outline: "0",
        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
      }),
      input: styles => "text"
    };
    const { onFocus, onBlur, from, ...props } = this.props;
    return (
      <div class="custom-drawer" id="custom-ho-user-filter">
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        <div style={{ marginTop: "60px" }}>
          <div className="drawer-header">
            <span className="drawer-title">{from === 'create-banner' ? 'Target User' : from === 'create-poster' ? 'SELECT DEALERS' : 'JK User'}</span>
            <img
              className="cursor-pointer"
              src="images/close.svg"
              alt="cancel"
              onClick={() => this.closeSideBar(false)}
            />
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
        </div>
        <div
          class="custom-drawer-content"
          style={{ overflow: "unset" }}
          ref={r => (this.customDrawerContent = r)}
          onClick={this.handleClickOutside}
        >
          {from === 'create-banner' && <Row
            style={{ marginLeft: "0px", marginRight: "0px", marginBottom: '15px' }}
          >
            <Col>
              <div>Select Users</div>
              {userTargetRadioOptions.map((u, index) => {
                return <>
                  <label className="container-rb">
                    {u.key}
                    <input
                      type="radio"
                      name="user_type_radio"
                      value={u.value}
                      onChange={this.handleRadioChange.bind(this, 'user_type_radio')}
                      checked={user_type_radio.value === u.value}
                    />
                    <span className="checkmark-rb"></span>
                  </label>&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              })
              }
            </Col>
          </Row>}
          {(user_type_radio.value === 'target_users') && <Row
            className="c-top-15"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            {this.state.targetData.length > 0 && from !== 'create-poster' && (
              <>
                <Col xl="12" className="mb-2">
                  {/* <Label for="Target">Select Target</Label> */}
                  <span className="filter-type-lbl ml-0">Select Target</span>
                </Col>
                <Col xl="12">
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    {(this.state.targetData || []).map((target, i) => (
                      <div className="d-flex" style={{ paddingRight: "20px" }}>
                        <label className="container-checkbox">
                          <input
                            type="checkbox"
                            name="target"
                            checked={target.isChecked}
                            id="checkbox"
                            value={target.role_name}
                            onChange={e => this.handleInputChange(e, i)}
                          />
                          &nbsp;{target.role_name}
                          <span className="checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </>
            )}
            {this.state.isDisplayEmployee && (
              <>
                <Col xl="12" className="mb-2">
                  <span className="filter-type-lbl ml-0">Select Employee</span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>
                <Col xl="12">
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    {(this.state.employeeData || []).map((employee, index) => (
                      <div className="d-flex" style={{ paddingRight: "20px" }}>
                        <label className="container-checkbox">
                          <input
                            type="checkbox"
                            name="employee"
                            checked={employee.isChecked}
                            id="checkbox"
                            value={employee.value}
                            onChange={e => this.handleInputChange(e, index)}
                          />
                          &nbsp;{employee.role_name}
                          <span className="checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </>
            )}
            {this.state.isDisplayDealer && (
              <>
                <Col xl="12" className="mb-2">
                  <span className="filter-type-lbl ml-0">
                    Select Dealer Classification
                  </span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>
                <Col xl="12">
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    {(this.state.dealerData || []).map((dealer, index) => (
                      <div className="d-flex" style={{ paddingRight: "20px" }}>
                        <label className="container-checkbox">
                          <input
                            type="checkbox"
                            name="dealer"
                            checked={dealer.isChecked}
                            id="checkbox"
                            value={dealer.customer_class_code}
                            onChange={e => this.handleInputChange(e, index)}
                          />
                          &nbsp;{dealer.customer_class}
                          <span className="checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </>
            )}
            {this.state.isDisplayFleets && (
              <>
                <Col xl="12" className="mb-2">
                  <span className="filter-type-lbl ml-0">
                    Select Fleet Member's Type
                  </span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>
                <Col xl="12">
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    {(this.state.fleetTypeData || []).map((fleet, index) => (
                      <div className="d-flex" style={{ paddingRight: "20px" }}>
                        <label className="container-checkbox">
                          <input
                            type="checkbox"
                            name="fleets"
                            checked={fleet.isChecked}
                            id="checkbox"
                            value={fleet.value}
                            onChange={e => this.handleInputChange(e, index)}
                          />
                          &nbsp;{fleet.label}
                          <span className="checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </>
            )}
            {this.state.zoneData.length > 0 && (
              <>
                <Col xl="12" className="mb-2">
                  <span className="filter-type-lbl ml-0">Select Zone</span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>
                <Col xl="12">
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    {(this.state.zoneData || []).map((zone, index) => (
                      <div className="d-flex" style={{ paddingRight: "20px" }}>
                        <label className="container-checkbox">
                          <input
                            type="checkbox"
                            name="zone_id"
                            checked={zone.isChecked}
                            id="checkbox"
                            value={zone.zone_id}
                            onChange={e => this.handleInputChange(e, index)}
                          />
                          &nbsp;{zone.zone_name}
                          <span className="checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </>
            )}
            {!this.state.isDisplayFleets && (
              <>
                <Col xl="4" md="12" xs="12">
                  <Col xl="12" className="mb-2 pl-0">
                    <span className="filter-type-lbl ml-0 pl-0">Region</span>
                    {/* <Label for="Target">Select Employee</Label> */}
                  </Col>
                  <FormGroup>
                    {/* <Label for="Target">Region</Label> */}
                    <Select
                      {...this.props.input}
                      ref={r => (this.refRegion = r)}
                      name="region"
                      id="region"
                      pageSize="1"
                      getOptionLabel={option => option.region_name}
                      getOptionValue={option => option.region_id}
                      options={this.state.regionData}
                      allowSelectAll
                      closeMenuOnSelect={false}
                      value={controls.region_id.value}
                      tabSelectsValue={false}
                      isSearchable={true}
                      filterOption={this.customFilter}
                      onChange={e =>
                        this.handleInputChange({
                          target: { name: "region_id", value: e }
                        })
                      }
                      placeholder="Search..."
                      styles={customStyles}
                      hideSelectedOptions={false}
                      onInputChange={e =>
                        this.onChangeFilter({
                          target: { name: "serachValueRegion", value: e }
                        })
                      }
                      inputValue={this.state.serachValueRegion}
                      components={{
                        Option: CustomOption,
                        ValueContainer: this.ValueContainer
                      }}
                      backspaceRemoves={false}
                      isMulti
                      onChangeCallback={this.onBlurSelect}
                    />
                  </FormGroup>
                </Col>

                <Col xl="4" md="12" xs="12">
                  <FormGroup>
                    <Col xl="12" className="mb-2 pl-0">
                      <span className="filter-type-lbl ml-0">Area</span>
                    </Col>
                    <Select
                      {...this.props.input}
                      ref={r => (this.refArea = r)}
                      name="target"
                      pageSize="1"
                      getOptionLabel={option => option.area_name}
                      getOptionValue={option => option.area_id}
                      options={this.state.areaData}
                      allowSelectAll
                      closeMenuOnSelect={false}
                      value={controls.area_id.value}
                      tabSelectsValue={false}
                      isSearchable={true}
                      filterOption={this.customFilterArea}
                      onChange={e =>
                        this.handleInputChange({
                          target: { name: "area_id", value: e }
                        })
                      }
                      placeholder="Search..."
                      styles={customStyles}
                      hideSelectedOptions={false}
                      onInputChange={e =>
                        this.onChangeFilter({
                          target: { name: "serachValueArea", value: e }
                        })
                      }
                      inputValue={this.state.serachValueArea}
                      components={{
                        Option: CustomOption,
                        ValueContainer: this.ValueContainerArea
                      }}
                      backspaceRemoves={false}
                      isMulti
                      onBlur={() => {
                        this.refArea.select.setState({ isOpen: false });
                      }}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>}
          {(user_type_radio.value === 'upload_excel') &&
            <Row
              className="c-top-15"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <Col>
                <div className="text-align-right">
                  {!isUploadExcelOpen &&
                    <span
                      className="download-upload-excel-button"
                      onClick={this.openUploadExcel}
                    >Upload Excel</span>}&nbsp;&nbsp;
                  <span className="download-upload-excel-button">
                    <a className="download-upload-excel-button" href="https://images.jktyrecrm.in/others/user_sample.xlsx" target="_blank" rel="noopener noreferrer">Download Excel</a>
                  </span>
                </div>
                {isUploadExcelOpen &&
                  <>
                    <div className="create-icon-con">
                      <div className="upload-image-container">
                        <label className="upload-image-label">
                          <div className="edit-icon">
                            Choose File
                            {/* <Ionicon icon="md-create" fontSize="24px" color="#fff" /> */}
                          </div>
                          <input
                            type="file"
                            accept=".xlsx"
                            onChange={e =>
                              this.handleInputChange({
                                target: {
                                  name: "selected_file",
                                  value: e.target.files[0]
                                }
                              })
                            }
                            className="visibility-hidden width-0"
                          />
                        </label>
                      </div>
                      <div className="file-upload-file-name">
                        {selected_file.value && selected_file.value.name && <Row>
                          <Col sm="10" className="padding-right-0">
                            {selected_file.value.name}
                          </Col>
                          <Col sm="2" className="text-align-right cursor-pointer" onClick={this.resetUploadExcel} >
                            <img src="images/close.svg" height="12px" width="12px" alt='' />
                          </Col>
                        </Row>}
                      </div>
                    </div>
                    {selected_file.showErrorMsg && <div className="error">* Please select file</div>}
                  </>
                }
                {/* {bannerImage.showErrorMsg && <div className="error">
                    {NO_IMAGE}
                  </div>} */}
              </Col>
            </Row>}

          <br />
          <br />
        </div>
        <Row
          className="c-top-15 custom-drawer-footer"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <Col className="d-flex-row" style={{ justifyContent: "flex-end" }}>
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
              color="dark"
              onClick={() => this.resetFilter(true)}
              style={{ width: "116px" }}
            >
              Reset
            </Button>
            &nbsp;&nbsp;
            <Button
              //  disabled={!isValidForm}
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              onClick={() => this.applyFilter()}
              style={{ cursor: "pointer" }}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CampaignHoUserFilter;
