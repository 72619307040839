import React, { Component, Fragment } from "react";
import { HashRouter as Router } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import cx from "classnames";
import { connect } from "react-redux";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import Storage from "./services/Storage";
import Routes from "./router/Routes";
import "./App.css";
import NetworkDetector from "./pages/NetworkDetetctor/NetworkDetector";
import GoogleAnalticsService from "./services/GoogleAnalticsService";
import UserService from "./services/UserService";
import AuthService from "./services/AuthService";
// import HandleCache from "./HandleCache";
const API_URL = process.env.REACT_APP_API_URL;

let noNetworkImage = new Image();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisconnected: false,
      noNetworkImage: "images/no-network.svg",
      userDetail: Storage.getUserDetail()
    };
    GoogleAnalticsService.reactGAInitialize();
  }
  // setCookie(userId) {
  //   cookie.save('userId', userId, { path: '/' })
  // }

  componentDidMount() {
    this.getCookie();
    this.handleConnectionChange();
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
    noNetworkImage.src = "images/no-network.svg";
    if (this.state.userDetail && this.state.userDetail.type && !this.state.userDetail.hasOwnProperty('permissions')) {
      this.getUserInfo();
    }

  }
  getUserInfo = () => {
    UserService.getUserInfo()
      .then(data => {
        let userData = data.data.data;
        if (userData.permissions) {
          let userDetailFromLocalStorage = Storage.getUserDetail();
          userDetailFromLocalStorage.permissions = userData.permissions;
          Storage.setUserDetail(userData);
          window.location.reload();
        } else {
          AuthService.logout();
          // this.closeLogoutModal();
          this.props.history.replace("/login");
        }
      })
      .catch(e => {
        console.error("e", e);
      })
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch(`${API_URL}/api/v1/welcome`, {
          mode: "no-cors"
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing);
            });
          })
          .catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  };

  getCookie = () => {
    const co = Storage.getKeepMeLoggedInCookie();
    if (!co) {
      Storage.removeTokenDetail();
    }
  };

  render() {
    const {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      // enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt
    } = this.props;
    return (

      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <img
              src={this.props.noNetworkImage}
              alt=''
              className="no-network-img"
              style={{ display: "none" }}
            />
            {this.state.isDisconnected ? (
              <NetworkDetector noNetworkImage={noNetworkImage.src} />
            ) : (
              <Router>
                <div
                  className={cx(
                    `app-container app-theme-${colorScheme}`,
                    { "fixed-header": enableFixedHeader },
                    { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                    { "fixed-footer": enableFixedFooter },
                    // { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                    { "closed-sidebar": true },
                    {
                      "closed-sidebar-mobile":
                        closedSmallerSidebar || width < 1250
                    },
                    { "sidebar-mobile-open": enableMobileMenu },
                    { "body-tabs-shadow-btn": enablePageTabsAlt }
                  )}
                >
                  <Routes />
                </div>
              </Router>
            )}
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt
});

export default connect(mapStateToProp)(App);
