import { request } from "./Request";
import { routes } from "../constants/constant.route";

const {
  EXCEPTIONAL_POINT_UPLOAD,
  EXCEPTIONAL_POINT_UPLOAD_V2,
  GET_CLOSING_BALANCE_TRACK,
} = routes.EXCEPTIONAL_POINT;
const API_URL = process.env.REACT_APP_API_URL;

export default class exceptionalPointsUploadService {
  static exceptionalPointsUpload(obj) {
    return request("POST", `${API_URL}${EXCEPTIONAL_POINT_UPLOAD}`, null, obj, null);
  }

  static exceptionalPointsUploadV2(obj) {
    return request("POST", `${API_URL}${EXCEPTIONAL_POINT_UPLOAD_V2}`, null, obj, null);
  }

  static getClosingBalanceTrack(obj) {
    let q = `?`;
    q += `type=${obj.type}&`
    return request("GET", `${API_URL}${GET_CLOSING_BALANCE_TRACK}${q}`, null, obj, null);
  }
}
