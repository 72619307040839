
import React from 'react';
import { Button, Modal, ModalBody, FormGroup, Input, Label, Row, Col } from 'reactstrap';

import UserService from '../../services/UserService';
import ModalService from '../../services/ModalService';
import GoogleAnalticsService from "../../services/GoogleAnalticsService";

import { messages } from '../../constants/constant.message';

import './RequestDetailUpdateModal.css';

const { ERROR_MESSAGE } = messages.COMMON;
const title = 'Update Detail';

class RequestDetailUpdateModal extends React.Component {

  state = {
    controls: {
      message: {
        value: '',
        valid: null,
        touched: false,
        nullValue: null
      },
      keepMeLoggenIn: false,
      isRequestSubmitSuccessModalOpen: false,
      modalMessage: ''
    },
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    // this.handleValidation();
  }

  openDetailRequestSubmitSuccessAlert() {
    this.setState({ isRequestSubmitSuccessModalOpen: true });
  }

  sendUpdateDetailRequest = () => {
    const obj = {
      message: this.state.controls.message.value
    };
    GoogleAnalticsService.event('Profile', 'Request Profile Submit');
    UserService.sendUpdateDetailRequest(obj)
      .then(data => {

        const message = data.data.message;
        this.setState({ message });
        ModalService.openAlert(title, message, 'success');
        this.closeModal();
        // this.openDetailRequestSubmitSuccessAlert();
      })
      .catch(e => {
        const err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert(title, message, 'error');
        console.error('error', e);
      });
  }


  closeModal = () => {
    this.setState({ isRequestSubmitSuccessModalOpen: false });
    this.props.closeModal(true);
  }

  render() {
    const { controls } = this.state;
    const { message } = controls;

    return (
      <div>
        {/* <AlertModal
          show={this.state.isRequestSubmitSuccessModalOpen}
          closeModal={this.closeModal}
          title={title}
          msg={this.state.message}
          buttons={null}
          child={null}
        /> */}
        <Modal id="request-detail-update-modal" isOpen={this.props.show} toggle={this.props.closeModal} centered>
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            <Row className="modal-title-border padding-bottom-10">
              <Col className="modal-title">
                REQUEST TO EDIT DETAILS
              </Col>
            </Row>
            <FormGroup className="margin-top-10">
              <Label for="password" className="request-details">Request Details</Label>
              <Input
                type="textarea"
                name="message"
                id="message"
                placeholder="Tell us what you want to edit with reason. We will verify details and update database soon. We expect this to
                            complete in a few days however we will try it as soon as possible."
                value={message.value}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <div style={{ textAlign: 'right' }}>
              <Button className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" onClick={this.props.closeModal} color="dark">Cancel</Button>
              <Button className="mb-1 mr-1 btn-pill Rectangle-button send-button" disabled={message.value === null || message.value === ""} onClick={this.sendUpdateDetailRequest} color="warning">Send</Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default RequestDetailUpdateModal; 