import React, { Component } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import lodash from "lodash";
import ConfirmationNextAtendee from "../ConfirmationNextAtendee/ConfirmationNextAtendee";
import CustomerIntractionAddEditVehicle from "../../components/CustomerIntractionAddEditVehicle/CustomerIntractionAddEditVehicle";
import Validation from "../../services/Validation";
import MerchandiseWalletService from "../../services/MerchandiseWalletService";
import CustomerIntractionServie from "../../services/CustomerIntractionService";
import swal from "sweetalert";
import * as Scroll from "react-scroll";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import ModalService from "../../services/ModalService";

const defaultTyreObj = {
  construction: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  front_or_rear: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  size: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brand: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brand_id: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  pattren: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  tyres: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brands: [],
  pattern: [],
};
class CustomerIntractionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isJKuserDeatil: false,
      controls: {
        frontRearData: {
          truck: [],
          bus: [],
          lcv: [],
          scv: [],
        },
        company_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        designation: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        mobile_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        attendee_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        whatsapp_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        email: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        address: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        same_as_mobile: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        state: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        pin_code: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
      },
      vehicleData: {},
      stateData: [],
      cityData: [],
      brands: [],
      isFormValid: false,
      isLoading: false,
    };
    this.state.tbrPattren = {
      jk_tyre: [
        "JUH XF",
        "JUH3+",
        "JUH3",
        "JUH4",
        "JUH5",
        "JDE++",
        "JDY",
        "JDH3",
        "JDH",
        "JDH XF",
        "JDC3",
        "JUC3",
        "JUC2",
        "JUM",
        "JDL",
      ],
      vikrant: ["XLD312", "XYA511", "XZS321"],
      apollo: [
        "MA",
        "MA326",
        "LD",
        "LDH",
        "MD",
        "RA",
        "RD",
        "LDR",
        "RA1",
        "RDHD",
        "RA NRG",
        "RAT NRG",
      ],
      mrf: [
        "S1R4",
        "S1R4+",
        "S1F4",
        "S3C8+",
        "S3H8",
        "S3K4",
        "S3P4",
        "S1M4+",
        "S1T4",
      ],
      ceat: [
        "WINMILE R",
        "WINSUPER D",
        "WINLOAD D",
        "WINMILE D",
        "WINMILE AW",
        "WINSUPER X3D",
        "WINMILE X3D",
        "HYTON",
      ],
      contil: [
        "HDR2 Alpha",
        "CHD2",
        "CHS2",
        "HDR2",
        "HDR2+",
        "HDX2",
        "HSX2",
        "HSR2",
        "HSC1",
      ],
      chalanger: ["CDH", "CUC", "CDD100", "CDU100"],
      bridgestone: [
        "R153",
        "R187",
        "G611",
        "M751 HL",
        "M751",
        "M729",
        "L301",
        "M840",
        "L370",
        "M851 XM",
        "M820",
        "G620",
        "R156",
        "R159",
      ],
      michelin: [
        "X MULTI D",
        "X MULTI Z",
        "XZY3 HD",
        "X GUARD D",
        "X GUARD Z",
        "X MULTI D",
        "X WORKS D HD",
        "X WORKS Z HD",
        "XZU3+",
      ],
    };
    this.state.tbbPattren = {
      jk_tyre: [
        "Jet L Miles",
        "Jet Xtra XLM",
        "Jet Trak XL",
        "Jet Trak MX",
        "Jet Xtra Load",
        "Jet Rock Xtra",
        "Jet Tuff DX",
        "Jet R Xtra Miles",
        "Jet R Miles",
      ],
      vikrant: [
        "Trak Lug+",
        "Star Lug+",
        "Rock Lug+",
        "Trak King Super+",
        "Trak King+",
      ],
      apollo: [
        "XT100K",
        "XT-9 Gold",
        "XT-9 Gold HD",
        "LSS XP",
        "Mine Lug",
        "SL HD",
        "XMR",
        "Amar Gold",
        "Amar DLX",
      ],
      mrf: [
        "SL707",
        "SL505",
        "SL50+",
        "SL50+R",
        "Muscle Rock",
        "M77",
        "SM909",
        "SM99",
      ],
      ceat: [
        "Mile XL Pro",
        "Mile XL",
        "HCL Super",
        "HCL Super HL",
        "Super Dumper HD",
        "Mile XL SL+HD",
        "Mile XL Rib Pro",
        "Mile XL Rib",
      ],
      contil: [
        "Conti BR",
        "Conti R Prime",
        "Conti R",
        "Conti HL",
        "Conti L",
        "Conti ML",
        "Conti RL-BM",
        "Conti RR",
        "Conti RL",
        "Conti CL",
        "Load Power 2B NW",
      ],
      bkt: ["XL Grip", "Rock Grip", "Steer Grip", "XL Grip Ultra"],
      birla: [
        "BT112",
        "Kala Patthar",
        "BT339",
        "BT339+",
        "Road Miler",
        "BT112 Platinum",
        "Tison Dlx",
        "Zeta+ Dlx",
        "Tarzon Dlx",
      ],
    };
    this.state.tbrTyreSize = [
      "7.50R20",
      "8.25R20",
      "9.00R20",
      "10.00R20",
      "11.00R20",
      "12.00R20",
      "11R22.5",
      "275/80R22.5",
      "295/80R22.5",
      "315/80R22.5",
    ];
    this.state.tbbTyreSize = [
      "7.50-20",
      "8.25-20",
      "9.00-20",
      "10.00-20",
      "11.00-20",
      "12.00-20",
      "295/95D20",
    ];
    // this.state.tbrPattren = this.state.tbrPattren.jk_tyre;
    // this.state.tbbPattren = this.state.tbbPattren.jk_tyre;
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getState();
    if (this.props.cip_uuid) {
      this.getCIPDetailById();
    }
  }

  getCIPDetailById = () => {
    this.setState({ isLoading: true });
    let { controls } = this.state;
    let reqData = { uuid: this.props.cip_uuid };
    if (controls.mobile_number.value) {
      reqData = { mobile_number: controls.mobile_number.value };
    }
    CustomerIntractionServie.getCIPDetailById(reqData)
      .then((data) => {
        if (data.data.data && Object.keys(data.data.data).length > 0) {
          let { controls, vehicleData } = this.state;
          controls.mobile_number.value = data.data.data.mobile_number;
          controls.email.value = data.data.data.email;
          controls.company_name.value = data.data.data.company_name;
          controls.attendee_name.value = data.data.data.attendee_name;
          controls.designation.value = data.data.data.designation;
          controls.whatsapp_number.value = data.data.data.whatsapp_number;
          controls.state.value = data.data.data.state_id;
          controls.city.value = data.data.data.city_id;
          controls.pin_code.value = data.data.data.pin_code;
          controls.address.value = data.data.data.address;
          if (
            data.data.data.vehicle_detail &&
            Object.keys(data.data.data.vehicle_detail).length > 0
          ) {
            let totalCountBus = lodash.sumBy(
              data.data.data.vehicle_detail.bus,
              function (o) {
                return o.count ? +o.count : 0;
              }
            );
            let totalCountTruck = lodash.sumBy(
              data.data.data.vehicle_detail.truck,
              function (o) {
                return o.count ? +o.count : 0;
              }
            );
            let totalCountScv = lodash.sumBy(
              data.data.data.vehicle_detail.scv,
              function (o) {
                return o.count ? +o.count : 0;
              }
            );
            let totalCountLcv = lodash.sumBy(
              data.data.data.vehicle_detail.lcv,
              function (o) {
                return o.count ? +o.count : 0;
              }
            );
            vehicleData = {
              totalCountBus: totalCountBus,
              totalCountTruck: totalCountTruck,
              totalCountLcv: totalCountLcv,
              totalCountScv: totalCountScv,
              vehicles: data.data.data.vehicle_detail,
            };
          }
          if (
            data.data.data.business_related_detail &&
            Object.keys(data.data.data.business_related_detail).length > 0
          ) {
            if (
              data.data.data.business_related_detail &&
              Object.keys(data.data.data.business_related_detail).length > 0
            ) {
              if (
                data.data.data.business_related_detail.truck &&
                data.data.data.business_related_detail.truck.length > 0
              ) {
                this.setFrontRearData(
                  "truck",
                  data.data.data.business_related_detail
                );
              }
              if (
                data.data.data.business_related_detail.bus &&
                data.data.data.business_related_detail.bus.length > 0
              ) {
                this.setFrontRearData(
                  "bus",
                  data.data.data.business_related_detail
                );
              }
              if (
                data.data.data.business_related_detail.lcv &&
                data.data.data.business_related_detail.lcv.length > 0
              ) {
                this.setFrontRearData(
                  "lcv",
                  data.data.data.business_related_detail
                );
              }
              if (
                data.data.data.business_related_detail.scv &&
                data.data.data.business_related_detail.scv.length > 0
              ) {
                this.setFrontRearData(
                  "scv",
                  data.data.data.business_related_detail
                );
              }
            }
          }
          this.setState({
            controls,
            vehicleData,
          });
          this.getCity();
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  setFrontRearData = (key, data) => {
    let { controls } = this.state;
    let { frontRearData } = controls;
    data[key].forEach((element, i) => {
      frontRearData[key].push(JSON.parse(JSON.stringify(defaultTyreObj)));
      frontRearData[key][i].front_or_rear.value = element.front_or_rear
        ? element.front_or_rear
        : "front_data";
      frontRearData[key][i].construction.value = element.construction
        ? element.construction
        : "";
      frontRearData[key][i].size.value = element.size ? element.size : "";
      frontRearData[key][i].brand.value = element.brand ? element.brand : "";
      if (element.brand_id) {
        this.changeFrontBrand(element.brand_id, i, key);
      }
      if (element.construction) {
        this.getBrand(element.construction, i, key, true);
      }
      frontRearData[key][i].pattren.value = element.pattren
        ? element.pattren
        : "";
      frontRearData[key][i].tyres.value = element.tyres ? element.tyres : "";
    });
    this.setState({
      controls,
    });
  };

  setRearData = (key, data) => {
    let { controls } = this.state;
    let { rearData } = controls;
    data[key].forEach((element, i) => {
      rearData[key].push(JSON.parse(JSON.stringify(defaultTyreObj)));
      rearData[key][i].construction.value = element.construction
        ? element.construction
        : "";
      rearData[key][i].size.value = element.size ? element.size : "";
      rearData[key][i].brand.value = element.brand ? element.brand : "";
      if (element.brand) {
        this.changeRearBrand(element.brand, i, key);
      }
      if (element.construction) {
        this.getBrand(element.construction, i, key, false);
      }
      rearData[key][i].pattren.value = element.pattren ? element.pattren : "";
      rearData[key][i].tyres.value = element.tyres ? element.tyres : "";
    });
    this.setState({
      controls,
    });
  };

  openAddTyreModal = (data) => {
    // this.refs.refConfirmationNextAtendee.toggle(data);
  };
  openAddEditVehicleModal = (type) => {
    this.refs.refCustomerIntractionAddEditVehicle.toggle(
      this.state.vehicleData,
      type
    );
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let {
      mobile_number,
      email,
      company_name,
      attendee_name,
      designation,
      whatsapp_number,
      state,
      city,
      pin_code,
    } = controls;

    if (company_name.touched === true || isSubmit) {
      company_name = Validation.notNullValidator(company_name);
      company_name.valid = !company_name.nullValue;
      if (company_name.valid === false) {
        company_name.showErrorMsg = true;
      } else {
        company_name.showErrorMsg = false;
      }
    }

    if (attendee_name.touched === true || isSubmit) {
      attendee_name = Validation.notNullValidator(attendee_name);
      attendee_name.valid = !attendee_name.nullValue;
      if (attendee_name.valid === false) {
        attendee_name.showErrorMsg = true;
      } else {
        attendee_name.showErrorMsg = false;
      }
    }

    if (designation.touched === true || isSubmit) {
      designation = Validation.notNullValidator(designation);
      designation.valid = !designation.nullValue;
      if (designation.valid === false) {
        designation.showErrorMsg = true;
      } else {
        designation.showErrorMsg = false;
      }
    }

    if (state.touched === true || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (city.touched === true || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (pin_code.touched === true || isSubmit) {
      pin_code = Validation.notNullValidator(pin_code);
      pin_code = Validation.validateLength(pin_code, 6);
      pin_code = Validation.validateNumber(pin_code);
      pin_code.valid = !(
        pin_code.nullValue ||
        pin_code.invalidLength ||
        pin_code.invalidNumber
      );
      if (!pin_code.valid) {
        pin_code.showErrorMsg = true;
      } else {
        pin_code.showErrorMsg = false;
      }
    }

    if (mobile_number.touched === true || isSubmit) {
      mobile_number = Validation.notNullValidator(mobile_number);
      if (!mobile_number.nullValue) {
        mobile_number = Validation.validatePhoneNumber(mobile_number);
      }
      mobile_number.valid = !(
        mobile_number.nullValue || mobile_number.invalidPhone
      );
      if (mobile_number.valid === false) {
        mobile_number.showErrorMsg = true;
      } else {
        mobile_number.showErrorMsg = false;
      }
    }

    if (whatsapp_number.touched === true || isSubmit) {
      whatsapp_number = Validation.validatePhoneNumber(whatsapp_number);
      whatsapp_number.valid = !whatsapp_number.invalidPhone;
      if (whatsapp_number.valid === false) {
        whatsapp_number.showErrorMsg = true;
      } else {
        whatsapp_number.showErrorMsg = false;
      }
    }

    email.valid = true;
    if (email.touched === true || isSubmit) {
      if (
        email.value === "" ||
        email.value === null ||
        email.value === undefined
      ) {
        email.valid = true;
        email.showErrorMsg = false;
      } else {
        email = Validation.emailValidator(email);
        email.valid = !email.invalidEmail;
        if (email.valid === false) {
          email.showErrorMsg = true;
        } else {
          email.showErrorMsg = false;
        }
      }
    }

    if (
      email.valid === true &&
      mobile_number.valid === true &&
      attendee_name.valid === true &&
      designation.valid === true &&
      whatsapp_number.valid === true &&
      state.valid === true &&
      city.valid === true &&
      pin_code.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleValidationFrontData = (key = "", isSubmit = false) => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    for (let i = 0; i < frontRearData[key].length; i++) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "front_data") {
        if (currentData.construction.touched === true || isSubmit) {
          currentData.construction = Validation.notNullValidator(
            currentData.construction
          );
          currentData.construction.valid = !!currentData.construction.value;
        }

        if (currentData.size.touched === true || isSubmit) {
          currentData.size = Validation.notNullValidator(currentData.size);
          currentData.size.valid = !!currentData.size.value;
        }

        if (currentData.brand.touched === true || isSubmit) {
          currentData.brand = Validation.notNullValidator(currentData.brand);
          currentData.brand.valid = !!currentData.brand.value;
        }

        if (currentData.pattren.touched === true || isSubmit) {
          currentData.pattren = Validation.notNullValidator(
            currentData.pattren
          );
          currentData.pattren.valid = !!currentData.pattren.value;
        }

        if (currentData.tyres.touched === true || isSubmit) {
          currentData.tyres = Validation.notNullValidator(currentData.tyres);
          currentData.tyres.valid = !!currentData.tyres.value;
        }
      }
    }
    let isTyreDetailValid = true;
    for (let i = 0; i < frontRearData[key].length; i++) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "front_data") {
        isTyreDetailValid =
          isTyreDetailValid &&
          (currentData.construction.valid &&
            currentData.size.valid &&
            currentData.brand.valid &&
            currentData.pattren.valid &&
            currentData.tyres.valid);
      }
    }
    let isValidForm = false;
    if (isTyreDetailValid) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({ controls });
    return isValidForm;
  };

  handleValidationRearData = (key = "", isSubmit = false) => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    for (let i = 0; i < frontRearData[key].length; i++) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "rear_data") {
        if (currentData.construction.touched === true || isSubmit) {
          currentData.construction = Validation.notNullValidator(
            currentData.construction
          );
          currentData.construction.valid = !!currentData.construction.value;
        }

        if (currentData.size.touched === true || isSubmit) {
          currentData.size = Validation.notNullValidator(currentData.size);
          currentData.size.valid = !!currentData.size.value;
        }

        if (currentData.brand.touched === true || isSubmit) {
          currentData.brand = Validation.notNullValidator(currentData.brand);
          currentData.brand.valid = !!currentData.brand.value;
        }

        if (currentData.pattren.touched === true || isSubmit) {
          currentData.pattren = Validation.notNullValidator(
            currentData.pattren
          );
          currentData.pattren.valid = !!currentData.pattren.value;
        }

        if (currentData.tyres.touched === true || isSubmit) {
          currentData.tyres = Validation.notNullValidator(currentData.tyres);
          currentData.tyres.valid = !!currentData.tyres.value;
        }
      }
    }
    let isTyreDetailValid = true;
    for (let i = 0; i < frontRearData[key].length; i++) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "rear_data") {
        isTyreDetailValid =
          isTyreDetailValid &&
          (currentData.construction.valid &&
            currentData.size.valid &&
            currentData.brand.valid &&
            currentData.pattren.valid &&
            currentData.tyres.valid);
      }
    }
    let isValidForm = false;
    if (isTyreDetailValid) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({ controls });
    return isValidForm;
  };

  handleInputChange = async (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (controlName === "same_as_mobile") {
      controls.same_as_mobile.value = !controls.same_as_mobile.value;
      if (
        !controls.mobile_number.showErrorMsg &&
        controls.mobile_number.value &&
        controls.same_as_mobile.value
      ) {
        controls.whatsapp_number.value = controls.mobile_number.value;
        controls.same_as_mobile.value = true;
      } else {
        controls.whatsapp_number.value = "";
        controls.same_as_mobile.value = false;
      }
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    await this.setState({ controls });
    this.handleValidation();
    if (controlName === "state") {
      this.getCity();
    }
  };

  handleOnBlurMobile = () => {
    let { controls } = this.state;
    if (controls.mobile_number.valid) {
      if (controls.same_as_mobile.value) {
        controls.whatsapp_number.value = controls.mobile_number.value;
      }
      this.getCIPDetailById();
    }
  };

  resetData = () => {
    let { controls, vehicleData } = this.state;
    controls.mobile_number.value = "";
    controls.mobile_number.touched = false;
    controls.email.value = "";
    controls.email.touched = false;
    controls.company_name.value = "";
    controls.company_name.touched = false;
    controls.attendee_name.value = "";
    controls.attendee_name.touched = false;
    controls.designation.value = "";
    controls.designation.touched = false;
    controls.whatsapp_number.value = "";
    controls.whatsapp_number.touched = false;
    controls.state.value = "";
    controls.state.touched = false;
    controls.city.value = "";
    controls.city.touched = false;
    controls.pin_code.value = "";
    controls.pin_code.touched = false;
    controls.address.value = "";
    controls.same_as_mobile.value = false;
    vehicleData = {};
    controls.frontRearData = {
      truck: [],
      bus: [],
      lcv: [],
      scv: [],
    };
    controls.rearData = {
      truck: [],
      bus: [],
      lcv: [],
      scv: [],
    };
    this.setState({
      controls,
      vehicleData,
    });
  };

  checkAllDataValidationFront = () => {
    let frontTruck = true;
    let frontBus = true;
    let frontLCV = true,
      frontSCV = true;
    if (!this.handleValidationFrontData("truck", true)) {
      frontTruck = false;
    }
    if (!this.handleValidationFrontData("bus", true)) {
      frontBus = false;
    }
    if (!this.handleValidationFrontData("lcv", true)) {
      frontLCV = false;
    }
    if (!this.handleValidationFrontData("scv", true)) {
      frontSCV = false;
    }
    return !!(
      frontTruck === true &&
      frontBus === true &&
      frontSCV === true &&
      frontLCV === true
    );
  };

  checkAllDataValidationRear = () => {
    let frontTruck = true;
    let frontBus = true;
    let frontLCV = true,
      frontSCV = true;
    if (!this.handleValidationRearData("truck", true)) {
      frontTruck = false;
    }
    if (!this.handleValidationRearData("bus", true)) {
      frontBus = false;
    }
    if (!this.handleValidationRearData("lcv", true)) {
      frontLCV = false;
    }
    if (!this.handleValidationRearData("scv", true)) {
      frontSCV = false;
    }
    return !!(
      frontTruck === true &&
      frontBus === true &&
      frontSCV === true &&
      frontLCV === true
    );
  };

  getReqFrontData = () => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    let resFrontData = {
      bus: [],
      truck: [],
      lcv: [],
      scv: [],
    };
    Object.keys(frontRearData).forEach((key, i) => {
      frontRearData[key].forEach((element) => {
        resFrontData[key].push({
          construction: element.construction.value,
          front_or_rear: element.front_or_rear.value,
          size: element.size.value,
          brand: element.brand.value,
          pattren: element.pattren.value,
          tyres: element.tyres.value ? element.tyres.value : "",
          brand_id: element.brand_id.value ? element.brand_id.value : "",
        });
      });
    });
    return resFrontData;
  };

  submitDetail = (isNextAttende = false) => {
    // let { isJKuserDeatil } = this.state;
    // this.setState({
    //   isJKuserDeatil: !isJKuserDeatil
    // })
    if (!isNextAttende) {
      if (!this.handleValidation(true)) {
        return true;
      }
      // if (!this.checkAllDataValidationFront()) {
      //   return false;
      // }
      // if (!this.checkAllDataValidationRear()) {
      //   return false;
      // }
    }
    let { controls, vehicleData } = this.state;
    let reqData = {
      company_name: controls.company_name.value,
      attendee_name: controls.attendee_name.value,
      designation: controls.designation.value,
      mobile_number: controls.mobile_number.value,
      whatsapp_number: controls.whatsapp_number.value
        ? controls.whatsapp_number.value
        : null,
      email: controls.email.value,
      state_id: controls.state.value,
      city_id: controls.city.value,
      pin_code: controls.pin_code.value,
      address: controls.address.value,
    };
    if (this.props.cip_id) {
      reqData.cip_id = this.props.cip_id;
    }
    if (Object.keys(vehicleData).length > 0) {
      reqData.vehicle_detail = vehicleData.vehicles;
      reqData.business_related_detail = this.getReqFrontData();
    }

    if (this.props.cip_uuid) {
      reqData.uuid = this.props.cip_uuid;
      this.updateCIPDetail(reqData);
      return;
    }

    this.setState({ isLoading: true });
    CustomerIntractionServie.addCipDetails(reqData)
      .then((res) => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        if (isNextAttende) {
          this.resetData();
        } else {
          this.props.endUpRegistration(false);
        }
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  updateCIPDetail = (reqData) => {
    this.setState({ isLoading: true });
    CustomerIntractionServie.updateCIPDetail(reqData)
      .then((res) => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.endUpRegistration(true);
        });
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  changeRearBrand = (brand, index, key) => {
    const { controls } = this.state;
    const { rearData } = controls;
    const tyreObj = rearData[key][index];
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrandPattern({ brand_id: brand })
      .then((res) => {
        tyreObj.pattern = res.data.data.pattern;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  changeFrontBrand = (brand, index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    const tyreObj = frontRearData[key][index];
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrandPattern({ brand_id: brand })
      .then((res) => {
        tyreObj.pattern = res.data.data.pattern;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  onChangeFrontRearTyre = (e, index, key, brands = []) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    const { frontRearData } = controls;
    if (controlName === "tyres") {
      if (isNaN(controlValue)) {
        return;
      }
    }
    const tyreObj = frontRearData[key][index];
    tyreObj[controlName].value = controlValue;
    tyreObj[controlName].touched = true;
    if (controlName === "construction") {
      tyreObj.size.value = "";
      tyreObj.brand.value = "";
      tyreObj.brand_id.value = "";
      tyreObj.pattren.value = "";
    } else if (controlName === "brand") {
      tyreObj.pattren.value = "";
    }
    if (controlName === "brand") {
      let brand = lodash.filter(brands, (element) => {
        return element.name === controlValue;
      });
      if (brand.length > 0) {
        tyreObj.brand_id.value = brand[0].id;
        this.changeFrontBrand(brand[0].id, index, key);
      } else {
        tyreObj.brand_id.value = "other";
        this.changeFrontBrand("other", index, key);
      }
    }
    if (controlName === "construction") {
      this.getBrand(controlValue, index, key, true);
    }
    this.setState({
      controls,
    });
    this.handleValidationFrontData(key);
  };

  onChangeRearTyre = (e, index, key) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    const { rearData } = controls;
    const tyreObj = rearData[key][index];
    if (controlName === "tyres") {
      if (isNaN(controlValue)) {
        return;
      }
    }
    tyreObj[controlName].value = controlValue;
    tyreObj[controlName].touched = true;
    if (controlName === "construction") {
      tyreObj.size.value = "";
      tyreObj.brand.value = "";
      tyreObj.pattren.value = "";
    } else if (controlName === "brand") {
      tyreObj.pattren.value = "";
    }
    if (controlName === "brand") {
      this.changeRearBrand(controlValue, index, key);
    }

    if (controlName === "construction") {
      this.getBrand(controlValue, index, key, false);
    }

    this.setState({
      controls,
    });
    this.handleValidationRearData(key);
  };

  addFrontTyreObject = (key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    let setdefaultTyreObj = defaultTyreObj;
    setdefaultTyreObj.front_or_rear.value = "front_data";
    frontRearData[key].push(JSON.parse(JSON.stringify(setdefaultTyreObj)));
    this.setState({ controls });
  };

  removeFrontTyre = (index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    frontRearData[key].splice(index, 1);
    this.setState({ controls });
  };

  addRearTyreObject = (key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    let setdefaultTyreObj = defaultTyreObj;
    setdefaultTyreObj.front_or_rear.value = "rear_data";
    frontRearData[key].push(JSON.parse(JSON.stringify(setdefaultTyreObj)));
    this.setState({ controls });
  };

  removeRearTyre = (index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    frontRearData[key].splice(index, 1);
    this.setState({ controls });
  };

  submitVehclies = (data) => {
    let vehicleData = lodash.cloneDeep(data);
    if (Object.keys(vehicleData).length <= 0) {
      this.setState({
        vehicleData: {},
      });
      return;
    }
    this.setState({ isLoading: true });
    let { controls } = this.state;
    let { frontRearData, rearData } = controls;
    if (vehicleData.totalCountTruck > 0) {
      if (frontRearData.truck.length === 0) {
        frontRearData.truck.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.truck[0].front_or_rear.value = "front_data";
        frontRearData.truck.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.truck[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["truck"] = [];
    }
    if (vehicleData.totalCountBus > 0) {
      if (frontRearData.bus.length === 0) {
        frontRearData.bus.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.bus[0].front_or_rear.value = "front_data";
        frontRearData.bus.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.bus[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["bus"] = [];
    }
    if (vehicleData.totalCountLcv > 0) {
      if (frontRearData.lcv.length === 0) {
        frontRearData.lcv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.lcv[0].front_or_rear.value = "front_data";
        frontRearData.lcv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.lcv[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["lcv"] = [];
    }
    if (vehicleData.totalCountScv > 0) {
      if (frontRearData.scv.length === 0) {
        frontRearData.scv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.scv[0].front_or_rear.value = "front_data";
        frontRearData.scv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.scv[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["scv"] = [];
    }
    this.setState({ isLoading: false });
    this.setState({
      vehicleData: vehicleData,
      frontRearData,
      rearData,
    });
  };

  getState = () => {
    this.setState({ isLoading: true });
    MerchandiseWalletService.getState()
      .then((data) => {
        let { stateData, controls } = this.state;
        let { data: states } = data.data;
        stateData = states;
        this.setState({ stateData, controls, isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  getCity = () => {
    let { controls } = this.state;
    this.setState({ isLoading: true });
    MerchandiseWalletService.getCity(controls.state.value)
      .then((data) => {
        let { cityData, controls } = this.state;
        const { data: cities } = data.data;
        cityData = cities;
        this.setState({ cityData, controls, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoading: false });
      });
  };

  scrollTo(id) {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80,
    });
  }

  confirmationNextAtendee = () => {
    if (!this.handleValidation(true)) {
      this.scrollTo("business-detail");
      return true;
    }

    // if (!this.checkAllDataValidationFront()) {
    //   return false;
    // }
    // if (!this.checkAllDataValidationRear()) {
    //   return false;
    // }
    this.refs.refConfirmationNextAtendee.toggle();

  };

  getBrand = (construction, index, key, isFront) => {
    const { controls } = this.state;
    const { frontRearData, rearData } = controls;
    let tyreObj = frontRearData[key][index];
    if (!isFront) {
      tyreObj = rearData[key][index];
    }
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrand({ construction: construction })
      .then((res) => {
        tyreObj.brands = res.data.data.brand;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };
  render() {
    let {
      isJKuserDeatil,
      // tbbPattren,
      // tbrPattren,
      vehicleData,
      controls,
    } = this.state;
    let { frontRearData } = controls;
    return (
      <div>
        {this.state.isLoading && <CustomSpinner />}
        {!isJKuserDeatil && (
          <>
            <div style={{ marginTop: "25px" }}>
              <Row id="business-detail">
                <Col xl="9" md="12" xs="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="d-flex">
                          <b className="margin-right-16 mt-auto mb-auto">
                            BUSINESS DETAIL
                          </b>
                        </Col>
                        <Col className="text-align-right pr-0">
                          <Button
                            className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                            color="dark"
                            onClick={() => this.props.endUpRegistration(true)}
                            style={{ width: "100px" }}
                          >
                            Back
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col xl="3" md="12" xs="12">
                          <FormGroup>
                            <Label for="mobile_number">
                              Mobile Number
                              <span className="required-field">*</span>
                            </Label>
                            <Input
                              autocomplete="nofill"
                              type="text"
                              maxLength="10"
                              onChange={(e) => this.handleInputChange(e)}
                              onBlur={this.handleOnBlurMobile}
                              name="mobile_number"
                              value={controls.mobile_number.value}
                              placeholder="Type here"
                            />
                            {controls.mobile_number.showErrorMsg && (
                              <div className="error">
                                * Please enter valid mobile number.
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xl="6" md="12" xs="12">
                          <FormGroup>
                            <Label for="company_name">
                              Company Name
                              <span className="required-field">*</span>
                            </Label>
                            <Input
                              type="text"
                              name="company_name"
                              id="company_name"
                              value={controls.company_name.value}
                              onChange={(e) => this.handleInputChange(e)}
                              autocomplete="nofill"
                              placeholder="Type here"
                            />
                            {controls.company_name.showErrorMsg && (
                              <div className="error">
                                * Please enter company name.
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xl="3" md="12" xs="12">
                          <FormGroup>
                            <Label for="owner_name">
                              Attendee Name
                              <span className="required-field">*</span>
                            </Label>
                            <Input
                              type="text"
                              autocomplete="nofill"
                              name="attendee_name"
                              value={controls.attendee_name.value}
                              onChange={(e) => this.handleInputChange(e)}
                              placeholder="Type here"
                            />
                            {controls.attendee_name.showErrorMsg && (
                              <div className="error">
                                * Please enter attendee name.
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xl="3" md="12" xs="12">
                          <FormGroup>
                            <Label for="company_type">
                              Designation
                              <span className="required-field">*</span>
                            </Label>
                            <select
                              className="form-control"
                              value={
                                controls.designation.value
                                  ? controls.designation.value.toLowerCase()
                                  : ""
                              }
                              name="designation"
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              <option value="owner">Owner</option>
                              <option value="manager">Manager</option>
                              <option value="others">Others</option>
                            </select>
                            {controls.designation.showErrorMsg && (
                              <div className="error">
                                * Please select designation.
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xl="3" md="12" xs="12">
                          <FormGroup>
                            <Label for="whatsapp_number">Whatsapp Number</Label>
                            <Input
                              autocomplete="nofill"
                              type="text"
                              onChange={(e) => this.handleInputChange(e)}
                              name="whatsapp_number"
                              value={controls.whatsapp_number.value}
                              disabled={controls.same_as_mobile.value}
                              maxLength="10"
                              id="whatsapp_number"
                              placeholder="Type here"
                            />
                            {controls.whatsapp_number.showErrorMsg && (
                              <div className="error">
                                * Please enter valid whatsapp number.
                              </div>
                            )}
                            <div className="mt-1">
                              <label className="custom-checkboax1--container">
                                <input
                                  type="checkbox"
                                  name="same_as_mobile"
                                  checked={controls.same_as_mobile.value}
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                                <span className="checkmark1" />
                              </label>
                              <span style={{ marginLeft: "10px" }}>
                                Same as mobile number
                              </span>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xl="6" md="12" xs="12">
                          <FormGroup>
                            <Label for="primaryPerson">Email Address</Label>
                            <Input
                              autocomplete="nofill"
                              type="text"
                              onChange={(e) => this.handleInputChange(e)}
                              value={controls.email.value}
                              name="email"
                              id="email"
                              placeholder="Type here"
                            />
                            {controls.email.showErrorMsg && (
                              <div className="error">
                                * Please enter email address.
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                      <Row>
                        <Col xl="6" md="12" xs="12">
                          <FormGroup>
                            <Label for="address">Address</Label>
                            <textarea
                              value={controls.address.value}
                              onChange={(e) => this.handleInputChange(e)}
                              name="address"
                              className="form-control address"
                            ></textarea>
                          </FormGroup>
                        </Col>
                        <Col xl="6" md="12" xs="12">
                          <Row>
                            <Col xl="6" md="12" xs="12">
                              <FormGroup>
                                <Label for="state">
                                  State<span className="required-field">*</span>
                                </Label>
                                <select
                                  className="form-control"
                                  name="state"
                                  value={controls.state.value}
                                  onChange={(e) => this.handleInputChange(e)}
                                >
                                  <option value="" selected="true" disabled>
                                    Select state
                                  </option>
                                  {this.state.stateData.map((state, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={state.state_id}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                                {controls.state.showErrorMsg && (
                                  <div className="error">
                                    * Please select state.
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col xl="6" md="12" xs="12">
                              <FormGroup>
                                <Label for="city">
                                  City<span className="required-field">*</span>
                                </Label>
                                <select
                                  className="form-control"
                                  name="city"
                                  value={controls.city.value}
                                  onChange={(e) => this.handleInputChange(e)}
                                >
                                  <option value="" selected="true" disabled>
                                    Select city
                                  </option>
                                  {this.state.cityData.map((city, j) => (
                                    <option
                                      className="text-capitalize"
                                      value={city.city_id}
                                    >
                                      {city.name}
                                    </option>
                                  ))}
                                </select>
                                {controls.city.showErrorMsg && (
                                  <div className="error">
                                    * Please select city.
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6" md="12" xs="12">
                              <FormGroup>
                                <Label for="pin">
                                  Pincode
                                  <span className="required-field">*</span>
                                </Label>
                                <Input
                                  autocomplete="nofill"
                                  type="text"
                                  name="pin_code"
                                  maxLength="6"
                                  value={controls.pin_code.value}
                                  onChange={(e) => this.handleInputChange(e)}
                                  id="pin_code"
                                  placeholder="Type here"
                                />
                              </FormGroup>
                              {controls.pin_code.showErrorMsg && (
                                <div className="error">
                                  * Please enter pincode .
                                </div>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="mt-4">
                    <CardBody className="pb-0">
                      <Row>
                        <Col xl="6" md="12" xs="12" className="d-flex-column">
                          <b className="margin-right-16">VEHICLE DETAIILS</b>
                        </Col>
                        {/* <Col xl="6" md="12" xs="12" className="text-align-right">
                          <img className="cursor-pointer" src='images/add.svg' alt='' onClick={() => this.openAddEditVehicleModal({})} />&nbsp;<span className="cursor-pointer" onClick={() => this.openAddEditVehicleModal({})}>Add Vehicle</span>
                        </Col> */}
                      </Row>
                      <hr className="ml-n3 mr-n3" />
                      <Row className="mt-n3">
                        <Col
                          xl="3"
                          md="12"
                          xs="12"
                          className="d-flex-row border-right-1px pb-3 pt-3"
                        >
                          <Row>
                            <Col xl="6" md="6" xs="6">
                              <img
                                src="images/truck.png"
                                className="fleet-truck-img"
                                alt=''
                              />
                            </Col>
                            <Col xl="6" md="6" xs="6" className="d-flex">
                              <div className="d-flex-column mt-2 ml-2">
                                <span className="ml-3">Truck</span>
                                {vehicleData &&
                                  vehicleData.totalCountTruck > 0 && (
                                    <span className="ml-3">
                                      {vehicleData.totalCountTruck}
                                    </span>
                                  )}
                                {!vehicleData.totalCountTruck && (
                                  <span className="d-flex mt-1">
                                    <img
                                      style={{ height: "15px" }}
                                      className="cursor-pointer"
                                      src="images/add.svg"
                                      alt=''
                                      onClick={() =>
                                        this.openAddEditVehicleModal("truck")
                                      }
                                    />
                                    &nbsp;
                                    <span
                                      className="cursor-pointer min-add-btn"
                                      onClick={() =>
                                        this.openAddEditVehicleModal("truck")
                                      }
                                    >
                                      Add
                                    </span>
                                  </span>
                                )}
                              </div>
                              {vehicleData &&
                                vehicleData.totalCountTruck > 0 && (
                                  <img
                                    onClick={() =>
                                      this.openAddEditVehicleModal("truck")
                                    }
                                    style={{
                                      height: "12px",
                                      marginTop: "-10px",
                                    }}
                                    className="cursor-pointer ml-auto"
                                    src="images/edit.svg"
                                    alt=''
                                  />
                                )}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xl="3"
                          md="12"
                          xs="12"
                          className="d-flex-row border-right-1px pb-3 pt-3"
                        >
                          <Row>
                            <Col xl="6" md="6" xs="6">
                              <img
                                src="images/bus.png"
                                className="fleet-truck-img"
                                alt=''
                              />
                            </Col>
                            <Col xl="6" md="6" xs="6" className="d-flex">
                              <div className="d-flex-column mt-2 ml-2">
                                <span>Bus</span>
                                {vehicleData &&
                                  vehicleData.totalCountBus > 0 && (
                                    <span className="">
                                      {vehicleData.totalCountBus}
                                    </span>
                                  )}
                                {!vehicleData.totalCountBus && (
                                  <span className="d-flex mt-1">
                                    <img
                                      style={{ height: "15px" }}
                                      className="cursor-pointer"
                                      src="images/add.svg"
                                      alt=''
                                      onClick={() =>
                                        this.openAddEditVehicleModal("bus")
                                      }
                                    />
                                    &nbsp;
                                    <span
                                      className="cursor-pointer min-add-btn"
                                      onClick={() =>
                                        this.openAddEditVehicleModal("bus")
                                      }
                                    >
                                      Add
                                    </span>
                                  </span>
                                )}
                              </div>
                              {vehicleData && vehicleData.totalCountBus > 0 && (
                                <img
                                  onClick={() =>
                                    this.openAddEditVehicleModal("bus")
                                  }
                                  style={{ height: "12px", marginTop: "-10px" }}
                                  className="cursor-pointer ml-auto"
                                  src="images/edit.svg"
                                  alt=''
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xl="3"
                          md="12"
                          xs="12"
                          className="d-flex-row border-right-1px pb-3 pt-4"
                        >
                          <Row>
                            <Col xl="6" md="6" xs="6">
                              <img
                                src="images/truck@2x.png"
                                className="fleet-truck-img"
                                alt=''
                              />
                            </Col>
                            <Col xl="6" md="6" xs="6" className="d-flex">
                              <div className="d-flex-column mt-2 ml-2">
                                <span>LCV</span>
                                {vehicleData &&
                                  vehicleData.totalCountLcv > 0 && (
                                    <span className="">
                                      {vehicleData.totalCountLcv}
                                    </span>
                                  )}
                                {!vehicleData.totalCountLcv && (
                                  <span className="d-flex mt-1">
                                    <img
                                      style={{ height: "15px" }}
                                      className="cursor-pointer"
                                      src="images/add.svg"
                                      alt=''
                                      onClick={() =>
                                        this.openAddEditVehicleModal("lcv")
                                      }
                                    />
                                    &nbsp;
                                    <span
                                      className="cursor-pointer min-add-btn"
                                      onClick={() =>
                                        this.openAddEditVehicleModal("lcv")
                                      }
                                    >
                                      Add
                                    </span>
                                  </span>
                                )}
                              </div>
                              {vehicleData && vehicleData.totalCountLcv > 0 && (
                                <img
                                  onClick={() =>
                                    this.openAddEditVehicleModal("lcv")
                                  }
                                  style={{ height: "12px", marginTop: "-10px" }}
                                  className="cursor-pointer ml-auto"
                                  src="images/edit.svg"
                                  alt=''
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xl="3"
                          md="12"
                          xs="12"
                          className="d-flex-row pb-3 pt-4"
                        >
                          <Row>
                            <Col xl="6" md="6" xs="6">
                              <img
                                src="images/scv.png"
                                className="fleet-truck-img"
                                alt=''
                              />
                            </Col>
                            <Col xl="6" md="6" xs="6" className="d-flex">
                              <div className="d-flex-column mt-2 ml-2">
                                <span>SCV</span>
                                {vehicleData &&
                                  vehicleData.totalCountScv > 0 && (
                                    <span className="">
                                      {vehicleData.totalCountScv}
                                    </span>
                                  )}
                                {!vehicleData.totalCountScv && (
                                  <span className="d-flex mt-1">
                                    <img
                                      style={{ height: "15px" }}
                                      className="cursor-pointer"
                                      src="images/add.svg"
                                      alt=''
                                      onClick={() =>
                                        this.openAddEditVehicleModal("scv")
                                      }
                                    />
                                    &nbsp;
                                    <span
                                      className="cursor-pointer min-add-btn"
                                      onClick={() =>
                                        this.openAddEditVehicleModal("scv")
                                      }
                                    >
                                      Add
                                    </span>
                                  </span>
                                )}
                              </div>
                              {vehicleData && vehicleData.totalCountScv > 0 && (
                                <img
                                  onClick={() =>
                                    this.openAddEditVehicleModal("scv")
                                  }
                                  style={{ height: "12px", marginTop: "-10px" }}
                                  className="cursor-pointer ml-auto"
                                  src="images/edit.svg"
                                  alt=''
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {Object.keys(vehicleData).length > 0 &&
                    Object.keys(frontRearData).map(
                      (key, keyIndex) =>
                        ((key === "truck" && vehicleData.totalCountTruck > 0) ||
                          (key === "bus" && vehicleData.totalCountBus > 0) ||
                          (key === "lcv" && vehicleData.totalCountLcv > 0) ||
                          (key === "scv" && vehicleData.totalCountScv > 0)) && (
                          <Card className="mt-4">
                            <CardBody>
                              <Row>
                                <Col
                                  xl="6"
                                  md="12"
                                  xs="12"
                                  className="d-flex-column"
                                >
                                  <b className="margin-right-16 text-uppercase">
                                    {key} RELATED DETAILS&nbsp;
                                    <span className="required-field">*</span>
                                  </b>
                                  {/* <span className="note-rect" style={{ border: 'none', marginTop: '3px', padding: '0' }}>Other Truck Bus Radial/ Bias Tyres bought Last year</span> */}
                                </Col>
                                {/* <Col xl="6" md="12" xs="12" className="text-align-right" style={{ marginTop: '20px' }}>
                        <img src='images/add.svg' alt='' className="cursor-pointer" onClick={() => { this.openAddTyreModal({ uuid: 1 }); }} />&nbsp;<span onClick={() => { this.openAddTyreModal({}); }} className="cursor-pointer">Add Tyres</span>
                      </Col> */}
                              </Row>
                              <hr />
                              <Table
                                className="mb-0 table-responsive mt-3"
                                bordered
                              >
                                <thead>
                                  <tr style={{ backgroundColor: "#f6f6f6" }}>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Tyre Position
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Construction
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Tyre Size
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Brand
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Pattren
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Tyres/Annum
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {frontRearData[key].map(
                                    (item, index) =>
                                      item.front_or_rear.value ===
                                      "front_data" && (
                                        <tr>
                                          <td className="font-size-14px">
                                            Front
                                          </td>
                                          <td
                                            className="business-related-detail-td"
                                            valign="top"
                                          >
                                            <select
                                              className="form-control min-width-100px"
                                              name="construction"
                                              value={item.construction.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key
                                                )
                                              }
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select
                                              </option>
                                              <option value="bias">Bias</option>
                                              <option value="radial">
                                                Radial
                                              </option>
                                            </select>
                                            {/* {item.construction.nullValue && <div className="error mt-1">Please select construction</div>} */}
                                          </td>
                                          <td
                                            className="business-related-detail-td"
                                            valign="top"
                                          >
                                            <select
                                              className="form-control min-width-120px"
                                              name="size"
                                              value={item.size.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key
                                                )
                                              }
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select
                                              </option>
                                              {key !== "lcv" &&
                                                key !== "scv" &&
                                                item.construction.value && (
                                                  <>
                                                    {item.construction.value ===
                                                      "bias"
                                                      ? this.state.tbbTyreSize.map(
                                                        (tbb, j) => (
                                                          <option
                                                            className="text-capitalize"
                                                            value={tbb}
                                                          >
                                                            {tbb}
                                                          </option>
                                                        )
                                                      )
                                                      : this.state.tbrTyreSize.map(
                                                        (tbr, j) => (
                                                          <option
                                                            className="text-capitalize"
                                                            value={tbr}
                                                          >
                                                            {tbr}
                                                          </option>
                                                        )
                                                      )}
                                                  </>
                                                )}
                                              <option value="other">
                                                Other
                                              </option>
                                            </select>
                                            {/* {item.size.nullValue && <div className="error mt-1">Please select tyre size</div>} */}
                                          </td>
                                          <td className="business-related-detail-td">
                                            <select
                                              className="form-control min-width-120px"
                                              name="brand"
                                              value={item.brand.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key,
                                                  item.brands
                                                )
                                              }
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select
                                              </option>
                                              {item.brands.map((brand, j) => (
                                                <option
                                                  className="text-capitalize"
                                                  value={brand.name}
                                                >
                                                  {brand.name}
                                                </option>
                                              ))}
                                              <option value="other">
                                                Other
                                              </option>
                                            </select>
                                            {/* {item.brand.nullValue && <div className="error mt-1">Please select brand</div>} */}
                                          </td>
                                          <td className="business-related-detail-td">
                                            <select
                                              className="form-control min-width-120px"
                                              name="pattren"
                                              value={item.pattren.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key
                                                )
                                              }
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select
                                              </option>
                                              {key !== "lcv" && key !== "scv" ? (
                                                <>
                                                  {item.pattern.map(
                                                    (pattern, j) => (
                                                      <option
                                                        className="text-capitalize"
                                                        value={pattern.name}
                                                      >
                                                        {pattern.name}
                                                      </option>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              <option value="Other">
                                                Other
                                              </option>
                                            </select>
                                            {/* {item.pattren.nullValue && <div className="error mt-1">Please select pattren</div>} */}
                                          </td>
                                          <td className="business-related-detail-td">
                                            <Input
                                              type="text"
                                              autocomplete="nofill"
                                              name="tyres"
                                              id="tyres"
                                              value={item.tyres.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key
                                                )
                                              }
                                              style={{ width: "115px" }}
                                              placeholder="Type here"
                                            />
                                            {/* {item.tyres.nullValue && <div className="error mt-1">Please enter tyres count.</div>} */}
                                          </td>
                                          <td>
                                            <img
                                              className="cursor-pointer"
                                              onClick={() =>
                                                this.removeFrontTyre(index, key)
                                              }
                                              src="images/delete.svg"
                                              alt=''
                                            />
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </tbody>
                              </Table>
                              {lodash.sumBy(frontRearData[key], function (o) {
                                return o.front_or_rear.value === "front_data"
                                  ? 1
                                  : 0;
                              }) <= 0 && (
                                  <div className="no-data">
                                    If you want to add {key} related details then
                                    click on add more button.
                                  </div>
                                )}
                              <div className="d-flex mt-2">
                                <img
                                  className="cursor-pointer"
                                  src="images/add.svg"
                                  alt=''
                                  style={{ height: "18px" }}
                                  onClick={() => this.addFrontTyreObject(key)}
                                />
                                &nbsp;&nbsp;
                                <span
                                  className="subsidiaries-name cursor-pointer"
                                  onClick={() => this.addFrontTyreObject(key)}
                                >
                                  Add More
                                </span>
                              </div>

                              <br />
                              <Table
                                className="mb-0 table-responsive mt-3"
                                bordered
                              >
                                <thead>
                                  <tr style={{ backgroundColor: "#f6f6f6" }}>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Tyre Position
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Construction
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Tyre Size
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Brand
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Pattren
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Tyres/Annum
                                    </th>
                                    <th style={{ border: "1px solid #e9ecef" }}>
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {frontRearData[key].map(
                                    (item, index) =>
                                      item.front_or_rear.value ===
                                      "rear_data" && (
                                        <tr>
                                          <td className="font-size-14px">
                                            Rear
                                          </td>
                                          <td className="business-related-detail-td">
                                            <select
                                              className="form-control min-width-100px"
                                              name="construction"
                                              value={item.construction.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key
                                                )
                                              }
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select
                                              </option>
                                              <option value="bias">Bias</option>
                                              <option value="radial">
                                                Radial
                                              </option>
                                            </select>
                                            {/* {item.construction.nullValue && <div className="error mt-1">Please select construction</div>} */}
                                          </td>
                                          <td className="business-related-detail-td">
                                            <select
                                              className="form-control min-width-120px"
                                              name="size"
                                              value={item.size.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key
                                                )
                                              }
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select
                                              </option>
                                              {key !== "lcv" &&
                                                key !== "scv" &&
                                                item.construction.value && (
                                                  <>
                                                    {item.construction.value ===
                                                      "bias"
                                                      ? this.state.tbbTyreSize.map(
                                                        (tbb, j) => (
                                                          <option
                                                            className="text-capitalize"
                                                            value={tbb}
                                                          >
                                                            {tbb}
                                                          </option>
                                                        )
                                                      )
                                                      : this.state.tbrTyreSize.map(
                                                        (tbr, j) => (
                                                          <option
                                                            className="text-capitalize"
                                                            value={tbr}
                                                          >
                                                            {tbr}
                                                          </option>
                                                        )
                                                      )}
                                                  </>
                                                )}
                                              <option value="Other">
                                                Other
                                              </option>
                                            </select>
                                            {/* {item.size.nullValue && <div className="error mt-1">Please select tyre size</div>} */}
                                          </td>
                                          <td className="business-related-detail-td">
                                            <select
                                              className="form-control min-width-120px"
                                              name="brand"
                                              value={item.brand.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key,
                                                  item.brands
                                                )
                                              }
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select
                                              </option>
                                              {item.brands.map((brand, j) => (
                                                <option
                                                  className="text-capitalize"
                                                  value={brand.name}
                                                >
                                                  {brand.name}
                                                </option>
                                              ))}
                                              <option value="other">
                                                Other
                                              </option>
                                            </select>
                                            {/* {item.brand.nullValue && <div className="error mt-1">Please select brand</div>} */}
                                          </td>
                                          <td className="business-related-detail-td">
                                            <select
                                              className="form-control min-width-120px"
                                              name="pattren"
                                              value={item.pattren.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key
                                                )
                                              }
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select
                                              </option>
                                              {key !== "lcv" && key !== "scv" ? (
                                                <>
                                                  {item.pattern.map(
                                                    (pattern, j) => (
                                                      <option
                                                        className="text-capitalize"
                                                        value={pattern.name}
                                                      >
                                                        {pattern.name}
                                                      </option>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                <option value="Other">
                                                  Other
                                                </option>
                                              )}
                                            </select>
                                            {/* {item.pattren.nullValue && <div className="error mt-1">Please select pattren</div>} */}
                                          </td>
                                          <td className="business-related-detail-td">
                                            <Input
                                              type="text"
                                              autocomplete="nofill"
                                              name="tyres"
                                              id="tyres"
                                              value={item.tyres.value}
                                              onChange={(e) =>
                                                this.onChangeFrontRearTyre(
                                                  e,
                                                  index,
                                                  key
                                                )
                                              }
                                              style={{ width: "115px" }}
                                              placeholder="Type here"
                                            />
                                            {/* {item.tyres.nullValue && <div className="error mt-1">Please enter tyres count.</div>} */}
                                          </td>
                                          <td>
                                            <img
                                              className="cursor-pointer"
                                              onClick={() =>
                                                this.removeRearTyre(index, key)
                                              }
                                              src="images/delete.svg"
                                              alt=''
                                            />
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </tbody>
                              </Table>
                              {lodash.sumBy(frontRearData[key], function (o) {
                                return o.front_or_rear.value === "rear_data"
                                  ? 1
                                  : 0;
                              }) <= 0 && (
                                  <div className="no-data">
                                    If you want to add {key} related details then
                                    click on add more button.
                                  </div>
                                )}
                              <div className="d-flex mt-2">
                                <img
                                  className="cursor-pointer"
                                  src="images/add.svg"
                                  style={{ height: "18px" }}
                                  onClick={() => this.addRearTyreObject(key)}
                                  alt=''
                                />
                                &nbsp;&nbsp;
                                <span
                                  className="subsidiaries-name cursor-pointer"
                                  onClick={() => this.addRearTyreObject(key)}
                                >
                                  Add More
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        )
                    )}
                  {this.props.cip_uuid ? (
                    <Col
                      className="text-align-right pr-0"
                      style={{ marginTop: "20px" }}
                    >
                      <Button
                        className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                        color="warning"
                        style={{ width: "140px" }}
                        onClick={() => this.submitDetail(false)}
                      >
                        SUBMIT
                      </Button>
                    </Col>
                  ) : (
                    <Col
                      className="text-align-right pr-0 next-atendee-wrapp"
                      style={{ marginTop: "20px" }}
                    >
                      <Button
                        className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                        color="warning"
                        style={{ width: "140px" }}
                        onClick={() => this.confirmationNextAtendee()}
                      >
                        SUBMIT
                      </Button>
                      {/* <Button className="mb-1 mr-1 btn-pill action-button font-weight-bold" color="warning"
                          style={{ width: '150px' }} onClick={() => this.submitDetail(true)}>NEXT ATENDEE</Button>&nbsp;&nbsp;&nbsp;
                        <Button className="mb-1 mr-1 btn-pill action-button font-weight-bold" color="warning"
                          style={{ width: '180px' }} onClick={() => this.submitDetail(false)}>END UP REGISTRATION</Button> */}
                    </Col>
                  )}
                </Col>
              </Row>
            </div>
            <ConfirmationNextAtendee
              ref="refConfirmationNextAtendee"
              submitDetail={this.submitDetail}
              from=""
            />
            <CustomerIntractionAddEditVehicle
              ref="refCustomerIntractionAddEditVehicle"
              submitVehclies={this.submitVehclies}
              from=""
            />
          </>
        )}
      </div>
    );
  }
}

export default CustomerIntractionDetail;
