import {
  RetailMarketingStoreInformationControls,
  // hoRecceControl,
  filterData,
} from "../components/RetailMarketingSystem/RetailMarktingControls";
import {
  SET_RETAIL_RECCI_STORE_INFORMATION,
  SET_RECCE_STATUS,
  SET_HO_RECCE_MODIFICATION,
  SET_RECCE_STATUS_COUNT,
  SET_RECCE_STATUS_COUNT_FILTER,
  SET_VENDOR_SCREEN,
  SET_FILTER_DATA,
  SET_FILTER_OPEN_CLOSE,
  SET_FILTER_APPLIED_STATUS,
  SET_DUPLICATE_RECCE,
} from "../constants/types";

const initialState = {
  addReccicontrols: RetailMarketingStoreInformationControls,
  status: "all",
  hoRecceControls: "",
  count: 0,
  statusCount: {},
  vendorScreen: false,
  filterData: filterData,
  filterOpen: false,
  filterAppliedStatus: false,
  duplicateRecce: false,
};

const RetailManagementSystemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RETAIL_RECCI_STORE_INFORMATION:
      return {
        ...state,
        addReccicontrols: Object.assign({}, action.addReccicontrols),
      };
    case SET_DUPLICATE_RECCE:
      return {
        ...state,
        duplicateRecce: action.duplicateRecce,
      };
    case SET_FILTER_OPEN_CLOSE:
      return {
        ...state,
        filterOpen: action.filterOpen,
      };
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: Object.assign({}, action.filterData),
      };
    case SET_FILTER_APPLIED_STATUS:
      return {
        ...state,
        filterAppliedStatus: action.filterAppliedStatus,
      };
    case SET_HO_RECCE_MODIFICATION:
      return {
        ...state,
        hoRecceControls: action.hoRecceControl,
      };
    case SET_RECCE_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case SET_RECCE_STATUS_COUNT:
      return {
        ...state,
        count: action.count,
      };
    case SET_RECCE_STATUS_COUNT_FILTER:
      return {
        ...state,
        statusCount: action.statusCount,
      };
    case SET_VENDOR_SCREEN:
      return {
        ...state,
        vendorScreen: action.vendorScreen,
      };
    default:
      return state;
  }
};

export default RetailManagementSystemReducer;
