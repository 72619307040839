import React, { useState } from 'react'
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from "react-moment";
import { messages } from '../../../constants/constant.message';
import { Button, Row, Col, Label, Modal, ModalBody } from "reactstrap";
import CustomSpinner from '../../../components/CustomSpinner/CustomSpinner';
import RetailMarktingViewUploadPhotographHO from '../../../components/RetailMarketingSystem/RetailMarktingViewUploadPhotographHO/RetailMarktingViewUploadPhotographHO';
import RetailMarketingSystemService from '../../../services/RetailMarketingSystemService';
import swal from 'sweetalert';
import './RecceProgressStatus.css';
import ModalService from '../../../services/ModalService';
import { getDuplicateRecce, getHoRecceModification, getRecceStatus, getRetailMarketingStoreInformationState, getVendorScreen } from '../../../components/RetailMarketingSystem/selector';
import { bindActionCreators } from 'redux';
import { handleValidationRetailMarketingStoreInformation, resetStoreDetailControls, setHoRecceControls, setRecceStatus, setRetailRecciControls, setRetailRecciDetail } from '../../../actions/RetailManagementSystemAction';
import RetailMarktingUploadPhotograph from '../../../components/RetailMarketingSystem/RetailMarktingUploadPhotograph/RetailMarktingUploadPhotograph';
import MultistepForRMS from '../../../components/MultistepForRMS/MultistepForRMS';
import RetailOtherRecceInformation from '../../../components/RetailMarketingSystem/RetailOtherRecceInformation/RetailOtherRecceInformation';
function RecceProgressStatus({
  storeDetailControls,
  recceStatus,
  hoRecceControls,
  vendorScreen,
  setRetailRecciDetailAction,
  setRetailRecciControlsAction,
  handleValidationRetailMarketingStoreInformationAction,
  location,
  history,
  duplicateRecce
}) {
  const [retail_id, setRetail_id] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditAPILoadComplted, setIsEditAPILoadComplted] = useState(false);
  const [delearInfo, setDelearInfo] = useState({});
  const [boardId, setBoardId] = useState([]);
  const [vendorInfo, setVendorInfo] = useState({});
  const [retailDetailinfo, setRetailDetailInfo] = useState({});
  const [modificationRemark, setModificationRemark] = useState('');
  const [counter, setCounter] = useState(0);
  const [openInstallationVendor, setopenInstallationVendor] = useState(false);
  const [modalModification, setModalModification] = useState(false);
  const [modalRejection, setModalRejection] = useState(false);
  const [modalApprove, setModalApprove] = useState(false);
  const [chairmanClubPriceforSignage, setchairmanClubPriceforSignage] = useState();
  const [chairmanClubPriceforCanopy, setChairmainClubPriceforCanopy] = useState();
  const [otherClubPriceforSignage, setOtherClubPriceforSignage] = useState();
  const [otherClubPriceforCanopy, setOtherClubPriceforCanopy] = useState();
  const [signagePriceChairmanClub, setSignagePriceChairmanClub] = useState();
  const [canopyPriceChairmanClub, setCanopyPriceChairmanClub] = useState();
  const [signagePriceOtherClub, setSignagePriceOtherClub] = useState();
  const [canopyPriceOtherClub, setCanopyPriceOtherClub] = useState();
  const [clubSelected, setClubSelected] = useState("chairman_club");
  const [remarkError, setRemarkError] = useState(false);
  const [vendorView, setVendorView] = useState(false);
  const [title, setTitle] = useState();
  const [vendor, setVendor] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [hoInstallation, setHoInstallation] = useState(false);
  const [toggleRecce, setToggleRecce] = useState();
  const [vendorInstallationModification, setVendorInstallationModification] = useState(false);
  const toggle = () => setModalModification(!modalModification);
  const toggleModalRejection = () => setModalRejection(!modalRejection);
  const toggleModalApprove = () => setModalApprove(!modalApprove)
  const [isInstallationApproved, setIsInstallationApproved] = useState();
  const [otherRecceInfo, setOtherRecceInfo] = useState([]);
  const screenState = JSON.parse(localStorage.getItem('screenState'));
  const screenTitle = localStorage.getItem('screenTitle');
  const screenStatus = localStorage.getItem('screenStatus');
  const retailIDFromURL = localStorage.getItem('activeLink');
  useEffect(() => {
    setToggleRecce(retailIDFromURL);
    const query = new URLSearchParams(location.search);
    const id = query.get("id");
    if (id) {
      setRetail_id(id);
      getRetailDetail(id);
      setTitle(screenTitle);
      setVendorView(location.vendorViewOnly);
      setIsEdit(location.isEdit);
      if (location.isEdit) {
        setopenInstallationVendor(true);
      }
      if (location.hoInstallation) {
        setHoInstallation(true);
      }
      if (location.isInstallationApproved) {
        setIsInstallationApproved(true);
      }
      if (location.vendorInstallationModification) {
        setVendorInstallationModification(true);
      }
      setVendor(location.vendor);

    }
    const name = "horemarkReject"
    storeDetailControls[name].value = "";
    storeDetailControls[name].touched = false;
    storeDetailControls[name].nullValue = null;
    storeDetailControls[name].showErrorMsg = false;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    const namee = "horemarks"
    storeDetailControls[namee].value = "";
    storeDetailControls[namee].touched = false;
    storeDetailControls[namee].nullValue = null;
    storeDetailControls[namee].showErrorMsg = false;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    const nam = "horemarksApprove"
    storeDetailControls[nam].value = "";
    storeDetailControls[nam].touched = false;
    storeDetailControls[nam].nullValue = null;
    storeDetailControls[nam].showErrorMsg = false;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));

  }, [counter, chairmanClubPriceforSignage, chairmanClubPriceforCanopy, otherClubPriceforSignage, otherClubPriceforCanopy, duplicateRecce, screenStatus, screenTitle, toggleRecce])
  const getRetailDetail = (id) => {
    let reqData = {
      retail_id: id
    };
    setIsLoading(true);
    RetailMarketingSystemService.getClubPrice()
      .then((data) => {

        const priceData = data.data.data;
        priceData.map((item) => {
          if (item.signage_type === "Chairman's Club") {
            setchairmanClubPriceforSignage(item.main_signage_rate_per_square_feet);
            setChairmainClubPriceforCanopy(item.canopy_rate_per_square_feet);
          }
          if (item.signage_type === "Other Club") {
            setOtherClubPriceforSignage(item.main_signage_rate_per_square_feet);
            setOtherClubPriceforCanopy(item.canopy_rate_per_square_feet);
          }
        })
        // setIsLoading(false);
        return RetailMarketingSystemService.getRetailDetail(reqData)
      })
      .then((data) => {

        const { data: retail_detail } = data.data;
        setRetailDetailInfo(retail_detail);
        setDelearInfo(retail_detail.dealer_detail);
        if (retail_detail.club_name !== "Chairman's Club") {
          setClubSelected('other_club');
        }
        var area = 0;
        var signagePriceChairman = 0;
        var signagePriceOther = 0;
        setBoardId(retail_detail.boards_detail[0].board_id);
        var boardId = [];
        retail_detail.boards_detail.map((item) => {
          return (
            boardId.push(item.board_id),
            area = (parseFloat(area)) + parseFloat((item.signage_width * 48) / 144),
            area = Number(area).toFixed(2),
            signagePriceChairman = parseFloat(signagePriceChairman) + parseFloat(item.square_feet_area * chairmanClubPriceforSignage),
            signagePriceChairman = Number(signagePriceChairman).toFixed(2),
            signagePriceOther = parseFloat(signagePriceOther) + parseFloat(item.square_feet_area * otherClubPriceforSignage),
            signagePriceOther = Number(signagePriceOther).toFixed(2)
          );
        })
        setBoardId(boardId);
        setSignagePriceChairmanClub(signagePriceChairman);
        setSignagePriceOtherClub(signagePriceOther);
        setCanopyPriceChairmanClub(Number((area * chairmanClubPriceforCanopy)).toFixed(2));
        setCanopyPriceOtherClub(Number((area * otherClubPriceforCanopy)).toFixed(2));
        setVendorInfo(retail_detail.vendor_detail);
        setOtherRecceInfo(retail_detail.other_recce);
        storeDetailControls.dealer.displayValue = retail_detail.dealer_detail.business_name;
        storeDetailControls.dealer.value = retail_detail.dealer_detail.dealer_id;
        storeDetailControls.state.value = retail_detail.dealer_detail.state_id;
        storeDetailControls.city.value = retail_detail.dealer_detail.city_id;
        storeDetailControls.district.value = retail_detail.dealer_detail.district_id;
        setRetailRecciControlsAction(storeDetailControls);
        setRetailRecciDetailAction(storeDetailControls, retail_detail);
        setIsLoading(false);
        setTimeout(() => {
          setIsEditAPILoadComplted(true);
        }, 1);
      })
      .catch((e) => {
        console.error("e", e);
        setIsLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        swal({
          title: "",
          text: message,
          icon: "error",
        }).then((isConfirm) => {
          goBack();
        });
      });
  };

  const goBack = () => {
    history.push({
      pathname: "/retail-marketing-recce-list"
    });
  }
  const goBackToVendor = () => {
    history.push({
      pathname: "/retail-marketing-system"
    });
  }
  // const modificationRequired = (e) =>{
  //   setModificationRemark(e.target.value);
  //   setCounter(counter+1);
  // }

  const openInstallationDetail = () => {
    setopenInstallationVendor(true);
  }
  const cancelInstallationDetail = () => {
    setopenInstallationVendor(false);
  }

  const handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    storeDetailControls[controlName].value = controlValue;
    storeDetailControls[controlName].touched = true;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
  }
  const submitInstallation = () => {

    setIsLoading(true);
    if (!handleValidationRetailMarketingStoreInformationAction(true, storeDetailControls)) {
      setIsLoading(false);
      return true;
    }
    const proposedSizeForNewSignatureInstallation = [];
    if (storeDetailControls.proposedSizeForNewSignatureInstallation && storeDetailControls.proposedSizeForNewSignatureInstallation.length > 0) {
      storeDetailControls.proposedSizeForNewSignatureInstallation.forEach((element, index) => {
        // if(index===0){
        proposedSizeForNewSignatureInstallation.push({
          "post_facade_front_view": element.frontView.value,
          "post_facade_closeup_shot": element.closeupView.value,
          "post_facade_long_shot": element.longShotView.value,
          "post_facade_left_view": element.leftView.value,
          "post_facade_right_view": element.rightView.value,
          "board_id": boardId[index],
        })
        // }
      });
    }
    const prePareObj = {
      "installation_remarks": storeDetailControls.remarksInstallation.value,
      "installation_acknowledgement_form": storeDetailControls.acknowledgementFormInstallation.value,
      "retail_id": retail_id,
      "installtion_date": storeDetailControls.installation_date.value,
      // "warranty_date": addOneYear(storeDetailControls.installation_date.value),
      "retail_board": proposedSizeForNewSignatureInstallation,
    }
    RetailMarketingSystemService.addUpdateRetailInstallation(prePareObj)
      .then((data) => {
        if (data.data && data.data.success) {
          swal({
            title: "Congratulations!",
            text: data.data.message,
            icon: "success",
            type: "success"
          }).then((isConfirm) => {

            goBackToVendor();
          });;
        }
        setIsLoading(false);

      })
      .catch((e) => {
        setIsLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });

  }
  const submit = (remark, retail_id, status, clubName) => {
    setIsLoading(true);
    if (!handleValidationRetailMarketingStoreInformationAction(true, storeDetailControls)) {
      return true;
    }

    const proposedSizeForNewSignature = [];
    if (storeDetailControls.proposedSizeForNewSignature && storeDetailControls.proposedSizeForNewSignature.length > 0) {
      storeDetailControls.proposedSizeForNewSignature.forEach(element => {
        proposedSizeForNewSignature.push({
          "pre_facade_front_view": element.frontView.value,
          "pre_facade_left_view": element.leftView.value,
          "pre_facade_right_view": element.rightView.value,
          "pre_facade_closeup_shot": element.closeupView.value,
          "pre_facade_long_shot": element.longShotView.value,
          "quantity": 1,
          "signage_width": Number(element.width.value),
          "signage_height": Number(element.height.value),
          "square_feet_area": element.totalArea.value
        })
      });
    }
    const prePareObject = {
      "recce_date": storeDetailControls.recce_date.value,
      "dealer_id": storeDetailControls.dealer.value,
      "address": storeDetailControls.address.value,
      "state_id": storeDetailControls.state.value,
      "city_id": storeDetailControls.city.value,
      "district_id": storeDetailControls.district.value,
      "pin_code": storeDetailControls.pincode.value,
      "signage_name": storeDetailControls.nameOnTheSignature.value,
      "acknowledgement_form": storeDetailControls.acknowledgementForm.value,
      "retail_board": proposedSizeForNewSignature,
      "remarks": storeDetailControls.remarks.value,
      "vendor_id": vendorInfo.vendor_id
    }
    if (retail_id) {
      prePareObject.retail_id = retail_id;
    }
    // this.setState({
    //   isLoading: true
    // });
    setIsLoading(true);
    if (status === "recce_modified") {
      if (modificationRemark === "") {
        setRemarkError(true);
      }

    }
    if (status === "recce_rejected") {
      if (modificationRemark === "") {
        setRemarkError(true);
      }

    }
    if (remarkError) {
      return;
    }
    const prePareObj = {
      "retail_id": retail_id,
      "status": status,
      "modification_comments": remark,
      "club_name": clubSelected === "chairman_club" ? "Chairman's Club" : clubSelected === 'other_club' && retailDetailinfo.club_name !== "Chairman's Club" ? retailDetailinfo.club_name : "Other Club",
    };
    if (status === "instllation_approved" || status === "installation_pending" || status === "installation_modified") {
      RetailMarketingSystemService.addUpdateRetailHOStatus(prePareObj)
        .then((data) => {
          if (data.data && data.data.success) {
            swal({
              title: "Congratulations!",
              text: data.data.message,
              icon: "success",
              type: "success"
            }).then((isConfirm) => {

              goBack();
            });;
          }
          setIsLoading(false);

        })
        .catch((e) => {
          setIsLoading(false);
          const err =
            e.response && e.response.data && e.response.data.error
              ? e.response.data.error[0]
              : null;
          const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
          ModalService.openAlert("", message, "error");
        });
    }
    else {
      RetailMarketingSystemService.addUpdateRetail(prePareObject)
        .then((data) => {
          // setIsLoading(false);
          RetailMarketingSystemService.addUpdateRetailHOStatus(prePareObj)
            .then((data) => {
              if (data.data && data.data.success) {
                swal({
                  title: "Congratulations!",
                  text: data.data.message,
                  icon: "success",
                  type: "success"
                }).then((isConfirm) => {

                  goBack();
                });;
              }
              setIsLoading(false);

            })
            .catch((e) => {
              setIsLoading(false);
              const err =
                e.response && e.response.data && e.response.data.error
                  ? e.response.data.error[0]
                  : null;
              const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
              ModalService.openAlert("", message, "error");
            });
        })
        .catch((e) => {
          setIsLoading(false);
        });

    }
    if (status === "recce_modified") {
      if (modificationRemark === "") {
        setRemarkError(true);
      }

    }
    if (status === "recce_rejected") {
      if (modificationRemark === "") {
        setRemarkError(true);
      }

    }
    if (remarkError) {
      return;
    }



  }

  const clubToggle = (clubName) => {

    setClubSelected(clubName);
  };
  return (
    <>
      <div id="retailMarketingSystem">
        {isLoading && <CustomSpinner />}
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex">
            <img
              src="images/right.svg"
              alt=""
              style={{ transform: "rotate(180deg)" }}
              onClick={() => {
                if (!screenState) {
                  goBackToVendor();
                }
                else {
                  goBack()
                }

              }}
            />
            &nbsp;&nbsp;
            <Label
              className="cursor-pointer back-lbl"
              onClick={() => {
                if (!screenState) {
                  goBackToVendor();
                }
                else {
                  goBack()
                }

              }}
            >
              Back
            </Label>
          </div>
          <Row>
            <Col>
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && !hoInstallation && retailDetailinfo.status && retailDetailinfo.status !== 'installation_completed' && retailDetailinfo.status !== 'recce_rejected' && retailDetailinfo.status !== 'installation_pending' && retailDetailinfo.status !== 'recce_modified' && !vendorView && <span
                style={{ float: "right", width: '123px', whiteSpace: 'nowrap', marginTop: '12px' }}
                className="link-warm-blue cursor-pointer "
                onClick={() => {
                  toggle();
                }}
              >
                Modification Required
              </span>}

            </Col>
            <Col>
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && hoInstallation && <span
                style={{ float: "right", width: '123px', whiteSpace: 'nowrap', marginTop: '12px' }}
                className="link-warm-blue cursor-pointer "
                onClick={() => {
                  toggle();
                }}
              >
                Modification Required
              </span>}

            </Col>
            <Col>
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && !hoInstallation && retailDetailinfo.status && retailDetailinfo.status !== 'installation_completed' && retailDetailinfo.status !== 'recce_rejected' && retailDetailinfo.status !== 'installation_pending' && retailDetailinfo.status !== 'recce_modified' && !vendorView && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '123px', background: '#E0E2E4', borderColor: '#F1F4F7' }}
                onClick={() => {
                  toggleModalRejection();
                }}
              >
                Reject Recce
              </Button>}
            </Col>
            <Col>
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && !hoInstallation && retailDetailinfo.status && retailDetailinfo.status !== 'installation_completed' && retailDetailinfo.status !== 'recce_rejected' && retailDetailinfo.status !== 'installation_pending' && retailDetailinfo.status !== 'recce_modified' && !vendorView && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '123px', background: '#E0E2E4', borderColor: '#F1F4F7' }}
                onClick={() => {
                  toggleModalApprove();
                }}
              >
                Approve Recce
              </Button>}
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== 'installation_modified' && retailDetailinfo.status !== 'instllation_approved' && hoInstallation && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", whiteSpace: 'nowrap', background: '#E0E2E4', borderColor: '#F1F4F7' }}
                onClick={() => {
                  toggleModalApprove();
                }}
              >
                Approve Installation
              </Button>}
            </Col>
            <Col>
              {!isEdit && openInstallationVendor && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '123px', background: '#E0E2E4', borderColor: '#F1F4F7' }}
                onClick={() => {
                  cancelInstallationDetail();
                }}
              >
                Cancel
              </Button>}
            </Col>
            <Col>
              {(!hoInstallation && !vendorView && !openInstallationVendor && retailDetailinfo.status && retailDetailinfo.status === 'installation_pending') && vendor && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '100%', background: '#FDBB1F', borderColor: '#F1F4F7', whiteSpace: 'nowrap' }}
                onClick={() => {
                  openInstallationDetail();
                }}
              >
                Complete Installation
              </Button>}
              {openInstallationVendor && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '100%', background: '#FDBB1F', borderColor: '#F1F4F7', whiteSpace: 'nowrap' }}
                onClick={() => {
                  submitInstallation();

                }}
              >
                {isEdit ? "Re-Submit Installation Details" : "Submit Installation Details"}
              </Button>}

            </Col>
          </Row>
        </div>
        <div className="header-title">{title}</div>
        {/* Stepper  */}
        <MultistepForRMS
          status={retailDetailinfo.status}
          updateAt={retailDetailinfo.updated_at}
        />
        {retailDetailinfo.status !== "recce_pending" && retailDetailinfo.modification_comments &&
          <Row style={{ marginTop: "56px" }}>
            <div style={{ width: "47.8%", marginLeft: '14px', marginBottom: '8px' }} className="service-pref-note d-flex-column ReferralRewardsPreferenceNote ho-remark mt-0">
              <Row>
                <Col sm="1" style={{ paddingTop: '4px' }}>
                  <img
                    src="images/group-30.png"
                    alt=""
                    className="Group-3 "

                  />
                </Col>
                <Col style={{ marginLeft: "-15px" }}>
                  <div style={{ fontFamily: 'Helvetica', fontSize: "12px", fontStyle: "normal", lineHeight: '14px', color: '#6E777F', fontWeight: 'normal' }}>Remarks by HO</div>
                  <div style={{ fontFamily: 'Helvetica', fontSize: "14px", fontStyle: "normal", lineHeight: '16px', color: '#495057', fontWeight: 'normal' }}>{retailDetailinfo.modification_comments}</div>
                </Col>
              </Row>
            </div>
          </Row>
        }
        {
          otherRecceInfo.length > 0 && screenState && <RetailOtherRecceInformation otherRecceInfo={otherRecceInfo} status={retailDetailinfo.status} />
        }
        <Row style={{ marginTop: '12px' }} className={retailDetailinfo.status === "recce_pending" ? "ho-remark" : ""}>
          <Col>
            <div className="pasanger-car-redial-card" style={{ padding: "24px", height: '100%' }}>
              <div className="dealer-info">
                Dealer Information
              </div>
              <div style={{ marginTop: "24px" }}>
                Recce Date
              </div>
              <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                <Moment format="DD-MM-YYYY">
                  {delearInfo.recce_date}
                </Moment>
              </div>
              <div style={{ marginTop: "32px" }}>
                <Row >
                  <Col>
                    <div>
                      Name of the Store
                    </div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {delearInfo.business_name}
                    </div>
                  </Col>
                  <Col>
                    <div>
                      SAP Code
                    </div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {delearInfo.dealer_code}
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Club Name
                    </div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {retailDetailinfo.club_name}
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{ marginTop: "32px" }}>
                <div>
                  <div>
                    Address
                  </div>
                  <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                    {delearInfo.address}, {delearInfo.city}, {delearInfo.district}, {delearInfo.state}, {delearInfo.pin_code}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {!openInstallationVendor && <Col>
            <div className="pasanger-car-redial-card" style={{ padding: "24px", height: '100%' }}>
              <div className="dealer-info">
                Vendor Information
              </div>
              <div style={{ marginTop: "24px" }}>
                Added Date
              </div>
              <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                <Moment format="DD-MM-YYYY">
                  {vendorInfo.added_at}
                </Moment>
              </div>
              <div style={{ marginTop: "32px" }}>
                <Row >
                  <Col>
                    <div>
                      Vendor Code
                    </div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {vendorInfo.vendor_code}
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Vendor Name
                    </div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {vendorInfo.vendor_name}
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{ marginTop: "32px" }}>
                <Row>
                  <Col>
                    <div>Zone</div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {vendorInfo.zone}
                    </div>
                  </Col>
                  <Col>
                    <div>Region</div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {vendorInfo.region}
                    </div>
                  </Col>
                  <Col>
                    <div>State GST</div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {vendorInfo.state_gst}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ whiteSpace: 'nowrap' }}>JK Region Code</div>
                    <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                      {vendorInfo.region_code}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>}
        </Row>
        {(openInstallationVendor || hoInstallation || vendorInstallationModification || isInstallationApproved || retailDetailinfo.status === 'installation_modified') && (retailDetailinfo.status !== 'installation_pending' || openInstallationVendor) && screenStatus !== 'recce_pending' && <div style={{ marginTop: '16px' }}> <RetailMarktingUploadPhotograph installationModification={vendorInstallationModification} installation={true} hoInstallation={hoInstallation} isApproved={isInstallationApproved} /> </div>}
        {!openInstallationVendor && retailDetailinfo.status !== 'instllation_approved' && retailDetailinfo.status !== 'installation_completed' && retailDetailinfo.status !== 'installation_modified' && <div className="pasanger-car-redial-card" style={{ marginTop: '16px', padding: "24px" }}>
          <div className="dealer-info">
            Recce Details
          </div>
          <div style={{ marginTop: "24px" }}>
            Name on the Signage
          </div>
          <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
            {retailDetailinfo.signage_name}
          </div>
          <div style={{ marginTop: '40px', display: 'flex' }}>
            <div style={{ fontWeight: 'bold' }}>
              Proposed Sizes for New Signage
            </div>&nbsp;&nbsp;&nbsp;
            <div style={{ fontFamily: 'Helvetica', fontWeight: 'normal', fontStyle: 'italic' }}>
              Note : To preview the image, click on the image.
            </div>
          </div>
          <RetailMarktingViewUploadPhotographHO isEdit="false" />
        </div>}
        {!openInstallationVendor && <div className="pasanger-car-redial-card" style={{ marginTop: '16px', padding: "24px" }}>
          <div style={{ fontFamily: 'Helvetica', fontWeight: '700', marginBottom: '24px' }}>
            Price Calculations
          </div>
          <Row style={{ paddingLeft: '0px' }}>
            {(retailDetailinfo.status === "recce_pending" || retailDetailinfo.status === "recce_modified") ? <>
              <Col sm="2">
                <Button
                  className={(clubSelected === "chairman_club") ? "mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold" : "other-club"}
                  color="warning"
                  type="submit"
                  style={{ float: "left", width: '123px', fontWeight: 'bold' }}
                  onClick={() => clubToggle("chairman_club")}
                >
                  Chairman Club
                </Button>
              </Col>
              <Col sm="2" style={{ marginLeft: '-2%' }}>
                <Button
                  className={clubSelected !== `chairman_club` ? "mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold" : "other-club"}
                  color="warning"
                  type="submit"
                  style={{ float: "left", width: '123px', borderColor: '#F1F4F7', fontWeight: 'bold' }}
                  onClick={() => clubToggle("other_club")}
                >
                  Other Club
                </Button>
              </Col> </> : <Col sm="2" md="2" xl="2">
              <div>
                Club Name
              </div>
              <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                {retailDetailinfo.club_name}
              </div>
            </Col>}
            <Col sm="2">
              <div>Price for Singage</div>
              <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                INR {clubSelected === "chairman_club" ? signagePriceChairmanClub : signagePriceOtherClub}
              </div>
            </Col>
            <Col sm="2">
              <div>Price of Canopy</div>
              <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                INR {clubSelected === "other_club" ? canopyPriceOtherClub : canopyPriceChairmanClub}
              </div>
            </Col>
            <Col sm="2">
              <div>Total Price</div>
              <div style={{ marginTop: "8px", fontWeight: 'bold' }}>
                INR {clubSelected === "chairman_club" ? (Number((parseFloat(signagePriceChairmanClub) + parseFloat(canopyPriceChairmanClub))).toFixed(2)) : (Number((parseFloat(signagePriceOtherClub) + parseFloat(canopyPriceOtherClub))).toFixed(2))}
              </div>
            </Col>
          </Row>
        </div>}
        <Row style={{ marginTop: "56px" }}>
          <Col>
            <div className="pasanger-car-redial-card" style={{ padding: "24px" }}>
              <div className="dealer-info">
                Dealer Acknowledgement on Recce Size
              </div>
              <div style={{ marginTop: "24px" }}>
                {
                  storeDetailControls.acknowledgementForm.value ?
                    <div className="d-flex-row">
                      <img src="/images/file-img.png" alt="" className="mt-auto mb-auto cursor-pointer"
                        style={{ height: '19px' }} />
                      <div className="add-more ml-2 cursor-pointer"
                      >
                        <span onClick={() =>
                          window.open(storeDetailControls.acknowledgementForm.value, '_blank')
                        } style={{ color: '#545CD8', fontFamily: 'Helvetica', fontWeight: 'bold', fontSize: '16px' }}>
                          View Document
                        </span>
                      </div>
                    </div>
                    :
                    <div className="d-flex-row">

                    </div>
                }
              </div>
            </div>
          </Col>
          <Col>
            <div className="pasanger-car-redial-card" style={{ padding: "24px" }}>
              <div className="dealer-info">
                Vendor Recce Comments
              </div>
              <div style={{ marginTop: "24px", fontStyle: 'italic' }}>
                {retailDetailinfo.vendor_comment ? retailDetailinfo.vendor_comment : `" "`}
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-between mb-2" style={{ marginTop: '41px' }}>
          <div className="d-flex">
            &nbsp;&nbsp;
            <Label
              className="cursor-pointer back-lbl"

            >

            </Label>
          </div>
          <Row>
            <Col>
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && !hoInstallation && retailDetailinfo.status && retailDetailinfo.status !== 'installation_completed' && retailDetailinfo.status !== 'recce_rejected' && retailDetailinfo.status !== 'installation_pending' && retailDetailinfo.status !== 'recce_modified' && !vendorView && <span
                style={{ float: "right", width: '123px', whiteSpace: 'nowrap', marginTop: '12px' }}
                className="link-warm-blue cursor-pointer "
                onClick={() => {
                  toggle();
                }}
              >
                Modification Required
              </span>}

            </Col>
            <Col>
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && hoInstallation && <span
                style={{ float: "right", width: '123px', whiteSpace: 'nowrap', marginTop: '12px' }}
                className="link-warm-blue cursor-pointer "
                onClick={() => {
                  toggle();
                }}
              >
                Modification Required
              </span>}

            </Col>
            <Col>
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && !hoInstallation && retailDetailinfo.status && retailDetailinfo.status !== 'installation_completed' && retailDetailinfo.status !== 'recce_rejected' && retailDetailinfo.status !== 'installation_pending' && retailDetailinfo.status !== 'recce_modified' && !vendorView && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '123px', background: '#E0E2E4', borderColor: '#F1F4F7' }}
                onClick={() => {
                  toggleModalRejection();
                }}
              >
                Reject Recce
              </Button>}
            </Col>
            <Col>
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && !hoInstallation && retailDetailinfo.status && retailDetailinfo.status !== 'installation_completed' && retailDetailinfo.status !== 'recce_rejected' && retailDetailinfo.status !== 'installation_pending' && retailDetailinfo.status !== 'recce_modified' && !vendorView && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '123px', background: '#E0E2E4', borderColor: '#F1F4F7' }}
                onClick={() => {
                  toggleModalApprove();
                }}
              >
                Approve Recce
              </Button>}
              {retailDetailinfo.status !== "installation_pending" && retailDetailinfo.status !== "instllation_approved" && retailDetailinfo.status !== 'installation_modified' && hoInstallation && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", whiteSpace: 'nowrap', background: '#E0E2E4', borderColor: '#F1F4F7' }}
                onClick={() => {
                  toggleModalApprove();
                }}
              >
                Approve Installation
              </Button>}
            </Col>
            <Col>
              {!isEdit && openInstallationVendor && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '123px', background: '#E0E2E4', borderColor: '#F1F4F7' }}
                onClick={() => {
                  cancelInstallationDetail();
                }}
              >
                Cancel
              </Button>}
            </Col>
            <Col>
              {!hoInstallation && !vendorView && !openInstallationVendor && retailDetailinfo.status && retailDetailinfo.status === 'installation_pending' && vendor && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '100%', background: '#FDBB1F', borderColor: '#F1F4F7', whiteSpace: 'nowrap' }}
                onClick={() => {
                  openInstallationDetail();
                }}
              >
                Complete Installation
              </Button>}
              {openInstallationVendor && <Button
                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                color="warning"
                type="submit"
                style={{ float: "right", width: '100%', background: '#FDBB1F', borderColor: '#F1F4F7', whiteSpace: 'nowrap' }}
                onClick={() => {
                  submitInstallation();
                }}
              >
                {isEdit ? "Re-Submit Installation Details" : "Submit Installation Details"}
              </Button>}

            </Col>
          </Row>
        </div>
        <Modal
          isOpen={modalModification}
          toggle={toggle}
          centered
          id="invite-dealer-modal"
        >
          <ModalBody>
            <Row className="modal-title-border padding-bottom-10">
              <Col className="modal-title">Add Remark</Col>
            </Row>
            <div style={{ marginBottom: '8px', marginTop: '34px' }}>Please provide your remarks<span className="required-field">*</span></div>
            <div>
              <textarea name="horemarks"
                autocomplete="nofill"
                id="horemarks" onChange={(e) =>
                  handleInputChange({
                    target: { name: "horemarks", value: e.target.value },
                  })
                } value={storeDetailControls.horemarks.value} rows="5" cols="57" style={{ background: 'rgba(253, 187, 31, 0.100415)', border: '1px solid #FDBB1F', boxSizing: 'border-box', borderRadius: '4px' }}>

              </textarea>
              {storeDetailControls.horemarks.showErrorMsg && (<div className="error"
                style={{ marginLeft: '15px' }}>
                * Please provide remark.
              </div>
              )}
            </div>
            <div style={{ textAlign: "right", marginTop: '34px' }}>
              <Button style={{ width: '116px' }} className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" color="dark" onClick={toggle}>Cancel</Button>{' '}
              <Button style={{ width: '116px' }} className="mb-1 mr-1 btn-pill Rectangle-button send-button" color="warning" onClick={() => {
                if (storeDetailControls.horemarks.value === "") {
                  const controlName = "horemarks"
                  storeDetailControls[controlName].showErrorMsg = true;
                  storeDetailControls[controlName].touched = true;
                  setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
                  handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
                }
                if (!storeDetailControls.horemarks.showErrorMsg && storeDetailControls.horemarks.value !== "") {
                  toggle();
                  const Modifiedstatus = hoInstallation ? "installation_modified" : "recce_modified"
                  submit(storeDetailControls.horemarks.value, retailDetailinfo.retail_id, Modifiedstatus, retailDetailinfo.club_name);
                }
              }}>Submit</Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modalRejection}
          toggle={toggleModalRejection}
          centered
          id="invite-dealer-modal"
        >
          <ModalBody>
            <Row className="modal-title-border padding-bottom-10">
              <Col className="modal-title">Add Remark</Col>
            </Row>
            <div style={{ marginBottom: '8px', marginTop: '34px' }}>Please provide your remarks<span className="required-field">*</span></div>
            <div>
              <textarea name="horemarkReject"
                autocomplete="nofill"
                id="horemarkReject" onChange={(e) =>
                  handleInputChange({
                    target: { name: "horemarkReject", value: e.target.value },
                  })
                } value={storeDetailControls.horemarkReject.value} rows="5" cols="57" style={{ background: 'rgba(253, 187, 31, 0.100415)', border: '1px solid #FDBB1F', boxSizing: 'border-box', borderRadius: '4px' }}>

              </textarea>
              {storeDetailControls.horemarkReject.showErrorMsg && (<div className="error"
                style={{ marginLeft: '15px' }}>
                * Please provide remark.
              </div>
              )}
            </div>
            <div style={{ textAlign: "right", marginTop: '34px' }}>
              <Button style={{ width: '116px' }} className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" color="dark" onClick={toggleModalRejection}>Cancel</Button>{' '}
              <Button style={{ width: '116px' }} className="mb-1 mr-1 btn-pill Rectangle-button send-button" color="warning" onClick={() => {
                if (storeDetailControls.horemarkReject.value === "") {
                  const controlName = "horemarkReject"
                  storeDetailControls[controlName].showErrorMsg = true;
                  storeDetailControls[controlName].touched = true;
                  setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
                  handleValidationRetailMarketingStoreInformationAction(false, storeDetailControls);
                }
                const rejectStatus = "recce_rejected";
                if (!storeDetailControls.horemarkReject.showErrorMsg && storeDetailControls.horemarkReject.value !== "") {
                  toggleModalRejection();
                  submit(storeDetailControls.horemarkReject.value, retailDetailinfo.retail_id, rejectStatus, retailDetailinfo.club_name);
                }
              }}>Submit</Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modalApprove}
          toggle={toggleModalApprove}
          centered
          id="invite-dealer-modal"
        >
          <ModalBody>
            <Row className="modal-title-border padding-bottom-10">
              <Col className="modal-title">Add Remark</Col>
            </Row>
            <div style={{ marginBottom: '8px', marginTop: '34px' }}>Please provide your remarks</div>
            <div>
              <textarea name="horemarksApprove"
                autocomplete="nofill"
                id="horemarksApprove" onChange={(e) =>
                  handleInputChange({
                    target: { name: "horemarksApprove", value: e.target.value },
                  })
                } value={storeDetailControls.horemarksApprove.value} rows="5" cols="57" style={{ background: 'rgba(253, 187, 31, 0.100415)', border: '1px solid #FDBB1F', boxSizing: 'border-box', borderRadius: '4px' }}>

              </textarea>
            </div>
            <div style={{ textAlign: "right", marginTop: '34px' }}>
              <Button style={{ width: '116px' }} className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" color="dark" onClick={toggleModalApprove}>Cancel</Button>{' '}
              <Button style={{ width: '116px' }} className="mb-1 mr-1 btn-pill Rectangle-button send-button" color="warning" onClick={() => {
                const approveStatus = hoInstallation ? "instllation_approved" : "installation_pending";
                toggleModalApprove();
                submit(storeDetailControls.horemarksApprove.value, retailDetailinfo.retail_id, approveStatus, retailDetailinfo.club_name);
              }}>Submit</Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}

RecceProgressStatus.propTypes = {
  storeDetailControls: PropTypes.instanceOf(Object).isRequired,
  hoRecceControls: PropTypes.string.isRequired,
  setRetailRecciControlsAction: PropTypes.func.isRequired,
  setHoRecceControlsAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    storeDetailControls: getRetailMarketingStoreInformationState(state),
    recceStatus: getRecceStatus(state),
    hoRecceControls: getHoRecceModification(state),
    vendorScreen: getVendorScreen(state),
    duplicateRecce: getDuplicateRecce(state),
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setRetailRecciControlsAction: setRetailRecciControls,
      setRecceStatusAction: setRecceStatus,
      handleValidationRetailMarketingStoreInformationAction: handleValidationRetailMarketingStoreInformation,
      setRetailRecciDetailAction: setRetailRecciDetail,
      resetStoreDetailControlsAction: resetStoreDetailControls,
      setHoRecceControlsAction: setHoRecceControls,
    },
    dispatch,
  );

export default connect(mapStateToProps,
  mapDispatchToProps,)(RecceProgressStatus);