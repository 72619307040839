import { request } from "./Request";
import { routes } from "../constants/constant.route";
import StorageService from "../services/Storage";
import { Roles } from "../constants/constant.role";

const dashBoardRoutes = routes.DASHBOARD;
const {
  DOWNLOAD_SCORE_CARD,
  GET_DISPLAY_LABEL,
  NAVBAR_TOHIDE,
  FILTERAPI,
  GET_HELP_MODULE_SCREEN,
  ADD_HELP_MODULE_SCREEN,
} = dashBoardRoutes;
const {
  GET_USER_INFO
} = routes.USER;
const uploadRoutes = routes.UPLOAD;
const { UPLOAD_SALES_REPORT } = uploadRoutes;

const API_URL = process.env.REACT_APP_API_URL;

export default class DashboardService {
  static getSecondaryCounts(year, sapId) {
    let qp = "?";
    if (year) {
      qp += `y=${year}`;
    }
    if (StorageService.getUserDetail() && StorageService.getUserDetail().type !== Roles.DEALER_ROLE) {
      if (sapId && year) {
        qp += `&sap_id=${sapId}`;
      } else if (sapId && !year) {
        qp += `sap_id=${sapId}`;
      }
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.SECONDARY_COUNT}${qp}`,
      null,
      null,
      null
    );
  }
  static getSecondaryCountsV2(year, sapId, isSensor, from_date,
    to_date,
    sub_dealer,
    type) {
    let qp = "?";
    let qp1 = `?y=${year}&sap_id=${sapId}&vehicle_type=${type === null ? '' : type}&sub_dealer_branch=${sub_dealer === null ? '' : sub_dealer}&from_date=${(from_date === null || from_date === undefined) ? '' : from_date}&to_date=${(to_date === null || to_date === undefined) ? '' : to_date}`
    if (year) {
      qp += `y=${year}`;
    }
    if (StorageService.getUserDetail() && StorageService.getUserDetail().type !== Roles.DEALER_ROLE) {
      if (sapId && year) {
        qp += `&sap_id=${sapId}`;
      } else if (sapId && !year) {
        qp += `sap_id=${sapId}`;
      }
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.SECONDARY_COUNT_DASBOARD_COUNT_v2}${(to_date || from_date || type || sub_dealer) ? qp1 : qp}`,
      null,
      null,
      null
    );
  }


  // static getDashboardCountForFilter(
  //   year,
  //   sapId,
  //   isSensor,
  //   from_date,
  //   to_date,
  //   sub_dealer,
  //   type) {
  //   let 
  //   return request(
  //     "GET",
  //     `${API_URL}${dashBoardRoutes.SECONDARY_COUNT_DASBOARD_COUNT_v2}${qp}`,
  //     null,
  //     null,
  //     null
  //   );
  // }

  static getYtd(year) {
    let qp = "?";
    if (year) {
      qp += `y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.YTD}${qp}`,
      null,
      null,
      null
    );
  }

  static getQtd(year) {
    let qp = "?";
    if (year) {
      qp += `y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.QTD}${qp}`,
      null,
      null,
      null
    );
  }

  static getMtd(year) {
    let qp = "?";
    if (year) {
      qp += `y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.MTD}${qp}`,
      null,
      null,
      null
    );
  }

  static getPrimaryGraph(year) {
    let qp = "?";
    if (year) {
      qp += `y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.PRIMARY_GRAPH}${qp}`,
      null,
      null,
      null
    );
  }

  static getPrimaryPoints(userId, year) {
    let qString = ``;
    if (userId) {
      qString += `?u=${userId}`;
    }
    if (year && !userId) {
      qString = `?y=${year}`;
    } else if (year && userId) {
      qString += `&y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.PRIMARY_POINTS}${qString}`,
      null,
      null,
      null
    );
  }

  static getYearlyOfftake(year) {
    let qp = "?";
    if (year) {
      qp += `y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.YEARLY_OFFTAKE}${qp}`,
      null,
      null,
      null
    );
  }

  static getRedeemData(year) {
    let qp = "?";
    if (year) {
      qp += `y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.GET_REDEEM_DATA}${qp}`,
      null,
      null,
      null
    );
  }

  static getVideoUrl() {
    return request(
      "POST",
      `${API_URL}${dashBoardRoutes.VIDEO_URL}`,
      null,
      null,
      null
    );
  }

  static getEmployeeDashboardPrimaryPoints(search, year) {
    let qp = ``;
    if (search) {
      qp += `?q=${search}`;
    }
    if (year && !search) {
      qp += `?y=${year}`;
    } else if (year && search) {
      qp += `&y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.GET_EMPLOYEE_DASHBOARD_PRIMARY_POINTS}${qp}`,
      null,
      null,
      null
    );
  }

  static uploadCsv(obj) {
    const headers = {
      "content-type": "multipart/form-data",
    };
    return request(
      "POST",
      `${API_URL}${UPLOAD_SALES_REPORT}`,
      null,
      obj,
      headers
    );
  }

  static downloadScoreCard(filter) {
    let qp = "";
    if (filter.y) {
      qp += `&year=${filter.y}`;
    }
    return request(
      "GET",
      `${API_URL}${DOWNLOAD_SCORE_CARD}${qp}`,
      null,
      null,
      null
    );
  }

  static getDisplayLabel(year) {
    let qp = ``;
    if (year) {
      qp += `?y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_DISPLAY_LABEL}${qp}`,
      null,
      null,
      null
    );
  }

  static navBarToHide() {
    return request("GET", `${API_URL}${NAVBAR_TOHIDE}`, null, null, null);
  }

  static getEngageCustomer() {
    return request("GET", `${API_URL}${dashBoardRoutes.ENGAGE_CUSTOMER}`, null, null, null);
  }

  static getGrowBusiness() {
    return request("GET", `${API_URL}${dashBoardRoutes.GROW_BUSINESS}`, null, null, null);
  }

  static getDashboardCounts(reqData) {
    return request("POST", `${API_URL}${dashBoardRoutes.DASHBOARD_COUNTS}`, null, reqData, null);
  }

  static getBanner() {
    return request("GET", `${API_URL}${dashBoardRoutes.GET_BANNER}`, null, null, null);
  }

  static getRedeemptionDashbaord() {
    return request("GET", `${API_URL}${dashBoardRoutes.GET_REDEEMPTION_DASHBOARD}`, null, null, null);
  }

  static getDashBoardCountsMerchandise() {
    return request('GET', `${API_URL}${routes.MERCHANDISE_WALLET.GET_DASHOBARD_COUNTS}`, null, null, null);
  }

  static sendOTP(data) {
    return request("POST", `${API_URL}${routes.WARRANTY_REGISTRATION.GENERATE_OTP}`, null, data, null);
  }

  static getVehicleType() {
    return request("GET", `${API_URL}${routes.CUSTOMER_MGT.GET_VEHICLE_TYPE}`, null, null, null);
  }

  static getNewDashboardOfftake(reqData) {
    return request("GET", `${API_URL}${dashBoardRoutes.NEW_DASHBOARD_TOTAL_OFFTAKE}`, reqData, null, null);
  }
  static bannerViewClick(reqData) {
    return request("POST", `${API_URL}${dashBoardRoutes.BANNER_VIEW_CLICK}`, null, reqData, null);
  }

  static getPoints(memberId, zone, year) {
    let qp = "";
    if (memberId) {
      qp = `?u=${memberId}`;
    }
    if (zone) {
      qp += `&zone=${zone}`;
    }
    if (year) {
      qp += `&y=${year}`;
    }
    return request(
      "GET",
      `${API_URL}${dashBoardRoutes.GET_POINTS}${qp}`,
      null,
      null,
      null
    );
  }

  static filterAPI(filter) {
    let qp = "?";
    if (filter.y) {
      qp += `y=${filter.y}`;
    }
    if (filter.q) {
      qp += `&q=${filter.q}`;
    }
    if (filter.m) {
      qp += `&m=${filter.m}`;
    }
    return request("GET", `${API_URL}${FILTERAPI}${qp}`, null, null, null);
  }

  static getHelpModuleScreen() {
    return request(
      "GET",
      `${API_URL}${GET_HELP_MODULE_SCREEN}`,
      null,
      null,
      null
    );
  }

  static addHelpModuleScreen(reqData) {
    return request(
      "POST",
      `${API_URL}${ADD_HELP_MODULE_SCREEN}`,
      null,
      reqData,
      null
    );
  }

  static getUserInfoByUUId(id) {
    let qp = "?";
    if (id) {
      qp += `id=${id}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_USER_INFO}${qp}`,
      null,
      null,
      null
    );
  }
}
