import React, { Component } from 'react';
import {
  Button,
  Row, Col,
  Table,
  Card, CardBody
} from 'reactstrap';
import AddCipCoAttendee from './AddCipCoAttendee';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import swal from 'sweetalert';

class CipListOfAttendees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cip_co_attendee_list: [],
      isDsipalyAddCoAttendee: false
    }
  }

  componentDidMount = () => {
    // if (this.props.cip_old_co_attendee_list && this.props.cip_old_co_attendee_list.length > 0) {
    //   this.setState({
    //     cip_co_attendee_list: this.props.cip_old_co_attendee_list
    //   })
    // } else if (this.props.cip_detail && this.props.cip_detail.id) {
    //   this.getCoAttendeeList();
    // }
  }

  init = () => {
    this.getCoAttendeeList();
  }

  getCoAttendeeList = () => {
    let reqData = {
      cip_detail_id: this.props.cip_detail.id,
      page: 1, pagesize: 200
    }
    this.setState({
      isLoading: true
    })
    CustomerIntractionServie.getCipCOAttendeeSummary(reqData)
      .then(data => {
        let { cip_co_attendee_list } = this.state;
        cip_co_attendee_list = data.data.data.data;
        this.setState({
          cip_co_attendee_list,
          isLoading: false
        })
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }


  nextBtnCoAttendee = () => {
    this.props.nextBtnCoAttendee();
  }
  openCoAttendee = (row = {}) => {
    this.refs.refAddCipCoAttendee.toggle(row);
  }

  deleteCIPCoAttendee = (data) => {
    swal({
      title: "",
      text: "Are you sure you want to delete this Co-Attendee ?",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(isConfirm => {
      if (isConfirm) {
        this.confirmDeleteCIPCoAttendee(data);
      }
    });

  }

  confirmDeleteCIPCoAttendee = (data) => {
    let reqData = {
      uuid: data.uuid
    }
    this.setState({ isLoading: true });
    CustomerIntractionServie.deleteCIPCoAttendee(reqData)
      .then(res => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.getCoAttendeeList();
        this.setState({ isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }


  render() {
    return (
      <Card>
        <CardBody>
          {this.state.isLoading && <CustomSpinner />}
          <Row>
            <Col xl="6" md="12" xs="12" className="mt-auto mb-3">
              <b className="margin-right-16 font-size-18px">List of Co-attendees</b>
            </Col>
            <Col xl="6" md="12" xs="12"
              className="text-align-right d-flex"
            >
              <Button
                className="ml-auto mt-auto btn-pill action-button font-weight-bold mb-3"
                color="warning"
                style={{ width: '180px' }}
                onClick={() => this.openCoAttendee()}
              >
                Add Co-Attendee
              </Button>
            </Col>
            <Col xl="12" md="12" xs="12">
              <Table className="mb-0 table-responsive tbl-f6f6f6" bordered style={{ marginTop: 0 }}>
                <thead>
                  <tr className="tr-f6f6f">
                    <th>Mobile Number</th>
                    <th>Co-attendee</th>
                    <th>Designation</th>
                    <th>
                      Whatsapp Number
                    </th>
                    <th>Email Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (this.state.cip_co_attendee_list || []).map((item, i) => (
                      <tr key={i}>
                        <td>{item.mobile_number}</td>
                        <td className="text-capitalize">{item.name}</td>
                        <td className="text-capitalize">{item.designation}</td>
                        <td>{item.whatsapp_number ? item.whatsapp_number : '--'}</td>
                        <td>{item.email ? item.email : '--'}</td>
                        <td>
                          <div className="d-flex">
                            <img src="/images/edit.svg" alt="" style={{ height: '16px', width: '16px' }} onClick={() => this.openCoAttendee(item)}
                              className="mt-auto mb-auto" />&nbsp;&nbsp;
                            <img src="/images/delete.svg" alt=""
                              onClick={() => this.deleteCIPCoAttendee(item)}
                              className="cursor-pointer mt-auto mb-auto" style={{ height: '14px' }} />&nbsp;&nbsp;
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>

              {
                (this.state.cip_co_attendee_list || []).length <= 0 &&
                <div className="no-data-without-border">
                  <span className="mt-2">
                    There are no co-attendee added yet, If any person from the same organization is attending the CIP then kindly click on add Co-Attendee button to add the co-attendant information
                  </span>
                </div>

              }
            </Col>
            <Col xl="12" md="12" xs="12"
              className="text-align-right d-flex"
              style={{ marginTop: '5%' }}
            >
              <Button
                className="ml-auto mt-auto btn-pill action-button font-weight-bold mb-3"
                color="warning"
                style={{ width: '137px' }}
                onClick={() => this.nextBtnCoAttendee()}
              >
                {(this.state.cip_co_attendee_list || []).length <= 0 ? 'Skip' : 'Next'}
              </Button>
            </Col>
          </Row>
          {
            <AddCipCoAttendee
              cip_detail={this.props.cip_detail}
              openCoAttendee={this.openCoAttendee}
              getCoAttendeeList={this.getCoAttendeeList}
              ref="refAddCipCoAttendee" />
          }
        </CardBody>
      </Card>
    );
  }
}

export default CipListOfAttendees;