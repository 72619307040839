import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Validation from "../../services/Validation";
import Storage from "../../services/Storage";
import { Roles } from "../../constants/constant.role";
class ShowroomSizeForm extends Component {
  location = {
    main_road: false,
    commercial_area: false,
    tyre_auto_market: false,
    residental_area: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      showRoomSize: props.showRoomSize,
      outlet_type: "",
      isEdit: false,
      userDetail: Storage.getUserDetail(),
      isEnableEdit: true,
      isDisplayEditBtn: false,
    };

    if (
      this.state.userDetail &&
      this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE &&
      window.location.hash !== "#/proposal-details"
    ) {
      this.state.isDisplayEditBtn = true;
      this.state.isEnableEdit = false;
    }
  }

  closeEdit = () => {
    //let { isEnableEdit } = this.state;
    this.setState({
      isEnableEdit: false,
    });
    this.props.setDetail(true);
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state.showRoomSize;
    if (
      !isNaN(controlValue) ||
      controlName === "having_separate_storage" ||
      controlName === "other_business"
    ) {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    if (
      controlName === "showroom_depth" ||
      controlName === "showroom_frontage"
    ) {
      if (controls.showroom_frontage.value && controls.showroom_depth.value) {
        controls.showroom_area.value =
          parseInt(controls.showroom_frontage.value) *
          parseInt(controls.showroom_depth.value);
      } else {
        controls.showroom_area.value = "";
      }
    }
    this.calculateDealerShareAmmount();
    this.setState({ controls });
    this.handleValidation(false);
  };

  calculateDealerShareAmmount = () => {
    if (
      this.state.outlet_type === "Xpress Wheel" ||
      this.state.outlet_type === "Farm Xpress Wheel"
    ) {
      const { controls } = this.state.showRoomSize;
      if (controls.showroom_area.value || controls.front_face_area.value) {
        let dealer_share_amount =
          400 *
          (parseInt(
            controls.front_face_area.value ? controls.front_face_area.value : 0
          ) +
            controls.showroom_area.value);
        if (dealer_share_amount < 300000) {
          controls.dealer_share_amount.value = 300000;
        } else {
          controls.dealer_share_amount.value = dealer_share_amount;
        }
      } else {
        controls.dealer_share_amount.value = "";
      }
      this.setState({ controls });
    }
  };

  handleInputCheckboxChange = (e) => {
    const controlName = e.target.name;
    // const controlValue = e.target.value;
    const { controls } = this.state.showRoomSize;
    const { location } = controls;
    location[controlName].value = !location[controlName].value;
    location[controlName].touched = true;
    this.setState({ controls });
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state.showRoomSize;
    let {
      showroom_frontage,
      showroom_depth,
      showroom_celling_height,
      // showroom_area,
      storage_area,
      having_separate_storage,
      front_face_area,
    } = controls;
    if (showroom_frontage.touched === true || isSubmit) {
      showroom_frontage = Validation.notNullValidator(showroom_frontage);
      showroom_frontage.valid = !showroom_frontage.nullValue;
      if (showroom_frontage.valid === false) {
        showroom_frontage.showErrorMsg = true;
      } else {
        showroom_frontage.showErrorMsg = false;
      }
    }

    if (showroom_depth.touched === true || isSubmit) {
      showroom_depth = Validation.notNullValidator(showroom_depth);
      showroom_depth.valid = !showroom_depth.nullValue;
      if (showroom_depth.valid === false) {
        showroom_depth.showErrorMsg = true;
      } else {
        showroom_depth.showErrorMsg = false;
      }
    }

    if (showroom_celling_height.touched === true || isSubmit) {
      showroom_celling_height = Validation.notNullValidator(
        showroom_celling_height
      );
      showroom_celling_height.valid = !showroom_celling_height.nullValue;
      if (showroom_celling_height.valid === false) {
        showroom_celling_height.showErrorMsg = true;
      } else {
        showroom_celling_height.showErrorMsg = false;
      }
    }
    // if (showroom_area.touched === true || isSubmit) {
    //   showroom_area = Validation.notNullValidator(showroom_area);
    //   showroom_area.valid = !showroom_area.nullValue;
    //   if (showroom_area.valid === false) {
    //     showroom_area.showErrorMsg = true;
    //   } else {
    //     showroom_area.showErrorMsg = false;
    //   }
    // }
    if (storage_area.touched === true || isSubmit) {
      if (
        having_separate_storage.value === "true" ||
        having_separate_storage.value === true
      ) {
        storage_area = Validation.notNullValidator(storage_area);
        storage_area.valid = !storage_area.nullValue;
        if (storage_area.valid === false) {
          storage_area.showErrorMsg = true;
        } else {
          storage_area.showErrorMsg = false;
        }
      } else {
        storage_area.valid = true;
      }
    }
    front_face_area.valid = true;
    front_face_area.showErrorMsg = false;
    if (
      (front_face_area.touched === true || isSubmit) &&
      (this.state.outlet_type === "Xpress Wheel" ||
        this.state.outlet_type === "Farm Xpress Wheel")
    ) {
      front_face_area = Validation.notNullValidator(front_face_area);
      front_face_area.valid = !front_face_area.nullValue;
      if (front_face_area.valid === false) {
        front_face_area.showErrorMsg = true;
      } else {
        front_face_area.showErrorMsg = false;
      }
    }

    if (
      showroom_frontage.valid === true &&
      showroom_depth.valid === true &&
      showroom_celling_height.valid === true &&
      storage_area.valid === true &&
      front_face_area.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ dealership_Details: { controls, isFormValid } });
    return isFormValid;
  };

  setOutletType = (outlet_type_name) => {
    let { outlet_type } = this.state;
    let { controls } = this.state.showRoomSize;
    if (
      outlet_type_name !== "Xpress Wheel" &&
      outlet_type_name !== "Farm Xpress Wheel"
    ) {
      controls.front_face_area.value = "";
      controls.dealer_share_amount.value = "";
    }
    outlet_type = outlet_type_name;
    this.setState(
      {
        outlet_type,
      },
      () => {
        this.calculateDealerShareAmmount();
      }
    );
  };

  enableEdit = (e) => {
    let { isEnableEdit } = this.state;
    if (isEnableEdit) {
      this.props.editDeatil(e, "submit");
    }
    isEnableEdit = !isEnableEdit;
    this.setState({
      isEnableEdit,
    });
  };

  resetDetail = () => {
    this.setState({
      showRoomSize: this.props.showRoomSize,
    });
  };

  render() {
    const { controls } = this.state.showRoomSize;
    const { isEdit, isEnableEdit } = this.state;
    return (
      <>
        <Card id="form-showroomsize-detail">
          <CardBody>
            {/* Showroom size (in ft) */}
            <Row className="dealership-details-title-wrapp">
              <Col xl="6" md="6" xs="6">
                <b>Showroom Size And Service Area</b>
              </Col>
              <div>
                {this.state.isDisplayEditBtn &&
                  (this.state.isEnableEdit ? (
                    <>
                      <span
                        className="hyper-link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.enableEdit(e)}
                      >
                        Save
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="hyper-link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.enableEdit(e)}
                      >
                        Edit
                      </span>
                    </>
                  ))}
                {this.state.isDisplayEditBtn && this.state.isEnableEdit && (
                  <>
                    &nbsp;&nbsp;
                    <span
                      className="hyper-link"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.closeEdit()}
                    >
                      Cancel
                    </span>
                  </>
                )}
                &nbsp;&nbsp;
                {/* <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.goToPrevPage()}>Back</Button> */}
              </div>
            </Row>
            <hr />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Frontage (Ft)<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="showroom_frontage"
                      value={controls.showroom_frontage.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("showroom_frontage")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.showroom_frontage.showErrorMsg && (
                    <div className="error">*Please enter frontage (width).</div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Depth (Ft)<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="showroom_depth"
                      value={controls.showroom_depth.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("showroom_depth")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.showroom_depth.showErrorMsg && (
                    <div className="error">*Please enter depth.</div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Ceiling Height (Ft)<span className="required-field">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="showroom_celling_height"
                      value={controls.showroom_celling_height.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                    />
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEdit("showroom_celling_height")
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {controls.showroom_celling_height.showErrorMsg && (
                    <div className="error">*Please enter ceiling height.</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Total area of Showroom (in SqFt- Width x Depth)
                    <span className="required-field">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="showroom_area"
                    value={controls.showroom_area.value}
                    onChange={(e) => this.handleInputChange(e)}
                    disabled={true}
                  />
                  {/* {controls.showroom_area.showErrorMsg && <div className="error">*Please enter total area of showroom.</div>} */}
                </FormGroup>
              </Col>
              {(this.state.outlet_type === "Xpress Wheel" ||
                this.state.outlet_type === "Farm Xpress Wheel") && (
                  <>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup
                        className="form-group"
                        style={{ marginTop: "20px" }}
                      >
                        <Label>
                          Area of Front Fascia(in SqFt)
                          <span className="required-field">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="front_face_area"
                          value={controls.front_face_area.value}
                          onChange={(e) => this.handleInputChange(e)}
                          disabled={!isEnableEdit}
                        />
                        {controls.front_face_area.showErrorMsg && (
                          <div className="error">
                            *Please enter area of front fascia.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup
                        className="form-group"
                        style={{ marginTop: "20px" }}
                      >
                        <Label>Dealer Share Amount(in Rs)</Label>
                        <div className="d-flex">
                          <Input
                            type="text"
                            name="dealer_share_amount"
                            value={controls.dealer_share_amount.value}
                            onChange={(e) => this.handleInputChange(e)}
                            disabled={true}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                )}
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Availability separate storage area? (Yes/No), Please specify
                    the size in (SqFt)
                  </Label>
                  <div className="d-flex">
                    <select
                      className="form-control select-height-70rem"
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                      name="having_separate_storage"
                      value={controls.having_separate_storage.value}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {isEdit && (
                      <img
                        onClick={() =>
                          this.enableEdit("having_separate_storage")
                        }
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup className="form-group" style={{ marginTop: "20px" }}>
                  <Label>
                    Area (in SqFt):
                    {(controls.having_separate_storage.value === "true" ||
                      controls.having_separate_storage.value === true) && (
                        <span className="required-field">*</span>
                      )}
                  </Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="storage_area"
                      value={controls.storage_area.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("storage_area")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                  {(controls.having_separate_storage.value === "true" ||
                    controls.having_separate_storage.value === true) &&
                    controls.storage_area.showErrorMsg && (
                      <div className="error">*Please enter area (in sqft).</div>
                    )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="8" md="12" xs="12">
                <FormGroup>
                  <Label>
                    Location of the showroom (tick wherever applicable)
                  </Label>
                  <div className="d-flex-row">
                    <div>
                      <label className="custom-checkboax1--container">
                        <input
                          type="checkbox"
                          name="main_road"
                          checked={controls.location.main_road.value}
                          onChange={(e) => this.handleInputCheckboxChange(e)}
                        />
                        <span className="checkmark1" />
                      </label>
                      <span style={{ marginLeft: "10px" }}>Main Road</span>
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <label className="custom-checkboax1--container">
                        <input
                          type="checkbox"
                          name="commercial_area"
                          checked={controls.location.commercial_area.value}
                          onChange={(e) => this.handleInputCheckboxChange(e)}
                        />
                        <span className="checkmark1" />
                      </label>
                      <span style={{ marginLeft: "10px" }}>
                        Commercial Area
                      </span>
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <label className="custom-checkboax1--container">
                        <input
                          type="checkbox"
                          name="tyre_auto_market"
                          checked={controls.location.tyre_auto_market.value}
                          onChange={(e) => this.handleInputCheckboxChange(e)}
                        />
                        <span className="checkmark1" />
                      </label>
                      <span style={{ marginLeft: "10px" }}>
                        Tyre/ Auto Market
                      </span>
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <label className="custom-checkboax1--container">
                        <input
                          type="checkbox"
                          name="residental_area"
                          checked={controls.location.residental_area.value}
                          onChange={(e) => this.handleInputCheckboxChange(e)}
                        />
                        <span className="checkmark1" />
                      </label>
                      <span style={{ marginLeft: "10px" }}>
                        Residental Area
                      </span>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody>
            {/* Financial Background */}
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Financial Background</b>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Value of Assets (Lac.)</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="assets_value"
                      value={controls.assets_value.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("assets_value")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Any Other Business</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="other_business"
                      value={controls.other_business.value}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!isEnableEdit}
                    />
                    {isEdit && (
                      <img
                        onClick={() => this.enableEdit("other_business")}
                        style={{ height: "14px" }}
                        className="cursor-pointer ml-2 mt-2"
                        src="images/edit.svg"
                        alt=''
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default ShowroomSizeForm;
