import React, { Component, Fragment } from 'react';
import { Table, Card, CardBody, Button, Row, Col, Input, FormGroup } from 'reactstrap';

import GiftCatalogService from '../../services/GiftCatalogService';

import UploadGiftProductImage from '../../modal/UploadGiftProductImage/UploadGiftProductImage';
import UploadGiftItems from '../../modal/UploadGiftItems/UploadGiftItems';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

import Pagination from '../../components/Pagination/Pagination';

const pageSize = 20;

class GiftItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      selectedIdToUploadImage: null,
      isUploadImageModalOpen: false,
      isUploadItemModalOpen: false,
      page: 1,
      totalProducts: 0,
      search: '',
      isLoading: false,
      controls: {
        status: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
      },
    }
  }

  componentDidMount() {
    this.getProductList(1);
  }

  handlePageChange = async (page) => {
    this.setState({ page: page, isLoading: true });
    await this.getProductList(page);
    this.setState({ isLoading: false })
  }

  getProductList = async (page) => {
    const dataRes = await GiftCatalogService.getProductList(page, pageSize, this.state.search, this.state.controls.status.value)
    if (dataRes) {
      let productsList = dataRes.data && dataRes.data.data ? dataRes.data.data : null;
      if (productsList) {
        let products = productsList.products;
        let count = productsList.count;
        this.setState({ products, totalProducts: count });
      }
    }
    // .then(data => {
    //   let productsList = data.data && data.data.data ? data.data.data : null;
    //   if (productsList) {
    //     let products = productsList.products;
    //     let count = productsList.count;
    //     this.setState({ products, totalProducts: count });
    //   }
    // })
  }

  handleRadioChange(e) {
    let { controls } = this.state;
    controls.status.value = e;
    this.setState({ controls });
    this.handlePageChange(1)
  }

  updateDefaultImage = async (body, id) => {
    this.setState({ isLoading: true });
    await GiftCatalogService.updateDefaultImage(id, body)
      .then(data => {
        // let products = data.data && data.data.data ? data.data.data : []
        // this.setState({ products });
      })
      .catch(e => {

      })
    this.setState({ isLoading: false });
  }

  openUploadImageModal = (id) => {
    this.setState({ isUploadImageModalOpen: true, selectedIdToUploadImage: id });
  }

  updateProduct = async (id, status) => {
    const postData = {
      is_active: !status
    }
    this.setState({ isLoading: true })

    await GiftCatalogService.updateProduct(id, postData)
      .then(data => {
        // let products = data.data && data.data.data ? data.data.data : []
        // this.setState({ products });
      })
      .catch(e => {

      })
    await this.getProductList(this.state.page);
    this.setState({ isLoading: false })
  }


  closeUploadImageModal = () => {
    this.setState({ isUploadImageModalOpen: false, selectedIdToUploadImage: null });
    this.getProductList(this.state.page);
  }


  openUploadItemModal = () => {
    this.setState({ isUploadItemModalOpen: true, });
  }


  closeUploadItemModal = () => {
    this.setState({ isUploadItemModalOpen: false });
    this.getProductList(this.state.page);

  }


  handleDefaultChange = (pIndex, index) => {
    let products = this.state.products;
    let product = products[pIndex];
    let images = product.images;
    let updateImageIndex = index;
    for (let i = 0; i < images.length; i++) {
      let image = images[i];
      if (i === index) {
        image.is_master = true;
      } else {
        image.is_master = false;
      }
    }
    this.updateDefaultImage({ index: updateImageIndex }, product.id);
    this.setState({ products });

  }


  handleSearchChange = (e) => {
    let searchVal = e.target.value;
    this.setState({ search: searchVal, page: 1 }, () => {
      this.getProductList(1);
    });
  }

  searchItems = () => {
    this.getGiftCatalogItems()
  }

  render() {
    const { products, isUploadImageModalOpen, selectedIdToUploadImage, isUploadItemModalOpen, totalProducts, search, controls, isLoading } = this.state;
    let prepareProductListRows = products.map((p, pIndex) =>
      <tr>
        <td style={{ width: '300px' }}>{p.title}</td>
        <td className="text-align-left">
          {p.images && p.images.map((i, index) =>
            <span>
              <label>
                <input type="radio" name={`${pIndex}+${index}`} checked={i.is_master} onClick={this.handleDefaultChange.bind(this, pIndex, index)}></input>
                <img src={i.thumb} alt="" height="50" width="50" />&nbsp;&nbsp;&nbsp;
              </label>
            </span>
          )}
        </td>
        <td>
          <span onClick={this.openUploadImageModal.bind(this, p.id)}>
            <Button className='btn-warning'>Upload</Button>
          </span>
          <span onClick={this.updateProduct.bind(this, p.id, p.is_active)} style={{ marginLeft: '10px' }}>
            <Button className='btn-dark' style={{ backgroundColor: '#e0e2e4', color: '#000' }}>{p.is_active ? 'InActive' : 'Active'}</Button>
          </span>
          {/* <span onClick={this.saveData.bind(this, pIndex)}>Save</span> */}
        </td>
      </tr>
    )
    return (
      <>
        {isLoading && <CustomSpinner />}
        <div>
          {isUploadImageModalOpen && <UploadGiftProductImage
            show={isUploadImageModalOpen}
            closeModal={this.closeUploadImageModal}
            id={selectedIdToUploadImage}
          ></UploadGiftProductImage>}
          {isUploadItemModalOpen && <UploadGiftItems
            show={isUploadItemModalOpen}
            closeModal={this.closeUploadItemModal}
          ></UploadGiftItems>}
          <Card>
            <CardBody>
              <Row className="margin-top-10">
                <Col xs="4">
                  {/* <Label> */}
                  <Input
                    placeholder="Search product name"
                    className="report-search-input"
                    onChange={this.handleSearchChange}
                    value={search}
                  ></Input>
                  <img src="images/search.svg" alt="" className="report-search icon-width" />
                  {/* </Label> */}
                </Col>
                <Col xs="3">
                  <Row style={{ marginLeft: "0", marginRight: "0" }}>
                    {/* <Col xl="12" md="12" xs="12">
                    <Label className="label-text">
                      Offer Status
                        </Label>
                  </Col> */}
                    <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                      <FormGroup>
                        <div className="d-flex-row pcrtype-wrapp">
                          <label className="custom-checkboax-radio--container">
                            <input
                              type="radio"
                              name="status"
                              value=""
                              onChange={() => {
                                this.handleRadioChange("");
                              }}
                              checked={controls.status.value === ""} />
                            <span className="checkmark-radio" />
                          </label>
                          <span className="label-text marg-pad">All</span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                      <FormGroup>
                        <div className="d-flex-row pcrtype-wrapp" style={{ paddingRight: "0" }} >
                          <label className="custom-checkboax-radio--container">
                            <input type="radio"
                              name="status"
                              value="active"
                              onChange={() => {
                                this.handleRadioChange("active");
                              }}
                              checked={controls.status.value === "active"} />
                            <span className="checkmark-radio" />
                          </label>
                          <span className="label-text marg-pad">Active</span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                      <FormGroup>
                        <div className="d-flex-row pcrtype-wrapp">
                          <label className="custom-checkboax-radio--container">
                            <input
                              type="radio"
                              name="status"
                              value="inactive"
                              onChange={() => {
                                this.handleRadioChange("inactive");
                              }}
                              checked={controls.status.value === "inactive"} />
                            <span className="checkmark-radio" />
                          </label>
                          <span className="label-text marg-pad">Inactive</span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col className="text-align-right">
                  <Button onClick={this.openUploadItemModal}>Upload Products</Button>
                </Col>
              </Row>
              <Table className="margin-top-5">
                <tr>
                  <th>Product Name</th>
                  <th>images</th>
                  <th>Action</th>
                </tr>
                {prepareProductListRows}
              </Table>
              <Pagination
                margin={2}
                page={this.state.page}
                // count={totalPages}
                pageSize={pageSize}
                totalRecords={totalProducts}
                onPageChange={this.handlePageChange}
              ></Pagination>
            </CardBody>
          </Card>
        </div>
      </>

    );
  }
}

export default GiftItemList;