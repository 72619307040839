import React from 'react';
import {
  Row, Col,
  Card, CardBody
} from 'reactstrap';
import './HowItWorks.css';

const HowItWorks = () => {
  return <Card id="about-jk-advantage" className="height-100 border-radius-10">
    <CardBody>
      <Row>
        <Col className="about-jkadvantage-title">
          WHAT'S NEW
        </Col>
      </Row>
      <br />
      {/* <Row>
        <Col>
          <img src="images/logo-small.png" width="137px" height="27" alt="" />
        </Col>
      </Row> */}
      {/* <br /> */}
      <Row>
        <Col className="about-jkadvantage-text">
          <ul>
            <li>A six times larger loyalty program wherein channel partners will earn points across categories like PCR, Truck/Bus, SCV/LCV, 2 Wheeler, 3 Wheeler,Farm and Retread.</li>
            <li>A cloud enabled scalable technology, available on Web and Mobile.</li>
            <li>Now register all your customer car and 2 wheeler with JK tyre warranty online and earn additional points for each registered entry. </li>
            <li>A dynamic report page, which updates based on daily offtakes for improved transparency.</li>
          </ul>
        </Col>
      </Row>

    </CardBody>
  </Card>;
};

export default HowItWorks;