import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import JkTyreLogo from '../../assets/images/JK-Tyre-logo.png'
import './RangerEvent.css';

const RangerEventHeader = ({ queryParameter }) => {
  let history = useHistory();

  const gotoContact = () => {
    history.push({
      pathname: '/event-registration',
      state: queryParameter
    })
  }
  return (
    <Fragment>
      <div className="rangerHeaderContainer">
        <div className="rangerHeaderNav">
          <img className="event-header-logo jktyre-event-logo" src={JkTyreLogo} alt="Jk Tyre" />
          <p onClick={gotoContact} className="rangerHeaderLinks">Register Here</p>
        </div>
      </div>
    </Fragment>
  )
}
export default RangerEventHeader