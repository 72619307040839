import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './NetworkDetector.css';
// const API_URL = process.env.REACT_APP_API_URL;
// import HeaderLogo from '../../theme-components/AppLogo';
// src/theme-components/AppLogo/index.js
export default class NetworkDetector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    }
  }

  componentDidMount() {
    this.checkUserAgent();
  }

  refreshPage() {
    window.location.reload();
  }

  checkUserAgent() {
    // let { isMobile } = this.state;
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.setState({
        isMobile: true
      })
      return 'mobile'
    } else if (/Chrome/i.test(ua)) {
      this.setState = (
        {
          isMobile: false
        }
      )
      return 'chrome'
    } else {
      this.setState = (
        {
          isMobile: false
        }
      )
      return 'other'
    }
  }


  render() {
    return (
      <>
        {/* <HeaderLogo /> */}
        <div className="app-header header-shadow no-network-header">
          <img src="images/logo-small.png" alt="JK Tyre" width="133px" height="35px" style={{ minWidth: '133px' }} />
        </div>
        <div className="app-main white-back-color" style={{ minHeight: '97vh', backgroundColor: '#fff' }}>
          {
            !this.state.isMobile && <Col xs="1" md="1" xl="1" style={{ padding: '0' }}>
              <div className="network-sidebar"></div>
            </Col>
          }
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col sm="5" className="d-flex-column" style={{ justifyContent: 'center' }}>
                  <span className="NO-NETWORK-CONNECTION">NO NETWORK CONNECTION</span>
                  <span className="Could-not-connect-to mt-2">Could not connect to the internet.<br />
                    Please check your  network</span>
                  <span className="Try-Again mt-2 cursor-pointer" onClick={() => this.refreshPage()}>Try Again</span>
                </Col>
                <Col sm="5" className="d-flex-column" style={{ justifyContent: 'center' }}>
                  <img src={this.props.noNetworkImage} alt="" className="no-network-img" />
                </Col>
              </Row>
            </div>
          </div>

        </div>
      </>
    );
  }
}