import React, { Component } from 'react';
// import { Row, Col, Card, CardBody, Button } from 'reactstrap';
// import Sidebar from 'react-sidebar';
// import Ionicon from 'react-ionicons';
// import swal from 'sweetalert';

// import Pagination from '../../components/Pagination/Pagination';
// import AddNewGroup from '../../components/AddNewGroup/AddNewGroup';
// import EditGroupDetail from '../../components/EditGroupDetail/EditGroupDetail';
import ManageGroup from '../../components/ManageGroup/ManageGroup';

// import CustomerManagementService from '../../services/CustomerManagementService';

// import ConfirmModal from '../../modal/ConfirmModal/ConfirmModal';

// import { formatDate } from '../../utils/utils';

// // import './ManageGroup.css';
// import { select } from 'react-cookies';

const pageSize = 10;

class ManageGroupPage extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     newGroupSidebarOpen: false,
  //     editGroupSidebarOpen: false,
  //     groupData: [],
  //     currentEditGroup: null,
  //     groupCount: 0,
  //     groupPage: 1,
  //     groupTotalPages: 1,
  //     isDeleteGroupModalOpen: false
  //   }
  //   this.childAddNewGroup = React.createRef();
  //   this.childEditNewGroup = React.createRef();
  // }

  // componentDidMount() {
  //   this.getGroups(1);
  // }

  // onCloseNewGroupSidebar = (flag = false) => {
  //   document.body.style.overflow = 'auto';
  //   if (flag) {
  //     this.setState({ groupPage: 1 });
  //     this.getGroups(1);
  //   }
  //   this.setState({ newGroupSidebarOpen: false })
  // }

  // reloadGroups = () => {
  //   this.getGroups(this.state.groupPage);
  // }

  // openNewGroupSidebar = (flag = false) => {
  //   this.setState({ newGroupSidebarOpen: true })
  // }

  // onCloseEditGroupSidebar = (flag = false) => {
  //   document.body.style.overflow = 'auto';
  //   this.setState({ editGroupSidebarOpen: false })
  // }

  // openEditGroupSidebar = (groupData) => {
  //   this.setState({ currentEditGroup: groupData, editGroupSidebarOpen: true })
  // }

  // getGroups = (page = 1) => {
  //   CustomerManagementService.getGroups({ page, pageSize })
  //     .then(res => {
  //       let { groupData, groupCount, groupTotalPages } = this.state;
  //       if (res.data.data && res.data.data.groups && res.data.data.groups.length > 0) {
  //         groupData = res.data.data.groups;
  //         groupCount = res.data.data.count;
  //         groupTotalPages = Math.ceil(groupCount / pageSize);
  //       } else {
  //         groupData = [];
  //       }
  //       this.setState({ groupData, groupCount, groupTotalPages });
  //     })
  //     .catch(e => {

  //       // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
  //       // const message = err && err.message ? err.message : ERROR_MESSAGE;
  //       //this.setState({ isLoading: false });
  //     });
  // }


  // handlePageChangeAllCustomer = (page) => {
  //   // const { search_customer_from_all } = this.state.controls;
  //   this.setState({ groupPage: page });
  //   this.getGroups(page);
  // }

  // deleteGroup = (groupId) => {
  //   CustomerManagementService.deleteGroup(groupId)
  //     .then(data => {
  //       this.setState({ groupPage: 1, isDeleteGroupModalOpen: false, selectedGroupToDelete: null });
  //       this.getGroups(1);
  //       if (data.data.message) {
  //         swal({
  //           title: 'Delete Grouppppppppp',
  //           text: data.data.message,
  //           icon: 'success',
  //           type: 'success',
  //         })
  //       }
  //     })
  //     .catch(e => {

  //     })
  // }

  // openDeleteGroupModal = (groupId) => {
  //   this.setState({ isDeleteGroupModalOpen: true, selectedGroupToDelete: groupId });
  // }
  // closeDeleteGroupModal = () => {
  //   this.setState({ isDeleteGroupModalOpen: false, selectedGroupToDelete: null });
  // }

  render() {
    // const { groupData, currentEditGroup, groupTotalPages, groupCount, groupPage, selectedGroupToDelete } = this.state;
    // const prepareGroupRaws = groupData.map(g => <tr key={g.group_id}>
    //   <td>{g.group_name}</td>
    //   <td>{g.member_count}</td>
    //   <td>{formatDate(g.created_on)}</td>
    //   <td>
    //     <span onClick={this.openEditGroupSidebar.bind(this, g)}>
    //       <Ionicon icon="md-create" fontSize="18px" color="#fdbb1f" className="cursor-pointer" />
    //     </span> &nbsp;&nbsp;
    //     <span onClick={this.openDeleteGroupModal.bind(this, g.group_id)}>
    //       <Ionicon icon="md-trash" fontSize="18px" color="#fdbb1f" className="cursor-pointer" />
    //     </span>
    //   </td>
    // </tr>)
    return (
      <div id="manage-user">
        {/* <Row>
          <Col>
            <div className="page-title">Manage Groups</div>
          </Col>
          <Col className="text-align-right">
            <Button className="mb-1 mr-1 btn-pill action-button profile-save-button" color="warning"
              onClick={this.openNewGroupSidebar}
            >Add Group</Button>
          </Col>
        </Row>
        <br /> */}
        <ManageGroup></ManageGroup>
        {/* {
          this.state.newGroupSidebarOpen &&
          <Sidebar
            sidebar={<>
              <AddNewG  QAAAroup
                ref={this.childAddNewGroup}
                onCloseSidebar={this.onCloseNewGroupSidebar}
              />
            </>}
            open={this.state.newGroupSidebarOpen}
            sidebarClassName="new-group-modal"
            pullRight
            styles={{ sidebar: { background: 'white', height: '90%', top: '60px' } }}
            children={<span></span>}
          />
        }
        {
          this.state.editGroupSidebarOpen &&
          <Sidebar
            sidebar={<>
              <EditGroupDetail
                ref={this.childEditNewGroup}
                groupData={currentEditGroup}
                onCloseSidebar={this.onCloseEditGroupSidebar}
                reloadGroups={this.reloadGroups}
              />
            </>}
            open={this.state.editGroupSidebarOpen}
            sidebarClassName="new-group-modal"
            pullRight
            styles={{ sidebar: { background: 'white', height: '90%', top: '60px' } }}
            children={<span></span>}
          />
        }
        <ConfirmModal
          show={this.state.isDeleteGroupModalOpen}
          closeModal={this.closeDeleteGroupModal}
          title="Delete Group"
          message="Are you sure you want to delete group?"
          actionHandler={this.deleteGroup.bind(this, selectedGroupToDelete)}
        >

        </ConfirmModal> */}

        {/* <Row>
          <Col sm="6">
            <Card>
              <CardBody>
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>Group Name</th>
                      <th>Members</th>
                      <th>Created On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prepareGroupRaws}
                  </tbody>
                </table>
                {groupTotalPages > 1 && <Pagination
                  margin={2}
                  page={groupPage}
                  count={groupTotalPages}
                  pageSize={pageSize}
                  totalRecords={groupCount}
                  onPageChange={this.handlePageChangeAllCustomer}
                ></Pagination>}
              </CardBody>
            </Card>
          </Col>
                </Row>*/}
      </div>
    );
  }
}

export default ManageGroupPage;