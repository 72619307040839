import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './CustomerReference.css';
import CustomerRefenceDetail from './CustomerRefenceDetail';
import CustomerReferenceSuccess from './CustomerReferenceSuccess';
import CustomerReferenceService from '../../services/CustomerReferenceService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
export default class CustomerReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display_page: 'CustomerRefenceDetail',
      dealer_detail: {
        dealer: {},
        customer: {},
        customerHistory: {},
        code: ''
      },
      is_valid_dealer_code: true
    };
    // const query = new URLSearchParams(this.props.location.search);
    if (this.props.match.params.token) {
      this.state.code = this.props.match.params.token;
    }
  }


  componentDidMount() {
    if (this.props.match.params.token) {
      this.getDealerInfo();
    }
  }

  getDealerInfo = async () => {
    this.setState({
      isLoading: true
    });
    await CustomerReferenceService.getDealerInfo({
      "code": this.props.match.params.token
    }).then(res => {
      let { dealer_detail } = this.state;
      if (res.data.data) {
        let address_data = "";
        if (res.data.data.dealer.address_line_1) {
          address_data = `${res.data.data.dealer.address_line_1}`;
        }
        if (res.data.data.dealer.address_line_2) {
          address_data = `${address_data}, ${res.data.data.dealer.address_line_2}`;
        }
        if (res.data.data.dealer.address_line_3) {
          address_data = `${address_data}, ${res.data.data.dealer.address_line_3}`;
        }
        if (res.data.data.dealer.address_line_4) {
          address_data = `${address_data}, ${res.data.data.dealer.address_line_4}`;
        }
        address_data = `${address_data}, ${res.data.data.dealer.city} | ${res.data.data.dealer.telephone_no_1}`;
        res.data.data.dealer.address_data = address_data;
        dealer_detail = res.data.data;
      }
      this.setState({
        dealer_detail,
        isLoading: false,
        is_valid_dealer_code: true
      });
    })
      .catch(e => {
        console.log("err", e);
        this.setState({
          isLoading: false,
          is_valid_dealer_code: false
        });
      });
  }

  submitRef = () => {
    this.setState({
      display_page: 'CustomerReferenceSuccess'
    })
  }

  isLoading = (flag) => {
    this.setState({
      isLoading: flag
    })
  }

  render() {
    let { display_page, dealer_detail } = this.state;
    return (
      <div id="CustomerReference">
        {this.state.isLoading && <CustomSpinner />}
        <Row className="m-0">
          <Col xl="12" xs="12" md="12" className="d-flex-column mt-5 text-align-center">
            <img src="images/logo.png" alt="" className="logo ml-auto mr-auto" />
            <span className="ask-ref mt-2 mb-3">Ask your Reference</span>
          </Col>
          <Col xl="5" md="12" xs="12" className="d-flex-column ml-auto mr-auto
          customer-refence-detail-col">
            {
              display_page === 'CustomerRefenceDetail' &&
              <CustomerRefenceDetail
                dealer_detail={this.state.dealer_detail}
                code={this.state.code}
                isLoading={this.isLoading}
                is_valid_dealer_code={this.state.is_valid_dealer_code}
                submitRef={this.submitRef} />
            }
            {
              display_page === 'CustomerReferenceSuccess' &&
              <CustomerReferenceSuccess
                dealer_detail={this.state.dealer_detail} />
            }
          </Col>
          {
            dealer_detail.dealer && Object.keys(dealer_detail.dealer).length > 0 &&
            <Col xl="12" md="12" xs="12" className="d-flex-column ml-auto mr-auto justify-content-center mt-4 mb-4">
              <Row>
                <Col xl="5" md="12" xs="12" className="customer-refence-detail-col ml-auto mr-auto justify-content-center d-flex-column">
                  <span className="store-name">{dealer_detail.dealer.business_name}</span>
                  <span className="mt-1 store-address">
                    {dealer_detail.dealer.address_data}</span>
                </Col>
              </Row>
            </Col>
          }
        </Row>
        {/* <Row className="mt-auto mb-3">
          <Col xl="12" md="12" xs="12" className="d-flex-column ml-auto mr-auto justify-content-center">
            <Row>
              <Col xl="5" md="12" xs="12" className="customer-refence-detail-col ml-auto mr-auto justify-content-center d-flex-column">
                <span className="store-name">Roman Sales Corporation</span>
                <span className="mt-2 store-address">Near Kalupur Comm. Bank, Jodhpur Cross Road, Satellite, Ahmedabad | 9797979898</span>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </div>
    )
  }
}
