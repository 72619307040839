import React, { Component, Fragment } from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';

// components
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

// services
import TradeSchemeService from '../../services/TradeSchemeService';
import ModalService from '../../services/ModalService';

// utils
import { getCurrentQuarter, getCurrentYear } from '../../utils/utils';

// constants
import { messages } from '../../constants/constant.message';

// css
import './PcrSchemeDealer.css';

const { ERROR_MESSAGE } = messages.COMMON;

let sapId = null;
class PcrSchemeDealer extends Component {
  state = {
    schemeData: {},
    currentQuarter: null,
    currentYear: null,
    schemes: [],
    overviewUrl: null,
    realQuarter: null,
    realYear: null,
    isLoading: false
  }

  constructor(props) {
    super(props);
    if (props && props.from === 'modal') {
      sapId = props.sapId;
    }
  }

  componentDidMount() {
    this.getSchemeDataDealer();
    this.getSchemes();
    const currentQuarter = getCurrentQuarter();
    const realQuarter = currentQuarter;
    const currentYear = getCurrentYear();
    this.setState({ currentQuarter, currentYear, realQuarter, realYear: currentYear });
  }

  getSchemeDataDealer(quarter = null, year = null) {
    this.setState({ isLoading: true });
    TradeSchemeService.getDealerScheme(quarter, year, sapId)
      .then(data => {
        this.setState({ isLoading: false });
        const schemeData = data.data && data.data.data ? data.data.data : {};
        this.setState({ schemeData });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error');
      })
  }

  getSchemes = () => {
    TradeSchemeService.getSchemes()
      .then(data => {
        const schemes = data.data && data.data.data ? data.data.data : [];
        this.setState({ schemes });
      })
      .catch(e => {
        const err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error');
      })
  }

  changeScheme = (scheme) => {
    this.setState({ currentQuarter: scheme.quarter, currentYear: scheme.year });
    this.getSchemeDataDealer(scheme.quarter, scheme.year);
  }

  render() {
    const { schemeData, currentQuarter, currentYear, schemes, realQuarter, realYear, isLoading } = this.state;
    const { qtd, mtd, eligible_gift, eligible_advantage_points, eligible_consistency_points, eligible_consistency_gift, quarter_label, selected_reward } = schemeData;

    const prepareSchemes = schemes.map((s, index) =>
      <CardBody
        className={`text-align-center quarter ${currentQuarter === s.quarter && currentYear === s.year ? 'active-quarter' : 'inactive-quarter'} ${index !== 0 ? 'border-top' : ''} cursor-pointer`}
        onClick={this.changeScheme.bind(this, s)}
      >
        <div>{s.label}</div>
        <div>{s.yearLabel}</div>
      </CardBody>
    )
    const prepareQuarterRows = qtd && qtd.data.map(q => <tr>
      <td className="table-row-title">{q.month}</td>
      <td>{q.target}</td>
      <td>{q.achieved}</td>
      <td>{q.achieved_percentage}</td>
    </tr>)

    const fileUrl = schemeData.overviewUrl ? schemeData.overviewUrl : null;
    return <div id="pcr-scheme">
      {isLoading && <CustomSpinner></CustomSpinner>}
      <Row >
        {this.props.from === 'page' && <Col>
          <div className="page-title">PCR Scheme</div>
        </Col>}
        <Col className="text-align-right">
          <span className="cursor-pointer">
            <a href={fileUrl} target="_blank" rel="noopener noreferrer" type="application/pdf">
              <img src="images/info1.svg" className="download-report-icon" alt='' />&nbsp;
              <span className={`download-report ${this.props.from === 'modal' ? 'margin-top-10' : ''}`}>
                View Scheme Overview</span>
            </a>
          </span>
        </Col>
      </Row>
      <br />
      {schemeData && Object.keys(schemeData).length > 0 && <Fragment>
        <Row>
          <Col sm="2" >
            {/* <Card> */}
            <div className="quarter-container">
              {prepareSchemes}
            </div>
            {/* </Card> */}
          </Col>
          <Col sm="6">
            <Row>
              <Col>
                <Card>
                  {/* <CardBody> */}
                  <div className="data-card-background padding-15">
                    <div className="data-card-title">
                      Month Till Date
                    </div>
                    <Row className="margin-top-20">
                      <Col sm="7" className="data-card-label text-align-right">
                        Total Target -
                      </Col>
                      <Col sm="4" className="data-card-number">
                        {realQuarter === currentQuarter ? mtd.target : '--'}
                      </Col>
                    </Row>
                    <Row className="margin-top-20">
                      <Col sm="7" className="data-card-label text-align-right">
                        Total Achievement -
                      </Col>
                      <Col sm="4" className="data-card-number">
                        {realQuarter === currentQuarter ? mtd.achievement : '--'}
                      </Col>
                    </Row>
                  </div>
                  <Row className="margin-top-10 padding-top-10 padding-bottom-10" >
                    <Col sm="7" className="data-card-label text-align-right">
                      % Achievement -
                    </Col>
                    <Col sm="4" className="data-card-number">
                      {realQuarter === currentQuarter && realYear === currentYear && mtd.achieved_percentage !== null ? `${mtd.achieved_percentage}%` : '--'}
                    </Col>
                  </Row>
                  {/* </CardBody> */}
                </Card>
              </Col>
              <Col>
                <Card>
                  {/* <CardBody> */}
                  <div className="data-card-background padding-15">
                    <div className="data-card-title">
                      Quarter Till Date
                    </div>
                    <Row className="margin-top-20">
                      <Col sm="7" className="data-card-label text-align-right">
                        Total Target -
                      </Col>
                      <Col sm="4" className="data-card-number">
                        {qtd.target}
                      </Col>
                    </Row>
                    <Row className="margin-top-20 ">
                      <Col sm="7" className="data-card-label text-align-right">
                        Total Achievement -
                      </Col>
                      <Col sm="4" className="data-card-number">
                        {qtd.achievement}
                      </Col>
                    </Row>
                  </div>
                  <Row className="margin-top-10 padding-top-10 padding-bottom-10">
                    <Col sm="7" className="data-card-label text-align-right">
                      % Achievement -
                    </Col>
                    <Col sm="4" className="data-card-number">
                      {qtd.achieved_percentage}%
                    </Col>
                  </Row>
                  {/* </CardBody> */}
                </Card>
              </Col>
            </Row>
            <Row className="margin-top-10">
              <Col>
                <Card >
                  <CardBody className="max-height-190">
                    <div className="table-title">{quarter_label}</div>
                    <Table className="margin-top-10">
                      <tr>
                        <th>Month</th>
                        <th>Total Target</th>
                        <th>Total Achievement</th>
                        <th>% Achievement</th>
                      </tr>
                      {prepareQuarterRows}
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            {selected_reward && <Card className="scheme-bg">
              <CardBody>
                <div className="scheme-card-title">
                  Your Selected Reward is
                </div>
                <div className="scheme-card-detail margin-top-40 margin-bottom-40">
                  {selected_reward}
                </div>

              </CardBody>
            </Card>}
            {!selected_reward && <Fragment>
              {(eligible_gift || eligible_advantage_points) && <Card className="scheme-bg">
                <CardBody>
                  <div className="scheme-card-title">
                    Reward on Target Achievement of Quarter
                  </div>
                  <div className="scheme-card-detail margin-top-20">
                    {eligible_gift}
                  </div>
                  {eligible_gift && eligible_advantage_points && <div className="or margin-top-10">
                    or
                  </div>}
                  <div className="scheme-card-detail margin-top-10">
                    {eligible_advantage_points}
                  </div>
                </CardBody>
              </Card>}
              {(eligible_consistency_points || eligible_consistency_gift) && <Card className="scheme-bg margin-top-10">
                <CardBody>
                  <div className="scheme-card-title">
                    Consistency Bonus
                  </div>
                  <div className="scheme-card-detail margin-top-20">
                    {eligible_consistency_gift}
                  </div>
                  {eligible_consistency_gift && eligible_consistency_points && <div className="or margin-top-10">
                    or
                  </div>}
                  <div className="scheme-card-detail margin-top-10">
                    {eligible_consistency_points}
                  </div>
                </CardBody>
              </Card>}
            </Fragment>}
          </Col>
        </Row>
      </Fragment>}
    </div>
  }
}

export default PcrSchemeDealer;