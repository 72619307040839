import React, { Component, Fragment } from "react";
import { Button, Input, Row, Col, Card, CardBody, FormGroup } from "reactstrap";
import ReactDOM from "react-dom";
import "./DashboardMobile.css";
import Chart from "react-apexcharts";
import Ionicon from "react-ionicons";
import swal from "sweetalert";
import CountDetailMobile from "../CountDetailMobile/CountDetailMobile";
import OfftakeSummaryMobile from "../OfftakeSummaryMobile/OfftakeSummaryMobile";
import GraphMobile from "../GraphMobile/GraphMobile";
import SendVerifyOtp from "../SendVerifyOtp/SendVerifyOtp";
import { formatNumber } from "../../utils/utils";
import Slider from "react-slick";

class DashboardMobile extends Component {
  state = {
    screens: ["dashboard", "mtd", "qtd", "ytd", "yo", "os"],
    currentOpenScreen: "dashboard", // mtd, qtd,ytd, yo(yearly offtake),os(offtake summary)
  };

  changeScreen = (screenName) => {
    window.scrollTo(0, 0);
    this.setState({ currentOpenScreen: screenName });
  };

  prepareSparkLineChart = (categories, series) => {
    const sparkLineChart = {
      options: {
        stroke: {
          curve: "smooth",
          width: 2,
        },
        markers: {
          size: 4,
        },
        xaxis: {
          categories,
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        tooltip: {
          label: "",
        },
      },
      series: [
        {
          name: "",
          data: series,
        },
      ],
    };

    return sparkLineChart;
  };

  redirectToSecondaryAbout = () => {
    this.props.redirectToSecondaryReport();
  };

  closeSendVerifyOtp = (phoneNumber, token) => {
    if (phoneNumber) {
      this.props.redirectToWarrantyReplacement(phoneNumber, token);
    }
  };

  openSendVerifyOtp = () => {
    const wrapper = document.createElement("div");
    ReactDOM.render(
      <SendVerifyOtp closeSendVerifyOtp={this.closeSendVerifyOtp} />,
      wrapper
    );
    const el = wrapper.firstChild;
    swal({
      text: "",
      content: el,
      buttons: false,
      className: "send-verify-otp-modal",
      closeOnClickOutside: false,
      closeOEscapeKey: false,
      closeOnConfirm: false,
    }).then((value) => {
    });
  };

  afterChangeBanner = (index) => {
    this.props.afterChangeBanner(index);
  };

  render() {
    const {
      controls,
      isSentOTP,
      yearObj,
      quarterObj,
      monthObj,
      stackedChartMobile,
      // advantageMessage,
      // redeemData,
      displayLabels,
      totalSeconadryPoints,
      noWarrantyCustomers,
      totalPrimaryPoints,
      totalYearlyOfftake,
      // redeemEnable,
      // redeemDays,
      sparkLineChartPrimary,
      sparkLineChartSecondary,
      // memberRole,
    } = this.props;
    const { warrantyPhoneNumber, warrantyOTP } = controls;
    const sparklinePrimary = this.prepareSparkLineChart(
      sparkLineChartPrimary.categories,
      sparkLineChartPrimary.data
    );
    const sparklineSecondary = this.prepareSparkLineChart(
      sparkLineChartSecondary.categories,
      sparkLineChartSecondary.data
    );
    const settings_banner = {
      className: "slider-banner",
      centerMode: false,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      dots: true,
      initialSlide: 1,
    };
    // const sparkline = this.prepareSparkLineChart();
    return (
      <div id="dashboard-mobile">
        {this.state.currentOpenScreen === "dashboard" && (
          <Fragment>
            {/* {redirectToWarrantyRegistration && <Redirect to="/warranty-registration" />} */}
            <div className="page-title">Dashboard</div>
            {/* {redeemEnable && <br />}

        {advantageMessage && <Row>
          <Col>
            <CardBody className="gradient-title text-align-center">

              <Row className="gradient-sub-title">
                <Col>
                  {advantageMessage}
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>} */}

            {/* {redeemEnable && <Row>
          <Col>
            <CardBody className="gradient-title text-align-center">
              <Row>
                <Col>
                  YOUR REWARDS ARE READY!!
                </Col>
              </Row>
              <Row className="gradient-sub-title">
                <Col>
                  <div>
                    Your opportunity to Redeem Points just opened up
                  </div>
                  <div>
                    You have {redeemDays} Days remaining to redeem
                  </div>
                </Col>
              </Row>
            </CardBody> 
          </Col>
        </Row>} */}
            <br />
            <div className="warranty-block padding-15 text-align-center">
              <Row>
                <Col
                  className="warranty-text warranty-text-bold"
                  style={{ color: "#545cd8", fontSize: "14px" }}
                >
                  WARRANTY REGISTRATION
                </Col>
              </Row>

              {!isSentOTP && (
                <Fragment>
                  <Row>
                    <Col
                      className="warranty-sub-text"
                      style={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      Enter the customer's phone number and we will send you a
                      verification code
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="input-group prefix justify-content-center">
                        {/* <span className="input-group-addon1">+91</span>
                        <Input
                          type="text"
                          name="warrantyPhoneNumber"
                          id="warrantyPhoneNumber"
                          maxLength="10"
                          className="input-focus-white"
                          style={{ paddingLeft: "45px" }}
                          value={warrantyPhoneNumber.value}
                          onChange={this.props.handleInputChange}
                          placeholder="Enter customer's phone number"
                        /> */}
                        <FormGroup className="mb-0">
                          <Input
                            type="text"
                            autoComplete="nofill"
                            className="nine-one-rect"
                            disabled={true}
                            value="+91"
                          />
                        </FormGroup>
                        <FormGroup className="mb-0">
                          <Input
                            type="text"
                            autoComplete="nofill"
                            className="enter-number-otp"
                            name="warrantyPhoneNumber"
                            maxLength="10"
                            value={warrantyPhoneNumber.value}
                            onChange={this.props.handleInputChange}
                            style={{ marginLeft: "10px", width: "230px" }}
                            placeholder="Enter Customer Mobile Number"
                          />
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ padding: "0px", marginTop: "10px" }}>
                      <Button
                        onClick={this.props.sendOTP}
                        disabled={
                          !warrantyPhoneNumber.value ||
                          (warrantyPhoneNumber.value &&
                            warrantyPhoneNumber.value.length !== 10)
                        }
                        className="mb-1 mr-1 btn-pill action-button send-verify-otp"
                        color="warning"
                      >
                        <b>Send OTP</b>
                      </Button>
                    </Col>
                  </Row>
                </Fragment>
              )}

              {isSentOTP && (
                <Fragment>
                  <Row>
                    <Col
                      className="warranty-sub-text mb-1"
                      style={{ fontSize: "12px", marginTop: "5px" }}
                    >
                      Enter the verification code. Didn't get the code?
                      <br />
                      <span
                        onClick={this.props.sendOTP}
                        style={{
                          color: "#545cd8",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginBottom: "5px",
                        }}
                      >
                        Send Again
                      </span>
                      &nbsp;&nbsp;
                      <span
                        onClick={this.props.cancelOTP}
                        style={{
                          color: "#545cd8",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Cancel
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        type="text"
                        name="warrantyOTP"
                        id="warrantyOTP"
                        className="input-focus-white"
                        value={warrantyOTP.value}
                        onChange={this.props.handleInputChange}
                        placeholder="Enter OTP"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ padding: "0px", marginTop: "5px" }}>
                      <Button
                        disabled={!warrantyOTP.value}
                        style={{ width: "125px" }}
                        onClick={this.props.verifyOTP}
                        className="mb-1 mr-1 btn-pill action-button send-verify-otp"
                        color="warning"
                      >
                        <b>Submit</b>
                      </Button>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </div>
            <br />
            {this.props.bannerData.length > 0 && (
              <Row>
                <Col xl="12" md="12" xs="12">
                  <Slider
                    {...settings_banner}
                    ref={this.sliderBanner}
                    initialSlide={0}
                  >
                    {this.props.banner_list_item}
                  </Slider>
                </Col>
              </Row>
            )}
            <br />
            <Row style={{ paddingLeft: "10px" }}>
              <Col style={{ paddingRight: "5px" }}>
                {this.props.isClamRegistrationOpen && (
                  <span
                    onClick={() => this.openSendVerifyOtp()}
                    className="custom-anchor-tag custom-pull-right existing-customer-eligibility"
                  >
                    Check existing customer’s eligibility for warranty
                  </span>
                )}
              </Col>
            </Row>

            {/* {memberRole === "dealer" && advantageMessage && <br />}

            {memberRole === "dealer" && advantageMessage && (
              <Row>
                <Col>
                  <CardBody
                    className={`gradient-title ${
                      redeemData.action ? "cursor-pointer" : ""
                      }`}
                    onClick={
                      redeemData.action
                        ? this.props.redeemAction.bind(this, redeemData.action)
                        : null
                    }
                  >
                   
                    <Row className="gradient-sub-title">
                      <Col>{advantageMessage}</Col>
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            )} */}

            <br />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="dashboard-page-card-title-big">
                          Total Offtake Points
                        </div>
                        <div
                          className="count-big margin-top-10"
                          style={{ textAlign: "left" }}
                        >
                          {formatNumber(totalPrimaryPoints)}
                        </div>
                      </Col>
                      <Col>
                        <Chart
                          options={sparklinePrimary.options}
                          series={sparklinePrimary.series}
                          type="line"
                          width="100%"
                          height="39px"
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col className="count-block-border text-align-center padding-bottom-10">
                        <div className="card-count-title">YTD</div>
                        <div className="card-count-period">
                          {displayLabels &&
                            displayLabels.year_till_previous_quarter}
                          {/* {yearObj.duration} */}
                        </div>
                        <div className="count-small">
                          {formatNumber(yearObj.count)}
                        </div>
                        <div className="margin-top-20">
                          <Ionicon
                            icon="ios-arrow-dropright"
                            onClick={this.changeScreen.bind(this, "ytd")}
                            fontSize="24px"
                            color="#fdbb1f"
                          />
                        </div>
                      </Col>
                      <Col className="count-block-border  text-align-center padding-bottom-10">
                        <div className="card-count-title">QTD</div>
                        <div className="card-count-period">
                          {displayLabels && displayLabels.quarter}
                          {/* {quarterObj.duration} */}
                        </div>
                        <div className="count-small">
                          {formatNumber(quarterObj.count)}
                        </div>
                        <div className="margin-top-20">
                          <Ionicon
                            icon="ios-arrow-dropright"
                            onClick={this.changeScreen.bind(this, "qtd")}
                            fontSize="24px"
                            color="#fdbb1f"
                          />
                        </div>
                      </Col>
                      <Col className="count-block-border  text-align-center padding-bottom-10">
                        <div className="card-count-title">MTD</div>
                        <div className="card-count-period">
                          {displayLabels && displayLabels.month}

                          {/* {monthObj.duration} */}
                        </div>
                        <div className="count-small">
                          {formatNumber(monthObj.count)}
                        </div>
                        <div className="margin-top-20">
                          <Ionicon
                            icon="ios-arrow-dropright"
                            onClick={this.changeScreen.bind(this, "mtd")}
                            fontSize="24px"
                            color="#fdbb1f"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="yearly-offtake-link margin-top-10"
                        onClick={this.changeScreen.bind(this, "yo")}
                      >
                        View yearly offtake
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            {!totalSeconadryPoints > 0 && (
              <Fragment>
                <Row>
                  <Col className="">
                    <Card>
                      <CardBody className="no-warranty-points text-align-center">
                        Start registering your customer for warranty and earn
                        points on each registartion.
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Fragment>
            )}
            {totalSeconadryPoints > 0 && (
              <Fragment>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <div className="dashboard-page-card-title-big">
                              {/* Total Warranty Points */}
                              Warranty Trends
                            </div>
                            {/* <div
                              className="count-big margin-top-10"
                              style={{ textAlign: "left" }}
                            >
                              {formatNumber(totalSeconadryPoints)}
                            </div> */}
                          </Col>
                          <Col>
                            <Chart
                              options={sparklineSecondary.options}
                              series={sparklineSecondary.series}
                              type="line"
                              width="100%"
                              height="39px"
                            />
                          </Col>
                        </Row>
                        <Row
                          className="margin-top-5 padding-top-10"
                          style={{ borderTop: "1px solid #eef1f1" }}
                        >
                          <Col xs="9" className="dashboard-page-card-title-big">
                            Total customer's registered with warrenty
                          </Col>
                          <Col xs="3" className="count-small">
                            {formatNumber(noWarrantyCustomers)}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Fragment>
            )}
            <Row>
              <Col className="margin-top-10">
                <Card className="height-100 warranty-background">
                  <CardBody>
                    <Row className="height-100">
                      <Col sm="2" xs="2" className="content-center">
                        <img
                          src="images/group-22.svg"
                          height="45"
                          width="45"
                          alt=""
                        />
                      </Col>
                      <Col
                        sm="8"
                        xs="8"
                        className="content-center seconadary-warranty "
                        style={{ margin: "auto", cursor: "pointer" }}
                        onClick={this.redirectToSecondaryAbout.bind(this)}
                      >
                        Know how to earn points on warranty
                      </Col>
                      <Col
                        sm="2"
                        xs="2"
                        className="content-center "
                        style={{ margin: "auto" }}
                      >
                        <img
                          src="images/group-18.svg"
                          height="18px"
                          width="21px"
                          alt=""
                        />
                      </Col>
                    </Row>
                    <div />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fragment>
        )}
        {this.state.currentOpenScreen === "mtd" && (
          <CountDetailMobile
            changeScreen={this.changeScreen}
            {...monthObj}
            displayLabels={displayLabels}
          />
        )}
        {this.state.currentOpenScreen === "qtd" && (
          <CountDetailMobile
            changeScreen={this.changeScreen}
            {...quarterObj}
            displayLabels={displayLabels}
          />
        )}
        {this.state.currentOpenScreen === "ytd" && (
          <CountDetailMobile
            changeScreen={this.changeScreen}
            {...yearObj}
            displayLabels={displayLabels}
          />
        )}
        {this.state.currentOpenScreen === "os" && (
          <OfftakeSummaryMobile changeScreen={this.changeScreen} />
        )}
        {this.state.currentOpenScreen === "yo" && (
          <GraphMobile
            totalYearlyOfftake={totalYearlyOfftake}
            changeScreen={this.changeScreen}
            stackedChartMobile={stackedChartMobile}
          />
        )}
      </div>
    );
  }
}

export default DashboardMobile;
