import React, { useEffect, useState, useCallback } from 'react';
import lodash from "lodash";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FormGroup, Label,
  Input,
  Row, Col,
  Card, CardBody,
  InputGroup, Button,
  InputGroupAddon
} from "reactstrap";
import Moment from "react-moment";
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { handleValidationRetailMarketingStoreInformation, setRetailRecciControls } from '../../../actions/RetailManagementSystemAction';
import { getRetailMarketingStoreInformationState } from '../selector';
import CampaignService from '../../../services/CampaignService';
import CustomSpinner from '../../CustomSpinner/CustomSpinner';
import RetailMarketingSystemService from '../../../services/RetailMarketingSystemService';
import Autosuggest from 'react-autosuggest';
import './RetailMarketingStoreInformation.css';
import { Modal, ModalBody } from "reactstrap";
import { Redirect } from 'react-router-dom';

function RetailMarketingStoreInformation({
  storeDetailControls,
  setRetailRecciControlsAction,
  handleValidationRetailMarketingStoreInformationAction,
  isEdit,
  history
}) {
  const [duplicate, setDuplicate] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  let calendarRecce = null;
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dealerList, setDealerList] = useState([]);
  const debouncedLoadSuggestions = useCallback(lodash.debounce(q => searchDealer(q), 500), []);


  const renderSuggestion = (suggestion) => {
    return <span>{suggestion.business_name}{' '}({suggestion.sap_id})</span>
  };
  useEffect(() => {
    // if (storeDetailControls.state.value) {
    //   getCity(storeDetailControls.state.value);
    //   getStateDistrict(storeDetailControls.state.value);
    // }
    getState();
  }, [])

  useEffect(() => {
    if (storeDetailControls.state.value) {
      getCity(storeDetailControls.state.value);
      getStateDistrict(storeDetailControls.state.value);
    }
  }, [storeDetailControls.state.value])
  const handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    if (controlName === "pincode") {
      if (!isNaN(e.target.value)) {
        storeDetailControls[controlName].value = controlValue;
      }
    } else {
      storeDetailControls[controlName].value = controlValue;
    }
    if (controlName === 'state') {
      storeDetailControls.city.value = '';
      storeDetailControls.district.value = '';
      storeDetailControls.pincode.value = '';
      // getCity(controlValue);
      // getStateDistrict(controlValue);
    }
    storeDetailControls[controlName].touched = true;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    if (controlName !== "pincode") {
      handleValidationRetailMarketingStoreInformationAction(false,
        storeDetailControls);
    }
    if (controlName === "pincode") {
      if (controlValue && controlValue.length === 6) {
        handleValidationRetailMarketingStoreInformationAction(false,
          storeDetailControls);
      }
    }
  }
  const onBlurPincode = () => {
    if (storeDetailControls.pincode.value) {
      handleValidationRetailMarketingStoreInformationAction(false,
        storeDetailControls);
    }
  }
  const getState = () => {
    setIsLoading(true);
    CampaignService.getState()
      .then((data) => {
        let { data: states } = data.data;
        // if (storeDetailControls.state.value) {
        //   getCity(storeDetailControls.state.value);
        //   getStateDistrict(storeDetailControls.state.value);
        // }
        if ((states.length > 0 && storeDetailControls.state.value &&
          states.findIndex(x => x.state_id === storeDetailControls.state.value) <= -1) ||
          (states.length <= 0 && storeDetailControls.state.value)) {
          storeDetailControls.state.value = '';
          setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        }
        setStateData(states);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  const getCity = (state = null) => {
    setIsLoading(true);
    let reqData = {
      state_id: state ? state : storeDetailControls.state.value,
    };
    CampaignService.getCity(reqData)
      .then((data) => {
        const { data: cities } = data.data;
        // if ((cities.length > 0 && storeDetailControls.city.value &&
        //   cities.findIndex(x => x.city_id === storeDetailControls.city.value) <= -1) ||
        //   (cities.length <= 0 && storeDetailControls.city.value)) {
        //   storeDetailControls.city.value = '';
        //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        // }
        setCityData(cities);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error("e", e);
        setIsLoading(false);
      });
  };

  const getStateDistrict = (state) => {
    setIsLoading(true);
    const reqData = {
      state_id: state ? state : storeDetailControls.state.value
    }
    RetailMarketingSystemService.getStateDistrict(reqData)
      .then((data) => {
        let { data: districts } = data.data;
        // if ((districts.length > 0 && storeDetailControls.district.value &&
        //   districts.findIndex(x => x.district_id === storeDetailControls.district.value) <= -1) ||
        //   (districts.length <= 0 && storeDetailControls.district.value)) {
        //   storeDetailControls.district.value = '';
        //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        // }
        setDistrictData(districts);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };


  const onSuggestionsClearRequested = () => {
    setDealerList([]);
  };
  const goBack = () => {
    setDuplicate(true);
  }
  const selectDealer = (suggestion) => {
    storeDetailControls.dealer.value = suggestion.dealer_id ? suggestion.dealer_id : '';
    storeDetailControls.dealer.touched = true;

    if (suggestion.dealer_state_id) {
      storeDetailControls.state.value = suggestion.dealer_state_id;
      storeDetailControls.pincode.value = '';
      storeDetailControls.state.showErrorMsg = false;
      storeDetailControls.pincode.showErrorMsg = false;
      storeDetailControls.district.showErrorMsg = false;
      getCity(suggestion.dealer_state_id);
      getStateDistrict(suggestion.dealer_state_id);
    }
    if (suggestion.dealer_address) {
      storeDetailControls.address.value = suggestion.dealer_address;
      storeDetailControls.address.showErrorMsg = false;
    }
    if (suggestion.dealer_city_id) {
      storeDetailControls.city.value = suggestion.dealer_city_id;
      storeDetailControls.city.showErrorMsg = false;
    }
    if (suggestion.pin_code) {
      storeDetailControls.pincode.value = suggestion.pin_code;
      storeDetailControls.pincode.showErrorMsg = false;
    }
    storeDetailControls.district.value = '';
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(false,
      storeDetailControls);
    if (suggestion.rms_count >= 1) {
      toggle();
      // ModalService.openAlert("", "You have already submitted Recce for this dealer during last year, do you still want to submit this new Recce?", "error");
    }
    return suggestion.business_name;
  };
  const searchDealer = (e, type) => {
    let reqData = {
      q: e,
    };
    setIsLoading(true);
    RetailMarketingSystemService.serachDealer(reqData)
      .then((res) => {
        setIsLoading(false);
        let dealerListTemp = [];
        if (res && res.data && res.data.data && res.data.data.length >= 0) {
          dealerListTemp = res.data.data;
        }
        setDealerList(dealerListTemp);
      })
      .catch((e) => {

      });
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions(value);
  };

  const onChangeDealer = (event, { newValue }) => {
    if (!newValue) {
      storeDetailControls.dealer.value = '';
      storeDetailControls.dealer.touched = true;
      handleValidationRetailMarketingStoreInformationAction(false,
        storeDetailControls);
    }

    storeDetailControls.dealer.displayValue = newValue;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  };

  const inputProps = {
    placeholder: "Locate the dealer by SAP code",
    value: storeDetailControls.dealer.displayValue,
    onChange: onChangeDealer,
  };
  return (
    <div style={{ position: 'relative' }}>
      {isLoading && <CustomSpinner />}
      {duplicate && <Redirect
        to={{
          pathname: "/retail-marketing-system"
        }}
      />}
      <Card id="RetailMarketingStoreInformation">
        <CardBody>
          <Row>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>Recce Date <span className="required-field">*</span></Label>
                {
                  !isEdit ?
                    <div className="d-flex">
                      <Label onClick={e => calendarRecce.state.open && e.preventDefault()} className="mb-0">
                        <InputGroup>
                          <DatePicker className={"form-control"}
                            onChange={(value) =>
                              handleInputChange({
                                target: {
                                  name: "recce_date",
                                  value: value,
                                },
                              })
                            }
                            name="recce_date"
                            selected={storeDetailControls.recce_date.value}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            ref={(r) => { calendarRecce = r; }}
                            shouldCloseOnSelect={true}
                            showYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            autoComplete='off'
                            placeholderText="Select"
                          />
                          <InputGroupAddon addonType="append" >
                            <div className="input-group-text" style={{ paddingRight: '8px' }}>
                              <img src="/images/calendar_icon.svg" className="calendar-icon" alt='' />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </div> :
                    (storeDetailControls.recce_date.value &&
                      <div className="font-weight-bold" style={{ fontSize: '16px' }}>
                        <Moment format="DD MMM, YYYY">
                          {storeDetailControls.recce_date.value}
                        </Moment>
                      </div>)
                }
                {storeDetailControls.recce_date.showErrorMsg && (
                  <div className="error">
                    * Please select recce date.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xl="6" md="12" xs="12">
              <Label for="pan">
                Name of the Store<span className="required-field">*</span>
              </Label>
              <div className="auto-suggest">
                <Autosuggest
                  suggestions={dealerList}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={
                    onSuggestionsClearRequested
                  }
                  getSuggestionValue={selectDealer}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  focusInputOnSuggestionClick={false}
                />
                <img
                  src="images/search.svg"
                  alt=''
                  className="report-search icon-width"
                  style={{ top: '39px' }}
                />
              </div>
              {storeDetailControls.dealer.showErrorMsg && (
                <div className="error">
                  * Please select name of the store.
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label for="pan">
                  Address<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <TextareaAutosize
                    name="address"
                    autocomplete="nofill"
                    id="address"
                    value={storeDetailControls.address.value}
                    style={{ minHeight: 60, maxHeight: 70, fontSize: "14px" }}
                    className="form-control"
                    onChange={(e) =>
                      handleInputChange({
                        target: { name: "address", value: e.target.value },
                      })
                    }
                    placeholder="Type Here"
                  />
                </div>
                {storeDetailControls.address.showErrorMsg && (
                  <div className="error">
                    * Please enter address.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  State<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="state"
                    value={storeDetailControls.state.value}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" selected disabled>
                      Select
                      </option>
                    {stateData &&
                      stateData.map((item, i) => (
                        <option value={item.state_id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                {storeDetailControls.state.showErrorMsg && (
                  <div className="error">
                    * Please select state.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  City<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="city"
                    value={storeDetailControls.city.value}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" selected disabled>
                      Select
                      </option>
                    {cityData &&
                      cityData.map((item, i) => (
                        <option value={item.city_id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                {storeDetailControls.city.showErrorMsg && (
                  <div className="error">
                    * Please select city.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  District<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="district"
                    value={storeDetailControls.district.value}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" selected disabled>
                      Select
                      </option>
                    {districtData &&
                      districtData.map((item, i) => (
                        <option value={item.district_id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                {storeDetailControls.district.showErrorMsg && (
                  <div className="error">
                    * Please select district.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  Pincode<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <Input
                    type="text"
                    name="pincode"
                    value={storeDetailControls.pincode.value}
                    maxLength="6"
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() => onBlurPincode()}
                    placeholder="Type Here"
                  />
                </div>
                {storeDetailControls.pincode.showErrorMsg && (
                  <div className="error">
                    * Please enter pincode.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        id="invite-dealer-modal"
        backdrop="static"
      >
        <ModalBody>
          <div>
            <img src="/images/info-3.png" alt='' style={{ marginLeft: '45%', marginTop: '24px' }} />
          </div>
          <div style={{ marginTop: '24px', fontFamily: 'Helvetica', textAlign: 'center', fontSize: '14px', lineHeight: '16px', color: '#495057' }}>
            You have already submitted Recce for this dealer during last year, do you still want to continue with this new Recce?
         </div>
          <div style={{ textAlign: "right", marginTop: '34px' }}>
            <Button style={{ width: '116px' }} className="mb-1 mr-1 btn-pill Rectangle-button cancel-button" color="dark" onClick={() => {
              toggle();
              goBack();
            }}>No</Button>{' '}
            <Button style={{ width: '116px' }} className="mb-1 mr-1 btn-pill Rectangle-button send-button" color="warning" onClick={() => {
              toggle()
            }}>Yes</Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
RetailMarketingStoreInformation.propTypes = {
  storeDetailControls: PropTypes.instanceOf(Object).isRequired,
  setRetailRecciControlsAction: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    storeDetailControls: getRetailMarketingStoreInformationState(state)
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setRetailRecciControlsAction: setRetailRecciControls,
      handleValidationRetailMarketingStoreInformationAction: handleValidationRetailMarketingStoreInformation,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetailMarketingStoreInformation);