import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import { Redirect, withRouter } from "react-router-dom";
import swal from "sweetalert";
import * as Scroll from "react-scroll";
import SelectPurpose from "./SelectPurpose/SelectPurpose";
import CustomerDetail from "./CustomerDetail/CustomerDetail";
import VehicleDetail from "./VehicleDetail/VehicleDetail";
import VehicleTradeHistory from "./VehicleTradeHistory/VehicleTradeHistory";
import EditCustomerDetail from "./EditCustomer/EditCustomer";
import SetReminder from "./SetReminder/SetReminder";
import AddTyrePurchaseDetail from "./AddTyrePurchaseDetail/AddTyrePurchaseDetail";
// services
import AddCustomerService from "../../services/AddCustomerService";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import UpcomingAppointment from "./UpcomingAppointment/UpcomingAppointment";
import AddCustomerSuccess from "./AddCustomerSuccess/AddCustomerSuccess";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import Storage from "../../services/Storage";
import "./AddCustomer.css";
import MarketingCouponService from "../../services/MarketingCouponService";
import VerifyCoupon from "../VerifyCoupon/VerifyCoupon";
import ReferralRewardsPreference from "../ReferralRewardsPreference/ReferralRewardsPreference";
import UserService from "../../services/UserService";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import ModalServicePref from "../../pages/DealerDashboard/ModalServicePref";
import BuyProduct from "../BuyProduct/BuyProduct";
import CampaignService from "../../services/CampaignService";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

const { ERROR_MESSAGE } = messages.COMMON;
class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPurpose: null,
      Label: "ADD CUSTOMER DETAILS",
      vehicle_label: "ADD VEHICLE DETAIL",
      userDetail: Storage.getUserDetail(),
      get_user_info: {},
      offer_data: {},
      customer_services: [],
      customer_services_lcv_scv: null,
      selectedServices: [],
      current_page: this.props.phone_number
        ? "edit_customer_detail"
        : "select_purpose",
      back: null,
      vehicleisToEdit: false,
      phone_number: null,
      selectPurposeref: {},
      vehicleDetailref: {},
      customerDetailref: {},
      toEditVehicleData: {},
      customer_vehicle_id: null,
      customer_vehicle: [],
      is_rediract_dashboard: false,
      customer_detail: {},
      is_edit_from_customer_list: false,
      Existing: false,
      backBreadCrumbs: [],
      nextBtnLable: "Next",
      is_added_from_warranty: false,
      storage_add_customer_warranty_data: {},
      is_redirect_to_warranty: false,
      dashborad_path: "/",
      page_history_config: [],
      /**page_redirect_config object for current_page , title and to display nextBtn, back button and hide button */
      page_redirect_config: {
        current_page: "select_purpose",
        title: "ADD CUSTOMER",
        nextBtnLabel: "Next",
        is_display_next: true,
        is_display_back: false,
        is_display_cancel: true,
        is_hide_all_btn: false,
      },
      couponData: {},
      success_detail: {},
      is_display_referral_reward: false,
      openSendVerifyOtp: false,
      quantity: 0,
      tyre_purchase_due_date: null,
      is_display_add_customer_sucesss: false,
      is_display_buy_sms: false,
      sms_credits: {
        credit: 0,
      },
    };
    this.selectPurposeref = React.createRef();
    this.setReminderDetailref = React.createRef();
    this.customerDetailref = React.createRef();
    this.vehicleDetailref = React.createRef();
    this.editCustomerDetailref = React.createRef();
    this.AddTyrePurchaseDetailref = React.createRef();
    this.UpcomingAppointmentRef = React.createRef();
    this.VehicleTradeHistoryRef = React.createRef();

    if (
      this.props &&
      this.props.couponData &&
      Object.keys(this.props.couponData).length > 0
    ) {
      this.state.couponData = this.props.couponData;
    }
    /**Opening the side bar in warranty-score-card page for add customer visit flow for sub_delear and branch*/
    if (
      this.state.userDetail &&
      (this.state.userDetail.type === Roles.SUB_DEALER_ROLE ||
        this.state.userDetail.type === Roles.BRANCH_ROLE)
    ) {
      this.state.dashborad_path = "/warranty-score-card";
    }

    if (Storage.getAddCustomerSuccessDetail()) {
      this.state.success_detail = Storage.getAddCustomerSuccessDetail();
      this.state.is_display_add_customer_sucesss = true;
    } else if (Storage.getAddCustomerData()) {
      this.state.storage_add_customer_warranty_data = Storage.getAddCustomerData();
      if (
        this.state.storage_add_customer_warranty_data &&
        Object.keys(this.state.storage_add_customer_warranty_data).length > 0
      ) {
        if (!this.state.storage_add_customer_warranty_data.vehicle_id) {
          Storage.removeAddCustomerData();
          this.state.storage_add_customer_warranty_data = {};
        }
      }
    }
    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)
    ) {
      this.state.dashborad_path = "/dealer-dashboard";
    }
  }

  /**Visit History from customer list vehicle open in sidebar */
  openVisitHistoryFromCUstomerListVehicle = async () => {
    await this.setNextPageRedirectConfig({
      title: "EDIT CUSTOMER DETAILS",
      current_page: "edit_customer_detail",
      is_display_back: true,
      is_display_next: true,
      is_hide_all_btn: false,
      nextBtnLabel: "Save",
      is_display_cancel: false,
    });
    await this.setState({
      phone_number: this.props.phone_number,
      is_edit_from_customer_list: true,
      customer_detail: {
        customer_id: this.props.customer.customer_id,
      },
    });
    GoogleAnalticsService.pageView("Vehicle Detail");
    await this.getCustomerDetail();
  };

  /**Visit History from servie taken  */
  openVisitHistoryFromServiceTaken = async () => {
    await this.setNextPageRedirectConfig({
      title: "VISIT HISTORY",
      current_page: "vehicle_trade_history",
      is_display_back: true,
      is_display_next: false,
      is_hide_all_btn: false,
      nextBtnLabel: "Save",
      is_display_cancel: false,
    });
    await this.setState({
      phone_number: this.props.phone_number,
      is_edit_from_customer_list: true,
      customer_detail: {
        customer_id: this.props.serviceDetailModalCustomer.customer_id,
      },
    });
    GoogleAnalticsService.pageView("Vehicle History View");
    //  await this.getCustomerDetail();
    /**Setting the props in VehicleTradeHistory Component from ref */
    this.VehicleTradeHistoryRef.current.setProps();
  };

  componentDidMount = () => {
    GoogleAnalticsService.pageView("Add Customer Visit View");
    this.getSMSCredits(false);
    if (this.props.phone_number) {
      if (this.props.is_open_visit_history) {
        this.openVisitHistoryFromServiceTaken();
      } else if (this.props.is_open_vehcile_detail_list) {
        this.openVisitHistoryFromCUstomerListVehicle();
      } else {
        this.editCustomerFromCustomerList();
      }
      this.setState(
        {
          phone_number: this.props.phone_number,
          is_edit_from_customer_list: true,
        },
        () => {
          this.getCustomerDetail();
        }
      );
    } else {
      GoogleAnalticsService.event(
        "Add Customer Visit",
        "Add New Cutomer Click"
      );
      if (Storage.getAddCustomerData()) {
        let data = Storage.getAddCustomerData();
        if (data.phone_number) {
          if (
            data.vehicle_category === "cargo" ||
            data.vehicle_category === "passenger"
          ) {
            this.serviceForLCVSCV(data);
          } else {
            this.redirectToAddTyrePurchase();
            this.setState(
              {
                is_added_from_warranty: true,
              },
              () => {
                this.getCustomerDetail(data.phone_number);
              }
            );
          }
        }
      } else {
        if (this.selectPurposeref.current) {
          this.selectPurposeref.current.customInit();
        }
      }
      this.getUserDetail();
      this.getDealerReferalReward();
    }
    document.body.style.overflow = "hidden";
    // debugger
    // this.refs.refAddCustomerSuccess.toggle({
    //   "customer_id": "d8153c81-f7b1-42c0-8da8-cb54c38963a7",
    //   "customer_history_id": "5db02483-7a11-416f-b46c-ab72801ad3d8"
    // });
  };

  editCustomerFromCustomerList = async () => {
    await this.setNextPageRedirectConfig({
      title: "EDIT CUSTOMER DETAILS",
      current_page: "edit_customer_detail",
      is_display_back: false,
      is_display_next: true,
      is_hide_all_btn: false,
      nextBtnLabel: "Save",
      is_display_cancel: false,
    });
  };

  /**Redirect to add Purchase tyre */
  redirectToAddTyrePurchase = async () => {
    await this.setNextPageRedirectConfig({
      title: "ADD TYRE PURCHASE DETAILS",
      current_page: "AddTyrePurchaseDetail",
      is_display_back: false,
      is_display_next: true,
      is_hide_all_btn: false,
      nextBtnLabel: "Submit",
      is_display_cancel: true,
    });
    this.AddTyrePurchaseDetailref.current.customInit();
  };

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  /** Get Customer Vehicle detail */
  getCustomerVehicle = async () => {
    // let customerDetailref = this.customerDetailref.current;
    // if (!customerDetailref.state.customer_detail.customer_id) {
    //   return
    // }
    this.setState({
      isLoading: true,
    });
    let params = {
      country_code: "91",
      phone_number: this.state.phone_number,
    };
    await AddCustomerService.getCustomerDetail(params)
      .then((res) => {
        let { customer_vehicle } = this.state;
        if (res.data.data && Object.keys(res.data.data).length > 0) {
          customer_vehicle = res.data.data && res.data.data.customer_vehicle;
        } else {
          customer_vehicle = [];
        }
        /**Setting the response of customer_vehicle and configuring the redirect based on different condition */
        this.setState(
          {
            customer_vehicle,
          },
          () => {
            this.setState({
              isLoading: false,
            });
            let { page_redirect_config, page_history_config } = this.state;
            if (this.state.is_edit_from_customer_list) {
              if (this.props.is_open_visit_history) {
              } else {
                if (this.editCustomerDetailref.current) {
                  this.editCustomerDetailref.current.setProps();
                }
              }
            } else {
              if (
                customer_vehicle.length <= 0 &&
                page_redirect_config.current_page === "vehicle_detail"
              ) {
                if (this.customerDetailref.current) {
                  /**Setting the customer detail using customInit method written in CustomerDetail.js using ref */
                  this.customerDetailref.current.customInit();
                }
                page_redirect_config.is_display_next = true;
                page_redirect_config.is_display_back = true;
                page_redirect_config.is_hide_all_btn = false;
                page_redirect_config.nextBtnLabel = "Next";
                this.setState({
                  page_redirect_config,
                });
              } else {
                /** filtering from page_history_config array*/
                let findIndex = page_history_config.findIndex(
                  (x) => x.current_page === "customer_detail"
                );
                /**If not found hide all buttons */
                if (findIndex > -1) {
                  page_history_config[findIndex].is_hide_all_btn = true;
                  this.setState({
                    page_history_config,
                  });
                } else if (
                  page_redirect_config.current_page === "customer_detail"
                ) {
                  /**hide all buttons if page_redirect_config.current_page === "customer_detail" */
                  page_redirect_config.is_hide_all_btn = true;
                  this.setState({
                    page_redirect_config,
                  });
                }
              }
            }
          }
        );
        return res.data.data;
      })
      .catch((e) => {
        console.log("error", e);
        this.setState({
          isLoading: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        return false;
      });

    // AddCustomerService.getCustomerVehicle(customerDetailref.state.customer_detail.customer_id)
    //   .then(res => {
    //     let { customer_vehicle } = this.state;
    //     if (res.data.data && res.data.data.length > 0) {
    //       customer_vehicle = res.data.data;
    //     } else {
    //       customer_vehicle = [];
    //     }

    //     this.setState({
    //       customer_vehicle,
    //       isLoading: false
    //     }, () => {
    //       if (this.state.is_edit_from_customer_list) {
    //         if (this.editCustomerDetailref.current) {
    //           this.editCustomerDetailref.current.setProps();
    //         }
    //       } else {
    //         if (this.customerDetailref.current) {
    //           this.customerDetailref.current.customInit();
    //         }
    //         let { page_redirect_config } = this.state;
    //         page_redirect_config.is_display_next = true;
    //         page_redirect_config.is_display_back = true;
    //         page_redirect_config.is_hide_all_btn = false;
    //         page_redirect_config.nextBtnLabel = 'Next';
    //         this.setState({
    //           page_redirect_config
    //         })
    //       }
    //     });
    //   })
    //   .catch(e => {
    //     this.setState({ isLoading: false });
    //     const err =
    //       e.response && e.response.data && e.response.data.error
    //         ? e.response.data.error[0]
    //         : null;
    //     const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
    //     ModalService.openAlert("", message, "error");
    //   });
  };

  selectPurpose = (purpose) => {
    this.setState({
      selectedPurpose: purpose,
      error: {},
    });
  };

  /**Getting the customer detail method on phone number */
  getCustomerDetail = async (phone_number = null) => {
    let { nextBtnLable } = this.state;
    let params = {
      country_code: "91",
      phone_number: phone_number ? phone_number : this.state.phone_number,
    };
    /**Setting the button Label to Save*/
    if (this.props.phone_number) {
      nextBtnLable = "Save";
    }
    this.setState({
      isLoading: true,
      nextBtnLable,
    });

    let cusromer_detail_data = await AddCustomerService.getCustomerDetail(
      params
    )
      .then((res) => {
        let {
          Existing,
          customer_vehicle,
          customer_detail,
          backBreadCrumbs: [],
        } = this.state;
        if (res.data.data && Object.keys(res.data.data).length > 0) {
          Existing = true;
          customer_vehicle = res.data.data && res.data.data.customer_vehicle;
          customer_detail = res.data.data && res.data.data.customer;
        } else {
          customer_vehicle = [];
          customer_detail = {};
          Existing = false;
        }

        // let routUrl =
        //   window.location.href &&
        //   window.location.href.split("/")[4] &&
        //   window.location.href.split("/")[4];
        // if (routUrl) {
        //   this.setState({
        //     redirectToDashboard: true
        //   })
        // }
        if (this.state.is_redirect_to_warranty) {
          // let href = window.location.href;
          /**Adding the value to localStorage and opening the verify Otp Dialog */
          Storage.setAddCustomerData({
            phone_number: phone_number ? phone_number : this.state.phone_number,
          });
          this.setState({
            openSendVerifyOtp: true,
          });
          // if (this.state.dashborad_path === '/dealer-dashboard' || this.state.dashborad_path === '/') {
          //   this.props.closeAddCustomer({
          //     is_warranty_redirect: true
          //   })
          // } else {
          //   this.setState({
          //     is_rediract_dashboard: true
          //   })
          // }
        }
        this.setState(
          {
            customer_vehicle,
            customer_detail,
            Existing,
            phone_number: phone_number ? phone_number : this.state.phone_number,
          },
          () => {
            this.setState({
              isLoading: false,
            });
            if (this.state.is_added_from_warranty) {
              if (this.selectPurposeref.current) {
                this.selectPurposeref.current.customInit();
              }
            }
            // if (customer_vehicle && customer_vehicle.length > 0) {
            //   if (this.vehicleDetailref.current) {
            //     this.vehicleDetailref.current.init(0);
            //   }
            // }

            /**This sets the value of the customerDetail in CustomerDetail.js */
            if (this.customerDetailref.current) {
              this.customerDetailref.current.customInit();
            }
            // if (this.AddTyrePurchaseDetailref.current) {
            //   this.AddTyrePurchaseDetailref.current.customInit();
            // }

            /**This sets the value of the customer Detail in EditCustomer.js */
            if (this.editCustomerDetailref.current) {
              this.editCustomerDetailref.current.setProps();
            }
          }
        );
        return res.data.data;
      })
      .catch((e) => {
        console.log("error", e);
        this.setState({
          isLoading: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        return false;
      });

    return cusromer_detail_data;
  };

  serviceForLCVSCV = (data) => {
    this.setState({
      isLoading: true,
    });

    AddCustomerService.getPurpose()
      .then((result) => {
        let index = result.data.data.findIndex(
          (x) => x.type === "tyre_purchase"
        );

        AddCustomerService.getBrandsRadial().then((response) => {
          /**if brand response exist creating the obj with response to get the existing customer service visist */
          let filter;
          if (
            response.data.data &&
            response.data.data.brand &&
            response.data.data.brand.length > 0
          ) {
            filter = response.data.data.brand.filter(
              (x) => x.type.toLowerCase() === "jk_tyre"
            );
            let obj = {
              customer_id: data.customer_id,
              customer_vehicle_id: data.customer_vehicle_id,
              purpose_id: result.data.data[index].purpose_id,
              services: [],
              brand_id: filter[0].id,
              quantity: data.qty,
              tyre_purchase_type: result.data.data[index].type,
              vehical_avg_monthly_running: 0,
              vehical_meter_reading: 0,
            };
            obj.tyre_purchase_type =
              obj.tyre_purchase_type === "other"
                ? "other"
                : "jk pcr and 2-3 wheeler";

            AddCustomerService.existingCustomerServiceVisit(obj)
              .then((response) => {
                response.data.data.message = response.data.message;

                this.setState({
                  success_detail: { ...response.data.data },
                  is_display_add_customer_sucesss: true,
                });

                this.setState({
                  isLoading: false,
                });
              })
              .catch((e) => {
                const err =
                  e.response && e.response.data && e.response.data.error
                    ? e.response.data.error[0]
                    : null;
                const message =
                  err && err.message
                    ? err.message
                    : messages.COMMON.ERROR_MESSAGE;
                ModalService.openAlert("", message, "error");
                console.log(e);
                this.setState({
                  isLoading: false,
                });
              });
          }
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  redirectToSetting = () => {
    // this.props.history.push("/notification-preference?is_from_add_customer=true");
    this.refs.modalServicePref.toggle({ is_from_add_customer_visit: false });
  };

  selectServices = (e, id) => {
    e.preventDefault();
    let { selectedServices, customer_services } = this.state;
    let index = selectedServices.findIndex((x) => x === id);
    let indexData = customer_services.findIndex((x) => x.service_id === id);
    if (index > -1) {
      selectedServices.splice(index, 1);
      customer_services[indexData].active = false;
    } else {
      if (selectedServices.indexOf(id) === -1) {
        selectedServices.push(id);
      }
      customer_services[indexData].active = true;
    }
    this.setState({ selectedServices, customer_services });
  };

  backPage = () => {
    let { page_history_config, page_redirect_config } = this.state;
    if (page_history_config.length > 0) {
      page_redirect_config =
        page_history_config[page_history_config.length - 1];
      page_history_config.pop();
    }
    if (this.props.is_open_visit_history) {
      this.props.closeAddCustomer({
        is_close_visit_history: true,
      });
      return;
    }
    if (
      this.props.is_open_vehcile_detail_list &&
      page_history_config.length === 0
    ) {
      this.props.closeAddCustomer({
        is_close_vehcile_detail_list: true,
      });
      return;
    }
    this.setState({
      page_redirect_config,
      page_history_config,
    });
    return;
  };

  editLabel = () => {
    this.setState({
      Label: "CUSTOMER DETAILS",
    });
  };

  SaveVehicleDetailForInquiry = async () => {
    /**Setting the data from child component using ref's */
    //let { selectPurposeref, vehicleDetailref } = this.state;
    let customerDetailref = this.customerDetailref.current.state;
    let selectPurposeref = this.selectPurposeref.current.state;
    let vehicleDetailref = this.vehicleDetailref.current.state;
    let vehicle_type_brand_model_name = "",
      vehicle_type_brand_name = "",
      vehicle_type_brand_id = null,
      vehicle_type_brand_model_id = null;
    if (vehicleDetailref.isEnterVehicleMakeModal) {
      vehicle_type_brand_model_name =
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value;
      vehicle_type_brand_name =
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value;
      vehicle_type_brand_id = null;
      vehicle_type_brand_model_id = null;
    } else {
      vehicle_type_brand_model_name =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value.name;
      vehicle_type_brand_name =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value
          .name;
      vehicle_type_brand_id =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value
          .brand_id;
      vehicle_type_brand_model_id =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value.model_id;
    }
    let obj = {
      phone_number: this.state.phone_number && this.state.phone_number,
      first_name:
        customerDetailref && customerDetailref.controls.first_name.value,
      last_name:
        customerDetailref && customerDetailref.controls.last_name.value,
      email_id: customerDetailref && customerDetailref.controls.email_id.value,
      birth_date:
        customerDetailref && customerDetailref.controls.birth_date.value,
      anniversary_date:
        customerDetailref && customerDetailref.controls.anniversary_date.value,
      remark: customerDetailref.controls.remark.value,
      group_id: [],
      purpose_id: selectPurposeref.controls.selectedPurpose.value.purpose_id,
      vehicle_type_id:
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_id.value,
      vehicle_type_brand_id: vehicle_type_brand_id,
      vehicle_type_brand_model_id: vehicle_type_brand_model_id,
      vehicle_type_brand_name: vehicle_type_brand_name,
      vehicle_type_brand_model_name: vehicle_type_brand_model_name,
      vehical_meter_reading:
        vehicleDetailref &&
        vehicleDetailref.vehicle_meter_reading &&
        parseInt(vehicleDetailref.vehicle_meter_reading),
      vehical_avg_monthly_running:
        vehicleDetailref &&
        vehicleDetailref.AVG_monthly_usage &&
        parseInt(vehicleDetailref.AVG_monthly_usage),
      registration_number: vehicleDetailref && vehicleDetailref.registration,
      vehicle_category:
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.vehicleCategory.value,
      type_of_inquiry: selectPurposeref.controls.type_of_inquiry.value,
    };
    if (
      customerDetailref.controls.group_id.value &&
      customerDetailref.controls.group_id.value.length > 0
    ) {
      obj.group_id = [];
      customerDetailref.controls.group_id.value.forEach((element) => {
        obj.group_id.push(element.group_id);
      });
    }
    this.setState({
      isLoading: true,
    });
    let responseData = await AddCustomerService.SaveVehicleDetail(obj)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        response.data.data.message = response.data.message;
        swal({
          title: "",
          text: response.data.message,
          icon: "success",
          type: "success",
        }).then((isConfirm) => {
          this.handleOnCancel({ refresh_list: true });
        });
        // response.data.data.is_hide_add_reff = true;
        //this.refs.refAddCustomerSuccess.toggle({ ...response.data.data });
        return response.data.data;
      })
      .catch((e) => {
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        this.setState({
          isLoading: false,
        });
        return false;
      });
    return responseData;
  };

  SaveVehicleDetailForService = async () => {
    /**Setting the data from child component using refs */
    //let { customerDetailref, selectPurposeref, vehicleDetailref } = this.state;
    let customerDetailref = this.customerDetailref.current.state;
    let selectPurposeref = this.selectPurposeref.current.state;
    let vehicleDetailref = this.vehicleDetailref.current.state;
    let vehicle_type_brand_model_name = "",
      vehicle_type_brand_name = "",
      vehicle_type_brand_id = null,
      vehicle_type_brand_model_id = null;
    if (vehicleDetailref.isEnterVehicleMakeModal) {
      vehicle_type_brand_model_name =
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value;
      vehicle_type_brand_name =
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value;
      vehicle_type_brand_id = null;
      vehicle_type_brand_model_id = null;
    } else {
      vehicle_type_brand_model_name =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value.name;
      vehicle_type_brand_name =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value
          .name;
      vehicle_type_brand_id =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value
          .brand_id;
      vehicle_type_brand_model_id =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value.model_id;
    }
    if (selectPurposeref.controls.selectedPurpose.type === "service") {
      let validation = this.AddTyrePurchaseDetailref.current.handleTyrePurchaseValidation(
        true,
        true,
        this.setReminderDetailref.current.state.tyre_purchase_due_date
      );
      if (
        vehicleDetailref.props.customer_vehicle &&
        vehicleDetailref.state.controls.vehicle_id.value
      ) {
        var selectedVehicleIndex = vehicleDetailref.props.customer_vehicle.findIndex(
          (x) =>
            x.vehicle_id === vehicleDetailref.state.controls.vehicle_id.value
        );
        if (selectedVehicleIndex > -1) {
          var selectedVehicle =
            vehicleDetailref.props.customer_vehicle[selectedVehicleIndex];
          if (
            !selectedVehicle ||
            !selectedVehicle.tyre_purchase_due_date ||
            (selectedVehicle &&
              selectedVehicle.tyre_purchase_due_date &&
              moment(selectedVehicle.tyre_purchase_due_date).valueOf() <
              moment().valueOf())
          ) {
            if (!validation) {
              ModalService.openAlert(
                "",
                "Please select next tyre replacement date.",
                "warning"
              );
              this.setState({ isLoading: false });
              return;
            }
            if (
              !this.setReminderDetailref.current.state.tyre_purchase_due_date
            ) {
              ModalService.openAlert(
                "",
                "Please select next tyre replacement date.",
                "warning"
              );
              this.setState({ isLoading: false });
              return;
            }
          }
        } else {
          if (!validation) {
            ModalService.openAlert(
              "",
              "Please select next tyre replacement date.",
              "warning"
            );
            this.setState({ isLoading: false });
            return;
          }
          if (!this.setReminderDetailref.current.state.tyre_purchase_due_date) {
            ModalService.openAlert(
              "",
              "Please select next tyre replacement date.",
              "warning"
            );
            this.setState({ isLoading: false });
            return;
          }
        }
      } else {
        if (!validation) {
          ModalService.openAlert(
            "",
            "Please select next tyre replacement date.",
            "warning"
          );
          this.setState({ isLoading: false });
          return;
        }
        if (!this.setReminderDetailref.current.state.tyre_purchase_due_date) {
          ModalService.openAlert(
            "",
            "Please select next tyre replacement date.",
            "warning"
          );
          this.setState({ isLoading: false });
          return;
        }
      }
      if (
        !selectedVehicle ||
        !selectedVehicle.tyre_purchase_due_date ||
        (selectedVehicle &&
          selectedVehicle.tyre_purchase_due_date &&
          moment(selectedVehicle.tyre_purchase_due_date).valueOf() <
          moment().valueOf())
      ) {
        if (
          this.setReminderDetailref &&
          this.setReminderDetailref.current &&
          this.setReminderDetailref.current.state &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail
            .select_brand_radio &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail
            .select_brand_radio.value === "other" &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail.brand_id &&
          !this.setReminderDetailref.current.state.tyrePurchaseDetail.brand_id
            .value
        ) {
          this.setState({ isLoading: false });
          return ModalService.openAlert("", "Please select brand.", "warning");
        }
      }
    }
    let obj = {
      phone_number: this.state.phone_number && this.state.phone_number,
      first_name:
        customerDetailref && customerDetailref.controls.first_name.value,
      last_name:
        customerDetailref && customerDetailref.controls.last_name.value,
      email_id: customerDetailref && customerDetailref.controls.email_id.value,
      birth_date:
        customerDetailref && customerDetailref.controls.birth_date.value,
      anniversary_date:
        customerDetailref && customerDetailref.controls.anniversary_date.value,
      remark: customerDetailref.controls.remark.value,
      group_id: [],
      purpose_id: selectPurposeref.controls.selectedPurpose.value.purpose_id,
      vehicle_type_id:
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_id.value,
      vehicle_type_brand_id: vehicle_type_brand_id,
      vehicle_type_brand_model_id: vehicle_type_brand_model_id,
      vehicle_type_brand_name: vehicle_type_brand_name,
      vehicle_type_brand_model_name: vehicle_type_brand_model_name,
      vehical_meter_reading:
        vehicleDetailref &&
        vehicleDetailref.vehicle_meter_reading &&
        parseInt(vehicleDetailref.vehicle_meter_reading),
      vehical_avg_monthly_running:
        vehicleDetailref &&
        vehicleDetailref.AVG_monthly_usage &&
        parseInt(vehicleDetailref.AVG_monthly_usage),
      registration_number: vehicleDetailref && vehicleDetailref.registration,
      vehicle_category:
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.vehicleCategory.value,
      services: [],
      tyre_purchase_due_date:
        !selectedVehicle ||
          !selectedVehicle.tyre_purchase_due_date ||
          (selectedVehicle &&
            selectedVehicle.tyre_purchase_due_date &&
            moment(selectedVehicle.tyre_purchase_due_date).valueOf() <
            moment().valueOf())
          ? this.setReminderDetailref.current.state.tyre_purchase_due_date
          : null,
      quantity: 1,
      brand_id:
        this.AddTyrePurchaseDetailref.current &&
        this.AddTyrePurchaseDetailref.current.state &&
        this.AddTyrePurchaseDetailref.current.state.controls &&
        this.AddTyrePurchaseDetailref.current.state.controls.brand_id &&
        this.AddTyrePurchaseDetailref.current.state.controls.brand_id.value &&
        Number(
          this.AddTyrePurchaseDetailref.current.state.controls.brand_id.value
        ),
      tyre_purchase_type:
        this.AddTyrePurchaseDetailref.current &&
          this.AddTyrePurchaseDetailref.current.state &&
          this.AddTyrePurchaseDetailref.current.state.controls &&
          this.AddTyrePurchaseDetailref.current.state.controls
            .select_brand_radio &&
          this.AddTyrePurchaseDetailref.current.state.controls.select_brand_radio
            .value
          ? this.AddTyrePurchaseDetailref.current.state.controls
            .select_brand_radio.value === "other"
            ? "other"
            : "jk pcr and 2-3 wheeler"
          : null,
    };

    if (this.setReminderDetailref.current) {
      if (this.setReminderDetailref.current.state.selected_service.length > 0) {
        this.setReminderDetailref.current.state.selected_service.forEach(
          (element) => {
            if (!element.is_set_service_due_date) {
              obj.services.push({
                service_id: element.service_id,
                set_reminder: true,
                remarks: element.remarks,
              });
            } else {
              obj.services.push({
                service_id: element.service_id,
                set_reminder: true,
                remarks: element.remarks,
                upcoming_due_date: element.upcoming_due_date,
              });
            }
          }
        );
      }
    }

    if (
      customerDetailref.controls.group_id.value &&
      customerDetailref.controls.group_id.value.length > 0
    ) {
      obj.group_id = [];
      customerDetailref.controls.group_id.value.forEach((element) => {
        obj.group_id.push(element.group_id);
      });
    }
    this.setState({
      isLoading: true,
    });
    let responseData = await AddCustomerService.SaveVehicleDetailForService(obj)
      .then((response) => {
        response.data.data.message = response.data.message;
        // this.refs.refAddCustomerSuccess.toggle({ ...response.data.data });
        this.setState({
          isLoading: false,
          success_detail: { ...response.data.data },
          is_display_add_customer_sucesss: true,
        });
        return response.data.data;
      })
      .catch((e) => {
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        this.setState({
          isLoading: false,
        });
        return false;
      });
    return responseData;
  };

  SaveVehicleDetailForTyrePurchase = async (isQuit = false) => {
    /**Setting the data for Tyre Purchase using refs */
    this.setState({
      quantity:
        this.AddTyrePurchaseDetailref.current &&
        Number(
          this.AddTyrePurchaseDetailref.current.state.controls.quantity.value
        ),
    });

    //let { customerDetailref, selectPurposeref, vehicleDetailref } = this.state;
    let customerDetailref = this.customerDetailref.current.state;
    let selectPurposeref = this.selectPurposeref.current.state;
    let vehicleDetailref = this.vehicleDetailref.current.state;
    let vehicle_type_brand_model_name = "",
      vehicle_type_brand_name = "",
      vehicle_type_brand_id = null,
      vehicle_type_brand_model_id = null;
    if (vehicleDetailref.isEnterVehicleMakeModal) {
      vehicle_type_brand_model_name =
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value;
      vehicle_type_brand_name =
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value;
      vehicle_type_brand_id = null;
      vehicle_type_brand_model_id = null;
    } else {
      vehicle_type_brand_model_name =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value.name;
      vehicle_type_brand_name =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value
          .name;
      vehicle_type_brand_id =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value
          .brand_id;
      vehicle_type_brand_model_id =
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value &&
        vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
          .value.model_id;
    }
    let obj = {
      phone_number: this.state.phone_number && this.state.phone_number,
      first_name:
        customerDetailref && customerDetailref.controls.first_name.value,
      last_name:
        customerDetailref && customerDetailref.controls.last_name.value,
      email_id: customerDetailref && customerDetailref.controls.email_id.value,
      birth_date:
        customerDetailref && customerDetailref.controls.birth_date.value,
      anniversary_date:
        customerDetailref && customerDetailref.controls.anniversary_date.value,
      remark: customerDetailref.controls.remark.value,
      group_id: [],
      purpose_id: selectPurposeref.controls.selectedPurpose.value.purpose_id,
      type_of_inquiry: selectPurposeref.controls.type_of_inquiry.value,
      vehicle_type_id:
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.custom_filter_vehicle_type_id.value,
      vehicle_type_brand_id: vehicle_type_brand_id,
      vehicle_type_brand_model_id: vehicle_type_brand_model_id,
      vehicle_type_brand_name: vehicle_type_brand_name,
      vehicle_type_brand_model_name: vehicle_type_brand_model_name,
      vehical_meter_reading:
        vehicleDetailref &&
        vehicleDetailref.vehicle_meter_reading &&
        parseInt(vehicleDetailref.vehicle_meter_reading),
      vehical_avg_monthly_running:
        vehicleDetailref &&
        vehicleDetailref.AVG_monthly_usage &&
        parseInt(vehicleDetailref.AVG_monthly_usage),
      registration_number: vehicleDetailref && vehicleDetailref.registration,
      vehicle_category:
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.vehicleCategory.value,
      brand_id:
        this.AddTyrePurchaseDetailref.current &&
        Number(
          this.AddTyrePurchaseDetailref.current.state.controls.brand_id.value
        ),
      quantity:
        this.AddTyrePurchaseDetailref.current &&
        Number(
          this.AddTyrePurchaseDetailref.current.state.controls.quantity.value
        ),
      tyre_purchase_type: selectPurposeref.controls.tyre_purchase_type.value,
      services: [],
    };
    if (
      customerDetailref.controls.group_id.value &&
      customerDetailref.controls.group_id.value.length > 0
    ) {
      obj.group_id = [];
      customerDetailref.controls.group_id.value.forEach((element) => {
        obj.group_id.push(element.group_id);
      });
    }
    if (this.AddTyrePurchaseDetailref.current && !isQuit) {
      if (
        this.AddTyrePurchaseDetailref.current.state.selected_service.length >
        0 &&
        this.AddTyrePurchaseDetailref.current.state.controls
          .set_service_reminder.value
      ) {
        this.AddTyrePurchaseDetailref.current.state.selected_service.forEach(
          (element) => {
            if (element.is_set_service_due_date === true) {
              if (element.is_add_service) {
                obj.services.push({
                  service_id: element.service_id,
                  set_reminder: true,
                  remarks: element.remarks,
                  upcoming_due_date: element.upcoming_due_date,
                });
              }
            }
          }
        );
      }
    }

    this.setState({
      isLoading: true,
    });
    let responseData = await AddCustomerService.SaveVehicleDetailForTyrePurchase(
      obj
    )
      .then((response) => {
        if (Storage.getAddCustomerData()) {
          Storage.removeAddCustomerData();
        }
        response.data.data.message = response.data.message;
        // this.refs.refAddCustomerSuccess.toggle({ ...response.data.data });
        this.setState({
          isLoading: false,
          success_detail: { ...response.data.data },
          is_display_add_customer_sucesss: true,
        });
        return response.data.data;
      })
      .catch((e) => {
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        return false;
      });
    return responseData;
  };

  redeemCoupon = (data) => {
    /**Post Method for the redeemCoupon */
    this.setState({
      isLoader: true,
    });
    MarketingCouponService.redeemCoupon(data)
      .then((data) => {
        this.setState({
          isLoader: false,
          quantity: 0,
        });
        if (data.data && data.data.success) {
          swal({
            title: "Congratulations!",
            text: data.data.message,
            icon: "success",
            type: "success",
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
          quantity: 0,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  /**Setting the redirect_page_config for edit vehicle Details */
  editVehicle = async (index) => {
    await this.setNextPageRedirectConfig({
      title: "EDIT VEHICLE DETAILS",
      current_page: "vehicle_detail",
      is_display_back: true,
      is_display_next: true,
      is_hide_all_btn: false,
      nextBtnLabel: "Save",
      is_display_cancel: false,
    });
    if (this.vehicleDetailref.current) {
      this.vehicleDetailref.current.init(index);
    }
  };

  saveCustomer = () => {
    if (this.editCustomerDetailref.current) {
      this.editCustomerDetailref.current.UpdateCustomer();
    }
  };

  viewHistory = (customer_vehicle_id = null) => {
    this.setState({ customer_vehicle_id: customer_vehicle_id }, () => {
      this.VehicleTradeHistoryRef.current.setProps();
      this.setNextPageRedirectConfig({
        title: customer_vehicle_id ? "VEHICLE TRADE HISTORY" : "VISIT HISTORY",
        current_page: "vehicle_trade_history",
        is_display_back: true,
        is_display_next: false,
        is_hide_all_btn: false,
        nextBtnLabel: "Save",
        is_display_cancel: false,
      });
    });
  };

  changeHeaderTitle = (title) => {
    /**Changing the header title of the side bar based on page_redirect_config  */
    let { page_history_config } = this.state;
    page_history_config.title = title;
    this.setState({
      page_history_config,
    });
  };

  handleOnCancel = (obj = {}) => {
    // let refAddCustomerSuccess = this.refs.refAddCustomerSuccess;
    let vehicleDetailref = this.vehicleDetailref.current.state;
    if (Storage.getAddCustomerData()) {
      Storage.removeAddCustomerData();
    }
    if (Storage.getAddCustomerSuccessDetail()) {
      Storage.removeAddCustomerSuccessDetail();
    }
    this.props.closeAddCustomer(obj);

    if (
      Object.keys(obj).length > 0 &&
      this.state.storage_add_customer_warranty_data &&
      Object.keys(this.state.storage_add_customer_warranty_data).length > 0 &&
      this.state.storage_add_customer_warranty_data.coupon_code
    ) {
      /**Preparing the data object to redeemCoupon from the storage_add_customer_warranty_data state which is filled by add_customer present in localStorage */
      let data = {
        phone_number: this.state.storage_add_customer_warranty_data
          .phone_number_coupon,
        token: this.state.storage_add_customer_warranty_data.token,
        coupon_code: this.state.storage_add_customer_warranty_data.coupon_code,
        redeemed_from: "customer_management",
        customer_id: this.state.storage_add_customer_warranty_data.customer_id,
        tyre_quantity: this.state.storage_add_customer_warranty_data.qty,
        // visit_history_id: refAddCustomerSuccess.state.oldDetail.customer_history_id,
        visit_history_id: this.state.success_detail.customer_history_id,
        vehicle_type_brand_id: this.state.storage_add_customer_warranty_data
          .vehicle_type_brand_id,
        vehicle_type_brand_model_id: this.state
          .storage_add_customer_warranty_data.vehicle_type_brand_model_id,
        sku_id_1: this.state.storage_add_customer_warranty_data.sku_id_1,
        sku_id_back: this.state.storage_add_customer_warranty_data.sku_id_back,
        sku_id_spare: this.state.storage_add_customer_warranty_data
          .sku_id_spare,
      };
      this.redeemCoupon(data);
    }

    if (
      Object.keys(obj).length > 0 &&
      obj.couponData &&
      Object.keys(obj.couponData).length > 0 &&
      obj.couponData.coupon_code
    ) {
      /**if CouponData is present than we create data object to check the customer */
      let data = {
        phone_number: obj.couponData.phone_number,
        token: obj.couponData.token,
        coupon_code: obj.couponData.coupon_code,
        redeemed_from: "customer_management",
        tyre_quantity: this.state.quantity,
        // visit_history_id: refAddCustomerSuccess.state.oldDetail.customer_history_id,
        visit_history_id: this.state.success_detail.customer_history_id,
        vehicle_type_brand_id:
          vehicleDetailref &&
          vehicleDetailref.controls &&
          vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value &&
          (vehicleDetailref.controls.custom_filter_vehicle_type_brand_id.value
            .brand_id ||
            vehicleDetailref.controls.custom_filter_vehicle_type_brand_id
              .value),
        vehicle_type_brand_model_id:
          vehicleDetailref &&
          vehicleDetailref.controls &&
          vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
            .value &&
          (vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
            .value.model_id ||
            vehicleDetailref.controls.custom_filter_vehicle_type_brand_model_id
              .value),
      };
      this.checkCustomer(data, obj);
    }
  };

  checkCustomer = (data, obj) => {
    /**Getting the  customer detail from the phoneNumber */
    this.setState({
      isLoading: true,
    });
    let reqData = {
      phone_number: data.phone_number,
    };

    AddCustomerService.getCustomerDetail(reqData)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        data.customer_id = res.data.data.customer.customer_id;
        data.first_name = res.data.data.customer.first_name;
        data.last_name = res.data.data.customer.last_name;
        this.redeemCoupon(data);
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  /**Configuring the cancel button present on header of sidebar */
  handleOnCancelAddCustomer = async () => {
    await this.setState(
      {
        couponData: {},
      },
      () => {
      }
    );

    if (!this.state.is_edit_from_customer_list) {
      if (
        this.state.page_redirect_config.current_page === "AddTyrePurchaseDetail"
      ) {
        if (
          Object.keys(this.state.storage_add_customer_warranty_data).length > 0
        ) {
          /**Open the confirm quit dialog box for tyre purchase flow  */
          this.confirmQuit();
        } else {
          this.confirmQuitInquiryAndService("tyre purchase");
        }
      } else if (
        /**Confirm Quit dialog for inquiry flow */
        this.state.page_redirect_config.current_page !== "select_purpose" &&
        this.selectPurposeref.current &&
        this.selectPurposeref.current.state.controls.selectedPurpose.value &&
        this.selectPurposeref.current.state.controls.selectedPurpose.value
          .type === "inquiry"
      ) {
        this.confirmQuitInquiryAndService("inquiry");
      } else if (
        /**Confirm Quit dialog for service flow */
        this.state.page_redirect_config.current_page !== "select_purpose" &&
        this.selectPurposeref.current &&
        this.selectPurposeref.current.state.controls.selectedPurpose.value &&
        this.selectPurposeref.current.state.controls.selectedPurpose.value
          .type === "service"
      ) {
        this.confirmQuitInquiryAndService("service");
      } else if (
        /**Confirm Quit dialog for tyre purchase flow */
        this.state.page_redirect_config.current_page !== "select_purpose" &&
        this.selectPurposeref.current &&
        this.selectPurposeref.current.state.controls.selectedPurpose.value &&
        this.selectPurposeref.current.state.controls.selectedPurpose.value
          .type === "tyre_purchase"
      ) {
        this.confirmQuitInquiryAndService("tyre purchase");
      } else {
        this.handleOnCancel();
      }
    } else {
      this.handleOnCancel();
    }
  };

  getTyrePurchaseDueDate(due_date) {
    let { tyre_purchase_due_date } = this.state;
    tyre_purchase_due_date = due_date;
    this.setState({ tyre_purchase_due_date });
  }

  addUpdateVehicle = async () => {
    if (this.vehicleDetailref.current) {
      if (!this.state.Existing) {
        if (
          this.selectPurposeref.current &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value
            .type === "inquiry"
        ) {
          let responseData = await this.SaveVehicleDetailForInquiry();
          // debugger
          // if (responseData) {
          //   this.vehicleDetailref.current.addVehicle(responseData.customer_id);
          // }
        } else if (
          this.selectPurposeref.current &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value
            .type === "service"
        ) {
          let responseData = await this.SaveVehicleDetailForService();
          // if (responseData) {
          //   this.vehicleDetailref.current.addVehicle(responseData.customer_id);
          // }
        } else if (
          this.selectPurposeref.current &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value
            .type === "tyre_purchase"
        ) {
          await this.existingCustomerTyrepurchaseVisit();
        }
      } else {
        if (
          this.selectPurposeref.current &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value
            .type === "inquiry"
        ) {
          this.existingCustomerInquiryVisit();
        } else if (
          this.selectPurposeref.current &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value
            .type === "service"
        ) {
          this.existingCustomerServiceVisit();
        } else if (
          this.selectPurposeref.current &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value
            .type === "tyre_purchase"
        ) {
          this.existingCustomerTyrepurchaseVisit();
        }
      }
    }
  };

  existingCustomerInquiryVisit = (vehicle_id = null) => {
    let selectPurposeref = this.selectPurposeref.current;
    let toEditVehicleData = this.vehicleDetailref.current;
    let customerDetailref = this.customerDetailref.current;
    let obj = {
      phone_number: this.state.phone_number && this.state.phone_number,
      customer_id: customerDetailref.state.customer_detail.customer_id,
      customer_vehicle_id: vehicle_id
        ? vehicle_id
        : toEditVehicleData.state.controls.vehicle_id.value,
      purpose_id:
        selectPurposeref.state.controls.selectedPurpose.value.purpose_id,
      vehical_meter_reading:
        toEditVehicleData.state.vehicle_meter_reading &&
        parseInt(toEditVehicleData.state.vehicle_meter_reading),
      vehical_avg_monthly_running:
        toEditVehicleData.state.AVG_monthly_usage &&
        parseInt(toEditVehicleData.state.AVG_monthly_usage),
      type_of_inquiry: selectPurposeref.state.controls.type_of_inquiry.value,
      vehicle_category:
        toEditVehicleData &&
        toEditVehicleData.controls &&
        toEditVehicleData.controls.vehicleCategory.value,
    };
    this.setState({
      isLoading: true,
    });
    AddCustomerService.existingCustomerInquiryVisit(obj)
      .then((response) => {
        response.data.data.message = response.data.message;
        swal({
          title: "",
          text: response.data.message,
          icon: "success",
          type: "success",
        }).then((isConfirm) => {
          this.handleOnCancel({ refresh_list: true });
        });
        // response.data.data.is_hide_add_reff = true;
        // this.refs.refAddCustomerSuccess.toggle({ ...response.data.data });
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          isLoading: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  existingCustomerServiceVisit = (vehicle_id = null) => {
    let selectPurposeref = this.selectPurposeref.current;
    let vehicleDetailref = this.vehicleDetailref.current;
    let customerDetailref = this.customerDetailref.current;
    if (selectPurposeref.state.selectedPurpose.type === "service") {
      let validation = this.AddTyrePurchaseDetailref.current.handleTyrePurchaseValidation(
        true,
        true,
        this.setReminderDetailref.current.state.tyre_purchase_due_date
      );
      if (
        vehicleDetailref.props.customer_vehicle &&
        vehicleDetailref.state.controls.vehicle_id.value
      ) {
        var selectedVehicleIndex = vehicleDetailref.props.customer_vehicle.findIndex(
          (x) =>
            x.vehicle_id === vehicleDetailref.state.controls.vehicle_id.value
        );
        if (selectedVehicleIndex > -1) {
          var selectedVehicle =
            vehicleDetailref.props.customer_vehicle[selectedVehicleIndex];
          if (
            !selectedVehicle ||
            !selectedVehicle.tyre_purchase_due_date ||
            (selectedVehicle &&
              selectedVehicle.tyre_purchase_due_date &&
              moment(selectedVehicle.tyre_purchase_due_date).valueOf() <
              moment().valueOf())
          ) {
            if (!validation) {
              ModalService.openAlert(
                "",
                "Please select next tyre replacement date.",
                "warning"
              );
              this.setState({ isLoading: false });
              return;
            }
            if (
              !this.setReminderDetailref.current.state.tyre_purchase_due_date
            ) {
              ModalService.openAlert(
                "",
                "Please select next tyre replacement date.",
                "warning"
              );
              this.setState({ isLoading: false });
              return;
            }
          }
        } else {
          if (!validation) {
            ModalService.openAlert(
              "",
              "Please select next tyre replacement date.",
              "warning"
            );
            this.setState({ isLoading: false });
            return;
          }
          if (!this.setReminderDetailref.current.state.tyre_purchase_due_date) {
            ModalService.openAlert(
              "",
              "Please select next tyre replacement date.",
              "warning"
            );
            this.setState({ isLoading: false });
            return;
          }
        }
      } else {
        if (!validation) {
          ModalService.openAlert(
            "",
            "Please select next tyre replacement date.",
            "warning"
          );
          this.setState({ isLoading: false });
          return;
        }
        if (!this.setReminderDetailref.current.state.tyre_purchase_due_date) {
          ModalService.openAlert(
            "",
            "Please select next tyre replacement date.",
            "warning"
          );
          this.setState({ isLoading: false });
          return;
        }
      }
      if (
        !selectedVehicle ||
        !selectedVehicle.tyre_purchase_due_date ||
        (selectedVehicle &&
          selectedVehicle.tyre_purchase_due_date &&
          moment(selectedVehicle.tyre_purchase_due_date).valueOf() <
          moment().valueOf())
      ) {
        if (
          this.setReminderDetailref &&
          this.setReminderDetailref.current &&
          this.setReminderDetailref.current.state &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail
            .select_brand_radio &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail
            .select_brand_radio.value === "other" &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail &&
          this.setReminderDetailref.current.state.tyrePurchaseDetail.brand_id &&
          !this.setReminderDetailref.current.state.tyrePurchaseDetail.brand_id
            .value
        ) {
          this.setState({ isLoading: false });
          return ModalService.openAlert("", "Please select brand.", "warning");
        }
      }
    }
    let obj = {
      phone_number: this.state.phone_number && this.state.phone_number,
      customer_id: customerDetailref.state.customer_detail.customer_id,
      customer_vehicle_id: vehicle_id
        ? vehicle_id
        : vehicleDetailref.state.controls.vehicle_id.value,
      purpose_id:
        selectPurposeref.state.controls.selectedPurpose.value.purpose_id,
      vehical_meter_reading:
        vehicleDetailref.state.vehicle_meter_reading &&
        parseInt(vehicleDetailref.state.vehicle_meter_reading),
      vehical_avg_monthly_running:
        vehicleDetailref.state.AVG_monthly_usage &&
        parseInt(vehicleDetailref.state.AVG_monthly_usage),
      services: [],
      vehicle_category:
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.vehicleCategory.value,
      tyre_purchase_due_date:
        !selectedVehicle ||
          !selectedVehicle.tyre_purchase_due_date ||
          (selectedVehicle &&
            selectedVehicle.tyre_purchase_due_date &&
            moment(selectedVehicle.tyre_purchase_due_date).valueOf() <
            moment().valueOf())
          ? this.setReminderDetailref.current.state.tyre_purchase_due_date
          : null,
      quantity: 1,
      brand_id:
        this.AddTyrePurchaseDetailref.current &&
        this.AddTyrePurchaseDetailref.current.state &&
        this.AddTyrePurchaseDetailref.current.state.controls &&
        this.AddTyrePurchaseDetailref.current.state.controls.brand_id &&
        this.AddTyrePurchaseDetailref.current.state.controls.brand_id.value &&
        Number(
          this.AddTyrePurchaseDetailref.current.state.controls.brand_id.value
        ),
      tyre_purchase_type:
        this.AddTyrePurchaseDetailref.current &&
          this.AddTyrePurchaseDetailref.current.state &&
          this.AddTyrePurchaseDetailref.current.state.controls &&
          this.AddTyrePurchaseDetailref.current.state.controls
            .select_brand_radio &&
          this.AddTyrePurchaseDetailref.current.state.controls.select_brand_radio
            .value
          ? this.AddTyrePurchaseDetailref.current.state.controls
            .select_brand_radio.value === "other"
            ? "other"
            : "jk pcr and 2-3 wheeler"
          : null,
    };

    if (this.setReminderDetailref.current) {
      if (this.setReminderDetailref.current.state.selected_service.length > 0) {
        this.setReminderDetailref.current.state.selected_service.forEach(
          (element) => {
            if (!element.is_set_service_due_date) {
              obj.services.push({
                service_id: element.service_id,
                set_reminder: true,
                remarks: element.remarks,
              });
            } else {
              obj.services.push({
                service_id: element.service_id,
                set_reminder: true,
                remarks: element.remarks,
                upcoming_due_date: element.upcoming_due_date,
              });
            }
          }
        );
      }
    }

    this.setState({
      isLoading: true,
    });
    AddCustomerService.existingCustomerServiceVisit(obj)
      .then((response) => {
        response.data.data.message = response.data.message;
        if (response.data.data && response.data.data.closeTyrePurchaseMessage) {
          swal({
            // title: "Are you sure want to quit the tyre purchase details flow",
            text: response.data.data.closeTyrePurchaseMessage,
            // icon: "warning",
            buttons: ["No", "Yes"],
          }).then((isConfirm) => {
            if (isConfirm) {
              this.closeTyrePurchase({
                customer_id: response.data.data.customer_id,
                customer_history_id: response.data.data.customer_history_id,
              });
            }
            // this.refs.refAddCustomerSuccess.toggle({ ...response.data.data });
            this.setState({
              success_detail: { ...response.data.data },
              is_display_add_customer_sucesss: true,
            });
          });
        } else {
          // this.refs.refAddCustomerSuccess.toggle({ ...response.data.data });
          this.setState({
            success_detail: { ...response.data.data },
            is_display_add_customer_sucesss: true,
          });
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        console.log(e);
        this.setState({
          isLoading: false,
        });
      });
  };

  closeTyrePurchase = (reqData) => {
    AddCustomerService.closeTyrePurchase(reqData)
      .then((response) => { })
      .catch((e) => {
        // this.setState({
        //   isLoading: false
        // })
        // console.log(e);
        // const err =
        //   e.response && e.response.data && e.response.data.error
        //     ? e.response.data.error[0]
        //     : null;
        // const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        // ModalService.openAlert("", message, "error");
      });
  };

  existingCustomerTyrepurchaseVisit = (is_quit = false, vehicle_id = null) => {
    this.setState({
      quantity:
        this.AddTyrePurchaseDetailref.current &&
        Number(
          this.AddTyrePurchaseDetailref.current.state.controls.quantity.value
        ),
    });

    let selectPurposeref = this.selectPurposeref.current;
    let vehicleDetailref = this.vehicleDetailref.current;
    let customerDetailref = this.customerDetailref.current;

    let obj = {
      phone_number: this.state.phone_number && this.state.phone_number,
      customer_id: customerDetailref.state.customer_detail.customer_id,
      customer_vehicle_id: vehicle_id
        ? vehicle_id
        : vehicleDetailref.state.controls.vehicle_id.value,
      purpose_id:
        selectPurposeref.state.controls.selectedPurpose.value.purpose_id,
      vehical_meter_reading:
        vehicleDetailref.state.vehicle_meter_reading &&
        parseInt(vehicleDetailref.state.vehicle_meter_reading),
      vehical_avg_monthly_running:
        vehicleDetailref.state.AVG_monthly_usage &&
        parseInt(vehicleDetailref.state.AVG_monthly_usage),
      brand_id:
        this.AddTyrePurchaseDetailref.current &&
        Number(
          this.AddTyrePurchaseDetailref.current.state.controls.brand_id.value
        ),
      quantity:
        this.AddTyrePurchaseDetailref.current &&
        Number(
          this.AddTyrePurchaseDetailref.current.state.controls.quantity.value
        ),
      tyre_purchase_type:
        selectPurposeref.state.controls.tyre_purchase_type.value,
      services: [],
      vehicle_category:
        vehicleDetailref &&
        vehicleDetailref.controls &&
        vehicleDetailref.controls.vehicleCategory.value,
    };

    if (!obj.customer_vehicle_id) {
      if (
        this.state.storage_add_customer_warranty_data &&
        Object.keys(this.state.storage_add_customer_warranty_data).length > 0 &&
        this.state.storage_add_customer_warranty_data.customer_vehicle_id
      ) {
        obj.customer_vehicle_id = this.state.storage_add_customer_warranty_data.customer_vehicle_id;
      }
    }

    if (this.AddTyrePurchaseDetailref.current && !is_quit) {
      if (
        this.AddTyrePurchaseDetailref.current.state.selected_service.length >
        0 &&
        this.AddTyrePurchaseDetailref.current.state.controls
          .set_service_reminder.value
      ) {
        this.AddTyrePurchaseDetailref.current.state.selected_service.forEach(
          (element) => {
            if (element.is_set_service_due_date === true) {
              if (element.is_add_service === true) {
                obj.services.push({
                  service_id: element.service_id,
                  set_reminder: true,
                  remarks: element.remarks,
                  upcoming_due_date: element.upcoming_due_date,
                });
              }
            }
          }
        );
      }
    }
    this.setState({
      isLoading: true,
    });
    AddCustomerService.existingCustomerTyrePurchaseVisit(obj)
      .then((response) => {
        // if (Storage.getAddCustomerData()) {
        //   Storage.removeAddCustomerData();
        // }
        response.data.data.message = response.data.message;
        if (response.data.data && response.data.data.closeTyrePurchaseMessage) {
          swal({
            // title: "Are you sure want to quit the tyre purchase details flow",
            text: response.data.data.closeTyrePurchaseMessage,
            // icon: "warning",
            buttons: ["No", "Yes"],
          }).then((isConfirm) => {
            if (isConfirm) {
              this.closeTyrePurchase({
                customer_id: response.data.data.customer_id,
                customer_history_id: response.data.data.customer_history_id,
              });
            }
            // this.refs.refAddCustomerSuccess.toggle({ ...response.data.data });
            this.setState({
              success_detail: { ...response.data.data },
              is_display_add_customer_sucesss: true,
            });
          });
        } else {
          // this.refs.refAddCustomerSuccess.toggle({ ...response.data.data });
          this.setState({
            success_detail: { ...response.data.data },
            is_display_add_customer_sucesss: true,
          });
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        console.log(e);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  addnewVehicle = async () => {
    let nextBtnLbl = "Next";
    if (
      this.selectPurposeref.current &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value.type &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value
        .type === "inquiry"
    ) {
      nextBtnLbl = "Submit";
    } else if (
      this.selectPurposeref.current &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value.type &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value
        .type === "service"
    ) {
      nextBtnLbl = "Next";
    }
    await this.setNextPageRedirectConfig({
      title: "ADD VEHICLE DETAILS",
      current_page: "vehicle_detail",
      is_display_back: true,
      is_display_next: true,
      is_hide_all_btn: false,
      nextBtnLabel: nextBtnLbl,
      is_display_cancel: false,
    });
    GoogleAnalticsService.event("Customer Detail", "Vehicle Details View");
    if (this.vehicleDetailref.current) {
      this.vehicleDetailref.current.init(-1);
    }
  };

  viewUpcomingAppointemnts = () => {
    this.setNextPageRedirectConfig({
      title: "UPCOMING APPOINTMENTS",
      current_page: "UpcomingAppointment",
      is_display_back: true,
      is_display_next: false,
      is_hide_all_btn: false,
      nextBtnLabel: "Save",
      is_display_cancel: false,
    });
  };

  isLoading = (isLoading) => {
    this.setState({
      isLoading: isLoading,
    });
  };

  setNextPageRedirectConfig = async (config) => {
    let { page_redirect_config, page_history_config } = this.state;
    page_history_config.push(Object.assign({}, page_redirect_config));
    page_redirect_config.title = config.title;
    page_redirect_config.current_page = config.current_page;
    page_redirect_config.nextBtnLabel = config.nextBtnLabel
      ? config.nextBtnLabel
      : "Next";
    page_redirect_config.is_hide_all_btn = config.is_hide_all_btn === true;
    page_redirect_config.is_display_next = config.is_display_next === true;
    page_redirect_config.is_display_back = config.is_display_back === true;
    page_redirect_config.is_display_cancel = config.is_display_cancel === true;
    await this.setState({
      page_redirect_config,
      page_history_config,
    });
    return true;
  };

  renderNextPageFromEditCustomer = async () => {
    let { page_redirect_config } = this.state;
    if (page_redirect_config.current_page === "edit_customer_detail") {
      if (this.editCustomerDetailref.current) {
        if (!this.editCustomerDetailref.current.handleValidation(true)) {
          return;
        }
      }
      let editCustomerDetailref = this.editCustomerDetailref.current.state;
      if (
        editCustomerDetailref.customgroupname &&
        editCustomerDetailref.controls.group_name.value
      ) {
        let group_id = await this.editCustomerDetailref.current.addGroup();
        if (!group_id) {
          return;
        }
      }
      if (this.editCustomerDetailref.current) {
        let response = await this.editCustomerDetailref.current.UpdateCustomer();
        if (!response) {
          return;
        }
      } else {
        return;
      }
      return;
    }

    if (
      page_redirect_config.current_page === "vehicle_detail" &&
      page_redirect_config.title === "EDIT VEHICLE DETAILS"
    ) {
      if (!this.vehicleDetailref.current.handleValidation(true)) {
        return;
      }
      let response_vehicle = await this.vehicleDetailref.current.addVehicle();
      if (!response_vehicle) {
        return;
      }
      this.backPage();
      return;
    }
  };

  renderNextPage = async () => {
    let { page_redirect_config } = this.state;
    // for select_purpose screen
    if (page_redirect_config.current_page === "select_purpose") {
      if (!this.selectPurposeref.current.handleValidation(true)) {
        return;
      }

      // check if jk tyre then redirect to warranty
      if (
        this.selectPurposeref.current &&
        this.selectPurposeref.current.state.controls.selectedPurpose.value
          .type === "tyre_purchase" &&
        this.selectPurposeref.current.state.controls.tyre_purchase_type
          .value === "jk pcr and 2-3 wheeler" && this.state.userDetail && this.state.userDetail.permissions.includes(Permissions.WARRANTY_REGISTRATION)
      ) {
        await this.setState({
          is_redirect_to_warranty: true,
          phone_number: this.selectPurposeref.current.state.controls
            .phone_number.value,
        });
      }

      else {
        await this.setState({
          is_redirect_to_warranty: false,
          phone_number: this.selectPurposeref.current.state.controls
            .phone_number.value,
        });
      }
      let cusromer_detail_data = await this.getCustomerDetail(
        this.selectPurposeref.current.state.controls.phone_number.value
      );

      if (!cusromer_detail_data) {
        return;
      }

      if (this.state.is_redirect_to_warranty) {
        return;
      }

      ///check is existing
      if (
        cusromer_detail_data.customer_vehicle &&
        Array.isArray(cusromer_detail_data.customer_vehicle) &&
        cusromer_detail_data.customer_vehicle.length > 0
      ) {
        if (this.vehicleDetailref.current) {
          let response = await this.vehicleDetailref.current.getServiceWiseVehicleTypeId();
          if (!response) {
            return false;
          }
        }
        this.setState({
          Existing: true,
        });
        this.setNextPageRedirectConfig({
          title: "CUSTOMER DETAILS",
          current_page: "customer_detail",
          is_display_back: true,
          is_display_next: true,
          is_hide_all_btn: true,
          nextBtnLabel: "Next",
          is_display_cancel: false,
        });
        GoogleAnalticsService.event(
          "Add Customer Visit",
          "Customer Details View"
        );
        return;
      } else {
        if (this.vehicleDetailref.current) {
          let response = await this.vehicleDetailref.current.getServiceWiseVehicleTypeId();
          if (!response) {
            return false;
          }
        }
        this.setNextPageRedirectConfig({
          title: "ADD CUSTOMER DETAILS",
          current_page: "customer_detail",
          is_display_back: true,
          is_display_next: true,
          is_hide_all_btn: false,
          nextBtnLabel: "Next",
          is_display_cancel: false,
        });
        return;
      }
    }

    //for customer detail screen or Add customer detail screen
    if (page_redirect_config.current_page === "customer_detail") {
      if (!this.state.Existing) {
        if (this.customerDetailref.current) {
          if (!this.customerDetailref.current.handleValidation(true)) {
            return;
          }

          if (
            this.customerDetailref.customgroupname &&
            this.customerDetailref.controls.group_name.value
          ) {
            let group_id = await this.customerDetailref.current.addGroup();
            if (!group_id) {
              return;
            }
          }
          if (
            this.selectPurposeref.current &&
            this.selectPurposeref.current.state.controls.selectedPurpose
              .value &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type === "inquiry"
          ) {
            this.setNextPageRedirectConfig({
              title: "ADD VEHICLE DETAILS",
              current_page: "vehicle_detail",
              is_display_back: true,
              is_display_next: true,
              is_hide_all_btn: false,
              nextBtnLabel: "Submit",
              is_display_cancel: false,
            });
            GoogleAnalticsService.event(
              "Add Customer Visit",
              "Add Vehicle View"
            );
            if (this.vehicleDetailref.current) {
              this.vehicleDetailref.current.init(-1);
            }
            return;
          } else {
            this.setNextPageRedirectConfig({
              title: "ADD VEHICLE DETAILS",
              current_page: "vehicle_detail",
              is_display_back: true,
              is_display_next: true,
              is_hide_all_btn: false,
              nextBtnLabel: "Next",
              is_display_cancel: false,
            });
            GoogleAnalticsService.event(
              "Add Customer Visit",
              "Add Vehicle View"
            );
            if (this.vehicleDetailref.current) {
              this.vehicleDetailref.current.init(-1);
            }
            return;
          }
        }
      } else {
        if (
          this.selectPurposeref.current &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value
            .type &&
          this.selectPurposeref.current.state.controls.selectedPurpose.value
            .type === "inquiry"
        ) {
          await this.setNextPageRedirectConfig({
            title: "ADD VEHICLE DETAILS",
            current_page: "vehicle_detail",
            is_display_back: true,
            is_display_next: true,
            is_hide_all_btn: false,
            nextBtnLabel: "Submit",
            is_display_cancel: false,
          });
          if (this.vehicleDetailref.current) {
            this.vehicleDetailref.current.init(-1);
          }
          GoogleAnalticsService.event("Add Customer Visit", "Add Vehicle View");
          return;
        } else {
          await this.setNextPageRedirectConfig({
            title: "ADD VEHICLE DETAILS",
            current_page: "vehicle_detail",
            is_display_back: true,
            is_display_next: true,
            is_hide_all_btn: false,
            nextBtnLabel: "Next",
            is_display_cancel: false,
          });
          GoogleAnalticsService.event("Add Customer Visit", "Add Vehicle View");
          if (this.vehicleDetailref.current) {
            this.vehicleDetailref.current.init(-1);
          }
          return;
        }
      }
    }

    //for vehicle detail
    if (page_redirect_config.current_page === "vehicle_detail") {
      if (this.vehicleDetailref.current) {
        if (!this.vehicleDetailref.current.handleValidation(true)) {
          return;
        }
        if (!this.state.Existing) {
          if (
            this.selectPurposeref.current &&
            this.selectPurposeref.current.state.controls.selectedPurpose
              .value &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type === "inquiry"
          ) {
            let responseData = await this.SaveVehicleDetailForInquiry();
            return;
          } else if (
            this.selectPurposeref.current &&
            this.selectPurposeref.current.state.controls.selectedPurpose
              .value &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type === "service"
          ) {
            await this.setNextPageRedirectConfig({
              title: "SET REMINDER DETAILS",
              current_page: "set_reminder",
              is_display_back: true,
              is_display_next: true,
              is_hide_all_btn: false,
              nextBtnLabel: "Submit",
              is_display_cancel: false,
            });
            GoogleAnalticsService.event(
              "Add Customer Visit",
              "View Set Reminder Screen"
            );
            if (this.setReminderDetailref.current) {
              this.setReminderDetailref.current.init();
            }
            // this.AddTyrePurchaseDetailref.current.customInit();
            return;
          } else if (
            this.selectPurposeref.current &&
            this.selectPurposeref.current.state.controls.selectedPurpose
              .value &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type === "tyre_purchase"
          ) {
            await this.setNextPageRedirectConfig({
              title: "ADD TYRE PURCHASE DETAILS",
              current_page: "AddTyrePurchaseDetail",
              is_display_back: true,
              is_display_next: true,
              is_hide_all_btn: false,
              nextBtnLabel: "Submit",
              is_display_cancel: false,
            });
            this.AddTyrePurchaseDetailref.current.customInit();
            GoogleAnalticsService.event(
              "Add Customer Visit",
              "Add Tyre Purchase Detail"
            );
            return;
          }
        } else {
          if (
            this.selectPurposeref.current &&
            this.selectPurposeref.current.state.controls.selectedPurpose
              .value &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type === "inquiry"
          ) {
            let response_vehicle = await this.vehicleDetailref.current.addVehicle();
            if (!response_vehicle) {
              return;
            }
            await this.existingCustomerInquiryVisit(
              response_vehicle.customer_vehicle_id
            );
          } else if (
            this.selectPurposeref.current &&
            this.selectPurposeref.current.state.controls.selectedPurpose
              .value &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type === "service"
          ) {
            await this.setNextPageRedirectConfig({
              title: "SET REMINDER DETAILS",
              current_page: "set_reminder",
              is_display_back: true,
              is_display_next: true,
              is_hide_all_btn: false,
              nextBtnLabel: "Submit",
              is_display_cancel: false,
            });
            GoogleAnalticsService.event(
              "Add Customer Visit",
              "View Set Reminder Screen"
            );
            if (this.setReminderDetailref.current) {
              this.setReminderDetailref.current.init();
            }
            return;
          } else if (
            this.selectPurposeref.current &&
            this.selectPurposeref.current.state.controls.selectedPurpose
              .value &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type &&
            this.selectPurposeref.current.state.controls.selectedPurpose.value
              .type === "tyre_purchase"
          ) {
            await this.setNextPageRedirectConfig({
              title: "ADD TYRE PURCHASE DETAILS",
              current_page: "AddTyrePurchaseDetail",
              is_display_back: true,
              is_display_next: true,
              is_hide_all_btn: false,
              nextBtnLabel: "Submit",
              is_display_cancel: false,
            });
            this.AddTyrePurchaseDetailref.current.customInit();
            GoogleAnalticsService.event(
              "Add Customer Visit",
              "Add Tyre Purchase Detail"
            );
            return;
          }
        }
      }
    }

    //for set reminder
    if (page_redirect_config.current_page === "set_reminder") {
      if (!this.state.Existing) {
        let responseData = await this.SaveVehicleDetailForService();
        GoogleAnalticsService.event("Set Reminder", "Add Visit");
        return;
      } else {
        let response_vehicle = await this.vehicleDetailref.current.addVehicle();
        if (!response_vehicle) {
          return;
        }
        let responseData = await this.existingCustomerServiceVisit(
          response_vehicle.customer_vehicle_id
        );
        return;
      }
    }

    //for tyre purchase details
    if (page_redirect_config.current_page === "AddTyrePurchaseDetail") {
      if (!this.AddTyrePurchaseDetailref.current.handleValidation(true)) {
        return;
      }
      if (!this.state.Existing) {
        let responseData = await this.SaveVehicleDetailForTyrePurchase();
        return;
      } else {
        let response_vehicle = {};
        if (this.state.storage_add_customer_warranty_data.customer_vehicle_id) {
          response_vehicle = this.state.storage_add_customer_warranty_data;
        } else {
          response_vehicle = await this.vehicleDetailref.current.addVehicle();
          if (!response_vehicle) {
            return;
          }
        }
        let responseData = await this.existingCustomerTyrepurchaseVisit(
          false,
          response_vehicle.customer_vehicle_id
        );
        return;
      }
    }

    if (page_redirect_config.current_page === "edit_customer_detail") {
      if (this.editCustomerDetailref.current) {
        if (!this.editCustomerDetailref.current.handleValidation(true)) {
          return;
        }
      }
      let editCustomerDetailref = this.editCustomerDetailref.current.state;
      if (
        editCustomerDetailref.customgroupname &&
        editCustomerDetailref.controls.group_name.value
      ) {
        let group_id = await this.editCustomerDetailref.current.addGroup();
        if (!group_id) {
          return;
        }
      }
      if (this.editCustomerDetailref.current) {
        let response = await this.editCustomerDetailref.current.UpdateCustomer();
        if (response) {
          this.backPage();
        } else {
          return;
        }
      } else {
        return;
      }
    }

    return;
  };

  validation_purpose = () => {
    let { error } = this.state;

    if (
      this.state.current_page === "select_purpose" &&
      this.selectPurposeref.current
    ) {
      if (!this.selectPurposeref.current.state.controls.selectedPurpose.value) {
        error = {
          errorMsg: "Please Select Purpose.",
          errorCode: "purpose",
        };
        this.setState({
          error,
        });
        return false;
      } else if (
        this.selectPurposeref.current &&
        !this.selectPurposeref.current.state.type_of_inquiry &&
        this.selectPurposeref.current.state.controls.selectedPurpose.value ==
        "inquiry"
      ) {
        error = {
          errorMsg: "Please select type of enquiry.",
          errorCode: "type_of_inquiry",
        };
        this.setState({
          error,
        });
        return false;
      } else if (
        this.selectPurposeref.current.state.controls.selectedPurpose.value ==
        "inquiry" &&
        !this.selectPurposeref.current.state.type_of_inquiry
      ) {
        error = {
          errorMsg: "Please select type of inquiry.",
          errorCode: "inquiry",
        };
        this.setState({
          error,
        });
        return false;
      } else if (
        this.selectPurposeref.current.state.controls.selectedPurpose.value ==
        "service" &&
        this.selectPurposeref.current.state.selectedServices.length === 0
      ) {
        error = {
          errorMsg: "Please select type of service.",
          errorCode: "service",
        };
        this.setState({
          error,
        });
        return false;
      } else if (
        this.selectPurposeref.current.state.controls.selectedPurpose.value ==
        "tyre_purchase" &&
        !this.selectPurposeref.current.state.tyre_purchase_type
      ) {
        error = {
          errorMsg: "Please select type of tyre.",
          errorCode: "tyrepurchase",
        };
        this.setState({
          error,
        });
        return false;
      } else if (
        this.selectPurposeref.current &&
        !this.selectPurposeref.current.state.phone_number
      ) {
        error = {
          errorMsg: "Please enter number.",
          errorCode: "number",
        };
        this.setState({
          error,
        });
        return false;
      } else if (
        this.selectPurposeref.current.state.phone_number.length !== 10
      ) {
        error = {
          errorMsg: "Please valid number.",
          errorCode: "number",
        };
        this.setState({
          error,
        });
        return false;
      }
    } else {
      return true;
    }
  };

  validation_customer_detail = () => {
    let { error } = this.state;
    if (
      this.state.current_page === "customer_detail" &&
      this.customerDetailref.current
    ) {
      if (!this.customerDetailref.current.state.first_name) {
        error = {
          errorMsg: "Please Enter First Name.",
          errorCode: "first_name",
        };
        this.setState({
          error,
        });
        return false;
      } else if (!this.customerDetailref.current.state.last_name) {
        error = {
          errorMsg: "Please Enter Last Name.",
          errorCode: "last_name",
        };
        this.setState({
          error,
        });
        return false;
      }
    }
  };

  editVehicleFromCustomerDetail = async (index) => {
    let nextBtnLbl = "Next";
    if (
      this.selectPurposeref.current &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value.type &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value
        .type === "inquiry"
    ) {
      nextBtnLbl = "Submit";
    } else if (
      this.selectPurposeref.current &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value.type &&
      this.selectPurposeref.current.state.controls.selectedPurpose.value
        .type === "service"
    ) {
      nextBtnLbl = "Next";
    }
    await this.setNextPageRedirectConfig({
      title: "EDIT VEHICLE DETAILS",
      current_page: "vehicle_detail",
      is_display_back: true,
      is_display_next: true,
      is_hide_all_btn: false,
      nextBtnLabel: nextBtnLbl,
      is_display_cancel: false,
    });
    GoogleAnalticsService.event("Edit Customer", "Edit Vehicle Detail");
    if (this.vehicleDetailref.current) {
      this.vehicleDetailref.current.init(index);
    }
    return;
  };

  confirmQuit = () => {
    swal({
      title: "Are you sure want to quit the tyre purchase details flow",
      text:
        "Please note that you have not set reminder details for the tyre purchased",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.existingCustomerTyrepurchaseVisit(true);
      }
    });
  };

  goToSelectPurposeScreen = async () => {
    let { page_redirect_config, page_history_config } = this.state;
    page_history_config = [];
    page_redirect_config = {
      current_page: "select_purpose",
      title: "ADD CUSTOMER",
      nextBtnLabel: "Next",
      is_display_next: true,
      is_display_back: false,
      is_display_cancel: true,
      is_hide_all_btn: false,
    };
    this.setState(
      {
        page_history_config,
        page_redirect_config,
        is_added_from_warranty: false,
        couponData: {},
      },
      () => {
        if (this.selectPurposeref.current) {
          this.selectPurposeref.current.resetData();
        }
      }
    );
  };

  confirmQuitInquiryAndService = (flow_name = "") => {
    swal({
      title: `Are you sure want to quit the ${flow_name} details flow`,
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.goToSelectPurposeScreen();
        // this.handleOnCancel();
      }
    });
  };

  editCustomer = async () => {
    await this.setNextPageRedirectConfig({
      title: "EDIT CUSTOMER DETAILS",
      current_page: "edit_customer_detail",
      is_display_back: true,
      is_display_next: true,
      is_hide_all_btn: false,
      nextBtnLabel: "Save",
      is_display_cancel: false,
    });
    GoogleAnalticsService.event("Edit Customer", "Edit Customer Detail");
  };
  editCustomerFromCustomerDetail = async () => {
    await this.setNextPageRedirectConfig({
      title: "EDIT CUSTOMER DETAILS",
      current_page: "edit_customer_detail",
      is_display_back: true,
      is_display_next: true,
      is_hide_all_btn: false,
      nextBtnLabel: "Save",
      is_display_cancel: false,
    });
    GoogleAnalticsService.event("Edit Customer", "Edit Customer Detail");
  };

  scrollTo(id) {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      containerId: "add-customer-custom-drawer-content",
      offset: -80,
    });
  }

  closeSendVerifyOtp = () => {
    this.setState({
      openSendVerifyOtp: false,
    });
  };

  openRewardReff = (isCallApi = false) => {
    this.setState({
      is_display_referral_reward: !this.state.is_display_referral_reward,
    });
    if (isCallApi) {
      this.getDealerReferalReward(true);
    }
  };

  async getUserDetail() {
    await UserService.getUserInfo()
      .then((response) => {
        this.setState((prevState) => {
          return {
            ...prevState,
            get_user_info: response.data.data,
          };
        });
      })
      .catch((e) => {
        console.error("e", e);
      });
  }

  getDealerReferalReward = (is_loader = false) => {
    AddCustomerService.getDealerReferalReward()
      .then((res) => {
        this.setState({
          offer_data: res.data.data,
        });
      })
      .catch((e) => {
        console.log("err", e);
      });
  };

  openCloseBuyProduct = (flag = false) => {
    if (flag) {
      this.getSMSCredits(flag);
    }
    this.setState({
      is_display_buy_sms: !this.state.is_display_buy_sms,
    });
  };

  saveReffDetail = () => {
    Storage.setAddCustomerSuccessDetail(this.state.success_detail);
  };

  getSMSCredits = (flag = false) => {
    if (flag) {
      this.setState({
        isLoading: true,
      });
    }
    CampaignService.getSMSCredits()
      .then((data) => {
        this.setState({
          sms_credits: data.data.data,
        });
        if (flag) {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        if (flag) {
          this.setState({
            isLoading: false,
          });
        }
      });
  };

  render() {
    const { current_page, page_redirect_config } = this.state;

    let show_all_btn = true;
    if (current_page === "customer_detail") {
      if (this.state.Existing) {
        show_all_btn = false;
      }
    }
    return (
      <>
        {this.state.is_rediract_dashboard && (
          <Redirect to={this.state.dashborad_path} />
        )}
        {this.state.is_display_add_customer_sucesss && (
          <div
            className={
              this.state.is_display_referral_reward ||
                this.state.is_display_buy_sms
                ? "hidden"
                : ""
            }
            style={{ height: "100%" }}
          >
            <AddCustomerSuccess
              ref="refAddCustomerSuccess"
              submitDetail={this.submitDetail}
              handleOnCancel={this.handleOnCancel}
              couponData={this.state.couponData}
              success_detail={this.state.success_detail}
              openRewardReff={this.openRewardReff}
              get_user_info={this.state.get_user_info}
              offer_data={this.state.offer_data}
              sms_credits={this.state.sms_credits}
              goToBuySMS={this.openCloseBuyProduct}
              from=""
            />
          </div>
        )}
        <ModalServicePref
          from="notification-pref"
          is_first_service_pref={false}
          ref="modalServicePref"
        />
        {this.state.is_display_buy_sms && (
          <BuyProduct
            from="add-customer"
            saveReffDetail={this.saveReffDetail}
            getSMSCredits={this.getSMSCredits}
            openCloseBuyProduct={this.openCloseBuyProduct}
          />
        )}
        {this.state.is_display_referral_reward && (
          <ReferralRewardsPreference
            from="add-customer"
            closeSideBar={this.openRewardReff}
          />
        )}
        <div
          id="add-customer"
          className={
            this.state.is_display_add_customer_sucesss
              ? "hidden"
              : "custom-drawer"
          }
        >
          {/* <AddCustomerSuccess
            ref="refAddCustomerSuccess"
            submitDetail={this.submitDetail}
            handleOnCancel={this.handleOnCancel}
            couponData={this.state.couponData}
            from=""
          /> */}
          {this.state.openSendVerifyOtp && (
            <VerifyCoupon
              mainModel={true}
              data={{}}
              closeModal={this.closeSendVerifyOtp}
              openModel={true}
              sendOtpScreen={true}
              phone_number={this.state.phone_number}
              from="add-customer"

            />
          )}
          {this.state.isLoading && <CustomSpinner />}
          <div style={{ marginTop: "0px" }}>
            <div className="drawer-header">
              {
                <span className="drawer-title" style={{ marginLeft: "10px" }}>
                  {page_redirect_config.title}
                </span>
              }
              {!this.props.is_open_visit_history &&
                !this.props.is_open_vehcile_detail_list && (
                  <img
                    className="cursor-pointer"
                    src="images/close.svg"
                    alt="cancel"
                    onClick={() => this.handleOnCancelAddCustomer()}
                  />
                )}
            </div>
            <hr style={{ border: "solid 1px #ced4da", marginTop: "0.5rem" }} />
          </div>
          <div
            class="custom-drawer-content"
            id="add-customer-custom-drawer-content"
          >
            {
              <>
                {/**Hiding the child component using css on page_redirect_config.current_page value */}
                <div
                  className={
                    page_redirect_config.current_page !== "select_purpose"
                      ? "hidden"
                      : ""
                  }
                >
                  <SelectPurpose
                    ref={this.selectPurposeref}
                    error={this.state.error}
                    isLoading={this.isLoading}
                    redirectToSetting={this.redirectToSetting}
                    is_added_from_warranty={this.state.is_added_from_warranty}
                    couponData={this.state.couponData}
                  />
                </div>
                <div
                  className={
                    page_redirect_config.current_page !== "customer_detail"
                      ? "hidden"
                      : ""
                  }
                  style={{ marginRight: "35px", marginLeft: "15px" }}
                >
                  <CustomerDetail
                    ref={this.customerDetailref}
                    editLabel={this.editLabel}
                    editVehicle={this.editVehicleFromCustomerDetail}
                    viewHistory={this.viewHistory}
                    addnewVehicle={this.addnewVehicle}
                    backPage={this.backPage}
                    nextPage={this.nextPage}
                    Existing={this.state.Existing}
                    customer_vehicle={
                      this.state.customer_vehicle
                        ? this.state.customer_vehicle
                        : []
                    }
                    customer_detail={
                      this.state.customer_detail
                        ? this.state.customer_detail
                        : {}
                    }
                    phone_number={this.state.phone_number}
                    isLoading={this.isLoading}
                    editCustomer={this.editCustomerFromCustomerDetail}
                    getCustomerVehicle={this.getCustomerVehicle}
                    scrollTo={this.scrollTo}
                    couponData={this.state.couponData}
                  />
                </div>
                <div
                  className={
                    page_redirect_config.current_page !== "vehicle_detail"
                      ? "hidden"
                      : ""
                  }
                  style={{ marginRight: "20px" }}
                >
                  <VehicleDetail
                    ref={this.vehicleDetailref}
                    selectPurposeref={this.selectPurposeref}
                    phone_number={this.state.phone_number}
                    customer_vehicle={
                      this.state.customer_vehicle
                        ? this.state.customer_vehicle
                        : []
                    }
                    customer_detail={
                      this.state.customerDetailref.customer_detail
                        ? this.state.customerDetailref.customer_detail
                        : this.state.customer_detail
                          ? this.state.customer_detail
                          : {}
                    }
                    nextPage={this.nextPage}
                    backPage={this.backPage}
                    isLoading={this.isLoading}
                    Existing={this.state.Existing}
                    getCustomerVehicle={this.getCustomerVehicle}
                    is_edit_from_customer_list={
                      this.state.is_edit_from_customer_list
                    }
                    couponData={this.state.couponData}
                  />
                </div>
                <div
                  className={
                    page_redirect_config.current_page !==
                      "vehicle_trade_history"
                      ? "hidden"
                      : ""
                  }
                >
                  <VehicleTradeHistory
                    ref={this.VehicleTradeHistoryRef}
                    customer_detail={
                      this.state.customerDetailref.customer_detail
                        ? this.state.customerDetailref.customer_detail
                        : this.state.customer_detail
                          ? this.state.customer_detail
                          : {}
                    }
                    customer_vehicle_id={this.state.customer_vehicle_id}
                    isLoading={this.isLoading}
                  />
                </div>
                <div
                  className={
                    page_redirect_config.current_page !== "edit_customer_detail"
                      ? "hidden"
                      : ""
                  }
                >
                  <EditCustomerDetail
                    ref={this.editCustomerDetailref}
                    editVehicle={this.editVehicle}
                    customer_vehicle={
                      this.state.customer_vehicle
                        ? this.state.customer_vehicle
                        : []
                    }
                    viewUpcomingAppointemnts={this.viewUpcomingAppointemnts}
                    customer_detail={this.state.customer_detail}
                    isLoading={this.isLoading}
                    viewTradeHistory={this.viewHistory}
                    handleOnCancel={this.handleOnCancel}
                    getCustomerVehicle={this.getCustomerVehicle}
                    is_edit_from_customer_list={
                      this.state.is_edit_from_customer_list
                    }
                    backPage={this.backPage}
                    is_open_vehcile_detail_list={
                      this.props.is_open_vehcile_detail_list
                    }
                  // ViewHistory={this.state.customer_detail}
                  />
                </div>
                <div
                  className={
                    page_redirect_config.current_page !== "set_reminder"
                      ? "hidden"
                      : ""
                  }
                  style={{ marginLeft: "10px" }}
                >
                  <SetReminder
                    ref={this.setReminderDetailref}
                    customer_detail={
                      this.state.customerDetailref.customer_detail
                    }
                    customer_vehicle_id={this.state.customer_vehicle_id}
                    select_purpose_detail={this.selectPurposeref}
                    vehicleDetailref={this.vehicleDetailref.current}
                    ViewHistory={
                      this.customerDetailref.current &&
                      this.customerDetailref.current.state.ViewHistory
                    }
                    getTyrePurchaseDueDate={this.getTyrePurchaseDueDate}
                    isLoading={this.isLoading}
                    renderNextPage={this.renderNextPage}
                  />
                </div>
                <div
                  className={
                    page_redirect_config.current_page !==
                      "AddTyrePurchaseDetail"
                      ? "hidden"
                      : ""
                  }
                  style={{ marginRight: "20px" }}
                >
                  <AddTyrePurchaseDetail
                    ref={this.AddTyrePurchaseDetailref}
                    customer_detail={
                      this.state.customerDetailref.customer_detail
                    }
                    customer_vehicle_id={this.state.customer_vehicle_id}
                    ViewHistory={
                      this.customerDetailref.current &&
                      this.customerDetailref.current.state.ViewHistory
                    }
                    isLoading={this.isLoading}
                    storage_add_customer_warranty_data={
                      this.state.storage_add_customer_warranty_data
                    }
                    vehicleDetailref={this.vehicleDetailref.current}
                  />
                </div>
                <div
                  className={
                    page_redirect_config.current_page !== "UpcomingAppointment"
                      ? "hidden"
                      : ""
                  }
                >
                  <UpcomingAppointment
                    customer_vehicle={this.state.customer_vehicle}
                    viewHistory={this.viewHistory}
                    ref={this.UpcomingAppointmentRef}
                  />
                </div>
              </>
            }
          </div>

          {/* {
            show_all_btn &&
            <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
              <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
                {
                  this.state.backBreadCrumbs.length > 0 ?
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      onClick={() => this.backPage(null)}
                    >
                      Back
                </Button> :
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      onClick={() => this.handleOnCancelAddCustomer()}
                    >
                      Cancel
              </Button>
                }
                &nbsp;&nbsp;
            {
                  (this.state.current_page !== 'UpcomingAppointment' &&
                    this.state.current_page !== 'vehicle_trade_history') &&
                  <Button
                    className="mb-1 mr-1 btn-pill action-button profile-save-button"
                    color="warning"
                    onClick={() => this.renderNextPage()}>
                    {this.state.nextBtnLable}
                  </Button>
                }
              </Col>
            </Row>
          } */}

          {!page_redirect_config.is_hide_all_btn && (
            <Row
              className="c-top-15 custom-drawer-footer"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <Col
                className="d-flex-row"
                style={{ justifyContent: "flex-end" }}
              >
                {page_redirect_config.is_display_back && (
                  <Button
                    className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                    onClick={() => this.backPage(null)}
                  >
                    Back
                  </Button>
                )}
                {page_redirect_config.is_display_cancel && (
                  <Button
                    className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                    onClick={() => this.handleOnCancelAddCustomer()}
                  >
                    Cancel
                  </Button>
                )}
                &nbsp;&nbsp;
                {page_redirect_config.is_display_next &&
                  (this.state.is_edit_from_customer_list ? (
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={() => this.renderNextPageFromEditCustomer()}
                    >
                      {page_redirect_config.nextBtnLabel}
                    </Button>
                  ) : (
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={() => this.renderNextPage()}
                    >
                      {page_redirect_config.nextBtnLabel}
                    </Button>
                  ))}
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(AddCustomer);
