import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import WarrantyRegistrationService from "../../../services/WarrantyRegistrationService";

class ProductDetails extends Component {

  render() {
    const { controls } = this.props.productDetails;
    return (
      <Card>
        <CardBody>
          <Row className="warranty-customer-details-label">
            <Col xl="10" xs="10" ms="10">
              PRODUCT DETAILS
            </Col>
          </Row>
          {
            <div>
              <Row
                onClick={() =>
                  WarrantyRegistrationService.goTo(
                    controls.skuId1.value ||
                    controls.skuIdBack.value ||
                    controls.skuIdSpare.value,
                    this.props.updateState,
                    "productDetailsEdit"
                  )
                }
                className="disable-text-box-margin"
                style={{ cursor: "pointer" }}
              >
                <Col>
                  <div
                    className={`disabled-text-box ${controls.skuId1.value ||
                      controls.skuIdBack.value ||
                      controls.skuIdSpare.value
                      ? "filled-data-field"
                      : ""
                      }`}
                  >
                    <Row>
                      <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                        <span className="disabled-input-label">
                          Tyre Description
                        </span>
                      </Col>
                      <Col
                        title={`${(controls.skuId1.value &&
                          controls.skuId1.value.name) ||
                          ""} - ${(controls.skuIdBack.value &&
                            controls.skuIdBack.value.name) ||
                          ""} - ${(controls.skuIdSpare.value &&
                            controls.skuIdSpare.value.name) ||
                          ""}`}
                        className="text-overflow-dots"
                      >
                        <span className="disabled-input-value">
                          {controls.skuId1.value &&
                            `${controls.skuId1.value.name} - `}
                          {controls.skuIdBack.value &&
                            `${controls.skuIdBack.value.name} - `}
                          {controls.skuIdSpare.value &&
                            `${controls.skuIdSpare.value.name}`}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row
                onClick={() =>
                  WarrantyRegistrationService.goTo(
                    controls.tyreQuantity.value,
                    this.props.updateState,
                    "productDetailsEdit"
                  )
                }
                className="disable-text-box-margin"
                style={{ cursor: "pointer" }}
              >
                <Col>
                  <div
                    className={`disabled-text-box ${controls.tyreQuantity.value ? "filled-data-field" : ""
                      }`}
                  >
                    <Row>
                      <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                        <span className="disabled-input-label">
                          No. of Tyres Purchased
                        </span>
                      </Col>
                      <Col
                        title={controls.tyreQuantity.value}
                        className="text-overflow-dots"
                      >
                        <span className="disabled-input-value">
                          {controls.tyreQuantity.value}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row
                onClick={() =>
                  WarrantyRegistrationService.goTo(
                    controls.tyreQuantity.value,
                    this.props.updateState,
                    "productDetailsEdit"
                  )
                }
                className="disable-text-box-margin"
                style={{ cursor: "pointer" }}
              >
                <Col>
                  <div
                    className={`disabled-text-box ${this.props.sensorNumberAdded ? "filled-data-field" : ""
                      }`}
                  >
                    <Row>
                      <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                        <span className="disabled-input-label">
                          No. of Sensor
                        </span>
                      </Col>
                      <Col
                        title={this.props.sensorNumberAdded}
                        className="text-overflow-dots"
                      >
                        <span className="disabled-input-value">
                          {this.props.sensorNumberAdded ? this.props.sensorNumberAdded : ''}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          }
        </CardBody>
      </Card>
    );
  }
}

export default ProductDetails;
