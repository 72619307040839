import React from "react";
import {
  Modal,
  ModalBody,
  Input,
  Row,
  Col,
  Form,
  Table
} from "reactstrap";

import Pagination from "../../components/Pagination/Pagination";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import lodash from "lodash"; // library added for debounce functionality
import BannerService from '../../services/BannerService';
import { formatDate } from "../../utils/utils";
import "./TargetUsersModal.css";

class TargetUsersModal extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    users: [],
    count: 0,
    totalPages: 0,
    search: '',
    isLoading: false
  };

  constructor() {
    super();
    this.searchData = lodash.debounce(this.searchData, 500);
  }
  componentDidMount() {
    this.loadUserData();
  }

  loadUserData = () => {
    const { type } = this.props;
    if (type === null) {
      this.getBannerEligibleDealers();
    } else {
      this.getTargetUsers();
    }
  }

  searchData = (search, page) => {
    this.loadUserData();
  };

  handleSearchChange = e => {
    let searchVal = e.target.value;
    this.setState({ page: 1, search: searchVal }, () => {
      this.searchData(searchVal, 1);
    });
  };


  handlePageChange = page => {
    this.setState({ page: page }, () => {
      this.loadUserData();
    });
  };


  getTargetUsers = () => {
    const { page, pageSize, search } = this.state;
    const { uuid, type } = this.props;
    this.setState({ isLoading: true });
    BannerService.getBannerDealers(page, pageSize, uuid, type, search)
      .then(res => {
        if (res.data.data && res.data.data.elements) {
          let users = res.data.data.elements;
          let count = res.data.data.total_count;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState({
            users,
            count,
            totalPages,
            isLoading: false
          });
        } else {
          this.setState({
            users: [],
            count: 0,
            totalPages: 0,
            isLoading: false
          });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }


  getBannerEligibleDealers = () => {
    const { page, pageSize, search } = this.state;
    const { uuid } = this.props;
    this.setState({ isLoading: true });
    BannerService.getBannerEligibleDealers(page, pageSize, uuid, search)
      .then(res => {
        if (res.data.data && res.data.data.elements) {
          let users = res.data.data.elements;
          let count = res.data.data.total_count;
          const totalPages = Math.ceil(count / this.state.pageSize);

          this.setState({
            users,
            count,
            totalPages,
            isLoading: false
          });
        } else {
          this.setState({
            users: [],
            count: 0,
            totalPages: 0,
            isLoading: false
          });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }

  closeModal = () => {
    this.props.closeModal(true);
  };

  prepareRows = () => {
    const { users } = this.state;
    const { type } = this.props;
    return users.map(u =>
      <tr>
        <td>{u.owner_name}</td>
        <td >{u.user_id}</td>
        <td className="title-case">{u.role}</td>
        <td>{u.type}</td>
        {(type === 'view' || type === 'click') && <td>{formatDate(u.viewed_at)}</td>}
      </tr>
    )
  }

  getModalTitle = () => {
    const { type } = this.props;
    if (type === 'view') {
      return `BANNER USER'S VIEW COUNT`
    } else if (type === 'click') {
      return `BANNER USER'S CLICK COUNT`
    } else {
      return `TARGET USERS`
    }
  }

  render() {
    const { count, search, isLoading } = this.state;
    const { count: totalCounts, type } = this.props;
    return (
      <div>
        <Modal
          id="target-user-modal"
          isOpen={this.props.show}
          toggle={this.props.closeModal}
          centered
        >
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            {isLoading && <CustomSpinner />}
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col sm="8" className="modal-title">{this.getModalTitle()} : {totalCounts}</Col>
                <Col className="text-align-right">
                  <img
                    className="cursor-pointer"
                    src="images/close.svg"
                    alt="cancel"
                    width="15px"
                    onClick={() => this.props.closeModal()}
                  />
                </Col>
              </Row>
              <div></div>

              {/* <br /> */}
              <Row className="margin-bottom-10 margin-top-10">
                <Col xs="7">
                  <Input
                    placeholder="Search using dealer name or User id"
                    className="report-search-input"
                    name="search"
                    value={search}
                    onChange={this.handleSearchChange}
                  ></Input>
                  <img src="images/search.svg" className="report-search icon-width" alt="" />
                </Col>
              </Row>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>User Id</th>
                    <th>Role</th>
                    <th>Dealer Type</th>
                    {type === 'view' && <th>Viewed Date</th>}
                    {type === 'click' && <th>Clicked Date</th>}
                  </tr>
                </thead>
                <tbody>
                  {this.prepareRows()}
                </tbody>
              </Table>
              <div style={{ marginTop: "5px" }}>
                <Pagination
                  margin={2}
                  page={this.state.page}
                  count={this.state.totalPages}
                  pageSize={this.state.pageSize}
                  totalRecords={count}
                  onPageChange={this.handlePageChange}
                ></Pagination>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default TargetUsersModal;
