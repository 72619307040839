export const config = {
  WARRANTY_REGISTRATION: {
    CAR: {
      DAILYTYRELIMIT: 5,
      LAST6MONTHTYRELIMIT: 10,
    },
    TWOWHEELER: {
      DAILYTYRELIMIT: 3,
      LAST6MONTHTYRELIMIT: 5,
    },
  },
  DISPLAY_BUY_PRODUCT: true,
  DASHBOARD: {
    PCR_IMAGE: "images/pcr.png",
    WHEELER_IMAGE: "images/2w.png",
    TRUCK_IMAGE: "images/truck.png",
    FARM_IMAGE: "images/farm.png",
    SCV_IMAGE: "images/scv.png",
    LCV_IMAGE: "images/truck@2x.png",
  },
};
