import React from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Form
} from "reactstrap";

import Ionicons from 'react-ionicons';
import CustomerManagementService from '../../services/CustomerManagementService';
import { formatDate } from "../../utils/utils";
import "./VehicleDetailModal.css";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

class VehicleDetailModal extends React.Component {
  state = {
    vehicles: []
  };

  componentDidMount() {
    let customerId = this.props.data;
    this.getCustomerVehicle(customerId);
  }



  getCustomerVehicle = (customerId) => {
    this.setState({
      isLoading: true
    })
    CustomerManagementService.getCustomerVehicle(customerId)
      .then(data => {
        this.setState({
          isLoading: false
        })
        if (data.data.data.length > 0) {
          this.setState({ vehicles: data.data.data })
        }
      })
      .catch(e => {
        this.setState({
          isLoading: false
        })
      })
  }

  closeModal = () => {
    this.props.closeModal(true);
  };

  viewVehicleDetail = () => {
    this.props.viewVehicleDetail();
  }

  render() {
    const { vehicles } = this.state;
    // let obj = this.props.data ? this.props.data : [];
    return (
      <div>
        <Modal
          id="vehicle-detail-modal"
          isOpen={this.props.show}
          toggle={this.props.closeModal}
          centered
        >
          {this.state.isLoading && <CustomSpinner />}
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col className="modal-title">VEHICLE REGISTERED</Col>
                <Col sm="2" className="text-align-right">
                  <span className="cursor-pointer" onClick={this.closeModal}>
                    <Ionicons icon="md-close" color="#fdbb1f"></Ionicons>
                  </span>
                </Col>
              </Row>
              <div></div>
              {vehicles.map(o => <Row className="section-border">
                <Col className="padding-left-0 padding-right-0">
                  <div className="section-field-title">Vehicle Type</div>
                  <div className="section-field-value">{o.vehicle_type_name}</div>
                </Col>
                <Col className="padding-left-0 padding-right-0">
                  <div className="section-field-title">Vehicle Detail</div>
                  <div className="section-field-value">{o.vehicle_type_brand_name} {o.vehicle_type_brand_model_name}</div>
                  <div className="section-field-value vehicle-no">{o.registration_number}</div>
                </Col>
                <Col className="padding-left-0  padding-right-0">
                  <div className="section-field-title">Registered Date</div>
                  <div className="section-field-value">{o.registered_date && formatDate(o.registered_date)}</div>
                </Col>
              </Row>)}
              <Row>
                <Col>
                  <div className="visit-history mb-1 mt-3 justify-content-end d-flex" onClick={() => this.viewVehicleDetail()}>
                    <span className="ANV cursor-pointer font-size-14px">View Vehicle Details</span>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default VehicleDetailModal;
