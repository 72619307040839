import React, { Component } from 'react';
import {
  Button, Form,
  FormGroup, Label,
  Input,
  Row, Col
} from 'reactstrap';
import { Redirect } from 'react-router-dom';

//components
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

// modals
import AlertModal from '../../modal/AlertModal/AlertModal';
import ErrorModal from '../../modal/ErrorModal/ErrorModal';

// services
import AuthService from '../../services/AuthService';
import Validation from '../../services/Validation';
import ModalService from '../../services/ModalService';

// constant
import { messages } from '../../constants/constant.message';

// css
import './ForgotPassword.css';

const title = 'Forgot Password';
let { ERROR_MESSAGE } = messages.COMMON;
let { EMPTY_USER_NAME } = messages.LOGIN;

class ForgotPassword extends Component {

  state = {
    height: window.innerHeight,
    width: window.innerWidth,
    redirectToLoginPage: false,
    isRequestSent: false,
    isLoading: false,
    controls: {
      username: {
        value: '',
        valid: null,
        touched: false,
        nullValue: null
      }
    },
    isFormValid: false,
    isErrorModalOpen: false,
    errorMessage: ERROR_MESSAGE,
    isSuccessModalOpen: false,
    modalMessage: '',
    email: null
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    // this.handleValidation();
  }

  forgotPassword = (showAlert, e) => {
    if (e) {
      e.preventDefault();
    }
    const { controls } = this.state;
    const { username } = controls;

    const isFormValid = this.handleValidation();

    if (isFormValid === false) {
      return;
    }

    const obj = {
      sapId: username.value
    };
    this.setState({ isLoading: true })
    AuthService.forgotPassword(obj)
      .then(data => {
        const message = data.data.message;
        const email = data.data.data.email;
        if (showAlert === true) {
          ModalService.openAlert('Recover Password', message, 'success');
        }
        this.setState({ modalMessage: message, isRequestSent: true, email, isLoading: false });
        // this.openSuccessModal();
      })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
        // const message = e.response.data && e.response.data.message ? e.response.data.message : 'Something went wrong';
        ModalService.openAlert('Recover Password', message, 'error');
        // this.setState({ errorMessage: message, isErrorModalOpen: true });
      });
  }

  handleValidation = () => {
    let { controls, isFormValid } = this.state;
    let { username } = controls;

    username = Validation.notNullValidator(username);
    username.valid = !(username.nullValue);

    if (username.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  }

  resizeWindow = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  openSuccessModal = () => {
    this.setState({ isSuccessModalOpen: true });
  }

  closeSuceessModal = () => {
    this.setState({ isSuccessModalOpen: false, modalMessage: '', redirectToLoginPage: true });
  }

  openErrorModal = () => {
    this.setState({ isErrorModalOpen: true });
  }

  closeErrorModal = () => {
    this.setState({ isErrorModalOpen: false });
  }

  openLoginPage = () => {
    this.setState({ redirectToLoginPage: true });
  }

  render() {
    const { controls, redirectToLoginPage } = this.state;
    const { username } = controls;
    return (
      <div id="forgot-password">
        {/* <Container> */}
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        {redirectToLoginPage && <Redirect to="/login" />}
        <AlertModal
          show={this.state.isSuccessModalOpen}
          closeModal={this.closeSuceessModal}
          title={title}
          msg={this.state.modalMessage}
          buttons={null}
          child={null}
        />
        <ErrorModal
          closeModal={this.closeErrorModal}
          // open={this.state.isErrorModalOpen}
          title="Recover Password"
          msg={this.state.errorMessage}
          open={this.state.isErrorModalOpen}
        />
        <Row>
          <Col xl="3" xs="1" md="1" className={this.state.width < 1200 ? 'display-none' : ''}>
            <img className="login-left-img" src="images/login-img.jpg" alt="" />
          </Col>
          <Col xl="1" xs="1" md="1" />

          {!this.state.isRequestSent &&
            <Col xl="7" xs="10" md="10" className="forgot-password-container">
              <Form className="width-100">
                <Row className={this.state.width < 1200 ? 'margin-top-20' : null} />
                <Row className="padding-left-13" style={{ marginBottom: '20px' }}>
                  <img src="images/logo.png" width="233px" height="76px" style={{ objectFit: 'contain' }} alt="" />
                </Row>
                <Row className="welcome-text">
                  Recover your Password
                </Row>
                <Row className="sign-in-subtitle-text margin-top-5">
                  <div>
                    Please enter your User ID and a reset link will be sent to your email address.
                  </div>
                </Row>
                <hr />
                <Row>
                  <Col xl="6" xs="12" md="12">
                    {/* <Row> */}
                    <FormGroup>
                      <Label for="username">User ID</Label>
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        value={username.value}
                        onChange={this.handleInputChange}
                        placeholder="Enter user ID" />
                      {username.nullValue && <div className="error">* {EMPTY_USER_NAME}</div>}

                    </FormGroup>
                    {/* </Row> */}
                  </Col>
                  <Col xl="6" xs="12" md="12">
                    {/* <Row> */}

                    {/* </Row> */}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xl="4" className={this.state.width < 1200 ? 'display-none' : ''} />
                  <Col xl="8" xs="12" md="12" className="text-align-right" >
                    <Button className="mb-1 mr-1 btn-pill action-button forgot-password"
                      color="dark"
                      onClick={this.openLoginPage}
                    >
                      Back to login
                    </Button>{'  '}
                    <Button
                      className="mb-1 mr-1 btn-pill action-button send-recovery-link"
                      color="warning"
                      type="submit"
                      onClick={e => this.forgotPassword(false, e)}
                    >Send recovery link</Button>
                  </Col>
                </Row>
              </Form>
            </Col>}
          {this.state.isRequestSent &&
            <Col xl="7" xs="10" md="10" className="forgot-password-container">
              <div className="width-100">
                <Row className="" style={{ marginBottom: '20px' }}>
                  <Col>
                    <img src="images/login-logo.jpg" alt="" />
                  </Col>
                </Row>
                <Row>
                  <Col className="sign-in-subtitle-text">
                    <div>
                      We have sent a password reset link to {this.state.email}.
                    </div>
                    <div>
                      Please check your email.
                    </div>
                  </Col>
                </Row>
                {/* <br /> */}
                {/* <br /> */}
                <Row className="margin-top-40">
                  <Col className="sign-in-subtitle-text">
                    Didn’t get the link? <span className="link cursor-pointer" onClick={this.forgotPassword.bind(this, true)}>Resend</span>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xl="4" className={this.state.width < 1200 ? 'display-none' : ''} />
                  <Col xl="8" xs="12" md="12" className="text-align-right" >
                    <Button className="mb-1 mr-1 btn-pill action-button send-recovery-link" color="warning" onClick={this.openLoginPage}>Back to Login</Button>
                  </Col>
                </Row>
              </div>
            </Col>}
          <Col />

          <Col xl="1" xs="1" sm="1" />

        </Row>
        {/* </Container> */}
      </div >
    );
  }
}

export default ForgotPassword;