import React, { Component } from 'react';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import classnames from 'classnames';
import WarrantyRegistrationService from '../../../services/WarrantyRegistrationService';
import GoogleAnalticsService from '../../../services/GoogleAnalticsService';

class VehicalModel extends Component {
  vehicleModel = [];

  displayVehicleDetail = [];

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      controls: props.vehicleDetails && props.vehicleDetails.controls ? props.vehicleDetails.controls : {},
      isValidForm: props.vehicleDetails && props.vehicleDetails.isValidForm
    };
    this.getVehicleModel();
  }

  componentDidMount() {
    GoogleAnalticsService.event('Add Warranty', 'Vehicle Model View');
    this.isMobile = WarrantyRegistrationService.checkUserAgent();
    this.setState((prevState) => {
      return { ...prevState };
    });
  }

  async getVehicleModel() {
    try {
      const { controls } = this.state;
      const { vehicleTypeBrandId } = controls;
      this.props.isDataLoading(true);
      const { data } = await WarrantyRegistrationService.getVehicalModel({ vehicle_type_brand_id: vehicleTypeBrandId.value.brand_id });
      this.props.isDataLoading(false);
      if (data) {
        this.vehicleModel = JSON.parse(JSON.stringify(data.data));
        this.displayVehicleDetail = JSON.parse(JSON.stringify(data.data));
        if (this.displayVehicleDetail.length === 1 && this.displayVehicleDetail[0].name.toLowerCase() === ('other').toLowerCase()) {

        } else {
          this.displayVehicleDetail = JSON.parse(JSON.stringify(this.displayVehicleDetail.filter((value) => value.name.toLowerCase() !== ('other').toLowerCase())))
        }
        this.vehicleModel.forEach((element, index) => {
          if ((element.name).toLowerCase() === ('other').toLowerCase()) {
            element.name = 'Other (Vehicle Model is not available)'
          }
        });
      }
      this.setState((prevState) => {
        return { ...prevState };
      });
    } catch (error) {
      this.props.isDataLoading(false);
      console.log(error);
    }
  }

  goBack = () => {
    const { controls, isValidForm } = this.state;
    this.props.updateState({ visibleArea: 'vehicleMake', vehicleDetails: { controls, isValidForm } });
  }

  vechileModelClick(item) {
    const { controls, isValidForm } = this.state;
    controls.vehicleTypeBrandModelId.value = item;
    this.setState({ controls });
    this.props.updateState({ visibleArea: 'productDetailsEdit', vehicleDetails: { controls, isValidForm } });
  }

  render() {
    return (
      <>
        <style jsx="true">
          {
            `
            @media screen and (min-width: 220px) and (max-width: 991px) {
              .popover, .dropdown-menu {
                left: -1% !important;
                top: 0px !important;
                width: 100% !important;
                position: absolute !important;
                transform: translate3d(0px, 31px, 0px) !important;
              }
            }
        `
          }
        </style>
        <Row className="warranty-customer-details-label">
          <Col xl="10" xs="10" ms="10" style={{ display: 'flex' }}>
            <FontAwesomeIcon onClick={this.goBack} icon={faAngleLeft} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
            &nbsp;&nbsp;
            <span style={{ marginTop: '2px' }}>VEHICLE MODEL</span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <FormGroup>
              <Label for="tyreQuantity" className="input-label" style={{ fontWeight: 'bold' }}>Select the model of your customer’s vehicle<span className="required-star"> *</span></Label>
              <div className="d-flex-row vehical-sku-id-search service-pref-note-warranty">
                <Row>
                  <Label for="tyreQuantity" className="input-label">
                    Note - If you can not find the company of your customer’s vehicle please use the search bar to search for it.
                  </Label>
                  <Typeahead
                    id="vehicalModel"
                    filterBy={(option, props) => {
                      if (props.selected.length) {
                        return true;
                      }
                      return option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || option.name.toLowerCase().indexOf('other') !== -1;
                    }}
                    labelKey="name"
                    options={this.vehicleModel}
                    placeholder="Search..."
                    onChange={(selectedModel) => this.vechileModelClick(selectedModel[0])}
                  />
                  <FontAwesomeIcon className="serach-icon" icon={faSearch} color="#6e777f" />
                </Row>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ marginLeft: '0', marginTop: '10px' }}>
          {
            (this.displayVehicleDetail || []).map((type) => {
              return (
                <Col xl="3" xs="12" sm="12" md="3" style={{ paddingRight: '10px', paddingLeft: '2px' }} key={type.name}>
                  <button title={type.name} type="button" onClick={() => this.vechileModelClick(type)} className={classnames({ 'w-100 mb-3': this.isMobile }, 'mb-2 mr-2 btn-icon btn-pill btn btn-outline-secondary custom-secondary text-overflow-dots')} style={{ width: '100%' }}>
                    <span className="input-label">
                      {type.name}
                    </span>
                  </button>
                </Col>
              );
            })
          }
        </Row>
        <br />
        {/* <span className="Note---If-you-can-no">Note - If you can not find the model of your customer's vehicle please use the search bar to search for it.</span> */}
      </>
    );
  }
}

export default VehicalModel;