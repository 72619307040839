import React, { Component } from "react";
import { Button, Row, Col, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import classnames from "classnames";
import swal from "sweetalert";
import MaskedInput from "react-maskedinput";
import Validation from "../../../services/Validation";
import WarrantyRegistrationService from "../../../services/WarrantyRegistrationService";
import { messages } from "../../../constants/constant.message";

class VehicleRegistrationNumber extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      from: props.from,
      controls: (props.vehicleDetails && props.vehicleDetails.controls) || {},
      isValidForm: props.vehicleDetails && props.vehicleDetails.isValidForm,
      registeredVehicleDetails: props.registeredVehicleDetails,
      isDisabled: props.isDisabled ? true : false,
      localControls: {
        registration1: {
          value:
            (props.vehicleLocalRegistrationNumber &&
              props.vehicleLocalRegistrationNumber.registration1 &&
              props.vehicleLocalRegistrationNumber.registration1.value) ||
            "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidValue: null,
        },
        registration2: {
          value:
            (props.vehicleLocalRegistrationNumber &&
              props.vehicleLocalRegistrationNumber.registration2 &&
              props.vehicleLocalRegistrationNumber.registration2.value) ||
            "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidValue: null,
        },
        registration3: {
          value:
            (props.vehicleLocalRegistrationNumber &&
              props.vehicleLocalRegistrationNumber.registration3 &&
              props.vehicleLocalRegistrationNumber.registration3.value) ||
            "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidValue: null,
        },
        registration4: {
          value:
            (props.vehicleLocalRegistrationNumber &&
              props.vehicleLocalRegistrationNumber.registration4 &&
              props.vehicleLocalRegistrationNumber.registration4.value) ||
            "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidValue: null,
        },
      },
      isLocalValidForm:
        !!this.props.vehicleDetails.controls.registrationNumber &&
        this.props.vehicleDetails.controls.registrationNumber.value,
    };
  }

  componentDidMount() {
    const { controls } = this.state;
    const { vehicleCategory } = controls;
    if (vehicleCategory && !vehicleCategory.value) {
      vehicleCategory.value = "personal";
    }
    this.isMobile = WarrantyRegistrationService.checkUserAgent();
    this.setState((prevState) => {
      return { ...prevState, controls };
    });
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { isValidForm, controls, localControls } = this.state;
    localControls[controlName].touched = true;
    if (
      controlValue &&
      (controlName === "registration1" || controlName === "registration3")
    ) {
      localControls[controlName].value = (controlValue || "").toUpperCase();
      if (controlName === "registration1") {
        localControls.registration1.touched = true;
        this.setState({ localControls });
      } else {
        localControls.registration3.touched = true;
      }
    } else if (
      controlValue === "" &&
      (controlName === "registration1" || controlName === "registration3")
    ) {
      localControls[controlName].value = (controlValue || "").toUpperCase();
      if (controlName === "registration1") {
        localControls.registration1.touched = true;
      } else {
        localControls.registration3.touched = true;
      }
    }

    if (
      controlValue &&
      (controlName === "registration2" || controlName === "registration4")
    ) {
      localControls[controlName].value = controlValue;
      localControls[controlName].touched = true;
    } else if (
      controlValue === "" &&
      (controlName === "registration2" || controlName === "registration4")
    ) {
      localControls[controlName].value = controlValue;
      localControls[controlName].touched = true;
    }

    if (
      (!isNaN(controlValue) || controlName === "vehicleCategory") &&
      controls[controlName]
    ) {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }

    this.setState({ localControls, controls });

    const {
      registration1,
      registration2,
      registration3,
      registration4,
    } = localControls;
    if (registration3.value) {
      controls.registrationNumber.value = `${(
        registration1.value || ""
      ).replace(/_|\s/g, "")}-${(registration2.value || "").replace(
        /_|\s/g,
        ""
      )}-${(registration3.value || "").replace(/_|\s/g, "")}-${(
        registration4.value || ""
      ).replace(/_|\s/g, "")}`;
    } else {
      controls.registrationNumber.value = `${(
        registration1.value || ""
      ).replace(/_|\s/g, "")}-${(registration2.value || "").replace(
        /_|\s/g,
        ""
      )}-${(registration4.value || "").replace(/_|\s/g, "")}`;
    }

    this.props.updateState({
      visibleArea: "vehicleRegistrationNumber",
      isSameRoute: true,
      vehicleDetails: { controls, isValidForm },
      vehicleLocalRegistrationNumber: localControls,
    });
    this.handleValidation();
  };

  handleInputChangeVehicleCategory = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls, isValidForm } = this.state;
    if (!isNaN(controlValue) || controlName === "vehicleCategory") {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }

    this.setState({ controls });
    this.props.updateState({
      visibleArea: "vehicleRegistrationNumber",
      isSameRoute: true,
      vehicleDetails: { controls, isValidForm },
    });
  };

  handleValidation = () => {
    const { localControls, controls, registeredVehicleDetails } = this.state;
    let { isLocalValidForm, isValidForm } = this.state;
    let {
      registration1,
      registration2,
      // registration3,
      registration4,
    } = localControls;
    if (registration1.touched === true) {
      registration1 = Validation.notNullValidator(registration1);
      registration1.valid =
        !registration1.nullValue &&
        (registration1.value || "").replace(/_|\s/g, "").length === 2;
      if ((registration1.value || "").replace(/_|\s/g, "").length === 2) {
        document.getElementById("registration2").focus();
      }
      registration1.touched = false;
    }

    if (registration2.touched === true) {
      registration2 = Validation.notNullValidator(registration2);
      registration2.valid =
        !registration2.nullValue &&
        (registration2.value || "").replace(/_|\s/g, "").length >= 1;
      if ((registration2.value || "").replace(/_|\s/g, "").length === 2) {
        document.getElementById("registration3").focus();
      }
      registration2.touched = false;
    }

    // if (registration3.touched === true) {
    //   registration3 = Validation.notNullValidator(registration3);
    //   registration3.valid = !registration3.nullValue && (registration3.value || '').replace(/_|\s/g, '').length >= 1;
    //   if ((registration3.value || '').replace(/_|\s/g, '').length === 3) {
    //     document.getElementById('registration4').focus();
    //   }
    //   registration3.touched = false;
    // }

    if (registration4.touched === true) {
      registration4 = Validation.notNullValidator(registration4);
      registration4.valid =
        !registration4.nullValue &&
        ((registration4.value || "").replace(/_|\s/g, "").length === 4 ||
          (registration4.value || "").replace(/_|\s/g, "").length === 3);
      if (
        (registration4.value || "").replace(/_|\s/g, "").length === 4 ||
        (registration4.value || "").replace(/_|\s/g, "").length === 3
      ) {
        if (this.props.from && this.props.from !== "AddNewCustomer") {
          document.getElementById("registrationNumberNextButton").focus();
        }
      }
      registration4.touched = false;
    }

    isLocalValidForm = false;
    if (registration1.valid && registration2.valid && registration4.valid) {
      isLocalValidForm = true;
      isValidForm = true;
    } else {
      isLocalValidForm = false;
      isValidForm = false;
    }

    if (
      _.some(registeredVehicleDetails, {
        registration_number: controls.registrationNumber.value,
      })
    ) {
      isLocalValidForm = false;
      isValidForm = false;
      swal(
        "",
        messages.WARRANTY_REGISTRATION.ALREADYREGISTEREDVEHICLENUMBER,
        "warning"
      );
    }

    this.setState({ localControls, isLocalValidForm, isValidForm });
    return isValidForm;
  };

  onNextClick = () => {
    const { isValidForm, controls, localControls } = this.state;
    const {
      registration1,
      registration2,
      registration3,
      registration4,
    } = localControls;
    if (registration3.value) {
      controls.registrationNumber.value = `${(
        registration1.value || ""
      ).replace(/_|\s/g, "")}-${(registration2.value || "").replace(
        /_|\s/g,
        ""
      )}-${(registration3.value || "").replace(/_|\s/g, "")}-${(
        registration4.value || ""
      ).replace(/_|\s/g, "")}`;
    } else {
      controls.registrationNumber.value = `${(
        registration1.value || ""
      ).replace(/_|\s/g, "")}-${(registration2.value || "").replace(
        /_|\s/g,
        ""
      )}-${(registration4.value || "").replace(/_|\s/g, "")}`;
    }
    if (isValidForm) {
      this.props.updateState({
        visibleArea: "vehicleMake",
        vehicleDetails: { controls, isValidForm },
        vehicleLocalRegistrationNumber: localControls,
      });
    }
  };

  goBack = () => {
    const { controls, isValidForm } = this.state;
    this.props.updateState({
      visibleArea: "vehicleType",
      vehicleDetails: { controls, isValidForm },
    });
  };

  render() {
    const { localControls, controls } = this.state;
    const { vehicleCategory, vehicleTypeId } = controls;
    const { isLocalValidForm,
      // isDisabled
    } = this.state;
    const {
      registration1,
      registration2,
      registration3,
      registration4,
    } = localControls;
    let isShowHeader = true;
    if (this.props.from && this.props.from === "AddNewCustomer") {
      isShowHeader = false;
    }
    return (
      <div>
        {isShowHeader && (
          <Row className="warranty-customer-details-label">
            <Col sm="12" style={{ display: "flex" }}>
              <FontAwesomeIcon
                onClick={this.goBack}
                icon={faAngleLeft}
                style={{ fontSize: "20px", cursor: "pointer" }}
                color="#fdbb1f"
              />
              &nbsp;&nbsp;
              <span style={{ marginTop: "2px" }}>
                VEHICLE REGISTRATION NUMBER
              </span>
            </Col>
          </Row>
        )}
        <div>
          {isShowHeader && <hr />}
          <Row>
            <Col>
              <span className="input-label">
                Registration Number
                {/* <span className="required-star"> *</span> */}
              </span>
              <br />
              <div style={{ marginTop: "15px" }}>
                {/* ${controls.firstname.value ? 'filled-data-field' : ''}` */}

                <Row style={{ flexWrap: "nowrap" }}>
                  <div
                    className={classnames(
                      { "pr-0": this.isMobile },
                      "col-lg-3 col-md-2 col-sm-3 col-xs-3"
                    )}
                    style={this.isMobile ? { paddingLeft: "15px" } : {}}
                  >
                    <MaskedInput
                      mask="W   W"
                      formatCharacters={{
                        W: {
                          validate(char) {
                            return /\w/.test(char);
                          },
                          transform(char) {
                            return char.toUpperCase();
                          },
                        },
                      }}
                      type="text"
                      size="2"
                      placeholder="_   _"
                      className="form-control"
                      name="registration1"
                      id="registration1"
                      value={registration1.value}
                      onChange={this.handleInputChange}
                      style={{ textAlign: "center" }}
                    />
                    <div className="registration-number-help-label">
                      D&nbsp;&nbsp;&nbsp;L
                    </div>
                  </div>
                  <div
                    className={classnames(
                      { "pr-0": this.isMobile },
                      "col-lg-3 col-md-2 col-sm-2 col-xs-2"
                    )}
                    style={this.isMobile ? { paddingLeft: "6px" } : {}}
                  >
                    <MaskedInput
                      mask="W   W"
                      formatCharacters={{
                        W: {
                          validate(char) {
                            return /\w/.test(char);
                          },
                          transform(char) {
                            return char.toUpperCase();
                          },
                        },
                      }}
                      type="text"
                      placeholder="_   _"
                      className="form-control"
                      name="registration2"
                      id="registration2"
                      value={registration2.value}
                      onChange={this.handleInputChange}
                      style={{ textAlign: "center" }}
                    />
                    <div className="registration-number-help-label">
                      0&nbsp;&nbsp;&nbsp;1
                    </div>
                  </div>
                  <div
                    className={classnames(
                      { "pr-0": this.isMobile },
                      "col-lg-3 col-md-2 col-sm-2 col-xs-2"
                    )}
                    style={this.isMobile ? { paddingLeft: "6px" } : {}}
                  >
                    <MaskedInput
                      mask="W   W   W"
                      formatCharacters={{
                        W: {
                          validate(char) {
                            return /\w/.test(char);
                          },
                          transform(char) {
                            return char.toUpperCase();
                          },
                        },
                      }}
                      type="text"
                      placeholder="_   _   _"
                      className="form-control"
                      name="registration3"
                      id="registration3"
                      value={registration3.value}
                      onChange={this.handleInputChange}
                      style={{ textAlign: "center" }}
                    />
                    <div className="registration-number-help-label">
                      X&nbsp;&nbsp;&nbsp;Y&nbsp;&nbsp;&nbsp;Z
                    </div>
                  </div>
                  <div
                    className={classnames(
                      { "pr-0": this.isMobile },
                      "col-lg-4 col-md-3 col-sm-3 col-xs-3"
                    )}
                    style={this.isMobile ? { paddingLeft: "6px" } : {}}
                  >
                    <MaskedInput
                      mask="W   W   W   W"
                      formatCharacters={{
                        W: {
                          validate(char) {
                            return /\w/.test(char);
                          },
                          transform(char) {
                            return char.toUpperCase();
                          },
                        },
                      }}
                      type="text"
                      placeholder="_   _   _   _"
                      className="form-control"
                      name="registration4"
                      id="registration4"
                      value={registration4.value}
                      onChange={this.handleInputChange}
                      style={{ textAlign: "center" }}
                    />
                    <div className="registration-number-help-label">
                      1&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;&nbsp;4
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
          {isShowHeader && (
            <>
              <br />
              <div>
                <Label for="vehicleCategory" className="input-label">
                  Please select your customer’s type of ownership of the vehicle
                </Label>
              </div>
              <Row style={{ marginTop: "2px" }}>
                <Col xl="3" xs="12" ms="12" className="mt-1">
                  <div className="d-flex-row">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="personal"
                        value="personal"
                        checked={controls.vehicleCategory.value === "personal"}
                        onChange={(e) =>
                          this.handleInputChangeVehicleCategory({
                            target: {
                              name: "vehicleCategory",
                              value: "personal",
                            },
                          })
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: "10px" }}>Personal</span>
                  </div>
                </Col>

                <Col xl="6" xs="12" ms="12" className="mt-1">
                  <div className="d-flex-row">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="taxi"
                        value="taxi"
                        checked={vehicleCategory.value === "taxi"}
                        onChange={(e) =>
                          this.handleInputChangeVehicleCategory({
                            target: { name: "vehicleCategory", value: "taxi" },
                          })
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: "10px" }}>
                      Taxi{" "}
                      {(vehicleTypeId.value.name || "").toLowerCase() === "car"
                        ? "(Uber, OLA, Private taxi, etc.)"
                        : "(Zomato, OLA, Uber, etc.)"}
                    </span>
                  </div>
                </Col>
                <Col xl="6" xs="2" ms="2" />
              </Row>
            </>
          )}
          {isShowHeader && (
            <Row>
              <Col>
                <br />
                <Button
                  id="registrationNumberNextButton"
                  disabled={!isLocalValidForm}
                  className={classnames(
                    { "w-100 mt-4": this.isMobile },
                    "mb-1",
                    "mr-1",
                    "btn-pill",
                    "next-btn",
                    "font-weight-bold"
                  )}
                  color="warning"
                  onClick={this.onNextClick}
                >
                  Next
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default VehicleRegistrationNumber;
