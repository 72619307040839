import React, { Component } from 'react';
import {
  Button,
  Row, Col,
  Table,
  Card, CardBody,
} from 'reactstrap';
import './ProjectStageStatus.css';
import ProposalStatus from '../../components/ProposalStatus/ProposalStatus';
import Storage from '../../services/Storage';
import ProposalSatusUpdate from '../../components/ProposalSatusUpdate/ProposalSatusUpdate';
import ProposalSerivice from '../../services/ProposalSerivice';

class ProjectStageStatus extends Component {

  steelWheelState = [
    {
      id: 'recci_in_progress',
      name: 'Recci in progress',
      count: 1
    },
    {
      id: 'layout_agreement',
      name: 'Layout & Agreement',
      count: 1
    },
    {
      id: 'estimate_needed',
      name: 'Estimate Needed',
      count: 1
    },
    {
      id: 'Dealer_work_in_progress',
      name: 'Dealer Work In Progress',
      count: 1
    },
    {
      id: 'Agency_work_in_progress',
      name: 'Agency work in progress',
      count: 1
    },
    {
      id: 'Hand Over',
      name: 'Hand Over',
      count: 1
    },
    {
      id: 'Pre inauguration',
      name: 'Pre inauguration',
      count: 1
    },
    {
      id: 'Inaugurated',
      name: 'Inaugurated',
      count: 1
    },
    {
      id: 'Project completed',
      name: 'Project completed',
      count: 1
    },
  ]
  state = {};
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    let userDetail = Storage.getUserDetail();
    this.state = {
      id: query.get('id'),
      type: query.get('type'),
      userDetail: userDetail,
      proposalStageStatus: ProposalSerivice.getProposalStageStatus() ? ProposalSerivice.getProposalStageStatus() : {},
      isRecciInProgressReadOnly: true,
      proposalByIdData: {},
      proposalStageDetail: {
        agency_completion_data: [],
        dealership_completion_data: [],
        estimation_data: [],
        intrim_visit_data: [],
        layout_data: [],
        proposal_status: [],
        recci_data: [],
        stages_details: [],
      }
    };

    if (!this.state.id || (this.state.proposalStageStatus && Object.keys(this.state.proposalStageStatus).length <= 0)) {
      //  this.goToPrevPage();
    }
    if (this.state.proposalStageStatus && Object.keys(this.state.proposalStageStatus).length > 0) {
      if (this.state.proposalStageStatus.project_status &&
        this.state.proposalStageStatus.project_status.toLowerCase() === 'fresh proposal' &&
        this.state.userDetail.access_level === 'all') {
        this.state.isFreshProposal = true;
      }
    }
    if (this.state.type === 'sw') {
      this.state.progressStates = this.steelWheelState;
    }
    this.state.progressStateCurrentIndex = 0;
    if (this.state.progressStates && this.state.progressStates.length > 0) {
      this.state.progressStateCurrentIndex = this.state.progressStates.findIndex(x => x.id === this.state.id);
    } else {
      this.goToPrevPage();
    }
    if (this.state.progressStateCurrentIndex <= -1) {
      this.goToPrevPage();
    }
  }

  componentDidMount() {
    this.getPrposalById();
    this.getProposalStageDetail();
  }

  setPreInauguration_date = () => {
    let { controls_inauguration } = this.state;
    if (this.props.proposalStageDetail && this.props.proposalStageDetail.proposal_status
      && this.props.proposalStageDetail.proposal_status.length > 0) {
      controls_inauguration.inauguration_date = this.props.proposalStageDetail.proposal_status[0].inauguration_date ?
        new Date(this.props.proposalStageDetail.proposal_status[0].inauguration_date) : null;
      if (controls_inauguration.inauguration_date) {
        controls_inauguration.isUpdate = true;
      }
      this.setState({ controls_inauguration });
    }
  }

  getProposalStageDetail() {
    if (!this.state.proposalStageStatus && (!this.state.proposalStageStatus.uuid || this.state.proposalStageStatus.uuid === undefined)) {
      return
    }
    this.setState({ isLoading: true });
    ProposalSerivice.getProposalStageDetail(this.state.proposalStageStatus.uuid)
      .then(res => {

        if (res.data.data && res.data.data.length > 0) {
          this.setState((prevState) => {
            return { ...prevState, proposalStageDetail: res.data.data[0] }
          });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }
  getPrposalById() {
    if (!this.state.proposalStageStatus && (!this.state.proposalStageStatus.uuid || this.state.proposalStageStatus.uuid === undefined)) {
      return
    }
    this.setState({ isLoading: true });
    ProposalSerivice.getProposalById(this.state.proposalStageStatus.uuid)
      .then(res => {
        if (res.data.data && res.data.data.length > 0) {
          this.setState((prevState) => {
            return { ...prevState, proposalByIdData: res.data.data[0] }
          });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }
  goToPrevPage = () => {
    this.props.history.goBack();
  }

  redirectViewProposal = (id) => {
    this.props.history.push(`proposal-details?id=${id}`);
  }

  render() {
    return (
      <>
        <div className="header-title">Project Stage</div>
        <br />
        {
          !this.state.isFreshProposal &&
          <>
            <Card >
              <CardBody>
                <Row className="dealership-details-title-wrapp">
                  <Col xl="6" md="6" xs="6">
                    <b>Project status</b>
                  </Col>
                  <div>
                    <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.goToPrevPage()}>Back</Button>
                  </div>
                </Row>
                <hr style={{ marginTop: '0.5rem' }} />
                <div>
                  <section>
                    <nav>
                      <ol className="cd-breadcrumb triangle custom-icons">
                        {
                          this.state.progressStates.map((row, i) => (
                            <li className={this.state.progressStateCurrentIndex > i ? 'current-before' : (this.state.progressStateCurrentIndex === i ? 'current' : '')}>
                              <span>{row.name}</span>
                            </li>
                          ))
                        }
                        {/* <li className="current-before"><span>Recci in progress <br />30/07/2019</span></li>
                  <li className="current-before"><span>Layout & Agrespanent</span></li>
                  <li className="current-before"><span>Estimate needed</span></li>
                  <li className="current"><span>Dealer work in progress</span></li>
                  <li><span>Agency work in progress</span></li>
                  <li><span>Hand Over</span></li>
                  <li><span>Pre inauguration</span></li>
                  <li><span>Inaugurated</span></li>
                  <li><span>Project completed</span></li> */}
                      </ol>
                    </nav>
                  </section>
                </div>
                <br />
                <Table className="mb-0  tbl-about tbl-about table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Status</th><th scope="col">Remarks</th><th scope="col">Status Change Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Active</td><td>Agreement soft copy received on email (23-July-2019) .  </td><td>23 Jul 2019</td>
                    </tr><tr>
                      <td>On Hold</td><td>Signed agreement copy is not received yet. Please provide asap to revive the same.</td><td>18 Jul 2019</td>
                    </tr>

                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <br />
            <ProposalStatus
              userDetail={this.state.userDetail}
              proposalByIdData={this.state.proposalByIdData}
              proposalStageDetail={this.state.proposalStageDetail}
              goToPrevPage={() => this.goToPrevPage()} />
          </>
        }

        {
          this.state.isFreshProposal &&
          <ProposalSatusUpdate
            proposalStageStatus={this.state.proposalStageStatus}
            redirectViewProposal={this.redirectViewProposal}
            goToPrevPage={() => this.goToPrevPage()} />
        }
      </>
    );
  }
}

export default ProjectStageStatus;