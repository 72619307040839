import React, { Component, Fragment } from 'react';
import { Button, Table, Modal, ModalBody, ModalHeader, FormGroup, Input, Label, Row, Col, Form } from 'reactstrap';
import sweetAlert from 'sweetalert';

import PropTypes from 'prop-types';
import { formatDate } from '../../utils/utils';
import MerchandiseService from '../../services/MerchandiseService';

class MerchandiseOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remarks: ''
    };

    this.updateRemarks = this.updateRemarks.bind(this);
    this.remarkValueChange = this.remarkValueChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { remarks } = nextProps.orderDetails;

    if (remarks !== this.props.orderDetails.remarks) {
      this.setState({
        remarks
      });
    }
    return null;
  }

  async updateRemarks() {
    const { orderDetails, onUpdateOrder } = this.props;
    const { id: orderId, index } = orderDetails;
    const { remarks } = this.state;
    const alert = {
      title: 'Opps !!!',
      text: 'Error while updating remarks',
      icon: 'error',
      type: 'error'
    };

    const { success, data, message } = await MerchandiseService.updateMerchandiseOrder(orderId, { remarks });

    if (success) {
      const updatedOrder = {
        ...orderDetails,
        ...data
      };
      alert.title = 'Congratulations !';
      alert.text = message;
      alert.icon = 'success';
      alert.type = 'success';
      sweetAlert(alert);

      onUpdateOrder(updatedOrder, index);
    }

    sweetAlert(alert);
  }

  remarkValueChange(e) {
    const { target: { name, value } } = e;
    const controlName = name;
    const controlValue = value;

    this.setState({
      [controlName]: controlValue || null
    });
  }

  render() {
    const { remarks } = this.state;
    const { isOpen, onExit, orderDetails } = this.props;
    const {
      id,
      sap_id: sapId,
      group_order_id: groupOrderId,
      tracking_id: trackingId,
      order_id: orderId,
      business_name: businessName,
      owner_name: ownerName,
      telephone_no_1: mobile,
      email_1: email,
      item_name: itemName,
      item_quantity: quantity,
      status,
      date: orderDate,
      ready_to_dispatch_date: readyToDispatchDate,
      dispatch_date: dispatchDate,
      delivery_date: deliveryDate,
      address,
      city,
      state,
      pin_code: pinCode
    } = orderDetails;
    return (
      <Fragment>
        <Modal id="merchandise-order-details" isOpen={isOpen} toggle={onExit} onExit={onExit} size='xl' centered scrollable>
          <ModalHeader toggle={onExit}>Order Details</ModalHeader>
          <ModalBody>
            <Row>
              <Col xl="8" xs="12" md="12">
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Sr.: </strong></td>
                      <td> {id} </td>
                    </tr>
                    <tr>
                      <td><strong>SAP Id: </strong></td>
                      <td> {sapId} </td>
                    </tr>
                    <tr>
                      <td><strong>Tracking Id: </strong></td>
                      <td> {trackingId} </td>
                    </tr>
                    <tr>
                      <td><strong>Group Id: </strong></td>
                      <td> {groupOrderId} </td>
                    </tr>
                    <tr>
                      <td><strong>Order Id: </strong></td>
                      <td> {orderId} </td>
                    </tr>
                    <tr>
                      <td><strong>Business Name: </strong></td>
                      <td> {businessName} </td>
                    </tr>
                    <tr>
                      <td><strong>Email: </strong></td>
                      <td> {email} </td>
                    </tr>
                    <tr>
                      <td><strong>To: </strong></td>
                      <td>
                        <div>{ownerName}</div>
                        <div>{businessName}</div>
                        <div>{mobile}</div>
                        <div>{`${address}, ${city}, ${state} - ${pinCode}`}</div>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>From: </strong></td>
                      <td>
                        <div>JK Advantage 2.0 Support</div>
                        <div>Lomoso Solution Pvt Ltd</div>
                        <div>H-609, Titanium City Center, 100 Feet Rd, Satellite,Ahmedabad, Gujarat 380015</div>
                        <div>GSTN: 24AADCL1179K1ZF</div>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Item Name: </strong></td>
                      <td> {itemName} </td>
                    </tr>
                    <tr>
                      <td><strong>Quantity: </strong></td>
                      <td> {quantity} </td>
                    </tr>
                    <tr>
                      <td><strong>Status: </strong></td>
                      <td> {status} </td>
                    </tr>
                    <tr>
                      <td><strong>Order Date: </strong></td>
                      <td> {orderDate ? formatDate(orderDate) : '-'} </td>
                    </tr>
                    <tr>
                      <td><strong>Ready To Dispatch Date: </strong></td>
                      <td> {readyToDispatchDate ? formatDate(readyToDispatchDate) : '-'} </td>
                    </tr>
                    <tr>
                      <td><strong>Dispatch Date: </strong></td>
                      <td> {dispatchDate ? formatDate(dispatchDate) : '-'} </td>
                    </tr>
                    <tr>
                      <td><strong>Delivery Date: </strong></td>
                      <td> {deliveryDate ? formatDate(deliveryDate) : '-'} </td>
                    </tr>
                    <tr>
                      <td><strong>Address: </strong></td>
                      <td> {`${address}, ${city}, ${state} - ${pinCode}`} </td>
                    </tr>
                    <tr>
                      <td><strong /></td>
                      <td />
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col xl="4" xs="12" md="12">
                <Form>
                  <FormGroup>
                    <Label for="remarks">Remarks</Label>
                    <Input type="textarea" rows="7" name="remarks" id="remarks" value={remarks} onChange={this.remarkValueChange} />
                  </FormGroup>
                  <Button type="button" color='primary' onClick={this.updateRemarks}>Submit</Button>
                </Form>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

MerchandiseOrderDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  orderDetails: PropTypes.shape({
    id: PropTypes.any,
    sap_id: PropTypes.any,
    business_name: PropTypes.any,
    item_name: PropTypes.any,
    item_quantity: PropTypes.any,
    status: PropTypes.any,
    date: PropTypes.any,
    ready_to_dispatch_date: PropTypes.any,
    dispatch_date: PropTypes.any,
    delivery_date: PropTypes.any,
    address: PropTypes.any,
    city: PropTypes.any,
    state: PropTypes.any,
    pin_code: PropTypes.any,
    remarks: PropTypes.any,
    index: PropTypes.any,
    group_order_id: PropTypes.any,
    tracking_id: PropTypes.any,
    order_id: PropTypes.any,
  }).isRequired,
  onExit: PropTypes.func.isRequired,
  onUpdateOrder: PropTypes.func.isRequired
};

export default MerchandiseOrderDetails;