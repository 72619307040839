import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types'; // or PropTypes = require('prop-types');

class ErrorModal extends React.Component {
  componentWillReceiveProps(nextProps) {
    // this.state.modal = nextProps.show;
  }

  render() {
    // this.state.modal = this.props.show;
    return (
      <div>
        {/* <Button color="danger" onClick={this.toggle}>{this.props.buttonLabel}</Button> */}
        <Modal
          isOpen={this.props.open}
          toggle={this.props.closeModal}
          backdrop
          onClosed={this.props.closeModal}
          className={this.props.className}
        >
          <ModalHeader
            toggle={this.props.closeModal}
          >
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.props.msg}
          </ModalBody>
          {/* <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

ErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.any,
  title: PropTypes.string,
  msg: PropTypes.string.isRequired,
};

export default ErrorModal;
