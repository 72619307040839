import React, { Component } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Input, Label, Button } from 'reactstrap';
import Validation from '../../services/Validation';
import ModalService from '../../services/ModalService';
import CustomerReferenceService from '../../services/CustomerReferenceService';
import { messages } from '../../constants/constant.message';
import swal from 'sweetalert';

export default class CustomerRefenceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        first_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        last_name: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        phone_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      }
    }
  }

  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let isFormValid = false;
    let {
      first_name, last_name, phone_number
    } = controls;


    if ((first_name.touched === true || isSubmit)) {
      first_name = Validation.notNullValidator(first_name);
      first_name.valid = !first_name.nullValue;
      if (first_name.valid === false) {
        first_name.showErrorMsg = true;
      } else {
        first_name.showErrorMsg = false;
      }
    }

    if ((last_name.touched === true || isSubmit)) {
      last_name = Validation.notNullValidator(last_name);
      last_name.valid = !last_name.nullValue;
      if (last_name.valid === false) {
        last_name.showErrorMsg = true;
      } else {
        last_name.showErrorMsg = false;
      }
    }

    if (phone_number.touched === true || isSubmit) {
      phone_number = Validation.notNullValidator(phone_number);
      phone_number = Validation.validatePhoneNumber(phone_number);
      phone_number.valid = !(phone_number.nullValue || phone_number.invalidPhone);
      if (!phone_number.valid === false && phone_number.value && phone_number.value.length === 10) {
        phone_number.showErrorMsg = false;
      } else {
        phone_number.showErrorMsg = true;
      }
    }

    if (first_name.valid === true &&
      last_name.valid === true &&
      phone_number.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls
    })
    return isFormValid;
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let { controls } = this.state;
    if (controlName === 'phone_number') {
      if (!isNaN(e.target.value)) {
        if (e.target.value.length <= 10) {
          controls[controlName].value = e.target.value;
        }
      }
    } else {
      controls[controlName].value = controlValue;
    }
    controls[controlName].touched = true;
    this.setState({
      controls
    });
    this.handleValidation();
  }



  addMoreReff = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    this.submitRefData(true);
  }

  addMoreReff = () => {
    if (!this.handleValidation(true)) {
      return;
    }
    this.submitRefData(true);
  }

  resetAddRef = () => {
    let { controls } = this.state;
    controls.first_name.value = '';
    controls.last_name.value = '';
    controls.phone_number.value = '';
    controls.first_name.touched = false;
    controls.last_name.touched = false;
    controls.phone_number.touched = false;
    this.setState({
      controls
    })
  }

  submitRefData = async (is_add_more = false) => {

    if (!this.props.is_valid_dealer_code) {
      return
    }
    if (!this.handleValidation(true)) {
      return
    }
    let { controls } = this.state;
    let { dealer_detail } = this.props;
    let obj = {
      "customer_id": dealer_detail.customer.customer_id,
      "is_rating_enable": dealer_detail.customerHistory.is_offer_enable,
      "is_offer_enable": dealer_detail.customerHistory.is_offer_enable,
      "phone_number": controls.phone_number.value,
      "country_code": "91",
      "first_name": controls.first_name.value,
      "last_name": controls.last_name.value,
      "code": this.props.code
    }
    this.props.isLoading(true);
    let submitReff = await CustomerReferenceService.addReffCustomer(obj)
      .then((response) => {
        this.props.isLoading(false);
        this.resetAddRef();
        if (!is_add_more) {
          this.props.submitRef();
        } else {
          swal({
            title: "",
            text: response.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
          });
        }
        return true;
      })
      .catch((e) => {
        this.props.isLoading(false);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        return false;
      });
    return submitReff;
  }


  render() {
    let { controls } = this.state;
    let { dealer_detail } = this.props;
    return (
      <Card>
        <CardBody className="padding-32px">
          <Row>
            <Col xl="12" md="12" xs="12">
              <span className="Please-Provide-Reference">
                {
                  dealer_detail.customerHistory &&
                    dealer_detail.customerHistory.is_offer_enable ?
                    'Please Provide Reference to Get Offer' :
                    'Please Provide Reference Details'
                }
              </span>
            </Col>
            <Col xl="12" md="12" xs="12" className="mt-4">
              <FormGroup>
                <Label for="common">First Name<span className="required-field">*</span></Label>
                <Input
                  type="text"
                  autoComplete="nofill"
                  name="first_name"
                  value={controls.first_name.value}
                  id="first_name"
                  onChange={this.handleInputChange}
                  placeholder="Type here"
                />
                {controls.first_name.showErrorMsg && <div className="error show_error">
                  *Please enter first name.
                </div>}
              </FormGroup>

            </Col>
            <Col xl="12" md="12" xs="12">
              <FormGroup>
                <Label for="common">Last Name<span className="required-field">*</span></Label>
                <Input
                  type="text"
                  autoComplete="nofill"
                  name="last_name"
                  id="first_name"
                  value={controls.last_name.value}
                  onChange={this.handleInputChange}
                  placeholder="Type here"
                />
                {controls.last_name.showErrorMsg && <div className="error show_error">
                  *Please enter last name.
                </div>}
              </FormGroup>

            </Col>
            <Col xl="12" md="12" xs="12">
              <FormGroup>
                <Label for="common">Mobile Number<span className="required-field">*</span></Label>
                <Input
                  type="text"
                  autoComplete="nofill"
                  name="phone_number"
                  id="first_name"
                  value={controls.phone_number.value}
                  onChange={this.handleInputChange}
                  placeholder="Type here"
                />
                {controls.phone_number.showErrorMsg && <div className="error show_error">
                  *Please enter mobile number
                </div>}
              </FormGroup>
            </Col>
            <Col xl="12" md="12" xs="12" className="text-align-center mt-2">
              <div
                className={this.props.is_valid_dealer_code ? 'ml-auto mr-auto d-flex justify-content-center color-545CD8' :
                  'ml-auto mr-auto d-flex justify-content-center color-545CD8 opacity-5'}>
                <img src="/images/add_blue.svg"
                  alt=""
                  onClick={() => this.submitRefData(true)} className={this.props.is_valid_dealer_code ? "mt-auto mb-auto cursor-pointer" : "mt-auto mb-auto"} />
                <span
                  onClick={() => this.submitRefData(true)} className={this.props.is_valid_dealer_code ? "Ask-for-Reference-lbl ml-2 mt-auto mb-auto cursor-pointer" : "Ask-for-Reference-lbl ml-2 mt-auto mb-auto"}>Add more Reference</span>
              </div>
              <Button
                className="mt-4 mb-3 btn-pill action-button profile-save-button
                    ml-auto mr-auto"
                style={{
                  width: '90%',
                  height: '38px',
                  fontSize: '18px !important'
                }}
                color="warning"
                disabled={!this.props.is_valid_dealer_code}
                onClick={() => this.submitRefData()}>
                Submit
              </Button>
            </Col>
            {
              !this.props.is_valid_dealer_code &&
              <Col xl="12" md="12" xs="12" className="text-align-center mt-1">
                <div className="error show_error" style={{ fontSize: '13px' }}>
                  *Sorry ! Store is not eligible.
                </div>
              </Col>
            }
          </Row>
        </CardBody>
      </Card>
    )
  }
}
