import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Card,
  CardBody,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DatePicker from "react-datepicker";
import Storage from "../../services/Storage";
import ProposalSerivice from "../../services/ProposalSerivice";
import swal from "sweetalert";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import Moment from "react-moment";
import ModalService from "../../services/ModalService";
import Validation from "../../services/Validation";
import { messages } from "../../constants/constant.message";
import { Roles } from "../../constants/constant.role";
class RecciVisit extends Component {
  constructor(props) {
    super(props);
    let userDetail = Storage.getUserDetail();
    this.state = {
      proposalStageDetail: props.proposalStageDetail,
      isRecciInProgressReadOnly: true,
      proposalStageStatus: props.proposalStageStatus,
      userDetail: Storage.getUserDetail(),
      controls: {
        racci_id: "",
        visit_by: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        visit_date: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        document: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        phoneNumber: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      visitByData: [],
      isDisplayUploadPic: true,
      isLoading: false,
    };
    if (
      userDetail &&
      userDetail.access_level === "architect" &&
      props.status === "recci_in_progress"
    ) {
      this.state.isRecciInProgressReadOnly = false;
    }
  }

  componentDidMount() {
    this.getVisitbyData();
  }

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };

  setRecciData() {
    if (
      this.props.proposalStageDetail &&
      this.props.proposalStageDetail.recci_data &&
      this.props.proposalStageDetail.recci_data.length > 0
    ) {
      let {
        controls,
        isRecciInProgressReadOnly,
        isDisplayUploadPic,
      } = this.state;
      controls.visit_date.value = this.props.proposalStageDetail.recci_data[0]
        .visit_date
        ? new Date(this.props.proposalStageDetail.recci_data[0].visit_date)
        : null;
      if (
        this.props.proposalStageDetail.recci_data[0].person_name &&
        this.state.visitByData &&
        this.state.visitByData.length > 0
      ) {
        let visit_by = _.filter(this.state.visitByData, (element) => {
          return (
            element.name ===
            this.props.proposalStageDetail.recci_data[0].person_name
          );
        });

        if (visit_by && visit_by.length > 0) {
          controls.visit_by.value = visit_by[0].id;
          controls.phoneNumber.value = visit_by[0].phone;
        }
      }
      controls.document.value = this.props.proposalStageDetail.recci_data[0].document;
      controls.racci_id = this.props.proposalStageDetail.recci_data[0].racci_id;

      let indexLayoutStage = this.props.proposalStageDetail.stages_details.findIndex(
        (x) => x.stage && x.stage.toLowerCase() === "layout & agreement"
      );
      if (indexLayoutStage > -1) {
        isRecciInProgressReadOnly = true;
      } else {
        isRecciInProgressReadOnly = false;
      }
      if (
        this.state.userDetail &&
        this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE
      ) {
        isRecciInProgressReadOnly = true;
      }
      let todayDate = new Date();
      isDisplayUploadPic =
        controls.visit_date.value.setHours(0, 0, 0, 0) >
          todayDate.setHours(0, 0, 0, 0)
          ? false
          : true;
      this.setState((prevState) => {
        return {
          ...prevState,
          controls,
          isRecciInProgressReadOnly,
          isDisplayUploadPic,
        };
      });
    }
  }

  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      //this.props.isLoadingSet(false);
      const { controls } = this.state;
      if (data && data.data) {
        controls[type].value = data.data;
        controls[type].showErrorMsg = false;
        controls[type].valid = true;
      }
      // let { isLoading } = this.state;
      // isLoading = false;
      this.setState({ controls, isLoading: false }, () => {
        this.handleValidation();
      });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        const { controls } = this.state.annexures;
        controls[type].value = "";
        controls[type].touched = true;
        event.target.value = null;
        //this.handleValidation();
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB.",
          "error"
        );
        return;
      }
      this.uploadFileFormData(file, type);
    }
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isDisplayUploadPic } = this.state;
    let { visit_by, visit_date, document } = controls;
    let isFormValid = false;
    if (visit_date.touched === true || isSubmit) {
      visit_date = Validation.notNullValidator(visit_date);
      visit_date.valid = !visit_date.nullValue;
      if (visit_date.valid === false) {
        visit_date.showErrorMsg = true;
      } else {
        visit_date.showErrorMsg = false;
      }
    }

    if (visit_by.touched === true || isSubmit) {
      visit_by = Validation.notNullValidator(visit_by);
      visit_by.valid = !visit_by.nullValue;
      if (visit_by.valid === false) {
        visit_by.showErrorMsg = true;
      } else {
        visit_by.showErrorMsg = false;
      }
    }

    document.valid = true;
    document.showErrorMsg = false;
    if ((document.touched === true || isSubmit) && isDisplayUploadPic) {
      document = Validation.notNullValidator(document);
      document.valid = !document.nullValue;
      if (document.valid === false) {
        document.showErrorMsg = true;
      } else {
        document.showErrorMsg = false;
      }
    }

    if (
      visit_by.valid === true &&
      visit_date.valid === true &&
      document.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({
      controls,
    });
    return isFormValid;
  };
  handlePersonVisiting = (e) => {
    const { controls } = this.state;
    const controlName = e.target.name;
    controls[controlName].value = e.target.value;
    controls[controlName].touched = true;
    let phoneNumber = _.filter(this.state.visitByData, (element) => {
      return element.id == e.target.value;
    });
    if (phoneNumber.length > 0) {
      controls["phoneNumber"].value = phoneNumber[0].phone;
    }
    this.setState({ controls }, () => {
      this.handleValidation();
    });
  };

  onChangeDate = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    let { controls, isDisplayUploadPic } = this.state;
    let visit_date = new Date(e);
    let todayDate = new Date();
    isDisplayUploadPic =
      visit_date.setHours(0, 0, 0, 0) > todayDate.setHours(0, 0, 0, 0)
        ? false
        : true;
    controls["visit_date"].value = e;
    controls["visit_date"].touched = true;
    this.setState({ controls, isDisplayUploadPic }, () => {
      this.handleValidation();
    });
  };

  getVisitbyData() {
    if (
      !this.state.proposalStageStatus ||
      !this.state.proposalStageStatus.uuid
    ) {
      return;
    }
    ProposalSerivice.getVisitByData(this.state.proposalStageStatus.uuid)
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          this.setState(
            (prevState) => {
              return { ...prevState, visitByData: res.data.data };
            },
            () => {
              this.setRecciData();
            }
          );
        } else {
          this.setState((prevState) => {
            return { ...prevState, visitByData: [] };
          });
        }
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  submitRecci = (e) => {
    e.preventDefault();
    if (!this.checkActiveStatus()) {
      return false;
    }
    let { controls, isDisplayUploadPic } = this.state;
    if (!this.handleValidation(true)) {
      return;
    }
    const reqData = {};
    if (controls.visit_by.value) {
      reqData.visit_by = controls.visit_by.value;
      // reqData.visit_by = 1;
    }
    if (controls.visit_date.value) {
      reqData.visit_date = controls.visit_date.value.toISOString();
    }
    if (controls.document.value && isDisplayUploadPic) {
      reqData.document = controls.document.value;
    }

    if (controls.racci_id) {
      reqData.racci_id = controls.racci_id;
    }
    reqData.uuid = this.state.proposalStageStatus.uuid;

    this.setState({ isLoading: true });
    ProposalSerivice.submitRecciVisit(reqData)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message = "Recci visits details added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message = "Recci visits details updated successfully";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.initApi();
          //          this.props.goToPrevPage();
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  render() {
    const { controls, isRecciInProgressReadOnly, userDetail } = this.state;
    const { proposalStageDetail } = this.props;
    return (
      <>
        <br />
        {this.state.isLoading && <CustomSpinner />}
        {/* {proposalStageDetail && proposalStageDetail.recci_data && proposalStageDetail.recci_data.length <= 0 && */}
        <Card>
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Recci Visit</b>
              </Col>
            </Row>
            <hr />
            {isRecciInProgressReadOnly &&
              proposalStageDetail.recci_data &&
              proposalStageDetail.recci_data.length > 0 ? (
              <Row>
                <Col xl="2" md="12" xs="12">
                  <FormGroup className="d-flex-column">
                    <Label className="font-weight-bold">Date of Visit</Label>
                    <span className="text-capitalize">
                      <Moment format="DD MMM YYYY">
                        {proposalStageDetail.recci_data[0].visit_date}
                      </Moment>
                    </span>
                  </FormGroup>
                </Col>
                <Col xl="2" md="12" xs="12">
                  <FormGroup className="d-flex-column">
                    <Label className="font-weight-bold">Person Visiting</Label>
                    <span className="text-capitalize">
                      {proposalStageDetail.recci_data[0].person_name}
                    </span>
                  </FormGroup>
                </Col>
                <Col xl="2" md="12" xs="12">
                  <FormGroup className="d-flex-column">
                    <Label className="font-weight-bold">Phone No</Label>
                    <span className="text-capitalize">
                      {proposalStageDetail.recci_data[0].person_phone}
                    </span>
                  </FormGroup>
                </Col>
                {proposalStageDetail.recci_data[0].document && (
                  <Col xl="3" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label>Site picture</Label>
                      <a
                        className="aa d-flex-column"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={proposalStageDetail.recci_data[0].document}
                        style={{ marginRight: "auto" }}
                      >
                        <img
                          src="images/sidebar-icons/secondary-report-selected.svg"
                          alt=''
                          height="40px"
                        />
                        <span className="redirect-link">View</span>
                      </a>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            ) : (
              <form autocomplete="off">
                <Row>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label>
                        Date of Visit<span className="required-field">*</span>
                      </Label>
                      <Label
                        onClick={(e) =>
                          this.calendar1.state.open && e.preventDefault()
                        }
                      >
                        <InputGroup>
                          <DatePicker
                            className={"form-control"}
                            onChange={(e) => this.onChangeDate(e)}
                            name="installation_date"
                            selected={controls.visit_date.value}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            ref={(r) => (this.calendar1 = r)}
                            shouldCloseOnSelect={true}
                            showYearDropdown
                            dropdownMode="select"
                            disabled={userDetail.access_level === "brand_store"}
                          />
                          <InputGroupAddon addonType="append">
                            <div className="input-group-text">
                              <FontAwesomeIcon
                                htmlFor="datepicker"
                                icon={faCalendarAlt}
                              />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                      {controls.visit_date.showErrorMsg && (
                        <div className="error">
                          * Please select date of visit
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>
                        Person Visiting<span className="required-field">*</span>
                      </Label>
                      <select
                        className="form-control select-height-70rem"
                        name="visit_by"
                        value={controls.visit_by.value}
                        onChange={(e) => this.handlePersonVisiting(e)}
                        disabled={userDetail.access_level === "brand_store"}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {this.state.visitByData &&
                          this.state.visitByData.map((item, i) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                      </select>
                      {controls.visit_by.showErrorMsg && (
                        <div className="error">
                          * Please select person visiting
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="3" md="12" xs="12">
                    <FormGroup>
                      <Label>Phone No</Label>
                      <Input
                        type="text"
                        name="phoneNumber"
                        value={controls.phoneNumber.value}
                        onChange={(e) => this.handleInputChange(e)}
                        disabled={userDetail.access_level === "brand_store"}
                      />
                    </FormGroup>
                  </Col>
                  {this.state.isDisplayUploadPic &&
                    userDetail.access_level !== "brand_store" && (
                      <Col xl="3" md="12" xs="12">
                        <FormGroup>
                          <Label>
                            Upload Site Picture (documents as jpeg, jpg, png and
                            pdf file upto 5MB)
                            <span className="required-field">*</span>
                          </Label>
                          <input
                            accept="application/pdf,image/*"
                            type="file"
                            name="document"
                            onChange={(e) => this.uploadFile(e, "document")}
                          />
                          {controls.document.showErrorMsg && (
                            <div className="error">
                              * Please upload site picture.
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                  {controls.document &&
                    controls.document.value &&
                    controls.document.value.toLowerCase().indexOf("http") !==
                    -1 && (
                      <Col xl="3" md="12" xs="12">
                        <FormGroup className="d-flex-column">
                          <Label>Site picture</Label>
                          <a
                            className="aa d-flex-column"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={controls.document.value}
                            style={{ marginRight: "auto" }}
                          >
                            <img
                              src="images/sidebar-icons/secondary-report-selected.svg"
                              alt=''
                              height="40px"
                            />
                            <span className="redirect-link">View</span>
                          </a>
                        </FormGroup>
                      </Col>
                    )}
                </Row>
                {userDetail.access_level !== "brand_store" && (
                  <Row>
                    <Col
                      xl="3"
                      md="12"
                      xs="12"
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                        color="warning"
                        type="submit"
                        style={{ float: "right" }}
                        onClick={(e) => this.submitRecci(e)}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                )}
              </form>
            )}
          </CardBody>
        </Card>
        {/* } */}
        {/* {
          proposalStageDetail && proposalStageDetail.recci_data && proposalStageDetail.recci_data.length > 0 &&
          < Card >
            <CardBody>
              <Row>
                <Col xl="12" md="12" xs="12">
                  <b>Recci Visit</b>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup className="d-flex-column">
                    <Label>Date Of Visit</Label>
                    <span className="date-visit-input-lbl"><Moment format="DD-MM-YYYY">
                      {proposalStageDetail.recci_data[0].visit_date}
                    </Moment></span>
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>Person Visiting</Label>
                    <Input type="text" disabled="true" value={proposalStageDetail.recci_data[0].person_name} />
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup>
                    <Label>Phone No</Label>
                    <Input type="text" name="phoneNumber"
                      disabled="true"
                      value={proposalStageDetail.recci_data[0].person_phone} />
                  </FormGroup>
                </Col>
                <Col xl="3" md="12" xs="12">
                  <FormGroup className="d-flex-column">
                    <Label>Site picture</Label>
                    <a className='aa' target='_blank' rel="noopener noreferrer" href={proposalStageDetail.recci_data[0].document} className="d-flex-column" style={{ marginRight: 'auto' }}>
                      <img src='images/sidebar-icons/secondary-report-selected.svg' alt='' height='40px' />
                      <span className="redirect-link">Click here</span>
                    </a>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        } */}
      </>
    );
  }
}

export default RecciVisit;
