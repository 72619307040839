import React, { Component } from "react";
import ProposalSerivice from "../../services/ProposalSerivice";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Card,
  CardBody,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import TextareaAutosize from "react-textarea-autosize";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
class CompletionStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      controls: {
        remark: "",
        visit_date: null,
        document: "",
        stage: "Project completed",
        completed: true,
      },
      isLoading: false,
      userDetail: props.userDetail,
    };
  }
  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { controls } = this.state;
      if (data && data.data) {
        controls.document = data.data;
      }
      this.setState({ controls, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 5000000) {
        const { controls } = this.state;
        controls.document = "";
        event.target.value = null;
        return;
      }
      this.uploadFileFormData(file, type);
    }
  };

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };

  submit = () => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    let { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    if (!controls.visit_date) {
      return;
    }
    controls.stage =
      this.props.proposalStageDetail.dealership_completion_data &&
        this.props.proposalStageDetail.dealership_completion_data.length > 0
        ? "Dealer"
        : "Agency";
    let obj = {
      visits: [],
    };
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });
    if (controls.visit_date) {
      obj.visits.push({
        type: "Project completed",
        remark: controls.remark,
        visit_date: controls.visit_date,
      });
      // controls.interim.visit_date = new Date(controls.visit_date).setHours(5, 30);
      // controls.interim.visit_date = new Date(controls.visit_date).toISOString();
    } else {
      return true;
    }
    ProposalSerivice.saveInterimVisits(obj)
      .then((res) => {
        controls = {
          remark: "",
          visit_date: null,
          document: "",
          stage: "Dealer",
          completed: false,
        };
        if (this.fileInput) {
          this.fileInput.value = "";
        }
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message = "Completion stage added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message = "Completion in progress updated successfully";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.initApi();
        });
        this.setState({ isLoading: false, controls });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  onChangeDate = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const { controls } = this.state;
    controls["visit_date"] = e;
    this.setState({ controls });
  };

  handleInputCheckboxChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName] = !controls[controlName];
    this.setState({ controls });
  };
  handleInputChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName] = e.target.value;
    this.setState({ controls });
  };

  render() {
    const { controls, userDetail } = this.state;
    const { proposalStageDetail } = this.props;
    let minDateInaugration = null,
      inugrated = [];
    inugrated = proposalStageDetail.stages_details.filter(
      (x) => x.stage && x.stage === "Inaugurated"
    );
    if (inugrated.length > 0) {
      let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
      minDateInaugration = new Date(
        inugrated[0].completed_date.replace(pattern, "$3-$2-$1")
      );
    }
    return (
      minDateInaugration && (
        <>
          <br />
          {this.state.isLoading && <CustomSpinner />}
          <Card>
            <CardBody>
              <Row>
                <Col xl="12" md="12" xs="12">
                  <b>Completion Stage</b>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl="3" md="12" xs="12">
                  <FormGroup className="d-flex-column">
                    <Label>Date Of Completed</Label>
                    <Label
                      onClick={(e) =>
                        this.calendar1.state.open && e.preventDefault()
                      }
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={(e) => this.onChangeDate(e)}
                          name="visit_date"
                          selected={controls.visit_date}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={(r) => (this.calendar1 = r)}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          maxDate={new Date()}
                          minDate={
                            minDateInaugration ? minDateInaugration : null
                          }
                          dropdownMode="select"
                          disabled={userDetail.access_level === "brand_store"}
                        />
                        <InputGroupAddon addonType="append">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              htmlFor="datepicker"
                              icon={faCalendarAlt}
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl="4" md="12" xs="12">
                  <FormGroup>
                    <Label>Remark</Label>
                    <TextareaAutosize
                      name="remark"
                      autocomplete="nofill"
                      id="remark"
                      value={controls.remark}
                      style={{ minHeight: 60, maxHeight: 70, fontSize: "14px" }}
                      className="form-control"
                      onChange={(e) =>
                        this.handleInputChange({
                          target: { name: "remark", value: e.target.value },
                        })
                      }
                      placeholder=""
                      disabled={userDetail.access_level === "brand_store"}
                    />
                  </FormGroup>
                </Col>
                {/* <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Upload Completion Document</Label>
                  <input type="file" ref={ref => this.fileInput = ref} name="document" onChange={(e) => this.uploadFile(e, 'document')} />
                </FormGroup>
              </Col> */}
              </Row>
              {userDetail.access_level !== "brand_store" && (
                <Row>
                  <Col
                    xl="3"
                    md="12"
                    xs="12"
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: "right" }}
                      onClick={(e) => this.submit()}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </>
      )
    );
  }
}

export default CompletionStage;
