import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Form
} from "reactstrap";

import Validation from "../../services/Validation";
import SubDealerService from "../../services/SubDealerService";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";

import "./InviteSubDealerOrBranch.css";
import Storage from "../../services/Storage";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import swal from "sweetalert";
import { Permissions } from "../../constants/constant.permissions";

let {
  EMPTY_MOBILE_NUMBER,
  EMPTY_NICKNAME,
  INVALID_PHONE
} = messages.SUB_DEALER;

let { ERROR_MESSAGE } = messages.COMMON;

class InviteSubDealerOrBranch extends React.Component {
  state = {
    controls: {
      name: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null
      },
      mobileNumber: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null
      },
      dealerType: "sub_dealer",
      modalMessage: "",
    },
    invitee_permission: {
      visit_history: false,
      warranty: true,
      touched: true,
      showErrorMsg: false
    },
    isFormValid: false,
    is_edit: false,
    isLoading: false
  };

  constructor(props) {
    super(props);
    this.state.userDetail = Storage.getUserDetail();
    this.state.isDisplayInviteeType = false;
    if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)) {
      this.state.isDisplayInviteeType = true;
    }
  }

  componentDidMount() {
    let { controls, invitee_permission } = this.state;
    let { mobileNumber, name, dealerType } = controls;
    if (this.props.invite_data && Object.keys(this.props.invite_data).length > 0) {
      let { invite_data } = this.props;
      let phone_number = invite_data.phone_number;
      if (phone_number.indexOf('+91') > -1) {
        phone_number = phone_number.replace("+91", '');
      }
      mobileNumber.value = phone_number;
      name.value = invite_data.name;
      dealerType = invite_data.type;
      if (invite_data.permission && Array.isArray(invite_data.permission)) {
        invite_data.permission.forEach(element => {
          if (element === 'warranty') {
            invitee_permission.warranty = true;
          } else if (element === 'visit_history') {
            invitee_permission.visit_history = true;
          }
        });
      }
      this.setState({
        controls,
        is_edit: true,
        dealerType,
        invitee_permission
      })
    }
  }

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const re = /^(\s*\d+)+\s*$/;
    if (controlName === "mobileNumber") {
      if (e.target.value === "" || e.target.value.match(re)) {
        const { controls } = this.state;
        controls[controlName].value = controlValue;
        controls[controlName].touched = true;
        this.setState({ controls });
      } else {
        return;
      }
    } else {
      const { controls } = this.state;
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
      this.setState({ controls });
    }

    this.handleValidation();
  };

  handleValidation = (is_submit = false) => {
    let { controls, isFormValid, invitee_permission } = this.state;
    let { mobileNumber, name } = controls;

    if (name.touched || is_submit) {
      name = Validation.notNullValidator(name);
      name.valid = !name.nullValue;
    }

    if (mobileNumber.touched || is_submit) {
      mobileNumber = Validation.notNullValidator(mobileNumber);
      mobileNumber = Validation.validatePhoneNumber(mobileNumber);
      mobileNumber.valid = !(mobileNumber.nullValue || mobileNumber.invalidPhone);
    }
    invitee_permission.showErrorMsg = false;
    if ((invitee_permission.touched || is_submit) && this.state.isDisplayInviteeType) {
      if (!invitee_permission.warranty && !invitee_permission.visit_history) {
        invitee_permission.showErrorMsg = true;
      } else {
        invitee_permission.showErrorMsg = false;
      }
    }

    if (mobileNumber.valid === true && name.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    if (mobileNumber.valid === true && name.valid === true &&
      invitee_permission.showErrorMsg === false) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    this.setState({ controls, isFormValid });

    return isFormValid;
  };

  handleDealerTypeChange = e => {
    if (this.state.is_edit) {
      return
    }
    const { controls } = this.state;
    let { dealerType } = controls;
    dealerType = e.target.value;
    controls.dealerType = dealerType;
    this.setState({ controls });
  };

  inviteDealerOrBranch = e => {
    e.preventDefault();
    const { controls, invitee_permission } = this.state;
    const { name, mobileNumber, dealerType } = controls;

    const isFormValid = this.handleValidation(true);

    if (!isFormValid) {
      return;
    }

    const obj = {
      phone_number: mobileNumber.value,
      country_code: "+91",
      name: name.value,
      type: dealerType,
      invitee_permission: []
    };

    if (invitee_permission.warranty) {
      obj.invitee_permission.push('warranty');
    }
    if (invitee_permission.visit_history) {
      obj.invitee_permission.push('visit_history');
    }
    if (this.state.is_edit) {
      obj.invite_id = this.props.invite_data.invite_id;
      this.editInvitee(obj);
      return;
    }
    this.setState({
      isLoading: true
    })
    SubDealerService.inviteSubDealerOrBranch(obj)
      .then(data => {
        this.setState({
          isLoading: false
        })
        swal({
          title: "",
          text: data.data.message,
          icon: "success",
          type: "success",
        }).then((isConfirm) => {
          this.props.closeModal(true);
        });
      })
      .catch(e => {
        let err =
          e.response && e.response.data && e.response.data.error && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;

        ModalService.openAlert("Invite", message, "error");
        this.setState({
          isLoading: false
        })
      });
  };

  editInvitee = (obj) => {
    this.setState({
      isLoading: true
    })
    let reqData = {
      invite_id: obj.invite_id,
      invitee_permission: obj.invitee_permission
    }
    SubDealerService.editInvitee(reqData)
      .then(data => {
        this.setState({
          isLoading: false
        });
        swal({
          title: "",
          text: data.data.message,
          icon: "success",
          type: "success",
        }).then((isConfirm) => {
          this.props.closeModal(true);
        });
      })
      .catch(e => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;

        ModalService.openAlert("Invite", message, "error");
        this.setState({
          isLoading: false
        })
      });
  }



  closeModal = () => {
    this.props.closeModal();
  };

  addInviteePermission = (e, type) => {
    let { invitee_permission } = this.state;
    invitee_permission[type] = !invitee_permission[type];
    invitee_permission.touched = true;
    this.setState({
      invitee_permission
    });
    this.handleValidation();
  }

  render() {
    const { controls, invitee_permission, is_edit } = this.state;
    const { name, mobileNumber, dealerType } = controls;

    return (
      <div>
        <Modal
          id="invite-dealer-modal"
          isOpen={this.props.show}
          toggle={this.props.closeModal}
          centered
        >
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            {this.state.isLoading && <CustomSpinner />}
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col className="modal-title">SEND INVITATION VIA SMS</Col>
              </Row>
              {/* <div>
                {changePasswordErrorMessage && <div className="error"> {changePasswordErrorMessage}</div>}
              </div> */}

              <Row className="margin-top-10">
                <Col className={is_edit ? 'opacity-0-5' : ''}>
                  <label className="container">
                    {/* Sub-dealer / Staff */}
                    Sub Dealer
                    <input
                      type="radio"
                      name="dealerType"
                      value="sub_dealer"
                      onChange={this.handleDealerTypeChange}
                      checked={dealerType === "sub_dealer"}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container">
                    {/* Branch */}
                    Staff or Branch
                    <input
                      type="radio"
                      name="dealerType"
                      value="branch"
                      onChange={this.handleDealerTypeChange}
                      checked={dealerType === "branch"}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col sm="6" className={is_edit ? 'opacity-0-5' : ''}>
                  <FormGroup className="margin-top-10">
                    <Label for="confirmPassword">
                      Invite Staff / Branch / Sub Dealer {" "}
                      <span className="required-field">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder={
                        dealerType === "branch"
                          ? "Enter staff / branch name"
                          : "Enter sub dealer name"
                      }
                      value={name.value}
                      disabled={is_edit}
                      onChange={this.handleInputChange}
                    />
                    {name.nullValue && (
                      <div className="error">* {EMPTY_NICKNAME}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="6" className={is_edit ? 'opacity-0-5' : ''}>
                  <FormGroup className="margin-top-10">
                    <Label for="confirmPassword">
                      Mobile Number <span className="required-field">*</span>
                    </Label>
                    <div>
                      <span className="nineone">+91</span>
                      <Input
                        type="text"
                        maxLength="10"
                        name="mobileNumber"
                        id="mobileNumber"
                        placeholder="Enter 10 digit mobile no"
                        value={mobileNumber.value}
                        disabled={is_edit}
                        style={{ width: "80%", display: "inline" }}
                        onChange={this.handleInputChange}
                      />
                      {mobileNumber.nullValue && (
                        <div className="error" style={{ marginLeft: '40px' }}>* {EMPTY_MOBILE_NUMBER}</div>
                      )}
                      {mobileNumber.invalidPhone && (
                        <div className="error" style={{ marginLeft: '40px' }}>* {INVALID_PHONE}</div>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                {/* <Col xl="12" >
                  <label className="custom-checkboax1--container">
                    <input
                      type="checkbox"
                      name="service"
                      checked={invitee_permission.warranty}
                      onChange={(e) =>
                        this.addInviteePermission(e, 'warranty')
                      }
                    />
                    <span className="checkmark1" />
                  </label>
                  <span style={{ marginLeft: "10px" }}
                    className="text-align-left">
                    Invite for JK Warranty (PCR, 2/3 Wheelers)
                  </span>
                </Col> */}
                {
                  this.state.isDisplayInviteeType && <Col xl="12" className="mt-2">
                    <label className="custom-checkboax1--container">
                      <input
                        type="checkbox"
                        name="service"
                        checked={invitee_permission.visit_history}
                        onChange={(e) =>
                          this.addInviteePermission(e, 'visit_history')
                        }
                      />
                      <span className="checkmark1" />
                    </label>
                    <span style={{ marginLeft: "10px" }}
                      className="text-align-left">
                      Invite to add customers for store visit (Inquiry/Service/Purchase)
                    </span>
                    {invitee_permission.showErrorMsg && <div className="error">* Please select invitee type.</div>}
                  </Col>
                }
              </Row>
              <br />
              <div style={{ textAlign: "right" }}>
                <Button
                  className="mb-1 mr-1 btn-pill Rectangle-button cancel-button"
                  onClick={this.props.closeModal}
                  color="dark"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="mb-1 mr-1 btn-pill Rectangle-button send-button"
                  onClick={this.inviteDealerOrBranch}
                  color="warning"
                >

                  {is_edit ? 'Save' : 'Invite'}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default InviteSubDealerOrBranch;
