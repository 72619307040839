import React, { Component } from "react";

import {
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import "./ViewRemark.css";

class ViewRemark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthOptions: [],
      yearOptions: [],
      yeartoDownload: null,
      isLoader: false,
      remarkData: this.props.remarkData
    };
  }

  componentDidMount() { }

  render() {
    return (
      <>
        <Modal
          id="ViewRemark"
          isOpen={this.props.viewRemark}
          // toggle={this.props.openModel}
          className="modelCard"
          centered
        >
          <ModalBody>
            <div>
              <Col xl-12>
                <Row
                  style={{
                    marginTop: "5px",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                  className="justify-content-between"
                >
                  <Col className="label" xl="8" md="8" sm="8">
                    <div style={{ fontSize: "15px", marginLeft: "-15px" }}>
                      <img src="/images/sidebar-icons/remark_icon.svg" alt="" />
                      &nbsp;&nbsp;&nbsp; <b>View Remark</b>
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                      paddingRight: '0 !important',
                      textAlign: 'right'
                    }}
                  >
                    <img
                      src="./images/close1.svg"
                      alt=""
                      onClick={this.props.closeModal}
                    />
                  </Col>
                </Row>
                <div className="border" style={{ marginBottom: '5px' }} />
                <Row className="otherlangcontent-wrapps">
                  {
                    this.props.remarkData.services &&
                    this.props.remarkData.services.length > 0 &&
                    <Col lg="9" sm="9" md="9" xs="9">
                      <div className="otherlangcontent" style={{ width: "100%" }}>
                        <ul className="Enrollment-ul text-none">
                          {
                            this.props.remarkData.services &&
                            this.props.remarkData.services.map((item, i) => (
                              // item.remarks &&
                              <li className="enroll-li campaign-popup-notes">
                                <div className="d-flex-column">
                                  <b style={{ fontSize: '14px' }}>{item.service_name}</b>
                                  <span className="trade-history-reamrk-text mt-2">
                                    {item.remarks || '--'}
                                  </span>
                                </div>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </Col>
                  }
                </Row>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default ViewRemark;
