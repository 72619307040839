import React, { Component, Fragment } from 'react';
import { Row, FormGroup, Label, Input, Form, Col, Spinner, Button, Card, CardBody } from 'reactstrap';
import Select from 'react-select';

// services
import NotificationService from '../../services/NotificationService';
import Validation from '../../services/Validation';

// constant
import { messages } from '../../constants/constant.message';

import ModalService from '../../services/ModalService';

// css
import './SetNotification.css';

const { EMPTY_IMAGE, EMPTY_MESSAGE, EMPTY_TITLE, SELECT_ACTION, SELECT_ROLE } = messages.NOTIFICATION;
const { ERROR_MESSAGE } = messages.COMMON;

class SetNotificaton extends Component {
  state = {
    controls: {
      title: {
        value: '',
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false
      },
      message: {
        value: '',
        valid: null,
        touched: false,
        nullValue: null,
        required: true,
        showErrorMsg: false
      },
      profileImage: {
        value: null,
        valid: null,
        touched: false,
        nullValue: null,
        required: true,
        showErrorMsg: false
      },
      type: 'text',
      // profileImage: null,
      isImageUpdating: false,
      isFormValid: false,

    },
    actions: [],
    selectedRole: null,
    isRoleValid: true,
    selectedAction: null,
    isActionValid: true,
    imageUrl: null,
    selectedOption: null,
    rolesOption: [],
    allRoles: []
  }

  componentDidMount() {
    this.getRoles();
    // this.getNotificationActions();
  }

  getRoles = () => {
    let isRoleValid = false;
    NotificationService.getRoles()
      .then(data => {
        let { data: roles } = data.data;
        let rolesOption = [{
          value: 'all',
          label: 'All'
        }];

        let allRoles = [];
        isRoleValid = true;

        let selectedRole = null;
        if (roles && roles.length > 0) {
          for (let i = 0; i < roles.length; i++) {
            let obj = {
              value: roles[i].id,
              label: roles[i].name
            }
            rolesOption.push(obj);
            allRoles.push(obj);
          }
          selectedRole = rolesOption[0];
          let reqBody = {
            roles: selectedRole.value
          }
          this.getNotificationActions(reqBody)
        } else {
          isRoleValid = false;
        }
        this.setState({ selectedRole, rolesOption, isRoleValid, allRoles: allRoles });
      })
      .catch(e => {

      })
  }

  getNotificationActions = (reqBody) => {
    let isActionValid = true;
    NotificationService.getNotificationActions(reqBody)
      .then(data => {
        const { data: actions } = data.data;
        let actionOption = [];
        let selectedAction = null;

        if (actions && actions.length > 0) {
          for (let i = 0; i < actions.length; i++) {
            let obj = {
              value: actions[i].action,
              label: actions[i].title
            }
            actionOption.push(obj);
          }
          selectedAction = actionOption[0];
          isActionValid = true;
        } else {
          isActionValid = false;
        }
        this.setState({ selectedAction, actionOption, isActionValid });
      })
      .catch(e => {

      })
  }

  setCheckBoxValue = (value) => {
    const { controls } = this.state;
    controls.type = value;
    this.setState({ controls });
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    // this.handleValidation();
  }

  handleUploadImage = (event) => {
    const { controls } = this.state;
    const { profileImage } = controls;
    const file = event.target.files[0];
    this.setState({ isImageUpdating: true });
    const obj = {
      file
    };

    NotificationService.uploadNotificationImage(obj)
      .then(data => {
        const { imageUrl } = data.data.data;
        profileImage.value = URL.createObjectURL(file);
        this.setState({
          controls,
          isImageUpdating: false,
          imageUrl
        });
      })
      .catch(e => {
        this.setState({
          controls,
          isImageUpdating: false
        });
      });
  }

  handleValidation = () => {
    let { controls, isFormValid, selectedAction, selectedRole, isActionValid, isRoleValid } = this.state;
    let { type, title, message, profileImage } = controls;

    title = Validation.notNullValidator(title);
    title.valid = !(title.nullValue);

    message = Validation.notNullValidator(message);
    message.valid = !(message.nullValue);

    if (type === 'textpicture') {
      if (profileImage.value === null) {
        profileImage.valid = false;
        profileImage.nullValue = true;
      } else {
        profileImage.valid = true;
        profileImage.nullValue = false;
      }
    }

    if (Array.isArray(selectedRole) && selectedRole.length === 0) {
      selectedRole = null;
    }

    if ((selectedRole || (Array.isArray(selectedRole) && selectedRole.length > 0))) {

      isRoleValid = true;
    } else {
      isRoleValid = false;
    }

    if (selectedAction) {
      isActionValid = true;
    } else {
      isActionValid = false;
    }

    if (type === 'textpicture') {
      if (title.valid === true && message.valid === true && profileImage.valid && isRoleValid && isActionValid) {
        isFormValid = true;
      } else {
        isFormValid = false;
      }
    } else {
      if (title.valid === true && message.valid === true && isRoleValid && isActionValid) {
        isFormValid = true;
      } else {
        isFormValid = false;
      }
    }

    this.setState({ controls, isFormValid, isActionValid, isRoleValid });

    return isFormValid;
  }

  handleRoleChange = selectedRole => {
    this.setState({ selectedRole, selectedAction: [] });
    let roles = [];
    if (Array.isArray(selectedRole)) {
      for (let i = 0; i < selectedRole.length; i++) {
        roles.push(selectedRole[i].value);
      }
    } else if (selectedRole) {
      roles.push(selectedRole.value);
    }
    let reqBody = {
      roles
    }
    this.getNotificationActions(reqBody)

  };

  setNotificaton = (e) => {
    e.preventDefault();
    const { controls, selectedAction, selectedRole, imageUrl, allRoles } = this.state;
    const { title, type, message } = controls;
    let roles = [];
    let action = null;
    const isFormValid = this.handleValidation();


    if (isFormValid === false) {
      return;
    }

    const setAllRoles = () => {
      roles = [];
      for (let i = 0; i < allRoles.length; i++) {
        roles.push(allRoles[i].value);
      }
    }

    if (Array.isArray(selectedRole)) {
      for (let i = 0; i < selectedRole.length; i++) {
        roles.push(selectedRole[i].value);
        if (selectedRole[i].value === 'all') {
          setAllRoles();
          break;
        }
      }
    } else if (selectedRole) {
      if (selectedRole.value === 'all') {
        setAllRoles();
      } else {
        roles.push(selectedRole.value);
      }
    }



    if (selectedAction) {
      action = selectedAction.value;
    }


    const obj = {
      "role_id": roles,
      "user_ids": [],
      "title": title.value,
      "message": message.value,
      "type": type,
      "action": action,
      "image_url": type === 'textpicture' ? imageUrl : null
    }
    // this.setState({ errResponseMessage: "", isLoading: true });

    NotificationService.sendNotification(obj)
      .then(data => {
        ModalService.openAlert('Notification', 'Notification send successfully', 'success');
      })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert('Notification', message, 'error');
      });
  }

  handleActionChange = (selectedAction) => {
    this.setState({ selectedAction })
  }

  render() {
    const { controls, selectedRole, selectedAction, actionOption, rolesOption, isRoleValid, isActionValid } = this.state;
    const { type, message, title, profileImage, isImageUpdating } = controls;
    return (
      <div id="ntf">
        <div className="page-title">Communication</div>
        <br />

        <Row>
          <Col sm="6">
            <Card>
              <CardBody>
                <Form>
                  <Fragment>
                    <Row>
                      <Col>
                        <label className="container-cb">
                          <input
                            type="checkbox"
                            name="checkbox"
                            id="checkbox"
                            checked={type === 'text'}
                            onChange={this.setCheckBoxValue.bind(this, 'text')} />{' '}
                          Text
                          {/* <span className="checkmark"></span> */}
                        </label>{' '}

                        <label className="container-cb margin-left-10">
                          <input
                            type="checkbox"
                            name="checkbox"
                            id="checkbox"
                            checked={type === 'textpicture'}
                            onChange={this.setCheckBoxValue.bind(this, 'textpicture')} />{' '}
                          Picture
                          {/* <span className="checkmark"></span> */}
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label for="username">Select Role</Label><br />
                        <Select
                          classNamePrefix="border-none"
                          autoFocus={false}
                          value={selectedRole}
                          onChange={this.handleRoleChange}
                          options={rolesOption}
                          isMulti
                        />
                        {!isRoleValid && <div className="error">*{SELECT_ROLE}</div>}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="username">Select Action</Label><br />
                        <Select
                          value={selectedAction}
                          onChange={this.handleActionChange}
                          options={actionOption}
                        />
                        {!isActionValid && <div className="error">*{SELECT_ACTION}</div>}
                      </Col>
                    </Row>
                    <br />
                    {type === 'textpicture' && <Row>
                      <Col>

                        {isImageUpdating && <Spinner size="sm" color="primary" className="profile-spinner" />}
                        <div>
                          <input type="file" className="width-100" onChange={this.handleUploadImage} />
                        </div>
                        <div className="error-container">
                          {profileImage.nullValue && <div className="error">* {EMPTY_IMAGE}</div>}
                        </div>
                        <br />
                        {profileImage.value && <img src={profileImage.value} height="100px" width="auto" className="profile-image" alt="" />}
                      </Col>
                    </Row>}
                    <br />
                    <Row className="padding-left-13">
                      <FormGroup className="width-100">
                        <Label for="username">Title</Label>
                        <Input
                          type="text"
                          name="title"
                          id="title"
                          className=""
                          value={title.value}
                          onChange={this.handleInputChange} />
                        <div className="error-container">
                          {title.nullValue && <div className="error">* {EMPTY_TITLE}</div>}
                        </div>
                      </FormGroup>
                    </Row>
                    <Row className="padding-left-13">
                      <FormGroup className="width-100">
                        <Label for="username">Message</Label>
                        <Input
                          type="textarea"
                          name="message"
                          id="message"
                          className=""
                          value={message.value}
                          onChange={this.handleInputChange} />
                        <div className="error-container">
                          {message.nullValue && <div className="error">* {EMPTY_MESSAGE}</div>}
                        </div>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          className="mb-1 mr-1 btn-pill action-button login-button"
                          color="warning"
                          onClick={this.setNotificaton}
                          type="submit"
                          style={{ width: '139px' }}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Fragment>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div >
    );
  }
}

export default SetNotificaton;