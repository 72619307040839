import React, { Component } from 'react';
import CustomerManagementService from '../../services/CustomerManagementService';
import ModalService from '../../services/ModalService';
import { Row, Col, Button } from 'reactstrap';
import { messages } from '../../constants/constant.message';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import CampaignService from '../../services/CampaignService';
const { ERROR_MESSAGE } = messages.COMMON;
const pageSize = 10;
class PromotionalEventsUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupData: [],
      isLoading: false,
      groupCount: 0,
      groupPage: 1,
      groupTotalPages: 1,
      selected: [],
      selectAll: 0,
      count: 0,
      page: 1,
      pageSize: 10,
      totalPages: 0,
      isSelectAllEntry: false,
      counts: 0
    }
    if (props.jkPromotionalEventsUsersData && Object.keys(props.jkPromotionalEventsUsersData).length > 0) {
      if (props.jkPromotionalEventsUsersData.filter_promotion_and_services_group_ids) {
        this.state.selected = props.jkPromotionalEventsUsersData.filter_promotion_and_services_group_ids;
        this.state.selectAll = this.checkSelectAll();
      }
      if (props.jkPromotionalEventsUsersData.counts) {
        this.state.counts = props.jkPromotionalEventsUsersData.counts;
      }
    }
  }

  componentDidMount() {
    this.getGroups();
  }


  getGroups = (page = 1, search = null) => {
    this.setState({ isLoading: true });
    CustomerManagementService.getGroups({ page, pageSize, search })
      .then(res => {
        let { groupData, groupCount, groupTotalPages } = this.state;
        this.setState({ isLoading: false });

        if (res.data.data && res.data.data.groups && res.data.data.groups.length > 0) {
          groupData = res.data.data.groups;
          groupCount = res.data.data.count;
          groupTotalPages = Math.ceil(groupCount / pageSize);
        } else {
          groupData = [];
        }
        this.setState({ groupData, groupCount, groupTotalPages });
      })
      .catch(e => {
        this.setState({ isLoading: false });

        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error')
        //this.setState({ isLoading: false });
      });
  }

  toggleRow = (id) => {
    let { selected, groupData } = this.state;
    let index = selected.findIndex(x => x === id);
    let indexData = groupData.findIndex(x => x.group_id === id);
    if (index > -1) {
      selected.splice(index, 1);
      groupData[indexData].active = false;
    } else {
      if (selected.indexOf(id) === -1) {
        selected.push(id);
      }
      groupData[indexData].active = true;
    }
    let selectAll = this.checkSelectAll();
    this.setState({ selected, selectAll: selectAll, groupData, isSelectAllEntry: false });
  }

  toggleSelectAll = () => {
    let { selected } = this.state;
    if (this.state.selectAll === 0 || this.state.selectAll === 2) {
      //selected = [];
      this.state.groupData.forEach(x => {
        x.active = true;
        if (selected.indexOf(x.group_id) === -1) {
          selected.push(x.group_id);
        }
      });
      if (this.state.selectAll === 2) {
        this.state.selectAll = 0;
      }
    } else if (this.state.selectAll === 1) {
      if (this.state.isSelectAllEntry) {
        selected = [];
        this.state.groupData.forEach(x => {
          x.active = false;
        });
      } else {
        this.state.groupData.forEach((x) => {
          selected.forEach((element, i) => {
            if (element === x.group_id) {
              x.active = false;
              selected.splice(i, 1)
            }
          });
        });
      }
    }

    this.setState({
      selected: selected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
      isSelectAllEntry: false
    });
  }

  isItemSelected = (id) => {
    let { selected } = this.state;
    let isChecked = false;
    selected.forEach(element => {
      if (id === element) {
        isChecked = true;
      }
    });
    return isChecked;
  }

  checkSelectAll = () => {
    let { selected, groupData } = this.state;
    let selectAll = 0;
    let perPageCountSelection = 0;
    if (selected.length > 0) {
      selected.forEach(id => {
        let index = groupData.findIndex(x => x.group_id === id);
        if (index > -1) {
          perPageCountSelection++;
        }
      });
      if (perPageCountSelection === groupData.length) {
        selectAll = 1;
      } else if (perPageCountSelection === 0) {
        selectAll = 0;
      } else {
        selectAll = 2;
      }
    } else {
      selectAll = 0;
    }
    return selectAll;
  }


  getCustomerCount = () => {
    if (this.state.selected.length <= 0) {
      ModalService.openAlert('', "Please Select Group", 'error')
      return
    }
    let reqData = {
      "customer_target_type": "jk_promotion_and_services",
      filter_promotion_and_services_group_ids: this.state.selected
    };
    this.setState({ isLoading: true });
    CampaignService.getCustomerCount(reqData)
      .then(data => {
        this.setState({
          counts: data.data.data.count,
          isLoading: false
        });
        if (data.data.data.count === 0) {
          ModalService.openAlert('', "There is no user found with applied filter . Please change filter criteria", 'error')
          return
        }
        let filterData = {
          filter_promotion_and_services_group_ids: this.state.selected,
          counts: data.data.data.count
        }
        this.props.applyFilterPromotional(filterData);
        // this.props.closeSideBar(false);
      })
      .catch(e => {
        this.setState({ isLoading: false });

        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error')
      });
  }

  applyFilter = () => {
    this.getCustomerCount();
  }

  resetFilter = (flag) => {
    this.props.applyFilterPromotional({});
  }

  closeSideBar = () => {
    this.props.closeSideBar(false);
  }

  render() {
    let { groupData, groupCount } = this.state;
    return (
      <div class="custom-drawer" id="AddEditActivity">
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        {/* <div style={{ marginTop: '60px' }}>
          <div className="drawer-header">
            <span className="drawer-title">Promotional & Events Users</span>
            <img className="cursor-pointer" src="images/close.svg" alt='cancel' onClick={() => this.closeSideBar(false)} />
          </div>
          <hr style={{ border: 'solid 1px #ced4da' }} />
        </div> */}
        <div class="custom-drawer-content">
          <Row className="c-top-15" style={{ marginLeft: '0px', marginRight: '0px' }}>
            <Col xl="8">
              <table className="mb-0 table-responsive tbl-about tbl-about text-align-left">
                <thead>
                  <tr style={{ backgroundColor: '#f6f6f6' }} className="customer-list-table-header">
                    <th className="border-1-5">
                      {
                        groupCount > 0 && <>
                          {
                            this.state.selectAll === 2 ? <img onClick={(e) => this.toggleSelectAll()} src="images/group-16.svg" alt='' style={{ height: '18px' }} /> :
                              (this.state.selectAll === 1 ? <label className="custom-checkboax1--container">
                                <input type="checkbox" name="completed"
                                  checked={true}
                                  onChange={(e) => this.toggleSelectAll()}
                                />
                                <span className="checkmark1" />
                              </label> : <label className="custom-checkboax1--container">
                                <input type="checkbox" name="completed"
                                  checked={false}
                                  onChange={(e) => this.toggleSelectAll()}
                                />
                                <span className="checkmark1" />
                              </label>)
                          }
                        </>
                      }
                    </th>
                    <th className="text-align-left border-1-5">&nbsp;Group Name</th>
                    <th className="text-align-left border-1-5" style={{ padding: '7px' }}>Members</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    groupData.map((g, i) => (
                      <tr className={'customer-list-row'}>
                        <td className="border-1-5">
                          <label className="custom-checkboax1--container">
                            <input type="checkbox" name="main_road" checked={this.isItemSelected(g.group_id)}
                              onChange={() => this.toggleRow(g.group_id)}
                            />
                            <span className="checkmark1" />
                          </label>
                        </td>
                        <td className="text-align-left border-1-5">&nbsp;&nbsp;{g.group_name}</td>
                        <td style={{ padding: '7px' }} className="text-align-left border-1-5">{g.member_count}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              {
                groupCount <= 0 && <div className="no-data tbl-offtake-summary" style={{ maxWidth: '100%' }}>
                  Currently, there is no data to display
                </div>
              }
            </Col>
          </Row>
        </div>
        <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter(true)} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
            <Button
              //  disabled={!isValidForm} 
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: 'pointer' }}
              onClick={() => this.applyFilter()}
            >Apply
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PromotionalEventsUsers;