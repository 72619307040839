import React, { useEffect, useState, useCallback, useRef } from "react";
import lodash from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  Button,
  InputGroupAddon,
} from "reactstrap";
// import Moment from "react-moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
// import TextareaAutosize from "react-textarea-autosize";
import {
  handleValidationMiniRetailMarketingStoreInformation,
  // handleValidationRetailMarketingStoreInformation,
  setRetailRecciControls,
} from "../../../actions/RetailManagementSystemAction";
import { getRetailMarketingStoreInformationState } from "../selector";
import CampaignService from "../../../services/CampaignService";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import RetailMarketingSystemService from "../../../services/RetailMarketingSystemService";
import Autosuggest from "react-autosuggest";
import "./RetailMarketingSignageInformation.css";
import { Modal, ModalBody } from "reactstrap";
import { Redirect } from "react-router-dom";
import Ionicon from "react-ionicons";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Lightbox from "react-image-lightbox";

function RetailMarketingSignageInformation({
  storeDetailControls,
  setRetailRecciControlsAction,
  handleValidationRetailMarketingStoreInformationAction,
  isEdit,
  history,
  onCloseSidebar,
  getAllRetail,
}) {
  const [duplicate, setDuplicate] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  // let calendarRecce = null;
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dealerList, setDealerList] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imagesToPreview, setImagesToPreview] = useState();
  const [isPreviewImageLightBoxOpen, setIsPreviewImageLightBoxOpen] = useState(
    false
  );
  const [
    isPreviewImageLightBoxOpenInstallation,
    setIsPreviewImageLightBoxOpenInstallation,
  ] = useState(false);

  const debouncedLoadSuggestions = useCallback(
    lodash.debounce((q) => searchDealer(q), 500),
    []
  );

  const renderSuggestion = (suggestion) => {
    return (
      <span>
        {suggestion.business_name} ({suggestion.sap_id})
      </span>
    );
  };
  useEffect(() => {
    // if (storeDetailControls.state.value) {
    //   getCity(storeDetailControls.state.value);
    //   getStateDistrict(storeDetailControls.state.value);
    // }
    getState();
  }, []);

  useEffect(() => {
    if (storeDetailControls.state.value) {
      getCity(storeDetailControls.state.value);
      getStateDistrict(storeDetailControls.state.value);
    }
  }, [storeDetailControls.state.value]);
  const handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    if (controlName === "pincode") {
      if (!isNaN(e.target.value)) {
        storeDetailControls[controlName].value = controlValue;
      }
    } else {
      storeDetailControls[controlName].value = controlValue;
    }
    if (controlName === "state") {
      storeDetailControls.city.value = "";
      storeDetailControls.district.value = "";
      storeDetailControls.pincode.value = "";
      // getCity(controlValue);
      // getStateDistrict(controlValue);
    }
    storeDetailControls[controlName].touched = true;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    if (controlName !== "pincode") {
      handleValidationRetailMarketingStoreInformationAction(
        false,
        storeDetailControls
      );
    }
    if (controlName === "pincode") {
      if (controlValue && controlValue.length === 6) {
        handleValidationRetailMarketingStoreInformationAction(
          false,
          storeDetailControls
        );
      }
    }
  };
  // const onBlurPincode = () => {
  //   if (storeDetailControls.pincode.value) {
  //     handleValidationRetailMarketingStoreInformationAction(
  //       false,
  //       storeDetailControls
  //     );
  //   }
  // };
  const getState = () => {
    setIsLoading(true);
    CampaignService.getState()
      .then((data) => {
        let { data: states } = data.data;
        // if (storeDetailControls.state.value) {
        //   getCity(storeDetailControls.state.value);
        //   getStateDistrict(storeDetailControls.state.value);
        // }
        if (
          (states.length > 0 &&
            storeDetailControls.state.value &&
            states.findIndex(
              (x) => x.state_id === storeDetailControls.state.value
            ) <= -1) ||
          (states.length <= 0 && storeDetailControls.state.value)
        ) {
          storeDetailControls.state.value = "";
          setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        }
        setStateData(states);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  const getCity = (state = null) => {
    setIsLoading(true);
    let reqData = {
      state_id: state ? state : storeDetailControls.state.value,
    };
    CampaignService.getCity(reqData)
      .then((data) => {
        const { data: cities } = data.data;
        // if ((cities.length > 0 && storeDetailControls.city.value &&
        //   cities.findIndex(x => x.city_id === storeDetailControls.city.value) <= -1) ||
        //   (cities.length <= 0 && storeDetailControls.city.value)) {
        //   storeDetailControls.city.value = '';
        //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        // }
        setCityData(cities);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error("e", e);
        setIsLoading(false);
      });
  };

  const getStateDistrict = (state) => {
    setIsLoading(true);
    const reqData = {
      state_id: state ? state : storeDetailControls.state.value,
    };
    RetailMarketingSystemService.getStateDistrict(reqData)
      .then((data) => {
        let { data: districts } = data.data;
        // if ((districts.length > 0 && storeDetailControls.district.value &&
        //   districts.findIndex(x => x.district_id === storeDetailControls.district.value) <= -1) ||
        //   (districts.length <= 0 && storeDetailControls.district.value)) {
        //   storeDetailControls.district.value = '';
        //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        // }
        setDistrictData(districts);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const onSuggestionsClearRequested = () => {
    setDealerList([]);
  };
  const goBack = () => {
    setDuplicate(true);
  };
  const selectDealer = (suggestion) => {
    storeDetailControls.dealer.value = suggestion.id ? suggestion.id : "";
    storeDetailControls.dealer.touched = true;
    localStorage.setItem("serearchQuery", suggestion.sap_id);

    if (suggestion.dealer_state_id) {
      storeDetailControls.state.value = suggestion.dealer_state_id;
      storeDetailControls.pincode.value = "";
      storeDetailControls.state.showErrorMsg = false;
      storeDetailControls.pincode.showErrorMsg = false;
      storeDetailControls.district.showErrorMsg = false;
      getCity(suggestion.dealer_state_id);
      getStateDistrict(suggestion.dealer_state_id);
    }
    if (suggestion.dealer_address) {
      storeDetailControls.address.value = suggestion.dealer_address;
      storeDetailControls.address.showErrorMsg = false;
    }
    if (suggestion.dealer_city_id) {
      storeDetailControls.city.value = suggestion.dealer_city_id;
      storeDetailControls.city.showErrorMsg = false;
    }
    if (suggestion.pin_code) {
      storeDetailControls.pincode.value = suggestion.pin_code;
      storeDetailControls.pincode.showErrorMsg = false;
    }
    storeDetailControls.district.value = "";
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(
      false,
      storeDetailControls
    );
    if (suggestion.rms_count >= 1) {
      toggle();
      // ModalService.openAlert("", "You have already submitted Recce for this dealer during last year, do you still want to submit this new Recce?", "error");
    }
    return suggestion.business_name + " - " + suggestion.sap_id;
  };
  const searchDealer = (e, type) => {
    let reqData = {
      q: e,
    };
    setIsLoading(true);
    RetailMarketingSystemService.serachMiniDealer(reqData)
      .then((res) => {
        setIsLoading(false);
        let dealerListTemp = [];
        if (res && res.data && res.data.data && res.data.data.length >= 0) {
          dealerListTemp = res.data.data;
        }
        setDealerList(dealerListTemp);
      })
      .catch((e) => { });
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions(value);
  };

  const onChangeDealer = (event, { newValue }) => {
    if (!newValue) {
      storeDetailControls.dealer.value = "";
      storeDetailControls.dealer.touched = true;
      handleValidationRetailMarketingStoreInformationAction(
        false,
        storeDetailControls
      );
    }

    storeDetailControls.dealer.displayValue = newValue;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
  };

  const inputProps = {
    placeholder: "Locate the dealer by SAP code",
    value: storeDetailControls.dealer.displayValue,
    onChange: onChangeDealer,
  };

  const fileInputRefsRecceImage1 = useRef([]);
  const fileInputRefsRecceImage2 = useRef([]);
  const fileInputRefsRecceImage3 = useRef([]);
  const fileInputRefsinstallImage1 = useRef([]);
  const fileInputRefsinstallImage2 = useRef([]);
  const fileInputRefsinstallImage3 = useRef([]);

  const selectPhoto = (index, type) => {
    if (type === "fileInputRefsRecceImage1") {
      if (index > -1 && fileInputRefsRecceImage1.current[index]) {
        fileInputRefsRecceImage1.current[index].click();
      }
    }

    if (type === "fileInputRefsRecceImage2") {
      if (index > -1 && fileInputRefsRecceImage2.current[index]) {
        fileInputRefsRecceImage2.current[index].click();
      }
    }

    if (type === "fileInputRefsRecceImage3") {
      if (index > -1 && fileInputRefsRecceImage3.current[index]) {
        fileInputRefsRecceImage3.current[index].click();
      }
    }

    if (type === "fileInputRefsinstallImage1") {
      if (index > -1 && fileInputRefsinstallImage1.current[index]) {
        fileInputRefsinstallImage1.current[index].click();
      }
    }
    if (type === "fileInputRefsinstallImage2") {
      if (index > -1 && fileInputRefsinstallImage2.current[index]) {
        fileInputRefsinstallImage2.current[index].click();
      }
    }
    if (type === "fileInputRefsinstallImage3") {
      if (index > -1 && fileInputRefsinstallImage3.current[index]) {
        fileInputRefsinstallImage3.current[index].click();
      }
    }
  };

  const uploadImage = async (event, index, controlName) => {
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      const result = await uploadRetailFile(file);
      const {
        proposedSizeForNewSignature_New,
        // proposedSizeForNewSignatureInstallation_New,
      } = storeDetailControls;
      if (result) {
        const { data } = result;
        proposedSizeForNewSignature_New[index][controlName].value =
          data.data.imageUrl;
        proposedSizeForNewSignature_New[index][controlName].touched = true;
        setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        handleValidationRetailMarketingStoreInformationAction(
          false,
          storeDetailControls
        );
      }
      // if (result) {
      //   const { data } = result;
      //   proposedSizeForNewSignatureInstallation_New[index][controlName].value =
      //     data.data.imageUrl;
      //   proposedSizeForNewSignatureInstallation_New[index][
      //     controlName
      //   ].touched = true;
      //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
      //   handleValidationRetailMarketingStoreInformationAction(
      //     false,
      //     storeDetailControls
      //   );
      // }
    }
  };

  const handleUploadImage = async (event, index, controlName) => {
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      const result = await uploadRetailFile(file);
      const {
        proposedSizeForNewSignatureSignage_New,
        proposedSizeForNewSignatureInstallationSignage_New,
      } = storeDetailControls;
      // if (result) {
      //   const { data } = result;
      //   proposedSizeForNewSignatureSignage_New[index][controlName].value =
      //     data.data.imageUrl;
      //   proposedSizeForNewSignatureSignage_New[index][
      //     controlName
      //   ].touched = true;
      //   setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
      //   handleValidationRetailMarketingStoreInformationAction(
      //     false,
      //     storeDetailControls
      //   );
      // }
      if (result) {
        const { data } = result;
        proposedSizeForNewSignatureInstallationSignage_New[index][
          controlName
        ].value = data.data.imageUrl;
        proposedSizeForNewSignatureInstallationSignage_New[index][
          controlName
        ].touched = true;
        setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
        handleValidationRetailMarketingStoreInformationAction(
          false,
          storeDetailControls
        );
      }
    }
  };

  const uploadRetailFile = async (reqData) => {
    setIsLoading(true);
    const response = await RetailMarketingSystemService.uploadRetailImages(
      reqData
    )
      .then((data) => {
        setIsLoading(false);
        return data;
      })
      .catch((e) => {
        setIsLoading(false);
        return false;
      });
    return response;
  };

  const deletePhoto = (index, controlName) => {
    const { proposedSizeForNewSignature_New } = storeDetailControls;
    proposedSizeForNewSignature_New[index][controlName].value = "";
    proposedSizeForNewSignature_New[index][controlName].touched = true;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(
      false,
      storeDetailControls
    );
  };
  const deleteInstallPhoto = (index, controlName) => {
    const {
      proposedSizeForNewSignatureInstallationSignage_New,
    } = storeDetailControls;
    proposedSizeForNewSignatureInstallationSignage_New[index][
      controlName
    ].value = "";
    proposedSizeForNewSignatureInstallationSignage_New[index][
      controlName
    ].touched = true;
    setRetailRecciControlsAction(Object.assign({}, storeDetailControls));
    handleValidationRetailMarketingStoreInformationAction(
      false,
      storeDetailControls
    );
  };

  const openPreviewImageLightBox = (index, data) => {
    var imagee = [];
    setPhotoIndex(index);
    if (data.image1.value) {
      imagee.push(data.image1.value);
    }
    if (data.image2.value) {
      imagee.push(data.image2.value);
    }
    if (data.image3.value) {
      imagee.push(data.image3.value);
    }
    setImagesToPreview(imagee);
    setIsPreviewImageLightBoxOpen(true);
  };

  const openPreviewImageLightBoxInstallation = (index, data) => {
    setPhotoIndex(index);
    var imagee = [];
    // storeDetailControls.proposedSizeForNewSignatureInstallation.map((data)=>{
    if (data.image1.value) {
      imagee.push(data.image1.value);
    }
    if (data.image2.value) {
      imagee.push(data.image2.value);
    }
    if (data.image3.value) {
      imagee.push(data.image3.value);
    }
    // })
    setImagesToPreview(imagee);
    setIsPreviewImageLightBoxOpenInstallation(true);
  };

  const closePreviewImageLightBox = () => {
    setImagesToPreview([]);
    setIsPreviewImageLightBoxOpen(false);
    document.body.style.overflow = "auto";
  };
  const closePreviewImageLightBoxInstallation = () => {
    setImagesToPreview([]);
    setIsPreviewImageLightBoxOpenInstallation(false);
    document.body.style.overflow = "auto";
  };

  const onSubmit = () => {
    if (
      !handleValidationRetailMarketingStoreInformationAction(
        true,
        storeDetailControls
      )
    ) {
      return true;
    }

    const installMonth = moment(storeDetailControls.recce_date.value).format(
      "MM"
    );
    const installYear = moment(storeDetailControls.recce_date.value).format(
      "YYYY"
    );

    const prePareObj = {
      user_id: parseInt(storeDetailControls.dealer.value),
      installation_month: parseInt(installMonth),
      installation_year: parseInt(installYear),
      width: parseInt(storeDetailControls.imgwidth.value),
      height: parseInt(storeDetailControls.imgheight.value),
      recce_image_1:
        storeDetailControls.proposedSizeForNewSignature_New[0].image1.value,
      recce_image_2:
        storeDetailControls.proposedSizeForNewSignature_New[0].image2.value,
      recce_image_3:
        storeDetailControls.proposedSizeForNewSignature_New[0].image3.value,
      recce_installation_image_1:
        storeDetailControls
          .proposedSizeForNewSignatureInstallationSignage_New[0].image1.value,
      recce_installation_image_2:
        storeDetailControls
          .proposedSizeForNewSignatureInstallationSignage_New[0].image2.value,
      recce_installation_image_3:
        storeDetailControls
          .proposedSizeForNewSignatureInstallationSignage_New[0].image3.value,
    };

    setIsLoading(true);
    RetailMarketingSystemService.addMiniRetailDetail(prePareObj)
      .then((data) => {
        setIsLoading(false);
        if (data.data && data.data.success) {
          onCloseSidebar();
          localStorage.setItem("serearchQuery", "");
          getAllRetail();
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  return (
    <div style={{ position: "relative" }} id="RetailMarketingStoreInformation">
      {isLoading && <CustomSpinner />}
      {isPreviewImageLightBoxOpen && (
        <Lightbox
          mainSrc={imagesToPreview[photoIndex]}
          nextSrc={imagesToPreview[(photoIndex + 1) % imagesToPreview.length]}
          prevSrc={
            imagesToPreview[
            (photoIndex + imagesToPreview.length - 1) % imagesToPreview.length
            ]
          }
          onCloseRequest={closePreviewImageLightBox}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesToPreview.length - 1) % imagesToPreview.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesToPreview.length)
          }
        />
      )}

      {isPreviewImageLightBoxOpenInstallation && (
        <Lightbox
          mainSrc={imagesToPreview[photoIndex]}
          nextSrc={imagesToPreview[(photoIndex + 1) % imagesToPreview.length]}
          prevSrc={
            imagesToPreview[
            (photoIndex + imagesToPreview.length - 1) % imagesToPreview.length
            ]
          }
          onCloseRequest={closePreviewImageLightBoxInstallation}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesToPreview.length - 1) % imagesToPreview.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesToPreview.length)
          }
        />
      )}

      {duplicate && (
        <Redirect
          to={{
            pathname: "/retail-marketing-system",
          }}
        />
      )}

      <div className="drawer-header">
        <span className="drawer-title">Add New Signage</span>
        <Ionicon
          className="cursor-pointer"
          icon="md-close"
          fontSize="24px"
          color="#FDBB1F"
          onClick={onCloseSidebar}
        />
      </div>
      <hr style={{ border: "solid 1px #ced4da" }} />
      <div className="custom-drawer-content-wrapperr Fix-height">
        <div style={{ padding: "20px" }}>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label>
                  SAP ID<span className="required-field">*</span>
                </Label>
                <div className="auto-suggest">
                  <Autosuggest
                    suggestions={dealerList}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={selectDealer}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    focusInputOnSuggestionClick={false}
                  />
                  <img
                    src="images/search.svg"
                    alt=''
                    className="report-search icon-width"
                    style={{ top: "39px" }}
                  />
                </div>
                {storeDetailControls.dealer.showErrorMsg && (
                  <div className="error">
                    * Please select name of the store.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <FormGroup>
                <Label>
                  Installation Period<span className="required-field">*</span>
                </Label>
                {/* <div className="d-flex"> */}
                <InputGroup className="date-picker">
                  <DatePicker
                    className={"form-control"}
                    // selected={installationDate}
                    selected={storeDetailControls.recce_date.value}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    // onChange={handleDateChange}
                    onChange={(value) =>
                      handleInputChange({
                        target: {
                          name: "recce_date",
                          value: value,
                        },
                      })
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <div
                      className="input-group-text"
                      style={{ paddingRight: "8px" }}
                    >
                      <img
                        src="/images/calendar_icon.svg"
                        alt=''
                        className="calendar-icon"
                      />
                    </div>
                  </InputGroupAddon>
                </InputGroup>
                {storeDetailControls.recce_date.showErrorMsg && (
                  <div className="error">* Please select date.</div>
                )}
                {/* </div> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label>
                  Signage Width (in Ft)<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <input
                    type="number"
                    name="imgwidth"
                    className="form-control"
                    // value={signaneWidth}
                    value={storeDetailControls.imgwidth.value}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Type here"
                  />
                </div>
                {storeDetailControls.imgwidth.showErrorMsg && (
                  <div className="error">* Please enter width</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label>
                  Signage Height (in Ft)
                  <span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <input
                    type="number"
                    name="imgheight"
                    className="form-control"
                    // value={signaneHeight}
                    value={storeDetailControls.imgheight.value}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Type here"
                  />
                </div>
                {storeDetailControls.imgheight.showErrorMsg && (
                  <div className="error">* Please enter height</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          {storeDetailControls.proposedSizeForNewSignature_New.map(
            (data, index) => {
              return (
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label>Upload Recce Image</Label>
                      <div className="d-flex">
                        {/* <SignageFilePicker /> */}
                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">Image 1</Label>
                            {data.image1.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(
                                      index,
                                      "fileInputRefsRecceImage1"
                                    )
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() => deletePhoto(index, "image1")}
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.image1.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.image1.value})`,
                              }}
                              onClick={() => openPreviewImageLightBox(0, data)}
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsRecceImage1")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => uploadImage(e, index, "image1")}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            ref={(el) =>
                              (fileInputRefsRecceImage1.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                        </div>

                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">Image 2</Label>
                            {data.image2.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(
                                      index,
                                      "fileInputRefsRecceImage2"
                                    )
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() => deletePhoto(index, "image2")}
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.image2.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.image2.value})`,
                              }}
                              onClick={() => openPreviewImageLightBox(0, data)}
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsRecceImage2")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => uploadImage(e, index, "image2")}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            ref={(el) =>
                              (fileInputRefsRecceImage2.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                        </div>

                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">Image 3</Label>
                            {data.image3.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(
                                      index,
                                      "fileInputRefsRecceImage3"
                                    )
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() => deletePhoto(index, "image3")}
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.image3.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.image3.value})`,
                              }}
                              onClick={() => openPreviewImageLightBox(0, data)}
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsRecceImage3")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => uploadImage(e, index, "image3")}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            ref={(el) =>
                              (fileInputRefsRecceImage3.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              );
            }
          )}

          {storeDetailControls.proposedSizeForNewSignatureInstallationSignage_New.map(
            (data, index) => {
              return (
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label>Upload Installation Image</Label>
                      <div className="d-flex">
                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">
                              Image 1<span className="required-field">*</span>
                            </Label>
                            {data.image1.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(
                                      index,
                                      "fileInputRefsinstallImage1"
                                    )
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() =>
                                    deleteInstallPhoto(index, "image1")
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.image1.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.image1.value})`,
                              }}
                              onClick={() =>
                                openPreviewImageLightBoxInstallation(0, data)
                              }
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsinstallImage1")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) =>
                              handleUploadImage(e, index, "image1")
                            }
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            ref={(el) =>
                              (fileInputRefsinstallImage1.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                          {data.image1.showErrorMsg && (
                            <div className="error">* Upload image.</div>
                          )}
                        </div>
                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">Image 2</Label>
                            {data.image2.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(
                                      index,
                                      "fileInputRefsinstallImage2"
                                    )
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() =>
                                    deleteInstallPhoto(index, "image2")
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.image2.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.image2.value})`,
                              }}
                            // onClick={() =>
                            //   openPreviewImageLightBox(0, data)
                            // }
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsinstallImage2")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) =>
                              handleUploadImage(e, index, "image2")
                            }
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            ref={(el) =>
                              (fileInputRefsinstallImage2.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                        </div>

                        <div className="d-flex-column upload-photo-wrapper">
                          <div className="d-flex justify-content-between">
                            <Label className="photo-lbl">Image 3</Label>
                            {data.image3.value && (
                              <div className="d-flex">
                                <img
                                  src="/images/edit.svg"
                                  alt=''
                                  className="edit-photo"
                                  onClick={() =>
                                    selectPhoto(
                                      index,
                                      "fileInputRefsinstallImage3"
                                    )
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                                <img
                                  src="/images/delete.svg"
                                  alt=''
                                  className="cursor-pointer"
                                  onClick={() =>
                                    deleteInstallPhoto(index, "image3")
                                  }
                                  style={{ height: "14px" }}
                                />
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </div>
                          {data.image3.value ? (
                            <div
                              className="photo-view"
                              style={{
                                backgroundImage: `url(${data.image3.value})`,
                              }}
                            // onClick={() =>
                            //   openPreviewImageLightBox(0, data)
                            // }
                            ></div>
                          ) : (
                            <div
                              className="upload-photo-div d-flex"
                              onClick={() =>
                                selectPhoto(index, "fileInputRefsinstallImage3")
                              }
                            >
                              <img
                                src="images/photo_upload.png"
                                alt=''
                                style={{ height: "20px", margin: "auto" }}
                              />
                              &nbsp;
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) =>
                              handleUploadImage(e, index, "image3")
                            }
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            ref={(el) =>
                              (fileInputRefsinstallImage3.current[index] = el)
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              );
            }
          )}
        </div>
      </div>

      <Row
        style={{
          marginLeft: "0",
          marginRight: "0",
          height: "100%",
          maxHeight: "80px",
          position: "relative",
          bottom: "0",
          width: "100%",
        }}
      >
        <Col xl="12" xs="12" md="12" className="text-align-right footer-drawer">
          <Button
            className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
            color="dark"
            onClick={onCloseSidebar}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button
            // disabled={disabled_button ? true : null}
            className="mb-1 mr-1 btn-pill action-button profile-save-button signage-save-button"
            color="warning"
            onClick={onSubmit}
          // onClick={() => {
          //     action === 'edit' ? this.fileUploadCheck({ 'edit': true }) : this.fileUploadCheck({ 'edit': false });
          // }}
          >
            Save
            {/* {action === 'new' ? 'Generate' : 'Save'} */}
          </Button>
        </Col>
      </Row>

      {/* <Card id="RetailMarketingStoreInformation">
        <CardBody>
          <Row>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  Recce Date <span className="required-field">*</span>
                </Label>
                {!isEdit ? (
                  <div className="d-flex">
                    <Label
                      onClick={(e) =>
                        calendarRecce.state.open && e.preventDefault()
                      }
                      className="mb-0"
                    >
                      <InputGroup>
                        <DatePicker
                          className={"form-control"}
                          onChange={(value) =>
                            handleInputChange({
                              target: {
                                name: "recce_date",
                                value: value,
                              },
                            })
                          }
                          name="recce_date"
                          selected={storeDetailControls.recce_date.value}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          ref={(r) => {
                            calendarRecce = r;
                          }}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          maxDate={new Date()}
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Select"
                        />
                        <InputGroupAddon addonType="append">
                          <div
                            className="input-group-text"
                            style={{ paddingRight: "8px" }}
                          >
                            <img
                              src="/images/calendar_icon.svg"
                               alt=''
                              className="calendar-icon"
                            />
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </div>
                ) : (
                  storeDetailControls.recce_date.value && (
                    <div
                      className="font-weight-bold"
                      style={{ fontSize: "16px" }}
                    >
                      <Moment format="DD MMM, YYYY">
                        {storeDetailControls.recce_date.value}
                      </Moment>
                    </div>
                  )
                )}
                {storeDetailControls.recce_date.showErrorMsg && (
                  <div className="error">* Please select recce date.</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xl="6" md="12" xs="12">
              <Label for="pan">
                Name of the Store<span className="required-field">*</span>
              </Label>
              <div className="auto-suggest">
                <Autosuggest
                  suggestions={dealerList}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={selectDealer}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  focusInputOnSuggestionClick={false}
                />
                <img
                  src="images/search.svg"
                   alt=''
                  className="report-search icon-width"
                  style={{ top: "39px" }}
                />
              </div>
              {storeDetailControls.dealer.showErrorMsg && (
                <div className="error">* Please select name of the store.</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xl="6" md="12" xs="12">
              <FormGroup>
                <Label for="pan">
                  Address<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <TextareaAutosize
                    name="address"
                    autocomplete="nofill"
                    id="address"
                    value={storeDetailControls.address.value}
                    style={{ minHeight: 60, maxHeight: 70, fontSize: "14px" }}
                    className="form-control"
                    onChange={(e) =>
                      handleInputChange({
                        target: { name: "address", value: e.target.value },
                      })
                    }
                    placeholder="Type Here"
                  />
                </div>
                {storeDetailControls.address.showErrorMsg && (
                  <div className="error">* Please enter address.</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  State<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="state"
                    value={storeDetailControls.state.value}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {stateData &&
                      stateData.map((item, i) => (
                        <option value={item.state_id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                {storeDetailControls.state.showErrorMsg && (
                  <div className="error">* Please select state.</div>
                )}
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  City<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="city"
                    value={storeDetailControls.city.value}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {cityData &&
                      cityData.map((item, i) => (
                        <option value={item.city_id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                {storeDetailControls.city.showErrorMsg && (
                  <div className="error">* Please select city.</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  District<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <select
                    className="form-control select-height-70rem font-size-14px"
                    name="district"
                    value={storeDetailControls.district.value}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {districtData &&
                      districtData.map((item, i) => (
                        <option value={item.district_id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                {storeDetailControls.district.showErrorMsg && (
                  <div className="error">* Please select district.</div>
                )}
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label>
                  Pincode<span className="required-field">*</span>
                </Label>
                <div className="d-flex">
                  <Input
                    type="text"
                    name="pincode"
                    value={storeDetailControls.pincode.value}
                    maxLength="6"
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() => onBlurPincode()}
                    placeholder="Type Here"
                  />
                </div>
                {storeDetailControls.pincode.showErrorMsg && (
                  <div className="error">* Please enter pincode.</div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card> */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        id="invite-dealer-modal"
        backdrop="static"
      >
        <ModalBody>
          <div>
            <img
              src="/images/info-3.png"
              style={{ marginLeft: "45%", marginTop: "24px" }}
              alt=''
            />
          </div>
          <div
            style={{
              marginTop: "24px",
              fontFamily: "Helvetica",
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "16px",
              color: "#495057",
            }}
          >
            You have already submitted Recce for this dealer during last year,
            do you still want to continue with this new Recce?
          </div>
          <div style={{ textAlign: "right", marginTop: "34px" }}>
            <Button
              style={{ width: "116px" }}
              className="mb-1 mr-1 btn-pill Rectangle-button cancel-button"
              color="dark"
              onClick={() => {
                toggle();
                goBack();
              }}
            >
              No
            </Button>{" "}
            <Button
              style={{ width: "116px" }}
              className="mb-1 mr-1 btn-pill Rectangle-button send-button"
              color="warning"
              onClick={() => {
                toggle();
              }}
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
RetailMarketingSignageInformation.propTypes = {
  storeDetailControls: PropTypes.instanceOf(Object).isRequired,
  setRetailRecciControlsAction: PropTypes.func.isRequired,
  installation: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => {
  return {
    storeDetailControls: getRetailMarketingStoreInformationState(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRetailRecciControlsAction: setRetailRecciControls,
      handleValidationRetailMarketingStoreInformationAction: handleValidationMiniRetailMarketingStoreInformation,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailMarketingSignageInformation);
