import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Table,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProposalSerivice from "../../services/ProposalSerivice";
import Moment from "react-moment";
import Storage from "../../services/Storage";
import swal from "sweetalert";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { Roles } from "../../constants/constant.role";
class ProposalAssign extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      assign: true,
      type: query.get("type"),
      userDetail: Storage.getUserDetail(),
      proposalsList: [],
      controls: {
        agency_id: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        month: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        year: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        outlet: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        stage: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        project_status: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        proposal_status: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        q: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      isAssginAgency: false,
      agencyList: [],
      assign_checkbox: [],
      years: [],
    };

    let currentYear = new Date().getFullYear();
    let startYear = 2017;
    let count = currentYear - startYear;
    for (var i = 0; i <= count; i++) {
      this.state.years.push(startYear);
      startYear++;
    }
  }

  componentDidMount() {
    this.getProposalList();
    if (this.state.userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE) {
      this.getAgencyList();
    }
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
  };

  isAllSelected() {
    let isAllSlect = false;
    this.selection.selected.forEach((element1) => {
      this.customerList.forEach((element2) => {
        if (element1 === element2) {
          isAllSlect = true;
        }
      });
    });
    return isAllSlect;
  }

  isItemSelected = (id) => {
    let { assign_checkbox } = this.state;
    let isChecked = false;
    assign_checkbox.forEach((element) => {
      if (id === element) {
        isChecked = true;
      }
    });
    return isChecked;
  };

  toggleRow = (id) => {
    let { assign_checkbox } = this.state;
    let index = assign_checkbox.findIndex((x) => x === id);
    if (index > -1) {
      assign_checkbox.splice(index, 1);
    } else {
      assign_checkbox.push(id);
    }
    this.setState({ assign_checkbox });
  };

  search = () => {
    this.getProposalList();
  };

  getAgencyList() {
    ProposalSerivice.getAgencyList()
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          this.setState((prevState) => {
            return { ...prevState, agencyList: res.data.data };
          });
        } else {
          this.setState((prevState) => {
            return { ...prevState, proposalsList: [] };
          });
        }
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  }

  submitAgency() {
    const apiPromises = [];
    let { controls } = this.state;
    if (!controls.agency_id.value || this.state.assign_checkbox.length < 0) {
      return;
    }
    this.setState({ isLoading: true });
    (this.state.assign_checkbox || []).forEach((item) => {
      let reqData = {
        agency_id: controls.agency_id.value,
        uuid: item,
      };
      apiPromises.push(ProposalSerivice.assignAgency(reqData));
    });
    Promise.all(apiPromises)
      .then((results) => {
        if (results && results.length > 0) {
          controls.agency_id.value = "";
          swal({
            title: "Congratulations !",
            text: results[0].data.message,
            icon: "success",
            type: "success",
          }).then((value) => { });
          this.getProposalList();
          this.setState({
            isLoading: false,
            controls,
            isAssginAgency: false,
            assign_checkbox: [],
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  }
  assignAgency = (uuid) => {
    if (!uuid) {
      return true;
    }
    let { controls } = this.state;
    let reqData = {
      agency_id: controls.agency_id.value,
      uuid: uuid,
    };
    ProposalSerivice.assignAgency(reqData)
      .then((res) => {
        controls.agency_id.value = "";
        swal({
          title: "Congratulations !",
          text: "Agency assigned successfully",
          icon: "success",
          type: "success",
        }).then((value) => { });
        this.getProposalList();
        this.setState({ isLoading: false, controls, isAssginAgency: false });
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };
  getProposalList() {
    const reqData = {};
    const { controls } = this.state;
    if (controls.month.value) {
      reqData.month = controls.month.value;
    }
    if (controls.year.value) {
      reqData.year = controls.year.value;
    }
    if (controls.outlet.value) {
      reqData.outlet = controls.outlet.value;
    }
    if (controls.stage.value) {
      reqData.stage = controls.stage.value;
    }
    if (controls.project_status.value) {
      reqData.project_status = controls.project_status.value;
    }
    if (controls.proposal_status.value) {
      reqData.proposal_status = controls.proposal_status.value;
    }

    if (controls.q.value) {
      reqData.q = controls.q.value;
    }
    reqData.page = 1;
    reqData.limit = 10000;
    this.setState({ isLoading: true });
    ProposalSerivice.getProposalList(reqData)
      .then((res) => {
        if (res.data.data && res.data.data.proposals.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              proposalsList: res.data.data.proposals,
              isLoading: false,
            };
          });
        } else {
          this.setState((prevState) => {
            return { ...prevState, proposalsList: [], isLoading: false };
          });
        }
      })
      .catch((e) => {
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isLoading: false });
      });
  }

  backToMain = () => {
    // this.getProposalList();
    this.setState((prevState) => {
      return { ...prevState, assign_checkbox: [] };
    });
  };
  redirectProjectStageStatus = (obj) => {
    ProposalSerivice.setProposalStageStatus(obj);
    let userDetail = Storage.getUserDetail();
    if (userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE) {
      if (this.state.assign) {
        let { proposalsList } = this.state;
        proposalsList = [];
        proposalsList.push(obj);
        this.setState((prevState) => {
          return { ...prevState, proposalsList, isAssginAgency: true };
        });
      } else {
        this.props.history.push(`proposal-architect`);
      }
    } else if (
      userDetail.access_level.toLowerCase() === "area" ||
      userDetail.access_level.toLowerCase() === "all"
    ) {
      this.props.history.push(`proposal-architect-am`);
    } else {
      this.props.history.push(`proposal-architect`);
    }
  };
  redirectNewProposal = () => {
    this.props.history.push("proposal-details");
  };
  redirectProposalDashboard = () => {
    this.props.history.push("proposal-dashboard");
  };

  resetFilter = () => {
    let { controls } = this.state;
    controls.month.value = "";
    controls.year.value = "";
    controls.outlet.value = "";
    controls.project_status.value = "";
    controls.proposal_status.value = "";
    controls.q.value = "";
    controls.stage.value = "";
    this.setState((prevState) => {
      return { ...prevState, controls };
    });
    this.getProposalList();
  };

  render() {
    let { controls } = this.state;
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <div className="header-title">Proposal Assign</div>
        <br />
        <Card>
          <CardBody>
            {/* Dealership Details */}
            <Row>
              <Col xl="6" md="12" xs="12">
                <b>
                  <FontAwesomeIcon
                    className="ml-2 opacity-8 arrow-color cursor-pointer"
                    icon={faFilter}
                  />
                  &nbsp;Filter
                </b>
              </Col>

              <Col xl="6" className="text-align-right">
                {(this.state.userDetail.access_level.toLowerCase() === "area" ||
                  this.state.userDetail.access_level.toLowerCase() ===
                  "all") && (
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      onClick={() => this.redirectNewProposal()}
                    >
                      New Proposal
                    </Button>
                  )}
                &nbsp;&nbsp;
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                  color="dark"
                  onClick={() => this.redirectProposalDashboard()}
                >
                  Back
                </Button>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Month</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="month"
                    value={controls.month.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="">
                      Select Month
                    </option>
                    <option value="1">Jan</option>
                    <option value="2">Feb</option>
                    <option value="3">Mar</option>
                    <option value="4">Apr</option>
                    <option value="5">May</option>
                    <option value="6">Jun</option>
                    <option value="7">Jul</option>
                    <option value="8">Aug</option>
                    <option value="9">Sep</option>
                    <option value="10">Oct</option>
                    <option value="11">Nov</option>
                    <option value="12">Dec</option>
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Year</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="year"
                    value={controls.year.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="">
                      All year
                    </option>
                    {this.state.years.map((item, i) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Outlet Type</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="outlet"
                    value={controls.outlet.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option value="">Select outlet type </option>
                    <option selected="selected" value="Steel Wheel">
                      Steel Wheel
                    </option>
                    <option value="XPress Wheel">XPress Wheel</option>
                    <option value="PTP">PTP</option>
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Project Stage</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="stage"
                    value={controls.stage.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option value="" selected disabled="true">
                      Select project stage
                    </option>
                    <option value="Recci in progress">Recci in progress</option>
                    <option value="Layout & agreement">
                      Layout &amp; Agreement
                    </option>
                    <option value="Estimate needed">Estimate needed</option>
                    <option value="Dealer work in progress">
                      Dealer work in progress
                    </option>
                    <option value="Agency work in progress">
                      Agency work in progress
                    </option>
                    <option value="Hand Over">Hand Over</option>
                    <option value="Pre inauguration">Pre inauguration</option>
                    <option value="Inaugurated">Inaugurated</option>
                    <option value="Project completed">Project completed</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label>Proposal Status</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="project_status"
                    value={controls.project_status.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="0">
                      Select proposal status
                    </option>
                    <option value="Fresh Proposal">Fresh Proposal</option>
                    <option value="Proposal accepted">Proposal accepted</option>
                    <option value="Modification needed">
                      Modification needed
                    </option>
                    <option value="Proposal rejected">Proposal rejected</option>
                  </select>
                </FormGroup>
              </Col>

              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Active / On Hold / Dropped</Label>
                  <select
                    className="form-control select-height-70rem"
                    name="proposal_status"
                    value={controls.proposal_status.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  >
                    <option selected="selected" value="0">
                      Select Status
                    </option>
                    <option value="Active">Active</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Dropped">Dropped</option>
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="phone">Keyword</Label>
                  <Input
                    type="text"
                    placeholder="Search keyword (Dealer/Region/Mobile)"
                    name="q"
                    value={controls.q.value}
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col
                xl="3"
                md="12"
                xs="12"
                className="d-flex-column"
                style={{ marginBottom: "1%" }}
              >
                <div style={{ marginTop: "auto" }} className="d-flex-row">
                  <Button
                    className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                    color="warning"
                    type="submit"
                    onClick={() => this.search()}
                    style={{ paddingLeft: "25px", paddingRight: "25px" }}
                  >
                    Search
                  </Button>
                  <div
                    className="d-flex-row reset-filter-wrapp"
                    style={{ marginLeft: "10px" }}
                    onClick={() => this.resetFilter()}
                  >
                    <img
                      src="images/reset.svg"
                      alt=""
                      style={{ height: "20px", width: "20px", margin: "auto" }}
                    />
                    &nbsp;&nbsp;
                    <span style={{ marginTop: "auto", marginBottom: "auto" }}>
                      Reset
                    </span>
                  </div>
                </div>
              </Col>
              {/* <Col xl="1"></Col> */}
            </Row>
            <br />
            <div>
              {this.state.assign_checkbox &&
                this.state.assign_checkbox.length > 0 && (
                  <>
                    <Row>
                      <Col xl="3" md="12" xs="12">
                        <FormGroup>
                          <Label>Agency</Label>
                          <select
                            className="form-control select-height-70rem"
                            name="agency_id"
                            value={controls.agency_id.value}
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="" selected disabled>
                              Select agency
                            </option>
                            {this.state.agencyList &&
                              this.state.agencyList.map((item, i) => (
                                <option value={item.id}>
                                  {item.business_name}
                                </option>
                              ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col xl="3" md="12" xs="12">
                        <Button
                          className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                          color="warning"
                          type="submit"
                          style={{ marginTop: "30px" }}
                          onClick={(e) => this.submitAgency()}
                        >
                          Submit
                        </Button>{" "}
                        &nbsp;&nbsp;
                        <Button
                          className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                          color="dark"
                          style={{ marginTop: "30px" }}
                          onClick={() => this.backToMain()}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              <div className="tbl-project-stage-proposal-assign">
                <Table
                  id="table"
                  className="mb-0 table-responsive tbl-about tbl-about"
                >
                  <thead>
                    <tr>
                      <th scope="col">select</th>
                      <th scope="col" style={{ width: "100px" }}>
                        Dealership Name
                      </th>
                      <th scope="col">Region</th>
                      {/* <th scope="col">Project Status</th> */}
                      {this.state.userDetail.access_level !== "architect" && (
                        <th scope="col">Proposal Status</th>
                      )}
                      {this.state.userDetail.access_level === "architect" && (
                        <th scope="col" style={{ width: "100px" }}>
                          Agency
                        </th>
                      )}
                      <th scope="col">Project Stage</th>
                      <th scope="col">Outlet Type</th>
                      <th scope="col">Status</th>
                      {this.state.userDetail.access_level !== "architect" && (
                        <th scope="col">Report Date</th>
                      )}
                      <th scope="col">Approve Date</th>
                    </tr>
                  </thead>
                  <tbody className="project-stage-filter-tbody">
                    {this.state.proposalsList.map((row, i) =>
                      row.outlet_type === "PTP" ? (
                        ""
                      ) : (
                        <tr key={i}>
                          <td>
                            {/* <a onClick={() => this.redirectProjectStageStatus(row)} id="ContentPlaceHolder1_grdproposal_lnkbtn_0" href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$grdproposal$ctl02$lnkbtn&#39;,&#39;&#39;)"   >Select</a> */}
                            <label className="custom-checkboax1--container">
                              <input
                                type="checkbox"
                                name="main_road"
                                checked={this.isItemSelected(row.uuid)}
                                onChange={() => this.toggleRow(row.uuid)}
                              />
                              <span className="checkmark1" />
                            </label>
                          </td>
                          <td>{row.name}</td>
                          <td>{row.region}</td>
                          {this.state.userDetail.access_level ===
                            "architect" && (
                              <td>
                                {row.agency_name ? (
                                  <span>{row.agency_name}</span>
                                ) : (
                                  <span className="no-data-hypen  ml-auto mr-auto"></span>
                                )}
                              </td>
                            )}
                          {/* <td>{row.project_status}</td> */}
                          {this.state.userDetail.access_level !==
                            "architect" && <td>{row.project_status}</td>}
                          <td>
                            {row.stage ? (
                              <sapn>{row.stage}</sapn>
                            ) : (
                              <span className="no-data-hypen  ml-auto mr-auto"></span>
                            )}
                          </td>
                          <td>{row.outlet_type}</td>
                          <td>{row.status}</td>
                          {/* <td>{row.proposal_status}</td> */}
                          {this.state.userDetail.access_level !==
                            "architect" && (
                              <td>
                                {row.report_date && (
                                  <Moment format="DD/MM/YYYY">
                                    {row.report_date}
                                  </Moment>
                                )}
                              </td>
                            )}
                          <td>
                            {row.approval_date ? (
                              <Moment format="DD/MM/YYYY">
                                {row.approval_date}
                              </Moment>
                            ) : (
                              <span className="no-data-hypen ml-auto mr-auto"></span>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                    {/* <tr>
                    <td>
                      <a onClick={() => this.redirectProjectStageStatus()} id="ContentPlaceHolder1_grdproposal_lnkbtn_0" href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$grdproposal$ctl02$lnkbtn&#39;,&#39;&#39;)"   >Select</a>
                    </td><td>Advance Tyres Sales and Services</td><td>Ahmedabad</td><td>Proposal accepted</td><td>Layout &amp; Agreement</td><td>SW</td><td>Active</td><td>Fresh</td><td>7/16/2019 5:19:43 PM</td>
                  </tr> */}
                  </tbody>
                </Table>
                {this.state.proposalsList.length <= 0 && (
                  <div
                    className="no-data tbl-offtake-summary"
                    style={{ maxWidth: "100%" }}
                  >
                    Currently, there is no proposals.
                  </div>
                )}
              </div>
              <br />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default ProposalAssign;
