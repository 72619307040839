import React, { Fragment, useState, useEffect } from 'react';
import Mt30 from './../../assets/images/mt30.png';
import Mt0 from './../../assets/images/mt0.png';
import Mt90 from './../../assets/images/mt90.png';
import RangerMt from '../../assets/images/rangerMT.png';
import { useHistory } from 'react-router-dom';
import './RangerEvent.css';

const eventImage = [
  {
    id: '1',
    src: Mt30
  },
  {
    id: '2',
    src: Mt0
  },
  {
    id: '3',
    src: Mt90
  },
  {
    id: '4',
    src: RangerMt
  }
]


const RangerEventContent = ({ queryParameter }) => {

  let history = useHistory();
  const [displayImage, setDisplayImage] = useState(0)
  const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(false)

  const imageClicked = async (index) => {
    setDisplayImage(index);
    setClicked(true)
    setTimeout(() => {
      setClicked(false)
    }, 1000);
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 800);
  }, [])

  const gotoContact = () => {
    history.push({
      pathname: '/event-registration',
      state: queryParameter
    })
  }
  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <div className="rangercontentHeader">
          <div className="rangerImageContainer">
            <div className={"mainEventImageContainer " + (clicked ? "active-image" : "de-active")}>
              <img className="mainEventImage " src={eventImage[displayImage].src} alt="" />
            </div>
            <div className="imagegridContainer">
              {eventImage.map((image, index) => (
                <div>
                  <img onClick={() => imageClicked(index)} className={"rangereventImage " + (loading ? "active-font-table1" : 'de-active')} src={image.src} alt="" />
                </div>
              ))}
            </div>
            <div>
              <p className={"rangerimagetext " + (loading ? 'active-font' : 'de-active')}>Unique and Eye Catchy Styling with Stylish Raised White Letters.</p>
            </div>
          </div>

          <div className="eventContent">
            <div className="eventName">
              RANGER M/T
          </div>
            <table className="eventTable">
              <thead>
                <tr>
                  <th className={"tableHeading " + (loading ? "active-font-table1" : "de-active")}>
                    COMPATIBLE VEHICLES
                </th>
                  <th className={"tableHeading " + (loading ? "active-font-table1" : "de-active")}>
                    AVAILABLE SIZE
                </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={"tableBody " + (loading ? "active-font-table1" : "de-active")}>
                    Isuzu D-max Vcross , Toyota Fortuner , Ford Endeavour
                </td>
                  <td className={"tableBody " + (loading ? "active-font-table1" : "de-active")}>
                    265/70 R17
                </td>
                </tr>
                <tr>
                  <td className={"tableBody " + (loading ? "active-font-table1" : "de-active")}>
                    Mahindra Thar (old)
                </td>
                  <td className={"tableBody " + (loading ? "active-font-table1" : "de-active")}>
                    31X10.5 R15 - Upcoming
                </td>
                </tr>
                <tr>
                  <td className={"tableBody " + (loading ? "active-font-table1" : "de-active")}>
                    Mahindra Thar (2020)
                </td>
                  <td className={"tableBody " + (loading ? "active-font-table1" : "de-active")}>
                    245/75 R16 - Upcoming
                </td>
                </tr>
              </tbody>
            </table>
            <div className="hr"></div>
            <small style={{ opacity: '1' }} className={"eventnote " + (loading ? "active-font-note" : "de-active")}>* Representative list only, compatible with more vehicles as per the available tyre sizes</small>
            <table cellspacing="0" cellpadding="0" className="eventTable">
              <thead>
                <tr>
                  <th className={"tableHeading " + (loading ? "active-font" : "de-active")}>
                    BENEFITS
                </th>
                  <th className={"tableHeading " + (loading ? "active-font" : "de-active")}>
                    FEATURES
                </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={"tableBody " + (loading ? "active-font" : "de-active")}>
                    Excellent performance for Professional Off-Roading
                </td>
                  <td className={"tableBody " + (loading ? "active-font" : "de-active")}>
                    Mud terrain pattern with rugged blocks and shoulder
                </td>
                </tr>
                <tr>
                  <td className={"tableBody " + (loading ? "active-font" : "de-active")}>
                    Unique and eye catchy styling
                </td>
                  <td className={"tableBody " + (loading ? "active-font" : "de-active")}>
                    Stylish Raised White Letters
                </td>
                </tr>
                <tr>
                  <td className={"tableBody " + (loading ? "active-font" : "de-active")}>
                    Sporty and Rugged appeal to the vehicle
                </td>
                  <td className={"tableBody " + (loading ? "active-font" : "de-active")}>
                    Large chevron blocks
                </td>
                </tr>
                <tr>
                  <td className={"tableBody " + (loading ? "active-font" : "de-active")}>
                    Anti cut and anti chipping performance
                </td>
                  <td className={"tableBody " + (loading ? "active-font" : "de-active")}>
                    Nen Gen Tread compound
                </td>
                </tr>
                <tr>
                  <td className={"tableBody lastTableBody " + (loading ? "active-font" : "de-active")}>

                    {`New generation reinforcement materials for improving Off Roading
                        performance & better durability in extreme conditions `}

                  </td>
                  <td className={"tableBody lastTableBody " + (loading ? "active-font" : "de-active")}>
                    Long Lasting Reinforcements
                </td>
                </tr>
              </tbody>
            </table>
            <button onClick={gotoContact} className="eventContactButton">Register Here</button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default RangerEventContent;