import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import warranty0 from "../../../assets/images/warranty0.png";
import warranty1 from "../../../assets/images/warranty1.png";
import warranty2 from "../../../assets/images/warranty2.png";
import warranty3 from "../../../assets/images/warranty3.png";
import warranty4 from "../../../assets/images/warranty4.png";
import warranty5 from "../../../assets/images/warranty5.png";
import warranty6 from "../../../assets/images/warranty6.png";
import "../SecondaryPointsReport.css";

const helpGuide = {
  data: [
    {
      image: warranty0,
      englishMsg:
        "Enter the customer’s 10 digit mobile number for WARRANTY REGISTRATION.",
      hindiMsg:
        "वारंटी पंजीकरण के लिए ग्राहक का 10 अंकों का मोबाइल नंबर दर्ज करें!",
    },
    {
      image: warranty1,
      englishMsg: "Verify OTP code received on customer’s mobile number.",
      hindiMsg: "ग्राहक के मोबाइल नंबर पर प्राप्त ओटीपी कोड सत्यापित करें!",
    },
    {
      image: warranty2,
      englishMsg:
        "Enter the customer’s deails like First name, Last name and Email address.",
      hindiMsg: "ग्राहक का विवरण दर्ज करें जैसे पहला नाम अंतिम नाम और ईमेल!",
    },
    {
      image: warranty3,
      englishMsg:
        "Select the type of vehicle i.e. Car or two wheeler and if existing vehicle owner then select the vehicle number.",
      hindiMsg:
        "कृपया वाहन के प्रकार का चयन करें जैसे की कार या दो पहिया वाहन और यदि मौजूदा वाहनका मालिक है तो वाहन की  संख्या का चयन करें!",
    },
    {
      image: warranty4,
      englishMsg:
        "Select the type of vehicle i.e. Car or two wheeler and if existing vehicle owner then select the vehicle number.",
      hindiMsg: "निर्देशानुसार वाहनों का शेष विवरण पूरा करें!",
    },
    {
      image: warranty5,
      englishMsg:
        "Enter the Product details based on tyre description. For example search the product like blaze, touring and you will find the matching product list. Select the correct product description.",
      hindiMsg:
        "टायर विवरण के आधार पर उत्पाद विवरण दर्ज करें। जैसे कीं ब्लेज़,टूरिंग और जैसे उत्पाद खोजें और आपको उत्पाद सूची मिल जाएगी अब उनमें से सही उत्पाद विवरण चुनें।",
    },
    {
      image: warranty6,
      englishMsg:
        "Select the number of tyres, customer has purchased and complete the registration.",
      hindiMsg:
        "ग्राहक के टायरों की संख्या का चयन करें जो खरीदा है और पंजीकरण पूरा करें।",
    },
  ],
};

const SubDealerGuide = ({ is_login_first_time, subdealerGuideClose }) => {
  const [index, setIndex] = useState(0);
  const [language, setLanguage] = useState("English");
  const [modal, setModal] = useState(is_login_first_time);

  const prevClick = async () => {
    await setIndex(index - 1);
  };

  const nextClick = async () => {
    await setIndex(index + 1);
  };

  const onChangelanguage = async (data) => {
    await setLanguage(data);
  };

  return (
    <Fragment>
      <Modal
        isOpen={modal}
        centered={true}
        contentClassName="subdealerGuideModal"
      >
        <div>
          <ModalHeader toggle={subdealerGuideClose}>
            <div style={{ paddingBottom: "10px" }}>
              <span style={{ color: "#495057", fontSize: "14px" }}>
                Warranty Registration Guide
              </span>
            </div>
          </ModalHeader>
          <div style={{borderTop:'1px solid #F1F0F0' , margin:'0px 0px 12px 0'}}></div>
          <div
              style={{
                display: "flex",
                placeItems: "center",
                border: "1px solid #c0c0c0",
                padding: "5px",
                borderRadius: "8px",
                width:'max-content',
                margin:'auto'
              }}
            >
              <div style={{ marginRight: "5px" }}>
                <button
                  style={{
                    outline: "none",
                    border: "none",
                    fontWeight: "bold",
                  }}
                  className={
                    language === "English"
                      ? "custom_guidebutton-active"
                      : "custom-guidebutton-deactive"
                  }
                  onClick={() => onChangelanguage("English")}
                >
                  English
                </button>
              </div>
              <div>
                <button
                  style={{
                    outline: "none",
                    border: "none",
                    fontWeight: "bold",
                  }}
                  className={
                    language === "Hindi"
                      ? "custom_guidebutton-active"
                      : "custom-guidebutton-deactive"
                  }
                  onClick={() => onChangelanguage("Hindi")}
                >
                  Hindi
                </button>
              </div>
            </div>
          <ModalBody>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className={index === 0 ? "disabledGuideButton" : "guideButton"}
                disabled={index === 0}
                onClick={prevClick}
              >
                <ChevronLeft />
              </button>
              <div>
                <div
                  style={{
                    padding: "10px",
                    height: "240px",
                  }}
                >
                  <img
                    className="img-fluid"
                    src={helpGuide.data[index].image}
                    alt=""
                  />
                </div>
              </div>
              <button
                className={index === 6 ? "disabledGuideButton" : "guideButton"}
                disabled={index === 6}
                onClick={nextClick}
              >
                <ChevronRight />
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              {language === "English" ? (
                <p style={{ height: "65px" }}>
                  {helpGuide.data[index].englishMsg}
                </p>
              ) : (
                <p style={{ height: "65px" }}>
                  {helpGuide.data[index].hindiMsg}
                </p>
              )}
            </div>
          </ModalFooter>
        </div>
      </Modal>
    </Fragment>
  );
};
export default SubDealerGuide;
