export const ProposalDetailState = {
  dealership_Details: {
    tempSo: "",
    controls: {
      proposal_type: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      outlet_type: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      category: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      name: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      address: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      pincode: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      city: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      state: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      landline: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: false,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      mobile: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      email_id: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      region: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      area: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      so: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    isFormValid: false,
  },
  businessAnalysis: {
    controls: {
      site_picture: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      lease_aggrement: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      is_agree_exclusive_jk: {
        value: "Y",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      remarks: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      ownership_type: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      proprietor_name: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      is_in_tyre_business: {
        value: "Y",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      counters: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      is_jk_authorized_dealer: {
        value: false,
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      sap_id: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        invalidMinLength: null,
        invalidLength: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      sas_non_truck: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      sas_truck: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      is_premised_owner: {
        value: true,
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      shutters_count: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      compitition_brand_pcr: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      existing_shop_compitition: {
        value: false,
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      existing_show_compitition_name: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      total_counter_total_year_potential: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      total_counter_total_h1_potential: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      present_offtake_total_year_turnover: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      present_offtake_total_h1_turnover: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      counter_share_total_year_turnover: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      counter_share_total_h1_turnover: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      proposed_business_total_year_turnover: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      proposed_business_h1_turnover: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      counter_monthly: [
        {
          lcv: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          type: "Total Counter",
          scv: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          car: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          two_wheel: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
        },
        {
          lcv: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          type: "Present Offtake",
          scv: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          car: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          two_wheel: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
        },
        {
          lcv: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          type: "Counter Share",
          scv: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          car: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          two_wheel: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
        },
        {
          lcv: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          type: "Proposed Business",
          scv: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          car: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
          two_wheel: {
            value: "",
            valid: null,
            touched: false,
            nullValue: null,
            invalidEmail: null,
            required: true,
            showErrorMsg: false,
            autoFocus: false,
            isDisable: false,
          },
        },
      ],
    },
    isFormValid: false,
  },
  showRoomSize: {
    controls: {
      front_face_area: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      dealer_share_amount: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      showroom_frontage: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      showroom_depth: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      showroom_celling_height: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      showroom_area: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      having_separate_storage: {
        value: "Y",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      storage_area: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      location: {
        main_road: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          isDisable: false,
        },
        commercial_area: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          isDisable: false,
        },
        tyre_auto_market: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          isDisable: false,
        },
        residental_area: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          isDisable: false,
        },
      },
      assets_value: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      other_business: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    isFormValid: false,
  },
  machineryDetailsExisting: [
    {
      type: "Wheel Aligner",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    {
      type: "Tyre Changer",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    {
      type: "Wheel Balancer",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    {
      type: "Nitrogen Inflator",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    {
      type: "Compressor",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
  ],
  machineryDetailsRequired: [
    {
      type: "Wheel Aligner",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    {
      type: "Tyre Changer",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    {
      type: "Wheel Balancer",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    {
      type: "Nitrogen Inflator",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    {
      type: "Compressor",
      brand: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      model: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      installation_date: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
  ],
  annexures: {
    controls: {
      willing_machinery_invest: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
      willing_civil_invest: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidEmail: null,
        required: true,
        showErrorMsg: false,
        autoFocus: false,
        isDisable: false,
      },
    },
    isFormValid: false,
  },
};
