import React, { Component } from 'react';
import {
  Card
} from 'reactstrap';
import { Player } from 'video-react';

import DashboardService from '../../services/DashboardService';

class VideoComponent extends Component {

  state = {
    videoUrl: null
  }

  componentDidMount() {
    DashboardService.getVideoUrl()
      .then(data => {
        const videoUrl = data.data.data;
        this.setState({ videoUrl });
      })
      .catch(e => {

      });
  }

  render() {
    const { videoUrl } = this.state;
    return <Card className="height-100 border-radius-10">
      {/* <CardBody> */}
      <Player
        ref={player => {
          this.player = player;
        }}
        height={251}
      >
        {videoUrl && <source src={videoUrl} />}
      </Player>
      <div className="video-title margin-top-10">ADVANTAGE 2.0 HIGHLIGHTS</div>
      {/* <div className="video-subtitle margin-bottom-20">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</div> */}
      {/* </CardBody> */}

    </Card>;
  }
}

export default VideoComponent;