import React, { Component, Fragment } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import { roundValue, downlodFile, formatNumber } from "../../utils/utils";
import "./HODashboardClassWiseCard.css";
import HeadOfficeService from "../../services/HeadOfficeService";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
class HODashboardClassWiseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
    };
  }

  downloadExcelHo = (className, currentNav) => {
    this.setState({
      isLoader: true,
    });
    let type = null;
    let ref = null;
    if (currentNav) {
      type = currentNav.type;
      ref = currentNav.ref;
    }
    HeadOfficeService.downloadExcelHo(
      type,
      ref,
      className,
      this.props.selectedMonth,
      this.props.selectedYear
    )
      .then((data) => {
        const { file } = data.data.data;
        downlodFile(file);
        this.setState({
          isLoader: false,
        });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({
          isLoader: false,
        });
      });
  };

  render() {
    const {
      offtake,
      totalDealers,
      // findFirstQuarterMonthsToCurrentMonths,
      // currentQuarterMonths,
      // currentMonth,
      currentNav,
      label,
    } = this.props;
    // const prepareRows = offtake.map(o =>
    //   <Row className="dashboard-page-card-title-small margin-top-20 margin-bottom-10">
    //     <Col sm="1" style={{ paddingRight: '0px', paddingLeft: '18px', textAlign: 'left' }}>
    //       {o.customerClass}
    //     </Col>
    //     <Col sm="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
    //       {o.totalDealers}
    //     </Col>
    //     <Col sm="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
    //       <Row style={{ fontSize: '12px' }}>
    //         <Col sm="6" className="text-align-right">
    //           {roundValue(o.yearOfftake)}
    //         </Col>
    //         <Col sm="6" className="text-align-left">
    //           {o.yearActiveDealer}
    //         </Col>
    //       </Row>
    //     </Col>
    //     <Col sm="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
    //       <Row style={{ fontSize: '12px' }}>
    //         <Col sm="6" className="text-align-right">
    //           {roundValue(o.quarterOfftake)}
    //         </Col>
    //         <Col sm="6" className="text-align-left">
    //           {o.quarterActiveDealer}
    //         </Col>
    //       </Row>
    //     </Col>
    //     <Col sm="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
    //       <Row style={{ fontSize: '12px' }}>
    //         <Col sm="6" className="text-align-right">
    //           {roundValue(o.monthOfftake)}
    //         </Col>
    //         <Col sm="6" className="text-align-left">
    //           {o.monthActiveDealer}
    //         </Col>
    //       </Row>
    //     </Col>
    //   </Row>
    // )

    return (
      <Fragment>
        {(this.state.isLoader || this.props.isLoader) && <CustomSpinner />}
        <Row>
          <Col className="sub-title">PARTICIPATING ACCOUNTS</Col>
        </Row>
        <Card className="heigh-95-percentage">
          <CardBody style={{ padding: "0px" }}>
            {/* <Row className="card-custom-header">
            <Col sm="1" style={{ paddingRight: '0px' }}>
            </Col>
            <Col sm="2" style={{ paddingLeft: '0px', paddingRight: '0px' }} className="dashboard-page-card-title-big">
              Total Dealers
            </Col>
            <Col sm="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <div className="dashboard-page-card-title-big margin-top-5">Year Till Date</div>
              <div className="dashboard-page-card-title-small margin-top-5">Apr-Sept'19</div>
              <Row style={{ fontSize: '12px' }} className="margin-top-20">
                <Col sm="6" className="text-align-right">
                  Offtake
                </Col>
                <Col sm="6" className="text-align-left">
                  Active Dealers
                </Col>
              </Row>
            </Col>
            <Col sm="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <div className="dashboard-page-card-title-big margin-top-5">Quarter Till Date</div>
              <div className="dashboard-page-card-title-small margin-top-5">Apr-Sept'19</div>
              <Row style={{ fontSize: '12px' }} className="margin-top-20">
                <Col sm="6" className="text-align-right">
                  Offtake
                </Col>
                <Col sm="6" className="text-align-left">
                  Active Dealers
                </Col>
              </Row>
            </Col>
            <Col sm="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <div className="dashboard-page-card-title-big margin-top-5">Month Till Date</div>
              <div className="dashboard-page-card-title-small margin-top-5">Apr'19</div>
              <Row style={{ fontSize: '12px', textAlign: 'center' }} className="margin-top-20">
                <Col sm="6" className="text-align-right">
                  Offtake
                </Col>
                <Col sm="6" className="text-align-left">
                  Active Dealers
                </Col>
              </Row>
            </Col>
          </Row>
          {prepareRows} */}
            <Table
              className="table-responsive tbl-HODashboardClassWiseCard"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <thead
                style={{
                  backgroundImage: "linear-gradient(101deg, #f8e184, #f7d174)",
                }}
              >
                <tr className="border-0">
                  <th className="border-0"></th>
                  <th className="text-align-center border-0 width-55px">
                    <span className="dashboard-page-card-title-big">
                      Total Accounts
                    </span>
                  </th>
                  <th className="text-align-center border-0" colspan="2">
                    <span className="dashboard-page-card-title-big">
                      Year Till Date
                      <br />
                      <span className="dashboard-page-card-title-small">
                        {label.year}
                        {/* {findFirstQuarterMonthsToCurrentMonths} */}
                      </span>
                    </span>
                  </th>
                  <th className="text-align-center border-0" colspan="2">
                    <span className="dashboard-page-card-title-big">
                      Quarter Till Date
                      <br />
                      <span className="dashboard-page-card-title-small">
                        {label.quarter}
                        {/* {currentQuarterMonths} */}
                      </span>
                    </span>
                  </th>
                  <th className="text-align-center border-0" colspan="2">
                    <span className="dashboard-page-card-title-big">
                      Month Till Date
                      <br />
                      <span className="dashboard-page-card-title-small">
                        {label.month}
                        {/* {currentMonth} */}
                      </span>
                    </span>
                  </th>
                </tr>
                <tr className="border-0">
                  <th className="text-align-center border-0"></th>
                  <th className="text-align-right border-0">
                    <span
                      className="count-small hyper-link cursor-pointer"
                      onClick={() => this.downloadExcelHo(null, currentNav)}
                    >
                      {totalDealers}
                    </span>
                  </th>
                  <th className="border-top-0 font-weight-normal text-align-right title-class-wise border-right-217">
                    Offtake
                  </th>
                  <th className="border-0 font-weight-normal text-align-center title-class-wise width-44px">
                    Active Accounts
                  </th>
                  <th className="border-top-0 font-weight-normal text-align-right title-class-wise border-right-217">
                    Offtake
                  </th>
                  <th className="border-0 font-weight-normal text-align-center title-class-wise width-44px">
                    Active Accounts
                  </th>
                  <th className="border-top-0 font-weight-normal text-align-right title-class-wise border-right-217">
                    Offtake
                  </th>
                  <th className="border-0 font-weight-normal text-align-center title-class-wise width-44px">
                    Active Accounts
                  </th>
                </tr>
              </thead>
              <tbody>
                {offtake.map((o) => (
                  <tr className="border-0">
                    <td className="border-top-0 title-small text-align-left">
                      {o.customerClass}
                    </td>
                    <td
                      className="border-top-0 title-small text-align-right cursor-pointer hyper-link"
                      onClick={() =>
                        this.downloadExcelHo(
                          o.customerClass.toLowerCase(),
                          currentNav
                        )
                      }
                    >
                      {o.totalDealers}
                    </td>
                    <td className="border-top-0 title-small text-align-right border-right-217">
                      {formatNumber(roundValue(o.yearOfftake))}
                    </td>
                    <td
                      className="border-top-0 title-small text-align-right pr-2"
                      style={{ paddingRight: "0px" }}
                    >
                      {o.yearActiveDealer}
                    </td>
                    <td className="border-top-0 title-small text-align-right border-right-217">
                      {formatNumber(roundValue(o.quarterOfftake))}
                    </td>
                    <td
                      className="border-top-0 title-small text-align-right pr-2"
                      style={{ paddingRight: "0px" }}
                    >
                      {o.quarterActiveDealer}
                    </td>
                    <td className="border-top-0 title-small text-align-right border-right-217">
                      {formatNumber(roundValue(o.monthOfftake))}
                    </td>
                    <td className="border-0 title-small text-align-right pr-2">
                      {o.monthActiveDealer}
                    </td>
                  </tr>
                ))}
                {/* <td></td> */}
              </tbody>
            </Table>
            <div className="Note-This-figures-s text-align-right">
              Note: Offtake entry shows the average per month data.
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default HODashboardClassWiseCard;
