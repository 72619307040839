import React, { Component } from 'react';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import { Row, Col, Label, Button, FormGroup } from 'reactstrap';
import ReactDOM from 'react-dom';
import CampaignService from '../../services/CampaignService';
import Select, { components } from "react-select";
import lodash from 'lodash';
import { messages } from "../../constants/constant.message";
import ModalService from '../../services/ModalService';
import Validation from '../../services/Validation';
const { ERROR_MESSAGE } = messages.COMMON;
class CampaignSecondaryUserFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isMultiSelectedVehicleType: false,
      controls: {
        state_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        vehicle_type: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        brand_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        filter_vehicle_type_brand_model_ids: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        min: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        max: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        filter_warranty_registered_by_month: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        filter_warranty_registered_by_year: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        }
      },
      serachValueState: '',
      serachValueCity: '',
      serachValueMake: '',
      serachValueModel: '',
      dealerData: [],
      stateData: [],
      cityData: [],
      makeData: [],
      vehicleModelData: [],
      vehicleTypeData: [],
      vehicleCategories: [
        { label: 'All', value: [] },
        { label: 'Personal', value: 'personal' },
        { label: 'Taxi', value: 'taxi' }
      ],
      vehicleCategoriesLCVSCV: [
        { label: 'All', value: [] },
        { label: 'Passenger', value: 'passenger' },
        { label: 'Cargo', value: 'cargo' }
      ],
      isDisplayDealer: true,
      counts: 0,
      jkSecondaryUserFilterData: {},
      isSetDataFirstTime: true,
      isFormValid: false,
      years: [],
      months: [{ label: 'Jan', value: 1 },
      { label: 'Feb', value: 2 }, { label: 'Mar', value: 3 }, { label: 'Apr', value: 4 }, { label: 'May', value: 5 },
      { label: 'Jun', value: 6 }, { label: 'July', value: 7 }, { label: 'Aug', value: 8 }, { label: 'Sept', value: 9 },
      { label: 'Oct', value: 10 }, { label: 'Nov', value: 11 }, { label: 'Dec', value: 12 }]
    }
    if (props.jkSecondaryUserFilterData && Object.keys(props.jkSecondaryUserFilterData).length > 0) {
      this.state.jkSecondaryUserFilterData = props.jkSecondaryUserFilterData;
      if (props.jkSecondaryUserFilterData.counts) {
        this.state.counts = props.jkSecondaryUserFilterData.counts;
      }
      //Set Props FLeet Data
      if (this.state.jkSecondaryUserFilterData.filter_vehicle_categories.length === 0) {
        this.state.vehicleCategories[0].isChecked = true;
        this.state.vehicleCategoriesLCVSCV[0].isChecked = true;
      } else {
        if (this.state.jkSecondaryUserFilterData.filter_vehicle_categories) {
          this.state.jkSecondaryUserFilterData.filter_vehicle_categories.forEach(filter_vehicle_categories => {
            this.state.vehicleCategories.forEach(element => {
              if (element.value === filter_vehicle_categories) {
                element.isChecked = true;
              }
            });
            this.state.vehicleCategoriesLCVSCV.forEach(element => {
              if (element.value === filter_vehicle_categories) {
                element.isChecked = true;
              }
            });
          });
        }
      }
      this.state.controls.min.value = props.jkSecondaryUserFilterData.filter_vehicle_monthly_usage_min === 0 ? '0' : props.jkSecondaryUserFilterData.filter_vehicle_monthly_usage_min;
      this.state.controls.max.value = props.jkSecondaryUserFilterData.filter_vehicle_monthly_usage_max === 0 ? '0' : props.jkSecondaryUserFilterData.filter_vehicle_monthly_usage_max;
      this.state.controls.filter_warranty_registered_by_month.value = props.jkSecondaryUserFilterData.filter_warranty_registered_by_month ? props.jkSecondaryUserFilterData.filter_warranty_registered_by_month : '';
      this.state.controls.filter_warranty_registered_by_year.value = props.jkSecondaryUserFilterData.filter_warranty_registered_by_year ? props.jkSecondaryUserFilterData.filter_warranty_registered_by_year : '';
    } else {
      this.state.vehicleCategories[0].isChecked = true;
      this.state.vehicleCategoriesLCVSCV[0].isChecked = true;
    }

    let date = new Date();
    let currentYear = date.getFullYear();
    let startYear = 2019;
    let count = currentYear - startYear;
    for (var i = 0; i <= count; i++) {
      this.state.years.push(startYear);
      startYear++;
    }
  }
  componentDidMount() {
    this.getState();
    this.getDealerClassication();
    this.getVehicleType();
    document.body.style.overflow = 'hidden';
  }
  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }
  async getVehicleModel(ids) {
    try {
      let { controls, vehicleModelData, jkSecondaryUserFilterData } = this.state;
      this.setState({ isLoading: true });
      const { data } = await CampaignService.getVehicalModel({
        vehicle_type_brand_id: ids
      });
      if (data && data.data.length > 0) {
        vehicleModelData = data.data;
        if (jkSecondaryUserFilterData && Object.keys(jkSecondaryUserFilterData).length > 0) {
          if (jkSecondaryUserFilterData.filter_vehicle_type_brand_model_ids &&
            jkSecondaryUserFilterData.filter_vehicle_type_brand_model_ids.length > 0) {
            jkSecondaryUserFilterData.filter_vehicle_type_brand_model_ids.forEach(filter_vehicle_type_brand_model_ids => {
              controls.filter_vehicle_type_brand_model_ids.value.push({
                model_id: filter_vehicle_type_brand_model_ids,
                name: "",
                quality: "",
                short_number: 0
              });
            });
          }
        }
      } else {
        vehicleModelData = [];
      }
      this.setState(prevState => {
        return { ...prevState, vehicleModelData, controls, isLoading: false };
      });
    } catch (error) {
      this.setState(prevState => {
        return { ...prevState, isLoading: false };
      });
      console.log(error);
    }
  }


  getVehicleType = () => {
    this.setState({ isLoading: true });
    CampaignService.getVehicleType()
      .then(res => {
        let { vehicleTypeData } = this.state;
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.splice(0, 0, {
            image_url: "",
            name: "All",
            vehicle_type_id: []
          });
          vehicleTypeData = res.data.data;
          //Set Props Data
          if (this.state.isSetDataFirstTime && this.state.jkSecondaryUserFilterData && Object.keys(this.state.jkSecondaryUserFilterData).length > 0) {
            if (this.state.jkSecondaryUserFilterData.filter_vehicle_type_ids.length === 0) {
              vehicleTypeData[0].isChecked = true;
              this.getVehicleMake([]);
            } else {
              let vehicleId = [];
              if (this.state.jkSecondaryUserFilterData.filter_vehicle_type_ids) {
                this.state.jkSecondaryUserFilterData.filter_vehicle_type_ids.forEach(filter_vehicle_type_ids => {
                  vehicleTypeData.forEach(element => {
                    if (element.vehicle_type_id === filter_vehicle_type_ids) {
                      element.isChecked = true;
                      vehicleId.push(element.vehicle_type_id);
                    }
                  });
                });
              }
              this.getVehicleMake(vehicleId);
            }
          } else {
            vehicleTypeData[0].isChecked = true;
            this.getVehicleMake([]);
          }
        } else {
          vehicleTypeData = [];
        }
        this.setState({
          vehicleTypeData,
          isLoading: false
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };
  getState = () => {
    this.setState({ isLoading: true });
    CampaignService.getState()
      .then(data => {
        let { stateData, jkSecondaryUserFilterData, controls } = this.state;
        let { data: states } = data.data;
        // states.splice(0, 0, {
        //   "name": "All",
        //   "state_id": [],
        //   "code": "All",
        //   "is_own_state": false
        // });
        stateData = states;

        if (jkSecondaryUserFilterData && Object.keys(jkSecondaryUserFilterData).length > 0) {
          if (jkSecondaryUserFilterData.filter_state_ids &&
            jkSecondaryUserFilterData.filter_state_ids.length > 0) {
            let state_id = [];
            jkSecondaryUserFilterData.filter_state_ids.forEach(filter_state_ids => {
              stateData.forEach(element => {
                if (element.state_id === filter_state_ids) {
                  controls.state_id.value.push(element);
                  state_id.push(element.state_id);
                }
              });
            });
            this.getCity(state_id);
          }
        }
        this.setState({ stateData, controls, isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  }

  getCity = (stateId) => {
    let reqData = {
      state_id: stateId
    }
    this.setState({ isLoading: true });
    CampaignService.getCity(reqData)
      .then(data => {
        let { cityData, jkSecondaryUserFilterData, controls } = this.state;
        const { data: cities } = data.data;
        cityData = cities;

        // cityData.splice(0, 0, {
        //   "name": "All",
        //   "city_id": [],
        //   "city_rank": 1,
        //   "is_own_city": false
        // });

        if (this.state.isSetDataFirstTime && jkSecondaryUserFilterData && Object.keys(jkSecondaryUserFilterData).length > 0) {
          if (jkSecondaryUserFilterData.filter_city_ids &&
            jkSecondaryUserFilterData.filter_city_ids.length > 0) {
            jkSecondaryUserFilterData.filter_city_ids.forEach(filter_city_ids => {
              controls.city_id.value.push({
                "name": "",
                "city_id": filter_city_ids,
                "city_rank": 1,
                "is_own_city": false
              });
            });
          }
        }
        this.setState({ cityData, controls, isLoading: false });

      })
      .catch(e => {
        console.error('e', e);
        this.setState({ isLoading: false });
      });
  }
  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { min, max, filter_warranty_registered_by_month, filter_warranty_registered_by_year } = controls;
    if (min.touched === true || isSubmit) {
      min = Validation.notNullValidator(min);
      max = Validation.notNullValidator(max);
      min.isNotValidMinMax = false;
      max.isNotValidMinMax = false;
      if (!min.nullValue && !max.nullValue) {
        if (min.value === 0 || min.value === '0') {
          min.valid = false;
        } else if (parseInt(min.value) > parseInt(max.value)) {
          min.valid = false;
          min.isNotValidMinMax = true;
        } else {
          min.valid = true;
        }
      } else if (!min.nullValue) {
        if (min.value === 0 || min.value === '0') {
          min.valid = false;
        } else {
          min.valid = true;
          if (max.nullValue && (max.touched === true || isSubmit)) {
            max.showErrorMsg = true;
            max.valid = false;
          }
        }
      } else {
        min.valid = true;
      }
      if (!min.isNotValidMinMax) {
        if (min.valid === false) {
          min.showErrorMsg = true;
        } else {
          min.showErrorMsg = false;
        }
      }
    }


    if (max.touched === true || isSubmit) {
      min = Validation.notNullValidator(min);
      max = Validation.notNullValidator(max);
      if (!min.nullValue && !max.nullValue) {
        if (max.value === 0 || max.value === '0') {
          max.valid = false;
        } else if (parseInt(max.value) < parseInt(min.value)) {
          max.isNotValidMinMax = true;
          max.valid = false;
        } else {
          max.valid = true;
        }
      } else if (!max.nullValue) {
        if (max.value === 0 || max.value === '0') {
          max.valid = false;
        } else {
          max.valid = true;
          if ((min.nullValue && min.touched === true) || isSubmit) {
            min.showErrorMsg = true;
            min.valid = false;
          }
        }
      } else {
        if (!min.nullValue) {
          max.valid = false;
          max.showErrorMsg = true;
        } else {
          max.valid = true;
        }
      }
      if (!max.isNotValidMinMax) {
        if (max.valid === false) {
          max.showErrorMsg = true;
        } else {
          max.showErrorMsg = false;
        }
      } else {
        max.showErrorMsg = false;
      }
    }
    filter_warranty_registered_by_month.valid = true;
    filter_warranty_registered_by_year.valid = true;
    filter_warranty_registered_by_month.showErrorMsg = false;
    filter_warranty_registered_by_year.showErrorMsg = false;
    if (filter_warranty_registered_by_month.touched === true || isSubmit) {
      filter_warranty_registered_by_month = Validation.notNullValidator(filter_warranty_registered_by_month);
      filter_warranty_registered_by_year = Validation.notNullValidator(filter_warranty_registered_by_year);
      if (!filter_warranty_registered_by_month.nullValue && !filter_warranty_registered_by_year.nullValue) {
        let date = new Date();
        if (date.getFullYear() === parseInt(filter_warranty_registered_by_year.value)) {
          let currentMonth = date.getMonth() + 1;
          if (parseInt(filter_warranty_registered_by_month.value) > currentMonth) {
            filter_warranty_registered_by_month.valid = false;
            filter_warranty_registered_by_month.showErrorMsg = true;
          }
        }
      } else if (!filter_warranty_registered_by_month.nullValue && filter_warranty_registered_by_year.nullValue) {
        filter_warranty_registered_by_year.showErrorMsg = true;
        filter_warranty_registered_by_year.valid = false;
      } else if (filter_warranty_registered_by_month.nullValue && !filter_warranty_registered_by_year.nullValue) {
        filter_warranty_registered_by_month.showErrorMsg = true;
        filter_warranty_registered_by_month.valid = false;
      }
    }



    if (min.valid === true && max.valid === true &&
      filter_warranty_registered_by_year.valid === true &&
      filter_warranty_registered_by_month.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    this.setState({
      controls
    });

    return isFormValid;
  }
  handleInputChange = (e, index = -1) => {
    const controlName = e.target.name;
    let controlValue = e.target.value;
    let { stateData, cityData, controls, dealerData, vehicleTypeData,
      makeData, vehicleCategories, vehicleCategoriesLCVSCV, vehicleModelData, isSetDataFirstTime } = this.state;
    isSetDataFirstTime = false;
    if (controlName === 'state_id') {
      if (this.refCity) {
        this.refCity.onMenuClose();
      }
      controls.city_id.value = [];
      controls[controlName].value = controlValue;
      cityData = [];
      if (controlValue === null) {
        controlValue = [];
        controls[controlName].value = controlValue;
      }
      if (controlValue && controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = stateData;
            this.refState.onMenuClose();
          } else if (controlValue.length === stateData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = stateData;
          this.refState.onMenuClose();
        } else if (controlValue.length === stateData.length - 1) {
          controls[controlName].value = [];
          this.refState.onMenuClose();
        }
      }
      let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
      if (index_all > -1) {
        this.getCity([]);
      } else {
        let state_id = [];
        controls[controlName].value.forEach(element => {
          state_id.push(element.state_id);
        });
        this.getCity(state_id);
      }
      if (this.state.serachValueState) {
        this.onChangeFilter({ target: { name: 'serachValueState', value: '' } })
      }
    } else if (controlName === 'city_id') {
      this.refState.onMenuClose();
      controls[controlName].value = controlValue;
      if (controlValue && controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = cityData;
            this.refCity.onMenuClose();
          } else if (controlValue.length === cityData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = cityData;
          this.refCity.onMenuClose();
        } else if (controlValue.length === cityData.length - 1) {
          controls[controlName].value = [];
          this.refCity.onMenuClose();
        }
      }
      if (this.state.serachValueCity) {
        this.onChangeFilter({ target: { name: 'serachValueCity', value: '' } })
      }
    } else if (controlName === 'dealer') {
      dealerData[index].isChecked = !dealerData[index].isChecked;
      if (index === 0) {
        dealerData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      }
      else if (index > 0) {
        dealerData[0].isChecked = false;
      }
    } else if (controlName === 'vehiclecategory') {
      vehicleCategories[index].isChecked = !vehicleCategories[index].isChecked;
      vehicleCategoriesLCVSCV[index].isChecked = !vehicleCategoriesLCVSCV[index].isChecked;
      if (index === 0) {
        vehicleCategories.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
        vehicleCategoriesLCVSCV.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      }
      else if (index > 0) {
        vehicleCategories[0].isChecked = false;
        vehicleCategoriesLCVSCV[0].isChecked = false;
      }
    } else if (controlName === 'vehicle_type') {
      vehicleTypeData[index].isChecked = !vehicleTypeData[index].isChecked;
      if (index === 0) {
        vehicleTypeData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
            controls[controlName].value = [];
          }
        });
      }
      else if (index > 0) {
        vehicleTypeData[0].isChecked = false;
        if (vehicleTypeData[index].isChecked) {
          controls[controlName].value.push(vehicleTypeData[index].vehicle_type_id);
        } else {
          let i = controls[controlName].value.filter(value => value === vehicleTypeData[index].vehicle_type_id);
          let filteredValue = controls[controlName].value.filter(value => !i.includes(value))
          controls[controlName].value = filteredValue
        }
      }
      controls.brand_id.value = [];
      controls.filter_vehicle_type_brand_model_ids.value = [];
      vehicleModelData = [];
      makeData = [];

      let index_all = lodash.filter(vehicleTypeData, (element) => {
        return element.name === 'All';
      });
      if (index_all.length > 0 && index_all[0].isChecked) {
        this.getVehicleMake([]);
      } else {
        let brand_id = [];
        vehicleTypeData.forEach(element => {
          if (element.isChecked) {
            brand_id.push(element.vehicle_type_id);
          }
        });
        this.getVehicleMake(brand_id);
      }
    }
    else if (controlName === 'brand_id') {
      controls[controlName].value = controlValue;
      controls.filter_vehicle_type_brand_model_ids.value = [];
      vehicleModelData = [];
      if (controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = makeData;
            this.refMake.onMenuClose();
          } else if (controlValue.length === makeData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = makeData;
          this.refMake.onMenuClose();
        } else if (controlValue.length === makeData.length - 1) {
          controls[controlName].value = [];
          this.refMake.onMenuClose();
        }
      }


      let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
      if (index_all <= -1) {
        let ids = [];
        controls[controlName].value.forEach(element => {
          ids.push(element.brand_id);
        });
        this.getVehicleModel(ids);
      }
      if (this.state.serachValueMake) {
        this.onChangeFilter({ target: { name: 'serachValueMake', value: '' } })
      }
    } else if (controlName === 'max' || controlName === 'min') {
      if (!isNaN(e.target.value)) {
        controls[controlName].value = controlValue;
        controls[controlName].touched = true;
        this.setState({
          controls
        });
        this.handleValidation(false);
      }
    } else if (controlName === 'filter_warranty_registered_by_month' || controlName === 'filter_warranty_registered_by_year') {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
      this.setState({
        controls
      });
      this.handleValidation(false);

    } else if (controlName === 'filter_vehicle_type_brand_model_ids') {
      controls[controlName].value = controlValue;
      if (controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(x => x.name === 'All');
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = vehicleModelData;
            this.refModel.onMenuClose();
          } else if (controlValue.length === vehicleModelData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = vehicleModelData;
          this.refModel.onMenuClose();
        } else if (controlValue.length === vehicleModelData.length - 1) {
          controls[controlName].value = [];
          this.refModel.onMenuClose();
        }
      }
      if (this.state.serachValueModel) {
        this.onChangeFilter({ target: { name: 'serachValueModel', value: '' } })
      }
    } else {
      controls[controlName].value = controlValue;
    }
    this.setState({
      controls,
      cityData,
      makeData,
      vehicleModelData,
      isSetDataFirstTime
    });
  }

  getDealerClassication = () => {
    this.setState({ isLoading: false });
    CampaignService.getDealerClassication()
      .then(data => {
        if (data.data.data.customer_class) {
          data.data.data.customer_class.splice(0, 0, {
            "customer_class": "All",
            "customer_class_code": "all"
          });

          //Set Props Data
          if (this.state.isSetDataFirstTime && this.state.jkSecondaryUserFilterData && Object.keys(this.state.jkSecondaryUserFilterData).length > 0) {
            if (this.state.jkSecondaryUserFilterData.filter_dealer_classification.length === 0) {
              data.data.data.customer_class[0].isChecked = true;
            } else {
              if (this.state.jkSecondaryUserFilterData.filter_dealer_classification) {
                this.state.jkSecondaryUserFilterData.filter_dealer_classification.forEach(filter_dealer_classification => {
                  data.data.data.customer_class.forEach(element => {
                    if (element.customer_class_code === filter_dealer_classification) {
                      element.isChecked = true;
                    }
                  });
                });
              }
            }
          } else {
            data.data.data.customer_class[0].isChecked = true;
          }
        }
        this.setState({
          dealerData: data.data.data.customer_class,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        return err;
      });
  }

  getVehicleMake = (vehicle_type_id) => {
    let { isMultiSelectedVehicleType } = this.state;
    isMultiSelectedVehicleType = false;
    if (vehicle_type_id && (vehicle_type_id.length === 0 || vehicle_type_id.length >= 2)) {
      isMultiSelectedVehicleType = true;
    }
    let reqData = {
      "vehicle_type_id": vehicle_type_id
    }
    this.setState({ isLoading: true });
    CampaignService.getVehicalMake(reqData)
      .then(data => {
        let { controls, jkSecondaryUserFilterData } = this.state;
        if (data.data.data) {
          // data.data.data.splice(0, 0, {
          //   "name": "All",
          //   "short_number": 1,
          //   "quality": "premium",
          //   "brand_id": [],
          //   "image_url": "",
          //   "vehicle_type": ""
          // });

          if (this.state.isSetDataFirstTime && jkSecondaryUserFilterData && Object.keys(jkSecondaryUserFilterData).length > 0) {
            if (jkSecondaryUserFilterData.filter_vehicle_type_brand_ids &&
              jkSecondaryUserFilterData.filter_vehicle_type_brand_ids.length > 0) {
              let ids = [];
              jkSecondaryUserFilterData.filter_vehicle_type_brand_ids.forEach(filter_vehicle_type_brand_ids => {
                controls.brand_id.value.push({
                  "name": "All",
                  "short_number": 1,
                  "quality": "premium",
                  "brand_id": filter_vehicle_type_brand_ids,
                  "image_url": "",
                  "vehicle_type": ""
                });
                ids.push(filter_vehicle_type_brand_ids);
              });
              this.getVehicleModel(ids);
            }
          }
        }
        this.setState({
          makeData: data.data.data,
          controls,
          isMultiSelectedVehicleType,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        return err;
      });
  }


  onChangeFilter = (e) => {
    let controlName = e.target.name;
    this.setState({
      [controlName]: e.target.value
    })
  }
  ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.stateData.length === this.state.controls.state_id.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen && <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }} name="serachValueState" value={this.state.serachValueState} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };


  ValueContainerCity = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.cityData.length === this.state.controls.city_id.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen && <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }}
            value={this.state.serachValueCity}
            name="serachValueCity" onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };

  ValueContainerMake = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.makeData.length === this.state.controls.brand_id.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen && <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }} name="serachValueMake" onChange={(e) => this.onChangeFilter(e)}
            value={this.state.serachValueMake}
            placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };


  ValueContainerModel = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
          {/* <input type="text" style={{ border: 'none', outline: 'none' }} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' /> */}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.vehicleModelData.length === this.state.controls.filter_vehicle_type_brand_model_ids.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen && <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }} value={this.state.serachValueModel} name="serachValueModel" onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };



  customFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }




  getReqData = () => {
    let { dealerData, controls, vehicleTypeData, vehicleCategories, vehicleCategoriesLCVSCV } = this.state;
    let reqData = {
      "customer_target_type": "jk_secondary_point",
      filter_dealer_classification: [],
      filter_state_ids: [],
      filter_city_ids: [],
      filter_vehicle_type_ids: [],
      filter_vehicle_type_brand_ids: [],
      filter_vehicle_categories: [],
      filter_vehicle_type_brand_model_ids: []
    };
    let all = '';
    //dealerData
    if (dealerData.length > 0 && this.state.isDisplayDealer) {
      all = lodash.filter(dealerData, (element) => {
        return element.customer_class === 'All';
      });
      if (!all[0].isChecked) {
        dealerData.forEach(element => {
          if (element.isChecked) {
            reqData.filter_dealer_classification.push(element.customer_class_code);
          }
        });
      } else {
        reqData.filter_dealer_classification = [];
      }
    }

    //state Data
    if (controls.state_id.value.length > 0) {
      all = controls.state_id.value.findIndex(x => x.name === 'All');
      if (all <= -1) {
        controls.state_id.value.forEach(element => {
          reqData.filter_state_ids.push(element.state_id);
        });

      }
    }

    // city data
    if (controls.city_id.value.length > 0) {
      all = controls.city_id.value.findIndex(x => x.name === 'All');
      if (all <= -1) {
        controls.city_id.value.forEach(element => {
          reqData.filter_city_ids.push(element.city_id);
        });

      }
    }

    //filter_vehicle_type_ids
    if (vehicleTypeData.length > 0) {
      all = lodash.filter(vehicleTypeData, (element) => {
        return element.name === 'All';
      });
      if (!all[0].isChecked) {
        vehicleTypeData.forEach(element => {
          if (element.isChecked) {
            reqData.filter_vehicle_type_ids.push(element.vehicle_type_id);
          }
        });
      } else {
        reqData.filter_vehicle_type_ids = [];
      }
    }

    // vehicle make 
    if (controls.brand_id.value.length > 0) {
      controls.brand_id.value.forEach(element => {
        reqData.filter_vehicle_type_brand_ids.push(element.brand_id);
      });
    }

    // vehicle model 
    if (controls.filter_vehicle_type_brand_model_ids.value.length > 0) {
      controls.filter_vehicle_type_brand_model_ids.value.forEach(element => {
        reqData.filter_vehicle_type_brand_model_ids.push(element.model_id);
      });
    }

    // vehicleCategories
    if (vehicleCategories.length > 0) {
      all = lodash.filter(vehicleCategories, (element) => {
        return element.label === 'All';
      });
      if (!all[0].isChecked) {
        vehicleCategories.forEach(element => {
          if (element.isChecked) {
            reqData.filter_vehicle_categories.push(element.value);
          }
        });
      } else {
        reqData.filter_vehicle_categories = [];
      }
    }

    if (vehicleCategoriesLCVSCV.length > 0) {
      all = lodash.filter(vehicleCategoriesLCVSCV, (element) => {
        return element.label === 'All';
      });
      if (!all[0].isChecked) {
        vehicleCategoriesLCVSCV.forEach(element => {
          if (element.isChecked) {
            reqData.filter_vehicle_categories.push(element.value);
          }
        });
      } else {
        reqData.filter_vehicle_categories = [];
      }
    }

    // if (controls.min.value) {
    //   reqData.filter_vehicle_monthly_usage_min = +controls.min.value;
    // }

    // if (controls.max.value) {
    //   reqData.filter_vehicle_monthly_usage_max = +controls.max.value;
    // }
    reqData.filter_warranty_registered_by_year = controls.filter_warranty_registered_by_year.value ? +controls.filter_warranty_registered_by_year.value :
      null;
    reqData.filter_warranty_registered_by_month = controls.filter_warranty_registered_by_month.value ? +controls.filter_warranty_registered_by_month.value :
      null;

    return reqData;
  }

  getCustomerCount = () => {
    if (!this.handleValidation(true)) {
      return true;
    }
    let reqData = this.getReqData();
    this.setState({ isLoading: true });
    CampaignService.getCustomerCount(reqData)
      .then(data => {
        this.setState({
          counts: data.data.data.count,
          isLoading: false
        });
        if (data.data.data.count === 0) {
          ModalService.openAlert('', "There is no user found with applied filter . Please change filter criteria", 'error')
          return
        }
        let filterData = {
          counts: this.state.counts,
        };
        filterData = { ...reqData, ...filterData };
        this.props.applyFilter(filterData);
        // this.props.closeSideBar(false);
      })
      .catch(e => {
        this.setState({ isLoading: false });

        let err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert('', message, 'error')
      });
  }

  applyFilter = () => {
    this.getCustomerCount();
  }
  resetFilter = (flag) => {
    this.props.applyFilter({});
  }

  closeSideBar = () => {
    this.props.closeSideBar(false);
  }




  handleClickOutside = (event) => {
    if (this.refState) {
      const domNode = ReactDOM.findDOMNode(this.refState);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refState.state.menuIsOpen) {
          this.refState.onMenuClose();
        }
      }
    }

    if (this.refCity) {
      const domNode = ReactDOM.findDOMNode(this.refCity);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refCity.state.menuIsOpen) {
          this.refCity.onMenuClose();
        }
      }
    }


    if (this.refMake) {
      const domNode = ReactDOM.findDOMNode(this.refMake);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refMake.state.menuIsOpen) {
          this.refMake.onMenuClose();
        }
      }
    }

    if (this.refModel) {
      const domNode = ReactDOM.findDOMNode(this.refModel);
      if (!domNode || !domNode.contains(event.target)) {
        if (this.refModel.state.menuIsOpen) {
          this.refModel.onMenuClose();
        }
      }
    }
  }

  render() {
    let { controls, vehicleTypeData } = this.state;
    let { vehicle_type } = controls;
    const customStyles = {
      control: base => ({
        ...base,
        maxHeight: 70,
        overflow: 'auto',
        theme: 'primary',
        borderColor: '#fee19d',
        outline: '0',
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
      }),
      input: styles => ('text'),
    };

    const LCVIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'lcv');
    const LCVId = LCVIndex && LCVIndex > -1 ? vehicleTypeData[LCVIndex].vehicle_type_id : null;
    const SCVIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'scv');
    const SCVId = SCVIndex && SCVIndex > -1 ? vehicleTypeData[SCVIndex].vehicle_type_id : null;
    const CarIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'car');
    const CarId = CarIndex && CarIndex > -1 ? vehicleTypeData[CarIndex].vehicle_type_id : null;
    const TwoWheelIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === '2 wheeler');
    const TwoWheelId = TwoWheelIndex && TwoWheelIndex > -1 ? vehicleTypeData[TwoWheelIndex].vehicle_type_id : null;
    const TruckIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'truck');
    const TruckId = TruckIndex && TruckIndex > -1 ? vehicleTypeData[TruckIndex].vehicle_type_id : null;
    const BusIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'bus');
    const BusId = BusIndex && BusIndex > -1 ? vehicleTypeData[BusIndex].vehicle_type_id : null;
    const FarmIndex = vehicleTypeData && vehicleTypeData.findIndex(x => x && x.type && x.type.toLowerCase() === 'farm');
    const FarmId = FarmIndex && FarmIndex > -1 ? vehicleTypeData[FarmIndex].vehicle_type_id : null;

    const CustomOptionVehicleMake = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label
              className="container-cb pr-0" ref={innerRef} {...innerProps}
            >
              <input
                type="checkbox"
                name="cc_self"
                checked={props.isSelected === true || props.isSelected === 'true'}
                id="checkbox"
              />
              <span className="checkmarkrectangle" />
            </label>
            <div className="ml-2 d-flex-row" ref={innerRef} {...innerProps} style={{ marginTop: '-2px', flexWrap: 'wrap' }}>
              <span>{props.data.name}</span>
              {this.state.isMultiSelectedVehicleType && <span>&nbsp;{`(${props.data.vehicle_type})`}</span>}
            </div>
          </div>
        </>
      );
    };
    const CustomOption = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label
              className="container-cb pr-0" ref={innerRef} {...innerProps}
            >
              <input
                type="checkbox"
                name="cc_self"
                checked={props.isSelected === true || props.isSelected === 'true'}
                id="checkbox"
              />
              <span className="checkmarkrectangle" />
            </label>
            <span className="ml-2" ref={innerRef} {...innerProps} style={{ marginTop: '-2px' }}>{props.data.name}</span>
          </div>
        </>
      );
    };
    const CustomOptionCity = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label
              className="container-cb pr-0" ref={innerRef} {...innerProps}
            >
              <input
                type="checkbox"
                name="cc_self"
                checked={props.isSelected === true || props.isSelected === 'true'}
                id="checkbox"
              />
              <span className="checkmarkrectangle" />
            </label>
            <span className="ml-2" ref={innerRef} {...innerProps} style={{ marginTop: '-2px' }}>{props.data.name}</span>
          </div>
        </>
      );
    };
    return (
      <div class="custom-drawer" id="AddEditActivity">
        {this.state.isLoading && <CustomSpinner />}
        {/* <div style={{ marginTop: '60px' }}>
          <div className="drawer-header">
            <span className="drawer-title">JK Secondary User (warranty user)</span>
            <img className="cursor-pointer" src="images/close.svg" alt='cancel' onClick={() => this.closeSideBar(false)} />
          </div>
          <hr style={{ border: 'solid 1px #ced4da' }} />
        </div> */}
        <div class="custom-drawer-content" onClick={this.handleClickOutside}>
          <Row className="c-top-15" style={{ marginLeft: '0px', marginRight: '0px' }}>
            {
              this.state.isDisplayDealer && this.state.dealerData.length > 0 &&
              <>
                <Col xl="12" className="mb-2">
                  <span className="filter-type-lbl ml-0">Select Dealers</span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>
                <Col xl="12">
                  <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                    {
                      (this.state.dealerData || []).map((dealer, index) => (
                        <div className="d-flex" style={{ paddingRight: '20px' }}>
                          <label
                            className="container-cb pr-0"
                          >
                            <input
                              type="checkbox"
                              name="dealer"
                              checked={dealer.isChecked}
                              id="checkbox"
                              value={dealer.customer_class_code}
                              onChange={(e) => this.handleInputChange(e, index)}
                            />
                            &nbsp;{dealer.customer_class}
                            <span className="checkmarkrectangle" />
                          </label>
                        </div>
                      ))
                    }
                  </div>
                </Col>
              </>
            }

            <Col xl="4" md="12" xs="12">
              <Col xl="12" className="mb-2 pl-0">
                <span className="filter-type-lbl ml-0 pl-0">State</span>
                {/* <Label for="Target">Select Employee</Label> */}
              </Col>
              <FormGroup>
                <Select
                  {...this.props.input}
                  ref={r => this.refState = r}
                  name="state_id"
                  pageSize="1"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.state_id}
                  options={this.state.stateData}
                  allowSelectAll
                  closeMenuOnSelect={false}
                  value={controls.state_id.value}
                  tabSelectsValue={false}
                  isSearchable={true}
                  filterOption={this.customFilter}
                  onChange={(e) => this.handleInputChange({ target: { name: 'state_id', value: e } })}
                  onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueState', value: e } })}
                  placeholder="Search..."
                  styles={customStyles}
                  hideSelectedOptions={false}
                  inputValue={this.state.serachValueState}
                  components={{ Option: CustomOption, ValueContainer: this.ValueContainer }}
                  backspaceRemoves={false}
                  isMulti
                />
              </FormGroup>

            </Col>

            {
              this.state.cityData.length > 0 &&
              <Col xl="4" md="12" xs="12">
                <Col xl="12" className="mb-2 pl-0">
                  <span className="filter-type-lbl ml-0 pl-0">City</span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>
                <FormGroup>
                  {/* <Label for="Target">Region</Label> */}
                  <Select
                    {...this.props.input}
                    ref={r => this.refCity = r}
                    name="city_id"
                    pageSize="1"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.city_id}
                    options={this.state.cityData}
                    loadOptions={this.state.cityData}
                    allowSelectAll
                    closeMenuOnSelect={false}
                    value={controls.city_id.value}
                    tabSelectsValue={false}
                    isSearchable={true}
                    filterOption={this.customFilter}
                    onChange={(e) => this.handleInputChange({ target: { name: 'city_id', value: e } })}
                    placeholder="Search..."
                    styles={customStyles}
                    hideSelectedOptions={false}
                    onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueCity', value: e } })}
                    inputValue={this.state.serachValueCity}
                    components={{ Option: CustomOptionCity, ValueContainer: this.ValueContainerCity }}
                    backspaceRemoves={false}
                    isMulti
                  />
                </FormGroup>

              </Col>

            }
            {
              vehicleTypeData.length > 0 &&
              <>
                <Col xl="12" className="mb-2">
                  <span className="filter-type-lbl ml-0">Select Vehicle Type</span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>
                <Col xl="12">
                  <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                    {
                      vehicleTypeData.map((vehicle, index) => (
                        <div className="d-flex" style={{ paddingRight: '20px' }}>
                          <label
                            className="container-cb pr-0"
                          >
                            <input
                              type="checkbox"
                              name="vehicle_type"
                              checked={vehicle.isChecked}
                              id="checkbox"
                              value={vehicle.name}
                              onChange={(e) => this.handleInputChange(e, index)}
                            />
                            &nbsp;{vehicle.name}
                            <span className="checkmarkrectangle" />
                          </label>
                        </div>
                      ))
                    }
                  </div>
                </Col>
              </>
            }
            {
              this.state.makeData.length > 0 &&
              <Col xl="4" md="12" xs="12">
                <Col xl="12" className="mb-2 pl-0">
                  <span className="filter-type-lbl ml-0 pl-0">Select Vehicle Make</span>
                </Col>
                <FormGroup>

                  <Select
                    {...this.props.input}
                    ref={r => this.refMake = r}
                    name="brand_id"
                    pageSize="1"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.brand_id}
                    options={this.state.makeData}
                    allowSelectAll
                    closeMenuOnSelect={false}
                    value={controls.brand_id.value}
                    tabSelectsValue={false}
                    isSearchable={true}
                    filterOption={this.customFilter}
                    onChange={(e) => this.handleInputChange({ target: { name: 'brand_id', value: e } })}
                    placeholder="Search..."
                    styles={customStyles}
                    hideSelectedOptions={false}
                    inputValue={this.state.serachValueMake}
                    onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueMake', value: e } })}
                    components={{ Option: CustomOptionVehicleMake, ValueContainer: this.ValueContainerMake }}
                    backspaceRemoves={false}
                    isMulti
                  />
                </FormGroup>

              </Col>
            }

            {
              this.state.vehicleModelData.length > 0 &&
              <Col xl="4" md="12" xs="12">
                <Col xl="12" className="mb-2 pl-0">
                  <span className="filter-type-lbl ml-0 pl-0">Select Vehicle Model</span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>
                <FormGroup>
                  {/* <Label for="Target">Region</Label> */}
                  <Select
                    {...this.props.input}
                    ref={r => this.refModel = r}
                    name="filter_vehicle_type_brand_model_ids"
                    pageSize="1"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.model_id}
                    options={this.state.vehicleModelData}
                    allowSelectAll
                    closeMenuOnSelect={false}
                    value={controls.filter_vehicle_type_brand_model_ids.value}
                    tabSelectsValue={false}
                    isSearchable={true}
                    filterOption={this.customFilter}
                    onChange={(e) => this.handleInputChange({ target: { name: 'filter_vehicle_type_brand_model_ids', value: e } })}
                    placeholder="Search..."
                    styles={customStyles}
                    hideSelectedOptions={false}
                    inputValue={this.state.serachValueModel}
                    onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueModel', value: e } })}
                    components={{ Option: CustomOption, ValueContainer: this.ValueContainerModel }}
                    backspaceRemoves={false}
                    isMulti
                    onBlur={() => {
                      this.refMake.select.setState({ isOpen: false });
                    }}
                  />
                </FormGroup>

              </Col>
            }


            <>
              {(vehicle_type.value.length === 0) && <Col xl="12" className="mb-2">
                <span className="filter-type-lbl ml-0">Select Vehicle Category</span>
                {/* <Label for="Target">Select Employee</Label> */}
              </Col>}
              <Col xl="12">
                {(vehicle_type.value.length === 0) &&
                  <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                    {
                      (this.state.vehicleCategories || []).map((category, index) => (
                        <div className="d-flex" style={{ paddingRight: '20px' }}>
                          <label
                            className="container-cb pr-0"
                          >
                            <input
                              type="checkbox"
                              name="vehiclecategory"
                              checked={category.isChecked}
                              id="checkbox"
                              value={category.customer_class_code}
                              onChange={(e) => this.handleInputChange(e, index)}
                            />
                            &nbsp;{category.label}
                            <span className="checkmarkrectangle" />
                          </label>
                        </div>
                      ))
                    }
                  </div>
                }
              </Col>
              {(vehicle_type.value.length >= 1 && !vehicle_type.value.includes(LCVId) && !vehicle_type.value.includes(SCVId) && !vehicle_type.value.includes('all')) &&
                <Col xl="12" className="mb-2">
                  <span className="filter-type-lbl ml-0">Select Vehicle Category</span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>}
              <Col xl="12">
                {(vehicle_type.value.length >= 1 && !vehicle_type.value.includes(LCVId) && !vehicle_type.value.includes(SCVId) && !vehicle_type.value.includes('all')) &&
                  <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                    {
                      (this.state.vehicleCategories || []).map((category, index) => (
                        <div className="d-flex" style={{ paddingRight: '20px' }}>
                          <label
                            className="container-cb pr-0"
                          >
                            <input
                              type="checkbox"
                              name="vehiclecategory"
                              checked={category.isChecked}
                              id="checkbox"
                              value={category.customer_class_code}
                              onChange={(e) => this.handleInputChange(e, index)}
                            />
                            &nbsp;{category.label}
                            <span className="checkmarkrectangle" />
                          </label>
                        </div>
                      ))
                    }
                  </div>
                }

              </Col>
              {(vehicle_type.value.length >= 1 && !vehicle_type.value.includes(CarId) && !vehicle_type.value.includes(TwoWheelId) && !vehicle_type.value.includes(TruckId)
                && !vehicle_type.value.includes(BusId) && !vehicle_type.value.includes(FarmId) && !vehicle_type.value.includes('all')) &&
                <Col xl="12" className="mb-2">
                  <span className="filter-type-lbl ml-0">Select Vehicle Category</span>
                  {/* <Label for="Target">Select Employee</Label> */}
                </Col>}
              <Col xl="12">
                {(vehicle_type.value.length >= 1 && !vehicle_type.value.includes(CarId) && !vehicle_type.value.includes(TwoWheelId) && !vehicle_type.value.includes(TruckId)
                  && !vehicle_type.value.includes(BusId) && !vehicle_type.value.includes(FarmId) && !vehicle_type.value.includes('all')) &&
                  <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                    {
                      (this.state.vehicleCategoriesLCVSCV || []).map((category, index) => (
                        <div className="d-flex" style={{ paddingRight: '20px' }}>
                          <label
                            className="container-cb pr-0"
                          >
                            <input
                              type="checkbox"
                              name="vehiclecategory"
                              checked={category.isChecked}
                              id="checkbox"
                              value={category.customer_class_code}
                              onChange={(e) => this.handleInputChange(e, index)}
                            />
                            &nbsp;{category.label}
                            <span className="checkmarkrectangle" />
                          </label>
                        </div>
                      ))
                    }
                  </div>
                }
              </Col>
            </>

          </Row>
          <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
            <Col xl="12" className="mb-2">
              <span className="filter-type-lbl ml-0">Select Year And Month</span>
              {/* <Label for="Target">Select Employee</Label> */}
            </Col>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label for="common">Year</Label>
                <select className="form-control" name="filter_warranty_registered_by_year"
                  value={controls.filter_warranty_registered_by_year.value}
                  onChange={(e) => this.handleInputChange(e)}>
                  <option value=''>Select Year</option>
                  {
                    this.state.years.map((item, i) => (
                      <option value={item} selected={this.state.currentYear === item}>{item}</option>
                    ))
                  }
                </select>
                {controls.filter_warranty_registered_by_year.showErrorMsg && (
                  <div className="error">
                    *Please select the valid year
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label for="common">Month</Label>
                <select className="form-control" name="filter_warranty_registered_by_month"
                  style={{ width: '140px' }}
                  value={controls.filter_warranty_registered_by_month.value} onChange={(e) => this.handleInputChange(e)}>
                  <option value=''>Select Month</option>
                  {
                    this.state.months.map((item, i) => (
                      <option value={item.value}>{item.label}</option>
                    ))
                  }
                </select>
                {controls.filter_warranty_registered_by_month.showErrorMsg && (
                  <div className="error">
                    *Please select the valid month
                  </div>
                )}
              </FormGroup>
            </Col>

          </Row>
          {/* <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
            <Col xl="12" className="mb-2">
              <span className="filter-type-lbl ml-0">Enter Vehicle Monthly Usage(KMs)</span>
              
            </Col>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label for="common">Min</Label>
                <Input
                  type="text"
                  name="min"
                  id="min"
                  autoComplete="nofill"
                  value={controls.min.value ? controls.min.value : ''}
                  onChange={(e) => this.handleInputChange(e, -1)}
                  placeholder="Type here" />
                {controls.min.showErrorMsg && (
                  <div className="error">
                    *Please enter the valid value
                      </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="3" md="12" xs="12">
              <FormGroup>
                <Label for="common">Max</Label>
                <Input
                  type="text"
                  name="max"
                  id="max"
                  autoComplete="nofill"
                  value={controls.max.value ? controls.max.value : ''}
                  onChange={(e) => this.handleInputChange(e, -1)}
                  placeholder="Type here" />
                {controls.max.showErrorMsg && (
                  <div className="error">
                    *Please enter the valid value
                      </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="12" md="12" xs="12" className="mt-n3">
              {!controls.max.showErrorMsg && !controls.min.showErrorMsg &&
                (controls.min.isNotValidMinMax || controls.max.isNotValidMinMax) && (
                  <div className="error">
                    *Maximum value should be greater then minimum value
                      </div>
                )}
            </Col>
          </Row> */}
          <br /><br />
        </div>
        <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter(true)} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
            <Button
              //  disabled={!isValidForm} 
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: 'pointer' }}
              onClick={() => this.applyFilter()}
            >Apply
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CampaignSecondaryUserFilter;