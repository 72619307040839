import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Input,
} from "reactstrap";
import Pagination from "../../components/Pagination/Pagination";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { downlodFile } from "../../utils/utils";
import lodash from "lodash"; // library added for debounce functionality
import Sidebar from "react-sidebar";
import CampaignService from "../../services/CampaignService";
import './OrderHistory.css'
import OrderHistoryFilterDealer from "../../components/OrderHistoryFilter/OrderHistoryFilterDealer";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { Permissions } from "../../constants/constant.permissions";
const pageSize = 10;

class OrderHistory extends Component {


  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalPages: 0,
      orderData: [],
      totalOrderCounts: 0,
      filterApplied: false,
      openCustomerManagementFilter: false,
      pageSize: 10,
      controls_filter: {},
      is_warranty_points_redemption: false,
      userDetail: Storage.getUserDetail()
    };
    if (this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.WARRANTY_POINTS_REDEMPTION)) {
      this.state.is_warranty_points_redemption = true;
    }
    this.searchReport = lodash.debounce(this.searchReport, 500);
  }

  componentDidMount() {
    GoogleAnalticsService.pageView('Order History View');
    this.getOrderHistory(false);
  }
  searchReport = (search) => {
    this.setState({
      search: search
    }, () => {
      this.getOrderHistory();
    })
  };

  handlePageChange = (page) => {
    this.setState({ page: page }, () => {
      this.getOrderHistory(false);
    });
  };

  getFilterData = () => {
    let reqData = {};
    let { controls_filter } = this.state;
    if (controls_filter.orderStartDate.value) {
      reqData.orderStartDate = controls_filter.orderStartDate.value;
    }
    if (controls_filter.orderStartDate.value) {
      let date = new Date(controls_filter.orderStartDate.value);
      date.setHours(0, 0, 0, 0);
      reqData.startDate = date.toISOString();
    }
    if (controls_filter.orderEndDate.value) {
      let date = new Date(controls_filter.orderEndDate.value);
      date.setHours(23, 59, 59, 59);
      reqData.endDate = date.toISOString();
    }
    if (controls_filter.amountStart.value) {
      reqData.amountStart = controls_filter.amountStart.value;
    }
    if (controls_filter.amountEnd.value) {
      reqData.amountEnd = controls_filter.amountEnd.value;
    }
    if (controls_filter.pointStart.value) {
      reqData.pointStart = controls_filter.pointStart.value;
    }
    if (controls_filter.pointEnd.value) {
      reqData.pointEnd = controls_filter.pointEnd.value;
    }
    if (controls_filter.paymentMode.value && controls_filter.paymentMode.value !== 'all') {
      reqData.paymentMode = controls_filter.paymentMode.value;
    }
    return reqData;
  }

  downloadOrderInvoice = (id) => {
    if (!id) {
      return
    }
    GoogleAnalticsService.event('Order History', 'Invoice Download');
    this.setState({ isLoading: true });
    CampaignService.downloadOrderInvoice({ orderId: id })
      .then((data) => {
        if (data.data.data) {
          let { file } = data.data.data;
          if (file) {
            downlodFile(file);
          } else {
            ModalService.openAlert("", 'Not have invoice', "error");
          }
        } else {
          ModalService.openAlert("", 'Not have invoice', "error");
        }
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  getOrderHistory(downloadReport = null) {
    this.setState({ isLoading: true });
    let reqData = {
      page: this.state.page,
      limit: this.state.pageSize,
      q: this.state.search
    }
    if (downloadReport) {
      reqData.export = true;
    }
    let { controls_filter } = this.state;
    if (controls_filter && Object.keys(controls_filter).length > 0) {
      reqData = { ...reqData, ...this.getFilterData() };
    }
    CampaignService.getOrderHistory(reqData)
      .then((data) => {
        this.setState({ isLoading: false });
        if (downloadReport === true) {
          let { file } = data.data.data;
          downlodFile(file);
          return;
        }
        const { orders, count } = data.data.data;
        const orderData = orders;
        const totalOrderCounts = count;
        const totalPages = Math.ceil(totalOrderCounts / pageSize);
        this.setState({ orderData, totalOrderCounts, totalPages });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  downloadReport = () => {
    GoogleAnalticsService.event('Order History', 'Order History Download Report');
    this.getOrderHistory(true);
  };

  handleSearchChange = (e) => {
    let searchVal = e.target.value;
    this.setState({ search: searchVal, page: 1 });
    this.searchReport(searchVal);
  };

  applyDealerFilter = (controls = {}) => {
    let { controls_filter, filterApplied } = this.state;
    if (controls && Object.keys(controls).length > 0) {
      controls_filter = Object.assign({}, controls);
      filterApplied = true;
    } else {
      controls_filter = {};
      filterApplied = false;
    }
    this.setState({
      controls_filter,
      filterApplied,
      openCustomerManagementFilter: false,
      page: 1,
      search: ''
    }, () => {
      this.getOrderHistory()
    })
  }

  closeOrderHistoryFilterDealer = () => {
    this.setState({
      openCustomerManagementFilter: false
    })
  }

  openCustomerManagementFilter = () => {
    this.setState({
      openCustomerManagementFilter: true
    })
  }

  render() {
    const {
      orderData,
      search,
      totalOrderCounts,
      totalPages,
    } = this.state;
    const prepareRow = orderData.map((w, index) => (
      <tr>
        <td>{w.Date}</td>
        <td>{w['Order ID']}</td>
        <td>{w['Product Name']}</td>
        <td>{w['Product Qty']}</td>
        <td>{w['Total amount'] ? w['Total amount'] : '--'}</td>
        {
          this.state.is_warranty_points_redemption &&
          <td>{w['Points redemption'] ? w['Points redemption'] : '--'}</td>
        }
        <td>{w['Mode Of Payment'] ? w['Mode Of Payment'] : '--'}</td>
        <td>{w['Transaction Status'] ? w['Transaction Status'] : '--'}</td>
        {/* <td>{w['Payment Received date'] ? w['Payment Received date'] : '--'}</td> */}
        <td>{
          w['Invoice No.'] && w['Transaction Status'] === 'Success' &&
            w['Mode Of Payment'] === 'Online' ?
            <span className="link-blue cursor-pointer" onClick={() => this.downloadOrderInvoice(w['Order ID'] ? w['Order ID'] : null)}>{w['Invoice No.']}</span>
            : '--'}</td>
      </tr>
    ));
    return (
      <Fragment>
        <span className="header-title">Order History</span>
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        {this.state.openCustomerManagementFilter && (
          <Sidebar
            sidebar={
              <>
                <OrderHistoryFilterDealer
                  applyDealerFilter={this.applyDealerFilter}
                  controls_filter={this.state.controls_filter}
                  is_warranty_points_redemption={this.state.is_warranty_points_redemption}
                  closeOrderHistoryFilterDealer={this.closeOrderHistoryFilterDealer} />
              </>
            }
            open={true}
            sidebarClassName="campaign-drawer"
            pullRight
            styles={{
              sidebar: {
                background: "white",
                height: "90%",
                top: "60px",
                width: "36%",
                borderRadius: "2px",
              },
            }}
          />
        )}
        <div id="order-history" style={{ marginTop: "20px" }}>
          <Card>
            <CardBody className="overflow-auto">
              <Row >
                <Col xs="12" className="mb-3">
                  <Row className="m-0 justify-content-between">
                    <div className="d-flex" style={{ width: '37%' }}>
                      <Input
                        placeholder="Search by Invoice Number, OrderId, Item Description"
                        className="report-search-input"
                        onChange={this.handleSearchChange}
                        style={{ paddingRight: '30px' }}
                        value={search}
                      ></Input>
                      <img src="images/search.svg"
                        alt=""
                        className="icon-width"
                        style={{ marginLeft: '-28px' }} />

                      <img
                        src={this.state.filterApplied === true ? "images/smaller-blue-dot-hi.png" : "images/filter.svg"}
                        alt=""
                        className={`cursor-pointer ${this.state.filterApplied === true
                          ? 'filter-icon-selected mt-auto mb-auto'
                          : 'filter-icon mt-auto mb-auto'}`
                        }
                        style={{ marginLeft: '30px' }}
                        onClick={() => this.openCustomerManagementFilter()}
                      />
                    </div>
                    {orderData.length > 0 &&
                      (
                        <span
                          className="download-report cursor-pointer mt-auto mb-auto"
                          onClick={this.downloadReport}
                        >
                          {" "}
                          Download Orders
                        </span>
                      )}
                  </Row>
                </Col>
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th>Order Date</th>
                        <th>Order ID</th>
                        <th>Item Description</th>
                        <th>Quantity</th>
                        <th>Amount INR</th>
                        {this.state.is_warranty_points_redemption &&
                          <th>Point Redeemed</th>
                        }
                        <th>Payment Mode</th>
                        <th>Payment Status</th>
                        {/* <th>Payment Date</th> */}
                        <th>Invoice No</th>
                      </tr>
                    </thead>
                    <tbody>{prepareRow}</tbody>
                  </Table>
                  {orderData.length === 0 && (
                    <div className="text-align-center card-point-header">
                      {
                        this.state.filterApplied ? 'There is no order found with applied filter. Please change filter criteria.'
                          : 'Currently, there are not any order to display.'
                      }
                    </div>
                  )}
                  {totalPages > 1 && (
                    <Pagination
                      margin={2}
                      page={this.state.page}
                      count={totalPages}
                      pageSize={pageSize}
                      totalRecords={totalOrderCounts}
                      onPageChange={this.handlePageChange}
                    ></Pagination>
                  )}

                  {orderData.length === 0 && (
                    <div className="text-align-center card-point-header"></div>
                  )}

                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Fragment>
    )
  }
}
export default OrderHistory;