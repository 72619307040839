import { request } from "./Request";
import { routes } from "../constants/constant.route";

const {
  GET_USER_INFO,
  UPDATE_USER_INFO,
  UPLOAD_PROFILE_IMAGE,
  UPDATE_DETAIL_REQUEST,
  CHANGE_PASSWORD,
  SEARCH_DEALER,
  GET_IMMEDIATE_DEALER,
  GET_AREA_REGION_ZONE,
  GET_USER_COUNT,
  GET_STATIC_WHATSAPP_TEMPLATES,
  WHATSAPP_REPORT,
  WHATSAPP_REPORT_DETAIL,
  GET_ALL_WHATSAPP_TEMPLATES,
} = routes.USER;

const {
  SAVE_TOKEN,
  GET_NOTIFICATION,
  GET_UNREAD_NOTIFICATION_COUNT,
} = routes.OTHER;

const API_URL = process.env.REACT_APP_API_URL;

export default class UserService {
  static getUserInfo(userObj = {}) {
    return request(
      "GET",
      `${API_URL}${GET_USER_INFO}${
        userObj && userObj.id ? `?id=${userObj.id}` : ""
      }`,
      null,
      null,
      null
    );
  }

  static updateUserInfo(obj) {
    return request("POST", `${API_URL}${UPDATE_USER_INFO}`, null, obj, null);
  }

  static sendUpdateDetailRequest(obj) {
    return request(
      "POST",
      `${API_URL}${UPDATE_DETAIL_REQUEST}`,
      null,
      obj,
      null
    );
  }

  static uploadImage(image, type) {
    let qp = `?`;
    if (type) {
      qp += `type=${type}`;
    }
    const headers = {
      "content-type": "multipart/form-data",
    };
    return request(
      "POST",
      `${API_URL}${UPLOAD_PROFILE_IMAGE}${qp}`,
      null,
      image,
      headers
    );
  }

  static uploadImage1(image) {
    // const headers = {
    //   "content-type": "multipart/form-data"
    // };
    return request(
      "POST",
      `${API_URL}${UPLOAD_PROFILE_IMAGE}`,
      null,
      image,
      null
    );
  }

  static updatePassword(body) {
    return request("POST", `${API_URL}${CHANGE_PASSWORD}`, null, body, null);
  }

  static saveToken(obj) {
    return request("POST", `${API_URL}${SAVE_TOKEN}`, null, obj, null);
  }

  static getNotification(q) {
    return request(
      "GET",
      `${API_URL}${GET_NOTIFICATION}?page=${q.page}&pagesize=${q.pagesize}`,
      null,
      null,
      null
    );
  }

  static getUnreadNotificationCount() {
    return request(
      "GET",
      `${API_URL}${GET_UNREAD_NOTIFICATION_COUNT}`,
      null,
      null,
      null
    );
  }

  static searchDealer(query, uuid) {
    let qp = "?";
    if (query) {
      qp += `q=${query}&`;
    }
    if (uuid) {
      qp += `u=${uuid}`;
    }
    return request("GET", `${API_URL}${SEARCH_DEALER}${qp}`, null, null, null);
  }

  static getImmediateDealer(uuid) {
    let qp = ``;
    if (uuid) {
      qp += `?u=${uuid}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_IMMEDIATE_DEALER}?${qp}`,
      null,
      null,
      null
    );
  }

  static getAreaRegionZone(uuid) {
    let qp = ``;
    if (uuid) {
      qp += `?u=${uuid}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_AREA_REGION_ZONE}${qp}`,
      null,
      null,
      null
    );
  }
  static getUserCount(obj) {
    return request("POST", `${API_URL}${GET_USER_COUNT}`, null, obj, null);
  }
  static getWhatsappStaticTemplates() {
    return request(
      "GET",
      `${API_URL}${GET_STATIC_WHATSAPP_TEMPLATES}`,
      null,
      null,
      null
    );
  }
  static getWhatsappReport(params) {
    return request("GET", `${API_URL}${WHATSAPP_REPORT}`, params, null, null);
  }

  static getSMSReportDetails(params) {
    return request(
      "GET",
      `${API_URL}${WHATSAPP_REPORT_DETAIL}`,
      params,
      null,
      null
    );
  }

  static getWhatsappAllTemplates(params) {
    return request(
      "GET",
      `${API_URL}${GET_ALL_WHATSAPP_TEMPLATES}`,
      params,
      null,
      null
    );
  }
}
