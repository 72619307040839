import React, { Component } from "react";
import MarketingCouponService from '../../services/MarketingCouponService';

import {
  Row,
  Col,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  InputGroupAddon,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { messages } from "../../constants/constant.message";
import ModalService from "../../services/ModalService";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";

const { ERROR_MESSAGE } = messages.COMMON;


class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      isLoader: false,
      dropdown_filter_value: false,
      controls: {
        type: '',
        purpose: '',
        start_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        end_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        paid_by_jk: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      },
      type: [],
      purpose: [],
    };
  }

  componentDidMount() {
    this.getTypeFromCampaign();
    this.getTypeAndData();
  }

  getTypeFromCampaign = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getTypeFromCampaign()
      .then(res => {
        let { type } = this.state;

        if (res.data.data) {
          type = res.data.data;
        } else {
          type = [];
        }

        this.setState({
          type,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  handleRadioChangePaid(e) {
    let { controls } = this.state;
    controls.paid_by_jk.value = e;
    this.setState({ controls });
  };

  getTypeAndData = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getTypeAndData()
      .then(res => {
        let { purpose } = this.state;

        if (res.data.data) {
          purpose = res.data.data.purpose;
        } else {
          purpose = [];
        }

        this.setState({
          purpose,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  onChangeDate = (e, controlName) => {
    const { controls } = this.state;
    controls[controlName].value = e;
    this.setState({ controls });
  };

  handleInputChange = (e) => {
    let { controls } = this.state;
    let controlName = e.target.name;

    const controlValue = e.target.value;
    controls[controlName] = controlValue;

    this.setState({
      controls
    })
  }

  toggle = () => {
    GoogleAnalticsService.toString('View Report', 'Redeem Marketing Filter Click')
    let { dropdownOpen, controls } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
      controls
    });
  };

  applyFilter = () => {
    let { controls } = this.state;
    this.props.applyFilter(controls);
    this.setState({
      dropdownOpen: false,
      dropdown_filter_value: true
    });

  }

  resetFilter = () => {
    this.props.applyFilter({});
    this.setState({
      controls: {
        type: '',
        purpose: '',
        start_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        end_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        paid_by_jk: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      },
      dropdown_filter_value: false
    }, () => {
      this.toggle();
    });
  }

  render() {
    let { controls } = this.state;
    return (
      <>

        <Dropdown
          group
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          size="sm"
          className="dropdown-toggle-ho-all-india-filter-wrapper"
        >
          <DropdownToggle
            color="white"
            className="text-capitalize All-Fleets-DropdownlLbl form-control"
            style={{ border: 'none' }}
          >
            {(this.state.dropdown_filter_value === false || this.state.dropdown_filter_value === 'false') && (
              <img
                src="images/filter.svg"
                alt=""
                className="cursor-pointer"
              />
            )}
            {(this.state.dropdown_filter_value === true || this.state.dropdown_filter_value === 'true') && (
              <img
                src="images/filter_applied.svg"
                alt=""
                className="cursor-pointer"
              />
            )}
          </DropdownToggle>

          <DropdownMenu className="dropdown-toggle-fleet-type-filter" style={{ width: "285px" }}>

            <Row
              className="p-2"
            >
              <Col xl="12" md="12" xs="12" >
                <Label className="filter-lbl">Type</Label>
              </Col>
              <Col xl="12" md="12" xs="12">
                <Row>
                  <Col xl="12" md="12" xs="12">
                    <FormGroup>
                      <select
                        className="form-control form-group-select select select-height-70rem"
                        name="type"
                        value={controls.type}
                        onChange={(e) => { this.handleInputChange(e) }}>
                        <option className="label-text" value="">Select</option>
                        {
                          this.state.type.map((item) => (
                            <option className="label-text" value={item.vehicle_type_id}>{item.name}</option>
                          ))
                        }
                      </select>
                    </FormGroup>
                  </Col>

                </Row>
              </Col>
              <Col xl="12" md="12" xs="12" >
                <Label className="filter-lbl">Purpose</Label>
              </Col>
              <Col xl="12" md="12" xs="12">
                <Row>
                  <Col xl="12" md="12" xs="12">
                    <FormGroup>
                      <select
                        className="form-control form-group-select select select-height-70rem"
                        name="purpose"
                        value={controls.purpose}
                        onChange={(e) => { this.handleInputChange(e) }}>
                        <option className="label-text" value="">Select</option>
                        {
                          this.state.purpose.map((item) => (
                            <option className="label-text" value={item.id}>{item.name}</option>
                          ))
                        }
                      </select>
                    </FormGroup>
                  </Col>
                </Row>

              </Col>
            </Row>

            <Row
              className="pl-2"
            >
              <Col xl="12" md="12" xs="12" >
                <Label className="filter-lbl">Coupon Amount Paid</Label>
              </Col>

              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="paid_by_jk"
                        value=""
                        onChange={() => {
                          this.handleRadioChangePaid("");
                        }}
                        checked={controls.paid_by_jk.value === ""} />
                      <span className="checkmark-radio" />
                    </label>
                    <Label className="filter-lbl" style={{ marginLeft: "5px" }}>All</Label>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp" style={{ paddingRight: "0" }}>
                    <label className="custom-checkboax-radio--container">
                      <input type="radio"
                        name="paid_by_jk"
                        value="JK Tyre"
                        onChange={() => {
                          this.handleRadioChangePaid("JK Tyre");
                        }}
                        checked={controls.paid_by_jk.value === "JK Tyre"} />
                      <span className="checkmark-radio" />
                    </label>
                    <Label className="filter-lbl" style={{ marginLeft: "5px" }}>JK Tyre</Label>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12" style={{ paddingRight: "0" }}>
                <FormGroup>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="paid_by_jk"
                        value="Dealer"
                        onChange={() => {
                          this.handleRadioChangePaid("Dealer");
                        }}
                        checked={controls.paid_by_jk.value === "Dealer"} />
                      <span className="checkmark-radio" />
                    </label>
                    <Label className="filter-lbl" style={{ marginLeft: "5px" }}>Dealer</Label>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row
              className="pl-2"
            >
              <Col xl="12" md="12" xs="12" >
                <Label className="filter-lbl-bold">Redemption Date</Label>
              </Col>
              <Col xl="11" md="11" xs="11">
                <Row>
                  <Col xl="6" md="6" xs="6" style={{ "padding-right": "0px" }}>
                    <FormGroup>
                      <Label for="common" className="filter-lbl">Start Date</Label>

                      <Label
                        onClick={e =>
                          this.calendar1.state.open && e.preventDefault()
                        }
                      >
                        <InputGroup>
                          <DatePicker className="form-control m_title"
                            placeholderText="Select"
                            onChange={e => this.onChangeDate(e, "start_date")}
                            selected={controls.start_date.value}
                            name="start_date"
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            ref={r => (this.calendar1 = r)}
                            shouldCloseOnSelect={true}
                            showYearDropdown
                            // minDate={new Date()}
                            // maxDate={controls.end_date.value ? controls.end_date.value : null}
                            popperPlacement="botom-start"
                            popperModifiers={{
                              flip: {
                                enabled: false,
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false
                              }
                            }}
                            autoComplete="nofill"
                            dropdownMode="select"
                          />
                          <InputGroupAddon addonType="append">
                            <div className="input-group-text">
                              <FontAwesomeIcon
                                htmlFor="datepicker"
                                icon={faCalendarAlt}
                              />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xl="6" md="6" xs="6" style={{ "padding-right": "0px" }}>
                    <FormGroup>
                      <Label for="common" className="filter-lbl">End Date</Label>
                      <Label
                        className="add-customer-date"
                        onClick={e =>
                          this.calendar2.state.open && e.preventDefault()
                        }
                      >
                        <InputGroup>
                          <DatePicker
                            placeholderText="Select"
                            className={"form-control"}
                            onChange={e =>
                              this.onChangeDate(e, "end_date")
                            }
                            name="end_date"
                            // maxDate={new Date()}
                            selected={controls.end_date.value}
                            dateFormat="dd/MM/yyyy"
                            autoComplete="nofill"
                            showMonthDropdown
                            ref={r => (this.calendar2 = r)}
                            shouldCloseOnSelect={true}
                            showYearDropdown
                            minDate={controls.start_date.value ? controls.start_date.value : null}
                            dropdownMode="select"
                            popperPlacement="botom-start"
                            popperModifiers={{
                              flip: {
                                enabled: false
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false
                              }
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <div className="input-group-text">
                              <FontAwesomeIcon
                                htmlFor="datepicker"
                                icon={faCalendarAlt}
                              />
                            </div>
                          </InputGroupAddon>
                        </InputGroup>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ margin: "auto" }}>
              <Col xl="6" xs="6" md="6" className="text-align-right">
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                  color="dark"
                  style={{ width: '110px' }}
                  onClick={() => {
                    this.resetFilter();
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col xl="6" xs="6" md="6" className="text-align-right">
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-save-button"
                  color="warning"
                  style={{ width: '110px' }}
                  onClick={(e) => {
                    this.applyFilter(e);
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </DropdownMenu>
        </Dropdown>

      </>
    );
  }
}

export default Filter;
