/**
 * @name MarketingCouponService
 * @author Foram Sojitra <foram.sojitra@techifysolutions.com>
 * @description All APIs for marketing coupons
 * @version 0.0.1
 */
import { request } from './Request';
import { routes } from '../constants/constant.route';

const {
  GET_COUPON,
  GET_REDEEMED_COUPON,
  GET_PROGRAM,
  GET_DATA_FOR_COUPON,
  GET_SUB_REPORT,
  DELETE_COUPON,
  GET_COUPON_BY_ID,
  CN_REPORT,
  ADD_COUPON,
  GET_TYPE,
  VALIDATE_COUPON,
  REDEEM_COUPON,
  GET_TYPE_PROGRAMNAME,
  GET_LOCATION
} = routes.MARKETING_COUPON;
const API_URL = process.env.REACT_APP_API_URL;

export default class MarketingCouponService {


  /**
   * @name validate coupons
   * @description get dealer coupons
   * @param  {page: number, pagesize: number, search: search}
   * @method GET
   * @returns promise
   */
  static validateCoupon(data) {
    return request(
      'POST',
      `${API_URL}${VALIDATE_COUPON}`,
      null,
      data,
      null
    );
  }

  /**
   * @name redeem coupons
   * @description get dealer coupons
   * @param  {page: number, pagesize: number, search: search}
   * @method GET
   * @returns promise
   */
  static redeemCoupon(data) {
    return request(
      'POST',
      `${API_URL}${REDEEM_COUPON}`,
      null,
      data,
      null
    );
  }

  static getLocation(data) {
    return request(
      'GET',
      `${API_URL}${GET_LOCATION}${data}`,
      null,
      data,
      null
    );
  }

  /**
   * @name get coupons
   * @description get dealer coupons detail
   * @param  {page: number, pagesize: number, search: search}
   * @method GET
   * @returns promise
   */
  static getCoupon(data) {
    return request(
      'GET',
      `${API_URL}${GET_COUPON}`,
      data,
      null,
      null
    );
  }

  /**
   * @name get dealers redeemed coupons
   * @description get dealer coupons detail
   * @param  {page: number, pagesize: number, search: search}
   * @method GET
   * @returns promise
   */
  static getRedeemedDealerCoupon(data) {
    return request(
      'GET',
      `${API_URL}${GET_REDEEMED_COUPON}`,
      data,
      null,
      null
    );
  }

  /**
   * @name get reports of coupons , pending, redeemed and dealers
   * @method GET
   * @returns promise
   */
  static getSubReports(data) {
    return request(
      'GET',
      `${API_URL}${GET_SUB_REPORT}`,
      data,
      null,
      null
    );
  }

  /**
   * @name get delete coupon
   * @method post
   * @returns promise
   */
  static deleteCoupon(data) {
    return request(
      'POST',
      `${API_URL}${DELETE_COUPON}`,
      null,
      data,
      null
    );
  }

  /**
  * @name get get coupon by id
  * @method get
  * @returns promise
  */
  static getCouponById(data) {
    return request(
      'GET',
      `${API_URL}${GET_COUPON_BY_ID}`,
      data,
      null,
      null
    );
  }

  /**
   * @name get Program
   * @description get program names
   * @method GET
   * @returns promise
   */
  static getProgram(data) {

    return request(
      'GET',
      `${API_URL}${GET_PROGRAM}?q=${data.search_query}`,
      null,
      null,
      null
    );

  }

  /**
   * @name get purpose and other data
   * @description get purpose and other data for coupons
   * @method GET
   * @returns promise
   */
  static getTypeAndData(data) {
    return request(
      'POST',
      `${API_URL}${GET_DATA_FOR_COUPON}`,
      null,
      data,
      null
    );

  }

  /**
   * @name get types
   * @description get types 
   * @method GET
   * @returns promise
   */
  static getTypeFromCampaign() {
    return request(
      'GET',
      `${API_URL}${GET_TYPE}`,
      null,
      null,
      null
    );

  }

  /**
   * @name get types
   * @description get types 
   * @method GET
   * @returns promise
   */
  static getType(data) {
    return request(
      'GET',
      `${API_URL}${GET_TYPE_PROGRAMNAME}`,
      data,
      null,
      null
    );

  }

  /**
   * @description download cn report
   * @method POST
   * @returns promise
   */
  static CNReport(data) {
    return request(
      'POST',
      `${API_URL}${CN_REPORT}`,
      null,
      data,
      null
    );
  }

  /**
   * @description add coupon
   * @method POST
   * @returns promise
   */
  static addCouon(data) {
    return request(
      'POST',
      `${API_URL}${ADD_COUPON}`,
      null,
      data,
      null
    );
  }

}
