import React, { useState, useCallback, useEffect } from 'react';
import {
  Table,
  Input,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import Moment from 'react-moment';
import Pagination from '../../components/Pagination/Pagination';
import DatePicker from 'react-datepicker';
import lodash from "lodash";
import './UserUpdates.css';
import CampaignService from '../../services/CampaignService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';


// const data = [
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
// ]


const EmailCampaignTable = ({ openSideBarCreateCampaign }) => {
  const [searchValue, setSearchValue] = useState('')
  const [count, setCount] = useState(10);
  const [totalCount, setTotalCount] = useState(10);
  const [pagesize, setPageSize] = useState(10);
  const [page, setPage] = useState(1)
  const [openfilter, setOpenFilter] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // const [userQuery, setUserQuery] = useState("");
  const delayedQuery = useCallback(lodash.debounce(q => searchData(q), 500), []);
  const [searchQuery, setSearchQuery] = useState('');
  const [campaignRetailListData, setCampaignRetailListData] = useState([]);
  const [isFirsTimeLoadPageDataNotFound, setIsFirsTimeLoadPageDataNotFound] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const serearchQuery = localStorage.getItem('serearchQuery');
  const pageNumber = localStorage.getItem('pageNumber');


  useEffect(() => {
    getAllEmail();
  }, [(page * pagesize), openfilter])

  useEffect(() => {
    getAllEmail();
    setPageSize(10);
    setPage(Number(pageNumber) ? Number(pageNumber) : 1);
  }, [searchQuery,])
  const handleChange = (e) => {
    setPage(1);
    localStorage.setItem('pageNumber', 1);
    setSearchValue(e.target.value)
    // setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  }
  const searchData = (search) => {
    setSearchQuery(search)
  };
  const openFilter = async () => {
    await setOpenFilter(!openfilter)
  }

  const onStartDateChange = (e) => {
    setStartDate(e)
  }
  const onEndDateChange = (e) => {
    setEndDate(e)
  }

  const onFilterApplied = () => {
    if (startDate || endDate) {
      setFilterApplied(true)
    }
    openFilter();
  }
  const onResetApplied = () => {
    setStartDate('');
    setEndDate('');
    setFilterApplied(false);
    openFilter();
  }

  const getToDateWhileSending = (date) => {
    let dateToProcesss = Object.assign(date);
    let currentDate = dateToProcesss.getDate();
    dateToProcesss.setDate(currentDate + 1);
    dateToProcesss.setHours(5, 29, 59, 59);
    return dateToProcesss.toISOString();
  }
  const getAllEmail = () => {
    setIsLoading(true);

    let reqData = {
      pageSize: pagesize,
      page: pageNumber ? pageNumber : page,
      start_date: startDate,
      end_date: endDate,
      q: searchQuery,
    }
    if (startDate) {
      let start_date_value = Object.assign(startDate);
      start_date_value.setHours(5, 30, 0, 0);
      reqData['start_date'] = start_date_value.toISOString();
    }
    if (endDate) {
      let dateToProcess = new Date(endDate.getTime());
      reqData['end_date'] = getToDateWhileSending(dateToProcess);
    }
    CampaignService.getAllCampaignDetail(reqData)
      .then((data) => {
        let { data: list } = data.data;
        if (list.elements && Array.isArray(list.elements) && list.elements.length > 0) {
          setCampaignRetailListData(list.elements);
          if (isFirsTimeLoadPageDataNotFound) {
            setIsFirsTimeLoadPageDataNotFound(false);
          }
        } else {
          setCampaignRetailListData([]);
        }
        const totalCountsTemp = list.total_count;
        const totalPagesTemp = Math.ceil(totalCountsTemp / pagesize);
        setCount(totalCountsTemp);
        setTotalCount(totalPagesTemp);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }
  const handlePageChange = (page) => {
    localStorage.setItem('pageNumber', Number(page));
    setPage(page);
  }
  const handleInputChange = (e) => {
    setPageSize(Number(e.target.value));
  }
  const handleEmailSentToDownload = (data) => {
    window.open(data, '_blank')
  }
  const Filter = () => {
    return (
      <div className="email_campaign_filter_container">
        <Card>
          <CardBody style={{ padding: 0 }}>
            <div style={{ padding: '18px' }}>
              <div>
                <span className="email_campaign_filter_heading">Created At</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '14px', marginBottom: '24px' }}>
                <div style={{ paddingRight: '3px' }}>
                  <div style={{ marginBottom: '12px' }}><span className="email_campaign_date_title">Start Date</span></div>
                  <InputGroup>
                    <DatePicker
                      placeholderText="select"
                      className={"form-control"}
                      selected={startDate}
                      onChange={onStartDateChange}
                      showMonthDropdown
                      //ref={(r) => (this.calendar = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={new Date()}
                      dropdownMode="select"
                      //onInputClick={this.onInputClickDate}
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append">
                      <div className="input-group-text">
                        <img src="/images/sidebar-icons/calendar_icon.png" alt="" />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div style={{ paddingLeft: '3px' }}>
                  <div style={{ marginBottom: '12px' }}><span className="email_campaign_date_title">End Date</span></div>
                  <InputGroup>
                    <DatePicker
                      placeholderText="select"
                      className={"form-control"}
                      selected={endDate}
                      onChange={onEndDateChange}
                      showMonthDropdown
                      //ref={(r) => (this.calendar = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={new Date()}
                      dropdownMode="select"
                      //onInputClick={this.onInputClickDate}
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append">
                      <div className="input-group-text">
                        <img src="/images/sidebar-icons/calendar_icon.png" alt="" />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <button style={{ color: '#6E777F', background: '#E0E2E4' }} className="email_campaign_button" onClick={onResetApplied}>Reset</button>
                <button style={{ color: '#495057', background: '#FDBB1F' }} className="email_campaign_button" onClick={onFilterApplied}> Apply </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div >
    )
  }


  return (
    <div style={{ width: '100%' }}>
      <div className="email_title_container">
        <span className="email_campaign_title">Email Campaign</span>
        <button onClick={() => openSideBarCreateCampaign(true)} className="create_campaign_button">Create Email Campaign</button>
      </div>
      <div className="email_input_container">
        <div style={{ alignItems: 'center' }} className="d-flex-row">
          <div
            style={{ width: "448px", height: '35px' }}
            className="d-flex-row search-input"
          >
            <Input
              placeholder="Search by Campaign Name, From Name, Subject, From Email "
              className="login-input"
              name="search"
              onChange={handleChange}
              value={searchValue}
            ></Input>
            <img
              src="images/search.svg"
              alt=""
              className="icon-width"
              style={{ marginLeft: "-25px", width: '16px' }}
            />
          </div>
          <div style={{ marginLeft: '14px' }}>
            {filterApplied ? <img src="images/filter_applied.svg" alt=""
              className="cursor-pointer"
              onClick={() => openFilter()} /> :
              <img
                src="images/filter.svg"
                alt=""
                className="cursor-pointer"
                onClick={() => openFilter()}
              />}
          </div>
          {openfilter && <div style={{ position: 'relative' }}><Filter /></div>}
          {/* <div className="d-flex-row" style={{ marginLeft: "10px" }}>
            {this.state.filterControls &&
              Object.keys(this.state.filterControls).length > 0 && (
                <img
                  src="images/filter_applied.svg"
                   alt=""
                  className="cursor-pointer"
                //onClick={() => this.openCouponsFilter()}
                 />
              )}
            {this.state.filterControls &&
              Object.keys(this.state.filterControls).length === 0 && (
                <img
                  src="images/filter.svg"
                   alt=""
                  className="cursor-pointer"
                // onClick={() => this.openCouponsFilter()}
                 />
              )}
          </div> */}

        </div>
      </div>
      {isLoading && <CustomSpinner />}
      <div style={{ padding: '0 18px' }}>
        <Table className="mb-0 table-responsive tbl-about tbl-about text-align-center">
          <thead>
            <tr
              style={{ backgroundColor: "#f6f6f6", height: '40px' }}
              className="coupon-list-table-header"
            >
              <td rowSpan="2" className="text-align-center border-1-5">
                <span className="table_header_text">Campaign Name</span>
              </td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">From Name</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Subject</span> </td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">From Email</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Email Sent To</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Email HTML Files</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Campaign Run On</span></td>
            </tr>
          </thead>
          <tbody>
            {campaignRetailListData && campaignRetailListData.map(item => (
              <tr>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.campaign_name}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.from_name}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.subject}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.from_email}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data"><span><img src="images/download.svg" alt="" onClick={() => handleEmailSentToDownload(item.to_email_file)} style={{ cursor: 'pointer' }} /></span></td>
                <td className="text-align-center border-1-5 email_campaign_table_data"><span><img src="images/download.svg" alt="" onClick={() => handleEmailSentToDownload(item.content_html)} style={{ cursor: 'pointer' }} /></span></td>
                <td className="text-align-center border-1-5 email_campaign_table_data">
                  <Moment format="DD-MM-YYYY hh:mm A">
                    {item.created_at}
                  </Moment>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {count > 0 && (
        <div
          className="d-flex-row"
          style={{ marginTop: "10px", justifyContent: "flex-end" }}
        >
          <span className="Rows-per-page">Rows per page:</span>
          <select
            className="form-control rows-per-page-select"
            name="pageSize"
            value={pagesize}
            onChange={e => handleInputChange(e)}
            style={{ width: "65px" }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          &nbsp;&nbsp;
          <div style={{ marginTop: "5px" }}>
            <Pagination
              margin={2}
              page={page}
              count={totalCount}
              pageSize={pagesize}
              totalRecords={count}
              onPageChange={handlePageChange}
            ></Pagination>
          </div>
        </div>
      )}
    </div>
  )
}

export default EmailCampaignTable;
