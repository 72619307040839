import React, { Component } from 'react';
import { Row } from "reactstrap";
// import moment from "moment";
import moment from "moment-timezone";

class UpcomingAppointment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customer_vehicle: []
    }
  }
  setPrpos() {
    let { customer_vehicle } = this.state;
    this.setState({
      customer_vehicle
    })
  }

  viewHistory = (history) => {
    this.setState({
      ViewHistory: history,
    });
    this.props.viewHistory(history);
  };

  generateServiceToolTip = (services) => {
    let serviceString = '';
    if (services && Array.isArray(services) && services.length > 0) {
      for (let i = 0; i < services.length; i++) {
        if (i === 0) {
          serviceString += services[i];
        } else {
          serviceString += `\n${services[i]}`
        }
      }
    }
    return serviceString;
  }


  generateServiceText = (lastVisitService) => {
    let c = null;
    if (lastVisitService && Array.isArray(lastVisitService) && lastVisitService.length > 0) {
      if (lastVisitService.length === 1) {
        c = <span className="sub-value-table-service d-flex">({lastVisitService[0]})</span>
      } else {
        c = <span className="sub-value-table-service d-flex" id="customer-list-tooltip">({lastVisitService[0]})
          <span className="cursor-pointer tool-tip" data-tooltip-position="top" data-tooltip={this.generateServiceToolTip(lastVisitService)} >
            <span className="sub-value-more cursor-pointer">+{lastVisitService.length - 1}</span>
          </span>
          {/* <span className="sub-value-more cursor-pointer" title={this.generateServiceToolTip(lastVisitService)}>+{lastVisitService.length - 1}</span> */}
        </span>
      }
    }
    return c;
  }


  render() {
    let { customer_vehicle } = this.props;
    return (
      <>
        <Row className="add-customer-wrapper" style={{ marginLeft: '15px !important' }}>
          {customer_vehicle &&
            customer_vehicle.map((item, i) => (
              item.upcoming_due_date &&
              <div className="edit-veh-det-view w-100 mb-3" style={{ paddingTop: '10px', marginLeft: '7px', marginRight: '15px' }}>
                <table className="w-100 tbl-vehicle-detail" cellspacing="0">
                  <tr>
                    <td style={{ width: '20px' }}></td>
                    <td className="borde-bottom-EFF1F3">
                      <div className="d-flex-column mb-2">
                        <div className="d-flex-row">
                          <span style={{ fontSize: "12px" }}>
                            Vehicle Type
                          </span>
                        </div>
                        <div>
                          <img
                            src={item.vehicle_type_image_url}
                            className="vehicle-image"
                            alt=''
                          />
                        </div>
                      </div>
                    </td>
                    <td className="borde-bottom-EFF1F3">
                      <div className="d-flex-column pb-2" style={{ width: '128px', minWidth: '128px' }}>
                        <div className="V-t-card mb-1" style={{ width: 'auto' }}>Vehicle Detail</div>
                        <div className="V-t-model mb-1" style={{ width: 'auto' }}>
                          {
                            item.vehicle_type_brand_name || item.vehicle_type_brand_model_name ?
                              <b>
                                {item.vehicle_type_brand_name}&nbsp;&nbsp;
                                {item.vehicle_type_brand_model_name}
                              </b> : '--'
                          }
                        </div>
                        <div className="V-t-number mb-1" style={{ width: 'auto' }}>{item.registration_number}</div>
                      </div>
                    </td>
                    <td className="borde-bottom-EFF1F3">
                      <div className="V-t-card mb-1">Usage Type</div>
                      <div className="V-t-model mb-1">
                        <b>{item.vehicle_category ? (item.vehicle_category.toLowerCase() === 'taxi' ? 'Commercial' : item.vehicle_category) : ''}</b>
                      </div>
                    </td>
                    <td >
                      {/* <div className="del-img">
                        <img
                          className="cursor-pointer"
                          src="images/edit.svg"
                          style={{ height: "13px" }}
                          onClick={() => this.editVehicle(i)}
                           alt=''
                        />
                        &nbsp;&nbsp;
                    <img
                          className="cursor-pointer"
                          src="/images/delete.svg"
                          onClick={() =>
                            this.deleteCustomerConfirm(item.vehicle_id)
                          }
                           alt=''
                        />
                      </div> */}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="vertical-align-middle">
                      <div style={{ fontSize: "12px" }}>
                        Last Visit Date
                      </div>
                    </td>
                    <td className="vertical-align-middle">
                      <div className="d-flex-row justify-content-between">
                        <div className="V-t-model">
                          {
                            item.last_visit_date ?
                              <b>
                                {" "}
                                { }
                                {item.last_visit_date &&
                                  moment.tz(item.last_visit_date, 'Asia/Kolakata').format("DD MMM,YYYY")}
                              </b> : <b>--</b>
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex-column mt-2 mb-2">
                        <div className="d-flex-row">
                          <span>Purpose</span>&nbsp;
                          <span style={{ fontSize: "14px" }}>
                            {
                              item.last_purpose_name ?
                                <b>{item.last_purpose_name}</b> :
                                <b>--</b>
                            }
                          </span>
                        </div>
                        {
                          item.last_purpose_service_name && item.last_purpose_service_name.length > 0 &&
                          <span>
                            {/* {item.last_purpose_service_name.map((last_purpose_service_name, i) => (
                              <>
                                {last_purpose_service_name}
                                {
                                  (i !== (item.last_purpose_service_name.length - 1)) && ', '
                                }
                              </>
                            ))
                            } */}
                            {this.generateServiceText(item.last_purpose_service_name)}
                          </span>
                        }
                      </div>
                    </td>
                    <td>
                      <div
                        className="del-img mt-2"
                        onClick={() => this.viewHistory(item)}
                      >
                        <img src="/images/history_icon.png" alt='' />
                      </div>
                    </td>
                  </tr>
                  <tr className="upcoming-aapoint">
                    <td></td>
                    <td className="vertical-align-middle" style={{ height: '40px' }}>
                      <div style={{ fontSize: '12px' }} className="d-flex">
                        <span style={{ width: '130px' }}>Upcoming Appointment</span>
                      </div>
                    </td>
                    <td className="vertical-align-middle">
                      {
                        item.upcoming_due_date ?
                          <div className="V-t-model d-flex" style={{ marginTop: '5px' }}>
                            <b>{moment(item.upcoming_due_date).format("DD MMM,YYYY")}</b>
                          </div> :
                          <b>--</b>
                      }
                    </td>
                    <td className="vertical-align-middle">
                      <div className="V-t-card-2 d-flex-column flex-wrap pt-2 pb-2">
                        <div className="d-flex-row">
                          <span>Purpose &nbsp;&nbsp;&nbsp;{" "}</span>
                          <span style={{ fontSize: "14px" }}>
                            {
                              item.upcoming_purpose_name ?
                                <b>{item.upcoming_purpose_name}</b> :
                                <b>--</b>
                            }
                          </span>
                        </div>
                        {
                          item.upcoming_purpose_service_name && item.upcoming_purpose_service_name.length > 0 &&
                          <span>
                            {/* {item.upcoming_purpose_service_name.map((upcoming_purpose_service_name, i) => (
                              <>
                                {upcoming_purpose_service_name}
                                {
                                  (i !== (item.upcoming_purpose_service_name.length - 1)) && ', '
                                }
                              </>
                            ))
                            } */}
                            {this.generateServiceText(item.upcoming_purpose_service_name)}
                          </span>
                        }
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </table>
              </div>
            ))}

        </Row>
      </>
    );
  }
}

export default UpcomingAppointment;