import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap";
import "./WarrantyReplacement.css";
import WarrantyReplacementService from "../../services/WarrantyReplacementService";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import swal from "sweetalert";

class SelectVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      token: "",
      selectedVehicleType: "",
      redirectToDashboard: false,
      redirectToWarrantyRegistration: false,
      redirectToWarrantyCheck: false,
      vehicleDetails: [],
      vehicleType: [],
      isLoading: false,
      type: null,
      selectedVehicleNumber: "",
      controls: {
        vehicleId: {
          value: "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
          databaseFieldName: "vehicle_id"
        },
        state: {
          value: "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
          databaseFieldName: "state_id"
        },
        city: {
          value: "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
          databaseFieldName: "city_id"
        },
        vehicleTypeId: {
          value: "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
          databaseFieldName: "vehicle_type_id"
        },
        vehicleCategory: {
          value: null,
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
          databaseFieldName: "vehicleCategory"
        }
      },
      isValidForm: false
    };
  }

  componentWillMount() {
    const query = new URLSearchParams(this.props.location.search);
    const phoneNumber = query.get("phoneNumber");
    const token = query.get("t");
    if (!phoneNumber || (phoneNumber && phoneNumber.length !== 10) || !token) {
      this.redirectDashboard();
    }

    this.setState({ phoneNumber, token });
    this.findCustomer(phoneNumber, "+91");
    this.getVehicleType();
  }

  /**
   * @name getVehicleType
   * @desc The method is used to get vehicle types.. car, two wheeler
   */
  async getVehicleType() {
    try {
      const { data } = await WarrantyReplacementService.getVehicleType();

      if (data) {
        this.vehicleType = data.data;
      }

      this.setState(prevState => {
        return { ...prevState };
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @name redirectDashboard
   * @desc The method is used to redirect on dashboard
   */
  redirectDashboard() {
    let { redirectToDashboard } = this.state;
    redirectToDashboard = true;
    this.setState({ redirectToDashboard });
  }

  /**
   * @name findCustomer
   * @desc The method will return whether the device is browser or mobile
   * @params {number, string}
   */
  async findCustomer(phone_number, country_code) {
    let { vehicleDetails } = this.state;
    try {
      this.setState({ isLoading: true });
      const obj = {
        phone_number,
        country_code
      };

      const qResponse = await WarrantyReplacementService.getCustomer(obj);
      const { data } = qResponse;
      this.setState({ isLoading: false });
      if (data.data && data.success) {
        vehicleDetails = data.data.vehicleDetails;
        this.setState({ vehicleDetails });
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  }

  /**
   * @name registeredVehicleClick
   * @desc The method will redirect to warranty check screen
   * @params {object}
   */
  registeredVehicleClick(obj) {
    if (obj.vehicle_id) {
      const encryptedvehicle_id = window.btoa(obj.vehicle_id); // convert to base64 string
      this.setState({
        redirectToWarrantyCheck: true,
        selectedVehicleNumber: encryptedvehicle_id
      });
    }
  }

  /**
   * @name newVehicleClick
   * @desc The method will redirect to warranty registration screen
   * @params {object}
   */
  vehicleTypeClick(type) {
    if (!this.state.type) {
      swal("Sorry !", "Please Select Vehicle Type.", "warning");
      return;
    }
    if (!this.state.controls.vehicleCategory.value) {
      swal("Sorry !", "Please Select Vehicle Category.", "warning");
      return;
    }
    this.setState({
      redirectToWarrantyRegistration: true,
      selectedVehicleType: type
    });
  }

  handleInputChangeVehicleCategory = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (!isNaN(controlValue) || controlName === "vehicleCategory") {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }

    this.setState({ controls });
  };

  updateType = (type, i) => {
    this.vehicleType.filter(x => (x.isActive = false));
    this.vehicleType[i].isActive = true;
    this.setState({
      type
    });
  };

  capitalizeFirstLetter = string => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  render() {
    const {
      vehicleDetails,
      isLoading,
      selectedVehicleNumber,
      phoneNumber,
      token,
      type,
      selectedVehicleType,
      redirectToDashboard,
      redirectToWarrantyRegistration,
      redirectToWarrantyCheck,
      controls
    } = this.state;
    const { vehicleCategory } = controls;
    return (
      <div>
        {isLoading && <CustomSpinner />}
        {!isLoading && (
          <>
            {redirectToDashboard && <Redirect to="/" />}
            {redirectToWarrantyRegistration && (
              <Redirect
                to={{
                  pathname: "/warranty-registration",
                  search: `phoneNumber=${phoneNumber}&t=${token}&typeId=${selectedVehicleType.vehicle_type_id}&typeName=${selectedVehicleType.name}&from=warrantyReplacement&vehicleCategory=${vehicleCategory.value}`
                }}
              />
            )}
            {redirectToWarrantyCheck && (
              <Redirect
                to={{
                  pathname: "/warranty-check",
                  search: `q=${selectedVehicleNumber}&t=${token}`
                }}
              />
            )}
            <Row style={{ padding: "0px" }}>
              <Col xl="6" md="6" ms="12" xs="12">
                {vehicleDetails && vehicleDetails.length ? (
                  <>
                    <div>
                      <span className="Warranty-Registration select-vehicle-title">
                        Select Vehicle
                      </span>
                      <br />
                      <br />
                      <span className="select-vehicle-label">
                        <b>Select from existing vehicle</b>
                      </span>
                    </div>
                    <div style={{ marginTop: "-5px" }}>
                      <br />
                      <Row>
                        {vehicleDetails && vehicleDetails.length > 0 && (
                          <>
                            {vehicleDetails.map((item, i) => (
                              <>
                                <Col
                                  xl="5"
                                  md="5"
                                  xs="10"
                                  // className="view-vehicle"
                                  className="view-vehicle"
                                  style={{
                                    marginTop: "-10px",
                                    marginLeft: "15px",
                                    marginRight: "0",
                                    marginBottom: "20px",
                                    height: "auto",
                                    width: "auto",
                                    padding: "20px 5px 10px 12px",
                                    cursor: "pointer"
                                  }}
                                  onClick={() =>
                                    this.registeredVehicleClick(item)
                                  }
                                >
                                  <Row
                                    style={{
                                      marginTop: "-20px",
                                      fontSize: "12px"
                                    }}
                                  >
                                    <Col
                                      xl="6"
                                      md="6"
                                      xs="6"
                                      className="line-height-2"
                                      style={{
                                        marginTop: "15px"
                                      }}
                                    >
                                      Vehicle Type
                                    </Col>
                                    <Col
                                      style={{ marginTop: "20px" }}
                                      xl="6"
                                      md="6"
                                      xs="6"
                                    >
                                      <b>
                                        {this.capitalizeFirstLetter(item.type)}
                                      </b>{" "}
                                    </Col>
                                  </Row>

                                  <Row
                                    style={{
                                      marginTop: "-20px",
                                      fontSize: "12px"
                                    }}
                                  >
                                    <Col
                                      xl="6"
                                      md="6"
                                      xs="6"
                                      className="line-height-2"
                                      style={{
                                        marginTop: "15px"
                                      }}
                                    >
                                      Vehicle Category
                                    </Col>
                                    <Col
                                      style={{ marginTop: "20px" }}
                                      xl="6"
                                      md="6"
                                      xs="6"
                                    >
                                      <b>
                                        {this.capitalizeFirstLetter(
                                          item.vehicle_category
                                        )}
                                      </b>{" "}
                                    </Col>
                                  </Row>

                                  <Row
                                    style={{
                                      marginTop: "-20px",
                                      fontSize: "12px"
                                    }}
                                  >
                                    <Col
                                      xl="6"
                                      md="6"
                                      xs="6"
                                      className="line-height-2"
                                      style={{
                                        marginTop: "15px"
                                      }}
                                    >
                                      Make & Model
                                    </Col>
                                    <Col
                                      style={{ marginTop: "20px" }}
                                      xl="6"
                                      md="6"
                                      xs="6"
                                    >
                                      <b>
                                        {this.capitalizeFirstLetter(
                                          item.brand_name
                                        )}
                                      </b>{" "}
                                      <b>
                                        {this.capitalizeFirstLetter(
                                          item.model_name
                                        )}
                                      </b>
                                    </Col>
                                  </Row>
                                </Col>
                              </>
                            ))}
                          </>
                        )}
                      </Row>

                      <div style={{ marginTop: "0px", display: "flex" }}>
                        <span className="input-label">OR</span>
                        <span className="horizontal-OR-line" />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="no-details">
                    There are no details associated with this number. <br />
                    Please register the product first to check eligibility for
                    warranty.
                    <br />
                    <br />
                  </div>
                )}
                <div style={{ marginTop: "15px" }}>
                  <span className="input-label">
                    Add New Vehicle for Warranty Registration{" "}
                    <span className="required-field">*</span>
                  </span>
                  <div
                    className="d-flex-row"
                    style={{ flexWrap: "wrap", marginTop: "15px" }}
                  >
                    {(this.vehicleType || []).map((type, i) => {
                      return (
                        <button
                          type="button"
                          onClick={() => this.updateType(type, i)}
                          key={type.name}
                          className={
                            type.isActive
                              ? "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column custom-secondary-selected"
                              : "mb-2 mr-2 btn-icon btn btn-outline-secondary custom-secondary d-flex-column"
                          }
                          style={
                            (type.name || "").toLowerCase() === "car"
                              ? {
                                width: "130px",
                                height: "130px",
                                borderRadius: "25px",
                                justifyContent: "space-between",
                                display: "flex",
                                paddingTop: "20px"
                              }
                              : {
                                width: "130px",
                                height: "130px",
                                borderRadius: "25px",
                                justifyContent: "space-between",
                                display: "flex",
                                paddingTop: "20px",
                                paddingBottom: "14px"
                              }
                          }
                        >
                          <span
                            className="input-label"
                            style={{
                              color: "#6e777f",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }}
                          >
                            {type.name}
                          </span>
                          <br />
                          <img
                            src={type.image_url}
                            alt={type.name}
                            className={
                              (type.name || "").toLowerCase() === "car"
                                ? "vehicle-type-image-car"
                                : "vehicle-type-image-bike"
                            }
                          />
                        </button>
                      );
                    })}
                  </div>

                  <>
                    <br />
                    <div>
                      <Label for="vehicleCategory" className="input-label">
                        Please select your customer’s type of ownership of the
                        vehicle <span className="required-field">*</span>
                      </Label>
                    </div>
                    <Row style={{ marginTop: "2px" }}>
                      <Col xl="3" xs="12" ms="12" className="mt-1">
                        <div className="d-flex-row">
                          <label className="custom-checkboax-radio--container">
                            <input
                              type="radio"
                              name="personal"
                              value="personal"
                              // checked={
                              //   controls.vehicleCategory.value === "personal"
                              // }
                              onChange={e =>
                                this.handleInputChangeVehicleCategory({
                                  target: {
                                    name: "vehicleCategory",
                                    value: "personal"
                                  }
                                })
                              }
                            />
                            <span className="checkmark-radio" />
                          </label>
                          <span style={{ marginLeft: "10px" }}>Personal</span>
                        </div>
                      </Col>

                      <Col xl="6" xs="12" ms="12" className="mt-1">
                        <div className="d-flex-row">
                          <label className="custom-checkboax-radio--container">
                            <input
                              type="radio"
                              name="taxi"
                              value="taxi"
                              // checked={vehicleCategory.value === "taxi"}
                              onChange={e =>
                                this.handleInputChangeVehicleCategory({
                                  target: {
                                    name: "vehicleCategory",
                                    value: "taxi"
                                  }
                                })
                              }
                            />
                            <span className="checkmark-radio" />
                          </label>
                          <span style={{ marginLeft: "10px" }}>
                            Taxi{" "}
                            {(
                              (this.state.type && this.state.type.name) ||
                              ""
                            ).toLowerCase() === "car"
                              ? "(Uber, OLA, Private taxi, etc.)"
                              : "(Zomato, OLA, Uber, etc.)"}
                          </span>
                        </div>
                      </Col>
                      <Col xl="6" xs="2" ms="2" />
                    </Row>
                  </>
                </div>
              </Col>
              <Col xl="1" md="1" ms="0" xs="0" />
              <Col xl="5" md="5" ms="12" xs="12">
                {vehicleDetails && vehicleDetails.length > 0 && (
                  <Card>
                    <CardBody>
                      <div className="no-details" style={{ fontSize: "19px" }}>
                        Please select the vehicle for which you want to check
                        the eligibility for the warranty.
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </>
        )}
        <br />
        <br />
        <Button
          className="mb-1 mr-1 btn-pill action-button font-weight-bold"
          color="warning"
          onClick={() => this.vehicleTypeClick(type)}
          style={{ width: "116px" }}
        >
          Next
        </Button>
      </div>
    );
  }
}

export default SelectVehicle;
