import React, { Component } from 'react';
import {
  Card, CardBody, Table, Row, Col, Input, FormGroup, InputGroup,
  InputGroupAddon, Label, Button
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Ionicon from 'react-ionicons';
import lodash from "lodash"; // library added for debounce functionality

import Pagination from "../../components/Pagination/Pagination";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import PosterService from '../../services/PosterService';

import PosterDealersModel from '../../modal/PosterDealersModel/PosterDealersModel';
import PosterPreviewModal from '../../modal/PosterPreviewModal/PosterPreviewModal';

import { formatDate } from "../../utils/utils";

import './PosterList.css';

const defaultFilterControls = {
  posterCategory: {
    value: '',
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false
  },
  posterStatusRadio: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false
  },
  posterStartDate: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false
  },
  posterEndDate: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false
  }
}

class PosterList extends Component {

  state = {
    posters: [],
    page: 1,
    pageSize: 10,
    isLoading: false,
    totalPages: 0,
    count: 0,
    search: '',

    isTargetUserModalOpen: false,
    targetUserModalPosterUuid: null,
    targetUserModalType: null,
    targetUserModalCount: 0,

    isPosterFilterOpen: false,
    filterApplied: false,
    actionMenuIndex: -1,

    isPreviewImageLightBoxOpen: false,
    imagesToPreview: [],
    imageTitle: null,

    posterCategories: [
      {
        key: 'All',
        value: ''
      },
    ],

    posterStatuses: [
      {
        key: 'Active',
        value: 'true'
      },
      {
        key: 'Inactive',
        value: 'false'
      },
    ],
    controls: JSON.parse(JSON.stringify(defaultFilterControls))
  }

  constructor() {
    super();
    this.actionContainer = React.createRef();
    this.searchData = lodash.debounce(this.searchData, 500);
  }

  componentDidMount() {
    this.getPosters();
    this.getPosterCategory();
  }

  searchData = (search, page) => {
    this.getPosters();
  };

  getPosters = (download_list = false) => {
    const { page, pageSize, search } = this.state;
    this.setState({
      isLoading: true
    });
    const filterObj = this.getReqData();
    let body = {
      page,
      pageSize: pageSize,
      q: search
    }
    body = { ...body, ...filterObj };
    PosterService.getPosterList(body)
      .then(res => {
        if (res.data.data && res.data.data.elements.length > 0) {
          let posters = res.data.data.elements;
          let count = res.data.data.total_count;
          const totalPages = Math.ceil(count / this.state.pageSize);
          this.setState({
            posters,
            count,
            totalPages,
            isLoading: false
          });
        } else {
          let posters = [];
          let count = 0;
          this.setState({
            posters,
            count,
            isLoading: false
          });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  };

  getReqData = () => {
    const { controls } = this.state;
    const { posterCategory, posterStartDate, posterEndDate, posterStatusRadio } = controls;
    let filterObj = {};
    if (posterCategory.value) {
      filterObj.category = posterCategory.value;
    }
    if (posterStartDate.value) {
      posterStartDate.value.setHours(0, 0, 0, 0);
      filterObj.start_date = posterStartDate.value.toISOString();
    }
    if (posterEndDate.value) {
      posterEndDate.value.setHours(23, 59, 59, 59);
      filterObj.end_date = posterEndDate.value.toISOString();
    }
    if (posterStatusRadio.value) {
      filterObj.is_active = posterStatusRadio.value;
    }
    return filterObj;
  }

  getPosterCategory = () => {
    let posterCategories = [{
      key: '',
      label: 'All'
    }]
    this.setState({ isLoading: true });
    PosterService.getPosterCategory()
      .then(data => {
        if (data.data.data) {
          posterCategories = [...posterCategories, ...data.data.data];
        }
        this.setState({ posterCategories, isLoading: false });
      })
      .catch(e => {
        this.setState({ posterCategories, isLoading: false })
        console.error("e", e);
      })
  }

  closeActionMenuWhenClickedOutsideMenu = event => {
    if (this.actionContainer.current && this.actionContainer.current.contains(event.target)) {
    } else {
      this.setState({ actionMenuIndex: -1 })
    }
  };

  activeInactivePoster = (uuid, isActive) => {
    let obj = {
      "uuid": uuid,
      "is_active": isActive
    }
    this.setState({ isLoading: true, actionMenuIndex: -1 });
    PosterService.activeInactivePoster(obj)
      .then(data => {
        this.setState({ isLoading: false });
        this.getPosters();
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }

  handleSearchChange = e => {
    let searchVal = e.target.value;
    this.setState({ page: 1, search: searchVal }, () => {
      this.searchData(searchVal, 1);
    });
  };

  handlePageChange = page => {
    this.setState({ page: page }, () => {
      this.getPosters();
    });
  };

  handleRadioChange = (controlName, e) => {
    let { controls } = this.state;
    let radioControl = controls[controlName]
    radioControl.value = e.target.value;
    this.setState({ controls });
  }

  handleDateChange = (controlName, e) => {
    const { controls } = this.state;
    let dateControl = controls[controlName];
    dateControl.value = e;
    dateControl.touched = true;
    this.setState({ controls });
    // this.handleValidation();
  }

  handleFilterChange = (e) => {
    const controlName = e.target.name;
    let controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls }, () => { });
  }

  applyFilter = () => {
    this.setState({ page: 1, isPosterFilterOpen: false, filterApplied: true }, () => {
      this.getPosters();
    });
  }

  resetFilter = () => {
    this.setState({ isPosterFilterOpen: false, controls: JSON.parse(JSON.stringify(defaultFilterControls)), filterApplied: false }, () => {
      this.getPosters();
    });
  }

  openTargetUserModal = (uuid, type, count) => {
    this.setState({
      targetUserModalPosterUuid: uuid,
      targetUserModalType: type,
      isTargetUserModalOpen: true,
      targetUserModalCount: count
    })
  }

  closeTargetUserModal = () => {
    this.setState({
      targetUserModalPosterUuid: null,
      targetUserModalType: null,
      isTargetUserModalOpen: false,
      targetUserModalCount: 0
    })
  }

  openClosePosterFilter = () => {
    this.setState({ isPosterFilterOpen: !this.state.isPosterFilterOpen });
  }

  OpenActionMenu = (index) => {
    this.setState({ actionMenuIndex: index })
  }

  openCreateUpdatePosterPage = (action, b) => {
    this.props.history.push({
      pathname: "/create-poster",
      posterData: b,
      action: action
    });
  }

  handleInputChange = e => {
    let { pageSize } = this.state;
    pageSize = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        pageSize: pageSize,
        page: 1
      };
    });
    setTimeout(() => {
      this.getPosters();
    }, 1);
  };

  openPreviewImage = (image_url, imageTitle) => {
    this.setState({
      imagesToPreview: [image_url],
      imageTitle,
      isPreviewImageLightBoxOpen: true
    })
  }

  closePreviewImageLightBox = () => {
    this.setState({
      isPreviewImageLightBoxOpen: false
    });
  }

  renderFilterDesign = () => {
    const { controls, posterCategories, posterStatuses } = this.state;
    const { posterStatusRadio, posterStartDate, posterEndDate, posterCategory } = controls;
    return <div className="filter-container">
      <div className="field-title">Category</div>
      <Row className="margin-top-10">
        <Col>
          <select className="form-control select drop-down-control"
            name="posterCategory" value={posterCategory.value}
            onChange={this.handleFilterChange}
          >
            {posterCategories.map(s => (
              <option value={s.key.toLowerCase()}>{s.label}</option>
            ))}
          </select>
        </Col>
      </Row>
      {/* </div> */}
      <div className="field-title margin-top-20">Poster Status</div>
      <Row className="margin-top-12">
        <Col>
          {posterStatuses.map((b, index) => {
            return <>
              <label className="container-rb">
                {b.key}
                <input
                  type="radio"
                  name="posterStatusRadio"
                  value={b.value}
                  onChange={this.handleRadioChange.bind(this, 'posterStatusRadio')}
                  checked={posterStatusRadio.value === b.value}
                />
                <span className="checkmark-rb"></span>
              </label>&nbsp;&nbsp;&nbsp;&nbsp;
              </>
          }
          )}
        </Col>
      </Row>

      <div className="field-title margin-top-20 font-bold">Created on</div>
      <Row className="margin-top-12">
        <Col>
          <FormGroup>
            <Label for="password" className="field-title">Start Date</Label>
            <div>
              <Label className="width-100"
                onClick={e => this.posterStartDate.state.open && e.preventDefault()}
              >
                <InputGroup>
                  <DatePicker className={"form-control calendar-input"}
                    selected={posterStartDate.value}
                    onChange={this.handleDateChange.bind(this, 'posterStartDate')}
                    showMonthDropdown
                    ref={r => this.posterStartDate = r}
                    // shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    placeholderText="Select"
                    shouldCloseOnSelect
                    dropdownMode="select"
                    maxDate={posterEndDate.value ? posterEndDate.value : new Date()}
                  />
                  <InputGroupAddon addonType="append" >
                    <div className="input-group-text">
                      <img src="/images/calendar_icon.svg" alt="" className="calendar-icon" />
                    </div>
                  </InputGroupAddon>
                </InputGroup>
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="password" className="field-title">End Date</Label>
            <div>
              <Label className="width-100"
                onClick={e => this.posterEndDate.state.open && e.preventDefault()}
              >
                <InputGroup>
                  <DatePicker className={"form-control calendar-input"}
                    selected={posterEndDate.value}
                    onChange={this.handleDateChange.bind(this, 'posterEndDate')}
                    showMonthDropdown
                    ref={r => this.posterEndDate = r}
                    // shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    placeholderText="Select"
                    shouldCloseOnSelect
                    dropdownMode="select"
                    maxDate={new Date()}
                    minDate={posterStartDate.value ? posterStartDate.value : new Date(0)}
                  />
                  <InputGroupAddon addonType="append" >
                    <div className="input-group-text">
                      <img src="/images/calendar_icon.svg" alt="" className="calendar-icon" />
                    </div>
                  </InputGroupAddon>
                </InputGroup>
              </Label>

            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginLeft: '0', marginRight: '0' }}>
        <Col xs="12" md="12" lg="12" className="text-align-right" style={{ marginTop: '0', paddingRight: '0px', paddingLeft: '0px' }}>
          <Button className="button cancel-button" onClick={this.resetFilter}
          >Reset</Button>&nbsp;&nbsp;
          <Button className="button create-button" onClick={this.applyFilter}>Apply</Button>
        </Col>
      </Row>
    </div>
  }

  renderActionMenu = (row, i) => {
    const { actionMenuIndex } = this.state;
    return <>
      <Ionicon className="cursor-pointer" icon="md-more" fontSize="24px" color="#fdbb1f" onClick={this.OpenActionMenu.bind(this, i)} />
      {actionMenuIndex === i && <div className="action-menu" ref={this.actionContainer}>
        <div>
          <Row className="action-menu-content cursor-pointer"
            onClick={() =>
              this.openCreateUpdatePosterPage('edit', row)
            }
          >
            <Col sm="1">
              <img
                className="cursor-pointer ml-1"
                style={{ height: "13px" }}
                alt=""
                src="images/edit.svg"
              />
            </Col>
            <Col >Edit</Col>
          </Row>
        </div>
        <div>
          <Row className="customer-list-action-menu-content cursor-pointer" onClick={() => this.activeInactivePoster(row.uuid, !row.is_active)}>
            <Col sm="1">
              <img className=" ml-1" style={{ height: "17px", width: "21px" }} src="images/activate_inactive_icon.svg" alt="" />
            </Col>
            <Col>{row.is_active === true ? 'Inactive' : 'Active'}</Col>
          </Row>
        </div>
      </div>}
    </>
  }

  // UI Components 
  prepareRows = () => {
    let { posters } = this.state;
    return posters.map((b, i) =>
      <tr>
        <td >{formatDate(b.created_at)}</td>
        <td className={b.poster_url.length > 0 ? "count" : ""}
          style={{ maxWidth: '193px', overflow: 'hidden', whiteSpace: 'unset' }}
          onClick={() => this.openPreviewImage(b.poster_url, 'POSTER IMAGE')}
        > {b.poster_url ? <> {'View'} </> : '--'}
        </td>
        <td className={b.reference_poster_url.length > 0 ? "count" : ""}
          style={{ maxWidth: '230px', overflow: 'hidden', whiteSpace: 'unset' }}
          onClick={() => this.openPreviewImage(b.reference_poster_url, 'PREVIEW IMAGE')}
        > {b.reference_poster_url ? <> {'View'} </> : '--'}
        </td>
        <td>{b.category}</td>
        <td className={b.target_users > 0 ? "count" : ""}
          style={{ textAlign: 'center' }}
          onClick={() => b.target_users > 0 ? this.openTargetUserModal(b.uuid, 'total', b.target_users) : null}
        >{b.target_users}</td>
        <td className={b.download > 0 ? "count" : ""}
          style={{ textAlign: 'center' }}
          onClick={() => b.download > 0 ? this.openTargetUserModal(b.uuid, 'download', b.download) : null}
        >{b.download}</td>
        <td>{b.is_active === true ? 'Active' : 'Inactive'}</td>
        <td style={{ position: 'relative', textAlign: 'center' }}>{this.renderActionMenu(b, i)}</td>
      </tr>
    )
  }

  render() {
    const {
      count, isTargetUserModalOpen, targetUserModalPosterUuid, targetUserModalType, search,
      isPosterFilterOpen, filterApplied, isLoading, targetUserModalCount,
      isPreviewImageLightBoxOpen, imagesToPreview, imageTitle
    } = this.state;
    let photoIndex = 0;
    return <div id="poster-list"
      onMouseDown={this.closeActionMenuWhenClickedOutsideMenu}
    >
      {isTargetUserModalOpen && <PosterDealersModel
        show={isTargetUserModalOpen}
        closeModal={this.closeTargetUserModal}
        uuid={targetUserModalPosterUuid}
        type={targetUserModalType}
        count={targetUserModalCount}
      ></PosterDealersModel>}
      <div className="page-title">
        Poster List
      </div>
      {isPreviewImageLightBoxOpen &&
        <PosterPreviewModal show={isPreviewImageLightBoxOpen} closeModal={this.closePreviewImageLightBox}
          data={{ imagesToPreview: imagesToPreview[photoIndex], imageTitle }}>
        </PosterPreviewModal>
      }
      <br />
      <Card>
        <CardBody>
          {isLoading && <CustomSpinner />}
          <Row className="margin-bottom-10 margin-top-10">
            <Col xs="4" className="padding-right-0">
              <Input
                placeholder="Search by Category"
                className="report-search-input"
                name="search"
                value={search}
                onChange={this.handleSearchChange}
              ></Input>
              <img src="images/search.svg" alt="" className="report-search icon-width" />
            </Col>
            <Col xs="2" className="padding-left-0">
              <div>
                <div>
                  <img
                    src={this.state.filterApplied === true ? "images/smaller-blue-dot-hi.png" : "images/filter.svg"}
                    alt=""
                    className={`cursor-pointer ${filterApplied === true ? 'filter-icon-selected' : 'filter-icon'}`}
                    onClick={this.openClosePosterFilter}
                   />
                </div>
                {isPosterFilterOpen && this.renderFilterDesign()}
              </div>
            </Col>
            <Col className="text-align-right">
              <button
                className="button create-button"
                style={{ width: '158px' }}
                onClick={() => this.openCreateUpdatePosterPage('add', null)}
              >Create Poster
              </button>
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th rowSpan="2">Created Date</th>
                <th rowSpan="2" style={{ textAlign: 'center' }}>Poster Image</th>
                <th rowSpan="2" style={{ textAlign: 'center' }}>Preview Image</th>
                <th rowSpan="2">Category</th>
                <th rowSpan="2" style={{ textAlign: 'center' }}>Total Dealers</th>
                <th rowSpan="2" style={{ width: '120px', textAlign: 'center' }}>Total Download Count</th>
                <th rowSpan="2">Status</th>
                <th rowSpan="2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.prepareRows()}
            </tbody>
          </Table>
          {count <= 0 && filterApplied === false && (
            <div className="no-data tbl-offtake-summary" style={{ maxWidth: "100%" }}>
              There are no Poster created yet. To create a new Poster, click on “Create Poster” button
            </div>
          )}
          {count <= 0 && filterApplied === true && (
            <div className="no-data tbl-offtake-summary" style={{ maxWidth: "100%" }}>
              There are no Poster for applied filter.
            </div>
          )}
          {count > 0 && (
            <div style={{ marginTop: "5px" }}>
              <div className="d-flex-row" style={{ marginTop: "10px", justifyContent: "flex-end" }}>
                <span className="Rows-per-page">Rows per page:</span>
                <select className="form-control rows-per-page-select" name="pageSize" value={this.state.pageSize}
                  onChange={e => this.handleInputChange(e)} style={{ width: "65px" }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                </select> &nbsp;&nbsp;
                <div style={{ marginTop: "5px" }}>
                  <Pagination
                    margin={2}
                    page={this.state.page}
                    count={this.state.totalPages}
                    pageSize={this.state.pageSize}
                    totalRecords={count}
                    onPageChange={this.handlePageChange}
                  ></Pagination>
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  }
}

export default PosterList;
