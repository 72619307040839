import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Form
} from "reactstrap";

import Validation from "../../services/Validation";
import UserService from "../../services/UserService";
import StorageService from "../../services/Storage";

import { messages } from "../../constants/constant.message";

import "./ChangePassword.css";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";

let {
  EMPTY_CURRENT_PASSWORD,
  EMPTY_NEW_PASSWORD,
  INVALID_NEW_PASSWORD,
  PASSWORD_NOT_MATCH
} = messages.UPDATE_PASSWORD;

let { ERROR_MESSAGE } = messages.COMMON;

class ChangePassword extends React.Component {
  state = {
    controls: {
      currentPassword: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null
      },
      newPassword: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null
      },
      confirmPassword: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        passwordNotMatch: false,
        isEmpty: false
      },
      logoutFromOther: false,
      changePasswordErrorMessage: null,
      modalMessage: ""
    },
    isFormValid: false
  };

  componentDidMount() {
    GoogleAnalticsService.pageView('Change Password Dialogue View');
    let { controls } = this.state;
    controls["confirmPassword"].passwordNotMatch = false;
    this.setState({ controls });
  }

  setLogoutFromOtherCheckboxValue = () => {
    const { controls } = this.state;
    // const { agreeTermAndCondition } = controls;
    controls.logoutFromOther = !controls.logoutFromOther;
    // agreeTermAndCondition.value = !agreeTermAndCondition.value;
    this.setState({ controls });
    // this.handleValidation();
  };

  handleInputChange = e => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    if (controlName === "currentPassword") {
      this.setState({
        changePasswordErrorMessage: null
      });
    }
    this.setState({ controls });
    // this.handleValidation();
  };

  handleValidation = () => {
    let { controls, isFormValid } = this.state;
    let { currentPassword, newPassword, confirmPassword } = controls;

    currentPassword = Validation.notNullValidator(currentPassword);
    currentPassword.valid = !currentPassword.nullValue;

    newPassword = Validation.notNullValidator(newPassword);
    newPassword = Validation.maxLengthValidator(newPassword, 20);
    newPassword.valid = !(newPassword.invalidLength || newPassword.nullValue);

    confirmPassword = Validation.notNullValidator(confirmPassword);

    confirmPassword = Validation.maxLengthValidator(confirmPassword, 20);

    if (newPassword.value !== confirmPassword.value) {
      confirmPassword.passwordNotMatch = true;
    } else {
      confirmPassword.passwordNotMatch = false;
    }
    confirmPassword.valid = !confirmPassword.passwordNotMatch;

    if (
      currentPassword.valid === true &&
      newPassword.valid === true &&
      confirmPassword.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    this.setState({ controls, isFormValid });

    return isFormValid;
  };

  openDetailRequestSubmitSuccessAlert() { }

  updatePassword = e => {
    e.preventDefault();
    let { controls } = this.state;
    let { currentPassword, newPassword, logoutFromOther } = controls;

    const isFormValid = this.handleValidation();
    if (isFormValid === false) {
      return;
    }

    let token = StorageService.getTokenDetail();

    let obj = {
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
      logoutFromOther,
      accessToken: token.accessToken,
      refreshToken: token.refreshToken
    };
    this.setState({ changePasswordErrorMessage: null });
    UserService.updatePassword(obj)
      .then(data => {
        GoogleAnalticsService.event('Change Password', 'Password Change Submit');
        this.props.closeModal(true, data.data.message);
      })
      .catch(e => {
        let err = e.response && e.response.data && e.response.data.error[0]
          ? e.response.data.error[0]
          : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ changePasswordErrorMessage: message });
        console.error("e", e);
      });
  };

  closeModal = () => {
    this.setState({ isRequestSubmitSuccessModalOpen: false });
    this.props.closeModal(true);
  };

  render() {
    const { controls, changePasswordErrorMessage } = this.state;
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      logoutFromOther
    } = controls;

    return (
      <div>
        <Modal
          id="change-password"
          isOpen={this.props.show}
          toggle={this.props.closeModal}
          centered
        >
          {/* <ModalHeader toggle={this.toggle} >REQUEST TO EDIT DETAILS</ModalHeader> */}
          <ModalBody>
            <Form>
              <Row className="modal-title-border padding-bottom-10">
                <Col className="modal-title">CHANGE PASSWORD</Col>
              </Row>
              <div></div>
              <Row>
                <Col>
                  <FormGroup className="margin-top-10">
                    <Label for="currentPassword">Current Password</Label>
                    <Input
                      type="password"
                      name="currentPassword"
                      id="currentPassword"
                      value={currentPassword.value}
                      onChange={this.handleInputChange}
                    />

                    {changePasswordErrorMessage && (
                      <div className="error"> {changePasswordErrorMessage}</div>
                    )}
                    {currentPassword.nullValue && (
                      <div className="error">* {EMPTY_CURRENT_PASSWORD}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="margin-top-10">
                    <Label for="newPassword">New Password</Label>
                    <Input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      value={newPassword.value}
                      onChange={this.handleInputChange}
                    />
                    {newPassword.nullValue && (
                      <div className="error">* {EMPTY_NEW_PASSWORD}</div>
                    )}
                    {newPassword.invalidLength && (
                      <div className="error">* {INVALID_NEW_PASSWORD}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="margin-top-10">
                    <Label for="confirmPassword">Confirm New Password</Label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      value={confirmPassword.value}
                      onChange={this.handleInputChange}
                    />
                    {!confirmPassword.nullValue &&
                      confirmPassword.passwordNotMatch && (
                        <div className="error">* {PASSWORD_NOT_MATCH}</div>
                      )}
                    {confirmPassword.nullValue && (
                      <div className="error">
                        * Confirm password should not be empty.
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="container-cb">
                    <input
                      type="checkbox"
                      name="checkbox-cp"
                      id="checkbox-cp"
                      checked={logoutFromOther}
                      onChange={this.setLogoutFromOtherCheckboxValue}
                    />{" "}
                    Logout from other device
                    <span className="checkmark"></span>
                  </label>
                  {/* <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                      checked={logoutFromOther}
                      onChange={this.setLogoutFromOtherCheckboxValue}
                    />{' '}
                    Logout from other device
                  </Label>
                </FormGroup> */}{" "}
                </Col>
              </Row>
              <br />
              <div style={{ textAlign: "right" }}>
                <Button
                  className="mb-1 mr-1 btn-pill Rectangle-button cancel-button"
                  onClick={this.props.closeModal}
                  color="dark"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="mb-1 mr-1 btn-pill Rectangle-button send-button"
                  onClick={this.updatePassword}
                  color="warning"
                >
                  Save
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ChangePassword;
