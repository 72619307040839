import React, { Component } from "react";
import lodash from "lodash"; // library added for debounce functionality

// modal
import SpeacialEngagement from "../../modal/SpeacialEngagement/SpeacialEngagement";

// components
import DashboardEmployeeWeb from "../../components/DashboardEmployeeWeb/DashboardEmployeeWeb";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

// services
import DashboardService from "../../services/DashboardService";
import WarrantyScoreCardService from "../../services/WarrantyScoreCardService";
import SpeacialEngagementService from "../../services/SpecialEngagementService";
import StorageService from "../../services/Storage";

// constant
import { config } from "../../constants/constant.config";

// utils
import {
  formatNumber,
  findPreviousQuarterMonths,
  findFirstQuarterToCurrentMonths,
  findMonthFromFirstQToPreviousQMonths,
  getCurrentMonth,
  getCurrentQuarterMonths,
  checkUserAgent,
  findCurrentQuarterMonths,
} from "../../utils/utils";

// css
import "./DashboardEmployee.css";
import {
  askForPermissioToReceiveNotifications,
  initializeFirebase,
} from "../../push-notification";
import { Roles } from "../../constants/constant.role";

const dashBoardImages = config.DASHBOARD;

class DashboardEmployee extends Component {
  state = {
    yearObj: {
      totalPoints: 0,
      data: [],
    },
    yearForWarrantyPoints: {},
    quarterObj: {
      totalPoints: 0,
      data: [],
    },
    monthObj: {
      totalPoints: 0,
      data: [],
    },
    currentQMonths: "",
    year_name: null,
    monthFromFirstQtoPreviousQ: "",
    firstQToCurrentMonth: "",
    currentMonth: "",
    totalPrimaryPoints: 0,
    totalPrivionalPoints: 0,
    totalSeconadryPoints: 0,
    totalYearlyOfftake: 0,
    noWarrantyCustomers: 0,
    categoryImages: null,
    warrantyTotalPoints: 0,
    warrantyTotalRegisteredCustomers: 0,
    warrantyTotalRegisteredCustomersCurrentMonth: 0,
    warrantyTotalPointsCurrentMonth: 0,
    redirectToWarrantyRegistration: false,
    search: "",
    stackedChartWeb: {
      categories: [
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "Mar",
      ],
      series: [
        {
          name: "Pcr",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Truck",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Wheeler",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Farm",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Lcv",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Scv",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      stackedChartMobile: {
        categories: {
          q1: ["Apr", "May", "Jun"],
          q2: ["Apr", "May", "Jun"],
          q3: ["Apr", "May", "Jun"],
          q4: ["Apr", "May", "Jun"],
        },
        series: {
          q1: [
            {
              name: "Pcr",
              data: [0, 0, 0],
            },
            {
              name: "Truck",
              data: [0, 0, 0],
            },
            {
              name: "Wheeler",
              data: [0, 0, 0],
            },
            {
              name: "Farm",
              data: [0, 0, 0],
            },
            {
              name: "Lcv",
              data: [0, 0, 0],
            },
            {
              name: "Scv",
              data: [0, 0, 0],
            },
          ],
          q2: [
            {
              name: "Pcr",
              data: [0, 0, 0],
            },
            {
              name: "Truck",
              data: [0, 0, 0],
            },
            {
              name: "Wheeler",
              data: [0, 0, 0],
            },
            {
              name: "Farm",
              data: [0, 0, 0],
            },
            {
              name: "Lcv",
              data: [0, 0, 0],
            },
            {
              name: "Scv",
              data: [0, 0, 0],
            },
          ],
          q3: [
            {
              name: "Pcr",
              data: [0, 0, 0],
            },
            {
              name: "Truck",
              data: [0, 0, 0],
            },
            {
              name: "Wheeler",
              data: [0, 0, 0],
            },
            {
              name: "Farm",
              data: [0, 0, 0],
            },
            {
              name: "Lcv",
              data: [0, 0, 0],
            },
            {
              name: "Scv",
              data: [0, 0, 0],
            },
          ],
          q4: [
            {
              name: "Pcr",
              data: [0, 0, 0],
            },
            {
              name: "Truck",
              data: [0, 0, 0],
            },
            {
              name: "Wheeler",
              data: [0, 0, 0],
            },
            {
              name: "Farm",
              data: [0, 0, 0],
            },
            {
              name: "Lcv",
              data: [0, 0, 0],
            },
            {
              name: "Scv",
              data: [0, 0, 0],
            },
          ],
        },
      },
    },
    stackedChart: null,
    isLoading: true,
    isSpecialEngagementModalOpen: false,
    year: null,
  };

  constructor() {
    super();
    this.searchReport = lodash.debounce(this.searchReport, 500);
  }

  componentDidMount() {
    // initializeFirebase();
    askForPermissioToReceiveNotifications();
    const userDetail = StorageService.getUserDetail();
    const { type: dealerRole } = userDetail;
    if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      this.getPopupLink();
    }

    window.scroll(0, 0);
    // let currentQMonths = findCurrentQuarterMonths();

    const monthFromFirstQtoPreviousQ = findMonthFromFirstQToPreviousQMonths();
    const currentMonth = getCurrentMonth();
    const currentQMonths = findCurrentQuarterMonths();
    const firstQToCurrentMonth = findFirstQuarterToCurrentMonths();

    const stackedChart = this.prepareStackedChart(
      this.state.stackedChartWeb.categories,
      this.state.stackedChartWeb.series
    );
    this.setState({
      stackedChart,
      currentQMonths,
      currentMonth,
      firstQToCurrentMonth,
      monthFromFirstQtoPreviousQ,
    });
    this.prepareDataQuarterWise(
      this.state.stackedChartWeb.categories,
      this.state.stackedChartWeb.series
    );
    // this.loadInitialData();
  }

  loadInitialData = (year) => {
    this.setState({ year });
    return Promise.all([
      this.getEmployeeDashboardPrimaryPoints(null, year),
      this.getWarrantyDealerAndPoints(1, "", year),
      this.getDisplayLabel(year),
    ])
      .then((data) => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  getDisplayLabel = (year) => {
    // this.setState({ isLoading: true });
    DashboardService.getDisplayLabel(year)
      .then((data) => {
        const labels = data.data && data.data.data ? data.data.data : null;
        // this.setState({ isLoading: false });
        this.setState({ displayLabels: labels });
      })
      .catch((e) => {
        // this.setState({ isLoading: false });
      });
  };

  prepareStackedChart = (categories, series) => {
    const stackedChart = {
      options: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: "",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },

        // title: {
        //   text: 'Yearly Offtake'
        // },
        xaxis: {
          categories,
          labels: {
            formatter(val) {
              return val;
            },
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          tickAmount: 3,
          crosshairs: {
            show: false,
            position: "back",
            stroke: {
              color: "#b6b6b6",
              width: 1,
              dashArray: 0,
            },
          },
          axisBorder: {
            show: false,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
        },
        tooltip: {
          y: {
            formatter(val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "right",
          horizontalAlign: "left",
          offsetX: 0,
          offsetY: 0,
          formatter(val) {
            if (val === "PCR") {
              return `<img src = "${dashBoardImages.PCR_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "Truck") {
              return `<img src = "${dashBoardImages.TRUCK_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "Farm") {
              return `<img src = "${dashBoardImages.FARM_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "TW") {
              return `<img src = "${dashBoardImages.WHEELER_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "LCV") {
              return `<img src = "${dashBoardImages.LCV_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            if (val === "SCV") {
              return `<img src = "${dashBoardImages.SCV_IMAGE}" alt="" height = "18px" width = "43px" style = "margin-left:5px;object-fit:contain;margin-bottom: 8px;">`;
            }
            // return val
          },
          markers: {
            width: 38,
            height: 18,
          },
        },
        grid: {
          show: true,
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: [
          "#c970ff",
          "#9ad6d0",
          "#30b1ff",
          "#9ad851",
          "#9e95e2",
          "#fac2c0",
        ],
      },
      // series: [
      //   {
      //     name: 'Pcr',
      //     data: [44, 55, 41, 37, 22, 43, 21, 0, 0, 0, 0, 0]
      //   },
      //   {
      //     name: 'Truck',
      //     data: [44, 55, 41, 37, 22, 43, 21, 0, 0, 0, 0, 0]
      //   },
      //   {
      //     name: 'Wheeler',
      //     data: [44, 55, 41, 37, 22, 43, 21, 0, 0, 0, 0, 0]
      //   },
      //   {
      //     name: 'Farm',
      //     data: [53, 32, 33, 52, 13, 43, 32, 0, 0, 0, 0, 0]
      //   }
      // ],
      series,
    };

    return stackedChart;
  };

  prepareDataQuarterWise = (categories, series) => {
    // categories = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];
    const qCategories = creatCategoriesQuarterWise(categories);
    let pcrSeries = [];
    let truckSeries = [];
    let wheelerSeries = [];
    let farmSeries = [];
    let lcvSeries = [];
    let scvSeries = [];

    for (let i = 0; i < series.length; i++) {
      if (series[i].name === "Pcr") {
        pcrSeries = series[i].data;
      } else if (series[i].name === "Truck") {
        truckSeries = series[i].data;
      } else if (series[i].name === "Wheeler") {
        wheelerSeries = series[i].data;
      } else if (series[i].name === "Farm") {
        farmSeries = series[i].data;
      } else if (series[i].name === "Lcv") {
        lcvSeries = series[i].data;
      } else if (series[i].name === "Scv") {
        scvSeries = series[i].data;
      }
    }

    // const pcrSeries = series[0].data;
    // const truckSeries = series[1].data;
    // const wheelerSeries = series[2].data;
    // const farmSeries = series[3].data;
    // const lcvSeries = series[4].data;
    // const scvSeries = series[5].data;

    const qPcrSeries = createDataQuarterWise(pcrSeries);
    const qTruckSeries = createDataQuarterWise(truckSeries);
    const qFarmSeries = createDataQuarterWise(farmSeries);
    const qWheelerSeries = createDataQuarterWise(wheelerSeries);
    const qLcvSeries = createDataQuarterWise(lcvSeries);
    const qScvSeries = createDataQuarterWise(scvSeries);

    const q1 = prepareQObj("q1");
    const q2 = prepareQObj("q2");
    const q3 = prepareQObj("q3");
    const q4 = prepareQObj("q4");

    const q1Total = getQuarterTotal("q1");
    const q2Total = getQuarterTotal("q2");
    const q3Total = getQuarterTotal("q3");
    const q4Total = getQuarterTotal("q4");

    function creatCategoriesQuarterWise(series) {
      const q1 = series.slice(0, 3);
      const q2 = series.slice(3, 6);
      const q3 = series.slice(6, 9);
      const q4 = series.slice(9, 12);
      return { q1, q2, q3, q4 };
    }

    function createDataQuarterWise(series) {
      const q1 = series.slice(0, 3);
      const q2 = series.slice(3, 6);
      const q3 = series.slice(6, 9);
      const q4 = series.slice(9, 12);

      const q1Total = getQuarterCategoryWiseTotal(q1);
      const q2Total = getQuarterCategoryWiseTotal(q2);
      const q3Total = getQuarterCategoryWiseTotal(q3);
      const q4Total = getQuarterCategoryWiseTotal(q4);

      return { q1, q2, q3, q4, q1Total, q2Total, q3Total, q4Total };
    }

    function getQuarterCategoryWiseTotal(series) {
      series[0] = parseInt(series[0]);
      series[1] = parseInt(series[1]);
      series[2] = parseInt(series[2]);
      return series[0] + series[1] + series[2];
    }

    function getQuarterTotal(quarter) {
      let total = 0;
      total += qPcrSeries[`${quarter}Total`];
      total += qTruckSeries[`${quarter}Total`];
      total += qWheelerSeries[`${quarter}Total`];
      total += qFarmSeries[`${quarter}Total`];
      total += qLcvSeries[`${quarter}Total`];
      total += qScvSeries[`${quarter}Total`];
      return total;
    }

    function prepareQObj(quarter) {
      return {
        categories: qCategories[quarter],
        series: [
          {
            name: "Pcr",
            data: qPcrSeries[quarter],
          },
          {
            name: "Truck",
            data: qTruckSeries[quarter],
          },
          {
            name: "Wheeler",
            data: qWheelerSeries[quarter],
          },
          {
            name: "Farm",
            data: qFarmSeries[quarter],
          },
          {
            name: "Lcv",
            data: qLcvSeries[quarter],
          },
          {
            name: "Scv",
            data: qScvSeries[quarter],
          },
        ],
      };
    }

    const s = { q1, q2, q3, q4 };
    const c = qCategories;
    const total = {
      q1: q1Total,
      q2: q2Total,
      q3: q3Total,
      q4: q4Total,
    };

    const obj = {
      series: s,
      categories: c,
      total,
    };
    this.setState({ stackedChartMobile: obj });
    // return { q1, q2, q3, q4 }
  };

  getEmployeeDashboardPrimaryPoints = (search = null, year) => {
    this.setState({ isLoading: true });
    return DashboardService.getEmployeeDashboardPrimaryPoints(search, year)
      .then((data) => {
        const {
          total_primary,
          provisional_points,
          monthly,
          quartely,
          yearly,
          by_month,
          images,
          total_primary_earned,
        } = data.data.data;
        const totalPrimaryPoints = total_primary_earned
          ? parseInt(total_primary_earned)
          : 0;
        const totalPrivionalPoints = provisional_points
          ? parseInt(provisional_points)
          : 0;
        const monthObj = monthly;
        const quarterObj = quartely;
        const yearObj = yearly;
        yearObj.total_points = totalPrimaryPoints;
        const categoryImages = images;
        const obj = by_month;
        const categories = obj.months;
        const series = obj.series;
        const totalYearlyOfftake = obj.total;
        const o = {
          categories,
          series,
        };
        const stackedChart = this.prepareStackedChart(o.categories, o.series);

        this.prepareDataQuarterWise(categories, series);
        this.setState({ isLoading: false });
        this.setState({
          totalPrimaryPoints,
          totalPrivionalPoints,
          monthObj,
          quarterObj,
          yearObj,
          categoryImages,
          stackedChartWeb: o,
          stackedChart,
          totalYearlyOfftake,
        });
        return Promise.resolve(null);
      })
      .catch((e) => {
        console.log("err", e);
        this.setState({ isLoading: false });
        return Promise.reject(null);
      });
  };

  openPrimaryReportScreen = () => {
    this.props.history.push("/offtake-score-card-report");
  };

  openSecondaryReportScreen = () => {
    this.props.history.push("/warranty-score-card-report");
  };

  getWarrantyDealerAndPoints = (page, search, year) => {
    this.setState({ isLoading: true });
    return WarrantyScoreCardService.getAllDealersAndPoints(
      page,
      0,
      search,
      null,
      null,
      year
    )
      .then((data) => {
        this.setState({ isLoading: false });
        const { total_card, current_month_card, year_card } = data.data.data;
        const warrantyTotalPoints = total_card.warranty_points;
        const warrantyTotalRegisteredCustomers = total_card.warranty_customers;
        const yearForWarrantyPoints = year_card;
        const warrantyTotalRegisteredCustomersCurrentMonth =
          current_month_card.warranty_customers;
        const warrantyTotalPointsCurrentMonth =
          current_month_card.warranty_points;

        this.setState({
          warrantyTotalPoints,
          warrantyTotalRegisteredCustomers,
          warrantyTotalRegisteredCustomersCurrentMonth,
          warrantyTotalPointsCurrentMonth,
          yearForWarrantyPoints,
        });
        return Promise.resolve(null);
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        return Promise.reject(null);
      });
  };

  searchReport = (search) => {
    this.getWarrantyDealerAndPoints(1, search);
    this.getEmployeeDashboardPrimaryPoints(search);
  };

  handleSearchChange = (e) => {
    const searchVal = e.target.value;
    this.setState({ search: searchVal });
    this.searchReport(searchVal);
  };

  getPopupLink = () => {
    SpeacialEngagementService.getPopupLink()
      .then((data) => {
        if (data.data && data.data.data.link) {
          this.setState({ isSpecialEngagementModalOpen: true });
        }
      })
      .catch((e) => { });
  };

  closeSpecialEngagementModal = () => {
    this.setState({ popupData: null, isSpecialEngagementModalOpen: false });
  };

  yearFilterApplied = (year, year_name) => {
    if (year_name) {
      this.setState({
        year_name,
      });
    }
    this.loadInitialData(year);
  };

  render() {
    const { isSpecialEngagementModalOpen } = this.state;
    return (
      <div id="dashboard">
        {isSpecialEngagementModalOpen && (
          <SpeacialEngagement
            show={isSpecialEngagementModalOpen}
            // popupDetail={popupDetail}
            closeModal={this.closeSpecialEngagementModal}
          ></SpeacialEngagement>
        )}
        {this.state.isLoading && <CustomSpinner />}
        <DashboardEmployeeWeb
          {...this.state}
          history={this.props.history}
          handleInputChange={this.handleInputChange}
          handleValidation={this.handleValidation}
          sendOTP={this.sendOTP}
          cancelOTP={this.cancelOTP}
          yearFilterApplied={this.yearFilterApplied}
          verifyOTP={this.verifyOTP}
          prepareStackedChart={this.prepareStackedChart}
          openPrimaryReportScreen={this.openPrimaryReportScreen}
          openSecondaryReportScreen={this.openSecondaryReportScreen}
          handleSearchChange={this.handleSearchChange}
        />
      </div>
    );
  }
}

export default DashboardEmployee;
