import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types'; // or PropTypes = require('prop-types');
import StorageService from '../../services/Storage';
import AuthService from '../../services/AuthService';
import ComponetWithHeader from '../CompentWithHeader/ComponetWithHeader';
import ComponentWithoutHeader from '../ComponentWithoutHeader/ComponentWithoutHeader';
import ComponentWithoutSideNav from '../ComponentWithoutSideNav/ComponentWithoutSideNav';
import { Roles } from '../../constants/constant.role';


/**
 * private route component to prevent unauthorize access
 */
const OnBoardingRoute = ({ component: Component, sidenav, header, ...rest }) => {
  // uncomment  when authentication api implement

  const isAuthenticated = AuthService.isAuthenticated();
  return (

    <Route
      {...rest}
      render={props => {
        if (isAuthenticated === true) {
          let userDetail = StorageService.getUserDetail()
          if (
            userDetail.type === Roles.ZONE_MANAGER_ROLE ||
            userDetail.type === Roles.REGION_MANAGER_ROLE ||
            userDetail.type === Roles.AREA_MANAGER_ROLE ||
            userDetail.type === Roles.SALES_OFFICER_ROLE ||
            userDetail.is_login_first_time === false) {
            return <Redirect to={{
              pathname: '/dashboard',
            }}
            />
          } else if (userDetail.type === Roles.HEAD_OFFICE_ROLE || userDetail.type === Roles.PROJECT_MANAGER_ROLE) {
            return <Redirect to={{
              pathname: '/dashboard',
            }}
            />

          }
          else if (userDetail.is_login_first_time === false) {
            return <Redirect to={{
              pathname: '/',
            }}
            />
          } else if ((userDetail.type === Roles.SUB_DEALER_ROLE ||
            userDetail.type === Roles.BRANCH_ROLE) &&
            userDetail.is_login_first_time === false
          ) {
            return <Redirect to={{
              pathname: '/warranty-score-card',
            }}
            />
          } else if (userDetail.type === Roles.MARKETING_ROLE) {
            return <Redirect to={{
              pathname: '/set-notification',
            }}
            />
          } else if (userDetail.type === Roles.SUPPORT_ROLE) {
            return <Redirect to={{
              pathname: '/merchandise-orders',
            }}
            />
          }


          if (header === true && sidenav === true) {
            return <ComponetWithHeader component={Component} {...props} />
          } else if (header === true && sidenav === false) {
            return <ComponentWithoutSideNav component={Component} {...props} />
          } else {
            return <ComponentWithoutHeader component={Component} {...props} />
          }
        } else {
          return <Redirect to={{
            pathname: '/login',
          }}
          />
        }
      }
      }
    />
  );
};

OnBoardingRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default OnBoardingRoute;
