import React, { Component } from 'react';
import {
  Row, Col, Button, ModalBody, Label, Modal
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import './CipPictureUpload.css';
import UserService from '../../services/UserService';
import ModalService from '../../services/ModalService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import Validation from '../../services/Validation';
import CustomerIntractionServie from '../../services/CustomerIntractionService';

class CipPictureUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      imagesDisplay: [],
      isOpenLightbox: false,
      controls: {
        picture1: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        picture2: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        picture3: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        }
      },
      cip_data: {},
      is_view_picture: false
    }
  }

  openLightBox = (img) => {
    if (!img) {
      return
    }
    let { imagesDisplay } = this.state;
    imagesDisplay = [];
    imagesDisplay.push(img);
    this.setState({
      imagesDisplay,
      isOpenLightbox: true
    })
  }

  toggle = (data = {}, is_view_pic = false) => {
    if (!this.state.visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    let { cip_data, is_view_picture, controls } = this.state;
    is_view_picture = is_view_pic;
    cip_data = data;
    if (cip_data && Object.keys(cip_data).length > 0) {
      controls.picture1.value = cip_data.image_1;
      controls.picture2.value = cip_data.image_2;
      controls.picture3.value = cip_data.image_3;
    }
    this.setState({
      controls,
      visible: !this.state.visible,
      cip_data,
      is_view_picture
    }, () => {
      // if (this.state.visible) {
      //   this.props.openCoAttendee();
      // }
    })
  }

  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let { picture1, picture2 } = controls;
    let isValidForm = false;

    if (picture1.touched === true || isSubmit) {
      picture1 = Validation.notNullValidator(picture1);
      picture1.valid = !picture1.nullValue;
      if (picture1.valid === false) {
        picture1.showErrorMsg = true;
      } else {
        picture1.showErrorMsg = false;
      }
    }
    if (picture2.touched === true || isSubmit) {
      picture2 = Validation.notNullValidator(picture2);
      picture2.valid = !picture2.nullValue;
      if (picture2.valid === false) {
        picture2.showErrorMsg = true;
      } else {
        picture2.showErrorMsg = false;
      }
    }

    if (picture1.valid === true &&
      picture2.valid === true) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({
      controls
    })
    return isValidForm;

  }
  submitPicture = () => {
    if (!this.handleValidation(true)) {
      return
    }
    this.setState({ isLoading: true });
    let { controls } = this.state;
    let reqData =
    {
      "uuid": this.state.cip_data.uuid,
      "image_1": controls.picture1.value,
      "image_2": controls.picture2.value,
      "image_3": controls.picture3.value,
      "end": true
    }
    CustomerIntractionServie.endCIP(reqData)
      .then((res) => {
        if (this.props.getCip) {
          this.props.getCip(false);
        }
        // if (this.props.from === 'CipDetail') {
        if (!this.state.cip_data.expenses) {
          this.props.openExpensesIncurred(this.state.cip_data);
        } else {
          this.cancel();
        }
        // } else {
        //   swal({
        //     title: "",
        //     text: res.data.message,
        //     icon: "success",
        //     type: "success",
        //   }).then((value) => {

        //   });
        // }
        this.setState({ isLoading: false });
        this.toggle({});
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  selectPicture = (fileInput) => {
    let { controls } = this.state;
    if (this[fileInput]) {
      this[fileInput].click();
      if (fileInput === 'fileInput1') {
        controls.picture1.showErrorMsg = false;
        controls.picture1.touched = true;
      } else if (fileInput === 'fileInput2') {
        controls.picture2.showErrorMsg = false;
        controls.picture2.touched = true;
      } else if (fileInput === 'fileInput3') {
        controls.picture3.showErrorMsg = false;
        controls.picture3.touched = true;
      }
      this.setState({
        controls
      });
    }
  };


  uploadFile = (event, fileInput) => {
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      file.append("type", 'cip_image');
      if (event.target.files[0].size > 5000000) {
        ModalService.openAlert("", "Please upload valid file !", "error");
      }
      this.handleUploadImage({
        file: event.target.files[0],
        type: 'cip_image'
      }, fileInput);
    }
  };

  openDeletePictureModal = fileInput => {
    let { controls } = this.state;
    if (fileInput === 'fileInput1') {
      controls.picture1.value = null;
      if (this.fileInput1) {
        this.fileInput1.value = '';
      }
    } else if (fileInput === 'fileInput2') {
      controls.picture2.value = null;
      if (this.fileInput2) {
        this.fileInput2.value = '';
      }
    } else if (fileInput === 'fileInput3') {
      controls.picture3.value = null;
      if (this.fileInput3) {
        this.fileInput3.value = '';
      }
    }
    this.setState({
      controls
    })
    // swal({
    //   title: "Delete Picture",
    //   text: "Are you sure, you want to delete picture ?",
    //   icon: "warning",
    //   buttons: ["No", "Yes"]
    // }).then(isConfirm => {
    //   if (isConfirm) {
    //     this.deleteGroup(groupId);
    //   }
    // });
  };

  handleUploadImage = (file, fileInput) => {
    this.setState({
      isLoading: true
    })
    UserService.uploadImage(file)
      .then((data) => {
        let { controls } = this.state;
        if (data && data.data && data.data.data && data.data.data.imageUrl) {
          if (fileInput === 'fileInput1') {
            controls.picture1.value = data.data.data.imageUrl;
            if (this.fileInput1) {
              this.fileInput1.value = '';
            }
          } else if (fileInput === 'fileInput2') {
            controls.picture2.value = data.data.data.imageUrl;
            if (this.fileInput2) {
              this.fileInput2.value = '';
            }
          } else if (fileInput === 'fileInput3') {
            controls.picture3.value = data.data.data.imageUrl;
            if (this.fileInput3) {
              this.fileInput3.value = '';
            }
          }
        } else {
          if (fileInput === 'fileInput1') {
            controls.picture1.value = null;
          } else if (fileInput === 'fileInput2') {
            controls.picture2.value = null;
          } else if (fileInput === 'fileInput3') {
            controls.picture3.value = null;
          }
        }
        this.handleValidation();
        this.setState({
          controls,
          isLoading: false
        })
        // this.props.isProfileUpdate(true);
        // this.setState({
        //   profileImage: URL.createObjectURL(file),
        //   isImageUpdating: false,
        // });
      })
      .catch((e) => {
        this.setState({
          isLoading: false
        });
      });
  };

  cancel = () => {
    if (this.props.redirectSummary) {
      this.props.redirectSummary();
    }
    this.toggle({});
  }
  render() {
    let { imagesDisplay, isOpenLightbox, controls } = this.state;
    let photoIndex = 0;
    return (
      <Modal
        isOpen={this.state.visible}
        toggle={this.toggle}
        className="modal-md modal-lg custom-confirm-modal modal-sm modal-dialog-centered"
        style={{ maxWidth: "575px" }}
        backdrop={this.backdrop ? "static" : "nostatic"}
      >
        {isOpenLightbox && (
          <Lightbox
            mainSrc={imagesDisplay[photoIndex]}
            nextSrc={0}
            prevSrc={0}
            onCloseRequest={() => this.setState({ isOpenLightbox: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + imagesDisplay.length - 1) % imagesDisplay.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % imagesDisplay.length,
              })
            }
          />
        )}
        {this.state.isLoading && <CustomSpinner />}
        <div className="d-flex-column">
          <div className="confirm-modal-title-wrapp">
            {
              this.state.is_view_picture ? <span className="confirm-modal-title">VIEW PICTURE</span>
                : <span className="confirm-modal-title">END CIP</span>
            }
            <FontAwesomeIcon className="pull-right cursor-pointer" onClick={() => this.toggle({})} icon={faTimes} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
          </div>
        </div>
        {
          !this.state.is_view_picture ?
            <ModalBody style={{ padding: '1.1rem' }}>
              <Row>
                <Col xl="12" xs="12" md="12" className="mt-1 mb-4">
                  <div style={{ fontSize: '16px' }}>As Requested, Please upload photographs.</div>
                </Col>
                <Col xl="4" md="12" xs="12">
                  <Label for="common" className="font-weight-bold">Picture1<span className="required-field">*</span></Label>
                  {
                    controls.picture1.value ?
                      <div className="d-flex-row">
                        <img
                          src={controls.picture1.value}
                          className="cip-uploaded-img"
                          onClick={() => { this.openLightBox(controls.picture1.value) }}
                          style={{ cursor: "pointer" }}
                          alt=''
                        />
                        <div className="d-flex-column justify-content-between ml-2">
                          <img src="/images/edit.svg" alt='' className="cursor-pointer" style={{ height: '16px', width: '16px' }} onClick={() => this.selectPicture('fileInput1')} />&nbsp;&nbsp;
                          <img src="/images/delete.svg" alt='' className="cursor-pointer"
                            onClick={() => this.openDeletePictureModal('fileInput1')} style={{ height: '14px' }} />
                        </div>
                      </div> : <div
                        onClick={() => this.selectPicture('fileInput1')}
                        className="select-picture-wrapper mt-0"
                      >
                        <img
                          className="Select-Picture-img"
                          src="images/camera.svg"
                          alt=''
                        />
                        <span
                          className="Select-Picture"
                          style={{ cursor: "pointer" }}
                        >
                          Select Picture
                        </span>
                      </div>
                  }
                  <input
                    accept="image/*"
                    type="file"
                    onChange={e => this.uploadFile(e, 'fileInput1')}
                    ref={ref => (this.fileInput1 = ref)}
                    style={{ display: "none" }}
                  />
                  {controls.picture1.showErrorMsg && (
                    <div className="error">
                      * Please select picture1.
                    </div>
                  )}
                </Col>
                <Col xl="4" md="12" xs="12">
                  <Label for="common" className="font-weight-bold">Picture2<span className="required-field">*</span></Label>
                  {
                    controls.picture2.value ?
                      <div className="d-flex-row">
                        <img
                          src={controls.picture2.value}
                          className="cip-uploaded-img"
                          onClick={() => { this.openLightBox(controls.picture2.value) }}
                          style={{ cursor: "pointer" }}
                          alt=''
                        />
                        <div className="d-flex-column justify-content-between ml-2">
                          <img src="/images/edit.svg" alt='' className="cursor-pointer" style={{ height: '16px', width: '16px' }} onClick={() => this.selectPicture('fileInput2')} />&nbsp;&nbsp;
                          <img src="/images/delete.svg" alt='' className="cursor-pointer"
                            onClick={() => this.openDeletePictureModal('fileInput2')} style={{ height: '14px' }} />
                        </div>
                      </div> :
                      <div
                        onClick={() => this.selectPicture('fileInput2')}
                        className="select-picture-wrapper mt-0"
                      >
                        <img
                          className="Select-Picture-img"
                          src="images/camera.svg"
                          alt=''
                        />
                        <span
                          className="Select-Picture"
                          style={{ cursor: "pointer" }}
                        >
                          Select Picture
                        </span>
                      </div>
                  }
                  <input
                    accept="image/*"
                    type="file"
                    onChange={e => this.uploadFile(e, 'fileInput2')}
                    ref={ref => (this.fileInput2 = ref)}
                    style={{ display: "none" }}
                  />
                  {controls.picture2.showErrorMsg && (
                    <div className="error">
                      * Please select picture2.
                    </div>
                  )}
                </Col>
                <Col xl="4" md="12" xs="12">
                  <Label for="common" className="font-weight-bold">Picture3</Label>
                  {
                    controls.picture3.value ?
                      <div className="d-flex-row">
                        <img
                          src={controls.picture3.value}
                          className="cip-uploaded-img"
                          onClick={() => { this.openLightBox(controls.picture3.value) }}
                          style={{ cursor: "pointer" }}
                          alt=''
                        />
                        <div className="d-flex-column justify-content-between ml-2">
                          <img src="/images/edit.svg" alt='' className="cursor-pointer" style={{ height: '16px', width: '16px' }} onClick={() => this.selectPicture('fileInput3')} />&nbsp;&nbsp;
                          <img src="/images/delete.svg" alt='' onClick={() => this.openDeletePictureModal('fileInput3')} className="cursor-pointer" style={{ height: '14px' }} />
                        </div>
                      </div> :
                      <div
                        onClick={() => this.selectPicture('fileInput3')}
                        className="select-picture-wrapper mt-0"
                      >
                        <img
                          className="Select-Picture-img"
                          src="images/camera.svg"
                          alt=''
                        />
                        <span
                          className="Select-Picture"
                          style={{ cursor: "pointer" }}
                        >
                          Select Picture
                        </span>
                      </div>
                  }
                  <input
                    accept="image/*"
                    type="file"
                    onChange={e => this.uploadFile(e, 'fileInput3')}
                    ref={ref => (this.fileInput3 = ref)}
                    style={{ display: "none" }}
                  />
                </Col>
                <Col xl="12" md="12" xs="12" className="mt-3 mb-3">
                  <div style={{ fontsize: '12px', color: '#838C95' }}>
                    Note : To preview the image, click on the image.
                  </div>
                </Col>
                <Col xl="12" md="12" xs="12">
                  <div className="d-flex justify-content-end mt-3 mb-2">
                    <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      onClick={() => this.cancel()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={() => this.submitPicture()}
                    >
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            :
            <ModalBody style={{ padding: '1.1rem' }}>
              <Row>
                <Col xl="12" xs="12" md="12" className="mt-1 mb-4">
                  {/* <div style={{ fontSize: '16px' }}>As Requested, Please upload photographs.</div> */}
                </Col>
                <Col xl="4" md="12" xs="12">
                  <Label for="common" className="font-weight-bold">Picture1<span className="required-field">*</span></Label>

                  <div className="d-flex-row">
                    <img
                      src={controls.picture1.value ? controls.picture1.value : '/images/no-img.jpeg'}
                      alt=''
                      className="cip-uploaded-img"
                      onClick={() => { this.openLightBox(controls.picture1.value) }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                </Col>
                <Col xl="4" md="12" xs="12">
                  <Label for="common" className="font-weight-bold">Picture2<span className="required-field">*</span></Label>

                  <div className="d-flex-row">
                    <img
                      src={controls.picture2.value ? controls.picture2.value : '/images/no-img.jpeg'}
                      alt=''
                      className="cip-uploaded-img"
                      onClick={() => { this.openLightBox(controls.picture2.value) }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                </Col>
                <Col xl="4" md="12" xs="12">
                  <Label for="common" className="font-weight-bold">Picture3</Label>

                  <div className="d-flex-row">
                    <img
                      src={controls.picture3.value ? controls.picture3.value : '/images/no-img.jpeg'}
                      alt=''
                      className="cip-uploaded-img"
                      onClick={() => { this.openLightBox(controls.picture3.value) }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                </Col>
                <Col xl="12" md="12" xs="12" className="mt-3 mb-3">
                  <div style={{ fontsize: '12px', color: '#838C95' }}>
                    Note : To preview the image, click on the image.
                  </div>
                </Col>
              </Row>
            </ModalBody>
        }
      </Modal>
    );
  }
}

export default CipPictureUpload;