import { request } from "./Request";
import { routes } from "../constants/constant.route";

const {
  GET_PURPOSE,
  GET_SERVICE,
  GET_FESTIVAL,
  GET_CAMPAIGN_DATA,
  GET_ALL_CUSTOMER,
  GET_CUSTOMISE_CUSTOMER,
  GET_GROUPS,
  GET_VEHICLE_TYPE,
  SOURCE_OF_CUSTOMER,
  GET_CUSTOM_CUSTOMER,
  UPLOAD_CAMPAIGN_IMAGE,
  POST_CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  SMS_REPORT_DETAIL,
  GET_CAMPAIGN_COUNT,
  POST_EDIT_CAMPAIGN,
  POST_RERUN,
  GET_CAMPAIGN_DETAIL,
  POST_CAMPAIGN_RUN,
  GET_SMS_DETAIL,
  NOTIFICATION_REPORT,
  GET_TARGETED_EMPLOYEES,
  GET_DEALER_CLIFICATION,
  GET_ZONE,
  GET_REGION,
  GET_AREA,
  GET_CUSTOMER_COUNT,
  GET_CIP_CUSTOMER_COUNT,
  GET_TARGETS,
  GET_PRODUCTS,
  BUY_SMS_BY_POINTS,
  BUY_SMS_ONLINE,
  ORDER_HISTORY,
  SMS_CREDIT,
  STATE_BY_GST,
  DOWNLOAD_ORDER_INVOICE,
  CANCEL_TRANSACTION,
  GET_ALL_CAMPAIGN_DETAIL,
  GET_DEALERS_DETAILS,
  GET_FLEET_DETAILS,
  GET_STATE_CITY_DISTRICT_MASTER,
  GET_ADVANTAGE_BAADSHAH_EMPLOYEE_MASTER
} = routes.CAMPAIGN;

const API_URL = process.env.REACT_APP_API_URL;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export default class CampaignService {
  static cancelTransaction(id) {
    return request(
      "POST",
      `${API_URL}${CANCEL_TRANSACTION}`,
      null,
      { 'order_id': id },
      null
    );
  }

  static getPurpose(body) {
    return request("GET", `${API_URL}${GET_PURPOSE}`, null, null, null);
  }

  static getService(body) {
    return request("GET", `${API_URL}${GET_SERVICE}`, null, null, null);
  }

  static getFestival(body) {
    return request("GET", `${API_URL}${GET_FESTIVAL}`, null, null, null);
  }

  static getCampaignData(body) {
    return request("GET", `${API_URL}${GET_CAMPAIGN_DATA}`, body, null, null);
  }

  static getAllCustomerData(body) {
    return request("GET", `${API_URL}${GET_ALL_CUSTOMER}`, null, null, null);
  }

  static getCustomiseCustomerData(body) {
    return request(
      "GET",
      `${API_URL}${GET_CUSTOMISE_CUSTOMER}`,
      null,
      null,
      null
    );
  }

  static getGroups(body) {
    return request("GET", `${API_URL}${GET_GROUPS}`, null, null, null);
  }

  static getSourceofCustomer(body) {
    return request("GET", `${API_URL}${SOURCE_OF_CUSTOMER}`, null, null, null);
  }

  static uploadCampaignImage(fromdata) {
    return request(
      "POST",
      `${API_URL}${UPLOAD_CAMPAIGN_IMAGE}`,
      null,
      fromdata,
      null
    );
  }

  static getCustomiseCustomer(body) {
    return request(
      "POST",
      `${API_URL}${GET_CUSTOM_CUSTOMER}`,
      null,
      body,
      null
    );
  }

  static createCampaign(body, template_id) {
    let data;
    if (!template_id) {
      data = body
    }
    else if (template_id) {
      data = { ...body, template_id: template_id }
    }
    return request(
      "POST",
      `${API_URL}${POST_CREATE_CAMPAIGN}`,
      null,
      data,
      null
    );
  }

  static editCampaign(body, id) {
    return request(
      "PUT",
      `${API_URL}${POST_EDIT_CAMPAIGN}${id}`,
      null,
      body,
      null
    );
  }

  static deleteCampaign(id) {
    return request(
      "DELETE",
      `${API_URL}${DELETE_CAMPAIGN}?campaign_id=${id}`,
      null,
      null,
      null
    );
  }
  static getAllCampaignDetail(body) {
    return request("GET", `${API_URL}${GET_ALL_CAMPAIGN_DETAIL}`, body, null, null);
  }
  static downloadReport(obj) {
    return request("GET", `${API_URL}${SMS_REPORT_DETAIL}?uuid=${obj.uuid}&export=${obj.export}`, null, null, null, null);
  }
  static getDealersDetails() {
    return request("GET", `${API_URL}${GET_DEALERS_DETAILS}`, null, null, null)
  }
  static getFleetDetails() {
    return request("GET", `${API_URL}${GET_FLEET_DETAILS}`, null, null, null)
  }
  static getStateCityDistrictMaster() {
    return request("POST", `${API_URL}${GET_STATE_CITY_DISTRICT_MASTER}`, null, null, null)
  }
  static getAdvantageBaadshahEmployeeMaster(body) {
    return request("POST", `${API_URL}${GET_ADVANTAGE_BAADSHAH_EMPLOYEE_MASTER}`, null, body, null)
  }
  static getCampaignCount(body) {
    return request("GET", `${API_URL}${GET_CAMPAIGN_COUNT}`, body, null, null);
  }

  static reRun(body) {
    return request("POST", `${API_URL}${POST_RERUN}`, null, body, null);
  }
  static campaignRunNow(id) {
    return request(
      "POST",
      `${API_URL}${POST_CAMPAIGN_RUN}${id}/run-now`,
      null,
      null,
      null
    );
  }

  static campaignRerunNow(id) {
    return request("POST", `${API_URL}${POST_RERUN}`, null, id, null);
  }
  static getCampaignDetail(id) {
    return request(
      "GET",
      `${API_URL}${GET_CAMPAIGN_DETAIL}?campaign_id=${id}`,
      null,
      null,
      null
    );
  }
  static getSMSDetail(body) {
    return request(
      "GET",
      `${API_URL}${GET_SMS_DETAIL}?campaignId=${body.id}`,
      null,
      null,
      null
    );
  }

  static getSMSDetailAPIForPushNotification(body) {
    return request(
      "GET",
      `${API_URL}${NOTIFICATION_REPORT}?campaignId=${body.id}&export=${body.export}&limit=${body.limit}&offset=${body.offset}`,
      null,
      null,
      null
    );
  }

  static getTargetedEmployee() {
    return request(
      "GET",
      `${API_URL}${GET_TARGETED_EMPLOYEES}`,
      null,
      null,
      null
    );
  }
  static getDealerClassication() {
    return request(
      "GET",
      `${API_URL}${GET_DEALER_CLIFICATION}`,
      null,
      null,
      null
    );
  }

  static getZone() {
    return request("GET", `${API_URL}${GET_ZONE}`, null, null, null);
  }
  static getRegion(reqData) {
    return request("POST", `${API_URL}${GET_REGION}`, null, reqData, null);
  }
  static getArea(reqData) {
    return request("POST", `${API_URL}${GET_AREA}`, null, reqData, null);
  }

  static getState() {
    return request(
      "GET",
      `${API_URL}${routes.WARRANTY_REGISTRATION.GETSTATE}`,
      null,
      null,
      null
    );
  }

  static getCity(statId) {
    return request(
      "POST",
      `${API_URL}${routes.WARRANTY_REGISTRATION.GETCITY}`,
      null,
      statId,
      null,
      NODE_ENV === 'staging' ? "aa129ca92680ef3519f09d3e3725366b94d1b66a" : NODE_ENV === 'development' ? "9641375153b2407a4f568d3ab44b37425625b626" : NODE_ENV === 'production' ? "62b91e1cc94e0ef87a622acb2dfaa036034b85df" : ""
    );
  }
  static registerForEvent(obj) {
    return request(
      "POST",
      `${API_URL}${routes.WARRANTY_REGISTRATION.EVENT_REGISTRATION}`,
      null,
      obj,
      null,
      NODE_ENV === 'staging' ? "e4e5231604278db4f64bc322a859257126c7a017" : NODE_ENV === 'development' ? "fece4a188de8ed86d68bf00af6ab66aaf7a7a8aa" : NODE_ENV === 'production' ? "acc85cc41c384167da5041459d0083791ed7398b" : ""
    );
  }
  static getVehicleType() {
    return request(
      "GET",
      `${API_URL}${routes.WARRANTY_REGISTRATION.GETVEHICLETYPE}`,
      null,
      null,
      null
    );
  }
  static getVehicleTypeCamp() {
    return request("GET", `${API_URL}${GET_VEHICLE_TYPE}`, null, null, null);
  }

  static getTargets() {
    return request("GET", `${API_URL}${GET_TARGETS}`, null, null, null);
  }

  static getVehicalMake(params) {
    return request(
      "POST",
      `${API_URL}${routes.WARRANTY_REGISTRATION.GET_VEHICALE_MAKE}`,
      null,
      params,
      null
    );
  }

  static getCustomerCount(params) {
    return request(
      "POST",
      `${API_URL}${GET_CUSTOMER_COUNT}`,
      null,
      params,
      null
    );
  }

  static getCIPCustomerCount(params) {
    return request(
      "POST",
      `${API_URL}${GET_CIP_CUSTOMER_COUNT}`,
      null,
      params,
      null
    );
  }

  static getVehicalModel(params) {
    return request(
      "POST",
      `${API_URL}${routes.WARRANTY_REGISTRATION.GET_VEHICALE_MODEL}`,
      null,
      params,
      null
    );
  }

  static getProducts() {
    return request("GET", `${API_URL}${GET_PRODUCTS}`, null, null, null);
  }

  static buyProduct(req, mode) {
    if (mode === 'warranty') {
      return request(
        "POST",
        `${API_URL}${BUY_SMS_BY_POINTS}`,
        null,
        req,
        null
      );
    } else {
      return request(
        "POST",
        `${API_URL}${BUY_SMS_ONLINE}`,
        null,
        req,
        null
      );
    }
  }

  static getOrderHistory(reqData) {
    return request(
      "GET",
      `${API_URL}${ORDER_HISTORY}`,
      reqData,
      null,
      null
    );

  }

  static getSMSCredits() {
    return request(
      "GET",
      `${API_URL}${SMS_CREDIT}`,
      null,
      null,
      null
    );
  }
  static getStateByGST(reqData) {
    return request(
      "GET",
      `${API_URL}${STATE_BY_GST}`,
      reqData,
      null,
      null
    );
  }

  static downloadOrderInvoice(reqData) {
    return request(
      "GET",
      `${API_URL}${DOWNLOAD_ORDER_INVOICE}`,
      reqData,
      null,
      null
    );
  }
}
