import React from 'react';
import { Button, FormGroup, Label, Row, Col, Input } from 'reactstrap';
import swal from 'sweetalert';
import './SendVerifyOtp.css';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import Validation from '../../services/Validation';
import { messages } from '../../constants/constant.message';
import WarrantyRegistrationService from '../../services/WarrantyRegistrationService';

class SendVerifyOtp extends React.Component {
  state = {
    moveTime: true,
    deleteTime: false
  };

  isFirstTimeValidate = true;

  constructor(props) {
    super(props);
    this.state = {
      controls: {
        phoneNumber: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidPhone: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        otp: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidPhone: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        }
      },
      isSendOtp: false,
      isOtpVerified: false,
      isFormValid: false,
      errorMessage: '',
      isLoading: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleValidation = (firstTime = true, isSubmit = false) => {
    const { controls, isFormValid } = this.state;
    let {
      phoneNumber,
      otp
    } = controls;
    if (!this.state.isSendOtp && !this.isFirstTimeValidate) {
      phoneNumber = Validation.notNullValidator(phoneNumber);
      phoneNumber = Validation.validatePhoneNumber(phoneNumber);
      phoneNumber.valid = !(phoneNumber.nullValue || phoneNumber.invalidPhone);
      if (!phoneNumber.valid === false && phoneNumber.value && phoneNumber.value.length === 10) {
        phoneNumber.showErrorMsg = false;
      } else {
        phoneNumber.showErrorMsg = true;
      }
    }
    if (this.state.isSendOtp && !this.isFirstTimeValidate) {
      otp = Validation.notNullValidator(otp);
      otp.valid = !otp.nullValue;
      otp.showErrorMsg = !otp.valid;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  }



  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    this.handleValidation();
  }

  sendVerifyOtp = (isSendOtp) => {
    this.isFirstTimeValidate = false;
    this.handleValidation();
    const { controls } = this.state;
    const {
      phoneNumber,
      otp
    } = controls;
    if (!phoneNumber.showErrorMsg && isSendOtp) {
      this.sendOTP();
    }
    if (!phoneNumber.showErrorMsg && !otp.showErrorMsg && !isSendOtp) {
      this.verifyOTP();
    }
  }

  sendOTP = async () => {
    const { controls } = this.state;
    let { isSendOtp } = this.state;
    try {
      const { phoneNumber } = controls;
      if (phoneNumber.value) {
        isSendOtp = false;
        this.setState({ isLoading: true });
        const { data } = await WarrantyRegistrationService.sendOTP({ phone_number: phoneNumber.value });
        if (data && data.success) {
          isSendOtp = true;
        }
        this.setState({ isSendOtp, isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  }

  closeOtp = (phoneNumber, token) => {
    this.props.closeSendVerifyOtp(phoneNumber, token);
    swal.close();
  }

  verifyOTP = async () => {
    const { controls } = this.state;
    const { phoneNumber, otp } = controls;
    try {
      if (otp.value) {
        this.setState({ isLoading: true });
        const { data } = await WarrantyRegistrationService.verifyOTP({ phone_number: phoneNumber.value, otp: otp.value });
        if (data && data.success) {
          this.setState({ isOtpVerified: true, isLoading: false });
          this.closeOtp(phoneNumber.value, (data.data || {}).token);
        } else {
          this.setState({ isOtpVerified: false, errorMessage: messages.WARRANTY_REGISTRATION.INVALIDOTP, isLoading: false });
        }

      }
    } catch (error) {
      this.setState({ isOtpVerified: false, errorMessage: messages.WARRANTY_REGISTRATION.INVALIDOTP, isLoading: false });
    }
    setInterval(() => {
      this.setState({ errorMessage: '' });
    }, 7000);
  }

  render() {
    const { controls } = this.state;
    const {
      phoneNumber,
      otp
    } = controls;
    return (
      <div className="send-verify-otp-wrapp">
        {this.state.isLoading && <CustomSpinner />}
        <span className="Verification">Check existing customer’s eligibility for Warranty</span>
        <hr className="swal-hr" />
        <div className="d-flex-column">
          {
            !this.state.isSendOtp &&
            <Row>
              <Col style={{ width: '100%', flexBasis: 'unset' }}>
                <FormGroup>
                  <Label for="number" className="input-label">Enter customer’s mobile number</Label>
                  <Input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={phoneNumber.value}
                    onChange={(ev) => this.handleInputChange(ev)}
                  />
                </FormGroup>
                {phoneNumber.showErrorMsg && <div className="error" style={{ marginTop: '-15px', marginBottom: '5px' }}>* {messages.SEND_VERIFY_OTP.INVALID_MOBILE_NUMBER}</div>}
              </Col>
            </Row>
          }
          {
            this.state.isSendOtp && <Row>
              <Col style={{ width: '100%', flexBasis: 'unset' }}>
                <FormGroup>
                  <Label for="number" className="input-label">Enter OTP sent to {this.state.controls.phoneNumber.value}</Label>
                  <Input
                    type="text"
                    name="otp"
                    id="otp"
                    value={otp.value}
                    onChange={(ev) => this.handleInputChange(ev)}
                  />
                </FormGroup>
                {otp.showErrorMsg && <div className="error" style={{ marginTop: '-15px', marginBottom: '5px' }}>* {messages.SEND_VERIFY_OTP.INVALID_MOBILE_OTP}</div>}
              </Col>
            </Row>
          }
          {
            this.state.isSendOtp &&
            <div className="Didnt-get-the-code">
              <span>Didn’t get the code? <span className="text-style-1" onClick={() => this.sendOTP()}>Send Again</span></span>
            </div>
          }
          {
            <div>
              {this.state.errorMessage && <div className="error" style={{ marginTop: '-15px', marginBottom: '5px' }}>* {this.state.errorMessage}</div>}
            </div>
          }
          <Row>
            <Col xl="12" xs="12" md="12" className="text-align-center">
              <Button className="mb-1 mr-3 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.closeOtp()}>Close</Button>
              <Button type="submit" className="mb-1 mr-1 btn-pill action-button profile-save-button" color="warning" onClick={() => this.sendVerifyOtp(!this.state.isSendOtp)}>{this.state.isSendOtp ? 'Submit' : 'Send OTP'}</Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

// const wrapper = document.createElement("div");
// ReactDOM.render(<SendVerifyOtp />, wrapper);

// export default wrapper.firstChild;
export default SendVerifyOtp;
