import React, { Component } from "react";

import {
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
// import DashboardService from "../../services/DashboardService";
import MarketingCouponService from "../../services/MarketingCouponService";
import CustomerManagementService from "../../services/CustomerManagementService";
// utils
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import "./VerifyCoupon.css";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import WarrantyRegistrationService from "../../services/WarrantyRegistrationService";
import swal from "sweetalert";
import { Redirect, withRouter } from "react-router-dom";
import Storage from "../../services/Storage";
import { setAddCustomerRedirect } from "../../actions/UserAction";
import { connect } from "react-redux";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { Permissions } from "../../constants/constant.permissions";

const { ERROR_MESSAGE } = messages.COMMON;

class VerifyCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        phone_numeber: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false,
        },
        otp: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false,
        },
        first_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false,
        },
        last_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false,
        },
      },
      customer_id: "",
      token: "",
      coupon_token: "",
      isLoader: false,
      data: this.props.data,
      mainModel: this.props.mainModel,
      sendOtp: false,
      verifyOtp: false,
      addCustomer: false,
      redirectToWarrantyRegistration: false,
      withoutCoderedirectToWarrantyRegistration: false,
      redirectReport: false,
      openCustomerManagement: false,
      displayBackBtn: true,
      sendOtpTitle: "REDEEM COUPON",
      userDetail: Storage.getUserDetail(),
    };
  }

  componentDidMount() {

    if (this.props.sendOtpScreen) {
      let { controls } = this.state;
      if (this.props.phone_number) {
        controls.phone_numeber.value = this.props.phone_number;
      }
      this.setState(
        {
          mainModel: false,
          verifyOtp: controls.phone_numeber.value ? true : false,
          sendOtp: controls.phone_numeber.value ? false : true,
          displayBackBtn: false,
          controls,
          sendOtpTitle: "WARRANTY REGISTRATION",
        },
        () => {
          if (this.props.phone_number) {
            this.sendOtp();
          }
        }
      );
    }
  }

  backClearData = () => {
    let { controls } = this.state;
    let { first_name, last_name, phone_numeber, otp } = controls;
    first_name.value = "";
    last_name.value = "";
    phone_numeber.value = "";
    otp.value = "";

    this.setState({ mainModel: false, sendOtp: true });
  };

  redeemIt = () => {
    if (this.state.data.err_mssg) {
      ModalService.openAlert("", this.state.data.err_mssg, "warning");
      return;
    }

    GoogleAnalticsService.event(
      "Redeem Marketing Coupon",
      "Enter Coupon Redeem Dialogue Redeem Click"
    );
    this.setState({ mainModel: false, sendOtp: true });
  };

  sendOtp = () => {
    if (!this.handleValidationOTP(true)) {
      return;
    }
    this.setState({
      isLoader: true,
    });
    GoogleAnalticsService.event(
      "Redeem Marketing Coupon",
      "Enter Coupon Mobile Dialogue Send Otp Click"
    );
    WarrantyRegistrationService.sendOTP({
      phone_number: this.state.controls.phone_numeber.value,
      type: "coupon",
    })
      .then((data) => {
        this.setState({
          isLoader: false,
          sendOtp: false,
          verifyOtp: true,
        });
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        console.error("e", e);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  sendOtpBack = () => {
    this.backClearData();
    this.setState({ mainModel: true, sendOtp: false });
  };

  verifyOtp = () => {
    if (!this.handleValidationVerify(true)) {
      return;
    }
    this.setState({
      isLoader: true,
    });
    GoogleAnalticsService.event(
      "Redeem Marketing Coupon",
      "Enter Coupon Otp Submit Click"
    );
    WarrantyRegistrationService.verifyOTP({
      phone_number: this.state.controls.phone_numeber.value,
      otp: this.state.controls.otp.value,
    })
      .then((data) => {

        if (this.props.from && this.props.from === "add-customer") {
          this.setState({
            coupon_token: "",
            token: data.data.data.token,
            withoutCoderedirectToWarrantyRegistration: true,
          });
          return;
        }
        if (
          (this.state.data.type === "2 wheeler" ||
            this.state.data.type === "car" ||
            this.state.data.type === "lcv" ||
            this.state.data.type === "scv") &&
          this.state.data.purpose === "tyre_purchase"
        ) {

          // this.setState({
          //   coupon_token: data.data.data.coupon_token,
          //   token: data.data.data.token,
          //   redirectToWarrantyRegistration: true
          // });

          // this.props.closeModal();
          this.setState({
            isLoader: false,
            coupon_token: data.data.data.coupon_token,
            verifyOtp: false,
            addCustomer: true,
          });
        } else if (
          (this.state.userDetail.permissions &&
            !this.state.userDetail.permissions.includes(
              Permissions.CUSTOMER_MANAGEMENT
            )) ||
          this.state.data.purpose === "other"
        ) {
          this.setState({
            isLoader: false,
            coupon_token: data.data.data.coupon_token,
            verifyOtp: false,
            addCustomer: true,
          });
        } else {

          this.setState(
            {
              isLoader: false,
              coupon_token: data.data.data.coupon_token,
              token: data.data.data.token,
              openCustomerManagement: true,
              verifyOtp: false,
            },
            () => { }
          );

          this.props.setAddCustomerRedirect({
            redirectToAddCustomer: true,
            type: this.state.data.type,
            token: data.data.data.coupon_token,
            coupon_code: this.state.data.coupon_code,
            purpose: this.state.data.purpose,
            phone_number: this.state.controls.phone_numeber.value,
            type_name: this.state.data.type_name,
          });

          this.props.closeModal();
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        console.error("e", e);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  verifyOtpBack = () => {
    this.backClearData();
    this.setState({ sendOtp: true, verifyOtp: false });
  };

  checkCustomer = () => {
    if (!this.checkCustomerValidation(true)) {
      return;
    }
    this.setState({
      isLoader: true,
    });
    let reqData = {
      phone_number: this.state.controls.phone_numeber.value,
      first_name: this.state.controls.first_name.value,
      last_name: this.state.controls.last_name.value,
    };
    CustomerManagementService.addCustomer(reqData)
      .then((res) => {
        this.setState({
          customer_id: res.data.data.customer_id,
        });
        this.redeemCoupon();
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        console.error("e", e);
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  checkCustomerBack = () => {
    this.backClearData();
    this.setState({ verifyOtp: false, sendOtp: true, addCustomer: false });
  };

  redeemCoupon = () => {
    let reqData = {
      phone_number: this.state.controls.phone_numeber.value,
      token: this.state.coupon_token,
      coupon_code: this.state.data.coupon_code,
      redeemed_from: "other",
      customer_id: this.state.customer_id,
      tyre_quantity: 0,
      visit_history_id: null,
    };
    this.setState({
      isLoader: false,
    });
    MarketingCouponService.redeemCoupon(reqData)
      .then((data) => {
        if (data.data && data.data.success) {
          this.setState({
            isLoader: false,
            addCustomer: false,
            redirectReport: true,
          });
          swal({
            title: "Congratulations!",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            this.props.closeModal();
            if (this.props.location.pathname === "/redeem-coupon-report") {
              window.location.reload();
            }
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  checkCustomerValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { first_name, last_name } = controls;

    if ((first_name.touched === true || isSubmit) && first_name.value === "") {
      first_name.showErrorMsg = true;
      isFormValid = false;
    } else {
      first_name.showErrorMsg = false;
      isFormValid = true;
    }

    if ((last_name.touched === true || isSubmit) && last_name.value === "") {
      last_name.showErrorMsg = true;
      isFormValid = false;
    } else {
      last_name.showErrorMsg = false;
      isFormValid = true;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleValidationVerify = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { otp } = controls;

    if ((otp.touched === true || isSubmit) && otp.value === "") {
      otp.showErrorMsg = true;
      isFormValid = false;
    } else {
      otp.showErrorMsg = false;
      isFormValid = true;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleValidationOTP = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let { phone_numeber } = controls;

    if (
      (phone_numeber.touched === true || isSubmit) &&
      phone_numeber.value === ""
    ) {
      phone_numeber.showErrorMsg = true;
      isFormValid = false;
    } else {
      phone_numeber.showErrorMsg = false;
      isFormValid = true;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;

    if (
      controls[controlName].touched === true &&
      (controls[controlName].value === "" || controls[controlName].value === null)
    ) {
      controls[controlName].showErrorMsg = true;
    } else {
      controls[controlName].showErrorMsg = false;
    }
    this.setState({ controls });
  };

  render() {
    const {
      redirectToWarrantyRegistration,
      redirectReport,
      token,
      controls,
      data,
      openCustomerManagement,
      coupon_token,
      withoutCoderedirectToWarrantyRegistration,
    } = this.state;

    return (
      <div id="coupon-verify">
        {redirectReport && <Redirect to="/redeem-coupon-report" />}

        {redirectToWarrantyRegistration && (
          <Redirect
            to={{
              pathname: "/warranty-registration",
              search: `phoneNumber=${controls.phone_numeber.value}&t=${token}&couponCode=${data.coupon_code}&type=${data.type}&couponToken=${coupon_token}`,
            }}
          />
        )}

        {withoutCoderedirectToWarrantyRegistration && (
          <Redirect
            to={{
              pathname: "/warranty-registration",
              search: `phoneNumber=${controls.phone_numeber.value}&t=${token}`,
            }}
          />
        )}

        {openCustomerManagement && <Redirect to="/dealer-dashboard" />}

        <Modal
          id={this.state.mainModel ? "redeem-coupon-detail" : "redeem-coupon"}
          className={
            this.state.sendOtpTitle === "WARRANTY REGISTRATION"
              ? "redeem-coupon-warranty"
              : ""
          }
          isOpen={this.props.mainModel}
          toggle={this.props.openModel}
          centered
        >
          {this.state.isLoader && <CustomSpinner />}
          {this.state.mainModel && (
            <ModalBody>
              <div>
                {/* <Col xl-12> */}
                <Row
                  style={{
                    marginTop: "5px",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Col className="label-verify" xl="9" md="9" sm="9">
                    <b>REDEEM COUPON</b>
                  </Col>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                      paddingLeft: "90px",
                    }}
                  >
                    <img
                      style={{
                        width: "10px",
                      }}
                      src="./images/close.svg"
                      onClick={this.props.closeModal}
                      alt=""
                    />
                  </Col>
                </Row>
                <div
                  className="border"
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                />
                <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                  <Col xl="12" md="12" sm="12">
                    <Label className="label-select-data-main">
                      {this.state.data.message}
                    </Label>
                  </Col>
                  <Col xl="12" md="12" sm="12">
                    <div className="service-pref-note-redeem">
                      <label className="label-redeem label-select-data">
                        {this.state.data.paid_by}
                      </label>
                    </div>
                  </Col>
                  <Col xl="12" md="12" sm="12">
                    <Label className="label-select">Coupon Code :</Label>{" "}
                    <Label className="label-select-data">
                      {this.state.data.coupon_code}
                    </Label>
                  </Col>
                  <Col xl="12" md="12" sm="12">
                    <div className="label-select label-select-div">
                      Offer Name :
                    </div>
                    <div className="label-select-data label-select-data-div">
                      {this.state.data.offer_name}
                    </div>
                    {/* <Label className="label-select">Offer Name :</Label> <Label className="label-select-data">{this.state.data.offer_name}</Label> */}
                  </Col>
                  <Col xl="12" md="12" sm="12">
                    <div className="label-select label-select-div">
                      Offer Detail :
                    </div>

                    {/* {!this.state.data.content_url && 
                    <div className="label-select-data label-select-data-div">
                      {this.state.data.offer_details}
                    </div>} */}
                    <div
                      className="label-select-data label-select-data-div"
                      dangerouslySetInnerHTML={{
                        __html: this.state.data.offer_details,
                      }}
                    ></div>
                  </Col>

                  {this.state.data.content_url && (
                    <Col xl="12" md="12" sm="12">
                      <div className="label-select-data label-select-data-div">
                        {" "}
                        <a
                          style={{
                            color: "blue",
                          }}
                          target="blank"
                          href={this.state.data.content_url}
                        >
                          View Offer
                        </a>
                      </div>
                    </Col>
                  )}
                  <Col xl="6" md="6" sm="6">
                    <Label className="label-select">Discount per tyre :</Label>{" "}
                    <Label className="label-select-data">
                      {this.state.data.discount_per_tyre}
                    </Label>
                  </Col>
                  <Col xl="6" md="6" sm="6">
                    <Label className="label-select">Maximum Discount :</Label>{" "}
                    <Label className="label-select-data">
                      {this.state.data.max_discount}
                    </Label>
                  </Col>
                  {this.state.data.one_tyre_price && (
                    <Col xl="6" md="6" sm="6">
                      <Label className="label-select">
                        Price of one tyre :
                      </Label>{" "}
                      <Label className="label-select-data">
                        {this.state.data.one_tyre_price}
                      </Label>
                    </Col>
                  )}
                  <Col xl="12" md="12" sm="12">
                    <Label className="label-select-mssg">
                      {this.state.data.validityMessage}
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="12"
                    md="12"
                    sm="12"
                    style={{
                      padding: "-1px",
                      textAlign: "center",
                      paddingBottom: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    <Button
                      style={{ width: "142px" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.props.closeModal}
                    >
                      Cancel
                    </Button>{" "}
                    &nbsp;&nbsp;
                    <Button
                      style={{ width: "142px" }}
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={this.redeemIt}
                    >
                      Redeem it
                    </Button>
                    {/* <Button style={{ width: "142px" }}
                    className="mb-1 mr-1 btn-pill action-button profile-save-button"
                    color="warning"
                    onClick={this.state.data.redeemable ? this.redeemIt : this.props.closeModal }
                  >
                    {this.state.data.redeemable ? 'Redeem it' : 'Ok'}
                  </Button> */}
                  </Col>
                  {/* <Col xl="6" md="6" sm="12" style={{
                    padding: "-1px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}>
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={this.redeemIt}
                    >
                      Redeem it
                  </Button>
                  </Col> */}
                </Row>
                {/* </Col> */}
              </div>
            </ModalBody>
          )}

          {this.state.sendOtp && (
            <ModalBody>
              <div>
                <Col xl-12>
                  <Row
                    style={{
                      marginTop: "5px",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                  >
                    <Col className="label-verify" xl="9" md="9" sm="9">
                      <b>{this.state.sendOtpTitle}</b>
                    </Col>
                    {/* {
                    !this.state.displayBackBtn &&
                    <Col
                      xl="3"
                      md="3"
                      sm="3"
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        paddingLeft: "60px",
                      }}
                    >
                      <img
                        style={{
                          width: "10px"
                        }}
                        src="./images/close.svg"
                        onClick={this.props.closeModal}
                        alt=""
                      />
                    </Col>
                  } */}
                  </Row>
                  <div className="border" />
                  <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                    <Col
                      className="label-verify"
                      xl="12"
                      md="12"
                      sm="12"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Label className="label-select">
                        Enter Customer Mobile number&nbsp;
                        <span className="required-field">*</span>
                      </Label>
                    </Col>
                    <Col xl="3" md="3" sm="3">
                      <FormGroup>
                        <Input
                          type="text"
                          autoComplete="nofill"
                          disabled
                          value="+91"
                          placeholder="Type here"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="9" md="9" sm="9" style={{ paddingLeft: "0" }}>
                      <FormGroup>
                        <Input
                          type="text"
                          autoComplete="nofill"
                          name="phone_numeber"
                          id="phone_numeber"
                          maxlength="10"
                          value={controls.phone_numeber.value}
                          onChange={this.handleInputChange}
                          placeholder="Type here"
                        />
                        {controls.phone_numeber.showErrorMsg && (
                          <div className="error">
                            * Please enter phone number.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xl="12"
                      md="12"
                      sm="12"
                      style={{
                        padding: "-1px",
                        textAlign: "center",
                        paddingBottom: "20px",
                        paddingTop: "10px",
                      }}
                    >
                      {this.state.displayBackBtn ? (
                        <Button
                          style={{ width: "43%" }}
                          className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                          color="warning"
                          onClick={this.sendOtpBack}
                        >
                          Back
                        </Button>
                      ) : (
                        <Button
                          style={{ width: "43%" }}
                          className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                          color="warning"
                          // onClick={this.props.closeModal}
                          onClick={this.props.closeModal}
                        >
                          Close
                        </Button>
                      )}
                      &nbsp; &nbsp;
                      <Button
                        style={{ width: "43%" }}
                        className="mb-1 mr-1 btn-pill action-button profile-save-button"
                        color="warning"
                        onClick={this.sendOtp}
                      >
                        Send OTP
                      </Button>
                    </Col>
                    {/* <Col xl="6" md="6" sm="12" style={{
                    padding: "-1px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}>
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={this.sendOtp}
                    >
                      Send OTP
                                            </Button>
                  </Col> */}
                  </Row>
                </Col>
              </div>
            </ModalBody>
          )}

          {this.state.verifyOtp && (
            <ModalBody>
              <div>
                <Col xl-12>
                  <Row
                    style={{
                      marginTop: "5px",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                  >
                    <Col className="label-verify" xl="9" md="9" sm="9">
                      <b>{this.state.sendOtpTitle}</b>
                    </Col>
                    {/* <Col
                    xl="3"
                    md="3"
                    sm="3"
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                      paddingLeft: "60px",
                    }}
                  >
                    <img
                      style={{
                        width: "10px"
                      }}
                      src="./images/close.svg"
                      alt=""
                      onClick={this.props.closeModal}
                    />
                  </Col> */}
                  </Row>
                  <div className="border" />
                  <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                    <Col
                      className="label-verify"
                      xl="12"
                      md="12"
                      sm="12"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Label className="label-select">
                        Enter the OTP Sent to{" "}
                        {this.state.controls.phone_numeber.value}
                        <br />
                        Did not got code?{" "}
                        <span
                          onClick={this.sendOtp}
                          className="label-send-again"
                        >
                          Send again
                        </span>
                      </Label>
                    </Col>
                    <Col
                      className="label-verify"
                      xl="12"
                      md="12"
                      sm="12"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Label className="label-select">
                        Enter OTP&nbsp;
                        <span className="required-field">*</span>
                      </Label>
                    </Col>
                    <Col xl="12" md="12" sm="12">
                      <FormGroup>
                        <Input
                          type="text"
                          autoComplete="nofill"
                          name="otp"
                          id="otp"
                          value={controls.otp.value}
                          onChange={this.handleInputChange}
                          placeholder="Type here"
                        />
                        {controls.otp.showErrorMsg && (
                          <div className="error">* Please enter otp.</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xl="12"
                      md="12"
                      sm="12"
                      style={{
                        padding: "-1px",
                        textAlign: "center",
                        paddingBottom: "20px",
                        paddingTop: "10px",
                      }}
                    >
                      {this.state.displayBackBtn ? (
                        <Button
                          style={{ width: "43%" }}
                          className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                          color="warning"
                          // onClick={this.props.closeModal}
                          onClick={this.verifyOtpBack}
                        >
                          Back
                        </Button>
                      ) : (
                        <Button
                          style={{ width: "43%" }}
                          className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                          color="warning"
                          // onClick={this.props.closeModal}
                          onClick={this.props.closeModal}
                        >
                          Close
                        </Button>
                      )}
                      &nbsp; &nbsp;
                      <Button
                        style={{ width: "43%" }}
                        className="mb-1 mr-1 btn-pill action-button profile-save-button"
                        color="warning"
                        onClick={this.verifyOtp}
                      >
                        Submit
                      </Button>
                    </Col>
                    {/* <Col xl="6" md="6" sm="12" style={{
                    padding: "-1px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}>
                    
                  </Col> */}
                  </Row>
                </Col>
              </div>
            </ModalBody>
          )}

          {this.state.addCustomer && (
            <ModalBody>
              <div>
                <Col xl-12>
                  <Row
                    style={{
                      marginTop: "5px",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                  >
                    <Col className="label-verify" xl="9" md="9" sm="9">
                      <b>ADD CUSTOMER</b>
                    </Col>
                    {/* <Col
                    xl="3"
                    md="3"
                    sm="3"
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                      paddingLeft: "60px",
                    }}
                  >
                    <img
                      style={{
                        width: "10px"
                      }}
                      src="./images/close.svg"
                      alt=""
                      onClick={this.props.closeModal}
                    />
                  </Col> */}
                  </Row>
                  <div className="border" />
                  <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                    <Col
                      className="label-verify"
                      xl="12"
                      md="12"
                      sm="12"
                      style={{ marginTop: "0px", marginBottom: "0px" }}
                    >
                      <Label className="label-select">
                        First Name&nbsp;
                        <span className="required-field">*</span>
                      </Label>
                    </Col>
                    <Col
                      xl="12"
                      md="12"
                      sm="12"
                      style={{
                        marginTop: "0px",
                        marginBottom: "15px",
                        paddingTop: "0px",
                      }}
                    >
                      <FormGroup
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                      >
                        {/* <FormGroup > */}
                        <Input
                          type="text"
                          autoComplete="nofill"
                          name="first_name"
                          id="first_name"
                          value={controls.first_name.value}
                          onChange={this.handleInputChange}
                          placeholder="Type here"
                        />
                        {controls.first_name.showErrorMsg && (
                          <div className="error">
                            * Please enter first name.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                    <Col
                      className="label-verify"
                      xl="12"
                      md="12"
                      sm="12"
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        paddingTop: "0px",
                      }}
                    >
                      <Label className="label-select">
                        Last Name&nbsp;<span className="required-field">*</span>
                      </Label>
                    </Col>
                    <Col xl="12" md="12" sm="12">
                      <FormGroup style={{ marginTop: "0px" }}>
                        <Input
                          type="text"
                          autoComplete="nofill"
                          name="last_name"
                          id="last_name"
                          value={controls.last_name.value}
                          onChange={this.handleInputChange}
                          placeholder="Type here"
                        />
                        {controls.last_name.showErrorMsg && (
                          <div className="error">* Please enter last name.</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xl="12"
                      md="12"
                      sm="12"
                      style={{
                        padding: "-1px",
                        textAlign: "center",
                        paddingBottom: "20px",
                        paddingTop: "10px",
                      }}
                    >
                      <Button
                        style={{ width: "43%" }}
                        className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                        color="warning"
                        // onClick={this.props.closeModal}
                        onClick={this.checkCustomerBack}
                      >
                        Back
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ width: "43%" }}
                        className="mb-1 mr-1 btn-pill action-button profile-save-button"
                        color="warning"
                        onClick={this.checkCustomer}
                      >
                        Submit
                      </Button>
                    </Col>
                    {/*  <Col xl="6" md="6" sm="12" style={{
                    padding: "-1px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}>
                    <Button
                      className="mb-1 mr-1 btn-pill action-button profile-save-button"
                      color="warning"
                      onClick={this.checkCustomer}
                    >
                      Submit
                                            </Button>
                  </Col> */}
                  </Row>
                </Col>
              </div>
            </ModalBody>
          )}
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAddCustomerRedirect: (redirectDetailsAddCustomer) =>
    dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
});

export default connect(null, mapDispatchToProps)(withRouter(VerifyCoupon));
