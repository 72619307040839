import React, { Fragment } from 'react';
import Header from '../../theme-components/Header/Header';
import Footer from '../../components/Footer/Footer';

const ComponentWithoutSideNav = ({ component: Component, ...rest }) => {
  return (
    <Fragment>
      <Header></Header>
      <div className="app-main" style={{ minHeight: '95.5vh' }}>
        <div className="app-main__inner">
          <Component {...rest} />
        </div>
      </div>
      <Footer></Footer>
    </Fragment>
  )
}

export default ComponentWithoutSideNav;