import { request } from './Request';
import { routes } from '../constants/constant.route';
const { SUBMIT_POPUP_DETAIL, GET_POPUP_DETAIL, GET_POPUP_LINK, GET_USER_POPUP_TITLES, GET_USER_POPUP_DETAIL } = routes.SPECIAL_ENGAGEMENT;
const API_URL = process.env.REACT_APP_API_URL;

export default class SpecialEngagementService {

  static getPopupDetail(accessToken) {
    let token = null;
    if (accessToken) {
      token = {
        accessToken: accessToken
      }
    }
    return request('GET', `${API_URL}${GET_POPUP_DETAIL}`, null, null, null, token);
  }

  static submitPopupDetail(body, accessToken) {
    let token = null;
    if (accessToken) {
      token = {
        accessToken: accessToken
      }
    }
    return request('POST', `${API_URL}${SUBMIT_POPUP_DETAIL}`, null, body, null, token);
  }

  static getPopupLink() {
    return request('GET', `${API_URL}${GET_POPUP_LINK}`, null, null, null);
  }

  static getPopupTitles() {
    return request('GET', `${API_URL}${GET_USER_POPUP_TITLES}`, null, null, null);
  }

  static getPopupDetailFromId(accessToken, uuid) {
    let token = null;
    let qp = `?`;
    if (uuid) {
      qp += `uuid=${uuid}`
    }
    if (accessToken) {
      token = {
        accessToken: accessToken
      }
    }
    return request('GET', `${API_URL}${GET_USER_POPUP_DETAIL}${qp}`, null, null, null, token);
  }

}