import { request } from './Request';
import { routes } from '../constants/constant.route';
const {
  SEND_NOTIFICATION,
  GET_NOTIFICATION_ACTIONS,
  GET_ROLES,
  UPLOAD_NOTIFICATION_IMAGE
} = routes.NOTIFICATION;
const API_URL = process.env.REACT_APP_API_URL;

export default class NotificationService {

  static sendNotification(obj) {
    return request('POST', `${API_URL}${SEND_NOTIFICATION}`, null, obj, null);
  }

  static getNotificationActions(obj) {
    return request('POST', `${API_URL}${GET_NOTIFICATION_ACTIONS}`, null, obj, null);
  }

  static getRoles() {
    return request('GET', `${API_URL}${GET_ROLES}`, null, null, null);
  }

  static uploadNotificationImage(image) {
    const headers = {
      'content-type': 'multipart/form-data'
    };
    return request('POST', `${API_URL}${UPLOAD_NOTIFICATION_IMAGE}`, null, image, headers);
  }
}