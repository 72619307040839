import React, { Component } from "react";
import { Button, Label, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RetailMarketingStoreInformation from "../../../components/RetailMarketingSystem/RetailMarketingStoreInformation/RetailMarketingStoreInformation";
import RetailMarktingUploadPhotograph from "../../../components/RetailMarketingSystem/RetailMarktingUploadPhotograph/RetailMarktingUploadPhotograph";
import { getRetailMarketingStoreInformationState } from "../../../components/RetailMarketingSystem/selector";
import "./RetailMarketingRecce.css";
import {
  handleValidationRetailMarketingStoreInformation,
  setRetailRecciControls,
  setRetailRecciDetail,
  resetStoreDetailControls,
} from "../../../actions/RetailManagementSystemAction";
import RetailMarketingSystemService from "../../../services/RetailMarketingSystemService";
import { messages } from "../../../constants/constant.message";
import swal from "sweetalert";
import ModalService from "../../../services/ModalService";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import MultistepForRMS from "../../../components/MultistepForRMS/MultistepForRMS";

class RetailMarketingRecce extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEdit: false,
      isLoading: false,
      isEditAPILoadComplted: false,
      title: "",
      hoRemark: "",
      status: "",
      statusUpdateDate: "",
    };
    if (window.performance) {
      const entries = performance.getEntriesByType("navigation");
      const event = entries.map((nav) => nav.type);
      if (event && Array.isArray(event) && event.length > 0) {
        if (event[0] === "reload") {
          this.goBack();
        }
      }
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");
    if (id) {
      this.setState({
        isEdit: true,
        retail_id: id,
        title: localStorage.getItem("screenTitle"),
      });
      this.getRetailDetail(id);
    }
  }

  getRetailDetail = (id) => {
    let reqData = {
      retail_id: id,
    };
    RetailMarketingSystemService.getRetailDetail(reqData)
      .then((data) => {
        const { data: retail_detail } = data.data;
        this.setState({
          hoRemark: retail_detail.modification_comments,
          status: retail_detail.status,
          statusUpdateDate: retail_detail.updated_at,
        });
        const {
          setRetailRecciDetailAction,
          storeDetailControls,
          setRetailRecciControlsAction,
        } = this.props;
        storeDetailControls.dealer.displayValue =
          retail_detail.dealer_detail.business_name;
        storeDetailControls.dealer.value =
          retail_detail.dealer_detail.dealer_id;
        storeDetailControls.state.value = retail_detail.dealer_detail.state_id;
        storeDetailControls.city.value = retail_detail.dealer_detail.city_id;
        storeDetailControls.district.value =
          retail_detail.dealer_detail.district_id;
        setRetailRecciControlsAction(storeDetailControls);
        setRetailRecciDetailAction(storeDetailControls, retail_detail);
        setTimeout(() => {
          this.setState({
            isEditAPILoadComplted: true,
          });
        }, 1);
      })
      .catch((e) => {
        console.error("e", e);
        this.setisLoading({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        swal({
          title: "",
          text: message,
          icon: "error",
        }).then((isConfirm) => {
          this.goBack();
        });
      });
  };
  goBack = () => {
    const { resetStoreDetailControlsAction, storeDetailControls } = this.props;
    resetStoreDetailControlsAction(storeDetailControls);
    this.props.history.push({
      pathname: "/retail-marketing-system",
    });
  };

  submit = () => {
    const {
      storeDetailControls,
      handleValidationRetailMarketingStoreInformationAction,
    } = this.props;
    if (
      !handleValidationRetailMarketingStoreInformationAction(
        true,
        storeDetailControls
      )
    ) {
      return true;
    }
    const proposedSizeForNewSignature = [];
    if (
      storeDetailControls.proposedSizeForNewSignature &&
      storeDetailControls.proposedSizeForNewSignature.length > 0
    ) {
      storeDetailControls.proposedSizeForNewSignature.forEach((element) => {
        proposedSizeForNewSignature.push({
          pre_facade_front_view: element.frontView.value,
          pre_facade_left_view: element.leftView.value,
          pre_facade_right_view: element.rightView.value,
          pre_facade_closeup_shot: element.closeupView.value,
          pre_facade_long_shot: element.longShotView.value,
          quantity: 1,
          signage_width: Number(element.width.value),
          signage_height: Number(element.height.value),
          square_feet_area: element.totalArea.value,
        });
      });
    }
    const prePareObj = {
      recce_date: storeDetailControls.recce_date.value,
      dealer_id: storeDetailControls.dealer.value,
      address: storeDetailControls.address.value,
      state_id: storeDetailControls.state.value,
      city_id: storeDetailControls.city.value,
      district_id: storeDetailControls.district.value,
      pin_code: storeDetailControls.pincode.value,
      signage_name: storeDetailControls.nameOnTheSignature.value,
      acknowledgement_form: storeDetailControls.acknowledgementForm.value,
      retail_board: proposedSizeForNewSignature,
      remarks: storeDetailControls.remarks.value,
    };
    if (this.state.isEdit) {
      prePareObj.retail_id = this.state.retail_id;
    }
    this.setState({
      isLoading: true,
    });
    RetailMarketingSystemService.addUpdateRetail(prePareObj)
      .then((data) => {
        this.setState({
          isLoading: false,
        });
        if (data.data && data.data.success) {
          swal({
            title: "Congratulations!",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((isConfirm) => {
            this.goBack();
          });
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message =
          err && err.message ? err.message : messages.COMMON.ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  getStyle = () => {
    const obj = {
      opacity: 1,
      color: "#FDBB1F",
      fontWeight: "bold",
    };
    if (this.state.status === "recce_rejected") {
      obj.opacity = 0.2;
    }
    if (
      this.state.status === "installation_pending" ||
      this.state.status === "recce_rejected"
    ) {
      obj.color = "#A3ABB3";
    }
    if (this.state.status !== "recce_modified") {
      obj.fontWeight = 400;
    }
    return obj;
  };
  render() {
    const { isEdit, isLoading } = this.state;
    return (
      <div id="RetailMarketingRecce" style={{ position: "relative" }}>
        {isLoading && <CustomSpinner />}
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: "17px" }}
        >
          <div className="d-flex">
            <img
              src="images/right.svg"
              alt=""
              style={{ transform: "rotate(180deg)" }}
              onClick={() => this.goBack()}
            />
            &nbsp;&nbsp;
            <Label
              className="cursor-pointer back-lbl"
              onClick={() => this.goBack()}
            >
              Back
            </Label>
          </div>
          <Button
            className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
            color="warning"
            type="submit"
            style={{ float: "right", width: "123px" }}
            onClick={(e) => this.submit()}
          >
            {isEdit ? "Re-Submit" : "Submit"}
          </Button>
        </div>
        <span className="header-title">
          {isEdit ? this.state.title : "Add a New Recce"}
        </span>
        {this.state.status !== "" && this.state.status !== "recce_pending" && (
          <MultistepForRMS
            status={this.state.status}
            updateAt={this.state.statusUpdateDate}
          />
        )}
        <div className="m-top-24px">
          {this.state.hoRemark !== "" && this.state.status !== "recce_pending" && (
            <div
              style={{ width: "47.8%", marginLeft: "0px", marginBottom: "8px" }}
              className="service-pref-note d-flex-column ReferralRewardsPreferenceNote ho-remark
              mt-0"
            >
              <Row>
                <Col sm="1" style={{ paddingTop: "4px" }}>
                  <img src="images/group-30.png" alt="" className="Group-3 " />
                </Col>
                <Col style={{ marginLeft: "-15px" }}>
                  <div
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "12px",
                      fontStyle: "normal",
                      lineHeight: "14px",
                      color: "#6E777F",
                      fontWeight: "normal",
                    }}
                  >
                    Remarks by HO
                  </div>
                  <div
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "14px",
                      fontStyle: "normal",
                      lineHeight: "16px",
                      color: "#495057",
                      fontWeight: "normal",
                    }}
                  >
                    {this.state.hoRemark}
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <RetailMarketingStoreInformation isEdit={isEdit} />
        </div>
        <div className="m-top-24px">
          <RetailMarktingUploadPhotograph isEdit={isEdit} />
        </div>
        <div className="d-flex mt-4">
          <Button
            className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold
            ml-auto"
            color="warning"
            type="submit"
            style={{ float: "right", width: "123px" }}
            onClick={(e) => this.submit()}
          >
            {isEdit ? "Re-Submit" : "Submit"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    storeDetailControls: getRetailMarketingStoreInformationState(state),
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRetailRecciControlsAction: setRetailRecciControls,
      handleValidationRetailMarketingStoreInformationAction: handleValidationRetailMarketingStoreInformation,
      setRetailRecciDetailAction: setRetailRecciDetail,
      resetStoreDetailControlsAction: resetStoreDetailControls,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailMarketingRecce);
