import { request } from "./Request";
import { routes } from "../constants/constant.route";

const bannerRoutes = routes.BANNER;
const {
  ADD_EDIT_BANNER,
  GET_TYPES_OF_BANNER,
  GET_BANNER,
  ACTIVE_INACTIVE_BANNER,
  GET_BANNER_VIEW_CLICK,
  GET_ALL_BANNER,
  GET_BANNER_ELIGIBLE_DEALERS,
  GET_BANNER_DEALERS,
  GET_BANNER_ACTIONS
} = bannerRoutes;

const API_URL = process.env.REACT_APP_API_URL;

export default class BannerService {
  static addEditBanner(obj) {
    const headers = {
      "content-type": "multipart/form-data",
    };
    return request(
      "POST",
      `${API_URL}${ADD_EDIT_BANNER}`,
      null,
      obj,
      headers
    );
  }

  static getTypesOfBanner() {
    return request(
      "GET",
      `${API_URL}${GET_TYPES_OF_BANNER}`,
      null,
      null,
      null
    );
  }

  static getBanner() {
    return request(
      "GET",
      `${API_URL}${GET_BANNER}`,
      null,
      null,
      null
    );
  }

  static activeInactiveBanner(obj) {
    return request(
      "POST",
      `${API_URL}${ACTIVE_INACTIVE_BANNER}`,
      null,
      obj,
      null
    );
  }

  static getBannerViewClick(obj) {
    return request(
      "POST",
      `${API_URL}${GET_BANNER_VIEW_CLICK}`,
      null,
      obj,
      null
    );
  }

  static getAllBanner(body) {
    let qp = `?`
    return request(
      "POST",
      `${API_URL}${GET_ALL_BANNER}${qp}`,
      null,
      body,
      null
    );
  }

  static getBannerEligibleDealers(page, pagesize, uuid, q) {
    let qp = `?`
    if (page) {
      qp += `page=${page}&`;
    }
    if (pagesize) {
      qp += `pageSize=${pagesize}&`;
    }
    if (uuid) {
      qp += `uuid=${uuid}&`;
    }
    if (q) {
      qp += `q=${q}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_BANNER_ELIGIBLE_DEALERS}${qp}`,
      null,
      null,
      null
    );
  }

  static getBannerDealers(page, pagesize, uuid, type, q) {
    let qp = `?`
    if (page) {
      qp += `page=${page}&`;
    }
    if (pagesize) {
      qp += `pageSize=${pagesize}&`;
    }
    if (uuid) {
      qp += `uuid=${uuid}&`;
    }
    if (type) {
      qp += `type=${type}&`;
    }
    if (q) {
      qp += `q=${q}`;
    }
    return request(
      "GET",
      `${API_URL}${GET_BANNER_DEALERS}${qp}`,
      null,
      null,
      null
    );
  }

  static getBannerActions() {
    return request(
      "GET",
      `${API_URL}${GET_BANNER_ACTIONS}`,
      null,
      null,
      null
    );
  }
}