import React, { Component, Fragment } from 'react';
import Ionicon from 'react-ionicons';
import {
  Row, Col, Card, CardBody
} from 'reactstrap';
import moment from "moment";

import CustomSpinner from "../CustomSpinner/CustomSpinner";

import './DealerProfile.css';
import { Permissions } from '../../constants/constant.permissions';

class DealerProfile extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    }
  }

  componentDidMount() {
  }

  onCloseSidebar = () => {
    this.props.closeModal();
  }

  render() {
    const { dealerDetail, address } = this.props;
    return <div style={{ height: "110%" }}
    // className="height-100"
    >
      <div className="drawer-header" style={{ paddingTop: '18px' }}>
        <span className="business-name">{dealerDetail.business_name}&nbsp;
          <span className="drawer">({dealerDetail.display_type})</span>
        </span>
        <div className="text-align-right">
          <span className="business-name" style={{ marginRight: '10px' }}>
            SAS: {dealerDetail.sas_average_turn_over || '--'}
          </span>
          <Ionicon className="cursor-pointer" icon="md-close" fontSize="24px" color="#fdbb1f" onClick={() => this.onCloseSidebar()} />
        </div>
      </div>
      <hr style={{ border: 'solid 1px #ced4da', marginTop: '8px' }} />
      {this.state.isLoading && <CustomSpinner />}
      <div className="data-container">
        <Card id="profile-detail" className="height-100" style={{ height: 'auto' }}>
          <CardBody className="padding-top-bottom-0" style={{ background: '#F7F8FA', maxHeight: '340px' }}>
            <Row>
              <Col sm="5">
                <Row>
                  <Col sm="5" className="text-align-center" style={{ paddingLeft: '0px', maxWidth: '120px' }}>
                    {!dealerDetail.image_url && (
                      <img src="images/Web_Avatar.svg" height="50px" width="auto" className="profile-image" alt="" />
                    )}
                    {dealerDetail.image_url && (
                      <img src={dealerDetail.image_url} height="50px" width="auto" className="profile-image" alt="" />
                    )}
                  </Col>
                  <Col sm="7">
                    <div className="business-name" style={{ verticalAlign: 'middle', lineHeight: '80px' }}>
                      {dealerDetail.owner_name || ''}
                    </div>
                  </Col>
                </Row>
                <Fragment>
                  <Row className='margin-top-10'>
                    <Col>
                      <div>Email Address</div>
                      <div>
                        <b>{dealerDetail.email_1 || '--'}</b>
                      </div>
                    </Col>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>Owner’s Phone Number</div>
                      <div className="title-case">
                        <b>{dealerDetail.telephone_no_1 || '--'}</b>
                      </div>
                    </Col>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>Member Code</div>
                      <div className="title-case">
                        <b>{dealerDetail.sap_id || '--'}</b>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div>Date of Joining</div>
                      <div className="title-case">
                        <b>
                          {(dealerDetail.date_of_joining &&
                            moment(dealerDetail.date_of_joining).format(
                              "DD/MM/YYYY"
                            )) ||
                            "--"}
                        </b>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              </Col>
              <Col sm="7" style={{ borderLeft: '1px solid #000000' }}>
                <Row>
                  <Col>
                    <div className="business-name" style={{ verticalAlign: 'middle', lineHeight: '80px' }}>
                      Business Details
                    </div>
                  </Col>
                </Row>
                <Fragment>
                  <Row className='margin-top-10'>
                    <Col>
                      <div>Business Name</div>
                      <div className="title-case">
                        <b>{dealerDetail.business_name || '--'}</b>
                      </div>
                    </Col>
                    <Col>
                      <div>CIBIL Score</div>
                      <div className="title-case">
                        <b>{dealerDetail.cibil_score || '--'}</b>
                      </div>
                    </Col>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>GST Number</div>
                      <div className="title-case">
                        <b>{dealerDetail.gst_number || '--'}</b>
                      </div>
                    </Col>
                    <Col>
                      <div>PAN Number</div>
                      <div className="title-case">
                        <b>{dealerDetail.pan_number || '--'}</b>
                      </div>
                    </Col>
                    {dealerDetail && dealerDetail.permissions && dealerDetail.permissions.includes(Permissions.VIEW_SENDER_ID) === true &&
                      <Col>
                        <div>Sender ID</div>
                        <div className="title-case">
                          <b>{dealerDetail.sender_id || '--'}</b>
                        </div>
                      </Col>
                    }
                  </Row><br />
                  <Row>
                    <Col>
                      <div>Business Address</div>
                      <div className="title-case">
                        <b>{address || '--'}</b>
                      </div>
                    </Col>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>State</div>
                      <div className="title-case">
                        <b>{dealerDetail.state || '--'}</b>
                      </div>
                    </Col>
                    <Col>
                      <div>City</div>
                      <div className="title-case">
                        <b>{dealerDetail.city || '--'}</b>
                      </div>
                    </Col>
                    <Col>
                      <div>Pincode</div>
                      <div className="title-case">
                        <b>{dealerDetail.pin_code || '--'}</b>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              </Col>
            </Row>
          </CardBody><br />
          <CardBody className="padding-top-bottom-0">
            <Row>
              <Col>
                <Fragment>
                  <Row>
                    <div className="business-name" style={{ marginLeft: '15px' }}>
                      Social Details
                    </div>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>Birthdate</div>
                      <div className="title-case">
                        <b>{(dealerDetail.birth_date && moment(dealerDetail.birth_date).format('DD/MM/YYYY')) || '--'}</b>
                      </div>
                    </Col>
                    <Col>
                      <div>Anniversary Date</div>
                      <div className="title-case">
                        <b>{(dealerDetail.anniversary_date && moment(dealerDetail.anniversary_date).format('DD/MM/YYYY')) || '--'}</b>
                      </div>
                    </Col>
                  </Row><br />
                  <Row>
                    <Col>
                      {dealerDetail.website &&
                        <a to="/" href={dealerDetail.website.includes('http') === true ? dealerDetail.website : 'https://' + dealerDetail.website} style={{ margin: '10px' }} target="_blank" rel="noopener noreferrer">
                          <img src="images/website.png" height="30px" width="auto" alt="" style={{ background: 'transparent' }} />
                        </a>
                      }
                      {dealerDetail.facebook_url &&
                        <a to="/" href={dealerDetail.facebook_url.includes('http') === true ? dealerDetail.facebook_url : 'https://' + dealerDetail.facebook_url} style={{ margin: '10px' }} target="_blank" rel="noopener noreferrer">
                          <img src="images/fb.png" height="30px" width="auto" alt="" />
                        </a>
                      }
                      {dealerDetail.instagram_url &&
                        <a href={dealerDetail.instagram_url.includes('http') === true ? dealerDetail.instagram_url : 'https://' + dealerDetail.instagram_url} style={{ margin: '10px' }} target="_blank" rel="noopener noreferrer">
                          <img src="images/instagram.png" height="30px" width="auto" alt="" />
                        </a>
                      }
                    </Col>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>Store Google My Business Link</div>
                      <div className="title-case">
                        {dealerDetail.google_my_business_link &&
                          <a class='link' href={dealerDetail.google_my_business_link} target="_blank" rel="noopener noreferrer">Open Store Google my Business</a>
                        }
                        {!dealerDetail.google_my_business_link && '--'}
                      </div>
                    </Col>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>Store Google My Business Rating Link</div>
                      <div className="title-case">
                        {dealerDetail.google_my_business_rating_link &&
                          <a class='link' href={dealerDetail.google_my_business_rating_link} target="_blank" rel="noopener noreferrer">Open Store Google my Business Rating Link</a>
                        }
                        {!dealerDetail.google_my_business_rating_link && '--'}
                      </div>
                    </Col>
                  </Row><br />
                </Fragment>
              </Col>
              <Col>
                <Fragment>
                  <Row>
                    <div className="business-name" style={{ marginLeft: '15px' }}>
                      Primary Contact Details
                    </div>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>Contact Person</div>
                      <div className="title-case">
                        <b>{dealerDetail.alternate_contact_person || '--'}</b>
                      </div>
                    </Col>
                    <Col>
                      <div>Designation</div>
                      <div className="title-case">
                        <b>{dealerDetail.designation || '--'}</b>
                      </div>
                    </Col>
                  </Row><br />
                  <Row>
                    <Col>
                      <div>Contact Number</div>
                      <div className="title-case">
                        <b>{dealerDetail.alternate_contact_number || '--'}</b>
                      </div>
                    </Col>
                    <Col>
                      <div>Whatsapp Number</div>
                      <div className="title-case">
                        <b>{dealerDetail.whats_app_number || '--'}</b>
                      </div>
                    </Col>
                  </Row><br />
                </Fragment>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div >
    </div >
  }
}

export default DealerProfile;