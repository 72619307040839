import { request } from './Request';
import { routes } from '../constants/constant.route';

const API_URL = process.env.REACT_APP_API_URL;

export default class AboutService {

  static getAboutDetails() {
    return request('GET', `${API_URL}${routes.ABOUT_DETAIL.GET_ABOUT_DETAIL}`, null, null, null);
  }
}