import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import { connect } from "react-redux";
import SubDealerGuide from "./components/SubdealerGuide";
import { Redirect } from "react-router-dom";
import Ionicon from "react-ionicons";
import lodash from "lodash"; // library added for debounce functionality
import swal from "sweetalert";
import format from "date-fns/format";
import Pagination from "../../components/Pagination/Pagination";
import SecondaryPointsFilter from "./components/SecondaryPointsFilter";
import Sidebar from "react-sidebar";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import SendVerifyOtp from "../../components/SendVerifyOtp/SendVerifyOtp";
import WarrantyScoreCardService from "../../services/WarrantyScoreCardService";
import WarrantyRegistrationService from "../../services/WarrantyRegistrationService";
import DashboardService from "../../services/DashboardService";
import StorageService from "../../services/Storage";
import { messages } from "../../constants/constant.message";
import { formatDate, downlodFile, formatNumber } from "../../utils/utils";
import "./SecondaryPointsReport.css";
import Storage from "../../services/Storage";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import AddCustomer from "../../components/AddCustomer/AddCustomer";
import { setAddCustomerRedirect, setRedirect } from "../../actions/UserAction";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";
let sapId = null;
let delearName = null;
const pageSize = 10;

class SecondaryPointsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sapIdFromUrl: false,
      warranty_data: {
        customer_count: 0,
        pcr_wheeler_tyre: 0,
        total_points: 0,
        two_three_wheeler_tyre: 0,
        scv_tyre: 0,
        lcv_tyre: 0,
        sensor_count: 0,
        pcr_wheeler_tyre_with_sensor: 0,
        two_three_wheeler_tyre_with_sensor: 0,
        scv_tyre_with_sensor: 0,
        lcv_tyre_with_sensor: 0,
      },
      openWarrantyRegistrationForSubdealer: false,
      openOtpModule: false,
      isSensor: false,
      activeTab: 0,
      filterControls: {},
      filterApplied: false,
      isLoading: false,
      isHelpModuleLoading: false,
      userDetail: Storage.getUserDetail(),
      isClamRegistrationOpen: false,
      page: 1,
      popuploading: false,
      totalPages: 0,
      search: "",
      warrantyData: [],
      totalWarrantyCounts: 0,
      modelOpenIndex: null,
      isCurrentUser: false,
      manageUserLevel: [],
      currentUser: null,
      randomKey: null,
      dealerRole: null,
      isSentOTP: false,
      isValidForm: false,
      invalidOTP: "",
      openCalender: false,
      totalSeconadryPoints: 0,
      noWarrantyCustomers: 0,
      pcr_wheeler_tyre: 0,
      two_three_wheeler_tyre: 0,
      controls: {
        warrantyPhoneNumber: {
          value: "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
        },
        warrantyOTP: {
          value: "",
          valid: null,
          touched: false,
          message: null,
          nullValue: null,
          invalidFirstname: null,
        },
      },
      redirectToWarrantyRegistration: false,
      token: "",
      isHelpModalOpen: false,
      displayLabels: null,
      yearOptions: [],
      year: null,
      quarter: null,
      month: null,
      pcr_min: null,
      pcr_max: null,
      two_wheel_min: null,
      two_wheel_max: null,
      year_name: null,
      is_dealer: false,
      is_order_history: false,

      tooltipOpen: false,
      tooltip_name: "warranty_report",
      filterMsg: "Showing results of 2019 to Till now",
      isSubDealerLoginfirst: false,
      opensidebarAddcustomer: false,
      openSubDealerGuide: false,
    };
    sapId = null;
    delearName = null;

    this.searchReport = lodash.debounce(this.searchReport, 500);
    const query = new URLSearchParams(this.props.location.search);
    if (query.get("y")) {
      this.state.year = query.get("y");
    }

    if (this.state.userDetail.type === Roles.DEALER_ROLE) {
      this.state.is_dealer = true;
    }
    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.WARRANTY_POINTS_REDEMPTION)
    ) {
      this.state.is_order_history = true;
    }
    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.CHECK_ELIGIBILITY_FOR_WARRANTY)
    ) {
      this.state.isClamRegistrationOpen = true;
    }

    this.WarrantyFilter = React.createRef();
  }

  async componentDidMount() {
    const login = localStorage.getItem("openSubDealerGuide");
    if (login && (login === true || login === "true")) {
      this.openSubDealerGuide();
      localStorage.setItem("openSubDealerGuide", false);
    }
    GoogleAnalticsService.pageView("Warranty Report View");
    // let searchParam = this.props.location.search;
    // this.filterAPI();
    const userDetail = StorageService.getUserDetail();
    const { type } = userDetail;
    this.setState({ dealerRole: type });
    this.getDisplayLabel();
    const query = new URLSearchParams(this.props.location.search);
    sapId = query.get("id");
    if (sapId) {
      await this.setState({
        sapId: sapId,
        sapIdFromUrl: true,
      });
    } else if (!sapId) {
      await this.setState({
        sapIdFromUrl: false,
      });
    }
    delearName = query.get("name");
    const id = query.get("k");
    const q = query.get("q");
    if (q === "t") {
      this.setState({ isHelpModalOpen: true });
    }
    let manageUserLevel = [];
    let currentUser = null;
    if (id) {
      manageUserLevel = StorageService.getNavigationLevelDetail(id);
      if (manageUserLevel && manageUserLevel.length > 0) {
        currentUser = manageUserLevel[manageUserLevel.length - 1];
        if (currentUser && currentUser.sap_id) {
          sapId = currentUser.sap_id;
          delearName = currentUser.name;
        }
      }
      this.setState({
        currentUser,
        manageUserLevel,
        randomKey: id,
        year: currentUser.y,
      });
    } else {
      this.getHelpModuleScreen();
    }

    // if (searchParam) {
    //   let keys = searchParam.split('&');
    //   let idString = keys[0];
    //   let nameString = keys[1];
    //   let id = idString.split('=');
    //   sapId = id[1];
    //   let name = nameString ? nameString.split('=') : null;
    //   delearName = name ? decodeURI(name[1]) : null;

    // }

    if (!sapId && !id) {
      // const userDetail = StorageService.getUserDetail();
      const { sap_id, owner_name } = userDetail;
      sapId = sap_id;
      delearName = owner_name;
      // isCurrentUser = true;
      this.setState({ isCurrentUser: true });
    }
    // sapId = this.props.match.params.sapId;
    // if (!sapId) {
    //   // return to dashboard
    //   this.props.history.push('/dashboard');
    // }
    // let searchParam = this.props.location.search;
    // if (searchParam) {
    //   let keys = searchParam.split('&');
    //   let nameString = keys[0];
    //   let name = nameString ? nameString.split('=') : null;
    //   delearName = name ? decodeURI(name[1]) : null;
    // }

    this.getDealerWarrantyDetail(this.state.page, this.state.search);
    //   this.getSecondaryCount();
    if (
      this.props.redirectDetailsAddCustomer &&
      this.props.redirectDetailsAddCustomer.redirectToAddCustomer
    ) {
      this.props.setAddCustomerRedirect(null);
      this.setState({
        opensidebarAddcustomer: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.redirectDetailsAddCustomer !==
      this.props.redirectDetailsAddCustomer
    ) {
      if (
        this.props.redirectDetailsAddCustomer &&
        this.props.redirectDetailsAddCustomer.redirectToAddCustomer
      ) {
        this.setState(
          {
            opensidebarAddcustomer: false,
          },
          () => {
            this.setState({
              opensidebarAddcustomer: true,
            });
          }
        );
      }
      this.props.setAddCustomerRedirect(null);
    }
  }

  getHelpModuleScreen = () => {
    // if (this.state.isCurrentUser) {
    this.setState({ isHelpModuleLoading: true });
    DashboardService.getHelpModuleScreen()
      .then((data) => {
        this.setState({ isHelpModuleLoading: false });
        const { screen_name } = data.data.data;
        if (
          screen_name.includes(this.state.tooltip_name) === false &&
          this.state.dealerRole === Roles.DEALER_ROLE && this.state.userDetail.customer_class !== "na" && this.state.userDetail.customer_class !== "hy"
        ) {
          this.setState({
            tooltipOpen: true,
            tooltip_name: "customer_registered",
          });
        }
      })
      .catch((e) => {
        this.setState({ isHelpModuleLoading: false });
      });
    // }
  };

  moduleScreenForSubdealer = () => {
    // this.setState({ isHelpModuleLoading: true });
    DashboardService.getHelpModuleScreen()
      .then((data) => {
        // this.setState({ isHelpModuleLoading: false });
        const { screen_name } = data.data.data;
        if (screen_name.includes(this.state.tooltip_name) === false) {
          this.setState({
            tooltipOpen: true,
            tooltip_name: "customer_registered",
          });
        }
      })
      .catch((e) => {
        this.setState({ isHelpModuleLoading: false });
      });
  };
  updateZone = (data) => {
    this.setState();
  };

  getWarrantyData = (data) => {
    this.setState(
      {
        year: data.year || new Date().getFullYear(),
        quarter: data.quarter,
        month: data.month,
        pcr_min: data.pcr_min,
        pcr_max: data.pcr_max,
        two_wheel_min: data.two_wheel_min,
        two_wheel_max: data.two_wheel_max,
        page: 1,
      },
      () => {
        this.getDisplayLabel();
        const sapId =
          this.state.currentUser && this.state.currentUser.sap_id
            ? this.state.currentUser.sap_id
            : null;
        this.getDealerWarrantyDetail(1, this.state.search, false);

        let { filterMsg } = this.state;

        let month = data.month;
        this.state.allMonths = [
          { key: `Jan`, value: 1 },
          { key: `Feb`, value: 2 },
          { key: `Mar`, value: 3 },
          { key: `Apr`, value: 4 },
          { key: `May`, value: 5 },
          { key: `June`, value: 6 },
          { key: `July`, value: 7 },
          { key: `Aug`, value: 8 },
          { key: `Sep`, value: 9 },
          { key: `Oct`, value: 10 },
          { key: `Nov`, value: 11 },
          { key: `Dec`, value: 12 },
        ];
        let d = new Date();
        var currMonth = d.getMonth();
        let currentYear =
          currMonth >= 0 || currMonth <= 2
            ? data.year == new Date().getFullYear() + 1
              ? true
              : false
            : data.year == new Date().getFullYear() + 1
              ? true
              : false;
        for (var key in this.state.allMonths) {
          if (
            currentYear &&
            this.state.allMonths[key]["value"] == currMonth + 1
          ) {
            month = this.state.allMonths[key]["value"];
          }
        }
        if (
          data.year &&
          data.year !== "" &&
          data.year !== "all" &&
          data.year !== null
        ) {
          let lastYear =
            new Date().getMonth() + 1 > 3
              ? Number(data.year.slice(-2)) + 1
              : data.year.slice(-2);
          filterMsg = `Showing results of ${data.year}-${lastYear} Year`;
        }
        if (data.quarter && data.quarter !== "" && data.quarter !== null) {
          let year = data.year
            ? data.year
            : new Date().getFullYear().toString();
          let quarter = [
            `Apr'` + year.slice(-2) + `-June'` + year.slice(-2),
            `July'` + year.slice(-2) + `-Sep'` + year.slice(-2),
            `Oct'` + year.slice(-2) + `-Dec'` + year.slice(-2),
            `Jan'` +
            (Number(year) + 1).toString().slice(-2) +
            `-Mar'` +
            (Number(year) + 1).toString().slice(-2),
          ];
          filterMsg = `Showing results of Quarter ${quarter[data.quarter - 1]}`;
        }
        if (
          data.year === new Date().getFullYear() &&
          month &&
          month !== "" &&
          month !== null
        ) {
          filterMsg = `Showing results of Month ${this.state.allMonths[month - 1]["key"]
            }'${month > 3 ? data.year : Number(data.year + 1)}`;
        }
        if (
          data.year !== new Date().getFullYear() &&
          month &&
          month !== "" &&
          month !== null
        ) {
          filterMsg = `Showing results of Month ${this.state.allMonths[month - 1]["key"]
            }'${month > 3 ? data.year : Number(data.year + 1)}`;
        }
        if (data.year === "all") {
          filterMsg = "Showing results of 2019 to Till now";
        }
        this.setState({ filterMsg, allMonths: this.state.allMonths });
      }
    );
  };

  addHelpModuleScreen(name) {
    this.setState({ isHelpModuleLoading: true });
    DashboardService.addHelpModuleScreen({ screen_name: name })
      .then((data) => {
        this.setState({ tooltipOpen: false, tooltip_name: "" });
        this.setState({ isHelpModuleLoading: false });
      })
      .catch((e) => {
        this.setState({ isHelpModuleLoading: false });
      });
  }

  getDealerWarrantyDetail(page, search, downloadReport = null) {
    this.setState({ isLoading: true });
    let to_date, from_date;
    if (
      this.state.filterControls.start_date &&
      this.state.filterControls.start_date.value !== ""
    ) {
      //from_date = this.state.filterControls.start_date.value;
      let startDate = new Date(this.state.filterControls.start_date.value);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      from_date = format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }

    if (
      this.state.filterControls.end_date &&
      this.state.filterControls.end_date.value !== ""
    ) {
      //to_date = this.state.filterControls.end_date.value;
      let endDate = new Date(this.state.filterControls.end_date.value);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      to_date = format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }

    let sub_dealer =
      this.state.filterControls.sub_dealer &&
        this.state.filterControls.sub_dealer.value
        ? this.state.filterControls.sub_dealer.value
        : null;
    let type =
      this.state.filterControls.type && this.state.filterControls.type.value
        ? this.state.filterControls.type.value
        : null;

    WarrantyScoreCardService.getDealerWarrantyDetailV2(
      page,
      pageSize,
      search,
      sapId,
      downloadReport,
      this.state.year,
      this.state.quarter,
      this.state.month,
      this.state.pcr_min,
      this.state.pcr_max,
      this.state.two_wheel_min,
      this.state.two_wheel_max,
      this.state.isSensor,
      from_date,
      to_date,
      sub_dealer,
      type
    )
      .then((data) => {
        this.setState({ isLoading: false });
        if (downloadReport === true) {
          let { file } = data.data.data;
          downlodFile(file);
          return;
        }
        const { warranty_data, total_warranty_count } = data.data.data;
        const warrantyData = warranty_data;
        const totalWarrantyCounts = total_warranty_count;
        const totalPages = Math.ceil(totalWarrantyCounts / pageSize);
        this.setState({ warrantyData, totalWarrantyCounts, totalPages });
        this.getSecondaryCount();
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  getDisplayLabel = (year) => {
    DashboardService.getDisplayLabel(year)
      .then((data) => {
        const labels = data.data && data.data.data ? data.data.data : null;

        this.setState({ displayLabels: labels });
      })
      .catch((e) => { });
  };

  handleSearchChange = (e) => {
    let searchVal = e.target.value;
    this.setState({ search: searchVal, page: 1 });
    this.searchReport(searchVal, 1);
  };

  searchReport = (search, page) => {
    this.getDealerWarrantyDetail(page, search);
  };

  handlePageChange = (page) => {
    this.setState({ page: page });
    this.getDealerWarrantyDetail(page, this.state.search);
  };

  openModal = (index) => {
    if (typeof index === "number") {
      this.setState({ modelOpenIndex: index });
    } else {
      this.setState({ modelOpenIndex: null });
    }
  };

  gotoWarrantyPage = () => {
    this.props.history.push(`/warranty-score-card-report?y=${this.state.year}`);
  };

  getDataFromNavigation = (index) => {
    let { randomKey, manageUserLevel } = this.state;
    this.props.history.push(
      `/warranty-score-card-report?id=${randomKey}&i=${index}&y=${this.state.year}`
    );
  };

  getPageTitle = () => {
    const { dealerRole } = this.state;
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      return "Head Office";
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      return "Zone Manager";
    } else if (dealerRole === Roles.ZTM_ROLE) {
      return "Zonal Trade Marketing";
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      return "Region Manager";
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      return "Sales Officer";
    } else {
      return "Area Manager";
    }
  };

  sendOTP = async () => {
    const { controls } = this.state;
    controls.warrantyOTP.value = "";
    this.setState({
      popuploading: true,
    });
    let { isSentOTP, popuploading } = this.state;
    try {
      const { warrantyPhoneNumber } = controls;
      if (warrantyPhoneNumber.value) {
        isSentOTP = false;
        const { data } = await WarrantyRegistrationService.sendOTP({
          phone_number: warrantyPhoneNumber.value,
        });
        if (data && data.success) {
          isSentOTP = true;
          popuploading = false;
        }
        this.setState({
          controls,
          isSentOTP,
          popuploading,
          openOtpModule: true,
          openWarrantyRegistrationForSubdealer: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ popuploading: false, controls });
      swal("", messages.WARRANTY_REGISTRATION.INVALIDNUMBER, "warning");
    }
  };

  cancelOTP = () => {
    this.setState({ isSentOTP: false });
  };

  verifyOTP = async () => {
    this.setState({ popuploading: true });
    const { controls } = this.state;
    let { redirectToWarrantyRegistration, token } = this.state;
    const { warrantyPhoneNumber, warrantyOTP } = controls;
    try {
      if (warrantyOTP.value) {
        const { data } = await WarrantyRegistrationService.verifyOTP({
          phone_number: warrantyPhoneNumber.value,
          otp: warrantyOTP.value,
        });
        if (data && data.success) {
          redirectToWarrantyRegistration = true; // when this flag set to true, it will redirect to warranty-registration
          token = (data.data || {}).token;
        } else {
          swal("", messages.WARRANTY_REGISTRATION.INVALIDOTP, "warning");
        }
        this.setState({
          controls,
          redirectToWarrantyRegistration,
          token,
          popuploading: false,
        });
      }
    } catch (error) {
      this.setState({ popuploading: false });
      swal("", messages.WARRANTY_REGISTRATION.INVALIDOTP, "warning");
    }
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (!isNaN(controlValue) && controlName === "warrantyPhoneNumber") {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    } else if (controlName !== "warrantyPhoneNumber") {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    this.setState({ controls });
    // this.handleValidation();
  };

  closeSendVerifyOtp = (phoneNumber, token) => {
    if (phoneNumber) {
      this.redirectToWarrantyReplacement(phoneNumber, token);
    }
  };

  redirectToWarrantyReplacement = (phoneNumber, token) => {
    window.scroll(0, 0);
    this.props.history.push(
      `warranty-replacement?phoneNumber=${phoneNumber}&t=${token}`
    );
  };

  openSendVerifyOtp = () => {
    const wrapper = document.createElement("div");
    ReactDOM.render(
      <SendVerifyOtp closeSendVerifyOtp={this.closeSendVerifyOtp} />,
      wrapper
    );
    const el = wrapper.firstChild;
    swal({
      text: "",
      content: el,
      buttons: false,
      className: "send-verify-otp-modal",
      closeOnClickOutside: false,
      closeOEscapeKey: false,
      closeOnConfirm: false,
    }).then((value) => { });
  };

  getSecondaryCount = () => {
    let {
      warranty_data,
      isClamRegistrationOpen,
      totalSeconadryPoints,
      noWarrantyCustomers,
      pcr_wheeler_tyre,
      two_three_wheeler_tyre,
      filterControls,
    } = this.state;
    let to_date, from_date;
    if (
      filterControls.start_date &&
      this.state.filterControls.start_date.value !== ""
    ) {
      //from_date = this.state.filterControls.start_date.value;
      let startDate = new Date(this.state.filterControls.start_date.value);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      from_date = format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }

    if (
      filterControls.end_date &&
      this.state.filterControls.end_date.value !== ""
    ) {
      //to_date = this.state.filterControls.end_date.value;
      let endDate = new Date(this.state.filterControls.end_date.value);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      to_date = format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }
    let sub_dealer =
      this.state.filterControls.sub_dealer &&
        this.state.filterControls.sub_dealer.value
        ? this.state.filterControls.sub_dealer.value
        : null;
    let type =
      this.state.filterControls.type && this.state.filterControls.type.value
        ? this.state.filterControls.type.value
        : null;
    return DashboardService.getSecondaryCountsV2(
      "all",
      sapId,
      this.state.isSensor,
      from_date,
      to_date,
      sub_dealer,
      type
    )
      .then((data) => {
        data = data.data.data;
        warranty_data = data.dashboardCount;
        isClamRegistrationOpen = data.isClamRegistrationOpen;
        totalSeconadryPoints = data.dashboardCount.total_points
          ? parseInt(data.dashboardCount.total_points)
          : 0;
        noWarrantyCustomers = data.dashboardCount.customer_count
          ? parseInt(data.dashboardCount.customer_count)
          : 0;
        pcr_wheeler_tyre = data.dashboardCount.pcr_wheeler_tyre
          ? parseInt(data.dashboardCount.pcr_wheeler_tyre)
          : 0;
        two_three_wheeler_tyre = data.dashboardCount.two_three_wheeler_tyre
          ? parseInt(data.dashboardCount.two_three_wheeler_tyre)
          : 0;
        this.setState({
          warranty_data,
          totalSeconadryPoints,
          noWarrantyCustomers,
          two_three_wheeler_tyre,
          pcr_wheeler_tyre,
        });
        return Promise.resolve();
      })
      .catch((e) => {
        return Promise.resolve();
      });
  };

  openHelpModal = () => {
    this.setState({ isHelpModalOpen: true });
  };

  closeHelpModal = () => {
    this.setState({ isHelpModalOpen: false });
  };

  openWarrantyRegistrationForSubdealer = () => {
    const {
      controls: { warrantyPhoneNumber },
    } = this.state;
    warrantyPhoneNumber.value = "";
    this.setState({
      openWarrantyRegistrationForSubdealer: !this.state
        .openWarrantyRegistrationForSubdealer,
      warrantyPhoneNumber,
    });
  };

  // =======================================================================

  downloadReport = () => {
    this.getDealerWarrantyDetail(this.state.page, this.state.search, true);
  };

  filterAPI = () => {
    const { year } = this.state;

    let filter = {
      y: year ? year : null,
    };
    this.setState({ isLoader: true });
    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          this.setState({ isLoader: false });

          this.setState(
            {
              yearOptions: res.data.data.year ? res.data.data.year : [],
            },
            () => {
              if (
                !this.state.year &&
                this.state.yearOptions &&
                this.state.yearOptions[0]
              ) {
                this.setState(
                  {
                    year: this.state.yearOptions[0].value,
                    year_name: this.state.yearOptions[0].name,
                  },
                  () => {
                    this.getDealerWarrantyDetail(
                      this.state.page,
                      this.state.search
                    );
                    this.getSecondaryCount();
                  }
                );
              } else {
                this.getDealerWarrantyDetail(
                  this.state.page,
                  this.state.search
                );
                this.getSecondaryCount();
              }
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        console.log("err", err);
      });
  };

  onChangeYear = (e) => {
    this.setState(
      {
        year: e && e.target && e.target.value,
      },
      () => {
        // this.filterAPI();

        this.getDealerWarrantyDetail(this.state.page, this.state.search);
        this.getSecondaryCount();
      }
    );
  };

  redirectToOrderHistory = () => {
    window.scroll(0, 0);
    this.props.history.push(`order-history`);
  };

  changeTab = (activeTab) => {
    let isSensor = activeTab === 0 ? false : true;
    this.setState(
      (prevState) => {
        return { ...prevState, activeTab: activeTab, isSensor: isSensor };
      },
      () => {
        this.getDealerWarrantyDetail(this.state.page, this.state.search);
      }
    );
  };

  applyFilter = (controls) => {
    if (
      !controls.end_date.value &&
      !controls.start_date.value &&
      !controls.sub_dealer.value &&
      !controls.type.value
    ) {
      this.setState({
        filterApplied: false,
      });
    } else if (
      controls.end_date.value ||
      controls.start_date.value ||
      controls.sub_dealer.value ||
      controls.type.value
    ) {
      this.setState({
        filterApplied: true,
      });
    }
    this.setState(
      {
        filterControls: controls,
        // filterApplied: true,
        page: 1,
        count: 0,
        page: 1,
        pageSize: 10,
        totalPages: 0,
      },
      () => {
        this.getDealerWarrantyDetail(this.state.page, this.state.search);
      }
    );
    this.onCloseFilter();
  };

  resetFilter = () => {
    this.setState(
      {
        filterControls: {},
        filterApplied: false,
      },
      () => {
        this.getDealerWarrantyDetail(this.state.page, this.state.search);
      }
    );
    this.onCloseFilter();
  };

  openFilter = () => {
    document.body.style.overflow = "hidden";
    this.setState((prevState) => {
      return {
        ...prevState,
        sidebarOpenFilter: true,
      };
    });
  };

  onCloseFilter = () => {
    document.body.style.overflow = "auto";
    this.setState({
      sidebarOpenFilter: false,
    });
  };

  reOpenToolTipPopOver = (name) => {
    this.setState({
      tooltipOpen: true,
      tooltip_name: name,
    });
  };

  closeAddCustomer = (obj) => {
    if (obj && Object.keys(obj).length > 0) {
      if (obj.is_warranty_redirect) {
        if (localStorage.getItem("add_customer")) {
          let data = JSON.parse(localStorage.getItem("add_customer"));
          let { controls } = this.state;
          if (data && data.phone_number) {
            controls.warrantyPhoneNumber.value = data.phone_number;
            this.setState(
              {
                controls,
              },
              () => {
                this.sendOTP(true);
              }
            );
          }
        }
      }
    }
    this.setState({
      opensidebarAddcustomer: false,
    });
  };

  openSubDealerGuide = () => {
    // const { userDetail } = this.state;
    // userDetail.is_login_first_time = true;
    this.setState({ openSubDealerGuide: true }, () => { });
  };

  subdealerGuideClose = () => {
    // const { userDetail , dealerRole } = this.state;
    // userDetail.is_login_first_time = false;
    this.setState({ openSubDealerGuide: false });
    localStorage.setItem("openSubDealerGuide", false);
    this.moduleScreenForSubdealer();
  };

  render() {
    const {
      warrantyData,
      warranty_data,
      search,
      yearOptions,
      totalWarrantyCounts,
      totalPages,
      modelOpenIndex,
      manageUserLevel,
      isClamRegistrationOpen,
      controls,
      isSentOTP,
      invalidOTP,
      redirectToWarrantyRegistration,
      token,
      dealerRole,
      displayLabels,
      two_three_wheeler_tyre,
      pcr_wheeler_tyre,
      filterMsg,
      userDetail,
    } = this.state;
    const { warrantyPhoneNumber, warrantyOTP } = controls;
    const pageTitle = this.getPageTitle();
    const navigation =
      manageUserLevel && manageUserLevel.length > 0 ? (
        manageUserLevel.map((m, index) => (
          <Fragment>
            <span>
              <Ionicon
                icon="ios-arrow-forward"
                fontSize="34px"
                color="#fdbb1f"
              />
            </span>
            {m.role !== "dealer" && (
              <span
                className="prev-page-link cursor-pointer title-case"
                onClick={this.getDataFromNavigation.bind(this, index + 1)}
              >
                {`Warranty scorecard of ${m.name} (${m.role})`}
              </span>
            )}
            {m.role === "dealer" && (
              <span
                className="current-page title-case"
                onClick={this.getDataFromNavigation.bind(this, index + 1)}
              >
                {`Report of ${m.name}`}
              </span>
            )}
          </Fragment>
        ))
      ) : (
        <Fragment>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span
            className="current-page title-case"
            onClick={this.getDataFromNavigation.bind(this, 0)}
          >
            Warranty Points Report for {delearName}
          </span>
        </Fragment>
      );

    const prepareModalData = (modalData) => {
      return modalData.map((m) => <div>{m.ticket_number}</div>);
    };

    const prepareModalDataSensor = (modalData) => {
      return modalData.map((m) => <div>{m}</div>);
    };

    const prepareRow = warrantyData.map((w, index) => (
      <tr>
        <td>{formatDate(w.registration_date)}</td>
        <td>
          <div>{w.customer_name}</div>
          <div>{w.customer_phone_number}</div>
        </td>
        <td>{w.vehicle_type_name}</td>
        <td>
          <Row>
            {/* <Col xs="3">
              <img src="images/pcr.png" alt="" className="secondary-report-image" />
            </Col> */}
            <Col>
              <div>{w.registration_number}</div>
              <div>
                {w.brand_name} {w.model_name}
              </div>
            </Col>
          </Row>
        </td>

        {this.state.activeTab === 1 && w.serial_number && w.serial_number[0] && (
          <td>
            <div>
              {w.serial_number[0]}{" "}
              <img
                src="images/sidebar-icons/about.svg"
                alt=""
                height="13px"
                onClick={
                  modelOpenIndex === index
                    ? this.openModal
                    : this.openModal.bind(this, index)
                }
              />
              {modelOpenIndex === index && (
                <div style={{ position: "relative" }}>
                  <div
                    className={
                      !this.state.activeTab === 1
                        ? "track-pop-warranty arrow_box_report"
                        : "track-sensor-popup arrow_box_report"
                    }
                  >
                    <Card>
                      <CardBody>
                        {prepareModalDataSensor(w.serial_number)}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              )}
            </div>
            <div>
              {w.count} {w.count > 1 ? "Sensors" : "Sensor"}
            </div>
          </td>
        )}

        {this.state.activeTab === 0 &&
          w.warranty_registration_number &&
          w.warranty_registration_number[0] &&
          w.bonus_point === false && (
            <td>
              <div style={{ position: "relative" }}>
                {w.warranty_registration_number[0].ticket_number}{" "}
                <img
                  src="images/sidebar-icons/about.svg"
                  alt=""
                  height="13px"
                  onClick={
                    modelOpenIndex === index
                      ? this.openModal
                      : this.openModal.bind(this, index)
                  }
                />
                {modelOpenIndex === index && (
                  <div
                    className={
                      this.state.activeTab === 0
                        ? "track-pop-warranty arrow_box_report"
                        : "track-sensor-popup arrow_box_report"
                    }
                  >
                    <Card>
                      <CardBody>
                        {prepareModalData(w.warranty_registration_number)}
                      </CardBody>
                    </Card>
                  </div>
                )}
              </div>
              <div>
                {w.tyre_qty} {w.tyre_qty > 1 ? "Tyres" : "Tyre"}
              </div>
            </td>
          )}
        {!(
          dealerRole === Roles.BRANCH_ROLE ||
          dealerRole === Roles.SUB_DEALER_ROLE ||
          userDetail.customer_class === "na" ||
          userDetail.customer_class === "hy"
        ) && <td>{this.state.activeTab === 0 ? w.points : w.sensor_points}</td>}
        {!(
          dealerRole === Roles.BRANCH_ROLE ||
          dealerRole === Roles.SUB_DEALER_ROLE ||
          userDetail.customer_class === "na" ||
          userDetail.customer_class === "hy"
        ) && <td>{w.nick_name}</td>}
      </tr>
    ));
    return (
      <Fragment>
        <>
          {this.state.opensidebarAddcustomer && (
            <Sidebar
              sidebar={
                <>
                  <AddCustomer
                    ref={this.childCustomiseDetail}
                    resetFilter={this.state.CampaignDetailData}
                    CampaignDetailData={this.state.CampaignDetailData}
                    onSelectTargetGroup={this.changeTargetGroup}
                    getFilterControls={this.state.filterControls}
                    closeAddCustomer={this.closeAddCustomer}
                  />
                </>
              }
              open={true}
              sidebarClassName="campaign-drawer"
              pullRight
              styles={{
                sidebar: {
                  background: "white",
                  height: this.state.height ? this.state.height : "90%",
                  top: "60px",
                  width: "47%",
                  borderRadius: "2px",
                },
              }}
            />
          )}
        </>

        {this.state.openSubDealerGuide &&
          (dealerRole === Roles.SUB_DEALER_ROLE ||
            dealerRole === Roles.BRANCH_ROLE ||
            userDetail.customer_class === "na" ||
            userDetail.customer_class === "hy") && (
            <SubDealerGuide
              is_login_first_time={this.state.openSubDealerGuide}
              subdealerGuideClose={this.subdealerGuideClose}
            />
          )}
        {this.state.sidebarOpenFilter && (
          <Sidebar
            sidebar={
              <>
                <SecondaryPointsFilter
                  applyFilter={this.applyFilter}
                  resetFilter={this.resetFilter}
                  onCloseSidebar={this.onCloseFilter}
                  getFilterControls={this.state.filterControls}
                  sapId={
                    this.state.sapIdFromUrl
                      ? this.state.sapId
                      : this.state.userDetail.sap_id &&
                        /^\d+$/.test(this.state.userDetail.sap_id) === true
                        ? this.state.userDetail.sap_id
                        : this.state.currentUser.sap_id
                          ? this.state.currentUser.sap_id
                          : ""
                  }
                  userDetail={this.state.userDetail}
                />
              </>
            }
            open={this.state.sidebarOpenFilter}
            pullRight
            styles={{
              sidebar: {
                width: "400px",
                background: "white",
                height: "100%",
                top: "60px",
              },
            }}
          />
        )}
        <span className="header-title">Warranty Points Report</span>
        <span>
          {/* {warrantyData.length !== 0 && ( 
            /*&& this.state.isCurrentUser */}
          {dealerRole === Roles.DEALER_ROLE ||
            dealerRole === Roles.SUB_DEALER_ROLE ||
            dealerRole === Roles.BRANCH_ROLE ? (
            <img
              className="mt-auto ml-2 cursor-pointer"
              src="/images/info_white_background.svg"
              alt=""
              style={{ marginBottom: "10px", height: "16px" }}
              onClick={
                dealerRole === Roles.SUB_DEALER_ROLE ||
                  dealerRole === Roles.BRANCH_ROLE ||
                  userDetail.customer_class === "na" ||
                  userDetail.customer_class === "hy"
                  ? () => this.openSubDealerGuide()
                  : () => this.reOpenToolTipPopOver("customer_registered")
              }
            />
          ) : null}
          {/* )} */}
        </span>
        {/* <HelpModal
          show={this.state.isHelpModalOpen}
          closeModal={this.closeHelpModal}
        ></HelpModal> */}
        <div id="secondary-point-report">
          {redirectToWarrantyRegistration && (
            <Redirect
              to={{
                pathname: "/warranty-registration",
                search: `phoneNumber=${warrantyPhoneNumber.value}&t=${token}`,
              }}
            />
          )}

          {(this.state.isLoading || this.state.isHelpModuleLoading) && (
            <CustomSpinner></CustomSpinner>
          )}
          {!this.state.isCurrentUser && (
            <Row>
              <Col>
                <span
                  className="prev-page-link cursor-pointer title-case"
                  onClick={this.gotoWarrantyPage}
                >
                  {this.state.dealerRole === Roles.PROJECT_MANAGER_ROLE
                    ? "Warranty Scorecard"
                    : `Warranty scorecard for ${pageTitle}`}
                </span>
                {navigation}
                {/* <span>
                <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
              </span>
              <span className="current-page">Warranty Points Report for {delearName}</span> */}
              </Col>
            </Row>
          )}

          {/* {(dealerRole === Roles.SUB_DEALER_ROLE || dealerRole === Roles.BRANCH_ROLE) && (
            <Fragment>
              <Row className="warranty-block padding-15">
                <Col>
                  {isSentOTP ? (
                    <Row>
                      <Col
                        xl="8"
                        md="8"
                        sm="8"
                        className="warranty-text-bold"
                        style={{ marginTop: "9px", paddingLeft: "0" }}
                      >
                        <span
                          className="warranty-text"
                          style={{ color: "#545cd8", fontSize: "16px" }}
                        >
                          WARRANTY REGISTRATION
                        </span>
                        <span
                          className="warranty-text"
                          style={{ marginLeft: "15px" }}
                        >
                          Enter the OTP code send to {warrantyPhoneNumber.value}
                          . Didn't get the code? &nbsp;
                          <span
                            onClick={this.sendOTP}
                            className="custom-anchor-tag"
                          >
                            Send Again
                          </span>
                          &nbsp;&nbsp;
                          <span
                            onClick={this.cancelOTP}
                            className="custom-anchor-tag"
                          >
                            Cancel
                          </span>
                        </span>
                      </Col>
                      <Col xl="3" md="3" sm="3" style={{ paddingLeft: "0" }}>
                        <Input
                          type="text"
                          name="warrantyOTP"
                          id="warrantyOTP"
                          className="input-focus-white"
                          value={warrantyOTP.value}
                          onChange={this.handleInputChange}
                          placeholder="Enter OTP"
                        />
                      </Col>
                      <Col xl="1" md="1" sm="1" style={{ padding: "0" }}>
                        <Button
                          disabled={!warrantyOTP.value}
                          style={{ width: "95px" }}
                          onClick={this.verifyOTP}
                          className="mb-1 mr-1 btn-pill action-button send-verify-otp"
                          color="warning"
                        >
                          <b>Submit</b>
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col
                        xl="8"
                        md="8"
                        sm="8"
                        className="warranty-text-bold"
                        style={{
                          marginTop: "9px",
                          paddingLeft: "0",
                          paddingRight: "0",
                          display: "flex",
                        }}
                      >
                        <div
                          className="warranty-text"
                          style={{
                            color: "#545cd8",
                            fontSize: "16px",
                            width: "100%",
                            maxWidth: "222px",
                          }}
                        >
                          WARRANTY REGISTRATION
                        </div>
                        <span
                          className="warranty-text"
                          style={{ marginLeft: "15px" }}
                        >
                          Enter the customer's phone number and we will send you
                          a verification code
                        </span>
                      </Col>
                      <Col xl="3" md="3" sm="3" style={{ paddingLeft: "0" }}>
                        <div className="input-group prefix">
                          <span className="input-group-addon1">+91</span>
                          <Input
                            type="text"
                            name="warrantyPhoneNumber"
                            id="warrantyPhoneNumber"
                            maxLength="10"
                            className="input-focus-white"
                            style={{ paddingLeft: "43px" }}
                            value={warrantyPhoneNumber.value}
                            onChange={this.handleInputChange}
                            placeholder="Enter customer's phone number"
                          />
                        </div>
                      </Col>
                      <Col xl="1" md="1" sm="1" style={{ padding: "0" }}>
                        <Button
                          onClick={this.sendOTP}
                          disabled={
                            !warrantyPhoneNumber.value ||
                            (warrantyPhoneNumber.value &&
                              warrantyPhoneNumber.value.length !== 10)
                          }
                          className="mb-1 mr-1 btn-pill action-button send-verify-otp"
                          color="warning"
                        >
                          <b>Send OTP</b>
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col style={{ paddingRight: '5px', marginTop: '10px' }}>
                  {
                    isClamRegistrationOpen &&
                    <span onClick={() => this.openSendVerifyOtp()} className="custom-anchor-tag custom-pull-right existing-customer-eligibility">Check existing customer’s eligibility for warranty</span>
                  }
                </Col>
              </Row> 
            </Fragment>
          )} */}

          <Row
            style={{
              marginBottom: "30px",
              marginTop: "25px",
              position: "relative",
            }}
          >
            <Col xs="5" className="max-width-450px">
              {/* <Label> */}
              <Input
                placeholder="Search using name, phone no, sensor or warranty number"
                className="report-search-input second-point-report-search"
                onChange={this.handleSearchChange}
                value={search}
              ></Input>
              <img src="images/search.svg" alt="" className="report-search icon-width" />
              {/* </Label> */}
            </Col>
            <div className="d-flex-row">
              {this.state.filterControls &&
                this.state.filterApplied &&
                Object.keys(this.state.filterControls).length > 0 && (
                  <img
                    id="dealer_filter"
                    src="images/filter_applied.svg"
                    alt=""
                    className="cursor-pointer"
                    onClick={() => this.openFilter()}
                  />
                )}
              {this.state.filterControls && !this.state.filterApplied && (
                <img
                  id="dealer_filter"
                  src="images/filter.svg"
                  alt=""
                  className="cursor-pointer"
                  onClick={() => this.openFilter()}
                />
              )}
            </div>
            {dealerRole === Roles.DEALER_ROLE ||
              dealerRole === Roles.SUB_DEALER_ROLE ||
              dealerRole === Roles.BRANCH_ROLE ? (
              <UncontrolledPopover
                placement={"right-start"}
                hideArrow={false}
                isOpen={
                  this.state.tooltipOpen &&
                  this.state.tooltip_name === "dealer_filter"
                }
                fade={true}
                target={"dealer_filter"}
                trigger="focus"
                className="warranty-report"
              >
                <div ref={(r) => (this.dealerPopOver = r)}>
                  <PopoverHeader className="popover-title d-flex justify-content-between">
                    <span>Help Guide Tour</span>
                    <span>4/4</span>
                  </PopoverHeader>
                  <PopoverBody className="d-flex-column">
                    <Row>
                      <Col xl="10">
                        <span className="popover-lbl mt-2">
                          Introducing Filter for Warranty Report
                        </span>
                      </Col>
                    </Row>
                    <span className="popover-lbl-value mt-2">
                      Now filter data for results. You can filter warranty
                      records based on category as well based om date range.
                      <br></br>
                      <br></br> If you want to filter records based on sub
                      dealer/ branch, you have option available too.
                    </span>
                    <br />
                    <div className="d-flex-row justify-content-between mb-2">
                      <span
                        className="link-blue cursor-pointer font-size-14px font-weight-bold"
                        // onClick={() => this.addHelpModuleScreen('sensor_report')}
                        onClick={() => {
                          this.setState({
                            tooltip_name: "sensor_report",
                            tooltipOpen: true,
                          });
                        }}
                      >
                        Previous
                      </span>
                      <span
                        className="link-blue cursor-pointer font-size-14px font-weight-bold"
                        onClick={() =>
                          this.addHelpModuleScreen("warranty_report")
                        }
                      // onClick={() => {
                      //   this.setState({
                      //     tooltip_name: 'dealer_filter',
                      //     tooltipOpen: false
                      //   })
                      // }}
                      >
                        Close
                      </span>
                    </div>
                  </PopoverBody>
                </div>
              </UncontrolledPopover>
            ) : null}
            {/* <Col style={{ left: '-20px', bottom: '-5px' }}>{warrantyData.length !== 0 && this.state.isCurrentUser && <img className="mt-auto mb-auto ml-2 cursor-pointer" src="/images/info_white_background.svg" alt=""
              style={{ height: '16px' }} onClick={() => this.reOpenToolTipPopOver('warranty_report')} />}</Col> */}
            {/* <Col><WarrantyFilter updateZone={this.updateZone} getWarrantyData={this.getWarrantyData} ref={this.WarrantyFilter} data={this.state} /></Col> */}
            {/* <Col xs={warrantyData.length !== 0 ? "6" : "6"} /> */}
            {/* {
              dealerRole !== 'dealer' &&
              <Col xl="4" md="4" xs="4" className="ml-auto" style={{ paddingRight: "40px" }}>
                <Row className="justify-content-end">
                  <div className="fin-label">Financial Year</div>
                &nbsp;
                <FormGroup>
                    <select
                      name="month"
                      className="form-control cursor-pointer"
                      value={this.state.year}
                      style={{
                        fontSize: "13px",
                        width: "110px",
                        color: "#6e777f",
                        marginLeft: "auto",
                      }}
                      onChange={(e) => {

                        this.onChangeYear(e);
                      }}
                    >
                      <option value="all" selected>
                        All
                    </option>
                      {this.state.yearOptions.map((item) => (

                        <option value={item.value}>{item.name}</option>
                      ))}
                    </select>
                  </FormGroup>
                </Row>
              </Col>
            } */}

            {/* {warrantyData.length === 0 && (
              <div className="text-align-right">
                <img
                  src="images/help.png"
                   alt=""
                  className="download-report-icon"
                  onClick={this.openHelpModal}
                 />
              </div>
            )} */}
            {(dealerRole === Roles.SUB_DEALER_ROLE ||
              dealerRole === Roles.BRANCH_ROLE ||
              userDetail.customer_class === "na" ||
              userDetail.customer_class === "hy") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    right: "15px",
                  }}
                >
                  <button
                    onClick={this.openWarrantyRegistrationForSubdealer}
                    className="addNewWarrantyButton"
                  >
                    Add New Warranty Registration
                  </button>
                </div>
              )}
          </Row>
          {this.state.openWarrantyRegistrationForSubdealer && (
            <Col>
              <Card id="registration_modal">
                <Modal
                  isOpen={this.state.openWarrantyRegistrationForSubdealer}
                  centered={true}
                  size="sm-3"
                  contentClassName="custom_modal_content"
                >
                  {this.state.popuploading && <CustomSpinner />}
                  <ModalHeader>WARRANTY REGISTRATION</ModalHeader>
                  <ModalBody>
                    <div style={{ margin: "20px 0" }}>
                      Please provide your customer's mobile number and we will
                      send code to your customer
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          background: "#f4f5f5",
                          border: "1px solid #c0c0c0",
                          padding: "6px 10px",
                          borderRadius: "3px",
                          marginRight: "15px",
                        }}
                      >
                        <span style={{ color: "#495057" }}>+91</span>
                      </div>
                      {/* {this.state.popuploading && <CustomSpinner />} */}
                      <div style={{ width: "100%" }}>
                        <Input
                          type="text"
                          name="warrantyPhoneNumber"
                          id="warrantyPhoneNumber"
                          maxLength="10"
                          className="input-focus-white"
                          value={warrantyPhoneNumber.value}
                          onChange={this.handleInputChange}
                          placeholder="Enter customer's phone number"
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="closeButton"
                      onClick={this.openWarrantyRegistrationForSubdealer}
                    >
                      Close
                    </Button>{" "}
                    <Button
                      onClick={this.sendOTP}
                      disabled={
                        !warrantyPhoneNumber.value ||
                        (warrantyPhoneNumber.value &&
                          warrantyPhoneNumber.value.length !== 10)
                      }
                      className="send-verify-Otp"
                    >
                      Send OTP
                    </Button>
                  </ModalFooter>
                </Modal>
              </Card>
            </Col>
          )}
          {isSentOTP && this.state.openOtpModule ? (
            <Col>
              <Card id="registration_modal">
                <Modal
                  isOpen={this.state.openOtpModule}
                  centered={true}
                  size="sm-3"
                  contentClassName="custom_modal_content"
                >
                  {this.state.popuploading && <CustomSpinner />}
                  <ModalHeader>WARRANTY REGISTRATION</ModalHeader>
                  <ModalBody>
                    <div style={{ margin: "20px 0" }}>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#495057",
                          marginBottom: "8px",
                        }}
                      >
                        Enter the OTP code send to {warrantyPhoneNumber.value}.
                      </span>
                      <br />

                      <span
                        style={{
                          fontSize: "14px",
                          color: "#495057",
                          marginBottom: "8px",
                        }}
                      >
                        Didn't get the code? &nbsp;
                      </span>
                      <span
                        onClick={this.sendOTP}
                        className="custom-anchor-tag"
                      >
                        Send Again
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "100%" }}>
                        <Input
                          type="text"
                          name="warrantyOTP"
                          id="warrantyOTP"
                          className="input-focus-white"
                          value={warrantyOTP.value}
                          onChange={this.handleInputChange}
                          placeholder="Enter OTP"
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="closeButton"
                      onClick={() => {
                        this.setState({
                          openOtpModule: false,
                        });
                      }}
                    >
                      Close
                    </Button>{" "}
                    <Button
                      onClick={this.verifyOTP}
                      disabled={!warrantyOTP.value}
                      className="send-verify-Otp"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </Modal>
              </Card>
            </Col>
          ) : null}
          {/* <Row>
            <Col style={{ margin: '15px 15px 15px 0px' }}>
              <span> {filterMsg} </span>
            </Col>
          </Row> */}
          {/* 
          {(this.state.noWarrantyCustomers > 0 ||
            this.state.totalSeconadryPoints > 0) && (
              <Row className="margin-LR-0 margin-TB-15">
                <CardBody className="gradient-title-nrml">
                  <Row>
                    <Col>
                      <span className="gradient-sub-title_nrml">
                        Warranty Registered Customers {" - "}
                      </span>
                      <span className="gradient-sub-title_nrml">
                        {this.state.noWarrantyCustomers}
                      </span>
                      <br />
                      <span className="gradient-sub-title_nrml">
                        Total Warranty Points {" - "}
                      </span>
                      <span className="gradient-sub-title_nrml">
                        {formatNumber(this.state.totalSeconadryPoints)}
                      </span>
                    </Col>
                    <Col className="text-align-right"></Col>
                  </Row>
                </CardBody>
              </Row>
            )} */}

          <Row xl="12" className="ml-0 mr-0 ">
            <Row xl="12">
              <Col xl="4" className="points_card_container">
                <Card
                  id="customer_registered"
                  className={
                    this.state.tooltipOpen &&
                      !(
                        userDetail.customer_class === "na" ||
                        userDetail.customer_class === "hy"
                      ) &&
                      this.state.tooltip_name === "customer_registered"
                      ? "backgrond-image-f8e184-without-hover pl-2 pt-1 pb-2 box-shadow-40px-0-5"
                      : "backgrond-image-f8e184-without-hover pl-2 pt-1 pb-2"
                  }
                >
                  <CardBody className="padding-bottom-10px">
                    <Row>
                      {!(
                        dealerRole === Roles.SUB_DEALER_ROLE ||
                        dealerRole === Roles.BRANCH_ROLE ||
                        userDetail.customer_class === "na" ||
                        userDetail.customer_class === "hy"
                      ) ? (
                        <Col md="4" xl="4" lg="4" className="d-flex-column">
                          <span className="second-point-report-card-lbl">
                            Total Points
                          </span>
                          <span
                            className="second-point-report-card-lbl-value point-card-value"
                            style={{ marginTop: "20px" }}
                          >
                            {warranty_data.total_points
                              ? formatNumber(warranty_data.total_points)
                              : 0}
                          </span>
                        </Col>
                      ) : null}
                      <Col md="8" xl="8" lg="8" className="d-flex-column">
                        <span className="second-point-report-card-lbl margin-bottom-15">
                          Customer Registered
                        </span>

                        <Row>
                          <Col md="6" xl="6" lg="6" className="d-flex-column">
                            <span className="second-point-report-card-lbl-title">
                              Warranty
                            </span>
                            <span className="second-point-report-card-lbl-value mt-1 point-card-value">
                              {warranty_data.customer_count
                                ? formatNumber(warranty_data.customer_count)
                                : 0}
                            </span>
                          </Col>
                          <Col xl="6" md="6" lg="6" className="d-flex-column">
                            <span className="second-point-report-card-lbl-title">
                              Sensors
                            </span>
                            <span className="second-point-report-card-lbl-value mt-1 point-card-value">
                              {warranty_data.sensor_customer_count
                                ? formatNumber(
                                  warranty_data.sensor_customer_count
                                )
                                : 0}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {dealerRole === Roles.DEALER_ROLE ||
                  dealerRole === Roles.SUB_DEALER_ROLE ||
                  dealerRole === Roles.BRANCH_ROLE ? (
                  <UncontrolledPopover
                    placement={"right-start"}
                    hideArrow={false}
                    isOpen={
                      this.state.tooltipOpen &&
                      this.state.tooltip_name === "customer_registered"
                    }
                    fade={true}
                    target={"customer_registered"}
                    trigger="focus"
                    className="warranty-report"
                  >
                    <div ref={(r) => (this.dealerPopOver = r)}>
                      <PopoverHeader className="popover-title d-flex justify-content-between">
                        <span>Help Guide Tour</span>
                        <span>1/4</span>
                      </PopoverHeader>
                      <PopoverBody className="d-flex-column">
                        <Row>
                          <Col xl="10">
                            <span className="popover-lbl mt-2">
                              Customer Registered and Points
                            </span>
                          </Col>
                        </Row>
                        <span className="popover-lbl-value mt-2">
                          It displays the secondary points earned with Warranty
                          registration.<br></br>
                          <br></br> It also displays the number of customers
                          registered with tyre warranty as well for sensor
                          warranty.
                        </span>
                        <br />
                        <div className="d-flex-row justify-content-end mb-2">
                          <span
                            className="link-blue cursor-pointer font-size-14px font-weight-bold"
                            // onClick={() =>
                            //   this.getHelpModuleScreen("warranty_report")
                            // }
                            onClick={() => {
                              this.setState({
                                tooltipOpen: true,
                                tooltip_name: "warranty_report",
                              });
                            }}
                          >
                            Next
                          </span>
                        </div>
                      </PopoverBody>
                    </div>
                  </UncontrolledPopover>
                ) : null}
              </Col>

              <Col xl="4" className="points_card_container">
                <Card
                  id="warranty_report"
                  className={
                    this.state.tooltipOpen &&
                      this.state.tooltip_name === "warranty_report"
                      ? "backgrond-image-f8e184-without-hover pl-2 pt-1 pb-2 box-shadow-40px-0-5"
                      : "backgrond-image-f8e184-without-hover pl-2 pt-1 pb-2"
                  }
                >
                  <CardBody className="padding-bottom-10px">
                    <Row>
                      <Col xl="12" md="12" lg="12" className="d-flex-column">
                        <span className="second-point-report-card-lbl margin-bottom-15">
                          Tyres Registered with Warranty
                        </span>
                      </Col>
                      <Col xl="12" md="12" lg="12" className="d-flex-column">
                        <Row>
                          <Col xl="3" md="3" lg="3" className="d-flex-column">
                            <span className="second-point-report-card-lbl-title">
                              PCR
                            </span>
                            <span
                              className="second-point-report-card-lbl-value point-card-value"
                              style={{ marginTop: "5px" }}
                            >
                              {warranty_data.pcr_wheeler_tyre
                                ? formatNumber(warranty_data.pcr_wheeler_tyre)
                                : 0}
                            </span>
                          </Col>
                          <Col xl="3" md="3" lg="3" className="d-flex-column">
                            <span
                              className="second-point-report-card-lbl-title"
                              style={{ width: "max-content" }}
                            >
                              2 Wheeler
                            </span>
                            <span
                              className="second-point-report-card-lbl-value point-card-value"
                              style={{ marginTop: "5px" }}
                            >
                              {warranty_data.two_three_wheeler_tyre
                                ? formatNumber(
                                  warranty_data.two_three_wheeler_tyre
                                )
                                : 0}
                            </span>
                          </Col>
                          <Col xl="3" md="3" lg="3" className="d-flex-column">
                            <span className="second-point-report-card-lbl-title">
                              LCV
                            </span>
                            <span
                              className="second-point-report-card-lbl-value point-card-value"
                              style={{ marginTop: "5px" }}
                            >
                              {warranty_data.lcv_tyre
                                ? formatNumber(warranty_data.lcv_tyre)
                                : 0}
                            </span>
                          </Col>
                          <Col xl="3" md="3" lg="3" className="d-flex-column">
                            <span
                              className="second-point-report-card-lbl-title"
                              style={{ width: "max-content" }}
                            >
                              SCV
                            </span>
                            <span
                              className="second-point-report-card-lbl-value point-card-value"
                              style={{ marginTop: "5px" }}
                            >
                              {warranty_data.scv_tyre
                                ? formatNumber(warranty_data.scv_tyre)
                                : 0}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {dealerRole === Roles.DEALER_ROLE ||
                  dealerRole === Roles.SUB_DEALER_ROLE ||
                  dealerRole === Roles.BRANCH_ROLE ? (
                  <UncontrolledPopover
                    placement={"right-start"}
                    hideArrow={false}
                    isOpen={
                      this.state.tooltipOpen &&
                      this.state.tooltip_name === "warranty_report"
                    }
                    fade={true}
                    target={"warranty_report"}
                    trigger="focus"
                    className="warranty-report"
                  >
                    <div ref={(r) => (this.dealerPopOver = r)}>
                      <PopoverHeader className="popover-title d-flex justify-content-between">
                        <span>Help Guide Tour</span>
                        <span>2/4</span>
                      </PopoverHeader>
                      <PopoverBody className="d-flex-column">
                        <Row>
                          <Col xl="10">
                            <span className="popover-lbl mt-2">
                              Tyres Registered with Warranty.
                            </span>
                          </Col>
                        </Row>
                        <span className="popover-lbl-value mt-2">
                          It displays the number of tyres registered by you
                          under warranty for each Category
                        </span>
                        <br />
                        <div className="d-flex-row justify-content-between mb-2">
                          <span
                            className="link-blue cursor-pointer font-size-14px font-weight-bold"
                            // onClick={() => this.addHelpModuleScreen('warranty_report')}
                            onClick={() => {
                              this.setState({
                                tooltipOpen: true,
                                tooltip_name: "customer_registered",
                              });
                            }}
                          >
                            Previous
                          </span>
                          <span
                            className="link-blue cursor-pointer font-size-14px font-weight-bold"
                            // onClick={() => this.addHelpModuleScreen('warranty_report')}
                            onClick={() => {
                              this.setState({
                                tooltipOpen: true,
                                tooltip_name: "sensor_report",
                              });
                            }}
                          >
                            Next
                          </span>
                        </div>
                      </PopoverBody>
                    </div>
                  </UncontrolledPopover>
                ) : null}
              </Col>

              <Col xl="4" className="points_card_container">
                <Card
                  id="sensor_report"
                  className={
                    this.state.tooltipOpen &&
                      this.state.tooltip_name === "sensor_report"
                      ? "backgrond-image-f8e184-without-hover pl-2 pt-1 pb-2 box-shadow-40px-0-5"
                      : "backgrond-image-f8e184-without-hover pl-2 pt-1 pb-2"
                  }
                >
                  <CardBody className="padding-bottom-10px">
                    <Row>
                      <Col lg="12" md="12" xl="12" className="d-flex-column">
                        <span className="second-point-report-card-lbl margin-bottom-15">
                          Sensors Registered
                        </span>
                      </Col>
                      <Col md="12" xl="12" lg="12" className="d-flex-column">
                        <Row>
                          <Col md="3" xl="3" lg="3" className="d-flex-column">
                            <span className="second-point-report-card-lbl-title">
                              PCR
                            </span>
                            <span
                              className="second-point-report-card-lbl-value point-card-value"
                              style={{ marginTop: "5px" }}
                            >
                              {warranty_data.pcr_wheeler_tyre_with_sensor
                                ? formatNumber(
                                  warranty_data.pcr_wheeler_tyre_with_sensor
                                )
                                : 0}
                            </span>
                          </Col>
                          <Col xl="3" md="3" lg="3" className="d-flex-column">
                            <span
                              className="second-point-report-card-lbl-title"
                              style={{ width: "max-content" }}
                            >
                              2 Wheeler
                            </span>
                            <span
                              className="second-point-report-card-lbl-value point-card-value"
                              style={{ marginTop: "5px" }}
                            >
                              {warranty_data.two_three_wheeler_tyre_with_sensor
                                ? formatNumber(
                                  warranty_data.two_three_wheeler_tyre_with_sensor
                                )
                                : 0}
                            </span>
                          </Col>
                          <Col xl="3" md="3" lg="3" className="d-flex-column">
                            <span className="second-point-report-card-lbl-title">
                              LCV
                            </span>
                            <span
                              className="second-point-report-card-lbl-value point-card-value"
                              style={{ marginTop: "5px" }}
                            >
                              {warranty_data.lcv_tyre_with_sensor
                                ? formatNumber(
                                  warranty_data.lcv_tyre_with_sensor
                                )
                                : 0}
                            </span>
                          </Col>
                          <Col xl="3" md="3" lg="3" className="d-flex-column">
                            <span
                              className="second-point-report-card-lbl-title"
                              style={{ width: "max-content" }}
                            >
                              SCV
                            </span>
                            <span
                              className="second-point-report-card-lbl-value point-card-value"
                              style={{ marginTop: "5px" }}
                            >
                              {warranty_data.scv_tyre_with_sensor
                                ? formatNumber(
                                  warranty_data.scv_tyre_with_sensor
                                )
                                : 0}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {dealerRole === Roles.DEALER_ROLE ||
                  dealerRole === Roles.SUB_DEALER_ROLE ||
                  dealerRole === Roles.BRANCH_ROLE ? (
                  <UncontrolledPopover
                    placement={"right-start"}
                    hideArrow={false}
                    isOpen={
                      this.state.tooltipOpen &&
                      this.state.tooltip_name === "sensor_report"
                    }
                    fade={true}
                    target={"sensor_report"}
                    trigger="focus"
                    className="warranty-report"
                  >
                    <div ref={(r) => (this.dealerPopOver = r)}>
                      <PopoverHeader className="popover-title d-flex justify-content-between">
                        <span>Help Guide Tour</span>
                        <span>3/4</span>
                      </PopoverHeader>
                      <PopoverBody className="d-flex-column">
                        <Row>
                          <Col xl="10">
                            <span className="popover-lbl mt-2">
                              Sensors Registered
                            </span>
                          </Col>
                        </Row>
                        <span className="popover-lbl-value mt-2">
                          It displays the number of sensors registered by you
                          under warranty for each category.
                        </span>
                        <br />
                        <div className="d-flex-row justify-content-between mb-2">
                          <span
                            className="link-blue cursor-pointer font-size-14px font-weight-bold"
                            // onClick={() => this.addHelpModuleScreen('sensor_report')}
                            onClick={() => {
                              this.setState({
                                tooltip_name: "warranty_report",
                                tooltipOpen: true,
                              });
                            }}
                          >
                            Previous
                          </span>
                          <span
                            className="link-blue cursor-pointer font-size-14px font-weight-bold"
                            // onClick={() => this.addHelpModuleScreen('sensor_report')}
                            onClick={() => {
                              this.setState({
                                tooltip_name: "dealer_filter",
                                tooltipOpen: true,
                              });
                            }}
                          >
                            Next
                          </span>
                        </div>
                      </PopoverBody>
                    </div>
                  </UncontrolledPopover>
                ) : null}
              </Col>
            </Row>
          </Row>
          <Row style={{ marginRight: "-50px" }} className="mt-3">
            {this.state.is_dealer && this.state.is_order_history && (
              <Col xl="10">
                <span className="you-can-check-redeemed">
                  Note: You can check redeemed point history from order history
                  screen{" "}
                  <span
                    className="custom-anchor-tag cursor-pointer"
                    onClick={() => this.redirectToOrderHistory()}
                  >
                    Click here
                  </span>
                </span>
              </Col>
            )}
          </Row>
          <Row style={{ paddingLeft: "15px" }}>
            <div
              style={{ padding: 0, display: "flex" }}
              className="col-md-12 col-lg-12 col-xl-12"
            >
              {
                <div
                  className="col-md-6 col-lg-6 col-xl-6 d-flex-row"
                  style={{ marginTop: "20px", padding: 0 }}
                >
                  <span
                    onClick={() => this.changeTab(0)}
                    className={
                      this.state.activeTab === 0
                        ? "custom-rc-tab-active"
                        : "custom-rc-tab-deactive"
                    }
                  >
                    Warranty Details
                  </span>
                  <span
                    onClick={() => {
                      this.changeTab(1);
                    }}
                    className={
                      this.state.activeTab === 1
                        ? "custom-rc-tab-active ml-4"
                        : "custom-rc-tab-deactive ml-4"
                    }
                  >
                    Sensor Details
                  </span>
                </div>
              }

              {warrantyData.length !== 0 &&
                !(
                  dealerRole === Roles.SUB_DEALER_ROLE ||
                  dealerRole === Roles.BRANCH_ROLE ||
                  userDetail.customer_class === "na" ||
                  userDetail.customer_class === "hy"
                ) && (
                  <Col
                    xs="2"
                    md="6"
                    lg="6"
                    xl="6"
                    className="pr-0 text-align-right"
                    style={{ height: "50px", marginLeft: "auto", padding: 0 }}
                  >
                    {/* {(dealerRole === Roles.SUB_DEALER_ROLE || dealerRole === Roles.BRANCH_ROLE) && (
                    <img
                      src="images/help.png"
                       alt=""
                      className=" download-report-icon"
                      style={{ position: "absolute", bottom: "0", left: "35%" }}
                      onClick={this.openHelpModal}
                     />
                  )} */}
                    <img
                      src="images/download.svg"
                      alt=""
                      className="download-report-icon margin-left-10"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        right: "120px",
                      }}
                      onClick={this.openCalender}
                    />
                    <span
                      className="download-report cursor-pointer"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        right: "15px",
                      }}
                      onClick={this.downloadReport}
                    >
                      {" "}
                      Download Report
                    </span>
                  </Col>
                )}
            </div>
          </Row>

          <Row className="margin-top-10">
            <Col>
              <Card>
                <CardBody className="overflow-auto">
                  <Table>
                    <thead>
                      <tr>
                        <th
                          className={
                            dealerRole === Roles.BRANCH_ROLE ||
                              dealerRole === Roles.SUB_DEALER_ROLE ||
                              userDetail.customer_class === "na" ||
                              userDetail.customer_class === "hy"
                              ? "smallWidthTable"
                              : ""
                          }
                        >
                          Registration Date
                        </th>
                        <th
                          className={
                            dealerRole === Roles.BRANCH_ROLE ||
                              dealerRole === Roles.SUB_DEALER_ROLE ||
                              userDetail.customer_class === "na" ||
                              userDetail.customer_class === "hy"
                              ? "smallWidthTable"
                              : ""
                          }
                        >
                          Customer Name & Phone no.{" "}
                        </th>
                        <th
                          className={
                            dealerRole === Roles.BRANCH_ROLE ||
                              dealerRole === Roles.SUB_DEALER_ROLE ||
                              userDetail.customer_class === "na" ||
                              userDetail.customer_class === "hy"
                              ? "smallWidthTable"
                              : ""
                          }
                        >
                          Vehicle Type
                        </th>
                        <th
                          className={
                            dealerRole === Roles.BRANCH_ROLE ||
                              dealerRole === Roles.SUB_DEALER_ROLE ||
                              userDetail.customer_class === "na" ||
                              userDetail.customer_class === "hy"
                              ? "smallWidthTable"
                              : ""
                          }
                        >
                          Vehicle Info.
                        </th>
                        <th>
                          {this.state.activeTab === 0
                            ? "Warranty Details"
                            : "Sensor Details"}
                        </th>
                        {!(
                          dealerRole === Roles.BRANCH_ROLE ||
                          dealerRole === Roles.SUB_DEALER_ROLE ||
                          userDetail.customer_class === "na" ||
                          userDetail.customer_class === "hy"
                        ) && (
                            <th>
                              {this.state.activeTab === 0
                                ? "Warranty Pts. Earned"
                                : "Sensor Points Earned"}
                            </th>
                          )}
                        {!(
                          dealerRole === Roles.BRANCH_ROLE ||
                          dealerRole === Roles.SUB_DEALER_ROLE ||
                          userDetail.customer_class === "na" ||
                          userDetail.customer_class === "hy"
                        ) && (
                            // <th>Sub-dealer/Branch Name</th>
                            <th>Staff / Branch / Sub Dealer</th>
                          )}
                      </tr>
                    </thead>
                    <tbody>{prepareRow}</tbody>
                  </Table>
                  {!this.state.filterApplied && warrantyData.length === 0 && (
                    <div className="text-align-center card-point-header">
                      Currently {delearName ? delearName : ",you"} have not
                      registered any customer for warranty.
                    </div>
                  )}
                  {this.state.filterApplied && warrantyData.length === 0 && (
                    <div className="text-align-center card-point-header">
                      We can't find any records matching your search criteria.
                      Please change search criteria and try again.
                    </div>
                  )}
                  {totalPages > 1 && (
                    <Pagination
                      margin={2}
                      page={this.state.page}
                      count={totalPages}
                      pageSize={pageSize}
                      totalRecords={totalWarrantyCounts}
                      onPageChange={this.handlePageChange}
                    ></Pagination>
                  )}
                </CardBody>
                {warrantyData.length === 0 && (
                  <Card>
                    <CardBody className="text-align-center card-point-header"></CardBody>
                  </Card>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

// export default SecondaryPointsReport;
const mapDispatchToProps = (dispatch) => ({
  setAddCustomerRedirect: (redirectDetailsAddCustomer) =>
    dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
  setRedirect: (redirectDetails) => dispatch(setRedirect(redirectDetails)),
});

const mapStateToProps = (state) => ({
  redirectDetailsAddCustomer: state.User.redirectDetailsAddCustomer,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryPointsReport);
