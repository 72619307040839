import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  Nav,
  CardHeader,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import Sidebar from "react-sidebar";
import { Typeahead } from "react-bootstrap-typeahead";
import swal from "sweetalert";
import { formatDate } from "../../utils/utils";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import PrimaryPointsReportModal from "../../modal/PrimaryPointsReportModal/PrimaryPointsReportModal";
import UserService from "../../services/UserService";
import Validation from "../../services/Validation";
import EmailCampaignTable from "./EmailCampaignTable";
import SkuListTable from "./SkuListTable";
import UserUpdateService from "../../services/UserUpdateService";
import RetailMarketingSystemService from "../../services/RetailMarketingSystemService";

import "./UserUpdates.css";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import Storage from "../../services/Storage";
import CampaignService from "../../services/CampaignService";
import classnames from "classnames";

import RMSVendorEnrollAndDealerMapping from "./RMSVendorEnrollAndDealerMapping";
import DealerProfileModal from "../../modal/DealerProfileModal/DealerProfileModal";
import AdminService from "../../services/AdminService";
import { Permissions } from "../../constants/constant.permissions";
import UserDocumentsService from "../../services/UserDocumentsService";
import FleetCommunicationService from "../../services/FleetCommunicationService";
import WhatsappCampaignTable from "./WhatsappCampaignTable";
import WhatsappTemplates from "./WhatsappTemplates/WhatsappTemplates";

const { ERROR_MESSAGE } = messages.COMMON;
const advantage_role_types = {
  am: "Area Manager",
  so: "Sales Officer",
  zm: "Zone Manager",
  sm: "Sales Manager",
};

const employee_sample_files = {
  am:
    "https://s3.amazonaws.com/images.jktyrecrm.in/others/AM+Enroll+Format.xlsx",
  so:
    "https://s3.amazonaws.com/images.jktyrecrm.in/others/SO+Enroll+Format.xlsx",
  zm:
    "https://s3.amazonaws.com/images.jktyrecrm.in/others/ZM+Enroll+Format.xlsx",
  sm:
    "https://s3.amazonaws.com/images.jktyrecrm.in/others/RM+Enroll+Format.xlsx",
  sampleFile: "https://images.jktyrecrm.in/others/Employee+Modification.xlsx",
  whatsappSampleFile:
    "https://s3.amazonaws.com/images.jktyrecrm.in/others/WhatsAppMessages_Format.xlsx",
};

const defaultFilterControls = {
  typeRadio: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  upload_type: {
    value: "new",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  upload_type_employee: {
    value: "advantage",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  uuid: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  login_id: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  member_id: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  sap_id: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  selectedFile: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  selectedFileHtml: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  selectedAttachmentFile: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  from_name: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  from_email: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  campaign_name: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  subject: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  fileName: "",
  stateValue: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  cityValue: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
  districtValue: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
  upload_role_type_employee: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
  sku_id: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
  sku_description: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
  sku_group: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
  sku_type: {
    value: "normal",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
  document_type: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
  document_group_id: {
    value: null,
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
    districtExistsError: false,
  },
};

const whatsappDefaultControls = {
  wtemplate_name: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  wtemplate_msg: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  wtemplate_media_value: {
    value: "",
    valid: null,
    touched: false,
    required: false,
    showErrorMsg: false,
  },
  wtemplate_media_filename_value: {
    value: "",
    valid: null,
    touched: false,
    required: false,
    showErrorMsg: false,
  },
  wis_personalized: {
    value: false,
    valid: null,
    touched: false,
    required: false,
    showErrorMsg: false,
  },

  wdocument_type: {
    value: "",
    valid: null,
    touched: false,
    required: false,
    showErrorMsg: false,
  },
  wdocument_group_id: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  whatsapp_no_file: {
    value: "",
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  wtemplate: {
    value: {},
    valid: null,
    touched: false,
    required: true,
    showErrorMsg: false,
  },
  fileName: "",
};

const allTypes = [
  {
    key: "Change Password to Default",
    value: "change_password",
  },
  {
    key: "Map Sap ID to Fleet",
    value: "map_sap",
  },
  {
    key: "Update User Status",
    value: "update_status",
  },
  {
    key: "Update Dealer Classification",
    value: "update_class",
  },
  {
    key: "Point Report",
    value: "point_report",
  },
  {
    key: "View Dealer's Profile",
    value: "view_dealer_profile",
  },
  {
    key: "Upload Redemption Points",
    value: "upload_redemption_points",
  },
  {
    key: "Email Campaign",
    value: "email_campaign",
  },
  {
    key: "Whatsapp Campaign",
    value: "whatsapp_campaign",
  },
  // {
  //   key: "Whatsapp Templates",
  //   value: "whatsapp_templates",
  // },
  {
    key: "Dealer Master",
    value: "dealers_details",
  },
  {
    key: "Fleet Import",
    value: "import_fleet",
  },
  {
    key: "Fleet Master",
    value: "fleet_details",
  },
  {
    key: "Generate All Fleet Scorecard",
    value: "generate_all_fleet_scorecard",
  },
  {
    key: "Add New City Or District",
    value: "add_new_city",
  },
  {
    key: "Download State, City, District, Territory Master",
    value: "download_city_state",
  },
  {
    key: "Manage SKU",
    value: "manage_sku",
  },
];

const ref = React.createRef();
class UserUpdates extends Component {
  state = {
    fileUploadData: [],
    userDetail: Storage.getUserDetail(),
    suggestions: [],
    createCampaignSideBar: false,
    whatsappCampaignSideBar: false,
    addSkuSideBar: false,
    isPrimaryPointReportModalOpen: false,
    uuid: null,
    isLoader: false,
    actionMenuIndex: null,
    isFormValid: "",
    isSkuFormValid: "",
    stateData: [],
    cityData: [],
    districtData: [],
    addDistict: false,
    // districtExistsError:false,
    types: allTypes,
    filterTypes: allTypes,
    dealerOrBaadshahSwitch: "",
    cityOrDistrictSwitch: "",
    controls: JSON.parse(JSON.stringify(defaultFilterControls)),
    activeTabUplaodType: "enroll_new_employee",
    downloadEmployeeSampleFile: employee_sample_files.sampleFile,
    dealerbyId: {},
    isDealerProfileModal: false,
    currentClassification: "",
    adminSwitch: "advantage_switch",
    whatsappSwitch: "whatsapp_campaign",
    isPersonalized: false,
    isPersonalizedHelpPopover: false,
    documentTypes: [],
    wtControls: JSON.parse(JSON.stringify(whatsappDefaultControls)),
    isWtFormValid: false,
    templateMessageRows: 9,
    whatsappTemplates: [],
    whatsappSampleFile: employee_sample_files.whatsappSampleFile,
  };

  constructor(props) {
    super(props);
    this.childWhatsappTableRef = React.createRef();
    this.childWhatsappTemplates = React.createRef();
  }

  componentDidMount() {
    this.getWhatsappStaticTemplates();
    const types = [...this.state.types];
    if (
      this.state.userDetail.permissions.includes(
        Permissions.DATA_UPDATE_UTILITY
      )
    ) {
      types.push({
        key: "Data Modification and Upload",
        value: "data_modification_and_upload",
      });
    }
    types.push({
      key: "RMS Vendor Enroll & Dealer Mapping",
      value: "rms_vendor_enroll_dealer_mapping",
    });

    this.setState(
      {
        types: types,
        filterTypes: types,
      },
      () => {
        if (this.state.adminSwitch === "advantage_switch") {
          // Advantage
          const filterArrAdv = this.state.types.filter(
            (type) =>
              type.value !== "import_fleet" &&
              type.value !== "fleet_details" &&
              type.value !== "generate_all_fleet_scorecard"
          ); // Fleet Import & Fleet Master
          this.setState({ filterTypes: filterArrAdv });
        } else {
          // Baadshah
          const filterArrBd = this.state.types.filter(
            (type) =>
              type.value === "import_fleet" ||
              type.value === "fleet_details" ||
              type.value === "generate_all_fleet_scorecard"
          ); // Fleet Import & Fleet Master
          this.setState({ filterTypes: filterArrBd });
        }
      }
    );

    this.getState();
    this.getAllDealerReport();
    this.getDocumentTypes();
  }

  getState = () => {
    this.setState({
      isLoader: true,
    });
    CampaignService.getState()
      .then((data) => {
        const { data: states } = data.data;
        this.setState({ stateData: states });
        this.setState({
          isLoader: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
      });
  };

  getCity = (state = null) => {
    this.setState({
      isLoader: true,
    });
    const reqData = {
      state_id: state,
    };
    CampaignService.getCity(reqData)
      .then((data) => {
        const { data: cities } = data.data;
        this.setState({ cityData: cities });
        this.setState({
          isLoader: false,
        });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({
          isLoader: false,
        });
      });
  };

  getStateDistrict = async (state) => {
    this.setState({ addDistict: false });
    this.setState({
      isLoader: true,
    });

    const reqData = {
      state_id: state,
    };
    await RetailMarketingSystemService.getStateDistrict(reqData)
      .then((data) => {
        const { data: districts } = data.data;
        this.setState({ districtData: districts });
        this.setState({
          isLoader: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
      });
  };

  getAllDealerReport = (page, search) => {
    UserService.searchDealer(search)
      .then((data) => {
        const { data: dealers } = data.data;
        const suggestions = dealers;
        this.setState({ suggestions });
      })
      .catch((e) => {});
  };

  getDealerDetails = () => {
    this.setState({
      isLoader: true,
    });
    CampaignService.getDealersDetails()
      .then((data) => {
        window.open(data.data.data.file, "_blank");
        this.setState({
          isLoader: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
      });
  };
  getFleetMasterData = () => {
    this.setState({
      isLoader: true,
    });
    CampaignService.getFleetDetails()
      .then((data) => {
        window.open(data.data.data.file, "_blank");
        this.setState({
          isLoader: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
      });
  };

  getDocumentTypes = async () => {
    let documentTypes = await UserDocumentsService.getDocumentTypes();
    documentTypes = documentTypes.data.data || [];
    this.setState({ documentTypes });
  };

  downloadAdvantageBaadshahEmployeeMaster = (employe_type) => {
    this.setState({
      isLoader: true,
    });

    const data = {
      type: employe_type,
    };
    CampaignService.getAdvantageBaadshahEmployeeMaster(data)
      .then((data) => {
        if (data.data.success) {
          window.open(data.data.data.file, "_blank");
          this.setState({
            isLoader: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
      });
  };

  downloadStateCityDistrictMaster = () => {
    this.setState({
      isLoader: true,
    });
    CampaignService.getStateCityDistrictMaster()
      .then((data) => {
        window.open(data.data.data.file, "_blank");
        this.setState({
          isLoader: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
      });
  };

  handleRadioChange = (controlName, e) => {
    let { controls } = this.state;
    // if(e.target.value === 'point_report'){
    //   this.setState({ uuid: '' });
    // }
    // this.setState({ uuid: '' });
    if (e.target.value === "upload_redemption_points") {
      this.getRedemptionFileData();
    }

    controls = JSON.parse(JSON.stringify(defaultFilterControls));

    const radioControl = controls[controlName];
    radioControl.value = e.target.value;

    if (e.target.value === "data_modification_and_upload") {
      this.setState({ dealerOrBaadshahSwitch: "dealer" });
    } else {
      this.setState({ controls });
      this.setState({ dealerOrBaadshahSwitch: "" });
    }
    if (e.target.value === "add_new_city") {
      this.setState({ cityOrDistrictSwitch: "city" });
    } else {
      this.setState({ controls });
      this.setState({ cityOrDistrictSwitch: "" });
    }

    // if(e.target.value === 'dealers_details'){
    //   this.getDealerDetails();
    // }
  };

  handleRadioChangeType = (controlName, e) => {
    const { controls } = this.state;
    const upload_type = controls[controlName];
    const upload_type_employee = controls[controlName];
    if (controlName === "upload_type_employee") {
      upload_type_employee.value = e.target.value;
      controls.upload_role_type_employee.value = null;
    } else {
      upload_type.value = e.target.value;
    }

    if (this.fileInput) {
      this.fileInput.value = "";
      controls.fileName = "";
      controls.selectedFile.value = "";
      controls.selectedFile.touched = false;
      controls.selectedFile.showErrorMsg = false;
    }
    this.setState({ controls }, () => {
      this.setEmployeeSampleFile(null);
    });
  };

  openSideBarCreateCampaign = (value) => {
    this.setState({
      createCampaignSideBar: value,
    });
  };

  openSideBarAddSku = (value) => {
    this.setState({
      addSkuSideBar: value,
    });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (controlName === "stateValue") {
      this.getCity(controlValue);
      this.getStateDistrict(controlValue);
      controls.districtValue.value = "";
    }

    if (controlName === "cityValue") {
      controls[controlName].districtExistsError = false;
    }
    if (controlName === "districtValue") {
      controls[controlName].districtExistsError = false;
    }

    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    // if(controls.districtValue.showErrorMsg  === true){
    //   controls.districtValue.touched  = true
    //   controls.districtValue.value  = ''
    // }
    if (
      controls[controlName].touched === true &&
      (controls[controlName].value === "" ||
        controls[controlName].value === null)
    ) {
      controls[controlName].showErrorMsg = true;
    } else {
      controls[controlName].showErrorMsg = false;
    }

    if (controlName === "selectedFile" && e.target.value) {
      controls.fileName = e.target.value.name;
    }

    if (controlName === "from_email" && e.target.value) {
      const email = Validation.emailValidator(controls[controlName]);
      controls[controlName].valid = !email.invalidEmail;
      if (controls[controlName].valid) {
        controls[controlName].showErrorMsg = false;
      } else {
        controls[controlName].showErrorMsg = true;
      }
    }
    this.setState({ controls });
  };

  getRedemptionFileData = () => {
    this.setState({
      isLoader: true,
    });

    let { fileUploadData } = this.state;

    UserUpdateService.getRedemptionFileData()
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.data) {
          fileUploadData = data.data.data;
          this.setState({ fileUploadData });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  statusUpdate = () => {
    if (!this.handleValidationFileMap(true)) {
      return;
    }

    this.setState({
      isLoader: true,
    });

    let { controls } = this.state;

    const formData = new FormData();

    if (controls.selectedFile.value) {
      formData.append("file", controls.selectedFile.value);
    }

    UserUpdateService.updateStatus(formData)
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ controls });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  dataModificationandUploadFM = () => {
    if (!this.handleValidationFileMap(true)) {
      return;
    }
    this.setState({
      isLoader: true,
    });
    let { controls, activeTabUplaodType } = this.state;

    const formData = new FormData();
    if (controls.selectedFile.value) {
      formData.append("file", controls.selectedFile.value);
      if (controls.upload_type_employee.value === "advantage") {
        formData.append(
          "upload_type",
          activeTabUplaodType === "enroll_new_employee" ? "new" : "update"
        );
        if (activeTabUplaodType === "enroll_new_employee") {
          formData.append(
            "role_type",
            advantage_role_types[controls.upload_role_type_employee.value]
          );
        }
      }
      formData.append("employee_type", controls.upload_type_employee.value);
    }

    UserUpdateService.dataModificationandUploadFM(formData)
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ dealerOrBaadshahSwitch: " ", controls });
            // this.setState({ controls });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  dataModificationandUpload = () => {
    if (!this.handleValidationFileMap(true)) {
      return;
    }

    this.setState({
      isLoader: true,
    });

    let { controls } = this.state;

    const formData = new FormData();

    if (controls.selectedFile.value) {
      formData.append("file", controls.selectedFile.value);
      formData.append("upload_type", controls.upload_type.value);
    }

    UserUpdateService.dataModificationandUpload(formData)
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ controls, dealerOrBaadshahSwitch: "" });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  fleetDataUpload = () => {
    if (!this.handleValidationFileMap(true)) {
      return;
    }

    this.setState({
      isLoader: true,
    });

    let { controls } = this.state;

    const formData = new FormData();

    if (controls.selectedFile.value) {
      formData.append("file", controls.selectedFile.value);
      formData.append("upload_type", controls.upload_type.value);
    }

    UserUpdateService.fleetDataUpload(formData)
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ controls, dealerOrBaadshahSwitch: "" });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  classUpdate = () => {
    if (!this.handleValidationFileMap(true)) {
      return;
    }

    this.setState({
      isLoader: true,
    });

    let { controls } = this.state;

    const formData = new FormData();

    if (controls.selectedFile.value) {
      formData.append("file", controls.selectedFile.value);
    }

    UserUpdateService.updateClass(formData)
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ controls });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  uploadRedemptionPoints = () => {
    if (!this.handleValidationFileMap(true)) {
      return;
    }

    this.setState({
      isLoader: true,
    });

    let { controls } = this.state;

    const formData = new FormData();

    if (controls.selectedFile.value) {
      formData.append("file", controls.selectedFile.value);
    }

    UserUpdateService.uploadRedemptionPoints(formData)
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ controls });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  handleAdminSwitchChange = (switchVal) => {
    this.setState({ adminSwitch: switchVal });

    const { controls } = this.state;
    controls.selectedFile.value = "";
    controls.selectedFile.valid = null;
    controls.selectedFile.touched = false;
    controls.selectedFile.nullValue = null;
    controls.selectedFile.showErrorMsg = false;
    controls.fileName = "";
    this.setState({
      controls: JSON.parse(JSON.stringify(defaultFilterControls)),
    });
    this.setState({ dealerOrBaadshahSwitch: "" });
    this.setState({ cityOrDistrictSwitch: "" });
    if (switchVal === "advantage_switch") {
      const filterArrAdv = this.state.types.filter(
        (type) =>
          type.value !== "import_fleet" &&
          type.value !== "fleet_details" &&
          type.value !== "generate_all_fleet_scorecard"
      ); // Fleet Import & Fleet Master
      this.setState({ filterTypes: filterArrAdv });
    } else {
      // baadshah_switch
      const filterArrBd = this.state.types.filter(
        (type) =>
          type.value === "import_fleet" ||
          type.value === "fleet_details" ||
          type.value === "generate_all_fleet_scorecard"
      ); // Fleet Import & Fleet Master
      this.setState({ filterTypes: filterArrBd });
    }
  };

  handleWhatsappwitchChange = (switchVal) => {
    this.setState({ whatsappSwitch: switchVal });
    if (switchVal === "whatsapp_campaign") {
      this.getWhatsappStaticTemplates();
    }
  };

  handledealerOrBaadshahSwitchChange = (switchValue) => {
    this.setState({ dealerOrBaadshahSwitch: switchValue });
    const { controls } = this.state;
    controls.selectedFile.value = "";
    controls.selectedFile.valid = null;
    controls.selectedFile.touched = false;
    controls.selectedFile.nullValue = null;
    controls.selectedFile.showErrorMsg = false;
    controls.fileName = "";
    this.setState({ controls });
    // ref.current.clear();
  };

  handlecityOrDistrictSwitchChange = (switchValue) => {
    this.setState({ cityOrDistrictSwitch: switchValue });
    const { controls } = this.state;
    controls.stateValue.value = "";
    controls.districtValue.value = "";
    controls.cityValue.value = "";
    controls.stateValue.showErrorMsg = false;
    controls.districtValue.showErrorMsg = false;
    controls.districtValue.districtExistsError = false;
    controls.cityValue.showErrorMsg = false;
    controls.cityValue.cityExistsError = false;
    this.setState({ controls });
    // this.setState({controls:JSON.parse(JSON.stringify(defaultFilterControls))});
  };

  handleValidationFileMap = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    const { selectedFile } = controls;

    isFormValid = true;

    if (
      (selectedFile.touched === true || isSubmit) &&
      selectedFile.value === ""
    ) {
      selectedFile.showErrorMsg = true;
      isFormValid = false;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  mapSap = () => {
    if (!this.handleValidationMapSap(true)) {
      return;
    }
    this.setState({
      isLoader: true,
    });
    UserUpdateService.mapSap({
      id: this.state.controls.member_id.value,
      sap_id: this.state.controls.sap_id.value,
    })
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            let { controls } = this.state;
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ controls });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  handleValidationMapSap = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    const { member_id, sap_id } = controls;

    isFormValid = true;

    if ((member_id.touched === true || isSubmit) && member_id.value === "") {
      member_id.showErrorMsg = true;
      isFormValid = false;
    }

    if ((sap_id.touched === true || isSubmit) && sap_id.value === "") {
      sap_id.showErrorMsg = true;
      isFormValid = false;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  addCity = () => {
    if (!this.handleCityValidation(true)) {
      return;
    }
    const { controls, cityOrDistrictSwitch } = this.state;
    const obj = {
      state_id: controls.stateValue.value,
      district_id: controls.districtValue.value,
      city_name:
        cityOrDistrictSwitch === "city" ? controls.cityValue.value : "",
      type: cityOrDistrictSwitch,
    };
    this.setState({ isLoader: true });
    UserUpdateService.addCity(obj)
      .then((data) => {
        this.setState({ isLoader: false });
        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            let { controls } = this.state;
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ cityOrDistrictSwitch: "", controls });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });

    //  this.setState({isLoader:true})
  };

  updatePasword = () => {
    if (!this.handleValidationUpdatePasword(true)) {
      return;
    }
    this.setState({
      isLoader: true,
    });
    UserUpdateService.updatePasswordToDefault({
      id: this.state.controls.login_id.value,
    })
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            let { controls } = this.state;
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            this.setState({ controls });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  handleCityValidation = (isSubmit = false) => {
    let {
      controls,
      isFormValid,
      cityData,
      districtData,
      addDistict,
      cityOrDistrictSwitch,
    } = this.state;
    isFormValid = true;
    if (
      (controls.cityValue.value === null ||
        controls.cityValue.value === "" ||
        controls.cityValue.showErrorMsg) &&
      cityOrDistrictSwitch === "city"
    ) {
      controls.cityValue.showErrorMsg = true;
      isFormValid = false;
    }
    if (
      controls.stateValue.value === null ||
      controls.stateValue.value === "" ||
      controls.stateValue.showErrorMsg
    ) {
      controls.stateValue.showErrorMsg = true;
      isFormValid = false;
    }
    if (
      controls.districtValue.value === null ||
      controls.districtValue.value === "" ||
      controls.districtValue.showErrorMsg
    ) {
      controls.districtValue.showErrorMsg = true;
      isFormValid = false;
    }
    {
      cityOrDistrictSwitch === "city" &&
        cityData.map((item) => {
          if (item.name == controls.cityValue.value) {
            isFormValid = false;
            controls.cityValue.showErrorMsg = true;
            controls.cityValue.cityExistsError = true;
          }
        });
    }
    {
      addDistict &&
        districtData.map((item) => {
          if (item.name == controls.districtValue.value) {
            isFormValid = false;
            controls.districtValue.showErrorMsg = true;
            controls.districtValue.districtExistsError = true;
          }
        });
    }
    {
      cityOrDistrictSwitch === "district" &&
        districtData.map((item) => {
          if (item.name == controls.districtValue.value) {
            isFormValid = false;
            controls.districtValue.showErrorMsg = true;
            controls.districtValue.districtExistsError = true;
          }
        });
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleValidationUpdatePasword = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    const { login_id } = controls;

    if ((login_id.touched === true || isSubmit) && login_id.value === "") {
      login_id.showErrorMsg = true;
      isFormValid = false;
    } else {
      login_id.showErrorMsg = false;
      isFormValid = true;
    }

    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  // selectedSap = (radioVal) => {

  //   if (
  //     this.state.controls.uuid &&
  //     this.state.controls.uuid.value &&
  //     this.state.controls.uuid.value.uuid
  //   ) {
  //     this.setState({ uuid: this.state.controls.uuid.value.uuid }, () => {
  //       // ref.current.clear();
  //       // this.handleInputChange({ target: { name: 'uuid', value: null } });
  //       if (radioVal === 'point_report') {
  //         this.setState({ isPrimaryPointReportModalOpen: true });
  //       } else {
  //         this.setState({ isLoader: true });
  //         let userObj = {
  //           id: this.state.uuid || "",
  //         }
  //         UserService.getUserInfo(userObj)
  //           .then((data) => {
  //             const userData = data.data.data;
  //             this.setState({ dealerbyId: userData }, () => {

  //               this.setState({ isDealerProfileModal: true });
  //               this.setState({ isLoader: false });
  //             })
  //           })
  //           .catch((e) => {
  //             console.error("e", e);
  //             this.setState({ isLoader: false });
  //           });

  //       }
  //     });
  //   }
  // };

  selectedSap = (radioVal) => {
    if (
      this.state.controls.uuid &&
      this.state.controls.uuid.value &&
      this.state.controls.uuid.value.uuid
    ) {
      this.setState({ uuid: this.state.controls.uuid.value.uuid }, () => {
        // this.setState({ isPrimaryPointReportModalOpen: true });

        this.setState({ isLoader: true });
        let userObj = {
          id: this.state.uuid || "",
        };
        UserService.getUserInfo(userObj)
          .then((data) => {
            const userData = data.data.data;
            this.setState(
              {
                currentClassification:
                  userData.display_type || this.state.userDetail.display_type,
              },
              () => {
                this.setState({ isPrimaryPointReportModalOpen: true });
                this.setState({ isLoader: false });
              }
            );
          })
          .catch((e) => {
            console.error("e", e);
            this.setState({ isLoader: false });
          });
      });
    }
  };

  getWhatsappStaticTemplates = () => {
    UserService.getWhatsappStaticTemplates()
      .then((data) => {
        const templates = data.data.data;
        this.setState({
          whatsappTemplates: templates,
        });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoader: false });
      });
  };

  viewDealerProfile = (radioVal) => {
    if (
      this.state.controls.uuid &&
      this.state.controls.uuid.value &&
      this.state.controls.uuid.value.uuid
    ) {
      this.setState({ uuid: this.state.controls.uuid.value.uuid }, () => {
        this.setState({ isLoader: true });
        let userObj = {
          id: this.state.uuid || "",
        };
        UserService.getUserInfo(userObj)
          .then((data) => {
            const userData = data.data.data;
            this.setState({ dealerbyId: userData }, () => {
              this.setState({ isDealerProfileModal: true });
              this.setState({ isLoader: false });
            });
          })
          .catch((e) => {
            console.error("e", e);
            this.setState({ isLoader: false });
          });
      });
    }
  };

  openPrimaryPointReportModal = () => {
    this.setState({ isPrimaryPointReportModalOpen: true });
  };

  closePrimaryPointReportModal = () => {
    this.setState({ isPrimaryPointReportModalOpen: false });
  };

  sendMail = () => {
    this.setState({
      createCampaignSideBar: false,
    });
    // const typeRadio = this.state.controls;
    // typeRadio.value = 'email_campaign';
    // this.setState({ controls });
    if (!this.handleValidationSendmail(true)) {
      return;
    }

    this.setState({
      isLoader: true,
    });

    let { controls, isPersonalized } = this.state;
    const formData = new FormData();

    if (controls.selectedFile.value) {
      formData.append("file", controls.selectedFile.value);
    }

    if (controls.selectedFileHtml.value) {
      formData.append("file", controls.selectedFileHtml.value);
    }

    if (controls.selectedAttachmentFile.value) {
      formData.append("file", controls.selectedAttachmentFile.value);
    }

    formData.append("from_name", controls.from_name.value);
    formData.append("from_email", controls.from_email.value);
    formData.append("campaign_name", controls.campaign_name.value);
    formData.append("subject", controls.subject.value);
    formData.append("personalized", isPersonalized);
    if (isPersonalized) {
      formData.append("document_type", controls.document_type.value);
      if (controls.document_type.value) {
        formData.append("document_group_id", controls.document_group_id.value);
      }
    }

    UserUpdateService.emailSend(formData)
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            const radioControl = controls.typeRadio;
            radioControl.value = "email_campaign";
            this.setState({ controls });
          });
        }
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  sendWhatsappMsg = () => {
    // const typeRadio = this.state.controls;
    // typeRadio.value = 'email_campaign';
    // this.setState({ controls });
    let { wtControls } = this.state;
    console.log("wtControls sennnnd", wtControls);
    if (!this.handleSendWhatsappValidation(true)) {
      return;
    }

    this.setState({ isLoader: true });

    let { isPersonalized, controls, whatsappTemplates } = this.state;
    const formData = new FormData();

    if (wtControls.whatsapp_no_file.value) {
      formData.append("file", wtControls.whatsapp_no_file.value);
    }
    formData.append("template_name", wtControls.wtemplate_name.value);
    const templateData = whatsappTemplates.find(
      (x) => x.templateName === wtControls.wtemplate_name.value
    );
    formData.append("template_message", wtControls.wtemplate_msg.value);
    formData.append(
      "msg_type",
      templateData.msgType ? String(templateData.msgType).toLowerCase() : "text"
    );
    if (templateData.template && templateData.template.header) {
      formData.append(
        "media_type",
        String(templateData.template.header).toLowerCase()
      );
    } else if (templateData.msgType.toLowerCase() !== "text") {
      formData.append("media_type", templateData.msgType);
    }
    formData.append("personalized", wtControls.wis_personalized.value);
    if (
      wtControls.wis_personalized.value === true ||
      wtControls.wis_personalized.value === "true"
    ) {
      formData.append("file_name", wtControls.wdocument_type.value);
      formData.append("document_type", wtControls.wdocument_type.value);
      if (wtControls.wdocument_type.value) {
        formData.append(
          "document_group_id",
          wtControls.wdocument_group_id.value
        );
      }
    } else {
      formData.append("media_url", wtControls.wtemplate_media_value.value);
      formData.append(
        "file_name",
        wtControls.wtemplate_media_filename_value.value
      );
    }

    UserUpdateService.whatsappUtility(formData)
      .then((data) => {
        setTimeout(() => {
          this.setState({
            isLoader: false,
          });
          if (data.data && data.data.message) {
            swal({
              title: "",
              text: data.data.message,
              icon: "success",
              type: "success",
            }).then((value) => {
              controls = JSON.parse(JSON.stringify(defaultFilterControls));
              wtControls = JSON.parse(JSON.stringify(whatsappDefaultControls));
              const radioControl = controls.typeRadio;
              radioControl.value = "email_campaign";
              this.setState({
                wtControls,
                whatsappCampaignSideBar: data.data.success ? false : true,
              });
              if (this.childWhatsappTableRef.current) {
                this.childWhatsappTableRef.current.getWhatsappReport();
              }
            });
          }
        }, 5000);
      })
      .catch((e) => {
        this.setState({
          isLoader: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "warning");
      });
  };

  saveSkuData = async () => {
    const typeRadio = this.state.controls;
    typeRadio.value = "manage_sku";
    this.setState({ controls });
    const isValid = await this.handleValidationSkuSave(true);
    if (!isValid) {
      return true;
    }
    this.setState({
      isLoader: true,
    });

    let { controls } = this.state;
    // const formData = new FormData();
    const passData = {
      material_code: controls.sku_id.value,
      sku_group: controls.sku_group.value,
      description: controls.sku_description.value,
      sku_type: controls.sku_type.value,
    };
    AdminService.saveSkuData(passData)
      .then((data) => {
        this.setState({
          isLoader: false,
        });

        if (data.data && data.data.message) {
          swal({
            title: "",
            text: data.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            controls = JSON.parse(JSON.stringify(defaultFilterControls));
            const radioControl = controls.typeRadio;
            radioControl.value = "manage_sku";
            this.setState({ controls, addSkuSideBar: false });
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          isLoader: false,
          // addSkuSideBar: false,
        });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        if (err && err.status !== "400") {
          this.setState({
            addSkuSideBar: false,
          });
        }
        this.setState({
          addSkuSideBar: true,
        });
        ModalService.openAlert("", message, "warning");
      });
  };

  handleValidationSendmail = (isSubmit) => {
    let { controls, isFormValid } = this.state;
    const {
      from_email,
      from_name,
      subject,
      campaign_name,
      selectedFile,
      selectedFileHtml,
      document_type,
      document_group_id,
    } = controls;

    isFormValid = true;

    if ((from_email.touched === true || isSubmit) && from_email.value === "") {
      from_email.showErrorMsg = true;
      isFormValid = false;
    } else {
      const email = Validation.emailValidator(from_email);
      from_email.valid = !email.invalidEmail;
      if (from_email.valid) {
        from_email.showErrorMsg = false;
        isFormValid = true;
      } /* else {
        from_email.showErrorMsg = true;
        isFormValid = true;
      } */
    }

    if ((from_name.touched === true || isSubmit) && from_name.value === "") {
      from_name.showErrorMsg = true;
      isFormValid = false;
    } /* else {
      from_name.showErrorMsg = false;
      isFormValid = true;
    } */

    if ((subject.touched === true || isSubmit) && subject.value === "") {
      subject.showErrorMsg = true;
      isFormValid = false;
    } /* else {
      subject.showErrorMsg = false;
      isFormValid = true;
    } */

    if (
      (campaign_name.touched === true || isSubmit) &&
      campaign_name.value === ""
    ) {
      campaign_name.showErrorMsg = true;
      isFormValid = false;
    } /* else {
      campaign_name.showErrorMsg = false;
      isFormValid = true;
    } */

    if (
      (selectedFile.touched === true || isSubmit) &&
      selectedFile.value === ""
    ) {
      selectedFile.showErrorMsg = true;
      isFormValid = false;
    } /* else {
      isFormValid = true;
    } */

    if (
      (selectedFileHtml.touched === true || isSubmit) &&
      selectedFileHtml.value === ""
    ) {
      selectedFileHtml.showErrorMsg = true;
      isFormValid = false;
    } /* else {
      isFormValid = true;
    } */

    if (this.state.sendPersonalizedAttachment) {
      if (!document_type.value) {
        document_type.showErrorMsg = true;
        isFormValid = false;
      }
      if (!document_group_id.value) {
        controls.document_group_id.showErrorMsg = true;
        isFormValid = false;
      }
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  toggleClose = () => {
    const { controls } = this.state;
    controls.campaign_name.showErrorMsg = false;
    controls.from_name.showErrorMsg = false;
    controls.subject.showErrorMsg = false;
    controls.from_email.showErrorMsg = false;
    controls.selectedFile.showErrorMsg = false;
    controls.selectedFileHtml.showErrorMsg = false;
    this.setState({ createCampaignSideBar: false, controls });
  };

  toggleWhatsappCampaignClose = () => {
    this.setState({
      whatsappCampaignSideBar: false,
      wtControls: JSON.parse(JSON.stringify(whatsappDefaultControls)),
    });
  };

  toggleAddSkuClose = () => {
    let { controls } = this.state;
    controls = JSON.parse(JSON.stringify(defaultFilterControls));
    controls.typeRadio.value = "manage_sku";
    this.setState({ addSkuSideBar: false, controls });
  };

  handleValidationSkuSave = (isSubmit) => {
    let { controls, isSkuFormValid } = this.state;
    const { sku_id, sku_description, sku_group } = controls;

    isSkuFormValid = true;
    if ((sku_id.touched === true || isSubmit) && !sku_id.value) {
      sku_id.showErrorMsg = true;
      isSkuFormValid = false;
    }

    if (
      (sku_description.touched === true || isSubmit) &&
      !sku_description.value
    ) {
      sku_description.showErrorMsg = true;
      isSkuFormValid = false;
    }

    if ((sku_group.touched === true || isSubmit) && !sku_group.value) {
      sku_group.showErrorMsg = true;
      isSkuFormValid = false;
    }
    this.setState({ controls, isSkuFormValid });
    return isSkuFormValid;
  };

  setEmployeeSampleFile = (role = null) => {
    const downloadEmployeeSampleFile =
      role && employee_sample_files[role]
        ? employee_sample_files[role]
        : employee_sample_files.sampleFile;
    this.setState({
      downloadEmployeeSampleFile,
    });
  };

  setLoading = (isLoader = false) => {
    this.setState({
      isLoader,
    });
  };

  setIsPersonalizedCheckboxValue = () => {
    // let { isPersonalized } = this.state;
    const isPersonalized = !this.state.isPersonalized;
    this.setState({ isPersonalized });
    if (!isPersonalized) {
      this.clearDocumentInputs();
    }
  };

  clearDocumentInputs = () => {
    const { controls } = this.state;
    const {
      document_type: documentType,
      document_group_id: documentGroupId,
    } = controls;
    documentType.showErrorMsg = false;
    documentGroupId.showErrorMsg = false;
    documentType.value = "";
    documentGroupId.value = "";
    this.setState({ ...controls });
    // this.setState({ isPersonalized: true });
  };

  infoIcon = (message, id) => {
    return (
      <>
        {/* <button type="button" id="ScheduleUpdateTooltip" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title={message}> */}
        <img
          className="mt-auto ml-2 cursor-pointer"
          src="/images/info_white_background.svg"
          alt=""
          id={id ? id : "ScheduleUpdateTooltip"}
          // title={message}
          style={{ marginBottom: 2, height: "16px" }}
          // onClick={listenerFunc}
        />
        {/* </button>; */}
        <UncontrolledTooltip
          placement="top"
          target={id ? id : "ScheduleUpdateTooltip"}
          trigger="hover"
        >
          {message}
        </UncontrolledTooltip>
      </>
    );
  };

  generateAllFleetScorecard = async () => {
    try {
      const response = await FleetCommunicationService.generateAllFleetScorecard();
      if (response.data && response.data.message) {
        swal({
          title: "",
          text: response.data.message,
          icon: "success",
          type: "success",
        });
      }
    } catch (e) {
      swal({
        title: "",
        text: e.response && e.response.data && e.response.data.message,
        icon: "failure",
        type: "failure",
      });
    }
  };

  // Whatsapp
  openWhatsappSideBarCreateCampaign = (value) => {
    this.setState({
      whatsappCampaignSideBar: value,
      wtControls: JSON.parse(JSON.stringify(whatsappDefaultControls)),
    });
  };

  openWhatsappSideBarCreateCampaignFromTemplate = (value) => {
    this.setState({
      whatsappSwitch: "whatsapp_campaign",
      whatsappCampaignSideBar: true,
    });
  };

  handleInputWhatsAppChange = (name, value) => {
    let { wtControls, templateMessageRows, whatsappTemplates } = this.state;
    wtControls[name].value = value;
    wtControls[name].touched = true;

    if (
      wtControls[name].touched === true &&
      wtControls[name].required === true &&
      (wtControls[name].value === "" || wtControls[name].value === null)
    ) {
      wtControls[name].showErrorMsg = true;
    } else {
      wtControls[name].showErrorMsg = false;
    }
    function countNewLines(message) {
      // Regular expression to match newline characters
      const regex = /\r\n?|\n/g;
      // Use match method to find all occurrences of newline characters and count them
      const matches = message.match(regex);
      // Return the count of newline characters
      return matches ? matches.length : 0;
    }
    if (name === "wtemplate_name") {
      if (value) {
        const maxRows = 9;
        const minRows = 4;

        const template = whatsappTemplates.find(
          (x) => x.templateName === value
        );
        wtControls["wtemplate_msg"].value = template
          ? template.template.body
          : "";
        wtControls.wtemplate.value = template ? template : {};
        // Calculate the number of rows based on textarea content
        // let rowCount = wtControls["wtemplate_msg"].value.split("\r").length;
        let rowCount = countNewLines(wtControls["wtemplate_msg"].value);
        if (rowCount <= 4 && wtControls["wtemplate_msg"].value.length > 150) {
          const charsPerRow = 60;
          rowCount = Math.ceil(
            wtControls["wtemplate_msg"].value.length / charsPerRow
          );
        }
        templateMessageRows =
          Math.min(maxRows, Math.max(minRows, rowCount)) + 2;
        wtControls["wtemplate_msg"].showErrorMsg = false;
        wtControls["wtemplate_msg"].touched = false;

        wtControls["wtemplate_media_value"].value = "";
        // wtControls['wtemplate_media_value'].showErrorMsg = isMediaIndex !== -1 ? true : false;
        // wtControls['wtemplate_media_value'].touched = isMediaIndex !== -1 ? true : false;
        wtControls["wtemplate_media_filename_value"].value = "";
      } else {
        wtControls["wtemplate_msg"].value = "";
        wtControls["wtemplate_msg"].showErrorMsg = true;
        wtControls["wtemplate_msg"].touched = true;

        wtControls.wtemplate.value = {};
        wtControls["wtemplate_media_value"].value = "";
        // wtControls['wtemplate_media_value'].showErrorMsg = false;
        // wtControls['wtemplate_media_value'].touched = false;
        wtControls["wtemplate_media_filename_value"].value = "";
      }
    }

    if (name === "wis_personalized") {
      wtControls["wdocument_type"].value = "";
      wtControls["wdocument_group_id"].value = "";
    }

    if (name === "whatsapp_no_file") {
      wtControls.fileName = value.name;
    }

    if (name === "wdocument_type") {
      wtControls["wdocument_group_id"].value = "";
      if (value) {
        wtControls["wdocument_group_id"].showErrorMsg = true;
        wtControls["wdocument_group_id"].touched = true;
      } else {
        wtControls["wdocument_group_id"].showErrorMsg = false;
        wtControls["wdocument_group_id"].touched = false;
      }
    }

    // if (controlName === 'selectedFile' && e.target.value) {
    //   controls.fileName = e.target.value.name;
    // }

    // if (controlName === 'from_email' && e.target.value) {
    //   const email = Validation.emailValidator(controls[controlName]);
    //   controls[controlName].valid = !email.invalidEmail;
    //   if (controls[controlName].valid) {
    //     controls[controlName].showErrorMsg = false;
    //   } else {
    //     controls[controlName].showErrorMsg = true;
    //   }
    // }
    this.setState({ wtControls, templateMessageRows });
  };

  handleSendWhatsappValidation = (isSubmit) => {
    let { wtControls, isWtFormValid } = this.state;
    const {
      wtemplate_name,
      wtemplate_msg,
      wtemplate_media_value,
      wis_personalized,
      wdocument_type,
      wdocument_group_id,
      whatsapp_no_file,
    } = wtControls;

    isWtFormValid = true;

    if (
      (wtemplate_name.touched === true || isSubmit) &&
      wtemplate_name.value === ""
    ) {
      wtemplate_name.showErrorMsg = true;
      isWtFormValid = false;
    }

    if (
      (wtemplate_msg.touched === true || isSubmit) &&
      wtemplate_msg.value === ""
    ) {
      wtemplate_msg.showErrorMsg = true;
      isWtFormValid = false;
    }

    // if (
    //   ((wtControls.wtemplate_name.value && WhatsappTempData && WhatsappTempData.length > 0) && WhatsappTempData.findIndex(v => v.id === Number(wtControls.wtemplate_name.value) && v.type === "Media") !== -1) &&
    //   (wtemplate_media_value.touched === true || isSubmit) &&
    //   wtemplate_media_value.value === '') {
    //   wtemplate_media_value.showErrorMsg = true;
    //   isWtFormValid = false;
    // }

    if (
      (wis_personalized.value === true || wis_personalized.value === "true") &&
      wdocument_type.value &&
      (wdocument_group_id.touched === true || isSubmit) &&
      wdocument_group_id.value === ""
    ) {
      console.log("Tettttts");
      wdocument_group_id.showErrorMsg = true;
      isWtFormValid = false;
    }

    if (
      (whatsapp_no_file.touched === true || isSubmit) &&
      (whatsapp_no_file.value === "" || whatsapp_no_file.value === undefined)
    ) {
      whatsapp_no_file.showErrorMsg = true;
      isWtFormValid = false;
    }

    this.setState({ wtControls, isWtFormValid });

    return isWtFormValid;
  };

  render() {
    const {
      // types,
      filterTypes,
      controls,
      isLoader,
      isPrimaryPointReportModalOpen,
      uuid,
      suggestions,
      dealerOrBaadshahSwitch,
      addDistict,
      cityOrDistrictSwitch,
      downloadEmployeeSampleFile,
      fileUploadData,
      isDealerProfileModal,
      currentClassification,
      adminSwitch,
      isPersonalized,
      documentTypes,
      wtControls,
      templateMessageRows,
      whatsappTemplates,
      whatsappSampleFile,
      whatsappSwitch,
    } = this.state;
    const addCityStyle = {
      width: "40%",
      marginTop: addDistict ? "0px" : "-24%",
    };
    const {
      fileName,
      upload_type,
      upload_type_employee,
      upload_role_type_employee,
      sku_type,
    } = controls;

    let file = null;
    file = fileName ? (
      <span>{fileName}</span>
    ) : (
      <span className="hover-download">Upload Excel</span>
    );

    let fileExcelOrCSV = null;
    fileExcelOrCSV = wtControls.fileName ? (
      <span>{wtControls.fileName}</span>
    ) : (
      <span className="hover-download">Upload Excel Or CSV</span>
    );

    const { typeRadio } = controls;

    const renderRadioAdvantageEmployeeRoleType = () => {
      return (
        <div
          style={{
            marginTop: "10px",
            marginLeft: "10px",
            marginBottom: 15,
          }}
        >
          <label
            style={{ paddingLeft: 20, cursor: "pointer" }}
            className="container-rb"
          >
            ZM
            <input
              type="radio"
              name="upload_role_type_employee"
              value="zm"
              onChange={() => {
                controls.upload_role_type_employee.value = "zm";
                this.setState(
                  {
                    controls,
                  },
                  () => {
                    this.setEmployeeSampleFile("zm");
                  }
                );
              }}
              checked={upload_role_type_employee.value === "zm"}
            />
            <span className="checkmark-rb" />
          </label>
          <label
            style={{
              paddingLeft: 20,
              cursor: "pointer",
              marginLeft: "15px",
            }}
            className="container-rb"
          >
            RM
            <input
              type="radio"
              name="upload_role_type_employee"
              value="sm"
              onChange={() => {
                controls.upload_role_type_employee.value = "sm";
                this.setState(
                  {
                    controls,
                  },
                  () => {
                    this.setEmployeeSampleFile("sm");
                  }
                );
              }}
              checked={upload_role_type_employee.value === "sm"}
            />
            <span className="checkmark-rb" />
          </label>
          <label
            style={{
              paddingLeft: 20,
              cursor: "pointer",
              marginLeft: "15px",
            }}
            className="container-rb"
          >
            AM
            <input
              type="radio"
              name="upload_role_type_employee"
              value="am"
              onChange={() => {
                controls.upload_role_type_employee.value = "am";
                this.setState(
                  {
                    controls,
                  },
                  () => {
                    this.setEmployeeSampleFile("am");
                  }
                );
              }}
              checked={upload_role_type_employee.value === "am"}
            />
            <span className="checkmark-rb" />
          </label>
          <label
            style={{
              paddingLeft: 20,
              cursor: "pointer",
              marginLeft: "15px",
            }}
            className="container-rb"
          >
            SO
            <input
              type="radio"
              name="upload_role_type_employee"
              value="so"
              onChange={() => {
                controls.upload_role_type_employee.value = "so";
                this.setState(
                  {
                    controls,
                  },
                  () => {
                    this.setEmployeeSampleFile("so");
                  }
                );
              }}
              checked={upload_role_type_employee.value === "so"}
            />
            <span className="checkmark-rb" />
          </label>
        </div>
      );
    };

    const renderUploadEmplloyee = () => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <FormGroup
              style={{
                marginBottom: "0px",
                marginLeft: "12px",
              }}
            >
              <div className="d-flex">
                <img
                  src="images/download.svg"
                  alt=""
                  className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                />
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#5665d8",
                    "font-size": "12px",
                    cursor: "pointer",
                  }}
                  htmlFor="file"
                >
                  {file}
                </label>
              </div>
              <input
                style={{ display: "none" }}
                id="file"
                type="file"
                accept=".xlsx"
                onChange={(e) =>
                  this.handleInputChange({
                    target: {
                      name: "selectedFile",
                      value: e.target.files[0],
                    },
                  })
                }
                className="width-100"
                ref={(ref) => (this.fileInput = ref)}
              />
              {controls.selectedFile.showErrorMsg && (
                <div className="error">* Please choose file.</div>
              )}
            </FormGroup>
            <div
              className="link-warm-blue cursor-pointer"
              style={{
                marginLeft: "33px",
                cursor: "pointer",
                marginTop: "4px",
              }}
            >
              <a
                href={downloadEmployeeSampleFile}
                target="_blank"
                rel="noopener noreferrer"
                download
                style={{
                  color: "#5665d8",
                  "font-size": "12px",
                  cursor: "pointer",
                }}
              >
                <img
                  src="images/download.svg"
                  alt=""
                  className="download-report-icon cursor-pointer margin-left-10"
                />
                <label style={{ cursor: "pointer" }} className="">
                  Download Sample File
                </label>
              </a>
            </div>
            <div
              className="link-warm-blue cursor-pointer"
              style={{
                marginLeft: "33px",
                cursor: "pointer",
                marginTop: "4px",
              }}
            >
              <div
                onClick={() =>
                  this.downloadAdvantageBaadshahEmployeeMaster(
                    upload_type_employee.value
                  )
                }
                style={{
                  color: "#5665d8",
                  "font-size": "12px",
                  cursor: "pointer",
                }}
              >
                <img
                  src="images/download.svg"
                  alt=""
                  className="download-report-icon cursor-pointer margin-left-10"
                />
                <label style={{ cursor: "pointer" }} className="">
                  Download{" "}
                  {upload_type_employee.value === "baadshah"
                    ? "Baadshah"
                    : "Advantage"}{" "}
                  Employee Master
                </label>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Button
              style={{
                width: "10%",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
              color="warning"
              onClick={this.dataModificationandUploadFM}
            >
              Submit
            </Button>
          </div>
        </div>
      );
    };

    // const renderRadioSkuType = () => {
    //   return (
    //     <div
    //       style={{
    //         marginTop: '10px',
    //         marginLeft: '10px',
    //         marginBottom: 15,
    //       }}
    //     >
    //       <label
    //         style={{ paddingLeft: 20, cursor: 'pointer' }}
    //         className="container-rb"
    //       >
    //         Normal
    //         <input
    //           type="radio"
    //           name="sku_type"
    //           value="normal"
    //           onChange={() => {
    //             controls.sku_type.value = 'normal';
    //             this.setState(
    //               {
    //                 controls,
    //               },
    //             );
    //           }}
    //           checked={sku_type.value === 'normal'}
    //         />
    //         <span className="checkmark-rb" />
    //       </label>
    //       <label
    //         style={{
    //           paddingLeft: 20,
    //           cursor: 'pointer',
    //           marginLeft: '15px',
    //         }}
    //         className="container-rb"
    //       >
    //         Premium
    //         <input
    //           type="radio"
    //           name="sku_type"
    //           value="premium"
    //           onChange={() => {
    //             controls.sku_type.value = 'premium';
    //             this.setState(
    //               {
    //                 controls,
    //               },
    //             );
    //           }}
    //           checked={sku_type.value === 'premium'}
    //         />
    //         <span className="checkmark-rb" />
    //       </label>
    //     </div>
    //   );
    // };

    return (
      <div id="user-update">
        <div className="page-title">
          {/* User Updates */}
          Admin Functionality
        </div>
        <br />
        <Card>
          <CardBody>
            {isLoader && <CustomSpinner />}
            {this.state.createCampaignSideBar && (
              <Sidebar
                sidebar={
                  <>
                    <div style={{ position: "relative", height: "100vh" }}>
                      <div
                        style={{ height: "60px" }}
                        className="custom-dropdown-header"
                      >
                        <span className="custom-dropdown-header-title cursor-pointer">
                          Create Email Campaign
                        </span>
                        <img
                          className="cursor-pointer"
                          src="/images/close.svg"
                          alt=""
                          onClick={this.toggleClose}
                        />
                      </div>
                      <div className="create_email_container">
                        <div>
                          <label className="create_email_campaign_label">
                            Campaign Name *
                          </label>
                          <Input
                            type="text"
                            autoComplete="nofill"
                            name="campaign_name"
                            id="campaign_name"
                            value={controls.campaign_name.value}
                            onChange={this.handleInputChange}
                            placeholder="Campaign Name"
                          />
                          {this.state.controls.campaign_name.showErrorMsg && (
                            <div className="error">
                              *Please enter campaign name
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <label className="create_email_campaign_label">
                            From Name *
                          </label>
                          <Input
                            type="text"
                            autoComplete="nofill"
                            name="from_name"
                            id="from_name"
                            value={controls.from_name.value}
                            onChange={this.handleInputChange}
                            placeholder="From Name"
                          />
                          {controls.from_name.showErrorMsg && (
                            <div className="error">*Please enter from name</div>
                          )}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <label className="create_email_campaign_label">
                            Subject *
                          </label>
                          <Input
                            type="text"
                            autoComplete="nofill"
                            name="subject"
                            id="subject"
                            value={controls.subject.value}
                            onChange={this.handleInputChange}
                            placeholder="Subject"
                          />
                          {controls.subject.showErrorMsg && (
                            <div className="error">*Please enter subject</div>
                          )}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <label className="create_email_campaign_label">
                            From Email *
                          </label>
                          <Input
                            type="email"
                            autoComplete="nofill"
                            name="from_email"
                            id="from_email"
                            value={controls.from_email.value}
                            onChange={this.handleInputChange}
                            placeholder="From Email"
                          />
                          {this.state.controls.from_email.showErrorMsg && (
                            <div className="error">
                              *Please enter from email
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <FormGroup>
                            <label className="custom-checkboax1--container">
                              <input
                                type="checkbox"
                                name="isPersonalized"
                                checked={isPersonalized}
                                onChange={this.setIsPersonalizedCheckboxValue}
                              />
                              <span className="checkmark1" />
                            </label>
                            &nbsp;&nbsp;Personalized
                            {this.infoIcon(
                              "By enabling personalize user-specific documents will be attached to your email"
                            )}
                          </FormGroup>
                        </div>

                        {isPersonalized && (
                          <div>
                            <FormGroup>
                              <Label for="state">Document Type</Label>
                              <select
                                className="form-control"
                                name="document_type"
                                onChange={this.handleInputChange}
                                value={controls.document_type.value}
                              >
                                <option value="" selected="true">
                                  Select Document Type
                                </option>
                                {documentTypes.map((dt) => (
                                  <option
                                    key={dt.document_type}
                                    value={dt.document_type}
                                  >
                                    {dt.document_type}
                                  </option>
                                ))}
                              </select>
                              {controls.document_type.showErrorMsg && (
                                <div className="error">
                                  *Please select Document Type.
                                </div>
                              )}
                            </FormGroup>
                          </div>
                        )}
                        {controls.document_type.value && (
                          <div style={{ marginTop: "15px" }}>
                            <label className="create_email_campaign_label">
                              Document Group Id *
                            </label>
                            {this.infoIcon(
                              "You will receive this id in email on completion of document generation process or in api response on triggering process of document generation"
                            )}
                            <Input
                              type="text"
                              autoComplete="nofill"
                              name="document_group_id"
                              id="document_group_id"
                              value={controls.document_group_id.value}
                              onChange={this.handleInputChange}
                              placeholder="Document Group Id"
                            />
                            {controls.document_group_id.showErrorMsg && (
                              <div className="error">
                                *Please enter document group id
                              </div>
                            )}
                          </div>
                        )}

                        <div className="d-flex">
                          <Col
                            xl="6"
                            xs="6"
                            md="6"
                            style={{ marginLeft: "-10px" }}
                          >
                            <div style={{ marginTop: "15px" }}>
                              <label
                                className="create_email_campaign_label"
                                style={{ marginBottom: "10px" }}
                              >
                                Sample File
                              </label>
                              <div
                                className="link-warm-blue cursor-pointer"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0px",
                                  marginLeft: "0px",
                                  textAlign: "left",
                                }}
                              >
                                <a
                                  href={
                                    "https://images.jktyrecrm.in/others/Email_Format.xlsx"
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                  style={{
                                    color: "#5665d8",
                                    "font-size": "12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="images/download.svg"
                                    alt=""
                                    className="download-report-icon cursor-pointer margin-left-10"
                                  />
                                  <label
                                    style={{ cursor: "pointer" }}
                                    className=""
                                  >
                                    Download Sample File
                                  </label>
                                </a>
                              </div>
                            </div>
                          </Col>

                          <Col
                            xl="6"
                            xs="6"
                            md="6"
                            style={{ marginLeft: "-10px" }}
                          >
                            <div style={{ marginTop: "15px" }}>
                              <label
                                className="create_email_campaign_label"
                                style={{ marginBottom: "10px" }}
                              >
                                Email Sent to *
                              </label>
                              <FormGroup
                                style={{
                                  marginBottom: "0px",
                                  marginLeft: "5px",
                                }}
                              >
                                <div className="d-flex">
                                  <img
                                    src="images/download.svg"
                                    alt=""
                                    className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                                  />
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      color: "#5665d8",
                                      "font-size": "12px",
                                      cursor: "pointer",
                                      wordBreak: "break-all",
                                    }}
                                    htmlFor="file_to"
                                  >
                                    {this.state.controls.selectedFile.value &&
                                    this.state.controls.selectedFile.value
                                      .name ? (
                                      <span>
                                        {
                                          this.state.controls.selectedFile.value
                                            .name
                                        }
                                      </span>
                                    ) : (
                                      <span className="hover-download">
                                        Upload Excel Or CSV
                                      </span>
                                    )}
                                  </label>
                                </div>
                                <input
                                  style={{ display: "none" }}
                                  id="file_to"
                                  type="file"
                                  accept=".xlsx"
                                  onChange={(e) =>
                                    this.handleInputChange({
                                      target: {
                                        name: "selectedFile",
                                        value: e.target.files[0],
                                      },
                                    })
                                  }
                                  ref={(ref) => (this.fileInput = ref)}
                                />
                                {this.state.controls.selectedFile
                                  .showErrorMsg && (
                                  <div className="error">
                                    * Please choose file.
                                  </div>
                                )}
                              </FormGroup>
                            </div>
                          </Col>
                        </div>
                        <div className="d-flex">
                          <Col
                            xl="6"
                            xs="6"
                            md="6"
                            style={{ marginLeft: "-10px" }}
                          >
                            <div style={{ marginTop: "18px" }}>
                              <label
                                className="create_email_campaign_label"
                                style={{ marginBottom: "10px" }}
                              >
                                Email HTML File *
                              </label>

                              <FormGroup
                                style={{
                                  marginBottom: "0px",
                                  marginLeft: "5px",
                                }}
                              >
                                <div className="d-flex">
                                  <img
                                    src="images/download.svg"
                                    alt=""
                                    className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                                  />
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      color: "#5665d8",
                                      "font-size": "12px",
                                      cursor: "pointer",
                                      wordBreak: "break-all",
                                    }}
                                    htmlFor="file_html"
                                  >
                                    {this.state.controls.selectedFileHtml
                                      .value &&
                                    this.state.controls.selectedFileHtml.value
                                      .name ? (
                                      <span>
                                        {
                                          this.state.controls.selectedFileHtml
                                            .value.name
                                        }
                                      </span>
                                    ) : (
                                      <span className="hover-download">
                                        Upload HTML
                                      </span>
                                    )}
                                  </label>
                                </div>
                                <input
                                  style={{ display: "none" }}
                                  id="file_html"
                                  type="file"
                                  accept=".html"
                                  onChange={(e) =>
                                    this.handleInputChange({
                                      target: {
                                        name: "selectedFileHtml",
                                        value: e.target.files[0],
                                      },
                                    })
                                  }
                                  ref={(ref) => (this.fileInput = ref)}
                                />
                                {controls.selectedFileHtml.showErrorMsg && (
                                  <div className="error">
                                    * Please choose file.
                                  </div>
                                )}
                              </FormGroup>
                            </div>
                          </Col>
                          {!isPersonalized && (
                            <Col
                              xl="6"
                              xs="6"
                              md="6"
                              style={{ marginLeft: "-10px" }}
                            >
                              <div style={{ marginTop: "18px" }}>
                                <label
                                  className="create_email_campaign_label"
                                  style={{ marginBottom: "10px" }}
                                >
                                  Email Attachement File
                                </label>
                                <FormGroup
                                  style={{
                                    marginBottom: "0px",
                                    marginLeft: "12px",
                                  }}
                                >
                                  <div className="d-flex">
                                    <img
                                      src="images/download.svg"
                                      alt=""
                                      className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                                    />
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        color: "#5665d8",
                                        "font-size": "12px",
                                        wordBreak: "break-all",
                                        cursor: "pointer",
                                      }}
                                      htmlFor="file_attachment"
                                    >
                                      {this.state.controls
                                        .selectedAttachmentFile.value &&
                                      this.state.controls.selectedAttachmentFile
                                        .value.name ? (
                                        <span>
                                          {
                                            this.state.controls
                                              .selectedAttachmentFile.value.name
                                          }
                                        </span>
                                      ) : (
                                        <span className="hover-download">
                                          Upload File
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                  <input
                                    style={{ display: "none" }}
                                    id="file_attachment"
                                    type="file"
                                    // accept=".html"
                                    onChange={(e) =>
                                      this.handleInputChange({
                                        target: {
                                          name: "selectedAttachmentFile",
                                          value: e.target.files[0],
                                        },
                                      })
                                    }
                                    ref={(ref) => (this.fileInput = ref)}
                                  />
                                  {controls.selectedAttachmentFile
                                    .showErrorMsg && (
                                    <div className="error">
                                      * Please choose file.
                                    </div>
                                  )}
                                </FormGroup>
                              </div>
                            </Col>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: "0",
                          marginRight: "0",
                          maxHeight: "75px",
                          position: "absolute",
                          bottom: "60px",
                          width: "100%",
                        }}
                      >
                        <Row
                          style={{ marginLeft: "0", marginRight: "0" }}
                          className="dealer_filter_button_container"
                        >
                          <Col
                            xl="12"
                            xs="12"
                            md="12"
                            className="text-align-right footer-drawer"
                          >
                            <Button
                              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                              color="dark"
                              onClick={this.toggleClose}
                              style={{ width: "116px" }}
                            >
                              Cancel
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                              color="warning"
                              onClick={this.sendMail}
                              style={{ width: "116px" }}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                }
                open
                sidebarClassName="campaign-drawer"
                pullRight
                styles={{
                  sidebar: {
                    background: "white",
                    height: "90%",
                    top: "60px",
                    width: "37%",
                    borderRadius: "2px",
                  },
                }}
              />
            )}

            {/* Whatsapp create campaign sidebar */}
            {this.state.whatsappCampaignSideBar && (
              <Sidebar
                sidebar={
                  <>
                    <div style={{ position: "relative", height: "100vh" }}>
                      <div
                        style={{ height: "60px" }}
                        className="custom-dropdown-header"
                      >
                        <span className="custom-dropdown-header-title cursor-pointer">
                          Create Whatsapp Campaign
                        </span>
                        <img
                          className="cursor-pointer"
                          src="/images/close.svg"
                          alt=""
                          onClick={this.toggleWhatsappCampaignClose}
                        />
                      </div>
                      <div className="create_email_container">
                        <div>
                          <FormGroup>
                            <div className="d-flex justify-content-between">
                              <Label for="wtemplate_name">
                                Select Template *
                              </Label>
                              <span
                                className="link-blue cursor-pointer font-size-14px font-weight-bold"
                                onClick={() =>
                                  this.setState(
                                    {
                                      whatsappSwitch: "whatsapp_templates",
                                      whatsappCampaignSideBar: false,
                                    },
                                    () => {
                                      setTimeout(() => {
                                        this.childWhatsappTemplates.current.openAddWhatsappTemplatesSideBar(
                                          true,
                                          "whatsapp_campaign"
                                        );
                                      }, 100);
                                    }
                                  )
                                }
                              >
                                Create Template
                              </span>
                            </div>
                            {/* <select
                              className="form-control"
                              name="wtemplate_name"
                              onChange={(e) =>
                                this.handleInputWhatsAppChange(
                                  "wtemplate_name",
                                  e.target.value
                                )
                              }
                              value={wtControls.wtemplate_name.value || ""}
                              style={{ fontSize: "14px" }}
                            >
                              <option value="">Select</option>
                              {whatsappTemplates.map((dt) => (
                                <option
                                  key={dt.templateName}
                                  value={dt.templateName}
                                >
                                  {dt.templateName}
                                </option>
                              ))}
                            </select> */}
                            <Typeahead
                              id="whatsappTemplates-basic-typeahead-single"
                              labelKey="templateName"
                              onChange={(e) => {
                                this.handleInputWhatsAppChange(
                                  "wtemplate_name",
                                  e && e[0] && e[0].templateName
                                );
                              }}
                              options={whatsappTemplates}
                              placeholder="Choose a template name..."
                              selected={
                                wtControls.wtemplate_name.value
                                  ? [
                                      {
                                        templateName:
                                          wtControls.wtemplate_name.value,
                                      },
                                    ]
                                  : []
                              }
                            />
                            {wtControls.wtemplate_name.showErrorMsg && (
                              <div className="error">
                                *Please select Template.
                              </div>
                            )}
                          </FormGroup>
                        </div>
                        {wtControls.wtemplate_name.value && (
                          <div>
                            <label className="wtemplate_msg">
                              Templates Message *
                            </label>
                            <Input
                              type="textarea"
                              style={{ width: "100%", fontSize: "14px" }}
                              autoComplete="nofill"
                              name="wtemplate_msg"
                              id="wtemplate_msg"
                              value={wtControls.wtemplate_msg.value || ""}
                              onChange={(e) =>
                                this.handleInputWhatsAppChange(
                                  "wtemplate_msg",
                                  e.target.value
                                )
                              }
                              placeholder="Enter"
                              rows={templateMessageRows || 9}
                            />
                            <div className="grayNote mt-2 mb-3">
                              Note: Do not change message text in approved
                              template. Only replace the variable parameter
                              value.
                            </div>
                            {wtControls.wtemplate_msg.showErrorMsg && (
                              <div className="error">
                                *Please enter Templates Message.
                              </div>
                            )}
                          </div>
                        )}
                        <div>
                          <FormGroup>
                            <label className="custom-checkboax1--container">
                              <input
                                type="checkbox"
                                name="wis_personalized"
                                checked={wtControls.wis_personalized.value}
                                onChange={(e) =>
                                  this.handleInputWhatsAppChange(
                                    "wis_personalized",
                                    e.target.checked
                                  )
                                }
                              />
                              <span className="checkmark1" />
                            </label>
                            &nbsp;&nbsp;Personalized
                            {this.infoIcon(
                              "By enabling personalize user-specific documents will be attached to your whatsapp"
                            )}
                          </FormGroup>
                        </div>
                        {(wtControls.wis_personalized.value === true ||
                          wtControls.wis_personalized.value === "true") && (
                          <div>
                            <FormGroup>
                              <Label for="state">Document Type</Label>
                              <select
                                className="form-control"
                                name="wdocument_type"
                                onChange={(e) =>
                                  this.handleInputWhatsAppChange(
                                    "wdocument_type",
                                    e.target.value
                                  )
                                }
                                value={wtControls.wdocument_type.value || ""}
                                style={{ fontSize: "14px" }}
                              >
                                <option value="">Select Document Type</option>
                                {documentTypes.map((dt) => (
                                  <option
                                    key={dt.document_type}
                                    value={dt.document_type}
                                  >
                                    {dt.document_type}
                                  </option>
                                ))}
                              </select>
                              {wtControls.wdocument_type.showErrorMsg && (
                                <div className="error">
                                  *Please select Document Type.
                                </div>
                              )}
                            </FormGroup>
                          </div>
                        )}
                        {(wtControls.wis_personalized.value === true ||
                          wtControls.wis_personalized.value === "true") &&
                          wtControls.wdocument_type.value && (
                            <div style={{ marginTop: "15px" }}>
                              <label className="create_email_campaign_label">
                                Document Group Id *
                              </label>
                              {this.infoIcon(
                                "You will receive this id in email on completion of document generation process or in api response on triggering process of document generation",
                                "wdocument_group_id_info"
                              )}
                              <Input
                                type="text"
                                autoComplete="nofill"
                                name="wdocument_group_id"
                                id="wdocument_group_id"
                                value={wtControls.wdocument_group_id.value}
                                onChange={(e) =>
                                  this.handleInputWhatsAppChange(
                                    "wdocument_group_id",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter"
                                style={{ fontSize: "14px" }}
                              />
                              {wtControls.wdocument_group_id.showErrorMsg && (
                                <div className="error">
                                  *Please enter document group id.
                                </div>
                              )}
                            </div>
                          )}
                        {wtControls.wtemplate_name.value &&
                          wtControls.wtemplate.value &&
                          wtControls.wtemplate.value.templateName &&
                          wtControls.wtemplate.value.msgType !== "text" &&
                          wtControls.wtemplate.value.template &&
                          wtControls.wtemplate.value.template.header &&
                          (wtControls.wis_personalized.value === false ||
                            wtControls.wis_personalized.value === "false") && (
                            <>
                              <div
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "15px",
                                }}
                              >
                                {wtControls.wtemplate.value.template &&
                                  wtControls.wtemplate.value.template.header.toLowerCase() ===
                                    "video" && (
                                    <label>Media URL (Video URL)</label>
                                  )}
                                {wtControls.wtemplate.value.template &&
                                  wtControls.wtemplate.value.template.header.toLowerCase() ===
                                    "image" && (
                                    <label>Media URL (Image URL)</label>
                                  )}
                                {wtControls.wtemplate.value.template &&
                                  wtControls.wtemplate.value.template.header.toLowerCase() ===
                                    "document" && (
                                    <label>Media URL (Document URL)</label>
                                  )}
                                <Input
                                  type="text"
                                  autoComplete="nofill"
                                  name="wtemplate_media_value"
                                  id="wtemplate_media_value"
                                  value={
                                    wtControls.wtemplate_media_value.value || ""
                                  }
                                  onChange={(e) =>
                                    this.handleInputWhatsAppChange(
                                      "wtemplate_media_value",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter"
                                  style={{ fontSize: "14px" }}
                                />
                                {/* {wtControls.wtemplate_media_value.showErrorMsg && (
                              <div className="error">*Please enter Media Id.</div>
                            )} */}
                              </div>
                              <div
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "15px",
                                }}
                              >
                                <div className="d-flex">
                                  {" "}
                                  <label>File Name</label>
                                  {this.infoIcon(
                                    "Add file name which will be display in message",
                                    "whatsapp_file"
                                  )}
                                </div>
                                <Input
                                  type="text"
                                  autoComplete="nofill"
                                  name="wtemplate_media_filename_value"
                                  id="wtemplate_media_filename_value"
                                  value={
                                    wtControls.wtemplate_media_filename_value
                                      .value || ""
                                  }
                                  onChange={(e) =>
                                    this.handleInputWhatsAppChange(
                                      "wtemplate_media_filename_value",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter file name"
                                  style={{ fontSize: "14px" }}
                                />
                              </div>
                            </>
                          )}
                        <label
                          className="create_email_campaign_label"
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                        >
                          WhatsApp Numbers *
                        </label>
                        <div style={{ display: "flex" }}>
                          <FormGroup
                            style={{
                              marginBottom: "0px",
                              marginLeft: "12px",
                            }}
                          >
                            <img
                              src="images/download.svg"
                              alt=""
                              className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                            />
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#5665d8",
                                "font-size": "12px",
                                cursor: "pointer",
                                wordBreak: "break-all",
                              }}
                              htmlFor="whatsapp_no_file"
                            >
                              {fileExcelOrCSV}
                            </label>
                            <input
                              style={{ display: "none" }}
                              id="whatsapp_no_file"
                              name="whatsapp_no_file"
                              type="file"
                              accept=".csv,.xlsx"
                              onChange={(e) =>
                                this.handleInputWhatsAppChange(
                                  "whatsapp_no_file",
                                  e.target.files[0]
                                )
                              }
                              ref={(ref) => (this.fileInputWhatsapp = ref)}
                            />
                            {wtControls.whatsapp_no_file.showErrorMsg && (
                              <div className="error">* Please choose file.</div>
                            )}
                          </FormGroup>
                          <div
                            className="link-warm-blue cursor-pointer"
                            style={{
                              marginLeft: "33px",
                              cursor: "pointer",
                              marginTop: "4px",
                            }}
                          >
                            <a
                              href={whatsappSampleFile}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                              style={{
                                color: "#5665d8",
                                "font-size": "12px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src="images/download.svg"
                                alt=""
                                className="download-report-icon cursor-pointer margin-left-10"
                              />
                              <label style={{ cursor: "pointer" }} className="">
                                Download Sample File
                              </label>
                            </a>
                          </div>
                        </div>
                        {/* <div style={{ marginTop: "18px" }}>
                          <label
                            className="create_email_campaign_label"
                            style={{ marginBottom: "10px" }}
                          >
                            WhatsApp Numbers * (Select Excel or CSV file)
                          </label>
                          <Input
                            id="whatsapp_no_file"
                            name="whatsapp_no_file"
                            type="file"
                            accept=".csv,.xlsx"
                            onChange={(e) =>
                              this.handleInputWhatsAppChange(
                                "whatsapp_no_file",
                                e.target.files[0]
                              )
                            }
                          />
                          {wtControls.whatsapp_no_file.showErrorMsg && (
                            <div className="error">* Please choose file.</div>
                          )}
                        </div>
                        <div
                          className="link-warm-blue cursor-pointer"
                          style={{
                            cursor: "pointer",
                            marginTop: "10px",
                            textAlign: "left",
                          }}
                        >
                          <a
                            href={whatsappSampleFile}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            style={{
                              color: "#5665d8",
                              "font-size": "12px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="images/download.svg"
                              alt=""
                              className="download-report-icon cursor-pointer margin-left-10"
                            />
                            <label style={{ cursor: "pointer" }} className="">
                              Download Sample File
                            </label>
                          </a>
                        </div> */}
                      </div>
                      <div
                        style={{
                          marginLeft: "0",
                          marginRight: "0",
                          maxHeight: "75px",
                          position: "absolute",
                          bottom: "60px",
                          width: "100%",
                        }}
                      >
                        <Row
                          style={{ marginLeft: "0", marginRight: "0" }}
                          className="dealer_filter_button_container"
                        >
                          <Col
                            xl="12"
                            xs="12"
                            md="12"
                            className="text-align-right footer-drawer"
                          >
                            <Button
                              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                              color="dark"
                              onClick={this.toggleWhatsappCampaignClose}
                              style={{ width: "116px" }}
                            >
                              Cancel
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                              color="warning"
                              onClick={this.sendWhatsappMsg}
                              style={{ width: "116px" }}
                            >
                              Create
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                }
                open
                sidebarClassName="campaign-drawer"
                pullRight
                styles={{
                  sidebar: {
                    background: "white",
                    height: "90%",
                    top: "60px",
                    width: "37%",
                    borderRadius: "2px",
                  },
                }}
              />
            )}

            {this.state.addSkuSideBar && (
              <Sidebar
                sidebar={
                  <>
                    <div style={{ position: "relative", height: "100vh" }}>
                      <div
                        style={{ height: "60px" }}
                        className="custom-dropdown-header"
                      >
                        <span className="custom-dropdown-header-title cursor-pointer">
                          Create PCR SKU
                        </span>
                        <img
                          className="cursor-pointer"
                          src="/images/close.svg"
                          alt=""
                          onClick={this.toggleAddSkuClose}
                        />
                      </div>
                      <div className="create_email_container">
                        <div>
                          <label className="create_email_campaign_label">
                            SKU ID <span class="compulsory">*</span>
                          </label>
                          <Input
                            type="text"
                            autoComplete="nofill"
                            name="sku_id"
                            id="sku_id"
                            value={controls.sku_id.value}
                            onChange={this.handleInputChange}
                            placeholder="SKU ID"
                          />
                          {this.state.controls.sku_id.showErrorMsg && (
                            <div className="error">*Please enter SKU ID</div>
                          )}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <label className="create_email_campaign_label">
                            Description <span class="compulsory">*</span>
                          </label>
                          <Input
                            type="text"
                            autoComplete="nofill"
                            name="sku_description"
                            id="sku_description"
                            value={controls.sku_description.value}
                            onChange={this.handleInputChange}
                            placeholder="From Name"
                          />
                          {this.state.controls.sku_description.showErrorMsg && (
                            <div className="error">
                              *Please enter description
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <label className="create_email_campaign_label">
                            SKU Group <span class="compulsory">*</span>
                          </label>
                          <Input
                            type="text"
                            autoComplete="nofill"
                            name="sku_group"
                            id="sku_group"
                            value={controls.sku_group.value}
                            onChange={this.handleInputChange}
                            placeholder="Subject"
                          />
                          {this.state.controls.sku_group.showErrorMsg && (
                            <div className="error">*Please enter SKU group</div>
                          )}
                        </div>
                        <div className="" style={{ marginTop: "10px" }}>
                          {/* {renderRadioSkuType()} */}
                          <span style={{ marginTop: "10px" }}>SKU Type</span>
                          <div
                            style={{
                              marginTop: "10px",
                              marginLeft: "10px",
                              marginBottom: 15,
                            }}
                          >
                            <label
                              style={{ paddingLeft: 20, cursor: "pointer" }}
                              className="container-rb"
                            >
                              Normal
                              <input
                                type="radio"
                                name="sku_type"
                                value="normal"
                                onChange={() => {
                                  controls.sku_type.value = "normal";
                                  this.setState({
                                    controls,
                                  });
                                }}
                                checked={sku_type.value === "normal"}
                              />
                              <span className="checkmark-rb" />
                            </label>
                            <label
                              style={{
                                paddingLeft: 20,
                                cursor: "pointer",
                                marginLeft: "15px",
                              }}
                              className="container-rb"
                            >
                              Premium
                              <input
                                type="radio"
                                name="sku_type"
                                value="premium"
                                onChange={() => {
                                  controls.sku_type.value = "premium";
                                  this.setState({
                                    controls,
                                  });
                                }}
                                checked={sku_type.value === "premium"}
                              />
                              <span className="checkmark-rb" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: "0",
                          marginRight: "0",
                          maxHeight: "75px",
                          position: "absolute",
                          bottom: "60px",
                          width: "100%",
                        }}
                      >
                        <Row
                          style={{ marginLeft: "0", marginRight: "0" }}
                          className="dealer_filter_button_container"
                        >
                          <Col
                            xl="12"
                            xs="12"
                            md="12"
                            className="text-align-right footer-drawer"
                          >
                            <Button
                              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                              color="dark"
                              onClick={this.toggleAddSkuClose}
                              style={{ width: "116px" }}
                            >
                              Cancel
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                              color="warning"
                              onClick={this.saveSkuData}
                              style={{ width: "116px" }}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                }
                open
                sidebarClassName="campaign-drawer"
                pullRight
                styles={{
                  sidebar: {
                    background: "white",
                    height: "90%",
                    top: "60px",
                    width: "37%",
                    borderRadius: "2px",
                  },
                }}
              />
            )}

            <Row>
              <Col>
                <div className="switch-container adminSwitchContainer mb-0 mt-1">
                  <div
                    className={`switch-data ${
                      adminSwitch === "advantage_switch" ? "active-switch" : ""
                    }`}
                    onClick={this.handleAdminSwitchChange.bind(
                      this,
                      "advantage_switch"
                    )}
                  >
                    Advantage
                  </div>
                  <div
                    className={`switch-data ${
                      adminSwitch === "baadshah_switch" ? "active-switch" : ""
                    }`}
                    onClick={this.handleAdminSwitchChange.bind(
                      this,
                      "baadshah_switch"
                    )}
                  >
                    Baadshah
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="margin-bottom-10 margin-top-10">
              {filterTypes.map((b, index) => {
                return (
                  <>
                    <div className="margin-left-20" style={{ marginTop: 15 }}>
                      <label
                        style={{ paddingLeft: 20 }}
                        className="container-rb"
                      >
                        {b.key}
                        <input
                          type="radio"
                          name="typeRadio"
                          value={b.value}
                          onChange={this.handleRadioChange.bind(
                            this,
                            "typeRadio"
                          )}
                          checked={typeRadio.value === b.value}
                        />
                        <span className="checkmark-rb" />
                      </label>
                    </div>
                  </>
                );
              })}
            </Row>

            <Row>
              {typeRadio.value === "change_password" && (
                <>
                  <Col
                    className="label-verify"
                    xl="12"
                    md="12"
                    sm="12"
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    <Label className="label-select">
                      Login ID&nbsp;<span className="required-field">*</span>
                    </Label>
                  </Col>

                  <Col xl="3" md="4" sm="12">
                    <FormGroup>
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="login_id"
                        id="login_id"
                        value={controls.login_id.value}
                        onChange={this.handleInputChange}
                        placeholder="Type here"
                      />
                      {controls.login_id.showErrorMsg && (
                        <div className="error">* Please enter id.</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{ width: "40%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.updatePasword}
                    >
                      Update
                    </Button>
                  </Col>
                </>
              )}

              {typeRadio.value === "map_sap" && (
                <>
                  <Col
                    className="label-verify"
                    xl="3"
                    md="3"
                    sm="12"
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    <Label className="label-select">
                      Member ID&nbsp;<span className="required-field">*</span>
                    </Label>
                  </Col>

                  <Col
                    className="label-verify"
                    xl="3"
                    md="3"
                    sm="12"
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    <Label className="label-select">
                      Primary SAP ID&nbsp;
                      <span className="required-field">*</span>
                    </Label>
                  </Col>

                  <Col xl="6" md="6" sm="12" />

                  <Col xl="3" md="3" sm="12">
                    <FormGroup>
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="member_id"
                        id="member_id"
                        value={controls.member_id.value}
                        onChange={this.handleInputChange}
                        placeholder="Type here"
                      />
                      {controls.member_id.showErrorMsg && (
                        <div className="error">* Please enter member id.</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xl="3" md="3" sm="12">
                    <FormGroup>
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="sap_id"
                        id="sap_id"
                        value={controls.sap_id.value}
                        onChange={this.handleInputChange}
                        placeholder="Type here"
                      />
                      {controls.sap_id.showErrorMsg && (
                        <div className="error">* Please enter sap id.</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{ width: "40%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.mapSap}
                    >
                      Submit
                    </Button>
                  </Col>
                </>
              )}

              {typeRadio.value === "update_status" && (
                <>
                  <Col xl="3" md="4" sm="12" style={{ marginTop: "10px" }}>
                    <FormGroup style={{ marginBottom: "0px" }}>
                      <img
                        style={{ marginLeft: "4px" }}
                        src="images/download.svg"
                        alt=""
                        className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                      />
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#5665d8",
                          "font-size": "12px",
                        }}
                        htmlFor="file"
                      >
                        {file}
                      </label>
                      <input
                        style={{ display: "none" }}
                        id="file"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "selectedFile",
                              value: e.target.files[0],
                            },
                          })
                        }
                        className="width-100"
                        ref={(ref) => (this.fileInput = ref)}
                      />
                      {controls.selectedFile.showErrorMsg && (
                        <div className="error">* Please choose file.</div>
                      )}
                    </FormGroup>
                    <span className="link-warm-blue cursor-pointer">
                      <a
                        href="https://images.jktyrecrm.in/others/status_update_example.xlsx"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                          color: "#5665d8",
                          "font-size": "12px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          style={{ marginLeft: "4px" }}
                          src="images/download.svg"
                          alt=""
                          className="download-report-icon cursor-pointer margin-left-10"
                        />
                        <label className="" style={{ cursor: "pointer" }}>
                          Download Sample File
                        </label>
                      </a>
                    </span>
                  </Col>

                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{ width: "40%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.statusUpdate}
                    >
                      Update
                    </Button>
                  </Col>
                </>
              )}

              {typeRadio.value === "update_class" && (
                <>
                  <Col xl="3" md="4" sm="12" style={{ marginTop: "10px" }}>
                    <FormGroup style={{ marginBottom: "0px" }}>
                      <img
                        style={{ marginLeft: "4px" }}
                        src="images/download.svg"
                        alt=""
                        className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                      />
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#5665d8",
                          "font-size": "12px",
                        }}
                        htmlFor="file"
                      >
                        {file}
                      </label>
                      <input
                        style={{ display: "none" }}
                        id="file"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "selectedFile",
                              value: e.target.files[0],
                            },
                          })
                        }
                        className="width-100"
                        ref={(ref) => (this.fileInput = ref)}
                      />
                      {controls.selectedFile.showErrorMsg && (
                        <div className="error">* Please choose file.</div>
                      )}
                    </FormGroup>
                    <span className="link-warm-blue cursor-pointer">
                      <a
                        href="https://images.jktyrecrm.in/others/class_update_example.xlsx"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{ color: "#5665d8", "font-size": "12px" }}
                      >
                        <img
                          style={{ marginLeft: "4px" }}
                          src="images/download.svg"
                          alt=""
                          className="download-report-icon cursor-pointer margin-left-10"
                        />
                        <label className="">Download Sample File</label>
                      </a>
                    </span>
                  </Col>

                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{ width: "40%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.classUpdate}
                    >
                      Update
                    </Button>
                  </Col>
                </>
              )}
              {typeRadio.value === "dealers_details" && (
                <Col xl="4" md="4" sm="12">
                  <img
                    src="images/download.svg"
                    alt=""
                    className="download-report-icon cursor-pointer"
                    style={{ marginLeft: "4px" }}
                  />
                  <span
                    className="hover-download"
                    onClick={() => this.getDealerDetails()}
                    style={{
                      color: "rgb(86, 101, 216)",
                      fontSize: "12px",
                      fontFamily: "Helvetica",
                      cursor: "pointer",
                      fontWeight: "bold",
                      marginLeft: "2%",
                    }}
                  >
                    Download Dealer Master
                  </span>
                </Col>
              )}
              {typeRadio.value === "fleet_details" && (
                <Col xl="4" md="4" sm="12">
                  <img
                    src="images/download.svg"
                    alt=""
                    className="download-report-icon cursor-pointer"
                    style={{ marginLeft: "4px" }}
                  />
                  <span
                    className="hover-download"
                    onClick={() => this.getFleetMasterData()}
                    style={{
                      color: "rgb(86, 101, 216)",
                      fontSize: "12px",
                      fontFamily: "Helvetica",
                      cursor: "pointer",
                      fontWeight: "bold",
                      marginLeft: "2%",
                    }}
                  >
                    Download Fleet Master Report
                  </span>
                </Col>
              )}
              {typeRadio.value === "download_city_state" && (
                <Col xl="4" md="4" sm="12">
                  <img
                    src="images/download.svg"
                    alt=""
                    className="download-report-icon cursor-pointer"
                    style={{ marginLeft: "4px" }}
                  />
                  <span
                    className="hover-download"
                    onClick={() => this.downloadStateCityDistrictMaster()}
                    style={{
                      color: "rgb(86, 101, 216)",
                      fontSize: "12px",
                      fontFamily: "Helvetica",
                      cursor: "pointer",
                      fontWeight: "bold",
                      marginLeft: "2%",
                    }}
                  >
                    Download State, City, District, Territory Master
                  </span>
                </Col>
              )}
              {typeRadio.value === "add_new_city" && (
                <Row>
                  <Col>
                    <div className="switch-container">
                      <div
                        className={`switch-data ${
                          cityOrDistrictSwitch === "city" ? "active-switch" : ""
                        }`}
                        onClick={this.handlecityOrDistrictSwitchChange.bind(
                          this,
                          "city"
                        )}
                      >
                        Add City
                      </div>
                      <div
                        className={`switch-data ${
                          cityOrDistrictSwitch === "district"
                            ? "active-switch"
                            : ""
                        }`}
                        onClick={this.handlecityOrDistrictSwitchChange.bind(
                          this,
                          "district"
                        )}
                      >
                        Add District
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {cityOrDistrictSwitch === "city" && (
                <>
                  <Row style={{ marginLeft: "-193px", marginTop: "60px" }}>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>
                          State<span className="required-field">*</span>
                        </Label>
                        <div className="d-flex">
                          <select
                            style={{ cursor: "pointer" }}
                            className="form-control select-height-70rem font-size-14px"
                            name="stateValue"
                            value={controls.stateValue.value}
                            onChange={this.handleInputChange}
                          >
                            <option value="" selected disabled>
                              Select
                            </option>
                            {this.state.stateData &&
                              this.state.stateData.map((item, i) => (
                                <option value={item.state_id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {controls.stateValue.showErrorMsg && (
                          <div className="error">* Please select state.</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="3" md="12" xs="12">
                      {addDistict ? (
                        <FormGroup>
                          <Label>
                            District Name
                            <span className="required-field">*</span>
                          </Label>
                          <div className="d-flex">
                            <Input
                              type="text"
                              name="districtValue"
                              value={controls.districtValue.value}
                              maxLength="50"
                              onChange={this.handleInputChange}
                              placeholder="Type Here"
                              className="city-input"
                            />
                          </div>
                          {controls.districtValue.showErrorMsg && (
                            <div className="error">
                              {controls.districtValue.districtExistsError
                                ? "District name already exist."
                                : "* Please enter District."}
                            </div>
                          )}
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Label>
                            District<span className="required-field">*</span>
                          </Label>
                          <div className="d-flex">
                            <select
                              className="form-control select-height-70rem font-size-14px"
                              name="districtValue"
                              value={controls.districtValue.value}
                              onChange={this.handleInputChange}
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              {this.state.districtData &&
                                this.state.districtData.map((item, i) => (
                                  <option value={item.name}>{item.name}</option>
                                ))}
                            </select>
                          </div>
                          {controls.districtValue.showErrorMsg && (
                            <div className="error">
                              * Please select district.
                            </div>
                          )}
                        </FormGroup>
                      )}
                      {!addDistict && (
                        <div>
                          <label
                            className=""
                            style={{ cursor: "pointer", color: "#5665D8" }}
                            onClick={() => {
                              this.setState({ addDistict: true });
                            }}
                          >
                            Add District
                          </label>
                        </div>
                      )}
                    </Col>
                    <Col xl="3" md="12" xs="12">
                      <FormGroup>
                        <Label>
                          City Name<span className="required-field">*</span>
                        </Label>
                        <div className="d-flex">
                          <Input
                            type="text"
                            name="cityValue"
                            value={controls.cityValue.value}
                            maxLength="50"
                            onChange={this.handleInputChange}
                            placeholder="Type Here"
                            className="city-input"
                          />
                        </div>
                        {controls.cityValue.showErrorMsg && (
                          <div className="error">
                            {controls.cityValue.cityExistsError
                              ? "City name already exist."
                              : "* Please enter City."}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="4" sm="12">
                      <Button
                        style={addCityStyle}
                        className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                        color="warning"
                        onClick={this.addCity}
                      >
                        Add City
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              {cityOrDistrictSwitch === "district" && (
                <>
                  <Row style={{ marginLeft: "-193px", marginTop: "60px" }}>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label>
                          State<span className="required-field">*</span>
                        </Label>
                        <div className="d-flex">
                          <select
                            style={{ cursor: "pointer" }}
                            className="form-control select-height-70rem font-size-14px"
                            name="stateValue"
                            value={controls.stateValue.value}
                            onChange={this.handleInputChange}
                          >
                            <option value="" selected disabled>
                              Select
                            </option>
                            {this.state.stateData &&
                              this.state.stateData.map((item, i) => (
                                <option value={item.state_id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {controls.stateValue.showErrorMsg && (
                          <div className="error">* Please select state.</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label>
                          District Name<span className="required-field">*</span>
                        </Label>
                        <div className="d-flex">
                          <Input
                            type="text"
                            name="districtValue"
                            value={controls.districtValue.value}
                            maxLength="50"
                            onChange={this.handleInputChange}
                            placeholder="Type Here"
                            className="city-input"
                          />
                        </div>
                        {controls.districtValue.showErrorMsg && (
                          <div className="error">
                            {controls.districtValue.districtExistsError
                              ? "District name already exist."
                              : "* Please enter District."}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="4" sm="12">
                      <Button
                        style={{ width: "70%", marginTop: "16%" }}
                        className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                        color="warning"
                        onClick={() => this.addCity()}
                      >
                        Add District
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              {/* {(typeRadio.value === 'point_report' || typeRadio.value === 'view_dealer_profile') && (
                <>
                  <Col md="3" xs="12">
                    <div>
                      <Typeahead
                        id="uuid"
                        filterBy={['business_name', 'sap_id']}
                        options={suggestions}
                        labelKey={(option) =>
                          `${option.business_name} (${option.sap_id})`
                        }
                      
                        onChange={(e) => {
                          this.handleInputChange({
                            target: { name: 'uuid', value: e && e[0] && e[0] },
                          });
                        }}
                        name="sap_id"
                        placeholder="Search by dealer name or SAP code"
                        ref={ref}
                        renderMenuItemChildren={(selectedItem) => {
                          return (
                            <>
                              {selectedItem.business_name} (
                              {selectedItem.sap_id})
                            </>
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{ width: typeRadio.value === 'view_dealer_profile' ? '45%' : '40%' }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={() => this.selectedSap(typeRadio.value)}
                    >
                      {typeRadio.value === 'view_dealer_profile' ? 'View Dealer Profile' : 'View Report'}
                    </Button>
                  </Col>
                </>
              )} */}

              {typeRadio.value === "point_report" && (
                <>
                  <Col md="3" xs="12">
                    <div>
                      <Typeahead
                        id="uuid"
                        filterBy={["business_name", "sap_id"]}
                        options={suggestions}
                        labelKey={(option) =>
                          `${option.business_name} (${option.sap_id})`
                        }
                        onChange={(e) => {
                          this.handleInputChange({
                            target: { name: "uuid", value: e && e[0] && e[0] },
                          });
                        }}
                        name="sap_id"
                        placeholder="Search by dealer name or SAP code"
                        ref={ref}
                        renderMenuItemChildren={(selectedItem) => {
                          return (
                            <>
                              {selectedItem.business_name} (
                              {selectedItem.sap_id})
                            </>
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{ width: "40%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={() => this.selectedSap(typeRadio.value)}
                    >
                      View Report
                    </Button>
                  </Col>
                </>
              )}

              {typeRadio.value === "view_dealer_profile" && (
                <>
                  <Col md="3" xs="12">
                    <div>
                      <Typeahead
                        id="uuid"
                        filterBy={["business_name", "sap_id"]}
                        options={suggestions}
                        labelKey={(option) =>
                          `${option.business_name} (${option.sap_id})`
                        }
                        onChange={(e) => {
                          this.handleInputChange({
                            target: { name: "uuid", value: e && e[0] && e[0] },
                          });
                        }}
                        name="sap_id"
                        placeholder="Search by dealer name or SAP code"
                        ref={ref}
                        renderMenuItemChildren={(selectedItem) => {
                          return (
                            <>
                              {selectedItem.business_name} (
                              {selectedItem.sap_id})
                            </>
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{ width: "45%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={() => this.viewDealerProfile(typeRadio.value)}
                    >
                      View Dealer Profile
                    </Button>
                  </Col>
                </>
              )}

              {typeRadio.value === "email_campaign" && (
                <EmailCampaignTable
                  openSideBarCreateCampaign={this.openSideBarCreateCampaign}
                />
              )}

              {/* {typeRadio.value === "whatsapp_campaign" && (
                <WhatsappCampaignTable
                  openSideBarCreateCampaign={
                    this.openWhatsappSideBarCreateCampaign
                  }
                  ref={this.childWhatsappTableRef}
                />
              )} */}

              {/* <div
                style={{
                  display:
                    typeRadio.value === "whatsapp_campaign" ? "block" : "none",
                }}
              >
                <WhatsappCampaignTable
                  openSideBarCreateCampaign={
                    this.openWhatsappSideBarCreateCampaign
                  }
                  ref={this.childWhatsappTableRef}
                />
              </div> */}
              {typeRadio.value === "whatsapp_campaign" && (
                <>
                  <div style={{ padding: "15px" }}>
                    <Row>
                      <Col>
                        <div className="switch-container adminSwitchContainer mb-0 mt-1">
                          <div
                            className={`switch-data ${
                              whatsappSwitch === "whatsapp_campaign"
                                ? "active-switch"
                                : ""
                            }`}
                            onClick={this.handleWhatsappwitchChange.bind(
                              this,
                              "whatsapp_campaign"
                            )}
                          >
                            Whatsapp Campaign
                          </div>
                          <div
                            className={`switch-data ${
                              whatsappSwitch === "whatsapp_templates"
                                ? "active-switch"
                                : ""
                            }`}
                            onClick={this.handleWhatsappwitchChange.bind(
                              this,
                              "whatsapp_templates"
                            )}
                          >
                            Whatsapp Templates
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {whatsappSwitch === "whatsapp_campaign" && (
                    <WhatsappCampaignTable
                      openSideBarCreateCampaign={
                        this.openWhatsappSideBarCreateCampaign
                      }
                      ref={this.childWhatsappTableRef}
                    />
                  )}
                  {whatsappSwitch === "whatsapp_templates" && (
                    <Col xl="12" md="12" sm="12" style={{ marginTop: "10px" }}>
                      <WhatsappTemplates
                        ref={this.childWhatsappTemplates}
                        openWhatsappSideBarCreateCampaignFromTemplate={
                          this.openWhatsappSideBarCreateCampaignFromTemplate
                        }
                      />
                    </Col>
                  )}
                </>
              )}

              {/* {typeRadio.value === "whatsapp_templates" && (
                <Col xl="12" md="12" sm="12" style={{ marginTop: "10px" }}>
                  <WhatsappTemplates />
                </Col>
              )} */}

              {typeRadio.value === "manage_sku" && (
                <SkuListTable openSideBarCreateSku={this.openSideBarAddSku} />
              )}

              {typeRadio.value === "upload_redemption_points" && (
                <>
                  <Col xl="3" md="4" sm="12" style={{ marginTop: "10px" }}>
                    <FormGroup style={{ marginBottom: "0px" }}>
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#5665d8",
                          "font-size": "12px",
                        }}
                        htmlFor="file"
                      >
                        {file}
                      </label>
                      <input
                        style={{ display: "none" }}
                        id="file"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "selectedFile",
                              value: e.target.files[0],
                            },
                          })
                        }
                        className="width-100"
                        ref={(ref) => (this.fileInput = ref)}
                      />
                      {controls.selectedFile.showErrorMsg && (
                        <div className="error">* Please choose file.</div>
                      )}
                    </FormGroup>
                    <span className="link-warm-blue cursor-pointer">
                      <a
                        href="https://stage-jkadvantage.s3.amazonaws.com/report/file_2022_01_05_11_33_24.xlsx"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{ color: "#5665d8", "font-size": "12px" }}
                      >
                        <label className="">Download Sample File</label>
                      </a>
                    </span>
                  </Col>

                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{ width: "40%" }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.uploadRedemptionPoints}
                    >
                      Upload Points
                    </Button>
                  </Col>

                  <Col xl="6" md="6" sm="6" id="banner-list">
                    <Table>
                      <thead>
                        <tr>
                          <th
                            rowSpan="2"
                            style={{ width: "80px", fontWeight: "normal" }}
                          >
                            Name
                          </th>
                          <th
                            rowSpan="2"
                            style={{ width: "80px", fontWeight: "normal" }}
                          >
                            Uploaded Date
                          </th>
                        </tr>
                        <tr />
                      </thead>
                      {fileUploadData.map((b) => (
                        <tr>
                          <td>
                            <a
                              style={{ color: "#545cd8" }}
                              href={b.file}
                              target="blank"
                            >
                              {b.name}
                            </a>
                          </td>
                          <td>{formatDate(b.created_at)}</td>
                        </tr>
                      ))}
                      <tbody />
                    </Table>
                  </Col>
                </>
              )}

              {isPrimaryPointReportModalOpen && (
                <PrimaryPointsReportModal
                  show={isPrimaryPointReportModalOpen}
                  closeModal={this.closePrimaryPointReportModal}
                  sapId={uuid}
                  currentClassification={currentClassification}
                />
              )}

              {isDealerProfileModal && (
                <DealerProfileModal
                  isLoading={isLoader}
                  isOpen={isDealerProfileModal}
                  toggle={() =>
                    this.setState({
                      isDealerProfileModal: !isDealerProfileModal,
                    })
                  }
                  data={this.state.dealerbyId}
                  // show={isPrimaryPointReportModalOpen}
                  // closeModal={this.closePrimaryPointReportModal}
                  // sapId={uuid}
                />
              )}

              {typeRadio.value === "data_modification_and_upload" && (
                <Row>
                  <Col>
                    <div className="switch-container">
                      <div
                        className={`switch-data ${
                          dealerOrBaadshahSwitch === "dealer"
                            ? "active-switch"
                            : ""
                        }`}
                        onClick={this.handledealerOrBaadshahSwitchChange.bind(
                          this,
                          "dealer"
                        )}
                      >
                        Dealer Modification
                      </div>
                      <div
                        className={`switch-data ${
                          dealerOrBaadshahSwitch ===
                          "employee_advantage_baadshah"
                            ? "active-switch"
                            : ""
                        }`}
                        onClick={this.handledealerOrBaadshahSwitchChange.bind(
                          this,
                          "employee_advantage_baadshah"
                        )}
                      >
                        Employee Modification
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {typeRadio.value === "import_fleet" && (
                <>
                  <Col xl="12" md="12" sm="12" style={{ display: "flex" }}>
                    <FormGroup
                      style={{ marginBottom: "0px", marginLeft: "12px" }}
                    >
                      <img
                        src="images/download.svg"
                        alt=""
                        className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                      />
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#5665d8",
                          "font-size": "12px",
                          cursor: "pointer",
                          marginBottom: "0.5rem",
                        }}
                        htmlFor="file"
                      >
                        {file}
                      </label>
                      <input
                        style={{ display: "none", cursor: "pointer" }}
                        id="file"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "selectedFile",
                              value: e.target.files[0],
                            },
                          })
                        }
                        className="width-100"
                        ref={(ref) => (this.fileInput = ref)}
                      />
                      {controls.selectedFile.showErrorMsg && (
                        <div className="error">* Please choose file.</div>
                      )}
                    </FormGroup>
                    <div
                      className="link-warm-blue cursor-pointer"
                      style={{ marginTop: "4px", marginLeft: "62px" }}
                    >
                      <a
                        href="https://images.jktyrecrm.in/others/new-fleet-insert-sample.xlsx"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                          color: "#5665d8",
                          "font-size": "12px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src="images/download.svg"
                          alt=""
                          className="download-report-icon cursor-pointer margin-left-10"
                        />
                        <label className="" style={{ cursor: "pointer" }}>
                          Download Sample File
                        </label>
                      </a>
                    </div>
                  </Col>
                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{
                        width: "40%",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.fleetDataUpload}
                    >
                      Submit
                    </Button>
                  </Col>
                </>
              )}
              {typeRadio.value === "generate_all_fleet_scorecard" && (
                <>
                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{
                        width: "40%",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                      className="mb-1 mr-1 btn-pill action-button"
                      color="primary"
                      onClick={this.generateAllFleetScorecard}
                    >
                      Generate
                    </Button>
                  </Col>
                </>
              )}
              {dealerOrBaadshahSwitch === "dealer" && (
                <>
                  <Col
                    xl="12"
                    md="12"
                    sm="12"
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <label
                      style={{ paddingLeft: 20, cursor: "pointer" }}
                      className="container-rb"
                    >
                      Enroll New Dealers
                      <input
                        type="radio"
                        name="upload_type"
                        value="new"
                        onChange={this.handleRadioChangeType.bind(
                          this,
                          "upload_type"
                        )}
                        checked={upload_type.value === "new"}
                      />
                      <span className="checkmark-rb" />
                    </label>

                    <label
                      style={{ paddingLeft: 20, cursor: "pointer" }}
                      className="margin-left-20 container-rb"
                    >
                      {" Modify Dealer Details"}
                      <input
                        type="radio"
                        name="upload_type"
                        value="update"
                        onChange={this.handleRadioChangeType.bind(
                          this,
                          "upload_type"
                        )}
                        checked={upload_type.value === "update"}
                      />
                      <span className="checkmark-rb" />
                    </label>
                  </Col>
                  <Col xl="12" md="12" sm="12" style={{ display: "flex" }}>
                    <FormGroup
                      style={{ marginBottom: "0px", marginLeft: "12px" }}
                    >
                      <img
                        src="images/download.svg"
                        alt=""
                        className="download-report-icon rotateimg180 cursor-pointer margin-left-10"
                      />
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#5665d8",
                          "font-size": "12px",
                          cursor: "pointer",
                          marginBottom: "0.5rem",
                        }}
                        htmlFor="file"
                      >
                        {file}
                      </label>
                      <input
                        style={{ display: "none", cursor: "pointer" }}
                        id="file"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) =>
                          this.handleInputChange({
                            target: {
                              name: "selectedFile",
                              value: e.target.files[0],
                            },
                          })
                        }
                        className="width-100"
                        ref={(ref) => (this.fileInput = ref)}
                      />
                      {controls.selectedFile.showErrorMsg && (
                        <div className="error">* Please choose file.</div>
                      )}
                    </FormGroup>
                    <div
                      className="link-warm-blue cursor-pointer"
                      style={{ marginTop: "4px", marginLeft: "62px" }}
                    >
                      <a
                        href="https://images.jktyrecrm.in/others/Data-Import-Utility-File+-+Sample.xlsx"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                          color: "#5665d8",
                          "font-size": "12px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src="images/download.svg"
                          alt=""
                          className="download-report-icon cursor-pointer margin-left-10"
                        />
                        <label className="" style={{ cursor: "pointer" }}>
                          Download Sample File
                        </label>
                      </a>
                    </div>
                    <div
                      className="link-warm-blue cursor-pointer"
                      style={{ marginTop: "4px", marginLeft: "62px" }}
                    >
                      <div
                        onClick={() => this.downloadStateCityDistrictMaster()}
                        style={{
                          color: "#5665d8",
                          "font-size": "12px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src="images/download.svg"
                          alt=""
                          className="download-report-icon cursor-pointer margin-left-10"
                        />
                        <label className="" style={{ cursor: "pointer" }}>
                          Download State, City, District Master
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col xl="4" md="4" sm="12">
                    <Button
                      style={{
                        width: "40%",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                      className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                      color="warning"
                      onClick={this.dataModificationandUpload}
                    >
                      Submit
                    </Button>
                  </Col>
                </>
              )}
              {dealerOrBaadshahSwitch === "employee_advantage_baadshah" && (
                <>
                  <Col
                    xl="12"
                    md="12"
                    sm="12"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  >
                    <label
                      style={{ paddingLeft: 20, cursor: "pointer" }}
                      className="container-rb"
                    >
                      Advantage
                      <input
                        type="radio"
                        name="upload_type_employee"
                        value="advantage"
                        onChange={this.handleRadioChangeType.bind(
                          this,
                          "upload_type_employee"
                        )}
                        checked={upload_type_employee.value === "advantage"}
                      />
                      <span className="checkmark-rb" />
                    </label>
                    <label
                      style={{
                        paddingLeft: 20,
                        cursor: "pointer",
                        marginLeft: "44px",
                      }}
                      className="margin-left-20 container-rb"
                    >
                      {" Baadshah"}
                      <input
                        type="radio"
                        name="upload_type_employee"
                        value="baadshah"
                        onChange={this.handleRadioChangeType.bind(
                          this,
                          "upload_type_employee"
                        )}
                        checked={upload_type_employee.value === "baadshah"}
                      />
                      <span className="checkmark-rb" />
                    </label>
                  </Col>

                  <Col xl="12" md="12" sm="12" style={{ marginTop: "10px" }}>
                    {/* Render Upload Employees for Advantage */}
                    {upload_type_employee.value === "advantage" ? (
                      <Card>
                        <CardHeader className="card-header-tab">
                          <Nav
                            style={{ marginLeft: "0" }}
                            className="about-main-tab"
                          >
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active:
                                    this.state.activeTabUplaodType ===
                                    "enroll_new_employee",
                                })}
                                onClick={() => {
                                  this.setState({
                                    activeTabUplaodType: "enroll_new_employee",
                                  });
                                }}
                              >
                                Enroll New Employee
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active:
                                    this.state.activeTabUplaodType ===
                                    "modilfy_employee",
                                })}
                                onClick={() => {
                                  this.setState(
                                    {
                                      activeTabUplaodType: "modilfy_employee",
                                    },
                                    () => {
                                      this.setEmployeeSampleFile(null);
                                    }
                                  );
                                }}
                              >
                                Modify Employee Details
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardHeader>
                        <CardBody>
                          <TabContent
                            activeTab={this.state.activeTabUplaodType}
                          >
                            <TabPane tabId="enroll_new_employee">
                              {renderRadioAdvantageEmployeeRoleType()}
                              {controls.upload_role_type_employee.value
                                ? renderUploadEmplloyee()
                                : ""}
                            </TabPane>
                            <TabPane tabId="modilfy_employee">
                              {renderUploadEmplloyee()}
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    ) : (
                      ""
                    )}

                    {/* Render Upload Employees for Baadshah */}
                    {upload_type_employee.value === "baadshah" ? (
                      <div>
                        <br />
                        {renderUploadEmplloyee()}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </>
              )}
              {typeRadio.value === "rms_vendor_enroll_dealer_mapping" && (
                <RMSVendorEnrollAndDealerMapping setLoading={this.setLoading} />
              )}
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UserUpdates;
