import React, { Fragment, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "reactstrap";
import "chartjs-plugin-datalabels";
import WarrantyReportChartService from "../../services/WarrantyReportChartService";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./WarrantyReportChart.css";

// const API_URL = process.env.REACT_APP_API_URL;

const WarrantyReportChart = ({ vehicle_type, maxDate, zoneid }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getWarrantyChartData = () => {
    return WarrantyReportChartService.getChartData(
      zoneid,
      maxDate,
      vehicle_type
    );
  };

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
    setLoading(true);
    if (maxDate) {
      getWarrantyChartData()
        .then((data) => {
          setLoading(false);
          setData(data);
          setError(false);
          return Promise.resolve(null);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          setError(true);
          return Promise.reject(null);
        });
    }
  }, [maxDate, zoneid]);

  const Chartdata = {
    labels:
      data && data.data && data.data.data
        ? data.data.data.months
        : ["Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Offtakes",
        backgroundColor: "#949AF6",
        borderColor: "#949AF6",
        hoverBackgroundColor: "#949AF6",
        hoverBorderColor: "#949AF6",
        datalabels: {
          color: "#495057;",
        },
        data:
          data && data.data && data.data.data ? data.data.data.offtakes : "",
      },

      {
        label: "Warranty",
        backgroundColor: "#FDC321",
        borderColor: "#FDC321",
        borderWidth: 1,
        hoverBackgroundColor: "#FDC321",
        hoverBorderColor: "#FDC321",
        datalabels: {
          color: "#495057;",
        },
        data:
          data && data.data && data.data.data ? data.data.data.warranty : "",
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    maintainAspectRatio: 2,
    plugins: {
      datalabels: {
        display: true,
        color: "#495057;",
        anchor: "end",
        align: "top",
        font: {
          size: "12",
          weight: "bold",
          style: "normal",
          family: "Helvetica",
        },
        padding: {
          top: 5,
        },
      },
    },
    responsive: true,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#495057",
        fontSize: 12,
        usePointStyle: true,
        padding: 20,
      },
    },
    type: "bar",
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: { display: true },
          showScale: true,
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            maxTicksLimit: 3,
          },
          showScale: false,
          barPercentage: 0.8,
        },
      ],
    },
  };

  return (
    <Fragment>
      <div>
        <div style={{ padding: 0 }}>
          <div>
            <Card style={{ maxheight: "300px", paddingTop: "10px" }}>
              <div
                className="chart_container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "-10px",
                  padding: "0 20px",
                }}
              >
                <p className="chart_heading">
                  {vehicle_type === "car"
                    ? "PCR"
                    : vehicle_type === "lcv"
                      ? "LCV"
                      : vehicle_type === "scv"
                        ? "SCV"
                        : "2 Wheeler"}
                </p>
                <p className="chart_duration_heading">
                  {data && data.data ? data.data.data.quarter_month : ""}
                </p>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                    height: "260px",
                  }}
                >
                  <Loader
                    type="TailSpin"
                    color="#f7b933"
                    height={30}
                    width={30}
                  />
                </div>
              ) : error ? (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                    height: "225px",
                  }}
                >
                  Something went wrong
                </span>
              ) : (
                <div style={{ position: "relative" }}>
                  <Bar
                    className="bar_chart"
                    data={Chartdata}
                    width={null}
                    height={243}
                    options={options}
                  />
                  <div className="custom-x-axis"></div>

                  <div>
                    <div className="custom-legend">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          margin: "0 50px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="offtake_circle"></div>
                          <span className="legend_text">Offtake</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="warranty_circle"></div>
                          <span className="legend_text">Warranty</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default WarrantyReportChart;
