import React, { Component } from 'react';
import {
  Row, Col,
  Card, CardBody
} from 'reactstrap';
import WarrantyRegistrationService from '../../../services/WarrantyRegistrationService';
import GoogleAnalticsService from '../../../services/GoogleAnalticsService';

class CustomerDetails extends Component {
  componentDidMount() {
    GoogleAnalticsService.event('Add Warranty', 'Customer Details View');
  }
  render() {
    const { controls } = this.props.customerDetails;

    return (
      <Card>
        <CardBody>
          <Row className="warranty-customer-details-label">
            <Col xl="10" xs="10" ms="10">
              {/* CUSTOMER DETAILS */}
              VEHICLE OWNER'S DETAILS
            </Col>
          </Row>
          {<div>
            <Row onClick={() => WarrantyRegistrationService.goTo(controls.firstname.value, this.props.updateState, 'customerDetails')} className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={`disabled-text-box ${controls.firstname.value ? 'filled-data-field' : ''}`}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">First Name</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.firstname.value} className="disabled-input-value">
                        {controls.firstname.value}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row onClick={() => WarrantyRegistrationService.goTo(controls.lastname.value, this.props.updateState, 'customerDetails')} className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={`disabled-text-box ${controls.lastname.value ? 'filled-data-field' : ''}`}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">Last Name</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.lastname.value} className="disabled-input-value">
                        {controls.lastname.value}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row onClick={() => WarrantyRegistrationService.goTo(controls.ownerPhoneNumber.value, this.props.updateState, 'customerDetails')} className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={`disabled-text-box ${controls.ownerPhoneNumber.value ? 'filled-data-field' : ''}`}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">Mobile Number</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.ownerPhoneNumber.value} className="disabled-input-value">
                        {controls.ownerPhoneNumber.value}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {/* <Row onClick={() => WarrantyRegistrationService.goTo(controls.email.value, this.props.updateState, 'customerDetails')} className="disable-text-box-margin" style={{ cursor: 'pointer' }}>
              <Col>
                <div className={`disabled-text-box ${controls.email.value ? 'filled-data-field' : ''}`}>
                  <Row>
                    <Col xl="6" xs="6" ms="6" className="text-overflow-dots">
                      <span className="disabled-input-label">Email Address</span>
                    </Col>
                    <Col className="text-overflow-dots">
                      <span title={controls.email.value} className="disabled-input-value">
                        {controls.email.value}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
          </div>}
        </CardBody>
      </Card>
    );
  }
}

export default CustomerDetails;