import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Table,
  Input,
  Row,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Col,
  Modal,
} from "reactstrap";
import Ionicon from 'react-ionicons';
import Moment from 'react-moment';
import Pagination from '../../components/Pagination/Pagination';
import DatePicker from 'react-datepicker';
import lodash from "lodash";
import './UserUpdates.css';
import AdminService from '../../services/AdminService';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import swal from 'sweetalert';
import { messages } from "../../constants/constant.message";
import ModalService from '../../services/ModalService';
import { formatDate } from '../../utils/utils';

const { ERROR_MESSAGE } = messages.COMMON;

// const data = [
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
//   {
//     campaignName: 'Republic Day',
//     fromName: "JK Advantage",
//     subject: 'Happy Republic Day to you all!!',
//     fromemail: 'newjkadvantage@trackwalkins.com',
//     campaignRunningOn: '04-02-2021 3:07 PM'
//   },
// ]


const SkuListTable = ({ openSideBarCreateSku }) => {
  const actionContainerRef = useRef(null)
  const skuHistoryModalRef = useRef(null)

  const [searchValue, setSearchValue] = useState('')
  const [count, setCount] = useState(10);
  const [totalCount, setTotalCount] = useState(10);
  const [pagesize, setPageSize] = useState(10);
  const [page, setPage] = useState(1)
  const [openfilter, setOpenFilter] = useState(false);
  const [openSkuHistory, setOpenSkuHistory] = useState(false)
  const [filterApplied, setFilterApplied] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const delayedQuery = useCallback(lodash.debounce(q => searchData(q), 500), []);
  const [searchQuery, setSearchQuery] = useState('');
  const [skuListData, setSkuListData] = useState([]);
  const [isFirsTimeLoadPageDataNotFound, setIsFirsTimeLoadPageDataNotFound] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [skuHistoryData, setSkuHistoryData] = useState([])
  const [actionMenuIndex, setActionMenuIndex] = useState(null)
  const [pcrSkuHistoryIndex, setPcrSkuHistoryIndex] = useState(null)
  const [skuFilterStatus, setSkuFilterStatus] = useState('')
  const [openDownloadOption, setOpenDownloadOption] = useState(false)
  const [downloadFilter, setDownloadFilter] = useState('data')
  // const serearchQuery = localStorage.getItem('serearchQuery');
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [resetFIlter, setResetFilter] = useState(false)


  const pageNumber = localStorage.getItem('pageNumber');


  useEffect(() => {
    getAllSkuData();
  }, [(page * pagesize)])

  useEffect(() => {
    getAllSkuData();
    setPageSize(10);
    setPage(Number(pageNumber) ? Number(pageNumber) : 1);
  }, [searchQuery,])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        actionContainerRef.current &&
        !actionContainerRef.current.contains(event.target)
      ) {
        setActionMenuIndex(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (resetFIlter) {
      // Make the API call using the updated state values
      getAllSkuData()

      // Reset the tracking variable
      setResetFilter(false);
    }
  }, [resetFIlter]);

  const handleChange = (e) => {
    setPage(1);
    localStorage.setItem('pageNumber', 1);
    setSearchValue(e.target.value)
    // setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  }
  const searchData = (search) => {
    setSearchQuery(search)
  };
  const openFilter = async () => {
    await setOpenFilter(!openfilter)
  }

  const openDownloadOptionToggle = async () => {
    await setOpenDownloadOption(!openDownloadOption)
  }

  const openSkuHistoryModal = async (event, skuId) => {
    setActionMenuIndex(null)
    setOpenSkuHistory(!openSkuHistory)
    if (skuId && !openSkuHistory) {
      getSkuHistoryData(skuId)
      setPcrSkuHistoryIndex(skuId)
    } else {
      setSkuHistoryData([])
      setPcrSkuHistoryIndex(null)
      setActionMenuIndex(null)
    }
    // setActionMenuIndex(-1)
  }

  const onStartDateChange = (e) => {
    setStartDate(e)
  }
  const onEndDateChange = (e) => {
    setEndDate(e)
  }

  const onFilterApplied = () => {
    if (startDate || endDate || skuFilterStatus) {
      setFilterApplied(true)
    }
    // openFilter();
    getAllSkuData()
    setOpenFilter(false)
  }
  const onResetApplied = () => {
    setStartDate('');
    setEndDate('');
    setFilterApplied(false);
    setSkuFilterStatus('')
    setOpenFilter(false)
    setResetFilter(true)
  }

  const onCLoseDownloadOption = () => {
    setOpenDownloadOption(false)
  }

  const getToDateWhileSending = (date) => {
    let dateToProcesss = Object.assign(date);
    let currentDate = dateToProcesss.getDate();
    dateToProcesss.setDate(currentDate + 1);
    dateToProcesss.setHours(5, 29, 59, 59);
    return dateToProcesss.toISOString();
  }

  const SetDownloadOption = (data) => {
    setDownloadFilter(data)
  }
  const getAllSkuData = async () => {
    setIsLoading(true);

    let reqData = {
      pageSize: pagesize,
      page: pageNumber ? pageNumber : page,
      q: searchQuery
    }
    if (skuFilterStatus && skuFilterStatus !== '') {
      reqData['status'] = skuFilterStatus === 'Active' ? 'active' : 'inActive'
    }
    if (startDate) {
      let start_date_value = Object.assign(startDate);
      start_date_value.setHours(5, 30, 0, 0);
      reqData['start_date'] = start_date_value.toISOString();
    }
    if (endDate) {
      let dateToProcess = new Date(endDate.getTime());
      reqData['end_date'] = getToDateWhileSending(dateToProcess);
    }
    AdminService.getSkuListData(reqData)
      .then((response) => {
        let { skuData, count } = response.data.data;
        if (skuData && skuData.length > 0) {
          setSkuListData(skuData);
          if (isFirsTimeLoadPageDataNotFound) {
            setIsFirsTimeLoadPageDataNotFound(false);
          }
        } else {
          setSkuListData([]);
        }
        const totalCountsTemp = count;
        const totalPagesTemp = Math.ceil(totalCountsTemp / pagesize);
        setCount(totalCountsTemp);
        setTotalCount(totalPagesTemp);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  const onDownloadReport = () => {
    if (downloadFilter === 'history') {
      setIsLoading(true);
      AdminService.downloadSKUHistory()
        .then((response) => {
          let { file } = response.data.data;
          if (file) {
            window.open(file, "_blank");
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      let reqData = {
        pageSize: pagesize,
        page: pageNumber ? pageNumber : page,
        q: searchQuery,
        is_download: true
      }
      if (skuFilterStatus && skuFilterStatus !== '') {
        reqData['status'] = skuFilterStatus === 'Active' ? 'active' : 'inActive'
      }
      if (startDate) {
        let start_date_value = Object.assign(startDate);
        start_date_value.setHours(5, 30, 0, 0);
        reqData['start_date'] = start_date_value.toISOString();
      }
      if (endDate) {
        let dateToProcess = new Date(endDate.getTime());
        reqData['end_date'] = getToDateWhileSending(dateToProcess);
      }
      AdminService.getSkuListData(reqData)
        .then((response) => {
          let { file } = response.data.data;
          if (file) {
            window.open(file, "_blank");
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }

  const handlePageChange = (page) => {
    localStorage.setItem('pageNumber', Number(page));
    setPage(page);
  }

  const applyFilterStatus = (value) => {
    setSkuFilterStatus(value)
    setPage(1)
  }
  const handleInputChange = (e) => {
    setPageSize(Number(e.target.value));
  }
  const Filter = () => {
    return (
      <div className="email_campaign_filter_container">
        <Card>
          <CardBody style={{ padding: 0 }}>
            <div style={{ padding: '18px' }}>
              <div className='d-flex justify-content-between'>
                <span className="email_campaign_filter_heading">Created At</span>
                {/* <span className="email_campaign_filter_heading ml-1 cursor-pointer"
                 onClick={() => openFilter()}
                >X</span> */}
                <img
                  className="cursor-pointer ml-1"
                  src="/images/close.svg"
                  alt=""
                  width='12px'
                  onClick={() => {
                    if (!filterApplied) {
                      setStartDate('')
                      setEndDate('')
                      setSkuFilterStatus('')
                    }
                    setOpenFilter(!openFilter)
                  }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '14px', marginBottom: '24px' }}>
                <div style={{ paddingRight: '3px' }}>
                  <div style={{ marginBottom: '12px' }}><span className="email_campaign_date_title">Start Date</span></div>
                  <InputGroup>
                    <DatePicker
                      placeholderText="select"
                      className={"form-control"}
                      selected={startDate}
                      onChange={onStartDateChange}
                      showMonthDropdown
                      //ref={(r) => (this.calendar = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={new Date()}
                      dropdownMode="select"
                      //onInputClick={this.onInputClickDate}
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append">
                      <div className="input-group-text">
                        <img src="/images/sidebar-icons/calendar_icon.png" alt="" />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div style={{ paddingLeft: '3px' }}>
                  <div style={{ marginBottom: '12px' }}><span className="email_campaign_date_title">End Date</span></div>
                  <InputGroup>
                    <DatePicker
                      placeholderText="select"
                      className={"form-control"}
                      selected={endDate}
                      onChange={onEndDateChange}
                      showMonthDropdown
                      //ref={(r) => (this.calendar = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={new Date()}
                      dropdownMode="select"
                      //onInputClick={this.onInputClickDate}
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append">
                      <div className="input-group-text">
                        <img src="/images/sidebar-icons/calendar_icon.png" alt="" />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div className='status-container'>
                <span>Status</span>
                <div>
                  <div
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      marginBottom: 15,
                    }}
                  >
                    <label
                      style={{ paddingLeft: 20, cursor: 'pointer' }}
                      className="container-rb"
                    >
                      All
                      <input
                        type="radio"
                        name="sku_type"
                        value="all"
                        onChange={
                          () => {
                            applyFilterStatus('')
                            // setSkuFilterStatus('')
                          }
                        }
                        checked={skuFilterStatus === ''}
                      />
                      <span className="checkmark-rb" />
                    </label>
                    <label
                      style={{
                        paddingLeft: 20,
                        cursor: 'pointer',
                        marginLeft: '15px',
                      }}
                      className="container-rb"
                    >
                      Active
                      <input
                        type="radio"
                        name="sku_type"
                        value="Active"
                        onChange={() => {
                          applyFilterStatus('Active')
                        }
                          // setSkuFilterStatus('Active')}
                        }
                        checked={skuFilterStatus === 'Active'}
                      />
                      <span className="checkmark-rb" />
                    </label>
                    <label
                      style={{
                        paddingLeft: 20,
                        cursor: 'pointer',
                        marginLeft: '15px',
                      }}
                      className="container-rb"
                    >
                      InActive
                      <input
                        type="radio"
                        name="sku_type"
                        value="InActive"
                        onChange={() => {
                          applyFilterStatus('InActive')
                          // setSkuFilterStatus('InActive')
                        }}
                        checked={skuFilterStatus === 'InActive'}
                      />
                      <span className="checkmark-rb" />
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <button style={{ color: '#6E777F', background: '#E0E2E4' }} className="email_campaign_button" onClick={onResetApplied}>Reset</button>
                <button style={{ color: '#495057', background: '#FDBB1F' }} className="email_campaign_button" onClick={onFilterApplied}> Apply </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div >
    )
  }

  const DownloadOption = () => {
    return (
      <div className="email_campaign_filter_container" style={{ position: 'absolute', top: '100%', left: '45%' }}>
        <Card>
          <CardBody style={{ padding: 0 }}>
            <div style={{ padding: '18px' }}>
              <div className='status-container'>
                {/* <span>Status</span> */}
                <div>
                  <div className='d-flex justify-content-between mb-3'>
                    <span className="email_campaign_filter_heading">Download SKU Report</span>
                    <img
                      className="cursor-pointer ml-1"
                      src="/images/close.svg"
                      alt=""
                      width='12px'
                      onClick={onCLoseDownloadOption}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                      // marginLeft: '10px',
                      marginBottom: 15,
                    }}
                    className='d-flex flex-column text-left downloadSkuRadio'
                  >
                    <label
                      style={{ paddingLeft: 20, cursor: 'pointer' }}
                      className="container-rb"
                    >
                      Download SKU Data
                      <input
                        type="radio"
                        name="sku_type"
                        value="all"
                        onChange={
                          () => {
                            SetDownloadOption('data')
                            // applyFilterStatus('')
                          }
                        }
                        checked={downloadFilter === 'data'}
                      />
                      <span className="checkmark-rb" style={{ marginTop: '3px' }} />
                    </label>
                    <label
                      style={{
                        paddingLeft: 20,
                        cursor: 'pointer',
                      }}
                      className="container-rb"
                    >
                      Download SKU History Report
                      <input
                        type="radio"
                        name="sku_type"
                        value="Active"
                        onChange={() => {
                          SetDownloadOption('history')
                        }}
                        checked={downloadFilter === 'history'}
                      />
                      {/* // setSkuFilterStatus('Active')}
                      // applyFilterStatus('Active')}
                      // checked={skuFilterStatus === 'Active'} */}
                      <span className="checkmark-rb" style={{ marginTop: '3px' }} />
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <button style={{ color: '#6E777F', background: '#E0E2E4' }} className="email_campaign_button" onClick={onCLoseDownloadOption}>Close</button>
                <button style={{ color: '#495057', background: '#FDBB1F' }} className="email_campaign_button" onClick={onDownloadReport}> Download </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div >
    )
  }

  const getSkuHistoryData = (skuId) => {
    const reqData = {
      id: skuId
    }
    setIsModalLoading(true)
    AdminService.getSkuPcrHistoryData(reqData)
      .then((response) => {
        if (response.data.data) {
          setSkuHistoryData(response.data.data)
        }
        setIsModalLoading(false)
        // let{skuData, count} = response.data.data;
        //   if(skuData && skuData.length > 0)
        //   {
        //   setSkuListData(skuData);
        //   if (isFirsTimeLoadPageDataNotFound) {
        //     setIsFirsTimeLoadPageDataNotFound(false);
        //   }
        // } else {
        //   setSkuListData([]);
        // }
        // const totalCountsTemp = count;
        // const totalPagesTemp = Math.ceil(totalCountsTemp / pagesize);
        // setCount(totalCountsTemp);
        // setTotalCount(totalPagesTemp);
        // setIsLoading(false);
      })
      .catch((e) => {
        console.log(e)
        setIsModalLoading(false);
      });
  }

  const SKUHistory = () => {
    return (
      <Modal
        centered={true}
        isOpen={true}
        ref={skuHistoryModalRef}
        style={{ minWidth: '70%' }}
        className="modal-md modal-lg custom-confirm-modal modal-sm"
      >
        <Card>

          {isModalLoading && <CustomSpinner />}
          <CardBody style={{ padding: 0 }}>
            <div style={{ padding: '18px' }}>
              <div>
                <span className="email_campaign_filter_heading">SKU History {pcrSkuHistoryIndex} </span>
              </div>
              <div>
                <Table className="mb-0 table-responsive tbl-about tbl-about text-align-center">
                  <thead>
                    <tr
                      style={{ backgroundColor: "#f6f6f6", height: '40px' }}
                      className="coupon-list-table-header"
                    >
                      <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Status</span></td>
                      <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Updated At</span></td>
                    </tr>
                  </thead>
                  <tbody>
                    {skuHistoryData && skuHistoryData.length > 0 ? skuHistoryData.map((item, index) => (
                      <tr key={index + 'history'}>
                        <td className="text-align-center border-1-5 email_campaign_table_data">{item.is_active ? 'Active' : 'InActive'}</td>
                        <td className="text-align-center border-1-5 email_campaign_table_data">
                          <Moment format="DD-MM-YYYY hh:mm A">
                            {item.created_at}
                          </Moment>
                        </td>
                      </tr>
                    )) :
                      <tr>
                        <td colSpan={2}>There is no any SKU updates history.</td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>              <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                <button style={{ color: '#495057', background: '#FDBB1F' }} className="email_campaign_button" type='button' onClick={openSkuHistoryModal}> Close </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Modal >
    )
  }
  const OpenActionMenu = (index) => {
    setActionMenuIndex(index);
  };

  const updatePcrSkuStatus = (id, status) => {
    const tempData = {
      id: id,
      status: status
    }
    swal({
      // title: `${status === true ? "Active" : "Inactive"} PCR SKU`,
      text: `Are you sure want to update ${id} status as ${status === true ? "Active" : "Inactive"} ?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((isConfirm) => {
      if (isConfirm) {
        setIsLoading(true)
        AdminService.updatePcrSkuStatus(tempData).then(data => {
          setIsLoading(false)
          setActionMenuIndex(null)
          getAllSkuData();
          // this.getBanners();
          ModalService.openAlert('', data.data.message || '', 'success');
        })
          .catch(e => {
            setIsLoading(false)
            const err =
              e.response && e.response.data && e.response.data.error
                ? e.response.data.error[0]
                : null;
            const message = err && err.message ? err.message : ERROR_MESSAGE;
            ModalService.openAlert('', message, 'error');
          })
      }
    })
  }

  const renderActionMenu = (row, i) => {
    return <div>
      <Ionicon className="cursor-pointer" icon="md-more" fontSize="24px" color="#fdbb1f"
        onClick={() => OpenActionMenu(i)}
      />
      {actionMenuIndex === i &&
        <div className="action-menu manageSkuActionMenu"
          ref={actionContainerRef}
        >
          <div className='mb-2 mt-1'>
            <Row className="customer-list-action-menu-content cursor-pointer p-0"
              onClick={() => updatePcrSkuStatus(row.sku_id, !row.is_active)}
            >
              <Col sm="1" className='p-0'>
                <img
                  className=" ml-3"
                  style={{ height: "17px", width: "21px" }}
                  src="images/activate_inactive_icon.svg"
                  alt=""
                />
              </Col>
              <Col className='p-0 ml-3'>{(row.is_active === true || row.is_active === 'true') ? 'Inactive' : 'Active'}</Col>
            </Row>
          </div>
          <div>
            <Row className="customer-list-action-menu-content cursor-pointer p-0"
              onClick={(e) => { openSkuHistoryModal(e, row.sku_id) }}
            >
              <Col sm="1" className='p-0'>
                <img
                  className=" ml-3"
                  style={{ height: "17px", width: "22px" }}
                  src="images/info.svg"
                  alt=""
                />
              </Col>
              <Col className='p-0 ml-3'>History</Col>
            </Row>
          </div>
        </div>
      }
    </div>
  }


  return (
    <div style={{ width: '100%' }}
    >
      <div className="email_title_container">
        <span className="email_campaign_title">PCR SKU List</span>
      </div>
      <div className="email_input_container">
        <div style={{ alignItems: 'center' }} className="d-flex-row">
          <div
            style={{ width: "448px", height: '35px' }}
            className="d-flex-row search-input col-4 pl-0"
          >
            <Input
              placeholder="Search by SKU ID, SKU Name, SKU Group"
              className="login-input"
              name="search"
              onChange={handleChange}
              value={searchValue}
            ></Input>
            <img
              src="images/search.svg"
              alt=""
              className="icon-width"
              style={{ marginLeft: "-25px", width: '16px' }}
            />
          </div>
          <div className='' style={{ marginLeft: '14px' }}>
            {filterApplied ? <img src="images/filter_applied.svg" alt=""
              className="cursor-pointer"
              onClick={() => openFilter()} /> :
              <img
                src="images/filter.svg"
                alt=""
                className="cursor-pointer"
                onClick={() => openFilter()}
              />}
          </div>
          {openfilter && <div style={{ position: 'relative' }}><Filter /></div>}
          {openSkuHistory && <div><SKUHistory /></div>}
          {/* <div className="d-flex-row" style={{ marginLeft: "10px" }}>
            {this.state.filterControls &&
              Object.keys(this.state.filterControls).length > 0 && (
                <img
                  src="images/filter_applied.svg"
                   alt=""
                  className="cursor-pointer"
                //onClick={() => this.openCouponsFilter()}
                 />
              )}
            {this.state.filterControls &&
              Object.keys(this.state.filterControls).length === 0 && (
                <img
                  src="images/filter.svg"
                   alt=""
                  className="cursor-pointer"
                // onClick={() => this.openCouponsFilter()}
                 />
              )}
          </div> */}
          {/* <div className='text-align-right'> */}
          {/* </div> */}
          <div className='text-align-right col' style={{ position: 'relative' }}>
            {openDownloadOption && <DownloadOption />}
            <div style={{ display: "contents" }}>
              <span className="Download-icon" style={{ marginRight: '10px', display: 'contents' }}>
                <img
                  src="images/group-6.png"
                  srcSet="images/group-6@2x.png 2x,
                        images/group-6@3x.png 3x"
                  className="Group-6"
                  alt=""
                />
                <span
                  className="Download-Report"
                  onClick={() => openDownloadOptionToggle()}
                >
                  Download Report
                </span>
              </span>
            </div>

            <button onClick={() => openSideBarCreateSku(true)} className="create_campaign_button" style={{ marginLeft: '10px' }}>Create PCR SKU</button>
          </div>

        </div>
      </div>
      {isLoading && <CustomSpinner />}
      <div style={{ padding: '0 18px' }}>
        <Table className="mb-0 table-responsive tbl-about tbl-about text-align-center">
          <thead>
            <tr
              style={{ backgroundColor: "#f6f6f6", height: '40px' }}
              className="coupon-list-table-header"
            >
              <td rowSpan="2" className="text-align-center border-1-5">
                <span className="table_header_text">Name</span>
              </td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">SKU ID</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">SKU Group</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Quality</span> </td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Technology</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Product</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Application</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Size</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Code</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Status</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Created At</span></td>
              <td rowSpan="2" className="text-align-center border-1-5" ><span className="table_header_text">Actions</span></td>
            </tr>
          </thead>
          <tbody>
            {skuListData && skuListData.map((item, index) => (
              <tr>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.name}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.sku_id}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.sku_group}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">
                  {item.quality ? item.quality.charAt(0).toUpperCase() + item.quality.slice(1) : ''}
                </td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.technology_name}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.product_name}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.application_name}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.size}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.code}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">{item.is_active ? 'Active' : 'InActive'}</td>
                <td className="text-align-center border-1-5 email_campaign_table_data">
                  {formatDate(item.created_at)}
                  <br />
                  <Moment format="hh:mm A">
                    {item.created_at}
                  </Moment>
                </td>
                <td className="text-align-center border-1-5 email_campaign_table_data" style={{ position: 'relative', textAlign: 'center' }}>{renderActionMenu(item, index)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {count > 0 && (
        <div
          className="d-flex-row"
          style={{ marginTop: "10px", justifyContent: "flex-end" }}
        >
          <span className="Rows-per-page">Rows per page:</span>
          <select
            className="form-control rows-per-page-select"
            name="pageSize"
            value={pagesize}
            onChange={e => handleInputChange(e)}
            style={{ width: "65px" }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          &nbsp;&nbsp;
          <div style={{ marginTop: "5px" }}>
            <Pagination
              margin={2}
              page={page}
              count={totalCount}
              pageSize={pagesize}
              totalRecords={count}
              onPageChange={handlePageChange}
            ></Pagination>
          </div>
        </div>
      )}
    </div>
  )
}

export default SkuListTable;
