import React, { Component } from "react";
import ProposalSerivice from "../../services/ProposalSerivice";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import swal from "sweetalert";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import TextareaAutosize from "react-textarea-autosize";
import Validation from "../../services/Validation";
import { messages } from "../../constants/constant.message";
import { Roles } from "../../constants/constant.role";
class EstimateNeeded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalStageStatus: props.proposalStageStatus,
      proposalStageDetail: props.proposalStageDetail,
      controls: {
        approved: {
          value: "",
          showErrorMsg: false,
          touched: false,
          valid: false,
        },
        remark: {
          value: "",
          showErrorMsg: false,
          touched: false,
          valid: false,
        },
        estimate: {
          type: "estimate",
          file: "",
          showErrorMsg: false,
        },
        agreement: {
          type: "agreement",
          // file: '',
          aggrementDate: null,
          showErrorMsg: false,
        },
      },
      isLoading: false,
      userDetail: Storage.getUserDetail(),
    };
  }
  uploadFileFormData = async (file, type) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await ProposalSerivice.uploadNewDealerDocument(file);
      const { controls } = this.state;
      if (data && data.data) {
        controls[type].file = data.data;
        controls[type].showErrorMsg = false;
      }
      this.setState({ controls, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  uploadFile = (event, type) => {
    // let { isLoading } = this.state;
    // isLoading = true;
    // this.setState({ isLoading });
    if (event.target.files[0]) {
      const file = new FormData();
      file.append("file", event.target.files[0]);
      if (event.target.files[0].size > 6000000) {
        const { controls } = this.state;
        controls[type].file = "";
        event.target.value = null;
        ModalService.openAlert(
          "",
          "Document size exceeds maximum limit is 5MB.",
          "error"
        );
        return;
      }
      this.uploadFileFormData(file, type);
    }
  };
  submitAggrementDocuments = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return;
    }

    const { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    let documents = [];
    //this.submit();
    if (controls.agreement.aggrementDate) {
      if (!controls.estimate.file) {
        controls.estimate.showErrorMsg = true;
        this.setState((prevState) => {
          return { ...prevState };
        });
        return;
      }
    }
    if (!controls.estimate.file) {
      return;
    }
    documents.push({
      type: "estimate",
      file: controls.estimate.file,
    });
    const obj = { documents: documents };
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });

    ProposalSerivice.uploadEstimate(obj)
      .then((res) => {
        documents.forEach((element) => {
          element.file = "";
        });
        if (this.fileInput3) {
          this.fileInput3.value = "";
        }
        if (this.fileInput4) {
          this.fileInput4.value = "";
        }
        if (res.data.message && res.data.message.search("add") > -1) {
          res.data.message = "Civil Estimate added successfully";
        } else if (res.data.message && res.data.message.search("update") > -1) {
          res.data.message = "Civil Estimate updated successfully";
        }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.getProposalStageDetail();
        });
        this.setState({ isLoading: false, documents });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  submit = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return;
    }
    let { controls } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    if (!this.handleValidation(true)) {
      return;
    }
    let document = [];
    document = this.props.proposalStageDetail.estimation_data
      ? this.props.proposalStageDetail.estimation_data.filter(
        (x) => x.type === "estimate" && x.status === "pending"
      )
      : [];
    if (document.length <= 0) {
      ModalService.openAlert("", "Not found any pending document", "error");
      return;
    }
    let obj = {
      approved:
        controls.approved.value && controls.approved.value === "true"
          ? true
          : false,
      document_id: document[0].document_id,
      remark: controls.remark.value ? controls.remark.value : null,
    };
    obj.uuid = this.state.proposalStageStatus.uuid;

    this.setState({ isLoading: true });

    ProposalSerivice.saveEstimate(obj)
      .then((res) => {
        controls.approved.value = "";
        controls.remark.value = "";
        // if (res.data.message && res.data.message.search("add") > -1) {
        //   res.data.message = 'Estimate details added successfully';
        // } else if (res.data.message && res.data.message.search("update") > -1) {
        //   res.data.message = 'Estimate details updated successfully';
        // }
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.getProposalStageDetail();
        });
        this.setState((prevState) => {
          return { ...prevState, isLoading: false, controls };
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };
  onChangeDate = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const { controls } = this.state;
    controls.agreement.aggrementDate = e;
    controls.agreement.showErrorMsg = false;
    this.setState({ controls });
  };

  handleInputCheckboxChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName] = !controls[controlName];
    this.setState({ controls });
  };

  handleValidation = (isSubmit = false) => {
    let { controls } = this.state;
    let isFormValid = false;
    let { approved, remark } = controls;
    if (approved.touched === true || isSubmit) {
      if (approved.value) {
        approved.showErrorMsg = true;
        approved.valid = true;
      }
      if (approved.valid === false) {
        approved.showErrorMsg = true;
      } else {
        approved.showErrorMsg = false;
      }
    }

    remark.showErrorMsg = false;
    remark.valid = true;
    if (
      (remark.touched === true || isSubmit) &&
      approved.value &&
      (approved.value === "false" || approved.value === false)
    ) {
      remark = Validation.notNullValidator(remark);
      remark.valid = !remark.nullValue;
      if (remark.valid === false) {
        remark.showErrorMsg = true;
      } else {
        remark.showErrorMsg = false;
      }
    }

    if (approved.valid === true && remark.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    this.setState({
      controls,
    });
    return isFormValid;
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName].value = e.target.value;
    controls[controlName].touched = true;
    if (controlName === "approved") {
      controls.remark.value = "";
    }
    this.setState({ controls });
    this.handleValidation();
  };

  render() {
    const { controls } = this.state;
    let { proposalStageDetail, isCompletedAllStages } = this.props;
    let document = proposalStageDetail.estimation_data
      ? proposalStageDetail.estimation_data.filter(
        (x) =>
          x.type === "estimate" &&
          (x.status === "pending" || x.status === true)
      )
      : [];
    let isEstimation = proposalStageDetail.estimation_data
      ? proposalStageDetail.estimation_data.findIndex(
        (x) => x.type === "estimate"
      )
      : -1;
    let isPendingAnyDoc = false;
    if (document.length > 0) {
      isPendingAnyDoc = true;
    }

    return (
      (!isCompletedAllStages ||
        proposalStageDetail.estimation_data.length > 0) && (
        <>
          <br />
          {this.state.isLoading && <CustomSpinner />}
          <Card>
            <CardBody>
              <Row>
                <Col xl="12" md="12" xs="12">
                  <b>Civil Estimate</b>
                </Col>
              </Row>
              <hr />
              {
                // this.state.userDetail.access_level === 'area' &&
                <>
                  {!isPendingAnyDoc &&
                    this.state.userDetail.type &&
                    (this.state.userDetail.type.toLowerCase() ===
                      Roles.AREA_MANAGER_ROLE ||
                      this.state.userDetail.type.toLowerCase() ===
                      Roles.HEAD_OFFICE_ROLE ||
                      this.state.userDetail.type.toLowerCase() ===
                      Roles.ARCHITECT_ROLE) &&
                    !isCompletedAllStages && (
                      <>
                        <Row>
                          <Col xl="5" md="12" xs="12">
                            <FormGroup>
                              <Label>
                                Estimate Documents (jpeg, jpg, png and pdf file
                                upto 5MB)
                                <span className="required-field">*</span>
                              </Label>
                              <input
                                type="file"
                                ref={(ref) => (this.fileInput3 = ref)}
                                accept="image/*,.pdf"
                                name="layout"
                                onChange={(e) => this.uploadFile(e, "estimate")}
                              />
                            </FormGroup>
                            {controls.estimate &&
                              controls.estimate.showErrorMsg && (
                                <div className="error">
                                  *Please upload document.
                                </div>
                              )}
                          </Col>
                          <Col
                            xl="7"
                            md="12"
                            xs="12"
                            style={{ display: "flex" }}
                          >
                            <Button
                              className="mr-1 ml-auto btn-pill action-button action-ob-button font-weight-bold mt-auto mb-auto"
                              color="warning"
                              type="submit"
                              onClick={(e) => this.submitAggrementDocuments()}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                        <br />
                      </>
                    )}
                  {this.state.userDetail.type &&
                    (this.state.userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE ||
                      this.state.userDetail.type.toLowerCase() ===
                      Roles.HEAD_OFFICE_ROLE) &&
                    isPendingAnyDoc &&
                    !isCompletedAllStages && (
                      <Row>
                        <Col xl="3" md="12" xs="12">
                          <FormGroup>
                            <Label>
                              Estimate Status
                              <span className="required-field">*</span>
                            </Label>
                            <select
                              className="form-control select-height-70rem"
                              name="approved"
                              value={controls.approved.value}
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value="">Select approval status</option>
                              <option value="true">Approve</option>
                              <option value="false">Rejected</option>
                            </select>
                            {controls.approved.showErrorMsg && (
                              <div className="error">
                                * {"Please select estimate status"}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        {
                          <Col xl="5" md="12" xs="12">
                            <FormGroup>
                              <Label>
                                Remark
                                {(controls.approved.value === "false" || controls.approved.value === false) && (
                                  <span className="required-field">*</span>
                                )}
                              </Label>
                              <TextareaAutosize
                                name="remark"
                                autocomplete="nofill"
                                id="remark"
                                value={controls.remark.value}
                                style={{
                                  minHeight: 60,
                                  maxHeight: 70,
                                  fontSize: "14px",
                                }}
                                className="form-control"
                                onChange={(e) =>
                                  this.handleInputChange({
                                    target: {
                                      name: "remark",
                                      value: e.target.value,
                                    },
                                  })
                                }
                                placeholder=""
                              />
                              {controls.remark.showErrorMsg && (
                                <div className="error">
                                  * {"Please enter remark"}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        }
                        <Col
                          xl="4"
                          md="12"
                          xs="4"
                          style={{ display: "flex", marginLeft: "auto" }}
                        >
                          <Button
                            className="mr-1 ml-auto btn-pill action-button action-ob-button font-weight-bold mt-auto mb-auto"
                            color="warning"
                            type="submit"
                            onClick={(e) => this.submit()}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    )}
                </>
              }
              {proposalStageDetail &&
                proposalStageDetail.estimation_data &&
                proposalStageDetail.estimation_data.length > 0 &&
                isEstimation > -1 && (
                  <Table className="mb-0  tbl-about tbl-about table-responsive">
                    <thead>
                      <tr>
                        {/* <th scope="col">Type</th> */}
                        <th>Document</th>
                        <th>Upload Date</th>
                        <th>Status</th>
                        <th>Approve/Reject Date</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {proposalStageDetail.estimation_data.map((item) =>
                        item.type === "estimate" ? (
                          <tr>
                            {/* <td>{item.type}</td> */}
                            <td>
                              <a
                                className="aa d-flex-column"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.document}
                              >
                                <img
                                  src="images/sidebar-icons/secondary-report-selected.svg"
                                  alt=''
                                  height="40px"
                                  style={{ margin: "auto" }}
                                />
                                <span className="redirect-link">View</span>
                              </a>
                            </td>
                            <td>{item.created_at}</td>
                            <td className="text-capitalize">
                              {item.status === true || item.status === "true"
                                ? "Approved"
                                : item.status === false || item.status === "false"
                                  ? "Rejected"
                                  : item.status}
                            </td>
                            <td>
                              {item.approve_reject_date ? (
                                item.approve_reject_date
                              ) : (
                                <span className="no-data-hypen  ml-auto mr-auto"></span>
                              )}
                            </td>
                            <td
                              className="text-align-left"
                              style={{ maxWidth: "150px" }}
                            >
                              <span>
                                {item.remark ? (
                                  item.remark
                                ) : (
                                  <span className="no-data-hypen  ml-auto mr-auto"></span>
                                )}
                              </span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    </tbody>
                  </Table>
                )}
              {isEstimation <= -1 && (
                <div
                  className="no-data tbl-offtake-summary"
                  style={{ maxWidth: "100%", borderTop: "solid 0.9px #e9ecef" }}
                >
                  There is no data found.
                </div>
              )}
            </CardBody>
          </Card>
        </>
      )
    );
  }
}

export default EstimateNeeded;
