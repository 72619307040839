import React, { Component, Fragment } from "react";
import { Row, Col, Form } from "reactstrap";
import { connect } from "react-redux";
import Sidebar from "react-sidebar";
import Ionicon from "react-ionicons";
import { Card, CardBody } from "reactstrap";

// components
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import ActionScreen from "../../components/ActionScreen/ActionScreen";
import ProfileDetail from "../../components/ProfileDetail/ProfileDetail";
import EditOtherBusinessDetail from "../../components/EditOtherBusinessDetail/EditOtherBusinessDetail";
import EditPrimaryContactDetail from "../../components/EditPrimaryContactDetail/EditPrimaryContactDetail";
import EditSocialDetails from "../../components/EditSocialDetails/EditSocialDetails";
import DetailCard from "../../components/DetailCard/DetailCard";
import EditSubDealerDetail from "../../components/EditSubDealerDetail/EditSubDealerDetail";
import EditBranch from "../../components/EditBranch/EditBranch";

// services
import UserService from "../../services/UserService";
import StorageService from "../../services/Storage";
import Validation from "../../services/Validation";
import WarrantyRegistrationService from "../../services/WarrantyRegistrationService";
import SpecialEngagementService from "../../services/SpecialEngagementService";

// actions
import { setUserDetail, isProfileUpdate } from "../../actions/UserAction";

// modal
import ModalService from "../../services/ModalService";

// constants
import { messages } from "../../constants/constant.message";

// css
import "./Profile.css";
import Storage from "../../services/Storage";
import SocialDetail from "../../components/DetailCard/SocialDetail";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

const { ERROR_MESSAGE } = messages.COMMON;

class Profile extends Component {
  constructor(props) {
    super(props);
    const userDetail = StorageService.getUserDetail() ? StorageService.getUserDetail() : {};
    const { type: userRole = null } = userDetail;
    this.state = {
      userDetail: Storage.getUserDetail(),
      isLoading: true,
      userInfo: {},
      profileImage: null,
      sapId: null,
      businessName: null,
      ownerName: null,
      address: null,
      isLoader: false,
      isUpdateProfileRequestSend: true,
      isBusinessEditActive: false,
      isContactEditActive: false,
      isSocialEditActive: false,
      isBranchEditActive: false,
      isSubDealerEditActive: false,
      isImageUpdating: false,
      is_disable_sender_id: false,
      controls: {
        state: {
          value: null,
          valid: true,
          required: false,
          touched: false,
          invalidLength: null,
          showErrorMsg: false,
        },
        city: {
          value: null,
          valid: true,
          required: false,
          touched: false,
          invalidLength: null,
          showErrorMsg: false,
        },
        pin_code: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        address: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          invalidLength: null,
          showErrorMsg: false,
        },
        senderId: {
          value: null,
          valid: true,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          invalidAlphabetic: false,
        },
        email: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
        },
        phone: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidPhone: null,
          required: true,
          showErrorMsg: false,
        },
        gst: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          invalidLength: null,
          showErrorMsg: false,
        },
        pan: {
          value: null,
          valid: true,
          required: false,
          touched: false,
          invalidLength: null,
          showErrorMsg: false,
        },
        alPerson: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        alDesignation: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        alNumber: {
          value: null,
          valid: true,
          invalidPhone: null,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        wa: {
          value: null,
          valid: false,
          touched: false,
          invalidPhone: null,
          required: false,
          showErrorMsg: false,
        },
        birthDate: {
          value: null,
          valid: null,
          touched: false,
          required: true,
          showErrorMsg: false,
          // invalidDate: false
        },
        anniversaryDate: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
          // invalidDate: false
        },
        facebook: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        instagram: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        website: {
          value: null,
          valid: true,
          touched: false,
          required: true,
          showErrorMsg: false,
        },
        google_my_business_rating_link: {
          value: null,
          valid: true,
          touched: false,
          required: true,
          showErrorMsg: false,
        },
        google_my_business_link: {
          value: null,
          valid: true,
          touched: false,
          required: true,
          showErrorMsg: false,
        },
        agreeTermAndCondition: false,
        sameAsUser: false,
      },
      subDealerControls: {
        businessName: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        ownerName: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidPhone: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        gst: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          invalidLength: null,
          showErrorMsg: false,
        },
        pan: {
          value: null,
          valid: true,
          required: false,
          touched: false,
          invalidLength: null,
          showErrorMsg: false,
        },
        wa: {
          value: null,
          valid: false,
          touched: false,
          invalidPhone: null,
          required: false,
          showErrorMsg: false,
          autoFocus: false,
        },
        street: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        city: {
          value: [],
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        state: {
          value: [],
          valid: true,
          touched: false,
          required: true,
          showErrorMsg: false,
        },
        pincode: {
          value: null,
          valid: true,
          required: false,
          touched: false,
          invalidLength: null,
          showErrorMsg: false,
        },
      },
      branchControls: {
        alternateContactPerson: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        wa: {
          value: null,
          valid: false,
          touched: false,
          invalidPhone: null,
          required: false,
          showErrorMsg: false,
          autoFocus: false,
        },
        street: {
          value: null,
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        city: {
          value: [],
          valid: true,
          touched: false,
          required: false,
          showErrorMsg: false,
        },
        state: {
          value: [],
          valid: true,
          touched: false,
          required: true,
          showErrorMsg: false,
        },
        pincode: {
          value: null,
          valid: true,
          required: false,
          touched: false,
          invalidLength: null,
          showErrorMsg: false,
        },
      },
      businessCardObj: {
        title: "OTHER BUSINESS DETAILS",
        edit: "isBusinessEditActive",
        fields: [
          {
            title: "Email Address",
            value: null,
          },
          {
            title: "Owner’s Phone Number",
            value: null,
          },
          {
            title: "GST Number",
            value: null,
          },
          {
            title: "PAN Number",
            value: null,
          },
          {
            title: "Address",
            value: null,
          },
          {
            title: "State",
            value: null,
          },
          {
            title: "City",
            value: null,
          },
          {
            title: "Pin Code",
            value: null,
          },
        ],
      },
      contactCardObj: {
        title: "PRIMARY CONTACT DETAILS",
        edit: "isContactEditActive",
        fields: [
          {
            title: "Contact Person",
            value: null,
            class: "title-case",
          },
          {
            title: "Designation",
            value: null,
          },
          {
            title: "Contact Number",
            value: null,
          },
          {
            title: "Whatsapp Number",
            value: null,
          },
        ],
      },
      socialCardObj: {
        title: "SOCIAL DETAILS",
        edit: "isSocialEditActive",
        fields: [
          {
            title: "Birth Date",
            value: null,
          },
          {
            title: "Anniversary Date",
            value: null,
          },
          {
            title: "Facebook Page",
            value: null,
          },
          {
            title: "Instagram Page",
            value: null,
          },
          {
            title: "Website",
            value: null,
          },
          {
            title: "Store Google My Business Link",
            value: null,
          },
          {
            title: "Store Google My Business Rating Link",
            value: null,
          },
        ],
      },
      branchCardObj: {
        title: "DETAILS",
        edit: "isBranchEditActive",
        fields: [
          {
            title: "Contact Person Name",
            value: null,
            class: "title-case",
          },
          {
            title: "Whatsapp Number",
            value: null,
          },
          {
            title: "Street Address",
            value: null,
            class: "title-case",
          },
          {
            title: "State",
            value: null,
            class: "title-case",
          },
          {
            title: "City",
            value: null,
            class: "title-case",
          },
          {
            title: "Pincode",
            value: null,
          },
        ],
      },
      subDealerCardObj: {
        title: "DETAILS",
        edit: "isSubDealerEditActive",
        fields: [
          {
            title: "Business Name",
            value: null,
            class: "title-case",
          },
          {
            title: "Owner Name",
            value: null,
            class: "title-case",
          },
          {
            title: "Whatsapp Number",
            value: null,
          },
          {
            title: "Street Address",
            value: null,
            class: "title-case",
          },
          {
            title: "State",
            value: null,
            class: "title-case",
          },
          {
            title: "City",
            value: null,
            class: "title-case",
          },
          {
            title: "Pincode",
            value: null,
          },
          {
            title: "PAN Number",
            value: null,
            class: "capital-case",
          },
          {
            title: "GST Number",
            value: null,
            class: "capital-case",
          },
        ],
      },
      birthDate: null,
      anniversaryDate: null,
      detailObj: {
        email: "arpan@gmail.com",
        phone: "+91 46546516511",
        gst: null,
        pan: null,
        alPerson: null,
        alDesignation: null,
        alNumber: null,
        wa: null,
        birthDate: "2019/12/29",
        anniversaryDate: null,
        facebook: null,
        instagram: null,
      },
      activeFragment: null,
      isBusinessFormValid: false,
      isContactFormValid: false,
      isSocialFormValid: false,
      isSubDealerFormValid: false,
      isBranchFormValid: false,
      isBusinessFormLoading: false,
      isContatFormLoading: false,
      isSocialFormLoading: false,
      isSubDealerFormLoading: false,
      isBranchFormLoading: false,
      userRole,
      mobileNumber: null,
      states: [],
      cities: [],
      popupTitles: [],
    };
    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.VIEW_SENDER_ID)
    ) {
      this.state.businessCardObj.fields.push({
        title: "Sender ID",
        value: null,
        sender_id_note: `Your Sender ID status is in progress, Once it's approved then you will receive notification for the same`,
      });
    }
    this.childBusinessDetail = React.createRef();
  }

  async componentDidMount() {
    GoogleAnalticsService.pageView("Profile View");
    window.addEventListener("resize", this.resizeWindow);
    const userDetail = StorageService.getUserDetail();
    const { type: userRole } = userDetail;
    if (
      userRole === Roles.SUB_DEALER_ROLE ||
      userRole === Roles.BRANCH_ROLE ||
      userDetail.customer_class === "na" ||
      userDetail.customer_class === "hy"
    ) {
      await this.getState();
    }
    this.getUserDetail();
    this.setState({ userRole });
    this.getUserPopupTitles();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  getUserDetail = (dealerId) => {
    this.setState({ isLoader: true });
    UserService.getUserInfo()
      .then((data) => {
        const userData = data.data.data;
        this.setState({ userInfo: userData })
        this.props.setUserDetail(userData);
        this.setPageValues(userData);
        // uncomment this if block
        this.setState({ isLoader: false });
        // if (userData.is_login_first_time === true) {
        this.setState({ redirectToOnBoarding: true });
        this.setState({ isLoading: false });
        // }
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({ isLoader: false });
      });
  };

  setPageValues = async (uDetail) => {
    const {
      email_1,
      telephone_no_1,
      gst_number,
      pan_number,
      image_url,
      alternate_contact_person,
      alternate_contact_number,
      designation,
      whats_app_number,
      birth_date,
      anniversary_date,
      facebook_url,
      instagram_url,
      sap_id,
      business_name,
      owner_name,
      address_line_1,
      address_line_2,
      pin_code = null,
      address_line_3,
      address_line_4,
      is_update_profile_request_send,
      website: web_from_api,
      state,
      city,
      whats_app_number_user,
      nick_name,
      sender_id,
    } = uDetail;

    let {
      controls,
      sapId,
      address,
      businessName,
      profileImage,
      businessCardObj,
      contactCardObj,
      socialCardObj,
      ownerName,
      birthDate: bDayMaster,
      anniversaryDate: aDayMaster,
      mobileNumber,
      branchCardObj,
      subDealerCardObj,
      states,
      cities,
      branchControls,
      subDealerControls,
      userRole,
    } = this.state;

    mobileNumber = telephone_no_1;
    profileImage = image_url;
    sapId = sap_id;
    businessName = business_name;
    ownerName =
      userRole === Roles.DEALER_ROLE || userRole === Roles.DISTRIBUTOR_ROLE
        ? owner_name
        : nick_name;

    if (
      (userRole === Roles.DEALER_ROLE &&
        this.state.userDetail.customer_class !== "na" &&
        this.state.userDetail.customer_class !== "hy") ||
      userRole === Roles.DISTRIBUTOR_ROLE
    ) {
      let {
        email,
        phone,
        gst,
        pan,
        alPerson,
        alNumber,
        alDesignation,
        wa,
        birthDate,
        anniversaryDate,
        facebook,
        instagram,
        isUpdateProfileRequestSend,
        website,
      } = controls;

      email.value = email_1;
      phone.value = telephone_no_1;
      // phone.value = null;
      gst.value = gst_number;
      pan.value = pan_number;

      alPerson.value = alternate_contact_person;
      alNumber.value = alternate_contact_number;
      alDesignation.value = designation;
      wa.value = whats_app_number;
      controls.senderId.value = sender_id;

      // birthDate.value = null;
      // anniversaryDate.value = null;
      bDayMaster = birth_date;
      aDayMaster = anniversary_date;
      birthDate.value = birth_date ? new Date(birth_date) : null;
      anniversaryDate.value = anniversary_date
        ? new Date(anniversary_date)
        : null;
      facebook.value = facebook_url;
      instagram.value = instagram_url;
      website.value = web_from_api;
      controls.google_my_business_link.value = uDetail.google_my_business_link;
      controls.google_my_business_rating_link.value =
        uDetail.google_my_business_rating_link;

      controls.state.value = uDetail.state;
      controls.city.value = uDetail.city;
      controls.pin_code.value = uDetail.pin_code;
      controls.address.value = uDetail.address_line_1;

      address = "";
      if (address_line_1) {
        address = `${address_line_1}`;
      }
      if (address_line_2) {
        address = `${address}, ${address_line_2}`;
      }
      if (address_line_3) {
        address = `${address}, ${address_line_3}`;
      }
      if (address_line_4) {
        address = `${address}, ${address_line_4}`;
      }

      const businessFields = businessCardObj.fields;
      businessFields[0].value = email_1;
      businessFields[1].value = telephone_no_1;
      businessFields[2].value = gst_number;
      businessFields[3].value = pan_number;
      businessFields[4].value = address_line_1;
      businessFields[5].value = state;
      businessFields[6].value = city;
      businessFields[7].value = pin_code;

      if (
        this.state.userDetail.permissions &&
        this.state.userDetail.permissions.includes(Permissions.VIEW_SENDER_ID)
      ) {
        businessFields[8].value = sender_id;
      }

      const contactFields = contactCardObj.fields;
      contactFields[0].value = alternate_contact_person;
      contactFields[1].value = designation;
      contactFields[2].value = alternate_contact_number;
      contactFields[3].value = whats_app_number;

      const socialFiels = socialCardObj.fields;
      socialFiels[0].value = birth_date ? this.formatDate(birth_date) : null;
      socialFiels[1].value = anniversary_date
        ? this.formatDate(anniversary_date)
        : null;
      socialFiels[2].value = facebook_url;
      socialFiels[3].value = instagram_url;
      socialFiels[4].value = web_from_api;
      socialFiels[5].value = uDetail.google_my_business_link;
      socialFiels[6].value = uDetail.google_my_business_rating_link;

      isUpdateProfileRequestSend = is_update_profile_request_send;

      this.setState({
        controls,
        businessCardObj,
        contactCardObj,
        socialCardObj,
        isUpdateProfileRequestSend,
        bDayMaster,
        aDayMaster,
        address,
        is_disable_sender_id: controls.senderId.value ? true : false,
      });

      this.businessFormValidation(true);
      this.contactFormValidation(true);
      this.socialFormValidation(true);
    } else if (userRole === Roles.SUB_DEALER_ROLE) {
      const {
        businessName: subDealerBusinessName,
        ownerName: subDealerOwnerName,
        wa: subDealerWa,
        street: subDealerStreet,
        state: subDealerState,
        city: subDealerCity,
        pan: subDealerPan,
        gst: subDealerGst,
        pincode: subDealerPincode,
      } = subDealerControls;

      const selectedStateIndex = states.findIndex(
        (s) => s.name.toLowerCase() === state.toLowerCase()
      );
      const currentState = states[selectedStateIndex];
      const cities = await this.getCity(currentState.state_id);

      const selectedCityIndex = cities.findIndex(
        (c) => c.name.toLowerCase() === city.toLowerCase()
      );

      subDealerBusinessName.value = business_name;
      subDealerOwnerName.value = owner_name;
      subDealerWa.value = whats_app_number_user;
      subDealerStreet.value = address_line_1;
      subDealerState.value =
        selectedStateIndex !== -1 ? [states[selectedStateIndex]] : [];
      subDealerCity.value =
        selectedCityIndex !== -1 ? [cities[selectedCityIndex]] : [];
      subDealerPan.value = pan_number;
      subDealerGst.value = gst_number;
      subDealerPincode.value = pin_code;

      const subDealerFields = subDealerCardObj.fields;
      subDealerFields[0].value = business_name;
      subDealerFields[1].value = owner_name;
      subDealerFields[2].value = whats_app_number_user;
      subDealerFields[3].value = address_line_1;
      // selectedStateIndex = states.findIndex(s => s.name.toLowerCase() === state.toLowerCase());
      subDealerFields[4].value = state;
      // selectedCityIndex = cities.findIndex(c => c.name.toLowerCase() === city.toLowerCase());
      subDealerFields[5].value = city;
      subDealerFields[6].value = pin_code;
      subDealerFields[7].value = pan_number;
      subDealerFields[8].value = gst_number;

      this.setState({ subDealerCardObj, subDealerControls });

      this.handleSubDealerValidation(true);
    } else if (
      userRole === Roles.BRANCH_ROLE ||
      this.state.userDetail.customer_class === "na" ||
      this.state.userDetail.customer_class === "hy"
    ) {
      const {
        alternateContactPerson,
        wa: branchWaNumber,
        street: branchStreet,
        state: branchState,
        city: branchCity,
        pincode: branchPincode,
      } = branchControls;
      const selectedStateIndex = states.findIndex(
        (s) => s.name.toLowerCase() === state.toLowerCase()
      );
      // const currentState = states[selectedStateIndex];
      //const cities = await this.getCity(currentState.state_id);

      const selectedCityIndex = cities.findIndex(
        (c) => c.name.toLowerCase() === city.toLowerCase()
      );

      alternateContactPerson.value = alternate_contact_person;
      branchWaNumber.value = whats_app_number_user;
      branchStreet.value = address_line_1;
      branchState.value =
        selectedStateIndex !== -1 ? [states[selectedStateIndex]] : [];
      branchCity.value =
        selectedCityIndex !== -1 ? [cities[selectedCityIndex]] : [];
      branchPincode.value = pin_code;

      const branchFields = branchCardObj.fields;
      branchFields[0].value = alternate_contact_person;
      branchFields[1].value = whats_app_number_user;
      branchFields[2].value = address_line_1;
      // let selectedStateIndex = states.findIndex(s => s.name.toLowerCase() === state.toLowerCase());
      branchFields[3].value = state;
      // let selectedCityIndex = cities.findIndex(c => c.name.toLowerCase() === city.toLowerCase());
      branchFields[4].value = city;
      branchFields[5].value = pin_code;
      this.setState({ branchCardObj, branchControls });
      this.handleBranchValidation(true);
    }
    this.setState({
      sapId,
      businessName,
      ownerName,
      mobileNumber,
      profileImage,
    });
  };

  /**
   * @param firstTime when form loads for first time directly error showing makes bad experience
   *            so do not show any message for null values but disable save button if
   *            there is something missing or some error.
   */
  businessFormValidation = (firstTime) => {
    let { controls, isBusinessFormValid } = this.state;
    let {
      email,
      phone,
      gst,
      pan,
      senderId,
      address,
      city,
      state,
      pin_code,
    } = controls;
    if (firstTime === true || email.touched === true) {
      email = Validation.notNullValidator(email);
      email = Validation.emailValidator(email);
      email.valid = !(email.nullValue || email.invalidEmail);
      if (
        (firstTime && email.invalidEmail) ||
        (email.touched && email.valid === false)
      ) {
        email.showErrorMsg = true;
      } else {
        email.showErrorMsg = false;
      }
    }

    if (firstTime === true || phone.touched === true) {
      phone = Validation.notNullValidator(phone);
      phone = Validation.validatePhoneNumber(phone);
      phone.valid = !(phone.nullValue || phone.invalidPhone);
      if (
        (firstTime && phone.invalidPhone) ||
        (phone.touched && phone.valid === false)
      ) {
        phone.showErrorMsg = true;
      } else {
        phone.showErrorMsg = false;
      }
    }

    /* if (state.touched === true || firstTime) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (city.touched === true || firstTime) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    } */

    if (address.touched === true || firstTime) {
      address = Validation.notNullValidator(address);
      address.valid = !address.nullValue;
      if (address.valid === false) {
        address.showErrorMsg = true;
      } else {
        address.showErrorMsg = false;
      }
    }

    if (pin_code.touched === true || firstTime) {
      pin_code = Validation.notNullValidator(pin_code);
      pin_code = Validation.validateLength(pin_code, 6);
      pin_code.valid = !(pin_code.invalidLength || pin_code.nullValue);
      if (pin_code.valid === false) {
        pin_code.showErrorMsg = true;
      } else {
        pin_code.showErrorMsg = false;
      }
    }

    if (firstTime === true || gst.touched === true) {
      gst = Validation.validateLength(gst, 15);
      gst.valid = !gst.invalidLength;
      if (
        (firstTime && gst.invalidLength) ||
        (gst.touched && gst.valid === false)
      ) {
        gst.showErrorMsg = true;
      } else {
        gst.showErrorMsg = false;
      }
    }
    if (firstTime === true || senderId.touched === true) {
      senderId = Validation.notNullValidator(senderId);
      if (senderId.nullValue) {
        senderId.showErrorMsg = false;
        senderId.valid = true;
      } else {
        senderId = Validation.validateAlphabetic(senderId);
        if (senderId.invalidAlphabetic === true) {
          senderId.invalidMinLength = false;
          senderId.showErrorMsg = true;
          senderId.valid = false;
        } else {
          senderId.invalidAlphabetic = false;
          senderId = Validation.minLengthValidator(senderId, 6);
          senderId = Validation.maxLengthValidator(senderId, 6);
          senderId.valid =
            !senderId.invalidMinLength && !senderId.invalidLength;
          if (
            (firstTime && senderId.invalidMinLength) ||
            (senderId.touched && senderId.valid === false)
          ) {
            senderId.showErrorMsg = true;
          } else {
            senderId.showErrorMsg = false;
          }
        }
      }
    }

    if (firstTime === true || pan.touched === true) {
      pan = Validation.validateLength(pan, 10);
      pan.valid = !pan.invalidLength;
      if (
        (firstTime && pan.invalidLength) ||
        (pan.touched && pan.valid === false)
      ) {
        pan.showErrorMsg = true;
      } else {
        pan.showErrorMsg = false;
      }
    }

    if (
      email.valid === true &&
      phone.valid === true &&
      gst.valid === true &&
      pan.valid === true &&
      senderId.valid === true &&
      pin_code.valid === true &&
      state.valid === true &&
      city.valid === true &&
      address.valid === true
    ) {
      isBusinessFormValid = true;
    } else {
      isBusinessFormValid = false;
    }
    this.setState({ controls, isBusinessFormValid });
  };

  /**
   * @param firstTime when form loads for first time directly error showing makes bad experience
   *            so do not show any message for null values but disable save button if
   *            there is something missing or some error.
   */
  contactFormValidation = (firstTime) => {
    // let firstTime = true;

    let { controls, isContactFormValid } = this.state;
    let { alPerson, alDesignation, alNumber, wa } = controls;

    if (firstTime === true || alPerson.touched === true) {
      alPerson.valid = !alPerson.nullValue; // it will be removed
    }

    if (firstTime === true || alNumber.touched === true) {
      alNumber = Validation.validatePhoneNumber(alNumber);
      alNumber.valid = !alNumber.invalidPhone;
      if (
        (firstTime && alNumber.invalidPhone) ||
        (alNumber.touched && alNumber.valid === false)
      ) {
        alNumber.showErrorMsg = true;
      } else {
        alNumber.showErrorMsg = false;
      }
    }

    if (firstTime === true || alDesignation.touched === true) {
      alDesignation.valid = !alDesignation.nullValue;
    }

    if (firstTime === true || wa.touched === true) {
      wa = Validation.validatePhoneNumber(wa);
      wa = Validation.notNullValidator(wa);
      wa.valid = !(wa.invalidPhone || wa.nullValue);
      if (
        (firstTime && wa.invalidPhone) ||
        (wa.touched && wa.valid === false)
      ) {
        wa.showErrorMsg = true;
      } else {
        wa.showErrorMsg = false;
      }
    }

    if (
      alPerson.valid === true &&
      alNumber.valid === true &&
      alDesignation.valid === true &&
      wa.valid === true
    ) {
      isContactFormValid = true;
    } else {
      isContactFormValid = false;
    }
    this.setState({ controls, isContactFormValid });
  };

  /**
   * @param firstTime when form loads for first time directly error showing makes bad experience
   *            so do not show any message for null values but disable save button if
   *            there is something missing or some error.
   */
  socialFormValidation = (firstTime) => {
    let { controls, isSocialFormValid } = this.state;
    const {
      birthDate,
      instagram,
      facebook,
      anniversaryDate,
      website,
    } = controls;

    if (firstTime === true || birthDate.touched === true) {
      if (
        birthDate.value === null ||
        birthDate.value === undefined ||
        birthDate.value === ""
      ) {
        birthDate.nullValue = true;
      } else {
        birthDate.nullValue = false;
      }
      // if (typeof birthDate.value !== 'object') {
      //   birthDate.invalidDate = true;
      // }

      birthDate.valid = !birthDate.nullValue;
    }

    if (firstTime === true || anniversaryDate.touched === true) {
      anniversaryDate.valid = !anniversaryDate.nullValue;
    }

    if (firstTime === true || facebook.touched === true) {
      facebook.valid = !facebook.nullValue;
    }

    if (firstTime === true || instagram.touched === true) {
      instagram.valid = !instagram.nullValue;
    }

    if (firstTime === true || website.touched === true) {
      // website = Validation.notNullValidator(website);
      website.valid = !website.nullValue;
      if (website.touched && website.valid === false) {
        website.showErrorMsg = true;
      } else {
        website.showErrorMsg = false;
      }
    }

    if (
      birthDate.valid === true &&
      anniversaryDate.valid === true &&
      facebook.valid === true &&
      instagram.valid === true &&
      website.valid === true
    ) {
      isSocialFormValid = true;
    } else {
      isSocialFormValid = false;
    }
    this.setState({ controls, isSocialFormValid });
  };

  /**
   * @param firstTime when form loads for first time directly error showing makes bad experience
   *            so do not show any message for null values but disable save button if
   *            there is something missing or some error.
   */
  handleSubDealerValidation = (firstTime, isSubmit = false) => {
    // let firstTime = true;

    let { subDealerControls, isSubDealerFormValid } = this.state;
    let {
      businessName,
      ownerName,
      wa,
      street,
      state,
      city,
      gst,
      pan,
      pincode,
    } = subDealerControls;

    if (firstTime === true || businessName.touched === true || isSubmit) {
      businessName = Validation.notNullValidator(businessName);
      // email = Validation.emailValidator(email);
      businessName.valid = !businessName.nullValue;
      if ((businessName.touched || isSubmit) && businessName.valid === false) {
        businessName.showErrorMsg = true;
      } else {
        businessName.showErrorMsg = false;
      }
    }

    if (firstTime === true || ownerName.touched === true || isSubmit) {
      ownerName = Validation.notNullValidator(ownerName);
      // email = Validation.emailValidator(email);
      ownerName.valid = !ownerName.nullValue;
      if ((ownerName.touched || isSubmit) && ownerName.valid === false) {
        ownerName.showErrorMsg = true;
      } else {
        ownerName.showErrorMsg = false;
      }
    }

    if (firstTime === true || wa.touched === true || isSubmit) {
      wa = Validation.notNullValidator(wa);
      wa = Validation.validatePhoneNumber(wa);
      wa.valid = !(wa.nullValue || wa.invalidPhone);
      if (
        (firstTime && wa.invalidPhone) ||
        ((wa.touched || isSubmit) && wa.valid === false)
      ) {
        wa.showErrorMsg = true;
      } else {
        wa.showErrorMsg = false;
      }
    }

    if (firstTime === true || street.touched === true || isSubmit) {
      street = Validation.notNullValidator(street);
      // email = Validation.emailValidator(email);
      street.valid = !street.nullValue;
      if ((street.touched || isSubmit) && street.valid === false) {
        street.showErrorMsg = true;
      } else {
        street.showErrorMsg = false;
      }
    }

    if (firstTime === true || state.touched === true || isSubmit) {
      if (state.value.length > 0) {
        state.valid = true;
      } else {
        state.valid = false;
      }
      if ((state.touched || isSubmit) && state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (firstTime === true || city.touched === true || isSubmit) {
      if (city.value.length > 0) {
        city.valid = true;
      } else {
        city.valid = false;
      }
      if ((city.touched || isSubmit) && city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (firstTime === true || pincode.touched === true || isSubmit) {
      pincode = Validation.notNullValidator(pincode);
      pincode = Validation.validateLength(pincode, 6);
      pincode = Validation.validateNumber(pincode);
      pincode.valid = !(
        pincode.nullValue ||
        pincode.invalidLength ||
        pincode.invalidNumber
      );
      if (
        (firstTime && (pincode.invalidLength || pincode.invalidNumber)) ||
        ((pincode.touched || isSubmit) && pincode.valid === false)
      ) {
        pincode.showErrorMsg = true;
      } else {
        pincode.showErrorMsg = false;
      }
    }

    if (firstTime === true || gst.touched === true || isSubmit) {
      // gst = Validation.validateLength(gst, 15);
      gst = Validation.validateGST(gst);
      gst.valid = !gst.invalidGST;
      if (
        (firstTime && gst.invalidGST) ||
        ((gst.touched || isSubmit) && gst.valid === false)
      ) {
        gst.showErrorMsg = true;
      } else {
        gst.showErrorMsg = false;
      }
    }

    if (firstTime === true || pan.touched === true || isSubmit) {
      pan = Validation.validateLength(pan, 10);
      pan = Validation.validatePAN(pan);
      pan.valid = !pan.invalidPAN;
      if (
        (pan.value === null || pan.value === "") &&
        (gst.value === null || gst.value === "")
      ) {
        pan.nullValue = true;
        pan.valid = false;
      } else {
        pan.nullValue = null;
      }

      if (
        (firstTime && pan.invalidLength) ||
        ((pan.touched || isSubmit) && pan.valid === false)
      ) {
        pan.showErrorMsg = true;
      } else {
        pan.showErrorMsg = false;
      }
    }

    if (
      businessName.valid === true &&
      ownerName.valid === true &&
      wa.valid === true &&
      street.valid === true &&
      state.valid === true &&
      city.valid === true &&
      gst.valid === true &&
      pan.valid === true &&
      pincode.valid === true
    ) {
      isSubDealerFormValid = true;
    } else {
      isSubDealerFormValid = false;
    }
    this.setState({ subDealerControls, isSubDealerFormValid });
    return isSubDealerFormValid;
  };

  /**
   * @param firstTime when form loads for first time directly error showing makes bad experience
   *            so do not show any message for null values but disable save button if
   *            there is something missing or some error.
   */
  handleBranchValidation = (firstTime, isSubmit = false) => {
    // let firstTime = true;

    let { branchControls, isBranchFormValid } = this.state;
    let {
      wa,
      alternateContactPerson,
      street,
      state,
      city,
      pincode,
    } = branchControls;

    if (
      firstTime === true ||
      alternateContactPerson.touched === true ||
      isSubmit
    ) {
      alternateContactPerson = Validation.notNullValidator(
        alternateContactPerson
      );
      // email = Validation.emailValidator(email);
      alternateContactPerson.valid = !alternateContactPerson.nullValue;
      if (
        (alternateContactPerson.touched || isSubmit) &&
        alternateContactPerson.valid === false
      ) {
        alternateContactPerson.showErrorMsg = true;
      } else {
        alternateContactPerson.showErrorMsg = false;
      }
    }

    if (firstTime === true || wa.touched === true || isSubmit) {
      wa = Validation.notNullValidator(wa);
      wa = Validation.validatePhoneNumber(wa);
      wa.valid = !(wa.nullValue || wa.invalidPhone);
      if (
        (firstTime && wa.invalidPhone) ||
        ((wa.touched || isSubmit) && wa.valid === false)
      ) {
        wa.showErrorMsg = true;
      } else {
        wa.showErrorMsg = false;
      }
    }

    if (firstTime === true || street.touched === true || isSubmit) {
      street = Validation.notNullValidator(street);
      // email = Validation.emailValidator(email);
      street.valid = !street.nullValue;
      if ((street.touched || isSubmit) && street.valid === false) {
        street.showErrorMsg = true;
      } else {
        street.showErrorMsg = false;
      }
    }

    if (firstTime === true || state.touched === true || isSubmit) {
      if (state.value.length > 0) {
        state.valid = true;
      } else {
        state.valid = false;
      }
      if ((state.touched || isSubmit) && state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (firstTime === true || city.touched === true || isSubmit) {
      if (city.value.length > 0) {
        city.valid = true;
      } else {
        city.valid = false;
      }
      if ((city.touched || isSubmit) && city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (firstTime === true || pincode.touched === true || isSubmit) {
      pincode = Validation.notNullValidator(pincode);
      pincode = Validation.validateLength(pincode, 6);
      pincode = Validation.validateNumber(pincode);
      pincode.valid = !(
        pincode.nullValue ||
        pincode.invalidLength ||
        pincode.invalidNumber
      );
      if (
        (firstTime && (pincode.invalidLength || pincode.invalidNumber)) ||
        ((pincode.touched || isSubmit) && pincode.valid === false)
      ) {
        pincode.showErrorMsg = true;
      } else {
        pincode.showErrorMsg = false;
      }
    }

    if (
      alternateContactPerson.valid === true &&
      wa.valid === true &&
      street.valid === true &&
      state.valid === true &&
      city.valid === true &&
      pincode.valid === true
    ) {
      isBranchFormValid = true;
    } else {
      isBranchFormValid = false;
    }
    this.setState({ branchControls, isBranchFormValid });
    return isBranchFormValid;
  };

  handleUploadImage = (event) => {
    const file = event.target.files[0];
    this.setState({ isImageUpdating: true });
    const obj = {
      file,
    };

    UserService.uploadImage(obj)
      .then((data) => {
        this.props.isProfileUpdate(true);
        this.setState({
          profileImage: URL.createObjectURL(file),
          isImageUpdating: false,
        });
        GoogleAnalticsService.event("Profile", "Photo Upload");
      })
      .catch((e) => {
        this.setState({
          profileImage: null,
          isImageUpdating: false,
        });
      });
  };

  activeFragment = (fragment) => {
    this.setState({ activeFragment: fragment });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    let controlValue = e.target.value;
    const { controls } = this.state;
    if (controlValue && controlName === "senderId") {
      controlValue = controlValue.toUpperCase();
    }
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls }, () => { });

    if (controlName === "state") {
      controls.city.value = "";
      controls.city.touched = true;
    }
    if (controlName === "state") {
      if (this.childBusinessDetail.current) {
        this.childBusinessDetail.current.getCity(controls.state.value);
        this.businessFormValidation();
      }
    }
    // this.handleValidation();
    if (this.state.activeFragment === "business") {
      this.businessFormValidation();
    }
    if (this.state.activeFragment === "contact") {
      this.contactFormValidation();
    }
    if (this.state.activeFragment === "social") {
      this.socialFormValidation();
    }
  };

  handleSubDealerInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { subDealerControls } = this.state;
    subDealerControls[controlName].value = controlValue;
    subDealerControls[controlName].touched = true;
    this.setState({ subDealerControls });
    this.handleSubDealerValidation();
  };

  handleBranchInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { branchControls } = this.state;
    branchControls[controlName].value = controlValue;
    branchControls[controlName].touched = true;
    this.setState({ branchControls });
    this.handleBranchValidation();
  };

  handleBusinessSave = (e) => {
    e.preventDefault();
    const { controls, businessCardObj } = this.state;
    const {
      email,
      phone,
      gst,
      pan,
      senderId,
      city,
      state,
      address,
      pin_code,
    } = controls;
    const arr = businessCardObj.fields;
    const obj = {
      email: email.value,
      phoneNumber: phone.value, // only numbers
      gstNumber: gst.value ? gst.value.toUpperCase() : null, // 15 digit
      panNumber: pan.value ? pan.value.toUpperCase() : null, // 10 digit
      senderId: senderId.value ? senderId.value : null,
      city: city.value ? city.value : null,
      state: state.value ? state.value : null,
      address: address.value ? address.value : null,
      pin_code: pin_code.value ? pin_code.value : null,
    };
    if (email.valid === false) {
      return;
    }
    this.setState({ isBusinessFormLoading: true });
    UserService.updateUserInfo(obj)
      .then((data) => {
        const userData = data.data.data;
        this.props.setUserDetail(userData);
        arr[0].value = email.value;
        arr[1].value = phone.value;
        arr[2].value = gst.value ? gst.value.toUpperCase() : null;
        arr[3].value = pan.value ? pan.value.toUpperCase() : null;
        arr[4].value = address.value;
        arr[5].value = state.value;
        arr[6].value = city.value;
        arr[7].value = pin_code.value;
        if (
          this.state.userDetail.permissions &&
          this.state.userDetail.permissions.includes(Permissions.VIEW_SENDER_ID)
        ) {
          arr[8].value = senderId.value ? senderId.value : null;
        }
        this.setState({
          address: address.value,
          businessCardObj,
          isBusinessEditActive: false,
          isBusinessFormLoading: false,
        });
        GoogleAnalticsService.event(
          "Other Business Detail",
          "Update Business Detail"
        );
      })
      .catch((e) => {
        const err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isBusinessFormLoading: false });
        ModalService.openAlert("Update Profile", message, "error");
      });
  };

  handleContactSave = (e) => {
    e.preventDefault();
    const { controls, contactCardObj } = this.state;
    const { alPerson, alDesignation, alNumber, wa } = controls;
    const arr = contactCardObj.fields;

    const obj = {
      alternateContactPerson: alPerson.value,
      alternateContactNumber: alNumber.value,
      designation: alDesignation.value,
      whatsappNumber: wa.value,
    };
    this.setState({ isContatFormLoading: true });

    UserService.updateUserInfo(obj)
      .then((data) => {
        const userData = data.data.data;
        this.props.setUserDetail(userData);
        arr[0].value = alPerson.value;
        arr[1].value = alDesignation.value;
        arr[2].value = alNumber.value;
        arr[3].value = wa.value;
        this.setState({
          contactCardObj,
          isContactEditActive: false,
          isContatFormLoading: false,
        });
        GoogleAnalticsService.event(
          "Other Business Detail",
          "Update Primary Contact Detail"
        );
      })
      .catch((e) => {
        const err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isContatFormLoading: false });
        ModalService.openAlert("Update Profile", message, "error");
      });
  };

  handleSocialSave = (e) => {
    e.preventDefault();
    let { controls, socialCardObj, bDayMaster, aDayMaster } = this.state;
    const {
      birthDate,
      anniversaryDate,
      facebook,
      instagram,
      website,
    } = controls;
    const arr = socialCardObj.fields;

    const obj = {
      birthDate: birthDate.value,
      anniversaryDate: anniversaryDate.value,
      facebookUrl: facebook.value,
      instagramUrl: instagram.value,
      website: website.value,
      google_my_business_link: controls.google_my_business_link.value,
      google_my_business_rating_link:
        controls.google_my_business_rating_link.value,
    };
    this.setState({ isSocialFormLoading: true });

    UserService.updateUserInfo(obj)
      .then((data) => {
        const userData = data.data.data;
        this.props.setUserDetail(userData);
        bDayMaster = birthDate.value;
        aDayMaster = anniversaryDate.value;
        arr[0].value = birthDate.value
          ? this.formatDate(birthDate.value)
          : null;
        arr[1].value = anniversaryDate.value
          ? this.formatDate(anniversaryDate.value)
          : null;
        arr[2].value = facebook.value;
        arr[3].value = instagram.value;
        arr[4].value = website.value;
        arr[5].value = controls.google_my_business_link.value;
        arr[6].value = controls.google_my_business_rating_link.value;
        this.setState({
          socialCardObj,
          isSocialEditActive: false,
          aDayMaster,
          bDayMaster,
          isSocialFormLoading: false,
        });
        GoogleAnalticsService.event("Social Detail", "Update Social Detail");
      })
      .catch((e) => {
        const err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isSocialFormLoading: false });
        ModalService.openAlert("Update Profile", message, "error");
      });
  };

  updateProfileSubDealer = (e) => {
    e.preventDefault();

    const { subDealerControls, subDealerCardObj } = this.state;
    const {
      businessName,
      ownerName,
      wa,
      street,
      gst,
      pan,
      state,
      city,
      pincode,
    } = subDealerControls;
    const arr = subDealerCardObj.fields;

    const isFormValid = this.handleSubDealerValidation(false, true);
    if (isFormValid === false) {
      return;
    }

    const obj = {
      businessName: businessName.value,
      ownerName: ownerName.value, // only numbers
      whatsappNumberUser: wa.value,
      pinCode: pincode.value,
      address: street.value,
      state: state.value[0].name.toLowerCase(),
      city: city.value[0].name.toLowerCase(),

      gstNumber: gst.value ? gst.value.toUpperCase() : null, // 15 digit
      panNumber: pan.value ? pan.value.toUpperCase() : null, // 10 digit

      isLoginFirstTime: false,
    };

    this.setState({ isSubDealerFormLoading: true });
    UserService.updateUserInfo(obj)
      .then((data) => {
        const userDetail = StorageService.getUserDetail();
        userDetail.is_login_first_time = false;
        const userData = data.data.data;
        this.props.setUserDetail(userData);

        arr[0].value = businessName.value;
        arr[1].value = ownerName.value;
        arr[2].value = wa.value;
        arr[3].value = street.value;
        arr[4].value = state.value[0].name;
        arr[5].value = city.value[0].name;
        arr[6].value = pincode.value;
        arr[7].value = pan.value;
        arr[8].value = gst.value;

        StorageService.setUserDetail(userDetail);
        this.setState({
          isSubDealerFormLoading: false,
          subDealerCardObj,
          isSubDealerEditActive: false,
        });
        GoogleAnalticsService.event("Profile", "Submit Detail");
      })
      .catch((e) => {
        const err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isSubDealerFormLoading: false });
        ModalService.openAlert("Profile", message, "error");
      });
  };

  updateProfileBranch = (e) => {
    e.preventDefault();
    const isFormValid = this.handleBranchValidation(false, true);
    if (isFormValid === false) {
      return;
    }
    const { branchControls, branchCardObj } = this.state;
    const {
      wa,
      alternateContactPerson,
      street,
      state,
      city,
      pincode,
    } = branchControls;

    const arr = branchCardObj.fields;

    const obj = {
      alternateContactPerson: alternateContactPerson.value,
      whatsappNumberUser: wa.value,
      pinCode: pincode.value,
      address: street.value,
      state: state.value[0].name.toLowerCase(),
      city: city.value[0].name.toLowerCase(),

      isLoginFirstTime: false,
    };

    this.setState({ isBranchFormLoading: true });
    UserService.updateUserInfo(obj)
      .then((data) => {
        const userDetail = StorageService.getUserDetail();
        userDetail.is_login_first_time = false;
        const userData = data.data.data;
        this.props.setUserDetail(userData);
        arr[0].value = alternateContactPerson.value;
        arr[1].value = wa.value;
        arr[2].value = street.value;
        arr[3].value = state.value[0].name;
        arr[4].value = city.value[0].name;
        arr[5].value = pincode.value;

        StorageService.setUserDetail(userDetail);
        this.setState({
          isBranchFormLoading: false,
          branchCardObj,
          isBranchEditActive: false,
        });
        GoogleAnalticsService.event("Profile", "Submit Profile");
      })
      .catch((e) => {
        console.error("e", e);
        const err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        this.setState({ isBranchFormLoading: false });
        ModalService.openAlert("Profile", message, "error");
      });
  };

  handleBusinessCancel = () => {
    const { controls, businessCardObj } = this.state;
    const { email, phone, gst, pan, state, city, address, pin_code } = controls;
    const arr = businessCardObj.fields;
    email.value = arr[0].value;
    phone.value = arr[1].value;
    gst.value = arr[2].value;
    pan.value = arr[3].value;
    address.value = arr[4].value;
    state.value = arr[5].value;
    city.value = arr[6].value;
    pin_code.value = arr[7].value;
  };

  handleContactCancel = () => {
    const { controls, contactCardObj } = this.state;
    const { alPerson, alDesignation, alNumber, wa } = controls;
    const arr = contactCardObj.fields;

    alPerson.value = arr[0].value;
    alDesignation.value = arr[1].value;
    alNumber.value = arr[2].value;
    wa.value = arr[3].value;
    controls.sameAsUser = false;
    this.setState({ controls });
  };

  handleSocialCancel = () => {
    const { controls, socialCardObj, bDayMaster, aDayMaster } = this.state;
    const {
      birthDate,
      anniversaryDate,
      facebook,
      instagram,
      website,
    } = controls;
    const arr = socialCardObj.fields;

    birthDate.value = bDayMaster ? new Date(bDayMaster) : null;
    anniversaryDate.value = aDayMaster ? new Date(aDayMaster) : null;

    facebook.value = arr[2].value;
    instagram.value = arr[3].value;
    website.value = arr[4].value;
  };

  handleSubDealerCancel = async () => {
    const { subDealerControls, subDealerCardObj, states } = this.state;
    const {
      businessName,
      ownerName,
      wa,
      street,
      city,
      state,
      gst,
      pan,
      pincode,
    } = subDealerControls;
    const arr = subDealerCardObj.fields;
    businessName.value = arr[0].value;
    ownerName.value = arr[1].value;
    wa.value = arr[2].value;
    street.value = arr[3].value;
    // state.value = arr[4].value;
    const selectedStateIndex = states.findIndex(
      (s) => s.name.toLowerCase() === arr[4].value.toLowerCase()
    );
    const currentState = states[selectedStateIndex];
    state.value = [currentState];
    const cities = await this.getCity(currentState.state_id);
    const selectedCityIndex = cities.findIndex(
      (c) => c.name.toLowerCase() === arr[5].value.toLowerCase()
    );

    city.value = [cities[selectedCityIndex]];
    pincode.value = arr[6].value;
    pan.value = arr[7].value;
    gst.value = arr[8].value;
    this.handleSubDealerValidation(true);
  };

  handleBranchCancel = async () => {
    const { branchControls, branchCardObj, states } = this.state;
    const {
      alternateContactPerson,
      wa,
      street,
      state,
      city,
      pincode,
    } = branchControls;
    const arr = branchCardObj.fields;

    alternateContactPerson.value = arr[0].value;
    wa.value = arr[1].value;
    street.value = arr[2].value;

    // state.value = arr[4].value;
    const selectedStateIndex = states.findIndex(
      (s) => s.name.toLowerCase() === arr[3].value.toLowerCase()
    );
    const currentState = states[selectedStateIndex];
    state.value = [currentState];
    const cities = await this.getCity(currentState.state_id);
    const selectedCityIndex = cities.findIndex(
      (c) => c.name.toLowerCase() === arr[4].value.toLowerCase()
    );

    city.value = [cities[selectedCityIndex]];
    pincode.value = arr[5].value;
    this.handleBranchValidation(true);
  };

  makeEditActive = (frg, status) => {
    const st = this.state;
    st[frg] = status;

    if (status === false) {
      if (frg === "isBusinessEditActive") {
        this.handleBusinessCancel();
        GoogleAnalticsService.event("Profile", "Business Detail View");
      } else if (frg === "isContactEditActive") {
        this.handleContactCancel();
        GoogleAnalticsService.event("Profile", "Contact Detail View");
      } else if (frg === "isSocialEditActive") {
        this.handleSocialCancel();
        GoogleAnalticsService.event("Profile", "Social Detail View");
      } else if (frg === "isBranchEditActive") {
        this.handleBranchCancel();
        GoogleAnalticsService.event("Profile", "Branch Detail View");
      } else if (frg === "isSubDealerEditActive") {
        this.handleSubDealerCancel();
        GoogleAnalticsService.event("Profile", "Sub Dealer Detail View");
      }
    }
    this.setState({ st });
  };

  handleBirthDateChange = (e) => {
    const { controls } = this.state;
    const { birthDate } = controls;
    birthDate.value = e;
    birthDate.touched = true;
    this.setState({ controls });
    this.socialFormValidation();
  };

  handleAnniversaryDateChange = (e) => {
    const { controls } = this.state;
    const { anniversaryDate } = controls;
    anniversaryDate.value = e;
    anniversaryDate.touched = true;
    this.setState({ controls });
    // this.handleValidation();
  };

  setSameAsUserCheckBoxValue = () => {
    const { controls, ownerName } = this.state;
    const { phone, alPerson, alNumber, alDesignation } = controls;
    // const { agreeTermAndCondition } = controls;
    controls.sameAsUser = !controls.sameAsUser;
    if (controls.sameAsUser === true) {
      alPerson.value = ownerName;
      alPerson.touched = true;
      alNumber.value = phone.value;
      alNumber.touched = true;
      alDesignation.value = "owner";
      alDesignation.touched = true;
    }
    this.setState({ controls });
    this.contactFormValidation();
  };

  formatDate = (d) => {
    const dateString = new Date(d).toLocaleString();
    const dateSplit = dateString.split(",");
    const date = dateSplit[0];
    return date;
  };

  updateReqButton = (update) => {
    this.setState({ isUpdateProfileRequestSend: update });
  };

  handleSelectState = (e) => {
    const userRole = this.state.userRole;
    if (userRole === Roles.SUB_DEALER_ROLE) {
      const { subDealerControls } = this.state;
      const { state, city } = subDealerControls;
      state.touched = true;
      if (e.length > 0) {
        const stateId = e[0].state_id;
        state.value = e;
        city.value = [];
        this.setState({ subDealerControls });
        this.getCity(stateId);
      } else {
        state.value = [];
        city.value = [];
        this.setState({ subDealerControls, cities: [] });
      }
      this.handleSubDealerValidation(false);
    } else {
      const { branchControls } = this.state;
      const { state, city } = branchControls;
      state.touched = true;
      if (e.length > 0) {
        const stateId = e[0].state_id;
        state.value = e;
        city.value = [];
        this.setState({ branchControls });
        this.getCity(stateId);
      } else {
        state.value = [];
        city.value = [];
        this.setState({ branchControls, cities: [] });
      }
      this.handleBranchValidation(false);
    }
  };

  handleSelectCity = (e) => {
    const userRole = this.state.userRole;
    if (userRole === Roles.SUB_DEALER_ROLE) {
      const { subDealerControls } = this.state;
      const { city } = subDealerControls;
      city.touched = true;
      city.value = e;
      this.setState({ subDealerControls });
      this.handleSubDealerValidation(false);
    } else {
      const { branchControls } = this.state;
      const { city } = branchControls;
      city.touched = true;
      city.value = e;
      this.setState({ branchControls });
      this.handleBranchValidation(false);
    }
  };

  getState = () => {
    return WarrantyRegistrationService.getState()
      .then((data) => {
        const { data: states } = data.data;
        if (states.length > 0) {
          const currentState = states[0];
          this.getCity(currentState.state_id);
        }
        this.setState({ states });
        return Promise.resolve(states);
      })
      .catch((e) => {
        return Promise.reject([]);
      });
  };

  getCity = (stateId) => {
    return WarrantyRegistrationService.getCity(stateId)
      .then((data) => {
        const { data: cities } = data.data;
        this.setState({ cities });
        return Promise.resolve(cities);
      })
      .catch((e) => {
        console.error("e", e);
        return Promise.reject([]);
      });
  };

  getUserPopupTitles = () => {
    SpecialEngagementService.getPopupTitles()
      .then((data) => {
        const titles = data.data.data;
        this.setState({ popupTitles: titles });
      })
      .catch((e) => {
        console.error("e", e);
      });
  };

  closeActionSidebar = () => {
    this.setState({ isActionPageOpen: false });
  };

  openActionSidebar = () => {
    window.scroll(0, 0);
    this.setState({ isActionPageOpen: true });
  };

  openUrl = (url) => {
    if (url.indexOf("http") > -1) {
      window.open(url, "_blank");
    } else {
      window.open("http://" + url, "_blank");
    }
  };

  render() {
    const {
      controls,
      activeFragment,
      userRole,
      userDetail,
      subDealerControls,
      branchControls,
      states,
      cities,
      isActionPageOpen,
      popupTitles,
      userInfo
    } = this.state;
    const {
      email,
      phone,
      gst,
      pan,
      alPerson,
      alDesignation,
      alNumber,
      wa,
      birthDate,
      anniversaryDate,
      facebook,
      instagram,
      website,
      sameAsUser,
      senderId,
      google_my_business_rating_link,
      google_my_business_link,
    } = controls;

    const {
      businessName,
      city,
      gst: subDealerGst,
      ownerName,
      pan: subDealerPan,
      state,
      street,
      wa: subDealerWa,
      pincode: subDealerPincode,
    } = subDealerControls;
    const {
      wa: branchWaNumber,
      alternateContactPerson,
      city: branchCity,
      state: branchState,
      street: branchStreet,
      pincode: branchPincode,
    } = branchControls;

    return (
      <div id="profile">
        {this.state.isLoading && <CustomSpinner />}
        {
          <>
            {isActionPageOpen && (
              <Sidebar
                sidebar={
                  <>
                    <div className="drawer-header">
                      <span className="drawer-title">My Actions</span>
                      <img
                        className="cursor-pointer"
                        src="images/close.svg"
                        alt=""
                        onClick={() => {
                          this.closeActionSidebar();
                        }}
                      />
                    </div>
                    <hr style={{ border: "solid 1px #ced4da" }} />
                    <ActionScreen titles={popupTitles} />
                  </>
                }
                open={isActionPageOpen}
                sidebarClassName="action-screen-sidebar"
                pullRight
                styles={{
                  sidebar: { background: "white", height: "90%", top: "60px" },
                }}
              />
            )}
            <Form>
              {this.state.isLoading ? <CustomSpinner /> : " "}
              <Row>
                <Col xl="3" className="margin-top-10">
                  <ProfileDetail
                    sapId={this.state.sapId}
                    businessName={this.state.businessName}
                    ownerName={this.state.ownerName}
                    address={this.state.address}
                    mobileNumber={this.state.mobileNumber}
                    profileImage={this.state.profileImage}
                    isImageUpdating={this.state.isImageUpdating}
                    userDetail={this.state.userDetail}
                    handleUploadImage={this.handleUploadImage}
                    openRequestDetailModal={this.openRequestDetailModal}
                    updateReqButton={this.updateReqButton}
                    isUpdateProfileRequestSend={
                      this.state.isUpdateProfileRequestSend
                    }
                    showRequestButton
                    userInfo={userInfo}
                  />
                  {popupTitles && popupTitles.length > 0 && (
                    <Card className="margin-top-5 cursor-pointer">
                      <CardBody>
                        <Row onClick={this.openActionSidebar}>
                          <Col>
                            <div className="action-button">My Actions</div>
                          </Col>
                          <Col className="text-align-right">
                            <Ionicon
                              icon="ios-arrow-forward"
                              fontSize="24px"
                              color="#fdbb1f"
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </Col>
                <Col xl="9" className="margin-top-10">
                  {(userRole === Roles.DEALER_ROLE ||
                    (userDetail &&
                      userDetail.permissions.includes(Permissions.PROFILE))) &&
                    userDetail.customer_class !== "na" &&
                    userDetail.customer_class !== "hy" && (
                      <Fragment>
                        <Row>
                          {this.state.isBusinessEditActive === false && (
                            <Col>
                              <DetailCard
                                makeEditActive={this.makeEditActive}
                                cardObj={this.state.businessCardObj}
                              />
                            </Col>
                          )}
                          {this.state.isBusinessEditActive === true && (
                            <Col
                              onClick={this.activeFragment.bind(
                                this,
                                "business"
                              )}
                            >
                              <EditOtherBusinessDetail
                                email={email}
                                phone={phone}
                                gst={gst}
                                pan={pan}
                                senderId={senderId}
                                city={this.state.controls.city}
                                state={this.state.controls.state}
                                pin_code={this.state.controls.pin_code}
                                address={this.state.controls.address}
                                isLoading={this.state.isBusinessFormLoading}
                                className={
                                  activeFragment === "business"
                                    ? "card-yellow-border"
                                    : ""
                                }
                                handleInputChange={this.handleInputChange}
                                activeFragment={this.activeFragment}
                                onCancel={this.makeEditActive}
                                onSave={this.handleBusinessSave}
                                isFormValid={this.state.isBusinessFormValid}
                                userDetail={this.state.userDetail}
                                userInfo={this.props.userDetail}
                                ref={this.childBusinessDetail}
                                is_disable_sender_id={
                                  this.state.is_disable_sender_id
                                }
                              />
                            </Col>
                          )}
                        </Row>
                        <br />

                        <Row>
                          {this.state.isContactEditActive === false && (
                            <Col>
                              <DetailCard
                                makeEditActive={this.makeEditActive}
                                cardObj={this.state.contactCardObj}
                              />
                            </Col>
                          )}
                          {this.state.isContactEditActive === true && (
                            <Col
                              onClick={this.activeFragment.bind(
                                this,
                                "contact"
                              )}
                            >
                              <EditPrimaryContactDetail
                                alPerson={alPerson}
                                alDesignation={alDesignation}
                                alNumber={alNumber}
                                wa={wa}
                                isLoading={this.state.isContatFormLoading}
                                sameAsUser={sameAsUser}
                                className={
                                  activeFragment === "contact"
                                    ? "card-yellow-border"
                                    : ""
                                }
                                handleInputChange={this.handleInputChange}
                                activeFragment={this.activeFragment}
                                onCancel={this.makeEditActive}
                                onSave={this.handleContactSave}
                                isFormValid={this.state.isContactFormValid}
                                setSameAsUserCheckBoxValue={
                                  this.setSameAsUserCheckBoxValue
                                }
                              />
                            </Col>
                          )}
                        </Row>
                        <br />

                        <Row>
                          {this.state.isSocialEditActive === false && (
                            <Col>
                              <SocialDetail
                                makeEditActive={this.makeEditActive}
                                cardObj={this.state.socialCardObj}
                                openUrl={this.openUrl}
                              />
                            </Col>
                          )}
                          {this.state.isSocialEditActive === true && (
                            <Col
                              onClick={this.activeFragment.bind(this, "social")}
                            >
                              <EditSocialDetails
                                birthDate={birthDate}
                                anniversaryDate={anniversaryDate}
                                facebook={facebook}
                                instagram={instagram}
                                website={website}
                                google_my_business_rating_link={
                                  google_my_business_rating_link
                                }
                                google_my_business_link={
                                  google_my_business_link
                                }
                                isLoading={this.state.isSocialFormLoading}
                                className={
                                  activeFragment === "social"
                                    ? "card-yellow-border"
                                    : ""
                                }
                                handleInputChange={this.handleInputChange}
                                activeFragment={this.activeFragment}
                                onCancel={this.makeEditActive}
                                onSave={this.handleSocialSave}
                                handleBirthDateChange={
                                  this.handleBirthDateChange
                                }
                                handleAnniversaryDateChange={
                                  this.handleAnniversaryDateChange
                                }
                                isFormValid={this.state.isSocialFormValid}
                              />
                            </Col>
                          )}
                        </Row>
                      </Fragment>
                    )}
                  {userRole === Roles.BRANCH_ROLE ||
                    userDetail.customer_class === "na" ||
                    userDetail.customer_class === "hy" ? (
                    <Fragment>
                      <Row>
                        {this.state.isBranchEditActive === false && (
                          <Col>
                            <DetailCard
                              makeEditActive={this.makeEditActive}
                              cardObj={this.state.branchCardObj}
                            />
                          </Col>
                        )}
                        {this.state.isBranchEditActive === true && (
                          <Col
                            onClick={this.activeFragment.bind(this, "business")}
                          >
                            <EditBranch
                              className={
                                activeFragment === "business"
                                  ? "card-yellow-border"
                                  : ""
                              }
                              alternateContactPerson={alternateContactPerson}
                              wa={branchWaNumber}
                              street={branchStreet}
                              state={branchState}
                              city={branchCity}
                              states={states}
                              cities={cities}
                              pincode={branchPincode}
                              isLoading={this.state.isBranchFormLoading}
                              handleInputChange={this.handleBranchInputChange}
                              handleSelectCity={this.handleSelectCity}
                              handleSelectState={this.handleSelectState}
                              activeFragment={this.activeFragment}
                              onCancel={this.makeEditActive}
                              onSave={this.updateProfileBranch}
                              isFormValid={this.state.isBranchFormValid}
                            />
                          </Col>
                        )}
                      </Row>
                      <br />
                    </Fragment>
                  ) : (
                    ""
                  )}
                  {userRole === Roles.SUB_DEALER_ROLE ? (
                    <Fragment>
                      <Row>
                        {this.state.isSubDealerEditActive === false && (
                          <Col>
                            <DetailCard
                              makeEditActive={this.makeEditActive}
                              cardObj={this.state.subDealerCardObj}
                              userRole={userRole}
                            />
                          </Col>
                        )}
                        {this.state.isSubDealerEditActive === true && (
                          <Col
                            onClick={this.activeFragment.bind(this, "business")}
                          >
                            <EditSubDealerDetail
                              className={
                                activeFragment === "business"
                                  ? "card-yellow-border"
                                  : ""
                              }
                              businessName={businessName}
                              ownerName={ownerName}
                              wa={subDealerWa}
                              street={street}
                              state={state}
                              city={city}
                              gst={subDealerGst}
                              pan={subDealerPan}
                              states={states}
                              cities={cities}
                              pincode={subDealerPincode}
                              isLoading={this.state.isSubDealerFormLoading}
                              handleInputChange={
                                this.handleSubDealerInputChange
                              }
                              handleSelectCity={this.handleSelectCity}
                              handleSelectState={this.handleSelectState}
                              activeFragment={this.activeFrfaagment}
                              onCancel={this.makeEditActive}
                              onSave={this.updateProfileSubDealer}
                              isFormValid={this.state.isSubDealerFormValid}
                            />
                          </Col>
                        )}
                      </Row>
                      <br />
                    </Fragment>
                  ) : (
                    ""
                  )}
                  {/* <br /> */}
                </Col>
              </Row>
            </Form>
          </>
        }

        {/* )} */}

        {/* </Container> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserDetail: (user) => dispatch(setUserDetail(user)),
  isProfileUpdate: (bool) => dispatch(isProfileUpdate(bool)),
});

const mapStateToProp = (state) => ({
  userDetail: state.User.user,
});

export default connect(mapStateToProp, mapDispatchToProps)(Profile);

// export default Profile;
