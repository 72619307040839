import { request } from "./Request";
import { routes } from "../constants/constant.route";
const {
  ADD_GROUP,
  GET_GROUP_CUSTOMER,
  ADD_CUSTOMERS_TO_GROUP,
  EDIT_GROUP,
  DELETE_GROUP_CUSTOMER,
  DELETE_GROUP,
  GET_NEW_CUSTOMER_FOR_GROUP,
  ADD_TO_GROUP,
  GET_ALL_SERVICES,
  GET_VISIT_PURPOSE,
  GET_CUSTOMER_SERVICE_TAKEN,
  SAVE_DEALER_EMAIL_PREF
} = routes.CUSTOMER_MGT;

const {
  GET_CONTACTS
} = routes.WHATSAPP
const API_URL = process.env.REACT_APP_API_URL;

export default class CustomerManagementService {
  static getCustomers(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.GET_CUSTOMERS}`,
      null,
      reqData,
      null
    );
  }

  static getVehicleType() {
    return request(
      "GET",
      `${API_URL}${routes.CUSTOMER_MGT.GET_VEHICLE_TYPE}`,
      null,
      null,
      null
    );
  }

  static getGroups(reqData) {
    let qp = `?`;
    if (reqData.page) {
      qp += `page=${reqData.page}&`;
    }
    if (reqData.pageSize) {
      qp += `pageSize=${reqData.pageSize}&`;
    }

    if (reqData.search) {
      qp += `search_query=${reqData.search}&`;
    }

    return request(
      "GET",
      `${API_URL}${routes.CUSTOMER_MGT.GET_GROUP}${qp}`,
      null,
      null,
      null
    );
  }

  static getCustomerVehicle(customer_id) {
    return request(
      "GET",
      `${API_URL}${routes.CUSTOMER_MGT.GET_CUSTOMER_VEHICLE}?customer_id=${customer_id}`,
      null,
      null,
      null
    );
  }

  static addVehicle(data) {
    let api = routes.CUSTOMER_MGT.ADD_VEHICLE;
    if (data.vehicle_id) {
      api = routes.CUSTOMER_MGT.UPDATE_VEHICLE;
      return request("PUT", `${API_URL}${api}`, null, data, null);
    }
    return request("POST", `${API_URL}${api}`, null, data, null);
  }

  static addCustomer(data) {
    let api = routes.CUSTOMER_MGT.ADD_CUSTOMER;
    if (data.customer_id) {
      api = routes.CUSTOMER_MGT.UPDATE_CUSTOMER;
    }
    return request(
      data.customer_id ? "PUT" : "POST",
      `${API_URL}${api}`,
      null,
      data,
      null
    );
  }

  static blockCustomers(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.BLOCK_CUSTOMER}`,
      null,
      reqData,
      null
    );
  }

  static getBlockCustomers(reqData) {
    return request(
      "GET",
      `${API_URL}${routes.CUSTOMER_MGT.GET_BLOCK_CUSTOMERS}`,
      reqData,
      null,
      null
    );
  }

  static unBlockCustomers(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.UNBLOCK_CUSTOMER}`,
      null,
      reqData,
      null
    );
  }

  static addGroup(data) {
    return request("POST", `${API_URL}${ADD_GROUP}`, null, data, null);
  }

  static getGroupCustomers(page, pageSize, groupId, downloadFile, search) {
    let qp = `?`;
    if (page) {
      qp += `page=${page}&`;
    }
    if (pageSize) {
      qp += `pageSize=${pageSize}&`;
    }
    if (downloadFile) {
      qp += `download_list=${downloadFile}&`;
    }
    if (search) {
      qp += `search_query=${search}&`;
    }
    if (groupId) {
      qp += `group_id=${groupId}&`;
    }
    return request(
      "GET",
      `${API_URL}${GET_GROUP_CUSTOMER}${qp}`,
      null,
      null,
      null
    );
  }

  static addCustomerToGroup(data) {
    return request(
      "POST",
      `${API_URL}${ADD_CUSTOMERS_TO_GROUP}`,
      null,
      data,
      null
    );
  }

  static editGroup(data) {
    return request("POST", `${API_URL}${EDIT_GROUP}`, null, data, null);
  }

  static deleteGroupCustomer(data) {
    return request(
      "POST",
      `${API_URL}${DELETE_GROUP_CUSTOMER}`,
      null,
      data,
      null
    );
  }

  static addToGroup(data) {
    return request("POST", `${API_URL}${ADD_TO_GROUP}`, null, data, null);
  }

  static deleteCustomer(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.DELETE_CUSTOMER}`,
      null,
      reqData,
      null
    );
  }
  static deleteGroup(groupId) {
    let qp = `?`;
    if (groupId) {
      qp += `group_id=${groupId}&`;
    }
    return request(
      "DELETE",
      `${API_URL}${DELETE_GROUP}${qp}`,
      null,
      null,
      null
    );
  }

  static getNewCustomersForGroup(page, pageSize, groupId, search) {
    let qp = `?`;
    if (page) {
      qp += `page=${page}&`;
    }
    if (pageSize) {
      qp += `pageSize=${pageSize}&`;
    }
    if (search) {
      qp += `search_query=${search}&`;
    }
    if (groupId) {
      qp += `group_id=${groupId}&`;
    }
    return request(
      "GET",
      `${API_URL}${GET_NEW_CUSTOMER_FOR_GROUP}${qp}`,
      null,
      null,
      null
    );
  }

  static getSourceMember() {
    return request(
      "GET",
      `${API_URL}${routes.CUSTOMER_MGT.GET_SOURCE_MEMBER}`,
      null,
      null,
      null
    );
  }

  static deleteCustomerVehicle(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.DELETE_CUSTOMER_VEHICLE}`,
      null,
      reqData,
      null
    );
  }

  static uploadCustomerFile(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.UPLOAD_FILE}`,
      null,
      reqData,
      null
    );
  }

  static checkCustomer(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.CHECK_CUSTOMER}`,
      null,
      reqData,
      null
    );
  }

  static getAllCustomerService() {
    return request(
      "GET",
      `${API_URL}${GET_ALL_SERVICES}`,
      null,
      null,
      null
    );
  }

  static getCustomerVisitPurpose() {
    return request(
      "GET",
      `${API_URL}${GET_VISIT_PURPOSE}`,
      null,
      null,
      null
    );
  }

  static getCustomerServiceTaken(customer_id) {
    return request(
      "GET",
      `${API_URL}${GET_CUSTOMER_SERVICE_TAKEN}?customer_id=${customer_id}`,
      null,
      null,
      null
    );
  }
  static getServiceWiseVehicleTypeId(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.SERVICE_WISE_VEHICLE_TYPE_ID}`,
      null,
      reqData,
      null
    );
  }
  static getOverDueServices(reqData) {
    return request(
      "POST",
      `${API_URL}${routes.CUSTOMER_MGT.GET_OVERDUE_SERVICES}`,
      null,
      reqData,
      null
    );
  }
  static saveDealerEmailPref(reqData) {
    return request(
      "GET",
      `${API_URL}${SAVE_DEALER_EMAIL_PREF}`,
      reqData,
      null,
      null
    );
  }

  static getWhatsAppContacts(reqData) {
    return request(
      "GET",
      `${API_URL}${GET_CONTACTS}`,
      reqData,
      null,
      null
    );
  }
}
