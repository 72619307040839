// import React from "react";
import moment from "moment";
import _ from "lodash";
function compareObject(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
        if (base.type) {
          result["type"] = base.type;
        }
      }
    });
  }
  return changes(object, base);
}

function CheckChangeObjProposalDetail(obj, preObj) {
  try {
    let changeObj = [];
    if (preObj.proposal_type !== obj.proposal_type) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Proposal Type",
        original: preObj.proposal_type,
        revised: obj.proposal_type,
      });
    }
    if (preObj.outlet_type !== obj.outlet_type) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Type of Outlet",
        original: preObj.outlet_type,
        revised: obj.outlet_type,
      });
    }

    if (preObj.category !== obj.category) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Type of Category",
        original: preObj.category,
        revised: obj.category,
      });
    }
    if (preObj.name !== obj.name) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Dealership Name",
        original: preObj.name,
        revised: obj.name,
      });
    }
    if (preObj.address !== obj.address) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Address",
        original: preObj.address,
        revised: obj.address,
      });
    }
    if (preObj.state !== obj.state) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "State",
        original: preObj.state,
        revised: obj.state,
      });
    }
    if (preObj.city !== obj.city) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "State",
        original: preObj.city,
        revised: obj.city,
      });
    }
    if (preObj.pincode !== obj.pincode) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Pincode",
        original: preObj.pincode,
        revised: obj.pincode,
      });
    }
    if (preObj.mobile !== obj.mobile) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Mobile No",
        original: preObj.mobile,
        revised: obj.mobile,
      });
    }
    if (preObj.landline !== obj.landline) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Landline no",
        original: preObj.landline,
        revised: obj.landline,
      });
    }

    if (preObj.email_id !== obj.email_id) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "Email Id",
        original: preObj.email_id,
        revised: obj.email_id,
      });
    }
    if (preObj.so.id && obj.so.id && preObj.so.id !== obj.so.id) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Dealership Details",
        field_name: "SO Mobile Number",
        original: preObj.so.name,
        revised: obj.so.name,
      });
    }
    if (preObj.ownership_type !== obj.ownership_type) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Business Analysis",
        field_name:
          "Ownership Type(Sole proprietorship/ Partnership/ Pvt. Ltd.)",
        original: preObj.ownership_type,
        revised: obj.ownership_type,
      });
    }
    if (preObj.ownership_type !== obj.ownership_type) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Business Analysis",
        field_name: "Name of Proprietor/ Managing Partner/ Managing Director ",
        original: preObj.proprietor_name,
        revised: obj.proprietor_name,
      });
    }
    if (preObj.is_in_tyre_business !== obj.is_in_tyre_business) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Business Analysis",
        field_name: "Currently operating in tyre Business(Y/N)",
        original: preObj.is_in_tyre_business ? "Yes" : "No",
        revised: obj.is_in_tyre_business ? "Yes" : "No",
      });
    }
    if (preObj.counters !== obj.counters) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Business Analysis",
        field_name: "No. of Counters",
        original: preObj.counters,
        revised: obj.counters,
      });
    }
    if (preObj.is_jk_authorized_dealer !== obj.is_jk_authorized_dealer) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Business Analysis",
        field_name: "Authorized dealer of JK tyre?(Y/N)",
        original: preObj.is_jk_authorized_dealer ? "Yes" : "No",
        revised: obj.is_jk_authorized_dealer ? "Yes" : "No",
      });
    }
    if (preObj.sap_id !== obj.sap_id) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Business Analysis",
        field_name: "SAP Code",
        original: preObj.sap_id,
        revised: obj.sap_id,
      });
    }
    if (preObj.sas_non_truck !== obj.sas_non_truck) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Business Analysis",
        field_name: "SAS Non-Truck (in Lac.)",
        original: preObj.sas_non_truck,
        revised: obj.sas_non_truck,
      });
    }
    if (preObj.sas_truck !== obj.sas_truck) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Business Analysis",
        field_name: "SAS Truck (in Lac.)",
        original: preObj.sas_truck,
        revised: obj.sas_truck,
      });
    }

    if (obj.counter_monthly.length > 0 && preObj.counter_monthly.length > 0) {
      obj.counter_monthly.forEach((element, i) => {
        let indexObj = preObj.counter_monthly.findIndex(
          (x) => x.type == element.type
        );
        if (indexObj > -1) {
          let card_name = "";
          if (element.type === "Total Counter") {
            card_name = "Total Counter Potential of PCR (Monthly)";
          } else if (element.type === "Present Offtake") {
            card_name = "Present Offtake- JK Tyre-(Monthly)";
          } else if (element.type === "Counter Share") {
            card_name = "Counter Share (%)";
          } else if (element.type === "Proposed Business") {
            card_name = "Proposed Business (Average per month)";
          }
          if (element.car !== preObj.counter_monthly[indexObj].car) {
            changeObj.push({
              page_name: "Proposal Details",
              card_name: card_name,
              field_name: "CAR+UV Radial",
              original: preObj.counter_monthly[indexObj].car,
              revised: element.car,
            });
          }
          if (element.lcv !== preObj.counter_monthly[indexObj].lcv) {
            changeObj.push({
              page_name: "Proposal Details",
              card_name: card_name,
              field_name: "LCV (B)",
              original: preObj.counter_monthly[indexObj].lcv,
              revised: element.lcv,
            });
          }
          if (element.scv !== preObj.counter_monthly[indexObj].scv) {
            changeObj.push({
              page_name: "Proposal Details",
              card_name: card_name,
              field_name: "SCV (B)",
              original: preObj.counter_monthly[indexObj].scv,
              revised: element.scv,
            });
          }
        }
      });
    }

    if (obj.outlet_type === "Farm Xpress Wheel") {
      if (
        preObj.total_counter_total_year_potential !==
        obj.total_counter_total_year_potential
      ) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Total Counter Potential of FXW",
          field_name: "Total Year Potential",
          original: preObj.total_counter_total_year_potential,
          revised: obj.total_counter_total_year_potential,
        });
      }

      if (
        preObj.total_counter_total_h1_potential !==
        obj.total_counter_total_h1_potential
      ) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Total Counter Potential of FXW",
          field_name: "Total H1 Potential",
          original: preObj.total_counter_total_h1_potential,
          revised: obj.total_counter_total_h1_potential,
        });
      }

      if (
        preObj.present_offtake_total_year_turnover !==
        obj.present_offtake_total_year_turnover
      ) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Present Offtake- JK Tyre",
          field_name: "Total Year Turnover",
          original: preObj.present_offtake_total_year_turnover,
          revised: obj.present_offtake_total_year_turnover,
        });
      }

      if (
        preObj.present_offtake_total_h1_turnover !==
        obj.present_offtake_total_h1_turnover
      ) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Present Offtake- JK Tyre",
          field_name: "Total H1 Turnover",
          original: preObj.present_offtake_total_h1_turnover,
          revised: obj.present_offtake_total_h1_turnover,
        });
      }

      if (
        preObj.counter_share_total_year_turnover !==
        obj.counter_share_total_year_turnover
      ) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Counter Share (%)",
          field_name: "Total Year Turnover",
          original: preObj.counter_share_total_year_turnover,
          revised: obj.counter_share_total_year_turnover,
        });
      }

      if (
        preObj.counter_share_total_h1_turnover !==
        obj.counter_share_total_h1_turnover
      ) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Counter Share (%)",
          field_name: "Total H1 Turnover",
          original: preObj.counter_share_total_h1_turnover,
          revised: obj.counter_share_total_h1_turnover,
        });
      }

      if (
        preObj.proposed_business_total_year_turnover !==
        obj.proposed_business_total_year_turnover
      ) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Proposed Business",
          field_name: "Total Year Turnover",
          original: preObj.proposed_business_total_year_turnover,
          revised: obj.proposed_business_total_year_turnover,
        });
      }

      if (
        preObj.proposed_business_h1_turnover !==
        obj.proposed_business_h1_turnover
      ) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Proposed Business",
          field_name: "Total H1 Turnover",
          original: preObj.proposed_business_h1_turnover,
          revised: obj.proposed_business_h1_turnover,
        });
      }
    }

    if (
      preObj.present_offtake_total_year_turnover !==
      obj.present_offtake_total_year_turnover
    ) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Counter Share (%)",
        field_name: "Competition Brands Dealing with (PCR)",
        original: preObj.present_offtake_total_year_turnover,
        revised: obj.present_offtake_total_year_turnover,
      });
    }
    if (preObj.existing_shop_compitition !== obj.existing_shop_compitition) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Counter Share (%)",
        field_name: "Any existing Brand shop of Competition",
        original: preObj.existing_shop_compitition ? "Yes" : "No",
        revised: obj.existing_shop_compitition ? "Yes" : "No",
      });
    }
    if (
      preObj.existing_show_compitition_name !==
      obj.existing_show_compitition_name
    ) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Counter Share (%)",
        field_name: "If Yes, Please specify",
        original: preObj.existing_show_compitition_name,
        revised: obj.existing_show_compitition_name,
      });
    }
    if (preObj.is_agree_exclusive_jk !== obj.is_agree_exclusive_jk) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Proposed Business (Average per month)",
        field_name:
          "The Franchise agrees to maintain Exclusive sale & branding of JK Tyre at the counter (Yes/No)",
        original: preObj.is_agree_exclusive_jk ? "Yes" : "No",
        revised: obj.is_agree_exclusive_jk ? "Yes" : "No",
      });
    }
    if (preObj.remarks !== obj.remarks) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Proposed Business (Average per month)",
        field_name: "Remarks",
        original: preObj.remarks,
        revised: obj.remarks,
      });
    }
    if (preObj.is_premised_owner !== obj.is_premised_owner) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Specifications of Proposed Showroom",
        field_name: "Premises (Owned / Rented)",
        original: preObj.is_premised_owner ? "Owned" : "Rented",
        revised: obj.is_premised_owner ? "Owned" : "Rented",
      });
    }
    if (preObj.shutters_count !== obj.shutters_count) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Specifications of Proposed Showroom",
        field_name: "No. of shutters",
        original: preObj.shutters_count,
        revised: obj.shutters_count,
      });
    }
    if (preObj.site_picture !== obj.site_picture) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Specifications of Proposed Showroom",
        document_name: "Site Pictures",
      });
    }
    if (preObj.lease_aggrement !== obj.lease_aggrement) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Specifications of Proposed Showroom",
        document_name: "Lease agreement/Property Documents",
      });
    }
    if (preObj.showroom_frontage !== obj.showroom_frontage) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Showroom Size And Service Area",
        field_name: "Frontage (Ft)",
        original: preObj.showroom_frontage,
        revised: obj.showroom_frontage,
      });
    }
    if (preObj.showroom_depth !== obj.showroom_depth) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Showroom Size And Service Area",
        field_name: "Depth (Ft)",
        original: preObj.showroom_depth,
        revised: obj.showroom_depth,
      });
    }
    if (preObj.showroom_celling_height !== obj.showroom_celling_height) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Showroom Size And Service Area",
        field_name: "Ceiling Height (Ft)",
        original: preObj.showroom_celling_height,
        revised: obj.showroom_celling_height,
      });
    }

    if (preObj.showroom_area !== obj.showroom_area) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Showroom Size And Service Area",
        field_name: "Total area of Showroom (in SqFt- Width x Depth)",
        original: preObj.showroom_area,
        revised: obj.showroom_area,
      });
    }
    if (obj.outlet_type === "Xpress Wheel") {
      if (preObj.front_face_area !== obj.front_face_area) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Showroom Size And Service Area",
          field_name: "Area of Front Fascia(in SqFt)",
          original: preObj.front_face_area,
          revised: obj.front_face_area,
        });
      }

      if (preObj.dealer_share_amount !== obj.dealer_share_amount) {
        changeObj.push({
          page_name: "Proposal Details",
          card_name: "Showroom Size And Service Area",
          field_name: "Dealer Share Amount(in Rs)",
          original: preObj.dealer_share_amount,
          revised: obj.dealer_share_amount,
        });
      }
    }
    if (preObj.having_separate_storage !== obj.having_separate_storage) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Showroom Size And Service Area",
        field_name: "Availability separate storage area",
        original: preObj.having_separate_storage ? "Yes" : "No",
        revised: obj.having_separate_storage ? "Yes" : "No",
      });
    }
    if (preObj.storage_area !== obj.storage_area) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Showroom Size And Service Area",
        field_name: "Area (in SqFt)",
        original: preObj.storage_area,
        revised: obj.storage_area,
      });
    }
    let location = "",
      is_change_location_obj = false;
    if (obj.location.length > 0) {
      if (preObj.location.length > 0) {
        obj.location.forEach((element) => {
          if (element === "main_road") {
            let index = preObj.location.filter((x) => x === element);
            if (index <= -1) {
              location += "Main Road, ";
              is_change_location_obj = true;
            }
          } else if (element === "commercial_area") {
            let index = preObj.location.filter((x) => x === element);
            if (index <= -1) {
              location += "Commercial Area, ";
              is_change_location_obj = true;
            }
          } else if (element === "tyre_auto_market") {
            let index = preObj.location.filter((x) => x === element);
            if (index <= -1) {
              location += "Tyre Auto Market, ";
              is_change_location_obj = true;
            }
          } else if (element === "residental_area") {
            let index = preObj.location.filter((x) => x === element);
            if (index <= -1) {
              location += "Residental Area, ";
              is_change_location_obj = true;
            }
          }
        });
      } else {
        obj.location.forEach((element) => {
          if (element === "main_road") {
            location += "Main Road, ";
            is_change_location_obj = true;
          } else if (element === "commercial_area") {
            location += "Commercial Area, ";
            is_change_location_obj = true;
          } else if (element === "tyre_auto_market") {
            location += "Tyre Auto Market, ";
            is_change_location_obj = true;
          } else if (element === "residental_area") {
            location += "Residental Area, ";
            is_change_location_obj = true;
          }
        });
      }
    }

    if (is_change_location_obj) {
      let pre_location = "";
      preObj.location.forEach((element) => {
        if (element === "main_road") {
          pre_location += "Main Road, ";
        } else if (element === "commercial_area") {
          pre_location += "Commercial Area, ";
        } else if (element === "tyre_auto_market") {
          pre_location += "Tyre Auto Market, ";
        } else if (element === "residental_area") {
          pre_location += "Residental Area, ";
        }
      });
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Showroom Size And Service Area",
        field_name: "Location of the showroom",
        original: pre_location,
        revised: location,
      });
    }
    if (preObj.assets_value !== obj.assets_value) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Financial Background",
        field_name: "Value of Assets (Lac.)",
        original: preObj.assets_value,
        revised: obj.assets_value,
      });
    }
    if (preObj.other_business !== obj.other_business) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Financial Background",
        field_name: "Any Other Busness",
        original: preObj.other_business,
        revised: obj.other_business,
      });
    }

    //existing
    let diffrence_machinery_existing = compareObject(
      obj.machinery_existing,
      preObj.machinery_existing
    );
    if (
      diffrence_machinery_existing &&
      diffrence_machinery_existing.length > 0 &&
      preObj.machinery_existing.length > 0
    ) {
      (diffrence_machinery_existing || []).forEach((element) => {
        let machinery_existing = preObj.machinery_existing.filter(
          (x) => x.type === element.type
        );
        if (machinery_existing.length <= 0) {
          machinery_existing = [
            {
              type: element.type,
              brand: "",
              model: "",
              installation_date: null,
            },
          ];
        }
        if (element.hasOwnProperty("brand")) {
          changeObj.push({
            page_name: "Proposal Details",
            card_name: "Machinery Details (Existing Equipment)",
            field_name: element.type + "- Brand",
            original: machinery_existing[0].brand,
            revised: element.brand,
          });
        }
        if (element.hasOwnProperty("model")) {
          changeObj.push({
            page_name: "Proposal Details",
            card_name: "Machinery Details (Existing Equipment)",
            field_name: element.type + "- Model",
            original: machinery_existing[0].model,
            revised: element.model,
          });
        }
        if (element.hasOwnProperty("installation_date")) {
          try {
            changeObj.push({
              page_name: "Proposal Details",
              card_name: "Machinery Details (Existing Equipment)",
              field_name: element.type + "- Date of Installation",
              original: machinery_existing[0].installation_date
                ? moment(machinery_existing[0].installation_date).format(
                  "DD-MM-YYYY"
                )
                : "",
              revised: element.installation_date
                ? moment(element.installation_date).format("DD-MM-YYYY")
                : "",
            });
          } catch (error) {
            console.log("err", error);
          }
        }
      });
    }

    //required
    let diffrence_machinery_required = compareObject(
      obj.machinery_required,
      preObj.machinery_required
    );
    if (
      diffrence_machinery_required &&
      diffrence_machinery_required.length > 0 &&
      preObj.machinery_required.length > 0
    ) {
      (diffrence_machinery_required || []).forEach((element) => {
        let machinery_required = preObj.machinery_required.filter(
          (x) => x.type === element.type
        );
        if (machinery_required.length <= 0) {
          machinery_required = [
            {
              type: element.type,
              brand: "",
              model: "",
              installation_date: null,
            },
          ];
        }
        if (element.hasOwnProperty("brand")) {
          changeObj.push({
            page_name: "Proposal Details",
            card_name: "Machinery Details (Required Equipment)",
            field_name: element.type + "- Brand",
            original: machinery_required[0].brand,
            revised: element.brand,
          });
        }
        if (element.hasOwnProperty("model")) {
          changeObj.push({
            page_name: "Proposal Details",
            card_name: "Machinery Details (Required Equipment)",
            field_name: element.type + "- Model",
            original: machinery_required[0].model,
            revised: element.model,
          });
        }
        // if (element.hasOwnProperty("installation_date")) {
        //   changeObj.push({
        //     page_name: 'Proposal Details', card_name: 'Machinery Details (Existing Equipment)', field_name: element.type + '- Date of Installation',
        //     original: machinery_required[0].brand, revised: element.installation_date,
        //   });
        // }
      });
    }

    if (preObj.willing_machinery_invest !== obj.willing_machinery_invest) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Financial Background",
        field_name: "Willingness to invest in Machinery(Yes/No)",
        original: preObj.willing_machinery_invest,
        revised: obj.willing_machinery_invest,
      });
    }

    if (preObj.willing_civil_invest !== obj.willing_civil_invest) {
      changeObj.push({
        page_name: "Proposal Details",
        card_name: "Financial Background",
        field_name: "Willingness to invest in Civil Work (Yes/No)",
        original: preObj.willing_civil_invest,
        revised: obj.willing_civil_invest,
      });
    }

    return changeObj;
  } catch (error) {
    console.log("err", error);
  }
}
export default CheckChangeObjProposalDetail;
