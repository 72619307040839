import React, { Component } from 'react';
import {
  Button, FormGroup, Input, Label, Row, Col, InputGroup,
  InputGroupAddon
} from 'reactstrap';
import DatePicker from "react-datepicker";
import Validation from '../../services/Validation';
import CampaignService from '../../services/CampaignService';
import { messages } from '../../constants/constant.message';
import ModalService from '../../services/ModalService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import Storage from '../../services/Storage';
import { Roles } from '../../constants/constant.role';
const { ERROR_MESSAGE } = messages.COMMON;
class OrderHistoryFilterHO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      quantity: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      zoneData: [],
      regionData: [],
      areaData: [],
      userDetail: Storage.getUserDetail(),
      controls: {
        product: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        productQty: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        orderStartDate: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        orderEndDate: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        amountStart: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        amountEnd: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        pointStart: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        pointEnd: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        paymentMode: {
          value: 'all',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        paymentStatus: {
          value: 'all',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        zone: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        region: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
        area: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false
        },
      },
      is_head_office: false
    }
    if (this.state.userDetail && this.state.userDetail.type &&
      this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE) {
      this.state.is_head_office = true;
    }
  }

  componentDidMount() {
    this.initApi();
    if (this.props.controls_filter && Object.keys(this.props.controls_filter).length > 0) {
      this.setState({
        controls: this.props.controls_filter
      }, () => {
        let { controls_filter } = this.props;
        if (controls_filter.zone.value) {
          this.getRegion([controls_filter.zone.value]);
        }
        if (controls_filter.region.value) {
          this.getArea({
            region_ids: controls_filter.region.value ? [controls_filter.region.value] : [],
            zone_id: controls_filter.zone.value ? [controls_filter.zone.value] : []
          });
        }
      });
    }
  }

  initApi = async () => {
    await this.getProducts();
    if (this.state.is_head_office) {
      await this.getZone();
    }
  }

  getZone = () => {
    this.setState({
      isLoading: true
    })
    CampaignService.getZone()
      .then(data => {
        if (data.data.data) {
          this.setState({
            zoneData: data.data.data,
            isLoading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false
        })
        return err;
      });
  };

  getRegion = zone_ids => {
    let reqData = {
      zone_id: zone_ids
    };
    this.setState({
      isLoading: true
    })
    CampaignService.getRegion(reqData)
      .then(data => {
        if (data.data.data) {
          this.setState({
            regionData: data.data.data,
            isLoading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };

  getArea = data => {
    let reqData = {
      region_id: [],
      zone_id: []
    };
    if (data && Object.keys(data).length > 0) {
      reqData = {
        region_id: data.region_ids,
        zone_id: data.zone_id
      };
    }
    this.setState({
      isLoading: true
    });
    CampaignService.getArea(reqData)
      .then(data => {
        this.setState({
          areaData: data.data.data,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  };


  getProducts = () => {
    this.setState({
      isLoading: true
    });
    CampaignService.getProducts()
      .then(res => {
        this.setState({
          products: res.data.data.products,
          isLoading: false
        });
      })
      .catch(e => {
        this.setState({
          isLoading: false
        });

        let err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };


  handleValidation = (isSubmit = false) => {
    return true;
    let { controls } = this.state;
    let isFormValid = false;
    let {
      amountStart, amountEnd, pointStart, pointEnd
    } = controls;

    if (amountStart.touched === true || isSubmit) {
      amountStart = Validation.notNullValidator(amountStart);
      amountEnd = Validation.notNullValidator(amountEnd);
      amountStart.isNotValidMinMax = false;
      amountEnd.isNotValidMinMax = false;
      if (!amountStart.nullValue && !amountEnd.nullValue) {
        if (amountStart.value === 0 || amountStart.value === '0') {
          amountStart.valid = true;
        } else if (parseInt(amountStart.value) > parseInt(amountEnd.value)) {
          amountStart.valid = false;
          amountStart.isNotValidMinMax = true;
        } else {
          amountStart.valid = true;
        }
      } else if (!amountStart.nullValue) {
        if (amountStart.value === 0 || amountStart.value === '0') {
          amountStart.valid = true;
        } else {
          amountStart.valid = true;
          // if (amountEnd.nullValue && (amountEnd.touched === true || isSubmit)) {
          //   amountEnd.showErrorMsg = true;
          //   amountEnd.valid = false;
          // }
        }
      } else if (amountStart.nullValue) {
        amountStart.valid = true;
        amountStart.showErrorMsg = false;
      }
      else {
        amountStart.valid = true;
      }
      if (!amountStart.isNotValidMinMax) {
        if (amountStart.valid === false) {
          amountStart.showErrorMsg = true;
        } else {
          amountStart.showErrorMsg = false;
        }
      }
    }


    // if (amountEnd.touched === true || isSubmit) {
    //   amountStart = Validation.notNullValidator(amountStart);
    //   amountEnd = Validation.notNullValidator(amountEnd);
    //   if (!amountStart.nullValue && !amountEnd.nullValue) {
    //     if (amountEnd.value === 0 || amountEnd.value === '0') {
    //       amountEnd.valid = false;
    //     } else if (parseInt(amountEnd.value) < parseInt(amountStart.value)) {
    //       amountEnd.isNotValidMinMax = true;
    //       amountEnd.valid = false;
    //     } else {
    //       amountEnd.valid = true;
    //     }
    //   } else if (!amountEnd.nullValue) {
    //     if (amountEnd.value === 0 || amountEnd.value === '0') {
    //       amountEnd.valid = false;
    //     } else {
    //       amountEnd.valid = true;
    //       // if (amountStart.nullValue && amountStart.touched === true || isSubmit) {
    //       //   amountStart.showErrorMsg = true;
    //       //   amountStart.valid = false;
    //       // }
    //     }
    //   } else if (amountEnd.nullValue) {
    //     amountEnd.valid = true;
    //     amountEnd.showErrorMsg = false;
    //   } else {
    //     if (!amountStart.nullValue) {
    //       amountEnd.valid = false;
    //       amountEnd.showErrorMsg = true;
    //     } else {
    //       amountEnd.valid = true;
    //     }
    //   }
    //   if (!amountEnd.isNotValidMinMax) {
    //     if (amountEnd.valid === false) {
    //       amountEnd.showErrorMsg = true;
    //     } else {
    //       amountEnd.showErrorMsg = false;
    //     }
    //   } else {
    //     amountEnd.showErrorMsg = false;
    //   }
    // }


    if (pointStart.touched === true || isSubmit) {
      pointStart = Validation.notNullValidator(pointStart);
      pointEnd = Validation.notNullValidator(pointEnd);
      pointStart.isNotValidMinMax = false;
      pointEnd.isNotValidMinMax = false;
      if (!pointStart.nullValue && !pointEnd.nullValue) {
        if (pointStart.value === 0 || pointStart.value === '0') {
          pointStart.valid = true;
        } else if (parseInt(pointStart.value) > parseInt(pointEnd.value)) {
          pointStart.valid = false;
          pointStart.isNotValidMinMax = true;
        } else {
          pointStart.valid = true;
        }
      } else if (!pointStart.nullValue) {
        if (pointStart.value === 0 || pointStart.value === '0') {
          pointStart.valid = true;
        } else {
          pointStart.valid = true;
          // if (pointEnd.nullValue && (pointEnd.touched === true || isSubmit)) {
          //   pointEnd.showErrorMsg = true;
          //   pointEnd.valid = false;
          // }
        }
      } else if (pointStart.nullValue) {
        pointStart.valid = true;
        pointStart.showErrorMsg = false;
      }
      else {
        pointStart.valid = true;
      }
      if (!pointStart.isNotValidMinMax) {
        if (pointStart.valid === false) {
          pointStart.showErrorMsg = true;
        } else {
          pointStart.showErrorMsg = false;
        }
      }
    }


    if (pointEnd.touched === true || isSubmit) {
      pointStart = Validation.notNullValidator(pointStart);
      pointEnd = Validation.notNullValidator(pointEnd);
      if (!pointStart.nullValue && !pointEnd.nullValue) {
        if (pointEnd.value === 0 || pointEnd.value === '0') {
          pointEnd.valid = false;
        } else if (parseInt(pointEnd.value) < parseInt(pointStart.value)) {
          pointEnd.isNotValidMinMax = true;
          pointEnd.valid = false;
        } else {
          pointEnd.valid = true;
        }
      } else if (!pointEnd.nullValue) {
        if (pointEnd.value === 0 || pointEnd.value === '0') {
          pointEnd.valid = false;
        } else {
          pointEnd.valid = true;
          // if (pointStart.nullValue && pointStart.touched === true || isSubmit) {
          //   pointStart.showErrorMsg = true;
          //   pointStart.valid = false;
          // }
        }
      } else if (pointEnd.nullValue) {
        pointEnd.valid = true;
        pointEnd.showErrorMsg = false;
      } else {
        if (!pointStart.nullValue) {
          pointEnd.valid = false;
          pointEnd.showErrorMsg = true;
        } else {
          pointEnd.valid = true;
        }
      }
      if (!pointEnd.isNotValidMinMax) {
        if (pointEnd.valid === false) {
          pointEnd.showErrorMsg = true;
        } else {
          pointEnd.showErrorMsg = false;
        }
      } else {
        pointEnd.showErrorMsg = false;
      }
    }

    if (amountStart.valid === true && amountEnd.valid === true &&
      pointStart.valid === true && pointEnd.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    this.setState({
      controls
    });

    return isFormValid;
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let { controls } = this.state;
    controls[controlName].touched = true;
    if (controlName === 'pointStart' || controlName === 'pointEnd') {
      if (!isNaN(e.target.value)) {
        controls[controlName].value = e.target.value;
      }
    } else if (controlName === 'zone') {
      controls[controlName].value = controlValue;
      controls.region.value = '';
      controls.area.value = '';
      this.getRegion([controlValue]);
    } else if (controlName === 'region') {
      controls[controlName].value = controlValue;
      controls.area.value = '';
      this.getArea({
        region_ids: controlValue ? [controlValue] : [],
        zone_id: [controls.zone.value]
      })
    } else {
      controls[controlName].value = controlValue;
    }
    this.setState({ controls });
    this.handleValidation();
  };

  applyFilter = () => {
    this.handleValidation(true);
    let { controls } = this.state;
    let filterApplied = false;
    if (controls.orderStartDate.value ||
      controls.orderEndDate.value || controls.pointStart.value ||
      controls.pointEnd.value || controls.product.value ||
      controls.productQty.value || controls.zone.value || controls.area.value ||
      controls.paymentMode.value ||
      controls.region.value) {
      filterApplied = true;
    }
    this.props.applyDealerFilter(filterApplied ? controls : {});
  }

  resetFilter = () => {
    this.props.applyDealerFilter({});
  }

  closeOrderHistoryFilterDealer = () => {
    this.props.closeOrderHistoryFilterDealer();
  }


  render() {
    const { controls, products, userDetail } = this.state;
    return (
      <div class="custom-drawer">
        {this.state.isLoading && <CustomSpinner />}
        <div className="drawer-header">
          <span className="drawer-title">Filter</span>
          <img className="cursor-pointer" src="images/close.svg" alt='cancel'
            onClick={() => this.closeOrderHistoryFilterDealer()} />
        </div>
        <hr style={{ border: 'solid 1px #ced4da' }} className="w-100" />
        <div class="custom-drawer-content" style={{ marginLeft: '10px' }}>
          <Row className={'m-0'}>
            <Col xl="12">
              <FormGroup className="margin-top-10">
                <Label>Item Description</Label>
                <select
                  name="product"
                  className="form-control"
                  value={controls.product.value}
                  onChange={(e) => this.handleInputChange(e)}
                >
                  <option value='' key={'all'} >All</option>
                  {products.map((product, index) => (
                    <option value={product.uuid} key={index} >
                      {product.name}
                    </option>
                  ))
                  }

                </select>
              </FormGroup>
            </Col>
            <Col xl="12">
              <FormGroup>
                <Label>Quantity</Label>
                <select
                  name="productQty"
                  className="form-control"
                  value={controls.productQty.value}
                  onChange={(e) => this.handleInputChange(e)}
                >
                  <option value={''} key={'all'} >All</option>
                  {this.state.quantity.map((qty, index) => (
                    <option value={qty} key={index} >
                      {qty}
                    </option>
                  ))
                  }

                </select>
              </FormGroup>
            </Col>
            {
              userDetail.type && userDetail.type !== Roles.HEAD_OFFICE_ROLE &&
              <Col xl="12">
                <FormGroup>
                  <Label>Payment Mode</Label>
                  <Row>
                    <Col xl="2">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" name="paymentMode" value="all" checked={controls.paymentMode.value === 'all'}
                            onChange={(e) => this.handleInputChange({ target: { name: 'paymentMode', value: 'all' } })} />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: '10px' }} className={controls.paymentMode.value === 'all' ? 'font-weight-bold' : ''}> All</span>
                      </div>
                    </Col>
                    <Col xl="3">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" name="paymentMode" value="Online" checked={controls.paymentMode.value === 'Online'}
                            onChange={(e) => this.handleInputChange({ target: { name: 'paymentMode', value: 'Online' } })} />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: '10px' }}
                          className={controls.paymentMode.value === 'Online' ? 'font-weight-bold' : ''}> Online</span>
                      </div>
                    </Col>
                    <Col xl="5">
                      <div className="d-flex-row">
                        <label className="custom-checkboax-radio--container">
                          <input type="radio" name="paymentMode" value="Points Redeemption" checked={controls.paymentMode.value === 'Points Redeemption'}
                            onChange={(e) => this.handleInputChange({ target: { name: 'paymentMode', value: 'Points Redeemption' } })} />
                          <span className="checkmark-radio" />
                        </label>
                        <span style={{ marginLeft: '10px' }} className={controls.paymentMode.value === 'Points Redeemption' ? 'font-weight-bold' : ''}>Point Redemption</span>
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            }
            <Col xl="12">
              <Label className="font-weight-bold">Points Redeemed</Label>
            </Col>
            <Col xl="6">
              <FormGroup className="d-flex-column">
                <Label>Minimum points</Label>
                <Input
                  type="text"
                  name="pointStart"
                  placeholder="Type here"
                  value={controls.pointStart.value}
                  onChange={(e) => this.handleInputChange(e)}
                />
                {controls.pointStart.showErrorMsg && (
                  <div className="error">
                    *Please enter the valid value
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup className="d-flex-column">
                <Label>Maximum points</Label>
                <Input
                  type="text"
                  name="pointEnd"
                  placeholder="Type here"
                  value={controls.pointEnd.value}
                  onChange={(e) => this.handleInputChange(e)}
                />
                {controls.pointEnd.showErrorMsg && (
                  <div className="error">
                    *Please enter the valid value
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl="12" md="12" xs="12" className="mt-n3">
              {!controls.pointEnd.showErrorMsg && !controls.pointStart.showErrorMsg &&
                (controls.pointStart.isNotValidMinMax || controls.pointEnd.isNotValidMinMax) && (
                  <div className="error">
                    *Not valid minimum or maximum  value.
                  </div>
                )}
            </Col>
            <Col xl="12">
              <Label className="font-weight-bold">Order Date</Label>
            </Col>
            <Col xl="6">
              <FormGroup className="d-flex-column">
                <Label>Start Date</Label>
                <Label onClick={e => this.calendar1.state.open && e.preventDefault()}
                  className="display-botttom-datepicker">
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.orderStartDate.value
                          ? new Date(controls.orderStartDate.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'orderStartDate', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar1 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      maxDate={controls.orderEndDate.value ? controls.orderEndDate.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup className="d-flex-column">
                <Label>End Date</Label>
                <Label onClick={e => this.calendar2.state.open && e.preventDefault()}
                  className="date-picker-left-n-35px display-botttom-datepicker">
                  <InputGroup>
                    <DatePicker
                      className="form-control edit-name"
                      selected={
                        controls.orderEndDate.value
                          ? new Date(controls.orderEndDate.value)
                          : null
                      }
                      onChange={(e) => this.handleInputChange({ target: { name: 'orderEndDate', value: e } })}
                      showMonthDropdown
                      ref={(r) => (this.calendar2 = r)}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      minDate={controls.orderStartDate.value ? controls.orderStartDate.value : null}
                      dropdownMode="select"
                      placeholderText="Select"
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroupAddon addonType="append" >
                      <div className="input-group-text" style={{ paddingRight: '5px' }}>
                        {/* <FontAwesomeIcon htmlFor="datepicker" icon={faCalendarAlt} /> */}
                        <img src="/images/sidebar-icons/calendar_icon.png" alt='' />
                      </div>
                    </InputGroupAddon>
                  </InputGroup>
                </Label>
              </FormGroup>
            </Col>
            {
              this.state.is_head_office &&
              <>
                <Col xl="12">
                  <FormGroup>
                    <Label>Zone</Label>
                    <Row>
                      {this.state.zoneData.map((zone, index) => (
                        <Col xl="3" key={zone.zone_id}>
                          <div className="d-flex-row">
                            <label className="custom-checkboax-radio--container">
                              <input type="radio" name="zone" value={zone.zone_id} checked={controls.zone.value === zone.zone_id}
                                onChange={(e) => this.handleInputChange({ target: { name: 'zone', value: zone.zone_id } })} />
                              <span className="checkmark-radio" />
                            </label>
                            <span style={{ marginLeft: '10px' }}
                              className={controls.zone.value === zone.zone_id ? 'font-weight-bold' : ''}> {zone.zone_name}</span>
                          </div>
                        </Col>
                      ))
                      }
                    </Row>
                  </FormGroup>
                </Col>
                <Col xl="12">
                  <FormGroup>
                    <Label>Region</Label>
                    <select
                      name="region"
                      className="form-control"
                      value={controls.region.value}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value='' key={'all'} >All</option>
                      {this.state.regionData.map((region, index) => (
                        <option value={region.region_id} key={region.region_name} >
                          {region.region_name}
                        </option>
                      ))
                      }
                    </select>
                  </FormGroup>
                </Col>
                <Col xl="12">
                  <FormGroup>
                    <Label>Area</Label>
                    <select
                      name="area"
                      className="form-control"
                      value={controls.area.value}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value='' key={'all'} >All</option>
                      {this.state.areaData.map((area, index) => (
                        <option value={area.area_id} key={area.area_id} >
                          {area.area_name}
                        </option>
                      ))
                      }
                    </select>
                  </FormGroup>
                </Col>
              </>
            }
          </Row>
        </div>
        <Row className="c-top-15 custom-drawer-footer" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col className="d-flex-row" style={{ justifyContent: 'flex-end' }}>
            <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter()} style={{ width: '116px' }}>Reset</Button>&nbsp;&nbsp;
            <Button
              //  disabled={!isValidForm} 
              type="button"
              className="mb-1 mr-1 btn-pill action-button profile-save-button pull-right"
              color="warning"
              style={{ cursor: 'pointer' }}
              onClick={() => this.applyFilter()}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div >
    );
  }
}

export default OrderHistoryFilterHO;